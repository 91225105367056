import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';

import {
  ModalMessage,
  ModalContainer,
  ContentContainer,
  ModalContentContainer,
} from 'src/app/components/common/ModalMessage';
import { closeDashboard } from 'src/app/utils/external-events';
import analytics from 'src/app/services/analytics';
import { getUserPreferences } from 'src/app/redux/user/selectors';
import FTUModalContent from 'src/app/pages/qb-dashboard/components/FTUModalContent';
import { devices } from 'src/app/theme/AppDevices';
import { DASHBOARD_CLOSE_ORIGIN } from '../consts';

type Props = {
  onClose: () => void;
};

const eventPage = 'qb-dashboard';
const useFTUModal = ({ onClose }: Props) => {
  const userPreferences = useSelector(getUserPreferences);
  const [showFTUModal, setShowFTUModal] = useState<boolean>(!userPreferences.qbDashboardSeen);

  useEffect(() => {
    if (!userPreferences.qbDashboardSeen) {
      analytics.trackAction('show-ftu-dashboard-state-modal');
    }
  }, []);

  const closeModal = () => {
    setShowFTUModal(false);
    onClose();
  };

  const closeIframe = () => {
    analytics.track(eventPage, 'close-integration');
    closeDashboard(DASHBOARD_CLOSE_ORIGIN.CROSS);
  };

  const modalComponent = showFTUModal ? (
    <>
      <Box
        position="fixed"
        top="15px"
        right="20px"
        cursor="pointer"
        zIndex={10000}
        onClick={closeIframe}
      >
        <HeaderContentIcon className="icon-close-icon" />
      </Box>
      <Modal
        id="ftu-modal"
        withoutBackdrop
        contentComponent={<FTUModalContent onClose={closeModal} />}
      />
    </>
  ) : null;

  return [modalComponent];
};

const Modal = styled(ModalMessage)`
  ${ModalContainer} {
    position: absolute;
    bottom: 20px;

    @media ${devices.desktop}, ${devices.tablet} {
      width: 104rem;
    }
  }

  ${ModalContentContainer} {
    padding: 0;
  }
  ${ContentContainer} {
    margin-bottom: 0;
  }
`;

export const HeaderContentIcon = styled.i`
  font-size: 2.4rem;
  color: ${(props) => props.color || props.theme.text.color.darkGrey};
`;

export default useFTUModal;
