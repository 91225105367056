import {
  Table as ChakraTable,
  Thead as Header,
  Tbody as Body,
  Tr as Row,
  Th as HeaderCell,
  Td as Cell,
  TableCaption as Caption,
  TableProps as ChakraTableProps,
} from '@chakra-ui/react';
import { MD_SIZE, SIMPLE_VARIANT } from './Table.consts';

type TableProps<TTableVariants, TTableSizes> = Omit<ChakraTableProps, 'size' | 'variant' | 'colorScheme'>
  & {
    variant?: TTableVariants;
    size?: TTableSizes;
  };

type TableVariantsType = typeof SIMPLE_VARIANT;
type TableSizesType = typeof MD_SIZE;

const Table = <
  TTableVariants extends TableVariantsType | string = TableVariantsType,
  TTableSizes extends TableSizesType | string = TableSizesType
>({ children, ...restProps }: TableProps<TTableVariants, TTableSizes>) => (
  <ChakraTable {...restProps}>{children}</ChakraTable>
  );

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.HeaderCell = HeaderCell;
Table.Cell = Cell;
Table.Caption = Caption;

export { Table, Header, Body, Row, HeaderCell, Cell, Caption };
