import styled from 'styled-components';
// import { theme } from '@melio/billpay-design-system';
import { FlexProps, PopoverContentProps } from '@melio/billpay-design-system';

export const HeaderContentIcon = styled.i`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.ds.gray[200]};
`;

export const NotificationsLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const NewTag = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  background-color: ${({ theme }) => theme.colors.ds.pink[200]};
  border-radius: 50%;
  position: absolute;
  top: 5%;
  right: 15%;
  border: 0.1rem solid white;
`;

export const TransparentButton = styled.button`
  background: none;
  border: 0 none;
  display: flex;
  cursor: pointer;
`;

export const commonArrowStyles = {
  display: 'block',
  position: 'absolute',
  left: '83.3%!important',
  height: '1.6rem!important',
  width: '1.6rem!important',
  border: '0.1rem solid',
  borderColor: 'ds.gray.500',
  background: 'var(--chakra-colors-white)',
  zIndex: '10001!important',
  transform: 'rotate(45deg)!important',
};

export const defaultArrowStyles = {
  '[data-popper-placement*="bottom"] .chakra-popover__arrow-positioner': {
    ...commonArrowStyles,
    borderRight: 'none',
    borderBottom: 'none',
    top: '-0.9rem!important',
  },
};

export const PopoverWrap = styled.div`
  & > * > * :nth-child(1) {
    z-index: 3;
  }
  & > * > * :nth-child(2) {
    z-index: 5;
  }
`;
export const Image = styled.img`
  width: 100%;
`;

export const NoNotifications = styled.img`
  height: 12.2rem;
`;

export const BodyWrap = styled.div`
  margin-top: 0.8rem;
  & > * :last-child {
    display: none !important;
  }
`;

export const popoverContentStyles: PopoverContentProps = {
  left: '10%',
  marginTop: '0.5rem',
  height: '54rem',
  width: '38rem',
  boxShadow: 'unset',
  paddingBottom: '1.6rem',
  display: 'flex',
  flexDirection: 'column',
};

export const popoverHeaderStyles = {
  borderBottom: '0.4rem solid',
  borderBottomColor: 'ds.gray.800',
  padding: '2rem 2rem 0.8rem 3.2rem',
};

export const popoverBodyStyles = {
  overflow: 'auto',
  padding: '0',
  height: '100%',
};

export const noNotificationContainerStyles: FlexProps = {
  direction: 'column',
  justify: 'center',
  alignItems: 'center',
  height: '100%',
  gap: '1.6rem',
};
