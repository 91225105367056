import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SmartIcon, Tooltip } from '@melio/billpay-design-system';
import { analytics } from 'src/app/version-2/externals';
import {
  BATCH_BULK_EVENT_PAGE,
  REMOVE_FROM_LIST,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { HandCursorWrapper } from './renderers.styles';

export const RemoveRenderer: FC<any> = (props) => {
  const { row } = props;
  const { id, type, vendorId, totalBillIds, totalPartialBillIds }: BatchBulkItem = row.original;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const batchBulks = useSelector(batchBulkSelectors.selectPaymentIntentsItems);

  if (type === BatchBulkItemEnum.BILL || batchBulks?.length <= 1) return null;

  const onClick = () => {
    dispatch(batchBulkActions.deletePaymentIntentDTOSuccess({ id }));
    analytics.track(BATCH_BULK_EVENT_PAGE, REMOVE_FROM_LIST, {
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
      isBulk: totalBillIds?.length > 1,
    });
  };

  return (
    <Tooltip label={formatMessage({ id: 'batchBulkPage.renderers.remove.tooltip' })}>
      <HandCursorWrapper
        onClick={onClick}
        data-testid="delete-icon"
        className="disappearing-icon-wrapper"
      >
        <SmartIcon type="close" size="1.2rem" />
      </HandCursorWrapper>
    </Tooltip>
  );
};
