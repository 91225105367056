import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';

import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { AreaLoader } from '@melio/billpay-design-system';
import { fullOrgSyncStore } from 'src/app/modules/fullOrgSync/full-org-sync-store';
import { FullOrgSyncStatusEnum } from 'src/app/version-2/model/enums';
import {
  DASHBOARD_FILTER_ITEMS,
  QB_DASHBOARD_EMPTY_STATE_STATUS,
  QB_DASHBOARD_TAB_STATUS,
  UNPAID_BILL_DASHBOARD_STASUSES,
} from '../../consts';
import useGetDashboardColumns from '../../hooks/useDashboardColumns';
import useDashboardListParams from '../../hooks/useDashboardListParams';
import { useDashboardTabState, DashboardTabState } from '../../hooks/useDashboardTabState';
import useDashboardRowClickHandler from '../../hooks/useDashboardRowClickHandler';
import { DashboardListItemType } from '../../types';
import QBODataTable from '../QBODataTable';
import { tableEmptyStateWrapperStyles } from './styles';
import { useDashboardRowHighlighting } from '../../hooks/useDashboardRowHighlighting';
import { NoSearchResults } from '../NoSearchResults/NoSearchResults';
import { QBODashboardEmptyState } from '../QBODashboardEmptyState/QBODashboardEmptyState';

enum NO_RESULTS_MESSAGE_TYPES {
  QUERY_ONLY = 'queryOnly',
  FILTERS_ONLY = 'filtersOnly',
  QUERY_AND_FILTERS = 'queryAndFilters',
}

type Props = {
  status: QB_DASHBOARD_TAB_STATUS;
};

const QBDashboardTab = ({ status }: Props) => {
  const [columns] = useGetDashboardColumns(status);
  const qbDashboardListActions = useStoreActions(qbDashboardListItemsStore);
  const [listParams, { setOrder, setPage, setSearch }] = useDashboardListParams();
  const dashboardListItems: DashboardListItemType[] = useSelector((state) =>
    qbDashboardListItemsStore.selectors.list.value(state, listParams)
  );
  const loadStatus: { totalCount: number } = useSelector(
    qbDashboardListItemsStore.selectors.list?.status(listParams)
  );
  const totalCount = loadStatus?.totalCount || 0;

  const fullOrgSyncStatus = useSelector(fullOrgSyncStore.selectors.status);
  const isFullOrgSyncRunning = fullOrgSyncStatus === FullOrgSyncStatusEnum.RUNNING;

  const { highlightedItemIds } = useDashboardRowHighlighting();
  const selectionEnabled = status === QB_DASHBOARD_TAB_STATUS.UNPAID;
  const selectedIds: string[] = useSelector(
    qbDashboardListItemsStore.selectors.qbDashboardSelection.selectedIds
  );

  const [tabState] = useDashboardTabState();

  const [isRowClickable, handleRowClick] = useDashboardRowClickHandler({
    tabStatus: status,
  });

  const selectableItemsIds = dashboardListItems
    .filter((item) => item.metadata.statusInDashboard !== UNPAID_BILL_DASHBOARD_STASUSES.FAILED)
    .map((item) => item.id);

  if (tabState === DashboardTabState.LOADING) {
    return <AreaLoader />;
  }

  const clearFilters = (): void =>
    setSearch({
      ...listParams.filters,
      statusesInDashboard: undefined,
      vendors: undefined,
      dueDates: undefined,
    });

  const handleActionClick = (type: string): void => {
    const actionByType = {
      [NO_RESULTS_MESSAGE_TYPES.FILTERS_ONLY]: clearFilters,
      [NO_RESULTS_MESSAGE_TYPES.QUERY_AND_FILTERS]: clearFilters,
    };

    actionByType[type]();
  };

  if (tabState === DashboardTabState.NO_RESULTS) {
    const filters = listParams?.filters;
    const term = filters?.search || '';
    const hasAppliedFilters = DASHBOARD_FILTER_ITEMS.some((filterName) => filters[filterName]);

    const getNoResultMessageType = (): string => {
      if (term && !hasAppliedFilters) return NO_RESULTS_MESSAGE_TYPES.QUERY_ONLY;

      if (!term && hasAppliedFilters) return NO_RESULTS_MESSAGE_TYPES.FILTERS_ONLY;

      if (term && hasAppliedFilters) return NO_RESULTS_MESSAGE_TYPES.QUERY_AND_FILTERS;

      return NO_RESULTS_MESSAGE_TYPES.QUERY_AND_FILTERS;
    };

    const currentMessageType = getNoResultMessageType();
    const title = `paymentDashboard.search.noResults.${currentMessageType}.title`;
    const description = `paymentDashboard.search.noResults.${currentMessageType}.description`;
    const actionLabel =
      currentMessageType === NO_RESULTS_MESSAGE_TYPES.QUERY_ONLY
        ? ''
        : `paymentDashboard.search.noResults.${currentMessageType}.actionLable`;

    return (
      <Box __css={tableEmptyStateWrapperStyles}>
        <NoSearchResults
          term={term}
          title={title}
          description={description}
          actionLabel={actionLabel}
          onActionButtonClick={() => handleActionClick(currentMessageType)}
        />
      </Box>
    );
  }

  if (tabState === DashboardTabState.EMPTY) {
    const type =
      status === QB_DASHBOARD_TAB_STATUS.UNPAID && isFullOrgSyncRunning
        ? QB_DASHBOARD_EMPTY_STATE_STATUS.SYNCING
        : status;

    return (
      <Box __css={tableEmptyStateWrapperStyles}>
        <QBODashboardEmptyState type={type} />
      </Box>
    );
  }

  if (tabState === DashboardTabState.UNKNOWN) {
    return null;
  }

  return (
    <QBODataTable<DashboardListItemType>
      columns={columns}
      filters={listParams.filters}
      data={dashboardListItems}
      totalItems={totalCount}
      onChangeOrder={setOrder}
      onChangePage={setPage}
      rowClickSettings={{ isRowClickable, handleRowClick }}
      highlightedItemIds={highlightedItemIds}
      selectionEnabled={selectionEnabled}
      selectedIds={selectedIds}
      setSelectedIds={qbDashboardListActions.qbDashboardSelection.setSelection}
      selectableItemsIds={selectableItemsIds}
    />
  );
};

export default QBDashboardTab;
