import React from 'react';
import SelectedMethod from 'src/app/components/common/SelectMethods/components/SelectedMethod';
import { SelectedMethodContainer } from 'src/app/components/common/SelectMethods/components/SelectMethods';

type Props = {
  label: string;
};

export const SelectedMethodForSharedVendor = ({ label }: Props) => (
  <SelectedMethodContainer>
    <SelectedMethod
      title="bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod"
      icon="icon-bank-icon"
      label={label}
    />
  </SelectedMethodContainer>
);
