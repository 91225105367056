import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import locations from 'src/app/pages/locations';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { SYNC_PAYMENT_INTENT } from '../consts';

type Params = {
  billId: string;
  paymentOriginId?: string;
  paymentId?: string;
};

const useEditPaymentAction = ({ billId, paymentId, paymentOriginId }: Params) => {
  const [historyPush] = useHistoryWithOrgId();
  const location = useLocation();

  const goEditPayment = useCallback(() => {
    const exitUrl = `${location.pathname}${location.search}`;

    historyPush({
      path: locations.qbDashboard.syncPaymentByOrigin,
      params: {
        originId: paymentOriginId || '',
      },
      state: {
        exitUrl,
        intent: SYNC_PAYMENT_INTENT.EDIT_PAYMENT,
        paymentId,
        billId,
      },
    });
  }, [historyPush, billId, paymentOriginId, location]);

  return [goEditPayment];
};

export default useEditPaymentAction;
