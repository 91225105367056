import { QBOFooter } from 'src/app/components/layout/QBOElements';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { WizardInner } from 'src/app/components/layout/QBOWizardElements';
import { devices } from 'src/app/version-2/utils/devices.utils';

import styled from 'styled-components';

export const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  @media ${devices.phablet} {
    align-items: initial;
  }

  ${WizardInner} {
    margin-bottom: 3rem;

    @media ${devices.mobile}, ${devices.phablet} {
      padding: 0 1rem;
    }
  }

  ${QBOFooter} {
    @media ${devices.tablet} {
      border: 0 solid #dce0e5;
      border-top-width: 1px;
    }
  }
`;

export const LogoContainer = styled.div`
  margin: ${({ theme }) => theme.space.ds['2xl']} 0;
  text-align: center;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: ${({ theme }) => theme.space.ds.lg};
    margin-bottom: 3.5rem;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.ds.white};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.ds.lg};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.ds.gray[600]};
  padding: ${({ theme }) => theme.space.ds['2xl']};

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    border: 0;
    box-shadow: ${({ theme }) => theme.shadows.ds.md};
    padding: ${({ theme }) => theme.space.ds['2xl']} ${({ theme }) => theme.space.ds.lg};
  }
`;

export const Title = styled.div`
  margin-top: ${({ theme }) => theme.space.ds.xl};
  color: ${({ theme }) => theme.colors.ds.black};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  ${({ theme }) => theme.textStyles.ds.h5};
`;

export const Subtitle = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.ds.black};
  ${({ theme }) => theme.textStyles.ds.body2};
`;

export const Separator = styled.div`
  height: 0.4rem;
  align-self: stretch;

  @media ${devices.tablet}, ${devices.desktop} {
    margin-top: ${({ theme }) => theme.space.ds.lg};
    margin-bottom: ${({ theme }) => theme.space.ds['2xl']};
    background-color: ${({ theme }) => theme.colors.ds.white};
  }

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: ${({ theme }) => theme.space.ds['2xl']};
    margin-bottom: ${({ theme }) => theme.space.ds['2xl']};
    background-color: ${({ theme }) => theme.colors.ds.gray[800]};
  }
`;

export const ReviewTitle = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
  ${({ theme }) => theme.textStyles.ds.body2};
`;

export const ReviewItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space.ds.xl};
  gap: ${({ theme }) => theme.space.ds.xl};
`;
