import { forwardRef, InputProps as ChakraInputProps, Input as ChakraInput } from '@chakra-ui/react';

import { FieldWrapper } from '../FieldWrapper';
import { FieldWrapperType } from '../../types';

export type InputProps = Partial<ChakraInputProps> & FieldWrapperType;

const Input = forwardRef<InputProps, 'input'>(
  (
    {
      label,
      helperText,
      errorMessage,
      tooltipLabel,
      leftAddon,
      rightAddon,
      rightAddonWidth,
      recommendedLabelText,
      ...rest
    },
    ref,
  ) => {
    const { isDisabled, isRequired } = rest;
    const input = (id) => <ChakraInput ref={ref} {...rest} id={id} isInvalid={!!errorMessage} />;

    return (
      <FieldWrapper
        input={input}
        label={label}
        helperText={helperText}
        errorMessage={errorMessage}
        tooltipLabel={tooltipLabel}
        isDisabled={isDisabled}
        leftAddon={leftAddon}
        rightAddon={rightAddon}
        rightAddonWidth={rightAddonWidth}
        recommendedLabelText={recommendedLabelText}
        isRequired={isRequired}
      />
    );
  },
);

export { Input };
