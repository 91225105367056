import { ButtonProps, CloseButton as ChakraCloseButton, forwardRef } from '@chakra-ui/react';

type Props = Omit<ButtonProps, 'colorScheme' | 'variant' | 'size'> & {
  testId?: string | null;
  id?: string | null;
  size?: 'sm' | 'md' | 'lg';
};

export const CloseButton = forwardRef<Props, 'button'>((props: Props, ref) => {
  const { onClick, children, testId, id, ...rest } = props;
  const dataTestId = testId || `button-${id}`;

  return (
    <ChakraCloseButton onClick={onClick} data-testid={dataTestId} ref={ref} {...rest}>
      {children}
    </ChakraCloseButton>
  );
});
