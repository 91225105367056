import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';

import paymentsStore from 'src/app/modules/payments/payment-store';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { CONSTS, HOLIDAYS_WARNING_ICON_VARIANT } from 'src/app/utils/consts';
import { HolidaysWarning } from 'src/app/components/common/HolidaysWarning';
import { isFastDeliveryType } from 'src/app/utils/delivery-methods';
import { featureFlags } from '@melio/shared-web';
import QBOTableRangeDate from './QBOTableRangeDate';
import { DeliveryMethodType, PaymentType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const QBODeliveryEtaCell = ({ item }: Props) => {
  const [showHolidaysWarningLD] = featureFlags.useFeature('us-holidays-checks');
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(item.paymentId));
  const deliveryMethod: DeliveryMethodType = useSelector(
    deliveryMethodsStore.selectors.byId(item.deliveryMethodId)
  );

  let isFastPayment = false;

  if (showHolidaysWarningLD && deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
    isFastPayment = !isFastDeliveryType(payment?.deliveryPreference);
  }

  return (
    <Box display="flex">
      <QBOTableRangeDate fromDate={payment?.deliveryEta} toDate={payment?.maxDeliveryEta} />
      {isFastPayment && (
        <HolidaysWarning
          tooltipLabel="holidaysWarning.tooltipLabels.scheduled"
          variant={HOLIDAYS_WARNING_ICON_VARIANT.WARNING}
        />
      )}
    </Box>
  );
};

export default QBODeliveryEtaCell;
