import React from 'react';
import { useModal } from 'src/app/helpers/react/useModal';
import { CommonDialog } from 'src/app/ui/dialog/CommonDialog';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import ConfirmPage from 'src/app/pages/quickpay/operations/ConfirmPage';
import { useSelector } from 'react-redux';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { DIALOG_VARIANTS, PAYMENT_STATUS } from 'src/app/utils/consts';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import locations from '../locations';
import { QuickPayment } from '../../../modules/payments/quickpay-slice';
import { loggingApi } from 'src/app/version-2/api/loggers';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};
const EditConfirmPage = ({ onNext, onPrev, onExit }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const site = useSiteContext();
  const payment: QuickPayment = useSelector(
    paymentsStore.selectors.quickpay.newPaymentWizard.payment
  );
  const paymentActions = useStoreActions(paymentsStore);
  const orgId = useSelector(getOrgId);
  const isRetry = payment.status === PAYMENT_STATUS.FAILED;
  const action = isRetry ? 'retry' : 'update';
  const status: { loading: boolean } = useSelector(
    paymentsStore.selectors.quickpay[action].status(payment.id)
  );

  const [ErrorModal, showError] = useModal(CommonDialog, {
    confirm: async () => onNext(),
    variant: DIALOG_VARIANTS.ERROR,
    description: 'bills.pay.confirm.editError.description',
    confirmText: 'bills.pay.confirm.editError.cancel',
    modalName: 'editError',
    showCancel: false,
  });
  const onSubmit = async () => {
    const { totalAmount, ...rest } = payment;

    try {
      if (isRetry) {
        const createOrigin = site.createOrigin.pay.payment;

        await paymentActions.quickpay.retry({
          ...rest,
          orgId,
          amount: totalAmount,
          createOrigin,
        });
      } else {
        await paymentActions.quickpay.update({
          ...rest,
          orgId,
          amount: totalAmount,
        });
      }

      onNext();
    } catch (e: any) {
      loggingApi.error('EditConfirmPage.onSubmit(): quickpay update/retry error', { error: e });
      showError();
    }
  };

  const goEditNote = () => {
    historyPush({
      path: locations.edit.note,
      params: { paymentId: payment.id as string | number },
    });
  };
  const goEditDate = () => {
    historyPush({
      path: locations.edit.date,
      params: { paymentId: payment.id as string | number },
    });
  };
  const goEditDeliveryMethod = () => {
    const { vendorId } = payment;

    historyPush({
      path: locations.edit.deliveryMethod,
      params: { paymentId: payment.id as string | number, vendorId: vendorId as string },
    });
  };

  return (
    <>
      {ErrorModal}
      <ConfirmPage
        onExit={onExit}
        onPrev={onPrev}
        onSubmit={onSubmit}
        goEditDate={goEditDate}
        goEditNote={goEditNote}
        goEditDeliveryMethod={goEditDeliveryMethod}
        headerLabel="qbr.confirm.payment.header"
        isLoading={status?.loading}
        buttonLabel={isRetry ? 'bills.pay.edit.retry' : 'bills.pay.edit.action'}
      />
    </>
  );
};

export default EditConfirmPage;
