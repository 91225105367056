import React, { useCallback } from 'react';
import { SmartIcon } from '@melio/billpay-design-system';
import { CellContext } from '@tanstack/react-table';
import { analytics } from 'src/app/version-2/externals';
import {
  BATCH_BULK_EVENT_PAGE,
  BULK_GROUPING_CLICK,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  ExpanderImage,
  ExpanderRendererContainer,
} from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';

const ExpanderRenderer = (props: CellContext<BatchBulkItem, any>) => {
  const { row } = props;

  const onToggleExpand = useCallback(() => {
    const expandHandler = row.getToggleExpandedHandler();

    expandHandler();
    analytics.track(BATCH_BULK_EVENT_PAGE, BULK_GROUPING_CLICK, {
      groupingState: row.getIsExpanded() ? 'collapse' : 'expand', // it's reversed since we are just changing the state now
      vendorId: row.original.vendorId,
    });
  }, [row]);

  if (!row.getCanExpand()) return null;

  return (
    <ExpanderRendererContainer onClick={onToggleExpand}>
      <ExpanderImage isExpanded={row.getIsExpanded()}>
        <SmartIcon type="arrow-expand" />
      </ExpanderImage>
    </ExpanderRendererContainer>
  );
};

export { ExpanderRenderer };
