import { loggingApi } from 'src/app/version-2/api/loggers';
import { utilsLocations } from 'src/app/version-2/externals';
import { DeliveryMethod } from 'src/app/version-2/model/dtos';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { AchDeliveryMethodDetailsFlowState } from '../../model/objects/achDeliveryMethodDetailsFlowState';
import { achDeliveryMethodDetailsSelectors } from '../achDeliveryMethodDetails.slice';

import { History } from 'history';
import { select } from 'redux-saga/effects';

interface NavigateOnAchDeliveryMethodSavedHandlerParams {
  savedDeliveryMethod?: DeliveryMethod;
  vendorId: string;
  history: History;
}

export function* navigateOnAchDeliveryMethodSavedHandler({
  savedDeliveryMethod,
  vendorId,
  history,
}: NavigateOnAchDeliveryMethodSavedHandlerParams) {
  const redirectUrl: AchDeliveryMethodDetailsFlowState['redirectUrl'] = yield select(
    achDeliveryMethodDetailsSelectors.selectFlowStateRedirectUrl
  );

  if (redirectUrl) {
    const flowState: AchDeliveryMethodDetailsFlowState = yield select(
      achDeliveryMethodDetailsSelectors.selectFlowState
    );

    const prevState = history.location?.state || {};

    history.push(redirectUrl, {
      ...(prevState as any),
      batchList: flowState.batchList,
      selectedPaymentIntent: flowState.selectedPaymentIntent,
      newDeliveryMethod: savedDeliveryMethod,
    });
  } else {
    const orgId: string = yield select(userSliceSelectors.selectOrgId);

    loggingApi.error(
      'editDeliveryMethodSaga.navigateOnDeliveryMethodSavedHandler(): redirectUrl is not presented',
      { orgId }
    );

    history.push(
      utilsLocations.Settings.vendorSingle.url({
        id: vendorId,
        orgId,
      })
    );
  }
}
