import React, { FC } from 'react';
import { NotificationType } from 'src/app/redux/notifications/types';
import {
  CTALink,
  DateContainer,
  Divider,
  Item,
  NewTag,
  TextContainer,
} from './NotificationsItem.styles';

type Props = {
  notification: NotificationType;
  isNew: boolean;
};

const NotificationsItem: FC<Props> = ({ notification: { body, CTA, date }, isNew }) => (
  <>
    <Item>
      <TextContainer textStyle="ds.body2" isNew={isNew}>
        {body}
        {isNew && <NewTag />}
        {/* add on click */}
        {CTA && <CTALink target="_selfs" text={CTA.caption} isNew={isNew} />}
      </TextContainer>
      <DateContainer textStyle="ds.body3">{date}</DateContainer>
    </Item>
    <Divider />
  </>
);

export default NotificationsItem;
