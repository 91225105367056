import React, { memo, useState } from 'react';
import { Box, Image } from '@melio/billpay-design-system';
import virtualCardInfoImage from 'src/app/images/virtual-card/virtual-card-info-image.svg';
import { MIFormattedText } from 'src/app/utils/formatting';
import { CheckListItem } from 'src/app/components/common/CheckListItem';
import { OptionalDeliveryMethodsType } from 'src/app/utils/types';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import {
  CheckListContainer,
  HeaderContainer,
  HeaderContentContainer,
  ImagePlaceholder,
  MobileFeeInfoText,
} from './QBOVirtualCardMultilateralInfoContent.styles';
import dictionary from '../VirtualCardInfoPageDictionary';

const CHECK_MARK_ITEMS = ['easy', 'safe', 'flexible'];

type PropsType = {
  isMobile: boolean;
  deliveryType: OptionalDeliveryMethodsType;
};

const QBOVirtualCardMultilateralInfoContent = memo(({ isMobile, deliveryType }: PropsType) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const isCheckDeliveryType = deliveryType === DELIVERY_TYPE.CHECK;

  return (
    <Box data-testid="qbo-virtual-card-multilateral-info-content">
      <HeaderContainer>
        <Image
          onLoad={() => setIsImageLoaded(true)}
          src={virtualCardInfoImage}
          width="full"
          borderRadius="1rem"
        />
        {!isImageLoaded && <ImagePlaceholder />}
        <HeaderContentContainer isMobile={isMobile}>
          <Box
            textStyle={isMobile ? 'h5' : 'body1'}
            fontWeight="ds.semi"
            lineHeight={isMobile ? '2.8rem' : '3.2rem'}
            fontSize={isMobile ? '2rem' : '2.4rem'}
          >
            <MIFormattedText label={dictionary.multilateral.title} />
          </Box>
          <Box lineHeight={isMobile ? '2.4rem' : '2rem'} fontSize={isMobile ? '1.6rem' : '1.4rem'}>
            <MIFormattedText label={dictionary.multilateral.body} />
          </Box>
        </HeaderContentContainer>
      </HeaderContainer>
      <CheckListContainer>
        {CHECK_MARK_ITEMS.map((item) => (
          <CheckListItem
            key={item}
            title={dictionary.multilateral.checkListItem.title(item)}
            subtitle={dictionary.multilateral.checkListItem.subtitle(item, isCheckDeliveryType)}
          />
        ))}
      </CheckListContainer>
      {isMobile && (
        <MobileFeeInfoText>
          <MIFormattedText label={dictionary.multilateral.info} />
        </MobileFeeInfoText>
      )}
    </Box>
  );
});

export default QBOVirtualCardMultilateralInfoContent;
