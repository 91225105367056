import {
  ACCOUNT_NUMBER_REGEXP,
  ROUTING_NUMBER_REGEXP,
} from 'src/app/version-2/utils/validationRegex.utils';
import { fundingSourceWithSameDetailsExistTest } from './rules/fundingSourceWithSameDetailsExistTest';
import { routingChecksumTest } from './rules/routingChecksumTest';
import { AchDetailsFormFieldNamesEnum } from '../enums/achDetailsFormFieldNames.enum';
import { AchDetailsValidationContext } from '../objects/achDetailsValidationContext';

import { object, string, StringSchema } from 'yup';

export const achDetailsSchema = object({
  [AchDetailsFormFieldNamesEnum.RoutingNumber]: string()
    .test(routingChecksumTest)
    .matches(ROUTING_NUMBER_REGEXP, 'inputErrors.deliveryMethodAch.routingNumber.string.regex.base')
    .defined(),
  [AchDetailsFormFieldNamesEnum.AccountNumber]: (
    string() as StringSchema<string | undefined, AchDetailsValidationContext>
  )
    .matches(ACCOUNT_NUMBER_REGEXP, 'inputErrors.deliveryMethodAch.accountNumber.string.regex.base')
    .test(fundingSourceWithSameDetailsExistTest)
    .defined(),
  [AchDetailsFormFieldNamesEnum.ConfirmAccountNumber]: string()
    .test(
      'passwords-match',
      'inputErrors.deliveryMethodAch.confirmAccountNumber.doesntMatchAccountNumber',
      function (value) {
        return this.parent.accountNumber === value;
      }
    )
    .defined(),
}).required();
