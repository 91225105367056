import React from 'react';
import styled from 'styled-components';
import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  submit: any;
  achModel: any;
};

const QBRNewAchDeliveryMethodForm = ({ submit, achModel }: Props) => (
  <FormContainer onSubmit={submit}>
    <RowContainer>
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.routingNumber"
        model={achModel.routingNumber}
        required
        type="tel"
        autoFocus
        privateData
      />
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.accountNumber"
        model={achModel.accountNumber}
        required
        type="tel"
        privateData
      />
    </RowContainer>
    <FormHint>
      <MIFormattedText label="vendors.addDeliveryMethodByLink.achFormHint" />
    </FormHint>
    <button type="submit" aria-label="Submit" hidden />
  </FormContainer>
);

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0rem 0 4rem;
`;

const FormHint = styled.div`
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.darkGrey};
  margin-top: 0.4rem;
`;

const RowContainer = styled.div`
  margin-top: 1.2rem;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

export default QBRNewAchDeliveryMethodForm;
