import {
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import {
  ModalBody,
  ModalButton,
  ModalButtonContent,
  ModalCover,
  ModalFooter,
  ModalHeader,
  ModalHeaderSubTitle,
  ModalHeaderTitle,
  ModalIcon,
} from './components';

type Props = ModalProps & {
  isOverlay?: boolean;
};

export const Modal = ({
  children,
  scrollBehavior = 'inside',
  isOverlay = true,
  isCentered = true,
  ...rest
}: Props) => (
  <ChakraModal scrollBehavior={scrollBehavior} isCentered={isCentered} {...rest}>
    {isOverlay && <ModalOverlay />}
    <ModalContent data-testid="modal-container">{children}</ModalContent>
  </ChakraModal>
);

Modal.Header = ModalHeader;
Modal.HeaderTitle = ModalHeaderTitle;
Modal.HeaderSubTitle = ModalHeaderSubTitle;
Modal.Overlay = ModalOverlay;
Modal.CloseButton = ModalCloseButton;
Modal.Body = ModalBody;
Modal.Cover = ModalCover;
Modal.Footer = ModalFooter;
Modal.ButtonContent = ModalButtonContent;
Modal.Button = ModalButton;
Modal.Icon = ModalIcon;
