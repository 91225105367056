import { QBOLayoutPage } from 'src/app/version-2/externals';

import styled from 'styled-components';

export const HiddenInput = styled.input`
  display: none;
`;

export const AchDetailsFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.ds.xl};
  margin-bottom: ${({ theme }) => theme.space.ds.xl};
`;

export const AchDetailsFooter = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  ${({ theme }) => theme.textStyles.ds.body3};
  text-align: center;
`;

export const AchDeliveryMethodDetailsLayout = styled(QBOLayoutPage)`
  .wizard-step-title {
    margin-bottom: ${({ subtitle, theme }) =>
      subtitle ? theme.space.ds.xl : theme.space.ds['2xl']};
  }

  .wizard-footer-container {
    margin-top: ${({ theme }) => theme.space.ds.xl};
  }
`;
