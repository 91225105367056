import { loggingApi } from 'src/app/version-2/api/loggers';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { refundApi } from 'src/app/version-2/pages/refund/api/refund.api';
import { refundActions } from './refund.slice';

import { all, call, put, select, takeEvery } from 'redux-saga/effects';

function* createRefundPaymentHandler(action: ReturnType<typeof refundActions.createRefundPayment>) {
  try {
    const { reason, paymentId } = action.payload;
    const orgId = yield select(userSliceSelectors.selectOrgId);

    yield all([put(refundActions.clearRefundStatus()), put(refundActions.setRefundLoading(true))]);
    yield call(refundApi.refundPayment, { reason, orgId, paymentId });
    yield all([
      put(refundActions.refundPaymentSuccess()),
      put(refundActions.setRefundLoading(false)),
    ]);
  } catch (e) {
    yield all([
      put(refundActions.refundPaymentFailed()),
      put(refundActions.setRefundLoading(false)),
    ]);
    loggingApi.warn('refundSaga.fetchRefundStatusHandler(): failed', e);
  }
}

export function* watchRefund() {
  yield takeEvery(refundActions.createRefundPayment, createRefundPaymentHandler);
}
