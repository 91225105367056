import React, { useEffect } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import billsStore from 'src/app/modules/bills/bills-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { useRedirectToSinglePayBillFlow } from 'src/app/pages/quickbooks/hooks/useRedirectToSinglePayBillFlow';
import locations from 'src/app/pages/locations';
import { PayBillSkeleton } from '../bill/pay/components/PayBillSkeleton';
import { BillType, VendorType } from 'src/app/utils/types';

type LocationState = {
  redirectUrl: string;
  exitUrl: string;
  initialPaymentAmount: number;
  billId: string;
};

const QBOSyncBillPage = () => {
  const { redirectUrl, exitUrl, initialPaymentAmount, billId } = useLocation<LocationState>().state;
  const bill: BillType = useSelector(billsStore.selectors.byId(billId));
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(bill?.vendorId));
  const match = useRouteMatch<{ originId: string }>();
  const { originId } = match.params;
  const { redirectToSinglePayBillFlow } = useRedirectToSinglePayBillFlow({
    shouldUseHistoryReplace: true,
  });

  useEffect(() => {
    redirectToSinglePayBillFlow({
      originId,
      redirectUrl,
      exitUrl,
      initialPaymentAmount,
      errorBillDetails: {
        amount: bill?.balance as number | undefined,
        vendorName: vendor?.companyName,
      },
      billZeroBalanceRedirectUrl: locations.entrypoints.errors.billZeroBalance,
    });
  }, []);

  return <PayBillSkeleton />;
};

export default QBOSyncBillPage;
