import React from 'react';
import styled from 'styled-components';
import reduce from 'lodash/reduce';
import countBy from 'lodash/countBy';
import compact from 'lodash/compact';
import analytics from 'src/app/services/analytics';
import { PaymentType, CompanyInfoType } from 'src/app/utils/types';
import { MIFormattedCurrency, MIFormattedDate } from 'src/app/utils/formatting';
import {
  DashboardListItemTypeResponse,
  DashboardListParams,
} from 'src/app/pages/qb-dashboard/types';
import {
  EXPEDITED_DELIVERY_TYPES,
  PAYMENT_APPROVAL_STATUS,
  FUNDING_SOURCE_ORIGIN,
} from 'src/app/utils/consts';
import { SYNC_PAYMENT_NOT_APPROVED_ERROR } from 'src/app/version-2/model/constants/syncPaymentErrorCode.consts';

type Params = {
  payload: {
    totalCount: number;
    items: DashboardListItemTypeResponse[];
  };
  meta: {
    identifier: DashboardListParams;
  };
  extraParams?: {
    withSyncLoader: boolean;
  };
};

export const trackBillListLoaded = ({ payload, meta, extraParams }: Params) => {
  const totalAmount = reduce(payload.items, (sum, c) => sum + c.bill.totalAmount, 0);
  const billIds = payload.items?.map((item) => item.billId);
  const billStatus = countBy(payload.items, (item) => item.metadata.statusInDashboard);
  const { totalCount } = payload;
  const { filters } = meta.identifier;
  const tabName = filters.status;

  analytics.trackAction('dashboard-list-action', {
    totalAmount,
    billStatus,
    billIds,
    totalCount,
    tabName,
    filters,
    ...extraParams,
  });
};

export const trackDashboardListLoaded = ({ payload, meta, extraParams }: Params) => {
  const totalAmount = reduce(
    payload.items,
    (sum, c) => sum + (c.payment?.amount || c.bill.totalAmount),
    0
  );
  const billIds = payload.items?.map((item) => item.billId);
  const paymentIds = compact(payload.items?.map((item) => item.paymentId));
  const billStatus = countBy(payload.items, (item) => item.metadata.statusInDashboard);
  const { totalCount } = payload;
  const { filters } = meta.identifier;
  const tabName = filters.status;

  analytics.trackAction('dashboard-list-action', {
    totalAmount,
    billStatus,
    billIds,
    paymentIds,
    totalCount,
    tabName,
    filters,
    ...extraParams,
  });
};

type PartialAmountInvalidParams = {
  balance: number;
  amount: number;
};

export function isPartialAmountInvalid({ balance, amount }: PartialAmountInvalidParams) {
  return amount > balance;
}

export function getDashboardItemId(billId?: string, paymentId?: string): string {
  return `${billId}${parseInt(paymentId || '', 10) ? `_${paymentId}` : ''}`;
}

export function getSubtitle({ payment, hasVendorEmail, isRecurring }) {
  const isExpedited =
    payment?.deliveryPreference !== null
      ? EXPEDITED_DELIVERY_TYPES.includes(payment?.deliveryPreference || '')
      : false;
  const isQBCashFS = payment?.fundingSource?.origin === FUNDING_SOURCE_ORIGIN.QBCASH;
  const isPendingApproval = payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;

  if (isPendingApproval) {
    return 'bills.pay.payBillSuccess.subtitles.pendingApproval';
  }

  if (hasVendorEmail) {
    return isRecurring
      ? 'bills.pay.payBillSuccess.subtitles.recurringWithNotify'
      : 'bills.pay.payBillSuccess.subtitles.regularWithNotify';
  }

  if (isQBCashFS) {
    return 'bills.pay.payBillSuccess.subtitles.qbCash';
  }

  if (isExpedited) {
    return 'bills.pay.payBillSuccess.subtitles.expedited';
  }

  if (isRecurring) {
    return 'bills.pay.payBillSuccess.subtitles.recurring';
  }

  return 'bills.pay.payBillSuccess.subtitles.regular';
}

type GetHeadParams = {
  payment: PaymentType;
  hasVendorEmail: boolean;
  organization?: CompanyInfoType;
  vendorName: string;
  isRecurring?: boolean;
  shouldShowNotifyVendorButton?: boolean;
};

export function getPayBillSuccessLabels({
  payment,
  hasVendorEmail,
  organization,
  vendorName,
  isRecurring,
  shouldShowNotifyVendorButton = false,
}: GetHeadParams) {
  const title = `bills.pay.payBillSuccess.titles.${
    shouldShowNotifyVendorButton ? 'regularSuccess' : 'regular'
  }`;
  const subtitle = getSubtitle({ payment, hasVendorEmail, isRecurring });

  const textValues = {
    amount: <MIFormattedCurrency key="amount" value={payment?.amount?.toString() || null} />,
    vendorName,
    companyName: organization?.companyName || '',
    processDate: <MIFormattedDate key="scheduledDate" date={payment?.scheduledDate} />,
  };

  return { title, subtitle, textValues };
}

export function getWarningNotificationLabels({
  syncPaymentErrorCode,
  showQbcashNotification,
  payment,
}) {
  const isPaymentNotApprovedError = syncPaymentErrorCode === SYNC_PAYMENT_NOT_APPROVED_ERROR;

  if (isPaymentNotApprovedError && showQbcashNotification) {
    return {
      title: 'bills.pay.payBillSuccess.notifications.qbCashAndPaymentNotApproved.title',
      subtitle: 'bills.pay.payBillSuccess.notifications.qbCashAndPaymentNotApproved.subtitle',
      subtitleValues: {
        processDate: <MIFormattedDate key="scheduledDate" date={payment?.scheduledDate} />,
        listItem: (...chunks) => <ListItem>{React.Children.toArray(chunks)}</ListItem>,
        list: (...chunks) => <List>{React.Children.toArray(chunks)}</List>,
      },
    };
  }

  if (showQbcashNotification) {
    return {
      subtitle: 'bills.pay.payBillSuccess.notifications.qbCash.subtitle',
      subtitleValues: {
        processDate: <MIFormattedDate key="scheduledDate" date={payment?.scheduledDate} />,
      },
    };
  }

  if (isPaymentNotApprovedError) {
    return {
      title: 'bills.pay.payBillSuccess.notifications.paymentNotApproved.title',
      subtitle: 'bills.pay.payBillSuccess.notifications.paymentNotApproved.subtitle',
      subtitleValues: {},
    };
  }

  return {};
}

const ListItem = styled.li``;

const List = styled.ul`
  padding: 0;
  margin: 0;
  padding-inline-start: 1.2rem;
`;
