import React from 'react';
import DatePicker from 'src/app/components/form/DatePicker';
import styled from 'styled-components';
import { isBusinessDay, getEndOfLifeMaxDate } from 'src/app/utils/dates';
import { DateFieldType } from 'src/app/utils/types';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';

export type InlineDatePickerProps = {
  date?: Date;
  onChange: (arg0: DateFieldType) => void | Promise<void>;
  disabled?: boolean;
  isFirstWave?: boolean;
};

export const TableDatePicker = (props: InlineDatePickerProps) => {
  const { onChange, date, disabled, isFirstWave } = props;

  const customInput = (
    <DatePickerWrapper>
      <Section isDateSelected={!!date}>
        <div>
          {' '}
          {date ? (
            <MIFormattedDate date={date} />
          ) : (
            <MIFormattedText label="batchPayment.datePlaceholder" />
          )}
        </div>
        <EtaIconContainer>
          <EtaIcon isSelected={date} className="icon-eta-cal" />
        </EtaIconContainer>
      </Section>
    </DatePickerWrapper>
  );

  return (
    <StopPropagationSection
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DatePicker
        id="inlineDatePicker"
        required
        min={new Date()}
        max={getEndOfLifeMaxDate(isFirstWave)}
        date={date}
        disabled={disabled}
        onChange={onChange}
        customInput={customInput}
        onInputClick={() => analytics.trackAction('open-date-picker-from-table')}
        filterDate={(day) => isBusinessDay(day)}
      />
    </StopPropagationSection>
  );
};

const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border: ${({ theme }) => `0.1rem solid ${theme.text.color.label}`};
  border-radius: 0.3rem;
  font-size: 1.4rem;
  padding-left: 0.8rem;
  padding-right: 0.4rem;
  height: 3rem;
  color: ${(props) => props.theme.text.color.label};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.text.weight.normal};
  letter-spacing: -0.04rem;
`;

const StopPropagationSection = styled.div``;

const Section = styled.div<{ isDateSelected: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.isDateSelected && props.theme.text.color.main};
  width: 100%;
  justify-content: space-between;
  line-height: 2rem;
`;

const EtaIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
`;

const EtaIcon = styled.i`
  color: ${(props) => props.theme.text.color.label};
  font-size: 1.5rem;
`;
