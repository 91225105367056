import { useState } from 'react';
import DatePicker from 'src/app/components/form/DatePicker';
import styled, { css } from 'styled-components';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { isBusinessDay, getEndOfLifeMaxDate } from 'src/app/utils/dates';
import { DateFieldType } from 'src/app/utils/types';
import analytics from 'src/app/services/analytics';

export type InlineDatePickerProps = {
  date?: Date | null;
  onChange: (arg0: DateFieldType) => void | Promise<void>;
  errorLabel?: string;
  isFirstWave?: boolean;
};

export const InlineDatePicker = (props: InlineDatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { onChange, date, errorLabel, isFirstWave } = props;

  const customInput = (
    <InlineDatePickerContainer>
      <DatePickerContent>
        <DatePickerIcon>
          <i className="icon-eta-cal" />
        </DatePickerIcon>
        <DatePickerInfoTextContainer>
          <DatePickerText>
            {date && <MIFormattedText label="batchPayment.sidePanel.setDeductionDateEmpty" />}
          </DatePickerText>
          <DatePickerInfoText>
            <MIFormattedText
              label={
                date
                  ? 'batchPayment.sidePanel.dateValue'
                  : 'batchPayment.sidePanel.setDeductionDate'
              }
              values={{ date: <MIFormattedDate date={date} /> }}
            />
          </DatePickerInfoText>
          {errorLabel && (
            <ErrorMessage>
              <i className="icon-warning-icon" />
              <ErrorMessageText>
                <MIFormattedText label={errorLabel} values={{ option: 'date' }} />
              </ErrorMessageText>
            </ErrorMessage>
          )}
        </DatePickerInfoTextContainer>
      </DatePickerContent>
      <DropdownIcon data-testid="date-picker-dropdown-icon" isDropdownOpen={isOpen} />
    </InlineDatePickerContainer>
  );

  return (
    <DatePicker
      id="inlineDatePicker"
      required
      min={new Date()}
      max={getEndOfLifeMaxDate(isFirstWave)}
      date={date}
      onChange={onChange}
      customInput={customInput}
      onInputClick={() => {
        setIsOpen(true);
        analytics.trackAction('open-date-picker');
      }}
      onBlur={() => setIsOpen(false)}
      filterDate={(day) => isBusinessDay(day)}
    />
  );
};

const InlineDatePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6.5rem;
  padding: 1.25rem 2rem 1.25rem 0;
`;

const DatePickerContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DatePickerInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DatePickerIcon = styled.i<{ isBillPaid?: boolean }>`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  > i {
    font-size: 2.4rem;
    color: ${(props) =>
      props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentIcon}
`;

export const DatePickerText = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.2px;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentMethodText}
`;

export const DatePickerInfoText = styled.div<{ isBillPaid?: boolean }>`
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoText}
`;

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: start;
`;

const ErrorMessageText = styled.div`
  padding-left: 0.5rem;
`;

const DropdownIcon = styled.i.attrs({ className: 'icon-next-icon-2' })<{ isDropdownOpen: boolean }>`
  font-size: 1.6rem;
  color: rgba(107, 108, 114, 1);
  font-weight: ${(props) => props.theme.text.weight.bold};
  transform: rotate(90deg);
  position: relative;

  ${(props) =>
    props.isDropdownOpen &&
    css`
      transform: rotate(-90deg);
    `}
`;
