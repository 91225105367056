import EventEmitter from 'eventemitter3';

const event = 'back-button-fired';

const emitter = new EventEmitter();

const removeListener = (fn: () => void) => emitter.removeListener(event, fn);

const addListener = (fn: () => void): (() => void) => {
  emitter.addListener(event, fn);

  return () => removeListener(fn);
};

const handle = (...args) => emitter.emit(event, ...args);

const hasListeners = () => !!emitter.listenerCount(event);

export default {
  hasListeners,
  addListener,
  handle,
};
