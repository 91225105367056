import React, { useEffect, useState } from 'react';
import head from 'lodash/head';

import { NEW_VENDOR_ID } from 'src/app/utils/consts';

import { SelectFieldOption, WizardSelectField } from 'src/app/ui/form/WizardSelectField';

import styled from 'styled-components';
import { theme } from 'src/app/theme/qboTheme';
import { ModelViewField } from 'src/app/ui/form/use-form/useForm';

const SelectVendor = ({
  model,
  initialVendorOptions,
}: {
  model: ModelViewField<string>;
  initialVendorOptions: SelectFieldOption<string>[];
}) => {
  const [vendorOptions, setVendorOptions] = useState<SelectFieldOption<string>[]>([]);

  useEffect(() => {
    if (initialVendorOptions.length) {
      setVendorOptions(initialVendorOptions);
    }
  }, [initialVendorOptions.length]);

  const onCreateNewVendor = (value: string) => {
    const firstVendor = head(vendorOptions);

    if (firstVendor?.id === NEW_VENDOR_ID) {
      setVendorOptions([{ ...firstVendor, label: value }, ...initialVendorOptions]);
    } else {
      setVendorOptions([{ id: NEW_VENDOR_ID, label: value, value }, ...initialVendorOptions]);
    }
  };

  return (
    <WizardSelectField
      label="vendors.select.title"
      placeholder="vendors.select.placeholder"
      createLabel="vendors.select.addInSelect"
      iconColor={theme.colors.primary.opaque}
      options={vendorOptions}
      model={model}
      onCreate={onCreateNewVendor}
      errorMessageIcon={<ErrorIcon />}
      required
    />
  );
};

export { SelectVendor };

const ErrorIcon = styled.i.attrs({
  className: 'icon-warning-icon',
})`
  font-size: 1.6rem;
  margin-right: 0.4rem;
`;
