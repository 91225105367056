export const enum NotificationVariantEnum {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  SUCCESS_FILLED = 'success-filled',
  WARNING = 'warning',
  ERROR_FILLED = 'error-filled',
  HELP = 'help',
  MAIN = 'main',
  DISCOVER = 'discover',
}
