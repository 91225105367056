import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SmartIcon, Tooltip } from '@melio/billpay-design-system';
import { IconContainer } from 'src/app/version-2/pages/batch-bulk/components/InvalidValueIndication/InvalidValueIndication.styles';
import { batchBulkSelectors } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';

export interface InvalidValueIndicationProps {
  label: string;
}

export const InvalidValueIndication: FC<InvalidValueIndicationProps> = ({ label }) => {
  const hasTriedToSubmit: boolean = useSelector(batchBulkSelectors.selectHasTriedToSubmit);

  return hasTriedToSubmit ? (
    <Tooltip label={label}>
      <IconContainer>
        <SmartIcon type="info" />
      </IconContainer>
    </Tooltip>
  ) : null;
};
