import React from 'react';
import { useIntl } from 'react-intl';
import { NotificationCard, NotificationTypeEnum } from '@melio/billpay-design-system';
import { getTableWidth } from 'src/app/version-2/pages/batch-bulk/utils/tableWidth.utils';

import styled, { css } from 'styled-components';

/**
 * Shows notification after applied fee in confirm page
 */
type Props = {
  isBatch?: boolean;
};
const NotificationPromotionsEnded = ({ isBatch }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <StyledCard
      $isBatch={isBatch}
      type={NotificationTypeEnum.INFO}
      subtitle={formatMessage({
        id: 'deliveryMethods.notDefinedInfo.check.appliedFee.notification.promotionsEnded',
      })}
    />
  );
};

const StyledCard = styled(NotificationCard)<{ $isBatch?: boolean }>`
  margin: 0 0.2rem 2rem;

  ${(props) =>
    props.$isBatch &&
    css`
      margin: 1rem auto 0;
      width: 100%;
      max-width: ${getTableWidth()};

      i {
        font-size: 1.85rem;
        margin-left: 0.4rem;
      }
    `}

  .notification-title {
    padding-bottom: 0.4rem;
  }

  a {
    cursor: pointer;
  }
`;

export { NotificationPromotionsEnded };
