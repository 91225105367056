import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

import { selectFundingSourceAction } from 'src/app/redux/payBillWizard/actions';
import { getFundingSources, getOriginPlaidItemId, getOrgId } from 'src/app/redux/user/selectors';
import {
  getPayment,
  getBill,
  getSelectedFundingSource,
  getIsRecurring,
} from 'src/app/redux/payBillWizard/selectors';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import analytics from 'src/app/services/analytics';
import { NavigateType } from 'src/app/utils/types';
import { getBillBalance, getPartialBillId } from 'src/app/utils/bills';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import {
  DELIVERY_TYPE,
  PAYMENT_APPROVAL_STATUS,
  PAYMENT_STATUS,
  CARD_TYPES,
} from 'src/app/utils/consts';
import { isCardExpired } from 'src/app/utils/card';
import { getLayoutHeaderLabel } from 'src/app/pages/bill/utils';
import locations from 'src/app/utils/locations';
import { getLatestPayment, shouldAllowEditByDeliveryType } from 'src/app/utils/payments';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { usePartialPaymentAmount } from 'src/app/pages/bill/pay/hooks/usePartialPaymentAmount';
import { usePartialPaymentsQualification } from 'src/app/pages/bill/hooks/usePartialPaymentsBillQualification';
import { useAmexVerification } from 'src/app/pages/bill/pay/hooks/useAmexVerification';
import { useDebitFee } from 'src/app/pages/bill/pay/hooks/useDebitFee';
import { useVerifyMicroDeposits } from 'src/app/pages/bill/pay/hooks/useVerifyMicroDeposits';
import { useBenefits } from 'src/app/pages/bill/pay/hooks/useBenefits';
import PartialPaymentAmount from 'src/app/pages/bill/pay/components/PartialPaymentAmount';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import {
  WizardStepTitle,
  WizardStepSubTitle,
  WizardFooterContainer,
} from 'src/app/components/layout/QBOWizardElements';
import { useLocationState } from 'src/app/utils/hooks';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import QboFundingSourcesListLayout from 'src/app/components/common/SelectMethods/containers/QboFundingSourcesListLayout';
import VerifyMicroDeposits from 'src/app/components/micro-deposits/VerifyMicroDeposits';
import { devices } from 'src/app/theme/AppDevices';
import { CreditCardBenefitsModal } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitsModal';
import { Footer } from './Footer';
import { NotificationStatus } from './NotificationStatus';
import { InvalidFundingSourceNotificationCard } from './InvalidFundingSourceNotificationCard';
import { useGoAddPage } from '../hooks/navigation/useGoAddPage';
import { useFundingSourceNavigate } from '../hooks/navigation/useFundingSourceNavigate';
import { getVendorType } from 'src/app/utils/vendor-utils';
import { EVENT_PAGE } from 'src/app/version-2/pages/funding-source/model/consts';
import { FeatureFlagsEnum, FundingSourcePageVariantsEnum } from 'src/app/version-2/model/enums';
import { FundingSource, CardAccount } from 'src/app/version-2/model/dtos';
import { shouldSendEvent } from 'src/app/version-2/utils/experimental.util';
import { fundingSourceActions } from 'src/app/version-2/pages/funding-source/modules/fundingSource.slice';
import { paymentsSelectors } from 'src/app/version-2/modules/payments/payments.slice';
import {
  fundingSourcesActions,
  fundingSourcesSelectors,
} from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import { getFeatureFlagStatus } from 'src/app/version-2/utils/featureFlags.utils';

type Props = {
  onPrev: (() => void) | null;
  goExit: () => void;
  setPaymentAmount: (amount: number) => void;
  getUrlAfterFundingSourceStepForRecoveryFlow: () => Promise<string>;
  navigate: NavigateType;
  showCCTooltip?: boolean;
};

export const PayBillFundingSourcePage = ({
  goExit,
  onPrev,
  setPaymentAmount,
  getUrlAfterFundingSourceStepForRecoveryFlow,
  navigate,
  showCCTooltip,
}: Props) => {
  const intl = useIntl();

  const payments = useSelector(paymentsSelectors.selectPayments);
  const { goAddSelectedFundingSource } = useGoAddPage({
    navigate,
    getUrlAfterFundingSourceStepForRecoveryFlow,
  });
  const { onNextFundingSources } = useFundingSourceNavigate({
    navigate,
    getUrlAfterFundingSourceStepForRecoveryFlow,
  });
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const payment = useSelector(getPayment);
  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const fundingSources: FundingSource[] = useSelector(getFundingSources);
  const isFundingSourcesFetched = useSelector(fundingSourcesSelectors.selectFundingSourcesFetched);
  const validFundingSources = useSelector(fundingSourcesSelectors.selectValidFundingSources);
  const originPlaidItemId = useSelector(getOriginPlaidItemId);
  const organizationPreferences = useSelector(getOrganizationPreferences);
  const isRecurring = useSelector(getIsRecurring);
  const [mainFlowExitUrl] = useLocationState('exitUrl');
  const { vendor } = bill;

  const vendorPaymentPreferences = useSelector((state) =>
    vendorsStore.selectors.checkVendorPaymentPreferences.item(state, bill?.vendorId)
  );
  const isVendorEnableCCPayments = isEmpty(vendorPaymentPreferences);
  const partialBillId = getPartialBillId(bill);
  const {
    openVerifyMicrodepositsModal,
    verifyMicrodepositsModalProps,
    shouldShowVerifyMicrodepositsModal: showVerifyMicrodepositsModal,
  } = useVerifyMicroDeposits({
    partialBillId,
    eventPage: EVENT_PAGE,
  });

  const {
    shouldShowBenefitsModal,
    onBenefitsClicked,
    onCloseBenefitsModal,
    benefitsRelevantCreditCard,
  } = useBenefits();
  const onSelectingFundingSource = (newSelectedFundingSource: FundingSource) => {
    analytics.track(EVENT_PAGE, 'change-funding-source', {
      fundingSourceId: newSelectedFundingSource.id,
      partialBillId,
    });

    if (selectedFundingSource?.id === newSelectedFundingSource.id) {
      return onSubmit();
    }

    dispatch(selectFundingSourceAction(newSelectedFundingSource.id));
  };

  const billBalance = getBillBalance(bill, [payment?.id]);
  const { partialBillAmount, validationErrors, validationErrorsValues, editAmount, onChange } =
    usePartialPaymentAmount(payment?.amount?.toString() as string, billBalance);
  const canPartiallyPay = usePartialPaymentsQualification(bill?.qboSyncVersion, payment);
  const allowEdit = canPartiallyPay && shouldAllowEditByDeliveryType(payment?.deliveryMethod);
  const vendorType = getVendorType(vendor);
  const { shouldDisplayAmexVerification, openAmexModal, amexLoading, AmexModal } =
    useAmexVerification();
  const debitFee = useDebitFee();

  useEffect(() => {
    const isInternationalDM = payment.deliveryMethod.deliveryType === DELIVERY_TYPE.INTERNATIONAL;

    analytics.track(EVENT_PAGE, 'vendor-type', {
      has_international_dm: isInternationalDM,
    });

    const isInternationalVendorDisabled =
      isInternationalDM && !organizationPreferences.isEligibleForInternationalPayment;

    if (isInternationalVendorDisabled) {
      historyPush({
        path: locations.Bills.pay.internationalBlocked.url({ orgId, id: bill.id }),
        state: { exitUrl: mainFlowExitUrl },
      });
    } else if (!validFundingSources.length && !isFundingSourcesFetched) {
      dispatch(fundingSourcesActions.fetchFundingSources());
    }
  }, []);

  useEffect(() => {
    if (!bill || !bill?.id || !isFundingSourcesFetched) return;

    const isFundingSourceExperiment = getFeatureFlagStatus(
      FeatureFlagsEnum.FUNDING_SOURCE_EXPERIMENT
    );

    if (shouldSendEvent(isFundingSourceExperiment, validFundingSources, !!payments.length)) {
      dispatch(
        fundingSourceActions.sendEvents({
          isFundingSourceExperiment,
          fundingSources: validFundingSources,
          bill,
          fundingSourceOptions: ['bank-account', 'credit', 'debit'],
          featureFlags: {
            fundingSourceVariant: FundingSourcePageVariantsEnum.CONTROL,
          },
        })
      );
    }
  }, [bill?.id, isFundingSourcesFetched]);

  const onSubmit = async (fundingSource?: FundingSource) => {
    onSetPaymentAmount();

    const fundingSourceToSubmit = fundingSource || selectedFundingSource;

    if (await shouldDisplayAmexVerification(fundingSourceToSubmit, vendor?.id)) {
      openAmexModal({
        vendorName: vendor?.companyName,
        vendorId: vendor?.id,
        bill,
      });

      return;
    }

    if (debitFee.shouldDisplayModal(fundingSourceToSubmit)) {
      debitFee.openModal();

      return;
    }

    onNextFundingSources();
  };

  const onAddSelectedFundingSource = (value: string) => {
    onSetPaymentAmount();
    goAddSelectedFundingSource(value);
  };

  const onSetPaymentAmount = () => {
    const amount = Number(convertCurrencyToNumber(partialBillAmount));
    const paymentAmount = amount > billBalance || !amount ? payment?.amount : amount;

    setPaymentAmount(paymentAmount as number);
  };
  const onBenefitsButtonClicked = async () => {
    if (benefitsRelevantCreditCard) {
      if (await shouldDisplayAmexVerification(benefitsRelevantCreditCard, vendor?.id)) {
        openAmexModal({
          vendorName: vendor?.companyName,
          vendorId: vendor?.id,
          bill,
        });

        return;
      }

      onSelectingFundingSource(benefitsRelevantCreditCard);

      onSubmit(benefitsRelevantCreditCard);

      return;
    }

    onAddSelectedFundingSource(CARD_TYPES.CREDIT);

    onCloseBenefitsModal();
  };

  const filteredFundingSources = fundingSources.filter(
    (fs) =>
      !(
        fs.fundingType === 'card' &&
        (!fs.isVerified || isCardExpired(fs.cardAccount as CardAccount))
      )
  );

  const { companyName } = vendor;
  const headerValues = {
    amount: <MIFormattedCurrency value={payment?.amount ?? bill.balance} />,
    companyName,
  };
  const titleValues = {
    companyName: <CompanyNameText>{companyName}</CompanyNameText>,
  };

  const formattedBillBalance = intl.formatNumber(billBalance, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const lastPayment = getLatestPayment(bill?.payments);
  const originFundingSourceId = lastPayment?.fundingSourceId;
  const showInvalidFundingSourceNotification =
    lastPayment?.status === PAYMENT_STATUS.FAILED &&
    lastPayment?.approvalDecisionStatus !== PAYMENT_APPROVAL_STATUS.DECLINED &&
    originFundingSourceId === payment?.fundingSourceId &&
    (!originPlaidItemId || originPlaidItemId === payment?.fundingSource?.plaidAccount?.plaidItemId);

  const isCtaDisabled = !selectedFundingSource || amexLoading || !isEmpty(validationErrors);
  const isCtaShown = !isEmpty(fundingSources);

  return (
    <StyledQBOLayoutPage
      headerLabel={getLayoutHeaderLabel({
        isRecurring,
        deliverFailureData: payment?.deliverFailureData,
      })}
      headerLabelValues={headerValues}
      goExit={goExit}
      onPrev={onPrev as () => void | null}
      relativeStep={1 / 5}
      footer={
        <Footer
          shouldShowCta={isCtaShown}
          shouldShowSecurityDetails
          onSubmit={onSubmit}
          isProcessing={amexLoading}
          isDisabled={isCtaDisabled}
        />
      }
      qboFooter={<QBOFooterContainer hasBorder={false} />}
    >
      <WizardStepTitle>
        <MIFormattedText
          label="bills.pay.fundingSource.partialPayment.title"
          values={titleValues}
        />
      </WizardStepTitle>

      <PartialPaymentAmount
        billBalance={formattedBillBalance}
        partialBillAmount={partialBillAmount}
        validationErrors={validationErrors}
        validationErrorsValues={validationErrorsValues}
        editAmount={editAmount}
        onChange={onChange}
        canPartiallyPay={allowEdit}
      />

      <StyledNotificationCard />
      {showInvalidFundingSourceNotification && (
        <StyledInvalidFSNotificationCard dueDate={bill.dueDate as unknown as Date} />
      )}

      <WizardStepSubTitle>
        <MIFormattedText label="bills.pay.fundingSource.partialPayment.subtitle" />
      </WizardStepSubTitle>

      <QboFundingSourcesListLayout
        value={selectedFundingSource}
        fundingSources={filteredFundingSources}
        onChange={onSelectingFundingSource}
        onAddMethod={onAddSelectedFundingSource}
        onVerifyClicked={openVerifyMicrodepositsModal}
        onBenefitsClicked={onBenefitsClicked}
        isVendorEnableCCPayments={isVendorEnableCCPayments}
        vendorType={vendorType}
        debitFee={debitFee.fee}
        showCCTooltip={showCCTooltip}
        eventSource="single-payment"
        billIds={[bill.id]}
      />

      {showVerifyMicrodepositsModal && (
        <VerifyMicroDeposits
          {...verifyMicrodepositsModalProps}
          fundingSourceId={verifyMicrodepositsModalProps.key}
        />
      )}
      <AmexModal onNext={onNextFundingSources} />
      <debitFee.Modal onNext={onNextFundingSources} />
      {shouldShowBenefitsModal && (
        <CreditCardBenefitsModal
          onButtonClick={onBenefitsButtonClicked}
          onCloseClick={onCloseBenefitsModal}
          isLoading={amexLoading}
          analyticsProperties={{ bill, vendorId: vendor?.id }}
        />
      )}
    </StyledQBOLayoutPage>
  );
};

const commonSpacing = `
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  ${WizardStepTitle} {
    margin-bottom: 0.8rem;
  }

  ${css`
    ${WizardStepSubTitle} {
      ${commonSpacing} @media ${devices.mobile}, ${devices.phablet} {
        margin-bottom: 2rem;
      }
    }
  `}
  ${WizardFooterContainer} {
    margin-top: 0;
  }
`;

const StyledNotificationCard = styled(NotificationStatus)`
  ${commonSpacing}
`;

const StyledInvalidFSNotificationCard = styled(InvalidFundingSourceNotificationCard)`
  ${commonSpacing}
`;

const CompanyNameText = styled.span`
  color: ${(props) => props.theme.text.color.darkGrey};
`;
