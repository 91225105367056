import React, { RefObject } from 'react';
import { OrderedSet, RecordOf } from 'immutable';
import ListLayoutPage, { ListPage, ViewSinglePage } from 'src/app/components/layout/ListLayoutPage';
import { PageProps } from 'src/app/hoc/withListContainer';
import { BillsGroup, BillType } from 'src/app/utils/types';
import ViewBill from 'src/app/pages/bill/components/QBMViewBill';
import BillsList from 'src/app/components/list/BillsList';
import { Option } from 'src/app/components/common/MISingleSelect';
import RightPanelPlaceholder from 'src/app/components/onboarding/RightPanelPlaceholder';

type Props = {
  bills: OrderedSet<RecordOf<BillType>>;
  groupedBills: BillsGroup[];
  totalCount: number;
  vendors: Option[];
  intuitAccountsOptions: Option[];
  onItemRemove: (index: number) => void;
  addNewItem: (description: string, amount: number) => void;
  listViewRef: RefObject<any>;
  goCreateBill: () => void;
  onChangeAttachment: (file: File) => void;
  onDeleteAttachment: () => void;
  onFieldChange: (Object) => void;
  fileStorageUrl?: string;
  filePreviewUrls?: string[];
  validationErrors: Record<string, any>;
  isLoading: boolean;
  isListLoading: boolean;
  isSingleLoading: boolean;
  isUploading: boolean;
  billId: string;
  updateIsClosedBillsZeroStatePreference: () => void;
  billsCount: number;
  reloadBills: () => void;
  eventPage?: string;
  backPath: string;
} & PageProps;

const BillsListPage = ({
  bills,
  groupedBills,
  totalCount,
  vendors,
  filters,
  isLoading,
  updateIsClosedBillsZeroStatePreference,
  goCreateBill,
  onChangeAttachment,
  onDeleteAttachment,
  isListLoading,
  isSingleLoading,
  onFieldChange,
  validationErrors,
  intuitAccountsOptions,
  onItemRemove,
  addNewItem,
  fileStorageUrl,
  filePreviewUrls,
  isUploading,
  listViewRef,
  billId,
  billsCount,
  reloadBills,
  eventPage,
  backPath,
}: Props) => {
  const renderRightBlockContent = () => {
    if (billId) {
      return (
        <ViewBill
          billId={billId}
          vendors={vendors}
          isLoading={isSingleLoading}
          onChangeAttachment={onChangeAttachment}
          onDeleteAttachment={onDeleteAttachment}
          onFieldChange={onFieldChange}
          validationErrors={validationErrors}
          intuitAccountsOptions={intuitAccountsOptions}
          onItemRemove={onItemRemove}
          addNewItem={addNewItem}
          fileStorageUrl={fileStorageUrl}
          filePreviewUrls={filePreviewUrls}
          isUploading={isUploading}
          reloadBills={reloadBills}
          backPath={backPath}
        />
      );
    }

    return <RightPanelPlaceholder isLoading={isLoading} />;
  };

  return (
    <ListLayoutPage
      listViewRef={listViewRef}
      eventPage={eventPage}
      title="bills.title"
      isLoading={isLoading}
    >
      <ListPage>
        <BillsList
          bills={bills}
          groupedBills={groupedBills}
          totalCount={totalCount}
          pathname="bills"
          goCreateBill={goCreateBill}
          listItemBaseSearch={`${filters.search ? `search=${filters.search}` : ''}`}
          listBaseSearch={`${filters.search ? `search=${filters.search}` : ''}`}
          filters={filters}
          isLoading={isListLoading}
          updateIsClosedBillsZeroStatePreference={updateIsClosedBillsZeroStatePreference}
          billsCount={billsCount}
        />
      </ListPage>
      <ViewSinglePage>{renderRightBlockContent()}</ViewSinglePage>
    </ListLayoutPage>
  );
};

BillsListPage.defaultProps = {};

export default BillsListPage;
