import filter from 'lodash/filter';
import api from 'src/app/services/api/bills';
import { createRestfulSlice, hashListKey } from 'src/app/helpers/redux/createRestfulSlice';
import { createListSlice } from 'src/app/helpers/redux/restListSlice';

import omit from 'lodash/omit';
import { qbDashboardSelectionSlice } from './qb-dashboard-selection-slice';

import { name } from './consts';
import { qbDashboardPartialPaymentsAmountSlice } from './partial-payments-amounts';

import { viewPaymentUrlsSlice } from './qb-dashboard-urls-slice';
import { markAsUnpaidStatusSlice } from './mark-as-unpaid-status';
import { usageStatusSlice } from './usage-status';

const dashboardHashListKey = (payload) =>
  hashListKey(omit(payload, ['isPartialPaymentsEnabled', 'silent']));

const list = createListSlice({
  storeName: name,
  api: ({ isPartialPaymentsEnabled, silent, ...params }) =>
    isPartialPaymentsEnabled ? api.getNewQBDashboardList(params) : api.getQBDashboardList(params),
  actionName: 'list',
  listHashFunc: dashboardHashListKey,
});

export const qbDashboardListItemsStore = createRestfulSlice({
  name,
  api: {},
  slices: {
    qbDashboardSelection: qbDashboardSelectionSlice,
    partialPaymentsAmounts: qbDashboardPartialPaymentsAmountSlice,
    viewPaymentUrls: viewPaymentUrlsSlice,
    usageStatus: usageStatusSlice,
    markAsUnpaidStatus: markAsUnpaidStatusSlice,
    list,
  },
  selectors: {
    byId: (id) => (state) => state[name]?.byId[id],
    getByIds: (ids) => (state) => filter(state[name].byId, (bill) => ids.includes(bill.id)),
  },
});
