import { Link } from '@melio/billpay-design-system';
import { ReactComponent as Icon } from 'src/app/images/qbo/exclamation-mark-icon.svg';
import { MIFormattedText, useSiteContext } from 'src/app/version-2/externals';
import { Container, IconContainer, Text } from './Agreement.styles';

export const Agreement = () => {
  const site = useSiteContext();

  return (
    <Container>
      <IconContainer>
        <Icon width="2.4rem" height="2.4rem" />
      </IconContainer>
      <Text>
        <MIFormattedText
          label="vendors.upgradePayment.landing.agreement.base"
          values={{
            termsOfService: (
              <Link
                href={site.config.agreementLinks.termsOfService}
                isExternal
                textDecoration="none !important"
                color="blue.500"
              >
                <MIFormattedText label="vendors.upgradePayment.landing.agreement.termsOfService" />
              </Link>
            ),
            privacyPolicy: (
              <Link
                href={site.config.agreementLinks.privacyPolicy}
                isExternal
                textDecoration="none !important"
                color="blue.500"
              >
                <MIFormattedText label="vendors.upgradePayment.landing.agreement.privacyPolicy" />
              </Link>
            ),
            privacyStatement: (
              <Link
                href={site.config.agreementLinks.intuitPrivacyPolicy}
                isExternal
                textDecoration="none !important"
                color="blue.500"
              >
                <MIFormattedText label="vendors.upgradePayment.landing.agreement.privacyStatement" />
              </Link>
            ),
          }}
        />
      </Text>
    </Container>
  );
};
