import React from 'react';
import styled from 'styled-components';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { FULL_STORY_MASK_RULE_CLASS, DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import { SecurityDetails } from 'src/app/components/common/SecurityDetails';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { TabapayMigrationModal } from 'src/app/components/tabapay/TabapayMigrationModal';
import { envApi } from 'src/app/version-2/api/env';
import { useBreak } from 'src/app/hoc';
import { devices } from 'src/app/theme/AppDevices';
import { isSandboxIndicator } from 'src/app/utils/funding-sources';
import { AreaLoader } from '@melio/billpay-design-system';

type Props = {
  goNext: () => void;
  goExit: () => void;
  onError: () => void;
  loadIframe: () => void;
  isLoading: boolean;
  errorCode: string | null | undefined;
  onHideErrorMessageAlert: () => void;
};

const AddCardAccountsPage = ({
  goExit,
  goNext,
  isLoading,
  loadIframe,
  errorCode,
  onHideErrorMessageAlert,
  onError,
}: Props) => {
  const site = useSiteContext();
  const { isDesktop, isPhablet } = useBreak();
  const iFrameWidth = isDesktop || isPhablet ? '700px' : '200px';
  const iFrameHeight = site.theme?.pages?.AddCardAccountsPage?.iFrameHeight || '470px';
  const isSandboxIndicatorShown = isSandboxIndicator(site);

  return (
    <site.components.StepLayout
      goExit={goExit}
      onNext={goNext}
      isLoading={isLoading}
      title="onboarding.fundingSources.card.iframeTitle"
      subtitle="onboarding.fundingSources.card.iframeSubTitle"
      innerSize={75}
      isHideActions
      hideHeader
      isSandboxIndicatorShown={isSandboxIndicatorShown}
    >
      {errorCode && (
        <QBODialog
          type={DIALOG_TYPE.ALERT}
          variant={DIALOG_VARIANTS.ERROR}
          title="onboarding.fundingSources.card.notSupportedTitle"
          subtitle={`server.${errorCode}`}
          okButtonText="dialogs.buttons.close"
          cancelButtonText="dialogs.buttons.close"
          onOkAction={onHideErrorMessageAlert}
          onCancelAction={onHideErrorMessageAlert}
        />
      )}
      <TabapayMigrationModal goExit={goExit} />
      <IframeContainer className={FULL_STORY_MASK_RULE_CLASS}>
        {isLoading && (
          <LoaderContainer>
            <AreaLoader />
          </LoaderContainer>
        )}
        <TabapayIframe
          isLoading={isLoading}
          onLoad={loadIframe}
          title="taba pay SSO"
          id="sso"
          onError={onError}
          src={envApi.getConfig().services.tabapay.url}
          frameBorder="0"
          scrolling="no"
          width={iFrameWidth}
          height={iFrameHeight}
        />
        <SecurityDetailsContainer>
          <SecurityDetails />
        </SecurityDetailsContainer>
      </IframeContainer>
    </site.components.StepLayout>
  );
};

AddCardAccountsPage.defaultProps = {};

export default AddCardAccountsPage;

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.LoaderContainer}
`;
const TabapayIframe = styled.iframe<{ isLoading?: boolean }>`
  opacity: ${(props) => (props.isLoading ? '0' : '1')};
  width: 1px;
  min-width: 100%;
  width: 100%;
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.TabapayIframe}
`;

const IframeContainer = styled.div`
  position: relative;
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.IframeContainer}
`;

const SecurityDetailsContainer = styled.div`
  width: 45rem;
  margin: 0 auto;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 34rem;
  }
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.SecurityDetailsContainer}
`;
