import React from 'react';
import { Link, Popover, SmartIcon } from '@melio/billpay-design-system';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import {
  DatePicker,
  isBusinessDay,
  MIFormattedDate,
  MIFormattedText,
} from 'src/app/version-2/externals';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';
import {
  DeductionDateDueDate,
  DeductionDateDueDateItem,
  DeductionDateDueDateLabel,
  DeductionDateDueDateValue,
  DeductionDateFormattedDateContainer,
  DeductionDatePickerWrapper,
  DeductionDateSelection,
  DeliveryDateCircle,
  ExpandCollapseMenuIcon,
  NotificationCardContainer,
  NotificationCardStyled,
} from './renderers.styles';

import { Moment } from 'moment';

interface DeductionDateRendererProps {
  id: string;
  dueDate?: Date | null;
  minScheduledDate: Date | undefined;
  maxScheduledDate?: Date | undefined;
  deductionDate: Date | null;
  isOpen: boolean;
  onChange: (value) => void;
  onClick: () => void;
  onClose: () => void;
  renderers?: any;
  calendarIcon?: boolean;
  disabled?: boolean;
  removeFooter?: boolean;
  showEOLNotification?: boolean;
  eolNotificationLabel: string;
  goLearnMore: () => void;
  onMonthChange?: (month: Moment) => void;
}

export const DeductionDateRenderer = ({
  id,
  dueDate,
  minScheduledDate,
  isOpen,
  onClose,
  onChange,
  onClick,
  deductionDate,
  renderers,
  calendarIcon = false,
  disabled = false,
  removeFooter = false,
  maxScheduledDate,
  showEOLNotification,
  eolNotificationLabel,
  goLearnMore,
  onMonthChange,
}: DeductionDateRendererProps) => (
  <div data-testid="deduction-date-cell">
    <Popover isOpen={isOpen} arrowColor="#fff0" onClose={onClose} trigger="click">
      <Popover.PopoverTrigger>
        <DeductionDateSelection
          onClick={onClick}
          data-testid="batch-bulk-deduction-date"
          as={renderers?.DateSelection}
          aria-disabled={disabled}
        >
          <DeductionDateFormattedDateContainer>
            {calendarIcon && <SmartIcon type="calendar" />}
            {deductionDate ? <MIFormattedDate date={deductionDate} /> : null}
          </DeductionDateFormattedDateContainer>
          <ExpandCollapseMenuIcon $isOpen={isOpen}>
            <SmartIcon type="arrow-dropdown" />
          </ExpandCollapseMenuIcon>
        </DeductionDateSelection>
      </Popover.PopoverTrigger>
      <Popover.PopoverContent>
        <DeductionDatePickerWrapper data-testid="batch-bulk-date-picker">
          {isOpen ? (
            <DatePicker
              id={`${deductionDate}-${id}`}
              onChange={onChange}
              date={deductionDate}
              min={minScheduledDate}
              max={maxScheduledDate}
              size={TextInputSizeEnum.INLINE}
              inline
              withBottomElement
              filterDate={isBusinessDay}
              dueDate={dueDate}
              onMonthChange={onMonthChange}
            />
          ) : null}
          {!removeFooter ? (
            <DeductionDateDueDate>
              <DeductionDateDueDateItem>
                <DeliveryDateCircle />
                <div>
                  <DeductionDateDueDateLabel>
                    <MIFormattedText label="batchBulkPage.renderers.deductionDate.dueDate" />
                  </DeductionDateDueDateLabel>
                  <DeductionDateDueDateValue>
                    <MIFormattedDate date={dueDate} />
                  </DeductionDateDueDateValue>
                </div>
              </DeductionDateDueDateItem>
              <DeductionDateDueDateItem>
                <DeliveryDateCircle isDeductionDate />
                <div>
                  <DeductionDateDueDateLabel>
                    <MIFormattedText label="batchBulkPage.renderers.deductionDate.deductedDate" />
                  </DeductionDateDueDateLabel>
                  <DeductionDateDueDateValue>
                    <MIFormattedDate date={deductionDate} />
                  </DeductionDateDueDateValue>
                </div>
              </DeductionDateDueDateItem>
            </DeductionDateDueDate>
          ) : null}
          {showEOLNotification ? (
            <NotificationCardContainer>
              <NotificationCardStyled
                type={NOTIFICATION_CARD_TYPES.INFO}
                subtitle={{
                  label: eolNotificationLabel,
                  values: {
                    learnMoreLink: (...chunks) => (
                      <Link onClick={goLearnMore} cursor="pointer" isExternal>
                        {React.Children.toArray(chunks)}
                      </Link>
                    ),
                  },
                }}
              />
            </NotificationCardContainer>
          ) : null}
        </DeductionDatePickerWrapper>
      </Popover.PopoverContent>
    </Popover>
  </div>
);
