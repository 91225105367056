import styled from 'styled-components';

import { PAYMENT_STATUS } from 'src/app/utils/consts';

export default styled.li<{ $status?: string; $isRefundFlow?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.text.fontType.hint};
  padding-bottom: 1rem;
  gap: 5.5rem;

  .current {
    color: ${(props) => props.theme.text.color.darkGrey};
    display: inline-block;
    font-weight: initial;
  }
  .status {
    color: ${(props) => props.theme.text.color.main};
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-weight: ${(props) => props.theme.text.weight.normal};
    ${(props) =>
      props.$status === PAYMENT_STATUS.FAILED &&
      !props.$isRefundFlow &&
      `color: ${props.theme.colors.ds.red[200]}`};

    .date {
      color: ${(props) => props.theme.text.color.main};
    }
  }
  .button {
    background: transparent;
    border: 0;
    padding: 0;
    color: ${(props) => props.theme.text.color.link};
    font-weight: initial;
    text-decoration: none;
    cursor: pointer;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;
