import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { selectFundingSourceAction } from 'src/app/redux/payBillWizard/actions';
import { getFundingSources, getOrgId } from 'src/app/redux/user/selectors';
import {
  getFeeFundingSourceFlow,
  getSelectedFundingSource,
} from 'src/app/redux/payBillWizard/selectors';
import analytics from 'src/app/services/analytics';
import { NavigateType } from 'src/app/utils/types';
import { getPartialBillId } from 'src/app/utils/bills';
import { MIFormattedText } from 'src/app/utils/formatting';
import { isCardExpired } from 'src/app/utils/card';
import { useAmexVerification } from 'src/app/pages/bill/pay/hooks/useAmexVerification';
import { useDebitFee } from 'src/app/pages/bill/pay/hooks/useDebitFee';
import { useVerifyMicroDeposits } from 'src/app/pages/bill/pay/hooks/useVerifyMicroDeposits';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import {
  WizardStepTitle,
  WizardStepSubTitle,
  WizardInner,
} from 'src/app/components/layout/QBOWizardElements';
import QboFundingSourcesListLayout from 'src/app/components/common/SelectMethods/containers/QboFundingSourcesListLayout';
import VerifyMicroDeposits from 'src/app/components/micro-deposits/VerifyMicroDeposits';
import { devices } from 'src/app/theme/AppDevices';
import locations from 'src/app/utils/locations';
import { useHistory, useLocation } from 'react-router-dom';
import { getOrgFeeFundingSource } from 'src/app/redux/organization/selectors';
import { ChangeMethodModal } from 'src/app/pages/settings/components/fee/components/ChangeMethodModal';
import { useModal } from 'src/app/helpers/react/useModal';
import { FEE_FLOWS } from 'src/app/redux/payBillWizard/types';
import { QBOHeaderContainer } from 'src/app/components/layout/QBOHeader';
import { FundingSource, CardAccount } from 'src/app/version-2/model/dtos';
import { useGoAddPage } from '../../../../bill/pay/hooks/navigation/useGoAddPage';
import { Footer } from '../../../../bill/pay/PayBillFundingSourcePageContainer/Footer';

type Props = {
  onPrev: (() => void) | null;
  goExit: () => void;
  getUrlAfterFundingSourceStepForRecoveryFlow: () => Promise<string>;
  navigate: NavigateType;
};

const eventPage = 'fee-funding-source-page';
const settingsEventPage = 'settings-billing';
const BASE_PREFIX = 'bills.pay.feeFundingSourcePage';

export const FeeFundingSourcePage = ({
  goExit,
  onPrev,
  getUrlAfterFundingSourceStepForRecoveryFlow,
  navigate,
}: Props) => {
  const history = useHistory();
  const location = useLocation<{ currentFeeFundingSourceId: number; newUnverifiedBank: number }>();
  const { goAddSelectedFundingSource } = useGoAddPage({
    navigate,
    getUrlAfterFundingSourceStepForRecoveryFlow,
  });
  const flow = useSelector(getFeeFundingSourceFlow);
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const orgFeeFundingSource = useSelector(getOrgFeeFundingSource);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const fundingSources = useSelector(getFundingSources);
  const isManagedByFirm = useMemo(
    () => Boolean(orgFeeFundingSource?.managedByOrganizationId),
    [orgFeeFundingSource]
  );
  const areTheSame = useMemo(
    () => Boolean(selectedFundingSource?.id === location?.state?.currentFeeFundingSourceId),
    [selectedFundingSource, location]
  );
  const isDisabled = useMemo(
    () => !selectedFundingSource || areTheSame,
    [areTheSame, selectedFundingSource]
  );

  const partialBillId = getPartialBillId({});
  const {
    openVerifyMicrodepositsModal,
    verifyMicrodepositsModalProps,
    shouldShowVerifyMicrodepositsModal: showVerifyMicrodepositsModal,
  } = useVerifyMicroDeposits({
    partialBillId,
    eventPage,
  });

  const onSelectingFundingSource = (selectedFundingSource: FundingSource) => {
    analytics.track(eventPage, 'change-funding-source', {
      fundingSourceId: selectedFundingSource.id,
    });
    dispatch(selectFundingSourceAction(selectedFundingSource.id));
  };

  const { amexLoading } = useAmexVerification();
  const debitFee = useDebitFee();

  useEffect(() => {
    if (location?.state?.newUnverifiedBank) {
      dispatch(selectFundingSourceAction(location?.state?.newUnverifiedBank));

      return;
    }

    if (orgFeeFundingSource) {
      dispatch(selectFundingSourceAction(orgFeeFundingSource.fundingSourceId));
    }
  }, []);

  useEffect(() => {
    analytics.track(eventPage, 'select-fee-funding-source');
  }, []);

  const redirectToCompletePage = () => {
    history.push(locations.Onboarding.fundingSources.fee.checks.complete.url({ orgId }), {
      selectedFundingSourceId: selectedFundingSource.id,
      backFromBilling: true,
    });
  };
  const onModalSubmit = () => {
    analytics.track(settingsEventPage, 'change-payment-method', {
      paymentMethodId: selectedFundingSource.id,
      paymentMethodType: selectedFundingSource.fundingType,
    });

    redirectToCompletePage();
  };

  const onModalDismiss = () => {
    analytics.track(settingsEventPage, 'change-payment-method-cancel', {
      paymentMethodId: selectedFundingSource.id,
      paymentMethodType: selectedFundingSource.fundingType,
    });
  };

  const [ChangeModalComponent, showModal] = useModal(ChangeMethodModal, {
    onClick: onModalSubmit,
    dismiss: onModalDismiss,
  });

  const onSubmit = async () => {
    if (flow === FEE_FLOWS.CHANGE && isManagedByFirm) {
      analytics.track(settingsEventPage, 'change-payment-method-modal-shown', {
        paymentMethodId: selectedFundingSource.id,
        paymentMethodType: selectedFundingSource.fundingType,
      });

      showModal();

      return;
    }

    analytics.track(settingsEventPage, 'payment-method-continue', {
      paymentMethodId: selectedFundingSource.id,
      paymentMethodType: selectedFundingSource.fundingType,
    });

    redirectToCompletePage();
  };

  const onAddSelectedFundingSource = (value: string) => {
    goAddSelectedFundingSource(value, true);
  };

  const filteredFundingSources = fundingSources.filter(
    ({ cardAccount }) => !isCardExpired(cardAccount as CardAccount)
  );

  const subtitleText = orgFeeFundingSource?.managedByOrganizationId
    ? `${BASE_PREFIX}.firmSubtitle`
    : `${BASE_PREFIX}.smbSubtitle`;

  return (
    <>
      {ChangeModalComponent}
      <StyledQBOLayoutPage
        {...{ goExit, onPrev }}
        footer={
          <Footer
            buttonLabel={`${BASE_PREFIX}.ctaButton`}
            shouldShowCta
            onSubmit={onSubmit}
            isProcessing={amexLoading}
            isDisabled={isDisabled}
          />
        }
      >
        {flow === FEE_FLOWS.ADD && (
          <>
            <StyledWizardStepTitleWithoutSubTitle>
              <MIFormattedText label={`${BASE_PREFIX}.title`} />
            </StyledWizardStepTitleWithoutSubTitle>
            <WizardStepSubTitle>
              <MIFormattedText label={subtitleText} />
            </WizardStepSubTitle>
          </>
        )}

        {flow !== FEE_FLOWS.ADD && (
          <StyledWizardStepTitle>
            <MIFormattedText label={`${BASE_PREFIX}.title`} />
          </StyledWizardStepTitle>
        )}

        <QboFundingSourcesListLayout
          value={selectedFundingSource}
          isBillingFeeFlow
          fundingSources={filteredFundingSources}
          onChange={onSelectingFundingSource}
          onAddMethod={onAddSelectedFundingSource}
          onVerifyClicked={openVerifyMicrodepositsModal}
          debitFee={debitFee.fee}
        />

        {showVerifyMicrodepositsModal && (
          <VerifyMicroDeposits
            {...verifyMicrodepositsModalProps}
            fundingSourceId={verifyMicrodepositsModalProps.key}
          />
        )}
      </StyledQBOLayoutPage>
    </>
  );
};

const commonSpacing = `
  margin-top: 0.8rem;
  margin-bottom: 4rem;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const StyledQBOLayoutPage = styled(QBOLayoutPage)<{
  isCentered?: boolean;
  goExit?: any;
  onPrev?: any;
}>`
  ${QBOHeaderContainer} {
    @media ${devices.mobile} {
      background: none;
    }
  }
  ${WizardStepTitle} {
    margin-bottom: 0.8rem;
  }

  ${WizardInner} {
    @media ${devices.desktop}, ${devices.tablet} {
      justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
    }
  }

  ${css`
    ${WizardStepSubTitle} {
      ${commonSpacing}

      @media ${devices.mobile}, ${devices.phablet} {
        margin-bottom: 2rem;
      }
    }
  `}
`;

const StyledWizardStepTitle = styled(WizardStepTitle)`
  &&& {
    margin-bottom: 4rem;
  }
`;

const StyledWizardStepTitleWithoutSubTitle = styled(WizardStepTitle)`
  &&& {
    margin-bottom: 0;
  }
`;
