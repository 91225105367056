import { Box, SmartIcon } from '@melio/billpay-design-system';
import { goBackBtn } from './BackButton.styles';

interface BackButtonProps {
  onPrev?: (() => void) | null;
}

export const BackButton = ({ onPrev }: BackButtonProps) => {
  const handlePrevClick = () => {
    if (onPrev) {
      onPrev();
    }
  };

  return (
    <Box onClick={handlePrevClick} data-testid="previous-icon" __css={goBackBtn}>
      <SmartIcon type="arrow-back" size="2.4rem" />
    </Box>
  );
};
