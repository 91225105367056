import styled from 'styled-components';
import { Button } from '@melio/billpay-design-system';

export const DrawerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SupportTeamLabel = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2};
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  margin-top: ${({ theme }) => theme.sizes.ds.sm};
`;

export const StartChatButton = styled(Button)`
  margin-top: ${({ theme }) => theme.sizes.ds.lg};
`;

export const ChatIcon = styled.i`
  font-size: 2rem;
  margin-right: ${({ theme }) => theme.sizes.ds.sm};
  margin-bottom: ${({ theme }) => theme.sizes.ds.xs};
`;
