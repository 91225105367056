import * as React from 'react';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import { WizardFormColumn } from 'src/app/components/layout/WizardElements';
import MIEditFormControl from 'src/app/components/common/MIEditFormControl';
import MIViewFormControl from 'src/app/components/common/MIViewFormControl';
import { FormTypeEnum, ScreenModeEnum } from 'src/app/version-2/model/enums';

type Props = {
  formControls: (Record<string, any> | Record<string, any>[])[];
  mode?: ScreenModeEnum;
  formType?: FormTypeEnum;
};

const renderFormControl = (formControl, mode, formType) => {
  if (formControl.isHide) {
    return null;
  }

  return mode === ScreenModeEnum.EDIT ? (
    <MIEditFormControl key={formControl.id} formControl={formControl} formType={formType} />
  ) : (
    <MIViewFormControl key={formControl.id} formControl={formControl} />
  );
};

const MIForm = ({
  formControls,
  mode = ScreenModeEnum.EDIT,
  formType = FormTypeEnum.WIZARD,
}: Props) => (
  <>
    {formControls.map((formControl) => {
      if (isArray(formControl)) {
        const rowKeyId = formControl.reduce(
          (result, nestedFormControl) => `${result} ${nestedFormControl.id}`,
          ''
        );

        return (
          <RowContainer key={rowKeyId}>
            {formControl.map((nestedFormControl) => (
              <WizardFormColumn key={nestedFormControl.id}>
                {renderFormControl(nestedFormControl, mode, formType)}
              </WizardFormColumn>
            ))}
          </RowContainer>
        );
      }

      return renderFormControl(formControl, mode, formType);
    })}
  </>
);

const RowContainer = styled.div`
  display: flex;
`;

export default MIForm;
