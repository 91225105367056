import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  GoogleCombinedAddressType,
  FieldType,
  CompanyInfoType,
  UserContextType,
} from 'src/app/utils/types';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { devices } from 'src/app/theme/AppDevices';
import { COMPANY_FORM_PAGE, DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import { getErrorCode } from 'src/app/redux/payBillWizard/selectors';
import { resetErrorAction } from 'src/app/redux/payBillWizard/actions';
import failedToLoad from 'src/app/images/qbo/failed-to-load-002-icon.png';
import analytics from 'src/app/services/analytics';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { AddressNameTypeEnum, TaxIdEnum } from 'src/app/version-2/model/enums';
import QBOLegalInfoForm from './QBOLegalInfoForm';

type Props = {
  legalCompanyName: string;
  companyName: string;
  taxId?: string;
  taxIdType?: TaxIdEnum;
  validationErrors: Record<string, any>;
  onLegalInfoChange: (field: FieldType) => void;
  isLoading: boolean;
  onAddressChange: (
    address: GoogleCombinedAddressType,
    type?: (typeof AddressNameTypeEnum)[keyof typeof AddressNameTypeEnum]
  ) => void;
  address: GoogleCombinedAddressType;
  onPrev?: () => void;
  goExit: () => void;
  handleOnSubmit: () => void;
  contactFirstName: string;
  contactLastName: string;
  dateOfBirth?: string;
  phone?: string;
  companyAddress: GoogleCombinedAddressType;
  openLegalAddressInput?: boolean;
  companyInfo: CompanyInfoType;
  profile?: UserContextType;
  texts?: {
    title: string;
    subtitle: string;
    button: string;
    loading: string;
  };
  isInternational?: boolean;
  ErrorComponent?: React.ReactNode;
};

const PayBillCompleteLegalInfoPage = ({
  legalCompanyName,
  taxId,
  taxIdType,
  address,
  isLoading,
  goExit,
  onPrev,
  validationErrors,
  onLegalInfoChange,
  onAddressChange,
  handleOnSubmit,
  companyName,
  contactFirstName,
  contactLastName,
  dateOfBirth,
  companyAddress,
  openLegalAddressInput,
  companyInfo,
  profile,
  phone,
  texts,
  isInternational,
  ErrorComponent,
}: Props) => {
  const errorCode = useSelector(getErrorCode);
  const intl = useIntl();
  const dispatch = useDispatch();

  const hideErrorAlert = () => {
    analytics.track('legal-info', 'hide-error-alert');
    dispatch(resetErrorAction());
  };

  if (errorCode) {
    analytics.track('bill-error', 'payment-cannot-be-approved');

    return (
      <ErrorPage
        illustration={failedToLoad}
        title="bills.pay.confirm.error.approvingPaymentTitle"
        subtitle="bills.pay.confirm.error.approvingPaymentSubtitle"
        buttonAction={goExit}
        buttonLabel="bills.pay.confirm.error.approvingPaymentCTA"
      />
    );
  }

  return (
    <QBOLayoutPage
      title={
        isLoading
          ? texts?.loading || 'bills.pay.confirm.completeLegalInfo.titleLoading'
          : texts?.title || 'bills.pay.confirm.completeLegalInfo.title'
      }
      subtitle={
        isLoading
          ? 'bills.pay.confirm.completeLegalInfo.subtitleLoading'
          : texts?.subtitle || 'bills.pay.confirm.completeLegalInfo.subtitle'
      }
      subTitleValues={{ companyName }}
      goExit={goExit}
      onPrev={onPrev}
      onNext={handleOnSubmit}
      innerSize={65}
      nextLabel={texts?.button || 'bills.pay.confirm.completeLegalInfo.action'}
      isLoading={isLoading}
      fullWidthCTA
      hideHeader
    >
      {errorCode && (
        <QBODialog
          type={DIALOG_TYPE.ALERT}
          variant={DIALOG_VARIANTS.ERROR}
          title="bills.pay.confirm.error.title"
          subtitle={
            intl.messages[`server.${errorCode}`] ? `server.${errorCode}` : 'server.Network Error'
          }
          onCancelAction={hideErrorAlert}
          cancelButtonText="bills.pay.confirm.error.CTA"
        />
      )}
      {ErrorComponent && <StyledErrorComponent>{ErrorComponent}</StyledErrorComponent>}
      <LegalInfoContainer>
        <FormWrapper>
          <QBOLegalInfoForm
            legalCompanyName={legalCompanyName}
            address={address}
            onChange={onLegalInfoChange}
            validationErrors={validationErrors}
            taxId={taxId}
            contactFirstName={contactFirstName}
            contactLastName={contactLastName}
            dateOfBirth={dateOfBirth}
            companyAddress={companyAddress}
            openLegalAddressInput={openLegalAddressInput}
            companyInfo={companyInfo}
            profile={profile}
            phone={phone}
            taxIdType={taxIdType}
            onAddressChange={onAddressChange}
            companyFormPage={COMPANY_FORM_PAGE.ONBOARDING}
            isLoading={isLoading}
            isInternational={isInternational}
            texts={texts}
          />
        </FormWrapper>
      </LegalInfoContainer>
    </QBOLayoutPage>
  );
};

export default PayBillCompleteLegalInfoPage;

const LegalInfoContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 45rem;
  padding-top: 0.7rem;

  @media ${devices.desktop} {
    justify-content: space-between;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2.4rem 0 2rem;
  }
`;

const FormWrapper = styled.div``;

const StyledErrorComponent = styled.div`
  max-width: 45rem;
  margin: 0 auto;
`;
