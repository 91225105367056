import { deliveryMethodsApi } from 'src/app/version-2/api/deliveryMethods.api';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { countriesActions } from './countries.slice';

import { call, put, select, takeEvery } from 'redux-saga/effects';

function* fetchCountriesHandler(action: ReturnType<typeof countriesActions.fetchCountries>) {
  try {
    const { vendorId } = action.payload;
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const { countries } = yield call(deliveryMethodsApi.fetchSupportedCountries, orgId, vendorId);

    yield put(countriesActions.setCountries(countries ?? []));
  } catch (error) {
    yield put(countriesActions.setCountries([]));
  }
}

export function* watchCountriesSaga() {
  yield takeEvery(countriesActions.fetchCountries, fetchCountriesHandler);
}
