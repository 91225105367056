import React from 'react';

import { Box, theme } from '@melio/billpay-design-system';

type Props = {
  color?: string;
  fontSize?: string;
  onClick: () => void;
};

const EditIcon = ({
  color = theme.colors.ds.gray[300],
  fontSize = theme.sizes.ds.xl,
  onClick,
}: Props) => (
  <Box onClick={onClick}>
    <i style={{ color, fontSize, cursor: 'pointer' }} className="icon-edit-icon" />
  </Box>
);

export { EditIcon };
