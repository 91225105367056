import isEmpty from 'lodash/isEmpty';
import {
  createApiCallSlice,
  ON_REQUEST,
  ON_FAILURE,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import authApi from 'src/app/services/api/auth';
import clientService from 'src/app/services/api/clientService';

type ChangePasswordStorePayloadType = {
  currentPassword: string;
  password: string;
  confirmedPassword: string;
};

type ChangePasswordStoreStateType = {
  changingPassword: boolean;
};

const changePasswordStore = createApiCallSlice<
  ChangePasswordStorePayloadType,
  ChangePasswordStoreStateType
>({
  name: '[PROFILE] PASSWORD_CHANGE',
  api: ({ currentPassword, password }) => authApi.changePassword(currentPassword, password),
  reducers: {
    [ON_REQUEST]: (state) => {
      state.changingPassword = true;
    },
    [ON_FAILURE]: (state) => {
      state.changingPassword = false;
    },
    [ON_SUCCESS]: (state) => {
      state.changingPassword = false;
    },
  },
  async validate(credentials: ChangePasswordStorePayloadType) {
    const { validationErrors } = await clientService.getValidationErrors(
      'userRegistration',
      credentials,
      Object.keys(credentials) as (keyof ChangePasswordStorePayloadType)[]
    );

    return isEmpty(validationErrors) ? null : validationErrors;
  },
});

export default changePasswordStore;
