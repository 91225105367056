import { ROUTE_NUMBER_PREFIXES } from 'src/app/version-2/utils/validationRegex.utils';

import { TestConfig, TestContext } from 'yup';

export interface CreateInvalidCheckSumErrorParams {
  validationCtx: TestContext;
  value: string;
}

export const createInvalidCheckSumError = ({ validationCtx }: CreateInvalidCheckSumErrorParams) =>
  validationCtx.createError({
    message: 'vendors.globalOptInCandidates.validation.invalidChecksum',
  });

export const routingChecksumTest = {
  test: (value = '', validationCtx) => {
    const digits = value.trim().split('').map(Number);
    const prefix = value.substring(0, 2);

    if (!ROUTE_NUMBER_PREFIXES.includes(prefix)) {
      return createInvalidCheckSumError({ validationCtx, value });
    }

    const sum =
      3 * (digits[0] + digits[3] + digits[6]) +
      7 * (digits[1] + digits[4] + digits[7]) +
      (digits[2] + digits[5] + digits[8]);

    if (sum % 10 !== 0) {
      return createInvalidCheckSumError({ validationCtx, value });
    }

    return true;
  },
} as TestConfig<string | undefined>;
