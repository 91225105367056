import { BreakValuesEnum } from 'src/app/version-2/model/enums';
import { DevicesInterface } from 'src/app/version-2/model/objects/devicesInterface';

export const devices: DevicesInterface = {
  mobile: `(min-width: ${BreakValuesEnum.MOBILE}px) and (max-width: ${
    BreakValuesEnum.PHABLET - 0.02
  }px)`,
  phablet: `(min-width: ${BreakValuesEnum.PHABLET}px) and (max-width: ${
    BreakValuesEnum.TABLET - 0.02
  }px)`,
  tablet: `(min-width: ${BreakValuesEnum.TABLET}px) and (max-width: ${
    BreakValuesEnum.DESKTOP - 0.02
  }px)`,
  desktop: `(min-width: ${BreakValuesEnum.DESKTOP}px)`,
  nonMobile: '',
  nonDesktop: '',
};

devices.nonMobile = `${devices.phablet}, ${devices.tablet}, ${devices.desktop}`;

devices.nonDesktop = `${devices.mobile}, ${devices.phablet}, ${devices.tablet}`;
