import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { QBRBillListType } from 'src/app/utils/types';
import { QBR_BILL_LIST_TAB_TYPE } from 'src/app/utils/consts';

type Props = {
  currentType: QBRBillListType;
  toggleType: () => void;
};

const BillsListTabs = ({ currentType, toggleType }: Props) => {
  const onToggle = () => {
    toggleType();
  };
  const active = currentType === QBR_BILL_LIST_TAB_TYPE.OPEN;

  return (
    <ListTabsContainer>
      <Tab active={active} onClick={onToggle}>
        <MIFormattedText label="bills.list.tabs.open" />
      </Tab>
      <Tab active={!active} onClick={onToggle}>
        <MIFormattedText label="bills.list.tabs.completed" />
      </Tab>
    </ListTabsContainer>
  );
};

export default BillsListTabs;

const ListTabsContainer = styled.div`
  height: 2.8rem;
  background-color: rgba(118, 118, 128, 0.12);
  border-radius: 0.8rem;
  margin: 1.2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
`;

const Tab = styled.div<{ active?: boolean }>`
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  background-color: ${(props) => (props.active ? props.theme.colors.white.opaque : 'none')};
  flex: 0 0 49.5%;
  border: ${(props) => (props.active ? '0.5px solid rgba(0, 0, 0, 0.04)' : '')};
  box-shadow: ${(props) =>
    props.active ? '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);' : 'none'};
  text-align: center;
  border-radius: 7px;
  vertical-align: middle;
  line-height: 2.4rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  cursor: pointer;
`;
