import { DeliveryOptionType, VendorType } from 'src/app/utils/types';
import { DELIVERY_TYPE, FLOW_ORIGIN, PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { FundingSource } from 'src/app/version-2/model/dtos';

export type JustPayPaymentType = {
  orgId: number;
  fundingSourceId: number;
  fundingSourceType?: string;
  deliveryMethodId: number;
  deliveryMethodType?: DELIVERY_TYPE;
  vendor: { id: number; companyName: string };
  category: { id?: string; value: string; isCategoryPreselected: boolean };
  amount: number;
  isQbCash?: boolean;
  suggestedDates: SuggestedDates;
  noteToVendor: string;
  plaidItemId?: number;
  suggestedFundingSources: SuggestedFundingSources;
  approvalDecisionStatus?: PAYMENT_APPROVAL_STATUS;
  createdPayment: any;
  fundingSourceOrigin: string;
  trackingBillId?: string;
  exitUrl?: string;
  flowOrigin?: FLOW_ORIGIN;
  payBillFlowUUID?: string | null;
};

export type SuggestedDates = {
  scheduledDate: Date;
  minScheduledDate: Date;
  deliveryEta: Date;
  maxDeliveryEta: Date;
  deliveryOptions: DeliveryOptionType[];
  actualDeliveryDays: number;
  deliveryPreferenceType: string;
};

/*
  TODO: @denis-melio
  'any' type should be remove when will be removed 'immutable' library
 */
export type SuggestedFundingSources = {
  selectedFundingSourceId?: number;
  selectedFundingSource?: FundingSource | any;
  fundingSources?: FundingSource[] | any;
};

export const enum JustPayLayoutPageRelativeSteps {
  JustPayAddPaymentInfo = 1 / 6,
  JustPaySelectFundingSource = 2 / 6,
  JustPaySelectDeliveryMethod = 3 / 6,
  JustPaySelectDeductionDate = 4 / 6,
  JustPayNoteToVendor = 5 / 6,
  JustPayReviewAndConfirm = 6 / 6,
}

export type getRedirectUrlByDeliveryMethodStatusProps = {
  orgId: number | string;
  selectedVendor: VendorType;
  newVendorId?: number;
  deliveryMethodType: any;
};
