import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { GlobalState } from 'src/app/redux/types';
import { SmartLink } from 'src/app/modules/navigation/components/SmartLink';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import QBODrawer from 'src/app/components/layout/QBODrawer';
import { BackMobile } from 'src/app/components/layout/QBOHeader';
import { melioClose } from 'src/app/utils/external-events';
import { ListSearchBarContextType } from 'src/app/utils/types';
import {
  applicationActions,
  applicationSelectors,
} from 'src/app/version-2/modules/application/application.slice';
import { getOrgId } from 'src/app/redux/user/selectors';
import styled from 'styled-components';
import { withNavigator, withBreak } from '../../hoc';
import { MIFormattedText } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import { withListSearchBarContext } from '../../hoc/withListSearchBarContext';

type MapDispatchToProps = {
  setUrlToBack: (url: string) => void;
  setIsFaqOpen: (isOpen: boolean) => void;
};
type MapStateToProps = {
  urlToBack: string;
  orgId: string;
};
type Props = {
  children: React.ReactElement<any>;
  params: Record<string, any>;
  location: {
    pathname: string;
  };
} & ListSearchBarContextType &
  MapDispatchToProps &
  MapStateToProps;

class QBOMobileHeader extends React.PureComponent<Props> {
  // This will be removed once this is a function component
  // eslint-disable-next-line react/state-in-constructor

  getPathName = () => {
    const pathName = this.props.location.pathname.split('/');

    return pathName[1] === 'orgs' ? pathName[3] : pathName[1];
  };

  getMobileHeaderTitle() {
    const pathName = this.getPathName();

    return `nav.mobileHeader.${pathName}`;
  }

  openDrawer = () => {
    const { setIsFaqOpen } = this.props;

    setIsFaqOpen(true);
    analytics.trackAction('faq.view', { isOpen: true });
  };

  onDrawerClose = () => {
    analytics.trackAction('faq.view', { isOpen: false });
  };

  onBack = () => {
    melioClose();
  };

  onSettingsClick = () => {
    analytics.trackAction('settings.view');
    this.props.setUrlToBack(locations.Bills.index.url({ orgId: this.props.orgId }));
  };

  render() {
    const { orgId } = this.props.params;

    return (
      <>
        <HeaderContainer>
          <HeaderActions>
            <>
              <ButtonContainer>
                <BackMobile onClick={this.onBack}>
                  <i className="icon-back-arrow" />
                </BackMobile>
              </ButtonContainer>
              <HeaderTitle>
                <MIFormattedText label={this.getMobileHeaderTitle()} />
              </HeaderTitle>
              <ButtonContainer>
                <HelpIcon onClick={this.openDrawer}>
                  <HeaderContentIcon className="icon-help-icon" />
                </HelpIcon>
                <SettingsLink
                  to={locations.Settings.index.url({ orgId })}
                  onClick={this.onSettingsClick}
                >
                  <HeaderContentIcon className="icon-settings-icon" />
                </SettingsLink>
              </ButtonContainer>
            </>
          </HeaderActions>
        </HeaderContainer>
        <QBODrawer onCloseCallback={this.onDrawerClose} />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  setUrlToBack(url: string) {
    dispatch(applicationActions.setUrlToBack(url));
  },
  setIsFaqOpen(isOpen: boolean) {
    dispatch(applicationActions.setFaqPanel({ isOpen }));
  },
});
const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  urlToBack: applicationSelectors.selectUrlToBack(state),
  orgId: getOrgId(state),
});

export default compose(
  withNavigator(),
  withBreak(),
  withListSearchBarContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(QBOMobileHeader);

const HeaderContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.opaque};
  height: ${(props) => props.theme.sizes.mobileHeaderHeight};
  position: fixed;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.mobileHeader};
  box-shadow: '0 0 1rem 0 rgba(0,0,0,0.2)';
  @media ${devices.tablet} {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  }
  ${(props) => props.theme?.components?.MobileHeader?.MobileHeaderContainer}
`;
const ButtonContainer = styled.div`
  ${(props) => props.theme?.components?.MobileHeader?.ButtonContainer}
`;
const HelpIcon = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;
const HeaderContentIcon = styled.i`
  @media ${devices.mobile}, ${devices.phablet} {
    color: ${(props) => props.theme.colors.brand};
    font-size: 2.8rem;
    margin-left: 1rem;
  }
  ${(props) => props.theme?.components?.MobileHeader?.HeaderContentIcon}
`;
const SettingsLink = styled(SmartLink)`
  @media ${devices.mobile}, ${devices.phablet} {
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    border: none;
    ${(props) => props.theme?.components?.QBOHeaderContainerClass?.SettingsLink}
  }
`;
const HeaderActions = styled.div<{ isSearchBarOpen?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  padding: ${(props) =>
    props.isSearchBarOpen ? '1.6rem 2rem 1.6rem 2rem' : '2.4rem 2rem 2.4rem 2rem'};

  @media ${devices.tablet} {
    padding: ${(props) =>
      props.isSearchBarOpen ? '1.1rem 2rem 1.1rem 2rem' : '2.4rem 2rem 2.4rem 2rem'};
  }

  width: 100%;
  -webkit-tap-highlight-color: transparent;
  @media ${devices.mobile}, ${devices.phablet} {
    align-items: center;
  }
  ${(props) => props.theme?.components?.MobileHeader?.MobileHeaderActions}
`;

const HeaderTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  flex-grow: 1;
  font-size: ${(props) => props.theme.text.size.subNav};
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-align: center;
  ${(props) => props.theme?.components?.MobileHeader?.HeaderTitle}
`;
