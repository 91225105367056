import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import batchBillsStore, { getBillListActions } from 'src/app/modules/batch-bills/batch-bills-store';
import { BillType } from 'src/app/utils/types';

export type PartialPayments = Record<number, number>;
export type Bill = RecordOf<BillType>;

type UsePartialPayments = (dispatch) => {
  setPaymentAmount: (billId: number, paymentAmount: number) => void;
  setPaymentAmounts: (bills: PartialPayments) => void;
  getPaymentAmount: (bill?: Bill) => number;
};
export const usePartialPayments: UsePartialPayments = (dispatch) => {
  const billActions = useMemo(() => getBillListActions(dispatch), [dispatch]);
  const paymentAmounts: Record<string, number> = useSelector(
    batchBillsStore.selectors.getBillListByIdsSlice.partialPayments
  );

  const setPaymentAmount = useCallback(
    (billId: number, paymentAmount: number) => {
      billActions.updatePartialPayment(billId, paymentAmount);
    },
    [billActions]
  );

  const setPaymentAmounts = useCallback(
    (bills: PartialPayments) => {
      const validBills = pickBy(bills, identity);

      if (Object.keys(validBills).length) {
        billActions.updatePartialPayments(validBills);
      }
    },
    [billActions]
  );

  const getPaymentAmount = useCallback(
    (bill?: Bill) => (bill && (paymentAmounts[bill.id] || bill.balance)) || 0,
    [paymentAmounts]
  );

  return {
    setPaymentAmount,
    getPaymentAmount,
    setPaymentAmounts,
  };
};

type UsePartialPayment = (bill?: Bill) => {
  openBalance: number;
  isValidAmount: boolean;
  setIsValidAmount: (validAmount: boolean) => void;
  partialPaymentAmount: number;
  setPartialPaymentAmount: (partialPaymentAmount: number) => void;
};

export const usePartialPayment: UsePartialPayment = (bill) => {
  const [isValidAmount, setIsValidAmount] = useState(true);
  const [paymentAmounts, setPaymentAmounts] = useState<PartialPayments>({});

  const openBalance = bill?.balance || 0;

  const partialPaymentAmount = (bill && paymentAmounts[bill.id]) || 0;
  const setPartialPaymentAmount = (amount: number) => {
    if (bill) {
      setPaymentAmounts((res) => ({ ...res, [bill.id]: amount || 0 }));
    }
  };

  return {
    openBalance,
    isValidAmount,
    setIsValidAmount,
    partialPaymentAmount,
    setPartialPaymentAmount,
  };
};
