import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Popover } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/version-2/externals';
import { Payment } from 'src/app/version-2/model/dtos';
import { BillPayments } from 'src/app/version-2/model/dtos/billPayments';
import {
  paymentsActions,
  paymentsSelectors,
} from 'src/app/version-2/modules/payments/payments.slice';
import { BillsItem } from './BillsItem';
import {
  CloseAction,
  HeaderItem,
  List,
  ListItem,
  ListWrapper,
  PopoverTriggerWrapper,
  SkeletonLoading,
  StyledLink,
} from './BillsPopover.styles';

type BillsPopoverProps = {
  bills?: BillPayments[];
  paymentId?: string;
};

const BillsPopover = ({ paymentId = '', bills }: BillsPopoverProps) => {
  const dispatch = useDispatch();
  const payment = useSelector(paymentsSelectors.selectPaymentById(paymentId)) || ({} as Payment);
  const combinedBills = bills || payment.bills;

  useEffect(() => {
    if (paymentId && !combinedBills) dispatch(paymentsActions.fetchPaymentById({ paymentId }));
  }, [paymentId, combinedBills]);

  if (!paymentId && !bills) return null;

  if (!combinedBills?.length) return <SkeletonLoading />;

  return (
    <Popover trigger="click" preventOverflow>
      {({ onClose }) => (
        <>
          <Popover.PopoverTrigger>
            <PopoverTriggerWrapper>
              <StyledLink>
                <MIFormattedText
                  label="batchPayment.billsPopover.count"
                  values={{ billsNum: combinedBills.length }}
                />
              </StyledLink>
            </PopoverTriggerWrapper>
          </Popover.PopoverTrigger>
          <Popover.PopoverContent>
            <Popover.PopoverBody>
              <CloseAction>
                <Box onClick={onClose}>
                  <i className="icon-close-icon" />
                </Box>
              </CloseAction>
              <ListWrapper>
                <List>
                  <ListItem>
                    <HeaderItem>
                      <MIFormattedText label="batchPayment.billsPopover.id" />
                    </HeaderItem>
                    <HeaderItem>
                      <MIFormattedText label="batchPayment.billsPopover.dueDate" />
                    </HeaderItem>
                    <HeaderItem>
                      <MIFormattedText label="batchPayment.billsPopover.amount" />
                    </HeaderItem>
                  </ListItem>
                </List>

                <List>
                  {combinedBills.map((item) => (
                    <BillsItem key={item.id || item?.billId} bill={item?.bill || item} />
                  ))}
                </List>
              </ListWrapper>
            </Popover.PopoverBody>
          </Popover.PopoverContent>
        </>
      )}
    </Popover>
  );
};

export { BillsPopover };
