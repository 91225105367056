import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RecordOf } from 'immutable';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS } from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import { BillType, DeliveryMethodType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import { getFailedRefundPaymentDate, isVirtualCardPaymentExpired } from 'src/app/utils/payments';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import {
  BlockTitleContainer,
  MainPaymentActivityContainer,
  PaymentInfoContainer,
} from 'src/app/components/layout/PaymentActivityElements';
import { FundingSource } from 'src/app/version-2/model/dtos';
import ViewBillPaymentActivityMemoInfo from './ViewBillPaymentActivityMemoInfo';
import ViewBillPaymentActivityFundingSourceInfo from './ViewBillPaymentActivityFundingSourceInfo';
import { VirtualCardExpiredPaymentActivity } from './VirtualCardExpiredPaymentActivity';

type Props = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  fundingSource: FundingSource;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  qbCashState?: QBCashStateType;
};

export const FailedPaymentActivity = ({
  bill,
  payment,
  fundingSource,
  deliveryMethod,
  qbCashState,
}: Props) => {
  const paymentFailedDate = getFailedRefundPaymentDate(payment);
  const isDirectPayment = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;
  const isVirtualCardExpired = isVirtualCardPaymentExpired(payment);
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);

  useEffect(() => {
    if (isRetryFailedToDeliver) {
      analytics.page('RetryPaymentDetails', 'Viewed', {
        paymentId: payment?.id,
        deliveryMethodId: deliveryMethod?.id,
      });
    }
  }, [isRetryFailedToDeliver]);

  if (isVirtualCardExpired) {
    return (
      <VirtualCardExpiredPaymentActivity
        bill={bill}
        payment={payment}
        fundingSource={fundingSource}
        deliveryMethod={deliveryMethod}
        qbCashState={qbCashState}
      />
    );
  }

  const payFromLabel = isRetryFailedToDeliver
    ? 'viewBillPaymentActivity.paidFromTitle'
    : 'viewBillPaymentActivity.payFromTitle';

  return (
    <>
      <BlockTitleContainer>
        <MIFormattedText label={payFromLabel} />
      </BlockTitleContainer>
      <MainPaymentActivityContainer>
        <ViewBillPaymentActivityFundingSourceInfo
          qbCashState={qbCashState}
          fundingSource={fundingSource}
          bill={bill}
        />
        {paymentFailedDate && (
          <PaymentInfoContainer>
            <PaymentFailedContainer>
              <MIFormattedText
                label="bills.form.paymentActivity.failedPayment"
                values={{
                  date: <MIFormattedDate date={paymentFailedDate} />,
                }}
              />
            </PaymentFailedContainer>
          </PaymentInfoContainer>
        )}
      </MainPaymentActivityContainer>
      {!isDirectPayment && <ViewBillPaymentActivityMemoInfo payment={payment} />}
    </>
  );
};

const PaymentFailedContainer = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.colors.failure.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 2rem;
`;
