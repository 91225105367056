import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';

import { EditIcon } from 'src/app/components/common/EditIcon';
import { MIFormattedText } from 'src/app/utils/formatting';

import {
  FORM_TYPES,
  TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH,
  TIMELINE_STEP_ACTIONS,
} from '../consts';
import { VendorPaymentSectionProps } from '../types';

const { EDIT_EMAIL } = TIMELINE_STEP_ACTIONS;

type Props = {
  isMailEditEnabled: boolean;
};

const VendorPaymentMailInfo = ({
  payment,
  vendorPaymentActions,
  isMailEditEnabled,
}: VendorPaymentSectionProps & Props) => {
  const payorEmail = payment.organization.userOrganizations?.[0]?.user?.email;

  return (
    <Flex direction="column" width="full" gridGap="1.4rem">
      <Flex justify="space-between" alignItems="center" gridGap={3}>
        <Flex direction="column">
          <Box textStyle="body3" color="gray.500">
            <MIFormattedText
              label={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_EMAIL}.vendor`}
            />
          </Box>
          <Box textStyle="body3">{payment.vendor.contactEmail}</Box>
        </Flex>
        {isMailEditEnabled && vendorPaymentActions && (
          <EditIcon onClick={vendorPaymentActions[EDIT_EMAIL]} />
        )}
      </Flex>
      {payorEmail ? (
        <Flex direction="column">
          <Box textStyle="body3" color="gray.500">
            <MIFormattedText
              label={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_EMAIL}.payor`}
            />
          </Box>
          <Box textStyle="body3">{payorEmail}</Box>
        </Flex>
      ) : null}
    </Flex>
  );
};

export { VendorPaymentMailInfo };
