export const content = {
  bgColor: 'black',
  border: 'solid 0.1rem',
  borderColor: 'black',
  sx: {
    '--popper-arrow-size': '1.132rem',
    '--popper-arrow-bg': 'var(--chakra-colors-black)',
  },
  p: '1.6rem',
  width: '20.5rem',
  borderRadius: 'ds.md',
};

export const close = {
  border: 'none',
  bgColor: 'transparent',
  color: 'ds.gray.300',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  w: '1.9rem',
  h: '1.9rem',
  top: 0,
  right: 0,
  padding: 0,
  cursor: 'pointer',
  __css: {
    '.chakra-icon': {
      w: '0.937rem',
      h: '0.937rem',
    },
  },
};

export const body = {
  p: '0',
  color: 'ds.white',
  wordBreak: 'break-word' as any,
  display: 'flex',
  justifyContent: 'space-between',
  textStyle: 'ds.body3',
  fontWeight: 'ds.semi',
};
