import React, { useEffect } from 'react';
import styled from 'styled-components';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import { devices } from 'src/app/theme/AppDevices';
import checkMark from 'src/app/images/icons/check-mark.svg';
import { MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';
import { useQueryString } from 'src/app/utils/hooks';

const TestVendorForex = () => {
  const query = useQueryString();

  useEffect(() => {
    analytics.track('', 'ForexTestSuccessPage-Viewed', query);
  }, []);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <Content>
        <Illustration src={checkMark} />
        <Title>
          <MIFormattedText label="qbo.testVendorForex.title" />
        </Title>
        <SubTitle>
          <MIFormattedText label="qbo.testVendorForex.subtitle" />
        </SubTitle>
      </Content>
    </QBOLayoutPage>
  );
};

export default TestVendorForex;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 2rem;
`;

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.6rem;
  border: 1px solid ${(props) => props.theme.colors.shadow.lightGrey};
  box-sizing: border-box;

  @media ${devices.desktop}, ${devices.tablet} {
    padding: 4rem;
  }
`;

const Illustration = styled(QBLogo)`
  width: 60px;
  height: 60px;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-align: center;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.text.size.errorPageTitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 2rem;
  align-self: center;
  margin-bottom: 1rem;
`;
