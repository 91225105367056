import styled from 'styled-components';

import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';

type Props = {
  error: {
    responseData: string | string[];
  };
};

const camelToTitle = (text = '') => {
  const result = text.replace(/([A-Z])/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const ErrorAlert = ({ error }: Props) => {
  const { responseData: errorMessage } = error || {};

  if (!errorMessage || !errorMessage?.length) return null;

  const renderMessages = () =>
    Array.isArray(errorMessage) ? (
      errorMessage.map((item) => (
        <div className="row" key={item}>
          {camelToTitle(item)}
        </div>
      ))
    ) : (
      <div>{camelToTitle(errorMessage)}</div>
    );

  return <StyledError type={NOTIFICATION_CARD_TYPES.ERROR} renderText={renderMessages()} />;
};

const StyledError = styled(QBONotificationCard)`
  font-size: 1.4rem;
  line-height: normal;
  color: #d52b1e;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 2rem;

  .row:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
