import React from 'react';
import { RecordOf } from 'immutable';
import { Link, BadgeVariants } from '@melio/billpay-design-system';

import { DeliveryMethodType } from 'src/app/utils/types';
import analytics from 'src/app/services/analytics';

import { CONSTS } from 'src/app/utils/consts';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  method: RecordOf<DeliveryMethodType>;
  onClick: () => void;
  companyName: string;
  showDropdownSelectedMethod: boolean;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
  tag?: {
    label: string;
    variant: BadgeVariants;
  };
  onToggleVirtualCardInfoModal?: () => void;
};

function VirtualCardDeliveryMethod({
  onClick,
  method,
  isSelected,
  companyName,
  showDropdownSelectedMethod,
  optionComponent: OptionComponent,
  disabled,
  tag,
  onToggleVirtualCardInfoModal,
}: Props) {
  const goLearnMore = () => {
    analytics.track('pay-bill', 'delivery-method-add', {
      type: CONSTS.DELIVERY_TYPE.VIRTUAL_CARD,
    });

    if (onToggleVirtualCardInfoModal) {
      onToggleVirtualCardInfoModal();
    }
  };

  if (showDropdownSelectedMethod) {
    if (method) {
      return (
        <SelectedMethod
          icon="icon-credit-card-icon"
          title="bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod"
          label="selectMethods.selectedDeliveryMethodLabel"
          labelValues={{
            displayName: method.getDeliveryInfo(companyName),
          }}
        />
      );
    }

    return null;
  }

  if (!method) {
    return (
      <OptionComponent
        id="virtualCard"
        icon="icon-credit-card-icon"
        label="deliveryMethods.notDefinedInfo.virtual-card.label"
        labelValues={{
          to: '',
        }}
        description={
          disabled
            ? 'deliveryMethods.notDefinedInfo.virtual-card.disabled'
            : 'deliveryMethods.notDefinedInfo.virtual-card.desc'
        }
        descriptionValues={{
          learnMoreLink: (...chunks) => (
            <Link onClick={goLearnMore} isExternal>
              {React.Children.toArray(chunks)}
            </Link>
          ),
        }}
        onClick={onClick}
        tag={tag}
        disabled={disabled}
      />
    );
  }

  return (
    <OptionComponent
      id={method.id}
      label="deliveryMethods.notDefinedInfo.virtual-card.selectedLabel"
      labelValues={{
        displayName: method.getDeliveryInfo(companyName),
      }}
      description="deliveryMethods.notDefinedInfo.virtual-card.desc"
      descriptionValues={{
        learnMoreLink: (...chunks) => (
          <Link onClick={goLearnMore} isExternal>
            {React.Children.toArray(chunks)}
          </Link>
        ),
      }}
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
      method={method}
    />
  );
}

function VirtuaCardDeliverySelectedMethod({
  method,
  companyName,
}: {
  method: RecordOf<DeliveryMethodType>;
  companyName: string;
}) {
  return (
    <SelectedMethod
      title="deliveryMethods.notDefinedInfo.virtual-card.title"
      label="selectMethods.selectedDeliveryMethodLabel"
      labelValues={{
        displayName: method.getDeliveryInfo(companyName),
      }}
      method={method}
    />
  );
}

VirtualCardDeliveryMethod.methodType = CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;
VirtualCardDeliveryMethod.SelectedMethod = VirtuaCardDeliverySelectedMethod;
VirtualCardDeliveryMethod.isDisabled = () => false;

export default VirtualCardDeliveryMethod;
