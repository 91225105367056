import { RecordOf } from 'immutable';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { BillType, PaymentType } from 'src/app/utils/types';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodText,
  PaymentInfoText,
  EditElement,
} from 'src/app/components/layout/PaymentActivityElements';
import {
  getPaymentById,
  isReturnedCheckPayment,
  isUndepositedPayment,
} from 'src/app/utils/payments';
import { FAILED_PAYMENT_TYPE } from 'src/app/utils/consts';
import Tag from './Tag';

type Props = {
  payment: RecordOf<PaymentType>;
  bill: RecordOf<BillType>;
  onEdit: () => void;
};

const PayBillPaymentActivityDeductionDateInfo = ({ payment, bill, onEdit }: Props) => {
  const now = moment();
  const isRecurringBill = !!bill.recurringBill;
  const originPayment = getPaymentById(bill.payments, payment.id);
  const canEdit =
    payment?.metadata?.failedType !== FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER &&
    !isRecurringBill &&
    !isReturnedCheckPayment(payment) &&
    !isUndepositedPayment(payment);
  const isUpdated =
    originPayment &&
    payment?.metadata?.failedType &&
    moment(payment?.scheduledDate).diff(moment(originPayment?.scheduledDate), 'days') &&
    !isReturnedCheckPayment(payment) &&
    !isUndepositedPayment(payment);
  const regularBillDeductionDateLabel = now.isAfter(moment(originPayment?.scheduledDate))
    ? 'payBillPaymentActivity.deductionDate.pastLabel'
    : 'payBillPaymentActivity.deductionDate.futureLabel';
  const deductionDateLabel = isRecurringBill
    ? 'payBillPaymentActivity.deductionDate.recurringLabel'
    : regularBillDeductionDateLabel;

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <ScheduledIcon />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={deductionDateLabel} />
            </PaymentMethodText>
            <PaymentInfoText>
              <MIFormattedDate date={payment.scheduledDate} />
              {isUpdated && <Tag label="payBillPaymentActivity.updated" />}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {canEdit && <EditElement onEdit={onEdit} />}
      </PaymentActivityMainContainer>
    </PaymentActivityContainer>
  );
};

const ScheduledIcon = styled.i.attrs({ className: 'icon-scheduled-icon' })``;

export default PayBillPaymentActivityDeductionDateInfo;
