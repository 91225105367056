import React, { DragEvent, ChangeEvent, useRef } from 'react';
import { DragAndDropWrapper, FileInput } from './FileUploader.styles';

interface FileUploaderProps {
  onSelectFile: (file: File) => void;
  children: React.ReactNode;
  allowedTypes?: string[];
  changeInputFile?: (event: ChangeEvent<HTMLInputElement>) => void;
  dragEnter?: (event: DragEvent<HTMLDivElement>) => void;
  dragOver?: (event: DragEvent<HTMLDivElement>) => void;
  dragAndDropClick?: (event: DragEvent<HTMLDivElement>) => void;
}

export const FileUploader = ({
  onSelectFile,
  allowedTypes,
  changeInputFile,
  dragEnter,
  dragOver,
  children,
  dragAndDropClick,
}: FileUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFile = (file: File) => {
    const isTypesProvided = allowedTypes && allowedTypes.length > 0;
    if (
      !isTypesProvided
      || allowedTypes.some((type) => type === file.type || type.indexOf('*') >= 0)
    ) {
      onSelectFile(file);
    }
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!event.dataTransfer.items) {
      return;
    }

    for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
      const item = event.dataTransfer.items[i];

      if (item.kind === 'file') {
        handleFile(item.getAsFile() as File);
      }
    }
  };

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (dragEnter) {
      dragEnter(event);
    }
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (dragOver) {
      dragOver(event);
    }
  };

  const onChangeInputFile = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    if (!target.files) {
      return;
    }

    if (changeInputFile) {
      changeInputFile(event);
    }

    const { files } = target;
    const numberOfFiles = files.length;
    for (let i = 0; i < numberOfFiles; i += 1) {
      handleFile(files[i]);
    }
  };

  const triggerFileInput = (event: DragEvent<HTMLDivElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    if (dragAndDropClick) {
      dragAndDropClick(event);
    }
  };

  return (
    <>
      <DragAndDropWrapper
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragOver={onDragOver}
        onClick={triggerFileInput}
      >
        {children}
      </DragAndDropWrapper>
      <FileInput
        ref={fileInputRef}
        onChange={onChangeInputFile}
        type="file"
        accept={(allowedTypes || []).join(', ')}
      />
    </>
  );
};
