import React from 'react';
import styled, { css } from 'styled-components';
import * as WizardElements from 'src/app/components/layout/WizardElements';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  children: JSX.Element[];
  collapsed: boolean;
  header?: React.ReactElement;
  footer?: React.ReactElement;
  maxVisibleCollapsedItems?: number;
};

export const CollapsableGroup = ({
  children,
  collapsed,
  header,
  footer,
  maxVisibleCollapsedItems = 3,
}: Props) => {
  const renderPaymentsSeparator = () => (
    <PaymentsSeparator>
      <WizardElements.WizardOptionLine />
      <PaymentsSeparatorText>
        <MIFormattedText label="bills.form.partialPayments.linkedPayments" />
      </PaymentsSeparatorText>
      <WizardElements.WizardOptionLine />
    </PaymentsSeparator>
  );

  if (children.length > 1) {
    if (collapsed) {
      return (
        <GroupContainer>
          {children[0]}
          <CollapseContainer
            expanded={!collapsed}
            maxVisibleCollapsedItems={maxVisibleCollapsedItems}
            length={children.length}
          >
            {children.slice(1)}
          </CollapseContainer>
        </GroupContainer>
      );
    }

    return (
      <>
        {header || renderPaymentsSeparator()}
        {children}
        {footer || (
          <PaymentsSeparator isLower>
            <WizardElements.WizardOptionLine />
          </PaymentsSeparator>
        )}
      </>
    );
  }

  return children[0];
};

const GroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  > div:first-of-type {
    z-index: 10;
    filter: drop-shadow(0px 2px 10px ${({ theme }) => theme.text.color.lightGrey});
  }
`;

const CollapseContainer = styled.div<{
  expanded: boolean;
  maxVisibleCollapsedItems: number;
  length: number;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: ${(props) => (!props.expanded && props.length > 2 ? '5rem' : '2.5rem')};

  ${({ theme, expanded, length, maxVisibleCollapsedItems }) =>
    !expanded &&
    maxVisibleCollapsedItems &&
    css`
      > div {
        margin-top: -11rem;
        filter: drop-shadow(0px 2px 10px ${theme.text.color.lightGrey});
      }

      > div:nth-of-type(1) {
        position: absolute;
        width: 96%;
        z-index: 8;
        ${length > 2 &&
        css`
          margin-top: -13.2rem;
        `}
      }

      > div:nth-of-type(2) {
        position: absolute;
        width: 92%;
        z-index: 6;
      }

      > div:nth-of-type(n + ${maxVisibleCollapsedItems}) {
        display: none;
      }
    `}
`;

const PaymentsSeparator = styled.div<{
  isLower?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  margin: ${(props) => (props.isLower ? '2rem 0' : '1rem 0 2rem')};
  align-self: center;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
`;

const PaymentsSeparatorText = styled.div`
  padding: 0 1rem;
`;
