import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import fileAPI from 'src/app/services/api/files';
import analytics from 'src/app/services/analytics';

type Params = {
  analyticsData: {
    trackingBillId?: string;
  };
};

export const useUpload10KInvoice = ({ analyticsData }: Params) => {
  const orgId = useSelector(getOrgId);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadCanceled, setIsUploadCanceled] = useState<boolean>(false);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [files, setFiles] = useState<any[]>([]);

  const handleUploadStart = () => {
    analytics.trackAction('upload-image', analyticsData);
    setIsUploading(true);
    setIsUploadCanceled(false);
    setIsUploadError(false);
  };

  const handleUploadSuccess = (file) => {
    analytics.trackAction('upload-10K-invoice-file-success', analyticsData);
    setIsUploading(false);
    setIsUploadError(false);
    setFileName(file.fileName);
    setFiles([file.id]);
  };

  const handleUploadError = () => {
    analytics.trackAction('error-upload-image', analyticsData);
    setIsUploading(false);
    setIsUploadError(true);
    setFileName('');
    setFiles([]);
  };

  const handleRetry = () => {
    analytics.trackAction('retry-upload-image', analyticsData);
    setIsUploading(false);
    setIsUploadError(false);
    setFileName('');
    setFiles([]);
  };

  const onDeleteAttachment = () => {
    setFileName('');
    setFiles([]);
  };

  const handleSelectFile = (file: File) => {
    handleUploadStart();
    fileAPI
      .uploadFile(orgId, file)
      .then((object) => {
        handleUploadSuccess(object.file);
      })
      .catch(handleUploadError);
  };

  return {
    fileName,
    isUploading,
    isUploadCanceled,
    isUploadError,
    files,
    handleSelectFile,
    handleRetry,
    onDeleteAttachment,
  };
};
