import { useSelector } from 'react-redux';
import analytics from 'src/app/services/analytics';
import uboAPI from 'src/app/services/api/organization-ultimate-beneficial-owners';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/app/utils/consts';
import { TaxIdEnum } from 'src/app/version-2/model/enums';
import locations from 'src/app/utils/locations';
import { getPartialBillId } from 'src/app/utils/bills';
import { BillType, DeliveryMethodType, NavigateType } from 'src/app/utils/types';

import { getOrgId, getCompanyInfo } from 'src/app/redux/user/selectors';
import { useHasFeatureFlag } from 'src/app/hooks/useHasFeatureFlag';
import { hasUltimateBeneficialOwners } from 'src/app/utils/organizations-ubo-utils';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { PAY_BILL_EVENT_PAGE } from '../../consts';

type Params = {
  navigate: NavigateType;
};

type GoNextInternationalVendorParams = {
  bill: BillType;
  redirectUrl: string;
  exitUrl: string;
};

type GoNextParams = {
  bill: BillType;
  redirectUrl: string;
  exitUrl: string;
  selectedFundingSource: FundingSource;
  defaultDeliveryMethod?: DeliveryMethodType;
  isInternational: boolean;
};

export const useFundingSourceStatelessNavigate = ({ navigate }: Params) => {
  const orgId = useSelector(getOrgId);
  const companyInfo = useSelector(getCompanyInfo);
  const { isFeatureFlagEnabled } = useHasFeatureFlag();

  const goNextForInternationalVendor = async ({
    bill,
    redirectUrl,
    exitUrl,
  }: GoNextInternationalVendorParams) => {
    const shouldShowUltimateBeneficialOwnersFlow = async (organizationId, taxIdType) => {
      try {
        const uboResponse = await uboAPI.getOrganizationUltimateBeneficialOwners(organizationId);

        return hasUltimateBeneficialOwners(uboResponse) && taxIdType === TaxIdEnum.EIN;
      } catch (e) {
        return false;
      }
    };

    const purposeUrl = locations.Vendors.deliveryMethods.international.purposeOfPayment.url({
      orgId,
      id: bill.vendor.id,
    });

    let nextStepUrl = purposeUrl;

    // Making sure the UBO API call and checks will be done only when the FF is on
    const isUltimateBeneficialOwnersFlowOn = isFeatureFlagEnabled('ultimateBeneficialOwnersFlow');

    if (isUltimateBeneficialOwnersFlowOn) {
      // Calling the API + handling the result to determine if to direct the user to the UBO flow or continue as normal
      const shouldShowUBO = await shouldShowUltimateBeneficialOwnersFlow(
        orgId,
        companyInfo?.taxIdType
      );

      // If there are no UBO's we will set the nextStepUrl to the UBO Flow

      if (shouldShowUBO) {
        nextStepUrl = locations.Vendors.deliveryMethods.international.ultimateBeneficialOwners.url({
          orgId,
          id: bill.vendor.id,
        });
      }
    }

    navigate(nextStepUrl, false, {
      redirectUrl,
      origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL,
      exitUrl,
    });
  };

  const goNextFundingSource = async ({
    selectedFundingSource,
    bill,
    redirectUrl,
    exitUrl,
    isInternational,
  }: GoNextParams) => {
    analytics.track(PAY_BILL_EVENT_PAGE, 'funding-source-continue', {
      fundingSourceId: selectedFundingSource.id,
      partialBillId: getPartialBillId(bill),
    });
    const url = redirectUrl;

    if (!selectedFundingSource.intuitAccountId) {
      navigate(locations.Onboarding.fundingSources.bank.account.url(), false, {
        fundingSourceId: selectedFundingSource.id,
        redirectUrl: url,
        origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL,
        exitUrl,
      });
    } else if (isInternational) {
      await goNextForInternationalVendor({ bill, redirectUrl, exitUrl });
    } else {
      navigate(url);
    }
  };

  return { goNextFundingSource };
};
