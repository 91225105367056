import React, { useState, useEffect, useCallback } from 'react';
import { getQBRBillsDefaultFilters } from 'src/app/utils/bills';
import { useSelector } from 'react-redux';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/app/pages/quickpay/locations';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useApi } from 'src/app/hoc/useApi';
import vendorApi from 'src/app/services/api/vendors';
import paymentStore from 'src/app/modules/payments/payment-store';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import { AreaLoader } from '@melio/billpay-design-system';
import { QBR_BILL_LIST_TAB_TYPE, CONSTS } from 'src/app/utils/consts';
import { MIFormattedDate } from 'src/app/utils/formatting';
import nativeBackButtonHandler from 'src/app/modules/navigation/nativeBackButtonHandler';
import QBRSuccessLayout from 'src/app/components/layout/QBRSuccessLayout';
import deliveryMethodsApi from 'src/app/services/api/deliveryMethods';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { DeliveryMethodType } from 'src/app/utils/types';
import { VendorEmailModal } from './VendorEmailModal';
import { getCurrentStateByVendor } from './utils';
import { QuickPayment } from '../../../modules/payments/quickpay-slice';

export const SUCCESS_PAGE_STATES = Object.freeze({
  HAS_VENDOR_EMAIL: 'hasVendorEmail',
  EMAIL_UPDATED: 'emailUpdated',
  NO_VENDOR_EMAIL: 'noVendorEmail',
});

type getDeliveryMethodByIdResponseType = {
  deliveryMethod: DeliveryMethodType;
};

const SuccessPage = () => {
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const payment: QuickPayment = useSelector(
    paymentStore.selectors.quickpay.newPaymentWizard.payment
  );
  const vendorId = payment?.vendorId;
  const { vendor } = useFetchVendor(vendorId);
  const [showCollectVendorEmailModal, setShowCollectVendorEmailModal] = useState(false);

  const [getOwnedVendorExists, isOwnedVendorExists, isGettingOwnedVendorData] = useApi<
    [{ orgId: string; id: number }],
    boolean
  >(vendorApi.getOwnedVendorExists);

  const [getDeliveryMethodById, getDeliveryMethodByIdResponse] = useApi<
    [{ orgId: string; vendorId: number; id: string }],
    getDeliveryMethodByIdResponseType
  >(deliveryMethodsApi.getDeliveryMethodById);

  const { deliveryMethod } = getDeliveryMethodByIdResponse || {};

  const [currentState, setCurrentState] = useState(SUCCESS_PAGE_STATES.NO_VENDOR_EMAIL);

  useEffect(() => nativeBackButtonHandler.addListener(goToBillList), []);

  useEffect(() => {
    getDeliveryMethodById({
      orgId,
      vendorId: vendorId as unknown as number,
      id: payment.deliveryMethodId as string,
    });
  }, []);

  const goToBillList = () => {
    historyPush({
      path: locations.list.index,
      query: getQBRBillsDefaultFilters(QBR_BILL_LIST_TAB_TYPE.OPEN),
      state: { name: 'initialState' },
    });
  };

  const collectVendorEmail = async () => {
    await setShowCollectVendorEmailModal((prevState) => !prevState);
  };

  const onSuccess = async () => {
    setCurrentState(SUCCESS_PAGE_STATES.EMAIL_UPDATED);
    goToBillList();
  };

  const getOwnedVendor = useCallback(async () => {
    if (vendor?.id) {
      await getOwnedVendorExists({
        orgId,
        id: vendor?.id,
      });
    }
  }, [vendor, getOwnedVendorExists, orgId]);

  useEffect(() => {
    if (vendor) {
      getOwnedVendor();
    }
  }, [getOwnedVendor, vendor]);

  useEffect(() => {
    if (isOwnedVendorExists !== null) {
      setCurrentState(getCurrentStateByVendor(vendor, isOwnedVendorExists));
    }
  }, [isOwnedVendorExists, vendor]);
  const vendorName =
    deliveryMethod?.paperCheck?.printName || vendor?.printOnCheck || vendor?.companyName;

  const getAddressView = (dm) =>
    `${dm?.paperCheck?.addressLine1} ${dm?.paperCheck?.city}, ${dm?.paperCheck?.state} ${dm?.paperCheck?.zipCode}`;

  const TEXT_VALUES_BY_DELIVERY_METHOD_TYPE = {
    [CONSTS.DELIVERY_TYPE.ACH]: {
      deliveryMethodInfo: getAccountNumber4digits(deliveryMethod?.bankAccount),
      title: 'qbr.success.achTitle',
      toSubtitle: 'qbr.success.toSubtitleAch',
      linkLabel: 'qbr.success.done',
      linkVariant: CONSTS.BUTTON_VARIANT.SECONDARY,
    },
    [CONSTS.DELIVERY_TYPE.CHECK]: {
      deliveryMethodInfo: getAddressView(deliveryMethod),
      title: 'qbr.success.checkTitle',
      toSubtitle: 'qbr.success.toSubtitleCheck',
      linkLabel: 'qbr.success.done',
      linkVariant: CONSTS.BUTTON_VARIANT.SECONDARY,
    },
    [CONSTS.DELIVERY_TYPE.VIRTUAL]: {
      deliveryMethodInfo: deliveryMethod?.virtualAccount?.email || vendor?.contactEmail,
      title: 'qbr.success.virtualDMTitle',
      toSubtitle: 'qbr.success.toSubtitleVirtual',
      note: 'qbr.success.scheduledNoteVirtual',
      linkLabel: 'qbr.success.gotIt',
      linkVariant: CONSTS.BUTTON_VARIANT.PRIMARY,
    },
  };

  const textValues = {
    amount: payment?.totalAmount,
    processDate: <MIFormattedDate date={payment?.scheduledDate} />,
    vendorName,
    totalAmount: payment.totalAmount,
    deliveryEta: payment.deliveryEta,
    maxDeliveryEta: payment.maxDeliveryEta,
    scheduledDate: payment.scheduledDate,
    ...TEXT_VALUES_BY_DELIVERY_METHOD_TYPE[deliveryMethod?.deliveryType ?? vendor?.contactEmail],
  };

  const ACTION_LABEL_BY_STATE = {
    [SUCCESS_PAGE_STATES.HAS_VENDOR_EMAIL]: {
      action: collectVendorEmail,
      linkLabel: 'qbr.success.done',
    },
    [SUCCESS_PAGE_STATES.NO_VENDOR_EMAIL]: {
      action: collectVendorEmail,
      label: 'qbr.success.emailThem',
      linkLabel: 'qbr.success.done',
    },
    [SUCCESS_PAGE_STATES.EMAIL_UPDATED]: {
      action: collectVendorEmail,
      linkLabel: 'qbr.success.done',
    },
  };

  if (isGettingOwnedVendorData || isOwnedVendorExists === null) {
    return <AreaLoader />;
  }

  const { action, label, linkLabel } = ACTION_LABEL_BY_STATE[currentState];

  return (
    <>
      {showCollectVendorEmailModal && (
        <VendorEmailModal
          onSuccess={onSuccess}
          onCloseModal={goToBillList}
          vendorId={vendor?.id}
          vendorEmail={vendor?.contactEmail || ''}
          vendorName={vendorName}
        />
      )}
      <QBRSuccessLayout
        textValues={textValues}
        buttonLabel={label}
        buttonAction={action}
        linkLabel={linkLabel}
        linkAction={goToBillList}
        isLoading={false}
      />
    </>
  );
};

export default SuccessPage;
