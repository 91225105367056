import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import JustPayLayoutPage from 'src/app/pages/just-pay/JustPayLayoutPage';
import { AreaLoader } from '@melio/billpay-design-system';
import useUpdateUserPreference from 'src/app/modules/users/hooks/useUpdateUserPreferences';
import Upload10KInvoiceFileLayout from 'src/app/components/layout/Upload10KInvoiceFileLayout';
import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import { WizardSelectField } from 'src/app/ui/form/WizardSelectField';
import { getCurrencyMask } from 'src/app/utils/currency-utils';
import { MIFormattedText } from 'src/app/utils/formatting';
import { JustPayLayoutPageRelativeSteps } from 'src/app/pages/just-pay/justPayTypes';
import { devices } from 'src/app/theme/AppDevices';
import { getUserPreferences } from 'src/app/redux/user/selectors';
import { redirectToPayBills } from 'src/app/utils/external-events';
import { useModal } from 'src/app/helpers/react/useModal';
import analytics from 'src/app/services/analytics';
import { DELIVERY_TYPE, FLOW_ORIGIN } from 'src/app/utils/consts';
import intuit from 'src/app/utils/intuit';
import { SelectVendor } from 'src/app/components/common/SelectVendor';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { OrganizationPreferencesType } from 'src/app/utils/types';
import { useJustPayAddPaymentInfoState } from '../hooks/useJustPayAddPaymentInfoState';
import { useUpload10KInvoice } from '../hooks/useUpload10KInvoice';
import { JustPayIntroModal } from './JustPayIntroModal';
import { ModelViewField } from '../../../ui/form';

type Props = {
  onNext: (path: string) => () => void;
  onPrev: () => void;
  onExit: () => void;
};

export const JustPayAddPaymentInfo = ({ onNext, onPrev, onExit }: Props) => {
  const {
    vendorOptions,
    categoryOptions,
    paymentInfoMV,
    paymentInfoMVActions,
    isBillAmountRequiresInvoiceFile,
    isVendorListLoading,
    isCategoryListLoading,
    loadingMV,
    payment,
    flowOrigin,
    selectedVendor,
  } = useJustPayAddPaymentInfoState({ onNext });
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const isVendorWithInternationalDM = selectedVendor?.deliveryMethods?.find(
    (dm) => dm.deliveryType === DELIVERY_TYPE.INTERNATIONAL
  );
  const isInternationalVendorDisable =
    isVendorWithInternationalDM && !organizationPreferences.isEligibleForInternationalPayment;
  const { updateUserPreference } = useUpdateUserPreference();
  const userPreferences = useSelector(getUserPreferences);
  const {
    fileName,
    isUploading,
    isUploadError,
    handleSelectFile,
    handleRetry,
    onDeleteAttachment,
  } = useUpload10KInvoice({
    analyticsData: {
      trackingBillId: payment.trackingBillId,
    },
  });

  const onSubmitLayoutPage = () => {
    /*
      TODO: @denis-melio
      need to fix memory leak on redirect (the same bug in QBR flow)
  */
    paymentInfoMVActions.submit();
  };

  const onContinueIntroModalClick = () => {
    updateUserPreference({ key: 'isJustPayIntuitEntrypointAware', value: true });
  };

  const onPayBillsRedirectClick = () => {
    analytics.trackAction('pay-bills-redirect');
    redirectToPayBills();
  };

  const [JustPayIntroModalElement, showJustPayIntroModal] = useModal(JustPayIntroModal, {
    onContinueClick: onContinueIntroModalClick,
    onPayBillsRedirectClick,
    modalName: 'intro-modal',
  });

  useEffect(() => {
    intuit.endLoadingWrapper();
    intuit.hideFTULoadingWrapper();
  }, []);

  useEffect(() => {
    if (
      flowOrigin === FLOW_ORIGIN.INTUIT_ENTRYPOINT &&
      !userPreferences.isJustPayIntuitEntrypointAware
    ) {
      showJustPayIntroModal();
    }
  }, []);

  if (isVendorListLoading || isCategoryListLoading || loadingMV) {
    return <AreaLoader />;
  }

  return (
    <JustPayAddPaymentInfoContainer>
      {JustPayIntroModalElement}
      <JustPayLayoutPage
        headerLabel="justPay.wizard.header"
        title="justPay.paymentInfo.title"
        onNext={onSubmitLayoutPage}
        goExit={onExit}
        onPrev={onPrev}
        relativeStep={JustPayLayoutPageRelativeSteps.JustPayAddPaymentInfo}
        contentWrapperMode="alignment-top"
        isQboFooter
        isNextDisabled={isInternationalVendorDisable}
        nextButtonTooltip="justPay.paymentInfo.international.disableTooltip"
      >
        <SelectVendor model={paymentInfoMV.vendor} initialVendorOptions={vendorOptions} />
        <WizardSelectField
          label="justPay.paymentInfo.categorySelectTitle"
          placeholder="justPay.paymentInfo.categorySelectPlaceHolder"
          options={categoryOptions}
          model={paymentInfoMV.category}
        />
        <InlineTextInputField
          label="justPay.paymentInfo.amount.title"
          model={paymentInfoMV.amount as ModelViewField<string>}
          placeholder="justPay.paymentInfo.amount.placeholder"
          mask={getCurrencyMask()}
          errorMessageIcon={<ErrorIcon />}
          required
          privateData
          className="justPay-paymentInfo-amount"
        />
        {isBillAmountRequiresInvoiceFile && (
          <>
            <Upload10kInvoiceTitle>
              <MIFormattedText label="justPay.paymentInfo.instruction" />
            </Upload10kInvoiceTitle>
            <Upload10KInvoiceFileLayout
              fileName={fileName}
              isFileUploading={isUploading}
              isFileUploadError={isUploadError}
              onSelectFile={handleSelectFile}
              onRetry={handleRetry}
              onChangeInvoiceFile={handleSelectFile}
              onDeleteInvoiceFile={onDeleteAttachment}
            />
          </>
        )}
      </JustPayLayoutPage>
    </JustPayAddPaymentInfoContainer>
  );
};

const JustPayAddPaymentInfoContainer = styled.div`
  .justPay-paymentInfo-amount {
    .input-container {
      @media ${devices.mobile}, ${devices.phablet} {
        margin-bottom: 0;
      }
    }
  }
`;

const Upload10kInvoiceTitle = styled.div`
  margin-top: 0.25rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.text.color.arrow};
`;

const ErrorIcon = styled.i.attrs({
  className: 'icon-warning-icon',
})`
  font-size: 1.6rem;
  margin-right: 0.4rem;
`;
