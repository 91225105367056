import { MutableRefObject } from 'react';
import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { compose } from 'recompose';
import styled, { css } from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { withBreak } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';

type Props = {
  id: string;
  value: string | number | null;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  eventPage: string;
  intl: IntlShape;
  onFocus: () => void;
  onBlur: () => void;
  onChange: (value: { id: string; value: string }) => void;
  onClear: () => void;
  onSubmit: () => void;
  device: { isMobile: boolean };
  inputRef?: MutableRefObject<HTMLInputElement>;
};

class MISearchBar extends React.PureComponent<Props> {
  static defaultProps = {
    disabled: false,
    placeholder: '',
    autoFocus: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onFocus: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onBlur: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClear: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    testId: '',
  };

  constructor(props: Props) {
    super(props);

    this.searchInputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { value, onSubmit, inputRef } = this.props;

    if (prevProps.value !== value && !value) {
      onSubmit();
    }

    if (inputRef && this.searchInputRef.current) {
      inputRef.current = this.searchInputRef.current;
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { disabled, onChange, id } = this.props;

    if (!disabled && onChange) {
      onChange({ id, value: e.currentTarget.value });
    }
  };

  handleSubmit = (event: any) => {
    const { onSubmit, device, eventPage, value } = this.props;

    event.preventDefault();

    if (device.isMobile && this.searchInputRef.current) {
      this.searchInputRef.current.blur();
    }

    analytics.track(eventPage, 'search-enter', { searchTerm: value });
    onSubmit();
  };

  handleSearchIconClick = () => {
    const { value, onSubmit, eventPage } = this.props;

    if (value) {
      analytics.track(eventPage, 'search-icon', { searchTerm: value });
      onSubmit();
    }
  };

  handleClearClick = () => {
    const { onClear, eventPage } = this.props;

    analytics.track(eventPage, 'search-clear');
    onClear();
  };

  searchInputRef: { current: HTMLInputElement | null };

  render() {
    const { id, placeholder, intl, disabled, value, autoFocus, onFocus, onBlur } = this.props;
    const placeholderText = !placeholder ? '' : intl.formatMessage({ id: placeholder });

    return (
      <SearchBarForm onSubmit={this.handleSubmit} action=".">
        <SearchBarInput
          ref={this.searchInputRef}
          id={id}
          name={id}
          disabled={disabled}
          value={value || ''}
          placeholder={placeholderText}
          type="search"
          autoFocus={autoFocus}
          onChange={this.handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid="search-input"
        />
        <SearchIcon
          isSearchable={!!value}
          onClick={this.handleSearchIconClick}
          className="icon-search-magnifier-icon"
        />
        {value && <ClearIcon className="icon-close-icon" onClick={this.handleClearClick} />}
      </SearchBarForm>
    );
  }
}

export default compose(withBreak())(injectIntl(MISearchBar));

const SearchBarForm = styled.form`
  width: 100%;
  position: relative;
`;

const SearchBarInput = styled.input`
  width: 100%;
  height: ${(props) => props.theme.sizes.searchBarHeight};
  background-color: ${(props) => props.theme.colors.dark.translucent05};
  padding-left: 4.8rem;
  padding-right: 4.2rem;
  border: none;
  outline: none;
  line-height: ${(props) => props.theme.sizes.searchBarHeight};
  color: ${(props) => props.theme.text.color.main};
  border-radius: 0.6rem;
  font-size: ${(props) => props.theme.text.size.regular};
  transition: ${(props) => props.theme.animation.transition.default};
  -webkit-appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: ${(props) => props.theme.colors.dark.translucent3};
    font-size: ${(props) => props.theme.text.size.regular};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.white.opaque};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.white.opaque};
    box-shadow: 0 0.5rem 1rem 0 ${(props) => props.theme.colors.dark.translucent2};
  }

  @media ${devices.nonDesktop} {
    background-color: ${(props) => props.theme.colors.white.opaque};
    box-shadow: 0 0.5rem 1rem 0 ${(props) => props.theme.colors.dark.translucent2};

    &:hover {
      background-color: ${(props) => props.theme.colors.white.opaque};
    }

    &:focus {
      background-color: ${(props) => props.theme.colors.white.opaque};
    }
  }
`;

const iconStyles = css`
  position: absolute;
  z-index: 2;
  display: block;
  line-height: ${(props) => props.theme.sizes.searchBarHeight};
  vertical-align: middle;
  text-align: center;
  color: ${(props) => props.theme.colors.dark.translucent3};

  ${SearchBarInput}:focus ~ & {
    color: ${(props) => props.theme.colors.dark.opaque};
  }
`;

const SearchIcon = styled.i<{ isSearchable?: boolean }>`
  ${iconStyles}

  cursor: ${(props) => (props.isSearchable ? 'pointer' : 'default')};
  width: 4.8rem;
  font-size: 2.4rem;
  top: 0;
  left: 0;
`;

const ClearIcon = styled.i`
  ${iconStyles}

  cursor: pointer;
  width: ${(props) => props.theme.sizes.searchBarHeight};
  font-size: 1.6rem;
  top: 0;
  right: 0;
`;
