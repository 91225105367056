import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, Flex } from '@melio/billpay-design-system';
import {
  Arrow,
  ArrowShadow,
  BodyContainer,
  CloseIcon,
  flexFooterButtonsStyles,
  flexFooterStyles,
  mainContainerStyles,
  StepContainer,
  StepsText,
  TitleContainer,
} from './HelpGuide.styles';
import { getPosition } from './HelpGuide.utils';

export interface StepParams {
  parentSelector: string;
  titleLabel: string;
  bodyLabel: string;
  position:
    | string
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'left-start'
    | 'left'
    | 'left-end'
    | 'right-start'
    | 'right'
    | 'right-end';
}

export interface HelpGuideProps {
  nextLabel: string;
  prevLabel: string;
  doneLabel: string;
  ofLabel: string;
  startStep?: number;
  isOpen: boolean;
  isOneStep?: boolean;
  onClose: () => void;
  onOpen?: () => void;
  steps: StepParams[];
}

export const HelpGuide: FC<HelpGuideProps> = ({
  steps,
  nextLabel,
  prevLabel,
  doneLabel,
  ofLabel,
  startStep = 0,
  isOpen,
  isOneStep = false,
  onClose,
  onOpen,
}) => {
  const { formatMessage } = useIntl();
  const [currentStep, setCurrentStep] = useState(startStep);
  const [position, setPosition] = useState({});
  const [tooltipClosed, setTooltipClosed] = useState(false);

  const nextStep = useCallback(
    (event) => {
      if (currentStep !== steps.length - 1) {
        setCurrentStep(currentStep + 1);
        event.target.blur();
      }
    },
    [currentStep]
  );

  const prevStep = useCallback(
    (event) => {
      if (currentStep !== 0) {
        setCurrentStep(currentStep - 1);
        event.target.blur();
      }
    },
    [currentStep]
  );

  useEffect(() => {
    const relElm: DOMRect | undefined = document
      .querySelector(steps[currentStep]?.parentSelector as string)
      ?.getBoundingClientRect();

    const myStepElm: DOMRect | undefined = document
      .getElementById('current-guide-step')
      ?.getBoundingClientRect();
    const elmPosition = getPosition(relElm, myStepElm, steps[currentStep]?.position);

    setPosition(elmPosition);
  }, [currentStep, isOpen]);

  useEffect(() => {
    setCurrentStep(0);

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'revert';
    }
  }, [isOpen]);

  useEffect(() => {
    if (!tooltipClosed && isOpen && typeof onOpen === 'function') {
      setTooltipClosed(true);
      onOpen();
    }
  }, [isOpen, onOpen, tooltipClosed]);

  const backButton = () =>
    currentStep !== 0 && (
      <Button size="md" variant="tertiary" onClick={prevStep} data-testid="help-guide-prev-btn">
        {formatMessage({ id: prevLabel })}
      </Button>
    );

  const renderButtons = () => {
    if (!isOneStep && currentStep !== steps.length - 1) {
      return (
        <>
          {backButton()}
          <Button size="md" variant="primary" onClick={nextStep} data-testid="help-guide-next-btn">
            {formatMessage({ id: nextLabel })}
          </Button>
        </>
      );
    }

    return (
      <>
        {backButton()}
        <Button size="md" variant="primary" onClick={onClose} data-testid="help-guide-done-btn">
          {formatMessage({ id: doneLabel })}
        </Button>
      </>
    );
  };

  return isOpen && !!steps?.length ? (
    <Box {...position} {...mainContainerStyles} data-testid="help-guide-box">
      <StepContainer>
        <Arrow className={steps[currentStep].position}>
          <ArrowShadow />
        </Arrow>
        <TitleContainer data-testid="help-guide-title">
          <Box>{formatMessage({ id: steps[currentStep].titleLabel })}</Box>
          <CloseIcon
            className="icon-close-icon"
            onClick={onClose}
            data-testid="help-guide-close-btn"
          />
        </TitleContainer>
        <BodyContainer data-testid="help-guide-body">
          {formatMessage({ id: steps[currentStep].bodyLabel })}
        </BodyContainer>
        <Flex {...flexFooterStyles}>
          <StepsText>
            {!isOneStep && (
              <>
                {currentStep + 1} {formatMessage({ id: ofLabel })} {steps.length}
              </>
            )}
          </StepsText>
          <Flex {...flexFooterButtonsStyles}>{renderButtons()}</Flex>
        </Flex>
      </StepContainer>
    </Box>
  ) : null;
};
