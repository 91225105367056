import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useUpdateVendorEmail } from 'src/app/modules/vendors/vendor-email/hooks/useUpdateVendorEmail';
import { validateVendorEmail } from 'src/app/modules/vendors/vendor-email/utils/validate-user-email';
import { useForm, ValidationErrors } from 'src/app/ui/form';
import ValidationError from 'src/app/ui/ValidationError';
import { MemoStepFormState } from '../types';

type Props = {
  onSubmitSuccess: () => void;
  shouldAskForEmail: boolean;
  vendorId: number;
  initialValues: Record<keyof MemoStepFormState, string | null | undefined>;
  onChange: (values: Partial<MemoStepFormState>) => void;
  onEmailUpdateSuccess: (newVendorEmail: string) => void;
  onValidationErrors: (errors: ValidationErrors<MemoStepFormState>) => void;
};

export const useVendorMemoStepForm = ({
  onSubmitSuccess,
  shouldAskForEmail,
  vendorId,
  initialValues,
  onChange,
  onEmailUpdateSuccess,
  onValidationErrors,
}: Props) => {
  const { updateVendorEmail } = useUpdateVendorEmail(vendorId);

  const model: MemoStepFormState = useMemo(
    () => ({
      memo: initialValues.memo || '',
      contactEmail: initialValues.contactEmail || '',
    }),
    []
  );

  const onSubmit = async ({ contactEmail }: MemoStepFormState) => {
    if (!shouldAskForEmail) {
      onSubmitSuccess();

      return;
    }

    const { isValid, validationErrors } = validateVendorEmail(contactEmail, {
      required: false,
    });

    if (!isValid) {
      onValidationErrors(validationErrors);
      throw new ValidationError({ validationErrors });
    }

    if (!isEmpty(contactEmail)) {
      await updateVendorEmail(contactEmail);
      onEmailUpdateSuccess(contactEmail);
    }

    onSubmitSuccess();
  };

  const [formMV, { submit }, , loading] = useForm<MemoStepFormState>(model, {
    submit: onSubmit,
    onChange: ({ modelState, key, value }) => {
      const onlyChangedValues = {
        [key]: value,
      };

      onChange(onlyChangedValues);

      return modelState;
    },
  });

  return {
    formMV,
    submit,
    shouldAskForEmail,
    loading,
  };
};
