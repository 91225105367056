const index = '/orgs/:orgId/vendors/:id/delivery-method/international';

export const internationalLocations = {
  base: [index],
  index,
  paymentDetails: `${index}/payment-details`,
  vendorAddress: `${index}/address`,
  purposeOfPayment: `${index}/purpose-of-payment`,
  missingDetails: `${index}/missing-details`,
  ultimateBeneficialOwners: `${index}/ultimate-beneficial-owners`,
};
