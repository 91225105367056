import React from 'react';
import styled from 'styled-components';
import { differenceInBusinessDays } from 'date-fns';
import moment from './moment-business-days';
import { MIFormattedDate } from './formatting';
import deliveryApi from '../services/api/delivery';

function getEndOfLifeMaxDate(isFirstWave = false) {
  return isFirstWave
    ? moment('2024-01-03 21:59:00Z').toDate()
    : moment('2024-05-22 20:59:59Z').toDate();
}

function isAfterEndOfLife(date, isFirstWave) {
  return moment(date).isSameOrAfter(getEndOfLifeMaxDate(isFirstWave));
}

function isEndOfLifeMonth(date, isFirstWave) {
  return moment(date).isSame(getEndOfLifeMaxDate(isFirstWave), 'month');
}

function isBusinessDay(date) {
  return moment(date).isBusinessDay();
}

function isSameDay(date) {
  return moment(date).isSame(moment(), 'day');
}

function isTomorrow(date) {
  const tomorrow = new Date();

  tomorrow.setDate(new Date().getDate() + 1);

  return moment(date).isSame(moment(tomorrow), 'day');
}

function isAfterDateBy(date, amountOfDays) {
  const dayToCheck = new Date();

  dayToCheck.setDate(new Date().getDate() + amountOfDays);

  return moment(date).isSame(moment(dayToCheck), 'day');
}

function isDatePassed(date) {
  const today = new Date();

  today.setDate(new Date().getDate());

  return moment(date).isBefore(moment(today), 'day');
}

function getDifferenceInBusinessDays(firstDate, secondDate) {
  return differenceInBusinessDays(firstDate, secondDate);
}

function isTomorrowBusinessDay() {
  const tomorrow = new Date();

  tomorrow.setDate(new Date().getDate() + 1);

  return isBusinessDay(tomorrow);
}

function getClosestBusinessDay(date = moment()) {
  if (isBusinessDay(date)) {
    return date;
  }

  return moment(date).nextBusinessDay();
}

// eslint-disable-next-line react/prop-types
function DeliveryDateFormat({ date, maxDate }) {
  if (!maxDate || moment(date).isSameOrAfter(moment(maxDate), 'day')) {
    return <MIFormattedDate date={date} />;
  }

  const toDate = moment(maxDate);

  let toDateValue = <MIFormattedDate date={toDate} />;

  let fromDateValue = <MIFormattedDate date={date} />;

  if (moment(date).isSame(toDate, 'year')) {
    fromDateValue = <MIFormattedDate year={undefined} date={date} />;

    if (moment(date).isSame(toDate, 'month')) {
      toDateValue = moment(toDate).format('D, YYYY');
    }
  }

  return (
    <>
      {fromDateValue}
      {' - '}
      {toDateValue}
    </>
  );
}

// eslint-disable-next-line react/prop-types
function DeliveryDate({ date, maxDate, fontType }) {
  const formattedDate = DeliveryDateFormat({
    date,
    maxDate,
  });

  return (
    <>
      <DeliveryDateContainer data-testid="delivery-date" fontType={fontType}>
        {formattedDate}
      </DeliveryDateContainer>
    </>
  );
}

const DeliveryDateContainer = styled.span`
  white-space: nowrap;
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.dates?.DeliveryDateContainer}
`;

async function getInitialProcessingDates({
  scheduledDate,
  orgId,
  deliveryMethodId,
  fundingSourceId,
  amount,
  dueDate,
  paymentId,
  payBillFlowUUID,
  isFirstWave,
}) {
  const minScheduledDateRequest = deliveryApi.getDeliveryTime(
    orgId,
    new Date(),
    deliveryMethodId,
    fundingSourceId,
    amount,
    paymentId,
    payBillFlowUUID
  );

  let deliveryDateRequest = minScheduledDateRequest;

  if (scheduledDate) {
    deliveryDateRequest = deliveryApi.getDeliveryTime(
      orgId,
      scheduledDate,
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID
    );
  } else if (dueDate) {
    deliveryDateRequest = deliveryApi.getLatestProcessingDate(
      orgId,
      new Date(dueDate),
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID
    );
  }

  const [
    { suggestedScheduledDate: minScheduledDate },
    { suggestedScheduledDate, deliveryDate, maxDeliveryDate, deliveryOptions },
  ] = await Promise.all([minScheduledDateRequest, deliveryDateRequest]);

  let maxEOLDeliveryDate;

  if (!maxDeliveryDate) {
    maxEOLDeliveryDate = getEndOfLifeMaxDate(isFirstWave);
  } else {
    maxEOLDeliveryDate = isAfterEndOfLife(maxDeliveryDate, isFirstWave)
      ? getEndOfLifeMaxDate(isFirstWave)
      : maxDeliveryDate;
  }

  return {
    suggestedScheduledDate,
    deliveryDate,
    maxDeliveryDate: maxEOLDeliveryDate,
    minScheduledDate,
    deliveryOptions,
  };
}

const getMinScheduledDate = async ({ orgId, deliveryMethodId, fundingSourceId, amount }) => {
  const { suggestedScheduledDate } = await deliveryApi.getDeliveryTime(
    orgId,
    new Date(),
    deliveryMethodId,
    fundingSourceId,
    amount
  );

  return suggestedScheduledDate;
};

/**
 * Checks if @checkDate is within the next two working days starting from @fromDate
 *
 * @param {number | Date} fromDate
 * @param {number | Date} checkDate
 * @returns boolean
 */
const isWithinNextTwoWorkingDaysIntervalFromDate = (fromDate, checkDate) => {
  const diff = differenceInBusinessDays(checkDate, fromDate);

  return diff >= 0 && diff < 3;
};

export {
  isBusinessDay,
  isSameDay,
  isTomorrow,
  DeliveryDate,
  getInitialProcessingDates,
  getClosestBusinessDay,
  DeliveryDateFormat,
  getMinScheduledDate,
  isAfterDateBy,
  isDatePassed,
  isTomorrowBusinessDay,
  getDifferenceInBusinessDays,
  isWithinNextTwoWorkingDaysIntervalFromDate,
  getEndOfLifeMaxDate,
  isAfterEndOfLife,
  isEndOfLifeMonth,
};
