import React, { useCallback, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { PayBillFundingSourcePageContainer } from 'src/app/pages/bill/pay/PayBillFundingSourcePageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getBill, getPayment } from 'src/app/redux/payBillWizard/selectors';
import locations from 'src/app/utils/locations';
import { flagsStore } from 'src/app/modules/flags/flags-store';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import VendorFlowRouter from 'src/app/pages/vendor/delivery-methods/international/router';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { InternationalBillPage } from './components/InternationalBillPage';

export const InternationalEPRouter = () => {
  const [historyPush] = useHistoryWithOrgId();
  const payment = useSelector(getPayment);
  const bill = useSelector(getBill);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(flagsStore.actions.setIsInternationalEntryPoint(true));
  }, []);

  const exitUrl = '/orgs/:orgId/bills/qb-dashboard-list';

  const onExit = useCallback(() => {
    historyPush({ path: exitUrl });
  }, []);

  return (
    <Switch>
      <SmartRoute exact path={locations.MainApp.dashboardInternationalBill.url()}>
        <InternationalBillPage onPrev={onExit} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.Bills.pay.funding.url()} exact>
        <PayBillFundingSourcePageContainer
          id={bill.id}
          paymentId={payment.id}
          prevStepURL={locations.MainApp.dashboardInternationalBill.url()}
          nextStepURL={locations.Bills.pay.deliveryMethod.url()}
        />
      </SmartRoute>
      <SmartRoute path={locations.MainApp.dashboardInternationalBill.url()}>
        <VendorFlowRouter />
      </SmartRoute>
    </Switch>
  );
};
