import React from 'react';
import { RecordOf } from 'immutable';
import { DeliveryMethodRecord } from 'src/app/pages/vendor/records-constants';
import { DeliveryMethodType } from 'src/app/utils/types';
import DeliveryMethodsSelect from 'src/app/components/common/SelectMethods/containers/DeliveryMethodsSelect';
import { SelectedMethodForSharedVendor } from 'src/app/components/common/SelectMethods/components/SelectedMethodForSharedVendor';
import { Bill } from 'src/app/pages/bill/pay/hooks/usePartialPayments';
import { isSharedVendor } from 'src/app/pages/vendor/utils';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  onChangeDM: (dm: DeliveryMethodType) => void;
  onAddDmMethod: (DELIVERY_TYPE) => void;
  deliveryMethods: RecordOf<DeliveryMethodType>[];
  selectedDeliveryMethod: DeliveryMethodType | undefined;
  selectedFundingSource: FundingSource | undefined;
  bill: Bill;
  hasPartialAmount: boolean;
  onToggleVirtualCardInfoModal: () => void;
};

export const SidePanelDeliveryMethodSection = ({
  selectedDeliveryMethod,
  deliveryMethods,
  bill,
  hasPartialAmount,
  selectedFundingSource,
  onChangeDM,
  onAddDmMethod,
  onToggleVirtualCardInfoModal,
}: Props) =>
  isSharedVendor(bill?.vendor) ? (
    <SelectedMethodForSharedVendor label={bill?.vendor?.companyName} />
  ) : (
    <DeliveryMethodsSelect
      vendor={bill?.vendor}
      deliveryMethods={deliveryMethods}
      fundingSource={selectedFundingSource}
      value={DeliveryMethodRecord(selectedDeliveryMethod)}
      errorLabel={
        !selectedDeliveryMethod ? 'batchPayment.sidePanel.deliveryMethodError' : undefined
      }
      companyName={bill?.vendor?.companyName}
      hasPartialAmount={hasPartialAmount}
      onChange={onChangeDM}
      allowVirtualDeliveryMethod={selectedFundingSource?.isVerified}
      onAddMethod={(dmType) => onAddDmMethod(dmType)}
      onToggleVirtualCardInfoModal={onToggleVirtualCardInfoModal}
    />
  );
