import api from 'src/app/services/api/deliveryMethods';
import paymentStore from 'src/app/modules/payments/payment-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import { LOAD_DELIVERY_METHODS_SUCCESS } from 'src/app/redux/user/actionTypes';
import { selectCreateIsLoading } from 'src/app/helpers/redux/restCreateSlice';
import { achDeliveryMethodDetailsActions } from 'src/app/version-2/pages/ach-delivery-method-details/modules/achDeliveryMethodDetails.slice';
import { DeliveryMethodType, EditableDeliveryMethodType } from 'src/app/utils/types';
import { getValidationErrors } from '@melio/sizzers-js-common';
import { CONSTS } from 'src/app/utils/consts';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
// import { validateEmail } from 'src/app/pages/vendor/delivery-methods/utils';

import {
  replaceVirtualDeliveryMethodSlice,
  fetchUnilateralRequestDetailsWithTokenSlice,
  whitePagesAddressSlice,
  copyFromOwnedVendorWithUnilateralToken,
  replaceDeliveryMethodSlice,
  copyFromOwnedVendorSlice,
  updateWithUnilateralToken,
  updateDeliveryMethodACHSlice,
  addCardAccountWithToken,
  clearWhitePageAddressSlice,
  shiftVirtualCardToACHPaymentStatusCollectedSlice,
} from './delivery-methods-slice';

export const name = 'deliveryMethods';

const createDeliveryMethodIsLoading = selectCreateIsLoading('default');

const persistConfig = {
  whitelist: ['shouldCloseVerifyIndicator'],
};

type DeliveryMethodStoreType = {
  orgId: string;
  vendorId: string;
  id: string;
  deliveryMethod: DeliveryMethodType;
};

type DeliveryMethodStoreInitialStateType = {
  validation: {
    loading: boolean;
    invalidTokenData: any | null;
    deliveryMethodId: string;
  };
};

const deliveryMethodsStore = createRestfulSlice<
  DeliveryMethodStoreType,
  DeliveryMethodStoreInitialStateType
>({
  name,
  schemaName: 'deliveryMethod',
  persistConfig,
  api: {
    create({ orgId, vendorId, ...params }) {
      return api.addDeliveryMethod({ orgId, vendorId, params }).then((res) => res.deliveryMethod);
    },
    update({ orgId, vendorId, id, deliveryMethod }) {
      return api
        .editDeliveryMethodById(orgId, vendorId, id, deliveryMethod)
        .then((res) => res.deliveryMethod);
    },
    fetch: ({ orgId, vendorId, id }) =>
      api.getDeliveryMethodById({ orgId, vendorId, id }).then((res) => res.deliveryMethod),
    list: ({ orgId, vendorId }) =>
      api.getDeliveryMethods(orgId, vendorId).then((res) => ({ items: res.deliveryMethods })),
  },
  // TODO: need to fix type mismatch "createRestfulSlice" gets "DeliveryMethodStoreType" but "deliveryMethod" was sending as "DeliveryMethodType"
  async validateFunc(deliveryMethod: any) {
    if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
      const validateEmail = (email) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(String(email).toLowerCase());
      };

      return validateEmail(deliveryMethod.virtualCardAccount.accountEmail)
        ? null
        : {
            'virtualCardAccount.accountEmail':
              'inputErrors.deliveryMethod.virtualAccount.email.string.email',
          };
    }

    const res = await getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
  extraReducers: {
    [LOAD_DELIVERY_METHODS_SUCCESS](state, { deliveryMethods }) {
      deliveryMethods.forEach((deliveryMethod) => {
        state.byId[deliveryMethod.id] = deliveryMethod;
      });
    },
    [paymentStore?.actions.fetchPaymentDetailsWithToken.success](state: any, { payload }) {
      payload.payment.vendor.deliveryMethods.forEach((dm) => {
        state.byId[dm.id] = dm;
      });
    },
    [paymentStore?.actions.fetchEmailToVendorDetails.success](state: any, { payload }) {
      payload.payment.vendor.deliveryMethods.forEach((dm) => {
        state.byId[dm.id] = dm;
      });
    },
    [qbDashboardListItemsStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        if (item.deliveryMethod) {
          state.byId[item.deliveryMethod.id] = item.deliveryMethod;
        }
      });
    },
    [achDeliveryMethodDetailsActions.deliveryMethodCreated.type](state, { payload }) {
      const { deliveryMethod } = payload;

      state.byId[deliveryMethod.id] = merge(state.byId[deliveryMethod.id] || {}, deliveryMethod);
    },
    [achDeliveryMethodDetailsActions.deliveryMethodUpdated.type](state, { payload }) {
      const { deliveryMethod } = payload;

      state.byId[deliveryMethod.id] = merge(state.byId[deliveryMethod.id] || {}, deliveryMethod);
    },
  },
  selectors: {
    byId: (deliveryMethodId) => (state) => state[name].byId[deliveryMethodId] as DeliveryMethodType,
    all: (state) => Object.values(state[name]?.byId || {}),
    byVendorId: (vendorId) => (state) =>
      Object.values<DeliveryMethodType>(state[name]?.byId || {}).filter(
        (dm) => dm.vendorId === vendorId
      ),
    manualAddress(deliveryMethodId) {
      const paymentSelectors = {
        whitePageAddress(state) {
          return state[name].meta[deliveryMethodId]?.whitePageAddress;
        },
        isAddressLoading(state) {
          return state[name].meta[deliveryMethodId]?.loading;
        },
      };

      return paymentSelectors;
    },
    validation: (state) => state[name].validation,
    isCreating: (state) => createDeliveryMethodIsLoading()(state[name]),
  },
  slices: {
    clearWhitePageAddressSlice,
    replaceVirtualDeliveryMethod: replaceVirtualDeliveryMethodSlice,
    fetchUnilateralRequestDetails: fetchUnilateralRequestDetailsWithTokenSlice,
    validateAddress: whitePagesAddressSlice,
    copyFromOwnedVendorWithUnilateralToken,
    replaceDeliveryMethodSlice,
    updateDeliveryMethodACHSlice,
    copyFromOwnedVendorSlice,
    updateWithUnilateralToken,
    addCardAccountWithToken,
    shiftVirtualCardToACHPaymentStatusCollected: shiftVirtualCardToACHPaymentStatusCollectedSlice,
  },
});

export function getDeliveryMethodActions(dispatch) {
  return {
    async clearWhitePageAddress({ deliveryMethodId }) {
      return dispatch(clearWhitePageAddressSlice.actions.clear(deliveryMethodId));
    },
    async create({ orgId, vendorId, params }) {
      return dispatch(deliveryMethodsStore.actions.create({ orgId, vendorId, ...params }));
    },
    async edit({
      orgId,
      vendorId,
      id,
      deliveryMethod,
    }: {
      orgId: string;
      vendorId: string;
      id: string;
      deliveryMethod: EditableDeliveryMethodType;
    }) {
      return dispatch(
        deliveryMethodsStore.actions.update({
          orgId,
          vendorId,
          id,
          deliveryMethod,
        })
      );
    },
    async replaceDeliveryMethodWithToken({ token, deliveryMethod, orgId, vendorId }) {
      return dispatch(
        replaceDeliveryMethodSlice.actions({
          token,
          deliveryMethod,
          orgId,
          vendorId,
        })
      );
    },
    async updateDeliveryMethodWithToken({ token, deliveryMethod, orgId, vendorId }) {
      return dispatch(
        updateDeliveryMethodACHSlice.actions({
          token,
          deliveryMethod,
          orgId,
          vendorId,
        })
      );
    },
    async copyFromOwnedVendor({ orgId, vendorId, token }) {
      return dispatch(copyFromOwnedVendorSlice.actions({ orgId, vendorId, token }));
    },
  };
}

export default deliveryMethodsStore;
