import billApi from 'src/app/services/api/bills';
import { billsApi } from 'src/app/version-2/api/bills.api';
import { filesApi } from 'src/app/version-2/api/files.api';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { organizationApi } from 'src/app/version-2/api/organization.api';
import { paymentsApi } from 'src/app/version-2/api/payments.api';
import { FileUploadResponse } from 'src/app/version-2/api/reponses/FileUploadResponse';
import { userApi } from 'src/app/version-2/api/user.api';
import { vendorApi } from 'src/app/version-2/api/vendor.api';
import {
  analytics,
  clearPayBillWizardBill,
  pushNotification,
  utilsLocations,
} from 'src/app/version-2/externals';
import { ITEMS_PER_PAGE } from 'src/app/version-2/model/constants/dashboard';
import { FAST_DELIVERY_TYPES } from 'src/app/version-2/model/constants/fee';
import { Bill, DeliveryMethod } from 'src/app/version-2/model/dtos';
import { DeliveryOption } from 'src/app/version-2/model/dtos/deliveryOption';
import { AddFundingSourceWizardOriginEnum, DeliveryEnum } from 'src/app/version-2/model/enums';
import { FastFeeDeliveryEnum } from 'src/app/version-2/model/enums/fastFeeDelivery.enum';
import { NotificationVariantEnum } from 'src/app/version-2/model/enums/notificationVariant.enum';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { fundingSourcesSelectors } from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import { userActions, userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { SCHEDULE_PAYMENTS_LIMIT } from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkBanner.consts';
import { UploadInvoiceFile } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { DEDUCTION_DATE_TYPES } from 'src/app/version-2/pages/batch-bulk/renderers/DeductionDateSelect/DeductionDateSelect.consts';
import { getDefaultMemo } from 'src/app/version-2/utils/bills.utils';
import {
  fetchRedirectQuery,
  getItemIds,
  getScheduledPaymentsHandler,
  goQBDashboard,
} from 'src/app/version-2/utils/dashboard.utils';
import { batchBulkActions, batchBulkSelectors } from './batchBulk.slice';
import { createBatchBulkPaymentAdapter } from '../adapters/createBatchBulkPayment.adapter';
import { batchBulkApi } from '../api/batchBulk.api';
import { CreateBatchBulkPaymentResponse, FetchBulkPaymentIntentsResponse } from '../api/responses';
import {
  AMEX_INDUSTRY_MODAL_ERROR,
  AMEX_INDUSTRY_MODAL_SAVE,
  BATCH_BULK_EVENT_PAGE,
  EXPRESS_CHECK_EXPOSURE,
  FAST_ACH_EXPOSURE,
  FTU_FAST_PAYMENT_POPOVER_CONFIRM,
  LOAD_BATCH_PAYMENTS_TABLE,
  SAVE_EMAIL_FOR_BULK_RECONCILIATION,
  SCHEDULE_BATCH_PAYMENTS_REQUEST,
  SCHEDULE_BATCH_PAYMENTS_SUCCESS,
  TOGGLE_FAST_OPTION,
} from '../model/consts/batchBulkAnalytics.consts';
import { BatchBulkPaymentIntent } from '../model/dtos/batchBulkPaymentIntent';
import { isEligibleToBulkPayment, removeCharsAndParseIds } from '../utils/analytics.utils';
import {
  buildPaymentIntentDTOs,
  convertPaymentIntentIdToNumber,
  extractBatchListFromPreservedState,
  getUploadInvoiceFilesFromPaymentIntents,
  isBatchBulksHasFastExposed,
  mergePartialPaymentsWithBatchList,
} from '../utils/batchList.utils';

import { TakeableChannel } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

function* fetchBatchBulksHandler({ type, payload }) {
  const { preservedState, analyticsEventProps, partialPayments, fundingSourceId } = payload;

  let orgId = yield select(userSliceSelectors.selectOrgId);

  if (!orgId || !orgId.length) {
    // TODO - do this in a more infra way
    yield put(userActions.fetchUserProfile());
    orgId = yield select(userSliceSelectors.selectOrgId);
  }

  try {
    const isCombined = yield select(batchBulkSelectors.selectIsCombined);
    const billIds = yield select(batchBulkSelectors.selectBillIds);
    const fundingSources = yield select(fundingSourcesSelectors.selectValidFundingSources);
    const headerRepeatingPaymentMethod = yield select(
      batchBulkSelectors.selectHeaderRepeatingPaymentMethod
    );
    const selectedFundingSourceId = headerRepeatingPaymentMethod || fundingSourceId;
    const fetchFunction = isCombined ? batchBulkApi.fetchBulks : batchBulkApi.fetchBatches;

    if (!billIds || billIds.length === 0) return;

    yield put(batchBulkActions.setIsLoading(true));
    yield put(batchBulkActions.setIsBulkPaymentMethod(false));

    const params = {
      orgId,
      billIds: billIds.slice(0, SCHEDULE_PAYMENTS_LIMIT),
      fundingSourceId: selectedFundingSourceId,
    };

    let batchListPrepared: BatchBulkPaymentIntent[] = [];

    if (!selectedFundingSourceId && preservedState?.batchList) {
      batchListPrepared = extractBatchListFromPreservedState({
        preservedState,
        fundingSources,
      });
    } else {
      yield put(batchBulkActions.paymentIntentInit());
      yield put(batchBulkActions.billsDefaultMemoInit());
      const batchBulksResponse: FetchBulkPaymentIntentsResponse = yield call(fetchFunction, params);
      const { batchList } = batchBulksResponse;

      const organizationPreferences = yield select(
        (state) => state.organization.organizationPreferences
      );
      const isEligibleForInternationalPayment =
        organizationPreferences.isEligibleForInternationalPayment;

      const withPayBillFlowUUID = batchList.map((paymentIntent) => {
        if (!paymentIntent.payment.payBillFlowUUID) {
          paymentIntent.payment.payBillFlowUUID = uuidv4();
        }

        return paymentIntent;
      });

      batchListPrepared = withPayBillFlowUUID;

      yield put(batchBulkActions.setIsScheduleDisabled(false));
      yield put(batchBulkActions.setIsScheduledBlocked(false));

      if (!isEligibleForInternationalPayment) {
        // remove international bills only if state is TX,VN
        let removedCount = 0;

        batchListPrepared = batchListPrepared.filter((paymentIntent) => {
          const isInternational =
            paymentIntent.payment?.deliveryMethod?.deliveryType === DeliveryEnum.INTERNATIONAL;

          if (isInternational) removedCount += 1;

          return !isInternational;
        });

        yield put(batchBulkActions.setRemovedInternationalCount(removedCount));

        // blocking submit in case there only international payments for the blocked state
        if (removedCount === withPayBillFlowUUID?.length) {
          yield put(batchBulkActions.setIsScheduledBlocked(true));
        }
      }
    }

    if (!preservedState?.batchList) {
      mergePartialPaymentsWithBatchList({
        batchList: batchListPrepared,
        partialPayments,
      });
    }

    batchListPrepared = buildPaymentIntentDTOs({
      batchList: batchListPrepared,
      fundingSources,
    });

    const uploadInvoiceFiles = getUploadInvoiceFilesFromPaymentIntents(batchListPrepared);

    yield put(batchBulkActions.setInvoiceFilesBatch(uploadInvoiceFiles));
    const userPreferences = yield select((state) => state.user.userPreferences);

    yield put(
      batchBulkActions.fetchPaymentIntentDTOsSuccess({
        batchList: batchListPrepared,
        fundingSources,
      })
    );

    if (preservedState?.newDeliveryMethod) {
      yield put(
        batchBulkActions.updateDeliveryMethod({
          selectedPaymentIntent: {
            paymentIntentId: preservedState.selectedPaymentIntent.paymentIntentId,
            deliveryMethod: preservedState.newDeliveryMethod,
          },
          isNewDeliveryMethod: true,
        })
      );
    }

    if (
      preservedState?.newFundingSourceId &&
      preservedState?.selectedPaymentIntent.paymentIntentId
    ) {
      const { paymentIntentId } = preservedState.selectedPaymentIntent;

      if (paymentIntentId !== '-1') {
        yield put(
          batchBulkActions.updateFundingSource({
            selectedPaymentIntent: {
              paymentIntentId,
              fundingSourceId: preservedState?.newFundingSourceId,
            },
            bulk: false,
          })
        );
      }
    }

    batchListPrepared.sort((a, b) =>
      a.payment.vendor.companyName.localeCompare(b.payment.vendor.companyName)
    );

    for (const batchItem of batchListPrepared) {
      if (batchItem.deliveryOptions?.length > 1) {
        yield put(batchBulkActions.setShownBatchBulkFastACHExperiment(batchItem.id));
        break;
      }
    }

    const isCombineSwitchDisabled: boolean = yield select(
      batchBulkSelectors.selectIsCombineSwitchDisabled
    );

    if (
      type === batchBulkActions.setIsCombined.type &&
      !preservedState &&
      !isCombineSwitchDisabled
    ) {
      pushNotification(
        isCombined
          ? {
              type: NotificationVariantEnum.SUCCESS,
              msg: 'batchBulkPage.notifications.combined',
            }
          : {
              type: NotificationVariantEnum.SUCCESS,
              msg: 'batchBulkPage.notifications.flattened',
            }
      );
    }

    const isFastExposed = isBatchBulksHasFastExposed(batchListPrepared);

    yield put(batchBulkActions.setIsLoading(false));

    const totalAmount = yield select(batchBulkSelectors.selectTotalAmount);
    const vendorIds = yield select<any>(batchBulkSelectors.selectVendorIds);
    const noDeliveryMethodBillIds = yield select<any>(
      batchBulkSelectors.selectPaymentIntentItemIdsWithoutDeliveryMethod
    );
    const batchBillIdsArr = yield select(batchBulkSelectors.selectBillIdsArrFromBillDTOs);
    const hasBeenCombinedByFeature = yield select(
      batchBulkSelectors.selectHasBeenCombinedByFeature
    );

    const isCombinedDisabled: boolean = yield select(
      batchBulkSelectors.selectIsCombineSwitchDisabled
    );

    const analyticsExperimentNames = (() => {
      const names: string[] = [];

      if (!isCombinedDisabled) {
        names.push('uncombined-default');
      }

      return names.sort();
    })();

    analytics.track(BATCH_BULK_EVENT_PAGE, LOAD_BATCH_PAYMENTS_TABLE, {
      batchBillIds: batchBillIdsArr,
      batchBillCount: batchBillIdsArr?.length,
      batchBillTotalMount: totalAmount,
      noDeliveryMethodBillIds,
      noDeliveryMethodBillCount: noDeliveryMethodBillIds?.length || 0,
      vendorsCount: vendorIds?.length || 0,
      bulkPaymentEligible: isEligibleToBulkPayment(batchListPrepared, billIds.length),
      isFastExposed,
      ...analyticsEventProps,
      experimentName: analyticsExperimentNames,
      experimentValue: {
        'uncombined-default': hasBeenCombinedByFeature,
      },
    });
  } catch (e) {
    yield put(batchBulkActions.fetchPaymentIntentDTOsFail());
    loggingApi.error('batchBulkSaga.fetchBatchBulksHandler(): failed', e);
  }
}

function* fetchAllowedMccCodesHandler() {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const mccCodes = yield call<typeof organizationApi.fetchAmexAllowedMcc>(
      organizationApi.fetchAmexAllowedMcc,
      orgId
    );

    yield put(batchBulkActions.setAllowedMccCodes(mccCodes?.list));
  } catch (e) {
    loggingApi.error('batchBulksSaga.fetchAllowedMccCodesHandler(): failed', e);
  }
}

function* createBatchBulkPaymentHandler(
  action: ReturnType<typeof batchBulkActions.createBatchBulkPayment>
) {
  try {
    yield put(batchBulkActions.setIsScheduleDisabled(true));
    yield put(batchBulkActions.setPaidPayments([]));
    const { history } = action.payload;
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const paymentIntentsDTOs = yield select(batchBulkSelectors.selectPaymentIntentsDTOs);
    const payments = createBatchBulkPaymentAdapter(paymentIntentsDTOs);
    const isValid = yield select(batchBulkSelectors.selectIsValid);

    if (!isValid) {
      yield put(batchBulkActions.setHasTriedToSubmit(true));
      yield put(batchBulkActions.setIsScheduleDisabled(false));

      return;
    }

    analytics.track(BATCH_BULK_EVENT_PAGE, SCHEDULE_BATCH_PAYMENTS_REQUEST);

    const response: CreateBatchBulkPaymentResponse = yield call<
      typeof batchBulkApi.createBatchBulkPayment
    >(batchBulkApi.createBatchBulkPayment, {
      orgId,
      payments,
    });

    yield put(batchBulkActions.trackCreateBatchBulkPaymentSuccess(response));
    yield put(batchBulkActions.setPaidPayments(response?.payments));
    yield put(batchBulkActions.updateCheckPaymentScheduledNotificationToVendor());
    yield put(
      batchBulkActions.redirectToDashboard({
        history,
        payments: response?.payments,
      })
    );
    loggingApi.info('batchBulksSaga.createBatchBulkPaymentHandler(): success');
  } catch (e) {
    // yield put(batchBulkActions.setIsScheduleDisabled(false));
    loggingApi.error('batchBulksSaga.createBatchBulkPaymentHandler(): failed', e);
  }
}

function* updateCheckPaymentScheduledNotificationToVendorHandler() {
  try {
    const paidPayments = yield select<any>(
      batchBulkSelectors.selectPaidPaymentsToScheduleVendorNotification
    );

    for (const paidPayment of paidPayments) {
      yield call(paymentsApi.sendPaperCheckPaymentScheduledNotificationToVendor, {
        orgId: paidPayment.orgId,
        paymentId: paidPayment.paymentId,
      });
    }
  } catch (e) {
    loggingApi.error(
      'batchBulksSaga.updateCheckPaymentScheduledNotificationToVendorHandler(): failed',
      e
    );
    console.error(e);
  }
}

function* trackCreateBatchBulkPaymentSuccessHandler(
  action: ReturnType<typeof batchBulkActions.trackCreateBatchBulkPaymentSuccess>
) {
  try {
    const { payments /* , failedBills */ } = action.payload;
    const partialBillIds = yield select(batchBulkSelectors.selectAllPartialBillIds);
    const finalToggleState = yield select(batchBulkSelectors.selectIsCombined);
    const isBulkPaymentsNotPossible = yield select(
      batchBulkSelectors.selectIsCombineSwitchDisabled
    );
    const fastAchExposedIds = yield select<any>(
      batchBulkSelectors.selectEligiblePaymentIntentsForFastAch
    );
    const expressChecksExposedIds = yield select<any>(
      batchBulkSelectors.selectEligiblePaymentIntentsForExpressCheck
    );
    const convertedFastAchExposedIds = removeCharsAndParseIds(fastAchExposedIds);
    const convertedExpressChecksExposedIds = removeCharsAndParseIds(expressChecksExposedIds);

    const totalFastAchPayments = payments.filter(
      (payment) =>
        payment?.deliveryMethod?.deliveryType === (DeliveryEnum.ACH as any) &&
        FAST_DELIVERY_TYPES.includes(payment.deliveryPreference as FastFeeDeliveryEnum)
    ).length;
    const totalFastCheckPayments = payments.filter(
      (payment) =>
        payment.deliveryMethod?.deliveryType === (DeliveryEnum.CHECK as any) &&
        FAST_DELIVERY_TYPES.includes(payment.deliveryPreference as FastFeeDeliveryEnum)
    ).length;

    analytics.track(BATCH_BULK_EVENT_PAGE, SCHEDULE_BATCH_PAYMENTS_SUCCESS, {
      billIds: payments
        .map((payment) => payment.billPayments?.map((billPayment) => billPayment.billId) || [])
        .flat(),
      totalPayments: payments.length,
      totalFastEligibleAchPayments: convertedFastAchExposedIds.length,
      totalFastEligibleCheckPayments: convertedExpressChecksExposedIds.length,
      totalFastAchPayments,
      totalFastCheckPayments,
      partialBillIds,
      finalToggleState,
      bulkPaymentEligible: !isBulkPaymentsNotPossible,
    });

    analytics.track(BATCH_BULK_EVENT_PAGE, FAST_ACH_EXPOSURE, {
      batchBillIds: convertedFastAchExposedIds,
      batchBillCount: convertedFastAchExposedIds?.length,
    });
    analytics.track(BATCH_BULK_EVENT_PAGE, EXPRESS_CHECK_EXPOSURE, {
      batchBillIds: convertedExpressChecksExposedIds,
      batchBillCount: convertedExpressChecksExposedIds?.length,
    });
  } catch (e) {
    loggingApi.error('batchBulksSaga.trackCreateBatchBulkPaymentSuccessHandler(): failed', e);
  }
}

function* updateVendorMccCode(action: ReturnType<typeof batchBulkActions.updateVendorMccCode>) {
  const { vendorId, mccCode } = action.payload;

  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);

    const vendorPaymentsIntentDTO: BatchBulkPaymentIntent[] = yield select(
      batchBulkSelectors.selectPaymentIntentItemsByVendorId(vendorId)
    );

    yield call<typeof vendorApi.updateMccCode>(vendorApi.updateMccCode, {
      orgId,
      params: {
        vendorId,
        mccCode,
      },
    });

    for (const vendorPaymentIntentDTO of vendorPaymentsIntentDTO) {
      yield put(
        batchBulkActions.updatedPaymentIntentDTOSuccess({
          id: vendorPaymentIntentDTO.id,
          changes: {
            mccCode,
          } as Partial<BatchBulkPaymentIntent>,
        })
      );
    }

    yield put(batchBulkActions.setAmexVerificationModalAsClosed());
    analytics.track(BATCH_BULK_EVENT_PAGE, AMEX_INDUSTRY_MODAL_SAVE, {
      vendorId,
      mccCode,
    });
  } catch (e) {
    loggingApi.error('batchBulksSaga.updateVendorMccCode(): failed', e);
    analytics.track(BATCH_BULK_EVENT_PAGE, AMEX_INDUSTRY_MODAL_ERROR, {
      vendorId,
      mccCode,
    });
  }
}

function* updateVendorEmail(action: ReturnType<typeof batchBulkActions.updateVendorEmail>) {
  try {
    const { contactEmail, paymentIntentId } = action.payload;
    const paymentIntentDTO: BatchBulkPaymentIntent = yield select(
      batchBulkSelectors.selectPaymentIntentDTO(paymentIntentId)
    );
    const vendorId = paymentIntentDTO.payment.vendor.id;
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const vendorPaymentsIntentDTO: BatchBulkPaymentIntent[] = yield select(
      batchBulkSelectors.selectPaymentIntentDTOsByVendorId(vendorId)
    );

    yield call<typeof vendorApi.update>(vendorApi.update, {
      orgId,
      id: vendorId,
      params: { contactEmail },
    });

    for (const vendorPaymentIntentDTO of vendorPaymentsIntentDTO) {
      yield put(
        batchBulkActions.updatedPaymentIntentDTOSuccess({
          id: vendorPaymentIntentDTO.id,
          changes: {
            payment: {
              ...vendorPaymentIntentDTO.payment,
              vendor: {
                ...vendorPaymentIntentDTO.payment.vendor,
                contactEmail,
              },
            },
          } as Partial<BatchBulkPaymentIntent>,
        })
      );
    }

    yield put(
      batchBulkActions.updateVendorEmailSuccess({
        contactEmail,
        vendorId,
      })
    );

    yield put(
      batchBulkActions.setVendorIdToContactEmailMappingItem({
        vendorId,
        contactEmail,
      })
    );

    yield put(batchBulkActions.setAddEmailModalAsClosed());
    analytics.track(BATCH_BULK_EVENT_PAGE, SAVE_EMAIL_FOR_BULK_RECONCILIATION, {
      vendorId,
    });
  } catch (e) {
    loggingApi.error('batchBulksSaga.updateVendorEmail(): failed', e);
  }
}

function* updateDeductionDateHandler(
  action: ReturnType<typeof batchBulkActions.updateDeductionDate>
) {
  try {
    const { date, paymentIntentId, amount: amountPayload, isByDueDate } = action.payload;
    const paymentIntentDTO: BatchBulkPaymentIntent = yield select(
      batchBulkSelectors.selectPaymentIntentDTO(paymentIntentId)
    );

    if (!paymentIntentDTO.payment.deliveryMethodId || !paymentIntentDTO.payment.fundingSourceId) {
      yield put(
        batchBulkActions.updatedPaymentIntentDTOSuccess({
          id: paymentIntentId,
          changes: {
            payment: {
              ...paymentIntentDTO.payment,
              scheduledDate: date,
            },
          },
        })
      );
    } else {
      const { deliveryMethodId, fundingSourceId, amount, deliveryMethod, payBillFlowUUID } =
        paymentIntentDTO.payment;
      const orgId = yield select(userSliceSelectors.selectOrgId);
      const dateAction = isByDueDate
        ? paymentsApi.fetchDeliveryByDueDate
        : paymentsApi.fetchDeliveryTime;

      const { dates } = yield call<typeof dateAction>(dateAction, {
        orgId,
        deliveryMethodId,
        amount: amountPayload || (amount as number),
        scheduledDate: date,
        fundingSourceId,
        payBillFlowUUID: paymentIntentDTO.payment.payBillFlowUUID ? payBillFlowUUID : uuidv4(),
      });
      const relevantDate: DeliveryOption = dates.find(
        (date) => date?.type === deliveryMethod?.deliveryType
      );
      const { fee, maxDeliveryDate, deliveryDate, scheduledDate, minScheduledDate } =
        relevantDate || {};

      yield put(
        batchBulkActions.updatedPaymentIntentDTOSuccess({
          id: paymentIntentId,
          changes: {
            fee,
            deliveryOptions: dates,
            minScheduledDate: minScheduledDate.toString(),
            payment: {
              ...paymentIntentDTO.payment,
              scheduledDate: isByDueDate ? scheduledDate : date,
              maxDeliveryEta: maxDeliveryDate,
              deliveryEta: deliveryDate,
            },
          },
        })
      );
    }
  } catch (e) {
    loggingApi.error('batchBulksSaga.updateDeductionDateHandler(): failed', e);
  }
}

function* updateAmountHandler(action: ReturnType<typeof batchBulkActions.updateAmount>) {
  try {
    const { paymentIntentId, billId, amount } = action.payload;
    const paymentIntentDTO = yield select(
      batchBulkSelectors.selectPaymentIntentDTO(paymentIntentId)
    );

    yield put(
      batchBulkActions.updatedPaymentIntentDTOSuccess({
        id: paymentIntentId,
        changes: {
          payment: {
            ...paymentIntentDTO.payment,
            amount:
              paymentIntentDTO.payment.bills
                ?.map((bill) => (bill.id !== billId ? bill.balance || 0 : amount))
                .reduce((sum, cur) => sum + cur) || 0,
            bills: paymentIntentDTO.payment.bills.map((bill: Bill) => {
              if (bill.id === billId) {
                return {
                  ...bill,
                  balance: amount,
                } as Bill as any;
              }

              return bill;
            }),
          },
        },
      })
    );
  } catch (e) {
    loggingApi.error('batchBulksSaga.updateAmountHandler(): failed', e);
  }
}

function* createFundingSourceHandler(
  action: ReturnType<typeof batchBulkActions.createFundingSource>
) {
  try {
    const { selectedPaymentIntent, history } = action.payload;
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const batchList = yield select(batchBulkSelectors.selectPaymentIntentsDTOs);
    const billIds = yield select(batchBulkSelectors.selectBillIds);

    history.push(
      utilsLocations.Bills.pay.batchFunding.url({
        ids: billIds,
        orgId,
      }),
      {
        preservedState: {
          origin: AddFundingSourceWizardOriginEnum.BATCH_BULK,
          batchList,
          selectedPaymentIntent,
          exitUrl: utilsLocations.Bills.pay.batch.url({
            ids: billIds,
            orgId,
          }),
        },
        exitUrl: utilsLocations.Bills.pay.batch.url({
          ids: billIds,
          orgId,
        }),
      }
    );
  } catch (e) {
    loggingApi.error('batchBulksSaga.createFundingSourceHandler(): failed', e);
  }
}

function* updateFundingSourceAfterDeleteHandler(
  action: ReturnType<typeof batchBulkActions.updateFundingSourceAfterDelete>
) {
  const { fundingSourceId } = action.payload;

  const paymentIntentDTOs = yield select(batchBulkSelectors.selectPaymentIntentsDTOs);
  const fundingSources = yield select(fundingSourcesSelectors.selectValidFundingSources);

  for (const paymentIntentDTO of paymentIntentDTOs) {
    try {
      if (fundingSourceId === paymentIntentDTO?.payment?.fundingSourceId) {
        const paymentIntentId = paymentIntentDTO.id;

        yield put(
          batchBulkActions.updateFundingSource({
            selectedPaymentIntent: {
              paymentIntentId,
              fundingSourceId: fundingSources[0]?.id,
            },
            bulk: false,
          })
        );
      }
    } catch (e) {
      loggingApi.error('batchBulksSaga.updateFundingSourceAfterDeleteHandler(): failed', e);
    }
  }
}

function* updateFundingSourceHandler(
  action: ReturnType<typeof batchBulkActions.updateFundingSource>
) {
  const {
    selectedPaymentIntent: { paymentIntentId, fundingSourceId },
    bulk = false,
  } = action.payload;

  const paymentIntentDTO = yield select(batchBulkSelectors.selectPaymentIntentDTO(paymentIntentId));

  try {
    yield put(
      batchBulkActions.updatedPaymentIntentDTOSuccess({
        id: paymentIntentId,
        changes: {
          payment: {
            ...paymentIntentDTO.payment,
            fundingSourceId,
          },
        },
      })
    );

    yield put(batchBulkActions.setIsBulkPaymentMethod(bulk));

    // getting latest fee options
    yield put(
      batchBulkActions.updateDeductionDate({
        paymentIntentId,
        date: paymentIntentDTO.payment?.scheduledDate,
      })
    );
  } catch (e) {
    loggingApi.error('batchBulksSaga.updateFundingSource(): failed', e);
  }
}

function* createDeliveryMethodHandler(
  action: ReturnType<typeof batchBulkActions.createDeliveryMethod>
) {
  try {
    const { selectedPaymentIntent, history } = action.payload;
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const batchList = yield select(batchBulkSelectors.selectPaymentIntentsDTOs);
    const billIds = yield select(batchBulkSelectors.selectBillIds);
    const redirectUrl = utilsLocations.Bills.pay.batch.url({
      ids: billIds,
      orgId,
    });

    history.push(
      utilsLocations.Bills.pay.deliveryMethod.url({
        id: selectedPaymentIntent.billId,
        orgId,
      }),
      {
        preservedState: {
          origin: AddFundingSourceWizardOriginEnum.BATCH_BULK,
          batchList,
          selectedPaymentIntent,
          redirectUrl,
        },
        redirectUrl,
        exitUrl: utilsLocations.Bills.pay.batch.url({
          ids: billIds,
          orgId,
        }),
      }
    );
  } catch (e) {
    loggingApi.error('batchBulksSaga.createDeliveryMethodHandler(): failed', e);
  }
}

function* setPaymentDatesActionHandler(
  action: ReturnType<typeof batchBulkActions.setPaymentDatesAction>
) {
  try {
    const { billId, deliveryEta, deliveryPreference } = action.payload;

    const paymentIntentDTO = yield select(batchBulkSelectors.selectPaymentIntentDTO(billId));

    yield put(
      batchBulkActions.updatedPaymentIntentDTOSuccess({
        id: billId,
        changes: {
          ...paymentIntentDTO,
          payment: {
            ...paymentIntentDTO.payment,
            deliveryEta: deliveryEta as any,
            deliveryPreference: deliveryPreference as any,
          },
        },
      })
    );
  } catch (e) {
    loggingApi.error('batchBulksSaga.setPaymentDatesActionHandler(): failed', e);
  }
}

export function* updateDeliveryMethodHandler(
  action: ReturnType<typeof batchBulkActions.updateDeliveryMethod>
) {
  const {
    selectedPaymentIntent: { paymentIntentId, deliveryMethod },
    isNewDeliveryMethod,
  } = action.payload;

  // sometimes deliveryMethod provided in payload doesn't have vendorId prop, we have to get it from store
  const selectedPaymentIntentDTO: BatchBulkPaymentIntent = yield select(
    batchBulkSelectors.selectPaymentIntentDTO(paymentIntentId)
  );

  const paymentIntentsDTOsWithSameVendor: BatchBulkPaymentIntent[] = yield select(
    batchBulkSelectors.selectPaymentIntentDTOsByVendorId(
      selectedPaymentIntentDTO?.payment?.vendorId
    )
  );

  try {
    yield all(
      paymentIntentsDTOsWithSameVendor.map((paymentIntentDTO) => {
        const isSelectedPaymentIntent = paymentIntentDTO.id === paymentIntentId;

        const paymentIntentDeliveryMethodIsEqual =
          paymentIntentDTO.payment.deliveryMethodId === deliveryMethod?.id;

        const isNotSelectedPaymentIntentShouldBeUpdated =
          !isSelectedPaymentIntent && paymentIntentDeliveryMethodIsEqual && isNewDeliveryMethod;

        // delivery method for not selected payment intents with compatible FS is already set
        // by buildPaymentIntentDTOs fn in fetch saga,
        // so we need to update only deduction dates for such a case
        if (isNotSelectedPaymentIntentShouldBeUpdated) {
          return put(
            batchBulkActions.updateDeductionDate({
              paymentIntentId: paymentIntentDTO.id,
              date: paymentIntentDTO.payment.scheduledDate,
            })
          );
        }

        // for international DM we should force update for all payment intents with the same vendor
        const isInternational = deliveryMethod?.deliveryType === DeliveryEnum.INTERNATIONAL;

        if (isSelectedPaymentIntent || isInternational) {
          return updateDeliveryMethodForSinglePaymentIntent({
            paymentIntentDTO: {
              ...paymentIntentDTO,
              payment: {
                ...paymentIntentDTO.payment,
                deliveryPreference: paymentIntentDeliveryMethodIsEqual
                  ? paymentIntentDTO.payment.deliveryPreference
                  : null,
              },
            },
            deliveryMethod: deliveryMethod as DeliveryMethod,
          });
        }

        return undefined;
      })
    );
  } catch (e) {
    loggingApi.error('batchBulksSaga.updateDeliveryMethod(): failed', e);
  }
}

interface UpdateDeliveryMethodForSinglePaymentIntentParams {
  paymentIntentDTO: BatchBulkPaymentIntent;
  deliveryMethod: DeliveryMethod;
}

function* updateDeliveryMethodForSinglePaymentIntent({
  paymentIntentDTO,
  deliveryMethod,
}: UpdateDeliveryMethodForSinglePaymentIntentParams) {
  yield put(
    batchBulkActions.updatedPaymentIntentDTOSuccess({
      id: paymentIntentDTO.id,
      changes: {
        ...paymentIntentDTO,
        payment: {
          ...paymentIntentDTO.payment,
          deliveryMethod: deliveryMethod,
          deliveryMethodId: deliveryMethod.id,
        },
      },
    })
  );

  // getting latest fee options
  yield put(
    batchBulkActions.updateDeductionDate({
      paymentIntentId: paymentIntentDTO.id,
      date: paymentIntentDTO.payment.scheduledDate,
    })
  );
}

function* fetchShowHelpGuideHandler() {
  try {
    const { user: profile } = yield call(userApi.fetchProfile);

    // show help guide
    const shouldShowGuide = !profile?.userPreferences?.batchBulkOnboardingSeen;

    yield put(batchBulkActions.setShowHelpGuide(shouldShowGuide));

    // show test uncombined guide
    const shouldShowUncombinedGuide = !profile?.userPreferences?.qbBatchBulkUncombinedSeen;

    yield put(batchBulkActions.setShowUncombinedGuide(shouldShowUncombinedGuide));
  } catch (e) {
    loggingApi.warn('batchBulksSaga.fetchShowHelpGuideHandler(): failed', e);
  }
}

function* updateShowHelpGuideUIHandler(
  action: ReturnType<typeof batchBulkActions.updateShowHelpGuideUI>
) {
  const {
    payload: { value },
  } = action;

  try {
    yield put(batchBulkActions.setShowUncombinedGuide(value));
    yield put(batchBulkActions.setShowHelpGuide(value));
  } catch (e) {
    loggingApi.warn('batchBulksSaga.updateShowHelpGuideUIHandler(): failed', e);
  }
}

function* updateShowHelpGuideApiHandler(
  action: ReturnType<typeof batchBulkActions.updateShowHelpGuideApi>
) {
  const {
    payload: { key, value },
  } = action;

  try {
    if (value === false) {
      const { user: profile } = yield call(userApi.fetchProfile);
      const bool = profile?.userPreferences?.batchBulkOnboardingSeen;

      if (!bool) {
        yield put(batchBulkActions.setShowHelpGuideTooltip(true));
      }

      yield call(userApi.updateUserPreference, {
        key,
        value: !value,
      });
    }
  } catch (e) {
    loggingApi.warn('batchBulksSaga.updateShowHelpGuideApiHandler(): failed', e);
  }
}

function* goToSettingsPageHandler(action: ReturnType<typeof batchBulkActions.goToSettingsPage>) {
  const { history } = action.payload;
  const orgId = yield select(userSliceSelectors.selectOrgId);
  const batchList = yield select(batchBulkSelectors.selectPaymentIntentsDTOs);
  const billIds = yield select(batchBulkSelectors.selectBillIds);
  const redirectUrl = utilsLocations.Bills.pay.batch.url({
    ids: billIds,
    orgId,
  });

  yield put(applicationActions.setUrlToBack(redirectUrl));

  history.push(utilsLocations.Settings.index.url({ orgId }), {
    origin: AddFundingSourceWizardOriginEnum.BATCH_BULK,
    batchList,
    billIds,
    redirectUrl,
    exitUrl: redirectUrl,
  });
}

function* fetchDefaultMemoHandler(action: ReturnType<typeof batchBulkActions.fetchDefaultMemo>) {
  try {
    const { paymentIntentId, translations } = action.payload;
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const paymentIntentDTO: BatchBulkPaymentIntent = yield select(
      batchBulkSelectors.selectPaymentIntentDTO(paymentIntentId)
    );

    const { payment } = paymentIntentDTO;
    const { vendor, note, bills } = payment;

    // ignore if there's an existing note
    if (note) return;

    const { intuitAcctNum } = yield call<typeof vendorApi.fetchIntuitAcctNum>(
      vendorApi.fetchIntuitAcctNum,
      {
        orgId,
        id: vendor.id,
      }
    );

    const modifiedNote = getDefaultMemo({
      bills: bills as Bill[],
      intuitAcctNum,
      translations,
    });

    yield put(
      batchBulkActions.setBillsDefaultMemo({
        id: paymentIntentId,
        note: modifiedNote,
      })
    );
  } catch (e) {
    loggingApi.error('batchBulksSaga.fetchDefaultMemoHandler(): failed', e);
  }
}

function* updateInvoiceFileHandler(action: ReturnType<typeof batchBulkActions.setInvoiceFile>) {
  try {
    const { paymentIntentId, invoiceFile } = action.payload;

    yield put(
      batchBulkActions.setInvoiceFile({
        paymentIntentId,
        invoiceFile,
      })
    );
  } catch (e) {
    loggingApi.warn('batchBulksSaga.updateInvoiceFileHandler(): failed', e);
  }
}

function* redirectToDashboardHandler(
  action: ReturnType<typeof batchBulkActions.redirectToDashboard>
) {
  try {
    const { history, payments } = action.payload;
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const itemIds = getItemIds(payments);

    yield put(clearPayBillWizardBill());

    if (!itemIds) return;

    const firstItem = payments?.[0];

    const metadata = yield call(billsApi.fetchNewQBDashboardItemMetadata, {
      orgId,
      params: {
        pageSize: ITEMS_PER_PAGE,
        paymentId: firstItem?.id,
        billId: firstItem?.billId,
      },
    });
    const { message, code, ...filteredMetaData } = metadata;
    const query = fetchRedirectQuery({
      itemIds,
      metadata: filteredMetaData,
    });

    const billListModified = yield select<any>(batchBulkSelectors.selectBillListModified);

    yield put(batchBulkActions.setPaidPayments([]));

    goQBDashboard({
      orgId,
      itemIds,
      paidPayments: payments,
      billListModified,
      getScheduledPayments: getScheduledPaymentsHandler,
      query,
      history,
    });
  } catch (e) {
    loggingApi.warn('batchBulksSaga.redirectToDashboardHandler(): failed', e);
  }
}

function* updateBulkDeductionDateHandler(
  action: ReturnType<typeof batchBulkActions.updateBulkDeductionDate>
) {
  try {
    const { date, type } = action.payload;
    const paymentIntents = yield select(batchBulkSelectors.selectPaymentIntentsDTOs);
    const billListModified = yield select<any>(batchBulkSelectors.selectBillListModified);

    const isByDueDate = type === DEDUCTION_DATE_TYPES[0].id;

    for (const paymentIntent of paymentIntents) {
      const billId = convertPaymentIntentIdToNumber(paymentIntent);
      const billModified = billListModified[billId];
      const initScheduledDate = billModified?.deliveryOptions[0]?.scheduledDate;
      const scheduleDate = isByDueDate ? billModified?.bill?.dueDate : date || initScheduledDate;

      yield put(
        batchBulkActions.updateDeductionDate({
          date: scheduleDate,
          paymentIntentId: paymentIntent.id,
          isByDueDate,
        })
      );
    }
  } catch (e) {
    loggingApi.warn('batchBulksSaga.updateBulkDeductionDate(): failed', e);
  }
}

function* updateDeliveryOptionHandler(
  action: ReturnType<typeof batchBulkActions.updateDeliveryOption>
) {
  const { paymentIntentId, deliveryOption, isFast, deliverySpeedLabel } = action.payload;
  const orgId = yield select(userSliceSelectors.selectOrgId);
  const paymentIntentDTO = yield select(batchBulkSelectors.selectPaymentIntentDTO(paymentIntentId));

  const deliveryPreferences = {
    [DeliveryEnum.ACH]: FastFeeDeliveryEnum.EXPEDITED_ACH,
    [DeliveryEnum.CHECK]: FastFeeDeliveryEnum.EXPRESS_CHECK,
  };

  const deliveryPreference = isFast
    ? deliveryPreferences[paymentIntentDTO.payment.deliveryMethod.deliveryType]
    : null;

  try {
    yield put(
      batchBulkActions.setPaymentDatesAction({
        billId: paymentIntentId,
        deliveryEta: deliveryOption.deliveryDate.toString(),
        deliveryPreference,
      })
    );
  } catch (error) {
    loggingApi.error('batchBulksSaga.updateDeliveryOptionHandler(): failed', { error, orgId });
  }

  const partialBillIds = yield select(batchBulkSelectors.selectAllPartialBillIds);

  analytics.track(BATCH_BULK_EVENT_PAGE, TOGGLE_FAST_OPTION, {
    billIds: paymentIntentId,
    partialBillIds,
    fastType: deliveryOption.type,
    toggleStatus: isFast,
    vendorId: paymentIntentDTO.payment.vendorId,
    deliverySpeedLabel: deliverySpeedLabel ? 'on_time' : 'fast',
  });
}

function* updateShownBatchBulkFastACHExperimentHandler(
  action: ReturnType<typeof batchBulkActions.updateShownBatchBulkFastACHExperiment>
) {
  const {
    payload: { key, value },
  } = action;

  try {
    if (value) {
      yield put(batchBulkActions.setShownBatchBulkFastACHExperiment(undefined));

      yield call(userApi.updateUserPreference, {
        key,
        value,
      });

      analytics.track(BATCH_BULK_EVENT_PAGE, FTU_FAST_PAYMENT_POPOVER_CONFIRM);
    }
  } catch (e) {
    loggingApi.warn('batchBulksSaga.updateShownBatchBulkFastACHExperimentHandler(): failed', e);
  }
}

function* updateBulkPaymentMethodsHandler(
  action: ReturnType<typeof batchBulkActions.updateBulkPaymentMethods>
) {
  try {
    const { fundingSourceId } = action.payload;
    const paymentIntents = yield select(batchBulkSelectors.selectPaymentIntentsDTOs);

    for (const paymentIntent of paymentIntents) {
      yield put(
        batchBulkActions.updateFundingSource({
          selectedPaymentIntent: {
            paymentIntentId: paymentIntent.id,
            fundingSourceId: fundingSourceId as unknown as string,
          },
          bulk: true,
        })
      );
    }
  } catch (e) {
    loggingApi.warn('batchBulksSaga.updateBulkPaymentMethods(): failed', e);
  }
}

function* updateBillInvoiceFileHandler(
  action: ReturnType<typeof batchBulkActions.updateBillInvoice>
) {
  const { billId } = action.payload;

  const paymentIntentId = `bill-${billId}`;
  const orgId = yield select(userSliceSelectors.selectOrgId);
  const paymentIntent: BatchBulkPaymentIntent = yield select(
    batchBulkSelectors.selectPaymentIntentDTO(paymentIntentId)
  );
  const currentInvoiceFile: UploadInvoiceFile = yield select(
    batchBulkSelectors.selectInvoiceByBillId(paymentIntentId)
  );

  yield put(
    batchBulkActions.setInvoiceFile({
      paymentIntentId: paymentIntentId,
      invoiceFile: currentInvoiceFile.invoiceFile,
      loadingStatus: {
        isLoading: true,
      },
    })
  );

  try {
    const updatedBill: Bill = yield call(
      billApi.editBillById,
      orgId,
      billId,
      { files: [currentInvoiceFile.invoiceFile?.id] },
      'all'
    );
    const updatedBills = (paymentIntent.payment.bills || []).map((bill) => {
      if (bill.id === updatedBill.id) {
        return updatedBill;
      }

      return bill;
    });

    yield put(
      batchBulkActions.setInvoiceFile({
        paymentIntentId: paymentIntentId,
        invoiceFile: currentInvoiceFile.invoiceFile,
        loadingStatus: {
          isLoading: false,
        },
      })
    );

    yield put(
      batchBulkActions.updatedPaymentIntentDTOSuccess({
        id: paymentIntentId,
        changes: {
          payment: {
            bills: updatedBills,
          },
        } as Partial<BatchBulkPaymentIntent>,
      })
    );
  } catch (e) {
    yield put(
      batchBulkActions.setInvoiceFile({
        paymentIntentId: paymentIntentId,
        invoiceFile: currentInvoiceFile.invoiceFile,
        loadingStatus: {
          isLoading: false,
          error: (e as Error).message,
        },
      })
    );
  }
}

function* uploadInvoiceFileHandler(action: ReturnType<typeof batchBulkActions.uploadInvoiceFile>) {
  const { billId, file } = action.payload;
  const orgId = yield select(userSliceSelectors.selectOrgId);

  yield put(
    batchBulkActions.setInvoiceFile({
      paymentIntentId: billId,
      invoiceFile: null,
      loadingStatus: {
        isLoading: true,
      },
    })
  );

  try {
    const uploadFileResponse: FileUploadResponse = yield call(filesApi.uploadFile, {
      orgId,
      file,
    });

    yield put(
      batchBulkActions.setInvoiceFile({
        paymentIntentId: billId,
        invoiceFile: uploadFileResponse.file,
        loadingStatus: {
          code: uploadFileResponse.code,
          isLoading: false,
        },
      })
    );

    analytics.track('international-invoice-collection', 'FileForPaymentInvoice-Added', {
      billId,
      reason: 'international-payment',
    });

    loggingApi.info('batchBulkSaga.uploadInvoiceFileHandler(): successed', { billId });
  } catch (e: unknown) {
    loggingApi.error('batchBulkSaga.uploadInvoiceFileHandler(): failed', e);

    yield put(
      batchBulkActions.setInvoiceFile({
        paymentIntentId: billId,
        invoiceFile: null,
        loadingStatus: {
          isLoading: false,
          error: (e as Error).message,
        },
      })
    );
  }
}

function* removeUploadInvoiceFileHandler(
  action: ReturnType<typeof batchBulkActions.removeUploadInvoiceFile>
) {
  const { billId } = action.payload;

  yield put(
    batchBulkActions.setInvoiceFile({
      paymentIntentId: billId,
      invoiceFile: null,
    })
  );
}

export function* watchBatchBulk() {
  yield takeEvery(batchBulkActions.fetchBatchBulks, fetchBatchBulksHandler);
  yield takeEvery(batchBulkActions.refetchBatchBulksByPaymentMethod, fetchBatchBulksHandler);
  yield takeEvery(batchBulkActions.setIsCombined, fetchBatchBulksHandler);
  yield takeEvery(batchBulkActions.fetchBatchBulks, fetchAllowedMccCodesHandler);
  yield takeEvery(batchBulkActions.createBatchBulkPayment, createBatchBulkPaymentHandler);
  yield takeEvery(batchBulkActions.updateDeductionDate, updateDeductionDateHandler);
  yield takeEvery(batchBulkActions.updateAmount, updateAmountHandler);
  yield takeEvery(batchBulkActions.updateVendorEmail, updateVendorEmail);
  yield takeEvery(batchBulkActions.updateVendorMccCode, updateVendorMccCode);
  yield takeEvery(batchBulkActions.createFundingSource, createFundingSourceHandler);
  yield takeEvery(batchBulkActions.updateFundingSource, updateFundingSourceHandler);
  yield takeEvery(
    batchBulkActions.updateFundingSourceAfterDelete,
    updateFundingSourceAfterDeleteHandler
  );
  yield takeEvery(batchBulkActions.createDeliveryMethod, createDeliveryMethodHandler);
  yield takeEvery(batchBulkActions.updateDeliveryMethod, updateDeliveryMethodHandler);
  yield takeEvery(batchBulkActions.fetchShowHelpGuide, fetchShowHelpGuideHandler);
  yield takeEvery(batchBulkActions.updateShowHelpGuideUI, updateShowHelpGuideUIHandler);
  yield takeEvery(batchBulkActions.updateShowHelpGuideApi, updateShowHelpGuideApiHandler);
  yield takeEvery(batchBulkActions.setPaymentDatesAction, setPaymentDatesActionHandler);
  yield takeEvery(
    batchBulkActions.trackCreateBatchBulkPaymentSuccess,
    trackCreateBatchBulkPaymentSuccessHandler
  );
  yield takeEvery(batchBulkActions.goToSettingsPage, goToSettingsPageHandler);
  yield takeEvery(
    batchBulkActions.updateCheckPaymentScheduledNotificationToVendor,
    updateCheckPaymentScheduledNotificationToVendorHandler
  );
  yield takeEvery(batchBulkActions.fetchDefaultMemo, fetchDefaultMemoHandler);
  yield takeEvery(
    batchBulkActions.updateInvoiceFile as unknown as TakeableChannel<unknown>,
    updateInvoiceFileHandler
  );
  yield takeEvery(batchBulkActions.redirectToDashboard, redirectToDashboardHandler);
  yield takeEvery(batchBulkActions.updateBulkPaymentMethods, updateBulkPaymentMethodsHandler);
  yield takeEvery(batchBulkActions.updateDeliveryOption, updateDeliveryOptionHandler);
  yield takeEvery(
    batchBulkActions.updateShownBatchBulkFastACHExperiment,
    updateShownBatchBulkFastACHExperimentHandler
  );
  yield takeEvery(batchBulkActions.updateBulkDeductionDate, updateBulkDeductionDateHandler);
  yield takeEvery(batchBulkActions.uploadInvoiceFile, uploadInvoiceFileHandler);
  yield takeEvery(batchBulkActions.updateBillInvoice, updateBillInvoiceFileHandler);
  yield takeEvery(batchBulkActions.removeUploadInvoiceFile, removeUploadInvoiceFileHandler);
}
