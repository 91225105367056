import React, { useMemo, useState } from 'react';
import head from 'lodash/head';
import { CONSTS } from 'src/app/utils/consts';
import { getOrgId, getFundingSources } from 'src/app/redux/user/selectors';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import paymentStore from 'src/app/modules/payments/payment-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { useSelector } from 'react-redux';
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom';
import { getDeliveryMethodByType } from 'src/app/pages/vendor/records';
import { OptionalDeliveryMethodsType } from 'src/app/utils/types';
import { QBRPayBillSelectDeliveryMethodPage } from 'src/app/pages/bill/pay/components/QBRPayBillSelectDeliveryMethodPage';
import utilsLocations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import { getConfirmBill } from './utils';
import { FundingSource } from 'src/app/version-2/model/dtos';
import locations from '../locations';
import { QuickPayment } from '../../../modules/payments/quickpay-slice';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
  type: string;
};

const SelectDeliveryMethodPage = ({ onNext, onPrev, onExit, type }: Props) => {
  const orgId = useSelector(getOrgId);
  const match = useRouteMatch<{ vendorId?: string; paymentId?: string }>();
  const payment: QuickPayment = useSelector(
    paymentStore.selectors.quickpay.newPaymentWizard.payment
  );
  const fundingSource = head<FundingSource>(useSelector(getFundingSources));
  const vendorId = match.params?.vendorId || payment?.vendorId;
  const paymentId = match.params?.paymentId || payment?.id;
  const [selectedDeliveryMethodType, setSelectedDeliveryMethodType] = useState('');
  const [selectedDeliveryMethodId, setSelectedDeliveryMethodId] = useState(
    payment?.deliveryMethodId
  );
  const { vendor } = useFetchVendor(vendorId);
  const bill = getConfirmBill(payment, vendor);
  const paymentActions = useStoreActions(paymentStore);
  const deliveryMethodOptions = useMemo(() => {
    const ach = getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.ACH, vendor?.deliveryMethods);
    const check = getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.CHECK, vendor?.deliveryMethods);

    return [ach, check];
  }, [vendor]);

  const headerLabel =
    type === 'new' && selectedDeliveryMethodId
      ? 'qbr.edit.selectDeliveryMethod.title'
      : `qbr.${type}.selectDeliveryMethod.title`;

  const history = useHistory();

  const goAddDeliveryMethod = (type: OptionalDeliveryMethodsType) => {
    const deliveryMethodUrlMap = {
      [CONSTS.DELIVERY_TYPE.ACH]: generatePath(locations.deliveryMethods.ach.new, {
        orgId,
        vendorId,
      }),
      [CONSTS.DELIVERY_TYPE.CHECK]: generatePath(locations.deliveryMethods.check.new, {
        orgId,
        vendorId,
      }),
      [CONSTS.DELIVERY_TYPE.VIRTUAL]: utilsLocations.Vendors.deliveryMethods.virtual.create.url({
        orgId,
        id: bill.vendor.id,
      }),
    };
    const url = deliveryMethodUrlMap[type];

    history.push({
      pathname: url,
    });
  };

  const goEditDeliveryMethod = (type: OptionalDeliveryMethodsType, deliveryMethodId?: string) => {
    if (paymentId && deliveryMethodId) {
      const deliveryMethodUrlMap = {
        [CONSTS.DELIVERY_TYPE.ACH]: generatePath(locations.edit.ach, {
          orgId,
          vendorId,
          deliveryMethodId,
          paymentId,
        }),
        [CONSTS.DELIVERY_TYPE.CHECK]: generatePath(locations.edit.check, {
          orgId,
          vendorId,
          deliveryMethodId,
          paymentId,
        }),
      };
      const url = deliveryMethodUrlMap[type];

      history.push({ pathname: url });
    } else if (paymentId && !deliveryMethodId) {
      const deliveryMethodUrlMap = {
        [CONSTS.DELIVERY_TYPE.ACH]: generatePath(locations.edit.achNew, {
          orgId,
          vendorId,
          paymentId,
        }),
        [CONSTS.DELIVERY_TYPE.CHECK]: generatePath(locations.edit.checkNew, {
          orgId,
          vendorId,
          paymentId,
        }),
      };
      const url = deliveryMethodUrlMap[type];

      history.push({ pathname: url });
    } else {
      const deliveryMethodUrlMap = {
        [CONSTS.DELIVERY_TYPE.ACH]: generatePath(locations.deliveryMethods.ach.edit, {
          orgId,
          vendorId,
          deliveryMethodId,
        }),
        [CONSTS.DELIVERY_TYPE.CHECK]: generatePath(locations.deliveryMethods.check.edit, {
          orgId,
          vendorId,
          deliveryMethodId,
        }),
      };
      const url = deliveryMethodUrlMap[type];

      history.push({ pathname: url });
    }
  };

  const goAddVirtualDeliveryMethod = () => {
    analytics.track('pay-bill', 'delivery-method-add', {
      type: CONSTS.DELIVERY_TYPE.VIRTUAL,
    });

    goAddDeliveryMethod(CONSTS.DELIVERY_TYPE.VIRTUAL);
  };

  const onSubmit = async () => {
    await paymentActions.quickpay.newPaymentWizard.update({
      deliveryMethodId: selectedDeliveryMethodId,
      scheduledDate: undefined,
      deliveryEta: undefined,
      maxDeliveryEta: undefined,
    });
    onNext();
  };

  const onDeliveryMethodChange = (type: OptionalDeliveryMethodsType) => {
    const selectedDeliveryMethod = (vendor?.deliveryMethods || []).find(
      (dm) => dm.deliveryType === type
    );

    if (selectedDeliveryMethod) {
      analytics.track('pay-bill', 'delivery-method-select', { type });
      setSelectedDeliveryMethodType(selectedDeliveryMethod.deliveryType);
      setSelectedDeliveryMethodId(selectedDeliveryMethod.id);
      goEditDeliveryMethod(type, selectedDeliveryMethod.id);
    } else if (paymentId) {
      analytics.track('pay-bill', 'delivery-method-add', { type });
      goEditDeliveryMethod(type);
    } else {
      analytics.track('pay-bill', 'delivery-method-add', { type });
      goAddDeliveryMethod(type);
    }
  };

  const companyName = vendor?.printOnCheck || vendor?.companyName;
  const titleValues = { vendor: companyName };

  return (
    <QBRPayBillSelectDeliveryMethodPage
      onNext={onSubmit}
      onPrev={onPrev}
      goExit={onExit}
      companyName={companyName}
      amount={payment?.totalAmount?.toString() as string}
      bill={bill}
      deliveryMethodOptions={deliveryMethodOptions}
      selectedDeliveryMethod={selectedDeliveryMethodType}
      onDeliveryMethodChange={onDeliveryMethodChange}
      headerLabel={headerLabel}
      goAddVirtualDeliveryMethod={goAddVirtualDeliveryMethod}
      selectedFundingSource={fundingSource}
      title="bills.pay.deliveryMethods.title"
      titleValues={titleValues}
      subtitle="bills.pay.deliveryMethods.subtitle"
    />
  );
};

export default SelectDeliveryMethodPage;
