import React, { FC } from 'react';
import { Text } from '@melio/billpay-design-system';
import { TableHeaderSkeleton } from 'src/app/version-2/pages/batch-bulk/components/BatchBulkPage/BatchBulkPage.styles';

// eslint-disable-next-line react/prop-types
export interface HeaderRendererProps {
  title: string;
  id?: string;
}
export const HeaderRenderer: FC<HeaderRendererProps> = ({ title, id }) => (
  <Text id={id} textStyle="ds.body3">
    {title}
  </Text>
);

interface HeaderRendererWrappersProps {
  isLoading: boolean;
  children: React.ReactElement;
}
export const HeaderRendererWrapper = ({ isLoading, children }: HeaderRendererWrappersProps) => {
  if (isLoading) {
    return <TableHeaderSkeleton />;
  }

  return children;
};
