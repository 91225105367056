import { postRequest, fetchRequest } from './api';
import { envApi } from '../../version-2/api/env';

const apiBaseUrl = envApi.getConfig().server.baseUrl;

export function baseURL(url) {
  if (!url) {
    return '';
  }

  return url.startsWith('/') ? `${apiBaseUrl}/api/${url}` : url;
}

export default {
  uploadFile(orgId, file, conf = {}) {
    const url = `/orgs/${orgId}/files`;

    const data = new FormData();

    data.append('file', file);

    return postRequest(url, data, conf);
  },

  uploadCompanyLogoFile(orgId, file, conf = {}) {
    const url = `/orgs/${orgId}/files/company-logo`;

    const data = new FormData();

    data.append('file', file);

    return postRequest(url, data, conf);
  },

  billDetails(orgId, id) {
    const url = `/orgs/${orgId}/files/${id}/bill-details`;

    return fetchRequest(url);
  },

  fetchFileUrls(orgId, id) {
    const url = `/orgs/${orgId}/files/${id}`;

    return fetchRequest(url).then((result) => {
      const fileStorageUrl = baseURL(result.fileStorageUrl);
      const filePreviewUrls = result.filePreviewUrls.map((filePreviewUrl) =>
        baseURL(filePreviewUrl)
      );

      return {
        fileStorageUrl,
        filePreviewUrls,
      };
    });
  },

  getLogoByVendorHandle(link) {
    const url = `/me/${link}/logo`;

    return fetchRequest(url, null, { isBypassThrowError: true })
      .then((result) => {
        const fileStorageUrl = baseURL(result.fileStorageUrl);

        return {
          fileStorageUrl,
        };
      })
      .catch(() => ({ fileStorageUrl: '' }));
  },
};
