import * as React from 'react';
import { useTranslation } from 'src/app/version-2/utils/translations';
import styled from 'styled-components';
import { NotificationCard, NotificationTypeEnum } from '@melio/billpay-design-system';
import {
  isRetryFailedToDeliverACH,
  getRetryFailedToDeliverACH,
} from 'src/app/version-2/utils/payment.utils';
import { DeliverFailureData } from 'src/app/version-2/model/dtos';

type Props = {
  routingNumber?: string;
  accountNumber?: string;
  errorCode: string;
  errorTitle?: string;
  deliverFailureData?: DeliverFailureData;
};

const EditBankDeliveryMethodNotification = ({
  routingNumber,
  accountNumber,
  errorCode,
  errorTitle,
  deliverFailureData,
}: Props) => {
  const { formatMessage } = useTranslation();

  const isRetryFailedToDeliver = isRetryFailedToDeliverACH({ deliverFailureData });

  if (errorCode) {
    return (
      <StyledNotificationCard
        type={NotificationTypeEnum.ERROR}
        title={errorTitle ? formatMessage(`server.${errorTitle}`) : undefined}
        subtitle={formatMessage(`server.${errorCode}`)}
      />
    );
  }

  if (isRetryFailedToDeliver) {
    const errorCode = getRetryFailedToDeliverACH({ deliverFailureData });

    return (
      <StyledNotificationCard
        type={NotificationTypeEnum.INFO}
        subtitle={formatMessage(`bills.pay.editAchDeliveryMethod.failedToDeliverACH.${errorCode}`, {
          routingNumber,
          accountNumber,
        })}
      />
    );
  }

  return null;
};

export { EditBankDeliveryMethodNotification };

const StyledNotificationCard = styled(NotificationCard)`
  margin: 0 0 4rem;

  ul {
    padding-top: 0.8rem;
  }
`;
