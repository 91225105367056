import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FieldType } from 'src/app/utils/types';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { useLocationState } from 'src/app/utils/hooks';

type PartialPaymentAmountReturnType = {
  partialBillAmount: string;
  validationErrors: { totalAmount?: string };
  validationErrorsValues: { billBalance: string };
  editAmount: boolean;
  onChange: (FieldType) => void;
};

type OnChangeProps = FieldType;

export function usePartialPaymentAmount(
  initialPartialAmount: string,
  billBalance: number
): PartialPaymentAmountReturnType {
  const intl = useIntl();
  const [validationErrors, setValidationErrors] = useState({});
  const [partialBillAmount, setPartialBillAmount] = useState(initialPartialAmount);
  const [editAmount] = useLocationState('editAmount', false);

  const onChange = ({ value }: OnChangeProps) => {
    const amount = Number(convertCurrencyToNumber(value));

    setPartialBillAmount(value);

    if (billBalance && amount > billBalance) {
      setValidationErrors({
        totalAmount: 'bills.form.partialPayments.errors.editAmountError',
      });
    } else if (!amount) {
      setValidationErrors({
        totalAmount: 'bills.form.partialPayments.errors.enterAmount',
      });
    } else {
      setValidationErrors({});
    }
  };

  const validationErrorsValues = {
    billBalance: intl.formatNumber(billBalance, {
      style: 'currency',
      currency: 'USD',
    }),
  };

  return {
    partialBillAmount,
    validationErrors,
    validationErrorsValues,
    editAmount,
    onChange,
  };
}
