import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditFundingSourceLabelState } from 'src/app/pages/settings/PaymentMethodsSettingsPageContainer';
import { Box } from '@melio/billpay-design-system';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import MIButton from 'src/app/components/common/MIButton';
import { MIFormattedText } from 'src/app/utils/formatting';
import { FormRow, FormContainer } from 'src/app/ui/form/FormElements';
import { InlineTextInputField, useForm } from 'src/app/ui/form';
import { CONSTS } from 'src/app/utils/consts';
import {
  updateFundingSourceLabelAction,
  removeFundingSourceLabelAction,
} from 'src/app/redux/user/actions';
import { getIsRemovingFundingSourceLabel } from 'src/app/redux/user/selectors';
import analytics from 'src/app/services/analytics';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';

type Props = {
  editingFundingSourceLabel: EditFundingSourceLabelState;
  dismiss: (event?: React.MouseEvent) => void;
};

export const EditPaymentMethodLabelDialog = (props: Props) => {
  const { editingFundingSourceLabel, dismiss } = props;
  const dispatch = useDispatch();
  const isRemovingLabel = useSelector(getIsRemovingFundingSourceLabel);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  const formModelData = useMemo(
    () => ({
      id: editingFundingSourceLabel?.id,
      nickname: editingFundingSourceLabel?.currentNickname,
    }),
    [editingFundingSourceLabel]
  );

  const onSubmit = (id, nickname) =>
    new Promise((resolve, reject) => {
      dispatch(updateFundingSourceLabelAction(id, nickname, resolve, reject));
    });

  const onRemove = async () => {
    await new Promise((resolve, reject) => {
      dispatch(
        removeFundingSourceLabelAction(editingFundingSourceLabel?.id as number, resolve, reject)
      );
    });
    dismiss();
  };

  const [editLabelMV, { submit }, , loading] = useForm(formModelData, {
    submit: async ({ id, nickname }) => {
      if (!nickname) {
        setDisplayErrorMessage(true);

        return false;
      }

      await onSubmit(id, nickname);
      analytics.trackAction('add-payment-method-label-success', {
        id,
        label: nickname,
      });
      dismiss();

      return true;
    },
    onChange: ({ value, modelState }) => {
      setDisplayErrorMessage(!value);

      return modelState;
    },
  });

  return (
    <ModalMessage
      isLoading={loading}
      titleComponent={<MIFormattedText label="settings.paymentMethods.editLabelDialog.title" />}
      descriptionComponent={
        <Box textAlign="left">
          <MIFormattedText label="settings.paymentMethods.editLabelDialog.description" />
        </Box>
      }
      contentComponent={
        <FormContainer onSubmit={submit}>
          <FormRow>
            <InlineTextInputField
              id="editPMLabel"
              label="settings.paymentMethods.editLabelDialog.inputLabel"
              placeholder="settings.paymentMethods.editLabelDialog.placeholder"
              model={editLabelMV.nickname}
              autoFocus
              required
              type="text"
              errorMessage={
                displayErrorMessage ? 'settings.paymentMethods.editLabelDialog.emptyError' : ''
              }
            />
          </FormRow>
        </FormContainer>
      }
      alignLeft
      onCloseClick={dismiss}
      buttonComponent={
        <GroupContainer>
          <MIButton
            label="settings.paymentMethods.editLabelDialog.saveCta"
            type="submit"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={submit}
            isProcessing={loading}
            disabled={isRemovingLabel}
            fullWidth
          />
          {editingFundingSourceLabel?.currentNickname && (
            <MIButton
              label="settings.paymentMethods.editLabelDialog.removeCta"
              variant={CONSTS.BUTTON_VARIANT.CANCEL}
              onClick={onRemove}
              isProcessing={isRemovingLabel}
              disabled={loading}
              fullWidth
            />
          )}
        </GroupContainer>
      }
    />
  );
};

const GroupContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;

  > button {
    width: auto;
    min-width: 15.8rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    gap: 0.2rem;
    flex-direction: column;

    > button:last-of-type {
      margin-right: 0;
    }
    > button:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;
