import React, { ReactNode } from 'react';
import { SmartIcon, Text } from '@melio/billpay-design-system';
import {
  ArrivalDateButtonContainer,
  ArrivalDateButtonLabel,
  DropDownIconContainer,
} from 'src/app/version-2/pages/batch-bulk/renderers/ArrivalDate/ArrivalDate.renderer.styles';

interface ArrivalDateButtonProps {
  isMenuOpen: boolean;
  label: ReactNode;
}

export const ArrivalDateButton = ({ isMenuOpen, label }: ArrivalDateButtonProps) => (
  <ArrivalDateButtonContainer>
    <ArrivalDateButtonLabel>
      <Text textStyle="ds.body3" as={ArrivalDateButtonContainer} data-testid="current-arrival-date">
        {label}
      </Text>
    </ArrivalDateButtonLabel>
    <DropDownIconContainer isOpen={isMenuOpen}>
      <SmartIcon type="arrow-dropdown" />
    </DropDownIconContainer>
  </ArrivalDateButtonContainer>
);
