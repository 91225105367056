import React from 'react';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { BUTTONS_DIRECTIONS, DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';

export type CommonDialogProps = {
  title: string;
  description?: string;
  textValues?: Record<string, any>;
  confirmText?: string;
  cancelText?: string;
  variant?: DIALOG_VARIANTS;
  dismiss: () => any;
  confirm: () => Promise<any>;
  showCancel?: boolean;
  hideIcon?: boolean;
  maxWidth?: string;
  minHeight?: string;
  fullWidth?: boolean;
  onOkDisabled?: boolean;
  children?: React.ReactNode;
  buttonsDirection?: BUTTONS_DIRECTIONS;
  close?: () => any;
};
export const CommonDialog = ({
  title,
  description,
  textValues,
  variant,
  confirm,
  confirmText,
  cancelText,
  dismiss,
  hideIcon,
  showCancel,
  maxWidth,
  minHeight,
  fullWidth,
  children,
  buttonsDirection,
  onOkDisabled,
}: CommonDialogProps) => (
  <QBODialog
    type={DIALOG_TYPE.CONFIRM}
    variant={variant || DIALOG_VARIANTS.ERROR}
    title={title}
    titleValues={textValues}
    subtitle={description}
    subtitleValues={textValues}
    okButtonText={confirmText}
    cancelButtonText={cancelText}
    onOkAction={() => confirm().then(dismiss)}
    onOkDisabled={onOkDisabled}
    onCancelAction={dismiss}
    hideIcon={hideIcon}
    showCancel={showCancel}
    maxWidth={maxWidth}
    minHeight={minHeight}
    fullWidth={fullWidth}
    buttonsDirection={buttonsDirection}
  >
    {children}
  </QBODialog>
);
