export const enum HeaderAccessorEnum {
  EXPANDER = 'EXPANDER',
  VENDOR = 'companyName',
  BILL = 'invoiceNumber',
  PAYMENT_METHOD = 'selectedPaymentMethod',
  DEDUCTION_DATE = 'deductionDate',
  DELIVERY_METHOD = 'selectedDeliveryMethod',
  DELIVERY_SPEED = 'maxDeliveryEta',
  DELIVERY_DATE = 'deliveryEta',
  ARRIVAL_DATE = 'arrivalDate',
  AMOUNT = 'amount',
  DETAILS = 'DETAILS',
  REMOVE = 'Remove',
  MEMO = 'MEMO',
}
