import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { fullOrgSyncStore } from 'src/app/modules/fullOrgSync/full-org-sync-store';
import { FullOrgSyncStatusEnum } from 'src/app/version-2/model/enums';
import { QB_DASHBOARD_TAB_STATUS } from '../consts';
import useDashboardListParams from './useDashboardListParams';
import { useLoadDashboardList } from './useLoadDashboardList';

const SYNC_INTERVAL = 5000;

/**
 * The hook is supposed to be used to periodically load list data for the Unpaid tab. The loading will
 * only occur during the Full Org sync.
 */
const useDashboardListPolling = () => {
  const fullOrgSyncStatus: FullOrgSyncStatusEnum = useSelector(fullOrgSyncStore.selectors.status);
  const [listParams] = useDashboardListParams();
  const { loadDashboardList } = useLoadDashboardList({ silent: true });

  const pullingInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const startPullingInterval = () => {
    stopPullingInterval();

    pullingInterval.current = setInterval(pullDashboardList, SYNC_INTERVAL);
  };

  const stopPullingInterval = () => {
    if (pullingInterval.current) {
      clearInterval(pullingInterval.current);
      pullingInterval.current = null;
    }
  };

  const pullDashboardList = () => {
    loadDashboardList(listParams);
  };

  useEffect(() => {
    if (fullOrgSyncStatus !== FullOrgSyncStatusEnum.RUNNING) {
      stopPullingInterval();

      if (
        [FullOrgSyncStatusEnum.DONE, FullOrgSyncStatusEnum.ERROR].includes(fullOrgSyncStatus) &&
        listParams.filters.status === QB_DASHBOARD_TAB_STATUS.UNPAID
      ) {
        pullDashboardList();
      }
    }
  }, [fullOrgSyncStatus]);

  useEffect(() => {
    if (
      fullOrgSyncStatus === FullOrgSyncStatusEnum.RUNNING &&
      listParams.filters.status === QB_DASHBOARD_TAB_STATUS.UNPAID
    ) {
      startPullingInterval();
    }
  }, [listParams]);

  useEffect(() => stopPullingInterval, []);
};

export { useDashboardListPolling };
