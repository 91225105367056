import { useEffect } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BackButton,
  Box,
  Button,
  Input,
  Link,
  Select,
  SmartIcon,
} from '@melio/billpay-design-system';
import { envApi } from 'src/app/version-2/api/env';
import { analytics, MIAddressAutocomplete, QBOLayoutPage } from 'src/app/version-2/externals';
import { ReactComponent as QBLogo } from 'src/app/version-2/images/qb-logo.svg';
import { Address } from 'src/app/version-2/model/dtos';
import { GoogleCombinedAddress } from 'src/app/version-2/model/dtos/googleCombinedAddress';
import { FcbOptions } from 'src/app/version-2/pages/global-opt-in-details/model/consts/fcbOptions';
import {
  BUSINESS_DETAILS_CTA_CLICK,
  BUSINESS_DETAILS_FORM_ERROR,
  BUSINESS_DETAILS_PAGE_EVENT_NAME,
  GLOBAL_OPT_IN_PAGE,
} from 'src/app/version-2/pages/global-opt-in-details/model/consts/GlobalOptInAnalytics.consts';
import { FcbOptionValuesEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/FcbOptionValuesEnum.enum';
import { globalOptInFormFieldNamesEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInFormFieldNamesEnum.enum';
import { globalOptInSteps } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInSteps.enum';
import { OptInBusinessDetailsFormData } from 'src/app/version-2/pages/global-opt-in-details/model/objects/optinBusinessDetailsFormData';
import { businessDetailsSchema } from 'src/app/version-2/pages/global-opt-in-details/model/schemas/businessDetailsValidation.schema';
import {
  globalOptInSelectors,
  globalOptInSliceActions,
} from 'src/app/version-2/pages/global-opt-in-details/modules/globalOptIn.slice';
import { transformErrorsToAnalyticsFormat } from 'src/app/version-2/pages/global-opt-in-details/utils/globalOptInDetailsAnalytics.utils';
import { convertToServerAddress } from 'src/app/version-2/utils/convertToServerAddress.utils';
import {
  addressCompleteStyle,
  cardStyle,
  logoStyle,
  privacyPolicySectionStyle,
  separatorStyle,
  titleStyle,
  yourDetails,
} from './OptInBusinessDetails.styles';

export const OptInBusinessDetails = () => {
  const businessDetailsValue = useSelector(globalOptInSelectors.selectBusinessDetailsData);
  const { token } = useRouteMatch<{ token: string; id: string }>().params;

  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    clearErrors,
    watch,
  } = useForm<OptInBusinessDetailsFormData>({
    resolver: yupResolver(businessDetailsSchema),
    defaultValues: businessDetailsValue,
  });

  const businessNameError = errors[globalOptInFormFieldNamesEnum.businessName];
  const addressError = errors[globalOptInFormFieldNamesEnum.googlePlaceId];
  const fcbStatusError = errors[globalOptInFormFieldNamesEnum.fcbStatus];
  const routingNumberError = errors[globalOptInFormFieldNamesEnum.routingNumber];
  const accountNumberError = errors[globalOptInFormFieldNamesEnum.accountNumber];

  const isOptionalTextShow =
    watch(globalOptInFormFieldNamesEnum.fcbStatus) === FcbOptionValuesEnum.StandaloneBusiness ||
    watch(globalOptInFormFieldNamesEnum.fcbStatus) === FcbOptionValuesEnum.NotSure;

  const onSubmit = (body) => {
    analytics.track(GLOBAL_OPT_IN_PAGE, BUSINESS_DETAILS_CTA_CLICK);
    dispatch(
      globalOptInSliceActions.submitBusinessDetails({
        history,
        token,
        businessDetailsFormData: body,
      })
    );
  };

  const onValidationErrors = (validationErrors: FieldErrors<OptInBusinessDetailsFormData>) => {
    const transformedValidationErrors = transformErrorsToAnalyticsFormat({
      validationErrors,
      stepName: globalOptInSteps.businessDetails,
      formatMessage,
    });

    analytics.trackAction(BUSINESS_DETAILS_FORM_ERROR, {
      error: { ...transformedValidationErrors },
    });
  };

  const updateAddressModel = (address: Address) => {
    const {
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      countryCode,
      aptNumber,
      googlePlaceId,
    } = address;

    setValue(`${globalOptInFormFieldNamesEnum.addressLine1}`, addressLine1 || '');
    setValue(`${globalOptInFormFieldNamesEnum.addressLine2}`, addressLine2 || '');
    setValue(`${globalOptInFormFieldNamesEnum.city}`, city || '');
    setValue(`${globalOptInFormFieldNamesEnum.state}`, state || '');
    setValue(`${globalOptInFormFieldNamesEnum.zipCode}`, zipCode || '');
    setValue(`${globalOptInFormFieldNamesEnum.countryCode}`, countryCode || '');
    setValue(`${globalOptInFormFieldNamesEnum.aptNumber}`, aptNumber || '');
    setValue(`${globalOptInFormFieldNamesEnum.googlePlaceId}`, googlePlaceId || '');
  };

  const onChangeAddress = (address: GoogleCombinedAddress) => {
    setValue(`${globalOptInFormFieldNamesEnum.inputValue}`, address.inputValue);
    setValue(`${globalOptInFormFieldNamesEnum.formattedAddress}`, address.formattedAddress);

    if (address && address?.addressComponents?.length > 0) {
      const convertedAddress = convertToServerAddress(address);
      const { googlePlaceId, formattedAddress } = convertedAddress;

      if (googlePlaceId && formattedAddress) {
        clearErrors(globalOptInFormFieldNamesEnum.googlePlaceId);
        updateAddressModel(convertedAddress);
      }

      setValue(`${globalOptInFormFieldNamesEnum.address}`, address);
    } else {
      updateAddressModel({});
      setValue(`${globalOptInFormFieldNamesEnum.address}`, null);
    }
  };

  const handleBusinessTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(`${globalOptInFormFieldNamesEnum.fcbStatus}`, e.target.value);
    clearErrors(globalOptInFormFieldNamesEnum.fcbStatus);
  };

  useEffect(() => {
    analytics.page(GLOBAL_OPT_IN_PAGE, BUSINESS_DETAILS_PAGE_EVENT_NAME);
  }, []);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success" innerSize={53}>
      <Box __css={logoStyle}>
        <QBLogo />
      </Box>
      <Box __css={cardStyle}>
        <BackButton onPrev={() => history.goBack()} />
        <Box __css={titleStyle}>
          {formatMessage({ id: 'vendors.globalOptInCandidates.content.businessDetailsTitle' })}
        </Box>
        <Box __css={separatorStyle} />
        <Box __css={yourDetails}>
          {formatMessage({ id: 'vendors.globalOptInCandidates.content.businessDetailsSubTitle' })}
        </Box>
        <Input
          label={formatMessage({ id: 'vendors.globalOptInCandidates.form.businessName' })}
          type="text"
          helperText={formatMessage({
            id: 'vendors.globalOptInCandidates.content.businessHelperText',
          })}
          {...register(globalOptInFormFieldNamesEnum.businessName)}
          errorMessage={businessNameError && formatMessage({ id: businessNameError.message })}
        />
        <Box __css={addressCompleteStyle}>
          <MIAddressAutocomplete
            id="address"
            addressLabel="vendors.globalOptInCandidates.form.address"
            mode="inline"
            required
            onChange={onChangeAddress}
            allowAllCountries
            address={watch(globalOptInFormFieldNamesEnum.address)}
            hideSuite
            showClearIcon
            addressNotices={['vendors.globalOptInCandidates.content.addressHelperText']}
            errorMessage={addressError && addressError?.message}
            errorMessageIcon={
              <Box mr="ds.md">
                <SmartIcon type="error" size="1.6rem" />
              </Box>
            }
          />
        </Box>
        <Box mb="ds.2xl">
          <Select
            required
            isSearchable={false}
            value={FcbOptions.find(
              (option) => option.value === watch(globalOptInFormFieldNamesEnum.fcbStatus)
            )}
            placeholder={formatMessage({
              id: 'vendors.globalOptInCandidates.content.businessSelectPlaceholder',
            })}
            label={formatMessage({
              id: 'vendors.globalOptInCandidates.form.businessType',
            })}
            options={FcbOptions}
            disableSearch
            onChange={handleBusinessTypeChange}
            errorMessage={fcbStatusError && formatMessage({ id: fcbStatusError?.message })}
          />
        </Box>
        <Box mb="ds.2xl">
          <Input
            {...register(globalOptInFormFieldNamesEnum.routingNumber)}
            label={formatMessage({
              id: 'vendors.globalOptInCandidates.form.routingNumber',
            })}
            type="number"
            inputMode="numeric"
            data-testid="input-routingNumber"
            tooltipLabel={formatMessage({
              id: 'vendors.globalOptInCandidates.content.tooltipLabel',
            })}
            isRequired={!isOptionalTextShow}
            recommendedLabelText={
              isOptionalTextShow
                ? formatMessage({ id: 'vendors.globalOptInCandidates.content.recommended' })
                : undefined
            }
            {...register(globalOptInFormFieldNamesEnum.routingNumber)}
            errorMessage={routingNumberError && formatMessage({ id: routingNumberError?.message })}
          />
        </Box>
        <Box mb="ds.2xl">
          <Input
            {...register(globalOptInFormFieldNamesEnum.accountNumber)}
            label={formatMessage({ id: 'vendors.globalOptInCandidates.form.accountNumber' })}
            type="number"
            inputMode="numeric"
            data-testid="input-accountNumber"
            isRequired={!isOptionalTextShow}
            recommendedLabelText={
              isOptionalTextShow
                ? formatMessage({ id: 'vendors.globalOptInCandidates.content.recommended' })
                : undefined
            }
            rightAddonWidth="0"
            isDisabled={false}
            tooltipLabel={formatMessage({
              id: 'vendors.globalOptInCandidates.content.tooltipLabel',
            })}
            errorMessage={accountNumberError && formatMessage({ id: accountNumberError?.message })}
          />
        </Box>
        <Button variant="primary" size="md" onClick={handleSubmit(onSubmit, onValidationErrors)}>
          {formatMessage({
            id: 'vendors.globalOptInCandidates.content.applyButton',
          })}
        </Button>
        <Box __css={privacyPolicySectionStyle}>
          {formatMessage(
            {
              id: 'vendors.globalOptInCandidates.content.privacyPolicy',
            },
            {
              termsOfService: (
                <Link href={envApi.getConfig().globalOptInFlowLinks.termsOfServices} target="blank">
                  {formatMessage({ id: 'vendors.globalOptInCandidates.content.termOfServiceLink' })}
                </Link>
              ),
              privacyPolicyLink: (
                <Link href={envApi.getConfig().globalOptInFlowLinks.privacyPolicy} target="blank">
                  {formatMessage({ id: 'vendors.globalOptInCandidates.content.privacyLink' })}
                </Link>
              ),
            }
          )}
        </Box>
      </Box>
    </QBOLayoutPage>
  );
};
