import { getJWTPayload } from 'src/app/helpers/jwt';
import analytics from 'src/app/services/analytics';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import {
  ADD_CARD_ACCOUNT_EVENT_PAGE,
  IFRAME_RESPONSE_DATA,
} from 'src/app/version-2/pages/add-card-account/model/consts/AddCardAccountAnalytics.consts';
import {
  ADD_CARD_ACCOUNT_SLICE,
  addCardAccountActions,
  addCardAccountSelectors,
} from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';
import { addCardAccountDeliveryMethodActions } from 'src/app/version-2/pages/add-card-account/modules/deliveryMethod/addCardAccountDeliveryMethod.slice';
import { addCardAccountFundingSourceActions } from 'src/app/version-2/pages/add-card-account/modules/fundingSource/addCardAccountFundingSource.slice';

import { put, select, takeEvery } from 'redux-saga/effects';

function* setCardBrandHandler(action: ReturnType<typeof addCardAccountActions.setCardBrand>) {
  const { event } = action.payload;

  loggingApi.info('addCardAccountSaga.setCardBrandHandler(): event', event);

  yield put(
    addCardAccountActions.cardBrand({
      cardBrand: event.cardBrand,
    })
  );
}

function* setErrorValidationsHandler(action) {
  const {
    fieldId,
    event: { errors, empty, complete },
  } = action.payload;

  const hasError = !!errors?.length || empty;

  const errorValidations = yield select(addCardAccountSelectors.selectErrorValidation);

  const isDirty = !errorValidations[fieldId]?.dirty ? !empty : true;

  yield put(
    addCardAccountActions.errorValidations({
      fieldId,
      hasError,
      empty,
      dirty: isDirty,
      complete,
      errors,
      errorOnBlur:
        (!errorValidations[fieldId]?.complete || !complete) &&
        (errorValidations[fieldId]?.dirty || isDirty),
    })
  );
}

function* linkCardTokenizationHandler(action) {
  const { bt, btData, history } = action.payload;

  const { isVendorFlow, location, vendorToken } = yield select(
    addCardAccountSelectors.selectFlowState
  );

  const { orgId: payloadOrgId } = getJWTPayload(vendorToken);
  const orgId = yield select(userSliceSelectors.selectOrgId) || payloadOrgId;

  yield put({
    type: `${ADD_CARD_ACCOUNT_SLICE}/isRequesting`,
    payload: true,
  });

  let tokenizedData;
  let cardNumberTokenizedData;

  try {
    if (bt) {
      const tokenizedDataPromise = new Promise((resolve, reject) => {
        bt.tokenize({
          type: 'card',
          data: btData,
          mask: {
            number: '{{ data.number | slice: 0, 6 }}XXXXXX{{ data.number | last4 }}',
            expiration_month: '{{ data.expiration_month }}',
            expiration_year: '{{ data.expiration_year }}',
          },
        })
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });

      tokenizedData = yield tokenizedDataPromise;

      const cardNumberTokenizedDataPromise = new Promise((resolve, reject) => {
        bt.tokenize({
          type: 'card_number',
          data: btData.number,
        })
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });

      cardNumberTokenizedData = yield cardNumberTokenizedDataPromise;

      loggingApi.info(
        'addCardAccountSaga.linkCardTokenizationHandler(): tokenizedDataPromise and cardNumberTokenizedDataPromise passed',
        { orgId }
      );
    }
  } catch (error) {
    loggingApi.error('addCardAccountSaga.linkCardTokenizationHandler(): failed', { orgId, error });
    history.goBack();
  }

  if (tokenizedData && cardNumberTokenizedData) {
    const {
      id,
      data: { number, expiration_month: expirationMonth, expiration_year: expirationYear },
    } = tokenizedData;

    const { id: cardNumberToken, fingerprint: cardNumberFingerprint } = cardNumberTokenizedData;

    const actionTypeAccordingToVendorFlow = isVendorFlow
      ? addCardAccountFundingSourceActions.validateCardAccount.type
      : addCardAccountDeliveryMethodActions.validateCard.type;

    const data = {
      orgId,
      cardToken: id,
      cardBin: number.slice(0, 6),
      digits: number.slice(-4),
      expiration: `${expirationMonth}/${expirationYear.toString().slice(-2)}`,
      cardNumberIdentifiers: {
        cardNumberToken,
        cardNumberFingerprint,
      },
      token: vendorToken,
      location,
      history,
    };

    analytics.track(ADD_CARD_ACCOUNT_EVENT_PAGE, IFRAME_RESPONSE_DATA);

    loggingApi.info('addCardAccountSaga.linkCardTokenizationHandler(): IFRAME_RESPONSE_DATA', {
      orgId,
    });

    yield put({
      type: actionTypeAccordingToVendorFlow,
      payload: data,
    });
  }
}

export function* watchAddCardAccount() {
  yield takeEvery(addCardAccountActions.setCardBrand, setCardBrandHandler);
  yield takeEvery(addCardAccountActions.setErrorValidations, setErrorValidationsHandler);
  yield takeEvery(addCardAccountActions.linkCardTokenization, linkCardTokenizationHandler);
}
