import { prefix } from '../../consts';

export const fontWeights = {
  [prefix]: {
    bold: 'bold',
    semi: 600,
    medium: 500,
    regular: 'normal',
  },
};
