import * as React from 'react';
import styled from 'styled-components';
import { devices } from '../../theme/AppDevices';

type SingleViewLoadingContainerProps = {
  children: React.ReactNode;
  isEditMode?: boolean;
  className?: string;
};

const SingleViewLoadingContainer = ({ children, isEditMode }: SingleViewLoadingContainerProps) => (
  <SingleViewLoadingWrapper isEditMode={isEditMode}>{children}</SingleViewLoadingWrapper>
);

const SingleViewLoadingWrapper = styled.div<{ isEditMode?: boolean }>`
  opacity: 1;
  display: flex;
  flex-direction: column;
  &.loading {
    opacity: 0;
  }

  padding-bottom: ${(props) => (props.isEditMode ? '7rem' : '0')};
  padding-top: 0;

  @media ${devices.mobile}, ${devices.phablet} {
    padding-top: ${(props) => (props.isEditMode ? '7rem' : '0')};
    padding-bottom: 0;
  }

  ${(props) => props.theme?.components?.SingleViewLoadingContainer?.SingleViewLoadingWrapper}
`;

type EmptyListButtonContainerProps = {
  children: React.ReactNode;
};

const EmptyListButtonContainer = ({ children }: EmptyListButtonContainerProps) => (
  <EmptyListButtonWrapper>{children}</EmptyListButtonWrapper>
);

SingleViewLoadingContainer.defaultProps = {};

const EmptyListButtonWrapper = styled.div`
  max-height: 5rem;
  width: 100%;
`;

type CloseButtonContainerProps = {
  onClick: () => void;
};

const CloseButton = ({ onClick }: CloseButtonContainerProps) => (
  <CloseButtonWrapper onClick={onClick}>
    <i className="icon-close-icon" />
  </CloseButtonWrapper>
);

CloseButton.defaultProps = {};

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;

  i {
    cursor: pointer;
  }

  color: ${(props) => props.theme.text.color.label};
  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }
`;

export { SingleViewLoadingContainer, EmptyListButtonContainer, CloseButton };
