import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

const baseStyleOverlay: SystemStyleObject = {
  bg: 'ds.overlay',
  zIndex: 'modal',
};

const baseStyleDialogContainer: SystemStyleFunction = (props) => {
  const { isCentered, scrollBehavior } = props;
  const marginY = { md: '8rem', base: 'ds.md' };
  const marginX = { md: 0, base: 'ds.lg' };
  const width = { md: '100vw', base: 'auto' };

  return {
    display: 'flex',
    zIndex: 'modal',
    justifyContent: 'center',
    alignItems: isCentered ? 'center' : 'flex-start',
    overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
    my: marginY,
    mx: marginX,
    w: width,
  };
};

const baseStyleDialog: SystemStyleFunction = () => {
  const padding = { md: 'ds.2xl', base: 'ds.xl' };

  return {
    borderRadius: 'ds.lg',
    bg: 'ds.white',
    color: 'inherit',
    maxH: 'full',
    p: padding,
    zIndex: 'modal',
    boxSizing: 'border-box',
  };
};

const baseStyleHeader: SystemStyleObject = {
  p: 0,
  w: 'full',
  mb: { md: 'ds.md', base: 'ds.lg' },
  color: 'ds.black',
};

const baseStyleCloseButton: SystemStyleObject = {
  pos: 'absolute',
  top: 'ds.lg',
  insetEnd: 'ds.xl',
  w: 'ds.xl',
  h: 'ds.xl',
};

const baseStyleBody: SystemStyleFunction = (props) => {
  const { scrollBehavior } = props;

  return {
    flex: 1,
    overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
    mb: { md: 'ds.2xl', base: '3.2rem' },
    px: 0,
    color: 'ds.black',
  };
};

const baseStyleFooter: SystemStyleFunction = () => ({
  px: 0,
  pb: 0,
});

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  footer: baseStyleFooter(props),
});

const getSize = (value: string): PartsStyleObject<typeof parts> => {
  const modalSizes = {
    full: {
      dialog: {
        maxW: '100vw',
        minH: 'fit-content',
        '@supports(min-height: -webkit-fill-available)': {
          minH: '-webkit-fill-available',
        },
        my: 0,
        borderRadius: 'none',
        boxShadow: 'none',
      },
      dialogContainer: {
        my: 0,
        mx: 0,
        maxHeight: '100vh',
      },
    },
    sm: {
      dialog: {
        maxW: '49.6rem',
        my: 0,
      },
    },
    md: {
      dialog: { maxW: '57.6rem' },
    },
  };

  return modalSizes[value];
};

const sizes = {
  sm: getSize('sm'),
  md: getSize('md'),
  full: getSize('full'),
};

const defaultProps = {
  size: 'md',
};

export const ModalTheme = {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps,
};

export const CoverTheme: ComponentStyleConfig = {
  baseStyle: {
    w: 'full',
  },

  sizes: {
    sm: {
      h: '14.4rem',
    },
    md: {
      h: '24rem',
    },
    lg: {
      h: '32rem',
    },
  },

  defaultProps: {
    size: 'md',
  },
};
