/* eslint-disable no-template-curly-in-string, max-len */
import { EventMappingsTree } from 'src/app/services/analytics/types';

const MAPPING: EventMappingsTree = {
  '/orgs/*/welcome/funding-sources/bank/account': {
    'page.view': ['qbo-account', 'select'],
    'go.exit': ['qbo-account', 'go-exit'],
    'option-changed-intuitAccountId': [
      'qbo-account',
      'changed-to',
      { qboAccountId: 'option.value' },
    ],
    'qbo-account-connected': [
      'qbo-account',
      'payment-account-connected',
      { accountType: 'type', fundingSourceId: 'fundingSourceId' },
    ],
    'form-validation-error': ['qbo-account', 'connect-error', { error: 'validationErrors' }],
  },
  '/orgs/*/welcome/funding-sources/bank/complete': {
    'page.view': ['add-funding-source', 'success'],
    'onboarding.fundingSources.bank.manually.buttonCTA': ['add-funding-source', 'continue'],
  },
  '/orgs/*/welcome/funding-sources/bank/manually': {
    'page.view': ['add-funding-source', 'bank-manually'],
    'form-validation-error': [
      'bank-add-microdeposit',
      'bank-add-microdeposit-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'go.exit': ['bank-add-microdeposit', 'funding-source-added-manually-close'],
    'funding-source-added-manually': ['bank-add-microdeposit', 'funding-source-added-manually'],
    'funding-source-added-manually-success': [
      'bank-add-microdeposit',
      'funding-source-added-manually-success',
    ],
  },
  '/orgs/*/welcome/funding-sources/bank/cant-find': {
    'page.view': ['add-funding-source', 'bank-cant-find'],

    'original-connect-manually': ['bank-add-cant-find', 'funding-source-cant-find-bank'],
    'original-cancel': ['bank-add-cant-find', 'cancel'],

    'push-to-md-submit': ['bank-add-microdeposit', 'funding-source-added-manually'],
    'push-to-md-submit-success': ['bank-add-microdeposit', 'funding-source-added-manually-success'],
    'push-to-md-choose-new-method': [
      'bank-add-microdeposit',
      'PlaidFailedUseDifferentPaymentMethod-Selected',
      { billId: 'billId', vendorId: 'vendorId' },
    ],
    'form-validation-error': [
      'bank-add-microdeposit',
      'bank-add-microdeposit-validation-error',
      { validationErrors: 'validationErrors' },
    ],
  },
};

export default MAPPING;
