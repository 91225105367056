/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Big } from 'big.js';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import locations from 'src/app/utils/locations';
import globalLocations from 'src/app/pages/locations';
import intuit from 'src/app/utils/intuit';
import { getPurposeByType } from 'src/app/utils/payments';
import { melioClose } from 'src/app/utils/external-events';
import find from 'lodash/find';
import { Loader } from '@melio/billpay-design-system';
import sumBy from 'lodash/sumBy';
import head from 'lodash/head';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import countBy from 'lodash/countBy';
import analytics from 'src/app/services/analytics';
import batchBillsStore, { getBillListActions } from 'src/app/modules/batch-bills/batch-bills-store';
import paymentStore, { getPaymentsActions } from 'src/app/modules/payments/payment-store';
import profileStore from 'src/app/modules/profile/profile-store';
import MIButton from 'src/app/components/common/MIButton';
import PaymentItemList from 'src/app/pages/bill/pay/PaymentItemList';
import SidePanelLayout from 'src/app/components/layout/SidePanelLayout';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { getCompanyInfo, getUserPreferences } from 'src/app/redux/user/selectors';
import { RecordOf } from 'immutable';
import {
  BillType,
  PaymentType,
  CompanyInfoType,
  ConfirmationOrigin,
  UserPreferencesType,
  OrganizationPreferencesType,
} from 'src/app/utils/types';
import {
  PaymentMethodErrors,
  BatchDataItem,
  billListModifiedType,
} from 'src/app/pages/bill/pay/types';
import BatchNotifications from 'src/app/pages/bill/pay/QBOBatchNotifications';
import moment from 'moment';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import {
  calculateTotalFee,
  getPaymentFastFeeForPartialPayments,
  isFastDeliveryType,
} from 'src/app/utils/delivery-methods';
import { CONSTS, PURPOSE_OF_PAYMENT_STRUCTURE, FAST_DELIVERY_TYPES } from 'src/app/utils/consts';
import { FundingSourceOrigins } from 'src/app/version-2/model/enums';
import { useCheckRequiredLegalCompanyInfo } from 'src/app/modules/organizations/hooks/useCheckRequiredLegalCompanyInfo';
import { useModal } from 'src/app/helpers/react/useModal';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { loadFundingSourcesAction } from 'src/app/redux/user/actions';
import { getPartialBillId, hasOneBillWithMaximumAmountLimit } from 'src/app/utils/bills';
import { updateUserPreference } from 'src/app/services/api/userPreferences';
import { useAmexVerification } from 'src/app/pages/bill/pay/hooks/useAmexVerification';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import userApi from 'src/app/services/api/user';
import { featureFlags } from '@melio/shared-web';
import { useDebitFee } from 'src/app/pages/bill/pay/hooks/useDebitFee';
import { DEFAULT_DASHBOARD_REDIRECT_PARAMS } from 'src/app/pages/qb-dashboard/hooks/useGetDashboardListItemPaginationParams';
import { VirtualCardInfoModal } from 'src/app/pages/bill/components/VirtualCardInfoModal';
import { useVirtualCardInfoModal } from 'src/app/pages/bill/pay/hooks/useVirtualCardInfoModal';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { PaymentExceedsAvailableBalanceModal } from './components/PaymentExceedsAvailableBalanceModal';
import useSetPaymentsMemo from './hooks/useSetPaymentsMemo';
import { PartialPayments, usePartialPayments } from './hooks/usePartialPayments';
import { getArrayOfVendorIdToPaymentIdMapping } from '../utils';

type Props = {
  partialPayments?: PartialPayments;
  currentId: string | null;
  preservedState: Record<string, any>;
};

type BatchLocalState = {
  ids: string;
  vendorId?: number;
  orderedIds: string[];
  isLimitNotificationClosed?: boolean;
  isCompletedNotificationClosed?: boolean;
  hadErrors?: boolean;
  isHolidaysNotificationClose?: boolean;
};

let batchLocalState: BatchLocalState = {
  ids: '',
  orderedIds: [],
};

type PaymentData = { paymentType: string; type: string };

const PayBillBulkPage = ({ partialPayments = {}, currentId, preservedState }: Props) => {
  // hooks
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const { checkRequiredLegalCompanyInfo } = useCheckRequiredLegalCompanyInfo();
  const { setPaymentsMemo } = useSetPaymentsMemo();
  const dispatch = useDispatch();
  const history = useHistory();
  const [batchItems, setBatchItems] = useState<Map<string, BatchDataItem>>(new Map());
  const [isBalanceConfirmModalShown, setBalanceConfirmModalVisibility] = useState<
    boolean | undefined
  >(true);
  const [isSidePanelClosedByUser, setIsSidePanelClosedByUser] = useState(false);
  const [showHolidaysWarningLD] = featureFlags.useFeature('us-holidays-checks');
  const debitFee = useDebitFee();

  const [, setState] = useState({});
  const [numOfErrors, setNumOfErrors] = useState<number>(0);

  const [isHolidaysWarning, setIsHolidaysWarning] = useState<boolean>(false);
  const [selectedFundingSource, setSelectedFundingSource] = useState<any>();
  const forceUpdate = React.useCallback(() => setState({}), [setState]);
  const onLimitNotificationClose = React.useCallback(() => {
    batchLocalState.isLimitNotificationClosed = true;
    forceUpdate();
  }, [forceUpdate]);

  const { shouldDisplayAmexVerification, openAmexModal, AmexModal, MCCCodes } =
    useAmexVerification();

  const onCompletedNotificationClose = React.useCallback(() => {
    batchLocalState.isCompletedNotificationClosed = true;
    forceUpdate();
  }, [forceUpdate]);

  const onHolidaysNotificationClose = React.useCallback(() => {
    batchLocalState.isHolidaysNotificationClose = true;
    forceUpdate();
    updateUserPreference('hideBatchPaymentsHolidaysNotification', true);
  }, [forceUpdate]);

  const [shouldShowVirtualCardInfoModal, setShouldShowVirtualCardInfoModal] =
    useState<boolean>(false);

  const { onToggleVirtualCardInfoModal } = useVirtualCardInfoModal({
    onToggle: () => setShouldShowVirtualCardInfoModal(!shouldShowVirtualCardInfoModal),
  });

  // actions
  const billActions = getBillListActions(dispatch);
  const paymentActions = getPaymentsActions(dispatch);
  const billIds = useMemo(() => Object.keys(partialPayments), [partialPayments]);

  // selectors
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const userId = useSelector(profileStore.selectors.getCurrentUserId);
  const userFundingSources = useSelector(paymentStore.selectors.getUserFundingSources);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const userPreferences: UserPreferencesType = useSelector(profileStore.selectors.preferences);
  const isLoading: boolean = useSelector(paymentStore.selectors.getPaymentLoading);
  const paymentList: PaymentType[] = useSelector(paymentStore.selectors.getPaymentList);
  const billListModified: Record<string, RecordOf<billListModifiedType>> = useSelector(
    paymentStore.selectors.billListModified
  );
  const selectedBill: billListModifiedType = useSelector(
    paymentStore.selectors.getSelectedBill({
      currentId,
      isExternalFlow: !!preservedState,
      preservedState,
    })
  );

  const companyInfo: RecordOf<CompanyInfoType> = useSelector(getCompanyInfo);
  const qbCashBalance = useSelector(fundingSourcesStore.selectors.getQBCashBalance.balance);
  const isLimitReached: boolean = useSelector(
    batchBillsStore.selectors.getBillListByIdsSlice.isLimitReached
  );
  const filteredDirectPayments: boolean = useSelector(
    batchBillsStore.selectors.getBillListByIdsSlice.filteredDirectPayments
  );
  const totalCount: number = useSelector(
    batchBillsStore.selectors.getBillListByIdsSlice.totalCount
  );
  const redirectUrl = useSelector(paymentStore.selectors.qboBatchPaymentsRedirectUrl);
  const exitUrl = useSelector(paymentStore.selectors.qboBatchPaymentsExitUrl);

  const { hideBatchPaymentsHolidaysNotification } = useSelector(getUserPreferences);

  const itemArray = Array.from(batchItems.values());

  const errors = Array.from(batchItems.values()).filter((item) => item.errors);
  const showCompleted = !errors.length && !!batchLocalState.hadErrors;
  const isSidePanelOpen = !!currentId && !isSidePanelClosedByUser;
  const ids = isEmpty(billIds) ? keys(billListModified) : billIds;
  const redirectRoute = locations.Bills.pay.batch;
  const billId = selectedBill?.bill?.id;

  const { redirectToDashboard, isFetchingRedirectParams } = useRedirectToDashboard();
  const { getPaymentAmount, setPaymentAmount, setPaymentAmounts } = usePartialPayments(dispatch);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => setPaymentAmounts(partialPayments), [partialPayments]);

  useEffect(() => {
    if (!hideBatchPaymentsHolidaysNotification && showHolidaysWarningLD) {
      const isShow = !!find(itemArray, ({ bill }) => {
        const { deliveryMethod, deliveryPreference }: any = head(bill.payments);

        return (
          deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK &&
          !isFastDeliveryType(deliveryPreference) &&
          true
        );
      });

      setIsHolidaysWarning(isShow);
    }
  }, [hideBatchPaymentsHolidaysNotification, itemArray]);

  const selectFundingSourceHandler = (fundingSource) => {
    setSelectedFundingSource(fundingSource);
  };

  const trackBackendEvent = (key, paymentId) => {
    try {
      userApi.trackEvent(userId, 'analytics-batch-payment-scheduled', {
        table: 'payments',
        id: paymentId,
        key,
        value: true,
      });
    } catch (er) {
      // faild to send backend analytics
    }
  };

  const totalAmount = itemArray.reduce((total, { bill }) => total + getPaymentAmount(bill), 0);
  const totalFee = isPartialPaymentsEnabled
    ? itemArray
        .reduce(
          (total, { bill, deliveryOptions }) =>
            total.plus(
              getPaymentFastFeeForPartialPayments(bill, deliveryOptions, getPaymentAmount(bill))
            ),
          new Big(0)
        )
        .toNumber()
    : calculateTotalFee(itemArray);

  const isQBCashFSPlural = () => {
    const count = countBy(
      itemArray,
      (item) => item.bill?.payments?.[0]?.fundingSource?.origin === FundingSourceOrigins.QBCASH
    );

    return count.true > 1;
  };

  const [BalanceConfirmModal, showBalanceConfirmModal] = useModal(
    PaymentExceedsAvailableBalanceModal,
    {
      confirm: async () => {
        setBalanceConfirmModalVisibility(false);
        await onPayPayments();

        return new Promise(() => {
          analytics.trackAction('qbcash-low-balance-schedule-anyway');
        });
      },
      edit: () => {
        const firstBillWithQBCashFS = find(itemArray, ({ bill }) => {
          const payment: PaymentType = bill.payments[0];

          return payment.fundingSource.origin === FundingSourceOrigins.QBCASH;
        });

        analytics.trackAction('qbcash-low-balance-edit-funding-source');
        history.push(
          locations.Bills.pay.batch.url({
            ids: billIds,
            currentId: firstBillWithQBCashFS?.bill.id,
          })
        );
      },
      close: () => {
        analytics.trackAction('qbcash-low-balance-confirm-close');
      },
      title: isQBCashFSPlural()
        ? 'batchPayment.balanceConfirm.titlePlural'
        : 'batchPayment.balanceConfirm.title',
      description: isQBCashFSPlural()
        ? 'batchPayment.balanceConfirm.descriptionPlural'
        : 'batchPayment.balanceConfirm.description',
      confirmText: 'batchPayment.balanceConfirm.confirm',
      cancelText: 'batchPayment.balanceConfirm.cancel',
    }
  );

  const refreshFundingSources = useCallback(
    () =>
      new Promise((resolve, reject) => {
        dispatch(loadFundingSourcesAction(resolve, reject));
      }),
    [dispatch]
  );

  const onPayPayments = useCallback(async () => {
    if (errors?.length) {
      history.push(
        redirectRoute.url({
          ids,
          currentId: errors[0].bill.id,
        })
      );

      return;
    }

    const isMissingLegalInfo = !(await checkRequiredLegalCompanyInfo(companyInfo));

    if (isMissingLegalInfo) {
      history.push(locations.Bills.pay.batchCompleteLegalInfo.url());
    } else {
      try {
        const parsedPaymentList = paymentList.map((paymentItem) => ({
          ...paymentItem,
          note: billListModified[paymentItem.billId as string]?.bill?.note,
        }));

        const response = await paymentActions.createBatchPayment({
          orgId,
          payments: parsedPaymentList,
        });

        const analyticsProps = getFinalAnalyticProps(billListModified);

        analytics.trackAction('schedule-payments-success', analyticsProps);
        analytics.track(
          'pay-bills-batch',
          'fast-ach-exposure',
          getFastAnalyticsProps({
            bills,
            type: CONSTS.DELIVERY_TYPE.ACH,
            getPaymentAmount,
          })
        );
        analytics.track(
          'pay-bills-batch',
          'express-check-exposure',
          getFastAnalyticsProps({
            bills,
            type: CONSTS.DELIVERY_TYPE.CHECK,
            getPaymentAmount,
          })
        );

        response.payload.paid?.forEach((payment) => {
          trackBackendEvent(analyticsProps.batchId, payment.id);
        });

        goQBDashboard(response.payload.paid);
        // eslint-disable-next-line no-empty
      } catch (e) {
        goQBDashboard([]);
      }
    }
  }, [
    billListModified,
    companyInfo,
    errors,
    history,
    ids,
    orgId,
    paymentActions,
    paymentList,
    redirectRoute,
    redirectUrl,
  ]);

  const handleSubmit = useCallback(
    async (itemArray: Record<string, RecordOf<billListModifiedType>>) => {
      if (errors?.length) {
        setNumOfErrors(errors.length);

        return;
      }

      const qbCashTotalAmount = sumBy(values(itemArray), (item: RecordOf<billListModifiedType>) => {
        const payment = head(item.bill.payments);

        if (payment?.fundingSource?.origin === FundingSourceOrigins.QBCASH) {
          return Number(payment?.amount || 0);
        }

        return 0;
      });

      if (qbCashTotalAmount > (qbCashBalance as number)) {
        analytics.trackAction('qbcash-low-balance-confirm-open');
        showBalanceConfirmModal();
      } else {
        await onPayPayments();
      }
    },
    [onPayPayments, qbCashBalance, showBalanceConfirmModal]
  );

  const bills = Object.values(billListModified).map(({ bill }) => bill);

  const goQBDashboard = async (paidPayments) => {
    const itemIds = paidPayments.map((payment) => ({
      billId: payment.billId,
      paymentId: payment.id,
      vendorId: payment.vendorId,
    }));

    const batchPaymentIds = paidPayments.map((item) => item.id);

    analytics.trackAction('go-to-dashboard', {
      flow: 'batch',
      fromDashboard: false,
    });

    await redirectToDashboard({
      redirectQuery: DEFAULT_DASHBOARD_REDIRECT_PARAMS,
      state: {
        batchPaymentIds,
        billListModified,
        confirmationOrigin: ConfirmationOrigin.BATCH_PAYMENT,
        vendorsToPaymentsMapping: getArrayOfVendorIdToPaymentIdMapping(itemIds),
      },
    });
  };

  useEffect(() => {
    billActions.getBillListByIds({
      orgId,
      billIds,
    });
  }, []);

  useEffect(() => {
    if (filteredDirectPayments) {
      analytics.trackAction('filtered-biller-directory-bills', {
        partialBillIds: bills.map(getPartialBillId),
        totalBills: totalCount,
        totalDisplayedBills: bills.length,
        totalFilteredBills: totalCount - bills.length,
      });
    }
  }, [filteredDirectPayments, totalCount, bills]);

  useEffect(() => {
    refreshFundingSources().then(() => {
      intuit.endLoadingWrapper();
    });
  }, [refreshFundingSources]);

  useEffect(() => {
    if (preservedState) {
      const eventData = getPaymentData(preservedState, selectedBill?.bill, userFundingSources);

      if (!eventData) return;

      if (selectedFundingSource) {
        preservedState.selectedFundingSourceId = selectedFundingSource.id;
      }

      paymentActions.setPaymentMethod({
        ...preservedState,
        billId: currentId,
        ...eventData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preservedState, currentId, selectedFundingSource]);

  const fetchShouldDisplayAmexVerifications = useCallback(async (bill) => {
    const response = await shouldDisplayAmexVerification(
      bill?.payments?.[0]?.fundingSource,
      bill?.vendor?.id
    );

    return response;
  }, []);

  useEffect(() => {
    if (!billListModified || billListModified.length) {
      return;
    }

    const billListEntries = Object.entries(billListModified).map(
      ([id, item]): [string, Promise<BatchDataItem>] => [
        id,
        billToBatchDataItem(item, fetchShouldDisplayAmexVerifications),
      ]
    );

    if (!billListEntries.length) {
      return;
    }

    const promises = billListEntries.map((entries) => entries[1]);

    Promise.all(promises).then((resolved) => {
      const resolvedBillListEntries = resolved.map((entries) => [
        `${entries?.bill?.id}`,
        entries,
      ]) as any;

      if (hasOneBillWithMaximumAmountLimit(resolved)) {
        history.replace(globalLocations.entrypoints.errors.batchBillAmountLimit, { exitUrl });
      } else {
        let orderedItems: Map<string, BatchDataItem>;
        const billIdsAsString = billIds.join(',');

        const isNewBills = batchLocalState.ids !== billIdsAsString;

        if (isNewBills) {
          orderedItems = new Map(resolvedBillListEntries.sort(compareByHasErrorsOrByDate));
          const hadErrors = resolvedBillListEntries.some(
            ([, item]) => !!Object.keys(item.errors || {}).length
          );

          setPaymentsMemo();

          batchLocalState = {
            ids: billIdsAsString,
            orderedIds: Array.from(orderedItems.keys()),
            hadErrors,
          };
        } else {
          orderedItems = new Map(
            resolvedBillListEntries.sort(
              ([a], [b]) =>
                batchLocalState.orderedIds.indexOf(a) - batchLocalState.orderedIds.indexOf(b)
            )
          );
        }

        setBatchItems(orderedItems);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billListModified, billIds, numOfErrors]);

  useEffect(() => {
    if (batchItems.size === 0 || isSidePanelClosedByUser || currentId || !errors?.length) return;

    history.push(
      redirectRoute.url({
        ids,
        currentId: errors[0].bill.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchItems, currentId]);

  const {
    components: { StepLayout },
  } = useSiteContext();

  const goExit = () => {
    if (exitUrl) {
      history.push(exitUrl as any);
    } else {
      melioClose();
    }
  };

  const renderCustomFooter = () => {
    if (isSidePanelOpen) return <CustomFooter />;

    return (
      <CustomFooter>
        <MIButton
          label="batchPayment.buttons.cancel"
          variant={CONSTS.BUTTON_VARIANT.CANCEL}
          onClick={goExit}
          disabled={isLoading}
        />
        <MIButton
          label="batchPayment.buttons.scheduleAllPayments"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          isProcessing={isLoading}
          onClick={() => handleSubmit(billListModified)}
          disabled={bills.length === 0}
        />
      </CustomFooter>
    );
  };

  const onSaveAndContinue = (payment: RecordOf<PaymentType>, deliveryOptions) => {
    if (!payment) return;

    if (payment.billId) {
      const item = batchItems.get(payment.billId.toString());

      if (item) {
        const batchItemsCopy = new Map(batchItems);

        item.errors = null;
        setBatchItems(batchItemsCopy);
      }
    }

    billActions.updateBatchBillsNoteById({
      note: payment.note as string,
      billId: payment.billId as string,
    });

    billActions.updateDeliveryOptions({
      billId: payment.billId,
      deliveryOptions,
    });
    billActions.updateDeliveryMethods({
      billId: payment.billId,
      deliveryMethods: payment.vendor.deliveryMethods,
    });
    paymentActions.changePayment(payment);

    history.push(
      redirectRoute.url({
        ids,
        currentId: null,
      })
    );
  };

  const closeSidePanel = () => {
    setIsSidePanelClosedByUser(true);
    history.push(redirectRoute.url({ ids }));
  };

  const handleItemSelect = (currentId) => {
    setIsSidePanelClosedByUser(false);
    history.push(
      redirectRoute.url({
        ids,
        currentId,
      })
    );
  };

  const sidepanelRedirectUrl = useMemo(
    () =>
      redirectRoute.url({
        ids,
        currentId: billId,
        orgId,
      }),
    [ids, orgId, billId, redirectRoute]
  );

  const shouldShowPreloader = isLoading || isFetchingRedirectParams;

  if (shouldShowPreloader) {
    return <Loader />;
  }

  return (
    <PayBillBulkPageContainer>
      <StepLayout
        goExit={isSidePanelClosedByUser ? goExit : undefined}
        contentWrapperMode="batchList"
        qboFooter={renderCustomFooter()}
        innerSize={100}
      >
        {isBalanceConfirmModalShown && BalanceConfirmModal}
        <BatchPayment>
          <BatchPaymentHeader>
            <BatchPaymentAmountTitle>
              <MIFormattedText label="batchPayment.amountTitle" />
            </BatchPaymentAmountTitle>
            <HeaderMainTitleRow>
              <BatchPaymentTitle>
                <MIFormattedText
                  label="batchPayment.title"
                  values={{ billsCount: Object.keys(billListModified).length }}
                />
              </BatchPaymentTitle>
              <BatchPaymentAmountCount data-testid="batch-payment-amount-count">
                <MIFormattedCurrency value={totalAmount} />
                <BatchPaymentTotalFee>
                  {!!totalFee && (
                    <>
                      {' '}
                      + <MIFormattedCurrency value={totalFee} />
                      <MIFormattedText label="batchPayment.feesHeader" />
                    </>
                  )}
                </BatchPaymentTotalFee>
              </BatchPaymentAmountCount>
            </HeaderMainTitleRow>
          </BatchPaymentHeader>
          <BatchNotifications
            numOfBills={itemArray.length}
            originalNumOfBills={totalCount}
            isCompleted={showCompleted}
            isCompletedNotificationClosed={!!batchLocalState.isCompletedNotificationClosed}
            onCompletedNotificationClose={onCompletedNotificationClose}
            numOfErrors={numOfErrors}
            isLimitReached={!numOfErrors && isLimitReached}
            filteredDirectPayments={!numOfErrors && filteredDirectPayments}
            isLimitNotificationClosed={!!batchLocalState.isLimitNotificationClosed}
            onLimitNotificationClose={onLimitNotificationClose}
            onHolidaysNotificationClose={onHolidaysNotificationClose}
            isHolidaysWarning={!batchLocalState.isHolidaysNotificationClose && isHolidaysWarning}
            batchData={itemArray}
          />
          <PaymentItemList
            orgId={orgId}
            selectedItemId={currentId}
            data={itemArray}
            onDeductionDateChange={(params, deliveryOptions) => {
              billActions.updateDeliveryOptions({
                billId: params.billId,
                deliveryOptions,
              });
              paymentActions.setPaymentDatesAction(params);
            }}
            onFastToggle={paymentActions.setPaymentDatesAction}
            onItemSelected={handleItemSelect}
            companyInfo={companyInfo}
            isErrorIcon={!!numOfErrors}
          />
          <AmexModal ctaLabel="amexVerification.modal.batchSubmit" isBatch />
        </BatchPayment>
      </StepLayout>

      <SidePanelLayout
        isOpen={isSidePanelOpen}
        bill={selectedBill?.bill}
        paymentAmount={getPaymentAmount(selectedBill?.bill)}
        setPaymentAmount={(amount: number) => {
          selectedBill && setPaymentAmount(+selectedBill.bill.id, amount);
        }}
        fundingSources={userFundingSources}
        deliveryOptions={selectedBill?.deliveryOptions}
        companyInfo={companyInfo}
        continueAction={onSaveAndContinue}
        onClosePanel={closeSidePanel}
        orgId={orgId}
        shouldDisplayAmexVerification={shouldDisplayAmexVerification}
        MCCCodes={MCCCodes}
        redirectUrl={sidepanelRedirectUrl}
        onAddNewPaymentMethod={paymentActions.setPaymentMethod}
        continueButtonLabel={
          !errors || errors?.length < 2
            ? 'batchPayment.sidePanel.buttons.saveAndComplete'
            : 'batchPayment.sidePanel.buttons.saveAndContinue'
        }
        openAmexModal={openAmexModal}
        selectFundingSourceHandler={selectFundingSourceHandler}
        debitFee={debitFee}
        onToggleVirtualCardInfoModal={onToggleVirtualCardInfoModal}
        isFirstWave={!!organizationPreferences.billPayFirstWaveUser}
      />
      <VirtualCardInfoModal
        isVisible={shouldShowVirtualCardInfoModal}
        onSubmit={() => {
          history.push(
            locations.Vendors.deliveryMethods['virtual-card'].create.url({
              id: selectedBill?.bill.vendorId,
              orgId,
            }),
            {
              redirectUrl: sidepanelRedirectUrl,
              exitUrl: sidepanelRedirectUrl,
            }
          );
        }}
        onToggle={onToggleVirtualCardInfoModal}
      />
    </PayBillBulkPageContainer>
  );
};

const billToBatchDataItem = async (
  billItem: billListModifiedType,
  fetchShouldDisplayAmexVerifications
) => ({
  ...billItem,
  errors: await getBillErrors(billItem.bill, fetchShouldDisplayAmexVerifications),
  createdAtDate: moment(billItem.bill.createdAt),
});

const compareByHasErrorsOrByDate = (
  [, a]: [string, BatchDataItem],
  [, b]: [string, BatchDataItem]
) => {
  if (!a.errors && b.errors) return 1;

  if (a.errors && !b.errors) return -1;

  return a.createdAtDate.diff(b.createdAtDate);
};

const getPaymentData = (
  preservedState: Record<string, any>,
  bill: RecordOf<BillType>,
  userFundingSources: any
): PaymentData | null => {
  if (preservedState?.newFundingSource && bill) {
    return {
      paymentType: 'bank-account',
      type: 'bank',
    };
  }

  if (preservedState?.selectedFundingSourceId && bill) {
    const card = find(
      userFundingSources,
      (el) => el.id === preservedState?.selectedFundingSourceId
    );

    if (card?.cardAccount?.cardType === 'credit') {
      return {
        paymentType: 'credit-card',
        type: 'credit',
      };
    }

    if (card?.cardAccount?.cardType === 'debit') {
      return {
        paymentType: 'debit-card',
        type: 'debit',
      };
    }
  }

  if (preservedState?.newDeliveryMethod && bill) {
    return {
      paymentType: 'delivery-method',
      type: preservedState.newDeliveryMethod?.deliveryType,
    };
  }

  return null;
};

const getBillErrors = async (
  bill: RecordOf<BillType>,
  fetchShouldDisplayAmexVerifications
): Promise<PaymentMethodErrors | null> => {
  const payment = bill.payments?.[0];

  if (!payment) return null;

  const isInternational =
    payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL;

  const hasErrorInPurposeOfPaymentType = isInternational
    ? !payment.purpose || !getPurposeByType(payment.purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE)
    : false;
  const hasErrorInPurposeOfPaymentDescription = isInternational
    ? getPurposeByType(payment.purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE) === 'other' &&
      !getPurposeByType(payment.purpose, PURPOSE_OF_PAYMENT_STRUCTURE.DESCRIPTION)
    : false;
  const hasErrorInDeliveryMethod = !payment.deliveryMethodId || !payment.deliveryMethod;
  const hasErrorInScheduledDate = !payment.scheduledDate;
  let hasErrorInFundingSource = !payment.fundingSourceId || !payment.fundingSource;

  if (await fetchShouldDisplayAmexVerifications(bill)) {
    hasErrorInFundingSource = true;
  }

  if (
    !hasErrorInPurposeOfPaymentDescription &&
    !hasErrorInPurposeOfPaymentType &&
    !hasErrorInDeliveryMethod &&
    !hasErrorInScheduledDate &&
    !hasErrorInFundingSource
  ) {
    return null;
  }

  return {
    hasErrorInDeliveryMethod,
    hasErrorInScheduledDate,
    hasErrorInFundingSource,
    hasErrorInPurposeOfPaymentType,
    hasErrorInPurposeOfPaymentDescription,
  };
};

type FinalAnalyticProps = {
  totalPayments: number;
  totalFastEligibleAchPayments: number;
  totalFastEligibleCheckPayments: number;
  totalFastCheckPayments: number;
  totalFastAchPayments: number;
  batchId: number;
};

const getFinalAnalyticProps = (
  billListModified: Record<string, RecordOf<billListModifiedType>>
): FinalAnalyticProps => {
  const bills = Object.values(billListModified);
  const analyticsParams = {
    billIds: Object.keys(billListModified),
    totalPayments: bills.length,
    totalFastEligibleAchPayments: 0,
    totalFastEligibleCheckPayments: 0,
    totalFastCheckPayments: 0,
    totalFastAchPayments: 0,
    partialBillIds: bills.map(({ bill }) => getPartialBillId(bill)),
    batchId: new Date().getTime(),
  };

  for (const { bill, deliveryOptions } of bills) {
    if (deliveryOptions?.length > 1) {
      const deliveryType = bill.payments[0]?.deliveryMethod.deliveryType;
      const deliveryPreference = bill.payments[0]?.deliveryPreference || '';

      if ([CONSTS.DELIVERY_TYPE.ACH].includes(deliveryType)) {
        analyticsParams.totalFastEligibleAchPayments += 1;

        if (FAST_DELIVERY_TYPES.includes(deliveryPreference)) {
          analyticsParams.totalFastAchPayments += 1;
        }
      }

      if ([CONSTS.DELIVERY_TYPE.CHECK].includes(deliveryType)) {
        analyticsParams.totalFastEligibleCheckPayments += 1;

        if (FAST_DELIVERY_TYPES.includes(deliveryPreference)) {
          analyticsParams.totalFastCheckPayments += 1;
        }
      }
    }
  }

  return analyticsParams;
};

const getFastAnalyticsProps = ({ bills, type, getPaymentAmount }) => {
  const filteredBills = bills.filter((bill) => {
    const deliveryType = bill.payments[0]?.deliveryMethod.deliveryType;

    return [type].includes(deliveryType);
  });

  const batchBillIds = filteredBills.map((bill) => bill.id);
  const batchBillCount = batchBillIds.length;
  const batchBillTotalAmount = filteredBills.reduce(
    (total, bill) => total + getPaymentAmount(bill),
    0
  );
  const partialBillIds = filteredBills.map(getPartialBillId);

  return {
    batchBillIds,
    batchBillCount,
    batchBillTotalAmount,
    partialBillIds,
  };
};

const PayBillBulkPageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
`;

const CustomFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 5rem;
  background: rgba(57, 58, 61, 1);
`;

const BatchPayment = styled.div`
  width: 100%;
  padding-top: 4rem;
`;

const BatchPaymentHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.6rem;
`;

const BatchPaymentTitle = styled.div`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  line-height: 3.2rem;
`;

const HeaderMainTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BatchPaymentAmountTitle = styled.div`
  font-size: 1.2rem;
  margin-bottom: 0.4rem;
  text-transform: uppercase;
  color: rgba(107, 108, 114, 1);
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.text.weight.normal};
  line-height: 1.8rem;
  align-self: flex-end;
`;

const BatchPaymentAmountCount = styled.div`
  font-size: 3.6rem;
  color: rgba(57, 58, 61, 1);
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.text.weight.bold};
  line-height: 4.4rem;
`;

const BatchPaymentTotalFee = styled.div`
  font-size: ${({ theme }) => theme.text.size.sectionTitleM};
  color: ${({ theme }) => theme.text.color.subtitle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: ${({ theme }) => theme.text.size.regular};
  font-weight: normal;
  text-align: right;
  min-height: ${({ theme }) => theme.text.size.regular};
`;

export default PayBillBulkPage;
