import styled from 'styled-components';

type Props = {
  email: string;
};

export const EmailLink = ({ email }: Props) => (
  <EmailLinkContainer href={`mailto:${email}`} target="_blank">
    {email}
  </EmailLinkContainer>
);

const EmailLinkContainer = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;
