import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Flex } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';
import { ReactComponent as QBLogo } from 'src/app/images/qbo/vc-details/qb-semitransparent.svg';
import { ReactComponent as JPMorgan } from 'src/app/images/qbo/vc-details/jp-morgan.svg';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';

interface CardPreviewProps {
  amount: number;
  cardDetails: {
    number?: string;
    cvc?: string;
    exp?: string;
  };
}

const CardPreview = ({ amount, cardDetails }: CardPreviewProps) => {
  const intl = useIntl();

  const formattedAmount = intl.formatNumber(amount, {
    style: 'currency',
    currency: 'USD',
  });
  const [wholeAmountPart, decimalAmountPart] = formattedAmount.split('.');

  // convert raw card number string as '1111222233334444'
  // to readable format as '1111 2222 3333 4444 '
  const formattedCardNumber = cardDetails.number?.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');

  return (
    <Box __css={containerStyle}>
      <Box __css={logoContainerStyle}>
        <QBLogo width="10.8rem" height="10rem" />
      </Box>

      <Flex justify="space-between" align="center">
        <Box as="span" __css={amountStyle} className={FULL_STORY_MASK_RULE_CLASS}>
          {wholeAmountPart}
          <Box as="span" __css={decimalStyle}>
            {decimalAmountPart}
          </Box>
        </Box>
        <Mastercard width="3.6rem" height="3.4rem" />
      </Flex>

      <Flex __css={footerStyle}>
        <Box mb="0.5rem">
          <MIFormattedText
            privateData
            label="common.rawValue"
            values={{ value: formattedCardNumber }}
          />
        </Box>
        <Flex justify="space-between">
          <Flex align="center">
            <Box __css={smallLabelStyle}>
              <MIFormattedText label="vendors.virtualCardDetails.valid.previewCard.validThru" />
            </Box>
            <MIFormattedText
              privateData
              label="common.rawValue"
              values={{ value: cardDetails.exp }}
            />
            <Box __css={smallLabelStyle} ml="1.2rem">
              <MIFormattedText label="vendors.virtualCardDetails.valid.previewCard.cvc" />
            </Box>
            <MIFormattedText
              privateData
              label="common.rawValue"
              values={{ value: cardDetails.cvc }}
            />
          </Flex>
          <JPMorgan width="7.6rem" height="1.6rem" />
        </Flex>
      </Flex>
    </Box>
  );
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  w: '24.6rem',
  minH: '15rem',
  alignSelf: 'center',
  p: '1rem 1.6rem 1.6rem',
  background: 'linear-gradient(230.87deg, #00C1BF 0%, #53B700 100%)',
  boxShadow: '0px 4px 20px rgba(33, 33, 36, 0.1)',
  boxSizing: 'border-box',
  borderRadius: 'lg',
};

const logoContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const amountStyle = {
  flexWrap: 'wrap',
  wordBreak: 'break-word',
  fontWeight: '600',
  color: 'gray.50',
  lineHeight: '2.4rem',
  fontSize: '2.4rem',
  mr: '0.2rem',
};

const decimalStyle = {
  textStyle: 'body4',
  verticalAlign: 'top',
};

const footerStyle = {
  direction: 'column',
  color: 'white',
  fontWeight: '600',
  textStyle: 'body4',
};

const smallLabelStyle = {
  fontWeight: '400',
  fontSize: '0.6rem',
  lineHeight: '0.6rem',
  mr: '0.4rem',
};

export { CardPreview };
