import React, { memo } from 'react';
import { Box, Text } from '@melio/billpay-design-system';

import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import { formatDate } from 'src/app/pages/bill/pay/components/RecurringPaymentView/utils';
import { BillType } from 'src/app/utils/types';
import { styles } from './styles';

type Props = {
  nextQboBillToPayInfo: BillType;
};

const Container = ({ children }) => (
  <Box {...styles.container} borderTopStyle="solid" __css={{ mb: ['4rem', 0], mt: [0, '3rem'] }}>
    {children}
  </Box>
);
const Table = ({ children }) => (
  <Box {...styles.table} __css={{ flexDirection: ['column', 'row'] }} borderStyle="solid">
    {children}
  </Box>
);
const TextBlockSection = ({ children }) => <Box {...styles.textSection}>{children}</Box>;
const TextBlockContainer = ({ children }) => <Box {...styles.textBlock}>{children}</Box>;
const LeftSideTextWrapper = ({ children }) => (
  <Box flex="1 1 50%" textAlign="left" m="1">
    {children}
  </Box>
);

const RightSideTextWrapper = ({ children }) => (
  <Box flex="1 1 50%" __css={{ textAlign: ['right', 'left'] }} m="1">
    {children}
  </Box>
);

export const NextQboBillToPayInfo = memo(({ nextQboBillToPayInfo }: Props) => {
  if (!nextQboBillToPayInfo) {
    return null;
  }

  return (
    <Container>
      <Box textAlign="left">
        <Text mt={6} mb={1} fontWeight={600} textStyle="body2">
          <MIFormattedText label="bills.pay.payBillSuccess.titles.nextQboBillToPay" />
        </Text>
        <Text
          mt={0}
          mb={0}
          textStyle="body2"
          data-testid="wizard-next-bill-to-pay-subtitle"
          textAlign="left"
        >
          <MIFormattedText label="bills.pay.payBillSuccess.subtitles.nextQboBillToPay" />
        </Text>
      </Box>
      <Table>
        <TextBlockSection>
          <TextBlockContainer>
            <LeftSideTextWrapper>
              <Text
                {...{ ...styles.tableText, ...styles.tableTitle }}
                maxW={160}
                textTransform="uppercase"
              >
                <MIFormattedText label="paymentDashboard.columns.vendor.title" />
              </Text>
            </LeftSideTextWrapper>
            <RightSideTextWrapper>
              <Text
                {...{ ...styles.tableText, ...styles.tableTitle }}
                maxW={160}
                textTransform="uppercase"
                mb={1}
              >
                <MIFormattedText label="paymentDashboard.columns.invoiceNumber.title" />
              </Text>
            </RightSideTextWrapper>
          </TextBlockContainer>
          <TextBlockContainer>
            <LeftSideTextWrapper>
              <Text {...styles.tableText} maxW={180} wordBreak="break-word">
                {nextQboBillToPayInfo.vendor.companyName}
              </Text>
            </LeftSideTextWrapper>
            <RightSideTextWrapper>
              <Text {...styles.tableText} maxW={160}>
                {nextQboBillToPayInfo.invoiceNumber || '-'}
              </Text>
            </RightSideTextWrapper>
          </TextBlockContainer>
        </TextBlockSection>
        <TextBlockSection>
          <TextBlockContainer>
            <LeftSideTextWrapper>
              <Text {...{ ...styles.tableText, ...styles.tableTitle }} textTransform="uppercase">
                <MIFormattedText label="paymentDashboard.columns.dueDate.title" />
              </Text>
            </LeftSideTextWrapper>
            <RightSideTextWrapper>
              <Text
                {...{ ...styles.tableText, ...styles.tableTitle }}
                textAlign="right"
                textTransform="uppercase"
              >
                <MIFormattedText label="paymentDashboard.columns.amount.title" />
              </Text>
            </RightSideTextWrapper>
          </TextBlockContainer>
          <TextBlockContainer>
            <LeftSideTextWrapper>
              <Text {...styles.tableText}>{formatDate(nextQboBillToPayInfo.dueDate)}</Text>
            </LeftSideTextWrapper>
            <RightSideTextWrapper>
              <Text {...styles.tableText} textAlign="right">
                <MIFormattedCurrency value={nextQboBillToPayInfo.totalAmount} />
              </Text>
            </RightSideTextWrapper>
          </TextBlockContainer>
        </TextBlockSection>
      </Table>
    </Container>
  );
});
