type IconStyleParams = {
  isActive: boolean;
};

export const iconStyle = ({ isActive }: IconStyleParams) => ({
  pl: 2,
  h: 4,
  color: isActive ? 'black' : 'gray.300',
  svg: {
    display: 'inline',
    height: '5px',
  },
});
