import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BillFrequencyEnum } from 'src/app/version-2/model/enums';
import { BillType } from 'src/app/utils/types';
import recurringBillsApi from 'src/app/services/api/recurringBills';
import { getOrgId } from 'src/app/redux/user/selectors';

import { PaymentTooltip } from './PaymentTooltip';
import { FieldValue } from './shared';

/**
 * Recurring occurrences and frequency fields
 */

type Props = {
  bill: BillType;
  isView?: boolean;
};

const RecurringPaymentView = ({ bill, isView }: Props) => {
  const [bills, setBills] = useState<BillType[]>([]);
  const orgId = useSelector(getOrgId);
  const { recurringBill = {} } = bill;

  const frequency = recurringBill?.frequency;
  const dueDate = recurringBill?.dueDate;
  const occurrences = recurringBill?.occurrences;

  useEffect(() => {
    // get recurring bills list if view payment
    if (isView) getRecurringBills();
  }, []);

  const getRecurringBills = async () => {
    try {
      const { bills } = await recurringBillsApi.getBillsByRecurringId(orgId, bill.recurringBillId);

      if (bills?.length) setBills(bills);
    } catch (e) {
      // error
    }
  };

  let specificDay = '';
  let label = '';

  if (frequency === BillFrequencyEnum.WEEKLY) {
    specificDay = dueDate ? moment(dueDate).format('dddd').toString() : '';
    label = 'bills.pay.confirm.recurringWeeklyHint';
  } else {
    specificDay = dueDate ? moment(dueDate).format('Do').toString() : '';
    label = 'bills.pay.confirm.recurringMonthlyHint';
  }

  const occurrencesLabel = `bills.pay.confirm.${
    isView ? 'occurrencesViewField' : 'occurrencesField'
  }`;

  return (
    <BoxRow>
      <BoxColumn>
        <FieldName>
          <MIFormattedText label="bills.pay.confirm.frequencyField" />
        </FieldName>
        <FieldValue>
          <MIFormattedText label={label} values={{ specificDay, occurrences }} />
        </FieldValue>
      </BoxColumn>
      <BoxColumn>
        <FieldName>
          <MIFormattedText label={occurrencesLabel} />
        </FieldName>

        {/* view payment - toolip */}
        {isView && <PaymentTooltip bill={bill} bills={bills} />}

        {/* confirm */}
        {!isView && <FieldValue>{occurrences}</FieldValue>}
      </BoxColumn>
    </BoxRow>
  );
};

export default RecurringPaymentView;

const BoxRow = styled.div`
  display: flex;
  column-gap: 1.6rem;
  margin-top: 1rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxColumn}
`;

const FieldName = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}

  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldName}
`;
