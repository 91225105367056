import React from 'react';
import styled from 'styled-components';

import { MITableHeaderCell, MITableRow } from 'src/app/components/layout/TableElements';
import { MIFormattedText } from 'src/app/utils/formatting';

const InternalBillsTableHeaderRow = () => (
  <MITableRow>
    <MITableHeaderCell>
      <MIFormattedText label="settings.billing.paymentsTable.date" />
    </MITableHeaderCell>
    <AmountTableHeaderCell>
      <MIFormattedText label="settings.billing.paymentsTable.amount" />
    </AmountTableHeaderCell>
    <PdfIconTableHeaderCell>
      <MIFormattedText label="settings.billing.paymentsTable.receipt" />
    </PdfIconTableHeaderCell>
  </MITableRow>
);

const AmountTableHeaderCell = styled(MITableHeaderCell)`
  text-align: left;
`;

const PdfIconTableHeaderCell = styled(MITableHeaderCell)`
  text-align: center;
  padding-right: 2rem;
`;

export default InternalBillsTableHeaderRow;
