import React from 'react';
import { Box } from '@melio/billpay-design-system';

import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { useBreak } from 'src/app/hoc';
import { MIFormattedText } from 'src/app/utils/formatting';

import { PaymentType } from 'src/app/utils/types';

import { FORM_TYPES, TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH } from '../consts';
import { EditPaymentBankDetails } from './EditPaymentBankDetails';
import { EditPaymentCheckAddress } from './EditPaymentCheckAddress';
import { EditPaymentEmail } from './EditPaymentEmail';

type Props = {
  payment: PaymentType;
  formType: FORM_TYPES;
  token: string;
  isLoading: boolean;
  onClose: () => void;
};

const VendorPaymentEditModal = ({ payment, formType, token, isLoading, onClose }: Props) => {
  const { isMobile } = useBreak();

  const formsByType = {
    [FORM_TYPES.EDIT_BANK_DETAILS]: {
      form: (
        <EditPaymentBankDetails
          payment={payment}
          isMobile={isMobile}
          token={token}
          isLoading={isLoading}
          onClose={onClose}
        />
      ),
      descriptionValues: { companyName: payment.organization.companyName },
    },

    [FORM_TYPES.EDIT_CHECK_ADDRESS]: {
      form: (
        <EditPaymentCheckAddress
          payment={payment}
          isMobile={isMobile}
          token={token}
          isLoading={isLoading}
          onClose={onClose}
        />
      ),
      descriptionValues: { companyName: payment.organization.companyName },
    },
    [FORM_TYPES.EDIT_EMAIL]: {
      form: (
        <EditPaymentEmail
          payment={payment}
          isMobile={isMobile}
          token={token}
          isLoading={isLoading}
          onClose={onClose}
        />
      ),
      descriptionValues: {
        payor: payment.organization.companyName,
      },
    },
  };

  return (
    <ModalMessage
      titleComponent={
        <Box
          textStyle={isMobile ? 'h6' : 'h5'}
          textColor="black"
          marginBottom={1}
          fontWeight="ds.semi"
        >
          <MIFormattedText
            label={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${formType}.title`}
          />
        </Box>
      }
      descriptionComponent={
        <Box textStyle={isMobile ? 'body3' : 'body2'} textAlign="left" textColor="black">
          <MIFormattedText
            label={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${formType}.description`}
            values={formsByType[formType].descriptionValues}
          />
        </Box>
      }
      contentComponent={formsByType[formType].form}
      withTitleContainer={false}
      withContentContainer={false}
      onCloseClick={isMobile || isLoading ? undefined : onClose}
      unsetModalOverflow
    />
  );
};

export { VendorPaymentEditModal };
