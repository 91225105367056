import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { fullOrgSyncStore } from 'src/app/modules/fullOrgSync/full-org-sync-store';
import { FullOrgSyncStatusEnum } from 'src/app/version-2/model/enums';
import { DashboardListParams } from '../types';
import { trackBillListLoaded, trackDashboardListLoaded } from '../utils';

type Params = {
  silent?: boolean; // passing `true` here will prevent `loading` param of qbDashboardListItemsStore from setting to `true` during the API call
};

type Response = {
  loadDashboardList: (listParams: DashboardListParams) => Promise<void>;
};

export const useLoadDashboardList = ({ silent }: Params = { silent: false }): Response => {
  const dashboardListActions = useStoreActions(qbDashboardListItemsStore);
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();

  const fullOrgSyncStatus = useSelector(fullOrgSyncStore.selectors.status);

  const loadDashboardList = useCallback(
    async (listParams: DashboardListParams) => {
      dashboardListActions.qbDashboardSelection.setSelection([]);
      dashboardListActions.partialPaymentsAmounts.resetAmounts();

      const listResult = await dashboardListActions.list({
        ...listParams,
        isPartialPaymentsEnabled,
        silent,
      });

      const analyticsParams = {
        ...listResult,
        extraParams: { withSyncLoader: fullOrgSyncStatus === FullOrgSyncStatusEnum.RUNNING },
      };

      if (isPartialPaymentsEnabled) {
        trackDashboardListLoaded(analyticsParams);
      } else {
        trackBillListLoaded(analyticsParams);
      }
    },
    [dashboardListActions, isPartialPaymentsEnabled, fullOrgSyncStatus]
  );

  return { loadDashboardList };
};
