import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { RecordOf } from 'immutable';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { withNavigator } from 'src/app/hoc';
import { getPartialBillId } from 'src/app/utils/bills';
import {
  CONSTS,
  PAYMENT_STATUS,
  RECEIVER_REFUSED_CREDIT_ENTRY,
  FAILED_TO_DELIVER_ERR,
  BANK_ACCOUNT_EXIST,
} from 'src/app/utils/consts';
import {
  FAILED_TO_DELIVER_SAME_BANK_DETAILS,
  FAILED_TO_DELIVER_SAME_BANK_DETAILS_TITLE,
} from 'src/app/version-2/model/constants';
import { FieldType, BankType, DeliveryMethodType } from 'src/app/utils/types';
import { getBill, getPayment } from 'src/app/redux/payBillWizard/selectors';
import { getOrgId, getOwnedVendorId, getFundingSources } from 'src/app/redux/user/selectors';
import { BankAccount } from 'src/app/version-2/model/dtos';
import EditBankDeliveryMethodPage from 'src/app/pages/vendor/delivery-methods/components/EditBankDeliveryMethodPage';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import { BankRecord } from 'src/app/pages/vendor/records';
import {
  selectNewDeliveryMethodAction,
  selectPaymentDatesAction,
} from 'src/app/redux/payBillWizard/actions';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import {
  getBankDetailsEditDeliveryPage,
  validateSameBankDetails,
} from 'src/app/version-2/utils/deliveryMethod.utils';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import { checkBankAccount } from 'src/app/pages/vendor/delivery-methods/utils';
import { AreaLoader } from '@melio/billpay-design-system';
import { getPaymentById } from 'src/app/utils/payments';
import deliveryApi from 'src/app/services/api/delivery';
import { withPayBillData, PayBillProps } from './hoc/withPayBillData';

const eventPage = 'edit-ach-delivery-method';

function EditAchDeliveryMethodPage({ id, navigate }: PayBillProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const {
    id: paymentId,
    deliveryMethodId,
    vendorId,
    fundingSourceId,
    amount,
    scheduledDate,
    deliveryPreference,
  } = payment;
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const userFundingSources = useSelector(getFundingSources);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const bankAccount = (
    useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId)) as DeliveryMethodType
  )?.bankAccount;
  const [bank, setBank] = useState<RecordOf<BankType>>(bankAccount || (BankRecord() as any));
  const [isBankChanged, setIsBankChanged] = useState(false);
  const vendorName = bill.vendor.companyName;
  const titleValues = { vendor: vendorName };
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorCode, setErrorCode] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const fetchDeliveryMethod = useCallback(async () => {
    if (deliveryMethodId) {
      await deliveryMethodActions.fetch({
        orgId,
        vendorId,
        id: deliveryMethodId,
      });
    }
  }, [deliveryMethodId, orgId, vendorId, deliveryMethodActions]);

  const fetchExpeditedAchDeliveryDates = useCallback(async () => {
    const { deliveryDate, maxDeliveryDate } = await deliveryApi.getExpeditedAchDeliveryDates(
      orgId,
      Number.parseInt(deliveryMethodId, 10),
      fundingSourceId,
      amount as number
    );

    dispatch(
      selectPaymentDatesAction(
        scheduledDate,
        deliveryDate,
        maxDeliveryDate,
        deliveryPreference as string
      )
    );
  }, [
    deliveryMethodId,
    orgId,
    amount,
    deliveryPreference,
    dispatch,
    fundingSourceId,
    scheduledDate,
  ]);

  useEffect(() => {
    if (payment?.id) {
      const { status, metadata } = payment;
      const isReceiverRefusedCredit =
        status === PAYMENT_STATUS.FAILED &&
        metadata?.failureMessage === RECEIVER_REFUSED_CREDIT_ENTRY;

      if (isReceiverRefusedCredit) {
        fetchExpeditedAchDeliveryDates();
        navigate(locations.Bills.pay.edit.confirm.url({ id: bill.id, paymentId }));
      }
    }
  }, [payment, bill]);

  useEffect(() => {
    if (bankAccount) {
      if (isBankChanged) setBank(BankRecord(bankAccount));
      else {
        const inputValues = getBankDetailsEditDeliveryPage({
          bank: bankAccount,
          deliverFailureData: payment?.deliverFailureData,
        });

        setBank(BankRecord(inputValues));
      }
    }
  }, [bankAccount]);

  useEffect(() => {
    if (deliveryMethodId) {
      fetchDeliveryMethod();
    }
  }, [deliveryMethodId, fetchDeliveryMethod]);

  const onChange = ({ id, value }: FieldType) => {
    setBank(bank.merge({ [id]: value }));

    if (!isBankChanged) {
      setIsBankChanged(true);
    }

    if (errorCode) setErrorCode('');

    if (errorTitle) setErrorTitle('');
  };

  const getValidationsError = () => {
    let validationErrors = getValidationErrors('deliveryMethodAch', bank);

    if (isValidationOk(validationErrors) && Number(vendorId) !== Number(ownedVendorId)) {
      validationErrors = checkBankAccount(bank, userFundingSources);
    }

    return validationErrors;
  };

  const updateDeliveryMethod = async (deliveryMethod) => {
    const { payload } = await deliveryMethodActions.update({
      orgId,
      vendorId,
      id: deliveryMethodId,
      deliveryMethod,
    });

    await dispatch(selectNewDeliveryMethodAction(payload));

    analytics.track(eventPage, 'edit-delivery-method-success', {
      organizationId: orgId,
      billId: bill.id,
      paymentId: parseInt(paymentId),
      fundingSourceId: deliveryMethodId,
      type: deliveryMethod.deliveryType,
      partialBillId: getPartialBillId(bill),
    });
  };

  const onDone = async () => {
    setIsLoading(true);
    const originPayment = getPaymentById(bill.payments, paymentId);
    const validationErrors = getValidationsError();
    const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);
    const isSameBankDetails = validateSameBankDetails({
      bank,
      prevBank: bankAccount as BankAccount,
    });

    setValidationErrors(validationErrors);

    const newDeliveryMethod = {
      deliveryType: CONSTS.DELIVERY_TYPE.ACH,
      bankAccount: bank,
    };

    if (isRetryFailedToDeliver && isSameBankDetails) {
      // apply only for retry failed to deliver ach
      setErrorCode(FAILED_TO_DELIVER_SAME_BANK_DETAILS);
      setErrorTitle(FAILED_TO_DELIVER_SAME_BANK_DETAILS_TITLE);
      setIsLoading(false);
    } else if (isValidationOk(validationErrors)) {
      try {
        await fetchExpeditedAchDeliveryDates();

        if (originPayment?.deliveryMethodId === deliveryMethodId || isBankChanged) {
          await updateDeliveryMethod(newDeliveryMethod);
        }

        historyPush({
          path: locations.Bills.pay.edit.confirm.url(),
          params: { id, paymentId },
        });
      } catch (err: any) {
        const errorCode = err?.error?.code || err?.code;

        if (errorCode === BANK_ACCOUNT_EXIST) {
          setErrorCode(FAILED_TO_DELIVER_ERR);
        } else {
          setErrorCode(errorCode);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      analytics.track(eventPage, 'edit-delivery-method-validation-error', {
        ...validationErrors,
        partialBillId: getPartialBillId(bill),
      });
    }
  };

  const goExit = () => {
    history.goBack();
  };

  if (!deliveryMethodId || !payment?.id) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <EditBankDeliveryMethodPage
      vendorName={vendorName}
      bank={bank}
      titleValues={titleValues}
      onDone={onDone}
      onChange={onChange}
      onPrev={goExit}
      goExit={goExit}
      isLoading={isLoading}
      validationErrors={validationErrors}
      errorCode={errorCode}
      errorTitle={errorTitle}
      deliveryMethodId={deliveryMethodId}
      deliverFailureData={payment?.deliverFailureData}
    />
  );
}

export default compose(withNavigator(), withPayBillData())(EditAchDeliveryMethodPage);
