import { Record } from 'immutable';
import get from 'lodash/get';
import { CURRENCY_USD } from 'src/app/version-2/model/constants';
import { BillFrequencyEnum } from 'src/app/version-2/model/enums';
import type { BillType, OptionalDeliveryMethodsType } from '../../utils/types';
import { BILL_STATUS } from '../../utils/consts';
import { DeliveryMethodRecord, VendorRecord } from '../vendor/records-constants';

export const BillRecord: Record.Factory<BillType> = Record(
  {
    id: '',
    balance: undefined,
    totalAmount: null,
    currency: CURRENCY_USD,
    organization: {
      id: '',
      companyName: '',
    },
    createdById: undefined,
    metadata: {
      isDeletable: true,
    },
    origin: '',
    originId: '',
    status: BILL_STATUS.UNPAID,
    vendorId: NaN,
    lineItems: [],
    payments: [],
    files: [],
    dueDate: '',
    originMetaUrl: '',
    lastUpdater: '',
    invoiceDate: new Date(),
    terms: NaN,
    creator: '',
    invoiceNumber: '',
    note: '',
    intuitAccountId: '',
    internalBill: false,
    goodsReceived: null,
    vendor: {
      id: '',
      companyName: '',
      deliveryMethods: undefined,
      contactEmail: '',
    },
    recurringBill: {
      id: '',
      occurrences: NaN,
      frequency: BillFrequencyEnum.MONTHLY,
      dueDate: '',
    },
    occurrences: NaN,
    frequency: BillFrequencyEnum.MONTHLY,
    recurringBillId: '',
    recurringBillIndex: NaN,
    partner: undefined,
    externallyPaid: NaN,
    qboSyncVersion: null,
    invoiceFileName: '',
    getDeliveryMethodByType(type: OptionalDeliveryMethodsType) {
      return getDeliveryMethodByType(this, type);
    },
    getDeliveryMethodById(id: string) {
      return getDeliveryMethodById(this, id);
    },
    getFirstFileId() {
      return get(this.files, '[0].id', null);
    },

    isVendorRequest() {
      return this.vendor && !!this.vendor.ownedById;
    },
  } as any,
  'BillRecord'
);

export const getDeliveryMethodByType = (bill: BillType, type: OptionalDeliveryMethodsType) => {
  const vendorRecord = VendorRecord(bill.vendor as any);

  return vendorRecord.getDeliveryMethodByType(type);
};

export const getDeliveryMethodById = (bill: BillType, id: string) => {
  if (bill.vendor && bill.vendor.deliveryMethods) {
    return DeliveryMethodRecord(bill.vendor.deliveryMethods.find((dm) => dm.id === id));
  }

  return null;
};
