import React from 'react';
import { RecordOf } from 'immutable';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BillType, DeliveryMethodType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import {
  MainPaymentActivityContainer,
  BlockTitleContainer,
} from 'src/app/components/layout/PaymentActivityElements';
import { FundingSource } from 'src/app/version-2/model/dtos';
import ViewBillPaymentActivityMemoInfo from './ViewBillPaymentActivityMemoInfo';
import ViewBillPaymentActivityFundingSourceInfo from './ViewBillPaymentActivityFundingSourceInfo';
import { ViewBillPaymentActivityDeliveryMethodInfo } from './ViewBillPaymentActivityDeliveryMethodInfo';
import ViewBillPaymentActivityDeductionDateInfo from './ViewBillPaymentActivityDeductionDateInfo';

type Props = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  fundingSource: FundingSource;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  qbCashState?: QBCashStateType;
};

export const VirtualCardExpiredPaymentActivity = ({
  bill,
  payment,
  fundingSource,
  deliveryMethod,
  qbCashState,
}: Props) => {
  const payFromLabel = 'viewBillPaymentActivity.payFromTitle';
  const receiveLabel = 'viewBillPaymentActivity.receiveTitle';

  return (
    <>
      <BlockTitleContainer>
        <MIFormattedText label={payFromLabel} />
      </BlockTitleContainer>
      <MainPaymentActivityContainer>
        <ViewBillPaymentActivityFundingSourceInfo
          qbCashState={qbCashState}
          fundingSource={fundingSource}
          bill={bill}
        />
        <ViewBillPaymentActivityDeductionDateInfo
          bill={bill}
          payment={payment}
          deliveryMethod={deliveryMethod}
        />
      </MainPaymentActivityContainer>

      <BlockTitleContainer>
        <MIFormattedText label={receiveLabel} />
      </BlockTitleContainer>
      <MainPaymentActivityContainer>
        <ViewBillPaymentActivityDeliveryMethodInfo
          deliveryMethod={deliveryMethod}
          bill={bill}
          payment={payment}
        />
      </MainPaymentActivityContainer>
      <ViewBillPaymentActivityMemoInfo payment={payment} />
    </>
  );
};
