import React from 'react';
import keys from 'lodash/keys';
import { MIFormattedText } from 'src/app/utils/formatting';
import payEmptyStateIcon from 'src/app/images/qbo/pay-empty-state-icon.svg';
import { BILL_STATUS } from 'src/app/utils/consts';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { BillStatusType } from 'src/app/utils/types';
import { Filters } from 'src/app/hoc/withListContainer';

export const getBillsListEmptyLabel = (
  selectedTabBillStatus: BillStatusType,
  isSearchPerformed: boolean
) => {
  if (isSearchPerformed) {
    return 'bills.emptyState.search';
  }

  switch (selectedTabBillStatus) {
    case BILL_STATUS.SCHEDULED:
      return 'bills.emptyState.textScheduled';
    case BILL_STATUS.PAID:
      return 'bills.emptyState.textPaid';
    default:
      return 'bills.emptyState.btnHint';
  }
};

export const showZeroState = (bills, selectedTabBillStatus: BillStatusType, filters: Filters) => {
  // const { bills, isClosedBillsZeroState } = this.props;
  // After introducing NewEmptyList widget we're no sure that
  // it will be our final decisition - so we left zeroState related code
  // https://trello.com/c/UtfAiBKS/2105-new-zero-state-component-for-pay-inbox-and-request-paid
  const isClosedBillsZeroState = false;

  const isSearchPerformed = !!filters.search;
  const isHideActionButton = selectedTabBillStatus !== BILL_STATUS.UNPAID || isSearchPerformed;
  const showBillsZeroState =
    !isClosedBillsZeroState &&
    !isSearchPerformed &&
    selectedTabBillStatus === BILL_STATUS.UNPAID &&
    bills.size === 0;

  return { showBillsZeroState, isHideActionButton, isSearchPerformed };
};

export const QBOBillListEmptyState = (props) => {
  const { bills, goCreateBill, billsCount, selectedTabBillStatus, filters, testId } = props;
  const { isSearchPerformed } = showZeroState(bills, selectedTabBillStatus, filters);
  const emptyLabel = getBillsListEmptyLabel(selectedTabBillStatus, isSearchPerformed);
  const showNewEmptyList = !isSearchPerformed && selectedTabBillStatus === BILL_STATUS.UNPAID;
  const ctaLabel = billsCount
    ? 'bills.newEmptyState.buttonText'
    : 'bills.newEmptyState.buttonTextFirstBill';

  if (showNewEmptyList) {
    return (
      <EmptyListContainer data-testid={testId && `${testId}-container`}>
        <Title>
          <MIFormattedText label="bills.newEmptyState.title" />
        </Title>
        <Image src={payEmptyStateIcon} />
        <SubTitle>
          <MIFormattedText label="bills.newEmptyState.subtitle" />
        </SubTitle>
        {keys(Array(3))
          .map((k) => Number(k) + 1)
          .map((itemId) => (
            <ListItem key={itemId}>
              <ListItemId>{itemId}</ListItemId>
              <ListItemText>
                <MIFormattedText label={`bills.newEmptyState.item${itemId}`} />
              </ListItemText>
            </ListItem>
          ))}
        <ButtonContainer onClick={goCreateBill} data-testid={testId || `button-${ctaLabel}`}>
          <ButtonText>
            <MIFormattedText label={ctaLabel} />
          </ButtonText>
          <ArrowIcon className="icon-next-icon-2" />
        </ButtonContainer>
      </EmptyListContainer>
    );
  }

  return (
    <EmptyStateContainer>
      <Text>
        <MIFormattedText label={emptyLabel} />
      </Text>
      <Text>
        <MIFormattedText label="bills.emptyState.btnText" />
      </Text>
    </EmptyStateContainer>
  );
};

const ListItem = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  display: flex;
  line-height: 2rem;
  text-align: left;
  margin-bottom: 2rem;
`;

const ListItemId = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  font-size: ${(props) => props.theme.text.size.regular};
  align-self: center;
  margin-right: 1.6rem;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  text-align: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.border.lightGrey};
`;
const ListItemText = styled.span`
  ${(props) => props.theme.text.fontType.regular};
`;

const EmptyStateContainer = styled.div`
  height: calc(100vh - 50rem);
  display: flex;
  flex-direction: column;
  align-items: flex-left;
  justify-content: center;

  @media ${devices.mobile} {
    height: calc(100vh - 25rem);
  }
`;

const Text = styled.div`
  ${(props) => props.theme.text.fontType.regular}
  font-weight: ${(props) => props.theme.text.weight.normal};
  color: rgba(141, 144, 150, 1);
  text-align: center;
  text-align: center;
  padding-bottom: 1.5rem;
`;

const EmptyListContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  align-items: flex-left;
  justify-content: center;
  margin: 1.6rem;
  padding: 1.6rem;
  border: 1px solid rgba(212, 215, 220, 1);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04), 0 8px 12px 0 rgba(0, 0, 0, 0.06);
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  color: ${(props) => props.theme.text.color.main};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-align: left;
  letter-spacing: 0;
  margin-bottom: 0.8rem;
`;

const Title = styled.p`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.bold};
  font-size: ${(props) => props.theme.text.size.sectionTitleM};
  text-align: left;
  text-transform: uppercase;
`;

const Image = styled.img`
  width: 100%;
`;

const ButtonContainer = styled.div`
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  padding-top: 1.8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonText = styled.div`
  color: ${(props) => props.theme.text.color.green};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
`;

const ArrowIcon = styled.i`
  color: ${(props) => props.theme.text.color.green};
  font-size: 2.4rem;
`;
