export enum RefundReasonEnum {
  IncorrectVendorDetails = 'incorrectVendorDetails',
  IncorrectPaymentAmount = 'incorrectPaymentAmount',
  PaidViaAnotherProvider = 'paidViaAnotherProvider',
  SwitchingPaymentMethod = 'switchingPaymentMethod',
  GoodsServicesIssues = 'goodsServicesIssues',
  BadCustomerExperience = 'badCustomerExperience',
  Other = 'other',
}

export enum RefundRequestSourceEnum {
  button = 'button',
  link = 'link',
}
