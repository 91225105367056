import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { clearPayBillWizardFlow, setPayBillWizardFlow } from 'src/app/redux/payBillWizard/actions';
import { BILLING_FEATURE_WIZARD_FLOW } from 'src/app/pages/settings/components/fee/consts';
import { AddAnotherRadioMethodOption } from './MethodElements';
import CreditPaymentMethodTooltip from '../types/creditPaymentMethodTooltip/CreditPaymentMethodTooltip';

export type MethodListItemType = {
  name?: string;
  componentType: any;
  methods: any[];
  onVerifyClicked?: (id: number) => void;
  isSettings?: boolean;
  disabled?: boolean;
  disabledText?: string;
  companyName?: string;
  isVendorAbsorbedFee?: boolean;
  description?: string;
  methodErrors?: any;
  isSelectedDeliveryMethodVirtualCard?: boolean;
  onBenefitsClicked?: (card?: FundingSource) => void;
  debitFee?: React.ReactNode | string;
  isBillingFeeFlow?: undefined | boolean;
  showCCTooltip?: boolean;
  hideShowMore?: boolean;
  isInternationalVendor?: boolean;
  isInternationalEntryPoint?: boolean;
};

type Props = {
  value: any;
  list: MethodListItemType[];
  onAddMethod: (type: string) => void;
  onChange?: (value: any) => void;
  isBillingFeeFlow?: undefined | boolean;
  showAddAnotherOption?: boolean;
  isDisabled?: (method: any) => boolean;
  optionComponent: any;
  modifyFundingSourceActions?: (method: any) => Record<string, any>;
};

function MethodsList({
  value,
  list,
  onAddMethod,
  onChange,
  showAddAnotherOption,
  isDisabled = () => false,
  modifyFundingSourceActions,
  optionComponent,
  isBillingFeeFlow,
}: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isBillingFeeFlow) {
      dispatch(setPayBillWizardFlow(BILLING_FEATURE_WIZARD_FLOW));
    } else {
      dispatch(clearPayBillWizardFlow());
    }
  }, [isBillingFeeFlow]);

  return (
    <>
      {list.map(
        (
          {
            componentType: ComponentType,
            methods,
            isBillingFeeFlow,
            disabled,
            name,
            showCCTooltip,
            hideShowMore,
            ...rest
          },
          index
        ) => (
          <GroupContainer key={index}>
            {isEmpty(methods) ? (
              <ListItemContainer>
                {showCCTooltip && name === 'CC' && <CreditPaymentMethodTooltip />}
                <ComponentType
                  disabled={disabled}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }

                    onAddMethod(ComponentType.methodType);
                  }}
                  optionComponent={optionComponent}
                  {...rest}
                />
              </ListItemContainer>
            ) : (
              <>
                {methods.map((method, index) => {
                  const methodDisabled =
                    disabled ||
                    method.disabled ||
                    isDisabled(method) ||
                    ComponentType.isDisabled(method, {
                      isSelectedDeliveryMethodVirtualCard:
                        rest?.isSelectedDeliveryMethodVirtualCard,
                    });

                  const showTooltip = !!showCCTooltip && index === 0;

                  return (
                    <ListItemContainer key={method.id}>
                      {showTooltip && <CreditPaymentMethodTooltip />}
                      <ComponentType
                        key={method.id}
                        method={method}
                        isBillingFeeFlow={isBillingFeeFlow}
                        onClick={(event: { stopPropagation: () => void }) => {
                          if (methodDisabled) {
                            event.stopPropagation();
                          } else if (onChange) {
                            onChange(method);
                          }
                        }}
                        disabled={methodDisabled}
                        isSelected={value === method}
                        optionComponent={optionComponent}
                        modifyActions={
                          modifyFundingSourceActions ? modifyFundingSourceActions(method) : null
                        }
                        {...rest}
                      />
                    </ListItemContainer>
                  );
                })}
                {showAddAnotherOption && ComponentType.showAddAnotherOption && (
                  <AddAnotherRadioMethodOption
                    key={`add-another-${ComponentType.methodType}`}
                    data-testid={`radio-select-method-${ComponentType.methodType}`}
                    label={ComponentType.addAnotherMethodLabel}
                    $hideShowMore={hideShowMore}
                    onClick={() => {
                      onAddMethod(ComponentType.methodType);
                    }}
                  />
                )}
              </>
            )}
          </GroupContainer>
        )
      )}
    </>
  );
}

const GroupContainer = styled.div`
  margin-bottom: 2rem;
  ${(props) => props.theme.components?.MethodsList?.GroupContainer}
`;

const ListItemContainer = styled.div`
  position: relative;
`;

export default MethodsList;
