import React, { useMemo } from 'react';
import billAlreadyPaidIcon from 'src/app/images/qbo/bill-already-paid.svg';
import styled from 'styled-components';
import ErrorPage, { Illustration } from 'src/app/components/layout/ErrorLayoutPage';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import {
  isPaymentFailed,
  isPaymentMarkedAsPaid,
  isPaymentProcessed,
  isPaymentScheduled,
} from 'src/app/utils/payments';
import { useBillDetails } from 'src/app/pages/bill/hooks/useBillDetails';
import { useLocationState } from 'src/app/utils/hooks';
import { AreaLoader } from '@melio/billpay-design-system';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import errorIcon from 'src/app/images/qbo/error-icon.png';

enum TEXT_VARIANT {
  PROCESSED = 'processed',
  SCHEDULED = 'scheduled',
  MARKED_AS_PAID = 'paid',
  FAILED = 'failed',
}

export const BillBalanceZeroPage = () => {
  const [billId] = useLocationState('billId');
  const [exitUrl] = useLocationState('exitUrl');
  const { fetchRedirectQuery, isFetchingRedirectParams, redirectToDashboard } =
    useRedirectToDashboard();
  const { billDetails: bill, error: billDetailsError } = useBillDetails(billId);

  const { textVariant, itemsToHighlight } = useMemo(() => {
    if (bill) {
      const payments = bill.payments || [];

      const scheduledPayments = payments.filter((payment) => isPaymentScheduled(payment));
      const markedAsPaidPayments = payments.filter((payment) => isPaymentMarkedAsPaid(payment));
      const processedPayments = payments.filter((payment) => isPaymentProcessed(payment));
      const failedPayments = payments.filter((payment) => isPaymentFailed(payment));

      if (scheduledPayments.length) {
        return {
          textVariant: TEXT_VARIANT.SCHEDULED,
          itemsToHighlight: scheduledPayments.map((payment) => ({
            billId: bill.id,
            paymentId: payment.id,
          })),
        };
      }

      if (processedPayments.length) {
        return {
          textVariant: TEXT_VARIANT.PROCESSED,
          itemsToHighlight: processedPayments.map((payment) => ({
            billId: bill.id,
            paymentId: payment.id,
          })),
        };
      }

      if (markedAsPaidPayments.length) {
        return {
          textVariant: TEXT_VARIANT.MARKED_AS_PAID,
          itemsToHighlight: markedAsPaidPayments.map((payment) => ({
            billId: bill.id,
            paymentId: payment.id,
          })),
        };
      }

      if (failedPayments.length) {
        return {
          textVariant: TEXT_VARIANT.FAILED,
          itemsToHighlight: failedPayments.map((payment) => ({
            billId: bill.id,
            paymentId: payment.id,
          })),
        };
      }
    }

    return {};
  }, [bill]);

  if (billDetailsError?.code === 'ERR') {
    return (
      <ErrorPage
        illustration={errorIcon}
        title="inputErrors.QBO01.title"
        subtitle="inputErrors.QBO01.subTitle"
        buttonAction={exitUrl}
        buttonLabel="inputErrors.QBO01.buttonCTA"
        exitUrl={exitUrl}
      />
    );
  }

  if (!bill && !billDetailsError) {
    return <AreaLoader />;
  }

  const handleRedirect = async () => {
    if (itemsToHighlight) {
      const redirectQuery = await fetchRedirectQuery(itemsToHighlight);

      redirectToDashboard({ redirectQuery });
    }
  };

  const textValues = {
    amount: <MIFormattedCurrency value={bill?.totalAmount.toString()} />,
    vendorName: bill?.vendor?.companyName,
  };

  return (
    <StyledErrorPage
      illustration={billAlreadyPaidIcon}
      title="bills.pay.billZeroBalance.title"
      subtitle={textVariant ? `bills.pay.billZeroBalance.${textVariant}.subTitle` : ''}
      subtitleValues={textValues}
      buttonLabel="bills.pay.billZeroBalance.buttonLabel"
      buttonAction={handleRedirect}
      isProcessing={isFetchingRedirectParams}
      exitUrl={exitUrl}
    />
  );
};

const StyledErrorPage = styled(ErrorPage)`
  ${Illustration} {
    height: 7.3rem;
    margin-bottom: 2.5rem;
    margin-left: 1.5rem;
  }
`;
