import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@melio/billpay-design-system';
import { ReactComponent as FirstIcon } from 'src/app/images/qbo/vc-details/first.svg';
import { ReactComponent as SecondIcon } from 'src/app/images/qbo/vc-details/second.svg';
import { ReactComponent as ThirdIcon } from 'src/app/images/qbo/vc-details/third.svg';
import {
  Container,
  Subtitle,
  TextContainer,
  Title,
} from 'src/app/version-2/pages/virtual-card-details/components/ProcessCardStep/ProcessCardStep.styles';

interface ProcessCardStepProps {
  idx: number;
  title: string;
  subtitle: string;
}

const stepIconsMap = {
  1: <FirstIcon />,
  2: <SecondIcon />,
  3: <ThirdIcon />,
};

export const ProcessCardStep = ({ idx, title, subtitle }: ProcessCardStepProps) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Box>{stepIconsMap[idx]}</Box>
      <TextContainer>
        <Title>
          {formatMessage({
            id: title,
          })}
        </Title>
        <Subtitle>
          {formatMessage({
            id: subtitle,
          })}
        </Subtitle>
      </TextContainer>
    </Container>
  );
};
