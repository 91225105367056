import React from 'react';
import { useSelector } from 'react-redux';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import QBOTableDate from './QBOTableDate';
import { PaymentType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const QBOPaidOnCell = ({ item }: Props) => {
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(item.paymentId));
  const date = payment?.manual ? payment?.scheduledDate : payment?.deliveryEta;

  return <QBOTableDate date={date} />;
};

export default QBOPaidOnCell;
