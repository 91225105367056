import React from 'react';
import { RecordOf } from 'immutable';
import compact from 'lodash/compact';
import { DeliveryMethodType } from 'src/app/utils/types';
import { CONSTS, VENDOR_TYPES } from 'src/app/utils/consts';
import { getInternationalDisabledText } from 'src/app/version-2/utils/international.utils';
import { getVendorType } from 'src/app/utils/vendor-utils';
import { useIsInternationalOn } from 'src/app/pages/vendor/delivery-methods/international/hooks/useIsInternationalOn';
import { featureFlags } from '@melio/shared-web';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { isEligibleToReceiveVirtualCard } from 'src/app/utils/payments';
import { SelectMethods } from '../components/SelectMethods';
import AchDeliveryMethod from '../types/AchDeliveryMethod';
import CheckDeliveryMethod from '../types/CheckDeliveryMethod';
import VirtualDeliveryMethod from '../types/VirtualDeliveryMethod';
import InternationalMethod from '../types/InternationalMethod';
import VirtualCardDeliveryMethod from '../types/VirtualCardDeliveryMethod';
import { MethodListItemType } from '../components/MethodsList';

type Props<T> = {
  vendor?: Record<string, any>;
  value?: T;
  deliveryMethods: T[];
  fundingSource?: FundingSource;
  companyName?: string;
  errorLabelValues?: any;
  errorLabel?: string;
  onChange: (value: T) => void;
  onAddMethod: (type: string) => void;
  allowVirtualDeliveryMethod?: boolean;
  hasPartialAmount: boolean;
  onToggleVirtualCardInfoModal?: () => void;
};

function DeliveryMethodsSelect({
  vendor,
  value,
  deliveryMethods,
  fundingSource,
  companyName,
  onAddMethod,
  onChange,
  errorLabelValues,
  errorLabel,
  allowVirtualDeliveryMethod,
  hasPartialAmount = false,
  onToggleVirtualCardInfoModal,
}: Props<RecordOf<DeliveryMethodType>>) {
  const [bpVirtualCardOptInSingleBillPayFlow] = featureFlags.useFeature(
    'bp-virtual-card-opt-in-single-bill-pay-flow'
  );

  const isInternationalFFEnabled = useIsInternationalOn();
  const ach = deliveryMethods.find((dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.ACH);
  const check = deliveryMethods.find((dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.CHECK);
  const virtual = deliveryMethods.find((dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL);
  const international = deliveryMethods.find(
    (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL
  );
  const virtualCard = deliveryMethods.find(
    (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD
  );
  const vendorType = getVendorType(vendor);
  const isVendorNotDetermined = vendorType === VENDOR_TYPES.NOT_DETERMINED;
  const isInternationalVendor = vendorType === VENDOR_TYPES.INTERNATIONAL || isVendorNotDetermined;
  const isDomesticlVendor = vendorType === VENDOR_TYPES.DOMESTIC || isVendorNotDetermined;
  const internationalCCDisabledText = getInternationalDisabledText({
    fundingSource,
    billHasPartialPayments: hasPartialAmount,
  });

  const list: MethodListItemType[] = compact<any>([
    isDomesticlVendor &&
      bpVirtualCardOptInSingleBillPayFlow && {
        name: CONSTS.DELIVERY_TYPE.VIRTUAL_CARD,
        componentType: VirtualCardDeliveryMethod,
        methods: virtualCard ? [virtualCard] : [],
        companyName,
        tag: {
          variant: 'new',
          label: 'deliveryMethods.notDefinedInfo.virtual-card.tagLabel',
        },
        disabled: !isEligibleToReceiveVirtualCard(fundingSource),
        onToggleVirtualCardInfoModal,
      },
    isDomesticlVendor && {
      name: CONSTS.DELIVERY_TYPE.ACH,
      componentType: AchDeliveryMethod,
      methods: ach ? [ach] : [],
      companyName,
    },
    isDomesticlVendor && {
      name: CONSTS.DELIVERY_TYPE.CHECK,
      componentType: CheckDeliveryMethod,
      methods: check ? [check] : [],
      fundingSource,
    },
    allowVirtualDeliveryMethod && isDomesticlVendor
      ? {
          name: CONSTS.DELIVERY_TYPE.VIRTUAL,
          componentType: VirtualDeliveryMethod,
          methods: virtual ? [virtual] : [],
        }
      : null,
    isInternationalFFEnabled &&
      isInternationalVendor && {
        name: CONSTS.DELIVERY_TYPE.INTERNATIONAL,
        componentType: InternationalMethod,
        methods: international ? [international] : [],
        disabled: !!internationalCCDisabledText,
        disabledText: internationalCCDisabledText,
        tag: {
          variant: 'new',
          label: 'deliveryMethods.notDefinedInfo.international.tagLabel',
        },
      },
  ]);

  return (
    <SelectMethods
      value={value}
      list={list}
      id="delivery-method"
      testId="delivery-method"
      errorLabelValues={errorLabelValues}
      errorLabel={errorLabel}
      placeholderIcon="icon-bank-icon"
      placeholderLabel="selectMethods.deliveryMethodPlaceholder"
      onChange={onChange}
      onAddMethod={onAddMethod}
    />
  );
}

export default DeliveryMethodsSelect;
