import React from 'react';
import styled from 'styled-components';
import { PaymentType } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS, PAYMENT_STATUS } from 'src/app/utils/consts';
import { isPaymentDelivered } from './utils';

type Props = {
  payment: PaymentType;
};

const QBRBillListItemStatus = ({ payment }: Props) => {
  const getCompletedLabel = (payment) => {
    switch (payment?.deliveryMethod?.deliveryType) {
      case CONSTS.DELIVERY_TYPE.CHECK:
        return isPaymentDelivered(payment)
          ? 'bills.list.paymentStatus.completed.checkDeposited'
          : 'bills.list.paymentStatus.completed.checkSent';
      case CONSTS.DELIVERY_TYPE.ACH:
        return 'bills.list.paymentStatus.completed.ach';
      default:
        return 'bills.list.paymentStatus.completed.completed';
    }
  };

  const getScheduledLabel = (payment) => {
    switch (payment?.deliveryMethod?.deliveryType) {
      case CONSTS.DELIVERY_TYPE.VIRTUAL:
        return 'bills.list.paymentStatus.scheduledVirtual';
      default:
        return 'bills.list.paymentStatus.scheduled';
    }
  };

  const CONTENT_BY_STATUS = {
    [PAYMENT_STATUS.FAILED]: (
      <ItemStatusFailed>
        <MIFormattedText label="bills.list.paymentStatus.failed" />
      </ItemStatusFailed>
    ),
    [PAYMENT_STATUS.BLOCKED]: (
      <ItemStatus>
        <MIFormattedText label={getScheduledLabel(payment)} />
      </ItemStatus>
    ),
    [PAYMENT_STATUS.SCHEDULED]: (
      <ItemStatus>
        <MIFormattedText label={getScheduledLabel(payment)} />
      </ItemStatus>
    ),
    [PAYMENT_STATUS.IN_PROGRESS]: (
      <ItemStatus>
        <MIFormattedText label="bills.list.paymentStatus.processing" />
      </ItemStatus>
    ),
    [PAYMENT_STATUS.COMPLETED]: (
      <ItemStatus>
        <MIFormattedText label={getCompletedLabel(payment)} />
      </ItemStatus>
    ),
  };

  return CONTENT_BY_STATUS[payment.status] || null;
};

export default QBRBillListItemStatus;

const ItemStatus = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.darkGrey};
`;

const ItemStatusFailed = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.red};
`;
