import { prefix } from '../../consts';

export const colors = {
  [prefix]: {
    gray: {
      100: '#393A3D',
      200: '#6B6C72',
      300: '#8D9096',
      400: '#BABEC5',
      500: '#D4D7DC',
      600: '#E3E5E8',
      700: '#ECEEF1',
      800: '#F4F5F8',
    },
    black: '#393A3D',
    overlay: '#21212499',
    white: '#FFFFFF',
    red: {
      100: '#B80000',
      200: '#D52B1E',
      300: '#E43834',
      400: '#F25A52',
      500: '#FF8979',
      700: '#FBEAE9',
    },
    yellow: {
      100: '#FFAD00',
      200: '#FFBB00',
      300: '#FFCA00',
      400: '#FFDC00',
      500: '#FFEE00',
    },
    blue: {
      100: '#055393',
      200: '#0077C5',
      300: '#0097E6',
      400: '#21ABF6',
      500: '#34BFFF',
      700: '#E6F5FC',
    },
    green: {
      100: '#108000',
      200: '#2CA01C',
      300: '#53B700',
      400: '#7FD000',
      500: '#A9E838',
      700: '#EAF6E8',
    },
    orange: {
      100: '#F95700',
      200: '#FF6A00',
      300: '#FF8000',
      400: '#FF9331',
      500: '#FFAC61',
    },
    pink: {
      100: '#9C005E',
      200: '#C9007A',
      300: '#E31C9E',
      400: '#FF59CC',
      500: '#FF88EC',
    },
    purple: {
      100: '#4E2B8F',
      200: '#6436AF',
      300: '#7A3DD8',
      400: '#9457FA',
      500: '#A898FF',
    },
    teal: {
      100: '#008481',
      200: '#00A6A4',
      300: '#00C1BF',
      400: '#00D7D7',
      500: '#15EFE9',
      600: '#e5f6f6',
    },
    cyan: {
      100: '#006e6b',
      200: '#007572',
      300: '#007d7a',
      400: '#008481',
      500: '#168c88',
      600: '#249390',
      700: '#2f9b97',
    },
  },
};
