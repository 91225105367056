import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { getOrgId, getFundingSources } from 'src/app/redux/user/selectors';
import {
  NEW_VENDOR_ID,
  REDIRECTION_PERFORMED,
  DELIVERY_METHOD_ORIGIN,
  FUNDING_SOURCE_ORIGIN,
} from 'src/app/utils/consts';
import ValidationError from 'src/app/ui/ValidationError';
import organizationsApi from 'src/app/services/api/organizations';
import paymentsApi from 'src/app/services/api/payments';
import billsApi from 'src/app/services/api/bills';
import { IntuitBankAccountResponseType, VendorType } from 'src/app/utils/types';
import { SelectFieldOption } from 'src/app/ui/form/WizardSelectField';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourceOrigins } from 'src/app/version-2/model/enums';
import paymentStore from 'src/app/modules/payments/payment-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { useQueryString } from 'src/app/utils/hooks';
import { useApi } from 'src/app/hoc/useApi';
import { hasMaximumAmountLimit, hasMinimumAmountLimit } from 'src/app/utils/bills';
import { useForm } from 'src/app/ui/form';
import { mapVendorsToOptions, isNewVendor } from 'src/app/utils/vendors';
import {
  mapCategoriesToOptions,
  getJustPayDefaultDeliveryMethod,
  getRedirectUrlByDeliveryMethodStatus,
} from 'src/app/pages/just-pay/justPayUtils';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import justPayLocations from 'src/app/pages/just-pay/justPayLocations';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import analytics from 'src/app/services/analytics';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { RISK_BILL_AMOUNT } from 'src/app/version-2/pages/batch-bulk/model/consts/riskBillAmount.consts';

export type QueryType = {
  isQBCash: string;
  deliveryMethodType: string;
};

type ModelType = {
  amount: string | number | null;
  vendor: string | null;
  category: string | null;
};

type ValidationErrorsType = {
  amount?: string;
  vendor?: string;
};

type WizardFieldsToUpdate = {
  deliveryMethodId?: number | null;
  deliveryMethodType?: string | null;
  vendor: { id: number; companyName: string };
  category?: {
    id?: string | undefined;
    value?: string | undefined;
    isCategoryPreselected: boolean;
  };
};

enum PAYMENT_MV_KEYS {
  AMOUNT = 'amount',
  VENDOR = 'vendor',
  CATEGORY = 'category',
}

function getDeliveryTypeFromQuery(query: QueryType): string | null {
  return query.deliveryMethodType === ':deliveryMethodType' ? null : query.deliveryMethodType;
}

export const useJustPayAddPaymentInfoState = ({ onNext }) => {
  /*
    TODO: @denis-melio
    breakdown - separate and move PaymentState from this hook
  */
  const orgId: string = useSelector(getOrgId);
  const query: QueryType = useQueryString() as QueryType;
  const paymentStoreActions = useStoreActions(paymentStore);
  const vendorsStoreActions = useStoreActions(vendorsStore);
  const deliveryMethodsStoreActions = useStoreActions(deliveryMethodsStore);
  const [historyPush] = useHistoryWithOrgId();

  const userFundingSources: FundingSource[] = useSelector(getFundingSources);

  const vendors: VendorType[] = useSelector(vendorsStore.selectors.all);
  const isVendorListLoading = useSelector(vendorsStore.selectors.loadingByOrgId(orgId));

  const payment: JustPayPaymentType = useSelector(
    paymentStore.selectors.justPay.justPayWizard.payment
  );

  const selectedVendor: VendorType = useSelector(vendorsStore.selectors.byId(payment.vendor?.id));

  const [vendorOptions, setVendorOptions] = useState<SelectFieldOption<string>[]>([]);

  const [categoryOptions, setCategoryOptions] = useState<SelectFieldOption<string>[]>([]);

  const [isBillAmountRequiresInvoiceFile, setIsBillAmountRequiresInvoiceFile] =
    useState<boolean>(false);

  const [fetchCategoriesForBill, , isCategoryListLoading] = useApi(
    organizationsApi.getAccountsForBill,
    false
  );

  const [getPayments] = useApi(paymentsApi.getPayments);

  const [getBills] = useApi(billsApi.getBills);

  const model = useMemo(
    () =>
      ({
        amount: payment.amount || '',
        vendor: payment.vendor?.companyName || '',
        category: payment.category?.value || '',
      } as ModelType),
    [payment.category?.value]
  );

  const amountDebouncedUpdate = useCallback(
    debounce(
      (value) =>
        paymentStoreActions.justPay.justPayWizard.update({
          amount: convertCurrencyToNumber(value),
        }),
      150
    ),
    []
  );

  const getValidationErrors = (value: ModelType) => {
    const validationErrors: ValidationErrorsType = {};

    if (isEmpty(value.vendor)) {
      validationErrors.vendor = 'inputErrors.justPayPaymentInfo.vendor.string.base';
    }

    if (isEmpty(value.amount)) {
      validationErrors.amount = 'inputErrors.justPayPaymentInfo.amount.string.base';
    }

    if (hasMaximumAmountLimit(Number(convertCurrencyToNumber(value.amount)))) {
      validationErrors.amount = 'inputErrors.justPayPaymentInfo.amount.string.max';
    }

    if (hasMinimumAmountLimit(Number(convertCurrencyToNumber(value.amount)))) {
      validationErrors.amount = 'inputErrors.justPayPaymentInfo.amount.string.min';
    }

    return validationErrors;
  };

  const redirectToNextScreen = ({ newVendorId }) => {
    if (payment.fundingSourceOrigin === FUNDING_SOURCE_ORIGIN.QBCASH && payment.deliveryMethodId) {
      onNext(justPayLocations.operations.selectDeductionDate)();

      return;
    }

    if (payment.fundingSourceOrigin !== FUNDING_SOURCE_ORIGIN.QBCASH) {
      onNext(justPayLocations.create.selectFundingSource)();

      return;
    }

    if (!payment.deliveryMethodId) {
      const url = getRedirectUrlByDeliveryMethodStatus({
        orgId,
        selectedVendor,
        newVendorId,
        deliveryMethodType: payment.deliveryMethodType,
      });

      historyPush({
        path: url,
        state: {
          origin: DELIVERY_METHOD_ORIGIN.JUST_PAY,
          redirectUrl: justPayLocations.operations.selectDeductionDate,
        },
      });

      return;
    }

    onNext(justPayLocations.create.selectFundingSource)();
  };

  const [paymentInfoMV, paymentInfoMVActions, validationErrors, loadingMV] = useForm<ModelType>(
    model,
    {
      submit: async (value: ModelType) => {
        const validationErrors = getValidationErrors(value);

        if (!isEmpty(validationErrors)) {
          throw new ValidationError({ validationErrors });
        }

        if (isNewVendor(vendorOptions, payment.vendor?.id.toString())) {
          const newVendor = await vendorsStoreActions.create({
            orgId,
            companyName: payment.vendor.companyName,
          });

          const { id: newVendorId, companyName: newVendorCompanyName } = newVendor.payload;

          paymentStoreActions.justPay.justPayWizard.update({
            vendor: {
              id: newVendorId,
              companyName: newVendorCompanyName,
            },
          });
          analytics.trackAction('payment-info-continue', {
            vendorId: newVendorId,
            category: payment.category?.id,
            isNewVendor: true,
            isCategoryPreselected: payment.category.isCategoryPreselected,
            trackingBillId: payment.trackingBillId,
          });
          redirectToNextScreen({ newVendorId });

          return REDIRECTION_PERFORMED;
        }

        analytics.trackAction('payment-info-continue', {
          vendorId: payment.vendor?.id,
          category: payment.category?.id,
          isNewVendor: false,
          isCategoryPreselected: payment.category.isCategoryPreselected,
          trackingBillId: payment.trackingBillId,
        });

        redirectToNextScreen({ newVendorId: null });

        return REDIRECTION_PERFORMED;
      },
      onChange: ({ id, key, value, modelState }) => {
        const changeVendor = () => {
          const fieldsToUpdate: WizardFieldsToUpdate = {
            vendor: { id, companyName: value },
            category: {
              isCategoryPreselected: false,
            },
          };

          if (id === NEW_VENDOR_ID) {
            const deliveryMethodType = getDeliveryTypeFromQuery(query);

            fieldsToUpdate.deliveryMethodType = deliveryMethodType;
            fieldsToUpdate.deliveryMethodId = null;

            paymentStoreActions.justPay.justPayWizard.update(fieldsToUpdate);
          } else {
            const getPreSelected = async () => {
              const { objects } = await getBills({
                orgId,
                filters: { start: 0, limit: 1, sorting: 'createdAt:DESC', vendorId: id },
              });
              const lastCategoryId = get(objects, '[0].intuitAccountId');
              const preSelected = find(
                categoryOptions,
                (category) => category.id === lastCategoryId
              );

              if (preSelected) {
                fieldsToUpdate.category = {
                  id: preSelected.id,
                  value: preSelected.value,
                  isCategoryPreselected: true,
                };
              }

              paymentStoreActions.justPay.justPayWizard.update(fieldsToUpdate);
            };

            getPreSelected();
          }
        };

        const changeCategory = () => {
          analytics.trackAction('select-vendor-category', {
            categoryId: id,
            categoryName: value,
            vendorId: payment.vendor?.id,
            trackingBillId: payment.trackingBillId,
          });
          paymentStoreActions.justPay.justPayWizard.update({
            category: { id, value, isCategoryPreselected: false },
          });
        };

        const changeCases = {
          [PAYMENT_MV_KEYS.AMOUNT]: amountDebouncedUpdate.bind(this, value),
          [PAYMENT_MV_KEYS.VENDOR]: changeVendor,
          [PAYMENT_MV_KEYS.CATEGORY]: changeCategory,
        };

        changeCases[key]();

        return modelState;
      },
    }
  );

  useEffect(() => {
    const isQBCash = query.isQBCash === 'true';
    const deliveryMethodType = getDeliveryTypeFromQuery(query);

    vendorsStoreActions.list({ orgId });

    fetchCategoriesForBill(orgId).then(
      ({ accounts }: { accounts: IntuitBankAccountResponseType[] }) => {
        setCategoryOptions(mapCategoriesToOptions(accounts));
      }
    );

    if (isQBCash) {
      const qbCashFundingSource = userFundingSources.find(
        (fs) => fs.origin === FundingSourceOrigins.QBCASH
      );

      paymentStoreActions.justPay.justPayWizard.update({
        fundingSourceId: qbCashFundingSource?.id,
        fundingSourceType: qbCashFundingSource?.fundingType,
        fundingSourceOrigin: FUNDING_SOURCE_ORIGIN.QBCASH,
        suggestedFundingSources: {
          selectedFundingSource: find(
            userFundingSources,
            (fs) => fs.id === qbCashFundingSource?.id
          ),
        },
      });
    } else {
      getPayments({
        orgId,
        filters: { start: 0, limit: 1 },
        sorting: 'createdAt',
      }).then((lastPayment) => {
        if (!isEmpty(get(lastPayment, 'objects'))) {
          const lastFundingSource = head<any>(lastPayment.objects);

          paymentStoreActions.justPay.justPayWizard.update({
            fundingSourceId: lastFundingSource?.fundingSourceId,
            fundingSourceType: lastFundingSource?.fundingType, // TODO: @denis-melio: don't get fundingType from the server
            suggestedFundingSources: {
              selectedFundingSource: find(
                userFundingSources,
                (fs) => fs.id === lastFundingSource?.fundingSourceId
              ),
            },
          });
        }
      });
    }

    paymentStoreActions.justPay.justPayWizard.update({
      orgId,
      isQBCash,
      deliveryMethodType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVendorOptions(mapVendorsToOptions(vendors));
  }, [vendors]);

  useEffect(() => {
    if (!isEmpty(selectedVendor)) {
      const defaultDeliveryMethod = getJustPayDefaultDeliveryMethod({
        deliveryMethodType: payment.deliveryMethodType,
        deliveryMethods: selectedVendor?.deliveryMethods,
      });

      paymentStoreActions.justPay.justPayWizard.update({
        deliveryMethodId: defaultDeliveryMethod?.id,
        deliveryMethodType: defaultDeliveryMethod?.deliveryType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVendor]);

  useEffect(() => {
    convertCurrencyToNumber(paymentInfoMV.amount.value) >= RISK_BILL_AMOUNT.REQUIRES_INVOICE_FILE
      ? setIsBillAmountRequiresInvoiceFile(true)
      : setIsBillAmountRequiresInvoiceFile(false);
  }, [paymentInfoMV.amount.value]);

  useEffect(() => {
    const { vendor } = payment;

    if (vendor?.id && vendor?.id.toString() !== NEW_VENDOR_ID) {
      deliveryMethodsStoreActions.list({ orgId: payment.orgId, vendorId: vendor.id });
    }
  }, [payment.vendor?.id]);

  return {
    payment,
    paymentStoreActions,
    vendorOptions,
    categoryOptions,
    paymentInfoMV,
    paymentInfoMVActions,
    validationErrors,
    isBillAmountRequiresInvoiceFile,
    loadingMV,
    isVendorListLoading,
    isCategoryListLoading,
    flowOrigin: payment?.flowOrigin,
    selectedVendor,
  };
};
