import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { CONST } from './const';

const DEFAULT_CYCLE_TIME = 5000;
const DEFAULT_PROGRESS = 25;
const DEFAULT_STEP = 25;

export type ProgressPageProps = {
  progress?: number;
  step?: number;
  cycleTime?: number;
  className?: string;
};
export const ProgressPage: React.FC<ProgressPageProps> = ({
  progress = DEFAULT_PROGRESS,
  step = DEFAULT_STEP,
  cycleTime = DEFAULT_CYCLE_TIME,
}) => (
  <LoadingContainer>
    <ProgressPageLoader progress={progress} step={step} cycleTime={cycleTime} />
  </LoadingContainer>
);

export const ProgressPageLoader: React.FC<ProgressPageProps> = ({
  progress = DEFAULT_PROGRESS,
  step = DEFAULT_STEP,
  cycleTime = DEFAULT_CYCLE_TIME,
  className,
}) => {
  const [friendlyMessage, setFriendlyMessage] = useState<string>();
  const site = useSiteContext();
  const mounted = useRef(false);
  const siteContext = site.name;
  const messages = CONST.MESSAGES[siteContext];
  const title = CONST.TITLES[siteContext];

  useEffect(() => {
    mounted.current = true;
    animateMessages();

    return () => {
      mounted.current = false;
    };
  }, []);

  const animateMessages = () => {
    let currMsg = 0;

    setFriendlyMessage(messages[currMsg]);
    setInterval(() => {
      if (!mounted.current) return;

      currMsg = (currMsg + 1) % messages.length;
      setFriendlyMessage(messages[currMsg]);
    }, cycleTime);
  };

  return (
    <LoadingMessage className={className}>
      <OneSecMessage>
        <MIFormattedText label={title} />
      </OneSecMessage>
      <FriendlyMessage>
        <MIFormattedText label={friendlyMessage} />
      </FriendlyMessage>
      <ProgressBarContainer>
        <ProgressBar>
          <Progress progress={progress} step={step} />
        </ProgressBar>
      </ProgressBarContainer>
    </LoadingMessage>
  );
};

const LoadingContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.opaque};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => props.theme.zIndex.overlay};
  width: 100%;
  height: 100%;
  border: 0;
`;

const LoadingMessage = styled.div`
  left: 50%;
  width: calc(100% - 3.2rem);
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const OneSecMessage = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
`;

const FriendlyMessage = styled.div`
  position: relative;
  min-height: 4rem;
  font-size: ${(props) => props.theme.text.size.wizardText};
  line-height: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'Avenir Next forINTUIT';
  color: ${(props) => props.theme.text.color.label};
  animation: message 5s linear infinite;

  @keyframes message {
    0% {
      opacity: 0;
    }

    5% {
      opacity: 1;
    }

    95% {
      opacity: 1;
      top: 0px;
    }

    100% {
      opacity: 0;
      top: 2rem;
    }
  }
`;

const ProgressBarContainer = styled.div`
  max-width: 30rem;
  margin: 0 auto 4rem auto;
  border-radius: 6rem;
  overflow: hidden;
  width: 100%;
`;

const ProgressBar = styled.div`
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.brand};
  border-radius: 6rem;
  display: block;
`;

const progressAnimation = (progress: number, step: number) =>
  keyframes`
    0% {
      width: ${progress - step}%;
    }
    100% {
      width: ${progress}%;
    }
  `;

const Progress = styled.div<{ progress: number; step: number }>`
  animation: ${(props) => progressAnimation(props.progress, props.step)} 2s ease-in-out 1;
  background: ${(props) => props.theme.colors.brand};
  color: #fff;
  padding: 0.5rem;
  width: 0;
  animation-fill-mode: forwards;
`;
