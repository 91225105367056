import React from 'react';
import { Box, Flex, Tooltip } from '@melio/billpay-design-system';

import { useSelector } from 'react-redux';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { useQueryState } from 'src/app/utils/hooks';
import { useIsTruncated } from 'src/app/helpers/react/useIsTruncated';
import style from './style';
import { VendorType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const QBOVendorNameCell = ({ item }: Props) => {
  const [, setVendorId] = useQueryState<string>('vendorId');
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(item.vendorId));
  const { ref, isTruncated } = useIsTruncated<HTMLDivElement>();

  const handleVendorClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setVendorId(item.vendorId);
  };

  return (
    <Flex>
      <Tooltip placement="right" label={vendor.companyName} isDisabled={!isTruncated}>
        <Box
          ref={ref}
          __css={style}
          onClick={handleVendorClick}
          data-testid={`vendor-name-cell-${item.id}`}
        >
          {vendor.companyName}
        </Box>
      </Tooltip>
    </Flex>
  );
};

export default QBOVendorNameCell;
