import { RefObject, useEffect, useRef, useState } from 'react';

type Response<T> = {
  isTruncated: boolean;
  ref: RefObject<T>;
};

function useIsTruncated<T extends HTMLElement>(): Response<T> {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    const scrollWidth = ref.current?.scrollWidth || 0;
    const clientWidth = ref.current?.clientWidth || 0;

    setIsTruncated(scrollWidth > clientWidth);
  }, [ref.current?.clientWidth, ref.current?.scrollWidth]);

  return { ref, isTruncated };
}

export { useIsTruncated };
