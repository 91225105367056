import { MIFormattedCurrency } from 'src/app/version-2/externals';
import { OrganizationFee } from 'src/app/version-2/model/dtos';
import { CardTypeEnum, DeliveryEnum, FeeVariantsEnum } from 'src/app/version-2/model/enums';

export type DeliveryFeeTypes = DeliveryEnum | CardTypeEnum | FeeVariantsEnum;

// get fee object from catalog by delivery type
export const getFeeFromCatalog = (fees, deliveryType: DeliveryFeeTypes) =>
  fees?.[deliveryType]?.value;

// extact final fee amount (including symbol e.g $1.5, 2.9%)
interface getCompleteFeeByDeliveryTypeParams {
  fees: Record<string, any> | Record<string, any>[] | null;
  deliveryType?: DeliveryFeeTypes;
  existingFeeItem?: any;
}
export const getCompleteFeeByDeliveryType = ({
  fees,
  deliveryType,
  existingFeeItem,
}: getCompleteFeeByDeliveryTypeParams): string | React.ReactNode => {
  // fees are not loaded yet
  if (!fees) return undefined;

  const feeValues = !Array.isArray(fees) ? Object.values(fees) : fees;
  const feeItem =
    existingFeeItem || feeValues.find((fee: OrganizationFee) => fee?.feeType === deliveryType);

  // selected fee is not in the list
  if (!feeItem?.value) return undefined;

  const { valueType, value = '' } = feeItem;

  if (value === '0' || value === 0) return undefined;

  if (valueType === 'percent') return `${value}%`;

  return <MIFormattedCurrency value={value || 0} />;
};

export const isAchCheckFeeType = (feeType: string | undefined) =>
  feeType === FeeVariantsEnum.ACH_TO_CHECK;
