import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';

const QBROutsideFooter: React.FC = () => (
  <QBROutsideFooterContainer>
    <TextInfo>
      <MIFormattedText label="vendors.addDeliveryMethodByLink.success.info" />
    </TextInfo>
    <Link href="https://quickbooks.intuit.com/get-money/">
      <MIFormattedText label="vendors.addDeliveryMethodByLink.success.linkLabel" />
    </Link>
  </QBROutsideFooterContainer>
);

export default QBROutsideFooter;

const QBROutsideFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem 2rem;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
  background-color: ${(props) => props.theme.colors.white.opaque};

  @media ${devices.desktop}, ${devices.tablet} {
    margin: -10rem auto auto;
    max-width: 49.8rem;
    padding-bottom: 3.5rem;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
`;

const TextInfo = styled.div`
  text-align: center;
  color: ${(props) => props.theme.text.color.darkGrey};
  ${(props) => props.theme.text.fontType.medium};
  margin-top: 2rem;
`;

const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  margin-top: 1.2rem;
  ${(props) => props.theme.text.fontType.medium};
  font-weight: ${(props) => props.theme.text.weight.medium};
  color: ${(props) => props.theme.colors.link};
  text-decoration: none;
`;
