import { FundingSource } from 'src/app/version-2/model/dtos';
import { AchDetailsValidationContext } from '../../objects/achDetailsValidationContext';

import { TestConfig } from 'yup';

interface IsFundingSourceWithTheSameDetailsExistParams {
  userFundingSources: FundingSource[];
  routingNumber?: string;
  accountNumber?: string;
}

const isFundingSourceWithTheSameDetailsExist = ({
  userFundingSources,
  routingNumber,
  accountNumber,
}: IsFundingSourceWithTheSameDetailsExistParams) =>
  userFundingSources.some((fundingSource) => {
    const bankAccount = fundingSource.bankAccount || {};

    return (
      bankAccount.routingNumber === routingNumber && bankAccount.accountNumber === accountNumber
    );
  });

export const fundingSourceWithSameDetailsExistTest: TestConfig<
  string | undefined,
  AchDetailsValidationContext
> = {
  test: (accountNumber, validationCtx) => {
    const { options, createError, parent } = validationCtx;
    const userFundingSources = options.context?.userFundingSources || [];

    const routingNumber = parent.routingNumber;

    if (
      isFundingSourceWithTheSameDetailsExist({ userFundingSources, routingNumber, accountNumber })
    ) {
      return createError({
        message: 'inputErrors.deliveryMethodAch.tryToPayHimself',
      });
    }

    return true;
  },
};
