import styled from 'styled-components';

export const FlexAlignCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UnilateralContentContainer = styled(FlexAlignCenterContainer)`
  margin-bottom: 2rem;
`;

export const ItemContainer = styled(FlexAlignCenterContainer)`
  margin-bottom: 2rem;
  text-align: center;
`;

export const ItemContentContainer = styled(FlexAlignCenterContainer)<{ isMobile?: boolean }>`
  grid-gap: ${({ isMobile }) => (isMobile ? '0.8rem' : '0.2rem')};
  margin-bottom: 2rem;
`;

export const ItemContentTitle = styled(FlexAlignCenterContainer)<{ isMobile?: boolean }>`
  grid-gap: ${({ isMobile }) => (isMobile ? '0.8rem' : '0.2rem')};
`;

export const ItemContentBody = styled(FlexAlignCenterContainer)<{ isMobile?: boolean }>`
  grid-gap: ${({ isMobile }) => (isMobile ? '0.8rem' : '0.2rem')};
`;
