import styled from 'styled-components';

export const DragAndDropWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
`;

export const FileInput = styled.input`
  visibility: hidden;
  position: absolute;
  height: auto;
  width: auto;
`;
