import { matchPath } from 'react-router-dom';
import orgLocations from '../pages/orgs/locations';

const getOrgIdByUrl = (): number => {
  let orgId;
  const match = matchPath<{ orgId?: string }>(window.location.pathname, {
    path: orgLocations.index,
    exact: false,
    strict: true,
  });

  if (match?.params?.orgId) {
    orgId = Number(match.params.orgId);
  }

  return orgId;
};

const getRealmIdByUrl = (): string | undefined => {
  const match = matchPath<{ realmId?: string }>(window.location.pathname, {
    path: ['/quickbooks/entry/:action/:realmId', '/quickbooks/mobile/:action/:realmId'],
    exact: false,
    strict: false,
  });

  return match?.params?.realmId;
};

export { getOrgIdByUrl, getRealmIdByUrl };
