import React from 'react';
import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import { CheckFeeEnum, FeatureFlagsEnum, FeeVariantsEnum } from 'src/app/version-2/model/enums';
import { organizationSelectors } from 'src/app/version-2/modules/organization/organization.slice';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import {
  isAchCheckPayment,
  isCheckFeeOpenNoPromotions,
  isCheckFeeOpenWithPromotions,
} from 'src/app/version-2/utils/checkFees.utils';
import { getCompleteFeeByDeliveryType } from 'src/app/version-2/utils/fee.utils';
import { MAX_NOTIFICATION_COUNT } from './consts';
import { NotificationAppliedFee } from './NotificationAppliedFee';
import { NotificationPromotionsEnded } from './NotificationPromotionsEnded';

// confirm fee applied notificationz
const NotificationCheckFee = (props) => {
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlagsEnum.CHECK_FEES, CheckFeeEnum.CLOSED, {
    shouldTrack: false,
  });
  const userPreferences = useSelector(userSliceSelectors.selectUserPreferences);
  const catalog = useSelector(organizationSelectors.selectFeeCatalog);
  const isValidCheckFeePayment = isAchCheckPayment(props);

  // init notification count
  const checkFeeEnabledWithPromotions = isCheckFeeOpenWithPromotions(checkFeeFlag);
  const feeNotificationCount = Number(userPreferences?.checkFeesNotificationCount) || 0;

  // promotions ended count
  const checkFeeEnabledNoPromotions = isCheckFeeOpenNoPromotions(checkFeeFlag);
  const promotionsEndedCount = Number(userPreferences?.checkFeesPromotionsEndedCount) || 0;

  if (
    checkFeeEnabledWithPromotions &&
    isValidCheckFeePayment &&
    feeNotificationCount < MAX_NOTIFICATION_COUNT
  ) {
    const catalogFee = getCompleteFeeByDeliveryType({
      fees: catalog,
      deliveryType: FeeVariantsEnum.ACH_TO_CHECK,
    });

    return <NotificationAppliedFee fee={catalogFee} isBatch={!!props.batchData} />;
  }

  if (
    checkFeeEnabledNoPromotions &&
    isValidCheckFeePayment &&
    promotionsEndedCount < MAX_NOTIFICATION_COUNT
  ) {
    return <NotificationPromotionsEnded isBatch={!!props.batchData} />;
  }

  return null;
};

export { NotificationCheckFee };
