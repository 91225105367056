const LOCATIONS = {
  base: [
    '/vendors/delivery-method-virtual/:token',
    '/vendors/unilateral/add-delivery-method/:token',
    '/vendors/unilateral/add-delivery-method/:token/switch-company',
    '/vendors/unilateral/add-delivery-method/:token/invalid-link',
    '/vendors/unilateral/add-delivery-method/:token/success',
  ],
  index: '/vendors/delivery-method-virtual/:token',
  addMethod: '/vendors/unilateral/add-delivery-method/:token',
  switchCompany: '/vendors/unilateral/add-delivery-method/:token/switch-company',
  invalidToken: '/vendors/unilateral/add-delivery-method/:token/invalid-link',
  success: '/vendors/unilateral/add-delivery-method/:token/success',
};

export default LOCATIONS;
