import React, { useEffect } from 'react';

import Step from 'src/app/components/layout/QBOLayoutPage';
import analytics from 'src/app/services/analytics';

import { StepProps } from '../../types';

import Form from './Form';

const PurposeOfPayment = ({ relativeStep, onPrev, onNext, onExit }: StepProps) => {
  useEffect(() => {
    analytics.page('pay-bill', 'international-purpose');
  }, []);

  return (
    <Step
      title="international.purposeOfPayment.title"
      relativeStep={relativeStep}
      onPrev={onPrev}
      goExit={onExit}
      hideHeader
    >
      <Form onNext={onNext} />
    </Step>
  );
};

export default PurposeOfPayment;
