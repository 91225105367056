import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@melio/billpay-design-system';

import { useSiteContext } from 'src/app/hoc/withSiteContext';
import MIButton from 'src/app/components/common/MIButton';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { OrganizationType, PaymentType, VirtualCardType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import { InfoLayout, InlineLink } from '../components';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  organization?: OrganizationType;
  payment?: PaymentType;
  virtualCard?: VirtualCardType;
  processing?: boolean;
  onIssueNewCardClick: VoidFunction;
};

const ExpiredLayout = ({
  organization,
  payment,
  virtualCard,
  processing,
  onIssueNewCardClick,
}: Props) => {
  const { analyticsProperties } = useStateChangedAnalytics({
    payment,
    paymentState: VirtualCardPaymentState.Expired,
  });
  const intl = useIntl();
  const site = useSiteContext();

  const formattedAmount =
    payment?.amount &&
    intl.formatNumber(payment.amount, {
      style: 'currency',
      currency: 'USD',
    });

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <InfoLayout.Icon type={InfoLayout.IconType.Alert} />
      <InfoLayout.Title>
        <MIFormattedText
          label="vendors.virtualCardDetails.expired.title"
          values={{
            date: <MIFormattedDate date={virtualCard?.endDate} />,
          }}
        />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label="vendors.virtualCardDetails.expired.subtitle"
          values={{
            name: organization?.companyName,
            amount: formattedAmount,
          }}
        />
      </InfoLayout.Subtitle>

      <Box mt="4rem">
        <MIButton
          label="vendors.virtualCardDetails.expired.cta"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={onIssueNewCardClick}
          isProcessing={processing}
          fullWidth
          analyticsProperties={analyticsProperties}
        />
      </Box>

      <Box mt="1.6rem" textStyle="body3" textAlign="center">
        <MIFormattedText
          label="vendors.virtualCardDetails.common.contactSupport"
          values={{
            link: (...chunks) => (
              <InlineLink href={`mailto:${site.config.support.email}`} chunks={chunks} />
            ),
          }}
        />
      </Box>
    </InfoLayout>
  );
};

export { ExpiredLayout };
