import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';

type Props = {
  value: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
  testId?: string;
};

const QBOSearchInput = ({ onSubmit, onChange, disabled, value, placeholder, testId }: Props) => {
  const intl = useIntl();
  const ref = useRef<HTMLInputElement>();
  const placeholderText = !placeholder ? '' : intl.formatMessage({ id: placeholder });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onChange(e.currentTarget.value || '');
    }
  };
  const handleSearchIconClick = () => {
    onSubmit(value);
  };

  return (
    <SearchInputContainer>
      <SearchInput
        disabled={disabled}
        value={value || ''}
        placeholder={placeholderText}
        type="search"
        ref={ref as any}
        onChange={handleChange}
        data-testid={testId}
      />
      <SearchIcon
        isSearchable={!!value}
        onClick={handleSearchIconClick}
        className="icon-search-magnifier-icon"
      />
      {value && <ClearIcon className="icon-close-icon" onClick={() => onChange('')} />}
    </SearchInputContainer>
  );
};

export default QBOSearchInput;

const SearchInputContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const SearchInput = styled.input`
  width: 24.8rem;
  height: 3.6rem;
  background: ${(props) => props.theme.background.lightGrey};
  padding: 0 3rem 0 4.2rem;
  border: 2px solid transparent;
  outline: none;
  color: ${(props) => props.theme.text.color.main};
  border-radius: 3rem;
  font-size: ${(props) => props.theme.text.size.regular};
  transition: all 0.5s;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.dark.translucent3};
    font-size: ${(props) => props.theme.text.size.regular};
  }

  &:focus {
    border: 2px solid ${(props) => props.theme.colors.border.grey};
  }
`;

const iconStyles = css`
  position: absolute;
  z-index: 2;
  display: block;
  line-height: ${(props) => props.theme.text.size.title};
  text-align: center;
`;

const SearchIcon = styled.i<{ isSearchable?: boolean }>`
  ${iconStyles}
  color: ${(props) => props.theme.text.color.darkGrey};
  cursor: ${(props) => (props.isSearchable ? 'pointer' : 'default')};
  width: 4.8rem;
  font-size: 2rem;
  top: 0;
  left: 0;
`;

const ClearIcon = styled.i`
  ${iconStyles}
  color: rgba(186, 190, 197, 1);
  cursor: pointer;
  width: 4.2rem;
  font-size: 2rem;
  top: 0;
  right: 0;
`;
