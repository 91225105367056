import React, { useEffect, useState } from 'react';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';

import vendorsApi from 'src/app/services/api/vendors';
import paymentStore from 'src/app/modules/payments/payment-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { PaymentType } from 'src/app/utils/types';
import { pushNotification } from 'src/app/services/notifications';
import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';

import { EditPaymentEmailForm } from './forms';
import { FORM_TYPES, TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH } from '../consts';
import { triggerAnalyticsEvent } from '../utils';

type Props = {
  payment: PaymentType;
  isMobile: boolean;
  token: string;
  isLoading: boolean;
  onClose: () => void;
};

const formFieldNames = ['contactEmail'];
const labels = formFieldNames.reduce(
  (currentLabels, currentFieldName) => ({
    ...currentLabels,
    [currentFieldName]: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_EMAIL}.form.${currentFieldName}`,
  }),
  {}
);

const EditPaymentEmail = ({ payment, isMobile, token, isLoading, onClose }: Props) => {
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const paymentStoreActions = useStoreActions(paymentStore);

  useEffect(() => {
    triggerAnalyticsEvent({ payment, action: 'email-modal-opened' });
  }, []);

  const handleSubmit = async (value: { [key: string]: string }) => {
    const { contactEmail } = value;
    let validationErrors = getValidationErrors('vendor', { contactEmail }, formFieldNames);

    if (validationErrors?.contactEmail || !contactEmail) {
      validationErrors = {
        contactEmail: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_EMAIL}.form.validation.contactEmail.required`,
      };
    }

    setValidationErrors(validationErrors);

    const isValid = isValidationOk(validationErrors) && contactEmail;

    if (!isValid) return;

    try {
      await vendorsApi.editVendorEmailWithToken({ token, contactEmail });
      paymentStoreActions.fetchPaymentDetailsWithToken({ token });

      triggerAnalyticsEvent({
        payment,
        action: 'edit-email-saved',
        additionalData: {
          successfullySaved: true,
        },
      });
      onClose();
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_EMAIL}.notification.success`,
        textValues: {
          contactEmail,
        },
      });
    } catch (error) {
      triggerAnalyticsEvent({
        payment,
        action: 'edit-email-saved',
        additionalData: {
          successfullySaved: false,
        },
      });
      pushNotification({
        type: NOTIFICATION_VARIANT.ERROR,
        msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_EMAIL}.notification.failed`,
      });
    }
  };

  return (
    <EditPaymentEmailForm
      payment={payment}
      labels={labels}
      validationErrors={validationErrors}
      isMobile={isMobile}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export { EditPaymentEmail };
