import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';

import { getIsRecurring } from 'src/app/redux/payBillWizard/selectors';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import MIMoney from 'src/app/components/common/MIMoney';
import {
  organizationActions,
  organizationSelectors,
} from 'src/app/version-2/modules/organization/organization.slice';
import { getCompleteFeeByDeliveryType } from 'src/app/version-2/utils/fee.utils';
import { FeeVariantsEnum } from 'src/app/version-2/model/enums/feeVariants.enum';
import { getOrgFeeFundingSource } from 'src/app/redux/organization/selectors';

enum checks {
  twoLeft = 2,
  oneLeft = 1,
  noneLeft = 0,
}

type PaymentRegularFeeCheckProps = {
  textValues: Record<string, any>;
  hasPromotions: boolean;
  isEdit: boolean | undefined;
};

const PaymentRegularFeeCheck = ({
  textValues,
  hasPromotions,
  isEdit,
}: PaymentRegularFeeCheckProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    hasPromotions && dispatch(organizationActions.fetchFreeChecks({ isBatch: false }));
  }, [hasPromotions]);
  const isRecurring = useSelector(getIsRecurring);
  const totalFreeChecks = useSelector(organizationSelectors.selectTotalFreeChecks);
  const availableFreeChecks = useSelector(organizationSelectors.selectAvailableFreeChecks);
  const catalog = useSelector((state) => state.organization.feeCatalog);
  const catalogFee = getCompleteFeeByDeliveryType({
    fees: catalog,
    deliveryType: FeeVariantsEnum.ACH_TO_CHECK,
  });

  const renderFreeCheckText = (totalFreeChecks, availableFreeChecks) => {
    const designProps = { color: 'ds.gray.200', textStyle: 'ds.body3', pt: '0.5rem' };

    if (isRecurring) {
      let text = '';

      if (availableFreeChecks === checks.twoLeft)
        text = 'bills.pay.confirm.checkFee.recurring.twoLeft';
      else if (availableFreeChecks === checks.oneLeft)
        text = 'bills.pay.confirm.checkFee.recurring.oneLeft';
      else if (availableFreeChecks === checks.noneLeft)
        text = 'bills.pay.confirm.checkFee.recurring.noneLeft';

      if (text)
        return (
          <Box {...designProps}>
            <MIFormattedText label={text} values={{ fee: catalogFee }} />
          </Box>
        );

      return null;
    }

    if (!isEdit && availableFreeChecks) {
      return (
        <Box {...designProps}>
          <MIFormattedText
            label="bills.pay.confirm.checkFee.promotionsLeft"
            values={{ available: availableFreeChecks, total: totalFreeChecks }}
          />
        </Box>
      );
    }

    return null;
  };
  const feeFundingSource = useSelector(getOrgFeeFundingSource);

  const feeChargeText = useMemo(() => {
    if (feeFundingSource) return 'bills.pay.confirm.checkFee.firmFee';

    return `bills.pay.confirm.checkFee.${textValues.finalAmount ? 'feeCharge' : 'noCharge'}`;
  }, [textValues, feeFundingSource]);

  return (
    <>
      <Box pb="ds.lg" color="ds.gray.100">
        <FeeItem $freeChecks={totalFreeChecks}>
          <MIFormattedText label="bills.pay.confirm.checkFee.title" />
          <MIFormattedCurrency value={textValues.finalAmount || ''} />
        </FeeItem>

        {!!totalFreeChecks && renderFreeCheckText(totalFreeChecks, availableFreeChecks)}
      </Box>

      <TotalCheckFeeContainer>
        <FeeItem>
          <Box color="ds.gray.100">
            <MIFormattedText label="bills.pay.confirm.totalFee" values={textValues} />
            <Box textStyle="ds.body3" mt="0.5rem" color="ds.gray.200">
              <MIFormattedText label={feeChargeText} />
            </Box>
          </Box>
          <StyledMIMoney amount={textValues.finalAmount} />
        </FeeItem>
      </TotalCheckFeeContainer>
    </>
  );
};

export { PaymentRegularFeeCheck };

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const FeeItem = styled.div<{ total?: number }>`
  margin-top: 2rem;
  font-size: ${(props) =>
    props.total ? props.theme.text.size.subTitle : props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.regular};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TotalFeeContainer = styled.div`
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
`;

const TotalCheckFeeContainer = styled(TotalFeeContainer)`
  font-size: 2rem;
`;
