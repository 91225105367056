import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, Menu, SmartIcon, Text, Tooltip } from '@melio/billpay-design-system';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourceItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  PaymentMethodButton,
  PaymentMethodItem,
} from 'src/app/version-2/pages/batch-bulk/renderers/PaymentMethod/components';
import {
  MenuButtonContainer,
  MenuEmptyListButton,
} from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';
import { menuListStyles } from './PaymentMethodMenu.styles';

import styled, { css } from 'styled-components';

export interface RenderersType {
  PaymentMethodMenuButton?: React.FC;
  PaymentMethodButton?: React.FC;
  PaymentFundingSourceImage?: React.FC;
  DateSelection?: React.FC;
}

export interface PaymentMethodMenuProps {
  selectedPaymentMethod?: FundingSource;
  amex?: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  openAmexVerificationModal?: () => void;
  isAmexVerificationMissing?: boolean;
  AmexPopover?: React.ReactElement;
  paymentMethods: FundingSourceItem[] | undefined;
  onCreateFundingSource: (e) => void;
  onMethodSelect: (fundingSource: FundingSource) => void;
  emptyText?: string;
  multipleText?: string;
  bulkSelect?: boolean;
  renderers?: RenderersType;
  showTooltip?: boolean;
  checkIfDisabled: (fs: FundingSourceItem) => boolean;
}
interface renderMenuItemProps {
  fundingSource: FundingSource;
  isDisabled: boolean;
}

export const PaymentMethodMenu = ({
  onMenuOpen,
  onMenuClose,
  onCreateFundingSource,
  onMethodSelect,
  paymentMethods = [],
  amex = false,
  AmexPopover,
  selectedPaymentMethod,
  isAmexVerificationMissing,
  emptyText,
  multipleText,
  bulkSelect = false,
  showTooltip = false,
  renderers,
  checkIfDisabled,
}: PaymentMethodMenuProps) => {
  const { formatMessage } = useIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const menuTriggerHandler = () => {
    setIsMenuOpen(!isMenuOpen);
    isMenuOpen ? onMenuOpen() : onMenuClose();
  };

  const renderMenuItem = ({ fundingSource, isDisabled }: renderMenuItemProps) => (
    <StyledMenuItem
      data-testid={`select-method-option-label-${fundingSource.id}`}
      onClick={() => !isDisabled && onMethodSelect(fundingSource)}
      key={fundingSource.id}
      closeOnSelect={!isDisabled}
      $isDisabled={isDisabled}
    >
      <PaymentMethodItem fundingSource={fundingSource} />
    </StyledMenuItem>
  );

  return (
    <Menu
      placement="bottom-start"
      onOpen={menuTriggerHandler}
      onClose={menuTriggerHandler}
      autoWidth
    >
      <MenuButtonContainer
        isWarningNotification={isAmexVerificationMissing}
        data-testid="select-method-dropdown-icon"
      >
        <Menu.MenuButton as={renderers?.PaymentMethodMenuButton}>
          {selectedPaymentMethod && (
            <PaymentMethodButton
              fundingSource={selectedPaymentMethod}
              isMenuOpen={isMenuOpen}
              renderers={{
                PaymentMethodButton: renderers?.PaymentMethodButton,
                PaymentFundingSourceImage: renderers?.PaymentFundingSourceImage,
              }}
            />
          )}
          {!paymentMethods.length && (
            <MenuEmptyListButton>
              <Text as={renderers?.PaymentMethodButton}>{emptyText}</Text>
              <SmartIcon type="arrow-dropdown" />
            </MenuEmptyListButton>
          )}
          {!!paymentMethods.length && !selectedPaymentMethod && !bulkSelect && (
            <MenuEmptyListButton>
              <Text as={renderers?.PaymentMethodButton}>{multipleText}</Text>
              <SmartIcon type="arrow-dropdown" />
            </MenuEmptyListButton>
          )}
        </Menu.MenuButton>
        {amex && isAmexVerificationMissing && AmexPopover}
      </MenuButtonContainer>
      <Menu.MenuList data-testid="payment-method-menu" {...menuListStyles}>
        <Menu.MenuItem>
          <Link onClick={onCreateFundingSource} data-testid="add-funding-source">
            {formatMessage({ id: 'batchBulkPage.renderers.fundingSource.addAnotherPaymentMethod' })}
          </Link>
        </Menu.MenuItem>

        {paymentMethods?.map((fundingSource) => {
          const isDisabled = checkIfDisabled(fundingSource);

          if (showTooltip && isDisabled) {
            return (
              <Tooltip
                key={fundingSource.id}
                label={formatMessage({
                  id: 'batchBulkPage.renderers.fundingSource.disabledTooltip',
                })}
                placement="top"
              >
                {renderMenuItem({ fundingSource, isDisabled })}
              </Tooltip>
            );
          }

          return renderMenuItem({ fundingSource, isDisabled });
        })}
      </Menu.MenuList>
    </Menu>
  );
};

const StyledMenuItem = styled(Menu.MenuItem)`
  && {
    ${(props) =>
      props.$isDisabled &&
      css`
        cursor: not-allowed;
        opacity: 0.5;
      `}
  }
`;
