import React from 'react';
import { RecordOf } from 'immutable';
import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import { PAYMENT_STATUS } from 'src/app/utils/consts';
import { BillType, DeliveryMethodType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import styled, { css } from 'styled-components';
import { MIFormattedText, MIFieldOrEmpty } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import { BillsPopover } from 'src/app/version-2/components/BillsPopover';
import MIMoney from 'src/app/components/common/MIMoney';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { FundingSource } from 'src/app/version-2/model/dtos';
import PaymentFee from '../pay/components/PaymentFee';
import ViewBillPaymentActivity from './ViewBillPaymentActivity/ViewBillPaymentActivity';
import { QBMPaymentsMadeLink } from './QBMPaymentsMadeLink';

type Props = {
  bill: RecordOf<BillType>;
  fundingSource: FundingSource;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  payment: RecordOf<PaymentType>;
  fee?: PaymentFeeInfo;
  qbCashState?: QBCashStateType;
  onPaymentsMadeClick(): void;
};

export const QBMViewBillPaymentReview = ({
  bill,
  fee,
  fundingSource,
  deliveryMethod,
  payment,
  qbCashState,
  onPaymentsMadeClick,
}: Props) => {
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const companyName = bill.vendor?.companyName || '';
  const isPaymentFailed = payment.status === PAYMENT_STATUS.FAILED;
  const isMarkedAsPaid = payment.manual;
  const isBatchBulkCombined = (bill.totalAmount || 0) < (payment.amount || 0);

  const renderInvoice = () => {
    if (isBatchBulkCombined) {
      return (
        <FieldValue>
          <BillsPopover paymentId={payment.id} />
        </FieldValue>
      );
    }

    return (
      <>
        <FieldValue data-testid="bill-invoice-number">
          <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.view.noInvoiceNumber" />
        </FieldValue>
        {isPartialPaymentsEnabled && (
          <QBMPaymentsMadeLink bill={bill} onClick={onPaymentsMadeClick} />
        )}
      </>
    );
  };

  return (
    <MainBox>
      <BaseContainer>
        <HighlightedHeader data-testid="billpay-view-bill-total">
          <SubTitle>
            <MIFormattedText label="bills.pay.confirm.total" />
          </SubTitle>
          <StyledMIMoney size="large" amount={payment.amount as number} />
        </HighlightedHeader>
      </BaseContainer>

      <PaymentInfoContainer>
        <SubTitle>
          <MIFormattedText label="bills.pay.confirm.payTo" />
        </SubTitle>
        <BoxRow>
          <BoxColumn>
            <VendorFieldName>
              <MIFormattedText label="bills.pay.confirm.vendorField" />
            </VendorFieldName>
            <FieldValue data-testid="bill-company-name">{companyName}</FieldValue>
          </BoxColumn>
          <InvoiceBoxColumn>
            <FieldName>
              <MIFormattedText label="bills.pay.confirm.invoiceNumberField" />
            </FieldName>
            {renderInvoice()}
          </InvoiceBoxColumn>
        </BoxRow>
        <SectionSeparator />
      </PaymentInfoContainer>
      {!isMarkedAsPaid && (
        <PaymentActivityContainer>
          <ViewBillPaymentActivity
            bill={bill}
            payment={payment}
            fundingSource={fundingSource}
            deliveryMethod={deliveryMethod}
            qbCashState={qbCashState}
          />
        </PaymentActivityContainer>
      )}
      {!isPaymentFailed && fee && <PaymentFee fee={fee} />}
    </MainBox>
  );
};

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.14);

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.MainBox}
`;

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

const HighlightedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.HighlightedHeader}
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const PaymentActivityContainer = styled(BaseContainer)`
  padding-bottom: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentActivityContainer}
`;

const PaymentInfoContainer = styled(BaseContainer)`
  padding-bottom: 1.7rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentInfoContainer}
`;

const BoxRow = styled.div`
  display: flex;
  margin-top: 1.8rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  padding-right: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxColumn}
`;

const InvoiceBoxColumn = styled(BoxColumn)`
  ${(props) => props.theme?.components?.BillPaymentReview?.InvoiceBoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}

  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldName}
`;

const VendorFieldName = styled(FieldName)`
  ${(props) => props.theme?.components?.BillPaymentReview?.VendorFieldName}
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldValue}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;
const SectionSeparator = styled.div`
  display: none;
  ${(props) => props.theme?.components?.BillPaymentReview?.SectionSeparator}
`;
