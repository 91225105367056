/* eslint-disable */
import React from 'react';
type Props = {
  width?: string;
  color?: string;
};

const SelectedDMSvgIcon = ({ width = '25', color = '#00D28F' }: Props) => (
  <React.Fragment>
    <svg
      width={`${width}px`}
      height={`${width}px`}
      viewBox="0 0 51 51"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none">
        <g transform="translate(-695.000000, -399.000000)" fill={color}>
          <g transform="translate(505.000000, 347.000000)">
            <g transform="translate(190.000000, 52.000000)">
              <path d="M25.5,0 C11.475,0 0,11.475 0,25.5 C0,39.525 11.475,51 25.5,51 C39.525,51 51,39.525 51,25.5 C51,11.475 39.525,0 25.5,0 Z M39.7375,18.4875 L22.7375,35.4875 C22.3125,35.9125 21.8875,36.125 21.25,36.125 C20.6125,36.125 20.1875,35.9125 19.7625,35.4875 L11.2625,26.9875 C10.4125,26.1375 10.4125,24.8625 11.2625,24.0125 C12.1125,23.1625 13.3875,23.1625 14.2375,24.0125 L21.25,31.025 L36.7625,15.5125 C37.6125,14.6625 38.8875,14.6625 39.7375,15.5125 C40.5875,16.3625 40.5875,17.6375 39.7375,18.4875 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </React.Fragment>
);

export default SelectedDMSvgIcon;
