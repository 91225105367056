import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import moment from 'moment';
import { createPdfFile } from 'src/app/utils/pdf';
import { BillType } from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import withListContainer, { Filters } from 'src/app/hoc/withListContainer';
import billsApi from 'src/app/services/api/bills';
import { AreaLoader } from '@melio/billpay-design-system';
import { getOrgId } from 'src/app/redux/user/selectors';
import BillingSettingsPage from './components/BillingSettingsPage';

type MapStateToProps = {
  orgId: string;
};

type Props = {
  filters: Filters;
} & MapStateToProps;

type State = {
  isLoading: boolean;
  internalBills: RecordOf<BillType>[];
  internalBillsTotal: number;
};

class BillingSettingsPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      internalBills: [],
      internalBillsTotal: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadInternalBills();
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    if (prevProps.filters !== filters) {
      this.loadInternalBills();
    }
  }

  onGeneratePdfInvoice = async (billId: string, invoiceDate: any) => {
    const { base64 } = await billsApi.generatePdfInvoice(this.props.orgId, billId);
    const fileName = `Melio-Receipt-${moment(invoiceDate).format('YYYY-MM-DD')}`;

    createPdfFile(base64, fileName);
  };

  loadInternalBills = async () => {
    const { orgId, filters } = this.props;

    this.setState({ isLoading: true });
    try {
      const { objects: internalBills, totalCount } = await billsApi.getInternalBills({
        orgId,
        filters,
      });

      this.setState({
        isLoading: false,
        internalBills,
        internalBillsTotal: totalCount,
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, internalBills, internalBillsTotal } = this.state;
    const { filters } = this.props;

    return (
      <>
        {isLoading && <AreaLoader />}
        {!isLoading && (
          <BillingSettingsPage
            internalBills={internalBills}
            internalBillsTotal={internalBillsTotal}
            onGeneratePdfInvoice={this.onGeneratePdfInvoice}
            filters={filters}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

export default compose(withListContainer(), connect(mapStateToProps))(BillingSettingsPageContainer);
