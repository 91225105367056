import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, Tooltip } from '@melio/billpay-design-system';
import { CellContext } from '@tanstack/react-table';
import { analytics } from 'src/app/version-2/externals';
import {
  BATCH_BULK_EVENT_PAGE,
  BULK_GROUPING_CLICK,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';

const BillRenderer: FC<CellContext<BatchBulkItem, string>> = (props) => {
  const {
    row,
    row: {
      original: { invoiceNumber },
    },
    column,
  } = props;

  const ref = useRef<any>(null);
  const { formatMessage } = useIntl();
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

  const isSubRow = row.depth;
  const isOriginalRow = !row.subRows.length;

  const handleExpandToggle = useCallback(() => {
    const expandHandler = row.getToggleExpandedHandler();

    expandHandler();
    analytics.track(BATCH_BULK_EVENT_PAGE, BULK_GROUPING_CLICK, {
      groupingState: row.getIsExpanded() ? 'collapse' : 'expand', // it's reversed since we are just changing the state now
      vendorId: row.original.vendorId,
    });
  }, [row]);

  useLayoutEffect(() => {
    if (column.getSize() < ref?.current?.offsetWidth) {
      setIsShowTooltip(true);
    }
  }, [column]);

  const content = (
    <span ref={ref} data-testid="bill-id">
      {invoiceNumber}
    </span>
  );

  if (isOriginalRow || isSubRow) {
    if (isShowTooltip) {
      return (
        <Tooltip label={invoiceNumber} placement="top-start">
          {content}
        </Tooltip>
      );
    }

    return content;
  }

  if (row.getIsExpanded()) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link onClick={handleExpandToggle} style={{ cursor: 'pointer' }}>
        {formatMessage(
          { id: 'batchBulkPage.renderers.bill.hide' },
          { billsNum: row.subRows.length }
        )}
      </Link>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link onClick={handleExpandToggle} style={{ cursor: 'pointer' }}>
      {formatMessage({ id: 'batchBulkPage.renderers.bill.show' }, { billsNum: row.subRows.length })}
    </Link>
  );
};

export { BillRenderer };
