const LOCATIONS = {
  base: [
    '/vendors/expedite/delivery-methods/:token',
    '/vendors/expedite/select-delivery-method/:token',
    '/vendors/expedite/check/select-delivery-method/:token',
    '/vendors/:id/expedite/select-delivery-method/:token/*',
  ],
  index: '/vendors/expedite/delivery-methods/:token',
  selectDeliveryMethod: '/vendors/expedite/select-delivery-method/:token',
  shiftCheckDeliveryMethod: '/vendors/expedite/check/select-delivery-method/:token',
  invalidToken: '/vendors/expedite/select-delivery-method/:token/invalid-link',
  paymentCanceled: '/vendors/:id/expedite/select-delivery-method/:token/payment-canceled',
  virtualCardSuccess: '/vendors/expedite/select-delivery-method/:token/virtual-card-success',
};

export default LOCATIONS;
