import * as React from 'react';
import Page from '../../../components/layout/Page';
import MIButton from '../../../components/common/MIButton';
import { loggingApi } from 'src/app/version-2/api/loggers';
//TODO - alternative for sentry report!
const ErrorPage = () => (
  <Page title="error.title">
    <p>We are sorry — something has gone wrong.</p>
    <p>Our team has been notified, but click here fill out a report.</p>
    <MIButton
      label="error.report"
      onClick={() => loggingApi.error('ErrorPage.reportClick(): reporting error:')}
    />
  </Page>
);

ErrorPage.defaultProps = {};

export default ErrorPage;
