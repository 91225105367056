import { useState } from 'react';
import analytics from 'src/app/services/analytics';

type useCreditCardSourcesListReturnValue = {
  shouldShowCreditCardSourcesListModal: boolean;
  onCreditCardSourcesListClicked: () => void;
  onCloseCreditCardSourcesListModal: () => void;
};

export const useCreditCardSourcesList = (): useCreditCardSourcesListReturnValue => {
  const [shouldShowCreditCardSourcesListModal, setShouldShowCreditCardSourcesListModal] =
    useState<boolean>(false);

  const onCreditCardSourcesListClicked = () => {
    setShouldShowCreditCardSourcesListModal(true);
    analytics.trackAction('credit-card-sources-list-click', {
      shouldShowCreditCardSourcesListModal,
    });
  };

  const onCloseCreditCardSourcesListModal = () => setShouldShowCreditCardSourcesListModal(false);

  return {
    shouldShowCreditCardSourcesListModal,
    onCreditCardSourcesListClicked,
    onCloseCreditCardSourcesListModal,
  };
};
