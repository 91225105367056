import { featureFlags } from '@melio/shared-web';
import { DELIVERY_TYPE, DELIVERY_METHOD_ORIGIN } from 'src/app/utils/consts';
import { DeliveryMethodType } from 'src/app/utils/types';
import locations from 'src/app/utils/locations';

export const useEditDeliveryMethod = (
  deliveryMethod: DeliveryMethodType,
  deliveryMethodOrigin?: DELIVERY_METHOD_ORIGIN
): string => {
  // TODO: Denis - local env doesn't work as expected, need to investigate it (Edit link not showing)
  const [showEditDeliveryMethodForSingleBillPayFlow] =
    featureFlags.useFeature('bp-edit-delivery-method');
  const [showEditDeliveryMethodForJustPayFlow] = featureFlags.useFeature(
    'bp-edit-delivery-method-just-pay-flow'
  );
  let path = '';

  if (
    !deliveryMethod ||
    !showEditDeliveryMethodForSingleBillPayFlow ||
    (deliveryMethodOrigin === DELIVERY_METHOD_ORIGIN.JUST_PAY &&
      !showEditDeliveryMethodForJustPayFlow)
  )
    return path;

  if (deliveryMethod?.deliveryType === DELIVERY_TYPE.ACH && deliveryMethod?.bankAccount) {
    path = locations.Vendors.deliveryMethods.ach.edit.url();
  }

  if (deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK && deliveryMethod?.paperCheck) {
    path = locations.Vendors.deliveryMethods.check.edit.url();
  }

  return path;
};
