import React from 'react';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import { PaymentType } from 'src/app/utils/types';

type CompletedRefundMessageProps = { payment: PaymentType };

export const CompletedRefundMessage = ({ payment }: CompletedRefundMessageProps) => {
  const achTraceNumber = payment.metadata?.achTraceNumber;
  const completedRefundSubtitle = {
    label: 'bills.form.paymentActivity.completedPaymentDescription',
    values: {
      traceNumber: achTraceNumber,
    },
  };

  return (
    <QBONotificationCard
      type={NOTIFICATION_CARD_TYPES.INFO}
      subtitle={completedRefundSubtitle}
      showIcon={false}
    />
  );
};
