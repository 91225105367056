import { BankAccountEnum, DeliveryEnum } from 'src/app/version-2/model/enums';
import { createAchDeliveryMethodHandler } from './createAchDeliveryMethod.saga';
import { navigateOnAchDeliveryMethodSavedHandler } from './navigateOnAchDeliveryMethodSaved.saga';
import { updateAchDeliveryMethodHandler } from './updateAchDeliveryMethod.saga';
import {
  achDeliveryMethodDetailsActions,
  achDeliveryMethodDetailsSelectors,
} from '../achDeliveryMethodDetails.slice';

import { put, select } from 'redux-saga/effects';

export function* submitAchDeliveryMethodHandler(
  action: ReturnType<typeof achDeliveryMethodDetailsActions.submitAchDeliveryMethod>
) {
  yield put(achDeliveryMethodDetailsActions.setIsSubmitting(true));

  const {
    bankAccount: bankAccountDataToSubmit,
    vendorId,
    deliveryMethodId,
    history,
  } = action.payload;

  const currentBankAccount = yield select(achDeliveryMethodDetailsSelectors.selectBankAccount);

  if (
    bankAccountDataToSubmit.routingNumber === currentBankAccount?.routingNumber &&
    bankAccountDataToSubmit.accountNumber === currentBankAccount?.accountNumber
  ) {
    yield navigateOnAchDeliveryMethodSavedHandler({
      vendorId,
      history,
    });

    yield put(achDeliveryMethodDetailsActions.setIsSubmitting(false));

    return;
  }

  const deliveryMethodSubmitData = {
    deliveryType: DeliveryEnum.ACH,
    bankAccount: {
      accountType: BankAccountEnum.CHECKING,
      routingNumber: bankAccountDataToSubmit.routingNumber,
      accountNumber: bankAccountDataToSubmit.accountNumber,
    },
  };

  if (deliveryMethodId) {
    yield updateAchDeliveryMethodHandler({
      deliveryMethod: deliveryMethodSubmitData,
      vendorId,
      deliveryMethodId,
      history,
    });

    return;
  }

  yield createAchDeliveryMethodHandler({
    deliveryMethod: deliveryMethodSubmitData,
    vendorId,
    history,
  });
}
