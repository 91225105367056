import { FieldType } from 'src/app/utils/types';
import curry from 'lodash/curry';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';

export const enum RESOLUTIONS {
  SUCCESS = 'success',
  SUPPORT = 'support',
  NOT_FOUND = 'not_found',
}

export type State = {
  amount1: string;
  amount2: string;
  resolution?: RESOLUTIONS;
  serverError?: string;
  validationErrors: {
    amount1?: string;
    amount2?: string;
  };
};

export type MicroDepositState = {
  isLoading?: boolean;
  companyName?: string;
} & State;

export type MicroDepositProps = {
  onSuccess?: () => void;
  onBlocked?: () => void;
  onInvalidToken?: () => void;
};

export type MicroDepositActions = {
  onChange: (FieldType) => void;
  onSubmit: () => Promise<void>;
  dismiss: () => void;
};

export const validateInputs = (state: State, setState: (state: State) => void) => {
  const { amount1, amount2 } = state;
  const cleanAmount1 = convertCurrencyToNumber(amount1);
  const cleanAmount2 = convertCurrencyToNumber(amount2);
  const validationErrors = getValidationErrors('microDepositsVerification', {
    amount1: cleanAmount1,
    amount2: cleanAmount2,
  });

  setState({ ...state, validationErrors, serverError: undefined });

  return [isValidationOk(validationErrors), validationErrors];
};

export const parseErrorToState = (
  error: any,
  errorToResolutionMap: Record<string, any>,
  errVerifyMicroDeposit: string
) => {
  const errorCode = error?.code || '';

  const resolution = errorToResolutionMap[errorCode];

  if (resolution) {
    return {
      resolution,
    };
  }

  let serverError = `server.${errorCode}`;

  if (errorCode === errVerifyMicroDeposit) {
    serverError = 'inputErrors.microDepositsVerification.invalidAmount';
  }

  return {
    serverError,
    validationErrors: {
      amount1: '',
      amount2: '',
    },
  };
};

export const dismiss = (setState: (state: State) => void) => {
  setState({
    amount1: '',
    amount2: '',
    validationErrors: {},
    resolution: undefined,
  });
};

export const onChange = curry(
  (state: State, setState: (state: State) => void, { id, value }: FieldType) => {
    setState({
      ...state,
      [id]: value,
      validationErrors: {
        ...state.validationErrors,
        [id]: undefined,
      },
    });
  }
);
