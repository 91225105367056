import React from 'react';
import styled, { css } from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import MIMoney from 'src/app/components/common/MIMoney';
import { getBillBalance } from 'src/app/utils/bills';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { BillType } from 'src/app/utils/types';
import PartialPaymentsInfo from './PartialPaymentsInfo';

type Props = {
  isPartialPayments: boolean;
  currentPaymentId?: string;
  bill: BillType;
  onLabelClick?: (paymentId, status) => void;
};

const BillOpenBalanceInfo = ({
  bill,
  isPartialPayments,
  currentPaymentId,
  onLabelClick,
}: Props) => {
  const openBalance = getBillBalance(bill, []) || 0;
  const totalAmount = Number(convertCurrencyToNumber(bill.totalAmount));

  return (
    <BillOpenBalanceContainer>
      <OpenBalanceContainer>
        <MIFormattedText label="bills.form.partialPayments.openBalance" />
        <MIMoney amount={isPartialPayments ? openBalance : totalAmount} />
      </OpenBalanceContainer>
      {isPartialPayments && (
        <PartialPaymentsContainer>
          <Separator />
          <PartialPaymentsInfoBlock
            bill={bill}
            onLabelClick={onLabelClick}
            currentPaymentId={currentPaymentId}
          />
          <TotalAmountContainer>
            <MIFormattedText label="bills.form.partialPayments.total" />
            <MIFormattedCurrency value={totalAmount} />
          </TotalAmountContainer>
        </PartialPaymentsContainer>
      )}
    </BillOpenBalanceContainer>
  );
};

const PartialPaymentsInfoBlock = styled(PartialPaymentsInfo)`
  ${({ theme }) => theme?.components?.BillOpenBalance?.PartialPaymentsInfoBlock}
`;

const BillOpenBalanceContainer = styled.div`
  padding: 3.5rem 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.text.color.main};
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  border-bottom-right-radius: 0.9rem;
  border-bottom-left-radius: 0.9rem;
  @media ${devices.mobile} {
    padding: 1.6rem 1.6rem 2rem 1.6rem;
    box-sizing: border-box;
  }

  ${(props) => props.theme?.components?.BillOpenBalance?.BillOpenBalanceContainer}
`;

const containerBaseStyles = css`
  display: flex;
  justify-content: space-between;
`;

const OpenBalanceContainer = styled.div`
  ${containerBaseStyles};
  letter-spacing: 0.038rem;
  font-size: 2.3rem;
  width: 100%;

  ${({ theme }) => theme?.components?.BillOpenBalance?.OpenBalanceContainer}
`;

const TotalAmountContainer = styled.div`
  ${containerBaseStyles};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.regular};
  line-height: 1.6rem;

  > span {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }

  ${({ theme }) => theme?.components?.BillOpenBalance?.TotalAmountContainer}
`;

const PartialPaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2.6rem 0 0.8rem;

  ${({ theme }) => theme?.components?.BillOpenBalance?.PartialPaymentsContainer}
`;

const Separator = styled.div`
  height: 0.1rem;
  background-color: #eceef1;
  margin-bottom: 2.4rem;

  ${({ theme }) => theme?.components?.BillOpenBalance?.Separator}
`;

export default BillOpenBalanceInfo;
