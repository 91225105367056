import React from 'react';
import { RecordOf } from 'immutable';
import { MultiLineSelectBox, SmartIcon } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { DeliveryMethodType } from 'src/app/utils/types';

type Props = {
  onClick: () => void;
  vendorName: string;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

export const VirtualCardDeliveryMethodOption = ({ onClick, vendorName, deliveryMethod }: Props) => (
  <MultiLineSelectBox onClick={onClick}>
    <MultiLineSelectBox.TopContentWrapper>
      <SmartIcon type="mastercard" />
      <MultiLineSelectBox.HeadingWrapper>
        <MultiLineSelectBox.Title>
          <MIFormattedText label="bills.pay.virtualCardRecovery.virtualCard.title" />
        </MultiLineSelectBox.Title>
        <MultiLineSelectBox.Description>
          <MIFormattedText label="bills.pay.virtualCardRecovery.virtualCard.description" />
        </MultiLineSelectBox.Description>
      </MultiLineSelectBox.HeadingWrapper>
    </MultiLineSelectBox.TopContentWrapper>

    <MultiLineSelectBox.Separator />

    <MultiLineSelectBox.Info>{deliveryMethod?.getDeliveryInfo(vendorName)}</MultiLineSelectBox.Info>
  </MultiLineSelectBox>
);
