import React from 'react';
import { Box } from '@melio/billpay-design-system';

import { ReactComponent as CheckMark } from 'src/app/images/qbo/dashboard/check-mark-icon.svg';
import { MIFormattedText } from 'src/app/utils/formatting';

const Item = ({ label }: { label: string }) => (
  <Box textStyle="body3" fontWeight="600" mb="1rem">
    <Box as="span" pr="1.6rem">
      <CheckMark />
    </Box>
    <MIFormattedText label={label} />
  </Box>
);

export default Item;
