export const getPosition = (
  relElm: DOMRect | undefined,
  myStepElm: DOMRect | undefined,
  positionKey: string
) => {
  if (relElm && myStepElm) {
    const elmTop: number = relElm?.top;
    const elmBottom: number = relElm?.bottom;
    const elmRight: number = relElm?.right;
    const elmLeft: number = relElm?.left;
    const elmWidth: number = relElm?.width;
    const elmHeight: number = relElm?.height;
    const currentStepHeight: number = myStepElm.height;
    const currentStepwidth: number = myStepElm.width;
    const position = {
      'top-start': { left: elmLeft - 6, top: elmTop - 17 - currentStepHeight },
      top: {
        left: 0.5 * elmWidth + elmLeft - currentStepwidth * 0.5,
        top: elmTop - 17 - currentStepHeight,
      },
      'top-end': { left: elmRight + 6 - currentStepwidth, top: elmTop - 17 - currentStepHeight },
      'bottom-start': { left: elmLeft - 6, top: elmBottom + 17 },
      bottom: { left: 0.5 * elmWidth + elmLeft - currentStepwidth * 0.5, top: elmBottom + 17 },
      'bottom-end': { left: elmRight + 6 - currentStepwidth, top: elmBottom + 17 },
      'left-start': { left: elmLeft - 17 - currentStepwidth, top: elmTop - 6 },
      left: {
        left: elmLeft - 17 - currentStepwidth,
        top: 0.5 * elmHeight + elmTop - currentStepHeight * 0.5,
      },
      'left-end': { left: elmLeft - 17 - currentStepwidth, top: elmBottom + 6 - currentStepHeight },
      'right-start': { left: elmRight + 17, top: elmTop - 6 },
      right: { left: elmRight + 17, top: 0.5 * elmHeight + elmTop - 0.5 * currentStepHeight },
      'right-end': { left: elmRight + 17, top: elmBottom + 6 - currentStepHeight },
    };

    return position[positionKey];
  }

  return {};
};
