import * as React from 'react';
import styled, { css } from 'styled-components';
import { BillPageType } from 'src/app/utils/types';
import { BILL_PAGE_TYPE } from 'src/app/utils/consts';
import { AreaLoader } from '@melio/billpay-design-system';
import { FileUploader } from '@melio/billpay-design-system';
import UploadAttachment from '../common/UploadAttachment';
import { MIFormattedText } from '../../utils/formatting';
import addFile from '../../images/general/add-file.svg';
import analytics from 'src/app/services/analytics';
import { devices } from '../../theme/AppDevices';

type Props = {
  onSelectFile: (file: File) => void;
  onRetry: () => void;
  fileName: string;
  isFileUploading: boolean;
  isFileUploadError: boolean;
  onChangeInvoiceFile: (file: File) => void;
  onDeleteInvoiceFile: () => void;
  pageType?: BillPageType;
  eventPage?: string;
  margin?: string;
  eventProperties?: Record<string, any>;
};

const Upload10KInvoiceFileLayout = ({
  onSelectFile,
  fileName,
  isFileUploadError,
  isFileUploading,
  onDeleteInvoiceFile,
  onChangeInvoiceFile,
  onRetry,
  pageType,
  eventPage,
  margin,
  eventProperties,
}: Props) => {
  const onChangeInvoiceFileClicked = ({
    currentTarget: target,
  }: React.SyntheticEvent<HTMLInputElement>) => {
    if (target.files && target.files.length > 0) {
      onChangeInvoiceFile(target.files[0]);
      target.value = '';
    }
  };

  const renderFileActionButtons = () => {
    if (isFileUploadError) {
      return (
        <InvoiceFileAction onClick={onRetry}>
          <MIFormattedText label="bills.form.attachment.retry" />
        </InvoiceFileAction>
      );
    }

    return (
      <UploadAttachment
        uploadLabel="bills.form.attachment.replace"
        deleteLabel="bills.form.attachment.delete"
        onChangeAttachment={onChangeInvoiceFileClicked}
        onDeleteAttachment={onDeleteInvoiceFile}
        isShowDeleteButton
        accept="image/png, image/jpeg, application/pdf"
      />
    );
  };

  const renderUploadInvoiceFile = () => {
    const icon = <img src={addFile} alt="Upload file" />;

    if (isFileUploading) {
      return <AreaLoader />;
    }

    const trackEvent = (event: string) => {
      const currentEventPage = eventPage || '10k-invoice-file-upload';

      analytics.track(currentEventPage, event, eventProperties);
    };

    if (!fileName && !isFileUploadError) {
      return (
        <FileUploader
          onSelectFile={onSelectFile}
          changeInputFile={() => trackEvent('file-selected')}
          dragAndDropClick={() => trackEvent('import-from-file-button')}
          allowedTypes={['image/png', 'image/jpeg', 'application/pdf']}
        >
          <FileUploadContainer>
            <IconWrapper>{icon}</IconWrapper>

            <LabelWrapper>
              <MIFormattedText label="bills.pay.expedite.dragAndDrop" />
            </LabelWrapper>
          </FileUploadContainer>
        </FileUploader>
      );
    }

    return (
      <FileContainer>
        <FileName isFileUploadError={isFileUploadError}>
          {isFileUploadError && <MIFormattedText label="bills.form.attachment.error" />}
          {fileName}
        </FileName>
        <FileButtonsContainer error={isFileUploadError}>
          {renderFileActionButtons()}
        </FileButtonsContainer>
      </FileContainer>
    );
  };

  const getUploadAreaMargin = (pageType, margin) => {
    if (margin) return margin;

    return pageType === BILL_PAGE_TYPE.CREATE ? '2rem auto 4rem' : '2rem auto';
  };

  return (
    <UploadArea error={isFileUploadError} margin={getUploadAreaMargin(pageType, margin)}>
      {renderUploadInvoiceFile()}
    </UploadArea>
  );
};

const UploadArea = styled.div<{ error?: boolean; margin: string }>`
  height: 5rem;
  border: 0.1rem dashed
    ${(props) => (props.error ? props.theme.text.color.red : props.theme.text.color.label)};
  border-radius: 0.8rem;
  max-width: 45rem;
  width: 100%;
  position: relative;
  margin: ${(props) => props.margin};
  ${(p) =>
    !p.error &&
    css`
      &:hover {
        border: 0.1rem dashed ${(props) => props.theme.colors.brand};
      }
    `};

  #actionContainer {
    width: 100%;
  }
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.3rem 2rem;

  @media ${devices.mobile} {
    padding: 1.3rem 1rem;
  }
`;

const FileName = styled.div<{ isFileUploadError?: boolean }>`
  display: flex;
  flex: 1;
  color: ${(props) =>
    props.isFileUploadError ? props.theme.text.color.red : props.theme.colors.brand};
  letter-spacing: -0.02rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${(props) => props.theme.text.fontType.regular};

  @media ${devices.mobile} {
    width: 15rem;
  }
`;

const InvoiceFileAction = styled.div`
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 2.5rem;
  text-transform: uppercase;
  position: relative;
`;
const FileButtonsContainer = styled.div<{ error?: boolean }>`
  margin-right: ${(props) => (props.error ? '0' : '1.5rem')};
  cursor: pointer;
`;

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const IconWrapper = styled.div`
  img {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 2rem;
  }
`;

const LabelWrapper = styled.span`
  margin: 0;
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.regular};
`;

export default Upload10KInvoiceFileLayout;
