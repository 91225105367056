import isMobile from 'ismobilejs';
import { featureFlags } from '@melio/shared-web';

import analytics from 'src/app/services/analytics';
import { supportApi } from 'src/app/services/api/support';
import qbIcon from 'src/app/images/icons/qb-icon.png';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { envApi } from 'src/app/version-2/api/env';

import { isLoginFromForest } from '../utils/user-utils';

let isAndroidQBM = false;
const url = new URL(window.location.href);
const deviceInfo = isMobile(window.navigator);
const siteConfigParam = url.searchParams.get('siteConfig');
const { widgetApiKey } = envApi.getConfig().services.zendesk;

declare global {
  interface Window {
    zESettings: any;
    $zopim: any;
    intercomSettings: any;
    Intercom: any;
    Forethought: any;
    zE: (
      instance: 'messenger' | 'messenger:on',
      action: 'open' | 'hide' | 'close' | 'loginUser',
      callback?: (data?: any) => void
    ) => void;
  }
}

enum ChatOption {
  Zendesk,
  ZendeskMessaging,
}

if (siteConfigParam === 'qbm' && deviceInfo?.android?.phone) {
  isAndroidQBM = true;
}

const zendeskService = {
  chatOption: ChatOption.Zendesk,
  attempts: 8,
  enabled: true,
  forethoughtInjected: false,
  zendeskInjected: false,
  zendeskMessagingInjected: false,

  resolveChatOption() {
    const isZendeskMessagingEnabled = featureFlags.defaultClient.getVariant(
      FeatureFlagsEnum.ZENDESK_MESSAGING,
      false
    );

    return isZendeskMessagingEnabled ? ChatOption.ZendeskMessaging : ChatOption.Zendesk;
  },

  setChat({ isLoggedInAs, user, canContactSupport }) {
    this.chatOption = this.resolveChatOption();

    switch (this.chatOption) {
      case ChatOption.ZendeskMessaging:
        this.injectZendeskMessaging({ user, isLoggedInAs, canContactSupport });
        break;
      case ChatOption.Zendesk:
      default:
        this.injectZendesk({ user, isLoggedInAs, canContactSupport });
    }
  },

  injectZendesk(params) {
    // script already loaded
    if (this.zendeskInjected) return;

    const script = document.createElement('script');

    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetApiKey}`;
    script.id = 'ze-snippet';

    // handle zendesk init
    script.onload = () => this.initZendesk(params);

    // avoid reinject the script in the future
    this.zendeskInjected = true;

    document.head.insertAdjacentHTML(
      'beforeend',
      `<style>iframe#launcher { display: none; }</style>`
    );

    // remove full screen button from chat
    window.zESettings = {
      webWidget: {
        navigation: {
          popoutButton: {
            enabled: false,
          },
        },
      },
    };

    document.body.appendChild(script);
  },
  injectZendeskMessaging(params) {
    // script already loaded
    if (this.zendeskMessagingInjected) return;

    const script = document.createElement('script');

    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetApiKey}`;
    script.id = 'ze-snippet';

    // handle zendesk init
    script.onload = () => this.initZendeskMessaging(params);

    // avoid reinject the script in the future
    this.zendeskMessagingInjected = true;

    document.head.insertAdjacentHTML(
      'beforeend',
      `<style>iframe#launcher { display: none; }</style>`
    );

    // remove full screen button from chat
    window.zESettings = {
      webWidget: {
        navigation: {
          popoutButton: {
            enabled: false,
          },
        },
      },
    };

    document.body.appendChild(script);
  },

  initZendesk({ user, isLoggedInAs, canContactSupport }) {
    const isDisabled = isLoginFromForest() || isLoggedInAs || !canContactSupport;
    const zendesk = window.$zopim;

    if (!zendesk && this.attempts > 0) {
      // waiting for zendesk object
      this.attempts -= 1;

      setTimeout(() => this.initZendesk({ user, isLoggedInAs, canContactSupport }), 500);

      return;
    }

    if (!zendesk || isDisabled) {
      // chat is not available
      this.enabled = false;

      // hide widget floating button (for isDisabled case)
      if (zendesk) zendesk?.livechat?.button?.hide();

      // chat is not allowed for this user or loading failed
      analytics.track('pay-bill', 'zendesk-open-failed');

      return;
    }

    if (!user?.id || !user?.orgId || !user?.email) {
      analytics.track('pay-bill', 'zendesk-empty-user');
    }

    // save user data for chat session
    this.setData(user);

    // update design for QBO chat
    this.setDesign();

    // inject forethought script to extend chat
    this.injectForethought(user);
  },
  initZendeskMessaging({ user, isLoggedInAs, canContactSupport }) {
    const isDisabled = isLoginFromForest() || isLoggedInAs || !canContactSupport;
    const zendesk = window?.zE;

    if (!zendesk && this.attempts > 0) {
      // waiting for zendesk object
      this.attempts -= 1;

      setTimeout(() => this.initZendeskMessaging({ user, isLoggedInAs, canContactSupport }), 500);

      return;
    }

    if (!zendesk || isDisabled) {
      // chat is not available
      this.enabled = false;

      // chat is not allowed for this user or loading failed
      analytics.track('pay-bill', 'zendesk-open-failed');

      return;
    }

    if (!user?.id || !user?.orgId || !user?.email) {
      analytics.track('pay-bill', 'zendesk-empty-user');
    }

    const shouldAuthenticateUser = featureFlags.defaultClient.getVariant(
      FeatureFlagsEnum.ZENDESK_MESSAGING_AUTHENTICATION,
      false
    );

    if (shouldAuthenticateUser) {
      zendesk('messenger:on', 'open', () => {
        this.logUserIntoZendesk();
      });

      // always start with closed state
      // to login user when they open the chat
      zendesk('messenger', 'close');
    }
  },

  async getZendeskToken() {
    const { token } = await supportApi.getZendeskToken();

    return token;
  },

  logUserIntoZendesk() {
    const zendesk = window.zE;

    if (!zendesk) {
      analytics.track('pay-bill', 'zendesk-login-user-error', { error: 'zendesk not defined' });

      return;
    }

    zendesk('messenger', 'loginUser', async (callback: (token: string) => void) => {
      try {
        // zendesk reruns this function on 401 (e.g. when the token expires during chatting),
        // so adding the new token getting here to handle the token expiration
        const zendeskMessagingToken = await this.getZendeskToken();

        callback(zendeskMessagingToken);
      } catch (error) {
        analytics.track('pay-bill', 'zendesk-login-user-error', { error });
      }
    });
  },

  setData(user) {
    try {
      // sending user data to support
      const zendesk = window.$zopim;
      const { email, firstName, lastName, orgId, id, registrationFlow } = user;

      zendesk(() => {
        const { livechat } = zendesk;

        livechat.setName(`${firstName} ${lastName}`);
        livechat.setEmail(email);

        // custom tags
        livechat.addTags(`orgId-${orgId}`);
        livechat.addTags(`userId-${id}`);

        if (registrationFlow) livechat.addTags(registrationFlow);
      });
    } catch (error) {
      // disable chat if cannot send user data
      this.enabled = false;

      analytics.track('pay-bill', 'zendesk-set-data-error', {
        error,
        user: {
          userId: user?.id,
          registrationFlow: user?.registrationFlow,
          orgId: user?.orgId,
        },
      });
    }
  },

  setDesign() {
    try {
      const { livechat } = window.$zopim;

      // change chat UI for QBO
      livechat.window.setTitle('Bill Pay Support');
      livechat.concierge.setAvatar(qbIcon);

      // hide widget floating button by default (we also hide button with css, located in snippet)
      livechat.button.hide();

      livechat.window.onHide(() => {
        // zendesk is showing the floating button again when closing the chat
        // force hide it again after closing
        livechat.button.hide();
      });
    } catch (error) {
      // disable chat if cannot send user data
      this.enabled = false;
    }
  },

  injectForethought(user) {
    const { email, firstName, lastName } = user;

    // script already loaded
    if (this.forethoughtInjected) return;

    const script = document.createElement('script');

    script.src = 'https://solve-widget.forethought.ai/embed.js';
    script.type = 'application/javascript';
    script.setAttribute('data-api-key', '74ba8f52-1c1e-4b60-8b1e-bc74bcdd4a0d');
    script.setAttribute('data-ft-current_page_url', 'window.location.href');
    script.setAttribute('data-ft-verbose', 'false');
    script.setAttribute('data-ft-name', `${firstName} ${lastName}`);
    script.setAttribute('data-ft-email', email);
    script.setAttribute('data-ft-workflow', 'Melio Routing');
    script.setAttribute('data-ft-tag', '');
    script.setAttribute('data-ft-tag_2', '');
    script.setAttribute('data-ft-dummy', '');

    // hide forethought UI
    script.onload = () => window?.Forethought?.('widget', 'hide');

    // hiding forethougt button after closing its window
    window.addEventListener('message', (e) => {
      if (e.data.event === 'forethoughtWidgetClosed') {
        window.Forethought('widget', 'hide');
      }
    });

    // avoid reinject the script in the future
    this.forethoughtInjected = true;

    document.body.appendChild(script);
  },

  show() {
    switch (this.chatOption) {
      case ChatOption.ZendeskMessaging:
        this.showZendeskMessaging();
        break;
      case ChatOption.Zendesk:
      default:
        this.showZendesk();
    }
  },
  showZendesk() {
    if (!this.enabled || !window?.$zopim || !window?.Forethought) {
      // chat is not allowed for this user or loading failed
      analytics.track('pay-bill', 'zendesk-open-failed');

      return;
    }

    const { livechat } = window.$zopim;
    const forethought = window.Forethought;

    if (isAndroidQBM || livechat.isChatting()) {
      // qbm android shows zendesk right away - forethought is not working there
      // show zendesk chat if there is an active session
      livechat.window.show();
      analytics.track('pay-bill', 'zendesk-qbm-android-open-success');
    } else {
      // showing forethought at start - they will open zendesk in their flow
      // we first open it and then show to avoid floating button in the screen
      forethought('widget', 'open');
      forethought('widget', 'show');
      analytics.track('pay-bill', 'forethought-open-success');
    }
  },
  showZendeskMessaging() {
    if (!this.enabled || !window?.zE) {
      // chat is not allowed for this user or loading failed
      analytics.track('pay-bill', 'zendesk-open-failed');

      return;
    }

    window.zE('messenger', 'open');
    analytics.track('pay-bill', 'zendesk-open-success');
  },

  shutdown() {
    switch (this.chatOption) {
      case ChatOption.ZendeskMessaging:
        this.shutdownZendeskMessaging();
        break;
      case ChatOption.Zendesk:
      default:
        this.shutdownZendesk();
    }
  },
  shutdownZendesk() {
    const zendesk = window?.$zopim;
    const forethought = window?.Forethought;

    if (zendesk) zendesk.livechat.window.hide();

    if (forethought) forethought('widget', 'hide');
  },
  shutdownZendeskMessaging() {
    const zendesk = window?.zE;

    if (zendesk) zendesk('messenger', 'hide');
  },

  clearChat() {
    this.enabled = false;
    this.shutdown();
  },
};

export default zendeskService;
