import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';

import billsStore from 'src/app/modules/bills/bills-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { BillsPopover, shouldDisplayPopover } from 'src/app/version-2/components/BillsPopover';
import { BillType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

export const QBOInvoiceNumberCell = ({ item }: Props) => {
  const bill: BillType = useSelector(billsStore.selectors.byId(item.billId));
  const invoiceNumber = bill?.invoiceNumber ?? '-';
  const { billPayments } = item?.payment || {};

  const preventRowClick = (e) => e.stopPropagation();

  if (shouldDisplayPopover(billPayments)) {
    return (
      <Box onClick={preventRowClick}>
        <BillsPopover bills={billPayments} />
      </Box>
    );
  }

  return <Box color={bill?.invoiceNumber ? 'inherit' : 'gray.500'}>{invoiceNumber}</Box>;
};
