import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { RecordOf, OrderedSet } from 'immutable';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import withListContainer, { PageContainerProps } from 'src/app/hoc/withListContainer';
import { withBreak } from 'src/app/hoc';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import { History } from 'history';
import { BillType, FieldType, BillStatusType, BillsGroup, PaymentType } from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import api from 'src/app/services/api/bills';
import { BILL_STATUS } from 'src/app/utils/consts';
import { Option } from 'src/app/components/common/MISingleSelect';
import { getStatusApiParam } from 'src/app/utils/bills';
import { groupByPaymentDate, groupByPaymentsDataPartialPayments } from 'src/app/utils/bills-group';
import { withListSearchBarContext } from 'src/app/hoc/withListSearchBarContext';
import { getOrgId } from 'src/app/redux/user/selectors';
import { updateUserPreferenceAction } from 'src/app/redux/user/actions';
import paymentsStore, { ApprovalDecisionType } from 'src/app/modules/payments/payment-store';
import { getStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import billsStore from 'src/app/modules/bills/bills-store';
import { withPartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import BillsListPage from './components/BillsListPage';
import { BillRecord } from './records';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';

const billFilterKeys = ['status', 'start', 'limit', 'search', 'vendorId'] as const;

type MapStateToProps = {
  bills: OrderedSet<RecordOf<BillType>>;
  billsTotalCount: number;
  isBillsLoading: boolean;
  isPaymentsLoading: boolean;
  payments: PaymentType[];
  paymentsTotalCount: number;
  orgId: string;
  approvalDecisionStatus: ApprovalDecisionType;
  isListLoading: boolean;
};

type LoadBillsParams = {
  orgId: string;
  scope: string;
  filters: {
    status?: string;
    start?: number;
    limit?: number;
    search?: string;
    vendorId?: string;
  };
};

type LoadPaymentsParams = {
  orgId: string;
  status: string;
  search?: string;
  start?: number;
  limit?: number;
};

type MapDispatchToProps = {
  loadBills: (params: LoadBillsParams) => void;
  loadPayments: (params: LoadPaymentsParams) => void;
  updateUserPreference: (id: string, value: any) => void;
};

type Props = {
  device: {
    isMobile: boolean;
    isPhablet: boolean;
    isDesktop: boolean;
  };
  site: any;
  history: History;
  vendors: Option[];
  intuitAccountsOptions: Option[];
  onFieldChange: (field: FieldType) => void;
  validationErrors: Record<string, any>;
  bill?: RecordOf<BillType>;
  onItemRemove: (index: number) => void;
  addNewItem: (description: string, amount: number) => void;
  onChangeAttachment: (file: File) => void;
  onDeleteAttachment: () => void;
  fileStorageUrl?: string;
  filePreviewUrls?: string[];
  isUploading: boolean;
  isPartialPaymentsEnabled: boolean;
} & PageContainerProps &
  MapStateToProps &
  MapDispatchToProps;

type State = {
  groupedBills: BillsGroup[];
  billsCount: number;
};

const eventPage = 'bills';

class BillsListPageContainer extends React.PureComponent<Props, State> {
  static defaultProps = {};

  node: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.state = {
      groupedBills: [],
      billsCount: 0,
    };
    this.node = React.createRef();
  }

  componentDidMount() {
    const { filters, orgId, setContextSearchFilterValue, clearSearchContext } = this.props;

    this.getBillsStatusCount(orgId);

    if (filters.status) {
      this.loadBillsAndRequests();
    } else {
      this.setDefaultFilters();
    }

    if (filters.search) {
      setContextSearchFilterValue(filters.search);
    } else {
      clearSearchContext();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      filters,
      id,
      approvalDecisionStatus,
      contextSearchFilterValue,
      setFilters,
      setContextSearchFilterValue,
      bills,
      setSelected,
      device: { isDesktop },
      isBillsLoading,
      payments,
      isListLoading,
      isPartialPaymentsEnabled,
    } = this.props;

    const isBillsChanged = prevProps.isBillsLoading && prevProps.isBillsLoading !== isBillsLoading;
    const isListChanged = prevProps.isListLoading && prevProps.isListLoading !== isListLoading;

    const isListLoadingFinished = isPartialPaymentsEnabled ? isListChanged : isBillsChanged;

    if (isListLoadingFinished) {
      window.scrollTo(0, 0);
      const groupedBills =
        isPartialPaymentsEnabled && !!payments.length
          ? groupByPaymentsDataPartialPayments(payments, bills, filters.status as BillStatusType)
          : groupByPaymentDate(bills, filters.status as BillStatusType);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ groupedBills }, () => {
        const { groupedBills } = this.state;
        const shouldSelectFirstItem =
          !id &&
          isDesktop &&
          !filters.search &&
          groupedBills.length &&
          groupedBills[0].bills.length > 0;

        if (shouldSelectFirstItem) {
          setSelected(groupedBills[0].bills[0].id);
        }
      });
    }

    if (prevProps.contextSearchFilterValue !== contextSearchFilterValue) {
      setFilters({
        ...filters,
        search: contextSearchFilterValue,
      });
    }

    if (
      prevProps.filters.search !== filters.search &&
      filters.search !== contextSearchFilterValue
    ) {
      setContextSearchFilterValue(filters.search);
    }

    const isFiltersChanged = billFilterKeys.some(
      (filterKey) => prevProps.filters[filterKey] !== this.props.filters[filterKey]
    );
    const isApprovalDecisionChanged =
      prevProps.approvalDecisionStatus.loading &&
      prevProps.approvalDecisionStatus.loading !== approvalDecisionStatus.loading;

    if (isFiltersChanged || isApprovalDecisionChanged) {
      if (filters.status) {
        this.loadBillsAndRequests();
      } else {
        this.setDefaultFilters();
      }
    }
  }

  setDefaultFilters = () => {
    const { setFilters } = this.props;
    const defaultFilters = getBillsDefaultFilters(BILL_STATUS.UNPAID);

    setFilters(defaultFilters);
  };

  getBillsStatusCount = (orgId: string) => {
    api.getBillsStatusCount(orgId).then(({ unpaid, paid, scheduled }) => {
      const billsCount = unpaid + paid + scheduled;

      analytics.track(eventPage, 'count-loaded', { billsCount });
      this.setState({ billsCount });
    });
  };

  loadBills = () => {
    const { orgId, filters, loadBills, isPartialPaymentsEnabled } = this.props;
    const { status, start, limit, search, vendorId } = filters;

    if (isPartialPaymentsEnabled && status !== BILL_STATUS.UNPAID) {
      return;
    }

    loadBills({
      orgId,
      scope: 'billsList',
      filters: {
        status: getStatusApiParam(status as BILL_STATUS, isPartialPaymentsEnabled),
        start,
        limit,
        search,
        vendorId,
      },
    });
  };

  loadPayments = () => {
    const { orgId, filters, loadPayments } = this.props;
    const { start, limit, search, status } = filters;

    loadPayments({
      orgId,
      status: status || BILL_STATUS.UNPAID,
      search,
      start,
      limit,
    });
  };

  loadBillsAndRequests = () => {
    const { isPartialPaymentsEnabled } = this.props;

    this.loadBills();

    if (isPartialPaymentsEnabled) this.loadPayments();
  };

  goCreateBill = () => {
    analytics.track(eventPage, 'add-first-bill');
    this.props.navigate(locations.Bills.create.index.url(), false, {
      manually: true,
    });
  };

  updateIsClosedBillsZeroStatePreference = () => {
    this.props.updateUserPreference('isClosedBillsZeroState', true);
  };

  isBillLoaded = () => {
    const { id, bill } = this.props;

    return (id && bill) || !id;
  };

  render() {
    const { billsCount, groupedBills } = this.state;
    const {
      setFilters,
      filters,
      vendors,
      intuitAccountsOptions,
      onFieldChange,
      validationErrors,
      onItemRemove,
      addNewItem,
      id,
      onChangeAttachment,
      onDeleteAttachment,
      fileStorageUrl,
      filePreviewUrls,
      isUploading,
      bills,
      billsTotalCount,
      paymentsTotalCount,
      isListLoading,
    } = this.props;

    return (
      <BillsListPage
        billId={id}
        eventPage={eventPage}
        setFilters={setFilters}
        filters={filters}
        listViewRef={this.node}
        bills={bills}
        groupedBills={groupedBills}
        totalCount={billsTotalCount + paymentsTotalCount}
        vendors={vendors}
        isLoading={isListLoading}
        isListLoading={isListLoading}
        isSingleLoading={!this.isBillLoaded()}
        goCreateBill={this.goCreateBill}
        onChangeAttachment={onChangeAttachment}
        onDeleteAttachment={onDeleteAttachment}
        onFieldChange={onFieldChange}
        validationErrors={validationErrors}
        intuitAccountsOptions={intuitAccountsOptions}
        onItemRemove={onItemRemove}
        addNewItem={addNewItem}
        fileStorageUrl={fileStorageUrl}
        filePreviewUrls={filePreviewUrls}
        isUploading={isUploading}
        updateIsClosedBillsZeroStatePreference={this.updateIsClosedBillsZeroStatePreference}
        billsCount={billsCount}
        reloadBills={this.loadBillsAndRequests}
        backPath={locations.Bills.index.url()}
      />
    );
  }
}

const mapStateToProps = (
  state: GlobalState,
  { filters, isPartialPaymentsEnabled }
): MapStateToProps => {
  const orgId = getOrgId(state);
  const { status, start, limit, search, vendorId } = filters;
  const billListParams = {
    orgId,
    scope: 'billsList',
    filters: {
      status: getStatusApiParam(status, isPartialPaymentsEnabled),
      start,
      limit,
      search,
      vendorId,
    },
  };

  const paymentListParams = {
    orgId,
    status: status || BILL_STATUS.UNPAID,
    search,
    start,
    limit,
  };

  const isBillsLoading =
    billsStore.selectors.regularList.status(billListParams)(state)?.loading || false;
  const isPaymentsLoading =
    paymentsStore.selectors.list.status(paymentListParams)(state)?.loading || false;
  const isListLoading = isBillsLoading || isPaymentsLoading;

  return {
    orgId,
    bills: OrderedSet(
      billsStore.selectors.regularList.value(state, billListParams).map((bill) => BillRecord(bill))
    ),
    isBillsLoading,
    isPaymentsLoading,
    isListLoading,
    billsTotalCount:
      billsStore.selectors.regularList.status(billListParams)(state)?.totalCount || 0,
    payments: paymentsStore.selectors.list.value(state, paymentListParams),
    paymentsTotalCount:
      paymentsStore.selectors.list.status(paymentListParams)(state)?.totalCount || 0,
    approvalDecisionStatus: paymentsStore.selectors.approvalDecisionStatus(state),
  };
};

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  loadBills(params) {
    const actions = getStoreActions(billsStore)(dispatch);

    actions.regularList(params);
  },
  loadPayments(params) {
    const actions = getStoreActions(paymentsStore)(dispatch);

    actions.list(params);
  },
  updateUserPreference(id: string, value: any) {
    dispatch(updateUserPreferenceAction(id, value));
  },
});

export default compose(
  withBreak(),
  withSiteContext(),
  withListSearchBarContext(),
  withListContainer(),
  withPartialPaymentsEnabled(),
  connect(mapStateToProps, mapDispatchToProps)
)(BillsListPageContainer);
