import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';

import analytics from 'src/app/services/analytics';
import MIButton from 'src/app/components/common/MIButton';
import { useUpdateQBDashboardSwitcher } from 'src/app/pages/qb-dashboard/hooks/useUpdateQbDashboardSwitcher';
import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS } from 'src/app/utils/consts';
import setPayBillsImg from 'src/app/images/qbo/set-pay-bills-modal-img.png';
import { closeDashboard } from 'src/app/utils/external-events';
import {
  ModalWrapper as StyledModalWrapper,
  ModalContainer as StyledModalContainer,
  ModalContentContainer as StyledModalContentContainer,
  Backdrop,
} from 'src/app/components/common/ModalMessage';
import { getUserPreferences } from 'src/app/redux/user/selectors';
import useUpdateUserPreference from 'src/app/modules/users/hooks/useUpdateUserPreferences';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { HeaderContentIcon } from './useFTUModal';
import { DASHBOARD_CLOSE_ORIGIN } from '../consts';

type Props = {
  onClose: () => void;
};

export const useQbDashboardSwitcherModalInitialState = () => {
  const userPreferences = useSelector(getUserPreferences);
  const { isSwitcherChecked } = useUpdateQBDashboardSwitcher();
  const wasDashboardUsedBefore = useSelector(
    qbDashboardListItemsStore.selectors.usageStatus.wasUsedBefore
  );

  return (
    wasDashboardUsedBefore &&
    !userPreferences.qbDashboardSwitcherManageModalSeen &&
    !isSwitcherChecked
  );
};

export function useManageDefaultSwitcherStateModal({ onClose }: Props) {
  const { updateUserPreference } = useUpdateUserPreference();
  const { toggleSwitcher } = useUpdateQBDashboardSwitcher();
  const initialState = useQbDashboardSwitcherModalInitialState() as boolean;

  const [visible, setVisibility] = useState<boolean>(initialState);

  const closeModal = () => {
    setVisibility(false);
    onClose();
    updateUserPreference({ key: 'qbDashboardSwitcherManageModalSeen', value: true });
  };

  const onConfirm = () => {
    closeModal();
    toggleSwitcher(true);
    analytics.trackAction('set-pay-bills-online-mode-as-default');
  };

  const onDelayLater = () => {
    closeModal();
    analytics.trackAction('set-pay-bills-online-mode-maybe-later');
  };

  const onHideModal = () => {
    closeModal();
    analytics.trackAction('close-default-switcher-state-modal');
  };

  const closeIframe = () => {
    analytics.track('qb-dashboard', 'close-integration');
    closeDashboard(DASHBOARD_CLOSE_ORIGIN.CROSS);
  };

  useEffect(() => {
    if (visible) {
      analytics.trackAction('show-default-switcher-state-modal');
    }
  }, []);

  return (
    visible &&
    ReactDOM.createPortal(
      <ModalWrapper data-testid="switcher-state-modal">
        <Box
          position="fixed"
          top="15px"
          right="20px"
          cursor="pointer"
          zIndex={10000}
          onClick={closeIframe}
        >
          <HeaderContentIcon color="#393A3D" className="icon-close-icon" />
        </Box>
        <Backdrop onClick={closeModal} />
        <ModalContainer data-testid="modal-message-container">
          <Close onClick={onHideModal} />
          <ModalContentContainer>
            <ModalContentPart>
              <ModalHeader>
                <MIFormattedText label="paymentDashboard.setPayBillView.title" />
              </ModalHeader>
              <ModalList>
                <ModalListItem>
                  <Icon className="icon-checkmark-icon" />
                  <MIFormattedText label="paymentDashboard.setPayBillView.options.first" />
                </ModalListItem>
                <ModalListItem>
                  <Icon className="icon-checkmark-icon" />
                  <MIFormattedText label="paymentDashboard.setPayBillView.options.second" />
                </ModalListItem>
                <ModalListItem>
                  <Icon className="icon-checkmark-icon" />
                  <MIFormattedText label="paymentDashboard.setPayBillView.options.third" />
                </ModalListItem>
              </ModalList>
              <MIButton
                label="paymentDashboard.setPayBillView.buttons.default"
                variant={CONSTS.BUTTON_VARIANT.PRIMARY}
                onClick={onConfirm}
              />
              <MIButton
                label="paymentDashboard.setPayBillView.buttons.later"
                variant={CONSTS.BUTTON_VARIANT.EMPTY}
                onClick={onDelayLater}
              />
            </ModalContentPart>
            <ModalContentPart>
              <ModalImage src={setPayBillsImg} />
            </ModalContentPart>
          </ModalContentContainer>
        </ModalContainer>
      </ModalWrapper>,
      document.querySelector('body') as any
    )
  );
}

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  font-size: 2.4rem;
  cursor: pointer;
  color: rgba(186, 190, 197, 1);
`;

const Icon = styled.i`
  margin-right: 1.6rem;
  vertical-align: middle;
  font-size: 1.375rem;
  font-weight: bold;
  color: rgba(16, 128, 0, 1);
`;

const ModalImage = styled.img`
  width: 410px;
  align-self: flex-end;
`;

const ModalList = styled.ul`
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
`;

const ModalListItem = styled.li`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 3rem;
`;

const ModalWrapper = styled(StyledModalWrapper)``;

const ModalContainer = styled(StyledModalContainer)`
  position: absolute;
  bottom: 2rem;
  width: 83.6rem;
  padding: 3.5rem 4rem 4rem;
`;

const ModalContentContainer = styled(StyledModalContentContainer)`
  flex-direction: row;
  padding: 0;
  gap: 5.2rem;
`;

const ModalContentPart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;

  button {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  button:last-of-type {
    margin-top: 0.4rem;
    border: none;
  }
`;

const ModalHeader = styled.h1`
  max-width: 315px;
  margin: 0;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.2rem;
`;
