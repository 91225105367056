import { Link } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const PaymentMethodButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PaymentMethodButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaymentMethodButtonLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentMethodMenuButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;

export const FundingSourceImage = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
`;

export const AddNewContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
`;

export const AddNew = styled(Link)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
