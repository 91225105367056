import { getDeliveryOptionFastFeeForPartialPayments } from 'src/app/utils/delivery-methods';
import { FAST_DELIVERY_TYPES } from 'src/app/version-2/model/constants/fee';
import { FastFeeDeliveryEnum } from 'src/app/version-2/model/enums';
import { EXPEDITED_DELIVERY_TYPES } from 'src/app/utils/consts';
import { DeliveryOptionType } from 'src/app/utils/types';
import { DeliveryTypeExpeditedEnum } from 'src/app/version-2/model/enums';

export const isFastDeliveryOption = (
  deliveryOptions: DeliveryOptionType[],
  deliveryOptionType: string
) =>
  EXPEDITED_DELIVERY_TYPES.includes(deliveryOptionType) ||
  (deliveryOptionType === FastFeeDeliveryEnum.EXPRESS_CHECK &&
    !deliveryOptions.find((item) => item.type === DeliveryTypeExpeditedEnum.OVERNIGHT_CHECK));

export const getDeliveryOptionFees = (
  deliveryOption: DeliveryOptionType,
  paymentAmount?: number | null
): number =>
  paymentAmount
    ? getDeliveryOptionFastFeeForPartialPayments(deliveryOption, paymentAmount)
    : deliveryOption.amount;

export const getExpeditedACHDeliveryDate = (deliveryOptions: DeliveryOptionType[]) =>
  deliveryOptions.find(
    (deliveryOption) => deliveryOption.type === DeliveryTypeExpeditedEnum.EXPEDITED_ACH
  )?.deliveryDate;

export const sortDeliveryOptions = (deliveryOptions: DeliveryOptionType[]) => {
  const result = [...deliveryOptions];
  const isFirstOptionFast = FAST_DELIVERY_TYPES.includes(
    deliveryOptions[0].type as FastFeeDeliveryEnum
  );

  if (!isFirstOptionFast) {
    return result.reverse();
  }

  return result;
};
