import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Menu, Popover, Tag } from '@melio/billpay-design-system';

import analytics from 'src/app/services/analytics';
import { MIFormattedText } from 'src/app/utils/formatting';
import { FiltersChangeInfo, MenuFilterItem, ExtendedSelectOption } from 'src/app/utils/types';

import { MultiSelect } from 'src/app/components/common/MultiSelect';
import { ReactComponent as ArrowDownAcuteIcon } from 'src/app/images/qbo/arrow_down_acute.svg';
import { DASHBOARD_FILTER_ITEMS_NAMES } from '../consts';
import { SyncingBillsSpinner } from './SyncingBillsSpinner/SyncingBillsSpinner';

type OpenFiltersStatus = {
  [key: string]: boolean;
};

type Props = {
  currentStatus: string;
  isBillsSyncRunning: boolean;
  filterMenuItems: MenuFilterItem[];
  onChange: (change: FiltersChangeInfo) => void;
};

export const Filters = ({
  currentStatus,
  isBillsSyncRunning,
  filterMenuItems,
  onChange,
}: Props) => {
  const [openFiltersStatus, setOpenFiltersStatus] = useState<OpenFiltersStatus>(
    filterMenuItems.reduce((prev, curr) => ({ ...prev, [curr.label]: false }), {})
  );
  const [hasSelectedFilters, setHasSelectedFilters] = useState<boolean>(false);
  const [currentItemLabel, setCurrentItemLabel] = useState<string>('');

  useEffect(
    () =>
      setHasSelectedFilters(
        filterMenuItems.some(({ options }) => options.some(({ value }) => value))
      ),
    [filterMenuItems]
  );

  useEffect(() => {
    if (currentStatus) {
      setCurrentItemLabel('');
    }
  }, [currentStatus]);

  const handleMenuItemSelect = (selectInfo: { label: string } | null) => {
    const { label } = selectInfo || {};

    if (label && label === currentItemLabel) return;

    setOpenFiltersStatus(
      filterMenuItems.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.label]: curr.label === label ? !openFiltersStatus[label] : false,
        }),
        {}
      )
    );

    setCurrentItemLabel(label || '');
  };

  const getLabelFromOption = ({
    label,
    values,
  }: Pick<ExtendedSelectOption, 'values' | 'label'>) => {
    const hasDymaicValues = values;
    const dynamicValuesFirstKey = hasDymaicValues && Object.keys(values)[0];
    const key = dynamicValuesFirstKey ? values[dynamicValuesFirstKey] : label;

    return key;
  };

  const handleClearFilter = (type: string, options: ExtendedSelectOption[], filterLabel: string) =>
    onChange({
      type,
      options: options.map((option) => {
        const isFilterToRemove = getLabelFromOption(option) === filterLabel;

        return {
          ...option,
          value: isFilterToRemove ? false : option.value,
          selected: isFilterToRemove ? false : option.selected,
        };
      }),
    });

  const handleClearAll = () => onChange({ type: null });

  const handleFiltersClick = (isOpen: boolean) => {
    analytics.trackAction('dashboard-list-filters-action', {
      clickTarget: !isOpen ? 'open' : 'close',
      status: currentStatus,
    });
  };

  return (
    <FiltersContainer>
      <StyledMenu isLazy closeOnSelect={false} onClose={() => handleMenuItemSelect(null)}>
        {({ isOpen }) => (
          <>
            <FiltersButton as={FiltersButton} onClick={() => handleFiltersClick(isOpen)}>
              <MIFormattedText label="paymentDashboard.search.filters.mainButtonTitle" />
              <FilterButtonArrowIcon />
            </FiltersButton>
            <StyledList>
              {filterMenuItems.map(({ type, label, options }) => (
                <RelativeWrapper
                  key={type}
                  onMouseOverCapture={() => handleMenuItemSelect({ label })}
                >
                  <Menu.MenuItem
                    fontWeight={options.some(({ value }) => value) ? '600' : 'intial'}
                    backgroundColor={openFiltersStatus[label] ? 'ds.gray.800' : 'initial'}
                  >
                    <MIFormattedText label={label} />
                    <MenuItemArrowIcon />
                  </Menu.MenuItem>
                  <Popover
                    isLazy
                    isOpen={openFiltersStatus[label]}
                    placement="right-start"
                    arrowStyles={popoverFixedPositionStyle}
                    closeOnBlur={false}
                  >
                    <Popover.PopoverTrigger>
                      <PopoverTriggerFiller />
                    </Popover.PopoverTrigger>
                    <Popover.PopoverContent>
                      <MultiSelect
                        searchable={type === DASHBOARD_FILTER_ITEMS_NAMES.VENDORS}
                        options={options}
                        onChange={(updatedOptions) => onChange({ type, options: updatedOptions })}
                      />
                    </Popover.PopoverContent>
                  </Popover>
                </RelativeWrapper>
              ))}
            </StyledList>
          </>
        )}
      </StyledMenu>
      <TagsContainer>
        {filterMenuItems.map(({ type, options }) =>
          options
            .filter(({ value }) => value)
            .map(({ label, values }) => {
              const dynamicLabel = getLabelFromOption({ label, values });

              return (
                <Tag key={dynamicLabel} style={{ margin: 0 }}>
                  <Tag.TagLabel>
                    <MIFormattedText label={label} values={values} />
                  </Tag.TagLabel>
                  <Tag.TagCloseButton
                    onClick={() => handleClearFilter(type, options, dynamicLabel)}
                  />
                </Tag>
              );
            })
        )}
        {hasSelectedFilters && (
          <ClearLabel onClick={handleClearAll}>
            <MIFormattedText label="paymentDashboard.search.filters.clearLabelMessage" />
          </ClearLabel>
        )}
        {isBillsSyncRunning && <SyncingBillsSpinner />}
      </TagsContainer>
    </FiltersContainer>
  );
};

const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 1rem;
`;

const FiltersButton = styled(Menu.MenuButton)`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-width: 12rem;
  box-sizing: border-box;
  gap: 0.8rem;
  margin-inline-start: 0.8rem;
  padding: 0.6rem 2.4rem;
  border-radius: 2rem;
  border-color: ${(props) => props.theme.colors.ds.black};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.ds.gray[500]};
  }
`;

const ArrowIcon = styled(ArrowDownAcuteIcon)`
  path {
    fill: black;
  }
`;

const FilterButtonArrowIcon = styled(ArrowIcon)`
  margin-left: 1.33rem;
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const StyledMenu = styled(Menu)`
  border: 1px solid ${(props) => props.theme.colors.ds.gray[400]};
  box-shadow: 0 2px 2px 0 ${(props) => props.theme.colors.ds.gray[400]};
`;

const StyledList = styled(Menu.MenuList)`
  max-width: 13.8rem;
`;

const MenuItemArrowIcon = styled(ArrowIcon)`
  position: absolute;
  right: 1.33rem;
  transform: rotate(270deg);
`;

const PopoverTriggerFiller = styled.div`
  position: absolute;
  right: 1rem;
  top: 0rem;
`;

const ClearLabel = styled.div`
  min-width: 5.5rem;
  padding: 0.6rem 0.4rem;
  color: ${(props) => props.theme.colors.ds.blue[100]};
  cursor: pointer;
  line-height: 2rem;
  user-select: none;
  font-size: 1.4rem;
  font-weight: 400;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const popoverFixedPositionStyle = {
  '.chakra-popover__popper': {
    '--popper-transform-origin': 'left top !important',
    transform: 'translate3d(13.8rem, 0, 0) !important',
  },
};
