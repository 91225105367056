import React from 'react';
import { RecordOf } from 'immutable';
import { DELIVERY_TYPE, PAYMENT_APPROVAL_STATUS, TAG_VARIANT } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import styled from 'styled-components';
import { isSameDay, DeliveryDate } from 'src/app/utils/dates';
import MITag from 'src/app/components/common/MITag';
import { isFastDeliveryType } from 'src/app/utils/delivery-methods';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodText,
  PaymentInfoText,
  EditElement,
} from 'src/app/components/layout/PaymentActivityElements';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import { DeliveryDateInfoHint as Hint } from './Hint';

type Props = {
  payment: RecordOf<JustPayPaymentType>;
  fundingSource: FundingSource;
  onEdit?: (() => void) | null;
};

export const JustPayPaymentActivityDeliveryDateInfo = ({
  payment,
  onEdit,
  fundingSource,
}: Props) => {
  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = payment?.deliveryMethodType === DELIVERY_TYPE.VIRTUAL;
  const isSameDayDelivery = isSameDay(payment?.suggestedDates?.deliveryEta);
  const isFast = isFastDeliveryType(payment?.suggestedDates?.deliveryPreferenceType);
  let label = 'payBillPaymentActivity.deliveryDate.deliveryEtaLabel';

  if (isMicrodeposit || isUnilateral || isApprovalRequired) {
    label = 'payBillPaymentActivity.deliveryDate.estimatedDeliveryEtaLabel';
  }

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <i className="icon-eta-cal" />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={label} />
            </PaymentMethodText>
            <PaymentInfoText>
              {isSameDayDelivery && (
                <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />
              )}
              <DeliveryDate
                date={payment?.suggestedDates?.deliveryEta}
                maxDate={payment?.suggestedDates?.maxDeliveryEta}
              />
              {isFast && (
                <FastTag
                  label="bills.pay.date.deliveryOptions.price.fastTag"
                  variant={TAG_VARIANT.BRAND}
                />
              )}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        <EditElement onEdit={onEdit} />
      </PaymentActivityMainContainer>
      <Hint payment={payment} fundingSource={fundingSource} />
    </PaymentActivityContainer>
  );
};

const FastTag = styled(MITag)`
  margin-left: 0.6rem;
  vertical-align: middle;
  font-size: 0.8rem;
  padding: 0 0.4rem;
  position: relative;
  top: -0.1rem;
`;
