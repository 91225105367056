import { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { melioClose } from 'src/app/utils/external-events';
import locations from 'src/app/utils/locations';
import { renderBlockedForPaymentDialog } from 'src/app/utils/vendors';
import * as QBOLayoutElements from 'src/app/components/layout/QBOElements';
import { BillFrequencyEnum } from 'src/app/version-2/model/enums';
import { OnInputChange, Option } from '../../components/common/MISingleSelect';
import { withNavigator, withBreak } from '../../hoc';
import { devices } from '../../theme/AppDevices';
import type { FieldType, BillType, OrganizationPreferencesType } from '../../utils/types';
import type { GlobalState } from '../../redux/types';
import NewBillMainPage from './components/NewBillMainPage';
import withNewBillData from './hoc/withNewBillData';
import { getOrganizationPreferences } from '../../redux/organization/selectors';
import { getOrgId } from '../../redux/user/selectors';

type LocationState = {
  bill: BillType;
  validationErrors: Record<string, any>;
  manually: boolean;
};

type MapStateToProps = {
  orgId: string;
  organizationPreferences: OrganizationPreferencesType;
};

type Props = {
  fileStorageUrl: string;
  filePreviewUrls: string[];
  bill: BillType;
  validationErrors: Record<string, any>;
  vendors: Option[];
  filteredVendors?: Option[];
  onVendorsInputChange?: OnInputChange;
  isUploading: boolean;
  isRecurring: boolean;
  locationState: LocationState;
  onFieldChange: (field: FieldType) => void;
  onItemRemove: (index: number) => void;
  onChange: (field: FieldType) => void;
  goWithUploadedBill: (file: File, orgId: string) => void;
  onUploadRemove: () => void;
  goExit: () => void;
  goExitManually: () => void;
  onSubmitBill: (
    cb: (isPassedValidation: boolean) => void,
    shouldReturnToBillsDashboard: boolean
  ) => void;
  handleUploadStart: () => void;
  handleUploadCancel: () => void;
  intuitAccountsOptions: Option[];
  vendorAccountIdentifier?: string;
  addNewItem: () => void;
  handleUploadError: () => void;
  handleUploadSuccess: (file: File) => void;
  handleRetry: () => void;
  occurrences: string;
  frequency: BillFrequencyEnum;
  onDeleteAttachment: () => void;
  fileName: string;
  isUploadError: boolean;
  site: any;
  isVendorBlockedForPayment: boolean;
  navigate: (arg0: string) => void;
} & MapStateToProps;

type State = {
  isLoading: boolean;
};

class NewBillPageContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  onCreateBill = (shouldReturnToBillsDashboard) => {
    const { onSubmitBill } = this.props;

    this.setState({ isLoading: true }, () => {
      onSubmitBill(() => {
        this.setState({ isLoading: false });
      }, shouldReturnToBillsDashboard);
    });
  };

  goExitHandler = () => {
    melioClose();
  };

  onCloseBlockPaymentDialog = () => {
    const { bill, navigate } = this.props;

    navigate(locations.Bills.view.url({ id: bill.id }));
  };

  render() {
    const {
      handleUploadStart,
      handleUploadCancel,
      goWithUploadedBill,
      onFieldChange,
      onItemRemove,
      onUploadRemove,
      goExit,
      goExitManually,
      intuitAccountsOptions,
      vendorAccountIdentifier,
      addNewItem,
      vendors,
      orgId,
      isUploading,
      fileStorageUrl,
      filePreviewUrls,
      bill,
      validationErrors,
      locationState,
      handleUploadError,
      isRecurring,
      onChange,
      occurrences,
      frequency,
      onDeleteAttachment,
      fileName,
      isUploadError,
      handleUploadSuccess,
      handleRetry,
      site,
      onVendorsInputChange,
      filteredVendors,
      isVendorBlockedForPayment,
      organizationPreferences,
    } = this.props;
    const { isLoading } = this.state;
    const manually = locationState && locationState.manually ? locationState.manually : false;

    return (
      <>
        {isVendorBlockedForPayment &&
          renderBlockedForPaymentDialog(site, this.onCloseBlockPaymentDialog)}
        <StepHeaderWrapper>
          <QBOLayoutElements.QBOHeaderContainer
            onPrev={goExitManually}
            goExit={manually ? goExitManually : goExit}
            headerLabel="bills.view.headerTitle"
          />
        </StepHeaderWrapper>

        <NewBillPageWrapper>
          <NewBillMainPage
            occurrences={occurrences}
            frequency={frequency}
            vendors={vendors}
            filteredVendors={filteredVendors}
            onVendorsInputChange={onVendorsInputChange}
            intuitAccountsOptions={intuitAccountsOptions}
            vendorAccountIdentifier={vendorAccountIdentifier}
            orgId={orgId}
            handleUploadStart={handleUploadStart}
            handleUploadCancel={handleUploadCancel}
            onUploadRemove={onUploadRemove}
            goWithUploadedBill={goWithUploadedBill}
            isUploading={isUploading}
            onFieldChange={onFieldChange}
            onChange={onChange}
            isRecurring={isRecurring}
            onItemRemove={onItemRemove}
            addNewItem={addNewItem}
            fileStorageUrl={fileStorageUrl}
            filePreviewUrls={filePreviewUrls}
            bill={bill}
            validationErrors={validationErrors}
            goExit={this.goExitHandler}
            goExitManually={goExitManually}
            onSubmitBill={this.onCreateBill}
            isProcessing={isLoading}
            manually={manually}
            handleUploadError={handleUploadError}
            onDeleteAttachment={onDeleteAttachment}
            fileName={fileName}
            isUploadError={isUploadError}
            handleUploadSuccess={handleUploadSuccess}
            handleRetry={handleRetry}
            isFirstWave={!!organizationPreferences?.billPayFirstWaveUser}
          />
        </NewBillPageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
  organizationPreferences: getOrganizationPreferences(state),
});

export default compose(
  withNavigator(),
  withSiteContext(),
  withNewBillData(),
  withBreak(),
  connect(mapStateToProps)
)(NewBillPageContainer);

const NewBillPageWrapper = styled.div`
  display: flex;
  height: calc(100% - 6.4rem);
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};

  @media ${devices.tablet} {
    height: unset;
  }
  @media ${devices.mobile}, ${devices.phablet} {
    height: unset;
  }
  ${(props) => props.theme.pages?.NewBillPageContainer?.NewBillPageWrapper};
`;
const StepHeaderWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  padding: 2rem;
  ${(props) => props.theme?.pages?.NewBillPageContainer?.StepHeaderWrapper}
`;
