import { GLOBAL_ERROR_OCCURRED } from './actionTypes';

export const initialState = {
  errorOccurred: false,
  title: null,
  subtitle: null,
};

export const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_ERROR_OCCURRED:
      return {
        ...state,
        errorOccurred: true,
        title: action.title,
        subtitle: action.subtitle,
      };
    default:
      return state;
  }
};
