import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DELIVERY_TYPE, DELIVERY_METHOD_ORIGIN } from 'src/app/utils/consts';
import { CheckRecord, getDeliveryMethodByType } from 'src/app/pages/vendor/records';
import { Skeleton } from '@melio/billpay-design-system';
import { getBill, getPayment, getExitUrl } from 'src/app/redux/payBillWizard/selectors';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import {
  selectNewDeliveryMethodAction,
  resetErrorAction,
} from 'src/app/redux/payBillWizard/actions';
import { compose } from 'recompose';
import { withNavigator } from 'src/app/hoc';
import { featureFlags } from '@melio/shared-web';
import locations from 'src/app/utils/locations';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import { getNotice } from 'src/app/version-2/utils/checkFees.utils';
import {
  organizationSelectors,
  organizationActions,
} from 'src/app/version-2/modules/organization/organization.slice';
import { ANALYTICS_PAGE_NAME, getConfirmURL } from 'src/app/pages/bill/pay/RecoveryFlowSteps/utils';
import { RESOURCE_BASE } from '../config';
import { withPayBillData, PayBillProps } from '../../hoc/withPayBillData';
import { DeliveryMethodItem } from './DeliveryMethodItem';
import { RecoveryFlowType } from '../../consts';
import { PaymentType } from 'src/app/utils/types';

const StepChooseDeliveryMethod = ({
  onPrev,
  goExit,
  flowType,
}: PayBillProps & { flowType: RecoveryFlowType }) => {
  const [isReturnedCheckFeatureActive] = featureFlags.useFeature('bp-returned-check');
  const [voidChecksEnabled] = featureFlags.useFeature('bp-void-check', false);
  const [historyPush] = useHistoryWithOrgId();
  const bill = useSelector(getBill);
  const payment: PaymentType = useSelector(getPayment);
  const { companyName = '' } = bill.vendor ?? {};
  const achDM = getDeliveryMethodByType(DELIVERY_TYPE.ACH, bill.vendor?.deliveryMethods || []);
  const checkDM = getDeliveryMethodByType(DELIVERY_TYPE.CHECK, bill.vendor?.deliveryMethods || []);
  const dispatch = useDispatch();
  const exitUrl = useSelector(getExitUrl);
  const catalog = useSelector(organizationSelectors.selectFeeCatalog);

  const onModalOpen = () => dispatch(organizationActions.setIsCheckFeeModalOpen(true));
  const checkFeeTexts = getNotice({
    fundingSource: payment?.fundingSource,
    catalog,
    onModalOpen,
  });
  const isVoidCheck = voidChecksEnabled && flowType === RecoveryFlowType.VOID_CHECK;

  const getDMIDIfAlreadyExists = (newDeliveryType) => {
    const deliveryMethodByType = getDeliveryMethodByType(
      newDeliveryType,
      bill.vendor?.deliveryMethods || []
    );

    if (deliveryMethodByType?.id) return deliveryMethodByType.id;

    return null;
  };

  const handleChooseDMAnalytics = (newDeliveryType) => {
    // retry event
    let category = ANALYTICS_PAGE_NAME;
    let event = 'retry-payment-delivery-change-from-check';

    if (isVoidCheck) {
      // void check event
      category = 'resend-undeposited-check';
      event = `delivery-change-from-check-to-${newDeliveryType}`;
    }

    analytics.track(category, event, {
      billID: bill?.id,
      billId: bill?.id,
      original_paymentID: payment?.id,
      paymentId: payment?.id,
      originDeliveryType: DELIVERY_TYPE.CHECK,
      new_deliveryID: getDMIDIfAlreadyExists(newDeliveryType),
      new_deliveryType: newDeliveryType,
      partialBillId: getPartialBillId(bill),
    });
  };

  const onACHSelected = async () => {
    handleChooseDMAnalytics(DELIVERY_TYPE.ACH);

    if (achDM.id) {
      await dispatch(selectNewDeliveryMethodAction(achDM));

      const confirmUrl = getConfirmURL(flowType);

      historyPush({
        path: confirmUrl.url({
          id: bill?.id,
          paymentId: payment?.id,
        }),
      });
    } else {
      historyPush({
        path: locations.Bills.pay.edit[flowType].missingACHDetails.url({
          id: bill?.id,
          paymentId: payment?.id,
        }),
      });
    }
  };

  const onCheckSelected = async () => {
    let pathToDeliveryMethodsFlow = locations.Vendors.deliveryMethods.check.create.url({
      id: bill.vendorId,
    });

    handleChooseDMAnalytics(DELIVERY_TYPE.CHECK);

    if (checkDM?.id) {
      pathToDeliveryMethodsFlow = locations.Vendors.deliveryMethods.check.edit.url({
        id: bill?.vendorId,
        deliveryMethodId: checkDM?.id,
      });
      await dispatch(selectNewDeliveryMethodAction(checkDM));
    }

    const redirectUrl = locations.Bills.pay.edit[flowType].chooseCheckDelivery.url({
      id: bill?.id,
      paymentId: payment?.id,
    });

    historyPush({
      path: pathToDeliveryMethodsFlow,
      state: {
        redirectUrl,
        exitUrl,
        origin: DELIVERY_METHOD_ORIGIN.RETURNED_CHECK,
        check: CheckRecord(checkDM.paperCheck || {}),
        isFundingSourceVerified: true,
        isVoidCheck,
      },
    });
  };

  useEffect(() => {
    dispatch(resetErrorAction());

    const billId = bill?.id;
    const paymentId = payment?.id;

    if (billId && paymentId) {
      if (isVoidCheck)
        analytics.page('resend-undeposited-check', 'select-delivery-method', {
          billId,
          paymentId,
        });
      else analytics.page('resend-check', 'choose-new-dm');
    }
  }, [payment?.id, bill?.id]);

  const isPaymentVoidAndResendCompitable = payment.metadata?.isVoidAndRetryEnabled;

  if (!isReturnedCheckFeatureActive && (!voidChecksEnabled || !isPaymentVoidAndResendCompitable))
    return null;

  return (
    <QBOLayoutPage
      title={`${RESOURCE_BASE[flowType]}.stepChooseDM.title`}
      titleValues={{ vendorName: companyName }}
      goExit={goExit}
      onPrev={onPrev}
      isHideActions
    >
      {isVoidCheck && !companyName ? (
        <>
          <Skeleton w="100%" h="7rem" mt="0" mb="2rem" />
          <Skeleton w="100%" h="7rem" mt="0" mb="2rem" />
        </>
      ) : (
        <>
          <DeliveryMethodItem
            key={achDM.deliveryType}
            deliveryMethod={achDM}
            flowType={flowType}
            onItemClick={onACHSelected}
          />
          <DeliveryMethodItem
            key={checkDM.deliveryType}
            deliveryMethod={checkDM}
            flowType={flowType}
            onItemClick={onCheckSelected}
            description={checkFeeTexts?.description}
            descriptionValues={checkFeeTexts?.descriptionValues}
          />
        </>
      )}
    </QBOLayoutPage>
  );
};

const StepChooseDeliveryMethodHocs = compose(
  withNavigator(),
  withPayBillData()
)(StepChooseDeliveryMethod);

export { StepChooseDeliveryMethodHocs as StepChooseDeliveryMethod };
