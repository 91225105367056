import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { melioClose } from 'src/app/utils/external-events';
import { Loader } from '@melio/billpay-design-system';
import paymentsApi from 'src/app/services/api/payments';
import failedToLoad from 'src/app/images/qbo/failed-to-load-002-icon.png';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import { getOrgId } from 'src/app/redux/user/selectors';
import quickpayLocations from 'src/app/pages/quickpay/locations';
import { encodeQuery } from 'src/app/utils/query-utils';
import { QBR_BILL_LIST_TAB_TYPE, PAGINATION } from 'src/app/utils/consts';

type Props = {
  query: {
    paymentId: number;
  };
};

const QuickpayViewBillRedirect = ({ query }: Props) => {
  const { paymentId } = query;
  const [hasError, setHasError] = useState(false);
  const orgId = useSelector(getOrgId);
  const history = useHistory();

  const redirectToViewBill = useCallback(
    (billId) => {
      history.push({
        pathname: generatePath(quickpayLocations.list.index, { orgId }),
        search: encodeQuery(
          {
            start: PAGINATION.DEFAULT_START,
            limit: PAGINATION.DEFAULT_LIMIT,
            type: QBR_BILL_LIST_TAB_TYPE.OPEN,
            id: billId,
          },
          [],
          ''
        ),
      });
    },
    [history, orgId]
  );

  const syncBill = useCallback(
    async ({ orgId, paymentId }) => {
      try {
        const data = await paymentsApi.getPaymentById(orgId, paymentId);
        const { billId } = data.object;

        setHasError(false);
        redirectToViewBill(billId);
        // eslint-disable-next-line no-empty
      } catch (e) {
        setHasError(true);
      }
    },
    [redirectToViewBill]
  );

  useEffect(() => {
    syncBill({ orgId, paymentId });
  }, [syncBill, orgId, paymentId]);

  return hasError ? (
    <ErrorPage
      illustration={failedToLoad}
      title="bills.pay.errorVendorNotFound.title"
      subtitle="bills.pay.errorVendorNotFound.subtitle"
      buttonAction={melioClose}
      buttonLabel="bills.pay.errorVendorNotFound.action"
    />
  ) : (
    <Loader />
  );
};

export default QuickpayViewBillRedirect;
