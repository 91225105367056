import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Box, Flex } from '@melio/billpay-design-system';

import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { flagsStore } from 'src/app/modules/flags/flags-store';
import { MIFormattedText } from 'src/app/utils/formatting';
import QBOHeader from 'src/app/pages/qb-dashboard/components/QBOHeader';
import useVerifyMicroDepositAction from 'src/app/pages/qb-dashboard/hooks/useVerifyMicroDepositAction';
import QBODashboardFooter from 'src/app/pages/qb-dashboard/components/QBODashboardFooter';
import SearchInput from 'src/app/pages/qb-dashboard/components/SearchInput/SearchInput';
import useFetchIntuitBankAccounts from 'src/app/modules/intuit-bank-accounts/hooks/useFetchIntuitBankAccounts';
import useEditVendorModal from 'src/app/pages/qb-dashboard/hooks/useEditVendorModal';
import {
  useManageDefaultSwitcherStateModal,
  useQbDashboardSwitcherModalInitialState,
} from 'src/app/pages/qb-dashboard/hooks/useManageDefaultSwitcherStateModal';
import { useSinglePayBillSuccessModal } from 'src/app/pages/qb-dashboard/hooks/useSinglePayBillSuccessModal';
import { BatchPaymentsSuccessModal } from 'src/app/pages/qb-dashboard/components/BatchPaymentsSuccessModal';
import { useJustPaySuccessModal } from 'src/app/pages/qb-dashboard/hooks/useJustPaySuccessModal';
import { useQueryState, useLocationState } from 'src/app/utils/hooks';
import { getOrgId, getUserPreferences } from 'src/app/redux/user/selectors';
import useUpdateUserPreference from 'src/app/modules/users/hooks/useUpdateUserPreferences';
import { FiltersChangeInfo, VendorType } from 'src/app/utils/types';
import { QBOFooterContainer as QBOLegalFooter } from 'src/app/components/layout/QBOElements';
import useDashboardListParams from './hooks/useDashboardListParams';
import useFTUModal from './hooks/useFTUModal';
import {
  BATCH_FOOTER_HEIGHT,
  DASHBOARD_FILTER_ITEMS,
  QB_DASHBOARD_TAB_STATUS,
  TABS,
} from './consts';
import QBDashboardTab from './components/QBDashboardTab/QBDashboardTab';
import { useLoadDashboardList } from './hooks/useLoadDashboardList';
import { useUpdateQBDashboardSwitcher } from './hooks/useUpdateQbDashboardSwitcher';
import { DashboardAddNewBillButton } from './components/DashboardAddNewBillButton/DashboardAddNewBillButton';
import { DashboardFilterMenuItems } from './types';
import { getDashboardFilters } from './components/QBDashboardTab/utils';
import { Filters } from './components/Filters';
import { useFullOrgSyncStatusPolling } from './hooks/useFullOrgSyncStatusPolling';
import { useDashboardListPolling } from './hooks/useDashboardListPolling';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum, FullOrgSyncStatusEnum } from 'src/app/version-2/model/enums';

export const QBDashboardPage = () => {
  const orgId: string = useSelector(getOrgId);
  const userPreferences = useSelector(getUserPreferences);
  const dispatch = useDispatch();

  const { status: fullOrgSyncStatus } = useFullOrgSyncStatusPolling();
  const isFullOrgSyncRunning = fullOrgSyncStatus === FullOrgSyncStatusEnum.RUNNING;

  const isQbDashboardSwitcherModalInitialVisible = useQbDashboardSwitcherModalInitialState();
  const isFTUModalVisible = !userPreferences.qbDashboardSeen;
  const { updateUserPreference } = useUpdateUserPreference();
  const [isSwitcherTooltipVisible, setIsSwitcherTooltipVisible] = useState(false);
  const [isQboRefundPaymentFF] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_REFUND_PAYMENT,
    false
  );

  const [listParams, { setStatus, setSearch }] = useDashboardListParams();
  const { loadDashboardList } = useLoadDashboardList();
  const selectedItemsIds: any[] = useSelector(
    qbDashboardListItemsStore.selectors.qbDashboardSelection.selectedIds
  );
  const loadStatus: { totalCount: number } = useSelector(
    qbDashboardListItemsStore.selectors.list?.status(listParams)
  );
  const isInternationalEntryPoint = useSelector(
    (state) => state['flags'].isInternationalEntryPoint
  );

  const { isSwitcherChecked } = useUpdateQBDashboardSwitcher();
  const isOpenFooter = selectedItemsIds.length > 0;

  useFetchIntuitBankAccounts();

  const [EditVendorModal] = useEditVendorModal();
  const showSwitcherTooltip = () => {
    if (!userPreferences.qbDashboardSwitcherTooltipSeen) {
      setIsSwitcherTooltipVisible(true);
      updateUserPreference({
        key: 'qbDashboardSwitcherTooltipSeen',
        value: true,
      });
    }
  };

  const [confirmationOrigin] = useLocationState('confirmationOrigin');
  const [isPayBillSuccessModalVisible, setIsPayBillSuccessModalVisible] = useState(
    !!confirmationOrigin
  );

  const shouldShowTooltip = !isQbDashboardSwitcherModalInitialVisible && !isFTUModalVisible;

  const [SinglePayBillSuccessModal] = useSinglePayBillSuccessModal(() => {
    setIsPayBillSuccessModalVisible(false);

    if (shouldShowTooltip) {
      showSwitcherTooltip();
    }
  });

  const onDone = () => {
    setIsPayBillSuccessModalVisible(false);
    clearLocationStateAfterClosingModal();

    if (shouldShowTooltip) {
      showSwitcherTooltip();
    }
  };

  const clearLocationStateAfterClosingModal = () => {
    window.history.replaceState({}, '');
  };

  const [JustPaySuccessModal] = useJustPaySuccessModal(() => {
    setIsPayBillSuccessModalVisible(false);

    if (shouldShowTooltip) {
      showSwitcherTooltip();
    }
  });

  const ManageDefaultSwitcherStateModal = useManageDefaultSwitcherStateModal({
    onClose: showSwitcherTooltip,
  });
  const [FTUModal] = useFTUModal({
    onClose: showSwitcherTooltip,
  });

  const [mdFundingSourceId] = useQueryState<string | undefined>('mdFundingSourceId');
  const [VerifyMicroDepositDialog] = useVerifyMicroDepositAction(mdFundingSourceId);

  useEffect(() => {
    if (isInternationalEntryPoint) dispatch(flagsStore.actions.setIsInternationalEntryPoint(false));

    if (isSwitcherChecked && !isFTUModalVisible) {
      showSwitcherTooltip();
    }
  }, []);

  useEffect(() => {
    loadDashboardList(listParams);
  }, [loadDashboardList, listParams]);

  useDashboardListPolling();

  const onChangeTab = (statusIndex: number) => {
    setStatus(TABS[statusIndex]);
  };

  const onCloseTooltip = () => {
    setIsSwitcherTooltipVisible(false);
  };

  const { filters } = listParams;
  const totalCount = loadStatus?.totalCount || 0;
  const isPaginationOn = totalCount > filters.limit;

  const vendorsStoreActions = useStoreActions(vendorsStore);
  const vendors: VendorType[] = useSelector(vendorsStore.selectors.all);

  const [filterMenuItems, setFilterMenuItems] = useState<DashboardFilterMenuItems>(
    getDashboardFilters(
      listParams.filters.status,
      listParams.filters,
      vendors,
      isQboRefundPaymentFF
    )
  );

  useEffect(() => {
    vendorsStoreActions.list({ orgId });
  }, []);

  useEffect(() => {
    if (listParams.filters && vendors.length) {
      setFilterMenuItems(
        getDashboardFilters(
          listParams.filters.status,
          listParams.filters,
          vendors,
          isQboRefundPaymentFF
        )
      );
    }
  }, [vendors, listParams.filters]);

  const clearAllFilters = () => {
    setFilterMenuItems(
      Object.keys(filterMenuItems)
        .map((type) => ({
          type,
          label: filterMenuItems[type].label,
          options: filterMenuItems[type].options.map((option) => ({
            ...option,
            value: false,
            selected: false,
          })),
        }))
        .reduce((prev, { type, label, options }) => ({ ...prev, [type]: { label, options } }), {})
    );

    setSearch({
      ...listParams.filters,
      vendors: undefined,
      statusesInDashboard: undefined,
      dueDates: undefined,
    });
  };

  const handleFiltersChange = ({ type, options = [] }: FiltersChangeInfo) => {
    if (type) {
      setFilterMenuItems({ ...filterMenuItems, [type]: { ...filterMenuItems[type], options } });
      setSearch({
        ...listParams.filters,
        [type]: options
          .filter(({ value }) => value)
          .map(({ id, label }) => {
            if (id) return id;

            const splitedTranslationLabel = label.split('.');

            return splitedTranslationLabel[splitedTranslationLabel.length - 1];
          }),
      });
    } else {
      clearAllFilters();
    }
  };

  return (
    <Flex flexDir="column" minHeight="100%">
      <QBDashboardContentWrapper>
        <Tabs
          variant="line"
          index={TABS.indexOf(listParams.filters.status)}
          onChange={onChangeTab}
          isLazy
        >
          <Box bg="white" position="sticky" top="0" zIndex="2">
            <QBOHeader
              isSwitcherTooltipVisible={isSwitcherTooltipVisible}
              onCloseTooltip={onCloseTooltip}
            />
            <Tabs.TabList m="1rem 2rem 0">
              <Tabs.Tab data-testid="dashboard-tab-unpaid" eventName="tab-event-unpaid">
                <MIFormattedText label="paymentDashboard.tabs.unpaid" />
              </Tabs.Tab>
              <Tabs.Tab data-testid="dashboard-tab-scheduled" eventName="tab-event-scheduled">
                <MIFormattedText label="paymentDashboard.tabs.scheduled" />
              </Tabs.Tab>
              <Tabs.Tab data-testid="dashboard-tab-paid" eventName="tab-event-paid">
                <MIFormattedText label="paymentDashboard.tabs.paid" />
              </Tabs.Tab>
            </Tabs.TabList>
            <QBODashboardActions>
              <SearchInput />
              <DashboardAddNewBillButton />
            </QBODashboardActions>
          </Box>
          <FilterWrapper>
            <Filters
              currentStatus={listParams.filters.status}
              isBillsSyncRunning={isFullOrgSyncRunning}
              filterMenuItems={DASHBOARD_FILTER_ITEMS.filter(
                (filterItemName) => filterMenuItems[filterItemName]
              ).map((type) => ({
                type,
                ...filterMenuItems[type],
              }))}
              onChange={({ type, options }) => handleFiltersChange({ type, options })}
            />
          </FilterWrapper>
          <Tabs.TabPanels m="1rem 2rem 0" w="auto">
            <Tabs.TabPanel>
              <QBDashboardTab status={QB_DASHBOARD_TAB_STATUS.UNPAID} />
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <QBDashboardTab status={QB_DASHBOARD_TAB_STATUS.SCHEDULED} />
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <QBDashboardTab status={QB_DASHBOARD_TAB_STATUS.PAID} />
            </Tabs.TabPanel>
          </Tabs.TabPanels>
        </Tabs>
        <QBDashboardLegalFooterWrapper isOpenFooter={isOpenFooter} isPaginationOn={isPaginationOn}>
          <QBOLegalFooter />
        </QBDashboardLegalFooterWrapper>
        <QBODashboardFooter isOpenFooter={isOpenFooter} />
      </QBDashboardContentWrapper>
      {!isPayBillSuccessModalVisible && FTUModal}
      {isPayBillSuccessModalVisible && SinglePayBillSuccessModal}
      {isPayBillSuccessModalVisible && <BatchPaymentsSuccessModal onDone={onDone} />}
      {isPayBillSuccessModalVisible && JustPaySuccessModal}
      {EditVendorModal}
      {VerifyMicroDepositDialog}
      {!isPayBillSuccessModalVisible && ManageDefaultSwitcherStateModal}
    </Flex>
  );
};

const QBDashboardContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${({ isOpenFooter }) =>
    isOpenFooter ? `calc(100vh - ${BATCH_FOOTER_HEIGHT})` : '100vh'};
`;

const QBODashboardActions = styled.div`
  display: flex;
  position: absolute;
  top: 6rem;
  right: 2rem;
`;

const CHAKRA_PAGINATION_MARGIN_BOTTOM_VAR = 'var(--chakra-space-8)';
const QBDashboardLegalFooterWrapper = styled.div<{
  isPaginationOn?: boolean;
  isOpenFooter?: boolean;
}>`
  margin-top: ${({ isPaginationOn }) =>
    isPaginationOn ? `calc(10rem - ${CHAKRA_PAGINATION_MARGIN_BOTTOM_VAR})` : '10rem'};
  margin-bottom: ${({ isOpenFooter }) => (isOpenFooter ? BATCH_FOOTER_HEIGHT : '0')};
  animation: ${({ isOpenFooter }) =>
    isOpenFooter
      ? css`0.5s linear alternate ${keyframes({
          from: { 'margin-bottom': '0' },
          to: { 'margin-bottom': BATCH_FOOTER_HEIGHT },
        })};`
      : '0s'};
`;

const FilterWrapper = styled.div`
  position: relative;
  margin-top: 2rem;
  padding: 0 1.6rem;
  z-index: 1;
`;
