import { ReactNode } from 'react';
import { Modal } from './Modal';

type Props = {
  size?: string;
  isOpen: boolean;
  onClose: () => void;
  title?: string | ReactNode;
  text?: string | ReactNode;
  primaryButtonLabel?: string | ReactNode;
  primaryButtonClick?: () => void;
  secondaryButtonLabel?: string | ReactNode;
  secondaryButtonClick?: () => void;
  children?: ReactNode;
};

export const ConfirmModal = ({
  isOpen,
  onClose,
  title,
  text,
  primaryButtonLabel,
  primaryButtonClick,
  secondaryButtonLabel,
  secondaryButtonClick,
  children,
  size,
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} size={size}>
    <Modal.CloseButton />
    <Modal.Header>
      <Modal.HeaderTitle>{title}</Modal.HeaderTitle>
      <Modal.HeaderSubTitle>{text}</Modal.HeaderSubTitle>
    </Modal.Header>
    {children ? <Modal.Body>{children}</Modal.Body> : null}
    <Modal.ButtonContent>
      {secondaryButtonLabel ? (
        <Modal.Button variant="secondary" size="md" onClick={secondaryButtonClick}>
          {secondaryButtonLabel}
        </Modal.Button>
      ) : null}
      {primaryButtonLabel ? (
        <Modal.Button variant="primary" size="md" onClick={primaryButtonClick}>
          {primaryButtonLabel}
        </Modal.Button>
      ) : null}
    </Modal.ButtonContent>
  </Modal>
);
