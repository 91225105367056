import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal } from '@melio/billpay-design-system';
import { analytics } from 'src/app/version-2/externals';
import {
  BATCH_BULK_EVENT_PAGE,
  SAVE_MEMO_TO_VENDOR_CLICK,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { SkeletonLoading } from './MemoForVendorModal.styles';

interface MemoForVendorModalProps {
  isOpen: boolean;
}

export const MemoForVendorModal: FC<MemoForVendorModalProps> = ({ isOpen }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const paymentIntent: BatchBulkPaymentIntent = useSelector(
    batchBulkSelectors.selectMemoForVendorModalBatchBulk
  ) as BatchBulkPaymentIntent;
  const [note, setNote] = useState<string>(paymentIntent.payment.note || '');
  const [memoLoading, setMemoLoading] = useState<boolean>(false);
  const { bills, vendorId } = paymentIntent.payment;
  const partialBillIds = useSelector(
    batchBulkSelectors.selectPartialBillIdsOfPayment(paymentIntent.id)
  );
  const billDefaultMemo = useSelector(batchBulkSelectors.selectBillDefaultMemo(paymentIntent.id));
  const billIds = bills?.map((bill) => bill.id);
  const paymentIntentNote = paymentIntent.payment.note;

  const translations = {
    combinedPayments: formatMessage({ id: 'batchBulkPage.modals.memoForVendor.combinedPayments' }),
    invoiceNum: formatMessage({ id: 'batchBulkPage.modals.memoForVendor.invoiceNum' }),
    accountNum: formatMessage({ id: 'batchBulkPage.modals.memoForVendor.accountNum' }),
  };

  useEffect(() => {
    handleDefaultMemo();

    if (paymentIntent.id && !billDefaultMemo) {
      // fetching intuit account number once if needed
      dispatch(
        batchBulkActions.fetchDefaultMemo({ paymentIntentId: paymentIntent.id, translations })
      );
    }

    return () => {
      if (billDefaultMemo && !billDefaultMemo?.init) {
        // avoid value reassignment on the second time
        dispatch(
          batchBulkActions.setBillsDefaultMemo({
            ...billDefaultMemo,
            init: true,
          })
        );
      }
    };
  }, [paymentIntent?.id, billDefaultMemo]);

  const onButtonClick = () => {
    dispatch(
      batchBulkActions.updatedPaymentIntentDTOSuccess({
        id: paymentIntent.id,
        changes: { payment: { ...paymentIntent.payment, note } } as Partial<BatchBulkPaymentIntent>,
      })
    );
    onCloseClick();
    analytics.track(BATCH_BULK_EVENT_PAGE, SAVE_MEMO_TO_VENDOR_CLICK, {
      billIds,
      partialBillIds,
      vendorId,
    });
  };

  const onCloseClick = () => {
    dispatch(batchBulkActions.setMemoForVendorModalAsClosed());
  };

  const handleDefaultMemo = () => {
    // avoid value assignment: when having existing note or opening modal for the second time
    if (paymentIntentNote || billDefaultMemo?.init) return;

    if (typeof billDefaultMemo === 'undefined') {
      setMemoLoading(true);
    } else {
      setMemoLoading(false);
      setNote(billDefaultMemo?.note || '');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseClick}>
      <Modal.Header>
        <Modal.HeaderTitle>
          {formatMessage({ id: 'batchBulkPage.modals.memoForVendor.title' })}
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.CloseButton />
      <Modal.Body>
        {memoLoading ? (
          <SkeletonLoading />
        ) : (
          <Input
            data-testid="memo-input"
            variant="flushed"
            autoFocus
            value={note}
            onChange={(event) => setNote(event.target.value)}
            label={formatMessage({ id: 'batchBulkPage.modals.memoForVendor.input.label' })}
            placeholder={formatMessage({
              id: 'batchBulkPage.modals.memoForVendor.input.placeholder',
            })}
            helperText={formatMessage({ id: 'batchBulkPage.modals.memoForVendor.input.info' })}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonContent isCentred>
          <Modal.Button
            data-testid="modal-button"
            onClick={onButtonClick}
            size="md"
            variant="primary"
          >
            {formatMessage({ id: 'batchBulkPage.modals.memoForVendor.button' })}
          </Modal.Button>
        </Modal.ButtonContent>
      </Modal.Footer>
    </Modal>
  );
};
