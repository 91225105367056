import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { getOrgId } from 'src/app/redux/user/selectors';
import locations from 'src/app/pages/locations';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { PAYMENT_FLOW_TYPE } from '../consts';

type GoSchedulePaymentParams = {
  billId: string;
  billOriginId: string;
  initialPaymentAmount: number | null;
};

type Response = [(params: GoSchedulePaymentParams) => void];

const useSchedulePaymentAction = (): Response => {
  const orgId = useSelector(getOrgId);
  const location = useLocation();
  const [historyPush] = useHistoryWithOrgId();

  const goSchedulePayment = useCallback(
    ({ billId, billOriginId, initialPaymentAmount }: GoSchedulePaymentParams) => {
      const redirectUrl = `${generatePath(locations.bill.pay.schedule.successFromDashboard, {
        orgId,
        billId,
      })}?flowType=${PAYMENT_FLOW_TYPE.PAY}`;
      const exitUrl = `${location.pathname}${location.search}`;

      historyPush({
        path: locations.qbDashboard.syncBillByOrigin,
        params: { originId: billOriginId },
        state: {
          redirectUrl,
          exitUrl,
          initialPaymentAmount,
          billId,
        },
      });
    },
    [orgId, location, historyPush]
  );

  return [goSchedulePayment];
};

export default useSchedulePaymentAction;
