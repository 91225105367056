import React from 'react';
import { Box, Flex, Image } from '@melio/billpay-design-system';
import checkMark from 'src/app/images/virtual-card/check-mark.svg';
import { MIFormattedText } from 'src/app/utils/formatting';

type PropsType = {
  title: string;
  subtitle: string;
};

export const CheckListItem = ({ title, subtitle }: PropsType): React.ReactElement => (
  <Flex justifyContent="flex-start" alignItems="start" mb={4}>
    <Image src={checkMark} mr={3} __css={{ marginTop: '0.6rem' }} />
    <Flex flexDirection="column" alignItems="flex-start">
      <Box textStyle="h8-demi">
        <MIFormattedText label={title} />
      </Box>
      <Box textStyle="body3" __css={{ textAlign: 'left' }}>
        <MIFormattedText label={subtitle} />
      </Box>
    </Flex>
  </Flex>
);
