import { Badge as ChakraBadge, ComponentWithAs, BadgeProps as Props } from '@chakra-ui/react';

export type BadgeVariants =
  | 'primary'
  | 'primaryOutlined'
  | 'error'
  | 'errorOutlined'
  | 'declined'
  | 'warning'
  | 'info'
  | 'gray'
  | 'lightGray'
  | 'darkGray'
  | 'black'
  | 'new';

type BadgeProps = Omit<Props, 'variant' | 'size' | 'colorScheme'> & {
  variant: BadgeVariants;
};

export const Badge = ChakraBadge as ComponentWithAs<'span', BadgeProps>;
