import React, { PropsWithChildren } from 'react';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import { getJustPayHeaderLabelValues } from 'src/app/pages/just-pay/justPayJSXUtils';
import JustPayLayoutPage from 'src/app/pages/just-pay/JustPayLayoutPage';
import { JustPayLayoutPageRelativeSteps } from 'src/app/pages/just-pay/justPayTypes';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
  isProcessing: boolean;
};

export const JustPayNoteToVendorLayout = ({
  children,
  onExit,
  onNext,
  onPrev,
  isProcessing,
}: PropsWithChildren<Props>) => {
  const {
    payment: {
      amount,
      vendor: { companyName: vendorCompanyName },
    },
  } = useJustPayStore();

  return (
    <JustPayLayoutPage
      headerLabel="justPay.defaultHeader"
      headerLabelValues={getJustPayHeaderLabelValues(amount, vendorCompanyName)}
      title="justPay.noteToVendor.title"
      onNext={onNext}
      goExit={onExit}
      onPrev={onPrev}
      relativeStep={JustPayLayoutPageRelativeSteps.JustPayNoteToVendor}
      isLoading={isProcessing}
      isQboFooter
    >
      {children}
    </JustPayLayoutPage>
  );
};
