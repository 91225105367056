import isEmpty from 'lodash/isEmpty';
import { CLEAR_STATE } from 'src/app/redux/user/actionTypes';
import {
  createApiCallSlice,
  ON_FAILURE,
  ON_REQUEST,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import api, { RegisterPayloadType } from 'src/app/services/api/auth';
import { clearStateReducer } from 'src/app/helpers/redux/clearStateReducer';
import clientServiceApi from 'src/app/services/api/clientService';
import { composeSlices } from 'src/app/helpers/redux/composeSlice';
import profileStore from 'src/app/modules/profile/profile-store';
import { getIsLoggedIn } from 'src/app/redux/user/selectors';

const name = 'auth';

type Registration = {
  loading?: boolean;
  error?: any | null;
  user?: any;
};

type AuthState = {
  registration: Registration;
};

const initialState: AuthState = {
  registration: {
    loading: false,
    error: null,
    user: null,
  },
};

const registrationReducers = {
  [ON_REQUEST]: (state) => {
    state.registration.loading = true;
    state.registration.error = null;
  },
  [ON_SUCCESS]: (state, action) => {
    state.registration.loading = false;
    state.registration.error = null;
    state.registration.user = action.payload.user;
    state.registration.isFirstTimeVisitedRegularMelio =
      action.payload.isFirstTimeVisitedRegularMelio;
  },
  [ON_FAILURE]: (state, action) => {
    state.registration.loading = false;
    state.registration.error = action.error || null;
  },
  [CLEAR_STATE]: (state) => {
    state.registration = initialState.registration;
  },
};

const emailValidation = async (email, password) => {
  const { validationErrors } = await clientServiceApi.getValidationErrors('userRegistration', {
    email,
    password,
  });

  return isEmpty(validationErrors) ? null : validationErrors;
};

type RegistrationPayload = {
  email: string;
  password: string;
  emailVerificationPath: string;
  registrationOrigin: string;
  registrationFlow: string;
  referringVendor: string;
};

const vendorRegistration = createApiCallSlice<RegistrationPayload, any>({
  name: '[AUTH] VENDOR_REGISTRATION',
  api: api.vendorRegister,
  initialState,
  reducers: registrationReducers,
  validate({ email, password }) {
    return emailValidation(email, password);
  },
});

const regularRegistration = createApiCallSlice<RegisterPayloadType, any>({
  name: '[AUTH] REGULAR_REGISTRATION',
  api: api.register,
  initialState,
  reducers: registrationReducers,
  validate({ email, password }) {
    return emailValidation(email, password);
  },
});

const selectors = {
  authenticated: (state: any) => getIsLoggedIn(state),
  userAuthType: (state: any) => {
    const profile = profileStore.selectors.profile(state);
    const isLoggedIn = selectors.authenticated(state);
    let userAuthType = 'unauthenticated';

    if (isLoggedIn) {
      if (profile?.isGuest) {
        userAuthType = 'guest';
      } else if (!profile?.isEmailVerified) {
        userAuthType = 'emailNotVerified';
      } else {
        userAuthType = 'authenticated';
      }
    }

    return userAuthType;
  },
  registration() {
    const registerSelectors = {
      isLoading(state) {
        return state[name].registration?.loading;
      },
      error(state) {
        return state[name].registration?.error;
      },
      user(state) {
        return state[name].registration?.user;
      },
      isFirstTimeVisitedRegularMelio(state) {
        return state[name].registration?.isFirstTimeVisitedRegularMelio;
      },
    };

    return registerSelectors;
  },
};

const authenticationStore = composeSlices(
  { vendorRegistration, regularRegistration },
  {
    selectors,
    extraReducers: { ...clearStateReducer(initialState) },
  }
);

export default authenticationStore;
