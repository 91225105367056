import React, { useEffect } from 'react';
import MobileAppLayout from 'src/app/components/layout/MobileAppLayout';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { Switch, useRouteMatch, generatePath, useLocation, useHistory } from 'react-router-dom';
import { ENTRY_ACTION } from 'src/app/pages/quickpay/consts';
import { getQBRBillsDefaultFilters } from 'src/app/utils/bills';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import CreateConfirmPage from 'src/app/pages/quickpay/create/CreateConfirmPage';
import EditDatePage from 'src/app/pages/quickpay/operations/EditDatePage';
import EditNotePage from 'src/app/pages/quickpay/operations/EditNotePage';
import CompleteCompanyInfoPage from 'src/app/pages/quickpay/operations/CompleteCompanyInfoPage';
import CreateEntryPage from 'src/app/pages/quickpay/create/CreateEntryPage';
import EditEntryPage from 'src/app/pages/quickpay/edit/EditEntryPage';
import EditConfirmPage from 'src/app/pages/quickpay/edit/EditConfirmPage';
import SelectDeliveryMethodPage from 'src/app/pages/quickpay/operations/SelectDeliveryMethodPage';
import CheckDeliveryMethodPage from 'src/app/pages/quickpay/deliveryMethods/CheckDeliveryMethodPage';
import SuccessPage from 'src/app/pages/quickpay/success/SuccessPage';
import AchDeliveryMethodPage from 'src/app/pages/quickpay/deliveryMethods/AchDeliveryMethodPage';
import BillsListPage from 'src/app/pages/quickpay/bills/QBRBillsListPage';
import HelloPage from 'src/app/pages/quickpay/create/HelloPage';
import * as CONSTS from 'src/app/utils/consts';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { isFirstQBOPage, melioClose } from 'src/app/utils/external-events';
import { QBCashStateType } from 'src/app/utils/types';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { featureFlags } from '@melio/shared-web';
import locations from './locations';
import ErrorPage from './failure/ErrorPage';

const QuickpayEditRouter = () => {
  const match = useRouteMatch<{ paymentId: string }>();
  const { paymentId } = match.params;
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const onExit = () =>
    historyPush({
      path: locations.list.index,
      query: getQBRBillsDefaultFilters(CONSTS.QBR_BILL_LIST_TAB_TYPE.OPEN),
    });

  const onNext = (url: string) => () => historyPush({ path: url });
  const goBack = () => history.goBack();
  const confirmUrl = generatePath(locations.edit.confirm, { orgId, paymentId });

  return (
    <Switch>
      <SmartRoute path={locations.edit.entry}>
        <EditEntryPage />
      </SmartRoute>
      <SmartRoute path={locations.edit.date}>
        <EditDatePage onNext={onNext(confirmUrl)} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.edit.note}>
        <EditNotePage onNext={onNext(confirmUrl)} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.edit.deliveryMethod}>
        <SelectDeliveryMethodPage
          onNext={onNext(confirmUrl)}
          onExit={onExit}
          onPrev={goBack}
          type="edit"
        />
      </SmartRoute>
      <SmartRoute path={locations.edit.check}>
        <CheckDeliveryMethodPage onNext={onNext(confirmUrl)} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.edit.ach}>
        <AchDeliveryMethodPage onNext={onNext(confirmUrl)} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.edit.checkNew}>
        <CheckDeliveryMethodPage onNext={onNext(confirmUrl)} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.edit.achNew}>
        <AchDeliveryMethodPage onNext={onNext(confirmUrl)} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.edit.confirm}>
        <EditConfirmPage onNext={onExit} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
    </Switch>
  );
};

const QuickpayRouter = () => {
  const [historyPush, historyReplace] = useHistoryWithOrgId();
  const location = useLocation<{ entry: ENTRY_ACTION }>();
  const history = useHistory();
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const [mockQBCashErrorPage] = featureFlags.useFeature('bp-qbr-qbcash-balance-error-page');
  const onExit = () =>
    historyPush({
      path: locations.list.index,
      query: getQBRBillsDefaultFilters(CONSTS.QBR_BILL_LIST_TAB_TYPE.OPEN),
    });

  const onNext = (path: string) => () => historyPush({ path });
  const onNextReplaceCurrent = (path: string) => () => historyReplace({ path });
  const goBack = () => history.goBack();
  const closeMelioOrGoBack = () => {
    if (isFirstQBOPage() || location?.state?.entry === ENTRY_ACTION.CONFIRM) {
      melioClose();
    } else {
      goBack();
    }
  };

  useEffect(() => {
    if (qbCashState?.error || mockQBCashErrorPage) {
      historyPush({
        path: locations.errorPage,
        state: { error: qbCashState?.error?.message },
      });
    }
  }, [qbCashState, mockQBCashErrorPage]);

  return (
    <Switch>
      <SmartRoute path={locations.list.hello}>
        <HelloPage />
      </SmartRoute>
      <SmartRoute path={locations.create.entry}>
        <CreateEntryPage />
      </SmartRoute>
      <SmartRoute path={locations.create.date}>
        <EditDatePage onNext={onNext(locations.create.confirm)} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.create.note}>
        <EditNotePage onNext={onNext(locations.create.confirm)} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={locations.create.confirm}>
        <CreateConfirmPage
          onNext={onNext(locations.create.success)}
          onExit={onExit}
          onPrev={closeMelioOrGoBack}
        />
      </SmartRoute>
      <SmartRoute path={locations.create.success}>
        <SuccessPage />
      </SmartRoute>
      <SmartRoute path={locations.deliveryMethods.ach.new} exact>
        <AchDeliveryMethodPage
          onNext={onNextReplaceCurrent(locations.create.confirm)}
          onPrev={goBack}
          onExit={onExit}
        />
      </SmartRoute>
      <SmartRoute path={locations.deliveryMethods.ach.edit} exact>
        <AchDeliveryMethodPage
          onNext={onNextReplaceCurrent(locations.create.confirm)}
          onPrev={goBack}
          onExit={onExit}
        />
      </SmartRoute>
      <SmartRoute path={locations.deliveryMethods.check.new} exact>
        <CheckDeliveryMethodPage
          onNext={onNextReplaceCurrent(locations.create.confirm)}
          onPrev={goBack}
          onExit={onExit}
        />
      </SmartRoute>
      <SmartRoute path={locations.deliveryMethods.check.edit} exact>
        <CheckDeliveryMethodPage
          onNext={onNextReplaceCurrent(locations.create.confirm)}
          onPrev={goBack}
          onExit={onExit}
        />
      </SmartRoute>
      <SmartRoute path={locations.create.deliveryMethod}>
        <SelectDeliveryMethodPage
          onNext={onNext(locations.create.confirm)}
          onPrev={closeMelioOrGoBack}
          onExit={onExit}
          type="new"
        />
      </SmartRoute>
      <SmartRoute path={locations.edit.base}>
        <QuickpayEditRouter />
      </SmartRoute>
      <SmartRoute path={locations.list.index}>
        <MobileAppLayout>
          <BillsListPage />
        </MobileAppLayout>
      </SmartRoute>
      <SmartRoute path={locations.create.completeCompanyInfo}>
        <CompleteCompanyInfoPage
          onNext={onNext(locations.create.success)}
          onPrev={goBack}
          onExit={onExit}
        />
      </SmartRoute>
      <SmartRoute path={locations.errorPage}>
        <ErrorPage onClose={melioClose} />
      </SmartRoute>
    </Switch>
  );
};

export default QuickpayRouter;
