import React from 'react';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';
import { MIFormattedText } from 'src/app/utils/formatting';
import useGetFundingSourceDisplayName from 'src/app/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import { FundingSourceTypesEnum, CardTypeEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  fundingSource: FundingSource;
  goNext: () => void;
};

const FirstIntuitAccountAddedSuccessPage = ({ fundingSource, goNext }: Props) => {
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const fundingSourceType =
    fundingSource?.fundingType === FundingSourceTypesEnum.ACH
      ? fundingSource?.fundingType
      : fundingSource?.cardAccount?.cardType;
  let fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.ach';

  if (fundingSourceType === CardTypeEnum.DEBIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.debitAccount';
  } else if (fundingSourceType === CardTypeEnum.CREDIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.creditAccount';
  }

  return (
    <QBOSuccessLayout
      titleValues={{ type: <MIFormattedText label={fundingTypeText} /> }}
      title="onboarding.fundingSources.bank.intuitAccounts.success.title"
      text="onboarding.fundingSources.bank.intuitAccounts.success.subTitle"
      textValues={{
        displayName: getFundingSourceDisplayName({ fundingSource }),
      }}
      buttonLabel="onboarding.fundingSources.bank.intuitAccounts.success.cta"
      buttonValues={{ type: <MIFormattedText label={fundingTypeText} /> }}
      buttonAction={goNext}
      hideHeader
    />
  );
};

export default FirstIntuitAccountAddedSuccessPage;
