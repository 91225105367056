import type { RecordOf } from 'immutable';
import { batch } from 'react-redux';
import type { UserContextType, CompanyInfoType, UserPreferencesType } from '../../utils/types';
import {
  LOAD_FUNDING_SOURCES,
  LOAD_FUNDING_SOURCES_FAILED,
  LOAD_FUNDING_SOURCES_SUCCESS,
  DELETE_FUNDING_SOURCE,
  DELETE_FUNDING_SOURCE_SUCCESS,
  DELETE_FUNDING_SOURCE_FAILED,
  UPDATE_FUNDING_SOURCE_LABEL,
  UPDATE_FUNDING_SOURCE_LABEL_SUCCESS,
  UPDATE_FUNDING_SOURCE_LABEL_FAILED,
  REMOVE_FUNDING_SOURCE_LABEL,
  REMOVE_FUNDING_SOURCE_LABEL_SUCCESS,
  REMOVE_FUNDING_SOURCE_LABEL_FAILED,
  LOAD_PROFILE,
  LOAD_PROFILE_SUCCESS,
  LOAD_PROFILE_FAILED,
  SET_PROFILE,
  CLEAR_USER_INFO,
  LOAD_DELIVERY_METHODS,
  LOAD_DELIVERY_METHODS_SUCCESS,
  LOAD_DELIVERY_METHODS_FAILED,
  LOAD_COMPANY_INFO,
  LOAD_COMPANY_INFO_SUCCESS,
  LOAD_COMPANY_INFO_FAILED,
  SET_COMPANY_INFO,
  UPDATE_USER_PREFERENCE,
  UPDATE_USER_PREFERENCE_SUCCESS,
  UPDATE_USER_PREFERENCE_FAILED,
  SET_USER_PREFERENCES,
  INIT_USER,
  INIT_USER_SUCCESS,
  CHECK_AND_INIT_USER,
  CHECK_AND_INIT_USER_FINISH,
  VERIFY_FUNDING_SOURCE,
  VERIFY_FUNDING_SOURCE_SUCCESS,
  VERIFY_FUNDING_SOURCE_FAILED,
  UPDATE_ORIGIN_PLAID_ITEM_ID,
} from './actionTypes';

import { FundingSource } from 'src/app/version-2/model/dtos';

export const loadFundingSourcesAction = (
  resolve?: (fundingSources: FundingSource[]) => void,
  reject?: (error: any) => void
) => ({
  type: LOAD_FUNDING_SOURCES,
  resolve,
  reject,
});

export const loadFundingSourcesSuccessAction = (fundingSources: FundingSource[]) => ({
  type: LOAD_FUNDING_SOURCES_SUCCESS,
  fundingSources,
});

export const loadFundingSourcesFailedAction = () => ({
  type: LOAD_FUNDING_SOURCES_FAILED,
});

export const deleteFundingSourceAction = (
  id: number,
  resolve: () => void,
  reject: (error: any) => void
) => ({
  type: DELETE_FUNDING_SOURCE,
  id,
  resolve,
  reject,
});

export const deleteFundingSourceSuccessAction = (deletedFundingSourceId: number) => ({
  type: DELETE_FUNDING_SOURCE_SUCCESS,
  deletedFundingSourceId,
});

export const deleteFundingSourceFailedAction = () => ({
  type: DELETE_FUNDING_SOURCE_FAILED,
});

export const updateFundingSourceLabelAction = (
  id: number,
  nickname: string,
  resolve: (value?: any) => void,
  reject: (error: any) => void
) => ({
  type: UPDATE_FUNDING_SOURCE_LABEL,
  id,
  nickname,
  resolve,
  reject,
});

export const updateFundingSourceLabelSuccessAction = (
  updatedLabelFundingSource: FundingSource
) => ({
  type: UPDATE_FUNDING_SOURCE_LABEL_SUCCESS,
  updatedLabelFundingSource,
});

export const updateFundingSourceLabelFailedAction = () => ({
  type: UPDATE_FUNDING_SOURCE_LABEL_FAILED,
});

export const removeFundingSourceLabelAction = (
  id: number,
  resolve: (value?: any) => void,
  reject: (error: any) => void
) => ({
  type: REMOVE_FUNDING_SOURCE_LABEL,
  id,
  resolve,
  reject,
});

export const removeFundingSourceLabelSuccessAction = (
  removedLabelFundingSource: FundingSource
) => ({
  type: REMOVE_FUNDING_SOURCE_LABEL_SUCCESS,
  removedLabelFundingSource,
});

export const removeFundingSourceLabelFailedAction = () => ({
  type: REMOVE_FUNDING_SOURCE_LABEL_FAILED,
});

export const loadProfileAction = (resolve: () => void, reject: (error: any) => void) => ({
  type: LOAD_PROFILE,
  resolve,
  reject,
});
export const verifyFundingSourceAction = (
  id: number,
  token: string,
  amount1: number,
  amount2: number,
  resolve: (value?: unknown) => void,
  reject: (error: any) => void
) => ({
  type: VERIFY_FUNDING_SOURCE,
  id,
  token,
  amount1,
  amount2,
  resolve,
  reject,
});

export const loadProfileSuccessAction = (profile: RecordOf<UserContextType>) => ({
  type: LOAD_PROFILE_SUCCESS,
  profile,
});

export const loadProfileFailedAction = () => ({
  type: LOAD_PROFILE_FAILED,
});

export const setProfileAction = (profile: RecordOf<UserContextType>, organizations?: any) => ({
  type: SET_PROFILE,
  profile,
  organizations,
});

export const clearUserInfoAction = () => ({
  type: CLEAR_USER_INFO,
});
export const verifyFundingSourceSuccessAction = (verifiedFundingSourceId: number) => ({
  type: VERIFY_FUNDING_SOURCE_SUCCESS,
  verifiedFundingSourceId,
});

export const verifyFundingSourceFailedAction = (
  verifiedFundingSourceId: number,
  errorCode: string
) => ({
  type: VERIFY_FUNDING_SOURCE_FAILED,
  verifiedFundingSourceId,
  errorCode,
});

export const loadDeliveryMethodsAction = (resolve?: () => void, reject?: (error: any) => void) => ({
  type: LOAD_DELIVERY_METHODS,
  resolve,
  reject,
});

export const loadDeliveryMethodsSuccessAction = (deliveryMethods: FundingSource[]) => ({
  type: LOAD_DELIVERY_METHODS_SUCCESS,
  deliveryMethods,
});

export const loadDeliveryMethodsFailedAction = () => ({
  type: LOAD_DELIVERY_METHODS_FAILED,
});

export const loadCompanyInfoAction = (
  resolve: (companyInfo: RecordOf<CompanyInfoType>) => void,
  reject: (error: any) => void
) => ({
  type: LOAD_COMPANY_INFO,
  resolve,
  reject,
});

export const loadCompanyInfoSuccessAction = (companyInfo: RecordOf<CompanyInfoType>) => ({
  type: LOAD_COMPANY_INFO_SUCCESS,
  companyInfo,
});

export const loadCompanyInfoFailedAction = () => ({
  type: LOAD_COMPANY_INFO_FAILED,
});

export const setCompanyInfoAction = (companyInfo: RecordOf<CompanyInfoType>) => ({
  type: SET_COMPANY_INFO,
  companyInfo,
});

export const updateUserPreferenceAction = (
  id: string,
  value: any,
  resolve: (value?: any) => void = () => null,
  reject: (error: any) => void = () => null
) => ({
  type: UPDATE_USER_PREFERENCE,
  id,
  value,
  resolve,
  reject,
});

export const updateUserPreferenceSuccessAction = (id: string, value: any) => ({
  type: UPDATE_USER_PREFERENCE_SUCCESS,
  id,
  value,
});

export const updateUserPreferenceFailedAction = () => ({
  type: UPDATE_USER_PREFERENCE_FAILED,
});

export const setUserPreferencesAction = (userPreferences: RecordOf<UserPreferencesType>) => ({
  type: SET_USER_PREFERENCES,
  userPreferences,
});

export const updateOriginPlaidItemIdAction = (id: number | null) => ({
  type: UPDATE_ORIGIN_PLAID_ITEM_ID,
  id,
});

export const initUserAction = (
  user: Record<string, any>,
  isLoggedIn = false,
  resolve: () => void,
  reject: (error: any) => void
) => ({
  type: INIT_USER,
  user,
  isLoggedIn,
  resolve,
  reject,
});

export const initUserSuccessAction = (isLoggedInAs: boolean) => ({
  type: INIT_USER_SUCCESS,
  isLoggedInAs,
});

export const checkAndInitUserAction = (
  params?: { orgId?: number; realmId?: string; skip2FACheck?: boolean },
  resolve: (value?: any) => void = () => null,
  reject: (error: any) => void = () => null
) => ({
  type: CHECK_AND_INIT_USER,
  ...(params || {}),
  resolve,
  reject,
});

export const checkAndInitUserFinishAction = () => ({
  type: CHECK_AND_INIT_USER_FINISH,
});

export const updateCompanyInfoAction = (
  dispatch: any,
  companyInfo: RecordOf<CompanyInfoType>,
  profile: RecordOf<UserContextType>
) => {
  batch(() => {
    let profileUpdateData = { orgName: companyInfo.companyName };

    if (
      companyInfo.contactFirstName &&
      companyInfo.contactLastName &&
      !profile.firstName &&
      !profile.lastName
    ) {
      profileUpdateData = {
        ...profileUpdateData,
        firstName: companyInfo.contactFirstName,
        lastName: companyInfo.contactLastName,
      } as any;
    }

    dispatch(setProfileAction(profile.merge(profileUpdateData)));
    dispatch(setCompanyInfoAction(companyInfo));
  });
};
