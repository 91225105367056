import React from 'react';
import styled from 'styled-components';
import { MICardForm } from 'src/app/components/common/MICard';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';

export type Props = {
  onLogoutAndUnlink: () => void;
};

const QBOAccountSettingsPage = ({ onLogoutAndUnlink }: Props) => (
  <MICardForm>
    <HintContainer>
      <MIFormattedText label="qbo.settings.account.logoutHint" />
    </HintContainer>
    <ButtonsContainer>
      <MIButton
        variant={CONSTS.BUTTON_VARIANT.SECONDARY}
        fullWidth
        label="qbo.settings.account.logout"
        onClick={onLogoutAndUnlink}
      />
    </ButtonsContainer>
  </MICardForm>
);

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
  * {
    width: 100%;
  }
`;

const HintContainer = styled.div`
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.text.color.black};
  ${(props) => props.theme.components?.AccountSettings?.HintContainer}
`;

export default QBOAccountSettingsPage;
