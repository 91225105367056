import {
  AmexIcon,
  ArrowDropDownIcon,
  ArrowExpandIcon,
  ArrowSortingIcon,
  ArrowBack,
  BankIcon,
  BankOfAmericaIcon,
  BlueInfoIcon,
  CalendarIcon,
  ChaseIcon,
  CheckedIcon,
  CitiBankIcon,
  CloseIcon,
  DinersIcon,
  DiscoverIcon,
  CreditCardIcon,
  DebitCardIcon,
  DropdownIcon,
  EditIcon,
  ErrorIcon,
  FileUploadIcon,
  FilledArrowIcon,
  FITBIcon,
  IdeaIcon,
  IndeterminateIcon,
  InfoIcon,
  InspectIcon,
  JPMorganIcon,
  MastercardIcon,
  MemoIcon,
  QBCashIcon,
  QuestionMarkIcon,
  QBLogoSemiTransparentIcon,
  SearchIcon,
  SentIcon,
  SuccessIcon,
  SuccessOutlineIcon,
  SVBIcon,
  USBankIcon,
  USDIcon,
  VisaCardIcon,
  VisaIcon,
  WellsFargoIcon,
  YellowInfoIcon,
  LockIcon,
  EyeIcon,
  EyeIconCrossed,
  CreditCardIllustrationIcon,
  DebitCardIllustrationIcon,
  BankIllustrationIcon,
  QboFooterLogos,
  ProtectIcon,
  ProcessIcon,
} from '../../assets/images';

export const icons = {
  amex: AmexIcon,
  'arrow-dropdown': ArrowDropDownIcon,
  'arrow-expand': ArrowExpandIcon,
  'arrow-sorting': ArrowSortingIcon,
  'arrow-back': ArrowBack,
  bank: BankIcon,
  'bank-illustration': BankIllustrationIcon,
  'bank-of-america': BankOfAmericaIcon,
  'blue-info': BlueInfoIcon,
  calendar: CalendarIcon,
  'chase-bank': ChaseIcon,
  checked: CheckedIcon,
  'citi-bank': CitiBankIcon,
  close: CloseIcon,
  'credit-card': CreditCardIcon,
  'credit-card-illustration': CreditCardIllustrationIcon,
  'debit-card': DebitCardIcon,
  'debit-card-illustration': DebitCardIllustrationIcon,
  diners: DinersIcon,
  discover: DiscoverIcon,
  dropdown: DropdownIcon,
  edit: EditIcon,
  error: ErrorIcon,
  'file-upload': FileUploadIcon,
  'filled-arrow': FilledArrowIcon,
  'fifth-third-bank': FITBIcon,
  'jpmorgan-bank': JPMorganIcon,
  idea: IdeaIcon,
  indeterminate: IndeterminateIcon,
  info: InfoIcon,
  inspect: InspectIcon,
  mastercard: MastercardIcon,
  visaCard: VisaCardIcon,
  memo: MemoIcon,
  'qb-cash': QBCashIcon,
  'qb-semitransparent-logo': QBLogoSemiTransparentIcon,
  'question-mark': QuestionMarkIcon,
  search: SearchIcon,
  sent: SentIcon,
  'silicon-valley-bank': SVBIcon,
  success: SuccessIcon,
  'success-outline': SuccessOutlineIcon,
  'us-bank': USBankIcon,
  usd: USDIcon,
  visa: VisaIcon,
  'wells-fargo-bank': WellsFargoIcon,
  'yellow-info': YellowInfoIcon,
  lock: LockIcon,
  'eye-icon': EyeIcon,
  'eye-icon-crossed': EyeIconCrossed,
  'qbo-footer-logos': QboFooterLogos,
  protect: ProtectIcon,
  process: ProcessIcon,
};

export type IconType = keyof typeof icons;
