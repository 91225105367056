import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import { Tooltip } from '@melio/billpay-design-system';

type Props = {
  tooltip?: string;
  children: React.ReactNode;
};

const MainActionWrapper = ({ tooltip, children }: Props) => {
  if (tooltip) {
    return <Tooltip label={<MIFormattedText label={tooltip} />}>{children}</Tooltip>;
  }

  return <>{children}</>;
};

export default MainActionWrapper;
