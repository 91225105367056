import React from 'react';
import { featureFlags } from '@melio/shared-web';
import { StyledLink } from 'src/app/version-2/components/CheckFee/CheckFee.styles';
import { CheckFeeModal } from 'src/app/version-2/components/CheckFee/CheckFeeModal';
import { analytics } from 'src/app/version-2/externals';
import {
  DeliveryMethod,
  FundingSource,
  OrganizationFeeCatalog,
} from 'src/app/version-2/model/dtos';
import {
  CheckFeeEnum,
  DeliveryEnum,
  FeatureFlagsEnum,
  FeeVariantsEnum,
  FundingSourceTypesEnum,
} from 'src/app/version-2/model/enums';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects/batchBulkItem';
import {
  isCheckDeliveryMethod,
  isFastDeliveryType,
} from 'src/app/version-2/utils/deliveryMethod.utils';
import { getCompleteFeeByDeliveryType, isAchCheckFeeType } from 'src/app/version-2/utils/fee.utils';

export const isCheckFeeClosed = (value?: string) => value === CheckFeeEnum.CLOSED;
export const isCheckFeeOpen = (value?: string) =>
  isCheckFeeOpenWithPromotions(value) || isCheckFeeOpenNoPromotions(value);
export const isCheckFeeOpenWithPromotions = (value?: string) =>
  value === CheckFeeEnum.OPEN_WITH_PROMOTIONS;
export const isCheckFeeOpenNoPromotions = (value?: string) =>
  value === CheckFeeEnum.OPEN_NO_PROMOTIONS;

export const isAchFundingType = (selectedFundingSource?: any) =>
  selectedFundingSource?.fundingType === FundingSourceTypesEnum.ACH;

export const isBatchAchCheck = (data?: BatchBulkItem[]) =>
  (data || []).some((payment) => {
    const isAch = payment.selectedPaymentMethod?.fundingType?.includes(FundingSourceTypesEnum.ACH);
    const isFastChecks = payment.fastFee || isFastDeliveryType(payment.feeType);
    const isCheck = payment.selectedDeliveryMethod?.deliveryType?.includes(DeliveryEnum.CHECK);

    return isCheck && isAch && !isFastChecks;
  });

// verify ach-to-check in single/batch flows
export interface IsAchCheckPaymentType {
  deliveryMethod?: DeliveryMethod;
  fundingSource?: FundingSource;
  deliveryPreference?: null | string | undefined;
  batchData?: any;
}

export const isAchCheckPayment = ({
  deliveryMethod,
  fundingSource,
  deliveryPreference,
  batchData,
}: IsAchCheckPaymentType) => {
  if (batchData) {
    return isBatchAchCheck(batchData);
  }

  const isCheck = isCheckDeliveryMethod(deliveryMethod);
  const hasValidFS = isAchFundingType(fundingSource);
  const isFastDelivery = isFastDeliveryType(deliveryPreference);

  return isCheck && hasValidFS && !isFastDelivery;
};

// get payment fee component values (confirm)
interface GetPaymentFeeValuesType {
  fundingSource?: FundingSource;
  feeType?: string;
  fee: number;
  catalogFee: React.ReactNode | string | null;
}

interface GetPaymentFeeValuesTypeReturn {
  hasCheckZeroFeeUI: boolean;
  hasAchCheckFee: boolean;
  hasPromotions: boolean;
}
export const getPaymentFeeValues = ({
  fundingSource,
  feeType,
  fee,
  catalogFee,
}: GetPaymentFeeValuesType): GetPaymentFeeValuesTypeReturn => {
  const checkFeeFlag = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.CHECK_FEES,
    CheckFeeEnum.CLOSED
  );
  const checkFeeEnabledWithPromotions = isCheckFeeOpenWithPromotions(checkFeeFlag);
  const hasValidFS = isAchFundingType(fundingSource);
  const hasFee = fee > 0;
  const hasAchCheckFee = isAchCheckFeeType(feeType);
  const hasZeroFeeUI = checkFeeEnabledWithPromotions && hasValidFS && !hasFee && !!catalogFee;

  return {
    hasCheckZeroFeeUI: hasZeroFeeUI,
    hasAchCheckFee,
    hasPromotions: checkFeeEnabledWithPromotions,
  };
};

interface GetNoticeTypes {
  fundingSource?: any;
  catalog: OrganizationFeeCatalog | null;
  onModalOpen: any;
}
export const getNotice = ({ fundingSource, catalog, onModalOpen }: GetNoticeTypes) => {
  const checkFeeFlag = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.CHECK_FEES,
    CheckFeeEnum.CLOSED
  );
  const catalogFee = getCompleteFeeByDeliveryType({
    fees: catalog,
    deliveryType: FeeVariantsEnum.ACH_TO_CHECK,
  });
  const checkFeeEnabled = isCheckFeeOpen(checkFeeFlag);
  const checkFeeEnabledWithPromotions = isCheckFeeOpenWithPromotions(checkFeeFlag);

  const defaultValues = {
    description: undefined,
    descriptionValues: undefined,
  };

  // settings page has no funding source
  const isSettingsPage = !fundingSource;
  const isAchFS = isSettingsPage || isAchFundingType(fundingSource);

  if (!isAchFS) {
    // card funding source
    if (checkFeeEnabled) {
      // showing new free message in case of check it not using a valid funding source (ach)
      const openModal = () => {
        analytics.track('MoreInformation', 'Learned', { infoType: 'check-fee' });
        onModalOpen();
      };
      const learnMoreLink = (...chunks) => (
        <StyledLink onClick={openModal}>
          <CheckFeeModal />
          {React.Children.toArray(chunks)}
        </StyledLink>
      );

      return {
        description: 'deliveryMethods.notDefinedInfo.check.appliedFee.free',
        descriptionValues: {
          learnMoreLink,
        },
      };
    }

    return defaultValues;
  }

  if (checkFeeEnabled && catalogFee) {
    const openModal = () => {
      analytics.track('MoreInformation', 'Learned', { infoType: 'check-fee' });
      onModalOpen();
    };
    const learnMoreLink = (...chunks) => (
      <StyledLink onClick={openModal}>
        <CheckFeeModal />
        {React.Children.toArray(chunks)}
      </StyledLink>
    );

    const promotionsPrefix = checkFeeEnabledWithPromotions
      ? 'appliedFee'
      : 'appliedFeeNoPromotions';
    const descriptionPrefix = isSettingsPage ? 'settingsDescription' : 'description';

    return {
      description: `deliveryMethods.notDefinedInfo.check.${promotionsPrefix}.${descriptionPrefix}`,
      descriptionValues: {
        learnMoreLink,
        fee: catalogFee,
      },
    };
  }

  return defaultValues;
};
