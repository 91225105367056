import { FcbOptionLabelsEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/FcbOptionsLabelsEnum.enum';
import { FcbOptionValuesEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/FcbOptionValuesEnum.enum';

type FcbOption = {
  label: FcbOptionLabelsEnum;
  value: FcbOptionValuesEnum;
};

export const FcbOptions: FcbOption[] = [
  {
    label: FcbOptionLabelsEnum.StandaloneBusiness,
    value: FcbOptionValuesEnum.StandaloneBusiness,
  },
  {
    label: FcbOptionLabelsEnum.PartOfLargerOrganization,
    value: FcbOptionValuesEnum.PartOfLargerOrganization,
  },
  {
    label: FcbOptionLabelsEnum.NotSure,
    value: FcbOptionValuesEnum.NotSure,
  },
];
