import React from 'react';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';
import SuccessIllustration from '../../images/qbo/success-check.svg';

type Props = {
  complete: () => void;
};

const MicroDepositsQBOSuccessPage = ({ complete }: Props) => (
  <QBOSuccessLayout
    illustration={SuccessIllustration}
    title="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
    text="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
    buttonLabel="fundingSources.microDeposits.complete"
    buttonAction={complete}
    hideHeader
  />
);

export default MicroDepositsQBOSuccessPage;
