import { paymentsApi } from 'src/app/version-2/api/payments.api';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { paymentsActions } from './payments.slice';

import { call, put, select, takeEvery } from 'redux-saga/effects';

function* fetchPaymentByIdHandler(action: ReturnType<typeof paymentsActions.fetchPaymentById>) {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const { paymentId } = action.payload;
    const { object: payment } = yield call(paymentsApi.fetchPaymentById, { paymentId, orgId });

    yield put(paymentsActions.setPayment(payment ?? []));
  } catch (error) {
    yield put(paymentsActions.setPayment([]));
  }
}

function* fetchPaymentsHandler(action) {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);

    yield put(paymentsActions.setIsFetching(true));

    const { objects: payments } = yield call(paymentsApi.list, {
      orgId,
      ...action.payload,
    });

    yield put(paymentsActions.fetchPaymentsSuccess(payments ?? []));
  } catch (e) {
    yield put(paymentsActions.fetchPaymentsFail());
  } finally {
    yield put(paymentsActions.setIsFetching(false));
  }
}

export function* watchPaymentsSaga() {
  yield takeEvery(paymentsActions.fetchPaymentById, fetchPaymentByIdHandler);
  yield takeEvery(paymentsActions.fetchPayments, fetchPaymentsHandler);
}
