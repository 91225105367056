import React from 'react';
import styled from 'styled-components';
import { useEditCheckAddressStatus } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import { AreaLoader } from '@melio/billpay-design-system';
import GeneralFlowErrorContainer from 'src/app/pages/quickbooks/GeneralFlowErrorContainer';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import failedToLoad from 'src/app/images/qbo/failed-to-load-002-icon.png';

type Props = {
  token: string;
};

const CheckBlockedPage = ({ token }: Props) => {
  const { isPaymentLoading, organization } = useEditCheckAddressStatus({
    token,
  });
  const site = useSiteContext();

  if (isPaymentLoading) return <AreaLoader />;

  const companyEmail = organization?.userOrganizations?.[0]?.user?.email;
  const supportEmail = site.config.support.email;

  return (
    <GeneralFlowErrorContainer
      hideExitButton
      illustration={failedToLoad}
      illustrationStyle={{ width: 96, height: 96, marginBottom: '2rem' }}
      subtitle="vendors.editCheckAddress.checkBlocked.description"
      subtitleValues={{
        companyName: organization?.companyName,
        companyEmail: <EmailLink href={`mailto:${companyEmail}`}>{companyEmail}</EmailLink>,
        supportEmail: <EmailLink href={`mailto:${supportEmail}`}>{supportEmail}</EmailLink>,
      }}
    />
  );
};

export default CheckBlockedPage;

const EmailLink = styled.a`
  text-decoration: none;
  color: #2ca01c;
`;
