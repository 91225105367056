import React from 'react';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { Box, Tooltip } from '@melio/billpay-design-system';

import { useSelector } from 'react-redux';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { DeliveryMethodType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const QBODeliveryMethodCell = ({ item }: Props) => {
  const deliveryMethod: DeliveryMethodType = useSelector(
    deliveryMethodsStore.selectors.byId(item.deliveryMethodId)
  );

  const deliveryType = deliveryMethod?.deliveryType;

  if (deliveryType === DELIVERY_TYPE.VIRTUAL) {
    return (
      <Tooltip label={<MIFormattedText label="paymentDashboard.columns.deliveryMethod.tooltip" />}>
        <Box as="span" color="gray.400">
          <MIFormattedText label="paymentDashboard.columns.deliveryMethod.virtual" />
        </Box>
      </Tooltip>
    );
  }

  if (deliveryType === DELIVERY_TYPE.ACH) {
    return <MIFormattedText label="paymentDashboard.columns.deliveryMethod.ach" />;
  }

  if (deliveryType === DELIVERY_TYPE.CHECK) {
    return <MIFormattedText label="paymentDashboard.columns.deliveryMethod.check" />;
  }

  if (deliveryType === DELIVERY_TYPE.INTERNATIONAL) {
    return <MIFormattedText label="paymentDashboard.columns.deliveryMethod.international" />;
  }

  if (deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
    return <MIFormattedText label="paymentDashboard.columns.deliveryMethod.virtualCard" />;
  }

  if (deliveryType === DELIVERY_TYPE.CARD) {
    return (
      <Tooltip
        label={<MIFormattedText label="viewBillPaymentActivity.deliveryMethod.modifiedByVendor" />}
      >
        <Box as="span">
          <MIFormattedText label="paymentDashboard.columns.deliveryMethod.debit" />
        </Box>
      </Tooltip>
    );
  }

  return null;
};

export default QBODeliveryMethodCell;
