import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import locations from 'src/app/utils/locations';
import { useSelector, useDispatch } from 'react-redux';
import { getFundingSources, getOrgId } from 'src/app/redux/user/selectors';
import analytics from 'src/app/services/analytics';
import { MIFormattedText } from 'src/app/utils/formatting';
import { getOrgFeeFundingSource } from 'src/app/redux/organization/selectors';
import { useModal } from 'src/app/helpers/react/useModal';
import { OrganizationBillingFee } from 'src/app/utils/types';
import { removeOrganizationFeeFundingSource } from 'src/app/redux/organization/actions';
import {
  clearSelectedFundingSourceAction,
  setFeeFundingSourceFlow,
} from 'src/app/redux/payBillWizard/actions';
import { FEE_FLOWS } from 'src/app/redux/payBillWizard/types';
import { getSelectedFundingSource } from 'src/app/redux/payBillWizard/selectors';
import { getFundingSourceCard } from './billing-method-cards/utils';
import { RemovePaymentModal } from './RemovePaymentModal';
import { CardContainer, Title } from './styles';

const eventPage = 'settings-billing';

interface Props {
  feeFundingSource?: OrganizationBillingFee;
}

export const BillingMethodCard: React.FC<Props> = ({ feeFundingSource }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const selectedFeeFundingSource = useSelector(getOrgFeeFundingSource);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const allFundingSources = useSelector(getFundingSources);

  const track = (event: string, props: Record<string, any> = {}) => {
    const fundingSourceProps = selectedFundingSource
      ? {
          paymentMethodId: selectedFundingSource.id,
          paymentMethodType: selectedFundingSource.fundingType,
        }
      : {};

    analytics.track(eventPage, event, {
      ...props,
      ...fundingSourceProps,
    });
  };

  const handleRemove = (dismissModal) => {
    dispatch(clearSelectedFundingSourceAction());
    dispatch(removeOrganizationFeeFundingSource(selectedFeeFundingSource.id, dismissModal));
  };

  const handleDismiss = (result) => {
    track(`remove-payment-method${result ? `-${result}` : ''}`);
  };

  const [RemoveModalComponent, showModal] = useModal(RemovePaymentModal, {
    remove: handleRemove,
    onDismiss: handleDismiss,
    onShow: () => {
      track('remove-payment-method-modal-shown');
    },
  });

  const actions = useMemo(() => {
    const url = locations.Onboarding.fundingSources.fee.checks.selectFundingSource.url({ orgId });

    return feeFundingSource
      ? [
          {
            label: 'settings.billing.feeCard.actions.change',
            action: () => {
              track('payment-method-action', { action: 'edit-method' });
              dispatch(setFeeFundingSourceFlow(FEE_FLOWS.CHANGE));
              history.push(url, {
                currentFeeFundingSourceId: selectedFeeFundingSource.fundingSourceId,
              });
            },
          },
          {
            label: 'settings.billing.feeCard.actions.remove',
            action: () => {
              track('payment-method-action', { action: 'remove-method' });

              showModal();
            },
          },
        ]
      : [
          {
            label: 'settings.billing.feeCard.actions.addMethod',
            action: () => {
              dispatch(clearSelectedFundingSourceAction());
              dispatch(setFeeFundingSourceFlow(FEE_FLOWS.ADD));
              history.push(url);
            },
          },
        ];
  }, [feeFundingSource]);

  const FundingSourceCard = useMemo(
    () => getFundingSourceCard(allFundingSources, feeFundingSource),
    [feeFundingSource]
  );

  return (
    <>
      {RemoveModalComponent}
      <CardContainer>
        <Title>
          <MIFormattedText label="settings.billing.feeCard.checks.title" />
        </Title>

        {FundingSourceCard && <FundingSourceCard {...{ actions }} />}
      </CardContainer>
    </>
  );
};
