import { DEFAULT_PAGE_SIZE } from './constants';

export const getPaginationLabelRange = (
  pageIndex: number,
  totalItems = 0,
  pageItemCount = DEFAULT_PAGE_SIZE
) => {
  if (totalItems > 0) {
    const from = pageIndex * pageItemCount + 1;
    const to = Math.min(totalItems, (pageIndex + 1) * pageItemCount);

    return `${from} - ${to} of ${totalItems}`;
  }

  return '';
};
