import React from 'react';
import { useSelector } from 'react-redux';
import { getOrgName } from 'src/app/redux/user/selectors';
import SettingsMethodsListItem from 'src/app/components/common/SelectMethods/components/SettingsMethodsListItem';
import firmPaymentMethodSVG from 'src/app/images/general/firm-payment-method.svg';

export const ExistingFirmFundingSource = ({ actions }) => {
  const orgName = useSelector(getOrgName);

  return (
    <SettingsMethodsListItem
      imageSrc={firmPaymentMethodSVG}
      id="existing-firm-funding-source"
      label="settings.billing.feeCard.checks.cards.firmFS.label"
      description="settings.billing.feeCard.checks.cards.firmFS.description"
      descriptionValues={{ orgName }}
      actionOptions={actions}
      hint=""
      origin="origin"
    />
  );
};
