import { postRequest, deleteRequest, fetchRequest } from './api';

export default {
  createRecurringBill(orgId, params) {
    const url = `/orgs/${orgId}/recurring-bill`;

    return postRequest(url, params);
  },

  deleteCurrentRecurringPaymentById(orgId, id) {
    const url = `/orgs/${orgId}/recurring-bill/cancel-occurrence/${id}`;

    return deleteRequest(url);
  },

  deleteAllRecurringPayments(orgId, id) {
    const url = `/orgs/${orgId}/recurring-bill/${id}`;

    return deleteRequest(url);
  },

  getOrgRecurringBills(orgId, params) {
    const url = `/orgs/${orgId}/recurring-bill/get`;

    return fetchRequest(url, params);
  },

  getBillsByRecurringId(orgId, recurringBillId) {
    const url = `/orgs/${orgId}/recurring-bill/get-bills/${recurringBillId}`;

    return fetchRequest(url);
  },
};
