import styled from 'styled-components';
import { Button, Link } from '@melio/billpay-design-system';

export const StartChatButton = styled(Button)`
  margin-top: ${({ theme }) => theme.sizes.ds.lg};
`;

export const ChatIcon = styled.i`
  font-size: 2.4rem;
  margin-right: ${({ theme }) => theme.sizes.ds.sm};
`;

export const SupportTeamLabel = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2};
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  margin-top: ${({ theme }) => theme.sizes.ds.sm};
`;

export const ContactContainer = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2};
`;

export const SupportLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
