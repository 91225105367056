import * as React from 'react';
import styled from 'styled-components';
import { MITextInput, MITextInputBaseProps } from 'src/app/components/common/MITextInput';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';

type Props = MITextInputBaseProps & {
  shouldShowValue?: boolean;
};

type State = {
  shouldShowValue: boolean;
};

class MIPasswordInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    ...MITextInput?.defaultProps,
    type: 'password',
    shouldShowValue: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      shouldShowValue: props.shouldShowValue || props.type !== 'password',
    };
  }

  togglePassword = () => {
    this.setState(({ shouldShowValue }) => ({
      shouldShowValue: !shouldShowValue,
    }));
  };

  inputTypeHandler = () => {
    const { type, viewOnly, inputMode } = this.props;
    const shouldShowValue = this.state.shouldShowValue && !viewOnly;

    if (shouldShowValue && type === 'password' && inputMode === 'numeric') {
      return 'number';
    }

    if (shouldShowValue && type === 'password') {
      return 'text';
    }

    return type;
  };

  render() {
    const { type, size, viewOnly, id } = this.props;
    const shouldShowValue = this.state.shouldShowValue && !viewOnly;
    const inputType = this.inputTypeHandler();
    const passwordIcon = shouldShowValue ? 'icon-hide-icon' : 'icon-show-icon';
    const testId = this.props.testId || `input-${id}`;

    return (
      <MITextInput
        {...this.props}
        type={inputType}
        suffix={
          <EyeIcon
            className={passwordIcon}
            password={type === 'password'}
            size={size as TextInputSizeEnum}
            viewOnly={viewOnly}
            data-testid={testId ? `${testId}-show` : null}
            onClick={this.togglePassword}
          />
        }
        privateData
      />
    );
  }
}

export default MIPasswordInput;

const EyeIcon = styled.i<{
  password?: boolean;
  size: TextInputSizeEnum;
  viewOnly?: boolean;
}>`
  display: ${(props) => (!props.viewOnly ? 'block' : 'none')};
  border: none;
  cursor: pointer;
  font-size: ${(props) => (props.size === TextInputSizeEnum.INLINE ? '1.7rem' : '2.2rem')};
`;
