import React from 'react';
import styled from 'styled-components';
import MISingleSelect from 'src/app/components/common/MISingleSelect';
import { InlineTextInputField } from 'src/app/ui/form';
import { options } from 'src/app/pages/vendor/delivery-methods/international/routes/PurposeOfPayment/utils';
import InvoiceUpload from 'src/app/pages/vendor/delivery-methods/international/routes/PurposeOfPayment/InvoiceUpload';
function PurposeOfPayment({ onChange, errors, purpose, showInvoiceFileSelector, billId }) {
  const { type, description } = purpose;

  return (
    <Purpose>
      <MISingleSelect
        id="purpose"
        value={type}
        label="international.purposeOfPayment.purpose.label"
        placeholder="international.purposeOfPayment.purpose.placeholder"
        errorMessage={errors.type}
        onChange={(val) => onChange('type', val)}
        options={options}
        menuPosition="fixed"
        required
      />
      <InlineTextInputField
        id="description"
        value={description}
        label="international.purposeOfPayment.description.label"
        placeholder="international.purposeOfPayment.description.placeholder"
        errorMessage={errors.description}
        onChange={(val) => onChange('description', val)}
        required
      />
      {showInvoiceFileSelector && <InvoiceUpload error={errors.file} isSidePanel billId={billId} />}
    </Purpose>
  );
}

const Purpose = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export default PurposeOfPayment;
