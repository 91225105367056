import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Menu, Popover, SmartIcon } from '@melio/billpay-design-system';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { CellContext } from '@tanstack/react-table';
import { analytics, MIFormattedDate } from 'src/app/version-2/externals';
import { DeliveryOption } from 'src/app/version-2/model/dtos/deliveryOption';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import {
  BATCH_BULK_EVENT_PAGE,
  OPEN_SELECT_ARRIVAL_DATE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import {
  BatchBulkItem,
  DeliveryMethodItem,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  ArrivalDateLateBadgeWrapper,
  ArrivalDateMenuButton,
  ArrivalDateMenuButtonContainer,
  ArrivalDateNA,
  CloseIconContainer,
  LabelTooltip,
  LateBadgeButtonWrapper,
  LateBadgeDetailsPopoverText,
  LateBadgeDetailsPopoverTitle,
  LateBadgeDetailsPopoverTitleContainer,
  LateBadgeDetailsPopoverWrapper,
} from 'src/app/version-2/pages/batch-bulk/renderers/ArrivalDate/ArrivalDate.renderer.styles';
import {
  ArrivalDateButton,
  ArrivalDateMenuItem,
} from 'src/app/version-2/pages/batch-bulk/renderers/ArrivalDate/components';
import { mapCalendarFees } from 'src/app/version-2/pages/batch-bulk/utils/fee.utils';
import { removeTime } from 'src/app/version-2/utils/dates.utils';
import { isFastDeliveryType } from 'src/app/version-2/utils/deliveryMethod.utils';
import { isFastDeliveryOption } from 'src/app/version-2/utils/deliveryOption.utils';

import { isAfter, isSameDay, isTomorrow } from 'date-fns';

const ArrivalDateRenderer: FC<CellContext<BatchBulkItem, DeliveryMethodItem>> = (props) => {
  const dispatch = useDispatch();

  const {
    row,
    row: {
      original: {
        id: paymentIntentId,
        selectedPaymentMethod,
        vendorId,
        totalBillIds,
        totalPartialBillIds,
        deliveryOptions,
        deliveryEta,
        type,
        dueDate,
      },
    },
  } = props;

  const { formatMessage } = useIntl();
  const userPreferences = useSelector(userSliceSelectors.selectUserPreferences);
  const shownBatchBulkFastACHExperiment = useSelector(
    batchBulkSelectors.selectShownBatchBulkFastACHExperiment
  );

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isSubRow = !!row.depth;

  const onMenuTrigger = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);

    if (!isMenuOpen) {
      analytics.track(BATCH_BULK_EVENT_PAGE, OPEN_SELECT_ARRIVAL_DATE, {
        fromTable: 'yes',
        billIds: totalBillIds,
        partialBillIds: totalPartialBillIds,
        vendorId,
      });
    }
  }, [setIsMenuOpen, isMenuOpen, totalBillIds, totalPartialBillIds, vendorId]);

  const onPopoverActions = () => {
    dispatch(
      batchBulkActions.updateShownBatchBulkFastACHExperiment({
        key: 'qbShownBatchBulkFastACHExperiment',
        value: true,
      })
    );

    onMenuTrigger();
  };

  const onDeliveryOptionChange = ({
    deliveryOption,
    isFast,
    deliverySpeedLabel,
  }: {
    deliveryOption: DeliveryOption;
    isFast: boolean;
    deliverySpeedLabel: boolean;
  }) => {
    dispatch(
      batchBulkActions.updateDeliveryOption({
        paymentIntentId,
        deliveryOption,
        isFast,
        deliverySpeedLabel,
      })
    );
  };

  const shouldDisplayLate = (deliveryEta, dueDate) => {
    let isLate = false;

    if (deliveryEta && dueDate) {
      isLate =
        !isSameDay(deliveryEta, dueDate) || isAfter(removeTime(deliveryEta), removeTime(dueDate));

      if (isLate) {
        return isLate;
      }

      if (isSameDay(deliveryEta, dueDate)) {
        isLate = !isSameDay(deliveryEta, dueDate);
      }
    }

    return isLate;
  };

  const isLateBadge = useMemo(
    () => shouldDisplayLate(deliveryEta, dueDate),
    [type, deliveryEta, dueDate]
  );

  const isOnTimeBadge = useCallback(() => {
    const deliveryOption = mappedDeliveryOptions?.find((option: DeliveryOption) =>
      isFastDeliveryType(option.type)
    );
    const defaultDeliveryOption = mappedDeliveryOptions?.find(
      (option: DeliveryOption) => !isFastDeliveryType(option.type)
    );

    if (
      defaultDeliveryOption &&
      dueDate &&
      !isAfter(removeTime(new Date(defaultDeliveryOption.deliveryDate)), removeTime(dueDate))
    ) {
      return false;
    }

    let onTime;

    if (deliveryOption && deliveryEta && dueDate) {
      onTime = isAfter(removeTime(new Date(deliveryOption?.deliveryDate)), removeTime(dueDate));

      if (onTime) {
        return !onTime;
      }

      if (dueDate && isSameDay(new Date(deliveryOption?.deliveryDate), dueDate)) {
        return isSameDay(new Date(deliveryOption?.deliveryDate), dueDate);
      }

      if (!shouldDisplayLate(deliveryEta, dueDate) && onTime) {
        return false;
      }
    }

    return !onTime;
  }, [dueDate, deliveryEta]);

  if (isSubRow) return null;

  if (!deliveryOptions?.length) {
    return (
      <ArrivalDateNA>
        {formatMessage({ id: 'batchBulkPage.renderers.arrivalDate.estimateNA' })}
      </ArrivalDateNA>
    );
  }

  const mappedDeliveryOptions: DeliveryOption[] = mapCalendarFees(
    deliveryOptions as DeliveryOption[]
  ).reverse();

  const getArrivalDateLabel = (date) => {
    const dateValue = { date: <MIFormattedDate date={date} /> };

    if (isSameDay(new Date(), date)) {
      return formatMessage({ id: `batchBulkPage.renderers.arrivalDate.today` });
    }

    if (isTomorrow(date)) {
      return formatMessage({ id: `batchBulkPage.renderers.arrivalDate.tomorrow` });
    }

    return formatMessage({ id: `batchBulkPage.renderers.arrivalDate.date` }, dateValue);
  };

  const badge = (
    <div>
      <LabelTooltip $isLateBadge>
        <ArrivalDateLateBadgeWrapper>
          <Badge variant="warning">
            {formatMessage({ id: 'batchBulkPage.deliveryDate.late' })}
          </Badge>
        </ArrivalDateLateBadgeWrapper>
      </LabelTooltip>
    </div>
  );

  const LateBadgePopoverContent = (isOpen) => (
    <Popover closeOnBlur placement="top" arrowColor="ds.white" isOpen={isOpen}>
      <Popover.PopoverTrigger>{badge}</Popover.PopoverTrigger>
      <Popover.PopoverContent>
        <LateBadgeDetailsPopoverWrapper>
          <LateBadgeDetailsPopoverTitleContainer>
            <LateBadgeDetailsPopoverTitle>
              {formatMessage({ id: 'batchBulkPage.renderers.arrivalDate.popover.title' })}
            </LateBadgeDetailsPopoverTitle>
            <CloseIconContainer onClick={onPopoverActions} aria-hidden="true">
              <SmartIcon type="close" size="1.4rem" />
            </CloseIconContainer>
          </LateBadgeDetailsPopoverTitleContainer>
          <LateBadgeDetailsPopoverText>
            {formatMessage({ id: 'batchBulkPage.renderers.arrivalDate.popover.text' })}
          </LateBadgeDetailsPopoverText>
          <LateBadgeButtonWrapper>
            <Button variant="primary" size="md" onClick={onPopoverActions}>
              {formatMessage({ id: 'batchBulkPage.renderers.arrivalDate.popover.cta' })}
            </Button>
          </LateBadgeButtonWrapper>
        </LateBadgeDetailsPopoverWrapper>
      </Popover.PopoverContent>
    </Popover>
  );

  const renderBadge = () => {
    if (!isLateBadge) return null;

    if (
      shownBatchBulkFastACHExperiment === paymentIntentId &&
      !userPreferences?.qbShownBatchBulkFastACHExperiment
    ) {
      return <LateBadgePopoverContent isOpen />;
    }

    return badge;
  };

  return (
    <Menu
      placement="bottom-start"
      onOpen={onMenuTrigger}
      onClose={onMenuTrigger}
      autoWidth
      isOpen={isMenuOpen}
    >
      <ArrivalDateMenuButtonContainer data-testid="arrival-date">
        {!selectedPaymentMethod?.isVerified || deliveryOptions?.length === 1 ? (
          <MIFormattedDate date={deliveryOptions[0].deliveryDate} />
        ) : (
          <Menu.MenuButton as={ArrivalDateMenuButton}>
            <ArrivalDateButton isMenuOpen={isMenuOpen} label={getArrivalDateLabel(deliveryEta)} />
          </Menu.MenuButton>
        )}
        {renderBadge()}
      </ArrivalDateMenuButtonContainer>
      <Menu.MenuList>
        {mappedDeliveryOptions.map((deliveryOption) => {
          const isFast = isFastDeliveryOption(mappedDeliveryOptions, deliveryOption);

          return (
            <Menu.MenuItem
              key={new Date(deliveryOption.deliveryDate).getTime()}
              onClick={() =>
                onDeliveryOptionChange({
                  deliveryOption,
                  isFast,
                  deliverySpeedLabel: isOnTimeBadge(),
                })
              }
              data-testid="arrival-date-button"
            >
              <ArrivalDateMenuItem
                deliveryOption={deliveryOption}
                selectedPaymentMethod={selectedPaymentMethod}
                isFast={isFast}
                isOnTimeBadge={isOnTimeBadge()}
              />
            </Menu.MenuItem>
          );
        })}
      </Menu.MenuList>
    </Menu>
  );
};

export { ArrivalDateRenderer };
