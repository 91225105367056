import React from 'react';
import { featureFlags } from '@melio/shared-web';
import { Box } from '@melio/billpay-design-system';

import { useTranslation } from 'src/app/utils/formatting';
import { PaymentType } from 'src/app/utils/types';
import { isVoidAndRetryInProgress } from 'src/app/utils/payments';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';

import { getSubtitleValuesForVoidedCheckNotification } from './utils';

type Props = {
  payment: PaymentType;
};

const CheckVoidedNotification = ({ payment }: Props) => {
  const { formatMessage } = useTranslation();
  const [voidChecksEnabled] = featureFlags.useFeature('bp-void-check', false);
  const showVoidedCheckNotification = isVoidAndRetryInProgress(payment);
  const checkSerialNumber = payment.metadata?.voidedCheckData?.checkSerialNumber;

  if (!voidChecksEnabled || !checkSerialNumber || !showVoidedCheckNotification) {
    return null;
  }

  const paperCheckText = formatMessage('bills.pay.voidCheckRecovery.voidedNotification.paperCheck');
  const achText = formatMessage('bills.pay.voidCheckRecovery.voidedNotification.ach');
  const valueOptions = getSubtitleValuesForVoidedCheckNotification({
    payment,
    paperCheckText,
    achText,
  });

  return (
    <Box m={4} marginBottom={0} data-testid="check-paid-notification">
      <QBONotificationCard
        type={NOTIFICATION_CARD_TYPES.INFO}
        title={{ label: 'bills.pay.voidCheckRecovery.voidedNotification.title' }}
        subtitle={{
          label: 'bills.pay.voidCheckRecovery.voidedNotification.subtitle',
          values: valueOptions,
        }}
      />
    </Box>
  );
};

export { CheckVoidedNotification };
