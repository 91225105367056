import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsLoggedIn, getOwnedVendorId, getDeliveryMethods } from 'src/app/redux/user/selectors';
import { generatePath, useHistory } from 'react-router-dom';
import locations from 'src/app/pages/vendor/shift-vendor-to-ach/locations';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import profileStore from 'src/app/modules/profile/profile-store';
import analytics from 'src/app/services/analytics';

const ANALYTICS_TAG_NAME = 'useInitFetch';

export function useInitFetch(vendorId, token) {
  const history = useHistory();
  const { paymentId } = getJWTPayload(token);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const payment = useSelector(paymentStore.selectors.byId(paymentId));
  const paymentActions = useStoreActions(paymentStore);

  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const isMultiCompany = organizations.length > 1;
  const initialUrl = isMultiCompany ? locations.switchCompany : locations.addMethod;

  const loadPaymentData = async (existingPaymentData) => {
    let payment;

    if (existingPaymentData) payment = existingPaymentData;
    else {
      try {
        const res = await paymentActions.fetchEmailToVendorDetails({ token });

        payment = res.payload?.payment;
      } catch (e) {
        history.push(generatePath(locations.invalidToken, { id: vendorId, token }));
      }
    }

    if (payment?.deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK) {
      if (isLoggedIn) {
        const organizationId = payment?.organization?.id;
        const vendorEmail = payment?.vendor?.contactEmail;

        const commonAnalyticProps = {
          ...analytics.getExtraProperties(),
          organizationId,
          paymentId,
          vendorId,
          vendorEmail,
          siteConfig: analytics.siteConfig,
        };
        const melioMeExistMethodFlow = ownedVendorId && orgDeliveryMethods?.length;
        const melioMeFlowName = melioMeExistMethodFlow
          ? 'melio.me existing delivery method'
          : 'melio.me add delivery method';

        analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
          ...commonAnalyticProps,
          flow: melioMeFlowName,
        });
      }
    }

    history.push(generatePath(initialUrl, { id: vendorId, token }));
  };

  useEffect(() => {
    loadPaymentData(payment);

    return () => analytics.removeExtraProperties(ANALYTICS_TAG_NAME);
  }, [paymentId, token, history, paymentActions, vendorId]);

  return undefined;
}
