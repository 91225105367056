import React, { useEffect } from 'react';

import GeneralFlowErrorContainer from 'src/app/pages/quickbooks/GeneralFlowErrorContainer';
import Icon from 'src/app/images/icons/payment-completed.svg';
import analytics from 'src/app/services/analytics';

const PaymentProcessed = () => {
  useEffect(() => {
    analytics.page('push-to-debit', 'payment-already-sent-to-card', {
      OriginDeliveryType: 'check',
    });
  }, []);

  return (
    <GeneralFlowErrorContainer
      illustration={Icon}
      title="vendors.deliveryMethods.shiftToDebit.errors.alreadyProcessed.title"
      subtitle="vendors.deliveryMethods.shiftToDebit.errors.alreadyProcessed.subtitle"
    />
  );
};

export default PaymentProcessed;
