import { compareAsc, isAfter, isSameDay as isPaymentOnTime } from 'date-fns';
import { Big } from 'big.js';
import {
  BillType,
  CompanyInfoType,
  DeliveryMethodType,
  DeliveryOptionType,
  PaymentType,
} from 'src/app/utils/types';
import find from 'lodash/find';
import clone from 'lodash/clone';
import head from 'lodash/head';
import {
  isTomorrow as isDateTomorrow,
  isAfterDateBy,
  isDatePassed,
  isSameDay,
} from 'src/app/utils/dates';
import {
  CardTypeEnum,
  FastFeeDeliveryEnum,
  FundingSourceTypesEnum,
  DeliveryTypeExpeditedEnum,
  FastAchExperimentCaseEnum,
} from 'src/app/version-2/model/enums';
import internationalCountries from 'src/app/pages/vendor/delivery-methods/international/countries.json';
import { DeliveryOption } from 'src/app/version-2/model/dtos/deliveryOption';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { getDeliveryMethodById } from '../pages/bill/records';
import { CONSTS, DELIVERY_TYPE, FAST_DELIVERY_TYPES, PAYMENT_STATUS } from './consts';
import locations from './locations';

function isPOBox(addressLine1?: string | null): boolean {
  // eslint-disable-next-line max-len
  const POBoxRegex =
    /^((unit|#)?\s?(num|number|#)?\s\d+)|(((p[\s\\.]?[o\s][\\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/;

  return addressLine1 ? POBoxRegex.test(addressLine1.toLowerCase()) : false;
}
const getDeliveryMethodName = (deliveryMethod?: DeliveryMethodType) => {
  const accountType = deliveryMethod?.bankAccount ? deliveryMethod?.bankAccount.accountType : '';
  const accountTypeCapitalize = accountType.charAt(0).toUpperCase() + accountType.slice(1);
  const deliveryInfo = deliveryMethod?.bankAccount ? deliveryMethod?.getDeliveryInfo() : '';

  return `${accountTypeCapitalize} account ${deliveryInfo}`;
};

const deliveryTypePredicate = (deliveryType?: DELIVERY_TYPE) => (dm: DeliveryMethodType) =>
  dm.deliveryType === deliveryType;

const formatCheckPrintName = (printName: string) =>
  (printName || '')
    .replace(/[^a-zA-Z0-9,:;/&#@‘\-.() ]/g, ' ')
    .replace(/  +/g, ' ')
    .toUpperCase();

interface RemoveUnsupportedDeliveryOptionsByBillParams {
  site: any;
  deliveryOptions: DeliveryOptionType[];
  bill: BillType;
  payment: PaymentType;
  fundingSource: FundingSource | null;
  companyInfo: CompanyInfoType;
}

const removeUnsupportedDeliveryOptionsByBill = ({
  site,
  deliveryOptions,
  bill,
  payment,
  fundingSource,
  companyInfo,
}: RemoveUnsupportedDeliveryOptionsByBillParams) =>
  removeUnsupportedDeliveryOptionsByDeliveryMethod({
    site,
    deliveryOptions,
    deliveryMethod: getDeliveryMethodById(bill, payment?.deliveryMethodId),
    fundingSource,
    companyInfo,
  });

interface RemoveUnsupportedDeliveryOptionsByDeliveryMethodParams {
  site: any;
  deliveryOptions: DeliveryOptionType[];
  deliveryMethod: DeliveryMethodType | null;
  fundingSource: FundingSource | null;
  companyInfo: CompanyInfoType;
}

const removeUnsupportedDeliveryOptionsByDeliveryMethod = ({
  site,
  deliveryOptions,
  deliveryMethod,
  fundingSource,
  companyInfo,
}: RemoveUnsupportedDeliveryOptionsByDeliveryMethodParams) => {
  const clonedDeliveryOptions = clone(deliveryOptions);

  if (clonedDeliveryOptions?.length > 1) {
    return clonedDeliveryOptions.filter((deliveryOption) =>
      isDeliveryOptionSupported({
        deliveryOption,
        deliveryMethod,
        fundingSource,
        companyInfo,
        isFastACHEnabledForSite: site.hasFastAch,
      })
    );
  }

  return clonedDeliveryOptions;
};

interface IsDeliveryOptionSupportedParams {
  deliveryOption: DeliveryOptionType | DeliveryOption;
  deliveryMethod: DeliveryMethodType | null;
  fundingSource: FundingSource | null;
  companyInfo: CompanyInfoType;
  isFastACHEnabledForSite: boolean;
}

export const isDeliveryOptionSupported = ({
  deliveryOption,
  deliveryMethod,
  fundingSource,
  companyInfo,
  isFastACHEnabledForSite,
}: IsDeliveryOptionSupportedParams) => {
  if (deliveryOption.type === FastFeeDeliveryEnum.EXPEDITED_ACH && !isFastACHEnabledForSite) {
    return false;
  }

  if (
    deliveryOption.type === FastFeeDeliveryEnum.EXPEDITED_ACH &&
    fundingSource?.cardAccount?.cardType === CardTypeEnum.DEBIT
  ) {
    return false;
  }

  if (deliveryOption.type === FastFeeDeliveryEnum.OVERNIGHT_CHECK) {
    return false;
  }

  if (
    deliveryOption.type === FastFeeDeliveryEnum.EXPRESS_CHECK &&
    isFastCheckDisabled(deliveryMethod, companyInfo)
  ) {
    return false;
  }

  return true;
};

const isFastCheckDisabled = (
  deliveryMethod: DeliveryMethodType | null,
  companyInfo: CompanyInfoType
) =>
  isPOBox(deliveryMethod?.paperCheck?.addressLine1) ||
  !companyInfo.legalState ||
  !companyInfo.state ||
  !companyInfo.canPayWithFastCheck;

const getFastACHCase = (billDueDate) => {
  const isInThePast = isDatePassed(billDueDate);
  const isTomorrow = isDateTomorrow(billDueDate);
  const isToday = isSameDay(billDueDate);
  const isDayAfterTomorrow = isAfterDateBy(billDueDate, 2);

  if (isInThePast) {
    return FastAchExperimentCaseEnum.DUE_DATE_HAS_PASSED;
  }

  if (isTomorrow || isDayAfterTomorrow || isToday) {
    return FastAchExperimentCaseEnum.DUE_DATE_IN_MAX_TWO_DAYS;
  }

  return undefined;
};

const shouldDisplayDeliverySpeedOptions = (
  fundingSourceId,
  deliveryMethodId,
  deliveryOptions: DeliveryOptionType[]
) =>
  fundingSourceId &&
  deliveryMethodId &&
  Array.isArray(deliveryOptions) &&
  deliveryOptions.length > 1;

const shouldDisplayDeliverySpeed = (fundingSourceId, deliveryOptions) =>
  !!fundingSourceId && Array.isArray(deliveryOptions) && deliveryOptions.length > 1;

const getDeliveryOption = (deliveryPreference: string, deliveryOptions?: DeliveryOptionType[]) =>
  find(deliveryOptions, (o) => o.type === deliveryPreference) ||
  (deliveryOptions && deliveryOptions[0]) ||
  null;

const getFastType = (deliveryMethodType) => {
  switch (deliveryMethodType) {
    case DELIVERY_TYPE.ACH:
      return FastFeeDeliveryEnum.EXPEDITED_ACH;

    case DELIVERY_TYPE.CHECK:
      return FastFeeDeliveryEnum.EXPRESS_CHECK;

    default:
      return deliveryMethodType;
  }
};

const isFastAllowed = (site, deliveryType?: DELIVERY_TYPE) => {
  switch (deliveryType) {
    case DELIVERY_TYPE.ACH:
      return site.hasFastAch;

    case DELIVERY_TYPE.CHECK:
      return true;

    default:
      return false;
  }
};

const isFastACHAllowed = (deliveryOptions: DeliveryOptionType[], selectedFundingSource) => {
  const dm = deliveryOptions?.some(
    (option) => option.type === DeliveryTypeExpeditedEnum.EXPEDITED_ACH
  );
  const fs = selectedFundingSource.fundingType === FundingSourceTypesEnum.ACH;

  return dm && fs;
};

const isFastDeliveryType = (deliveryPreference) =>
  FAST_DELIVERY_TYPES.includes(deliveryPreference || '');

const getPaymentFastFee = (bill, deliveryOptions) => {
  const payment: any = head(bill.payments);
  const { deliveryPreference } = payment;

  if (!deliveryPreference) return 0;

  const deliveryOption = deliveryOptions.find((item) => item.type === deliveryPreference);
  const fee = parseFloat(deliveryOption?.amount);

  return fee || 0;
};

const getDeliveryOptionFastFeeForPartialPayments = (
  deliveryOption: DeliveryOptionType | DeliveryOption,
  paymentAmount: number
): number => {
  const { isFixed, amount, percent, cap } = deliveryOption;

  if (isFixed) return amount || 0;

  let fee = percent
    ? Math.max(parseFloat(((paymentAmount / 100) * percent).toFixed(2)), 0.01)
    : amount;

  fee = cap ? Math.min(fee, cap) : fee;

  return fee || 0;
};

const getPaymentFastFeeForPartialPayments = (
  bill: BillType,
  deliveryOptions: DeliveryOptionType[],
  paymentAmount: number
): number => {
  const payment = bill.payments[0];
  const { deliveryPreference } = payment;

  if (!deliveryPreference) return 0;

  const deliveryOption = deliveryOptions.find((item) => item.type === deliveryPreference);

  if (!deliveryOption) return 0;

  return getDeliveryOptionFastFeeForPartialPayments(deliveryOption, paymentAmount);
};

const calculateTotalFee = (billItems) => {
  const totalFee = billItems.reduce(
    (total, { bill, deliveryOptions }) => total.plus(getPaymentFastFee(bill, deliveryOptions)),
    new Big(0)
  );

  return totalFee.toNumber();
};

const getAddDeliveryMethodUrlMap = (orgId: string, vendorId?: number) => ({
  [CONSTS.DELIVERY_TYPE.VIRTUAL_CARD]: locations.Vendors.deliveryMethods['virtual-card'].create.url(
    {
      orgId,
      id: vendorId,
    }
  ),
  [CONSTS.DELIVERY_TYPE.ACH]: locations.Vendors.deliveryMethods.ach.create.url({
    orgId,
    id: vendorId,
  }),
  [CONSTS.DELIVERY_TYPE.CHECK]: locations.Vendors.deliveryMethods.check.create.url({
    orgId,
    id: vendorId,
  }),
  [CONSTS.DELIVERY_TYPE.VIRTUAL]: locations.Vendors.deliveryMethods.virtual.create.url({
    orgId,
    id: vendorId,
  }),
  [CONSTS.DELIVERY_TYPE.INTERNATIONAL]: locations.Vendors.deliveryMethods.international.create.url({
    orgId,
    id: vendorId,
  }),
});

const isInternationalPayment = (payment: PaymentType) =>
  payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL;

const companyAddressHasPOBox = (companyInfo: CompanyInfoType) =>
  isPOBox(companyInfo?.addressLine1) || isPOBox(companyInfo?.legalAddressLine1);

const getInternationalDMCountryName = (deliveryMethod) =>
  internationalCountries[
    deliveryMethod?.internationalAccount?.payeeCountry?.toUpperCase() ||
      deliveryMethod?.internationalAccount?.payeeAccount?.country?.toUpperCase()
  ] || '';

const getInternationalDMBankCountryName = (deliveryMethod) =>
  internationalCountries[
    deliveryMethod?.internationalAccount?.payeeBankCountry?.toUpperCase() ||
      deliveryMethod?.internationalAccount?.payeeAccount?.payeeBankCountry?.toUpperCase()
  ] || '';

export const isCheckDeliveryMethod = (deliveryMethod: DeliveryMethodType) =>
  deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK;

const isLateDeliveryType = ({
  paymentDeliveryETA,
  bills,
}: {
  paymentDeliveryETA: PaymentType['deliveryEta'];
  bills: BillType[];
}): boolean => {
  const earliestBillDueDate = bills
    .map(({ dueDate }) => new Date(dueDate))
    .sort((leftDate, rightDate) => compareAsc(leftDate, rightDate))?.[0];

  const paymentDeliveryETADate = new Date(paymentDeliveryETA);

  return (
    isAfter(paymentDeliveryETADate, earliestBillDueDate) &&
    !isPaymentOnTime(paymentDeliveryETADate, earliestBillDueDate)
  );
};

const getIsNeedToShowACHLatePaymentNotification = ({
  deliveryOptions,
  bills,
  paymentDeliveryETA,
  deliveryPreference,
  paymentStatus,
}: {
  deliveryOptions: DeliveryOptionType[];
  bills: BillType[];
  paymentDeliveryETA: string;
  deliveryPreference?: string;
  paymentStatus: PaymentType['status'];
}) => {
  if (paymentStatus === PAYMENT_STATUS.FAILED) {
    return false;
  }

  const isAlreadyFast = isFastDeliveryType(deliveryPreference);

  if (isAlreadyFast) {
    return false;
  }

  const isFastACHOptionExists = deliveryOptions.some(
    (option) => option.type === DeliveryTypeExpeditedEnum.EXPEDITED_ACH
  );

  if (!isFastACHOptionExists) {
    return false;
  }

  return isLateDeliveryType({ paymentDeliveryETA, bills });
};

export {
  getDeliveryMethodName,
  deliveryTypePredicate,
  formatCheckPrintName,
  removeUnsupportedDeliveryOptionsByBill,
  shouldDisplayDeliverySpeedOptions,
  shouldDisplayDeliverySpeed,
  getDeliveryOption,
  getFastType,
  isFastAllowed,
  calculateTotalFee,
  isFastDeliveryType,
  getPaymentFastFee,
  getDeliveryOptionFastFeeForPartialPayments,
  getPaymentFastFeeForPartialPayments,
  getAddDeliveryMethodUrlMap,
  removeUnsupportedDeliveryOptionsByDeliveryMethod,
  isPOBox,
  companyAddressHasPOBox,
  getInternationalDMCountryName,
  getInternationalDMBankCountryName,
  isInternationalPayment,
  isFastACHAllowed,
  getFastACHCase,
  getIsNeedToShowACHLatePaymentNotification,
  isLateDeliveryType,
};
