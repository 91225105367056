import styled from 'styled-components';
import { RecordOf } from 'immutable';
import { DeliveryMethodType } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import { getInternationalDMBankCountryName } from 'src/app/utils/delivery-methods';
import { Flags } from 'src/app/components/common/Flags';

type Props = {
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

const PayBillPaymentActivitySendingToCountry = ({ deliveryMethod }: Props) => {
  const countryCode =
    deliveryMethod?.internationalAccount?.payeeBankCountry ||
    deliveryMethod?.internationalAccount?.payeeAccount?.payeeBankCountry;
  const countryName = getInternationalDMBankCountryName(deliveryMethod);

  return (
    <StyledContainer>
      <MIFormattedText label="payBillPaymentActivity.sendingTo" />
      <Flags code={countryCode} />
      <span>{countryName}</span>
    </StyledContainer>
  );
};

export default PayBillPaymentActivitySendingToCountry;

const StyledContainer = styled.div`
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.normal};
  color: ${(props) => props.theme.text.color.main};
  line-height: 2rem;
  padding-top: 0.7rem;
  margin-left: 4.7rem;
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;
