import { ReactNode } from 'react';

import { Box, SmartIcon, Flex, Fade } from '../..';

export type BaseSelectMethodButtonProps = {
  selected: boolean; // for managing the bold bottom green border
  inactive?: boolean; // for managing text styles (passing true will make them grayed)
  onToggleSelect: (selected: boolean) => void;
};

type Props = {
  icon: ReactNode;
  title: string;
  description: ReactNode | null;
  checked: boolean; // for managing the checkmark
} & BaseSelectMethodButtonProps;

const SelectMethodButton = ({
  icon,
  title,
  description,
  selected,
  checked,
  inactive = false,
  onToggleSelect,
}: Props) => {
  const iconElement = (
    <Flex
      alignItems="center"
      w="full"
      h={['6.3rem', '7.5rem']}
      minH={['6.3rem', '7.5rem']}
      justify="center"
    >
      <Flex alignItems="center" w={['4.7rem', '5.7rem']} h={['4.7rem', '5.7rem']} justify="center">
        {icon}
      </Flex>
    </Flex>
  );

  const titleElement = (
    <Box
      textColor={inactive && !checked ? 'ds.gray.200' : 'ds.black'}
      fontWeight="ds.semi"
      textStyle={['ds.body2', 'ds.body1']}
      textAlign="center"
      w="full"
    >
      {title}
    </Box>
  );

  const descriptionElement = (
    <Box
      textStyle="ds.body3"
      textAlign="center"
      w="full"
      textColor="ds.gray.200"
      mt={['2', '1.4rem']}
      textOverflow="ellipsis"
      wordBreak="break-word"
      noOfLines={2}
    >
      {description}
    </Box>
  );

  const checkmarkIconElement = (
    <Flex
      pos="absolute"
      alignItems="center"
      justify="center"
      display="flex"
      top={['1.4rem', '4']}
      right={['1.4rem', '4']}
      w={['2rem', '2.4rem']}
      h={['2rem', '2.4rem']}
    >
      <SmartIcon type="checked" color="ds.green.200" width="1.8rem" height="1.4rem" />
    </Flex>
  );

  const bottomLineElement = (
    <Box pos="absolute" left="0" bottom="0" w="full" h="3px">
      <Fade in={selected} transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}>
        <Box w="full" h="3px" bgColor="ds.green.200" />
      </Fade>
    </Box>
  );

  return (
    <Flex
      direction="column"
      boxSizing="border-box"
      p={['3', '4']}
      w={['15rem', '18rem']}
      h={['15rem', '18rem']}
      bgColor="ds.white"
      borderWidth="1px"
      borderColor="ds.gray.500"
      borderStyle="solid"
      pos="relative"
      borderRadius="ds.md"
      overflow="hidden"
      _hover={{
        bgColor: '#F2F9F1',
        cursor: 'pointer',
      }}
      onClick={() => onToggleSelect(!selected)}
    >
      {checked && checkmarkIconElement}
      {iconElement}
      {titleElement}
      {descriptionElement}
      {bottomLineElement}
    </Flex>
  );
};

export { SelectMethodButton };
