import { ReactNode } from 'react';
import { Box } from '@melio/billpay-design-system';
import { modalHeaderSubTitleStyle } from './Modal.style';

type Props = {
  children: ReactNode;
};

export const ModalHeaderSubTitle = ({ children }: Props) => (
  <Box __css={modalHeaderSubTitleStyle} data-testid="header-subtitle-text">
    {children}
  </Box>
);
