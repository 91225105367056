import styled, { css } from 'styled-components';
import { RecordOf } from 'immutable';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addBusinessDays } from 'date-fns';
import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import { PAYMENT_STATUS } from 'src/app/utils/consts';
import { BillType, DeliveryMethodType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import { isRecurringBill } from 'src/app/utils/bills';
import { MIFieldOrEmpty, MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import RecurringPaymentView from '../../pay/components/RecurringPaymentView';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { isPaymentFailedRefund, isRefundPaymentFlow } from 'src/app/utils/payments';
import { BillsPopover } from 'src/app/version-2/components/BillsPopover';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import PaymentFee from '../../pay/components/PaymentFee';
import ViewBillPaymentActivity from '../ViewBillPaymentActivity/ViewBillPaymentActivity';
import PaymentList from './PaymentList';
import { getApprovalDecisions } from './utils';
import { ApprovalDetailsType } from '../../pay/components/PayBillPaymentActivity/PaymentApprovalLogs/types';
import PaymentApprovalLogs from '../../pay/components/PayBillPaymentActivity/PaymentApprovalLogs/PaymentApprovalLogs';
import { CheckOverdueNotification } from '../CheckOverdueNotification';
import { CheckVoidedNotification } from '../CheckVoidedNotification';
import { useVoidCheckActions } from '../../hooks/useVoidCheckActions';
import { ViewBillPaymentReviewTotalSection } from './ViewBillPaymentReviewTotalSection/ViewBillPaymentReviewTotalSection';
import { FailedRefundPaymentActivity } from '../ViewPaymentActivity/FailedRefundPaymentActivity/FailedRefundPaymentActivity';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  bill: RecordOf<BillType>;
  fundingSource: FundingSource;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  payment: RecordOf<PaymentType>;
  fee?: PaymentFeeInfo;
  qbCashState?: QBCashStateType;
  isPartialPayment: boolean;
  currentAmount: number;
  isMarkedAsPaid: boolean;
  isBatchBulkCombined?: boolean;
};

const QBOViewBillPaymentReview = ({
  bill,
  fee,
  fundingSource,
  deliveryMethod,
  payment,
  qbCashState,
  isPartialPayment,
  currentAmount,
  isMarkedAsPaid,
  isBatchBulkCombined,
}: Props) => {
  const { orgId, paymentId } = useParams<{
    orgId: string;
    paymentId: string;
  }>();
  const companyName = bill.vendor?.companyName || '';
  const isPaymentFailed = payment.status === PAYMENT_STATUS.FAILED;
  const isRecurring = isRecurringBill(bill);
  const history = useHistory();
  const involvedUsers = payment?.metadata?.audit || [];
  const creator = involvedUsers.find((user) => user.actor.id === payment.createdById);
  const approvalDetails: ApprovalDetailsType = {
    ...getApprovalDecisions(payment, involvedUsers),
    scheduledBy: {
      actionDate: payment.createdAt,
      role: creator?.actor?.role,
      user: creator?.actor,
    },
  } as ApprovalDetailsType;
  const isRefundFlow = isRefundPaymentFlow(payment) || isPaymentFailedRefund(payment);
  const [isTimelineFeatureEnabled] = featureFlags.useFeature(
    FeatureFlagsEnum.PAYMENT_DETAILS_TIMELINE,
    false
  );
  const refundRequestedDate = payment.metadata?.paymentStatusDates?.refundInitiated;
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);
  const refundDueDate = refundRequestedDate
    ? addBusinessDays(new Date(refundRequestedDate), 5).toISOString()
    : null;
  const { payBillRedirectUrl } = useSelector(
    qbDashboardListItemsStore.selectors.viewPaymentUrls.urls
  ) as { payBillRedirectUrl: string };

  const locationStateForPayBillFlow = {
    exitUrl: `${history.location.pathname}${history.location.search}`,
    redirectUrl: payBillRedirectUrl,
  };

  const { handleVoidCheckAndResendPayment } = useVoidCheckActions({
    billId: bill.id,
    paymentId,
    locationStateForPayBillFlow,
  });

  return (
    <MainBox>
      {!isTimelineFeatureEnabled && (
        <CheckOverdueNotification
          payment={payment}
          onVoidCheckAndResendPayment={handleVoidCheckAndResendPayment}
        />
      )}
      <CheckVoidedNotification payment={payment} />
      <ViewBillPaymentReviewTotalSection currentAmount={currentAmount} paymentId={paymentId} />

      {isRefundFlow ? (
        <RefundPaymentContainer>
          <FailedRefundPaymentActivity
            refundRequestedDate={refundRequestedDate}
            refundDueDate={refundDueDate}
            bill={bill}
            payment={payment}
            fundingSource={fundingSource}
            isPartialPayment={isPartialPayment}
          />
        </RefundPaymentContainer>
      ) : (
        <PaymentInfoContainer>
          {!isRetryFailedToDeliver && (
            <PaymentApprovalLogs
              approvalDecisionStatus={payment.approvalDecisionStatus}
              approvalDetails={approvalDetails}
            />
          )}
          <SubTitle>
            <MIFormattedText label="bills.pay.confirm.payTo" />
          </SubTitle>
          <BoxRow>
            <BoxColumn>
              <VendorFieldName>
                <MIFormattedText label="bills.pay.confirm.vendorField" />
              </VendorFieldName>
              <FieldValue data-testid="bill-company-name">{companyName}</FieldValue>
            </BoxColumn>
            <InvoiceBoxColumn>
              <FieldName>
                <MIFormattedText label="bills.pay.confirm.invoiceNumberField" />
              </FieldName>
              <FieldValue $hasPaymentTooltip={isPartialPayment} data-testid="bill-invoice-number">
                {isBatchBulkCombined ? (
                  <BillsPopover paymentId={paymentId} />
                ) : (
                  <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.view.noInvoiceNumber" />
                )}
              </FieldValue>
              {isPartialPayment && <PaymentList bill={bill} orgId={orgId} paymentId={paymentId} />}
            </InvoiceBoxColumn>
          </BoxRow>

          {isRecurring && <RecurringPaymentView bill={bill} isView />}

          <SectionSeparator />
        </PaymentInfoContainer>
      )}
      {!isMarkedAsPaid && !isRefundFlow && (
        <PaymentActivityContainer>
          <ViewBillPaymentActivity
            bill={bill}
            payment={payment}
            fundingSource={fundingSource}
            deliveryMethod={deliveryMethod}
            qbCashState={qbCashState}
          />
        </PaymentActivityContainer>
      )}
      {!isPaymentFailed && fee && <PaymentFee fee={fee} />}
    </MainBox>
  );
};

export default QBOViewBillPaymentReview;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.14);

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.MainBox}
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const RefundPaymentContainer = styled(BaseContainer)`
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentActivityContainer}
  padding-bottom: 0;
`;

const PaymentActivityContainer = styled(BaseContainer)`
  padding-bottom: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentActivityContainer}
`;

const PaymentInfoContainer = styled(BaseContainer)`
  padding-bottom: 1.7rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentInfoContainer}
`;

const BoxRow = styled.div`
  display: flex;
  margin-top: 1.8rem;
  column-gap: 1.6rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxColumn}
`;

const InvoiceBoxColumn = styled(BoxColumn)`
  ${(props) => props.theme?.components?.BillPaymentReview?.InvoiceBoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldName}
`;

const VendorFieldName = styled(FieldName)`
  ${(props) => props.theme?.components?.BillPaymentReview?.VendorFieldName}
`;

const FieldValue = styled.div<{ $hasPaymentTooltip?: boolean }>`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldValue}
  ${(props) => props.$hasPaymentTooltip && `margin-bottom: 0.25rem`};
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;
const SectionSeparator = styled.div`
  display: none;
  ${(props) => props.theme?.components?.BillPaymentReview?.SectionSeparator}
`;
