import {
  FundingSourceImage,
  PaymentMethodButtonContent,
} from 'src/app/version-2/pages/batch-bulk/renderers/PaymentMethod/PaymentMethod.renderer.styles';

import styled from 'styled-components';

export const HeadSectionWrap = styled.div<{ $sectionVisible: boolean }>`
  padding: 2rem 4.2rem 4rem 4.2rem;
  display: flex;
  visibility: ${(props) => (props.$sectionVisible ? 'visible' : 'hidden')};
`;

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.ds.h5};
  font-weight: ${(props) => props.theme.fontWeights.ds.semi};
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;

export const SmallSubTitle = styled.div`
  ${({ theme }) => theme.textStyles.ds.body4};
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
`;

export const LabelText = styled.div`
  ${({ theme }) => theme.textStyles.ds.body3};
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;

export const SwitchWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
`;

export const InfoIconContainer = styled.div<{ $isDisabled?: boolean }>`
  margin: 0 0.4rem;
  svg path {
    fill: ${({ theme, $isDisabled }) =>
      $isDisabled ? theme.colors.ds.gray[300] : theme.colors.ds.gray[200]};
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const RightHeadSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 5rem;
  max-width: 100%;
  padding: 0 40px;
  margin-top: 1.6rem;
  border-right: 1px solid ${({ theme }) => theme.colors.ds.white};
  & :last-of-type {
    border-right: 0 none;
    padding-right: 0;
  }
  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.colors.ds.gray[400]};
  }
  & :first-of-type {
    padding-left: 0;
  }
`;

export const ExpendableColumn = styled(Column)`
  width: 100%;
  max-width: 45rem;
  height: 5rem;
`;

export const FeeWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 2rem;
  row-gap: 0.3rem;
  text-align: right;
  align-items: center;
  justify-items: end;
`;

export const FeeTitle = styled.div<{ $isBold?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0;
  ${({ theme }) => theme.textStyles.ds.body3}
  font-weight: ${({ theme, $isBold }) =>
    $isBold ? theme.fontWeights.ds.semi : theme.fontWeights.ds.regular};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;

export const FeeAmount = styled.div<{ $isBold?: boolean }>`
  padding: 0;
  ${({ theme }) => theme.textStyles.ds.body3}
  font-weight: ${({ theme, $isBold }) =>
    $isBold ? theme.fontWeights.ds.semi : theme.fontWeights.ds.regular};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;

export const CombineBillsText = styled.div<{ $isDisabled: boolean }>`
  ${({ theme }) => theme.textStyles.ds.body2};
  color: ${({ theme, $isDisabled }) =>
    $isDisabled ? theme.colors.ds.gray[300] : theme.colors.ds.gray[100]};
`;

export const MultipleBillsText = styled.div<{ $isDisabled: boolean }>`
  ${({ theme }) => theme.textStyles.ds.body2};
  color: ${({ theme, $isDisabled }) =>
    $isDisabled ? theme.colors.ds.gray[300] : theme.colors.ds.gray[100]};
`;

export const AmountTitle = styled.div`
  ${({ theme }) => theme.textStyles.ds.h5};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  display: flex;
  align-items: flex-start;
  margin: 0.4rem 0;
`;

export const AmountSup = styled.span`
  ${({ theme }) => theme.textStyles.ds.body3};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;

export const MethodsMenuButton = styled.div`
  width: 100%;
  height: 100%;
  max-width: 214px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ds.gray[400]};
  padding-bottom: 3px;

  &:hover {
    cursor: pointer;
  }
`;

export const PaymentMethodButton = styled(PaymentMethodButtonContent)`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const PaymentFundingSourceImage = styled(FundingSourceImage)`
  height: 1.6rem;
  width: 1.6rem;
`;
