import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  MicroDepositState,
  MicroDepositActions,
} from 'src/app/pages/settings/hooks/microDepositsCommon';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ModalMessage as ModalMessageComponent } from 'src/app/components/common/ModalMessage';
import { getMicrodepositMask } from 'src/app/utils/currency-utils';
import { isEnterPressed } from 'src/app/utils/events';
import MINotification from 'src/app/components/common/MINotification';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';
import { MicroDepositPopover } from './MicroDepositPopover';
import { MITextInput } from '../common/MITextInput';
import { useMicroDepositAmountValidation } from './hooks/useMicroDepositAmountValidation';

type Props = MicroDepositState &
  MicroDepositActions & { fundingSourceId: number; accountNumberLast4Digits?: string };

function onKeyPressed(onSubmit, event: React.KeyboardEvent<any>) {
  if (isEnterPressed(event)) {
    onSubmit();
  }
}
export default function VerifyMicroDepositsDialog({
  amount1,
  amount2,
  onChange,
  validationErrors,
  serverError,
  onSubmit,
  dismiss,
  isLoading,
  companyName,
  accountNumberLast4Digits,
}: Props) {
  const [errors, handleChange, resetErrors] = useMicroDepositAmountValidation(onChange);

  const handleSubmit = useCallback(() => {
    onSubmit();
    resetErrors();
  }, [onSubmit, resetErrors]);

  const keyPressed = useCallback(
    (event) => onKeyPressed(handleSubmit, event),
    [onSubmit, resetErrors]
  );

  const hasErrors = () => Object.values(errors).some((value) => !!value);

  const areInputsEmpty = () => amount1 === '' || amount2 === '';

  return (
    <ModalMessage
      titleComponent={
        <>
          <div className="title">
            <MIFormattedText label="settings.microDeposits.verifyDialog.title" />
          </div>
          <div className="text">
            <MIFormattedText
              label="settings.microDeposits.verifyDialog.subtitle"
              values={{ accountNumber: accountNumberLast4Digits, companyName }}
            />
          </div>
        </>
      }
      contentComponent={
        <>
          <SumsFormContainer onKeyDown={keyPressed}>
            <TextInputContainer>
              <MITextInput
                id="amount1"
                label="settings.microDeposits.verifyDialog.sum1Label"
                placeholder="settings.microDeposits.verifyDialog.placeholder"
                value={amount1}
                autoFocus
                required
                onChange={handleChange}
                errorMessage={validationErrors.amount1 || errors.amount1}
                errorMessageIcon={<ErrorMessageIcon className="icon-warning-icon" />}
                mask={getMicrodepositMask()}
              />
            </TextInputContainer>
            <TextInputContainer>
              <MITextInput
                id="amount2"
                label="settings.microDeposits.verifyDialog.sum2Label"
                placeholder="settings.microDeposits.verifyDialog.placeholder"
                value={amount2}
                required
                onChange={handleChange}
                errorMessage={validationErrors.amount2 || errors.amount2}
                errorMessageIcon={<ErrorMessageIcon className="icon-warning-icon" />}
                mask={getMicrodepositMask()}
              />
            </TextInputContainer>
          </SumsFormContainer>
          {serverError && (
            <ErrorMessage>
              <ErrorMessageText>
                <MIFormattedText label={serverError} />
              </ErrorMessageText>
              <ErrorMessageNotification>
                <MINotification
                  label={serverError}
                  icon={<ErrorMessageIcon className="icon-warning-icon" />}
                  type="error"
                />
              </ErrorMessageNotification>
            </ErrorMessage>
          )}
          <MicroDepositPopover />
        </>
      }
      buttonComponent={
        <ButtonContainer>
          <MIButton
            onClick={handleSubmit}
            label="settings.microDeposits.verifyDialog.confirm"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            isProcessing={isLoading}
            disabled={hasErrors() || areInputsEmpty()}
          />
        </ButtonContainer>
      }
      onCloseClick={dismiss}
    />
  );
}

const ModalMessage = styled(ModalMessageComponent)`
  width: 100%;
  > .modal-message-container {
    overflow: inherit;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 1.5rem;
  text-align: center;

  ${(props) => props.theme?.components?.ModalMessage?.ButtonContainer}
`;

const SumsFormContainer = styled.form`
  display: flex;
`;

const TextInputContainer = styled.div`
  width: 100%;
  &:first-child {
    margin-right: 2rem;
  }
  .input-container {
    margin-bottom: 0;

    [data-testid='input-amount1-notices'],
    [data-testid='input-amount2-notices'] {
      position: absolute;
    }

    @media ${devices.mobile} {
      position: relative;

      [data-testid='input-amount1-notices'],
      [data-testid='input-amount2-notices'] {
        bottom: -22.5px;
      }
    }
  }
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  margin-top: 2rem;
  ${(props) => props.theme.text.fontType.hint};
`;

const ErrorMessageIcon = styled.i`
  display: none;
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageIcon}
`;

const ErrorMessageText = styled.div`
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageText}
`;

const ErrorMessageNotification = styled.div`
  display: none;
  ${(props) => props.theme?.components?.VerifyMicroDepositsDialog?.ErrorMessageNotification}
`;
