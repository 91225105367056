import { useHistory } from 'react-router-dom';
import { BackButtonProps } from 'src/app/pages/vendor/components/VendorLayoutElements';
import { getIsVendorPaymentDetailsFlow } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import { navigateOnTrackPaymentPage } from 'src/app/version-2/pages/vendor-upgrade-payment/utils/navigation.utils';
import { StyledBackButton } from './BackToTrackPaymentPageButton.styles';

type BackToTrackPaymentPageButtonProps = Pick<BackButtonProps, 'analyticsProps'> & {
  token: string;
};

export const BackToTrackPaymentPageButton = ({
  token,
  analyticsProps,
}: BackToTrackPaymentPageButtonProps) => {
  const history = useHistory();

  const isUserCameFromPaymentDetailsFlow = getIsVendorPaymentDetailsFlow(token);

  if (!isUserCameFromPaymentDetailsFlow) {
    return null;
  }

  return (
    <StyledBackButton
      data-testid="go-back-track-payment-page-button"
      analyticsProps={analyticsProps}
      onPrev={() => navigateOnTrackPaymentPage(history, token)}
      isAbsolutePosition={true}
    />
  );
};
