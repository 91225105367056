import * as React from 'react';
import { devices } from 'src/app/theme/AppDevices';
import attachmentIcon from 'src/app/images/bills/attachment-icon.svg';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo.svg';
import styled from 'styled-components';

type BillAttachmentCounterProps = {
  isQBOAttachment?: boolean;
  count: number;
};

const BillAttachmentCounter: React.FC<BillAttachmentCounterProps> = ({
  isQBOAttachment,
  count,
}) => (
  <CounterWrapper>
    {isQBOAttachment && <QBLogo src={qbLogo} />}
    <IconContainer className="counter-icon-container" count={count}>
      <Icon src={attachmentIcon} />
      {count > 1 && <Count>{count}</Count>}
    </IconContainer>
  </CounterWrapper>
);

export default BillAttachmentCounter;

const CounterWrapper = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  opacity: 0.7;
  background-color: ${(props) => props.theme.text.color.light};
`;

const Icon = styled.img`
  width: 0.8rem;

  @media ${devices.mobile} {
    width: 1rem;
  }
`;

const QBLogo = styled.img`
  width: 2rem;
  margin-right: 0.8rem;

  @media ${devices.mobile} {
    width: 2.4rem;
  }
`;

const Count = styled.span`
  margin-left: 0.36rem;
  line-height: 1.4rem;
  color: ${(props) => props.theme.colors.white.opaque};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};

  @media ${devices.mobile} {
    font-size: ${(props) => props.theme.text.size.subNav};
  }
`;
