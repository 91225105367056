import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import { isCardExpired } from 'src/app/utils/card';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { FundingSource } from 'src/app/version-2/model/dtos/fundingSource';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { isBankAccountBlocked } from 'src/app/version-2/utils/paymentMethods.utils';

export const FUNDING_SOURCES_SLICE = `${VERSION_2}fundingSources`;

export const selectState = (state: any) => state[FUNDING_SOURCES_SLICE];

export interface FundingSourceSlice {
  fundingSources: EntityState<FundingSource>;
  isFundingSourcesFetched?: boolean;
}

const fundingSourceAdapter = createEntityAdapter<FundingSource>({
  selectId: (fundingSource) => fundingSource.id,
});

export const getFundingSourcesInitialState = (): FundingSourceSlice => ({
  fundingSources: fundingSourceAdapter.getInitialState(),
  isFundingSourcesFetched: false,
});

export const fundingSourcesSlice = createSlice({
  name: FUNDING_SOURCES_SLICE,
  initialState: getFundingSourcesInitialState(),
  reducers: {
    addFundingSourceSuccess: (state, action: PayloadAction<FundingSource>) => {
      fundingSourceAdapter.addOne(state.fundingSources, action.payload);
    },
    updatedFundingSourceSuccess: (state, action: PayloadAction<Update<FundingSource>>) => {
      fundingSourceAdapter.updateOne(state.fundingSources, action.payload);
    },
    deleteFundingSourceSuccess: (state, action: PayloadAction<number>) => {
      fundingSourceAdapter.removeOne(state.fundingSources, action.payload);
    },
    fetchFundingSourcesSuccess: (state, action: PayloadAction<FundingSource[]>) => {
      fundingSourceAdapter.setAll(state.fundingSources, action.payload);
    },
    fetchFundingSourcesFail: (state) => {
      fundingSourceAdapter.removeAll(state.fundingSources);
    },
    setIsFundingSourcesFetched: (state, action: PayloadAction<boolean>) => {
      state.isFundingSourcesFetched = action.payload;
    },
  },
});

export const fundingSourcesActions = {
  ...fundingSourcesSlice.actions,
  fetchFundingSources: createAction(`${FUNDING_SOURCES_SLICE}/fetch`),
  fetchFundingSourcesV2: createAction(`${FUNDING_SOURCES_SLICE}/fetchV2`),
};

export const fundingSourcesSelectors = {
  selectFundingSourcesFetched: createSelector(
    selectState,
    (state) => state.isFundingSourcesFetched
  ),
  selectValidFundingSources: createSelector(selectState, (state) => {
    const fundingSources = fundingSourceAdapter.getSelectors().selectAll(state.fundingSources);

    return fundingSources
      .filter(
        (fs) =>
          !(fs.fundingType === FundingSourceTypesEnum.CARD && isCardExpired(fs.cardAccount as any))
      )
      .filter(
        (fs) =>
          !(
            fs.fundingType === FundingSourceTypesEnum.ACH &&
            isBankAccountBlocked(fs as FundingSource)
          )
      ); // TODO: use our isCardExpired functionality
  }),
};
