import React from 'react';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import { CheckType, EditableDeliveryMethodType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import { useSelector, useDispatch } from 'react-redux';
import deliveryMethodsStore, {
  getDeliveryMethodActions,
} from 'src/app/modules/delivery-methods/delivery-methods-store';
import CheckDeliveryMethod from 'src/app/pages/vendor/delivery-methods/CheckDeliveryMethod';
import justPayLocations from 'src/app/pages/just-pay/justPayLocations';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import analytics from 'src/app/services/analytics';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const CheckDeliveryMethodPage = ({ onNext, onPrev, onExit }: Props) => {
  const dispatch = useDispatch();

  const {
    payment: {
      vendor: { id: vendorId },
      deliveryMethodId: selectedDeliveryMethodId,
      orgId,
      trackingBillId,
    },
    paymentStoreActions: paymentActions,
  } = useJustPayStore();

  const deliveryMethodActions = getDeliveryMethodActions(dispatch);
  const isLoadingCreate = (
    useSelector(deliveryMethodsStore.selectors.create.status()) as { loading: boolean }
  )?.loading;
  const isLoadingUpdate = (
    useSelector(deliveryMethodsStore.selectors.update.status(selectedDeliveryMethodId)) as {
      loading: boolean;
    }
  )?.loading;
  const { fetchVendor } = useFetchVendor(vendorId.toString());
  const onSuccess = async (
    paperCheck: CheckType,
    isVerified: boolean,
    checkDeliveryMethodId?: string
  ) => {
    try {
      analytics.trackAction('add-delivery-method', {
        deliveryMethodType: CONSTS.DELIVERY_TYPE.CHECK,
        trackingBillId,
      });

      const deliveryMethod: EditableDeliveryMethodType = {
        paperCheck,
        isVerified,
        deliveryType: CONSTS.DELIVERY_TYPE.CHECK,
      };
      let response;

      if (checkDeliveryMethodId && vendorId) {
        response = await deliveryMethodActions.edit({
          orgId: orgId.toString(),
          vendorId: vendorId.toString(),
          id: checkDeliveryMethodId,
          deliveryMethod,
        });
      } else {
        response = await deliveryMethodActions.create({
          orgId,
          vendorId,
          params: deliveryMethod,
        });
      }

      await paymentActions.justPay.justPayWizard.update({
        deliveryMethodId: response.payload.id,
        deliveryMethodType: response.payload.deliveryType,
      });

      analytics.trackAction('create-delivery-method-success', {
        deliveryMethodType: response.payload.deliveryType,
        trackingBillId,
      });
      await fetchVendor();

      onNext();
    } catch (error) {
      analytics.trackAction('create-delivery-method-failure', {
        deliveryMethodType: CONSTS.DELIVERY_TYPE.CHECK,
        trackingBillId,
      });
    }
  };

  return (
    <CheckDeliveryMethod
      onSuccess={onSuccess}
      goExit={onExit}
      onPrev={onPrev}
      isLoading={isLoadingCreate || isLoadingUpdate}
      vendorId={vendorId.toString()}
      nextLabel="justPay.deliveryMethods.check.cta"
      title="justPay.deliveryMethods.check.title"
      selectDeliveryMethodPath={justPayLocations.create.selectDeliveryMethod}
    />
  );
};

export default CheckDeliveryMethodPage;
