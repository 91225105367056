import React, { useCallback, useMemo } from 'react';
import { Box, Flex, Link } from '@melio/billpay-design-system';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { CATEGORY_LIST_VIEW_VARIANTS, CONSTS, DELIVERY_TYPE } from 'src/app/utils/consts';
import { DeliveryMethodType, PaymentType, ReferrerType } from 'src/app/utils/types';
import { MICategoryList } from 'src/app/components/common/MICategoryList';
import { MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';
import shiftToAchLocations from 'src/app/pages/vendor/shift-vendor-to-ach/locations';

import { useBreak } from 'src/app/hoc';
import * as VIRTUAL_CARD_LOCATIONS from '../../virtual-card-details/locations';
import virtualCardLocations from '../../locations';

type PropsType = {
  token: string;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  analyticsProps?: { [key: string]: any };
  translationPath: string;
};

const VendorCheckSelectCategories = ({
  payment,
  analyticsProps,
  deliveryMethod,
  token,
  translationPath,
}: PropsType) => {
  const history = useHistory();
  const { isMobile } = useBreak();

  const selectDeliveryMethod = useCallback(
    (deliveryMethodType) => {
      analytics.trackAction('DeliveryMethod-Chose', {
        deliveryMethodType,
        ...analyticsProps,
      });
      const referrer =
        payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH
          ? ReferrerType.SHIFT_ACH_TO_VC
          : ReferrerType.SHIFT_CHECK_TO_VC;

      switch (deliveryMethodType) {
        case DELIVERY_TYPE.VIRTUAL_CARD:
          history.push(generatePath(virtualCardLocations.virtualCardDetails.optIn, { token }), {
            deliveryMethod,
            deliveryMethodId: deliveryMethod?.id,
            payment,
            referrer,
          });
          break;
        case DELIVERY_TYPE.ACH:
          history.push(
            generatePath(shiftToAchLocations.index, { token, id: payment.deliveryMethod.id })
          );

          break;
        default:
          break;
      }
    },
    [token, deliveryMethod, payment]
  );

  const categories = useMemo(() => {
    const handleMoreInfoVirtualCard = (e) => {
      e.stopPropagation();
      analytics.trackAction('MoreInformation-Learned', {
        infoType: DELIVERY_TYPE.VIRTUAL_CARD,
        ...analyticsProps,
      });
      history.push(generatePath(VIRTUAL_CARD_LOCATIONS.default.infoPage, { token }), {
        payment,
      });
    };

    return [
      {
        id: DELIVERY_TYPE.VIRTUAL_CARD,
        label: `${translationPath}.virtualCard.label`,
        icon: 'icon-credit-card-icon',
        info: (
          <CategoryInfoContainer>
            <CategoryVirtualCardInfoContainer>
              <MIFormattedText label={`${translationPath}.virtualCard.hint`} />
              <Box as="span" onClick={handleMoreInfoVirtualCard}>
                <LinkContainer>
                  <MIFormattedText
                    label={`${translationPath}.virtualCard.learnMore`}
                    values={{
                      link: (...chunks) => (
                        <Link cursor="pointer">{React.Children.toArray(chunks)}</Link>
                      ),
                    }}
                  />
                </LinkContainer>
              </Box>
            </CategoryVirtualCardInfoContainer>
          </CategoryInfoContainer>
        ),
        includeArrow: true,
      },
      {
        id: DELIVERY_TYPE.ACH,
        label: `${translationPath}.ach.label`,
        icon: 'icon-bank-icon',
        hint: `${translationPath}.ach.hint`,
        includeArrow: true,
      },
    ];
  }, [history, token, payment]);

  return (
    <Flex p={isMobile ? 5 : 8}>
      <CategoryListContainer>
        <MICategoryList
          categories={categories}
          selectedId={deliveryMethod?.deliveryType}
          onSelect={selectDeliveryMethod}
          analyticsProps={analyticsProps}
          withShadow={false}
          viewVariant={CATEGORY_LIST_VIEW_VARIANTS.COLUMN}
        />
      </CategoryListContainer>
    </Flex>
  );
};

const CategoryInfoContainer = styled.div`
  ${({ theme }) => theme.text.fontType.medium};
  color: ${({ theme }) => theme.text.color.darkGrey};
  text-align: initial;
  @media ${devices.mobile}, ${devices.phablet} {
    white-space: initial;
  }
`;

const CategoryVirtualCardInfoContainer = styled.div`
  ${({ theme }) => theme.text.fontType.medium};
  color: ${({ theme }) => theme.text.color.darkGrey};
  font-weight: 500;
  white-space: normal;
`;

const CategoryListContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  & > div {
    text-align: initial;
    width: 100%;
  }
`;

const LinkContainer = styled.span`
  color: ${({ theme }) => theme.text.color.blue};
  &:hover {
    text-decoration: underline;
  }
`;

export default VendorCheckSelectCategories;
