import { RecordOf } from 'immutable';
import React from 'react';
import classNames from 'classnames';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS, FAILED_PAYMENT_TYPE } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BillType, DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodText,
  PaymentInfoText,
  EditElement,
  PaymentMethodContainer,
} from 'src/app/components/layout/PaymentActivityElements';
import {
  getPaymentById,
  isUndepositedPayment,
  isVirtualCardPaymentExpired,
} from 'src/app/utils/payments';
import { isSharedVendor } from 'src/app/pages/vendor/utils';
import { getDeliveryMethodInfo } from 'src/app/pages/vendor/records';
import PayBillPaymentActivitySendingToCountry from './PayBillPaymentActivitySendingToCountry';
import Tag from './Tag';
import { DeliveryMethodIcon } from './DeliveryMethodIcon';
import { DeliveryMethodInfoIcon } from './DeliveryMethodInfoIcon';
import { shouldAllowEditDeliveryMethod } from 'src/app/version-2/utils/deliveryMethod.utils';
import { DeliveryMethod } from 'src/app/version-2/model/dtos';

type Props = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  onEdit?: () => void;
};

const PayBillPaymentActivityDeliveryMethodInfo = ({
  deliveryMethod,
  onEdit,
  bill,
  payment,
}: Props) => {
  const isRPPSDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;
  const isVirtualCardDeliveryMethod =
    deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;
  const isCheckDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.CHECK;
  const isInternationalMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL;

  const originPayment = getPaymentById(bill.payments, payment.id);
  const isVirtualCardExpired = isVirtualCardPaymentExpired(payment, originPayment?.deliveryMethod);
  const isVirtualCard = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;

  const canEdit =
    shouldAllowEditDeliveryMethod(deliveryMethod as unknown as DeliveryMethod) ||
    isVirtualCardExpired ||
    (!isRPPSDeliveryMethod &&
      !isVirtualCard &&
      !isInternationalMethod &&
      !isSharedVendor(bill?.vendor) &&
      payment?.metadata?.failedType !== FAILED_PAYMENT_TYPE.FAILED_TO_COLLECT);

  const isUpdated =
    originPayment &&
    (payment?.metadata?.failedType || isUndepositedPayment(payment)) &&
    deliveryMethod?.id !== originPayment?.deliveryMethodId;

  const deliveryMethodInfo = getDeliveryMethodInfo({
    deliveryMethod,
    vendorName: bill?.vendor?.companyName,
    isSharedVendor: isSharedVendor(bill?.vendor),
  });

  const getDeliveryMethodLabel = () => {
    if (isRPPSDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.rppsLabel';
    }

    if (isVirtualCardDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.virtualCardLabel';
    }

    if (isCheckDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.paperCheckLabel';
    }

    if (isInternationalMethod) {
      return 'payBillPaymentActivity.deliveryMethod.internationalLabel';
    }

    return 'payBillPaymentActivity.deliveryMethod.achLabel';
  };

  const getDeliveryMethodLabelValues = () => {
    if (!shouldAllowEditDeliveryMethod(deliveryMethod as unknown as DeliveryMethod)) {
      return {
        icon: (
          <DeliveryMethodInfoIcon tooltipLabel="payBillPaymentActivity.deliveryMethod.virtualCardTooltip" />
        ),
      };
    }

    return { icon: <></> };
  };

  const paymentInfoTextClassName = classNames({
    [FULL_STORY_MASK_RULE_CLASS]: isCheckDeliveryMethod,
  });

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <DeliveryMethodIcon deliveryMethod={deliveryMethod} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodContainer>
              <PaymentMethodText>
                <MIFormattedText
                  label={getDeliveryMethodLabel()}
                  values={getDeliveryMethodLabelValues()}
                />
              </PaymentMethodText>
            </PaymentMethodContainer>
            <PaymentInfoText
              className={paymentInfoTextClassName}
              data-testid="bill-pay-delivery-method"
            >
              {deliveryMethodInfo}
              {isUpdated && <Tag label="payBillPaymentActivity.updated" />}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {canEdit && <EditElement onEdit={onEdit} />}
      </PaymentActivityMainContainer>
      {isInternationalMethod && (
        <PayBillPaymentActivitySendingToCountry deliveryMethod={deliveryMethod} />
      )}
    </PaymentActivityContainer>
  );
};

export default PayBillPaymentActivityDeliveryMethodInfo;
