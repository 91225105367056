import * as React from 'react';
import { RecordOf } from 'immutable';
import { FieldType, VendorType } from 'src/app/utils/types';
import { ScreenModeEnum, FormTypeEnum, FormControlTypeEnum } from 'src/app/version-2/model/enums';
import { FORM_AUTOCOMPLETE_OFF } from 'src/app/version-2/model/constants';
import MIForm from '../../../components/common/MIForm';

type Props = {
  vendor: RecordOf<VendorType>;
  mode?: ScreenModeEnum;
  onChange: (arg0: FieldType) => void;
  validationErrors: Record<string, string>;
  formType?: FormTypeEnum;
};

const QBOVendorForm = ({
  vendor,
  validationErrors,
  onChange,
  mode = ScreenModeEnum.EDIT,
  formType = FormTypeEnum.WIZARD,
}: Props) => {
  const vendorFormControls = [
    {
      id: 'companyName',
      label: 'vendors.create.name.inputTitle',
      value: vendor.companyName,
      onChange,
      required: true,
      autoFocus: true,
      errorMessage: validationErrors.companyName,
      controlType: FormControlTypeEnum.TEXT,
      autocomplete: FORM_AUTOCOMPLETE_OFF,
    },
    {
      id: 'contactEmail',
      label: 'vendors.create.email.inputTitle',
      value: vendor.contactEmail,
      onChange,
      errorMessage: validationErrors.contactEmail,
      controlType: FormControlTypeEnum.TEXT,
      emptyLabel: 'vendors.form.contactEmailEmpty',
      autocomplete: FORM_AUTOCOMPLETE_OFF,
      privateData: true,
    },
  ];

  return <MIForm formType={formType} formControls={vendorFormControls} mode={mode} />;
};

export default QBOVendorForm;
