const baseUrl = '/orgs/:orgId/quickpay';

const LOCATIONS = {
  base: [baseUrl],
  create: {
    entry: `${baseUrl}/entry`,
    deliveryMethod: `${baseUrl}/delivery-method/:vendorId`,
    confirm: `${baseUrl}/confirm`,
    note: `${baseUrl}/note`,
    date: `${baseUrl}/date`,
    success: `${baseUrl}/success`,
    notifyVendor: `${baseUrl}/notifyVendor`,
    completeCompanyInfo: `${baseUrl}/complete-company-info`,
  },
  edit: {
    base: `${baseUrl}/:paymentId/edit`,
    entry: `${baseUrl}/:paymentId/edit/entry`,
    deliveryMethod: `${baseUrl}/:paymentId/edit/delivery-method/:vendorId`,
    note: `${baseUrl}/:paymentId/edit/note`,
    date: `${baseUrl}/:paymentId/edit/date`,
    confirm: `${baseUrl}/:paymentId/edit/confirm`,
    achNew: `${baseUrl}/:paymentId/edit/delivery-methods/ach/:vendorId`,
    ach: `${baseUrl}/:paymentId/edit/delivery-methods/:deliveryMethodId/ach/:vendorId`,
    checkNew: `${baseUrl}/:paymentId/edit/delivery-methods/check/:vendorId`,
    check: `${baseUrl}/:paymentId/edit/delivery-methods/:deliveryMethodId/check/:vendorId`,
  },
  deliveryMethods: {
    check: {
      new: `${baseUrl}/delivery-method/check/:vendorId`,
      edit: `${baseUrl}/delivery-method/:deliveryMethodId/check/edit/:vendorId`,
    },
    ach: {
      new: `${baseUrl}/delivery-method/ach/new/:vendorId`,
      edit: `${baseUrl}/delivery-method/:deliveryMethodId/ach/edit/:vendorId`,
    },
  },
  list: {
    index: `${baseUrl}/bills`,
    hello: `${baseUrl}/hello`,
  },
  errorPage: `${baseUrl}/error-page`,
};

export default LOCATIONS;
