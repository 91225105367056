import React from 'react';
import { Box, Flex, Image } from '@melio/billpay-design-system';

import { DashboardListItemFundingSourceType } from 'src/app/pages/qb-dashboard/types';
import { getCardImageUrl } from 'src/app/utils/card';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { iconStyles, imageStyles } from './styles';

type Props = {
  fundingSource?: FundingSource | DashboardListItemFundingSourceType;
};

const CardMethod = ({ fundingSource }: Props) => {
  const cardImage = getCardImageUrl(fundingSource as FundingSource);

  return (
    <Flex>
      {cardImage ? (
        <Image __css={imageStyles} src={cardImage} />
      ) : (
        <Box as="i" __css={iconStyles} className="icon-debit-card-icon" />
      )}
      {`...${fundingSource?.cardAccount?.card4digits}`}
    </Flex>
  );
};

export default CardMethod;
