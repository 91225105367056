import React from 'react';
import { useIntl } from 'react-intl';
import { SmartIcon, Text } from '@melio/billpay-design-system';
import {
  DeliveryMethodButtonContainer,
  DeliveryMethodButtonContent,
  DeliveryMethodButtonLabel,
  DropDownIconContainer,
} from 'src/app/version-2/pages/batch-bulk/renderers/DeliveryMethod/DeliveryMethod.renderer.styles';

export const DeliveryMethodButton = ({ deliveryMethod, isMenuOpen }) => {
  const { formatMessage } = useIntl();

  const {
    meta: { label },
  } = deliveryMethod;

  return (
    <DeliveryMethodButtonContainer>
      <DeliveryMethodButtonLabel>
        <Text
          textStyle="ds.body3"
          as={DeliveryMethodButtonContent}
          data-testid="current-delivery-method"
        >
          {formatMessage({ id: label })}
        </Text>
      </DeliveryMethodButtonLabel>
      <DropDownIconContainer isOpen={isMenuOpen}>
        <SmartIcon type="arrow-dropdown" />
      </DropDownIconContainer>
    </DeliveryMethodButtonContainer>
  );
};
