import { ChangeEvent } from 'react';
import { useTheme, SelectProps as ChakraSelectProps } from '@chakra-ui/react';
import ReactSelect, { components, Props as ReactSelectProps } from 'react-select';

import { FieldWrapper } from '../FieldWrapper';
import { FieldWrapperType } from '../../types';
import { SmartIcon } from '../SmartIcon';

import { selectStyleTemplate } from './Select.styles';

export type Option = { value: string; label: string };

export type SelectProps = Omit<FieldWrapperType, 'leftAddon' | 'rightAddon'> &
  Omit<ChakraSelectProps, 'variant' | 'size' | 'icon' | 'value' | 'onChange' | 'defaultValue'> &
  Omit<ReactSelectProps, 'onChange' | 'options'> & {
    options: Option[];
  // eslint-disable-next-line no-unused-vars
    onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  };

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SmartIcon type="dropdown" width="0.8rem" height="0.5rem" />
  </components.DropdownIndicator>
);

const Select = ({
  options, onChange, label, helperText, errorMessage, tooltipLabel, ...rest
}: SelectProps) => {
  const theme = useTheme();
  const styles = selectStyleTemplate(false, theme);

  const selectProps = {
    blurInputOnSelect: false,
    styles,
    options,
    components: { DropdownIndicator },
    menuPortalTarget: document.body,
  };

  const handleChange = (selectedOption: Option) => onChange?.(
    {
      target: { value: selectedOption.value },
    } as ChangeEvent<HTMLSelectElement>,
  );

  const input = (id) => (
    <ReactSelect
      {...selectProps}
      {...rest}
      id={id}
      onChange={(newValue) => handleChange(newValue as Option)}
    />
  );

  return (
    <FieldWrapper
      input={input}
      label={label}
      helperText={helperText}
      errorMessage={errorMessage}
      tooltipLabel={tooltipLabel}
      isDisabled={rest.isDisabled}
    />
  );
};

export { Select };
