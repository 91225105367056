import React from 'react';
import noop from 'lodash/noop';
import { Box, Flex, Menu } from '@melio/billpay-design-system';

import { ReactComponent as ArrowDownIcon } from 'src/app/images/qbo/arrow-down.svg';
import { ActionEventPropertiesType } from 'src/app/pages/qb-dashboard/types';
import analytics from 'src/app/services/analytics';
import { MIFormattedText } from 'src/app/utils/formatting';
import MainActionWrapper from './MainActionWrapper';
import { mainActionLabelStyle, menuButtonStyle } from './styles';

type Action = {
  id: string;
  label: string;
  onClick: () => void;
  tooltip?: string;
};

type Props = {
  isDisabled?: boolean;
  eventProperties: ActionEventPropertiesType;
  actions?: Action[];
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const QBOActionsCell = ({
  eventProperties,
  isDisabled,
  actions = [],
  onMouseEnter = noop,
  onMouseLeave = noop,
}: Props) => {
  const [mainAction, ...menuActions] = actions;
  const showActionsMenu = menuActions.length > 0;

  const handleActionClick = (event: React.MouseEvent, action: Action) => {
    event.stopPropagation();

    if (!isDisabled) {
      analytics.trackAction('action-click', {
        ...eventProperties,
        action: action.id,
        fromRow: false,
      });
      action.onClick();
    }
  };

  const onMenuButtonClick = (event) => {
    event.stopPropagation();

    if (isDisabled) {
      event.preventDefault();
    }
  };

  return (
    <Flex
      justifyContent="flex-end"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      mr={showActionsMenu ? '-1' : '0'}
    >
      <MainActionWrapper tooltip={mainAction.tooltip}>
        <Box
          as="span"
          __css={mainActionLabelStyle({ isDisabled })}
          onClick={(event) => handleActionClick(event, mainAction)}
          data-testid={`dashboard-main-action-${eventProperties.billId}`}
        >
          <MIFormattedText label={mainAction.label} />
        </Box>
      </MainActionWrapper>

      {showActionsMenu && (
        <Menu>
          <Menu.MenuButton
            as={Box}
            ml="2"
            px="1"
            data-testid={`dashboard-actions-list-button-${eventProperties.billId}`}
            onClick={onMenuButtonClick}
            __css={menuButtonStyle({ isDisabled })}
          >
            <ArrowDownIcon />
          </Menu.MenuButton>
          <Menu.MenuList>
            {menuActions.map((action) => (
              <Menu.MenuItem
                key={action.label}
                onClick={(event) => handleActionClick(event, action)}
                data-testid={`dashboard-actions-${action.label}`}
              >
                <MIFormattedText label={action.label} />
              </Menu.MenuItem>
            ))}
          </Menu.MenuList>
        </Menu>
      )}
    </Flex>
  );
};

export default QBOActionsCell;
