import { RecordOf } from 'immutable';
import React from 'react';
import classNames from 'classnames';
import maxBy from 'lodash/maxBy';
import {
  BILL_STATUS,
  DELIVERY_TYPE,
  FULL_STORY_MASK_RULE_CLASS,
  PAYMENT_STATUS,
} from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BillType, DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodText,
  PaymentInfoText,
  PaymentMethodContainer,
} from 'src/app/components/layout/PaymentActivityElements';
import PayBillPaymentActivitySendingToCountry from 'src/app/pages/bill/pay/components/PayBillPaymentActivity/components/PayBillPaymentActivitySendingToCountry';
import { isPushToDebitPayment } from 'src/app/utils/payments';
import { getDeliveryMethodInfo } from 'src/app/pages/vendor/records';
import { isSharedVendor } from 'src/app/pages/vendor/utils';
import { getFormattedCheckSerial } from 'src/app/utils/bills';
import { PaymentDeliveryStatusEnum } from 'src/app/version-2/model/enums';
import { DeliveryMethodIcon } from './DeliveryMethodIcon';
import { DeliveryMethodInfoHint } from './DeliveryMethodInfoHint';

type Props = {
  bill: RecordOf<BillType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  payment: RecordOf<PaymentType>;
};

const ViewBillPaymentActivityDeliveryMethodInfo = ({ deliveryMethod, bill, payment }: Props) => {
  const { deliveryType } = deliveryMethod;
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const isACHDeliveryMethod = deliveryType === DELIVERY_TYPE.ACH;
  const isRPPSDeliveryMethod = deliveryType === DELIVERY_TYPE.RPPS;
  const isCheckDeliveryMethod = deliveryType === DELIVERY_TYPE.CHECK;
  const isVirtualCardDeliveryMethod = deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;
  const isInternationalMethod = deliveryMethod.deliveryType === DELIVERY_TYPE.INTERNATIONAL;
  const virtualCardInfo = maxBy(payment.virtualCards, 'createdAt');
  const isPaymentFailed = payment.status === PAYMENT_STATUS.FAILED;
  const isPaymentIsCompletedAndCleared =
    payment.status === PAYMENT_STATUS.COMPLETED &&
    payment.deliverStatus === PaymentDeliveryStatusEnum.CLEARED;
  const shouldShowLabelWithVirtualCardInfo =
    isVirtualCardDeliveryMethod &&
    virtualCardInfo &&
    (isPaymentFailed || isPaymentIsCompletedAndCleared);
  const isDebitConverted = isPushToDebitPayment(payment);

  let labelValues = {};
  let label = '';

  if (isCheckDeliveryMethod && isBillPaid) {
    label = 'viewBillPaymentActivity.deliveryMethod.paidBillPaperCheckLabel';
    const formattedCheckSerial = getFormattedCheckSerial(payment);

    labelValues = { formattedCheckSerial };
  } else if (isACHDeliveryMethod && isBillPaid) {
    label = 'viewBillPaymentActivity.deliveryMethod.paidBillAchLabel';
  } else if (isCheckDeliveryMethod) {
    label = 'viewBillPaymentActivity.deliveryMethod.paperCheckLabel';
  } else if (isRPPSDeliveryMethod) {
    label = 'viewBillPaymentActivity.deliveryMethod.rppsLabel';
  } else if (shouldShowLabelWithVirtualCardInfo) {
    label = 'viewBillPaymentActivity.deliveryMethod.virtualCardLabelWithDetails';
    labelValues = {
      card4digits: virtualCardInfo?.accountNumber4digits,
    };
  } else if (isVirtualCardDeliveryMethod) {
    label = 'viewBillPaymentActivity.deliveryMethod.virtualCardLabel';
  } else if (isInternationalMethod) {
    label = 'viewBillPaymentActivity.deliveryMethod.internationalLabel';
  } else if (isDebitConverted) {
    label = 'viewBillPaymentActivity.deliveryMethod.bankDebitCard';
  } else {
    label = 'viewBillPaymentActivity.deliveryMethod.achLabel';
  }

  const deliveryMethodInfo = getDeliveryMethodInfo({
    deliveryMethod,
    vendorName: bill?.vendor?.companyName,
    isSharedVendor: isSharedVendor(bill?.vendor),
  });

  const paymentInfoTextClassName = classNames({
    [FULL_STORY_MASK_RULE_CLASS]: isCheckDeliveryMethod,
  });

  return (
    <PaymentActivityContainer data-testid="view-payment-activity-delivery-method-info">
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon isBillPaid={isBillPaid}>
            <DeliveryMethodIcon deliveryMethod={deliveryMethod} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodContainer>
              <PaymentMethodText data-testid="bill-payment-method">
                <MIFormattedText label={label} values={labelValues} />
              </PaymentMethodText>
            </PaymentMethodContainer>
            <PaymentInfoText
              className={paymentInfoTextClassName}
              data-testid="bill-pay-delivery-method"
              isBillPaid={isBillPaid}
            >
              {deliveryMethodInfo}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
      </PaymentActivityMainContainer>
      <DeliveryMethodInfoHint
        payment={payment}
        deliveryMethod={deliveryMethod}
        virtualCard={virtualCardInfo}
      />
      {isInternationalMethod && (
        <PayBillPaymentActivitySendingToCountry deliveryMethod={deliveryMethod} />
      )}
    </PaymentActivityContainer>
  );
};

export { ViewBillPaymentActivityDeliveryMethodInfo };
