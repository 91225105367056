import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import compact from 'lodash/compact';
import paymentsStore from 'src/app/modules/payments/payment-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { getFundingSources } from 'src/app/redux/user/selectors';
import useViewPaymentAction from 'src/app/pages/qb-dashboard/hooks/useViewPaymentAction';
import useVerifyMicroDepositAction from 'src/app/pages/qb-dashboard/hooks/useVerifyMicroDepositAction';
import useCancelPaymentAction from 'src/app/pages/qb-dashboard/hooks/useCancelPaymentAction';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { canVerifyManualBankAccount } from 'src/app/utils/funding-sources';
import useEditPaymentAction from 'src/app/pages/qb-dashboard/hooks/useEditPaymentAction';
import { DASHBOARD_ITEM_ACTIONS } from 'src/app/pages/qb-dashboard/consts';
import billsStore from 'src/app/modules/bills/bills-store';
import { useGetActionCellEventProperties } from 'src/app/pages/qb-dashboard/hooks/useGetActionCellEventProperties';
import QBOActionsCell from './QBOActionsCell';
import { BillType, PaymentType, VendorType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const QBOScheduledActionCell = ({ item }: Props) => {
  const { fundingSourceId, billId, vendorId, paymentId, metadata } = item;
  const bill: BillType = useSelector(billsStore.selectors.byId(billId));
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(paymentId));
  const { eventProperties } = useGetActionCellEventProperties({
    billId,
    paymentId,
    status: metadata.statusInDashboard,
  });
  const isFinancingPayment = !!payment?.financingAccount?.id;
  const [goViewPayment] = useViewPaymentAction();
  const [goEditPayment] = useEditPaymentAction({
    billId,
    paymentOriginId: payment?.originId,
    paymentId,
  });
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(vendorId));
  const [CancelPaymentDialog, showCancelPaymentDialog] = useCancelPaymentAction({
    billId,
    paymentId,
    companyName: vendor?.companyName,
    amount: payment?.amount?.toString() as string,
    modalName: 'cancel-payment-dialog',
    recurringBillId: bill?.recurringBillId as unknown as number,
  });

  const [, showVerifyMDDialog] = useVerifyMicroDepositAction(fundingSourceId, paymentId);
  const fundingSources = useSelector(getFundingSources);

  const handleViewPaymentClick = useCallback(() => {
    goViewPayment({
      billId,
      paymentOriginId: payment?.originId,
      paymentId,
    });
  }, [billId, paymentId, payment, goViewPayment]);

  const addMicroDepositCellAction = useCallback(() => {
    const selectedFundingSource = fundingSources.find(
      (fs) => fs.id.toString() === (fundingSourceId?.toString() as string)
    );

    if (fundingSourceId && canVerifyManualBankAccount(selectedFundingSource)) {
      return {
        id: DASHBOARD_ITEM_ACTIONS.VERIFY_MD,
        onClick: showVerifyMDDialog,
        label: 'paymentDashboard.columns.actions.verifyAccount',
      };
    }

    return null;
  }, [fundingSourceId, showVerifyMDDialog, fundingSources]);

  const viewPaymentCellAction = {
    id: DASHBOARD_ITEM_ACTIONS.VIEW,
    onClick: handleViewPaymentClick,
    label: 'paymentDashboard.columns.actions.view',
  };

  const cancelPaymentAction = {
    id: DASHBOARD_ITEM_ACTIONS.CANCEL,
    onClick: showCancelPaymentDialog,
    label: 'paymentDashboard.columns.actions.cancel',
  };

  const editPaymentAction = {
    id: DASHBOARD_ITEM_ACTIONS.EDIT,
    onClick: goEditPayment,
    label: 'paymentDashboard.columns.actions.edit',
  };

  const actions = compact([
    addMicroDepositCellAction(),
    viewPaymentCellAction,
    ...(isFinancingPayment ? [] : [editPaymentAction, cancelPaymentAction]),
  ]);

  return (
    <>
      {CancelPaymentDialog}

      <QBOActionsCell eventProperties={eventProperties} actions={actions} />
    </>
  );
};

export default QBOScheduledActionCell;
