import React from 'react';
import VerifyMicroDeposits from 'src/app/components/micro-deposits/VerifyMicroDeposits';
import { useFundingSourceMicroDepositState } from 'src/app/pages/settings/hooks/useFundingSourceMicroDeposits';
import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import { useQueryState } from 'src/app/utils/hooks';
import useDashboardListParams from './useDashboardListParams';
import { useLoadDashboardList } from './useLoadDashboardList';

type Response = [React.ReactNode | null | false, (show?: boolean) => void];

const microDepositEventPage = 'dashboard-verify-manual-account';

const useVerifyMicroDepositAction = (fundingSourceId?: string, paymentId?: string): Response => {
  const microDepositProps = {
    key: Number(fundingSourceId),
    fundingSourceId: Number(fundingSourceId),
  };
  const { loadDashboardList } = useLoadDashboardList();
  const [listParams] = useDashboardListParams();
  const [mdFundingSourceId, setFundingSourceId] = useQueryState<string | undefined>(
    'mdFundingSourceId'
  );
  const [, setPaymentId] = useQueryState<string | undefined>('paymentId');

  const showVerifyMicroDepositModal = !!parseInt(mdFundingSourceId || '', 10);

  const setModalVisibility = (show = true) => {
    const fundingSourceIdValue = show ? fundingSourceId : undefined;
    const paymentIdValue = show ? paymentId : undefined;

    setFundingSourceId(fundingSourceIdValue);
    setPaymentId(paymentIdValue);
  };

  const [state, actions]: any = useFundingSourceMicroDepositState(
    microDepositEventPage,
    microDepositProps
  );

  const onVerifyFinished = (isSuccess) => {
    setModalVisibility(false);

    if (isSuccess) {
      loadDashboardList(listParams);
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: 'paymentDashboard.microDeposits.toastMessage',
      });
    }
  };

  const VerifyMicroDepositDialog = showVerifyMicroDepositModal && (
    <VerifyMicroDeposits
      {...state}
      {...actions}
      key={fundingSourceId}
      fundingSourceId={fundingSourceId}
      onVerifyFinished={onVerifyFinished}
      eventPage={microDepositEventPage}
      dialogSuccessTitle="paymentDashboard.microDeposits.verifyDialogSuccessTitle"
      dialogSuccessSubtitle="paymentDashboard.microDeposits.verifyDialogSuccessSubTitle"
    />
  );

  return [VerifyMicroDepositDialog, setModalVisibility];
};

export default useVerifyMicroDepositAction;
