import { createSelector, createSlice } from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';

export const VIRTUAL_CARD_DETAILS_SLICE = `${VERSION_2}virtualCardDetails`;

export const selectState = (state) => state[VIRTUAL_CARD_DETAILS_SLICE] as VirtualCardDetailsSlice;

export interface VirtualCardDetailsSlice {
  isHowToUseCardModalOpen: boolean;
}

export const getVirtualCardDetailsInitialState = (): VirtualCardDetailsSlice => ({
  isHowToUseCardModalOpen: false,
});

export const virtualCardDetailsSlice = createSlice({
  name: VIRTUAL_CARD_DETAILS_SLICE,
  initialState: getVirtualCardDetailsInitialState(),

  reducers: {
    setHowToUseCardModalAsOpen: (state) => {
      state.isHowToUseCardModalOpen = true;
    },
    setHowToUseCardModalAsClosed: (state) => {
      state.isHowToUseCardModalOpen = false;
    },
  },
});

const selectIsHowToUseCardModalOpen = createSelector(
  selectState,
  (state) => state.isHowToUseCardModalOpen
);

export const virtualCardDetailsActions = {
  ...virtualCardDetailsSlice.actions,
};

export const virtualCardDetailsSelectors = {
  selectIsHowToUseCardModalOpen,
};
