import { useSelector } from 'react-redux';
import billsStore from 'src/app/modules/bills/bills-store';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { ActionEventPropertiesType, StatusInDashboardType } from 'src/app/pages/qb-dashboard/types';
import { BillType, PaymentType } from 'src/app/utils/types';

type Params = {
  billId: string;
  paymentId?: string;
  status: StatusInDashboardType;
};

type Response = {
  eventProperties: ActionEventPropertiesType;
};

export const useGetActionCellEventProperties = ({
  billId,
  paymentId,
  status,
}: Params): Response => {
  const bill: BillType = useSelector(billsStore.selectors.byId(billId));
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(paymentId));
  const partialAmount = useSelector(
    qbDashboardListItemsStore.selectors.partialPaymentsAmounts.byId(billId)
  );

  let isPartial;

  if (payment) {
    // for failed payment on unpaid tab and payments on scheduled/paid tabs
    isPartial = (payment.amount as number) < bill?.totalAmount;
  } else {
    // for unpaid/partially paid bills on unpaid tab
    isPartial = ((partialAmount || bill?.balance) as number) < bill?.totalAmount;
  }

  return {
    eventProperties: {
      billId,
      paymentId,
      status,
      isPartial,
    },
  };
};
