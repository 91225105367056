import styled, { css } from 'styled-components';

export const TableContainer = styled.table`
  border-radius: 0.4rem;
  box-shadow: ${({ theme }) => `0px 2px 10px ${theme.colors.ds.gray['500']}`};
  border-collapse: collapse;
  background: ${({ theme }) => theme.colors.ds.white};
`;

export const HeaderRow = styled.tr.attrs({ className: 'header-row', height: 36 })`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.ds.gray['600']}`};
`;

export const HeaderCell = styled.div.attrs({ className: 'header-cell' })<{ isSortable?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : '')};
  padding: 0.5rem 1.6rem;

  ${({ theme }) => theme.textStyles.ds.body3};
`;

export const DataRow = styled.tr.attrs({ height: 36 })<{
  isSubRow?: boolean;
}>`
  ${({ theme }) => theme.textStyles.ds.body3}
  ${({ isSubRow, theme }) => (isSubRow
    ? css`
          background: ${theme.colors.ds.gray['700']};

          :hover {
            background-color: ${theme.colors.ds.gray['600']};
          }
        `
    : css`
          background: ${theme.colors.ds.white};
          border-bottom: 1px solid ${theme.colors.ds.gray['600']};
          :hover {
            background-color: ${theme.colors.ds.gray['800']};
          }
        `)}
  &:last-child {
    border-bottom: none;
  }
`;

export const DataCell = styled.td.attrs({ className: 'cell' })<{
  size: number;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  padding: 1.2rem 1.6rem;

  &:first-of-type {
    padding: 0;
  }

  ${({ theme }) => theme.textStyles.ds.body};
`;

export const ArrowSortingImage = styled.div.attrs({ className: 'arrow-sorting-image' })<{
  rotate?: number;
}>`
  margin-left: 1rem;
  ${({ rotate }) => css`
    transform: rotate(${rotate}deg);
  `}
`;

export const ExpandRowImage = styled.img.attrs({ className: 'expand-row-image' })<{
  rotate?: number;
}>`
  margin-left: 1rem;
  margin-top: 0.2rem;
  ${({ rotate }) => css`
    transform: rotate(${rotate}deg);
  `};
`;
