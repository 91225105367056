import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deliveryMethodsStore, {
  getDeliveryMethodActions,
} from 'src/app/modules/delivery-methods/delivery-methods-store';
import { generatePath, useHistory } from 'react-router-dom';
import locations from 'src/app/pages/vendor/shift-vendor-to-ach/locations';
import { useForm } from 'src/app/ui/form';
import { CONSTS, NOTIFICATION_VARIANT, PAYMENT_STATUS } from 'src/app/utils/consts';
import { useApi } from 'src/app/hoc/useApi';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from 'src/app/pages/vendor/vendor-payment-tracking/locations';
import deliveryMethodsApi from 'src/app/services/api/deliveryMethods';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import { getIsVendorPaymentDetailsFlow } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import { pushNotification } from 'src/app/services/notifications';
import {
  EXPEDITE_OPTIONS,
  TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH,
} from 'src/app/pages/vendor/vendor-payment-tracking/consts';
import { PaymentType } from 'src/app/utils/types';
import { BANK_ACCOUNT_CHECKING_TYPE } from 'src/app/version-2/model/constants';

export type ReturnType = [(params: any) => Promise<any>, Record<string, any>, boolean, boolean];

export function useShiftVendorToACHState(vendorId, orgId, token): ReturnType {
  const history = useHistory();
  const { paymentId } = getJWTPayload(token);
  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));
  const paymentActions = useStoreActions(paymentStore);
  const [formValues, setFormValues] = useState({
    id: null,
    routingNumber: '',
    accountNumber: '',
  });
  const [getDeliveryMethod] = useApi<[Record<string, any>], Record<string, any>>(
    deliveryMethodsApi.getDeliveryMethodACHWithToken
  );

  const deliveryMethodActions = getDeliveryMethodActions(useDispatch());
  const { invalidTokenData, loading: isLoading } = useSelector(
    deliveryMethodsStore.selectors.validation
  ) as { invalidTokenData: any; loading: boolean };

  const editMode = !!formValues.id;

  const model = useMemo(
    () => ({
      deliveryType: CONSTS.DELIVERY_TYPE.ACH,
      bankAccount: {
        accountType: BANK_ACCOUNT_CHECKING_TYPE,
        routingNumber: formValues.routingNumber,
        accountNumber: formValues.accountNumber,
      },
    }),
    [formValues.routingNumber, formValues.accountNumber]
  );

  const submitAction = async (values) => {
    if (editMode) {
      return updateDeliveryMethod(values);
    }

    return createDeliveryMethod(values);
  };

  const goToSuccessPage = () => {
    if (getIsVendorPaymentDetailsFlow(token)) {
      history.push(
        generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { id: vendorId, token })
      );

      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.expedite.${EXPEDITE_OPTIONS.TO_ACH}`,
      });
    } else {
      const isCurrentPaymentToAch = paymentId && payment?.status === PAYMENT_STATUS.SCHEDULED;
      const path = isCurrentPaymentToAch ? locations.shiftedCurrentPayment : locations.success;

      history.push(generatePath(path, { id: vendorId, token }));
      paymentActions.fetchEmailToVendorDetails({ token });
    }
  };

  const updateDeliveryMethod = async (values) => {
    await deliveryMethodActions.updateDeliveryMethodWithToken({
      token,
      deliveryMethod: { ...values, id: formValues.id },
      orgId,
      vendorId,
    });

    goToSuccessPage();
  };

  const createDeliveryMethod = async (values) => {
    await deliveryMethodActions.replaceDeliveryMethodWithToken({
      token,
      deliveryMethod: { ...values },
      orgId,
      vendorId,
    });

    goToSuccessPage();
  };

  const [deliveryMethod, { submit }, validationErrors] = useForm(model, {
    submit: submitAction,
  });

  const initialState = useCallback(async () => {
    try {
      const response = await getDeliveryMethod({ token });
      const { deliveryMethod } = response;

      if (deliveryMethod) {
        const { routingNumber, accountNumber } = deliveryMethod.bankAccount;

        setFormValues({
          id: deliveryMethod.id,
          routingNumber,
          accountNumber,
        });
      }
    } catch (e) {
      history.push(generatePath(locations.invalidToken, { id: vendorId, token }));
    }
  }, [token, getDeliveryMethod, history, vendorId]);

  useEffect(() => {
    initialState();
  }, [initialState]);

  useEffect(() => {
    if (invalidTokenData && invalidTokenData?.code) {
      if (invalidTokenData?.code === 'DLM07') {
        validationErrors['bankAccount.accountNumber'] = `server.${invalidTokenData?.code}`;
      } else {
        history.push(generatePath(locations.invalidToken, { id: vendorId, token }));
      }
    }
  }, [invalidTokenData, history, token, validationErrors, vendorId]);

  return [submit, deliveryMethod, isLoading, editMode];
}
