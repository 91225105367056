import React, { memo } from 'react';
import { Box } from '@melio/billpay-design-system';
import { DeliveryDate } from 'src/app/utils/dates';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';

import moment from 'moment-business-days';
import styled from 'styled-components';
import MITag from 'src/app/components/common/MITag';
import { TAG_VARIANT } from 'src/app/utils/consts';

type PropsType = {
  deliveryEta: string;
  maxDeliveryEta: string;
  scheduledDate: string;
  translationPath: string;
};

const VendorCheckETA = memo(
  ({ deliveryEta, maxDeliveryEta, scheduledDate, translationPath }: PropsType) => (
    <EtaContainer>
      <Box>
        <MIFormattedText
          label={`${translationPath}.eta.original`}
          values={{
            paymentETA: <DeliveryDate date={deliveryEta} maxDate={maxDeliveryEta} />,
          }}
        />
      </Box>
      <NewEtaContainer>
        <MIFormattedText
          label={`${translationPath}.eta.new`}
          values={{
            paymentETA: <MIFormattedDate date={moment(scheduledDate).businessAdd(3)} />,
          }}
        />
        <FastTag label="bills.pay.date.deliveryOptions.price.fastTag" variant={TAG_VARIANT.BRAND} />
      </NewEtaContainer>
    </EtaContainer>
  )
);

const EtaContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  & span {
    font-size: 1.4rem;
  }
  & b {
    font-weight: 600;
  }
`;

const NewEtaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;

const FastTag = styled(MITag)`
  margin-left: 0.6rem;
  vertical-align: middle;
  font-size: 1.2rem !important;
  padding: 0 0.4rem;
  position: relative;
`;

export default VendorCheckETA;
