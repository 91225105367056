interface GetArrayOfVendorIdToPaymentIdMappingParams {
  vendorId: number;
  paymentId: number;
}

export const getArrayOfVendorIdToPaymentIdMapping = (
  objsArray: GetArrayOfVendorIdToPaymentIdMappingParams[]
) =>
  objsArray.reduce((acc, obj) => {
    const { vendorId, paymentId } = obj;

    return { ...acc, [vendorId]: paymentId };
  }, {});
