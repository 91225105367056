import qbrIosSite from 'src/app/sites/qbrIosSite';
import merge from 'lodash/merge';
import { RegistrationOriginEnum, PaymentCreateFlowOriginEnum } from 'src/app/version-2/model/enums';

const qbrAndroidSite = merge({}, qbrIosSite, {
  name: 'qbr',
  createOrigin: {
    pay: {
      payment: PaymentCreateFlowOriginEnum.QBR_ANDROID,
    },
  },
  registrationOrigin: RegistrationOriginEnum.QUICKBOOKS_ROCKETMAN_ANDROID,
});

export default qbrAndroidSite;
