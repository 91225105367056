import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from '@melio/billpay-design-system';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { getLayoutHeaderLabel } from 'src/app/pages/bill/utils';
import {
  getPayment,
  getBill,
  getCompanyName,
  getIsRecurring,
} from 'src/app/redux/payBillWizard/selectors';
import { MIFormattedCurrency } from 'src/app/utils/formatting';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
  isProcessing: boolean;
};

export const PayBillMemoPageLayout = ({
  onNext,
  onPrev,
  onExit,
  isProcessing,
  children,
}: PropsWithChildren<Props>) => {
  const payment = useSelector(getPayment);
  const bill = useSelector(getBill);
  const companyName = useSelector(getCompanyName);
  const isRecurring = useSelector(getIsRecurring);

  const amount = (payment.amount ?? bill.balance)?.toString() as string;
  const relativeStep = 4 / 5;
  const headerLabelValues = {
    amount: <MIFormattedCurrency value={amount} />,
    companyName,
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  return (
    <QBOLayoutPage
      headerLabel={getLayoutHeaderLabel({
        isRecurring,
        deliverFailureData: payment?.deliverFailureData,
      })}
      headerLabelValues={headerLabelValues}
      title="bills.pay.memo.title"
      goExit={onExit}
      onPrev={onPrev}
      onNext={onNext}
      relativeStep={relativeStep}
      isLoading={isProcessing}
    >
      {children}
    </QBOLayoutPage>
  );
};
