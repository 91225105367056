import React from 'react';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { RESOURCE_BASE } from '../config';

export const BankMissingDetails = ({ values, onChange, validationErrors, flowType }) => (
  <>
    <MITextInput
      id="routingNumber"
      label={`${RESOURCE_BASE[flowType]}.stepMissingACHDetails.inputRoutingNumberLabel`}
      onChange={onChange}
      value={values.routingNumber.value}
      type="number"
      errorMessage={validationErrors.routingNumber}
      required
      privateData
      autocomplete="off"
    />
    <MITextInput
      id="accountNumber"
      value={values.accountNumber.value}
      label={`${RESOURCE_BASE[flowType]}.stepMissingACHDetails.inputAccountNumberLabel`}
      onChange={onChange}
      type="number"
      errorMessage={validationErrors.accountNumber}
      required
      privateData
      autocomplete="off"
    />
  </>
);
