import { FundingSource } from 'src/app/version-2/model/dtos';
import { isEligibleToReceiveInternational } from 'src/app/version-2/utils/international.utils';

interface isValidPaymentMethodParams {
  paymentMethod: FundingSource;
  isInternational: boolean;
  isVirtualCard: boolean;
}
export const isValidPaymentMethod = ({
  paymentMethod,
  isInternational,
  isVirtualCard,
}: isValidPaymentMethodParams) => {
  if (isInternational && !isEligibleToReceiveInternational(paymentMethod)) return true;

  // block all cards as well in case of virtual card (server returns an empty DM)
  if (isVirtualCard && !isEligibleToReceiveInternational(paymentMethod)) return true;

  return false;
};
