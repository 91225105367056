import React from 'react';
import MIPasswordInput from 'src/app/components/common/MIPasswordInput';
import { InputModeType } from 'src/app/components/common/MITextInput';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';
import { ModelViewField } from './use-form';

type Props = {
  label: string;
  notices?: [string];
  model: ModelViewField<string>;
  id?: string;
  autoFocus?: boolean;
  required?: boolean;
  value?: string | number;
  onChange?: (event: { value: string }) => any;
  shouldShowValue?: boolean;
  pattern?: string;
  inputMode?: InputModeType;
  disabled?: boolean;
};

export function WizardPasswordInputField(props: Props) {
  const { model, ...rest } = props;
  const value = props.value === undefined ? model.value : props.value;
  const onChange = props.onChange || model.onChange;
  const id = props.id || model.id;

  return (
    <MIPasswordInput
      {...rest}
      value={value}
      onChange={onChange}
      id={id}
      size={TextInputSizeEnum.WIZARD}
      errorMessage={model.error}
    />
  );
}
