import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { BankAccount, DeliveryMethod } from 'src/app/version-2/model/dtos';
import { AchDeliveryMethodDetailsFlowState } from '../model/objects/achDeliveryMethodDetailsFlowState';

import { History } from 'history';

interface AchDeliveryMethodDetailsSlice {
  bankAccount?: BankAccount;
  vendorName?: string;
  showConfirmAccountNumberField: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  error: {
    code?: string;
  };
  flowState: Partial<AchDeliveryMethodDetailsFlowState>;
}

export interface DeliveryMethodCreatedActionPayload {
  deliveryMethod: DeliveryMethod;
}

export interface DeliveryMethodUpdatedActionPayload {
  updatedDeliveryMethodId: string;
  deliveryMethod: DeliveryMethod;
}

export const ACH_DELIVERY_METHOD_DETAILS_SLICE = `${VERSION_2}achDeliveryMethodDetails`;

const selectState = (state) =>
  state[ACH_DELIVERY_METHOD_DETAILS_SLICE] as AchDeliveryMethodDetailsSlice;

const getAchDeliveryMethodDetailsSliceInitialState = (): AchDeliveryMethodDetailsSlice => ({
  vendorName: undefined,
  bankAccount: undefined,
  showConfirmAccountNumberField: true,
  isLoading: true, // set it to true to avoid flashing of the page before we start fetching the data
  isSubmitting: false,
  error: {
    code: undefined,
  },
  flowState: {
    redirectUrl: undefined,
    exitUrl: undefined,
    origin: undefined,
    batchList: undefined,
    selectedPaymentIntent: undefined,
  },
});

export const achDeliveryMethodDetailsSlice = createSlice({
  name: ACH_DELIVERY_METHOD_DETAILS_SLICE,
  initialState: getAchDeliveryMethodDetailsSliceInitialState(),
  reducers: {
    setBankAccount: (state, action: PayloadAction<BankAccount>) => {
      state.bankAccount = action.payload;
    },
    setShowConfirmAccountNumberField: (state, action: PayloadAction<boolean>) => {
      state.showConfirmAccountNumberField = action.payload;
    },
    setVendorName: (state, action: PayloadAction<string>) => {
      state.vendorName = action.payload;
    },
    setFlowState: (state, action: PayloadAction<AchDeliveryMethodDetailsFlowState>) => {
      state.flowState = action.payload;
    },
    resetState: () => getAchDeliveryMethodDetailsSliceInitialState(),
    setError: (
      state,
      action: PayloadAction<{
        error?: {
          code: string;
        };
      }>
    ) => {
      state.error.code = action.payload?.error?.code;
    },
    resetError: (state) => {
      state.error.code = undefined;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
  },
});

export const achDeliveryMethodDetailsActions = {
  ...achDeliveryMethodDetailsSlice.actions,
  submitAchDeliveryMethod: createAction<{
    bankAccount: Required<Pick<BankAccount, 'accountNumber' | 'routingNumber'>>;
    deliveryMethodId?: string;
    vendorId: string;
    history: History;
  }>(`${ACH_DELIVERY_METHOD_DETAILS_SLICE}/submitAchDeliveryMethod`),
  loadAchDeliveryMethodDetailsPageData: createAction<{
    deliveryMethodId?: string;
    vendorId: string;
  }>(`${ACH_DELIVERY_METHOD_DETAILS_SLICE}/loadAchDeliveryMethodDetailsPageData`),
  deliveryMethodCreated: createAction<DeliveryMethodCreatedActionPayload>(
    `${ACH_DELIVERY_METHOD_DETAILS_SLICE}/deliveryMethodCreated`
  ),
  deliveryMethodUpdated: createAction<DeliveryMethodUpdatedActionPayload>(
    `${ACH_DELIVERY_METHOD_DETAILS_SLICE}/deliveryMethodUpdated`
  ),
};

const selectVendorName = createSelector(selectState, (state) => state.vendorName);
const selectBankAccount = createSelector(selectState, (state) => state.bankAccount);
const selectInitialFormValues = createSelector(selectState, (state) => ({
  routingNumber: state.bankAccount?.routingNumber || '',
  accountNumber: state.bankAccount?.accountNumber || '',
  confirmAccountNumber: state.bankAccount?.accountNumber || '',
}));
const selectShowConfirmAccountNumberField = createSelector(
  selectState,
  (state) => state.showConfirmAccountNumberField
);
const selectErrorCode = createSelector(selectState, (state) => state.error?.code);
const selectIsLoading = createSelector(selectState, (state) => state.isLoading);
const selectIsSubmitting = createSelector(selectState, (state) => state.isSubmitting);
const selectFlowState = createSelector(selectState, (state) => state.flowState);
const selectFlowStateOrigin = createSelector(selectState, (state) => state.flowState.origin);
const selectFlowStateRedirectUrl = createSelector(
  selectState,
  (state) => state.flowState.redirectUrl
);
const selectFlowAnalyticsParams = createSelector(
  selectState,
  (state) => state.flowState.analyticsParams
);

export const achDeliveryMethodDetailsSelectors = {
  selectVendorName,
  selectBankAccount,
  selectShowConfirmAccountNumberField,
  selectInitialFormValues,
  selectErrorCode,
  selectIsLoading,
  selectIsSubmitting,
  selectFlowState,
  selectFlowStateOrigin,
  selectFlowStateRedirectUrl,
  selectFlowAnalyticsParams,
};
