import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from '@melio/billpay-design-system';
import intercomService from 'src/app/services/intercom';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { useBreak } from 'src/app/hoc';
import { analytics, MIFormattedText } from 'src/app/version-2/externals';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import {
  DrawerContentContainer,
  SupportTeamLabel,
  StartChatButton,
  ChatIcon,
} from './QBODrawerSubtitle.styles';

const eventPage = 'qbo';

export const QBODrawerSubtitle = () => {
  const dispatch = useDispatch();
  const onSupportEmailClick = () => {
    analytics.track(eventPage, 'support-email');
  };
  const onHelpCenterClick = () => {
    analytics.track(eventPage, 'help-center');
  };
  const openSupportChat = () => {
    analytics.track(eventPage, 'support-open-start-chat');
    dispatch(applicationActions.setFaqPanel({ isOpen: false }));
    intercomService.show();
  };

  const { isMobile, isPhablet } = useBreak();
  const showStartChatButton = isMobile || isPhablet;
  const site = useSiteContext();
  const label = showStartChatButton ? 'drawer.contactLabelMobile' : 'drawer.contactLabel';

  return (
    <DrawerContentContainer>
      <SupportTeamLabel>
        <MIFormattedText
          label={label}
          values={{
            supportLink: (...chunks) => (
              <Link href={`mailto:${site.config.support.email}`} onClick={onSupportEmailClick}>
                {React.Children.toArray(chunks)}
              </Link>
            ),
            chatLink: (...chunks) => (
              <Link onClick={openSupportChat}>{React.Children.toArray(chunks)}</Link>
            ),
            helpCenterLink: (...chunks) => (
              <Link href={site.config.helpCenter.link} onClick={onHelpCenterClick} isExternal>
                {React.Children.toArray(chunks)}
              </Link>
            ),
          }}
        />
      </SupportTeamLabel>
      {showStartChatButton ? (
        <StartChatButton
          size="md"
          width="full"
          leftIcon={<ChatIcon className="icon-support-icon" />}
          onClick={openSupportChat}
        >
          <MIFormattedText label="launcher.startChat" />
        </StartChatButton>
      ) : null}
    </DrawerContentContainer>
  );
};
