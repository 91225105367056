import React, { useEffect } from 'react';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import analytics from 'src/app/services/analytics';
import { useVendorMemoStepForm } from 'src/app/modules/vendors/vendor-memo-step/hooks/useVendorMemoStepForm';
import { useShouldAskForVendorEmailOnMemoStep } from 'src/app/modules/vendors/vendor-memo-step/hooks/useShouldAskForVendorEmailOnMemoStep';
import { AreaLoader } from '@melio/billpay-design-system';
import { MemoStepFormFields } from 'src/app/modules/vendors/vendor-memo-step/components/MemoStepFormFields';
import { JustPayNoteToVendorLayout } from './components/JustPayNoteToVendorLayout';
import { useCheckOwnedVendorExists } from '../Success/hooks/useCheckOwnedVendorExists';

const SCREEN_NAME = 'memo';
const EVENT_PAGE = 'pay-bill';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

export const JustPayNoteToVendor = ({ onNext, onExit, onPrev }: Props) => {
  const {
    payment: {
      vendor: { id: vendorId },
      noteToVendor,
      trackingBillId,
      deliveryMethodType,
    },
    vendor: { contactEmail: vendorContactEmail },
    paymentStoreActions,
  } = useJustPayStore();

  const analyticsProps = {
    vendorId,
  };

  const [isOwnedVendorExists, isGettingOwnedVendorData] = useCheckOwnedVendorExists({
    vendorId,
    vendorEmail: vendorContactEmail,
    eventPage: EVENT_PAGE,
  });

  const shouldAskForEmail = useShouldAskForVendorEmailOnMemoStep({
    isOwnedVendorExists,
    vendorContactEmail,
    deliveryType: deliveryMethodType,
  });

  const onSubmitSuccess = () => {
    analytics.trackAction('set-memo-continue', {
      memoToVendor: formMV.memo.value,
      trackingBillId,
    });
    onNext();
  };

  const { formMV, submit, loading } = useVendorMemoStepForm({
    onSubmitSuccess,
    shouldAskForEmail,
    vendorId,
    initialValues: {
      contactEmail: vendorContactEmail,
      memo: noteToVendor,
    },
    onChange: (onlyChangedValues) => {
      onlyChangedValues.memo !== undefined &&
        paymentStoreActions.justPay.justPayWizard.update({
          noteToVendor: onlyChangedValues.memo,
        });
    },
    onEmailUpdateSuccess: (newVendorEmail) => {
      analytics.trackAction('vendor-email-save-success', {
        screenName: SCREEN_NAME,
        vendorEmail: newVendorEmail,
        ...analyticsProps,
      });
    },
    onValidationErrors: (validationErrors) => {
      analytics.trackAction('form-validation-error', {
        validationErrors,
      });
    },
  });

  useEffect(() => {
    shouldAskForEmail &&
      analytics.trackAction('exposed-to-add-vendor-email-line', {
        screenName: SCREEN_NAME,
        ...analyticsProps,
      });
  }, [shouldAskForEmail]);

  if (isGettingOwnedVendorData) {
    return <AreaLoader />;
  }

  return (
    <JustPayNoteToVendorLayout
      onNext={submit}
      onExit={onExit}
      onPrev={onPrev}
      isProcessing={loading}
    >
      <MemoStepFormFields
        formMV={formMV}
        showEmailField={shouldAskForEmail}
        memoFieldProps={{
          label: 'justPay.noteToVendor.label',
          placeholder: 'justPay.noteToVendor.placeholder',
          notices: ['justPay.noteToVendor.hint'],
        }}
      />
    </JustPayNoteToVendorLayout>
  );
};
