import { ExperimentVariantsEnum } from 'src/app/version-2/model/enums';

export const EXPERIMENT_NAME = 'landing-page-and-funding-source';

export const EXPERIMENT_VARIANTS = {
  CONTROL: ExperimentVariantsEnum.CONTROL,
  CARD: ExperimentVariantsEnum.CARD,
  TABLE: ExperimentVariantsEnum.TABLE,
};

export const EXPERIMENT_TYPE = {
  TYPE: 'type',
  SELECT: 'select',
};
