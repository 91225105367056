import React from 'react';
import styled from 'styled-components';
import { DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import intercomService from 'src/app/services/intercom';
import QBOMIDialog, { Close } from 'src/app/components/common/QBOMIDialog';

export const SyncBillErrorDialog = ({ dismiss }: { dismiss: () => void }) => (
  <StyledQBODialog
    type={DIALOG_TYPE.CONFIRM}
    variant={DIALOG_VARIANTS.SUCCESS}
    title="bills.pay.fundingSource.syncErrorDialog.title"
    subtitle="bills.pay.fundingSource.syncErrorDialog.subtitle"
    okButtonText="bills.pay.fundingSource.syncErrorDialog.okButton"
    cancelButtonText="bills.pay.fundingSource.syncErrorDialog.cancelButton"
    onOkAction={() => {
      dismiss();
      intercomService.show();
    }}
    onCancelAction={dismiss}
    showConfirmationText={false}
  />
);

const StyledQBODialog = styled(QBOMIDialog)`
  ${Close} {
    display: none;
  }
`;
