import React from 'react';
import { Route, Switch } from 'react-router-dom';

import locations from 'src/app/utils/locations';
import QBOVendorsListPageContainer from '../vendor/QBOVendorsListPageContainer';
import QBOViewVendor from '../vendor/components/QBOViewVendor';

const VendorSettingsPageContainer = () => (
  <Switch>
    <Route
      path={locations.Settings.vendorSingle.url({ id: ':id' })}
      exact
      component={QBOViewVendor}
    />
    <Route>
      <QBOVendorsListPageContainer />
    </Route>
  </Switch>
);

export default VendorSettingsPageContainer;
