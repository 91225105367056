import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import { PaymentIcon } from 'src/app/components/layout/PaymentActivityElements';

export type ReviewSectionItemProps = {
  icon?: string;
  image?: React.ReactNode;
  label: React.ReactNode;
  value: React.ReactNode;
  notes?: React.ReactNode;
};

const ReviewGridItem = ({ icon, image, label, value, notes }: ReviewSectionItemProps) => (
  <Flex>
    {!!image && <Box __css={imageContainerStyle}>{image}</Box>}
    {!image && !!icon && (
      <PaymentIcon>
        <i className={icon} />
      </PaymentIcon>
    )}
    <Flex __css={contentStyle}>
      <Box __css={labelStyle}>{label}</Box>
      <Box __css={valueStyle}>{value}</Box>
      {!!notes && <Box __css={notesStyle}>{notes}</Box>}
    </Flex>
  </Flex>
);

const imageContainerStyle = {
  w: '3.2rem',
  h: '3.2rem',
  mr: '2rem',
};

const contentStyle = {
  ml: '-0.8rem',
  direction: 'column',
  textStyle: 'body3',
  fontWeight: '400',
};

const labelStyle = {
  color: 'gray.400',
};

const valueStyle = {
  color: 'black',
  fontSize: '1.6rem',
};

const notesStyle = {
  color: 'gray.300',
  textStyle: 'body4',
  mt: '0.4rem',
};

export { ReviewGridItem };
