import { fetchRequest } from 'src/app/version-2/externals';
import { GetPaymentUpsellDetailsRequest } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/requests/GetPaymentUpsellDetailsRequest';
import { GetPaymentUpsellDetailsResponse } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/responses/GetPaymentUpsellDetailsResponse';

export const getPaymentUpsellDetailsApi = {
  getPaymentUpsellDetails({ token }: GetPaymentUpsellDetailsRequest) {
    const url = `/payment/details/upgrade/${token}`;

    return fetchRequest<GetPaymentUpsellDetailsResponse>(url);
  },
};
