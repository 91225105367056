import { persistReducer, createTransform } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import unionWith from 'lodash/unionWith';
import { CLEAR_STATE } from 'src/app/redux/user/actionTypes';
import {
  achDeliveryMethodDetailsActions,
  DeliveryMethodCreatedActionPayload,
  DeliveryMethodUpdatedActionPayload,
} from 'src/app/version-2/pages/ach-delivery-method-details/modules/achDeliveryMethodDetails.slice';
import type { PayBillWizardState } from './types';
import {
  BEGIN_REGULAR_PAY_BILL_FLOW,
  BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
  BEGIN_RECURRING_PAY_BILL_FLOW,
  BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS,
  BEGIN_RECURRING_PAY_BILL_FLOW_FAILED,
  SET_PURPOSE_OF_PAYMENT,
  SELECT_FUNDING_SOURCE,
  CLEAR_SELECTED_FUNDING_SOURCE,
  SELECT_PAYMENT_DATES,
  UPDATE_PAYMENT_MEMO,
  SELECT_DELIVERY_METHOD,
  ADD_NEW_DELIVERY_METHOD,
  CREATE_RECURRING_BILL_SUCCESS,
  CREATE_RECURRING_BILL_ERROR,
  UPDATE_BILL,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  END_PAY_BILL_FLOW,
  INIT_PAY_BILL_FLOW,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_ERROR,
  UPDATE_PAYMENT,
  FETCH_BILL,
  FETCH_BILL_SUCCESS,
  FETCH_BILL_FAILED,
  RESET_ERROR,
  SET_FEE,
  CREATE_RECURRING_BILL,
  RETRY_FAILED_PAYMENT_SUCCESS,
  RETRY_FAILED_PAYMENT_ERROR,
  RETRY_FAILED_PAYMENT,
  SET_BILL_ID,
  SET_DELETED_FUNDING_SOURCE_ID,
  CLEAR_DELETED_FUNDING_SOURCE_ID,
  SET_PAYMENT_AMOUNT,
  SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA_FINISH,
  SET_PAYBILL_WIZARD_FLOW,
  CLEAR_PAYBILL_WIZARD_FLOW,
  CLEAR_PAYBILL_WIZARD_BILL,
  SET_FEE_FUNDING_SOURCE_FLOW,
  SET_FEE_FUNDING_BANK_TYPE,
  SHOW_US_HOLIDAY_NOTIFICATION,
  HIDE_US_HOLIDAY_NOTIFICATION,
  CANCEL_AND_RETRY_PAYMENT,
  CANCEL_AND_RETRY_PAYMENT_SUCCESS,
  CANCEL_AND_RETRY_PAYMENT_ERROR,
  SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW,
  SET_DELIVERY_OPTIONS,
  FETCH_DELIVERY_OPTIONS,
  FETCH_DELIVERY_OPTIONS_SUCCESS,
  FETCH_DELIVERY_OPTIONS_FAILURE,
  FETCH_INITIAL_DELIVERY_OPTIONS,
  FETCH_INITIAL_DELIVERY_OPTIONS_SUCCESS,
  FETCH_INITIAL_DELIVERY_OPTIONS_FAILURE,
  SET_SYNC_PAYMENT_ERROR_CODE,
  SET_INVOICE_FILE,
} from './actionTypes';
import { BillRecord } from '../../pages/bill/records';
import { PaymentRecord } from '../../pages/payment/records';

export const initialState: PayBillWizardState = {
  flow: undefined,
  feeFundingSourceFlow: undefined,
  isLoading: false,
  bill: BillRecord(),
  deletedFundingSources: [],
  payment: PaymentRecord(),
  isRecurring: false,
  isCancelAndRetryPaymentFlow: false,
  recurringBill: {},
  firstBillIdWithRecurringBill: null,
  recurringBillId: null,
  errorCode: null,
  syncPaymentErrorCode: null,
  redirectUrl: null,
  exitUrl: null,
  fee: undefined,
  invoiceFilesByBillId: {},
  notifications: {
    showUSHolidayUSPCLatency: true,
  },
  deliveryOptionsData: {
    loading: false,
    errorCode: undefined,
    data: undefined,
  },
  deliveryOptions: [],
};

const recordsTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState, key) => {
    if (key === 'bill') {
      return BillRecord(outboundState as any);
    }

    if (key === 'payment') {
      return PaymentRecord(outboundState as any);
    }

    return outboundState;
  }
);

const persistConfig = {
  key: 'payBillWizard',
  storage: storageSession,
  transforms: [recordsTransform],
};

const updateDeliveryMethodsInBill = (bill: PayBillWizardState['bill'], deliveryMethod) =>
  bill.merge({
    vendor: {
      ...bill.vendor,
      deliveryMethods: unionWith(
        [deliveryMethod],
        bill.vendor?.deliveryMethods || [],
        (a, b) => a.id === b.id
      ),
    },
  });

const updatePaymentSelectedDeliveryMethod = (
  payment: PayBillWizardState['payment'],
  deliveryMethod
) =>
  payment.merge({
    deliveryMethodId: deliveryMethod?.id,
    deliveryMethod: deliveryMethod,
  });

const deliveryMethodCreatedHandler = (
  state: PayBillWizardState,
  payload: DeliveryMethodCreatedActionPayload
) => {
  if (String(payload.deliveryMethod.vendorId) === String(state.bill.vendorId)) {
    return {
      ...state,
      payment: updatePaymentSelectedDeliveryMethod(state.payment, payload.deliveryMethod),
      bill: updateDeliveryMethodsInBill(state.bill, payload.deliveryMethod),
    };
  }

  return state;
};

const deliveryMethodUpdatedHandler = (
  state: PayBillWizardState,
  payload: DeliveryMethodUpdatedActionPayload
) => {
  if (payload.updatedDeliveryMethodId === String(state.payment.deliveryMethodId)) {
    return {
      ...state,
      payment: updatePaymentSelectedDeliveryMethod(state.payment, payload.deliveryMethod),
      bill: updateDeliveryMethodsInBill(state.bill, payload.deliveryMethod),
    };
  }

  if (String(payload.deliveryMethod.vendorId) === String(state.bill.vendorId)) {
    return {
      ...state,
      bill: updateDeliveryMethodsInBill(state.bill, payload.deliveryMethod),
    };
  }

  return state;
};

const payBillWizardReducer = (state: PayBillWizardState = initialState, action) => {
  switch (action.type) {
    case BEGIN_REGULAR_PAY_BILL_FLOW:
      return {
        ...state,
        isLoading: true,
        redirectUrl: action.redirectUrl,
        exitUrl: action.exitUrl,
      };
    case BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS:
      return {
        ...state,
        bill: action.bill,
        payment: action.payment,
        isLoading: false,
      };
    case BEGIN_REGULAR_PAY_BILL_FLOW_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA_FINISH:
      return {
        ...state,
        bill: action.bill,
        payment: action.payment,
        redirectUrl: action.redirectUrl,
        exitUrl: action.exitUrl,
      };
    case BEGIN_RECURRING_PAY_BILL_FLOW:
      return {
        ...initialState,
        isLoading: true,
        isRecurring: true,
        exitUrl: action.exitUrl,
      };
    case BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bill: action.bill,
        payment: action.payment,
        recurringBill: action.recurringBill,
      };
    case BEGIN_RECURRING_PAY_BILL_FLOW_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SET_PAYMENT_AMOUNT:
      return {
        ...state,
        payment: state.payment.merge({
          amount: action.amount,
        }),
      };
    case SET_PURPOSE_OF_PAYMENT:
      return {
        ...state,
        payment: state.payment.merge({
          purpose: action.purpose,
        }),
      };
    case SELECT_FUNDING_SOURCE:
      return {
        ...state,
        payment: state.payment.merge({
          fundingSourceId: action.id,
        }),
      };
    case CLEAR_SELECTED_FUNDING_SOURCE:
      return {
        ...state,
        payment: state.payment.remove('fundingSourceId'),
      };
    case SELECT_PAYMENT_DATES:
      return {
        ...state,
        payment: state.payment.merge({
          scheduledDate: action.scheduledDate,
          deliveryEta: action.deliveryEta,
          maxDeliveryEta: action.maxDeliveryEta,
          deliveryPreference: action.deliveryPreference,
        }),
      };
    case ADD_NEW_DELIVERY_METHOD:
      return {
        ...state,
        bill: updateDeliveryMethodsInBill(state.bill, action.deliveryMethod),
      };
    case SELECT_DELIVERY_METHOD:
      return {
        ...state,
        payment: updatePaymentSelectedDeliveryMethod(state.payment, action.deliveryMethod),
      };
    case achDeliveryMethodDetailsActions.deliveryMethodCreated.type:
      return deliveryMethodCreatedHandler(state, action.payload);
    case achDeliveryMethodDetailsActions.deliveryMethodUpdated.type:
      return deliveryMethodUpdatedHandler(state, action.payload);
    case UPDATE_PAYMENT_MEMO:
      return {
        ...state,
        payment: state.payment.merge({ note: action.memo }),
      };
    case UPDATE_BILL:
      return {
        ...state,
        bill: state.bill.merge({ goodsReceived: action.goodsReceived }),
      };
    case FETCH_BILL:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_BILL_SUCCESS:
      return {
        ...state,
        bill: action.bill,
        isLoading: false,
      };
    case FETCH_BILL_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_RECURRING_BILL:
      return {
        ...state,
        isLoading: true,
        errorCode: null,
      };
    case CREATE_RECURRING_BILL_SUCCESS:
      return {
        ...state,
        isLoading: true,
        firstBillIdWithRecurringBill: action.firstBillIdWithRecurringBill,
        payment: action.payment,
        recurringBillId: action.recurringBillId,
      };
    case CREATE_RECURRING_BILL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.errorCode,
      };

    case CREATE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        errorCode: null,
      };

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        isLoading: false,
      };
    case CREATE_PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.errorCode,
      };

    case UPDATE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        errorCode: null,
      };
    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        isLoading: false,
      };
    case UPDATE_PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.errorCode,
      };

    case RETRY_FAILED_PAYMENT:
      return {
        ...state,
        isLoading: true,
        errorCode: null,
      };
    case RETRY_FAILED_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        isLoading: false,
      };
    case RETRY_FAILED_PAYMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.errorCode,
      };

    case INIT_PAY_BILL_FLOW:
      return { ...initialState };
    case END_PAY_BILL_FLOW:
      return { ...initialState };
    case RESET_ERROR:
      return {
        ...state,
        errorCode: null,
      };
    case SET_FEE:
      return {
        ...state,
        fee: action.payload,
      };
    case SET_BILL_ID:
      return {
        ...state,
        payment: state.payment.merge({ billId: action.billId }),
      };
    case CLEAR_DELETED_FUNDING_SOURCE_ID:
      return {
        ...state,
        deletedFundingSources: [],
      };
    case SET_DELETED_FUNDING_SOURCE_ID:
      state.deletedFundingSources.push(action.fundingSourceId);

      return state;
    case CLEAR_STATE:
      return {
        ...initialState,
      };

    case SET_INVOICE_FILE:
      return {
        ...state,
        invoiceFilesByBillId: {
          ...state.invoiceFilesByBillId,
          [action.billId]: action.payload,
        },
      };

    case SET_PAYBILL_WIZARD_FLOW:
      return { ...state, flow: action.payload };

    case CLEAR_PAYBILL_WIZARD_FLOW:
      return { ...state, flow: undefined };

    case CLEAR_PAYBILL_WIZARD_BILL:
      return { ...state, bill: BillRecord(), payment: PaymentRecord() };

    case SET_FEE_FUNDING_SOURCE_FLOW:
      return { ...state, feeFundingSourceFlow: { flow: action.payload } };

    case SET_FEE_FUNDING_BANK_TYPE:
      return { ...state, feeFundingSourceFlow: { bankType: action.payload } };

    case SHOW_US_HOLIDAY_NOTIFICATION: {
      return { ...state, notifications: { showUSHolidayUSPCLatency: true } };
    }

    case HIDE_US_HOLIDAY_NOTIFICATION: {
      return { ...state, notifications: { showUSHolidayUSPCLatency: false } };
    }

    case CANCEL_AND_RETRY_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case SET_SYNC_PAYMENT_ERROR_CODE:
      return { ...state, syncPaymentErrorCode: action.payload };

    case CANCEL_AND_RETRY_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        isLoading: false,
        isCancelAndRetryPaymentFlow: false,
      };

    case CANCEL_AND_RETRY_PAYMENT_ERROR:
      return { ...state, isLoading: false, errorCode: action.errorCode };

    case SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW:
      return {
        ...state,
        isCancelAndRetryPaymentFlow: action.payload,
      };

    case FETCH_DELIVERY_OPTIONS:
      return {
        ...state,
        deliveryOptionsData: {
          ...state.deliveryOptionsData,
          loading: true,
          errorCode: undefined,
        },
      };

    case FETCH_DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        deliveryOptionsData: {
          ...state.deliveryOptionsData,
          loading: false,
          data: {
            ...state.deliveryOptionsData.data,
            ...action.payload,
          },
        },
        deliveryOptions: action.payload.deliveryOptions,
      };

    case FETCH_DELIVERY_OPTIONS_FAILURE:
      return {
        ...state,
        deliveryOptionsData: {
          ...state.deliveryOptionsData,
          loading: false,
          errorCode: action.errorCode,
        },
      };

    case FETCH_INITIAL_DELIVERY_OPTIONS:
      return {
        ...state,
        deliveryOptionsData: {
          ...state.deliveryOptionsData,
          loading: true,
          errorCode: undefined,
        },
      };

    case FETCH_INITIAL_DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        deliveryOptionsData: {
          ...state.deliveryOptionsData,
          loading: false,
          data: action.payload,
        },
        deliveryOptions: action.payload.deliveryOptions,
      };

    case FETCH_INITIAL_DELIVERY_OPTIONS_FAILURE:
      return {
        ...state,
        deliveryOptionsData: {
          ...state.deliveryOptionsData,
          loading: false,
          errorCode: action.errorCode,
        },
      };

    case SET_DELIVERY_OPTIONS:
      return {
        ...state,
        deliveryOptions: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, payBillWizardReducer);
