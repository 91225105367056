import React, { ReactNode } from 'react';
import { SmartIcon } from '@melio/billpay-design-system';
import { NotificationTypeEnum } from './NotificationCard.enum';
import {
  Container,
  Title,
  Subtitle,
  ContentWrapper,
  ActionWrapper,
} from './NotificationCard.styles';
import { NotificationCardIcon } from './NotificationCardIcon';

export interface NotificationCardProps {
  type: NotificationTypeEnum;
  title?: string;
  className?: string;
  subtitle?: string | ReactNode;
  onClose?: () => void;
  isStacked?: boolean;
}

export const NotificationCard = (props: NotificationCardProps) => {
  const { type, title, subtitle, onClose, isStacked = false, className } = props;

  return (
    <Container type={type} isStacked={isStacked} className={className}>
      <NotificationCardIcon type={type} />
      <ContentWrapper>
        {title && <Title type={type}>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </ContentWrapper>
      {onClose && (
        <ActionWrapper onClick={onClose}>
          <SmartIcon type="close" />
        </ActionWrapper>
      )}
    </Container>
  );
};
