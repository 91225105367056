import { EventMappingsTree } from 'src/app/services/analytics/types';

const MAPPINGS: EventMappingsTree = {
  '/quickbooks/entrypoints/loaders/pay/*': {
    'page.view': ['qbo-actions', 'pay'],
  },
  '/quickbooks/entrypoints/errors/contractors': {
    'page.view': ['pay-bill', 'contractor-page'],
  },
  '/quickbooks/entrypoints/errors/org-blocked-by-risk': {
    'page.view': ['qbo-blocked', 'blocked-user'],
  },
  '/quickbooks/entrypoints/errors/bill-not-found': {
    'page.view': ['bill-error', 'not-paid-by-melio'],
  },
  '/quickbooks/entrypoints/errors/bill-already-scheduled': {
    'page.view': ['pay-bill', 'bill-already-scheduled'],
  },
  '/quickbooks/entrypoints/errors/bill-zero-balance': {
    'page.view': ['pay-bills', 'bill-zero-balance'],
    'bills.pay.billZeroBalance.buttonLabel': [
      'pay-bills',
      'bill-zero-balance-go-to-dashboard-button-clicked',
    ],
  },
};

export default MAPPINGS;
