import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';

export const Container = styled(Box)<{ $shouldAddMB: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  margin-bottom: ${({ $shouldAddMB }) => $shouldAddMB && '2.2rem'};
`;

export const Separator = styled.div`
  height: 0.1rem;
  background-color: ${({ theme }) => theme.colors.ds.gray[700]};
  width: 100%;
  margin: 0 auto;
`;

export const PaymentFailedContainer = styled(Box)`
  ${({ theme }) => theme.textStyles.ds.body3Semi};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  color: ${(props) => props.theme.colors.ds.red[200]};
  margin-top: 2rem;
`;

export const ReasonContainer = styled.div`
  margin-top: 2rem;
`;

export const ReasonTitleContainer = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2};
`;

export const ReasonContentContainer = styled.div`
  ${({ theme }) => theme.textStyles.ds.body1};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;

export const ReasonSeparator = styled(Separator)`
  width: 45rem;
  margin-left: -2rem;
`;
