import React from 'react';
import styled, { css } from 'styled-components';

import { useBreak } from 'src/app/hoc';

type Props = {
  isFixed?: boolean;
  children: React.ReactElement;
};

export const ButtonContainer = ({ isFixed = false, children }: Props) => {
  const device = useBreak();
  const isMobileView = device.isMobile || device.isPhablet;

  if (isMobileView) {
    return (
      <MobileContainer isFixed={isFixed}>
        {React.cloneElement(children, { fullWidth: true })}
      </MobileContainer>
    );
  }

  return children;
};

const MobileContainer = styled.div<{ isFixed: boolean }>`
  width: 100%;
  display: flex;
  background: ${(props) => props.theme.background.default};
  justify-content: center;
  padding-bottom: 3rem;
  border-bottom: 1px solid #e3e5e8;
  box-sizing: border-box;

  ${(props) =>
    props.isFixed &&
    css`
      position: fixed;
      bottom: 11rem;
      left: 0;
      right: 0;
    `}

  > a {
    justify-content: center;
    width: 100%;
  }

  .button {
    justify-content: center;
    min-height: 4.8rem;
    border-radius: 2.4rem;
    font-size: ${(props) => props.theme.text.size.regular};
  }
`;
