import { useState, useEffect } from 'react';
import { useDashboardHighlightedItems } from './useDashboardHighlightedItems';

type Response = {
  highlightedItemIds: string[];
};

const useDashboardRowHighlighting = (): Response => {
  const {
    highlightedItemIds: queryIds,
    removeHighlightedItemIds: removeHighlightedItemIdsFromUrl,
  } = useDashboardHighlightedItems();
  const [highlightedItemIds, setHighlightedItemIds] = useState(queryIds);

  useEffect(() => {
    if (queryIds && queryIds.length) {
      setHighlightedItemIds(queryIds);
      removeHighlightedItemIdsFromUrl();
    }
  }, [queryIds.join('')]);

  return { highlightedItemIds };
};

export { useDashboardRowHighlighting };
