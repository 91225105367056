import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Box } from '@melio/billpay-design-system';

import { CONSTS } from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import { AreaLoader } from '@melio/billpay-design-system';
import MIButton from 'src/app/components/common/MIButton';
import {
  ContentWrapper,
  Footer,
  Header,
} from 'src/app/pages/vendor/components/VendorLayoutElements';
import NewDeliveryMethodForm from 'src/app/pages/vendor/components/NewDeliveryMethodForm';
import { ModelView, useForm } from 'src/app/ui/form';
import { formatCheckPrintName } from 'src/app/utils/delivery-methods';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { CheckType } from 'src/app/utils/types';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import { convertPaperCheck } from 'src/app/utils/address';
import { useEditCheckAddressFormState } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressFormState';
import { useEditCheckAddressStatus } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import WhitePageAddressContainer from './WhitePageAddressContainer';
import { BillPaymentBlock, BlockSeparator } from './shared';
import { getIsVendorPaymentDetailsFlow } from '../vendor-payment-tracking/utils';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from '../vendor-payment-tracking/locations';

type Props = {
  token: string;
  id: string;
};

const CheckChangeAddressPage = (props: Props) => {
  const { token, id } = props;
  const { paymentId, payment, isPaymentLoading, organization } = useEditCheckAddressStatus({
    token,
  });
  const { filesUrls } = useStructuredSelectors(paymentStore.selectors.payment(paymentId));
  const { actions, deliveryMethodModel, isDeliveryMethodProcessing } = useEditCheckAddressFormState(
    { token, id }
  );
  const history = useHistory();

  const { onSubmit } = actions;
  const [deliveryMethodMV, deliveryMethodMVActions] = useForm<{
    paperCheck: CheckType;
  }>(deliveryMethodModel, {
    submit: (value) => {
      const { ...rest } = value;

      value.paperCheck.printName = formatCheckPrintName(value.paperCheck.printName);

      return onSubmit({ ...rest }, false);
    },
  });

  const paperCheck = useMemo<CheckType>(
    () => convertPaperCheck(deliveryMethodMV.paperCheck as ModelView<CheckType>),
    [deliveryMethodMV.paperCheck]
  );
  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId)
  );
  const [whitePageAddressView, setWhitePageAddressView] = useState(false);

  useEffect(() => {
    const suggestAddress =
      whitePageAddress &&
      !isEmpty(whitePageAddress) &&
      ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid);

    setWhitePageAddressView(suggestAddress);

    if (suggestAddress)
      analytics.trackAction('suggest-alt-address', {
        flow: 'payment-schedule',
      });

    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSubmit({ deliveryType: CONSTS.DELIVERY_TYPE.CHECK, paperCheck }, true);
    }
  }, [whitePageAddress]);

  if (isPaymentLoading) return <AreaLoader />;

  const header = (
    <Header
      payment={payment}
      organization={organization}
      filesUrls={filesUrls}
      hideLogo
      subTitle="vendors.editCheckAddress.checkNotSent.header"
      showFullHeader
      onPrev={
        getIsVendorPaymentDetailsFlow(token)
          ? () =>
              history.push(generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token }))
          : null
      }
    />
  );

  if (whitePageAddressView) {
    return (
      <WhitePageAddressContainer
        setWhitePageAddressView={setWhitePageAddressView}
        isLoading={isDeliveryMethodProcessing || isAddressLoading}
        whitePageAddress={whitePageAddress}
        submit={onSubmit}
        model={deliveryMethodMV.paperCheck}
        header={header}
      />
    );
  }

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <Box textAlign="center" marginBottom={5} marginTop={10}>
        <QBLogo />
      </Box>
      <BillPaymentBlock>
        {header}
        <BlockSeparator $width="80%" />
        <ContentWrapper title="vendors.editCheckAddress.checkNotSent.form.title">
          <NewDeliveryMethodForm
            submit={deliveryMethodMVActions.submit}
            selectedDeliveryMethodType={CONSTS.DELIVERY_TYPE.CHECK}
            checkModel={deliveryMethodMV.paperCheck}
          />
          <MIButton
            label="vendors.editCheckAddress.checkNotSent.form.saveCTA"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={deliveryMethodMVActions.submit}
            isProcessing={isDeliveryMethodProcessing || isAddressLoading}
            fullWidth
          />
          <Footer companyName={organization?.companyName as string} />
        </ContentWrapper>
      </BillPaymentBlock>
    </QBOLayoutPage>
  );
};

export default CheckChangeAddressPage;
