import React from 'react';
import { Box } from '@melio/billpay-design-system';

const controlButtonStyle = {
  bg: 'transparent',
  border: 'none',
  px: '0.5rem',
  cursor: 'pointer',
  color: 'gray.500',
  textStyle: 'body3',
  _active: {
    color: 'black',
  },
  _focus: {
    border: 'none',
    outline: 0,
  },
  _disabled: {
    color: 'gray.200',
    cursor: 'auto',
  },
};

type Props = {
  children: React.ReactNode;
  disabled: boolean;
  onClick: () => void;
};

const PaginationControl = ({ children, ...props }: Props) => (
  <Box as="button" __css={controlButtonStyle} {...props}>
    {children}
  </Box>
);

export default PaginationControl;
