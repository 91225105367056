const baseStyle = {
  px: 'ds.sm',
  py: '0',
  borderRadius: 'ds.md',
  textStyle: 'ds.body3',
  fontWeight: 'ds.semi',
  textTransform: 'uppercase',
  fontFamily: 'body',
};

const variantPrimary = {
  color: 'ds.white',
  bg: 'ds.green.200',
};

const variantPrimaryOutlined = {
  color: 'ds.green.200',
  boxShadow: 'inset 0 0 0px 1px',
  bg: 'transparent',
};

const variantError = {
  color: 'ds.white',
  bg: 'ds.red.200',
};

const variantErrorOutlined = {
  color: 'ds.red.200',
  boxShadow: 'inset 0 0 0px 1px',
  bg: 'transparent',
};

const variantWarning = {
  color: 'ds.black',
  bg: 'ds.yellow.200',
};

const variantDeclined = {
  color: 'ds.red.200',
  bg: 'ds.red.700',
  boxShadow: 'inset 0 0 0px 1px',
};

const variantInfo = {
  color: 'ds.white',
  bg: 'ds.blue.200',
};

const variantDarkGray = {
  bg: 'ds.gray.200',
  color: 'ds.white',
};

const variantLightGray = {
  bg: 'ds.gray.700',
  color: 'ds.gray.300',
};

const variantNew = {
  bg: 'ds.green.200',
  color: 'ds.white',
};

const variantGray = {
  bg: 'ds.gray.500',
  color: 'ds.gray.200',
};

const variantBlack = {
  bg: 'ds.black',
  color: 'ds.white',
};

const variants = {
  primary: variantPrimary,
  primaryOutlined: variantPrimaryOutlined,
  error: variantError,
  errorOutlined: variantErrorOutlined,
  warning: variantWarning,
  declined: variantDeclined,
  info: variantInfo,
  darkGray: variantDarkGray,
  gray: variantGray,
  lightGray: variantLightGray,
  new: variantNew,
  black: variantBlack,
};

const defaultProps = {
  variant: 'primary',
  colorScheme: 'gray',
};

export const BadgeTheme = {
  baseStyle,
  variants,
  defaultProps,
};
