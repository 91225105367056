function baseStyle() {
  return {
    textStyle: 'ds.body3',
    px: 'ds.md',
    py: 'ds.sm',
    bg: 'ds.black',
    color: 'ds.white',
    zIndex: 'tooltip',
    borderRadius: 'ds.md',
    position: 'relative',
    maxWidth: '25rem',
    fontFamily: 'body',
    '--popper-arrow-bg': 'var(--chakra-colors-ds-black)',
  };
}

export const TooltipTheme = {
  baseStyle,
};
