import { breaks } from 'src/app/theme/AppDevices';

// Not using useBreak hook, because in iframe it doesn't work properly
export function isMobileInLoader() {
  let screenWidth = 0;

  try {
    screenWidth = window.parent.innerWidth;
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return screenWidth < breaks.desktop;
}
