import React from 'react';
import { RecordOf } from 'immutable';
import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS } from 'src/app/utils/consts';
import { QBCashStateType } from 'src/app/utils/types';
import {
  MainPaymentActivityContainer,
  BlockTitleContainer,
} from 'src/app/components/layout/PaymentActivityElements';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import { JustPayPaymentActivityFundingSourceInfo as FundingSourceInfo } from './components/FundingSourceInfo/FundingSourceInfo';
import { JustPayPaymentActivityDeductionDateInfo as DeductionDateInfo } from './components/DeductionDateInfo';
import { JustPayPaymentActivityDeliveryMethodInfo as DeliveryMethodInfo } from './components/DeliveryMethodInfo';
import { JustPayPaymentActivityDeliveryDateInfo as DeliveryDateInfo } from './components/DeliveryDateInfo/DeliveryDateInfo';
import { JustPayPaymentActivityMemoInfo as MemoInfo } from './components/MemoInfo';

export type ActivityActionsType = {
  onEditFundingSource: () => void;
  onEditPartialAmount: () => void;
  onEditDate: () => void;
  onEditNote: () => void;
  onEditDeliveryMethod?: () => void;
};

type Props = {
  fundingSource: FundingSource;
  payment: RecordOf<JustPayPaymentType>;
  activityActions: ActivityActionsType;
  qbCashState?: QBCashStateType;
};

export const JustPayPaymentActivity = ({
  activityActions,
  fundingSource,
  payment,
  qbCashState,
}: Props) => {
  const { onEditFundingSource, onEditDate, onEditDeliveryMethod, onEditNote } = activityActions;
  const isVirtualDeliveryMethod = payment?.deliveryMethodType === CONSTS.DELIVERY_TYPE.VIRTUAL;

  return (
    <>
      <BlockTitleContainer>
        <MIFormattedText label="payBillPaymentActivity.payFromTitle" />
      </BlockTitleContainer>
      <MainPaymentActivityContainer>
        <FundingSourceInfo
          fundingSource={fundingSource}
          onEdit={onEditFundingSource}
          qbCashState={qbCashState}
        />
        <DeductionDateInfo payment={payment} onEdit={onEditDate} />
      </MainPaymentActivityContainer>
      <BlockTitleContainer>
        <MIFormattedText label="payBillPaymentActivity.receiveTitle" />
      </BlockTitleContainer>
      <MainPaymentActivityContainer>
        {!isVirtualDeliveryMethod && (
          <DeliveryMethodInfo onEdit={onEditDeliveryMethod} payment={payment} />
        )}
        <DeliveryDateInfo
          payment={payment}
          fundingSource={fundingSource}
          onEdit={isVirtualDeliveryMethod ? onEditDeliveryMethod : null}
        />
      </MainPaymentActivityContainer>
      <MemoInfo memo={payment?.noteToVendor} onEdit={onEditNote} />
    </>
  );
};
