import styled from 'styled-components';

import { devices } from 'src/app/theme/AppDevices';

export const BillPaymentBlock = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  border: 1px solid ${(props) => props.theme.colors.shadow.lightGrey};
  box-sizing: border-box;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 53rem;
  }
`;

export const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

export const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 2rem;
  font-size: 2.4rem;
  color: #393a3c;
`;

export const Subtitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: #393a3c;
  margin-bottom: 3rem;
`;

export const ShiftToAchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 4rem 4rem;
  border-radius: 0 0 0.6rem 0.6rem;
  color: #393a3c;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

export const BlockSeparator = styled.div<{ $width?: string }>`
  height: 0.4rem;
  background: ${(props) => props.theme.colors.white.lightBlue};
  width: ${(props) => props.$width || '100%'};
  margin: 0 auto;
`;

export const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

export const ShiftToAchTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 3.2rem;
  margin-top: 4rem;
`;

export const ShiftToAchSubtitle = styled.div`
  font-size: 1.4rem;
  line-height: ${(props) => props.theme.text.lineHeight.medium};
  margin: 1.2rem 0 4rem;
  color: ${(props) => props.theme.text.color.subtitle};
`;
