import React from 'react';
import styled, { css } from 'styled-components';
import { DeliveryMethodType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  deliveryMethod: DeliveryMethodType;
  companyName: string;
  onItemClick: () => void;
  isSelected: boolean;
};

export const QBRDeliveryMethodListItem = ({ deliveryMethod, onItemClick, isSelected }: Props) => {
  const getDeliveryMethodIcon = () => {
    let icon = '';

    if (isSelected) {
      icon = 'icon-checkmark-icon';
    } else {
      icon =
        deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.ACH
          ? 'icon-qbxm-bank'
          : 'icon-qbxm-check';
    }

    return icon;
  };

  const getDeliveryMethodLabelProps = () => {
    if (!deliveryMethod.id) {
      return {
        label: `deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.empty`,
      };
    }

    return {
      label: `deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.label`,
      values: { displayName: getDisplayName() },
    };
  };

  const getDisplayName = () => {
    switch (deliveryMethod.deliveryType) {
      case CONSTS.DELIVERY_TYPE.ACH:
        return deliveryMethod?.bankAccount?.accountNumber4digits;

      case CONSTS.DELIVERY_TYPE.CHECK:
        return `${deliveryMethod?.paperCheck?.addressLine1}, ${deliveryMethod?.paperCheck?.city},
        ${deliveryMethod?.paperCheck?.state}, ${deliveryMethod?.paperCheck?.zipCode}`;

      default:
        return '';
    }
  };

  return (
    <DeliveryMethod
      isSelected={isSelected}
      onClick={onItemClick}
      data-testid={`radio-select-method-${deliveryMethod.deliveryType}`}
    >
      <DeliveryMethodDetailsContainer>
        <DeliveryMethodDescriptionContainer>
          <DeliveryMethodIconWrapper>
            <DeliveryMethodIcon isSelected={isSelected} className={getDeliveryMethodIcon()} />
          </DeliveryMethodIconWrapper>
          <DeliveryMethodInfo>
            <DeliveryMethodName>
              <MIFormattedText {...getDeliveryMethodLabelProps()} />
            </DeliveryMethodName>
            <DeliveryMethodDescription>
              <MIFormattedText
                label={`deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.desc`}
              />
            </DeliveryMethodDescription>
          </DeliveryMethodInfo>
        </DeliveryMethodDescriptionContainer>
      </DeliveryMethodDetailsContainer>
    </DeliveryMethod>
  );
};

const DeliveryMethodDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeliveryMethodDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const DeliveryMethod = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  max-width: 45rem;
  width: 100%;
  min-height: 8.4rem;
  border-radius: 0.8rem;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primary.translucent1 : props.theme.colors.white.opaque};
  padding: 2rem 1.6rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  transition: ${(props) => props.theme.animation.transition.default};
  transition-property: box-shadow;
  box-shadow: none;
  border: ${(props) =>
    props.isSelected
      ? `0.2rem solid ${props.theme.text.color.highligh}`
      : `0.2rem solid ${props.theme.text.color.lightGrey}`};

  &:hover:not(:active) {
    ${(props) =>
      !props.isSelected &&
      css`
        box-shadow: 0 0.2rem 1rem 0 rgba(192, 196, 203, 1);
      `}
  }

  &:first-child {
    margin-top: 0;
  }
  box-shadow: ${(props) => `0 0.1rem 0.4rem ${props.theme.colors.dark.translucent1}`};
  border: none;
`;

const DeliveryMethodInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${(props) => props.theme.text.weight.normal};
  width: 90%;
  ${(props) => props.theme.text.fontType.medium};
`;

const DeliveryMethodDescription = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.small};
`;

const DeliveryMethodIconWrapper = styled.div`
  background: ${(props) => props.theme.colors.white.lightBlue};
  min-width: 4.4rem;
  min-height: 4.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.6rem;
`;

const DeliveryMethodIcon = styled.i<{ isSelected?: boolean }>`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.icon.grey};
`;

const DeliveryMethodName = styled.span`
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: ${(props) => props.theme.text.weight.medium};
`;
