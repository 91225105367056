import { featureFlags } from '@melio/shared-web';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { CardTypeEnum } from 'src/app/version-2/model/enums/cardType.enum';
import { getCardName, isMastercard } from 'src/app/version-2/utils/paymentMethods.utils';

interface AllowInternationalParams {
  fundingSource?: FundingSource | any; // we use any to avoid multiple files changes for AccountType
  isInternationalVendor?: boolean;
}
export const allowInternational = ({
  fundingSource,
  isInternationalVendor = true,
}: AllowInternationalParams) => {
  const hasInternationalMastercard = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.INTERNATIONAL_MASTERCARD,
    false
  );

  const isCredit = fundingSource?.cardAccount?.cardType === CardTypeEnum.CREDIT;
  const isDebit = fundingSource?.cardAccount?.cardType === CardTypeEnum.DEBIT;
  const cardType = getCardName(fundingSource as FundingSource);
  const allowCard = isMastercard(fundingSource as FundingSource);

  const defaultResponse = {
    allow: true,
    description: '',
    values: null,
  };

  if (!isInternationalVendor) return defaultResponse;

  if (!fundingSource && hasInternationalMastercard) {
    return {
      allow: true,
      description: 'onboarding.fundingSources.options.credit.international',
      values: null,
    };
  }

  if (!fundingSource && !hasInternationalMastercard) {
    return {
      allow: false,
      description: 'deliveryMethods.notDefinedInfo.international.disabled.credit',
      values: null,
    };
  }

  if (isCredit && !hasInternationalMastercard) {
    // ff temp code
    return {
      allow: false,
      description: 'deliveryMethods.notDefinedInfo.international.disabled.credit',
      values: { cardType },
    };
  }

  if (isCredit && !allowCard) {
    return {
      allow: false,
      description: 'bills.form.paymentActivity.scheduledBill.international.card.disable',
      values: { cardType },
    };
  }

  if (isDebit) {
    return {
      allow: false,
      description: 'deliveryMethods.notDefinedInfo.international.disabled.debit',
      values: null,
    };
  }

  return defaultResponse;
};

export const getInternationalDisabledText = ({
  fundingSource,
  billHasPartialPayments,
  isEligibleForInternationalPayment,
}: {
  fundingSource?: FundingSource | any;
  billHasPartialPayments?: boolean;
  isEligibleForInternationalPayment?: boolean;
}): string => {
  const international = allowInternational({ fundingSource });

  if (!international.allow) {
    return international.description;
  }

  if (billHasPartialPayments) {
    return 'deliveryMethods.notDefinedInfo.international.disabled.partial';
  }

  if (!isEligibleForInternationalPayment) {
    return 'deliveryMethods.notDefinedInfo.international.disabled.notEligible';
  }

  return '';
};

export const isEligibleToReceiveInternational = (fundingSource?: FundingSource): boolean => {
  const international = allowInternational({ fundingSource: fundingSource as FundingSource });

  return international.allow;
};
