import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, Loader } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import userPreferences from 'src/app/services/api/userPreferences';
import { isAfterEndOfLife } from 'src/app/utils/dates';
import { OrganizationPreferencesType } from 'src/app/utils/types';
import { iframeEventsApi } from 'src/app/version-2/api/iframeEvents.api';
import { NotificationCheckFee } from 'src/app/version-2/components/CheckFee/NotificationCheckFee';
import { HelpGuide } from 'src/app/version-2/components/HelpGuide/HelpGuide';
import { analytics, MIFormattedText, QBOHeader, utilsLocations } from 'src/app/version-2/externals';
import { CheckFeeEnum, FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import {
  fundingSourcesActions,
  fundingSourcesSelectors,
} from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import { intuitActions } from 'src/app/version-2/modules/intuit/intuit.slice';
import { organizationActions } from 'src/app/version-2/modules/organization/organization.slice';
import {
  uboActions,
  uboSelectors,
} from 'src/app/version-2/modules/ultimateBeneficialOwners/ultimateBeneficialOwners.slice';
import { userActions, userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { AddVendorEmailModal } from 'src/app/version-2/pages/batch-bulk/components/AddVendorEmailModal';
import { AmexVerificationModal } from 'src/app/version-2/pages/batch-bulk/components/AmexVerificationModal';
import {
  BatchBulkTable,
  Container,
  Content,
  Footer,
  FooterButtons,
  FooterOnboardingLabel,
  LoaderWrapper,
  onboardingContainerStyles,
  TopWhiteWrap,
} from 'src/app/version-2/pages/batch-bulk/components/BatchBulkPage/BatchBulkPage.styles';
import { BillDetailsModal } from 'src/app/version-2/pages/batch-bulk/components/BillDetailsModal';
import { EndOfLifeNotification } from 'src/app/version-2/pages/batch-bulk/components/EndOfLifeNotification';
import { HeadSection } from 'src/app/version-2/pages/batch-bulk/components/HeadSection';
import { InternationalDetailsModal } from 'src/app/version-2/pages/batch-bulk/components/InternationalDetailsModal';
import { MemoForVendorModal } from 'src/app/version-2/pages/batch-bulk/components/MemoForVendorModal';
import { OnboardingTooltip } from 'src/app/version-2/pages/batch-bulk/components/OnboardingTooltip/OnboardingTooltip';
import {
  BATCH_BULK_EVENT_PAGE,
  BATCH_SORT_BILLS,
  BULK_TOGGLE_CLICKED,
  COMBINED_COLUMNS,
  FACH_BATCH,
  SEPARATE_COLUMNS,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import {
  BatchBulkItem,
  SelectedPaymentIntent,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { modifyTextSteps } from 'src/app/version-2/pages/batch-bulk/utils/batchList.utils';
import {
  getColumnsDefaultSorting,
  getTableColumns,
} from 'src/app/version-2/pages/batch-bulk/utils/tableColumns.utils';
import { getColumnsWidths } from 'src/app/version-2/pages/batch-bulk/utils/tableWidth.utils';
import { canCombinedBills } from 'src/app/version-2/utils/bills.utils';
import { isCheckFeeOpenWithPromotions } from 'src/app/version-2/utils/checkFees.utils';
import { hasRequiredLegalCompanyInfo } from 'src/app/version-2/utils/company.utils';
import onboardingData from './onboardingData.json';
import uncombinedData from './uncombinedData.json';
import { BatchBulkBanner } from '../BatchBulkBanner';

export interface BatchBulkPageProps {
  billIds: string[];
  partialPayments: Record<string, number>;
  preservedState: {
    selectedFundingSourceId?: number;
    newFundingSourceId?: number;
    batchList: BatchBulkPaymentIntent[];
    selectedPaymentIntent: SelectedPaymentIntent;
  };
}

export const BatchBulkPage: FC<BatchBulkPageProps> = (props) => {
  const { billIds, partialPayments, preservedState } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const batchBulks: BatchBulkItem[] = useSelector(batchBulkSelectors.selectPaymentIntentsItems);
  const orgId = useSelector(userSliceSelectors.selectOrgId);

  const isMemoForVendorModalOpen: boolean = useSelector(
    batchBulkSelectors.selectIsMemoForVendorModalOpen
  );
  const isBillDetailsModalOpen: boolean = useSelector(
    batchBulkSelectors.selectIsBillDetailsModalOpen
  );
  const isAddEmailModalOpen: boolean = useSelector(batchBulkSelectors.selectIsAddEmailModalOpen);
  const isAmexVerificationModalOpen: boolean = useSelector(
    batchBulkSelectors.selectIsAmexVerificationModalOpen
  );
  const isInternationalModalOpen: boolean = useSelector(
    batchBulkSelectors.selectIsInternationalModalOpen
  );
  const showHelpGuide = useSelector(batchBulkSelectors.selectShowHelpGuide);
  const showUncombinedGuide = useSelector(batchBulkSelectors.selectShowUncombinedGuide);
  const showHelpGuideTooltip = useSelector(batchBulkSelectors.selectShowHelpGuideTooltip);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const exitUrl = useSelector(batchBulkSelectors.selectExitUrl);
  const [onboardingFeatureFlag] = featureFlags.useFeature<boolean>('batch-bulk-v-2-onboarding');
  const [isBpFastACHExperimentInBatchFlow] = featureFlags.useFeature<boolean>(
    'bp-fast-ACH-experiment-in-batch-flow'
  );
  const [shouldUseFetchV2FeatureFlag] = featureFlags.useFeature<boolean>(
    FeatureFlagsEnum.FUNDING_SOURCE_API_V2,
    false
  );
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlagsEnum.CHECK_FEES, CheckFeeEnum.CLOSED, {
    shouldTrack: false,
  });

  const isFirstWave = useMemo(() => !!organizationPreferences?.billPayFirstWaveUser, []);
  const [isNextDisabled, setIsNextDisabled] = useState(
    batchBulks.some((bulk) => isAfterEndOfLife(bulk.minScheduledDate, isFirstWave))
  );
  const fundingSources = useSelector(fundingSourcesSelectors.selectValidFundingSources);
  const companyInfo = useSelector(userSliceSelectors.getCompanyInfo);
  const isLoading = useSelector(batchBulkSelectors.selectIsLoading) as boolean;
  const isBatchValid = useSelector(batchBulkSelectors.selectIsValid);
  const isUSHolidayNotificationEnabled: boolean | undefined = useSelector(
    batchBulkSelectors.selectIsUSHolidayNotificationEnabled
  );
  const isCombined: boolean = useSelector(batchBulkSelectors.selectIsCombined);
  const isScheduleLoading: boolean = useSelector(batchBulkSelectors.isScheduleDisabled);
  const isScheduleBlocked: boolean = useSelector(batchBulkSelectors.selectIsScheduledBlocked);
  const hasUltimateBeneficialOwners = useSelector(uboSelectors.hasUboItems);
  const internationalBills = useSelector(batchBulkSelectors.selectInternationalBills);

  // test case (temp code)
  const [shouldUncombineBulkFF] = featureFlags.useFeature(FeatureFlagsEnum.BATCH_UNCOMBINED, false);
  const uncombinedReturningUsers = !showHelpGuide && shouldUncombineBulkFF;

  const { push } = history;

  const { formatMessage } = useIntl();
  const isCheckFeePromotions = isCheckFeeOpenWithPromotions(checkFeeFlag);

  const trackSortBills = (name, sortingDirection) => {
    analytics.track(BATCH_BULK_EVENT_PAGE, BATCH_SORT_BILLS, {
      orderBy: name,
      orderDirection: sortingDirection,
    });
  };
  const onSchedulePayments = useCallback(() => {
    const shouldRedirectOnLegalInfoPage = !hasRequiredLegalCompanyInfo(companyInfo);
    const shouldRedirectOnUboPage = !hasUltimateBeneficialOwners && internationalBills?.length > 0;

    if (isBatchValid && (shouldRedirectOnLegalInfoPage || shouldRedirectOnUboPage)) {
      // company info is missing - direct from batch (without create new dm flow)
      if (shouldRedirectOnLegalInfoPage)
        push(utilsLocations.Bills.pay.batchCompleteLegalInfo.url());
      // updating ubo - direct from batch (without create new dm flow)
      else if (shouldRedirectOnUboPage)
        push(
          utilsLocations.Vendors.deliveryMethods.international.ultimateBeneficialOwners.url({
            orgId,
            id: internationalBills?.[0]?.vendorId,
          }),
          { isBatchDirect: true }
        );
    } else {
      // submit bills
      dispatch(batchBulkActions.createBatchBulkPayment({ history }));

      // clear US Holiday notification state
      dispatch(batchBulkActions.setIsUSHolidayNotificationEnabled(undefined));

      // hiding check fee notifications
      dispatch(userActions.increaseCheckFeePaymentCount({ batchData: batchBulks }));
    }
  }, [companyInfo, batchBulks, isCheckFeePromotions]);

  const goToSettingsPage = useCallback(() => {
    dispatch(batchBulkActions.goToSettingsPage({ history }));
  }, [history]);

  const onCombineChanged = useCallback((event) => {
    const isCombinedNewValue: boolean = event.target.checked;

    dispatch(
      batchBulkActions.setIsCombined({
        isCombined: isCombinedNewValue,
        partialPayments,
      })
    );
    analytics.track(BATCH_BULK_EVENT_PAGE, BULK_TOGGLE_CLICKED, {
      newToggleState: isCombinedNewValue,
    });
  }, []);

  const data = isLoading ? [] : batchBulks;

  const setShowHelpGuide = useCallback(
    ({ key, value }: { key: string; value: boolean }) => {
      setShowHelpGuideUI({ key, value });
      setShowHelpGuideApi({ key, value });
    },
    [userPreferences]
  );
  const setShowHelpGuideUI = useCallback(
    ({ key, value }: { key: string; value: boolean }) => {
      window.scrollTo(0, 0);
      dispatch(
        batchBulkActions.updateShowHelpGuideUI({
          key,
          value,
        })
      );
    },
    [userPreferences]
  );

  const setShowHelpGuideApi = useCallback(
    ({ key, value }: { key: string; value: boolean }) => {
      dispatch(
        batchBulkActions.updateShowHelpGuideApi({
          key,
          value,
        })
      );
    },
    [userPreferences]
  );

  const onCloseTooltip = useCallback(() => {
    dispatch(batchBulkActions.setShowHelpGuideTooltip(false));
  }, []);

  useEffect(() => {
    if (!orgId) {
      dispatch(userActions.fetchUserProfile());
    }
  }, []);

  useEffect(() => {
    if (!hasRequiredLegalCompanyInfo(companyInfo)) {
      dispatch(userActions.fetchCompanyInfo());
    }
  }, []);

  useEffect(() => {
    if (internationalBills?.length > 0) {
      dispatch(uboActions.fetchUltimateBeneficialOwners());
    }
  }, [internationalBills?.length]);

  useEffect(() => {
    if (orgId) {
      if (shouldUseFetchV2FeatureFlag) {
        dispatch(fundingSourcesActions.fetchFundingSourcesV2());
      } else {
        dispatch(fundingSourcesActions.fetchFundingSources());
      }

      dispatch(batchBulkActions.fetchShowHelpGuide());
    }
  }, [orgId]);

  useEffect(() => {
    const isLastFundingSourceRemovedInSettings =
      !fundingSources.length && preservedState?.batchList;

    if (fundingSources.length || isLastFundingSourceRemovedInSettings) {
      const experimentValue = isBpFastACHExperimentInBatchFlow
        ? COMBINED_COLUMNS
        : SEPARATE_COLUMNS;

      dispatch(batchBulkActions.setBillIds(billIds));
      dispatch(organizationActions.fetchActiveFeeFundingSource());
      dispatch(
        batchBulkActions.fetchBatchBulks({
          preservedState,
          partialPayments,
          analyticsEventProps: {
            experimentName: [FACH_BATCH],
            experimentValue,
          },
        })
      );
    }
  }, [fundingSources]);

  useEffect(() => {
    // get number of free checks if available
    if (isCheckFeePromotions) dispatch(organizationActions.fetchFreeChecks({ isBatch: true }));
  }, [isCheckFeePromotions]);

  useEffect(() => {
    if (batchBulks?.length > 0) {
      const canCombine = canCombinedBills(batchBulks);

      if (isCombined && !canCombine) {
        dispatch(
          batchBulkActions.setIsCombined({
            isCombined: false,
            partialPayments,
          })
        );
        dispatch(batchBulkActions.setIsCombineSwitchDisabled(true));
      } else if (isLoading || (!isCombined && batchBulks?.length === 1)) {
        dispatch(batchBulkActions.setIsCombineSwitchDisabled(true));
      } else if (canCombine) {
        dispatch(batchBulkActions.setIsCombineSwitchDisabled(false));
      }
    }
  }, [batchBulks, isLoading]);

  useEffect(() => {
    if (batchBulks?.length > 0 && !isScheduleBlocked && !isScheduleLoading) {
      setIsNextDisabled(
        batchBulks.some((bulk) => isAfterEndOfLife(bulk.deductionDate, isFirstWave))
      );
    } else if (isScheduleBlocked || isScheduleLoading) {
      setIsNextDisabled(true);
    }
  }, [batchBulks, isScheduleBlocked, isFirstWave]);

  const columnWidths = useMemo(getColumnsWidths, [window.screen.width]);

  useEffect(() => {
    dispatch(intuitActions.fetchBillCategories());
  }, []);

  useEffect(() => {
    if (!preservedState && isUSHolidayNotificationEnabled === undefined) {
      dispatch(batchBulkActions.setIsUSHolidayNotificationEnabled(true));
    }
  }, []);

  const columns = useMemo(
    () =>
      getTableColumns({
        isBpFastACHExperimentInBatchFlow,
        isLoading,
        columnWidths,
        formatMessage,
      }),
    [columnWidths, isLoading, formatMessage, isBpFastACHExperimentInBatchFlow]
  );

  const countItems = data.length;

  const defaultSorting = useMemo(
    () => getColumnsDefaultSorting(isLoading || countItems === 0),
    [isLoading, countItems]
  );

  const goExit = useCallback(() => {
    if (exitUrl) {
      push(exitUrl);

      // clear US Holiday notification state
      dispatch(batchBulkActions.setIsUSHolidayNotificationEnabled(undefined));
    } else {
      iframeEventsApi.melioClose();
    }
  }, [exitUrl]);

  return (
    <Container>
      <TopWhiteWrap>
        <QBOHeader goExit={goExit} goToSettings={goToSettingsPage} />

        <HeadSection
          onCombineChanged={onCombineChanged}
          partialPayments={partialPayments}
          isCombined={isCombined}
          selectedFundingSourceId={
            preservedState?.newFundingSourceId || preservedState?.selectedFundingSourceId
          }
        />
      </TopWhiteWrap>

      <Content>
        <BatchBulkBanner numOfSelectedBills={billIds?.length} />
        <NotificationCheckFee batchData={batchBulks} />
        <EndOfLifeNotification isDisabled={isNextDisabled} isFirstWave={isFirstWave} />
        <div data-testid="batch-bulk-table">
          <BatchBulkTable
            data={data}
            columns={columns}
            defaultSorting={defaultSorting}
            onSorting={trackSortBills}
          />
        </div>
        {isLoading && (
          <LoaderWrapper>
            <Loader context="page" />
          </LoaderWrapper>
        )}
        {isMemoForVendorModalOpen && <MemoForVendorModal isOpen={isMemoForVendorModalOpen} />}
        <BillDetailsModal isOpen={isBillDetailsModalOpen} />
        <AddVendorEmailModal isOpen={isAddEmailModalOpen} />
        {isAmexVerificationModalOpen && (
          <AmexVerificationModal isOpen={isAmexVerificationModalOpen} />
        )}
        {isInternationalModalOpen && (
          <InternationalDetailsModal isOpen={isInternationalModalOpen} />
        )}
      </Content>
      {onboardingFeatureFlag && (
        <HelpGuide
          nextLabel={onboardingData.nextLabel}
          prevLabel={onboardingData.prevLabel}
          doneLabel={onboardingData.doneLabel}
          ofLabel={onboardingData.ofLabel}
          steps={modifyTextSteps(onboardingData.steps)}
          isOpen={showHelpGuide && data.length > 0}
          onOpen={() => {
            setShowHelpGuideApi({ key: 'batchBulkOnboardingSeen', value: false });
            setShowHelpGuideApi({ key: 'qbBatchBulkUncombinedSeen', value: false });
          }}
          onClose={() => {
            setShowHelpGuideUI({ key: 'batchBulkOnboardingSeen', value: false });
            setShowHelpGuideUI({ key: 'qbBatchBulkUncombinedSeen', value: false });
          }}
        />
      )}

      {uncombinedReturningUsers && (
        <HelpGuide
          nextLabel={uncombinedData.nextLabel}
          prevLabel={uncombinedData.prevLabel}
          doneLabel={uncombinedData.doneLabel}
          ofLabel={uncombinedData.ofLabel}
          steps={uncombinedData.steps}
          isOpen={showUncombinedGuide && data.length > 0}
          onOpen={() => setShowHelpGuideApi({ key: 'qbBatchBulkUncombinedSeen', value: false })}
          onClose={() => setShowHelpGuideUI({ key: 'qbBatchBulkUncombinedSeen', value: false })}
          isOneStep
        />
      )}
      <Footer>
        <Box {...onboardingContainerStyles}>
          <FooterOnboardingLabel
            onClick={() => {
              setShowHelpGuide({ key: 'batchBulkOnboardingSeen', value: true });
              dispatch(batchBulkActions.setShowHelpGuideTooltip(false));
            }}
            data-testid="help-guide-bottom-text"
          >
            {!showHelpGuide && onboardingFeatureFlag && (
              <MIFormattedText label="batchBulkPage.helpGuide.footer.title" />
            )}
          </FooterOnboardingLabel>
          {onboardingFeatureFlag && data.length > 0 && (
            <OnboardingTooltip isOpen={showHelpGuideTooltip} onClose={onCloseTooltip} />
          )}
        </Box>
        <FooterButtons>
          <Button onClick={goExit} variant="passivePrimary" size="md">
            <MIFormattedText label="batchBulkPage.cancel" />
          </Button>
          <Button
            onClick={onSchedulePayments}
            isLoading={isScheduleLoading}
            disabled={isScheduleBlocked || isScheduleLoading || isNextDisabled}
            variant="primary"
            size="md"
          >
            <MIFormattedText label="batchBulkPage.button" />
          </Button>
        </FooterButtons>
      </Footer>
    </Container>
  );
};
