import { useSiteContext } from 'src/app/hoc/withSiteContext';
import styled, { css } from 'styled-components';
import head from 'lodash/head';
import { Tooltip, Box } from '@melio/billpay-design-system';
import { useSelector } from 'react-redux';
import { CONSTS, HOLIDAYS_WARNING_ICON_VARIANT, FUNDING_SOURCE_ORIGIN } from 'src/app/utils/consts';
import { TableDatePicker } from 'src/app/ui/form/TableDatePicker';
import { getCardImageUrl } from 'src/app/utils/card';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { BatchDataItem } from 'src/app/pages/bill/pay/types';
import QBOSwitch from 'src/app/components/qbo/QBOSwitch';
import { getFundingSourceType, isOneDayDeliverySpeed } from 'src/app/utils/funding-sources';
import {
  getFastType,
  getDeliveryOption,
  isFastDeliveryType,
  getPaymentFastFee,
  getPaymentFastFeeForPartialPayments,
  removeUnsupportedDeliveryOptionsByBill,
} from 'src/app/utils/delivery-methods';
import {
  DeliveryOptionType,
  PaymentType,
  BillType,
  CompanyInfoType,
  OrganizationPreferencesType,
} from 'src/app/utils/types';
import deliveryApi from 'src/app/services/api/delivery';
import { isSameDay, isTomorrow, DeliveryDateFormat } from 'src/app/utils/dates';

import qbCashBankOrigin from 'src/app/images/icons/qb-cash-bank-origin.svg';
import { HolidaysWarning } from 'src/app/components/common/HolidaysWarning';
import { featureFlags } from '@melio/shared-web';
import { FUNDING_DEFAULT_LOGO } from 'src/app/version-2/model/constants';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';

type Props = {
  item: BatchDataItem;
  onDeductionDateChange: (params: any, deliveryOptions: any) => void;
  onFastToggle: (params: any) => void;
  onItemSelected: (id: string) => void;
  isSelected: boolean;
  orgId: number;
  companyInfo: CompanyInfoType;
  amount: number;
  isErrorIcon?: boolean;
};

type DeliverySpeedProps = {
  bill: BillType;
  payment?: PaymentType;
  deliveryOptions: DeliveryOptionType[];
  onFastToggle: (params: any) => void;
  isFastPayment: boolean;
  errors: any;
};

const renderPaymentMethod = (payment, paymentMethodError, isErrorIcon) => {
  const hasLogo =
    payment.fundingSource?.logo !== '' && payment.fundingSource?.logo !== FUNDING_DEFAULT_LOGO;

  if (!payment?.fundingSource?.fundingType) {
    return (
      <PaymentMethod>
        <PlaceholderText>
          {isErrorIcon && paymentMethodError && (
            <ErrorMessage>
              <i className="icon-warning-icon" />
            </ErrorMessage>
          )}
          <MIFormattedText label="batchPayment.methodPlaceholder" />
        </PlaceholderText>
      </PaymentMethod>
    );
  }

  if (
    payment.fundingSource?.fundingType === 'ach' &&
    payment.fundingSource?.origin === FUNDING_SOURCE_ORIGIN.QBCASH
  ) {
    return (
      <PaymentMethod>
        <PaymentMethodIcon>
          <Image src={qbCashBankOrigin} />
        </PaymentMethodIcon>
        <MIFormattedText label="batchPayment.paymentListTitle.qbCashLabel" />
      </PaymentMethod>
    );
  }

  if (payment.fundingSource?.fundingType === 'ach') {
    const digits = getAccountNumber4digits(payment.fundingSource.bankAccount);

    return (
      <PaymentMethod>
        <PaymentMethodIcon>
          {!hasLogo && <i className="icon-bank-icon" />}
          {hasLogo && (
            <img
              src={`data:image/jpeg;base64,${payment.fundingSource?.logo}`}
              alt="selected-method-icon"
            />
          )}
        </PaymentMethodIcon>
        <MIFormattedText label="batchPayment.paymentListTitle.achLabel" values={{ digits }} />
      </PaymentMethod>
    );
  }

  const isAmexError = paymentMethodError;

  return (
    <PaymentMethod>
      {getCardImageUrl(payment.fundingSource) ? (
        <Image
          src={getCardImageUrl(payment.fundingSource)}
          alt={payment.fundingSource?.cardAccount?.cardType}
        />
      ) : (
        <PaymentMethodCardIcon
          className={`icon-${payment.fundingSource?.cardAccount?.cardType}-card-icon`}
        />
      )}

      <MIFormattedText
        label="batchPayment.paymentListTitle.achLabel"
        values={{ digits: payment.fundingSource?.cardAccount?.card4digits }}
      />
      {isAmexError && (
        <Tooltip label={<MIFormattedText label="amexVerification.tooltip.title" />} placement="top">
          <Box as="i" fontSize="1.4rem" ml="0.5rem" mb="0.1rem" color="#D52B1E">
            <i className="icon-warning-icon" />
          </Box>
        </Tooltip>
      )}
    </PaymentMethod>
  );
};

const renderDeliveryMethod = (payment, errors, isErrorIcon) => {
  const deliveryType = payment?.deliveryMethod?.deliveryType;

  if (!deliveryType) {
    return (
      <DeliveryMethod>
        <PlaceholderText>
          {isErrorIcon && errors?.hasErrorInDeliveryMethod && (
            <ErrorMessage>
              <i className="icon-warning-icon" />
            </ErrorMessage>
          )}
          <MIFormattedText label="batchPayment.deliveryPlaceholder" />
        </PlaceholderText>
      </DeliveryMethod>
    );
  }

  if (deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL) {
    const hasError =
      errors?.hasErrorInPurposeOfPaymentDescription || errors?.hasErrorInPurposeOfPaymentType;

    return (
      <DeliveryMethod>
        {isErrorIcon && hasError && (
          <ErrorMessage>
            <i className="icon-warning-icon" />
          </ErrorMessage>
        )}

        <MIFormattedText label="batchPayment.paymentListTitle.international" />
      </DeliveryMethod>
    );
  }

  if (deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
    return (
      <Tooltip label={<MIFormattedText label="paymentDashboard.columns.deliveryMethod.tooltip" />}>
        <DeliveryMethod>
          <MIFormattedText label="batchPayment.deliveryVirtualPlaceholder" />
        </DeliveryMethod>
      </Tooltip>
    );
  }

  if (deliveryType === CONSTS.DELIVERY_TYPE.ACH) {
    return (
      <DeliveryMethod>
        <MIFormattedText label="batchPayment.paymentListTitle.bank" />
      </DeliveryMethod>
    );
  }

  if (deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
    return (
      <DeliveryMethod>
        <MIFormattedText label="batchPayment.paymentListTitle.check" />
      </DeliveryMethod>
    );
  }

  if (deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
    return (
      <Tooltip label={<MIFormattedText label="batchPayment.paymentListTitle.virtualCardTooltip" />}>
        <DeliveryMethod>
          <MIFormattedText label="batchPayment.paymentListTitle.virtualCard" />
        </DeliveryMethod>
      </Tooltip>
    );
  }

  return null;
};

const DeliverySpeed = ({
  bill,
  payment,
  deliveryOptions,
  onFastToggle,
  isFastPayment,
  errors,
}: DeliverySpeedProps) => {
  const deliveryOptionType = payment?.deliveryMethod?.deliveryType;
  const isDisabled =
    errors?.hasErrorInDeliveryMethod ||
    errors?.hasErrorInScheduledDate ||
    errors?.hasErrorInFundingSource ||
    !payment?.fundingSourceId ||
    false;
  const shouldDisplayTheSwitch =
    payment?.fundingSource?.id &&
    payment?.deliveryMethod?.id &&
    deliveryOptionType !== CONSTS.DELIVERY_TYPE.VIRTUAL &&
    deliveryOptions &&
    deliveryOptions.length > 1;
  const toggleType = () => {
    if (isFastPayment) {
      return null;
    }

    return getFastType(deliveryOptionType);
  };

  const handleDeliverySpeedChange = () => {
    const deliveryPreference = toggleType();
    const selectedDeliveryOption = getDeliveryOption(
      deliveryPreference || payment?.deliveryMethod?.deliveryType,
      deliveryOptions
    );

    if (selectedDeliveryOption) {
      const { scheduledDate, deliveryDate, maxDeliveryDate } = selectedDeliveryOption;

      onFastToggle({
        billId: bill?.id,
        scheduledDate,
        deliveryDate,
        maxDeliveryDate,
        deliveryPreference,
      });
    }
  };

  if (isDisabled) {
    return (
      <DeliverySpeedCell>
        <PlaceholderText>
          <MIFormattedText label="batchPayment.deliveryPlaceholder" />
        </PlaceholderText>
      </DeliverySpeedCell>
    );
  }

  if (shouldDisplayTheSwitch) {
    return (
      <DeliverySpeedCell>
        <QBOSwitch
          id={`fast-${deliveryOptionType}`}
          value={isFastPayment}
          label={`batchPayment.fast.${deliveryOptionType}`}
          onChange={handleDeliverySpeedChange}
        />
      </DeliverySpeedCell>
    );
  }

  const fundingSourceType = getFundingSourceType(payment?.fundingSource);

  const getDeliverySpeedLabel = (): string => {
    const commonPart = 'bills.pay.date.deliveryOptions.subTitle.';

    const dynamicPart = isOneDayDeliverySpeed(deliveryOptionType, fundingSourceType)
      ? 'achDebit'
      : deliveryOptionType;

    return `${commonPart}${dynamicPart}`;
  };

  const subtitle = getDeliverySpeedLabel();

  return (
    <DeliverySpeedCell>
      <PlaceholderText>
        <MIFormattedText label={subtitle} />
      </PlaceholderText>
    </DeliverySpeedCell>
  );
};

const PaymentItem = ({
  orgId,
  item,
  onDeductionDateChange,
  onItemSelected,
  onFastToggle,
  isSelected,
  companyInfo,
  amount,
  isErrorIcon,
}: Props) => {
  const site = useSiteContext();
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const { bill, errors } = item;
  const payment = head(bill.payments);

  const handleDeductionDateChange = async (newDateObj) => {
    if (!payment?.deliveryMethodId || !payment?.fundingSourceId) return;

    const { deliveryOptions } = await deliveryApi.getDeliveryTime(
      orgId.toString(),
      newDateObj.date,
      payment?.deliveryMethodId as unknown as number,
      payment?.fundingSourceId,
      bill.totalAmount,
      payment?.id,
      payment?.payBillFlowUUID
    );

    const newDeliveryMethods = removeUnsupportedDeliveryOptionsByBill({
      site,
      deliveryOptions: deliveryOptions || item.deliveryOptions,
      bill,
      payment,
      fundingSource: null,
      companyInfo,
    });
    const selectedDeliveryOption = getDeliveryOption(
      payment?.deliveryPreference || '',
      newDeliveryMethods
    );

    if (selectedDeliveryOption) {
      const { scheduledDate, deliveryDate, maxDeliveryDate } = selectedDeliveryOption;

      onDeductionDateChange(
        {
          billId: bill?.id,
          scheduledDate,
          deliveryDate,
          maxDeliveryDate,
          deliveryPreference: payment?.deliveryPreference,
        },
        newDeliveryMethods
      );
    }
  };

  const deliveryOptions = payment
    ? removeUnsupportedDeliveryOptionsByBill({
        site,
        deliveryOptions: item.deliveryOptions,
        bill,
        payment,
        fundingSource: null,
        companyInfo,
      })
    : item.deliveryOptions;
  const fee = isPartialPaymentsEnabled
    ? getPaymentFastFeeForPartialPayments(bill, deliveryOptions, amount)
    : getPaymentFastFee(bill, deliveryOptions);
  const isFastPayment = isFastDeliveryType(payment?.deliveryPreference);
  const hasDeliveryMethod = !!payment?.deliveryMethodId;

  const DatePicker = () => (
    <TableDatePicker
      date={payment?.scheduledDate ? new Date(payment.scheduledDate) : undefined}
      onChange={handleDeductionDateChange}
      disabled={!hasDeliveryMethod}
      isFirstWave={!!organizationPreferences?.billPayFirstWaveUser}
    />
  );

  return (
    <PaymentItemContainer
      data-testid={`payment-item-${bill.id}`}
      onClick={() => onItemSelected(bill.id)}
      isSelected={isSelected}
    >
      <Vendor>{bill.vendor.companyName}</Vendor>
      {renderPaymentMethod(payment, errors?.hasErrorInFundingSource, isErrorIcon)}
      <TableDatePickerContainer>
        {hasDeliveryMethod ? (
          <DatePicker />
        ) : (
          <Tooltip
            label={<MIFormattedText label="batchPayment.deliveryOptions.empty" />}
            placement="top"
          >
            <Box as="span">
              <DatePicker />
            </Box>
          </Tooltip>
        )}
      </TableDatePickerContainer>
      {renderDeliveryMethod(payment, errors, isErrorIcon)}
      <DeliverySpeed
        bill={bill}
        payment={payment}
        errors={errors}
        deliveryOptions={deliveryOptions}
        onFastToggle={onFastToggle}
        isFastPayment={isFastPayment}
      />
      <DeliveryDate
        deliveryEta={payment?.deliveryEta}
        selectedDeliveryOption={getDeliveryOption(
          payment?.deliveryPreference || '',
          item.deliveryOptions
        )}
        isFastPayment={isFastPayment}
      />
      <Amount>
        <MIFormattedCurrency value={amount} />
        {!!fee && (
          <Fee>
            + <MIFormattedCurrency value={fee} />
          </Fee>
        )}
      </Amount>
    </PaymentItemContainer>
  );
};

export default PaymentItem;

type DeliveryDateProps = {
  deliveryEta?: any;
  isFastPayment: boolean;
  selectedDeliveryOption?: DeliveryOptionType | null;
};

type DeliveryDateTextProps = {
  selectedDeliveryOption: DeliveryOptionType | null;
};

const DeliveryDateText = ({ selectedDeliveryOption }: DeliveryDateTextProps) => {
  if (selectedDeliveryOption?.type === CONSTS.DELIVERY_TYPE.VIRTUAL) {
    return (
      <PlaceholderText>
        <MIFormattedText label="batchPayment.deliveryDayVirtual" />
      </PlaceholderText>
    );
  }

  if (!selectedDeliveryOption?.deliveryDate) {
    return (
      <PlaceholderText>
        <MIFormattedText label="batchPayment.deliveryDayPlaceholder" />
      </PlaceholderText>
    );
  }

  if (isSameDay(selectedDeliveryOption?.deliveryDate)) {
    return <MIFormattedText label="batchPayment.date.today" />;
  }

  return isTomorrow(selectedDeliveryOption?.deliveryDate) ? (
    <MIFormattedText label="batchPayment.date.tomorrow" />
  ) : (
    <DeliveryDateFormat
      date={selectedDeliveryOption?.deliveryDate}
      maxDate={selectedDeliveryOption?.maxDeliveryDate}
    />
  );
};

const DeliveryDate = ({
  deliveryEta,
  selectedDeliveryOption,
  isFastPayment,
}: DeliveryDateProps) => {
  const [showHolidaysWarningLD] = featureFlags.useFeature('us-holidays-checks');

  return (
    <DeliveryDateContainer isDateSelected={deliveryEta} isFastPayment={isFastPayment}>
      {showHolidaysWarningLD &&
        !isFastPayment &&
        selectedDeliveryOption?.type === CONSTS.DELIVERY_TYPE.CHECK && (
          <HolidaysWarning
            tooltipLabel="holidaysWarning.tooltipLabels.default"
            variant={HOLIDAYS_WARNING_ICON_VARIANT.WARNING}
            customIconStyles={{ position: 'relative', bottom: '0.2rem' }}
          />
        )}
      <DeliveryDateText selectedDeliveryOption={selectedDeliveryOption as DeliveryOptionType} />
    </DeliveryDateContainer>
  );
};

const PaymentItemContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 2.4rem 2rem;
  border-radius: 0.8rem;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: ${(props) => `0 0.5rem 1.5rem 0 ${props.theme.colors.dark.translucent1}`};
  padding: 1.5rem 2rem;
  transition-property: box-shadow;
  transition-duration: ${(props) => props.theme.animation.transition.default};
  height: 6rem;
  min-height: 6rem;
  width: 100%;
  user-select: none;
  margin-bottom: 1.2rem;
  position: relative;
  &:hover {
    box-shadow: ${(props) => `0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2}`};
  }

  &:after {
    ${({ theme, isSelected }) =>
      isSelected &&
      `
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 0.2rem solid ${theme.colors.progress.done};
      pointer-events: none;
      border-radius: 0.8rem;`}
  }
`;

const Cell = css`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.text.weight.normal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.text.color.main};
  line-height: 2rem;
  min-width: 11.7rem;
  box-sizing: border-box;
  margin-right: 2rem;
`;

const PlaceholderText = styled.div`
  color: ${(props) => props.theme.text.color.label};
  display: flex;
  font-size: ${(props) => props.theme.text.size.wizardText};
  padding: 0 0.1rem;
`;

const Vendor = styled.div`
  ${Cell};
`;

const DeliverySpeedCell = styled.div`
  ${Cell};
  display: flex;
`;

const DeliveryDateContainer = styled.div<DeliveryDateProps & { isDateSelected?: boolean }>`
  ${Cell};
  min-width: 13.7rem;
  margin-right: 0;
  font-style: ${({ isDateSelected }) => (isDateSelected ? 'normal' : 'italic')};
  ${({ isFastPayment, theme }) => isFastPayment && `color: ${theme.text.color.highlight}`}
`;

const Image = styled.img`
  height: 2rem;
  margin-right: 0.8rem;
`;

const TableDatePickerContainer = styled.div`
  min-width: 11.7rem;
  max-width: 11.7rem;
  margin-right: 2rem;
`;

const PaymentMethod = styled.div`
  ${Cell};
  display: flex;
  align-items: center;
`;

const DeliveryMethod = styled.div`
  ${Cell};
`;

const Amount = styled.div`
  ${Cell};
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 1rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const Fee = styled.div`
  color: ${({ theme }) => theme.text.color.subtitle};
  ${({ theme }) => theme.text.fontType.hint};
`;

const PaymentMethodIcon = styled.i`
  height: 2rem;
  width: 2rem;
  font-size: ${(props) => props.theme.text.size.regular};
  margin-right: 0.8rem;
  > img {
    height: 2rem;
  }
`;

const PaymentMethodCardIcon = styled.i`
  font-size: 1.7rem;
  padding: 0;
  margin-right: 0.8rem;
  width: 2rem;
`;

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: start;
`;
