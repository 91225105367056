import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/app/hoc/useApi';
import organizationApi from 'src/app/services/api/organizations';
import { getOrgId } from 'src/app/redux/user/selectors';
import useLinkIntuitAccount from './useLinkIntuitAccount';

type CreateAndLinkIntuitAccountParams = {
  name: string;
  type: string;
  fundingSourceId: string;
  orgId?: string;
};

const useCreateAndLinkIntuitAccount = () => {
  const currentOrgId = useSelector(getOrgId);
  const [createIntuitAccount, , isCreating] = useApi<[string, { name: string; type: string }], any>(
    organizationApi.createIntuitAccount
  );
  const { linkIntuitAccount, isIntuitAccountLinking } = useLinkIntuitAccount();

  const createAndLinkIntuitAccount = useCallback(
    async ({ name, type, fundingSourceId, orgId }: CreateAndLinkIntuitAccountParams) => {
      const newIntuitAccount = await createIntuitAccount(currentOrgId || (orgId as string), {
        name,
        type,
      });

      await linkIntuitAccount({
        intuitAccountId: newIntuitAccount.Account.Id,
        fundingSourceId,
      });

      return newIntuitAccount;
    },
    [createIntuitAccount, linkIntuitAccount, currentOrgId]
  );

  const isIntuitAccountCreatingAndLinking = isIntuitAccountLinking || isCreating;

  return { createAndLinkIntuitAccount, isIntuitAccountCreatingAndLinking };
};

export default useCreateAndLinkIntuitAccount;
