import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { MICardForm } from 'src/app/components/common/MICard';
import { useDispatch, useSelector } from 'react-redux';
import { MIFormattedText } from 'src/app/utils/formatting';
import { DIALOG_VARIANTS, CARD_TYPES, DIALOG_TYPE } from 'src/app/utils/consts';
import {
  DeleteFundingSourceErrorData,
  EditFundingSourceLabelState,
} from 'src/app/pages/settings/PaymentMethodsSettingsPageContainer';
import { AreaLoader } from '@melio/billpay-design-system';
import VerifyMicroDeposits from 'src/app/components/micro-deposits/VerifyMicroDeposits';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { useFundingSourceMicroDepositState } from 'src/app/pages/settings/hooks/useFundingSourceMicroDeposits';
import { useFundingSourceEditLabel } from 'src/app/pages/settings/hooks/useFundingSourceEditLabel';
import FundingSourcesListLayout from 'src/app/components/common/SelectMethods/containers/QboFundingSourcesListLayout';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { useBenefits } from 'src/app/pages/bill/pay/hooks/useBenefits';
import { CreditCardBenefitsModal } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitsModal';
import { useDebitFee } from 'src/app/pages/bill/pay/hooks/useDebitFee';
import { flagsStore } from 'src/app/modules/flags/flags-store';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { getPaymentMethodName } from 'src/app/version-2/utils/paymentMethods.utils';

export type Props = {
  goAddFundingSources: (type: string) => void;
  onDeleteFailedError: () => void;
  onDeleteConfirmed: (source: FundingSource) => void;
  onDeletePaymentMethodCanceled: () => void;
  fundingSourceToDelete: FundingSource;
  deletingId: number | null;
  errorCode: string;
  errorData: DeleteFundingSourceErrorData;
  isSingleLoading: boolean;
  fundingSources: FundingSource[];
  onVerifyClicked: (id: number) => void;
  verifyingId: number | null;
  editingFundingSourceLabel: EditFundingSourceLabelState;
  resetFundingSourceLabelEdit: () => void;
  onVerifyFinished: () => void;
  onVerifyFailed: () => void;
  modifyActions: (accountTypes: FundingSource) => Record<string, any>;
};

const DELETE_FUNDING_SOURCE_ERROR_CODE = 'RSC03';

const getErrorDialogConfig = (errorCode: string, errorData: DeleteFundingSourceErrorData) => {
  let title = 'payments.form.deleteDialog.removalFailedTitle';
  let subtitle = `server.${errorCode}`;

  if (errorCode === DELETE_FUNDING_SOURCE_ERROR_CODE && errorData) {
    const hasFailedPayments = (errorData?.failedPayments || []).length > 0;

    title = 'payments.form.deleteDialog.failed.title';
    subtitle = hasFailedPayments
      ? 'payments.form.deleteDialog.failed.failedPaymentsSubtitle'
      : 'payments.form.deleteDialog.failed.scheduledPaymentsSubtitle';
  }

  return { title, subtitle };
};

const PaymentMethodsSettingsPage = ({
  deletingId,
  errorCode,
  errorData,
  isSingleLoading,
  onDeletePaymentMethodCanceled,
  onDeleteFailedError,
  onDeleteConfirmed,
  fundingSourceToDelete,
  fundingSources,
  verifyingId,
  editingFundingSourceLabel,
  resetFundingSourceLabelEdit,
  onVerifyFinished,
  onVerifyFailed,
  onVerifyClicked,
  modifyActions,
  goAddFundingSources,
}: Props) => {
  const microDepositEventPage = 'settings-verify-manual-account';
  const microDepositProps = {
    key: verifyingId,
    fundingSourceId: verifyingId as number,
  };
  const dispatch = useDispatch();
  const [state, actions]: any = useFundingSourceMicroDepositState(
    microDepositEventPage,
    microDepositProps
  );
  const { shouldShowBenefitsModal, onBenefitsClicked, onCloseBenefitsModal } = useBenefits();
  const isInternationalEntryPoint = useSelector(
    (state) => state['flags'].isInternationalEntryPoint
  );
  const wasInternationalEntryPoint = useMemo(() => !!isInternationalEntryPoint, []);

  useEffect(() => {
    if (isInternationalEntryPoint) {
      dispatch(flagsStore.actions.setIsInternationalEntryPoint(false));
    }

    return () => {
      if (wasInternationalEntryPoint)
        dispatch(flagsStore.actions.setIsInternationalEntryPoint(true));
    };
  }, []);

  const [EditPaymentMethodLabelModal] = useFundingSourceEditLabel(
    editingFundingSourceLabel,
    resetFundingSourceLabelEdit
  );

  const debitFee = useDebitFee();

  const renderDeletePaymentMethodDialog = () => {
    let paymentMethodName = '';
    let paymentMethodNumber = '';

    if (fundingSourceToDelete.cardAccount) {
      paymentMethodName = fundingSourceToDelete.cardAccount.network;
      paymentMethodNumber = fundingSourceToDelete.cardAccount.card4digits;
    } else if (fundingSourceToDelete.bankAccount) {
      paymentMethodName =
        fundingSourceToDelete.getInstitutionName() || fundingSourceToDelete.getDisplayName();
      paymentMethodNumber = getAccountNumber4digits(fundingSourceToDelete.bankAccount);
    }

    return (
      <QBODialog
        type={DIALOG_TYPE.CONFIRM}
        variant={DIALOG_VARIANTS.ERROR}
        title="settings.paymentMethods.deleteDialog.title"
        subtitle="settings.paymentMethods.deleteDialog.subtitle"
        subtitleValues={{
          paymentMethodName: <strong>{paymentMethodName}</strong>,
          paymentMethodNumber: <strong>...{paymentMethodNumber}</strong>,
        }}
        titleValues={{
          fundingType: <MIFormattedText label="settings.paymentMethods.ach" />,
          displayName: getPaymentMethodName({ fundingSource: fundingSourceToDelete }),
        }}
        okButtonText="settings.paymentMethods.deleteDialog.confirm"
        onOkAction={() => onDeleteConfirmed(fundingSourceToDelete)}
        onCancelAction={onDeletePaymentMethodCanceled}
      />
    );
  };

  const errDialogConfig = getErrorDialogConfig(errorCode, errorData);

  return (
    <>
      {EditPaymentMethodLabelModal}
      {isSingleLoading && <AreaLoader />}
      {!isSingleLoading && (
        <>
          <>
            <MICardForm>
              <FundingSourcesListContainer>
                <FundingSourcesListLayout
                  fundingSources={fundingSources}
                  onAddMethod={goAddFundingSources}
                  onVerifyClicked={onVerifyClicked}
                  modifyFundingSourceActions={modifyActions}
                  onBenefitsClicked={onBenefitsClicked}
                  debitFee={debitFee.fee}
                  eventSource="settings"
                  billIds={[]}
                />
              </FundingSourcesListContainer>
            </MICardForm>
          </>
          {verifyingId && (
            <VerifyMicroDeposits
              {...state}
              {...actions}
              key={verifyingId}
              fundingSourceId={verifyingId}
              onVerifyFinished={onVerifyFinished}
              onVerifyFailed={onVerifyFailed}
              eventPage={microDepositEventPage}
              dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
              dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
            />
          )}
          {deletingId && fundingSourceToDelete && renderDeletePaymentMethodDialog()}

          {errorCode && (
            <QBODialog
              type={DIALOG_TYPE.ALERT}
              variant={DIALOG_VARIANTS.ERROR}
              title={errDialogConfig.title}
              subtitle={errDialogConfig.subtitle}
              onCancelAction={onDeleteFailedError}
              cancelButtonText="dialogs.buttons.close"
            />
          )}

          {shouldShowBenefitsModal && (
            <CreditCardBenefitsModal
              onButtonClick={() => goAddFundingSources(CARD_TYPES.CREDIT)}
              onCloseClick={onCloseBenefitsModal}
              shouldDisplayButtonComponent={false}
              analyticsProperties={{}}
            />
          )}
        </>
      )}
    </>
  );
};

const FundingSourcesListContainer = styled.div`
  margin-top: 1.5rem;
`;

export default PaymentMethodsSettingsPage;
