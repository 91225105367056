import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const FormTheme: ComponentMultiStyleConfig = {
  parts: ['container', 'form'],
  baseStyle: {
    form: {
      width: '100%',
    },
    container: {
      marginTop: '0 !important',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'body',
      _hidden: {
        display: 'none',
      },
    },
  },
};
