import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import get from 'lodash/get';
import ScrollToTop from 'src/app/components/layout/ScrollToTop';
import financialAccountsApi from 'src/app/services/api/financialAccounts';
import AddCardAccountsPage from './components/AddCardAccountsPage';
import locations from 'src/app/utils/locations';
import { withPreservedStateNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import { GlobalState } from 'src/app/redux/types';
import { getOrgId } from 'src/app/redux/user/selectors';
import { envApi } from 'src/app/version-2/api/env';
import { loggingApi } from 'src/app/version-2/api/loggers';

type MapStateToProps = {
  orgId: string;
};

type Props = {
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  locationState: Record<string, any>;
} & MapStateToProps;

type State = {
  isLoading: boolean;
  errorCode: string | null | undefined;
};

const eventPage = 'payment-method-credit-card';

/**
 * @note not in use yet.
 * once we have a step for selecting a bank, we should move here some of the logic of FundingSourcePageContainer
 */

class AddCardAccountsPageContainer extends React.PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      errorCode: null,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.pfReceivedMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.pfReceivedMessage, false);
  }

  onHideErrorMessageAlert = () => {
    this.setState({ errorCode: null });
  };

  pfReceivedMessage = (event) => {
    // If event origin is not from TabaPay's iframe, skip this part
    if (!get(envApi.getConfig(), 'services.tabapay.url', '').startsWith(get(event, 'origin', '')))
      return;

    try {
      if (event.data === 'Close') this.navigateOnError();

      analytics.track('taba-pay', 'iframe-response');

      if (event.data.slice(0, 7) === 'Error: ') {
        analytics.track('taba-pay', 'iframe-error-response');
        this.navigateOnError();
      }

      const [digitsAndBin, expiration, token] = event.data.split('|');

      analytics.track('taba-pay', 'iframe-response-data');

      if (!token) this.navigateOnError();

      const digits = (digitsAndBin || '0000').slice(-4);
      const cardBin = digitsAndBin.length === 10 ? digitsAndBin.substring(0, 6) : null;

      this.setState({ isLoading: true });
      financialAccountsApi
        .checkCard(this.props.orgId, { token, cardBin })
        .then(() => {
          this.setState({ isLoading: false });
          analytics.track('taba-pay', 'iframe-response-success');

          this.props.navigate(locations.Onboarding.fundingSources.card.cardholder.url(), false, {
            orgId: this.props.orgId,
            token,
            digits,
            expiration,
            redirectUrl: get(this.props, 'location.state.redirectUrl'),
            cardBin,
          });
        })
        .catch((err) => {
          this.setState({
            errorCode: get(err, 'code') || get(err, 'response.status'),
            isLoading: false,
          });
        });
    } catch (error: any) {
      analytics.track('taba-pay', 'iframe-error-response');
      loggingApi.error('AddCardAccountsPageContainer.pfReceivedMessage(): error on checking card', {
        event: JSON.stringify(event.data),
        message: 'taba-pay iframe-error-response',
        error,
      });
      this.navigateOnError();
    }
  };

  navigateOnError = () => {
    this.props.navigateToExitWithPreservedState?.();
  };

  goNext = () => {
    const { locationState } = this.props;

    analytics.track(eventPage, 'link-credit-card');
    this.props.navigate(locations.Onboarding.fundingSources.card.cardholder.url(), false, {
      ...locationState.preservedState,
    });
  };

  goExit = () => {
    const { locationState, navigate, navigateToExitWithPreservedState } = this.props;

    analytics.track(eventPage, 'exit');

    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  loadIframe = () => this.setState({ isLoading: false });

  loadIframeError = () => {
    analytics.track('taba-pay', 'iframe-error-response');
    this.navigateOnError();
    const error = new Error('iframe-error-response-test');

    loggingApi.error('AddCardAccountsPageContainer.loadIframeError(): error on loading Iframe', {
      error,
      errorInfo: 'taba-pay iframe-error-response',
    });
  };

  render() {
    const { isLoading, errorCode } = this.state;

    return (
      <ScrollToTop>
        <AddCardAccountsPage
          loadIframe={this.loadIframe}
          goNext={this.goNext}
          goExit={this.goExit}
          isLoading={isLoading}
          errorCode={errorCode}
          onError={this.loadIframeError}
          onHideErrorMessageAlert={this.onHideErrorMessageAlert}
        />
      </ScrollToTop>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

export default compose(
  withPreservedStateNavigator(),
  connect(mapStateToProps)
)(AddCardAccountsPageContainer);
