import moment from 'moment-business-days';
import React from 'react';
import { Box, Flex, Badge, Link, StackDivider, VStack } from '@melio/billpay-design-system';

import { TAG_VARIANT } from 'src/app/utils/consts';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';

import { PaymentType } from 'src/app/utils/types';
import optinDictionary from './optinDictionary';

const VirtualCardOptInContainer = ({
  isMobile,
  children,
}: {
  isMobile: boolean;
  children: React.ReactNode[];
}) => (
  <Flex
    position="relative"
    direction="column"
    bg="white"
    padding={isMobile ? '1.6rem 1.6rem 4rem' : 10}
    gridGap={isMobile ? 5 : 10}
    borderStyle="solid"
    borderWidth="0.1rem"
    borderRadius="0.8rem"
    borderColor="gray.200"
  >
    {children}
  </Flex>
);

const VirtualCardOptInContentContainer = ({ children }: { children: React.ReactNode }) => (
  <VStack
    direction="column"
    justify="center"
    align="center"
    bgColor="white"
    divider={<StackDivider borderColor="gray.100" borderStyle="solid" />}
    spacing={5}
    width="full"
  >
    {children}
  </VStack>
);

const VirtualCardOptInContentHeader = ({
  isMobile,
  isVirtualPayment,
}: {
  isMobile: boolean;
  isVirtualPayment: boolean;
}) => (
  <Flex direction="column" textAlign="center" justifyContent="center" gridGap="0.8rem">
    <Box padding="0 2rem" textStyle="h5" fontWeight="ds.semi">
      <MIFormattedText label={optinDictionary.header.title(isVirtualPayment)} />
    </Box>
    <Box textStyle={isMobile ? 'body2' : 'body3'} color="black">
      <MIFormattedText label={optinDictionary.header.body(isMobile)} />
    </Box>
  </Flex>
);

const VirtualCardOptInContentPaymentSection = ({
  payment,
  isPaymentCollected,
}: {
  payment: PaymentType;
  isPaymentCollected: boolean;
}) => (
  <Flex direction="column" gridGap="1rem" color="black" textStyle="body3">
    <Box>
      <MIFormattedText
        label={optinDictionary.paymentDetails.amount}
        values={{
          amount: <MIFormattedCurrency value={payment?.amount || ''} />,
        }}
      />
    </Box>
    <Flex direction="column">
      <Box>
        <MIFormattedText
          label={optinDictionary.paymentDetails.deliverTo}
          values={{
            vendorEmail: payment?.vendor.contactEmail,
            organizationName: payment?.organization?.companyName,
          }}
        />
      </Box>
      <Box color="gray.500">
        <MIFormattedText
          label={optinDictionary.paymentDetails.changeEmail}
          values={{
            contactLink: (
              <Link
                href={`mailto:${payment?.organization.userOrganizations?.[0]?.user?.email || ''}`}
                isExternal
              >
                <MIFormattedText
                  label={optinDictionary.paymentDetails.contact}
                  values={{
                    organizationName: payment?.organization?.companyName,
                  }}
                />
              </Link>
            ),
            organizationName: payment?.organization?.companyName,
          }}
        />
      </Box>
    </Flex>
    <Box>
      <MIFormattedText
        label={optinDictionary.paymentDetails.ETA}
        values={{
          paymentETA: isPaymentCollected ? (
            <MIFormattedText label={optinDictionary.paymentDetails.ETAValue} />
          ) : (
            <MIFormattedDate date={moment(payment?.scheduledDate).businessAdd(3)} />
          ),
        }}
      />
    </Box>
  </Flex>
);

const VirtualCardOptInContentPosHeader = ({ isMobile }: { isMobile: boolean }) => (
  <Flex direction="column" gridGap={2} color="black">
    <Flex
      direction={isMobile ? 'column-reverse' : 'row'}
      gridGap={1}
      alignItems={isMobile ? 'start' : 'center'}
    >
      <Box color="black" textStyle="body2" fontWeight="ds.semi">
        <MIFormattedText label={optinDictionary.pos.title} />
      </Box>
      <Box>
        <Badge variant={TAG_VARIANT.WARNING}>
          <MIFormattedText label={optinDictionary.pos.tag} />
        </Badge>
      </Box>
    </Flex>
    <Box textStyle="body3">
      <MIFormattedText label={optinDictionary.pos.subtitle} />
    </Box>
  </Flex>
);

const VirtualCardOptInMobileButtonsContainer = ({ children }) => (
  <Flex
    direction="column"
    gridGap="1rem"
    position="sticky"
    left="0"
    bottom="0"
    backgroundColor="white"
    padding={4}
    boxShadow="sm"
  >
    {children}
  </Flex>
);

export {
  VirtualCardOptInContainer,
  VirtualCardOptInContentContainer,
  VirtualCardOptInContentHeader,
  VirtualCardOptInContentPaymentSection,
  VirtualCardOptInContentPosHeader,
  VirtualCardOptInMobileButtonsContainer,
};
