import React, { SyntheticEvent } from 'react';
import once from 'lodash/once';
import styled from 'styled-components';
import fileAPI from '../../../services/api/files';
import { devices } from '../../../theme/AppDevices';
import { MIFormattedText } from '../../../utils/formatting';
import dragBillImage from '../../../images/bills/drag-bill.svg';
import analytics from '../../../services/analytics';
import type { BillCreateOptionsType } from '../../../utils/types';

type Props = {
  orgId: string;
  fileId?: number | string;
  flavor?: 'block' | 'inline';
  allowedTypes?: string[];
  onAddAnother?: () => void;
  onRemove?: (fileId?: number | string) => void;
  options?: BillCreateOptionsType[];
  onUploadStart?: () => void;
  onUploadComplete: (file: File, orgId: string) => void;
  onUploadError: () => void;
};

type State = {
  attachmentURL: string;
};

const eventPage = 'bill-create';

class BillCreateOptions extends React.PureComponent<Props, State> {
  static defaultProps = {
    fileId: undefined,
    flavor: 'block',
    allowedTypes: ['image/png', 'image/jpeg', 'application/pdf'],
    options: [],
    onUploadStart: () => null,
    onAddAnother: () => null,
    onRemove: () => null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      attachmentURL: '',
    };

    this.fileInputRef = React.createRef();
    this.scanFileInputRef = React.createRef();
  }

  componentDidMount() {
    this.fetchFile();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.fileId !== prevProps.fileId) {
      this.fetchFile();
    }
  }

  onDrop = (event: any) => {
    event.preventDefault();

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
        const item = event.dataTransfer.items[i];

        if (item.kind === 'file') {
          this.handleFile(item.getAsFile());
        }
      }
    }
  };

  onDragEnter = (event: any) => {
    event.preventDefault();
  };

  onDragOver = (event: any) => {
    event.preventDefault();
  };

  onChangeInputFile = ({ currentTarget: target }: SyntheticEvent<HTMLInputElement>) => {
    if (target.files) {
      analytics.track(eventPage, 'file-file-selected');

      for (let i = 0; i < target.files.length; i += 1) {
        this.handleFile(target.files[i]);
      }
    }
  };

  fetchFile() {
    if (this.props.fileId !== undefined) {
      fileAPI.fetchFileUrls(this.props.orgId, this.props.fileId).then(this.handleFileFetched);
    }
  }

  handleFile = (file: File) => {
    if ((this.props.allowedTypes || []).indexOf(file.type) > -1) {
      if (this.props.onUploadStart) this.props.onUploadStart();

      fileAPI
        .uploadFile(this.props.orgId, file)
        .then(this.handleUploadComplete)
        .catch(this.props.onUploadError);
    }
  };

  handleUploadComplete = (result: Record<string, any>) => {
    if (result && result.file) {
      this.props.onUploadComplete(result.file, this.props.orgId);
    }
  };

  handleFileFetched = (data: Record<string, any>) => {
    this.setState({ attachmentURL: data.fileStorageUrl });
  };

  handleAddAnother = () => this.props.onAddAnother && this.props.onAddAnother();

  handleRemove = () => this.props.onRemove && this.props.onRemove(this.props.fileId);

  handleAttachmentClick = () => {
    window.open(this.state.attachmentURL);
  };

  // TODO: this is a workaround, because, for some reason, each click on Camera or File button
  // generated two click events
  doOnceEvent = once((isScan?) =>
    isScan
      ? analytics.track(eventPage, 'scan-image-button')
      : analytics.track(eventPage, 'import-from-file-button')
  );

  triggerFileInput = () => {
    this.doOnceEvent();

    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  triggerScanFileInput = () => {
    this.doOnceEvent(true);

    if (this.scanFileInputRef.current) {
      this.scanFileInputRef.current.click();
    }
  };

  fileInputRef: { current: HTMLInputElement | null };

  scanFileInputRef: { current: HTMLInputElement | null };

  renderBillCreateOptions() {
    const { allowedTypes, options } = this.props;

    return (
      <BillCreateOptionsContainer>
        {options &&
          options.map(({ id, icon, imgSrc, label, description, click }) =>
            click ? (
              <BillCreateOptionsWrapper
                key={id}
                onClick={click}
                data-testid={`bill-create-option-${label}`}
              >
                {imgSrc && <Image src={imgSrc} />}
                {icon && <Icon className={icon} />}
                <BillCreateOptionsTextBlock>
                  <LabelText>
                    <MIFormattedText label={label} />
                  </LabelText>
                  <DescriptionText>
                    <MIFormattedText label={description} />
                  </DescriptionText>
                </BillCreateOptionsTextBlock>
              </BillCreateOptionsWrapper>
            ) : (
              <BillCreateOptionsWrapper
                key={id}
                onDrop={this.onDrop}
                onDragEnter={this.onDragEnter}
                onDragOver={this.onDragOver}
                onClick={id === 'scan-bill' ? this.triggerScanFileInput : this.triggerFileInput}
                isHidden={id === 'import-bill-file'}
              >
                {imgSrc && <Image src={imgSrc} />}
                {icon && <Icon className={icon} />}
                <BillCreateOptionsTextBlock>
                  <LabelText>
                    <MIFormattedText label={label} />
                  </LabelText>
                  <DescriptionText>
                    <MIFormattedText label={description} />
                  </DescriptionText>
                </BillCreateOptionsTextBlock>
                <FileInput
                  ref={id === 'scan-bill' ? this.scanFileInputRef : this.fileInputRef}
                  onChange={this.onChangeInputFile}
                  type="file"
                  accept={id === 'scan-bill' ? 'image/*' : (allowedTypes || []).join(', ')}
                />
              </BillCreateOptionsWrapper>
            )
          )}
      </BillCreateOptionsContainer>
    );
  }

  renderDropBillCreateOption() {
    const { flavor, allowedTypes } = this.props;

    return (
      <>
        <DragAndDropWrapper
          withUpload
          flavor={flavor}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragOver={this.onDragOver}
          onClick={this.triggerFileInput}
        >
          <img src={dragBillImage} alt="bills.new.dragAndDrop" />

          <span className="noDragDrop">
            <MIFormattedText label="bills.new.dragAndDropMobile" />
          </span>

          <span className="okDragDrop">
            <MIFormattedText
              label="bills.new.dragAndDrop"
              values={{
                browse: (
                  <BrowseWrapper>
                    <MIFormattedText label="bills.new.browse" />
                  </BrowseWrapper>
                ),
              }}
            />
          </span>
        </DragAndDropWrapper>
        <FileInput
          ref={this.fileInputRef}
          onChange={this.onChangeInputFile}
          type="file"
          accept={(allowedTypes || []).join(', ')}
        />
      </>
    );
  }

  renderAttachment() {
    const { fileId, flavor } = this.props;
    const { attachmentURL } = this.state;

    return (
      <DragAndDropWrapper withAttachment fileId={fileId} flavor={flavor}>
        <Attachment src={attachmentURL} onClick={this.handleAttachmentClick} />
        <ActionButtons>
          <AddAnother onClick={this.handleAddAnother}>
            <MIFormattedText label="bills.form.attachment.addAnother" />
          </AddAnother>
          <Remove onClick={this.handleRemove}>
            <MIFormattedText label="bills.form.attachment.remove" />
          </Remove>
        </ActionButtons>
      </DragAndDropWrapper>
    );
  }

  render() {
    const { fileId, options } = this.props;

    if (fileId !== undefined) {
      return this.renderAttachment();
    }

    if (options && options.length) {
      return this.renderBillCreateOptions();
    }

    return this.renderDropBillCreateOption();
  }
}

export default BillCreateOptions;

const DragAndDropWrapper = styled.div<{
  flavor?: string;
  withProgress?: boolean;
  withUpload?: boolean;
  withAttachment?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) =>
    props.withProgress ? props.theme.text.size.hint : props.theme.text.size.regular};
  box-sizing: border-box;
  background-color: ${(props) => (props.withAttachment ? 'white' : 'transparent')};
  text-align: ${(props) => (props.withAttachment ? 'left' : 'center')};
  cursor: ${(props) => props.withUpload && 'pointer'};

  ${(props) => {
    if (props.flavor === 'block') {
      return `
        padding: 4.6rem 4.6rem 6rem 4.6rem;
      `;
    }

    return `
      padding: 2.3rem;
    `;
  }}

  i {
    display: inline-block;
    vertical-align: middle;
    font-size: 2rem;
    margin-right: 1rem;
  }

  .okDragDrop {
    display: none;
    font-size: 1.2rem;
    line-height: 2.5rem;
    margin-top: 2.2rem;
  }

  .noDragDrop {
    display: inline;
  }

  img {
    width: 3.5rem;
    height: 3.9rem;
    margin: 0 auto;
  }

  @media ${devices.desktop}, ${devices.tablet} {
    .okDragDrop {
      display: inline;
    }

    .noDragDrop {
      display: none;
    }
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0;

    .noDragDrop {
      padding-top: 1rem;
    }
  }
`;

const Attachment = styled.img`
  max-width: 30%;
  max-height: 14.8rem;
  cursor: pointer;
`;

const ActionButtons = styled.div`
  position: absolute;
  top: 2.3rem;
  right: 2.3rem;
  width: 100%;
  text-align: right;

  div {
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.12rem;
    font-weight: ${(props) => props.theme.text.weight.semiBold};
    font-size: 1.3rem;
  }
`;

const AddAnother = styled.div`
  margin-right: 1rem;
`;

const Remove = styled.div`
  color: ${(props) => props.theme.text.color.red};
`;

const BrowseWrapper = styled.span`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.primary.opaque};
`;

const FileInput = styled.input`
  visibility: hidden;
  position: absolute;
  height: auto;
  width: auto;
`;

const Image = styled.img`
  height: 3.4rem;
  width: 3.4rem;
`;

const Icon = styled.i`
  font-size: 3.4rem;
  color: ${(props) => props.theme.colors.icons.card};
`;

const LabelText = styled.div`
  line-height: 3.2rem;
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};

  ${(props) =>
    props.theme?.pages?.NewBillCreateOptionsPage?.BillCreateOptions?.MIRadioCardLabelText}
`;

const DescriptionText = styled.div`
  line-height: 1rem;
  color: ${(props) => props.theme.text.color.label};
  font-size: 1.2rem;
  text-overflow: ellipsis;

  ${(props) =>
    props.theme?.pages?.NewBillCreateOptionsPage?.BillCreateOptions?.MIRadioCardDescriptionText}
`;

const BillCreateOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: ${(props) => `0 0.5rem 1.5rem 0 ${props.theme.colors.dark.translucent1}}`};
  width: 100%;
  max-width: 45rem;
  border-radius: 0.8rem;
  padding: 3.5rem 2.4rem;
  box-sizing: border-box;
  cursor: pointer;
  background-color: white;
  margin-bottom: 1.5rem;
  transition-property: box-shadow;
  transition-duration: ${(props) => props.theme.animation.transition.default};

  &:hover:not(:active) {
    box-shadow: ${(props) => `0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2}}`};
  }

  ${(props) =>
    props.theme?.pages?.NewBillCreateOptionsPage?.BillCreateOptions?.BillCreateOptionsWrapper}
`;

const BillCreateOptionsTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
`;

const BillCreateOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  align-items: center;

  ${(props) =>
    props.theme?.pages?.NewBillCreateOptionsPage?.BillCreateOptions?.BillCreateOptionsContainer}
`;
