export const enum RegistrationOriginEnum {
  APP = 'app',
  LANDING = 'landing',
  QUICKBOOKS = 'quickbooks',
  QUICKBOOKS_MOBILE = 'quickbooksMobile',
  QUICKBOOKS_ROCKETMAN_IOS = 'quickbooksRocketmanIOS',
  QUICKBOOKS_ROCKETMAN_ANDROID = 'quickbooksRocketmanAndroid',
  QUICKBOOKS_QBM_IOS = 'quickbooksQbmIOS',
  QUICKBOOKS_QBM_ANDROID = 'quickbooksQbmAndroid',
  LANDING_NO_VERIFICATION = 'landingNoVerification',
  LANDING_WITH_PROMO = 'landingWithPromo',
  ADD_COMPANY = 'addCompany',
  SYNGENCY_INTEGRATION = 'syngency',
}
