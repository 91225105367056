import * as React from 'react';
import styled from 'styled-components';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import { useBreak } from 'src/app/hoc';
import partnersIcon from 'src/app/images/outside/trusted.png';
import partnersIconMobile from 'src/app/images/outside/trustedMobile.png';
import { SupportLine } from './SupportLine';

type OutsideFooterProps = {
  showPartners?: boolean;
  showSupport?: boolean;
  showLinks?: boolean;
  withEmailHighlighting?: boolean;
};

const OutsideFooter = ({
  showPartners = true,
  showSupport = true,
  showLinks = true,
  withEmailHighlighting = false,
}: OutsideFooterProps) => {
  const site = useSiteContext();
  const device = useBreak();

  return (
    <OutsideFooterContainer>
      {showPartners && (
        <>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.trustedBy" />
          <PartnersImage
            src={device.isMobile || device.isPhablet ? partnersIconMobile : partnersIcon}
            alt="partners"
          />
        </>
      )}
      {showSupport && (
        <SupportLine __css={supportLineStyle} withHighlighting={withEmailHighlighting} />
      )}
      {showLinks && (
        <LinksContainer>
          <FooterLink href={site.config.agreementLinks.privacyPolicy}>
            <MIFormattedText label="vendors.addDeliveryMethodByLink.privacyPolicy" />
          </FooterLink>
          <TextSeparator>|</TextSeparator>
          <FooterLink href={site.config.agreementLinks.userAgreement}>
            <MIFormattedText label="vendors.addDeliveryMethodByLink.termsOfService" />
          </FooterLink>
        </LinksContainer>
      )}
    </OutsideFooterContainer>
  );
};

export default OutsideFooter;

const OutsideFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: ${(props) => props.theme.background.wizard};
  }

  @media ${devices.mobile} {
    margin-bottom: 5rem;
  }
`;

const FooterLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  text-decoration: none;
  color: ${(props) => props.theme.text.color.subtitle};
`;

const LinksContainer = styled.div`
  display: flex;
`;

const TextSeparator = styled.span`
  margin: 0 1rem;
`;

const PartnersImage = styled.img`
  width: 45rem;
  margin: 2rem 0;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 32.5rem;
  }
`;

const supportLineStyle = {
  mb: '1.2rem',
};
