import { AreaLoader } from '@melio/billpay-design-system';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Switch, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import {
  FastAchMethodPage,
  OfferExpiredPage,
  RtpMethodPage,
  SelectMethodPage,
  SuccessPage,
  upgradePaymentActions,
} from 'src/app/version-2/pages/vendor-upgrade-payment';
import { upgradePaymentLocations as locations } from './locations';

export const UpgradePaymentRouter = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const match = useRouteMatch<{ token: string }>();
  const token = match.params?.token || '';

  useEffect(() => {
    dispatch(
      upgradePaymentActions.getPaymentUpsellDetailsAndNavigate({
        token,
        history,
      })
    );

    return () => {
      dispatch(upgradePaymentActions.resetState());
    };
  }, []);

  return (
    <Switch>
      <SmartRoute path={locations.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={locations.selectMethod} exact>
        <SelectMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.fastAchMethod} exact>
        <FastAchMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.rtpMethod} exact>
        <RtpMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.success} exact>
        <SuccessPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.offerExpired} exact>
        <OfferExpiredPage token={token} />
      </SmartRoute>
    </Switch>
  );
};
