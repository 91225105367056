import { postRequest } from 'src/app/version-2/externals';
import { FetchRefundStatusRequest } from 'src/app/version-2/pages/refund/api/requests';
import { FetchRefundStatusResponse } from 'src/app/version-2/pages/refund/api/responses';

export const refundApi = {
  refundPayment: ({
    orgId,
    paymentId,
    ...params
  }: FetchRefundStatusRequest): Promise<FetchRefundStatusResponse> =>
    postRequest<FetchRefundStatusResponse>(`/orgs/${orgId}/payments/${paymentId}/refund`, params),
};
