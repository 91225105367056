import api from 'src/app/services/api/bills';
import { createBatchUpdateSlice } from 'src/app/helpers/redux/restBatchUpdateSlice';
import { PaymentCreateFlowOriginEnum } from 'src/app/version-2/model/enums';
import { name } from './consts';

type Params = {
  orgId: string;
  items: {
    id?: string;
    amount?: string;
  }[];
  intuitAccountId?: string;
  createOrigin?: PaymentCreateFlowOriginEnum;
};

const batchMarkAsPaidApi = (params: Params) =>
  api.batchMarkAsPaid({
    orgId: params.orgId,
    items: params.items,
    intuitAccountId: params.intuitAccountId,
    createOrigin: params.createOrigin,
  });

const batchMarkAsPaidSlice = createBatchUpdateSlice({
  storeName: name,
  api: batchMarkAsPaidApi,
  actionName: 'BATCH_MARK_AS_PAID',
});

export default batchMarkAsPaidSlice;
