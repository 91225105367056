import { deleteRequest, fetchRequest, postRequest, putRequest } from './api';

export default {
  getVendors({
    orgId,
    start = 0,
    limit = 0,
    filters = {},
    shouldMelioMeVendorHasDeliveryMethod = false,
  }) {
    const url = `/orgs/${orgId}/vendors`;

    return fetchRequest(url, {
      start,
      limit,
      shouldMelioMeVendorHasDeliveryMethod,
      ...filters,
    });
  },

  validateVendorName(orgId, params) {
    const url = `/orgs/${orgId}/vendors/validateVendorName`;

    return postRequest(url, params);
  },

  createVendor(orgId, params) {
    const url = `/orgs/${orgId}/vendors`;

    return postRequest(url, params);
  },

  createOwnedVendor(orgId) {
    const url = `/orgs/${orgId}/vendors/create-owned`;

    return postRequest(url);
  },

  getVendorById({ orgId, id, options = {} }) {
    const url = `/orgs/${orgId}/vendors/${id}`;

    return fetchRequest(url, null, options);
  },

  editVendorById({ orgId, id, params }) {
    const url = `/orgs/${orgId}/vendors/${id}`;

    return putRequest(url, params);
  },

  editVendorEmailWithToken(params) {
    const url = `/vendor/edit-contact-email`;

    return putRequest(url, params);
  },

  deleteVendorById(orgId, id) {
    const url = `/orgs/${orgId}/vendors/${id}`;

    return deleteRequest(url);
  },

  setHandle(orgId, id, params) {
    const url = `/orgs/${orgId}/vendors/${id}/handle`;

    return postRequest(url, params);
  },

  getVendorsDeliveryMethods(orgId) {
    const url = `/orgs/${orgId}/vendors/delivery-methods`;

    return fetchRequest(url);
  },

  getOwnedVendorExists({ orgId, id }) {
    const url = `/orgs/${orgId}/vendors/${id}/ownedVendor`;

    return fetchRequest(url).then((res) => res.result);
  },

  checkVendorPaymentPreferences({ orgId, id }) {
    const url = `/orgs/${orgId}/vendors/${id}/payment-preferences`;

    return fetchRequest(url);
  },

  getIntuitAcctNum(orgId, id) {
    const url = `/orgs/${orgId}/vendors/${id}/vendor-intuit-acct-num`;

    return fetchRequest(url);
  },

  getVendorMccCode(orgId, vendorId) {
    const url = `/orgs/${orgId}/vendor-traits/mcc-code?vendorId=${vendorId}`;

    return fetchRequest(url);
  },

  updateVendorMccCode(orgId, vendorId, mccCode) {
    const url = `/orgs/${orgId}/vendor-traits/mcc-code`;

    return putRequest(url, { vendorId, mccCode });
  },

  getIsFirstPayment(orgId, vendorId) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/is-first-payment`;

    return fetchRequest(url, { vendorId });
  },
};
