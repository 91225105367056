import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import billsApi from 'src/app/services/api/bills';
import { ModalMessageStyled } from './DeletePaymentModal.styled';
import { DeletePaymentModal, DeletePaymentModalTitle } from './DeletePaymentModal';
import { useLoadDashboardList } from '../useLoadDashboardList';
import useDashboardListParams from '../useDashboardListParams';

type Response = [React.ReactNode, () => void];

type Props = {
  billId: string;
  companyName: string;
  amount?: number | null;
};

const useDeletePaymentModal = ({ billId, companyName, amount }: Props): Response => {
  const orgId = useSelector(getOrgId) as string;

  const [showModal, setShowModal] = useState<boolean>(false);
  const { loadDashboardList } = useLoadDashboardList();
  const [listParams] = useDashboardListParams();

  const reset = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const deletePayment = () => {
    billsApi.deleteBillById(orgId, billId).then(() => {
      setShowModal(false);
      loadDashboardList(listParams);
    });
  };

  const deletePaymentModal = showModal ? (
    <ModalMessageStyled
      titleComponent={<DeletePaymentModalTitle />}
      contentComponent={
        <DeletePaymentModal
          onCancel={reset}
          onDelete={deletePayment}
          billId={billId}
          companyName={companyName}
          amount={amount}
        />
      }
      onCloseClick={reset}
    />
  ) : (
    <></>
  );

  return [deletePaymentModal, handleOpenModal];
};

export default useDeletePaymentModal;
