import React, { useEffect, useState } from 'react';
import analytics from 'src/app/services/analytics';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { RefundReasonEnum } from 'src/app/version-2/model/enums';
import { useIntl } from 'react-intl';
import MISingleSelect from 'src/app/components/common/MISingleSelect';
import { RequestRefundStepPropsType, Option } from './types';

const prefix = 'bills.refund.chooseReason';
const errorMessage = `${prefix}.dropdownError`;

const RequestRefundReason = (props: RequestRefundStepPropsType) => {
  const { goNext, goExit, bill, payment, selectedReason, setSelectedReason } = props;
  const { formatMessage } = useIntl();
  const { components } = useSiteContext();
  const [validationError, setValidationError] = useState('');
  const [inputValue, setInputValue] = useState<Option | null>(null);

  useEffect(() => {
    analytics.page('self-serve-refund', 'reason', {
      billId: bill.id,
      paymentId: payment.id,
    });
  }, []);

  const options: Option[] = Object.values(RefundReasonEnum).map((option, index) => ({
    id: option,
    value: index + 1,
    label: formatMessage({ id: `${prefix}.reasons.${option}` }),
  }));

  const onSelectReason = (result) => {
    const { value } = result;

    const option = options.find((o) => o.value === value) || null;

    setInputValue(() => option);
    setValidationError('');
    setSelectedReason(option);
  };

  const onSubmit = () => {
    if (!selectedReason) {
      setValidationError(errorMessage);
    } else {
      analytics.trackAction('reason-submit', {
        billId: bill.id,
        paymentId: payment.id,
        reason: selectedReason.id,
      });
      goNext();
    }
  };

  return (
    <components.StepLayout
      title={`${prefix}.title`}
      titleValues={{ amount: <MIFormattedCurrency value={Number(payment?.amount)} /> }}
      goExit={goExit}
      qboFooter={<QBOFooterContainer />}
      hideHeader
      onSubmit={onSubmit}
    >
      <MISingleSelect
        label={`${prefix}.dropdownTitle`}
        placeholder={`${prefix}.dropdownPlaceholder`}
        value={inputValue}
        onChange={onSelectReason}
        errorMessage={validationError}
        required
        options={options}
      />
    </components.StepLayout>
  );
};

export default RequestRefundReason;
