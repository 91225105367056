import MAPPINGS from './action-notification-mapping';
import { pushNotification } from './notificationService';
import { addFlattenedValues } from '../translation-service';
import { loggingApi } from 'src/app/version-2/api/loggers';

function constructTextValues(mapping, action, intl) {
  const textValues = { error: '' };

  if (mapping.type === 'error') {
    if (action.error.message) {
      const errorMessageId = `errors.${action.error.message}`;
      const errorMessage = intl.messages[errorMessageId];

      if (errorMessage) {
        textValues.error = intl.formatMessage({ id: errorMessageId });
      } else {
        const missingNotificationTranslation = new Error(
          `Missing translation for error toast notification: ${errorMessageId}`
        );

        loggingApi.error('notificationMiddleware.constructTextValues(): error', {
          missingNotificationTranslation,
          messageInfo: { action },
        });
      }
    }
  } else {
    addFlattenedValues(textValues, 'payload', action.payload);

    if (action.meta) {
      addFlattenedValues(textValues, 'meta', action.meta);
    }
  }

  return textValues;
}

export function notificationMiddleware(intl) {
  return () => (next) => (action) => {
    const res = next(action);

    if (action.meta?.disableNotification) {
      return res;
    }

    let mapping = MAPPINGS[action.type];

    if (!mapping && action?.error?.code === 'ERR') {
      mapping = { type: 'error', msg: 'serverErrors.ERR' };
    }

    if (mapping && !action.error?.validationErrors) {
      pushNotification({
        type: mapping.type,
        msg: mapping.msg,
        textValues: constructTextValues(mapping, action, intl),
      });
    }

    return res;
  };
}
