import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import paymentStore from 'src/app/modules/payments/payment-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import {
  PaymentType,
  VendorType,
  DeliveryMethodType,
  OrganizationType,
  VirtualCardType,
} from 'src/app/utils/types';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import shiftVirtualCardLocations from 'src/app/pages/vendor/shift-virtual-card/locations';

type Props = {
  token: string;
};

type Response = {
  vendor?: VendorType;
  payment?: PaymentType;
  organization?: OrganizationType;
  deliveryMethod?: DeliveryMethodType;
  isPaymentLoading?: boolean;
  virtualCard?: VirtualCardType;
};

const useShiftVirtualCardData = ({ token }: Props): Response => {
  const history = useHistory();
  const { paymentId } = getJWTPayload(token);

  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(payment?.vendor?.id));
  const organization: OrganizationType = useSelector(
    organizationStore.selectors.byId(payment?.organizationId)
  );
  const deliveryMethod = vendor?.deliveryMethods?.find(
    (dm) => dm.id === payment?.deliveryMethod?.id
  );

  const paymentActions = useStoreActions(paymentStore);

  const onInvalidToken = useCallback(() => {
    history.push(generatePath(shiftVirtualCardLocations.invalidToken, { token }));
  }, [history, token]);

  const fetchPaymentDetailsWithToken = useCallback(async () => {
    try {
      await paymentActions.fetchPaymentDetailsWithToken({
        token,
      });
    } catch (error) {
      onInvalidToken();
    }
  }, [token, onInvalidToken]);

  useEffect(() => {
    fetchPaymentDetailsWithToken();
  }, [fetchPaymentDetailsWithToken]);

  return {
    vendor,
    payment,
    organization,
    deliveryMethod,
    isPaymentLoading,
  };
};

export { useShiftVirtualCardData };
