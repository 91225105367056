export const BATCH_BULK_EVENT_PAGE = 'pay-bills-batch';
export const LOAD_BATCH_PAYMENTS_TABLE = 'load-batch-payments-table';
export const BULK_TOGGLE_CLICKED = 'bulk-toggle-clicked';
export const BULK_GROUPING_CLICK = 'bulk-grouping-click';
export const BULK_LATE_PAYMENT_BADGE_IN_ROW = 'late-badge-in-row';
export const GLOBAL_DEDUCTION_DATE_TYPE_UPDATE = 'update-deduction-date-type';
export const GLOBAL_DEDUCTION_DATE_CHOOSE = 'global-deduction-date-choose';
export const GLOBAL_DEDUCTION_DATE_UPDATE = 'global-update-deduction-date';
export const OPEN_SELECT_FUNDING_SOURCE = 'open-select-funding-source';
export const ADD_SELECT_FUNDING_SOURCE = 'add-select-funding-source';
export const CHOOSE_PAYMENT_METHOD = 'choose-payment-method';
export const OPEN_SCHEDULED_DATE_CALENDAR_FROM_TABLE = 'open-scheduled-date-calendar-from-table';
export const SELECT_SCHEDULED_DATE = 'select-scheduled-date';
export const OPEN_SELECT_DELIVERY_METHOD = 'open-select-delivery-method';
export const CHOOSE_DELIVERY_METHOD = 'choose-delivery-method';
export const ADD_DELIVERY_METHOD_CLICK = 'add-delivery-method-click';
export const TOGGLE_FAST_OPTION = 'toggle-fast-option';
export const FOCUS_PAYMENT_AMOUNT = 'focus-payment-amount';
export const BILL_DETAILS_CLICK = 'view-bill-details-click';
export const ADD_MEMO_TO_VENDOR_CLICK = 'add-memo-to-vendor-click';
export const SAVE_MEMO_TO_VENDOR_CLICK = 'save-memo-to-vendor-click';
export const ADD_EMAIL_FOR_BULK_RECONCILIATION = 'add-email-for-bulk-reconciliation';
export const SAVE_EMAIL_FOR_BULK_RECONCILIATION = 'save-email-for-bulk-reconciliation';
export const AMEX_INDUSTRY_MODAL_OPEN = 'amex-industry-modal_open';
export const INTERNATIONAL_DETAILS_MODAL_OPEN = 'international-details-modal_open';
export const INTERNATIONAL_DETAILS_MODAL_SAVE = 'international-details-modal_save';
export const AMEX_INDUSTRY_MODAL_SELECT_CATEGORY = 'amex-industry-modal_select-category';
export const AMEX_INDUSTRY_MODAL_SAVE = 'amex-industry-modal_save';
export const AMEX_INDUSTRY_MODAL_ERROR = 'amex-industry-modal_error';
export const SCHEDULE_BATCH_PAYMENTS_REQUEST = 'schedule-batch-payments-request';
export const SCHEDULE_BATCH_PAYMENTS_SUCCESS = 'schedule-payments-success';
export const FAST_ACH_EXPOSURE = 'fast-ach-exposure';
export const EXPRESS_CHECK_EXPOSURE = 'express-check-exposure';
export const BATCH_SORT_BILLS = 'sort-bills';
export const REMOVE_FROM_LIST = 'remove-bill-from-batch';
export const BATCH_BULK_PAYMENT_METHOD_SELECT = 'global-payment-method-selected';
export const OPEN_SELECT_ARRIVAL_DATE = 'open-select-arrival-date';
export const FTU_FAST_PAYMENT_POPOVER_CONFIRM = 'ftu-fast-payment-popover-confirm';
export const COMBINED_COLUMNS = 'combined-columns';
export const SEPARATE_COLUMNS = 'separate-columns';
export const FACH_BATCH = 'fach-batch';
