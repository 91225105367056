import React from 'react';
import { RecordOf } from 'immutable';
import { DeliveryMethodType } from 'src/app/utils/types';

import { CONSTS } from 'src/app/utils/consts';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  method: RecordOf<DeliveryMethodType>;
  onClick: () => void;
  companyName: string;
  showDropdownSelectedMethod: boolean;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
};

function AchDeliveryMethod({
  onClick,
  method,
  isSelected,
  companyName,
  showDropdownSelectedMethod,
  optionComponent: OptionComponent,
  disabled,
}: Props) {
  if (showDropdownSelectedMethod) {
    if (method) {
      return (
        <SelectedMethod
          icon="icon-bank-icon"
          title="bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod"
          label="selectMethods.selectedDeliveryMethodLabel"
          labelValues={{
            displayName: method.getDeliveryInfo(companyName),
          }}
        />
      );
    }

    return null;
  }

  if (!method) {
    return (
      <OptionComponent
        id="ach"
        label="deliveryMethods.notDefinedInfo.ach.label"
        labelValues={{ to: '' }}
        icon="icon-bank-icon"
        description="deliveryMethods.notDefinedInfo.ach.desc"
        onClick={onClick}
      />
    );
  }

  return (
    <OptionComponent
      id={method.id}
      label="deliveryMethods.notDefinedInfo.ach.selectedLabel"
      labelValues={{
        displayName: method.getDisplayName(companyName),
      }}
      icon="icon-bank-icon"
      description="deliveryMethods.notDefinedInfo.ach.desc"
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    />
  );
}

function AchDeliverySelectedMethod({
  method,
  companyName,
}: {
  method: RecordOf<DeliveryMethodType>;
  companyName: string;
}) {
  return (
    <SelectedMethod
      icon="icon-bank-icon"
      title="bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod"
      label="selectMethods.selectedDeliveryMethodLabel"
      labelValues={{
        displayName: method.getDeliveryInfo(companyName),
      }}
    />
  );
}

AchDeliveryMethod.methodType = CONSTS.DELIVERY_TYPE.ACH;
AchDeliveryMethod.SelectedMethod = AchDeliverySelectedMethod;
AchDeliveryMethod.isDisabled = () => false;

export default AchDeliveryMethod;
