import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CONSTS } from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import { useForm } from 'src/app/ui/form';
import profileStore from 'src/app/modules/profile/profile-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { useLocationState } from 'src/app/utils/hooks';
import qbrLocations from 'src/app/pages/quickpay/locations';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { PaymentType } from 'src/app/utils/types';
import { ContactsTabEnum } from 'src/app/version-2/model/enums';

type Props = {
  vendorId: string;
  navigate: (url: string) => void;
  shouldIgnorePhone?: boolean;
};

const useQBRVirtualDeliveryMethodState = ({
  vendorId,
  navigate,
  shouldIgnorePhone = false,
}: Props) => {
  const vendorActions = useStoreActions(vendorsStore);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const paymentActions = useStoreActions(paymentsStore);
  const [exitUrl] = useLocationState('exitUrl', null);
  const { vendor, isVendorLoading } = useFetchVendor(vendorId);

  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const isVendorUpdating = (
    useSelector(vendorsStore.selectors.update.status(vendorId)) as { loading: boolean }
  )?.loading;
  const payment: PaymentType = useSelector(
    paymentsStore.selectors.quickpay.newPaymentWizard.payment
  );
  const existingVirtualDeliveryMethod = vendor?.deliveryMethods?.find(
    (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL
  );
  const isDeliveryMethodCreating = (
    useSelector(deliveryMethodsStore.selectors.create.status()) as { loading: boolean }
  )?.loading;
  const isDeliveryMethodUpdating = (
    useSelector(
      deliveryMethodsStore.selectors.update.status(existingVirtualDeliveryMethod?.id)
    ) as { loading: boolean }
  )?.loading;
  const isFormProcessing = isDeliveryMethodCreating || isVendorUpdating || isDeliveryMethodUpdating;

  const model = useMemo(
    () => ({
      deliveryType: CONSTS.DELIVERY_TYPE.VIRTUAL,
      virtualAccount: {
        email: existingVirtualDeliveryMethod?.virtualAccount?.email || vendor?.contactEmail,
        phone: shouldIgnorePhone
          ? ''
          : existingVirtualDeliveryMethod?.virtualAccount?.phone || vendor?.contactPhone,
      },
    }),
    [vendor]
  );

  const submitAction = async (value) => {
    let payload;
    const vendorData = {};

    if (existingVirtualDeliveryMethod?.id) {
      const shouldUpdateVirtualAccount =
        existingVirtualDeliveryMethod.virtualAccount?.email !== value.virtualAccount.email ||
        existingVirtualDeliveryMethod.virtualAccount?.phone !== value.virtualAccount.phone;

      if (shouldUpdateVirtualAccount) {
        ({ payload } = await deliveryMethodActions.update({
          orgId,
          vendorId,
          id: existingVirtualDeliveryMethod.id,
          deliveryMethod: value,
          ...value,
        }));
        await vendorActions.update({ orgId, id: vendorId, ...vendorData });
        await paymentActions.quickpay.newPaymentWizard.update({
          deliveryMethodId: payload.id,
          vendorId,
        });
      } else {
        payload = existingVirtualDeliveryMethod;
        await paymentActions.quickpay.newPaymentWizard.update({
          deliveryMethodId: existingVirtualDeliveryMethod.id,
          vendorId,
        });
        await vendorActions.update({ orgId, id: vendorId, ...vendorData });
      }
    } else {
      ({ payload } = await deliveryMethodActions.create({
        orgId,
        vendorId,
        ...value,
      }));
      await paymentActions.quickpay.newPaymentWizard.update({
        deliveryMethodId: payload.id,
        vendorId,
      });
    }

    const shouldUpdateVendor =
      !vendor.contactEmail || (!vendor.contactPhone && value.virtualAccount.phone);

    if (shouldUpdateVendor) {
      let vendorData = {};

      if (!vendor?.contactEmail) {
        vendorData = {
          ...vendorData,
          contactEmail: value.virtualAccount.email,
        };
      }

      if (!vendor?.contactPhone) {
        vendorData = {
          ...vendorData,
          contactPhone: value.virtualAccount.phone,
        };
      }

      await vendorActions.update({ orgId, id: vendorId, ...vendorData });
    }

    if (payment.id) {
      navigate(
        generatePath(qbrLocations.edit.confirm, {
          orgId,
          paymentId: payment.id,
        })
      );
    } else {
      navigate(generatePath(qbrLocations.create.confirm, { orgId }));
    }
  };
  const [virtualDeliveryMethodVM, { submit }] = useForm(model, {
    submit: submitAction,
  });

  const goExit = () => {
    const url =
      exitUrl ||
      locations.Vendors.view.url({
        id: vendorId,
        type: ContactsTabEnum.VENDORS,
      });

    navigate(url);
  };

  const shouldShowDeliveryMethodPagePreloader = (vendorId && !vendor) || isVendorLoading;

  return {
    virtualDeliveryMethodVM,
    submit,
    vendor,
    isFormProcessing,
    isVendorLoading: shouldShowDeliveryMethodPagePreloader,
    goExit,
  };
};

export default useQBRVirtualDeliveryMethodState;
