import { BILL_STATUS } from 'src/app/utils/consts';
import { BillType, PaymentType, TransactionType } from 'src/app/utils/types';

export const groupUnpaidAndScheduledBills = (bills: BillType[]) => {
  const failedBills: BillType[] = [];
  const scheduledBills: BillType[] = [];

  bills.forEach((bill) => {
    const { status } = bill;

    if (status === BILL_STATUS.UNPAID) {
      failedBills.push(bill);
    } else {
      scheduledBills.push(bill);
    }
  });

  return { failedBills, scheduledBills };
};

const isCheckDeposited = (transactions: TransactionType[]) =>
  transactions.length &&
  transactions.some(
    (t) => t.status === 'SYSTEM' && t.rawData && t.rawData.description === 'Posted Check'
  );

export const isPaymentDelivered = (payment: PaymentType) => {
  if (payment.status !== 'completed') {
    return false;
  }

  if (payment?.deliveryMethod?.deliveryType === 'check') {
    return isCheckDeposited(payment.transactions || []);
  }

  if (payment?.deliveryMethod?.deliveryType === 'ach') {
    return payment?.transactions?.length > 0;
  }

  return false;
};
