import { MIFormattedText } from 'src/app/version-2/externals';
import { Container, Icon, Subtitle, TextsContainer, Title } from './ReviewItem.styles';

export const ReviewItem = ({ iconClassName, titleLabel, subtitleLabel, subtitleValues }) => (
  <Container>
    <Icon className={iconClassName} />
    <TextsContainer>
      <Title>
        <MIFormattedText label={titleLabel} />
      </Title>
      <Subtitle>
        <MIFormattedText label={subtitleLabel} values={subtitleValues} />
      </Subtitle>
    </TextsContainer>
  </Container>
);
