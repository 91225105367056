import React from 'react';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  label: string;
};

const ManualAddressSubtitle: React.FC<Props> = ({ label }) => {
  const site = useSiteContext();
  const { WizardElements } = site.components;

  return (
    <WizardElements.WizardFormRow>
      <WizardElements.WizardStepSubTitle>
        <MIFormattedText label={label} />
      </WizardElements.WizardStepSubTitle>
    </WizardElements.WizardFormRow>
  );
};

export default ManualAddressSubtitle;
