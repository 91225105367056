import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { SmartIcon } from '@melio/billpay-design-system';
import analytics from 'src/app/services/analytics';
import { envApi } from 'src/app/version-2/api/env';
import { ADD_DELIVERY_METHOD_WIZARD_ORIGIN } from 'src/app/version-2/model/constants';
import { CardDetailsContainer } from 'src/app/version-2/pages/add-card-account/components/CardDetails/CardDetailsContainer';
import { ErrorMessageModal } from 'src/app/version-2/pages/add-card-account/components/ErrorMessageModal/ErrorMessageModal';
import {
  ADD_CARD_ACCOUNT_EVENT_PAGE,
  EVENT_EXIT,
  PAYMENT_METHOD_CREDIT_CARD,
} from 'src/app/version-2/pages/add-card-account/model/consts/AddCardAccountAnalytics.consts';
import { addCardAccountActions } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';
import {
  addCardAccountDeliveryMethodActions,
  addCardAccountDeliveryMethodSelectors,
} from 'src/app/version-2/pages/add-card-account/modules/deliveryMethod/addCardAccountDeliveryMethod.slice';
import {
  addCardAccountFundingSourceActions,
  addCardFundingSourceSelectors,
} from 'src/app/version-2/pages/add-card-account/modules/fundingSource/addCardAccountFundingSource.slice';
import {
  CloseButton,
  TopPanel,
} from 'src/app/version-2/pages/add-card-account/renderers/AddCardAccount.renderer.styles';

interface AddCardAccountRendererProps {
  location: {
    state: {
      redirectUrl: string;
      preservedState?: any;
      exitUrl?: string;
    };
  };
  token: string;
  isTitle?: boolean;
  isFooter?: boolean;
}

export const AddCardAccountRenderer = ({
  location,
  token,
  isTitle,
  isFooter,
}: AddCardAccountRendererProps) => {
  const dispatch = useDispatch();
  const isVendorFlow =
    location?.state?.preservedState?.origin === ADD_DELIVERY_METHOD_WIZARD_ORIGIN;

  const validationFundingSourceCardStatus = useSelector(
    addCardFundingSourceSelectors.selectValidationCardStatus
  );
  const validationDeliveryMethodCardStatus = useSelector(
    addCardAccountDeliveryMethodSelectors.selectValidationCardAccount
  );

  const history = useHistory();

  const { bt } = useBasisTheory(envApi.getConfig().services.basisTheory.key, { elements: true });

  const handleExit = () => {
    analytics.track(PAYMENT_METHOD_CREDIT_CARD, EVENT_EXIT, {
      vaulting: ADD_CARD_ACCOUNT_EVENT_PAGE,
    });

    if (location?.state?.exitUrl) {
      history.push(location.state.exitUrl);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(
      addCardAccountActions.flowState({
        isVendorFlow: location?.state?.preservedState?.origin !== ADD_DELIVERY_METHOD_WIZARD_ORIGIN,
        location,
        vendorToken: token,
      })
    );
  }, []);

  const handleCloseErrorModal = () => {
    dispatch(addCardAccountFundingSourceActions.resetValidationCardStatus());
    dispatch(addCardAccountDeliveryMethodActions.resetValidationCardStatus());
  };

  const errorMessage =
    validationFundingSourceCardStatus?.error?.code ||
    validationDeliveryMethodCardStatus?.error?.code;

  return (
    <BasisTheoryProvider bt={bt}>
      <TopPanel>
        <CloseButton onClick={handleExit} isVendorFlow={isVendorFlow}>
          <SmartIcon type="close" size="1.4rem" />
        </CloseButton>
      </TopPanel>
      <ErrorMessageModal errorMessage={errorMessage} onClose={handleCloseErrorModal} />
      <CardDetailsContainer isTitle={isTitle} isFooter={isFooter} />
    </BasisTheoryProvider>
  );
};
