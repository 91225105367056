import { fetchRequest, postRequest } from 'src/app/version-2/externals';
import { CheckCandidatesRequest } from './request/CheckCandidatesRequest';
import { GlobalOptInFormSubmitType } from './request/GlobalOptInFormSubmitType';
import { GetCandidateByTokenResponse } from './response/GetCandidateByTokenResponse';
import { GlobalOptInFormSubmitResponse } from './response/GlobalOptInFormSubmitResponse';

export const candidatesApi = {
  getCandidateByToken: ({ token }: CheckCandidatesRequest) =>
    fetchRequest<GetCandidateByTokenResponse>('/candidates', { token }),

  submitRawBusiness: (token: string, params: GlobalOptInFormSubmitType) =>
    postRequest<GlobalOptInFormSubmitResponse>(`/candidates/submit?token=${token}`, params),
};
