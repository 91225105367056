import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

import { startTrackBillPayFlow } from 'src/app/services/analytics/trackPayBillFlow';
import analytics from 'src/app/services/analytics';

import { getDeliveryMethodForPayment } from 'src/app/redux/utils';
import { setInitialRegularFlowDataAction } from 'src/app/redux/payBillWizard/actions';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { getBill } from 'src/app/redux/payBillWizard/selectors';
import { getOrgId, getFundingSources } from 'src/app/redux/user/selectors';

import { isRppsVendor } from 'src/app/pages/vendor-directory/utils';
import { getPartialBillId, hasMaximumAmountLimit } from 'src/app/utils/bills';
import globalLocations from 'src/app/pages/locations';
import locations from 'src/app/utils/locations';
import intuit from 'src/app/utils/intuit';
import { withNavigator } from 'src/app/hoc';
import { BillType, NavigateType, OrganizationPreferencesType } from 'src/app/utils/types';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { DELIVERY_TYPE, CARD_TYPES } from 'src/app/utils/consts';
import { isCardExpired } from 'src/app/utils/card';
import { getVendorType } from 'src/app/utils/vendor-utils';
import { shouldAllowEditByDeliveryType } from 'src/app/utils/payments';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { FundingSource, CardAccount } from 'src/app/version-2/model/dtos';

import { useApi } from 'src/app/hoc/useApi';
import useGetVendorPaymentPreferences from 'src/app/modules/vendors/hooks/useGetVendorPaymentPreferences';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { useLocationState } from 'src/app/utils/hooks';
import { useModal } from 'src/app/helpers/react/useModal';
import { usePartialPaymentsQualification } from 'src/app/pages/bill/hooks/usePartialPaymentsBillQualification';
import { useAmexVerification } from 'src/app/pages/bill/pay/hooks/useAmexVerification';
import { useVerifyMicroDeposits } from 'src/app/pages/bill/pay/hooks/useVerifyMicroDeposits';
import { usePartialPaymentAmount } from 'src/app/pages/bill/pay/hooks/usePartialPaymentAmount';
import { useDebitFee } from 'src/app/pages/bill/pay/hooks/useDebitFee';
import { useBenefits } from 'src/app/pages/bill/pay/hooks/useBenefits';

import billsApi from 'src/app/services/api/bills';
import accountingSoftwareSync from 'src/app/services/api/accountingSoftwareSync';
import { devices } from 'src/app/theme/AppDevices';

import { AreaLoader } from '@melio/billpay-design-system';
import ScrollToTop from 'src/app/components/layout/ScrollToTop';
import PartialPaymentAmount from 'src/app/pages/bill/pay/components/PartialPaymentAmount';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import {
  WizardStepTitle,
  WizardStepSubTitle,
  WizardInner,
  WizardFooterContainer,
} from 'src/app/components/layout/QBOWizardElements';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { ButtonText, HeaderContentIcon, settingsStyles } from 'src/app/components/layout/QBOHeader';
import QboFundingSourcesListLayout from 'src/app/components/common/SelectMethods/containers/QboFundingSourcesListLayout';
import VerifyMicroDeposits from 'src/app/components/micro-deposits/VerifyMicroDeposits';
import { CreditCardBenefitsModal } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitsModal';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { Footer } from '../PayBillFundingSourcePageContainer/Footer';
import {
  SyncBillResponse,
  GetInitialPayFlowDataParams,
  GetInitialPayFlowDataResponse,
} from './types';
import { SyncBillErrorDialog } from './SyncBillErrorDialog';
import { useBackgroundSyncEntryNavigation } from './useBackgroundSyncEntryNavigation';
import { PayBillSkeleton } from '../components/PayBillSkeleton';
import { QboSyncVersionEnum, FundingSourceTypesEnum } from 'src/app/version-2/model/enums';

const eventPage = 'pay-bill';

type Props = {
  navigate: NavigateType;
  showCCTooltip: boolean;
};

const PayBillBackgroundSyncEntryPageComposed = ({ navigate, showCCTooltip }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [historyPush, historyReplace] = useHistoryWithOrgId();
  const [originId] = useLocationState('originId');
  const [mainFlowRedirectUrl] = useLocationState('redirectUrl');
  const [mainFlowExitUrl] = useLocationState('exitUrl');
  const [mainFlowInitialPaymentAmount] = useLocationState('initialPaymentAmount');
  const [billZeroBalanceRedirectUrl] = useLocationState('billZeroBalanceRedirectUrl');
  const { goExit, goNext, goAddFundingSource } = useBackgroundSyncEntryNavigation({
    mainFlowExitUrl,
    eventPage,
    navigate,
  });
  const fundingSources = useSelector(getFundingSources);
  const billFlowData = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );

  const {
    shouldShowBenefitsModal,
    onBenefitsClicked,
    onCloseBenefitsModal,
    benefitsRelevantCreditCard,
  } = useBenefits();

  const [isInitialPayFlowDataLoading, setIsInitialPayFlowDataLoading] = useState(true);
  const [getInitialPayFlowData, initialPayFlowData, isInitialPayFlowLoading] = useApi<
    GetInitialPayFlowDataParams,
    GetInitialPayFlowDataResponse
  >(billsApi.getPayBillInitialData);
  const [selectedFundingSource, setSelectedFundingSource] = useState<FundingSource>();
  const [syncBillPromise, setSyncBillPromise] = useState<Promise<SyncBillResponse>>();
  const [isPaymentAmountInitialized, setIsPaymentAmountInitialized] = useState(false);
  const [isSyncBillFailed, setIsSyncBillFailed] = useState(false);
  const [isRedirectToNextPageInitiated, setIsRedirectToNextPageInitiated] = useState(false);

  const filteredFundingSources = fundingSources.filter(
    (fs) =>
      !(
        fs.fundingType === FundingSourceTypesEnum.CARD &&
        (!fs.isVerified || isCardExpired(fs.cardAccount as CardAccount))
      )
  );

  useEffect(() => {
    analytics.trackRoute('page-viewed');
  }, []);

  useEffect(() => {
    const getInitialBillData = async () => {
      try {
        const { melioVendor, melioBill, contractor, billInfo } = await getInitialPayFlowData({
          orgId,
          originId,
        });
        const hasInternationalDeliveryMethod = melioVendor?.deliveryMethods?.some(
          (dm) => dm.deliveryType === DELIVERY_TYPE.INTERNATIONAL
        );

        analytics.track(eventPage, 'vendor-type', {
          has_international_dm: hasInternationalDeliveryMethod,
        });

        const isInternationalVendorDisabled =
          hasInternationalDeliveryMethod &&
          !organizationPreferences.isEligibleForInternationalPayment;

        if (isInternationalVendorDisabled) {
          historyPush({
            path: locations.Bills.pay.internationalBlocked.url({ orgId, id: melioBill?.id }),
            state: { exitUrl: mainFlowExitUrl },
          });

          return null;
        }

        if (contractor) {
          historyPush({
            path: globalLocations.entrypoints.errors.contractors,
            state: { billId: melioBill?.id, exitUrl: mainFlowExitUrl },
          });

          return null;
        }

        if (hasMaximumAmountLimit(Number(billInfo.totalAmount))) {
          historyPush({
            path: globalLocations.entrypoints.errors.billAmountLimit,
            state: { exitUrl: mainFlowExitUrl },
          });

          return null;
        }

        if (isRppsVendor(melioVendor as any)) {
          historyPush({
            path: globalLocations.entrypoints.errors.directPayments,
            state: { exitUrl: mainFlowExitUrl },
          });

          return null;
        }

        if (billInfo.balance === 0 || melioBill?.balance === 0) {
          historyPush({
            path: billZeroBalanceRedirectUrl,
            state: { billId: melioBill?.id, exitUrl: mainFlowExitUrl },
          });

          return null;
        }

        return { melioVendor, melioBill, contractor, billInfo };
      } catch (error: any) {
        historyPush({
          path: globalLocations.entrypoints.errors.billSyncError,
          state: {
            errorCode: error?.code,
            vendorName: error?.responseData?.vendorName,
            amount: error?.responseData?.totalAmount,
            exitUrl: mainFlowExitUrl,
          },
        });

        return null;
      }
    };

    const handleSyncBillError = (error: any) => ({
      error: {
        errorCode: error?.code,
        vendorName: error?.responseData?.vendorName,
        amount: error?.responseData?.totalAmount,
      },
    });

    const setupDefaults = async () => {
      setIsInitialPayFlowDataLoading(true);
      const initialPayFlowData = await getInitialBillData();

      if (initialPayFlowData === null) return;

      setIsInitialPayFlowDataLoading(false);
    };

    // To handle the case when the user press back in the browser
    if (billFlowData.id) {
      historyReplace({ path: locations.Bills.pay.funding.url({ id: billFlowData.id }) });
    } else {
      startTrackBillPayFlow();
      setupDefaults();
      setSyncBillPromise(
        accountingSoftwareSync.syncBillByOriginId(orgId, originId).catch(handleSyncBillError)
      );
    }
  }, []);

  useEffect(() => {
    if (!isInitialPayFlowDataLoading) {
      intuit.endLoadingWrapper();
      intuit.hideFTULoadingWrapper();
    }
  }, [isInitialPayFlowDataLoading]);

  const initializePayBillFlowState = async ({ fundingSource }) => {
    const bill = await getSyncedBill();

    if (!bill) {
      return null;
    }

    const defaultDeliveryMethod = getDeliveryMethodForPayment({
      deliveryMethods: bill.vendor.deliveryMethods,
      fundingSource,
    });

    const initialPaymentAmount = getAmountToInitializeFlow();

    dispatch(
      setInitialRegularFlowDataAction({
        bill,
        fundingSource,
        initialPaymentAmount,
        deliveryMethod: defaultDeliveryMethod,
        redirectUrl: mainFlowRedirectUrl,
        exitUrl: mainFlowExitUrl,
      })
    );

    return { bill, defaultDeliveryMethod };
  };

  const getAmountToInitializeFlow = () => {
    const amount = Number(convertCurrencyToNumber(partialBillAmount));
    const isAmountValid = amount && amount <= billBalance;

    if (isAmountValid && billBalance !== amount) {
      analytics.track(eventPage, `payment-amount-changed`, {
        partialBillId,
        amount,
        balance: billBalance,
      });
    }

    return isAmountValid ? amount : billBalance;
  };

  const getSyncedBill = async (): Promise<BillType | undefined | null> => {
    setIsRedirectToNextPageInitiated(true);
    const syncBillResult = await syncBillPromise;

    if (syncBillResult?.error) {
      analytics.track(eventPage, 'unhandled-sync-bill-error', { orgId, originId });
      setIsRedirectToNextPageInitiated(false);
      setIsSyncBillFailed(true);
      showErrorDialog();

      return null;
    }

    return syncBillResult?.bill;
  };

  const onNext = async () => {
    const initialFlowData = await initializePayBillFlowState({
      fundingSource: selectedFundingSource,
    });

    if (!initialFlowData || !selectedFundingSource) {
      return;
    }

    const { bill, defaultDeliveryMethod } = initialFlowData;

    await goNext({ selectedFundingSource, bill, defaultDeliveryMethod });
  };

  const { melioVendor, melioBill, vendorInfo, billInfo } = initialPayFlowData || {};
  const { vendorPaymentPreferences } = useGetVendorPaymentPreferences(melioVendor?.id);
  const partialBillId = getPartialBillId(melioBill as any);
  const {
    openVerifyMicrodepositsModal,
    verifyMicrodepositsModalProps,
    shouldShowVerifyMicrodepositsModal: showVerifyMicrodepositsModal,
  } = useVerifyMicroDeposits({ partialBillId, eventPage });

  const onSelectingFundingSource = (newSelectedFundingSource: FundingSource) => {
    analytics.track(eventPage, 'change-funding-source', {
      fundingSourceId: newSelectedFundingSource.id,
      partialBillId,
    });

    if (selectedFundingSource?.id === newSelectedFundingSource.id) {
      return handleSubmit();
    }

    setSelectedFundingSource(newSelectedFundingSource);
  };

  const billBalance = billInfo?.balance || 0;
  const initialPaymentAmount = mainFlowInitialPaymentAmount || billBalance;

  const {
    partialBillAmount,
    validationErrors,
    validationErrorsValues,
    editAmount,
    onChange: onChangePaymentAmount,
  } = usePartialPaymentAmount(String(initialPaymentAmount), billBalance);

  useEffect(() => {
    onChangePaymentAmount({ value: String(initialPaymentAmount) });
  }, [initialPaymentAmount]);

  useEffect(() => {
    if (!isPaymentAmountInitialized && partialBillAmount !== '0') {
      setIsPaymentAmountInitialized(true);
    }
  }, [partialBillAmount]);

  const qboSyncVersion = melioBill
    ? melioBill.qboSyncVersion
    : QboSyncVersionEnum.BILL_CREATED_AFTER_SYNC;
  const canPartiallyPay = usePartialPaymentsQualification(qboSyncVersion);
  const internationalDeliveryMethod = melioVendor?.deliveryMethods?.find(
    (dm) => dm.deliveryType === DELIVERY_TYPE.INTERNATIONAL
  );
  const allowEdit =
    canPartiallyPay &&
    shouldAllowEditByDeliveryType(internationalDeliveryMethod as any) &&
    !isSyncBillFailed;
  const vendorType = getVendorType(melioVendor);
  const { shouldDisplayAmexVerification, openAmexModal, amexLoading, AmexModal } =
    useAmexVerification();

  const debitFee = useDebitFee();

  const [SyncBillErrorDialogElement, showErrorDialog] = useModal(SyncBillErrorDialog, {
    modalName: 'sync-bill-error-dialog',
  });

  const handleSubmit = async (fundingSource?: FundingSource) => {
    const fundingSourceToSubmit = fundingSource || selectedFundingSource;

    if (
      melioVendor &&
      (await shouldDisplayAmexVerification(fundingSourceToSubmit, melioVendor.id))
    ) {
      openAmexModal({
        vendorName: melioVendor.companyName,
        vendorId: melioVendor.id,
        bill: melioBill,
      });

      return;
    }

    if (fundingSourceToSubmit && debitFee.shouldDisplayModal(fundingSourceToSubmit)) {
      debitFee.openModal();

      return;
    }

    await onNext();
  };

  const onAddSelectedFundingSource = async (fundingSourceTypeToAdd: string) => {
    const initialFlowData = await initializePayBillFlowState({
      fundingSource: null,
    });

    if (!initialFlowData) {
      return;
    }

    const { bill, defaultDeliveryMethod } = initialFlowData;

    goAddFundingSource({ fundingSourceTypeToAdd, bill, defaultDeliveryMethod });
  };

  const shouldShowLoader =
    isInitialPayFlowDataLoading || !initialPayFlowData || !isPaymentAmountInitialized;

  if (shouldShowLoader) {
    return <PayBillSkeleton />;
  }

  if (isRedirectToNextPageInitiated) {
    return <AreaLoader placement="wizard" />;
  }

  const companyName = vendorInfo?.companyName;
  const headerValues = {
    amount: <MIFormattedCurrency value={initialPaymentAmount} />,
    companyName,
  };
  const titleValues = {
    companyName: <CompanyNameText>{companyName}</CompanyNameText>,
  };

  const formattedBillBalance = intl.formatNumber(billBalance, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const onSettingsClick = async () => {
    const initialFlowData = await initializePayBillFlowState({
      fundingSource: selectedFundingSource,
    });

    if (!initialFlowData) {
      return;
    }

    const { bill } = initialFlowData;
    const urlToReturn = locations.Bills.pay.funding.url({ id: bill.id, orgId });

    dispatch(applicationActions.setUrlToBack(urlToReturn));
    analytics.track('qbo', 'settings');
    historyPush({ path: locations.Settings.index.url() });
  };

  const onBenefitsButtonClicked = async () => {
    if (benefitsRelevantCreditCard) {
      let vendor = melioVendor;

      if (!vendor) {
        const { melioVendor: initialMelioVendor } = await getInitialPayFlowData({
          orgId,
          originId,
        });

        vendor = initialMelioVendor;
      }

      if (vendor && (await shouldDisplayAmexVerification(benefitsRelevantCreditCard, vendor?.id))) {
        openAmexModal({
          vendorName: vendor?.companyName,
          vendorId: vendor?.id,
          bill: melioBill,
        });

        return;
      }

      onSelectingFundingSource(benefitsRelevantCreditCard);

      handleSubmit(benefitsRelevantCreditCard);
    } else {
      onAddSelectedFundingSource(CARD_TYPES.CREDIT);
    }

    onCloseBenefitsModal();
  };

  const isCtaDisabled =
    !selectedFundingSource || amexLoading || !isEmpty(validationErrors) || isSyncBillFailed;
  const isCtaShown = !isEmpty(fundingSources);

  return (
    <ScrollToTop>
      <StyledQBOLayoutPage
        headerLabel="qbo.header.title"
        headerLabelValues={headerValues}
        goExit={goExit}
        relativeStep={1 / 5}
        settingsComponent={
          <SettingsComponent
            isSyncBillFailed={isSyncBillFailed}
            onSettingsClick={onSettingsClick}
          />
        }
        footer={
          <StyledFooter
            $isSyncError={isSyncBillFailed}
            shouldShowCta={isCtaShown}
            shouldShowSecurityDetails
            onSubmit={handleSubmit}
            isProcessing={amexLoading}
            isDisabled={isCtaDisabled}
          />
        }
        qboFooter={<QBOFooterContainer hasBorder={false} />}
      >
        <WizardStepTitle>
          <MIFormattedText
            label="bills.pay.fundingSource.partialPayment.title"
            values={titleValues}
          />
        </WizardStepTitle>

        <PartialPaymentAmount
          billBalance={formattedBillBalance}
          partialBillAmount={partialBillAmount}
          validationErrors={validationErrors}
          validationErrorsValues={validationErrorsValues}
          editAmount={editAmount}
          onChange={onChangePaymentAmount}
          canPartiallyPay={allowEdit}
        />

        <WizardStepSubTitle>
          <MIFormattedText label="bills.pay.fundingSource.partialPayment.subtitle" />
        </WizardStepSubTitle>

        <FundingSourcesListWraper $isSyncError={isSyncBillFailed}>
          <QboFundingSourcesListLayout
            value={selectedFundingSource}
            fundingSources={filteredFundingSources}
            onChange={onSelectingFundingSource}
            onAddMethod={onAddSelectedFundingSource}
            onVerifyClicked={openVerifyMicrodepositsModal}
            onBenefitsClicked={onBenefitsClicked}
            isVendorEnableCCPayments={isEmpty(vendorPaymentPreferences)}
            vendorType={vendorType}
            debitFee={debitFee.fee}
            showCCTooltip={showCCTooltip}
            eventSource="single-payment"
            billIds={[melioBill?.id || '']}
          />
        </FundingSourcesListWraper>

        {showVerifyMicrodepositsModal && (
          <VerifyMicroDeposits
            {...verifyMicrodepositsModalProps}
            fundingSourceId={verifyMicrodepositsModalProps.key}
          />
        )}
        {shouldShowBenefitsModal && (
          <CreditCardBenefitsModal
            onButtonClick={onBenefitsButtonClicked}
            onCloseClick={onCloseBenefitsModal}
            isLoading={amexLoading || isInitialPayFlowLoading}
            analyticsProperties={{ bill: melioBill, vendorId: melioVendor?.id }}
          />
        )}
        <AmexModal onNext={onNext} />
        <debitFee.Modal onNext={onNext} />
        {SyncBillErrorDialogElement}
      </StyledQBOLayoutPage>
    </ScrollToTop>
  );
};

const PayBillBackgroundSyncEntryPage = withNavigator()(PayBillBackgroundSyncEntryPageComposed);

export { PayBillBackgroundSyncEntryPage };

const SettingsLinkWrapper = styled.div`
  cursor: pointer;
  ${settingsStyles}
`;

const SettingsComponent = ({ onSettingsClick, isSyncBillFailed }) =>
  isSyncBillFailed ? null : (
    <SettingsLinkWrapper onClick={onSettingsClick}>
      <HeaderContentIcon className="icon-settings-icon" />
      <ButtonText>
        <MIFormattedText label="qbo.header.settings" />
      </ButtonText>
    </SettingsLinkWrapper>
  );

const commonSpacing = `
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const StyledQBOLayoutPage = styled(QBOLayoutPage)<{ isCentered?: boolean }>`
  ${WizardStepTitle} {
    margin-bottom: 0.8rem;
  }

  ${WizardInner} {
    @media ${devices.desktop}, ${devices.tablet} {
      justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
    }
  }

  ${WizardFooterContainer} {
    margin-top: 2rem;
  }

  ${WizardStepSubTitle} {
    ${commonSpacing} @media ${devices.mobile}, ${devices.phablet} {
      margin-bottom: 2rem;
    }
  }
`;

const overlayStyles = css`
  opacity: 0.6;
  background: #f4f5f8;
  pointer-events: none;
`;

const FundingSourcesListWraper = styled.div<{ $isSyncError?: boolean }>`
  ${(props) => props.$isSyncError && overlayStyles}
`;

const StyledFooter = styled(Footer)<{ $isSyncError?: boolean }>`
  ${(props) => props.$isSyncError && overlayStyles}
`;

const CompanyNameText = styled.span`
  color: ${(props) => props.theme.text.color.darkGrey};
`;
