import React from 'react';
import { Tooltip as ChakraTooltip, Placement, PositionProps } from '@chakra-ui/react';

import { useBreak } from '../../hooks/useBreak';

import { TooltipMobile } from './Tooltip.mobile';

type TooltipProps = {
  label?: React.ReactNode;
  placement?: Placement;
  isDisabled?: boolean;
  isOpen?: boolean;
  shouldWrapChildren?: boolean;
  closeDelay?: number;
  zIndex?: PositionProps['zIndex'];
};

const Tooltip: React.FC<TooltipProps> = ({ children, ...rest }) => {
  const { isMobile } = useBreak();

  if (isMobile) {
    return <TooltipMobile {...rest}>{children}</TooltipMobile>;
  }

  return (
    <ChakraTooltip {...rest} hasArrow arrowSize={5}>
      {children}
    </ChakraTooltip>
  );
};

export { Tooltip };
