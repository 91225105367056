import { AdvancedTable, Skeleton } from '@melio/billpay-design-system';
import { getTableWidth } from 'src/app/version-2/pages/batch-bulk/utils/tableWidth.utils';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
`;

export const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 4rem 0;
  overflow-y: auto;
  table {
    margin-top: 2rem;
    min-width: ${getTableWidth()};
  }
  th {
    font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
    > div {
      position: relative;
    }
  }
  tr {
    .disappearing-icon-wrapper svg {
      visibility: hidden;
      pointer-events: none;
    }
  }
  tr:hover {
    .disappearing-icon-wrapper svg {
      visibility: visible;
      pointer-events: unset;
    }
  }

  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;

export const LoaderWrapper = styled.div`
  background: ${({ theme }) => theme.colors.ds.white};
  width: ${getTableWidth()};
  flex: 1;
  box-shadow: 1rem 0 1rem -1rem ${({ theme }) => theme.colors.ds.gray[500]},
    0 1rem 1rem -1rem ${({ theme }) => theme.colors.ds.gray[500]},
    -1rem 0 1rem -1rem ${({ theme }) => theme.colors.ds.gray[500]};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: unset;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 2rem;
  background-color: ${(props) => props.theme.colors.ds.gray[100]};
  gap: 1.6rem;
`;

export const FooterButtons = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const FooterOnboardingLabel = styled.div`
  color: ${({ theme }) => theme.colors.ds.white};
  ${({ theme }) => theme.textStyles.ds.body1};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  margin-left: 1.6rem;
  cursor: pointer;
`;

export const onboardingContainerStyles = { position: 'relative' as const };

export const List = styled.div`
  flex: 1;
  font-size: 2rem;
  word-break: break-all;
`;

export const TopWhiteWrap = styled.div`
  background: ${({ theme }) => theme.colors.ds.white};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.ds.gray[500]};
  & > * :nth-child(1) {
    background: ${({ theme }) => theme.colors.ds.white};
  }
  & > * :nth-child(2) {
    // fix a bug with the topbar
    display: contents;
  }
`;

export const BatchBulkTable = styled(AdvancedTable)`
  tbody > tr.late-payment {
    background: #fff7e6;
    &:hover {
      background: #fff2d6;
    }
  }
`;

export const TableHeaderSkeleton = styled(Skeleton)`
  height: ${({ theme }) => theme.sizes.ds.xl};
  margin: 1rem 0;
  width: ${({ theme }) => theme.sizes.full};
`;
