import {
  TIMELINE_STEP_ACTIONS,
  TIMELINE_STEP_STAGES,
  TIMELINE_STEP_STATUSES,
  TIMELINE_STEP_TRANSLATION_ROOT_PATH,
} from '../consts';
import { TimelineStepType, VendorPaymentActionsType, VendorPaymentSectionProps } from '../types';
import { getScheduledStep } from './get-common-steps';
import { getPaymentDeductionStatus, getPaymentClearingStatus } from './steps-statuses';

const { FUTURE_STEP, SUCCEED, FAILED } = TIMELINE_STEP_STATUSES;
const { DEDUCTION, CLEAR } = TIMELINE_STEP_STAGES;

const getVirtualCardDeductionAction = ({
  deductionStatus,
  clearingStatus,
  baseTranslationPath,
  vendorPaymentActions,
}: {
  deductionStatus: TIMELINE_STEP_STATUSES;
  clearingStatus: TIMELINE_STEP_STATUSES;
  baseTranslationPath: string;
  vendorPaymentActions: VendorPaymentActionsType;
}): Pick<TimelineStepType, 'actionLabel' | 'actionAnalyticsName' | 'onAction'> => {
  const actionByStatus = {
    [FUTURE_STEP]: {
      actionLabel: `${baseTranslationPath}.${DEDUCTION}.action.${FUTURE_STEP}`,
      actionAnalyticsName: TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT],
    },
    [SUCCEED]:
      clearingStatus !== SUCCEED
        ? {
            actionLabel: `${baseTranslationPath}.${DEDUCTION}.action.${SUCCEED}`,
            actionAnalyticsName: TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT,
            hint: `${baseTranslationPath}.${DEDUCTION}.hint.${SUCCEED}`,
            onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT],
          }
        : {},
  };

  return actionByStatus[deductionStatus] || {};
};

const getVirtualCardClearingAction = ({
  clearingStatus,
  baseTranslationPath,
  vendorPaymentActions,
}: {
  clearingStatus: TIMELINE_STEP_STATUSES;
  baseTranslationPath: string;
  vendorPaymentActions: VendorPaymentActionsType;
}): Pick<TimelineStepType, 'actionLabel' | 'actionAnalyticsName' | 'hint' | 'onAction'> => {
  if (clearingStatus === FAILED) {
    return {
      actionLabel: `${baseTranslationPath}.${CLEAR}.action.${FAILED}`,
      actionAnalyticsName: TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT,
      hint: `${baseTranslationPath}.${CLEAR}.hint.${FAILED}`,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT],
    };
  }

  return {};
};

const getVirtualCardSteps = ({
  payment,
  vendorPaymentActions = {},
}: VendorPaymentSectionProps): TimelineStepType[] => {
  const baseTranslationPath = `${TIMELINE_STEP_TRANSLATION_ROOT_PATH}.${payment.deliveryMethod.deliveryType}`;
  const deductionStatus = getPaymentDeductionStatus(payment);
  const clearingStatus = getPaymentClearingStatus(payment);

  const getVirtualCardDeductionStep = (): TimelineStepType => ({
    title: `${baseTranslationPath}.${DEDUCTION}.title.${deductionStatus}`,
    subtitle: `${baseTranslationPath}.${DEDUCTION}.subtitle`,
    subtitleValues: { vendorEmail: payment.vendor.contactEmail },
    dates: [payment.scheduledDate],
    status: deductionStatus,
    ...getVirtualCardDeductionAction({
      deductionStatus,
      clearingStatus,
      baseTranslationPath,
      vendorPaymentActions,
    }),
  });

  const scheduledStep = { ...getScheduledStep(payment), nextStepStatus: deductionStatus };
  const deductionStep = getVirtualCardDeductionStep();

  deductionStep.nextStepStatus = clearingStatus;

  const getVirtualCardClearingStep = (): TimelineStepType => ({
    title: `${baseTranslationPath}.${CLEAR}.title.${clearingStatus}`,
    dates: [payment.deliveryEta],
    status: clearingStatus,
    ...getVirtualCardClearingAction({ clearingStatus, baseTranslationPath, vendorPaymentActions }),
  });

  const deliveryStep = getVirtualCardClearingStep();

  return [scheduledStep, deductionStep, deliveryStep];
};

export { getVirtualCardSteps };
