/* eslint-disable max-len,no-template-curly-in-string */
import merge from 'lodash/merge';
import * as qboTranslate from './en-US';

const qbrTranslate = merge({}, qboTranslate, {
  'en-US': {
    server: {
      DLM07: 'You already added this account',
    },
    general: {
      areYouSure: ' ',
      or: 'or',
    },
    progress: {
      continue: 'Save',
    },
    qbr: {
      confirm: {
        create: {
          header: 'New payment',
        },
        payment: {
          header: 'Payment',
        },
        from: 'from',
      },
      edit: {
        selectDeliveryMethod: {
          title: 'Edit payment',
        },
        date: {
          title: 'Edit date',
        },
        note: {
          title: 'Edit note',
        },
      },
      new: {
        selectDeliveryMethod: {
          title: 'New payment',
        },
      },
      deliveryMethods: {
        check: {
          title: 'Paper check',
        },
      },
      success: {
        email: {
          modal: {
            title: 'Send {vendor} an email',
            subtitle:
              'When payment is processed, we’ll give them a heads up. That way they know it’s coming.',
          },
          text: 'We’ll let them know when it’s on the way.',
          saveEmail: 'Done',
          emailLabel: 'Email',
          emailPlaceholder: 'Email address',
        },
        title: 'Payment scheduled',
        subtitle:
          '<private>{amount, number, currency}</private> to {vendor} will process on {processDate}.{br}One business day later, your balance will update.{br}{br} We’ll send them an email when it’s on the way.',
        subtitleNoEmail:
          '<private>{amount, number, currency}</private> to {vendor} will process on {processDate}.{br}One business day later, your balance will update.{br}{br} Want to update them when it does?',
        gotIt: 'Got it',
        done: 'Done',
        emailThem: 'Let them know',
        amount: 'Amount',
        to: 'To',
        toTitle: '{companyName}',
        toSubtitleAch: 'Account (...{to})',
        toSubtitleCheck: '{to}',
        toSubtitleVirtual: '{to}',
        deliveryETA: 'Delivery ETA',
        scheduledDate: 'Deduct on',
        virtualDMTitle: 'Payment{br}scheduled',
        achTitle: 'Bank transfer{br}scheduled',
        checkTitle: 'Paper check{br}scheduled',
        scheduledDateVirtual: 'Pending',
        noteVirtual: 'If they choose before {scheduledDate}, payment will deduct {etaDate}.',
        scheduledNoteVirtual:
          'To meet these dates, they need to choose how to get paid by {scheduledDate} at 9AM PT.',
      },
    },
    manualAddress: {
      confirm: {
        title: 'Let’s fix the address you entered',
        subtitle:
          'We spotted a small issue with the address you provided, and suggested an alternative below in green. Pleas select and/or edit the final version you’d like to',
      },
      invalidAddress: {
        title: 'Address couldn’t be verified',
        subtitle:
          'The address entered couldn’t be verified. Please review it below to ensure arrives at the right place.',
        invalidPOBoxSubtitle:
          'We couldn’t confirm the accuracy of this address, but that doesn’t necessarily mean it’s wrong. Please review to ensure this check arrives at the right place.',
      },
      line2Label: 'Address line 2 (optional)',
      edit: 'Edit address',
      invalidTitle: 'Address couldn’t be verified',
    },
    nav: {
      mobileHeader: {
        bills: 'Payments',
        quickpay: 'Payments',
      },
    },
    payments: {
      form: {
        deleteDialog: {
          subtitle: 'If you do, it won’t be deducted from your account or sent to {companyName}.',
          confirm: 'Yes, cancel',
          cancelText: 'No',
        },
        deleteDialogDeclined: {
          title: 'Delete payment?',
          subtitle: 'Are you sure you want to delete this payment?',
          confirm: 'Yes, delete',
          cancelText: 'No',
        },
      },
    },
    list: {
      group: {
        bill: {
          scheduled: {
            billsDueThisWeek: 'This week',
          },
        },
      },
    },
    deliveryMethods: {
      notDefinedInfo: {
        ach: {
          empty: 'Bank transfer (ACH)',
          label: 'Bank transfer to bank account ending with {displayName}',
          desc: '3 business days.',
        },
        check: {
          empty: 'Paper check',
          label: 'Send a check to {displayName}',
          desc: '5-7 business days.',
        },
        infoText: 'Your contacts don’t need to sign up or pay any fees.',
      },
    },
    vendors: {
      deliveryMethods: {
        bank: {
          header: {
            title: 'Bank transfer',
          },
          title: 'What’s their routing and account number?',
          sectionTitle: 'We’ll store it for next time.',
          routingNumber: 'Routing number',
          accountNumber: 'Account number ',
          save: 'Next',
          security: 'Your info is protected by encryption',
        },
        check: {
          header: {
            title: 'Check in the mail',
          },
          title: 'Where should we mail it?',
          submit: 'Next',
          name: {
            label: 'Name on check',
          },
        },
        virtual: {
          title: 'What’s {vendor}’s email?',
          subtitle: 'We’ll email them, so they can choose how to get paid and enter their info.',
          email: 'Email address',
          done: 'Next',
        },
      },
      addDeliveryMethodByLink: {
        includedNote: '{note}',
        subTitle: 'sent',
        selectTitle: 'How do you want to get paid?',
        footerTitle:
          'Bill Pay services powered by Melio with funds held by Evolve Bank & Trust or J.P Morgan (members of the FDIC and the Federal Reserve)',
        achCategoryLabel: 'Bank transfer (ACH)',
        checkCategoryLabel: 'Paper check',
        achHint: '3 business days',
        checkHint: '5–7 business days',
        achTitle: 'Add your bank info',
        achFormHint: 'Your info is protected by encryption',
        submitCTA: 'Done',
        checkTitle: 'How do you want to get paid?',
        zipCode: 'Zip',
        success: {
          achTitle: 'Bank transfer{br}scheduled',
          checkTitle: 'Paper check{br}scheduled',
          subtitle:
            'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>Invoice {invoiceNumber}</sb> is on its way.',
          amount: 'Amount',
          from: 'From',
          to: 'To',
          deliveryETA: 'Delivery ETA',
          amountValue: '{amount}',
          fromValue: '{from}',
          toValue: 'Account (...{to})',
          toCheckValue: '{to}',
          deliveryETAValue: '{deliveryETA}',
          info: 'Well, that was easy. Want to send payments like this? Get the business banking app that made it happen.',
          linkLabel: 'Download Money by QuickBooks',
        },
        manualAddressTitle: 'Did you mean this?',
        manualAddressSubTitle: 'Select or edit the address you want to use.',
        manualAddressCTA: 'Next',
      },
    },
    bills: {
      view: {
        title: 'Payment details',
        mobileTitle: 'Payment details',
      },
      pay: {
        confirm: {
          payTo: 'To',
          vendorField: 'Name',
          emptyMemoLabel: 'No memo to vendor',
          addMemo: 'Note (optional)',
          total: 'Amount',
          action: 'Schedule payment',
          title: 'Review and schedule',
          confirmTerms:
            'By tapping Schedule payment, I give permission to charge my Money by QuickBooks account on {date} for {amount}',
          balanceConfirm: {
            title: 'Heads up',
            description:
              'This payment exceeds your current balance. If there isn’t enough money in your account on the payment date, it won’t go through.',
            confirm: 'Schedule anyway',
            cancel: 'Edit payment',
          },
          editError: {
            description: 'Hmm, that didn’t work',
            cancel: 'Close',
          },
          completeLegalInfo: {
            title: 'One last thing...',
            subtitle: 'Before sending your first payment, let’s confirm a few last details.',
            taxIdType: {
              inputTitle: 'Select your tax ID type',
            },
          },
          balance: 'Account balance: ${qbCashBalance}',
          vendorEmail: '{vendorEmail}',
          companyName: '{companyName}',
          accountNumber: 'Account (...{accountNumber}',
          address: '{address}',
          etaDate: 'Delivery ETA: {etaDate}',
          unilateralTitle: 'Let them choose',
          unilateralSubtitle: 'We’ll email them today, so they can pick ACH or paper check.',
        },
        edit: {
          action: 'Schedule payment',
          retry: 'Retry payment',
        },
        date: {
          title: 'When do you want it deducted from your account?',
          estimatedDelivery: 'Delivery ETA',
          subtitle: ' ',
          billScheduledDate: 'Deduct on {date}',
          ach: {
            deliveryMethod: '(by 8 PM contact’s local time)',
          },
          deliveryOptions: {
            fastAchDates: {
              date: '{date}',
              free: '{date} (Free)',
            },
          },
        },
        memo: {
          title: 'Send a note with it',
          subtitle:
            'This shows up in the email they get and on their bank transaction or the memo line of their check.',
          inputTitle: 'Note (optional)',
          inputTitleValue: '{label}',
          placeholder: 'Business expense',
          counter: '{length}/{maxLength}',
          uneditablePlaceholder: 'No note to contact',
        },
        deliveryMethods: {
          title: 'How do you want to pay {vendor}?',
          subtitle: 'All options are free.',
          unilateralLink: 'Let them choose',
          unilateralHint: 'We send an email. They pick ACH or check.',
        },
        errorVendorNotFound: {
          title: 'Sorry, something went wrong',
          subtitle: 'We had trouble receiving data about the vendor',
          action: 'Ok. I‘ll check back later',
        },
      },
      form: {
        paymentActivity: {
          payFromMy: 'from',
          futureProcessedDate: 'Deduct on',
          processedDate: 'Deducted on',
          scheduledBill: {
            deliveryPaperCheckMethod: 'Paper check',
            scheduleMethodAch: 'Money by QuickBooks',
            deliveryBankTransferMethod: 'ACH Bank transfer',
            balance: 'Account balance:  <private>{balance, number, currency}</private>',
            scheduleMethodAchQBCash: 'Money by QuickBooks',
            unauthorizedBalance: '--',
          },
          failedPaymentDescription: {
            default:
              'Payment failed due to card network restriction. Please connect another payment method and retry to complete this payment.',
            incorrectVendorACH:
              'Please correct the payment receiver’s routing number (ACH/direct deposit, not wire) and notify us at support@moneybyqbusers.melio.com so we can quickly process this payment.',
          },
          deliveryTitleETA: 'Delivery ETA',
          paidFromMy: 'from',
          vendorReceived: 'paid by',
          vendorReceive: 'pay by',
          actions: {
            cancel: 'Cancel payment',
            edit: 'Edit payment',
            delete: 'Delete',
          },
        },
      },
      list: {
        button: 'New payment',
        tabs: {
          open: 'Open',
          completed: 'Completed',
        },
        paymentStatus: {
          failed: 'Payment failed',
          scheduled: 'Scheduled',
          scheduledVirtual: 'Scheduled (pending)',
          failedBillStatus: {
            ach: {
              description:
                'Hmm, that didn’t go through. Make sure their email address is right, then try again.',
            },
            check: {
              description:
                'Hmm, that didn’t go through. Make sure their email address is right, then try again.',
            },
            virtual: {
              description:
                'Hmm, that didn’t go through. Make sure their email address is right, then try again.',
            },
          },
          scheduledBillStatus: {
            ach: {
              description: 'Deducting from your account {scheduledDate}.',
            },
            check: {
              description: 'Deducting from your account {scheduledDate}.',
            },
            virtual: {
              description: 'Email sent {createdAt} (and it expires {expiredDate})',
            },
          },
          completedBillStatus: {
            ach: {
              description: 'Sent {scheduledDate} and arriving in their account {deliveryEta}.',
            },
            check: {
              checkSentDescription: 'Paper check sent {scheduledDate} and arriving {deliveryEta}.',
              checkDeliveredDescription: 'Paper check delivered {deliveryEta}.',
            },
            virtual: {
              description: 'Sent {scheduledDate} and arriving in their account {deliveryEta}.',
            },
          },
          scheduledDescription: '{description}',
          processing: 'Processing',
          completed: {
            ach: 'Paid (ACH)',
            checkSent: 'Sent (check)',
            checkDeposited: 'Deposited (check)',
            completed: 'Completed',
            scheduled: 'Scheduled',
          },
        },
        empty: 'No payments yet',
        header: {
          scheduled: 'Scheduled',
          needsAttention: 'Needs attention',
        },
      },
      status: {
        paymentPaid: 'Paid',
        paymentScheduled: 'Scheduled',
      },
    },
    qbo: {
      footer: {
        poweredBy:
          'Pay services powered by <sb>Melio</sb> with funds held by <sb>Evolve Bank & Trust</sb> or <sb>J.P Morgan</sb> (members of the FDIC and the Federal Reserve)',
      },
    },
    drawer: {
      contactLabel: 'If you still have questions, please contact our support team at',
      emailLabel: ' ',
      faq: {
        question1: "How do I view or edit my vendor's delivery information?",
        answer1: `Here's how to add or edit a vendor's delivery method{br}{br}
        <b>ADDING A VENDOR</b>:{br}{br}
        1. Within the Money by Quickbooks App, select “Pay”{br}{br}
        2. Enter the amount you wish to send and select “Next”{br}{br}
        3. On the contacts screen, click on the green “+” button in the bottom right corner{br}{br}
        4. Select "Create new" and enter the vendor’s name, email, and phone number. Otherwise, choose to add a vendor from a list of contacts in your phone.{br}{br}
        5. Click “Save Contact” and select them on the contact page to open Pay{br}{br}
        6. On the next screen choose to either enter the vendor’s ACH information, mailing address, or send an ACH request to their email address.{br}{br}
        7. Fill out the required fields, then select "Schedule Payment" to complete your payment set up.{br}{br}
        {br}
        <b>EDITING A VENDOR</b>:{br}{br}
        1. Within the Money by Quickbooks App, select “Pay”{br}{br}
        2. Enter the amount you wish to send and select “Next”{br}{br}
        3. Select the vendor you want to pay{br}{br}
        4. On the next screen, select the arrow in the “Pay By” section{br}{br}
        5. Choose whether you want to edit their ACH or Mailing Address{br}{br}
        6. Update their payment method information and click "Next"{br}{br}
        `,
        question2: 'How do I void a check?',
        answer2: `If you need to void an undeposited check, you can submit your request to our team via the above chat, or via email.  When reaching out, please provide the following information:{br}{br}
        <ulcf>
          <li>Vendor Name</li>{br}
          <li>Payment Amount</li>{br}
          <li>Check Number</li>{br}
          <li>How you would like us to proceed once the check is voided</li>{br}
        </ulcf>
        Once voided, we are able to re-send the payment directly to the vendor, either by issuing a new check, or via ACH bank transfer if the vendor accepts electronic payment.  If ACH is possible, we would be able to get the payment to the vendor within one business day.{br}{br}
        When re-sending payment, Melio <b>will not</b> debit the funds from your payment method again - we will simply re-send the funds we already hold.`,
        question3: 'Where can I see the check number?',
        answer3:
          "For any Pay payment sent out via Paper Check, you can view the check number within the relevant Payment Details. Here's how to locate the Payment Details section for a payment:{br}{br}" +
          '1. From your Money by QB home dashboard, navigate to the "Account" tab, located in the bottom-left corner of your screen.{br}{br}' +
          '2. Select the "Go to Payments" option listed under the "Payments" header.{br}{br}' +
          '3. Once the Pay feature loads, select the "Completed" tab to view all completed payments.{br}{br}' +
          '4. Select the relevant payment to view the Payment Details. In the "Paid by" section, you should see the check number listed.{br}{br}' +
          "Please note that a check number is only generated once the check has been printed and sent out from our (Melio's) bank.",
        question4: 'When will my payment arrive?',
        answer4: `Our standard processing times per payment are as follows:{br}{br}
          <ulcf>
            <li>ACH to ACH (< $100k): 3 full business days</li>{br}
            <li>ACH to ACH (≥ $100k): 4 full business days</li>{br}
            <li>Debit Card or Credit Card to ACH: 1 full business day</li>{br}
            <li>ACH/Card to Paper Check: 5-7 business days via USPS</li>
          </ulcf>`,
        question5: 'How do I verify my bank account?',
        answer5:
          'If you connected your bank account to Money by QB Pay via the "Verify with deposits" option, you should have received two micro-deposits (less than $1 amounts) from "Melio", 1-2 business days after first linking the account.{br}{br}' +
          'Once you have received those two amounts, you can verify your bank account within your Pay Settings.  To do so, navigate to "Settings" → "Connected accounts". Next to the bank account you would like to verify, select the "Verify" option.  Then, enter the two deposit amounts you received.',
        question6: 'Can I make a partial payment?',
        answer6:
          'Currently, Money by QB Pay does not have a partial payment feature, so it will pull the total bill amount during payment setup.{br}{br}' +
          'A workaround for now may be to create separate bills of lesser amounts, or edit the total bill amount and re-click "Save and Schedule Payment".{br}{br}' +
          'Our Development team is currently working on the Partial Payment feature, and we plan to implement it in the coming months.',
        question7: '',
        question8: 'How can I have my vendor fill out their own ACH or Check information?',
        answer8:
          'As you set up your payment, you will be prompted to select how your vendor would like to receive their payment.  Here, you can select either ACH bank transfer or Paper Check, and enter the information if you have it already.{br}{br}' +
          'If you would like your vendor to choose their own preferred method, you can select the "Ask vendor for payment details" option.  If you select this option, we will send an email to the vendor with a secure link through which they can choose their preferred method, and enter the ACH or Paper Check information themselves.{br}{br}' +
          'Once your vendor has entered their information, you will be notified via email.',
        question9: 'Can I make an international payment?',
        answer9:
          'Currently, Money by QB Pay powered by Melio can only facilitate domestic ACH and Paper Check payments within the U.S.{br}{br}' +
          'Our Development team is currently working on an International Payments feature, and we hope to implement it in the coming months.',
        question10: 'Are there any fees?',
        answer10: `The Money by QB Pay powered by Melio service does not carry any additional subscription fees.  It is included in your Money by QuickBooks app.{br}{br}
            For standard payments made via bank transfer (ACH) or Debit Card, you will not be charged any transaction fees.  For Credit Card payments, you will be charged a 2.9% transaction fee, billed separately from the original payment by Melio.{br}{br}
            For our expedited payment options, the associated fees are as follows:{br}{br}
            <ulcf>
              <li>Fast ACH*: 1% of total transaction amount</li>{br}
              <li>Fast Check: $20 flat fee</li>{br}
            </ulcf>
            *Note: The Fast ACH feature is currently being rolled out in phases to select customers`,
        question11: 'Can I make payments to individuals via Money by QB Pay powered by Melio?',
        answer11:
          'Money by QB Pay powered by Melio is a business to business (B2B) payment solution.  Therefore, we are not be able to process payments to non-business individuals, or payments made for personal reasons.{br}{br}' +
          'However, if your vendor is a registered sole proprietor, we will be able to facilitate payment to that individual.',
        question12: 'Can my business use Money by QB Pay powered by Melio?',
        answer12: `Money by QB Pay is a B2B solution designed to serve small businesses in the United States, including sole proprietorships. Therefore, virtually any business looking to make bill payments to another business can use Money by QB Pay.{br}{br}
            We are, however, prohibited from working with businesses involved with any of the following:{br}{br}
            <ulcf>
              <li>Internet gambling</li>{br}
              <li>Multi-level marketing firms or any agents that represent them</li>{br}
              <li>Sales of tobacco, marijuana, pharmaceuticals, or nutraceuticals</li>{br}
              <li>Pornography, obscene materials, or any sexual/adult services</li>{br}
              <li>Weapons, ammunitions, gunpowder, fireworks, and any other explosives</li>{br}
              <li>Toxic, flammable or any radioactive material</li>
            </ulcf>`,
        question13: 'How does Melio process my payment?',
        answer13:
          "Melio processes the payments made through Money by QB Pay on QuickBooks' behalf.  There are two steps in the payment process:{br}{br}" +
          '1. First, Melio collects the funds from your bank account or card.{br}{br}' +
          "2. Then, Melio's bank sends the payment amount out to your vendor for delivery. For payments sent via Paper Check, Melio's bank prints and sends the check out in the mail.",
        question14: 'Can I see a list of all payments made via Money by QB Pay powered by Melio?',
        answer14: `1. Within the Money By Quickbooks App, select “Transactions”{br}{br}
          2. This section is broken down into three tabs: “Money In”, “Money Out”, and “All”{br}{br}
          3. "Money In" will show payments sent to your Money by Quickbook account while "Money Out" will show payments sent to your list of contacts via the Pay feature.{br}{br}
          4. For detailed information, such as payment type and date, simply tap on the specific payment and it will open a new screen providing this information.{br}{br}`,
        question15: "Help! I still can't find a solution",
        answer15:
          'No worries. Get in touch with our Support team here at Money by QB Pay powered Melio, we’re here to help. You can reach us via live chat or email.{br}{br}' +
          'You can access our live chat via the "start a chat" option located at the top of this FAQ window. Or, you can email our team by sending a message to { supportEmail }{br}{br}' +
          '<b>Support Hours:</b>{br}{br}' +
          'SAT - SUN: Closed{br}{br}' +
          'MON - THU: 9:30am - 8pm EST{br}{br}' +
          'FRI: 9:30am - 5pm EST',
        question16: "Why is my vendor's industry required for American Express card payments.",
        answer16: `From November 22nd 2021, there is an important change in how Bill Pay powered by Melio processes payments made with an American Express credit card. From this date, you’ll only be able to pay vendors from the American Express list of pre-approved industries:{br}{br}
              <ulcf>
              <li>Education</li>{br}
              <li>Government</li>{br}
              <li>Utilities</li>{br}
              <li>Membership Club</li>{br}
              <li>Professional Services</li>{br}
              <li>Business Services</li>{br}
              <li>Inventory/ Wholesale</li>{br}
              <li>Construction/ Logistics</li>{br}
              </ulcf>
              {br}<b>How this change affects your payments</b>{br}{br}
              From November 22nd, you’ll be asked to choose your vendor’s industry when you pay with your American Express card. Note that you’ll only need to do this once per vendor, and you won’t need to choose your own industry
              If your vendor's industry is not on the permitted industries list, you won’t be able to complete the payment with American Express. You can choose an alternative payment method - either a non-American Express card or bank transfer (ACH).`,
        question17: '',
      },
    },
    inputErrors: {
      deliveryMethodCheck: {
        printName: {
          any: {
            empty: 'Enter a name',
            required: 'Enter a name',
          },
          string: {
            max: 'We’re sorry, but name on check should not exceed 255 characters',
          },
        },
        googlePlaceId: {
          any: {
            empty: 'Please enter the vendor address',
            required: 'Please enter the vendor address',
          },
        },
        addressLine1: {
          any: {
            empty: 'Enter a valid address',
            required: 'Enter a valid address',
          },
          string: {
            min: 'Please enter a delivery address',
          },
        },
        city: {
          any: {
            empty: 'Please enter the city',
            required: 'Please enter the city',
          },
        },
        state: {
          any: {
            empty: 'Please select the state',
            required: 'Please enter the state',
            allowOnly: 'Please select the state',
          },
        },
        zipCode: {
          any: {
            empty: 'Enter a valid zip code',
            required: 'Enter a valid zip code',
          },
          string: {
            regex: {
              base: 'Please enter a 5 or 9 digit zip code',
            },
          },
        },
      },
      deliveryMethodAch: {
        routingNumber: {
          string: {
            regex: {
              base: 'This should be 9 digits',
            },
            invalidChecksum: 'Enter a valid routing number',
          },
          any: {
            required: 'Enter a valid routing number',
          },
        },
        accountNumber: {
          any: {
            required: 'Enter a valid account number',
          },
          string: {
            regex: {
              base: 'This should be 6-17 digits',
            },
          },
        },
      },
    },
    input: {
      optional: ' ',
    },
  },
});

export default qbrTranslate;
