import React from 'react';
import { FormControl } from '@chakra-ui/react';

import { useInputId } from '../../hooks/useInputId';
import { FieldWrapperType } from '../../types';

import { Label } from './Label';
import { Footer } from './Footer';
import { Field } from './Field';

export type FieldWrapperProps = FieldWrapperType & {
  // eslint-disable-next-line no-unused-vars
  input: (id: string) => React.ReactElement | null;
};

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  label,
  helperText,
  errorMessage,
  tooltipLabel,
  isDisabled,
  input,
  leftAddon,
  rightAddon,
  rightAddonWidth,
  recommendedLabelText,
  isRequired,
}: FieldWrapperProps) => {
  const [id] = useInputId();

  return (
    <FormControl w="100%">
      <Label
        id={id}
        label={label}
        tooltipLabel={tooltipLabel}
        isDisabled={isDisabled}
        recommendedLabelText={recommendedLabelText}
        isRequired={isRequired}
      />
      <Field
        id={id}
        input={input}
        leftAddon={leftAddon}
        rightAddon={rightAddon}
        rightAddonWidth={rightAddonWidth}
      />
      <Footer errorMessage={errorMessage} helperText={helperText} />
    </FormControl>
  );
};

export { FieldWrapper };
