import { useEffect } from 'react';

export default function SiteIcon() {
  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    if (favicon) {
      favicon.href = `/static/qbo-logo16x16.ico`;
    }
  }, []);

  return null;
}
