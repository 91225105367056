/* eslint-disable max-len */
import merge from 'lodash/merge';
import { theme as qboTheme } from './qboTheme';
import { devices } from './AppDevices';

const theme = merge({}, qboTheme, {
  text: {
    color: {
      readonly: 'rgba(212, 215, 220, 1)',
      main: 'rgba(57, 58, 61, 1)',
      white: 'rgba(255, 255, 255, 1)',
      darkGrey: 'rgba(107, 108, 114, 1)',
      lightGrey: 'rgba(186, 190, 197, 1)',
    },
    weight: {
      regular: 400,
      medium: 500,
      demi: 600,
      bold: 700,
    },
    fontType: {
      hugest: `
        font-size: 3.8rem;
        line-height: 4.4rem;
      `,
      huge: `
        font-size: 3.4rem;
        line-height: 4.4rem;
      `,
      big: `
        font-size: 2.4rem;
        line-height: 2.4rem;
      `,
      enlarged: `
        font-size: 1.8rem;
        line-height: 2.4rem;
      `,
      regular: `
        font-size: 1.6rem;
        line-height: 2.4rem;
      `,
      medium: `
        font-size: 1.4rem;
        line-height: 2rem;
      `,
      hint: `
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;
      `,
      small: `
        font-size: 1.2rem;
        line-height: 1.6rem;
      `,
    },
  },
  colors: {
    brand: 'rgba(44, 160, 28, 1)',
    error: 'rgba(213, 43, 30, 1)',
    button: {
      disabledMain: 'rgba(157, 219, 158, 1)',
      cancelGrey: 'rgba(236, 238, 241, 1)',
      fontRed: 'rgba(184, 0, 0, 1)',
    },
    icon: {
      grey: 'rgba(186, 190, 197, 1)',
    },
    dark: {
      translucent1: 'rgba(0, 0, 0, 0.2)',
      white: 'rgba(255, 255, 255, 1)',
    },
    border: {
      grey: 'rgba(231, 231, 231, 1)',
      lightGrey: 'rgba(227, 229, 232, 1)',
      light: 'rgba(212, 215, 220, 1)',
      darkGrey: 'rgba(219, 219, 219, 1)',
      dark: 'rgba(153, 153, 156, 1)',
      halfDarkGrey: 'rgba(219, 219, 219, 0.5)',
      quarterDarkGrey: 'rgba(219, 219, 219, 0.3)',
    },
    gradient: {
      start: 'rgba(0, 132, 129, 1)',
      end: 'rgba(44, 159, 28, 1)',
    },
    white: {
      opaque: 'rgba(255, 255, 255, 1)',
      veryLightGrey: 'rgba(250, 250, 250, 1)',
      lightBlue: 'rgba(244, 245, 248, 1)',
      whiteSmoke: 'rgba(246,246,246, 1)',
    },
    link: 'rgba(0, 119, 197, 1)',
  },
  icons: {
    'qbxm-checkmark': '\\e978',
    'qbxm-bank': '\\e979',
    'qbxm-check': '\\e97a',
    'qbxm-email': '\\e97b',
  },
  components: {
    QBOHeaderContainerClass: {
      QBOHeader: () => `
        padding: 0;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        height: 8.8rem;
        border-bottom: none;
      `,
      SettingsLink: () => `
        display: none;
      `,
      HeaderContent: () => `
        position: relative;
        top: -1.7rem;
      `,
      HeaderButtonContent: () => `
        position: relative;
        top: 4.5rem;
      `,
      Close: () => `
        display: none;
      `,
    },
    QBOFooterContainer: {
      QBOFooter: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
          background-color: ${props.theme.colors.white.opaque};
          margin 0;
          padding: 3.2rem 1.3rem;
        }

        @media ${devices.desktop} {
          display: none;
        }
      `,
    },
    BillPaymentReview: {
      BaseContainer: (props: any) => `
        padding: 1.6rem;
        align-self: center;
        border-bottom: 0.1rem solid ${props.theme.colors.white.lightBlue};
        overflow-x: hidden;
      `,
      PaymentActivityContainer: () => `
        padding: 1.6rem;
      `,
      InvoiceBoxColumn: () => `
        display: none;
      `,
      FeeContainer: () => `
        display: none;
      `,
      MainBox: (props: any) => `
      border-radius: 0;
      box-shadow: none;
      &.review {
        border-radius: 0;
        background-color: ${props.theme.colors.white.opaque};
        box-shadow: none;
        border-bottom: 0.4rem solid ${props.theme.colors.white.lightBlue};
        }
      `,
      VendorFieldName: () => `
        display: none;
      `,
    },
    QBOBillPaymentsActivity: {
      Separator: () => `
        display: none;
      `,
      ActionOptionsContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          width: auto;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 1.8rem;
          border-top: none;
          bottom: 0;
          margin-bottom: 0;
          margin-top: 0;
          padding-bottom: 2rem;
        }
        >:not(:first-child) {
          margin-right: 1.2rem;
        }
      `,
    },
    PaymentActivity: {
      PaymentInfoHint: () => `
        display: none;
      `,
      AdditionalDescription: () => `
        display: none;
      `,
    },
    WizardElements: {
      WizardInner: (props: any) => `
        background-color: ${props.theme.colors.white.opaque};
        padding: 0;
        width: 100%;
      `,
      WizardInnerSuccess: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          padding: 1.6rem;
          box-sizing:border-box;
        }
      `,
      WizardStepTitle: (props: any) => `
        align-self: flex-start;
        text-align: left;
        height: 100%;
        margin-bottom: 0 !important;
        padding: 2rem 1.6rem 0.8rem;
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: ${props.theme.text.weight.demi};
      `,
      WizardButtonContainer: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          background: ${props.theme.colors.white.opaque};
        }
        button {
          min-height: 4.8rem;
        }
      `,
      WizardStepActionsContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          width: 90%;
          margin-top: 0;
        }
        width: 100%;
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        margin-top: 3rem;
      `,
      WizardInputsContainer: () => `
        box-sizing: border-box;
        width: 100%;
        @media ${devices.mobile}, ${devices.phablet} {
          display: flex;
          flex-direction: column;
        }
      `,
      WizardFormRow: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          height: auto;
          margin-bottom: 0;
        }
      `,
      WizardStepSubTitle: () => `
        align-self: flex-start;
        padding: 0 1.6rem;
        text-align: left;
        margin-bottom: 0;
      `,
      WizardIllustration: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          height: 6rem;
          width: 6rem;
          margin-bottom: 2.4rem;
        }
      `,
    },
    ModalMessage: {
      ModalContentContainer: () => `
        display: flex;
        justify-content: flex-start;
      `,
      TitleContainer: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          margin: 2rem -1.6rem 3.2rem;
          padding: 1rem 0;
          .text {
            font-size: ${props.theme.text.size.sectionTitle};
            line-height: ${props.theme.text.lineHeight.regular};
          }
        }
      `,
      ButtonContainer: () => `
        padding: 1.6rem 0;
        button {
          height: 4.8rem;
          box-shadow: none;
        };
        display: flex;
        flex-grow: 2;
        flex-direction: column-reverse;
      `,
      CloseButtonWrapper: (props: any) => `
        color: ${props.theme.text.color.green};
      `,
    },
    PaymentDatePicker: {
      PaymentDatePickerContainer: () => `
        border: none;
        box-shadow: none;
      `,
      InfoContainer: (props: any) => `
        border-top: 0.4rem solid ${props.theme.colors.white.lightBlue};
      `,
      DueDateDescription: () => `
        display: none;
      `,
    },
    MIRadioGroup: {
      MIRadioGroupContainer: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          border: 0.1rem solid ${props.theme.colors.white.lightBlue};
          box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.06);
          border-radius: 0.8rem;
          padding: 1.6rem;
        }
        @media ${devices.desktop}, ${devices.tablet} {
          padding: 1.6rem 1.6rem 0 1.6rem;
        }
      `,
      OptionContainer: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          &:first-of-type {
            border-bottom: 0.1rem solid ${props.theme.text.color.lightGrey};
            margin-bottom: 1rem;
            padding-bottom: 1.6rem;
          }
          &:last-of-type {
            padding-top: 1.6rem;
          }
        }
      `,
      ManualAddressIcon: () => `
        display: none;
      `,
    },
    ManualAddressRow: {
      Container: (props: any) => `
        @media ${devices.mobile}, ${devices.phablet} {
          > button {
            font-weight: ${props.theme.text.weight.semiBold};
            color:  ${props.theme.text.color.link};
            text-transform: uppercase;
            font-size: ${props.theme.text.size.hint};
            margin-top: 0.5rem;
          }
        }
      `,
      AddressRowCard: (props: any) => `
      > div:first-child {
        box-shadow: 0 0.2rem 1rem ${props.theme.colors.shadow.lightGrey};
        border-radius: 0.8rem;
        border: 1px solid ${props.theme.colors.shadow.lightGrey};
        line-height: 2.4rem;
      }
      `,
    },
    MIAddressAutocompleteSmartyStreets: {
      IconStyles: () => `
        line-height: 11.4rem;
        width: 5.6rem;
      `,
      ClearIcon: () => `
        padding-top: 3.8rem;
      `,
    },
    EditCheckAddressModalButtonRow: {
      ButtonContainer: () => `
        flex-direction: column;
        bottom: 2rem;
        > button {
          margin-bottom: 1rem;
        }
      `,
    },
    ManualAddressConfirmPage: {
      FormContainer: (props: any) => `
        padding: 1.6rem 0;
        > div {
          box-shadow: 0 0.2rem 1rem ${props.theme.colors.shadow.lightGrey};
        }
        @media ${devices.desktop}, ${devices.tablet} {
          padding: 0;
        }
      `,
    },
    MICard: {
      MICardContainer: () => `
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        border: none;
      `,
      Container: () => `
        @media ${devices.mobile} {
          flex-direction: column;
          flex-grow: 2;
          justify-content: space-between;
        }
      `,
    },
    MISingleSelect: {
      selectWrapper: () => `
        margin-bottom: 0;
        padding: 1.6rem;
        padding-top: 5rem;
      `,
    },
    ListLayoutPage: {
      QBOFab: () => `
        display: none;
      `,
      ListContainer: (props: any) => `
        background-color: ${props.theme.colors.white.opaque};
      `,
    },
    QBOMIDialog: {
      TextsContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          margin: 0;
        }
      `,
      SubtitleContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          font-size: 1.6rem;
          margin-bottom: 3rem;
        }
      `,
      Close: 'display:none;',
      ActionsContainer: () => `
        @media ${devices.mobile}, ${devices.phablet} {
          height: auto;
        }
      `,
    },
    BillStatus: {
      Status: (props: any) => {
        if (props.status === 'scheduled') {
          return `color: ${props.theme.text.color.main}`;
        }

        return `color: ${props.color}`;
      },

      Icon: (props: any) => {
        if (props.status === 'scheduled') {
          return `color: ${props.theme.colors.warning.opaque}`;
        }

        return `color: ${props.color}`;
      },
    },
    QBOBillListItem: {
      ListItemContainer: () => `
        margin-bottom: 0;
      `,
    },
    QBOElements: {
      BackMobile: (props: any) => `
      @media ${devices.mobile}, ${devices.phablet} {
        min-width: 0;
        height: 2.4rem;
        width: 2.4rem;
        font-size: ${props.theme.text.size.wizardStepTitleMobile};
      }
      `,
    },
    MITextInput: {
      TextInputContainer: () => `
        box-sizing: border-box;
        margin: 0;
        @media ${devices.mobile}, ${devices.phablet} {
          padding: 4.8rem 0 0;
        }
        @media ${devices.desktop}, ${devices.tablet} {
          padding: 4.5rem 0 0;
        }
      `,
      TextInput: (props: any) => `
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: ${props.theme.text.weight.medium};
        padding-bottom: 0;
        border-bottom: 0.1rem solid ${props.theme.colors.border.lightGrey}};
      `,
    },
    MIDropDown: {
      DropDownContainer: () => `
        margin-top: -1.6rem;
      `,
      DropDownIndicator: () => `
        display: none;
      `,
    },
    MINotices: {
      Notices: () => `
        margin-top: 1rem;
      `,
    },
    MobileHeader: {
      MobileHeaderContainer: () => `
        ((0,166,164,1) 0%, rgba(0,132,129,1) 100%);
        height: 4.4rem;
        @media ${devices.mobile}, ${devices.phablet} {
          height: 4.5rem;
        }
      `,
      HeaderTitle: (props: any) => `
        font-size: ${props.theme.text.size.regular};
        font-weight: ${props.theme.text.weight.semiBold};
      `,
      HeaderContentIcon: (props: any) => `
        font-size: ${props.theme.text.size.wizardStepTitleMobile};
      `,
      MobileHeaderActions: () => `
      @media ${devices.mobile}, ${devices.phablet} {
        padding: 2rem;
      }
      `,
    },
    Layout: {
      Overlay: () => `
        height: 90%;
      `,
    },
    MIInputLabel: {
      InputLabel: (props: any) => `
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${props.theme.text.color.darkGrey};
        font-weight: ${props.theme.text.weight.medium};
      `,
    },
    FormElements: {
      FormContainer: () => `
        > * {
          margin-bottom: 0;
        }
      `,
      FormRow: () => `
        > *:first-child {
          margin-right: 0;
        }
        > *:last-child {
          margin-left: 0;
        }
      `,
    },
    dates: {
      DeliveryDateContainer: (props: any) => `
        ${
          props.fontType
            ? props.theme.text.fontType[props.fontType]
            : props.theme.text.fontType.medium
        }
      `,
    },
    MIButton: {
      MIButton: (props: any) => `
        height: 4.8rem;
        border-radius: 2.4rem;
        text-transform: none;
        font-size: ${props.theme.text.size.regular};
        font-weight: ${props.theme.text.weight.semiBold};
        padding: 0 0.1rem;
      `,
      PrimaryButton: (props: any) => `
        height: 4.8rem;
        &:active {
          background-color: ${props.theme.colors.brand};
        }
        &:disabled {
          color: ${props.theme.text.color.white};
          background-color: ${props.theme.colors.button.disabledMain};
        }
        box-shadow: none;
      `,
      CancelButton: (props: any) => `
        background: ${props.theme.colors.dark.transparent};
        margin-right: 0.7rem;
        border: 0.2rem solid ${props.theme.colors.text};
        &:disabled {
          color: ${props.processing ? 'transparent' : props.theme.colors.white.opaque};
        }
        &:hover {
          color: ${props.theme.colors.text};
        }
      `,
      DestructiveButton: (props: any) => `
        background: ${props.theme.colors.button.cancelGrey};
        color: ${props.theme.colors.button.fontRed};
        box-shadow: none;
      `,
    },
    QBODrawer: {
      SupportLink: () => `
        color: inherit;
        text-decoration: none;
      `,
    },
    MIMoney: {
      FractionBalance: (props: any) => `
        ${props.theme.text.fontType.regular};
        font-weight: ${props.theme.text.weight.medium};
        padding-left: 0;
      `,
      IntegerBalance: (props: any) => `
        ${props.theme.text.fontType.regular};
        font-weight: ${props.theme.text.weight.medium};
      `,
      Dot: (props: any) => `
        ${props.theme.text.fontType.regular};
        display: inline-block;
      `,
    },
    DefaultLabel: (props: any) => `
      color: ${props.theme.text.color.lightGrey};
    `,
  },
  pages: {
    QboLayoutPage: {
      QboLayoutPageFooter: () => `
        display: none;
      `,
    },
    ManualAddressInvalidPage: {
      Label: () => `
        display: none;
      `,
      FormContainer: () => `
      `,
      Icon: () => `
        width: 2.2rem;
      `,
      ButtonsContainer: () => `
        display: none;
        margin: 1.6rem 1.6rem;
        > button {
          width: 100%;
        }
      `,
      FormWrapper: (props: any) => `
        @media ${devices.mobile} {
          display: flex;
          border: 0.1rem solid ${props.theme.colors.white.lightBlue};
          box-shadow: 0 0.4rem 1.6rem rgba(0,0,0,0.06);
          border-radius: 0.8rem;
          margin: 1.6rem 0;
        }
      `,
    },
    SelectBankAccountVerificationTypePage: {
      Card: (props: any) => `
        margin-bottom: 3rem;
        padding: 2rem 1.6rem;
        box-shadow: 0rem 0.1rem 0.4rem ${props.theme.colors.dark.translucent1};
      `,
      CardLabelText: (props: any) => `
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: ${props.theme.text.weight.medium};
      `,
      DescriptionText: (props: any) => `
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: ${props.theme.text.color.main}
      `,
      IconContainer: () => `
        display: none;
      `,
      Image: () => `
        margin-right: 1.6rem;
      `,
    },
    EditBankDeliveryMethodPage: {
      SecurityContainer: (props: any) => `
        padding-left: 0;
        margin-top: 0.5rem;
        font-weight: ${props.theme.text.weight.regular};
      `,
    },
  },
});

export { theme };
