import { useEffect } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { getBill, getPayment } from 'src/app/redux/payBillWizard/selectors';
import { useHistoryBack } from 'src/app/pages/bill/pay/hooks/useHistoryBack';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import MIButton from 'src/app/components/common/MIButton';
import { devices } from 'src/app/theme/AppDevices';
import { PaymentDeliveryOptions } from 'src/app/components/common/PaymentDeliveryOptions';
import { getOrgId } from 'src/app/redux/user/selectors';
import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS, DELIVERY_TYPE } from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import { withNavigator } from 'src/app/hoc';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { selectPaymentDatesAction } from 'src/app/redux/payBillWizard/actions';
import { AreaLoader } from '@melio/billpay-design-system';
import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import { ANALYTICS_PAGE_NAME, getConfirmURL } from 'src/app/pages/bill/pay/RecoveryFlowSteps/utils';
import { withPayBillData, PayBillProps } from '../../../hoc/withPayBillData';
import { RESOURCE_BASE } from '../../config';
import { RecoveryFlowType } from '../../../consts';
import { useDeliveryDateRecoveryHeadings } from '../../../hooks/useDeliveryDateRecoveryHeadings';

const getTextResources = (flowType: RecoveryFlowType) => ({
  formTitle: `${RESOURCE_BASE[flowType]}.stepRecoverByCheck.chooseDeliveryDates.formTitle`,
  buttonSave: `${RESOURCE_BASE[flowType]}.stepRecoverByCheck.chooseDeliveryDates.buttonSave`,
});

const ChooseDeliveryDate = ({
  goExit,
  onPrev,
  deliveryOptionsDates,
  isLoading,
  flowType,
}: PayBillProps & { flowType: RecoveryFlowType }) => {
  const TEXT_RESOURCES = getTextResources(flowType);
  const orgId = useSelector(getOrgId);
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const [historyPush] = useHistoryWithOrgId();
  const dispatch = useDispatch();
  const deliveryOptions = deliveryOptionsDates?.deliveryOptions || null;
  const deliveryPreference = payment?.deliveryPreference;
  const analyticsOptions = {
    screenName: 'retry-check',
    partialBillId: getPartialBillId(bill),
  };

  const { getTitle, getSubtitle } = useDeliveryDateRecoveryHeadings();

  const isBulkPayment = !!(payment?.bills?.length && payment?.bills?.length > 1);
  const dueDate = isBulkPayment
    ? (payment.bills
        ?.map((bill) => new Date(bill.dueDate).getTime())
        .reduce((acc, curr) => Math.min(acc, curr)) as number)
    : bill.dueDate;

  useHistoryBack({
    path: locations.Bills.pay.edit[flowType].chooseCheckDelivery.url({
      orgId,
      id: bill.id,
      paymentId: payment.id,
    }),
  });

  const handleContinueClick = () => {
    analytics.track(ANALYTICS_PAGE_NAME, 'retry-payment-delivery-set-check-type-continue-success', {
      billId: bill?.id,
      original_paymentID: payment?.id,
      originDeliveryType: DELIVERY_TYPE.CHECK,
      new_deliveryID: payment?.deliveryMethodId,
      new_deliveryType: DELIVERY_TYPE.CHECK,
      ...analyticsOptions,
    });

    const confirmUrl = getConfirmURL(flowType);

    historyPush({
      path: confirmUrl.url({
        id: bill?.id,
        paymentId: payment?.id,
      }),
    });
  };

  const onSelectDeliveryOption = (scheduledDate, deliveryDate, maxDeliveryDate, type) => {
    analytics.track('pay-bill', `delivery-selected-${type}`, analyticsOptions);
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryDate, maxDeliveryDate, type));
  };

  useEffect(() => {
    analytics.page('retry-payment', 'exposure-to-express-check');
    analytics.track('pay-bill', 'express-check-shown', analyticsOptions);
  }, []);

  if (isLoading) {
    return <AreaLoader placement="wizard" />;
  }

  const { label: titleLabel } = getTitle({ dueDate, deliveryOptions });
  const { label: subtitleLabel, values: subtitleValues } = getSubtitle({
    isBulkPayment,
    dueDate,
    deliveryOptions,
  });

  return (
    <QBOLayoutPage
      title={titleLabel}
      subtitle={subtitleLabel}
      subTitleValues={subtitleValues}
      isHideActions
      goExit={goExit}
      onPrev={onPrev}
    >
      <FormWrapper>
        <InfoContainer>
          <InfoItemContainer>
            <InfoItemText>
              <MIFormattedText label={TEXT_RESOURCES.formTitle} />
            </InfoItemText>
            <SelectDeliveryDateContainer>
              {deliveryOptions && (
                <PaymentDeliveryOptions
                  paymentAmount={payment?.amount}
                  deliveryOptions={deliveryOptions}
                  deliveryPreference={deliveryPreference as string | undefined}
                  onSelectDeliveryOption={onSelectDeliveryOption}
                />
              )}
            </SelectDeliveryDateContainer>
          </InfoItemContainer>
        </InfoContainer>
        <MIButton
          type="button"
          disabled={false}
          className="button"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label={TEXT_RESOURCES.buttonSave}
          onClick={handleContinueClick}
        />
      </FormWrapper>
    </QBOLayoutPage>
  );
};

const ChooseDeliveryDateHOC = compose(withNavigator(), withPayBillData())(ChooseDeliveryDate);

export { ChooseDeliveryDateHOC as ChooseDeliveryDate };

const FormWrapper = styled.form`
  width: 354px;
  margin: 0 auto;

  @media ${devices.mobile} {
    width: 100%;
  }

  .button {
    display: flex;
    align-items: center;
    margin: 4.5rem auto 0;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  padding: 2rem;
  background: ${(props) => props.theme.colors.white.opaque};
  border: 1px solid #ededed;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const SelectDeliveryDateContainer = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 1.6rem;
  ${(props) => props.theme.text.fontType.medium};
`;

const InfoItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const InfoItemText = styled.div`
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.demi};
  text-align: left;
  letter-spacing: 0;
`;
