import { FormHelperText } from '@chakra-ui/react';

import { Flex } from '../../components/ui/Flex';
import { SmartIcon } from '../SmartIcon';

type FooterProps = {
  errorMessage?: string;
  helperText?: string;
};

const Footer = ({ errorMessage, helperText }: FooterProps) => {
  const footerText = errorMessage || helperText;

  if (!footerText) return null;

  return (
    <FormHelperText
      textStyle="ds.body3"
      color={errorMessage ? 'ds.red.100' : 'ds.gray.200'}
      mt="ds.sm"
    >
      <Flex gap="ds.md" align="start">
        {errorMessage ? <SmartIcon type="error" size="1.6rem" /> : null}
        {footerText}
      </Flex>
    </FormHelperText>
  );
};

export { Footer };
