import React from 'react';
import {
  createStandaloneToast,
  CreateStandAloneToastParam,
  ToastId,
  UseToastOptions,
} from '@chakra-ui/react';
import { theme, Box, SmartIcon } from '../..';
import { ToastTypeEnum } from './enums';
import { closeIcon, container, toastContent, typeIcon } from './ToastMessage.styles';

interface ToastMessageProps {
  children: React.ReactNode;
  type?: ToastTypeEnum;
  onClose?: () => void;
  testId?: ToastId;
}

const ToastMessage: React.FC<ToastMessageProps> = ({
  children,
  type = ToastTypeEnum.REGULAR,
  onClose,
  testId,
}) => (
  <Box __css={container} data-testid={testId}>
    <Box __css={toastContent}>
      {type !== ToastTypeEnum.REGULAR ? (
        <Box __css={typeIcon}>
          <SmartIcon type={type} />
        </Box>
      ) : null}
      {children}
    </Box>
    {onClose ? (
      <Box onClick={onClose} __css={closeIcon}>
        <SmartIcon type="close" />
      </Box>
    ) : null}
  </Box>
);

export type ToastParams = {
  message: React.ReactNode;
  type?: ToastTypeEnum;
  options?: UseToastOptions;
};

const createStandaloneToastFactory = (preferences?: CreateStandAloneToastParam) => {
  const toast = createStandaloneToast({
    theme,
    ...preferences,
  });

  return {
    ...toast,
    show: ({ message, type, options = {} }: ToastParams) => toast({
      render: ({ onClose }) => (
        <ToastMessage type={type} onClose={onClose} testId={options.id}>
          {message}
        </ToastMessage>
      ),
      ...options,
    }),
  };
};

export { ToastMessage, createStandaloneToastFactory };
