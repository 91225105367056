import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useApi } from 'src/app/hoc/useApi';
import { melioClose } from 'src/app/utils/external-events';
import failedToLoad from 'src/app/images/qbo/failed-to-load-002-icon.png';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import { getOrgId } from 'src/app/redux/user/selectors';
import accountingSync from 'src/app/services/api/accountingSoftwareSync';
import quickpayLocations from 'src/app/pages/quickpay/locations';
import { encodeQuery } from 'src/app/utils/query-utils';
import { VendorType } from 'src/app/utils/types';
import { Loader } from '@melio/billpay-design-system';

type Props = {
  query: {
    totalAmount: number;
    currency: string;
    vendorOriginId: string;
    note: string;
  };
  vendorOriginId: string;
};

const QuickpayRedirect = ({ query }: Props) => {
  const { totalAmount, currency, vendorOriginId, note } = query;
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const [syncVendorByOriginId, , , error] = useApi<
    [{ orgId: string; vendorOriginId: string }],
    { vendor: VendorType }
  >(accountingSync.syncVendorByOriginId);

  const redirectToCreate = useCallback(
    (vendorId) => {
      history.push({
        pathname: generatePath(quickpayLocations.create.entry, { orgId }),
        search: encodeQuery({ totalAmount, vendorId, currency: currency || 'USD', note }, [], ''),
      });
    },
    [history, orgId, totalAmount, currency, note]
  );

  const syncVendor = useCallback(
    async ({ orgId, vendorOriginId }) => {
      try {
        const result = await syncVendorByOriginId({ orgId, vendorOriginId });
        const vendorId = result?.vendor?.id;

        redirectToCreate(vendorId);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    [syncVendorByOriginId, redirectToCreate]
  );

  useEffect(() => {
    syncVendor({ orgId, vendorOriginId });
  }, [syncVendor, orgId, vendorOriginId]);

  if (error || !vendorOriginId || !totalAmount) {
    return (
      <ErrorPage
        illustration={failedToLoad}
        title="bills.pay.errorVendorNotFound.title"
        subtitle="bills.pay.errorVendorNotFound.subtitle"
        buttonAction={melioClose}
        buttonLabel="bills.pay.errorVendorNotFound.action"
      />
    );
  }

  return <Loader />;
};

export default QuickpayRedirect;
