import React from 'react';
import { useStyleConfig } from '@chakra-ui/react';

import { Flex, Box } from '../../index';

type TestIdProps = {
  'data-testid'?: string;
};
type Props = { children: React.ReactNode; onClick?: () => void } & TestIdProps;
type DefaultProps = { children: React.ReactNode } & TestIdProps;

export const MultiLineSelectBox = ({ children, onClick, ...rest }: Props) => {
  const styles = useStyleConfig('MultiLineSelectBox');

  return (
    <Flex __css={styles} onClick={onClick} {...rest}>
      {children}
    </Flex>
  );
};

const TopContentWrapper = ({ children, ...rest }: DefaultProps) => <Flex align="center" {...rest}>{children}</Flex>;

const HeadingWrapper = ({ children, ...rest }: DefaultProps) => (
  <Flex direction="column" ml="ds.lg" {...rest}>
    {children}
  </Flex>
);

const Title = ({ children, ...rest }: DefaultProps) => (
  <Box textStyle="ds.body1Semi" color="ds.black" {...rest}>
    {children}
  </Box>
);

const Description = ({ children, ...rest }: DefaultProps) => (
  <Box textStyle="ds.body3" color="ds.gray.300" {...rest}>
    {children}
  </Box>
);

const Info = ({ children, ...rest }: DefaultProps) => (
  <Box textStyle="ds.body3" color="ds.gray.200" {...rest}>
    {children}
  </Box>
);

type IconProps = { name: string } & TestIdProps;
const Icon = ({ name, ...rest }: IconProps) => (
  <Box as="i" color="ds.black" fontSize="2.4rem" className={name} {...rest} />
);

const Separator = () => <Box width="100%" height="1px" backgroundColor="ds.gray.500" my="ds.lg" />;

MultiLineSelectBox.TopContentWrapper = TopContentWrapper;
MultiLineSelectBox.HeadingWrapper = HeadingWrapper;
MultiLineSelectBox.Title = Title;
MultiLineSelectBox.Description = Description;
MultiLineSelectBox.Info = Info;
MultiLineSelectBox.Icon = Icon;
MultiLineSelectBox.Separator = Separator;
