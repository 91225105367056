import { FieldErrors } from 'react-hook-form';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import { AchDetailsFormData } from '../model/objects/achDetailsFormData';

interface TransformValidationErrorsToAnalyticsFormatParams {
  validationErrors: FieldErrors<AchDetailsFormData>;
}

export const transformValidationErrorsToAnalyticsFormat = ({
  validationErrors,
}: TransformValidationErrorsToAnalyticsFormatParams) =>
  Object.entries(validationErrors).reduce(
    (acc, [fieldName, error]) => ({
      ...acc,
      [fieldName]: error.message,
    }),
    {}
  );

interface GetBillIdsAnalyticsParamParams {
  billId?: string;
  batchList?: BatchBulkPaymentIntent[];
  paymentIntentId?: string;
}

export const getBillIdsAnalyticsParam = ({
  batchList,
  paymentIntentId,
  billId,
}: GetBillIdsAnalyticsParamParams) => {
  if (batchList && paymentIntentId) {
    const paymentIntent = batchList.find(({ id }) => id === paymentIntentId);

    if (paymentIntent?.payment?.bills?.length) {
      return paymentIntent?.payment?.bills?.map(({ id }) => id);
    }

    if (paymentIntent?.payment?.billId) {
      return [paymentIntent?.payment?.billId];
    }

    return null;
  }

  if (billId) {
    return [billId];
  }

  return null;
};
