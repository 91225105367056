import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { getOrgId } from 'src/app/redux/user/selectors';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/app/pages/locations';
import { SYNC_PAYMENT_INTENT } from '../consts';

type Params = {
  billId: string;
  paymentOriginId?: string;
  paymentId?: string;
  paymentStatus?: string;
};

type Response = [(params: Params) => void];

const useViewPaymentAction = (): Response => {
  const orgId = useSelector(getOrgId);
  const location = useLocation();
  const [historyPush] = useHistoryWithOrgId();

  const goViewPayment = useCallback(
    ({ billId, paymentOriginId, paymentId, paymentStatus }: Params) => {
      const exitUrl = `${location.pathname}${location.search}`;

      const unpaidTabRedirectUrl = generatePath(
        `${locations.qbDashboard.dashboard}?status=unpaid`,
        { orgId }
      );

      historyPush({
        path: locations.qbDashboard.syncPaymentByOrigin,
        params: {
          originId: paymentOriginId || '',
        },
        state: {
          exitUrl,
          intent: SYNC_PAYMENT_INTENT.VIEW_PAYMENT,
          paymentId,
          billId,
          unpaidTabRedirectUrl,
          paymentStatus,
        },
      });
    },
    [orgId, location, historyPush]
  );

  return [goViewPayment];
};

export default useViewPaymentAction;
