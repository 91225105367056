export const cardStyle = {
  display: 'flex',
  p: ['ds.xl', 'ds.2xl'],
  bgColor: 'ds.white',
  flexDirection: 'column',
  border: '1px',
  borderRadius: '0.6rem',
  borderColor: 'ds.gray.500',
  boxSizing: 'border-box',
  textStyle: 'ds.body2',
  color: 'ds.black',
};

export const logoStyle = {
  textAlign: 'center',
  mt: 'ds.2xl',
  mb: 'ds.2xl',
};

export const titleStyle = {
  textStyle: 'ds.h5',
  color: 'ds.gray.100',
};

export const separatorStyle = {
  h: 'ds.sm',
  bgColor: 'ds.gray.800',
  mt: 'ds.2xl',
};

export const yourDetails = {
  textStyle: 'ds.body1Semi',
  color: 'ds.gray.100',
  mt: 'ds.xl',
};

export const fullNameContainer = {
  d: 'flex',
  gap: 'ds.xl',
  mt: 'ds.xl',
  mb: 'ds.2xl',
};

export const defaultInputStyle = {
  mb: 'ds.2xl',
};
