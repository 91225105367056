import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@melio/billpay-design-system';
import { PurposeOfPayment } from 'src/app/version-2/components/PurposeOfPayment/PurposeOfPayment';
import { analytics } from 'src/app/version-2/externals';
import { PurposeOfPayment as PurposeOfPaymentDTO } from 'src/app/version-2/model/dtos';
import {
  collectInvoiceActions,
  collectInvoiceSelectors,
} from 'src/app/version-2/modules/collectInvoice/collectInvoice.slice';
import {
  countriesActions,
  countriesSelectors,
} from 'src/app/version-2/modules/countries/countries.slice';
import {
  BATCH_BULK_EVENT_PAGE,
  INTERNATIONAL_DETAILS_MODAL_SAVE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import { PurposeOfPaymentStructure } from 'src/app/version-2/pages/batch-bulk/model/enums/international.enum';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { convertPaymentIntentIdToNumber } from 'src/app/version-2/pages/batch-bulk/utils/batchList.utils';
import {
  checkIsFileChanged,
  getPurposeByType,
  transformPurposeOfPayment,
  validatePurposeOfPaymentForm,
} from 'src/app/version-2/pages/batch-bulk/utils/internationalModal.utils';
import { SkeletonLoading } from './InternationalDetailsModal.styles';

interface InternationalDetailsModalProps {
  isOpen: boolean;
}

export const InternationalDetailsModal = ({ isOpen }: InternationalDetailsModalProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const paymentIntent = useSelector(
    batchBulkSelectors.selectInternationalModalBillDTO
  ) as BatchBulkPaymentIntent;
  const partialBillIds = useSelector(
    batchBulkSelectors.selectPartialBillIdsOfPayment(paymentIntent.id)
  );
  const invoice = useSelector(batchBulkSelectors.selectInvoiceByBillId(paymentIntent.id));
  const invoiceFile = invoice?.invoiceFile || null;

  const countriesList = useSelector(countriesSelectors.selectCountries);
  const [purpose, setPurpose] = useState<string>(paymentIntent?.payment?.purpose || '');
  const [purposeErrors, setPurposeErrors] = useState<PurposeOfPaymentDTO>({
    type: '',
    description: '',
    file: '',
  });

  const { vendorId, deliveryMethod } = paymentIntent.payment;
  const paymentIntentId = paymentIntent.id;

  const showInvoiceFileSelector = useSelector(
    collectInvoiceSelectors.selectShowInvoicetByVendorId(vendorId)
  );

  useEffect(() => {
    // fetch countries list if needed (for invoice check)
    if (countriesList?.length === 0) dispatch(countriesActions.fetchCountries({ vendorId }));
  }, []);

  useEffect(() => {
    if (countriesList?.length > 0) {
      dispatch(
        collectInvoiceActions.fetchCollectInvoice({
          vendorId,
          countryCode: deliveryMethod?.internationalAccount?.payeeBankCountry || '',
          countries: countriesList,
          billId: paymentIntentId,
        })
      );
    }
  }, [countriesList]);

  const validateForm = () => {
    const { validationErrors, isValid } = validatePurposeOfPaymentForm({
      purpose,
      showInvoiceFileSelector,
      invoiceFile,
    });

    setPurposeErrors(validationErrors);

    return isValid;
  };

  const handleSave = () => {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    analytics.track(BATCH_BULK_EVENT_PAGE, INTERNATIONAL_DETAILS_MODAL_SAVE, {
      billIds: paymentIntentId,
      partialBillIds,
      vendorId,
    });

    const billId = convertPaymentIntentIdToNumber(paymentIntent);

    if (invoiceFile && checkIsFileChanged(paymentIntent, invoiceFile, billId)) {
      dispatch(batchBulkActions.updateBillInvoice({ billId }));
    }

    dispatch(
      batchBulkActions.updatedPaymentIntentDTOSuccess({
        id: paymentIntentId,
        changes: {
          payment: { ...paymentIntent.payment, purpose },
        } as Partial<BatchBulkPaymentIntent>,
      })
    );

    closeModal();
  };

  const closeModalAndRemoveFile = () => {
    dispatch(batchBulkActions.removeUploadInvoiceFile({ billId: paymentIntentId }));
    dispatch(batchBulkActions.setInternationalModalAsClosed());
  };

  const closeModal = () => {
    dispatch(batchBulkActions.setInternationalModalAsClosed());
  };

  const clearPurposeErrors = () =>
    setPurposeErrors({
      description: '',
      type: '',
      file: '',
    });

  const handlePurposeOfPaymentChange = (type, { value }) => {
    clearPurposeErrors();

    setPurpose(
      transformPurposeOfPayment({
        type: getPurposeByType(purpose, PurposeOfPaymentStructure.TYPE),
        description: getPurposeByType(purpose, PurposeOfPaymentStructure.DESCRIPTION),
        [type]: value,
      })
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModalAndRemoveFile}>
      <Modal.Header>
        <Modal.HeaderTitle>
          {formatMessage({ id: 'batchBulkPage.modals.internationalDetails.title' })}
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.CloseButton />
      <Modal.Body>
        {!countriesList.length && (
          <>
            <SkeletonLoading />
            <SkeletonLoading />
          </>
        )}

        {countriesList.length > 0 && (
          <PurposeOfPayment
            errors={purposeErrors}
            onChange={handlePurposeOfPaymentChange}
            purpose={{
              type: getPurposeByType(purpose, PurposeOfPaymentStructure.TYPE),
              description: getPurposeByType(purpose, PurposeOfPaymentStructure.DESCRIPTION),
            }}
            showInvoiceFileSelector={showInvoiceFileSelector}
            billId={paymentIntentId}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonContent isCentred>
          <Modal.Button data-testid="modal-button" onClick={handleSave} size="md" variant="primary">
            {formatMessage({ id: 'batchBulkPage.modals.internationalDetails.button' })}
          </Modal.Button>
        </Modal.ButtonContent>
      </Modal.Footer>
    </Modal>
  );
};
