import React from 'react';
import styled from 'styled-components';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import { SelectFieldOption } from 'src/app/ui/form/WizardSelectField';
import { DIALOG_VARIANTS, BILL_STATUS, DIALOG_TYPE } from './consts';
import { VendorType } from './types';

const getVendorBills = (bills: any) =>
  bills.map((bill) => ({
    title: bill.invoiceNumber,
    status: bill.status,
  }));

// we allow to delete vendors if they don't have any scheduled or unpaid payments
const canDeleteVendor = (bills: any) =>
  bills.filter((vb) => vb.status === BILL_STATUS.SCHEDULED || vb.status === BILL_STATUS.UNPAID)
    .length === 0;

const renderBlockedForPaymentDialog = (site: any, onCancelAction: () => void) => (
  <QBODialog
    type={DIALOG_TYPE.CONFIRM}
    variant={DIALOG_VARIANTS.WARNING}
    title="bills.form.blockedForPaymentDialog.title"
    subtitle="bills.form.blockedForPaymentDialog.subtitle"
    subtitleValues={{
      supportEmail: (
        <StyledMIInlineLink
          target="_self"
          text={site.config.support.email}
          to={`mailto:${site.config.support.email}`}
        />
      ),
    }}
    cancelButtonText="dialogs.buttons.close"
    onCancelAction={onCancelAction}
  />
);

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: ${(props) => props.theme.text.size.regular};
`;

const isNewVendor = (vendorOptions: SelectFieldOption<string>[], vendorId: string | undefined) =>
  vendorOptions.every(({ id }) => id !== vendorId);

const mapVendorsToOptions = (vendors: VendorType[]): SelectFieldOption<string>[] =>
  vendors.map(
    (vendor: VendorType): SelectFieldOption<string> => ({
      id: String(vendor.id),
      label: vendor.companyName,
      value: vendor.companyName,
    })
  );

export {
  canDeleteVendor,
  getVendorBills,
  renderBlockedForPaymentDialog,
  isNewVendor,
  mapVendorsToOptions,
};
