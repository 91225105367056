import { getFormattedAddress } from 'src/app/utils/formatting';
import { getCheckDepositedDate } from 'src/app/utils/payments';
import {
  TIMELINE_STEP_ACTIONS,
  TIMELINE_STEP_STAGES,
  TIMELINE_STEP_STATUSES,
  TIMELINE_STEP_TRANSLATION_ROOT_PATH,
} from '../consts';
import { TimelineStepType, VendorPaymentActionsType, VendorPaymentSectionProps } from '../types';
import { getScheduledStep } from './get-common-steps';
import {
  getPaymentDeductionStatus,
  getPaymentDeliveryStatus,
  getPaymentClearingStatus,
} from './steps-statuses';

const { FUTURE_STEP, FAILED } = TIMELINE_STEP_STATUSES;
const { DEDUCTION, DELIVERY, CLEAR } = TIMELINE_STEP_STAGES;

const getCheckDeductionAction = ({
  deductionStatus,
  baseTranslationPath,
  vendorPaymentActions,
}: {
  deductionStatus: TIMELINE_STEP_STATUSES;
  baseTranslationPath: string;
  vendorPaymentActions: VendorPaymentActionsType;
}): Pick<TimelineStepType, 'actionLabel' | 'actionAnalyticsName' | 'hint' | 'onAction'> => {
  const actionByStatus = {
    [FUTURE_STEP]: {
      actionLabel: `${baseTranslationPath}.${DEDUCTION}.action.${FUTURE_STEP}`,
      actionAnalyticsName: TIMELINE_STEP_ACTIONS.EDIT_CHECK_ADDRESS,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.EDIT_CHECK_ADDRESS],
    },
    [FAILED]: {
      actionLabel: `${baseTranslationPath}.${DEDUCTION}.action.${FAILED}`,
      actionAnalyticsName: TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT,
      hint: `${baseTranslationPath}.${DEDUCTION}.hint.${FAILED}`,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT],
    },
  };

  return actionByStatus[deductionStatus] || {};
};

const getCheckDeliveryAction = ({
  deductionStatus,
  deliveryStatus,
  baseTranslationPath,
  vendorPaymentActions,
  eligibleForPushToDebit,
}: {
  deductionStatus: TIMELINE_STEP_STATUSES;
  deliveryStatus: TIMELINE_STEP_STATUSES;
  baseTranslationPath: string;
  vendorPaymentActions: VendorPaymentActionsType;
  eligibleForPushToDebit: boolean;
}): Pick<TimelineStepType, 'actionLabel' | 'actionAnalyticsName' | 'onAction'> => {
  const expediteToAch = deductionStatus === FUTURE_STEP;

  if (!expediteToAch && !eligibleForPushToDebit) {
    return {};
  }

  const actionType = expediteToAch
    ? TIMELINE_STEP_ACTIONS.TO_ACH
    : TIMELINE_STEP_ACTIONS.TO_PUSH_TO_DEBIT;

  const actionByStatus = {
    [FUTURE_STEP]: {
      actionLabel: `${baseTranslationPath}.${DELIVERY}.action.${FUTURE_STEP}.${actionType}`,
      tooltipLabel: !expediteToAch
        ? `${baseTranslationPath}.${DELIVERY}.action.${FUTURE_STEP}.tooltipLabel`
        : null,
      actionAnalyticsName: actionType,
      onAction: vendorPaymentActions[actionType],
    },
  };

  return actionByStatus[deliveryStatus] || {};
};

const getCheckSteps = ({
  payment,
  vendorPaymentActions = {},
}: VendorPaymentSectionProps): TimelineStepType[] => {
  const baseTranslationPath = `${TIMELINE_STEP_TRANSLATION_ROOT_PATH}.${payment.deliveryMethod.deliveryType}`;
  const deductionStatus = getPaymentDeductionStatus(payment);

  const { addressLine1, addressLine2, city, state, zipCode } =
    payment.deliveryMethod.paperCheck ?? {};

  const getCheckDeductionStep = (): TimelineStepType => ({
    title: `${baseTranslationPath}.${DEDUCTION}.title.${deductionStatus}`,
    subtitle: `${baseTranslationPath}.${DEDUCTION}.subtitle`,
    subtitleValues: {
      checkAddress: getFormattedAddress({ addressLine1, addressLine2, city, state, zipCode }),
    },
    dates: [payment.scheduledDate],
    status: deductionStatus,
    ...getCheckDeductionAction({ deductionStatus, baseTranslationPath, vendorPaymentActions }),
  });

  const scheduledStep = { ...getScheduledStep(payment), nextStepStatus: deductionStatus };
  const deductionStep = getCheckDeductionStep();

  if (deductionStatus === FAILED) {
    return [scheduledStep, deductionStep];
  }

  const deliveryStatus = getPaymentDeliveryStatus(payment);
  const depositStatus = getPaymentClearingStatus(payment);

  deductionStep.nextStepStatus = deliveryStatus;

  const deliveryDates =
    deliveryStatus === TIMELINE_STEP_STATUSES.FUTURE_STEP
      ? { dates: [payment.deliveryEta, payment.maxDeliveryEta] }
      : {};

  const getCheckDeliveryStep = (): TimelineStepType => ({
    title: `${baseTranslationPath}.${DELIVERY}.title.${deliveryStatus}`,
    ...deliveryDates,
    status: deliveryStatus,
    nextStepStatus: depositStatus,
    ...getCheckDeliveryAction({
      deductionStatus,
      deliveryStatus,
      baseTranslationPath,
      vendorPaymentActions,
      eligibleForPushToDebit: payment.eligibleForPushToDebit,
    }),
  });

  const deliveryStep = getCheckDeliveryStep();

  const getCheckDepositStep = (): TimelineStepType => ({
    title: `${baseTranslationPath}.${CLEAR}.title.${deliveryStatus}`,
    dates: [getCheckDepositedDate(payment.transactions)],
    status: depositStatus,
  });

  const depositStep = getCheckDepositStep();

  return [scheduledStep, deductionStep, deliveryStep, depositStep];
};

export { getCheckSteps };
