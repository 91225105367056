import React, { useCallback, useState } from 'react';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/app/utils/locations';
import { BillType, PaymentType } from 'src/app/utils/types';
import RequestRefundReason from './RequestRefundReason';
import RequestRefundReview from './RequestRefundReview/RequestRefundReview';
import { Option, RequestRefundStepPropsType, Steps } from './types';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';

type RenderCurrentStepProps = {
  currentStep: Steps;
  props: RequestRefundStepPropsType;
};

const renderCurrentStep = ({ currentStep, props }: RenderCurrentStepProps) => {
  switch (currentStep) {
    case 'request-refund-reason':
      return <RequestRefundReason {...props} />;
    case 'request-refund-review':
      return <RequestRefundReview {...props} selectedReason={props.selectedReason as Option} />;
    default:
      return null;
  }
};

type Props = {
  payment: PaymentType;
  bill: BillType;
};

export const RequestRefund = ({ payment, bill }: Props) => {
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const [selectedReason, setSelectedReason] = useState<Option | null>(null);
  const [currentStep, setCurrentStep] = useState<Steps>(
    isRefundFlowFeature ? 'request-refund-review' : 'request-refund-reason'
  );
  const [historyPush] = useHistoryWithOrgId();
  const goExit = useCallback(() => {
    historyPush({
      path: locations.Bills.payment.url({
        billId: bill.id,
        paymentId: payment.id,
      }),
    });
  }, []);

  const goNext = useCallback(() => {
    setCurrentStep(isRefundFlowFeature ? 'request-refund-sent' : 'request-refund-review');
  }, []);

  return renderCurrentStep({
    currentStep,
    props: { payment, bill, goExit, goNext, selectedReason, setSelectedReason },
  });
};
