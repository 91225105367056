import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from '../../utils/formatting';

type Props = {
  header: string;
};

const BillGroupHeader = ({ header }: Props): React.ReactElement => (
  <BillHeaderContainer>
    <MIFormattedText label={header} />
  </BillHeaderContainer>
);

const BillHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.sectionTitleM};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  line-height: 2.5rem;
  margin-bottom: 0.6rem;
  ${(props) => props.theme.components?.BillGroupHeader?.BillHeaderContainer}
`;

export default BillGroupHeader;
