import React from 'react';
import { format } from 'date-fns';
import { Box } from '@melio/billpay-design-system';

import { DATE_FORMATS } from 'src/app/utils/date-fns';

type Props = {
  date: string | Date | undefined;
};

const QBOTableDate = ({ date }: Props) => {
  const formatDate = date
    ? format(new Date(date), DATE_FORMATS.monthShortWithLongDateAndYear)
    : '-';

  return <Box>{formatDate}</Box>;
};

export default QBOTableDate;
