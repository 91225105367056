import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { OrderedSet, RecordOf } from 'immutable';
import { VendorType, UserContextType } from 'src/app/utils/types';
import QBOVendorsListPage from './components/QBOVendorsListPage';
import vendorsApi from '../../services/api/vendors';
import { GlobalState } from '../../redux/types';
import { getProfile } from '../../redux/user/selectors';
import { VendorRecord } from './records-constants';

type MapStateToProps = {
  profile: RecordOf<UserContextType>;
};
type Props = MapStateToProps & {
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
};

type State = {
  vendors: OrderedSet<RecordOf<VendorType>>;
  isListLoading: boolean;
};

class QBOVendorsListPageContainer extends React.PureComponent<Props, State> {
  static defaultProps = {};

  _isMounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      vendors: OrderedSet(),
      isListLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadVendors();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadVendors = () => {
    const { profile } = this.props;

    this.setState({ isListLoading: true });
    vendorsApi
      .getVendors({ orgId: profile.orgId, filters: { search: '' } })
      .then(({ objects: vendors }) => {
        if (this._isMounted) {
          this.setState({
            isListLoading: false,
            vendors: OrderedSet(vendors.map((vendor) => VendorRecord(vendor))),
          });
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isListLoading: false });
        }
      });
  };

  render() {
    const { vendors, isListLoading } = this.state;

    return <QBOVendorsListPage vendors={vendors} isListLoading={isListLoading} />;
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
});

export default compose(connect(mapStateToProps, undefined))(QBOVendorsListPageContainer);
