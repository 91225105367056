import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SmartIcon, Text } from '@melio/billpay-design-system';
import { batchBulkSelectors } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  DEDUCTION_DATE_TYPES,
  DeductionDateObjectType,
  DeductionSelectProps,
} from '../DeductionDateSelect.consts';
import * as Styles from '../DeductionDateSelect.styles';

export const DeductionDateType = ({ onSelect, disabled = false }: DeductionSelectProps) => {
  const { formatMessage } = useIntl();
  const hasSameDates = useSelector(batchBulkSelectors.selectHasSameDates);
  const isCombined = useSelector(batchBulkSelectors.selectIsCombined);
  const [selectedDeductionDate, setSelectedDeductionDate] = useState<DeductionDateObjectType>(
    DEDUCTION_DATE_TYPES[1]
  );

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDeductionDate(DEDUCTION_DATE_TYPES[1]);
  }, [isCombined]);

  const menuTriggerHandler = () => setIsMenuOpen(!isMenuOpen);

  const onItemSelectHandler = (type: DeductionDateObjectType) => {
    setSelectedDeductionDate(type);

    onSelect(type);
  };

  const isDueDate = selectedDeductionDate.id === DEDUCTION_DATE_TYPES[0].id;
  const hasSameDateMultiDates =
    selectedDeductionDate.id === DEDUCTION_DATE_TYPES[1].id && !hasSameDates;
  const hasMultipleDates = !isDueDate && !disabled && hasSameDateMultiDates;

  return (
    <Styles.DeductionDateType
      placement="bottom-start"
      onOpen={menuTriggerHandler}
      onClose={menuTriggerHandler}
      autoWidth
    >
      <Styles.DeductionDateMenuButtonContainer>
        <Styles.DeductionDateMenuButton disabled={disabled}>
          <Styles.DeductionDateButtonLabel>
            <Text as={Styles.DeductionDateButtonContent}>
              {formatMessage({
                id: hasMultipleDates
                  ? 'batchBulkPage.headSection.deductionDate.types.multi'
                  : selectedDeductionDate.text,
              })}
            </Text>
          </Styles.DeductionDateButtonLabel>
          <Styles.ExpandCollapseMenuIcon $isOpen={isMenuOpen} aria-disabled={disabled}>
            <SmartIcon type="arrow-dropdown" />
          </Styles.ExpandCollapseMenuIcon>
        </Styles.DeductionDateMenuButton>
      </Styles.DeductionDateMenuButtonContainer>
      <Styles.DeductionDateMenuList data-testid="deduction-date-menu">
        {DEDUCTION_DATE_TYPES.map((type) => (
          <Styles.DeductionDateMenuItem onClick={() => onItemSelectHandler(type)} key={type.id}>
            {formatMessage({ id: type.text })}
          </Styles.DeductionDateMenuItem>
        ))}
      </Styles.DeductionDateMenuList>
    </Styles.DeductionDateType>
  );
};
