import React from 'react';
import { Box, Link } from '@melio/billpay-design-system';

type Props = {
  chunks: React.ReactNode;
  href?: string;
};

const InlineLink = ({ chunks, href }: Props) => (
  <Box as="span" color="blue.500" cursor="pointer">
    <Link isExternal href={href} textDecoration="none !important">
      {React.Children.toArray(chunks)}
    </Link>
  </Box>
);

export { InlineLink };
