import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Modal } from '@melio/billpay-design-system';
import { Container } from 'src/app/version-2/pages/virtual-card-details/components/HowToUseCardModal/HowToUseCardModal.style';
import { HowToUseVirtualCard } from 'src/app/version-2/pages/virtual-card-details/components/HowToUseVirtualCard';
import { virtualCardDetailsActions } from 'src/app/version-2/pages/virtual-card-details/modules/virtualCardDetails.slice';

interface HowToUseCardModalProps {
  isOpen: boolean;
}

export const HowToUseCardModal = ({ isOpen }: HowToUseCardModalProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const closeModal = () => {
    dispatch(virtualCardDetailsActions.setHowToUseCardModalAsClosed());
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.CloseButton />
      <Modal.Body>
        <Container>
          <HowToUseVirtualCard isLeftAlign />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonContent isCentred>
          <Modal.Button onClick={closeModal} size="md" variant="primary">
            {formatMessage({ id: 'vendors.virtualCardDetails.valid.processCard.modalCTA' })}
          </Modal.Button>
        </Modal.ButtonContent>
      </Modal.Footer>
    </Modal>
  );
};
