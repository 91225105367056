import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import OutsideFooter from 'src/app/components/common/OutsideLayout/OutsideFooter';
import { Box, Flex, Image } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import { PaymentCollectStatusEnum } from 'src/app/version-2/model/enums';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import { PaymentType, DeliveryMethodType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import { useBreak } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import PosExampleDesktop from 'src/app/images/qbo/vc-details/pos-example-desktop.jpg';
import PosExampleMobile from 'src/app/images/qbo/vc-details/pos-example-mobile.jpg';
import { BackButton } from '../../../components/VendorLayoutElements';
import {
  VirtualCardOptInContainer,
  VirtualCardOptInContentContainer,
  VirtualCardOptInContentHeader,
  VirtualCardOptInContentPaymentSection,
  VirtualCardOptInContentPosHeader,
  VirtualCardOptInMobileButtonsContainer,
} from './StyledComponents';
import VirtualCardOptInActionButtons from './VirtualCardOptInActionButtons';
import optinDictionary from './optinDictionary';

type PropsType = {
  token: string;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  deliveryMethodId: string;
  referrer: string;
};

export const QBOVirtualCardOptInPage = ({
  token,
  payment,
  deliveryMethod,
  deliveryMethodId,
  referrer,
}: PropsType) => {
  const history = useHistory();
  const { isMobile } = useBreak();

  useEffect(() => {
    analytics.trackAction('payment-opt-in-page-loaded', {
      vendorId: payment?.vendor.id,
      paymentId: payment?.id,
      vendorEmail: payment?.vendor?.contactEmail,
      payorOrgId: payment?.organization.id,
      isEligibleToVirtualCard: payment?.isEligibleToVirtualCard,
    });
  }, []);

  const isVirtualPayment = payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;
  const isPaymentCollected = payment?.collectStatus === PaymentCollectStatusEnum.SENT;

  const optInActionButtonsProps = {
    isVirtualPayment,
    token,
    deliveryMethod,
    deliveryMethodId,
    isMobile,
    payment,
    referrer,
  };

  return (
    <Flex direction="column" color="ds.black">
      <QBOLayoutPage hideHeader contentWrapperMode="success">
        <Box textAlign="center" marginBottom={5} marginTop={10}>
          <QBLogo />
        </Box>
        <VirtualCardOptInContainer isMobile={isMobile}>
          {!isMobile && (
            <Box
              position={isMobile ? 'relative' : 'absolute'}
              left={isMobile ? 0 : 6}
              top={isMobile ? 0 : 6}
            >
              <BackButton
                isAbsolutePosition={false}
                onPrev={() => {
                  history.goBack();
                }}
              />
            </Box>
          )}
          <VirtualCardOptInContentContainer>
            <VirtualCardOptInContentHeader
              isMobile={isMobile}
              isVirtualPayment={isVirtualPayment}
            />
            <Flex direction="column" gridGap={5}>
              <Box textStyle="body3" color="gray.500" fontWeight="ds.medium">
                <MIFormattedText label={optinDictionary.paymentDetails.header} />
              </Box>
              <VirtualCardOptInContentPaymentSection
                payment={payment}
                isPaymentCollected={isPaymentCollected}
              />
              <Flex direction="column" bg="gray.50" padding="5" borderRadius="0.8rem" gridGap={5}>
                <VirtualCardOptInContentPosHeader isMobile={isMobile} />
                <Box>
                  <Image
                    src={isMobile ? PosExampleMobile : PosExampleDesktop}
                    width="full"
                    borderRadius="1rem"
                  />
                </Box>
                {isMobile ? (
                  <Box textStyle="body4" color="black" textAlign="center">
                    <MIFormattedText
                      label={optinDictionary.info}
                      values={{
                        organizationName: payment?.organization.companyName,
                      }}
                    />
                  </Box>
                ) : (
                  <Flex direction="column" textAlign="center" gridGap="0.8rem">
                    <VirtualCardOptInActionButtons {...optInActionButtonsProps} />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </VirtualCardOptInContentContainer>
        </VirtualCardOptInContainer>
        {!isMobile && <OutsideFooter showSupport={false} />}
      </QBOLayoutPage>
      {isMobile && (
        <VirtualCardOptInMobileButtonsContainer>
          <VirtualCardOptInActionButtons {...optInActionButtonsProps} />
        </VirtualCardOptInMobileButtonsContainer>
      )}
    </Flex>
  );
};
