import React from 'react';
import BillCreateOptions from './BillCreateOptions';
import InvoiceFilePreview from '../../../components/layout/InvoiceFilePreview';

type Props = {
  orgId: string;
  handleUploadStart: () => void;
  onUploadRemove: () => void;
  goWithUploadedBill: (file: File, orgId: string) => void;
  fileStorageUrl: string;
  filePreviewUrls: string[];
  handleUploadError: () => void;
};
const BillCreateOptionsContainer = ({
  orgId,
  handleUploadStart,
  goWithUploadedBill,
  onUploadRemove,
  fileStorageUrl,
  filePreviewUrls,
  handleUploadError,
}: Props) => (
  <>
    {fileStorageUrl ? (
      <InvoiceFilePreview
        onUploadRemove={onUploadRemove}
        fileStorageUrl={fileStorageUrl}
        filePreviewUrls={filePreviewUrls}
      />
    ) : (
      <BillCreateOptions
        flavor="block"
        orgId={orgId}
        onUploadStart={handleUploadStart}
        onUploadComplete={goWithUploadedBill}
        onUploadError={handleUploadError}
      />
    )}
  </>
);

export default BillCreateOptionsContainer;
