import * as React from 'react';
import styled from 'styled-components';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import qboLogo from '../../images/qbo/logo-qbo-footer-with-jpm-no-svb.svg';
import { QBOHeader as QBOHeaderContainer } from './QBOHeader';

const QBOLayoutContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  position: relative;

  @media ${devices.mobile} {
    align-items: initial;
  }
`;

const QBOFooterContainer = ({ hasBorder = false }) => {
  const site = useSiteContext();

  return (
    <QBOFooter className="qbo-footer" hasBorder={hasBorder}>
      <MelioFooterLogo>
        <QBOLogo src={qboLogo} alt="qboLogo" />
      </MelioFooterLogo>
      <MelioFooterText>
        <MIFormattedText label="qbo.footer.poweredBy" />
      </MelioFooterText>
      <FooterSettingsTextWrap>
        <FooterSettingsText
          href={site.config.agreementLinks.termsOfService}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MIFormattedText label="qbo.footer.termsOfService" />
        </FooterSettingsText>
        <FooterSettingsText
          href={site.config.agreementLinks.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MIFormattedText label="qbo.footer.privacyPolicy" />
        </FooterSettingsText>
      </FooterSettingsTextWrap>
    </QBOFooter>
  );
};

const QBOLogo = styled.img`
  height: 2.4rem;
`;

export const QBOFooter = styled.div<{ hasBorder: boolean }>`
  display: flex;
  align-items: center;
  color: rgba(113, 113, 115);
  font-size: 1rem;
  width: 100%;
  padding: 0 1.6rem;
  box-sizing: border-box;
  margin-top: auto;
  height: 4rem;
  background-color: rgba(236, 237, 240);

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1.6rem 1.2rem;
    height: auto;
    min-height: 8.8rem;
    background-color: #f4f5f8;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    ${({ hasBorder }) => (hasBorder ? 'border: 0 solid #dce0e5; border-top-width: 1px;' : '')}
  }

  ${(props) => props.theme?.components?.QBOFooterContainer?.QBOFooter}
`;

const MelioFooterLogo = styled.div`
  padding-right: 1.6rem;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    padding: 0;
    margin-bottom: 0.8rem;
  }
`;

const FooterSettingsTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    > a {
      margin: 0;
    }
    > a:last-child {
      margin-left: 1.5rem;
    }
  }
`;

const FooterSettingsText = styled.a`
  text-decoration: none;
  color: rgba(28, 130, 210);
  margin-left: 1.6rem;
  &:hover {
    text-decoration: underline;
  }
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: flex;
  }
`;

const MelioFooterText = styled.a`
  line-height: 1.4rem;
  letter-spacing: -0.01rem;
  strong {
    font-weight: 600;
  }
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    text-align: center;
    margin-bottom: 0.8rem;
  }
`;

export { QBOLayoutContainer, QBOHeaderContainer, QBOFooterContainer };
