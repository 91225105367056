import { ClientDeviceDataHeaderName, getDeviceDataHeader } from '@melio/risk-data-collection';
import { featureFlags } from '@melio/shared-web';
import { loggingApi } from 'src/app/version-2/api/loggers/logging.api';
import { requestLogging } from 'src/app/version-2/api/rest/rest.utils';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { LocalStorageKeysEnum } from 'src/app/version-2/model/enums/localStorageKeys.enum';
import { ServerError } from 'src/app/version-2/model/objects/ServerError';
import { get2FAContext, shouldUse2FAHeaders } from 'src/app/version-2/utils/headers-2fa.utils';
import { getPathParams } from 'src/app/version-2/utils/query.utils';

import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

interface Headers {
  'Cache-Control': string;
  'x-site-context': string;
  'x-2fa-tokens'?: string;
  'x-2fa-context'?: string;
  'client-request-id'?: string;
  Authorization?: string;
  [ClientDeviceDataHeaderName]: string;
}

export interface InitInterceptorsParams {
  instance: AxiosInstance;
  siteContextName: string;
  authToken?: string;
}

export const interceptRequestHeaders =
  (siteContextName: string, authToken?: string) => (config: InternalAxiosRequestConfig) => {
    const headers: Partial<Headers> = {
      'Cache-Control': 'no-cache',
      'x-site-context': siteContextName,
      'client-request-id': uuidv4(),
      ...getDeviceDataHeader(),
    };

    try {
      const deviceDataHeader = getDeviceDataHeader();

      if (isEmpty(deviceDataHeader)) {
        loggingApi.info(
          'restInterceptors.interceptRequestHeaders(): risk-data-collection-device-data-header',
          {
            deviceData: JSON.stringify(deviceDataHeader),
            url: config.url,
          }
        );
      }
    } catch (e: unknown) {
      const typedError = e as Error;

      loggingApi.error('restInterceptors.interceptRequestHeaders(): risk-data-collection', {
        message: typedError.message,
        stack: typedError.stack,
      });
    }

    if (shouldUse2FAHeaders(siteContextName, false)) {
      const stored2FATokens = localStorage.getItem(LocalStorageKeysEnum.TWO_FA_TOKENS);

      if (stored2FATokens) {
        headers['x-2fa-tokens'] = stored2FATokens;
      }

      headers['x-2fa-context'] = get2FAContext(siteContextName);
    }

    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }

    config.headers = { ...config.headers, ...headers } as unknown as typeof config.headers;

    requestLogging(config);

    return config;
  };
export const interceptResponseHeaders = (siteContextName: string) => (response: AxiosResponse) => {
  if (shouldUse2FAHeaders(siteContextName, false)) {
    const x2faTokensHeader = response.config.headers?.['x-2fa-tokens'];

    if (x2faTokensHeader) {
      localStorage.setItem(LocalStorageKeysEnum.TWO_FA_TOKENS, x2faTokensHeader);
    }
  }

  return response;
};

export const interceptNoContentResponse = (response: AxiosResponse) => {
  if (response.config.method?.toLowerCase() === 'delete' && response.status === 204) {
    response.data = {
      success: true,
      code: 'OK',
    };
  }

  return response;
};
export const interceptErrorResponse = (error) => {
  const { config, response } = error;
  const { status } = response || { status: 400 };

  if (status >= 500) {
    loggingApi.error(`restInterceptors.interceptErrorResponse(): server error`, {
      originalRequest: config,
      status,
      message: error.message,
    });
  }

  if (config?.isBypassThrowError) {
    return;
  }

  if (status === 401) {
    window.location.hash = 'unauthorized';
    window.location.reload();
  }

  if (error.response) {
    return Promise.reject(new ServerError({ ...config, ...error.response?.data }));
  }

  return Promise.reject(error);
};

export const interceptUnsuccessfulResponse = (response: AxiosResponse) => {
  const { success } = response.data;

  if (!success) {
    return Promise.reject(new ServerError({ ...response.request, ...response.data }));
  }

  return response;
};
// interceptUnauthorizedErrorResponse;
export const interceptSuccessfulResponse = (response: AxiosResponse) => {
  const { data, message, code, json } = response.data;

  return { ...data, ...json, message, code };
};

export const interceptResponseLogging = (response: AxiosResponse): AxiosResponse => {
  const isLogPerRequestResponseFeatureFlag = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.BP_LOG_PER_REQUEST_RESPONSE,
    false
  );

  if (isLogPerRequestResponseFeatureFlag) {
    try {
      loggingApi.info('restInterceptors.interceptResponseLogging(): middleware-logger', {
        url: response.config?.url,
        urlParams: response.config?.url ? getPathParams(response.config.url) : null,
        clientRequestId: response.config?.headers['client-request-id'],
        data: response.data,
        status: response.status,
        responseURL: response.request.responseURL,
        baseURL: response.config?.baseURL,
        queryParams: response.config?.params,
        method: response.config?.method,
      });
    } catch (error: any) {
      loggingApi.error('restInterceptors.interceptResponseLogging(): middleware-logger', {
        message: error.message,
      });
    }
  }

  return response;
};
export const initRestInterceptors = ({
  instance,
  siteContextName,
  authToken,
}: InitInterceptorsParams) => {
  instance.interceptors.request.clear();
  instance.interceptors.response.clear();
  instance.interceptors.request.use(interceptRequestHeaders(siteContextName, authToken));
  instance.interceptors.response.use(interceptResponseLogging);
  instance.interceptors.response.use(interceptResponseHeaders(siteContextName));
  instance.interceptors.response.use(interceptNoContentResponse);
  instance.interceptors.response.use(interceptUnsuccessfulResponse, interceptErrorResponse);
  instance.interceptors.response.use(interceptSuccessfulResponse);
};
