import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { generatePath, useHistory } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import {
  DeliveryMethodType,
  PaymentType,
  VendorType,
  PaymentFeeItem,
  OrganizationType,
} from 'src/app/utils/types';
import vendorLocations from 'src/app/pages/vendor/locations';
import shiftToAchLocations from 'src/app/pages/vendor/shift-vendor-to-ach/locations';
import { isEligibleToReceiveVirtualCard } from 'src/app/utils/payments';
import { pushNotification } from 'src/app/services/notifications';
import { CONSTS, NOTIFICATION_VARIANT, PAYMENT_STATUS } from 'src/app/utils/consts';
import { FundingSource } from 'src/app/version-2/model/dtos';
import selectExpeditedLocations from '../locations';
import { getIsVendorPaymentDetailsFlow } from '../../vendor-payment-tracking/utils';
import {
  EXPEDITE_OPTIONS,
  TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH,
} from '../../vendor-payment-tracking/consts';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from '../../vendor-payment-tracking/locations';
import { useSelectExpeditedHooksState } from '../hooks/useSelectExpeditedHooksState';

export type SelectExpediteStateType = {
  payment: PaymentType;
  organization: any;
  isPaymentLoading: boolean;
  deliveryMethod: DeliveryMethodType;
  vendor: VendorType;
  fee?: PaymentFeeItem;
};

export type ShiftToDebitDeliveryMethodActionsType = {
  validateAddress: (address) => void;
};

export function useSelectExpeditedState(token, isShiftCheckDeliveryMethodPath) {
  const history = useHistory();

  const { paymentId, vendorId } = getJWTPayload(token);

  const [isPaymentLoaded, setIsPaymentLoaded] = useState<boolean>(false);
  const [checkToAchVCFeatureFlag] = featureFlags.useFeature('check-to-vc-ach');

  const paymentActions = useStoreActions(paymentStore);
  const fetchPaymentDetailsWithToken = useCallback(async () => {
    try {
      await paymentActions.fetchPaymentDetailsWithToken({
        token,
      });
      setIsPaymentLoaded(true);
    } catch (error: any) {
      if (error.error.code === 'VT3') {
        history.push(generatePath(selectExpeditedLocations.invalidToken, { id: vendorId, token }));
      } else {
        history.push(generatePath(selectExpeditedLocations.invalidToken, { token }));
      }
    }
  }, [paymentActions, token, history]);

  useEffect(() => {
    fetchPaymentDetailsWithToken();
  }, [fetchPaymentDetailsWithToken]);

  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));

  const { isLoading: isPaymentLoading, fee } = useSelectExpeditedHooksState(paymentId);

  const isLoading = isPaymentLoading;

  const currentDeliveryMethod: DeliveryMethodType = useSelector(
    deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId)
  );
  const organization: OrganizationType = useSelector(
    organizationStore.selectors.byId(payment?.organization?.id)
  );
  const { vendor } = useFetchVendor(payment?.vendorId);

  useEffect(() => {
    if (payment && isPaymentLoaded) {
      if (payment.status === PAYMENT_STATUS.FAILED) {
        history.replace(
          generatePath(selectExpeditedLocations.invalidToken, { id: vendorId, token })
        );
      }

      if (payment.deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
        if (getIsVendorPaymentDetailsFlow(token)) {
          history.push(generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token }));

          pushNotification({
            type: NOTIFICATION_VARIANT.SUCCESS,
            msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.expedite.${EXPEDITE_OPTIONS.TO_VIRTUAL_CARD}`,
          });
        } else {
          history.push(generatePath(selectExpeditedLocations.virtualCardSuccess, { token }));
        }
      } else if (
        isShiftCheckDeliveryMethodPath &&
        payment.deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.CHECK
      ) {
        const isVirtualCardSupported = isEligibleToReceiveVirtualCard(
          payment.fundingSource as unknown as Partial<FundingSource>
        );

        if (!isVirtualCardSupported || !checkToAchVCFeatureFlag) {
          history.push(
            generatePath(shiftToAchLocations.index, { token, id: payment.deliveryMethod.id })
          );
        }
      } else {
        history.push(generatePath(vendorLocations.shiftToDebit.addDebitMethod, { token }));
      }
    }
  }, [payment, isPaymentLoaded]);

  return {
    state: {
      payment,
      organization,
      isPaymentLoading,
      deliveryMethod: currentDeliveryMethod,
      vendor,
      fee,
      isLoading,
    },
  };
}
