import { featureFlags } from '@melio/shared-web';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FeatureFlagsEnum, FundingSourcePageVariantsEnum } from 'src/app/version-2/model/enums';
import { checkEmptyFundingSources } from 'src/app/version-2/utils/paymentMethods.utils';

export const getFeatureFlagStatus = (flag, params = {}, defaultValue = false): boolean => {
  const status = featureFlags.defaultClient.getVariantNoTrack(flag, defaultValue);

  return status || defaultValue;
};

export const getLandingPageFeatureFlagStatus = (params = {}) => {
  const flagStatus = getFeatureFlagStatus(FeatureFlagsEnum.PLANS_LANDING_PAGE_FEATURE_FLAG, params);
  const landingPageToggle = getFeatureFlagStatus(
    FeatureFlagsEnum.PLANS_LANDING_PAGE_FEATURE_FLAG_TOGGLE,
    params
  );

  return flagStatus && landingPageToggle;
};

export const getFundingSourceVariant = (
  isFundingSourceExperiment: boolean,
  fundingSources: FundingSource[],
  hasPayments: boolean
): string => {
  if (!isFundingSourceExperiment || !checkEmptyFundingSources(fundingSources) || hasPayments) {
    return FundingSourcePageVariantsEnum.CONTROL;
  } else {
    return featureFlags.defaultClient.getVariantNoTrack(
      FeatureFlagsEnum.FUNDING_SOURCE_VARIANT,
      FundingSourcePageVariantsEnum.CONTROL
    );
  }
};
