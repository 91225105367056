import { DELIVERY_TYPE } from 'src/app/utils/consts';

export type ShouldAskForVendorEmailProps = {
  vendorContactEmail?: string;
  deliveryType?: string;
  isOwnedVendorExists: boolean | null;
};

export const shouldAskForVendorEmail = ({
  vendorContactEmail,
  deliveryType,
  isOwnedVendorExists,
}: ShouldAskForVendorEmailProps) => {
  const isUnilateral = deliveryType === DELIVERY_TYPE.VIRTUAL;
  const isContactEmailAlreadySet = !!vendorContactEmail || isUnilateral || isOwnedVendorExists;

  return !isContactEmailAlreadySet;
};
