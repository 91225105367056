import React from 'react';
import { Box, BoxProps, forwardRef, Skeleton } from '@melio/billpay-design-system';
import { devices } from 'src/app/version-2/utils/devices.utils';

import styled from 'styled-components';

export const ListWrapper = styled.div`
  min-width: 30rem;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

export const HeaderItem = styled(Box).attrs({ color: 'ds.gray.300' })``;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 22rem;
  overscroll-behavior-y: contain;
  overflow: auto;
`;

export const ListItem = styled.li`
  display: flex;
  font-size: 1.2rem;
  padding: 0.6rem 0;
  position: relative;
  color: ${(props) => props.theme.colors.ds.gray[100]};
  border-bottom: 1px solid ${(props) => props.theme.colors.ds.gray[700]};

  &:first-child {
    padding-top: 0.2rem;
  }
  &:last-child {
    border-bottom: 0;
  }

  div:nth-child(1),
  div:nth-child(2) {
    width: 40%;
  }
  div:nth-child(3) {
    width: 20%;
    text-align: end;
    padding-right: 0.5rem;
  }
`;

export const StyledLink = styled.span`
  ${({ theme }) => theme?.components?.BillOpenBalance?.ReviewLink}
  cursor: pointer;
`;

export const CloseAction = styled.div`
  display: flex;
  justify-content: end;
  color: ${(props) => props.theme.text.color.label};
  padding-top: 0.8rem;

  i {
    cursor: pointer;
    font-size: 2.2rem;
    color: ${(props) => props.theme.colors.ds.gray[200]};

    &:hover {
      color: ${(props) => props.theme.text.color.main};
    }
  }
`;

export const SkeletonLoading = styled(Skeleton)`
  width: 11.6rem;
  height: 1.2rem;
  margin-bottom: 1rem;
`;

export const PopoverTriggerWrapper = forwardRef<BoxProps, 'div'>(({ children, ...props }, ref) => (
  <Box tabIndex={0} {...props} _focus={{ outline: 'none' }} display="inline" ref={ref}>
    {children}
  </Box>
));
