import { useEffect, useMemo } from 'react';
import isUndefined from 'lodash/isUndefined';
import { useSelector, useDispatch } from 'react-redux';
import { CONSTS, DELIVERY_METHOD_ORIGIN } from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import { selectNewDeliveryMethodAction } from 'src/app/redux/payBillWizard/actions';
import { useForm } from 'src/app/ui/form';
import profileStore from 'src/app/modules/profile/profile-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { useLocationState } from 'src/app/utils/hooks';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import { AddFundingSourceWizardOriginEnum, ContactsTabEnum } from 'src/app/version-2/model/enums';

type Props = {
  vendorId: string;
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  shouldIgnorePhone?: boolean;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const useVirtualDeliveryMethodState = ({
  vendorId,
  navigate,
  shouldIgnorePhone = false,
  navigateWithPreservedState,
}: Props) => {
  const dispatch = useDispatch();
  const vendorActions = useStoreActions(vendorsStore);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const [origin] = useLocationState('origin', null);
  const [redirectUrl] = useLocationState('redirectUrl', null);
  const [exitUrl] = useLocationState('exitUrl', null);
  const [changeDeliveryMethodType] = useLocationState('changeDeliveryMethodType', null);
  const { vendor, isVendorLoading, fetchVendor } = useFetchVendor(vendorId);
  const { paymentStoreActions } = useJustPayStore();

  useEffect(() => {
    const shouldReloadVendor =
      vendor && [vendor.deliveryMethods, vendor.contactEmail].some(isUndefined);

    if (shouldReloadVendor) {
      fetchVendor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const isVendorUpdating = (
    useSelector(vendorsStore.selectors.update.status(vendorId)) as { loading: boolean }
  )?.loading;
  const existingVirtualDeliveryMethod = vendor?.deliveryMethods?.find(
    (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL
  );
  const isDeliveryMethodCreating = (
    useSelector(deliveryMethodsStore.selectors.create.status()) as { loading: boolean }
  )?.loading;
  const isDeliveryMethodUpdating = (
    useSelector(
      deliveryMethodsStore.selectors.update.status(existingVirtualDeliveryMethod?.id)
    ) as { loading: boolean }
  )?.loading;
  const isFormProcessing = isDeliveryMethodCreating || isVendorUpdating || isDeliveryMethodUpdating;

  const model = useMemo(
    () => ({
      deliveryType: CONSTS.DELIVERY_TYPE.VIRTUAL,
      virtualAccount: {
        email: existingVirtualDeliveryMethod?.virtualAccount?.email || vendor?.contactEmail,
        phone: shouldIgnorePhone
          ? ''
          : existingVirtualDeliveryMethod?.virtualAccount?.phone || vendor?.contactPhone,
      },
    }),
    [vendor]
  );

  const submitAction = async (value) => {
    let payload;

    if (existingVirtualDeliveryMethod?.id) {
      const shouldUpdateVirtualAccount =
        existingVirtualDeliveryMethod.virtualAccount?.email !== value.virtualAccount.email ||
        existingVirtualDeliveryMethod.virtualAccount?.phone !== value.virtualAccount.phone;

      if (shouldUpdateVirtualAccount) {
        ({ payload } = await deliveryMethodActions.update({
          orgId,
          vendorId,
          id: existingVirtualDeliveryMethod.id,
          deliveryMethod: value,
        }));
      } else {
        payload = existingVirtualDeliveryMethod;
      }
    } else {
      ({ payload } = await deliveryMethodActions.create({
        orgId,
        vendorId,
        ...value,
      }));
    }

    const shouldUpdateVendor =
      !vendor.contactEmail || (!vendor.contactPhone && value.virtualAccount.phone);

    if (shouldUpdateVendor) {
      let vendorData = {};

      if (!vendor?.contactEmail) {
        vendorData = {
          ...vendorData,
          contactEmail: value.virtualAccount.email,
        };
      }

      if (!vendor?.contactPhone) {
        vendorData = {
          ...vendorData,
          contactPhone: value.virtualAccount.phone,
        };
      }

      await vendorActions.update({
        orgId,
        id: vendorId,
        ...vendorData,
      });
    }

    if (origin === DELIVERY_METHOD_ORIGIN.JUST_PAY) {
      paymentStoreActions.justPay.justPayWizard.update({
        deliveryMethodId: payload.id,
        deliveryMethodType: payload.deliveryType,
      });
    }

    if (origin === DELIVERY_METHOD_ORIGIN.PAY_BILL || changeDeliveryMethodType) {
      dispatch(selectNewDeliveryMethodAction(payload));
    }

    const url =
      redirectUrl ||
      locations.Vendors.view.url({
        id: vendorId,
        type: ContactsTabEnum.VENDORS,
        orgId,
      });

    if (origin === AddFundingSourceWizardOriginEnum.BATCH_BULK) {
      dispatch(selectNewDeliveryMethodAction(payload));
      navigateWithPreservedState({ newDeliveryMethod: payload });

      return;
    }

    if (origin === DELIVERY_METHOD_ORIGIN.BATCH_VIRTUAL_PAY_BILLS) {
      navigate(redirectUrl, false, {
        newDeliveryMethod: {
          ...payload,
        },
      });
    } else {
      navigate(url);
    }
  };
  const [virtualDeliveryMethodVM, { submit }] = useForm(model, {
    submit: submitAction,
  });

  const goExit = () => {
    const url =
      exitUrl ||
      locations.Vendors.view.url({
        id: vendorId,
        type: ContactsTabEnum.VENDORS,
      });

    navigate(url);
  };

  const shouldShowDeliveryMethodPagePreloader = (vendorId && !vendor) || isVendorLoading;

  return {
    virtualDeliveryMethodVM,
    submit,
    vendor,
    isFormProcessing,
    isVendorLoading: shouldShowDeliveryMethodPagePreloader,
    goExit,
  };
};

export default useVirtualDeliveryMethodState;
