import React from 'react';
import { useSelector } from 'react-redux';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { PaymentType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

export const QBOPaymentAmountCell = ({ item }: Props) => {
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(item.paymentId));

  return <MIFormattedCurrency value={Number(payment?.amount)} currency="$" />;
};
