import { Text } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 67.8rem;
`;

export const MethodsTable = styled.table`
  width: 100%;
  max-width: 67.8rem;
  border-radius: 1rem;
  overflow: hidden;
  border: 0.1rem solid ${({ theme }) => theme.colors.ds.gray[700]};
  margin-bottom: 1rem;
  border-spacing: 0;
  table-layout: fixed;
`;

export const Row = styled.tr`
  display: flex;
  height: 100%;
`;

export const SectionColumn = styled.td`
  min-width: 15.9rem;
  background: ${({ theme }) => theme.colors.ds.gray[700]};
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  min-height: 4.7rem;
  font-size: ${({ theme }) => theme.textStyles.ds.body3.fontSize};
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeights.ds.regular};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  padding-left: 1.6rem;

  &:first-of-type {
    min-height: 1rem;
    padding: 1rem 1.6rem;
  }

  &:nth-child(3) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.ds.gray[600]};
  }
`;

export const SectionHeader = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  padding: 0;
  margin: 0;
`;

export const MethodColumn = styled.td<{ $isDebit?: boolean }>`
  background: ${({ theme }) => theme.colors.ds.white};
  width: 17.4rem;
  min-height: 16.8rem;
  padding-top: ${({ $isDebit }) => ($isDebit ? 0.7 : 1.4)}rem;
  border-right: 0.1rem solid ${({ theme }) => theme.colors.ds.gray[700]};

  &:last-of-type {
    border-right: none;
  }
`;

export const Column = styled.td`
  background: ${({ theme }) => theme.colors.ds.white};
  width: 17.2rem;
  height: 20rem;
  padding: 3.5rem 0 0;
  border-right: 0.1rem solid ${({ theme }) => theme.colors.ds.gray[700]};

  &:last-of-type {
    border-right: none;
  }
`;

export const ColumnItem = styled.div`
  display: flex;
  min-height: 4.7rem;
  align-items: center;
  font-size: ${({ theme }) => theme.textStyles.ds.body3.fontSize};
  padding-left: 1.6rem;

  > p {
    padding: 0 0.6rem 0 0.9rem;
    color: ${({ theme }) => theme.colors.ds.gray[100]};
  }

  &:nth-child(2) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.ds.gray[700]};
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.ds.gray[100]};
    }
  }
`;
