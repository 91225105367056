import React from 'react';
import { RecordOf } from 'immutable';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import QBMBillPaymentsActivity from 'src/app/pages/bill/components/QBMBillPaymentsActivity';
import { withTheme } from 'styled-components';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import { AreaLoader } from '@melio/billpay-design-system';
import { QBOBillDetailsForm } from 'src/app/pages/bill/components/QBOBillDetailsForm';
import QBOPageHeader from 'src/app/components/common/QBOPageHeader';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { MIFieldOrEmpty, MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { withBreak, withNavigator } from 'src/app/hoc';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import BillOpenBalanceInfo from 'src/app/components/list/BillOpenBalanceInfo/BillOpenBalanceInfo';
import {
  BillType,
  DeletePaymentActionType,
  FieldType,
  IntuitBankAccountType,
  NavigateType,
  PaymentType,
  QboBillAttachment,
  OrganizationPreferencesType,
  UserSummaryType,
} from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import { MICardTitle } from 'src/app/components/common/MICard';
import RightPanelPlaceholder from 'src/app/components/onboarding/RightPanelPlaceholder';
import { SingleViewLoadingContainer } from 'src/app/components/layout/Containers';
import {
  CONSTS,
  BILL_STATUS,
  PAYMENT_STATUS,
  DELETE_PAYMENT_ACTION,
  BILL_PAGE_TYPE,
  PAYMENT_APPROVAL_STATUS,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import { renderBlockedForPaymentDialog } from 'src/app/utils/vendors';
import analytics from 'src/app/services/analytics';
import {
  convertPartialPaymentStatusToBillStatus,
  deserializePaymentId,
  getActionOptions,
  getActionOptionsPartialPayments,
  getBillHeaderActionsOptions,
  getBillPaymentTag,
  getBillTag,
  isBillHasPartialPayments,
  isManuallyPaid,
  serializePaymentId,
  showMarkAsPaidBills,
} from 'src/app/utils/bills';
import { MIFloatedEditDoneButtons } from 'src/app/components/common/MIFloatedEditDoneButtons';
import { Option } from 'src/app/components/common/MISingleSelect';
import billsApi from 'src/app/services/api/bills';
import recurringBillsApi from 'src/app/services/api/recurringBills';
import withListContainer from 'src/app/hoc/withListContainer';
import paymentsApi from 'src/app/services/api/payments';
import { getStatusInfo } from 'src/app/utils/bill-details';
import { getCompanyInfo, getOrgId } from 'src/app/redux/user/selectors';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { Permissions } from 'src/app/utils/permissions';
import profileStore from 'src/app/modules/profile/profile-store';
import paymentsStore, {
  ApprovalDecisionType,
  getPaymentsActions,
} from 'src/app/modules/payments/payment-store';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import MIButton from 'src/app/components/common/MIButton';
import { FormContainer } from 'src/app/ui/form/FormElements';
import usersStore from 'src/app/modules/users/users-store';
import { getLatestPayment, getPaymentById } from 'src/app/utils/payments';
import { encodeQuery } from 'src/app/utils/query-utils';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { getBillListActions } from 'src/app/modules/batch-bills/batch-bills-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { CheckVendorPaymentPreferencesType } from 'src/app/modules/vendors/check-vendor-payment-preferences';
import quickPayLocations from 'src/app/pages/quickpay/locations';
import { isEnterPressed } from 'src/app/utils/events';
import intercomService from 'src/app/services/intercom';
import {
  beginRegularPayBillFlowAction,
  selectPaymentDatesAction,
} from 'src/app/redux/payBillWizard/actions';
import useFetchIntuitBankAccounts from 'src/app/modules/intuit-bank-accounts/hooks/useFetchIntuitBankAccounts';
import intuitBankAccountsStore from 'src/app/modules/intuit-bank-accounts/intuit-bank-accounts-store';
import { getStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getQBOBillAttachments } from 'src/app/pages/bill/components/utils';
import { withPartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import withNewBillData from '../../hoc/withNewBillData';
import { BillDetailsHeader } from '../BillDetailsHeader';
import BillAttachment from '../BillAttachment';
import BillStatus from '../BillStatus';
import { BillRecord } from '../../records';
import { QBMViewBillMarkAsPaidModal } from './modals/QBMViewBillMarkAsPaidModal';
import {
  BillDetailsCard,
  BillFormContainer,
  BillInfoContainer,
  BillOpenBalanceInfoContainer,
  ButtonsContainer,
  ButtonWrapper,
  FormContent,
  FormHeader,
  MarkAsPaidButton,
  MiCardTitleWrapper,
  PaymentDetails,
  PaymentDetailsCard,
  StyledFailedPaymentMessage,
  StyledMIInlineLink,
  ViewBillContainer,
  ViewBillQBOFooterWrapper,
} from './QBMViewBill.styles';
import { ScreenModeEnum } from 'src/app/version-2/model/enums';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';

type PaymentParamsType = {
  orgId: string;
  id: string;
  reason?: string | null;
};

type MapStateToProps = {
  orgId: string;
  permissions: Permissions;
  currentUser: any;
  approvalDecisionStatus: ApprovalDecisionType;
  scheduledBy: UserSummaryType;
  intuitBankAccounts: IntuitBankAccountType[];
  vendorPaymentPreferences: Record<string, any>;
  companyName: string;
  billId: string;
  paymentId: string;
  organizationPreferences: OrganizationPreferencesType;
};

type MapDispatchToProps = {
  approvePayment: (params: PaymentParamsType) => void;
  declinePayment: (params: PaymentParamsType) => void;
  getBillById: (params: Omit<PaymentParamsType, 'reason'>) => Promise<any>;
  beginRegularPayBillFlow: (billId: string, paymentId: string) => void;
  selectPaymentDates: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    deliveryPreference?: string
  ) => void;
  checkVendorPaymentPreferences: ({ orgId, id }) => Promise<any>;
};

type Props = {
  bill: RecordOf<BillType>;
  backPath: string;
  vendors?: Option[];
  intuitAccountsOptions?: Option[];
  device: {
    isMobile: boolean;
    isPhablet: boolean;
  };
  onChangeAttachment?: (file: File) => void | null;
  onDeleteAttachment?: () => void | null;
  onFieldChange?: (field: FieldType) => void;
  validationErrors?: Record<string, any>;
  theme: Record<string, any>;
  fileStorageUrl?: string;
  filePreviewUrls?: string[];
  isUploading?: boolean;
  isAttachmentLoading: boolean;
  query: {
    status?: string;
    start?: number;
    limit?: number;
    search?: string;
    paymentId?: string;
  };
  site: any;
  onLoadBill: (bill?: RecordOf<BillType>) => void;
  id: string;
  onSubmitBill: (cb: (isPassedValidation: boolean) => void) => void;
  navigate: NavigateType;
  billId: string;
  reloadBills: () => void;
  onCancelForm: (prevBill: RecordOf<BillType> | null) => void;
  isPartialPaymentsEnabled: boolean;
  isPartialPaymentsUIEnabled: boolean;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  isDeleting: boolean;
  isMarkAsPaidModalOpen: boolean;
  isVendorBlockedForPayment: boolean;
  mode: ScreenModeEnum;
  isLoading: boolean;
  recurringBill: RecordOf<BillType> | null;
  showDeclineModal: boolean;
  declineReason: string;
  prevBill: RecordOf<BillType> | null;
  qboBillAttachments: QboBillAttachment[] | null;
  billHasPartialPayments: boolean;
};

const eventPage = 'bill';

class QBMViewBill extends React.PureComponent<Props, State> {
  static defaultProps = { isListLoading: false };
  openBalanceRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    mode: ScreenModeEnum.VIEW,
    isDeleting: false,
    isVendorBlockedForPayment: false,
    isLoading: false,
    recurringBill: null,
    showDeclineModal: false,
    declineReason: '',
    prevBill: null,
    qboBillAttachments: null,
    isMarkAsPaidModalOpen: false,
    billHasPartialPayments: false,
  });

  componentDidMount() {
    this.loadBill();
  }

  componentDidUpdate(prevProps: Props) {
    const { billId, approvalDecisionStatus } = this.props;

    if (
      (prevProps.billId == null && billId) ||
      (prevProps.billId && billId && prevProps.billId !== billId) ||
      (prevProps.approvalDecisionStatus.loading &&
        prevProps.approvalDecisionStatus.loading !== approvalDecisionStatus.loading)
    ) {
      this.resetState();
      this.loadBill();
    }
  }

  onDeleteBill = () => {
    const { orgId, backPath, navigate, billId } = this.props;

    analytics.track(eventPage, 'delete-bill-confirmed');
    this.setState({ isLoading: true });
    billsApi
      .deleteBillById(orgId, billId)
      .then(() => {
        analytics.track(eventPage, 'delete-bill-success');
        navigate(backPath);
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onEditBill = (cb: (isPassedValidation: boolean) => void) => {
    const { onSubmitBill } = this.props;

    this.setState({ isLoading: true }, () => {
      onSubmitBill((isPassedValidation: boolean) => {
        this.setState({ isLoading: false });
        cb(isPassedValidation);
      });
    });
  };

  onDeletePayment = (payment?: PaymentType, type?: DeletePaymentActionType) => {
    analytics.track(eventPage, 'delete-payment-confirmed');

    switch (type) {
      case DELETE_PAYMENT_ACTION.RECURRING_CURRENT:
        this.deleteCurrentRecurringPaymentById(payment?.id);
        break;
      case DELETE_PAYMENT_ACTION.RECURRING_ALL:
        this.deleteAllRecurringPayments();
        break;
      default:
        this.deletePayment(payment?.id);
    }
  };

  onMarkBillPaymentStatus = async ({
    isPaid,
    intuitAccountId,
    paymentId,
    amount,
  }: {
    isPaid: boolean;
    intuitAccountId?: string | null;
    paymentId?: number | null;
    amount?: number | null;
  }) => {
    const {
      billId,
      orgId,
      bill: markedBill,
      navigate,
      isPartialPaymentsUIEnabled,
      site,
    } = this.props;
    const baseEventAction = isPaid ? 'paid' : 'unpaid';

    if (!markedBill) {
      return;
    }

    analytics.track(eventPage, `mark-as-${baseEventAction}`);
    this.setState({ isLoading: true });

    const { object: updatedBill } = await billsApi.markAsPaid({
      orgId,
      id: markedBill.id,
      isPaid,
      intuitAccountId,
      paymentId,
      amount,
      createOrigin: site.createOrigin.pay.payment,
    });

    analytics.track(eventPage, `mark-as-${baseEventAction}-success`);

    const isPartialMarkAsPaidBill =
      isPartialPaymentsUIEnabled &&
      updatedBill.balance > 0 &&
      updatedBill.balance < updatedBill.totalAmount;

    const status = !isPaid || isPartialMarkAsPaidBill ? BILL_STATUS.UNPAID : BILL_STATUS.PAID;
    const defaultFilters = getBillsDefaultFilters(status);
    let id = billId;

    if (isPartialPaymentsUIEnabled && isPaid) {
      const payment = updatedBill.payments.find((p) => p.manual && p.amount === amount);

      id = serializePaymentId(billId, payment.id);
    }

    await this.loadBill();

    await navigate(
      locations.Bills.filteredView.url({
        id,
        ...defaultFilters,
      })
    );
  };

  onClose = () => {
    const { navigate } = this.props;
    const defaultFilters = getBillsDefaultFilters(BILL_STATUS.PAID);

    navigate(
      locations.Bills.filteredViewWithoutId.url({
        ...defaultFilters,
      })
    );
  };

  onToggleMode = () => {
    const { bill, onCancelForm } = this.props;

    this.setState(
      ({ mode }) => ({
        mode: mode === ScreenModeEnum.VIEW ? ScreenModeEnum.EDIT : ScreenModeEnum.VIEW,
        // TODO: Use callback in setState when referencing the previous state
        // eslint-disable-next-line react/no-access-state-in-setstate
        prevBill: mode === ScreenModeEnum.VIEW ? bill : this.state.prevBill,
      }),
      () => {
        if (this.state.mode === ScreenModeEnum.EDIT) {
          analytics.track('bills', 'edit-bill-mode');
        } else {
          analytics.track('bills', 'cancel-edit-bill-mode');
          onCancelForm(this.state.prevBill);
        }
      }
    );
  };

  onEditBillFunc = () => {
    this.onEditBill((isPassedValidation) => {
      if (isPassedValidation) {
        this.setState({ mode: ScreenModeEnum.VIEW });
      }
    });
  };

  onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      this.onEditBillFunc();
    }
  };

  onDeleteClicked = () => {
    analytics.track('bills', 'delete-bill');
    this.setState({ isDeleting: true });
  };

  onDeleteBillCanceled = () => {
    analytics.track('bills', 'delete-bill-canceled');
    this.setState({ isDeleting: false });
  };

  onHideNonDeletableModal = () => {
    analytics.track('bills', 'hide-non-deletable-modal');
    this.setState({ isDeleting: false });
  };

  onMarkBillAsPaid = () => {
    const { intuitBankAccounts, isPartialPaymentsUIEnabled } = this.props;

    if (isPartialPaymentsUIEnabled) {
      analytics.trackAction('mark-as-paid-modal', { toggle: 'open' });
      this.setState({ isMarkAsPaidModalOpen: true });
    } else if (intuitBankAccounts.length === 1) {
      this.onMarkBillPaymentStatus({ isPaid: true, intuitAccountId: intuitBankAccounts[0]?.id });
    } else {
      analytics.trackAction('mark-as-paid-modal', { toggle: 'open' });
      this.setState({ isMarkAsPaidModalOpen: true });
    }
  };

  onMarkBillAsUnpaid = (payment: PaymentType) =>
    this.onMarkBillPaymentStatus({ isPaid: false, paymentId: payment.id as unknown as number });

  onFailedRetryPayment = (payment: PaymentType) => {
    this.onRetryPayment(Number(payment.id));
  };

  onRetryPayment = (paymentId?: number) => {
    const { billId, navigate } = this.props;

    analytics.track(eventPage, 'retry-payment');
    navigate(
      locations.Bills.pay.edit.funding.url({
        id: billId,
        paymentId,
      })
    );
  };

  onRetryPaymentDelivery = (paymentId: number, deliveryMethodId?: number) => {
    const { billId, navigate } = this.props;

    analytics.track(eventPage, 'retry-payment-delivery');
    navigate(
      locations.Bills.pay.edit.deliveryMethodAch.url({
        id: billId,
        paymentId,
        deliveryMethodId,
      })
    );
  };

  onChangeDeliveryMethod = (paymentId: number) => {
    const { billId, navigate } = this.props;

    analytics.track(eventPage, 'retry-payment-delivery');
    navigate(
      locations.Bills.pay.edit.deliveryMethod.url({
        id: billId,
        paymentId,
      })
    );
  };

  onApproveDecision = (status: string, reason?: string | null) => {
    const { orgId, approvePayment, declinePayment, paymentId } = this.props;

    if (status === 'approved') {
      approvePayment({
        orgId,
        id: paymentId,
        reason,
      });
    } else {
      declinePayment({
        orgId,
        id: paymentId,
        reason,
      });
    }
  };

  onApprove = () => {
    this.onApproveDecision(PAYMENT_APPROVAL_STATUS.APPROVED);
  };

  onDecline = () => {
    this.setState({
      showDeclineModal: true,
    });
  };

  onTrackDelivery = () => {
    const { bill, paymentId } = this.props;

    analytics.track(eventPage, 'track-delivery');
    const payment = getPaymentById(bill.payments, paymentId);
    const trackingUrl = payment?.checkTracks?.[0]?.trackingUrl;

    if (trackingUrl) {
      window.open(trackingUrl);
    }
  };

  getHeaderSubtitle = () => {
    const { bill } = this.props;
    const { recurringBill } = this.state;

    if (bill.recurringBillId && recurringBill) {
      const { frequency, occurrences } = recurringBill;
      const index = bill.recurringBillIndex || null;

      return (
        <MIFormattedText
          label="bills.view.recurringBillInfo"
          values={{
            frequency,
            index,
            occurrences,
          }}
        />
      );
    }

    return bill.invoiceNumber ? (
      <MIFormattedText
        label="bills.view.invoiceNumber"
        values={{ invoiceNumber: bill.invoiceNumber }}
      />
    ) : (
      <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.form.invoiceNumberEmpty" />
    );
  };

  redirectDefaultScheduledBills = () => {
    const { site, navigate, orgId } = this.props;

    if (site.quickpayList) {
      navigate(generatePath(quickPayLocations.list.index, { orgId }));
    } else {
      const defaultFilters = getBillsDefaultFilters(BILL_STATUS.SCHEDULED);

      navigate(locations.Bills.filteredViewWithoutId.url(defaultFilters));
    }
  };

  deletePayment = async (paymentId) => {
    const { orgId, reloadBills, site } = this.props;

    this.setState({ isLoading: true });

    try {
      await paymentsApi.deletePaymentById(orgId, paymentId, {
        deleteBill: site.deleteBillWithPayment,
      });
      analytics.track(eventPage, 'delete-payment-success');
      this.setState({ isLoading: false });
      this.redirectDefaultScheduledBills();
      reloadBills();
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  deleteCurrentRecurringPaymentById = async (paymentId) => {
    const { orgId, bill, navigate, reloadBills } = this.props;
    const { recurringBill } = this.state;

    this.setState({ isLoading: true });

    try {
      await recurringBillsApi.deleteCurrentRecurringPaymentById(orgId, paymentId);
      analytics.track(eventPage, 'delete-current-recurring-bill-payment-success');

      const nextRecurringBillIndex = bill.recurringBillIndex + 1;

      if (recurringBill && nextRecurringBillIndex > recurringBill.occurrences) {
        this.setState({ isLoading: false });
        this.redirectDefaultScheduledBills();
        reloadBills();
      } else {
        const { objects: bills } = await billsApi.getBills({
          orgId,
          filters: {
            recurringBillId: bill.recurringBillId,
            recurringBillIndex: nextRecurringBillIndex,
          },
        });
        const defaultFilters = getBillsDefaultFilters(BILL_STATUS.SCHEDULED);
        const url =
          bills[0] && bills[0].id
            ? locations.Bills.filteredView.url({
                id: bills[0].id,
                ...defaultFilters,
              })
            : locations.Bills.filteredViewWithoutId.url(defaultFilters);

        this.setState({ isLoading: false });
        navigate(url);
        reloadBills();
      }
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  deleteAllRecurringPayments = async () => {
    const { orgId, bill, reloadBills } = this.props;

    this.setState({ isLoading: true });

    try {
      await recurringBillsApi.deleteAllRecurringPayments(orgId, bill.recurringBillId);
      analytics.track(eventPage, 'delete-all-recurring-bill-payments-success');
      this.setState({ isLoading: false });

      this.redirectDefaultScheduledBills();
      reloadBills();
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  goPayBill = async () => {
    const {
      navigate,
      billId,
      checkVendorPaymentPreferences,
      orgId,
      bill,
      vendorPaymentPreferences,
    } = this.props;

    analytics.track(eventPage, 'pay-bill');

    if (vendorPaymentPreferences?.blockPayments) {
      this.setState({ isVendorBlockedForPayment: true });
    } else {
      const { payload } = await checkVendorPaymentPreferences({
        orgId,
        id: bill.vendorId,
      });

      if (payload?.blockPayments) {
        this.setState({ isVendorBlockedForPayment: true });
      } else {
        navigate(locations.Bills.pay.funding.url({ id: billId }));
      }
    }
  };

  goEditPayment = (payment: PaymentType) => {
    const { billId, site, orgId, navigate } = this.props;

    analytics.track(eventPage, 'edit-bill');

    if (site.quickEditPayment) {
      const url = generatePath(quickPayLocations.edit.entry, {
        orgId,
        paymentId: payment.id,
      });

      navigate(url);
    } else {
      navigate(
        locations.Bills.pay.edit.funding.url({
          id: billId,
          paymentId: payment.id,
        })
      );
    }
  };

  resetState = () => {
    const initialState = this.getInitialState();

    this.setState(initialState);
  };

  onClickSupport = () => {
    intercomService.show();
    analytics.track('bills', 'payment-failed-contact-support');
  };

  loadBill = async () => {
    const { orgId, onLoadBill, getBillById, billId, isPartialPaymentsEnabled } = this.props;
    let bill;

    try {
      const response = await getBillById({
        orgId,
        id: billId,
      });
      const { object: billData } = response.payload;

      if (billData.recurringBillId) {
        const result = await recurringBillsApi.getOrgRecurringBills(orgId, {
          params: [billData.recurringBillId],
        });
        const recurringBill = result.recurringBills.pop();

        this.setState({ recurringBill });
      }

      const qboBillAttachments = await getQBOBillAttachments(billData.originId, orgId);

      this.setState({ qboBillAttachments });
      bill = BillRecord(billData);

      onLoadBill && onLoadBill(bill);
    } catch {
      onLoadBill && onLoadBill(BillRecord());
    } finally {
      this.setState({ isLoading: false });

      if (isPartialPaymentsEnabled) {
        this.setState({
          billHasPartialPayments: isBillHasPartialPayments(bill),
        });
      }
    }
  };

  renderDeleteModalDialog = () => {
    const { bill, site } = this.props;
    const isDeletable = get(bill, 'metadata.isDeletable', true);

    if (isDeletable) {
      return (
        <QBODialog
          type={DIALOG_TYPE.CONFIRM}
          variant={DIALOG_VARIANTS.ERROR}
          title="bills.form.deleteDialog.title"
          titleValues={{
            invoiceNumber: bill.invoiceNumber,
            companyName: bill.vendor.companyName,
          }}
          subtitle="bills.form.deleteDialog.subtitle"
          subtitleValues={{
            totalAmount: (
              <strong>
                <MIFormattedCurrency value={bill.totalAmount} />
              </strong>
            ),
            companyName: <strong>{bill.vendor.companyName}</strong>,
          }}
          okButtonText="bills.form.deleteDialog.confirm"
          onOkAction={this.onDeleteBill}
          onCancelAction={this.onDeleteBillCanceled}
        />
      );
    }

    return (
      <QBODialog
        type={DIALOG_TYPE.ALERT}
        variant={DIALOG_VARIANTS.ERROR}
        title="bills.form.nonDeletableDialog.title"
        subtitle="bills.form.nonDeletableDialog.subtitle"
        subtitleValues={{
          supportEmail: (
            <StyledMIInlineLink
              target="_self"
              text={site.config.support.email}
              to={`mailto:${site.config.support.email}`}
            />
          ),
        }}
        cancelButtonText="bills.form.nonDeletableDialog.ok"
        onCancelAction={this.onHideNonDeletableModal}
      />
    );
  };

  renderDeclineModalDialog = () => {
    const { showDeclineModal, declineReason } = this.state;
    const { approvalDecisionStatus, scheduledBy } = this.props;

    if (!showDeclineModal) {
      return null;
    }

    const onDecline = () => {
      this.onApproveDecision(PAYMENT_APPROVAL_STATUS.DECLINED, declineReason);
    };
    const onKeyPressed = (event: React.KeyboardEvent) => {
      if (isEnterPressed(event)) {
        onDecline();
        event.preventDefault();
      }
    };
    const onClose = () => this.setState({ showDeclineModal: false });

    return (
      <ModalMessage
        id="decline-payment-modal"
        titleComponent={
          <div className="title">
            <MIFormattedText
              label="bills.form.declineDialog.title"
              values={{
                userName: scheduledBy?.firstName,
              }}
            />
          </div>
        }
        contentComponent={
          <FormContainer onKeyDown={onKeyPressed}>
            <MITextInput
              id="declineNote"
              label="bills.form.declineDialog.noteTitle"
              placeholder="bills.form.declineDialog.placeholder"
              value={declineReason}
              autoFocus
              onChange={(e) => this.setState({ declineReason: e.value })}
            />
          </FormContainer>
        }
        buttonLabel="bills.form.declineDialog.buttonLabel"
        onButtonClick={onDecline}
        isLoading={approvalDecisionStatus.loading}
        onCloseClick={onClose}
      />
    );
  };

  renderMarkAsPaidModalDialog = () => {
    const { intuitBankAccounts, isPartialPaymentsUIEnabled, bill } = this.props;
    const options = intuitBankAccounts.map((account) => ({
      value: account.id,
      label: account.name,
    }));

    return (
      <QBMViewBillMarkAsPaidModal
        dismiss={() => {
          analytics.trackAction('mark-as-paid-modal', { toggle: 'close' });
          this.setState({ isMarkAsPaidModalOpen: false });
        }}
        isPartialPaymentsUIEnabled={isPartialPaymentsUIEnabled}
        balance={bill.balance}
        invoiceNumber={bill.invoiceNumber}
        options={options}
        submit={async ({ intuitAccountId, amount }) => {
          analytics.trackAction('mark-as-paid-modal', { toggle: 'close' });
          this.setState({ isMarkAsPaidModalOpen: false });
          this.onMarkBillPaymentStatus({
            isPaid: true,
            intuitAccountId,
            amount,
          });
        }}
      />
    );
  };

  renderErrorMessage = () => {
    const { bill, paymentId } = this.props;
    const payment = getPaymentById(bill.payments, paymentId);

    if (payment) {
      return <StyledFailedPaymentMessage bill={bill} payment={payment} />;
    }

    return null;
  };

  goViewDetails = () => this.openBalanceRef.current?.scrollIntoView({ behavior: 'smooth' });

  onLabelClick = (paymentId, paymentStatus) => {
    const { bill, navigate } = this.props;
    const status = convertPartialPaymentStatusToBillStatus(paymentStatus);
    const defaultFilters = getBillsDefaultFilters(status);

    navigate(
      locations.Bills.filteredView.url({
        id: serializePaymentId(bill.id, paymentId),
        ...defaultFilters,
      })
    );
  };

  handlePaymentsMadeClick = () =>
    this.openBalanceRef.current?.scrollIntoView({ behavior: 'smooth' });

  render() {
    const {
      bill,
      vendors,
      intuitAccountsOptions,
      device,
      onChangeAttachment,
      onDeleteAttachment,
      theme,
      onFieldChange,
      validationErrors,
      isUploading,
      query,
      fileStorageUrl,
      filePreviewUrls,
      isAttachmentLoading,
      permissions,
      currentUser,
      approvalDecisionStatus,
      backPath,
      site,
      paymentId,
      isPartialPaymentsEnabled,
      organizationPreferences,
    } = this.props;
    const {
      mode,
      isDeleting,
      isLoading,
      showDeclineModal,
      qboBillAttachments,
      isMarkAsPaidModalOpen,
      isVendorBlockedForPayment,
      billHasPartialPayments,
    } = this.state;

    const isFullSingleView = device.isMobile || device.isPhablet;
    const isPartialPayments = isPartialPaymentsEnabled && billHasPartialPayments;
    const showViewDetailsLink = isPartialPayments && query.status !== BILL_STATUS.UNPAID;
    const isUnpaidInboxBill = isPartialPayments
      ? query.status === BILL_STATUS.UNPAID
      : bill.status === BILL_STATUS.UNPAID;

    if (!bill || !bill.id) {
      return <RightPanelPlaceholder isLoading={isLoading} />;
    }

    const hasManualPayment = bill.payments.some((p) => p.manual);
    const manuallyPaid = isManuallyPaid(bill.status, hasManualPayment);
    const showMarkAsPaid = showMarkAsPaidBills(bill, permissions);
    const onCloseBlockPaymentDialog = () => this.setState({ isVendorBlockedForPayment: false });
    const actions = {
      onMarkBillAsPaid: this.onMarkBillAsPaid,
      onMarkBillAsUnpaid: this.onMarkBillAsUnpaid,
      onToggleMode: this.onToggleMode,
      onDeleteClicked: this.onDeleteClicked,
    };

    const actionOptions =
      isPartialPaymentsEnabled && billHasPartialPayments
        ? getActionOptionsPartialPayments({
            bill,
            mode,
            addMarkAsPaid: showMarkAsPaid,
            permissions,
            currentUser,
            isUnpaidInboxBill,
            actions,
          })
        : getActionOptions({
            bill,
            mode,
            addMarkAsPaid: showMarkAsPaid,
            permissions,
            currentUser,
            actions,
          });
    const relevantPayment = getPaymentById(bill.payments, paymentId);

    const isPaymentFailed = relevantPayment?.status === PAYMENT_STATUS.FAILED;
    const status =
      isPartialPaymentsEnabled && paymentId
        ? getBillPaymentTag(bill, relevantPayment)
        : getBillTag(bill);

    const isRequestAndHasNotDeliveryMethods =
      bill.isVendorRequest() && isEmpty(bill.vendor.deliveryMethods);
    const statusInfo = getStatusInfo(
      { status },
      theme,
      relevantPayment as PaymentType,
      relevantPayment ? relevantPayment.manual : manuallyPaid,
      isRequestAndHasNotDeliveryMethods,
      bill.vendor.companyName
    );
    const billsSearchPath = encodeQuery(query, ['id'], '');
    const isEditMode = mode === ScreenModeEnum.EDIT;
    const showPageHeader = !(isEditMode && isFullSingleView);
    const canSchedulePayment = isUnpaidInboxBill && !isRequestAndHasNotDeliveryMethods;
    const headerSubtitle = this.getHeaderSubtitle();
    const { recurringBill } = this.state;
    const isMarkAsPaid = find(actionOptions, (el) => el.label === 'bills.actions.markAsPaid');
    const showBillPaymentActivity = !isEmpty(bill.payments);
    const showBillPaymentDetails = !isPartialPaymentsEnabled
      ? statusInfo || status === PAYMENT_STATUS.FAILED || showBillPaymentActivity
      : !!relevantPayment;

    const isApproving = approvalDecisionStatus.loading && !showDeclineModal;
    const headerActions = getBillHeaderActionsOptions({
      bill,
      paymentId,
      unpaidBill: isUnpaidInboxBill,
      permissions,
      onRetryPayment: this.onRetryPayment,
      onRetryPaymentDelivery: this.onRetryPaymentDelivery,
      onChangeDeliveryMethod: this.onChangeDeliveryMethod,
      onClickSupport: this.onClickSupport,
      onDecline: this.onDecline,
      onApprove: this.onApprove,
      goPayBill: this.goPayBill,
      onTrackDelivery: this.onTrackDelivery,
      isLoading,
      isEditMode,
      canSchedulePayment,
      isApproving,
    });

    const dueDate = bill.dueDate ? new Date(bill.dueDate) : null;
    const occurrences =
      recurringBill && !isNil(recurringBill.occurrences)
        ? recurringBill.occurrences.toString()
        : null;

    return (
      <ViewBillContainer>
        {isDeleting && this.renderDeleteModalDialog()}
        {isMarkAsPaidModalOpen && this.renderMarkAsPaidModalDialog()}
        <FetchIntuitBankAccounts />
        {isLoading && <AreaLoader />}
        {this.renderDeclineModalDialog()}
        {isVendorBlockedForPayment &&
          renderBlockedForPaymentDialog(site, onCloseBlockPaymentDialog)}
        {bill && !isLoading && (
          <SingleViewLoadingContainer
            isEditMode={isEditMode}
            className={isLoading ? 'loading' : ''}
          >
            {showPageHeader && (
              <QBOPageHeader
                backNav={{
                  pathname: backPath,
                  search: billsSearchPath,
                }}
                text={bill.vendor.companyName}
                subTitle={headerSubtitle}
                label={!canSchedulePayment ? 'bills.view.mobileTitle' : 'bills.view.title'}
                actionOptions={actionOptions}
              >
                {isFullSingleView && (
                  <BillDetailsHeader
                    companyName={bill.vendor.companyName}
                    isFullSingleView={isFullSingleView}
                    description={headerSubtitle}
                    status={status}
                    headerActions={headerActions}
                  />
                )}
              </QBOPageHeader>
            )}
            {!isFullSingleView && (
              <BillDetailsHeader
                companyName={bill.vendor.companyName}
                isFullSingleView={isFullSingleView}
                description={headerSubtitle}
                status={status}
                headerActions={headerActions}
                showViewDetailsLink={showViewDetailsLink}
                goViewDetails={this.goViewDetails}
              />
            )}
            <BillInfoContainer>
              {showBillPaymentDetails && showBillPaymentActivity && (
                <PaymentDetailsCard mode="mainSingleScreen">
                  {statusInfo && <BillStatus statusInfo={statusInfo} />}
                  {status === PAYMENT_STATUS.FAILED && this.renderErrorMessage()}
                  <QBMBillPaymentsActivity
                    bill={bill}
                    onClose={this.onClose}
                    goEditPayment={this.goEditPayment}
                    onDeletePayment={this.onDeletePayment}
                    onRetryPayment={this.onFailedRetryPayment}
                    onRetryPaymentDelivery={this.onRetryPaymentDelivery}
                    onChangeDeliveryMethod={this.onChangeDeliveryMethod}
                    onMarkBillAsUnpaid={this.onMarkBillAsUnpaid}
                    onMarkBillAsPaid={this.onMarkBillAsPaid}
                    paymentId={paymentId}
                    onPaymentsMadeClick={this.handlePaymentsMadeClick}
                  />
                </PaymentDetailsCard>
              )}
              <BillDetailsCard>
                <PaymentDetails
                  onKeyDown={this.onKeyPressed}
                  canSchedulePayment={canSchedulePayment}
                >
                  <FormHeader>
                    <MiCardTitleWrapper>
                      <MICardTitle label="bills.view.title" />
                    </MiCardTitleWrapper>
                  </FormHeader>
                  <FormContent isEditMode={isEditMode}>
                    <BillFormContainer isEditMode={isEditMode}>
                      <QBOBillDetailsForm
                        isViewRecurring={!!recurringBill}
                        occurrences={occurrences}
                        frequency={recurringBill && recurringBill.frequency}
                        isDisabled={Boolean(isUploading)}
                        vendors={vendors}
                        intuitAccountsOptions={intuitAccountsOptions}
                        onFieldChange={onFieldChange}
                        totalAmount={bill.totalAmount}
                        vendorId={bill.vendorId ? bill.vendorId.toString() : null}
                        intuitAccountId={bill.intuitAccountId}
                        invoiceNumber={bill.invoiceNumber}
                        dueDate={dueDate}
                        note={bill.note}
                        validationErrors={validationErrors}
                        mode={mode}
                        billPageType={BILL_PAGE_TYPE.EDIT}
                        isRecurringBill={Boolean(recurringBill)}
                        isFirstWave={!!organizationPreferences.billPayFirstWaveUser}
                      />
                    </BillFormContainer>

                    {!isLoading && !isAttachmentLoading && (
                      <BillAttachment
                        qboBillAttachments={qboBillAttachments}
                        isDisabled={isUploading}
                        fileStorageUrl={fileStorageUrl}
                        filePreviewUrls={filePreviewUrls}
                        mode={mode}
                        onChangeAttachment={onChangeAttachment}
                        onDeleteAttachment={onDeleteAttachment}
                        eventPage="bills"
                        withCounter
                      />
                    )}
                  </FormContent>
                </PaymentDetails>
                {isPartialPayments && (
                  <BillOpenBalanceInfoContainer ref={this.openBalanceRef}>
                    <BillOpenBalanceInfo
                      isPartialPayments={billHasPartialPayments}
                      currentPaymentId={paymentId}
                      bill={bill}
                      onLabelClick={this.onLabelClick}
                    />
                  </BillOpenBalanceInfoContainer>
                )}
              </BillDetailsCard>
              {isEditMode && (
                <MIFloatedEditDoneButtons
                  onDone={this.onEditBillFunc}
                  onCancel={this.onToggleMode}
                  doneLabel="bills.edit.save"
                  cancelLabel="bills.edit.cancel"
                  isDisabled={isUploading}
                  titleLabel="bills.new.edit"
                />
              )}
            </BillInfoContainer>
          </SingleViewLoadingContainer>
        )}
        {isUnpaidInboxBill && !isEditMode && !isLoading && !isPaymentFailed && (
          <ButtonWrapper>
            <ButtonsContainer>
              <MIButton
                onClick={this.goPayBill}
                label="bills.new.schedulePayment"
                variant={CONSTS.BUTTON_VARIANT.PRIMARY}
                fullWidth
                disabled={isUploading}
                isProcessing={!'isProcessing && !isSaveAndCloseClicked'}
              />

              <MarkAsPaidButton
                onClick={isMarkAsPaid?.action}
                label={isMarkAsPaid?.label as string}
                variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                fullWidth
                isProcessing={!'isProcessing && isSaveAndCloseClicked'}
              />
            </ButtonsContainer>
          </ButtonWrapper>
        )}

        {!isLoading && (
          <ViewBillQBOFooterWrapper>
            <QBOFooterContainer />
          </ViewBillQBOFooterWrapper>
        )}
      </ViewBillContainer>
    );
  }
}

const mapStateToProps = (state: GlobalState, ownProps: Props): MapStateToProps => {
  const orgId = getOrgId(state);
  const { companyName } = getCompanyInfo(state);
  const { billId, paymentId } = ownProps.isPartialPaymentsEnabled
    ? deserializePaymentId(ownProps.id)
    : {
        billId: ownProps.id,
        paymentId: getLatestPayment(ownProps.bill.payments)?.id,
      };

  return {
    orgId,
    permissions: profileStore.selectors.getPermissions(state),
    currentUser: profileStore.selectors.profile(state),
    approvalDecisionStatus: paymentsStore.selectors.approvalDecisionStatus(state),
    scheduledBy: usersStore.selectors.byId(
      getPaymentById(ownProps.bill.payments, paymentId)?.createdById
    )(state),
    intuitBankAccounts:
      intuitBankAccountsStore.selectors.list.value(state, {
        orgId,
      }) || [],
    companyName,
    organizationPreferences: getOrganizationPreferences(state),
    vendorPaymentPreferences: vendorsStore.selectors.checkVendorPaymentPreferences.item(
      state,
      ownProps.bill.vendorId
    ),
    billId,
    paymentId,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...getPaymentsActions(dispatch),
  ...getBillListActions(dispatch),
  selectPaymentDates(
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    selectedId?: number
  ) {
    dispatch(
      selectPaymentDatesAction(
        scheduledDate,
        deliveryEta,
        maxDeliveryEta,
        selectedId as unknown as string
      )
    );
  },
  beginRegularPayBillFlow(id: string, paymentId: string) {
    dispatch(beginRegularPayBillFlowAction(id, paymentId));
  },
  checkVendorPaymentPreferences({ orgId, id }: CheckVendorPaymentPreferencesType) {
    const vendorActions = getStoreActions(vendorsStore)(dispatch);

    return vendorActions.checkVendorPaymentPreferences({
      orgId,
      id,
    });
  },
});

export default withTheme(
  compose(
    withBreak(),
    withNavigator(),
    withListContainer(),
    withNewBillData(),
    withSiteContext(),
    withPartialPaymentsEnabled(),
    connect(mapStateToProps, mapDispatchToProps)
  )(QBMViewBill)
);

const FetchIntuitBankAccounts = () => {
  useFetchIntuitBankAccounts();

  return <></>;
};
