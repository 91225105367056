import { featureFlags } from '@melio/shared-web';
import compact from 'lodash/compact';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { PaymentType } from 'src/app/utils/types';

const getApprovalDecisionsByStatus = (
  payment: PaymentType,
  status: PAYMENT_APPROVAL_STATUS,
  involvedUsers: any[]
) => {
  const approvalDetail = payment?.paymentApprovalDecisions
    ?.filter((record) => record.status === status)
    .map((record) => {
      const user = involvedUsers.find((user) => user.actor.id === record.userId);

      return (
        user && {
          actionDate: record.createdAt,
          reason: record.reason,
          role: user.actor?.role,
          user: user.actor,
        }
      );
    });

  return compact(approvalDetail);
};

export const getApprovalDecisions = (payment: PaymentType, involvedUsers: any[]) => {
  const [enableApprovalWorkflows] = featureFlags.useFeature('approval-workflows', false);

  const updatedPayment = enableApprovalWorkflows
    ? payment
    : {
        ...payment,
        paymentApprovalDecisions: [
          {
            status: payment.approvalDecisionStatus,
            createdAt: payment.approvalDecisionDate,
            reason:
              payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED
                ? payment.approvalDecisionReason
                : undefined,
            userId: payment.approvalDecisionById,
          },
        ],
      };

  return {
    approvedBy: getApprovalDecisionsByStatus(
      updatedPayment,
      PAYMENT_APPROVAL_STATUS.APPROVED,
      involvedUsers
    ),
    declinedBy: getApprovalDecisionsByStatus(
      updatedPayment,
      PAYMENT_APPROVAL_STATUS.DECLINED,
      involvedUsers
    ),
  };
};
