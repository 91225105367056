import React from 'react';
import styled from 'styled-components';

import { MITableBodyCell, MITableRow } from 'src/app/components/layout/TableElements';
import { BillStatusType } from 'src/app/utils/types';
import MIMoney, { IntegerBalance, FractionBalance } from 'src/app/components/common/MIMoney';

type Props = {
  billStatus: BillStatusType;
  invoiceDate: Date;
  totalAmount: number;
  onPdfIconClick: () => void;
};

const InternalBillsTableBodyRow = ({ invoiceDate, totalAmount, onPdfIconClick }: Props) => (
  <MITableRow>
    <MITableBodyCell>{invoiceDate}</MITableBodyCell>
    <AmountTableBodyCell>
      <TableMIMoney size="extraSmall" amount={totalAmount} />
    </AmountTableBodyCell>
    <PdfIconTableBodyCell>
      <PdfIcon className="icon-download-file" onClick={() => onPdfIconClick()} />
    </PdfIconTableBodyCell>
  </MITableRow>
);

const AmountTableBodyCell = styled(MITableBodyCell)`
  text-align: center;
`;

const TableMIMoney = styled(MIMoney)`
  display: flex;
  justify-content: flex-end;

  ${FractionBalance},${IntegerBalance} {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
`;

const PdfIconTableBodyCell = styled(MITableBodyCell)`
  text-align: center;
  padding-right: 0.9rem;
`;

const PdfIcon = styled.i`
  font-size: 2.4rem;
  cursor: pointer;
  color: ${(props) => props.theme.colors.ds.black};
`;

export default InternalBillsTableBodyRow;
