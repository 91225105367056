import * as React from 'react';
import ErrorPage from './components/ErrorPage';
import { loggingApi } from 'src/app/version-2/api/loggers';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ hasError: true });

    loggingApi.error('ErrorBoundary.componentDidCatch(): error bounded', {
      message: error.message,
      stack: error.stack,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
