import enUS from 'date-fns/locale/en-US';

const formatRelativeLocale = {
  lastWeek: "'last' eeee ',' p",
  yesterday: "'yesterday,' p",
  today: "'today,' p",
  tomorrow: "'tomorrow,' p",
  nextWeek: "eeee ',' p",
  other: 'P',
};

export const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

export const enum DATE_FORMATS {
  day = 'EEE',
  dayLong = 'EEEE',
  dayOfMonthWithOrdinal = 'do',
  dayAndYear = 'd, yyyy',
  isoDate = 'yyyy-MM-dd',
  dayMonthYearDate = 'dd-MM-yyyy',
  monthShortWithDateAndYear = 'MMM d, yyyy',
  monthShortWithLongDateAndYear = 'MMM dd, yyyy',
  monthShortWithYear = 'MMM yyyy',
  monthNumberWithShortYear = 'MM-yy',
  monthNumberSlashShortYear = 'MM/yy',
  monthNumberWithYear = 'MM/yyyy',
  monthWithDay = 'MMM d',
  monthShortWithLongDateAndYearWithHours = 'MMM dd, yyyy, p',
}
