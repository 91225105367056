import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from '../../../utils/formatting';
import MIButton from '../../../components/common/MIButton';
import { CONSTS } from '../../../utils/consts';

type Props = {
  handleUploadCancel: () => void;
  goManual: () => void;
};

const UploadFileError = (props: Props) => (
  <UploadFileErrorContainer>
    <UploadFileErrorBlock>
      <UploadFileErrorIconContainer>
        <ErrorIcon className="icon-notification-icon" />
      </UploadFileErrorIconContainer>
      <UploadFileErrorLabelContainer>
        <MIFormattedText label="bills.new.errorProcessingFile" /> <br />
        <MIFormattedText label="bills.new.errorProcessingFileAction" />
      </UploadFileErrorLabelContainer>
      <MIButton
        variant={CONSTS.BUTTON_VARIANT.SECONDARY}
        label="bills.new.retry"
        onClick={props.handleUploadCancel}
      />
      <MIButton
        variant={CONSTS.BUTTON_VARIANT.CANCEL}
        label="bills.new.enterManually"
        onClick={props.goManual}
      />
    </UploadFileErrorBlock>
  </UploadFileErrorContainer>
);

const UploadFileErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
`;

const UploadFileErrorBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  max-width: 40rem;
  text-align: center;
  color: ${(props) => props.theme.text.color.red};
`;

const UploadFileErrorIconContainer = styled.div`
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  border: 5px solid ${(props) => props.theme.text.color.red};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
`;

const ErrorIcon = styled.i`
  font-size: 3.6rem;
`;

const UploadFileErrorLabelContainer = styled.div`
  margin-bottom: 3rem;
`;

export default UploadFileError;
