import React from 'react';
import { featureFlags } from '@melio/shared-web';
import { DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import MIDialog from 'src/app/components/common/MIDialog';

export const TabapayMigrationModal = ({ goExit }: { goExit: () => void }) => {
  const [isTabapayInMigrations] = featureFlags.useFeature('tabapay-in-migration', false);

  if (isTabapayInMigrations) {
    return (
      <MIDialog
        type={DIALOG_TYPE.ALERT}
        variant={DIALOG_VARIANTS.ERROR}
        title="onboarding.fundingSources.card.tabaPayMigrationErrorTitle"
        subtitle="onboarding.fundingSources.card.tabaPayMigrationErrorSubtitle"
        okButtonText="dialogs.buttons.close"
        cancelButtonText="dialogs.buttons.close"
        onOkAction={goExit}
        onCancelAction={goExit}
      />
    );
  }

  return null;
};
