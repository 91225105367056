import { LoggerMessage } from 'src/app/version-2/api/loggers/LoggerMessage';
import { LoggerSessionContext } from 'src/app/version-2/api/loggers/LoggerSessionContext';
import { DebugLevelEnum } from 'src/app/version-2/model/enums';
import { Logger } from './Logger';

let sessionContext: LoggerSessionContext = {
  userId: '',
  orgId: '',
};

export const consoleLogger: Logger = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init() {},
  get debugLevel(): DebugLevelEnum {
    if (process.env.NX_REACT_APP_ENV === 'production') {
      return DebugLevelEnum.WARN;
    }

    return DebugLevelEnum.DEBUG;
  },
  set sessionContext(value: LoggerSessionContext) {
    sessionContext = value;
  },
  debug(message?: LoggerMessage, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.debug(message, ...optionalParams, sessionContext);
  },
  log(message?: LoggerMessage, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams, sessionContext);
  },
  info(message?: LoggerMessage, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.info(message, ...optionalParams, sessionContext);
  },
  warn(message?: LoggerMessage, ...optionalParams: any[]): void {
    console.warn(message, ...optionalParams, sessionContext);
  },
  error(message?: LoggerMessage, ...optionalParams: any[]): void {
    console.error(message, ...optionalParams, sessionContext);
  },
};
