import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import MINotificationCard from 'src/app/components/common/MINotificationCard';
import { VerificationState, ResendState } from '../hooks/useAuthCodeVerification';

type Props = {
  verificationState: VerificationState;
  resendState: ResendState;
};

const Notification = ({ verificationState, resendState }: Props) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    setIsHidden(false);
  }, [resendState]);

  useEffect(() => {
    const verificationFailed = [
      VerificationState.Failed,
      VerificationState.FailedMaxAttempts,
      VerificationState.FailedBlocked,
    ].includes(verificationState);

    if (verificationFailed) {
      setIsHidden(true);
    }
  }, [verificationState]);

  const handleCloseClick = () => {
    setIsHidden(true);
  };

  if (verificationState === VerificationState.Failed) {
    return (
      <StyledNotificationCard
        type={NOTIFICATION_CARD_TYPES.ERROR_FILLED}
        title={{ label: 'guests.codeVerification.errors.invalidCode.title' }}
        subtitle={{ label: 'guests.codeVerification.errors.invalidCode.subtitle' }}
      />
    );
  }

  if (verificationState === VerificationState.FailedMaxAttempts) {
    return (
      <StyledNotificationCard
        type={NOTIFICATION_CARD_TYPES.ERROR_FILLED}
        title={{ label: 'guests.codeVerification.errors.attemptsAreOver.title' }}
        subtitle={{ label: 'guests.codeVerification.errors.attemptsAreOver.subtitle' }}
      />
    );
  }

  if (verificationState === VerificationState.FailedBlocked) {
    return (
      <StyledNotificationCard
        type={NOTIFICATION_CARD_TYPES.ERROR_FILLED}
        title={{ label: 'guests.codeVerification.errors.verificationBlocked.title' }}
        subtitle={{ label: 'guests.codeVerification.errors.verificationBlocked.subtitle' }}
      />
    );
  }

  if (!isHidden && resendState === ResendState.Regular) {
    return (
      <StyledNotificationCard
        type={NOTIFICATION_CARD_TYPES.SUCCESS_FILLED}
        title={{ label: 'guests.codeVerification.messages.codeSent.title' }}
        subtitle={{ label: 'guests.codeVerification.messages.codeSent.subtitle' }}
        onClose={handleCloseClick}
      />
    );
  }

  if (!isHidden && resendState === ResendState.Final) {
    return (
      <StyledNotificationCard
        type={NOTIFICATION_CARD_TYPES.SUCCESS_FILLED}
        title={{ label: 'guests.codeVerification.messages.finalCodeSent.title' }}
        subtitle={{ label: 'guests.codeVerification.messages.finalCodeSent.subtitle' }}
        onClose={handleCloseClick}
      />
    );
  }

  return null;
};

const StyledNotificationCard = styled(MINotificationCard)`
  width: 100%;
  max-width: 45rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
  @media ${devices.mobile} {
    margin-top: 2rem;
  }
`;

export { Notification };
