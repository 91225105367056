import React from 'react';
import { RecordOf } from 'immutable';
import styled from 'styled-components';
import { PaginationItemsTypeEnum } from 'src/app/version-2/model/enums/paginationItemsType.enum';
import { BillType } from 'src/app/utils/types';
import { MICardForm } from 'src/app/components/common/MICard';
import MIPaginationBar from 'src/app/components/common/MIPaginationBar';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import { Filters } from 'src/app/hoc/withListContainer';
import { MITable, MITableHead, MITableBody } from 'src/app/components/layout/TableElements';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import { useSelector } from 'react-redux';
import { getOrgFeeFundingSource } from 'src/app/redux/organization/selectors';
import { featureFlags } from '@melio/shared-web';
import InternalBillsTableHeaderRow from './InternalBillsTableHeaderRow';
import InternalBillsTableBodyRow from './InternalBillsTableBodyRow';

import { BillingMethodCard } from './fee/components/BillingMethodCard';
import { CardContainer, Title } from './fee/components/styles';
import { BILLING_FEATURE } from './fee/consts';

const eventPage = 'settings-billing';

type Props = {
  internalBills: RecordOf<BillType>[];
  internalBillsTotal: number;
  onGeneratePdfInvoice: (billId: string, invoiceDate: Date) => void;
  filters: Filters;
};

const BillingSettingsPage = ({
  internalBills,
  internalBillsTotal,
  filters,
  onGeneratePdfInvoice,
}: Props) => {
  const [isFeatureVisible] = featureFlags.useFeature(BILLING_FEATURE);
  const feeFundingSource = useSelector(getOrgFeeFundingSource);

  return (
    <Wrapper>
      <BillingSettingsMICardForm>
        {isFeatureVisible && <BillingMethodCard feeFundingSource={feeFundingSource} />}

        {internalBillsTotal === 0 && (
          <CardContainer>
            <Title>
              <MIFormattedText label="settings.billing.cardTitle" />
            </Title>
            <EmptyFeePlaceholder>
              <MIFormattedText label="settings.billing.emptyFeesPlaceholder" />
            </EmptyFeePlaceholder>
          </CardContainer>
        )}
        {internalBillsTotal > 0 && (
          <CardContainer>
            <Title>
              <MIFormattedText label="settings.billing.cardTitle" />
            </Title>
            <InternalBillsTable>
              <MITableHead>
                <InternalBillsTableHeaderRow />
              </MITableHead>
              <MITableBody>
                {internalBills.map((internalBill) => (
                  <InternalBillsTableBodyRow
                    key={internalBill.id}
                    billStatus={internalBill.status}
                    invoiceDate={internalBill.invoiceDate}
                    totalAmount={internalBill.totalAmount}
                    onPdfIconClick={() => {
                      analytics.track(eventPage, 'receipt-download', {
                        receiptId: internalBill.id,
                        receiptDate: internalBill.invoiceDate,
                        receiptAmount: internalBill.totalAmount,
                      });

                      onGeneratePdfInvoice(internalBill.id, internalBill.invoiceDate);
                    }}
                  />
                ))}
              </MITableBody>
            </InternalBillsTable>

            <MIPaginationBar
              total={internalBillsTotal}
              start={filters.start}
              limit={filters.limit}
              pathname={locations.Settings.billingItems.url()}
              baseSearch="sorting=invoiceDate:desc"
              itemType={PaginationItemsTypeEnum.LINK}
            />
          </CardContainer>
        )}
      </BillingSettingsMICardForm>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0.2rem 1.6rem 0 1.6rem;
  }
`;

const BillingSettingsMICardForm = styled(MICardForm)`
  @media ${devices.mobile} {
    padding-left: 0;
    padding-right: 0;
  }

  ${(props) => props.theme.pages?.Settings?.BillingSettingsMICardForm}
`;

const EmptyFeePlaceholder = styled.p`
  font-size: ${(props) => props.theme.text.size.sectionTitleM};
  color: ${(props) => props.theme.text.color.subtitle};
  margin-top: 0;
  @media ${devices.mobile} {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
`;

const InternalBillsTable = styled(MITable)`
  margin-bottom: 2.5rem;

  ${(props) => props.theme.pages?.Settings?.InternalBillsTable}
`;

export default BillingSettingsPage;
