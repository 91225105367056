import { organizationUltimateBeneficialOwnersApi } from 'src/app/version-2/api/organizationUltimateBeneficialOwners.api';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { uboActions } from './ultimateBeneficialOwners.slice';

import { call, put, select, takeEvery } from 'redux-saga/effects';

function* fetchUltimateBeneficialOwnersHandler() {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const { uboResults } = yield call(organizationUltimateBeneficialOwnersApi.fetch, { orgId });

    yield put(uboActions.setUboItems(uboResults ?? []));
  } catch (error) {
    yield put(uboActions.setUboItems([]));
  }
}

export function* watchUltimateBeneficialOwnersSaga() {
  yield takeEvery(uboActions.fetchUltimateBeneficialOwners, fetchUltimateBeneficialOwnersHandler);
}
