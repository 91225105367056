import { useEffect } from 'react';

/**
 * Handle key press inside react component
 *
 * @param {Function} callback
 */
const useKeyPress = (callback: (key: string) => void) => {
  function onKeyup(event) {
    callback(event.key);
  }

  useEffect(() => {
    window.addEventListener('keyup', onKeyup);

    return () => window.removeEventListener('keyup', onKeyup);
  }, []);
};

export default useKeyPress;
