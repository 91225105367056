import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import MIMoney from 'src/app/components/common/MIMoney';

type Props = {
  totalAmount: number;
};

const BillDetailsTotal = ({ totalAmount }: Props) => (
  <BillDetailsTotalContainer>
    <MIFormattedText label="bills.form.total" />
    <MIMoney amount={convertCurrencyToNumber(totalAmount)} />
  </BillDetailsTotalContainer>
);

const BillDetailsTotalContainer = styled.div`
  padding: 3.5rem 3.6rem;
  height: 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.text.color.main};
  letter-spacing: 0.038rem;
  font-size: 2.3rem;
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  border-bottom-right-radius: 0.9rem;
  border-bottom-left-radius: 0.9rem;
  @media ${devices.mobile} {
    padding: 1.7rem 1.6rem;
    height: 8.8rem;
    box-sizing: border-box;
  }

  ${(props) => props.theme?.components?.BillDetails?.BillDetailsTotal}
`;

export default BillDetailsTotal;
