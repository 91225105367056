import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import { Upsell } from 'src/app/version-2/components/Upsell';

import styled from 'styled-components';

const Container = ({ children, ...rest }) => (
  <Flex
    direction="column"
    justify="start"
    align="center"
    minWidth={{
      base: `calc(${({ theme }) => theme.sizes.full} - ${({ theme }) => theme.sizes.ds.xl})`,
      sm: `calc(${({ theme }) => theme.sizes.full} - ${({ theme }) => theme.sizes.ds.xl})`,
      md: `calc(${({ theme }) => theme.sizes.full} - ${({ theme }) => theme.sizes.ds.xl})`,
      lg: '53rem',
      xl: '53rem',
      '2xl': '53rem',
    }}
    maxWidth={{
      base: `${({ theme }) => theme.sizes.full}`,
      sm: `${({ theme }) => theme.sizes.full}`,
      md: `${({ theme }) => theme.sizes.full}`,
      lg: '53rem',
      xl: '53rem',
      '2xl': '53rem',
    }}
    margin="0 auto"
    padding={`${({ theme }) => theme.sizes.ds['2xl']} 0`}
    paddingRight={{
      base: `${({ theme }) => theme.space.ds.md}`,
      sm: `${({ theme }) => theme.space.ds.md}`,
      md: `${({ theme }) => theme.space.ds.md}`,
    }}
    paddingLeft={{
      base: `${({ theme }) => theme.space.ds.md}`,
      sm: `${({ theme }) => theme.space.ds.md}`,
      md: `${({ theme }) => theme.space.ds.md}`,
    }}
    {...rest}
  >
    {children}
  </Flex>
);

const InfoContainer = ({ children, ...rest }) => (
  <Flex
    direction="column"
    justify="start"
    align="center"
    width="full"
    borderStyle="solid"
    borderColor="gray.200"
    borderRadius="md"
    borderWidth={1}
    overflow="hidden"
    marginBottom="2rem"
    {...rest}
  >
    {children}
  </Flex>
);

interface InfoSecProps {
  isMobile: boolean;
}

const InfoSection = styled(Box)<InfoSecProps>`
  width: calc(
    100% - ${({ isMobile, theme }) => (isMobile ? theme.space.ds.md : theme.space.ds['2xl'])} * 2
  );
  padding: 4rem ${({ isMobile, theme }) => (isMobile ? theme.space.ds.md : theme.space.ds['2xl'])};
  background-color: white;
`;

const StyledUpsell = styled(Upsell)`
  background-color: ${({ theme }) => theme.colors.ds.white};
  border-radius: 0;
`;

export { Container, InfoContainer, InfoSection, StyledUpsell };
