import { keyframes } from '@melio/billpay-design-system';
import { Theme } from '../../theme';

const fade = (startColor: string, endColor: string) => keyframes({
  from: { borderColor: startColor, background: startColor },
  to: { borderColor: endColor, background: endColor },
});

const baseStyle = (props: { theme: Theme }) => {
  const { theme } = props;

  const start = theme.colors.ds.gray['800'];
  const end = theme.colors.ds.gray['500'];

  return {
    borderRadius: 'ds.md',
    borderColor: start,
    background: end,
    animation: `1.5s linear infinite alternate ${fade(start, end)}`,
  };
};

export const SkeletonTheme = {
  baseStyle,
};
