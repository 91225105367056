import { useIntl } from 'react-intl';
import { IconType } from '@melio/billpay-design-system';
import { FundingSourceCard } from 'src/app/version-2/components';
import { FoundSourceCardProps } from 'src/app/version-2/model/objects/foundingSourceCard';
import { PaymentMethodsEnum } from 'src/app/version-2/pages/funding-source/model/enums';
import { getPaymentMethodIcon } from 'src/app/version-2/utils/paymentMethods.utils';
import { CardsContainer } from './FundingSourceCards.styles';

interface FundingSourceCardsProps {
  methods: Array<PaymentMethodsEnum>;
  onSelect?: (method) => void;
}

export const FundingSourceCards = ({ onSelect, methods = [] }: FundingSourceCardsProps) => {
  const { formatMessage } = useIntl();
  const int = useIntl();

  const onMethodSelect = (method: string) => {
    if (onSelect) onSelect(method);
  };

  return (
    <CardsContainer align="center" justify="center">
      {methods.map((methodEnum) => {
        const method = methodEnum.toString();
        const args: FoundSourceCardProps = {
          id: method,
          icon: getPaymentMethodIcon(method) as IconType,
          iconSize: method === 'debit' ? 5.4 : 4,
          title: formatMessage({ id: `paymentMethods.${method}.title` }),
          subtitle: formatMessage({ id: `paymentMethods.${method}.subtitle` }),
          fee: formatMessage({ id: `paymentMethods.${method}.fee` }),
          advantages: formatMessage({ id: `paymentMethods.${method}.advantages` }).split(','),
          withButton: true,
          buttonText: formatMessage({ id: `paymentMethods.${method}.button` }),
        };

        const comment = int.messages[`paymentMethods.${method}.comment`];

        if (comment) {
          args.comment = String(comment);
        }

        return (
          <FundingSourceCard
            key={args.id}
            hover={false}
            {...args}
            onClick={() => onMethodSelect(method.toString())}
          />
        );
      })}
    </CardsContainer>
  );
};
