import { postRequest } from 'src/app/version-2/externals';
import { CreateCardAccountWithTokenRequest } from 'src/app/version-2/pages/add-card-account/api/deliveryMethod/requests/CreateCardAccountWithTokenRequest';
import { ValidateCardAccountWithTokenRequest } from 'src/app/version-2/pages/add-card-account/api/deliveryMethod/requests/ValidateCardAccountWithTokenRequest';
import { CreateCardAccountWithTokenResponse } from 'src/app/version-2/pages/add-card-account/api/deliveryMethod/responses/CreateCardAccountWithTokenResponse';
import { ValidateCardAccountWithTokenResponse } from 'src/app/version-2/pages/add-card-account/api/deliveryMethod/responses/ValidateCardAccountWithTokenResponse';

export const addCardAccountDeliveryMethodWithTokenApi = {
  validateCardAccountWithToken(
    params: Omit<ValidateCardAccountWithTokenRequest, 'digits' | 'expiration'>
  ) {
    const url = '/delivery-method/push-to-debit/validate-card';

    return postRequest<ValidateCardAccountWithTokenResponse>(url, params);
  },

  createCardAccountWithToken(params: CreateCardAccountWithTokenRequest) {
    const url = '/delivery-method/create-card-account';

    return postRequest<CreateCardAccountWithTokenResponse>(url, params);
  },
};
