import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { Payment } from 'src/app/version-2/model/dtos';
import { PaymentStatusEnum } from 'src/app/version-2/model/enums';

export const PAYMENTS_SLICE = `${VERSION_2}payments`;

interface PaymentsSlice {
  payments: EntityState<Payment>;
  isFetching: boolean;
}

export const selectState = (state: any) => state[PAYMENTS_SLICE];

const paymentsAdapter = createEntityAdapter<Payment>({
  selectId: (payment) => payment.id,
});

export const paymentsInitialState: PaymentsSlice = {
  payments: paymentsAdapter.getInitialState(),
  isFetching: false,
};

export const paymentsSlice = createSlice({
  name: PAYMENTS_SLICE,
  initialState: paymentsInitialState,
  reducers: {
    setPayment: (state, action) => {
      paymentsAdapter.setOne(state.payments, action.payload);
    },
    fetchPaymentsSuccess: (state, action: PayloadAction<Payment[]>) => {
      paymentsAdapter.setAll(state.payments, action.payload);
    },
    fetchPaymentsFail: (state) => {
      paymentsAdapter.removeAll(state.payments);
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
  },
});

export const paymentsSelectors = {
  selectPayments: createSelector(selectState, (state) =>
    paymentsAdapter.getSelectors().selectAll(state.payments)
  ),
  selectPaymentById: (paymentId: string) =>
    createSelector(selectState, (state) =>
      paymentsAdapter.getSelectors().selectById(state.payments, paymentId)
    ),
  selectIsFetching: createSelector(selectState, (state) => state.isFetching),
};

export const paymentsActions = {
  ...paymentsSlice.actions,
  fetchPaymentById: createAction<{ paymentId: string }>(`${PAYMENTS_SLICE}/fetch-payment-by-id`),
  fetchPayments: createAction<
    | {
        start?: number;
        limit?: number;
        status?: PaymentStatusEnum;
      }
    | undefined
  >(`${PAYMENTS_SLICE}/fetch-payments`),
};
