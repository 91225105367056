import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Bill } from 'src/app/pages/bill/pay/hooks/usePartialPayments';
import { usePartialPaymentsQualification } from 'src/app/pages/bill/hooks/usePartialPaymentsBillQualification';

import {
  CurrencyInput,
  CurrencyInputType,
  getFormattedValueForCurrency,
} from 'src/app/components/common/CurrencyInput';
import { CURRENCY_USD } from 'src/app/version-2/model/constants';
import { TotalAmount, FieldSubTitle } from './Styles';

type EditablePaymentAmountProps = {
  bill?: Bill;
  paymentAmount: number;
  min?: number;
  max: number;
  disabled?: boolean;
  setPaymentAmount: (amount: number) => void;
  setValid?: (valid: boolean) => void;
  onFocus?: () => void;
};

type ErrorProps = {
  errors?: Record<string, string | undefined>;
  errorsValues?: Record<string, string>;
};

export const EditablePaymentAmount = (props: EditablePaymentAmountProps) => {
  const { bill, disabled } = props;

  const isBillQualifiedForPartialPayment = usePartialPaymentsQualification(bill?.qboSyncVersion);

  if (disabled || !isBillQualifiedForPartialPayment) {
    return (
      <>
        <FieldSubTitle />
        <TotalAmount amount={props.paymentAmount} />
      </>
    );
  }

  return <PaymentAmountInput {...props} />;
};

const useFieldValue = (id = ''): [string, (value: string) => void] => {
  const [fieldValues, setValues] = useState<Record<string, string>>({});
  const setValue = useCallback(
    (value: string) => setValues((res) => ({ ...res, [id]: value })),
    [id]
  );
  const fieldValue = useMemo(() => fieldValues[id], [fieldValues, id]);

  return [fieldValue, setValue];
};

const useErrorProps = (openBalance: number, currency: string): ErrorProps => {
  const intl = useIntl();
  const max = intl.formatNumber(openBalance, {
    currency,
    style: 'currency',
  });

  return useMemo(
    () => ({
      errors: { paymentAmount: 'batchPayment.sidePanel.amountSourceError' },
      errorsValues: { max },
    }),
    [max]
  );
};

const useMoneyInput = ({
  bill,
  min = 0.01,
  max,
  paymentAmount = 0,
  setPaymentAmount,
  setValid,
}: EditablePaymentAmountProps) => {
  const [value, setValue] = useFieldValue(bill?.id);
  const [error, setError] = useState<ErrorProps>({});
  const errorProps = useErrorProps(max, bill?.currency || CURRENCY_USD);

  const isInvalid = useMemo(() => {
    const target = Number(value);

    return target > max || (target < min && paymentAmount !== target);
  }, [paymentAmount, value, max, min]);

  useEffect(() => {
    setValue(
      getFormattedValueForCurrency(paymentAmount.toString(), bill?.currency as typeof CURRENCY_USD)
    );
  }, [bill]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isInvalid) {
      setValid && setValid(false);
      setError(errorProps);
    } else {
      setPaymentAmount(Number(value));
      setError({});
      setValid && setValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill, value, isInvalid]);

  const onChange = ({ value }) => setValue(value);

  const inputProps = useMemo(
    () => ({
      value,
      onChange,
    }),
    [error, value] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    ...inputProps,
    error,
  };
};

const PaymentAmountInput = (props: EditablePaymentAmountProps) => {
  const { value, error, onChange } = useMoneyInput(props);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <CurrencyInput
        name="paymentAmount"
        type={CurrencyInputType.INPUT_FIELD}
        value={value}
        label="batchPayment.sidePanel.paymentAmount"
        currency={props.bill?.currency as typeof CURRENCY_USD}
        onFocus={props.onFocus}
        onChange={onChange}
        {...error}
      />
    </form>
  );
};
