import { createAction } from '@reduxjs/toolkit';
import { BillType } from 'src/app/utils/types';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { FundingSource } from 'src/app/version-2/model/dtos';

export const FUNDING_SOURCE_SLICE = `${VERSION_2}fundingSource`;

export const fundingSourceActions = {
  sendEvents: createAction<{
    fundingSources: FundingSource[];
    bill: BillType;
    fundingSourceOptions?: string[];
    isFundingSourceExperiment?: boolean;
    featureFlags: {
      fundingSourceVariant?: string;
    };
  }>(`${FUNDING_SOURCE_SLICE}/send-funding-source-events`),
};
