import { useEffect } from 'react';
import analytics from 'src/app/services/analytics';
import { PaymentType } from 'src/app/utils/types';
import { VirtualCardPaymentState } from './useVirtualCardData';

const eventName = 'state-changed';

const paymentStateMapping = {
  [VirtualCardPaymentState.Unknown]: 'unknown',
  [VirtualCardPaymentState.Valid]: 'valid',
  [VirtualCardPaymentState.Cancelled]: 'cancelled',
  [VirtualCardPaymentState.Processed]: 'processed',
  [VirtualCardPaymentState.NewDeliveryMethod]: 'new-delivery-method',
  [VirtualCardPaymentState.NewDeliveryMethodProcessed]: 'new-delivery-method-processed',
  [VirtualCardPaymentState.Expired]: 'expired',
  [VirtualCardPaymentState.Recovered]: 'recovered',
  [VirtualCardPaymentState.RecoverySuccess]: 'recovery-success',
  [VirtualCardPaymentState.NewDeliveryMethodScheduled]: 'shift-vc-to-ach',
};

type Props = {
  payment?: PaymentType;
  paymentState: VirtualCardPaymentState;
  extraProperties?: Record<string, string | number | undefined>;
};

type Result = {
  analyticsProperties: Record<string, string | number | undefined>;
};

const useStateChangedAnalytics = ({
  payment,
  paymentState,
  extraProperties = {},
}: Props): Result => {
  const analyticsProperties = {
    state: paymentStateMapping[paymentState],
    paymentId: payment?.id,
    vendorId: payment?.vendor?.id,
    vendorEmail: payment?.vendor?.contactEmail,
    ...extraProperties,
  };

  useEffect(() => {
    analytics.trackAction(eventName, analyticsProperties);
  }, []);

  return { analyticsProperties };
};

export { useStateChangedAnalytics };
