const dashboard = '/orgs/:orgId/bills/qb-dashboard-list';
const defaultDashboard = `${dashboard}?status=unpaid&limit=24`;

const LOCATIONS = {
  base: [dashboard],
  dashboard,
  defaultDashboard,
  syncBillByOrigin: `${dashboard}/sync-bill/:originId`,
  syncPaymentByOrigin: `${dashboard}/sync-payment/:originId`,
  syncBillsByOrigin: `${dashboard}/sync-bills`,
};

export default LOCATIONS;
