import { ColumnItem } from '../components/QBODataTable/types';
import { QB_DASHBOARD_TAB_STATUS } from '../consts';
import { DashboardListItemType } from '../types';
import usePaidColumns from './usePaidColumns';
import useScheduledColumns from './useScheduledColumns';
import useUnpaidColumns from './useUnpaidColumns';

type ResponseType = [ColumnItem<DashboardListItemType>[]];

const useGetDashboardColumns = (status: QB_DASHBOARD_TAB_STATUS): ResponseType => {
  const [unpaidColumns] = useUnpaidColumns();
  const [scheduledColumns] = useScheduledColumns();
  const [paidColumns] = usePaidColumns();

  switch (status) {
    case QB_DASHBOARD_TAB_STATUS.SCHEDULED:
      return [scheduledColumns];
    case QB_DASHBOARD_TAB_STATUS.PAID:
      return [paidColumns];
    case QB_DASHBOARD_TAB_STATUS.UNPAID:
    default:
      return [unpaidColumns];
  }
};

export default useGetDashboardColumns;
