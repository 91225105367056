import styled from 'styled-components';
import { SmartLink } from 'src/app/modules/navigation/components/SmartLink';

export const TooltipLabel = styled.span`
  display: contents;
  font-style: italic;
`;

export const HeaderContentIcon = styled.i`
  font-size: 2.4rem;
  color: ${(props) => props.theme.text.color.darkGrey};
`;

export const SettingsLink = styled(SmartLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  border: none;
`;

export const BlackOverlay = styled.div<{ display: boolean }>`
  display: ${(props) => (props.display ? 'block' : 'none')};
  position: ${(props) => (props.display ? 'absolute' : '')};
  background-color: ${(props) => props.theme.colors.ds.black};
  opacity: 60%;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 4;
`;
