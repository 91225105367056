import React, { useMemo, FunctionComponent } from 'react';
import {
  ModalMessage,
  ContentContainer,
  ModalContainer,
  SubTitleContainer,
} from 'src/app/components/common/ModalMessage';
import styled from 'styled-components';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS, FORM_KEY_FIELDS } from 'src/app/utils/consts';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { WizardNewSelectField } from 'src/app/ui/form/WizardNewSelectField';
import { WizardCurrencyField } from 'src/app/ui/form/WizardCurrencyField';
import { FormRow } from 'src/app/ui/form/FormElements';
import { useForm } from 'src/app/ui/form';
import ValidationError from 'src/app/ui/ValidationError';
import analytics from 'src/app/services/analytics';

type BankAccountFormType = {
  intuitAccountId: string | null;
  amount: number | null;
};

type BillsMetadata = {
  id: string;
  balance: number;
  amount: number | null;
}[];

type Props = {
  dismiss: () => void;
  submit: (bankAccountFT: BankAccountFormType) => Promise<void>;
  options: { label: string; value: string }[];
  isProcessing: boolean;
  billsMetadata: BillsMetadata;
};

export const QBDashboardMarkAsPaidModal: FunctionComponent<Props> = ({
  dismiss,
  submit,
  isProcessing,
  options,
  billsMetadata,
}) => {
  const { isPartialPaymentsUIEnabled } = usePartialPaymentsEnabled();
  const isBatch = billsMetadata.length > 1;

  const model = useMemo(() => {
    if (isPartialPaymentsUIEnabled && !isBatch && billsMetadata[0]) {
      const { amount, balance } = billsMetadata[0];

      return {
        intuitAccountId: null,
        amount: amount || balance,
      };
    }

    return {
      intuitAccountId: null,
      amount: null,
    };
  }, [isPartialPaymentsUIEnabled, isBatch]);

  const [bankAccountVM, { submit: onSubmit }] = useForm<BankAccountFormType>(model, {
    submit: async ({ intuitAccountId, amount }) => {
      if (isPartialPaymentsUIEnabled) {
        if (isBatch) {
          await submit({
            intuitAccountId,
            amount: null,
          });
          dismiss();

          return;
        }

        const { balance } = billsMetadata[0];

        if (amount === 0) {
          const validationErrors = {
            amount: <MIFormattedText label="paymentDashboard.markAsPaid.errors.zeroAmount" />,
          };

          throw new ValidationError({ validationErrors });
        }

        if (balance && amount && balance < amount) {
          const validationErrors = {
            amount: (
              <MIFormattedText
                label="paymentDashboard.markAsPaid.errors.invalidAmount"
                values={{
                  balance: <MIFormattedCurrency value={balance} />,
                }}
              />
            ),
          };

          throw new ValidationError({ validationErrors });
        }

        await submit({
          intuitAccountId,
          amount,
        });
      } else {
        await submit({
          intuitAccountId,
          amount: null,
        });
      }

      dismiss();
    },
    onChange: ({ key, modelState, value }) => {
      if (key !== FORM_KEY_FIELDS.AMOUNT) {
        return modelState;
      }

      if (value === 0) {
        modelState.amount = '';
      } else {
        modelState.amount = value;
      }

      return modelState;
    },
  });

  const onAmountFocus = () => {
    analytics.trackAction('mark-as-paid-modal-on-focus-amount', {
      amount: bankAccountVM.amount?.value,
      source: 'mark-as-paid-modal',
    });
  };

  const dialogTitleComp = useMemo(() => {
    if (isPartialPaymentsUIEnabled) {
      return <MIFormattedText label="paymentDashboard.markAsPaid.dialogTitlePartial" />;
    }

    const label = isBatch
      ? 'paymentDashboard.markAsPaid.dialogTitleBatch'
      : 'paymentDashboard.markAsPaid.dialogTitle';

    return <MIFormattedText label={label} />;
  }, [isPartialPaymentsUIEnabled, isBatch]);

  const dialogSubTitleComp = useMemo(() => {
    if (isPartialPaymentsUIEnabled) {
      const label = isBatch
        ? 'paymentDashboard.markAsPaid.dialogSubTitleBatch'
        : 'paymentDashboard.markAsPaid.dialogSubTitle';

      return <MIFormattedText label={label} />;
    }

    return null;
  }, [isPartialPaymentsUIEnabled, isBatch]);

  const buttonLabel = useMemo(() => {
    if (isPartialPaymentsUIEnabled) {
      return isBatch
        ? 'paymentDashboard.markAsPaid.ctaMarkAsPaid'
        : 'paymentDashboard.markAsPaid.ctaSaveAndClose';
    }

    return 'paymentDashboard.markAsPaid.ctaSave';
  }, [isPartialPaymentsUIEnabled, isBatch]);

  return (
    <StyledModalMessage
      onCloseClick={dismiss}
      titleComponent={dialogTitleComp}
      contentSubTitle={dialogSubTitleComp}
      contentComponent={
        <>
          {isPartialPaymentsUIEnabled && !isBatch && (
            <StyledFormRow>
              <WizardCurrencyField
                onFocus={onAmountFocus}
                label="paymentDashboard.markAsPaid.paymentAmountLabel"
                model={bankAccountVM.amount}
                placeholder="paymentDashboard.markAsPaid.paymentAmountLabelPlaceholder"
                required
              />
            </StyledFormRow>
          )}
          <FormRow>
            <WizardNewSelectField
              label={
                isPartialPaymentsUIEnabled
                  ? 'paymentDashboard.markAsPaid.intuitAccountLabelPartial'
                  : 'paymentDashboard.markAsPaid.intuitAccountLabel'
              }
              placeholder="paymentDashboard.markAsPaid.intuitAccountPlaceholder"
              required
              options={options}
              model={bankAccountVM.intuitAccountId}
              allowCustom={false}
            />
          </FormRow>
        </>
      }
      buttonComponent={
        <ButtonsContainer>
          <MIButton
            label={buttonLabel}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={onSubmit}
            disabled={!bankAccountVM.intuitAccountId.value}
            isProcessing={isProcessing}
          />
        </ButtonsContainer>
      }
    />
  );
};

const StyledModalMessage = styled(ModalMessage)`
  ${ModalContainer} {
    overflow-y: visible;
  }

  ${ContentContainer} {
    margin-bottom: 0;
  }

  ${SubTitleContainer} {
    color: ${(props) => props.theme.text.color.darkGrey};
  }
`;

const StyledFormRow = styled(FormRow)`
  margin-bottom: 2rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
