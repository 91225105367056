import { featureFlags } from '@melio/shared-web';
import { FilesUrls } from 'src/app/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import { PaymentFeeItem, PaymentType } from 'src/app/utils/types';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import paymentStore from 'src/app/modules/payments/payment-store';

export const useShiftToDebitHooksState = (
  paymentId: PaymentType['id']
): {
  isLoading: boolean;
  fee?: PaymentFeeItem;
  filesUrls?: FilesUrls;
} => {
  const [isBasisTheoryFlagEnabled, isBasisTheoryFlagLoading] = featureFlags.useFeature(
    FeatureFlagsEnum.BP_BASIS_THEORY,
    true,
    {
      shouldTrack: false,
    }
  );

  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const { fee } = useStructuredSelectors(paymentStore.selectors.payment(paymentId));
  const { filesUrls } = useStructuredSelectors(paymentStore.selectors.payment(paymentId));

  if (isBasisTheoryFlagLoading) {
    return {
      isLoading: true,
      fee: undefined,
      filesUrls: undefined,
    };
  }

  if (isBasisTheoryFlagEnabled) {
    return {
      isLoading: false,
      fee: undefined,
      filesUrls: undefined,
    };
  }

  return {
    isLoading: isPaymentLoading,
    fee,
    filesUrls,
  };
};
