import first from 'lodash/first';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import billsStore from 'src/app/modules/bills/bills-store';
import intuitBankAccountsStore from 'src/app/modules/intuit-bank-accounts/intuit-bank-accounts-store';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { useMarkAsPaidBillsMetadata } from 'src/app/pages/qb-dashboard/hooks/useMarkAsPaidBillsMetadata';
import { IntuitBankAccountType } from 'src/app/utils/types';
import { useModal } from 'src/app/helpers/react/useModal';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { getDashboardItemId } from 'src/app/pages/qb-dashboard/utils';
import { PAGINATION } from 'src/app/utils/consts';
import { QB_DASHBOARD_TAB_STATUS, ITEMS_PER_PAGE } from '../consts';
import { QBDashboardMarkAsPaidModal } from '../components/QBDashboardMarkAsPaidModal';
import { useRedirectToDashboard } from './useRedirectToDashboard';

type Response = [() => void, React.ReactNode];

const useMarkAsPaidAction = (billId: string): Response => {
  const { isPartialPaymentsUIEnabled } = usePartialPaymentsEnabled();
  const orgId = useSelector(getOrgId);
  const billsActions = useStoreActions(billsStore);
  const site = useSiteContext();
  const createOrigin = site.createOrigin.pay.payment;
  const isProcessing = (
    useSelector(billsStore.selectors.markAsPaid.status(billId)) as { loading: boolean }
  )?.loading;
  const accounts: IntuitBankAccountType[] =
    useSelector((state) =>
      intuitBankAccountsStore.selectors.list.value(state, {
        orgId,
      })
    ) || [];
  const options = accounts.map((account) => ({
    value: account.id,
    label: account.name,
  }));
  const { redirectToDashboard } = useRedirectToDashboard();
  const [billsMetadata] = useMarkAsPaidBillsMetadata({ billIds: [billId] });

  const markAsPaidRequest = useCallback(
    async (intuitAccountId: string, amount?: number | null) => {
      const {
        payload: { payments },
      } = await billsActions.markAsPaid({
        orgId,
        id: billId,
        amount,
        isPaid: true,
        actionType: 'paid',
        intuitAccountId,
        createOrigin,
      });
      const { id: paymentId } = first(payments) || {};

      const redirectQuery = {
        start: PAGINATION.DEFAULT_START,
        status: QB_DASHBOARD_TAB_STATUS.PAID,
        limit: ITEMS_PER_PAGE,
        highlightedItemIds: getDashboardItemId(billId, paymentId),
      };

      await redirectToDashboard({ redirectQuery });
    },
    [redirectToDashboard, orgId, billId, billsActions, createOrigin]
  );

  const [MarkAsPaidDialog, showDialog] = useModal(QBDashboardMarkAsPaidModal, {
    submit: async ({ intuitAccountId, amount }) => markAsPaidRequest(intuitAccountId, amount),
    isProcessing,
    options,
    modalName: 'mark-as-paid-modal',
    billsMetadata,
  });

  const markBillAsPaid = useCallback(async () => {
    if (!isPartialPaymentsUIEnabled && accounts.length === 1) {
      await markAsPaidRequest(accounts[0].id);
    } else {
      showDialog();
    }
  }, [isPartialPaymentsUIEnabled, accounts, markAsPaidRequest, showDialog]);

  return [markBillAsPaid, MarkAsPaidDialog];
};

export default useMarkAsPaidAction;
