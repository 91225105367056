import React from 'react';
import styled from 'styled-components';
import { Box, Center } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { ReactComponent as SearchIcon } from 'src/app/images/qbo/search.svg';
import { HeadingTextStyle, TextStyle } from './styles';

type Props = {
  term: string;
  title: string;
  description: string;
  actionLabel: string;
  onActionButtonClick: () => void;
};

export const NoSearchResults = ({
  term,
  title,
  description,
  actionLabel,
  onActionButtonClick,
}: Props) => (
  <Center h="100%" data-testid="dashboard-search-no-results">
    <Box textAlign="center">
      <NoResultIcon />
      <Box as="h2" __css={HeadingTextStyle} data-testid="empty-state-title">
        <MIFormattedText label={title} values={{ term }} />
      </Box>
      <Box as="p" __css={TextStyle} data-testid="empty-state-description">
        <MIFormattedText label={description} />
      </Box>

      {actionLabel && (
        <Box onClick={onActionButtonClick}>
          <StyledAction>
            <MIFormattedText label={actionLabel} />
          </StyledAction>
        </Box>
      )}
    </Box>
  </Center>
);

const NoResultIcon = styled(SearchIcon)`
  margin-bottom: 1.5rem;
`;

const StyledAction = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colors.ds.blue[200]};
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`;
