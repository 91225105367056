import { envApi } from 'src/app/version-2/api/env';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { postRequest } from 'src/app/version-2/externals';

export const applicationApi = {
  postSessionStartEvent: (params: { [key: string]: string }) => {
    postRequest(`/risk/session-start-listener`, params);
  },
  postSessionEndEvent: (contextName: string) => {
    const body = {
      channel: contextName,
    };

    const headers = {
      type: 'application/json',
    };
    const blob = new Blob([JSON.stringify(body)], headers);

    try {
      navigator.sendBeacon(
        `${envApi.getConfig().server.baseUrl}/api/risk/session-end-listener`,
        blob
      );
    } catch (error) {
      loggingApi.error('application.api(): postSessionEndEvent sendBeacon failed', {
        error,
      });
    }
  },
};
