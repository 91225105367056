import { useEffect, useState } from 'react';
import { useApi } from 'src/app/hoc/useApi';
import deliveryApi from 'src/app/services/api/delivery';
import deliveryMethodsApi from 'src/app/services/api/deliveryMethods';
import { JustPayLayoutPageRelativeSteps } from 'src/app/pages/just-pay/justPayTypes';
import {
  OptionalDeliveryMethodsType,
  DeliveryOptionType,
  DeliveryMethodType,
} from 'src/app/utils/types';
import { useLocationState } from 'src/app/utils/hooks';
import { removeUnsupportedDeliveryOptionsByDeliveryMethod } from 'src/app/utils/delivery-methods';
import JustPayLayoutPage from 'src/app/pages/just-pay/JustPayLayoutPage';
import PaymentDatePicker from 'src/app/components/common/PaymentDatePicker';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { AreaLoader } from '@melio/billpay-design-system';
import { getJustPayHeaderLabelValues } from 'src/app/pages/just-pay/justPayJSXUtils';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import analytics from 'src/app/services/analytics';
import { CONSTS, ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/app/utils/consts';
import { getInitialProcessingDates, isAfterEndOfLife } from 'src/app/utils/dates';
import { getFundingSourceType } from 'src/app/utils/funding-sources';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
  onError: () => void;
};

type GetDeliveryMethodParamsType = {
  orgId: string;
  vendorId: number;
  id: number;
};

type GetDeliveryMethodResponseType = {
  code: string;
  message: string;
  deliveryMethod: DeliveryMethodType;
};

type GetDeliveryTimeResponseType = {
  suggestedScheduledDate: Date;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  deliveryOptions: DeliveryOptionType[];
  actualDeliveryDays: number;
};

export const JustPaySelectDeductionDate = ({ onNext, onExit, onPrev, onError }: Props) => {
  const {
    site,
    payment,
    payment: {
      orgId,
      fundingSourceId,
      deliveryMethodId,
      deliveryMethodType,
      amount,
      vendor: { id: vendorId, companyName: vendorCompanyName },
      trackingBillId,
    },
    paymentStoreActions,
    companyInfo,
    selectedFundingSource,
    organizationPreferences,
  } = useJustPayStore();

  const isFirstWave = !!organizationPreferences?.billPayFirstWaveUser;
  const [newDeliveryMethod] = useLocationState('newDeliveryMethod');

  const {
    suggestedDates: {
      scheduledDate,
      minScheduledDate,
      deliveryEta,
      maxDeliveryEta,
      deliveryOptions,
      deliveryPreferenceType,
    } = {},
  } = payment;

  const [isNextDisabled, setIsNextDisabled] = useState(
    isAfterEndOfLife(scheduledDate || minScheduledDate, isFirstWave)
  );

  const [isLoading, setIsLoading] = useState(true);
  const fundingSourceType = getFundingSourceType(selectedFundingSource);

  const [getDeliveryTime, , , getDeliveryTimeError] = useApi<
    [
      orgId: string,
      dueDate: Date,
      deliveryMethodId: number,
      fundingSourceId: number,
      amount: number,
      paymentId?: string,
      payBillFlowUUID?: string | null
    ],
    GetDeliveryTimeResponseType
  >(deliveryApi.getDeliveryTime.bind(deliveryApi), false);

  const [getDeliveryMethodById, getDeliveryMethodByIdResult, , getDeliveryMethodByIdError] = useApi<
    [GetDeliveryMethodParamsType],
    GetDeliveryMethodResponseType
  >(deliveryMethodsApi.getDeliveryMethodById, false);

  const getFastPaymentDates = (
    defaultScheduledDate: Date,
    defaultDeliveryEta: Date,
    defaultMaxDeliveryEta: Date,
    deliveryOptions: DeliveryOptionType[],
    deliveryPreferenceType: string
  ) => {
    const fastOption = deliveryOptions?.find((option) => option.type === deliveryPreferenceType);

    return fastOption
      ? {
          scheduledDate: fastOption.scheduledDate,
          deliveryEta: fastOption.deliveryDate,
          maxDeliveryEta: fastOption.maxDeliveryDate,
        }
      : {
          scheduledDate: defaultScheduledDate,
          deliveryEta: defaultDeliveryEta,
          maxDeliveryEta: defaultMaxDeliveryEta,
        };
  };

  const trackFastOptionsShownEvents = (deliveryOptionsCount = 0) => {
    if (deliveryOptionsCount > 1) {
      if (deliveryMethodType === CONSTS.DELIVERY_TYPE.CHECK) {
        analytics.trackAction(
          deliveryOptionsCount === 2 ? 'express-check-shown' : 'express-fast-shown',
          {
            fundingSourceType,
            trackingBillId,
          }
        );
      } else if (deliveryMethodType === CONSTS.DELIVERY_TYPE.ACH) {
        analytics.trackAction('fast-ach-shown', { fundingSourceType, trackingBillId });
      }
    }
  };

  const onDateChange = async ({ value: selectedDate }) => {
    const deliveryTimeResult = await getDeliveryTime(
      orgId.toString(),
      selectedDate,
      deliveryMethodId,
      fundingSourceId,
      amount,
      undefined,
      payment.payBillFlowUUID
    );

    setIsNextDisabled(isAfterEndOfLife(selectedDate, isFirstWave));

    const {
      suggestedScheduledDate,
      deliveryDate: deliveryEta,
      maxDeliveryDate: maxDeliveryEta,
      deliveryOptions,
    } = deliveryTimeResult;

    analytics.trackAction('select-date', {
      suggestedScheduledDate,
      deliveryDate: deliveryEta,
      maxDeliveryDate: maxDeliveryEta,
      trackingBillId,
    });

    const deliveryMethod = getDeliveryMethodByIdResult?.deliveryMethod;
    const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByDeliveryMethod({
      site,
      deliveryOptions,
      deliveryMethod: deliveryMethod as DeliveryMethodType,
      fundingSource: selectedFundingSource,
      companyInfo,
    });

    paymentStoreActions.justPay.justPayWizard.update({
      suggestedDates: {
        deliveryOptions: possibleDeliveryOptions,
        ...getFastPaymentDates(
          suggestedScheduledDate,
          deliveryEta,
          maxDeliveryEta,
          deliveryOptions,
          deliveryPreferenceType as string
        ),
      },
    });
  };

  const onOptionSelected = (
    scheduledDate,
    deliveryDate,
    maxDeliveryDate,
    deliveryPreferenceType
  ) => {
    paymentStoreActions.justPay.justPayWizard.update({
      suggestedDates: {
        deliveryPreferenceType,
        ...getFastPaymentDates(
          scheduledDate,
          deliveryDate,
          maxDeliveryDate,
          deliveryOptions as DeliveryOptionType[],
          deliveryPreferenceType
        ),
      },
    });

    setIsNextDisabled(isAfterEndOfLife(scheduledDate, isFirstWave));

    analytics.trackAction(`delivery-selected-${deliveryPreferenceType}`, { trackingBillId });
  };

  const handleOnNext = () => {
    analytics.trackAction('set-date-continue', { trackingBillId });
    onNext();
  };

  const onCreditCardSelected = (card: FundingSource) => {
    paymentStoreActions.justPay.justPayWizard.update({
      fundingSourceId: card.id,
      fundingSourceType: card.fundingType,
      suggestedFundingSources: {
        selectedFundingSourceId: card.id,
        selectedFundingSource: card,
      },
    });
  };
  // bypasses are used from outside
  // to prevent race conditions/closure restrictions while switching a funding source
  const handleInitialDataLoading = async (bypasses?: any, newDeliveryMethodId?: string) => {
    const initialScheduledDate = payment?.suggestedDates?.scheduledDate || new Date();
    const [initialDatesResult, deliveryMethodResult] = await Promise.all([
      getInitialProcessingDates({
        orgId,
        deliveryMethodId: newDeliveryMethodId || deliveryMethodId,
        fundingSourceId,
        scheduledDate: initialScheduledDate,
        amount,
        dueDate: new Date(),
        undefined,
        payBillFlowUUID: payment?.trackingBillId,
        ...bypasses,
        isFirstWave,
      }),
      getDeliveryMethodById({
        orgId: orgId.toString(),
        vendorId,
        id: Number(newDeliveryMethodId || deliveryMethodId),
      }),
    ]);

    const {
      suggestedScheduledDate,
      minScheduledDate,
      deliveryDate: deliveryEta,
      maxDeliveryDate: maxDeliveryEta,
      deliveryOptions,
    } = initialDatesResult;
    const { deliveryMethod } = deliveryMethodResult;
    const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByDeliveryMethod({
      site,
      deliveryOptions,
      deliveryMethod: deliveryMethod as DeliveryMethodType,
      fundingSource: selectedFundingSource as FundingSource,
      companyInfo,
    });

    setIsNextDisabled(isAfterEndOfLife(suggestedScheduledDate, isFirstWave));

    trackFastOptionsShownEvents(possibleDeliveryOptions?.length);

    paymentStoreActions.justPay.justPayWizard.update({
      suggestedDates: {
        minScheduledDate,
        deliveryOptions: possibleDeliveryOptions,
        deliveryPreferenceType: deliveryMethodType,
        ...getFastPaymentDates(
          suggestedScheduledDate,
          deliveryEta,
          maxDeliveryEta,
          deliveryOptions,
          deliveryPreferenceType as string
        ),
      },
    });

    setIsLoading(false);
  };

  useEffect(() => {
    if (newDeliveryMethod) {
      paymentStoreActions.justPay.justPayWizard.update({
        deliveryMethodId: newDeliveryMethod.id,
        deliveryMethodType: newDeliveryMethod.deliveryType,
      });
    }

    handleInitialDataLoading(undefined, newDeliveryMethod?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getDeliveryTimeError || getDeliveryMethodByIdError) {
      onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDeliveryTimeError, getDeliveryMethodByIdError]);

  if (isLoading) {
    return <AreaLoader />;
  }

  return (
    <JustPayLayoutPage
      headerLabel="justPay.defaultHeader"
      headerLabelValues={getJustPayHeaderLabelValues(amount, vendorCompanyName)}
      title="justPay.deductionDate.title"
      subtitle="justPay.deductionDate.subtitle"
      onNext={handleOnNext}
      goExit={onExit}
      onPrev={onPrev}
      relativeStep={JustPayLayoutPageRelativeSteps.JustPaySelectDeductionDate}
      isNextDisabled={isNextDisabled}
      isQboFooter
    >
      <PaymentDatePicker
        fundingSource={selectedFundingSource as FundingSource}
        deliveryPreference={deliveryPreferenceType}
        deliveryDate={deliveryEta as Date}
        maxDeliveryDate={maxDeliveryEta as Date}
        deliveryMethodType={deliveryMethodType as OptionalDeliveryMethodsType}
        scheduledDate={scheduledDate as Date}
        totalAmount={amount.toString()}
        minDate={new Date(minScheduledDate as Date)}
        onChange={onDateChange}
        deliveryOptions={deliveryOptions}
        onSelectDeliveryOption={onOptionSelected}
        fundingSourceType={fundingSourceType}
        eventPage="just-pay-select-deduction-date"
        loadSuggestedProcessingDate={handleInitialDataLoading}
        onCreditCardSelected={onCreditCardSelected}
        navigationOrigin={ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY}
        billId={trackingBillId}
      />
    </JustPayLayoutPage>
  );
};
