import React from 'react';
import { featureFlags } from '@melio/shared-web';
import { RecordOf } from 'immutable';
import { FormattedNumber } from 'react-intl';
import styled, { css } from 'styled-components';

import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import { geInternationalStaticFee } from 'src/app/utils/international';
import { isAchFundingType } from 'src/app/version-2/utils/checkFees.utils';
import { DeliveryMethodType, OptionalDeliveryMethodsType } from 'src/app/utils/types';
import { CONSTS, CARD_TYPES } from 'src/app/utils/consts';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import MIMoney from 'src/app/components/common/MIMoney';
import { VirtualCheckFeeNotification } from 'src/app/version-2/components/CheckFee/VirtualNotification';
import {
  FundingSourceTypesEnum,
  MiMoneyFlavorEnum,
  CheckFeeEnum,
  FeatureFlagsEnum,
} from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

import { PaymentRegularFeeTotal } from './PaymentRegularFeeTotal';

type PaymentFeeProps = {
  fee: PaymentFeeInfo;
  isVendorAbsorbedCardFee?: boolean;
  deliveryMethod?: RecordOf<DeliveryMethodType>;
  fundingSource?: FundingSource;
  isEdit?: boolean;
};

const PaymentFee = ({
  fee,
  isVendorAbsorbedCardFee,
  deliveryMethod,
  fundingSource,
  isEdit,
}: PaymentFeeProps) => {
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlagsEnum.CHECK_FEES, CheckFeeEnum.CLOSED, {
    shouldTrack: false,
  });

  const getFee = (fee: PaymentFeeInfo) => {
    const feeReduced = fee.finalAmount < fee.baseAmount;
    const showFeeDescription = fee.baseAmount > 0;

    return {
      feeText: <StyledMIMoney amount={fee.finalAmount} />,
      showFeeDescription,
      textValues: {
        showAmount: true,
        baseAmount: fee.baseAmount,
        basePercent: fee?.feeStructure?.value,
        finalAmount: fee.finalAmount,
        savingAmount: fee.promotion.reduction,
        expiration: fee.promotion.expiration && new Date(fee.promotion.expiration),
        promotionName: fee.promotion.promotionName,
      },
      baseAmount: fee.baseAmount,
      savingAmount: fee.promotion.reduction,
      finalAmount: fee.finalAmount,
      showFeeHint: feeReduced,
    };
  };

  const hasValidCheckFS = checkFeeFlag && isAchFundingType(fundingSource);
  const isVirtualDM = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;
  const isAchVirtualDM = !!(hasValidCheckFS && isVirtualDM);
  const isInternational = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL;
  const isCard = fundingSource?.fundingType === FundingSourceTypesEnum.CARD;

  const notifications = (): React.ReactElement | null => {
    if (isAchVirtualDM) return <VirtualCheckFeeNotification />;

    return null;
  };

  const {
    feeText,
    showFeeDescription,
    showFeeHint,
    textValues,
    savingAmount,
    baseAmount,
    finalAmount,
  } = (fee && getFee(fee)) || {};

  if (fee && fee.fastFee) {
    return <FastFee fee={fee} />;
  }

  if (fee && fee.fastFeeApi) {
    return <FastFeeApi fee={fee} />;
  }

  if (fee && isInternational && isCard) {
    return <InternationalFee fee={fee} />;
  }

  if (savingAmount && savingAmount > 0) {
    return (
      <SavingAmountFee
        textValues={textValues}
        baseAmount={baseAmount}
        finalAmount={finalAmount}
        savingAmount={savingAmount}
      />
    );
  }

  return (
    <RegularFee
      feeType={fee?.feeStructure?.feeType}
      textValues={textValues}
      feeText={feeText}
      showFeeDescription={showFeeDescription}
      showFeeHint={showFeeHint}
      isVendorAbsorbedCardFee={isVendorAbsorbedCardFee}
      fundingSource={fundingSource}
      deliveryMethodType={deliveryMethod?.deliveryType}
      notifications={notifications}
      isAchVirtualDM={isAchVirtualDM}
      isEdit={isEdit}
    />
  );
};

export default PaymentFee;

type FastFeeProps = {
  fee: PaymentFeeInfo;
};

const FastFee = ({ fee }: FastFeeProps) => {
  const { feeStructure, fastFee, finalAmount } = fee;
  const { cap } = feeStructure;
  const feeDetailsLabel =
    cap && Number(fastFee.totalAmount) === Number(cap) ? 'feeDetailsNoPercentage' : 'feeDetails';
  const isCreditCardFeeExist = feeStructure?.type === 'percent' && finalAmount > 0;
  const totalAmount = isCreditCardFeeExist
    ? fastFee.totalAmount + finalAmount
    : fastFee.totalAmount;

  return (
    <FeeContainer>
      <SubTitle>
        <MIFormattedText label="bills.pay.confirm.fee" />
      </SubTitle>
      <FeeItem>
        <MIFormattedText
          label={`bills.pay.confirm.${fastFee.type}.${feeDetailsLabel}`}
          values={{ fee: (fastFee as any).fee }}
        />
        <MIFormattedCurrency value={fastFee.totalAmount.toString() || ''} />
      </FeeItem>
      {isCreditCardFeeExist && (
        <FeeItem>
          <MIFormattedText
            label="bills.pay.confirm.creditCardFee"
            values={{
              fee: <FormattedNumber value={feeStructure.value} format="percent" />,
            }}
          />
          <MIFormattedCurrency value={fee.finalAmount.toString() || ''} />
        </FeeItem>
      )}
      <TotalFeeContainer>
        <FeeItem>
          <MIFormattedText label="bills.pay.confirm.totalFee" />
          <MIFormattedCurrency value={totalAmount.toString() || ''} />
        </FeeItem>
        <TotalFeeDescription>
          <MIFormattedText label="bills.pay.confirm.expedited-ach.totalFeeDescription" />
        </TotalFeeDescription>
      </TotalFeeContainer>
    </FeeContainer>
  );
};

type FastFeeApiProps = {
  fee: Record<string, any>;
};

const FastFeeApi = ({ fee }: FastFeeApiProps) => {
  const { feeStructure, fastFeeApi, finalAmount } = fee;
  const feeType = feeStructure?.feeType;
  const fastFeeType = fastFeeApi?.feeType;

  const fastFeeExists = fastFeeType && fastFeeApi?.totalAmount;
  const isCreditCard = feeType === CARD_TYPES.CREDIT;
  const isDebitCard = feeType === CARD_TYPES.DEBIT;
  const isCardFeeExist = (isCreditCard || isDebitCard) && feeStructure?.value;

  return (
    <FeeContainer>
      <SubTitle>
        <MIFormattedText label="bills.pay.confirm.fee" />
      </SubTitle>
      {fastFeeExists && (
        <FeeItem>
          <MIFormattedText
            label={`bills.pay.confirm.${fastFeeApi.feeType}.feeDetails`}
            values={{ fee: (fastFeeApi as any).fee }}
          />
          <MIFormattedCurrency value={fastFeeApi.totalAmount.toString() || ''} />
        </FeeItem>
      )}
      {isCardFeeExist && (
        <FeeItem>
          <MIFormattedText
            label={`bills.pay.confirm.${feeType}CardFee`}
            values={{
              fee: feeStructure?.percent ? (
                <FormattedNumber value={feeStructure?.percent} format="percent" />
              ) : (
                ''
              ),
            }}
          />

          <MIFormattedCurrency value={feeStructure.value.toString() || ''} />
        </FeeItem>
      )}
      <TotalFeeContainer>
        <FeeItem>
          <MIFormattedText label="bills.pay.confirm.totalFee" />
          <MIFormattedCurrency value={finalAmount.toString() || ''} />
        </FeeItem>
        <TotalFeeDescription>
          <MIFormattedText label="bills.pay.confirm.expedited-ach.totalFeeDescription" />
        </TotalFeeDescription>
      </TotalFeeContainer>
    </FeeContainer>
  );
};

type SavingAmountProps = {
  textValues: Record<string, any>;
  baseAmount: number;
  savingAmount: number;
  finalAmount: number;
};

const SavingAmountFee = ({
  textValues,
  baseAmount,
  savingAmount,
  finalAmount,
}: SavingAmountProps) => (
  <FeeContainer>
    <SubTitle>
      <MIFormattedText label="bills.pay.confirm.fee" />
    </SubTitle>
    <FeeItem>
      <MIFormattedText label="bills.pay.confirm.feePromoBase" values={textValues} />
      <StyledMIMoney size="small" amount={baseAmount} />
    </FeeItem>
    <FeeItem>
      <div>
        <MIFormattedText label="bills.pay.confirm.feePromoReduction" values={textValues} />
        <FeeDescription>
          <MIFormattedText label="bills.pay.confirm.feePromoReductionHint" values={textValues} />
        </FeeDescription>
      </div>
      <StyledMIMoney size="small" flavor={MiMoneyFlavorEnum.POSITIVE} amount={-savingAmount} />
    </FeeItem>
    <TotalFeeContainer>
      <FeeItem>
        <MIFormattedText label="bills.pay.confirm.totalFee" values={textValues} />
        <StyledMIMoney size="normal" amount={finalAmount} />
      </FeeItem>
    </TotalFeeContainer>
  </FeeContainer>
);

type RegularFeeProps = {
  feeType?: string;
  textValues: Record<string, any>;
  feeText: React.ReactNode;
  showFeeHint: boolean;
  showFeeDescription: boolean;
  isVendorAbsorbedCardFee?: boolean;
  notifications: () => React.ReactElement | null;
  fundingSource?: FundingSource;
  deliveryMethodType?: OptionalDeliveryMethodsType | undefined;
  isAchVirtualDM: boolean;
  isEdit?: boolean | undefined;
};

const RegularFee = ({
  showFeeDescription,
  isVendorAbsorbedCardFee,
  notifications: Notifications,
  isAchVirtualDM,
  ...rest
}: RegularFeeProps) => {
  const getFeeFormattedText = (showFeeDescription: boolean, isVendorAbsorbedCardFee?: boolean) => {
    let feeFormattedText;

    if (showFeeDescription) {
      feeFormattedText = {
        label: 'bills.pay.confirm.feeTerm',
      };
    } else if (isVendorAbsorbedCardFee) {
      feeFormattedText = {
        label: 'bills.pay.confirm.absorbedFee',
      };
    }

    return feeFormattedText;
  };

  const feeFormattedText = getFeeFormattedText(showFeeDescription, isVendorAbsorbedCardFee);

  return (
    <FeeContainer>
      <SubTitle>
        <MIFormattedText label="bills.pay.confirm.fee" />
      </SubTitle>
      <Notifications />
      {!isAchVirtualDM && <PaymentRegularFeeTotal {...rest} />}
      {feeFormattedText && (
        <FeeDescription>
          <MIFormattedText {...feeFormattedText} />
        </FeeDescription>
      )}
    </FeeContainer>
  );
};

type InternationalFeeProps = {
  fee: Record<string, any>;
};
const InternationalFee = ({ fee }: InternationalFeeProps) => {
  const { feeStructure, finalAmount } = fee;
  const feeType = feeStructure?.feeType;

  const internationalFee = geInternationalStaticFee();
  const isCreditCard = feeType === CARD_TYPES.CREDIT;
  const isCardFeeExist = isCreditCard && feeStructure?.value;

  return (
    <FeeContainer>
      <SubTitle>
        <MIFormattedText label="bills.pay.confirm.fee" />
      </SubTitle>

      {isCardFeeExist && (
        <FeeItem>
          <MIFormattedText
            label={`bills.pay.confirm.${feeType}CardFee`}
            values={{
              fee: feeStructure?.percent ? (
                <FormattedNumber value={feeStructure?.percent} format="percent" />
              ) : (
                ''
              ),
            }}
          />

          <MIFormattedCurrency value={feeStructure.value.toString() || ''} />
        </FeeItem>
      )}

      {internationalFee && (
        <FeeItem>
          <MIFormattedText label={`bills.pay.confirm.internationalFee`} />

          <MIFormattedCurrency value={internationalFee.toString() || ''} />
        </FeeItem>
      )}
      <TotalFeeContainer>
        <FeeItem>
          <MIFormattedText label="bills.pay.confirm.totalFee" />
          <MIFormattedCurrency value={finalAmount.toString() || ''} />
        </FeeItem>
        <TotalFeeDescription>
          <MIFormattedText label="bills.pay.confirm.expedited-ach.totalFeeDescription" />
        </TotalFeeDescription>
      </TotalFeeContainer>
    </FeeContainer>
  );
};

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const FeeContainer = styled(BaseContainer)`
  border-bottom: 0;
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeContainer}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FeeDescription = styled.div`
  ${baseTextStyles}
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeDescription}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;

const FeeItem = styled.div<{ total?: number }>`
  padding-right: 2rem;
  margin: 2rem 0;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) =>
    props.total ? props.theme.text.size.subTitle : props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.regular};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TotalFeeContainer = styled.div`
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  ${FeeItem} {
    margin-bottom: 0.5rem;
  }
`;

const TotalFeeDescription = styled.div`
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.hint};
  ${(props) => props.theme?.components?.BillPaymentReview?.TotalFeeDescription}
`;
