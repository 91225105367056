import React from 'react';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import styled from 'styled-components';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';
import intercomService from '../../services/intercom';

function MicroDepositsBlockedPage() {
  const site = useSiteContext();

  return (
    <QBOSuccessLayout
      hideHeader
      illustration={<ErrorIcon />}
      title="settings.microDeposits.verifyDialogLimitReached.title"
      text="settings.microDeposits.verifyDialogLimitReached.subtitleWithoutChat"
      textValues={{
        supportPhone: (
          <SupportLink href={`tel:${site.config.support.phone}`}>
            {site.config.support.phone}
          </SupportLink>
        ),
        supportEmail: (
          <SupportLink href={`mailto:${site.config.support.email}`}>
            {site.config.support.email}
          </SupportLink>
        ),
        br: <br />,
      }}
      buttonLabel="fundingSources.microDeposits.chatWithSupport"
      buttonValues={{
        icon: <SupportIcon />,
      }}
      buttonAction={() => {
        intercomService.show();
      }}
    />
  );
}

export default MicroDepositsBlockedPage;
const ErrorIcon = styled.i.attrs({ className: 'icon-notification-icon' })`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.9rem;
  color: ${(props) => props.theme.colors.primary.destructive};
  font-size: 4.8rem;
`;
const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;

const SupportIcon = styled.i.attrs({ className: 'icon-support-icon' })`
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 2.4rem;
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1rem;
  vertical-align: middle;
`;
