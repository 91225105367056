import { DeliveryMethod, FundingSource } from 'src/app/version-2/model/dtos';
import { CardNetworkEnum, DeliveryEnum } from 'src/app/version-2/model/enums';
import { BatchBulkPaymentIntentErrorsEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/BatchBulkPaymentIntentErrors.enum';

import { date, object, string } from 'yup';

export const batchBulkPaymentIntentItemSchema = object({
  selectedPaymentMethod: object().defined(BatchBulkPaymentIntentErrorsEnum.NO_PAYMENT_METHOD),
  selectedDeliveryMethod: object().defined(BatchBulkPaymentIntentErrorsEnum.NO_DELIVERY_METHOD),
  deductionDate: date().defined(BatchBulkPaymentIntentErrorsEnum.NO_DEDUCTION_DATE),
  mccCode: string().when(['selectedPaymentMethod'], {
    is: (selectedPaymentMethod: FundingSource) =>
      selectedPaymentMethod?.cardAccount?.network === CardNetworkEnum.AMEX,
    then: string().defined(BatchBulkPaymentIntentErrorsEnum.AMEX_VERIFICATION_MISSING),
  }),
  purpose: string().when(['selectedDeliveryMethod'], {
    is: (selectedDeliveryMethod: DeliveryMethod) =>
      selectedDeliveryMethod?.deliveryType === DeliveryEnum.INTERNATIONAL,
    then: string().defined(BatchBulkPaymentIntentErrorsEnum.INTERNATIONAL_DETAILS_MISSING),
  }),
});
