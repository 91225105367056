import { Text } from '@melio/billpay-design-system';
import { ExperimentLandingPageContainer } from 'src/app/version-2/components';

import styled from 'styled-components';

export const PageContainer = styled(ExperimentLandingPageContainer)<{ $faqPanel?: boolean }>`
  align-content: flex-start;
  grid-template-rows: 9rem 5.2rem 8rem 47rem;
  height: auto;

  p {
    margin: 0;
  }

  #drawer {
    position: fixed;
    height: ${({ $faqPanel }) => ($faqPanel ? 100 : 0)}%;
  }
`;

export const PaymentInfoText = styled(Text)`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  margin: 0;
`;

export const Subtitle = styled.div<{ $variant?: string }>`
  max-width: ${({ $variant }) => ($variant === 'table' ? 67.8 : 85)}rem;
`;

export const Footnote = styled.div<{ $variant?: string }>`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: flex-start;
  max-width: ${({ $variant }) => ($variant === 'table' ? 67.8 : 85)}rem;

  p {
    display: flex;
    gap: 1rem;
  }
`;

export const CreditCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  padding-top: 0.5rem;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 999;
  background: ${({ theme }) => theme.colors.ds.gray[800]};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: unset;
  }
`;

export const BoldString = styled.b`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

export const ProgressContainer = styled.div`
  position: absolute;
  top: 4.8rem;
  left: 0;
  height: 0.2rem;
  width: 100%;
`;

export const ProgressBar = styled.div<{ $width?: number }>`
  position: relative;
  bottom: 0;
  left: 0;
  height: 0.2rem;
  width: ${({ $width }) => $width || 20}%;
  background: ${({ theme }) => theme.colors.ds.green[200]};
`;

export const IconContainer = styled.div`
  svg {
    path {
      fill: ${({ theme }) => theme.colors.ds.gray[200]};
    }
  }
`;
