import { featureFlags } from '@melio/shared-web';
import {
  shouldAskForVendorEmail,
  ShouldAskForVendorEmailProps,
} from 'src/app/modules/vendors/vendor-email/utils/should-ask-for-vendor-email';

const FEATURE_FLAG_KEY = 'bp-collect-vendor-email-on-memo-step';

export const useShouldAskForVendorEmailOnMemoStep = ({
  vendorContactEmail,
  deliveryType,
  isOwnedVendorExists,
}: ShouldAskForVendorEmailProps) => {
  const [isCollectVendorEmailOnMemoStepEnabled] = featureFlags.useFeature(FEATURE_FLAG_KEY, false);

  if (!isCollectVendorEmailOnMemoStepEnabled) {
    return false;
  }

  return shouldAskForVendorEmail({
    vendorContactEmail,
    deliveryType,
    isOwnedVendorExists,
  });
};
