import React from 'react';
import { Box, Flex, Link, Tooltip } from '@melio/billpay-design-system';

import { useBreak } from 'src/app/hoc';
import { MIFormattedText } from 'src/app/utils/formatting';
import { DeliveryDateFormat } from 'src/app/utils/dates';

import { TimelineStepType } from '../../types';
import {
  VendorPaymentTimelineStepContainer,
  VendorPaymentTimelineStepContent,
  VendorPaymentTimelineStepStatus,
} from './StyledComponents';

type Props = {
  step: TimelineStepType;
};

const VendorPaymentTimelineStep = ({
  step: {
    status,
    nextStepStatus,
    title,
    dates,
    subtitle,
    subtitleValues,
    hint,
    actionLabel,
    tooltipLabel,
    onAction,
    textValues,
  },
}: Props) => {
  const { isMobile } = useBreak();
  const LinkComponent = (
    <Flex>
      <Link
        textStyle={isMobile ? 'body3' : 'body4'}
        color="blue.500"
        cursor="pointer"
        onClick={onAction}
        data-testid="vendor-payment-timeline-step-action"
      >
        <MIFormattedText label={actionLabel} values={textValues?.actionLabel || {}} />
      </Link>
    </Flex>
  );

  return (
    <VendorPaymentTimelineStepContainer
      status={status}
      data-testid="vendor-payment-timeline-step-container"
    >
      <VendorPaymentTimelineStepStatus status={status} nextStepStatus={nextStepStatus} />
      <VendorPaymentTimelineStepContent>
        <Flex justify="space-between" gridGap={5}>
          <Flex direction="column">
            <Box
              textStyle={isMobile ? 'body2' : 'body3'}
              fontWeight="ds.semi"
              data-testid="vendor-payment-timeline-step-title"
            >
              <MIFormattedText label={title} />
            </Box>
            {subtitle && (
              <Box data-testid="vendor-payment-timeline-step-subtitle">
                <MIFormattedText label={subtitle} values={subtitleValues} />
              </Box>
            )}
          </Flex>
          <Box
            textAlign="right"
            textStyle={isMobile ? 'body2' : 'body3'}
            flex="none"
            data-testid="vendor-payment-timeline-step-date"
          >
            {DeliveryDateFormat({
              date: dates ? dates[0] : null,
              maxDate: dates ? dates[1] : null,
            })}
          </Box>
        </Flex>
        {(hint || actionLabel) && (
          <Flex flexDirection={isMobile ? 'column' : 'row'} gridGap={1}>
            {hint && (
              <Box
                textStyle={isMobile ? 'body3' : 'body4'}
                color="gray.300"
                data-testid="vendor-payment-timeline-step-hint"
              >
                <MIFormattedText label={hint} />
              </Box>
            )}
            {tooltipLabel && actionLabel && onAction && (
              <Tooltip
                placement="bottom"
                label={
                  <MIFormattedText label={tooltipLabel} values={textValues?.tooltipLabel || {}} />
                }
              >
                {LinkComponent}
              </Tooltip>
            )}
            {!tooltipLabel && actionLabel && onAction && LinkComponent}
          </Flex>
        )}
      </VendorPaymentTimelineStepContent>
    </VendorPaymentTimelineStepContainer>
  );
};

export { VendorPaymentTimelineStep };
