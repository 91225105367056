import styled, { css } from 'styled-components';
import { Loader } from '@melio/billpay-design-system';

export const StyledForm = styled.div<{ $hasScroll: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  max-height: 46rem;
  padding: 0 1rem;
  box-sizing: border-box;
  padding-right: 2rem;
  padding-bottom: 2rem;

  ${(props) =>
    props.$hasScroll &&
    css`
      overflow-y: auto;
      overscroll-behavior-y: contain;
    `}
`;

export const StyledRow = styled.div<{ $fullWidth?: boolean; mb?: string }>`
  margin-bottom: ${(p) => (p.mb ? p.mb : '4rem')};
  width: 100%;
  display: flex;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.$fullWidth &&
    css`
      & > div {
        width: 100%;
      }
    `}

  #inlineDatePicker {
    padding-left: 0;
  }
`;

export const StyledCol = styled.div`
  width: 48%;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: ${(props) => `0 0 5rem 5rem ${props.theme.colors.white.opaque}`};
  transition: 0.1s;
  z-index: 1;
`;
