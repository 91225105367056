import { applicationApi } from 'src/app/version-2/modules/application/api/application.api';
import { applicationActions } from './application.slice';

import { takeEvery } from 'redux-saga/effects';

// eslint-disable-next-line require-yield
function* sessionStartActionHandler(
  action: ReturnType<typeof applicationActions.sessionStartAction>
) {
  applicationApi.postSessionStartEvent(action.payload);
}

// eslint-disable-next-line require-yield
function* sessionEndActionHandler(action: ReturnType<typeof applicationActions.sessionEndAction>) {
  const { contextName } = action.payload;

  applicationApi.postSessionEndEvent(contextName);
}

export function* watchApplicationSaga() {
  yield takeEvery(applicationActions.sessionStartAction, sessionStartActionHandler);
  yield takeEvery(applicationActions.sessionEndAction, sessionEndActionHandler);
}
