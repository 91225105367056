import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';

import { getProfile, getCompanyInfo } from 'src/app/redux/user/selectors';
import Step from 'src/app/components/layout/QBOLayoutPage';
import analytics from 'src/app/services/analytics';
import internationalAPI from 'src/app/services/api/deliveryMethods';
import { useApi } from 'src/app/hoc/useApi';
import { CONSTS } from 'src/app/utils/consts';
import { loggingApi } from 'src/app/version-2/api/loggers';
import locations from 'src/app/utils/locations';
import { getPaymentsActions } from 'src/app/modules/payments/payment-store';
import { isPayBillFlow, isBatchFlow } from '../../utils';
import InternationalContext from '../../Context';
import { StepProps, StateTypes } from '../../types';
import Form from './Form';
import { internationalLocations } from 'src/app/pages/vendor/delivery-methods/international/locations';
import { ErrorAlert } from 'src/app/pages/vendor/delivery-methods/international/components/ErrorAlert';
import useShowUltimateBeneficialOwnersFlow from '../../hooks/useShowUltimateBeneficialOwnersFlow';
import { useCheckMissingInternationalLegalInfo } from '../../hooks/useCheckInternationalHasMissingLegalInfo';
import { ErrorModal } from './ErrorModal';

function VendorAddress({ relativeStep, onNext, onPrev, onExit }: StepProps) {
  const [state, actions] = useContext(InternationalContext);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const companyInfo = useSelector(getCompanyInfo);
  const [editDeliveryMethod, , editLoading, editError] = useApi(
    internationalAPI.editInternationalDelivery
  );
  const [createDeliveryMethod, , createLoading, createError] = useApi(
    internationalAPI.createInternationalDelivery
  );
  const { setQboBatchPaymentsNavigationUrls } = getPaymentsActions(dispatch);
  const shouldDisplayUltimateBeneficialOwnersFlow = useShowUltimateBeneficialOwnersFlow();
  const { checkMissingInternationalCompanyLegalInfo } = useCheckMissingInternationalLegalInfo();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const isLoading = createLoading || editLoading;

  useEffect(() => {
    analytics.page('pay-bill', 'international-country-vendor-details');
  }, []);

  useEffect(() => {
    const error = state.edit ? editError : createError;

    if (error?.responseData) {
      analytics.track('pay-bill', 'international-create-dm-error', {
        error: error.responseData,
      });
    }

    if (error && !showErrorModal) setShowErrorModal(true);
  }, [editError, createError]);

  const titleValues = actions.getTitleValues();
  const originValues = actions.getOriginValues();

  const handleSubmit = async (requierdFieldsValues) => {
    actions.setContextData(StateTypes.REQUIRED_FIELDS, requierdFieldsValues);
    const missingLegalInfo = await checkMissingInternationalCompanyLegalInfo({
      companyInfo,
      profile,
    });

    const isBatch = isBatchFlow(state.origin);

    if (missingLegalInfo) {
      if (isBatch) {
        const missingDetailsBatch = `${locations.Bills.pay.batchCompleteLegalInfo.url()}?isInternational=true`;

        setQboBatchPaymentsNavigationUrls({
          redirectUrl: state.redirectUrl,
          // batch page close and confirm buttons won't work as expected without this reset (exitUrl is batch page)
          exitUrl: '',
        });

        return actions.goToPath({
          path: missingDetailsBatch,
          state: {
            international: {
              state: { ...state, [StateTypes.REQUIRED_FIELDS]: requierdFieldsValues },
            },
          },
        });
      }

      // assuming tht we want to redirect to paybill
      return setTimeout(() => actions.goToPath({ path: internationalLocations.missingDetails }));
    }

    try {
      const deliveryAction = (partialState) =>
        state.edit
          ? actions.editDelivery(editDeliveryMethod, partialState)
          : actions.createDelivery(createDeliveryMethod, partialState);

      const newDeliveryMethod = await deliveryAction({
        [StateTypes.REQUIRED_FIELDS]: requierdFieldsValues,
      });

      if (shouldDisplayUltimateBeneficialOwnersFlow) {
        const uboUrl = internationalLocations.ultimateBeneficialOwners;
        const uboFlowUrl = isBatch ? `${uboUrl}?isBatchCreateDM=true` : uboUrl; // some flows override one of the params

        return setTimeout(() =>
          actions.goToPath({
            path: uboFlowUrl,
            state: {
              newDeliveryMethod: {
                ...newDeliveryMethod,
                deliveryType: CONSTS.DELIVERY_TYPE.INTERNATIONAL,
              },
              ...(state?.preservedState || {}),
              isBatchCreateDM: !!isBatch,
            },
          })
        );
      }

      if (isPayBillFlow(state.origin)) {
        return onNext?.();
      }

      // Here we assuming that if we're not in paybill (isPayBillFlow)
      // we're returning to batch
      return actions.goToPath({
        path: state.redirectUrl,
        state: {
          newDeliveryMethod: {
            ...newDeliveryMethod,
            deliveryType: CONSTS.DELIVERY_TYPE.INTERNATIONAL,
          },
          ...(state?.preservedState || {}),
        },
        params: { currentId: state.billId },
      });
    } catch (e) {
      loggingApi.error('InternationalVendorAddress.submit(): error', {
        error: e,
        isEdit: state?.edit,
        billId: state?.billId,
      });

      return undefined;
    }
  };

  if (!state.country?.code)
    return (
      <Redirect
        to={{
          pathname: generatePath(internationalLocations.index, {
            orgId: state.orgId,
            id: state.vendor.id,
          }),
        }}
      />
    );

  const onCloseErrorModal = () => setShowErrorModal(false);

  return (
    <Step
      onPrev={onPrev}
      titleValues={titleValues}
      relativeStep={relativeStep}
      goExit={onExit}
      hideHeader
      title="international.vendorDetails.title"
      {...originValues}
    >
      {!isLoading && <ErrorAlert error={state.edit ? editError : createError} />}
      <Form onSubmit={handleSubmit} isLoading={isLoading} />
      <ErrorModal hasError={showErrorModal} onClose={onCloseErrorModal} />
    </Step>
  );
}

export default VendorAddress;
