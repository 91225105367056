const ACTION_PREFIX = '[ORGANIZATION]';

export const SET_ORGANIZATION_PREFERENCES = `${ACTION_PREFIX} SET_ORGANIZATION_PREFERENCES`;

export const UPDATE_ORGANIZATION_PREFERENCE = `${ACTION_PREFIX} UPDATE_ORGANIZATION_PREFERENCE`;
export const UPDATE_ORGANIZATION_PREFERENCE_SUCCESS = `${ACTION_PREFIX} UPDATE_ORGANIZATION_PREFERENCE_SUCCESS`;
export const UPDATE_ORGANIZATION_PREFERENCE_FAILED = `${ACTION_PREFIX} UPDATE_ORGANIZATION_PREFERENCE_FAILED`;

export const UPDATE_SERVICE_FEES_PREFERENCE = `${ACTION_PREFIX} UPDATE_SERVICE_FEES_PREFERENCE`;
export const LOAD_FEE_CATALOG_SUCCESS = `${ACTION_PREFIX} LOAD_FEE_CATALOG_SUCCESS`;
export const LOAD_FEE_CATALOG_FAILED = `${ACTION_PREFIX} LOAD_FEE_CATALOG_FAILED`;

export const INITIAL_FETCH_FEE_FUNDING_SOURCE = `${ACTION_PREFIX} INITIAL_FETCH_FEE_FUNDING_SOURCE`;
export const INITIAL_FETCH_FEE_FUNDING_SOURCE_SUCCESS = `${ACTION_PREFIX} INITIAL_FETCH_FEE_FUNDING_SOURCE_SUCCESS`;
export const INITIAL_FETCH_FEE_FUNDING_SOURCE_FAILED = `${ACTION_PREFIX} INITIAL_FETCH_FEE_FUNDING_SOURCE_FAILED`;

export const REMOVE_FEE_FUNDING_SOURCE = `${ACTION_PREFIX} REMOVE_FEE_FUNDING_SOURCE`;
export const CLEAR_FEE_FUNDING_SOURCE = `${ACTION_PREFIX} CLEAR_FEE_FUNDING_SOURCE`;
export const GET_ACTIVE_FEE_FUNDING_SOURCE = `${ACTION_PREFIX} GET_ACTIVE_FEE_FUNDING_SOURCE`;
export const GET_ACTIVE_FEE_FUNDING_SOURCE_SUCCESS = `${ACTION_PREFIX} GET_ACTIVE_FEE_FUNDING_SOURCE_SUCCESS`;
export const GET_ACTIVE_FEE_FUNDING_SOURCE_FAILED = `${ACTION_PREFIX} GET_ACTIVE_FEE_FUNDING_SOURCE_FAILED`;

export const UPDATE_FEE_FUNDING_SOURCE = `${ACTION_PREFIX} UPDATE_FEE_FUNDING_SOURCE`;
export const UPDATE_ORG_FEE_FUNDING_SOURCE = `${ACTION_PREFIX} UPDATE_ORG_FEE_FUNDING_SOURCE`;

export const CREATE_FEE_FUNDING_SOURCE = `${ACTION_PREFIX} CREATE_FEE_FUNDING_SOURCE`;

export const SET_FEE_FUNDING_LOADING = `${ACTION_PREFIX} SET_FEE_FUNDING_LOADING`;
export const INITIAL_SET_FEE_FUNDING_SOURCE = `${ACTION_PREFIX} INITIAL_SET_FEE_FUNDING_SOURCE`;
