import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import locations from 'src/app/utils/locations';
import globalLocations from 'src/app/pages/locations';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import accountingSoftwareSync from 'src/app/services/api/accountingSoftwareSync';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import intuit from 'src/app/utils/intuit';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { ENTRYPOINT_SOURCE, PAYMENT_STATUS } from 'src/app/utils/consts';
import { useLocationState } from 'src/app/utils/hooks';
import { isMobileInLoader } from 'src/app/utils/screen';
import { ProgressPage } from '../ProgressPage';

type Props = {
  id: string;
};

export const EditPaymentLoader = ({ id }: Props) => {
  const orgId = useSelector(getOrgId);
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const [source] = useLocationState('source');
  const [, historyReplace] = useHistoryWithOrgId();
  const { fetchRedirectQuery } = useRedirectToDashboard();

  useEffect(() => {
    intuit.endLoadingWrapper();
  }, []);

  useEffect(() => {
    async function loadPaymentData() {
      if (orgId) {
        try {
          const { payment } = await accountingSoftwareSync.syncPaymentByOriginId(orgId, id);

          if (payment.billId) {
            const shouldRedirectToViewPayment =
              isMobileInLoader() ||
              (payment.status === PAYMENT_STATUS.FAILED && source === ENTRYPOINT_SOURCE.EMAIL);

            if (shouldRedirectToViewPayment) {
              const viewPaymentUrl = isPartialPaymentsEnabled
                ? locations.Bills.payment.url({
                    billId: payment.billId,
                    paymentId: payment.id,
                  })
                : locations.Bills.single.url({ billId: payment.billId });

              historyReplace({
                path: viewPaymentUrl,
              });
            } else {
              const params = await fetchRedirectQuery(
                [
                  {
                    paymentId: payment.id,
                    billId: payment.billId,
                  },
                ],
                true
              );

              historyReplace({
                path: globalLocations.qbDashboard.dashboard,
                query: params,
              });
            }
          } else {
            throw new Error('Bill not found');
          }
        } catch (e) {
          historyReplace({ path: globalLocations.entrypoints.errors.billNotFound });
        }
      }
    }

    loadPaymentData();
  }, [orgId, id, fetchRedirectQuery, source, isPartialPaymentsEnabled]);

  return <ProgressPage progress={75} />;
};
