import { select, put, takeEvery, all, call, delay } from 'redux-saga/effects';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { getOrgId } from 'src/app/redux/user/selectors';
import {
  UPDATE_ORGANIZATION_PREFERENCE,
  UPDATE_SERVICE_FEES_PREFERENCE,
  REMOVE_FEE_FUNDING_SOURCE,
  UPDATE_ORG_FEE_FUNDING_SOURCE,
  CREATE_FEE_FUNDING_SOURCE,
} from './actionTypes';
import {
  updateOrganizationPreferenceSuccessAction,
  updateOrganizationPreferenceFailedAction,
  updateFeeFundingSource,
  setFeeFundingLoading,
  clearFeeFundingSource,
} from './actions';
import organizationPreferencesApi from '../../services/api/organizationPreferences';
import organizationApi from '../../services/api/organizations';

function* updateOrganizationPreference({ orgId, key, value, resolve, reject }) {
  try {
    yield call(organizationPreferencesApi.updateOrganizationPreference, orgId, key, value);
    yield put(updateOrganizationPreferenceSuccessAction(key, value));
    resolve();
  } catch (e) {
    yield put(updateOrganizationPreferenceFailedAction());
    reject();
  }
}

function* updateServiceFeesPreference({ orgId, value, resolve, reject }) {
  try {
    yield call(organizationPreferencesApi.updateServiceFeesPreference, orgId, value);
    yield put(updateOrganizationPreferenceSuccessAction('isVendorAbsorbedFee', value));
    resolve();
  } catch (e) {
    yield put(updateOrganizationPreferenceFailedAction());
    reject();
  }
}

function* removeOrganizationFeeFundingSource({ payload }) {
  const { organizationBillingFeeId, dismissModal } = payload;

  try {
    yield put(setFeeFundingLoading(true));

    const orgId = yield select(getOrgId);

    yield call(organizationApi.removeFeeFundingSource, orgId, organizationBillingFeeId);
    yield put(clearFeeFundingSource());
    yield put(setFeeFundingLoading(false));

    yield dismissModal();

    yield pushNotification({
      type: NOTIFICATION_VARIANT.INFO,
      msg: 'settings.billing.feeCard.toaster.remove.success',
    });
  } catch (error) {
    yield pushNotification({
      type: NOTIFICATION_VARIANT.ERROR,
      msg: 'settings.billing.feeCard.toaster.remove.failed',
    });
  }
}

function* updateOrganizationFeeFundingSource(action) {
  const {
    payload: { redirectOnSuccess, feeFundingSource },
  } = action;

  try {
    yield put(setFeeFundingLoading(true));
    const orgId = yield select(getOrgId);

    yield call(organizationApi.updateFeeFundingSource, orgId, feeFundingSource);
    const orgFeeFundingSource = yield call(organizationApi.fetchActiveFeeFundingSource, orgId);

    yield put(updateFeeFundingSource(orgFeeFundingSource.organizationBillingFees[0]));
    yield delay(1400);
  } catch (error) {
    pushNotification({
      type: NOTIFICATION_VARIANT.ERROR,
      msg: 'settings.billing.feeCard.toaster.create.fail',
    });
  } finally {
    yield put(setFeeFundingLoading(false));
    yield delay(100);
    yield redirectOnSuccess();
  }
}

function* createOrganizationFeeFundingSource({
  payload: { createOrganizationFeeFundingSource, redirectOnSuccess },
}) {
  try {
    yield put(setFeeFundingLoading(true));
    const orgId = yield select(getOrgId);

    yield call(organizationApi.createFeeFundingSource, orgId, createOrganizationFeeFundingSource);
    const orgFeeFundingSource = yield call(organizationApi.fetchActiveFeeFundingSource, orgId);

    yield put(updateFeeFundingSource(orgFeeFundingSource.organizationBillingFees[0]));
    yield delay(1400);
  } catch (error) {
    pushNotification({
      type: NOTIFICATION_VARIANT.ERROR,
      msg: 'settings.billing.feeCard.toaster.create.fail',
    });
  } finally {
    yield put(setFeeFundingLoading(false));
    yield delay(100);
    yield redirectOnSuccess();
  }
}

export function* organizationSagas() {
  yield all([
    takeEvery(CREATE_FEE_FUNDING_SOURCE, createOrganizationFeeFundingSource),
    takeEvery(UPDATE_ORG_FEE_FUNDING_SOURCE, updateOrganizationFeeFundingSource),
    takeEvery(REMOVE_FEE_FUNDING_SOURCE, removeOrganizationFeeFundingSource),
    takeEvery(UPDATE_ORGANIZATION_PREFERENCE, updateOrganizationPreference),
    takeEvery(UPDATE_SERVICE_FEES_PREFERENCE, updateServiceFeesPreference),
  ]);
}
