import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Location } from 'history';
import analytics from 'src/app/services/analytics';
import { WIZARD_PROGRESS_BAR_TYPE } from 'src/app/utils/consts';
import { WizardProgressBarType } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import styled, { css, ThemeProps } from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { ProgressBarFlavorEnum } from 'src/app/version-2/model/enums';
import ProgressBar from '../common/ProgressBar';
import StepProgressBar from './StepProgressBar';

type Props = {
  exitLink?: string | Location;
  goExit?: () => void;
};

const CloseButton = ({ exitLink, goExit }: Props) => {
  if (exitLink) {
    return (
      <NavLink to={exitLink}>
        <CloseIcon />
      </NavLink>
    );
  }

  if (goExit) {
    return (
      <CloseAction onClick={goExit}>
        <CloseIcon data-testid="close-form-icon" />
      </CloseAction>
    );
  }

  return <></>;
};

const SupportContainer = ({ onSupportTrigger }) => (
  <SupportAction onClick={onSupportTrigger}>
    <SupportIcon />
    <MIFormattedText label="wizard.support" />
  </SupportAction>
);

const CloseIcon = styled.i.attrs({ className: 'icon-close-icon' })``;
const SupportIcon = styled.i.attrs({ className: 'icon-support-icon', size: 2 })`
  font-size: 2rem;
  padding-right: 0.8rem;
`;

type StepHeaderProps = {
  relativeStep?: number | null;
  isPrevDisabled?: boolean;
  onPrev?: () => void | null;
  isPrev?: boolean;
  progressBarSteps?: string[];
  minorSteps?: number[];
  progressBarType?: WizardProgressBarType;
  exitLink?: string | Location;
  goExit?: () => void;
  displaySupportLink?: boolean;
  onSupportTrigger?: () => void;
};

const StepHeader = ({
  exitLink,
  goExit,
  relativeStep,
  isPrevDisabled,
  onPrev,
  isPrev,
  progressBarSteps,
  minorSteps,
  progressBarType,
  displaySupportLink,
  onSupportTrigger,
}: StepHeaderProps) => {
  const renderProgressBar = () => {
    if (
      progressBarType === WIZARD_PROGRESS_BAR_TYPE.WITH_SUBSTEPS &&
      progressBarSteps &&
      minorSteps
    ) {
      return (
        <StepProgressBarContainer>
          <StepProgressBar progressBarSteps={progressBarSteps} minorSteps={minorSteps} />
        </StepProgressBarContainer>
      );
    }

    if (relativeStep) {
      return <ProgressBar relativeStep={relativeStep} flavor={ProgressBarFlavorEnum.WIZARD} />;
    }

    return null;
  };
  const handlePrevClick = () => {
    if (onPrev) {
      analytics.trackAction('go.back');
      onPrev();
    }
  };

  const handleExitClick = goExit
    ? () => {
        analytics.trackAction('go.exit');
        goExit();
      }
    : undefined;

  return (
    <StepHeaderContainer spaced={relativeStep || isPrev}>
      <LeftActionsSection>
        {!isPrevDisabled && isPrev && (
          <Back
            progressBarType={progressBarType}
            onClick={handlePrevClick}
            data-testid="previous-icon"
          >
            <i className="icon-prev-icon" />
          </Back>
        )}
        {isPrevDisabled && <Back progressBarType={progressBarType} />}
        {displaySupportLink && <div />}
      </LeftActionsSection>
      {renderProgressBar()}
      <RightActionsSection>
        {displaySupportLink && <SupportContainer onSupportTrigger={onSupportTrigger} />}
        <Close progressBarType={progressBarType}>
          <CloseButton exitLink={exitLink} goExit={handleExitClick} />
        </Close>
      </RightActionsSection>
    </StepHeaderContainer>
  );
};

StepHeader.defaultProps = {
  exitLink: '',
  goExit: null,
  isPrevDisabled: false,
  onPrev: null,
  isPrev: true,
  progressBarSteps: [],
  minorSteps: [],
  relativeStep: null,
};

const StepHeaderContainer = styled.div<{ spaced?: number | boolean | null }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${(props) => (props.spaced ? 'space-between' : 'flex-end')};

  @media ${devices.mobile}, ${devices.phablet} {
    align-items: center;
  }
  ${(props) => props.theme.components?.StepHeader?.StepHeaderContainer}
`;

const StepProgressBarContainer = styled.div`
  display: flex;
  flex-grow: 3;
  width: 100%;
  justify-content: center;
`;

const navIconStyles = css`
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  margin-top: ${(props: ThemeProps<any> & { progressBarType?: WIZARD_PROGRESS_BAR_TYPE }) =>
    props.progressBarType && props.progressBarType === WIZARD_PROGRESS_BAR_TYPE.WITH_SUBSTEPS
      ? '0.8rem'
      : '0'};

  i {
    cursor: pointer;
  }
`;

const Back = styled.div<{ progressBarType?: WizardProgressBarType }>`
  ${navIconStyles};

  color: ${(props) => props.theme.text.color.label};
  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }

  ${(props) => props.theme?.components?.StepHeader?.StepHeaderBack};
`;

const LeftActionsSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
`;
const RightActionsSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const Close = styled.div<{ progressBarType?: WizardProgressBarType }>`
  ${navIconStyles};

  a {
    color: ${(props) => props.theme.text.color.label};
    &:hover {
      color: ${(props) => props.theme.text.color.main};
    }
    text-decoration: none;
  }

  ${(props) => props.theme?.components?.StepHeader?.StepHeaderClose};
`;

const CloseAction = styled.div`
  color: ${(props) => props.theme.text.color.label};
  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }
`;

const SupportAction = styled.div`
  align-items: center;
  color: ${(props) => props.theme.text.color.black};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.text.size.sectionTitleM};
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-transform: uppercase;
  padding-right: 3rem;
`;

export default StepHeader;
