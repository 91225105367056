import * as React from 'react';
import { useSelector } from 'react-redux';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { useLocationState } from 'src/app/utils/hooks';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';
import locations from 'src/app/utils/locations';
import styled from 'styled-components';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN, FUNDING_SOURCE_ORIGIN } from 'src/app/utils/consts';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { withPreservedStateNavigator } from 'src/app/hoc';
import clockIcon from 'src/app/images/general/clock.svg';
import { FundingSourceType, FundingSourceOrigins } from 'src/app/utils/types';

type Props = {
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const CompleteAddingBankAccountsPageContainer = ({
  navigate,
  navigateWithPreservedState,
}: Props) => {
  const site = useSiteContext();
  const fundingSources: FundingSourceType[] = useSelector(fundingSourcesStore.selectors.all);
  const [newFundingSourceId] = useLocationState('newFundingSourceId', '');
  const [origin] = useLocationState('origin', '');
  const fundingSource = fundingSources.find((fs) => fs.id === newFundingSourceId);

  const goNextFlow = () => {
    if (navigateWithPreservedState) {
      // Passed state is used in batch payments
      const fs = newFundingSourceId
        ? { fsId: newFundingSourceId }
        : { origin: FUNDING_SOURCE_ORIGIN.PLAID };
      let state: Record<string, any> = {
        newFundingSource: fs,
      };

      if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.GUEST_ONBOARDING) {
        state = {
          selectedFundingSourceId: newFundingSourceId,
        };
      }

      navigateWithPreservedState(state);
    } else {
      navigate(locations.Bills.index.url());
    }
  };

  let title = 'onboarding.fundingSources.bank.complete.title';
  let subTitle = 'onboarding.fundingSources.bank.complete.subtitle';
  let buttonCTA = 'onboarding.fundingSources.bank.complete.next';
  let illustration = site.theme.pages?.CompleteAddingBankAccountsPage?.successImage;
  let textAlign = 'center';
  const subTitleValues: { email?: JSX.Element } = {
    email: (
      <SupportLink href={`mailto:${site.config.support.email}`} target="_blank">
        {site.config.support.email}
      </SupportLink>
    ),
  };

  if (fundingSource?.origin === FundingSourceOrigins.manual && !fundingSource?.isVerified) {
    title = 'onboarding.fundingSources.bank.manually.connectSuccessTitle';
    subTitle = 'onboarding.fundingSources.bank.manually.connectSuccessSubTitle';
    buttonCTA = 'onboarding.fundingSources.bank.manually.buttonCTA';
    illustration = clockIcon;
    textAlign = site.theme?.pages?.CompleteAddingBankAccountsPage?.textAlign || 'center';
  }

  return (
    <QBOSuccessLayout
      illustration={illustration}
      textAlign={textAlign}
      title={title}
      text={subTitle}
      textValues={subTitleValues}
      buttonLabel={buttonCTA}
      buttonAction={goNextFlow}
      hideHeader
    />
  );
};

export default withPreservedStateNavigator()(CompleteAddingBankAccountsPageContainer);

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;
