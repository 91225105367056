import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, SmartIcon, Text } from '@melio/billpay-design-system';
import { getBill } from 'src/app/redux/payBillWizard/selectors';
import { iframeEventsApi } from 'src/app/version-2/api/iframeEvents.api';
import {
  analytics,
  NavigateType,
  QBOHeader,
  useGoAddPage,
  withNavigator,
  withPayBillData,
} from 'src/app/version-2/externals';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { applicationSelectors } from 'src/app/version-2/modules/application/application.slice';
import {
  fundingSourcesActions,
  fundingSourcesSelectors,
} from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import { paymentsSelectors } from 'src/app/version-2/modules/payments/payments.slice';
import { EVENT_PAGE } from 'src/app/version-2/pages/funding-source/model/consts';
import {
  EventNameEnum,
  FundingTypesEnum,
} from 'src/app/version-2/pages/funding-source/model/enums';
import { fundingSourceActions } from 'src/app/version-2/pages/funding-source/modules/fundingSource.slice';
import {
  getLpExperimentVariantComponent,
  shouldSendEvent,
} from 'src/app/version-2/utils/experimental.util';
import {
  getFeatureFlagStatus,
  getFundingSourceVariant,
} from 'src/app/version-2/utils/featureFlags.utils';
import {
  BoldString,
  CreditCards,
  Footnote,
  IconContainer,
  LoaderWrapper,
  PageContainer,
  PaymentInfoText,
  ProgressBar,
  ProgressContainer,
  Subtitle,
} from './FundingSourcePage.styles';
import { FundingSourceCards } from '../FundingSourceCards';
import { FundingSourceTable } from '../FundingSourceTable';

import { compose } from 'recompose';

const VARIANT_COMPONENTS = {
  card: FundingSourceCards,
  table: FundingSourceTable,
};

interface FundingSourcePageProps {
  navigate: NavigateType;
  getUrlAfterFundingSourceStepForRecoveryFlow: () => Promise<string>;
}

const FundingSourcePageComponent = (props: FundingSourcePageProps) => {
  const { navigate, getUrlAfterFundingSourceStepForRecoveryFlow } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [variantComponent, setVariantComponent] = useState<string | undefined>(undefined);
  const { formatMessage, formatNumber } = useIntl();
  const bill = useSelector(getBill);
  const faqPanel = useSelector(applicationSelectors.selectFaqPanel);
  const payments = useSelector(paymentsSelectors.selectPayments);
  const isFundingSourcesFetched = useSelector(fundingSourcesSelectors.selectFundingSourcesFetched);
  const fundingSources = useSelector(fundingSourcesSelectors.selectValidFundingSources);

  const { goAddSelectedFundingSource } = useGoAddPage({
    navigate,
    getUrlAfterFundingSourceStepForRecoveryFlow,
  });
  const fundingSourceOptions = formatMessage({ id: 'fundingSource.sources.options' }).split(',');
  const [paymentMethods, setPaymentMethods] = useState<string[]>([]);

  useEffect(() => {
    setPaymentMethods(fundingSourceOptions);

    if (!fundingSources.length && !isFundingSourcesFetched) {
      dispatch(fundingSourcesActions.fetchFundingSources());
    }
  }, []);

  useEffect(() => {
    setIsLoading(!fundingSources.length && !isFundingSourcesFetched);
  }, [fundingSources, isFundingSourcesFetched]);

  useEffect(() => {
    if (!bill || !bill?.id || !isFundingSourcesFetched) return;

    const isFundingSourceExperiment = getFeatureFlagStatus(
      FeatureFlagsEnum.FUNDING_SOURCE_EXPERIMENT
    );
    const fundingSourceVariant = getFundingSourceVariant(
      isFundingSourceExperiment,
      fundingSources,
      !!payments.length
    );

    setVariantComponent(
      getLpExperimentVariantComponent(fundingSourceVariant, fundingSources, !!payments.length)
    );

    if (shouldSendEvent(isFundingSourceExperiment, fundingSources, !!payments.length)) {
      dispatch(
        fundingSourceActions.sendEvents({
          fundingSources,
          isFundingSourceExperiment,
          bill,
          fundingSourceOptions,
          featureFlags: {
            fundingSourceVariant,
          },
        })
      );
    }
  }, [bill?.id, isFundingSourcesFetched]);

  const trackSelection = (method: string) => {
    let props: Record<string, any> = {
      billIds: [bill],
    };

    if (method === FundingTypesEnum.bank) {
      props = {
        ...props,
        bankAccountType: null,
      };
    }

    analytics.page(EVENT_PAGE, EventNameEnum[method], props);
  };

  const onMethodSelect = (method: string) => {
    const fundingSource = FundingTypesEnum[method];

    trackSelection(method);
    goAddSelectedFundingSource(fundingSource);
  };

  const PageLoader = () => (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );

  const MethodsPage = variantComponent && VARIANT_COMPONENTS[variantComponent];

  return isLoading ? (
    <PageLoader />
  ) : (
    <PageContainer
      title={formatMessage(
        { id: 'fundingSource.title' },
        {
          amount: (
            <PaymentInfoText textStyle="ds.body6" $color="gray[200]">
              ${bill.totalAmount}
            </PaymentInfoText>
          ),
          payee: (
            <PaymentInfoText textStyle="ds.body6" $color="gray[200]">
              {bill.vendor?.companyName}
            </PaymentInfoText>
          ),
        }
      )}
      subtitle={
        <Subtitle $variant={variantComponent}>
          {formatMessage({ id: 'fundingSource.subtitle' })}
        </Subtitle>
      }
      footnote={
        <Footnote $variant={variantComponent}>
          <Text textStyle="ds.body3">
            <IconContainer>
              <SmartIcon type="lock" size="2.4rem" />
            </IconContainer>
            {formatMessage({ id: 'fundingSource.footnote' })}
          </Text>
          <CreditCards>
            <SmartIcon type="visa" size="3.8rem" />
            <SmartIcon type="mastercard" size="3.8rem" />
            <SmartIcon type="amex" size="3.8rem" />
            <SmartIcon type="diners" size="3.8rem" />
            <SmartIcon type="discover" size="3.8rem" />
          </CreditCards>
        </Footnote>
      }
      header={
        <QBOHeader
          {...props}
          headerLabel="fundingSource.header.payment"
          headerLabelValues={{
            amount: (
              <BoldString>
                {formatNumber(bill.totalAmount, { style: 'currency', currency: 'USD' })}
              </BoldString>
            ),
            payee: <BoldString>{bill.vendor?.companyName}</BoldString>,
          }}
          onPrev={undefined}
          goExit={iframeEventsApi.melioClose}
        />
      }
      progressBar={
        <ProgressContainer>
          <ProgressBar $width={100 / 5} />
        </ProgressContainer>
      }
      $faqPanel={faqPanel.isOpen}
    >
      {!bill.id && <PageLoader />}
      {bill.id && MethodsPage && <MethodsPage methods={paymentMethods} onSelect={onMethodSelect} />}
    </PageContainer>
  );
};

const FundingSourcePage = compose(withNavigator(), withPayBillData())(FundingSourcePageComponent);

export { FundingSourcePage };
