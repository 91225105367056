import { prefix } from '../../consts';

export const spacing = {
  [prefix]: {
    xs: '0.2rem',
    sm: '0.4rem',
    md: '0.8rem',
    lg: '1.6rem',
    xl: '2rem',
    '2xl': '4rem',
    '3xl': '6rem',
  },
};
