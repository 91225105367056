import { deliveryMethodsApi } from 'src/app/version-2/api/deliveryMethods.api';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { DeliveryMethodRequestData } from 'src/app/version-2/api/requests/DeliveryMethodRequestData';
import { analytics, pushNotification } from 'src/app/version-2/externals';
import { SERVER_ERROR_INTERNAL_CODE } from 'src/app/version-2/model/constants';
import { NotificationVariantEnum } from 'src/app/version-2/model/enums';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { navigateOnAchDeliveryMethodSavedHandler } from './navigateOnAchDeliveryMethodSaved.saga';
import { achDeliveryMethodDetailsActions } from '../achDeliveryMethodDetails.slice';

import { History } from 'history';
import { call, put, select } from 'redux-saga/effects';

interface UpdateAchDeliveryMethodHandlerParams {
  deliveryMethod: DeliveryMethodRequestData;
  vendorId: string;
  deliveryMethodId: string;
  history: History;
}

export function* updateAchDeliveryMethodHandler({
  deliveryMethod,
  vendorId,
  deliveryMethodId,
  history,
}: UpdateAchDeliveryMethodHandlerParams) {
  yield put(achDeliveryMethodDetailsActions.setIsSubmitting(true));
  yield put(achDeliveryMethodDetailsActions.resetError());

  const orgId: string = yield select(userSliceSelectors.selectOrgId);

  analytics.trackAction('edit-delivery-method', {
    type: deliveryMethod.deliveryType,
  });

  try {
    const { deliveryMethod: updatedDeliveryMethod } = yield call(deliveryMethodsApi.update, {
      orgId,
      vendorId,
      deliveryMethodId,
      data: deliveryMethod,
    });

    yield put(
      achDeliveryMethodDetailsActions.deliveryMethodUpdated({
        deliveryMethod: updatedDeliveryMethod,
        updatedDeliveryMethodId: deliveryMethodId,
      })
    );

    analytics.trackAction('edit-delivery-method-success', {
      type: deliveryMethod.deliveryType,
      vendorId,
    });

    yield call(navigateOnAchDeliveryMethodSavedHandler, {
      savedDeliveryMethod: updatedDeliveryMethod,
      vendorId,
      history,
    });

    yield put(achDeliveryMethodDetailsActions.setIsSubmitting(false));
  } catch (error: any) {
    const errorCode = error?.code || error?.error?.code || SERVER_ERROR_INTERNAL_CODE;

    analytics.trackAction('edit-delivery-method-failure', {
      type: deliveryMethod.deliveryType,
      vendorId,
    });

    loggingApi.error(
      'editAchDeliveryMethodSaga.updateAndSetAchDeliveryMethodHandler(): edit failed',
      { errorCode, vendorId, orgId }
    );

    pushNotification({
      type: NotificationVariantEnum.ERROR,
      msg: `server.${errorCode}`,
    });

    yield put(
      achDeliveryMethodDetailsActions.setError({
        error: { code: errorCode },
      })
    );
    yield put(achDeliveryMethodDetailsActions.setIsSubmitting(false));
  }
}
