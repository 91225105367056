import { fetchRequest, postRequest } from 'src/app/version-2/externals';
import {
  CreateBatchBulkPaymentRequest,
  FetchBulkPaymentIntentsRequest,
} from 'src/app/version-2/pages/batch-bulk/api/requests';
import {
  CreateBatchBulkPaymentResponse,
  FetchBulkPaymentIntentsResponse,
} from 'src/app/version-2/pages/batch-bulk/api/responses';

export const batchBulkApi = {
  fetchBatches: ({ orgId, ...params }: FetchBulkPaymentIntentsRequest) =>
    fetchRequest<FetchBulkPaymentIntentsResponse>(`/orgs/${orgId}/qbo/bills/batch/flatten`, params),
  fetchBulks: ({ orgId, ...params }: FetchBulkPaymentIntentsRequest) =>
    fetchRequest<FetchBulkPaymentIntentsResponse>(`/orgs/${orgId}/qbo/bills/batch/grouped`, params),
  createBatchBulkPayment: ({ orgId, ...params }: CreateBatchBulkPaymentRequest) =>
    postRequest<CreateBatchBulkPaymentResponse>(`/orgs/${orgId}/qbo/payments/batch/create`, params),
};
