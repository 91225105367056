const ADD_DELIVERY_METHOD_PATH = 'vendors.addDeliveryMethodByLink.virtualCardOptInPage';

const optinDictionary = {
  header: {
    title: (isVirtualPayment: boolean) =>
      `${ADD_DELIVERY_METHOD_PATH}.${isVirtualPayment ? 'unilateral.' : ''}title`,
    body: (isMobile: boolean) =>
      `${ADD_DELIVERY_METHOD_PATH}.${isMobile ? 'moblieSubTitle' : 'subTitle'}`,
  },
  paymentDetails: {
    header: `${ADD_DELIVERY_METHOD_PATH}.paymentDetails.title`,
    amount: `${ADD_DELIVERY_METHOD_PATH}.paymentDetails.amount`,
    deliverTo: `${ADD_DELIVERY_METHOD_PATH}.paymentDetails.toBeDeliveredTo`,
    changeEmail: `${ADD_DELIVERY_METHOD_PATH}.paymentDetails.changeEmail`,
    contact: `${ADD_DELIVERY_METHOD_PATH}.paymentDetails.contact`,
    ETA: `${ADD_DELIVERY_METHOD_PATH}.paymentDetails.paymentETA`,
    ETAValue: `${ADD_DELIVERY_METHOD_PATH}.paymentDetails.paymentETAAchValue`,
  },
  info: `${ADD_DELIVERY_METHOD_PATH}.info`,
  actionButtons: {
    primary: (isVirtualPayment: boolean) =>
      `${ADD_DELIVERY_METHOD_PATH}.${isVirtualPayment ? 'unilateral.' : ''}ctaLabel`,
    secondary: (isVirtualPayment: boolean) =>
      `${ADD_DELIVERY_METHOD_PATH}.${
        isVirtualPayment ? 'unilateral.changeDeliveryMethodCta' : 'secondCta'
      }`,
  },
  pos: {
    title: `${ADD_DELIVERY_METHOD_PATH}.posExplanation.title`,
    tag: `${ADD_DELIVERY_METHOD_PATH}.posExplanation.tag`,
    subtitle: `${ADD_DELIVERY_METHOD_PATH}.posExplanation.subtitle`,
  },
};

export default optinDictionary;
