import React, { MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-js/types/elements/elements';
import { CardChangeEvent } from '@basis-theory/basis-theory-js/types/elements/events';
import {
  CardDetailsContainer,
  CardNumber,
  CardValidations,
} from 'src/app/version-2/pages/add-card-account/components/CardDetails/CardDetails.styles';
import { CardExpirationDateField } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/CardExpirationDateField/CardExpirationDateField';
import { CardNumberField } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/CardNumberField/CardNumberField';
import { CardVerificationCodeField } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/CardVerificationCodeField/CardVerificationCodeField';
import { BASIS_THEORY_ELEMENT_COMMON_STYLE } from 'src/app/version-2/pages/add-card-account/model/consts/basisTheoryElemetCommonStyle.consts';
import { addCardAccountActions } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';

export interface CardDetailsProps {
  cardNumberFieldId: string;
  cardExpirationDateFieldId: string;
  cardVerificationCodeFieldId: string;

  onReady(id: string): void;

  attemptedToLink: boolean;
  refs: {
    cardNumberRef: MutableRefObject<CardNumberElement | undefined>;
    cardExpirationRef: MutableRefObject<CardExpirationDateElement | undefined>;
    cardVerificationRef: MutableRefObject<CardVerificationCodeElement | undefined>;
  };
}

export const CardDetails = ({
  cardNumberFieldId,
  cardExpirationDateFieldId,
  cardVerificationCodeFieldId,
  onReady,
  refs,
}: CardDetailsProps) => {
  const dispatch = useDispatch();

  const onChange = (fieldId: string, event: CardChangeEvent) => {
    dispatch(
      addCardAccountActions.setCardBrand({
        event,
      })
    );

    dispatch(
      addCardAccountActions.setErrorValidations({
        fieldId,
        event,
      })
    );
  };

  const onBlur = (fieldId: string) => {
    dispatch(
      addCardAccountActions.onBlur({
        fieldId,
      })
    );
  };

  const onFocus = (fieldId: string) => {
    dispatch(
      addCardAccountActions.onFocus({
        fieldId,
      })
    );
  };

  return (
    <CardDetailsContainer>
      <CardNumber>
        <CardNumberField
          id={cardNumberFieldId}
          onReady={onReady}
          elementStyle={BASIS_THEORY_ELEMENT_COMMON_STYLE}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          cardNumberRef={refs.cardNumberRef}
        />
      </CardNumber>
      <CardValidations>
        <CardExpirationDateField
          id={cardExpirationDateFieldId}
          onReady={onReady}
          elementStyle={BASIS_THEORY_ELEMENT_COMMON_STYLE}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          cardExpirationRef={refs.cardExpirationRef}
        />
        <CardVerificationCodeField
          id={cardVerificationCodeFieldId}
          onReady={onReady}
          elementStyle={BASIS_THEORY_ELEMENT_COMMON_STYLE}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          cardVerificationRef={refs.cardVerificationRef}
        />
      </CardValidations>
    </CardDetailsContainer>
  );
};
