import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { addCardFundingSourceApi } from 'src/app/version-2/pages/add-card-account/api/fundingSource/addCardFundingSource.api';
import { CreateCardAccountResponse } from 'src/app/version-2/pages/add-card-account/api/fundingSource/responses/CreateCardAccountResponse';
import { ValidateCardResponse } from 'src/app/version-2/pages/add-card-account/api/fundingSource/responses/ValidateCardResponse';
import {
  ADD_CARD_ACCOUNT_EVENT_PAGE,
  IFRAME_ERROR_RESPONSE,
  IFRAME_RESPONSE_SUCCESS,
  LINK_CREDIT_CARD,
  PAYMENT_METHOD_CREDIT_CARD,
} from 'src/app/version-2/pages/add-card-account/model/consts/AddCardAccountAnalytics.consts';
import { addCardAccountActions } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';
import { addCardAccountFundingSourceActions } from 'src/app/version-2/pages/add-card-account/modules/fundingSource/addCardAccountFundingSource.slice';

import { call, put, takeEvery } from 'redux-saga/effects';

function* validateCardHandler(
  action: ReturnType<typeof addCardAccountFundingSourceActions.validateCardAccount>
) {
  const {
    orgId,
    cardToken,
    cardBin,
    cardNumberIdentifiers,
    digits,
    expiration,
    location,
    history,
  } = action.payload;

  try {
    const { message, code }: ValidateCardResponse = yield call<
      typeof addCardFundingSourceApi.validateCard
    >(addCardFundingSourceApi.validateCard, {
      orgId,
      cardToken,
      cardBin,
      cardNumberIdentifiers,
    });

    yield put(
      addCardAccountFundingSourceActions.setValidateCardStatus({
        message,
        code,
      })
    );

    analytics.track(ADD_CARD_ACCOUNT_EVENT_PAGE, IFRAME_RESPONSE_SUCCESS);
    analytics.track(PAYMENT_METHOD_CREDIT_CARD, LINK_CREDIT_CARD, {
      vaulting: ADD_CARD_ACCOUNT_EVENT_PAGE,
    });

    yield put(addCardAccountActions.isRequesting(false));

    loggingApi.info('addCardAccountFundingSourceSaga.validateCardHandler(): passed', { orgId });

    history.push(locations.Onboarding.fundingSources.card.cardholder.url({ orgId }), {
      preservedState: {
        ...history.location.state?.preservedState,
      },
      orgId,
      token: cardToken,
      digits,
      expiration,
      exitUrl: location.state.exitUrl,
      redirectUrl: location.state.redirectUrl,
      cardBin,
    });
  } catch (error) {
    analytics.track(ADD_CARD_ACCOUNT_EVENT_PAGE, IFRAME_ERROR_RESPONSE);
    loggingApi.error('addCardAccountFundingSourceSaga.validateCardHandler(): error', {
      error,
      errorType: IFRAME_ERROR_RESPONSE,
      errorInfo: `${ADD_CARD_ACCOUNT_EVENT_PAGE} ${IFRAME_ERROR_RESPONSE}`,
    });

    yield put(
      addCardAccountFundingSourceActions.setValidateCardStatus({
        error: {
          message: (error as ValidateCardResponse).message,
          code: (error as ValidateCardResponse).code,
        },
      })
    );

    yield put(addCardAccountActions.isRequesting(false));

    loggingApi.error('addCardAccountFundingSourceSaga.validateCardHandler(): failed', {
      orgId,
      error,
    });
  }
}

function* createCardAccountHandler(
  action: ReturnType<typeof addCardAccountFundingSourceActions.createCardAccount>
) {
  const { orgId } = action.payload;

  try {
    yield put(addCardAccountActions.isRequesting(true));

    const { accounts, code }: CreateCardAccountResponse = yield call<
      typeof addCardFundingSourceApi.createCardAccount
    >(
      addCardFundingSourceApi.createCardAccount,
      orgId,
      {
        ...action.payload,
      },
      { catchCall: true }
    );

    yield put(
      addCardAccountFundingSourceActions.setCreateCardAccountStatus({
        accounts,
        code,
      })
    );

    loggingApi.info('addCardAccountFundingSourceSaga.createCardAccountHandler(): passed', {
      orgId,
    });

    yield put(addCardAccountActions.isRequesting(false));
  } catch (error) {
    yield put(
      addCardAccountFundingSourceActions.setCreateCardAccountStatus({
        error: {
          message: (error as ValidateCardResponse).message,
          code: (error as ValidateCardResponse).code,
        },
      })
    );

    yield put(addCardAccountActions.isRequesting(false));

    loggingApi.error('addCardAccountFundingSourceSaga.createCardAccountHandler(): failed', {
      error,
      orgId,
    });
  }
}

export function* watchAddCardAccountFundingSource() {
  yield takeEvery(addCardAccountFundingSourceActions.validateCardAccount, validateCardHandler);
  yield takeEvery(addCardAccountFundingSourceActions.createCardAccount, createCardAccountHandler);
}
