import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getProfile, getOrgId } from 'src/app/redux/user/selectors';
import analytics from 'src/app/services/analytics';

const eventPage = 'two-factor-auth';

type Response = {
  trackCodeEntered: (success: boolean) => void;
  trackSupportClicked: VoidFunction;
  trackResendClicked: VoidFunction;
};

const useAuthCodeVerificationAnalytics = (): Response => {
  const profile = useSelector(getProfile);
  const orgId = useSelector(getOrgId);

  const analyticsProps = { orgId, userId: profile.id, userEmail: profile.email };

  const countAttemptsEnterCode = useRef(0);
  const countAttemptsResendCode = useRef(0);

  useEffect(() => {
    analytics.page(eventPage, 'insert-code', analyticsProps);
  }, []);

  const trackCodeEntered = (success: boolean) => {
    countAttemptsEnterCode.current += 1;

    analytics.trackAction('enter-code', {
      ...analyticsProps,
      count: countAttemptsEnterCode.current,
      success,
    });
  };

  const trackSupportClicked = () => {
    analytics.trackAction('contact-support', {
      ...analyticsProps,
      count: countAttemptsEnterCode.current,
    });
  };

  const trackResendClicked = () => {
    countAttemptsResendCode.current += 1;

    analytics.trackAction('resend-code', {
      ...analyticsProps,
      count: countAttemptsResendCode.current,
    });
  };

  return {
    trackCodeEntered,
    trackSupportClicked,
    trackResendClicked,
  };
};

export { useAuthCodeVerificationAnalytics };
