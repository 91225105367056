import React, { PureComponent, SyntheticEvent } from 'react';
import { compose } from 'recompose';
import { FormSubtitle } from 'src/app/ui/form/FormElements';
import styled from 'styled-components';
import { QBOBillDetailsForm } from 'src/app/pages/bill/components/QBOBillDetailsForm';
import { BillFrequencyEnum } from 'src/app/version-2/model/enums';
import { MIFormattedText } from '../../../utils/formatting';
import { OnInputChange, Option } from '../../../components/common/MISingleSelect';
import MIButton from '../../../components/common/MIButton';
import { withBreak } from '../../../hoc';
import { devices } from '../../../theme/AppDevices';
import { CONSTS, BILL_PAGE_TYPE } from '../../../utils/consts';
import type { BillType, FieldType } from '../../../utils/types';
import fileAPI from '../../../services/api/files';

type Props = {
  isUploading: boolean;
  vendors: Option[];
  onVendorsInputChange?: OnInputChange;
  intuitAccountsOptions: Option[];
  vendorAccountIdentifier?: string;
  onFieldChange: (field: FieldType) => void;
  onChange: (field: FieldType) => void;
  bill: BillType;
  validationErrors: Record<string, any>;
  onAddBill: (shouldReturnToBillsDashboard: boolean) => void;
  isProcessing: boolean;
  manually?: boolean;
  isRecurring: boolean;
  occurrences: string;
  frequency: BillFrequencyEnum;
  onDeleteAttachment: () => void;
  fileName: string;
  isUploadError: boolean;
  handleUploadStart: () => void;
  handleUploadError: () => void;
  handleUploadSuccess: (file: File) => void;
  handleRetry: () => void;
  orgId: string;
  site: any;
  isFirstWave?: boolean;
};

type State = {
  isSaveAndCloseClicked: boolean;
};

class NewBillPage extends PureComponent<Props, State> {
  static defaultProps = {
    manually: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isSaveAndCloseClicked: false,
    };
  }

  handleSubmit = (event: Event) => {
    this.setState({ isSaveAndCloseClicked: false });
    event.preventDefault();
    this.props.onAddBill(false);
  };

  handleSubmitAndClose = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ isSaveAndCloseClicked: true });
    event.preventDefault();
    this.props.onAddBill(true);
  };

  handleSelectFile = (file: File) => {
    this.props.handleUploadStart();
    fileAPI
      .uploadFile(this.props.orgId, file)
      .then((object) => {
        this.props.handleUploadSuccess(object.file);
      })
      .catch(this.props.handleUploadError);
  };

  render() {
    const {
      isUploading,
      vendors,
      onFieldChange,
      bill,
      manually,
      isRecurring,
      validationErrors,
      isProcessing,
      intuitAccountsOptions,
      onChange,
      occurrences,
      frequency,
      handleRetry,
      onDeleteAttachment,
      fileName,
      isUploadError,
      isFirstWave,
    } = this.props;
    const { isSaveAndCloseClicked } = this.state;
    const defaultVendorId = bill.vendorId ? bill.vendorId.toString() : null;
    const isAutofocus = !!defaultVendorId;
    const dueDate = bill.dueDate ? new Date(bill.dueDate) : null;

    return (
      <BillDetailsContainer manually={manually} data-testid="new-bill-form">
        <FormWrapper>
          <FormSubtitle>
            <FormSubtitleWrapper>
              <MIFormattedText label="bills.view.title" />
            </FormSubtitleWrapper>
          </FormSubtitle>
          <QBOBillDetailsForm
            isDisabled={isUploading}
            isAutofocus={isAutofocus}
            vendors={vendors}
            onFieldChange={onFieldChange}
            onChange={onChange}
            occurrences={occurrences}
            frequency={frequency}
            isRecurringBill={isRecurring}
            vendorId={defaultVendorId}
            totalAmount={bill.totalAmount}
            invoiceNumber={bill.invoiceNumber}
            dueDate={dueDate}
            note={bill.note}
            validationErrors={validationErrors}
            intuitAccountsOptions={intuitAccountsOptions}
            billPageType={BILL_PAGE_TYPE.CREATE}
            intuitAccountId={bill.intuitAccountId}
            onChangeInvoice={this.handleSelectFile}
            onDeleteAttachment={onDeleteAttachment}
            fileName={fileName}
            isUploadError={isUploadError}
            isUploading={isUploading}
            handleSelectFile={this.handleSelectFile}
            handleRetry={handleRetry}
            manually={Boolean(manually)}
            isFirstWave={isFirstWave}
          />
        </FormWrapper>
        <ButtonsContainer>
          <MIButton
            onClick={this.handleSubmit}
            label="bills.new.saveAndAdd"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            fullWidth
            disabled={isUploading}
            isProcessing={isProcessing && !isSaveAndCloseClicked}
          />
          {!isRecurring && (
            <SaveAndCloseButton
              onClick={this.handleSubmitAndClose as any}
              label="bills.new.saveAndClose"
              variant={CONSTS.BUTTON_VARIANT.SECONDARY}
              fullWidth
              isProcessing={isProcessing && isSaveAndCloseClicked}
            />
          )}
        </ButtonsContainer>
      </BillDetailsContainer>
    );
  }
}

export default compose(withBreak())(NewBillPage);

const BillDetailsContainer = styled.form<{ manually?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding: 0 6rem;
  max-width: ${(props) => (props.manually ? '45rem' : '40rem')};

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2rem 0 10rem;
  }

  ${(props) => props.theme?.pages?.NewBillPage?.BillDetailsContainer};
`;

const ButtonsContainer = styled.div`
  text-align: center;
  margin: 1rem 0 1.8rem;

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: ${(props) => props.theme.colors.white.opaque};
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 -0.4rem 1.5rem 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }
  ${(props) => props.theme?.pages?.NewBillPage?.NewBillButtonsContainer};
`;

const FormWrapper = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    position: relative;
  }
`;

const SaveAndCloseButton = styled(MIButton)`
  margin-top: 1rem;
`;

const FormSubtitleWrapper = styled.div`
  ${(props) => props.theme?.pages?.NewBillPage?.FormSubtitleWrapper};
`;
