import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';

export const TimelineContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 8rem;
  position: relative;
`;

export const ProgressBar = styled.div<{ value: number; isFull: boolean }>`
  height: 0.2rem;
  width: calc(51% * ${({ value }) => value});
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.ds.green[200]} ${({ value }) => (value > 1 ? 50 : 0)}%,
    ${({ theme }) => theme.colors.ds.white} calc(${({ value }) => (value > 1 ? 45 : 75)}% * ${({ value }) => value})
  );
  transition: all 200ms;
  z-index: 1;
`;

export const Bar = styled.div`
  position: absolute;
  height: 0.2rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.ds.gray[500]};
  z-index: 0;
`;

export const StepContainer = styled(Flex)`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Step = styled.div<{ isChecked?: boolean; index: number }>`
  position: relative;
  height: ${({ isChecked }) => (isChecked ? 1.66 : 0.8)}rem;
  width: ${({ isChecked }) => (isChecked ? 1.66 : 0.8)}rem;
  border-radius: 50%;
  background-color: ${({ theme, isChecked }) => (isChecked ? theme.colors.ds.green[200] : theme.colors.ds.gray[500])};
  border: ${({ theme, isChecked }) => (isChecked ? `0.16rem solid ${theme.colors.ds.white}` : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ index }) => index + 2};
`;

export const Label = styled(Flex)`
  position: absolute;
  top: 2.5rem;
  text-align: center;
  width: 20rem;
  gap: 0.2rem;
  color: ${({ theme }) => theme.colors.ds.gray[100]};

  p {
    margin: 0;
  }
`;
