import { useOutsideClick, UseOutsideClickProps } from '@chakra-ui/react';

type UseOutsideClickWithIgnoredProps = {
  ignoredClassName: string;
} & UseOutsideClickProps;

const useOutsideClickWithIgnored = ({
  ref,
  handler,
  ignoredClassName,
}: UseOutsideClickWithIgnoredProps) => {
  const hasIgnoredClass = (element, ignoredClass) => {
    const el = element.correspondingElement;

    return (el || element).classList.contains(ignoredClass);
  };

  const isInIgnoredElement = (element, ignoredClass) => {
    do {
      if (hasIgnoredClass(element, ignoredClass)) {
        return true;
      }
    } while ((element = element.parentElement)); // eslint-disable-line

    return false;
  };

  const handleOutsideClick = (e: Event) => {
    if (isInIgnoredElement(e.target, ignoredClassName)) {
      return;
    }

    if (handler) {
      handler(e);
    }
  };

  useOutsideClick({
    ref,
    handler: handleOutsideClick,
  });
};

export { useOutsideClick, useOutsideClickWithIgnored };
