import React, { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SmartIcon, Tooltip } from '@melio/billpay-design-system';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { CellContext } from '@tanstack/react-table';
import {
  analytics,
  MIFormattedCurrency,
  usePartialPaymentsEnabled,
} from 'src/app/version-2/externals';
import { DeliveryEnum } from 'src/app/version-2/model/enums';
import {
  BATCH_BULK_EVENT_PAGE,
  FOCUS_PAYMENT_AMOUNT,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  AmmountContainer,
  Amount,
  CurrencyFieldForCell,
  EditIconWrapper,
  Fee,
} from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';
import { canPartiallyPayBill } from 'src/app/version-2/utils/bills.utils';
import { debounce } from 'src/app/version-2/utils/debounce';

export const AmountRenderer: FC<CellContext<BatchBulkItem, number>> = (props) => {
  const { cell, row } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const amountValue = cell.getValue() || 0;
  const item: BatchBulkItem = row.original;
  const {
    type: itemType,
    id,
    paymentIntentId,
    billId,
    feeIncludeAdditional,
    freeCheck,
    qboSyncVersion,
    deductionDate,
    vendorId,
    totalBillIds,
    totalPartialBillIds,
    selectedDeliveryMethod,
  } = item;

  const { isPartialPaymentsUIEnabled } = usePartialPaymentsEnabled();
  const [isEditMode, setIsEditMode] = useState<boolean>();
  const isEditVisible = useMemo(
    () =>
      itemType === BatchBulkItemEnum.BATCH_BULK_PAYMENT_INTENT &&
      billId &&
      selectedDeliveryMethod?.deliveryType !== DeliveryEnum.INTERNATIONAL &&
      canPartiallyPayBill(isPartialPaymentsUIEnabled, qboSyncVersion),
    [id, itemType, billId, isPartialPaymentsUIEnabled, qboSyncVersion, selectedDeliveryMethod]
  );
  const maxAmount = useSelector(batchBulkSelectors.selectBillMaxAmount(billId || id));
  const hasNoSelectedDM =
    !selectedDeliveryMethod && itemType === BatchBulkItemEnum.BATCH_BULK_PAYMENT_INTENT;

  const onChange = useCallback(
    debounce((event) => {
      let newValue = event.floatValue;

      if (newValue > maxAmount) {
        newValue = maxAmount;
      }

      dispatch(
        batchBulkActions.updateAmount({
          amount: newValue,
          paymentIntentId: paymentIntentId || id,
          billId: billId || id,
        })
      );
    }),
    [maxAmount, paymentIntentId, billId, id]
  );

  const onInputBlur = useCallback(() => {
    if (amountValue <= 0) {
      dispatch(
        batchBulkActions.updateAmount({
          amount: maxAmount,
          paymentIntentId: paymentIntentId || id,
          billId: billId || id,
        })
      );
    }

    // getting latest fee options
    dispatch(
      batchBulkActions.updateDeductionDate({
        paymentIntentId: paymentIntentId || id,
        date: deductionDate,
        amount: amountValue,
      })
    );

    setIsEditMode(false);
  }, [amountValue, maxAmount, paymentIntentId, billId, id, setIsEditMode]);
  const onInputFocus = useCallback((event) => event.target.select(), []);
  const onClickEdit = useCallback(() => {
    setIsEditMode(true);
    analytics.track(BATCH_BULK_EVENT_PAGE, FOCUS_PAYMENT_AMOUNT, {
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
      amount: amountValue,
    });
  }, [setIsEditMode, totalBillIds, totalPartialBillIds, vendorId, amountValue]);

  return (
    <div data-testid="amount-cell">
      {!isEditMode && (
        <AmmountContainer $withIconMargin={!isEditVisible}>
          <div>
            <Amount
              $hasNoSelectedDM={hasNoSelectedDM}
              $isBill={itemType === BatchBulkItemEnum.BILL}
            >
              <MIFormattedCurrency value={amountValue} data-testid="batch-payment-amount-count" />
            </Amount>
            {(!!feeIncludeAdditional || !!item.fee) && !freeCheck && (
              <Fee $withIconMargin>
                <MIFormattedCurrency
                  value={feeIncludeAdditional || item.fee}
                  data-testid="bill-fee-amount"
                />
              </Fee>
            )}
            {!!freeCheck && <Fee>Free</Fee>}
          </div>

          {isEditVisible && (
            <Tooltip label={formatMessage({ id: 'batchBulkPage.renderers.amount.tooltip' })}>
              <EditIconWrapper onClick={onClickEdit} className="disappearing-icon-wrapper">
                <SmartIcon type="edit" />
              </EditIconWrapper>
            </Tooltip>
          )}
        </AmmountContainer>
      )}
      {isEditMode && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        <CurrencyFieldForCell
          autoFocus
          onFocus={onInputFocus}
          size="ds.sm"
          showIcon={false}
          value={amountValue}
          onChange={onChange}
          onBlur={onInputBlur}
        />
      )}
    </div>
  );
};
