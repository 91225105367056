import styled from 'styled-components';

export const MainPaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme?.components?.PaymentActivity?.MainPaymentActivityContainer}
`;

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2};
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
  color: ${({ theme }) => theme.colors.ds.gray[200]};
`;
