import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import MILink from 'src/app/components/common/MILink';
import styled from 'styled-components';

export const VerifyAccountLink = styled(MIInlineLink)`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
`;

export const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.colors.primary.opaque};
  text-decoration: none;
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.normal};
  border: none;
  font-size: ${(props) => props.theme.text.size.linkHint};
`;
