import React from 'react';
import { RecordOf } from 'immutable';
import { MultiLineSelectBox } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { DeliveryMethodType } from 'src/app/utils/types';

type Props = {
  onClick: () => void;
  vendorName: string;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

export const ACHDeliveryMethodOption = ({ onClick, deliveryMethod, vendorName }: Props) => (
  <MultiLineSelectBox onClick={onClick}>
    <MultiLineSelectBox.TopContentWrapper>
      <MultiLineSelectBox.Icon name="icon-bank-icon" />
      <MultiLineSelectBox.HeadingWrapper>
        <MultiLineSelectBox.Title>
          <MIFormattedText label="bills.pay.virtualCardRecovery.ach.title" />
        </MultiLineSelectBox.Title>
        <MultiLineSelectBox.Description>
          <MIFormattedText label="bills.pay.virtualCardRecovery.ach.description" />
        </MultiLineSelectBox.Description>
      </MultiLineSelectBox.HeadingWrapper>
    </MultiLineSelectBox.TopContentWrapper>

    <MultiLineSelectBox.Separator />

    <MultiLineSelectBox.Info>
      {deliveryMethod.id ? (
        deliveryMethod.getDeliveryInfo(vendorName)
      ) : (
        <MIFormattedText label="bills.pay.virtualCardRecovery.ach.info" />
      )}
    </MultiLineSelectBox.Info>
  </MultiLineSelectBox>
);
