import React, { useState } from 'react';
import JustPayLayoutPage from 'src/app/pages/just-pay/JustPayLayoutPage';
import { JustPayLayoutPageRelativeSteps } from 'src/app/pages/just-pay/justPayTypes';
import { getJustPayHeaderLabelValues } from 'src/app/pages/just-pay/justPayJSXUtils';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import QboFundingSourcesListLayout from 'src/app/components/common/SelectMethods/containers/QboFundingSourcesListLayout';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/app/utils/locations';
import justPayLocations from 'src/app/pages/just-pay/justPayLocations';
import {
  CONSTS,
  DELIVERY_METHOD_ORIGIN,
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  CARD_TYPES,
} from 'src/app/utils/consts';
import { AreaLoader } from '@melio/billpay-design-system';
import includes from 'lodash/includes';
import {
  getFilteredFundingSources,
  getRedirectUrlByDeliveryMethodStatus,
} from 'src/app/pages/just-pay/justPayUtils';
import VerifyMicroDeposits from 'src/app/components/micro-deposits/VerifyMicroDeposits';
import { useFundingSourceMicroDepositState } from 'src/app/pages/settings/hooks/useFundingSourceMicroDeposits';
import useGetVendorPaymentPreferences from 'src/app/modules/vendors/hooks/useGetVendorPaymentPreferences';
import { CreditCardBenefitsModal } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitsModal';
import { useBenefits } from 'src/app/pages/bill/pay/hooks/useBenefits';
import analytics from 'src/app/services/analytics';
import { useDebitFee } from 'src/app/pages/bill/pay/hooks/useDebitFee';
import { useAmexVerification } from 'src/app/pages/bill/pay/hooks/useAmexVerification';
import { VendorType } from 'src/app/utils/types';
import { Footer } from 'src/app/pages/bill/pay/PayBillFundingSourcePageContainer/Footer';
import styled from 'styled-components';
import { WizardFooterContainer } from 'src/app/components/layout/QBOWizardElements';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  onNext: (path: string) => () => void;
  onPrev: () => void;
  onExit: () => void;
};

export const JustPaySelectFundingSource = ({ onNext, onPrev, onExit }: Props) => {
  const [verifyingId, setVerifyingId] = useState<number | null>(null);
  const debitFee = useDebitFee();

  const {
    shouldShowBenefitsModal,
    onBenefitsClicked,
    onCloseBenefitsModal,
    benefitsRelevantCreditCard,
  } = useBenefits();

  const { shouldDisplayAmexVerification, openAmexModal, AmexModal, amexLoading } =
    useAmexVerification();

  const {
    payment: {
      orgId,
      amount,
      vendor: { id: vendorId, companyName: vendorCompanyName },
      deliveryMethodId,
      deliveryMethodType,
      suggestedFundingSources,
      trackingBillId,
    },
    paymentStoreActions,
    userFundingSources,
    permissions,
    selectedVendor,
  } = useJustPayStore();
  const selectedFundingSource = suggestedFundingSources?.selectedFundingSource;

  const onVerifyFinished = () => {
    analytics.trackAction('verify-finish', { trackingBillId });
    setVerifyingId(null);
  };

  const microDepositEventPage = 'just-pay-verify-manual';
  const microDepositProps: any = {
    key: verifyingId,
    fundingSourceId: verifyingId,
    onVerifyFinished,
  };
  const [fundingSourceMicroDepositState, fundingSourceMicroDepositActions]: any =
    useFundingSourceMicroDepositState(microDepositEventPage, microDepositProps);

  /*
    TODO: @denis-melio
    should be tested with 'vendorPaymentPreferences'
  */
  const { vendorPaymentPreferences, isCheckingVendorEnableCCPayments } =
    useGetVendorPaymentPreferences(vendorId.toString());

  /*
    TODO: @denis-melio
    should be tested with no user account permissions
  */
  const isVerify: boolean = permissions.deliveryMethod.verify();

  const [historyPush] = useHistoryWithOrgId();

  const shouldSkipDeliveryMethodPage = (deliveryType) =>
    !includes(
      [CONSTS.DELIVERY_TYPE.ACH, CONSTS.DELIVERY_TYPE.CHECK, CONSTS.DELIVERY_TYPE.CARD],
      deliveryType
    );

  const getUrlAfterFundingSource = () => {
    const nextStepURL = justPayLocations.create.selectDeliveryMethod;

    if (deliveryMethodId) {
      return shouldSkipDeliveryMethodPage(deliveryMethodType)
        ? justPayLocations.operations.selectDeductionDate
        : nextStepURL;
    }

    return nextStepURL;
  };

  const onAddSelectedFundingSource = (selectedFundingSourceType) => {
    const analyticProperty =
      selectedFundingSourceType === CARD_TYPES.CREDIT ? 'add-credit-card' : 'add-debit-card';

    const exitUrl = justPayLocations.create.selectFundingSource;
    const redirectUrl = getUrlAfterFundingSource();
    const origin = ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY;

    if (selectedFundingSource?.plaidAccount) {
      paymentStoreActions.justPay.justPayWizard.update({
        plaidItemId: selectedFundingSource?.plaidAccount.plaidItemId,
      });
    }

    switch (selectedFundingSourceType) {
      case FundingSourceTypesEnum.ACH:
        analytics.trackAction('add-bank-account', { trackingBillId });
        historyPush({
          path: locations.Onboarding.fundingSources.bank.select.url(),
          state: {
            preservedState: { origin },
            redirectUrl,
            exitUrl,
          },
        });
        break;
      case CARD_TYPES.CREDIT:
      case CARD_TYPES.DEBIT:
        analytics.trackAction(analyticProperty, { trackingBillId });

        historyPush({
          path: locations.Onboarding.fundingSources.card.index.url(),
          state: {
            preservedState: { origin },
            redirectUrl,
            exitUrl,
          },
        });
        break;
      default:
        break;
    }
  };

  /*
  TODO: @denis-melio
  'any' type should be remove when will be removed 'immutable' library
 */
  const onSelectingFundingSource = (newSelectedFundingSource: any) => {
    analytics.trackAction('change-funding-source', {
      fundingSourceId: newSelectedFundingSource.id,
      trackingBillId,
    });

    if (selectedFundingSource?.id === newSelectedFundingSource?.id) {
      return handleOnNext();
    }

    paymentStoreActions.justPay.justPayWizard.update({
      suggestedFundingSources: {
        selectedFundingSourceId: newSelectedFundingSource.id,
        selectedFundingSource: newSelectedFundingSource,
      },
    });
  };

  const onVerifyClicked = (id: number) => {
    analytics.trackAction('verify-click', { trackingBillId });

    if (isVerify) {
      setVerifyingId(id);
    }
  };

  const handleOnNext = async ({
    skipDebitCheck = false,
    fundingSource,
  }: {
    skipDebitCheck?: boolean;
    fundingSource?: FundingSource;
  } = {}) => {
    const fundingSourceToSubmit = fundingSource || selectedFundingSource;

    analytics.trackAction('funding-source-continue', {
      fundingSourceId: fundingSourceToSubmit.id,
      trackingBillId,
    });

    if (!skipDebitCheck && debitFee.shouldDisplayModal(fundingSourceToSubmit)) {
      debitFee.openModal();

      return;
    }

    if (await shouldDisplayAmexVerification(fundingSourceToSubmit, vendorId)) {
      openAmexModal({
        vendorName: vendorCompanyName,
        vendorId,
        bill: null,
      });

      return;
    }

    paymentStoreActions.justPay.justPayWizard.update({
      fundingSourceId: fundingSourceToSubmit.id,
      fundingSourceType: fundingSourceToSubmit.fundingType,
    });

    if (!deliveryMethodId) {
      const url = getRedirectUrlByDeliveryMethodStatus({
        orgId,
        selectedVendor: selectedVendor as VendorType,
        deliveryMethodType,
      });

      historyPush({
        path: url,
        state: {
          origin: DELIVERY_METHOD_ORIGIN.JUST_PAY,
          redirectUrl: justPayLocations.operations.selectDeductionDate,
        },
      });

      return;
    }

    onNext(justPayLocations.operations.selectDeductionDate)();
  };

  if (isCheckingVendorEnableCCPayments) {
    return <AreaLoader />;
  }

  const onBenefitsButtonClicked = async () => {
    if (benefitsRelevantCreditCard) {
      if (await shouldDisplayAmexVerification(benefitsRelevantCreditCard, vendorId)) {
        openAmexModal({
          vendorName: vendorCompanyName,
          vendorId,
          bill: null,
        });

        return;
      }

      onSelectingFundingSource(benefitsRelevantCreditCard);
      handleOnNext({ fundingSource: benefitsRelevantCreditCard });
    } else {
      onAddSelectedFundingSource(CARD_TYPES.CREDIT);
    }
  };

  const onDebitFeeNext = () => handleOnNext({ skipDebitCheck: true });

  return (
    <StyledJustPayLayoutPage
      headerLabel="justPay.defaultHeader"
      headerLabelValues={getJustPayHeaderLabelValues(amount, vendorCompanyName)}
      title="justPay.fundingSource.title"
      subtitle="justPay.fundingSource.subtitle"
      onNext={handleOnNext}
      goExit={onExit}
      onPrev={onPrev}
      relativeStep={JustPayLayoutPageRelativeSteps.JustPaySelectFundingSource}
      isQboFooter
      isLoading={amexLoading}
      footer={<Footer shouldShowSecurityDetails />}
    >
      <QboFundingSourcesListLayout
        value={selectedFundingSource}
        fundingSources={getFilteredFundingSources(userFundingSources)}
        onChange={onSelectingFundingSource}
        onAddMethod={onAddSelectedFundingSource}
        onVerifyClicked={onVerifyClicked}
        isVendorEnableCCPayments={vendorPaymentPreferences}
        onBenefitsClicked={onBenefitsClicked}
        debitFee={debitFee.fee}
        eventSource="just-pay"
        billIds={[]}
      />

      {verifyingId && (
        <VerifyMicroDeposits
          {...fundingSourceMicroDepositState}
          {...fundingSourceMicroDepositActions}
          key={verifyingId}
          fundingSourceId={verifyingId}
          onVerifyFinished={onVerifyFinished}
          eventPage={microDepositEventPage}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      )}
      {shouldShowBenefitsModal && (
        <CreditCardBenefitsModal
          onButtonClick={onBenefitsButtonClicked}
          onCloseClick={onCloseBenefitsModal}
          isLoading={amexLoading}
          analyticsProperties={{ bill: null, vendorId }}
        />
      )}
      <debitFee.Modal onNext={onDebitFeeNext} />
      <AmexModal onNext={handleOnNext} />
    </StyledJustPayLayoutPage>
  );
};

const StyledJustPayLayoutPage = styled(JustPayLayoutPage)`
  ${WizardFooterContainer} {
    margin-top: 2rem;
  }
`;
