import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  margin-bottom: 4rem;
`;
