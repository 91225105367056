import { put, all, call, select, getContext, CallEffect } from 'redux-saga/effects';
import { PAY_BILL_EVENT_PAGE } from 'src/app/pages/bill/pay/consts';
import { getOrgId, getCompanyInfo } from 'src/app/redux/user/selectors';
import analytics from 'src/app/services/analytics';
import { getPayBillFlowUUID } from 'src/app/services/analytics/trackPayBillFlow';
import deliveryAPI from 'src/app/services/api/delivery';
import { isDeliveryOptionSupported } from 'src/app/utils/delivery-methods';
import { loggingApi } from 'src/app/version-2/api/loggers';
import {
  fetchInitialDeliveryOptionsSuccessAction,
  fetchInitialDeliveryOptionsFailureAction,
} from '../actions';
import { getPayment, getBill, getSelectedFundingSource } from '../selectors';
import type { FetchDeliveryOptionsResponse } from '../types';

export function* fetchInitialDeliveryOptionsHandler() {
  const [site, orgId, companyInfo, payment, bill, fundingSource] = yield all([
    getContext('site'),
    select(getOrgId),
    select(getCompanyInfo),
    select(getPayment),
    select(getBill),
    select(getSelectedFundingSource),
  ]);
  const payBillFlowUUID = getPayBillFlowUUID();

  const {
    id: paymentId,
    fundingSourceId,
    deliveryMethodId,
    amount,
    deliveryMethod,
    scheduledDate,
  } = payment;
  const { dueDate } = bill;

  analytics.track(PAY_BILL_EVENT_PAGE, 'get-initial-delivery-date', {
    paymentId,
    fundingSourceId,
    deliveryMethodId,
  });

  try {
    const effects: CallEffect[] = [];

    effects.push(
      call(
        deliveryAPI.getDeliveryTime,
        orgId,
        new Date(),
        deliveryMethodId,
        fundingSourceId,
        amount,
        paymentId,
        payBillFlowUUID
      )
    );

    if (scheduledDate) {
      effects.push(
        call(
          deliveryAPI.getDeliveryTime,
          orgId,
          new Date(scheduledDate),
          deliveryMethodId,
          fundingSourceId,
          amount,
          paymentId,
          payBillFlowUUID
        )
      );
    } else if (dueDate) {
      effects.push(
        call(
          deliveryAPI.getLatestProcessingDate,
          orgId,
          new Date(dueDate),
          deliveryMethodId,
          fundingSourceId,
          amount,
          paymentId,
          payBillFlowUUID
        )
      );
    }

    const responses: FetchDeliveryOptionsResponse[] = yield all(effects);
    const earliestDeliveryOptionsData = responses[0];
    const suggestedDeliveryOptionsData = responses[responses.length - 1];

    const result = {
      minScheduledDate: earliestDeliveryOptionsData.suggestedScheduledDate,
      suggestedScheduledDate: suggestedDeliveryOptionsData.suggestedScheduledDate,
      deliveryDate: suggestedDeliveryOptionsData.deliveryDate,
      maxDeliveryDate: suggestedDeliveryOptionsData.maxDeliveryDate,
      deliveryOptions: suggestedDeliveryOptionsData.deliveryOptions.filter((deliveryOption) =>
        isDeliveryOptionSupported({
          deliveryOption,
          deliveryMethod,
          fundingSource,
          companyInfo,
          isFastACHEnabledForSite: site.hasFastAch,
        })
      ),
      actualDeliveryDays: suggestedDeliveryOptionsData.actualDeliveryDays,
    };

    analytics.track(PAY_BILL_EVENT_PAGE, 'get-initial-delivery-date-success', {
      paymentId,
      fundingSourceId,
      deliveryMethodId,
    });

    yield put(fetchInitialDeliveryOptionsSuccessAction(result));
  } catch (e: any) {
    analytics.track(PAY_BILL_EVENT_PAGE, 'get-initial-delivery-date-failure', {
      paymentId,
      fundingSourceId,
      deliveryMethodId,
      reason: e.message || '',
    });

    yield put(fetchInitialDeliveryOptionsFailureAction(e.code));

    loggingApi.error(
      'fetchInitialDeliveryOprionsSaga.fetchInitialDeliveryOptionsHandler(): failed',
      e
    );
  }
}
