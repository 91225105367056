import type { RecordOf } from 'immutable';
import { DeliveryOption } from 'src/app/version-2/model/dtos/deliveryOption';
import { FundingSource } from 'src/app/version-2/model/dtos';
import type { BillType, PaymentType, DeliveryMethodType } from '../../utils/types';
import { DeliveryOptionType } from '../../utils/types';

export type PaymentFeeInfo = Readonly<{
  baseAmount: number;
  finalAmount: number;
  feeStructure: {
    type: 'fixed' | 'percent';
    value: number;
    cap: number;
    feeType?: string;
  };
  promotion: {
    reduction: number;
    expiration: string;
    promotionName: string;
  };
  fastFee: {
    type: string;
    totalAmount: number;
  };
  fastFeeApi?: {
    type: string;
    totalAmount: number;
  };
}>;
export const enum BANK_TYPE {
  UNVERIFIED = 'unverified',
  PLAID = 'plaid',
}
export const enum FEE_FLOWS {
  ADD = 'add',
  CHANGE = 'change',
}

interface FeeFundingSourceFlow {
  bankType?: BANK_TYPE;
  flow?: FEE_FLOWS;
}

export type PayBillWizardState = Readonly<{
  flow?: string;
  feeFundingSourceFlow?: FeeFundingSourceFlow;
  deletedFundingSources: number[];
  isLoading: boolean;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  isRecurring: boolean;
  isCancelAndRetryPaymentFlow: boolean;
  recurringBill: Record<string, any>;
  firstBillIdWithRecurringBill: string | null;
  recurringBillId: string | null;
  errorCode: string | null;
  syncPaymentErrorCode: string | null;
  redirectUrl: string | null;
  exitUrl: string | null;
  fee: PaymentFeeInfo | undefined;
  invoiceFilesByBillId: Record<string, LoadedFile | null>;
  notifications: Record<string, boolean>;
  deliveryOptionsData: DeliveryOptionsDataType;
  deliveryOptions: DeliveryOptionType[];
}>;

export type LoadedFile = {
  id: string;
  name: string;
};

type DeliveryOptionsDataType = {
  loading: boolean;
  errorCode?: string;
  data?: FetchInitialDeliveryOptionsResponse;
};

export type BeginRegularPayBillFlowType = {
  type: string;
  id: string;
  paymentId: string;
  redirectUrl?: string | null;
  exitUrl?: string | null;
  initialPaymentAmount?: number | null;
  keepPaymentFundingSource?: boolean | null;
};

export type SetRegularPayBillFlowInitialDataType = {
  type: string;
  bill: BillType;
  initialPaymentAmount: number;
  fundingSource?: FundingSource;
  deliveryMethod?: DeliveryMethodType;
  exitUrl?: string | null;
  redirectUrl?: string | null;
};

export type SetRegularPayBillFlowInitialDataFinishType = {
  type: string;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  exitUrl?: string | null;
  redirectUrl?: string | null;
};

export type BeginRecurringPayBillFlowType = {
  type: string;
  bill: Record<string, any>;
  recurringBill: Record<string, any>;
  exitUrl?: string | null;
};

export type SelectNewDeliveryMethodType = {
  type: string;
  deliveryMethod: DeliveryMethodType;
};

export type BillIdType = {
  type: string;
  billId: string;
};

export type CreateRecurringBillType = {
  type: string;
  resolve: () => void;
  reject: (error: any) => void;
};

export type CreatePaymentType = {
  type: string;
  resolve: () => void;
  reject: (error: any) => void;
};

export type UpdatePaymentType = {
  type: string;
  resolve: () => void;
  reject: (error: any) => void;
};

export type RetryFailedPaymentType = {
  type: string;
  resolve: () => void;
  reject: (error: any) => void;
};

export type SelectFundingSourceType = {
  type: string;
  id: number;
};

export type LoadDefaultMemoParams = {
  billRecord: RecordOf<BillType>;
  paymentRecord: RecordOf<PaymentType>;
};

export type CancelAndRetryPaymentType = {
  type: string;
  resolve: (paymentObject: PaymentType) => void;
  reject: (error: Error) => void;
};

export type FetchDeliveryOptionsType = {
  type: string;
  payload: { scheduledDate: Date };
};

export type FetchDeliveryOptionsResponse = {
  deliveryOptions: DeliveryOption[];
  suggestedScheduledDate: Date;
  actualDeliveryDays: number;
  deliveryDate: Date;
  maxDeliveryDate: Date;
};

export type FetchInitialDeliveryOptionsResponse = {
  minScheduledDate: Date;
} & FetchDeliveryOptionsResponse;
