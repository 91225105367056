import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { SmartIcon } from '@melio/billpay-design-system';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import { NotificationVariantEnum } from 'src/app/version-2/model/enums';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ReactComponent as IdeaIcon } from 'src/app/images/qbo/international/idea.svg';

type NotificationType = NOTIFICATION_CARD_TYPES | NotificationVariantEnum;

type LabelValues = {
  label: string;
  values?: Record<string, any>;
};

export type QBONotificationCardProps = {
  type: NotificationType;
  title?: LabelValues;
  showIcon?: boolean;
  subtitle?: LabelValues;
  onClose?: () => void;
  className?: string;
  renderText?: ReactNode;
};

const QBONotificationCard = ({
  type,
  title,
  showIcon = true,
  subtitle,
  onClose,
  className,
  renderText,
}: QBONotificationCardProps) => {
  const iconClass = getIcon(type);
  const isDiscoverCard = type === NOTIFICATION_CARD_TYPES.DISCOVER;
  const isWarningIcon = iconClass === 'icon-warning-icon';

  return (
    <Container
      className={className}
      type={type}
      showCloseButton={!!onClose}
      hasSubtitle={!!subtitle}
    >
      {!isDiscoverCard &&
        showIcon &&
        (isWarningIcon ? (
          <SmartIcon type="yellow-info" size="2rem" />
        ) : (
          <Icon className={iconClass} type={type} />
        ))}
      {showIcon && isDiscoverCard && <DiscoverCardIcon />}
      <TextContainer className="notification-card-text-container">
        {title?.label && (
          <Title className="notification-title" hasSubtitle={!!subtitle} type={type}>
            <MIFormattedText label={title?.label} values={title?.values} />
          </Title>
        )}
        {subtitle?.label && (
          <Subtitle className="notification-subtitle">
            <MIFormattedText label={subtitle?.label} values={subtitle.values} />
          </Subtitle>
        )}
        {renderText && <div>{renderText}</div>}
      </TextContainer>
      {!!onClose && <CloseButton className="icon-close-icon" onClick={onClose} />}
    </Container>
  );
};

export default QBONotificationCard;

const getIcon = (type: NotificationType) => {
  switch (type) {
    case NOTIFICATION_CARD_TYPES.INFO:
      return 'icon-intuit-info-icon';
    case NOTIFICATION_CARD_TYPES.SUCCESS:
      return 'icon-full-checkmark-icon';
    case NOTIFICATION_CARD_TYPES.MAIN:
      return 'icon-info-hollow-icon';
    case NOTIFICATION_CARD_TYPES.WARNING:
    default:
      return 'icon-warning-icon';
  }
};

const getColor = (type: NotificationType, theme: any) => {
  switch (type) {
    case NOTIFICATION_CARD_TYPES.ERROR:
      return theme.colors.failure.opaque;
    case NOTIFICATION_CARD_TYPES.WARNING:
      return theme.colors.warning.opaque;
    case NOTIFICATION_CARD_TYPES.INFO:
      return theme.colors.ds.blue[200];
    case NOTIFICATION_CARD_TYPES.MAIN:
      return theme.text.color.main;
    case NOTIFICATION_CARD_TYPES.DISCOVER:
      return theme.colors.ds.blue['300'];
    case NOTIFICATION_CARD_TYPES.SUCCESS:
    default:
      return theme.colors.success.opaque;
  }
};

type ContainerType = {
  type: NotificationType;
  showIcon?: boolean;
  showCloseButton: boolean;
  hasSubtitle: boolean;
};

const Container = styled.div<ContainerType>`
  display: flex;
  padding: ${({ hasSubtitle }) => (hasSubtitle ? '2rem 2rem 2rem 2rem' : '2.8rem 2rem')};
  border-radius: 0.8rem;
  border-style: solid;
  border-width: 0.1rem;
  box-sizing: border-box;
  border-color: ${({ type, theme }) => getColor(type, theme)};
  padding: 1.6rem;
  gap: 1rem;

  ${(props) => props.theme?.components?.QBONotificationCard?.Container}
`;

const TextContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const Icon = styled.i<{ type: NotificationType }>`
  color: ${({ type, theme }) => getColor(type, theme)};
  font-size: 2.4rem;
  margin-top: -0.3rem;
  margin-left: -0.3rem;
`;

const DiscoverCardIcon = styled(IdeaIcon)<{ type?: NotificationType }>`
  margin-right: 0.7rem;
  margin-left: -0.3rem;
  width: 3rem;
  height: 2.1rem;
`;

const Title = styled.div<{ hasSubtitle?: boolean; type?: NotificationType }>`
  color: ${(props) =>
    props.type === NOTIFICATION_CARD_TYPES.ERROR
      ? getColor(props.type, props.theme)
      : props.theme.text.color.main};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  ${({ theme }) => theme.text.fontType.medium};
`;

const Subtitle = styled.div`
  ${({ theme }) => theme.text.fontType.medium};
  color: ${(props) => props.theme.colors.ds.gray[100]};
`;

const CloseButton = styled.i`
  color: ${({ theme }) => theme.text.color.darkGrey};
  font-size: 2.4rem;
  cursor: pointer;
  margin-left: auto;
  align-self: start;
`;
