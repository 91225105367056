const locations = {
  base: '/quickbooks/entrypoints',
  loaders: {
    pay: '/quickbooks/entrypoints/loaders/pay/:originId',
    dashboard: '/quickbooks/entrypoints/loaders/dashboard',
  },
  errors: {
    contractors: '/quickbooks/entrypoints/errors/contractors',
    directPayments: '/quickbooks/entrypoints/errors/direct-payments',
    orgBlockedByRisk: '/quickbooks/entrypoints/errors/org-blocked-by-risk',
    billNotFound: '/quickbooks/entrypoints/errors/bill-not-found',
    billSyncError: '/quickbooks/entrypoints/errors/bill-sync-error',
    billAlreadyScheduled: '/quickbooks/entrypoints/errors/bill-already-scheduled',
    billZeroBalance: '/quickbooks/entrypoints/errors/bill-zero-balance',
    billAmountLimit: '/quickbooks/entrypoints/errors/bill-amount-limit',
    batchBillAmountLimit: '/quickbooks/entrypoints/errors/batch-bill-amount-limit',
  },
};

export default locations;
