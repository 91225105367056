import { QboFloatedButtons } from 'src/app/components/bill/QboFloatedButtons';

type Props = {
  onDone: () => any;
  onCancel: () => any;
  doneLabel: string;
  cancelLabel: string;
  isDisabled?: boolean;
  className?: string;
  isProcessing?: boolean;
  titleLabel?: string;
};

export const MIFloatedEditDoneButtons = ({
  className,
  onDone,
  onCancel,
  doneLabel,
  cancelLabel,
  isDisabled,
  isProcessing,
  titleLabel,
}: Props) => (
  <QboFloatedButtons
    className={className}
    onDone={onDone}
    onCancel={onCancel}
    doneLabel={doneLabel}
    cancelLabel={cancelLabel}
    isDisabled={isDisabled}
    isProcessing={isProcessing}
    titleLabel={titleLabel}
  />
);
