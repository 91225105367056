import * as React from 'react';
import { compose } from 'recompose';
import { OrderedSet, RecordOf } from 'immutable';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { VendorType } from 'src/app/utils/types';
import QBOVendorListItem from '../../../components/list/QBOVendorListItem';
import { AreaLoader } from '@melio/billpay-design-system';
import EmptyList from '../../../components/list/EmptyList';
import { withNavigator } from '../../../hoc';

type Props = {
  vendors: OrderedSet<RecordOf<VendorType>>;
  isListLoading: boolean;
};

class VendorsListPage extends React.Component<Props> {
  renderVendorsSubList = function (vendorsSubList) {
    return vendorsSubList.map(({ id, companyName }) => (
      <QBOVendorListItem key={id} id={id} name={companyName} />
    ));
  };

  renderVendors = () => {
    const { vendors } = this.props;

    if (vendors.size === 0) {
      return <EmptyList text="vendors.emptyState.vendorText" />;
    }

    const sortedContacts = vendors
      .sort((a, b) => a.companyName.localeCompare(b.companyName))
      .reduce((alphaSortObj, contactObj) => {
        const firstLetter = contactObj.companyName.substr(0, 1).toLowerCase();

        if (!alphaSortObj[firstLetter]) {
          alphaSortObj[firstLetter] = [];
        }

        alphaSortObj[firstLetter].push(contactObj);

        return alphaSortObj;
      }, {});

    return Object.keys(sortedContacts).map((alpha) => (
      <div key={alpha}>
        <AlphabeticalLetters>{alpha}</AlphabeticalLetters>
        {this.renderVendorsSubList(sortedContacts[alpha])}
      </div>
    ));
  };

  render() {
    const { isListLoading } = this.props;

    return (
      <VendorListContainer>
        {isListLoading ? <AreaLoader placement="list" /> : this.renderVendors()}
      </VendorListContainer>
    );
  }
}

export default compose(withNavigator())(VendorsListPage);

const AlphabeticalLetters = styled.div`
  color: rgba(107, 108, 114, 1);
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  line-height: 1.8rem;
  text-transform: uppercase;
  border-bottom: 0.2rem solid rgba(212, 215, 220, 1);
  padding: 1.3rem 1rem;
`;

const VendorListContainer = styled.div`
  position: relative;
  padding: 3.4rem 3.6rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0.2rem 1.6rem 0 1.6rem;
  }
`;
