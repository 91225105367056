/* eslint-disable max-lines */
import React, { useState, useMemo, useEffect } from 'react';
import { PaymentRecord } from 'src/app/pages/payment/records';
import sortBy from 'lodash/sortBy';
import first from 'lodash/first';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import api from 'src/app/services/api/delivery';
import { devices } from 'src/app/theme/AppDevices';
import analytics from 'src/app/services/analytics';
import { AccountRecord } from 'src/app/pages/settings/records';
import { PaymentDeliveryOptions } from 'src/app/components/common/PaymentDeliveryOptions';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import MIButton from 'src/app/components/common/MIButton';
import { FundingSource } from 'src/app/version-2/model/dtos';
import locations from 'src/app/utils/locations';
import { RecordOf } from 'immutable';
import { Box } from '@melio/billpay-design-system';

import {
  shouldAllowEditByDeliveryType,
  isEligibleToReceiveVirtualCard,
  getPurposeByType,
  transformPurposeOfPayment,
} from 'src/app/utils/payments';
import { QboFundingSourcesSelect } from 'src/app/components/common/SelectMethods/containers/QboFundingSourcesSelect';
import {
  getFundingSourceType,
  getSelectedOrDefaultFundingSource,
} from 'src/app/utils/funding-sources';
import {
  DeliveryMethodType,
  PaymentType,
  DeliveryOptionType,
  VendorType,
} from 'src/app/utils/types';
import {
  BUTTON_VARIANT,
  DELIVERY_TYPE,
  CONSTS,
  PURPOSE_OF_PAYMENT_STRUCTURE,
  DELIVERY_METHOD_ORIGIN,
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  CARD_TYPES,
} from 'src/app/utils/consts';
import { InlineDatePicker } from 'src/app/ui/form/InlineDatePicker';
import { MIFormattedText, MIFormattedDate, MIFieldOrEmpty } from 'src/app/utils/formatting';
import { getInternationalDeliveryMethod } from 'src/app/utils/international';
import {
  removeUnsupportedDeliveryOptionsByBill,
  shouldDisplayDeliverySpeedOptions,
  getDeliveryOption,
  getFastType,
  isFastDeliveryType,
} from 'src/app/utils/delivery-methods';
import { Bill, usePartialPayment } from 'src/app/pages/bill/pay/hooks/usePartialPayments';
import batchBillsStore from 'src/app/modules/batch-bills/batch-bills-store';
import { getPartialBillId } from 'src/app/utils/bills';
import PurposeOfPayment from 'src/app/components/common/PurposeOfPayment';
import { useBenefits } from 'src/app/pages/bill/pay/hooks/useBenefits';
import { CreditCardBenefitsModal } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitsModal';
import useApprovalDesicionStatus from 'src/app/pages/bill/hooks/useApprovalDesicionStatus';
import internationalAPI from 'src/app/services/api/deliveryMethods';
import { useApi } from 'src/app/hoc/useApi';
import { Country } from 'src/app/pages/vendor/delivery-methods/international/types';
import {
  collectInvoiceActions,
  collectInvoiceSelectors,
} from 'src/app/version-2/modules/collectInvoice/collectInvoice.slice';
import { ReturnType as DebitReturnType } from 'src/app/pages/bill/pay/hooks/useDebitFee';
import { SidePanelDeliveryMethodSection } from './SidePanelDeliveryMethodSection';
import { DeliveryMethodRecord } from 'src/app/pages/vendor/records-constants';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { EditablePaymentAmount } from '../qbo/EditablePaymentAmount';
import { getInvoiceFileBillId } from 'src/app/redux/payBillWizard/selectors';

type Props = {
  bill: Bill;
  isOpen: boolean;
  onClosePanel: () => void;
  fundingSources: any;
  deliveryOptions: DeliveryOptionType[];
  companyInfo: any;
  continueAction: (params: any, deliveryOptions: any) => void;
  orgId: number;
  redirectUrl: string;
  shouldDisplayAmexVerification: (
    fundingSource: FundingSource,
    vendorId: number
  ) => Promise<boolean>;
  onAddNewPaymentMethod: (paymentMethod: Record<string, any>) => void;
  continueButtonLabel: string;
  paymentAmount: number;
  MCCCodes: Record<number, string>;
  setPaymentAmount: (amount: number) => void;
  openAmexModal?: any;
  selectFundingSourceHandler: (fundingSource: any) => void;
  debitFee: DebitReturnType;
  onToggleVirtualCardInfoModal: () => void;
  isFirstWave?: boolean;
};
const eventPage = 'pay-bills-batch';
const fileErrorObj = { file: 'international.purposeOfPayment.invoiceUpload.required' };
const getInternationalPayeeBankCountry = (bill, deliveryMethods) => {
  const internationalDM = deliveryMethods.find(
    (method) => method.id === bill?.payments?.[0]?.deliveryMethod?.id
  );

  return internationalDM?.internationalAccount?.payeeBankCountry;
};

const SidePanelLayout = ({
  bill,
  isOpen,
  onClosePanel,
  fundingSources,
  deliveryOptions,
  companyInfo,
  continueAction: onSaveAndContinue,
  orgId,
  redirectUrl,
  onAddNewPaymentMethod,
  continueButtonLabel,
  paymentAmount,
  MCCCodes,
  setPaymentAmount,
  openAmexModal,
  shouldDisplayAmexVerification,
  selectFundingSourceHandler,
  debitFee,
  onToggleVirtualCardInfoModal,
  isFirstWave,
}: Props) => {
  const deliveryMethods = useMemo(
    () => get(bill, 'vendor.deliveryMethods', []).map((dm) => DeliveryMethodRecord(dm)),
    [bill]
  );
  const history = useHistory();
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<
    DeliveryMethodType | undefined
  >(undefined);
  const [selectedFundingSource, setSelectedFundingSource] = useState<FundingSource | undefined>(
    getSelectedOrDefaultFundingSource(
      bill?.payments?.[0]?.fundingSource?.id,
      fundingSources,
      selectedDeliveryMethod?.deliveryType
    )
  );
  const [deliveryPreference, setdeliveryPreference] = useState(
    bill?.payments[0]?.deliveryPreference
  );
  const [deductionDate, setDeductionDate] = useState<Date | null>(
    bill?.payments?.[0]?.scheduledDate
  );
  const [estimateDate, setEstimateDate] = useState(bill?.payments?.[0]?.deliveryEta);
  const [selectedDeliveryOptions, setSelectedDeliveryOptions] = useState<DeliveryOptionType[]>(
    deliveryOptions || []
  );
  const [memo, setMemo] = useState<string | undefined>(bill?.payments?.[0]?.note || bill?.note);
  const [purpose, setPurpose] = useState<string>(bill?.payments?.[0]?.purpose || '');
  const hasVirtualCardDeliveryMethod = deliveryMethods.some(
    (dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD
  );
  const internationalDeliveryMethod = getInternationalDeliveryMethod(deliveryMethods);
  const disableEditByDeliveryType = !shouldAllowEditByDeliveryType(internationalDeliveryMethod);

  const {
    openBalance,
    partialPaymentAmount,
    setPartialPaymentAmount,
    isValidAmount,
    setIsValidAmount,
  } = usePartialPayment(bill);
  const [purposeErrors, setPurposeErrors] = useState<Record<string, any>>({
    type: '',
    description: '',
    file: '',
  });
  const isInternational =
    selectedDeliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL;
  const [isShouldAmex, setIsShouldAmex] = useState<boolean>(false);
  const hasPartialAmount = partialPaymentAmount > 0 && partialPaymentAmount !== bill?.totalAmount;
  const approvalDesicionStatus = useApprovalDesicionStatus({ amount: bill?.payments?.[0].amount });

  const {
    shouldShowBenefitsModal,
    onBenefitsClicked,
    onCloseBenefitsModal,
    benefitsRelevantCreditCard,
  } = useBenefits();
  const site = useSiteContext();
  const [getSupportedCountries] = useApi(internationalAPI.getSupportedCountries);
  const [countriesList, setCountriesList] = useState<Country[]>([]);
  const vendorId = String(bill?.vendor?.id);
  const showInvoiceFileSelector = useSelector(
    collectInvoiceSelectors.selectShowInvoicetByVendorId(vendorId)
  );
  const invoiceFile = useSelector(getInvoiceFileBillId(bill?.id));

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFundingSource) {
      selectFundingSourceHandler(selectedFundingSource);
    }
  }, [selectedFundingSource]);

  useEffect(() => {
    setSelectedDeliveryMethod(
      deliveryMethods.find((method) => method.id === bill?.payments?.[0]?.deliveryMethod?.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryMethods]);
  useEffect(() => {
    clearPurposeErrors();

    if (isInternational) {
      setPurpose(
        transformPurposeOfPayment({
          type: getPurposeByType(bill?.payments?.[0]?.purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE),
          description: getPurposeByType(
            bill?.payments?.[0]?.purpose,
            PURPOSE_OF_PAYMENT_STRUCTURE.DESCRIPTION
          ),
        })
      );

      validatePurposeOfPaymentForm();
    }
  }, [bill?.id, isInternational, showInvoiceFileSelector]);

  useEffect(() => {
    setdeliveryPreference(bill?.payments[0]?.deliveryPreference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill?.payments[0]?.deliveryPreference]);

  useEffect(() => {
    setPurpose(
      transformPurposeOfPayment({
        type: getPurposeByType(bill?.payments?.[0]?.purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE),
        description: getPurposeByType(
          bill?.payments?.[0]?.purpose,
          PURPOSE_OF_PAYMENT_STRUCTURE.DESCRIPTION
        ),
      })
    );
  }, [bill?.id]);

  useEffect(() => {
    validatePurposeOfPaymentForm();
  }, [purpose, invoiceFile]);

  useEffect(() => {
    setSelectedFundingSource(
      getSelectedOrDefaultFundingSource(
        bill?.payments?.[0]?.fundingSource?.id,
        fundingSources,
        selectedDeliveryMethod?.deliveryType
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill?.payments?.[0]?.fundingSource?.id, fundingSources]);

  useEffect(() => {
    setMemo(bill?.payments?.[0]?.note || bill?.note);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill?.note, bill?.payments?.[0]?.note]);

  useEffect(() => {
    setDeductionDate(bill?.payments?.[0]?.scheduledDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill?.payments?.[0]?.scheduledDate]);

  useEffect(() => {
    if (showInvoiceFileSelector) {
      if (invoiceFile) {
        setPurposeErrors({
          ...purposeErrors,
          file: '',
        });
      } else {
        setPurposeErrors({ ...purposeErrors, ...fileErrorObj });
      }
    }
  }, [invoiceFile]);

  useEffect(() => {
    if (isInternational && bill?.vendor?.id && countriesList?.length === 0) fetchCountriesList();
  }, [isInternational, bill?.vendor?.id]);

  const fetchCountriesList = async () => {
    const { countries } = await getSupportedCountries(orgId, bill?.vendor?.id);

    setCountriesList(countries);
  };

  useEffect(() => {
    if (countriesList?.length > 0) {
      dispatch(
        collectInvoiceActions.fetchCollectInvoice({
          vendorId,
          countryCode: getInternationalPayeeBankCountry(bill, deliveryMethods) || '',
          countries: countriesList,
          billId: bill?.id,
        })
      );
    }
  }, [countriesList]);

  const estimatePayments = async () => {
    try {
      const deliveryMethodId = selectedDeliveryMethod?.id || bill?.payments?.[0]?.deliveryMethodId;
      const fundingSourceId = selectedFundingSource?.id || bill?.payments?.[0]?.fundingSourceId;
      const amount = bill?.totalAmount;
      const deliveryDay = await api.getDeliveryTime(
        orgId.toString(),
        deductionDate as Date,
        deliveryMethodId as unknown as number,
        fundingSourceId,
        amount
      );

      setSelectedDeliveryOptions(
        removeUnsupportedDeliveryOptionsByBill({
          site,
          deliveryOptions: deliveryDay.deliveryOptions,
          bill,
          payment: bill?.payments[0],
          fundingSource: null,
          companyInfo,
        })
      );

      const date =
        getDeliveryOption(deliveryPreference || '', deliveryDay.deliveryOptions)?.deliveryDate ||
        deliveryDay.deliveryDate;

      setEstimateDate(date);
    } catch (error) {
      analytics.trackAction('select-scheduled-date-error', error as Record<string, any>);
    }
  };

  useEffect(() => {
    if (deductionDate && selectedDeliveryMethod?.id && selectedFundingSource?.id) {
      estimatePayments();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductionDate, selectedDeliveryMethod?.id, selectedFundingSource?.id]);

  const getFallbackDeliveryMethodInsteadVirtualCard = (deliveryMethods: DeliveryMethodType[]) => {
    const allowedFallbackDeliveryTypes = [
      DELIVERY_TYPE.ACH,
      DELIVERY_TYPE.CHECK,
      DELIVERY_TYPE.VIRTUAL,
    ];

    const allowedFalbackDeliveryMethods = deliveryMethods.filter((dm) =>
      allowedFallbackDeliveryTypes.includes(dm.deliveryType)
    );
    const sortedDeliveryMethods = sortBy(allowedFalbackDeliveryMethods, (dm) => {
      if (dm.deliveryType === DELIVERY_TYPE.ACH) {
        return 0;
      }

      if (dm.deliveryType === DELIVERY_TYPE.CHECK) {
        return 1;
      }

      if (dm.deliveryType === DELIVERY_TYPE.VIRTUAL) {
        return 2;
      }

      return 3;
    });

    return first(sortedDeliveryMethods);
  };

  const updateFundingSource = (fundingSource) => {
    setIsShouldAmex(false);
    setSelectedFundingSource(
      getSelectedOrDefaultFundingSource(
        fundingSource.id,
        fundingSources,
        selectedDeliveryMethod?.deliveryType
      )
    );

    const isVirtualCardSupported = isEligibleToReceiveVirtualCard(fundingSource);

    if (
      !isVirtualCardSupported &&
      selectedDeliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD
    ) {
      const fallbackDeliveryMethod = getFallbackDeliveryMethodInsteadVirtualCard(deliveryMethods);

      setSelectedDeliveryMethod(fallbackDeliveryMethod);
    } else if (
      isVirtualCardSupported &&
      hasVirtualCardDeliveryMethod &&
      selectedDeliveryMethod?.deliveryType !== DELIVERY_TYPE.VIRTUAL_CARD
    ) {
      const virtualCardDM = deliveryMethods.find(
        (dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD
      );

      setSelectedDeliveryMethod(virtualCardDM);
    }
  };

  const validatePurposeOfPaymentForm = () => {
    const typeError = !getPurposeByType(purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE);
    const descriptionError =
      getPurposeByType(purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE) === 'other' &&
      !getPurposeByType(purpose, PURPOSE_OF_PAYMENT_STRUCTURE.DESCRIPTION);
    const fileError = showInvoiceFileSelector && !invoiceFile;
    const hasError = typeError || descriptionError || fileError;

    let purposeErrorsObj = {};

    if (typeError)
      purposeErrorsObj = {
        ...purposeErrorsObj,
        type: 'inputErrors.international.purpose.empty',
      };

    if (descriptionError) {
      purposeErrorsObj = {
        ...purposeErrorsObj,
        description: 'inputErrors.international.description.empty',
      };
    }

    if (fileError) {
      purposeErrorsObj = { ...purposeErrorsObj, ...fileErrorObj };
    }

    setPurposeErrors(purposeErrorsObj);

    if (hasError) return false;

    setPurposeErrors({
      type: '',
      description: '',
      file: '',
    });

    return true;
  };

  const clearPurposeErrors = () =>
    setPurposeErrors({
      description: '',
      type: '',
      file: '',
    });

  const handlePurposeOfPaymentChange = (type, { value }) => {
    clearPurposeErrors();

    setPurpose(
      transformPurposeOfPayment({
        type: getPurposeByType(purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE),
        description: getPurposeByType(purpose, PURPOSE_OF_PAYMENT_STRUCTURE.DESCRIPTION),
        [type]: value,
      })
    );
  };

  const [methodErrorsMap, setMethodErrorsMap] = useState({});

  useEffect(() => {
    const amexError = isShouldAmex
      ? {
          label: 'amexVerification.methodErrors.creditPaymentMethod.label',
          values: {
            value: (...chunks) => (
              <Box
                as="span"
                color="ds.blue.100"
                onClickCapture={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  openAmexModal({
                    vendorName: bill.vendor.companyName,
                    vendorId: bill.vendor.id,
                    bill,
                  });
                }}
              >
                {React.Children.toArray(chunks)}
              </Box>
            ),
          },
        }
      : undefined;

    setMethodErrorsMap({
      ...methodErrorsMap,
      creditPaymentMethod: amexError,
    });
  }, [isShouldAmex]);

  // We closed Amex MCC for batch for now
  useEffect(() => {
    const getShouldDisplayAmexVerification = async () => {
      const res = await shouldDisplayAmexVerification(
        selectedFundingSource as FundingSource,
        bill?.vendor?.id
      );

      setIsShouldAmex(res);
    };

    getShouldDisplayAmexVerification();
  }, [selectedFundingSource, bill?.vendor?.id, MCCCodes]);

  const savePayments = async ({ skipDebitCheck = false }: any = {}) => {
    if (isShouldAmex) {
      openAmexModal({
        vendorName: bill.vendor.companyName,
        vendorId: bill.vendor.id,
        bill,
      });

      return;
    }

    if (!skipDebitCheck && debitFee.shouldDisplayModal(selectedFundingSource as FundingSource)) {
      debitFee.openModal();

      return;
    }

    const isInternational =
      selectedDeliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL;

    if (isInternational) {
      const isValid = validatePurposeOfPaymentForm();

      if (!isValid) {
        return;
      }
    }

    if (selectedDeliveryMethod?.id && selectedFundingSource?.id && deductionDate) {
      const newPayment: RecordOf<PaymentType> = PaymentRecord({
        billId: bill.id,
        fundingSourceId: selectedFundingSource.id,
        deliveryMethodId: selectedDeliveryMethod.id,
        scheduledDate: deductionDate,
        deliveryEta: estimateDate,
        note: memo,
        purpose,
        deliveryPreference: deliveryPreference || null,
        vendor: {
          deliveryMethods,
        } as any,
      });

      analytics.trackAction('set-side-panel', {
        amount: partialPaymentAmount,
        isPartial: partialPaymentAmount !== bill.totalAmount,
        partialBillId: getPartialBillId(bill),
      });

      setPaymentAmount(partialPaymentAmount);

      onSaveAndContinue(newPayment, selectedDeliveryOptions);
    }
  };

  const onAddFsMethod = (fsType) => {
    selectedDeliveryMethod &&
      onAddNewPaymentMethod({
        billId: bill.id,
        selectedDeliveryMethodId: selectedDeliveryMethod.id,
        scheduledDate: deductionDate,
        newNote: memo,
        paymentType: 'select-funding-source',
        type: fsType,
      });

    if (fsType === FundingSourceTypesEnum.ACH) {
      history.push(locations.Onboarding.fundingSources.bank.select.url({ orgId }), {
        preservedState: {
          origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.GUEST_ONBOARDING,
        },
        redirectUrl,
        exitUrl: redirectUrl,
      });
    }

    if (fsType === CARD_TYPES.DEBIT || fsType === CARD_TYPES.CREDIT) {
      // debit and credit
      history.push(locations.Onboarding.fundingSources.card.index.url({ orgId }), {
        preservedState: {
          origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.GUEST_ONBOARDING,
        },
        redirectUrl,
        exitUrl: redirectUrl,
      });
    }
  };

  const onAddDmMethod = (dmType) => {
    selectedFundingSource &&
      onAddNewPaymentMethod({
        billId: bill.id,
        selectedFundingSourceId: selectedFundingSource.id,
        scheduledDate: deductionDate,
        newNote: memo,
        paymentType: 'select-delivery-method',
        type: dmType,
      });

    if (dmType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
      history.push(
        locations.Vendors.deliveryMethods['virtual-card'].create.url({
          id: bill.vendorId,
          orgId,
        }),
        {
          redirectUrl,
          exitUrl: redirectUrl,
        }
      );
    }

    if (dmType === CONSTS.DELIVERY_TYPE.ACH) {
      history.push(
        locations.Vendors.deliveryMethods.ach.create.url({
          id: bill.vendorId,
          orgId,
        }),
        {
          redirectUrl,
          exitUrl: redirectUrl,
        }
      );
    }

    if (dmType === CONSTS.DELIVERY_TYPE.CHECK) {
      history.push(
        locations.Vendors.deliveryMethods.check.create.url({
          id: bill.vendorId,
          orgId,
        }),
        {
          redirectUrl,
          exitUrl: redirectUrl,
          isFundingSourceVerified: selectedFundingSource?.isVerified,
          origin: DELIVERY_METHOD_ORIGIN.BATCH_VIRTUAL_PAY_BILLS,
        }
      );
    }

    if (dmType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
      history.push(
        locations.Vendors.deliveryMethods.virtual.create.url({
          id: bill.vendorId,
          orgId,
        }),
        {
          origin: DELIVERY_METHOD_ORIGIN.BATCH_VIRTUAL_PAY_BILLS,
          redirectUrl,
          exitUrl: redirectUrl,
        }
      );
    }

    if (dmType === CONSTS.DELIVERY_TYPE.INTERNATIONAL) {
      history.push(
        locations.Vendors.deliveryMethods.international.create.url({
          id: bill.vendorId,
          orgId,
        }),
        {
          origin: DELIVERY_METHOD_ORIGIN.BATCH,
          bill,
          redirectUrl,
          exitUrl: redirectUrl,
        }
      );
    }
  };

  const onChangeDatePicker = (newDate) => {
    analytics.track(eventPage, 'select-scheduled-date', {
      partialBillId: getPartialBillId(bill),
    });
    setDeductionDate(newDate.date);
  };

  const handleFastTypeChange = (selectedDM) => {
    if (selectedDM.deliveryType === selectedDeliveryMethod?.deliveryType) {
      return setdeliveryPreference(
        isFastDeliveryType(deliveryPreference) ? getFastType(selectedDM.deliveryType) : undefined
      );
    }

    return setdeliveryPreference(undefined);
  };
  const vendorDeliveryMethods: DeliveryMethodType[] = useSelector(
    batchBillsStore.selectors.deliveryMethodsByBillId(bill?.id as unknown as number)
  );
  const onChangeDM = (selectedDM: DeliveryMethodType) => {
    if (selectedDM?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL && !selectedDM.virtualAccount) {
      const vendorDeliveryMethod = vendorDeliveryMethods.find(
        (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL
      );

      selectedDM.virtualAccount = vendorDeliveryMethod?.virtualAccount;
    }

    handleFastTypeChange(selectedDM);
    setSelectedDeliveryMethod(selectedDM);
  };

  const handleDeliverySpeedChange = (scheduledDate, deliveryDate, _maxDeliveryDate, type) => {
    setEstimateDate(deliveryDate);
    setDeductionDate(scheduledDate);
    setdeliveryPreference(type);
  };

  const onDebitFeeNext = () => savePayments({ skipDebitCheck: true });

  const getDeliveryDateSection = () => {
    if (!selectedFundingSource?.id || !selectedDeliveryMethod?.id || !estimateDate) {
      return (
        <DeliveryDatePlaceholder>
          <CalIcon>
            <i className="icon-eta-cal" />
          </CalIcon>
          <MIFormattedText label="batchPayment.fast.initialLabel" />
        </DeliveryDatePlaceholder>
      );
    }

    const isVirtualDM = selectedDeliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;

    const shouldDisplayDeliverySpeed =
      shouldDisplayDeliverySpeedOptions(
        selectedFundingSource?.id,
        selectedDeliveryMethod?.id,
        selectedDeliveryOptions
      ) && !isVirtualDM;

    const approvalRequiredHint = approvalDesicionStatus?.pending && (
      <PaymentApprovalHint
        ml={shouldDisplayDeliverySpeed ? 3 : 5.2}
        mt={shouldDisplayDeliverySpeed ? 0 : -1}
      >
        <MIFormattedText
          label="payBillPaymentActivity.deliveryDate.approvalRequiredHint"
          values={{ date: <MIFormattedDate date={estimateDate} /> }}
        />
      </PaymentApprovalHint>
    );

    return shouldDisplayDeliverySpeed ? (
      <>
        <PaymentDeliveryOptions
          // TODO: pass dueDate if 'ON TIME' tag logic is nedded
          paymentAmount={paymentAmount}
          fundingSourceType={getFundingSourceType(selectedFundingSource)}
          deliveryOptions={selectedDeliveryOptions}
          deliveryPreference={deliveryPreference as string}
          onSelectDeliveryOption={handleDeliverySpeedChange}
        />
        {approvalRequiredHint}
      </>
    ) : (
      <>
        <DeliveryDatePlaceholder selectedDate={!isVirtualDM}>
          <CalIcon>
            <i className="icon-eta-cal" />
          </CalIcon>
          <MIFormattedText
            label={
              isVirtualDM
                ? 'batchPayment.sidePanel.estimateNA'
                : 'batchPayment.sidePanel.estimateDateBank'
            }
            values={{ date: <MIFormattedDate date={estimateDate} /> }}
          />
        </DeliveryDatePlaceholder>
        {approvalRequiredHint}
      </>
    );
  };

  const amount = partialPaymentAmount || paymentAmount;
  const hasPurposeOfPaymentError =
    isInternational && !!(purposeErrors.type || purposeErrors.description || purposeErrors.file);

  const onBenefitsButtonClicked = () => {
    if (benefitsRelevantCreditCard) {
      updateFundingSource(benefitsRelevantCreditCard);
      onCloseBenefitsModal();
    } else {
      onAddFsMethod(CARD_TYPES.CREDIT);
    }
  };

  return (
    <>
      <SidePanelPageContainerPosition className={isOpen ? 'open' : ''} />
      <SidePanelPageContainer className={isOpen ? 'open' : ''}>
        <Close onClick={onClosePanel} />
        <BaseContainer>
          <HighlightedHeader>
            <EditablePaymentAmount
              bill={bill}
              max={openBalance}
              paymentAmount={amount}
              setPaymentAmount={setPartialPaymentAmount}
              setValid={setIsValidAmount}
              disabled={disableEditByDeliveryType}
              onFocus={() =>
                analytics.trackAction('batch-focus-payment-amount', {
                  amount,
                  source: 'batch-payments-side-panel',
                  partialBillId: getPartialBillId(bill),
                })
              }
            />
          </HighlightedHeader>
        </BaseContainer>
        <PaymentInfoContainer>
          <SubTitle>
            <MIFormattedText label="batchPayment.sidePanel.payTo" />
          </SubTitle>
          <BoxRow>
            <BoxColumn>
              <FieldName>
                <MIFormattedText label="batchPayment.sidePanel.vendor" />
              </FieldName>
              <FieldValue> {get(bill, 'vendor.companyName', '')}</FieldValue>
            </BoxColumn>
            <BoxColumn>
              <FieldName>
                <MIFormattedText label="batchPayment.sidePanel.invoice" />
              </FieldName>
              <FieldValue>
                <MIFieldOrEmpty
                  value={get(bill, 'invoiceNumber', '')}
                  label="batchPayment.sidePanel.noInvoiceNumber"
                />
              </FieldValue>
            </BoxColumn>
          </BoxRow>
          <BoxRow>
            <BoxColumn>
              <FieldName>
                <MIFormattedText label="batchPayment.sidePanel.dueDate" />
              </FieldName>
              <FieldValue>
                <MIFormattedDate date={get(bill, 'dueDate', new Date())} />
              </FieldValue>
            </BoxColumn>
          </BoxRow>
          <SectionSeparator />
          <SidePanelWrapper>
            <Divider size="1px" />
            <TextTitle padding>
              <MIFormattedText label="batchPayment.sidePanel.payFrom" />
            </TextTitle>
            <QboFundingSourcesSelect
              vendor={bill?.vendor as VendorType}
              value={AccountRecord(selectedFundingSource)}
              errorLabel={
                !selectedFundingSource ? 'batchPayment.sidePanel.fundingSourceError' : undefined
              }
              placeholderIcon="icon-debit-card-icon"
              fundingSourcePlaceholder="selectMethods.fundingSourcePlaceholder"
              fundingSources={fundingSources}
              onChange={updateFundingSource}
              onAddMethod={(fsType) => onAddFsMethod(fsType)}
              methodErrorsMap={methodErrorsMap}
              selectedDeliveryMethod={selectedDeliveryMethod}
              onBenefitsClicked={onBenefitsClicked}
              debitFee={debitFee.fee}
            />
            <InlineDatePicker
              date={deductionDate}
              onChange={onChangeDatePicker}
              errorLabel={!deductionDate ? 'batchPayment.sidePanel.deductionDateError' : undefined}
              isFirstWave={isFirstWave}
            />
            <Divider size="1px" />
            <TextTitle padding>
              <MIFormattedText label="batchPayment.sidePanel.vendorReceives" />
            </TextTitle>
            <SidePanelDeliveryMethodSection
              selectedDeliveryMethod={selectedDeliveryMethod}
              selectedFundingSource={selectedFundingSource}
              bill={bill}
              deliveryMethods={deliveryMethods}
              hasPartialAmount={hasPartialAmount}
              onChangeDM={onChangeDM}
              onAddDmMethod={onAddDmMethod}
              onToggleVirtualCardInfoModal={onToggleVirtualCardInfoModal}
            />
            <Divider size="1px" />
            <TextTitle padding>
              <MIFormattedText label="batchPayment.sidePanel.deliveryDate" />
            </TextTitle>
            {getDeliveryDateSection()}
            <Divider size="1px" />

            {isInternational && (
              <>
                <TextTitle padding>
                  <MIFormattedText label="batchPayment.paymentListTitle.paymentDetails" />
                </TextTitle>
                <PurposeOfPayment
                  errors={purposeErrors}
                  onChange={handlePurposeOfPaymentChange}
                  purpose={{
                    type: getPurposeByType(purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE),
                    description: getPurposeByType(
                      purpose,
                      PURPOSE_OF_PAYMENT_STRUCTURE.DESCRIPTION
                    ),
                  }}
                  showInvoiceFileSelector={showInvoiceFileSelector}
                  billId={bill?.id}
                />
              </>
            )}

            <MITextInputWrapper>
              <MITextInput
                value={memo}
                label={
                  selectedDeliveryMethod?.deliveryType === 'check'
                    ? 'batchPayment.sidePanel.memoCheck'
                    : 'batchPayment.sidePanel.memoBank'
                }
                placeholder="batchPayment.sidePanel.memoPlaceholder"
                onChange={({ value: textValue }) => setMemo(textValue)}
              />
            </MITextInputWrapper>
          </SidePanelWrapper>
        </PaymentInfoContainer>
        <ButtonWrapper>
          <MIButton
            label={continueButtonLabel}
            variant={BUTTON_VARIANT.PAY}
            onClick={savePayments}
            disabled={
              isShouldAmex ||
              hasPurposeOfPaymentError ||
              !isValidAmount ||
              !selectedDeliveryMethod ||
              !selectedFundingSource ||
              !deductionDate
            }
          />
        </ButtonWrapper>
        <debitFee.Modal onNext={onDebitFeeNext} />
      </SidePanelPageContainer>

      {shouldShowBenefitsModal && (
        <CreditCardBenefitsModal
          onButtonClick={onBenefitsButtonClicked}
          onCloseClick={onCloseBenefitsModal}
        />
      )}
    </>
  );
};

export default SidePanelLayout;

const Text = css`
  font-size: ${(props) => props.theme.text.size.button};
  line-height: 2rem;
  color: rgba(57, 58, 61, 1);
  font-weight: ${(props) => props.theme.text.weight.normal};
`;

const sidePanelWidth = '38.4rem';

const TextTitle = styled.div<{ padding?: boolean }>`
  ${Text}
  text-transform: uppercase;
  color: rgba(107, 108, 114, 1);
  padding-bottom: ${({ padding }) => (padding ? '0px' : '5px')};
  padding-top: ${({ padding }) => (padding ? '1.25rem' : '0px')};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: inherit;
  bottom: 0;
  background: ${(props) => props.theme.colors.white.opaque};
  border-top: 2px solid ${(props) => props.theme.background.default};
  z-index: 2;
  padding-right: 2rem;
  box-sizing: border-box;
  height: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MITextInputWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
`;

const SidePanelWrapper = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
`;

const Divider = styled.div<{ size?: string }>`
  background: ${(props) => props.theme.background.default};
  height: ${({ size }) => size || '4px'};
`;

const SidePanelPageContainerPosition = styled.div`
  position: relative;
  width: 0;
  transition: 0.7s ease-in-out;

  &.open {
    right: 0;
    width: ${sidePanelWidth};
    @media ${devices.mobile} {
      width: 30 wv;
    }
  }
`;

const SidePanelPageContainer = styled.div`
  position: fixed;
  background: ${(props) => props.theme.background.default};
  margin-left: 0.625rem;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: ${sidePanelWidth};
  background-color: ${(props) => props.theme.colors.white.opaque};
  top: 0;
  right: 0;
  transform: translateX(${sidePanelWidth});
  overflow-x: hidden;
  transition: transform 0.7s ease-in-out;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.14);
  ${(props) => props.theme.text.fontType.small};

  &.open {
    transform: translateX(0);
    @media ${devices.mobile} {
      width: 100%;
    }
  }
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 2;
  padding: 2rem 2rem 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  box-sizing: border-box;
`;

const DeliveryDatePlaceholder = styled.div<{ selectedDate?: boolean }>`
  color: ${(props) => {
    if (props.selectedDate) {
      return props.theme.text.color.main;
    }

    return props.theme.text.color.label;
  }};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2rem;
  height: 5rem;
`;

const HighlightedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.HighlightedHeader}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;
  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;

const PaymentInfoContainer = styled(BaseContainer)`
  padding-bottom: 1.7rem;
  min-width: 300px;
  z-index: 1;
  position: relative;
  overflow: scroll;
  margin-bottom: 5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentInfoContainer}
`;

const PaymentApprovalHint = styled.div<{ ml: number; mt: number }>`
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${(props) => props.theme.text.color.darkGrey};
  margin-left: ${(props) => `${props.ml}rem`};
  margin-top: ${(props) => `${props.mt}rem`};
`;

const BoxRow = styled.div`
  display: flex;
  margin-top: 1.8rem;
  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  padding-right: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxColumn}
`;

const FieldName = styled.div`
  ${baseTextStyles}
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldName}
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldValue}
`;

const SectionSeparator = styled.div`
  display: none;
  ${(props) => props.theme?.components?.BillPaymentReview?.SectionSeparator}
`;

const Close = styled.i.attrs({
  className: 'icon-close-icon',
  'data-testid': 'close-side-panel',
})`
  color: ${({ theme }) => theme.text.color.darkGrey};
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  z-index: 3;
`;

const PaymentIcon = styled.i<{ isBillPaid?: boolean }>`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};

  > img {
    height: 2.4rem;
  }

  > i {
    font-size: 2.4rem;
    color: ${(props) =>
      props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  }

  ${(props) => props.theme?.components?.PaymentActivity?.PaymentIcon}
`;

const CalIcon = styled(PaymentIcon)`
  i {
    color: ${(props) => props.theme.text.color.label};
  }
`;
