import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import PaginationControl from './PaginationControl';

type Props = {
  canNextPage?: boolean;
  goToLastPage: () => void;
  goToNextPage: () => void;
};

const PaginationNext = ({ canNextPage, goToLastPage, goToNextPage }: Props) => (
  <>
    <PaginationControl onClick={goToNextPage} disabled={!canNextPage}>
      <MIFormattedText label="paymentDashboard.pagination.next" />
      &nbsp;&gt;
    </PaginationControl>
    <PaginationControl onClick={goToLastPage} disabled={!canNextPage}>
      <MIFormattedText label="paymentDashboard.pagination.last" />
    </PaginationControl>
  </>
);

export default PaginationNext;
