import React from 'react';
import MISingleSelect from 'src/app/components/common/MISingleSelect';
import { ModelViewField } from './use-form';

export type SelectFieldOption<V> = {
  id?: string;
  label: string;
  value?: V;
};
export type WizardSelectFieldProps<V> = {
  model: ModelViewField<V>;
  label: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  noOptionsLabel?: string;
  createLabel?: string;
  iconColor?: string;
  onCreate?: (newLabel: string) => void;
  options: SelectFieldOption<V>[];
  errorMessageIcon?: React.ReactNode;
  privateData?: boolean;
  notices?: string[];
};

export function WizardSelectField<V>(props: WizardSelectFieldProps<V>) {
  const { model, options, onCreate, disabled, ...rest } = props;
  const id = props.id || model.id;
  const value = options.find((o) => o.value === model.value) || null;

  const onChange = ({ id, value, label, meta }) => {
    const newValue = value || label;

    if (onCreate && meta.action === 'create-option') {
      onCreate(value);
    }

    model.onChange({ id, value: newValue });
  };

  return (
    <MISingleSelect
      {...rest}
      id={id}
      errorMessage={model?.error}
      onChange={onChange}
      allowCustom={!!onCreate}
      options={options}
      value={value}
      isDisabled={disabled}
    />
  );
}
