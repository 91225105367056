import React, { useMemo, ReactNode } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { OptionType, defaultOptionRender } from 'src/app/components/common/DropDown/MIDropDown';
import { WizardNewSelectField } from 'src/app/ui/form/WizardNewSelectField';
import useGetFundingSourceDisplayName from 'src/app/modules/funding-sources/hooks/useGetFundingSourceDisplayName';

const WizardIntuitAccountSelectField = ({ intuitAccounts, fundingSource, ...restProps }) => {
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const intuitAccountsOptions = useMemo(() => {
    const fundingSourceDisplayName = getFundingSourceDisplayName({
      fundingSource,
    });
    const options = (intuitAccounts || []).map((account) => ({
      value: account.Id,
      label: account.Name,
    }));

    if (options.some(({ label }) => label === fundingSourceDisplayName)) {
      return options;
    }

    return [{ value: 'new', label: `${fundingSourceDisplayName} (New)` }, ...options];
  }, [intuitAccounts, getFundingSourceDisplayName, fundingSource]);

  const renderOption = (option: OptionType, searchText?: string | null): ReactNode | null => {
    if (option.value === 'new') {
      return <AddOption searchText={searchText} />;
    }

    return defaultOptionRender(option, searchText);
  };

  return (
    <WizardNewSelectField
      label="onboarding.fundingSources.bank.intuitAccounts.accountLabel"
      placeholder="onboarding.fundingSources.bank.intuitAccounts.placeholder"
      required
      renderOption={renderOption}
      options={intuitAccountsOptions}
      privateData
      {...restProps}
    />
  );
};

export default WizardIntuitAccountSelectField;

const AddOption = ({ searchText }) => {
  if (searchText) {
    return null;
  }

  return (
    <AddOptionComponentWrapper>
      <PlusIcon />
      <MIFormattedText label="onboarding.fundingSources.bank.intuitAccounts.customLabel" />
    </AddOptionComponentWrapper>
  );
};

const AddOptionComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.theme?.pages?.SetBankAccountsQuickbooksIdPageContainer?.AddOptionComponentWrapper}
`;

const PlusIcon = styled.i.attrs({ className: 'icon-plus-icon' })`
  vertical-align: text-bottom;
  font-size: 2rem;
  margin-right: 1rem;
  margin-left: 0.2rem;
`;
