import { useMemo, useState } from 'react';
import { forwardRef, InputProps as ChakraInputProps } from '@chakra-ui/react';

import { FieldWrapperType } from '../../types';
import { Input, SmartIcon, Box } from '../..';

export type PasswordInputProps = Partial<ChakraInputProps> &
  FieldWrapperType & {
    isValueVisible?: boolean;
  };

const PasswordInput = forwardRef<PasswordInputProps, 'input'>(
  ({ isValueVisible = false, inputMode, ...restInputProps }, ref) => {
    const [showValue, setShowValue] = useState(isValueVisible);

    const inputType = useMemo(() => {
      if (showValue && inputMode === 'numeric') {
        return 'number';
      }

      if (showValue) {
        return 'text';
      }

      return 'password';
    }, [showValue, inputMode]);

    return (
      <Input
        {...restInputProps}
        ref={ref}
        type={inputType}
        rightAddonWidth="2.4rem"
        rightAddon={(
          <Box
            cursor="pointer"
            onClick={() => {
              setShowValue(!showValue);
            }}
          >
            <SmartIcon
              width="2.4rem"
              height="2.4rem"
              type={showValue ? 'eye-icon-crossed' : 'eye-icon'}
            />
          </Box>
        )}
      />
    );
  },
);

export { PasswordInput };
