export interface AmexVerificationValidationErrors {
  industry?: string;
  subIndustry?: string;
}

interface ValidateReturn {
  validationErrors: AmexVerificationValidationErrors;
  isValid: boolean;
}
export const validateAmexMccCode = (
  industry?: { value: string; label: string },
  subIndustry?: { value: string; label: string }
): ValidateReturn => {
  const validationErrors: AmexVerificationValidationErrors = {};
  let isValid = true;

  if (!industry?.value) {
    validationErrors.industry = 'amexVerification.modal.form.industry.empty';
    isValid = false;
  }

  if (!subIndustry?.value) {
    validationErrors.subIndustry = 'amexVerification.modal.form.code.empty';
    isValid = false;
  }

  return {
    validationErrors,
    isValid,
  };
};
