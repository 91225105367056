import styled from 'styled-components';
import PaymentProcessingBlockedNotificationCard from '../PaymentProcessingBlockedNotificationCard';
import { Box, Flex } from '@melio/billpay-design-system';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';

export const Container = styled.div``;

export const WrappedPaymentProcessingBlockedNotificationCard = styled(
  PaymentProcessingBlockedNotificationCard
)`
  margin: 2rem;
`;

export const Separator = styled.div`
  height: 0.1rem;
  background-color: ${({ theme }) => theme.colors.ds.gray[700]};
  margin: 0 2rem;
  ${(props) => props.theme?.components?.QBOBillPaymentsActivity?.Separator}
`;

export const MarkAsPaidContainer = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.colors.success.opaque};
  ${(props) => props.theme.text.fontType.regular}
`;

export const MarkAsPaidAccountInfo = styled.div`
  ${(props) => props.theme.text.fontType.hint}
  color: ${(props) => props.theme.text.color.darkGrey};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

export const MarkedAsPaidInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.6rem;
`;

export const TimelineSection = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  border-bottom: 0.4rem solid ${({ theme }) => theme.colors.white.lightBlue};
  padding: 0 2rem;
`;

export const BillTimelineContainer = styled(Box)`
  height: 7.8rem;
  padding: 0 2.6rem 4rem;
`;

export const CheckOverdueMessage = styled(QBONotificationCard)`
  margin: 0;
  margin-bottom: 2rem;
  border-color: ${({ theme }) => theme.colors.ds.yellow[200]};
  i {
    color: ${({ theme }) => theme.colors.ds.yellow[200]};
  }
`;
