import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FeeFundingSourcePage } from 'src/app/pages/settings/components/fee/components/FeeFundingSourcePage';
import { compose } from 'recompose';
import analytics from 'src/app/services/analytics';
import { withNavigator } from 'src/app/hoc';
import { withPayBillData } from 'src/app/pages/bill/pay/hoc/withPayBillData';
import locations from 'src/app/utils/locations';
import { getOrgId } from 'src/app/redux/user/selectors';

const eventPage = 'settings-billing';

const AddPaymentMethod = ({ navigate, getUrlAfterFundingSourceStepForRecoveryFlow }) => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);

  useEffect(() => {
    analytics.track(eventPage, 'payment-method');
  }, []);

  const gotoBillingSettingsPAge = () => {
    history.push(locations.Settings.billingItems.url({ orgId }));
  };

  return (
    <FeeFundingSourcePage
      goExit={gotoBillingSettingsPAge}
      onPrev={history.goBack}
      {...{ navigate, getUrlAfterFundingSourceStepForRecoveryFlow }}
    />
  );
};

export default compose(withNavigator(), withPayBillData())(AddPaymentMethod);
