import React, { useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import isMobile from 'ismobilejs';
import includes from 'lodash/includes';
import { ChakraProvider } from '@melio/billpay-design-system';
import { PaymentCreateFlowOriginEnum } from 'src/app/version-2/model/enums';
import QboSite from '../sites/qboSite';
import { GlobalStyle } from '../theme/global-theme';
import qbrIosSite from '../sites/qbrIosSite';
import QbrAndroidSite from '../sites/qbrAndroidSite';
import qbmIosSite from '../sites/qbmIosSite';
import qbmAndroidSite from '../sites/qbmAndroidSite';
import { Site } from '../sites/site';

const SITE_NAME = {
  QBO: {
    DEFAULT: 'intuit-app.meliopayments.com',
    ALPHA: 'alpha-intuit-app.meliopayments.com',
    DEV: 'dev-intuit-app.meliopayments.com',
  },
};

const SiteContext = React.createContext<Site>(null as any);

const SITES: Record<string, Site> = {
  [SITE_NAME.QBO.DEFAULT]: QboSite,
  [SITE_NAME.QBO.ALPHA]: QboSite,
  [SITE_NAME.QBO.DEV]: QboSite,
  'qbr-ios': qbrIosSite,
  'qbr-android': QbrAndroidSite,
  'qbm-ios': qbmIosSite,
  'qbm-android': qbmAndroidSite,
  qbo: QboSite,
};

const QBO_SITE_NAME = SITE_NAME.QBO.DEFAULT;
const SITE_OVERRIDE = process.env.NX_REACT_APP_SITE;

export function getSiteFromUrl(href: string) {
  const uri = new URL(href);
  const { host } = uri;
  const siteConfigParam: any = uri.searchParams.get('siteConfig');

  if (SITES[siteConfigParam]) {
    return SITES[siteConfigParam];
  }

  if (siteConfigParam === 'qbm') {
    return SITES[getQbmSiteConfig()];
  }

  if (isQuickpay(host, siteConfigParam)) {
    return SITES[getQuickpaySiteName()];
  }

  if (SITES[host]) {
    return SITES[host];
  }

  if (host.substring(0, 7) === 'intuit-') {
    return SITES[QBO_SITE_NAME];
  }

  if (SITE_OVERRIDE && SITES[SITE_OVERRIDE.toLowerCase()]) {
    return SITES[SITE_OVERRIDE.toLowerCase()];
  }

  return SITES[QBO_SITE_NAME];
}

export function getQuickpaySiteName() {
  const deviceInfo = isMobile(window.navigator);

  if (deviceInfo.android.phone) {
    return PaymentCreateFlowOriginEnum.QBR_ANDROID;
  }

  return PaymentCreateFlowOriginEnum.QBR_IOS;
}

function getQbmSiteConfig() {
  const deviceInfo = isMobile(window.navigator);

  if (deviceInfo.android.phone) {
    return PaymentCreateFlowOriginEnum.QBM_ANDROID;
  }

  return PaymentCreateFlowOriginEnum.QBM_IOS;
}

function isQuickpay(host, siteConfigParam) {
  if (siteConfigParam === 'qbr') return true;

  const QBR = {
    DEFAULT: 'quickpay-app.meliopayments.com',
    ALPHA: 'alpha-quickpay-app.meliopayments.com',
    DEV: 'dev-quickpay-app.meliopayments.com',
  };

  return includes(QBR, host);
}

// function preloadImages(paths) {
//   const imgPaths = getImgPaths(paths);
//
//   imgPaths.forEach((imgPath) => {
//     const img = new Image();
//
//     img.src = imgPath;
//   });
// }
type SiteProviderProps = {
  site: Site;
  children: React.ReactNode;
};

export function SiteProvider({ site, children }: SiteProviderProps) {
  const firstRender = useRef<boolean>();

  if (!firstRender.current) {
    firstRender.current = true;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // images are now mostly svgrs and are not external code that can be cached.
  // useEffect(() => {
  //   preloadImages([
  //     '/dashboard/',
  //     '/accounting-software/',
  //     '/funding-sources/',
  //     '/general/',
  //     '/icons/',
  //     '/onboarding/',
  //     '/vendors/',
  //     '/melio-logo-loader.gif',
  //   ]);
  // }, []);

  return (
    <SiteContext.Provider value={site}>
      <ThemeProvider theme={site.theme}>
        <ChakraProvider resetCSS={false} theme={site.chakraTheme}>
          {children}
        </ChakraProvider>
        <GlobalStyle />
      </ThemeProvider>
    </SiteContext.Provider>
  );
}

export { SiteContext };
