import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import paymentStore from 'src/app/modules/payments/payment-store';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import { getOrgId } from 'src/app/redux/user/selectors';
import { OrganizationType } from 'src/app/utils/types';

type BatchTitlesProps = {
  scheduledPaymentsTextValues?: { countPayments: number; totalAmount: number };
};

export const useBatchesTitles = (props: BatchTitlesProps) => {
  const scheduledPaymentsFromStore = useSelector(paymentStore.selectors.getScheduledPayments);
  const scheduledPayments = props?.scheduledPaymentsTextValues || scheduledPaymentsFromStore;
  const orgId = useSelector(getOrgId);
  const organization: OrganizationType = useSelector(organizationStore.selectors.byId(orgId));

  return {
    totalPayments: (
      <strong>
        {' '}
        <MIFormattedText
          label="batchPayment.success.total"
          values={{
            totalPayedBills: get(scheduledPayments, 'countPayments', 0),
          }}
        />
      </strong>
    ),
    total: (
      <strong>
        <MIFormattedCurrency value={get(scheduledPayments, 'totalAmount', 0)} />
      </strong>
    ),
    companyName: organization?.companyName,
  };
};
