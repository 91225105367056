import React from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';

import { AreaLoader } from '@melio/billpay-design-system';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import shiftToAchLocations from 'src/app/pages/vendor/shift-vendor-to-ach/locations';
import ConvertedUnilateralPaymentActivity from 'src/app/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { useEditCheckAddressStatus } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import successImage from 'src/app/images/general/qbo-check-circle.svg';
import {
  BillInfoContainer,
  BillPaymentBlock,
  Title,
  Subtitle,
  ShiftToAchContainer,
  ShiftToAchTitle,
  ShiftToAchSubtitle,
  BlockSeparator,
} from './shared';

type Props = {
  token: string;
};

const CheckAddressChangedPage = ({ token }: Props) => {
  const history = useHistory();
  const { payment, isPaymentLoading, organization } = useEditCheckAddressStatus({ token });
  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  const goShiftToAch = () => {
    history.push(generatePath(shiftToAchLocations.index, { id: payment?.vendorId, token }));
  };

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <Box textAlign="center" marginBottom={5} marginTop={10}>
        <QBLogo />
      </Box>
      <BillPaymentBlock>
        <BillInfoContainer>
          <DoneIcon src={successImage} />
          <Title data-testid="success-title">
            <MIFormattedText label="vendors.editCheckAddress.checkNotSent.success.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.editCheckAddress.checkNotSent.success.subtitle"
              values={{
                totalAmount: <MIFormattedCurrency value={bill.totalAmount} />,
                companyName: organization?.companyName,
                invoiceNumber: bill.invoiceNumber,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
        <ShiftToAchContainer>
          <BlockSeparator />
          <ShiftToAchTitle>
            <MIFormattedText label="vendors.editCheckAddress.checkNotSent.success.upsaleTitle" />
          </ShiftToAchTitle>
          <ShiftToAchSubtitle>
            <MIFormattedText label="vendors.editCheckAddress.checkNotSent.success.upsaleSubTitle" />
          </ShiftToAchSubtitle>
          <MIButton
            onClick={goShiftToAch}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            label="vendors.editCheckAddress.checkNotSent.success.upsaleCTA"
            fullWidth
          />
        </ShiftToAchContainer>
      </BillPaymentBlock>
    </QBOLayoutPage>
  );
};

export default CheckAddressChangedPage;

const DoneIcon = styled.img`
  height: 4.8rem;
  width: 4.8rem;
  margin-bottom: 2rem;
`;
