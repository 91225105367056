import styled from 'styled-components';
import { Popover, PopoverArrow } from '@melio/billpay-design-system';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';
import verifyBankAccountExample from 'src/app/images/qbo/verify-bank-account-example.svg';

type Props = {
  transitionStyles?: Record<string, any>;
  mobileStyles?: Record<string, any>;
};

export const MicroDepositPopover = ({
  transitionStyles = defaultTransitionStyles,
  mobileStyles = defaultMobileStyles,
}: Props) => (
  <Popover placement="bottom" arrowStyles={{ ...defaultArrowStyles, ...transitionStyles }}>
    <Popover.PopoverTrigger>
      <Popover.Text
        marginTop={27}
        fontWeight={600}
        color="#0077c5"
        cursor="pointer"
        display="flex"
        alignItems="center"
      >
        <InfoIcon />
        <MIFormattedText label="settings.microDeposits.verifyDialog.viewAnExample" />
      </Popover.Text>
    </Popover.PopoverTrigger>
    <Popover.PopoverContent left="-33%" sx={mobileStyles} onClick={(e) => e.stopPropagation()}>
      <PopoverArrow />
      <Popover.PopoverBody>
        <Popover.Text fontWeight={600} marginTop={2} marginBottom={2}>
          <MIFormattedText label="settings.microDeposits.verifyDialog.exampleTitleText" />
        </Popover.Text>
        <Popover.Image width="100%" src={verifyBankAccountExample} alt="Example" />
      </Popover.PopoverBody>
    </Popover.PopoverContent>
  </Popover>
);

const defaultMobileStyles = {
  [`@media${devices.mobile}`]: {
    left: '-30px',
    top: '10px',
  },
};

const commonArrowStyles = {
  display: 'block',
  position: 'absolute',
  left: '50%!important',
  height: '16px!important',
  width: '16px!important',
  border: '1px solid var(--chakra-colors-gray-200)',
  background: 'var(--chakra-colors-white)',
  zIndex: '10001!important',
  transform: 'rotate(45deg)!important',
  [`@media${devices.mobile}`]: {
    left: '25%!important',
  },
};

const defaultArrowStyles = {
  '[data-popper-placement*="top"] .chakra-popover__arrow-positioner': {
    ...commonArrowStyles,
    borderLeft: 'none',
    borderTop: 'none',
    bottom: '-9px!important',
  },
  '[data-popper-placement*="bottom"] .chakra-popover__arrow-positioner': {
    ...commonArrowStyles,
    borderRight: 'none',
    borderBottom: 'none',
    top: '-9px!important',
  },
};

const defaultTransitionStyles = {
  '[data-popper-placement*="bottom"]': {
    transform: 'translate3d(29.5px, 338px, 0px)!important',
  },
  '[data-popper-placement*="top"]': {
    transform: 'translate3d(29.5px, -178px, 0px)!important',
  },
};

const InfoIcon = styled.i.attrs({ className: 'icon-info-hollow-icon' })`
  font-size: 2rem;
  margin-right: 6px;
  color: #393a3d;
`;
