import CurrencyInputField from 'react-currency-input-field';
import styled, { css } from 'styled-components';
import { CurrencyInputType } from './consts';

export const container = (type: CurrencyInputType) => ({
  direction: 'column' as any,
  align: type === CurrencyInputType.INPUT_FIELD ? 'flex-start' : 'center',
});

export const label = {
  color: 'gray.500',
  textStyle: 'body3',
  fontWeight: '500',
  mb: '1rem',
  textTransform: 'uppercase' as any,
};

export const inputContainer = (type: CurrencyInputType) => ({
  direction: 'row' as any,
  justify: type === CurrencyInputType.INPUT_FIELD ? 'flex-start' : 'center',
});

export const currencySign = {
  textStyle: 'h5',
  fontWeight: '600',
  color: 'black',
  mr: '0.4rem',
  mt: '0.4rem',
};

export const hiddenAmount = {
  fontSize: '4.2rem',
  lineHeight: '5.2rem',
  fontWeight: '600',
  fontFamily: 'body',
  position: 'fixed' as any,
  visibility: 'hidden' as any,
};

export const pencil = {
  className: 'icon-intuit-edit-manual',
  alignSelf: 'center',
  fontSize: '2.1rem',
  cursor: 'pointer',
  color: 'gray.500',
  mb: '0.4rem',
};

export const error = (type: CurrencyInputType) => ({
  color: '#d0021b',
  textStyle: 'body4',
  fontWeight: type === CurrencyInputType.INPUT_FIELD ? '400' : '500',
  mt: '0.6rem',
  pr: type === CurrencyInputType.INPUT_FIELD ? '0.5rem' : 0,
  textAlign: type === CurrencyInputType.INPUT_FIELD ? 'initial' : ('center' as any),
});

const fontStyle = css<{ type: CurrencyInputType }>`
  font-size: ${({ type }) => (type === CurrencyInputType.INPUT_FIELD ? '3.4rem' : '4.2rem')};
  line-height: ${({ type }) => (type === CurrencyInputType.INPUT_FIELD ? '3rem' : '5.2rem')};
  font-weight: ${({ theme, type }) =>
    type === CurrencyInputType.INPUT_FIELD ? theme.text.weight.regular : theme.text.weight.demi};
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.text.color.main};
`;

const borderStyle = css<{ type: CurrencyInputType }>`
  box-sizing: border-box;
  border: ${({ type }) => (type === CurrencyInputType.INPUT_FIELD ? '1px solid' : 'none')};
  border-radius: 0.3rem;
  border-color: #e3e5e8;

  &:focus {
    border-color: #6b6c72;
  }

  &:hover {
    border-color: #6b6c72;
  }
`;

const placeholderFontStyle = css`
  ${fontStyle}
  color: ${({ theme }) => theme.text.color.grey};
`;

export default styled(CurrencyInputField)`
  width: ${({ type }) => (type === CurrencyInputType.INPUT_FIELD ? '85%' : '9rem')};
  max-width: 40rem;
  height: ${({ type }) => (type === CurrencyInputType.INPUT_FIELD ? '4.4rem' : '5.2rem')};
  padding: ${({ type }) => (type === CurrencyInputType.INPUT_FIELD ? '0.8rem' : '0')};
  ${borderStyle}
  ${fontStyle};

  &:focus {
    outline: none;
    box-shadow: none !important;
  }

  &::placeholder {
    ${placeholderFontStyle}
  }

  &::-moz-placeholder {
    ${placeholderFontStyle}
    opacity: 1
  }

  &::-webkit-input-placeholder {
    ${placeholderFontStyle}
  }

  &:-ms-input-placeholder {
    ${placeholderFontStyle}
  }

  background-color: ${({ type }) =>
    type === CurrencyInputType.INPUT_FIELD ? 'white' : 'transparent'};
`;
