import { useState } from 'react';
import analytics from 'src/app/services/analytics';

import { FundingSource } from 'src/app/version-2/model/dtos';

type useBenefitsReturnValue = {
  shouldShowBenefitsModal: boolean;
  onBenefitsClicked: (card?: FundingSource) => void;
  onCloseBenefitsModal: () => void;
  benefitsRelevantCreditCard?: FundingSource;
};

export const useBenefits = (): useBenefitsReturnValue => {
  const [shouldShowBenefitsModal, setShouldShowBenefitsModal] = useState<boolean>(false);
  const [benefitsRelevantCreditCard, setBenefitsRelevantCreditCard] = useState<
    FundingSource | undefined
  >();
  const onBenefitsClicked = (card?: FundingSource) => {
    setShouldShowBenefitsModal(true);
    setBenefitsRelevantCreditCard(card);
    analytics.trackAction('benefits-click', {
      shouldShowBenefitsModal,
    });
  };

  const onCloseBenefitsModal = () => {
    setBenefitsRelevantCreditCard(undefined);
    setShouldShowBenefitsModal(false);
  };

  return {
    shouldShowBenefitsModal,
    onBenefitsClicked,
    onCloseBenefitsModal,
    benefitsRelevantCreditCard,
  };
};
