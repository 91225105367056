import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Modal } from '@melio/billpay-design-system';

export interface ErrorModalProps {
  hasError: boolean;
  onClose: () => void;
}

const ErrorModal = ({ hasError, onClose }: ErrorModalProps) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const onRetry = () => {
    onClose();
    history.goBack();
  };

  return (
    <Modal isOpen={!!hasError} onClose={onClose}>
      <Modal.Header>
        <Modal.HeaderTitle>
          {formatMessage({ id: 'international.vendorLocation.errorModal.title' })}
        </Modal.HeaderTitle>
      </Modal.Header>

      <Modal.CloseButton />

      <Modal.Body>
        <ModalBodyText>
          {formatMessage({ id: 'international.vendorLocation.errorModal.text' })}
        </ModalBodyText>
      </Modal.Body>

      <Modal.Footer>
        <Modal.ButtonContent isCentred>
          <Modal.Button data-testid="modal-button" onClick={onRetry} size="md" variant="primary">
            {formatMessage({ id: 'international.vendorLocation.errorModal.cta' })}
          </Modal.Button>
        </Modal.ButtonContent>
      </Modal.Footer>
    </Modal>
  );
};

export { ErrorModal };

export const ModalBodyText = styled.div`
  ${({ theme }) => theme.textStyles.ds.body1};
  align-self: flex-start;
`;
