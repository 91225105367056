import React from 'react';
import { Box, Flex, Image } from '@melio/billpay-design-system';

import { DashboardListItemFundingSourceType } from 'src/app/pages/qb-dashboard/types';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { FUNDING_DEFAULT_LOGO } from 'src/app/version-2/model/constants';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { imageStyles, iconStyles } from './styles';

type Props = {
  fundingSource?: FundingSource | DashboardListItemFundingSourceType;
};

const ACHMethod = ({ fundingSource }: Props) => {
  const digits = getAccountNumber4digits(fundingSource?.bankAccount);
  const hasLogo = fundingSource?.logo && fundingSource?.logo !== FUNDING_DEFAULT_LOGO;

  const fallbackLogo = <Box as="i" __css={iconStyles} className="icon-bank-icon" />;

  return (
    <Flex>
      {hasLogo ? (
        <Image
          w="5"
          __css={imageStyles}
          src={`data:image/jpeg;base64,${fundingSource?.logo}`}
          fallback={fallbackLogo}
        />
      ) : (
        fallbackLogo
      )}
      {`...${digits}`}
    </Flex>
  );
};

export default ACHMethod;
