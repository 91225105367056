import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from '@melio/billpay-design-system';
import QBROutsideLayout from 'src/app/components/layout/QBROutsideLayout';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import paymentStore from 'src/app/modules/payments/payment-store';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS, DELIVERY_DATE_FONT_TYPE } from 'src/app/utils/consts';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import { UnilateralPaymentProps } from 'src/app/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { getJWTPayload } from 'src/app/helpers/jwt';
import vendorsLocations from 'src/app/pages/vendor/locations';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { DeliveryDate } from 'src/app/utils/dates';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';

type Props = UnilateralPaymentProps;

const QBRAddDeliveryMethodSuccessPage = (props: Props) => {
  const history = useHistory();
  const { token } = props;
  const { paymentId } = getJWTPayload(token);
  const payment: any = useSelector(paymentStore.selectors.byId(paymentId));
  const paymentActions = useStoreActions(paymentStore);

  useEffect(() => {
    if (!payment) {
      paymentActions
        .fetchPaymentDetailsWithToken({ token, action: 'unilateralPayment' })
        .catch(() => {
          history.push(generatePath(vendorsLocations.unilateral.invalidToken, { token }));
        });
    }
  }, [paymentActions, token, history, payment]);
  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const organization: any = useSelector(
    organizationStore.selectors.byId(payment?.organization?.id)
  );
  const deliveryMethod: any = useSelector(
    deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId)
  );
  const isAchMethod = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH;
  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  const getAddressView = (dm) =>
    `${dm?.paperCheck?.addressLine1} ${dm?.paperCheck?.city}, ${dm?.paperCheck?.state} ${dm?.paperCheck?.zipCode}`;

  return (
    <QBROutsideLayout
      hideLogin
      headerTitle={
        isAchMethod
          ? 'vendors.addDeliveryMethodByLink.success.achTitle'
          : 'vendors.addDeliveryMethodByLink.success.checkTitle'
      }
    >
      <BillPaymentBlock>
        <BillInfoContainer>
          <SuccessRow>
            <SuccessRowTitle>
              <MIFormattedText label="vendors.addDeliveryMethodByLink.success.amount" />
            </SuccessRowTitle>
            <SuccessRowContent>
              <MIFormattedCurrency value={bill.totalAmount} />
            </SuccessRowContent>
          </SuccessRow>
          <SuccessRow>
            <SuccessRowTitle>
              <MIFormattedText label="vendors.addDeliveryMethodByLink.success.from" />
            </SuccessRowTitle>
            <SuccessRowContent>
              <MIFormattedText
                label="vendors.addDeliveryMethodByLink.success.fromValue"
                values={{ from: organization?.companyName }}
              />
            </SuccessRowContent>
          </SuccessRow>
          <SuccessRow>
            <SuccessRowTitle>
              <MIFormattedText label="vendors.addDeliveryMethodByLink.success.to" />
            </SuccessRowTitle>
            <SuccessRowContent>
              <MIFormattedText
                label={
                  isAchMethod
                    ? 'vendors.addDeliveryMethodByLink.success.toValue'
                    : 'vendors.addDeliveryMethodByLink.success.toCheckValue'
                }
                values={
                  isAchMethod
                    ? {
                        to: getAccountNumber4digits(deliveryMethod?.bankAccount),
                      }
                    : {
                        to: (
                          <span className={FULL_STORY_MASK_RULE_CLASS}>
                            {getAddressView(deliveryMethod)}
                          </span>
                        ),
                      }
                }
              />
            </SuccessRowContent>
          </SuccessRow>
          <SuccessRow>
            <SuccessRowTitle>
              <MIFormattedText label="vendors.addDeliveryMethodByLink.success.deliveryETA" />
            </SuccessRowTitle>
            <SuccessRowContent>
              <DeliveryDate
                date={payment?.deliveryEta}
                maxDate={payment?.maxDeliveryEta}
                fontType={DELIVERY_DATE_FONT_TYPE.REGULAR}
              />
            </SuccessRowContent>
          </SuccessRow>
        </BillInfoContainer>
      </BillPaymentBlock>
    </QBROutsideLayout>
  );
};

export default QBRAddDeliveryMethodSuccessPage;

const SuccessRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.text.fontType.regular};
  height: 6.5rem;
  border-bottom: 0.05rem solid ${(props) => props.theme.colors.border.light};
`;

const SuccessRowTitle = styled.div`
  color: ${(props) => props.theme.text.color.darkGrey};
  margin-right: 2rem;
`;

const SuccessRowContent = styled.div`
  font-weight: ${(props) => props.theme.text.weight.medium};
  color: ${(props) => props.theme.text.color.main};
  text-align: right;
`;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const BillInfoContainer = styled.div`
  padding: 0 1.6rem;
`;
