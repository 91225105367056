import { RecordOf } from 'immutable';
import { useSelector } from 'react-redux';
import React from 'react';
import classNames from 'classnames';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodText,
  PaymentInfoText,
  EditElement,
  PaymentMethodContainer,
} from 'src/app/components/layout/PaymentActivityElements';
import { isSharedVendor } from 'src/app/pages/vendor/utils';
import { getDeliveryMethodIcon } from 'src/app/pages/bill/utils';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import { getDeliveryMethodInfo } from 'src/app/pages/vendor/records';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { DeliveryMethodType, VendorType } from 'src/app/utils/types';

type Props = {
  payment: RecordOf<JustPayPaymentType>;
  onEdit?: () => void;
};

export const JustPayPaymentActivityDeliveryMethodInfo = ({ onEdit, payment }: Props) => {
  const deliveryMethod: DeliveryMethodType = useSelector(
    deliveryMethodsStore.selectors.byId(payment.deliveryMethodId)
  );
  const isCheckDeliveryMethod = payment?.deliveryMethodType === CONSTS.DELIVERY_TYPE.CHECK;
  const vendor: VendorType =
    useSelector(vendorsStore.selectors.byId(payment?.vendor?.id)) ||
    (payment?.vendor as VendorType);

  const deliveryMethodInfo = getDeliveryMethodInfo({
    deliveryMethod,
    vendorName: vendor?.companyName,
    isSharedVendor: isSharedVendor(vendor),
  });

  const getDeliveryMethodLabel = () => {
    if (isCheckDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.paperCheckLabel';
    }

    return 'payBillPaymentActivity.deliveryMethod.achLabel';
  };

  const paymentInfoTextClassName = classNames({
    [FULL_STORY_MASK_RULE_CLASS]: isCheckDeliveryMethod,
  });

  const canEdit = !isSharedVendor(vendor);

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <i className={getDeliveryMethodIcon(deliveryMethod)} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodContainer>
              <PaymentMethodText>
                <MIFormattedText label={getDeliveryMethodLabel()} />
              </PaymentMethodText>
            </PaymentMethodContainer>
            <PaymentInfoText
              className={paymentInfoTextClassName}
              data-testid="bill-pay-delivery-method"
            >
              {deliveryMethodInfo}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {canEdit ? <EditElement onEdit={onEdit} /> : null}
      </PaymentActivityMainContainer>
    </PaymentActivityContainer>
  );
};
