import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { QBCashStateType } from 'src/app/utils/types';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentInfoText,
  EditElement,
  PaymentInfoHint,
} from 'src/app/components/layout/PaymentActivityElements';
import {
  FundingSourceTypesEnum,
  CardTypeEnum,
  FundingSourceOrigins,
} from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourceInfoIcon as Icon } from './Icon';
import { FundingSourceInfoDescription as Description } from './Description';

type Props = {
  fundingSource: FundingSource;
  onEdit: () => void;
  qbCashState?: QBCashStateType;
};

export const JustPayPaymentActivityFundingSourceInfo = ({
  fundingSource,
  qbCashState,
  onEdit,
}: Props) => {
  let label = 'payBillPaymentActivity.fundingSource.achLabel';

  if (fundingSource?.fundingType === FundingSourceTypesEnum.CARD) {
    label =
      fundingSource?.cardAccount?.cardType === CardTypeEnum.CREDIT
        ? 'payBillPaymentActivity.fundingSource.creditCardLabel'
        : 'payBillPaymentActivity.fundingSource.debitCardLabel';
  }

  if (fundingSource?.origin === FundingSourceOrigins.QBCASH) {
    label = 'bills.form.paymentActivity.scheduledBill.scheduleMethodAchQBCash';
  }

  const hint = isManualBankAccountNotVerified(fundingSource)
    ? 'payBillPaymentActivity.fundingSource.unverifiedBankAccountHint'
    : '';

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <Icon fundingSource={fundingSource} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodContainer>
              <PaymentMethodText>
                <MIFormattedText label={label} />
              </PaymentMethodText>
            </PaymentMethodContainer>
            <PaymentInfoText isQBCash={fundingSource?.origin === FundingSourceOrigins.QBCASH}>
              <Description fundingSource={fundingSource} qbCashState={qbCashState} />
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        <EditElement onEdit={onEdit} />
      </PaymentActivityMainContainer>
      {hint && (
        <PaymentInfoHint>
          <MIFormattedText label={hint} />
        </PaymentInfoHint>
      )}
    </PaymentActivityContainer>
  );
};
