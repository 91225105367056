import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import { FullOrgSyncStatusEnum } from 'src/app/version-2/model/enums';
import { initiateFullOrgSyncSlice, checkFullOrgSyncStatusSlice } from './full-org-sync-slice';
import { name } from './consts';

const fullOrgSyncStore = createRestfulSlice({
  name,
  api: {},
  initialState: {
    status: FullOrgSyncStatusEnum.NONE,
  },
  slices: {
    initiateFullOrgSync: initiateFullOrgSyncSlice,
    checkFullOrgSyncStatus: checkFullOrgSyncStatusSlice,
  },
  selectors: {
    status: (state) => state[name].status,
  },
});

export { fullOrgSyncStore };
