import React, { ReactElement } from 'react';
import { MIFormattedDate } from 'src/app/version-2/externals';
import { removeTime } from 'src/app/version-2/utils/dates.utils';

import { format, isAfter, isSameDay, isSameMonth, isSameYear, parseISO } from 'date-fns';

export const DeliveryDateFormat = ({
  date,
  maxDate,
}: {
  date: string;
  maxDate: string;
}): ReactElement => {
  const parsedDate = parseISO(date);
  const parsedMaxDate = parseISO(maxDate);

  if (
    !maxDate ||
    isSameDay(parsedDate, parsedMaxDate) ||
    isAfter(removeTime(parsedDate), removeTime(parsedMaxDate))
  ) {
    return <MIFormattedDate date={date} />;
  }

  let toDateValue = <MIFormattedDate date={maxDate} />;

  let fromDateValue = <MIFormattedDate date={date} />;

  if (isSameYear(parsedDate, parsedMaxDate)) {
    fromDateValue = <MIFormattedDate year={undefined} date={date} />;

    if (isSameMonth(parsedDate, parsedMaxDate)) {
      toDateValue = <MIFormattedDate date={format(parsedMaxDate, 'd, yyyy')} />;
    }
  }

  return (
    <>
      {fromDateValue}
      {' - '}
      {toDateValue}
    </>
  );
};
