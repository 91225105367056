import { UserOrganization } from 'src/app/utils/types';
import { postRequest, putRequest, deleteRequest, fetchRequest } from './api';

const api = {
  delete(params) {
    const url = `/orgs/${params.orgId}/users-management/${params.id}`;

    return deleteRequest(url, params);
  },
  update(params) {
    const url = `/orgs/${params.orgId}/users-management/${params.id}`;

    return putRequest(url, params).then((res) => res.user);
  },
  list(params) {
    const url = `/orgs/${params.orgId}/users-management`;

    return fetchRequest(url).then((res) => res.users);
  },
  changeOwner(params) {
    const url = `/orgs/${params.orgId}/users-management/${params.id}/change-owner`;

    return postRequest(url, params);
  },
  addUsers({
    orgId,
    targetOrgId,
    items,
  }: {
    orgId: number;
    targetOrgId: number;
    items: UserOrganization[];
  }) {
    const url = `/orgs/${orgId}/users-management/add-users`;

    return postRequest(url, { targetOrgId, items }).then((res) => res.userOrganizations);
  },
};

export default api;
