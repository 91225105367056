import { isBefore, isSameDay } from 'date-fns';
import paymentsApi from 'src/app/services/api/payments';
import { DELIVERY_TYPE } from 'src/app/utils/consts';

const useNotifyVendorsOnPaymentScheduled = (paidPaymentsMainData) => {
  const shouldEmailVendorOnPaymentScheduled = (requiredData) => {
    const now = new Date();
    const isScheduledDateTodayOrAfterToday =
      isSameDay(now, new Date(requiredData.scheduledDate)) ||
      isBefore(now, new Date(requiredData.scheduledDate));

    if (
      requiredData?.paymentId &&
      requiredData?.organizationId &&
      requiredData?.contactEmail &&
      isScheduledDateTodayOrAfterToday
    ) {
      return true;
    }

    return false;
  };

  const notifyVendor = (notifyData) => {
    if (notifyData.deliveryType === DELIVERY_TYPE.ACH) {
      paymentsApi.notifyVendor(notifyData.organizationId, notifyData.paymentId);
    } else if (
      notifyData.deliveryType === DELIVERY_TYPE.CHECK &&
      shouldEmailVendorOnPaymentScheduled(notifyData)
    ) {
      paymentsApi.sendEditCheckAddress(notifyData.organizationId, notifyData.paymentId);
    }
  };

  const notifyVendors = (vendorIdsToContactEmailObj) => {
    const notificationsData = paidPaymentsMainData.filter(
      (paidPayment) => !!vendorIdsToContactEmailObj?.[paidPayment.vendorId]
    );

    for (const notificationData of notificationsData) {
      notifyVendor({
        ...notificationData,
        contactEmail: vendorIdsToContactEmailObj[notificationData.vendorId],
      });
    }
  };

  return [notifyVendors];
};

export default useNotifyVendorsOnPaymentScheduled;
