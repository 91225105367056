import { theme } from '@chakra-ui/react';

const tagTheme = theme.components.Tag;

export const TagTheme = {
  ...tagTheme,
  baseStyle: {
    ...tagTheme.baseStyle,
    container: {
      px: 'ds.lg',
      py: 'ds.md',
      fontWeight: 'ds.regular',
      fontFamily: 'body',
    },
    closeButton: {
      ...tagTheme.baseStyle.closeButton,
      border: 'none',
      cursor: 'pointer',
      px: 'ds.md',
      _focus: {
        background: 'transparent',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
  sizes: {
    lg: {
      container: {
        margin: '0 0.8rem',
        borderRadius: '10rem',
        cursor: 'default',
        fontSize: '1.4rem',
        color: 'ds.black',
        backgroundColor: 'ds.gray.600',
        minW: 'auto',
        minH: 'auto',
      },
    },
  },
} as any;
