import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem auto;
  border-radius: 0.8rem;
  gap: 1.6rem;

  @media ${devices.mobile} {
    width: 100%;
    flex-direction: column-reverse;
  }
`;
