import { useState, useEffect } from 'react';
import Breakjs from 'breakjs';

const BREAK_VALUES = {
  MOBILE: 0,
  PHABLET: 440,
  TABLET: 768,
  DESKTOP: 960,
};

export const breaks = {
  mobile: BREAK_VALUES.MOBILE,
  phablet: BREAK_VALUES.PHABLET,
  tablet: BREAK_VALUES.TABLET,
  desktop: BREAK_VALUES.DESKTOP,
};

export const devices = {
  mobile: `(min-width: ${BREAK_VALUES.MOBILE}px) and (max-width: ${BREAK_VALUES.PHABLET - 0.02}px)`,
  phablet: `(min-width: ${BREAK_VALUES.PHABLET}px) and (max-width: ${BREAK_VALUES.TABLET - 0.02}px)`,
  tablet: `(min-width: ${BREAK_VALUES.TABLET}px) and (max-width: ${BREAK_VALUES.DESKTOP - 0.02}px)`,
  desktop: `(min-width: ${BREAK_VALUES.DESKTOP}px)`,
  nonMobile: '',
  nonDesktop: '',
};

devices.nonMobile = `${devices.phablet}, ${devices.tablet}, ${devices.desktop}`;
devices.nonDesktop = `${devices.mobile}, ${devices.phablet}, ${devices.tablet}`;

export const appLayout = Breakjs(breaks);

export const useBreak = () => {
  const currentLayout = appLayout.current();
  const [layout, setLayout] = useState(currentLayout);

  useEffect(() => {
    setLayout(currentLayout);
  }, [currentLayout]);

  useEffect(() => {
    let unmounted = false;

    appLayout.addChangeListener((newLayout: string) => {
      if (!unmounted) {
        setLayout(newLayout);
      }
    });

    return () => {
      unmounted = true;
      appLayout.removeChangeListener();
    };
  }, []);

  return {
    isMobile: layout === 'mobile',
    isPhablet: layout === 'phablet',
    isTablet: layout === 'tablet',
    isDesktop: layout === 'desktop',
  };
};
