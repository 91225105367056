import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { IntuitBillCategory } from 'src/app/version-2/model/dtos/intuitBillCategory';

export const INTUIT_SLICE = `intuit`;

interface IntuitSlice {
  billCategories: EntityState<IntuitBillCategory>;
}

const intuitCategoriesAdapter = createEntityAdapter<IntuitBillCategory>({
  selectId: (category) => category.Id,
});

export const intuitInitialState: IntuitSlice = {
  billCategories: intuitCategoriesAdapter.getInitialState(),
};

export const selectState = (state: any) => state[INTUIT_SLICE];

export const intuitSlice = createSlice({
  name: INTUIT_SLICE,
  initialState: intuitInitialState,
  reducers: {
    setBillCategories: (state, action) => {
      intuitCategoriesAdapter.setAll(state.billCategories, action.payload);
    },
  },
});

export const intuitSelectors = {
  selectIntuitBillCategory: (categoryId: string) =>
    createSelector(selectState, (state) =>
      intuitCategoriesAdapter.getSelectors().selectById(state.billCategories, categoryId)
    ),
};

export const intuitActions = {
  ...intuitSlice.actions,
  fetchBillCategories: createAction(`${INTUIT_SLICE}/fetch-bill-categories`),
};
