import { PayloadAction } from '@reduxjs/toolkit';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { getPaymentUpsellDetailsApi } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/getPaymentUpsellDetails.api';
import { GetPaymentUpsellDetailsRequest } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/requests/GetPaymentUpsellDetailsRequest';
import { GetPaymentUpsellDetailsResponse } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/responses/GetPaymentUpsellDetailsResponse';
import { GetPaymentUpsellDetailsAndNavigateActionParams } from 'src/app/version-2/pages/vendor-upgrade-payment/model/objects/GetPaymentUpsellDetailsAndNavigateActionParams';
import { upgradePaymentActions } from 'src/app/version-2/pages/vendor-upgrade-payment/modules/upgradePayment.slice';
import {
  navigateOnPaymentUpsellDetailsLoaded,
  redirectToOfferExpiredPage,
} from 'src/app/version-2/pages/vendor-upgrade-payment/utils/navigation.utils';

import { call, put } from 'redux-saga/effects';

export function* getPaymentUpsellDetailsHandler(
  action: PayloadAction<GetPaymentUpsellDetailsRequest>
) {
  try {
    yield put(upgradePaymentActions.setIsLoading(true));

    const { token } = action.payload;
    const response: GetPaymentUpsellDetailsResponse = yield call(
      getPaymentUpsellDetailsApi.getPaymentUpsellDetails,
      { token }
    );

    yield put(upgradePaymentActions.setUpsellData(response));
    yield put(upgradePaymentActions.setIsLoading(false));
    yield put(upgradePaymentActions.setIsLoadFinished(true));

    return { response, error: null };
  } catch (error: any) {
    const { token } = action.payload;
    const { paymentId, vendorId } = getJWTPayload(token);

    loggingApi.error(
      'getPaymentUpsellDetails.saga.getPaymentUpsellDetailsHandler(): Error while fetching payment upsell details',
      { error, paymentId, vendorId }
    );

    yield put(upgradePaymentActions.setError(error));
    yield put(upgradePaymentActions.setIsLoading(false));
    yield put(upgradePaymentActions.setIsLoadFinished(true));

    return { response: null, error };
  }
}

export function* getPaymentUpsellDetailsAndNavigateHandler(
  action: PayloadAction<GetPaymentUpsellDetailsAndNavigateActionParams>
) {
  const { token, history } = action.payload;

  const { response, error } = yield call(getPaymentUpsellDetailsHandler, action);

  if (response) {
    navigateOnPaymentUpsellDetailsLoaded({
      payment: response.payment,
      upsellItems: response.upsellItems,
      history,
      token,
    });

    return;
  }

  redirectToOfferExpiredPage(history, token, error?.code);
}
