import { useState } from 'react';

export type ErrorsState = {
  amount1: string;
  amount2: string;
};

const initialErrorsState = {
  amount1: '',
  amount2: '',
};

type InputType = {
  id: number;
  value: string;
};

type ReturnErrorsType = [ErrorsState, (InputType) => void, () => void];

export const useMicroDepositAmountValidation = (
  onChange: (input: InputType) => void
): ReturnErrorsType => {
  const [errors, setErrors] = useState(initialErrorsState);

  const handleChange = (input: InputType) => {
    const inputValue = +input.value.slice(1);
    const maxIntegerAmount = 1;

    if (inputValue >= maxIntegerAmount) {
      setErrors((prevState) => ({
        ...prevState,
        [input.id]: 'inputErrors.microDepositsVerification.invalidDeposit',
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [input.id]: initialErrorsState[input.id],
      }));
    }

    onChange(input);
  };

  const resetErrors = () => {
    setErrors(initialErrorsState);
  };

  return [errors, handleChange, resetErrors];
};
