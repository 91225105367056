export const parseBoolean = (value?: string) => value === 'true';
export const parseNumber = (value?: string) => {
  if (value === undefined) return value;

  const numberValue = +value;

  if (Number.isNaN(numberValue)) return undefined;

  return numberValue;
};
