import React, { useCallback, useEffect, useState } from 'react';
import { BillListItemWrapper } from 'src/app/components/list/BillsList/BillListItemWrapper';
import { CollapsableGroup } from 'src/app/components/list/BillsList/CollapsableGroup';
import styled from 'styled-components';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import { useBreak } from 'src/app/hoc';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { RecordOf } from 'immutable';
import { MIFormattedText } from 'src/app/utils/formatting';
import { QBOBillListEmptyState } from 'src/app/components/bill/QBOBillListEmptyState';
import { BillType, BillStatusType, BillsListProps } from 'src/app/utils/types';
import { getBillsTabs, groupPartialPaymentsByBill } from 'src/app/utils/bills';
import { BILL_STATUS, BILL_STATUSES, PAGINATION } from 'src/app/utils/consts';
import { useQueryString } from 'src/app/utils/hooks';
import analytics from 'src/app/services/analytics';
import usePagination from 'src/app/core/components/pagination/hooks/usePagination';
import Pagination from 'src/app/core/components/pagination/Pagination';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import Box from 'src/app/core/ds/box';
import { AreaLoader } from '@melio/billpay-design-system';
import BillGroupHeader from './BillGroupHeader';
import Tabs from './Tabs';

type Props = {
  hideVendorNames?: boolean;
  listBaseSearch?: string;
  listItemBaseSearch?: string;
  pathname: string;
  isLoading: boolean;
  goCreateBill: (event: React.SyntheticEvent<HTMLInputElement>) => void | string | Promise<void>;
  billsCount: number;
  updateIsClosedBillsZeroStatePreference: () => void;
} & BillsListProps;

const eventPage = 'bills-list';

const BillsList: React.FC<Props> = ({
  hideVendorNames = false,
  listBaseSearch = '',
  listItemBaseSearch = '',
  filters,
  bills,
  groupedBills,
  isLoading,
  totalCount,
  pathname,
  billsCount,
  goCreateBill,
  updateIsClosedBillsZeroStatePreference,
}) => {
  const query = useQueryString();
  const [historyPush] = useHistoryWithOrgId();
  const { isMobile } = useBreak();
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const [mobileCollapsedId, setMobileCollapsedId] = useState('');

  useEffect(() => {
    if (!query.id) {
      setMobileCollapsedId('');
    }
  }, [query.id]);

  const getSelectedTabBillStatus = (): BillStatusType => {
    const isStatusValid =
      filters && filters.status && BILL_STATUSES.some((status) => status === filters.status);

    return isStatusValid ? (filters.status as BillStatusType) : BILL_STATUS.UNPAID;
  };

  const selectedTabBillStatus = getSelectedTabBillStatus();

  const setPage = useCallback(
    (pageIndex: number) => {
      historyPush({
        path: pathname,
        query: {
          ...query,
          start: pageIndex * PAGINATION.DEFAULT_LIMIT,
          limit: PAGINATION.DEFAULT_LIMIT,
          status: selectedTabBillStatus,
          id: '',
        },
      });
    },
    [historyPush, pathname, query, selectedTabBillStatus]
  );

  const {
    canNextPage,
    canPreviousPage,
    previousPage,
    nextPage,
    showPagination,
    pageIndex,
    setPaginationState,
  } = usePagination({
    totalItems: totalCount,
    setPage,
    start: filters.start,
    limit: filters.limit,
  });

  useEffect(() => {
    if (selectedTabBillStatus) {
      setPaginationState(pageIndex);
    }
  }, [pageIndex, selectedTabBillStatus, setPaginationState]);

  const onZeroStateAction = () => {
    analytics.track(eventPage, 'zero-state-ok-cliked');

    if (updateIsClosedBillsZeroStatePreference) {
      updateIsClosedBillsZeroStatePreference();
    }
  };

  const renderBillItemWrapper = (bill, listItemBaseSearch, collapsed = false) => {
    const isSetMobilePaymentsCollapsed =
      collapsed && isMobile && filters.status === BILL_STATUS.PAID && isPartialPaymentsEnabled;

    const onClick = isSetMobilePaymentsCollapsed ? () => setMobileCollapsedId(bill.id) : undefined;

    return (
      <BillListItemWrapper
        key={bill.id}
        bill={bill}
        isPartialPaymentsEnabled={isPartialPaymentsEnabled}
        hideVendorNames={hideVendorNames}
        onClick={onClick}
      />
    );
  };

  const renderBillsGroup = (
    bills: (RecordOf<BillType> | BillType)[] | null,
    listItemBaseSearch?: string
  ) => {
    if (!bills) {
      return <></>;
    }

    if (isPartialPaymentsEnabled && filters.status === BILL_STATUS.PAID) {
      const groups = groupPartialPaymentsByBill(bills);

      return groups.map((payments, index) => {
        const ids = payments.map((payment) => payment.id);
        const collapsed = ids.length > 1 && !ids.includes(mobileCollapsedId);

        return (
          <CollapsableGroup key={index} collapsed={collapsed}>
            {payments.map((payment) =>
              renderBillItemWrapper(payment, listItemBaseSearch, collapsed)
            )}
          </CollapsableGroup>
        );
      });
    }

    return <>{bills.map((bill) => renderBillItemWrapper(bill, listItemBaseSearch))}</>;
  };

  const renderLabelPaidTab = (selectedTabBillStatus: BillStatusType) =>
    selectedTabBillStatus === BILL_STATUS.PAID && (
      <LabelPaidTab>
        <LabelPaidTabIcon className="icon-intuit-info-icon" />
        <TitlesGroup>
          <LabelPaidTabTitle>
            <MIFormattedText label="bills.labelPaidTab.title" />
          </LabelPaidTabTitle>
          <LabelPaidTabSubTitle>
            <MIFormattedText label="bills.labelPaidTab.subTitle" />
          </LabelPaidTabSubTitle>
        </TitlesGroup>
      </LabelPaidTab>
    );

  const allBillGroupsEmpty = every(groupedBills, isEmpty);
  const isShowEmptyBillList = !isLoading && !hideVendorNames && allBillGroupsEmpty;
  const tabs = getBillsTabs(BILL_STATUSES, selectedTabBillStatus, 'bills', listBaseSearch);

  return (
    <Container>
      <Tabs tabs={tabs} />
      <Content>
        {isLoading ? (
          <AreaLoader placement="list" />
        ) : (
          <>
            {renderLabelPaidTab(selectedTabBillStatus)}
            {isShowEmptyBillList && (
              <QBOBillListEmptyState
                selectedTabBillStatus={selectedTabBillStatus}
                filters={filters}
                bills={bills}
                billsCount={billsCount}
                goCreateBill={goCreateBill}
                onZeroStateAction={onZeroStateAction}
              />
            )}
            <BillListItemsContainer>
              {groupedBills.map((billGroup, idx) => (
                <BillGroupContainer key={idx}>
                  {billGroup.header && <BillGroupHeader header={billGroup.header} />}
                  {renderBillsGroup(billGroup.bills, listItemBaseSearch)}
                </BillGroupContainer>
              ))}
            </BillListItemsContainer>
            {showPagination && (
              <Box
                ml="auto"
                float={{
                  base: 'left',
                  md: 'right',
                }}
                py={4}
                h="4.4rem"
              >
                <Pagination
                  pageIndex={pageIndex}
                  totalItems={totalCount}
                  canNextPage={canNextPage}
                  canPreviousPage={canPreviousPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  pageItemCount={PAGINATION.DEFAULT_LIMIT}
                />
              </Box>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

const BillListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:last-of-type {
    margin-bottom: 0;
  }
`;

const BillGroupContainer = styled.div`
  margin-bottom: 3rem;
`;

export default BillsList;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Content = styled.div`
  width: 100%;
  position: relative;
`;

const LabelPaidTab = styled.div`
  display: none;
  ${(props) => props.theme?.pages?.BillsListPage?.LabelPaidTab}
`;

const LabelPaidTabTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.medium};
`;

const LabelPaidTabSubTitle = styled.div`
  margin-top: 0.5rem;
  ${(props) => props.theme.text.fontType.medium};
`;

const LabelPaidTabIcon = styled.i`
  font-size: 2.4rem;
  margin-right: 1.2rem;
  color: ${(props) => props.theme.text.color.link};
`;

const TitlesGroup = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.text.color.main};
`;
