import { RecordOf } from 'immutable';
import React from 'react';
import styled from 'styled-components';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodText,
  PaymentInfoText,
  EditElement,
} from 'src/app/components/layout/PaymentActivityElements';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';

type Props = {
  payment: RecordOf<JustPayPaymentType>;
  onEdit: () => void;
};

export const JustPayPaymentActivityDeductionDateInfo = ({ payment, onEdit }: Props) => (
  <PaymentActivityContainer>
    <PaymentActivityMainContainer>
      <PaymentInfoContainer>
        <PaymentIcon>
          <ScheduledIcon />
        </PaymentIcon>
        <PaymentTextContainer>
          <PaymentMethodText>
            <MIFormattedText label="payBillPaymentActivity.deductionDate.futureLabel" />
          </PaymentMethodText>
          <PaymentInfoText>
            <MIFormattedDate date={payment.suggestedDates?.scheduledDate} />
          </PaymentInfoText>
        </PaymentTextContainer>
      </PaymentInfoContainer>
      <EditElement onEdit={onEdit} />
    </PaymentActivityMainContainer>
  </PaymentActivityContainer>
);

const ScheduledIcon = styled.i.attrs({ className: 'icon-scheduled-icon' })``;
