import { createSlice } from '@reduxjs/toolkit';
import { name } from './consts';

const initialState = {
  viewPaymentUrls: {},
};

type Urls = {
  exitUrl?: string;
  payBillRedirectUrl?: string;
  unpaidTabpayBillRedirectUrl?: string;
};

type SetUrls = {
  payload: Urls;
};

type UrlsState = {
  viewPaymentUrls: Urls;
};

type UrlsReducers = {
  setUrls: (state: UrlsState, action: SetUrls) => void;
  removeUrls: (state: UrlsState, action: Record<string, unknown>) => void;
};

const createViewPaymentUrlsSlice = () => {
  const slice = createSlice<UrlsState, UrlsReducers>({
    name: `[${name.toUpperCase()}] VIEW_PAYMENT_URLS`,
    initialState,
    reducers: {
      setUrls(state, { payload }) {
        state.viewPaymentUrls = payload;
      },
      removeUrls(state) {
        state.viewPaymentUrls = {};
      },
    },
  });

  return {
    ...slice,
    selectors: {
      urls: (state) => state[name]?.viewPaymentUrls || {},
    },
    dispatchers: {
      setUrls: (dispatch) => (params) => dispatch(slice.actions.setUrls(params)),
      removeUrls: (dispatch) => () => dispatch(slice.actions.removeUrls()),
    },
  };
};

const viewPaymentUrlsSlice = createViewPaymentUrlsSlice();

export { viewPaymentUrlsSlice };
