export const enum OrganizationCreateOriginEnum {
  QBO_INTEGRATION = 'qbo-integration',
  ADD_COMPANY = 'add-company',
  USER_SIGNUP = 'user-signup',
  GUEST = 'guest',
  QBR_IOS = 'qbr-ios',
  QBR_ANDROID = 'qbr-android',
  QBM_IOS = 'qbm-ios',
  QBM_ANDROID = 'qbm-android',
  QBDT_INTEGRATION = 'qbdt-integration',
  QBDT_WINDOWS = 'qbdt-windows',
  QBDT_MAC = 'qbdt-mac',
}
