import { createSlice } from '@reduxjs/toolkit';

const name = 'flags';

type FlagsStoreState = {
  is2FARequired: boolean;
  is2FAStarted: boolean;
  isFullOrgSyncRequired: boolean;
  isInternationalEntryPoint: boolean;
};

const FLAGS_SLICE_NAME = `[${name.toUpperCase()}]`;

const initialState: FlagsStoreState = {
  is2FARequired: false,
  is2FAStarted: false,
  isFullOrgSyncRequired: false,
  isInternationalEntryPoint: false,
};

const flagsStore = createSlice({
  name: FLAGS_SLICE_NAME,
  initialState,
  reducers: {
    set2FARequired: (state, action) => {
      state.is2FARequired = action.payload;
    },
    set2FAStarted: (state, action) => {
      state.is2FAStarted = action.payload;
    },
    setFullOrgSyncRequired: (state, action) => {
      state.isFullOrgSyncRequired = action.payload;
    },
    setIsInternationalEntryPoint: (state, action) => {
      state.isInternationalEntryPoint = action.payload;
    },
  },
});

export { flagsStore };
