import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.space.ds.xl};
  gap: ${({ theme }) => theme.space.ds.lg};
  padding: ${({ theme }) => theme.space.ds.lg};
  background-color: #fafafa;
  border-radius: ${({ theme }) => theme.radii.ds.lg};
  ${({ theme }) => theme.textStyles.ds.body1};
`;

export const IconContainer = styled.div`
  width: 2.4rem;
  height: 2.4rem;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.ds.black};
`;

export const Link = styled.div<{ isEnabled: boolean }>`
  color: ${({ theme }) => theme.colors.ds.green[100]};
  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.5)};
  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'not-allowed')};
`;
