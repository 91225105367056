import { useSelector } from 'react-redux';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { getJWTPayload } from 'src/app/helpers/jwt';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { OrganizationType, PaymentType } from 'src/app/utils/types';

export type EditCheckAddressProps = {
  token: string;
};

export type EditCheckAddressState = {
  payment: PaymentType;
  organization: any;
  isPaymentLoading: boolean;
  isDeliveryMethodProcessing: boolean;
  updatingDeliveryMethodId?: string;
};

export function useEditCheckAddressStatus(props: EditCheckAddressProps) {
  const { token } = props;
  const { paymentId } = getJWTPayload(token);

  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const organization: OrganizationType = useSelector(
    organizationStore.selectors.byId(payment?.organization?.id)
  );
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor = useSelector(fetchSelector.byId(payment?.vendor?.id));

  return {
    paymentId,
    payment,
    isPaymentLoading,
    organization,
    vendor,
  };
}
