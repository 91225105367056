import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@melio/billpay-design-system';
import { devices } from 'src/app/theme/AppDevices';
import { buttonHeight } from 'src/app/theme/qboTheme';
import MIButton from 'src/app/components/common/MIButton';
import analytics from 'src/app/services/analytics';
import { MIFormattedText } from 'src/app/utils/formatting';
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  FAILED_PAYMENT_TYPE,
  PAYMENT_STATUS,
  PAYMENT_APPROVAL_STATUS,
} from 'src/app/utils/consts';
import {
  isVirtualCardPaymentExpired,
  isReturnedCheckPayment,
  isUndepositedCheck,
} from 'src/app/utils/payments';
import { isRecurringBill } from 'src/app/utils/bills';
import { BillType, PaymentType } from 'src/app/utils/types';
import { featureFlags } from '@melio/shared-web';
import {
  FeatureFlagsEnum,
  MetadataPaymentTypeEnum,
  PaymentCollectStatusEnum,
} from 'src/app/version-2/model/enums';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import { isMissingPaymentDeliveryMethod } from 'src/app/version-2/utils/deliveryMethod.utils';

export const enum RETRY_PAYMENT_TYPE {
  DEFAULT = 'default',
  CHANGE_DELIVERY_METHOD = 'change-delivery-method',
  RETRY_PAYMENT_DELIVERY = 'retry-payment-delivery',
  VIRTUAL_CARD_EXPIRED = 'virtual-card-expired',
  EXISTING_DELIVERY_METHOD = 'existing-delivery-method',
}

type Props = {
  bill: BillType;
  payment: PaymentType;
  goEditPayment: () => void;
  onCancelPayment: (props: any) => void;
  goExit: () => void;
  handleRetryPayment: (retryType: RETRY_PAYMENT_TYPE) => void;
  onMarkBillAsUnpaid: () => void;
  onResolveCheckReturn: () => void;
  onVoidCheckAndResendPayment: () => void;
  handleRefund?: () => void;
};

export const ActionsButtons = ({
  bill,
  payment,
  goEditPayment,
  onCancelPayment,
  goExit,
  onMarkBillAsUnpaid,
  handleRetryPayment,
  onResolveCheckReturn,
  onVoidCheckAndResendPayment,
  handleRefund,
}: Props) => {
  const [voidChecksEnabled] = featureFlags.useFeature('bp-void-check', false);
  const isPaymentAlreadyRetried = payment?.deliverStatus?.includes(PaymentCollectStatusEnum.RETRY);
  const isVoidCheck = voidChecksEnabled && !isPaymentAlreadyRetried && isUndepositedCheck(payment);
  const shouldShowRefund = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.SELF_SERVE_REFUND,
    false
  );
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const [isQboRefundPaymentFF] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_REFUND_PAYMENT,
    false
  );

  useEffect(() => {
    if (isVoidCheck)
      analytics.track('pay-bill', 'has-option-to-void', {
        billId: bill?.id,
        paymentId: payment?.id,
      });
  }, [isVoidCheck]);

  if (isVoidCheck) {
    return (
      <ActionOptionsContainerAuto>
        <Button onClick={onVoidCheckAndResendPayment} size="md" variant="secondary">
          <MIFormattedText label="bills.pay.confirm.undepositedCheck.cta" />
        </Button>
      </ActionOptionsContainerAuto>
    );
  }

  const isBillInternal = bill.internalBill;
  const isPaymentMarkedAsPaid = payment.manual;
  const isPaymentProcessed =
    [PAYMENT_STATUS.COMPLETED, PAYMENT_STATUS.IN_PROGRESS].includes(
      payment.status as PAYMENT_STATUS
    ) && !payment.manual;

  const isFinancingPayment = !!payment.financingAccount?.id;

  if (isBillInternal || (isPaymentProcessed && !isPaymentMarkedAsPaid) || isFinancingPayment) {
    return null;
  }

  const isPendingApproval = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isPaymentDeclined = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED;
  const isPaymentRefund = payment?.metadata?.paymentType === MetadataPaymentTypeEnum.REFUND;
  const isReturnedCheck = isReturnedCheckPayment(payment);
  const isRecurring = isRecurringBill(bill);
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);
  const [isReturnedCheckFeatureActive] = featureFlags.useFeature('bp-returned-check');

  if (isReturnedCheck) {
    if (isReturnedCheckFeatureActive) {
      return (
        <ActionOptionsContainer>
          <MIButton
            label="bills.form.paymentActivity.actions.resolvePayment"
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={onResolveCheckReturn}
            fullWidth
          />
        </ActionOptionsContainer>
      );
    }

    return null;
  }

  if (isPendingApproval) {
    return (
      <ActionOptionsContainer>
        <MIButton
          label="bills.form.paymentActivity.actions.cancel"
          variant={BUTTON_VARIANT.DESTRUCTIVE}
          onClick={onCancelPayment}
          fullWidth
        />
      </ActionOptionsContainer>
    );
  }

  if (isPaymentDeclined) {
    return (
      <ActionOptionsContainer>
        <MIButton
          label="bills.form.paymentActivity.actions.cancel"
          variant={BUTTON_VARIANT.DESTRUCTIVE}
          onClick={onCancelPayment}
          fullWidth
        />
        <MIButton
          label="bills.form.paymentActivity.actions.retryPaymentAction"
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={() => handleRetryPayment(RETRY_PAYMENT_TYPE.DEFAULT)}
          fullWidth
        />
      </ActionOptionsContainer>
    );
  }

  if (isPaymentMarkedAsPaid) {
    return (
      <ActionOptionsContainer>
        <MIButton
          label="bills.form.paymentActivity.actions.markAsUnpaid"
          variant={BUTTON_VARIANT.PRIMARY}
          onClick={onMarkBillAsUnpaid}
          fullWidth
        />
        <MIButton
          label="bills.form.paymentActivity.actions.close"
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={goExit}
          fullWidth
        />
      </ActionOptionsContainer>
    );
  }

  const isPaymentScheduled = [PAYMENT_STATUS.SCHEDULED, PAYMENT_STATUS.BLOCKED].includes(
    payment.status as PAYMENT_STATUS
  );

  if (isPaymentScheduled && isRecurring) {
    return (
      <ActionOptionsContainer>
        <MIButton
          label="bills.form.paymentActivity.actions.cancelRecurringPayment"
          variant={BUTTON_VARIANT.DESTRUCTIVE}
          onClick={onCancelPayment}
          fullWidth
          dropDownItems={[
            {
              label: 'bills.form.paymentActivity.actions.cancelAllRecurringPayments',
              onClick: () => onCancelPayment({ isCancelAllRecurringPayments: true }),
            },
          ]}
        />
        <MIButton
          label="bills.form.paymentActivity.actions.edit"
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={goEditPayment}
          fullWidth
        />
      </ActionOptionsContainer>
    );
  }

  if (isPaymentScheduled) {
    return (
      <ActionOptionsContainer>
        <MIButton
          label="bills.form.paymentActivity.actions.cancel"
          variant={BUTTON_VARIANT.DESTRUCTIVE}
          onClick={onCancelPayment}
          fullWidth
        />

        <MIButton
          label="bills.form.paymentActivity.actions.edit"
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={goEditPayment}
          fullWidth
        />
      </ActionOptionsContainer>
    );
  }

  if (payment?.metadata?.canUserRetry && isVirtualCardPaymentExpired(payment)) {
    return (
      <ActionOptionsContainer>
        <MIButton
          label="bills.form.paymentActivity.actions.close"
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={goExit}
          fullWidth
        />
        <MIButton
          label="bills.form.paymentActivity.actions.retryPaymentAction"
          variant={BUTTON_VARIANT.PRIMARY}
          onClick={() => handleRetryPayment(RETRY_PAYMENT_TYPE.VIRTUAL_CARD_EXPIRED)}
          fullWidth
        />
      </ActionOptionsContainer>
    );
  }

  if (isRetryFailedToDeliver && !shouldShowRefund) {
    // missing delivery method in failed to deliver (changed/deleted after failed)
    const missingDeliveryMethod = isMissingPaymentDeliveryMethod(payment);
    const retryType = missingDeliveryMethod
      ? RETRY_PAYMENT_TYPE.EXISTING_DELIVERY_METHOD
      : RETRY_PAYMENT_TYPE.RETRY_PAYMENT_DELIVERY;

    const retryAction = () => {
      analytics.track('RetryPayment', 'Clicked', {
        paymentId: payment?.id,
        deliveryMethodId: payment?.deliveryMethodId,
        buttonSource: 'main_button',
      });

      handleRetryPayment(retryType);
    };

    return (
      <ActionOptionsContainer>
        <MIButton
          label="bills.actions.retry"
          variant={BUTTON_VARIANT.PRIMARY}
          onClick={retryAction}
          fullWidth
        />
      </ActionOptionsContainer>
    );
  }

  const refundableMeta = payment?.metadata?.canUserRetry || payment?.metadata?.isPaymentRefundable;

  if (shouldShowRefund && refundableMeta) {
    const collectMessagesToRetry = [
      'accountFrozen',
      'uncollectedFunds',
      'invalidTransaction',
      'bankAccountUnauthorized',
      'userBlockedMelioAccount',
      'exceedsWithdrawalAmountLimit',
    ];

    if (payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER) {
      const { deliveryMethod } = payment;
      const missingDeliveryMethod = !deliveryMethod || Boolean(deliveryMethod?.deletedAt);
      const retryType = missingDeliveryMethod
        ? RETRY_PAYMENT_TYPE.CHANGE_DELIVERY_METHOD
        : RETRY_PAYMENT_TYPE.RETRY_PAYMENT_DELIVERY;
      const shouldShowRefundButton =
        payment.metadata?.isPaymentRefundable &&
        handleRefund &&
        isQboRefundPaymentFF &&
        isRefundFlowFeature;
      const retryAction = () => handleRetryPayment(retryType);

      return (
        <ActionOptionsContainer>
          {shouldShowRefundButton && (
            <MIButton
              label="bills.actions.refund"
              variant={BUTTON_VARIANT.SECONDARY}
              onClick={handleRefund}
              fullWidth
            />
          )}
          <MIButton
            label={`bills.actions.retry${!isRefundFlowFeature ? 'PaymentDelivery' : ''}`}
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={retryAction}
            fullWidth
          />
        </ActionOptionsContainer>
      );
    }

    if (
      payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_COLLECT &&
      collectMessagesToRetry.includes(payment?.metadata?.failureMessage as string)
    ) {
      return (
        <ActionOptionsContainer>
          <MIButton
            label="bills.actions.retryPayment"
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={() => handleRetryPayment(RETRY_PAYMENT_TYPE.DEFAULT)}
            fullWidth
          />
        </ActionOptionsContainer>
      );
    }

    return (
      <ActionOptionsContainer>
        <MIButton
          label="bills.form.paymentActivity.actions.retryPayment"
          variant={BUTTON_VARIANT.PRIMARY}
          onClick={() => handleRetryPayment(RETRY_PAYMENT_TYPE.DEFAULT)}
          fullWidth
        />
        <MIButton
          label="bills.form.paymentActivity.actions.cancel"
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={onCancelPayment}
          fullWidth
        />
      </ActionOptionsContainer>
    );
  }

  return null;
};

const ActionOptionsContainer = styled.div.attrs({ 'data-testid': 'action-options-container' })`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 8rem;
  margin-bottom: -14.6rem;
  margin-top: 11rem;
  flex-direction: row;

  & button {
    padding: 0;
    margin-right: 1rem;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.8rem;
    border-top: 0.4rem solid ${(props) => props.theme.background.default};
    bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    > button {
      margin-bottom: 1rem;
      ${buttonHeight[BUTTON_SIZE.NORMAL]};
    }
  }
`;

const ActionOptionsContainerAuto = styled.div`
  text-align: center;
  margin: 0 auto;
  position: relative;
  bottom: 8rem;
  margin-bottom: -14.6rem;
  margin-top: 11rem;

  @media ${devices.mobile}, ${devices.phablet} {
    width: auto;
    padding-top: 1.8rem;
    bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 2rem;
    > button {
      ${buttonHeight[BUTTON_SIZE.NORMAL]};
    }
  }
`;
