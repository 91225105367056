import { putRequest, fetchRequest, postRequest } from './api';

export default {
  fetch(userId) {
    const url = `/users/${userId}`;

    return fetchRequest(url);
  },
  update(user) {
    const url = `/users/${user.id}`;

    return putRequest(url, user).then((res) => res.user);
  },

  trackEvent(
    userId: string,
    eventName: string,
    properties: {
      table: string;
      id: string;
      key: string;
      value: any;
    }
  ) {
    const url = `/users/${userId}/track`;

    return postRequest(url, { eventName, properties });
  },
};
