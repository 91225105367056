import React from 'react';
import { RecordOf } from 'immutable';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BILL_STATUS, CONSTS, PAYMENT_STATUS } from 'src/app/utils/consts';
import { BillType, DeliveryMethodType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import {
  MainPaymentActivityContainer,
  BlockTitleContainer,
} from 'src/app/components/layout/PaymentActivityElements';
import { FundingSource } from 'src/app/version-2/model/dtos';
import ViewBillPaymentActivityDeductionDateInfo from './components/ViewBillPaymentActivityDeductionDateInfo';
import ViewBillPaymentActivityDeliveryDateInfo from './components/ViewBillPaymentActivityDeliveryDateInfo';
import { ViewBillPaymentActivityDeliveryMethodInfo } from './components/ViewBillPaymentActivityDeliveryMethodInfo';
import ViewBillPaymentActivityMemoInfo from './components/ViewBillPaymentActivityMemoInfo';
import ViewBillPaymentActivityFundingSourceInfo from './components/ViewBillPaymentActivityFundingSourceInfo';
import { FailedPaymentActivity } from './components/FailedPaymentActivity';

type Props = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: FundingSource;
  qbCashState?: QBCashStateType;
};

const ViewBillPaymentActivity = ({
  bill,
  payment,
  deliveryMethod,
  fundingSource,
  qbCashState,
}: Props) => {
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const isPaymentInProgress = payment.status === PAYMENT_STATUS.IN_PROGRESS;
  const isPaymentFailed = payment.status === PAYMENT_STATUS.FAILED;
  const isVirtualDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;
  const isDirectPayment = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;

  let payFromLabel;
  let receiveLabel;

  if (isBillPaid && !isPaymentInProgress) {
    payFromLabel = 'viewBillPaymentActivity.paidFromTitle';
    receiveLabel = 'viewBillPaymentActivity.receivedTitle';
  } else {
    payFromLabel = 'viewBillPaymentActivity.payFromTitle';
    receiveLabel = 'viewBillPaymentActivity.receiveTitle';
  }

  const isSharedVendor = bill.isVendorRequest();

  if (isPaymentFailed) {
    return (
      <FailedPaymentActivity
        payment={payment}
        fundingSource={fundingSource}
        deliveryMethod={deliveryMethod}
        bill={bill}
        qbCashState={qbCashState}
      />
    );
  }

  return (
    <>
      <BlockTitleContainer data-testid="billpay-view-title-container">
        <MIFormattedText label={payFromLabel} />
      </BlockTitleContainer>
      <MainPaymentActivityContainer data-testid="billpay-view-activity-container">
        <ViewBillPaymentActivityFundingSourceInfo
          fundingSource={fundingSource}
          qbCashState={qbCashState}
          bill={bill}
        />
        <ViewBillPaymentActivityDeductionDateInfo
          bill={bill}
          payment={payment}
          deliveryMethod={deliveryMethod}
        />

        {isSharedVendor && (
          <ViewBillPaymentActivityDeliveryDateInfo
            bill={bill}
            payment={payment}
            deliveryMethod={deliveryMethod}
            fundingSource={fundingSource}
          />
        )}
      </MainPaymentActivityContainer>

      {!isSharedVendor && (
        <>
          <BlockTitleContainer>
            <MIFormattedText label={receiveLabel} />
          </BlockTitleContainer>
          <MainPaymentActivityContainer>
            {!isVirtualDeliveryMethod && (
              <ViewBillPaymentActivityDeliveryMethodInfo
                deliveryMethod={deliveryMethod}
                bill={bill}
                payment={payment}
              />
            )}
            <ViewBillPaymentActivityDeliveryDateInfo
              bill={bill}
              payment={payment}
              deliveryMethod={deliveryMethod}
              fundingSource={fundingSource}
            />
          </MainPaymentActivityContainer>
        </>
      )}
      {!isDirectPayment && <ViewBillPaymentActivityMemoInfo payment={payment} />}
    </>
  );
};

export default ViewBillPaymentActivity;
