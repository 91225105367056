import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SmartIcon, Tooltip } from '@melio/billpay-design-system';
import { analytics } from 'src/app/version-2/externals';
import {
  BATCH_BULK_EVENT_PAGE,
  BILL_DETAILS_CLICK,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import {
  BatchBulkItem,
  BatchBulkPaymentIntentItem,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { HandCursorWrapper } from './renderers.styles';

export const BillDetailsRenderer: FC<any> = (props) => {
  const { row } = props;
  const { id, type, vendorId, totalBillIds, totalPartialBillIds } = row.original as BatchBulkItem;
  const { subRows, billId } = row.original as BatchBulkPaymentIntentItem;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onClick = useCallback(() => {
    dispatch(
      batchBulkActions.setBillDetailsModalAsOpen({
        id: type === BatchBulkItemEnum.BILL ? id : ((subRows?.[0]?.id || billId) as string),
      })
    );
    analytics.track(BATCH_BULK_EVENT_PAGE, BILL_DETAILS_CLICK, {
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
    });
  }, [totalBillIds, totalPartialBillIds, vendorId]);

  if (type !== BatchBulkItemEnum.BILL && (subRows?.length || 0) > 1) {
    return null;
  }

  return (
    <Tooltip label={formatMessage({ id: 'batchBulkPage.renderers.billDetails.tooltip' })}>
      <HandCursorWrapper
        onClick={onClick}
        className="disappearing-icon-wrapper"
        datat-testid="bill-details-icon"
      >
        <SmartIcon type="inspect" />
      </HandCursorWrapper>
    </Tooltip>
  );
};
