import React from 'react';
import { NoOptionsProps } from 'src/app/components/common/DropDown/MIDropDown';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  noOptionsLabel?: string;
} & NoOptionsProps;

const NoOptions = (props: Props) =>
  isEmpty(props.text) && props.noOptionsLabel ? (
    <NoOptionsWrapper>
      <MIFormattedText label={props.noOptionsLabel} />
    </NoOptionsWrapper>
  ) : null;

const NoOptionsWrapper = styled.div`
  height: 4.8rem;
  line-height: 4.8rem;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.main};
`;

export default NoOptions;
