import { useEffect } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Input } from '@melio/billpay-design-system';
import { analytics, QBOLayoutPage } from 'src/app/version-2/externals';
import { ReactComponent as QBLogo } from 'src/app/version-2/images/qb-logo.svg';
import {
  GLOBAL_OPT_IN_PAGE,
  PERSONAL_DETAILS_CTA_CLICK,
  PERSONAL_DETAILS_FORM_ERROR,
  PERSONAL_DETAILS_PAGE_EVENT_NAME,
} from 'src/app/version-2/pages/global-opt-in-details/model/consts/GlobalOptInAnalytics.consts';
import { globalOptInFormFieldNamesEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInFormFieldNamesEnum.enum';
import { globalOptInSteps } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInSteps.enum';
import { OptInPersonalDetailsFormData } from 'src/app/version-2/pages/global-opt-in-details/model/objects/optInPersonalDetailsFormData';
import { personalDetailsSchema } from 'src/app/version-2/pages/global-opt-in-details/model/schemas/personalDetailsValidation.schema';
import {
  globalOptInSelectors,
  globalOptInSliceActions,
} from 'src/app/version-2/pages/global-opt-in-details/modules/globalOptIn.slice';
import { transformErrorsToAnalyticsFormat } from 'src/app/version-2/pages/global-opt-in-details/utils/globalOptInDetailsAnalytics.utils';
import {
  cardStyle,
  defaultInputStyle,
  fullNameContainer,
  logoStyle,
  separatorStyle,
  titleStyle,
  yourDetails,
} from './OptInPersonalDetails.styles';

export const OptInPersonalDetails = () => {
  const personalDetailsValue = useSelector(globalOptInSelectors.selectPersonalDetailsData);
  const { token } = useRouteMatch<{ token: string; id: string }>().params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<OptInPersonalDetailsFormData>({
    resolver: yupResolver(personalDetailsSchema),
    defaultValues: personalDetailsValue,
  });

  const onSubmit = (formData) => {
    analytics.track(GLOBAL_OPT_IN_PAGE, PERSONAL_DETAILS_CTA_CLICK);
    dispatch(
      globalOptInSliceActions.continuePersonalDetailsForm({
        history,
        personalDetailsFormData: formData,
        optInActiveSteps: globalOptInSteps.businessDetails,
        token,
      })
    );
  };

  const onValidationErrors = (validationErrors: FieldErrors<OptInPersonalDetailsFormData>) => {
    const transformedValidationErrors = transformErrorsToAnalyticsFormat({
      validationErrors,
      stepName: globalOptInSteps.personalDetails,
      formatMessage,
    });

    analytics.trackAction(PERSONAL_DETAILS_FORM_ERROR, {
      error: { ...transformedValidationErrors },
    });
  };

  const firstNameError = errors[globalOptInFormFieldNamesEnum.firstName];
  const lastNameError = errors[globalOptInFormFieldNamesEnum.lastName];
  const phoneError = errors[globalOptInFormFieldNamesEnum.phone];
  const emailError = errors[globalOptInFormFieldNamesEnum.email];

  useEffect(() => {
    analytics.page(GLOBAL_OPT_IN_PAGE, PERSONAL_DETAILS_PAGE_EVENT_NAME);
  }, []);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success" innerSize={53}>
      <Box __css={logoStyle}>
        <QBLogo />
      </Box>
      <Box __css={cardStyle}>
        <Box __css={titleStyle}>
          {formatMessage({ id: 'vendors.globalOptInCandidates.content.personalDetailsTitle' })}
        </Box>
        <Box __css={separatorStyle} />
        <Box __css={yourDetails}>
          {formatMessage({ id: 'vendors.globalOptInCandidates.content.personalDetailsSubTitle' })}
        </Box>
        <Box __css={fullNameContainer}>
          <Input
            label={formatMessage({ id: 'vendors.globalOptInCandidates.form.firstName' })}
            type="text"
            {...register(globalOptInFormFieldNamesEnum.firstName)}
            errorMessage={firstNameError && formatMessage({ id: firstNameError.message })}
          />
          <Input
            label={formatMessage({ id: 'vendors.globalOptInCandidates.form.lastName' })}
            type="text"
            {...register(globalOptInFormFieldNamesEnum.lastName)}
            errorMessage={lastNameError && formatMessage({ id: lastNameError.message })}
          />
        </Box>
        <Box __css={defaultInputStyle}>
          <Input
            label={formatMessage({ id: 'vendors.globalOptInCandidates.form.email' })}
            type="email"
            {...register(globalOptInFormFieldNamesEnum.email)}
            errorMessage={emailError && formatMessage({ id: emailError.message })}
            helperText={formatMessage({
              id: 'vendors.globalOptInCandidates.content.emailHelperText',
            })}
          />
        </Box>
        <Box __css={defaultInputStyle}>
          <Input
            label={formatMessage({ id: 'vendors.globalOptInCandidates.form.phone' })}
            inputMode="tel"
            {...register(globalOptInFormFieldNamesEnum.phone)}
            errorMessage={phoneError && formatMessage({ id: phoneError.message })}
          />
        </Box>
        <Button variant="primary" size="md" onClick={handleSubmit(onSubmit, onValidationErrors)}>
          {formatMessage({ id: 'vendors.globalOptInCandidates.content.continue' })}
        </Button>
      </Box>
    </QBOLayoutPage>
  );
};
