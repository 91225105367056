import React from 'react';
import { useSelector } from 'react-redux';
import {
  DashboardListItemFundingSourceType,
  DashboardListItemType,
} from 'src/app/pages/qb-dashboard/types';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { FUNDING_SOURCE_ORIGIN } from 'src/app/utils/consts';
import QBCashMethod from './QBCashMethod';
import ACHMethod from './ACHMethod';
import CardMethod from './CardMethod';
import { FundingSourceType } from 'src/app/utils/types';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';

type Props = {
  item: DashboardListItemType;
};

const QBOPaymentMethodCell = ({ item }: Props) => {
  const fundingSource: FundingSourceType | DashboardListItemFundingSourceType =
    useSelector(fundingSourcesStore.selectors.byId(item.fundingSourceId)) || item.fundingSource;

  if (
    fundingSource?.fundingType === FundingSourceTypesEnum.ACH &&
    fundingSource?.origin === FUNDING_SOURCE_ORIGIN.QBCASH
  ) {
    return <QBCashMethod />;
  }

  if (fundingSource?.fundingType === FundingSourceTypesEnum.ACH) {
    return <ACHMethod fundingSource={fundingSource} />;
  }

  if (fundingSource?.fundingType === FundingSourceTypesEnum.CARD) {
    return <CardMethod fundingSource={fundingSource} />;
  }

  return null;
};

export default QBOPaymentMethodCell;
