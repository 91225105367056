import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import size from 'lodash/size';
import { RecordOf } from 'immutable';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import {
  BillType,
  DeliveryOptionType,
  OptionalDeliveryMethodsType,
  OrganizationPreferencesType,
} from 'src/app/utils/types';
import PaymentDatePicker from 'src/app/components/common/PaymentDatePicker';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { getDifferenceInBusinessDays, isAfterEndOfLife } from 'src/app/utils/dates';
import { MIFormattedCurrency, MIFormattedDate } from 'src/app/utils/formatting';
import { AreaLoader } from '@melio/billpay-design-system';
import { Link } from '@melio/billpay-design-system';
import analytics from 'src/app/services/analytics';
import {
  getPayment,
  isShownUSHolidayLatencyNotification,
} from 'src/app/redux/payBillWizard/selectors';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { getPartialBillId } from 'src/app/utils/bills';
import {
  CONSTS,
  NOTIFICATION_CARD_TYPES,
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
} from 'src/app/utils/consts';
import { isFastACHAllowed, getFastACHCase } from 'src/app/utils/delivery-methods';
import {
  selectFundingSourceAction,
  hideUSHolidayUSPCLatency,
} from 'src/app/redux/payBillWizard/actions';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { featureFlags } from '@melio/shared-web';
import PaymentDatePickerInfo from 'src/app/pages/bill/pay/components/PaymentDatePickerInfo';
import { FastAchExperimentCaseEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  QBONotificationCardWrapper,
  DateContainer,
  FastACHContainer,
} from './PayBillDatePage.styles';
import PayBillDatePageButtons from '../PayBillDatePageButtons/PayBillDatePageButtons';
import { useAmexVerification } from '../../hooks/useAmexVerification';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  goExit: () => void;
  onSelectDeliveryOption?: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    type?: string
  ) => void;
  deliveryOptions?: DeliveryOptionType[];
  onChange: (date: Date) => void;
  bill: RecordOf<BillType>;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  deliveryMethodType: OptionalDeliveryMethodsType;
  minScheduledDate?: Date;
  scheduledDate: Date;
  isLoading?: boolean;
  deliveryPreference?: string;
  headerLabel: string;
  title: string;
  subtitle?: string;
  relativeStep?: number;
  titleValues?: Record<string, any>;
  selectedFundingSource?: FundingSource;
  fundingSourceType?: string;
  loadSuggestedProcessingDate?: () => Promise<void>;
};

const PayBillDatePage = ({
  bill,
  deliveryDate,
  deliveryMethodType,
  scheduledDate,
  selectedFundingSource,
  onChange,
  deliveryOptions,
  onNext,
  onPrev,
  isLoading = false,
  goExit,
  deliveryPreference,
  minScheduledDate,
  maxDeliveryDate,
  onSelectDeliveryOption,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  subtitle,
  fundingSourceType,
  loadSuggestedProcessingDate,
}: Props) => {
  const payment = useSelector(getPayment);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const isFirstWave = !!organizationPreferences?.billPayFirstWaveUser;
  const showHolidayWarning = useSelector(isShownUSHolidayLatencyNotification);
  const [isNextDisabled, setIsNextDisabled] = useState(
    isAfterEndOfLife(scheduledDate, isFirstWave)
  );
  const site = useSiteContext();
  const [showHolidaysWarningLD] = featureFlags.useFeature(
    FeatureFlagsEnum.US_HOLIDAYS_CHECKS,
    false
  );
  const dispatch = useDispatch();
  const fastACHCase = useMemo(() => getFastACHCase(bill.dueDate), []);
  const isFastAllowed = useMemo(
    () => isFastACHAllowed(deliveryOptions || [], selectedFundingSource),
    [deliveryOptions]
  );
  const {
    shouldDisplayAmexVerification,
    openAmexModal: openModal,
    amexLoading,
    AmexModal,
  } = useAmexVerification();
  const [isShowOnlyFastACHPage, setIsShowOnlyFastACHPage] = useState(
    () => isFastAllowed && !!fastACHCase
  );

  useEffect(() => {
    const isPaymentWillBeLate =
      getDifferenceInBusinessDays(new Date(deliveryDate), new Date(bill.dueDate)) > 0;

    analytics.trackRoute('page-viewed', {
      billId: bill.id,
      isPaymentWillBeLate,
    });
  }, []);

  const onOpenDatepicker = useCallback(() => {
    analytics.track('pay-bill', 'edit-deduction-date', {
      partialBillId: getPartialBillId(bill),
      billId: bill.id,
    });
    setIsShowOnlyFastACHPage(false);
  }, []);

  const titleMap = {
    [FastAchExperimentCaseEnum.DUE_DATE_IN_MAX_TWO_DAYS]:
      'bills.pay.date.fastACHExperiment.onTimeTitle',
    [FastAchExperimentCaseEnum.DUE_DATE_HAS_PASSED]:
      'bills.pay.date.fastACHExperiment.deliverSoonerTitle',
  };
  const subtitleMap = {
    [FastAchExperimentCaseEnum.DUE_DATE_IN_MAX_TWO_DAYS]:
      'bills.pay.date.fastACHExperiment.onTimeSubtitle',
    [FastAchExperimentCaseEnum.DUE_DATE_HAS_PASSED]:
      'bills.pay.date.fastACHExperiment.deliverSoonerSubtitle',
  };

  const onSubmit = useCallback(async () => {
    if (await shouldDisplayAmexVerification(selectedFundingSource, bill.vendor?.id)) {
      openModal({
        vendorName: bill.vendor?.companyName,
        vendorId: bill.vendor?.id,
        bill,
      });

      return;
    }

    onNext?.();
  }, [shouldDisplayAmexVerification, selectedFundingSource, bill]);

  const closeHolidayWarning = () => {
    dispatch(hideUSHolidayUSPCLatency());
  };

  const goLearnMore = () => {
    dispatch(applicationActions.setFaqPanel({ isOpen: true, focusedQuestion: 20 }));
  };

  const holidaysNotificationProps =
    size(deliveryOptions) > 1
      ? {
          type: NOTIFICATION_CARD_TYPES.WARNING,
          title: { label: 'holidaysWarning.deductionDate.warning.title' },
          subtitle: {
            label: 'holidaysWarning.deductionDate.warning.subtitle',
            values: { link: <Link onClick={goLearnMore}>Learn more</Link> },
          },
        }
      : {
          type: NOTIFICATION_CARD_TYPES.INFO,
          title: { label: 'holidaysWarning.deductionDate.info.title' },
          subtitle: { label: 'holidaysWarning.deductionDate.info.subtitle' },
        };

  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={payment?.amount ?? bill.balance} />
      </strong>
    ),
    companyName: <strong>{bill.vendor.companyName}</strong>,
  };

  const onDateChange = ({ value }) => {
    onChange(value);
    setIsNextDisabled(isAfterEndOfLife(value, isFirstWave));
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  const onCreditCardSelected = (card: FundingSource) => {
    dispatch(selectFundingSourceAction(card.id));
  };

  return (
    <site.components.StepLayout
      headerLabel={headerLabel}
      headerLabelValues={headerLabelValues}
      title={isShowOnlyFastACHPage ? titleMap[fastACHCase as string] : title}
      titleValues={titleValues}
      subtitle={isShowOnlyFastACHPage ? subtitleMap[fastACHCase as string] : subtitle}
      subTitleValues={{
        date: (
          <DateContainer>
            <MIFormattedDate date={bill.dueDate} />
          </DateContainer>
        ),
      }}
      goExit={goExit}
      onPrev={onPrev}
      isHideActions
      isLoading={isLoading || amexLoading}
      innerSize={100}
      relativeStep={relativeStep}
    >
      <site.components.WizardElements.InnerWizardContentWrapper>
        {showHolidayWarning &&
          showHolidaysWarningLD &&
          deliveryMethodType === CONSTS.DELIVERY_TYPE.CHECK && (
            <QBONotificationCardWrapper>
              <QBONotificationCard {...holidaysNotificationProps} onClose={closeHolidayWarning} />
            </QBONotificationCardWrapper>
          )}

        {!isShowOnlyFastACHPage && (
          <PaymentDatePicker
            fundingSource={selectedFundingSource}
            deliveryPreference={deliveryPreference}
            deliveryDate={deliveryDate}
            maxDeliveryDate={maxDeliveryDate}
            deliveryMethodType={deliveryMethodType}
            scheduledDate={scheduledDate}
            totalAmount={bill.totalAmount ? bill.totalAmount.toString() : ''}
            minDate={minScheduledDate}
            dueDate={new Date(bill.dueDate)}
            onChange={onDateChange}
            deliveryOptions={deliveryOptions}
            onSelectDeliveryOption={onSelectDeliveryOption}
            fundingSourceType={fundingSourceType}
            eventPage="pay-bill-date-page"
            loadSuggestedProcessingDate={loadSuggestedProcessingDate}
            onCreditCardSelected={onCreditCardSelected}
            navigationOrigin={ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL}
            billId={bill.id}
          />
        )}
        {isShowOnlyFastACHPage && (
          <FastACHContainer>
            <PaymentDatePickerInfo
              fundingSource={selectedFundingSource}
              deliveryPreference={deliveryPreference}
              deliveryDate={deliveryDate}
              maxDeliveryDate={maxDeliveryDate}
              deliveryMethodType={deliveryMethodType}
              dueDate={new Date(bill.dueDate)}
              scheduledDate={scheduledDate}
              totalAmount={bill.totalAmount ? bill.totalAmount.toString() : ''}
              deliveryOptions={deliveryOptions || []}
              onSelectDeliveryOption={onSelectDeliveryOption}
              fundingSourceType={fundingSourceType}
            />
          </FastACHContainer>
        )}
        <PayBillDatePageButtons
          isSecondaryButton={!!isShowOnlyFastACHPage}
          primaryLabel="bills.pay.date.buttons.primary"
          handleNext={onSubmit}
          handleSecondary={onOpenDatepicker}
          secondaryLabel="bills.pay.date.buttons.secondary"
          isDisabled={isNextDisabled}
        />
        <AmexModal onNext={onNext} />
      </site.components.WizardElements.InnerWizardContentWrapper>
    </site.components.StepLayout>
  );
};

export default PayBillDatePage;
