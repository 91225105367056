const baseStyle = {
  lineHeight: '1.2',
  borderRadius: '3rem',
  fontWeight: 'ds.semi',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  border: 0,
  cursor: 'pointer',
  fontFamily: 'body',

  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
};

const variants = {
  primary: {
    backgroundColor: 'ds.green.200',
    color: 'ds.white',
    _hover: {
      backgroundColor: 'ds.green.300',
      _disabled: {
        backgroundColor: 'ds.green.200',
      },
    },
    _focus: {
      outline: '2px solid',
      outlineColor: 'ds.blue.200',
    },
    _active: {
      backgroundColor: 'ds.green.100',
    },
  },
  secondary: {
    color: 'ds.gray.100',
    border: '2px solid',
    borderColor: 'ds.gray.200',
    backgroundColor: 'unset',
    _hover: {
      backgroundColor: 'ds.gray.500',
    },
    _focus: {
      outline: '2px solid',
      outlineColor: 'ds.blue.200',
    },
    _active: {
      backgroundColor: 'ds.gray.600',
    },
  },
  tertiary: {
    color: 'ds.blue.200',
    backgroundColor: 'unset',
    _hover: {
      backgroundColor: 'ds.gray.800',
    },
    _focus: {
      outline: '2px solid',
      outlineColor: 'ds.blue.200',
    },
    _active: {
      backgroundColor: 'ds.gray.600',
    },
  },
  destructive: {
    backgroundColor: 'ds.red.200',
    color: 'ds.white',
    _hover: {
      backgroundColor: '#C6160F',
      _disabled: {
        backgroundColor: 'ds.red.200',
      },
    },
    _focus: {
      outline: '2px solid',
      outlineColor: 'ds.blue.200',
    },
    _active: {
      backgroundColor: 'ds.red.100',
    },
  },
  secondaryDestructive: {
    color: 'ds.red.100',
    border: '2px solid',
    borderColor: 'ds.red.200',
    backgroundColor: 'unset',
    _hover: {
      backgroundColor: 'ds.red.700',
    },
    _focus: {
      outline: '2px solid',
      outlineColor: 'ds.blue.200',
    },
    _active: {
      backgroundColor: '#F7D5D2',
    },
  },
  passivePrimary: {
    backgroundColor: 'ds.gray.100',
    color: 'ds.white',
    _hover: {
      backgroundColor: '#1C1D1E',
      _disabled: {
        backgroundColor: 'ds.gray.100',
      },
    },
    _focus: {
      outline: '2px solid',
      outlineColor: 'ds.blue.200',
    },
  },
};

const sizes = {
  sm: {
    minW: '8rem',
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    fontWeight: 'ds.semi',
    py: '0.4rem',
    px: '1.6rem',
    height: '2.4rem',
  },
  md: {
    minW: '1.1rem',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: 'ds.semi',
    py: '0.6rem',
    px: '2.4rem',
    height: '3.6rem',
  },
  lg: {
    minW: '1.1rem',
    fontSize: '2rem',
    lineHeight: '2.8rem',
    fontWeight: 'ds.semi',
    py: '1rem',
    px: '2.8rem',
    height: '4.8rem',
  },
};

const defaultProps = {
  variant: 'primary',
  size: 'sm',
};

export const ButtonTheme = {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
