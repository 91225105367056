import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { StackDivider, VStack } from '@melio/billpay-design-system';
import { useBreak } from 'src/app/hoc';
import { DATE_FORMATS } from 'src/app/utils/date-fns';
import { Upsell } from 'src/app/version-2/components/Upsell';
import {
  analytics,
  CardFieldsDesktop,
  CardFieldsMobile,
  InfoLayout,
  MIFormattedDate,
  MIFormattedText,
  pushNotification,
} from 'src/app/version-2/externals';
import { Organization } from 'src/app/version-2/model/dtos/organization';
import { Payment } from 'src/app/version-2/model/dtos/payment';
import { VirtualCard } from 'src/app/version-2/model/dtos/virtualCard';
import { NotificationVariantEnum } from 'src/app/version-2/model/enums/notificationVariant.enum';
import { VirtualCardPaymentState } from 'src/app/version-2/model/enums/virtualCardPaymentState.enum';
import { CardPreview } from 'src/app/version-2/pages/virtual-card-details/components/CardPreview';
import { HowToUseCardModal } from 'src/app/version-2/pages/virtual-card-details/components/HowToUseCardModal';
import { HowToUseVirtualCard } from 'src/app/version-2/pages/virtual-card-details/components/HowToUseVirtualCard';
import { VIRTUAL_CARD_PAYMENT_STATE_CHANGE_EVENT_NAME } from 'src/app/version-2/pages/virtual-card-details/model/consts/analytics.consts';
import { virtualCardDetailsSelectors } from 'src/app/version-2/pages/virtual-card-details/modules/virtualCardDetails.slice';
import { getPaymentStateChangedProperties } from 'src/app/version-2/pages/virtual-card-details/utils/analytics.utils';
import {
  CardFieldsContainer,
  ContainerStyle,
  FooterSection,
  OrganizationName,
  OrganizationSection,
  Section,
  VirtualCardDescription,
  VirtualCardEstimation,
} from './ValidCardLayout.styles';

import { format } from 'date-fns';

const notificationByField = {
  cardNumber: 'vendors.virtualCardDetails.valid.notifications.cardNumber',
  expiryDate: 'vendors.virtualCardDetails.valid.notifications.expiryDate',
  cvc: 'vendors.virtualCardDetails.valid.notifications.cvc',
  zipCode: 'vendors.virtualCardDetails.valid.notifications.zipCode',
  issuerAddress: 'vendors.virtualCardDetails.valid.notifications.issuerAddress',
};

interface ValidCardLayoutProps {
  payment: Payment;
  virtualCard: VirtualCard;
  organization: Organization;
  token: string;
  isGlobalOptInUpsellEnabled?: boolean;
}

export const ValidCardLayout = ({
  payment,
  virtualCard,
  organization,
  token,
  isGlobalOptInUpsellEnabled = false,
}: ValidCardLayoutProps) => {
  const { formatMessage } = useIntl();
  const analyticsProperties = getPaymentStateChangedProperties({
    payment,
    paymentState: VirtualCardPaymentState.Valid,
  });

  const isHowToUseCardModalOpen: boolean = useSelector(
    virtualCardDetailsSelectors.selectIsHowToUseCardModalOpen
  );

  const { isMobile } = useBreak();

  useEffect(() => {
    analytics.trackAction(VIRTUAL_CARD_PAYMENT_STATE_CHANGE_EVENT_NAME, analyticsProperties);
  }, []);

  const handleFieldCopy = (fieldName: string, value?: string) => {
    const notification = notificationByField[fieldName];

    if (value) {
      navigator.clipboard.writeText(String(value));
      analytics.trackAction('vendors.virtualCardDetails.valid.cta', {
        ...analyticsProperties,
        cardFieldName: fieldName,
      });

      if (notification) {
        pushNotification({
          type: NotificationVariantEnum.SUCCESS,
          msg: notification,
        });
      }
    }
  };

  const cardDetails = {
    number: virtualCard?.cardNumber,
    cvc: virtualCard?.cvv,
    exp:
      virtualCard?.expirationDate &&
      format(new Date(virtualCard.expirationDate), DATE_FORMATS.monthNumberSlashShortYear),
  };

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <HowToUseCardModal isOpen={isHowToUseCardModalOpen} />
      <VStack
        __css={ContainerStyle}
        spacing={isMobile ? 5 : 10}
        divider={<StackDivider borderColor="gray.400" borderStyle="solid" />}
      >
        <Section>
          <OrganizationSection>
            <OrganizationName>{organization?.companyName}</OrganizationName>
            <VirtualCardDescription>
              {formatMessage({
                id: 'vendors.virtualCardDetails.valid.sent',
              })}
            </VirtualCardDescription>
          </OrganizationSection>

          <CardPreview
            isGlobalOptInUpsellEnabled={isGlobalOptInUpsellEnabled}
            amount={payment?.amount ?? 0}
            cardDetails={cardDetails}
          />
          <CardFieldsContainer>
            {isMobile ? (
              <CardFieldsMobile cardDetails={cardDetails} onFieldCopy={handleFieldCopy} />
            ) : (
              <CardFieldsDesktop cardDetails={cardDetails} onFieldCopy={handleFieldCopy} />
            )}
          </CardFieldsContainer>
          <VirtualCardEstimation>
            <MIFormattedText
              label="vendors.virtualCardDetails.valid.estimate"
              values={{
                date: <MIFormattedDate date={virtualCard?.endDate} />,
              }}
            />
          </VirtualCardEstimation>
        </Section>
        <FooterSection>
          {isGlobalOptInUpsellEnabled ? (
            <Upsell paymentId={payment?.id} token={token} />
          ) : (
            <HowToUseVirtualCard />
          )}
        </FooterSection>
      </VStack>
    </InfoLayout>
  );
};
