const parts = ['container', 'control', 'label', 'icon'];

const baseStyleControl = {
  w: '100%',
  transition: 'none',
  border: '1px',
  borderRadius: 'ds.md',
  borderColor: 'ds.gray.400',
  boxSizing: 'border-box',
  backgroundColor: 'ds.white',
  fontFamily: 'body',

  _checked: {
    borderColor: 'ds.green.200',
    color: 'ds.green.200',
    _focus: {
      boxShadow: 'none',
      outlineColor: 'transparent',
    },
    _hover: {
      borderColor: 'ds.green.200',
      outlineColor: 'transparent',
    },
  },
  _hover: {
    bg: 'inherit',
  },

  _indeterminate: {
    borderColor: 'ds.green.200',
    color: 'ds.green.200',
    _hover: {
      borderColor: 'ds.green.200',
      outlineColor: 'transparent',
    },
  },

  _disabled: {
    borderColor: 'ds.gray.200',
    cursor: 'default',
  },

  _focus: {
    boxShadow: 'none',
    outlineColor: 'transparent',
  },

  '&:not([data-checked]):not([data-disabled])': {
    '&[aria-hidden]': {
      borderColor: 'ds.gray.400',
    },
  },
};

const baseStyleLabel = {
  userSelect: 'none',
  marginLeft: 'ds.md',
};

const baseStyleContainer = {
  borderRadius: 'ds.md',
  '.chakra-checkbox__control[data-disabled]': {
    '&[aria-hidden]': {
      backgroundColor: 'ds.gray.800',
      borderColor: 'ds.gray.600',
    },
  },
  _hover: {
    '.chakra-checkbox__control:not([data-checked]):not([data-disabled]):not([data-indeterminate])':
      {
        '&[aria-hidden]': {
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto',
        },
      },
    '.chakra-checkbox__control:not([data-indeterminate])': {
      '&[aria-hidden]': {
        borderColor: 'ds.gray.400',
      },
    },
    '.chakra-checkbox__control[data-disabled]': {
      '&[aria-hidden]': {
        borderColor: 'ds.gray.600',
      },
    },
  },
};

const baseStyle = {
  control: baseStyleControl,
  label: baseStyleLabel,
  container: baseStyleContainer,
};

const sizes = {
  md: {
    control: { w: 'ds.xl', h: 'ds.xl' },
    label: { textStyle: 'ds.body2' },
    icon: { fontSize: 10 },
  },
};

const defaultProps = {
  size: 'md',
};

export const CheckboxTheme = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
