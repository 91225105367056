import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';

import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS, NOTIFICATION_VARIANT, BUTTON_SIZE } from 'src/app/utils/consts';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { MIFormattedText } from 'src/app/utils/formatting';
import locations from 'src/app/utils/locations';
import { getDashboardItemId } from 'src/app/pages/qb-dashboard/utils';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import { hasRecurringFrequency } from 'src/app/utils/payments';
import analytics from 'src/app/services/analytics';

import { useBillModalState } from './hooks/useBillModalState';
import { BillModalForm } from './BillModalForm/BillModalForm';
import { BillModalTooltip } from './components/BillModalTooltip/BillModalTooltip';
import useDashboardListParams from '../../hooks/useDashboardListParams';
import { useLoadDashboardList } from '../../hooks/useLoadDashboardList';
import { useDashboardHighlightedItems } from '../../hooks/useDashboardHighlightedItems';
import { ANALYTICS_PAGE_NAME } from './utils';

type OnBillSavedProps = { billId: string; vendorId: string; isNewVendor: boolean };

enum BUTTON_ACTION_TYPES {
  SAVE = 'save',
  SCHEDULE = 'schedule',
  CANCEL = 'cancel',
}

const TEXTS_PREFIX = 'paymentDashboard.addBillToDashboard.billModal';

const BillModalDialog = ({ dismiss, billId }: { dismiss: () => void; billId: string }) => {
  const [showRecurring] = featureFlags.useFeature('qb-recurring-payments');
  const [historyPush] = useHistoryWithOrgId();
  const { redirectToDashboard, fetchRedirectQuery } = useRedirectToDashboard();
  const [listParams] = useDashboardListParams();
  const { setHighlightedItemIds } = useDashboardHighlightedItems();
  const { loadDashboardList } = useLoadDashboardList();
  const [buttonActionType, setButtonActionType] = useState('');
  const [isMainDataLoading, setIsMainDataLoading] = useState(false);
  const location = useLocation();
  const isEditBillMode = !!billId;

  const onBillSavedAndSynced = ({ billId, vendorId, isNewVendor }: OnBillSavedProps) => {
    if (billId) {
      setTimeout(() => {
        dismiss();
      });

      const reportAnlytics = (title: string): void =>
        analytics.track(ANALYTICS_PAGE_NAME, title, {
          vendorId,
          isNewVendor,
          billId,
        });

      if (buttonActionType === BUTTON_ACTION_TYPES.SAVE) {
        pushNotification({
          type: NOTIFICATION_VARIANT.SUCCESS,
          msg: `${TEXTS_PREFIX}.notifications.billSaved`,
          textValues: {
            billId,
          },
        });

        reportAnlytics('save-and-close-click');

        setTimeout(() => {
          redirectToDashboardLogic(billId);
        });
      }

      if (buttonActionType === BUTTON_ACTION_TYPES.SCHEDULE) {
        reportAnlytics('schedule-payment-click');
        schedulePayment(billId);
      }
    } else {
      setButtonActionType('');
    }
  };

  const onEditBillSaved = (billId: string) => {
    if (billId) {
      setTimeout(() => {
        dismiss();
      });
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: `${TEXTS_PREFIX}.notifications.billUpdated`,
        textValues: {
          billId,
        },
      });
      analytics.track(ANALYTICS_PAGE_NAME, 'edit-bill-save-and-close-click', {
        vendorIdd: billModalState?.billModalMV?.vendorId?.value,
        billId,
      });
      setTimeout(() => {
        redirectToDashboardLogic(billId);
      });
    }
  };

  const onFailure = (errorType: string) => {
    const eventName =
      buttonActionType === BUTTON_ACTION_TYPES.SCHEDULE
        ? 'schedule-payment-failure'
        : 'save-and-close-failure';

    analytics.track(ANALYTICS_PAGE_NAME, eventName, { errorType });
    setButtonActionType('');
  };

  const billModalState = useBillModalState({
    onBillSavedAndSynced,
    onEditBillSaved,
    onFailure,
    billId,
    setIsMainDataLoading,
  });

  const isRecurring = hasRecurringFrequency(billModalState.billModalMV?.frequency?.value);

  const handleOnCloseClick = (dismiss) => {
    dismiss && dismiss();
  };

  const handleSaveAndCloseClick = async () => {
    setButtonActionType(BUTTON_ACTION_TYPES.SAVE);
    const eventName = isEditBillMode ? 'edit-bill-save-and-close-try' : 'save-and-close-try';

    analytics.track(ANALYTICS_PAGE_NAME, eventName);
  };

  const handleSchedulePaymentClick = async () => {
    setButtonActionType(BUTTON_ACTION_TYPES.SCHEDULE);
    analytics.track(ANALYTICS_PAGE_NAME, 'schedule-payment-try');
  };

  const saveBill = async () => {
    await billModalState.billModalMVActions.submit();
  };

  const redirectToDashboardLogic = async (billId: string) => {
    const redirectQuery = await fetchRedirectQuery([{ billId }]);

    if (
      listParams.filters.status === redirectQuery.status &&
      listParams.filters.start === redirectQuery.start
    ) {
      setHighlightedItemIds([getDashboardItemId(billId)]);
      loadDashboardList(listParams);
    } else {
      await redirectToDashboard({ itemIds: [{ billId }] });
    }
  };

  const schedulePayment = (billId) => {
    const redirectUrl = `${location.pathname}${location.search}`;

    setTimeout(() => {
      historyPush({
        path: locations.Bills.pay.funding.url({ id: billId }),
        state: {
          exitUrl: redirectUrl,
        },
      });
    });
  };

  useEffect(
    () => () => {
      setButtonActionType('');
    },
    []
  );

  useEffect(() => {
    if (buttonActionType) {
      saveBill();
    }
  }, [buttonActionType]);

  const getPrimayButtonProps = (isEditMode) =>
    isEditMode
      ? {
          label: `${TEXTS_PREFIX}.buttons.saveAndClose`,
          onClick: handleSaveAndCloseClick,
          isProcessing: billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SAVE,
          disabled:
            isMainDataLoading ||
            (billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SCHEDULE) ||
            !!billModalState.disableTooltip,
        }
      : {
          label: `${TEXTS_PREFIX}.buttons.schedulePayment`,
          onClick: handleSchedulePaymentClick,
          isProcessing:
            billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SCHEDULE,
          disabled:
            isMainDataLoading ||
            (billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SAVE) ||
            !!billModalState.disableTooltip,
        };

  const getSecondaryButtonProps = (isEditMode) =>
    isEditMode
      ? {
          label: `${TEXTS_PREFIX}.buttons.cancel`,
          onClick: dismiss,
          disabled:
            isMainDataLoading ||
            (billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SAVE) ||
            !!billModalState.disableTooltip,
        }
      : {
          label: `${TEXTS_PREFIX}.buttons.saveAndClose`,
          onClick: handleSaveAndCloseClick,
          isProcessing: billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SAVE,
          disabled:
            isMainDataLoading ||
            (billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SCHEDULE) ||
            !!billModalState.disableTooltip,
        };

  const titleProps = { label: `${TEXTS_PREFIX}.titles.main` };
  const primaryButtonProps = getPrimayButtonProps(isEditBillMode);
  const secondaryButtonProps = getSecondaryButtonProps(isEditBillMode);

  return (
    <StyledModalMessage
      titleComponent={
        <StyledModalTitle>
          <MIFormattedText {...titleProps} />
        </StyledModalTitle>
      }
      contentComponent={
        <BillModalForm
          isEditBillMode={isEditBillMode}
          vendorOptions={billModalState.vendorOptions}
          billModalMV={billModalState.billModalMV}
          categoryOptions={billModalState.categoryOptions}
          isLoading={isMainDataLoading}
        />
      }
      buttonComponent={
        <StyledButtonsContainer $hasBorder={!isEditBillMode && (showRecurring as boolean)}>
          {!isRecurring && (
            <StyledButton>
              <BillModalButton
                buttonVariant={CONSTS.BUTTON_VARIANT.SECONDARY}
                {...secondaryButtonProps}
              />
            </StyledButton>
          )}
          <BillModalTooltip
            isOpen={!!billModalState.disableTooltip}
            label={billModalState.disableTooltip as string | undefined}
          >
            <BillModalButton
              buttonVariant={CONSTS.BUTTON_VARIANT.PRIMARY}
              {...primaryButtonProps}
            />
          </BillModalTooltip>
        </StyledButtonsContainer>
      }
      onCloseClick={() => handleOnCloseClick(dismiss)}
    />
  );
};

const BillModalButton = ({
  label,
  buttonVariant,
  onClick,
  disabled = false,
  isProcessing = false,
}) => (
  <MIButton
    label={label}
    variant={buttonVariant}
    onClick={onClick}
    disabled={disabled}
    isProcessing={isProcessing}
    size={BUTTON_SIZE.NORMAL}
  />
);

const StyledModalMessage = styled(ModalMessage)`
  .modal-inner-content {
    margin-right: -2rem;
  }
`;

const StyledButton = styled.div`
  margin-inline-end: 2rem;
`;

const StyledModalTitle = styled.div`
  font-size: 2.4rem;
  font-weight: 900;
  color: ${(props) => props.theme.colors.text};
  line-height: 3.2rem;
  height: 3.2rem;
  padding-left: 0.8rem;
`;

const StyledButtonsContainer = styled.div<{ $hasBorder: boolean }>`
  display: flex;
  justify-content: right;
  ${(props) => props.$hasBorder && `border-top: 1px solid ${props.theme.colors.ds.gray['400']}`};
  margin: -40px -40px 0 -40px;
  padding: 40px 40px 0 0;
`;

export { BillModalDialog };
