import locations from 'src/app/utils/locations';
import AddPaymentMethodPage from './pages/add-payment-method';
import { PendingAccountVerification } from './pages/bank/pending-account-verification';
import CompletePage from './pages/complete-page';

export default [
  {
    path: locations.Onboarding.fundingSources.fee.checks.selectFundingSource.url(),
    component: AddPaymentMethodPage,
  },
  {
    path: locations.Onboarding.fundingSources.fee.checks.complete.url(),
    component: CompletePage,
  },
  {
    path: locations.Onboarding.fundingSources.fee.checks.bank.pendingBankVerification.url(),
    component: PendingAccountVerification,
  },
];
