import {
  createApiCallSlice,
  ON_FAILURE,
  ON_REQUEST,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import accountingSoftwareSync from 'src/app/services/api/accountingSoftwareSync';
import { FullOrgSyncStatusEnum } from 'src/app/version-2/model/enums';
import { name, State, CheckFullOrgSyncSuccessActionPayload } from './consts';

const initiateFullOrgSyncSlice = createApiCallSlice<number, State>({
  name: `[${name}] initiate-full-org-sync`.toUpperCase(),
  api: accountingSoftwareSync.syncQuickBooksData,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.status = FullOrgSyncStatusEnum.RUNNING;
    },
    [ON_FAILURE]: (state) => {
      state.status = FullOrgSyncStatusEnum.ERROR;
    },
  },
});

const checkFullOrgSyncStatusSlice = createApiCallSlice<
  number,
  State,
  CheckFullOrgSyncSuccessActionPayload
>({
  name: `[${name}] check-full-org-sync-status`.toUpperCase(),
  api: accountingSoftwareSync.syncQuickBooksDataStatus,
  reducers: {
    [ON_SUCCESS]: (state, action) => {
      state.status = action.payload.status;
    },
    [ON_FAILURE]: (state) => {
      state.status = FullOrgSyncStatusEnum.ERROR;
    },
  },
});

export { initiateFullOrgSyncSlice, checkFullOrgSyncStatusSlice };
