import { Flex } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const CardsContainer = styled(Flex)`
  width: 100%;
  max-width: 85.6rem;
  display: flex;
  gap: 2rem;
  margin: 0 auto;
`;
