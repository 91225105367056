import React from 'react';
import { VendorEmailField } from 'src/app/modules/vendors/vendor-email/components/VendorEmailField';
import { ModelView } from 'src/app/ui/form';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';
import { NOTE_FIELD_MAX_LENGTH } from 'src/app/version-2/model/constants';
import { MemoStepFormState } from '../types';

type Props = {
  formMV: ModelView<MemoStepFormState>;
  showEmailField: boolean;
  memoFieldProps?: {
    label?: string;
    placeholder?: string;
    notices?: string[];
  };
};

export const MemoStepFormFields = ({ formMV, showEmailField, memoFieldProps = {} }: Props) => (
  <>
    <WizardTextInputField
      label={memoFieldProps.label || 'bills.pay.memo.inputTitle'}
      placeholder={memoFieldProps.placeholder || 'bills.pay.memo.placeholder'}
      notices={memoFieldProps.notices || ['bills.pay.memo.hint']}
      autoFocus
      type="text"
      maxlength={NOTE_FIELD_MAX_LENGTH}
      model={formMV.memo}
      privateData
      size={TextInputSizeEnum.WIZARD}
    />
    {showEmailField && (
      <VendorEmailField
        value={formMV.contactEmail.value}
        onChange={formMV.contactEmail.onChange}
        validationError={formMV.contactEmail.error}
      />
    )}
  </>
);
