import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 2rem;
  }
  ${(props) => props.theme?.components?.FormElements?.FormContainer}
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  > * {
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1;
  }
  > *:first-child {
    margin-left: 0;
    margin-bottom: 0;
  }
  > *:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  ${(props) => props.theme?.components?.FormElements?.FormRow}
`;

const HintSection = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  background-color: ${(props) => props.theme.colors.border.quarterDarkGrey};
  color: ${(props) => props.theme.text.color.subtitle};
  padding: 1.6rem;
  border-radius: 8px;
  > * {
    margin-bottom: 2rem;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

const FormSectionText = styled.div`
  font-size: ${(props) => props.theme.text.size.sectionTitleM};
  color: ${(props) => props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-transform: uppercase;
`;

const FormSubtitle = styled.h2`
  opacity: 1;
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-align: left;
  @media ${devices.desktop}, ${devices.tablet} {
    font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  }

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: ${(props) => props.theme.text.size.wizardStepTitleMobile};
  }
`;

const FormButtons = styled.div`
  text-align: center;
  margin: 1rem 0 1.8rem;

  @media ${devices.mobile}, ${devices.phablet} {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 1rem 2rem;
    justify-content: space-between;
    box-sizing: border-box;

    > button {
      width: auto;
    }
    > button:nth-of-type(1) {
      padding-left: 2rem;
    }
  }

  & > button {
    @media ${devices.mobile} {
      line-height: 1rem;
    }
  }
`;

const FormButtonsDocked = styled(FormButtons)`
  @media ${devices.mobile}, ${devices.phablet} {
    background-color: ${(props) => props.theme.colors.white.opaque};
    position: fixed;
    box-shadow: 0 -0.4rem 1.5rem 0 rgba(0, 0, 0, 0.05);
    bottom: 0;
    right: 0;
  }
`;
const FormButtonsAlwaysDocked = styled(FormButtonsDocked)`
  @media ${devices.tablet}, ${devices.desktop} {
    margin: 0;
    padding: 2rem;
    box-shadow: none;
    border-top: 0.1rem solid ${(props) => props.theme.colors.border.lightGrey};
    background-color: ${(props) => props.theme.colors.white.opaque};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export {
  FormContainer,
  FormRow,
  FormSectionText,
  HintSection,
  FormSubtitle,
  FormButtons,
  FormButtonsDocked,
  FormButtonsAlwaysDocked,
};
