import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import { useApi } from 'src/app/hoc/useApi';
import { RecordOf } from 'immutable';

import { billListModifiedType } from 'src/app/pages/bill/pay/types';
import paymentStore from 'src/app/modules/payments/payment-store';

import { getOrgId } from 'src/app/redux/user/selectors';

import organizationsApi from 'src/app/services/api/organizations';
import { getBillListActions } from 'src/app/modules/batch-bills/batch-bills-store';

import { getDefaultMemo } from 'src/app/utils/bills';

type UseSetPaymentsMemoType = {
  setPaymentsMemo: () => Promise<void>;
};

const useSetPaymentsMemo = (): UseSetPaymentsMemoType => {
  const dispatch = useDispatch();
  const [getVendorsIntuitAcctNum] = useApi(organizationsApi.getVendorsIntuitAcctNum);
  const billActions = useMemo(() => getBillListActions(dispatch), [dispatch]);

  const orgId = useSelector(getOrgId);
  const billListModified: Record<string, RecordOf<billListModifiedType>> = useSelector(
    paymentStore.selectors.billListModified
  );

  const setPaymentsMemo = async () => {
    const bills = Object.values(billListModified).map((item: billListModifiedType) => item.bill);
    const intuitVendorOriginIds = uniq(bills.map((bill) => bill.vendor.originId));

    if (isEmpty(intuitVendorOriginIds)) {
      return;
    }

    const { vendors } = await getVendorsIntuitAcctNum(orgId, intuitVendorOriginIds);

    const vendorOriginIdToIntuitAcctNumber = keyBy(vendors, 'Id');

    bills.forEach((bill) =>
      billActions.updateBatchBillsNoteById({
        note: getDefaultMemo(
          bill.invoiceNumber,
          vendorOriginIdToIntuitAcctNumber[bill.vendor.originId]?.AcctNum
        ),
        billId: bill.id,
      })
    );
  };

  return {
    setPaymentsMemo,
  };
};

export default useSetPaymentsMemo;
