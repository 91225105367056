import React from 'react';
import { InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';

import { FieldWrapperType } from '../../types';

export type FieldProps = Pick<FieldWrapperType, 'leftAddon' | 'rightAddon' | 'rightAddonWidth'> & {
  id: string;
  // eslint-disable-next-line no-unused-vars
  input: (id: string) => React.ReactElement | null;
};

const Field: React.FC<FieldProps> = ({ id, input, leftAddon, rightAddon, rightAddonWidth }) => {
  const InputComponent = input(id);

  if (leftAddon || rightAddon) {
    return (
      <InputGroup>
        {leftAddon && <InputLeftElement>{leftAddon}</InputLeftElement>}
        {InputComponent}
        {rightAddon && <InputRightElement w={rightAddonWidth}>{rightAddon}</InputRightElement>}
      </InputGroup>
    );
  }

  return InputComponent;
};

export { Field };
