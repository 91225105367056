import { Box } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const Card = styled(Box)`
  width: 45rem;
  background: ${({ theme }) => theme.colors.ds.white};
  border-radius: ${({ theme }) => theme.radii.ds.lg};
  box-shadow: ${({ theme }) => theme.shadows.ds.md};
  padding: ${({ theme }) => theme.space.ds.xl};
  margin: 1rem;
  cursor: pointer;
  transition: all 200ms;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.ds.xl};
  }
`;
