import { useSelector } from 'react-redux';
import { CONSTS, PAY_EDIT_LOCATION_NAME, PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import {
  getBill,
  getPayment,
  getIsRecurring,
  getSelectedFundingSource,
} from 'src/app/redux/payBillWizard/selectors';
import { NavigateType } from 'src/app/utils/types';

import {
  isInternationalPayment,
  isPaymentFailed,
  isVirtualCardPayment,
} from 'src/app/utils/payments';
import { isSharedVendor } from 'src/app/pages/vendor/utils';
import { usePayBillNavigateCommon } from './usePayBillNavigateCommon';
import { useFundingSourceStatelessNavigate } from './useFundingSourceStatelessNavigate';

type Params = {
  navigate: NavigateType;
  getUrlAfterFundingSourceStepForRecoveryFlow: () => Promise<string>;
};

export const useFundingSourceNavigate = ({
  navigate,
  getUrlAfterFundingSourceStepForRecoveryFlow,
}: Params) => {
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const isRecurring = useSelector(getIsRecurring);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const { getUrl } = usePayBillNavigateCommon();
  const { goNextFundingSource } = useFundingSourceStatelessNavigate({ navigate });

  const getUrlAfterFundingSource = async () => {
    if (payment?.id && isPaymentFailed(payment)) {
      return getUrlAfterFundingSourceStepForRecoveryFlow();
    }

    if (isRecurring) {
      if (payment.deliveryMethodId) {
        return locations.Bills.pay.recurring.memo.url();
      }

      return locations.Bills.pay.recurring.deliveryMethod.url();
    }

    if (payment.deliveryMethodId) {
      if (payment.id) {
        const deliveryMethod = bill.getDeliveryMethodById(payment.deliveryMethodId);
        const isVirtualDeliveryMethod =
          deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;
        const isDeclinedPayment =
          payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED;
        const shouldSkipDeliveryMethodPage =
          isInternationalPayment(payment) ||
          isVirtualCardPayment(payment) ||
          bill.isVendorRequest() ||
          isVirtualDeliveryMethod ||
          isDeclinedPayment ||
          isSharedVendor(bill.vendor);

        return shouldSkipDeliveryMethodPage
          ? locations.Bills.pay.edit.date.url({
              id: bill.id,
              paymentId: payment.id,
            })
          : getUrl(PAY_EDIT_LOCATION_NAME.DELIVERY_METHOD);
      }

      return locations.Bills.pay.date.url({ id: bill.id });
    }

    return getUrl(PAY_EDIT_LOCATION_NAME.DELIVERY_METHOD);
  };

  const onNextFundingSources = async () => {
    const redirectUrl = await getUrlAfterFundingSource();
    const exitUrl = getUrl(PAY_EDIT_LOCATION_NAME.FUNDING);
    const isInternational = isInternationalPayment(payment);

    await goNextFundingSource({
      selectedFundingSource,
      exitUrl,
      bill,
      redirectUrl,
      isInternational,
    });
  };

  return { getUrlAfterFundingSource, onNextFundingSources };
};
