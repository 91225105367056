import { putRequest } from './api';

export function updateUserPreference(key: string, value: boolean | string | null): Promise<void> {
  const url = '/users/user-preferences';

  return putRequest(url, { key, value });
}

export default {
  updateUserPreference,
};
