import { datadogLogs } from '@datadog/browser-logs';
import { envApi } from 'src/app/version-2/api/env';
import { Logger } from 'src/app/version-2/api/loggers/Logger';
import { LoggerSessionContext } from 'src/app/version-2/api/loggers/LoggerSessionContext';
import { DebugLevelEnum } from 'src/app/version-2/model/enums';

const { logger } = datadogLogs;
let sessionContext: LoggerSessionContext = {
  userId: '',
  orgId: '',
};

export const dataDogLogger: Logger = {
  init() {
    const { clientToken, site } = envApi.getConfig().services.dataDog;

    datadogLogs.init({
      clientToken,
      site,
      service: 'bill-pay-qbo',
      env: process.env.NX_REACT_APP_ENV,
      sampleRate: 100,
      forwardErrorsToLogs: true,
      useCrossSiteSessionCookie: true,
    });
  },
  get debugLevel(): DebugLevelEnum {
    return DebugLevelEnum.INFO;
  },
  set sessionContext(value: LoggerSessionContext) {
    sessionContext = value;
  },
  debug(message?: any, ...optionalParams: any[]): void {
    logger.debug(message, { ...sessionContext, optionalParams });
  },
  log(message?: any, ...optionalParams: any[]): void {
    logger.log(message, { ...sessionContext, optionalParams });
  },
  info(message?: any, ...optionalParams: any[]): void {
    logger.info(message, { ...sessionContext, optionalParams });
  },
  warn(message?: any, ...optionalParams: any[]): void {
    logger.warn(message, { ...sessionContext, optionalParams });
  },
  error(message?: any, ...optionalParams: any[]): void {
    logger.error(message, { ...sessionContext, optionalParams });
  },
};
