import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import billsStore from 'src/app/modules/bills/bills-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { pushNotification } from 'src/app/services/notifications';
import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { useRedirectToDashboard } from './useRedirectToDashboard';

type Response = [() => void];

type Params = {
  id: string;
  billId: string;
  paymentId?: string;
};

const useMarkAsUnpaidAction = ({ billId, paymentId, id }: Params): Response => {
  const orgId = useSelector(getOrgId);
  const billsActions = useStoreActions(billsStore);
  const { redirectToDashboard, fetchRedirectQuery } = useRedirectToDashboard();
  const { markAsUnpaidStatus } = useStoreActions(qbDashboardListItemsStore);

  const markBillAsUnpaid = useCallback(async () => {
    markAsUnpaidStatus.setStatus({ id, processing: true });
    await billsActions.markAsPaid(
      {
        orgId,
        id: billId,
        isPaid: false,
        actionType: 'unpaid',
        paymentId,
      },
      { disableNotification: true }
    );

    const redirectQuery = await fetchRedirectQuery([{ billId }]);

    markAsUnpaidStatus.setStatus({ id, processing: false });
    pushNotification({
      type: NOTIFICATION_VARIANT.SUCCESS,
      msg: 'paymentDashboard.markAsPaid.toastMessageUnpaidSingle',
    });

    await redirectToDashboard({ redirectQuery });
  }, [billsActions, orgId, billId, paymentId, redirectToDashboard, fetchRedirectQuery, id]);

  return [markBillAsUnpaid];
};

export default useMarkAsUnpaidAction;
