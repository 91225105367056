import React from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { VStack } from '@melio/billpay-design-system';

import analytics from 'src/app/services/analytics';
import { getPayment, getBill, getIsLoading } from 'src/app/redux/payBillWizard/selectors';
import { getOrgId } from 'src/app/redux/user/selectors';
import { selectDeliveryMethodAction } from 'src/app/redux/payBillWizard/actions';
import { withNavigator } from 'src/app/hoc';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { DELIVERY_METHOD_ORIGIN, DELIVERY_TYPE } from 'src/app/utils/consts';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/app/utils/locations';
import { WizardOrLine } from 'src/app/components/layout/WizardElements';
import { AreaLoader } from '@melio/billpay-design-system';
import { getPartialBillId } from 'src/app/utils/bills';
import { DeliveryMethodType } from 'src/app/utils/types';
import { withPayBillData, PayBillProps } from '../hoc/withPayBillData';
import { VirtualCardDeliveryMethodOption } from './VirtualCardDeliveryMethodOption';
import { ACHDeliveryMethodOption } from './ACHDeliveryMethodOption';
import { VirtualDeliveryMethodOption } from './VirtualDeliveryMethodOption';

const eventPage = 'virtual-card-recovery';

const VirtualCardRecoveryFlowPage = ({ goExit }: PayBillProps) => {
  const payment = useSelector(getPayment);
  const bill = useSelector(getBill);
  const isBillDataLoading = useSelector(getIsLoading);
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();
  const history = useHistory();
  const vendorName = bill.vendor.companyName;
  const values = {
    amount: (
      <strong>
        <MIFormattedCurrency value={payment?.amount} />
      </strong>
    ),
    companyName: <strong>{vendorName}</strong>,
  };

  const titleValues = { vendor: vendorName };
  const achDeliveryMethod = bill.getDeliveryMethodByType(DELIVERY_TYPE.ACH);
  const virtualCardDeliveryMethod = bill.getDeliveryMethodByType(DELIVERY_TYPE.VIRTUAL_CARD);
  const virtualDeliveryMethod = bill.getDeliveryMethodByType(DELIVERY_TYPE.VIRTUAL);

  const selectDeliveryMethod = (deliveryMethod: DeliveryMethodType) => {
    dispatch(selectDeliveryMethodAction(deliveryMethod));

    historyPush({
      path: locations.Bills.pay.edit.confirm.url(),
      params: { id: bill.id, paymentId: payment.id },
    });
  };

  const navigateCreateDeliveryMethod = (url: string) => {
    const redirectUrl = generatePath(locations.Bills.pay.edit.confirm.url(), {
      orgId,
      id: bill.id,
      paymentId: payment.id,
    });
    const exitUrl = history.location.pathname;

    history.push({
      pathname: url,
      state: {
        origin: DELIVERY_METHOD_ORIGIN.PAY_BILL,
        exitUrl,
        redirectUrl,
        billId: bill.id,
      },
    });
  };

  const onVirtualCardSelected = () => {
    analytics.track(eventPage, 'select-delivery-method', {
      type: 'virtual-card',
      partialBillId: getPartialBillId(bill),
      vendorId: bill.vendor?.id,
      paymentId: payment.id,
    });

    selectDeliveryMethod(virtualCardDeliveryMethod);
  };

  const onAchSelected = () => {
    const eventType = achDeliveryMethod.id ? 'select-delivery-method' : 'create-delivery-method';

    analytics.track(eventPage, eventType, {
      type: 'ach',
      partialBillId: getPartialBillId(bill),
      vendorId: bill.vendor?.id,
      paymentId: payment.id,
    });

    if (achDeliveryMethod.id) {
      selectDeliveryMethod(achDeliveryMethod);
    } else {
      navigateCreateDeliveryMethod(
        locations.Vendors.deliveryMethods.ach.create.url({
          orgId,
          id: bill.vendorId,
        })
      );
    }
  };

  const onUnilateralSelected = () => {
    analytics.track(eventPage, 'create-delivery-method', {
      type: 'virtual',
      partialBillId: getPartialBillId(bill),
      vendorId: bill.vendor?.id,
      paymentId: payment.id,
    });
    navigateCreateDeliveryMethod(
      locations.Vendors.deliveryMethods.virtual.create.url({
        orgId,
        id: bill.vendorId,
      })
    );
  };

  const showVirtualDeliveryMethodOption = !achDeliveryMethod.id;
  const virtualDeliveryMethodEmail =
    virtualDeliveryMethod?.virtualAccount?.email || bill.vendor?.contactEmail;

  if (isBillDataLoading) {
    return <AreaLoader />;
  }

  return (
    <QBOLayoutPage
      headerLabel="qbo.header.title"
      headerLabelValues={values}
      title="bills.pay.virtualCardRecovery.title"
      titleValues={titleValues}
      relativeStep={2 / 5}
      goExit={goExit}
    >
      <VStack spacing={5} mx="4">
        <VirtualCardDeliveryMethodOption
          onClick={onVirtualCardSelected}
          vendorName={vendorName}
          deliveryMethod={virtualCardDeliveryMethod}
        />

        <ACHDeliveryMethodOption
          onClick={onAchSelected}
          vendorName={vendorName}
          deliveryMethod={achDeliveryMethod}
        />

        {showVirtualDeliveryMethodOption && (
          <>
            <WizardOrLine />
            <VirtualDeliveryMethodOption
              onClick={onUnilateralSelected}
              email={virtualDeliveryMethodEmail}
            />
          </>
        )}
      </VStack>
    </QBOLayoutPage>
  );
};

const VirtualCardRecoveryFlowPageWithHocs = compose(
  withNavigator(),
  withPayBillData()
)(VirtualCardRecoveryFlowPage);

export { VirtualCardRecoveryFlowPageWithHocs as VirtualCardRecoveryFlowPage };
