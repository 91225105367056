import { startOfDay, isAfter } from 'date-fns';
import { FAST_DELIVERY_TYPES } from 'src/app/utils/consts';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { DeliveryOptionType } from 'src/app/utils/types';

interface GetTitleParams {
  dueDate: string | number;
  deliveryOptions?: DeliveryOptionType[] | null;
}

interface GetSubtitleParams {
  isBulkPayment: boolean;
  dueDate: string | number;
  deliveryOptions?: DeliveryOptionType[] | null;
}

interface HeadingResult {
  label?: string;
  values?: Record<string, string | JSX.Element>;
}

const useDeliveryDateRecoveryHeadings = () => {
  const getRegularDeliveryDate = (deliveryOptions?: DeliveryOptionType[] | null) =>
    deliveryOptions?.find((deliveryOption) => !FAST_DELIVERY_TYPES.includes(deliveryOption.type))
      ?.deliveryDate;

  const getTitle = ({ dueDate, deliveryOptions }: GetTitleParams): HeadingResult => {
    const regularDeliveryDate = getRegularDeliveryDate(deliveryOptions);

    return regularDeliveryDate &&
      dueDate &&
      isAfter(startOfDay(new Date(regularDeliveryDate)), startOfDay(new Date(dueDate)))
      ? { label: 'bills.pay.common.recovery.chooseDeliveryDate.title.late' }
      : { label: 'bills.pay.common.recovery.chooseDeliveryDate.title.onTime' };
  };

  const getSubtitle = ({
    isBulkPayment,
    dueDate,
    deliveryOptions,
  }: GetSubtitleParams): HeadingResult => {
    const regularDeliveryDate = getRegularDeliveryDate(deliveryOptions);

    const predicateLabel = isBulkPayment
      ? 'bills.pay.common.recovery.chooseDeliveryDate.predicate.bulk'
      : 'bills.pay.common.recovery.chooseDeliveryDate.predicate.regular';
    const predicateValues = {
      dueDate: <MIFormattedDate date={dueDate} />,
    };

    const label =
      regularDeliveryDate &&
      dueDate &&
      isAfter(startOfDay(new Date(regularDeliveryDate)), startOfDay(new Date(dueDate)))
        ? 'bills.pay.common.recovery.chooseDeliveryDate.subtitle.late'
        : 'bills.pay.common.recovery.chooseDeliveryDate.subtitle.onTime';
    const values = {
      predicate: <MIFormattedText label={predicateLabel} values={predicateValues} />,
    };

    return { label, values };
  };

  return {
    getTitle,
    getSubtitle,
  };
};

export { useDeliveryDateRecoveryHeadings };
