import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';

import MIButton from 'src/app/components/common/MIButton';
import { useBreak } from 'src/app/hoc';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/app/utils/consts';

interface Props {
  primaryLabel: string;
  secondaryLabel?: string;
  isProcessing?: boolean;
  onPrimaryClick: () => void;
  onSecondaryClick?: () => void;
}

const ButtonsGroupComponent = ({
  primaryLabel,
  secondaryLabel,
  isProcessing,
  onPrimaryClick,
  onSecondaryClick,
}: Props) => {
  const { isMobile } = useBreak();

  return (
    <Flex direction="column" gridGap={2}>
      <Box onClick={onPrimaryClick}>
        <MIButton
          variant={BUTTON_VARIANT.PRIMARY}
          label={primaryLabel}
          size={isMobile ? BUTTON_SIZE.SMALL : BUTTON_SIZE.NORMAL}
          fullWidth
          isProcessing={isProcessing}
        />
      </Box>
      {secondaryLabel && (
        <Box onClick={onSecondaryClick}>
          <MIButton
            size={isMobile ? BUTTON_SIZE.SMALL : BUTTON_SIZE.NORMAL}
            variant={BUTTON_VARIANT.SECONDARY}
            label={secondaryLabel}
            fullWidth
          />
        </Box>
      )}
    </Flex>
  );
};

export { ButtonsGroupComponent };
