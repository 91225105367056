import * as React from 'react';
import styled from 'styled-components';
import { CONSTS } from 'src/app/utils/consts';
import MIButton from '../common/MIButton';
import { MIFormattedText } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import analytics from '../../services/analytics';

type Props = {
  fileStorageUrl?: string | null;
  filePreviewUrls?: string[] | null;
  onUploadRemove: () => void;
};

type State = {
  currentPage: number;
};

export default class InvoiceFilePreview extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentPage: 1,
    };
  }

  onNextPageClick = () => {
    analytics.track('bill', 'view-next-doc-page');
    const { currentPage } = this.state;

    this.setState({ currentPage: currentPage + 1 });
  };

  onPrevPageClick = () => {
    analytics.track('bill', 'view-prev-doc-page');
    const { currentPage } = this.state;

    this.setState({ currentPage: currentPage - 1 });
  };

  renderImageView = () => {
    const { currentPage } = this.state;
    const { fileStorageUrl, filePreviewUrls } = this.props;
    const src =
      filePreviewUrls && filePreviewUrls.length ? filePreviewUrls[currentPage - 1] : fileStorageUrl;

    return <LoadedBillImg src={src as string} />;
  };

  renderPagination = (numPages: number) => {
    const { currentPage } = this.state;

    return (
      <PaginationInvoiceCommandBar>
        <PaginationInvoiceWrapper>
          <PaginationItemNavLink onClick={this.onPrevPageClick} isDisabled={currentPage === 1}>
            <i className="icon-prev-page-icon" />
          </PaginationItemNavLink>
          <PaginationInvoiceText>
            <MIFormattedText
              label="bills.new.pdfPagination"
              values={{ current: currentPage, total: numPages, page: 'Page' }}
            />
          </PaginationInvoiceText>
          <PaginationItemNavLink
            onClick={this.onNextPageClick}
            isDisabled={currentPage === numPages}
          >
            <i className="icon-next-page-icon" />
          </PaginationItemNavLink>
        </PaginationInvoiceWrapper>
      </PaginationInvoiceCommandBar>
    );
  };

  render() {
    const { fileStorageUrl, filePreviewUrls, onUploadRemove } = this.props;
    const numPages = (filePreviewUrls && filePreviewUrls.length) || 0;

    return (
      <>
        <UploadWrapper fileStorageUrl={fileStorageUrl as string}>
          {fileStorageUrl && this.renderImageView()}
          {numPages > 1 && this.renderPagination(numPages)}
        </UploadWrapper>
        {fileStorageUrl && (
          <BillCommandBar>
            <MIButton
              variant={CONSTS.BUTTON_VARIANT.REMOVE}
              label="bills.new.removeFile"
              onClick={onUploadRemove}
            />
          </BillCommandBar>
        )}
      </>
    );
  }
}

const UploadWrapper = styled.div<{ fileStorageUrl: string }>`
  display: flex;
  height: 100%;
  border-radius: ${(props) => (props.fileStorageUrl.length ? '0.8rem' : '0')};
  align-items: center;
  justify-content: center;
`;

const BillCommandBar = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: space-between;
  > button {
    height: 2.5rem;
    line-height: 2.5rem;
    font-weight: ${(props) => props.theme.text.weight.semiBold};
    margin-top: 0.5rem;
  }
`;

const LoadedBillImg = styled.img`
  border-radius: 0.8rem;
  max-height: 100%;
  max-width: 100%;
  object-fit: scale-down;
`;

const PaginationItemNavLink = styled.div<{ isDisabled?: boolean }>`
  color: ${(props) => props.theme.text.color.main};
  opacity: ${(props) => (props.isDisabled ? '0.3' : '1')};
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: pointer;
  margin-top: 0.4rem;
  font-size: 2.3rem;
`;

const PaginationInvoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  > div:nth-of-type(1) {
    padding-left: 0.8rem;
  }

  > div:nth-of-type(3) {
    padding-right: 0.8rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    > div:nth-of-type(1) {
      padding-left: 0.6rem;
    }

    > div:nth-of-type(3) {
      padding-right: 0.6rem;
    }
  }
`;

const PaginationInvoiceText = styled.div`
  font-size: ${(props) => props.theme.text.size.button};
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 1.8rem;
`;

const PaginationInvoiceCommandBar = styled.div`
  background-color: rgba(234, 234, 234, 0.95);
  display: flex;
  height: 4.6rem;
  position: absolute;
  border-radius: 0.8rem;
  bottom: 2.2rem;

  @media ${devices.desktop}, ${devices.tablet} {
    max-width: 40%;
    width: 100%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    height: 3rem;
    bottom: 1rem;
    border-radius: 0.5rem;
  }
`;
