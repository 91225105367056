import React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIDotsActionsMenu from 'src/app/components/common/MIDotsActionsMenu';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import { ActionOption } from 'src/app/components/common/MIActionsMenu';
import { CardTypeEnum, CardNetworkEnum } from 'src/app/version-2/model/enums';
import { getCardLogo, getIconCardLogo } from 'src/app/utils/card';

type Props = {
  goAddItem?: (cardType?: CardTypeEnum) => void;
  iconClassName?: string;
  nameLabel: string;
  nameLabelValues?: Record<string, any>;
  descriptionLabel?: string;
  descriptionValues?: Record<string, any>;
  itemName?: string;
  itemNameValues?: Record<string, any>;
  cardType?: CardTypeEnum;
  network?: CardNetworkEnum;
  imageObj?: Record<string, any>;
  actionOptions?: ActionOption[];
  hideAddItemLink?: boolean;
  disabled?: boolean;
  moduleName?: string;
};

const SettingItemDetails = ({
  goAddItem,
  iconClassName,
  nameLabel,
  nameLabelValues,
  descriptionLabel,
  descriptionValues,
  itemName,
  cardType,
  network,
  imageObj,
  actionOptions,
  hideAddItemLink,
  disabled,
}: Props) => {
  let settingItemProps = {};

  if (!itemName && goAddItem) {
    settingItemProps = { actionable: 'actionable' };
  }

  const hasLogo = !!(imageObj && imageObj.logo?.length);
  const renderCreditCardIcon = () =>
    !imageObj && iconClassName
      ? getIconCardLogo(iconClassName)
      : getCardLogo(cardType as CardTypeEnum, network as CardNetworkEnum);

  const onClick = () => {
    goAddItem && goAddItem(cardType);
  };

  return (
    <SettingItemDetailsDiv {...settingItemProps}>
      <SettingItemDetailsDataContainer>
        {cardType === CardTypeEnum.CREDIT && renderCreditCardIcon()}
        {imageObj && hasLogo && (
          <Image src={`data:image/jpeg;base64,${imageObj.logo}`} alt={imageObj.name} />
        )}
        {imageObj && !hasLogo && (
          <FundingSourceIcon disabled={disabled} className="icon-bank-icon" />
        )}
        <SettingItemDetailsTextContainer>
          <SettingItemDetailsName disabled={disabled}>
            <MIFormattedText label={nameLabel} values={nameLabelValues} />
          </SettingItemDetailsName>
          <SettingItemDetailsDescription disabled={disabled}>
            {descriptionLabel && (
              <MIFormattedText label={descriptionLabel} values={descriptionValues} />
            )}
          </SettingItemDetailsDescription>
        </SettingItemDetailsTextContainer>
      </SettingItemDetailsDataContainer>
      {!actionOptions && goAddItem && !hideAddItemLink && (
        <StyledMIInlineLink label="settings.paymentMethods.hint" onClick={onClick} />
      )}
      {actionOptions && !!actionOptions.length && (
        <MIDotsActionsMenu actionOptions={actionOptions} />
      )}
    </SettingItemDetailsDiv>
  );
};

export default SettingItemDetails;

const FundingSourceIcon = styled.i<{ disabled?: boolean }>`
  font-size: 3.4rem;
  color: ${(props) =>
    props.disabled ? props.theme.colors.dark.translucent3 : props.theme.colors.icons.card};
  margin-right: 2rem;
`;

const SettingItemDetailsDiv = styled.div<{
  disabled?: boolean;
  actionable?: string;
}>`
  width: 100%;
  border-radius: 0.8rem;
  border: solid 0.1rem #e3e3e3;
  background-color: ${(props) => props.theme.colors.white.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  position: relative;
  ${(p) =>
    p.actionable &&
    !p.disabled &&
    css`
      &:hover {
        box-shadow: none;
      }
    `}
  ${(p) =>
    p.disabled &&
    css`
      ${FundingSourceIcon}:before {
        color: ${(props) => props.theme.colors.dark.translucent05};
      }
    `}
`;

const Image = styled.img`
  height: 3.4rem;
  width: 3.4rem;
  margin-right: 2rem;
`;

const SettingItemDetailsTextContainer = styled.div`
  flex: 1;
`;

const SettingItemDetailsDataContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 1.5rem 0;
`;

const SettingItemDetailsName = styled.div<{ disabled?: boolean }>`
  min-height: 2.2rem;
  color: ${(props) =>
    props.disabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.main};
  font-size: 1.6rem;
  line-height: 2.2rem;
`;

const SettingItemDetailsDescription = styled.div<{ disabled?: boolean }>`
  min-height: 1.6rem;
  color: ${(props) =>
    props.disabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.hint};
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;
