import { useCallback, useMemo, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../constants';

type Props = {
  setPage: (pageIndex: number) => void;
  start?: number;
  totalItems?: number;
  limit?: number;
};

const usePagination = ({
  totalItems = 0,
  limit = DEFAULT_PAGE_SIZE,
  setPage,
  start = 0,
}: Props) => {
  const pageCount = useMemo(() => Math.ceil(totalItems / limit), [totalItems, limit]);
  const showPagination = totalItems > limit;
  const pageIndex = useMemo(() => Math.floor(start / limit), [start, limit]);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);

  const setPaginationState = useCallback(
    (page: number) => {
      setCanNextPage(page !== pageCount - 1);
      setCanPreviousPage(page !== 0);
    },
    [pageCount]
  );

  const goToPage = useCallback(
    (page: number) => {
      setPaginationState(page);
      setPage(page);
    },
    [setPage, setPaginationState]
  );

  const previousPage = useCallback(() => {
    const prevIndexPage = pageIndex - 1;

    goToPage(prevIndexPage);
  }, [goToPage, pageIndex]);

  const nextPage = useCallback(() => {
    const nextIndexPage = pageIndex + 1;

    goToPage(nextIndexPage);
  }, [goToPage, pageIndex]);

  return {
    canNextPage,
    canPreviousPage,
    previousPage,
    nextPage,
    showPagination,
    pageCount,
    pageIndex,
    setPaginationState,
  };
};

export default usePagination;
