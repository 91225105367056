import { postRequest } from 'src/app/version-2/externals';
import { UpgradePaymentByPayeeRequest } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/requests/UpgradePaymentByPayeeRequest';

export const upgradePaymentByPayeeApi = {
  upgradePayment({ paymentId, token, type }: UpgradePaymentByPayeeRequest) {
    const url = `/payment/${paymentId}/upgrade`;

    return postRequest<void>(url, { token, type });
  },
};
