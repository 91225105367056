import React, { useContext, useEffect } from 'react';

import Step from 'src/app/components/layout/QBOLayoutPage';
import analytics from 'src/app/services/analytics';

import { StepProps } from '../../types';
import InternationalContext from '../../Context';

import Form from './Form';

const VendorLocation = ({ relativeStep, onNext, onPrev, onExit }: StepProps) => {
  const [, actions] = useContext(InternationalContext);

  useEffect(() => {
    analytics.page('pay-bill', 'international-choose-country');
  }, []);

  const titleValues = actions.getTitleValues();
  const originValues = actions.getOriginValues();

  return (
    <Step
      titleValues={titleValues}
      relativeStep={relativeStep}
      onPrev={onPrev}
      goExit={onExit}
      hideHeader
      title="international.vendorLocation.title"
      {...originValues}
    >
      <Form onNext={onNext} />
    </Step>
  );
};

export default VendorLocation;
