import type { RecordOf } from 'immutable';
import type { GlobalState } from '../types';
import type {
  UserContextType,
  DeliveryMethodType,
  CompanyInfoType,
  UserPreferencesType,
} from '../../utils/types';
import { CONSTS } from '../../utils/consts';
import { KybTypeEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

export const getFundingSources = (state: GlobalState): FundingSource[] => state.user.fundingSources;
export const getIsLoading = (state: GlobalState): boolean => state.user.isLoading;
export const getProfile = (state: GlobalState): RecordOf<UserContextType> => state.user.profile;
export const getUserId = (state: GlobalState): number | undefined | null => state.user.profile.id;
export const getOrgId = (state: GlobalState): string => state.user.profile.orgId;
export const getOrgName = (state: GlobalState): string | null | undefined =>
  state.user.profile.orgName;

export const getIsFundingSourceDeleting = (state: GlobalState): boolean =>
  state.user.isFundingSourceDeleting;
export const getIsRemovingFundingSourceLabel = (state: GlobalState): boolean =>
  state.user.isRemovingFundingSourceLabel;
export const getIsFundingSourceVerifying = (state: GlobalState): boolean =>
  state.user.isFundingSourceVerifying;
export const getDeliveryMethods = (state: GlobalState): RecordOf<DeliveryMethodType>[] =>
  (state.user.deliveryMethods || []).filter((dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.ACH);
export const getCompanyInfo = (state: GlobalState): RecordOf<CompanyInfoType> =>
  state.user.companyInfo;
export const getOwnedVendorId = (state: GlobalState): string =>
  (state.user.companyInfo.ownedVendorId as unknown as string) || '';
export const getUserPreferences = (state: GlobalState): RecordOf<UserPreferencesType> =>
  state.user.userPreferences;
export const getCanContactSupport = (state: GlobalState): boolean => {
  const companyInfo = getCompanyInfo(state);

  return companyInfo.kyb !== KybTypeEnum.REJECT;
};
export const getIsUserChecked = (state: GlobalState): boolean => state.user.isUserChecked;
export const getIsLoggedIn = (state: GlobalState): boolean => state.user.isLoggedIn;
export const getIsLoggedInAs = (state: GlobalState): boolean => state.user.isLoggedInAs;
export const getOriginPlaidItemId = (state: GlobalState): number | null =>
  state.user.originPlaidItemId;
export const getDMVirtualAccountEmail = (state: GlobalState): string =>
  state.deliveryMethods.byId[state.payments.quickpay?.deliveryMethodId]?.virtualAccount?.email ||
  '';
