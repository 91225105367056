import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';
import {
  ModalMessage,
  ModalContentContainer,
  ContentContainer,
} from 'src/app/components/common/ModalMessage';
import EditVendor from 'src/app/pages/qb-dashboard/components/EditVendorModal/EditVendor';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { useQueryState } from 'src/app/utils/hooks';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import useDashboardListParams from './useDashboardListParams';

function useEditVendorModal() {
  const [listParams] = useDashboardListParams();
  const [vendorId, setVendorId] = useQueryState<string | undefined>('vendorId');
  const orgId = useSelector(getOrgId);
  const showVendorModal = !!vendorId;
  const vendorActions = useStoreActions(vendorsStore);
  const loadStatus: { loading: boolean } = useSelector(
    qbDashboardListItemsStore.selectors.list?.status(listParams)
  );

  const reset = () => {
    analytics.trackAction('vendor-modal-close');
    setVendorId(undefined);
  };

  useEffect(() => {
    if (showVendorModal && loadStatus && !loadStatus.loading) {
      initialVendor();
    }
  }, [showVendorModal, loadStatus]);

  const initialVendor = async () => {
    await vendorActions.fetch({
      orgId,
      id: vendorId,
    });
  };

  const vendorModal = showVendorModal ? (
    <ModalMessageStyled
      titleComponent={<MIFormattedText label="paymentDashboard.editVendor.title" />}
      contentComponent={<EditVendor closeModal={reset} />}
      onCloseClick={reset}
    />
  ) : null;

  return [vendorModal];
}

export default useEditVendorModal;

const ModalMessageStyled = styled(ModalMessage)`
  ${ModalContentContainer} {
    padding: 4rem 3rem;
  }

  ${ContentContainer} {
    margin-bottom: 0;
  }
`;
