import React, { useMemo } from 'react';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import ValidationError from 'src/app/ui/ValidationError';
import { useForm } from 'src/app/ui/form';
import { validateVendorEmail } from 'src/app/modules/vendors/vendor-email/utils/validate-user-email';
import { useUpdateVendorEmail } from 'src/app/modules/vendors/vendor-email/hooks/useUpdateVendorEmail';
import { VendorEmailTitle, VendorEmailContent } from './VendorEmailComponents';

type Props = {
  vendorId: number;
  vendorName: string;
  vendorEmail: string;
  onSuccess: () => void;
  onCloseModal: () => void;
};

export const VendorEmailModal = ({
  vendorId,
  vendorName,
  vendorEmail,
  onSuccess,
  onCloseModal,
}: Props) => {
  const model = useMemo(() => ({ contactEmail: vendorEmail }), []);
  const { updateVendorEmail, loading: editVendorLoading } = useUpdateVendorEmail(vendorId);

  const [vendorEmailMV, { submit }, , loading] = useForm(model, {
    submit: async () => {
      const { contactEmail } = vendorEmailMV;
      const { validationErrors, isValid } = validateVendorEmail(contactEmail.value);

      if (!isValid) {
        throw new ValidationError({ validationErrors });
      }

      await updateVendorEmail(contactEmail.value as string);
      await onSuccess();
    },
  });

  return (
    <ModalMessage
      titleComponent={<VendorEmailTitle vendorName={vendorName} />}
      contentComponent={<VendorEmailContent model={vendorEmailMV} />}
      buttonLabel="qbr.success.email.saveEmail"
      onCloseClick={onCloseModal}
      onButtonClick={submit}
      isLoading={editVendorLoading || loading}
    />
  );
};
