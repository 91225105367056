import React from 'react';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { useShiftVirtualCardData } from 'src/app/pages/vendor/shift-virtual-card/hooks/useShiftVirtualCardData';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';
import { InfoLayout, ReviewGridItem, InlineLink } from '../components';

type Props = {
  token: string;
};

const NotificationLayout = ({ token }: Props) => {
  const site = useSiteContext();

  const { payment, deliveryMethod } = useShiftVirtualCardData({
    token,
  });

  useStateChangedAnalytics({ payment, paymentState: VirtualCardPaymentState.Notification });

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <InfoLayout.Icon type={InfoLayout.IconType.Info} />
      <InfoLayout.Title>
        <MIFormattedText label="vendors.virtualCardDetails.notification.title" />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label="vendors.virtualCardDetails.notification.subtitle"
          values={{
            link: (...chunks) => (
              <InlineLink href={`mailto:${site.config.support.email}`} chunks={chunks} />
            ),
          }}
        />
      </InfoLayout.Subtitle>
      <InfoLayout.Separator />
      <InfoLayout.SectionTitle>
        <MIFormattedText label="vendors.virtualCardDetails.notification.reviewSectionTitle" />
      </InfoLayout.SectionTitle>
      <InfoLayout.Grid>
        <ReviewGridItem
          image={<Mastercard width="3.2rem" height="3.2rem" />}
          label={<MIFormattedText label="vendors.virtualCardDetails.notification.cardTo" />}
          value={
            <MIFormattedText
              label="vendors.virtualCardDetails.notification.cardData"
              values={{
                email: deliveryMethod?.virtualCardAccount?.accountEmail,
                card4digits: payment?.lastCreatedVirtualCard?.accountNumber4digits,
              }}
            />
          }
        />
        <ReviewGridItem
          icon="icon-eta-cal"
          label={<MIFormattedText label="vendors.virtualCardDetails.notification.deliveryETA" />}
          value={<MIFormattedDate date={payment?.deliveryEta} />}
        />
      </InfoLayout.Grid>
    </InfoLayout>
  );
};

export { NotificationLayout };
