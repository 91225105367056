import React, { useEffect, useMemo } from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { SelectExpediteStateType } from 'src/app/pages/vendor/select-expedited/hoc/select-expedited-hooks';
import { TransactionDestinationEnum } from 'src/app/version-2/model/enums';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import analytics from 'src/app/services/analytics';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { useBreak } from 'src/app/hoc';
import { CARD_TYPES, DELIVERY_TYPE } from 'src/app/utils/consts';
import { getPartialBillId } from 'src/app/utils/bills';
import VendorSelectExpeditedCategories from '../VendorSelectExpeditedCategories/VendorSelectExpeditedCategories';
import { Content, Separator } from './VendorSelectExpeditedDeliveryMethodPage.styles';
import { stringifyDeliveryMethods } from '../utils';

type PropsType = {
  token: string;
  state: Pick<SelectExpediteStateType, 'payment' | 'deliveryMethod' | 'organization'>;
};

const achOptions = [CARD_TYPES.DEBIT, DELIVERY_TYPE.VIRTUAL_CARD];

export const VendorSelectExpeditedDeliveryMethodPage = ({ state, token }: PropsType) => {
  const { payment, organization, deliveryMethod } = state;
  const { isMobile } = useBreak();

  const analyticsProps = useMemo(
    () => ({
      flow: TransactionDestinationEnum.VENDOR,
      paymentId: payment?.id,
      billId: payment?.billId,
      partialBillId: getPartialBillId({
        id: payment?.billId as string,
        payments: payment?.billPayments,
      }),
      vendorId: payment?.vendor?.id,
    }),
    [payment]
  );

  useEffect(() => {
    analytics.trackAction('ChooseDeliveryMethod-Viewed', {
      deliveryMethodTypes: stringifyDeliveryMethods(achOptions),
      ...analyticsProps,
    });
  }, []);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success" innerSize={60}>
      <Box textAlign="center" marginBottom={5} marginTop={10}>
        <QBLogo />
      </Box>
      <Content>
        <Flex
          direction="column"
          textAlign="center"
          justifyContent="center"
          gridGap="1.1rem"
          m={5}
          mb={0}
          p={5}
        >
          <Box padding={isMobile ? 0 : '0 7rem'} textStyle="h5" fontWeight="ds.semi">
            <MIFormattedText label="vendors.addDeliveryMethodByLink.title" />
          </Box>
          <Box color="black" fontSize={14}>
            <MIFormattedText
              label="vendors.addDeliveryMethodByLink.subtitle"
              values={{
                totalAmount: <MIFormattedCurrency value={payment?.amount || ''} />,
                companyName: organization?.companyName,
              }}
            />
          </Box>
        </Flex>

        {isMobile && <Separator />}
        <VendorSelectExpeditedCategories
          payment={payment}
          deliveryMethod={deliveryMethod}
          token={token}
          analyticsProps={analyticsProps}
        />
      </Content>
    </QBOLayoutPage>
  );
};
