export const PAY_BILL_EVENT_PAGE = 'pay-bill';

export const enum RecoveryFlowType {
  RETURNED_CHECK = 'returnedCheckRecovery',
  VOID_CHECK = 'voidCheck',
}

export const RECOVERY_FLOW_TYPE = {
  RETURNED_CHECK: RecoveryFlowType.RETURNED_CHECK,
  VOID_CHECK: RecoveryFlowType.VOID_CHECK,
};

export const EVENT_NAMES = {
  ADD_CREDIT: 'add-credit-card',
  ADD_DEBIT: 'add-debit-card',
  ADD_BANK: 'add-bank-account',
};
