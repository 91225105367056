import { call, select } from 'redux-saga/effects';
import { featureFlags } from '@melio/shared-web';

import paymentsApi from 'src/app/services/api/payments';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { getOrgId } from '../../user/selectors';

export function* sendEmailToVendorOnPaymentScheduleHandler({
  deliveryType,
  paymentId,
  contactEmail,
}) {
  const orgId = yield select(getOrgId);
  const isSendingEditCheckEnabled = featureFlags.defaultClient.getVariant(
    'qb-edit-check-address',
    false
  );

  const shouldSentEmail =
    isSendingEditCheckEnabled && !!contactEmail && deliveryType === DELIVERY_TYPE.CHECK;

  if (shouldSentEmail) {
    yield call(paymentsApi.sendEditCheckAddress, orgId, paymentId);
  }
}
