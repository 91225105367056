import omit from 'lodash/omit';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import billsApiService, { DashboardItemMetadataParams } from 'src/app/services/api/bills';
import { getDashboardItemId } from 'src/app/pages/qb-dashboard/utils';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { useApi } from 'src/app/hoc/useApi';
import { PAGINATION } from 'src/app/utils/consts';
import { QB_DASHBOARD_TAB_STATUS, ITEMS_PER_PAGE } from '../consts';

export type RedirectQuery = {
  start: number;
  status: QB_DASHBOARD_TAB_STATUS;
  limit: number;
  highlightedItemIds: string;
};

export const DEFAULT_DASHBOARD_REDIRECT_PARAMS: RedirectQuery = {
  start: PAGINATION.DEFAULT_START,
  status: QB_DASHBOARD_TAB_STATUS.UNPAID,
  limit: ITEMS_PER_PAGE,
  highlightedItemIds: '',
};

export const PAID_DASHBOARD_REDIRECT_PARAMS: RedirectQuery = {
  start: PAGINATION.DEFAULT_START,
  status: QB_DASHBOARD_TAB_STATUS.PAID,
  limit: ITEMS_PER_PAGE,
  highlightedItemIds: '',
};

const CANCEL_METADATA_REQUEST_TIMEOUT = 5000;

export const useGetDashboardListItemPaginationParams = () => {
  const orgId = useSelector(getOrgId);
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const getPaginationRedirectParams = isPartialPaymentsEnabled
    ? billsApiService.getNewQBDashboardItemMetadata
    : billsApiService.getQBDashboardItemMetadata;
  const [getPaginationRedirectParamsRequest, , isFetchingRedirectParams] = useApi(
    getPaginationRedirectParams
  );

  const getDashboardListItemPaginationParams = useCallback(
    async ({ billId, paymentId, withoutTimeout = false }): Promise<RedirectQuery> => {
      const requestParams: DashboardItemMetadataParams = {
        orgId,
        params: {
          pageSize: ITEMS_PER_PAGE,
        },
      };

      const isPaymentIdValid = paymentId && parseInt(paymentId || '', 10);
      const isBillIdValid = billId && parseInt(billId || '', 10);

      if (isPaymentIdValid) {
        requestParams.params.paymentId = paymentId;
      }

      if (isBillIdValid) {
        requestParams.params.billId = billId;
      }

      const redirectParams: RedirectQuery = DEFAULT_DASHBOARD_REDIRECT_PARAMS;

      try {
        if (isPaymentIdValid || isBillIdValid) {
          // For users that have a lot of payments this request might take significant time to load
          // pagination params. It's a workaround to cancel request if it takes more than 5 secs
          // and redirect user to the first page. It's short term solution - in long term perspective
          // we'll need to optimize the request on api side
          const abortController = new AbortController();

          requestParams.options = {
            signal: abortController.signal,
          };
          const cancelTimeoutId = setTimeout(() => {
            if (!withoutTimeout) {
              abortController.abort();
            }
          }, CANCEL_METADATA_REQUEST_TIMEOUT);
          const result = await getPaginationRedirectParamsRequest(requestParams);

          clearTimeout(cancelTimeoutId);
          const resultWithoutStatusFields = omit(result, ['message', 'code']);

          return {
            ...resultWithoutStatusFields,
            highlightedItemIds: getDashboardItemId(billId, paymentId),
            limit: ITEMS_PER_PAGE,
          };
        }
      } catch (e) {
        return redirectParams;
      }

      return redirectParams;
    },
    [orgId, getPaginationRedirectParamsRequest]
  );

  return {
    getDashboardListItemPaginationParams,
    isFetchingRedirectParams,
  };
};
