import { Button, IconType, SmartIcon } from '@melio/billpay-design-system';
import { FoundSourceCardProps } from 'src/app/version-2/model/objects/foundingSourceCard';
import {
  AdvantageItem,
  Advantages,
  Card,
  Comment,
  Fee,
  IconContainer,
  Subtitle,
  Title,
} from './FundingSourceCard.styles';

export const FundingSourceCard = ({
  id,
  icon,
  iconSize,
  title,
  subtitle,
  fee,
  advantages,
  comment,
  onClick,
  isSelected,
  withButton = false,
  buttonText,
  hover = true,
}: FoundSourceCardProps) => (
  <Card onClick={onClick} $selected={isSelected} $withButton={withButton} $hover={hover}>
    <IconContainer $selected={isSelected}>
      <SmartIcon
        type={!isSelected ? (icon as IconType) : 'checked'}
        size={`${isSelected ? 2 : iconSize || 4}rem`}
      />
    </IconContainer>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <Fee>{fee}</Fee>
    {withButton && (
      <Button variant="primary" size="md">
        {buttonText}
      </Button>
    )}
    <Advantages>
      {advantages.map((item, index) => (
        <AdvantageItem key={`${id}_advantage_${index}`}>
          <SmartIcon type="checked" size="1.5rem" color="ds.green.200" />
          {item}
        </AdvantageItem>
      ))}
    </Advantages>
    {comment && <Comment>{comment}</Comment>}
  </Card>
);
