import React, { useCallback } from 'react';
import IMask from 'imask';
import { IMaskMixin } from 'react-imask';
import { Input } from '@melio/billpay-design-system';

import { ModelViewField } from 'src/app/ui/form';

// @ts-ignore
const MaskedTextInput = IMaskMixin(({ inputRef, isInvalid, ...props }) => (
  <Input
    {...(props as any)}
    ref={inputRef}
    textAlign="right"
    variant="outline"
    textStyle="ds.body2Semi"
    color={isInvalid && 'ds.red.200'}
    fontWeight="ds.semi"
  />
));

type InlineCurrencyFieldProps = {
  model: ModelViewField<string>;
  id?: string;
  placeholder?: string | any;
  isInvalid?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
};

const masks = {};

function getMask(id: string) {
  let mask = masks[id];

  if (!mask) {
    mask = IMask.createMask({
      mask: '$num',
      blocks: {
        num: {
          mask: Number,
          scale: 2,
          padFractionalZeros: true,
          thousandsSeparator: ',',
          radix: '.',
        },
      },
    });
    masks[id] = mask;
  }

  return mask;
}

export function QBOTableCurrencyInput(props: InlineCurrencyFieldProps) {
  const { model, placeholder, isInvalid, onBlur, onFocus } = props;
  const id = props.id || model.id;
  const originalOnChange = model.onChange;
  const onChange = useCallback(
    (value) => {
      if (value !== model.value) {
        originalOnChange({ value });
      }
    },
    [originalOnChange, model.value]
  );

  const mask = getMask(id);

  return (
    <MaskedTextInput
      mask={mask}
      // @ts-ignore
      unmask
      value={model.value}
      onAccept={onChange}
      placeholder={placeholder}
      id={id}
      data-testid={id}
      isInvalid={isInvalid}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}
