import styled from 'styled-components';
import {
  WizardActionsRowContainer as StyledWizardActionsRowContainer,
  WizardButtonContainer as StyledWizardButtonContainer,
} from 'src/app/components/layout/QBOWizardElements';
import { ModalContainer as StyledModalContainer } from 'src/app/components/common/ModalMessage';

export const WizardButtonContainer = styled(StyledWizardButtonContainer)<{
  vendorEmailNotExist?: boolean;
}>`
  width: auto !important;

  button {
    width: auto;
  }

  &:first-of-type {
    button {
      border: none;
    }

    button:hover {
      background: ${({ vendorEmailNotExist }) => vendorEmailNotExist && 'none'};
    }
  }
`;

export const WizardActionsRowContainer = styled(StyledWizardActionsRowContainer)<{
  hasMissingEmails?: boolean;
}>`
  width: auto;
  justify-content: ${({ hasMissingEmails }) => (hasMissingEmails ? 'flex-end' : 'center')};
  margin-top: 4rem;
`;

export const ModalContainer = styled(StyledModalContainer)`
  width: 50.6rem;
  height: auto;
  padding: 3.5rem 4rem 4rem;

  .notification-title,
  .notification-subtitle {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .notification-subtitle {
    font-weight: 400;
  }

  > div {
    min-height: auto;

    div {
      width: 100%;
      text-align: left;
      margin-bottom: 0;

      > img + div {
        font-weight: 600;
        margin-bottom: 0.8rem;
      }
    }
  }

  &.batch-payment-modal {
    > div {
      margin-bottom: 0;

      div {
        > img + div {
          font-weight: 700;
        }
      }
    }
  }
`;

export const BatchInfoContainer = styled.div`
  margin-top: 2rem;

  > .batch-info-container-notification-card {
    margin-top: 2rem;
  }

  .notification-card-text-container {
    .notification-title {
      margin-bottom: 1rem;
    }
  }

  ${WizardActionsRowContainer} {
    justify-content: center;
    margin-top: 4rem;
  }
`;

export const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  font-size: 2.4rem;
  cursor: pointer;
  color: rgba(107, 108, 114, 1);
`;
