export interface DeductionSelectProps {
  onSelect: (type: DeductionDateObjectType) => void;
  disabled?: boolean;
}

export interface DeductionDateSelectProps {
  date?: Date;
  minScheduleDate?: Date;
  onChange: (change: { type: string; date?: Date | null }) => void;
  disabled?: boolean;
  deductionType: DeductionDateObjectType;
  setDeductionType: (type) => void;
}

export interface DeductionDatePickerProps {
  date?: Date;
  onChange: (date) => void;
  minScheduleDate?: Date;
  disabled?: boolean;
}

export interface DeductionDateObjectType {
  id: string;
  text: string;
}

export const DEDUCTION_DATE_TYPES: DeductionDateObjectType[] = [
  { id: 'arrive-by-due–date', text: 'batchBulkPage.headSection.deductionDate.types.due' },
  { id: 'the-same-day', text: 'batchBulkPage.headSection.deductionDate.types.same' },
];

export const BY_DUE_DATE_SELECTOR = 'arrive-by-due–date';
export const DATE_SELECTOR_TYPE = 'the-same-day';
