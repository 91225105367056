import React, { useCallback, useEffect } from 'react';
import analytics from 'src/app/services/analytics';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';
import { useApi } from 'src/app/hoc/useApi';
import successImage from 'src/app/images/icons/icons_check.svg';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { getPaymentById } from 'src/app/utils/payments';
import { BillType } from 'src/app/utils/types';
import billsApi from 'src/app/services/api/bills';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { AreaLoader } from '@melio/billpay-design-system';
import locations from 'src/app/utils/locations';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { refundSelectors } from 'src/app/version-2/pages/refund/modules/refund.slice';
import { RefundStatusEnum } from 'src/app/version-2/pages/refund/model/status.enum';

const prefix = 'bills.refund.success';

export default function RequestRefundSuccess() {
  const match = useRouteMatch<{ billId: string; paymentId: string; orgId: string }>();
  const { billId, paymentId, orgId } = match.params;
  const [historyPush] = useHistoryWithOrgId();
  const refundStatus = useSelector(refundSelectors.selectRefundStatus);
  const [loadBill, billResult] = useApi<[{ orgId: string; id: string }], { object: BillType }>(
    billsApi.getBillById,
    false
  );
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const payment = getPaymentById(billResult?.object?.payments, paymentId);
  const fundingSource: any = useSelector(
    fundingSourcesStore.selectors.byId(payment?.fundingSourceId)
  );
  const accountNumber = getAccountNumber4digits(fundingSource?.bankAccount);

  useEffect(() => {
    loadBill({
      orgId,
      id: billId,
    });
  }, [billId, orgId, loadBill]);

  useEffect(() => {
    const props = {
      billId: parseInt(billId),
      paymentId: parseInt(paymentId),
      isRefundFlowFeature,
      organizationId: parseInt(orgId),
    };

    loggingApi.info('RequestRefundSuccess.init(): Page init', {
      ...props,
      refundReason: payment?.metadata?.refundReason,
    });
    analytics.page('self-serve-refund', 'success', props);
  }, []);

  const goExit = useCallback(() => {
    historyPush({
      path: locations.Bills.payment.url({
        billId,
        paymentId,
        orgId,
      }),
    });
  }, []);

  const onSubmit = () => {
    const props = {
      paymentId: parseInt(paymentId),
      billId: parseInt(billId),
      orgId,
      isRefundFlowFeature,
      refundReason: payment?.metadata?.refundReason,
    };

    loggingApi.info('RequestRefundSuccess.onSubmit(): Submit button clicked', props);
    analytics.trackAction('success-track-button', props);
    historyPush({ path: locations.Bills.payment.url(props) });
  };

  const getSuccessText = () => {
    const texts = {
      title: `${prefix}.title`,
      subtitle: `${prefix}.subtitle`,
      button: `${prefix}.button`,
    };

    if (isRefundFlowFeature) {
      texts.button = `${prefix}.buttonDetails`;

      if (refundStatus === RefundStatusEnum.ERR_FAILED_REFUND) {
        texts.title = `${prefix}.titlePOPS`;
        texts.subtitle = `${prefix}.subtitlePOPS`;
      } else {
        texts.subtitle = `${prefix}.subtitleNew`;
      }
    }

    return texts;
  };

  return payment ? (
    <QBOSuccessLayout
      illustration={successImage}
      goExit={goExit}
      title={getSuccessText().title}
      text={getSuccessText().subtitle}
      textValues={{ amount: payment?.amount, bankNumber: accountNumber }}
      buttonLabel={getSuccessText().button}
      buttonAction={() => {
        onSubmit();
      }}
      hideHeader
    />
  ) : (
    <AreaLoader />
  );
}
