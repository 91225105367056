import type { RecordOf } from 'immutable';
import type {
  BeginRegularPayBillFlowType,
  BeginRecurringPayBillFlowType,
  SelectNewDeliveryMethodType,
  CreateRecurringBillType,
  CreatePaymentType,
  UpdatePaymentType,
  PaymentFeeInfo,
  BillIdType,
  SetRegularPayBillFlowInitialDataType,
  SetRegularPayBillFlowInitialDataFinishType,
  FEE_FLOWS,
  BANK_TYPE,
  FetchDeliveryOptionsResponse,
  FetchInitialDeliveryOptionsResponse,
} from './types';
import type {
  BillType,
  PaymentType,
  DeliveryMethodType,
  DeliveryOptionType,
} from '../../utils/types';
import {
  BEGIN_REGULAR_PAY_BILL_FLOW,
  BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
  BEGIN_RECURRING_PAY_BILL_FLOW,
  BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS,
  BEGIN_RECURRING_PAY_BILL_FLOW_FAILED,
  SELECT_FUNDING_SOURCE,
  SET_PAYMENT_AMOUNT,
  SELECT_PAYMENT_DATES,
  UPDATE_PAYMENT_MEMO,
  END_PAY_BILL_FLOW,
  SELECT_DELIVERY_METHOD,
  ADD_NEW_DELIVERY_METHOD,
  SELECT_NEW_DELIVERY_METHOD,
  SET_PURPOSE_OF_PAYMENT,
  CREATE_RECURRING_BILL,
  CREATE_RECURRING_BILL_SUCCESS,
  CREATE_RECURRING_BILL_ERROR,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_ERROR,
  FETCH_BILL,
  FETCH_BILL_SUCCESS,
  FETCH_BILL_FAILED,
  RESET_ERROR,
  SET_FEE,
  UPDATE_BILL,
  RETRY_FAILED_PAYMENT,
  RETRY_FAILED_PAYMENT_SUCCESS,
  RETRY_FAILED_PAYMENT_ERROR,
  SET_BILL_ID,
  SET_DELETED_FUNDING_SOURCE_ID,
  CLEAR_DELETED_FUNDING_SOURCE_ID,
  SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA,
  SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA_FINISH,
  CLEAR_SELECTED_FUNDING_SOURCE,
  SET_PAYBILL_WIZARD_FLOW,
  CLEAR_PAYBILL_WIZARD_FLOW,
  CLEAR_PAYBILL_WIZARD_BILL,
  SET_FEE_FUNDING_SOURCE_FLOW,
  SET_FEE_FUNDING_BANK_TYPE,
  SHOW_US_HOLIDAY_NOTIFICATION,
  HIDE_US_HOLIDAY_NOTIFICATION,
  CANCEL_AND_RETRY_PAYMENT,
  CANCEL_AND_RETRY_PAYMENT_SUCCESS,
  CANCEL_AND_RETRY_PAYMENT_ERROR,
  SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW,
  SET_DELIVERY_OPTIONS,
  FETCH_DELIVERY_OPTIONS,
  FETCH_DELIVERY_OPTIONS_SUCCESS,
  FETCH_DELIVERY_OPTIONS_FAILURE,
  FETCH_INITIAL_DELIVERY_OPTIONS_SUCCESS,
  FETCH_INITIAL_DELIVERY_OPTIONS_FAILURE,
  FETCH_INITIAL_DELIVERY_OPTIONS,
  SET_SYNC_PAYMENT_ERROR_CODE,
  SET_INVOICE_FILE,
} from './actionTypes';
import { LoadedFile } from './types';

export const beginRegularPayBillFlowSuccessAction = (
  bill: RecordOf<BillType>,
  payment: RecordOf<PaymentType>
) => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  bill,
  payment,
});

export const beginRegularPayBillFlowFailedAction = () => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
});

export const beginRegularPayBillFlowAction = (
  id: string,
  paymentId: string,
  redirectUrl?: string,
  exitUrl?: string,
  initialPaymentAmount?: number,
  keepPaymentFundingSource?: boolean
): BeginRegularPayBillFlowType => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW,
  id,
  paymentId,
  redirectUrl,
  exitUrl,
  initialPaymentAmount,
  keepPaymentFundingSource,
});

export const setInitialRegularFlowDataAction = ({
  bill,
  fundingSource,
  deliveryMethod,
  initialPaymentAmount,
  redirectUrl,
  exitUrl,
}: Omit<SetRegularPayBillFlowInitialDataType, 'type'>): SetRegularPayBillFlowInitialDataType => ({
  type: SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA,
  bill,
  fundingSource,
  deliveryMethod,
  initialPaymentAmount,
  redirectUrl,
  exitUrl,
});
export const setInitialRegularFlowDataActionFinish = ({
  bill,
  payment,
  redirectUrl,
  exitUrl,
}: Omit<
  SetRegularPayBillFlowInitialDataFinishType,
  'type'
>): SetRegularPayBillFlowInitialDataFinishType => ({
  type: SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA_FINISH,
  bill,
  payment,
  redirectUrl,
  exitUrl,
});

export const beginRecurringPayBillFlowAction = (
  bill: Record<string, any>,
  recurringBill: Record<string, any>,
  exitUrl?: string
): BeginRecurringPayBillFlowType => ({
  type: BEGIN_RECURRING_PAY_BILL_FLOW,
  bill,
  recurringBill,
  exitUrl,
});

export const beginRecurringPayBillFlowSuccessAction = (
  bill: RecordOf<BillType>,
  payment: RecordOf<PaymentType>,
  recurringBill: Record<string, any>
) => ({
  type: BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS,
  bill,
  payment,
  recurringBill,
});

export const beginRecurringPayBillFlowFailedAction = () => ({
  type: BEGIN_RECURRING_PAY_BILL_FLOW_FAILED,
});

export const endPayBillFlowAction = (isCloseEvent?: boolean, preventCloseIframe?: boolean) => ({
  type: END_PAY_BILL_FLOW,
  isCloseEvent,
  preventCloseIframe,
});

export const selectFundingSourceAction = (id: number) => ({
  type: SELECT_FUNDING_SOURCE,
  id,
});

export const clearSelectedFundingSourceAction = () => ({
  type: CLEAR_SELECTED_FUNDING_SOURCE,
});

export const setPaymentAmountAction = (amount: number) => ({
  type: SET_PAYMENT_AMOUNT,
  amount,
});

export const selectPaymentDatesAction = (
  scheduledDate: Date,
  deliveryEta: Date,
  maxDeliveryEta: Date,
  deliveryPreference: string
) => ({
  type: SELECT_PAYMENT_DATES,
  scheduledDate,
  deliveryEta,
  maxDeliveryEta,
  deliveryPreference,
});

export const setFeeAction = (payload: PaymentFeeInfo) => ({
  type: SET_FEE,
  payload,
});

export const setBillIdAction = (billId: BillIdType) => ({
  type: SET_BILL_ID,
  billId,
});

export const setDeletedFundingSource = (fundingSourceId: number) => ({
  type: SET_DELETED_FUNDING_SOURCE_ID,
  fundingSourceId,
});

export const clearDeletedFundingSources = () => ({
  type: CLEAR_DELETED_FUNDING_SOURCE_ID,
});

export const selectDeliveryMethodAction = (deliveryMethod?: DeliveryMethodType) => ({
  type: SELECT_DELIVERY_METHOD,
  deliveryMethod,
});

export const addNewDeliveryMethodAction = (deliveryMethod: DeliveryMethodType) => ({
  type: ADD_NEW_DELIVERY_METHOD,
  deliveryMethod,
});

export const selectNewDeliveryMethodAction = (
  deliveryMethod: DeliveryMethodType
): SelectNewDeliveryMethodType => ({
  type: SELECT_NEW_DELIVERY_METHOD,
  deliveryMethod,
});

export const setPurposeOfPaymentAction = (purpose: string) => ({
  type: SET_PURPOSE_OF_PAYMENT,
  purpose,
});

export const updatePaymentMemoAction = (memo: string) => ({
  type: UPDATE_PAYMENT_MEMO,
  memo,
});

export const updateBillAction = (goodsReceived: boolean) => ({
  type: UPDATE_BILL,
  goodsReceived,
});

export const fetchBillAction = (id: string) => ({
  type: FETCH_BILL,
  id,
});

export const fetchBillSuccessAction = (bill: RecordOf<BillType>) => ({
  type: FETCH_BILL_SUCCESS,
  bill,
});

export const fetchBillFailedAction = () => ({
  type: FETCH_BILL_FAILED,
});

export const createRecurringBillSuccessAction = (
  firstBillIdWithRecurringBill: string,
  payment: RecordOf<PaymentType>,
  recurringBillId: string
) => ({
  type: CREATE_RECURRING_BILL_SUCCESS,
  firstBillIdWithRecurringBill,
  payment,
  recurringBillId,
});

export const createRecurringBillErrorAction = (errorCode: string) => ({
  type: CREATE_RECURRING_BILL_ERROR,
  errorCode,
});

export const createRecurringBillAction = (
  resolve: () => void,
  reject: (error: any) => void
): CreateRecurringBillType => ({
  type: CREATE_RECURRING_BILL,
  resolve,
  reject,
});

export const createPaymentSuccessAction = (payment: RecordOf<PaymentType>) => ({
  type: CREATE_PAYMENT_SUCCESS,
  payment,
});

export const createPaymentErrorAction = (errorCode: string) => ({
  type: CREATE_PAYMENT_ERROR,
  errorCode,
});

export const createPaymentAction = (
  resolve: () => void,
  reject: (error: any) => void
): CreatePaymentType => ({
  type: CREATE_PAYMENT,
  resolve,
  reject,
});

export const updatePaymentSuccessAction = (payment: RecordOf<PaymentType>) => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payment,
});

export const updatePaymentErrorAction = (errorCode: string) => ({
  type: UPDATE_PAYMENT_ERROR,
  errorCode,
});

export const updatePaymentAction = (
  resolve: () => void,
  reject: (error: any) => void
): UpdatePaymentType => ({
  type: UPDATE_PAYMENT,
  resolve,
  reject,
});

export const retryFailedPaymentSuccessAction = (payment: RecordOf<PaymentType>) => ({
  type: RETRY_FAILED_PAYMENT_SUCCESS,
  payment,
});

export const retryFailedPaymentErrorAction = (errorCode: string) => ({
  type: RETRY_FAILED_PAYMENT_ERROR,
  errorCode,
});

export const retryFailedPaymentAction = (
  resolve: () => void,
  reject: (error: any) => void
): CreatePaymentType => ({
  type: RETRY_FAILED_PAYMENT,
  resolve,
  reject,
});

export const resetErrorAction = () => ({
  type: RESET_ERROR,
});
export const setInvoiceFileAction = (billId: string, file: LoadedFile | null) => ({
  type: SET_INVOICE_FILE,
  billId,
  payload: file,
});

export const setPayBillWizardFlow = (flowName?: string) => ({
  type: SET_PAYBILL_WIZARD_FLOW,
  payload: flowName,
});

export const clearPayBillWizardFlow = () => ({
  type: CLEAR_PAYBILL_WIZARD_FLOW,
});

export const clearPayBillWizardBill = () => ({
  type: CLEAR_PAYBILL_WIZARD_BILL,
});

export const setFeeFundingSourceFlow = (flow: FEE_FLOWS) => ({
  type: SET_FEE_FUNDING_SOURCE_FLOW,
  payload: flow,
});
export const setFeeFundingSourceBankType = (bankType: BANK_TYPE) => ({
  type: SET_FEE_FUNDING_BANK_TYPE,
  payload: bankType,
});

export const showUSHolidayUSPCLatency = () => ({
  type: SHOW_US_HOLIDAY_NOTIFICATION,
});
export const hideUSHolidayUSPCLatency = () => ({
  type: HIDE_US_HOLIDAY_NOTIFICATION,
});

export const cancelAndRetryPaymentSuccessAction = (payment: PaymentType) => ({
  type: CANCEL_AND_RETRY_PAYMENT_SUCCESS,
  payment,
});

export const cancelAndRetryPaymentErrorAction = (errorCode?: string | null) => ({
  type: CANCEL_AND_RETRY_PAYMENT_ERROR,
  errorCode,
});

export const cancelAndRetryPaymentAction = (
  resolve: () => void,
  reject: (error: any) => void
): CreatePaymentType => ({
  type: CANCEL_AND_RETRY_PAYMENT,
  resolve,
  reject,
});

export const setIsCancelAndRetryFlowAction = (payload: boolean) => ({
  type: SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW,
  payload,
});

export const fetchDeliveryOptionsAction = (payload: { scheduledDate: Date }) => ({
  type: FETCH_DELIVERY_OPTIONS,
  payload,
});

export const fetchDeliveryOptionsSuccessAction = (payload: FetchDeliveryOptionsResponse) => ({
  type: FETCH_DELIVERY_OPTIONS_SUCCESS,
  payload,
});

export const fetchDeliveryOptionsFailureAction = (errorCode: string) => ({
  type: FETCH_DELIVERY_OPTIONS_FAILURE,
  errorCode,
});

export const fetchInitialDeliveryOptionsAction = () => ({
  type: FETCH_INITIAL_DELIVERY_OPTIONS,
});

export const fetchInitialDeliveryOptionsSuccessAction = (
  payload: FetchInitialDeliveryOptionsResponse
) => ({
  type: FETCH_INITIAL_DELIVERY_OPTIONS_SUCCESS,
  payload,
});

export const fetchInitialDeliveryOptionsFailureAction = (errorCode: string) => ({
  type: FETCH_INITIAL_DELIVERY_OPTIONS_FAILURE,
  errorCode,
});

export const setDeliveryOptionsAction = (deliveryOptions: DeliveryOptionType[]) => ({
  type: SET_DELIVERY_OPTIONS,
  payload: deliveryOptions,
});

export const setSyncPaymentErrorCode = (syncPaymentErrorCode: string) => ({
  type: SET_SYNC_PAYMENT_ERROR_CODE,
  payload: syncPaymentErrorCode,
});
