import * as React from 'react';
import { RecordOf } from 'immutable';
import styled from 'styled-components';
import MIButton from 'src/app/components/common/MIButton';
import MISecurityDetails from 'src/app/components/common/MISecurityDetails';
import MINotificationCard from 'src/app/components/common/MINotificationCard';
import SelectItemCard from 'src/app/components/common/SelectItemCard';
import DeliveryMethodsList from 'src/app/pages/settings/components/DeliveryMethodsList';
import { CONSTS, NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import { DeliveryMethodType } from 'src/app/utils/types';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { Option } from '../SelectBankAccountVerificationTypePageContainer';

type Props = {
  options: Option[];
  goExit: () => void;
  goDashboard: () => void;
  vendorId: string;
  deliveryMethods: RecordOf<DeliveryMethodType>[];
  showUnverifiedAchLimitNotification: boolean;
};

const SelectBankAccountVerificationTypePage = ({
  options,
  goExit,
  goDashboard,
  vendorId,
  deliveryMethods,
  showUnverifiedAchLimitNotification,
}: Props) => {
  const site = useSiteContext();
  const isACHDeliveryMethodExists =
    vendorId && deliveryMethods.some((dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.ACH);
  const getSubTitle = () => {
    let subTitle = 'onboarding.fundingSources.bank.verification.paymentSubtitle';

    if (vendorId) {
      subTitle = isACHDeliveryMethodExists
        ? 'onboarding.deliveryMethods.bank.verifySubtitle'
        : 'onboarding.fundingSources.bank.verification.deliverySubtitle';
    }

    return subTitle;
  };

  const renderFooter = () => (
    <>
      <MISecurityDetails eventPage="onboarding-vendor-delivery-method" />
      {isACHDeliveryMethodExists && (
        <ButtonContainer>
          <MIButton
            label="onboarding.deliveryMethods.bank.verifyCta"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={goDashboard}
            fullWidth
          />
        </ButtonContainer>
      )}
    </>
  );

  return (
    <site.components.StepLayout
      title={
        isACHDeliveryMethodExists
          ? 'onboarding.deliveryMethods.bank.verifyTitle'
          : 'onboarding.fundingSources.bank.verification.title'
      }
      subtitle={getSubTitle()}
      goExit={goExit}
      innerSize={50}
      footer={vendorId && renderFooter()}
      hideHeader
    >
      {isACHDeliveryMethodExists ? (
        <DeliveryMethodsListContainer>
          <DeliveryMethodsList deliveryMethods={deliveryMethods} />
        </DeliveryMethodsListContainer>
      ) : (
        <Container>
          {showUnverifiedAchLimitNotification && (
            <NotificationCard
              type={NOTIFICATION_CARD_TYPES.ERROR}
              subtitle={{
                label: 'onboarding.fundingSources.bank.verification.manual.limit',
              }}
            />
          )}
          {options.map(({ id, label, description, onClick }, index) => (
            <React.Fragment key={id}>
              <SelectItemCard
                id={id}
                testId={`verification-type-${id}`}
                onClick={onClick}
                label={label}
                description={description}
              />
              {options.length === 2 && index === 0 && (
                <OrLineWrapper>
                  <site.components.WizardElements.WizardOrLine />
                </OrLineWrapper>
              )}
            </React.Fragment>
          ))}
        </Container>
      )}
    </site.components.StepLayout>
  );
};

export default SelectBankAccountVerificationTypePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrLineWrapper = styled.div`
  ${(props) => props.theme?.pages?.SelectBankAccountVerificationTypePage?.OrLineWrapper}
`;

const DeliveryMethodsListContainer = styled.div`
  margin: -1rem 0 4rem;
`;

const ButtonContainer = styled.div`
  margin-top: 4rem;
  width: 100%;
`;

const NotificationCard = styled(MINotificationCard)`
  margin-bottom: 2rem;
`;
