export const enum FormControlTypeEnum {
  TEXT = 'text',
  LINK = 'link',
  CURRENCY = 'currency',
  ADDRESS = 'address',
  DATE = 'date',
  SELECT = 'select',
  DROP_DOWN = 'drop-down',
  RADIO = 'radio',
  TEL = 'tel',
  FILE = 'file',
}
