import React from 'react';
import head from 'lodash/head';
import { RecordOf } from 'immutable';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withNavigator } from 'src/app/hoc';
import { GlobalState } from 'src/app/redux/types';
import { AreaLoader } from '@melio/billpay-design-system';
import { getPartialBillId } from 'src/app/utils/bills';
import api from 'src/app/services/api/delivery';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import {
  getInitialProcessingDates,
  isAfterEndOfLife,
  getEndOfLifeMaxDate,
} from 'src/app/utils/dates';
import {
  selectPaymentDatesAction,
  setDeliveryOptionsAction,
} from 'src/app/redux/payBillWizard/actions';
import { removeUnsupportedDeliveryOptionsByBill } from 'src/app/utils/delivery-methods';
import { getFundingSourceType } from 'src/app/utils/funding-sources';
import { isNumber, getLayoutHeaderLabel } from 'src/app/pages/bill/utils';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import {
  BillType,
  CompanyInfoType,
  DeliveryOptionType,
  OrganizationPreferencesType,
  PaymentType,
} from 'src/app/utils/types';
import {
  getBill,
  getPayment,
  getSelectedFundingSource,
  getIsRecurring,
} from 'src/app/redux/payBillWizard/selectors';
import { globalErrorOccurred } from 'src/app/redux/error/actions';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { getOrgId, getCompanyInfo } from 'src/app/redux/user/selectors';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { FundingSource } from 'src/app/version-2/model/dtos';
import PayBillDatePage from './components/PayBillDatePage/PayBillDatePage';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

type State = {
  isInnerLoading: boolean;
  deliveryDate?: Date;
  maxDeliveryDate?: Date;
  minScheduledDate?: Date;
  scheduledDate?: Date;
  deliveryOptions?: DeliveryOptionType[];
};

type MapStateToProps = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  orgId: string;
  selectedFundingSource?: FundingSource;
  isRecurring?: boolean;
  companyInfo: RecordOf<CompanyInfoType>;
  organizationPreferences: OrganizationPreferencesType;
};

type MapDispatchToProps = {
  selectPaymentDates: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    deliveryPreference?: string
  ) => void;
  globalErrorOccurred: (title: string | undefined, subtitle: string | undefined) => void;
  setDeliveryOptions: (deliveryOptions: DeliveryOptionType[]) => void;
};

type Props = {
  site: any;
} & PayBillProps &
  MapStateToProps &
  MapDispatchToProps;
const eventPage = 'pay-bill';

class PayBillDatePageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInnerLoading: false,
      minScheduledDate: undefined,
      deliveryDate: undefined,
      maxDeliveryDate: undefined,
      scheduledDate: undefined,
      deliveryOptions: undefined,
    };
  }

  componentDidMount() {
    const { payment, navigate, bill } = this.props;

    if (!isNumber(payment.deliveryMethodId)) {
      return navigate(locations.Bills.pay.deliveryMethod.url({ id: bill.id }));
    }

    return this.loadSuggestedProcessingDate();
  }

  getSelectedDeliveryPreference = (options: DeliveryOptionType[]) => {
    const { bill, payment } = this.props;
    const { deliveryType } = bill.getDeliveryMethodById(payment.deliveryMethodId);
    const isDeclinedPayment = payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED;
    const deliveryPreference = payment?.deliveryPreference;
    const currentSelected = options.filter((opt) => opt.type === deliveryPreference);

    if (currentSelected.length > 0 && !isDeclinedPayment) {
      return currentSelected[0]?.type;
    }

    return head(options)?.type || deliveryType;
  };

  getMaxDeliveryDate = (maxDeliveryDate?: Date) => {
    const { organizationPreferences } = this.props;

    if (!maxDeliveryDate) {
      return getEndOfLifeMaxDate(!!organizationPreferences.billPayFirstWaveUser);
    }

    if (isAfterEndOfLife(maxDeliveryDate, !!organizationPreferences.billPayFirstWaveUser)) {
      return getEndOfLifeMaxDate(!!organizationPreferences.billPayFirstWaveUser);
    }

    return maxDeliveryDate;
  };

  // bypasses are used from outside
  // to prevent race conditions/closure restrictions while switching a funding source
  async loadSuggestedProcessingDate(bypasses?: any) {
    const {
      payment,
      bill,
      orgId,
      site,
      companyInfo,
      selectedFundingSource,
      setDeliveryOptions,
      organizationPreferences,
    } = this.props;
    const { deliveryMethodId, fundingSourceId, scheduledDate, amount, payBillFlowUUID } = payment;

    this.setState({ isInnerLoading: true });
    try {
      const {
        suggestedScheduledDate,
        minScheduledDate,
        deliveryDate,
        maxDeliveryDate,
        deliveryOptions,
      } = await getInitialProcessingDates({
        orgId,
        deliveryMethodId: Number.parseInt(deliveryMethodId, 10),
        fundingSourceId: fundingSourceId || selectedFundingSource?.id,
        scheduledDate,
        amount,
        dueDate: bill.dueDate,
        paymentId: payment?.id,
        payBillFlowUUID,
        ...bypasses,
        isFirstWave: !!organizationPreferences?.billPayFirstWaveUser,
      });
      const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByBill({
        site,
        deliveryOptions,
        bill,
        payment,
        fundingSource: selectedFundingSource as FundingSource,
        companyInfo,
      });

      setDeliveryOptions(possibleDeliveryOptions);

      if (possibleDeliveryOptions?.length > 1) {
        const { deliveryType } = bill.getDeliveryMethodById(payment.deliveryMethodId);

        if (deliveryType === 'check') {
          analytics.track(
            eventPage,
            possibleDeliveryOptions?.length === 2 ? 'express-check-shown' : 'express-fast-shown',
            {
              partialBillId: getPartialBillId(bill),
            }
          );
        } else if (deliveryType === 'ach') {
          const { selectedFundingSource } = this.props;
          const fundingSourceType = getFundingSourceType(selectedFundingSource);

          analytics.track(eventPage, 'fast-ach-shown', {
            billId: bill.id,
            fundingSourceType,
            partialBillId: getPartialBillId(bill),
          });
        }
      }

      this.setState({
        isInnerLoading: false,
        scheduledDate: new Date(suggestedScheduledDate),
        deliveryDate,
        maxDeliveryDate: new Date(maxDeliveryDate),
        minScheduledDate: new Date(minScheduledDate),
        deliveryOptions: possibleDeliveryOptions,
      });
      const selectedDeliveryPreference =
        this.getSelectedDeliveryPreference(possibleDeliveryOptions);
      const paymentDate = getPaymentDate(
        suggestedScheduledDate,
        deliveryDate,
        maxDeliveryDate,
        possibleDeliveryOptions,
        selectedDeliveryPreference
      );

      const maxEOLDeliveryDate = this.getMaxDeliveryDate(paymentDate.maxDeliveryDate);

      this.props.selectPaymentDates(
        paymentDate.scheduledDate,
        paymentDate.deliveryDate,
        maxEOLDeliveryDate,
        selectedDeliveryPreference
      );
    } catch (e) {
      this.setState({ isInnerLoading: false });
    }
  }

  isLoading = () => this.props.isLoading || this.state.isInnerLoading;

  onOptionSelected = (scheduledDate, deliveryDate, maxDeliveryDate, type) => {
    const maxEOLDeliveryDate = this.getMaxDeliveryDate(maxDeliveryDate);

    this.setState({
      scheduledDate,
      deliveryDate,
      maxDeliveryDate: maxEOLDeliveryDate,
    });

    const { selectedFundingSource, bill } = this.props;
    const properties = {
      billId: bill.id,
      partialBillId: getPartialBillId(bill),
      fundingSourceId: selectedFundingSource?.id,
      fundingSourceType: getFundingSourceType(selectedFundingSource),
    };

    analytics.track(eventPage, `delivery-selected-${type || 'regular'}`, properties);

    this.props.selectPaymentDates(scheduledDate, deliveryDate, maxEOLDeliveryDate, type);
  };

  handleDateSelected = (selectedScheduledDate: Date) => {
    const { orgId, bill, payment, site, companyInfo, selectedFundingSource, setDeliveryOptions } =
      this.props;

    this.setState({ isInnerLoading: true });
    api
      .getDeliveryTime(
        orgId,
        selectedScheduledDate,
        Number.parseInt(payment.deliveryMethodId, 10),
        payment.fundingSourceId,
        payment.amount as number,
        payment?.id,
        payment?.payBillFlowUUID
      )
      .then(({ deliveryDate, suggestedScheduledDate, maxDeliveryDate, deliveryOptions }) => {
        const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByBill({
          site,
          deliveryOptions,
          bill,
          payment,
          fundingSource: selectedFundingSource as FundingSource,
          companyInfo,
        });

        setDeliveryOptions(possibleDeliveryOptions);

        const selectedDeliveryPreference =
          this.getSelectedDeliveryPreference(possibleDeliveryOptions);

        let maxEOLDeliveryDate = this.getMaxDeliveryDate(maxDeliveryDate);

        this.setState({
          isInnerLoading: false,
          scheduledDate: suggestedScheduledDate,
          deliveryDate,
          maxDeliveryDate: maxEOLDeliveryDate,
          deliveryOptions: possibleDeliveryOptions,
        });

        const paymentDate = getPaymentDate(
          suggestedScheduledDate,
          deliveryDate,
          maxDeliveryDate,
          possibleDeliveryOptions,
          selectedDeliveryPreference
        );

        maxEOLDeliveryDate = this.getMaxDeliveryDate(paymentDate.maxDeliveryDate);

        this.props.selectPaymentDates(
          paymentDate.scheduledDate,
          paymentDate.deliveryDate,
          paymentDate.maxDeliveryDate,
          selectedDeliveryPreference
        );
        analytics.track(eventPage, 'select-date', {
          suggestedScheduledDate,
          deliveryDate,
          maxDeliveryDate: maxEOLDeliveryDate,
          partialBillId: getPartialBillId(bill),
        });
      })
      .catch(() => {
        this.setState({ isInnerLoading: false });
      });
  };

  render() {
    const {
      bill,
      payment,
      goExit,
      onNextSelectDate,
      onPrevDate,
      selectedFundingSource,
      isRecurring,
    } = this.props;
    const { scheduledDate, deliveryDate, maxDeliveryDate, minScheduledDate, deliveryOptions } =
      this.state;
    const selectedDeliveryMethod = bill.getDeliveryMethodById(payment.deliveryMethodId);

    return (
      <>
        {scheduledDate && deliveryDate && maxDeliveryDate ? (
          <PayBillDatePage
            bill={bill}
            selectedFundingSource={selectedFundingSource}
            deliveryPreference={payment.deliveryPreference as string}
            scheduledDate={scheduledDate}
            deliveryDate={deliveryDate}
            maxDeliveryDate={maxDeliveryDate}
            deliveryOptions={deliveryOptions}
            deliveryMethodType={selectedDeliveryMethod?.deliveryType}
            onSelectDeliveryOption={this.onOptionSelected}
            minScheduledDate={minScheduledDate}
            onNext={onNextSelectDate}
            onPrev={onPrevDate}
            onChange={this.handleDateSelected}
            isLoading={this.isLoading()}
            goExit={goExit}
            title="bills.pay.date.title"
            headerLabel={getLayoutHeaderLabel({
              isRecurring,
              deliverFailureData: payment?.deliverFailureData,
            })}
            subtitle="bills.pay.date.subtitle"
            relativeStep={3 / 5}
            fundingSourceType={getFundingSourceType(selectedFundingSource)}
            loadSuggestedProcessingDate={(bypasses?: any) =>
              this.loadSuggestedProcessingDate(bypasses)
            }
          />
        ) : (
          <AreaLoader placement="wizard" />
        )}
      </>
    );
  }
}

const getPaymentDate = (
  defaultScheduledDate: Date,
  defaultDeliveryDate: Date,
  defaultMaxDeliveryDate: Date,
  deliveryOptions: DeliveryOptionType[],
  type: string
) => {
  const fastAchOption = deliveryOptions?.find((option) => option.type === type);

  return fastAchOption
    ? {
        scheduledDate: fastAchOption.scheduledDate,
        deliveryDate: fastAchOption.deliveryDate,
        maxDeliveryDate: fastAchOption.maxDeliveryDate,
      }
    : {
        scheduledDate: defaultScheduledDate,
        deliveryDate: defaultDeliveryDate,
        maxDeliveryDate: defaultMaxDeliveryDate,
      };
};

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  payment: getPayment(state),
  bill: getBill(state),
  orgId: getOrgId(state),
  selectedFundingSource: getSelectedFundingSource(state),
  isRecurring: getIsRecurring(state),
  companyInfo: getCompanyInfo(state),
  organizationPreferences: getOrganizationPreferences(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectPaymentDates(
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    deliveryPreference?: string
  ) {
    dispatch(
      selectPaymentDatesAction(
        scheduledDate,
        deliveryEta,
        maxDeliveryEta,
        deliveryPreference as string
      )
    );
  },
  globalErrorOccurred(title?: string, subtitle?: string) {
    dispatch(globalErrorOccurred(title, subtitle));
  },
  setDeliveryOptions(deliveryOptions: DeliveryOptionType[]) {
    dispatch(setDeliveryOptionsAction(deliveryOptions));
  },
});

export default compose(
  withNavigator(),
  withPayBillData(),
  withSiteContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(PayBillDatePageContainer);
