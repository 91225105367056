import React from 'react';
import styled from 'styled-components';
import { useEditCheckAddressStatus } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import { AreaLoader } from '@melio/billpay-design-system';
import GeneralFlowErrorContainer from 'src/app/pages/quickbooks/GeneralFlowErrorContainer';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import paymentStore from 'src/app/modules/payments/payment-store';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import failedToLoad from 'src/app/images/qbo/failed-to-load-002-icon.png';

type Props = {
  token: string;
};

const PaymentCancelled = ({ token }: Props) => {
  const { paymentId } = getJWTPayload(token);
  const { errorData } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const { isPaymentLoading } = useEditCheckAddressStatus({
    token,
  });

  const site = useSiteContext();

  if (isPaymentLoading) return <AreaLoader />;

  const companyEmail = errorData?.data?.email;
  const supportEmail = site.config.support.email;

  return (
    <GeneralFlowErrorContainer
      hideExitButton
      illustration={failedToLoad}
      illustrationStyle={{ width: 96, height: 96, marginBottom: '2rem' }}
      subtitle="vendors.editCheckAddress.paymentCancelled.description"
      subtitleValues={{
        companyName: errorData?.data?.companyName,
        companyEmail: <EmailLink href={`mailto:${companyEmail}`}>{companyEmail}</EmailLink>,
        supportEmail: <EmailLink href={`mailto:${supportEmail}`}>{supportEmail}</EmailLink>,
      }}
    />
  );
};

export default PaymentCancelled;

const EmailLink = styled.a`
  text-decoration: none;
  color: #2ca01c;
`;
