import omit from 'lodash/omit';

import { isValidDateOfBirth } from 'src/app/utils/user-utils';
import { REGEX } from 'src/app/utils/consts';

import { ActiveForms, ErrorForms } from './types';

export const FORMS = ['form1', 'form2', 'form3', 'form4'];
export const ERROR_PREFIX = 'international.ultimateBeneficialOwners.inputErrors';
export const MAX_FORMS = FORMS.length;

export const uboTaxIdMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const initialErrorValues = (): ErrorForms => ({
  form1: {},
  form2: {},
  form3: {},
  form4: {},
});

export const formState = () => ({
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  taxId: '',
  addressText: '', // for UI only - will be removed on payload
  address: '',
  city: '',
  state: '',
  country: 'US',
  zipCode: '',
});

export const getInitialFormState = () => ({
  form1: formState(),
  form2: formState(),
  form3: formState(),
  form4: formState(),
});

export const getFieldsValidations = (values) => [
  {
    name: 'firstName',
    value: values.firstName.value,
    isRequired: true,
  },
  {
    name: 'lastName',
    value: values.lastName.value,
    isRequired: true,
  },
  {
    name: 'dateOfBirth',
    value: values.dateOfBirth.value,
    isRequired: true,
    customCheck: () => {
      const { value } = values.dateOfBirth;

      if (!value) return `${ERROR_PREFIX}.dateOfBirth.empty`;

      if (!isValidDateOfBirth(value)) return `${ERROR_PREFIX}.dateOfBirth.regex`;

      return '';
    },
  },
  {
    name: 'taxId',
    value: values.taxId.value,
    regex: REGEX.TAX_ID_NUMBER,
    isRequired: true,
  },
  {
    name: 'address',
    value: values.address.value,
    isRequired: true,
  },
];

// getting the next available form in list
export const getNextForm = (activeForms: ActiveForms): string => {
  const diffs = FORMS.filter((e) => !activeForms.includes(e));

  return diffs?.[0];
};

export const shouldDisplayDelete = (activeForms: ActiveForms): boolean => {
  if (activeForms.length === 1) return false;

  return true;
};

export const shouldDisplayAdd = (activeForms: ActiveForms, namespace: string): boolean => {
  if (activeForms.length === MAX_FORMS || activeForms[activeForms.length - 1] !== namespace)
    return false;

  return true;
};

export const convertDataToPayload = (data: Record<string, any>): Record<string, any>[] => {
  const values = Object.values(data);

  return values.map((fields) => omit(fields, ['addressText']));
};
