import locations from 'src/app/utils/locations';

import { RecoveryFlowType } from '../consts';

export const ANALYTICS_PAGE_NAME = 'bill';

export const getBorder = ({ selected, disabled, colors }) => {
  if (disabled) {
    return `0.1rem solid ${colors.dark.translucent2}`;
  }

  if (selected) {
    return `0.2rem solid ${colors.primary.opaque}`;
  }

  return 'none';
};

export const getFormattedDateRange = (deliveryDate: string, maxDeliveryDate: string) => {
  if (!deliveryDate || !maxDeliveryDate) return '';

  const deliveryDateMonth = new Date(deliveryDate).toLocaleDateString('en-gb', { month: 'short' });
  const deliveryDateDay = new Date(deliveryDate).toLocaleDateString('en-gb', { day: 'numeric' });
  const maxDeliveryDateMonth = new Date(maxDeliveryDate).toLocaleDateString('en-gb', {
    month: 'short',
  });
  const maxDeliveryDateDay = new Date(maxDeliveryDate).toLocaleDateString('en-gb', {
    day: 'numeric',
  });

  if (deliveryDateDay === maxDeliveryDateDay) return `${deliveryDateMonth} ${deliveryDateDay}`;

  return `${deliveryDateMonth} ${deliveryDateDay} - ${maxDeliveryDateMonth} ${maxDeliveryDateDay}`;
};

export const getConfirmURL = (flowType: string) => {
  if (flowType === RecoveryFlowType.VOID_CHECK) {
    return locations.Bills.pay.edit.confirm;
  }

  return locations.Bills.pay.confirm;
};
