import React from 'react';
import { RecordOf } from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/app/utils/consts';
import { NavigateType, OrganizationBillingFee, UserContextType } from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import { withNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import {
  getProfile,
  getFundingSources,
  getIsLoading,
  getIsFundingSourceDeleting,
} from 'src/app/redux/user/selectors';
import { deleteFundingSourceAction, loadFundingSourcesAction } from 'src/app/redux/user/actions';
import { getOrgFeeFundingSource } from 'src/app/redux/organization/selectors';
import { removeOrganizationFeeFundingSource } from 'src/app/redux/organization/actions';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import PaymentMethodsSettingsPage from './components/PaymentMethodsSettingsPage';

const eventPage = 'settings-payment-methods';
const settingsBillingEventPage = 'settings-billing';

export type EditFundingSourceLabelState = {
  id: number;
  currentNickname?: string | null;
} | null;

export type DeleteFundingSourceErrorData = {
  payments?: number[];
  failedPayments?: number[];
} | null;

type State = {
  editFundingSourceLabel: EditFundingSourceLabelState;
  deletingId: number | null;
  errorCode: string;
  errorData: DeleteFundingSourceErrorData;
  verifyingId: number | null;
  fundingSource: FundingSource | null;
};

type MapStateToProps = {
  fundingSources: FundingSource[];
  orgFeeFundingSource?: OrganizationBillingFee;
  isLoading: boolean;
  isFundingSourceDeleting: boolean;
  profile: RecordOf<UserContextType>;
};

type MapDispatchToProps = {
  deleteFundingSource: (id: number) => Promise<void>;
  loadFundingSources: () => void;
  deleteOrgFeeFundingSource: (id: number) => void;
};

type Props = {
  navigate: NavigateType;
  query: {
    verifyFundingSourceId?: string;
  };
} & MapStateToProps &
  MapDispatchToProps;

class PaymentMethodsSettingsPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editFundingSourceLabel: null,
      deletingId: null,
      verifyingId: null,
      errorCode: '',
      errorData: null,
      fundingSource: null,
    };
  }

  componentDidMount = () => {
    const { query, fundingSources } = this.props;
    const verifyingId = Number(query.verifyFundingSourceId);
    const fundingSourceToVerify = fundingSources.find((fs) => fs.id === verifyingId);

    if (fundingSourceToVerify?.bankAccount?.canVerify) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ verifyingId });
    }
  };

  onEditLabelClicked = (id: number, currentNickname: string) => {
    analytics.trackAction('add-payment-method-label', {
      id,
    });
    this.setState({
      editFundingSourceLabel: {
        id,
        currentNickname,
      },
    });
  };

  onDeleteClicked = (id: number) => {
    const { fundingSources } = this.props;
    const fundingSource = fundingSources.filter((fs) => fs.id === id)[0];

    this.setState({
      deletingId: id,
      fundingSource,
    });
  };

  onDeletePaymentMethodCanceled = () => {
    const { fundingSource } = this.state;

    analytics.track(settingsBillingEventPage, 'remove-payment-method-cancel', {
      paymentMethodId: fundingSource?.id,
      paymentMethodType: fundingSource?.fundingType,
    });

    this.setState({
      deletingId: null,
      fundingSource: null,
    });
  };

  onDeleteFailedError = () => {
    this.setState({ errorCode: '', errorData: null });
  };

  onDeleteConfirmed = () => {
    const { deletingId, fundingSource } = this.state;
    const { orgFeeFundingSource, deleteFundingSource } = this.props;

    analytics.track(settingsBillingEventPage, 'remove-payment-method', {
      paymentMethodId: fundingSource?.id,
      paymentMethodType: fundingSource?.fundingType,
    });

    if (deletingId && deletingId !== orgFeeFundingSource?.fundingSourceId) {
      deleteFundingSource(deletingId)
        .then(() => {
          this.setState({ deletingId: null });
        })
        .catch((error) => {
          this.setState({
            deletingId: null,
            errorCode: error && error.code ? error.code : '',
            errorData: error?.responseData,
          });
        });
    } else {
      this.setState({
        deletingId: null,
        errorCode: 'USED_AS_CF',
        errorData: {},
      });
    }
  };

  onVerifyClicked = (id: number) => {
    analytics.track(eventPage, 'verify-click');
    this.setState({ verifyingId: id });
  };

  onVerifyFinished = () => {
    analytics.track(eventPage, 'verify-finish');
    this.setState({
      verifyingId: null,
    });
  };

  onVerifyFailed = () => {
    this.props.loadFundingSources();
  };

  goAddFundingSources = (type: string) => {
    analytics.track(settingsBillingEventPage, 'add-new-payment-method', {
      paymentMethodType: type,
    });

    if (type === FundingSourceTypesEnum.ACH) {
      analytics.track(eventPage, 'add-bank-account');
      this.props.navigate(locations.Onboarding.fundingSources.bank.select.url(), false, {
        redirectUrl: locations.Settings.paymentMethods.url(),
        exitUrl: locations.Settings.paymentMethods.url(),
        preservedState: {
          origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.SETTINGS,
        },
      });
    } else {
      analytics.track(eventPage, `add-${type}-card`);
      this.props.navigate(locations.Onboarding.fundingSources.card.index.url(), false, {
        redirectUrl: locations.Settings.paymentMethods.url(),
        exitUrl: locations.Settings.paymentMethods.url(),
      });
    }
  };

  goEditFundingSource = (fundingSource: FundingSource) => {
    const { navigate } = this.props;

    analytics.track(
      eventPage,
      fundingSource.fundingType === FundingSourceTypesEnum.ACH
        ? 'plaid-edit-start'
        : 'card-edit-start'
    );

    navigate(locations.Onboarding.fundingSources.bank.account.url(), false, {
      redirectUrl: locations.Settings.paymentMethods.url(),
      exitUrl: locations.Settings.paymentMethods.url(),
      fundingSourceId: fundingSource.id,
      editMode: true,
    });
  };

  goViewFundingSource = (fundingSource: FundingSource) => {
    analytics.track(eventPage, 'manual-view-start');
    const { navigate } = this.props;

    navigate(
      locations.Onboarding.fundingSources.bank.manuallyView.url({
        id: fundingSource.id,
      })
    );
  };

  resetFundingSourceLabelEdit = () => {
    this.setState({
      editFundingSourceLabel: null,
    });
  };

  modifyActions = (fs) => ({
    view: () => this.goViewFundingSource(fs),
    edit: () => this.goEditFundingSource(fs),
    editLabel: () => this.onEditLabelClicked(fs.id, fs.nickname),
    delete: () => this.onDeleteClicked(fs.id),
  });

  render() {
    const { fundingSources, isLoading, isFundingSourceDeleting } = this.props;
    const { deletingId, errorCode, verifyingId, editFundingSourceLabel, errorData } = this.state;
    const fundingSourceToDelete = fundingSources.filter((fs) => fs.id === deletingId)[0];

    return (
      <PaymentMethodsSettingsPage
        onDeleteConfirmed={this.onDeleteConfirmed}
        onDeleteFailedError={this.onDeleteFailedError}
        onDeletePaymentMethodCanceled={this.onDeletePaymentMethodCanceled}
        goAddFundingSources={this.goAddFundingSources}
        isSingleLoading={isLoading || isFundingSourceDeleting}
        errorCode={errorCode}
        errorData={errorData}
        deletingId={deletingId}
        editingFundingSourceLabel={editFundingSourceLabel}
        resetFundingSourceLabelEdit={this.resetFundingSourceLabelEdit}
        fundingSourceToDelete={fundingSourceToDelete}
        fundingSources={fundingSources}
        onVerifyClicked={this.onVerifyClicked}
        onVerifyFinished={this.onVerifyFinished}
        onVerifyFailed={this.onVerifyFailed}
        verifyingId={verifyingId}
        modifyActions={this.modifyActions}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  fundingSources: getFundingSources(state),
  orgFeeFundingSource: getOrgFeeFundingSource(state),
  isLoading: getIsLoading(state),
  isFundingSourceDeleting: getIsFundingSourceDeleting(state),
  profile: getProfile(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  deleteFundingSource(id: number) {
    return new Promise((resolve, reject) => {
      dispatch(deleteFundingSourceAction(id, resolve, reject));
    });
  },
  deleteOrgFeeFundingSource(id: number) {
    dispatch(removeOrganizationFeeFundingSource(id));
  },
  loadFundingSources() {
    dispatch(loadFundingSourcesAction());
  },
});

export default compose(
  withNavigator(),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentMethodsSettingsPageContainer);
