import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Link, SmartIcon } from '@melio/billpay-design-system';
import { iframeEventsApi } from 'src/app/version-2/api/iframeEvents.api';
import { analytics } from 'src/app/version-2/externals';
import { FUNDING_TYPE } from 'src/app/version-2/pages/landing-pages/model/constants/fundingTypes';
import { landingPageActions } from 'src/app/version-2/pages/landing-pages/modules/landingPage.slice';
import { Close, Footnote, PageContainer, Subtitle } from './PlansLandingPage.styles';
import { Plans } from '../index';

type Props = {
  onRegister: (plan: string) => void;
  isLoading?: boolean;
};

const EVENT_PAGE = 'pay-bill';
const EVENT_FUNDING_SOURCE_SELECTED_NAME = 'plan-page-funding-source-selected';

export const PlansLandingPage = ({ onRegister, isLoading }: Props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  useEffect(() => {
    setPlans(formatMessage({ id: 'paymentMethods.options' }).split(','));
    dispatch(landingPageActions.setIsNewLpEntry(true));
  }, []);

  const onPlanSelect = (selectedPlan: string) => {
    setSelected(selectedPlan);
    analytics.track(EVENT_PAGE, EVENT_FUNDING_SOURCE_SELECTED_NAME, {
      fundingSourceType: FUNDING_TYPE[selectedPlan],
    });

    dispatch(landingPageActions.setSelectedPlan(FUNDING_TYPE[selectedPlan]));
  };

  const onClickHandler = () => {
    onRegister(FUNDING_TYPE[selected]);
  };

  const onClose = () => {
    iframeEventsApi.melioClose();
  };

  return (
    <PageContainer
      title={formatMessage({ id: 'landingPage.title' })}
      subtitle={<Subtitle>{formatMessage({ id: 'landingPage.subtitle' })}</Subtitle>}
      footnote={
        <Footnote>
          {formatMessage(
            { id: 'landingPage.footnote' },
            {
              melio: <Link href="#">{formatMessage({ id: 'landingPage.melio' })}</Link>,
              terms: <Link href="#">{formatMessage({ id: 'landingPage.terms' })}</Link>,
              policy: <Link href="#">{formatMessage({ id: 'landingPage.policy' })}</Link>,
              data: <Link href="#">here</Link>,
            }
          )}
        </Footnote>
      }
    >
      <Close onClick={onClose}>
        <SmartIcon type="close" size="1.2rem" />
      </Close>

      <Plans plans={plans} onSelect={onPlanSelect} />

      <Button
        onClick={onClickHandler}
        isDisabled={!selected}
        isLoading={isLoading}
        variant="primary"
        size="md"
      >
        {formatMessage({ id: 'landingPage.button' })}
      </Button>
    </PageContainer>
  );
};
