import React, { useContext } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import { useSelector } from 'react-redux';
import profileStore from 'src/app/modules/profile/profile-store';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import Highlighter from 'react-highlight-words';
import { NavLink, useHistory } from 'react-router-dom';
import { BILL_STATUS, PAYMENT_STATUS, TAG_VARIANT } from 'src/app/utils/consts';
import {
  MIFormattedText,
  MIFormattedDate,
  MIFieldOrEmpty,
  MIFormattedCurrency,
} from 'src/app/utils/formatting';
import { shouldDisplayPopover } from 'src/app/version-2/components/BillsPopover';
import { encodeQuery } from 'src/app/utils/query-utils';
import { BillStatusType, BillType } from 'src/app/utils/types';
import { Payment } from 'src/app/version-2/model/dtos';
import { MiMoneyFlavorEnum } from 'src/app/version-2/model/enums';
import styled, { ThemeContext, css } from 'styled-components';
import MIMoney from '../common/MIMoney';
import MITag from '../common/MITag';

type Props = {
  id: string;
  vendorName?: string | null | undefined;
  pathname?: string;
  invoiceNumber?: string | null | undefined;
  balance: number;
  status: BillStatusType;
  query?: Record<string, any>;
  tag: string | null | undefined;
  search?: string;
  dateLabel: string;
  displayDate: string | null;
  totalAmount: BillType['totalAmount'];
  billPayments?: Payment[] | undefined;
  onClick?(event: MouseEvent): void;
};

const eventPage = 'bills';

const QBOBillListItem = ({
  id,
  invoiceNumber = null,
  displayDate,
  tag = '',
  balance,
  totalAmount,
  dateLabel,
  query,
  status,
  vendorName = null,
  pathname,
  search = '',
  billPayments,
  onClick,
}: Props) => {
  const history = useHistory();
  const theme: any = useContext(ThemeContext);
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const path: string = pathname || locations.Bills.index.url({ orgId });
  const isBillPartiallyPaid = balance < totalAmount;
  const tagVariants = {
    [PAYMENT_STATUS.FAILED]: {
      label: 'list.group.bill.listItemStatus.failed',
      variant: TAG_VARIANT.ERROR,
    },
    [BILL_STATUS.SCHEDULED]: {
      label: 'list.group.bill.listItemStatus.scheduled',
      variant: TAG_VARIANT.BRAND,
    },
    [PAYMENT_STATUS.SCHEDULED]: {
      label: 'list.group.bill.listItemStatus.scheduled',
      variant: TAG_VARIANT.BRAND,
    },
    [PAYMENT_STATUS.BLOCKED]: {
      label: 'list.group.bill.listItemStatus.scheduled',
      variant: TAG_VARIANT.BRAND,
    },
    [BILL_STATUS.PAID]: {
      label: 'list.group.bill.listItemStatus.paid',
      variant: TAG_VARIANT.BRAND,
    },
    [PAYMENT_STATUS.COMPLETED]: {
      label: 'list.group.bill.listItemStatus.paid',
      variant: TAG_VARIANT.BRAND,
    },
    markedAsPaid: {
      label: 'list.group.bill.listItemStatus.markedAsPaid',
      variant: TAG_VARIANT.BRAND,
    },
    vcSent: {
      label: 'list.group.bill.listItemStatus.sent',
      variant: TAG_VARIANT.BRAND,
    },
    vcCompleted: {
      label: 'list.group.bill.listItemStatus.completed',
      variant: TAG_VARIANT.BRAND,
      isSecondary: true,
    },
  };
  const overdue = moment(displayDate).isBefore(moment());

  const canSchedule = status === BILL_STATUS.UNPAID && tag !== PAYMENT_STATUS.FAILED;
  const listSearchPath = encodeQuery({ ...query, id }, [], '');
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);

      return;
    }

    if (canSchedule) {
      analytics.track(eventPage, 'nav-to-single', { status });
    }

    history.push({ pathname: path, search: listSearchPath });
  };
  const scheduleOnClick = () => {
    analytics.trackAction('schedule.payment');
  };

  const renderListCard = () => (
    <BillListItem onClick={handleClick}>
      <BillListItemHeader vendorName={vendorName} data-testid="list-item-vendor-name">
        <VendorName>{vendorName}</VendorName>
        <BalanceWrapper>
          <MIMoney
            size="small"
            amount={balance}
            search={search}
            flavor={MiMoneyFlavorEnum.DEFAULT}
          />
        </BalanceWrapper>
      </BillListItemHeader>
      <BillListItemData>
        <TextualData>
          {shouldDisplayPopover(billPayments) && (
            <CombinedBills>
              <MIFormattedText
                label="batchPayment.billsPopover.count"
                values={{ billsNum: billPayments?.length }}
              />
            </CombinedBills>
          )}
          <InvoiceNumber data-testid="list-item-invoice-number">
            <MIFieldOrEmpty
              color={theme.text.color.subtitle}
              value={
                <Highlighter
                  searchWords={[search]}
                  autoEscape
                  textToHighlight={invoiceNumber ? `Bill ${invoiceNumber}` : ''}
                />
              }
              label="bills.form.invoiceNumberEmpty"
            />
          </InvoiceNumber>
        </TextualData>
        <RelDate data-testid="list-item-scheduled-date">
          <MIFormattedText
            label={dateLabel}
            values={{ date: <MIFormattedDate date={displayDate} /> }}
          />
        </RelDate>
      </BillListItemData>
      {isBillPartiallyPaid ? (
        <BillListItemData>
          <RelDate>
            <MIFormattedText label="bills.form.partialPayments.billListItem.totalAmount" />
            : <MIFormattedCurrency value={totalAmount} />
          </RelDate>
          <RelDate>
            <MITag
              label="bills.form.partialPayments.billListItem.tag"
              variant={TAG_VARIANT.PARTIALLY_PAID}
            />
          </RelDate>
        </BillListItemData>
      ) : (
        <TagWrapper>{tag && tagVariants[tag] ? <MITag {...tagVariants[tag]} /> : null}</TagWrapper>
      )}
    </BillListItem>
  );

  return (
    <BillListItemContainer data-testid={`bill-list-item-container-${id}`}>
      {renderListCard()}
      {(canSchedule || isBillPartiallyPaid) && (
        <ActionButton
          data-testid="bill-list-item-action"
          onClick={scheduleOnClick}
          to={locations.Bills.pay.funding.url({ id, orgId })}
        >
          {overdue ? (
            <FailedIcon className="icon-warning-icon" />
          ) : (
            <ScheduleIcon className="icon-schedule-bill" />
          )}
          <MIFormattedText label="bills.actions.pay" />
          <ActionArrow className="icon-next-icon-2" />
        </ActionButton>
      )}
    </BillListItemContainer>
  );
};

export default QBOBillListItem;

const BillListItemContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.opaque};
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  display: block;
  overflow: hidden;
  transition-property: box-shadow;
  transition-duration: ${(props) => props.theme.animation.transition.default};
  min-height: 10rem;

  padding: 1.6rem 1.6rem 0;
  margin-bottom: 0.4rem;
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.lightGrey};
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.lightGrey};
  ${(props) => props.theme?.components?.QBOBillListItem?.ListItemContainer}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const TextualData = styled.div`
  display: inline-block;
`;

const RelDate = styled.div`
  ${baseTextStyles}
  line-height: 1.6rem;
`;
const CombinedBills = styled.div`
  ${baseTextStyles}
  line-height: 1.6rem;
`;

const InvoiceNumber = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.6rem;
`;

const BalanceWrapper = styled.div`
  margin-left: 1rem;
  * {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
`;

const BillListItem = styled(({ testId, ...props }) => (
  <div data-testid="bill-list-item-container" {...props} />
))`
  text-decoration: none;
`;

const BillListItemHeader = styled.div<{ vendorName: string | null }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    get(props, 'vendorName.length', 0) > 0 ? 'space-between' : 'flex-end'};
  align-items: center;
  margin-bottom: 0.6rem;
`;

const BillListItemData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const TagWrapper = styled.div`
  text-align: right;
`;

const VendorName = styled.div`
  ${(props) => props.theme.text.fontType.regular};
  color: ${(props) => props.theme.text.color.main};
  flex: 1;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  display: table-cell;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ActionButton = styled(NavLink)`
  ${(props) => props.theme.text.fontType.regular};
  color: ${(props) => props.theme.text.color.darkGrey};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  display: flex;
  align-items: center;
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.lightGrey};
  height: 5.6rem;
  margin-top: 1.6rem;
  text-decoration: unset;
`;
const FailedIcon = styled.i`
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.warning.opaque};
  margin-right: 0.8rem;
`;
const ScheduleIcon = styled.i`
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.light.opaque};
  margin-right: 0.8rem;
`;

const ActionArrow = styled.i`
  font-size: 2.4rem;
  margin-left: auto;
  color: ${(props) => props.theme.text.color.arrow};
`;
