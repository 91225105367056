import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { Country } from 'src/app/version-2/model/dtos';

export const COUNTRIES_SLICE = `${VERSION_2}countries`;

interface countrieSlice {
  countries: EntityState<Country>;
}

export const selectState = (state: any) => state[COUNTRIES_SLICE];

const countriesAdapter = createEntityAdapter<Country>({
  selectId: (country) => country.code,
});

export const countriesInitialState: countrieSlice = {
  countries: countriesAdapter.getInitialState(),
};

export const countriesSlice = createSlice({
  name: COUNTRIES_SLICE,
  initialState: countriesInitialState,
  reducers: {
    setCountries: (state, action) => {
      countriesAdapter.setAll(state.countries, action.payload);
    },
  },
});

export const countriesSelectors = {
  selectCountries: createSelector(selectState, (state) =>
    countriesAdapter.getSelectors().selectAll(state.countries)
  ),
};

export const countriesActions = {
  ...countriesSlice.actions,
  fetchCountries: createAction<{ vendorId: string }>(`${COUNTRIES_SLICE}/fetch-countries`),
};
