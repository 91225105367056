import React, { useState } from 'react';
import { RecordOf } from 'immutable';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import {
  isManualBankAccountNotVerified,
  canVerifyManualBankAccount,
} from 'src/app/utils/funding-sources';
import { BILL_STATUS } from 'src/app/utils/consts';
import { BillType, QBCashStateType } from 'src/app/utils/types';

import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentInfoText,
  PaymentInfoHint,
} from 'src/app/components/layout/PaymentActivityElements';
import { useFundingSourceMicroDepositState } from 'src/app/pages/settings/hooks/useFundingSourceMicroDeposits';
import VerifyMicroDeposits from 'src/app/components/micro-deposits/VerifyMicroDeposits';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import analytics from 'src/app/services/analytics';
import {
  FundingSourceTypesEnum,
  FundingSourceOrigins,
  CardTypeEnum,
} from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import FundingSourceIcon from './FundingSourceIcon';
import FundingSourceDescription from './FundingSourceDescription';

type Props = {
  fundingSource: FundingSource;
  bill: RecordOf<BillType>;
  qbCashState?: QBCashStateType;
  isRefundMethodHeader?: boolean;
};

const ViewBillPaymentActivityFundingSourceInfo = ({
  bill,
  fundingSource,
  qbCashState,
  isRefundMethodHeader,
}: Props) => {
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const microDepositEventPage = 'bill-payment_verify-manual-account';
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const [microDepositState, microDepositActions] = useFundingSourceMicroDepositState(
    microDepositEventPage,
    {
      fundingSourceId: fundingSource.id,
    }
  );
  let label = 'viewBillPaymentActivity.fundingSource.achLabel';

  if (fundingSource.origin === FundingSourceOrigins.QBCASH) {
    label = 'bills.form.paymentActivity.scheduledBill.scheduleMethodAchQBCash';
  }

  if (fundingSource.fundingType === FundingSourceTypesEnum.CARD) {
    label =
      fundingSource.cardAccount?.cardType === CardTypeEnum.CREDIT
        ? 'viewBillPaymentActivity.fundingSource.creditCardLabel'
        : 'viewBillPaymentActivity.fundingSource.debitCardLabel';
  }

  let hintValues = {};
  let hint = '';

  if (canVerifyManualBankAccount(fundingSource)) {
    hint = 'viewBillPaymentActivity.fundingSource.unverifiedBankAccountHintWithAction';
    hintValues = {
      verifyLink: (...chunks) => (
        <VerifyAccountLink
          text={React.Children.toArray(chunks) as any}
          onClick={(event) => {
            analytics.track('bill-payment', 'verify-manual-account-click');
            event.preventDefault();
            setShowVerifyDialog(true);
          }}
        />
      ),
    };
  } else if (isManualBankAccountNotVerified(fundingSource)) {
    hint = 'viewBillPaymentActivity.fundingSource.unverifiedBankAccountHint';
  }

  const paymentMethodHeaderLabel = (
    <>
      {fundingSource.nickname && `${fundingSource.nickname} | `}
      <MIFormattedText label={label} />
    </>
  );

  return (
    <>
      {showVerifyDialog && (
        <VerifyMicroDeposits
          {...microDepositState}
          {...microDepositActions}
          key={fundingSource.id}
          fundingSourceId={fundingSource.id}
          onVerifyFinished={() => {
            setShowVerifyDialog(false);
          }}
          eventPage={microDepositEventPage}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      )}
      <PaymentActivityContainer data-testid="view-payment-activity-funding-source-info">
        <PaymentActivityMainContainer>
          <PaymentInfoContainer>
            <PaymentIcon isBillPaid={isBillPaid}>
              <FundingSourceIcon fundingSource={fundingSource} />
            </PaymentIcon>
            <PaymentTextContainer>
              <PaymentMethodContainer>
                <PaymentMethodText
                  isRefundMethodHeader={!!isRefundMethodHeader}
                  data-testid="billpay-view-payment-method"
                >
                  {paymentMethodHeaderLabel}
                </PaymentMethodText>
              </PaymentMethodContainer>
              <PaymentInfoText
                data-testid="billpay-view-payment-info-text"
                isBillPaid={isBillPaid}
                isQBCash={fundingSource.origin === FundingSourceOrigins.QBCASH}
              >
                <FundingSourceDescription
                  testId="billpay-view-payment-funding-source"
                  bill={bill}
                  qbCashState={qbCashState}
                  fundingSource={fundingSource}
                />
              </PaymentInfoText>
            </PaymentTextContainer>
          </PaymentInfoContainer>
        </PaymentActivityMainContainer>
        {!isBillPaid && hint && (
          <PaymentInfoHint data-testid="billpay-view-payment-info-hint">
            <MIFormattedText label={hint} values={hintValues} />
          </PaymentInfoHint>
        )}
      </PaymentActivityContainer>
    </>
  );
};

const VerifyAccountLink = styled(MIInlineLink)`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
`;

export default ViewBillPaymentActivityFundingSourceInfo;
