import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import { Button } from '@melio/billpay-design-system';
import { ReactComponent as AlertIcon } from 'src/app/images/qbo/vc-details/circle-alert.svg';
import { ButtonContainer, TitleContainer } from './DeletePaymentModal.styled';

const prefix = 'paymentDashboard.deleteRefundedPayment';

type Props = {
  onCancel: () => void;
  onDelete: () => void;
  billId: string;
  companyName: string;
  amount?: number | null;
};

export function DeletePaymentModal({ onCancel, onDelete, billId, amount, companyName }: Props) {
  return (
    <>
      <MIFormattedText
        label={`${prefix}.content`}
        values={{
          billId,
          amount,
          companyName,
        }}
      />
      <ButtonContainer>
        <Button variant="secondary" size="md" onClick={onCancel}>
          <MIFormattedText label={`${prefix}.cancel`} />
        </Button>
        <Button variant="destructive" size="md" onClick={onDelete}>
          <MIFormattedText label={`${prefix}.delete`} />
        </Button>
      </ButtonContainer>
    </>
  );
}

export function DeletePaymentModalTitle() {
  return (
    <TitleContainer>
      <AlertIcon width={60} height={60} />
      <MIFormattedText label={`${prefix}.title`} />
    </TitleContainer>
  );
}
