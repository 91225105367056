import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import styled from 'styled-components';
import { CheckType } from 'src/app/utils/types';
import { AreaLoader } from '@melio/billpay-design-system';
import QBRLayoutPage from 'src/app/components/layout/QBRLayoutPage';
import { MIFormattedText } from 'src/app/utils/formatting';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { ModelView, useForm } from 'src/app/ui/form';
import { CONSTS, BANK_ACCOUNT_EXIST, REGEX } from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';
import QBRCategoryList from 'src/app/components/common/QBRCategoryList';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import { convertPaperCheck } from 'src/app/utils/address';
import { formatCheckPrintName } from 'src/app/utils/delivery-methods';
import MIButton from 'src/app/components/common/MIButton';
import { Header, ContentWrapper } from 'src/app/pages/vendor/components/QBRVendorLayoutElements';
import QBRNewDeliveryMethodForm from 'src/app/pages/vendor/components/QBRNewDeliveryMethodForm';
import { featureFlags } from '@melio/shared-web';
import first from 'lodash/first';
import QBRWhitePageAddressContainer from './components/QBRWhitePageAddressContainer';

import {
  UnilateralDeliveryMethodType,
  UnilateralPaymentActions,
  UnilateralPaymentProps,
  UnilateralPaymentState,
} from './hoc/unilateral-payment-hooks';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';

const getRegularCategories = (isCardFundingSource: boolean) => [
  {
    id: 'ach',
    label: 'vendors.addDeliveryMethodByLink.achCategoryLabel',
    icon: 'icon-qbxm-bank',
    hint: isCardFundingSource
      ? 'vendors.addDeliveryMethodByLink.cardToAchHint'
      : 'vendors.addDeliveryMethodByLink.achHint',
  },
  {
    id: 'check',
    label: 'vendors.addDeliveryMethodByLink.checkCategoryLabel',
    icon: 'icon-qbxm-check',
    hint: 'vendors.addDeliveryMethodByLink.checkHint',
  },
];

type Props = {
  state: UnilateralPaymentState;
  actions: UnilateralPaymentActions;
  deliveryMethod: UnilateralDeliveryMethodType;
  isShiftVirtualCard: boolean;
} & UnilateralPaymentProps;

const QBRVendorSelectDeliveryMethodPage = (props: Props) => {
  const bpVirtualCardOptInFeatureFlag = !!first(featureFlags.useFeature('bp-virtual-card-opt-in'));

  const {
    actions,
    deliveryMethod,
    state: {
      isPaymentLoading,
      isDeliveryMethodProcessing,
      deliveryType,
      payment,
      organization,
      updatingDeliveryMethodId,
    },
  } = props;

  const isCardFundingSource = payment?.fundingSource?.fundingType === FundingSourceTypesEnum.CARD;

  const deliveryMethodUpdateStatus: any = useSelector(
    deliveryMethodsStore.selectors.update.status(updatingDeliveryMethodId)
  );
  const { onChange, onSubmit } = actions;
  const [deliveryMethodMV, deliveryMethodMVActions] = useForm(deliveryMethod, {
    submit: (value) => {
      const { deliveryType, ...rest } = value;

      if (deliveryType) {
        if (deliveryType === 'check') {
          value.paperCheck.printName = formatCheckPrintName(value.paperCheck.printName);
        }

        return onSubmit({ deliveryType, ...rest }, false, bpVirtualCardOptInFeatureFlag);
      }

      return Promise.reject(new Error('Delivery type not selected'));
    },
    onChange: ({ key, value, modelState }) => {
      let newState = modelState;

      if (key === 'paperCheck.printName' && value) {
        const isInvalid = REGEX.CHECK_PRINT_NAME.test(value);
        const formattedValue = value.replace(REGEX.CHECK_PRINT_NAME, '');

        if (isInvalid) {
          const { setError } = deliveryMethodMV.paperCheck.printName;

          setError && setError('inputErrors.deliveryMethodCheck.printName.any.invalidChar');
        }

        newState = set('paperCheck.printName', formattedValue, modelState);
      }

      return newState;
    },
  });

  useEffect(() => {
    if (deliveryMethodUpdateStatus?.error?.code === BANK_ACCOUNT_EXIST) {
      deliveryMethodMV.setValidationErrors({
        'bankAccount.accountNumber': `server.${BANK_ACCOUNT_EXIST}`,
      });
    }
  }, [deliveryMethodUpdateStatus]);

  const headerHandler = () => {
    if (deliveryType === CONSTS.DELIVERY_TYPE.CHECK && whitePageAddressView) {
      return setWhitePageAddressView(false);
    }

    if (deliveryMethod.deliveryType) {
      return selectDeliveryMethod('');
    }

    return null;
  };

  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId)
  );
  const [whitePageAddressView, setWhitePageAddressView] = useState(false);
  const paperCheck = useMemo<CheckType>(
    () => convertPaperCheck(deliveryMethodMV.paperCheck as ModelView<CheckType>),
    [deliveryMethodMV.paperCheck]
  );

  useEffect(() => {
    setWhitePageAddressView(
      whitePageAddress &&
        !isEmpty(whitePageAddress) &&
        ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid)
    );

    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSubmit(
        { deliveryType: CONSTS.DELIVERY_TYPE.CHECK, paperCheck },
        true,
        bpVirtualCardOptInFeatureFlag
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitePageAddress]);
  const selectDeliveryMethod = (type) => {
    onChange({ id: 'deliveryType', value: type });
  };

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  const renderContent = () => {
    if (whitePageAddressView && deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
      return (
        <QBRWhitePageAddressContainer
          setWhitePageAddressView={setWhitePageAddressView}
          isLoading={isDeliveryMethodProcessing || isAddressLoading}
          whitePageAddress={whitePageAddress}
          submit={(deliveryMethod, isAddressVerified) =>
            onSubmit(deliveryMethod, isAddressVerified, bpVirtualCardOptInFeatureFlag)
          }
          model={deliveryMethodMV.paperCheck}
        />
      );
    }

    const isDMEmpty = (dm) => {
      if (
        (get(dm, 'bankAccount.routingNumber.value') &&
          get(dm, 'bankAccount.accountNumber.value')) ||
        (get(dm, 'paperCheck.printName.value') &&
          get(dm, 'paperCheck.addressLine1.value') &&
          get(dm, 'paperCheck.city.value') &&
          get(dm, 'paperCheck.state.value') &&
          get(dm, 'paperCheck.zipCode.value'))
      ) {
        return false;
      }

      return true;
    };

    if (deliveryMethod.deliveryType) {
      return (
        <ContentWrapper
          title={
            deliveryType === CONSTS.DELIVERY_TYPE.ACH
              ? 'vendors.addDeliveryMethodByLink.achTitle'
              : 'vendors.addDeliveryMethodByLink.checkTitle'
          }
        >
          <QBRNewDeliveryMethodForm
            submit={deliveryMethodMVActions.submit}
            selectedDeliveryMethod={deliveryType}
            achModel={deliveryMethodMV.bankAccount}
            checkModel={deliveryMethodMV.paperCheck}
          />
          <ButtonContainer>
            <MIButton
              label="vendors.addDeliveryMethodByLink.submitCTA"
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={deliveryMethodMVActions.submit}
              isProcessing={isDeliveryMethodProcessing || isAddressLoading}
              fullWidth
              disabled={isDMEmpty(deliveryMethodMV)}
            />
          </ButtonContainer>
        </ContentWrapper>
      );
    }

    return (
      <ContentWrapper title="vendors.addDeliveryMethodByLink.selectTitle">
        <CategoryListContainer>
          <QBRCategoryList
            categories={getRegularCategories(isCardFundingSource)}
            selectedId={deliveryType}
            onSelect={(type) => selectDeliveryMethod(type)}
          />
        </CategoryListContainer>
      </ContentWrapper>
    );
  };

  return (
    <QBRLayoutPage
      hideHeader
      innerSize={60}
      footer={
        <Footer>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.footerTitle" />
        </Footer>
      }
      contentWrapperMode="unilateral"
    >
      <Content>
        <Header
          payment={payment}
          organization={organization}
          onPrev={deliveryMethod.deliveryType ? headerHandler : null}
          showFullHeader
          hideLogo
          filesUrls={{ filePreviewUrls: [payment?.bill?.invoiceNumber] }}
          note={payment?.note}
          subTitle="vendors.addDeliveryMethodByLink.subTitle"
        />
        {renderContent()}
      </Content>
    </QBRLayoutPage>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.white.opaque};

  @media ${devices.desktop}, ${devices.tablet} {
    border-radius: 0.6rem;
  }
`;

const CategoryListContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: -1.5rem;
  justify-content: center;
  @media ${devices.mobile}, ${devices.phablet} {
    & > label {
      width: 100%;
    }
  }

  @media ${devices.desktop}, ${devices.tablet} {
    margin-top: 4rem;
  }
`;

const Footer = styled.div`
  color: ${(props) => props.theme.text.color.darkGrey};
  ${(props) => props.theme.text.fontType.small};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-bottom: 1.6rem;

  @media ${devices.desktop}, ${devices.tablet} {
    margin-top: 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column-reverse;
`;

export default QBRVendorSelectDeliveryMethodPage;
