import { formatValue, CurrencyInputProps } from 'react-currency-input-field';
import { CURRENCY_USD } from 'src/app/version-2/model/constants';
import {
  CurrencyInputType,
  INTL_CONFIGS,
  MANUAL_CONFIGS,
  DEFAULT_CURRENCY,
  DEFAULT_INTL_CONFIG,
  DEFAULT_MANUAL_CONFIG,
} from './consts';

type FormatOptions = {
  groupSeparator: string;
  decimalSeparator: string;
};

const getFormattedValue = (value: string | undefined, options: FormatOptions): string =>
  formatValue({
    value,
    decimalScale: 2,
    ...options,
  });

/**
 * This functions serves to add alignment between the value we have in code
 * and the value we see inside the input after formatting.
 * It's kinda edge cases handling, beacuse for some input onValueChange callback
 * returns not the same value as we see on the screen.
 */
const getVerifiedValue = (
  value: string | undefined,
  options: FormatOptions
): string | undefined => {
  if (!value) return value;

  if (value === '.0') return '0';

  const { decimalSeparator, groupSeparator } = options;
  const splits = value?.split(decimalSeparator);

  if (splits.length > 1 && !splits[0]) {
    splits[0] = '0';
  } else {
    splits[0] = formatValue({
      value: splits[0],
      groupSeparator,
    });
  }

  return splits.join(decimalSeparator);
};

const getFormattedValueForCurrency = (
  value: string | undefined,
  currency: typeof CURRENCY_USD
): string => {
  const formatOptions = MANUAL_CONFIGS[currency] ?? DEFAULT_MANUAL_CONFIG;

  return formatValue({
    value,
    decimalScale: 2,
    ...formatOptions,
  });
};

const inputFieldCommonProps = {
  decimalScale: 2,
  allowNegativeValue: false,
  autoComplete: 'off',
};

const getInputFieldFormattingProps = (
  type: CurrencyInputType,
  currency: typeof CURRENCY_USD = DEFAULT_CURRENCY
): CurrencyInputProps => {
  switch (type) {
    case CurrencyInputType.CENTER_ALIGNED:
      return {
        ...inputFieldCommonProps,
        ...(MANUAL_CONFIGS[currency] ?? DEFAULT_MANUAL_CONFIG),
      };
    case CurrencyInputType.INPUT_FIELD:
      return {
        ...inputFieldCommonProps,
        intlConfig: INTL_CONFIGS[currency] ?? DEFAULT_INTL_CONFIG,
      };
    default:
      return {};
  }
};

export {
  getFormattedValue,
  getVerifiedValue,
  getFormattedValueForCurrency,
  getInputFieldFormattingProps,
};
