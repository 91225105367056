import { Record } from 'immutable';
import get from 'lodash/get';
import { KybStatusEnum, TaxIdEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { getAccountNumber4digits } from '../../utils/bank-account';
import type { CompanyInfoType, UserType } from '../../utils/types';
import { COUNTRY, FUNDING_SOURCE_ORIGIN } from '../../utils/consts';

export const AccountRecord: Record.Factory<FundingSource> = Record(
  {
    id: NaN,
    displayName: '',
    fundingType: '',
    intuitAccountId: '',
    logo: '',
    origin: FUNDING_SOURCE_ORIGIN.PLAID,
    deletedAt: null,
    createdAt: null,
    isVerified: false,
    nickname: null,
    bankAccount: {
      accountNumber4digits: '',
      accountNumber: '',
      canVerify: true,
      isBlocked: false,
    },
    plaidAccount: {
      balance: NaN,
      plaidItem: {
        institutionName: '',
      },
    },
    cardAccount: {
      cardType: '',
      card4digits: '',
      network: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
    },

    getName(showDisplayName = true) {
      const displayName = this.getDisplayName();
      const institutionName = this.getInstitutionName();
      const cutAccountNumber = getAccountNumber4digits(this.bankAccount);
      let info = '';

      if (this.bankAccount) {
        info = institutionName
          ? `(${institutionName}, ...${cutAccountNumber})`
          : `(...${cutAccountNumber})`;

        if (showDisplayName) {
          info = `${displayName} ${info}`;
        }
      } else if (this.cardAccount) {
        info = `${this.cardAccount.network} (...${this.cardAccount.card4digits})`;
      }

      return info;
    },

    getNameParts() {
      if (this.bankAccount) {
        const identifier = getAccountNumber4digits(this.bankAccount);

        return {
          displayName: this.getDisplayName(),
          institutionName: this.getInstitutionName() || undefined,
          identifier,
        };
      }

      if (this.cardAccount) {
        return {
          displayName: '',
          institutionName: this.cardAccount.network,
          identifier: this.cardAccount.card4digits,
        };
      }

      return {
        displayName: '',
        institutionName: '',
        identifier: '',
      };
    },

    getDisplayName() {
      return this.displayName || '';
    },

    getInstitutionName() {
      return get(this, 'plaidAccount.plaidItem.institutionName') || '';
    },
  } as any,
  'AccountRecord'
);

export const addressDefaults = {
  addressLine1: '',
  addressLine2: null,
  city: '',
  state: '',
  zipCode: null as string | null,
  countryCode: COUNTRY.US,
  googlePlaceId: null,
  aptNumber: '',
  addressLat: NaN,
  addressLng: NaN,
  formattedAddress: '',
};
export const legalAddressDefaults = {
  legalAddressLine1: '',
  legalAddressLine2: '',
  legalCity: '',
  legalState: '',
  legalZipCode: '',
  legalGooglePlaceId: '',
  legalAptNumber: '',
  legalAddressLat: NaN,
  legalAddressLng: NaN,
  legalFormattedAddress: '',
  legalCountryCode: COUNTRY.US,
};

const companyInfoDefaults = {
  id: null,
  ...addressDefaults,
  ...legalAddressDefaults,
  companyName: '',
  legalCompanyName: '',
  taxId: '',
  phone: '',
  contactFirstName: '',
  contactLastName: '',
  yearOfIncorporation: NaN,
  accountingSoftware: '',
  businessType: '',
  intuitLastFullUpdate: new Date(),
  logoUrl: '',
  logoId: null,
  taxIdType: TaxIdEnum.EIN,
  canDoPayments: false,
  canPayWithFastCheck: true,
  kybStatus: KybStatusEnum.UNDECIDED,
  kyb: null,
  mql: null,
  billingSetting: {
    fee: {
      credit: {
        value: 2.9,
        type: 'percent',
      },
      ach: {
        value: 0,
        type: 'fixed',
      },
      debit: {
        value: 0,
        type: 'fixed',
      },
    },
  },
  ownedVendorId: null,
  ownedVendorHandle: '',
  onboardingIndustryId: null,
  onboardingSubIndustryId: null,
  companyType: null,
  createOrigin: null,
};

export const CompanyInfoRecord: Record.Factory<CompanyInfoType> = Record(
  companyInfoDefaults as any,
  'CompanyInfoRecord'
);

export const UserRecord: Record.Factory<UserType> = Record(
  {
    name: '',
    email: '',
    phone: '',
  },
  'UserRecord'
);
