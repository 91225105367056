/* eslint-disable max-len */
import qbDashboardNotificationMapping from 'src/app/pages/qb-dashboard/action-notification-mappings';

const MAPPINGS = {
  '[USER] UPDATE_FUNDING_SOURCE_LABEL_SUCCESS': {
    type: 'success',
    msg: 'settings.paymentMethods.editLabelDialog.savedToast',
  },
  '[USER] REMOVE_FUNDING_SOURCE_LABEL_SUCCESS': {
    type: 'success',
    msg: 'settings.paymentMethods.editLabelDialog.removedToast',
  },
  '[PAYMENTS] PAYMENT_APPROVED_SUCCESS': {
    type: 'success',
    msg: 'bills.approval.approveSuccess',
  },
  '[PAYMENTS] PAYMENT_DECLINED_SUCCESS': {
    type: 'info',
    msg: 'bills.approval.declineSuccess',
  },
  '[VENDORS] CREATE_SUCCESS': {
    type: 'success',
    msg: 'contacts.create.successNotification',
  },
  '[CUSTOMERS] CREATE_SUCCESS': {
    type: 'success',
    msg: 'contacts.create.successNotification',
  },
  ...qbDashboardNotificationMapping,
};

export default MAPPINGS;
