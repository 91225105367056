import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS } from 'src/app/utils/consts';
import { ButtonVariantType } from 'src/app/utils/types';
import { isEnterPressed } from 'src/app/utils/events';
import * as WizardElements from './QBRWizardElements';
import * as QBRLayoutElements from './QBRElements';
import { MIFormattedText } from '../../utils/formatting';
import MIButton from '../common/MIButton';
import QBOProgressBar from '../common/QBOProgressBar';
import SandboxIndicator from '../common/SandboxIndicator';

type Props = {
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  title?: any;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  children?: React.ReactNode;
  onNext?: () => void | Promise<void> | null;
  nextLabel?: string;
  goExit?: () => void | null;
  contentWrapperMode?: string;
  onPrev?: () => void | null;
  isNextDisabled?: boolean;
  onSubmit?: () => void | boolean | null | Promise<void>;
  isLoading?: boolean;
  isHideActions?: boolean;
  ctaVariant?: ButtonVariantType;
  fullWidthCTA?: boolean;
  innerSize?: number;
  footer?: React.ReactNode | null;
  relativeStep?: number | null;
  hideHeader?: boolean;
  isSandboxIndicatorShown?: boolean;
  showFooter?: boolean;
};

const QBRLayoutPage = ({
  headerLabel,
  headerLabelValues,
  title = null,
  titleValues,
  subtitle,
  goExit,
  children,
  onPrev,
  onNext,
  nextLabel,
  isNextDisabled,
  onSubmit,
  isLoading,
  isHideActions,
  innerSize = 50,
  footer,
  subTitleValues,
  ctaVariant = CONSTS.BUTTON_VARIANT.PRIMARY,
  fullWidthCTA,
  relativeStep,
  contentWrapperMode,
  hideHeader,
  isSandboxIndicatorShown,
  showFooter,
}: Props) => {
  const submitMethod = onSubmit || onNext;
  const nextActionLabel = nextLabel || 'progress.continue';
  const buttonContainerWidth = fullWidthCTA ? '45' : 'auto';
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (submitMethod && !isNextDisabled && isEnterPressed(event)) {
      submitMethod();
    }
  };

  const handleNextClick = () => {
    if (submitMethod) {
      submitMethod();
    }
  };

  return (
    <QBRLayoutElements.QBRLayoutContainer onKeyDown={onKeyPressed}>
      {!hideHeader && (
        <>
          <QBRLayoutElements.QBRHeaderContainer
            goExit={goExit}
            onPrev={onPrev}
            headerLabel={headerLabel}
            headerLabelValues={headerLabelValues}
          />

          {relativeStep ? <QBOProgressBar relativeStep={relativeStep} /> : null}
        </>
      )}

      {hideHeader && goExit && <Close onClick={goExit} />}

      <WizardElements.WizardInner mode={contentWrapperMode as string}>
        {title && (
          <WizardElements.WizardStepTitle isSubtitle={!!subtitle}>
            {title && <MIFormattedText label={title} values={titleValues} />}
          </WizardElements.WizardStepTitle>
        )}

        {subtitle && (
          <WizardElements.WizardStepSubTitle>
            <MIFormattedText label={subtitle} values={subTitleValues} />
          </WizardElements.WizardStepSubTitle>
        )}

        <WizardElements.WizardInputs mode={contentWrapperMode} innerSize={innerSize}>
          {children}
        </WizardElements.WizardInputs>

        {!isHideActions && submitMethod && (
          <WizardElements.WizardStepActionsContainer>
            <WizardElements.WizardButtonContainer isProgressBar width={buttonContainerWidth}>
              <MIButton
                label={nextActionLabel}
                variant={ctaVariant}
                onClick={handleNextClick}
                isProcessing={isLoading}
                disabled={isNextDisabled}
                fullWidth
              />
            </WizardElements.WizardButtonContainer>
          </WizardElements.WizardStepActionsContainer>
        )}

        {footer && (
          <WizardElements.WizardFooterContainer>{footer}</WizardElements.WizardFooterContainer>
        )}
      </WizardElements.WizardInner>
      {isSandboxIndicatorShown && <SandboxIndicator />}
      <QbrLayoutPageFooter>
        {showFooter && <QBRLayoutElements.QBRFooterContainer />}
      </QbrLayoutPageFooter>
    </QBRLayoutElements.QBRLayoutContainer>
  );
};

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: fixed;
  top: 1.2rem;
  right: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  z-index: 1;

  @media ${devices.mobile}, ${devices.phablet} {
    position: relative;
    display: flex;
    align-self: flex-end;
    margin: 12px 12px 0 0;
    font-size: ${(props) => props.theme.text.size.title};
    right: 1rem;
    top: 0.8rem;
    color: ${(props) => props.theme.text.color.green};
  }
`;

const QbrLayoutPageFooter = styled.div`
  width: 100%;
  ${(props) => props.theme?.pages?.QbrLayoutPage?.QbrLayoutPageFooter}
`;

export default QBRLayoutPage;
