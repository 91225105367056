import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import analytics from 'src/app/services/analytics';
import intercomService from 'src/app/services/intercom';
import errorIcon from 'src/app/images/qbo/error-icon.png';
import billAlreadyPaidIcon from 'src/app/images/qbo/bill-already-paid.svg';
import zeroAmountIcon from 'src/app/images/qbo/zero-amount-icon.svg';
import ErrorPage, { Illustration } from 'src/app/components/layout/ErrorLayoutPage';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import intuit from 'src/app/utils/intuit';
import { SYNC_BILL_ERROR_CODE } from 'src/app/utils/consts';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import dashboardLocations from 'src/app/pages/qb-dashboard/locations';
import { melioClose } from 'src/app/utils/external-events';

type LocationState = {
  errorCode?: SYNC_BILL_ERROR_CODE;
  amount?: string;
  vendorName?: string;
  exitUrl?: string;
};

function trackBillSyncErrorPage(errorCode?: SYNC_BILL_ERROR_CODE) {
  let name = 'sync-error';

  if (errorCode === SYNC_BILL_ERROR_CODE.BILL_ALREADY_PAID) {
    name = 'bill-already-paid-error';
  } else if (errorCode === SYNC_BILL_ERROR_CODE.BILL_AMOUNT_ZERO) {
    name = 'amount-cannot-be-zero';
  } else if (errorCode === SYNC_BILL_ERROR_CODE.BILL_HAS_BEEN_DELETED) {
    name = 'bill-has-been-deleted';
  }

  analytics.page('bill-error', name, { errorCode });
}

export const BillSyncErrorPage = () => {
  const {
    errorCode,
    amount = '',
    vendorName,
    exitUrl,
  } = useLocation<LocationState | undefined>().state || {};
  const [historyPush] = useHistoryWithOrgId();
  const startChat = () => {
    analytics.track('pay-bill-sync-error', 'support-open-start-chat');
    intercomService.show();
  };

  const goToDashboard = () => {
    analytics.track('pay-bill-sync-error', 'go-to-dashboard');
    historyPush({
      path: dashboardLocations.dashboard,
    });
  };

  const goToBillPageOnIntuit = () => {
    analytics.track('pay-bill-sync-error', 'go-to-bill-page-on-intuit');
    melioClose();
  };

  useEffect(() => {
    intuit.endLoadingWrapper();
    intuit.hideFTULoadingWrapper();
  }, []);

  useEffect(() => {
    trackBillSyncErrorPage(errorCode);
  }, [errorCode]);

  if (errorCode === SYNC_BILL_ERROR_CODE.BILL_ALREADY_PAID) {
    return (
      <BillAlreadyPaidErrorPage
        illustration={billAlreadyPaidIcon}
        title="bills.errors.billAlreadyPaid.title"
        subtitle="bills.errors.billAlreadyPaid.subTitle"
        subtitleValues={{
          vendorName,
          amount: <MIFormattedCurrency value={amount} />,
        }}
        buttonAction={goToDashboard}
        buttonLabel="bills.errors.billAlreadyPaid.buttonLabel"
        exitUrl={exitUrl}
      />
    );
  }

  if (errorCode === SYNC_BILL_ERROR_CODE.BILL_AMOUNT_ZERO) {
    return (
      <ZeroAmountBillErrorPage
        illustration={zeroAmountIcon}
        title="bills.errors.zeroAmount.title"
        subtitle="bills.errors.zeroAmount.subTitle"
        subtitleValues={{
          vendorName,
        }}
        buttonAction={goToBillPageOnIntuit}
        buttonLabel="bills.errors.zeroAmount.buttonLabel"
        exitUrl={exitUrl}
      />
    );
  }

  if (errorCode === SYNC_BILL_ERROR_CODE.BILL_HAS_BEEN_DELETED) {
    return (
      <BillHasBeenDeletedErrorPage
        illustration={billAlreadyPaidIcon}
        title="bills.pay.billAlreadyScheduled.deleted.title"
        subtitle="bills.pay.billAlreadyScheduled.deleted.subTitle"
        subtitleValues={{
          vendorName,
          amount: <MIFormattedCurrency value={amount} />,
        }}
        buttonAction={goToDashboard}
        buttonLabel="bills.pay.billAlreadyScheduled.buttonLabel"
        exitUrl={exitUrl}
      />
    );
  }

  return (
    <ErrorPage
      illustration={errorIcon}
      title="bills.errors.sync.title"
      subtitle="bills.errors.sync.subTitle"
      buttonAction={startChat}
      buttonLabel="bills.errors.sync.buttonLabel"
      exitUrl={exitUrl}
    />
  );
};

const BillAlreadyPaidErrorPage = styled(ErrorPage)`
  ${Illustration} {
    height: 7.3rem;
    margin-bottom: 2.5rem;
    margin-left: 1.5rem;
  }
`;

const ZeroAmountBillErrorPage = styled(ErrorPage)`
  ${Illustration} {
    height: 6rem;
    margin-bottom: 2rem;
  }
`;

const BillHasBeenDeletedErrorPage = styled(ErrorPage)`
  ${Illustration} {
    height: 8.5rem;
    width: auto;
    margin-bottom: 4rem;
  }
`;
