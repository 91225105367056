/* eslint-disable camelcase */
export type FormUtils = {
  values: Record<string, any>;
  billingType: string;
  validationErrors: Record<string, any>;
  setValidationErrors: (state: Record<string, any>) => void;
};

export const enum ReducerActions {
  SET_DATA = 'SET_DATA',
}

export const enum StateTypes {
  COUNTRIES = 'countries',
  COUNTRY = 'country',
  OPTIONS = 'options',
  REQUIRED_FIELDS = 'requiredFields',
  REQUIRED_FIELDS_VALIDATION = 'requiredFieldsValidation',
  BANK_DETAILS = 'bankDetails',
  PURPOSE_OF_PAYMENT = 'purposeOfPayment',
  FEE = 'fee',
  CURRENCY = 'currency',
  AMOUNT = 'amount',
  ORG_ID = 'orgId',
  VENDOR = 'vendor',
  IBAN = 'iban',
  SWIFT = 'bic_swift',
  EXIT_URL = 'exitUrl',
  REDIRECT_URL = 'redirectUrl',
  DELIVERY_METHOD = 'deliveryMethod',
  ORIGIN = 'origin',
  EDIT = 'edit',
  ACCOUNT_NUMBER = 'accountNumber',
  BANK_NAME = 'bankName',
  ULTIMATE_BENEFICIAL_OWNERS = 'ultimateBeneficialOwners',
  TAX_ID_TYPE = 'taxIdType',
  BILL_ID = 'billId',
  PRESERVED_STATE = 'preservedState',
  IS_BATCH_DIRECT = 'isBatchDirect',
  IS_BATCH_CREATE_DM = 'isBatchCreateDM',
}

export enum BillingTypes {
  IBAN = 'iban',
  SWIFT = 'bic_swift',
}

export type Actions = {
  init: () => void;
  setVendorLocation: ({ country }) => void;
  setPaymentDetails: ({
    country,
    accountNumber,
    bankName,
    bankDetails,
    billingType,
    billingValue,
  }) => void;
  createDelivery: (create: any, partialState?: Record<string, any>) => Promise<any>;
  createInternationalDeliveryMethod: (
    create: any,
    partialState?: Record<string, any>
  ) => Promise<any>;
  editDelivery: (edit: any, partialState?: Record<string, any>) => Promise<any>;
  setContextData: (key, values) => void;
  goToPath: ({
    path,
    state,
    params,
  }: {
    path: string;
    state?: Record<string, any>;
    params?: Record<string, any>;
  }) => void;
  getTitleValues: () => Record<string, any>;
  getOriginValues: () => Record<string, any>;
  onPurposeBack: () => void;
  hasNoUltimateBeneficialOwners: () => boolean;
};

export type Country = {
  code: string;
  label: string;
  region: string | null;
  billingType: string;
  isSupported: boolean;
  risk: string;
};

export type RequiredFields = {
  acct_number: string;
  address: string;
  bic_swift?: string;
  iban?: string;
  city: string;
  company_name: string;
  country: string;
  entity_type: string;
  payment_type: string;
  postcode: string;
  state_or_province: string;
};

export type BankDetails = {
  account_number: string;
  bank_address: string;
  bank_branch: string;
  bank_city: string;
  bank_country: string;
  bank_country_ISO: string;
  bank_name: string;
  bank_post_code: string;
  bank_state: string;
  bic_swift: string;
  currency: string;
  identifier_type: string;
  identifier_value: string;
};

export type CountryOption = {
  value: string;
  label?: string;
  options?: CountryOption[];
};

export type CurrencyOption = {
  value: string;
  label: string;
  flag: string;
};

export type ReturnType = [InitialState, Actions];

export type InitialState = {
  [StateTypes.COUNTRIES]: Country[];
  [StateTypes.COUNTRY]: {
    code: string;
    name: string;
    billingType: string;
    isSupported: boolean;
    risk: string;
  };
  [StateTypes.OPTIONS]: CountryOption[];
  [StateTypes.REQUIRED_FIELDS_VALIDATION]: any;
  [StateTypes.REQUIRED_FIELDS]: any;
  [StateTypes.BANK_DETAILS]: any;
  [StateTypes.PURPOSE_OF_PAYMENT]: any;
  [StateTypes.FEE]: string;
  [StateTypes.CURRENCY]: string;
  [StateTypes.AMOUNT]: number;
  [StateTypes.ORG_ID]: string | undefined;
  [StateTypes.VENDOR]: Record<string, string>;
  [StateTypes.IBAN]: string;
  [StateTypes.SWIFT]: string;
  [StateTypes.EXIT_URL]: string;
  [StateTypes.REDIRECT_URL]: string;
  [StateTypes.DELIVERY_METHOD]: Record<string, string> | null;
  [StateTypes.ORIGIN]: string;
  [StateTypes.EDIT]: boolean;
  [StateTypes.ACCOUNT_NUMBER]: string;
  [StateTypes.BANK_NAME]: string;
  [StateTypes.ULTIMATE_BENEFICIAL_OWNERS]: any;
  [StateTypes.BILL_ID]?: number;
  [StateTypes.PRESERVED_STATE]?: Record<string, any>;
  [StateTypes.IS_BATCH_DIRECT]?: boolean;
  [StateTypes.IS_BATCH_CREATE_DM]?: boolean;
};

export type StepProps = {
  relativeStep?: number;
  hideHeader?: boolean;
  onNext?: (state?: Record<string, any>) => void;
  onPrev?: () => void;
  onExit?: () => void;
};

export const enum RiskTypesEnum {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}
