import React from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@melio/billpay-design-system';

import { useSiteContext } from 'src/app/hoc/withSiteContext';
import MIButton from 'src/app/components/common/MIButton';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { PaymentType, VirtualCardType, DeliveryMethodType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import { InfoLayout, InlineLink } from '../components';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  deliveryMethod?: DeliveryMethodType;
  virtualCard?: VirtualCardType;
  payment?: PaymentType;
  processing?: boolean;
  onViewNewCardDetailsClick: (id?: number) => void;
};

const RecoveredLayout = ({
  deliveryMethod,
  virtualCard,
  payment,
  processing,
  onViewNewCardDetailsClick,
}: Props) => {
  const { analyticsProperties } = useStateChangedAnalytics({
    payment,
    paymentState: VirtualCardPaymentState.Recovered,
  });
  const intl = useIntl();
  const site = useSiteContext();

  const formattedAmount =
    payment?.amount &&
    intl.formatNumber(payment.amount, {
      style: 'currency',
      currency: 'USD',
    });
  const actualVirtualCard = payment?.lastCreatedVirtualCard;

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <InfoLayout.Icon type={InfoLayout.IconType.Info} />
      <InfoLayout.Title>
        <MIFormattedText
          label="vendors.virtualCardDetails.recovered.title"
          values={{ date: <MIFormattedDate date={virtualCard?.endDate} /> }}
        />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label="vendors.virtualCardDetails.recovered.subtitle"
          values={{
            amount: formattedAmount,
            email: deliveryMethod?.virtualCardAccount?.accountEmail,
            date: <MIFormattedDate date={actualVirtualCard?.createdAt} />,
          }}
        />
      </InfoLayout.Subtitle>

      <Box mt="4rem">
        <MIButton
          label="vendors.virtualCardDetails.recovered.cta"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={() => onViewNewCardDetailsClick(actualVirtualCard?.id)}
          isProcessing={processing}
          fullWidth
          analyticsProperties={analyticsProperties}
        />
      </Box>

      <Box mt="1.6rem" textStyle="body3" textAlign="center">
        <MIFormattedText
          label="vendors.virtualCardDetails.common.contactSupport"
          values={{
            link: (...chunks) => (
              <InlineLink href={`mailto:${site.config.support.email}`} chunks={chunks} />
            ),
          }}
        />
      </Box>
    </InfoLayout>
  );
};

export { RecoveredLayout };
