import * as React from 'react';
import { RecordOf } from 'immutable';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { ManualAddressType } from 'src/app/components/common/ManualAddress/ManualAddressOptionsContainer';
import clientServiceApi from 'src/app/services/api/clientService';
import {
  BankType,
  CheckType,
  GoogleCombinedAddressType,
  EditableDeliveryMethodType,
  FieldType,
  AddressType,
  DeliveryMethodType,
  CompanyInfoType,
  AddressTypeFromApi,
} from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import locations from 'src/app/utils/locations';
import { BankRecord, CheckRecord, AddressRecord } from 'src/app/pages/vendor/records';
import deliveryMethodsApi from 'src/app/services/api/deliveryMethods';
import vendorsApi from 'src/app/services/api/vendors';
import { CONSTS, DELIVERY_METHOD_ORIGIN } from 'src/app/utils/consts';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { whitePagesAddressKeys } from 'src/app/utils/address';
import analytics from 'src/app/services/analytics/index';

import { selectNewDeliveryMethodAction } from 'src/app/redux/payBillWizard/actions';
import {
  getOrgId,
  getFundingSources,
  getOwnedVendorId,
  getCompanyInfo,
} from 'src/app/redux/user/selectors';
import { loadDeliveryMethodsAction } from 'src/app/redux/user/actions';

// redux-toolkit actions
import vendorStore from 'src/app/modules/vendors/vendors-store';
import { getStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getDeliveryMethodActions } from 'src/app/modules/delivery-methods/delivery-methods-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import {
  AddFundingSourceWizardOriginEnum,
  ContactsTabEnum,
  DbAnalyticsTraitsEnum,
  ManualAddressEnum,
} from 'src/app/version-2/model/enums';
import { ADDRESS_NO_GOOGLE_PLACE_ID } from 'src/app/version-2/model/constants';

type MapStateToProps = {
  orgId: string;
  companyInfo: RecordOf<CompanyInfoType>;
  userFundingSources: FundingSource[];
  ownedVendorId: string;
  qbVendorInfoAddress: AddressTypeFromApi;
  isQBVendorInfoLoaded: boolean;
};

type MapDispatchToProps = {
  selectNewDeliveryMethodForPayBillFlow: (deliveryMethod: DeliveryMethodType) => void;
  refreshDeliveryMethods: () => Promise<void>;
  getQBvendorInfo: (arg0: { orgId: number; vendorId: number }) => void;
  createDeliveryMethod: (orgId: string, vendorId: string, deliveryMethod: any) => Promise<any>;
  editDeliveryMethod: (params: any) => Promise<any>;
  checkVendorPaymentPreferences: ({ orgId, id }) => Promise<any>;
  updateJustPayWizardDeliveryMethod: (deliveryMethodId: string, deliveryMethodType: string) => void;
};

type Props = {
  vendorId: string;
  deliveryMethodId?: string;
  locationState: Record<string, any>;
  inputFields: string[];
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void;
} & MapDispatchToProps &
  MapStateToProps;

type State = {
  isLoading: boolean;
  isNew: boolean;
  isReady: boolean;
  validationErrors: Record<string, string>;
  addressValidationErrors: Record<string, string>;
  bank: RecordOf<BankType>;
  check: RecordOf<CheckType>;
  manualAddress: RecordOf<AddressType>;
  origin: string;
  eventPage: string;
  selectedAddressId: string;
  invalidAddress: boolean;
  confirmMode: boolean;
  isVerified: boolean;
  validatedAddress?: ManualAddressType;
  errorCode: string;
  isVendorBlockedForPayment: boolean;
};

export type DeliveryMethodProps = {
  vendorId: string;
  isLoading: boolean;
  confirmMode: boolean;
  invalidAddress: boolean;
  isNew: boolean;
  bank: RecordOf<BankType>;
  check: RecordOf<CheckType>;
  manualAddress: AddressType;
  validatedAddress: ManualAddressType;
  validationErrors: Record<string, string>;
  addressValidationErrors: Record<string, string>;
  onManualAddressChange: (field: FieldType) => void;
  onAddressAddRequest: () => void;
  onAddressConfirmed: () => void;
  onEditAddress: () => void;
  onAddressSelect: (id: string) => void;
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  goExit: () => void;
  onCheckNameAdded: (printName: string) => void;
  onCheckAddressAdded: (address: GoogleCombinedAddressType) => void;
  onCheckAddressPrev: () => void;
  errorCode: string;
  selectedAddressId: string;
  getQBvendorInfo: () => void;
  isQBVendorInfoLoaded: boolean;
  isVendorBlockedForPayment: boolean;
  isVoidCheck?: boolean;
};

type DeliveryMethodUpdatePayload = {
  orgId: string;
  vendorId: string;
  deliveryMethod: EditableDeliveryMethodType;
  deliveryMethodId?: string;
};

const mapStateToProps = (state: GlobalState, { vendorId }): MapStateToProps => ({
  orgId: getOrgId(state),
  companyInfo: getCompanyInfo(state),
  userFundingSources: getFundingSources(state),
  ownedVendorId: getOwnedVendorId(state),
  qbVendorInfoAddress: vendorStore.selectors.qboVendorInfo.value(state, vendorId),
  isQBVendorInfoLoaded: !vendorStore.selectors.qboVendorInfo.loading(state, vendorId),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectNewDeliveryMethodForPayBillFlow(deliveryMethod: DeliveryMethodType) {
    dispatch(selectNewDeliveryMethodAction(deliveryMethod));
  },
  refreshDeliveryMethods() {
    return new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    });
  },
  getQBvendorInfo({ orgId, vendorId }) {
    const actions = getStoreActions(vendorStore)(dispatch);

    actions.qboVendorInfo({ orgId, vendorId });
  },
  createDeliveryMethod(orgId, vendorId, deliveryMethod) {
    const params = {
      deliveryType: deliveryMethod.deliveryType,
      bankAccount: deliveryMethod?.bankAccount?.toJS(),
    };

    return getDeliveryMethodActions(dispatch).create({
      orgId,
      vendorId,
      params,
    });
  },
  editDeliveryMethod(params: any) {
    return getDeliveryMethodActions(dispatch).edit(params);
  },
  checkVendorPaymentPreferences({ orgId, id }) {
    const vendorActions = getStoreActions(vendorStore)(dispatch);

    return vendorActions.checkVendorPaymentPreferences({ orgId, id });
  },
  updateJustPayWizardDeliveryMethod(deliveryMethodId, deliveryMethodType) {
    const paymentStoreActions = getStoreActions(paymentStore)(dispatch);

    paymentStoreActions.justPay.justPayWizard.update({
      deliveryMethodId,
      deliveryMethodType,
    });
  },
});

export function withDeliveryMethodData() {
  return function (Component: any) {
    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      class ComponentWithDeliveryMethodData extends React.PureComponent<Props, State> {
        static defaultProps = {};

        constructor(props: Props) {
          super(props);
          const { locationState, vendorId } = this.props;
          const { ownedVendorId } = this.props;

          this.state = {
            isVerified: false,
            selectedAddressId: '1',
            validatedAddress: undefined,
            invalidAddress: false,
            confirmMode: false,
            isReady: false,
            isLoading: false,
            isNew: !this.props.deliveryMethodId,
            check: locationState && locationState.check ? locationState.check : CheckRecord(),
            bank: BankRecord(),
            validationErrors: {},
            addressValidationErrors: {},
            manualAddress: AddressRecord(),
            origin: locationState && locationState.origin ? locationState.origin : '',
            eventPage:
              parseInt(ownedVendorId, 10) === parseInt(vendorId, 10)
                ? 'vendor-company-delivery-method'
                : 'vendor-delivery-method',
            errorCode: '',
            isVendorBlockedForPayment: false,
          };
        }

        componentDidMount() {
          if (this.state.isNew) {
            const { check } = this.state;

            if (check.printName) {
              // eslint-disable-next-line react/no-did-mount-set-state
              this.setState({ isReady: true });
            } else {
              this.loadDefaultPrintName();
            }
          } else {
            this.loadDeliveryMethod();
          }
        }

        componentDidUpdate() {
          this.updateVendorAddress();
        }

        onCheckNameAdded = (printName: string) => {
          const {
            deliveryMethodId,
            vendorId: id,
            locationState,
            userFundingSources = [],
          } = this.props;
          const url = this.props.deliveryMethodId
            ? locations.Vendors.deliveryMethods.check.address.edit.url({
                id,
                deliveryMethodId,
              })
            : locations.Vendors.deliveryMethods.check.address.create.url({
                id,
              });
          const validationErrors = getValidationErrors(
            'deliveryMethodCheck',
            { printName },
            this.props.inputFields
          );

          if (this.props.deliveryMethodId) {
            analytics.track(this.state.eventPage, 'edit-delivery-method-check-name', {
              vendorId: id,
            });
          } else {
            analytics.track(this.state.eventPage, 'add-delivery-method-check-name', {
              vendorId: id,
            });
          }

          const isFundingSourceVerified = locationState?.isFundingSourceVerified;
          const isVoidCheck = locationState?.isVoidCheck;

          this.setState({ validationErrors }, () => {
            if (isValidationOk(validationErrors)) {
              this.props.navigate(url, false, {
                check: this.state.check.merge({ printName }),
                origin: this.state.origin,
                fundingSource: userFundingSources.find(
                  (fs) => fs.id === locationState.fundingSourceId
                ),
                isFundingSourceVerified,
                preservedState: locationState?.preservedState,
                isVoidCheck,
              });
            } else {
              analytics.track(
                this.state.eventPage,
                'add-delivery-method-check-name-validation-error',
                validationErrors
              );
            }
          });
        };

        onCheckAddressPrev = () => {
          const { deliveryMethodId, vendorId: id, navigate } = this.props;
          const { check } = this.state;
          const url = deliveryMethodId
            ? locations.Vendors.deliveryMethods.check.edit.url({
                id,
                deliveryMethodId,
              })
            : locations.Vendors.deliveryMethods.check.create.url({ id });

          navigate(url, false, { check });
        };

        onEditAddress = () => {
          const { validatedAddress } = this.state;

          if (validatedAddress && validatedAddress.diff) {
            const changes = validatedAddress.diff.reduce((obj, diff) => {
              const whitePagesKey = Object.keys(diff)[0];
              const key = whitePagesAddressKeys[whitePagesKey];

              // eslint-disable-next-line prefer-destructuring
              obj[key] = Object.values(diff)[0];

              return obj;
            }, {});

            this.setState(({ manualAddress }) => ({
              manualAddress: manualAddress.merge({ ...changes }),
            }));
          }

          this.setState({
            confirmMode: false,
            isLoading: false,
            invalidAddress: false,
          });
          analytics.track(this.state.eventPage, 'check-suggested-address-edited');
        };

        onAddressConfirmed = () => {
          const { selectedAddressId, validatedAddress, invalidAddress } = this.state;

          this.setState(({ check }) => ({
            check: check.merge({
              isAddressSuggestionIgnored:
                invalidAddress || selectedAddressId === ManualAddressEnum.ORIGINAL,
            }),
            isLoading: true,
          }));

          if (
            !invalidAddress &&
            selectedAddressId === ManualAddressEnum.SUGGESTED &&
            validatedAddress
          ) {
            const confirmedChanges = validatedAddress.diff.reduce((obj, diff) => {
              const whitePagesKey = Object.keys(diff)[0];
              const key = whitePagesAddressKeys[whitePagesKey];

              // eslint-disable-next-line prefer-destructuring
              obj[key] = Object.values(diff)[0];

              return obj;
            }, {});

            this.setState(
              ({ manualAddress }) => ({
                manualAddress: manualAddress.merge({ ...confirmedChanges }),
                isVerified: true,
              }),
              () => this.onAddressAdded()
            );
            analytics.track(this.state.eventPage, 'check-suggested-address-confirmed');
          } else {
            this.setState({ isVerified: false }, () => {
              this.onAddressAdded();
            });

            if (invalidAddress) {
              analytics.track(this.state.eventPage, 'check-no-suggestion-address-confirmed');
            } else {
              analytics.track(this.state.eventPage, 'check-suggested-address-declined');
            }
          }
        };

        onAddressAddRequest = () => {
          this.setState({ isLoading: true });
          const manualAddress = this.state.manualAddress.toJS();
          const { deliveryMethodId } = this.props;
          const validationErrors = getValidationErrors('deliveryMethodCheck', manualAddress, [
            'addressLine1',
            'city',
            'state',
            'zipCode',
          ]);

          if (deliveryMethodId) {
            analytics.track(this.state.eventPage, 'edit-delivery-method-check-address');
          } else {
            analytics.track(this.state.eventPage, 'add-delivery-method-check-address');
          }

          if (get(this.props.qbVendorInfoAddress, 'addressLine1')) {
            analytics.track(this.state.eventPage, 'address-from-qbo-suggestion');
          }

          this.setState({ validationErrors }, async () => {
            if (isValidationOk(validationErrors)) {
              await this.addressValidationService();
            } else {
              this.setState({
                isLoading: false,
              });

              if (deliveryMethodId) {
                analytics.track(
                  this.state.eventPage,
                  'edit-delivery-method-check-address-regular-validation-error',
                  validationErrors
                );
              } else {
                analytics.track(
                  this.state.eventPage,
                  'add-delivery-method-check-address-regular-validation-error',
                  validationErrors.toString().toUpperCase() as any
                );
              }
            }
          });
        };

        onAddressAdded = async () => {
          const { isVerified } = this.state;
          const manualAddress = this.state.manualAddress.toJS();

          manualAddress.googlePlaceId = ADDRESS_NO_GOOGLE_PLACE_ID;
          const paperCheck = this.state.check.merge({ ...manualAddress } as ManualAddressType);

          await this.onDeliveryMethodAdded({
            deliveryType: CONSTS.DELIVERY_TYPE.CHECK,
            paperCheck,
            isVerified,
          });
        };

        onAddressSelect = (id: string) => {
          analytics.track(this.state.eventPage, 'address-suggestion-selected');
          this.setState({ selectedAddressId: id });
        };

        onDeliveryMethodAdded = async (deliveryMethod: EditableDeliveryMethodType) => {
          const {
            refreshDeliveryMethods,
            orgId,
            ownedVendorId,
            checkVendorPaymentPreferences,
            vendorId,
          } = this.props;

          this.setState({ isLoading: true });
          try {
            const data = await this.updateDeliveryMethod({
              orgId,
              vendorId,
              deliveryMethodId: this.props.deliveryMethodId,
              deliveryMethod,
            });

            if (
              this.state.origin === DELIVERY_METHOD_ORIGIN.PAY_BILL ||
              this.state.origin === DELIVERY_METHOD_ORIGIN.JUST_PAY ||
              this.state.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK
            ) {
              const { selectNewDeliveryMethodForPayBillFlow, updateJustPayWizardDeliveryMethod } =
                this.props;

              if (
                this.state.origin === DELIVERY_METHOD_ORIGIN.PAY_BILL ||
                this.state.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK
              ) {
                selectNewDeliveryMethodForPayBillFlow(data);
              }

              if (this.state.origin === DELIVERY_METHOD_ORIGIN.JUST_PAY) {
                updateJustPayWizardDeliveryMethod(data.id, data.deliveryType);
              }

              const {
                payload: { blockPayments },
              } = await checkVendorPaymentPreferences({ orgId, id: vendorId });

              this.setState({ isVendorBlockedForPayment: blockPayments });
            }

            if (parseInt(vendorId, 10) === parseInt(ownedVendorId, 10)) {
              await refreshDeliveryMethods();
            }

            if (this.props.deliveryMethodId) {
              analytics.track(this.state.eventPage, 'edit-delivery-method-success', {
                type: deliveryMethod.deliveryType,
                vendorId,
              });
            } else {
              analytics.track(this.state.eventPage, 'add-delivery-method-success', {
                type: deliveryMethod.deliveryType,
                vendorId,
              });
            }

            const dataToAdd = { newDeliveryMethod: data };

            this.setState({ isLoading: false });
            this.navigateOut(dataToAdd);
          } catch (error: any) {
            this.setState({ isLoading: false, errorCode: error.code });
          }
        };

        onManualAddressChange = ({ value, id }: FieldType) => {
          this.setState(({ manualAddress }) => ({
            manualAddress: manualAddress.merge({ [id]: value }),
          }));
        };

        onGetQBvendorInfo = () => {
          const { getQBvendorInfo, orgId, vendorId } = this.props;

          getQBvendorInfo({ orgId: +orgId, vendorId: +vendorId });
        };

        updateVendorAddress = () => {
          // update the local state from the store, if vendor info was loaded from server
          if (this.props.isQBVendorInfoLoaded && !this.state.manualAddress.addressLine1) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(({ manualAddress }) => ({
              manualAddress: manualAddress.merge({
                ...this.props.qbVendorInfoAddress,
              }),
            }));
          }
        };

        addressValidationService = async () => {
          const { addressValidationErrors } = this.state;
          const manualAddress = this.state.manualAddress.toJS();
          const validatedAddressObj = await clientServiceApi.getAddressValidation(manualAddress);

          if (validatedAddressObj && validatedAddressObj.is_valid) {
            if (!validatedAddressObj.diff) {
              this.setState(
                {
                  invalidAddress: false,
                  validatedAddress: validatedAddressObj,
                  isVerified: true,
                },
                () => {
                  this.onAddressAdded();
                }
              );
            } else {
              if (validatedAddressObj.diff.length) {
                validatedAddressObj.diff.map((diff) =>
                  Object.assign(addressValidationErrors, diff)
                );
              }

              analytics.track(this.state.eventPage, 'alternative-suggested');
              this.setState({
                invalidAddress: false,
                confirmMode: true,
                isLoading: false,
                addressValidationErrors,
                validatedAddress: validatedAddressObj,
              });
            }
          } else {
            if (
              validatedAddressObj &&
              !validatedAddressObj.error &&
              !validatedAddressObj.is_valid
            ) {
              analytics.track(
                this.state.eventPage,
                'edit-delivery-method-check-no-suggestion-found'
              );
              this.setState({
                invalidAddress: true,
                isLoading: false,
                isVerified: false,
                validatedAddress: undefined,
              });
            }

            if (validatedAddressObj.error) {
              this.setState(
                {
                  isVerified: false,
                  isLoading: false,
                },
                () => {
                  this.onAddressAdded();
                }
              );
              analytics.track(
                this.state.eventPage,
                'edit-delivery-method-check-address-whitepages-validation-error',
                validatedAddressObj.error
              );
            }
          }
        };

        loadDefaultPrintName = () => {
          const { vendorId, orgId } = this.props;

          this.setState({ isLoading: true });
          vendorsApi
            .getVendorById({ orgId, id: vendorId })
            .then(({ object: vendor }) => {
              this.setState({
                isLoading: false,
                isReady: true,
                check: CheckRecord({ printName: vendor.companyName }),
              });
            })
            .catch(() => {
              this.setState({
                isLoading: false,
                isReady: true,
                check: CheckRecord({ printName: '' }),
              });
            });
        };

        loadDeliveryMethod = () => {
          const { vendorId, orgId, deliveryMethodId } = this.props;

          this.setState({ isLoading: true });
          deliveryMethodsApi
            .getDeliveryMethodById({ orgId, vendorId, id: deliveryMethodId })
            .then(({ deliveryMethod }) => {
              this.setState({
                isLoading: false,
                isReady: true,
                bank: BankRecord(deliveryMethod.bankAccount),
                // TODO: Use callback in setState when referencing the previous state
                // eslint-disable-next-line react/no-access-state-in-setstate
                check: this.state.check.printName
                  ? this.state.check
                  : CheckRecord(deliveryMethod.paperCheck),
                manualAddress: AddressRecord(deliveryMethod.paperCheck),
              });
            })
            .catch(() => {
              this.setState({
                isLoading: false,
                isReady: true,
                bank: BankRecord(),
                check: CheckRecord(),
              });
            });
        };

        updateDeliveryMethod = async (deliveryMethodUpdateData: DeliveryMethodUpdatePayload) => {
          const { orgId, vendorId, deliveryMethodId, deliveryMethod } = deliveryMethodUpdateData;
          const { ownedVendorId, createDeliveryMethod, editDeliveryMethod } = this.props;

          if (deliveryMethodId) {
            analytics.track(this.state.eventPage, 'edit-delivery-method', {
              type: deliveryMethod.deliveryType,
            });
            const { payload } = await editDeliveryMethod({
              orgId,
              vendorId,
              id: deliveryMethodId,
              deliveryMethod,
            });

            return payload;
          }

          if (ownedVendorId && ownedVendorId?.toString() === vendorId?.toString()) {
            deliveryMethod.isFilledByVendor = true;
            analytics.setTraits({
              [DbAnalyticsTraitsEnum.ADDED_DELIVERY_METHOD]: true,
            });
          }

          analytics.track(this.state.eventPage, 'add-delivery-method', {
            type: deliveryMethod.deliveryType,
          });

          const { payload } = await createDeliveryMethod(orgId, vendorId, deliveryMethod);

          return payload;
        };

        goExit = () => {
          analytics.track(this.state.eventPage, 'exit');

          if (this.props.navigateToExitWithPreservedState) {
            this.props.navigateToExitWithPreservedState();
          } else if (this.props.navigateWithPreservedState) {
            this.props.navigateWithPreservedState();
          } else {
            this.props.navigate(
              locations.Vendors.view.url({
                id: this.props.vendorId,
                type: ContactsTabEnum.VENDORS,
              })
            );
          }
        };

        navigateOut = (dataToAdd?: Record<string, any>) => {
          if (this.state.isVendorBlockedForPayment) {
            return;
          }

          if (this.props.navigateWithPreservedState) {
            this.props.navigateWithPreservedState(dataToAdd);
          } else {
            this.props.navigate(
              locations.Vendors.view.url({
                id: this.props.vendorId,
                type: ContactsTabEnum.VENDORS,
              })
            );
          }
        };

        render() {
          return (
            <>
              {this.state.isReady && (
                <Component
                  {...this.state}
                  {...this.props}
                  companyInfo={this.props.companyInfo}
                  goExit={this.goExit}
                  onCheckNameAdded={this.onCheckNameAdded}
                  onCheckAddressPrev={this.onCheckAddressPrev}
                  onManualAddressChange={this.onManualAddressChange}
                  onAddressAddRequest={this.onAddressAddRequest}
                  onAddressConfirmed={this.onAddressConfirmed}
                  onEditAddress={this.onEditAddress}
                  onAddressSelect={(id) => this.onAddressSelect(id)}
                  getQBvendorInfo={this.onGetQBvendorInfo}
                  isQBVendorInfoLoaded={this.props.isQBVendorInfoLoaded}
                />
              )}
            </>
          );
        }
      }
    );
  };
}
