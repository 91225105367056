import React from 'react';
import { useIntl } from 'react-intl';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import intercomService from 'src/app/services/intercom';
import { analytics } from 'src/app/version-2/externals';
import {
  SupportTeamLabel,
  StartChatButton,
  ContactContainer,
  ChatIcon,
  SupportLink,
} from './QBRDrawerSubtitle.styles';

const eventPage = 'qbr';

export const QBRDrawerSubtitle = () => {
  const site = useSiteContext();
  const { formatMessage } = useIntl();
  const onSupportEmailClick = () => {
    analytics.track(eventPage, 'support-email');
  };
  const openSupportChat = () => {
    analytics.track(eventPage, 'support-open-start-chat');
    intercomService.show();
  };

  return (
    <>
      <SupportTeamLabel>{formatMessage({ id: 'drawer.contactLabel' })}</SupportTeamLabel>
      <ContactContainer>
        <SupportLink href={`mailto:${site.config.support.email}`} onClick={onSupportEmailClick}>
          {site.config.support.email}
        </SupportLink>
      </ContactContainer>
      <StartChatButton
        size="lg"
        width="full"
        leftIcon={<ChatIcon className="icon-support-icon" />}
        onClick={openSupportChat}
      >
        {formatMessage({ id: 'launcher.startChat' })}
      </StartChatButton>
    </>
  );
};
