import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@melio/billpay-design-system';
import { MIFormattedCurrency, MIFormattedDate } from 'src/app/version-2/externals';
import { Bill } from 'src/app/version-2/model/dtos';
import { intuitSelectors } from 'src/app/version-2/modules/intuit/intuit.slice';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { SectionTitle, Value } from './BillDetailsModal.styles';

const formatPrefix = 'batchBulkPage.modals.billDetails';

interface BillDetailsModalProps {
  isOpen: boolean;
}

export const BillDetailsModal = ({ isOpen }: BillDetailsModalProps) => {
  const bill = useSelector(batchBulkSelectors.selectBillDetailsModalBillDTO) as Bill;
  const billCategory = useSelector(intuitSelectors.selectIntuitBillCategory(bill.intuitAccountId));
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const onClose = () => {
    dispatch(batchBulkActions.setBillDetailsModalAsClosed());
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} data-testid="bill-details-modal">
      <Modal.CloseButton data-testid="modal-close" />
      <Modal.Header>
        <Modal.HeaderTitle>{formatMessage({ id: `${formatPrefix}.title` })}</Modal.HeaderTitle>
      </Modal.Header>

      <SectionTitle>{formatMessage({ id: `${formatPrefix}.vendorName` })}</SectionTitle>
      <Value data-testid="bill-detail-modal-vendor-name">{bill.vendor?.companyName}</Value>

      <SectionTitle>{formatMessage({ id: `${formatPrefix}.category` })}</SectionTitle>
      <Value>{billCategory?.Name || formatMessage({ id: `${formatPrefix}.noCategory` })}</Value>

      <SectionTitle>{formatMessage({ id: `${formatPrefix}.billOpenBalance` })}</SectionTitle>
      <Value>
        <MIFormattedCurrency value={bill.balance || 0} />
      </Value>

      <SectionTitle>{formatMessage({ id: `${formatPrefix}.dueDate` })}</SectionTitle>
      <Value>
        <MIFormattedDate date={bill.dueDate} />
      </Value>

      <SectionTitle>{formatMessage({ id: `${formatPrefix}.billNo` })}</SectionTitle>
      <Value data-testid="bill-detail-modal-invoice-number">
        {bill.invoiceNumber
          ? bill.invoiceNumber
          : formatMessage({ id: `${formatPrefix}.noInvoiceNumber` })}
      </Value>

      <SectionTitle>{formatMessage({ id: `${formatPrefix}.noteToSelf` })}</SectionTitle>
      <Value>{bill.note || formatMessage({ id: `${formatPrefix}.noNote` })}</Value>
    </Modal>
  );
};
