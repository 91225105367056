import React from 'react';
import { SmartIcon } from '@melio/billpay-design-system';
import { FieldErrorMessageContainer } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/common/FieldErrorMessage/FieldErrorMessage.styles';

export interface FieldErrorMessageProps {
  label: string;
}

export const FieldErrorMessage = ({ label }: FieldErrorMessageProps) => (
  <FieldErrorMessageContainer>
    <SmartIcon type="error" size="1.8rem" />
    {label}
  </FieldErrorMessageContainer>
);
