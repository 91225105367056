import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import {
  getQBCashBalanceSlice,
  syncQBCashSlice,
} from 'src/app/modules/funding-sources/qb-cash-slice';
import fundingSourcesApi from 'src/app/services/api/fundingSources';
import {
  LOAD_FUNDING_SOURCES_SUCCESS,
  DELETE_FUNDING_SOURCE_SUCCESS,
  VERIFY_FUNDING_SOURCE_SUCCESS,
} from 'src/app/redux/user/actionTypes';
import { name } from './constants';

const fundingSourcesStore = createRestfulSlice({
  name,
  schemaName: 'fundingSource',
  api: fundingSourcesApi,
  slices: {
    syncQBCash: syncQBCashSlice,
    getQBCashBalance: getQBCashBalanceSlice,
  },
  selectors: {
    all: (state) => Object.values(state[name]?.byId || {}),
    byId: (id) => (state) => state[name]?.byId[id],
  },
  extraReducers: {
    [LOAD_FUNDING_SOURCES_SUCCESS]: (state, { fundingSources }) => {
      state.byId = fundingSources.reduce((acc, fs) => ({ ...acc, [fs.id]: fs.toJS() }), {});
    },
    [DELETE_FUNDING_SOURCE_SUCCESS]: (state, { deletedFundingSourceId }) => {
      delete state.byId[deletedFundingSourceId];
    },
    [VERIFY_FUNDING_SOURCE_SUCCESS]: (state, { verifiedFundingSourceId }) => {
      state.byId[verifiedFundingSourceId] = {
        ...(state.byId[verifiedFundingSourceId] || {}),
        isVerified: true,
      };
    },
  },
} as any);

export default fundingSourcesStore;
