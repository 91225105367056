import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS } from 'src/app/utils/consts';
import { ReactComponent as InternationalIcon } from 'src/app/images/qbo/international/delivery-icon.svg';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';

type SelectedMethodProps = {
  icon?: string;
  imageSrc?: string;
  title?: string;
  errorLabelValues?: any;
  errorLabel?: string;
  label: string;
  labelValues?: Record<string, any>;
  showDropdownIcon?: boolean;
  tooltipLabel?: string;
  tooltipValue?: Record<string, any>;
  isOpen?: boolean;
  method?: any;
};

function SelectedMethod({
  icon,
  imageSrc,
  title,
  label,
  labelValues,
  isOpen,
  showDropdownIcon,
  errorLabel,
  errorLabelValues,
  tooltipLabel,
  tooltipValue,
  method,
}: SelectedMethodProps) {
  const getIconComponent = () => {
    switch (method?.deliveryType) {
      case CONSTS.DELIVERY_TYPE.INTERNATIONAL:
        return <InternationalIcon />;

      case CONSTS.DELIVERY_TYPE.VIRTUAL_CARD:
        return <Mastercard width="3.2rem" height="2.5rem" />;

      default:
        return (icon && <i className={icon} />) || null;
    }
  };

  const methodIcon = getIconComponent();

  return (
    <>
      <SelectedMethodInfoContainer>
        <PaymentIcon>
          {methodIcon}
          {imageSrc && <img src={imageSrc} alt="selected-method-icon" />}
        </PaymentIcon>
        <SelectMethodInfoTextContainer>
          {title && (
            <PaymentMethodText>
              <MIFormattedText label={title} />
            </PaymentMethodText>
          )}
          <Tooltip
            isDisabled={!tooltipLabel}
            label={<MIFormattedText label={tooltipLabel} values={{ value: tooltipValue }} />}
          >
            <PaymentInfoText>
              {label && <MIFormattedText label={label} values={labelValues} />}
            </PaymentInfoText>
          </Tooltip>

          {errorLabel && (
            <ErrorMessage>
              <ErrorIcon className="icon-warning-icon" />
              <ErrorMessageText>
                <MIFormattedText label={errorLabel} values={errorLabelValues} />
              </ErrorMessageText>
            </ErrorMessage>
          )}
        </SelectMethodInfoTextContainer>
      </SelectedMethodInfoContainer>
      {showDropdownIcon && <DropdownIcon isDropdownOpen={isOpen} />}
    </>
  );
}

export default SelectedMethod;

const SelectedMethodInfoContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 3.8rem;
`;

const SelectMethodInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ErrorMessageText = styled.div`
  padding-left: 0.5rem;
`;

const ErrorIcon = styled.i`
  margin-top: 0.2rem;
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  padding-right: 0.5rem;
  display: flex;
`;

const DropdownIcon = styled.i.attrs({ className: 'icon-next-icon-2' })<{
  isDropdownOpen?: boolean;
  label?: string;
}>`
  font-size: 1.6rem;
  color: rgba(107, 108, 114, 1);
  font-weight: ${(props) => props.theme.text.weight.bold};
  transform: rotate(90deg);
  position: relative;
  right: ${({ isDropdownOpen, label }) =>
    /* eslint-disable no-nested-ternary */
    isDropdownOpen ? '-0.4rem;' : label ? '2rem' : '1.9rem'};
  ${(props) =>
    props.isDropdownOpen &&
    css`
      transform: rotate(-90deg);
    `}
`;

const PaymentInfoText = styled.div<{ isBillPaid?: boolean }>`
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoText}
`;

const PaymentMethodText = styled.div<{ isBillPaid?: boolean }>`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.2px;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentMethodText}
`;

const PaymentIcon = styled.i<{ isBillPaid?: boolean }>`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  > img {
    height: 2.4rem;
  }

  > i {
    font-size: 2.4rem;
    color: ${(props) =>
      props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentIcon}
`;
