import * as React from 'react';
import analytics from 'src/app/services/analytics';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  id?: string;
  positiveLabel?: string | null;
  negativeLabel?: string | null;
  value: boolean;
  onChange: (value: boolean) => void;
  color?: 'green' | 'purple' | 'red';
  testId?: string;
  disabled?: boolean;
  labelValues?: Record<string, any>;
  dashed?: boolean;
};

export const MICheckbox = ({
  id,
  positiveLabel,
  negativeLabel,
  value,
  onChange,
  color = 'green',
  testId,
  disabled,
  labelValues,
  dashed,
}: Props) => {
  const label = value ? positiveLabel : negativeLabel;
  const idCheckbox = `${label || ''}_checkbox`;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    label && analytics.trackAction(label, { checked: event.target.checked });
    onChange(event.target.checked);
  };

  const handleCheckClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <Container>
      <BooleanCheckboxContainer>
        <Checkbox
          id={id || idCheckbox}
          data-testid={testId || `input-${id || idCheckbox}`}
          checked={value}
          onChange={handleOnChange}
          color={color}
          disabled={disabled}
        />
        {dashed ? (
          <CheckDash disabled={disabled as boolean} onClick={handleCheckClick as any} />
        ) : (
          <Checkmark disabled={disabled as boolean} onClick={handleCheckClick as any} />
        )}
      </BooleanCheckboxContainer>
      {label && (
        <Label htmlFor={id || idCheckbox} checked={value}>
          <MIFormattedText label={label} values={labelValues} />
        </Label>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.label<{ checked?: boolean }>`
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-left: 1rem;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
  cursor: pointer;
`;

const Checkmark = styled.i<{ disabled: boolean }>`
  box-sizing: border-box;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  height: 2rem;
  width: 2rem;
  border: 0.2rem solid ${(props) => props.theme.text.color.label};
  ${(props) =>
    props.disabled &&
    css`
      border-color: ${(props) => props.theme.text.color.readonly};
    `}
  border-radius: 0.4rem;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0.7rem;
    top: 0.2rem;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
    display: none;
  }
`;

const CheckDash = styled.i<{ disabled: boolean }>`
  box-sizing: border-box;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  height: 2rem;
  width: 2rem;
  border: 0.2rem solid ${(props) => props.theme.text.color.label};
  ${(props) =>
    props.disabled &&
    css`
      border-color: ${(props) => props.theme.text.color.readonly};
    `}
  border-radius: 0.4rem;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0.9rem;
    top: 0.5rem;
    width: 0rem;
    height: 0.8rem;
    border: solid white;
    border-width: 0 0.2rem 0.2rem 0;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    display: none;
    border-radius: 5px;
  }
`;

const checkboxColorMap = (props) => ({
  green: props.theme.text.color.green,
  purple: props.theme.text.color.highlight,
  red: props.theme.text.color.red,
});

const Checkbox = styled.input.attrs({ type: 'checkbox' })<{ color: string }>`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  :checked + ${Checkmark} {
    border: none;
    background-color: ${(props) => checkboxColorMap(props)[props.color]};

    ${(props) =>
      props.disabled &&
      css`
        background-color: ${props.theme.colors.dark.translucent3};
      `}

    &:after {
      display: block;
    }
  }

  :checked + ${CheckDash} {
    border: none;
    background-color: ${(props) => checkboxColorMap(props)[props.color]};

    ${(props) =>
      props.disabled &&
      css`
        background-color: ${props.theme.colors.dark.translucent3};
      `}

    &:after {
      display: block;
    }
  }
`;

const BooleanCheckboxContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;

  ${Checkbox} {
    display: none;
  }
`;
