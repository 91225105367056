import { createSlice } from '@reduxjs/toolkit';
import { composeSlices } from 'src/app/helpers/redux/composeSlice';
import { defaultCreateHashFunction } from 'src/app/helpers/redux/createRestfulSlice';
import { createCreateSlice } from 'src/app/helpers/redux/restCreateSlice';
import { createFetchSlice } from 'src/app/helpers/redux/restFetchSlice';
import { createUpdateSlice } from 'src/app/helpers/redux/restUpdateSlice';
import quickPaymentsApi from 'src/app/services/api/quickPaymentsApi';
import { clearStateReducer } from 'src/app/helpers/redux/clearStateReducer';
import { PAYMENT_STATUS } from 'src/app/utils/consts';
import { PaymentCreateFlowOriginEnum } from 'src/app/version-2/model/enums';
import { safeDate } from './utils';

const name = 'payments';

export type QuickPayment = {
  id?: string;
  vendorId?: string;
  note?: string;
  scheduledDate?: Date;
  totalAmount?: number;
  amount?: number;
  deliveryEta?: Date;
  maxDeliveryEta?: Date;
  currency?: string;
  deliveryMethodId?: string;
  fundingSourceId?: string;
  billId?: string;
  status?: PAYMENT_STATUS;
  createOrigin?:
    | PaymentCreateFlowOriginEnum.QBR_IOS
    | PaymentCreateFlowOriginEnum.QBR_ANDROID
    | string;
  deliveryPreference?: string;
  payBillFlowUUID?: string | null;
};

type QuickpayState = {
  quickpay: QuickPayment;
};

const initialState: QuickpayState = {
  quickpay: {},
};

const create = createCreateSlice({
  actionName: 'quickpay_create',
  storeName: name,
  api: quickPaymentsApi.createWithBill,
  createHashFunc: defaultCreateHashFunction,
});

const update = createUpdateSlice({
  actionName: 'quickpay_update',
  storeName: name,
  api: quickPaymentsApi.update,
});

const retry = createUpdateSlice({
  actionName: 'quickpay_retry',
  storeName: name,
  api: quickPaymentsApi.create,
});

const newPaymentWizard = () => {
  const slice = createSlice({
    name: `[${name}] create_quickpay`.toUpperCase(),
    initialState,
    reducers: {
      new: (state, action) => {
        state.quickpay = {
          id: action.payload.id,
          vendorId: action.payload.vendorId,
          note: action.payload?.note,
          scheduledDate: safeDate(action.payload?.scheduledDate),
          deliveryEta: safeDate(action.payload?.deliveryEta),
          maxDeliveryEta: safeDate(action.payload?.maxDeliveryEta),
          totalAmount: parseFloat(action.payload.totalAmount),
          currency: action.payload?.currency || 'USD',
          deliveryMethodId: action.payload?.deliveryMethodId,
          fundingSourceId: action.payload?.fundingSourceId,
          status: action.payload?.status,
          billId: action.payload?.billId,
        };
      },
      create: (state, action) => {
        state.quickpay.billId = action.payload?.billId;
      },
      update: (state, action) => {
        const { payload } = action;

        Object.entries(payload).forEach(([key, value]) => {
          state.quickpay[key] = value;
        });
      },
      clear: (state) => {
        state.quickpay = {};
      },
    },
  });

  return {
    ...slice,
    initialState,
    selectors: {
      payment: (state) => state[name]?.quickpay,
    },
    dispatchers: {
      new: (dispatch) => (params) => dispatch(slice.actions.new(params)),
      update: (dispatch) => (params) => dispatch(slice.actions.update(params)),
      clear: (dispatch) => () => dispatch(slice.actions.clear()),
    },
  };
};

const fetch = createFetchSlice({
  actionName: 'quickpay_fetch',
  storeName: name,
  api: quickPaymentsApi.fetch,
});

const quickpaySlice = composeSlices(
  {
    create,
    update,
    retry,
    fetch,
    newPaymentWizard: newPaymentWizard(),
  },
  {
    extraReducers: { ...clearStateReducer(initialState) },
  }
);

export { quickpaySlice };
