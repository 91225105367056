import React from 'react';
import { Flex } from '@melio/billpay-design-system';

import { TimelineStepType } from '../types';

import { VendorPaymentTimelineStep } from './VendorPaymentTimelineStep';

type Props = {
  steps: TimelineStepType[];
};

const VendorPaymentTimeline = ({ steps }: Props) => (
  <Flex direction="column" width="100%" data-testid="vendor-payment-timeline-container">
    {steps?.map((step) => (
      <VendorPaymentTimelineStep step={step} key={step.title} />
    ))}
  </Flex>
);

export { VendorPaymentTimeline };
