import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from '@melio/billpay-design-system';

import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { DeliveryMethodType } from 'src/app/utils/types';
import { DeliveryMethodIcon } from './DeliveryMethodIcon';
import { DeliveryMethodDescription } from './DeliveryMethodDescription';
import { DeliveryMethodTitle } from './DeliveryMethodTitle';
import { getBorder } from '../utils';
import { RecoveryFlowType } from '../../consts';

type Props = {
  deliveryMethod: DeliveryMethodType;
  flowType: RecoveryFlowType;
  description?: string | React.ReactNode;
  descriptionValues?: Record<string, any>;
  onItemClick: () => void;
};

export const DeliveryMethodItem = ({
  deliveryMethod,
  flowType,
  description,
  descriptionValues,
  onItemClick,
}: Props) => {
  const deliveryType = deliveryMethod?.deliveryType;
  const isRecommended = deliveryType === DELIVERY_TYPE.ACH;

  return (
    <StyledDeliveryMethodItem onClick={onItemClick} data-testid={`delivery-method-${deliveryType}`}>
      <Flex justify="space-between">
        <Flex align="center">
          <Flex pl={1} pr={5} alignItems="center">
            <DeliveryMethodIcon deliveryType={deliveryType} />
          </Flex>
          <div>
            <DeliveryMethodTitle
              deliveryType={deliveryType}
              isRecommended={isRecommended}
              flowType={flowType}
            />
            <DeliveryMethodDescription
              deliveryType={deliveryType}
              flowType={flowType}
              description={description}
              descriptionValues={descriptionValues}
            />
          </div>
        </Flex>
      </Flex>
    </StyledDeliveryMethodItem>
  );
};

const StyledDeliveryMethodItem = styled.div<{ isDisabled?: boolean; isSelected?: boolean }>`
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  max-width: 45rem;
  width: 100%;
  border-radius: 0.8rem;
  border: ${(props) =>
    getBorder({
      selected: props.isSelected,
      disabled: props.isDisabled,
      colors: props.theme.colors,
    })};
  background-color: ${(props) =>
    props.isDisabled ? props.theme.colors.white.veryLightGrey : props.theme.colors.white.opaque};
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.isDisabled ? 'none' : `0 0.5rem 1.5rem 0 ${props.theme.colors.dark.translucent1}`};
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  transition: ${(props) => props.theme.animation.transition.default};
  transition-property: box-shadow;

  &:hover:not(:active) {
    ${(props) =>
      !(props.isDisabled || props.isSelected) &&
      css`
        box-shadow: 0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2};
      `}
  }

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    margin-top: 0;
  }
`;
