import * as React from 'react';
import { RecordOf } from 'immutable';
import styled from 'styled-components';
import { isValidationOk, getValidationErrors } from '@melio/sizzers-js-common';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { MICardForm, MICardTitle } from 'src/app/components/common/MICard';
import { DIALOG_VARIANTS, DELIVERY_METHOD_ORIGIN, DIALOG_TYPE } from 'src/app/utils/consts';
import {
  DeliveryMethodType,
  FieldType,
  VendorType,
  ScreenMode,
  UserContextType,
} from 'src/app/utils/types';
import { isEnterPressed } from 'src/app/utils/events';
import vendorsApi from 'src/app/services/api/vendors';
import { withNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import { MIFloatedEditDoneButtons } from 'src/app/components/common/MIFloatedEditDoneButtons';
import { AreaLoader } from '@melio/billpay-design-system';
import locations from 'src/app/utils/locations';
import { GlobalState } from 'src/app/redux/types';
import { getProfile } from 'src/app/redux/user/selectors';
import { devices } from 'src/app/theme/AppDevices';
import { isRppsVendor } from 'src/app/pages/vendor-directory/utils';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { ScreenModeEnum, FormTypeEnum } from 'src/app/version-2/model/enums';
import QBOVendorDeliveryMethodsList from './QBOVendorDeliveryMethodsList';
import QBOVendorForm from './QBOVendorForm';
import { VendorRecord } from '../records-constants';

type MapStateToProps = {
  profile: RecordOf<UserContextType>;
};

type Props = {
  id?: string | null;
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
} & MapStateToProps;

type State = {
  mode: ScreenMode;
  vendor?: RecordOf<VendorType>;
  validationErrors: Record<string, string>;
  isDeleting: boolean;
  isLoading: boolean;
  errorCode?: string | null;
};

const eventPage = 'vendor';

class QBOViewVendor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: ScreenModeEnum.EDIT,
      vendor: VendorRecord(),
      isLoading: false,
      validationErrors: {},
      isDeleting: false,
      errorCode: null,
    };
  }

  componentDidMount() {
    this.loadVendor();
  }

  onToggleMode = () => {
    this.setState(({ mode }) => ({
      mode: mode === ScreenModeEnum.VIEW ? ScreenModeEnum.EDIT : ScreenModeEnum.VIEW,
    }));

    if (this.state.mode === ScreenModeEnum.EDIT) {
      analytics.track(eventPage, 'edit-vendor-mode');
    } else {
      analytics.track(eventPage, 'cancel-edit-vendor-mode');
    }
  };

  onDeleteVendorClicked = () => {
    analytics.track('vendors', 'delete-vendor');
    this.setState({ isDeleting: true });
  };

  onDeleteVendorCanceled = () => {
    analytics.track('vendors', 'delete-vendor-canceled');
    this.setState({ isDeleting: false });
  };

  onKeyPressed = (event: React.KeyboardEvent) => {
    const { mode } = this.state;

    if (mode === ScreenModeEnum.EDIT && isEnterPressed(event)) {
      this.onEditVendor();
    }
  };

  onFieldChanged = ({ id, value }: FieldType) => {
    this.setState(({ vendor }) => ({ vendor: vendor?.merge({ [id]: value }) }));
  };

  onDeleteFailedVendorError = () => {
    this.onDeleteVendorCanceled();
    this.setState({ errorCode: '' });
  };

  onEditVendor = () => {
    const { vendor } = this.state;
    const { profile, id, navigate } = this.props;

    const validationErrors = getValidationErrors('vendor', vendor);

    this.setState({ validationErrors }, () => {
      if (isValidationOk(validationErrors)) {
        this.setState({ isLoading: true });
        analytics.track(eventPage, 'edit-vendor');

        vendorsApi
          .editVendorById({ orgId: profile.orgId, id, params: vendor })
          .then(({ object }) => {
            analytics.track(eventPage, 'edit-vendor-success');
            this.setState({ vendor: VendorRecord(object), isLoading: false });
            navigate(locations.Settings.vendors.url());
          })
          .catch(() => {
            analytics.track(eventPage, 'edit-vendor-fail');
            this.setState({ isLoading: false });
          });
      } else {
        analytics.track(eventPage, 'edit-vendor-validation-error', validationErrors);
      }
    });
  };

  onDeleteVendor = () => {
    const { profile, id } = this.props;

    analytics.track(eventPage, 'delete-vendor-confirmed');
    this.setState({ isLoading: true });
    vendorsApi
      .deleteVendorById(profile.orgId, id)
      .then(() => {
        analytics.track(eventPage, 'delete-vendor-success');
        this.setState({ vendor: undefined, isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false, errorCode: e.code });
      });
  };

  handleContactClick(type) {
    analytics.track(eventPage, 'contact-action', { type });
  }

  loadVendor = () => {
    const { id } = this.props;

    this.setState({ isLoading: true });

    if (id) {
      vendorsApi
        .getVendorById({ orgId: this.props.profile.orgId, id })
        .then(({ object: vendor }) => {
          this.setState({ vendor: VendorRecord(vendor), isLoading: false });
        })
        .catch(() => {
          this.setState({ vendor: VendorRecord(), isLoading: false });
        });
    } else {
      this.setState({ vendor: VendorRecord(), isLoading: false });
    }
  };

  goEditDeliveryMethod = (deliveryMethod: DeliveryMethodType) => {
    const { id } = this.state.vendor || {};
    const { profile } = this.props;
    const deliveryMethodId = deliveryMethod?.id;
    const locationPrefix = locations.Vendors.deliveryMethods[deliveryMethod.deliveryType];
    const url = (() => {
      if (deliveryMethodId) return locationPrefix.edit.url({ id, deliveryMethodId });

      return locationPrefix.create.url({ id });
    })();

    if (deliveryMethod.id) {
      analytics.track(eventPage, 'edit-delivery-method', {
        type: deliveryMethod.deliveryType,
      });
    } else {
      analytics.track(eventPage, 'add-delivery-method', {
        type: deliveryMethod.deliveryType,
      });
    }

    this.props.navigate(url, false, {
      redirectUrl: locations.Settings.vendorSingle.url({ id, orgId: profile.orgId }),
      exitUrl: locations.Settings.vendorSingle.url({ id, orgId: profile.orgId }),
      origin: DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS,
    });
  };

  renderAlertDialog(vendor) {
    let deleteSubText = 'vendors.form.deleteDialog.subtitle';

    const { errorCode } = this.state;

    if (errorCode) {
      analytics.track(eventPage, 'cannot-delete-vendor-dialog');
      deleteSubText =
        errorCode === 'VDR04'
          ? 'vendors.form.deleteDialog.subtitleWarningHasPayments'
          : 'vendors.form.deleteDialog.subtitleWarningHasBills';

      return (
        <QBODialog
          type={DIALOG_TYPE.CONFIRM}
          variant={DIALOG_VARIANTS.ERROR}
          title={`server.${errorCode}`}
          subtitle={deleteSubText}
          onCancelAction={this.onDeleteFailedVendorError}
        />
      );
    }

    analytics.track(eventPage, 'delete-vendor-dialog');

    return (
      <QBODialog
        type={DIALOG_TYPE.CONFIRM}
        variant={DIALOG_VARIANTS.ERROR}
        title="vendors.form.deleteDialog.title"
        titleValues={{ vendorName: vendor.companyName }}
        subtitle={deleteSubText}
        subtitleValues={{
          vendorName: vendor.companyName,
        }}
        okButtonText="vendors.form.deleteDialog.confirm"
        onOkAction={this.onDeleteVendor}
        onCancelAction={this.onDeleteVendorCanceled}
      />
    );
  }

  render() {
    const {
      mode,
      vendor: vendorState,
      validationErrors,
      isDeleting,
      vendor,
      isLoading,
    } = this.state;

    if (isLoading || !vendor?.id) {
      return <AreaLoader />;
    }

    const isVendorNotOwnedByUser = !vendor.ownedById;
    const showVendorDeliveryMethods = !isRppsVendor(vendor) && isVendorNotOwnedByUser;

    return (
      <>
        {isDeleting && this.renderAlertDialog(vendor)}
        <VendorMICardForm onKeyDown={(event) => this.onKeyPressed(event)}>
          <FormTitleContainer>
            <MICardTitle label="vendors.view.title" />
          </FormTitleContainer>
          {vendorState && (
            <QBOVendorForm
              mode={mode}
              vendor={vendorState}
              onChange={this.onFieldChanged}
              validationErrors={validationErrors}
              formType={FormTypeEnum.DETAILS}
            />
          )}
        </VendorMICardForm>
        {showVendorDeliveryMethods && (
          <QBOVendorDeliveryMethodsList
            vendor={vendor}
            goEditDeliveryMethod={this.goEditDeliveryMethod}
          />
        )}
        {mode === ScreenModeEnum.EDIT && (
          <VendorMIFloatedEditDoneButtons
            onDone={this.onEditVendor}
            doneLabel="vendors.edit.done"
            titleLabel="vendors.title"
            cancelLabel="bills.edit.cancel"
            onCancel={this.onToggleMode}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
});

export default compose(
  withNavigator(),
  withProps((props) => {
    const { id } = props.match.params;

    return {
      id,
    };
  }),
  connect(mapStateToProps)
)(QBOViewVendor);

const FormTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VendorMICardForm = styled(MICardForm)`
  display: flex;
  flex-direction: column;
  padding: 2.3rem 3.6rem 3rem 3.6rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2.3rem 1.6rem 3rem 1.6rem;
  }
`;

const VendorMIFloatedEditDoneButtons = styled(MIFloatedEditDoneButtons)`
  border-bottom-left-radius: 0.9rem;
  border-bottom-right-radius: 0.9rem;
`;
