import * as React from 'react';
import styled from 'styled-components';
import { ProgressBarFlavorEnum } from 'src/app/version-2/model/enums';
import ProgressBar from '../common/ProgressBar';
import { devices } from '../../theme/AppDevices';
import { MIFormattedText } from '../../utils/formatting';
import { useBreak } from '../../hoc';

type StepProgressBarHeaderProps = {
  progressBarSteps: string[];
  minorSteps: number[];
};

const StepProgressBar = ({ progressBarSteps, minorSteps }: StepProgressBarHeaderProps) => {
  const { isDesktop, isTablet } = useBreak();
  const circleSize = isDesktop || isTablet ? 3.4 : 2.4;

  return (
    <BarContainer>
      <MainProgressBarContainer circleSize={circleSize}>
        {minorSteps.map((steps, index) => (
          <ProgressBarContainer key={index}>
            <ProgressBar relativeStep={steps} flavor={ProgressBarFlavorEnum.MELIO_ME} />
          </ProgressBarContainer>
        ))}
      </MainProgressBarContainer>
      <StepsContainer>
        {progressBarSteps.map((label, index) => (
          <StepContainer key={index}>
            <MajorStep
              circleSize={circleSize}
              isActive={index === 0 || minorSteps[index - 1] === 1}
            >
              {index + 1}
            </MajorStep>
            {(isDesktop || isTablet) && (
              <StepTitle>
                <MIFormattedText label={label} />
              </StepTitle>
            )}
          </StepContainer>
        ))}
      </StepsContainer>
    </BarContainer>
  );
};

const StepProgressBarWidth = 53.5;

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${StepProgressBarWidth}rem;
  position: relative;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 60%;
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainProgressBarContainer = styled.div<{ circleSize: number }>`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: ${(props) => props.circleSize / 2 - 0.2}rem;
  left: ${(props) => props.circleSize - 0.1}rem;
  right: ${(props) => props.circleSize - 0.1}rem;
  width: calc(100%-${(props) => 2 * (props.circleSize - 0.1)}rem);
`;

const ProgressBarContainer = styled.div`
  width: 100%;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
`;

const StepTitle = styled.span`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-align: center;
  ${(props) => props.theme.text.fontType.small};
`;

const MajorStep = styled.div<{ circleSize: number; isActive?: boolean }>`
  text-align: center;
  line-height: ${(props) => props.circleSize}rem;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.primary.opaque : props.theme.colors.progress.undone};
  height: ${(props) => props.circleSize}rem;
  width: ${(props) => props.circleSize}rem;

  border-radius: 50%;
  color: ${(props) => props.theme.colors.white.opaque};
  font-size: ${(props) => props.theme.text.size.button};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  z-index: 1;

  @media ${devices.desktop}, ${devices.tablet} {
    margin-bottom: 1.6rem;
  }
`;

export default StepProgressBar;
