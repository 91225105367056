import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { RecordOf } from 'immutable';

import paymentsStore from 'src/app/modules/payments/payment-store';
import { getOrgId } from 'src/app/redux/user/selectors';
import QboFundingSourcesListLayout from 'src/app/components/common/SelectMethods/containers/QboFundingSourcesListLayout';
import { SecurityDetails } from 'src/app/components/common/SecurityDetails';
import ScrollToTop from 'src/app/components/layout/ScrollToTop';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { UserCompanyInfo } from 'src/app/version-2/model/dtos/userCompanyInfo';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import {
  WizardStepTitle,
  WizardStepSubTitle,
  WizardFooterContainer,
} from 'src/app/components/layout/QBOWizardElements';
import { usePartialPayments } from 'src/app/pages/bill/pay/hooks/usePartialPayments';
import { billListModifiedType } from 'src/app/pages/bill/pay/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import intuit from 'src/app/utils/intuit';
import locations from 'src/app/utils/locations';
import globalLocations from 'src/app/pages/locations';
import { CARD_TYPES, ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/app/utils/consts';
import { melioClose } from 'src/app/utils/external-events';
import { isOrgBlockedByRisk } from 'src/app/utils/company';
import { useBenefits } from 'src/app/pages/bill/pay/hooks/useBenefits';
import { CreditCardBenefitsModal } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitsModal';
import { useDebitFee } from 'src/app/pages/bill/pay/hooks/useDebitFee';
import {
  AddFundingSourceWizardOriginEnum,
  DeliveryEnum,
  FundingSourceTypesEnum,
  VendorTypeEnum,
} from 'src/app/version-2/model/enums';

type Props = {
  billIds: string[];
  partialPayments: Record<string, number>;
  locationState?: any;
};

const PayBatchFundingSourcePage = ({ billIds, partialPayments = {}, locationState }: Props) => {
  const history = useHistory<{ [key: string]: any }>();
  const dispatch = useDispatch();
  const intl = useIntl();
  const debitFee = useDebitFee();

  const orgId = useSelector(getOrgId);
  const companyInfo: Partial<UserCompanyInfo> = useSelector(userSliceSelectors.getCompanyInfo);
  const billsHeap: Record<string, RecordOf<billListModifiedType>> = useSelector(
    paymentsStore.selectors.billListModified
  );
  const paymentIntentId = locationState?.preservedState?.selectedPaymentIntent?.paymentIntentId;
  const paymentIntentItem = locationState?.preservedState?.batchList.find(
    (batchItem) => batchItem?.id === paymentIntentId
  );
  const { deliveryType } = paymentIntentItem?.payment?.deliveryMethod || {};
  const isInternational = deliveryType === DeliveryEnum.INTERNATIONAL;
  const vendorType = isInternational ? VendorTypeEnum.INTERNATIONAL : undefined;

  const { shouldShowBenefitsModal, onBenefitsClicked, onCloseBenefitsModal } = useBenefits();

  const currentUrl = locations.Bills.pay.batchFunding.url({
    ids: billIds,
    orgId,
  });

  const redirectUrl = locations.Bills.pay.batch.url({
    ids: billIds,
    orgId,
  });

  const exitUrl = useSelector(paymentsStore.selectors.qboBatchPaymentsExitUrl);

  const { getPaymentAmount, setPaymentAmounts } = usePartialPayments(dispatch);

  useEffect(() => {
    if (isOrgBlockedByRisk(companyInfo)) {
      history.push(globalLocations.entrypoints.errors.orgBlockedByRisk);

      return;
    }

    setPaymentAmounts(partialPayments);

    intuit.endLoadingWrapper();
  }, []);

  const isFSWizardBatchBulkOrigin =
    history.location?.state?.preservedState?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK;

  const handleExitClicked = () => {
    if (isFSWizardBatchBulkOrigin) {
      history.push(history.location.state.preservedState?.exitUrl, {
        ...history.location.state.preservedState,
      });

      return;
    }

    if (locationState?.exitUrl || exitUrl) {
      history.push(locationState?.exitUrl || exitUrl);
    } else {
      melioClose();
    }
  };

  const handleFundingSourceTypeSelected = (type: string) => {
    switch (type) {
      case FundingSourceTypesEnum.ACH:
        history.push(locations.Onboarding.fundingSources.bank.select.url({ orgId }), {
          preservedState: {
            origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.GUEST_ONBOARDING,
            ...locationState?.preservedState,
          },
          redirectUrl,
          exitUrl: currentUrl,
          billIds,
          isBatch: true,
        });
        break;
      case CARD_TYPES.CREDIT:
      case CARD_TYPES.DEBIT:
        history.push(locations.Onboarding.fundingSources.card.index.url(), {
          preservedState: {
            origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.GUEST_ONBOARDING,
            ...locationState?.preservedState,
          },
          redirectUrl,
          exitUrl: currentUrl,
        });
        break;
      default:
        break;
    }
  };

  let totalCount = 0;
  let totalAmount = 0;

  if (isFSWizardBatchBulkOrigin) {
    totalCount = paymentIntentItem?.payment?.bills?.length || 0;
    totalAmount = paymentIntentItem?.payment?.amount;
  } else {
    billIds.forEach((id) => {
      const paymentAmount = getPaymentAmount(billsHeap[id]?.bill);

      totalCount += paymentAmount ? 1 : 0;
      totalAmount += paymentAmount;
    });
  }

  const readableAmount = intl.formatNumber(totalAmount, {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <ScrollToTop>
      <StyledQBOLayoutPage
        headerLabel={totalCount && readableAmount ? 'batchPaymentFunding.header' : ''}
        headerLabelValues={{
          count: totalCount,
          total: readableAmount,
        }}
        goExit={handleExitClicked}
        footer={<SecurityDetails />}
      >
        <WizardStepTitle>
          <MIFormattedText label="batchPaymentFunding.title" />
        </WizardStepTitle>
        <WizardStepSubTitle>
          <MIFormattedText label="batchPaymentFunding.subtitle" />
        </WizardStepSubTitle>

        <QboFundingSourcesListLayout
          fundingSources={[]}
          onChange={() => null}
          onAddMethod={handleFundingSourceTypeSelected}
          onBenefitsClicked={onBenefitsClicked}
          debitFee={debitFee.fee}
          vendorType={vendorType}
          eventSource="batch-payment"
          billIds={billIds}
        />

        {shouldShowBenefitsModal && (
          <CreditCardBenefitsModal
            onButtonClick={() => handleFundingSourceTypeSelected(CARD_TYPES.CREDIT)}
            onCloseClick={onCloseBenefitsModal}
          />
        )}
      </StyledQBOLayoutPage>
    </ScrollToTop>
  );
};

const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  ${WizardStepTitle} {
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  ${WizardFooterContainer} {
    margin-top: 0;
  }
`;

export { PayBatchFundingSourcePage };
