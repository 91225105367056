import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { UboItem } from 'src/app/version-2/model/dtos';

export const UBO_SLICE = `${VERSION_2}ultimateBeneficialOwners`;

interface uboSlice {
  ubo: EntityState<UboItem>;
}

export const selectState = (state: any) => state[UBO_SLICE];

const uboAdapter = createEntityAdapter<UboItem>({
  selectId: (ubo) => ubo.id,
});

export const uboInitialState: uboSlice = {
  ubo: uboAdapter.getInitialState(),
};

export const uboSlice = createSlice({
  name: UBO_SLICE,
  initialState: uboInitialState,
  reducers: {
    setUboItems: (state, action) => {
      uboAdapter.setAll(state.ubo, action.payload);
    },
  },
});

export const uboSelectors = {
  hasUboItems: createSelector(selectState, (state) => {
    const items = uboAdapter.getSelectors().selectAll(state.ubo);

    return items?.length > 0;
  }),
};

export const uboActions = {
  ...uboSlice.actions,
  fetchUltimateBeneficialOwners: createAction(`${UBO_SLICE}/fetch-ubo`),
};
