import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Loader } from '@melio/billpay-design-system';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { devices } from 'src/app/theme/AppDevices';
import { InlineTextInputField } from 'src/app/ui/form';
import { validateField } from 'src/app/utils/form-utils';

import { ERROR_PREFIX } from '../../utils';
import { FormUtils, RequiredFields, BillingTypes, BankDetails } from '../../types';

type Props = FormUtils & {
  requiredFieldsValidation: RequiredFields;
  bankDetails: Partial<BankDetails>;
  bankDetailsLoading: boolean;
  setBankDetails: (value) => void;
  getBankDetails: (billingType: string, billingValue: string) => Promise<any>;
};

const BillingField = ({
  values,
  billingType,
  validationErrors,
  requiredFieldsValidation,
  bankDetails,
  bankDetailsLoading,
  setBankDetails,
  getBankDetails,
  setValidationErrors,
}: Props) => {
  const hasBankDetails = !!bankDetails?.identifier_type;
  const onValidate = (value) => {
    const errorMessage = validateField({
      name: billingType,
      value,
      regex: requiredFieldsValidation[billingType],
      isRequired: true,
      prefix: ERROR_PREFIX,
    });

    if (!isEmpty(bankDetails)) setBankDetails({});

    if (errorMessage) {
      if (validationErrors[billingType] !== errorMessage) {
        // avoid render
        setValidationErrors((prev) => ({
          ...prev,
          [billingType]: errorMessage,
        }));
      }
    } else {
      // validate for bank request (800ms)
      setValidationErrors({});
      onRequestDebounce(billingType, value);
    }
  };

  const onRequestDebounce = useCallback(debounce(getBankDetails, 800), [billingType]);
  const onValidateDebounce = useCallback(debounce(onValidate, 800), [
    billingType,
    validationErrors,
    bankDetails,
  ]);

  const onChange: any = ({ value, id }) => {
    values[id].onChange({ value });

    // validate for local error message (150ms)
    if (id === IBAN || id === SWIFT) onValidateDebounce(value);
  };

  const sharedParams = {
    onChange,
    required: true,
    readOnlyValue: bankDetailsLoading,
    privateData: true,
  };

  const { IBAN, SWIFT } = BillingTypes;

  const renderBillingField = () => {
    switch (billingType) {
      case SWIFT:
        return (
          <StyledInputWrapper>
            <StyledInput
              id={SWIFT}
              label="international.paymentDetails.bic_swift.label"
              placeholder="international.paymentDetails.bic_swift.placeholder"
              value={values[SWIFT].value}
              errorMessage={validationErrors[SWIFT]}
              {...sharedParams}
            />
            {bankDetailsLoading && <Loader context="button" />}
          </StyledInputWrapper>
        );
      default:
        return (
          <StyledInputWrapper>
            <StyledInput
              id={IBAN}
              label="international.paymentDetails.iban.label"
              placeholder="international.paymentDetails.iban.placeholder"
              value={values[IBAN].value}
              errorMessage={validationErrors[IBAN]}
              {...sharedParams}
            />
            {bankDetailsLoading && <Loader context="button" />}
          </StyledInputWrapper>
        );
    }
  };

  return (
    <>
      {renderBillingField()}

      {hasBankDetails && (
        <>
          <StyledInput
            id="accountNumber"
            label="international.paymentDetails.accountNumber.label"
            placeholder="international.paymentDetails.accountNumber.placeholder"
            value={values.accountNumber.value}
            errorMessage={validationErrors.accountNumber}
            onChange={onChange}
            required
            privateData
          />
          <StyledInput
            id="bankName"
            label="international.paymentDetails.bankName.label"
            placeholder="international.paymentDetails.bankName.placeholder"
            value={values.bankName.value}
            errorMessage={validationErrors.bankName}
            onChange={onChange}
            required
            privateData
          />
        </>
      )}
    </>
  );
};

export default BillingField;

const StyledInputWrapper = styled.div`
  position: relative;

  svg {
    right: 0;
    left: auto;
    margin-top: 0.6rem;
  }
`;

const StyledInput = styled(InlineTextInputField)`
  margin-top: 2.5rem;

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    margin-bottom: 4rem;

    .input-container {
      margin-bottom: 0;
    }
  }
`;
