import merge from 'lodash/merge';
import {
  createApiCallSlice,
  ON_FAILURE,
  ON_REQUEST,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import paymentsApi from 'src/app/services/api/payments';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { PaymentType } from 'src/app/utils/types';

const name = 'payments';

type FetchPaymentStateType = {
  byId: {
    [key: string]: PaymentType;
  };
  meta: {
    [key: string]: any;
  };
};

const fetchPaymentReducers = {
  [ON_REQUEST]: (state: FetchPaymentStateType, action) => {
    const { paymentId } = getJWTPayload(action.payload.token);

    state.meta[paymentId] = { loading: true, error: null };
  },
  [ON_SUCCESS]: (state: FetchPaymentStateType, { payload }) => {
    const { filesUrls, deliveryDates } = payload;

    state.byId[payload.payment.id] = merge(state.byId[payload.payment.id] || {}, payload.payment);
    state.meta[payload.payment.id] = {
      loading: false,
      error: null,
      filesUrls,
      deliveryDates,
    };
  },
  [ON_FAILURE]: (state: FetchPaymentStateType, action) => {
    const { paymentId } = getJWTPayload(action.meta.identifier.token);

    state.meta[paymentId] = { loading: false, error: action.error };
    const requestType = action.type.split('_').slice(0, -1).join('_');

    if (action.error.validationErrors) {
      const errorData = Object.keys(action.error.validationErrors).reduce((obj, key) => {
        obj[key] = action.error.validationErrors[key].replace(
          `serverErrors.${requestType}_REQUEST.`,
          ''
        );

        return obj;
      }, {});

      action.error.data = errorData;
    }
  },
};

const fetchPaymentDetailsWithToken = createApiCallSlice<any, FetchPaymentStateType>({
  name: `[${name.toUpperCase()}] FETCH_PAYMENT_DETAILS`,
  api: paymentsApi.getDetailsWithToken,
  reducers: fetchPaymentReducers,
});

const fetchEmailToVendorDetails = createApiCallSlice<any, FetchPaymentStateType>({
  name: `[${name.toUpperCase()}] FETCH_EMAIL_TO_VENDOR_DETAILS`,
  api: paymentsApi.getEmailToVendorData,
  reducers: fetchPaymentReducers,
});

const updatePaymentWithToken = createApiCallSlice<any, any>({
  name: `[${name.toUpperCase()}] UPDATE`,
  api: paymentsApi.updatePaymentByIdWithToken,
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.meta[action.payload.id] = { loading: true, error: null };
    },
    [ON_SUCCESS]: (state, action) => {
      state.meta[action.meta.identifier.id] = { loading: false, error: null };
    },
    [ON_FAILURE]: (state, action) => {
      state.meta[action.payload.id] = { loading: false, error: action.error };
    },
  },
});

const retryFailedToDeliverWithToken = createApiCallSlice<any, FetchPaymentStateType>({
  name: `[${name.toUpperCase()}] RETRY_FAILED_TO_DELIVER`,
  api: paymentsApi.retryFailedToDeliverWithToken,
  reducers: {
    [ON_REQUEST]: (state: FetchPaymentStateType, action) => {
      const { paymentId } = getJWTPayload(action.payload.token);

      state.meta[paymentId] = { loading: true, error: null };
    },
    [ON_SUCCESS]: (state: FetchPaymentStateType, { payload }) => {
      state.byId[payload.payment.id] = merge(state.byId[payload.payment.id] || {}, payload.payment);
      state.meta[payload.payment.id] = {
        loading: false,
        error: null,
      };
    },
    [ON_FAILURE]: (state: FetchPaymentStateType, action) => {
      const { paymentId } = getJWTPayload(action.meta.identifier.token);

      state.meta[paymentId] = { loading: false, error: action.error };
    },
  },
});

export {
  fetchPaymentDetailsWithToken,
  updatePaymentWithToken,
  fetchEmailToVendorDetails,
  retryFailedToDeliverWithToken,
};
