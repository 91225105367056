import { loggingApi } from 'src/app/version-2/api/loggers';
import { organizationApi } from 'src/app/version-2/api/organization.api';
import { FeeVariantsEnum } from 'src/app/version-2/model/enums/';
import {
  organizationActions,
  organizationSelectors,
} from 'src/app/version-2/modules/organization/organization.slice';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { getFeeFromCatalog } from 'src/app/version-2/utils/fee.utils';

import { call, put, select, takeEvery } from 'redux-saga/effects';

function* fetchActiveFeeFundingSourceHandler() {
  try {
    yield put(organizationActions.setFeeFundingLoading(true));
    const orgId = yield select(userSliceSelectors.selectOrgId);

    const { organizationBillingFees } = yield call(
      organizationApi.fetchActiveFeeFundingSource,
      orgId
    );

    if (organizationBillingFees?.length) {
      yield put(
        organizationActions.fetchActiveFeeFundingSourceHandlerSuccess(organizationBillingFees[0])
      );
    }
  } catch (error) {
    loggingApi.error('organizationSaga.fetchActiveFeeFundingSourceHandler(): failed', {
      error,
    });
  } finally {
    yield put(organizationActions.setFeeFundingLoading(false));
  }
}

function* fetchFreeChecksHandler(action: ReturnType<typeof organizationActions.fetchFreeChecks>) {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const res = yield call(organizationApi.fetchFreeChecks, orgId);

    const catalog = yield select(organizationSelectors.selectFeeCatalog);
    const catalogFeeNumber = getFeeFromCatalog(
      catalog as Record<string, any>,
      FeeVariantsEnum.ACH_TO_CHECK
    );

    const { isBatch } = action.payload;

    if (res?.total) {
      yield put(organizationActions.setAvailableFreeChecks(res.available));
      yield put(organizationActions.setTotalFreeChecks(res.total));

      // update free checks for batch flow
      if (isBatch) {
        yield put(batchBulkActions.setFreeChecks(res.available));
        yield put(batchBulkActions.setCheckFee(catalogFeeNumber));
      }
    }
  } catch (error) {
    loggingApi.error('organizationSaga.fetchFreeChecksHandler(): failed', { error });
  }
}

export function* watchOrganizationSaga() {
  yield takeEvery(
    organizationActions.fetchActiveFeeFundingSource,
    fetchActiveFeeFundingSourceHandler
  );
  yield takeEvery(organizationActions.fetchFreeChecks, fetchFreeChecksHandler);
}
