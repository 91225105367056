import styled from 'styled-components';

export const Purpose = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const InputWrapper = styled.div`
  margin-top: 3rem;
`;
