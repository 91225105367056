import * as React from 'react';
import styled from 'styled-components';
import { RoleTag, UserRoleTag } from 'src/app/components/user/UserRoleTag';
import { getFullName } from 'src/app/utils/user-utils';
import { devices } from 'src/app/theme/AppDevices';
import type { UserSummaryType, UserContextType } from 'src/app/utils/types';
import { BADGE_SIZE } from 'src/app/utils/consts';
import MIInitialsBadge from '../common/MIInitialsBadge';

export type UserSummaryProps = {
  user: UserSummaryType;
  userRole?: RoleTag | '';
  details: React.ReactNode | string;
};

export function UserSummary({ user, userRole, details }: UserSummaryProps) {
  const fullName = getFullName(user as UserContextType);

  return (
    <UserSummaryContainer data-testid={`user-${user.id}`}>
      <MIInitialsBadge name={fullName} size={BADGE_SIZE.LARGE} />
      <DetailsContainer>
        <UserNameContainer>
          <UserName data-testid={`user-name-${user.id}`}>{fullName}</UserName>
          {!!userRole && <UserRoleTag testId={`user-role-${user.id}`} userRole={userRole} />}
        </UserNameContainer>
        <UserDetails>{details}</UserDetails>
      </DetailsContainer>
    </UserSummaryContainer>
  );
}

const UserDetails = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  color: ${(props) => props.theme.text.color.darkGrey};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const UserSummaryContainer = styled.div`
  display: flex;
  min-width: 100%;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media ${devices.desktop}, ${devices.tablet} {
    max-width: 30rem;
  }
`;

const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 2.4rem;
  margin-right: 1rem;
  text-transform: capitalize;
  @media ${devices.desktop}, ${devices.tablet} {
    max-width: 25rem;
  }
`;

const DetailsContainer = styled.div`
  margin: 0 0 0 1.2rem;
`;
