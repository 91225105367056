import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import paymentStore from 'src/app/modules/payments/payment-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import paymentsApi from 'src/app/services/api/payments';
import { useApi } from 'src/app/hoc/useApi';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import { DELIVERY_TYPE, PAYMENT_STATUS } from 'src/app/utils/consts';
import {
  PaymentType,
  VendorType,
  VirtualCardType,
  DeliveryMethodType,
  OrganizationType,
} from 'src/app/utils/types';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { featureFlags } from '@melio/shared-web';
import { VirtualCardStatusEnum } from 'src/app/version-2/model/enums';

type Props = {
  token: string;
  virtualCardId: string;
  onInvalidToken: VoidFunction;
};

type Response = {
  virtualCardPaymentState: VirtualCardPaymentState;
  vendor?: VendorType;
  payment?: PaymentType;
  organization?: OrganizationType;
  virtualCard?: VirtualCardType;
  deliveryMethod?: DeliveryMethodType;
  isPaymentLoading?: boolean;
  issueNewCard: VoidFunction;
};

enum VirtualCardPaymentState {
  Unknown = 'UNKNOWN',
  Valid = 'VALID',
  Cancelled = 'CANCELLED',
  Processed = 'PROCESSED',
  NewDeliveryMethod = 'NEW_DELIVERY_METHOD',
  NewDeliveryMethodProcessed = 'NEW_DELIVERY_METHOD_PROCESSED',
  NewDeliveryMethodScheduled = 'NewDeliveryMethodScheduled',
  Expired = 'EXPIRED',
  Recovered = 'RECOVERED',
  RecoverySuccess = 'RECOVERY_SUCCESS',
  Notification = 'NOTIFICATION',
}

const useVirtualCardData = ({ token, virtualCardId, onInvalidToken }: Props): Response => {
  const [bpVirtualCardOptInSingleBillPayFlow] = featureFlags.useFeature(
    'bp-virtual-card-opt-in-single-bill-pay-flow'
  );
  const { paymentId } = getJWTPayload(token);

  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(payment?.vendor?.id));
  const organization: OrganizationType = useSelector(
    organizationStore.selectors.byId(payment?.organizationId)
  );
  const deliveryMethod = vendor?.deliveryMethods?.find(
    (dm) => dm.id === payment?.deliveryMethod?.id
  );
  const actualVirtualCard = payment?.lastCreatedVirtualCard;

  const { fetchPaymentDetailsWithToken, retryFailedToDeliverWithToken } =
    useStoreActions(paymentStore);

  const [virtualCard, setVirtualCard] = useState<VirtualCardType>();
  const [virtualCardPaymentState, setVirtualCardPaymentState] = useState<VirtualCardPaymentState>(
    VirtualCardPaymentState.Unknown
  );

  const [getVirtualCardWithToken] = useApi(paymentsApi.getVirtualCardData);

  const fetchVirtualCardDetailsWithToken = useCallback(async () => {
    try {
      const { virtualCard } = await getVirtualCardWithToken({
        token,
        virtualCardId,
      });

      setVirtualCard(virtualCard);
    } catch {
      onInvalidToken();
    }
  }, [token, virtualCardId, onInvalidToken, getVirtualCardWithToken]);

  useEffect(() => {
    fetchPaymentDetailsWithToken({
      token,
      action: 'readVirtualCardDetails',
    }).catch(() => {
      onInvalidToken();
    });

    fetchVirtualCardDetailsWithToken();
  }, [token, onInvalidToken, fetchPaymentDetailsWithToken, fetchVirtualCardDetailsWithToken]);

  useEffect(() => {
    if (payment && virtualCard) {
      if (payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
        if (
          payment.status === PAYMENT_STATUS.FAILED &&
          virtualCard.status === VirtualCardStatusEnum.Cancelled
        ) {
          setVirtualCardPaymentState(VirtualCardPaymentState.Cancelled);

          return;
        }

        if (
          payment.status === PAYMENT_STATUS.FAILED &&
          virtualCard.status === VirtualCardStatusEnum.Expired
        ) {
          setVirtualCardPaymentState(VirtualCardPaymentState.Expired);

          return;
        }

        if (
          payment.status === PAYMENT_STATUS.IN_PROGRESS &&
          virtualCard.status === VirtualCardStatusEnum.Expired
        ) {
          if (actualVirtualCard && actualVirtualCard.id !== virtualCard.id) {
            setVirtualCardPaymentState(VirtualCardPaymentState.Recovered);
          } else {
            setVirtualCardPaymentState(VirtualCardPaymentState.RecoverySuccess);
          }

          return;
        }

        if (
          virtualCard.status === VirtualCardStatusEnum.Posted ||
          virtualCard.status === VirtualCardStatusEnum.Authorized
        ) {
          setVirtualCardPaymentState(VirtualCardPaymentState.Processed);

          return;
        }

        setVirtualCardPaymentState(VirtualCardPaymentState.Valid);

        return;
      }

      if (payment?.deliveryMethod.isFilledByVendor && bpVirtualCardOptInSingleBillPayFlow) {
        if (
          virtualCard.status === VirtualCardStatusEnum.Posted ||
          virtualCard.status === VirtualCardStatusEnum.Authorized
        ) {
          setVirtualCardPaymentState(VirtualCardPaymentState.Processed);

          return;
        }

        setVirtualCardPaymentState(
          payment.status === PAYMENT_STATUS.COMPLETED
            ? VirtualCardPaymentState.NewDeliveryMethodProcessed
            : VirtualCardPaymentState.NewDeliveryMethodScheduled
        );

        return;
      }

      setVirtualCardPaymentState(
        payment.status === PAYMENT_STATUS.COMPLETED
          ? VirtualCardPaymentState.NewDeliveryMethodProcessed
          : VirtualCardPaymentState.NewDeliveryMethod
      );
    }
  }, [payment, virtualCard]);

  const issueNewCard = () =>
    retryFailedToDeliverWithToken({ token }).catch(() => {
      onInvalidToken();
    });

  return {
    vendor,
    payment,
    organization,
    virtualCard,
    deliveryMethod,
    virtualCardPaymentState,
    isPaymentLoading,
    issueNewCard,
  };
};

export { useVirtualCardData, VirtualCardPaymentState };
