import { FetchUboResponse } from 'src/app/version-2/api/reponses/FetchUboResponse';
import { CreateUboRequest } from 'src/app/version-2/api/requests/CreateUboRequest';
import { fetchRequest, postRequest } from 'src/app/version-2/externals';

export const organizationUltimateBeneficialOwnersApi = {
  create: ({ orgId, data }: CreateUboRequest) =>
    postRequest(`/orgs/${orgId}/organization-ultimate-beneficial-owner`, data),
  fetch: ({ orgId }) =>
    fetchRequest<FetchUboResponse>(`/orgs/${orgId}/organization-ultimate-beneficial-owner`),
};
