import api from 'src/app/services/api/bills';
import { createUpdateSlice } from 'src/app/helpers/redux/restUpdateSlice';
import { PaymentCreateFlowOriginEnum } from 'src/app/version-2/model/enums';
import { name } from './consts';

type Params = {
  orgId: string;
  id: string;
  paymentId?: number;
  amount?: number;
  isPaid: boolean;
  intuitAccountId?: string;
  actionType: 'paid' | 'unpaid';
  createOrigin?: PaymentCreateFlowOriginEnum;
};

const markAsPaidApi = (params: Params) =>
  api
    .markAsPaid({
      orgId: params.orgId,
      id: params.id,
      paymentId: params.paymentId,
      amount: params.amount,
      isPaid: params.isPaid,
      intuitAccountId: params.intuitAccountId,
      createOrigin: params.createOrigin,
    })
    .then((response) => response.object);

const markAsPaidSlice = createUpdateSlice({
  storeName: name,
  api: markAsPaidApi,
  actionName: 'MARK_AS_PAID',
});

export default markAsPaidSlice;
