import React from 'react';
import { AddOptionComponent } from 'src/app/components/common/DropDown/components/AddOptionComponent';
import { BottomButtonComponent } from 'src/app/components/common/DropDown/components/BottomButtonComponent';
import NoOptions from 'src/app/components/common/DropDown/components/NoOptions';
import MIDropDown, {
  BottomListComponentProps,
  listHeight,
  MIDropDownProps,
  optionHeight,
} from 'src/app/components/common/DropDown/MIDropDown';

type Props = {
  buttonAction?: () => void;
  buttonLabel?: string;
  createLabel?: string;
  noOptionsLabel?: string;
  placeholder?: string;
} & MIDropDownProps;

const MIEnhancedDropDown = ({ buttonAction, buttonLabel, ...props }: Props) => {
  /* eslint-disable react/jsx-indent */
  const bottomButton =
    buttonAction && buttonLabel
      ? ({ filteredOptions }: BottomListComponentProps) => (
          <BottomButtonComponent
            label={buttonLabel}
            action={buttonAction}
            shadow={filteredOptions.length > optionHeight / listHeight}
          />
        )
      : undefined;
  const firstCustomOption =
    props.allowCustom && props.createLabel
      ? (firstCustomOptionProp) => (
          <AddOptionComponent {...firstCustomOptionProp} label={props.createLabel} />
        )
      : undefined;
  /* eslint-enable react/jsx-indent */
  const emptyStateComponent = (noOptionProps) => (
    <NoOptions {...noOptionProps} noOptionsLabel={props.noOptionsLabel} />
  );

  return (
    <MIDropDown
      {...props}
      bottomListComponent={bottomButton}
      firstCustomOption={firstCustomOption}
      noOptionsComponent={emptyStateComponent}
    />
  );
};

export default MIEnhancedDropDown;
