import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  header: string;
};

const BillsGroupHeader = ({ header }: Props) => (
  <BillsGroupHeaderContainer>
    <MIFormattedText label={header} />
  </BillsGroupHeaderContainer>
);

export default BillsGroupHeader;

const BillsGroupHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.normal};
  margin-bottom: 1.2rem;
`;
