import styled from 'styled-components';
import { NotificationTypeEnum } from './NotificationCard.enum';

interface ColoredByType {
  type: NotificationTypeEnum;
  isStacked?: boolean;
}

const getBorderColorByType = (props) => {
  // For stacked notifications, we always display gray border
  if (props.isStacked) {
    return props.theme.colors.ds.gray[200];
  }

  switch (props.type) {
    case NotificationTypeEnum.INFO:
      return props.theme.colors.ds.blue[200];
    case NotificationTypeEnum.WARNING:
      return props.theme.colors.ds.yellow[200];
    case NotificationTypeEnum.ERROR:
      return props.theme.colors.ds.red[200];
    case NotificationTypeEnum.DISCOVER:
      return props.theme.colors.ds.blue[300];
    case NotificationTypeEnum.SUCCESS:
      return props.theme.colors.ds.green[300];
    default:
      return props.theme.colors.ds.gray[200];
  }
};

const getTitleColorByType = (props) => {
  if (props.type === NotificationTypeEnum.ERROR) {
    return props.theme.colors.ds.red[200];
  }
  return props.theme.colors.ds.gray[200];
};

export const Container = styled.div<ColoredByType>`
  font-family: ${({ theme }) => theme.fonts.body};
  display: flex;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.ds.white};
  padding: ${({ theme }) => theme.space.ds.lg};
  border-radius: ${({ theme }) => theme.radii.ds.md};
  border: 1px solid ${(props) => getBorderColorByType(props)};
`;

export const IconWrapper = styled.div`
  height: 100%;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  margin-left: ${({ theme }) => theme.space.ds.md};
`;

export const ActionWrapper = styled.div`
  height: 100%;
  margin-left: auto;
  cursor: pointer;
  // padding for alignment with notification type icon size
  padding: ${({ theme }) => theme.space.ds.sm};
`;

export const Title = styled.div<ColoredByType>`
  ${(props) => props.theme.textStyles.ds.body2}
  color: ${(props) => getTitleColorByType(props)};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
`;

export const Subtitle = styled.div`
  ${(props) => props.theme.textStyles.ds.body2}
  color: ${(props) => props.theme.colors.ds.gray[100]};
`;
