import { TimelineContainer, Bar, ProgressBar, StepContainer, Step, Label } from './Timeline.styles';
import { SmartIcon, Text } from '../..';

type TimelineStep = { label: string; text?: string };

interface Props {
  steps: Array<TimelineStep>;
  current: number;
}

const Timeline = ({ steps = [], current }: Props) => {
  const isChecked = (step: number) => step + 1 <= current;

  return (
    <TimelineContainer>
      {steps.length > 0 && (
        <>
          <StepContainer align="center" justify="space-between">
            {steps.slice(0, 3).map((step, index) => {
              const checked = isChecked(index);
              return (
                <Step key={step?.label} isChecked={checked} index={index}>
                  {checked && <SmartIcon type="checked" color="ds.white" size="0.8rem" />}
                  <Label direction="column" align="center">
                    <Text textStyle="ds.body3Semi" color="ds.gray.100">
                      {step?.label}
                    </Text>
                    {step?.text && (
                      <Text textStyle="ds.body3" color="ds.gray.300">
                        {step?.text}
                      </Text>
                    )}
                  </Label>
                </Step>
              );
            })}
            <Bar />
          </StepContainer>
          <ProgressBar value={current} isFull={current === steps.length} />
        </>
      )}
    </TimelineContainer>
  );
};

export { Timeline };
