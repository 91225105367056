import React from 'react';
import { FieldTitleContainer } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/common/FieldTitle/FieldTitle.styles';

interface FieldTitleProps {
  id: string;
  label: string;
  error?: boolean;
}

export const FieldTitle = ({ id, label }: FieldTitleProps) => (
  <FieldTitleContainer id={id}>{label}</FieldTitleContainer>
);
