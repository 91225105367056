const baseStyle = {
  bg: 'transparent',
  border: 'none',
  color: 'ds.gray.400',
  cursor: 'pointer',
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: { bg: 'transparent', color: 'ds.black' },
};

const sizes = {
  lg: {
    w: 'ds.xl',
    h: 'ds.xl',
    apply: 'ds.body2',
  },
  md: {
    w: 'ds.xl',
    h: 'ds.xl',
    apply: 'ds.body3',
  },
  sm: {
    w: 'ds.lg',
    h: 'ds.lg',
    apply: 'ds.body4',
  },
};

const defaultProps = {
  size: 'md',
};

const CloseButtonTheme = {
  baseStyle,
  sizes,
  defaultProps,
};

export { CloseButtonTheme };
