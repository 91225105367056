import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import analytics from 'src/app/services/analytics';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import billLocations from '../../../bill/locations';
import { Option, RequestRefundStepPropsType } from '../types';
import { BillDetailsHeader } from './BillDetailsHeader/BillDetailsHeader';
import { ReviewRefundBillInfo } from './ReviewRefundBillInfo/ReviewRefundBillInfo';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import locations from 'src/app/utils/locations';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import pagesLocations from 'src/app/pages/locations';
import { loggingApi } from 'src/app/version-2/api/loggers';
import {
  refundActions,
  refundSelectors,
} from 'src/app/version-2/pages/refund/modules/refund.slice';
import { RefundStatusEnum } from 'src/app/version-2/pages/refund/model/status.enum';

type Props = {
  selectedReason: Option;
} & Pick<RequestRefundStepPropsType, 'bill' | 'goExit' | 'payment'>;

function RequestRefundReview({ goExit, bill, payment, selectedReason }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const refundStatus = useSelector(refundSelectors.selectRefundStatus);
  const [historyPush] = useHistoryWithOrgId();
  const { components } = useSiteContext();
  const isLoading = useSelector(refundSelectors.selectRefundLoading);
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const locationState = {
    exitUrl: generatePath(pagesLocations.qbDashboard.dashboard, { orgId: payment.organizationId }),
  };

  useEffect(() => {
    analytics.page('self-serve-refund', 'review', {
      paymentId: payment.id,
      billId: bill.id,
      organizationId: payment.organizationId,
    });
    dispatch(refundActions.clearRefundStatus());
  }, [payment.id]);

  useEffect(() => {
    if (refundStatus !== RefundStatusEnum.UNKNOWN) {
      history.push(
        generatePath(billLocations.successScheduledRefund, {
          orgId: payment.organizationId,
          billId: bill.id,
          paymentId: payment.id,
        })
      );
    }
  }, [refundStatus]);

  const handleRefundSubmit = useCallback(async () => {
    try {
      const actionProps = {
        paymentId: payment.id,
        reason: selectedReason?.id,
      };

      const trackProps = {
        ...actionProps,
        billId: bill.id,
      };

      loggingApi.warn('RequestRefundReview.handleRefundSubmit(): Refund submit', trackProps);
      dispatch(refundActions.createRefundPayment(actionProps));

      analytics.track('bills', 'review-get-refund-cta-click', {
        organizationId: payment.organizationId,
        paymentId: payment.id,
        billId: bill.id,
      });
      analytics.trackAction('button-click', trackProps);
    } catch (error: any) {
      console.warn(error);
    }
  }, [payment.id, bill.id, selectedReason?.id]);

  const onRetryPayment = () => {
    loggingApi.info('RequestRefundReview.onRetryPayment(): Retry payment link clicked', {
      orgId: payment.organizationId,
      billId: bill.id,
      paymentId: payment.id,
    });
    analytics.track('bills', 'review-retry-payment-cta-click');

    historyPush({
      path: locations.Bills.pay.edit[isRefundFlowFeature ? 'deliveryMethodAch' : 'funding'].url({
        id: bill.id,
        paymentId: payment.id,
      }),
      state: locationState,
    });
  };

  return (
    <components.StepLayout
      goExit={goExit}
      qboFooter={<QBOFooterContainer />}
      hideHeader
      onSubmit={handleRefundSubmit}
      nextLabel={`bills.pay.requestRefund.review.${
        isRefundFlowFeature ? 'getRefundButton' : 'refundButton'
      }`}
      onRetry={onRetryPayment}
      isLoading={!!isLoading}
    >
      <BillDetailsHeader />
      <ReviewRefundBillInfo bill={bill} payment={payment} selectedReason={selectedReason} />
    </components.StepLayout>
  );
}

export default RequestRefundReview;
