import React, { useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import analytics from 'src/app/services/analytics';
import { qboRefuseConnect } from 'src/app/utils/external-events';
import { CONNECT_ERROR } from 'src/app/utils/consts';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';
import intuit from 'src/app/utils/intuit';
import illustration from 'src/app/images/qbo/failed-to-load-icon.svg';
import { loggingApi } from 'src/app/version-2/api/loggers';
import GeneralFlowErrorContainer from './GeneralFlowErrorContainer';

type Props = {
  query: {
    error: string;
  };
};

const eventPage = 'qbo-error-connect';

function ErrorConnectPageContainer({ query }: Props) {
  const site = useSiteContext();
  const errorType = get(query, 'error');

  useEffect(() => {
    intuit.endLoadingWrapper();
    intuit.hideFTULoadingWrapper();
  }, []);

  const goExit = () => {
    analytics.track(eventPage, 'close-integration');
    qboRefuseConnect();
  };

  const getErrorContent = () => {
    switch (errorType) {
      case CONNECT_ERROR.ORG_NOT_EXIST:
        return {
          title: 'connect.error.orgNotFound.title',
          subtitle: 'connect.error.orgNotFound.subtitle',
          buttonLabel: 'connect.error.orgNotFound.buttonLabel',
        };

      case CONNECT_ERROR.USER_NOT_ACTIVE:
        return {
          title: 'connect.error.userNotActive.title',
          subtitle: '',
          buttonLabel: 'connect.error.userNotActive.buttonLabel',
        };

      case CONNECT_ERROR.USER_NOT_EXIST:
        return {
          title: 'connect.error.userNotExist.title',
          subtitle: '',
          buttonLabel: 'connect.error.userNotExist.buttonLabel',
        };

      case CONNECT_ERROR.NO_USER_PERMISSION:
        return {
          title: 'connect.error.userPermission.title',
          subtitle: 'connect.error.userPermission.subtitle',
          buttonLabel: 'connect.error.userPermission.buttonLabel',
          values: {
            chatWithUs: (
              <Link href={`mailto:${site.config.support.email}`}>
                <MIFormattedText label="connect.error.userPermission.chatWithUs" />
              </Link>
            ),
          },
        };

      default:
        loggingApi.error('ConnectErrorPageContainer.getErrorContent(): event', {
          errorType,
          referrer: document?.referrer,
        });

        return {
          title: 'connect.error.general.title',
          subtitle: '',
          buttonLabel: 'connect.error.general.buttonLabel',
        };
        break;
    }
  };

  const { title, subtitle, buttonLabel, values } = getErrorContent();

  return (
    <GeneralFlowErrorContainer
      illustration={illustration}
      title={title}
      subtitle={subtitle}
      buttonAction={goExit}
      buttonLabel={buttonLabel}
      subtitleValues={values}
    />
  );
}

const Link = styled.a`
  text-decoration: underline;
  text-transform: lowercase;
  cursor: pointer;
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default ErrorConnectPageContainer;
