import { Link } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const ExperimentLandingPageContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.ds.gray[700]};
  font-size: ${({ theme }) => theme.textStyles.ds.body4};
`;

export const SmartIconWrapper = styled.div`
  height: 2.4rem;
  padding: 0 2rem;
`;

export const Bold = styled.span`
  display: inline-flex;
  font-size: ${({ theme }) => theme.textStyles.ds.body4Medium.fontSize};
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
`;

export const ExperimentLandingPageLink = styled(Link)`
  padding: 0 1rem;
`;
