import { Address } from 'src/app/version-2/model/dtos/address';

const getFormattedAddress = ({ addressLine1, addressLine2, city, state, zipCode }: Address) => {
  const addressDetails = [addressLine1, addressLine2, city, state, zipCode].filter(Boolean);

  return addressDetails.join(', ');
};

const getFormattedCardNumbers = (cardNumber?: string) => {
  if (!cardNumber) return '';

  return cardNumber.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
};

export { getFormattedAddress, getFormattedCardNumbers };
