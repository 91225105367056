import React from 'react';
import styled from 'styled-components';
import { RecordOf } from 'immutable';
import SelectItemCard from 'src/app/components/common/SelectItemCard';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { AreaLoader } from '@melio/billpay-design-system';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { BillType, DeliveryMethodType, OptionalDeliveryMethodsType } from 'src/app/utils/types';
import { QBRDeliveryMethodListItem } from 'src/app/pages/bill/pay/components/QBRDeliveryMethodListItem';
import unilateralNotSelectedImg from 'src/app/images/qbr/unilateral-not-selected.svg';
import QBRLayoutPage from 'src/app/components/layout/QBRLayoutPage';

type Props = {
  onNext: () => void;
  goExit: () => void;
  onPrev: () => void;
  goAddVirtualDeliveryMethod: () => void;
  companyName: string;
  amount: string;
  isLoading?: boolean;
  deliveryMethodOptions: RecordOf<DeliveryMethodType>[];
  selectedDeliveryMethod: string | undefined;
  selectedFundingSource: FundingSource | undefined;
  onDeliveryMethodChange: (value: OptionalDeliveryMethodsType) => void;
  bill: RecordOf<BillType>;
  headerLabel: string;
  relativeStep?: number;
  title: string;
  titleValues?: Record<string, any>;
  subtitle?: string;
};

export const QBRPayBillSelectDeliveryMethodPage = ({
  onNext,
  companyName,
  isLoading = false,
  deliveryMethodOptions,
  selectedDeliveryMethod,
  onDeliveryMethodChange,
  goExit,
  onPrev,
  amount,
  bill,
  goAddVirtualDeliveryMethod,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  subtitle,
}: Props) => {
  const site = useSiteContext();

  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={amount} />
      </strong>
    ),
    companyName: <strong>{companyName}</strong>,
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  return (
    <QBRLayoutPage
      headerLabel={headerLabel}
      headerLabelValues={headerLabelValues}
      title={title}
      titleValues={titleValues}
      goExit={goExit}
      onPrev={onPrev}
      relativeStep={relativeStep}
      onNext={onNext}
      isLoading={isLoading}
      innerSize={50}
      isNextDisabled={!selectedDeliveryMethod}
      isHideActions
      subtitle={subtitle}
    >
      <DeliveryMethodsList>
        <VirtualDeliveryMethodContainer>
          <SelectItemCard
            testId="delivery-method-virtual"
            onClick={goAddVirtualDeliveryMethod}
            label="bills.pay.deliveryMethods.unilateralLink"
            description="bills.pay.deliveryMethods.unilateralHint"
            imgSource={unilateralNotSelectedImg}
          />
          <site.components.WizardElements.WizardOrLine />
        </VirtualDeliveryMethodContainer>
        {deliveryMethodOptions.map((option) => (
          <QBRDeliveryMethodListItem
            key={option.deliveryType}
            deliveryMethod={option}
            companyName={companyName}
            isSelected={selectedDeliveryMethod === option.deliveryType}
            onItemClick={() => onDeliveryMethodChange(option.deliveryType)}
          />
        ))}
      </DeliveryMethodsList>
    </QBRLayoutPage>
  );
};

const DeliveryMethodsList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding 1.6rem 0;
`;

const VirtualDeliveryMethodContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`;
