import { restApi, Options } from 'src/app/version-2/api/rest/rest.api';

export function setAuthToken(authToken: string) {
  restApi.authToken = authToken;
}
export function fetchRequest<T = any>(
  url: string,
  params: Record<string, any> | null = {},
  options: Options = { isBypassThrowError: false }
): Promise<T> {
  return restApi.fetch<T>(url, params as Record<string, any>, options) as unknown as Promise<T>;
}

export function postRequest<T = any>(
  url: string,
  params: Record<string, any> = {},
  conf: Record<string, any> = {},
  options: Options = { isBypassThrowError: false }
): Promise<T> {
  return restApi.post<T>(url, params, options) as unknown as Promise<T>;
}

export function putRequest<T = any>(url: string, params: Record<string, any> = {}): Promise<T> {
  return restApi.put<T>(url, params) as unknown as Promise<T>;
}

export function deleteRequest<T = any>(url: string, params: Record<string, any> = {}): Promise<T> {
  return restApi.delete<T>(url, params) as unknown as Promise<T>;
}
