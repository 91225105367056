import styled from 'styled-components';

export const BillInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

export const ReasonSeparator = styled.div`
  height: 0.1rem;
  background-color: ${({ theme }) => theme.colors.ds.gray[700]};
  width: 45rem;
  margin: 0 auto;
`;
