export const baseStyle = {
  width: '100%',
  borderRadius: 'lg',
  backgroundColor: 'ds.white',
  boxShadow: '0px 2px 10px #D4D7DC',
  cursor: 'pointer',
  flexDirection: 'column',
  maxWidth: '45rem',
  pt: 'ds.lg',
  pb: '2.6rem',
  px: 'ds.xl',
  transitionProperty: 'box-shadow',
  transitionDuration: '300ms',
  fontFamily: 'body',

  _hover: {
    boxShadow: '0px 2px 10px #C0C4CB',
  },
};

export const MultiLineSelectBoxTheme = {
  baseStyle,
};
