import { postRequest, fetchRequest } from './api';

export default {
  createOrganizationUltimateBeneficialOwners(orgId, data) {
    const url = `/orgs/${orgId}/organization-ultimate-beneficial-owner`;

    return postRequest(url, data);
  },
  getOrganizationUltimateBeneficialOwners(orgId) {
    const url = `/orgs/${orgId}/organization-ultimate-beneficial-owner`;

    return fetchRequest(url);
  },
};
