import { VendorType } from 'src/app/utils/types';
import some from 'lodash/some';
import { CONSTS } from 'src/app/utils/consts';

export const isRppsVendor = (vendor: VendorType): boolean =>
  some(vendor?.deliveryMethods, (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.RPPS);

export const getVendorBillerId = (vendor: VendorType): string | undefined =>
  vendor.deliveryMethods.find((dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.RPPS)?.rppsAccount
    ?.billerId;
