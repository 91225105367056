import find from 'lodash/find';
import filter from 'lodash/filter';
import { IntuitBankAccountResponseType } from 'src/app/utils/types';
import { getDefaultDeliveryMethod } from 'src/app/redux/utils';
import { CONSTS, DELIVERY_TYPE } from 'src/app/utils/consts';
import { isBankAccountBlocked } from 'src/app/utils/funding-sources';
import { isCardExpired } from 'src/app/utils/card';
import justPayLocations from 'src/app/pages/just-pay/justPayLocations';
import { generatePath } from 'react-router-dom';
import { getRedirectUrlByDeliveryMethodStatusProps } from 'src/app/pages/just-pay/justPayTypes';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourceOrigins } from 'src/app/version-2/model/enums';

const ALLOWED_DELIVERY_TYPES = [DELIVERY_TYPE.VIRTUAL, DELIVERY_TYPE.ACH, DELIVERY_TYPE.CHECK];

export const mapCategoriesToOptions = (categories) =>
  categories.map((category: IntuitBankAccountResponseType) => ({
    id: category.Id,
    label: category.Name,
    value: category.Name,
  }));

export const getJustPayDefaultDeliveryMethod = ({ deliveryMethods, deliveryMethodType }) => {
  const allowedDeliveryMethods = deliveryMethods.filter((dm) =>
    ALLOWED_DELIVERY_TYPES.includes(dm.deliveryType)
  );

  if (deliveryMethodType) {
    const deliveryMethod = allowedDeliveryMethods.find(
      (dm) => dm.deliveryType === deliveryMethodType
    );

    return { id: deliveryMethod?.id, deliveryType: deliveryMethodType };
  }

  return getDefaultDeliveryMethod(allowedDeliveryMethods);
};

export function getDefaultFundingSource(
  fundingSources: FundingSource[],
  fundingSourceId: number
): FundingSource | null | undefined {
  const lastUsedFundingSource = find(fundingSources, (fs) => fs.id === fundingSourceId) ?? null;

  const qbCashFundingSource = find(
    fundingSources,
    (fs) => fs.origin === FundingSourceOrigins.QBCASH
  );

  if (isBankAccountBlocked(lastUsedFundingSource ?? qbCashFundingSource)) {
    return null;
  }

  return lastUsedFundingSource ?? qbCashFundingSource;
}

export const getFilteredFundingSources = (fundingSources): FundingSource[] | any =>
  filter(
    fundingSources,
    (fs) =>
      !(
        fs.fundingType === CONSTS.DELIVERY_TYPE.CARD &&
        (!fs.isVerified || isCardExpired(fs.cardAccount))
      )
  );

export const getRedirectUrlByDeliveryMethodStatus = ({
  orgId,
  selectedVendor,
  newVendorId,
  deliveryMethodType,
}: getRedirectUrlByDeliveryMethodStatusProps) => {
  let url;
  const redirectOptions = {
    orgId,
    vendorId: selectedVendor?.id ?? newVendorId,
  };

  const deliveryMethodUrlMap = {
    [CONSTS.DELIVERY_TYPE.ACH]: generatePath(
      justPayLocations.edit.achDeliveryMethodPage,
      redirectOptions
    ),
    [CONSTS.DELIVERY_TYPE.CHECK]: generatePath(
      justPayLocations.edit.checkDeliveryMethodPage,
      redirectOptions
    ),
  };

  if (deliveryMethodType) {
    url = deliveryMethodUrlMap[deliveryMethodType as string];
  } else {
    url = generatePath(justPayLocations.create.selectDeliveryMethod, redirectOptions);
  }

  return url;
};
