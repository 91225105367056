import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Tooltip, Box } from '@melio/billpay-design-system';

import { UNPAID_BILL_DASHBOARD_STASUSES } from 'src/app/pages/qb-dashboard/consts';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { useForm } from 'src/app/ui/form';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import { hasInternationalDeliveryMethod } from 'src/app/utils/international';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import billsStore from 'src/app/modules/bills/bills-store';
import { isPartialAmountInvalid } from 'src/app/pages/qb-dashboard/utils';
import { usePartialPaymentsQualification } from 'src/app/pages/bill/hooks/usePartialPaymentsBillQualification';
import { QBOTableCurrencyInput } from '../../QBOTableCurrencyInput/QBOTableCurrencyInput';
import { QBOPaymentAmountCell } from './QBOPaymentAmountCell';
import { BillType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

export const QBOAmountInputCell = ({ item }: Props) => {
  const intl = useIntl();
  const { billId, id, metadata, vendor } = item;

  const bill: BillType = useSelector(billsStore.selectors.byId(billId));
  const dashboardListActions = useStoreActions(qbDashboardListItemsStore);
  const model = useMemo(() => ({ partialAmount: '' }), []);
  const [hadValueBeforeFocus, setHadValueBeforeFocus] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [partialAmountVM, { submit }] = useForm(model, {
    submit: async ({ partialAmount }) => {
      handleSubmit(partialAmount);
    },
  });
  const tooltipVisible: boolean = useSelector(
    qbDashboardListItemsStore.selectors.partialPaymentsAmounts.invalidTooltipVisibleById(billId)
  );

  const isInvalidPartialAmount = isPartialAmountInvalid({
    balance: bill?.balance as number,
    amount: Number(partialAmountVM.partialAmount?.value),
  });

  const isQualifiedForPartialPayment = usePartialPaymentsQualification(bill?.qboSyncVersion);
  const hasInternational = hasInternationalDeliveryMethod(vendor?.deliveryMethods);

  useEffect(() => {
    setIsTooltipOpen(isInvalidPartialAmount);
  }, [isInvalidPartialAmount]);

  useEffect(() => {
    setIsTooltipOpen(tooltipVisible);
  }, [tooltipVisible]);

  useEffect(() => {
    const amountToSet =
      partialAmountVM.partialAmount?.value && partialAmountVM.partialAmount?.value !== '0'
        ? Number(partialAmountVM.partialAmount?.value)
        : null;

    dashboardListActions.partialPaymentsAmounts.setAmount({
      billId,
      amount: amountToSet,
    });
  }, [partialAmountVM.partialAmount?.value, dashboardListActions, billId]);

  const handleSubmit = (partialAmount?: string) => {
    const isValuePresent = !!partialAmount && partialAmount !== '0';
    const shouldSelectCheckbox = isValuePresent;
    const shouldUnselectCheckbox = hadValueBeforeFocus && !isValuePresent;

    if (shouldSelectCheckbox) {
      dashboardListActions.qbDashboardSelection.selectById(id);
    } else if (shouldUnselectCheckbox) {
      dashboardListActions.qbDashboardSelection.unselectById(id);
    }

    setHadValueBeforeFocus(!!partialAmount);

    setIsTooltipOpen(false);

    if (partialAmount === '0') {
      partialAmountVM.partialAmount.onChange({ value: '' });
    }
  };

  if (metadata.statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.FAILED) {
    return <QBOPaymentAmountCell item={item} />;
  }

  const onBlur = () => {
    handleSubmit(partialAmountVM.partialAmount?.value);
  };

  const onFocus = () => {
    setHadValueBeforeFocus(!!partialAmountVM.partialAmount?.value);
  };

  const stopPropagation = (event: React.MouseEvent): void => event.stopPropagation();

  const balance = bill?.balance;
  const placeholder = intl.formatNumber(balance as number, {
    style: 'currency',
    currency: 'USD',
  });

  const onMouseEnter = () => {
    if (isInvalidPartialAmount) {
      setIsTooltipOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (isInvalidPartialAmount) {
      setIsTooltipOpen(false);
    }
  };

  if (hasInternational) {
    return <MIFormattedCurrency value={Number(bill?.totalAmount)} currency="$" />;
  }

  if (!isQualifiedForPartialPayment) {
    return (
      <Tooltip
        label={<MIFormattedText label="paymentDashboard.tooltips.notEligibleForPartialPayment" />}
      >
        <span>
          <MIFormattedCurrency value={Number(bill?.totalAmount)} currency="$" />
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      isOpen={isTooltipOpen}
      label={<MIFormattedText label="paymentDashboard.columns.payment.amountTooBigErrorTooltip" />}
    >
      <form onSubmit={submit}>
        <Box
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
          onClick={stopPropagation}
          className={FULL_STORY_MASK_RULE_CLASS}
        >
          <QBOTableCurrencyInput
            id={`partial-amount-${billId}`}
            isInvalid={isInvalidPartialAmount}
            model={partialAmountVM.partialAmount}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </Box>
      </form>
    </Tooltip>
  );
};
