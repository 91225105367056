import { PayBillFundingSourcePageContainer } from 'src/app/pages/bill/pay/PayBillFundingSourcePageContainer';
import {
  EXPERIMENT_TYPE,
  EXPERIMENT_VARIANTS,
  FUNDING_SOURCE_EXPERIMENT_VARIANTS,
  FUNDING_SOURCE_VARIANT_COMPONENTS,
} from 'src/app/version-2/model/constants';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourcePageVariantsEnum } from 'src/app/version-2/model/enums';
import { FundingSourcePage } from 'src/app/version-2/pages/funding-source';
import {
  checkEmptyFundingSources,
  getQBCashForAccount,
} from 'src/app/version-2/utils/paymentMethods.utils';

export const getLpExperimentVariant = (
  variant: string,
  fundingSources: FundingSource[],
  hasPayments = false
) =>
  !checkEmptyFundingSources(fundingSources) || hasPayments
    ? FUNDING_SOURCE_EXPERIMENT_VARIANTS.CONTROL
    : FUNDING_SOURCE_EXPERIMENT_VARIANTS[variant];

export const getLpExperimentVariantComponent = (
  variant,
  fundingSources: FundingSource[],
  hasPayments = false
) =>
  !checkEmptyFundingSources(fundingSources) || hasPayments
    ? FUNDING_SOURCE_VARIANT_COMPONENTS.CONTROL
    : FUNDING_SOURCE_VARIANT_COMPONENTS[variant];

export const getLpExperimentVariantComponentName = (
  isFundingSourceExperiment: boolean,
  variant: string,
  fundingSources: FundingSource[],
  hasPayments = false
) =>
  isFundingSourceExperiment && !hasPayments && checkEmptyFundingSources(fundingSources)
    ? EXPERIMENT_VARIANTS[variant]
    : EXPERIMENT_VARIANTS.CONTROL;

export const getLpExperimentType = (
  isFundingSourceExperiment: boolean,
  variant: string,
  fundingSources: FundingSource[],
  hasPayments = false
) =>
  isFundingSourceExperiment &&
  variant !== FundingSourcePageVariantsEnum.CONTROL &&
  !hasPayments &&
  checkEmptyFundingSources(fundingSources) &&
  getQBCashForAccount(fundingSources)
    ? EXPERIMENT_TYPE.TYPE
    : EXPERIMENT_TYPE.SELECT;

export const shouldSendEvent = (
  isFundingSourceExperiment: boolean,
  fundingSources: FundingSource[],
  hasPayments = false
) => isFundingSourceExperiment && checkEmptyFundingSources(fundingSources) && !hasPayments;

export const getFsExperimentPage = (
  isFundingSourceExperiment: boolean,
  variant: string,
  fundingSources: FundingSource[],
  hasPayments = false
) =>
  isFundingSourceExperiment &&
  variant !== FundingSourcePageVariantsEnum.CONTROL &&
  !hasPayments &&
  checkEmptyFundingSources(fundingSources)
    ? FundingSourcePage
    : PayBillFundingSourcePageContainer;
