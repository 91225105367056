import { Text } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const Container = styled.div<{ $isDebit?: boolean }>`
  display: grid;
  justify-items: center;
  background: ${({ theme }) => theme.colors.ds.white};
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  grid-template-rows: ${({ $isDebit }) => ($isDebit ? 6.1 : 5.4)}rem 5rem 3.7rem;
`;

export const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  font-size: ${({ theme }) => theme.textStyles.ds.body1.fontSize};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  margin-bottom: 0;
  text-align: center;

  p {
    padding-top: 0.5rem;
    font-weight: ${({ theme }) => theme.fontWeights.ds.regular};
  }
`;

export const Fee = styled(Text)`
  margin: 0.5rem 0 0;
  font-size: ${({ theme }) => theme.textStyles.ds.body3.fontSize};
`;

export const Comment = styled(Text)`
  font-size: 0.9rem;
`;

export const getIconSize = (isDebit: boolean) => (isDebit ? 5.4 : 4);
