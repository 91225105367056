import { theme } from '@melio/billpay-design-system';

export const BASIS_THEORY_ELEMENT_COMMON_STYLE = {
  base: {
    ...theme.textStyles.ds.body1Semi,
    fontSize: '16px',
    backgroundColor: 'transparent',
    fontFamily: theme.fonts.body1,
    color: theme.colors.ds.gray[100],
    '::placeholder': {
      color: theme.colors.ds.gray[500],
    },
  },
  invalid: {
    color: theme.colors.ds.gray[100],
  },
};
