const parts = ['item', 'list', 'button'];

const baseStyleList = ({ autoWidth }) => {
  const width = autoWidth ? 'minWidth' : 'width';

  return {
    color: 'ds.gray.100',
    zIndex: 1,
    bg: 'ds.white',
    py: 'ds.sm',
    borderRadius: 'ds.md',
    boxShadow: 'ds.sm',
    [width]: '20rem',
    border: '1px',
    borderColor: 'ds.gray.600',
    fontFamily: 'body',
  };
};
const baseStyleItem = {
  textStyle: 'ds.body2',
  border: 0,
  bg: 'ds.white',
  px: 'ds.lg',
  cursor: 'pointer',
  h: 'ds.2xl',

  _disabled: {
    background: 'rgba(250, 250, 250, 1)',
    color: 'rgba(194, 194, 195, 1)',
    cursor: 'not-allowed',
  },

  _hover: {
    bg: 'ds.gray.800',
  },
};

const baseStyle = (theme) => ({
  list: baseStyleList(theme),
  item: baseStyleItem,
});

const sizes = {
  md: {
    item: {
      textStyle: 'ds.body2',
    },
  },
  sm: {
    item: {
      textStyle: 'ds.body3',
    },
  },
};

const defaultProps = {
  size: 'md',
};

export const MenuTheme = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
