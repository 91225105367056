import React from 'react';
import { useIntl } from 'react-intl';
import { SmartIcon } from '@melio/billpay-design-system';
import {
  Content,
  SecurityComplianceMessageContainer,
  SmartIconWrapper,
} from 'src/app/version-2/pages/add-card-account/components/SecurityComplianceMessage/SecurityComplianceMessage.styles';

export const SecurityComplianceMessage = () => {
  const { formatMessage } = useIntl();

  return (
    <SecurityComplianceMessageContainer>
      <SmartIconWrapper>
        <SmartIcon type="lock" size="1.8rem" />
      </SmartIconWrapper>
      <Content>
        {formatMessage({
          id: 'linkCard.securityComplianceMessage',
        })}
      </Content>
    </SecurityComplianceMessageContainer>
  );
};
