import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import pickBy from 'lodash/pickBy';
import { Box } from '@melio/billpay-design-system';
import MIButton from 'src/app/components/common/MIButton';
import analytics from 'src/app/services/analytics';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import vendorsLocations from 'src/app/pages/vendor/locations';
import { analyticsApi } from 'src/app/services/analytics/guest-analytics-api';
import { getJWTPayload } from 'src/app/helpers/jwt';

import deliveryMethodsStore, {
  getDeliveryMethodActions,
} from 'src/app/modules/delivery-methods/delivery-methods-store';
import { BUTTON_SIZE, BUTTON_VARIANT, NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { getIsVendorPaymentDetailsFlow } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import { pushNotification } from 'src/app/services/notifications';
import {
  EXPEDITE_OPTIONS,
  TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH,
} from 'src/app/pages/vendor/vendor-payment-tracking/consts';
import { DeliveryMethodType, PaymentType, ReferrerType } from 'src/app/utils/types';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from '../../../vendor-payment-tracking/locations';
import { ButtonsGroupComponent } from '../ButtonsGroup';
import optinDictionary from './optinDictionary';

type PropsType = {
  isVirtualPayment: boolean;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  deliveryMethodId: string;
  referrer: string;
  token: string;
  isMobile: boolean;
};

const VirtualCardOptInActionButtons = ({
  isVirtualPayment,
  payment,
  deliveryMethod,
  deliveryMethodId,
  referrer,
  token,
  isMobile,
}: PropsType) => {
  const history = useHistory();
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const [isProcessing, setIsProcessing] = useState(false);
  const { replaceDeliveryMethodWithToken } = getDeliveryMethodActions(useDispatch());

  const handleTrackConfirmPayment = useCallback(() => {
    analytics.trackAction('confirm-virtual-card-payment', {
      vendorId: payment?.vendorId,
      paymentId: payment?.id,
      billId: payment?.billId,
      deliveryType: deliveryMethod?.deliveryType,
      optInFlow: referrer,
    });
  }, [payment, deliveryMethod, referrer]);

  const multilateralHandler = useCallback(async () => {
    setIsProcessing(true);

    await replaceDeliveryMethodWithToken({
      token,
      deliveryMethod: {
        ...deliveryMethod,
        id: payment?.deliveryMethodId || deliveryMethodId,
        deliveryType: 'virtual-card',
        virtualCardAccount: { accountEmail: payment?.vendor?.contactEmail },
      },
      orgId: payment?.organization?.id,
      vendorId: payment?.vendorId,
    });

    handleTrackConfirmPayment();

    history.push(generatePath(vendorsLocations.selectExpedited.index, { token }));
  }, [
    replaceDeliveryMethodWithToken,
    token,
    deliveryMethod,
    payment,
    deliveryMethodId,
    handleTrackConfirmPayment,
  ]);

  const changeDeliveryMethod = useCallback(() => {
    if (isVirtualPayment) {
      history.push(generatePath(vendorsLocations.unilateral.addMethod, { token }));
    } else {
      history.goBack();
    }
  }, [token, isVirtualPayment]);

  const unilateralHandler = useCallback(async () => {
    setIsProcessing(true);

    await deliveryMethodActions.replaceVirtualDeliveryMethod(
      pickBy({
        token,
        deliveryMethod: {
          ...deliveryMethod,
          id: payment?.deliveryMethodId || deliveryMethodId,
        },
        paymentId: payment?.id || null,
      })
    );

    handleTrackConfirmPayment();

    const { unilateralRequestId } = getJWTPayload(token);

    analyticsApi.track({
      token,
      eventName: 'RequestDM-VendorCompleted',
      properties: {
        flow: payment ? 'Unilateral with payment' : 'Unilateral without payment',
        vendorId: payment?.vendor?.id,
        paymentId: payment?.id,
        vendorEmail: payment?.vendor?.contactEmail,
        payorOrgId: payment?.organization?.id,
        unilateralRequestId,
      },
    });

    if (getIsVendorPaymentDetailsFlow(token)) {
      history.push(generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token }));

      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.expedite.${EXPEDITE_OPTIONS.TO_VIRTUAL_CARD}`,
      });
    } else {
      history.push(generatePath(vendorsLocations.unilateral.success, { token }));
    }
  }, [token, deliveryMethod, payment, deliveryMethodId, handleTrackConfirmPayment]);

  const handleConfirmAndAcceptMap = useMemo(
    () => ({
      [ReferrerType.UNILATERAL]: unilateralHandler,
      [ReferrerType.SHIFT_ACH_TO_VC]: multilateralHandler,
      [ReferrerType.SHIFT_CHECK_TO_VC]: multilateralHandler,
    }),
    [unilateralHandler, multilateralHandler]
  );

  return (
    <>
      {isMobile ? (
        <>
          <ButtonsGroupComponent
            primaryLabel={optinDictionary.actionButtons.primary(isVirtualPayment)}
            secondaryLabel={optinDictionary.actionButtons.secondary(isVirtualPayment)}
            isProcessing={isProcessing}
            onPrimaryClick={handleConfirmAndAcceptMap[referrer]}
            onSecondaryClick={changeDeliveryMethod}
          />
          <Box textStyle="body4" color="black" textAlign="center">
            <MIFormattedText
              label={optinDictionary.info}
              values={{
                organizationName: payment?.organization.companyName,
              }}
            />
          </Box>
        </>
      ) : (
        <>
          <MIButton
            variant={BUTTON_VARIANT.PRIMARY}
            label={optinDictionary.actionButtons.primary(isVirtualPayment)}
            size={BUTTON_SIZE[isMobile ? 'SMALL' : 'NORMAL']}
            fullWidth
            isProcessing={isProcessing}
            onClick={handleConfirmAndAcceptMap[referrer]}
          />
          <Box textStyle="body4" textAlign="center" color="black">
            <MIFormattedText
              label="vendors.addDeliveryMethodByLink.virtualCardPosTerminal.notice"
              values={{ payor: payment?.organization?.companyName }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default VirtualCardOptInActionButtons;
