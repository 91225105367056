import { CURRENCY_SIGN as CurrencySign } from 'src/app/utils/consts';
import { CURRENCY_USD } from 'src/app/version-2/model/constants';

enum CurrencyInputType {
  INPUT_FIELD = 'InputFiled',
  CENTER_ALIGNED = 'CenterAligned',
}

const INTL_CONFIGS = {
  [CURRENCY_USD]: {
    locale: 'en-US',
    currency: CURRENCY_USD,
  },
};

const MANUAL_CONFIGS = {
  [CURRENCY_USD]: {
    groupSeparator: ',',
    decimalSeparator: '.',
  },
};

const DEFAULT_CURRENCY = CURRENCY_USD;
const DEFAULT_CURRENCY_SIGN = CurrencySign.USD;
const DEFAULT_INTL_CONFIG = INTL_CONFIGS[DEFAULT_CURRENCY];
const DEFAULT_MANUAL_CONFIG = MANUAL_CONFIGS[DEFAULT_CURRENCY];

export {
  INTL_CONFIGS,
  MANUAL_CONFIGS,
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_SIGN,
  DEFAULT_INTL_CONFIG,
  DEFAULT_MANUAL_CONFIG,
  CurrencySign,
  CurrencyInputType,
};
