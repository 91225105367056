import React from 'react';
import { RecordOf } from 'immutable';
import { FAILED_PAYMENT_TYPE, DELIVERY_TYPE, PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { BillType, DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import { isFirstPaymentLate } from 'src/app/utils/bills';
import { PaymentInfoHint } from 'src/app/components/layout/PaymentActivityElements';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  payment: RecordOf<PaymentType>;
  bill: RecordOf<BillType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: FundingSource;
};

const DeliveryDateInfoHint = ({ bill, payment, deliveryMethod, fundingSource }: Props) => {
  const isRecurringBill = !!bill.recurringBill;
  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  const paymentIsFailedToDeliver =
    payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;
  const date = <MIFormattedDate year={undefined} date={payment.scheduledDate} />;
  let hint;
  let hintValues = {};

  if (isMicrodeposit && isApprovalRequired) {
    hint = 'payBillPaymentActivity.deliveryDate.unverifiedBankAccountApprovalRequiredHint';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);

    hintValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isUnilateral && paymentIsFailedToDeliver) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralFailedToDeliver';
    hintValues = {
      date: <MIFormattedDate year={undefined} date={payment.deliveryEta} />,
    };
  } else if (isApprovalRequired && isUnilateral) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralApprovalRequiredHint';
    hintValues = {
      date,
    };
  } else if (isMicrodeposit) {
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);

    hint = 'payBillPaymentActivity.deliveryDate.unverifiedBankAccountHint';
    hintValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isApprovalRequired) {
    hint = 'payBillPaymentActivity.deliveryDate.approvalRequiredHint';
    hintValues = {
      date,
    };
  } else if (isUnilateral) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralHint';
    hintValues = {
      date,
    };
  } else if (isRecurringBill) {
    const firstPaymentLate = isFirstPaymentLate({ bill, payment });

    if (firstPaymentLate) {
      hint = 'payBillPaymentActivity.deliveryDate.recurringHint';
    }
  } else if (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH) {
    hint = 'payBillPaymentActivity.deliveryDate.achHint';
  }

  if (hint) {
    return (
      <PaymentInfoHint>
        <MIFormattedText label={hint} values={hintValues} />
      </PaymentInfoHint>
    );
  }

  return null;
};

export default DeliveryDateInfoHint;
