import React, { ReactElement } from 'react';
import findKey from 'lodash/findKey';
import { ManualAddressType } from 'src/app/components/common/ManualAddress/ManualAddressOptionsContainer';
import { AddressType, LegalAddressType } from 'src/app/utils/types';
import styled from 'styled-components';
import { whitePagesAddressKeys } from 'src/app/utils/address';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { ManualAddressEnum } from 'src/app/version-2/model/enums';
import MIRadioGroup from '../form/MIRadioGroup';
import { MIFieldOrEmpty } from '../../utils/formatting';
import ManualAddressRow from './ManualAddressRow';

type Props = {
  addressToConfirm?: ManualAddressType | null;
  addressValidationErrors?: Record<string, any>;
  originalAddress?: AddressType | LegalAddressType | null;
  printName?: string;
  onAddressSelect: (id: ManualAddressEnum) => void;
  onEditAddress: () => void;
  selectedAddressId: ManualAddressEnum;
};

const ManualAddressConfirmPage = ({
  addressToConfirm,
  addressValidationErrors,
  originalAddress,
  onAddressSelect,
  onEditAddress,
  selectedAddressId,
}: Props) => {
  const site = useSiteContext();
  const onSelect = (id: ManualAddressEnum) => {
    onAddressSelect(id);
  };

  const suggestedAddressValidationErrors = Object.keys(addressValidationErrors || {}).reduce(
    (acc, key) => {
      const newKey = findKey(whitePagesAddressKeys, (current) => current === key);

      if (!newKey) return acc;

      return {
        ...acc,
        [newKey]: addressValidationErrors?.[key],
      };
    },
    {}
  );

  const getAddressContentComponent = (address: ReactElement[][] | string, editLink?: boolean) => (
    <ManualAddressRow address={address} editLink={editLink} onEditAddress={onEditAddress} />
  );

  const getSuggestedAddressContent = () => {
    const addressFields = Object.keys(whitePagesAddressKeys);
    const addressFiltered = addressFields.filter((field) => !!addressToConfirm?.[field]);
    const suggestedAddress = addressFiltered.map((field, index) => {
      const address: ReactElement[] = [];

      address.push(
        <AddressElement key={`validatedAddress-${field}`}>
          <MIFieldOrEmpty
            value={addressToConfirm?.[field]}
            color={
              Object.keys(suggestedAddressValidationErrors || []).includes(field)
                ? site.theme.text.color.green
                : 'rgba(33, 33, 36, 1)'
            }
            label=""
          />
          {index !== addressFiltered.length - 1 && <Comma key={field} />}
        </AddressElement>
      );

      return address;
    });

    return getAddressContentComponent(suggestedAddress, true);
  };

  const getOriginalAddress = () => {
    const addressFields = Object.values(whitePagesAddressKeys);
    const addressFiltered = addressFields
      .filter((field) => !!originalAddress?.[field])
      .map((field) => originalAddress?.[field])
      .join(', ');

    return getAddressContentComponent(addressFiltered);
  };

  const addressOptions = [
    {
      id: ManualAddressEnum.SUGGESTED,
      label: 'manualAddress.suggested',
      component: getSuggestedAddressContent(),
    },
    {
      id: ManualAddressEnum.ORIGINAL,
      label: 'manualAddress.original',
      component: getOriginalAddress(),
    },
  ];

  return (
    <FormContainer>
      <MIRadioGroup
        id="manualAddress"
        group="manualAddress"
        options={addressOptions}
        selected={selectedAddressId}
        onSelect={onSelect}
        colDirection
        color="label"
      />
    </FormContainer>
  );
};

export default ManualAddressConfirmPage;

const FormContainer = styled.div`
  display: block;
  ${(props) => props.theme?.components?.ManualAddressConfirmPage?.FormContainer}
`;

const AddressElement = styled.div`
  display: inherit;
`;

const Comma = styled.span`
  &:after {
    content: ',';
    margin-right: 0.5rem;
  }
`;
