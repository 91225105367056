import React, { useEffect } from 'react';
import analytics from 'src/app/services/analytics';
import { useModal } from 'src/app/helpers/react/useModal';

import { ANALYTICS_PAGE_NAME } from '../utils';

import { BillModalDialog } from '../BillModalDialog';

type Response = [React.ReactNode, () => void];

type Props = {
  modalName: string;
  billId?: string | undefined;
};

export const useBillModal = ({ modalName, billId }: Props): Response => {
  const isEditBillMode = !!billId;

  const [billModalElement, showModal, isOpen] = useModal(BillModalDialog, {
    modalName,
    billId,
  });

  useEffect(() => {
    if (isOpen) {
      const eventName = isEditBillMode ? 'edit-bill-modal-show' : 'add-bill-modal-show';

      analytics.track(ANALYTICS_PAGE_NAME, eventName);
    }
  }, [isOpen]);

  return [billModalElement, showModal];
};
