import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { AreaLoader } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import locations from 'src/app/pages/vendor/locations';
import { InvalidAddDeliveryMethodPage, SmartRoute } from 'src/app/version-2/externals';
import { AlreadyFilled } from './components/OptInAlreadyFilledPage/AlreadyFilled';
import { OptInBusinessDetails } from './components/OptInBusinessDetails/OptInBusinessDetails';
import { OptInPersonalDetails } from './components/OptInPersonalDetails/OptInPersonalDetails';
import { Success } from './components/OptInSuccessPage/Success';
import { bpGlobalOptInFormFlow } from './model/consts/FFNames.consts';
import { globalOptInSliceActions } from './modules/globalOptIn.slice';

export const GlobalOptInRouter = () => {
  const { token } = useRouteMatch<{ token: string }>().params;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOptInFlowOpen] = featureFlags.useFeature(bpGlobalOptInFormFlow, false);

  useEffect(() => {
    if (isOptInFlowOpen) {
      dispatch(globalOptInSliceActions.checkGlobalOptInCandidates({ token, history }));
    }
  }, []);

  return (
    <>
      {isOptInFlowOpen ? (
        <Switch>
          <SmartRoute path={locations.virtualCardCandidatesLocations.index} exact>
            <AreaLoader />
          </SmartRoute>
          <SmartRoute path={locations.virtualCardCandidatesLocations.personalDetails} exact>
            <OptInPersonalDetails />
          </SmartRoute>
          <SmartRoute path={locations.virtualCardCandidatesLocations.businessDetails} exact>
            <OptInBusinessDetails />
          </SmartRoute>
          <SmartRoute path={locations.virtualCardCandidatesLocations.invalid} exact>
            <InvalidAddDeliveryMethodPage token={token} />
          </SmartRoute>
          <SmartRoute path={locations.virtualCardCandidatesLocations.success} exact>
            <Success />
          </SmartRoute>
          <SmartRoute path={locations.virtualCardCandidatesLocations.alreadyFilled} exact>
            <AlreadyFilled />
          </SmartRoute>
        </Switch>
      ) : null}
    </>
  );
};
