import styled from 'styled-components';

export const FieldTitleContainer = styled.span`
  display: block;
  text-align: left;
  margin-bottom: 0.4rem;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  ${({ theme }) => theme.textStyles.ds.body2Medium};
`;
