import merge from 'lodash/merge';
import isFunction from 'lodash/isFunction';
import { createSelector } from '@reduxjs/toolkit';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from './createApiCallSlice';
import { RestfulSliceParams } from './types';

export type RestListStatus = {
  loading: boolean;
  order?: string[];
  error?: Record<string, any>;
  totalCount?: number;
};

export function listSliceReducer(hashKey, resultMapFunction) {
  const hashFunc = isFunction(hashKey) ? hashKey : () => hashKey;

  return {
    [ON_REQUEST](state, action) {
      const key = hashFunc(action.payload);

      state.lists[key] = { ...state.lists[key], loading: !action.payload.silent };
    },
    [ON_SUCCESS](state, action) {
      const key = hashFunc(action.meta.identifier);
      const { items, ...payloadWithoutItems } = action.payload;

      (items || []).forEach((listItem) => {
        state.byId[listItem.id] = resultMapFunction(state, state.byId[listItem.id] || {}, listItem);
      });
      state.lists[key] = {
        loading: false,
        order: (items || []).map((e) => e.id),
        ...payloadWithoutItems,
      };
    },
    [ON_FAILURE](state, action) {
      const key = hashFunc(action.meta.identifier);

      state.lists[key] = { loading: false, error: action.error };
    },
  };
}

export function selectList(hashKey) {
  const hashFunc = isFunction(hashKey) ? hashKey : () => hashKey;

  return (payload) => (state) => {
    const key = hashFunc(payload);

    return (state.lists[key]?.order || []).map((id) => state.byId[id]);
  };
}

export function selectListIsLoading(name, hashKey) {
  return (state) => state[name].lists[hashKey]?.loading;
}

type ListSliceParams<T, I, S> = {
  listHashFunc: (T) => string;
  resultMapFunction?: (state: S, currItem: I, newItem: T) => I;
} & RestfulSliceParams<T>;

const order = (storeName, listHashFunc) => (state, params) =>
  state[storeName].lists?.[listHashFunc(params)]?.order;
const byId = (storeName) => (state) => state[storeName].byId;

export function createListSlice<T, I = T, S = any>({
  storeName,
  api,
  listHashFunc,
  validate,
  sagas,
  actionName = 'list',
  resultMapFunction = (state, currItem, newItem) => merge(currItem || ({} as I), newItem),
}: ListSliceParams<T, I, S>) {
  return createApiCallSlice({
    initialState: {
      lists: {},
      byId: {},
      meta: {},
    },
    name: `[${storeName}] ${actionName}`.toUpperCase(),
    api,
    reducers: listSliceReducer(listHashFunc, resultMapFunction),
    validate,
    selectors: {
      status: (params) => (state) => state[storeName].lists?.[listHashFunc(params)],
      value: createSelector([order(storeName, listHashFunc), byId(storeName)], (order, byId) =>
        (order || []).map((id) => byId[id])
      ),
    },
    sagas,
  });
}
