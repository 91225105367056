import { useMemo } from 'react';
import { FundingSourceTypesEnum, DeliveryTypeExpeditedEnum } from 'src/app/version-2/model/enums';
import { DeliveryOptionType } from 'src/app/utils/types';
import { isWithinNextTwoWorkingDaysIntervalFromDate } from 'src/app/utils/dates';

interface Params {
  dueDate?: string | Date | null;
  deliveryOptions?: DeliveryOptionType[];
  selectedFundingSourceType?: string;
}

const useFastOptionTag = ({ dueDate, deliveryOptions, selectedFundingSourceType }: Params) =>
  useMemo(() => {
    const fastACHDeliveryOption = deliveryOptions?.find(
      (option) => option.type === DeliveryTypeExpeditedEnum.EXPEDITED_ACH
    );
    const isACHFundingSourceSelected = selectedFundingSourceType === FundingSourceTypesEnum.ACH;

    if (fastACHDeliveryOption && isACHFundingSourceSelected) {
      const fastACHDeliveryDate = fastACHDeliveryOption.deliveryDate;
      const canOnlyFastACHDeliverOnTime =
        dueDate &&
        fastACHDeliveryDate &&
        isWithinNextTwoWorkingDaysIntervalFromDate(
          new Date(fastACHDeliveryDate),
          new Date(dueDate)
        );

      return canOnlyFastACHDeliverOnTime
        ? 'bills.pay.date.deliveryOptions.price.onTimeTag'
        : 'bills.pay.date.deliveryOptions.price.fastTag';
    }

    return 'bills.pay.date.deliveryOptions.price.fastTag';
  }, [dueDate, deliveryOptions, selectedFundingSourceType]);

export { useFastOptionTag };
