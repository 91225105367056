import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { theme as chakraTheme } from '@melio/billpay-design-system';
import BlockedUserPageContainer from 'src/app/pages/quickbooks/BlockedUserPageContainer';
import InvalidAddDeliveryMethodPage from 'src/app/pages/vendor/shift-vendor-to-ach/InvalidAddDeliveryMethodPage';
import QBOVendorSelectDeliveryMethodPage from 'src/app/pages/vendor/virtual-delivery-method/QBOVendorSelectDeliveryMethodPage';
import QBOAddDeliveryMethodSuccessPage from 'src/app/pages/vendor/virtual-delivery-method/QBOAddDeliveryMethodSuccessPage';
import QBOVirtualDeliveryMethodPageContainer from 'src/app/pages/vendor/delivery-methods/QBOVirtualDeliveryMethodPageContainer';
import { QBODrawerSubtitle } from 'src/app/components/layout/DrawerSubtitle/QBODrawerSubtitle';
import oldChakraTheme from 'src/app/design-system/theme/qboTheme';
import MINotices from 'src/app/components/common/MINotices';
import {
  PaymentCreateFlowOriginEnum,
  RegistrationFlowEnum,
  RegistrationOriginEnum,
} from 'src/app/version-2/model/enums';
import { envApi } from '../version-2/api/env';
import { theme as qboTheme } from '../theme/qboTheme';
import { theme } from '../theme/defaultTheme';
import QBOLayoutPage from '../components/layout/QBOLayoutPage';
import enTranslations from '../locale/en-US';
import * as WizardElements from '../components/layout/QBOWizardElements';

// theme props from new ds
const chakraStyledTheme = pick(chakraTheme, [
  'breakpoints',
  'colors',
  'fontWeights',
  'fonts',
  'radii',
  'shadows',
  'sizes',
  'space',
  'textStyles',
]);

const qboSite = {
  name: 'qbo',
  config: merge(envApi.getConfig(), envApi.getQBOSpecificConfig()),
  theme: merge(theme, qboTheme, chakraStyledTheme),
  chakraTheme: merge(oldChakraTheme, chakraTheme),
  messages: {
    'en-US': enTranslations['en-US'],
  },
  check2fa: true,
  hasFastAch: true,
  goUnilateralInvalidAddress: true,
  useNativeMobileDatePicker: true,
  defaultIntent: 'billPayConnect',
  createOrigin: {
    pay: {
      payee: RegistrationFlowEnum.VENDOR,
      payor: RegistrationFlowEnum.QBO_INTEGRATION,
      payment: PaymentCreateFlowOriginEnum.QBO_INTEGRATION,
    },
  },
  registrationOrigin: RegistrationOriginEnum.QUICKBOOKS,
  intercomSettings: {
    custom_launcher_selector: '#MelioIntercomLauncher',
    alignment: 'right',
    horizontal_padding: 20,
    vertical_padding: 20,
    background_color: '#2ca01c',
    action_color: '#2ca01c',
    hide_default_launcher: true,
  },
  components: {
    StepLayout: QBOLayoutPage,
    WizardElements,
    AddDeliveryMethodSuccessPage: QBOAddDeliveryMethodSuccessPage,
    VendorSelectDeliveryMethodPage: QBOVendorSelectDeliveryMethodPage,
    InvalidLinkPage: InvalidAddDeliveryMethodPage,
    VirtualDeliveryMethodPageContainer: QBOVirtualDeliveryMethodPageContainer,
    BlockedUserPageContainer,
    MINotices,
    DrawerSubtitle: QBODrawerSubtitle,
  },
};

export default qboSite;
