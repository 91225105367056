import { Bill, FundingSource } from 'src/app/version-2/model/dtos';
import { paymentIntentDTOToPaymentIntentItemAdapter } from 'src/app/version-2/pages/batch-bulk/adapters/paymentIntentDTOToPaymentIntentItemAdapter';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects/batchBulkItem';

export interface BatchBulkItemsAdapterReturn {
  batchBulkItems: BatchBulkItem[];
  bills: Bill[];
  maxAmounts: Record<string, number>;
  vendorIdToContactEmailMapping: Record<number, string>;
}

export const batchBulkItemsAdapter = (
  batchBulkPaymentIntents: BatchBulkPaymentIntent[],
  fundingSources: FundingSource[]
): BatchBulkItemsAdapterReturn => {
  const bills: Bill[] = [];
  const maxAmounts: Record<string, number> = {};
  const vendorIdToContactEmailMapping: Record<number, string> = {};
  const batchBulkItems: BatchBulkItem[] = batchBulkPaymentIntents.map((paymentIntent) => {
    bills.push(...(paymentIntent.payment.bills || []));
    bills.forEach((bill) => {
      maxAmounts[bill.id] = bill.totalAmount || 0;
    });
    const vendorId = paymentIntent?.payment?.vendorId;
    const contactEmail = paymentIntent?.payment?.vendor?.contactEmail;

    if (vendorId && contactEmail) vendorIdToContactEmailMapping[vendorId] = contactEmail;

    return paymentIntentDTOToPaymentIntentItemAdapter(paymentIntent, maxAmounts, fundingSources);
  });

  return {
    batchBulkItems,
    bills,
    maxAmounts,
    vendorIdToContactEmailMapping,
  };
};
