import { QBOFooter } from 'src/app/components/layout/QBOElements';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { devices } from 'src/app/version-2/utils/devices.utils';

import styled from 'styled-components';

export const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  @media ${devices.phablet} {
    align-items: initial;
  }

  ${QBOFooter} {
    @media ${devices.tablet} {
      border: 0 solid #dce0e5;
      border-top-width: 1px;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 12.2rem;
  height: 12.2rem;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
`;

export const Title = styled.div`
  margin-top: ${({ theme }) => theme.space.ds['2xl']};
  color: ${({ theme }) => theme.colors.ds.black};
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
  ${({ theme }) => theme.textStyles.ds.h5};
  align-self: center;
  text-align: center;
`;

export const Subtitle = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.ds.black};
  ${({ theme }) => theme.textStyles.ds.body2};
  align-self: center;
  text-align: center;
`;
