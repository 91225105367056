import React, { memo } from 'react';
import styled from 'styled-components';
import IMask from 'imask';
import { IMaskMixin } from 'react-imask';
import { Popover } from '@melio/billpay-design-system';
import { devices } from 'src/app/theme/AppDevices';
import { MIInputLabel } from 'src/app/components/common/MIInputLabel';
import { TextInput, Container } from 'src/app/components/common/MITextInput';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import MINotices from 'src/app/components/common/MINotices';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import { FormUtils } from '../../types';
import { isVendorDetailsOrigin } from '../../utils';

import { currenciesOptions } from './utils';
import CurrenciesList from '../../components/CurrenciesList';

const MaskedTextInput = IMaskMixin(({ inputRef, ...props }) => (
  <TextInput className={FULL_STORY_MASK_RULE_CLASS} {...(props as any)} ref={inputRef} />
));

const mask = IMask.createMask({
  mask: '$num',
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      thousandsSeparator: ',',
      radix: '.',
      padFractionalZeros: true,
    },
  },
});

type Props = Partial<FormUtils> & {
  fee: string;
  origin: string | undefined;
};

const PaymentField = ({ values = {}, fee, origin }: Props) => {
  if (isVendorDetailsOrigin(origin)) {
    // create delivery method from vendor screen - no need amount and currency
    return null;
  }

  return (
    <Popover placement="top-start" arrowColor="ds.white">
      <Popover.PopoverTrigger>
        <StyledPaymentField>
          <Container className="amount">
            <MIInputLabel label="international.paymentDetails.amount.label" required />
            <MaskedTextInput
              value={values.amount.value?.toString()}
              label="international.paymentDetails.amount.label"
              required
              readOnly
              withBorder
              mask={mask as any}
            />

            <MINotices
              size="none"
              notices={['international.paymentDetails.amount.notice']}
              noticeValues={{ fee: <MIFormattedCurrency value={fee} /> }}
            />
          </Container>

          <CurrenciesList
            value={values.currency.value}
            options={currenciesOptions}
            label="general.emptyLabel"
            readOnlyValue
            hideArrow
          />
        </StyledPaymentField>
      </Popover.PopoverTrigger>

      <StyledPopoverContent>
        <MIFormattedText label="international.paymentDetails.editPayment" />
      </StyledPopoverContent>
    </Popover>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.values.amount.value === nextProps.values.amount.value &&
    prevProps.values.currency.value === nextProps.values.currency.value
  );
}

export default memo(PaymentField, areEqual);

const StyledPaymentField = styled.div`
  display: flex;
  margin-top: 4rem;

  .amount {
    flex: 6;
    margin-top: -0.2rem;

    input {
      padding-top: 0.2rem;
    }
  }

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: -1rem;

    .amount {
      flex: 4;

      input {
        padding-top: 0;
      }
    }
  }
`;

const StyledPopoverContent = styled(Popover.PopoverContent)`
  margin-bottom: -2.5rem;
  padding: 1.5rem;
  font-size: ${(props) => props.theme.text.size.hint};
`;
