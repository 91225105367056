export const isZipCodeValid = (zipCode: string | null | undefined) =>
  // Validate zipcode against the payment processor zipcode schema.
  zipCode && new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/).test(zipCode);

export const hasRequiredLegalCompanyInfo = (companyInfo: Record<string, any>) =>
  !!(
    isZipCodeValid(companyInfo.zipCode) &&
    companyInfo.legalCompanyName &&
    companyInfo.legalFormattedAddress &&
    companyInfo.taxId &&
    companyInfo.taxIdType &&
    companyInfo.contactFirstName &&
    companyInfo.contactLastName &&
    companyInfo.phone &&
    companyInfo.formattedAddress
  );
