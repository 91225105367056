import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';
import { useSelector, useDispatch } from 'react-redux';
import { CONSTS } from 'src/app/utils/consts';
import { selectNewDeliveryMethodAction } from 'src/app/redux/payBillWizard/actions';
import { useForm } from 'src/app/ui/form';
import profileStore from 'src/app/modules/profile/profile-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { useLocationState } from 'src/app/utils/hooks';
import analytics from 'src/app/services/analytics';
import { AddFundingSourceWizardOriginEnum } from 'src/app/version-2/model/enums';

type Props = {
  vendorId: string;
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const useVirtualCardDeliveryMethodState = ({
  vendorId,
  navigate,
  navigateWithPreservedState,
}: Props) => {
  const location = useLocation<{
    origin: any;
  }>();

  const dispatch = useDispatch();
  const vendorActions = useStoreActions(vendorsStore);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const [redirectUrl] = useLocationState('redirectUrl', null);
  const [exitUrl] = useLocationState('exitUrl', null);
  const { vendor, isVendorLoading, fetchVendor } = useFetchVendor(vendorId);

  useEffect(() => {
    const shouldReloadVendor =
      vendor && [vendor.deliveryMethods, vendor.contactEmail].some(isUndefined);

    if (shouldReloadVendor) {
      fetchVendor();
    }
  }, []);

  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const isVendorUpdating = (
    useSelector(vendorsStore.selectors.update.status(vendorId)) as { loading: boolean }
  )?.loading;

  const existingVirtualCardDeliveryMethod = vendor?.deliveryMethods?.find(
    (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD
  );

  const isDeliveryMethodCreating = (
    useSelector(deliveryMethodsStore.selectors.create.status()) as { loading: boolean }
  )?.loading;

  const isDeliveryMethodUpdating = (
    useSelector(
      deliveryMethodsStore.selectors.update.status(existingVirtualCardDeliveryMethod?.id)
    ) as { loading: boolean }
  )?.loading;

  const isFormProcessing = isDeliveryMethodCreating || isVendorUpdating || isDeliveryMethodUpdating;

  const model = useMemo(
    () => ({
      deliveryType: CONSTS.DELIVERY_TYPE.VIRTUAL_CARD,
      virtualCardAccount: {
        accountEmail:
          existingVirtualCardDeliveryMethod?.virtualCardAccount?.accountEmail ||
          vendor?.contactEmail,
      },
    }),
    [vendor]
  );

  const submitAction = async (value) => {
    const { payload } = await deliveryMethodActions.create({
      orgId,
      vendorId,
      ...value,
    });

    if (!vendor.contactEmail) {
      const vendorData = { contactEmail: value.virtualCardAccount.accountEmail };

      await vendorActions.update({
        orgId,
        id: vendorId,
        ...vendorData,
      });
    }

    dispatch(selectNewDeliveryMethodAction(payload));

    analytics.trackAction('VirtualCardAddDeliveryMethod-Clicked', {
      vendorId,
      virtualCardAccountId: payload.virtualCardAccountId,
      origin: location.state.origin || 'delivery-method-batch',
    });

    if (location.state.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK) {
      dispatch(selectNewDeliveryMethodAction(payload));
      navigateWithPreservedState({ newDeliveryMethod: payload });
    } else {
      navigate(redirectUrl);
    }
  };

  const [virtualCardDeliveryMethodVM, { submit }] = useForm(model, {
    submit: submitAction,
  });

  const goExit = () => {
    navigate(exitUrl);
  };

  const shouldShowDeliveryMethodPagePreloader = (vendorId && !vendor) || isVendorLoading;

  return {
    virtualCardDeliveryMethodVM,
    submit,
    vendor,
    isFormProcessing,
    isVendorLoading: shouldShowDeliveryMethodPagePreloader,
    goExit,
  };
};

export default useVirtualCardDeliveryMethodState;
