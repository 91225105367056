import { Box, Link } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const DeliveryMethodButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DeliveryMethodButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeliveryMethodButtonLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const DeliveryMethodMenuButton = styled.div`
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const DeliveryMethodImage = styled.img`
  width: 1.6rem;
  margin-right: 0.8rem;
`;

export const DropDownIconContainer = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg)' : '')};
  div {
    display: flex;
  }
`;

export const DeliveryMethodItemContainer = styled.div<{ isDisabled: boolean }>`
  // TODO: Denis WIP - disabled menu item
  ${({ isDisabled }) => (isDisabled ? 'color: red' : 'color: blue')}
`;

export const AddNewContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
`;

export const AddNew = styled(Link)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeliveryTitle = styled(Box)`
  text-transform: capitalize;
`;

export const InternationalMenuButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
