import * as React from 'react';
import styled from 'styled-components';
import { RecordOf } from 'immutable';
import moment from 'moment';
import { useEffect, useState } from 'react';
import paymentsApi from 'src/app/services/api/payments';
import MIButton from 'src/app/components/common/MIButton';
import { getQBRBillActionOptions } from 'src/app/pages/bill/utils';
import { getLatestPayment } from 'src/app/utils/payments';
import reverse from 'lodash/reverse';
import { useModal } from 'src/app/helpers/react/useModal';
import {
  getPaymentActivityScheduleData,
  getPaymentActivityDeliveryData,
  isManuallyPaid,
} from 'src/app/utils/bills';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { PAYMENT_STATUS, DIALOG_TYPE, DIALOG_VARIANTS, BUTTON_SIZE } from 'src/app/utils/consts';
import { loggingApi } from 'src/app/version-2/api/loggers';
import {
  BillType,
  PaymentType,
  DeletePaymentActionType,
  QBCashStateType,
  NotePlaceholderType,
} from 'src/app/utils/types';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { RiskStatusEnum } from 'src/app/version-2/model/enums';
import { AccountRecord } from '../../settings/records';
import { QBRBillPaymentReview } from 'src/app/components/bill/QBRBillPaymentReview';
import QBOMIDialog from '../../../components/common/QBOMIDialog';
import { DeliveryMethodRecord } from '../../vendor/records-constants';

type Props = {
  bill: RecordOf<BillType>;
  goEditPayment: (payment: PaymentType) => void;
  onDeletePayment: (payment: PaymentType, type?: DeletePaymentActionType) => void;
  onRetryPayment?: (paymentId: number) => void;
  onMarkBillAsPaid?: () => void;
  isRequest?: boolean;
  qbCashState?: QBCashStateType;
  notePlaceholder?: NotePlaceholderType;
};

const QBRBillPaymentsActivity = ({
  bill,
  isRequest,
  onDeletePayment,
  goEditPayment,
  qbCashState,
  notePlaceholder,
}: Props) => {
  const companyName = bill.vendor.printOnCheck || bill.vendor.companyName;
  const lastPayment = getLatestPayment(bill.payments);

  const formTitles = {
    title: 'payments.form.deleteDialog.title',
    subtitle: 'payments.form.deleteDialog.subtitle',
    okButtonText: 'payments.form.deleteDialog.confirm',
    cancelButtonText: 'payments.form.deleteDialog.cancelText',
  };

  const formDeclinedTitles = {
    title: 'payments.form.deleteDialogDeclined.title',
    subtitle: 'payments.form.deleteDialogDeclined.subtitle',
    okButtonText: 'payments.form.deleteDialogDeclined.confirm',
    cancelButtonText: 'payments.form.deleteDialogDeclined.cancelText',
  };

  const { title, subtitle, okButtonText, cancelButtonText } = {
    ...(lastPayment.riskStatus === RiskStatusEnum.DECLINED ||
    lastPayment.status === PAYMENT_STATUS.FAILED
      ? formDeclinedTitles
      : formTitles),
  };

  const deleteDialog = ({ dismiss }: { dismiss: () => void }) => (
    <QBOMIDialog
      type={DIALOG_TYPE.CONFIRM}
      variant={DIALOG_VARIANTS.ERROR}
      title={title}
      titleValues={{
        companyName: <strong>{companyName}</strong>,
      }}
      subtitle={subtitle}
      subtitleValues={{
        companyName: <strong>{companyName}</strong>,
        amount: (
          <strong>
            <MIFormattedCurrency value={bill.totalAmount} />
          </strong>
        ),
      }}
      okButtonText={okButtonText}
      onOkAction={() => onDeletePayment && onDeletePayment(lastPayment)}
      onCancelAction={dismiss}
      cancelButtonText={cancelButtonText}
      buttonsSize={BUTTON_SIZE.SMALL}
    />
  );
  const [DeletePaymentModal, onDeletePaymentCanceled] = useModal(deleteDialog, {});

  const actionOptions = getQBRBillActionOptions(
    bill,
    lastPayment,
    goEditPayment,
    onDeletePaymentCanceled
  );

  return (
    <Container>
      <Separator />
      {DeletePaymentModal}
      <PaymentItem
        key={lastPayment.id}
        payment={lastPayment}
        bill={bill}
        isRequest={isRequest}
        qbCashState={qbCashState}
        notePlaceholder={notePlaceholder}
      />
      {actionOptions.length > 0 && (
        <ActionOptionsContainer>
          {reverse(actionOptions).map((actionOption, key) => (
            <MIButton
              key={key}
              label={actionOption.label}
              variant={actionOption.variant}
              onClick={actionOption.action}
              size={actionOption.size}
              fullWidth={actionOption.fullWidth}
            />
          ))}
        </ActionOptionsContainer>
      )}
    </Container>
  );
};

export default QBRBillPaymentsActivity;

/**
 * Total
 */
type PaymentItemProps = {
  payment: PaymentType;
  bill: RecordOf<BillType>;
  isRequest?: boolean | null;
  qbCashState?: QBCashStateType;
  notePlaceholder?: NotePlaceholderType;
};

const PaymentItem = ({
  bill,
  payment,
  isRequest,
  qbCashState,
  notePlaceholder,
}: PaymentItemProps) => {
  const [fee, setFee] = useState(null);
  const site = useSiteContext();
  const markedAsPaid = isManuallyPaid(bill.status, payment.manual);

  useEffect(() => {
    if (!markedAsPaid) {
      paymentsApi
        .getPaymentFee({
          orgId: payment.organizationId,
          fundingSourceId: payment.fundingSourceId,
          amount: bill.totalAmount,
          scheduledDate: payment.scheduledDate,
          deliveryMethodId: payment.deliveryMethodId,
          deliveryPreference: payment.deliveryPreference,
          allowDoubleFee: true,
        })
        .then((res) => {
          setFee(res.fee);
        })
        .catch((err) => {
          loggingApi.error('QBRBillPaymentsActivity.useEffect(): unable to get payment fee', {
            error: err,
          });
          setFee(null);
        });
    }
  }, [
    payment.deliveryMethodId,
    payment.organizationId,
    payment.fundingSourceId,
    bill.totalAmount,
    payment.scheduledDate,
    markedAsPaid,
    payment.deliveryPreference,
  ]);
  const companyName = bill.vendor.printOnCheck || bill.vendor.companyName;
  const fundingSource = AccountRecord((payment as any).fundingSource);
  const deliveryMethod = DeliveryMethodRecord(payment.deliveryMethod);

  const scheduledData = !isRequest
    ? getPaymentActivityScheduleData(
        bill.status,
        payment.scheduledDate,
        fundingSource,
        undefined,
        undefined,
        undefined,
        undefined,
        site,
        qbCashState
      )
    : null;

  const dueDate = moment(bill.dueDate).format('YYYY-MM-DD');
  const deliveryEta = moment(payment.deliveryEta).format('YYYY-MM-DD');
  const isFirstPaymentLate = deliveryEta > dueDate;
  const deliveryData =
    (!isRequest && !bill.isVendorRequest()) || isRequest
      ? getPaymentActivityDeliveryData(
          payment,
          bill.status,
          !!bill.recurringBillId,
          isFirstPaymentLate,
          companyName,
          payment.deliveryEta,
          deliveryMethod,
          false,
          undefined,
          undefined,
          isRequest
        )
      : null;

  return (
    <>
      <QBRBillPaymentReview
        bill={bill}
        fee={fee}
        companyName={companyName}
        occurrences={bill.occurrences}
        frequency={bill.frequency}
        isRecurringBill={!!bill.recurringBillId}
        dueDate={bill.dueDate as unknown as Date}
        scheduledData={scheduledData as any}
        deliveryData={deliveryData as any}
        memo={payment.note}
        isPaymentFailed={payment.status === PAYMENT_STATUS.FAILED}
        isSingleBill
        notePlaceholder={notePlaceholder}
      />
      {markedAsPaid && (
        <MarkAsPaidContainer>
          <MIFormattedText
            label="bills.view.markAsPaidInfo"
            values={{
              date: <MIFormattedDate date={payment.scheduledDate} />,
            }}
          />
        </MarkAsPaidContainer>
      )}
    </>
  );
};

PaymentItem.defaultProps = {
  isRequest: false,
};

const Container = styled.div`
  padding: 0 1.6rem;
`;

const Separator = styled.div`
  height: 0.1rem;
  background-color: rgba(244, 245, 248, 1);
  margin: 0 2rem;
  ${(props) => props.theme?.components?.QBOBillPaymentsActivity?.Separator}
`;

const ActionOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 8rem;
  margin-bottom: -16rem;
  margin-top: 11rem;
  flex-direction: row-reverse;

  ${(props) => props.theme?.components?.QBOBillPaymentsActivity?.ActionOptionsContainer}
`;

const MarkAsPaidContainer = styled.div`
  padding: 2rem 1.6rem;
  font-size: ${(props) => props.theme.text.size.linkHint};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.colors.success.opaque};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
`;
