import { PayloadAction } from '@reduxjs/toolkit';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { pushNotification } from 'src/app/version-2/externals';
import { NotificationVariantEnum } from 'src/app/version-2/model/enums';
import { upgradePaymentByPayeeApi } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/upgradePaymentByPayee.api';
import { UpgradePaymentByPayeeType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upgradePaymentByPayeeType.enum';
import { UpsellType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upsellType.enum';
import { UpgradePaymentByPayeeActionParams } from 'src/app/version-2/pages/vendor-upgrade-payment/model/objects/upgradePaymentByPayeeActionParams';
import { redirectToSuccessPage } from 'src/app/version-2/pages/vendor-upgrade-payment/utils/navigation.utils';
import { upgradePaymentActions } from '../upgradePayment.slice';

import { call, put } from 'redux-saga/effects';

const UpgradePaymentByPayeeTypeToUpsellTypeMap = {
  [UpgradePaymentByPayeeType.FAST_ACH]: UpsellType.PUSH_TO_FAST_ACH,
  [UpgradePaymentByPayeeType.RTP]: UpsellType.PUSH_TO_RTP,
};

const ERR_ALREADY_EXPEDITED_CODE_P2FACH = 'P2FACH5';
const ERR_ALREADY_EXPEDITED_CODE_P2RTP = 'P2RTP5';

const UpgradePaymentByPayeeTypeToErrorMessageMap = {
  [UpgradePaymentByPayeeType.FAST_ACH]: {
    [ERR_ALREADY_EXPEDITED_CODE_P2FACH]: 'vendors.upgradePayment.toast.errorAlreadyExpeditedFast',
    default: 'vendors.upgradePayment.toast.errorFast',
  },
  [UpgradePaymentByPayeeType.RTP]: {
    [ERR_ALREADY_EXPEDITED_CODE_P2RTP]: 'vendors.upgradePayment.toast.errorAlreadyExpeditedRtp',
    default: 'vendors.upgradePayment.toast.errorRtp',
  },
};

export function* upgradePaymentByPayeeHandler(
  action: PayloadAction<UpgradePaymentByPayeeActionParams>
) {
  try {
    yield put(upgradePaymentActions.setIsSubmitting(true));

    const { token, type, history } = action.payload;
    const { paymentId } = getJWTPayload(token);

    yield call(upgradePaymentByPayeeApi.upgradePayment, { token, paymentId, type });
    yield put(upgradePaymentActions.setIsSubmitting(false));
    redirectToSuccessPage(history, token, UpgradePaymentByPayeeTypeToUpsellTypeMap[type]);
  } catch (error: any) {
    const { token, type } = action.payload;
    const { paymentId, vendorId } = getJWTPayload(token);

    loggingApi.error(
      'upgradePaymentByPayee.saga.upgradePaymentByPayeeHandler(): Error while upgrading payment by payee',
      { error, paymentId, vendorId }
    );

    const UpgradeTypeMessages = UpgradePaymentByPayeeTypeToErrorMessageMap[type];
    const message = UpgradeTypeMessages[error?.code] || UpgradeTypeMessages.default;

    pushNotification({
      type: NotificationVariantEnum.ERROR,
      msg: message,
    });

    yield put(upgradePaymentActions.setError(error));
    yield put(upgradePaymentActions.setIsSubmitting(false));
  }
}
