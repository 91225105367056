import React from 'react';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { DeliveryMethodType, OrganizationType, PaymentType } from 'src/app/utils/types';
import { InfoLayout, ReviewGridItem } from '../components';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  payment?: PaymentType;
  deliveryMethod?: DeliveryMethodType;
  organization?: OrganizationType;
};

const NewDeliveryMethodLayout = ({ organization, payment, deliveryMethod }: Props) => {
  useStateChangedAnalytics({ payment, paymentState: VirtualCardPaymentState.NewDeliveryMethod });

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <InfoLayout.Icon type={InfoLayout.IconType.Info} />
      <InfoLayout.Title>
        <MIFormattedText label="vendors.virtualCardDetails.newDeliveryMethod.title" />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label="vendors.virtualCardDetails.newDeliveryMethod.subtitle"
          values={{ name: organization?.companyName }}
        />
      </InfoLayout.Subtitle>
      <InfoLayout.Separator />
      <InfoLayout.SectionTitle>
        <MIFormattedText label="vendors.virtualCardDetails.newDeliveryMethod.reviewSectionTitle" />
      </InfoLayout.SectionTitle>
      <InfoLayout.Grid>
        <ReviewGridItem
          icon="icon-bank-icon"
          label={<MIFormattedText label="vendors.virtualCardDetails.common.bankTransferTo" />}
          value={
            <MIFormattedText
              label="vendors.virtualCardDetails.common.bankAccount"
              values={{
                digits: getAccountNumber4digits(deliveryMethod?.bankAccount),
              }}
            />
          }
        />
        <ReviewGridItem
          icon="icon-eta-cal"
          label={<MIFormattedText label="vendors.virtualCardDetails.common.eta" />}
          value={<MIFormattedDate date={payment?.deliveryEta} />}
          notes={<MIFormattedText label="vendors.virtualCardDetails.common.etaNotes" />}
        />
      </InfoLayout.Grid>
    </InfoLayout>
  );
};

export { NewDeliveryMethodLayout };
