import { virtualCardCandidatesLocations } from 'src/app/version-2/pages/global-opt-in-details/location';
import shiftVendorToAch from './shift-vendor-to-ach/locations';
import shiftToDebit from './shift-vendor-to-debit/locations';
import unilateral from './virtual-delivery-method/locations';
import editCheckAddress from './edit-check-adress/locations';
import { internationalLocations } from 'src/app/pages/vendor/delivery-methods/international/locations';
import qbDirectory from './qb-directory/locations';
import virtualCardDetails from './virtual-card-details/locations';
import selectExpedited from './select-expedited/locations';
import shiftVirtualCard from './shift-virtual-card/locations';
import vendorPaymentTracking from './vendor-payment-tracking/locations';
import { upgradePaymentLocations } from './upgrade-payment/locations';

const LOCATIONS = {
  base: unilateral.base.concat(
    shiftVendorToAch.base,
    shiftToDebit.base,
    editCheckAddress.base,
    qbDirectory.base,
    virtualCardDetails.base,
    internationalLocations.base,
    selectExpedited.base,
    shiftVirtualCard.base,
    vendorPaymentTracking.base,
    virtualCardCandidatesLocations.base,
    upgradePaymentLocations.base
  ),
  unilateral,
  shiftVendorToAch,
  shiftToDebit,
  editCheckAddress,
  internationalLocations,
  qbDirectory,
  virtualCardDetails,
  selectExpedited,
  shiftVirtualCard,
  vendorPaymentTracking,
  virtualCardCandidatesLocations,
  upgradePaymentLocations,
};

export default LOCATIONS;
