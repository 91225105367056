import { deliveryMethodsApi } from 'src/app/version-2/api/deliveryMethods.api';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { DeliveryMethodRequestData } from 'src/app/version-2/api/requests/DeliveryMethodRequestData';
import { analytics, pushNotification } from 'src/app/version-2/externals';
import { SERVER_ERROR_INTERNAL_CODE } from 'src/app/version-2/model/constants';
import { NotificationVariantEnum } from 'src/app/version-2/model/enums';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { navigateOnAchDeliveryMethodSavedHandler } from './navigateOnAchDeliveryMethodSaved.saga';
import {
  achDeliveryMethodDetailsActions,
  achDeliveryMethodDetailsSelectors,
} from '../achDeliveryMethodDetails.slice';

import { History } from 'history';
import { call, put, select } from 'redux-saga/effects';

interface CreateAchDeliveryMethodHandlerParams {
  deliveryMethod: DeliveryMethodRequestData;
  vendorId: string;
  history: History;
}

export function* createAchDeliveryMethodHandler({
  deliveryMethod,
  vendorId,
  history,
}: CreateAchDeliveryMethodHandlerParams) {
  yield put(achDeliveryMethodDetailsActions.setIsSubmitting(true));
  yield put(achDeliveryMethodDetailsActions.resetError());

  const orgId = yield select(userSliceSelectors.selectOrgId);
  const analyticsParams = yield select(achDeliveryMethodDetailsSelectors.selectFlowAnalyticsParams);

  analytics.trackAction('add-delivery-method', {
    type: deliveryMethod.deliveryType,
    ...analyticsParams,
  });

  try {
    const { deliveryMethod: createdDeliveryMethod } = yield call(deliveryMethodsApi.create, {
      orgId,
      vendorId,
      data: {
        deliveryType: deliveryMethod.deliveryType,
        bankAccount: deliveryMethod.bankAccount,
      },
    });

    yield put(
      achDeliveryMethodDetailsActions.deliveryMethodCreated({
        deliveryMethod: createdDeliveryMethod,
      })
    );

    analytics.trackAction('add-delivery-method-success', {
      type: createdDeliveryMethod.deliveryType,
      vendorId,
      ...analyticsParams,
    });

    yield call(navigateOnAchDeliveryMethodSavedHandler, {
      savedDeliveryMethod: createdDeliveryMethod,
      vendorId,
      history,
    });

    yield put(achDeliveryMethodDetailsActions.setIsSubmitting(false));
  } catch (error: any) {
    const errorCode = error?.code || error?.error?.code || SERVER_ERROR_INTERNAL_CODE;

    loggingApi.error(
      'addAchDeliveryMethodSaga.createAndSetAchDeliveryMethodHandler(): creation failed',
      { errorCode, vendorId, orgId }
    );

    analytics.trackAction('add-delivery-method-failure', {
      type: deliveryMethod.deliveryType,
      ...analyticsParams,
    });

    pushNotification({
      type: NotificationVariantEnum.ERROR,
      msg: `server.${errorCode}`,
    });

    yield put(
      achDeliveryMethodDetailsActions.setError({
        error: { code: errorCode },
      })
    );
    yield put(achDeliveryMethodDetailsActions.setIsSubmitting(false));
  }
}
