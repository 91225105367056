import { RecordOf } from 'immutable';
import { useRefreshCompanyInfo } from 'src/app/modules/organizations/hooks/useRefreshCompanyInfo';
import { hasRequiredLegalCompanyInfo } from 'src/app/utils/company';
import { companyAddressHasPOBox } from 'src/app/utils/delivery-methods';
import { CompanyInfoType, UserContextType } from 'src/app/utils/types';
import { hasDateOfBirthTaxType } from 'src/app/utils/user-utils';

type Params = {
  companyInfo: RecordOf<CompanyInfoType>;
  profile: RecordOf<UserContextType>;
};

const shouldFillLegalInfo = ({ companyInfo, profile }: Params) =>
  !hasRequiredLegalCompanyInfo(companyInfo) ||
  (hasDateOfBirthTaxType(companyInfo.taxIdType) && !profile.dateOfBirth) ||
  companyAddressHasPOBox(companyInfo);

export const useCheckMissingInternationalLegalInfo = () => {
  const { refreshCompanyInfo } = useRefreshCompanyInfo();
  const checkMissingInternationalCompanyLegalInfo = async ({ companyInfo, profile }: Params) => {
    if (!shouldFillLegalInfo({ companyInfo, profile })) {
      return false;
    }

    const refreshedCompanyInfo = await refreshCompanyInfo();

    return shouldFillLegalInfo({ companyInfo: refreshedCompanyInfo, profile });
  };

  return { checkMissingInternationalCompanyLegalInfo };
};
