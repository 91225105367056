import { QuickPayment } from 'src/app/modules/payments/quickpay-slice';
import { RecordOf } from 'immutable';
import { BillRecord } from 'src/app/pages/bill/records';
import { BillType, VendorType } from 'src/app/utils/types';
import { safeDate } from 'src/app/modules/payments/utils';

export const getDateFields = (
  suggestedScheduledDate: string,
  deliveryEta: string,
  maxDeliveryEta: string
) => ({
  scheduledDate: safeDate(suggestedScheduledDate),
  deliveryEta: safeDate(deliveryEta),
  maxDeliveryEta: safeDate(maxDeliveryEta),
});

export const getConfirmBill = (payment: QuickPayment, vendor: VendorType): RecordOf<BillType> =>
  BillRecord({
    id: -1 as unknown as string,
    vendor: vendor as any,
    scheduledDate: payment.scheduledDate,
    totalAmount: payment.totalAmount,
    payment,
  } as any);
