import { FeetchFreeChecksResponse } from 'src/app/version-2/api/reponses/FeetchFreeChecksResponse';
import { FetchActiveFeeFundingSourceResponse } from 'src/app/version-2/api/reponses/FetchActiveFeeFundingSourceResponse';
import { fetchRequest, putRequest } from 'src/app/version-2/externals';
import { CARD_PROVIDERS } from 'src/app/version-2/model/enums';

export const organizationApi = {
  update: ({ orgId, id, params }) => putRequest(`/orgs/${orgId}/vendors/${id}`, params),
  fetchAmexAllowedMcc: (orgId) =>
    fetchRequest(`/orgs/${orgId}/allowed-mcc`, { cardNetwork: CARD_PROVIDERS.AMEX }),
  fetchCategoriesForBill: (orgId) => fetchRequest(`/orgs/${orgId}/accounts-bill-options`),
  fetchActiveFeeFundingSource: (orgId: number) =>
    fetchRequest<FetchActiveFeeFundingSourceResponse>(`/orgs/${orgId}/billing-fee`),
  fetchFreeChecks: (orgId: number) =>
    fetchRequest<FeetchFreeChecksResponse>(`/orgs/${orgId}/free-checks`),
};
