import React, { useCallback } from 'react';
import styled from 'styled-components';
import { CONSTS, BUTTON_SIZE } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import analytics from 'src/app/services/analytics';

type Props = {
  labelPrefix: string;
  utmCampaign: string;
};

const QBOJoinService = ({ labelPrefix, utmCampaign }: Props) => {
  const onClickHandle = useCallback(() => {
    analytics.trackAction('join-quickbooks-online', {
      source: utmCampaign,
    });
    window.open(
      `https://quickbooks.intuit.com/pricing?utm_source=melio&utm_campaign=${utmCampaign}`
    );
  }, [utmCampaign]);

  return (
    <>
      <DialogTitle>
        <MIFormattedText label={`${labelPrefix}.dialog.title`} />
      </DialogTitle>
      <DialogSubTitle>
        <MIFormattedText label={`${labelPrefix}.dialog.subTitle`} />
      </DialogSubTitle>
      <Button>
        <MIButton
          label={`${labelPrefix}.button`}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          fullWidth
          size={BUTTON_SIZE.VERY_SMALL}
          onClick={onClickHandle}
        />
      </Button>
    </>
  );
};

const DialogTitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.wizardStepTitle};
  font-weight: ${({ theme }) => theme.text.weight.demi};
  line-height: 3.2rem;
  margin-bottom: 1.6rem;
`;

const DialogSubTitle = styled.div`
  ${({ theme }) => theme.text.fontType.regular}
  line-height: ${({ theme }) => theme.text.lineHeight.regular};
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.text.color.subtitle};
`;

const Button = styled.div`
  height: 3.6rem;
  margin-bottom: 2.4rem;
`;

export default QBOJoinService;
