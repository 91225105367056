import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedCurrency } from 'src/app/version-2/externals';
import { Bill } from 'src/app/version-2/model/dtos';
import { ListItem } from './BillsPopover.styles';
import { formatDate } from './BillsPopover.utils';

interface BillsItemProps {
  bill: Bill;
}

const BillsItem = ({ bill }: BillsItemProps) => {
  const { id, dueDate, totalAmount } = bill;

  return (
    <ListItem key={id}>
      <Box>{id}</Box>
      <Box>{formatDate(dueDate)}</Box>
      <Box>
        <MIFormattedCurrency value={totalAmount} />
      </Box>
    </ListItem>
  );
};

export { BillsItem };
