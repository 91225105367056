import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
`;

export const ReasonTitleContainer = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ds.gray[200]};
`;

export const ReasonContentContainer = styled.div`
  ${({ theme }) => theme.textStyles.ds.body1};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;
