import React from 'react';
import { WizardFormColumn, WizardFormRow } from 'src/app/components/layout/WizardElements';
import { MITextInput } from 'src/app/components/common/MITextInput';

type Props = {
  value: string | null | undefined;
  label: string;
  id: string;
  required?: boolean;
  placeHolder: string;
  errorMessage: string;
  validationTest: string;
  wizardColumn?: boolean;
  submitValidation: boolean;
  returnVal: ({ id, value }: { id: string; value: any }) => void;
  autoFocus: boolean;
  disabled?: boolean;
  notices?: string | unknown[];
};

type State = {
  stateValue: string | null | undefined;
  isValid: boolean;
  Timer: any;
};

const validationUtils = {
  string: (value) => value.length >= 2,
  state: (value) => value === value.toUpperCase() && value.length === 2,
};

export default class CardFormInput extends React.Component<Props, State> {
  static defaultProps = {
    wizardColumn: true,
    required: false,
    autoFocus: false,
    disabled: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      stateValue: props.value,
      isValid:
        props.value && props.value.length
          ? validationUtils[props.validationTest](props.value)
          : true,
      Timer: '',
    };
  }

  static getDerivedStateFromProps(props: Props) {
    if (props.value === null) {
      return {
        stateValue: '',
        isValid: true,
        Timer: '',
      };
    }

    return null;
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextState.stateValue !== this.state.stateValue || nextProps.submitValidation) return true;

    if (nextProps.value === this.props.value) return false;

    return true;
  }

  handleChange = (event: Record<string, any>) => {
    const inputVal = event.value;

    clearTimeout(this.state.Timer);
    this.setState({
      stateValue: inputVal,
      Timer: setTimeout(() => {
        this.setState({
          isValid: validationUtils[this.props.validationTest](inputVal),
        });
        this.props.returnVal({ value: inputVal, id: this.props.id });
      }, 500),
    });
  };

  render() {
    const {
      label,
      placeHolder,
      required,
      errorMessage,
      wizardColumn,
      submitValidation,
      autoFocus,
      disabled,
      notices,
    } = this.props;
    const { stateValue, isValid } = this.state;
    const WizardWrapper = wizardColumn ? WizardFormColumn : WizardFormRow;

    return (
      <WizardWrapper>
        <MITextInput
          value={stateValue}
          label={label}
          placeholder={placeHolder}
          required={required}
          onChange={this.handleChange}
          autoFocus={autoFocus}
          errorMessage={!isValid || submitValidation ? errorMessage : ''}
          disabled={disabled}
          notices={notices}
        />
      </WizardWrapper>
    );
  }
}
