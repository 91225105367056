import { Box, CSSObject } from '@melio/billpay-design-system';

import ReactCodeInput from 'react-code-input';
import { useBreak } from 'src/app/hoc';

type textAlignType =
  | 'center'
  | '-moz-initial'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'
  | 'end'
  | 'justify'
  | 'left'
  | 'match-parent'
  | 'right'
  | 'start'
  | undefined;

type Props = {
  type?: 'number' | 'text' | 'password' | 'tel' | undefined;
  fields?: number;
  name?: string;
  isValid?: boolean;
  onChange: (value: string) => void;
  pattern?: string;
};

const getStyleProps = (isMobile) => {
  const inputWidth = isMobile ? '2.5rem' : '5.5rem';
  const commonStyles = {
    border: '0',
    margin: '1rem',
    paddingBottom: '1rem',
    fontSize: '2.3rem',
    height: '2.6rem',
    backgroundColor: 'transparent',
    outline: 'none',
    textAlign: 'center' as textAlignType,
    borderRadius: '0',
    width: inputWidth,
  };

  return {
    inputStyle: {
      ...commonStyles,
      borderBottom: '.2rem solid rgba(33,33,36,0.3)',
    },
    inputStyleInvalid: {
      ...commonStyles,
      borderBottom: '.2rem solid rgba(213, 43, 30, 1)', // red.500 #D52B1E
    },
  };
};

export const MICodeInput = ({
  type = 'text',
  fields = 4,
  isValid = true,
  onChange,
  pattern,
  name = 'MICodeInput',
}: Props) => {
  const { isMobile } = useBreak();

  return (
    <Box __css={containerStyle} sx={containerSX}>
      <ReactCodeInput
        type={type}
        fields={fields}
        isValid={isValid}
        autoFocus
        onChange={onChange}
        pattern={pattern}
        name={name}
        inputMode="numeric"
        {...getStyleProps(isMobile)}
      />
    </Box>
  );
};

const containerStyle: CSSObject = {
  textAlign: 'center',
};

const containerSX: CSSObject = {
  "input[type='number']": {
    MozAppearance: 'textfield',
  },
  "input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button":
    {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      appearance: 'none',
      margin: 0,
    },
};
