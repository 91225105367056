import React from 'react';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import { featureFlags } from '@melio/shared-web';
import { AddCardAccountRenderer } from 'src/app/version-2/pages/add-card-account/renderers/AddCardAccount.renderer';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import CompleteAddingBankAccountsPageContainer from './bank/CompleteAddingBankAccountsPageContainer';
import LinkIntuitAccountToFundingSourcePage from './bank/LinkIntuitAccountToFundingSourcePage';
import AddCardAccountsPageContainer from './card/AddCardAccountsPageContainer';
import SetCardAccountsHolderPageContainer from './card/SetCardAccountsHolderPageContainer';
import SetManuallyBankAccountPageContainer from './bank/SetManuallyBankAccountPageContainer';
import SetBankPlaidPageContainer from './bank/SetBankPlaidPageContainer';
import SelectBankAccountVerificationTypePageContainer from './bank/SelectBankAccountVerificationTypePageContainer';
import { BankAccountPage } from 'src/app/version-2/pages/bank-account/components';
import ViewManualBankAccountPage from './bank/ViewManualBankAccountPage';
import { CantFindBankPageContainer } from './bank/CantFindBankPageContainer';

const eventPage = 'add-funding-source';

export default [
  {
    path: locations.Onboarding.fundingSources.bank.account.url(),
    exact: true,
    component: LinkIntuitAccountToFundingSourcePage,
  },
  {
    path: locations.Onboarding.fundingSources.bank.plaid.url(),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'bank-plaid');

      return <SetBankPlaidPageContainer {...cProps} />;
    },
  },
  {
    path: locations.Onboarding.fundingSources.bank.complete.url(),
    exact: true,
    component: CompleteAddingBankAccountsPageContainer,
  },
  {
    path: locations.Onboarding.fundingSources.bank.cantFind.url(),
    exact: true,
    component: CantFindBankPageContainer,
  },
  {
    path: locations.Onboarding.fundingSources.bank.manually.url(),
    exact: true,
    component: SetManuallyBankAccountPageContainer,
  },
  {
    path: locations.Onboarding.fundingSources.bank.manuallyView.url(),
    exact: true,
    component: ViewManualBankAccountPage,
  },
  {
    path: locations.Onboarding.fundingSources.card.index.url(),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'card');

      const [isBasisTheoryFeatureFlag] = featureFlags.useFeature(FeatureFlagsEnum.BP_BASIS_THEORY);

      if (isBasisTheoryFeatureFlag) {
        return <AddCardAccountRenderer {...cProps} />;
      }

      return <AddCardAccountsPageContainer {...cProps} />;
    },
  },
  {
    path: locations.Onboarding.fundingSources.bank.select.url(),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'bank-verification-type-select');

      return <SelectBankAccountVerificationTypePageContainer {...cProps} />;
    },
  },
  {
    path: locations.Onboarding.fundingSources.bank.type.url(),
    exact: true,
    component: (cProps) => {
      const {
        location: {
          state: { billIds, isBatch, preservedState, redirectUrl },
        },
      } = cProps;

      return (
        <BankAccountPage
          billIds={billIds}
          isBatch={isBatch}
          preservedState={preservedState}
          redirectUrl={redirectUrl}
        />
      );
    },
  },
  {
    path: locations.Onboarding.fundingSources.card.cardholder.url(),
    exact: true,
    component: SetCardAccountsHolderPageContainer,
  },
];
