import { Theme } from '../../theme';

const sizes = {
  md: {
    tab: {
      textStyle: 'ds.body1Semi',
      padding: '1rem 1.6rem',
    },
  },
};

function variantLine(props: { theme: Theme }) {
  const { theme } = props;

  return {
    root: {
      display: 'flex',
      flexDir: 'column',
      h: '100%',
      fontFamily: 'body',
    },
    tablist: {
      bg: theme.colors.white,
      borderBottom: '0.2rem solid',
      borderColor: 'ds.gray.500',
    },
    tab: {
      bg: theme.colors.white,
      color: 'ds.gray.200',
      fontWeight: 'ds.medium',
      border: 0,
      cursor: 'pointer',
      position: 'relative',
      outline: 0,
      _selected: {
        color: 'ds.black',
        fontWeight: 'ds.semi',
      },
    },
    tabpanels: {
      width: 'inherit',
      h: '100%',
    },
    tabpanel: {
      display: 'flex',
      flexDir: 'column',
      h: '100%',
      _hidden: {
        display: 'none',
      },
    },
  };
}

const variants = {
  line: variantLine,
};

const defaultProps = {
  size: 'md',
  variant: 'line',
};

export const TabsTheme = {
  sizes,
  variants,
  defaultProps,
};
