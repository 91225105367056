import { RecordOf } from 'immutable';
import React from 'react';
import styled from 'styled-components';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { BillType, DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodText,
  PaymentInfoText,
  PaymentInfoHint,
} from 'src/app/components/layout/PaymentActivityElements';
import {
  BILL_STATUS,
  DELIVERY_TYPE,
  PAYMENT_APPROVAL_STATUS,
  PAYMENT_STATUS,
} from 'src/app/utils/consts';

type Props = {
  payment: RecordOf<PaymentType>;
  bill: RecordOf<BillType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

const ViewBillPaymentActivityDeductionDateInfo = ({ payment, bill, deliveryMethod }: Props) => {
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const isPaymentFailed = payment.status === PAYMENT_STATUS.FAILED;
  const deductionDateLabel =
    isBillPaid || isPaymentFailed
      ? 'viewBillPaymentActivity.deductionDate.paidLabel'
      : 'viewBillPaymentActivity.deductionDate.unpaidLabel';

  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isUnitaleral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  const hint =
    isApprovalRequired && !isUnitaleral
      ? 'viewBillPaymentActivity.deductionDate.approvalRequiredHint'
      : '';

  return (
    <PaymentActivityContainer data-testid="view-payment-activity-deduction-date">
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon isBillPaid={isBillPaid}>
            <ScheduledIcon />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={deductionDateLabel} />
            </PaymentMethodText>
            <PaymentInfoText isBillPaid={isBillPaid}>
              <MIFormattedDate date={payment.scheduledDate} />
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
      </PaymentActivityMainContainer>
      {!isBillPaid && hint && (
        <PaymentInfoHint>
          <MIFormattedText label={hint} />
        </PaymentInfoHint>
      )}
    </PaymentActivityContainer>
  );
};

const ScheduledIcon = styled.i.attrs({ className: 'icon-scheduled-icon' })``;

export default ViewBillPaymentActivityDeductionDateInfo;
