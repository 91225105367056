import { ExperimentComponentEnum, ExperimentVariantsEnum } from 'src/app/version-2/model/enums';

export const FUNDING_DEFAULT_LOGO = 'default';

export const FUNDING_SOURCE_EXPERIMENT_VARIANTS = {
  CONTROL: ExperimentVariantsEnum.CONTROL,
  CARD: ExperimentVariantsEnum.CARD,
  TABLE: ExperimentVariantsEnum.TABLE,
};

export const FUNDING_SOURCE_VARIANT_COMPONENTS = {
  CONTROL: ExperimentComponentEnum.CONTROL,
  CARD: ExperimentComponentEnum.CARD,
  TABLE: ExperimentComponentEnum.TABLE,
};
