import authApi from 'src/app/services/api/auth';
import { shouldUse2FAHeaders } from 'src/app/version-2/utils/headers-2fa.utils';
import { Site } from 'src/app/sites/site';

type Props = {
  realmId?: string;
  site: Site;
  skip: boolean;
};

export const enum Check2FACredentialsResult {
  Invalid = 'invalid',
  Valid = 'valid',
  Mismatched = 'mismatched',
}

const check2FACredentials = async ({ realmId, site, skip }: Props) => {
  if (skip || !site.check2fa || !realmId) {
    return Check2FACredentialsResult.Valid;
  }

  let response;

  try {
    if (shouldUse2FAHeaders(site.name)) {
      response = await authApi.check2faHeaderToken(realmId, site.defaultIntent);
    } else {
      response = await authApi.check2fa(realmId, site.defaultIntent);
    }

    return response.verified
      ? Check2FACredentialsResult.Valid
      : Check2FACredentialsResult.Mismatched;
  } catch {
    return Check2FACredentialsResult.Invalid;
  }
};

export { check2FACredentials };
