import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS } from 'src/app/utils/consts';
import { useBreak } from 'src/app/hoc';
import { getIsFeeFundingSourceLoading } from 'src/app/redux/organization/selectors';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { TitleContainer } from 'src/app/components/common/ModalMessage/ModalMessage.styles';
import { Button, Divider, Footer, Modal } from './styles';

interface Props {
  dismiss(result): void;
  remove(cb): void;
  onShow(): void;
}
const ModalRemovePayment = styled(Modal)`
  ${TitleContainer} {
    @media ${devices.mobile} {
      margin-bottom: 1.8rem;
    }
  }
`;

export const RemovePaymentModal: React.FC<Props> = ({ dismiss, remove, onShow }) => {
  const { isMobile } = useBreak();
  const isFeeFundingLoading = useSelector(getIsFeeFundingSourceLoading);

  useEffect(() => {
    onShow && onShow();
  }, []);

  const handleRemove = () => {
    remove(dismiss);
  };

  const handleDismiss = () => {
    dismiss && dismiss('cancel');
  };

  return (
    <ModalRemovePayment
      onCloseClick={handleDismiss}
      titleComponent={
        <Box textStyle="ds.h5" fontWeight="ds.semi">
          <MIFormattedText label="settings.billing.feeCard.modal.removePayment.title" />
        </Box>
      }
      contentComponent={
        <Box lineHeight="2.4rem">
          <MIFormattedText label="settings.billing.feeCard.modal.removePayment.description" />
        </Box>
      }
      footerComponent={
        <>
          <Divider />
          <Footer>
            <Button
              onClick={handleRemove}
              label="settings.billing.feeCard.modal.removePayment.actions.remove"
              variant={CONSTS.BUTTON_VARIANT.DESTRUCTIVE}
              fullWidth={isMobile}
              isProcessing={isFeeFundingLoading}
            />
            <Button
              onClick={handleDismiss}
              label="settings.billing.feeCard.modal.removePayment.actions.cancel"
              variant={CONSTS.BUTTON_VARIANT.SECONDARY}
              fullWidth={isMobile}
            />
          </Footer>
        </>
      }
    />
  );
};
