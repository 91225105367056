import billsApi from 'src/app/services/api/bills';
import organizationsApi from 'src/app/services/api/organizations';
import paymentApi from 'src/app/services/api/payments';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { EntrypointActionEnum } from 'src/app/version-2/model/enums';
import { JustPayPaymentType } from '../justPayTypes';

export const useJustPayCreatePayment = () => {
  const { createOrigin } = useSiteContext();

  const createPayment = async (payment: JustPayPaymentType) => {
    const createBillResponse = await billsApi.createBill(payment.orgId, {
      vendorId: payment.vendor.id,
      totalAmount: payment.amount,
      balance: payment.amount,
      currency: 'USD',
      note: payment.noteToVendor,
      intuitAccountId: payment.category?.id,
      dueDate: payment.suggestedDates.deliveryEta ?? payment.suggestedDates.scheduledDate,
      invoiceDate: payment.suggestedDates.scheduledDate,
      files: [],
    });

    const billId = createBillResponse.object.id;

    await organizationsApi.runBillSync(payment.orgId, billId);

    const createdPayment = await paymentApi.createPayment(payment.orgId, {
      amount: payment.amount,
      orgId: payment.orgId,
      vendorId: payment.vendor.id,
      currency: 'USD',
      scheduledDate: payment.suggestedDates.scheduledDate,
      deliveryEta: payment.suggestedDates.deliveryEta,
      fundingSourceId: payment.fundingSourceId,
      deliveryMethodId: payment.deliveryMethodId,
      note: payment.noteToVendor,
      createOrigin: createOrigin.pay.payment,
      deliveryPreference: payment.suggestedDates.deliveryPreferenceType,
      intuitAccountId: payment.category?.id,
      payBillFlowUUID: payment.trackingBillId,
      billId,
      additionalParams: {
        originFlow: EntrypointActionEnum.JustPay,
        trackingBillId: payment.trackingBillId,
        paymentProcessType: 'create-payment-with-bill',
      },
    });

    return createdPayment.object;
  };

  return { createPayment };
};
