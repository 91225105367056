const colors = {
  primary: {
    50: '#F2F9F1',
    500: '#2CA01C',
    600: '#108002',
    700: '#108000',
  },
  gray: {
    50: '#F4F5F8',
    100: '#E3E5E8',
    200: '#D4D7DC',
    300: '#8D9096',
    400: '#BABEC5',
    500: '#6B6C72',
    600: '#D8D8D8',
    700: '#ECEEF1',
  },
  black: '#393A3D',
  white: '#FFFFFF',
  red: {
    200: '#d52b1e',
    500: '#D52B1E',
    700: '#fbeae9',
  },
  yellow: {
    500: '#FFBB00',
  },
  blue: {
    200: '#055393',
    300: '#0097E6',
    500: '#0077C5',
  },
  green: {
    200: '#2CA01C',
  },
  pink: {
    200: '#C9007A',
  },
};

export type QBOColors = typeof colors;
export default colors;
