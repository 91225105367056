import { FetchNewQBDashboardItemMetadataResponse } from 'src/app/version-2/api/reponses/FetchNewQBDashboardItemMetadataResponse';
import { FetchNewQBDashboardItemMetadataRequest } from 'src/app/version-2/api/requests/FetchNewQBDashboardItemMetadataRequest';
import { fetchRequest } from 'src/app/version-2/externals';

export const billsApi = {
  fetchNewQBDashboardItemMetadata: ({ orgId, params }: FetchNewQBDashboardItemMetadataRequest) =>
    fetchRequest<FetchNewQBDashboardItemMetadataResponse>(
      `/orgs/${orgId}/bills/new-qb-dashboard-item-metadata`,
      params
    ),
};
