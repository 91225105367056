import React, { FC } from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import {
  TooltipContainer,
  RelativeContainer,
  Arrow,
  TextContainer,
} from './BillModalTooltip.styles';

export interface BillModalTooltipProps {
  isOpen: boolean;
  label?: string;
}

export const BillModalTooltip: FC<BillModalTooltipProps> = ({ isOpen, label, children }) => {
  if (!isOpen || !label) return <>{children}</>;

  return (
    <RelativeContainer>
      {children}
      <TooltipContainer>
        <RelativeContainer>
          <Arrow />
          <TextContainer>
            <MIFormattedText label={label} />
          </TextContainer>
        </RelativeContainer>
      </TooltipContainer>
    </RelativeContainer>
  );
};
