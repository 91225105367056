import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import authApi from '../../services/api/auth';
import { withNavigator } from '../../hoc';
import ErrorPage from '../general/components/ErrorPage';
import { UserContextType } from '../../utils/types';
import analytics from '../../services/analytics';
import { initUserAction } from '../../redux/user/actions';

const STATUS = {
  PENDING: 0,
  GO: 1,
  FAILED: 2,
};

type MapDispatchToProps = {
  initUser: (user: UserContextType, isLoggedInAs: boolean) => Promise<void>;
};

type Props = {
  token: string;
} & MapDispatchToProps;

type State = {
  status: number;
};

class LoginTokenRedirect extends React.PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      status: STATUS.PENDING,
    };
  }

  componentDidMount() {
    authApi
      .loginWithToken(this.props.token)
      .then(({ user }) => {
        this.props
          .initUser(user, true)
          .then(() => {
            analytics.identify(user, undefined, { isLoggedInAs: true });
            this.setState({ status: STATUS.GO });
          })
          .catch(() => this.setState({ status: STATUS.FAILED }));
      })
      .catch(() => this.setState({ status: STATUS.FAILED }));
  }

  render() {
    const { status } = this.state;

    if (status === STATUS.GO) {
      return <Redirect to="/" />;
    }

    if (status === STATUS.FAILED) {
      return <ErrorPage />;
    }

    return <div>Please wait...</div>;
  }
}

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  initUser(user: Record<string, any>, isLoggedInAs: boolean) {
    return new Promise((resolve, reject) => {
      dispatch(initUserAction(user, isLoggedInAs, resolve, reject));
    });
  },
});

export default compose(withNavigator(), connect(null, mapDispatchToProps))(LoginTokenRedirect);
