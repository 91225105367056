import { FetchDeliveryTimeResponse } from 'src/app/version-2/api/reponses/FetchDeliveryTimeResponse';
import { FetchFeeResponse } from 'src/app/version-2/api/reponses/FetchFeeResponse';
import { FetchPaymentResponse } from 'src/app/version-2/api/reponses/FetchPaymentResponse';
import { FetchPaymentsResponse } from 'src/app/version-2/api/reponses/FetchPaymentsResponse';
import { FetchDeliveryByDueDate, FetchDeliveryTimeRequest } from 'src/app/version-2/api/requests';
import { fetchRequest, postRequest } from 'src/app/version-2/externals';
import { DataOriginEnum } from 'src/app/version-2/model/enums';
import { dateInCentral } from 'src/app/version-2/utils/dates.utils';

export const paymentsApi = {
  list: ({ orgId, ...params }) =>
    fetchRequest<FetchPaymentsResponse>(`/orgs/${orgId}/payments`, {
      ...params,
      orgId,
    }),
  createPayment: ({ orgId, ...params }) =>
    postRequest(`/orgs/${orgId}/payments`, params).then((data) => data.object),
  fetchFee: ({
    orgId,
    fundingSourceId,
    amount,
    scheduledDate,
    deliveryPreference,
    deliveryMethodId,
    cardFeePaidBy,
    allowDoubleFee = false,
  }) =>
    fetchRequest<FetchFeeResponse>(`/orgs/${orgId}/payments/fee`, {
      amount,
      fundingSourceId,
      scheduledDate,
      deliveryPreference,
      deliveryMethodId,
      cardFeePaidBy,
      allowDoubleFee,
    }),
  fetchDeliveryTime: ({ orgId, ...params }: FetchDeliveryTimeRequest) =>
    fetchRequest<FetchDeliveryTimeResponse>(`/orgs/${orgId}/delivery/date`, {
      ...params,
      scheduledDate: params.scheduledDate
        ? dateInCentral(params.scheduledDate).toISOString()
        : null,
      paymentOrigin: DataOriginEnum.INTUIT,
    }),
  fetchDeliveryByDueDate: ({ orgId, scheduledDate, ...params }: FetchDeliveryByDueDate) =>
    fetchRequest<FetchDeliveryTimeResponse>(`/orgs/${orgId}/delivery/date/latest`, {
      ...params,
      dueDate: scheduledDate ? dateInCentral(scheduledDate).toISOString() : null,
      paymentOrigin: DataOriginEnum.INTUIT,
    }),
  sendPaperCheckPaymentScheduledNotificationToVendor: ({ orgId, paymentId }) =>
    postRequest(`/orgs/${orgId}/payments/${paymentId}/send-edit-check-address`),
  fetchPaymentById: ({ orgId, paymentId }) =>
    fetchRequest<FetchPaymentResponse>(`/orgs/${orgId}/payments/${paymentId}`),
};
