import { HANDLED_SYNC_PAYMENT_ERROR_CODES } from 'src/app/version-2/model/constants/syncPaymentErrorCode.consts';
import { PaymentSyncResultType } from './types';

export function syncPaymentRetry(fn, interval = 1000, retriesLeft = 5) {
  return new Promise<PaymentSyncResultType>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (HANDLED_SYNC_PAYMENT_ERROR_CODES.includes(error?.code) || retriesLeft === 1) {
            reject(error);

            return;
          }

          syncPaymentRetry(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}
