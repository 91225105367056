import { useCallback } from 'react';
import { useQueryIds } from 'src/app/utils/hooks';

type Response = {
  highlightedItemIds: string[];
  setHighlightedItemIds: (ids: string[]) => void;
  removeHighlightedItemIds: () => void;
};

const QUERY_PARAM_NAME = 'highlightedItemIds';

const useDashboardHighlightedItems = (): Response => {
  const [highlightedItemIds, , setHighlightedItemIds, clearHighlightedItemIds] =
    useQueryIds(QUERY_PARAM_NAME);

  const removeHighlightedItemIds = useCallback(() => {
    if (highlightedItemIds) {
      clearHighlightedItemIds();
    }
  }, [highlightedItemIds, clearHighlightedItemIds]);

  return {
    highlightedItemIds,
    setHighlightedItemIds,
    removeHighlightedItemIds,
  };
};

export { useDashboardHighlightedItems };
