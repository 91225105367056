import { Record } from 'immutable';
import { RegistrationFlowEnum, RegistrationOriginEnum } from 'src/app/version-2/model/enums';
import { UserContextType, UserPreferencesType } from '../utils/types';

export const UserContextRecord = Record<UserContextType>(
  {
    email: '',
    isEmailVerified: false,
    id: null,
    isGuest: false,
    name: '',
    orgId: '',
    orgName: '',
    userPreferences: null,
    organizations: [],
    registrationOrigin: RegistrationOriginEnum.APP,
    registrationFlow: RegistrationFlowEnum.REGULAR,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
  },
  'UserContextRecord'
);

export const UserPreferencesRecord = Record<UserPreferencesType>(
  {
    isClosedBillsZeroState: false,
    isClosedRequestsZeroState: false,
    allowCompaniesSwitcher: false,
    isQBRUnilateral: false,
    qbDashboardSeen: true,
    showQbDashboardInternalEntrypoints: false,
    hideBatchPaymentsHolidaysNotification: false,
    qbDashboardSwitcherManageModalSeen: false,
    qbDashboardSwitcherTooltipSeen: false,
    isJustPayIntuitEntrypointAware: false,
    qbExistingDebitCardFeeModal: false,
    qbExistingCheckFeeModal: false,
    checkFeesNotificationCount: null,
    checkFeesPromotionsEndedCount: null,
    batchBulkOnboardingSeen: false,
    qbBatchBulkUncombinedSeen: false,
    fundingSourceCCTooltipSeen: false,
    lastNotificationIdSeen: null,
  },
  'UserPreferencesRecord'
);
