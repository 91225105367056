import React from 'react';
import { getCardLogo, qbCashOriginIcon } from 'src/app/utils/card';
import { FUNDING_DEFAULT_LOGO } from 'src/app/version-2/model/constants';
import {
  FundingSourceTypesEnum,
  FundingSourceOrigins,
  CardNetworkEnum,
  CardTypeEnum,
} from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

type FundingSourceIconProps = {
  fundingSource?: FundingSource;
};

export const FundingSourceInfoIcon = ({ fundingSource }: FundingSourceIconProps) => {
  const bankIcon =
    fundingSource?.fundingType === FundingSourceTypesEnum.ACH &&
    fundingSource.logo &&
    fundingSource.logo !== FUNDING_DEFAULT_LOGO ? (
      <img src={`data:image/jpeg;base64,${fundingSource.logo}`} alt="funding source icon" />
    ) : (
      <div className="icon-bank-icon" />
    );
  const cardType = fundingSource?.cardAccount?.cardType || '';
  const network = (fundingSource?.cardAccount?.network || '').toUpperCase();
  let icon = bankIcon;

  if (
    fundingSource?.fundingType === FundingSourceTypesEnum.ACH &&
    fundingSource.origin === FundingSourceOrigins.QBCASH
  ) {
    icon = qbCashOriginIcon;
  }

  if (fundingSource && cardType === CardTypeEnum.CREDIT) {
    icon = getCardLogo(cardType, network as CardNetworkEnum);
  }

  if (fundingSource && cardType === CardTypeEnum.DEBIT) {
    icon = getCardLogo(cardType, network as CardNetworkEnum);
  }

  return icon;
};
