import { ReactNode } from 'react';
import { Box } from '@melio/billpay-design-system';
import { modalHeaderTitleStyle } from './Modal.style';

type Props = {
  children: ReactNode;
  isCentred?: boolean;
};

export const ModalHeaderTitle = ({ children, isCentred }: Props) => (
  <Box __css={modalHeaderTitleStyle(isCentred)} data-testid="header-title-text">
    {children}
  </Box>
);
