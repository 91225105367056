import { ADDRESS_REGEXP } from 'src/app/version-2/pages/global-opt-in-details/model/consts/optInDetailsValidation.consts';
import { FcbOptionValuesEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/FcbOptionValuesEnum.enum';
import { globalOptInFormFieldNamesEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInFormFieldNamesEnum.enum';
import { ROUTING_NUMBER_REGEXP } from 'src/app/version-2/utils/validationRegex.utils';
import { routingChecksumTest } from './rules/routingChecksumTest';

import { object, string } from 'yup';

export const businessDetailsSchema = object()
  .shape({
    [globalOptInFormFieldNamesEnum.fcbStatus]: string().required(
      'vendors.globalOptInCandidates.validation.businessName'
    ),
    [globalOptInFormFieldNamesEnum.inputValue]: string().nullable(),
    [globalOptInFormFieldNamesEnum.formattedAddress]: string().nullable(),
    [globalOptInFormFieldNamesEnum.businessName]: string()
      .trim()
      .required('vendors.globalOptInCandidates.validation.businessName'),
    [globalOptInFormFieldNamesEnum.googlePlaceId]: string().when(
      ['inputValue', 'formattedAddress'],
      {
        is: (inputValue: string, formattedAddress: string) => inputValue !== formattedAddress,
        then: string()
          .required('vendors.globalOptInCandidates.validation.addressSelect')
          .matches(ADDRESS_REGEXP, 'vendors.globalOptInCandidates.validation.addressSelect'),
        otherwise: string().required('vendors.globalOptInCandidates.validation.address'),
      }
    ),
    [globalOptInFormFieldNamesEnum.routingNumber]: string()
      .nullable()
      .transform((currentValue, originalValue) => (currentValue === '' ? null : originalValue))
      .when('fcbStatus', {
        is: (fcbStatus) => fcbStatus === FcbOptionValuesEnum.PartOfLargerOrganization,
        then: string()
          .transform((currentValue, originalValue) => (currentValue === '' ? null : originalValue))
          .required('vendors.globalOptInCandidates.validation.routingNumber')
          .min(9, 'vendors.globalOptInCandidates.validation.routingNumberMin')
          .test(routingChecksumTest)
          .matches(
            ROUTING_NUMBER_REGEXP,
            'vendors.globalOptInCandidates.validation.routingNumberMin'
          ),
        otherwise: string()
          .nullable()
          .matches(
            ROUTING_NUMBER_REGEXP,
            'vendors.globalOptInCandidates.validation.routingNumberMin'
          )
          .min(9, 'vendors.globalOptInCandidates.validation.routingNumberMin'),
      }),

    [globalOptInFormFieldNamesEnum.accountNumber]: string()
      .nullable()
      .transform((currentValue, originalValue) => (currentValue === '' ? null : originalValue))
      .min(6, 'vendors.globalOptInCandidates.validation.accountNumberMin')
      .max(17, 'vendors.globalOptInCandidates.validation.accountNumberMax')
      .when('fcbStatus', {
        is: (fcbStatus) => fcbStatus === FcbOptionValuesEnum.PartOfLargerOrganization,
        then: string()
          .required('vendors.globalOptInCandidates.validation.accountNumber')
          .transform((currentValue, originalValue) => (currentValue === '' ? null : originalValue))
          .min(6, 'vendors.globalOptInCandidates.validation.accountNumberMin')
          .max(17, 'vendors.globalOptInCandidates.validation.accountNumberMax'),
      }),

    [globalOptInFormFieldNamesEnum.addressLine1]: string().nullable(),
    [globalOptInFormFieldNamesEnum.addressLine2]: string().nullable(),
    [globalOptInFormFieldNamesEnum.city]: string().nullable(),
    [globalOptInFormFieldNamesEnum.countryCode]: string().nullable(),
    [globalOptInFormFieldNamesEnum.aptNumber]: string().nullable(),
    [globalOptInFormFieldNamesEnum.state]: string().nullable(),
    [globalOptInFormFieldNamesEnum.zipCode]: string().nullable(),
  })
  .required();
