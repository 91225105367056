import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, generatePath, useLocation } from 'react-router-dom';
import { getOrgId } from 'src/app/redux/user/selectors';
import locations from 'src/app/pages/locations';
import analytics from 'src/app/services/analytics';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import billsStore from 'src/app/modules/bills/bills-store';
import { useSelectedBillsMetadata } from '../components/QBODashboardFooter/hooks/useSelectedBillsMetadata';
import { BillType } from '../../../utils/types';

const useScheduleBatchPaymentsAction = (selectedBillsIds: string[]) => {
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const bills: BillType[] = useSelector(billsStore.selectors.getByIds(selectedBillsIds));
  const billsToSync = bills.map((bill) => ({
    id: bill.id,
    originId: bill.originId,
    amount: bill.balance,
  }));

  const goScheduleBatchPayments = useCallback(() => {
    const exitUrl = `${location.pathname}${location.search}`;

    dispatch(batchBulkActions.setHasBeenUncombinedByFeature(false));
    const url = generatePath(locations.qbDashboard.syncBillsByOrigin, {
      orgId,
    });

    analytics.trackAction('batch-actions', {
      action: 'schedule-batch',
      billIds: selectedBillsIds,
    });

    history.push(url, {
      exitUrl,
      billsToSync,
    });
  }, [orgId, location.pathname, location.search, billsToSync, selectedBillsIds, history]);

  return [goScheduleBatchPayments];
};

export default useScheduleBatchPaymentsAction;
