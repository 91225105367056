import { postRequest, putRequest, fetchRequest } from './api';

export default {
  createDeliveryMethod(params) {
    return postRequest('/unilateral-payments/create-delivery-method', params).then(
      (res) => res.deliveryMethod
    );
  },
  copyFromOwnedVendor(params) {
    return postRequest('/unilateral-payments/copy-from-owned-vendor', params).then(
      (res) => res.deliveryMethod
    );
  },
  updateDeliveryMethod({ id, token, deliveryMethod }) {
    return putRequest(`/unilateral-payments/delivery-methods/${id}`, {
      token,
      deliveryMethod,
    }).then((res) => res.deliveryMethod);
  },
  sendFillDeliveryMethodEmail({ vendorId, userId, orgId }) {
    return postRequest('/unilateral-payments/send-fill-delivery-method-email', {
      vendorId,
      userId,
      orgId,
    });
  },
  getRequestDataWithToken(params) {
    const url = '/unilateral-payments/fetch-request-data';

    return fetchRequest(url, params);
  },
};
