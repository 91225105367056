import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { CollectInvoice, Country } from 'src/app/version-2/model/dtos';

export const COLLECT_INVOICE_SLICE = `${VERSION_2}collectInvoice`;

interface collectInvoiceSlice {
  vendors: EntityState<CollectInvoice>;
}

export const selectState = (state: any) => state[COLLECT_INVOICE_SLICE];

const collectInvoiceAdapter = createEntityAdapter<CollectInvoice>({
  selectId: (invoice) => invoice.vendorId,
});

export const collectInvoiceInitialState: collectInvoiceSlice = {
  vendors: collectInvoiceAdapter.getInitialState(),
};

export const collectInvoiceSlice = createSlice({
  name: COLLECT_INVOICE_SLICE,
  initialState: collectInvoiceInitialState,
  reducers: {
    setShowInvoice: (state, action) => {
      collectInvoiceAdapter.setOne(state.vendors, action.payload);
    },
  },
});

export const collectInvoiceSelectors = {
  selectInvoicetByVendorId: (vendorId: string) =>
    createSelector(selectState, (state) =>
      collectInvoiceAdapter.getSelectors().selectById(state.vendors, vendorId)
    ),
  selectShowInvoicetByVendorId: (vendorId: string) =>
    createSelector(
      selectState,
      (state) =>
        !!collectInvoiceAdapter.getSelectors().selectById(state.vendors, vendorId)?.showInvoice
    ),
};

export const collectInvoiceActions = {
  ...collectInvoiceSlice.actions,
  fetchCollectInvoice: createAction<{
    vendorId: string;
    countryCode: string;
    countries: Country[];
    billId: string;
  }>(`${COLLECT_INVOICE_SLICE}/fetch-collect-invoice`),
  fetchFirstPayment: createAction<{
    vendorId: string;
  }>(`${COLLECT_INVOICE_SLICE}/fetch-first-payment`),
};
