import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Input, Modal, Text } from '@melio/billpay-design-system';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { validateVendorEmail } from 'src/app/version-2/utils/validate-vendor-email';

export interface VendorEmailValidationErrors {
  contactEmail?: string;
}

interface AddVendorEmailModalProps {
  isOpen: boolean;
}

export const AddVendorEmailModal: FC<AddVendorEmailModalProps> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const paymentIntent = useSelector(
    batchBulkSelectors.selectAddEmailModalBillDTO
  ) as BatchBulkPaymentIntent;

  const [contactEmail, setContactEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState<VendorEmailValidationErrors>({
    contactEmail: undefined,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const handleChange = (event) => setContactEmail(event.target.value);

  const closeModal = () => {
    dispatch(batchBulkActions.setAddEmailModalAsClosed());
  };
  const handleButtonClick = () => {
    const { validationErrors, isValid } = validateVendorEmail(contactEmail);

    setValidationErrors(validationErrors);

    if (!isValid) {
      return;
    }

    onSubmit(contactEmail);
  };

  const onSubmit = (contactEmail: string) => {
    setIsProcessing(true);
    dispatch(
      batchBulkActions.updateVendorEmail({
        contactEmail,
        paymentIntentId: paymentIntent.id,
      })
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>
        <Modal.HeaderTitle>
          {formatMessage({ id: 'batchBulkPage.modals.addVendorEmail.title' })}
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.CloseButton />
      <Modal.Body>
        <Box>
          <Text>{formatMessage({ id: 'batchBulkPage.modals.addVendorEmail.text' })}</Text>
          <Input
            variant="flushed"
            type="email"
            name="email"
            label={formatMessage({ id: 'batchBulkPage.modals.addVendorEmail.input.label' })}
            placeholder={formatMessage({
              id: 'batchBulkPage.modals.addVendorEmail.input.placeholder',
            })}
            onChange={handleChange}
            errorMessage={
              validationErrors?.contactEmail && formatMessage({ id: validationErrors.contactEmail })
            }
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonContent isCentred>
          <Modal.Button
            isLoading={isProcessing}
            disabled={isProcessing}
            onClick={handleButtonClick}
            size="md"
            variant="primary"
          >
            {formatMessage({ id: 'batchBulkPage.modals.addVendorEmail.button' })}
          </Modal.Button>
        </Modal.ButtonContent>
      </Modal.Footer>
    </Modal>
  );
};
