import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';

import InvalidLinkPage from '../components/InvalidLinkPage';
import CheckBlockedPage from '../edit-check-adress/CheckBlockedPage';
import { VendorPaymentDetailsPage } from './VendorPaymentDetailsPage';
import locations from './locations';
import { ExpediteItemContextProvider } from 'src/app/pages/vendor/vendor-payment-tracking/context/ExpediteItemContext';

const VendorPaymentTrackingRouter = () => {
  const match = useRouteMatch<{ token?: string }>();
  const token = match.params?.token || '';

  return (
    <Switch>
      <SmartRoute path={locations.base} exact>
        <ExpediteItemContextProvider token={token}>
          <VendorPaymentDetailsPage token={token} />
        </ExpediteItemContextProvider>
      </SmartRoute>
      <SmartRoute path={locations.blockedPayment} exact>
        <CheckBlockedPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.invalidLink} exact>
        <InvalidLinkPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

export { VendorPaymentTrackingRouter };
