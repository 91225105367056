import * as React from 'react';
import styled, { css } from 'styled-components';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { OnInputChange, Option } from '../../../components/common/MISingleSelect';
import BillCreateOptionsContainer from './BillCreateOptionsContainer';
import NewBillPage from './NewBillPage';
import { devices } from '../../../theme/AppDevices';
import { BillFileLoader } from 'src/app/components/bill/BillFileLoader';
import type { BillType, FieldType } from '../../../utils/types';
import { BillFrequencyEnum } from 'src/app/version-2/model/enums';

type Props = {
  vendors: Option[];
  filteredVendors?: Option[];
  onVendorsInputChange?: OnInputChange;
  intuitAccountsOptions: Option[];
  vendorAccountIdentifier?: string;
  orgId: string;
  handleUploadStart: () => void;
  handleUploadCancel: () => void;
  onFieldChange: (field: FieldType) => void;
  onItemRemove: (index: number) => void;
  onChange: (field: FieldType) => void;
  addNewItem: () => void;
  isUploading: boolean;
  onUploadRemove: () => void;
  goWithUploadedBill: (file: File, orgId: string) => void;
  fileStorageUrl: string;
  filePreviewUrls: string[];
  bill: BillType;
  validationErrors: Record<string, any>;
  goExit: () => void;
  goExitManually: () => void;
  onSubmitBill: (shouldReturnToBillsDashboard: boolean) => void;
  isProcessing: boolean;
  isRecurring: boolean;
  manually?: boolean;
  handleUploadError: () => void;
  handleRetry: () => void;
  handleUploadSuccess: (file: File) => void;
  occurrences: string;
  frequency: BillFrequencyEnum;
  onDeleteAttachment: () => void;
  fileName: string;
  isUploadError: boolean;
  isFirstWave?: boolean;
};

const NewBillMainPage = ({
  vendors,
  filteredVendors,
  onVendorsInputChange,
  intuitAccountsOptions,
  vendorAccountIdentifier,
  orgId,
  handleUploadStart,
  handleUploadCancel,
  onFieldChange,
  onItemRemove,
  addNewItem,
  isUploading,
  onUploadRemove,
  goWithUploadedBill,
  fileStorageUrl,
  filePreviewUrls,
  bill,
  validationErrors,
  goExit,
  goExitManually,
  onSubmitBill,
  isProcessing,
  manually,
  handleUploadError,
  onChange,
  isRecurring,
  occurrences,
  frequency,
  onDeleteAttachment,
  handleUploadSuccess,
  handleRetry,
  fileName,
  isUploadError,
  isFirstWave,
}: Props) => (
  <NewBillMainPageContainer>
    {!manually && (
      <LeftContainer isUploading={isUploading}>
        <UploadArea isUploading={isUploading} fileStorageUrl={fileStorageUrl}>
          {isUploading ? (
            <BillFileLoader onCancelAction={handleUploadCancel} />
          ) : (
            <BillCreateOptionsContainer
              orgId={orgId}
              fileStorageUrl={fileStorageUrl}
              filePreviewUrls={filePreviewUrls}
              handleUploadStart={handleUploadStart}
              onUploadRemove={onUploadRemove}
              goWithUploadedBill={goWithUploadedBill}
              handleUploadError={handleUploadError}
            />
          )}
        </UploadArea>
      </LeftContainer>
    )}
    <RightContainer isUploading={isUploading} manually={manually}>
      <NewBillPage
        occurrences={occurrences}
        frequency={frequency}
        orgId={orgId}
        isUploading={isUploading}
        vendors={vendors}
        filteredVendors={filteredVendors}
        onVendorsInputChange={onVendorsInputChange}
        intuitAccountsOptions={intuitAccountsOptions}
        vendorAccountIdentifier={vendorAccountIdentifier}
        onChange={onChange}
        bill={bill}
        onFieldChange={onFieldChange}
        isRecurring={isRecurring}
        onItemRemove={onItemRemove}
        addNewItem={addNewItem}
        validationErrors={validationErrors}
        goExit={goExit}
        goExitManually={goExitManually}
        onAddBill={onSubmitBill}
        isProcessing={isProcessing}
        manually={manually}
        onDeleteAttachment={onDeleteAttachment}
        fileName={fileName}
        isUploadError={isUploadError}
        handleUploadStart={handleUploadStart}
        goWithUploadedBill={goWithUploadedBill}
        handleUploadError={handleUploadError}
        handleRetry={handleRetry}
        handleUploadSuccess={handleUploadSuccess}
        isFirstWave={isFirstWave}
      />
      <QBOFooterWrapper>
        <QBOFooterContainer />
      </QBOFooterWrapper>
    </RightContainer>
  </NewBillMainPageContainer>
);

NewBillMainPage.defaultProps = {
  manually: false,
};

export default NewBillMainPage;

const NewBillMainPageContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;

  @media ${devices.nonDesktop} {
    flex-direction: column;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 7rem;
    overflow: inherit;
  }

  ${(props) => props.theme?.pages?.NewBillMainPage?.NewBillMainPageContainer}
`;

const LeftContainer = styled.div<{ isUploading?: boolean; manually?: boolean }>`
  display: flex;
  width: 60%;
  padding: 4.6rem;
  border-right: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  box-sizing: border-box;
  position: relative;

  @media ${devices.nonDesktop} {
    width: 100%;
    height: 63rem;
    border-right: none;
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  }

  @media ${devices.tablet} {
    height: 63rem;
    padding: 7.3rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2rem;
    ${(props) =>
      props.isUploading &&
      css`
        border: none;
      `}

    ${(props) =>
      !props.isUploading &&
      css`
        height: 24rem;
        padding-top: 1.5rem;
      `}
  }
  ${(props) => props.theme?.pages?.NewBillMainPage?.LeftContainer}
`;

const RightContainer = styled.div<{ isUploading?: boolean; manually?: boolean }>`
  width: ${(props) => (props.manually ? '100%' : '40%')};
  margin: 0;
  height: unset;
  box-sizing: border-box;

  @media ${devices.desktop} {
    overflow: auto;
    position: relative;
  }

  @media ${devices.tablet} {
    height: ${(props) => (props.manually ? '100vh' : 'unset')};
    overflow: ${(props) => (props.manually ? 'auto' : 'unset')};
  }

  @media ${devices.nonDesktop} {
    width: 100%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0 2rem;
    display: ${(props) => (props.isUploading && !props.manually ? 'none' : 'flex')};
  }

  ${(props) => props.theme?.pages?.NewBillMainPage?.RightContainer}
`;

const UploadArea = styled.div<{ isUploading?: boolean; fileStorageUrl?: string }>`
  height: 80vh;
  border: ${(props) => (props.fileStorageUrl ? '0.2rem dashed' : '0.1rem solid')};
  border-color: ${(props) => props.theme.colors.border.darkGrey};
  border-radius: 0.6rem;
  max-width: 60rem;
  width: 100%;
  margin: auto;
  position: relative;

  @media ${devices.nonDesktop} {
    height: 100%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    ${(props) =>
      props.isUploading &&
      css`
        border: none;
      `}

    ${(props) =>
      !props.isUploading &&
      css`
        height: unset;
        margin: 2.2rem 2.2rem 3.2rem 2.2rem;
        overflow: hidden;
      `}
  }
`;

const QBOFooterWrapper = styled.div`
  display: none;
  ${(props) => props.theme?.pages?.NewBillMainPage?.QBOFooterWrapper}
`;
