import { RecordOf } from 'immutable';
import { featureFlags } from '@melio/shared-web';
import some from 'lodash/some';
import find from 'lodash/find';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BillType, DeliveryMethodType, OrganizationPreferencesType } from 'src/app/utils/types';
import { useIsInternationalOn } from 'src/app/pages/vendor/delivery-methods/international/hooks/useIsInternationalOn';
import { isSharedVendor } from 'src/app/pages/vendor/utils';
import { VENDOR_TYPES, DELIVERY_TYPE } from 'src/app/utils/consts';
import { geInternationalStaticFee } from 'src/app/utils/international';
import { getInternationalDisabledText } from 'src/app/version-2/utils/international.utils';
import {
  organizationSelectors,
  organizationActions,
} from 'src/app/version-2/modules/organization/organization.slice';
import { getNotice } from 'src/app/version-2/utils/checkFees.utils';
import { getVendorType } from 'src/app/utils/vendor-utils';
import { AddFundingSourceWizardOriginEnum } from 'src/app/version-2/model/enums';
import { isBatchHasPartialPayment } from 'src/app/version-2/utils/bills.utils';
import { isBillHasPartialPayments, isRecurringRecord } from 'src/app/utils/bills';
import { isEligibleToReceiveVirtualCard } from 'src/app/utils/payments';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { FundingSource } from 'src/app/version-2/model/dtos';

export type DeliveryMethodConfig = {
  required: boolean;
  selected: boolean;
  deliveryMethod: DeliveryMethodType;
  only?: boolean;
  disabled?: boolean;
  metadata?: Record<string, any>;
};

export type VisibilityOptions = {
  showAllDMs: boolean;
  showDomesticDM: boolean;
  showInternationalDM: boolean;
};

type Props = {
  amount: string;
  bill: RecordOf<BillType>;
  selectedFundingSource: FundingSource | undefined;
  selectedDeliveryMethodType: string | undefined;
  combined?: boolean;
  selectedDeliveryMethod?: DeliveryMethodType;
};

type Response = {
  visibilityOptions: VisibilityOptions;
  internationalConfig: DeliveryMethodConfig;
  virtualConfig: DeliveryMethodConfig;
  vitrualCardConfig: DeliveryMethodConfig;
  achConfig: DeliveryMethodConfig;
  checkConfig: DeliveryMethodConfig;
};

type GetInternationalDMConfig = {
  amount: string;
  bill: RecordOf<BillType>;
  selectedFundingSource: FundingSource | undefined;
  selectedDeliveryMethodType: string | undefined;
  preservedState: Record<string, any>;
  isBatchBulkFlow: boolean;
  isEligibleForInternationalPayment: boolean;
};

const useGetInternationalDMConfig = ({
  amount,
  bill,
  selectedFundingSource,
  selectedDeliveryMethodType,
  preservedState,
  isBatchBulkFlow,
  isEligibleForInternationalPayment,
}: GetInternationalDMConfig): DeliveryMethodConfig => {
  const batchBillHasPartialPayments = isBatchBulkFlow
    ? isBatchHasPartialPayment({ preservedState, billId: bill.id, amount })
    : false;
  const billHasPartialPayments =
    isBillHasPartialPayments(bill) ||
    batchBillHasPartialPayments ||
    Number(amount) !== Number(bill.totalAmount);
  const internationalDisabledText = getInternationalDisabledText({
    fundingSource: selectedFundingSource,
    billHasPartialPayments,
    isEligibleForInternationalPayment,
  });
  const internationalStaticFee = geInternationalStaticFee();

  const required = useIsInternationalOn();
  const selected = selectedDeliveryMethodType === DELIVERY_TYPE.INTERNATIONAL;
  const disabled = !!internationalDisabledText;

  const deliveryMethod = bill?.getDeliveryMethodByType(DELIVERY_TYPE.INTERNATIONAL);

  return {
    required,
    selected,
    deliveryMethod,
    disabled,
    metadata: { fee: internationalStaticFee, description: internationalDisabledText },
  };
};

const getVirtualDMConfig = ({
  bill,
  selectedFundingSource,
  selectedDeliveryMethodType,
  combined,
}: {
  bill: RecordOf<BillType>;
  selectedFundingSource: FundingSource | undefined;
  selectedDeliveryMethodType: string | undefined;
  combined: boolean;
}): DeliveryMethodConfig => {
  const vendorHasVirtualDM = some(
    bill.vendor?.deliveryMethods,
    (dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL
  );
  const isRecurring = isRecurringRecord(bill);

  const required =
    !isRecurring &&
    (selectedFundingSource?.isVerified ||
      (!selectedFundingSource?.isVerified && vendorHasVirtualDM) ||
      (combined && !selectedFundingSource));
  const selected = combined ? selectedDeliveryMethodType === DELIVERY_TYPE.VIRTUAL : false;

  const virtualDeliveryMethod = find(
    bill.vendor?.deliveryMethods,
    (dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL
  );
  const deliveryMethod =
    combined && virtualDeliveryMethod
      ? {
          ...virtualDeliveryMethod,
          getDisplayName: () => DELIVERY_TYPE.VIRTUAL,
          getDeliveryInfo: () => '',
          getDeliveryTypeDescription: () => '',
        }
      : {
          id: DELIVERY_TYPE.VIRTUAL,
          deliveryType: DELIVERY_TYPE.VIRTUAL,
          logo: '',
          hasScheduledPayments: false,
          isFilledByVendor: false,
          getDisplayName: () => DELIVERY_TYPE.VIRTUAL,
          getDeliveryInfo: () => '',
          getDeliveryTypeDescription: () => '',
          intuitAccountId: null,
        };

  return { required, selected, deliveryMethod };
};

const useVirtualCardDMConfig = ({
  bill,
  selectedFundingSource,
  selectedDeliveryMethodType,
}: {
  bill: RecordOf<BillType>;
  selectedFundingSource: FundingSource | undefined;
  selectedDeliveryMethodType: string | undefined;
}): DeliveryMethodConfig => {
  const [bpVirtualCardOptInSingleBillPayFlow] = featureFlags.useFeature(
    'bp-virtual-card-opt-in-single-bill-pay-flow',
    false
  );

  const selected = selectedDeliveryMethodType === DELIVERY_TYPE.VIRTUAL_CARD;
  const disabled = !isEligibleToReceiveVirtualCard(selectedFundingSource);

  const virtualCardDeliveryMethod = find(
    bill.vendor?.deliveryMethods,
    (dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD
  );
  const deliveryMethod = {
    ...virtualCardDeliveryMethod,
    deliveryType: DELIVERY_TYPE.VIRTUAL_CARD,
    getDeliveryInfo: () => virtualCardDeliveryMethod?.virtualCardAccount?.accountEmail || '',
    getDisplayName: () => virtualCardDeliveryMethod?.virtualCardAccount?.accountEmail,
  } as DeliveryMethodType;

  const only =
    !disabled && !!virtualCardDeliveryMethod && virtualCardDeliveryMethod.isFilledByVendor;

  return {
    required: bpVirtualCardOptInSingleBillPayFlow,
    selected,
    disabled,
    deliveryMethod,
    only,
  };
};

const getACHDMConfig = ({
  bill,
  selectedDeliveryMethodType,
  selectedDeliveryMethod,
}: {
  bill: RecordOf<BillType>;
  selectedDeliveryMethodType: string | undefined;
  selectedDeliveryMethod?: DeliveryMethodType;
}): DeliveryMethodConfig => {
  const selected = selectedDeliveryMethodType === DELIVERY_TYPE.ACH;
  let deliveryMethod = bill.getDeliveryMethodByType(DELIVERY_TYPE.ACH);

  if (selectedDeliveryMethod?.deliveryType === DELIVERY_TYPE.ACH) {
    deliveryMethod = bill.getDeliveryMethodById(selectedDeliveryMethod.id);
  }

  const only = isSharedVendor(bill.vendor);

  return { required: true, selected, deliveryMethod, only };
};

const useCheckDMConfig = ({
  bill,
  selectedDeliveryMethodType,
  selectedFundingSource,
  selectedDeliveryMethod,
}: {
  bill: RecordOf<BillType>;
  selectedDeliveryMethodType: string | undefined;
  selectedFundingSource: FundingSource | undefined;
  selectedDeliveryMethod?: DeliveryMethodType;
}): DeliveryMethodConfig => {
  const dispatch = useDispatch();
  const catalog = useSelector(organizationSelectors.selectFeeCatalog);

  const selected = selectedDeliveryMethodType === DELIVERY_TYPE.CHECK;
  let deliveryMethod = bill.getDeliveryMethodByType(DELIVERY_TYPE.CHECK);

  if (selectedDeliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK) {
    deliveryMethod = bill.getDeliveryMethodById(selectedDeliveryMethod.id);
  }

  const onModalOpen = () => dispatch(organizationActions.setIsCheckFeeModalOpen(true));
  const { description, descriptionValues } = getNotice({
    fundingSource: selectedFundingSource,
    catalog,
    onModalOpen,
  });

  return { required: true, selected, deliveryMethod, metadata: { description, descriptionValues } };
};

const getVisibilityOptions = ({ bill }: { bill: RecordOf<BillType> }): VisibilityOptions => {
  const vendorType = getVendorType(bill.vendor);
  const showAllDMs = vendorType === VENDOR_TYPES.NOT_DETERMINED;
  const showDomesticDM = vendorType === VENDOR_TYPES.DOMESTIC;
  const showInternationalDM = vendorType === VENDOR_TYPES.INTERNATIONAL;

  return { showAllDMs, showDomesticDM, showInternationalDM };
};

const useDeliveryMethodListConfig = ({
  amount,
  bill,
  selectedFundingSource,
  selectedDeliveryMethodType,
  combined = false,
  selectedDeliveryMethod,
}: Props): Response => {
  const { preservedState } = useLocation<any>()?.state || {};
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const isBatchBulkFlow = preservedState?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK;

  const vitrualCardConfig = useVirtualCardDMConfig({
    bill,
    selectedFundingSource,
    selectedDeliveryMethodType,
  });
  const internationalConfig = useGetInternationalDMConfig({
    amount,
    bill,
    selectedFundingSource,
    selectedDeliveryMethodType,
    preservedState,
    isBatchBulkFlow,
    isEligibleForInternationalPayment: !!organizationPreferences.isEligibleForInternationalPayment,
  });
  const virtualConfig = getVirtualDMConfig({
    bill,
    selectedFundingSource,
    selectedDeliveryMethodType,
    combined,
  });
  const achConfig = getACHDMConfig({
    bill,
    selectedDeliveryMethodType,
    selectedDeliveryMethod,
  });
  const checkConfig = useCheckDMConfig({
    bill,
    selectedDeliveryMethodType,
    selectedFundingSource,
    selectedDeliveryMethod,
  });

  const visibilityOptions = getVisibilityOptions({ bill });

  return {
    visibilityOptions,
    internationalConfig,
    virtualConfig,
    vitrualCardConfig,
    achConfig,
    checkConfig,
  };
};

export { useDeliveryMethodListConfig };
