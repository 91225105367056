import { OrganizationBillingFee } from 'src/app/utils/types';
import { FUNDING_SOURCE_ORIGIN } from 'src/app/utils/consts';
import { getCardIcon, getCardImageUrl } from 'src/app/utils/card';
import qbCashBankOrigin from 'src/app/images/icons/qb-cash-bank-origin.svg';
import { FUNDING_DEFAULT_LOGO } from 'src/app/version-2/model/constants';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { ExistingOwnFundingSource } from './existing-own-funding-source';
import { ExistingFirmFundingSource } from './existing-firm-funding-source';
import { EmptyFundingSource } from './empty-funding-source';

export const getFundingSourceCard = (
  allFundingSources: any[],
  feeFundingSource?: OrganizationBillingFee
): any => {
  const crucialMismatchWithFundingSource = !allFundingSources?.find(
    (fs) => fs.id === feeFundingSource?.fundingSourceId
  );
  const notFirmFS = !feeFundingSource?.managedByOrganizationId;

  if (!feeFundingSource || (crucialMismatchWithFundingSource && notFirmFS))
    return EmptyFundingSource;

  if (feeFundingSource.managedByOrganizationId) return ExistingFirmFundingSource;

  return ExistingOwnFundingSource;
};

export function getImages(fundingSource, isIconOptional = true) {
  const hasLogo = fundingSource.logo !== '' && fundingSource.logo !== FUNDING_DEFAULT_LOGO;

  if (fundingSource.fundingType === FundingSourceTypesEnum.ACH) {
    if (fundingSource.origin === FUNDING_SOURCE_ORIGIN.QBCASH) {
      return { icon: '', imageSrc: qbCashBankOrigin };
    }

    const imageSrc = hasLogo ? `data:image/jpeg;base64,${fundingSource.logo}` : '';
    const icon = imageSrc && isIconOptional ? '' : 'icon-bank-icon';

    return { icon, imageSrc };
  }

  return {
    icon: getCardIcon(fundingSource),
    imageSrc: getCardImageUrl(fundingSource),
  };
}
