const parts = ['field'];

const baseStyle = {
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    boxSizing: 'border-box',
    fontFamily: 'body',

    borderColor: 'ds.gray.100',
    color: 'ds.gray.100',

    _placeholder: {
      color: 'ds.gray.400',
    },

    "&[type='number']": {
      MozAppearance: 'textfield',
    },
    "&[type='number']::-webkit-inner-spin-button, &[type='number']::-webkit-outer-spin-button": {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      appearance: 'none',
      margin: 0,
    },
  },
  icon: {},
};

const size = {
  md: {
    textStyle: 'ds.body1',
    fontWeight: 'ds.medium',
    px: 'ds.md',
    h: '3rem',
    borderRadius: 'ds.sm',
  },
};

const sizes = {
  md: {
    field: size.md,
    addon: size.md,
  },
};

const variantFlushed = () => ({
  field: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: 'ds.gray.500',
    borderRadius: 0,
    bg: 'inherit',
    px: 0,

    _hover: {
      borderColor: 'ds.gray.500',
    },
    _focus: {
      borderColor: 'ds.gray.100',
    },
    _readOnly: {
      boxShadow: 'none !important',
      userSelect: 'all',
      color: 'ds.gray.300',
      borderWidth: 0,
    },
    _disabled: {
      cursor: 'not-allowed',
      borderColor: 'ds.gray.500',
      color: 'ds.gray.500',
      bg: 'transparent',
      _placeholder: {
        color: 'ds.gray.600',
      },
    },
    _invalid: {
      borderColor: 'ds.red.200',
    },
  },
});

const variantOutline = () => ({
  field: {
    borderWidth: 1,
    borderStyle: 'solid',
    bg: 'inherit',
    borderColor: 'ds.gray.400',

    _hover: {
      borderColor: 'ds.gray.400',
    },
    _focus: {
      borderColor: 'ds.gray.100',
    },
    _readOnly: {
      boxShadow: 'none !important',
      userSelect: 'all',
      color: 'ds.gray.300',
      borderWidth: 0,
    },
    _disabled: {
      cursor: 'not-allowed',
      borderColor: 'ds.gray.400',
      color: 'ds.gray.400',
      _placeholder: {
        color: 'ds.gray.600',
      },
    },
    _invalid: {
      borderColor: 'ds.red.200',
    },
  },
});

const variants = {
  outline: variantOutline,
  flushed: variantFlushed,
};

const defaultProps = {
  size: 'md',
  variant: 'flushed',
};

export const InputTheme = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
