import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${(props) => props.theme.background.default};
    font-family: ${(props) => props.theme.fontFamily};
  }
  body > span {
    font-family: ${(props) => props.theme.fontFamily} !important;
  }
  ${(props) =>
    Object.keys(props.theme.icons)
      .map(
        (className) =>
          `.icon-${className}:before {
      content: "${props.theme.icons[className]}";
     }`
      )
      .join('\n')}
`;

export { GlobalStyle };
