import { CSSObject } from '@melio/billpay-design-system';
import styled from 'styled-components';
import { Loader } from '@melio/billpay-design-system';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { QBOHeaderContainer, SettingsLink } from 'src/app/components/layout/QBOHeader';

export const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  ${QBOHeaderContainer} {
    background-color: transparent;
  }
  ${SettingsLink} {
    display: none;
  }
`;

export const StyledLoader = styled(Loader)`
  position: relative;
  width: 6rem;
  height: 6rem;
  top: 0;
  left: 0;
`;

export const baseFontStyle: CSSObject = {
  color: 'black',
  textStyle: 'body3',
  fontWeight: '400',
};

export const containerStyle: CSSObject = {
  display: 'flex',
  w: '100%',
  h: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  ...baseFontStyle,
};

export const titleStyle: CSSObject = {
  my: '1rem',
  textStyle: 'h5',
  fontWeight: '500',
};

export const subtitleStyle: CSSObject = {
  maxW: '36rem',
  textAlign: 'center',
};

export const inputContainerStyle: CSSObject = {
  minH: '5.9rem',
  display: 'flex',
  p: '2rem 0 4rem',
};

export const loaderContainerStyle: CSSObject = {
  position: 'absolute',
  left: 'calc(50% - 3rem)',
  mt: '1.2rem',
  w: '6rem',
  h: '6rem',
};

export const spacierStyle: CSSObject = {
  h: '6rem',
};
