import { EventMappingsTree } from 'src/app/services/analytics/types';
import createMapping from './create/event-mapping';
import editMapping from './edit/event-mapping';
import billsMapping from './bills/event-mapping';

const qbrErrorPage = 'ErrorPage';

const MAPPING: EventMappingsTree = {
  ...createMapping,
  ...editMapping,
  ...billsMapping,
  '/orgs/*/quickpay/error-page': {
    'page-viewed': [
      qbrErrorPage,
      'Viewed',
      {
        newTrackingConvention: 'newTrackingConvention',
        errorType: 'errorType',
      },
    ],
  },
};

export default MAPPING;
