import { put, all, call, select } from 'redux-saga/effects';
import { getPayBillFlowUUID } from 'src/app/services/analytics/trackPayBillFlow';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { buildUpdatePaymentApiRequest } from 'src/app/utils/payments';
import paymentApi from 'src/app/services/api/payments';
import { PaymentRecord } from 'src/app/pages/payment/records';
import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import { PAY_BILL_EVENT_PAGE } from 'src/app/pages/bill/pay/consts';

import type { UpdatePaymentType } from '../types';
import { updatePaymentSuccessAction, updatePaymentErrorAction } from '../actions';
import { getBill, getPayment } from '../selectors';
import { getOrgId } from '../../user/selectors';

export function* updatePaymentHandler({ resolve, reject }: UpdatePaymentType) {
  const [payment, orgId, bill] = yield all([select(getPayment), select(getOrgId), select(getBill)]);
  const payBillFlowUUID = getPayBillFlowUUID();
  const deliveryType = payment.deliveryMethod?.deliveryType;
  const partialBillId = getPartialBillId(bill);

  analytics.track(PAY_BILL_EVENT_PAGE, 'update-confirm', { partialBillId });

  try {
    const isInternationalPayment = deliveryType === DELIVERY_TYPE.INTERNATIONAL;
    let paymentObj;

    if (isInternationalPayment) {
      const request = buildUpdatePaymentApiRequest({ payment });

      paymentObj = yield call(paymentApi.paymentApiUpdate, orgId, request);
    } else {
      const result = yield call(paymentApi.editPaymentById, orgId, {
        ...payment.toObject(),
        payBillFlowUUID,
      });

      paymentObj = result.object;
    }

    analytics.track(PAY_BILL_EVENT_PAGE, 'update-confirm-success', { partialBillId });
    yield put(updatePaymentSuccessAction(PaymentRecord(paymentObj)));
    resolve();
  } catch (e: any) {
    analytics.track(PAY_BILL_EVENT_PAGE, 'update-confirm-failed', { partialBillId });
    yield put(updatePaymentErrorAction(e.code));
    reject(e);
  }
}
