import { ValidationErrorsAccType } from 'src/app/utils/types';

export function findErrorFields(validationFields: string[], formDataObj: Record<string, any>) {
  return validationFields.reduce(
    (validationErrorsAcc: ValidationErrorsAccType, item: string): ValidationErrorsAccType => {
      if (formDataObj[item]?.error) {
        validationErrorsAcc[item] = formDataObj[item].error;
      }

      return validationErrorsAcc;
    },
    {}
  );
}
