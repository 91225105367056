import React from 'react';
import { ManualAddressType } from 'src/app/components/common/ManualAddress/ManualAddressOptionsContainer';
import { ModelView } from 'src/app/ui/form';
import { CheckType, EditableDeliveryMethodType } from 'src/app/utils/types';
import styled from 'styled-components';
import MIButton from 'src/app/components/common/MIButton';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';
import { FormRow } from 'src/app/ui/form/FormElements';
import { CONSTS } from 'src/app/utils/consts';
import { ContentWrapper } from 'src/app/pages/vendor/components/QBRVendorLayoutElements';
import useWhitePageAction from 'src/app/pages/vendor/hoc/white-page-hooks';

type Props = {
  setWhitePageAddressView: (value: boolean) => void;
  whitePageAddress: ManualAddressType;
  model: ModelView<CheckType>;
  isLoading: boolean;
  submit: (deliveryMethod: EditableDeliveryMethodType, isAddressVerified: boolean) => Promise<void>;
};

function QBRWhitePageAddressContainer(props: Props) {
  const { isLoading } = props;
  const [confirmAddress, renderWhitePageAddress] = useWhitePageAction(props);

  return (
    <ContentWrapper title="vendors.addDeliveryMethodByLink.manualAddressTitle">
      <ManualAddressSubTitle>
        <MIFormattedText label="vendors.addDeliveryMethodByLink.manualAddressSubTitle" />
      </ManualAddressSubTitle>
      {renderWhitePageAddress()}
      <ButtonContainer>
        <MIButton
          onClick={confirmAddress}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label="vendors.addDeliveryMethodByLink.manualAddressCTA"
          fullWidth
          isProcessing={isLoading}
        />
      </ButtonContainer>
    </ContentWrapper>
  );
}

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white.opaque};
  padding: 4rem 4rem 1rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ButtonContainer = styled(FormRow)`
  margin-top: 4rem;
`;

const ManualAddressSubTitle = styled.div`
  margin: 1rem 0;
  ${(props) => props.theme.text.fontType.regular};
`;

export default QBRWhitePageAddressContainer;
