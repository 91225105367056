import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Tooltip, Box } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import UploadInvoiceFileLayout from 'src/app/components/layout/Upload10KInvoiceFileLayout';
import analytics from 'src/app/services/analytics';
import { useUploadInvoice } from '../../hooks/useUploadInvoice';
const eventPage = 'international-invoice-collection';

const InvoiceUpload = ({ error, billId, isSidePanel = false }) => {
  const {
    isUploading,
    isUploadError,
    handleSelectFile,
    handleRetry,
    onDeleteAttachment,
    invoiceFile,
  } = useUploadInvoice(billId);

  useEffect(() => {
    analytics.track(eventPage, 'InvoiceFieldForPayment-Shown', {
      billId,
      reason: 'international-payment',
      isFromBatchFlow: isSidePanel,
    });
  }, [billId]);

  const selectFileHandler = (file: File) => {
    analytics.track(eventPage, 'FileForPaymentInvoice-Added', {
      billId,
      reason: 'international-payment',
      isFromBatchFlow: isSidePanel,
    });

    handleSelectFile(file);
  };

  const renderLabel = () => (
    <MainLabelWrapper>
      <MIFormattedText label="international.purposeOfPayment.invoiceUpload.label" />
      {!isSidePanel && (
        <Tooltip
          placement="top"
          label={<MIFormattedText label="international.purposeOfPayment.invoiceUpload.tooltip" />}
        >
          <ToolTipIcon className="icon-info-hollow-icon" />
        </Tooltip>
      )}
    </MainLabelWrapper>
  );

  const renderFooter = (error) => (
    <FooterWrapper>
      <MIFormattedText label={error} />
    </FooterWrapper>
  );

  return (
    <InvoiceUploadWrapper isSidePanel={isSidePanel}>
      {renderLabel()}
      <UploadInvoiceFileLayout
        fileName={invoiceFile?.name || ''}
        isFileUploading={isUploading}
        isFileUploadError={isUploadError}
        onSelectFile={selectFileHandler}
        onRetry={handleRetry}
        onChangeInvoiceFile={handleSelectFile}
        onDeleteInvoiceFile={onDeleteAttachment}
        eventPage="international-invoice-file-upload"
        eventProperties={{
          billId,
          reason: 'international-payment',
          isFromBatchFlow: isSidePanel,
        }}
        margin="1rem 0"
      />
      {isSidePanel && (
        <Box textStyle="ds.body3" color="ds.gray.200">
          <MIFormattedText label="international.purposeOfPayment.invoiceUpload.tooltip" />
        </Box>
      )}
      {error && renderFooter(error)}
    </InvoiceUploadWrapper>
  );
};

export default InvoiceUpload;

const InvoiceUploadWrapper = styled.div<{ isSidePanel?: boolean }>`
  margin-top: ${(props) => (props.isSidePanel ? '1.9rem' : '2.5rem')};
`;

const ToolTipIcon = styled.i`
  margin-left: 0.5rem;
  font-size: 1.6rem;
  color: ${(props) => props.theme.text.color.arrow};
`;

const MainLabelWrapper = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${(props) => props.theme.text.color.arrow};
  display: flex;
  align-items: center;
`;

const FooterWrapper = styled.div`
  margin-top: 0.4rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.text.color.red};
  line-height: 1.6rem;
  font-weight: 500;
`;
