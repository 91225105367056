import React, { useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { getOrgId } from 'src/app/redux/user/selectors';
import { VendorType } from 'src/app/utils/types';
import * as WizardElements from 'src/app/components/layout/QBOWizardElements';
import { MIFormattedText } from 'src/app/utils/formatting';
import { VendorEmailField } from 'src/app/modules/vendors/vendor-email/components/VendorEmailField';
import { CONSTS } from 'src/app/utils/consts';
import MIButton from 'src/app/components/common/MIButton';
import analytics from 'src/app/services/analytics';
import useNotifyVendorsOnPaymentScheduled from 'src/app/pages/qb-dashboard/hooks/useNotifyVendorsOnPaymentScheduled';
import { getVendorIdToContactEmailMapping } from 'src/app/version-2/pages/batch-bulk/utils/mappings.utils';
import { CloseButton } from '../../components/close-button';
import {
  ActionsContainer,
  Divider,
  InputsContainer,
  MainContainer,
  ModalContainerNoPadding,
  WizardText,
} from './styles';
import { VendorsInput } from '../types';
import { NotifyVendorsProps } from './types';
import { validateEmail } from './validation';
import { EMPTY_EMAIL_ERROR } from './consts';
import { ANALYTIC_EVENT_PAGE, ANALYTIC_PROP_FLOW } from '../../const';

export const NotifyVendors: React.FC<NotifyVendorsProps> = (props) => {
  const { closeModal, analyticsData, billedVendorIds, vendorsWithoutEmail, paidPaymentsMainData } =
    props;
  const orgId = useSelector(getOrgId);
  const vendorActions = useStoreActions(vendorsStore);
  const inputsContainerRef = useRef<HTMLElement>();
  const [vendorsInput, setVendorsInput] = useState<VendorsInput>({});
  const [notifyVendors] = useNotifyVendorsOnPaymentScheduled(paidPaymentsMainData);

  const areAllEmptyEmails = useMemo(
    () => Object.values(vendorsInput).every((vendor: VendorType) => !vendor.contactEmail),
    [vendorsInput]
  );
  const errors = useMemo(() => validateEmail(vendorsInput), [vendorsInput]);

  const isFormValid = useMemo(() => {
    const totalErrors = Object.keys(errors).length;
    const totalInputs = Object.keys(vendorsInput).length;
    const allErrorsIndicateEmpty = Object.values(errors).every((err) =>
      err.contactEmail.includes(EMPTY_EMAIL_ERROR)
    );

    return totalErrors === 0 || (allErrorsIndicateEmpty && totalErrors <= totalInputs);
  }, [errors]);

  const updateVendorEmailState = (value, vendorId) => {
    setVendorsInput((prevState) => ({
      ...prevState,
      [vendorId]: {
        contactEmail: value,
        id: vendorId,
      },
    }));
  };

  const updateVendorsEmail = async () => {
    const update = async () => {
      const totalVendors = Object.values(vendorsInput);
      const vendorsWithEmail = totalVendors.filter((vendor) => vendor.contactEmail);

      for await (const vendor of vendorsWithEmail) {
        await vendorActions.update({
          orgId,
          id: vendor.id,
          contactEmail: vendor.contactEmail,
        });
      }

      notifyVendors(getVendorIdToContactEmailMapping(vendorsWithEmail));

      analytics.track(ANALYTIC_EVENT_PAGE, 'VendorEmail-Notified', {
        flow: ANALYTIC_PROP_FLOW,
        paymentIds: analyticsData.paymentIds,
        vendorsCount: billedVendorIds.length,
        vendorsWithoutEmailCount: vendorsWithoutEmail.length,
        vendorsAddedEmail: vendorsWithEmail.length,
      });
    };

    closeModal(null, update);
  };

  // This uses ref to check if the div's content overflow its container and add a scrollbar
  const hasScrollBar = useMemo(() => {
    if (inputsContainerRef.current) {
      const { scrollHeight, clientHeight } = inputsContainerRef.current;

      return scrollHeight > clientHeight;
    }

    return false;
  }, [vendorsWithoutEmail]);

  return (
    <ModalContainerNoPadding>
      <CloseButton onClick={closeModal} />

      <MainContainer>
        <WizardElements.WizardTitle textAlign="left" data-testid="vendors-email-title">
          <MIFormattedText label="batchPayment.vendorsEmailForm.title" />
        </WizardElements.WizardTitle>
        <WizardText textAlign="center" className="batches-modal-subtitle">
          <MIFormattedText label="batchPayment.vendorsEmailForm.subtitle" />
        </WizardText>
        <InputsContainer ref={inputsContainerRef as any}>
          {vendorsWithoutEmail?.map((vendor: any) => {
            const { id, companyName } = vendor;

            return (
              <VendorEmailField
                key={id}
                validationError={errors[id]?.contactEmail}
                label="common.rawValue"
                labelValues={{ value: companyName }}
                value={vendorsInput[id]?.contactEmail}
                required
                onChange={({ value }) => {
                  updateVendorEmailState(value, vendor.id);
                }}
              />
            );
          })}
        </InputsContainer>
      </MainContainer>
      {hasScrollBar && <Divider />}
      <ActionsContainer>
        <MIButton
          disabled={areAllEmptyEmails || !isFormValid}
          testId="bill-success-modal-done"
          label="batchPayment.vendorsEmailForm.primaryCTA"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={updateVendorsEmail}
        />
      </ActionsContainer>
    </ModalContainerNoPadding>
  );
};
