import { extendTheme, theme as defaultTheme, Theme as ChakraTheme } from '@chakra-ui/react';
import * as components from './components';
import * as foundations from './foundations';
import * as typography from './typography';
import { breakpoints } from './breakpoints';

export const theme = extendTheme(
  {
    components,
    ...typography,
    ...foundations,
  },
  {
    config: defaultTheme.config,
    direction: defaultTheme.direction,
    transition: defaultTheme.transition,
    zIndices: defaultTheme.zIndices,
    breakpoints,
    components: {},
    styles: {},
    borders: {},
    colors: {},
    radii: {},
    shadows: {},
    sizes: {},
    space: {},
    fonts: {},
    fontSizes: {},
    fontWeights: {},
    letterSpacings: {},
    lineHeights: {},
  },
);

export type Theme = ChakraTheme | any;

export { extendTheme, theme as defaultTheme };
// export * from './global.styles';
