import React from 'react';
import styled, { css } from 'styled-components';
import { PAYMENT_STATUS } from 'src/app/utils/consts';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { isPaymentScheduled } from 'src/app/utils/payments';
import { PaymentType } from 'src/app/utils/types';

type Props = {
  date: string;
  statusLabel: string;
  payment: PaymentType;
};

const PartialPaymentStatus = ({ date, statusLabel, payment }: Props) => (
  <TextualData>
    <MIFormattedText
      label="bills.form.partialPayments.partialPaymentStatus"
      values={{
        date: <MIFormattedDate date={date} />,
        preposition: (
          <MIFormattedText
            label={
              isPaymentScheduled(payment)
                ? 'bills.form.partialPayments.preposition.for'
                : 'bills.form.partialPayments.preposition.on'
            }
          />
        ),
        status: (
          <StatusLabel failed={payment.status === PAYMENT_STATUS.FAILED}>
            <MIFormattedText label={statusLabel} />
          </StatusLabel>
        ),
      }}
    />
  </TextualData>
);

const TextualData = styled.div`
  display: inline-block;
`;

const StatusLabel = styled.span<{ failed?: boolean }>`
  color: ${({ theme }) => theme.text.color.subtitle};

  ${({ failed, theme }) =>
    failed &&
    css`
      color: ${theme.text.color.error};
    `};

  ${({ theme }) => theme?.components?.BillOpenBalance?.StatusLabel};
`;

export default PartialPaymentStatus;
