import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import locations from 'src/app/pages/qb-dashboard/locations';
import MainNavigator from 'src/app/utils/locations';
import { flagsStore } from 'src/app/modules/flags/flags-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import justPayLocations from 'src/app/pages/just-pay/justPayLocations';
import { getOrgId } from 'src/app/redux/user/selectors';
import { FLOW_ORIGIN } from 'src/app/utils/consts';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { OrganizationPreferencesType } from 'src/app/utils/types';
import analytics from 'src/app/services/analytics';
import { ActionItem } from '../types';

const ADD_NEW_BILL_BUTTON_PATH = 'paymentDashboard.addBillToDashboard.addNewBillButton';

export const useAddBillMenuItems = ({ showBillModalDialog }) => {
  const orgId = useSelector(getOrgId);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const dispatch = useDispatch();
  const [isInternationalEntryPointFFEnabled] = featureFlags.useFeature(
    'international-new-entry-point'
  );
  const paymentStoreActions = useStoreActions(paymentStore);
  const history = useHistory();

  const goToPath = (path: string, persistState?: Record<string, any>) => {
    history.push(generatePath(path, { orgId }), persistState);
  };

  const menuItems = useMemo(() => {
    const items: ActionItem[] = [
      {
        label: `${ADD_NEW_BILL_BUTTON_PATH}.addBillOption`,
        onClick: () => {
          analytics.track('bills-dashboard', 'new-bill-type', { bill_type: 'bill' });
          showBillModalDialog();
        },
      },
      {
        label: `${ADD_NEW_BILL_BUTTON_PATH}.payWithoutBillOption`,
        onClick: () => {
          paymentStoreActions.justPay.justPayWizard.update({
            exitUrl: locations.dashboard,
            flowOrigin: FLOW_ORIGIN.DASHBOARD,
          });
          analytics.track('bills-dashboard', 'new-bill-type', { bill_type: 'just-pay' });
          goToPath(justPayLocations.create.entry, {});
        },
      },
    ];

    if (isInternationalEntryPointFFEnabled) {
      items.push({
        label: `${ADD_NEW_BILL_BUTTON_PATH}.internationalPayment.label`,
        onClick: () => {
          analytics.track('bills-dashboard', 'new-bill-type', { bill_type: 'international' });
          goToPath(MainNavigator.MainApp.dashboardInternationalBill.url(), {
            exitUrl: locations.dashboard,
          });
          dispatch(flagsStore.actions.setIsInternationalEntryPoint(true));
        },
        showNewFeatureBadge: !!organizationPreferences.isEligibleForInternationalPayment,
        isDisabled: !organizationPreferences.isEligibleForInternationalPayment,
        tooltip: `${ADD_NEW_BILL_BUTTON_PATH}.internationalPayment.tooltip`,
      });
    }

    return items;
  }, []);

  return menuItems;
};
