import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import { useQueryString } from 'src/app/utils/hooks';
import { encodeQuery } from 'src/app/utils/query-utils';
import { ListSearchBarContext } from '../context/ListSearchBarContext';

type Props = {
  children?: React.ReactNode;
};

function withListSearchBarContext() {
  return function (Component: any) {
    return class ComponentWithListSearchBarContext extends React.PureComponent<Props> {
      render() {
        return (
          <ListSearchBarContext.Consumer>
            {(listSearchBarContext) => (
              <Component {...this.props} {...listSearchBarContext}>
                {this.props.children}
              </Component>
            )}
          </ListSearchBarContext.Consumer>
        );
      }
    };
  };
}

const useListSearchBarContext = () => {
  const searchContext = useContext(ListSearchBarContext);

  return [searchContext];
};

const useSearchValue = () => {
  const query = useQueryString();

  return query.search || '';
};

const useQuerySearchUpdater = () => {
  const [searchContext] = useListSearchBarContext();
  const searchValue = searchContext.contextSearchFilterValue;
  const history = useHistory();
  const query = useQueryString();
  const search = query.search || '';

  useEffect(() => {
    searchContext.setContextSearchFilterValue(search as string);
    // Run only first time to set the search value from the query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sync the query string with contextSearchFilterValue
  useEffect(() => {
    if (searchValue !== search) {
      history.push({
        ...history.location,
        search: encodeQuery(pickBy({ ...query, search: searchValue }), [], ''),
      });
    }
  }, [searchValue, search, history, query]);
};

export { withListSearchBarContext, useListSearchBarContext, useQuerySearchUpdater, useSearchValue };
