import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import isFunction from 'lodash/isFunction';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { Dispatch } from 'redux';
import { takeEvery } from 'redux-saga/effects';
import { PersistConfig } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { RestfulState } from 'src/app/helpers/redux/types';
import { getValidationErrors } from '@melio/sizzers-js-common';
import { createListSlice } from 'src/app/helpers/redux/restListSlice';
import { createDeleteSlice } from 'src/app/helpers/redux/restDeleteSlice';
import { createUpdateSlice } from 'src/app/helpers/redux/restUpdateSlice';
import { createFetchSlice } from 'src/app/helpers/redux/restFetchSlice';
import { createCreateSlice } from 'src/app/helpers/redux/restCreateSlice';
import { CLEAR_STATE } from 'src/app/redux/user/actionTypes';
import { composeSlices } from './composeSlice';

export function hashListKey(params: { [key: string]: any }): string {
  if (params.identifier) {
    return params.identifier.toString();
  }

  return map(
    params,
    (value, key: string) => `${key}:${isObject(value) ? JSON.stringify(value) : value}`
  ).join(':');
}

export function defaultCreateHashFunction() {
  return 'default';
}

type Dispatcher = (dispatch: Dispatch<any>) => (params: any) => void;

type ListResponse<T> = {
  items: T[];
  totalItems?: number;
};

export type CreateRestfulSliceOptions<T extends Entity, I = any> = {
  name: string;
  initialState?: Partial<RestfulState<T>> & I;
  api: {
    fetch?: (data: T) => Promise<T>;
    update?: (data: T) => Promise<T>;
    create?: (data: T) => Promise<T>;
    delete?: ({ orgId, id }) => Promise<any>;
    list?: (query?: any) => Promise<ListResponse<T>>;
  };
  extraReducers?: {
    [actionType: string]: (state: RestfulState<T>, action: any) => RestfulState<T> | void;
  };
  extraSagas?: { [actionType: string]: any };
  schemaName?: string;
  selectors?: { [key: string]: (state: any) => any };
  slices?: { [key: string]: any };
  createHashFunc?: (T) => string;
  listHashFunc?: (T) => string;
  dispatchers?: { [key: string]: Dispatcher };
  persistConfig?: Partial<PersistConfig<Partial<RestfulState<T>>>>;
  validateFunc?: (obj: T, changes?: Partial<T>) => Promise<any>;
};
export type Entity = {
  id: string | number;
};

export function createRestfulSlice<T extends Entity, I = any>(
  options: CreateRestfulSliceOptions<T, I>
) {
  const {
    name,
    api,
    extraReducers,
    createHashFunc = defaultCreateHashFunction,
    listHashFunc = hashListKey,
    validateFunc = undefined,
  } = options;
  const validate =
    validateFunc ||
    (async (obj: T, changes?: Partial<T>) => {
      const fieldsToCompare = changes && obj.id ? Object.keys(changes) : undefined;
      const res = await getValidationErrors(
        options.schemaName || options.name,
        obj,
        fieldsToCompare
      );

      return isEmpty(res) ? null : res;
    });

  // eslint-disable-next-line max-len
  const listSlice =
    api.list && createListSlice<T>({ storeName: name, api: api.list, listHashFunc });
  // eslint-disable-next-line max-len
  const createSlice =
    api.create &&
    createCreateSlice<T>({
      storeName: name,
      api: api.create,
      validate,
      createHashFunc,
    });
  const fetchSlice = api.fetch && createFetchSlice<T>({ storeName: name, api: api.fetch });
  const updateSlice =
    api.update && createUpdateSlice<T>({ storeName: name, api: api.update, validate });
  const deleteSlice = api.delete && createDeleteSlice<T>({ storeName: name, api: api.delete });
  const extraSagas = mapValues(options.extraSagas || {}, (saga, event) => takeEvery(event, saga));
  const persistConfig = options.persistConfig
    ? {
        key: name,
        storage: storageSession,
        ...options.persistConfig,
      }
    : null;

  const slice = composeSlices(
    {
      fetch: fetchSlice,
      update: updateSlice,
      list: listSlice,
      create: createSlice,
      delete: deleteSlice,
      ...(options.slices || {}),
    },
    {
      initialState: options.initialState,
      validate,
      extraReducers: {
        [CLEAR_STATE]() {
          return slice.initialState;
        },
        ...extraReducers,
      },
      selectors: options.selectors || {},
      extraSagas,
      dispatchers: options.dispatchers || {},
      persistConfig,
    }
  );

  return slice;
}

const recMapDispatchers = (dispatch, dispatchers) =>
  mapValues(dispatchers, (dispatcher) => {
    if (isFunction(dispatcher)) {
      return dispatcher(dispatch);
    }

    return recMapDispatchers(dispatch, dispatcher);
  });

export const getStoreActions = (store) => (dispatch) =>
  recMapDispatchers(dispatch, store.dispatchers);

export const useStoreActions = (store) => {
  const dispatcher = useDispatch();

  return useMemo(() => getStoreActions(store)(dispatcher), [store, dispatcher]);
};
