import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import styled from 'styled-components';
import reverse from 'lodash/reverse';
import paymentsApi from 'src/app/services/api/payments';
import MIButton from 'src/app/components/common/MIButton';
import { getBillActionOptions } from 'src/app/pages/bill/utils';
import { PaymentRecord } from 'src/app/pages/payment/records';
import { getPaymentById, getLatestPayment, convertFeeObject } from 'src/app/utils/payments';
import { useModal } from 'src/app/helpers/react/useModal';
import { BILL_STATUS, DIALOG_VARIANTS, DIALOG_TYPE } from 'src/app/utils/consts';
import { defaultFeeObject } from 'src/app/utils/fee';
import { getOrgId } from 'src/app/redux/user/selectors';
import {
  BillType,
  PaymentType,
  DeletePaymentActionType,
  QBCashStateType,
  IntuitBankAccountType,
} from 'src/app/utils/types';
import { AccountRecord } from 'src/app/pages/settings/records';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import QBOMIDialog from 'src/app/components/common/QBOMIDialog';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import intuitBankAccountsStore from 'src/app/modules/intuit-bank-accounts/intuit-bank-accounts-store';
import useFetchIntuitBankAccounts from 'src/app/modules/intuit-bank-accounts/hooks/useFetchIntuitBankAccounts';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { DataOriginEnum } from 'src/app/version-2/model/enums';
import PaymentProcessingBlockedNotificationCard from './PaymentProcessingBlockedNotificationCard';
import { QBMViewBillPaymentReview } from './QBMViewBillPaymentReview';
import { CheckOverdueNotification } from './CheckOverdueNotification';
import { useVoidCheckActions } from '../hooks/useVoidCheckActions';
import { DeliveryMethodRecord } from 'src/app/pages/vendor/records-constants';

type Props = {
  bill: RecordOf<BillType>;
  paymentId?: string;
  goEditPayment: (payment: PaymentType) => void;
  onDeletePayment: (payment?: PaymentType, type?: DeletePaymentActionType) => void;
  onRetryPaymentDelivery: (paymentId: number, deliveryMethodId?: number) => void;
  onChangeDeliveryMethod?: (paymentId: number) => void;
  onRetryPayment: (payment: PaymentType) => void;
  onMarkBillAsPaid?: (() => void) | null;
  onMarkBillAsUnpaid?: ((payment: PaymentType) => void) | null;
  onClose?(): void;
  onPaymentsMadeClick(): void;
};

const QBMBillPaymentsActivity = ({
  bill,
  onDeletePayment,
  goEditPayment,
  onRetryPaymentDelivery,
  onChangeDeliveryMethod,
  onRetryPayment,
  paymentId,
  onMarkBillAsPaid,
  onMarkBillAsUnpaid,
  onClose,
  onPaymentsMadeClick,
}: Props) => {
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();

  const orgId = useSelector(getOrgId);
  const payment = isPartialPaymentsEnabled
    ? PaymentRecord(getPaymentById(bill.payments, paymentId as string))
    : getLatestPayment(bill.payments);

  const { handleVoidCheckAndResendPayment } = useVoidCheckActions({
    billId: bill.id,
    paymentId: payment?.id ?? '',
  });

  useFetchIntuitBankAccounts();
  const intuitAccount: IntuitBankAccountType | undefined = useSelector(
    intuitBankAccountsStore.selectors.byId(payment?.intuitAccountId)
  );

  const dialogText = payment?.metadata?.canUserRetry
    ? 'bills.form.paymentActivity.dialog.delete'
    : 'bills.form.paymentActivity.dialog.cancel';

  const deleteDialog = ({ dismiss }: { dismiss: () => void }) => (
    <QBOMIDialog
      type={DIALOG_TYPE.CONFIRM}
      variant={DIALOG_VARIANTS.ERROR}
      title={`${dialogText}.title`}
      titleValues={{
        companyName: <strong>{bill.vendor.companyName}</strong>,
      }}
      subtitle={`${dialogText}.subtitle`}
      subtitleValues={{
        companyName: <strong>{bill.vendor.companyName}</strong>,
        amount: (
          <strong>
            <MIFormattedCurrency value={bill.totalAmount} />
          </strong>
        ),
      }}
      okButtonText={`${dialogText}.confirm`}
      onOkAction={() => onDeletePayment(payment)}
      onCancelAction={dismiss}
      cancelButtonText={`${dialogText}.cancelText`}
    />
  );
  const [DeletePaymentModal, onDeletePaymentCanceled] = useModal(deleteDialog, {});

  const actionOptions = getBillActionOptions({
    bill,
    payment,
    goEditPayment,
    onCancelPaymentClicked: onDeletePaymentCanceled,
    onChangeDeliveryMethod,
    onRetryPaymentDelivery,
    onRetryPayment,
    onMarkBillAsPaid,
    onMarkBillAsUnpaid,
    onVoidCheckAndResendPayment: handleVoidCheckAndResendPayment,
    onClose,
  });

  const [fee, setFee] = useState<Record<string, any> | null>(null);
  const markedAsPaid = payment.manual;

  const getFeeApi = async () => {
    try {
      const { fees } = await paymentsApi.getPaymentFeeApi(orgId, {
        paymentId: payment.id,
        paymentOrigin: DataOriginEnum.INTUIT,
      });

      // convert current fee to component sturcture
      setFee(convertFeeObject(fees));
    } catch (e) {
      setFee(defaultFeeObject);
    }
  };

  useEffect(() => {
    if (!markedAsPaid) {
      getFeeApi();
    }
  }, [
    payment.deliveryMethodId,
    payment.organizationId,
    payment.fundingSourceId,
    bill.totalAmount,
    payment.scheduledDate,
    markedAsPaid,
    payment.deliveryPreference,
  ]);

  const fundingSource = AccountRecord(
    (useSelector(fundingSourcesStore.selectors.byId(payment.fundingSourceId)) as any) ||
      payment.fundingSource
  );
  const deliveryMethod = DeliveryMethodRecord(payment.deliveryMethod);

  return (
    <Container>
      <Separator />
      {DeletePaymentModal}
      <>
        {bill.status === BILL_STATUS.SCHEDULED && (
          <WrappedPaymentProcessingBlockedNotificationCard
            payment={payment as RecordOf<PaymentType>}
            bill={bill}
          />
        )}
        <CheckOverdueNotification
          payment={payment}
          onVoidCheckAndResendPayment={handleVoidCheckAndResendPayment}
        />
        <QBMViewBillPaymentReview
          bill={bill}
          fee={fee as any}
          fundingSource={fundingSource}
          deliveryMethod={deliveryMethod}
          payment={payment as any}
          qbCashState={qbCashState}
          onPaymentsMadeClick={onPaymentsMadeClick}
        />
        {markedAsPaid && (
          <MarkedAsPaidInfoContainer>
            <MarkAsPaidContainer>
              <MIFormattedText
                label="bills.view.markAsPaidInfo"
                values={{
                  date: <MIFormattedDate date={payment.scheduledDate} />,
                }}
              />
            </MarkAsPaidContainer>
            {intuitAccount && (
              <MarkAsPaidAccountInfo>
                <MIFormattedText
                  label="bills.view.markAsPaidAcountInfo"
                  values={{
                    account: intuitAccount.name,
                  }}
                />
              </MarkAsPaidAccountInfo>
            )}
          </MarkedAsPaidInfoContainer>
        )}
      </>
      {actionOptions.length > 0 && (
        <ActionOptionsContainer>
          {reverse(actionOptions).map((actionOption, key) => (
            <MIButton
              key={key}
              label={actionOption.label}
              variant={actionOption.variant}
              onClick={(actionOption as any).action}
            />
          ))}
        </ActionOptionsContainer>
      )}
    </Container>
  );
};

export default QBMBillPaymentsActivity;

const Container = styled.div``;

const WrappedPaymentProcessingBlockedNotificationCard = styled(
  PaymentProcessingBlockedNotificationCard
)`
  margin: 2rem;
`;

const Separator = styled.div`
  height: 0.1rem;
  background-color: rgba(244, 245, 248, 1);
  margin: 0 2rem;
  ${(props) => props.theme?.components?.QBOBillPaymentsActivity?.Separator}
`;

const ActionOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 8rem;
  margin-bottom: -16rem;
  margin-top: 11rem;
  flex-direction: row-reverse;
  ${(props) => props.theme?.components?.QBOBillPaymentsActivity?.ActionOptionsContainer}
`;

const MarkAsPaidContainer = styled.div`
  font-size: ${(props) => props.theme.text.size.linkHint};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.colors.success.opaque};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
`;

const MarkAsPaidAccountInfo = styled.div`
  ${(props) => props.theme.text.fontType.hint}
  color: ${(props) => props.theme.text.color.darkGrey};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const MarkedAsPaidInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.6rem;
`;
