import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { FaqPanel } from 'src/app/version-2/model/dtos';

export const APPLICATION_SLICE = `${VERSION_2}application`;

interface ApplicationSlice {
  urlToBack: string;
  faqPanel: FaqPanel;
}

export const selectState = (state: any) => state[APPLICATION_SLICE];

export const applicationInitialState: ApplicationSlice = {
  urlToBack: '',
  faqPanel: {
    isOpen: false,
    focusedQuestion: undefined,
  },
};

export const applicationSlice = createSlice({
  name: APPLICATION_SLICE,
  initialState: applicationInitialState,
  reducers: {
    setUrlToBack: (state, action: PayloadAction<string>) => {
      state.urlToBack = action.payload;
    },
    setFaqPanel: (state, action: PayloadAction<FaqPanel>) => {
      state.faqPanel = action.payload;
    },
  },
});

export const applicationSelectors = {
  selectUrlToBack: createSelector(selectState, (state) => state.urlToBack),
  selectFaqPanel: createSelector(selectState, (state) => state.faqPanel),
};

export const applicationActions = {
  ...applicationSlice.actions,
  sessionStartAction: createAction<{ [key: string]: string }>(`${APPLICATION_SLICE}/session-start`),
  sessionEndAction: createAction<{ contextName: string }>(`${APPLICATION_SLICE}/session-end`),
};
