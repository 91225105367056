import { memo } from 'react';
import styled from 'styled-components';
import MISingleSelect from 'src/app/components/common/MISingleSelect';
import { Flags } from 'src/app/components/common/Flags';

import { CurrencyOption } from '../types';

const formatOptionLabel = ({ label, flag }) => (
  <StyledOption>
    <Flags code={flag} /> <span>{label}</span>
  </StyledOption>
);

const formatGroupLabel = (data) => (
  <div style={{ padding: '1rem 0 0.8rem 1.8rem' }}>
    <span>{data.label}</span>
  </div>
);

const overrideStyles = {
  option: {
    minHeight: '3rem',
    padding: '0.6rem 2rem',
  },
};

type Props = {
  value?: string;
  label: string;
  options: CurrencyOption[];
  errorMessage?: string;
  className?: string;
  readOnlyValue?: boolean;
  hideArrow?: boolean;
  onChange?: ({ value }) => void;
};

const CurrenciesList = ({
  options,
  label,
  value,
  errorMessage,
  className,
  readOnlyValue,
  hideArrow,
  onChange,
}: Props) => (
  <MISingleSelect
    value={value}
    label={label}
    options={options}
    onChange={onChange}
    formatGroupLabel={formatGroupLabel}
    errorMessage={errorMessage}
    className={className}
    readOnlyValue={readOnlyValue}
    formatOptionLabel={formatOptionLabel}
    overrideStyles={overrideStyles}
    hideArrow={hideArrow}
    required
    privateData
  />
);

function areEqual(prevProps, nextProps) {
  return prevProps.value === nextProps.value && prevProps.errorMessage === nextProps.errorMessage;
}

export default memo(CurrenciesList, areEqual);

const StyledOption = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
