import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckDeliveryMethodState } from 'src/app/pages/vendor/delivery-methods/CheckDeliveryMethod';
import useManualAddress from 'src/app/pages/vendor/delivery-methods/hooks/useManualAddress';
import { CONSTS, DELIVERY_METHOD_CHECK_FLOW, NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import { useSelector } from 'react-redux';
import { useDidUpdateEffect } from 'src/app/hooks/useDidUpdateEffect';
import ManualAddressSubtitle from 'src/app/components/layout/ManualAddressSubtitle';
import { CheckType } from 'src/app/utils/types';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import ManualAddressConfirmPage from 'src/app/components/layout/ManualAddressConfirmPage';
import { getOwnedVendorId } from 'src/app/redux/user/selectors';
import WarningIcon from 'src/app/images/general/warning-icon.svg';
import EditCheckAddressModal from './editCheckAddressModal/EditCheckAddressModal';
import CheckAddressDeliveryMethodForm from './CheckAddressDeliveryMethodForm';

type Props = {
  vendorId: string;
  check?: Partial<CheckType>;
  goExit: () => void;
  onSuccess: (
    paperCheck: CheckType,
    isVerified: boolean,
    checkDeliveryMethodId?: string
  ) => Promise<void>;
  isLoading: boolean;
  goUnilateralFlow: () => void;
  flowType: string;
  isReturnedCheck: boolean;
  isVoidCheck?: boolean;
};

const EditCheckAddressWithAutocompleteDeliveryMethodPage = ({
  onSuccess,
  vendorId,
  check,
  goExit,
  isLoading,
  goUnilateralFlow,
  flowType,
  isReturnedCheck,
  isVoidCheck,
}: Props) => {
  const site = useSiteContext();
  const { StepLayout } = site.components;
  const ownedVendorId = useSelector(getOwnedVendorId);
  const { actions, state } = useManualAddress({
    vendorId,
    onSuccess,
    checkModelOverwrite: check,
    ownedVendorId,
  });
  const history = useHistory();
  const [showNoficiation, setShowNotification] = React.useState(isReturnedCheck || isVoidCheck);

  const {
    address,
    selectedAddressId,
    whitePageValidationErrors,
    isAddressLoading,
    validatedAddressObj,
    checkDeliveryMethodMV,
    currentState,
    eventPage,
  } = state;
  const {
    onSelectedAddressId,
    onConfirmAddress,
    onConfirmInvalidAddress,
    submit,
    onEditAddress,
    onCloseModal,
  } = actions;

  useDidUpdateEffect(() => {
    // hide notification when user start typing
    if (showNoficiation) setShowNotification(false);
  }, [checkDeliveryMethodMV]);

  const TITLE = {
    [CheckDeliveryMethodState.CONFIRM]: 'manualAddress.confirmPage.title',
    [CheckDeliveryMethodState.NEW]: 'vendors.deliveryMethods.check.address.title',
    [CheckDeliveryMethodState.INVALID_ADDRESS]: 'manualAddress.invalidTitle',
    [CheckDeliveryMethodState.INVALID_PO_BOX]: 'manualAddress.invalidTitle',
  };

  const ACTIONS = {
    [CheckDeliveryMethodState.NEW]: {
      onNext: submit,
      nextLabel: 'vendors.deliveryMethods.check.submit',
    },
    [CheckDeliveryMethodState.CONFIRM]: {
      onNext: onConfirmInvalidAddress,
      nextLabel: 'vendors.deliveryMethods.check.address.confirm',
    },
    [CheckDeliveryMethodState.INVALID_ADDRESS]: {
      onNext: onConfirmAddress,
      nextLabel: 'vendors.deliveryMethods.check.submit',
    },
    [CheckDeliveryMethodState.INVALID_PO_BOX]: {
      onNext: onConfirmAddress,
      nextLabel: 'manualAddress.edit',
    },
  };

  const PopUpFlow = {
    [DELIVERY_METHOD_CHECK_FLOW.CONFIRM]: {
      onSuccessModal: onConfirmAddress,
      secondaryButtonLabel: 'manualAddress.correctInvalid',
      secondaryButtonVariant: CONSTS.BUTTON_VARIANT.CANCEL,
    },
    [DELIVERY_METHOD_CHECK_FLOW.UNILATERAL]: {
      onSuccessModal: goUnilateralFlow,
      secondaryButtonLabel: 'manualAddress.goUnilateral',
      secondaryButtonVariant: CONSTS.BUTTON_VARIANT.SECONDARY,
      invalidSubtitle: 'manualAddress.invalidUnilateralSubtitle',
    },
  };

  const CHECK_INVALID_POPUP = {
    [CheckDeliveryMethodState.INVALID_ADDRESS]: (
      <EditCheckAddressModal
        address={address}
        printName={checkDeliveryMethodMV.printName.value}
        onCloseModal={onCloseModal}
        icon={WarningIcon}
        subtitle="manualAddress.invalidSubtitle"
        PopUpFlow={PopUpFlow[flowType]}
        loading={isLoading}
      />
    ),
    [CheckDeliveryMethodState.INVALID_PO_BOX]: (
      <EditCheckAddressModal
        address={address}
        printName={checkDeliveryMethodMV.printName.value}
        onCloseModal={onCloseModal}
        icon={WarningIcon}
        subtitle="manualAddress.invalidPOBoxSubtitle"
        PopUpFlow={PopUpFlow[flowType]}
        loading={isLoading}
      />
    ),
  };

  const CHECK_DELIVERY_CONTENT = {
    [CheckDeliveryMethodState.NEW]: (
      <CheckAddressDeliveryMethodForm
        submit={submit}
        model={checkDeliveryMethodMV}
        eventPage={eventPage}
        setShowNotification={setShowNotification}
      />
    ),
    [CheckDeliveryMethodState.CONFIRM]: (
      <>
        <ManualAddressSubtitle label="manualAddress.confirmPage.subtitle" />
        <ManualAddressConfirmPage
          printName={checkDeliveryMethodMV.printName.value}
          addressToConfirm={validatedAddressObj}
          addressValidationErrors={whitePageValidationErrors}
          originalAddress={address}
          onAddressSelect={onSelectedAddressId}
          onEditAddress={onEditAddress}
          selectedAddressId={selectedAddressId}
        />
      </>
    ),
  };

  const onPrev = () => {
    const { CONFIRM, INVALID_ADDRESS } = CheckDeliveryMethodState;

    if (currentState === CONFIRM || currentState === INVALID_ADDRESS) {
      onEditAddress();
    } else {
      history.goBack();
    }
  };

  const notificationPrefix = isVoidCheck ? 'voidCheckRecovery' : 'returnedCheckRecovery';

  const notification = showNoficiation
    ? {
        type: NOTIFICATION_CARD_TYPES.WARNING,
        subtitle: {
          label: `bills.pay.${notificationPrefix}.stepRecoverByCheck.reviewAddressDetails.notificationText`,
        },
        onClose: () => setShowNotification(false),
      }
    : null;

  const getNextLabeLText = () => {
    if (isVoidCheck) return 'bills.pay.voidCheckRecovery.stepChooseDM.check.submit';

    return ACTIONS[currentState].nextLabel;
  };

  return (
    <StepLayout
      title={TITLE[currentState]}
      onNext={ACTIONS[currentState].onNext}
      goExit={goExit}
      onPrev={onPrev}
      isLoading={isLoading || isAddressLoading}
      nextLabel={getNextLabeLText()}
      ctaVariant={CONSTS.BUTTON_VARIANT.PRIMARY}
      fullWidthCTA
      hideHeader
      {...{ notification }}
    >
      {CHECK_DELIVERY_CONTENT[currentState]}
      {CHECK_INVALID_POPUP[currentState]}
    </StepLayout>
  );
};

export default EditCheckAddressWithAutocompleteDeliveryMethodPage;
