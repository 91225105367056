import React from 'react';
import { Switch } from 'react-router-dom';
import NewBillPageContainer from './NewBillPageContainer';
import NewBillCreateOptionsPageContainer from './NewBillCreateOptionsPageContainer';
import { BillPayWizardRouter } from './pay/BillPayWizardRouter';
import { SmartRoute } from '../../modules/navigation/components/SmartRoute';
import locations from './locations';

export function BillWizardRouter() {
  return (
    <Switch>
      <SmartRoute path={locations.create.index} exact>
        <NewBillPageContainer />
      </SmartRoute>
      <SmartRoute path={locations.create.options} exact>
        <NewBillCreateOptionsPageContainer />
      </SmartRoute>
      <SmartRoute path={locations.pay.base}>
        <BillPayWizardRouter />
      </SmartRoute>
    </Switch>
  );
}
