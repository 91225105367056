import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { RecordOf } from 'immutable';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { AreaLoader } from '@melio/billpay-design-system';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BillType, PaymentType } from 'src/app/utils/types';
import {
  BILL_STATUS,
  PAYMENT_STATUS,
  PAYMENT_APPROVAL_STATUS,
  PAGINATION,
} from 'src/app/utils/consts';
import { ITEMS_PER_PAGE, QB_DASHBOARD_TAB_STATUS } from 'src/app/pages/qb-dashboard/consts';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import { getPartialBillId, isBillHasPartialPayments, isRecurringBill } from 'src/app/utils/bills';
import recurringBillsApi from 'src/app/services/api/recurringBills';
import billsApi from 'src/app/services/api/bills';
import billsStore from 'src/app/modules/bills/bills-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentsApi from 'src/app/services/api/payments';
import { getStatusInfo } from 'src/app/utils/bill-details';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import errorIcon from 'src/app/images/qbo/error-icon.png';
import { DeliveryEnum } from 'src/app/version-2/model/enums';
import { extractDeliveryMethodFromVendor } from 'src/app/version-2/utils/deliveryMethod.utils';
import { getPaymentById } from 'src/app/utils/payments';
import { getOrgId } from 'src/app/redux/user/selectors';
import { melioClose, paymentDeleted } from 'src/app/utils/external-events';
import { WizardInner } from 'src/app/components/layout/QBOWizardElements';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import BillPaymentsActivity from 'src/app/pages/bill/components/ViewPaymentActivity/ViewPaymentActivity';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { useApi } from 'src/app/hoc/useApi';
import { useModal } from 'src/app/helpers/react/useModal';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import useKeyPress from 'src/app/hooks/useKeyPress';
import { BillRecord } from '../../records';
import QBOBillStatus from '../../components/QBOBillStatus';
import { ViewBillBlockRefundModal } from './ViewBillBlockRefundModal';
import { StatusMessage } from './NotificationCardStatusMessage/components/StatusMessage';
import billLocations from '../../locations';
import { CancelPaymentDialog } from './CancelPaymentDialog';
import { ActionsButtons, RETRY_PAYMENT_TYPE } from './ActionsButtons';
import { useVoidCheckActions } from '../../hooks/useVoidCheckActions';
import { featureFlags } from '@melio/shared-web';
import { loggingApi } from 'src/app/version-2/api/loggers';
import {
  FeatureFlagsEnum,
  MetadataPaymentTypeEnum,
  RefundRequestSourceEnum,
} from 'src/app/version-2/model/enums';

const eventPage = 'bill';

export const QBOViewPaymentPage = () => {
  const isRefundFlowFeature = featureFlags.defaultClient.getVariant(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const isTimelineFeatureEnabled = featureFlags.defaultClient.getVariant(
    FeatureFlagsEnum.PAYMENT_DETAILS_TIMELINE,
    false
  );
  const { billId, paymentId: paymentIdParam } = useParams<{
    billId: string;
    paymentId?: string;
  }>();
  const [historyPush] = useHistoryWithOrgId();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const billsActions = useStoreActions(billsStore);
  const { viewPaymentUrls } = useStoreActions(qbDashboardListItemsStore);
  const theme = useTheme();
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const orgId = useSelector(getOrgId);
  const { exitUrl, unpaidTabRedirectUrl, payBillRedirectUrl } = useSelector(
    qbDashboardListItemsStore.selectors.viewPaymentUrls.urls
  ) as any;

  const { redirectToDashboard } = useRedirectToDashboard();

  const locationStateForPayBillFlow = {
    exitUrl: `${history.location.pathname}${history.location.search}`,
    redirectUrl: payBillRedirectUrl,
  };

  const [loadBill, billResult, , billLoadingError] = useApi<
    [{ orgId: string; id: string }],
    { object: BillType }
  >(billsApi.getBillById, false);

  const bill = useMemo(
    () => (billResult ? BillRecord(billResult.object) : BillRecord()),
    [billResult]
  );

  const isPartialPayment = !!(
    paymentIdParam &&
    isPartialPaymentsEnabled &&
    isBillHasPartialPayments(bill)
  );
  const isRecurring = isRecurringBill(bill);

  const payment = isPartialPayment
    ? getPaymentById(bill.payments, paymentIdParam)
    : bill.payments[bill.payments.length - 1];
  const paymentId = payment?.id;

  const { handleVoidCheckAndResendPayment } = useVoidCheckActions({
    billId,
    paymentId: paymentId ?? '',
    locationStateForPayBillFlow,
    partialBillId: getPartialBillId(bill),
  });

  useEffect(() => {
    let props = {};

    if (isTimelineFeatureEnabled && payment) {
      props = {
        billId,
        paymentId,
        deliveryMethod: payment?.deliveryMethodId,
        paymentStatus: getPaymentStatus(),
      };
    }

    analytics.page('bills', 'single-qbo-payment', props);
  }, []);

  useEffect(() => {
    loadBill({ orgId, id: billId });
  }, [billId, orgId, loadBill]);

  useKeyPress((key) => {
    if (key === 'Escape') goExit && goExit();
  });

  const onCancelPayment = async () => {
    analytics.track(eventPage, 'delete-payment-confirmed');
    setIsLoading(true);

    try {
      const deleteAction = isRecurring
        ? recurringBillsApi.deleteCurrentRecurringPaymentById
        : paymentsApi.deletePaymentById;

      await deleteAction(orgId, payment?.id);

      const successEvent = isRecurring
        ? 'delete-current-recurring-bill-payment-success'
        : 'delete-payment-success';

      analytics.track(eventPage, successEvent, {
        billId,
        paymentId,
      });

      if (isRecurring) {
        redirectToScheduleDashboard();
      } else if (unpaidTabRedirectUrl) {
        await redirectToDashboard({ itemIds: [{ billId }] });
      } else {
        paymentDeleted();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onCancelAllRecurringPayments = async () => {
    analytics.track(eventPage, 'delete-all-recurring-bill-payments-confirmed');
    setIsLoading(true);

    try {
      await recurringBillsApi.deleteAllRecurringPayments(orgId, bill?.recurringBillId);
      analytics.track(eventPage, 'delete-all-recurring-bill-payments-success');

      redirectToScheduleDashboard();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onRetryPaymentDelivery = () => {
    analytics.track(eventPage, 'retry-payment-delivery', {
      organizationId: orgId,
      paymentId,
      billId: parseInt(billId),
    });
    historyPush({
      path: locations.Bills.pay.edit.deliveryMethodAch.url({
        id: billId,
        paymentId,
        deliveryMethodId: payment?.deliveryMethodId,
      }),
      state: locationStateForPayBillFlow,
    });
  };

  const onEditExistingDelivery = () => {
    analytics.track(eventPage, 'edit-existing-delivery-method');

    const deliveryMethod = extractDeliveryMethodFromVendor({
      bill: bill as any,
      type: DeliveryEnum.ACH,
    });
    const redirectUrl = locations.Bills.pay.edit.confirm.url({ id: bill.id, paymentId, orgId });

    historyPush({
      path: locations.Vendors.deliveryMethods.ach.edit.url({
        id: bill.vendor?.id,
        deliveryMethodId: deliveryMethod?.id,
        orgId,
      }),
      state: { ...locationStateForPayBillFlow, redirectUrl },
    });
  };

  const onChangeDeliveryMethod = () => {
    analytics.track(eventPage, 'change-delivery-method', {
      billId,
      paymentId,
    });
    historyPush({
      path: locations.Bills.pay.edit.deliveryMethod.url({
        id: billId,
        paymentId,
      }),
      state: locationStateForPayBillFlow,
    });
  };

  const onRetryPayment = () => {
    analytics.track(eventPage, 'retry-payment');

    historyPush({
      path: locations.Bills.pay.edit.funding.url({
        id: billId,
        paymentId,
      }),
      state: locationStateForPayBillFlow,
    });
  };

  const onRetryVirtualCardExpired = () => {
    analytics.track(eventPage, 'retry-virtual-card-payment');

    historyPush({
      path: locations.Bills.pay.edit.virtualCardRecovery.url({
        id: billId,
        paymentId,
      }),
      state: locationStateForPayBillFlow,
    });
  };

  const handleRetryPayment = (retryType: RETRY_PAYMENT_TYPE) => {
    if (retryType === RETRY_PAYMENT_TYPE.CHANGE_DELIVERY_METHOD) {
      onChangeDeliveryMethod();
    } else if (retryType === RETRY_PAYMENT_TYPE.RETRY_PAYMENT_DELIVERY) {
      onRetryPaymentDelivery();
    } else if (retryType === RETRY_PAYMENT_TYPE.VIRTUAL_CARD_EXPIRED) {
      onRetryVirtualCardExpired();
    } else if (retryType === RETRY_PAYMENT_TYPE.EXISTING_DELIVERY_METHOD) {
      onEditExistingDelivery();
    } else {
      onRetryPayment();
    }
  };

  const goExit = () => {
    if (exitUrl) {
      viewPaymentUrls.removeUrls();
      history.push(exitUrl);
    } else {
      analytics.track('qbo-single-bill', 'close-integration');
      melioClose();
    }
  };

  const onMarkBillAsUnpaid = async () => {
    const disableNotification = !unpaidTabRedirectUrl;

    setIsLoading(true);
    await billsActions.markAsPaid(
      {
        orgId,
        id: billId,
        paymentId,
        isPaid: false,
        actionType: 'unpaid',
      },
      { disableNotification }
    );

    if (unpaidTabRedirectUrl) {
      await redirectToDashboard({ itemIds: [{ billId }] });
    } else if (exitUrl) {
      history.push(exitUrl);
    } else {
      paymentDeleted();
    }
  };

  const goEditPayment = () => {
    analytics.track(eventPage, 'edit-bill');

    historyPush({
      path: locations.Bills.pay.edit.funding.url({
        id: billId,
        paymentId,
      }),
      state: locationStateForPayBillFlow,
    });
  };

  const handleResolveCheckReturn = () => {
    const analyticsOptions = {
      billID: parseInt(billId),
      original_paymentID: paymentId,
      originDeliveryType: payment?.deliveryMethod?.deliveryType,
      partialBillId: getPartialBillId(bill),
    };

    analytics.track(eventPage, 'retry-payment-delivery', analyticsOptions);
    historyPush({
      path: locations.Bills.pay.edit.returnedCheckRecovery.entry.url({
        id: billId,
        paymentId,
      }),
      state: locationStateForPayBillFlow,
    });
  };

  const [CancelPaymentModal, showCancelPaymentModal] = useModal(CancelPaymentDialog, {
    bill,
    payment,
    onCancelPayment,
    onCancelAllRecurringPayments,
  });

  const redirectToScheduleDashboard = () => {
    const redirectQuery = {
      start: PAGINATION.DEFAULT_START,
      status: QB_DASHBOARD_TAB_STATUS.SCHEDULED,
      limit: ITEMS_PER_PAGE,
      highlightedItemIds: '',
    };

    redirectToDashboard({ redirectQuery });
  };

  const [refundBlockModal, showRefundBlockModal] = useModal(ViewBillBlockRefundModal, {
    id: 'refund-block-modal',
    paymentId: payment?.id,
    billId,
    riskBlockReason: payment?.metadata?.notEligibleByRiskReason,
  });

  const onRefundPayment = useCallback(() => {
    loggingApi.info('QBOViewPaymentPage.onRefundPayment(): refund request', {
      orgId,
      paymentId,
      billId,
      isSelfServeRefundEligible: payment?.metadata?.isSelfServeRefundEligible,
    });

    if (payment?.metadata?.isSelfServeRefundEligible) {
      history.push(generatePath(billLocations.refund, { orgId, paymentId, billId }));
    } else {
      showRefundBlockModal();
    }
  }, [payment, showRefundBlockModal]);

  const handleRefundFlowRequest = () => {
    const props = {
      organizationId: orgId,
      paymentId,
      billId: parseInt(billId),
    };

    loggingApi.info('QBOViewPaymentPage.handleRefundFlowRequest(): refund request', {
      ...props,
      isSelfServeRefundEligible: payment?.metadata?.isSelfServeRefundEligible,
      requestSource: RefundRequestSourceEnum.button,
    });

    analytics.track(eventPage, 'payment-details-get-refund-cta-button-click', props);

    onRefundPayment();
  };

  const getPaymentStatus = () => {
    if (isPaymentFailed) {
      return PAYMENT_STATUS.FAILED;
    }

    if (isPartialPayment) {
      if (
        payment?.manual ||
        payment?.status === PAYMENT_STATUS.COMPLETED ||
        payment?.status === PAYMENT_STATUS.IN_PROGRESS
      ) {
        return BILL_STATUS.PAID;
      }

      return BILL_STATUS.SCHEDULED;
    }

    return bill.status;
  };

  if (billLoadingError) {
    analytics.track('bill-error', 'bill-not-found');

    return (
      <ErrorPage
        illustration={errorIcon}
        title="bills.view.error.titleNotFound"
        subtitle="bills.view.error.subtitleNotFound"
        buttonAction={goExit}
        buttonLabel="bills.view.error.buttonTextNotFound"
      />
    );
  }

  if (!bill || !bill.id) {
    return <AreaLoader />;
  }

  const manuallyPaid = payment?.manual;
  const isPaymentFailed = payment?.status === PAYMENT_STATUS.FAILED;
  const isPaymentDeclined = payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED;

  const isRequestAndHasNotDeliveryMethods =
    bill.isVendorRequest() && isEmpty(bill.vendor.deliveryMethods);
  const statusInfo = getStatusInfo(
    { status: getPaymentStatus() },
    theme,
    payment as PaymentType,
    manuallyPaid,
    isRequestAndHasNotDeliveryMethods,
    bill.vendor.companyName
  );
  const isRefund = payment && payment?.metadata?.paymentType === MetadataPaymentTypeEnum.REFUND;

  const title = isRecurring
    ? 'bills.view.recurringPaymentDetails'
    : `bills.view.${isRefundFlowFeature && isRefund ? 'refundDetails' : 'paymentDetails'}`;

  return (
    <StyledQBOLayoutPage goExit={goExit} innerSize={100}>
      <QBOSingleBillContainer>
        <ViewBillContainer>
          {isLoading && <AreaLoader />}

          {bill && !isLoading && (
            <>
              {CancelPaymentModal}
              {refundBlockModal}
              <SingleViewLoadingWrapper className={isLoading ? 'loading' : ''}>
                <BillDetailsHeaderContainer>
                  <PaymentDetailsTitle data-testid="view-payment-page-title">
                    <MIFormattedText label={title} />
                  </PaymentDetailsTitle>
                </BillDetailsHeaderContainer>
                <BillInfoContainer>
                  {statusInfo && <QBOBillStatus statusInfo={statusInfo} />}
                  {payment && isPaymentFailed && !isPaymentDeclined && (
                    <StatusMessageContainer>
                      <StatusMessage
                        bill={bill}
                        payment={payment}
                        onRefundPayment={onRefundPayment}
                      />
                    </StatusMessageContainer>
                  )}
                  <BillPaymentsActivity
                    bill={bill}
                    payment={payment as RecordOf<PaymentType>}
                    isPartialPayment={isPartialPayment}
                  />
                  <ActionsButtons
                    bill={bill}
                    payment={payment as RecordOf<PaymentType>}
                    goExit={goExit}
                    goEditPayment={goEditPayment}
                    onCancelPayment={showCancelPaymentModal}
                    onMarkBillAsUnpaid={onMarkBillAsUnpaid}
                    handleRetryPayment={handleRetryPayment}
                    onResolveCheckReturn={handleResolveCheckReturn}
                    onVoidCheckAndResendPayment={handleVoidCheckAndResendPayment}
                    handleRefund={handleRefundFlowRequest}
                  />
                </BillInfoContainer>
              </SingleViewLoadingWrapper>
            </>
          )}
        </ViewBillContainer>
      </QBOSingleBillContainer>
    </StyledQBOLayoutPage>
  );
};

const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  ${WizardInner} {
    justify-content: initial;
  }
`;

export const StatusMessageContainer = styled.div`
  margin: 2rem 2rem 0;
  font-weight: 500;
`;

const QBOSingleBillContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.mobile} {
    min-height: 100vh;
  }
`;

const ViewBillContainer = styled.div`
  max-width: 45rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  margin-top: 1.6rem;

  @media ${devices.mobile} {
    width: 100%;
    margin-top: 2rem;
  }
`;

const BillInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
`;

const BillDetailsHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PaymentDetailsTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  margin-bottom: 4rem;
`;

const SingleViewLoadingWrapper = styled.div`
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 8rem;
  width: 100%;
  &.loading {
    opacity: 0;
  }
`;
