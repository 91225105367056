import { useIntl } from 'react-intl';
import { Button, IconType, SmartIcon } from '@melio/billpay-design-system';
import { getPaymentMethodIcon } from 'src/app/version-2/utils/paymentMethods.utils';
import { Comment, Container, Fee, getIconSize, Title } from './FundingSource.styles';

interface FundingSourceProps {
  fundingSource: string;
  onClick: () => void;
}

export const FundingSource = ({ fundingSource, onClick }: FundingSourceProps) => {
  const { formatMessage, messages } = useIntl();
  const commentLabel = `fundingSource.sources.${fundingSource}.comment`;
  const comment = messages[commentLabel] ? formatMessage({ id: commentLabel }) : null;
  const isDebit = fundingSource === 'debit';

  return (
    <Container $isDebit={isDebit}>
      <SmartIcon
        type={getPaymentMethodIcon(fundingSource) as IconType}
        width={`${getIconSize(isDebit)}rem`}
        height={`${getIconSize(isDebit)}rem`}
      />
      <Title>
        {formatMessage({ id: `fundingSource.sources.${fundingSource}.title` })}
        <Fee>{formatMessage({ id: `fundingSource.sources.${fundingSource}.fee` })}</Fee>
      </Title>

      <Button variant="primary" onClick={onClick}>
        {formatMessage({ id: `fundingSource.sources.${fundingSource}.link` })}
      </Button>
      {comment ? <Comment>{comment}</Comment> : null}
    </Container>
  );
};
