import { BadgeTheme } from '../components/Badge';
import { ButtonTheme } from '../components/Button';
import { CheckboxTheme } from '../components/Checkbox';
import { InputTheme } from '../components/Input';
import { LinkTheme } from '../components/Link';
import { MenuTheme } from '../components/Menu';
import { MultiLineSelectBoxTheme } from '../components/MultiLineSelectBox';
import { PopoverTheme } from '../components/Popover';
import { SkeletonTheme } from '../components/Skeleton';
import { SwitchTheme } from '../components/Switch';
import { TabsTheme } from '../components/Tabs';
import { TableTheme } from '../components/Table';
import { TagTheme } from '../components/Tag';
import { TooltipTheme } from '../components/Tooltip';
import { FormTheme } from '../components/FormControl';
import { ModalTheme, CoverTheme } from '../components/Modal';
import { CloseButtonTheme } from '../components/CloseButton';

export const Button = ButtonTheme;
export const Badge = BadgeTheme;
export const Checkbox = CheckboxTheme;
export const Input = InputTheme;
export const Link = LinkTheme;
export const Menu = MenuTheme;
export const MultiLineSelectBox = MultiLineSelectBoxTheme;
export const Popover = PopoverTheme;
export const Skeleton = SkeletonTheme;
export const Switch = SwitchTheme;
export const Table = TableTheme;
export const Tabs = TabsTheme;
export const Tag = TagTheme;
export const Tooltip = TooltipTheme;
export const Form = FormTheme;
export const Modal = ModalTheme;
export const CloseButton = CloseButtonTheme;
export const ModalCover = CoverTheme;
