import type { RecordOf } from 'immutable';
import type { BillType, PaymentType, OptionalDeliveryMethodsType } from 'src/app/utils/types';
import { FundingSource } from 'src/app/version-2/model/dtos';
import type { GlobalState } from '../types';
import { getFundingSources } from '../user/selectors';

export const getBill = (state: GlobalState): RecordOf<BillType> => state.payBillWizard.bill;
export const getBillAmount = (state: GlobalState): string => state.payBillWizard.bill.totalAmount;
export const getCompanyName = (state: GlobalState): string =>
  state.payBillWizard.bill.vendor.companyName;
export const getPayment = (state: GlobalState): RecordOf<PaymentType> =>
  state.payBillWizard.payment;
export const getSyncPaymentErrorCode = (state: GlobalState): string | null =>
  state.payBillWizard.syncPaymentErrorCode;
export const getRecurringBill = (state: GlobalState): Record<string, any> =>
  state.payBillWizard.recurringBill;
export const getIsCancelAndRetryPaymentFlow = (state: GlobalState) =>
  state.payBillWizard.isCancelAndRetryPaymentFlow;
export const getIsRecurring = (state: GlobalState): boolean => state.payBillWizard.isRecurring;
export const getIsLoading = (state: GlobalState): boolean => state.payBillWizard.isLoading;
export const getSelectedFundingSource = (state: GlobalState): FundingSource => {
  const fundingSources = getFundingSources(state);
  const payment = getPayment(state);

  return fundingSources.find((fs) => fs.id === payment.fundingSourceId) as FundingSource;
};

export const getDeletedFundingSource = (state: GlobalState): number[] =>
  state.payBillWizard.deletedFundingSources;
export const getDeliveryMethodType = (state: GlobalState): OptionalDeliveryMethodsType => {
  let deliveryType;
  const { deliveryMethodId } = state.payBillWizard.payment;

  if (deliveryMethodId) {
    const deliveryMethod = state.payBillWizard.bill.getDeliveryMethodById(deliveryMethodId);

    deliveryType = deliveryMethod?.deliveryType;
  }

  return deliveryType;
};
export const getErrorCode = (state: GlobalState): string => state.payBillWizard.errorCode as string;
export const getFirstBillIdWithRecurringBill = (state: GlobalState) =>
  state.payBillWizard.firstBillIdWithRecurringBill;
export const getRecurringBillId = (state: GlobalState) => state.payBillWizard.recurringBillId;

export const getFee = (state: GlobalState) => state.payBillWizard.fee;
export const getQBCashState = (state: GlobalState) => state.fundingSources.qbcash;

export const getRedirectUrl = (state: GlobalState) => state.payBillWizard.redirectUrl;
export const getExitUrl = (state: GlobalState) => state.payBillWizard.exitUrl;

export const getInvoiceFileBillId = (billId?: string) => (state: GlobalState) =>
  billId ? state.payBillWizard.invoiceFilesByBillId[billId] : null;

export const getWizardFlow = (state: GlobalState) => state.payBillWizard.flow;

export const getFeeFundingSourceFlow = (state: GlobalState) =>
  state.payBillWizard.feeFundingSourceFlow?.flow;
export const getFeeFundingBankType = (state: GlobalState) =>
  state.payBillWizard.feeFundingSourceFlow?.bankType;
export const isShownUSHolidayLatencyNotification = (state: GlobalState) =>
  state.payBillWizard.notifications.showUSHolidayUSPCLatency;

export const getDeliveryOptions = (state: GlobalState) => state.payBillWizard.deliveryOptions;

export const selectDeliveryOptionsLoading = (state: GlobalState) =>
  state.payBillWizard.deliveryOptionsData.loading;
export const selectDeliveryOptionsData = (state: GlobalState) =>
  state.payBillWizard.deliveryOptionsData.data;
