import { FieldErrors } from 'react-hook-form';
import { MessageDescriptor } from 'react-intl';
import { globalOptInFormFieldNamesEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInFormFieldNamesEnum.enum';
import { globalOptInSteps } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInSteps.enum';
import { OptInBusinessDetailsFormData } from 'src/app/version-2/pages/global-opt-in-details/model/objects/optinBusinessDetailsFormData';
import { OptInPersonalDetailsFormData } from 'src/app/version-2/pages/global-opt-in-details/model/objects/optInPersonalDetailsFormData';

interface TransformPersonalDetailsErrorsToAnalyticsFormatParams<
  T extends OptInPersonalDetailsFormData | OptInBusinessDetailsFormData
> {
  validationErrors: FieldErrors<T>;
  stepName: globalOptInSteps;
  formatMessage(messageDescriptor: MessageDescriptor, values?: Record<string, unknown>): string;
}

export const transformErrorsToAnalyticsFormat = <
  T extends OptInPersonalDetailsFormData | OptInBusinessDetailsFormData
>({
  validationErrors,
  stepName,
  formatMessage,
}: TransformPersonalDetailsErrorsToAnalyticsFormatParams<T>) => {
  const errors = Object.entries(validationErrors).reduce(
    (acc, [fieldName, error]) => ({
      ...acc,
      [fieldName]: error.message,
    }),
    {}
  );

  const errorByStep = {
    [globalOptInSteps.personalDetails]: {
      firstName: errors[globalOptInFormFieldNamesEnum.firstName] ?? null,
      lastName: errors[globalOptInFormFieldNamesEnum.lastName] ?? null,
      email: errors[globalOptInFormFieldNamesEnum.email] ?? null,
      phone: errors[globalOptInFormFieldNamesEnum.phone] ?? null,
    },
    [globalOptInSteps.businessDetails]: {
      businessName: errors[globalOptInFormFieldNamesEnum.businessName] ?? null,
      googlePlaceId: errors[globalOptInFormFieldNamesEnum.googlePlaceId] ?? null,
      fcbStatus: errors[globalOptInFormFieldNamesEnum.fcbStatus] ?? null,
      routingNumber: errors[globalOptInFormFieldNamesEnum.routingNumber] ?? null,
      accountNumber: errors[globalOptInFormFieldNamesEnum.accountNumber] ?? null,
    },
  };

  return Object.keys(errorByStep[stepName])?.map((errKey) => ({
    [errKey]: errorByStep[stepName][errKey]
      ? formatMessage({ id: errorByStep[stepName][errKey] })
      : null,
  }));
};
