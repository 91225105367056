import { DeliveryEnum, FastFeeDeliveryEnum } from 'src/app/version-2/model/enums';

export const EXPEDITED_DELIVERY_TYPES = [
  FastFeeDeliveryEnum.EXPEDITED_ACH,
  FastFeeDeliveryEnum.OVERNIGHT_CHECK,
];

export const FAST_DELIVERY_TYPES = [...EXPEDITED_DELIVERY_TYPES, FastFeeDeliveryEnum.EXPRESS_CHECK];

export const FEE_DELIVERY_TYPES = [DeliveryEnum.CHECK];

export const DELIVERY_OPTION_TYPES = [
  ...EXPEDITED_DELIVERY_TYPES,
  ...FAST_DELIVERY_TYPES,
  ...FEE_DELIVERY_TYPES,
];
