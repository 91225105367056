import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, SmartIcon } from '@melio/billpay-design-system';
import {
  StyledItem,
  StyledSubtitle,
  StyledTitle,
} from 'src/app/version-2/components/CheckFee/CheckFee.styles';
import { analytics } from 'src/app/version-2/externals';
import {
  organizationActions,
  organizationSelectors,
} from 'src/app/version-2/modules/organization/organization.slice';

// Check protection static modal
const CheckFeeModal = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isOpen = useSelector(organizationSelectors.selectIsCheckFeeModalOpen);

  useEffect(() => {
    analytics.track('LearnMoreModal', 'Viewed', { infoType: 'check-fee' });
  });

  const onClose = () => dispatch(organizationActions.setIsCheckFeeModalOpen(false));
  const onModalClose = () => {
    onClose();
    analytics.track('LearnMoreModal', 'Closed', { infoType: 'check-fee' });
  };

  const onModalConfirm = () => {
    onClose();
    analytics.track('LearnMoreModal', 'Checked', { infoType: 'check-fee' });
  };

  return (
    <Modal isOpen={!!isOpen} onClose={onModalClose}>
      <Modal.Header>
        <Modal.HeaderTitle>
          <StyledTitle>
            <SmartIcon type="protect" />
            {formatMessage({ id: `checkFee.modal.title` })}
          </StyledTitle>
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.CloseButton data-testid="modal-close" />
      <Modal.Body>
        <>
          <StyledSubtitle>{formatMessage({ id: `checkFee.modal.description` })}</StyledSubtitle>

          {[1, 2, 3].map((item) => (
            <StyledItem key={item}>
              <SmartIcon type="checked" color="ds.green.200" size="1.9rem" />
              <div>
                <div className="title">{formatMessage({ id: `checkFee.modal.title${item}` })}</div>
                <div className="text">{formatMessage({ id: `checkFee.modal.text${item}` })}</div>
              </div>
            </StyledItem>
          ))}
        </>
      </Modal.Body>

      <Modal.Footer>
        <Modal.ButtonContent isCentred>
          <Modal.Button
            data-testid="modal-button"
            onClick={onModalConfirm}
            size="md"
            variant="primary"
          >
            {formatMessage({ id: 'checkFee.modal.confirm' })}
          </Modal.Button>
        </Modal.ButtonContent>
      </Modal.Footer>
    </Modal>
  );
};

export { CheckFeeModal };
