import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { FormRow } from 'src/app/ui/form/FormElements';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { AreaLoader } from '@melio/billpay-design-system';
import { useApi } from 'src/app/hoc/useApi';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { useForm } from 'src/app/ui/form';
import ValidationError from 'src/app/ui/ValidationError';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import organizationApi from 'src/app/services/api/organizations';
import { useLocationState } from 'src/app/utils/hooks';
import locations from 'src/app/utils/locations';
import { getOrgId } from 'src/app/redux/user/selectors';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  NOTIFICATION_VARIANT,
  NOTIFICATION_CARD_TYPES,
} from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import useLinkIntuitAccount from 'src/app/modules/funding-sources/hooks/useLinkIntuitAccount';
import useCreateAndLinkIntuitAccount from 'src/app/modules/funding-sources/hooks/useCreateAndLinkIntuitAccount';
import useGetFundingSourceDisplayName from 'src/app/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import analytics from 'src/app/services/analytics';
import { pushNotification } from 'src/app/services/notifications';
import { withPreservedStateNavigator } from 'src/app/hoc';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { getFeeFundingBankType, getWizardFlow } from 'src/app/redux/payBillWizard/selectors';
import { BILLING_FEATURE_WIZARD_FLOW } from 'src/app/pages/settings/components/fee/consts';
import { BANK_TYPE } from 'src/app/redux/payBillWizard/types';
import FirstIntuitAccountAddedSuccessPage from './components/FirstIntuitAccountAddedSuccessPage';
import TruncatedMethodCard from './components/TruncatedMethodCard';
import WizardIntuitAccountSelectField from './components/WizardIntuitAccountSelectField';
import { selectFundingSourceAction } from 'src/app/redux/payBillWizard/actions';
import {
  FundingSourceTypesEnum,
  CardNetworkEnum,
  CardTypeEnum,
} from 'src/app/version-2/model/enums';

type Props = {
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  locationState: Record<string, any>;
};

const LinkIntuitAccountToFundingSourcePage = ({
  navigateToExitWithPreservedState,
  navigate,
  navigateWithPreservedState,
  locationState,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const site = useSiteContext();
  const [loadIntuitAccounts, intuitAccountsResult, isIntuitAccountsLoading] = useApi<[string], any>(
    organizationApi.getAccountsPaymentOptions,
    undefined,
    true
  );
  const dispatch = useDispatch();
  const { linkIntuitAccount, isIntuitAccountLinking } = useLinkIntuitAccount();
  const { createAndLinkIntuitAccount, isIntuitAccountCreatingAndLinking } =
    useCreateAndLinkIntuitAccount();
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const [fundingSourceId] = useLocationState('fundingSourceId', {});
  const [exitUrl] = useLocationState('exitUrl', '');
  const [redirectUrl] = useLocationState('redirectUrl', '');
  const [editMode] = useLocationState('editMode', '');
  const [origin] = useLocationState('origin', '');
  const fundingSource: FundingSource = useSelector(
    fundingSourcesStore.selectors.byId(fundingSourceId)
  );
  const wizardFlow = useSelector(getWizardFlow);
  const feeFundingSourceBankType = useSelector(getFeeFundingBankType);
  const currentFundingSourceType =
    fundingSource?.fundingType === FundingSourceTypesEnum.ACH
      ? fundingSource?.fundingType
      : fundingSource?.cardAccount?.cardType || '';
  const fundingSourceDisplayName = getFundingSourceDisplayName({
    fundingSource,
  });
  const [isFirstIntuitAccountAdded, setIsFirstIntuitAccountAdded] = useState(false);
  const currentFundingSourceNetwork = fundingSource?.cardAccount?.network;

  const goExit = () => {
    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  const goNext = useCallback(() => {
    if (wizardFlow === BILLING_FEATURE_WIZARD_FLOW) {
      navigate(locations.Onboarding.fundingSources.fee.checks.complete.url({ orgId }), false, {
        selectedFundingSourceId: fundingSourceId,
      });
    }

    if (
      (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL ||
        origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY) &&
      currentFundingSourceNetwork === CardNetworkEnum.AMEX
    ) {
      // back to funding source - new/edit
      navigate(exitUrl || redirectUrl, false, {
        ...locationState,
        origin,
        newFundingSourceId: fundingSourceId,
      });
    } else if (
      origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.SETTINGS &&
      fundingSource.fundingType === FundingSourceTypesEnum.ACH
    ) {
      navigate(locations.Onboarding.fundingSources.bank.complete.url(), false, {
        ...locationState,
        origin,
        newFundingSourceId: fundingSourceId,
      });
    } else if (navigateWithPreservedState) {
      const originFromNewLp =
        locationState?.preservedState?.originFromNewLp ||
        locationState?.preservedState?.origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.NEW_LANDING_PAGE;

      const data =
        fundingSource?.fundingType === FundingSourceTypesEnum.CARD || originFromNewLp
          ? { selectedFundingSourceId: fundingSourceId }
          : {};

      if (originFromNewLp) {
        dispatch(selectFundingSourceAction(fundingSourceId));
        data['originFromNewLp'] = true;
      }

      navigateWithPreservedState(data);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fireAnalyticsOnAccountLinked = useCallback(() => {
    analytics.trackAction('qbo-account-connected', {
      type: currentFundingSourceType === 'credit' ? 'Credit card' : 'Bank',
      fundingSourceId,
    });
  }, [currentFundingSourceType, fundingSourceId]);

  useEffect(() => {
    loadAccounts();

    async function loadAccounts() {
      try {
        const result = await loadIntuitAccounts(orgId);
        const intuitAccounts = result?.accounts || [];

        if (isEmpty(intuitAccounts)) {
          await createAndLinkIntuitAccount({
            name: fundingSourceDisplayName,
            type: currentFundingSourceType,
            fundingSourceId,
          });
          fireAnalyticsOnAccountLinked();
          setIsFirstIntuitAccountAdded(true);
        }
      } catch (e) {
        if (editMode) {
          pushNotification({
            type: NOTIFICATION_VARIANT.ERROR,
            msg: 'server.ERR',
          });
        }

        goNext();
      }
    }
  }, [
    fireAnalyticsOnAccountLinked,
    loadIntuitAccounts,
    createAndLinkIntuitAccount,
    fundingSourceId,
    currentFundingSourceType,
    fundingSourceDisplayName,
    orgId,
    goNext,
    editMode,
  ]);

  const onSubmitIntuitAccount = async ({ intuitAccountId }) => {
    if (intuitAccountId) {
      try {
        if (intuitAccountId === 'new') {
          await createAndLinkIntuitAccount({
            name: fundingSourceDisplayName,
            type: currentFundingSourceType,
            fundingSourceId,
          });
        } else {
          await linkIntuitAccount({
            fundingSourceId,
            intuitAccountId,
          });
        }

        fireAnalyticsOnAccountLinked();

        if (wizardFlow === BILLING_FEATURE_WIZARD_FLOW) {
          if (feeFundingSourceBankType === BANK_TYPE.UNVERIFIED) {
            navigate(
              locations.Onboarding.fundingSources.fee.checks.bank.pendingBankVerification.url(),
              false,
              {
                newUnverifiedBank: fundingSourceId,
              }
            );
          } else {
            navigate(
              locations.Onboarding.fundingSources.fee.checks.selectFundingSource.url({ orgId }),
              false,
              {
                newUnverifiedBank: fundingSourceId,
              }
            );
          }
        } else {
          goNext();
        }
      } catch (e) {
        const validationErrors = {
          intuitAccountId: 'onboarding.fundingSources.bank.intuitAccounts.errors.exist',
        };

        throw new ValidationError({ validationErrors });
      }
    } else {
      const validationErrors = {
        intuitAccountId: 'onboarding.fundingSources.bank.intuitAccounts.errors.required',
      };

      throw new ValidationError({ validationErrors });
    }
  };
  const model = useMemo(
    () => ({ intuitAccountId: fundingSource?.intuitAccountId }),
    [fundingSource]
  );
  const [intuitAccountVM, { submit }] = useForm(model, {
    submit: onSubmitIntuitAccount as any,
  });

  const notificationLabel =
    origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL ||
    ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY
      ? 'onboarding.fundingSources.bank.intuitAccounts.paymentNotification'
      : 'onboarding.fundingSources.bank.intuitAccounts.notification';
  const { fundingTypeText, nextLabel } = getLabels(fundingSource);

  const isFirstIntuitAccountCreating =
    isIntuitAccountCreatingAndLinking && isEmpty(intuitAccountsResult?.accounts);

  if (isIntuitAccountsLoading || isFirstIntuitAccountCreating) {
    return <AreaLoader />;
  }

  if (isFirstIntuitAccountAdded) {
    return <FirstIntuitAccountAddedSuccessPage fundingSource={fundingSource} goNext={goNext} />;
  }

  const isFormSubmitting = isIntuitAccountCreatingAndLinking || isIntuitAccountLinking;

  const clickOutsideHandler = (props: { isDropDownOpen: boolean }) => {
    if (props.isDropDownOpen) {
      intuitAccountVM.setModelState({ value: '' });
    }
  };

  return (
    <site.components.StepLayout
      title="onboarding.fundingSources.bank.intuitAccounts.title"
      titleValues={{ type: <MIFormattedText label={fundingTypeText} /> }}
      subtitle="onboarding.fundingSources.bank.intuitAccounts.subtitle"
      goExit={goExit}
      onNext={submit}
      nextLabel={nextLabel}
      isLoading={isFormSubmitting}
      fullWidthCTA
      hideHeader
    >
      <MainContentContainer>
        {intuitAccountVM.intuitAccountId.value === 'new' && (
          <NotificationContainer>
            <QBONotificationCard
              type={NOTIFICATION_CARD_TYPES.INFO}
              subtitle={{
                label: notificationLabel,
                values: {
                  displayName: fundingSourceDisplayName,
                },
              }}
            />
          </NotificationContainer>
        )}
        <WrappedTruncatedMethodCard method={fundingSource as any} />
        <FormRow>
          <WizardIntuitAccountSelectField
            fundingSource={fundingSource}
            intuitAccounts={intuitAccountsResult?.accounts}
            model={intuitAccountVM.intuitAccountId}
            clickOutsideHandler={clickOutsideHandler}
          />
        </FormRow>
      </MainContentContainer>
    </site.components.StepLayout>
  );
};

const getLabels = (fundingSource: FundingSource) => {
  const fundingSourceType =
    fundingSource?.fundingType === FundingSourceTypesEnum.ACH
      ? fundingSource?.fundingType
      : fundingSource?.cardAccount?.cardType;
  let fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.ach';
  let nextLabel = 'onboarding.fundingSources.bank.intuitAccounts.linkAch';

  if (fundingSourceType === CardTypeEnum.DEBIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.debitAccount';
    nextLabel = 'onboarding.fundingSources.bank.intuitAccounts.linkDebit';
  } else if (fundingSourceType === CardTypeEnum.CREDIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.intuitAccounts.creditAccount';
    nextLabel = 'onboarding.fundingSources.bank.intuitAccounts.linkCredit';
  }

  return { fundingTypeText, nextLabel };
};

export default compose(withPreservedStateNavigator())(LinkIntuitAccountToFundingSourcePage);

const NotificationContainer = styled.div`
  margin-bottom: 2rem;
`;

const WrappedTruncatedMethodCard = styled(TruncatedMethodCard)`
  margin-bottom: 2rem;
`;

const MainContentContainer = styled.div`
  margin-top: -0.5rem;
  margin-bottom: -3rem;
`;
