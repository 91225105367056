import React from 'react';
import { HStack } from '@melio/billpay-design-system';

import analytics from 'src/app/services/analytics';
import PaginationPrevious from './components/PaginationPrevious';
import PaginationNext from './components/PaginationNext';
import PaginationPage from './components/PaginationPage';
import { getVisiblePages } from './utils';

type Props = {
  pageIndex: number;
  pageCount: number;
  goToPage: (page: number) => void;
};
const eventPage = 'pagination';

const QBOPagination = ({ pageIndex, pageCount, goToPage }: Props) => {
  const visiblePages = getVisiblePages(pageIndex, pageCount);
  const goToFirstPage = () => {
    analytics.trackAction(`${eventPage}-first-page`, { page: 0 });
    goToPage(0);
  };
  const goToLastPage = () => {
    analytics.trackAction(`${eventPage}-last-page`, { page: pageCount - 1 });
    goToPage(pageCount - 1);
  };
  const goToPreviousPage = () => {
    analytics.trackAction(`${eventPage}-previous-page`, {
      page: pageIndex - 1,
    });
    goToPage(pageIndex - 1);
  };
  const goToNextPage = () => {
    analytics.trackAction(`${eventPage}-next-page`, { page: pageIndex + 1 });
    goToPage(pageIndex + 1);
  };

  return (
    <HStack spacing={3}>
      <PaginationPrevious
        canPreviousPage={pageIndex > 0}
        goToFirstPage={goToFirstPage}
        goPreviousPage={goToPreviousPage}
      />
      {visiblePages.map((index) => (
        <PaginationPage
          key={index}
          page={index}
          isSelected={index === pageIndex}
          onClick={() => {
            analytics.trackAction(`${eventPage}-go-page`, { page: index });
            goToPage(index);
          }}
        />
      ))}
      <PaginationNext
        canNextPage={pageIndex < pageCount - 1}
        goToLastPage={goToLastPage}
        goToNextPage={goToNextPage}
      />
    </HStack>
  );
};

export default QBOPagination;
