import * as React from 'react';
import styled, { css } from 'styled-components';
import { History } from 'history';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { SmartLink } from 'src/app/modules/navigation/components/SmartLink';
import intercomService from 'src/app/services/intercom';
import { GlobalState } from 'src/app/redux/types';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { MIFormattedText } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import locations from '../../utils/locations';
import qboLogo from '../../images/qbo/logo-qbo-footer-with-jpm-no-svb.svg';
import QBODrawer from './QBODrawer';
import analytics from '../../services/analytics';
import { withNavigator, withBreak } from '../../hoc';
import { melioClose } from '../../utils/external-events';

const commonStyles = css`
  display: flex;
  align-items: center;
`;

const QBRLayoutContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  position: relative;
`;

type QBRHeaderMapDispatchToProps = {
  setUrlToBack: (url: string) => void;
  setIsFaqOpen: (isOpen: boolean) => void;
};

type QBRHeaderMapStateToProps = {
  orgId: string;
};

type QBRHeaderProps = {
  goExit?: () => void;
  onPrev?: () => void | null;
  headerLabel?: string;
  headerLabelValues?: Record<string, string>;
  history: History;
  device: Record<string, any>;
} & QBRHeaderMapDispatchToProps &
  QBRHeaderMapStateToProps;

type State = {
  isHeaderClosed: boolean;
};

const eventPage = 'qbo';

class QBRHeaderContainerClass extends React.Component<QBRHeaderProps, State> {
  static defaultProps = {
    onPrev: null,
    headerLabel: '',
    headerLabelValues: {},
  };

  constructor(props: QBRHeaderProps) {
    super(props);

    this.state = {
      isHeaderClosed: false,
    };
  }

  onPrev = () => {
    const { onPrev } = this.props;

    analytics.track(`${eventPage}-pay-bill-flow`, 'view-prev-page');

    if (onPrev) {
      onPrev();
    }
  };

  onSettingsClick = () => {
    this.props.setUrlToBack(
      this.props.history.location.pathname + this.props.history.location.search
    );
    analytics.track(eventPage, 'settings');
  };

  onDrawerClose = () => {
    analytics.track(eventPage, 'need-help-close');
  };

  openDrawer = () => {
    const { setIsFaqOpen } = this.props;

    setIsFaqOpen(true);
    analytics.track(eventPage, 'need-help-open');
  };

  openSupportChat = () => {
    analytics.track(eventPage, 'support-open-start-chat');
    intercomService.show();
  };

  closeIframe = () => {
    const { goExit } = this.props;

    analytics.track(`${eventPage}-pay-bill-flow`, 'close-integration');
    this.setState({ isHeaderClosed: true });

    if (goExit) {
      goExit();
    } else {
      melioClose();
    }
  };

  render() {
    const { onPrev, goExit, headerLabel, orgId, headerLabelValues } = this.props;
    const { isHeaderClosed } = this.state;
    const isMobile = this.props.device?.isMobile;

    return (
      <>
        <QBRHeader isBottomBorder={headerLabel}>
          <HeaderContent>
            {onPrev && (
              <Back onClick={this.onPrev}>
                <i className="icon-back-icon" />
              </Back>
            )}
            {headerLabel && !isHeaderClosed && (
              <HeaderText data-testid="qbo-header-text">
                <MIFormattedText
                  label={headerLabel}
                  values={{
                    breakLine: isMobile ? <br /> : <></>,
                    ...headerLabelValues,
                  }}
                />
              </HeaderText>
            )}
          </HeaderContent>
          <HeaderButtonContent flexEnd={!onPrev}>
            {onPrev && (
              <BackMobile onClick={this.onPrev} data-testid="back-mobile-button">
                <i className="icon-back-arrow" />
              </BackMobile>
            )}
            <ButtonContainer>
              <ButtonText>
                <HelpText onClick={this.openSupportChat}>
                  <HeaderContentIcon className="icon-support-icon-streak" />
                  <ButtonText>
                    <MIFormattedText label="qbo.header.support" />
                  </ButtonText>
                </HelpText>
              </ButtonText>

              <HelpText onClick={this.openDrawer}>
                <HeaderContentIcon className="icon-help-icon" />
                <ButtonText>
                  <MIFormattedText label="qbo.header.help" />
                </ButtonText>
              </HelpText>
              <SettingsLink
                to={locations.Settings.index.url({ orgId })}
                onClick={this.onSettingsClick}
              >
                <HeaderContentIcon className="icon-settings-icon" />
                <ButtonText>
                  <MIFormattedText label="qbo.header.settings" />
                </ButtonText>
              </SettingsLink>
              {goExit && (
                <Close data-testid="qbo-close-page" onClick={this.closeIframe}>
                  <i className="icon-close-icon" />
                </Close>
              )}
            </ButtonContainer>
          </HeaderButtonContent>
        </QBRHeader>
        <QBODrawer onCloseCallback={this.onDrawerClose} />
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState): QBRHeaderMapStateToProps => ({
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch): QBRHeaderMapDispatchToProps => ({
  setUrlToBack(url: string) {
    dispatch(applicationActions.setUrlToBack(url));
  },
  setIsFaqOpen(isOpen: boolean) {
    dispatch(applicationActions.setFaqPanel({ isOpen }));
  },
});

const QBRHeaderContainer = compose(
  withBreak(),
  connect(mapStateToProps, mapDispatchToProps),
  withNavigator()
)(QBRHeaderContainerClass);

const QBRHeader = styled.div<{ isBottomBorder?: string }>`
  ${commonStyles};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  height: 5rem;
  background-color: rgba(244, 245, 248);
  color: ${(props) => props.theme.text.color.main};
  border-bottom: ${(props) => (props.isBottomBorder ? '0.2rem solid rgba(216, 216, 216)' : 'none')};
  font-size: 1.4rem;
  padding: 0 1.4rem;

  @media ${devices.mobile} {
    font-size: 1.2rem;
    height: auto;
    background: ${(props) => props.theme.colors.white.opaque};
    flex-direction: column-reverse;
    padding: 1.6rem;
    color: ${(props) => props.theme.text.color.main};
    ${(props) => props.theme?.components?.QBOHeaderContainerClass?.QBOHeader}
  }
`;

const HeaderContent = styled.div`
  ${commonStyles};
  ${(props) => props.theme?.components?.QBOHeaderContainerClass?.HeaderContent}
`;
const HeaderButtonContent = styled.div<{ flexEnd?: boolean }>`
  ${commonStyles};
  display: flex;
  @media ${devices.mobile} {
    align-self: flex-end;
    justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'space-between')};
    width: inherit;
    ${(props) => props.theme?.components?.QBOHeaderContainerClass?.HeaderButtonContent}
  }
`;

const HeaderText = styled.div`
  strong {
    font-weight: 600;
  }
  @media ${devices.mobile} {
    text-align: center;
    font-size: ${({ theme }) => theme.text.size.regular};
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }
`;

const HeaderContentIcon = styled.i`
  color: rgba(107, 108, 114, 1);
  font-size: 2.4rem;
  @media ${devices.mobile} {
    color: ${(props) => props.theme.colors.brand};
    font-size: 2.8rem;
  }
`;

const HelpText = styled.div`
  ${commonStyles};
  cursor: pointer;
  margin-right: 2rem;
  @media ${devices.mobile} {
    margin: 0;
  }
`;
const ButtonText = styled.div`
  @media ${devices.mobile} {
    display: none;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
`;

const SettingsLink = styled(SmartLink)`
  ${commonStyles};
  margin-right: 3.6rem;
  text-decoration: none;
  border: none;
  color: ${(props) => props.theme.colors.text};
  &:active {
    color: ${(props) => props.theme.colors.text};
  }
  @media ${devices.mobile} {
    display: none;
  }
`;

const navIconStyles = css`
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  color: ${(props) => props.theme.text.color.label};
  cursor: pointer;
  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 2.8rem;
    width: 2.8rem;
    height: 2.8rem;
  }
`;

const Close = styled.div`
  ${navIconStyles};
  @media ${devices.mobile} {
    color: ${(props) => props.theme.colors.brand};
    ${(props) => props.theme?.components?.QBOHeaderContainerClass?.Close}
  }
`;

const Back = styled.div`
  ${navIconStyles};
  padding-right: 1.4rem;
  @media ${devices.mobile} {
    display: none;
  }
`;
const BackMobile = styled.div`
  display: none;
  @media ${devices.mobile} {
    display: block;
    ${navIconStyles};
    color: ${(props) => props.theme.colors.brand};
    min-width: 7.6rem;
  }
  ${(props) => props.theme?.components?.QBOElements?.BackMobile}
`;

const QBRFooterContainer = () => {
  const site = useSiteContext();

  return (
    <QBRFooter>
      <MelioFooterLogo>
        <QBRLogo src={qboLogo} alt="qboLogo" />
      </MelioFooterLogo>
      <MelioFooterText>
        <MIFormattedText label="qbo.footer.poweredBy" />
      </MelioFooterText>
      <FooterSettingsTextWrap>
        <FooterSettingsText
          href={site.config.agreementLinks.termsOfService}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MIFormattedText label="qbo.footer.termsOfService" />
        </FooterSettingsText>
        <FooterSettingsText
          href={site.config.agreementLinks.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MIFormattedText label="qbo.footer.privacyPolicy" />
        </FooterSettingsText>
      </FooterSettingsTextWrap>
    </QBRFooter>
  );
};

QBRFooterContainer.defaultProps = {};

const QBRLogo = styled.img`
  height: 2.4rem;
`;

const QBRFooter = styled.div`
  ${commonStyles};
  color: rgba(113, 113, 115);
  font-size: 1rem;
  width: 100%;
  padding: 0 1.6rem;
  box-sizing: border-box;
  margin-top: auto;
  height: 4rem;
  background-color: rgba(236, 237, 240);

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1.6rem 1.2rem;
    height: auto;
    min-height: 8.8rem;
  }
  ${(props) => props.theme?.components?.QBOFooterContainer?.QBOFooter}
`;

const MelioFooterLogo = styled.div`
  padding-right: 1.6rem;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    padding: 0;
    margin-bottom: 0.8rem;
  }
`;

const FooterSettingsTextWrap = styled.div`
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: flex;
    flex-direction: row;
    > a {
      margin: 0;
    }
    > a:last-child {
      margin-left: 1.5rem;
    }
  }
`;

const FooterSettingsText = styled.a`
  text-decoration: none;
  color: rgba(28, 130, 210);
  margin-left: 1.6rem;
  &:hover {
    text-decoration: underline;
  }
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: flex;
  }
`;

const MelioFooterText = styled.a`
  line-height: 1.4rem;
  letter-spacing: -0.01rem;
  strong {
    font-weight: 600;
  }
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    text-align: center;
    margin-bottom: 0.8rem;
  }
`;

export {
  QBRLayoutContainer,
  QBRHeaderContainer,
  QBRFooterContainer,
  QBRHeader,
  HeaderContent,
  SettingsLink,
  Close,
  Back,
  BackMobile,
};
