import React from 'react';
import {
  CreditCardBenefitIconWrapper,
  CreditCardBenefitTextWrapper,
  CreditCardBenefitTileStyled,
} from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefits.styled';
import { MIFormattedText } from 'src/app/utils/formatting';
import Box from 'src/app/core/ds/box/Box';

export interface CreditCardBenefitTileProps {
  icon: any;
  title: string;
  text: string;
  textValues?: Record<string, any>;
  isBottomBanner?: boolean;
}

export const CreditCardBenefitTile = (props: CreditCardBenefitTileProps) => {
  const { icon: Icon, text, textValues, title, isBottomBanner } = props;

  return (
    <CreditCardBenefitTileStyled isBottomBanner={isBottomBanner}>
      <CreditCardBenefitIconWrapper isBottomBanner={isBottomBanner}>
        <Icon />
      </CreditCardBenefitIconWrapper>
      <CreditCardBenefitTextWrapper isBottomBanner={isBottomBanner}>
        <Box textStyle="body2" fontWeight="ds.medium">
          <MIFormattedText label={title} />
        </Box>
        <Box textStyle="body3">
          <MIFormattedText label={text} values={textValues} />
        </Box>
      </CreditCardBenefitTextWrapper>
    </CreditCardBenefitTileStyled>
  );
};
