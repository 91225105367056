import React from 'react';
import { FieldError } from '@basis-theory/basis-theory-js/types/elements/shared';
import { FieldErrorMessage } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/common/FieldErrorMessage/FieldErrorMessage';
import { FieldErrorsContainer } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/common/FieldErrors/FieldErrors.styles';

export interface FieldErrorsProps {
  testId: string;
  empty: boolean;
  emptyLabel: string;
  invalidLabel: string;
  fieldErrors?: FieldError[];
}

export const FieldErrors = ({
  testId,
  fieldErrors,
  empty,
  emptyLabel,
  invalidLabel,
}: FieldErrorsProps) => (
  <FieldErrorsContainer data-testid={testId}>
    {empty ? <FieldErrorMessage label={emptyLabel} /> : null}

    {fieldErrors?.map((error, index) =>
      error.type === 'invalid' || (error.type === 'incomplete' && !empty) ? (
        <FieldErrorMessage key={index} label={invalidLabel} />
      ) : null
    )}
  </FieldErrorsContainer>
);
