import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import billsStore from 'src/app/modules/bills/bills-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { BillType } from 'src/app/utils/types';

type Params = {
  billIds: string[];
};

type BillsMetadata = {
  id: string;
  balance: number;
  amount: number | null;
}[];

const useMarkAsPaidBillsMetadata = ({ billIds }: Params): [BillsMetadata] => {
  const bills: BillType[] = useSelector(billsStore.selectors.getByIds(billIds));
  const billAmountDict: Record<string, number> = useSelector(
    qbDashboardListItemsStore.selectors.partialPaymentsAmounts.all
  );
  const items = useMemo(
    () =>
      bills.map((bill) => ({
        id: bill.id,
        balance: bill.balance,
        amount: billAmountDict[bill.id] || null,
      })),
    [bills, billAmountDict]
  );

  return [items as BillsMetadata];
};

export { useMarkAsPaidBillsMetadata };
