import { loggingApi } from 'src/app/version-2/api/loggers';
import { analytics } from 'src/app/version-2/externals';
import { FundingSourcePageVariantsEnum } from 'src/app/version-2/model/enums';
import { paymentsSelectors } from 'src/app/version-2/modules/payments/payments.slice';
import { EVENT_PAGE, EXPERIMENT_NAME } from 'src/app/version-2/pages/funding-source/model/consts';
import { EventNameEnum } from 'src/app/version-2/pages/funding-source/model/enums';
import { getLpExperimentVariant, shouldSendEvent } from 'src/app/version-2/utils/experimental.util';
import { fundingSourceActions } from './fundingSource.slice';

import { select, takeEvery } from 'redux-saga/effects';

function* sendFundingSourceEventsHandler(
  action: ReturnType<typeof fundingSourceActions.sendEvents>
) {
  const { fundingSources, bill, featureFlags, isFundingSourceExperiment = true } = action.payload;
  const { fundingSourceVariant = FundingSourcePageVariantsEnum.CONTROL } = featureFlags;

  const payments = yield select(paymentsSelectors.selectPayments);
  const sendEvent = shouldSendEvent(isFundingSourceExperiment, fundingSources, !!payments.length);
  const variantName = getLpExperimentVariant(
    fundingSourceVariant,
    fundingSources,
    !!payments.length
  );
  let props = { featureFlags, fundingSources, sendEvent, isFundingSourceExperiment };
  const experimentProps = { fundingSourceVariant, payments: payments.length };

  if (sendEvent) {
    props = {
      ...props,
      experimentName: EXPERIMENT_NAME,
      experimentVariant: variantName,
      billIds: [bill?.id],
    };
  }

  loggingApi.info('fundingSource.saga.sendFundingSourceEventsHandler(): Funding source event', {
    ...props,
    ...experimentProps,
  });

  if (sendEvent) {
    props = {
      ...props,
      ...experimentProps,
      organizationId: bill?.organizationId,
      userId: bill?.createdById,
      variant: variantName,
    };

    loggingApi.info(
      `fundingSource.saga.sendFundingSourceEventsHandler(): Experiment funding source variant ${variantName}`,
      props
    );
    analytics.track(EVENT_PAGE, EventNameEnum.experimentLandingPageFundingSource, props);
  }
}

export function* watchFundingSource() {
  yield takeEvery(fundingSourceActions.sendEvents, sendFundingSourceEventsHandler);
}
