import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { StatusInfoType } from 'src/app/utils/types';
import { devices } from 'src/app/theme/AppDevices';

type Props = {
  statusInfo: StatusInfoType;
  className?: string;
};

const BillStatus = ({ statusInfo, className }: Props) => {
  const { color, icon, label, description, descriptionValues, status } = statusInfo;

  return (
    <StatusContainer statusInfo={statusInfo} className={className}>
      <Icon color={color} label={label} status={status}>
        {typeof icon === 'string' ? <i className={icon} /> : icon}
      </Icon>
      <Status color={color} status={status}>
        <MIFormattedText label={label} />
        {description && (
          <StatusDescriptionText>
            <MIFormattedText label={description} values={descriptionValues} />
          </StatusDescriptionText>
        )}
      </Status>
    </StatusContainer>
  );
};

export default BillStatus;

const StatusContainer = styled.div<{ statusInfo?: { colorOpacity?: string } }>`
  display: flex;
  align-items: center;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${(props) => props.statusInfo?.colorOpacity};
  padding: 0 3rem;

  @media ${devices.mobile} {
    padding: 0 1.5rem;
  }

  ${(props) => props.theme?.components?.BillStatus?.StatusContainer}
`;

const Status = styled.div`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 1.2rem 0 1.2rem 1.3rem;
  text-align: left;
  align-self: center;

  @media ${devices.mobile} {
    padding: 1.2rem 0 1.2rem 0.4rem;
  }
  ${(props) => props.theme.text.fontType.medium};
  ${(props) => props.theme?.components?.BillStatus?.Status}
`;

const Icon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.color};
  margin: 1.9rem 1.1rem 1.5rem 0;
  ${(props) => props.theme?.components?.BillStatus?.Icon}
`;

const StatusDescriptionText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.regular};
  opacity: 0.7;
  ${(props) => props.theme?.components?.BillStatus?.StatusDescriptionText}
`;
