import { Payment } from 'src/app/version-2/model/dtos/payment';
import { VirtualCardPaymentState } from 'src/app/version-2/model/enums/virtualCardPaymentState.enum';

const paymentStateMapping = {
  [VirtualCardPaymentState.Unknown]: 'unknown',
  [VirtualCardPaymentState.Valid]: 'valid',
  [VirtualCardPaymentState.Cancelled]: 'cancelled',
  [VirtualCardPaymentState.Processed]: 'processed',
  [VirtualCardPaymentState.NewDeliveryMethod]: 'new-delivery-method',
  [VirtualCardPaymentState.NewDeliveryMethodProcessed]: 'new-delivery-method-processed',
  [VirtualCardPaymentState.Expired]: 'expired',
  [VirtualCardPaymentState.Recovered]: 'recovered',
  [VirtualCardPaymentState.RecoverySuccess]: 'recovery-success',
  [VirtualCardPaymentState.NewDeliveryMethodScheduled]: 'shift-vc-to-ach',
};

type Result = Record<string, string | number | undefined>;

interface GetPaymentStateChangedPropertiesProps {
  payment?: Payment;
  paymentState: VirtualCardPaymentState;
  extraProperties?: Record<string, string | number | undefined>;
}

export const getPaymentStateChangedProperties = ({
  payment,
  paymentState,
  extraProperties = {},
}: GetPaymentStateChangedPropertiesProps): Result => ({
  state: paymentStateMapping[paymentState],
  paymentId: payment?.id,
  vendorId: payment?.vendor?.id,
  vendorEmail: payment?.vendor?.contactEmail,
  ...extraProperties,
});
