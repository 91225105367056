import React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIDotsActionsMenu from 'src/app/components/common/MIDotsActionsMenu';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import { ActionOption } from 'src/app/components/common/MIActionsMenu';
import { devices } from 'src/app/theme/AppDevices';
import {
  MethodOptionDescription,
  MethodOptionOnTopDescription,
  MethodOptionLabel,
  MethodOptionLogo,
} from './MethodElements';

type SettingsMethodsListItemProps = {
  id: string;
  icon?: string;
  imageSrc?: string;
  label: string;
  labelValues?: Record<string, any>;
  description: string;
  descriptionValues?: Record<string, any>;
  onTopDescription?: string | null;
  disabled?: boolean;
  actionOptions: ActionOption[];
  onClick?: (event: any) => void;
  isEmptyList?: boolean;
  isExpired?: boolean;
  hint: string;
  hintValues?: Record<string, any>;
  origin: string;
};

export const SettingsMethodsListItem: React.FC<SettingsMethodsListItemProps> = ({
  id,
  icon,
  imageSrc,
  label,
  labelValues = {},
  description,
  descriptionValues = {},
  onTopDescription,
  disabled,
  onClick,
  actionOptions,
  isEmptyList,
  isExpired,
  hint,
  hintValues,
  origin,
}) => {
  const showActionOptions = actionOptions && !!actionOptions.length;

  return (
    <SettingsMethodsListItemContainer disabled={disabled} data-testid={`radio-select-method-${id}`}>
      <SettingsMethodsItemContainer isEmptyList={isEmptyList}>
        <MethodOptionLogo
          icon={icon}
          imageSrc={imageSrc}
          alt="option-icon"
          disabled={disabled}
          isExpired={isExpired}
        />
        <MethodInfoContainer>
          <MethodOptionLabel disabled={disabled}>
            {onTopDescription && (
              <MethodOptionOnTopDescription>{onTopDescription}</MethodOptionOnTopDescription>
            )}
            {label && <MIFormattedText label={label} values={labelValues} />}
            {hint && (
              <PaymentInfoAdditionalText isExpired={isExpired}>
                <MIFormattedText label={hint} values={hintValues} />
              </PaymentInfoAdditionalText>
            )}
          </MethodOptionLabel>
          {description && (
            <MethodOptionDescription disabled={disabled} isExpired={isExpired}>
              <MIFormattedText label={description} values={descriptionValues} />
            </MethodOptionDescription>
          )}
        </MethodInfoContainer>
      </SettingsMethodsItemContainer>
      {isEmptyList && (
        <StyledMIInlineLinkContainer>
          <StyledMIInlineLink label="settings.paymentMethods.hint" onClick={onClick} />
        </StyledMIInlineLinkContainer>
      )}
      {showActionOptions && <MIDotsActionsMenu actionOptions={actionOptions} />}
    </SettingsMethodsListItemContainer>
  );
};

const SettingsMethodsListItemContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  border-radius: 0.8rem;
  border: ${(props) => `0.1rem solid ${props.theme.colors.border.grey}`};
  background-color: ${(props) => props.theme.colors.white.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1.5rem;
  ${(p) =>
    !p.disabled &&
    css`
      &:hover {
        box-shadow: none;
      }
    `}
`;

const SettingsMethodsItemContainer = styled.div<{ isEmptyList?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 1.5rem 0;
  ${(props) => props.theme.components?.SettingsMethodsListItem?.SettingsMethodsItemContainer}
`;

const MethodInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${devices.mobile} {
    max-width: 17rem;
  }
  width: 100%;
`;

const StyledMIInlineLinkContainer = styled.div`
  display: flex;
  align-self: center;
`;
const StyledMIInlineLink = styled(MIInlineLink)`
  display: flex;
  top: 2rem;
  right: 2rem;
  align-items: center;
`;

const PaymentInfoAdditionalText = styled.span<{ isExpired?: boolean }>`
  color: ${(props) =>
    props.isExpired ? props.theme.text.color.red : props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.regular};
  white-space: nowrap;
`;

export default SettingsMethodsListItem;
