import React from 'react';
import styled from 'styled-components';
import { Box, Center, Image } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { HeadingTextStyle, TextStyle } from './styles';

type Props = {
  image?: string;
  title: string;
  description: string;
  descriptionValues?: Record<string, any>;
  primaryBtnText?: string;
  primaryBtnOnClick?: () => void;
  secondaryBtnText?: string;
  secondaryBtnOnClick?: () => void;
};

export const DashboardEmptyStateContent = (props: Props) => (
  <Center h="100%">
    <Box textAlign="center" w="45rem">
      {props.image && <Image src={props.image} mb="1rem" h="12rem" w="12rem" />}
      <Box as="h2" __css={HeadingTextStyle} data-testid="empty-state-title">
        <MIFormattedText label={props.title} />
      </Box>
      <Box as="p" __css={TextStyle} data-testid="empty-state-description">
        <MIFormattedText label={props.description} values={props.descriptionValues} />
      </Box>
      {props.primaryBtnText && props.primaryBtnOnClick && (
        <Box>
          <StyledPrimaryBtn>
            <MIButton
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              label={props.primaryBtnText}
              onClick={props.primaryBtnOnClick}
              fullWidth
              testId="empty-state-primary-btn"
            />
          </StyledPrimaryBtn>
        </Box>
      )}
      {props.secondaryBtnText && props.secondaryBtnOnClick && (
        <Box>
          <MIButton
            variant={CONSTS.BUTTON_VARIANT.SECONDARY}
            label={props.secondaryBtnText}
            onClick={props.secondaryBtnOnClick}
            fullWidth
            testId="empty-state-secondary-btn"
          />
        </Box>
      )}
    </Box>
  </Center>
);

const StyledPrimaryBtn = styled.div`
  margin: 24px 0 10px 0;
`;
