import dashboard from './dashboard/locations';
import bill from './bill/locations';
import vendor from './vendor/locations';
import orgs from './orgs/locations';
import qbDashboard from './qb-dashboard/locations';
import quickpay from './quickpay/locations';
import justPay from './just-pay/justPayLocations';
import entrypoints from './quickbooks/locations';

const LOCATIONS = {
  dashboard,
  bill,
  vendor,
  orgs,
  quickpay,
  qbDashboard,
  justPay,
  codeVerification: '/register/code-verification',
  authCodeVerification: '/auth/code-verification',
  entrypoints,
};

export default LOCATIONS;
