import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import doneImage from 'src/app/images/qbr/success-icon.svg';
import { devices } from 'src/app/theme/AppDevices';

type QBROutsideHeaderProps = {
  redirectUrl?: string;
  hideLogin?: boolean;
  headerTitle: string;
};

const QBROutsideHeader: React.FC<QBROutsideHeaderProps> = ({
  headerTitle,
}: QBROutsideHeaderProps) => (
  <QBROutsideHeaderContainer>
    <DoneIcon src={doneImage} />
    <Title data-testid="success-title">
      <MIFormattedText label={headerTitle} />
    </Title>
  </QBROutsideHeaderContainer>
);

export default QBROutsideHeader;

const DoneIcon = styled.img`
  height: 6rem;
  width: 6rem;
  margin-bottom: 3.2rem;

  @media ${devices.desktop}, ${devices.tablet} {
    margin-bottom: 1.4rem;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.text.color.white};
  font-weight: ${(props) => props.theme.text.weight.medium};
  ${(props) => props.theme.text.fontType.huge};
`;

const QBROutsideHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 32.8rem;
  background: ${(props) =>
    `linear-gradient(225deg, ${props.theme.colors.gradient.start} 0%, ${props.theme.colors.gradient.end} 100%)`};
  padding: 0 1.6rem 2.4rem;

  @media ${devices.desktop}, ${devices.tablet} {
    max-height: 20.4rem;
    min-height: 20.4rem;
    width: 49.8rem;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
  }
`;
