import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';

import { useBreak } from 'src/app/hoc';
import { setIsCancelAndRetryFlowAction } from 'src/app/redux/payBillWizard/actions';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { getExitUrl } from 'src/app/redux/payBillWizard/selectors';
import locations from 'src/app/utils/locations';
import { serializePaymentId } from 'src/app/utils/bills';

import { RECOVERY_FLOW_TYPE } from '../consts';
import { ChooseDeliveryDate } from '../RecoveryFlowSteps/StepChooseDeliveryDate/ChooseDeliveryDate';
import { StepChooseDeliveryMethod } from '../RecoveryFlowSteps/StepChooseDeliveryMethod';
import { StepMissingACHDetails } from '../RecoveryFlowSteps/StepMissingACHDetails';

import voidLocations from './locations';

type Props = {
  id: string | undefined;
  paymentId: string | undefined;
};

const VoidCheckRecoveryFlowPage = ({ id, paymentId }: Props) => {
  const { isMobile } = useBreak();
  const dispatch = useDispatch();
  const mobileExitUrl = locations.Bills.filteredView.url({
    id: serializePaymentId(id as string, paymentId as string),
  });
  const exitUrlSelector = useSelector(getExitUrl);
  const exitUrl = isMobile ? mobileExitUrl : exitUrlSelector;

  useEffect(() => {
    dispatch(setIsCancelAndRetryFlowAction(true));
  }, []);

  return (
    <Switch>
      <SmartRoute path={voidLocations.index} exact>
        <StepChooseDeliveryMethod
          id={id}
          paymentId={paymentId}
          prevStepURL={exitUrl}
          flowType={RECOVERY_FLOW_TYPE.VOID_CHECK}
        />
      </SmartRoute>
      <SmartRoute path={voidLocations.missingACHDetails} exact>
        <StepMissingACHDetails
          id={id}
          paymentId={paymentId}
          prevStepURL={voidLocations.index}
          flowType={RECOVERY_FLOW_TYPE.VOID_CHECK}
        />
      </SmartRoute>
      <SmartRoute path={voidLocations.chooseCheckDelivery} exact>
        <ChooseDeliveryDate
          id={id}
          paymentId={paymentId}
          prevStepURL={voidLocations.index}
          flowType={RECOVERY_FLOW_TYPE.VOID_CHECK}
        />
      </SmartRoute>
    </Switch>
  );
};

export default VoidCheckRecoveryFlowPage;
