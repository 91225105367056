import React from 'react';
import { Box } from '@melio/billpay-design-system';

type Props = {
  page: number;
  isSelected: boolean;
  onClick: () => void;
};

const stylePaginationPage = {
  bg: 'transparent',
  border: 'none',
  cursor: 'pointer',
  color: 'gray.300',
  textStyle: 'body3',
  px: '0.5rem',
  py: '1px',
  _selected: {
    color: 'black',
    fontWeight: 'bold',
    textStyle: 'body4',
    border: '1px',
    borderColor: 'gray.200',
    borderRadius: 'md',
  },
  _focus: {
    outline: 0,
  },
};

const PaginationPage: React.FC<Props> = ({ page, isSelected, onClick }) => (
  <Box
    data-testid={`qbo-pagination-page-${page}`}
    aria-selected={isSelected ? true : undefined}
    onClick={onClick}
    __css={stylePaginationPage}
  >
    {page + 1}
  </Box>
);

export default PaginationPage;
