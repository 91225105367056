import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { getEndOfLifeMaxDate, isAfterEndOfLife, isEndOfLifeMonth } from 'src/app/utils/dates';
import { OrganizationPreferencesType } from 'src/app/utils/types';
import { analytics } from 'src/app/version-2/externals';
import {
  BATCH_BULK_EVENT_PAGE,
  GLOBAL_DEDUCTION_DATE_CHOOSE,
  GLOBAL_DEDUCTION_DATE_UPDATE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { DeductionDateRenderer } from 'src/app/version-2/pages/batch-bulk/renderers';
import { DeductionDatePickerProps } from '../DeductionDateSelect.consts';
import { DeductionDateTrigger } from '../DeductionDateSelect.styles';

import { Moment } from 'moment';

export const DeductionDatePicker = ({
  date,
  onChange,
  disabled = false,
  minScheduleDate,
}: DeductionDatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const isFirstWave = !!organizationPreferences?.billPayFirstWaveUser;
  const maxDeliveryDate: Date = getEndOfLifeMaxDate(isFirstWave);
  const [showEOLNotification, setShowEOLNotification] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    isAfterEndOfLife(date, isFirstWave) ? null : date
  );

  const endOfLifeLabel = useMemo(
    () =>
      isFirstWave
        ? 'bills.pay.date.scheduledDateEndOfLifeNotificationFirstWave'
        : 'bills.pay.date.scheduledDateEndOfLifeNotificationSecondWave',
    [isFirstWave]
  );

  useEffect(() => {
    analytics.track(BATCH_BULK_EVENT_PAGE, GLOBAL_DEDUCTION_DATE_CHOOSE);
  }, []);

  useEffect(() => {
    date?.setHours(0, 0, 0, 0);
    const dateToCompare = new Date(selectedDate?.toISOString() as string);

    dateToCompare.setHours(0, 0, 0, 0);

    if (date && (!selectedDate || date > dateToCompare)) {
      setSelectedDate(isAfterEndOfLife(date, isFirstWave) ? null : date);
    }
  }, [date]);

  useEffect(() => {
    onChange(selectedDate);
  }, [selectedDate]);

  const onClickHandler = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

  const goLearnMore = () => {
    analytics.track('end-of-life-batch-deduction', 'learn-more', { isFirstWave });
    window.open(
      'https://quickbooks.intuit.com/learn-support/en-us/help-article/money-movement/switch-bill-pay-powered-melio-quickbooks-bill-pay/L98z4nrOH_US_en_US?uid=lofvkaen',
      '_blank'
    );
  };

  const onChangeHandler = useCallback((value: { date: Date | null }) => {
    setIsOpen(false);

    analytics.track(BATCH_BULK_EVENT_PAGE, GLOBAL_DEDUCTION_DATE_UPDATE);

    if (value.date) {
      setSelectedDate(isAfterEndOfLife(value.date, isFirstWave) ? null : value.date);
    }
  }, []);

  const toggleEndOfLifeNotification = useCallback((dateToCompare?: Moment | null) => {
    const isEndOfLifeMonthResult = isEndOfLifeMonth(dateToCompare, isFirstWave);

    setShowEOLNotification(isEndOfLifeMonthResult);
  }, []);

  useEffect(() => {
    toggleEndOfLifeNotification(selectedDate as unknown as Moment);
  }, [toggleEndOfLifeNotification, selectedDate]);

  const onMonthChange = useCallback((date: Moment) => {
    toggleEndOfLifeNotification(date);
  }, []);

  return (
    <DeductionDateRenderer
      id="batch-bulk-date-picker"
      deductionDate={selectedDate || new Date()}
      isOpen={isOpen}
      onClick={onClickHandler}
      onChange={onChangeHandler}
      minScheduledDate={minScheduleDate || new Date()}
      maxScheduledDate={maxDeliveryDate}
      onClose={() => setIsOpen(false)}
      renderers={{
        DateSelection: DeductionDateTrigger,
      }}
      calendarIcon
      disabled={disabled}
      removeFooter
      goLearnMore={goLearnMore}
      showEOLNotification={showEOLNotification}
      eolNotificationLabel={endOfLifeLabel}
      onMonthChange={onMonthChange}
    />
  );
};
