import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AreaLoader } from '@melio/billpay-design-system';
import billsApi from 'src/app/services/api/bills';
import { useApi } from 'src/app/hoc/useApi';
import { RequestRefund } from 'src/app/pages/qb-dashboard/RefundFlow/RequestRefund';
import { getPaymentById } from 'src/app/utils/payments';
import { BillType, PaymentType } from 'src/app/utils/types';
import { BillRecord } from 'src/app/pages/bill/records';

export const RequestRefundWrapper = () => {
  const match = useRouteMatch<{ billId: string; paymentId: string; orgId: string }>();
  const { billId, paymentId, orgId } = match.params;
  const [getBillById] = useApi(billsApi.getBillById);
  const [payment, setPayment] = useState<PaymentType | undefined>();
  const [bill, setBill] = useState<BillType | undefined>();

  useEffect(() => {
    const getBill = async () => {
      try {
        const billResult = await getBillById({ orgId, id: billId });
        const bill = billResult ? BillRecord(billResult.object) : BillRecord();
        const payment = getPaymentById(bill.payments, paymentId);

        setBill(bill);
        setPayment(payment);
      } catch (err) {
        console.log(err);
      }
    };

    getBill();
  }, []);

  return !payment || !bill ? <AreaLoader /> : <RequestRefund payment={payment} bill={bill} />;
};
