import React from 'react';
import { SmartIcon } from '@melio/billpay-design-system';
import {
  BrandLogo,
  BrandsLogosContainer,
} from 'src/app/version-2/pages/add-card-account/components/CardDetails/brandsLogos/BrandsLogos.styles';
import { BasisTheoryCardsBrandsEnum } from 'src/app/version-2/pages/add-card-account/model/enums/basisTheoryCardsBrands.enum';

interface BrandsLogosProps {
  selectedBrand: BasisTheoryCardsBrandsEnum;
}

export const BrandsLogos = ({ selectedBrand }: BrandsLogosProps) => {
  const isBrandSelected = (brand: BasisTheoryCardsBrandsEnum) =>
    selectedBrand === brand || selectedBrand === BasisTheoryCardsBrandsEnum.UNKNOWN;

  return (
    <BrandsLogosContainer>
      <BrandLogo translucent={!isBrandSelected(BasisTheoryCardsBrandsEnum.VISA)}>
        <SmartIcon type="visaCard" size="4.4rem" min-width="5.4rem" />
      </BrandLogo>
      <BrandLogo translucent={!isBrandSelected(BasisTheoryCardsBrandsEnum.MASTERCARD)}>
        <SmartIcon type="mastercard" size="4.4rem" min-width="5.4rem" />
      </BrandLogo>
      <BrandLogo translucent={!isBrandSelected(BasisTheoryCardsBrandsEnum.AMEX)}>
        <SmartIcon type="amex" size="4.4rem" min-width="5.4rem" />
      </BrandLogo>
      <BrandLogo translucent={!isBrandSelected(BasisTheoryCardsBrandsEnum.DINERS)}>
        <SmartIcon type="diners" width="4.4rem" min-width="5.4rem" />
      </BrandLogo>
      <BrandLogo translucent={!isBrandSelected(BasisTheoryCardsBrandsEnum.DISCOVER)}>
        <SmartIcon type="discover" size="4.4rem" min-width="5.4rem" />
      </BrandLogo>
    </BrandsLogosContainer>
  );
};
