import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';

export const NewTag = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  background-color: ${({ theme }) => theme.colors.ds.pink[200]};
  border-radius: 50%;
  position: absolute;
  top: 0.6rem;
  left: -1.6rem;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 2rem 1.6rem 3.2rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ds.gray[50]};
  }
`;

export const TextContainer = styled.div<{ isNew: boolean }>`
  font-weight: ${({ isNew }) => (isNew ? '600' : '500')};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CTALink = styled(MIInlineLink)<{ isNew: boolean }>`
  width: unset;
  /* height: unset; */
  min-width: unset;
  min-height: unset;
  font-weight: ${({ isNew }) => (isNew ? '500' : '400')};
`;

export const Divider = styled.div`
  height: 0.1rem;
  background-color: ${({ theme }) => theme.colors.ds.gray[700]};
  margin: 0 2rem 0 3.2rem;
`;

export const DateContainer = styled(Box)`
  color: ${({ theme }) => theme.colors.ds.gray[300]};
  min-width: fit-content;
  margin-left: 1.6rem;
`;
