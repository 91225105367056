import React, { forwardRef } from 'react';

// This HOC is just a hack to be able to use React Hooks inside a class component.
// It will inject the hook return values as a props to the wrapped component.
// example => https://infinum.com/the-capsized-eight/how-to-use-react-hooks-in-class-components
export const withHook =
  <P extends any[], R extends Record<string, any>>(...params: P) =>
  (useHook: (...params: P) => R) =>
  <Props extends R & any>(Component: React.FC<Props>) => {
    const WrappedComponent = forwardRef((props: Props, ref) => {
      const hookProps = useHook(...params);

      return <Component {...props} {...hookProps} ref={ref} />;
    });

    WrappedComponent.displayName = `withHook(${useHook.name})(${
      Component.displayName || Component.name
    })`;

    return WrappedComponent;
  };
