import React from 'react';
import { Flex } from '@melio/billpay-design-system';

import Item from 'src/app/pages/qb-dashboard/components/FTUModalContent/Elements/Item';

function Elements() {
  const elements = [
    { label: 'paymentDashboard.ftuModal.items.view' },
    { label: 'paymentDashboard.ftuModal.items.manage' },
    { label: 'paymentDashboard.ftuModal.items.schedule' },
    { label: 'paymentDashboard.ftuModal.items.switch' },
  ];

  return (
    <Flex flexDirection="column">
      {elements.map((element) => (
        <Item key={element.label} {...element} />
      ))}
    </Flex>
  );
}

export default Elements;
