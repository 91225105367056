// eslint-disable-next-line max-classes-per-file
import isNil from 'lodash/isNil';
import { BILL_STATUS } from './consts';
import { getBillsSearchPath } from 'src/app/utils/billsPath';

const pattern = /\{([0-9a-zA-Z_]+)}/g;

class Route {
  _url: string;

  constructor(url) {
    this._url = url;
  }

  url(params = {}) {
    return this._url.replace(pattern, (_match, i) => (isNil(params[i]) ? `:${i}` : params[i]));
  }
}
// TODO: move location to be a module with functions and remove class
export class MainNavigator {
  index: Route;

  MainApp: {
    dashboardSingle: Route;
    dashboard: Route;
    dashboardInternationalBill: Route;
  };

  Auth: {
    loginWithToken: Route;
    register: {
      changeEmail: Route;
    };
    authCodeVerification: Route;
  };

  Bills: {
    single: Route;
    payment: Route;
    index: Route;
    create: {
      index: Route;
      fromVendor: Route;
      options: Route;
    };
    view: Route;
    filteredView: Route;
    filteredViewWithoutId: Route;
    edit: Route;
    pay: {
      entry: Route;
      amount: Route;
      deliveryMethod: Route;
      date: Route;
      funding: Route;
      fastPayment: Route;
      internationalBlocked: Route;
      batch: Route;
      batchFunding: Route;
      recurring: {
        funding: Route;
        deliveryMethod: Route;
        memo: Route;
        confirm: Route;
        success: Route;
        completeLegalInfo: Route;
      };
      confirm: Route;
      memo: Route;
      success: Route;
      batchCompleteLegalInfo: Route;
      expedite: Route;
      completeLegalInfo: Route;
      edit: {
        deliveryMethod: Route;
        deliveryMethodAch: Route;
        virtualCardRecovery: Route;
        returnedCheckRecovery: {
          entry: Route;
          missingACHDetails: Route;
          chooseCheckDelivery: Route;
        };
        voidCheck: {
          entry: Route;
          missingACHDetails: Route;
          chooseCheckDelivery: Route;
        };
        date: Route;
        funding: Route;
        confirm: Route;
        memo: Route;
        fastPayment: Route;
      };
    };
  };

  Vendors: {
    view: Route;
    edit: Route;
    deliveryMethods: {
      add: Route;
      ach: {
        create: Route;
        edit: Route;
      };
      check: {
        create: Route;
        edit: Route;
        address: {
          create: Route;
          edit: Route;
        };
      };
      virtual: {
        create: Route;
      };
      international: {
        create: Route;
        edit: Route;
        purposeOfPayment: Route;
        ultimateBeneficialOwners: Route;
      };
      'virtual-card': {
        create: Route;
        edit: Route;
      };
    };
    // TODO: replace with route from https://meliorisk.atlassian.net/browse/ME-21149
    globalOptIn: Route;
  };

  Settings: {
    index: Route;
    account: Route;
    paymentMethods: Route;
    billingItems: Route;
    vendors: Route;
    vendorSingle: Route;
  };

  Onboarding: {
    fundingSources: {
      bank: {
        account: Route;
        type: Route;
        select: Route;
        plaid: Route;
        complete: Route;
        cantFind: Route;
        manually: Route;
        manuallyView: Route;
      };
      card: {
        index: Route;
        cardholder: Route;
        nickname: Route;
        complete: Route;
      };
      fee: {
        checks: {
          selectFundingSource: Route;
          complete: Route;
          bank: {
            pendingBankVerification: Route;
          };
        };
      };
    };
  };

  QuickBooks: {
    entrypoint: {
      register: Route;
      action: Route;
    };
    actions: {
      edit: Route;
      batchpay: Route;
      payNewLandingPageExperiment: Route;
      'bills-list': Route;
      'create-bill': Route;
      quickpay: Route;
      quicklist: Route;
      'quickpay-view': Route;
      'just-pay': Route;
    };
    errorConnect: Route;
    billsList: Route;
    createBill: Route;
    mobile: {
      pay: Route;
    };
    errorPage: Route;
    incognitoErrorPage: Route;
    safariErrorPage: Route;
    testVendorForex: Route;
  };

  FundingSources: {
    microDepositsVerify: Route;
    microDepositsSuccess: Route;
    microDepositsBlocked: Route;
  };

  constructor() {
    this.index = new Route('/');
    this.MainApp = {
      dashboardSingle: new Route(
        `/orgs/{orgId}/bills?${getBillsSearchPath(BILL_STATUS.UNPAID)}&section=dashboard`
      ),
      dashboard: new Route(`/orgs/{orgId}/bills?${getBillsSearchPath(BILL_STATUS.UNPAID)}`),
      dashboardInternationalBill: new Route('/orgs/{orgId}/international'),
    };

    this.Auth = {
      loginWithToken: new Route('/login-with-token/{token}'),
      register: {
        changeEmail: new Route('/register/change-email'),
      },
      authCodeVerification: new Route('/auth/code-verification'),
    };

    this.Bills = {
      single: new Route('/orgs/{orgId}/bill/{billId}'),
      payment: new Route('/orgs/{orgId}/bill/{billId}/payment/{paymentId}'),
      index: new Route('/orgs/{orgId}/bills'),
      create: {
        index: new Route('/orgs/{orgId}/bills/new'),
        fromVendor: new Route('/orgs/{orgId}/bills/new?vendorId={id}'),
        options: new Route('/orgs/{orgId}/bills/new/create-options'),
      },
      view: new Route(`/orgs/{orgId}/bills?id={id}&${getBillsSearchPath(BILL_STATUS.UNPAID)}`),
      filteredView: new Route(
        '/orgs/{orgId}/bills?id={id}&status={status}&sorting={sorting}&start={start}&limit={limit}'
      ),
      filteredViewWithoutId: new Route(
        '/orgs/{orgId}/bills?status={status}&sorting={sorting}&start={start}&limit={limit}'
      ),
      edit: new Route('/orgs/{orgId}/bills/{id}/edit'),
      pay: {
        entry: new Route('/orgs/{orgId}/bills/{id}/pay'),
        amount: new Route('/orgs/{orgId}/bills/{id}/pay/amount'),
        deliveryMethod: new Route('/orgs/{orgId}/bills/{id}/pay/delivery-method'),
        date: new Route('/orgs/{orgId}/bills/{id}/pay/date'),
        funding: new Route('/orgs/{orgId}/bills/{id}/pay/funding'),
        fastPayment: new Route('/orgs/{orgId}/bills/{id}/pay/fast-payment'),
        internationalBlocked: new Route('/orgs/{orgId}/bills/{id}/pay/international/block'),
        // eslint-disable-next-line max-len
        batch: new Route('/orgs/{orgId}/bills/pay/batch?ids={ids}&currentId={currentId}'),
        batchFunding: new Route('/orgs/{orgId}/bills/pay/batch-funding?ids={ids}'),
        recurring: {
          funding: new Route('/orgs/{orgId}/bills/pay/recurring/funding'),
          deliveryMethod: new Route('/orgs/{orgId}/bills/pay/recurring/delivery-method'),
          memo: new Route('/orgs/{orgId}/bills/pay/recurring/memo'),
          confirm: new Route('/orgs/{orgId}/bills/pay/recurring/confirm'),
          success: new Route('/orgs/{orgId}/bills/pay/recurring/success'),
          completeLegalInfo: new Route('/orgs/{orgId}/bills/pay/recurring/complete-legal-info'),
        },
        confirm: new Route('/orgs/{orgId}/bills/{id}/pay/confirm'),
        memo: new Route('/orgs/{orgId}/bills/{id}/pay/memo'),
        success: new Route('/orgs/{orgId}/bills/{id}/pay/success'),
        batchCompleteLegalInfo: new Route('/orgs/{orgId}/bills/pay/batch-complete-legal-info'),
        expedite: new Route('/orgs/{orgId}/bills/{id}/pay/expedite'),
        completeLegalInfo: new Route('/orgs/{orgId}/bills/{id}/pay/complete-legal-info'),
        edit: {
          deliveryMethod: new Route(
            '/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/delivery-method'
          ),
          deliveryMethodAch: new Route(
            '/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/delivery-method-ach'
          ),
          virtualCardRecovery: new Route(
            '/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/virtual-card-recovery'
          ),
          returnedCheckRecovery: {
            entry: new Route(
              '/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/returned-check-recovery'
            ),
            missingACHDetails: new Route(
              '/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/returned-check-recovery/ach/new'
            ),
            chooseCheckDelivery: new Route(
              '/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/returned-check-recovery/check/update-delivery'
            ),
          },
          voidCheck: {
            entry: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/void-check'),
            missingACHDetails: new Route(
              '/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/void-check/ach/new'
            ),
            chooseCheckDelivery: new Route(
              '/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/void-check/check/update-delivery'
            ),
          },
          date: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/date'),
          funding: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/funding'),
          confirm: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/confirm'),
          memo: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/memo'),
          fastPayment: new Route('/orgs/{orgId}/bills/{id}/payment/{paymentId}/edit/fast-payment'),
        },
      },
    };
    this.Vendors = {
      view: new Route('/orgs/{orgId}/contacts/vendors?id={id}'),
      edit: new Route('/orgs/{orgId}/contacts/vendors/{id}/edit'),
      deliveryMethods: {
        add: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/add'),
        ach: {
          create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/ach/new'),
          edit: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/ach/{deliveryMethodId}'),
        },
        check: {
          create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/check/new/name'),
          edit: new Route(
            '/orgs/{orgId}/vendors/{id}/delivery-method/check/{deliveryMethodId}/name'
          ),
          address: {
            create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/check/new/address'),
            edit: new Route(
              '/orgs/{orgId}/vendors/{id}/delivery-method/check/{deliveryMethodId}/address'
            ),
          },
        },
        virtual: {
          create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/virtual/new'),
        },
        international: {
          create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/international'),
          edit: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/international'),
          purposeOfPayment: new Route(
            '/orgs/{orgId}/vendors/{id}/delivery-method/international/purpose-of-payment'
          ),
          ultimateBeneficialOwners: new Route(
            '/orgs/{orgId}/vendors/{id}/delivery-method/international/ultimate-beneficial-owners'
          ),
        },
        'virtual-card': {
          create: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/virtual-card/new'),
          edit: new Route('/orgs/{orgId}/vendors/{id}/delivery-method/virtual-card/new'),
        },
      },
      globalOptIn: new Route('/orgs/{orgId}/vendors/global-opt-in/{token}'),
    };
    this.Settings = {
      index: new Route('/orgs/{orgId}/settings'),
      account: new Route('/orgs/{orgId}/settings/account'),
      paymentMethods: new Route('/orgs/{orgId}/settings/payment-methods'),
      billingItems: new Route('/orgs/{orgId}/settings/billing-items'),
      vendors: new Route('/orgs/{orgId}/settings/vendors'),
      vendorSingle: new Route('/orgs/{orgId}/settings/vendors/{id}'),
    };
    this.Onboarding = {
      fundingSources: {
        bank: {
          account: new Route('/orgs/{orgId}/welcome/funding-sources/bank/account'),
          type: new Route('/orgs/{orgId}/welcome/funding-sources/bank/type'),
          select: new Route('/orgs/{orgId}/welcome/funding-sources/bank/select'),
          plaid: new Route('/orgs/{orgId}/welcome/funding-sources/bank/plaid'),
          complete: new Route('/orgs/{orgId}/welcome/funding-sources/bank/complete'),
          cantFind: new Route('/orgs/{orgId}/welcome/funding-sources/bank/cant-find'),
          manually: new Route('/orgs/{orgId}/welcome/funding-sources/bank/manually'),
          manuallyView: new Route('/orgs/{orgId}/welcome/funding-sources/bank/manually/{id}'),
        },
        card: {
          index: new Route('/orgs/{orgId}/welcome/funding-sources/card'),
          cardholder: new Route('/orgs/{orgId}/welcome/funding-sources/card/holder'),
          nickname: new Route('/orgs/{orgId}/welcome/funding-sources/card/nickname'),
          complete: new Route('/orgs/{orgId}/welcome/funding-sources/card/complete'),
        },
        fee: {
          checks: {
            selectFundingSource: new Route(
              '/orgs/{orgId}/welcome/fee/checks/select-funding-source'
            ),
            complete: new Route('/orgs/{orgId}/welcome/fee/checks/complete'),
            bank: {
              pendingBankVerification: new Route(
                '/orgs/{orgId}/welcome/fee/checks/bank/pending-bankAccount-verification'
              ),
            },
          },
        },
      },
    };

    this.QuickBooks = {
      entrypoint: {
        register: new Route('/quickbooks/register/{action}/{txnId}'),
        action: new Route('/quickbooks/entry/{action}/{realmId}/{txnId}'),
      },
      actions: {
        edit: new Route('/quickbooks/edit/{id}'),
        batchpay: new Route(
          '/quickbooks/batchpay?txnIds={txnIds}&vendorId={vendorId}&isByVendorId={isByVendorId}'
        ),
        payNewLandingPageExperiment: new Route(
          '/quickbooks/pay-new-landing-page/pay?txnId={txnId}&isByVendorId={isByVendorId}'
        ),
        'bills-list': new Route('/quickbooks/bills-list/{realmId}'),
        'create-bill': new Route('/quickbooks/create-bill/{realmId}'),
        quickpay: new Route(
          '/quickbooks/quickpay?vendorOriginId={vendorOriginId}&totalAmount={totalAmount}&note={note}&currency={currency}'
        ),
        quicklist: new Route('/quickpay/bills'),
        'quickpay-view': new Route('/quickpay/quickpay-view?paymentId={paymentId}'),
        'just-pay': new Route(
          '/quickbooks/just-pay?isQBCash={isQBCash}&deliveryMethodType={deliveryMethodType}'
        ),
      },
      errorConnect: new Route('/quickbooks/error-connect'),
      billsList: new Route('/quickbooks/bills-list/{realmId}'),
      createBill: new Route('/quickbooks/create-bill/{realmId}'),
      mobile: {
        pay: new Route('/quickbooks/mobile/{action}/{realmId}/{txnId}'),
      },
      errorPage: new Route('/quickbooks/block/{source}'),
      incognitoErrorPage: new Route('/quickbooks/incognito-error-page'),
      safariErrorPage: new Route('/quickbooks/safari-error-page'),
      testVendorForex: new Route('/quickbooks/international/local-currency'),
    };
    this.FundingSources = {
      microDepositsVerify: new Route('/funding-sources/{id}/micro-deposits/verify/{token}'),
      microDepositsSuccess: new Route('/funding-sources/{id}/micro-deposits/success'),
      microDepositsBlocked: new Route('/funding-sources/{id}/micro-deposits/blocked'),
    };
  }
}

export default new MainNavigator();
