import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

type TagProps = {
  label: string;
};

const Tag = ({ label }: TagProps) => (
  <TagText>
    <MIFormattedText label={label} />
  </TagText>
);

export default Tag;

const TagText = styled.span`
  color: ${({ theme }) => theme?.text?.color?.message};
  margin-left: 0.6rem;
`;
