import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import { SET_PROFILE, SET_COMPANY_INFO } from 'src/app/redux/user/actionTypes';
import { OrganizationRestApi } from 'src/app/services/api/organizations';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { assignPaymentRequestToOrgSlice, getOrgAssignedToPaymentRequestSlice } from './multi-orgs';
import { OrganizationType } from '../../utils/types';

const name = 'organizations';

const persistConfig = {
  whitelist: ['create'],
};

const organizationStore = createRestfulSlice({
  name,
  schemaName: 'companyInfo',
  persistConfig,
  api: OrganizationRestApi,
  extraReducers: {
    [SET_PROFILE](state, payload) {
      const { organizations } = payload;

      (organizations || []).forEach((org) => {
        state.byId[org.id] = org;
      });
    },
    [SET_COMPANY_INFO](state, payload) {
      state.byId[payload.companyInfo.id] = {
        ...state.byId[payload.companyInfo.id],
        ...payload.companyInfo.toJS(),
      };
    },
    [paymentStore.actions.fetchPaymentDetailsWithToken.success](state, { payload }) {
      const { organization } = payload.payment;

      state.byId[organization.id] = organization;
    },
    [paymentStore?.actions.fetchEmailToVendorDetails.success](state: any, { payload }) {
      const { organization } = payload.payment;

      state.byId[organization.id] = organization;
    },
    [deliveryMethodsStore.actions.fetchUnilateralRequestDetails.success](state: any, { payload }) {
      const { organization } = payload;

      state.byId[organization.id] = organization;
    },
  },
  selectors: {
    byId: (id) => (state) => state[name].byId[id] as OrganizationType,
  },
  slices: {
    assignPaymentRequestToOrg: assignPaymentRequestToOrgSlice,
    getOrgAssignedToPaymentRequest: getOrgAssignedToPaymentRequestSlice,
  },
});

export default organizationStore;
