import React, { useCallback } from 'react';
import {
  FormattedMessage,
  FormattedDate,
  FormattedNumber,
  FormatDateOptions,
  useIntl,
} from 'react-intl';
import classNames from 'classnames';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import isString from 'lodash/isString';
import trim from 'lodash/trim';
import indefinite from 'indefinite';
import { checkApostropheForPluralPossessive } from './string-utils';
import { AddressType } from './types';
import { FULL_STORY_MASK_RULE_CLASS } from './consts';

type MIFormattedTextProps = {
  label?: string;
  values?: Record<string, any>;
  children?: any;
  testId?: string;
  privateData?: boolean;
};

const defaultFormatters = {
  b: (...chunks) => <b>{React.Children.toArray(chunks)}</b>,
  capb: (...chunks) => <CapitilizedBold>{React.Children.toArray(chunks)}</CapitilizedBold>,
  sb: (...chunks) => <SemiBold>{React.Children.toArray(chunks)}</SemiBold>,
  em: (...chunks) => <em>{React.Children.toArray(chunks)}</em>,
  un: (...chunks) => <Underlined>{React.Children.toArray(chunks)}</Underlined>,
  br: <br />,
  ind: (string) => indefinite(string, { articleOnly: true }),
  ap: (value) =>
    checkApostropheForPluralPossessive(
      typeof value === 'object' && value !== null ? value?.key : value
    ),
  colorSubtitle: (...chunks) => <ColoredSubtitle>{React.Children.toArray(chunks)}</ColoredSubtitle>,
  private: (...chunks) => (
    <span className={FULL_STORY_MASK_RULE_CLASS}>{React.Children.toArray(chunks)}</span>
  ),
  li: (...chunks) => <ListItem>{React.Children.toArray(chunks)}</ListItem>,
  ul: (...chunks) => <UnorderedList>{React.Children.toArray(chunks)}</UnorderedList>,
  ol: (...chunks) => <OrderedList>{React.Children.toArray(chunks)}</OrderedList>,
};

const SemiBold = styled.b`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const Underlined = styled.b`
  text-decoration: underline;
`;

const CapitilizedBold = styled.b`
  text-transform: capitalize;
`;

const ColoredSubtitle = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const ListItem = styled.li`
  padding-left: 0.3rem;
`;

const ListStyle = `
  padding: 0;
  margin: 0;
  padding-inline-start: 2rem;
`;

const UnorderedList = styled.ul`
  ${ListStyle}
`;

const OrderedList = styled.ol`
  ${ListStyle}
`;

const MIFormattedText = ({
  label,
  values,
  children,
  testId,
  privateData,
}: MIFormattedTextProps) => {
  if (!label) {
    return null;
  }

  const text = privateData ? (
    <span className={FULL_STORY_MASK_RULE_CLASS}>{children}</span>
  ) : (
    children
  );

  return (
    <FormattedMessage
      id={label}
      data-testid={testId}
      defaultMessage={label}
      values={{ ...values, ...defaultFormatters }}
    >
      {text}
    </FormattedMessage>
  );
};

const useTranslation = () => {
  const intl = useIntl();

  const formatMessage = useCallback(
    (id: any, values?: Record<string, any>) =>
      intl.formatMessage({ id }, { ...values, ...defaultFormatters }) as string,
    [intl]
  );

  return {
    formatMessage,
  };
};

MIFormattedText.defaultProps = {
  values: {},
};

type MIFieldOrEmptyProps = {
  value?: any;
  type?: string;
  label: string;
  color?: string;
  privateData?: boolean;
};

const MIFieldOrEmpty = ({ value, type, label, color, privateData }: MIFieldOrEmptyProps) => {
  let outputValue = value;
  let testValue = value;

  if (type === 'date') {
    outputValue = <MIFormattedDate date={testValue} />;
  }

  if (isString(testValue)) {
    testValue = trim(testValue);
  }

  const isEmptyValue = (testValue !== 0 && !testValue) || testValue === ''; // the number 0 is an actual value;
  const fieldClassName = classNames({
    [FULL_STORY_MASK_RULE_CLASS]: privateData,
  });

  return (
    <>
      {!isEmptyValue ? (
        <Field color={color} className={fieldClassName}>
          {outputValue}
        </Field>
      ) : (
        <DefaultLabel>
          <MIFormattedText label={label} privateData={privateData} />
        </DefaultLabel>
      )}
    </>
  );
};

MIFieldOrEmpty.defaultProps = {
  value: '',
  type: 'string',
  color: '',
};

const DefaultLabel = styled.div`
  color: ${(props) => props.theme.colors.ds.gray[400]};

  ${(props) => props.theme.components?.DefaultLabel}
`;

const Field = styled.div<{ errorColor?: any; color?: string }>`
  color: ${(props) => {
    if (props.errorColor) {
      return props.theme.text.color.error;
    }

    if (props.color) {
      return props.color;
    }

    return props.theme.text.color.main;
  }};
`;

type MIFormattedDateProps = {
  date: any;
  placeholder?: string;
} & FormatDateOptions;

const MIFormattedDate = ({ date, placeholder, ...props }: MIFormattedDateProps) => {
  const value = +new Date(date);

  return value ? (
    <FormattedDate value={value} day="numeric" month="short" year="numeric" {...props} />
  ) : (
    <MIFormattedText label={placeholder} />
  );
};

const MIFormattedDateTime = ({ date, ...props }: MIFormattedDateProps) => {
  const value = +new Date(date);

  return value ? (
    <FormattedDate
      value={value}
      day="numeric"
      month="short"
      year="numeric"
      hour="numeric"
      minute="numeric"
      {...props}
    />
  ) : null;
};

const generateFormattedCurrencySearchWords = (searchText: string) => {
  const separatorStepSize = 3;
  const separator = ',';

  const initialSeparatorIndexes = Array.from({ length: separatorStepSize }, (v, k) => k + 1);
  const separatorIndexesVariants = initialSeparatorIndexes
    .map((initialSeparatorIndex) => {
      let separatorIndex: number = initialSeparatorIndex;
      const separatorIndexes: number[] = [];

      while (separatorIndex < searchText.length) {
        separatorIndexes.push(separatorIndex);
        separatorIndex += separatorStepSize;
      }

      return separatorIndexes;
    })
    .filter((separatorIndexes) => separatorIndexes.length);

  const results = separatorIndexesVariants.map((separatorIndexes) => {
    let formattedSearchText = searchText;

    separatorIndexes.reverse().forEach((separatorIndex) => {
      formattedSearchText = `${formattedSearchText.substr(
        0,
        separatorIndex
      )}${separator}${formattedSearchText.substr(separatorIndex)}`;
    });

    return formattedSearchText;
  });

  return [searchText, ...results];
};

type MIFormattedCurrencyProps = {
  value?: string | number | null;
  currency?: string | null;
  round?: boolean;
  search?: string;
  className?: string;
  'data-testid'?: string;
};

const MIFormattedCurrency = ({
  value,
  currency,
  round,
  search,
  className,
  'data-testid': dataTestId,
}: MIFormattedCurrencyProps) => {
  let strValue: string | null = null;

  if (value) {
    strValue = typeof value === 'string' ? value : String(value);
  }

  const val = strValue ? Math.abs(round ? parseInt(strValue, 10) : parseFloat(strValue)) : 0;
  const minDigits = round ? 0 : 2;
  const searchWords = search ? generateFormattedCurrencySearchWords(search) : [];
  const wrapperClassName = classNames({
    [FULL_STORY_MASK_RULE_CLASS]: true,
    [className || '']: !!className,
  });

  return (
    <span className={wrapperClassName}>
      {strValue && parseInt(strValue, 10) < 0 ? '-' : ''}
      {currency}
      <FormattedNumber minimumFractionDigits={minDigits} value={val}>
        {(formattedNumber) => (
          <Highlighter
            searchWords={searchWords}
            autoEscape
            textToHighlight={formattedNumber}
            data-testid={dataTestId}
          />
        )}
      </FormattedNumber>
    </span>
  );
};

MIFormattedCurrency.defaultProps = {
  currency: '$',
  round: false,
  search: '',
};

const getFormattedAddress = ({ addressLine1, addressLine2, city, state, zipCode }: AddressType) => {
  const addressDetails = [addressLine1, addressLine2, city, state, zipCode].filter(Boolean);

  return addressDetails.join(', ');
};

export const getCreditCardNumberMask = (cardNumber = ''): string =>
  !cardNumber ? '' : cardNumber.replace(/\d{4}(?=.)/g, '$& ');

const getFormattedACH = (vendorCompanyName, isManagedByVendor, last4Digits) =>
  `${vendorCompanyName}${isManagedByVendor ? '' : ` (account ending in ${last4Digits})`}`;

export {
  MIFormattedText,
  MIFormattedDate,
  MIFormattedDateTime,
  MIFormattedCurrency,
  MIFieldOrEmpty,
  generateFormattedCurrencySearchWords,
  getFormattedAddress,
  useTranslation,
  getFormattedACH,
};
