import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import {
  CONSTS,
  NOTIFICATION_CARD_TYPES,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import { useLocationState } from 'src/app/utils/hooks';
import analytics from 'src/app/services/analytics';
import { useModal } from 'src/app/helpers/react/useModal';
import QBOMIDialog from 'src/app/components/common/QBOMIDialog';
import { VendorType, ConfirmationOrigin } from 'src/app/utils/types';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import * as QBOLayoutElements from 'src/app/components/layout/QBOElements';
import * as WizardElements from 'src/app/components/layout/QBOWizardElements';
import { ModalWrapper, Backdrop } from 'src/app/components/common/ModalMessage';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import successImage from 'src/app/images/icons/icons_check.svg';
import { useCheckOwnedVendorExists } from 'src/app/pages/just-pay/create/Success/hooks/useCheckOwnedVendorExists';
import { JustPayCollectVendorEmailModalMessage } from 'src/app/pages/just-pay/create/Success/JustPayCollectVendorEmailModalMessage';
import { shouldAskForVendorEmail } from 'src/app/modules/vendors/vendor-email/utils/should-ask-for-vendor-email';
import { getPayBillSuccessLabels, getWarningNotificationLabels } from '../utils';
import {
  WizardActionsRowContainer,
  WizardButtonContainer,
  ModalContainer as StyledModalContainer,
  Close,
} from '../styles';

const eventPage = 'just-pay';

const ModalContainer = styled(StyledModalContainer)`
  .single-payment-succes-modal-subtitle {
    font-size: 1.6rem;

    b {
      font-weight: 600 !important;
    }
  }
`;

export const useJustPaySuccessModal = (finishSchedulingFlow: () => void) => {
  const [payment] = useLocationState<JustPayPaymentType>('payment');
  const [vendor] = useLocationState<VendorType>('vendor');
  const [confirmationOrigin] = useLocationState<ConfirmationOrigin>('confirmationOrigin');
  const [syncPaymentErrorCode] = useLocationState<ConfirmationOrigin>('syncPaymentErrorCode');

  const [isCollectSuccessModalShown, showCollectSuccessModal] = useState<boolean>(true);
  const [visible, setVisibility] = useState<boolean>(
    confirmationOrigin === ConfirmationOrigin.JUST_PAY
  );

  const vendorName = vendor?.companyName;
  const vendorId = vendor?.id;
  const [isOwnedVendorExists] = useCheckOwnedVendorExists({
    vendorId: vendor?.id,
    vendorEmail: vendor?.contactEmail,
    eventPage,
  });
  const hasVendorEmail = isOwnedVendorExists ? true : !!vendor?.contactEmail;
  const shouldShowNotifyVendorButton = shouldAskForVendorEmail({
    isOwnedVendorExists,
    vendorContactEmail: vendor?.contactEmail,
    deliveryType: payment?.deliveryMethodType,
  });

  const {
    title: warningTitle = '',
    subtitle: warningSubtitle = '',
    subtitleValues: warningSubtitleValues = {},
  } = getWarningNotificationLabels({
    showQbcashNotification: false,
    syncPaymentErrorCode,
    payment,
  });

  const shouldShowWarningNotification = warningTitle || warningSubtitle;

  const analyticsProps = {
    vendorId,
    trackingBillId: payment?.trackingBillId,
  };

  const handleCloseModal = () => {
    finishSchedulingFlow();
    setVisibility(false);
  };

  const CollectSuccessModal = () => {
    const handleCollectVendorEmailSuccessModalOk = () => {
      analytics.track(eventPage, 'success-vendor-email-modal-closed', analyticsProps);

      showCollectSuccessModal(false);
      handleCloseModal();
    };

    return (
      <StyledCollectSuccessModal
        type={DIALOG_TYPE.CONFIRM}
        variant={DIALOG_VARIANTS.SUCCESS}
        title="bills.pay.collectVendorEmailModal.successTitle"
        titleValues={{ vendorName }}
        image={successImage}
        subtitle="bills.pay.collectVendorEmailModal.successSubTitle"
        okButtonText="bills.pay.collectVendorEmailModal.confirm"
        onOkAction={handleCollectVendorEmailSuccessModalOk}
        onCancelAction={handleCollectVendorEmailSuccessModalOk}
        showCancel={false}
      />
    );
  };

  const [CollectVendorEmailSuccessModal, showCollectVendorEmailSuccessModal] = useModal(
    CollectSuccessModal,
    {
      modalName: 'successCollectEmail',
    }
  );

  const [CollectVendorEmailModal, showCollectVendorEmailModal] = useModal(
    JustPayCollectVendorEmailModalMessage,
    {
      onSuccess: showCollectVendorEmailSuccessModal,
      payment,
      vendor,
      eventPage,
      modalName: 'collectEmail',
    }
  );

  const titles = getPayBillSuccessLabels({
    payment: payment?.createdPayment,
    hasVendorEmail,
    vendorName,
  });

  const confirmButtonLabel = shouldShowNotifyVendorButton
    ? 'bills.pay.payBillSuccess.buttonLabel.notifyVendor'
    : 'bills.pay.payBillSuccess.buttonLabel.done';

  const handleConfirm = () => {
    if (shouldShowNotifyVendorButton) {
      setVisibility(false);
      showCollectVendorEmailModal();
      analytics.track(eventPage, 'notify-my-vendor', {
        screenName: 'success',
        ...analyticsProps,
      });
    } else {
      handleCloseModal();
    }

    analytics.track(eventPage, 'done', {
      paymentId: payment?.createdPayment?.id,
      ...analyticsProps,
    });
  };

  const { title, subtitle, textValues } = titles;

  useEffect(() => {
    if (visible) {
      analytics.track(eventPage, 'success-payment-modal', {
        paymentId: payment?.createdPayment?.id,
        ...analyticsProps,
      });
    }
  }, []);

  useEffect(() => {
    if (!visible || !shouldShowNotifyVendorButton) {
      return;
    }

    analytics.track(eventPage, 'exposed-to-notify-my-vendor-button', {
      screenName: 'success',
      ...analyticsProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowNotifyVendorButton]);

  return [
    ReactDOM.createPortal(
      <>
        {CollectVendorEmailModal}
        {isCollectSuccessModalShown && CollectVendorEmailSuccessModal}
        {visible && (
          <ModalWrapper>
            <Backdrop onClick={handleCloseModal} />
            <ModalContainer data-testid="modal-message-container">
              <Close onClick={handleCloseModal} />
              <QBOLayoutElements.QBOLayoutContainer>
                <WizardElements.WizardInnerSuccess isCenterVert>
                  <WizardElements.WizardIllustration src={successImage} />
                  <WizardElements.WizardTitle data-testid="wizard-title">
                    <MIFormattedText label={title} values={textValues} />
                  </WizardElements.WizardTitle>
                  <WizardElements.WizardText
                    className="single-payment-succes-modal-subtitle"
                    textAlign="center"
                  >
                    <MIFormattedText label={subtitle} values={textValues} />
                  </WizardElements.WizardText>
                  {shouldShowWarningNotification && (
                    <StyledQBONotificationCard
                      type={NOTIFICATION_CARD_TYPES.WARNING}
                      title={{ label: warningTitle }}
                      subtitle={{
                        label: warningSubtitle,
                        values: warningSubtitleValues,
                      }}
                    />
                  )}
                  <WizardElements.WizardActionsContainer>
                    <WizardActionsRowContainer vendorEmailNotExist={shouldShowNotifyVendorButton}>
                      {shouldShowNotifyVendorButton && (
                        <WizardButtonContainer vendorEmailNotExist={shouldShowNotifyVendorButton}>
                          <MIButton
                            testId="notify-vendor-later"
                            label="bills.pay.payBillSuccess.buttonLabel.later"
                            variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                            onClick={handleCloseModal}
                          />
                        </WizardButtonContainer>
                      )}
                      <WizardButtonContainer>
                        <MIButton
                          testId="bill-success-modal-done"
                          label={confirmButtonLabel}
                          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
                          onClick={handleConfirm}
                        />
                      </WizardButtonContainer>
                    </WizardActionsRowContainer>
                  </WizardElements.WizardActionsContainer>
                </WizardElements.WizardInnerSuccess>
              </QBOLayoutElements.QBOLayoutContainer>
            </ModalContainer>
          </ModalWrapper>
        )}
      </>,
      document.querySelector('body') as any
    ),
  ];
};

const StyledCollectSuccessModal = styled(QBOMIDialog)`
  background-color: rgba(0, 0, 0, 0.65);

  .modal-title {
    font-size: 2.4rem;
  }

  .modal-subtitle {
    font-weight: 400;
  }
`;

const StyledQBONotificationCard = styled(QBONotificationCard)`
  margin-top: 3.5rem;
`;
