import React from 'react';
import { RecordOf } from 'immutable';
import { DELIVERY_TYPE, PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import { PaymentInfoHint } from 'src/app/components/layout/PaymentActivityElements';
import { FundingSource } from 'src/app/version-2/model/dtos';

type DeliveryDateInfoHintProps = {
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: FundingSource;
};

const DeliveryDateInfoHint = ({
  payment,
  deliveryMethod,
  fundingSource,
}: DeliveryDateInfoHintProps) => {
  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnitaleral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  let hint;
  const isBlockedForProcessing = isApprovalRequired || isMicrodeposit || isUnitaleral;

  if (isApprovalRequired && isUnitaleral) {
    hint = 'viewBillPaymentActivity.deliveryDate.unilateralApprovalRequiredHint';
  } else if (isUnitaleral) {
    hint = 'viewBillPaymentActivity.deliveryDate.unilateralHint';
  } else if (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH && !isBlockedForProcessing) {
    hint = 'viewBillPaymentActivity.deliveryDate.achHint';
  }

  if (hint) {
    return (
      <PaymentInfoHint>
        <MIFormattedText label={hint} />
      </PaymentInfoHint>
    );
  }

  return null;
};

export default DeliveryDateInfoHint;
