import { EventMappingsTree } from 'src/app/services/analytics/types';
import { mapApiSlice } from 'src/app/services/analytics/event-mapping-utils';

const pageEvent = 'quickpay-create';

const MAPPING: EventMappingsTree = {
  '/orgs/*/quickpay/confirm': {
    'page.view': [pageEvent, 'quickpay-confirm'],
    balanceConfirm: [pageEvent, 'quickpay-create-show-balance-confirmation', { toggle: 'toggle' }],
    'bills.pay.confirm.balanceConfirm.confirm': [
      pageEvent,
      'quickpay-create-confirm-schedule-anyway',
    ],
    'bills.pay.confirm.balanceConfirm.cancel': [
      pageEvent,
      'quickpay-create-edit-payment-after-balance-confirm',
    ],
    ...mapApiSlice('[PAYMENTS] QUICKPAY_CREATE', pageEvent, 'quickpay-create-payment'),
  },
  '/orgs/*/quickpay/date': {
    'page.view': [pageEvent, 'quickpay-date'],
    'progress.continue': [pageEvent, 'quickpay-date-save'],
  },
  '/orgs/*/quickpay/note': {
    'page.view': [pageEvent, 'quickpay-note'],
    'progress.continue': [pageEvent, 'quickpay-note-save'],
  },
  '/orgs/*/quickpay/success': {
    'page.view': [pageEvent, 'quickpay-success'],
    'qbr.success.email.saveEmail': [pageEvent, 'quickpay-success-send-email'],
    'qbr.success.emailThem': [pageEvent, 'quickpay-success-email-them'],
    'qbr.success.done': [pageEvent, 'quickpay-success-done'],
  },
  '/orgs/*/quickpay/notifyVendor': {
    'page.view': [pageEvent, 'quickpay-notify-vendor'],
  },
  '/orgs/*/quickpay/delivery-method/*': {
    'page.view': [pageEvent, 'quickpay-delivery-method'],
    'progress.continue': [pageEvent, 'quickpay-delivery-method-save'],
    'vendors.deliveryMethods.check.submit': [pageEvent, 'quickpay-delivery-method-check-submit'],
  },
  '/orgs/*/quickpay/delivery-method/check/*': {
    'page.view': [pageEvent, 'quickpay-delivery-method-new-check'],
    'progress.continue': [pageEvent, 'quickpay-delivery-method-new-check-save'],
    'vendors.deliveryMethods.check.submit': [
      pageEvent,
      'quickpay-delivery-method-new-check-submit',
    ],
  },
  '/orgs/*/quickpay/delivery-method/*/check/edit/*': {
    'page.view': [pageEvent, 'quickpay-delivery-method-edit-check'],
    'progress.continue': [pageEvent, 'quickpay-delivery-method-check-save'],
    'vendors.deliveryMethods.check.submit': [
      pageEvent,
      'quickpay-delivery-method-edit-check-submit',
    ],
  },
  '/orgs/*/quickpay/delivery-method/*/ach/edit/*': {
    'page.view': [pageEvent, 'quickpay-delivery-method-edit-ach'],
    'vendors.deliveryMethods.bank.save': [pageEvent, 'quickpay-delivery-method-edit-ach-save'],
    'vendors.deliveryMethods.check.submit': [pageEvent, 'quickpay-delivery-method-new-ach-submit'],
  },
  '/orgs/*/quickpay/delivery-method/ach/new/*': {
    'page.view': [pageEvent, 'quickpay-delivery-method-new-ach'],
    'vendors.deliveryMethods.bank.save': [pageEvent, 'quickpay-delivery-method-new-ach-save'],
    'vendors.deliveryMethods.ach.submit': [pageEvent, 'quickpay-delivery-method-new-ach-submit'],
  },
  '/orgs/*/quickpay/complete-company-info': {
    'page.view': [pageEvent, 'quickpay-complete-company-info'],
    'bills.pay.confirm.completeLegalInfo.action': [
      pageEvent,
      'quickpay-complete-company-info-submit',
    ],
    ...mapApiSlice('[PAYMENTS] QUICKPAY_CREATE', pageEvent, 'quickpay-create-payment'),
  },
};

export default MAPPING;
