import styled from 'styled-components';

export const IconContainer = styled.div`
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-block;
  * {
    fill: ${({ theme }) => theme.colors.ds.yellow[200]};
  }
`;
