import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { TextInput } from './MITextInput';

type Props = {
  placeholder?: string;
  onChange: (value: string) => void;
};

const MultiSelectSearchInput = ({ placeholder, onChange }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleInputValueChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const query = event?.target?.value || '';

    setSearchQuery(query);
    onChange(query);
  };

  return (
    <MultiSelectSearchInputWrapper>
      <SearchIcon isSearchable hasValue={!!searchQuery} className="icon-search-magnifier-icon" />
      <TextInputWithPrefix
        value={searchQuery}
        placeholder={placeholder}
        onChange={handleInputValueChange}
      />
      {searchQuery && (
        <ClearIcon
          hasValue={!!searchQuery}
          className="icon-close-icon"
          onClick={() => handleInputValueChange()}
        />
      )}
    </MultiSelectSearchInputWrapper>
  );
};

export { MultiSelectSearchInput };

const MultiSelectSearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 1.4rem;
`;

const TextInputWithPrefix = styled(TextInput)`
  color: ${(props) => props.theme.text.color.black};
  padding: 0 calc(1.4rem * 2);
`;

const iconStyles = css`
  position: absolute;
  z-index: 2;
  display: block;
  line-height: ${(props) => props.theme.text.size.title};
  text-align: center;
`;

const Icon = styled.i<{ hasValue: boolean }>`
  color: ${(props) =>
    props.hasValue ? props.theme.colors.icons.active : props.theme.colors.icons.inActive};
`;

const SearchIcon = styled(Icon)<{ isSearchable?: boolean }>`
  ${iconStyles}
  cursor: ${(props) => (props.isSearchable ? 'pointer' : 'default')};
  font-size: 2rem;
  bottom: 0;
  left: 0;
`;

const ClearIcon = styled(Icon)`
  ${iconStyles}
  cursor: pointer;
  font-size: 2rem;
  bottom: 0;
  right: 0;
`;
