import React, { useEffect, useState, useCallback } from 'react';
import head from 'lodash/head';
import { NEW_VENDOR_ID } from 'src/app/utils/consts';
import styled from 'styled-components';
import { SelectFieldOption, WizardSelectField } from 'src/app/ui/form/WizardSelectField';
import { theme } from 'src/app/theme/qboTheme';
import { ModelViewField } from 'src/app/ui/form/use-form/useForm';

const SelectInternationalVendor = ({
  model,
  initialVendorOptions,
}: {
  model: ModelViewField<string>;
  initialVendorOptions: SelectFieldOption<string>[];
}) => {
  const [vendorOptions, setVendorOptions] = useState<SelectFieldOption<string>[]>([]);

  useEffect(() => {
    if (initialVendorOptions.length) {
      setVendorOptions(initialVendorOptions);
    }
  }, [initialVendorOptions.length]);

  const onCreateNewVendor = useCallback((value: string) => {
    const firstVendor = head(vendorOptions);

    if (firstVendor?.id === NEW_VENDOR_ID) {
      setVendorOptions([{ ...firstVendor, label: value }, ...initialVendorOptions]);
    } else {
      setVendorOptions([{ id: NEW_VENDOR_ID, label: value, value }, ...initialVendorOptions]);
    }
  }, []);

  return (
    <WizardSelectField
      label="internationalPayment.vendor.select.title"
      placeholder="internationalPayment.vendor.select.placeholder"
      createLabel="internationalPayment.vendor.select.addInSelect"
      notices={['internationalPayment.vendor.select.notice']}
      iconColor={theme.colors.primary.opaque}
      options={vendorOptions}
      model={model}
      onCreate={onCreateNewVendor}
      errorMessageIcon={<ErrorIcon />}
      required
    />
  );
};

export { SelectInternationalVendor };

const ErrorIcon = styled.i.attrs({
  className: 'icon-warning-icon',
})`
  font-size: 1.6rem;
  margin-right: 0.4rem;
`;
