import { billListRestApi } from 'src/app/services/api/bills';
import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';

const name = 'quickpayBills';

const persistConfig = {
  whitelist: ['quickpayBills'],
};

const quickpayBillsStore = createRestfulSlice({
  name,
  api: billListRestApi,
  persistConfig,
});

export default quickpayBillsStore;
