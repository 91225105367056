import { EventMappingsTree } from 'src/app/services/analytics/types';

const REFUND_PAYMENT = 'refund-payment';
const REFUND_PAYMENT_SUCCEDED = 'refund-payment-succeeded';

export const selfServeRefundEventMapping: EventMappingsTree = {
  '/orgs/*/bill/*/payment/*/refund': {
    'reason-submit': [
      REFUND_PAYMENT,
      'reason-continue',
      { paymentId: 'paymentId', billId: 'billId', reason: 'reason' },
    ],
    'button-click': [
      REFUND_PAYMENT,
      'refund-request-sent',
      { paymentId: 'paymentId', billId: 'billId' },
    ],
  },
  '/orgs/*/bill/*/payment/*/refund-success': {
    'success-track-button': [
      REFUND_PAYMENT_SUCCEDED,
      'track-refund',
      { paymentId: 'paymentId', billId: 'billId' },
    ],
  },
};
