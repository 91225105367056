import cookies from 'js-cookie';
import { RegistrationOriginEnum } from 'src/app/version-2/model/enums';
import analytics from '../analytics';
import { postRequest, fetchRequest, putRequest } from './api';

type BasePayloadType = {
  email: string;
  password: string;
};
type LoginPayloadType = {
  isAfterRegister?: boolean;
} & BasePayloadType;

type GuestRegisterPayloadType = {
  registrationFlow: string;
  referringVendor?: number;
} & BasePayloadType;

export type RegisterPayloadType = {
  emailVerificationPath: string;
  registrationOrigin:
    | RegistrationOriginEnum.LANDING
    | RegistrationOriginEnum.LANDING_NO_VERIFICATION;
  registrationFlow: string;
  referringVendor?: string;
} & BasePayloadType;

const API = {
  LOGIN: '/auth/login',
  LOGIN_WITH_TOKEN: '/auth/login-with-token',
  CHECK: '/auth/check',
  REGISTER: '/auth/register',
  VENDOR_REGISTER: '/auth/vendor/register',
  GUEST_REGISTER: '/auth/guest/register',
  DETAILS: '/auth/details',
  MARKETING_DETAILS: '/auth/marketing-details',
  CHANGE_PASSWORD: '/auth/change-password',
  CHANGE_EMAIL: '/auth/change-email',
  CHECK_EMAIL_UNIQUENESS: '/auth/register/check-email',
  LOGOUT: '/auth/logout',
  RESET_PASSWORD_REQUEST: '/auth/reset-password/request',
  RESET_PASSWORD_RESOLVE: '/auth/reset-password/resolve',
  RESEND_EMAIL_VERIFICATION: '/auth/resend-email-verification',
  RESEND_EMAIL_VERIFICATION_CODE: '/auth/resend-email-verification-code',
  RESOLVE_EMAIL_VERIFICATION_CODE: '/auth/resolve-email-verification-code',
  VERIFY_EMAIL: '/auth/email-verification',
  INTUIT_LOGIN: '/auth/intuit/oauth/login/callback',
  INTUIT_LINK: '/auth/intuit/oauth/link/callback',
  INTUIT_CHECK: '/auth/intuit/oauth/check',
  INTUIT_UNLINK: '/auth/intuit/unlink',
  INTUIT_QBO_CHECK: '/auth/intuit/quickbooks/check',
  REQUEST_AUTH_CODE: '/auth/send-auth-verification-code',
  VERIFY_AUTH_CODE: '/auth/verify-auth-code',
  CHECK_TWO_FA: '/auth/check-2fa',
  CHECK_TWO_FA_HEADER_TOKEN: '/auth/headers/check-2fa',
  VERIFY_AUTH_CODE_FOR_HEADER_TOKEN: '/auth/headers/verify-auth-code',
};

function removeTrailingSlash(path) {
  return path.replace(/^\/+/, '');
}

export async function login({ email, password, isAfterRegister }: LoginPayloadType) {
  const url = API.LOGIN;
  const res = (await postRequest(url, { email, password })) || {};
  const { user } = res;

  if (user) {
    if (isAfterRegister) {
      analytics.alias(user.id);
      analytics.setOneTimeMarketingTraits();
    }

    analytics.identify(user, {
      integrations: {
        Salesforce: false,
      },
    });
  }

  return res;
}

export default {
  check() {
    const url = API.CHECK;

    return fetchRequest(url, null, { isBypassThrowError: true });
  },

  loginWithToken(token) {
    return postRequest(API.LOGIN_WITH_TOKEN, { token }).then(this.check);
  },

  async register({
    email,
    password,
    emailVerificationPath: path,
    registrationOrigin,
    registrationFlow,
    referringVendor,
  }: RegisterPayloadType) {
    const url = API.REGISTER;
    const { isFirstTimeVisitedRegularMelio } = await postRequest(url, {
      email,
      password,
      registrationOrigin,
      registrationFlow,
      emailVerificationPath: removeTrailingSlash(path),
      marketingTam: cookies.get('trackingTam'),
      marketingUtms: cookies.get('utms'),
      referringVendor,
    });

    const loginResponse = await login({
      email,
      password,
      isAfterRegister: true,
    });

    return {
      ...loginResponse,
      isFirstTimeVisitedRegularMelio,
    };
  },

  async vendorRegister({
    email,
    password,
    emailVerificationPath: path,
    registrationOrigin,
    registrationFlow,
  }) {
    const url = API.VENDOR_REGISTER;

    await postRequest(url, {
      email,
      password,
      registrationOrigin,
      registrationFlow,
      emailVerificationPath: removeTrailingSlash(path),
      marketingTam: cookies.get('trackingTam'),
      marketingUtms: cookies.get('utms'),
    });

    return login({ email, password, isAfterRegister: true });
  },

  async guestRegister({
    email,
    password,
    registrationFlow,
    referringVendor,
  }: GuestRegisterPayloadType) {
    const url = API.GUEST_REGISTER;

    await postRequest(url, {
      email,
      password,
      registrationFlow,
      marketingTam: cookies.get('trackingTam'),
      marketingUtms: cookies.get('utms'),
      referringVendor,
    });

    return login({ email, password, isAfterRegister: true });
  },

  async getUserDetails() {
    const url = API.DETAILS;

    return fetchRequest(url, {});
  },

  async updateUserDetails(userDetails) {
    const url = API.DETAILS;

    return putRequest(url, userDetails);
  },

  async updateUserMarketingDetails(userDetails) {
    const url = API.MARKETING_DETAILS;

    return putRequest(url, userDetails);
  },

  async changePassword(oldPassword, newPassword) {
    const url = API.CHANGE_PASSWORD;

    return postRequest(url, { oldPassword, newPassword });
  },

  async checkEmailUniqueness({ email }) {
    const url = API.CHECK_EMAIL_UNIQUENESS;

    return postRequest(url, { email });
  },

  async changeEmail({ email }) {
    const url = API.CHANGE_EMAIL;

    return postRequest(url, { email });
  },

  requestResetPassword({ email }: { email: string }) {
    const url = API.RESET_PASSWORD_REQUEST;

    return postRequest(url, { email });
  },

  async resetPassword({ token, email, password }) {
    const url = API.RESET_PASSWORD_RESOLVE;
    const res = await postRequest(url, { token, email, password });

    return res;
  },

  requestEmailVerification(emailVerificationPath) {
    const url = API.RESEND_EMAIL_VERIFICATION;

    return postRequest(url, {
      path: removeTrailingSlash(emailVerificationPath),
    });
  },

  resendEmailVerificationCode(email) {
    const url = API.RESEND_EMAIL_VERIFICATION_CODE;

    return postRequest(url, { email });
  },

  resolveEmailVerificationCode(email, code) {
    const url = API.RESOLVE_EMAIL_VERIFICATION_CODE;

    return postRequest(url, { email, code });
  },

  verifyEmail({ token, email }) {
    const url = API.VERIFY_EMAIL;

    return postRequest(url, { token, email });
  },

  async logout() {
    const url = API.LOGOUT;
    const res = await postRequest(url);

    analytics.reset(true);

    return res;
  },

  unlinkQuickbooksAccount(orgId) {
    const url = API.INTUIT_UNLINK;

    return postRequest(url, { orgId });
  },

  qboCheck(realmId, query) {
    const url = API.INTUIT_QBO_CHECK;

    return fetchRequest(url, { realmId, ...query });
  },

  check2fa(realmId, intent) {
    const url = API.CHECK_TWO_FA;

    return fetchRequest(url, { realmId, intent });
  },

  requestAuthCode() {
    const url = API.REQUEST_AUTH_CODE;

    return postRequest(url);
  },

  verifyAuthCode(code) {
    const url = API.VERIFY_AUTH_CODE;

    return postRequest(url, { code });
  },

  check2faHeaderToken(realmId, intent) {
    const url = API.CHECK_TWO_FA_HEADER_TOKEN;

    return fetchRequest(url, { realmId, intent });
  },

  verifyAuthCodeForHeaderToken(code) {
    const url = API.VERIFY_AUTH_CODE_FOR_HEADER_TOKEN;

    return postRequest(url, { code });
  },
};
