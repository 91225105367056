/* eslint-disable max-lines */
import React from 'react';
import { compose } from 'recompose';
import { RecordOf } from 'immutable';
import { connect } from 'react-redux';
import { isValidationOk, getValidationErrors } from '@melio/sizzers-js-common';
import { TaxIdEnum, DeliveryEnum } from 'src/app/version-2/model/enums';
import billsApi from 'src/app/services/api/bills';
import { generatePath } from 'react-router-dom';
import { CompanyInfoRecord } from 'src/app/pages/settings/records';
import analytics from 'src/app/services/analytics';
import companyInfoApi from 'src/app/services/api/organizations';
import userApi from 'src/app/services/api/user';
import {
  DELIVERY_TYPE,
  PAYMENT_STATUS,
  PAY_EDIT_LOCATION_NAME,
  FAILED_PAYMENT_TYPE,
  NOTIFICATION_VARIANT,
} from 'src/app/utils/consts';
import { History } from 'history';
import locations from 'src/app/utils/locations';
import dashboardLocations from 'src/app/pages/qb-dashboard/locations';
import {
  withRedirectToDashboard,
  RedirectParams,
} from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import {
  DEFAULT_DASHBOARD_REDIRECT_PARAMS,
  PAID_DASHBOARD_REDIRECT_PARAMS,
} from 'src/app/pages/qb-dashboard/hooks/useGetDashboardListItemPaginationParams';

import {
  formatDateOfBirth,
  isValidDateOfBirth,
  hasDateOfBirthTaxType,
} from 'src/app/utils/user-utils';
import {
  isPOBox,
  removeUnsupportedDeliveryOptionsByDeliveryMethod,
} from 'src/app/utils/delivery-methods';
import { getEventNameFromLocation } from 'src/app/utils/string-utils';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import { getConfirmDeliveryMethod } from 'src/app/version-2/utils/deliveryMethod.utils';
import {
  BillType,
  PaymentType,
  CompanyInfoType,
  UserContextType,
  GoogleCombinedAddressType,
  NavigateType,
  QBCashStateType,
  DeliveryOptionType,
  BankType,
  PayEditLocationNamesType,
  ConfirmationOrigin,
  OrganizationPreferencesType,
} from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import { convertToServerLegalAddress, convertToServerAddress } from 'src/app/utils/address';
import { getPartialBillId } from 'src/app/utils/bills';
import {
  beginRegularPayBillFlowAction,
  endPayBillFlowAction,
  createRecurringBillAction,
  cancelAndRetryPaymentAction,
  createPaymentAction,
  updatePaymentAction,
  selectPaymentDatesAction,
  fetchBillAction,
  retryFailedPaymentAction,
  setPaymentAmountAction,
} from 'src/app/redux/payBillWizard/actions';
import {
  getBill,
  getPayment,
  getIsRecurring,
  getRecurringBill,
  getQBCashState,
  getSelectedFundingSource,
  getExitUrl,
  getFirstBillIdWithRecurringBill,
  getRecurringBillId,
  getIsCancelAndRetryPaymentFlow,
  getSyncPaymentErrorCode,
} from 'src/app/redux/payBillWizard/selectors';
import { applicationSelectors } from 'src/app/version-2/modules/application/application.slice';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { withBreak } from 'src/app/hoc';

import { getProfile, getOrgId, getCompanyInfo } from 'src/app/redux/user/selectors';
import { setCompanyInfoAction, setProfileAction } from 'src/app/redux/user/actions';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { Site } from 'src/app/sites/site';
import { getInitialProcessingDates } from 'src/app/utils/dates';
import { pushNotification } from 'src/app/version-2/externals';
import {
  getDeliveryPreference,
  shouldShowFastPaymentOnRetryPaymentFlow,
  getPaymentById,
  isReturnedCheckPayment,
  isPaymentFailed,
  isUndepositedCheck,
} from 'src/app/utils/payments';
import { withHook } from 'src/app/hoc/withHook';
import { useCheckRequiredLegalCompanyInfo } from 'src/app/modules/organizations/hooks/useCheckRequiredLegalCompanyInfo';

import { RecoveryFlowType } from '../consts';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';

type MapStateToProps = {
  recurringBill: Record<string, any>;
  isRecurring: boolean;
  isCancelAndRetryFlow: boolean;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  profile: RecordOf<UserContextType>;
  orgId: string;
  companyInfo: RecordOf<CompanyInfoType>;
  qbCashState: QBCashStateType;
  selectedFundingSource: FundingSource;
  exitUrl: string | null;
  firstBillIdWithRecurringBill: string | null;
  recurringBillId: string | null;
  urlToBack: string | null;
  syncPaymentErrorCode: string | null;
  organizationPreferences: OrganizationPreferencesType;
};

type MapDispatchToProps = {
  endPayBillFlow: (isCloseEvent: boolean, preventCloseIframe?: boolean) => void;
  beginRegularPayBillFlow: (
    id: string,
    paymentId: string,
    redirectUrl?: string,
    exitUrl?: string,
    initialPaymentAmount?: number,
    keepExistingFundingSource?: boolean
  ) => void;
  createRecurringBill: () => Promise<void>;
  cancelAndRetry: () => Promise<void>;
  createPayment: () => Promise<void>;
  updatePayment: () => Promise<void>;
  retryFailedPayment: () => Promise<void>;
  setCompanyInfo: (companyInfo: RecordOf<CompanyInfoType>) => void;
  setProfile: (profile: RecordOf<UserContextType>) => void;
  setPaymentAmount: (amount: number) => void;
  selectPaymentDates: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    deliveryPreference?: string
  ) => void;
  fetchBill: (id: string) => void;
};

type Props = {
  navigate: NavigateType;
  basePath: string;
  nextStepURL: string;
  prevStepURL?: string;
  id: string;
  paymentId: string;
  location: Location;
  inputFields?: string[];
  history: History;
  locationState?: Record<string, any>;
  site: Site;
  device: {
    isMobile: boolean;
  };
  checkRequiredLegalCompanyInfo: (companyInfo: RecordOf<CompanyInfoType>) => Promise<boolean>;
  texts?: {
    title: string;
    subtitle: string;
    button: string;
    loading: string;
  };
  isInternational?: boolean;
  ErrorComponent?: React.ReactNode;
  flowType?: RecoveryFlowType;
  onLegalInfoCallback: () => Promise<void>;
  redirectToDashboard: (params: RedirectParams) => void;
  showCCTooltip?: boolean;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  isLoading: boolean;
  validationErrors: Record<string, any>;
  deliveryOptionsDates: {
    suggestedScheduledDate: Date;
    deliveryDate: Date;
    maxDeliveryDate: Date;
    minScheduledDate: Date;
    deliveryOptions: DeliveryOptionType[];
  } | null;
};

export type PayBillProps = {
  navigate: NavigateType;
  onPrev: () => void;
  onNext: () => void;
  getUrlAfterFundingSourceStepForRecoveryFlow: () => Promise<string>;
  onPrevConfirmPayment: () => void;
  onNextSelectDate: () => void;
  onPrevDate: () => void;
  onNextMemo: () => void;
  onPrevMemo: () => void;
  goExit: () => void;
  onSubmit: (payment?: PaymentType) => void;
  setPaymentAmount: (amount: number) => void;
  goEditNote: () => void;
  isLoading: boolean;
  email: string;
  id: string;
  onLegalInfoSubmit: (
    address: GoogleCombinedAddressType,
    companyAddress: GoogleCombinedAddressType,
    legalCompanyName: string,
    taxId?: string,
    taxIdType?: TaxIdEnum,
    contactFirstName?: string,
    contactLastName?: string,
    phone?: string,
    isValidLegalAddress?: boolean,
    isValidateCompanyAddress?: boolean,
    dateOfBirth?: string,
    openLegalAddressInput?: boolean
  ) => void;
  site: Site;
  checkBankAccount: (bankAccount: RecordOf<BankType>) => void;
  showCCTooltip?: boolean;
} & State;

const eventPage = 'pay-bill';

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  endPayBillFlow(isCloseEvent: boolean, preventCloseIframe?: boolean) {
    dispatch(endPayBillFlowAction(isCloseEvent, preventCloseIframe));
  },
  beginRegularPayBillFlow(
    id: string,
    paymentId: string,
    redirectUrl?: string,
    exitUrl?: string,
    initialPaymentAmount?: number,
    keepPaymentFundingSource?: boolean
  ) {
    dispatch(
      beginRegularPayBillFlowAction(
        id,
        paymentId,
        redirectUrl,
        exitUrl,
        initialPaymentAmount,
        keepPaymentFundingSource
      )
    );
  },
  setCompanyInfo(companyInfo: RecordOf<CompanyInfoType>) {
    dispatch(setCompanyInfoAction(companyInfo));
  },
  setProfile(profile: RecordOf<UserContextType>) {
    dispatch(setProfileAction(profile));
  },
  createRecurringBill() {
    return new Promise((resolve, reject) => {
      dispatch(createRecurringBillAction(resolve, reject));
    });
  },
  cancelAndRetry() {
    return new Promise((resolve, reject) => {
      dispatch(cancelAndRetryPaymentAction(resolve, reject));
    });
  },
  createPayment() {
    return new Promise((resolve, reject) => {
      dispatch(createPaymentAction(resolve, reject));
    });
  },
  updatePayment() {
    return new Promise((resolve, reject) => {
      dispatch(updatePaymentAction(resolve, reject));
    });
  },
  setPaymentAmount(amount: number) {
    dispatch(setPaymentAmountAction(amount));
  },
  retryFailedPayment() {
    return new Promise((resolve, reject) => {
      dispatch(retryFailedPaymentAction(resolve, reject));
    });
  },
  selectPaymentDates(scheduledDate, deliveryEta, maxDeliveryEta, selectedId?) {
    dispatch(
      selectPaymentDatesAction(scheduledDate, deliveryEta, maxDeliveryEta, selectedId as string)
    );
  },
  fetchBill(id: string) {
    dispatch(fetchBillAction(id));
  },
});

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  bill: getBill(state),
  payment: getPayment(state),
  syncPaymentErrorCode: getSyncPaymentErrorCode(state),
  isRecurring: getIsRecurring(state),
  isCancelAndRetryFlow: getIsCancelAndRetryPaymentFlow(state),
  recurringBill: getRecurringBill(state),
  profile: getProfile(state),
  orgId: getOrgId(state),
  companyInfo: getCompanyInfo(state),
  qbCashState: getQBCashState(state),
  selectedFundingSource: getSelectedFundingSource(state),
  exitUrl: getExitUrl(state),
  firstBillIdWithRecurringBill: getFirstBillIdWithRecurringBill(state),
  recurringBillId: getRecurringBillId(state),
  urlToBack: applicationSelectors.selectUrlToBack(state),
  organizationPreferences: getOrganizationPreferences(state),
});

export function withPayBillData() {
  return function (Component: any) {
    return compose(
      withSiteContext(),
      withBreak(),
      withRedirectToDashboard(),
      withHook()(useCheckRequiredLegalCompanyInfo)
    )(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(
        class ComponentWithPayBillData extends React.PureComponent<Props, State> {
          static defaultProps = {
            inputFields: [],
          };

          constructor(props: Props) {
            super(props);

            this.state = {
              isLoading: false,
              validationErrors: {},
              deliveryOptionsDates: null,
            };
          }

          _isMounted = false;

          setStateWhenMounted(s: any) {
            if (this._isMounted) {
              this.setState(s);
            }
          }

          componentDidMount() {
            this._isMounted = true;
            const {
              isRecurring,
              bill,
              id,
              beginRegularPayBillFlow,
              paymentId,
              payment,
              locationState,
              basePath,
              device,
              orgId,
              history,
            } = this.props;

            if ((!bill.id || !id || bill.id.toString() !== id.toString()) && !isRecurring) {
              const isConfirmationStep = ['pay/confirm', 'pay/complete-legal-info'].some((path) =>
                basePath.includes(path)
              );
              const isQbo = !device.isMobile;

              // This code is handling the case when user on dashboard clicks back after scheduling/editing payment

              if (isConfirmationStep && isQbo) {
                const dashboardRedirect = generatePath(
                  `${dashboardLocations.dashboard}?status=scheduled`,
                  { orgId }
                );

                history.push(dashboardRedirect);
              } else {
                beginRegularPayBillFlow(
                  id,
                  paymentId,
                  locationState?.redirectUrl,
                  locationState?.exitUrl,
                  locationState?.initialPaymentAmount,
                  locationState?.originFromNewLp
                );
              }
            }

            if (payment?.id) {
              this.getDeliveryOptionsDates();
            }
          }

          componentDidUpdate(prevProps: Readonly<Props>) {
            const { payment } = this.props;

            if (payment?.id && prevProps.payment?.id !== payment?.id) {
              this.getDeliveryOptionsDates();
            }
          }

          componentWillUnmount() {
            this._isMounted = false;
            // Fix for the issue https://github.com/sizzers/sizzers-web/issues/1871
            // We must handle back and differentiate if user back to `route inside pay flow`
            // or `route outside pay flow`.
            const payBillRelatedRoutesRegexes = {
              payBill: /^\/orgs\/\d+\/bills\/.+\/pay/,
              payRecurringBill: /^\/orgs\/\d+\/bills\/pay\/recurring/,
              backgroundSyncEntry: /^\/orgs\/\d+\/bills\/pay\/background-sync/,
              editPayment: /^\/orgs\/\d+\/bills\/.+\/edit/,
              addFundingSource: /^\/orgs\/\d+\/welcome\/funding-sources/,
              addDeliveryMethod: /^\/orgs\/\d+\/vendors\/.+\/delivery-method\/.+/,
              companyInfo: /^\/orgs\/\d+\/welcome\/business/,
              settings: /^\/orgs\/\d+\/settings/,
              memo: /^\/orgs\/.+\/bills\/.+\/pay\/memo/,
              expedite: /^\/orgs\/.+\/bills\/.+\/pay\/expedite/,
              confirm: /^\/orgs\/.+\/bills\/.+\/pay\/confirm/,
            };
            const nextLocation = this.props.history.location.pathname;
            const willStayInsidePayBillFlow = Object.values(payBillRelatedRoutesRegexes).some(
              (regex: any) => regex.test(nextLocation)
            );

            const batchRegex = /^\/orgs\/\d+\/bills\/pay\/batch/;
            const willStayInsideBatchBulkFlow = batchRegex.test(this.props.urlToBack || '');
            const willStayInsideBilling = (this.props.history.location.state as Record<string, any>)
              ?.backFromBilling;

            if (
              !willStayInsidePayBillFlow &&
              !willStayInsideBatchBulkFlow &&
              !willStayInsideBilling
            ) {
              this.props.endPayBillFlow(true, true);
            }
          }

          getDeliveryOptionsDates = async () => {
            this.setStateWhenMounted({ isLoading: true });

            const {
              site,
              bill,
              payment,
              orgId,
              basePath,
              isCancelAndRetryFlow,
              selectedFundingSource,
              selectPaymentDates,
              companyInfo,
              organizationPreferences,
            } = this.props;

            const isAch = payment?.deliveryMethod?.deliveryType === DELIVERY_TYPE.ACH;
            const isEditConfirmStep = ['edit/confirm'].some((path) => basePath.includes(path));

            const { deliveryMethodId, fundingSourceId, amount, payBillFlowUUID, deliveryMethod } =
              payment;

            try {
              const deliveryOptionsDates = await getInitialProcessingDates({
                orgId,
                deliveryMethodId: Number.parseInt(deliveryMethodId, 10),
                fundingSourceId,
                scheduledDate: null,
                amount,
                dueDate: bill.dueDate,
                paymentId: payment?.id,
                payBillFlowUUID,
                isFirstWave: !!organizationPreferences?.billPayFirstWaveUser,
              });

              const deliveryOptions = removeUnsupportedDeliveryOptionsByDeliveryMethod({
                site,
                deliveryOptions: deliveryOptionsDates.deliveryOptions,
                deliveryMethod,
                fundingSource: selectedFundingSource,
                companyInfo,
              });

              deliveryOptionsDates.deliveryOptions = deliveryOptions;

              if (isAch && isCancelAndRetryFlow && isEditConfirmStep) {
                const { suggestedScheduledDate, deliveryDate, maxDeliveryDate } =
                  deliveryOptionsDates;

                selectPaymentDates(
                  suggestedScheduledDate,
                  deliveryDate,
                  maxDeliveryDate,
                  DELIVERY_TYPE.ACH
                );
              }

              this.setStateWhenMounted({
                deliveryOptionsDates,
                isLoading: false,
              });
            } catch (e) {
              this.setStateWhenMounted({ isLoading: false });
            }
          };

          goToBillsList = async ({ nextStepURL, shouldReplace }) => {
            const { orgId, bill, navigate, syncPaymentErrorCode } = this.props;

            const nextQboBillToPayData = await billsApi.getNextQboBillToPay({
              orgId,
              params: { billId: bill.id, vendorId: bill.vendor?.id },
            });

            navigate(nextStepURL, shouldReplace, { nextQboBillToPayData, syncPaymentErrorCode });
          };

          goQBDashboard = async (billIdValue?: string | null) => {
            const {
              bill,
              orgId,
              payment,
              redirectToDashboard,
              endPayBillFlow,
              syncPaymentErrorCode,
            } = this.props;
            const billId = billIdValue || bill.id;
            const vendorId = bill.vendor?.id;

            endPayBillFlow(false, true);

            const nextQboBillToPayData = await billsApi.getNextQboBillToPay({
              orgId,
              params: { billId, vendorId },
            });

            redirectToDashboard({
              redirectQuery: DEFAULT_DASHBOARD_REDIRECT_PARAMS,
              state: {
                payment,
                bill,
                confirmationOrigin: ConfirmationOrigin.SINGLE_PAYMENT,
                nextQboBillToPayData,
                syncPaymentErrorCode,
              },
            });
          };

          onNext = (shouldReplace?: boolean) => {
            const {
              bill,
              payment,
              nextStepURL,
              basePath,
              location,
              navigate,
              device,
              redirectToDashboard,
            } = this.props;
            const eventName = `set-${getEventNameFromLocation(location)}`;

            analytics.track(eventPage, `${eventName}-continue`, {
              billId: bill.id,
              partialBillId: getPartialBillId(bill),
            });

            if (nextStepURL) {
              const isConfirmationStep = ['pay/confirm', 'pay/complete-legal-info'].some((path) =>
                basePath.includes(path)
              );
              const isConfirmationEditStep = ['edit/confirm'].some((path) =>
                basePath.includes(path)
              );
              const isQbo = !device.isMobile;
              const shouldRedirectToTheDashboard = isQbo && isConfirmationStep;
              const isRetryFailedToDeliverConfirm =
                isQbo && isConfirmationEditStep && isRetryFailedToDeliverACH(payment);

              if (shouldRedirectToTheDashboard) {
                this.goQBDashboard();
              } else if (isConfirmationStep) {
                this.goToBillsList({ nextStepURL, shouldReplace });
              } else if (isRetryFailedToDeliverConfirm) {
                // new ach failed to deliver
                redirectToDashboard({
                  redirectQuery: PAID_DASHBOARD_REDIRECT_PARAMS,
                  state: {
                    payment,
                    bill,
                  },
                });

                pushNotification({
                  type: NOTIFICATION_VARIANT.SUCCESS,
                  msg: 'bills.form.paymentActivity.failedPaymentDescription.failedToDeliverACHRetryNotification',
                });
              } else {
                navigate(nextStepURL, shouldReplace);
              }
            }
          };

          onPrevMemo = () => {
            const eventName = `set-${getEventNameFromLocation(this.props.location)}`;
            const { payment, isRecurring, bill } = this.props;

            analytics.track(eventPage, `${eventName}-back`, {
              partialBillId: getPartialBillId(bill),
            });

            if (isRecurring) {
              if (payment.deliveryMethodId) {
                this.props.navigate(locations.Bills.pay.recurring.funding.url());
              }
            } else if (this.props.prevStepURL) {
              this.props.navigate(this.props.prevStepURL);
            }
          };

          getUrl = (type: PayEditLocationNamesType) => {
            const { payment, bill } = this.props;
            const url = payment.id
              ? locations.Bills.pay.edit[type].url({
                  id: bill.id,
                  paymentId: payment.id,
                })
              : locations.Bills.pay[type].url({ id: bill.id });

            return url;
          };

          onNextMemo = () => {
            const { id, fetchBill, isRecurring } = this.props;

            // ignore bill if recurring - bill is not created yet
            if (!isRecurring) fetchBill(id);

            return this.onNext();
          };

          onPrevConfirmPayment = () => {
            const { payment, bill, navigate, isCancelAndRetryFlow, orgId, history } = this.props;

            const eventName = `set-${getEventNameFromLocation(this.props.location)}`;

            analytics.track(eventPage, `${eventName}-back`, {
              partialBillId: getPartialBillId(bill),
            });

            const isReturnedCheck = isReturnedCheckPayment(payment);

            if (isReturnedCheck) {
              return navigate(
                locations.Bills.pay.edit.returnedCheckRecovery.entry.url({
                  id: bill.id,
                  paymentId: payment.id,
                })
              );
            }

            const isUndepositedCheckPayment = isUndepositedCheck(payment);

            if (isUndepositedCheckPayment || isCancelAndRetryFlow) {
              return navigate(
                locations.Bills.pay.edit.voidCheck.entry.url({
                  id: bill.id,
                  paymentId: payment.id,
                })
              );
            }

            const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);

            if (isRetryFailedToDeliver) {
              const deliveryMethod = getConfirmDeliveryMethod({
                bill: bill as any,
                payment,
                type: DeliveryEnum.ACH,
              });
              const redirectUrl = locations.Bills.pay.edit.confirm.url({
                id: bill.id,
                paymentId: payment.id,
                orgId,
              });

              const exitUrl = `${history.location.pathname}${history.location.search}`;

              return navigate(
                locations.Vendors.deliveryMethods.ach.edit.url({
                  id: bill.vendor?.id,
                  deliveryMethodId: deliveryMethod?.id,
                  orgId,
                }),
                false,
                { redirectUrl, exitUrl }
              );
            }

            if (payment && payment.id && isPaymentFailed(payment)) {
              if (payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER) {
                const originPayment = getPaymentById(bill.payments, payment.id);

                if (originPayment?.deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
                  return navigate(
                    locations.Bills.pay.edit.virtualCardRecovery.url({
                      id: bill.id,
                      paymentId: payment.id,
                    })
                  );
                }

                return navigate(
                  locations.Bills.pay.edit.deliveryMethodAch.url({
                    id: bill.id,
                    paymentId: payment.id,
                    deliveryMethodId: payment.deliveryMethodId,
                  })
                );
              }

              const {
                id: paymentId,
                deliveryPreference,
                originDeliveryPreference,
                deliveryMethod,
              } = payment;
              const shouldShowFastPaymentPage = this.shouldShowFastPayment(
                paymentId,
                deliveryPreference,
                originDeliveryPreference,
                deliveryMethod
              );

              return shouldShowFastPaymentPage
                ? navigate(
                    locations.Bills.pay.edit.fastPayment.url({
                      id: bill.id,
                      paymentId,
                    })
                  )
                : navigate(
                    locations.Bills.pay.edit.funding.url({
                      id: bill.id,
                      paymentId,
                    })
                  );
            }

            return this.onPrev();
          };

          onNextSelectDate = () => {
            const { payment, bill, navigate } = this.props;

            if (payment && payment.id && isPaymentFailed(payment)) {
              const eventName = `set-${getEventNameFromLocation(this.props.location)}`;

              analytics.track(eventPage, `${eventName}-continue`, {
                partialBillId: getPartialBillId(bill),
              });

              return navigate(
                locations.Bills.pay.edit.confirm.url({
                  id: bill.id,
                  paymentId: payment.id,
                })
              );
            }

            return this.onNext();
          };

          getPaymentDeliveryOptions = (paymentId: string, deliveryPreference?: string) => {
            const { deliveryOptionsDates } = this.state;
            const { selectPaymentDates } = this.props;

            if (deliveryOptionsDates) {
              const { deliveryOptions } = deliveryOptionsDates;
              const selectedDeliveryId = getDeliveryPreference(
                deliveryOptions,
                deliveryPreference as string
              );
              const { type, scheduledDate, deliveryDate, maxDeliveryDate } =
                deliveryOptions[selectedDeliveryId];

              selectPaymentDates(scheduledDate, deliveryDate, maxDeliveryDate, type);

              return deliveryOptions;
            }

            return null;
          };

          shouldShowFastPayment = (
            paymentId,
            deliveryPreference,
            originDeliveryPreference,
            deliveryMethod
          ) => {
            let shouldShowFastPaymentPage = false;
            const deliveryOptions = this.getPaymentDeliveryOptions(paymentId, deliveryPreference);

            if (deliveryOptions) {
              const deliveryType = deliveryMethod?.deliveryType;

              shouldShowFastPaymentPage = shouldShowFastPaymentOnRetryPaymentFlow(
                deliveryType,
                originDeliveryPreference,
                deliveryOptions
              );
            }

            return shouldShowFastPaymentPage;
          };

          onPrev = () => {
            const { bill } = this.props;
            const eventName = `set-${getEventNameFromLocation(this.props.location)}`;

            analytics.track(eventPage, `${eventName}-back`, {
              partialBillId: getPartialBillId(bill),
            });

            if (this.props.prevStepURL) {
              this.props.navigate(this.props.prevStepURL);
            }
          };

          onPrevDate = () => {
            const { payment } = this.props;

            if (payment.deliveryMethodId) {
              const url = this.getUrl(PAY_EDIT_LOCATION_NAME.FUNDING);

              this.props.navigate(url);
            } else {
              this.onPrev();
            }
          };

          onSubmit = async () => {
            const { isRecurring, bill, navigate, companyInfo, checkRequiredLegalCompanyInfo } =
              this.props;
            const hasRequiredLegalCompanyInfo = await checkRequiredLegalCompanyInfo(companyInfo);

            if (hasRequiredLegalCompanyInfo) {
              if (isRecurring) {
                this.createRecurringBill();
              } else {
                this.updateOrCreatePayment();
              }
            } else if (isRecurring) {
              analytics.track(eventPage, 'recurring-go-to-complete-legal-info', {
                partialBillId: getPartialBillId(bill),
              });
              navigate(locations.Bills.pay.recurring.completeLegalInfo.url());
            } else {
              analytics.track(eventPage, 'go-to-complete-legal-info', {
                partialBillId: getPartialBillId(bill),
              });
              navigate(locations.Bills.pay.completeLegalInfo.url({ id: bill.id }));
            }
          };

          validateInternationalFields = ({
            dateOfBirth,
            companyAddress,
            legalAddress,
            inputFieldsWithLegalAddress,
            taxIdType,
          }) => {
            const { profile } = this.props;

            const validateDateOfBirth = () => {
              if (profile.dateOfBirth || !hasDateOfBirthTaxType(taxIdType)) return '';

              if (!dateOfBirth) return 'inputErrors.dateOfBirth.any.required';

              if (!isValidDateOfBirth(dateOfBirth))
                return 'inputErrors.dateOfBirth.string.regex.base';

              return '';
            };

            const validateAddress = (field) => {
              if (isPOBox(field)) return 'inputErrors.companyInfo.POBox';

              return '';
            };

            return {
              dateOfBirth: validateDateOfBirth(),
              companyAddress: validateAddress(companyAddress),
              legalAddress:
                inputFieldsWithLegalAddress?.includes('legalAddressLine1') &&
                validateAddress(legalAddress),
            };
          };

          getValidationErrors = ({
            isValidLegalAddress,
            isCompanyAddressValid,
            dataToUpdate,
          }: {
            isValidLegalAddress?: boolean;
            isCompanyAddressValid?: boolean;
            dataToUpdate: RecordOf<CompanyInfoType>;
          }) => {
            const { inputFields } = this.props;
            const inputFieldsWithLegalAddress =
              isValidLegalAddress && inputFields?.length
                ? [...inputFields, 'legalZipCode', 'legalAddressLine1', 'legalCity']
                : inputFields;

            const errors = getValidationErrors(
              'companyInfo',
              dataToUpdate,
              inputFieldsWithLegalAddress
            );

            return {
              ...errors,
              ...(!isCompanyAddressValid && {
                addressLine1: 'inputErrors.deliveryMethodCheck.addressLine1.any.invalidAddress',
              }),
              ...(!isValidLegalAddress && {
                legalAddressLine1:
                  'inputErrors.deliveryMethodCheck.addressLine1.any.invalidAddress',
              }),
            };
          };

          onLegalInfoSubmit = (
            address: GoogleCombinedAddressType,
            companyAddress: GoogleCombinedAddressType,
            legalCompanyName: string,
            taxId?: string,
            taxIdType?: TaxIdEnum,
            contactFirstName?: string,
            contactLastName?: string,
            phone?: string,
            isValidLegalAddress?: boolean,
            isCompanyAddressValid?: boolean,
            dateOfBirth?: string,
            openLegalAddressInput?: boolean
          ) => {
            const {
              inputFields,
              orgId,
              companyInfo,
              setCompanyInfo,
              isRecurring,
              bill,
              isInternational,
            } = this.props;
            const dataToUpdate = companyInfo.merge({
              legalCompanyName,
              taxId,
              taxIdType,
              contactFirstName,
              contactLastName,
              phone,
              ...convertToServerAddress(companyAddress),
              ...convertToServerLegalAddress(address),
            });
            const eventName = 'complete-legal-info';
            const inputFieldsWithLegalAddress =
              isValidLegalAddress && inputFields?.length
                ? [...inputFields, 'legalZipCode', 'legalAddressLine1', 'legalCity']
                : inputFields;

            const errorFileds = this.getValidationErrors({
              isValidLegalAddress,
              isCompanyAddressValid,
              dataToUpdate,
            });

            const validationErrors: any = Object.keys(errorFileds).reduce(
              (acc: Record<string, string>, key): Record<string, string> => {
                if (key === 'addressLine1') {
                  const shouldSetErrorForCompanyAddress =
                    !isCompanyAddressValid &&
                    (!companyInfo[key] || !companyInfo.legalFormattedAddress);

                  if (shouldSetErrorForCompanyAddress) {
                    acc[key] = errorFileds[key];
                  }

                  return acc;
                }

                const isLegalInfoField = key.toLowerCase().includes('legal');
                const shouldSetError = isLegalInfoField
                  ? openLegalAddressInput && !companyInfo[key]
                  : !companyInfo[key];

                if (shouldSetError && key === 'legalAddressLine1' && !isValidLegalAddress) {
                  acc[key] = errorFileds[key];

                  return acc;
                }

                if (shouldSetError) {
                  acc[key] = errorFileds[key];
                }

                return acc;
              },
              {}
            );

            if (isInternational) {
              const errorMessages = this.validateInternationalFields({
                dateOfBirth,
                companyAddress: dataToUpdate.addressLine1,
                legalAddress: dataToUpdate.legalAddressLine1,
                inputFieldsWithLegalAddress,
                taxIdType,
              });

              if (errorMessages.dateOfBirth)
                validationErrors.dateOfBirth = errorMessages.dateOfBirth;

              if (errorMessages.companyAddress)
                validationErrors.companyAddressPOBox = errorMessages.companyAddress;

              if (errorMessages.legalAddress)
                validationErrors.legalAddressPOBox = errorMessages.legalAddress;
            }

            this.setStateWhenMounted({
              validationErrors,
              isLoading: true,
            });

            if (isValidationOk(validationErrors)) {
              analytics.track(
                eventPage,
                `${eventName}-continue-success`,
                { partialBillId: getPartialBillId(bill) },
                { integrations: { Salesforce: true } }
              );

              companyInfoApi
                .updateCompanyInfo(orgId, {
                  ...dataToUpdate.toObject(),
                  didCompleteQBLegalInfo: true,
                })
                .then(({ companyInfo: updatedCompanyInfo }) => {
                  const companyInfoRecord = CompanyInfoRecord(updatedCompanyInfo);

                  setCompanyInfo(companyInfoRecord);

                  if (isInternational) {
                    this.createInternationalDM(dateOfBirth, taxIdType);
                  } else if (isRecurring) {
                    this.createRecurringBill();
                  } else {
                    this.updateOrCreatePayment();
                  }
                })
                .catch(() => {
                  this.setStateWhenMounted({ isLoading: false });
                });
            } else {
              analytics.track(eventPage, `${eventPage}-validation-error`, {
                ...validationErrors,
                partialBillId: getPartialBillId(bill),
              });
              this.setStateWhenMounted({ isLoading: false });
            }
          };

          getUrlAfterFundingSourceStepForRecoveryFlow = async () => {
            const { payment, bill } = this.props;

            await this.getDeliveryOptionsDates();
            const {
              id: paymentId,
              deliveryPreference,
              originDeliveryPreference,
              deliveryMethod,
            } = payment;
            const shouldShowFastPaymentPage = this.shouldShowFastPayment(
              paymentId,
              deliveryPreference,
              originDeliveryPreference,
              deliveryMethod
            );

            return shouldShowFastPaymentPage
              ? locations.Bills.pay.edit.fastPayment.url({
                  id: bill.id,
                  paymentId,
                })
              : locations.Bills.pay.edit.confirm.url({
                  id: bill.id,
                  paymentId,
                });
          };

          updateOrCreatePayment = async () => {
            const { payment, isCancelAndRetryFlow, bill } = this.props;

            try {
              if (isCancelAndRetryFlow) {
                await this.props.cancelAndRetry();
                this.goQBDashboard(bill.id);

                return;
              }

              if (payment.id && payment.status === PAYMENT_STATUS.FAILED) {
                await this.props.retryFailedPayment();
                this.onNext();

                return;
              }

              if (payment.id) {
                await this.props.updatePayment();
                this.onNext();

                return;
              }

              await this.props.createPayment();
              this.onNext();
            } catch (e) {
              this.setStateWhenMounted({ isLoading: false });
            }
          };

          createInternationalDM = async (dateOfBirth, taxIdType) => {
            const { profile, onLegalInfoCallback, setProfile } = this.props;

            try {
              if (!profile.dateOfBirth && hasDateOfBirthTaxType(taxIdType)) {
                // has previous no date of birth
                const formattedDate = formatDateOfBirth(dateOfBirth);
                const dataToUpdate = profile.merge({
                  dateOfBirth: formattedDate,
                });
                const res = await userApi.update(dataToUpdate);
                const newProfile = profile.merge({
                  dateOfBirth: res.dateOfBirth,
                });

                // update redux profile
                setProfile(newProfile);
              }

              await onLegalInfoCallback();

              this.onNext(true);
            } catch (e) {
              this.setStateWhenMounted({ isLoading: false });
            }
          };

          createRecurringBill = async () => {
            try {
              await this.props.createRecurringBill();
              const firstBillID = this.props.firstBillIdWithRecurringBill;

              this.goQBDashboard(firstBillID);
            } catch (e) {
              this.setStateWhenMounted({ isLoading: false });
            }
          };

          goExit = () => {
            const { navigate, bill, endPayBillFlow, device, orgId, exitUrl } = this.props;
            const { status } = bill;
            const defaultFilters = getBillsDefaultFilters(status);

            if (device.isMobile) {
              navigate(
                generatePath(locations.Bills.filteredViewWithoutId.url(defaultFilters), { orgId })
              );

              return;
            }

            if (exitUrl) {
              endPayBillFlow(false, true);
              navigate(exitUrl);

              return;
            }

            endPayBillFlow(true);
          };

          setPaymentAmount = (amount: number) => {
            const { setPaymentAmount, bill } = this.props;

            if (bill.balance !== amount) {
              analytics.track(eventPage, `payment-amount-changed`, {
                partialBillId: getPartialBillId(bill),
                amount,
                balance: bill.balance,
              });
            }

            setPaymentAmount(amount);
          };

          render() {
            return (
              <Component
                {...this.state}
                navigate={this.props.navigate}
                onPrev={this.onPrev}
                onNext={this.onNext}
                onNextMemo={this.onNextMemo}
                onPrevConfirmPayment={this.onPrevConfirmPayment}
                onNextSelectDate={this.onNextSelectDate}
                onPrevDate={this.onPrevDate}
                onPrevMemo={this.onPrevMemo}
                goExit={this.goExit}
                getUrlAfterFundingSourceStepForRecoveryFlow={
                  this.getUrlAfterFundingSourceStepForRecoveryFlow
                }
                onSubmit={this.onSubmit}
                onLegalInfoSubmit={this.onLegalInfoSubmit}
                setPaymentAmount={this.setPaymentAmount}
                email={this.props.profile.email}
                id={this.props.id}
                qbCashState={this.props.qbCashState}
                isInternational={this.props.isInternational}
                texts={this.props.texts}
                ErrorComponent={this.props.ErrorComponent}
                flowType={this.props.flowType}
                showCCTooltip={this.props.showCCTooltip}
              />
            );
          }
        }
      )
    );
  };
}
