import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import fileAPI from 'src/app/services/api/files';
import { setInvoiceFileAction } from 'src/app/redux/payBillWizard/actions';
import { getInvoiceFileBillId } from 'src/app/redux/payBillWizard/selectors';

export const useUploadInvoice = (billId: string) => {
  const orgId = useSelector(getOrgId);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadCanceled, setIsUploadCanceled] = useState<boolean>(false);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const invoiceFile = useSelector(getInvoiceFileBillId(billId));

  const dispatch = useDispatch();

  const handleUploadStart = () => {
    setIsUploading(true);
    setIsUploadCanceled(false);
    setIsUploadError(false);
  };

  const handleUploadSuccess = (file) => {
    dispatch(
      setInvoiceFileAction(billId, {
        id: file.id,
        name: file.fileName,
      })
    );

    setIsUploading(false);
    setIsUploadError(false);
  };

  const handleUploadError = () => {
    setIsUploading(false);
    setIsUploadError(true);
  };

  const handleRetry = () => {
    setIsUploading(false);
    setIsUploadError(false);
  };

  const onDeleteAttachment = () => {
    dispatch(setInvoiceFileAction(billId, null));
  };

  const handleSelectFile = (file: File) => {
    handleUploadStart();
    fileAPI
      .uploadFile(orgId, file)
      .then((object) => {
        handleUploadSuccess(object.file);
      })
      .catch(handleUploadError);
  };

  return {
    invoiceFile,
    isUploading,
    isUploadCanceled,
    isUploadError,
    handleSelectFile,
    handleRetry,
    onDeleteAttachment,
  };
};
