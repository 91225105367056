import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import MIButton from 'src/app/components/common/MIButton';
import { devices } from 'src/app/theme/AppDevices';
import {
  BUTTON_SIZE,
  BUTTONS_DIRECTIONS,
  CONSTS,
  IMAGE_POSITION,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ModalDialogSettings, ModalDialogTypes, ModalDialogVariants } from 'src/app/utils/types';
import styled from 'styled-components';

const QBOMIDialog = ({
  image,
  imagePosition = IMAGE_POSITION.CENTER,
  type,
  variant,
  title,
  titleValues,
  subtitle,
  subtitleValues,
  okButtonText,
  onOkAction,
  cancelButtonText,
  onCancelAction,
  onCloseAction,
  onOkDisabled,
  showCancel = true,
  buttonsDirection = BUTTONS_DIRECTIONS.HORIZONTAL,
  buttonsSize = BUTTON_SIZE.VERY_SMALL,
  className,
  showConfirmationText = true,
  cancelButtonVariant = CONSTS.BUTTON_VARIANT.SECONDARY,
  disclaimerText,
  children,
}: ModalDialogSettings) => {
  const [loading, setLoading] = useState(false);
  const renderCancelButtonText = (
    buttonText: string,
    dialogType: ModalDialogTypes,
    dialogVariant: ModalDialogVariants
  ) => {
    if (!buttonText) {
      return dialogType === DIALOG_TYPE.ALERT && dialogVariant === DIALOG_VARIANTS.SUCCESS
        ? 'dialogs.buttons.done'
        : 'dialogs.buttons.cancel';
    }

    return buttonText;
  };

  const onOk = () => {
    const res: any = onOkAction && onOkAction();

    if (res?.finally) {
      setLoading(true);
      res.finally(() => setLoading(false));
    }
  };

  const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

  return ReactDOM.createPortal(
    <DialogContainer
      className={className}
      onClick={stopPropagation}
      data-testid="component-qbo-mi-dialog"
    >
      <Dialog>
        <Close>
          <CloseAction onClick={onCloseAction || onCancelAction}>
            <i className="icon-close-icon" />
          </CloseAction>
        </Close>
        {image && <Illustration src={image} imagePosition={imagePosition} />}
        <TextsContainer className="modal-text-block">
          <TitleContainer className="modal-title" textAlign={imagePosition}>
            <MIFormattedText label={title} values={titleValues} />
          </TitleContainer>
          {subtitle && (
            <SubtitleContainer className="modal-subtitle" textAlign={imagePosition}>
              <MIFormattedText label={subtitle} values={subtitleValues} />
            </SubtitleContainer>
          )}

          {type === DIALOG_TYPE.CONFIRM &&
            okButtonText &&
            onOkAction &&
            onCancelAction &&
            showCancel &&
            showConfirmationText && (
              <SubtitleContainer>
                <MIFormattedText label={disclaimerText || 'general.areYouSure'} />
              </SubtitleContainer>
            )}
        </TextsContainer>
        {children}
        <ActionsContainer
          showButtons={DIALOG_TYPE.CONFIRM && onCancelAction && showCancel}
          buttonsDirection={buttonsDirection}
          className="actions-container"
        >
          {onCancelAction && showCancel && (
            <ButtonContainer buttonsDirection={buttonsDirection}>
              <MIButton
                variant={cancelButtonVariant}
                onClick={onCancelAction}
                label={renderCancelButtonText(cancelButtonText || '', type, variant)}
                fullWidth
                size={buttonsSize}
                disabled={loading}
              />
            </ButtonContainer>
          )}
          {type === DIALOG_TYPE.CONFIRM && okButtonText && onOkAction && (
            <ButtonContainer buttonsDirection={buttonsDirection}>
              <MIButton
                variant={
                  variant === DIALOG_VARIANTS.SUCCESS && onOkAction && okButtonText
                    ? CONSTS.BUTTON_VARIANT.PRIMARY
                    : CONSTS.BUTTON_VARIANT.DESTRUCTIVE
                }
                onClick={onOk}
                label={okButtonText}
                fullWidth
                size={buttonsSize}
                isProcessing={loading}
                disabled={onOkDisabled}
              />
            </ButtonContainer>
          )}
        </ActionsContainer>
      </Dialog>
    </DialogContainer>,
    document.querySelector('body') as any
  );
};

export default QBOMIDialog;

const directions = {
  [BUTTONS_DIRECTIONS.VERTICAL]: {
    dir: 'column-reverse',
    margin: 'margin-bottom: 1.2rem;',
  },
  [BUTTONS_DIRECTIONS.HORIZONTAL]: {
    dir: 'row',
    margin: 'margin-left: 1.2rem;',
  },
};

const imagePositions = {
  [IMAGE_POSITION.LEFT]: 'flex-start',
  [IMAGE_POSITION.CENTER]: 'center',
};

const DialogContainer = styled.div`
  z-index: ${(props) => props.theme.zIndex.overlay};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.dark.translucent5};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dialog = styled.div`
  box-sizing: border-box;
  padding: 4rem;
  max-width: 57.6rem;
  position: relative;
  width: calc(100% - 6rem);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.1rem solid rgb(212, 215, 220);
  border-radius: 0.8rem;
  background-color: white;
  box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.2);

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 1.3rem;
  }
`;

const TextsContainer = styled.div`
  margin-bottom: 3rem;
  @media ${devices.mobile}, ${devices.phablet} {
    text-align: center;
  }
  ${(props) => props.theme?.components?.QBOMIDialog?.TextsContainer}
`;

const TitleContainer = styled.div<{ textAlign: string }>`
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: ${(props) => props.theme.text.lineHeight.bigTitleM};
  text-align: ${(props) => props.textAlign};

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 2rem;
  }
`;

const SubtitleContainer = styled.div<{ textAlign?: string }>`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.normal};
  text-align: ${(props) => props.textAlign};
  ${(props) => props.theme.text.fontType.regular};
  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 1.4rem;
  }
  ${(props) => props.theme?.components?.QBOMIDialog?.SubtitleContainer}
`;

const ActionsContainer = styled.div<{
  showButtons?: boolean;
  buttonsDirection: BUTTONS_DIRECTIONS;
}>`
  display: flex;
  flex-direction: ${(props) => directions[props.buttonsDirection].dir};

  justify-content: ${(props) => (props.showButtons ? 'flex-end' : 'center')};
  width: 100%;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    align-items: center;
  }

  ${(props) => props.theme?.components?.QBOMIDialog?.ActionsContainer}
`;

const ButtonContainer = styled.div<{ buttonsDirection: BUTTONS_DIRECTIONS }>`
  min-width: 11.8rem;
  text-align: center;
  > button {
    width: 100%;
  }

  &:not(:first-child) {
    ${(props) => directions[props.buttonsDirection].margin};
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    padding: 0 0.2rem;
    &:not(:first-child) {
      margin: 1.2rem 0;
    }
  }

  ${(props) => props.theme?.components?.QBOMIDialog?.ButtonContainer}
`;

export const Close = styled.div`
  font-size: 2rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  ${(props) => props.theme?.components?.QBOMIDialog?.Close}
`;

const CloseAction = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.text.color.label};

  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }
`;

const Illustration = styled.img<{ imagePosition?: IMAGE_POSITION }>`
  margin-bottom: 4rem;
  align-self: ${(props) => imagePositions[props.imagePosition as string]};
  height: 6rem;
  width: 6rem;
`;
