import React from 'react';
import MIButton from 'src/app/components/common/MIButton';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { CONSTS } from 'src/app/utils/consts';
import { ReactComponent as ErrorIcon } from 'src/app/images/version-2/help.svg';
import { StyledQBOLayoutPage } from './PayBillInternationalBlockPage.styles';
import { melioClose } from 'src/app/utils/external-events';

const PREFIX_PATH = 'bills.pay.blockedInternationalPage';

export const PayBillInternationalBlockPage = () => (
  <StyledQBOLayoutPage
    qboFooter={<QBOFooterContainer />}
    hideHeader
    footer={
      <MIButton
        label={`${PREFIX_PATH}.cancelButton`}
        variant={CONSTS.BUTTON_VARIANT.PRIMARY}
        onClick={melioClose}
      />
    }
    image={ErrorIcon}
    title={`${PREFIX_PATH}.title`}
    subtitle={`${PREFIX_PATH}.subtitle`}
  />
);
