import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';

type ReturnType = {
  handleVoidCheckAndResendPayment: () => void;
};

type Props = {
  paymentId: string;
  billId: string;
  locationStateForPayBillFlow?: { exitUrl: string; redirectUrl: string };
  partialBillId?: string;
};

const useVoidCheckActions = ({
  paymentId,
  billId,
  locationStateForPayBillFlow,
  partialBillId,
}: Props): ReturnType => {
  const [historyPush] = useHistoryWithOrgId();

  const handleVoidCheckAndResendPayment = () => {
    analytics.track('login', 'resend-undeposited-check', {
      billId,
      paymentId,
      partialBillId,
    });

    historyPush({
      path: locations.Bills.pay.edit.voidCheck.entry.url({
        id: billId,
        paymentId,
      }),
      state: locationStateForPayBillFlow,
    });
  };

  return { handleVoidCheckAndResendPayment };
};

export { useVoidCheckActions };
