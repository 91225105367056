import React, { FC } from 'react';
import moment from 'moment';
import { getMaxlength } from '@melio/sizzers-js-common';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { BILL_PAGE_TYPE } from 'src/app/utils/consts';
import { Option } from 'src/app/components/common/MISingleSelect';
import { isBillAmountRequiresInvoiceFile } from 'src/app/utils/bills';
import { BillPageType, EditableBillType, FieldType } from 'src/app/utils/types';
import { convertCurrencyToNumber, getCurrencyMask } from 'src/app/utils/currency-utils';
import { getClosestBusinessDay, isBusinessDay, getEndOfLifeMaxDate } from 'src/app/utils/dates';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIForm from 'src/app/components/common/MIForm';
import { FORM_AUTOCOMPLETE_OFF } from 'src/app/version-2/model/constants';
import {
  BillFrequencyEnum,
  FormControlTypeEnum,
  FormTypeEnum,
  SingleSelectFlavorEnum,
  TextInputSizeEnum,
} from 'src/app/version-2/model/enums';
import IntuitAccountOption from './IntuitAccountOption';

type Props = {
  isDisabled: boolean;
  isAutofocus?: boolean;
  vendors?: Option[];
  onFieldChange?: (field: FieldType) => void;
  onChange?: (field: FieldType) => void;
  validationErrors?: Record<string, any>;
  isRecurringBill: boolean;
  isViewRecurring?: boolean;
  manually?: boolean;
  intuitAccountsOptions?: Option[];
  billPageType: BillPageType;
  occurrences: string | null;
  frequency: BillFrequencyEnum | null;
  mode?: any;
  onChangeInvoice?: (file: File) => void;
  onDeleteAttachment?: () => void;
  handleRetry?: () => void;
  fileName?: string;
  isUploadError?: boolean;
  isUploading?: boolean;
  handleSelectFile?: (file: File) => void;
  isFirstWave?: boolean;
} & EditableBillType;

export const QBOBillDetailsForm: FC<Props> = ({
  isDisabled,
  isAutofocus,
  vendorId,
  totalAmount,
  invoiceNumber,
  dueDate,
  note,
  vendors,
  onFieldChange,
  validationErrors,
  mode,
  intuitAccountsOptions,
  intuitAccountId,
  billPageType,
  onChange,
  isRecurringBill,
  isViewRecurring,
  manually,
  occurrences,
  frequency,
  onChangeInvoice,
  onDeleteAttachment,
  fileName,
  isUploading,
  isUploadError,
  handleSelectFile,
  handleRetry,
  isFirstWave,
}) => {
  const showUpload10KInvoiceFileInput =
    !isRecurringBill &&
    manually &&
    isBillAmountRequiresInvoiceFile(convertCurrencyToNumber(totalAmount));

  const site = useSiteContext();

  const formType =
    billPageType === BILL_PAGE_TYPE.CREATE ? FormTypeEnum.WIZARD : FormTypeEnum.DETAILS;
  const selectFlavor =
    billPageType === BILL_PAGE_TYPE.CREATE
      ? SingleSelectFlavorEnum.DEFAULT
      : SingleSelectFlavorEnum.INLINE;
  const inputSize =
    billPageType === BILL_PAGE_TYPE.CREATE ? TextInputSizeEnum.WIZARD : TextInputSizeEnum.INLINE;
  let dueDateNotices;
  let label;
  let specificDay;

  if (isRecurringBill && dueDate) {
    if (frequency === BillFrequencyEnum.WEEKLY) {
      specificDay = dueDate ? moment(dueDate).format('dddd').toString() : '';
      label = 'bills.form.recurring.recurringWeeklyHint';
    } else {
      specificDay = dueDate ? moment(dueDate).format('D').toString() : '';
      label = 'bills.form.recurring.recurringMonthlyHint';
    }

    dueDateNotices = <MIFormattedText label={label} values={{ specificDay }} />;
  }

  const vendorIdFormControl = {
    id: 'vendorId',
    placeholder: 'bills.form.vendorPlaceholder',
    noOptionsLabel: 'bills.form.vendorNoOptions',
    value: vendorId,
    label: 'bills.form.vendor',
    allowCustom: true,
    options: vendors,
    isDisabled,
    onChange: onFieldChange,
    errorMessage: validationErrors && validationErrors.vendorId,
    required: true,
    flavor: selectFlavor,
    createLabel: 'bills.new.vendorAddInSelect',
    controlType: FormControlTypeEnum.SELECT,
    inputMaxLength: getMaxlength('vendor', 'companyName'),
  };

  const intuitAccountIdFormControl = {
    id: 'intuitAccountId',
    placeholder: 'bills.form.intuitAccount',
    label: 'bills.form.intuitAccountPlaceholder',
    emptyLabel: 'bills.form.intuitAccountEmpty',
    options: intuitAccountsOptions,
    isDisabled,
    onChange: onFieldChange,
    errorMessage: validationErrors && validationErrors.intuitAccountId,
    flavor: selectFlavor,
    value: intuitAccountId,
    formatOptionLabel: ({ label: account }, { context }) => (
      <IntuitAccountOption context={context} account={account} />
    ),
    controlType: FormControlTypeEnum.SELECT,
    isHide: false,
    formatSelectViewValue: (selectValue) =>
      selectValue && selectValue.Name ? selectValue.Name : selectValue,
    filterOption: ({ label: account }, term) =>
      account.Name.toLowerCase().indexOf(term.toLowerCase()) > -1,
  };

  const totalAmountFormControl = {
    id: 'totalAmount',
    value: totalAmount,
    mask: getCurrencyMask(),
    label:
      billPageType === BILL_PAGE_TYPE.CREATE ? 'bills.form.billAmount' : 'bills.form.totalAmount',
    placeholder: 'bills.form.totalAmountPlaceholder',
    onChange: onFieldChange,
    disabled: isDisabled,
    autoFocus: isAutofocus,
    required: true,
    size: inputSize,
    errorMessage: validationErrors && validationErrors.totalAmount,
    controlType: FormControlTypeEnum.CURRENCY,
    type: 'text',
    autocomplete: FORM_AUTOCOMPLETE_OFF,
  };

  const invoiceNumberFormControl = {
    id: 'invoiceNumber',
    value: invoiceNumber,
    label: 'bills.form.invoiceNumber',
    emptyLabel: 'bills.form.invoiceNumberEmpty',
    placeholder: 'bills.form.invoiceNumberPlaceholder',
    type: 'text',
    onChange: onFieldChange,
    disabled: isDisabled,
    required: true,
    size: inputSize,
    errorMessage: validationErrors && validationErrors.invoiceNumber,
    controlType: FormControlTypeEnum.TEXT,
    autocomplete: FORM_AUTOCOMPLETE_OFF,
    maxlength: getMaxlength('bill', 'invoiceNumber'),
  };

  const dueDateFormControl = {
    id: 'dueDate',
    date: dueDate,
    label: isRecurringBill ? 'bills.form.recurring.dueDate' : 'bills.form.dueDate',
    emptyLabel: 'bills.form.dueDateEmpty',
    placeholder: isRecurringBill
      ? 'bills.form.recurring.dueDatePlaceholder'
      : 'bills.form.dueDatePlaceholder',
    onChange: onFieldChange,
    required: true,
    size: inputSize,
    errorMessage: validationErrors && validationErrors.dueDate,
    controlType: FormControlTypeEnum.DATE,
    disabled: isDisabled,
    min: isRecurringBill ? moment() : undefined,
    max: getEndOfLifeMaxDate(isFirstWave),
    filterDate: isRecurringBill ? (day) => isBusinessDay(day) : undefined,
    openToDate: isRecurringBill ? getClosestBusinessDay() : undefined,
    notices: [dueDateNotices],
    dateFormat: isRecurringBill ? 'dddd, MMM D, YYYY' : undefined,
    useNativeMobileDatePicker: site.useNativeMobileDatePicker,
  };

  const noteFormControl = {
    id: 'note',
    value: note,
    label: 'bills.form.description',
    emptyLabel: 'bills.form.descriptionEmpty',
    placeholder: 'bills.form.descriptionPlaceholder',
    onChange: onFieldChange,
    disabled: isDisabled,
    size: inputSize,
    errorMessage: validationErrors && validationErrors.note,
    controlType: FormControlTypeEnum.TEXT,
    autocomplete: FORM_AUTOCOMPLETE_OFF,
    maxlength: getMaxlength('bill', 'note'),
  };

  const upload10KInvoiceFormControl = {
    id: 'invoiceFile',
    fileName,
    text: 'bills.form.receivedProduct.instruction',
    isFileUploading: isUploading,
    isFileUploadError: isUploadError,
    onSelectFile: handleSelectFile,
    onRetry: handleRetry,
    onChangeInvoiceFile: onChangeInvoice,
    onDeleteInvoiceFile: onDeleteAttachment,
    controlType: FormControlTypeEnum.FILE,
    pageType: billPageType,
  };

  const occurrencesFormControl = {
    label: 'bills.form.recurring.occurrences',
    inputId: 'occurrences',
    id: 'occurrences',
    value: occurrences,
    required: true,
    size: inputSize,
    controlType: FormControlTypeEnum.TEXT,
    onChange: (field: FieldType) =>
      onChange &&
      onChange({
        id: field.id,
        value: field.value,
      }),
    errorMessage: validationErrors && validationErrors.occurrences,
    autocomplete: FORM_AUTOCOMPLETE_OFF,
    type: 'number',
    min: 0,
    placeholder: 'bills.form.recurring.occurrencesPlaceHolder',
    notices:
      frequency && frequency === BillFrequencyEnum.MONTHLY
        ? ['bills.form.recurring.occurrencesHintForMonthly']
        : ['bills.form.recurring.occurrencesHintForWeekly'],
  };

  const formControls: (Record<string, any> | Record<string, any>[])[] = [
    vendorIdFormControl,
    intuitAccountIdFormControl,
    totalAmountFormControl,
  ];

  if (billPageType === BILL_PAGE_TYPE.CREATE) {
    if (showUpload10KInvoiceFileInput) formControls.push(upload10KInvoiceFormControl);

    if (isRecurringBill) {
      formControls.push(
        [invoiceNumberFormControl, occurrencesFormControl],
        dueDateFormControl,
        noteFormControl
      );
    } else {
      formControls.push([invoiceNumberFormControl, dueDateFormControl], noteFormControl);
    }
  } else {
    if (isViewRecurring) {
      formControls.push(occurrencesFormControl);
    }

    formControls.push(dueDateFormControl, invoiceNumberFormControl, noteFormControl);
  }

  return <MIForm formControls={formControls} mode={mode} formType={formType} />;
};
