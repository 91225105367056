import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import type { BillCreateOptionsType } from 'src/app/utils/types';
import { getOrgId } from 'src/app/redux/user/selectors';
import MILink from 'src/app/components/common/MILink';
import * as WizardElements from 'src/app/components/layout/WizardElements';
import { useBreak } from 'src/app/hoc';
import { BillFileLoader } from 'src/app/components/bill/BillFileLoader';
import BillCreateOptions from './BillCreateOptions';
import UploadFileError from './UploadFileError';

type Props = {
  goWithUploadedBill: (file: Record<string, unknown>, orgId: string) => void;
  goExit: () => void;
  goManual: () => void;
  handleUploadStart: () => void;
  handleUploadCancel: () => void;
  handleManualClick: () => void;
  handleSkipCreateBillClick: () => void;
  handleCancelCreateBillClick: () => void;
  isUploading: boolean;
  isOnboarding: boolean;
  isUploadError: boolean;
  handleUploadError: () => void;
};

const NewBillCreateOptionsPage = ({
  handleUploadStart,
  handleUploadCancel,
  goWithUploadedBill,
  isUploading,
  goExit,
  goManual,
  isOnboarding,
  handleManualClick,
  handleSkipCreateBillClick,
  handleCancelCreateBillClick,
  isUploadError,
  handleUploadError,
}: Props) => {
  const site = useSiteContext();
  const orgId = useSelector(getOrgId);
  const { StepLayout } = site.components;
  const { isDesktop } = useBreak();
  const options: BillCreateOptionsType[] = [
    {
      id: 'add-bill-manually',
      icon: 'icon-add-manually',
      label: 'bills.new.createOptions.addBillManually',
      description: 'bills.new.createOptions.addBillManuallyDescription',
      click: handleManualClick,
    },
    {
      id: 'scan-bill',
      icon: 'icon-camera-scan-bill',
      label: 'bills.new.createOptions.scanBill',
      description: 'bills.new.createOptions.scanBillDescription',
      hideOption: isDesktop,
    },
    {
      id: 'import-bill-file',
      icon: 'icon-upload-file',
      label: 'bills.new.createOptions.importBillFile',
      description: 'bills.new.createOptions.importBillFileDescription',
    },
  ];

  const filteredOptions = options.filter((option) => !option.hideOption);

  return (
    <>
      {isUploading && !isUploadError && <BillFileLoader onCancelAction={handleUploadCancel} />}
      {!isUploading && isUploadError && (
        <UploadFileError handleUploadCancel={handleUploadCancel} goManual={goManual} />
      )}
      {!isUploading && !isUploadError && (
        <StepLayout
          relativeStep={0}
          headerLabel="bills.new.createOptions.header.title"
          goExit={goExit}
          title="bills.new.createOptions.title"
          subtitle="bills.new.createOptions.subTitle"
          onPrev={handleCancelCreateBillClick}
          innerSize={100}
          isHideActions
        >
          <BillCreateOptions
            flavor="block"
            orgId={orgId}
            options={filteredOptions}
            onUploadStart={handleUploadStart}
            onUploadComplete={goWithUploadedBill as any}
            onUploadError={handleUploadError}
          />
          {!isOnboarding && (
            <CancelButtonContainer>
              <MILink label="bills.new.createOptions.cancel" to={handleCancelCreateBillClick} />
            </CancelButtonContainer>
          )}
          {isOnboarding && (
            <WizardOnboardingElementsContainer>
              <WizardElements.WizardOrLine />
              <WizardElements.WizardLinkContainer>
                <MILink
                  label="bills.new.createOptions.skipCreation"
                  to={handleSkipCreateBillClick}
                />
              </WizardElements.WizardLinkContainer>
            </WizardOnboardingElementsContainer>
          )}
        </StepLayout>
      )}
    </>
  );
};

export default NewBillCreateOptionsPage;

const CancelButtonContainer = styled.div`
  margin-top: 3.8rem;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.theme?.pages?.NewBillCreateOptionsPage?.BillCreateOptions?.CancelButtonContainer}
`;

const WizardOnboardingElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
