import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { useBreak } from 'src/app/hoc';
import ExclamationMarkIcon from 'src/app/images/general/exclamation-mark.svg';
import { Center, Divider, Footer, Icon, Modal } from './styles';

interface Props {
  dismiss(): void;
  onClick(): void;
}

export const ChangeMethodModal: React.FC<Props> = ({ dismiss, onClick }) => {
  const { isMobile } = useBreak();

  return (
    <Modal
      image={
        <Center>
          <Icon src={ExclamationMarkIcon} />
        </Center>
      }
      onCloseClick={dismiss}
      titleComponent={
        <Box textStyle="h6">
          <MIFormattedText label="settings.billing.feeCard.modal.changePayment.title" />
        </Box>
      }
      contentComponent={
        <Box>
          <MIFormattedText label="settings.billing.feeCard.modal.changePayment.description" />
        </Box>
      }
      footerComponent={
        <>
          <Divider />
          <Footer>
            <MIButton
              onClick={onClick}
              label="settings.billing.feeCard.modal.changePayment.actions.change"
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              fullWidth={isMobile}
            />
            <MIButton
              onClick={dismiss}
              label="settings.billing.feeCard.modal.changePayment.actions.cancel"
              variant={CONSTS.BUTTON_VARIANT.CANCEL}
              fullWidth={isMobile}
            />
          </Footer>
        </>
      }
    />
  );
};
