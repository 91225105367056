import { useModal } from 'src/app/helpers/react/useModal';
import { EditFundingSourceLabelState } from 'src/app/pages/settings/PaymentMethodsSettingsPageContainer';
import { EditPaymentMethodLabelDialog } from 'src/app/pages/settings/components/EditPaymentMethodLabelDialog';
import { useEffect } from 'react';

export const useFundingSourceEditLabel = (
  editingFundingSourceLabel: EditFundingSourceLabelState,
  resetFundingSourceLabelEdit: () => void
) => {
  useEffect(() => {
    if (editingFundingSourceLabel?.id) {
      showEditPaymentMethodLabelModal();
    }
  }, [editingFundingSourceLabel]);

  const [EditPaymentMethodLabelModal, showEditPaymentMethodLabelModal] = useModal(
    EditPaymentMethodLabelDialog,
    {
      id: 'editPaymentMethodLabelModal',
      editingFundingSourceLabel,
      onDismiss: resetFundingSourceLabelEdit,
    }
  );

  return [EditPaymentMethodLabelModal];
};
