import styled from 'styled-components';

export const ArrivalDateButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ArrivalDateButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrivalDateButtonLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrivalDateMenuButton = styled.div`
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const ArrivalDateImage = styled.img`
  width: 1.6rem;
  margin-right: 0.8rem;
`;

export const DropDownIconContainer = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg)' : '')};

  div {
    display: flex;
  }
`;

export const ArrivalDateNA = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[300]};
`;

export const Indication = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.highlight};
`;

export const SubTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  ${({ theme }) => theme.textStyles.ds.body2Semi};
`;

export const Description = styled.div`
  ${({ theme }) => theme.textStyles.ds.body3}
`;
export const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

export const DeliveryOptionPrice = styled.span``;

export const LabelTooltip = styled.div<{ $isLateBadge?: boolean }>`
  display: flex;
  cursor: pointer;
  margin-left: ${({ $isLateBadge }) => $isLateBadge && '-9ch'};
  width: 3.5rem;
`;

export const ArrivalDateLateBadgeWrapper = styled.div`
  display: flex;
`;

export const LateBadgeDetailsPopoverWrapper = styled.div`
  display: flex;
  padding: 2rem;
  width: 32rem;
  box-sizing: border-box;
  flex-direction: row;
  white-space: normal;
  flex-wrap: wrap;
  align-content: start;
`;

export const LateBadgeDetailsPopoverTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  margin-top: 0.5rem;
`;
export const LateBadgeDetailsPopoverTitle = styled.div`
  ${(props) => props.theme.textStyles.ds.h6}
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin: 0 0 1.6rem 0;
`;
export const LateBadgeDetailsPopoverText = styled.div`
  ${(props) => props.theme.textStyles.ds.body2}
  margin: 0 0 1.6rem 0;
`;

export const LateBadgeButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  flex-basis: 100%;
`;

export const ArrivalDateMenuButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
