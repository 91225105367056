type HeaderStyleParams = {
  accessor: string;
  isOrderingDisabled?: boolean;
  orderBy?: string;
};

export const headerStyle = ({ accessor, isOrderingDisabled, orderBy }: HeaderStyleParams) => ({
  cursor: isOrderingDisabled ? 'inherit' : 'pointer',
  fontWeight: accessor === orderBy ? 600 : 'inherit',
  color: accessor === orderBy ? 'black' : 'inherit',
  _hover: {
    color: isOrderingDisabled ? 'inherit' : 'black',
    '.order-arrow': {
      color: 'black',
    },
  },
});
