import React, { useEffect } from 'react';
import { Box, Flex, Image } from '@melio/billpay-design-system';

import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { SupportLine } from 'src/app/components/common/OutsideLayout/SupportLine';
import { MIFormattedText } from 'src/app/utils/formatting';
import { useLocationState } from 'src/app/utils/hooks';
import analytics from 'src/app/services/analytics';
import vbdLogo from 'src/app/images/qbo/verified-business-directory.svg';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';

const QBOVerifiedBusinessDirectoryPage = (): JSX.Element => {
  const [flow] = useLocationState('flow', 'unknown');

  useEffect(() => {
    analytics.page('qb-directory', 'verified-business-directory', { flow });
  }, []);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success" innerSize={57}>
      <Image src={qbLogo} __css={qbLogoStyle} />
      <Box __css={cardStyle}>
        <Flex direction="column" alignItems="center">
          <Image src={vbdLogo} __css={vbdLogoStyle} />
          <Box mb="1.6rem" textStyle="h6" fontWeight="600">
            <MIFormattedText label="vendors.qbDirectory.verifiedBusinessDirectory.title" />
          </Box>
          <Box textStyle="body3" fontWeight="400">
            <MIFormattedText label="vendors.qbDirectory.verifiedBusinessDirectory.subtitle" />
          </Box>
        </Flex>
      </Box>
      <SupportLine __css={supportLineStyle} />
    </QBOLayoutPage>
  );
};

const qbLogoStyle = {
  w: '15rem',
  alignSelf: 'center',
  mb: '4rem',
};

const vbdLogoStyle = {
  w: '33.6rem',
  maxW: '100%',
  mb: ['3.1rem', '3.1rem', '2.5rem'],
};

const cardStyle = {
  border: '1px solid',
  borderColor: 'gray.200',
  boxSizing: 'border-box',
  borderRadius: '0.6rem',
  bgColor: 'white',
  textAlign: 'center',
  p: ['5.2rem 1.6rem 4.4rem', '5.2rem 1.6rem 4.4rem', '4rem'],
  color: 'black',
};

const supportLineStyle = {
  m: ['2rem 0 5rem', '2rem 0'],
};

export { QBOVerifiedBusinessDirectoryPage };
