import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { isRecurringBill } from 'src/app/utils/bills';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { getBill } from 'src/app/redux/payBillWizard/selectors';

/**
 * General warning/info/error in funding source page
 */

const NotificationStatus = ({ className = '' }) => {
  const [visible, setVisible] = useState<boolean>(true);
  const bill = useSelector(getBill);
  const isRecurring = isRecurringBill(bill);

  let type;
  let label = '';
  const onClose = () => setVisible(false);

  const values: Record<string, any> = {};

  if (isRecurring) {
    type = NOTIFICATION_CARD_TYPES.INFO;
    label = 'bills.pay.fundingSource.notification.recurring.label';
  }

  if (!type || !label || !visible) return null;

  return (
    <StyledCard className={className}>
      <QBONotificationCard type={type} subtitle={{ label, values }} onClose={onClose} />
    </StyledCard>
  );
};

const StyledCard = styled.div`
  margin-top: 3rem;
  margin-bottom: 3.8rem;
`;

export { NotificationStatus };
