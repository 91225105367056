const baseStyle = {
  textDecoration: 'none',
  color: 'ds.blue.200',
  fontFamily: 'body',
  cursor: 'pointer',

  _hover: {
    textDecoration: 'underline',
  },
};

export const LinkTheme = {
  baseStyle,
};
