import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { RESOURCE_BASE } from '../config';
import { RecoveryFlowType } from '../../consts';

type Props = {
  deliveryType: string;
  flowType: RecoveryFlowType;
  description?: string | React.ReactNode;
  descriptionValues?: {
    fee?: React.ReactNode;
    learnMoreLink?: React.ReactNode;
  };
};

export const DeliveryMethodDescription = ({
  deliveryType,
  flowType,
  description,
  descriptionValues,
}: Props) => {
  const deliveryMethodDescription =
    description || `${RESOURCE_BASE[flowType]}.stepChooseDM.${deliveryType}.description`;

  return (
    <StyledDeliveryMethodDescription>
      <MIFormattedText label={deliveryMethodDescription as string} values={descriptionValues} />
    </StyledDeliveryMethodDescription>
  );
};

const StyledDeliveryMethodDescription = styled.div`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.darkGrey};
`;
