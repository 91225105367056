import React from 'react';
import { useSelector } from 'react-redux';
import { Badge, Tooltip } from '@melio/billpay-design-system';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { SCHEDULED_BILL_DASHBOARD_STASUSES } from 'src/app/pages/qb-dashboard/consts';
import billsStore from 'src/app/modules/bills/bills-store';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { BillType, PaymentType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const QBOScheduledStatusCell = ({ item }: Props) => {
  const { statusInDashboard } = item.metadata;
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(item.paymentId));
  const bill: BillType = useSelector(billsStore.selectors.byId(item.billId));
  const isRecurring = bill?.recurringBillId;

  if (payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING) {
    return (
      <Badge variant="warning">
        <MIFormattedText label="paymentDashboard.columns.scheduledStatus.PendingApproval" />
      </Badge>
    );
  }

  if (isRecurring) {
    return (
      <Badge variant="black">
        <MIFormattedText label="paymentDashboard.columns.scheduledStatus.recurring" />
      </Badge>
    );
  }

  if (statusInDashboard === SCHEDULED_BILL_DASHBOARD_STASUSES.PENDING_VENDOR_DETAILS) {
    return (
      <Tooltip
        label={
          <MIFormattedText label="paymentDashboard.columns.scheduledStatus.unilateralTooltip" />
        }
      >
        <Badge variant="darkGray">
          <MIFormattedText label="paymentDashboard.columns.scheduledStatus.unilateral" />
        </Badge>
      </Tooltip>
    );
  }

  if (statusInDashboard === SCHEDULED_BILL_DASHBOARD_STASUSES.BANK_UNVERIFIED) {
    return (
      <Tooltip
        label={
          <MIFormattedText
            label="paymentDashboard.columns.scheduledStatus.unverifiedBankAccountTooltip"
            values={{
              date: <MIFormattedDate year={undefined} date={payment?.scheduledDate} />,
            }}
          />
        }
        isDisabled={!payment?.scheduledDate}
      >
        <Badge variant="darkGray">
          <MIFormattedText label="paymentDashboard.columns.scheduledStatus.unverifiedBankAccount" />
        </Badge>
      </Tooltip>
    );
  }

  return (
    <Badge variant="info">
      <MIFormattedText label="paymentDashboard.columns.scheduledStatus.scheduled" />
    </Badge>
  );
};

export default QBOScheduledStatusCell;
