import React, { useState, useEffect, useCallback } from 'react';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import { useRouteMatch } from 'react-router-dom';
import { BankRecord } from 'src/app/pages/vendor/records';
import { BankType, FieldType, DeliveryMethodType } from 'src/app/utils/types';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';

import { getOwnedVendorId, getFundingSources, getOrgId } from 'src/app/redux/user/selectors';
import { CONSTS } from 'src/app/utils/consts';
import { BANK_ACCOUNT_CHECKING_TYPE } from 'src/app/version-2/model/constants';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { FundingSource } from 'src/app/version-2/model/dtos';
import EditBankDeliveryMethodPage from './components/EditBankDeliveryMethodPage';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const AchDeliveryMethodPage = ({ onNext, onPrev, onExit }: Props) => {
  const match = useRouteMatch<{
    vendorId: string;
    deliveryMethodId?: string;
  }>();
  const vendorId = match.params?.vendorId;
  const selectedDeliveryMethodId = match.params?.deliveryMethodId;
  const orgId = useSelector(getOrgId);
  const paymentActions = useStoreActions(paymentsStore);
  const [validationErrors, setValidationErrors] = useState({});
  const userFundingSources = useSelector(getFundingSources);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const [errorCode, setErrorCode] = useState('');
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const [isDeliveryMethodChange, setDeliveryMethodChange] = useState(false);
  const isLoadingCreate = (
    useSelector(deliveryMethodsStore.selectors.create.status()) as { loading: boolean }
  )?.loading;
  const isLoadingUpdate = (
    useSelector(deliveryMethodsStore.selectors.update.status(selectedDeliveryMethodId)) as {
      loading: boolean;
    }
  )?.loading;
  const isLoading = isLoadingCreate || isLoadingUpdate;
  const bankAccount = (
    useSelector(deliveryMethodsStore.selectors.byId(selectedDeliveryMethodId)) as DeliveryMethodType
  )?.bankAccount;
  const [bank, setBank] = useState<RecordOf<BankType>>(bankAccount || (BankRecord() as any));
  const { vendor, fetchVendor } = useFetchVendor(vendorId);
  const onChange = ({ id, value }: FieldType) => {
    setDeliveryMethodChange(true);
    setBank((prevBank) => ({
      ...prevBank,
      accountType: BANK_ACCOUNT_CHECKING_TYPE,
      [id]: value,
    }));
  };

  const getDeliveryMethodById = useCallback(() => {
    if (vendor?.deliveryMethods?.length && selectedDeliveryMethodId) {
      deliveryMethodActions.fetch({
        orgId,
        vendorId,
        id: selectedDeliveryMethodId,
      });
    }
  }, [vendorId, orgId, selectedDeliveryMethodId, vendor, deliveryMethodActions]);

  useEffect(() => {
    if (bankAccount) {
      setBank(bankAccount as any);
    }
  }, [bankAccount]);

  useEffect(() => {
    getDeliveryMethodById();
  }, [getDeliveryMethodById]);

  const checkBankAccount = ({ routingNumber, accountNumber }: BankType) => {
    const hasFundingSources = userFundingSources.some((accountType: FundingSource) => {
      const bankAccount = accountType.bankAccount || {};

      return (
        bankAccount.routingNumber === routingNumber && bankAccount.accountNumber === accountNumber
      );
    });

    return hasFundingSources
      ? {
          accountNumber: 'inputErrors.deliveryMethodAch.tryToPayHimself',
        }
      : {};
  };

  const getBankAccount = (bank: BankType) => ({
    accountNumber: bank.accountNumber,
    routingNumber: bank.routingNumber,
    accountType: bank.accountType,
  });

  const onDone = async () => {
    try {
      let validationErrors = getValidationErrors('deliveryMethodAch', bank, [
        'routingNumber',
        'accountNumber',
      ]);

      if (isValidationOk(validationErrors) && vendorId !== ownedVendorId) {
        validationErrors = checkBankAccount(bank);
      }

      setValidationErrors(validationErrors);

      if (isValidationOk(validationErrors)) {
        if (selectedDeliveryMethodId) {
          if (isDeliveryMethodChange) {
            const updatedDeliveryMethod = await deliveryMethodActions.update({
              orgId,
              vendorId,
              id: selectedDeliveryMethodId,
              deliveryMethod: {
                bankAccount: getBankAccount(bank),
                deliveryType: CONSTS.DELIVERY_TYPE.ACH,
              },
            });

            await paymentActions.quickpay.newPaymentWizard.update({
              deliveryMethodId: updatedDeliveryMethod.payload.id,
              vendorId,
            });
          } else {
            await paymentActions.quickpay.newPaymentWizard.update({
              deliveryMethodId: Number(selectedDeliveryMethodId),
              vendorId: Number(vendorId),
            });
          }
        } else {
          const deliveryMethod = {
            bankAccount: bank,
            deliveryType: CONSTS.DELIVERY_TYPE.ACH,
            isFilledByVendor: vendorId === ownedVendorId,
          };
          const response = await deliveryMethodActions.create({
            orgId,
            vendorId,
            ...deliveryMethod,
          });

          await paymentActions.quickpay.newPaymentWizard.update({
            deliveryMethodId: response.payload.id,
            vendorId,
          });
        }

        await fetchVendor();
        onNext();
      }
    } catch (error: any) {
      setErrorCode(error.code);
    }
  };

  return (
    <EditBankDeliveryMethodPage
      vendorName={vendor?.printOnCheck || vendor?.companyName}
      bank={bank}
      onDone={onDone}
      onExit={onExit}
      onChange={onChange}
      onPrev={onPrev}
      validationErrors={validationErrors}
      errorCode={errorCode}
      companyName={vendor?.printOnCheck || vendor?.companyName}
      isLoading={isLoading}
    />
  );
};

export default AchDeliveryMethodPage;
