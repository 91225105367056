import { keyframes } from '@melio/billpay-design-system';
import { BATCH_FOOTER_HEIGHT } from '../../consts';

export const footerWrapperStyle = {
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  height: BATCH_FOOTER_HEIGHT,
  bg: 'black',
  position: 'fixed',
  bottom: '0',
  animation: `0.5s linear alternate ${keyframes({
    from: { bottom: `-${BATCH_FOOTER_HEIGHT}` },
    to: { bottom: '0' },
  })}`,
  px: 4,
  boxSizing: 'border-box',
};

export const mainButtonStyle = ({ hasMenuActions, isDisabled = false }) => {
  const baseStyles = {
    display: 'flex',
    alignItems: 'center',
    bg: 'primary.500',
    px: 6,
    apply: 'textStyles.body2',
    fontWeight: 600,
    color: 'white',
    cursor: 'pointer',
    borderRadius: 'xl',
    borderColor: 'white',
    opacity: isDisabled ? 0.6 : 1,
    _hover: {
      bg: isDisabled ? 'primary.500' : 'primary.700',
    },
  };

  return hasMenuActions
    ? {
        ...baseStyles,
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        borderRight: '0.1rem solid',
      }
    : baseStyles;
};

export const menuButtonWrapper = ({ isDisabled = false }) => ({
  display: 'flex',
  bg: 'primary.500',
  borderTopRightRadius: 'xl',
  borderBottomRightRadius: 'xl',
  alignItems: 'center',
  opacity: isDisabled ? 0.6 : 1,
  _hover: {
    bg: isDisabled ? 'primary.500' : 'primary.700',
  },
});
export const menuButton = {
  borderTopRightRadius: 'xl',
  borderBottomRightRadius: 'xl',
  p: 3,
  pt: '1.3rem',
  pr: 4,
};

export const TitleStyle = {
  apply: 'textStyles.body2',
  color: 'white',
  mr: 4,
};
