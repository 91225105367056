import styled from 'styled-components';
import cardIcons from 'src/app/images/icons/card_icons.svg';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  showIconCC?: boolean;
  className?: string;
};

export const SecurityDetails = ({ showIconCC, className = '' }: Props) => (
  <SecurityDetailsContainer className={className}>
    {showIconCC && <CardIcons src={cardIcons} alt="Card icons" />}
    <LockContainer>
      <LockImage>
        <i className="icon-lock-icon" />
      </LockImage>
      <LockText>
        <MIFormattedText label="guests.register.securityText" />
      </LockText>
    </LockContainer>
  </SecurityDetailsContainer>
);

const SecurityDetailsContainer = styled.div`
  margin: 0 auto 4rem auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const CardIcons = styled.img`
  margin-bottom: 2rem;
  height: 2.4rem;
`;
const LockContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LockImage = styled.div`
  font-size: 2.4rem;
  color: rgba(113, 113, 115, 1);
  margin-right: 1.5rem;
  align-self: start;
`;

const LockText = styled.div`
  text-align: left;
  align: justify;
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.hint};
`;
