import { useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHasFeatureFlag } from 'src/app/hooks/useHasFeatureFlag';
import { getCompanyInfo } from 'src/app/redux/user/selectors';
import InternationalContext from '../Context';
import { TaxIdEnum } from 'src/app/version-2/model/enums';

const useShowUltimateBeneficialOwnersFlow = (): boolean => {
  const [, actions] = useContext(InternationalContext);
  const companyInfo = useSelector(getCompanyInfo, shallowEqual);
  const { isFeatureFlagEnabled } = useHasFeatureFlag();
  const isUltimateBenficialOwnersOn = isFeatureFlagEnabled('ultimateBeneficialOwnersFlow');

  return (
    isUltimateBenficialOwnersOn &&
    companyInfo?.taxIdType === TaxIdEnum.EIN &&
    actions.hasNoUltimateBeneficialOwners()
  );
};

export default useShowUltimateBeneficialOwnersFlow;
