import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { envApi } from 'src/app/version-2/api/env';
import mapKeys from 'lodash/mapKeys';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/app/utils/consts';
import styled from 'styled-components';
import { withPreservedStateNavigator } from 'src/app/hoc';
import { PlaidLink } from 'src/app/pages/onboarding/funding-sources/bank/components/PlaidLink';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import financialAccountsApi from 'src/app/services/api/financialAccounts';
import { AreaLoader } from '@melio/billpay-design-system';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { selectFundingSourceAction } from 'src/app/redux/payBillWizard/actions';
import { loadFundingSourcesAction } from 'src/app/redux/user/actions';
import { getOrgId, getFundingSources } from 'src/app/redux/user/selectors';
import { isUnverifiedAchLimitExceeded } from 'src/app/utils/funding-sources';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import {
  AddFundingSourceWizardOriginEnum,
  DbAnalyticsTraitsEnum,
} from 'src/app/version-2/model/enums';

const eventPage = 'bank-add-plaid';

type Props = {
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  locationState: Record<string, any>;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const FooterWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  display: block !important;
`;

const PlaidFooter = () =>
  ReactDOM.createPortal(
    <FooterWrapper className="plaid-footer">
      <QBOFooterContainer />
    </FooterWrapper>,
    document.body
  );

const SetBankPlaidPageContainer = ({
  locationState,
  navigate,
  navigateToExitWithPreservedState,
  navigateWithPreservedState,
}: Props) => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const userFundingSources = useSelector(getFundingSources);
  const [isPlaidOpen, setIsPlaidOpen] = useState<boolean>(true);
  const { paymentStoreActions } = useJustPayStore();

  const refreshFundingSources = () =>
    new Promise((resolve, reject) => {
      dispatch(loadFundingSourcesAction(resolve, reject));
    });

  const onPlaidSuccess = (token: string, metadata: Record<string, any>) => {
    const origin = get(locationState, 'preservedState.origin', '');

    if (!metadata || metadata.error) {
      analytics.track(eventPage, 'plaid-error', { error: metadata.error });

      return;
    }

    const { account } = metadata;

    if (!account) {
      analytics.track(eventPage, 'plaid-error', {
        error: 'account not selected',
      });

      return;
    }

    const selectedAccountsIds = [account.id];

    addFundingSourcePlaidAccount(selectedAccountsIds, token, origin, metadata);
  };

  const onPlaidExit = () => {
    analytics.track(eventPage, 'plaid-exit');
    setIsPlaidOpen(false);

    if (isUnverifiedAchLimitExceeded(userFundingSources)) {
      if (navigateToExitWithPreservedState) {
        navigateToExitWithPreservedState(locationState);
      } else {
        navigate(locations.MainApp.dashboard.url());
      }
    } else {
      navigate(locations.Onboarding.fundingSources.bank.cantFind.url());
    }
  };

  const handleEvent = (eventName, eventProperties) => {
    analytics.track(
      'add-funding-source',
      `plaid-${envApi.getConfig().services.plaid.env}-link-${eventName.toLowerCase()}`,
      addPlaidPrefixToEventProps(eventProperties)
    );
  };

  const addPlaidPrefixToEventProps = (properties: Record<string, string>): Record<string, string> =>
    mapKeys(properties, (__value, key) => `plaid_metadata_${key}`);

  const addFundingSourcePlaidAccount = (
    selectedAccountsIds: string[],
    token: string,
    origin: string,
    successEventMetadata: Record<string, string>
  ) => {
    financialAccountsApi
      .addPlaidAccount(orgId, {
        token,
        selectedAccountsIds,
      })
      .then((data) => {
        refreshFundingSources()
          .then(async () => {
            const accounts = data.accounts && data.accounts.length ? data.accounts : [];

            analytics.track(eventPage, 'plaid-success', successEventMetadata);
            analytics.trackMqlEvent('added-funding', 'mql');
            analytics.trackMqlEvent('added-funding-plaid', 'mql');
            analytics.setTraits({
              [DbAnalyticsTraitsEnum.ADDED_FUNDING]: true,
            });
            analytics.setFundingSourceTraits();

            setIsPlaidOpen(false);

            if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL) {
              dispatch(selectFundingSourceAction(accounts[0].id));
            }

            if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY) {
              paymentStoreActions.justPay.justPayWizard.update({
                fundingSourceId: accounts[0].id,
                suggestedFundingSources: {
                  selectedFundingSourceId: accounts[0].id,
                },
              });
            }

            if (origin === AddFundingSourceWizardOriginEnum.BATCH_BULK) {
              navigateWithPreservedState({ newFundingSourceId: accounts[0].id });
            }

            navigate(locations.Onboarding.fundingSources.bank.account.url(), false, {
              ...locationState,
              fundingSourceId: accounts[0].id,
              origin,
              accounts,
            });
          })
          .catch(() => {
            setIsPlaidOpen(false);
          });
      })
      .catch(() => {
        setIsPlaidOpen(false);
      });
  };

  return (
    <>
      {isPlaidOpen || <AreaLoader />}
      <PlaidLink
        onEvent={handleEvent}
        orgId={orgId as unknown as number}
        onSuccess={onPlaidSuccess}
        onExit={onPlaidExit}
        showDialog={isPlaidOpen}
      />
      <PlaidFooter />
    </>
  );
};

export default compose(withPreservedStateNavigator())(SetBankPlaidPageContainer);
