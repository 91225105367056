import filter from 'lodash/filter';
import merge from 'lodash/merge';
import api from 'src/app/services/api/bills';
import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import regularListSlice from './regular-list-slice';
import markAsPaidSlice from './mark-as-paid-slice';

import { name } from './consts';
import batchMarkAsPaidSlice from './batch-mark-as-paid-slice';
import { qbDashboardListItemsStore } from '../qb-dashboard-list-items/qb-dashboard-list-items-store';

const billsStore = createRestfulSlice({
  name,
  api: {
    list: (params) =>
      api.getBills(params).then((res) => ({ items: res.objects, totalCount: res.totalCount })),
  },
  slices: {
    regularList: regularListSlice,
    markAsPaid: markAsPaidSlice,
    batchMarkAsPaid: batchMarkAsPaidSlice,
  },
  selectors: {
    dictionary: (state) => state[name].byId || {},
    byId: (id) => (state) => state[name]?.byId[id],
    getByIds: (ids) => (state) => filter(state[name].byId, (bill) => ids.includes(bill.id)),
  },
  extraReducers: {
    [qbDashboardListItemsStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        state.byId[item.bill.id] = merge(state.byId[item.bill.id] || {}, item.bill);
      });
    },
  },
});

export default billsStore;
