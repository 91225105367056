import { featureFlags } from '@melio/shared-web';
import { StepParams } from 'src/app/version-2/components/HelpGuide/HelpGuide';
import { Bill, DeliveryMethod, FundingSource } from 'src/app/version-2/model/dtos';
import { UploadedFileInfo } from 'src/app/version-2/model/dtos/UploadedFileInfo';
import { DeliveryEnum, FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import {
  BatchBulkItem,
  SelectedPaymentIntent,
  UploadInvoiceFile,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  getDefaultPaymentMethod,
  orderPaymentMethods,
} from 'src/app/version-2/utils/paymentMethods.utils';
import { getDefaultDeliveryMethod, orderDeliveryMethods } from './deliveryMethod.utils';

import orderBy from 'lodash/orderBy';

interface AddNewDeliveryMethodParams {
  newDeliveryMethod: DeliveryMethod;
  paymentIntentDeliveryMethods: DeliveryMethod[];
}

export const addNewDeliveryMethod = ({
  newDeliveryMethod,
  paymentIntentDeliveryMethods,
}: AddNewDeliveryMethodParams) => {
  const noVirtualDeliveryMethod = paymentIntentDeliveryMethods.filter(
    (deliveryMethod) => deliveryMethod.deliveryType !== DeliveryEnum.VIRTUAL
  );

  const existingDeliveryMethod = noVirtualDeliveryMethod.find(
    (deliveryMethod) => newDeliveryMethod.deliveryType === deliveryMethod.deliveryType
  );

  if (existingDeliveryMethod) {
    return noVirtualDeliveryMethod.map((deliveryMethod) => {
      if (deliveryMethod?.deliveryType === newDeliveryMethod.deliveryType) {
        return newDeliveryMethod;
      }

      return deliveryMethod;
    });
  }

  return [...noVirtualDeliveryMethod, newDeliveryMethod];
};

interface ExtractBatchListFromPreservedStateParams {
  preservedState: {
    batchList: BatchBulkPaymentIntent[];
    selectedPaymentIntent: SelectedPaymentIntent;
    newDeliveryMethod?: DeliveryMethod;
    newFundingSourceId?: number;
  };
  fundingSources: FundingSource[];
}

export const extractBatchListFromPreservedState = ({
  preservedState,
  fundingSources,
}: ExtractBatchListFromPreservedStateParams): BatchBulkPaymentIntent[] => {
  const { batchList, selectedPaymentIntent, newDeliveryMethod, newFundingSourceId } =
    preservedState;

  const selectedPaymentIntentDTO = batchList.find(
    (paymentIntentDTO) => paymentIntentDTO.id === selectedPaymentIntent?.paymentIntentId
  );
  const selectedPaymentIntentVendorId = selectedPaymentIntentDTO?.payment?.vendorId;

  return batchList.map((paymentIntent) => {
    const newDeliveryMethods = newDeliveryMethod
      ? addNewDeliveryMethod({
          newDeliveryMethod,
          paymentIntentDeliveryMethods: paymentIntent.payment.vendor.deliveryMethods,
        })
      : paymentIntent.payment.vendor.deliveryMethods;

    const isSelectedPaymentIntent = paymentIntent.id === selectedPaymentIntent?.paymentIntentId;

    if (isSelectedPaymentIntent) {
      const newFundingSource = newFundingSourceId
        ? fundingSources.find((fundingSource) => fundingSource.id === newFundingSourceId)
        : paymentIntent.payment.fundingSource;

      return {
        ...paymentIntent,
        payment: {
          ...paymentIntent.payment,
          fundingSourceId: newFundingSourceId || paymentIntent.payment.fundingSourceId,
          fundingSource: newFundingSource,
          deliveryMethodId: newDeliveryMethod?.id || paymentIntent.payment.deliveryMethodId,
          deliveryMethod: newDeliveryMethod || paymentIntent.payment.deliveryMethod,
          vendor: {
            ...paymentIntent.payment.vendor,
            deliveryMethods: newDeliveryMethods,
          },
        },
      } as BatchBulkPaymentIntent;
    }

    // populate DMs list with newly added DM for not selected payment intents with the same vendor
    if (paymentIntent.payment.vendorId === selectedPaymentIntentVendorId && newDeliveryMethod) {
      return {
        ...paymentIntent,
        payment: {
          ...paymentIntent.payment,
          vendor: {
            ...paymentIntent.payment.vendor,
            deliveryMethods: newDeliveryMethods,
          },
        },
      } as BatchBulkPaymentIntent;
    }

    return paymentIntent;
  });
};

export const sortBatchList = (batchList: BatchBulkItem[]) =>
  orderBy(batchList, [(item) => Number(item.deductionDate)], ['asc']);

export const isBatchBulksHasFastExposed = (batchBulks: BatchBulkPaymentIntent[]) =>
  batchBulks?.some(
    (batchBulkItem) =>
      batchBulkItem?.payment?.fundingSource?.isVerified &&
      batchBulkItem?.deliveryOptions?.length > 1
  );

export const safeBalanceAmount = (defaultAmount: number, incomingAmount: number) => {
  if (incomingAmount <= 0 || incomingAmount > defaultAmount) {
    return defaultAmount;
  }

  return incomingAmount;
};

export const mergePartialPaymentsWithBatchList = ({
  batchList,
  partialPayments,
}: {
  batchList: BatchBulkPaymentIntent[];
  partialPayments: Record<string, number>;
}) => {
  const partialPaymentsIds = Object.keys(partialPayments)
    .map(Number)
    .filter((paymentId) => partialPayments[paymentId] !== 0);

  if (!partialPaymentsIds.length) return batchList;

  for (const batchItem of batchList) {
    const amount =
      batchItem.payment.bills
        ?.map((bill) =>
          !partialPaymentsIds.includes(+bill.id)
            ? bill.balance || 0
            : safeBalanceAmount(bill.balance || 0, partialPayments[bill.id])
        )
        .reduce((sum, cur) => sum + cur) || 0;

    batchItem.payment.amount = amount;

    const bills = batchItem.payment.bills?.map((bill: Bill) => {
      if (partialPaymentsIds.includes(+bill.id)) {
        return {
          ...bill,
          balance: safeBalanceAmount(bill.balance || 0, partialPayments[bill.id]),
        } as Bill;
      }

      return bill;
    });

    batchItem.payment.bills = bills;
  }

  return batchList;
};

interface BuildPaymentIntentDTOsParams {
  fundingSources: FundingSource[];
  batchList: BatchBulkPaymentIntent[];
}

export const buildPaymentIntentDTOs = ({
  fundingSources,
  batchList,
}: BuildPaymentIntentDTOsParams): BatchBulkPaymentIntent[] =>
  batchList.map((paymentIntentDTO) =>
    buildPaymentIntentDTO({
      fundingSources,
      paymentIntentDTO,
    })
  );
interface BuildPaymentIntentDTOParams {
  fundingSources?: FundingSource[];
  paymentIntentDTO: BatchBulkPaymentIntent;
}

export const buildPaymentIntentDTO = ({
  fundingSources,
  paymentIntentDTO,
}: BuildPaymentIntentDTOParams) => {
  const orderedPaymentMethods = orderPaymentMethods(fundingSources);
  const defaultPaymentMethod = getDefaultPaymentMethod({
    fundingSources: orderedPaymentMethods,
    fundingSourceId: paymentIntentDTO.payment.fundingSourceId,
    deliveryMethod: paymentIntentDTO.payment.deliveryMethod,
  });

  const orderedDeliveryMethods = orderDeliveryMethods(
    paymentIntentDTO.payment.vendor.deliveryMethods.filter(
      (dm) => dm.deliveryType !== DeliveryEnum.CARD
    )
  );

  const defaultDeliveryMethod = getDefaultDeliveryMethod({
    deliveryMethod: paymentIntentDTO.payment.deliveryMethod,
    selectedPaymentMethod: defaultPaymentMethod,
    deliveryMethods: orderedDeliveryMethods,
  });

  return {
    ...paymentIntentDTO,
    payment: {
      ...paymentIntentDTO.payment,
      fundingSourceId: defaultPaymentMethod?.id,
      fundingSource: defaultPaymentMethod,
      deliveryMethodId: defaultDeliveryMethod?.id,
      deliveryMethod: defaultDeliveryMethod,
    },
  };
};

export const convertPaymentIntentIdToNumber = (paymentIntent: BatchBulkPaymentIntent) =>
  paymentIntent.id.split(',')?.[0]?.replace('bill-', '');

export const modifyTextSteps = (steps: StepParams[]): StepParams[] => {
  const shouldUncombineBulk = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.BATCH_UNCOMBINED,
    false
  );

  const modifiedSteps = steps;

  if (shouldUncombineBulk) {
    // modify step #4 - only with FF
    modifiedSteps[3] = {
      ...steps[3],
      bodyLabel: 'batchBulkPage.helpGuide.steps.4.testBody',
    };
  }

  return modifiedSteps;
};

const getPaymentIntentsWithInternationalDeliveryMethod = (
  paymentIntents: BatchBulkPaymentIntent[]
) =>
  paymentIntents.filter(
    ({ payment }) => payment.deliveryMethod?.deliveryType === DeliveryEnum.INTERNATIONAL
  );

const getBillsWithFiles = (bills: Bill[]) => bills.filter((bill) => !!bill.files.length);

const mapPaymentIntentsIdsToFiles = (paymentIntents: BatchBulkPaymentIntent[]) =>
  paymentIntents.reduce((map, paymentIntent) => {
    const billsWithFiles = getBillsWithFiles(paymentIntent.payment.bills || []);

    if (billsWithFiles.length) {
      map[paymentIntent.id] = billsWithFiles.map(({ files }) => files).flat();
    }

    return map;
  }, {} as Record<string, UploadedFileInfo[]>);

export const getUploadInvoiceFilesFromPaymentIntents = (
  paymentIntents: BatchBulkPaymentIntent[]
): UploadInvoiceFile[] => {
  const internationalPaymentIntents =
    getPaymentIntentsWithInternationalDeliveryMethod(paymentIntents);

  const internationalPaymentIntentIdToFilesMap = mapPaymentIntentsIdsToFiles(
    internationalPaymentIntents
  );

  const result: UploadInvoiceFile[] = [];

  for (const paymentIntentId in internationalPaymentIntentIdToFilesMap) {
    internationalPaymentIntentIdToFilesMap[paymentIntentId].forEach((invoiceFile) => {
      result.push({
        paymentIntentId,
        invoiceFile,
      });
    });
  }

  return result;
};
