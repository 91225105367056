import { generatePath } from 'react-router-dom';
import analytics from 'src/app/services/analytics';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { addCardAccountDeliveryMethodWithTokenApi } from 'src/app/version-2/pages/add-card-account/api/deliveryMethod/addCardAccountDeliveryMethodWithToken.api';
import { CreateCardAccountResponse } from 'src/app/version-2/pages/add-card-account/api/fundingSource/responses/CreateCardAccountResponse';
import { ValidateCardResponse } from 'src/app/version-2/pages/add-card-account/api/fundingSource/responses/ValidateCardResponse';
import {
  ADD_CARD_ACCOUNT_EVENT_PAGE,
  IFRAME_ERROR_RESPONSE,
  IFRAME_RESPONSE_SUCCESS,
  LINK_CREDIT_CARD,
  PAYMENT_METHOD_CREDIT_CARD,
} from 'src/app/version-2/pages/add-card-account/model/consts/AddCardAccountAnalytics.consts';
import { addCardAccountActions } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';
import { addCardAccountDeliveryMethodActions } from 'src/app/version-2/pages/add-card-account/modules/deliveryMethod/addCardAccountDeliveryMethod.slice';
import { getJWTPayload } from 'src/app/version-2/utils/jwtToken.utils';

import { call, put, takeEvery } from 'redux-saga/effects';

function* validateCardHandler(
  action: ReturnType<typeof addCardAccountDeliveryMethodActions.validateCard>
) {
  const { token, cardBin, cardToken, digits, expiration, location, history } = action.payload;

  const { orgId } = getJWTPayload(token);

  try {
    const { message, code }: ValidateCardResponse = yield call<
      typeof addCardAccountDeliveryMethodWithTokenApi.validateCardAccountWithToken
    >(addCardAccountDeliveryMethodWithTokenApi.validateCardAccountWithToken, {
      token,
      cardToken,
      cardBin,
    });

    yield put(
      addCardAccountDeliveryMethodActions.setValidateCardStatus({
        message,
        code,
      })
    );

    analytics.track(ADD_CARD_ACCOUNT_EVENT_PAGE, IFRAME_RESPONSE_SUCCESS);
    analytics.track(PAYMENT_METHOD_CREDIT_CARD, LINK_CREDIT_CARD, {
      vaulting: ADD_CARD_ACCOUNT_EVENT_PAGE,
    });

    yield put(addCardAccountActions.isRequesting(false));

    history.push(generatePath(location.state.redirectUrl, { token }), {
      card: {
        cardToken,
        cardBin,
        digits,
        expiration,
      },
    });

    loggingApi.info('addCardAccountDeliveryMethodSaga.validateCardHandler(): passed', { orgId });
  } catch (error) {
    analytics.track(ADD_CARD_ACCOUNT_EVENT_PAGE, IFRAME_ERROR_RESPONSE);
    loggingApi.error('addCardAccountDeliveryMethodSaga.validateCardHandler(): error', {
      error,
      errorType: IFRAME_ERROR_RESPONSE,
      errorInfo: `${ADD_CARD_ACCOUNT_EVENT_PAGE} ${IFRAME_ERROR_RESPONSE}`,
    });

    yield put(
      addCardAccountDeliveryMethodActions.setValidateCardStatus({
        error: {
          message: (error as ValidateCardResponse).message,
          code: (error as ValidateCardResponse).code,
        },
      })
    );

    yield put(addCardAccountActions.isRequesting(false));

    loggingApi.error('addCardAccountDeliveryMethodSaga.validateCardHandler(): failed', {
      orgId,
      error,
    });
  }
}

function* createCardAccountHandler(
  action: ReturnType<typeof addCardAccountDeliveryMethodActions.createCardAccount>
) {
  try {
    yield put(addCardAccountActions.isRequesting(true));

    const response = yield call<
      typeof addCardAccountDeliveryMethodWithTokenApi.createCardAccountWithToken
    >(addCardAccountDeliveryMethodWithTokenApi.createCardAccountWithToken, action.payload);

    yield put(addCardAccountDeliveryMethodActions.setCreateCardAccountStatus(response));
    yield put(addCardAccountActions.isRequesting(false));

    loggingApi.info('addCardAccountDeliveryMethodSaga.createCardAccountHandler(): passed', {
      response,
    });
  } catch (error) {
    yield put(
      addCardAccountDeliveryMethodActions.setCreateCardAccountStatus({
        error: error as CreateCardAccountResponse['error'],
      })
    );

    yield put(addCardAccountActions.isRequesting(false));

    loggingApi.error('addCardAccountDeliveryMethodSaga.createCardAccountHandler(): failed', error);
  }
}

export function* watchAddCardAccountDeliveryMethod() {
  yield takeEvery(addCardAccountDeliveryMethodActions.validateCard, validateCardHandler);
  yield takeEvery(addCardAccountDeliveryMethodActions.createCardAccount, createCardAccountHandler);
}
