import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Box, SmartIcon } from '@melio/billpay-design-system';
import { analytics, QBOLayoutPage } from 'src/app/version-2/externals';
import { ReactComponent as QBLogo } from 'src/app/version-2/images/qb-logo.svg';
import {
  APPLICATION_RECEIVED_EVENT_NAME,
  GLOBAL_OPT_IN_PAGE,
  SUCCESS_PAGE_EVENT_NAME,
} from 'src/app/version-2/pages/global-opt-in-details/model/consts/GlobalOptInAnalytics.consts';
import {
  cardStyle,
  logoStyle,
  successSubtitleStyle,
  successTitleStyle,
} from './SuccessPage.styles';
import { globalOptInSelectors } from '../../modules/globalOptIn.slice';

export const Success = () => {
  const { formatMessage } = useIntl();
  const userEmail = useSelector(globalOptInSelectors.selectEmail);

  useEffect(() => {
    analytics.page(GLOBAL_OPT_IN_PAGE, SUCCESS_PAGE_EVENT_NAME);
    analytics.track(GLOBAL_OPT_IN_PAGE, APPLICATION_RECEIVED_EVENT_NAME, {
      retry_to_submit: true,
    });

    window.history.pushState(null, '', window.location.href);
    const popStateListener = () => {
      window.history.pushState(null, '', window.location.href);
    };

    window.addEventListener('popstate', popStateListener);

    return () => {
      window.removeEventListener('popstate', popStateListener);
    };
  }, []);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success-top" innerSize={53}>
      <Box __css={logoStyle}>
        <QBLogo />
      </Box>
      <Box __css={cardStyle}>
        <SmartIcon type="success-outline" size="4.8rem" />
        <Box as="h1" __css={successTitleStyle}>
          {formatMessage({ id: 'vendors.globalOptInCandidates.success.title' })}
        </Box>
        <Box as="p" __css={successSubtitleStyle}>
          {formatMessage(
            { id: 'vendors.globalOptInCandidates.success.subTitle' },
            { email: userEmail || ' ' }
          )}
        </Box>
      </Box>
    </QBOLayoutPage>
  );
};
