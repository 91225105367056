import React from 'react';
import { useIntl } from 'react-intl';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';
import {
  PaymentType,
  DeliveryMethodType,
  OrganizationType,
  VirtualCardType,
} from 'src/app/utils/types';
import { InfoLayout, ReviewGridItem } from '../components';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  deliveryMethod?: DeliveryMethodType;
  payment?: PaymentType;
  organization?: OrganizationType;
  virtualCard?: VirtualCardType;
};

const RecoverySuccessLayout = ({ deliveryMethod, payment, organization, virtualCard }: Props) => {
  useStateChangedAnalytics({
    payment,
    paymentState: VirtualCardPaymentState.RecoverySuccess,
    extraProperties: { virtualCardId: virtualCard?.id },
  });
  const intl = useIntl();

  const formattedAmount =
    payment?.amount &&
    intl.formatNumber(payment.amount, {
      style: 'currency',
      currency: 'USD',
    });

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <InfoLayout.Icon type={InfoLayout.IconType.Check} />
      <InfoLayout.Title>
        <MIFormattedText label="vendors.virtualCardDetails.recoverySuccess.title" />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label="vendors.virtualCardDetails.recoverySuccess.subtitle"
          values={{ amount: formattedAmount, name: organization?.companyName }}
        />
      </InfoLayout.Subtitle>
      <InfoLayout.Separator />
      <InfoLayout.SectionTitle>
        <MIFormattedText label="vendors.virtualCardDetails.recoverySuccess.reviewSectionTitle" />
      </InfoLayout.SectionTitle>
      <InfoLayout.Grid>
        <ReviewGridItem
          image={<Mastercard width="3.2rem" height="3.2rem" />}
          label={<MIFormattedText label="vendors.virtualCardDetails.common.cardTo" />}
          value={deliveryMethod?.virtualCardAccount?.accountEmail}
        />
        <ReviewGridItem
          icon="icon-eta-cal"
          label={<MIFormattedText label="vendors.virtualCardDetails.common.deliveryOn" />}
          value={<MIFormattedDate date={payment?.deliveryEta} />}
        />
      </InfoLayout.Grid>
    </InfoLayout>
  );
};

export { RecoverySuccessLayout };
