import React from 'react';
import { Footnote, PageContainer, Subtitle, Title } from './ExperimentLandingPageContainer.styles';
import { ExperimentLandingPageFooter } from '../ExperimentLandingPageFooter';

interface ExperimentLandingPageContainerProps {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  header?: React.ReactNode;
  progressBar?: React.ReactNode;
  footnote?: string | React.ReactNode;
  children: React.ReactNode;
}

export const ExperimentLandingPageContainer = ({
  title,
  subtitle,
  footnote,
  header,
  progressBar,
  children,
  ...rest
}: ExperimentLandingPageContainerProps) => (
  <PageContainer {...rest}>
    {header}
    {progressBar}
    <Title textStyle="ds.h5">{title}</Title>
    <Subtitle textStyle="ds.body2">{subtitle}</Subtitle>

    {children}

    {footnote && <Footnote>{footnote}</Footnote>}
    <ExperimentLandingPageFooter />
  </PageContainer>
);
