import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { NotificationCard, NotificationTypeEnum } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { MIFormattedCurrency } from 'src/app/version-2/externals';
import { CheckFeeEnum, FeatureFlagsEnum, FeeVariantsEnum } from 'src/app/version-2/model/enums';
import { organizationSelectors } from 'src/app/version-2/modules/organization/organization.slice';
import {
  isCheckFeeOpen,
  isCheckFeeOpenNoPromotions,
} from 'src/app/version-2/utils/checkFees.utils';
import { getFeeFromCatalog } from 'src/app/version-2/utils/fee.utils';

/**
 * Shows notification after applied fee in confirm page
 */

const VirtualCheckFeeNotification = () => {
  const { formatMessage } = useIntl();
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlagsEnum.CHECK_FEES, CheckFeeEnum.CLOSED, {
    shouldTrack: false,
  });
  const catalog = useSelector(organizationSelectors.selectFeeCatalog);
  const catalogFee = getFeeFromCatalog(catalog, FeeVariantsEnum.ACH_TO_CHECK);
  const checkFeeEnabled = isCheckFeeOpen(checkFeeFlag);
  const checkFeeEnabledWithNoPromotions = isCheckFeeOpenNoPromotions(checkFeeFlag);

  if (!checkFeeEnabled || !catalogFee) return null;

  const notificationText = checkFeeEnabledWithNoPromotions
    ? 'deliveryMethods.notDefinedInfo.virtual.checkFee.notificationNoPromotions'
    : 'deliveryMethods.notDefinedInfo.virtual.checkFee.notification';

  const subtitle = formatMessage(
    { id: notificationText },
    { fee: <MIFormattedCurrency value={catalogFee || 0} /> }
  );

  return <NotificationCard type={NotificationTypeEnum.INFO} subtitle={subtitle} />;
};

export { VirtualCheckFeeNotification };
