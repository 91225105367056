/* eslint-disable no-restricted-globals */
import get from 'lodash/get';
import nativeBackButtonHandler from 'src/app/modules/navigation/nativeBackButtonHandler';
import { DASHBOARD_CLOSE_ORIGIN } from 'src/app/pages/qb-dashboard/consts';
import { loggingApi } from 'src/app/version-2/api/loggers';

const postMessage = (data: Record<string, any>, origin?: string) => {
  if (window.opener) {
    window.opener.postMessage({ ...data, source: 'melio-popup' }, origin || '*');
  } else if (parent !== window && get(window, 'parent.postMessage')) {
    parent.postMessage({ ...data, source: 'melio-iframe' }, origin || '*');
  } else {
    loggingApi.error('external-events.postMessage(): Could not post message', { ...data, origin });
  }
};
const melioLoaded = () => {
  postMessage({
    instanceId: 1,
    type: 'melio-loaded',
  });
};
const melioClose = () => {
  postMessage({
    instanceId: 1,
    type: 'melio-close',
  });
};
const paymentSuccess = (originId?: string) => {
  postMessage({
    instanceId: 1,
    type: 'payment-success',
    originId,
  });
};

const newPayment = () => {
  postMessage({
    instanceId: 1,
    type: 'new-payment',
  });
};
const paymentDeleted = () => {
  postMessage({
    instanceId: 1,
    type: 'payment-deleted',
  });
};
const melioError = () => {
  postMessage({
    instanceId: 1,
    type: 'melio-error',
  });
};
const blockedUser = () => {
  postMessage({
    instanceId: 1,
    type: 'blocked-user',
  });
};

const redirectToPayBills = () => {
  closeDashboard(DASHBOARD_CLOSE_ORIGIN.SWITCHER);
};

const toggleDashboardSwitcher = (value: boolean) => {
  postMessage({
    type: 'melio-dashboard-toggle',
    value,
  });
};

const closeDashboard = (origin: DASHBOARD_CLOSE_ORIGIN) => {
  // melio-dashboard-close-switcher is closing iframe and redirecting to `app.qbo.intuit.com/app/paybills`
  // melio-dashboard-close-x is closing iframe and redirecting to `app.qbo.intuit.com/app/homepage`
  postMessage({
    type:
      origin === DASHBOARD_CLOSE_ORIGIN.SWITCHER
        ? 'melio-dashboard-close-switcher'
        : 'melio-dashboard-close-x',
  });
};

const billNotFound = () => {
  postMessage({
    instanceId: 1,
    type: 'bill-not-found',
  });
};

const qboRefuseConnect = () => {
  postMessage({
    instanceId: 1,
    type: 'refuse-connect',
  });
};
const logOut = () => {
  postMessage({
    instanceId: 1,
    type: 'logout',
  });
};

const isFirstQBOPage = () => {
  const currentState = window.history.state;

  return currentState?.state?.name === 'initialState';
};

const redirectToContractors = () => {
  postMessage({
    instanceId: 1,
    type: 'melio-redirect-to-contractors',
  });
};
const handleParentMessage = (event: any) => {
  if (event?.data?.type === 'qbm-back') {
    if (nativeBackButtonHandler.hasListeners()) {
      return nativeBackButtonHandler.handle();
    }

    if (!isFirstQBOPage()) {
      return window.history.back();
    }

    // eslint-disable-next-line no-restricted-globals
    return parent.postMessage(
      {
        instanceId: 1,
        type: 'melio-close',
        source: 'melio-iframe',
      },
      '*'
    );
  }

  return null;
};

export {
  melioLoaded,
  melioClose,
  paymentSuccess,
  paymentDeleted,
  melioError,
  blockedUser,
  billNotFound,
  qboRefuseConnect,
  logOut,
  redirectToContractors,
  handleParentMessage,
  isFirstQBOPage,
  newPayment,
  toggleDashboardSwitcher,
  closeDashboard,
  redirectToPayBills,
};
