import React, { useState } from 'react';
import { RecordOf } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import { VStack } from '@melio/billpay-design-system';

import { getDeliveryMethodByType } from 'src/app/pages/vendor/records';
import { DeliveryMethodType, VendorType } from 'src/app/utils/types';
import { MICardSection, MICardTitle } from 'src/app/components/common/MICard';
import { CONSTS, DELIVERY_TYPE } from 'src/app/utils/consts';
import { useIsInternationalOn } from 'src/app/pages/vendor/delivery-methods/international/hooks/useIsInternationalOn';
import { VirtualCardInfoModal } from 'src/app/pages/bill/components/VirtualCardInfoModal';
import { useVirtualCardInfoModal } from 'src/app/pages/bill/pay/hooks/useVirtualCardInfoModal';
import {
  organizationSelectors,
  organizationActions,
} from 'src/app/version-2/modules/organization/organization.slice';
import analytics from 'src/app/services/analytics';
import { getNotice } from 'src/app/version-2/utils/checkFees.utils';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { OrganizationPreferencesType } from 'src/app/utils/types';
import { VirtualCardDeliveryMethodItem } from './VirtualCardDeliveryMethodItem';
import { AchCheckDeliveryMethodItem } from './AchCheckDeliveryMethodItem';
import { InternationalDeliveryMethodItem } from './InternationalDeliveryMethodItem';
import { isSharedVendor } from '../../utils';

type Props = {
  vendor: RecordOf<VendorType> | VendorType;
  goEditDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  className?: string;
};

const QBOVendorDeliveryMethodsList = ({ vendor, goEditDeliveryMethod, className }: Props) => {
  const isInternationalEnabled = useIsInternationalOn();
  const dispatch = useDispatch();

  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const catalog = useSelector(organizationSelectors.selectFeeCatalog);

  const [shouldShowVirtualCardInfoModal, setShouldShowVirtualCardInfoModal] =
    useState<boolean>(false);
  const { onToggleVirtualCardInfoModal } = useVirtualCardInfoModal({
    onToggle: () => setShouldShowVirtualCardInfoModal(!shouldShowVirtualCardInfoModal),
  });

  const [bpVirtualCardOptInSingleBillPayFlow] = featureFlags.useFeature(
    'bp-virtual-card-opt-in-single-bill-pay-flow'
  );

  const ach = getDeliveryMethodByType(DELIVERY_TYPE.ACH, vendor.deliveryMethods);
  const check = getDeliveryMethodByType(DELIVERY_TYPE.CHECK, vendor.deliveryMethods);
  const international = getDeliveryMethodByType(
    DELIVERY_TYPE.INTERNATIONAL,
    vendor.deliveryMethods
  );

  const virtualCardDeliveryMethod = vendor.deliveryMethods.find(
    (dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD
  );
  const internationalCardDeliveryMethod = vendor.deliveryMethods.find(
    (dm) => dm.deliveryType === DELIVERY_TYPE.INTERNATIONAL
  );

  const deliveryMethods = vendor.deliveryMethods?.length;
  const isDomestic = deliveryMethods && !internationalCardDeliveryMethod;
  const isInternational = deliveryMethods && !!internationalCardDeliveryMethod;

  const AchMethod = () => (
    <AchCheckDeliveryMethodItem
      deliveryMethod={ach}
      vendor={vendor}
      goEditDeliveryMethod={goEditDeliveryMethod}
    />
  );
  const onModalOpen = () => dispatch(organizationActions.setIsCheckFeeModalOpen(true));
  const CheckMethod = () => {
    const { description, descriptionValues } = getNotice({
      catalog,
      onModalOpen,
    });

    return (
      <AchCheckDeliveryMethodItem
        deliveryMethod={check}
        vendor={vendor}
        goEditDeliveryMethod={goEditDeliveryMethod}
        customDescription={description}
        descriptionValues={descriptionValues}
      />
    );
  };
  const InternationalMethod = () => (
    <InternationalDeliveryMethodItem
      deliveryMethod={international}
      vendor={vendor}
      goEditDeliveryMethod={goEditDeliveryMethod}
      isDisabled={!organizationPreferences.isEligibleForInternationalPayment}
    />
  );

  const renderVirtualCardInfoModal = () => (
    <VirtualCardInfoModal
      isVisible={shouldShowVirtualCardInfoModal}
      onToggle={onToggleVirtualCardInfoModal}
      onSubmit={() => {
        analytics.track('pay-bill', 'delivery-method-add', {
          type: CONSTS.DELIVERY_TYPE.VIRTUAL_CARD,
          vendorId: vendor.id,
          triggerFlow: 'VirtualCardInfoModal-SettingsPanel',
        });

        goEditDeliveryMethod({
          ...(virtualCardDeliveryMethod as any),
          deliveryType: CONSTS.DELIVERY_TYPE.VIRTUAL_CARD,
        });
      }}
      customLabels={{
        primaryCTA:
          'deliveryMethods.notDefinedInfo.virtual-card.infoModal.settingsPanel.primaryCTALabel',
      }}
    />
  );

  const renderDeliveryMethods = () => {
    if (isSharedVendor(vendor)) {
      return <AchMethod />;
    }

    if (isDomestic && bpVirtualCardOptInSingleBillPayFlow) {
      return (
        <>
          <VirtualCardDeliveryMethodItem
            deliveryMethod={virtualCardDeliveryMethod}
            vendor={vendor}
            goEditDeliveryMethod={goEditDeliveryMethod}
            onToggleVirtualCardInfoModal={onToggleVirtualCardInfoModal}
          />
          <AchMethod />
          <CheckMethod />
          {renderVirtualCardInfoModal()}
        </>
      );
    }

    if (isInternational && isInternationalEnabled) {
      return <InternationalMethod />;
    }

    return (
      <>
        {bpVirtualCardOptInSingleBillPayFlow && (
          <VirtualCardDeliveryMethodItem
            deliveryMethod={virtualCardDeliveryMethod}
            vendor={vendor}
            goEditDeliveryMethod={goEditDeliveryMethod}
            onToggleVirtualCardInfoModal={onToggleVirtualCardInfoModal}
          />
        )}
        <AchMethod />
        <CheckMethod />
        {isInternationalEnabled && <InternationalMethod />}
        {renderVirtualCardInfoModal()}
      </>
    );
  };

  return (
    <MICardSection className={className}>
      <MICardTitle label="vendors.deliveryMethods.title" />
      <VStack mt="5" spacing="3">
        {renderDeliveryMethods()}
      </VStack>
    </MICardSection>
  );
};

export default QBOVendorDeliveryMethodsList;
