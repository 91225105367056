import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import some from 'lodash/some';
import analytics from 'src/app/services/analytics';
import { DeliveryMethodType, OptionalDeliveryMethodsType, VendorType } from 'src/app/utils/types';
import { CONSTS, DELIVERY_METHOD_ORIGIN } from 'src/app/utils/consts';

import JustPayLayoutPage from 'src/app/pages/just-pay/JustPayLayoutPage';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import { getDeliveryMethodByType } from 'src/app/pages/vendor/records';
import { QBODeliveryMethodListItem } from 'src/app/pages/bill/pay/components/QBODeliveryMethodListItem';
import { getNotice } from 'src/app/version-2/utils/checkFees.utils';
import {
  organizationSelectors,
  organizationActions,
} from 'src/app/version-2/modules/organization/organization.slice';
import { NotificationDM as CheckFeeNotification } from 'src/app/version-2/components/CheckFee/NotificationDM';

import { generatePath } from 'react-router-dom';
import utilsLocations from 'src/app/utils/locations';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import {
  WizardOrLine,
  WizardStepActionsContainer,
} from 'src/app/components/layout/QBOWizardElements';
import { getJustPayHeaderLabelValues } from 'src/app/pages/just-pay/justPayJSXUtils';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import {
  JustPayLayoutPageRelativeSteps,
  JustPayPaymentType,
} from 'src/app/pages/just-pay/justPayTypes';
import { useFullOrgSync } from 'src/app/pages/hooks/useFullOrgSync';
import { FundingSource } from 'src/app/version-2/model/dtos';
import justPayLocations from '../justPayLocations';

type Props = {
  onNext: () => void;
  onExit: () => void;
  onPrev: () => void;
};

export const JustPaySelectDeliveryMethod = ({ onNext, onExit, onPrev }: Props) => {
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();
  const {
    selectedVendor,
    selectedFundingSource,
    paymentStoreActions,
    payment: { deliveryMethodType, orgId, amount, trackingBillId },
  } = useJustPayStore() as {
    selectedVendor: VendorType;
    selectedFundingSource: FundingSource;
    paymentStoreActions: any;
    payment: JustPayPaymentType;
  };
  const catalog = useSelector(organizationSelectors.selectFeeCatalog);
  const deliveryMethods: DeliveryMethodType[] = useSelector(
    deliveryMethodsStore.selectors.byVendorId(selectedVendor?.id)
  );

  // Reversing the array since the find in getDeliveryMethodByType returns the first found element and since
  // in edit mode we can have more than one of the same delivery methods type then in order to get the last one we reverse

  deliveryMethods.reverse();
  const ach = getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.ACH, deliveryMethods);
  const check = getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.CHECK, deliveryMethods);
  const deliveryMethodOptions = [ach, check];
  const vendorCompanyName = selectedVendor.printOnCheck ?? selectedVendor.companyName;

  const virtualDeliveryMethod = {
    deliveryType: CONSTS.DELIVERY_TYPE.VIRTUAL,
    id: CONSTS.DELIVERY_TYPE.VIRTUAL,
    logo: '',
    hasScheduledPayments: false,
    isFilledByVendor: false,
    getDisplayName: () => CONSTS.DELIVERY_TYPE.VIRTUAL,
    getDeliveryInfo: () => '',
    getDeliveryTypeDescription: () => '',
    intuitAccountId: null,
  };

  useFullOrgSync({ eventPage: 'just-pay' });

  const handleOnNext = () => {
    analytics.trackAction('set-delivery-method-continue', { trackingBillId });

    onNext();
  };

  const onModalOpen = () => dispatch(organizationActions.setIsCheckFeeModalOpen(true));
  const getValuesByType = (deliveryType) => {
    if (deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
      const { description, descriptionValues } = getNotice({
        fundingSource: selectedFundingSource,
        catalog,
        onModalOpen,
      });

      return {
        description,
        descriptionValues,
      };
    }

    return undefined;
  };

  const goAddDeliveryMethod = (type: OptionalDeliveryMethodsType) => {
    const deliveryMethodUrlMap = {
      [CONSTS.DELIVERY_TYPE.ACH]: generatePath(justPayLocations.edit.achDeliveryMethodPage, {
        orgId,
        vendorId: selectedVendor.id,
      }),
      [CONSTS.DELIVERY_TYPE.CHECK]: generatePath(justPayLocations.edit.checkDeliveryMethodPage, {
        orgId,
        vendorId: selectedVendor.id,
      }),
      [CONSTS.DELIVERY_TYPE.VIRTUAL]: utilsLocations.Vendors.deliveryMethods.virtual.create.url({
        orgId,
        id: selectedVendor.id,
      }),
    };
    const url = deliveryMethodUrlMap[type];

    historyPush({
      path: url,
      state: {
        origin: DELIVERY_METHOD_ORIGIN.JUST_PAY,
        redirectUrl: justPayLocations.operations.selectDeductionDate,
      },
    });
  };

  const goAddVirtualDeliveryMethod = () => {
    analytics.trackAction('delivery-method-add', {
      deliveryMethodType: CONSTS.DELIVERY_TYPE.VIRTUAL,
      trackingBillId,
    });

    goAddDeliveryMethod(CONSTS.DELIVERY_TYPE.VIRTUAL);
  };

  const onDeliveryMethodChange = (type: OptionalDeliveryMethodsType) => {
    const selectedDeliveryMethod = (deliveryMethods || []).find((dm) => dm.deliveryType === type);

    if (selectedDeliveryMethod) {
      analytics.trackAction('delivery-method-add', {
        deliveryMethodType: type,
        trackingBillId,
      });

      paymentStoreActions.justPay.justPayWizard.update({
        deliveryMethodId: selectedDeliveryMethod.id,
        deliveryMethodType: selectedDeliveryMethod.deliveryType,
      });
    } else {
      analytics.trackAction('delivery-method-add', {
        deliveryMethodType: type,
        trackingBillId,
      });

      goAddDeliveryMethod(type);
    }
  };

  const showVirtualDeliveryMethodOption =
    selectedFundingSource.isVerified ||
    (!selectedFundingSource.isVerified &&
      some(deliveryMethods, (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL));

  return (
    <StyledJustPayLayoutPage
      headerLabel="justPay.defaultHeader"
      headerLabelValues={getJustPayHeaderLabelValues(amount, vendorCompanyName)}
      title="justPay.deliveryMethods.title"
      titleValues={{ vendor: vendorCompanyName }}
      onNext={handleOnNext}
      goExit={onExit}
      onPrev={onPrev}
      isHideActions={!deliveryMethodType}
      relativeStep={JustPayLayoutPageRelativeSteps.JustPaySelectDeliveryMethod}
      isQboFooter
    >
      <CheckFeeNotification
        selectedFundingSource={selectedFundingSource as unknown as FundingSource}
      />
      <DeliveryMethodsList>
        {deliveryMethodOptions.map((option) => (
          <QBODeliveryMethodListItem
            key={option.deliveryType}
            deliveryMethod={option}
            companyName={vendorCompanyName}
            isSelected={deliveryMethodType === option.deliveryType}
            onItemClick={() => onDeliveryMethodChange(option.deliveryType)}
            deliveryMethodOrigin={DELIVERY_METHOD_ORIGIN.JUST_PAY}
            vendorId={selectedVendor?.id as unknown as string}
            customDescription={getValuesByType(option.deliveryType)?.description}
            descriptionValues={getValuesByType(option.deliveryType)?.descriptionValues}
          />
        ))}
        {showVirtualDeliveryMethodOption && (
          <VirtualDeliveryMethodContainer>
            <WizardOrLine />
            <QBODeliveryMethodListItem
              key={CONSTS.DELIVERY_TYPE.VIRTUAL}
              deliveryMethod={virtualDeliveryMethod}
              companyName={vendorCompanyName}
              isSelected={false}
              onItemClick={goAddVirtualDeliveryMethod}
              showDisplayName={false}
            />
          </VirtualDeliveryMethodContainer>
        )}
      </DeliveryMethodsList>
    </StyledJustPayLayoutPage>
  );
};

const DeliveryMethodsList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4rem;
`;

const VirtualDeliveryMethodContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`;

const StyledJustPayLayoutPage = styled(JustPayLayoutPage)`
  ${WizardStepActionsContainer} {
    margin-top: 0;
  }
`;
