export const enum PaymentMethodsSmartIconsTypesEnum {
  VISA = 'visaCard',
  MASTERCARD = 'mastercard',
  AMEX = 'amex',
  DINERS = 'diners',
  BANK = 'bank',
  CREDIT_CARD = 'credit-card',
  DEBIT_CARD = 'debit-card',
  BANK_OF_AMERICA = 'bank-of-america',
  CHASE = 'chase-bank',
  CITI_BANK = 'citi-bank',
  US_BANK = 'us-bank',
  WELLS_FARGO = 'wells-fargo-bank',
  SILICON_VALLEY_BANK = 'silicon-valley-bank',
  QB_CASH = 'qb-cash',
  FIFTH_THIRD_BANK = 'fifth-third-bank',
}
