// can't transform to const enum cause it's used as a dictionary in the code
export enum CURRENCY_SIGN {
  USD = '$',
}

// can't transform to const enum cause it's used in Object.values fn
export enum COUNTRY {
  US = 'US',
  PR = 'PR',
  VI = 'VI',
  GU = 'GU',
  MP = 'MP',
}

export enum DELIVERY_TYPE {
  ACH = 'ach',
  CHECK = 'check',
  VIRTUAL = 'virtual',
  CARD = 'card',
  RPPS = 'rpps',
  INTERNATIONAL = 'international',
  VIRTUAL_CARD = 'virtual-card',
}

export const enum BILL_STATUS {
  UNPAID = 'unpaid',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  SCHEDULED = 'scheduled',
}

export const BILL_STATUSES = [BILL_STATUS.UNPAID, BILL_STATUS.SCHEDULED, BILL_STATUS.PAID];

export const enum PAYMENT_STATUS {
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
  PROCESSED = 'processed',
  IN_PROGRESS = 'in-progress',
  BLOCKED = 'blocked',
  FAILED = 'failed',
}

export const enum PAYMENT_APPROVAL_STATUS {
  SKIP = 'skip',
  PENDING = 'pending',
  DECLINED = 'declined',
  APPROVED = 'approved',
}

export const FORM_KEY_FIELDS = {
  AMOUNT: 'amount',
};

export const enum FUNDING_SOURCE_ORIGIN {
  PLAID = 'plaid',
  MICRO = 'micro',
  MANUAL = 'manual',
  TABAPAY = 'taba-pay',
  QBCASH = 'qbcash',
}

export const NEW_LANDING_PAGE_ACTIONS = {
  batchpay: true,
  pay: true,
};

export const DEFAULT_QB_CASH_BALANCE = null;

export const FUNDING_SOURCE_LIMIT = {
  ACH_UNVERIFIED: 2,
};

export enum CARD_NETWORK_TYPES {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMEX = 'americanexpress',
  DINERS = 'diners',
}

export const enum CARD_TYPES {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export const enum BILL_PAGE_TYPE {
  EDIT = 'edit',
  CREATE = 'create',
  VIEW_REQUEST = 'view_request',
}

export const enum DIALOG_TYPE {
  ALERT = 'ALERT',
  CONFIRM = 'CONFIRM',
}

export const enum DIALOG_VARIANTS {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export const enum BUTTONS_DIRECTIONS {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export const enum BUTTONS_ROW_POSITION {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

export const enum IMAGE_POSITION {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
}

export const enum COMPANY_FORM_PAGE {
  SETTINGS = 'settings',
  ONBOARDING = 'onboarding',
}

export const PAGINATION = {
  DEFAULT_VISIBLE_PAGES_COUNT: 5,
  DEFAULT_LIMIT: 20,
  DEFAULT_START: 0,
};

export const enum SERVER_RESPONSE_CODES {
  OK = 'OK',
}

export const enum ADD_FUNDING_SOURCE_WIZARD_ORIGIN {
  PAY_BILL = 'funding-source-pay-bill',
  GUEST_ONBOARDING = 'funding-source-guest-onboarding',
  SETTINGS = 'funding-source-settings',
  JUST_PAY = 'funding-source-just-pay',
  NEW_LANDING_PAGE = 'new-experiment-landing-page',
}

export const enum LOCAL_STORAGE_KEYS {
  INTUIT_RETURN_URL = 'intuitReturnUrl',
  QUICKBOOKS_CHOOSE_REALM_ID = 'quickbooksReturnRelamId',
  TWO_FA_TOKENS = '2FATokens',
}

export const enum WIZARD_PROGRESS_BAR_TYPE {
  WITHOUT_SUBSTEPS = 'without-substeps',
  WITH_SUBSTEPS = 'with-substeps',
}

export const enum DELIVERY_METHOD_ORIGIN {
  ONBOARDING = 'delivery-method-onboarding',
  VENDOR_DETAILS = 'delivery-method-vendor-details',
  PAY_BILL = 'delivery-method-pay-bill',
  BATCH = 'delivery-method-batch',
  BATCH_VIRTUAL_PAY_BILLS = 'virtual-delivery-method-batch-pay',
  JUST_PAY = 'delivery-method-just-pay',
  RETURNED_CHECK = 'delivery-method-returned-check',
}

export const enum DELIVERY_METHOD_CHECK_FLOW {
  UNILATERAL = 'delivery-method-check-go-unilateral',
  CONFIRM = 'delivery-method-check-confirm-address',
}

export enum BUTTON_VARIANT {
  PRIMARY = 'primary',
  PAY = 'pay',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DESTRUCTIVE = 'destructive',
  CANCEL = 'cancel',
  REMOVE = 'remove',
  EMPTY = 'empty',
  EMPTY_FILLED_IN = 'emptyFilledIn',
  NAKED = 'naked',
}

export enum BUTTON_SIZE {
  SMALL = 'small',
  TINY = 'tiny',
  NORMAL = 'normal',
  VERY_SMALL = 'very-small',
}

export const enum BADGE_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  X_SMALL = 'x-small',
  X_LARGE = 'x-large',
}

export const DEFAULT_LOCATION = {
  lat: 40.77,
  lng: -74,
};

export const enum DELETE_PAYMENT_ACTION {
  SINGLE = 'single',
  RECURRING_CURRENT = 'current',
  RECURRING_ALL = 'all',
}

export enum VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES {
  NOT_FOUND = 'RSC01',
  ERR_VERIFY_MICRO_DEPOSITS = 'RSC11',
  CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS = 'RSC12',
  INVALID_TOKEN = 'RSC13',
}

export const enum INPUT_PATTERNS {
  NUMBERS = '[0-9]*',
}

export const enum NOTIFICATION_VARIANT {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export const enum ROLE {
  OWNER = 'owner',
  ADMIN = 'admin',
  CONTRIBUTOR = 'contributor',
  ACCOUNTANT = 'accountant',
  DELETED = 'deleted',
  ACCOUNTING_FIRM_ADMIN = 'accountingFirmAdmin',
  PENDING = 'pending',
}

export const enum TAG_VARIANT {
  BRAND = 'brand',
  LIGHT = 'light',
  DARK = 'dark',
  WARNING = 'warning',
  ERROR = 'error',
  DISABLED = 'disabled',
  EXPIRED = 'expired',
  SUCCESS = 'success',
  DEPOSITED = 'deposited',
  ACH_DEPOSITED = 'ach-deposited',
  PARTIALLY_PAID = 'partially-paid',
}

export const enum EVENT_MAPPING_NAME {
  DEFAULT = 'DEFAULT',
}

export const enum FEE_VARIANTS {
  ACH_TO_CHECK = 'ach-to-check',
}

export const EXPEDITED_DELIVERY_TYPES = ['expedited-ach', 'overnight-check'];

export const FAST_DELIVERY_TYPES = [...EXPEDITED_DELIVERY_TYPES, 'express-check'];

export const enum LINK_ACCOUNTING_SOFTWARE_ERROR {
  ACCESS_DENIED = 'access_denied',
  DIFF_REALMID = 'diff_realmid',
}

export const enum CONNECT_ERROR {
  ACCESS_DENIED = 'access_denied',
  ORG_NOT_EXIST = 'org_not_exist',
  USER_NOT_ACTIVE = 'user_not_active',
  USER_NOT_EXIST = 'user_not_exist',
  NO_USER_PERMISSION = 'no_user_permission',
}

export const enum QBR_BILL_LIST_TAB_TYPE {
  OPEN = 'open',
  COMPLETED = 'completed',
}

export const enum DELIVERY_DATE_FONT_TYPE {
  MEDIUM = 'medium',
  REGULAR = 'regular',
  HINT = 'hint',
  SMALL = 'small',
}

export const enum NOTIFICATION_CARD_TYPES {
  SUCCESS = 'success',
  SUCCESS_FILLED = 'success-filled',
  WARNING = 'warning',
  ERROR = 'error',
  ERROR_FILLED = 'error-filled',
  INFO = 'info',
  HELP = 'help',
  MAIN = 'main',
  DISCOVER = 'discover',
}

export const REGEX = {
  CHECK_PRINT_NAME: /[^a-zA-Z0-9,:;/&#@‘\-.() ]|  +/g,
  TAX_ID_NUMBER: /^[0-9]{9}$|^[0-9]{3}-[0-9]{2}-[0-9]{4}$|^[0-9]{2}-[0-9]{7}$/,
};

export const enum FAILED_PAYMENT_TYPE {
  FAILED_PAYMENT = 'failedPayment',
  FAILED_TO_COLLECT = 'failedToCollect',
  FAILED_TO_DELIVER = 'failedToDeliver',
}

export const enum FAILED_PAYMENT_MESSAGE_TYPES {
  RETURNED_CHECK = 'returnedCheck',
}

export const BANK_ACCOUNT_EXIST = 'DLM07';
export const FAILED_TO_DELIVER_ERR = 'FAILED_TO_DELIVER';
export const RECEIVER_REFUSED_CREDIT_ENTRY = 'receiverRefusedCreditEntry';
export const ERR_SHIFT_VIRTUAL_CARD_TO_ACH_AUTHORIZATION = 'DLM18';

export const enum ENTRYPOINT_SOURCE {
  EMAIL = 'email',
  MELIO_DASHBOARD = 'melio-dashboard',
}

export const enum TOGGLE_TYPE {
  COMPLETED = 'completed',
  OPEN = 'open',
}

export const enum PAY_EDIT_LOCATION_NAME {
  DATE = 'date',
  FUNDING = 'funding',
  DELIVERY_METHOD = 'deliveryMethod',
  DELIVERY_METHOD_ACH = 'deliveryMethodAch',
  CONFIRM = 'confirm',
  MEMO = 'memo',
  FAST_PAYMENT = 'fastPayment',
}

export const enum FLOW_ORIGIN {
  INTUIT_ENTRYPOINT = 'intuit-entrypoint',
  DASHBOARD = 'dashboard',
}

export const enum VENDOR_TYPES {
  NOT_DETERMINED = 0,
  DOMESTIC = 1,
  INTERNATIONAL = 2,
}

export const PURPOSE_OF_PAYMENT_STRUCTURE = {
  DESCRIPTION: 'description',
  TYPE: 'type',
};

export const PURPOSE_OF_PAYMENT_SEPARATOR = '::';

export const enum HOLIDAYS_WARNING_ICON_VARIANT {
  WARNING = 'warning',
  INFO = 'info',
}

export const FULL_STORY_MASK_RULE_CLASS = 'fs-mask';

export const IGNORE_OUTSIDE_CLICK_CLASS = 'ignore-outsideClick';

export const enum SYNC_BILL_ERROR_CODE {
  BILL_ALREADY_PAID = 'QBO07',
  BILL_AMOUNT_ZERO = 'QBO08',
  BILL_HAS_BEEN_DELETED = 'QBO09',
}

export const REDIRECTION_PERFORMED = 'redirection-performed';

export const NEW_VENDOR_ID = 'vendor';

export const BILL_MAX_AMOUNT = 1_000_000;
export const BILL_MIN_AMOUNT = 0;

export const ROLE_TAGS = {
  OWNER: 'owner',
  ADMIN: 'admin',
  CONTRIBUTOR: 'contributor',
  ACCOUNTANT: 'accountant',
  DELETED: 'deleted',
  ACCOUNTING_FIRM_OWNER: 'accountingFirmOwner',
  ACCOUNTING_FIRM_ADMIN: 'accountingFirmAdmin',
  PENDING: 'pending',
} as const;

export const enum CATEGORY_LIST_VIEW_VARIANTS {
  COLUMN = 'column',
}

export const enum TOKEN_ACTIONS {
  shiftVirtualCardToACH = 'shiftVirtualCardToACH',
  shiftVirtualCardToACHorCheck = 'shiftVirtualCardToACHorCheck',
}

export const enum NEXT_BILL {
  WITH_DETAILS = 'withDetails',
  NO_BILL = 'noBill',
}

export const ISSUER_ADDRESS = '205 Hudson St., New York, NY, USA';
export const ISSUER_ZIP_CODE = '10013';

export const CONSTS = {
  DELIVERY_TYPE,
  BUTTON_VARIANT,
};
