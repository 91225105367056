import { FundingSource } from 'src/app/version-2/model/dtos';
import { DeliveryMethodEligibilityStatusEnum } from 'src/app/version-2/model/enums';
import {
  DeliveryMethodItem,
  FundingSourceItem,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import { isBankAccountBlocked } from 'src/app/version-2/utils/paymentMethods.utils';
import { getDeliveryMethodEligibilityStatus } from '../utils/deliveryMethod.utils';

interface PaymentMethodAdapterParams {
  paymentMethod: FundingSource;
  selectedDeliveryMethod?: DeliveryMethodItem;
}

export const paymentMethodAdapter = ({
  paymentMethod,
  selectedDeliveryMethod,
}: PaymentMethodAdapterParams): FundingSourceItem => {
  const deliveryMethodEligibilityStatus = getDeliveryMethodEligibilityStatus({
    deliveryMethod: selectedDeliveryMethod,
    fundingSource: paymentMethod,
  });

  return {
    ...paymentMethod,
    meta: {
      isDisabled:
        deliveryMethodEligibilityStatus !== DeliveryMethodEligibilityStatusEnum.Eligible ||
        !!isBankAccountBlocked(paymentMethod),
    },
  };
};
