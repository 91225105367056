const LOCATIONS = {
  base: [
    '/vendors/debit-card-details/:token',
    '/vendors/add-debit-card/:token',
    '/vendors/add-debit-card/:token/invalid-link',
  ],
  index: '/vendors/debit-card-details/:token',
  addDebitMethod: '/vendors/add-debit-card/:token/debit-method',
  invalidToken: '/vendors/add-debit-card/:token/invalid-link',
  success: '/vendors/add-debit-card/:token/success',
  addAddress: '/vendors/add-debit-card/:token/address',
  checkDeposited: '/vendors/add-debit-card/:token/check-deposited',
  checkOfferExpired: '/vendors/add-debit-card/:token/check-offer-expired',
  paymentProcessed: '/vendors/add-debit-card/:token/payment-processed',
  paymentFailed: '/vendors/add-debit-card/:token/payment-failed',
};

export default LOCATIONS;
