import React from 'react';
import { Switch as ChakraSwitch, SwitchProps as ChakraSwitchProps } from '@chakra-ui/react';

type SwitchProps = Omit<ChakraSwitchProps, 'colorScheme' | 'variant' | 'size'> & {
  size?: 'lg' | 'md';
};

const Switch = ({ size = 'md', ...rest }: SwitchProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = rest;

    onChange?.(event);
  };

  return <ChakraSwitch size={size} {...rest} onChange={changeHandler} />;
};

export { Switch };
