import React, { useState, useMemo, MouseEvent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useLocationState } from 'src/app/utils/hooks';
import {
  ModalWrapper as StyledModalWrapper,
  Backdrop,
} from 'src/app/components/common/ModalMessage';
import { ConfirmationOrigin } from 'src/app/utils/types';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { pushNotification } from 'src/app/services/notifications';
import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { billListModifiedType } from 'src/app/pages/bill/pay/types';
import vendorsApi from 'src/app/services/api/vendors';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import { ModalContent } from './steps/types';
import { MainContent } from './steps/main-content';
import { NotifyVendors } from './steps/notify-vendor';

const ModalWrapper = styled(StyledModalWrapper)`
  .batches-modal-subtitle {
    font-size: 1.4rem !important;
  }
`;

interface Props {
  onDone(): void;
}

const fetchRelevantVendors = async (selectedBills, orgId) => {
  const vendors = await vendorsApi.getVendors({ orgId });
  const billedVendorsId = Object.values(selectedBills).map(
    (locationState) => (locationState as billListModifiedType).bill.vendor.id
  );

  const uniqueBilledVendorsId = uniq(billedVendorsId);
  const relevantVendors = uniqueBilledVendorsId
    .map((billId) => vendors.objects.filter((vendor) => vendor.id === billId))
    .flat();

  return relevantVendors;
};

export const BatchPaymentsSuccessModal: React.FC<Props> = (props) => {
  const [modalContent, setModalContent] = useState<ModalContent>(ModalContent.MAIN);
  const orgId = useSelector(getOrgId);
  const [batchPaymentIds] = useLocationState('batchPaymentIds');
  const [paidPaymentsMainData] = useLocationState('paidPaymentsMainData');
  const [confirmationOrigin] = useLocationState<ConfirmationOrigin>('confirmationOrigin');
  const isBatchPaymentFlow = !!batchPaymentIds?.length;
  const [isModalVisible, setModalVisibility] = useState<boolean>(
    isBatchPaymentFlow && confirmationOrigin === ConfirmationOrigin.BATCH_PAYMENT
  );
  const [selectedBills] = useLocationState('billListModified');
  const [scheduledPaymentsTextValues] = useLocationState('scheduledPaymentsTextValues');

  const [vendors, setVendors] = useState<any>([]);

  useEffect(() => {
    if (isEmpty(selectedBills)) return;

    fetchRelevantVendors(selectedBills, orgId).then((relevantVendors) =>
      setVendors(relevantVendors)
    );
  }, [selectedBills]);

  const navigateModalContentTo = (section: ModalContent) => {
    setModalContent(section);
  };

  const closeModal = async (_: MouseEvent | Event | null, cb?: () => Promise<void>) => {
    setModalVisibility(false);

    if (cb) {
      try {
        await cb();
        pushNotification({
          type: NOTIFICATION_VARIANT.SUCCESS,
          msg: 'paymentDashboard.notifyVendors.toastMessage.success',
        });
      } catch (error) {
        pushNotification({
          type: NOTIFICATION_VARIANT.ERROR,
          msg: 'paymentDashboard.notifyVendors.toastMessage.error',
        });
      }
    }

    props.onDone();
  };

  const isDataReady = useMemo(() => !isEmpty(vendors), [vendors]);
  const isBatchFlow = useMemo(() => !isEmpty(selectedBills), [selectedBills]);
  const isModalReady = useMemo(
    () => isModalVisible && isBatchFlow && isDataReady,
    [isModalVisible, isBatchFlow, isDataReady]
  );

  const vendorsWithoutEmail = useMemo(() => {
    if (!vendors.length) return [];

    return vendors.filter((vendor) => !vendor?.contactEmail);
  }, [vendors, isModalReady]);

  const hasMissingEmails = Boolean(vendorsWithoutEmail.length);

  return (
    <>
      {[
        ReactDOM.createPortal(
          isModalReady && (
            <ModalWrapper>
              <Backdrop onClick={closeModal} />
              {modalContent === ModalContent.MAIN && (
                <MainContent
                  {...{
                    closeModal,
                    isModalVisible,
                    navigateModalContentTo,
                    hasMissingEmails,
                    selectedBills,
                    vendorsWithoutEmail,
                    scheduledPaymentsTextValues,
                  }}
                  billedVendorIds={vendors}
                  analyticsData={{
                    paymentIds: batchPaymentIds,
                  }}
                />
              )}
              {modalContent === ModalContent.NOTIFY_VENDOR && (
                <NotifyVendors
                  {...{ closeModal, vendorsWithoutEmail }}
                  analyticsData={{ paymentIds: batchPaymentIds }}
                  billedVendorIds={vendors}
                  paidPaymentsMainData={paidPaymentsMainData}
                />
              )}
            </ModalWrapper>
          ),
          document.querySelector('body') as any
        ),
      ]}
    </>
  );
};
