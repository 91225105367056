import React from 'react';
import get from 'lodash/get';
import BillListItem from 'src/app/components/list/QBOBillListItem';
import {
  getBillTag,
  getPartialPaymentBillTag,
  isBillHasPartialPayments,
} from 'src/app/utils/bills';
import { BILL_STATUS } from 'src/app/utils/consts';
import { useQueryString } from 'src/app/utils/hooks';
import { BillType } from 'src/app/utils/types';
import { getLatestPayment } from 'src/app/utils/payments';
import { Payment } from 'src/app/version-2/model/dtos';

const getBillListItemInfo = (status, dueDate, scheduledDate) => {
  let displayDate: string | null = null;
  let dateLabel = '';

  switch (status) {
    case BILL_STATUS.SCHEDULED:
      displayDate = scheduledDate;
      dateLabel = 'bills.view.scheduledOn';
      break;

    case BILL_STATUS.PAID:
      displayDate = scheduledDate;
      dateLabel = 'bills.view.processed';
      break;

    case BILL_STATUS.UNPAID:
    default:
      displayDate = dueDate;
      dateLabel = 'bills.view.due';
  }

  return { dateLabel, displayDate };
};

type Props = {
  bill: BillType;
  isPartialPaymentsEnabled: boolean;
  hideVendorNames: boolean;
  onClick?: () => void;
};

export const BillListItemWrapper = ({
  bill,
  isPartialPaymentsEnabled,
  hideVendorNames,
  onClick,
}: Props) => {
  const { id, vendor, dueDate, totalAmount, status, invoiceNumber, payments } = bill;
  const query = useQueryString();
  const latestPayment = getLatestPayment(payments);
  const scheduledDate = latestPayment ? latestPayment.scheduledDate : null;
  const { dateLabel, displayDate } = getBillListItemInfo(status, dueDate, scheduledDate);

  const billHasPartialPayments = isBillHasPartialPayments(bill);
  const isPartialPayment = isPartialPaymentsEnabled && billHasPartialPayments;

  const balance =
    isPartialPayment && query.status === BILL_STATUS.UNPAID ? bill.balance : totalAmount || 0;
  const tag = isPartialPaymentsEnabled ? getPartialPaymentBillTag(id, bill) : getBillTag(bill);
  const billPayments = latestPayment?.billPayments;

  return (
    <BillListItem
      key={id}
      id={id}
      tag={tag}
      vendorName={hideVendorNames ? '' : get(vendor, 'companyName', '')}
      invoiceNumber={invoiceNumber}
      balance={balance}
      totalAmount={totalAmount}
      status={status}
      query={query}
      search={query.search as string}
      dateLabel={dateLabel}
      displayDate={displayDate}
      onClick={onClick}
      billPayments={billPayments as unknown as Payment[] | undefined}
    />
  );
};
