const baseStyleTrack = {
  borderRadius: 'var(--slider-track-height)',
  p: '0.1rem',
  width: 'var(--slider-track-width)',
  height: 'var(--slider-track-height)',
  transition: 'all 120ms',
  bg: 'ds.gray.300',
  fontFamily: 'body',

  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  _checked: {
    bg: 'ds.green.200',
  },
};

const baseStyleThumb = {
  bg: 'ds.white',
  transition: 'transform 250ms',
  borderRadius: 'inherit',
  width: 'var(--slider-track-height)',
  height: 'var(--slider-track-height)',
  _checked: {
    transform: 'translateX(var(--slider-thumb-x))',
  },
};

const baseStyle = {
  container: {
    '--slider-track-diff': 'calc(var(--slider-track-width) - var(--slider-track-height))',
    '--slider-thumb-x': 'var(--slider-track-diff)',
  },
  track: baseStyleTrack,
  thumb: baseStyleThumb,
};

const sizes = {
  md: {
    container: {
      '--slider-track-width': '2.8rem',
      '--slider-track-height': '1.4rem',
      lineHeight: 'normal',
    },
  },
  lg: {
    container: {
      '--slider-track-width': '3.4rem',
      '--slider-track-height': '1.8rem',
      lineHeight: 'normal',
    },
  },
};

export const SwitchTheme = {
  baseStyle,
  sizes,
};
