import React from 'react';
import { CONSTS } from 'src/app/utils/consts';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import {
  OptionalDeliveryMethodsType,
  organizationFee,
  CardTypes,
  FeeVariants,
  PaymentFeeItem,
  DeliveryOptionType,
} from 'src/app/utils/types';
import { isAchCheckFeeType } from 'src/app/version-2/utils/fee.utils';

export const calculateTotalFees = (fees: Record<string, any>[]) =>
  fees?.reduce((total, fee) => total + fee?.amount, 0);

export const calculateAmountWithFees = (amount: number | undefined | null, fees: any) =>
  (Number(amount) || 0) + (fees?.finalAmount || 0);

export const defaultFeeObject = {
  finalAmount: 0,
  feeStructure: {
    value: 0,
  },
  promotion: {},
};

// extact final fee amount (including symbol e.g $1.5, 2.9%)
export const getCompleteFeeByDeliveryType = (
  fees: Record<string, any> | Record<string, any>[] | null,
  deliveryType?: OptionalDeliveryMethodsType | CardTypes | FeeVariants,
  existingFeeItem?: any
): string | React.ReactNode => {
  // fees are not loaded yet
  if (!fees) return undefined;

  const feeValues = !Array.isArray(fees) ? Object.values(fees) : fees;
  const feeItem =
    existingFeeItem || feeValues.find((fee: organizationFee) => fee?.feeType === deliveryType);

  // selected fee is not in the list
  if (!feeItem?.value) return undefined;

  const { valueType, value = '' } = feeItem;

  if (value === '0' || value === 0) return undefined;

  if (valueType === 'percent') return `${value}%`;

  return <MIFormattedCurrency value={value} />;
};

// get fee object from catalog by delivery type
export const getFeeFromCatalog = (
  fees: Record<string, any>,
  deliveryType: OptionalDeliveryMethodsType | CardTypes | FeeVariants
) => fees?.[deliveryType]?.value;

export const mapCalendarFees = (deliveryOptions: DeliveryOptionType[]) => {
  const result = deliveryOptions.map((dm: DeliveryOptionType) => {
    const fee = dm.fee?.find((fee: PaymentFeeItem) => {
      // check has a different feeType, ach-to-check
      if (dm.type === CONSTS.DELIVERY_TYPE.CHECK && isAchCheckFeeType(fee?.feeCatalog?.feeType))
        return true;

      return fee.feeType === dm.type;
    });
    const newDm = { ...dm };

    if (fee) {
      // updating correct fast fee
      newDm.feeType = fee.feeType || fee.feeCatalog?.feeType || '';
      newDm.amount = fee.amount;
      newDm.isFixed = fee.isFixed || fee.feeCatalog?.valueType === 'fixed';
      newDm.percent = fee.percent;
    }

    return newDm;
  });

  return result;
};
