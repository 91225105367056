import { CSSObject } from '@melio/billpay-design-system';

export const containerStyle: CSSObject = {
  mr: '0.3rem',
};

export const indicationStyle: CSSObject = {
  fontWeight: 'ds.semi',
  color: 'ds.green.200',
};
