import InternationalIcon from 'src/app/images/qbo/international/delivery-icon.svg';
import BankIcon from 'src/app/images/version-2/bank.svg';
import CheckIcon from 'src/app/images/version-2/check-icon.svg';
import VirtualCardIcon from 'src/app/images/version-2/virtual-card-icon.svg';
import VirtualIcon from 'src/app/images/version-2/virtual-icon.svg';
import { PaymentType } from 'src/app/version-2/externals';
import { FAST_DELIVERY_TYPES } from 'src/app/version-2/model/constants/fee';
import {
  BankAccount,
  Bill,
  DeliverFailureData,
  DeliveryMethod,
} from 'src/app/version-2/model/dtos';
import { Check } from 'src/app/version-2/model/dtos/check';
import { DeliverFailureDataEnum, DeliveryEnum } from 'src/app/version-2/model/enums';
import { isEmpty } from 'src/app/version-2/utils/common';
import { getFormattedAddress } from 'src/app/version-2/utils/formatting';
import {
  getRetryFailedToDeliverACH,
  isRetryFailedToDeliverACH,
} from 'src/app/version-2/utils/payment.utils';

export const getImageSrc = (deliveryMethod: DeliveryMethod): SVGElement => {
  let imageSrc;

  switch (deliveryMethod.deliveryType) {
    case DeliveryEnum.ACH:
      imageSrc = BankIcon;
      break;
    case DeliveryEnum.CHECK:
      imageSrc = CheckIcon;
      break;
    case DeliveryEnum.VIRTUAL_CARD:
      imageSrc = VirtualCardIcon;
      break;
    case DeliveryEnum.VIRTUAL:
      imageSrc = VirtualIcon;
      break;
    case DeliveryEnum.INTERNATIONAL:
      imageSrc = InternationalIcon;
      break;
    default:
      break;
  }

  return imageSrc;
};

export const getDeliveryMethodLabel = (deliveryMethod: DeliveryMethod): string => {
  let label;

  switch (deliveryMethod.deliveryType) {
    case DeliveryEnum.ACH:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.bank';
      break;
    case DeliveryEnum.CHECK:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.check';
      break;
    case DeliveryEnum.VIRTUAL:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.virtual';
      break;
    case DeliveryEnum.RPPS:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.direct';
      break;
    case DeliveryEnum.INTERNATIONAL:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.international';
      break;
    case DeliveryEnum.VIRTUAL_CARD:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.virtualCard';
      break;
    default:
      break;
  }

  return label;
};

export const getDeliveryMethodDescription = (deliveryMethod: DeliveryMethod) => {
  const { deliveryType } = deliveryMethod;
  let label;
  let values;

  switch (deliveryType) {
    case DeliveryEnum.ACH:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.bankDescription';
      values = { digits: getAccountNumber4digits(deliveryMethod.bankAccount) };
      break;
    case DeliveryEnum.CHECK:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.checkDescription';
      values = { displayName: getFormattedAddress(deliveryMethod.paperCheck as Check) };
      break;
    case DeliveryEnum.VIRTUAL_CARD:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.virtualCardDescription';
      values = { displayName: deliveryMethod.virtualCardAccount?.accountEmail };
      break;
    case DeliveryEnum.VIRTUAL:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.virtualDescription';
      values = { displayName: deliveryMethod.virtualAccount?.email };
      break;
    case DeliveryEnum.INTERNATIONAL:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.international';
      break;
    default:
      label = 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.default';
      break;
  }

  return {
    label,
    values,
  };
};

export const getAccountNumber4digits = (bankAccount?): string =>
  bankAccount?.accountNumber4digits || bankAccount?.accountNumber?.slice(-4) || '';

export const isFastDeliveryType = (deliveryPreference) =>
  FAST_DELIVERY_TYPES.includes(deliveryPreference || '');

export const shouldAllowEditDeliveryMethod = (deliveryMethod: DeliveryMethod): boolean =>
  !(
    deliveryMethod.deliveryType === DeliveryEnum.VIRTUAL_CARD &&
    (deliveryMethod.isFilledByVendor || !isEmpty(deliveryMethod?.managedBy))
  );

export const isCheckDeliveryMethod = (deliveryMethod?: DeliveryMethod) =>
  deliveryMethod?.deliveryType === DeliveryEnum.CHECK;

interface GetBankDetailsEditDeliveryPageParams {
  bank: {
    routingNumber?: string;
    accountNumber?: string;
  };
  deliverFailureData?: DeliverFailureData;
}
// getting correct bank details including failed to ach retry cases
// affects edit delivery method page
export const getBankDetailsEditDeliveryPage = ({
  bank,
  deliverFailureData,
}: GetBankDetailsEditDeliveryPageParams) => {
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH({ deliverFailureData });

  if (isRetryFailedToDeliver) {
    const errorCode = getRetryFailedToDeliverACH({ deliverFailureData });

    if (errorCode === DeliverFailureDataEnum.BankDetailsError) {
      // R03 - bank details error
      return {
        ...bank,
        routingNumber: '',
        accountNumber: '',
      };
    }

    // R04 - account number error
    return {
      ...bank,
      routingNumber: bank?.routingNumber,
      accountNumber: '',
    };
  }

  return {
    ...bank,
    routingNumber: bank?.routingNumber,
    accountNumber: bank?.accountNumber,
  };
};

interface ValidateSameBankDetailsParams {
  bank: {
    routingNumber?: string;
    accountNumber?: string;
  };
  prevBank?: BankAccount | null;
}

export const validateSameBankDetails = ({ bank, prevBank }: ValidateSameBankDetailsParams) => {
  const inputAccountNumber = bank?.accountNumber;
  const prevAccountNumber = prevBank?.accountNumber;

  const inputRoutingNumber = bank?.routingNumber;
  const prevRoutingNumber = prevBank?.routingNumber;

  if (
    inputAccountNumber &&
    inputRoutingNumber &&
    inputAccountNumber === prevAccountNumber &&
    inputRoutingNumber === prevRoutingNumber
  ) {
    return true;
  }

  return false;
};

// missing delivery method inside the payment object (dm can be found in vendors list but not in payment)
export const isMissingPaymentDeliveryMethod = (payment: PaymentType) => {
  const { deliveryMethod } = payment;

  return !deliveryMethod || Boolean(deliveryMethod?.deletedAt);
};

interface ExtractDeliveryMethodFromVendorParams {
  bill: Bill;
  type: DeliveryEnum;
}
export const extractDeliveryMethodFromVendor = ({
  bill,
  type,
}: ExtractDeliveryMethodFromVendorParams) =>
  bill.vendor?.deliveryMethods?.find((dm) => dm.deliveryType?.includes(type));

interface GetConfirmDeliveryMethodParams {
  bill: Bill;
  payment: PaymentType;
  type: DeliveryEnum;
}
export const getConfirmDeliveryMethod = ({
  bill,
  payment,
  type,
}: GetConfirmDeliveryMethodParams) => {
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);
  const missingPaymentDeliveryMethod = isMissingPaymentDeliveryMethod(payment);

  if (isRetryFailedToDeliver && missingPaymentDeliveryMethod) {
    const deliveryMethod = extractDeliveryMethodFromVendor({ bill, type });

    return deliveryMethod;
  }

  return payment.deliveryMethod;
};
