import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import trim from 'lodash/trim';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { MITextInput } from 'src/app/components/common/MITextInput';
import withOutsideClickHandler from 'src/app/hoc/withOutsideClickHandler';
import { Expandable } from 'src/app/utils/types';
import analytics from 'src/app/services/analytics';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';

export type OptionType = {
  label: string;
  value: any;
  isDisabled?: boolean;
};

export type FirstCustomOptionProps = {
  createOption: (text) => void;
  text: string;
  options: OptionType[];
};

export type BottomListComponentProps = {
  filteredOptions: OptionType[];
};

export type NoOptionsProps = {
  text?: string;
};

export type MIDropDownProps = {
  id?: string;
  options: OptionType[];
  label?: string;
  labelValues?: Record<string, any>;
  value?: any;
  placeholder?: string;
  onChange?: (change: Expandable<{ value: string }>) => void;
  errorMessage?: string;
  required?: boolean;
  size?: string;
  allowCustom?: boolean;
  firstCustomOption?: (FirstCustomOptionProps) => ReactNode;
  bottomListComponent?: (BottomListComponentProps) => ReactNode;
  noOptionsComponent?: (NoOptionsProps) => ReactNode;
  viewOnly?: boolean;
  renderOption?: (option: OptionType, searchText?: string | null) => ReactNode | null;
  privateData?: boolean;
  clickOutsideHandler?: (props: { isDropDownOpen: boolean }) => void;
};

export const defaultOptionRender = (option: OptionType, searchText?: string | null) => {
  const { label, value } = option;

  if (
    label.toLowerCase().includes(searchText?.toLowerCase() || '') ||
    value?.toString().toLowerCase().includes(searchText?.toLowerCase())
  ) {
    return label;
  }

  return null;
};

const MIDropDown = ({
  id,
  label,
  labelValues = {},
  options,
  placeholder,
  errorMessage,
  size = TextInputSizeEnum.WIZARD,
  required,
  firstCustomOption,
  bottomListComponent,
  onChange,
  noOptionsComponent,
  value,
  allowCustom = true,
  viewOnly = false,
  renderOption = defaultOptionRender,
  privateData,
  clickOutsideHandler,
}: MIDropDownProps) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string | undefined>(undefined);
  const trimmedText = trim(text);
  const selectedOption = find(
    options,
    (option) =>
      (typeof value === 'string' &&
        typeof option.value === 'string' &&
        option.value.toLowerCase() === value?.toLowerCase()) ||
      option.value === value
  );
  const filteredOptions =
    isEmpty(trimmedText) || trimmedText === selectedOption?.label
      ? options
      : options.filter((option) => !!renderOption(option, trimmedText));

  useEffect(() => {
    if (selectedOption) {
      setText(selectedOption?.label);
    }
  }, [selectedOption, setText]);

  const onChangeWithEvent = (newOption) => {
    if (onChange) {
      analytics.trackAction(`option-changed-${id}`, { option: newOption });
      onChange(newOption);
    }
  };

  const onSelected = (option) => () => {
    setOpen(false);

    if (value !== option.value) {
      setText(undefined);
      onChangeWithEvent({
        id,
        value: option.value,
        meta: { action: 'select-option' },
      });
    }
  };
  const createOption = (text) => {
    const trimmedText = trim(text);
    const optionExists = find(options, (option) => option.label === trimmedText);

    if (optionExists) {
      onSelected(optionExists)();
    } else if (allowCustom) {
      setOpen(false);
      onChangeWithEvent({
        id,
        value: trimmedText,
        meta: { action: 'create-option' },
      });
    }
  };

  const onTextChange = ({ value }) => {
    setText(value);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      createOption(trimmedText);
    }
  };

  const dropDownIndicator = !viewOnly && (
    <DropDownIndicator
      onClick={() => setOpen(!open)}
      className="icon-large-dropdown-arrow"
      data-testid="toggle-dropdown-icon"
    />
  );

  const dropDownListItemClassName = classNames({
    [FULL_STORY_MASK_RULE_CLASS]: privateData,
  });

  const createOptionHandler = () => createOption(trimmedText);

  const clickHandler = () => {
    if (clickOutsideHandler && open) {
      setText(undefined);
      clickOutsideHandler({ isDropDownOpen: open });
    }

    setOpen(false);
  };

  const handleClickOutsideHandler = clickOutsideHandler ? clickHandler : createOptionHandler;

  return (
    <SingleSelectContainer handleClickOutside={handleClickOutsideHandler} onKeyDown={onKeyDown}>
      <MITextInput
        id={id}
        label={label}
        labelValues={labelValues}
        onChange={onTextChange}
        value={text}
        size={size}
        placeholder={placeholder}
        errorMessage={errorMessage}
        required={required}
        autocomplete="off"
        suffix={dropDownIndicator}
        onClick={() => setOpen(true)}
        onFocus={() => setOpen(true)}
        viewOnly={viewOnly}
        privateData={privateData}
      />
      <DropDownContainer hidden={!open} size={size}>
        <List>
          {firstCustomOption && firstCustomOption({ createOption, text, options })}
          {isEmpty(options) && noOptionsComponent && noOptionsComponent({ text })}
          {filteredOptions.map((option) => (
            <DropDownOption
              key={option.value}
              onClick={onSelected(option)}
              data-testid={`dropdown-option-${option.value}`}
              className={dropDownListItemClassName}
            >
              {renderOption(option)}
            </DropDownOption>
          ))}
        </List>
        {bottomListComponent && bottomListComponent({ filteredOptions })}
      </DropDownContainer>
    </SingleSelectContainer>
  );
};

export default MIDropDown;

const SingleSelectContainer = withOutsideClickHandler(styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
`);

const DropDownIndicator = styled.i`
  font-size: 1.8rem;
  color: black;
  transform: rotateZ(-90deg);
  &:hover {
    transform: rotateZ(-180deg);
  }

  ${(props) => props.theme.components?.MIDropDown?.DropDownIndicator}
`;

const listHeight = 27.6;
const optionHeight = 4.8;
const List = styled.div`
  max-height: ${listHeight}rem;
  overflow: scroll;
`;

export const DropDownOption = styled.div`
  height: ${optionHeight}rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 2rem;
  box-sizing: border-box;
  padding: 1.4rem 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  }
`;

export const DropDownContainer = styled.div<{ size: string; hidden?: boolean }>`
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-top: ${(props) => (props.size === TextInputSizeEnum.WIZARD ? '-3.5rem' : '5px')};
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  padding: 2px;
  ${(props) => props.theme?.components?.MIDropDown?.DropDownContainer}
`;

export { listHeight, optionHeight, SingleSelectContainer };
