import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useRedirectToSinglePayBillFlow } from 'src/app/pages/quickbooks/hooks/useRedirectToSinglePayBillFlow';
import locations from 'src/app/pages/locations';
import { ProgressPage } from '../ProgressPage';

export const SinglePayBillLoader = () => {
  const match = useRouteMatch<{ originId: string }>();
  const { originId } = match.params;
  const { redirectToSinglePayBillFlow } = useRedirectToSinglePayBillFlow({
    shouldUseHistoryReplace: false,
  });

  useEffect(() => {
    redirectToSinglePayBillFlow({
      originId,
      billZeroBalanceRedirectUrl: locations.entrypoints.errors.billAlreadyScheduled,
    });
  }, []);

  return <ProgressPage progress={75} />;
};
