import { createSlice } from '@reduxjs/toolkit';
import { name } from './consts';

const initialState = {
  usageStatus: {
    wasUsedBefore: false,
  },
};

type SetWasUsedBeforeAction = {
  payload: boolean;
};

type UsageStatusState = {
  usageStatus: {
    wasUsedBefore: boolean;
  };
};

type UsageStatusReducers = {
  setWasUsedBefore: (state: UsageStatusState, action: SetWasUsedBeforeAction) => void;
};

const createUsageStatusSlice = () => {
  const slice = createSlice<UsageStatusState, UsageStatusReducers>({
    name: `[${name.toUpperCase()}] USAGE_STATUS`,
    initialState,
    reducers: {
      setWasUsedBefore(state, { payload }) {
        state.usageStatus = {
          wasUsedBefore: payload,
        };
      },
    },
  });

  return {
    ...slice,
    selectors: {
      wasUsedBefore: (state) => !!state[name]?.usageStatus?.wasUsedBefore,
    },
    dispatchers: {
      setWasUsedBefore: (dispatch) => (params) => dispatch(slice.actions.setWasUsedBefore(params)),
    },
  };
};

const usageStatusSlice = createUsageStatusSlice();

export { usageStatusSlice };
