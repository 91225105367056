import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { icons, IconType } from './icons';
import { theme } from '../../theme';

type SmartIconProps = {
  type: IconType;
  size?: `${number}rem`;
  height?: `${number}rem` | 'auto';
  width?: `${number}rem` | 'auto';
  color?: string;
};

const IconContainer = styled.div<{ color?: string }>`
  display: flex;
  & svg {
    height: 100%;
    width: 100%;
    fill: ${({ color }) => color};
  }
`;

export const SmartIcon: FC<SmartIconProps> = ({ type, size, height, width, color }) => {
  const CurrentIcon = icons[type];
  const IconSize = {
    height: size || height,
    minHeight: size || height,
    width: size || width,
    minWidth: size || width,
  };

  const iconColor = useMemo(() => {
    if (color) {
      const colorPath = color.split('.');
      if (colorPath.length === 1) return colorPath;

      return colorPath.reduce((acc, c) => acc[c], theme['colors']);
    }
    return '';
  }, [color]);

  return (
    <IconContainer style={{ ...IconSize }} color={iconColor}>
      <CurrentIcon />
    </IconContainer>
  );
};
