import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/app/hoc/useApi';
import vendorApi from 'src/app/services/api/vendors';
import { getOrgId } from 'src/app/redux/user/selectors';
import { BillType } from 'src/app/utils/types';
import analytics from 'src/app/services/analytics';

type Params = {
  eventPage: string;
  bill: BillType;
};

export const useCheckOwnedVendorExists = ({ bill, eventPage }: Params) => {
  const orgId = useSelector(getOrgId);

  const [getOwnedVendorExists, isOwnedVendorExists, isGettingOwnedVendorData] = useApi<
    [{ orgId: string; id: number }],
    boolean
  >(vendorApi.getOwnedVendorExists, false, true);

  const getOwnedVendor = useCallback(async () => {
    if (bill.vendor?.id) {
      await getOwnedVendorExists({
        orgId,
        id: bill.vendor?.id,
      });
    }
  }, [bill.vendor, getOwnedVendorExists, orgId]);

  useEffect(() => {
    const { vendor } = bill;

    if (vendor) {
      getOwnedVendor();
    }

    if (!vendor?.contactEmail) {
      analytics.track(eventPage, 'confirm-notify-vendor');
    }
  }, [bill, getOwnedVendor]);

  return [isOwnedVendorExists, isGettingOwnedVendorData];
};
