import React from 'react';
import styled from 'styled-components';
import { getCardIcon, getCardImageUrl } from 'src/app/utils/card';
import useGetFundingSourceDisplayName from 'src/app/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import { FUNDING_DEFAULT_LOGO } from 'src/app/version-2/model/constants';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  method: FundingSource;
  className?: string;
};

function TruncatedMethodCard({ method, className }: Props) {
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  let icon = '';
  let imageSrc = '';

  if (method.fundingType === FundingSourceTypesEnum.ACH) {
    const hasLogo = method.logo !== '' && method.logo !== FUNDING_DEFAULT_LOGO;

    imageSrc = hasLogo ? `data:image/jpeg;base64,${method.logo}` : '';
    icon = imageSrc ? '' : 'icon-bank-icon';
  } else {
    icon = getCardIcon(method);
    imageSrc = getCardImageUrl(method);
  }

  return (
    <MethodInfoContainer className={className}>
      <Icon>
        {icon && <i className={icon} />}
        {imageSrc && <img src={imageSrc} alt="method-icon" />}
      </Icon>
      <MethodText>{getFundingSourceDisplayName({ fundingSource: method })}</MethodText>
    </MethodInfoContainer>
  );
}

export default TruncatedMethodCard;

const MethodInfoContainer = styled.div`
  display: flex;
  padding: 1.8rem;
  border: 0.1rem solid ${(props) => props.theme.text.color.readonly};
  border-radius: 0.8rem;
`;

const MethodText = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${(props) => props.theme.text.fontType.regular};
`;

const Icon = styled.div`
  color: ${(props) => props.theme.text.color.main};
  margin-right: 2rem;
  > img {
    height: 3.2rem;
  }

  > i {
    font-size: 3.2rem;
  }
`;
