import React from 'react';
import styled from 'styled-components';
import MIButton from 'src/app/components/common/MIButton';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { CONSTS } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';

type Props = {
  confirm: (result: any) => void;
  dismiss?: (result: any) => void;
  close: (result: any) => void;
  edit: (result: any) => void;
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
};

export const PaymentExceedsAvailableBalanceModal = ({
  dismiss,
  title,
  description,
  confirmText,
  cancelText,
  confirm,
  close,
  edit,
}: Props) => {
  const handleOnEdit = (event) => {
    if (dismiss) {
      dismiss(event);
    }

    edit(event);
  };

  const handleOnClose = (event) => {
    if (dismiss) {
      dismiss(event);
    }

    close(event);
  };

  return (
    <ModalMessage
      titleComponent={
        <Title>
          <MIFormattedText label={title} />
        </Title>
      }
      descriptionComponent={
        <DescriptionContainer>
          <MIFormattedText label={description} />
        </DescriptionContainer>
      }
      onCloseClick={handleOnClose}
      footerComponent={
        <ButtonsContainer>
          <MIButton
            onClick={handleOnEdit}
            label={cancelText}
            variant={CONSTS.BUTTON_VARIANT.CANCEL}
          />
          <MIButton onClick={confirm} label={confirmText} variant={CONSTS.BUTTON_VARIANT.PRIMARY} />
        </ButtonsContainer>
      }
    />
  );
};

const Title = styled.div`
  text-align: left;
  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: -3rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -4rem;
  > button:first-child {
    margin-right: 2rem;
    @media ${devices.mobile}, ${devices.phablet} {
      margin-right: 1rem;
    }
  }
  @media ${devices.mobile}, ${devices.phablet} {
    > button {
      padding: 0 1.3rem;
      margin-right: 0;
    }
  }
`;

const DescriptionContainer = styled.div`
  text-align: left;
  letter-spacing: 0;
  line-height: 2.4rem;
`;
