import React from 'react';
import { Record } from 'immutable';
import { getFormattedAddress, MIFormattedText } from 'src/app/utils/formatting';
import { getInternationalDMDisplayLabel } from 'src/app/utils/international';
import { BANK_ACCOUNT_CHECKING_TYPE } from 'src/app/version-2/model/constants';
import {
  AddressType,
  BankType,
  CheckType,
  DeliveryMethodType,
  OptionalDeliveryMethodsType,
} from '../../utils/types';
import { CONSTS } from '../../utils/consts';
import { addressDefaults } from '../settings/records';
import { getAccountNumber4digits } from '../../utils/bank-account';
import { DeliveryMethodRecord } from './records-constants';

export const getDeliveryMethodDisplayName = ({
  deliveryMethod,
  vendorName = '',
  isSharedVendor,
}: {
  deliveryMethod: DeliveryMethodType;
  vendorName?: string;
  isSharedVendor?: boolean;
}): string => {
  if (isSharedVendor) {
    return vendorName ?? 'Melio Network';
  }

  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);

  if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH && accountNumber4digits) {
    return `${vendorName} (...${accountNumber4digits})`;
  }

  if (deliveryMethod?.paperCheck) {
    const { addressLine1, addressLine2, city, state, zipCode, countryCode } =
      deliveryMethod?.paperCheck;
    const addressData: (string | null | undefined)[] = [
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      countryCode,
    ];

    const address: string = addressData.filter(Boolean).join(', ');

    return `${deliveryMethod?.paperCheck.printName}  | ${address}`;
  }

  return '';
};

export const getDeliveryMethodInfo = ({
  deliveryMethod,
  vendorName = '',
  isSharedVendor,
}: {
  deliveryMethod: DeliveryMethodType;
  vendorName?: string;
  isSharedVendor?: boolean;
}): string | JSX.Element => {
  if (isSharedVendor) {
    return vendorName ?? 'Melio Network';
  }

  const isRPPSDeliveryMethod = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;
  const isVirtualCardDeliveryMethod =
    deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;
  const isConvertedToDebit =
    deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CARD && deliveryMethod?.isFilledByVendor;

  if (isRPPSDeliveryMethod) {
    return <MIFormattedText label="deliveryMethods.rpps" />;
  }

  if (isVirtualCardDeliveryMethod) {
    return deliveryMethod?.virtualCardAccount?.accountEmail || '';
  }

  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);

  if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH && accountNumber4digits) {
    return `${vendorName} (...${accountNumber4digits})`;
  }

  if (deliveryMethod?.paperCheck) {
    return getFormattedAddress(deliveryMethod?.paperCheck);
  }

  if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL) {
    return getInternationalDMDisplayLabel(deliveryMethod?.internationalAccount);
  }

  if (isConvertedToDebit) {
    return `${vendorName} (••••)`;
  }

  return '';
};

export const getDeliveryMethodByType = (
  type: OptionalDeliveryMethodsType,
  deliveryMethods: DeliveryMethodType[]
) => {
  let deliveryMethod: DeliveryMethodType | undefined;

  if (deliveryMethods) {
    deliveryMethod = deliveryMethods.find((dm) => dm.deliveryType === type);
  }

  if (!deliveryMethod) {
    return DeliveryMethodRecord({ id: '', deliveryType: type });
  }

  return DeliveryMethodRecord(deliveryMethod);
};

export const BankRecord = Record<BankType>(
  {
    accountType: BANK_ACCOUNT_CHECKING_TYPE,
    routingNumber: '',
    accountNumber: '',
    intuitAccountId: '',
    isBlocked: false,
    accountNumber4digits: '',
  },
  'BankRecord'
);

export const CheckRecord = Record<CheckType>(
  {
    ...addressDefaults,
    printName: '',
    isAddressSuggestionIgnored: null,
  },
  'CheckRecord'
);

export const AddressRecord = Record<AddressType>(
  {
    ...addressDefaults,
  },
  'AddressRecord'
);
