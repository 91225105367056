import { RecordOf } from 'immutable';
import React from 'react';
import { MIFieldOrEmpty, MIFormattedText } from 'src/app/utils/formatting';
import { PaymentType } from 'src/app/utils/types';
import styled, { css } from 'styled-components';

type Props = {
  payment: RecordOf<PaymentType>;
};

const ViewBillPaymentActivityMemoInfo = ({ payment }: Props) => (
  <NoteBoxRow data-testid="view-payment-activity-memo">
    <NoteBoxColumn>
      <FieldName>
        <MIFormattedText label="viewBillPaymentActivity.memo.label" />
      </FieldName>
      <FieldValue data-testid="billpay-view-activity-memo">
        <MIFieldOrEmpty
          privateData
          value={payment.note || ''}
          label="viewBillPaymentActivity.memo.emptyPlaceholder"
        />
      </FieldValue>
    </NoteBoxColumn>
  </NoteBoxRow>
);

export default ViewBillPaymentActivityMemoInfo;

const NoteBoxRow = styled.div`
  display: flex;
  align-items: flex-start;
  word-break: break-all;
  ${(props) => props.theme?.components?.PaymentActivity?.NoteBoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  margin: 0.6rem 0 0rem;
  ${(props) => props.theme?.components?.PaymentActivity?.BoxColumn}
`;

const NoteBoxColumn = styled(BoxColumn)`
  margin-top: 0;
  ${(props) => props.theme?.components?.PaymentActivity?.NoteBoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.PaymentActivity?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.PaymentActivity?.FieldName}
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.FieldValue}
`;
