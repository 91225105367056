import { syncBillRetry } from 'src/app/utils/sync-bill-retry';
import { loggingApi } from 'src/app/version-2/api/loggers';

import organizationApi from './organizations';
import { fetchRequest, postRequest } from './api';
import retry from '../../utils/retry';

export default {
  syncQuickBooksData(orgId) {
    return organizationApi.runFullsync(orgId);
  },

  syncQuickBooksDataSynchronously(orgId) {
    return organizationApi.runFullSyncSynchronously(orgId);
  },

  syncQuickBooksDataStatus(orgId) {
    const url = `/orgs/${orgId}/quickbooks/sync/status`;

    return fetchRequest(url);
  },

  getAccountsForBill(orgId) {
    return organizationApi.getAccountsForBill(orgId);
  },

  syncBillByOriginId(orgId, originId) {
    const url = `/orgs/${orgId}/quickbooks/sync/bill/${originId}`;

    loggingApi.info('accountingSoftwareSync.syncBillByOriginId(): sync bill origin endpoint', {
      orgId,
      originId,
    });

    return syncBillRetry(() => fetchRequest(url), 1000, 4);
  },
  syncMultipleBillsByOriginId(orgId, originIds) {
    const url = `/orgs/${orgId}/quickbooks/sync/bills?originIds=${originIds.join()}`;

    return retry(() => fetchRequest(url), 500, 3);
  },

  syncPaymentByOriginId(orgId, originId) {
    const url = `/orgs/${orgId}/quickbooks/sync/payment/${originId}`;

    return fetchRequest(url);
  },

  syncVendorByOriginId({ orgId, vendorOriginId }) {
    const url = `/orgs/${orgId}/quickbooks/sync/vendor/${vendorOriginId}`;

    return postRequest(url);
  },

  syncVendorAndBillsByOriginId(orgId, vendorOriginId) {
    const url = `/orgs/${orgId}/quickbooks/sync/vendor-and-bills/${vendorOriginId}`;

    return postRequest(url);
  },
};
