import { EventMappingType } from '../types';

const billPayMemoPageProperties = {
  vendorId: 'vendorId',
  screenName: 'screenName',
  partialBillId: 'partialBillId',
};

export const getMemoPageEvents = (eventPage = 'pay-bill'): Record<string, EventMappingType> => ({
  'form-validation-error': [
    eventPage,
    'validation-error',
    { validationsErrors: 'validationErrors' },
  ],
  'vendor-email-save-success': [
    eventPage,
    'vendor-email-save-success',
    {
      ...billPayMemoPageProperties,
      vendorEmail: 'vendorEmail',
    },
  ],
  'exposed-to-add-vendor-email-line': [
    eventPage,
    'exposed-to-add-vendor-email-line',
    {
      ...billPayMemoPageProperties,
    },
  ],
});
