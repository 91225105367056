import deliveryApi from 'src/app/services/api/delivery';
import { BILL_STATUS } from 'src/app/utils/consts';
import { DbAnalyticsTraitsEnum, DataOriginEnum } from 'src/app/version-2/model/enums';
import { deleteRequest, postRequest, putRequest, fetchRequest } from './api';
import analytics from '../analytics';
import { convertLimit, convertStart } from '../../utils/pagination-utils';

export type PaymentFilters = {
  start?: number;
  limit?: number;
};

type PaymentFeeProps = {
  orgId: string;
  fundingSourceId: number;
  amount?: string | number | null;
  scheduledDate: Date;
  deliveryPreference?: string | null;
  deliveryMethodId: string | number;
  cardFeePaidBy?: string;
  allowDoubleFee?: boolean;
};

// TODO: add a scope argument to GET requests to get payments which point to deleted funding sources & delivery methods
export default {
  getPayments({
    orgId,
    filters = {},
    sorting,
  }: {
    orgId: string;
    filters: PaymentFilters;
    sorting: string;
  }) {
    const url = `/orgs/${orgId}/payments`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
      sorting,
    };

    return fetchRequest(url, params);
  },

  list({
    orgId,
    status,
    search,
    start,
    limit,
  }: {
    orgId: string;
    status: BILL_STATUS;
    search?: string;
    start?: number;
    limit?: number;
  }) {
    const url = `/orgs/${orgId}/payments/list`;
    const params = {
      status,
      search,
      start: convertStart(start),
      limit: convertLimit(limit),
    };

    return fetchRequest(url, params);
  },

  createPayment(orgId, params) {
    const url = `/orgs/${orgId}/payments`;

    analytics.setTraits({ [DbAnalyticsTraitsEnum.FIRST_TIME_PAY]: true });

    return postRequest(url, params);
  },
  /* The new end point for creating payments (payment-api)) */
  paymentApiCreate(orgId, data) {
    const url = `/orgs/${orgId}/payment-api/`;

    analytics.setTraits({ [DbAnalyticsTraitsEnum.FIRST_TIME_PAY]: true });

    return postRequest(url, data);
  },

  paymentApiCreateBatch(orgId, data) {
    const url = `/orgs/${orgId}/payment-api/batch`;

    return postRequest(url, data);
  },

  paymentApiUpdate(orgId, data) {
    const url = `/orgs/${orgId}/payment-api/${data?.id}`;

    analytics.setTraits({ [DbAnalyticsTraitsEnum.FIRST_TIME_PAY]: true });

    return putRequest(url, data);
  },

  retryFailedToCollect(orgId, paymentData) {
    const url = `/orgs/${orgId}/payments/${paymentData.id}/retry-failed-to-collect`;

    return postRequest(url, paymentData);
  },

  retryFailedToDeliver(orgId, paymentData) {
    const url = `/orgs/${orgId}/payments/${paymentData.id}/retry-failed-to-deliver`;

    return postRequest(url, paymentData);
  },

  cancelAndRetry(orgId, params) {
    const url = `/orgs/${orgId}/payments/${params.id}/cancel-and-retry`;

    return postRequest(url, {
      newDeliveryMethodId: params.newDeliveryMethodId,
      deliveryPreference: params.deliveryPreference,
    });
  },

  retryFailedToDeliverWithToken({ token }: { token: string }) {
    const url = '/payment/retry-failed-to-deliver';

    return postRequest(url, { token });
  },

  deletePaymentById(orgId, id, params = {}) {
    const url = `/orgs/${orgId}/payments/${id}`;

    return deleteRequest(url, params);
  },

  editPaymentById(orgId, params) {
    const url = `/orgs/${orgId}/payments/${params.id}`;

    return putRequest(url, params);
  },

  getPaymentById(orgId, paymentId) {
    const url = `/orgs/${orgId}/payments/${paymentId}`;

    return fetchRequest(url, {});
  },
  approvePayment({ orgId, id, reason }) {
    const url = `/orgs/${orgId}/payments/${id}/approval-decision`;

    return postRequest(url, { status: 'approved', reason });
  },
  declinePayment({ orgId, id, reason }) {
    const url = `/orgs/${orgId}/payments/${id}/approval-decision`;

    return postRequest(url, { status: 'declined', reason });
  },

  createPaymentsByBatch({ orgId, payments }) {
    const url = `/orgs/${orgId}/payments/create-batch`;

    return postRequest(url, payments);
  },

  getDeliveryTime({
    orgId,
    deductionDate,
    deliveryMethodId,
    fundingSourceId,
    amount,
    payBillFlowUUID,
  }) {
    return deliveryApi.getDeliveryTime(
      orgId,
      deductionDate,
      deliveryMethodId,
      fundingSourceId,
      amount,
      payBillFlowUUID
    );
  },

  getPaymentFee({
    orgId,
    fundingSourceId,
    amount,
    scheduledDate,
    deliveryPreference,
    deliveryMethodId,
    cardFeePaidBy,
    allowDoubleFee = false,
  }: PaymentFeeProps) {
    const url = `/orgs/${orgId}/payments/fee`;

    return fetchRequest(url, {
      amount,
      fundingSourceId,
      scheduledDate,
      deliveryPreference,
      deliveryMethodId,
      cardFeePaidBy,
      allowDoubleFee,
      paymentOrigin: DataOriginEnum.INTUIT,
    });
  },

  getPaymentFeeApi(orgId, params) {
    const url = `/orgs/${orgId}/payments/fee-api`;

    return fetchRequest(url, params);
  },

  getDetailsWithToken(params) {
    const url = '/payment/details';

    return fetchRequest(url, params);
  },

  getEmailToVendorData(params) {
    const url = '/payment/details/email-to-vendor';

    return fetchRequest(url, params);
  },

  getVirtualCardData(params) {
    const url = '/payment/details/virtual-card';

    return fetchRequest(url, params);
  },

  updatePaymentByIdWithToken(params) {
    const url = `/payment/${params.id}/push-to-debit`;

    return putRequest(url, params);
  },

  sendEditCheckAddress(orgId, id) {
    const url = `/orgs/${orgId}/payments/${id}/send-edit-check-address`;

    return postRequest(url);
  },

  notifyVendor(orgId, id) {
    const url = `/orgs/${orgId}/payments/${id}/notify-vendor`;

    return postRequest(url);
  },

  getPaymentsReport({
    orgId,
    filters = {},
  }: {
    orgId: string;
    filters: { startDate?: Date; endDate?: Date };
  }) {
    const url = `/orgs/${orgId}/payments/report`;
    const params = {
      startDate: filters.startDate?.toISOString(),
      endDate: filters.endDate?.toISOString(),
    };

    return fetchRequest(url, params);
  },

  getShiftVirtualCardToACHPaymentCredentialsWithToken(params) {
    const url = `/payment/shift-virtual-card-to-ACH-payment-credentials`;

    return fetchRequest(url, params);
  },
};
