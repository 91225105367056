import { EventMappingsTree } from 'src/app/services/analytics/types';
import { mapApiSlice } from 'src/app/services/analytics/event-mapping-utils';

const pageEvent = 'quickpay-edit';

const MAPPING: EventMappingsTree = {
  '/orgs/*/quickpay/*/edit/confirm': {
    'page.view': [pageEvent, 'quickpay-edit-confirm'],
    balanceConfirm: [pageEvent, 'quickpay-edit-show-balance-confirmation', { toggle: 'toggle' }],
    'bills.pay.confirm.balanceConfirm.confirm': [
      pageEvent,
      'quickpay-edit-confirm-schedule-anyway',
    ],
    'bills.pay.confirm.balanceConfirm.cancel': [
      pageEvent,
      'quickpay-edit-edit-payment-after-balance-confirm',
    ],
    ...mapApiSlice('[PAYMENTS] QUICKPAY_UPDATE', pageEvent, 'quickpay-edit-payment'),
  },
  '/orgs/*/quickpay/*/edit/date': {
    'page.view': [pageEvent, 'quickpay-edit-date'],
    'progress.continue': [pageEvent, 'quickpay-edit-date-save'],
  },
  '/orgs/*/quickpay/*/edit/note': {
    'page.view': [pageEvent, 'quickpay-edit-note'],
    'progress.continue': [pageEvent, 'quickpay-edit-note-save'],
  },
  '/orgs/*/quickpay/*/edit/success': {
    'page.view': [pageEvent, 'quickpay-edit-success'],
    'qbr.success.email.saveEmail': [pageEvent, 'quickpay-edit-success-send-email'],
    'qbr.success.emailThem': [pageEvent, 'quickpay-edit-success-email-them'],
    'qbr.success.done': [pageEvent, 'quickpay-edit-success-done'],
  },
  '/orgs/*/quickpay/*/edit/delivery-method/*': {
    'page.view': [pageEvent, 'quickpay-edit-delivery-method'],
  },
  '/orgs/*/quickpay/*/edit/delivery-methods/ach/*': {
    'page.view': [pageEvent, 'quickpay-edit-delivery-method-new-ach'],
    'vendors.deliveryMethods.bank.save': [pageEvent, 'quickpay-edit-delivery-method-new-ach-save'],
  },
  '/orgs/*/quickpay/*/edit/delivery-methods/*/ach/*': {
    'page.view': [pageEvent, 'quickpay-edit-delivery-method-edit-ach'],
    'vendors.deliveryMethods.bank.save': [pageEvent, 'quickpay-edit-delivery-method-edit-ach-save'],
  },
  '/orgs/*/quickpay/*/edit/delivery-methods/check/*': {
    'page.view': [pageEvent, 'quickpay-edit-delivery-method-new-check'],
    'vendors.deliveryMethods.bank.save': [
      pageEvent,
      'quickpay-edit-delivery-method-new-check-save',
    ],
  },
  '/orgs/*/quickpay/*/edit/delivery-methods/*/check/*': {
    'page.view': [pageEvent, 'quickpay-edit-delivery-method-edit-check'],
    'vendors.deliveryMethods.bank.save': [
      pageEvent,
      'quickpay-edit-delivery-method-edit-check-save',
    ],
  },
};

export default MAPPING;
