import { prefix } from '../../consts';

export const textStyles = {
  [prefix]: {
    h1: {
      fontSize: '4.8rem',
      fontWeight: 'ds.semi',
      lineHeight: '6rem',
    },
    h2: {
      fontSize: '4rem',
      fontWeight: 'ds.semi',
      lineHeight: '5.2rem',
    },
    h3: {
      fontSize: '3.4rem',
      fontWeight: 'ds.semi',
      lineHeight: '4.4rem',
    },
    h4: {
      fontSize: '2.8rem',
      fontWeight: 'ds.semi',
      lineHeight: '3.6rem',
    },
    h5: {
      fontSize: '2.4rem',
      fontWeight: 'ds.semi',
      lineHeight: '3.2rem',
    },
    h6: {
      fontSize: '2rem',
      fontWeight: 'ds.semi',
      lineHeight: '2.8rem',
    },
    body1: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    body1Semi: {
      fontSize: '1.6rem',
      fontWeight: 'ds.semi',
      lineHeight: '2.4rem',
    },
    body1Medium: {
      fontSize: '1.6rem',
      fontWeight: 'ds.medium',
      lineHeight: '2.4rem',
    },
    body1Bold: {
      fontSize: '1.6rem',
      fontWeight: 'ds.bold',
      lineHeight: '2.4rem',
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: '2rem',
    },
    body2Semi: {
      fontSize: '1.4rem',
      fontWeight: 'ds.semi',
      lineHeight: '2rem',
    },
    body2Medium: {
      fontSize: '1.4rem',
      fontWeight: 'ds.medium',
      lineHeight: '2rem',
    },
    body2Bold: {
      fontSize: '1.4rem',
      fontWeight: 'ds.bold',
      lineHeight: '2rem',
    },
    body3: {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
    },
    body3Semi: {
      fontSize: '1.2rem',
      fontWeight: 'ds.semi',
      lineHeight: '1.6rem',
    },
    body3Medium: {
      fontSize: '1.2rem',
      fontWeight: 'ds.medium',
      lineHeight: '1.6rem',
    },
    body3Bold: {
      fontSize: '1.2rem',
      fontWeight: 'ds.bold',
      lineHeight: '1.6rem',
    },
    body4: {
      fontSize: '1rem',
      lineHeight: '1.8rem',
    },
    body4Semi: {
      fontSize: '1rem',
      fontWeight: 'ds.semi',
      lineHeight: '1.8rem',
    },
    body4Medium: {
      fontSize: '1rem',
      fontWeight: 'ds.medium',
      lineHeight: '1.8rem',
    },
    body4Bold: {
      fontSize: '1rem',
      fontWeight: 'ds.bold',
      lineHeight: '1.8rem',
    },
  },
};
