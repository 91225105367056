import {
  createApiCallSlice,
  ON_FAILURE,
  ON_REQUEST,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import { createCreateSlice } from 'src/app/helpers/redux/restCreateSlice';
import fundingSourcesApi from 'src/app/services/api/fundingSources';
import { DEFAULT_QB_CASH_BALANCE } from 'src/app/utils/consts';
import { name } from './constants';

type BalanceState = {
  qbcash: {
    balance: number | null;
    loading: boolean;
    error?: any;
  };
};

type BalancePayload = {
  balance: string;
  orgId: string;
};

export const getQBCashBalanceSlice = createApiCallSlice<BalancePayload, BalanceState>({
  initialState: {
    qbcash: {
      balance: DEFAULT_QB_CASH_BALANCE,
      loading: false,
      error: null,
    },
  },
  name: `[${name}] get-qb-cash-balanace`.toUpperCase(),
  api: fundingSourcesApi.getQBCashBalanace,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.qbcash.loading = true;
    },
    [ON_SUCCESS]: (state, action) => {
      state.qbcash.loading = false;
      state.qbcash.balance = parseFloat(action.payload.balance);
    },
    [ON_FAILURE]: (state, action) => {
      state.qbcash.loading = false;
      state.qbcash.balance = DEFAULT_QB_CASH_BALANCE;
      state.qbcash.error = action.error;
    },
  },
  selectors: {
    balance: (state) => state[name].qbcash.balance,
    loading: (state) => state[name].qbcash.loading,
    error: (state) => state[name].qbcash.error,
    state: (state) => state[name].qbcash,
  },
});

export const syncQBCashSlice = createCreateSlice({
  storeName: name,
  actionName: 'sync-qb-cash',
  api: fundingSourcesApi.syncQBCash,
  createHashFunc: () => 'sync-qbcash',
});
