import React from 'react';
import { useSelector } from 'react-redux';
import { FUNDING_SOURCE_ORIGIN } from 'src/app/utils/consts';
import { isBankAccountBlocked } from 'src/app/utils/funding-sources';
import { QBCashStateType } from 'src/app/utils/types';
import qbCashBankOrigin from 'src/app/images/icons/qb-cash-bank-origin.svg';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import isEmpty from 'lodash/isEmpty';
import { isValidQBCashBalance } from 'src/app/utils/payments';
import { getWizardFlow } from 'src/app/redux/payBillWizard/selectors';
import { FundingSourceTypesEnum, FundingSourceOrigins } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { getPaymentMethodNameParts } from 'src/app/version-2/utils/paymentMethods.utils';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  onClick?: (event: any) => void;
  method: FundingSource;
  isSelected: boolean;
  optionComponent: any;
  onVerifyClicked?: (id: number) => void;
  disabled: boolean;
  modifyActions: Record<string, any>;
  isBillingFeeFlow?: boolean;
  isSettings?: boolean;
};

function getImages() {
  return {
    imageSrc: qbCashBankOrigin,
  };
}

const BASE_PREFIX = 'bills.form.paymentActivity.scheduledBill';

function getDescription(isValidBalance: boolean, isBillingFeeFlow: undefined | boolean) {
  if (isBillingFeeFlow) {
    return `${BASE_PREFIX}.hint.feeFundingSource`;
  }

  return isValidBalance ? `${BASE_PREFIX}.freeBalance` : `${BASE_PREFIX}.hint.regular`;
}

function getLabel(method) {
  return method.origin === FUNDING_SOURCE_ORIGIN.QBCASH
    ? `${BASE_PREFIX}.scheduleMethodAchQBCash`
    : `${BASE_PREFIX}.scheduleMethodAch`;
}

function getDescriptionValues(qbCashBalance) {
  return {
    balance: qbCashBalance,
  };
}

export const QboAchPaymentMethod = ({
  onClick,
  method,
  isSelected,
  optionComponent: OptionComponent,
  disabled,
  isBillingFeeFlow,
}: Props) => {
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const qbCashSyncStatus: { error?: any } = useSelector(
    fundingSourcesStore.selectors.syncQBCash.status()
  );
  const { imageSrc } = getImages();
  const isValidBalance = isValidQBCashBalance(qbCashState) && isEmpty(qbCashSyncStatus?.error);
  const description = getDescription(isValidBalance, isBillingFeeFlow);
  const descriptionValues = getDescriptionValues(qbCashState.balance);
  const label = getLabel(method);

  return (
    <OptionComponent
      id={method.id}
      disabled={disabled}
      label={label}
      labelValues={getPaymentMethodNameParts({ fundingSource: method })}
      description={description}
      descriptionValues={descriptionValues}
      isSelected={isSelected}
      imageSrc={imageSrc}
      onClick={onClick}
      origin={method.origin}
    />
  );
};

function QboAchPaymentSelectedMethod({ method }: { method: FundingSource }) {
  const { imageSrc } = getImages();
  const wizardFlow = useSelector(getWizardFlow);

  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const qbCashSyncStatus: { error?: any } = useSelector(
    fundingSourcesStore.selectors.syncQBCash.status()
  );

  let label = 'bills.pay.fundingSource.achLabel';
  const labelValues = getPaymentMethodNameParts({ fundingSource: method }) as any;

  if (method.origin === FundingSourceOrigins.QBCASH) {
    const isValidBalance = isValidQBCashBalance(qbCashState) && isEmpty(qbCashSyncStatus?.error);
    const description = getDescription(isValidBalance, !!wizardFlow);

    label = description;
    labelValues.balance = qbCashState?.balance ? String(qbCashState?.balance) : '';
  }

  return (
    <SelectedMethod
      imageSrc={imageSrc}
      title={getLabel(method)}
      label={label}
      labelValues={labelValues}
    />
  );
}

QboAchPaymentMethod.methodType = FundingSourceTypesEnum.ACH;
QboAchPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherBank';
QboAchPaymentMethod.SelectedMethod = QboAchPaymentSelectedMethod;
QboAchPaymentMethod.isDisabled = isBankAccountBlocked;
QboAchPaymentMethod.showAddAnotherOption = false;
