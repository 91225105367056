import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { MIFormattedText } from 'src/app/utils/formatting';
import intuit from 'src/app/utils/intuit';
import { CONSTS } from 'src/app/utils/consts';
import { EntrypointEventPageEnum, EntrypointActionEnum } from 'src/app/version-2/model/enums';
import { devices } from 'src/app/theme/AppDevices';
import scheduleIconIllustration from 'src/app/images/qbo/schedule-icon-illustration.png';
import bankCheckIcon from 'src/app/images/qbo/bank-check-icon-illustration.png';
import arrow from 'src/app/images/qbo/arrow-icon.svg';
import MIButton from 'src/app/components/common/MIButton';
import { closeDashboard, toggleDashboardSwitcher } from 'src/app/utils/external-events';
import analytics from 'src/app/services/analytics';
import { DASHBOARD_CLOSE_ORIGIN } from 'src/app/pages/qb-dashboard/consts';
import { FTUProgressBarLoader } from '../first-time-user-loader/FTUProgressBarLoader';

type Props = {
  onRegister?: () => void;
  isLoading?: boolean;
  isProgressMode?: boolean;
  realmId?: string;
  txnId?: string;
  txnIds?: string;
  action: EntrypointActionEnum;
};

export const QBOLandingPage = ({
  onRegister,
  isLoading,
  isProgressMode,
  realmId,
  txnId,
  txnIds,
  action,
}: Props) => {
  const isJustPayFlow = action === EntrypointActionEnum.JustPay;

  const pageEvent = isJustPayFlow
    ? EntrypointEventPageEnum.JustPay
    : EntrypointEventPageEnum.PayBill;

  const goExit = () => {
    // We noticed strange behavior when user after registration still see landing page - for example on pay bill flow.
    // And after clicking on `X` on this page he's appeared in loop, since he's redirection on intuit's /paybills and
    // because of turned on switcher he's redirected to melio dashboard. Adding turning off the switcher to prevent such
    // loops until root reason will be found.

    toggleDashboardSwitcher(false);
    closeDashboard(DASHBOARD_CLOSE_ORIGIN.CROSS);
  };

  const site = useSiteContext();

  intuit.endLoadingWrapper();

  const title = isJustPayFlow ? 'firstTimeNew.justPay.title' : 'firstTimeNew.title';
  const fundingSourceStepTitle = isJustPayFlow
    ? 'firstTimeNew.justPay.fundingSourceStep.title'
    : 'firstTimeNew.step.title1';
  const fundingSourceStepSubTitle = isJustPayFlow
    ? 'firstTimeNew.justPay.fundingSourceStep.subTitle'
    : 'firstTimeNew.step.subTitle1';

  useEffect(() => {
    const analyticsProperties = { realmId, txnId, txnIds };

    analytics.page(pageEvent, 'onboarding-screen', analyticsProperties);
  }, [realmId, txnId, txnIds, pageEvent]);

  return (
    <FirstTimeContainer>
      {!isProgressMode && (
        <Close onClick={goExit}>
          <i className="icon-close-icon" />
        </Close>
      )}

      <FirstTimeWrapper>
        {isProgressMode && <FTUProgressBarLoader />}
        <FirstTimeContentWrapper isHidden={isProgressMode}>
          <FirstTimeContent>
            <HowItWorksTitle>
              <MIFormattedText label={title} />
            </HowItWorksTitle>
            <StepsContainer>
              <StepContainer>
                <img src={scheduleIconIllustration} alt="stepImage" />
                <StepTitle>
                  <MIFormattedText label={fundingSourceStepTitle} />
                </StepTitle>
                <StepSubTitle>
                  <MIFormattedText label={fundingSourceStepSubTitle} />
                </StepSubTitle>
              </StepContainer>
              <ArrowStepContainer>
                <img src={arrow} alt="stepImage" width="40" />
              </ArrowStepContainer>
              <StepContainer>
                <img src={bankCheckIcon} alt="stepImage" />
                <StepTitle>
                  <MIFormattedText label="firstTimeNew.step.title2" />
                </StepTitle>
                <StepSubTitle>
                  <MIFormattedText label="firstTimeNew.step.subTitle2" />
                </StepSubTitle>
                <StepHint>
                  <MIFormattedText label="firstTimeNew.step.subTitle2Hint" />
                </StepHint>
              </StepContainer>
            </StepsContainer>
            <ButtonContainer>
              <MIButton
                label="firstTimeNew.button"
                variant={CONSTS.BUTTON_VARIANT.PRIMARY}
                onClick={onRegister}
                isProcessing={isLoading}
              />
            </ButtonContainer>
          </FirstTimeContent>
          <CostDescription>
            <FirstTimeDescription>
              <MIFormattedText
                label="firstTimeNew.description"
                values={{
                  melio: (
                    <SmLink href="http://www.meliopayments.com" target="_blank">
                      <MIFormattedText label="firstTimeNew.melio" />
                    </SmLink>
                  ),
                  privacyPolicy: (
                    <SmLink href={site.config.agreementLinks.privacyPolicy} target="_blank">
                      <MIFormattedText label="firstTimeNew.privacyPolicy" />
                    </SmLink>
                  ),
                  termsOfService: (
                    <SmLink href={site.config.agreementLinks.termsOfService} target="_blank">
                      <MIFormattedText label="firstTimeNew.termsOfService" />
                    </SmLink>
                  ),
                  QBOnlineData: (
                    <SmLink href={site.config.agreementLinks.QBOnlineData} target="_blank">
                      <MIFormattedText label="firstTimeNew.QBOnlineData" />
                    </SmLink>
                  ),
                }}
              />
            </FirstTimeDescription>
          </CostDescription>
        </FirstTimeContentWrapper>
      </FirstTimeWrapper>
      <QBOFooterContainer />
    </FirstTimeContainer>
  );
};

const FirstTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white.lightBlue};
  color: ${(props) => props.theme.text.color.main};
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  font-size: 1.8rem;
  cursor: pointer;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    color: ${(props) => props.theme.text.color.green};
    margin-top: -0.3rem;
    margin-right: -8px;
    > i {
      font-size: 3.2rem;
    }
  }
`;

const FirstTimeWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 0.8rem;
  width: 80%;
  max-width: 85rem;
  position: relative;

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    width: auto;
    margin: 4.6rem 1rem 1rem 1rem;
  }
  margin: 4rem 0;
`;

const FirstTimeContent = styled.div`
  padding: 6rem 6rem 4rem;
  text-align: center;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    width: 100%;
    padding: 2.4rem 1.6rem 0 1.6rem;
    box-sizing: border-box;
    margin-bottom: 2rem;
  }
`;

const CostDescription = styled.div`
  background-color: rgba(251, 251, 253, 1);
  padding: 3rem 6rem 4rem 6rem;
  text-align: center;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    padding: 2.2rem 1.6rem 2rem 1.6rem;
  }
`;

const HowItWorksTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  padding-bottom: 2rem;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    width: 100%;
    box-sizing: border-box;
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 4rem;
  align-items: center;
  > div:nth-child(2) {
    margin-bottom: 9.8rem;
  }

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    flex-direction: column;
    > div:nth-child(2) {
      padding-top: 2.5rem;
      margin-bottom: 0rem;
    }
    padding-bottom: 3.4rem;
    box-sizing: border-box;
  }
`;

const ArrowStepContainer = styled.div`
  width: 5.4rem;
  margin: 0 2rem 0 2rem;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    display: none;
  }
`;

const StepContainer = styled.div`
  min-width: 32.9rem;
  align-self: flex-start;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    align-self: center;
    > img {
      margin-top: 0.8rem;
    }
    &:last-child {
      margin-top: 2.6rem;
    }
  }
`;

const StepTitle = styled.div`
  font-size: 2rem;
  line-height: 2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin: 1rem 0 1rem 0;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    margin-top: 0.7rem;
  }
`;

const StepSubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  line-height: 2rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  margin: 0 0 1rem;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    box-sizing: border-box;
    font-weight: ${(props) => props.theme.text.weight.regular};
  }
`;

const StepHint = styled.div`
  margin-top: 2rem;
  font-size: 1.2rem;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    margin-top: 1.7rem;
  }
`;

const FirstTimeDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.text.color.subtitle};
  > a {
    color: ${(props) => props.theme.text.color.link};
    font-weight: ${(props) => props.theme.text.weight.regular};
    font-size: 1rem;
  }
`;

const SmLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.text.color.link};
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    button {
      width: 100%;
    }
  }
`;

const FirstTimeContentWrapper = styled.div<{ isHidden?: boolean }>`
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
`;
