import React from 'react';
import styled, { css } from 'styled-components';
import { Image } from '@melio/billpay-design-system';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';

const AddAnotherMethodOption = styled(MIInlineLink)`
  font-size: 1.4rem;
  line-height: 2.1rem;
  width: 100%;
  padding: 1rem 2rem;
  text-align: left;
  height: inherit;
`;

const AddAnotherRadioMethodOption = styled(AddAnotherMethodOption)<{ $hideShowMore?: boolean }>`
  padding: 0;
  ${(props) => props.theme.components?.MethodsList?.AddAnotherRadioMethodOption}
  ${(props) => props.$hideShowMore && `visibility: hidden`}
`;

const MethodOptionIcon = styled.i<{ disabled?: boolean }>`
  height: 4rem;
  width: 4rem;
  flex-basis: 4rem;
  flex-grow: 0;
  flex-shrink: 0;

  font-size: 4rem;
  align-self: flex-start;
  color: ${(props) =>
    props.disabled ? props.theme.colors.dark.translucent3 : 'rgba(107,108,114,1)'};
  border: none;
  margin-right: 2rem;
`;

const MethodOptionImage = styled(Image)<{ isExpired?: boolean }>`
  width: 4rem;
  flex-basis: 4rem;
  flex-grow: 0;
  flex-shrink: 0;
  height: inherit;
  max-height: 4rem;
  margin-right: 2rem;
  ${(props) =>
    props.$isExpired &&
    css`
      filter: grayscale(100%);
      opacity: 0.3;
    `}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

const MethodOptionLogo = ({ icon, imageSrc, alt, disabled = false, isExpired = false }) => {
  if (imageSrc) {
    return (
      <MethodOptionImage
        src={imageSrc}
        alt={alt}
        disabled={!isExpired && disabled}
        $isExpired={isExpired}
        fallback={<MethodOptionIcon className={icon} disabled={disabled} />}
      />
    );
  }

  if (icon) {
    return <MethodOptionIcon className={icon} disabled={disabled} />;
  }

  return null;
};

const MethodOptionDescription = styled.div<{
  disabled?: boolean;
  isExpired?: boolean;
}>`
  color: ${(props) => {
    if (props.disabled && !props.isExpired) {
      return props.theme.colors.dark.translucent3;
    }

    return props.theme.colors.ds.gray[200];
  }};
  font-weight: ${(props) => props.theme.text.weight.regular};
  text-overflow: ellipsis;
  text-align: left;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme.components?.MethodElements?.MethodOptionDescription}
`;

const MethodOptionOnTopDescription = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  text-overflow: ellipsis;
  text-align: left;
  ${(props) => props.theme.text.fontType.hint};
`;

const MethodOptionLabel = styled.div<{ disabled?: boolean }>`
  line-height: 2.2rem;
  color: ${(props) =>
    props.disabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.main};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.components?.MethodElements?.MethodOptionLabel}
`;

export {
  MethodOptionIcon,
  MethodOptionImage,
  MethodOptionLogo,
  MethodOptionDescription,
  MethodOptionOnTopDescription,
  MethodOptionLabel,
  AddAnotherMethodOption,
  AddAnotherRadioMethodOption,
};
