import React from 'react';
import { RecordOf } from 'immutable';
import styled from 'styled-components';

import { MIFormattedText } from 'src/app/utils/formatting';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { CARD_TYPES, FUNDING_SOURCE_ORIGIN, FAILED_PAYMENT_TYPE } from 'src/app/utils/consts';
import { BillType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentInfoText,
  EditElement,
  PaymentInfoHint,
} from 'src/app/components/layout/PaymentActivityElements';
import {
  getPaymentById,
  isReturnedCheckPayment,
  isUndepositedPayment,
} from 'src/app/utils/payments';
import {
  FundingSourceTypesEnum,
  CardTypeEnum,
  FundingSourceOrigins,
} from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import FundingSourceIcon from './FundingSourceIcon';
import FundingSourceDescription from './FundingSourceDescription';
import Tag from './Tag';

type Props = {
  fundingSource: FundingSource;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  onEdit: () => void;
  qbCashState?: QBCashStateType;
};

const PayBillPaymentActivityFundingSourceInfo = ({
  fundingSource,
  bill,
  qbCashState,
  onEdit,
  payment,
}: Props) => {
  let label = 'payBillPaymentActivity.fundingSource.achLabel';

  if (fundingSource.fundingType === FundingSourceTypesEnum.CARD) {
    label =
      fundingSource.cardAccount?.cardType === CardTypeEnum.CREDIT
        ? 'payBillPaymentActivity.fundingSource.creditCardLabel'
        : 'payBillPaymentActivity.fundingSource.debitCardLabel';
  }

  if (fundingSource.origin === FundingSourceOrigins.QBCASH) {
    label = 'bills.form.paymentActivity.scheduledBill.scheduleMethodAchQBCash';
  }

  const hint = isManualBankAccountNotVerified(fundingSource)
    ? 'payBillPaymentActivity.fundingSource.unverifiedBankAccountHint'
    : '';

  const originPayment = getPaymentById(bill.payments, payment.id);
  const canEdit =
    payment?.metadata?.failedType !== FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER &&
    !isReturnedCheckPayment(payment) &&
    !isUndepositedPayment(payment);
  const isUpdated =
    originPayment &&
    payment?.metadata?.failedType &&
    (fundingSource?.id !== originPayment?.fundingSourceId ||
      originPayment?.fundingSource?.plaidAccount?.plaidItemId !==
        fundingSource?.plaidAccount?.plaidItemId) &&
    !isReturnedCheckPayment(payment) &&
    !isUndepositedPayment(payment);

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <FundingSourceIcon fundingSource={fundingSource} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodContainer>
              <PaymentMethodText>
                <MIFormattedText label={label} />
                {fundingSource.origin === FundingSourceOrigins.QBCASH && isUpdated && (
                  <QBCashTagWrapper>
                    <Tag label="payBillPaymentActivity.updated" />
                  </QBCashTagWrapper>
                )}
              </PaymentMethodText>
            </PaymentMethodContainer>
            <PaymentInfoText isQBCash={fundingSource.origin === FundingSourceOrigins.QBCASH}>
              <FundingSourceDescription
                bill={bill}
                fundingSource={fundingSource}
                qbCashState={qbCashState}
              />
              {isUpdated && fundingSource.origin !== FundingSourceOrigins.QBCASH && (
                <Tag label="payBillPaymentActivity.updated" />
              )}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {canEdit && <EditElement onEdit={onEdit} />}
      </PaymentActivityMainContainer>
      {hint && (
        <PaymentInfoHint>
          <MIFormattedText label={hint} />
        </PaymentInfoHint>
      )}
    </PaymentActivityContainer>
  );
};

const QBCashTagWrapper = styled.span`
  font-size: ${({ theme }) => theme?.text?.size?.regular};
`;

export default PayBillPaymentActivityFundingSourceInfo;
