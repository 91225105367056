import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIMoney from 'src/app/components/common/MIMoney';

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;
  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;

export const TotalAmount: React.FC<React.ComponentProps<typeof MIMoney>> = styled(MIMoney).attrs(
  () => ({
    size: 'large',
  })
)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

type FieldSubTitleProps = {
  partialPaymentsEnabled?: boolean;
};
export const FieldSubTitle = ({ partialPaymentsEnabled = false }: FieldSubTitleProps) => (
  <SubTitle>
    <MIFormattedText
      label={
        partialPaymentsEnabled
          ? 'batchPayment.sidePanel.paymentAmount'
          : 'batchPayment.sidePanel.total'
      }
    />
  </SubTitle>
);
