import moment from 'moment';
import { OperatingSystemEnum, TaxIdEnum } from 'src/app/version-2/model/enums';

import { RoleLiteral, UserContextType } from './types';

export function isMelioUser(email = '') {
  return email.includes('meliopayments.com');
}

export function isLoginFromForest() {
  const referrer = document.referrer || '';

  return referrer.includes('forestadmin.com');
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return OperatingSystemEnum.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return OperatingSystemEnum.IOS;
  }

  return OperatingSystemEnum.UNKNOWN;
}

export function isFreeEmailDomain(email) {
  const domain = email.substring(email.lastIndexOf('@') + 1);
  const FREE_EMAIL_DOMAINS = [
    'aol.com',
    'att.net',
    'charter.net',
    'comcast.net',
    'facebook.com',
    'gamil.com',
    'gmail.com',
    'googlemail.com',
    'hotmail.com',
    'icloud.com',
    'live.com',
    'mac.com',
    'me.com',
    'msn.com',
    'outlook.com',
    'usa.com',
    'yahoo.com',
    'yandex.com',
    'ymail.com',
  ];

  if (!domain) {
    return true;
  }

  return FREE_EMAIL_DOMAINS.indexOf(domain) !== -1;
}

export function getUserOrganization(user, orgId) {
  return user?.userOrganizations?.find((o) => o.organizationId === orgId);
}

export function getUserRole(user, orgId): RoleLiteral {
  const userOrganization = getUserOrganization(user, orgId);

  return userOrganization?.role;
}

export const isValidDateOfBirth = (dateOfBirth) => {
  const date = moment(dateOfBirth, ['MM/DD/YYYY'], true);

  return date.isValid();
};

export const formatDateOfBirth = (dateOfBirth) =>
  moment(dateOfBirth, ['MM/DD/YYYY']).format('YYYY-MM-DD');

export const hasDateOfBirthTaxType = (taxType: TaxIdEnum | undefined): boolean =>
  taxType === TaxIdEnum.SSN || taxType === TaxIdEnum.ITIN;

export const getFullName = (user: UserContextType) =>
  [user?.firstName || '', user?.lastName || ''].join(' ').trim();
