import first from 'lodash/first';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import billsStore from 'src/app/modules/bills/bills-store';
import { NOTIFICATION_VARIANT, PAGINATION } from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import { IntuitBankAccountType } from 'src/app/utils/types';
import intuitBankAccountsStore from 'src/app/modules/intuit-bank-accounts/intuit-bank-accounts-store';
import { useModal } from 'src/app/helpers/react/useModal';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { useMarkAsPaidBillsMetadata } from 'src/app/pages/qb-dashboard/hooks/useMarkAsPaidBillsMetadata';
import { getDashboardItemId } from 'src/app/pages/qb-dashboard/utils';
import { BatchMarkedAsPaidResponse } from 'src/app/pages/qb-dashboard/types';
import { ITEMS_PER_PAGE, QB_DASHBOARD_TAB_STATUS } from '../consts';
import { QBDashboardMarkAsPaidModal } from '../components/QBDashboardMarkAsPaidModal';
import { useRedirectToDashboard } from './useRedirectToDashboard';

type Response = [() => void, React.ReactNode];

const useBatchMarkAsPaidAction = (billIds: string[]): Response => {
  const orgId = useSelector(getOrgId);
  const billsActions = useStoreActions(billsStore);
  const site = useSiteContext();
  const createOrigin = site.createOrigin.pay.payment;

  const isProcessing = (
    useSelector(billsStore.selectors.batchMarkAsPaid.status(billIds)) as { loading: boolean }
  )?.loading;
  const accounts: IntuitBankAccountType[] =
    useSelector((state) =>
      intuitBankAccountsStore.selectors.list.value(state, {
        orgId,
      })
    ) || [];
  const options = accounts.map((account) => ({
    value: account.id,
    label: account.name,
  }));
  const [billsMetadata] = useMarkAsPaidBillsMetadata({ billIds });
  const { redirectToDashboard } = useRedirectToDashboard();

  const batchMarkAsPaidRequest = useCallback(
    async (intuitAccountId: string) => {
      const response: BatchMarkedAsPaidResponse = await billsActions.batchMarkAsPaid({
        orgId,
        items: billsMetadata.map(({ id, amount }) => ({ id, amount })),
        intuitAccountId,
        createOrigin,
      });

      analytics.trackAction('mark-as-paid-modal-submit', { billIds });
      const count = response?.payload?.ids?.length;

      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: 'paymentDashboard.markAsPaid.toastMessageBatch',
        textValues: { count },
      });

      const highlightedItemIds = response?.payload?.items
        ?.map(({ id, payments }) => getDashboardItemId(id, first(payments)?.id))
        ?.join(',');

      const redirectQuery = {
        start: PAGINATION.DEFAULT_START,
        status: QB_DASHBOARD_TAB_STATUS.PAID,
        limit: ITEMS_PER_PAGE,
        highlightedItemIds,
      };

      await redirectToDashboard({ redirectQuery });
    },
    [orgId, billIds, billsMetadata, billsActions, createOrigin, redirectToDashboard]
  );

  const [MarkAsPaidDialog, showDialog] = useModal(QBDashboardMarkAsPaidModal, {
    submit: async ({ intuitAccountId }) => batchMarkAsPaidRequest(intuitAccountId),
    isProcessing,
    options,
    modalName: 'mark-as-paid-modal',
    billsMetadata,
  });

  const batchMarkBillsAsPaid = useCallback(async () => {
    if (accounts.length === 1) {
      await batchMarkAsPaidRequest(accounts[0].id);
    } else {
      showDialog();
    }
  }, [accounts, batchMarkAsPaidRequest, showDialog]);

  return [batchMarkBillsAsPaid, MarkAsPaidDialog];
};

export default useBatchMarkAsPaidAction;
