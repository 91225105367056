import React from 'react';
import analytics from 'src/app/services/analytics';
import { DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { BillType, PaymentType } from 'src/app/utils/types';
import { isRecurringBill } from 'src/app/utils/bills';
import { CancelPaymentModal } from './CancelPaymentDialog.styles';

const eventPage = 'bill-activity';

type Props = {
  dismiss: () => void;
  bill: BillType;
  payment?: PaymentType;
  onCancelPayment: () => void;
  onCancelAllRecurringPayments: () => void;
  isCancelAllRecurringPayments?: boolean;
};

export const CancelPaymentDialog = ({
  dismiss,
  payment,
  bill,
  onCancelPayment,
  onCancelAllRecurringPayments,
  isCancelAllRecurringPayments,
}: Props) => {
  analytics.track(eventPage, 'delete-payment-dialog-opend');
  const onCancelAction = () => {
    analytics.track(eventPage, 'delete-payment-dialog-closed');
    dismiss();
  };

  let title = 'bills.form.paymentActivity.dialog.cancel.title';
  let subtitle = 'bills.form.paymentActivity.dialog.cancel.subtitle';
  let disclaimer = '';
  let action = onCancelPayment;
  let okButtonText = 'bills.form.paymentActivity.dialog.cancel.confirm';

  if (isCancelAllRecurringPayments) {
    title = 'bills.form.paymentActivity.dialog.cancelAllPayments.title';
    subtitle = 'bills.form.paymentActivity.dialog.cancelAllPayments.subtitle';
    disclaimer = 'bills.form.paymentActivity.dialog.cancelAllPayments.disclaimer';
    action = onCancelAllRecurringPayments;

    analytics.track('bills', 'delete-all-payment');
  } else if (isRecurringBill(bill)) {
    title = 'bills.form.paymentActivity.dialog.cancelRecurringPayment.title';
    subtitle = 'bills.form.paymentActivity.dialog.cancelRecurringPayment.subtitle';
    disclaimer = 'bills.form.paymentActivity.dialog.cancelRecurringPayment.disclaimer';
    okButtonText = 'bills.form.paymentActivity.dialog.cancelRecurringPayment.confirm';

    analytics.track('bills', 'delete-current-payment', {
      billId: bill.id,
      paymentId: payment?.id,
    });
  }

  return (
    <CancelPaymentModal
      type={DIALOG_TYPE.CONFIRM}
      variant={DIALOG_VARIANTS.ERROR}
      title={title}
      titleValues={{
        companyName: <strong>{bill.vendor.companyName}</strong>,
      }}
      subtitle={subtitle}
      subtitleValues={{
        companyName: <strong>{bill.vendor.companyName}</strong>,
        amount: (
          <strong>
            <MIFormattedCurrency value={payment?.amount || ''} />
          </strong>
        ),
      }}
      disclaimerText={disclaimer}
      okButtonText={okButtonText}
      onOkAction={action}
      onCancelAction={onCancelAction}
      cancelButtonText="bills.form.paymentActivity.dialog.cancel.cancelText"
    />
  );
};
