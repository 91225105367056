import { colors } from './colors';

import { prefix } from '../../consts';

export const shadows = {
  [prefix]: {
    sm: `0px 1px 4px ${colors.ds.gray['500']}`,
    md: `0px 2px 8px ${colors.ds.gray['500']}`,
    lg: `0px 4px 16px ${colors.ds.gray['500']}`,
    xl: `0px 6px 24px ${colors.ds.gray['500']}`,
    none: 'none',
  },
};
