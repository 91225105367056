import React, { useMemo, ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { RecordOf } from 'immutable';
import MITag from 'src/app/components/common/MITag';
import { isSameDay, DeliveryDate } from 'src/app/utils/dates';
import { MIFormattedText, MIFormattedDate, MIFieldOrEmpty } from 'src/app/utils/formatting';
import {
  CONSTS,
  BILL_STATUS,
  PAYMENT_STATUS,
  DELIVERY_DATE_FONT_TYPE,
  FAST_DELIVERY_TYPES,
  TAG_VARIANT,
} from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';
import { BillType, NotePlaceholderType } from 'src/app/utils/types';
import { useSiteContext } from 'src/app/hoc/withSiteContext';

import {
  getCheckDepositedDate,
  getLatestPayment,
  getFailedRefundPaymentDate,
  getNotePlaceholder,
} from 'src/app/utils/payments';

type QBRPaymentActivityInfoBlockType = {
  date: any;
  description: string;
  dateIcon: string;
  onEditDate?: () => void;
  secondDate?: any;
  secondDateIcon?: string;
  secondDateDescription?: string;
};

type Props = {
  bill: RecordOf<BillType>;
  isRequest?: boolean;
  scheduledData?: QBRPaymentActivityInfoBlockType;
  deliveryData?: any;
  onEditNote?: () => void;
  memo?: string;
  noteFromCustomer?: string;
  isRecurringBill?: boolean;
  isPayeeMode?: boolean;
  isSharedVendor?: boolean;
  notePlaceholder?: NotePlaceholderType;
};

const QBRPaymentActivity = ({
  bill,
  isRequest,
  scheduledData,
  deliveryData,
  memo,
  onEditNote,
  isRecurringBill,
  noteFromCustomer,
  notePlaceholder,
  isPayeeMode,
  isSharedVendor,
}: Props) => {
  const site = useSiteContext();
  const isBillPaid = bill?.status === BILL_STATUS.PAID;
  const billPayments = bill?.payments;
  const payment = useMemo(() => getLatestPayment(billPayments), [billPayments]);
  const isReviewMode = !!onEditNote;
  const isPaymentFailed = payment?.status === PAYMENT_STATUS.FAILED && !isReviewMode;
  const checkDepositedDate = useMemo(() => {
    if (isBillPaid && payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
      return getCheckDepositedDate(payment.transactions);
    }

    return null;
  }, [isBillPaid, payment]);
  const paymentFailedDate = useMemo(
    () => (isPaymentFailed ? getFailedRefundPaymentDate(payment) : null),
    [isPaymentFailed, payment]
  );

  return (
    <>
      {isPaymentFailed && paymentFailedDate && (
        <PaymentInfoContainer>
          <PaymentFailedContainer>
            <MIFormattedText
              label="bills.form.paymentActivity.failedPayment"
              values={{
                date: <MIFormattedDate date={paymentFailedDate} />,
              }}
            />
          </PaymentFailedContainer>
        </PaymentInfoContainer>
      )}
      <QBRPaymentActivitySource
        method={deliveryData?.method}
        icon={deliveryData?.icon}
        isEditable={!isSharedVendor && deliveryData?.isEditable}
        isBillPaid={isBillPaid}
        onEdit={deliveryData?.onEdit}
        formattedCheckSerial={deliveryData?.formattedCheckSerial}
        title={deliveryData?.title}
        methodType={deliveryData?.type}
        site={site}
      />
      {!isPaymentFailed && (
        <>
          <QBRPaymentActivityDate
            isBillPaid={isBillPaid}
            date={<MIFormattedDate date={scheduledData?.date} />}
            etaDate={
              <DeliveryDate
                date={deliveryData?.date}
                maxDate={deliveryData?.maxDate}
                fontType={DELIVERY_DATE_FONT_TYPE.MEDIUM}
              />
            }
            description={scheduledData?.description}
            icon={scheduledData?.dateIcon}
            onEditDate={scheduledData?.onEditDate}
            isRecurringBill={isRecurringBill}
            deliveryData={deliveryData}
            site={site}
          />
          {scheduledData?.secondDate && (
            <QBRPaymentActivityDate
              isBillPaid={isBillPaid}
              date={scheduledData?.secondDate}
              description={scheduledData?.secondDateDescription}
              icon={scheduledData?.secondDateIcon}
              site={site}
            />
          )}
          {checkDepositedDate && <CheckDeposited date={checkDepositedDate} />}
        </>
      )}
      {isPaymentFailed && paymentFailedDate && (
        <PaymentInfoContainer>
          <PaymentFailedContainer>
            <MIFormattedText
              label="bills.form.paymentActivity.failedPayment"
              values={{
                date: <MIFormattedDate date={paymentFailedDate} />,
              }}
            />
          </PaymentFailedContainer>
        </PaymentInfoContainer>
      )}
      {!isPayeeMode && (
        <NoteBoxRow onClick={onEditNote || undefined}>
          <NoteBoxColumn>
            <FieldName>
              <MIFormattedText
                label={
                  isRequest
                    ? 'requests.form.paymentActivity.noteToCustomer'
                    : 'bills.pay.confirm.addMemo'
                }
              />
            </FieldName>
            <FieldValue>
              <MIFieldOrEmpty
                value={isRequest ? noteFromCustomer : memo}
                label={getNotePlaceholder(
                  notePlaceholder as NotePlaceholderType,
                  deliveryData?.isEditable,
                  isRequest as boolean
                )}
                privateData
              />
            </FieldValue>
          </NoteBoxColumn>
          {onEditNote && <EditLink />}
        </NoteBoxRow>
      )}
      {(isRequest || isPayeeMode) && (
        <NoteBoxRow>
          <NoteBoxColumn>
            <FieldName>
              <MIFormattedText label="requests.form.paymentActivity.noteFromCustomer" />
            </FieldName>
            <FieldValue>
              <MIFieldOrEmpty
                privateData
                value={memo}
                label="requests.form.paymentActivity.emptyNoteFromCustomerLabel"
              />
            </FieldValue>
          </NoteBoxColumn>
        </NoteBoxRow>
      )}
    </>
  );
};

QBRPaymentActivity.defaultProps = {
  isRequest: false,
  scheduledData: {},
  deliveryData: {},
  onEditNote: null,
  memo: '',
  noteFromCustomer: '',
  isRecurringBill: false,
};

type QBRPaymentActivitySourceProps = {
  method: string;
  info?: string;
  hint?: string;
  icon?: ReactNode;
  isEditable?: boolean;
  isBillPaid?: boolean;
  onEdit?: () => void;
  formattedCheckSerial?: string;
  site?: any;
  title: string;
  qbCashBalance?: string;
  methodType?: string;
};

export const QBRPaymentActivitySource = ({
  method,
  formattedCheckSerial,
  info,
  isEditable,
  onEdit,
  isBillPaid,
  hint,
  title,
  methodType,
}: QBRPaymentActivitySourceProps) => (
  <PaymentActivityContainer
    onClick={isEditable ? onEdit : undefined}
    data-testid={isEditable ? 'edit-delivery-method' : 'qbcash-funding-source'}
  >
    <BlockTitleContainer>
      <MIFormattedText label={title} />
      {isEditable && <EditLink />}
    </BlockTitleContainer>
    <PaymentActivityMainContainer>
      <PaymentInfoContainer>
        <PaymentTextContainer>
          <PaymentMethodContainer>
            <MIFormattedText label={method} values={{ formattedCheckSerial }} />
          </PaymentMethodContainer>
          {methodType === CONSTS.DELIVERY_TYPE.VIRTUAL && (
            <>
              <PaymentMethodContainer>
                <MIFormattedText label="bills.pay.confirm.unilateralTitle" />
              </PaymentMethodContainer>
              <PaymentInfoText>
                <MIFormattedText label="bills.pay.confirm.unilateralSubtitle" />
              </PaymentInfoText>
            </>
          )}
          {info && <PaymentInfoText isBillPaid={isBillPaid}>{info}</PaymentInfoText>}
        </PaymentTextContainer>
      </PaymentInfoContainer>
    </PaymentActivityMainContainer>
    {hint && (
      <PaymentInfoHint>
        <MIFormattedText label={hint} />
      </PaymentInfoHint>
    )}
  </PaymentActivityContainer>
);

QBRPaymentActivitySource.defaultProps = {
  hint: null,
};

type QBRPaymentActivityDateProps = {
  date: ReactElement;
  etaDate?: ReactElement;
  icon?: string;
  description?: string;
  additionalHint?: any;
  isBillPaid: boolean;
  isRecurringBill?: boolean;
  deliveryPreference?: string;
  deliveryDate?: Date;
  onEditDate?: () => void;
  site: any;
  deliveryData?: any;
};

const QBRPaymentActivityDate = ({
  date,
  etaDate,
  description,
  isBillPaid,
  onEditDate,
  additionalHint = {},
  isRecurringBill,
  deliveryDate,
  deliveryPreference = '',
  deliveryData,
}: QBRPaymentActivityDateProps) => {
  const isClickable = onEditDate && !isRecurringBill;

  return (
    <PaymentActivityContainer onClick={isClickable ? onEditDate : undefined}>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={description || ''} />
            </PaymentMethodText>
            <PaymentMethodContainer isBillPaid={isBillPaid}>
              {deliveryDate && isSameDay(deliveryDate) && (
                <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />
              )}
              <MIFormattedText
                label={
                  deliveryDate
                    ? 'bills.pay.date.deliveryOptions.fastAchDates.free'
                    : 'bills.pay.date.deliveryOptions.fastAchDates.date'
                }
                values={{ date, etaDate }}
              />
              {FAST_DELIVERY_TYPES.includes(deliveryPreference) && (
                <FastTag
                  label="bills.pay.date.deliveryOptions.price.fastTag"
                  variant={TAG_VARIANT.BRAND}
                />
              )}
            </PaymentMethodContainer>
            <PaymentInfoText>
              <MIFormattedText label="bills.pay.confirm.etaDate" values={{ etaDate }} />
              {deliveryData?.type === CONSTS.DELIVERY_TYPE.VIRTUAL && (
                <AdditionalDescription>
                  <MIFormattedText
                    label="qbr.success.noteVirtual"
                    values={{ scheduledDate: date, etaDate }}
                  />
                </AdditionalDescription>
              )}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {isClickable && <EditLink />}
      </PaymentActivityMainContainer>
      {additionalHint && additionalHint.label && (
        <AdditionalHint>
          <MIFormattedText label={additionalHint.label} values={additionalHint.values} />
        </AdditionalHint>
      )}
    </PaymentActivityContainer>
  );
};

type QBRCheckDepositedProps = {
  date: Date;
};

const CheckDeposited = ({ date }: QBRCheckDepositedProps) => (
  <CheckDepositedContainer>
    <CheckDepositedLabel>
      <MIFormattedText label="bills.form.paymentActivity.checkDeposited" />
    </CheckDepositedLabel>
    <CheckDepositedDate>
      <MIFormattedText
        label="bills.form.paymentActivity.checkDepositedDate"
        values={{
          date: <MIFormattedDate date={date} timeZone="UTC" />,
        }}
      />
    </CheckDepositedDate>
  </CheckDepositedContainer>
);

const baseContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

const EditLink = styled.i.attrs({ className: 'icon-next-page-icon' })`
  font-size: 2rem;
  color: ${(props) => props.theme.colors.icon.grey};
  margin-right: 0.4rem;
  cursor: pointer;
  align-self: flex-start;
`;

export const PaymentIcon = styled.i<{ isBillPaid?: boolean }>`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  > img {
    height: 2.4rem;
  }

  > i {
    font-size: 2.4rem;
    color: ${(props) =>
      props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentIcon}
`;

const PaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  border-bottom: 0.05rem solid ${(props) => props.theme.colors.border.light};
`;

const PaymentActivityMainContainer = styled.div`
  display: flex;
  padding-right: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentActivityMainContainer}
`;

const PaymentInfoContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoContainer}
`;

const PaymentTextContainer = styled.div`
  ${baseContainerStyles}
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentTextContainer}
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobile} {
    flex-direction: column;
  }
  ${(props) => props.theme.text.fontType.regular};
  font-weight: ${(props) => props.theme.text.weight.medium};
  color: ${(props) => props.theme.text.color.main};
`;

export const PaymentMethodText = styled.div`
  color: ${(props) => props.theme.text.color.darkGrey};
  ${(props) => props.theme.text.fontType.medium};
  margin-bottom: 0.4rem;
`;

export const PaymentInfoText = styled.div<{ isBillPaid?: boolean }>`
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.darkGrey};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.darkGrey};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: start;
`;

export const PaymentInfoHint = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  margin-left: 4.5rem;
  margin-right: 0.5rem;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoHint}
`;

export const AdditionalHint = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  margin: 0.8rem 0.5rem 0 4.5rem;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.AdditionalHint}
`;

const BlockTitleContainer = styled.h5`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.text.color.subtitle};
  margin-bottom: 0.2rem;
  margin-top: 0;
  text-transform: capitalize;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
`;

const PaymentFailedContainer = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.colors.failure.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 2rem;
`;

const NoteBoxRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 2rem 0;
  word-break: break-all;
  ${(props) => props.theme?.components?.PaymentActivity?.NoteBoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  margin: 0.6rem 0 0rem;
  ${(props) => props.theme?.components?.PaymentActivity?.BoxColumn}
`;

const NoteBoxColumn = styled(BoxColumn)`
  margin-top: 0;
  ${(props) => props.theme?.components?.PaymentActivity?.NoteBoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.PaymentActivity?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.darkGrey};
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.medium};
  ${(props) => props.theme.text.fontType.regular};
  overflow-wrap: anywhere;
  word-break: break-all;
  white-space: pre-wrap;
  margin-top: 0.4rem;
`;

const AdditionalDescription = styled.div`
  ${(props) => props.theme.text.fontType.small};
  margin-top: 1.2rem;
  > span {
    ${(props) => props.theme.text.fontType.small};
  }
`;

const CheckDepositedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 0.2rem;
`;

const CheckDepositedLabel = styled.div`
  color: ${(props) => props.theme.colors.white.opaque};
  background-color: ${(props) => props.theme.colors.success.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  border-radius: 0.4rem;
  padding: 0 0.8rem;
  text-transform: uppercase;
  ${(props) => props.theme.text.fontType.small};
`;

const CheckDepositedDate = styled.div`
  ${baseTextStyles}
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  margin-left: 1rem;
`;

const FastTag = styled(MITag)`
  margin-left: 0.6rem;
  vertical-align: middle;
  font-size: 0.8rem;
  padding: 0 0.4rem;
  position: relative;
  top: -2px;
`;

export default QBRPaymentActivity;
