import {
  DeliveryEnum,
  FastFeeDeliveryEnum,
  FundingSourceTypesEnum,
} from 'src/app/version-2/model/enums';
import { RISK_BILL_AMOUNT } from 'src/app/version-2/pages/batch-bulk/model/consts/riskBillAmount.consts';
import { DeliveryOption } from '../../../model/dtos/deliveryOption';

import { isSameDay } from 'date-fns';

export const getFastDeliveryLabel = ({
  deliveryOptions,
  deductionDate,
}: {
  deliveryOptions?: DeliveryOption[];
  deductionDate: Date | string;
}) => {
  const fastACHDeliveryOption = deliveryOptions?.find(
    (option) => option.type === FastFeeDeliveryEnum.EXPEDITED_ACH
  );

  if (
    fastACHDeliveryOption &&
    isSameDay(fastACHDeliveryOption.deliveryDate, new Date(deductionDate))
  ) {
    return 'batchBulkPage.renderers.deliverySpeed.cells.times.beforeCutoffFastACH';
  }

  if (fastACHDeliveryOption) {
    return 'batchBulkPage.renderers.deliverySpeed.cells.times.afterCutoffFastACH';
  }

  return 'batchBulkPage.renderers.deliverySpeed.cells.times.fastCheck';
};

interface DeliveryDurationSpeedLabelMapParams {
  fundingSourceType?: FundingSourceTypesEnum;
  amount: number;
  deliveryType?: string;
}

type deliveryDurationSpeedLabelMapKey =
  | DeliveryEnum.ACH
  | DeliveryEnum.VIRTUAL_CARD
  | DeliveryEnum.RPPS
  | DeliveryEnum.INTERNATIONAL
  | 'default';

export const deliveryDurationSpeedLabelMap: Record<
  deliveryDurationSpeedLabelMapKey,
  (params: DeliveryDurationSpeedLabelMapParams) => string
> = {
  [DeliveryEnum.ACH]: ({
    fundingSourceType,
    deliveryType,
    amount,
  }: DeliveryDurationSpeedLabelMapParams) => {
    if (fundingSourceType === FundingSourceTypesEnum.ACH) {
      return amount >= RISK_BILL_AMOUNT.BIG_TRANSFER_THRESHOLD
        ? 'batchBulkPage.renderers.deliverySpeed.cells.times.achOver100K'
        : 'batchBulkPage.renderers.deliverySpeed.cells.times.achBelow100K';
    }

    if (fundingSourceType === FundingSourceTypesEnum.CARD) {
      return 'batchBulkPage.renderers.deliverySpeed.cells.fastCCToAch';
    }

    return `batchBulkPage.renderers.deliverySpeed.cells.times.${deliveryType}`;
  },
  [DeliveryEnum.VIRTUAL_CARD]: () =>
    'batchBulkPage.renderers.deliverySpeed.cells.times.virtualCardAch',
  [DeliveryEnum.RPPS]: ({
    fundingSourceType,
    deliveryType,
  }: DeliveryDurationSpeedLabelMapParams) => {
    if (fundingSourceType === FundingSourceTypesEnum.ACH) {
      return 'batchBulkPage.renderers.deliverySpeed.cells.times.rppsAch';
    }

    if (fundingSourceType === FundingSourceTypesEnum.CARD) {
      return 'batchBulkPage.renderers.deliverySpeed.cells.times.rppsCreditOrDebit';
    }

    return `batchBulkPage.renderers.deliverySpeed.cells.times.${deliveryType}`;
  },
  [DeliveryEnum.INTERNATIONAL]: ({ amount }: DeliveryDurationSpeedLabelMapParams) =>
    amount >= RISK_BILL_AMOUNT.BIG_TRANSFER_THRESHOLD
      ? 'batchBulkPage.renderers.deliverySpeed.cells.times.internationalOverBigTransferThreshold'
      : 'batchBulkPage.renderers.deliverySpeed.cells.times.internationalBelowBigTransferThreshold',
  default: ({ deliveryType }: DeliveryDurationSpeedLabelMapParams) =>
    `batchBulkPage.renderers.deliverySpeed.cells.times.${deliveryType}`,
};
