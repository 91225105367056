import React from 'react';
import toLower from 'lodash/toLower';
import { injectIntl, IntlShape } from 'react-intl';
import { RecordOf } from 'immutable';
import moment from 'moment';
import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import styled from 'styled-components';
import { BillType, PaymentType, QBCashStateType, NotePlaceholderType } from 'src/app/utils/types';
import { CONSTS, BILL_STATUS, DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import {
  getPaymentActivityScheduleData,
  getPaymentActivityDeliveryData,
  getFundingSourceLabel,
} from 'src/app/utils/bills';
import { QBRBillPaymentReview } from 'src/app/components/bill/QBRBillPaymentReview';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import { AreaLoader } from '@melio/billpay-design-system';
import failedToLoad from 'src/app/images/qbo/failed-to-load-002-icon.png';
import analytics from 'src/app/services/analytics';
import { melioClose } from 'src/app/utils/external-events';
import { DeliveryDate } from 'src/app/utils/dates';
import QBRLayoutPage from 'src/app/components/layout/QBRLayoutPage';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  onSubmit: (payment: RecordOf<PaymentType>) => void;
  onPrev: () => void;
  goExit: () => void;
  goEditDeliveryMethod: () => void;
  goEditFundingSource: () => void;
  goEditNote: () => void;
  goEditDate: () => void;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  selectedFundingSource: FundingSource | null;
  isLoading?: boolean;
  isRecurring: boolean;
  recurringBill: Record<string, any>;
  errorCode: string | null;
  onHideErrorMessageAlert: () => void;
  site: any;
  fee: PaymentFeeInfo | null;
  intl: IntlShape;
  headerLabel: string;
  title: string;
  titleValues?: Record<string, any>;
  relativeStep?: number;
  isFundingSourceEditable?: boolean;
  buttonLabel: string;
  qbCashState: QBCashStateType;
  notePlaceholder?: NotePlaceholderType;
  dmVirtualAccountEmail?: string;
};

class QBRPayBillConfirmPage extends React.PureComponent<Props> {
  static defaultProps = {
    isLoading: false,
    isFundingSourceEditable: true,
  };

  handleOnSubmit = () => {
    this.props.onSubmit(this.props.payment);
  };

  closeIframe = () => {
    analytics.track('pay-bill-confirm', 'close-integration');
    melioClose();

    if (this.props.goExit) {
      this.props.goExit();
    }
  };

  selectedDeliveryMethod = this.props.bill.getDeliveryMethodById(
    this.props.payment.deliveryMethodId
  );

  render() {
    const {
      payment,
      isLoading,
      bill,
      goExit,
      onPrev,
      errorCode,
      onHideErrorMessageAlert,
      goEditDeliveryMethod,
      goEditFundingSource,
      goEditNote,
      goEditDate,
      isRecurring,
      recurringBill,
      site,
      fee,
      selectedFundingSource,
      intl,
      headerLabel,
      relativeStep,
      titleValues,
      isFundingSourceEditable,
      buttonLabel,
      qbCashState,
      notePlaceholder,
      dmVirtualAccountEmail,
    } = this.props;

    // FIXME getPaymentActivityScheduleData and getPaymentActivityDeliveryData handles too much
    // logic inside itself and it's not suitable to use it now - need refactoring
    let scheduledData = getPaymentActivityScheduleData(
      BILL_STATUS.SCHEDULED,
      payment.scheduledDate,
      selectedFundingSource,
      isFundingSourceEditable,
      goEditFundingSource,
      goEditDate,
      isRecurring,
      site,
      qbCashState
    );
    const { occurrences, frequency } =
      bill.recurringBillId && bill.recurringBill ? bill.recurringBill : recurringBill;
    const dueDate = moment(bill.dueDate).format('YYYY-MM-DD');
    const deliveryEta = moment(payment.deliveryEta).format('YYYY-MM-DD');
    const isFirstPaymentLate = deliveryEta > dueDate;
    let deliveryData = getPaymentActivityDeliveryData(
      payment,
      BILL_STATUS.SCHEDULED,
      isRecurring,
      isFirstPaymentLate,
      bill.vendor.companyName,
      payment.deliveryEta,
      this.selectedDeliveryMethod,
      true,
      goEditDeliveryMethod
    );
    let description;

    if (isRecurring && this.selectedDeliveryMethod?.deliveryType !== CONSTS.DELIVERY_TYPE.VIRTUAL) {
      description = 'requests.form.paymentActivity.recurringPaymentETA';
    }

    if (description && description.length) {
      deliveryData = { ...deliveryData, description };
    }

    if (this.selectedDeliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
      deliveryData = { ...deliveryData, onEditDate: goEditDeliveryMethod };
    }

    if (bill.isVendorRequest()) {
      scheduledData = {
        ...scheduledData,
        description: 'guests.confirm.scheduledDate',
        secondDate: <DeliveryDate date={payment.deliveryEta} maxDate={payment.maxDeliveryEta} />,
        secondDateIcon: 'icon-eta-cal',
        secondDateDescription: 'guests.confirm.deliveryDate',
      };

      deliveryData = null;
    }

    const headerLabelValues = {
      amount: (
        <strong>
          <MIFormattedCurrency value={bill.totalAmount} />
        </strong>
      ),
      companyName: <strong>{bill.vendor.companyName}</strong>,
    };

    if (errorCode) {
      analytics.track('bill-error', 'payment-cannot-be-approved');

      return (
        <ErrorPage
          illustration={failedToLoad}
          title="bills.pay.confirm.error.approvingPaymentTitle"
          subtitle="bills.pay.confirm.error.approvingPaymentSubtitle"
          buttonAction={this.closeIframe}
          buttonLabel="bills.pay.confirm.error.approvingPaymentCTA"
        />
      );
    }

    if (!bill.id) {
      return <AreaLoader />;
    }

    return (
      <QBRLayoutPage
        headerLabel={headerLabel}
        headerLabelValues={headerLabelValues}
        titleValues={titleValues}
        goExit={goExit}
        relativeStep={relativeStep}
        onPrev={onPrev}
        onNext={this.handleOnSubmit}
        isLoading={isLoading}
        innerSize={50}
        nextLabel={buttonLabel}
        ctaVariant={CONSTS.BUTTON_VARIANT.PAY}
        fullWidthCTA
        showFooter
      >
        {errorCode && (
          <QBODialog
            type={DIALOG_TYPE.ALERT}
            variant={DIALOG_VARIANTS.ERROR}
            title="bills.pay.confirm.error.title"
            subtitle={
              intl.messages[`server.${errorCode}`] ? `server.${errorCode}` : 'server.Network Error'
            }
            onCancelAction={onHideErrorMessageAlert}
            cancelButtonText="bills.pay.confirm.error.CTA"
          />
        )}
        <QBRBillPaymentReview
          bill={bill}
          companyName={bill.vendor.companyName}
          memo={payment.note}
          scheduledData={scheduledData as any}
          deliveryData={deliveryData as any}
          onEditNote={goEditNote}
          occurrences={occurrences}
          frequency={frequency}
          isRecurringBill={isRecurring}
          dueDate={recurringBill.dueDate}
          fee={fee}
          notePlaceholder={notePlaceholder}
          dmVirtualAccountEmail={dmVirtualAccountEmail}
        />
        <ConfirmAgreements>
          <MIFormattedText
            label="bills.pay.confirm.confirmTerms"
            values={{
              amount: <MIFormattedCurrency value={bill.totalAmount} />,
              // eslint-disable-next-line max-len
              type: (
                <MIFormattedText label={getFundingSourceLabel(selectedFundingSource)}>
                  {toLower}
                </MIFormattedText>
              ),
              date: <MIFormattedDate date={scheduledData.date} />,
            }}
          />
        </ConfirmAgreements>
      </QBRLayoutPage>
    );
  }
}

export default withSiteContext()(injectIntl(QBRPayBillConfirmPage));
const ConfirmAgreements = styled.div`
  text-align: center;
  color: ${(props) => props.theme.text.color.darkGrey};
  padding: 2.4rem 0 1.6rem 0;
  ${(props) => props.theme.text.fontType.small};
`;
