import moment from 'moment';

import { BillFrequencyEnum } from 'src/app/version-2/model/enums';
import { VendorType, IntuitBankAccountResponseType } from 'src/app/utils/types';
import { MODEL_KEYS } from './hooks/useBillModalState';

type VendorResponse = {
  code: string;
  message: string;
  objects: Record<any, any>;
  totalCount: number;
};

export const extractAndMapVendorOptions = (response: VendorResponse) =>
  response?.objects.map((vendor: VendorType) => ({
    id: vendor.id.toString(),
    label: vendor.companyName,
    value: vendor.id.toString(),
  }));

export const mapCategoriesToOptions = ({ accounts }) =>
  accounts?.map((category: IntuitBankAccountResponseType) => ({
    id: category.Id,
    label: category.Name,
    value: category.Id,
  }));

export const extractAndMapInternationalVendors = (response: VendorResponse) => {
  const internationalVendors = response.objects.filter(
    (vendor) => vendor.deliveryMethods.filter((e) => e.deliveryType === 'international').length > 0
  );

  return internationalVendors.map((vendor: VendorType) => ({
    id: vendor.id.toString(),
    label: vendor.companyName,
    value: vendor.id.toString(),
  }));
};

export const mapBillToFormModel = (bill) => {
  if (!bill) return {};

  return {
    [MODEL_KEYS.VENDOR_ID]: bill[MODEL_KEYS.VENDOR_ID].toString() || null,
    [MODEL_KEYS.INTUIT_ACCOUNT_ID]: bill[MODEL_KEYS.INTUIT_ACCOUNT_ID] || '',
    [MODEL_KEYS.TOTAL_AMOUNT]: bill[MODEL_KEYS.TOTAL_AMOUNT] || '',
    [MODEL_KEYS.INVOICE_NUMBER]: bill[MODEL_KEYS.INVOICE_NUMBER] || '',
    [MODEL_KEYS.DUE_DATE]: bill[MODEL_KEYS.DUE_DATE] || '',
    [MODEL_KEYS.NOTE]: bill[MODEL_KEYS.NOTE] || '',
    [MODEL_KEYS.BILL_ID]: bill.id || null,
    [MODEL_KEYS.BALANCE]: bill[MODEL_KEYS.BALANCE] || bill[MODEL_KEYS.TOTAL_AMOUNT] || '',
  };
};

export const calcTotalPaymentsMade = (isEdit, totalAmount, balance) =>
  isEdit && totalAmount && balance && totalAmount - balance;

export const BILL_MODAL_FAILURE_TYPES = {
  VALIDATION: 'validation',
  API: 'api',
};

export const ANALYTICS_PAGE_NAME = 'bills-dashboard';

export const getRecurringDateNotice = ({
  frequency,
  dueDate,
}: {
  frequency: string;
  dueDate: Date;
}) => {
  let label: string;
  let specificDay: string;

  if (frequency === BillFrequencyEnum.WEEKLY) {
    specificDay = dueDate ? moment(dueDate).format('dddd').toString() : '';
    label = 'bills.form.recurring.recurringWeeklyHint';
  } else {
    specificDay = dueDate ? moment(dueDate).format('D').toString() : '';
    label = 'bills.form.recurring.recurringMonthlyHint';
  }

  return { label, specificDay };
};

export const scrollToDateIfNeeded = ({ isRecurring, validationErrors }) => {
  const shouldScrollToDate = !!(
    isRecurring &&
    validationErrors.dueDate &&
    Object.keys(validationErrors).length === 1
  );

  if (shouldScrollToDate) {
    setTimeout(() => document.getElementById('dueDate')?.scrollIntoView({ behavior: 'smooth' }));
  }
};
