import { Button as ChakraButton, ButtonProps as Props, forwardRef } from '@chakra-ui/react';

type ButtonProps = Omit<Props, 'variant' | 'isInvalid'> & {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'secondaryDestructive' | 'passivePrimary';
  sizes?: 'sm' | 'md' | 'lg';
  'data-testid'?: string;
};

export const Button = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const iconSpacing = ((): string => {
    if (props.size === 'lg') return '10px';
    return '4px';
  })();

  return <ChakraButton ref={ref} {...props} iconSpacing={iconSpacing} />;
});
