import React, { useEffect, useState } from 'react';
import toLower from 'lodash/toLower';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmationOrigin, QBCashStateType, VendorType } from 'src/app/utils/types';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { HANDLED_SYNC_PAYMENT_ERROR_CODES } from 'src/app/version-2/model/constants/syncPaymentErrorCode.consts';
import {
  DataOriginEnum,
  CreditCardFeePaymentEnum,
  FundingSourceOrigins,
} from 'src/app/version-2/model/enums';
import { CONSTS } from 'src/app/utils/consts';
import { getFundingSourceLabel } from 'src/app/utils/bills';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';
import { PaymentExceedsAvailableBalanceModal } from 'src/app/pages/bill/pay/components/PaymentExceedsAvailableBalanceModal';
import { AreaLoader } from '@melio/billpay-design-system';
import { getQBCashState } from 'src/app/redux/payBillWizard/selectors';
import { userActions } from 'src/app/version-2/modules/user/user.slice';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { useIsQbDashboardEnabled } from 'src/app/pages/bill/pay/hooks/useIsQBDashboardEnabled';
import organizationApi from 'src/app/services/api/organizations';
import { getCompanyInfo } from 'src/app/redux/user/selectors';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import { useJustPayCreatePayment } from 'src/app/pages/just-pay/hooks/useJustPayCreatePayment';
import {
  JustPayLayoutPageRelativeSteps,
  JustPayPaymentType,
} from 'src/app/pages/just-pay/justPayTypes';
import { NotificationCheckFee } from 'src/app/version-2/components/CheckFee/NotificationCheckFee';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import { isPaymentExceededQBCashBalance, convertFeeObject } from 'src/app/utils/payments';
import { useCheckRequiredLegalCompanyInfo } from 'src/app/modules/organizations/hooks/useCheckRequiredLegalCompanyInfo';
import { defaultFeeObject, calculateAmountWithFees } from 'src/app/utils/fee';
import { getFundingSourceType } from 'src/app/utils/funding-sources';
import { DEFAULT_DASHBOARD_REDIRECT_PARAMS } from 'src/app/pages/qb-dashboard/hooks/useGetDashboardListItemPaginationParams';

import { useEmailVendorOnEditCheckAddres } from 'src/app/pages/vendor/hooks/useEmailVendorOnEditCheckAddress';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { JustPayPaymentReview } from './JustPayPaymentReview';
import JustPayLayoutPage from '../../JustPayLayoutPage';
import locations from '../../justPayLocations';

type Props = {
  onNext(path: string): () => void;
  onExit(): void;
  onBack(): void;
  onError(): void;
};

export const JustPayReviewAndConfirm = ({ onNext, onExit, onBack, onError }: Props) => {
  const dispatch = useDispatch();
  const isQbDashboardEnabled = useIsQbDashboardEnabled();
  const { redirectToDashboard } = useRedirectToDashboard();
  const {
    fundingSource,
    payment,
    paymentStoreActions,
    payment: { trackingBillId },
    vendor,
  } = useJustPayStore() as {
    fundingSource: FundingSource;
    payment: JustPayPaymentType;
    paymentStoreActions: any;
    vendor: VendorType;
  };
  const { createPayment: createPaymentLogic } = useJustPayCreatePayment();
  const qbCashState: QBCashStateType = useSelector(getQBCashState);
  const [isQBCashWarningDialog, setIsQBCashWarningDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFeeLoading, setIsfeeLoading] = useState<boolean>(false);
  const [historyPush] = useHistoryWithOrgId();
  const companyInfo = useSelector(getCompanyInfo);
  const { checkRequiredLegalCompanyInfo } = useCheckRequiredLegalCompanyInfo();
  const [fee, setFee] = useState<Record<string, any> | null>(null);

  const [emailVendorOnPaymentSchedule] = useEmailVendorOnEditCheckAddres();

  const { amount } = payment;
  const amountWithFee = calculateAmountWithFees(amount, fee);

  useEffect(() => {
    getFeeApi();
  }, []);

  const getFeeApi = async () => {
    setIsfeeLoading(true);

    try {
      const orgId = payment.orgId?.toString();

      const { fees } = await organizationApi.getOrganizationFee(orgId, {
        paymentAmount: payment.amount,
        fundingSourceType: getFundingSourceType(fundingSource),
        deliveryMethodType: payment.deliveryMethodType,
        deliveryPreference: payment.suggestedDates?.deliveryPreferenceType,
        paymentOrigin: DataOriginEnum.INTUIT,
        feesPaidBy: CreditCardFeePaymentEnum.PAYOR,
        organizationId: orgId,
      });

      setIsfeeLoading(false);
      setFee(convertFeeObject(fees));
    } catch (e) {
      onError();
      setIsfeeLoading(false);
      setFee(defaultFeeObject);
    }
  };

  const goEditNote = () => {
    analytics.trackAction('edit-note', { trackingBillId });
    historyPush({ path: locations.create.noteToVendor });
  };

  const goEditDate = () => {
    analytics.trackAction('edit-date', { trackingBillId });
    historyPush({ path: locations.operations.selectDeductionDate });
  };

  const goEditFundingSource = () => {
    analytics.trackAction('edit-funding-source', { trackingBillId });
    historyPush({ path: locations.create.selectFundingSource });
  };

  const goEditDeliveryMethod = () => {
    analytics.trackAction('edit-delivery-method', { trackingBillId });
    historyPush({ path: locations.create.selectDeliveryMethod });
  };

  const goNext = async () => {
    const isMissingLegalInfo = !(await checkRequiredLegalCompanyInfo(companyInfo));

    if (isMissingLegalInfo) {
      analytics.trackAction('go-to-complete-legal-info', { trackingBillId });
      onNext(locations.create.completeLegalInfo)();

      return;
    }

    try {
      setIsLoading(true);

      const createdPayment = await createPaymentLogic(payment);
      let syncPaymentErrorCode;

      try {
        await organizationApi.runPaymentSync(payment.orgId, createdPayment.id);
      } catch (syncPaymentError) {
        syncPaymentErrorCode = (syncPaymentError as any)?.code;
        loggingApi.error(
          'JustPayReviewAndConfirm.goNext(): sync payment failed',
          syncPaymentErrorCode
        );

        if (!HANDLED_SYNC_PAYMENT_ERROR_CODES.includes(syncPaymentErrorCode)) {
          throw syncPaymentError;
        }
      }

      emailVendorOnPaymentSchedule({
        deliveryType: payment.deliveryMethodType,
        scheduledDate: payment.suggestedDates.scheduledDate,
        paymentId: createdPayment.id,
        contactEmail: vendor?.contactEmail,
      });

      dispatch(
        userActions.increaseCheckFeePaymentCount({
          deliveryMethod: { deliveryType: payment.deliveryMethodType } as any,
          fundingSource: fundingSource as any,
          deliveryPreference: payment.suggestedDates?.deliveryPreferenceType,
        })
      );

      analytics.trackAction('confirm-success', { paymentId: createdPayment.id, trackingBillId });

      if (isQbDashboardEnabled) {
        analytics.trackAction('go-to-dashboard', { trackingBillId });

        const state = {
          payment: {
            ...payment,
            createdPayment,
          },
          vendor,
          confirmationOrigin: ConfirmationOrigin.JUST_PAY,
          syncPaymentErrorCode,
        };

        paymentStoreActions.justPay.justPayWizard.clear();
        setIsLoading(false);

        redirectToDashboard({ redirectQuery: DEFAULT_DASHBOARD_REDIRECT_PARAMS, state });
      } else {
        paymentStoreActions.justPay.justPayWizard.update({ createdPayment, exitUrl: null });
        setIsLoading(false);

        onNext(locations.create.success)();
      }
    } catch (error) {
      analytics.trackAction('confirm-failure', { createPaymentError: error, trackingBillId });
      setIsLoading(false);
      onError();
    }
  };

  const handleQBCashOnSubmit = () => {
    if (
      isPaymentExceededQBCashBalance({ payment, qbCashState, fundingSource: fundingSource as any })
    ) {
      onSubmitQBCash();
    } else {
      analytics.trackAction('confirm-and-schedule', { trackingBillId });
      goNext();
    }
  };

  const onSubmitQBCash = () => {
    analytics.trackAction('qbcash-low-balance-confirm-open', { trackingBillId });
    setIsQBCashWarningDialog(true);
  };

  const onCloseQBCashModal = () => {
    analytics.trackAction('qbcash-low-balance-confirm-close', { trackingBillId });
    setIsQBCashWarningDialog(false);
  };

  const onSubmitAnyway = () => {
    analytics.trackAction('qbcash-low-balance-schedule-anyway', { trackingBillId });
    goNext();
    setIsQBCashWarningDialog(false);
  };

  const handleOnQBCashEditFundingSource = () => {
    analytics.trackAction('qbcash-low-balance-edit-funding-source', { trackingBillId });
    goEditFundingSource();
  };

  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={payment.amount?.toString() as string} />
      </strong>
    ),
    companyName: <strong>{payment.vendor?.companyName}</strong>,
  };

  const activityActions = {
    onEditDate: goEditDate,
    onEditDeliveryMethod: goEditDeliveryMethod,
    onEditFundingSource: goEditFundingSource,
    onEditNote: goEditNote,
  };

  if (!(fee as any) || isFeeLoading) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <>
      {isQBCashWarningDialog && (
        <PaymentExceedsAvailableBalanceModal
          title="bills.pay.confirm.balanceConfirm.title"
          description="bills.pay.confirm.balanceConfirm.description"
          confirmText="bills.pay.confirm.balanceConfirm.confirm"
          cancelText="bills.pay.confirm.balanceConfirm.cancel"
          confirm={onSubmitAnyway}
          close={onCloseQBCashModal}
          edit={handleOnQBCashEditFundingSource}
        />
      )}
      <JustPayLayoutPage
        headerLabel="qbo.header.title"
        headerLabelValues={headerLabelValues}
        title="bills.pay.confirm.title"
        onNext={handleQBCashOnSubmit}
        onPrev={onBack}
        goExit={onExit}
        isLoading={isLoading}
        innerSize={50}
        nextLabel="bills.pay.confirm.action"
        ctaVariant={CONSTS.BUTTON_VARIANT.PAY}
        relativeStep={JustPayLayoutPageRelativeSteps.JustPayReviewAndConfirm}
        fullWidthCTA
        isQboFooter
      >
        <NotificationCheckFee
          deliveryMethod={{ deliveryType: payment.deliveryMethodType }}
          fundingSource={fundingSource}
          deliveryPreference={payment.suggestedDates?.deliveryPreferenceType}
        />
        <JustPayPaymentReview
          fee={fee as any}
          fundingSource={fundingSource as any}
          payment={payment as any}
          activityActions={activityActions as any}
          qbCashState={qbCashState}
          deliveryMethodType={payment.deliveryMethodType}
        />
        <ConfirmAgreements>
          <MIFormattedText
            label="bills.pay.confirm.confirmTerms"
            values={{
              amount: <MIFormattedCurrency value={amountWithFee?.toString() as string} />,
              type: (
                <MIFormattedText label={getFundingSourceLabel(fundingSource as any)}>
                  {!(fundingSource?.origin === FundingSourceOrigins.QBCASH) && toLower}
                </MIFormattedText>
              ),
              date: <MIFormattedDate date={payment.suggestedDates?.scheduledDate} />,
            }}
          />
        </ConfirmAgreements>
      </JustPayLayoutPage>
    </>
  );
};

const ConfirmAgreements = styled.div`
  text-align: center;
  color: #393a3d;
  margin: 2rem 0;
  ${(props) => props.theme.text.fontType.hint};
`;
