import { useState } from 'react';
import { useIntl } from 'react-intl';
import { IconType } from '@melio/billpay-design-system';
import { FundingSourceCard } from 'src/app/version-2/components';
import { FoundSourceCardProps } from 'src/app/version-2/model/objects/foundingSourceCard';
import { getPaymentMethodIcon } from 'src/app/version-2/utils/paymentMethods.utils';
import { CardsContainer } from './Plans.styles';

interface PlansProps {
  plans: Array<string>;
  onSelect: (plan: string) => void;
}

export const Plans = ({ plans = [], onSelect }: PlansProps) => {
  const { formatMessage } = useIntl();
  const int = useIntl();
  const [selected, setSelected] = useState<string>();

  const onClickHandler = (plan: string) => {
    setSelected(plan);
    onSelect(plan);
  };

  return (
    <CardsContainer>
      {plans.map((plan) => {
        const args: FoundSourceCardProps = {
          id: plan,
          icon: getPaymentMethodIcon(plan) as IconType,
          iconSize: plan === 'debit' ? 5.4 : 4,
          title: formatMessage({ id: `paymentMethods.${plan}.title` }),
          subtitle: formatMessage({ id: `paymentMethods.${plan}.subtitle` }),
          fee: formatMessage({ id: `paymentMethods.${plan}.fee` }),
          advantages: formatMessage({ id: `paymentMethods.${plan}.advantages` }).split(','),
          isSelected: selected === plan,
          className: `plans-lp-fs-card-${plan}`,
        };

        const comment = int.messages[`paymentMethods.${plan}.comment`];

        if (comment) {
          args.comment = String(comment);
        }

        return <FundingSourceCard key={args.id} {...args} onClick={() => onClickHandler(plan)} />;
      })}
    </CardsContainer>
  );
};
