import React from 'react';
import { MIFormattedDate } from 'src/app/utils/formatting';
import QBONotificationCard, {
  QBONotificationCardProps,
} from 'src/app/components/qbo/QBONotificationCard';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import { PaymentType } from 'src/app/utils/types';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';

type Props = { payment: PaymentType };

export const ScheduleRefundMessage = ({ payment }: Props) => {
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const isPOPS = !!payment?.voidChecks && payment?.voidChecks?.length > 0;
  const refundInitiatedDate = payment.metadata?.paymentStatusDates?.refundInitiated;
  const prefix = 'bills.form.paymentActivity';
  const getNotificationSubtitle = isRefundFlowFeature
    ? `${prefix}.scheduleRefundProcessingDescription${isPOPS ? 'Pops' : ''}`
    : `${prefix}.schedulePaymentDescription${isPOPS ? 'Pops' : ''}`;

  const notificationProps: QBONotificationCardProps = {
    type: NOTIFICATION_CARD_TYPES.INFO,
    showIcon: !isRefundFlowFeature,
    subtitle: {
      label: getNotificationSubtitle,
      values: {
        date: <MIFormattedDate date={refundInitiatedDate} />,
      },
    },
  };

  if (!isRefundFlowFeature) {
    notificationProps.title = { label: 'bills.status.paymentRefundScheduled.messageTitle' };
  }

  return <QBONotificationCard {...notificationProps} />;
};
