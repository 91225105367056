import React, { useState } from 'react';
import analytics from 'src/app/services/analytics';
import styled from 'styled-components';
import { ModalMessage as StyledModalMessage } from 'src/app/components/common/ModalMessage';
import { MIFormattedText } from 'src/app/utils/formatting';
import { PaymentType, BillType } from 'src/app/utils/types';
import { isEnterPressed } from 'src/app/utils/events';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { useVendorCollectEmailSubmit } from 'src/app/modules/vendors/vendor-email/hooks/useVendorCollectEmailSubmit';
import { VendorEmailField } from 'src/app/modules/vendors/vendor-email/components/VendorEmailField';
import { getPartialBillId } from 'src/app/utils/bills';

type Props = {
  dismiss: () => void;
  onSuccess: (contactEmail: string) => void;
  payment: PaymentType;
  bill: BillType;
  eventPage: string;
  subTitle?: string;
  emailLabel?: string;
};

export const CollectVendorEmailModalMessage = ({
  dismiss,
  onSuccess,
  payment,
  bill,
  eventPage,
  subTitle = 'bills.pay.collectVendorEmailModal.subTitle',
  emailLabel = 'bills.pay.collectVendorEmailModal.emailLabel',
}: Props) => {
  const [contactEmail, setContactEmail] = useState('');

  const vendorName = bill?.vendor?.companyName;
  const vendorId = bill?.vendor?.id;
  const pendingApproval = payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;

  const analyticsProps = {
    vendorId,
    screenName: 'success',
    vendorEmail: contactEmail,
    partialBillId: getPartialBillId(bill),
  };

  const handleCollectVendorEmailModalCancel = () => {
    analytics.track(eventPage, 'vendor-email-modal-save', analyticsProps);
    dismiss();
  };

  const { handleCollectVendorEmailModalSubmit, validationErrors, isProcessing } =
    useVendorCollectEmailSubmit({
      contactEmail,
      vendorId,
      deliveryType: payment.deliveryMethod.deliveryType,
      scheduledDate: payment.scheduledDate,
      paymentId: payment.id,
      onSubmitStart: () => {
        analytics.track(eventPage, 'vendor-email-modal-save', analyticsProps);
      },
      onValidationErrors: (validationErrors) => {
        analytics.track(eventPage, 'vendor-email-modal-save-validation-error', validationErrors);
      },
      onSubmitSuccess: () => {
        analytics.track(eventPage, 'vendor-email-modal-save-success', analyticsProps);
        dismiss();
        onSuccess(contactEmail);
      },
    });

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      handleCollectVendorEmailModalSubmit();
    }
  };

  return (
    <ModalMessage
      titleComponent={
        <>
          <div className="title">
            <MIFormattedText
              label="bills.pay.collectVendorEmailModal.title"
              values={{
                vendorName: <StyledVendorName>{vendorName}</StyledVendorName>,
              }}
            />
          </div>
          <div className="text">
            <MIFormattedText label={subTitle} values={{ vendorName }} />
          </div>
          <PendingNotification className="text">
            {pendingApproval && (
              <MIFormattedText label="bills.pay.collectVendorEmailModal.pending" />
            )}
          </PendingNotification>
        </>
      }
      contentComponent={
        <FormContainer className="collect-vendor-email-formContainer" onKeyDown={onKeyPressed}>
          <VendorEmailField
            label={emailLabel}
            value={contactEmail}
            onChange={({ value }) => setContactEmail(value)}
            validationError={validationErrors.contactEmail}
            autoFocus
            required
          />
        </FormContainer>
      }
      buttonLabel="bills.pay.collectVendorEmailModal.sendEmail"
      onButtonClick={handleCollectVendorEmailModalSubmit}
      onCloseClick={handleCollectVendorEmailModalCancel}
      isLoading={isProcessing}
      isButtonDisabled={!contactEmail}
    />
  );
};

const ModalMessage = styled(StyledModalMessage)`
  .modal-content-container {
    padding-left: 3.71rem;
    padding-right: 3.71rem;
    max-width: 50.6rem;

    .title {
      font-size: 2.4rem;

      span {
        font-size: 2.4rem;
      }
    }
  }

  & button {
    max-width: 47%;
  }
`;

const FormContainer = styled.div`
  width: 100%;

  &.collect-vendor-email-formContainer {
    .input-container {
      margin-bottom: 0 !important;
    }
  }

  #contactEmail {
    font-size: ${(props) => props.theme.text.size.regular};
  }
`;

const StyledVendorName = styled.span`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};

  ${(props) => props.theme?.pages?.PayBillSuccessPage?.StyledVendorName}
`;

const PendingNotification = styled.div`
  margin-top: 1rem;
`;
