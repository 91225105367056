import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  StyledQBOLayoutPage,
  inputContainerStyle,
  StyledLoader,
  containerStyle,
  loaderContainerStyle,
  spacierStyle,
  titleStyle,
  subtitleStyle,
} from './AuthCodeVerificationPage.styles';
import { Box, Flex } from '@melio/billpay-design-system';
import intercomService from 'src/app/services/intercom';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { useHasFeatureFlag } from 'src/app/hooks/useHasFeatureFlag';
import { useBreak } from 'src/app/hoc';
import { MICodeInput } from 'src/app/components/common/MICodeInput';
import { AreaLoader } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import { melioClose } from 'src/app/utils/external-events';
import { ReactComponent as BillsIcon } from 'src/app/images/qbo/bills.svg';
import { flagsStore } from 'src/app/modules/flags/flags-store';
import { Footer } from '../Footer';
import { Notification } from '../Notification';
import { useAuthCodeVerification, VerificationState } from '../../hooks/useAuthCodeVerification';
import { useAuthCodeVerificationAnalytics } from '../../hooks/useAuthCodeVerificationAnalytics';

const AuthCodeVerificationPage = () => {
  const { isMobile } = useBreak();
  const site = useSiteContext();
  const history = useHistory();
  const dispatch = useDispatch();

  const { isFeatureFlagEnabled } = useHasFeatureFlag();
  const isTestPeriod = isFeatureFlagEnabled('test2FARolloutPeriod');

  const enteredCode = useRef('');
  const [finishing, setFinishing] = useState<boolean>(false);

  const analytics = useAuthCodeVerificationAnalytics();

  useEffect(() => {
    dispatch(flagsStore.actions.set2FAStarted(true));
  }, []);

  const onCodeVerificationSuccess = () => {
    analytics.trackCodeEntered(true);

    dispatch(flagsStore.actions.set2FARequired(false));
    dispatch(flagsStore.actions.set2FAStarted(false));
    history.goBack();
  };

  const onCodeVerificationFailure = () => {
    analytics.trackCodeEntered(false);
  };

  const {
    processing,
    verificationState,
    resendState,
    email,
    resetVerificationState,
    requestCode,
    verifyCode,
  } = useAuthCodeVerification({
    siteName: site.name,
    onSuccess: onCodeVerificationSuccess,
    onFailure: onCodeVerificationFailure,
  });

  const handleResendCodeClick = () => {
    analytics.trackResendClicked();

    requestCode();
    resetVerificationState();
  };

  const handleContactSupportClick = () => {
    analytics.trackSupportClicked();

    intercomService.show();
  };

  const handleExitClick = () => {
    setFinishing(true);
    melioClose();
  };

  const handleCodeChange = (code: string) => {
    if (code.length === 4) {
      if (code !== enteredCode.current) {
        verifyCode(code);
      }
    } else if (
      ![VerificationState.FailedBlocked, VerificationState.FailedMaxAttempts].includes(
        verificationState
      )
    ) {
      resetVerificationState();
    }

    enteredCode.current = code;
  };

  const isInputValid = ![
    VerificationState.Failed,
    VerificationState.FailedMaxAttempts,
    VerificationState.FailedBlocked,
  ].includes(verificationState);

  return (
    <StyledQBOLayoutPage
      contentWrapperMode="success"
      innerSize={50}
      goExit={handleExitClick}
      qboFooter={isMobile ? <Box __css={spacierStyle} /> : null}
    >
      {finishing && <AreaLoader placement="melioMe" />}
      <Flex __css={containerStyle}>
        <BillsIcon width="12.2rem" height="12.2rem" />
        <Box __css={titleStyle}>
          <MIFormattedText label="guests.codeVerification.title" />
        </Box>
        <Box __css={subtitleStyle}>
          <MIFormattedText
            label={
              isTestPeriod
                ? 'guests.codeVerification.subtitle.soft'
                : 'guests.codeVerification.subtitle.regular'
            }
            values={{ email }}
          />
        </Box>

        <Notification verificationState={verificationState} resendState={resendState} />

        <Box __css={inputContainerStyle}>
          {processing || verificationState === VerificationState.Verified ? (
            <Box __css={loaderContainerStyle}>
              <StyledLoader />
            </Box>
          ) : (
            <MICodeInput
              type="number"
              pattern="\d*"
              isValid={isInputValid}
              onChange={handleCodeChange}
            />
          )}
        </Box>

        <Footer
          verificationState={verificationState}
          resendState={resendState}
          onResendCodeClick={handleResendCodeClick}
          onContactSupportClick={handleContactSupportClick}
        />
      </Flex>
    </StyledQBOLayoutPage>
  );
};

export { AuthCodeVerificationPage };
