import { QuickPayment } from 'src/app/modules/payments/quickpay-slice';
import { DbAnalyticsTraitsEnum } from 'src/app/version-2/model/enums';
import { deleteRequest, postRequest, putRequest, fetchRequest } from './api';
import analytics from '../analytics';
import { convertLimit, convertStart } from '../../utils/pagination-utils';
import { PaymentFilters } from './payments';

const quickPaymentsApi = {
  list({ orgId, filters = {} }: { orgId: string; filters: PaymentFilters }) {
    const url = `/orgs/${orgId}/payments`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },
  update({ orgId, ...params }: { orgId: string } & QuickPayment) {
    const url = `/orgs/${orgId}/payments/${params.id}`;

    return putRequest(url, params).then((res) => res.object);
  },
  create({ orgId, ...params }: { orgId: string } & QuickPayment) {
    const url = `/orgs/${orgId}/payments`;

    analytics.setTraits({ [DbAnalyticsTraitsEnum.FIRST_TIME_PAY]: true });

    return postRequest(url, params).then((data) => data.object);
  },
  delete({ orgId, id }) {
    const url = `/orgs/${orgId}/payments/${id}`;

    return deleteRequest(url);
  },

  createWithBill({ orgId, ...params }: { orgId: string } & QuickPayment) {
    const url = `/orgs/${orgId}/payments/create-with-bill`;

    return postRequest(url, params).then((res) => res.object);
  },
  fetch({ orgId, id }) {
    const url = `/orgs/${orgId}/payments/${id}`;

    return fetchRequest(url).then((res) => res.object);
  },
};

export default quickPaymentsApi;
