import React, { useState, useEffect } from 'react';
import {
  BillTimelineContainer,
  CheckOverdueMessage,
  TimelineSection,
} from './ViewPaymentActivity/ViewPaymentActivity.styles';
import { Timeline, Link } from '@melio/billpay-design-system';
import { getTimelineProps, isCheckPaymentDelay } from './utils';
import { NotificationVariantEnum } from 'src/app/version-2/model/enums';
import { useIntl } from 'react-intl';
import { useVoidCheckActions } from 'src/app/pages/bill/hooks/useVoidCheckActions';
import { RecordOf } from 'immutable';
import { BillType, PaymentType } from 'src/app/utils/types';
import { PAYMENT_STATUS } from 'src/app/utils/consts';

type Props = {
  payment: RecordOf<PaymentType>;
  bill: RecordOf<BillType>;
};

const PaymentTimeline = ({ payment, bill }: Props) => {
  const { formatMessage } = useIntl();
  const { handleVoidCheckAndResendPayment } = useVoidCheckActions({
    billId: bill.id,
    paymentId: payment.id,
  });
  const [showTimeline, setShowTimeline] = useState<boolean>(false);

  useEffect(() => {
    const isPaymentScheduled = payment?.status === PAYMENT_STATUS.SCHEDULED;
    const isPaymentBlocked = payment?.status === PAYMENT_STATUS.BLOCKED;
    const isPaymentFailed = payment?.status === PAYMENT_STATUS.FAILED;
    const isPaymentManual = payment?.manual;

    setShowTimeline(
      !isPaymentScheduled && !isPaymentFailed && !isPaymentBlocked && !isPaymentManual
    );
  }, []);

  return (
    <TimelineSection show={showTimeline}>
      <BillTimelineContainer>
        <Timeline
          steps={getTimelineProps(payment, formatMessage).steps}
          current={getTimelineProps(payment, formatMessage).current}
        />
      </BillTimelineContainer>

      {isCheckPaymentDelay(payment) && (
        <CheckOverdueMessage
          type={NotificationVariantEnum.INFO}
          subtitle={{
            label: 'bills.pay.confirm.undepositedCheck.overdue',
            values: {
              ctaLink: (...chunks) => (
                <Link onClick={handleVoidCheckAndResendPayment}>
                  {React.Children.toArray(chunks)}
                </Link>
              ),
            },
          }}
        />
      )}
    </TimelineSection>
  );
};

export default PaymentTimeline;
