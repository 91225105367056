import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { RecordOf } from 'immutable';
import styled from 'styled-components';
import locations from 'src/app/utils/locations';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { getPartialBillId, isRecurringRecord } from 'src/app/utils/bills';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import {
  CONSTS,
  FUNDING_SOURCE_ORIGIN,
  IMAGE_POSITION,
  NEXT_BILL,
  NOTIFICATION_CARD_TYPES,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import { useLocationState } from 'src/app/utils/hooks';
import analytics from 'src/app/services/analytics';
import { useModal } from 'src/app/helpers/react/useModal';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import QBOMIDialog from 'src/app/components/common/QBOMIDialog';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { CollectVendorEmailModalMessage } from 'src/app/pages/bill/pay/components/CollectVendorEmailModalMessage';
import { PaymentType, BillType, ConfirmationOrigin, CompanyInfoType } from 'src/app/utils/types';
import * as QBOLayoutElements from 'src/app/components/layout/QBOElements';
import * as WizardElements from 'src/app/components/layout/QBOWizardElements';
import { ModalWrapper, Backdrop } from 'src/app/components/common/ModalMessage';
import { NextQboBillToPayInfo } from 'src/app/pages/qb-dashboard/components/NextQboBillToPayInfo';
import successImage from 'src/app/images/icons/icons_check.svg';

import { shouldAskForVendorEmail } from 'src/app/modules/vendors/vendor-email/utils/should-ask-for-vendor-email';
import { useCheckOwnedVendorExists } from '../../bill/pay/hooks/useCheckOwnedVendorExists';
import { getPayBillSuccessLabels, getWarningNotificationLabels } from '../utils';

import {
  WizardActionsRowContainer,
  WizardButtonContainer,
  ModalContainer as StyledModalContainer,
  Close,
} from '../styles';

type NextQboBillToPayData = {
  nextQboBillToPay: BillType;
};

const eventPage = 'pay-bill';

type AnalyticNextBillAdditionalData = {
  vendorEmail: string;
  nextBill?: string;
  billId?: string | number | null;
};

export const useSinglePayBillSuccessModal = (finishSchedulingFlow: () => void) => {
  const [historyPush] = useHistoryWithOrgId();
  const [payment] = useLocationState<RecordOf<PaymentType>>('payment');
  const [bill] = useLocationState<RecordOf<BillType>>('bill');
  const [nextQboBillToPayData] = useLocationState<NextQboBillToPayData>('nextQboBillToPayData');
  const [confirmationOrigin] = useLocationState<ConfirmationOrigin>('confirmationOrigin');
  const [syncPaymentErrorCode] = useLocationState<string>('syncPaymentErrorCode');
  const [isCollectSuccessModalShown, showCollectSuccessModal] = useState<boolean>(true);
  const validSource = !!bill?.vendor?.id;

  const [visible, setVisibility] = useState<boolean>(
    validSource && confirmationOrigin === ConfirmationOrigin.SINGLE_PAYMENT
  );
  const qbCashState: { balance: number } = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const [isOwnedVendorExists] = useCheckOwnedVendorExists({
    bill: bill || { vendor: {} },
    eventPage,
  });
  const organization: CompanyInfoType = useSelector(
    organizationStore.selectors.byId(payment?.organizationId)
  );

  const vendorEmail = bill?.vendor?.contactEmail || null;
  const hasVendorEmail = isOwnedVendorExists || !!vendorEmail;
  const vendorName = bill?.vendor?.companyName;

  const deliveryMethod =
    bill?.getDeliveryMethodById && bill.getDeliveryMethodById(payment?.deliveryMethodId);

  const shouldShowNotifyVendorButton = shouldAskForVendorEmail({
    isOwnedVendorExists,
    vendorContactEmail: bill?.vendor?.contactEmail,
    deliveryType: deliveryMethod?.deliveryType,
  });

  const vendorEmailExists = !shouldShowNotifyVendorButton;

  const analyticsProps = {
    vendorId: bill?.vendorId,
    partialBillId: getPartialBillId(bill),
  };

  const handleCloseAndTrackAnalyticsForCollectSuccessModal = () => {
    showCollectSuccessModal(false);
    handleCloseModal();
    analytics.track(eventPage, 'SuccessPaymentModal-Closed');
  };

  const handleCloseCollectSuccessModal = () => {
    showCollectSuccessModal(false);
    handleCloseModal();
    analytics.track(eventPage, 'SuccessPaymentModal-Done', {
      paymentId: payment?.id,
      vendorId: bill?.vendor?.id,
    });
  };

  const handleConfirmCollectSuccessModal = () => {
    analytics.track(eventPage, 'success-vendor-email-modal-closed', analyticsProps);
    handleCloseCollectSuccessModal();
    handleCloseModal();
  };

  const scheduleNextPayment = () => {
    const billId = nextQboBillToPayData?.nextQboBillToPay?.id;

    analytics.track(eventPage, 'NextPaymentInSuccessPaymentModal-Scheduled', {
      billId,
    });

    historyPush({ path: locations.Bills.pay.funding.url({ id: billId }) });
  };

  const showQbcashNotification =
    payment?.fundingSource?.origin === FUNDING_SOURCE_ORIGIN.QBCASH &&
    parseFloat(payment?.amount) <= qbCashState.balance;

  const {
    title: warningTitle = '',
    subtitle: warningSubtitle = '',
    subtitleValues: warningSubtitleValues = {},
  } = getWarningNotificationLabels({
    showQbcashNotification,
    syncPaymentErrorCode,
    payment,
  });

  const shouldShowWarningNotification = warningTitle || warningSubtitle;
  const nextQboBillToPayInfo = nextQboBillToPayData?.nextQboBillToPay;
  const [vendorContactEmail, setVendorContactEmail] = useState(vendorEmail);

  const CollectSuccessModal = () => {
    useEffect(() => {
      analytics.track(eventPage, 'EmailSentSuccessPaymentModal-Shown', {
        paymentId: payment?.id,
        vendorId: bill?.vendor?.id,
        ...addAdditionalAnalytics(true),
      });
    }, []);

    return (
      <StyledCollectSuccessModal
        type={DIALOG_TYPE.CONFIRM}
        variant={DIALOG_VARIANTS.SUCCESS}
        title="bills.pay.collectVendorEmailModal.successTitle"
        image={successImage}
        subtitle="bills.pay.collectVendorEmailModal.qboSuccessSubTitle"
        titleValues={{ vendorName }}
        okButtonText={
          nextQboBillToPayInfo
            ? 'dialogs.buttons.schedule'
            : 'bills.pay.collectVendorEmailModal.confirm'
        }
        onOkAction={nextQboBillToPayInfo ? scheduleNextPayment : handleConfirmCollectSuccessModal}
        onCancelAction={nextQboBillToPayInfo && handleCloseCollectSuccessModal}
        onCloseAction={handleCloseAndTrackAnalyticsForCollectSuccessModal}
        showCancel={nextQboBillToPayInfo}
        showConfirmationText={false}
        imagePosition={nextQboBillToPayInfo ? IMAGE_POSITION.LEFT : IMAGE_POSITION.CENTER}
        {...(nextQboBillToPayInfo && {
          cancelButtonText: 'dialogs.buttons.done',
          className: 'single-pay-bill-success-modal',
        })}
      >
        {nextQboBillToPayInfo && (
          <NextQboBillToPayInfo nextQboBillToPayInfo={nextQboBillToPayInfo} />
        )}
      </StyledCollectSuccessModal>
    );
  };

  const handleCloseModal = () => {
    finishSchedulingFlow();
    setVisibility(false);
  };

  const handleClickCloseModal = () => {
    analytics.track(eventPage, 'SuccessPaymentModal-Closed');
    handleCloseModal();
  };

  const handleNotifyLater = () => {
    analytics.track(eventPage, 'SuccessPaymentModalMaybeLaterButton-Clicked');
    handleCloseModal();
  };

  const [CollectVendorEmailSuccessModal, showCollectVendorEmailSuccessModal] = useModal(
    CollectSuccessModal,
    {
      modalName: 'successCollectEmail',
      onSuccess: handleCloseModal,
    }
  );

  const handleShowCollectVendorEmailModal = (vendorContactEmail) => {
    setVendorContactEmail(vendorContactEmail);
    showCollectVendorEmailSuccessModal();
  };

  const [CollectVendorEmailModal, showCollectVendorEmailModal] = useModal(
    CollectVendorEmailModalMessage,
    {
      onSuccess: handleShowCollectVendorEmailModal,
      payment,
      bill,
      eventPage,
      modalName: 'collectEmail',
      subTitle: 'bills.pay.collectVendorEmailModal.qboSubTitle',
      emailLabel: 'bills.pay.collectVendorEmailModal.emailQboLabel',
    }
  );

  const titles = getPayBillSuccessLabels({
    payment,
    organization,
    hasVendorEmail,
    vendorName,
    isRecurring: isRecurringRecord(bill),
  });

  const handleConfirmAndRedirectToUnpaidTab = () => {
    analytics.track(eventPage, 'SuccessPaymentModal-Done', {
      paymentId: payment?.id,
      vendorId: bill?.vendor?.id,
    });

    handleConfirm();
  };

  const handleConfirm = () => {
    if (shouldShowNotifyVendorButton) {
      analytics.track(eventPage, 'SuccessPaymentModalNotifyVendorButton-Clicked', {
        flow: 'single-payment',
      });
      setVisibility(false);
      showCollectVendorEmailModal();
      analytics.track(eventPage, 'notify-my-vendor', {
        screenName: 'success',
        ...analyticsProps,
      });
    } else {
      handleCloseModal();
    }

    analytics.track(eventPage, 'done', {
      partialBillId: getPartialBillId(bill),
      paymentId: payment?.id,
      vendorId: bill?.vendor?.id,
    });
  };

  const { title, subtitle, textValues } = titles;

  const addAdditionalAnalytics = (
    shouldSendAdditionalData: boolean
  ): AnalyticNextBillAdditionalData => {
    const defaultProps: AnalyticNextBillAdditionalData = {
      vendorEmail: vendorContactEmail,
      ...(shouldSendAdditionalData && {
        nextBill: NEXT_BILL.NO_BILL,
        billId: null,
      }),
    };

    if (vendorContactEmail && nextQboBillToPayInfo) {
      return {
        ...defaultProps,
        nextBill: NEXT_BILL.WITH_DETAILS,
        billId: nextQboBillToPayInfo.id as any,
      };
    }

    return defaultProps;
  };

  useEffect(() => {
    if (visible) {
      analytics.track(eventPage, 'success-payment-modal', {
        partialBillId: getPartialBillId(bill),
        paymentId: payment?.id,
        vendorId: bill?.vendor?.id,
        ...addAdditionalAnalytics(vendorEmailExists),
      });
    }
  }, []);

  useEffect(() => {
    if (!visible || !shouldShowNotifyVendorButton) {
      return;
    }

    analytics.track(eventPage, 'exposed-to-notify-my-vendor-button', {
      screenName: 'success',
      ...analyticsProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowNotifyVendorButton]);

  const confirmButtonLabel = shouldShowNotifyVendorButton
    ? 'bills.pay.payBillSuccess.buttonLabel.notifyVendor'
    : 'bills.pay.payBillSuccess.buttonLabel.done';

  const nextQboBillToPayAndVendorEmailExist = nextQboBillToPayInfo && vendorEmailExists;

  const actionButtonsConfigs = {
    done: {
      visible: !shouldShowNotifyVendorButton,
      label: 'dialogs.buttons.done',
      testId: 'bill-success-modal-done',
      variant: CONSTS.BUTTON_VARIANT[nextQboBillToPayInfo ? 'CANCEL' : 'PRIMARY'],
      className: nextQboBillToPayInfo ? 'single-pay-bill-flow-done-button' : '',
      onClick: handleConfirmAndRedirectToUnpaidTab,
    },
    notifyLater: {
      visible: shouldShowNotifyVendorButton,
      label: 'bills.pay.payBillSuccess.buttonLabel.later',
      testId: 'notify-vendor-later',
      variant: CONSTS.BUTTON_VARIANT.SECONDARY,
      className: `notify-later-button-${CONSTS.BUTTON_VARIANT.SECONDARY}`,
      onClick: handleNotifyLater,
    },
    notifyVendor: {
      visible: shouldShowNotifyVendorButton,
      variant: CONSTS.BUTTON_VARIANT.PRIMARY,
      testId: 'notify-vendor-confirm',
      label: confirmButtonLabel,
      onClick: handleConfirm,
    },
    schedulePayment: {
      visible: nextQboBillToPayAndVendorEmailExist,
      variant: CONSTS.BUTTON_VARIANT.PRIMARY,
      testId: 'single-pay-bill-flow-done-schedule-payment',
      label: 'dialogs.buttons.schedule',
      onClick: scheduleNextPayment,
    },
  };

  return [
    ReactDOM.createPortal(
      <>
        {CollectVendorEmailModal}
        {isCollectSuccessModalShown && CollectVendorEmailSuccessModal}
        {visible && (
          <ModalWrapper>
            <Backdrop onClick={handleClickCloseModal} />
            <ModalContainer
              data-testid="modal-message-container"
              className={cx({
                'single-pay-bill-success-modal': nextQboBillToPayAndVendorEmailExist,
              })}
            >
              <Close onClick={handleClickCloseModal} />
              <QBOLayoutElements.QBOLayoutContainer>
                <WizardElements.WizardInnerSuccess isCenterVert>
                  <StyledWiardIllustration isCenterVert={!nextQboBillToPayAndVendorEmailExist}>
                    <WizardElements.WizardIllustration src={successImage} />
                  </StyledWiardIllustration>
                  <WizardElements.WizardTitle
                    className="single-payment-succes-modal-title"
                    data-testid="wizard-title"
                  >
                    <MIFormattedText label={title} values={textValues} />
                  </WizardElements.WizardTitle>
                  <WizardElements.WizardText
                    className="single-payment-succes-modal-subtitle"
                    textAlign="center"
                  >
                    <MIFormattedText label={subtitle} values={textValues} />
                  </WizardElements.WizardText>
                  {shouldShowWarningNotification && (
                    <StyledQBONotificationCard
                      type={NOTIFICATION_CARD_TYPES.WARNING}
                      title={{ label: warningTitle }}
                      subtitle={{
                        label: warningSubtitle,
                        values: warningSubtitleValues,
                      }}
                    />
                  )}
                  {nextQboBillToPayAndVendorEmailExist && (
                    <NextQboBillToPayInfo nextQboBillToPayInfo={nextQboBillToPayInfo} />
                  )}
                  <WizardElements.WizardActionsContainer>
                    <WizardActionsRowContainer
                      vendorEmailNotExist={nextQboBillToPayInfo || shouldShowNotifyVendorButton}
                    >
                      {Object.values(actionButtonsConfigs).map(
                        ({ visible, ...config }) =>
                          visible && (
                            <WizardButtonContainer
                              key={config.testId}
                              vendorEmailNotExist={shouldShowNotifyVendorButton}
                            >
                              <MIButton {...config} />
                            </WizardButtonContainer>
                          )
                      )}
                    </WizardActionsRowContainer>
                  </WizardElements.WizardActionsContainer>
                </WizardElements.WizardInnerSuccess>
              </QBOLayoutElements.QBOLayoutContainer>
            </ModalContainer>
          </ModalWrapper>
        )}
      </>,
      document.querySelector('body') as any
    ),
  ];
};

const ModalContainer = styled(StyledModalContainer)`
  &.single-pay-bill-success-modal {
    min-width: calc(750px - 4rem * 2);
  }

  .single-payment-succes-modal-title {
    margin-bottom: 1rem;
  }

  .single-payment-succes-modal-subtitle {
    font-size: 1.6rem;
    margin-bottom: 1rem;

    b {
      font-weight: 600 !important;
    }
  }

  .single-pay-bill-flow-done-button {
    border: 2px solid ${(props) => props.theme.colors.ds.black} !important;
    background-color: transparent !important;
  }
`;

const StyledWiardIllustration = styled.div<{ isCenterVert: boolean }>`
  text-align: ${(props) => (props.isCenterVert ? 'center' : 'left')}!important;
`;

const StyledCollectSuccessModal = styled(QBOMIDialog)`
  background-color: rgba(0, 0, 0, 0.65);

  &.single-pay-bill-success-modal > div {
    min-width: 752px;
  }

  .modal-text-block {
    margin-bottom: 0;
  }

  .modal-title {
    font-size: 2.4rem;
  }

  .modal-subtitle {
    font-weight: 400;
  }

  .actions-container {
    margin-top: 3rem;
  }
`;

const StyledQBONotificationCard = styled(QBONotificationCard)`
  margin-top: 3.5rem;
`;
