import React, { useState } from 'react';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { withPreservedStateNavigator } from 'src/app/hoc';
import { useApi } from 'src/app/hoc/useApi';
import CodeVerificationPage from 'src/app/components/layout/CodeVerificationPage';
import { setProfileAction } from 'src/app/redux/user/actions';
import { getProfile } from 'src/app/redux/user/selectors';
import analytics from 'src/app/services/analytics';
import authApi from 'src/app/services/api/auth';
import { useQueryString } from 'src/app/utils/hooks';
import locations from 'src/app/utils/locations';

type Props = {
  navigate: (path: string, params?: boolean, state?: Record<string, any>) => void;
  locationState: Record<string, any>;
};

const eventPage = 'registration';
const eventName = 'email-code-verification';
const CodeVerificationPageContainer: React.FC<Props> = ({ navigate }: Props) => {
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const query = useQueryString();
  const [showError, setIsErrorShown] = useState(false);
  const [codeInputKey, setCodeInputKey] = useState(1);

  const [resolveEmailVerification, , isResolving, resolveError] = useApi<
    [string, number],
    Record<string, any>
  >(authApi.resolveEmailVerificationCode);
  const [resendEmailVerificationCode, codeResendResult, isResending] = useApi<
    [string],
    Record<string, any>
  >(authApi.resendEmailVerificationCode);

  const onCodeChange = async (code: string) => {
    if (code.length === 4) await onResolveEmailVerification(Number(code));

    if (code.length === 0) setIsErrorShown(false);
  };

  const onChangeEmail = () => {
    navigate(locations.Auth.register.changeEmail.url());
  };

  const onResolveEmailVerification = async (code: number) => {
    try {
      const redirectUrl = query?.redirect;

      await resolveEmailVerification(profile.email, code);
      analytics.track(eventPage, `${eventName}-correct-code`);
      dispatch(setProfileAction(profile.merge({ isEmailVerified: true })));
      analytics.track(eventPage, `${eventName}-continue-success`);

      navigate(redirectUrl as string, true);
    } catch (e) {
      if (e) {
        setIsErrorShown(true);
        analytics.track(eventPage, `${eventName}-incorrect-code`);
      }
    }
  };

  const sendEmailVerificationCode = () => {
    resendEmailVerificationCode(profile.email).then(() => {
      setCodeInputKey(codeInputKey + 1);
      setIsErrorShown(false);
    });
  };

  return (
    <CodeVerificationPage
      codeInputKey={codeInputKey}
      email={profile.email}
      errorCode={showError ? resolveError?.code : ''}
      isLoading={isResolving || isResending}
      sendEmailVerificationCode={sendEmailVerificationCode}
      onChange={onCodeChange}
      onChangeEmail={onChangeEmail}
      isCodeResend={!!codeResendResult}
    />
  );
};

export default compose(withPreservedStateNavigator())(CodeVerificationPageContainer);
