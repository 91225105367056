import React from 'react';
import { Box } from '@melio/billpay-design-system';

import { MITextInput } from 'src/app/components/common/MITextInput';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';
import { ReactComponent as ClipBoardIcon } from 'src/app/images/qbo/vc-details/clipboard-icon.svg';

import { CardFieldsType } from './types';

interface Props {
  fieldName: string;
  label: string;
  value?: string;
  notices?: string[];
  withMargin?: boolean;
  fullWidth?: boolean;
  onFieldCopy: (fieldName: string, value?: string) => void;
  withSuffix: boolean;
  suffixStateByField?: CardFieldsType;
  setSuffixStateByField?: (cardFields: CardFieldsType) => void;
}

const CardField = ({
  fieldName,
  label,
  value,
  notices,
  withSuffix,
  withMargin = true,
  fullWidth,
  suffixStateByField,
  setSuffixStateByField,
  onFieldCopy,
}: Props) => {
  const containerSuffixProps = withSuffix
    ? {
        onMouseOver: () =>
          setSuffixStateByField &&
          suffixStateByField &&
          setSuffixStateByField({ ...suffixStateByField, [fieldName]: true }),
        onMouseLeave: () =>
          setSuffixStateByField &&
          suffixStateByField &&
          setSuffixStateByField({ ...suffixStateByField, [fieldName]: false }),
      }
    : {};

  const inputSuffixProps = withSuffix
    ? {
        limitSuffixSize: false,
        suffix: suffixStateByField && suffixStateByField[fieldName] && <ClipBoardIcon />,
      }
    : {};

  const mobileFieldsReverseMargin = '-1.6rem';

  return (
    <Box
      {...containerSuffixProps}
      onClick={() => onFieldCopy(fieldName, value)}
      width={fullWidth ? 'full' : 'initial'}
      cursor="pointer"
      marginBottom={withMargin ? 'initial' : mobileFieldsReverseMargin}
    >
      <MITextInput
        {...inputSuffixProps}
        label={label}
        value={value}
        required
        viewOnly
        readOnlyValue
        size={TextInputSizeEnum.INLINE}
        withBorder={false}
        notices={notices}
      />
    </Box>
  );
};

export { CardField };
