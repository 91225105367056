import * as React from 'react';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import { compose } from 'recompose';
import { isValidationOk } from '@melio/sizzers-js-common';
import { UserContextType } from 'src/app/utils/types';
import pagesLocations from 'src/app/pages/locations';
import { withPreservedStateNavigator } from '../../../hoc/index';
import clientServiceApi from '../../../services/api/clientService';
import { GlobalState } from '../../../redux/types';
import authApi from '../../../services/api/auth';
import analytics from '../../../services/analytics';
import ChangeEmailPage from './components/ChangeEmailPage';
import { setProfileAction } from '../../../redux/user/actions';
import { getProfile } from '../../../redux/user/selectors';

type MapStateToProps = {
  profile: RecordOf<UserContextType>;
};

type MapDispatchToProps = {
  setProfile: (profile: RecordOf<UserContextType>) => void;
};

type Props = {
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  email: string;
  validationErrors: Record<string, any>;
  isLoading: boolean;
  errorCode?: string;
};

const eventPage = 'change-email';

class ChangeEmailPageContainer extends React.PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);

    const { email } = this.props.profile;

    this.state = {
      email,
      validationErrors: {},
      isLoading: false,
    };
  }

  onNext = () => {
    const formattedEmail = this.state.email.trim().toLowerCase();

    analytics.track(eventPage, 'email-continue');

    if (formattedEmail === this.props.profile.email) {
      if (this.state.errorCode !== 'ATH01') {
        this.props.navigate(pagesLocations.codeVerification);
      }
    } else {
      this.setState({ isLoading: true });
      clientServiceApi
        .getValidationErrors('userRegistration', { email: formattedEmail })
        .then(({ validationErrors }) => {
          this.setState({ validationErrors }, () => {
            if (isValidationOk(this.state.validationErrors)) {
              authApi
                .changeEmail({ email: formattedEmail })
                .then(() => {
                  analytics.track(eventPage, 'email-continue-success');
                  this.setState({ isLoading: false });
                  this.props.setProfile(this.props.profile.merge({ email: formattedEmail }));
                  this.props.navigate(pagesLocations.codeVerification);
                })
                .catch((e) => {
                  analytics.track(eventPage, 'register-email-not-unique');
                  this.setState({ isLoading: false, errorCode: e.code });
                  this.props.setProfile(this.props.profile.merge({ email: formattedEmail }));
                });
            } else {
              this.setState({ isLoading: false });
              analytics.track(eventPage, 'email-validation-error', validationErrors);
            }
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  onPrev = () => {
    this.props.navigate(pagesLocations.codeVerification);
  };

  onChange = (email: string) => {
    this.setState({ email });
  };

  render() {
    const { email, validationErrors, isLoading, errorCode } = this.state;

    return (
      <ChangeEmailPage
        onNext={this.onNext}
        onPrev={this.onPrev}
        onChange={this.onChange}
        email={email}
        validationErrors={validationErrors}
        isLoading={isLoading}
        errorCode={errorCode}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  setProfile(profile: RecordOf<UserContextType>) {
    dispatch(setProfileAction(profile));
  },
});

export default compose(
  withPreservedStateNavigator(),
  connect(mapStateToProps, mapDispatchToProps)
)(ChangeEmailPageContainer);
