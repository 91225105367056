import React from 'react';
import { useSelector } from 'react-redux';
import { envApi } from 'src/app/version-2/api/env';
import { getOrgId } from 'src/app/redux/user/selectors';
import { withRouter, generatePath, RouteComponentProps } from 'react-router-dom';
import { encodeQuery, parseQueryString, convertStringToUrlObject } from '../utils/query-utils';

function getNavigate(props, orgId) {
  return (url, shouldReplaceCurrent = false, state = null, exitIframe = false, newTab = false) => {
    const parsedUrl = convertStringToUrlObject(url);

    parsedUrl.pathname = generatePath(parsedUrl.pathname, {
      orgId,
      ...props.match.params,
    });

    if (newTab) {
      const urlAsString = parsedUrl.search
        ? parsedUrl.pathname
        : `${parsedUrl.pathname}?${parsedUrl.search}`;

      window.open(urlAsString);

      return null;
    }

    if (exitIframe) {
      const appUrl = new URL(parsedUrl.pathname, envApi.getConfig().web.baseUrl);

      appUrl.search = parsedUrl.search;

      if (window !== window.parent && window.top) {
        window.top.location.href = appUrl.href;

        return null;
      }
    }

    if (shouldReplaceCurrent) {
      return props.history.replace(parsedUrl, state);
    }

    return props.history.push(parsedUrl, state);
  };
}

function getPathWithParams(props) {
  return (url, params) => generatePath(url, { ...props.match.params, ...params });
}

export type NavigationProperties<P> = RouteComponentProps<P> & {
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, unknown>,
    exitIframe?: boolean,
    newTab?: boolean
  ) => void;
  locationState: Record<string, any>;
  basePath: string;
  pathWithParams: string;
  params: Record<string, unknown>;
  query: Record<string, string>;
};
export type ComponentWithoutNavigationProps<P> = React.ComponentClass<
  Omit<P, keyof NavigationProperties<any>>
>;

function withNavigator() {
  return function <P>(Component: React.ComponentType<P>): ComponentWithoutNavigationProps<P> {
    return withRouter<RouteComponentProps<any>, any>((oProps) => {
      const props = { ...oProps };
      const orgId = useSelector(getOrgId);
      const navigate = getNavigate(oProps, orgId);
      const pathWithParams = getPathWithParams(oProps);

      const query = parseQueryString(oProps.location.search);

      return (
        <Component
          {...props}
          locationState={{ ...oProps.location.state }}
          basePath={oProps.location.pathname}
          navigate={navigate}
          pathWithParams={pathWithParams}
          params={props.match.params}
          query={query}
        >
          {props.children}
        </Component>
      );
    }) as any;
  };
}

export type NavigationState = {
  preservedState?: Record<string, any>;
  redirectUrl?: string;
  exitUrl: string;
} & Record<string, any>;

function withPreservedStateNavigator() {
  return function <P>(Component: React.ComponentType<P>): ComponentWithoutNavigationProps<P> {
    return withRouter((oProps) => {
      const props = { ...oProps };
      const navigate = getNavigate(oProps, null);

      const query = parseQueryString(oProps.location.search);

      const handleNavigation = (url, shouldReplaceCurrent = false, state: any = null) => {
        const { preservedState, redirectUrl, exitUrl } = oProps.location.state || {};

        navigate(url, shouldReplaceCurrent, {
          ...state,
          preservedState,
          redirectUrl,
          exitUrl,
        });
      };

      const navigateWithPreservedState = (dataToAdd) => {
        const { preservedState, redirectUrl } = oProps.location.state || {};

        navigate(redirectUrl, false, { ...preservedState, ...dataToAdd });
      };

      const navigateToExitWithPreservedState = (dataToAdd) => {
        const { preservedState, exitUrl } = oProps.location.state || {};

        navigate(exitUrl, false, {
          ...preservedState,
          ...dataToAdd,
          preservedState,
        });
      };

      return (
        <Component
          {...props}
          locationState={{ ...oProps.location.state }}
          basePath={oProps.location.pathname}
          navigate={handleNavigation}
          navigateWithPreservedState={
            oProps.location.state && oProps.location.state.redirectUrl
              ? navigateWithPreservedState
              : null
          }
          navigateToExitWithPreservedState={
            oProps.location.state && oProps.location.state.exitUrl
              ? navigateToExitWithPreservedState
              : null
          }
          query={query}
        >
          {props.children}
        </Component>
      ) as any;
    }) as ComponentWithoutNavigationProps<P>;
  };
}

function withListNavigator() {
  return function (Component: any) {
    return withRouter((oProps) => {
      const props = { ...oProps };
      const navigate = getNavigate(oProps, null);
      const query = parseQueryString(oProps.location.search);
      const { id, ...filters } = query;
      const setSelected = (id) => {
        const search = encodeQuery(
          {
            ...query,
            id,
          },
          [],
          ''
        );

        navigate(`${oProps.location.pathname}?${search}`);
      };

      return (
        <Component
          {...props}
          locationState={oProps.location.state}
          basePath={oProps.location.pathname}
          query={query}
          navigate={navigate}
          id={id}
          filters={filters}
          setSelected={setSelected}
        >
          {props.children}
        </Component>
      );
    });
  };
}

export { withNavigator, withListNavigator, withPreservedStateNavigator };
