import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { getSiteFromUrl } from 'src/app/context/SiteContext';
import { flattenMessages } from 'src/app/locale';
import DEFAULT_VALUES from 'src/app/locale/default-values';
import { createTranslationService } from 'src/app/services/translation-service';
import analytics from 'src/app/services/analytics';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { toastsApi } from 'src/app/version-2/api/toasts';

const CLOSE_NOTIFICATION_TIMEOUT = 2000;

export function pushNotification(item: {
  type: string;
  msg: string;
  textValues?: any;
  autoClose?: any;
}) {
  const { type, msg, textValues, autoClose = CLOSE_NOTIFICATION_TIMEOUT } = item;
  let toastId: string | number;

  analytics.trackAction('toast-notification', { type });

  const locale = 'en-US';
  const site = getSiteFromUrl(window.location.href);
  const intl = createTranslationService(
    {
      locale,
      messages: flattenMessages(site.messages[locale]),
    } as any,
    DEFAULT_VALUES
  );

  const toastParams = {
    message: intl.formatMessage({ id: msg || '' }, textValues),
    options: {
      duration: autoClose,
      id: `toast-${type}-${msg}`,
    },
  };

  switch (type) {
    case NOTIFICATION_VARIANT.SUCCESS:
      toastId = toastsApi.success(toastParams);
      break;
    case NOTIFICATION_VARIANT.INFO:
      toastId = toastsApi.show(toastParams);
      break;
    case NOTIFICATION_VARIANT.ERROR:
      toastId = toastsApi.error(toastParams);
      break;
    default:
      toastId = 0;
      loggingApi.log(`notificationService.pushNotification(): ${msg}`);
      break;
  }

  return toastId;
}

export function dismissNotification(toastId: string | number) {
  toastsApi.closeToast(toastId);
}
