import { TabSettingsType } from './types';

const getDefaultTabSetting = (
  tab: string,
  type: string,
  isActive: boolean,
  baseSearch = '',
  unreadBadge = false
): TabSettingsType => ({
  label: `list.tab.${type}.${tab}`,
  active: isActive,
  to: {
    search: `${baseSearch ? `${baseSearch}&` : ''}status=${tab}`,
  },
  trackEvent: {
    page: tab,
    name: `${type}-tab`,
    properties: undefined,
  },
  unreadBadge,
});

const getTabsSettings = (
  TYPES: Record<string, any>,
  selected: number,
  type: string,
  baseSearch = ''
): TabSettingsType[] =>
  Object.keys(TYPES).map((tab) =>
    getDefaultTabSetting(tab, type, selected === TYPES[tab], baseSearch)
  );

export { getDefaultTabSetting, getTabsSettings };
