import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getOwnedVendorId, getDeliveryMethods } from 'src/app/redux/user/selectors';
import { AreaLoader } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import { getJWTPayload } from 'src/app/helpers/jwt';
import successImage from 'src/app/images/general/qbo-check-circle.svg';
import paymentStore from 'src/app/modules/payments/payment-store';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import { OrganizationType, PaymentType } from 'src/app/utils/types';

const ShiftedCurrentPaymentPage = ({ token }) => {
  const ownedVendorId = useSelector(getOwnedVendorId);
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const { paymentId } = getJWTPayload(token);
  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const organization: OrganizationType = useSelector(
    organizationStore.selectors.byId(payment?.organization?.id)
  );

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <BillPaymentBlock>
        <BillInfoContainer>
          <DoneIcon src={successImage} />
          <Title data-testid="success-title">
            <MIFormattedText
              label={
                ownedVendorId && orgDeliveryMethods?.length
                  ? 'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.ownedVendorTitle'
                  : 'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.title'
              }
            />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.subtitle"
              values={{
                companyName: organization?.companyName,
              }}
            />
          </Subtitle>
        </BillInfoContainer>
      </BillPaymentBlock>
    </QBOLayoutPage>
  );
};

export default ShiftedCurrentPaymentPage;

const BillPaymentBlock = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  border: 1px solid ${(props) => props.theme.colors.shadow.lightGrey};
  box-sizing: border-box;
  text-align: center;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 53rem;
  }
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 2rem;
  font-size: 2rem;
`;

const Subtitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: #393a3c;
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const DoneIcon = styled.img`
  height: 5.4rem;
  width: 5.4rem;
  margin-bottom: 2rem;
`;
