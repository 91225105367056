import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { TAG_VARIANT } from 'src/app/utils/consts';
import MITag from 'src/app/components/common/MITag';
import { RESOURCE_BASE } from '../config';
import { RecoveryFlowType } from '../../consts';

type Props = {
  deliveryType: string;
  isRecommended: boolean;
  flowType: RecoveryFlowType;
};

export const DeliveryMethodTitle = ({ deliveryType, isRecommended, flowType }: Props) => {
  const title = `${RESOURCE_BASE[flowType]}.stepChooseDM.${deliveryType}.title`;

  return (
    <StyledDeliveryMethodTitle>
      <MIFormattedText label={title} />
      {isRecommended && (
        <TagContainer>
          <MITag
            label={`${RESOURCE_BASE[flowType]}.stepChooseDM.recommended`}
            isSecondary
            variant={TAG_VARIANT.BRAND}
          />
        </TagContainer>
      )}
    </StyledDeliveryMethodTitle>
  );
};

const TagContainer = styled.div`
  margin-left: 0.8rem;
  display: inline-flex;
`;

const StyledDeliveryMethodTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.darkGrey};
`;
