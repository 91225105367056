import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { DeliveryMethod, FundingSource } from 'src/app/version-2/model/dtos';
import { UserCompanyInfo } from 'src/app/version-2/model/dtos/userCompanyInfo';
import { UserProfile } from 'src/app/version-2/model/dtos/userProfile';

export const USER_SLICE = `${VERSION_2}user`;

interface userSlice {
  profile: Partial<UserProfile>;
  companyInfo: Partial<UserCompanyInfo>;
  checkFeesNotificationCount: string | null | undefined;
  checkFeesPromotionsEndedCount: string | null | undefined;
}

export const getUserSliceInitialState = (): userSlice => ({
  profile: {
    orgId: '',
    userPreferences: {},
    id: undefined,
  },
  companyInfo: {},
  checkFeesNotificationCount: null,
  checkFeesPromotionsEndedCount: null,
});

export type UserSlice = ReturnType<typeof getUserSliceInitialState>;

const selectState = (state: any): UserSlice => state[USER_SLICE];

export const userSlice = createSlice({
  name: USER_SLICE,
  initialState: getUserSliceInitialState(),
  reducers: {
    fetchUserProfileSuccess: (state, action: PayloadAction<UserProfile>) => {
      state.profile = action.payload;
    },
    setCompanyInfoSuccess: (state, action: PayloadAction<UserCompanyInfo>) => {
      state.companyInfo = action.payload;
    },
    setCompanyInfoFailed: (state) => {
      state.companyInfo = {};
    },
  },
});

export const userSliceSelectors = {
  selectOrgId: createSelector(selectState, (state) => state.profile.orgId),
  selectOrgById: (orgId) =>
    createSelector(selectState, (state) =>
      state.profile.organizations?.find((org) => org.id === orgId)
    ),
  selectUserPreferences: createSelector(selectState, (state) => state.profile.userPreferences),
  selectUserId: createSelector(selectState, (state) => state.profile.id),
  getCompanyInfo: createSelector(selectState, (state) => state.companyInfo),
};

export const userActions = {
  ...userSlice.actions,
  fetchUserProfile: createAction(`${USER_SLICE}/profile/fetch`),
  fetchCompanyInfo: createAction(`${USER_SLICE}/company-info/fetch`),
  increaseCheckFeePaymentCount: createAction<{
    deliveryMethod?: DeliveryMethod;
    fundingSource?: FundingSource;
    deliveryPreference?: null | string | undefined;
    batchData?: any;
  }>(`${USER_SLICE}/check-fee/increase-fee-payment-count`),
};
