import { Flex } from '@melio/billpay-design-system';
import { CATEGORY_LIST_VIEW_VARIANTS } from 'src/app/utils/consts';
import { DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import { MICategoryList } from 'src/app/components/common/MICategoryList';
import { useExpeditedCategories } from '../hooks/useExpeditedCategories';
import { CategoryListContainer } from './VendorSelectExpeditedCategories.styles';

type PropsType = {
  token: string;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  analyticsProps?: { [key: string]: any };
};

const VendorSelectExpeditedCategories = (props: PropsType) => {
  const { analyticsProps, deliveryMethod } = props;
  const { categories, selectDeliveryMethod } = useExpeditedCategories(props);

  return (
    <Flex p={8}>
      <CategoryListContainer>
        <MICategoryList
          categories={categories}
          selectedId={deliveryMethod?.deliveryType}
          onSelect={selectDeliveryMethod}
          analyticsProps={analyticsProps}
          withShadow={false}
          viewVariant={CATEGORY_LIST_VIEW_VARIANTS.COLUMN}
        />
      </CategoryListContainer>
    </Flex>
  );
};

export default VendorSelectExpeditedCategories;
