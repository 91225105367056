import * as React from 'react';
import { compose } from 'recompose';
import MISearchBar from 'src/app/components/common/MISearchBar';
import { ListSearchBarContextType } from 'src/app/utils/types';
import { withListSearchBarContext } from 'src/app/hoc/withListSearchBarContext';

type Props = {
  autoFocus?: boolean;
  eventPage: string;
} & ListSearchBarContextType;

class ListSearchBar extends React.PureComponent<Props> {
  onChangeSearch = ({ value }) => {
    this.props.setContextSearchInputValue(value);
  };

  onSubmit = () => {
    this.props.setContextSearchFilterValue(this.props.contextSearchInputValue);
  };

  render() {
    const { autoFocus, contextSearchInputValue, clearSearchContext, eventPage, searchInputRef } =
      this.props;

    return (
      <MISearchBar
        id="listSearch"
        value={contextSearchInputValue}
        autoFocus={autoFocus}
        placeholder="list.search"
        eventPage={eventPage}
        onChange={this.onChangeSearch}
        onClear={clearSearchContext}
        onSubmit={this.onSubmit}
        inputRef={searchInputRef}
      />
    );
  }
}

export default compose(withListSearchBarContext())(ListSearchBar);
