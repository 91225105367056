import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from '@melio/billpay-design-system';

import { ReactComponent as FTUImage } from 'src/app/images/qbo/dashboard/ftu-modal-image.svg';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import Elements from 'src/app/pages/qb-dashboard/components/FTUModalContent/Elements';
import useUpdateUserPreferences from 'src/app/modules/users/hooks/useUpdateUserPreferences';

const FTUModalContent = ({ onClose }: { onClose: () => void }) => {
  const { updateUserPreference } = useUpdateUserPreferences();

  const updatePreference = async () => {
    onClose();
    await updateUserPreference({ key: 'qbDashboardSeen', value: true });
  };

  return (
    <FTUModalContentContainer>
      <Close onClick={updatePreference} />
      <Flex p="3.3rem 4rem 3rem" justifyContent="space-between">
        <Box>
          <Box as="h2" textStyle="h5" fontWeight="600" mt="0" mb="2rem">
            <MIFormattedText label="paymentDashboard.ftuModal.title" />
          </Box>
          <Elements />
          <Box mt="1rem">
            <MIButton
              label="paymentDashboard.ftuModal.ctaButton"
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={updatePreference}
            />
          </Box>
        </Box>
        <FTUImage className="ftu-modal-banner" />
      </Flex>
    </FTUModalContentContainer>
  );
};

const FTUModalContentContainer = styled.div`
  .ftu-modal-banner {
    width: 35.7rem;
    height: 22.6rem;
    position: relative;
    z-index: 0;
  }
`;

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  font-size: 2.4rem;
  cursor: pointer;
  color: rgba(107, 108, 114, 1);
  z-index: 1;
`;

export default FTUModalContent;
