import { CSSObject } from '@melio/billpay-design-system';
import styled from 'styled-components';
import { Loader } from '@melio/billpay-design-system';

export const StyledLoader = styled(Loader)`
  left: 0.4rem;
`;

export const containerStyle: CSSObject = {
  position: 'relative',
  p: '0.6rem 0.4rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const labelStyle: CSSObject = {
  m: ' 0 0.4rem 0 3.2rem',
  textStyle: 'body3',
  color: 'gray.500',
};
