import {
  getPaymentUpsellDetailsAndNavigateHandler,
  getPaymentUpsellDetailsHandler,
} from './sagas/getPaymentUpsellDetails.saga';
import { upgradePaymentByPayeeHandler } from './sagas/upgradePaymentByPayee.saga';
import { upgradePaymentActions } from './upgradePayment.slice';

import { takeLatest, takeLeading } from 'redux-saga/effects';

export function* watchUpgradePaymentByPayee() {
  yield takeLatest(upgradePaymentActions.getPaymentUpsellDetails, getPaymentUpsellDetailsHandler);
  yield takeLatest(
    upgradePaymentActions.getPaymentUpsellDetailsAndNavigate,
    getPaymentUpsellDetailsAndNavigateHandler
  );
  yield takeLeading(upgradePaymentActions.upgradePaymentByPayee, upgradePaymentByPayeeHandler);
}
