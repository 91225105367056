import { QBDashboardTabStatusEnum } from 'src/app/version-2/model/enums';

export const DASHBOARD_START = 0;
export const ITEMS_PER_PAGE = 24;
export const DEFAULT_DASHBOARD_REDIRECT_PARAMS = {
  start: DASHBOARD_START,
  status: QBDashboardTabStatusEnum.UNPAID,
  limit: ITEMS_PER_PAGE,
  highlightedItemIds: '',
};
