import { put, all, call, select, getContext } from 'redux-saga/effects';
import { PAY_BILL_EVENT_PAGE } from 'src/app/pages/bill/pay/consts';
import { getOrgId, getCompanyInfo } from 'src/app/redux/user/selectors';
import analytics from 'src/app/services/analytics';
import { getPayBillFlowUUID } from 'src/app/services/analytics/trackPayBillFlow';
import deliveryAPI from 'src/app/services/api/delivery';
import { isDeliveryOptionSupported } from 'src/app/utils/delivery-methods';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { fetchDeliveryOptionsSuccessAction, fetchDeliveryOptionsFailureAction } from '../actions';
import { getPayment, getSelectedFundingSource } from '../selectors';
import type { FetchDeliveryOptionsResponse, FetchDeliveryOptionsType } from '../types';

export function* fetchDeliveryOptionsHandler({ payload }: FetchDeliveryOptionsType) {
  const { scheduledDate } = payload;
  const [site, orgId, companyInfo, payment, fundingSource] = yield all([
    getContext('site'),
    select(getOrgId),
    select(getCompanyInfo),
    select(getPayment),
    select(getSelectedFundingSource),
  ]);
  const payBillFlowUUID = getPayBillFlowUUID();

  const { id: paymentId, fundingSourceId, deliveryMethodId, amount, deliveryMethod } = payment;

  analytics.track(PAY_BILL_EVENT_PAGE, 'get-delivery-date', {
    paymentId,
    fundingSourceId,
    deliveryMethodId,
    scheduledDate,
  });

  try {
    const response: FetchDeliveryOptionsResponse = yield call(
      deliveryAPI.getDeliveryTime,
      orgId,
      scheduledDate,
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID
    );

    response.deliveryOptions = response.deliveryOptions?.filter((deliveryOption) =>
      isDeliveryOptionSupported({
        deliveryOption,
        deliveryMethod,
        fundingSource,
        companyInfo,
        isFastACHEnabledForSite: site.hasFastAch,
      })
    );

    analytics.track(PAY_BILL_EVENT_PAGE, 'get-delivery-date-success', {
      paymentId,
      fundingSourceId,
      deliveryMethodId,
      scheduledDate,
    });

    yield put(fetchDeliveryOptionsSuccessAction(response));
  } catch (e: any) {
    analytics.track(PAY_BILL_EVENT_PAGE, 'get-delivery-date-failure', {
      paymentId,
      fundingSourceId,
      deliveryMethodId,
      scheduledDate,
      reason: e.message || '',
    });

    yield put(fetchDeliveryOptionsFailureAction(e.code));

    loggingApi.error('fetchDeliveryOprionsSaga.fetchDeliveryOptionsHandler(): failed', e);
  }
}
