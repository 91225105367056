import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Heading, Text } from '@melio/billpay-design-system';
import analytics from 'src/app/services/analytics';
import { utilsLocations } from 'src/app/version-2/externals';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { Container, DescriptionContainer, WideButton } from './Upsell.styles';

interface UpsellProps {
  paymentId: string;
  token: string;
  'data-testid'?: string;
}

export const Upsell = ({ paymentId, token, ...rest }: UpsellProps) => {
  const { formatMessage } = useIntl();
  const orgId = useSelector(userSliceSelectors.selectOrgId);
  const history = useHistory();

  const goToOptInScreen = () => {
    analytics.trackAction('apply-to-vc-global-opt-in', {
      source: 'Get card details screen',
      cta: 'apply now',
      paymentId,
    });

    history.push(
      utilsLocations.Vendors.globalOptIn.url({
        token,
        orgId,
      })
    );
  };

  return (
    <Container {...rest}>
      <Heading as="h4" textStyle="ds.h4" mt="0" mb="0">
        {formatMessage({ id: 'vendors.virtualCardDetails.upsell.title' })}
      </Heading>
      <DescriptionContainer>
        <Text textStyle="ds.body1">
          {formatMessage({ id: 'vendors.virtualCardDetails.upsell.description' })}
        </Text>
      </DescriptionContainer>
      <WideButton size="md" onClick={goToOptInScreen}>
        {formatMessage({ id: 'vendors.virtualCardDetails.upsell.actionButton' })}
      </WideButton>
    </Container>
  );
};
