import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useUpdateOrganizationPreference from 'src/app/modules/organizations/hooks/useUpdateOrganizationPreference';
import { toggleDashboardSwitcher } from 'src/app/utils/external-events';
import { OrgPreferencesTypeKeys } from 'src/app/utils/types';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';

type Response = {
  isSwitcherChecked: boolean;
  toggleSwitcher: (value: boolean) => void;
};

export const useUpdateQBDashboardSwitcher = (): Response => {
  const organizationPreferences = useSelector(getOrganizationPreferences);
  const { updateOrganizationPreference } = useUpdateOrganizationPreference();

  const toggleSwitcher = useCallback(
    (value: boolean) => {
      toggleDashboardSwitcher(value);
      updateOrganizationPreference({
        key: OrgPreferencesTypeKeys.qbDashboardSwitcherChecked,
        value: String(value),
      });
    },
    [updateOrganizationPreference]
  );

  return {
    isSwitcherChecked: organizationPreferences?.qbDashboardSwitcherChecked === 'true',
    toggleSwitcher,
  };
};
