import React, { useMemo, FunctionComponent } from 'react';
import {
  ModalMessage,
  ModalContainer,
  ContentContainer,
} from 'src/app/components/common/ModalMessage';
import styled from 'styled-components';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { WizardNewSelectField } from 'src/app/ui/form/WizardNewSelectField';
import { FormRow } from 'src/app/ui/form/FormElements';
import { useForm } from 'src/app/ui/form';
import { WizardCurrencyField } from 'src/app/ui/form/WizardCurrencyField';
import ValidationError from 'src/app/ui/ValidationError';
import { devices } from 'src/app/theme/AppDevices';
import isEmpty from 'lodash/isEmpty';
import analytics from 'src/app/services/analytics';

type MarkAsPaidFormType = {
  intuitAccountId?: string | null;
  amount: number;
};

type Props = {
  dismiss: () => void;
  submit: ({ intuitAccountId, amount }: MarkAsPaidFormType) => Promise<void>;
  options: { label: string; value: string }[];
  isPartialPaymentsUIEnabled: boolean;
  balance?: number | null;
  invoiceNumber?: string;
};

export const QBMViewBillMarkAsPaidModal: FunctionComponent<Props> = ({
  dismiss,
  submit,
  options,
  isPartialPaymentsUIEnabled,
  balance,
  invoiceNumber,
}) => {
  const model = useMemo(() => ({ intuitAccountId: null, amount: balance || 0 }), []);
  const [markAsPaidVM, { submit: onSubmit }] = useForm<MarkAsPaidFormType>(model, {
    submit: async (model) => {
      if (isPartialPaymentsUIEnabled && balance) {
        let validationErrors = {};

        if (model.amount > balance) {
          validationErrors = {
            amount: (
              <Block>
                <MIFormattedText
                  label="bills.form.partialPayments.errors.amountToBalance"
                  values={{
                    paymentsAmount: <MIFormattedCurrency value={balance} />,
                  }}
                />
              </Block>
            ),
          };
        } else if (model.amount === 0) {
          validationErrors = {
            amount: <MIFormattedText label="bills.form.partialPayments.errors.enterAmount" />,
          };
        }

        if (!isEmpty(validationErrors)) throw new ValidationError({ validationErrors });
      }

      await submit(model);
    },
  });

  const getTitle = () => {
    if (isPartialPaymentsUIEnabled) {
      return 'bills.form.partialPayments.markAsPaid.intuitLinkedTitle';
    }

    return 'bills.form.markAsPaidDialog.dialogTitle';
  };

  const onAmountFocus = () => {
    analytics.trackAction('mark-as-paid-modal-on-focus-amount', {
      amount: markAsPaidVM.amount?.value,
      source: 'mark-as-paid-modal',
    });
  };

  return (
    <StyledModalMessage
      onCloseClick={dismiss}
      titleComponent={
        <Title>
          <MIFormattedText label={getTitle()} />
        </Title>
      }
      contentComponent={
        <>
          {isPartialPaymentsUIEnabled && (
            <>
              <DescriptionContainer>
                <MIFormattedText
                  label="bills.form.partialPayments.markAsPaid.description"
                  values={{ invoiceNumber }}
                />
              </DescriptionContainer>

              <StyledFormRow>
                <WizardCurrencyField
                  onFocus={onAmountFocus}
                  label="bills.form.partialPayments.markAsPaid.amountLabel"
                  model={markAsPaidVM.amount}
                  required
                />
              </StyledFormRow>
            </>
          )}

          <StyledFormRow>
            <WizardNewSelectField
              label="bills.form.markAsPaidDialog.intuitAccountLabel"
              placeholder="bills.form.markAsPaidDialog.intuitAccountPlaceholder"
              required
              options={options}
              model={markAsPaidVM.intuitAccountId}
              allowCustom={false}
            />
          </StyledFormRow>
        </>
      }
      buttonComponent={
        <ButtonsContainer>
          <GroupContainer>
            <MIButton
              label="bills.form.partialPayments.markAsPaid.saveButton"
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={onSubmit}
              disabled={!markAsPaidVM.intuitAccountId.value}
              fullWidth
            />
            <CancelButton
              label="dialogs.buttons.cancel"
              variant={CONSTS.BUTTON_VARIANT.CANCEL}
              onClick={dismiss}
            />
          </GroupContainer>
        </ButtonsContainer>
      }
    />
  );
};

const StyledModalMessage = styled(ModalMessage)`
  ${ModalContainer} {
    overflow-y: visible;
  }
  ${ContentContainer} {
    margin-bottom: 0;
  }
`;

const StyledFormRow = styled(FormRow)`
  .input-container {
    &:first-of-type {
      margin-bottom: 4rem;
    }
    @media ${devices.mobile}, ${devices.phablet} {
      margin-bottom: 4rem;
    }
  }
`;

const Title = styled.div`
  display: flex;
  margin-bottom: -1.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4.5rem;
`;

const GroupContainer = styled.div`
  display: flex;
  width: 60%;
  > button:not(:last-of-type) {
    margin-right: 1rem;
  }
  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
  @media ${devices.mobile} {
    flex-direction: column;
    > button:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;

const CancelButton = styled(MIButton)`
  margin-right: 0;
`;

const DescriptionContainer = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.hint};
  margin-bottom: 3rem;
`;

const Block = styled.div`
  display: block;
`;
