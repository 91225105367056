import React, { useMemo, useCallback } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useApi } from 'src/app/hoc/useApi';
import { checkApostropheForPluralPossessive } from 'src/app/utils/string-utils';
import ValidationError from 'src/app/ui/ValidationError';
import {
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  INPUT_PATTERNS,
  NOTIFICATION_VARIANT,
} from 'src/app/utils/consts';
import { BankType, DeliveryMethodType } from 'src/app/utils/types';
import { withPreservedStateNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import api from 'src/app/services/api/financialAccounts';
import { selectFundingSourceAction } from 'src/app/redux/payBillWizard/actions';
import { getBill } from 'src/app/redux/payBillWizard/selectors';
import { getOrgId } from 'src/app/redux/user/selectors';
import vendorsApi from 'src/app/services/api/vendors';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import useRefreshFundingSources from 'src/app/modules/funding-sources/hooks/useRefreshFundingSources';
import { useForm } from 'src/app/ui/form';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import { WizardPasswordInputField } from 'src/app/ui/form/WizardPasswordInputField';
import { WizardFormContainer } from 'src/app/ui/wizard/WizardFormContainer';
import {
  WizardChoiceFooter,
  WizardFooterContainer,
  WizardStepActionsContainer,
  WizardStepTitle,
} from 'src/app/components/layout/QBOWizardElements';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { devices } from 'src/app/theme/AppDevices';
import { QBOHeaderContainer } from 'src/app/components/layout/QBOHeader';
import { BANK_ACCOUNT_CHECKING_TYPE } from 'src/app/version-2/model/constants';
import { DbAnalyticsTraitsEnum } from 'src/app/version-2/model/enums';

type Props = {
  locationState: Record<string, any>;
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const PlaidFailedPageContainer = ({
  locationState,
  navigate,
  navigateToExitWithPreservedState,
}: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);

  const billId = bill?.id;
  const vendorId = bill?.vendorId;

  const { paymentStoreActions } = useJustPayStore();
  const [requestMicroDeposit, , isLoading] = useApi<[string, { bank: any }], any>(
    api.requestMicroDeposit
  );
  const [getVendorsDeliveryMethods, , isValidating] = useApi<[string], any>(
    vendorsApi.getVendorsDeliveryMethods
  );
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  const onMicroDepositCreated = useCallback(
    async ({ fundingSource }) => {
      const origin = locationState?.preservedState?.origin || '';

      await refreshFundingSources();
      analytics.setTraits({ [DbAnalyticsTraitsEnum.ADDED_FUNDING]: true });
      analytics.setFundingSourceTraits();
      analytics.trackAction('push-to-md-submit-success');
      analytics.trackMqlEvent('added-funding', 'mql');
      analytics.trackMqlEvent('added-funding-manual', 'mql');

      if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL) {
        dispatch(selectFundingSourceAction(fundingSource.id));
      }

      if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY) {
        paymentStoreActions.justPay.justPayWizard.update({
          fundingSourceId: fundingSource.id,
          suggestedFundingSources: {
            selectedFundingSourceId: fundingSource.id,
            selectedFundingSource: fundingSource,
          },
        });
      }

      navigate(locations.Onboarding.fundingSources.bank.account.url(), false, {
        ...locationState,
        fundingSourceId: fundingSource.id,
        origin,
      });
    },
    [locationState, refreshFundingSources, dispatch, navigate]
  );

  const handleExitClick = () => {
    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  const handleChooseDifferentMethodClick = () => {
    analytics.trackAction('push-to-md-choose-new-method', { billId, vendorId });

    handleExitClick();
  };

  const submitManualBankAccount = async (value) => {
    const bank = { ...value, accountType: BANK_ACCOUNT_CHECKING_TYPE };
    const validationErrors = await validateBankAccount(bank);

    if (isEmpty(validationErrors)) {
      analytics.trackAction('push-to-md-submit');

      try {
        const result = await requestMicroDeposit(orgId, { bank });

        onMicroDepositCreated(result);
      } catch (error: any) {
        if (error) {
          pushNotification({
            type: NOTIFICATION_VARIANT.ERROR,
            msg: `server.${error.code}`,
          });
        }
      }
    } else {
      throw new ValidationError({ validationErrors });
    }
  };

  const validateBankAccount = async (bank: BankType) => {
    const { routingNumber, accountNumber } = bank;
    const fieldsToValidation = [
      'accountType',
      'routingNumber',
      'accountNumber',
      'accountNumberConfirmation',
    ];
    const syncValidationErrors = getValidationErrors('fundingSourceBank', bank, fieldsToValidation);

    if (!isEmpty(syncValidationErrors)) {
      return syncValidationErrors;
    }

    const { vendorsDeliveryMethods } = await getVendorsDeliveryMethods(orgId);
    const deliveryMethod = vendorsDeliveryMethods.find((deliveryMethodType: DeliveryMethodType) => {
      const { bankAccount } = deliveryMethodType;

      return (
        bankAccount?.routingNumber === routingNumber && bankAccount?.accountNumber === accountNumber
      );
    });

    if (deliveryMethod?.vendor) {
      return {
        accountNumber: intl.formatMessage(
          { id: 'inputErrors.fundingSourceBank.alreadyDefined' },
          {
            vendorName: checkApostropheForPluralPossessive(deliveryMethod.vendor.companyName),
          }
        ),
      };
    }

    return {};
  };

  const model = useMemo(
    () => ({
      routingNumber: '',
      accountNumber: '',
    }),
    []
  );

  const [bankAccountVM, { submit: handleSaveAndContinueClick }] = useForm(model, {
    submit: submitManualBankAccount,
  });

  return (
    <StyledQBOLayoutPage
      title="onboarding.fundingSources.bank.plaidFailed.title"
      subtitle="onboarding.fundingSources.bank.plaidFailed.subtitle"
      nextLabel="onboarding.fundingSources.bank.plaidFailed.cta"
      isLoading={isLoading || isFundingSourcesRefreshing || isValidating}
      footer={
        <WizardChoiceFooter
          label="onboarding.fundingSources.bank.plaidFailed.note"
          link="onboarding.fundingSources.bank.plaidFailed.link"
          onClickLink={handleChooseDifferentMethodClick}
        />
      }
      qboFooter={<div />}
      goExit={handleExitClick}
      onSubmit={handleSaveAndContinueClick}
    >
      <WizardFormContainer>
        <WizardTextInputField
          model={bankAccountVM.routingNumber}
          label="onboarding.fundingSources.bank.manually.routingNumber"
          type="tel"
          autoFocus
          required
          privateData
        />
        <WizardPasswordInputField
          model={bankAccountVM.accountNumber}
          label="onboarding.fundingSources.bank.manually.accountNumber"
          required
          shouldShowValue
          pattern={INPUT_PATTERNS.NUMBERS}
          inputMode="numeric"
        />
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormContainer>
    </StyledQBOLayoutPage>
  );
};

const HiddenInput = styled.input`
  display: none;
`;

const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  ${QBOHeaderContainer} {
    background-color: rgba(244, 245, 248);
  }

  ${WizardFooterContainer} {
    margin-top: 2rem;

    @media ${devices.mobile}, ${devices.phablet} {
      margin-top: 0;
    }
  }

  ${WizardStepActionsContainer} {
    margin-top: 0;

    @media ${devices.mobile}, ${devices.phablet} {
      margin-top: 3rem;
    }
  }

  ${WizardStepTitle} {
    margin-bottom: 1.6rem;

    @media ${devices.mobile}, ${devices.phablet} {
      margin-bottom: 0.8rem;
    }
  }
`;

const PlaidFailedPageContainerComposed = compose(withPreservedStateNavigator())(
  PlaidFailedPageContainer
);

export { PlaidFailedPageContainerComposed as PlaidFailedPageContainer };
