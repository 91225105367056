import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { withNavigator } from 'src/app/hoc';
import { useModal } from 'src/app/helpers/react/useModal';
import useGetVendorPaymentPreferences from 'src/app/modules/vendors/hooks/useGetVendorPaymentPreferences';
import { getPartialBillId } from 'src/app/utils/bills';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { OptionalDeliveryMethodsType } from 'src/app/utils/types';
import locations from 'src/app/utils/locations';
import { AreaLoader } from '@melio/billpay-design-system';
import { getOrgId } from 'src/app/redux/user/selectors';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { WizardStepTitle } from 'src/app/components/layout/QBOWizardElements';
import { getLayoutHeaderLabel } from 'src/app/pages/bill/utils';
import { useFundingSourceNavigate } from 'src/app/pages/bill/pay/hooks/navigation/useFundingSourceNavigate';
import { NotificationDM as CheckFeeNotification } from 'src/app/version-2/components/CheckFee/NotificationDM';
import {
  getBill,
  getDeliveryMethodType,
  getPayment,
  getSelectedFundingSource,
  getIsRecurring,
} from 'src/app/redux/payBillWizard/selectors';
import analytics from 'src/app/services/analytics';
import { selectDeliveryMethodAction } from 'src/app/redux/payBillWizard/actions';
import { INIT_PAY_BILL_FLOW } from 'src/app/redux/payBillWizard/actionTypes';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { useFullOrgSync } from 'src/app/pages/hooks/useFullOrgSync';
import { renderBlockedForPaymentDialog } from 'src/app/utils/vendors';
import { AddFundingSourceWizardOriginEnum } from 'src/app/version-2/model/enums';
import { History } from 'src/app/pages/bill/pay/types';
import { withPayBillData, PayBillProps } from '../hoc/withPayBillData';
import { useGoAddPage } from '../hooks/navigation/useGoAddPage';
import { SelectDeliveryMethodList } from './components';

type Props = PayBillProps;

function PayBillSelectDeliveryMethodPageContainer({
  onPrev,
  goExit,
  onNext,
  navigate,
  getUrlAfterFundingSourceStepForRecoveryFlow,
}: Props) {
  const payment = useSelector(getPayment);
  const bill = useSelector(getBill);
  const history = useHistory<History>();
  const orgId = useSelector(getOrgId);
  const selectedDeliveryMethodType = useSelector(getDeliveryMethodType);
  let selectedFundingSource = useSelector(getSelectedFundingSource);
  const isInternationalEntryPoint = useSelector(
    (state) => state['flags'].isInternationalEntryPoint
  );
  const dispatch = useDispatch();
  const site = useSiteContext();
  const isRecurring = useSelector(getIsRecurring);
  const headerLabel = getLayoutHeaderLabel({
    isRecurring,
    deliverFailureData: payment?.deliverFailureData,
  });
  const { goAddDeliveryMethod } = useGoAddPage({
    navigate,
    getUrlAfterFundingSourceStepForRecoveryFlow,
  });
  const { getUrlAfterFundingSource } = useFundingSourceNavigate({
    navigate,
    getUrlAfterFundingSourceStepForRecoveryFlow,
  });

  const paymentIntentId =
    history.location?.state?.preservedState?.selectedPaymentIntent?.paymentIntentId;
  const paymentIntentItem = history.location?.state?.preservedState?.batchList?.find(
    (batchItem) => batchItem?.id === paymentIntentId
  );
  const isFSWizardBatchBulkOrigin =
    history.location?.state?.preservedState?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK;

  useEffect(() => {
    const isGoingPrev = history.action === 'POP';

    if (isInternationalEntryPoint && !isGoingPrev)
      onDeliveryMethodChange(DELIVERY_TYPE.INTERNATIONAL);

    if (isInternationalEntryPoint && isGoingPrev) {
      history.replace(
        locations.Bills.pay.funding.url({
          orgId,
          id: bill.id,
        })
      );
    }

    return () => {
      if (isBatchBulkFlow) dispatch({ type: INIT_PAY_BILL_FLOW });
    };
  }, []);

  const { vendorPaymentPreferences, isCheckingVendorEnableCCPayments } =
    useGetVendorPaymentPreferences(bill.vendorId);
  const blockDialog = () => renderBlockedForPaymentDialog(site, onCloseBlockPaymentDialog);
  const [BlockPaymentModal, showBlockPaymentModal] = useModal(blockDialog, {});

  useFullOrgSync({ eventPage: 'pay-bill' });

  const isBatchBulkFlow =
    history.location.state?.preservedState?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK;

  if (isBatchBulkFlow) {
    selectedFundingSource =
      history.location.state?.preservedState?.selectedPaymentIntent?.selectedPaymentMethod;
  }

  const onDeliveryMethodChange = (type: OptionalDeliveryMethodsType) => {
    const selectedDeliveryMethod = (bill.vendor?.deliveryMethods || []).find(
      (dm) => dm.deliveryType === type
    );

    if (selectedDeliveryMethod) {
      analytics.track('pay-bill', 'delivery-method-select', {
        type,
        partialBillId: getPartialBillId(bill),
      });
      dispatch(selectDeliveryMethodAction(selectedDeliveryMethod));
    } else {
      analytics.track('pay-bill', 'delivery-method-add', {
        type,
        partialBillId: getPartialBillId(bill),
      });
      goAddDeliveryMethod(type);
    }
  };

  const checkPaymentPreferences = async () => {
    if (isBatchBulkFlow) {
      history.push(history.location.state.redirectUrl, {
        ...(history.location.state as any)?.preservedState,
        newDeliveryMethod: payment.deliveryMethod,
      });
    } else if (
      selectedDeliveryMethodType === DELIVERY_TYPE.ACH &&
      vendorPaymentPreferences?.blockPayments
    ) {
      showBlockPaymentModal();
    } else if (selectedDeliveryMethodType === DELIVERY_TYPE.INTERNATIONAL) {
      const purposeUrl = locations.Vendors.deliveryMethods.international.purposeOfPayment.url({
        orgId,
        id: bill.vendor.id,
      });

      const redirectUrl = await getUrlAfterFundingSource();

      history.push(purposeUrl, {
        ...history.location.state,
        redirectUrl,
      });
    } else {
      onNext();
    }
  };

  const onCloseBlockPaymentDialog = () => {
    history.push(locations.Bills.view.url(), { id: bill.id } as History);
  };

  const goAddVirtualDeliveryMethod = () => {
    analytics.track('pay-bill', 'delivery-method-add', {
      type: DELIVERY_TYPE.VIRTUAL,
      partialBillId: getPartialBillId(bill),
    });
    goAddDeliveryMethod(DELIVERY_TYPE.VIRTUAL);
  };

  const goAddVirtualCardDeliveryMethod = (props: { [key: string]: string }) => {
    analytics.track('pay-bill', 'delivery-method-add', {
      type: DELIVERY_TYPE.VIRTUAL_CARD,
      partialBillId: getPartialBillId(bill),
      vendorId: bill.vendor.id,
      triggerFlow: props.triggerFlow,
    });
    goAddDeliveryMethod(DELIVERY_TYPE.VIRTUAL_CARD);
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  let amount: number;

  if (isFSWizardBatchBulkOrigin) {
    amount = paymentIntentItem?.payment?.amount;
  } else {
    amount = (payment?.amount ?? bill.balance) as number;
  }

  const companyName = bill.vendor?.companyName;

  const batchBulkNavigationBarHandler = () => {
    history.push(history.location.state?.preservedState?.redirectUrl, {
      ...history.location.state.preservedState,
    });
  };

  const onPrevHandler = isBatchBulkFlow ? batchBulkNavigationBarHandler : onPrev;

  const onGoExitHandler = isBatchBulkFlow ? batchBulkNavigationBarHandler : goExit;

  return (
    !isInternationalEntryPoint && (
      <>
        {BlockPaymentModal}
        <QBOLayoutPage
          headerLabel={headerLabel}
          headerLabelValues={{
            amount: <MIFormattedCurrency value={amount} />,
            companyName,
          }}
          relativeStep={2 / 5}
          goExit={onGoExitHandler}
          onPrev={onPrevHandler}
          onNext={checkPaymentPreferences}
          isLoading={isCheckingVendorEnableCCPayments}
          innerSize={50}
          isNextDisabled={!selectedDeliveryMethodType}
          isHideActions={isEmpty(bill.vendor?.deliveryMethods)}
        >
          <WizardStepTitle>
            <MIFormattedText
              label="bills.pay.deliveryMethods.title"
              values={{ vendor: companyName }}
            />
          </WizardStepTitle>
          <CheckFeeNotification selectedFundingSource={selectedFundingSource} />
          <SelectDeliveryMethodList
            amount={amount?.toString()}
            bill={bill}
            selectedFundingSource={selectedFundingSource}
            companyName={companyName}
            selectedDeliveryMethodType={selectedDeliveryMethodType}
            onDeliveryMethodChange={onDeliveryMethodChange}
            goAddVirtualCardDeliveryMethod={goAddVirtualCardDeliveryMethod}
            goAddVirtualDeliveryMethod={goAddVirtualDeliveryMethod}
          />
        </QBOLayoutPage>
      </>
    )
  );
}

const PayBillSelectDeliveryMethodPageContainerComposed = compose(
  withNavigator(),
  withPayBillData()
)(PayBillSelectDeliveryMethodPageContainer);

export { PayBillSelectDeliveryMethodPageContainerComposed as PayBillSelectDeliveryMethodPageContainer };
