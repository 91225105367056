import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/app/hoc/useApi';
import deliveryApi from 'src/app/services/api/delivery';
import { getOrgId } from 'src/app/redux/user/selectors';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';

type ApprovalDecisionStatusResponse = {
  approvalDecisionStatus: PAYMENT_APPROVAL_STATUS;
};

type Args = { status: string | undefined; pending: boolean; skip: boolean };

function useApprovalDesicionStatus({ amount }): Args {
  const orgId = useSelector(getOrgId);

  const [getApprovalDecisionStatus, result] = useApi<
    [string, string],
    ApprovalDecisionStatusResponse
  >(deliveryApi.getApprovalDecisionStatus);
  const status = result?.approvalDecisionStatus;

  useEffect(() => {
    getApprovalDecisionStatus(orgId, amount);
  }, [amount]);

  return {
    status: result?.approvalDecisionStatus,
    pending: status === PAYMENT_APPROVAL_STATUS.PENDING,
    skip: status === PAYMENT_APPROVAL_STATUS.SKIP,
  };
}

export default useApprovalDesicionStatus;
