import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';

import styled from 'styled-components';
import MIButton from 'src/app/components/common/MIButton';
import OutsideLayout from 'src/app/components/layout/OutsideLayout';
import MIRadioGroup, { Option } from 'src/app/components/form/MIRadioGroup';
import {
  ContentWrapper,
  SubmitButtonMobile,
} from 'src/app/pages/vendor/components/VendorLayoutElements';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useBreak } from 'src/app/hoc';
import profileStore from 'src/app/modules/profile/profile-store';
import { checkAndInitUserAction } from 'src/app/redux/user/actions';
import { getOrgId } from 'src/app/redux/user/selectors';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import locations from './locations';

type Props = {
  token: string;
  vendorId: string;
};

const SwitchCompanyPage = ({ token, vendorId }: Props) => {
  const { payerCompanyName } = getJWTPayload(token);
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useBreak();
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(orgId);

  const options: Option<string>[] = useMemo(
    () =>
      organizations.map((o) => ({
        id: o.id,
        label: 'companies.acceptRequest.companyOption',
        labelValues: { companyName: o.companyName },
      })),
    [organizations]
  );

  const initUserContext = (orgId) =>
    new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
    });

  const onCompanySwitch = async () => {
    if (orgId !== selectedOrganizationId) {
      await initUserContext(selectedOrganizationId);
    }

    history.push(generatePath(locations.addMethod, { token, id: vendorId }));
  };

  return (
    <>
      <OutsideLayout>
        <HeaderContentContainer>
          <HeaderTitle>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftVendorToAch.addAchTitle"
              values={{
                companyName: <CompanyName>{payerCompanyName}</CompanyName>,
              }}
            />
          </HeaderTitle>
        </HeaderContentContainer>
        <ContentWrapper title="vendors.addDeliveryMethodByLink.switchCompanyTitle">
          <SelectOrganizationsWrapper>
            <MIRadioGroup
              id="organization"
              colDirection
              group="organization"
              options={options}
              selected={selectedOrganizationId}
              onSelect={setSelectedOrganizationId}
            />
          </SelectOrganizationsWrapper>
          {!isMobile && (
            <MIButton
              onClick={onCompanySwitch}
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              label="progress.continue"
              fullWidth
            />
          )}
        </ContentWrapper>
      </OutsideLayout>
      {isMobile && <SubmitButtonMobile label="progress.continue" submit={onCompanySwitch} />}
    </>
  );
};

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const HeaderTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.2rem;
`;

const CompanyName = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const SelectOrganizationsWrapper = styled.div`
  padding: 2rem 0 4rem;
  overflow: auto;
  max-height: 40rem;
`;

export default SwitchCompanyPage;
