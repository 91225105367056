import { ModalContainer } from 'src/app/components/common/ModalMessage';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';

export const MainContainer = styled.div`
  padding: 0 4rem 2rem;
  z-index: 1;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
`;

export const Divider = styled.div`
  padding-top: 2rem;
  border-top: ${({ theme }) => `1px solid ${theme.colors.ds.gray['400']}`};
`;

export const InputsContainer = styled.div`
  margin-top: 2rem;
  position: relative;
  overflow-y: scroll;
  max-height: 58vh;
`;

export const ModalContainerNoPadding = styled(ModalContainer)`
  padding: 3.5rem 0 4rem 0;
  position: relative;
  overflow: hidden;
`;

export const WizardText = styled.div<{ textAlign?: string }>`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.wizardText};
  margin-bottom: 4rem;
  align-self: center;
  line-height: 2rem;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;
