import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsMethodsListItem from 'src/app/components/common/SelectMethods/components/SettingsMethodsListItem';
import { getFundingSources } from 'src/app/redux/user/selectors';
import { getOrgFeeFundingSource } from 'src/app/redux/organization/selectors';
import { useVerifyMicroDeposits } from 'src/app/pages/bill/pay/hooks/useVerifyMicroDeposits';
import { getPartialBillId } from 'src/app/utils/bills';
import {
  canVerifyManualBankAccount,
  isBankAccountBlocked,
  isManualBankAccountNotVerified,
} from 'src/app/utils/funding-sources';
import VerifyMicroDeposits from 'src/app/components/micro-deposits/VerifyMicroDeposits';
import intercomService from 'src/app/services/intercom';
import analytics from 'src/app/services/analytics';
import { FUNDING_SOURCE_ORIGIN } from 'src/app/utils/consts';
import { loadFundingSourcesAction } from 'src/app/redux/user/actions';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { getPaymentMethodNameParts } from 'src/app/version-2/utils/paymentMethods.utils';
import { getImages } from './utils';
import { ChatLink, VerifyAccountLink } from './styles';

const eventPage = 'existing-own-funding-source';
const FORMAT_PREFIX = 'settings.billing.feeCard.checks.cards.ownFS';

const openSupportChat = () => {
  analytics.track('qbo', 'support-open-start-chat');
  intercomService.show();
};

function getDescriptionValues(fundingSource, onVerifyClicked) {
  if (onVerifyClicked && canVerifyManualBankAccount(fundingSource)) {
    return {
      link: (
        <VerifyAccountLink
          testId={`verify-funding-source-${fundingSource.id}`}
          label="settings.paymentMethods.verifyAccountHint"
          onClick={(event) => {
            event.preventDefault();
            onVerifyClicked && onVerifyClicked(fundingSource.id);
            event.stopPropagation();
          }}
        />
      ),
    };
  }

  return {
    link: '',
    chatWithUs: (
      <ChatLink
        to={openSupportChat}
        label="settings.paymentMethods.microDepositsBlockedContactLabel"
      />
    ),
  };
}

export const ExistingOwnFundingSource = ({ actions }) => {
  const fundingSources = useSelector(getFundingSources);
  const selectedOrgFeeFundingSource = useSelector(getOrgFeeFundingSource);

  const fundingSource = useMemo(
    () => fundingSources.find((fs) => fs.id === selectedOrgFeeFundingSource.fundingSourceId),
    [fundingSources, selectedOrgFeeFundingSource]
  );

  const partialBillId = getPartialBillId({});

  const {
    openVerifyMicrodepositsModal,
    verifyMicrodepositsModalProps,
    shouldShowVerifyMicrodepositsModal: showVerifyMicrodepositsModal,
  } = useVerifyMicroDeposits({
    partialBillId,
    eventPage,
    withoutDeliveryMethod: true,
  });

  const dispatch = useDispatch();

  const onVerifyFailed = () => {
    dispatch(loadFundingSourcesAction());
  };

  const descriptionValues = getDescriptionValues(fundingSource, openVerifyMicrodepositsModal);

  if (fundingSource?.fundingType === FundingSourceTypesEnum.ACH) {
    const { icon, imageSrc } = getImages(fundingSource, false);

    const { displayName, identifier, institutionName } = getPaymentMethodNameParts({
      fundingSource,
    });

    const bank = fundingSource.origin === FUNDING_SOURCE_ORIGIN.QBCASH ? 'qbcash' : 'bank';
    const label = institutionName
      ? `${FORMAT_PREFIX}.${bank}.labelWithInstitution`
      : `${FORMAT_PREFIX}.${bank}.label`;
    const getFundingSourceDescription = () => {
      if (isManualBankAccountNotVerified(fundingSource)) {
        if (isBankAccountBlocked(fundingSource)) {
          return 'settings.paymentMethods.microDepositsBlocked';
        }
      }

      const text = fundingSource.isVerified ? 'description' : 'descriptionUnverified';

      return `${FORMAT_PREFIX}.${bank}.${text}`;
    };

    const description = getFundingSourceDescription();

    return (
      <>
        <SettingsMethodsListItem
          {...{ icon, imageSrc, label, descriptionValues }}
          id="existing-own-funding-source-bank-account"
          description={description}
          labelValues={{ displayName, identifier, institutionName }}
          actionOptions={actions}
          hint=""
          origin=""
        />
        {showVerifyMicrodepositsModal && (
          <VerifyMicroDeposits
            onVerifyFailed={onVerifyFailed}
            {...verifyMicrodepositsModalProps}
            fundingSourceId={verifyMicrodepositsModalProps.key}
          />
        )}
      </>
    );
  }

  if (fundingSource?.cardAccount) {
    const { icon, imageSrc } = getImages(fundingSource, false);

    const {
      cardAccount: { card4digits, network },
    } = fundingSource;

    return (
      <SettingsMethodsListItem
        icon={icon}
        imageSrc={imageSrc}
        id="existing-own-funding-source-credit-card"
        label={`${FORMAT_PREFIX}.creditcard.label`}
        description={`${FORMAT_PREFIX}.creditcard.description`}
        labelValues={{ network, card4digits }}
        actionOptions={actions}
        hint=""
        origin="origin"
      />
    );
  }

  return null;
};
