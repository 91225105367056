import React, { useMemo } from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { ColumnItem } from '../components/QBODataTable/types';
import QBODueDateCell from '../components/QBODataTable/components/QBODueDateCell';
import QBOVendorNameCell from '../components/QBODataTable/components/QBOVendorNameCell/QBOVendorNameCell';
import { QBOInvoiceNumberCell } from '../components/QBODataTable/components/QBOInvoiceNumberCell';
import QBOAmountCell from '../components/QBODataTable/components/QBOAmountCell';
import QBODeductedDateCell from '../components/QBODataTable/components/QBODeductedDateCell';
import QBOScheduledStatusCell from '../components/QBODataTable/components/QBOScheduledStatusCell';
import QBODeliveryEtaCell from '../components/QBODataTable/components/QBODeliveryEtaCell';
import QBOPaymentMethodCell from '../components/QBODataTable/components/QBOPaymentMethodCell';
import QBODeliveryMethodCell from '../components/QBODataTable/components/QBODeliveryMethodCell';
import QBOScheduledActionCell from '../components/QBODataTable/components/QBOScheduledActionsCell';
import { DashboardListItemType } from '../types';
import useDashboardTableVariant, { DASHBOARD_TABLE_VARIANT } from './useDashboardTableVariant';
import { DASHBOARD_COLUMNS } from '../consts';
import { QBOPaymentAmountCell } from '../components/QBODataTable/components/QBOPaymentAmountCell';

type ResponseType = [ColumnItem<DashboardListItemType>[]];

function useScheduledColumns(): ResponseType {
  const dashboardVariant = useDashboardTableVariant();
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const scheduledColumns = useMemo(() => {
    let columns = [
      {
        Header: <MIFormattedText label="paymentDashboard.columns.vendor.title" />,
        accessor: DASHBOARD_COLUMNS.VENDOR_NAME,
        Cell: QBOVendorNameCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.invoiceNumber.title" />,
        accessor: DASHBOARD_COLUMNS.INVOICE_NUMBER,
        Cell: QBOInvoiceNumberCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.dueDate.title" />,
        accessor: DASHBOARD_COLUMNS.DUE_DATE,
        Cell: QBODueDateCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.scheduledStatus.title" />,
        accessor: DASHBOARD_COLUMNS.STATUS,
        Cell: QBOScheduledStatusCell,
        width: '14.3rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.paymentMethod.title" />,
        accessor: DASHBOARD_COLUMNS.FUNDING_TYPE,
        Cell: QBOPaymentMethodCell,
        width: '13.3rem',
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.deductionDate.title" />,
        accessor: DASHBOARD_COLUMNS.SCHEDULED_DATE,
        Cell: QBODeductedDateCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.deliveryMethod.title" />,
        accessor: DASHBOARD_COLUMNS.DELIVERY_TYPE,
        Cell: QBODeliveryMethodCell,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.deliveryEta.title" />,
        accessor: DASHBOARD_COLUMNS.DELIVERY_ETA,
        Cell: QBODeliveryEtaCell,
      },
      isPartialPaymentsEnabled
        ? {
            Header: <MIFormattedText label="paymentDashboard.columns.paymentAmount.title" />,
            accessor: DASHBOARD_COLUMNS.PAYMENT_AMOUNT,
            Cell: QBOPaymentAmountCell,
            isNumeric: true,
          }
        : {
            Header: <MIFormattedText label="paymentDashboard.columns.amount.title" />,
            accessor: DASHBOARD_COLUMNS.TOTAL_AMOUNT,
            Cell: QBOAmountCell,
            isNumeric: true,
          },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.actions.title" />,
        accessor: DASHBOARD_COLUMNS.ACTIONS,
        width: '18rem',
        Cell: QBOScheduledActionCell,
        isNumeric: true,
        isOrderingDisabled: true,
      },
    ];

    if (dashboardVariant === DASHBOARD_TABLE_VARIANT.LIMITED) {
      columns = columns.filter(
        ({ accessor }) =>
          ![DASHBOARD_COLUMNS.DELIVERY_TYPE, DASHBOARD_COLUMNS.FUNDING_TYPE].includes(accessor)
      );
    }

    return columns;
  }, [dashboardVariant]);

  return [scheduledColumns];
}

export default useScheduledColumns;
