import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import paymentsApi from 'src/app/services/api/payments';
import { isManuallyPaid } from 'src/app/utils/bills';
import { BILL_STATUS } from 'src/app/utils/consts';
import { BillType, IntuitBankAccountType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import { AccountRecord } from '../../../settings/records';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { getOrgId } from 'src/app/redux/user/selectors';
import intuitBankAccountsStore from 'src/app/modules/intuit-bank-accounts/intuit-bank-accounts-store';
import useFetchIntuitBankAccounts from 'src/app/modules/intuit-bank-accounts/hooks/useFetchIntuitBankAccounts';
import { convertPaymentFeeApiObj } from 'src/app/utils/payments';
import { defaultFeeObject } from 'src/app/utils/fee';
import { DataOriginEnum } from 'src/app/version-2/model/enums';
import { DeliveryMethodRecord } from '../../../vendor/records-constants';
import QBOViewBillPaymentReview from '../QBOViewBillPaymentReview';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

import {
  Container,
  MarkAsPaidAccountInfo,
  MarkAsPaidContainer,
  MarkedAsPaidInfoContainer,
  Separator,
  WrappedPaymentProcessingBlockedNotificationCard,
} from './ViewPaymentActivity.styles';
import PaymentTimeline from 'src/app/pages/bill/components/PaymentTimeline';

type Props = {
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  isPartialPayment: boolean;
};

const QBOBillPaymentsActivity = ({ bill, payment, isPartialPayment }: Props) => {
  const isTimelineFeatureEnabled = featureFlags.defaultClient.getVariant(
    FeatureFlagsEnum.PAYMENT_DETAILS_TIMELINE,
    false
  );
  const orgId = useSelector(getOrgId);
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const isBatchBulkCombined = (bill.totalAmount || 0) < (payment.amount || 0);
  const currentAmount = isPartialPayment || isBatchBulkCombined ? payment.amount : bill.totalAmount;

  useFetchIntuitBankAccounts();
  const intuitAccount: IntuitBankAccountType | undefined = useSelector(
    intuitBankAccountsStore.selectors.byId(payment?.intuitAccountId)
  );

  const [fee, setFee] = useState<Record<string, any> | null>(null);
  const markedAsPaid = isPartialPayment
    ? payment?.manual
    : isManuallyPaid(bill.status, payment?.manual);

  const fundingSource = AccountRecord(
    (useSelector(fundingSourcesStore.selectors.byId(payment.fundingSourceId)) ||
      payment.fundingSource) as FundingSource
  );

  const getFeeApi = async () => {
    try {
      const { fees } = await paymentsApi.getPaymentFeeApi(orgId, {
        paymentId: payment.id,
        paymentOrigin: DataOriginEnum.INTUIT,
      });

      // convert current fee to component sturcture
      setFee(convertPaymentFeeApiObj(fees));
    } catch (e) {
      setFee(defaultFeeObject);
    }
  };

  useEffect(() => {
    if (!markedAsPaid) {
      getFeeApi();
    } else {
      setFee(null);
    }
  }, [
    payment.deliveryMethodId,
    payment.organizationId,
    payment.fundingSourceId,
    bill.totalAmount,
    payment.scheduledDate,
    markedAsPaid,
    payment.deliveryPreference,
  ]);

  const deliveryMethod = DeliveryMethodRecord(payment.deliveryMethod);

  return (
    <Container>
      {!isTimelineFeatureEnabled && <Separator />}
      <>
        {bill.status === BILL_STATUS.SCHEDULED && (
          <WrappedPaymentProcessingBlockedNotificationCard payment={payment} bill={bill} />
        )}
        {isTimelineFeatureEnabled && <PaymentTimeline payment={payment} bill={bill} />}
        <QBOViewBillPaymentReview
          bill={bill}
          fee={fee as any}
          fundingSource={fundingSource}
          deliveryMethod={deliveryMethod}
          payment={payment}
          currentAmount={currentAmount}
          qbCashState={qbCashState}
          isPartialPayment={isPartialPayment}
          isMarkedAsPaid={markedAsPaid}
          isBatchBulkCombined={isBatchBulkCombined}
        />
        {markedAsPaid && (
          <MarkedAsPaidInfoContainer>
            <MarkAsPaidContainer>
              <MIFormattedText
                label="bills.view.markAsPaidInfo"
                values={{
                  date: <MIFormattedDate date={payment.scheduledDate} />,
                }}
              />
            </MarkAsPaidContainer>
            {intuitAccount && (
              <MarkAsPaidAccountInfo>
                <MIFormattedText
                  label="bills.view.markAsPaidAcountInfo"
                  values={{
                    account: intuitAccount.name,
                  }}
                />
              </MarkAsPaidAccountInfo>
            )}
          </MarkedAsPaidInfoContainer>
        )}
      </>
    </Container>
  );
};

export default QBOBillPaymentsActivity;
