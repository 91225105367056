import React from 'react';
import { Box } from '@melio/billpay-design-system';

import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';

function EditVendorForm({ vendorMV }) {
  return (
    <>
      <Box m="2rem 0">
        <InlineTextInputField
          label="paymentDashboard.editVendor.name"
          model={vendorMV?.companyName}
          required
        />
      </Box>
      <Box>
        <InlineTextInputField
          label="paymentDashboard.editVendor.email"
          model={vendorMV?.contactEmail}
        />
      </Box>
    </>
  );
}

export default EditVendorForm;
