import * as React from 'react';
import styled, { css } from 'styled-components';
import analytics from 'src/app/services/analytics';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';

export type Option = {
  id: string;
  label: string;
  disabled?: boolean;
  icon?: string;
  hint?: string;
};

type Props = {
  onSelect: (id: string) => void;
  categories: Option[];
  selectedId?: string | null;
};

const QBRCategoryList = ({ categories, onSelect, selectedId }: Props) => {
  const onSelectCategory = (id) => {
    analytics.trackAction('select-category', { value: id });
    onSelect(id);
  };

  return (
    <ListContainer>
      {categories.map(({ id, label, icon, disabled, hint }) => (
        <Label
          htmlFor={id}
          key={id}
          selected={id === selectedId}
          disabled={disabled}
          data-testid={`category-list-${id}`}
          itemIcon={icon}
        >
          {icon && (
            <IconContainer>
              <MethodOptionIcon className={icon} disabled={disabled} />
            </IconContainer>
          )}
          <Category selected={id === selectedId} disabled={disabled}>
            <Input type="radio" id={id} onClick={() => onSelectCategory(id)} disabled={disabled} />
            <MIFormattedText label={label} />
            {hint && (
              <HintContainer>
                <MIFormattedText label={hint} />
              </HintContainer>
            )}
          </Category>
        </Label>
      ))}
    </ListContainer>
  );
};

export default QBRCategoryList;

const Category = styled.div<{ disabled?: boolean; selected?: boolean }>`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.medium};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.text.fontType.regular};

  @media ${devices.desktop}, ${devices.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Input = styled.input`
  display: none;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
  width: 100%;

  @media ${devices.desktop}, ${devices.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Label = styled.label<{
  selected?: boolean;
  itemIcon?: string;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  padding: 2rem 1.6rem;
  cursor: pointer;
  color: ${(props) => props.theme.text.color.subtitle};
  border-radius: 0.8rem;
  box-sizing: border-box;
  box-shadow: 0 5px 10px 0 rgba(33, 33, 36, 0.15);
  width: 100%;
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin-bottom: 2rem;
  transition: ${(props) => props.theme.animation.transition.default};

  &:nth-child(even) {
    margin-left: 0rem;
  }

  flex-direction: row;
  align-items: center;

  ${(props) =>
    !props.selected &&
    !props.disabled &&
    css`
      &:hover {
        box-shadow: ${(props) => `0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2}`};
      }
    `};

  @media ${devices.desktop}, ${devices.tablet} {
    width: 21rem;
    height: 14rem;
    margin-bottom: 4rem;
    flex-direction: column;
    justify-content: center;
  }
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: 1.6rem;
  background-color: ${(props) => props.theme.colors.white.lightBlue};
  min-width: 4.4rem;
  min-height: 4.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${devices.desktop}, ${devices.tablet} {
    margin: 0 0 1.2rem;
  }
`;

const HintContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  ${(props) => props.theme.text.fontType.medium};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const MethodOptionIcon = styled.i<{ disabled?: boolean }>`
  color: ${(props) =>
    props.disabled ? props.theme.text.color.label : props.theme.colors.icon.grey};
  font-size: 2.4rem;
`;
