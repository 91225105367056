import { combineReducers } from 'redux';
import billsStore from 'src/app/modules/batch-bills/batch-bills-store';
import bills from 'src/app/modules/bills/bills-store';
import { connectRouter } from 'connected-react-router';
import { USER_SLICE, userSlice } from 'src/app/version-2/modules/user/user.slice';
import notificationsReducer from './notifications/reducer';
import {
  APPLICATION_SLICE,
  applicationSlice,
} from 'src/app/version-2/modules/application/application.slice';
import {
  BATCH_BULK_SLICE,
  batchBulkSlice,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  VIRTUAL_CARD_DETAILS_SLICE,
  virtualCardDetailsSlice,
} from 'src/app/version-2/pages/virtual-card-details/modules/virtualCardDetails.slice';
import {
  LANDING_PAGE_SLICE,
  landingPageSlice,
} from 'src/app/version-2/pages/landing-pages/modules/landingPage.slice';
import {
  ADD_CARD_ACCOUNT,
  addCardAccountReducer,
} from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.reducer';
import {
  ACH_DELIVERY_METHOD_DETAILS_SLICE,
  achDeliveryMethodDetailsSlice,
} from 'src/app/version-2/pages/ach-delivery-method-details/modules/achDeliveryMethodDetails.slice';
import { intuitSlice } from 'src/app/version-2/modules/intuit/intuit.slice';
import {
  FUNDING_SOURCES_SLICE,
  fundingSourcesSlice,
} from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import {
  ORGANIZATION_SLICE,
  organizationSlice,
} from 'src/app/version-2/modules/organization/organization.slice';
import {
  UBO_SLICE,
  uboSlice,
} from 'src/app/version-2/modules/ultimateBeneficialOwners/ultimateBeneficialOwners.slice';
import {
  COUNTRIES_SLICE,
  countriesSlice,
} from 'src/app/version-2/modules/countries/countries.slice';
import {
  globalOptInSlice,
  GLOBAL_OPT_IN_SLICE,
} from 'src/app/version-2/pages/global-opt-in-details/modules/globalOptIn.slice';
import { refundSlice, REFUND_SLICE } from 'src/app/version-2/pages/refund/modules/refund.slice';
import { PAYMENTS_SLICE, paymentsSlice } from 'src/app/version-2/modules/payments/payments.slice';
import {
  COLLECT_INVOICE_SLICE,
  collectInvoiceSlice,
} from 'src/app/version-2/modules/collectInvoice/collectInvoice.slice';
import {
  UPGRADE_PAYMENT_SLICE,
  upgradePaymentSlice,
} from 'src/app/version-2/pages/vendor-upgrade-payment/modules/upgradePayment.slice';
import payBillWizard from './payBillWizard/reducer';
import user from './user/reducer';
import { organizationReducer } from './organization/reducer';
import { errorReducer } from './error/reducers';
import auth from '../modules/auth/auth-store';
import users from '../modules/users/users-store';
import profile from '../modules/profile/profile-store';
import organizations from '../modules/organizations/organizations-store';
import vendors from '../modules/vendors/vendors-store';
import payments from '../modules/payments/payment-store';
import deliveryMethods from '../modules/delivery-methods/delivery-methods-store';
import quickpayBills from '../modules/quickpay-bills/quickpay-bills-store';
import fundingSources from '../modules/funding-sources/funding-sources-store';
import intuitBankAccountsStore from '../modules/intuit-bank-accounts/intuit-bank-accounts-store';
import { qbDashboardListItemsStore } from '../modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { flagsStore } from '../modules/flags/flags-store';
import { fullOrgSyncStore } from '../modules/fullOrgSync/full-org-sync-store';

export default function rootReducer(history) {
  const reducers = combineReducers({
    payBillWizard,
    user,
    organization: organizationReducer,
    error: errorReducer,
    bills: bills.reducer,
    auth: auth.reducer,
    users: users.reducer,
    profile: profile.reducer,
    organizations: organizations.reducer,
    notifications: notificationsReducer,
    vendors: vendors.reducer,
    payments: payments.reducer,
    billsStore: billsStore.reducer,
    deliveryMethods: deliveryMethods.reducer,
    quickpayBills: quickpayBills.reducer,
    fundingSources: fundingSources.reducer,
    qbDashboardListItems: qbDashboardListItemsStore.reducer,
    router: connectRouter(history),
    intuitBankAccounts: intuitBankAccountsStore.reducer,
    flags: flagsStore.reducer,
    fullOrgSync: fullOrgSyncStore.reducer,
    [USER_SLICE]: userSlice.reducer,
    [APPLICATION_SLICE]: applicationSlice.reducer,
    [BATCH_BULK_SLICE]: batchBulkSlice.reducer,
    [VIRTUAL_CARD_DETAILS_SLICE]: virtualCardDetailsSlice.reducer,
    [LANDING_PAGE_SLICE]: landingPageSlice,
    [FUNDING_SOURCES_SLICE]: fundingSourcesSlice.reducer,
    [ORGANIZATION_SLICE]: organizationSlice.reducer,
    [UBO_SLICE]: uboSlice.reducer,
    [COUNTRIES_SLICE]: countriesSlice.reducer,
    [PAYMENTS_SLICE]: paymentsSlice.reducer,
    intuit: intuitSlice.reducer,
    [ADD_CARD_ACCOUNT]: addCardAccountReducer(),
    [ACH_DELIVERY_METHOD_DETAILS_SLICE]: achDeliveryMethodDetailsSlice.reducer,
    [COLLECT_INVOICE_SLICE]: collectInvoiceSlice.reducer,
    [GLOBAL_OPT_IN_SLICE]: globalOptInSlice.reducer,
    [REFUND_SLICE]: refundSlice.reducer,
    [UPGRADE_PAYMENT_SLICE]: upgradePaymentSlice.reducer,
  });

  return (state, action) => reducers(state, action);
}
