import React from 'react';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import noPaymentCreated from 'src/app/images/qbo/no-payment-created-icon.png';
import analytics from 'src/app/services/analytics';
import { billNotFound } from 'src/app/utils/external-events';

export const BillNotFoundErrorPage = () => {
  const goExit = () => {
    analytics.track('pay-bill-confirm', 'close-integration');
    billNotFound();
  };

  return (
    <ErrorPage
      illustration={noPaymentCreated}
      title="bills.view.error.notMelioBillTitle"
      subtitle="bills.view.error.notMelioBillSubtitle"
      buttonAction={goExit}
      buttonLabel="bills.view.error.buttonTextBillNoMelioFound"
    />
  );
};
