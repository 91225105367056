import styled, { css } from 'styled-components';
import { Tooltip } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  inputId?: string;
  label: string;
  required?: boolean;
  size?: 'inline' | 'wizard';
  tooltipLabel?: string;
  errorMessage?: string | null;
  labelValues?: Record<string, any>;
};

export const MIInputLabel = ({
  inputId,
  label,
  required,
  size = 'wizard',
  tooltipLabel,
  errorMessage,
  labelValues,
}: Props) => {
  if (!label) return null;

  return (
    <InputLabel
      errorMessage={errorMessage}
      size={size}
      htmlFor={inputId}
      hasTooltip={!!tooltipLabel}
    >
      <MIFormattedText label={label} values={labelValues} />

      {tooltipLabel && (
        <Tooltip placement="top" label={<MIFormattedText label={tooltipLabel} />}>
          <ToolTipIcon className="icon-info-hollow-icon" />
        </Tooltip>
      )}

      {!required && (
        <Optional>
          <MIFormattedText label="input.optional" />
        </Optional>
      )}
    </InputLabel>
  );
};

const InputLabel = styled.label<{
  errorMessage?: string | null;
  size?: 'inline' | 'wizard';
  hasTooltip?: boolean;
}>`
  min-height: 1.7rem;
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.02rem;
  line-height: 1.8rem;
  display: block;
  text-align: left;
  margin-bottom: ${(props) => (props.size === 'inline' ? '0' : '0.8rem')};

  color: ${(props) => {
    if (props.errorMessage) {
      return props.theme.text.color.error;
    }

    return props.theme.text.color.label;
  }};
  ${(props) => props.theme?.components?.MIInputLabel?.InputLabel}

  ${(props) =>
    props.hasTooltip &&
    css`
      line-height: normal;
      display: flex;
      align-items: center;
    `}
`;

const Optional = styled.span`
  font-size: 1.2rem;
  text-transform: none;
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.MIInputLabel?.Optional}
`;

const ToolTipIcon = styled.i`
  margin-left: 0.5rem;
  font-size: 1.5rem;
`;
