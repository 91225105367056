import { isValidationOk, getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';

export type VendorEmailValidationErrors = {
  contactEmail?: string;
};

type ValidateReturn = {
  validationErrors: VendorEmailValidationErrors;
  isValid: boolean;
};

type Options = {
  required?: boolean;
};

export const validateVendorEmail = (
  email?: string,
  options: Options = {
    required: true,
  }
): ValidateReturn => {
  if (isEmpty(email) && options.required) {
    return {
      validationErrors: {
        contactEmail: 'inputErrors.vendor.contactEmail.any.empty',
      },
      isValid: false,
    };
  }

  const validationErrors = getValidationErrors('vendor', { contactEmail: email }, ['contactEmail']);
  const isValid = isValidationOk(validationErrors);

  return {
    validationErrors,
    isValid,
  };
};
