import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from '../../utils/formatting';
import { FAQFeesTable } from './FAQFeesTable';
import analytics from '../../services/analytics';

const FAQ = [
  {
    question: 'drawer.faq.question1',
    answer: 'drawer.faq.answer1',
    id: 'faq-1',
  },
  {
    question: 'drawer.faq.question2',
    answer: 'drawer.faq.answer2',
    id: 'faq-2',
  },
  {
    question: 'drawer.faq.question3',
    answer: 'drawer.faq.answer3',
    id: 'faq-3',
  },
  {
    question: 'drawer.faq.question4',
    answer: 'drawer.faq.answer4',
    id: 'faq-4',
  },
  {
    question: 'drawer.faq.question5',
    answer: 'drawer.faq.answer5',
    id: 'faq-5',
  },
  {
    question: 'drawer.faq.question6',
    answer: 'drawer.faq.answer6',
    id: 'faq-6',
  },
  {
    question: 'drawer.faq.question7',
    answer: 'drawer.faq.answer7',
    id: 'faq-7',
  },
  {
    question: 'drawer.faq.question21',
    answer: 'drawer.faq.answer21',
    id: 'faq-21',
  },
  {
    question: 'drawer.faq.question8',
    answer: 'drawer.faq.answer8',
    id: 'faq-8',
  },
  {
    question: 'drawer.faq.question9',
    answer: 'drawer.faq.answer9',
    id: 'faq-9',
  },
  {
    question: 'drawer.faq.question10',
    answer: 'drawer.faq.answer10',
    id: 'faq-10',
  },
  {
    question: 'drawer.faq.question11',
    answer: 'drawer.faq.answer11',
    id: 'faq-11',
  },
  {
    question: 'drawer.faq.question12',
    answer: 'drawer.faq.answer12',
    id: 'faq-12',
  },
  {
    question: 'drawer.faq.question13',
    answer: 'drawer.faq.answer13',
    id: 'faq-13',
  },
  {
    question: 'drawer.faq.question14',
    answer: 'drawer.faq.answer14',
    id: 'faq-14',
  },
  {
    question: 'drawer.faq.question15',
    answer: 'drawer.faq.answer15',
    id: 'faq-15',
  },
  {
    question: 'drawer.faq.question16',
    answer: 'drawer.faq.answer16',
    id: 'faq-16',
  },
  {
    question: 'drawer.faq.question17',
    answer: 'drawer.faq.answer17',
    id: 'faq-17',
  },
  {
    question: 'drawer.faq.question18',
    answer: 'drawer.faq.answer18',
    id: 'faq-18',
  },
  {
    question: 'drawer.faq.question19',
    answer: 'drawer.faq.answer19',
    id: 'faq-19',
  },
  {
    question: 'drawer.faq.question20',
    answer: 'drawer.faq.answer20',
    id: 'faq-20',
  },
];

const QBOAccordion = () => (
  <AccordionList>
    {FAQ.map(({ question, answer, id }) => (
      <AccordionListItem key={question}>
        <AccordionItems question={question} answer={answer} id={id} />
      </AccordionListItem>
    ))}
  </AccordionList>
);

type AccordionProps = {
  question: string;
  answer: string;
  id: string;
};

const AccordionItems = ({ question, answer, id }: AccordionProps) => {
  const [isOpened, setOpened] = useState(false);
  const site = useSiteContext();
  const intl = useIntl();
  const showAccordionItem = intl.messages[question];
  const toggleItems = () => {
    analytics.track('qbo', isOpened ? 'answer-close' : 'answer-open');
    setOpened((prevOpen) => !prevOpen);
  };

  if (!showAccordionItem) {
    return null;
  }

  return (
    <AccordionItem id={id}>
      <AccordionItemLine onClick={toggleItems}>
        <AccordionItemTitle>
          <MIFormattedText label={question} />
        </AccordionItemTitle>
        <AccordionItemIcon>
          <i className={isOpened ? 'icon-minus-icon' : 'icon-plus-icon'} />
        </AccordionItemIcon>
      </AccordionItemLine>
      <AccordionItemInner className={isOpened ? 'open' : ''}>
        <AccordionItemContent>
          <MIFormattedText
            label={answer}
            values={{
              sp: <span style={{ margin: '0.5rem' }} />,
              supportPhone: (
                <SupportLink href={`tel:${site.config.support.phone}`}>
                  {site.config.support.phone}
                </SupportLink>
              ),
              supportEmail: site.config.support.email,
              ulcf: (...chunks) => (
                <UnorderedListCircleFilled>
                  {React.Children.toArray(chunks)}
                </UnorderedListCircleFilled>
              ),
              ulce: (...chunks) => (
                <UnorderedListCircleEmpty>
                  {React.Children.toArray(chunks)}
                </UnorderedListCircleEmpty>
              ),
              uls: (...chunks) => (
                <UnorderedListSquare>{React.Children.toArray(chunks)}</UnorderedListSquare>
              ),
              feesTable: <FAQFeesTable />,
            }}
          />
        </AccordionItemContent>
      </AccordionItemInner>
    </AccordionItem>
  );
};

export default QBOAccordion;

const AccordionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 0.4rem;
  overflow: hidden;
`;

const AccordionListItem = styled.li`
  &:last-child {
    border-bottom: 0.1rem solid rgba(227, 229, 232, 1);
  }
`;

const AccordionItem = styled.div`
  border-top: 0.1rem solid rgba(227, 229, 232, 1);
  color: rgba(37, 37, 37, 1);
`;

const AccordionItemLine = styled.div`
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white.opaque};
  z-index: 2;
  padding: 1.9rem 0;
`;

const AccordionItemTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.normal};
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
`;

const AccordionItemIcon = styled.span`
  font-size: 2rem;
  color: ${(props) => props.theme.text.color.highlight};
  margin-right: 1rem;
  cursor: pointer;
`;

const AccordionItemInner = styled.div`
  max-height: 0;
  overflow: hidden;
  transition-property: max-height;
  z-index: 1;

  &.open {
    max-height: 100%;
    transition-property: max-height;
  }
`;

const AccordionItemContent = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding-bottom: 2rem;
`;

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
`;

const UnorderedList = styled.ul`
  padding: 0;
  margin: 0;
  padding-inline-start: 2.5rem;
  & > li {
    &::marker {
      font-size: 1.5rem;
    }
  }
`;

const UnorderedListCircleFilled = styled(UnorderedList)`
  list-style-type: disc;
`;

const UnorderedListCircleEmpty = styled(UnorderedList)`
  list-style-type: circle;
`;

const UnorderedListSquare = styled(UnorderedList)`
  list-style-type: square;
`;
