import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Link } from '@melio/billpay-design-system';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { ReactComponent as Icon } from 'src/app/images/icons/payment-cancelled.svg';
import { MIFormattedText, useSiteContext } from 'src/app/version-2/externals';
import { upgradePaymentSelectors } from 'src/app/version-2/pages/vendor-upgrade-payment';
import {
  getVendorUpgradeAnalytics,
  vendorUpgradePaymentClientEventNames,
  vendorUpgradePaymentServerEventNames,
} from 'src/app/version-2/pages/vendor-upgrade-payment/utils/analytics.utils';
import { IconContainer, StyledQBOLayoutPage, Subtitle, Title } from './OfferExpiredPage.styles';

interface Props {
  token: string;
}
export const OfferExpiredPage = ({ token }: Props) => {
  const site = useSiteContext();
  const { state } = useLocation<{ expirationReason?: string }>();
  const expirationReason = state?.expirationReason || 'token expired';

  const isPaymentLoadFinished = useSelector(upgradePaymentSelectors.selectIsLoadFinished);
  const payment = useSelector(upgradePaymentSelectors.selectPayment);

  const { trackServerAction, trackUIAction } = useMemo(
    () => getVendorUpgradeAnalytics(token, payment),
    [payment, token]
  );

  useEffect(() => {
    if (isPaymentLoadFinished) {
      trackUIAction(vendorUpgradePaymentClientEventNames.OFFER_EXPIRED, {
        reason: expirationReason,
      });

      trackServerAction(vendorUpgradePaymentServerEventNames.OFFER_EXPIRED, {
        reason: expirationReason,
      });
    }
  }, [isPaymentLoadFinished, expirationReason, trackUIAction, trackServerAction]);

  return (
    <StyledQBOLayoutPage
      hideHeader
      contentWrapperMode="success"
      qboFooter={<QBOFooterContainer hasBorder />}
    >
      <IconContainer>
        <Icon />
      </IconContainer>
      <Title>
        <MIFormattedText label="vendors.upgradePayment.offerExpired.title" />
      </Title>
      <Subtitle>
        <MIFormattedText
          label="vendors.upgradePayment.offerExpired.subtitle"
          values={{
            supportEmail: (
              <Link
                href={`mailto:${site.config.support.email}`}
                isExternal
                color="blue.500"
                textDecoration="none !important"
              >
                {site.config.support.email}
              </Link>
            ),
          }}
        />
      </Subtitle>
    </StyledQBOLayoutPage>
  );
};
