import * as React from 'react';
import { compose } from 'recompose';
import { RecordOf } from 'immutable';
import { constructFilters } from 'src/app/utils/query-utils';
import {
  BillType,
  FieldType,
  ListSearchBarContextType,
  ValidationErrors,
} from 'src/app/utils/types';
import { Option } from 'src/app/components/common/MISingleSelect';
import { withListNavigator } from './withNavigator';

const LEADING_AMPERSAND_REGEX = /[^&](\S)*/g;

// TODO: Should this really know all types of query-string filters in the system?
export type Filters = {
  status?: string;
  type?: string;
  sorting?: string;
  start?: number;
  limit?: number;
  search?: string;
  billStatus?: string;
  paymentRequestId?: string;
  vendorId?: string;
};

export type PageProps = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
};

export type PageContainerProps = {
  id: string;
  navigate: (
    url: string | { pathname: string; search?: string },
    shouldReplaceCurrent?: boolean,
    state?: Record<string, unknown>
  ) => void;
  children: React.ReactElement<any>;
  basePath: string;
  query: any;
  orgId: string;
  ownedVendorId: string;
  locationState: Record<string, unknown>;
  addNewItem: (description: string, amount: number) => void;
  bill: RecordOf<BillType> | null | undefined;
  fileStorageUrl: string | null | undefined;
  filePreviewUrls: string[] | null | undefined;
  intuitAccountsOptions: Option[];
  isUploading: boolean;
  onChangeAttachment: (file: File) => void;
  onDeleteAttachment: () => void;
  onFieldChange: (value: FieldType) => void;
  onItemRemove: (index: number) => void;
  validationErrors: ValidationErrors<any>;
  vendors: Option[];
  setSelected: (arg0: any) => void;
} & PageProps &
  ListSearchBarContextType;

/**
 * @note Should be the last HoC in the composing chain
 */
function withListContainer() {
  return function (WrappedListContainer) {
    return function (props) {
      const createQueryString = (state) => {
        const filters = constructFilters(state.filters);
        const id = state.id ? `id=${state.id}` : null;
        const [queryString] =
          [filters, id]
            .filter((p) => p)
            .join('&')
            .match(LEADING_AMPERSAND_REGEX) || [];

        return queryString;
      };

      const setFilters = (filters: Filters) => {
        const current = { ...props, filters };

        // eslint-disable-next-line react/prop-types
        props.navigate(`${props.basePath}?${createQueryString(current)}`, true);
      };

      return <WrappedListContainer {...props} setFilters={setFilters} />;
    };
  };
}

withListContainer.defaultProps = {};

export default () => compose(withListNavigator(), withListContainer());
