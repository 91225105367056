import styled from 'styled-components';

export const SectionTitle = styled.div`
  ${(props) => props.theme.textStyles.ds.h5};
  font-weight: ${(props) => props.theme.fontWeights.ds.semi};
`;

export const FooterNote = styled.div<{ isLeftAlign?: boolean }>`
  ${(props) => props.theme.textStyles.ds.body2};
  text-align: ${({ isLeftAlign }) => (isLeftAlign ? 'left' : 'center')};
  color: ${(props) => props.theme.colors.ds.gray[200]};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.ds.xl};
`;
