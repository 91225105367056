import React from 'react';
import { format, isAfter, isSameDay, isSameYear, isSameMonth } from 'date-fns';
import { Box } from '@melio/billpay-design-system';

import { DATE_FORMATS } from 'src/app/utils/date-fns';
import QBOTableDate from './QBOTableDate';

type Props = {
  fromDate: string | Date | undefined;
  toDate: string | Date | undefined;
};

const QBOTableRangeDate = ({ fromDate, toDate }: Props) => {
  if (!fromDate) {
    return <Box>-</Box>;
  }

  const toDateValue = new Date(toDate || '');
  const fromDateValue = new Date(fromDate);

  if (!toDate || isSameDay(fromDateValue, toDateValue) || isAfter(fromDateValue, toDateValue)) {
    return <QBOTableDate date={fromDate} />;
  }

  let range;

  if (isSameYear(fromDateValue, toDateValue) && isSameMonth(fromDateValue, toDateValue)) {
    // Apr 10 - 12, 2020
    range = `${format(fromDateValue, DATE_FORMATS.monthWithDay)} - ${format(
      toDateValue,
      DATE_FORMATS.dayAndYear
    )}`;
  } else if (isSameYear(toDateValue, fromDateValue)) {
    // Mar 25 - Apr 02, 2021
    range = `${format(fromDateValue, DATE_FORMATS.monthWithDay)} - ${format(
      toDateValue,
      DATE_FORMATS.monthShortWithLongDateAndYear
    )}`;
  } else {
    // Mar 25, 2020 - Apr 02, 2021
    range = `${format(fromDateValue, DATE_FORMATS.monthShortWithLongDateAndYear)} - ${format(
      toDateValue,
      DATE_FORMATS.monthShortWithLongDateAndYear
    )}}`;
  }

  return <Box>{range}</Box>;
};

export default QBOTableRangeDate;
