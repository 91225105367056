import { MICategoryList } from 'src/app/components/common/MICategoryList';
import { ModelViewField } from '.';

export type SelectCategoryProps = {
  model: ModelViewField<string | null>;
  options: any;
};
export function SelectCategory({ model, options }: SelectCategoryProps) {
  return (
    <MICategoryList
      categories={options}
      selectedId={model.value}
      onSelect={(value) => model.onChange({ value })}
    />
  );
}
