import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  NotificationCard,
  NotificationCardProps,
  NotificationTypeEnum,
} from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { SCHEDULE_PAYMENTS_LIMIT } from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkBanner.consts';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { Container } from './BatchBulkBanner.styles';

export interface BatchBulkBannerProps {
  numOfSelectedBills: number;
}

const batchBulkBanners: Record<string, NotificationCardProps> = {
  scheduleLimit: {
    type: NotificationTypeEnum.INFO,
    title: 'batchBulkPage.banners.scheduleLimit.title',
    subtitle: 'batchBulkPage.banners.scheduleLimit.subtitle',
  },
  missingDetails: {
    type: NotificationTypeEnum.WARNING,
    title: 'batchBulkPage.banners.missingDetails.title',
    subtitle: 'batchBulkPage.banners.missingDetails.subtitle',
  },
  usHolidayUSPCLatency: {
    type: NotificationTypeEnum.INFO,
    title: 'batchBulkPage.banners.usHolidayUSPCLatency.title',
    subtitle: 'batchBulkPage.banners.usHolidayUSPCLatency.subtitle',
    more: 'batchBulkPage.banners.usHolidayUSPCLatency.more',
  },
  internationalStateBlock: {
    type: NotificationTypeEnum.INFO,
    title: 'batchBulkPage.banners.internationalStateBlock.title',
    subtitle: 'batchBulkPage.banners.internationalStateBlock.subtitle',
    subtitleMulti: 'batchBulkPage.banners.internationalStateBlock.subtitleMulti',
  },
};

export const BatchBulkBanner: FC<BatchBulkBannerProps> = (props) => {
  const { numOfSelectedBills } = props;
  const hasTriedToSubmit: boolean = useSelector(batchBulkSelectors.selectHasTriedToSubmit);
  const isValid: boolean = useSelector(batchBulkSelectors.selectIsValid);
  const numOfErrors: number = useSelector(batchBulkSelectors.selectNumOfPaymentIntentsWithErrors);
  const organizationPreferences = useSelector(
    (state) => state.organization.organizationPreferences
  );

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [showScheduleLimitBanner, setShowScheduleLimitBanner] = useState(
    numOfSelectedBills > SCHEDULE_PAYMENTS_LIMIT
  );
  const [showMissingDetailsBanner, setShowMissingDetailsBanner] = useState(false);
  const [showInternationalBlockedBanner, setShowInternationalBlockedBanner] = useState(true);
  const [isShownHolidaysWarningFeatureFlagOn] = featureFlags.useFeature(
    FeatureFlagsEnum.US_HOLIDAYS_CHECK_FEATURE_FLAG
  );
  const isUSHolidayNotificationEnabled = useSelector(
    isShownHolidaysWarningFeatureFlagOn
      ? batchBulkSelectors.selectIsUSHolidayNotificationEnabled
      : () => false
  );

  const internationalRemovedBills = useSelector(batchBulkSelectors.selectRemovedInternationalCount);
  const isInternationalVendorDisable =
    !!internationalRemovedBills && !organizationPreferences.isEligibleForInternationalPayment;

  const goLearnMore = () => {
    dispatch(applicationActions.setFaqPanel({ isOpen: true, focusedQuestion: 20 }));
  };

  const closeMissingDetailsBanner = () => {
    dispatch(batchBulkActions.setHasTriedToSubmit(false));
    setShowMissingDetailsBanner(false);
  };

  const disableUSHolidayNotification = () => {
    dispatch(batchBulkActions.setIsUSHolidayNotificationEnabled(false));
  };

  const disableInternationalBlockedNotification = () => {
    setShowInternationalBlockedBanner(false);
  };

  const hasCheckDeliveryMethod = useSelector(batchBulkSelectors.selectHasCheckDeliveryMethod);

  useEffect(() => {
    if (hasTriedToSubmit) {
      if (isValid) setShowMissingDetailsBanner(false);
      else setShowMissingDetailsBanner(true);
    }
  }, [hasTriedToSubmit, isValid]);

  const renderScheduleLimitBanner = (bannerProps, numOfSelectedBills) => (
    <NotificationCard
      type={bannerProps.type}
      title={formatMessage({ id: bannerProps.title })}
      subtitle={formatMessage({ id: bannerProps.subtitle }, { numOfSelectedBills })}
      onClose={() => setShowScheduleLimitBanner(false)}
    />
  );

  const renderMissingDetailsBanner = (bannerProps, numOfErrors) => (
    <NotificationCard
      type={bannerProps.type}
      title={formatMessage({ id: bannerProps.title }, { numOfErrors })}
      subtitle={formatMessage({ id: bannerProps.subtitle }, { numOfErrors })}
      onClose={closeMissingDetailsBanner}
    />
  );

  const renderUSHolidayUSPCLatencyBanner = (bannerProps) => (
    <NotificationCard
      type={NotificationTypeEnum.INFO}
      title={formatMessage({ id: bannerProps.title })}
      subtitle={formatMessage(
        { id: bannerProps.subtitle },
        { link: <Link onClick={goLearnMore}>{formatMessage({ id: bannerProps.more })}</Link> }
      )}
      onClose={disableUSHolidayNotification}
    />
  );

  const renderInternationalStateBanner = (bannerProps, numOfRemovedBills) => {
    const isSingleRemovedPayment = numOfRemovedBills === 1;

    return (
      <NotificationCard
        type={bannerProps.type}
        title={formatMessage({ id: bannerProps.title })}
        subtitle={formatMessage(
          { id: isSingleRemovedPayment ? bannerProps.subtitle : bannerProps.subtitleMulti },
          { numOfRemovedBills }
        )}
        onClose={disableInternationalBlockedNotification}
      />
    );
  };

  return (
    <Container data-testid="batch-bulk-banner">
      {!showMissingDetailsBanner &&
        showScheduleLimitBanner &&
        renderScheduleLimitBanner(batchBulkBanners.scheduleLimit, numOfSelectedBills)}
      {showMissingDetailsBanner &&
        renderMissingDetailsBanner(batchBulkBanners.missingDetails, numOfErrors)}
      {hasCheckDeliveryMethod &&
        isUSHolidayNotificationEnabled &&
        renderUSHolidayUSPCLatencyBanner(batchBulkBanners.usHolidayUSPCLatency)}
      {showInternationalBlockedBanner &&
        isInternationalVendorDisable &&
        renderInternationalStateBanner(
          batchBulkBanners.internationalStateBlock,
          internationalRemovedBills
        )}
    </Container>
  );
};
