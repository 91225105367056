import { useState } from 'react';
import { useSelector } from 'react-redux';
import analytics from 'src/app/services/analytics';
import profileStore from 'src/app/modules/profile/profile-store';
import { useFundingSourceMicroDepositState } from 'src/app/pages/settings/hooks/useFundingSourceMicroDeposits';

type Params = {
  eventPage: string;
  partialBillId: string;
  withoutDeliveryMethod?: boolean;
};

export const useVerifyMicroDeposits = ({
  partialBillId,
  eventPage,
  withoutDeliveryMethod,
}: Params) => {
  const [verifyingId, setVerifyingId] = useState<number | null>(null);
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const microDepositEventPage = 'pay-bill-verify-manual-account';
  const microDepositProps: any = {
    key: verifyingId,
    fundingSourceId: verifyingId,
  };
  const [state, actions]: any = useFundingSourceMicroDepositState(
    microDepositEventPage,
    microDepositProps
  );

  const onVerifyFinished = () => {
    analytics.track(eventPage, 'verify-finish', {
      partialBillId,
    });

    setVerifyingId(null);
  };

  const onVerifyClicked = (id: number) => {
    analytics.track(eventPage, 'verify-click', {
      partialBillId,
    });

    setVerifyingId(id);
  };

  const permissionVerify = withoutDeliveryMethod
    ? permissions.fundingSources.verify()
    : permissions.deliveryMethod.verify();

  const openVerifyMicrodepositsModal = permissionVerify ? onVerifyClicked : undefined;

  const verifyMicrodepositsModalProps = {
    ...state,
    ...actions,
    key: verifyingId,
    eventPage: microDepositEventPage,
    dialogSuccessTitle: 'settings.microDeposits.verifyDialogSuccess.paymentMethods.title',
    dialogSuccessSubtitle: 'settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle',
    onVerifyFinished,
  };

  return {
    openVerifyMicrodepositsModal,
    shouldShowVerifyMicrodepositsModal: !!verifyingId,
    verifyMicrodepositsModalProps,
  };
};
