import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getBillListActions } from 'src/app/modules/batch-bills/batch-bills-store';
import { getPaymentsActions } from 'src/app/modules/payments/payment-store';

type Response = [() => void];

const useResetQboBatchPaymentsState = (): Response => {
  const dispatch = useDispatch();
  const batchBillsActions = getBillListActions(dispatch);
  const paymentsActions = getPaymentsActions(dispatch);

  const resetQboBatchPaymentsState = useCallback(() => {
    batchBillsActions.resetQboBatchPaymentsState();
    paymentsActions.resetQboBatchPaymentsState();
  }, [batchBillsActions.resetQboBatchPaymentsState, paymentsActions.resetQboBatchPaymentsState]);

  return [resetQboBatchPaymentsState];
};

export default useResetQboBatchPaymentsState;
