/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import { mapApiSlice } from 'src/app/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/app/services/analytics/types';
import { ANALYTICS_VENDOR_CLARITY_PAGE_NAME } from './vendor-payment-tracking/consts';

const vendorDeliveryMethodPage = 'vendor-delivery-method';
const editAddressEvent = 'edit-address';
const unilateralPageEvent = 'unilateral-payment';
const shiftVendorToAchPageEvent = 'shift-vendor-to-ach';
const shiftToDebitPageEvent = 'shift-to-debit';
const qbDirectoryEvent = 'qb-directory';
const vendorEvent = 'vendor';
const virtualCardEvent = 'virtual-card';
const virtualCardDetailsPageEvent = 'virtual-card-details';
const globalOptInEvent = 'global-opt-in-suvc';
const expediteDeliveryMethod = 'expedite-delivery-method';
const posTerminalPage = 'POSTerminalExplanationPage';

const MAPPING: EventMappingsTree = {
  '/orgs/*/vendors/*/delivery-method/ach/new': {
    'add-delivery-method': [vendorDeliveryMethodPage, 'add-delivery-method', { type: 'type' }],
    'add-delivery-method-success': [
      vendorDeliveryMethodPage,
      'add-delivery-method-success',
      { type: 'type', vendorId: 'vendorId' },
    ],
    'add-delivery-method-failure': [
      vendorDeliveryMethodPage,
      'add-delivery-method-success',
      { type: 'type', vendorId: 'vendorId' },
    ],
    'delivery-method-validation-error': [
      vendorDeliveryMethodPage,
      'add-delivery-method-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'go.exit': [vendorDeliveryMethodPage, 'exit'],
  },
  '/orgs/*/vendors/*/delivery-method/ach/*': {
    'edit-delivery-method': [vendorDeliveryMethodPage, 'edit-delivery-method', { type: 'type' }],
    'edit-delivery-method-success': [
      vendorDeliveryMethodPage,
      'edit-delivery-method-success',
      { type: 'type', vendorId: 'vendorId' },
    ],
    'edit-delivery-method-failure': [
      vendorDeliveryMethodPage,
      'edit-delivery-method-success',
      { type: 'type', vendorId: 'vendorId' },
    ],
    'delivery-method-validation-error': [
      vendorDeliveryMethodPage,
      'edit-delivery-method-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'go.exit': [vendorDeliveryMethodPage, 'exit'],
  },
  '/vendors/*/add-delivery-method/*': {
    'page.view': [shiftVendorToAchPageEvent, 'add-delivery-method'],
    'go.back': [shiftVendorToAchPageEvent, 'go-back'],
    ...mapApiSlice('[DELIVERYMETHODS] REPLACE_METHOD', 'vendors-ach-details', 'add-ach'),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE_ACH_METHOD', 'vendors-ach-details', 'edit-ach'),
  },
  '/vendors/*/add-delivery-method/*/switch-company': {
    'page.view': [shiftVendorToAchPageEvent, 'switch-company'],
    'option-selected-organization': [
      shiftVendorToAchPageEvent,
      'select-organization',
      { selectedOrgId: 'option.id' },
    ],
    'vendors.addDeliveryMethodByLink.switchCompanyCTA': [
      shiftVendorToAchPageEvent,
      'switch-organization',
    ],
  },
  '/vendors/*/link-delivery-method/*': {
    'page.view': [shiftVendorToAchPageEvent, 'link-delivery-method'],
    'go.back': [shiftVendorToAchPageEvent, 'go-back'],
    '[DELIVERYMETHODS] COPY_FORM_OWNED_VENDOR_FAILURE': [
      shiftVendorToAchPageEvent,
      'validation-error',
      { validation: 'error.message' },
    ],
    '[DELIVERYMETHODS] COPY_FORM_OWNED_VENDOR_SUCCESS': [
      shiftVendorToAchPageEvent,
      'delivery-method-linked',
    ],
  },
  '/vendors/*/add-delivery-method/*/shifted-current-payment': {
    'page.view': [shiftVendorToAchPageEvent, 'shifted-current-payment'],
    'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.upsaleCTA': [
      shiftVendorToAchPageEvent,
      'join-melio',
    ],
  },
  '/vendors/*/add-delivery-method/*/success': {
    'page.view': [shiftVendorToAchPageEvent, 'success'],
    'vendors.deliveryMethods.ach.changeSuccess.button': [
      'vendors-ach-details',
      'quickbooks-signup',
    ],
    'vendors.deliveryMethods.shiftVendorToAch.success.learnMore': [
      shiftVendorToAchPageEvent,
      'learnMore',
    ],
    'vendors.deliveryMethods.shiftVendorToAch.success.upsale.cta': [
      'vendors-ach-details',
      'melio-signup',
    ],
    'vendors.joinServiceUpsell.cta': [
      qbDirectoryEvent,
      'join-qbo-directory',
      {
        flow: 'flow',
      },
    ],
  },
  '/vendors/*/add-delivery-method/*/invalid-link': {
    'page.view': [shiftVendorToAchPageEvent, 'invalid-token'],
    'vendors.deliveryMethods.ach.errors.supportLink': ['invalid-token', 'support-open-start-chat'],
  },
  '/vendors/*/edit-address/*/check-sent': {
    'page.view': [shiftVendorToAchPageEvent, 'check-sent'],
    'vendors.editCheckAddress.checkSent.upsaleCTA': ['vendors-ach', 'get-paid-by-bank'],
  },
  '/vendors/*/edit-address/*/check-deposited': {
    'page.view': [shiftVendorToAchPageEvent, 'check-deposited'],
    'vendors.editCheckAddress.checkDeposited.upsaleCTA': ['vendors-ach', 'get-paid-by-bank'],
  },
  '/vendors/*/edit-address/*/change-address': {
    'page.view': [editAddressEvent, 'change-address'],
    'suggest-alt-address': [editAddressEvent, 'suggest-alternative-address', { flow: 'flow' }],
    'submit-address': [editAddressEvent, 'choose-address', { flow: 'option' }],
    'vendors.addDeliveryMethodByLink.manualAddressCTA': [editAddressEvent, 'confirm-address'],
    'vendors.editCheckAddress.checkNotSent.form.saveCTA': [editAddressEvent, 'save-address'],
  },
  '/vendors/*/edit-address/*/address-saved': {
    'page.view': [editAddressEvent, 'address-saved'],
    'vendors.editCheckAddress.checkNotSent.success.upsaleCTA': [
      editAddressEvent,
      'enable-bank-deposits',
    ],
  },
  '/vendors/*/edit-address/*/switched-to-ach': {
    'page.view': [shiftVendorToAchPageEvent, 'switched-to-ach'],
    'vendors.editCheckAddress.SwitchedCheckAlreadyPage.upsaleCTA': [
      shiftVendorToAchPageEvent,
      'click-join-melio',
    ],
  },
  '/vendors/*/edit-address/*/check-blocked': {
    'page.view': [shiftVendorToAchPageEvent, 'blocked-by-risk'],
  },
  '/vendors/*/edit-address/*/payment-cancelled': {
    'page.view': [shiftVendorToAchPageEvent, 'payment-cancelled'],
  },
  '/vendors/*/edit-address/*/check-cancelled': {
    'page.view': [shiftVendorToAchPageEvent, 'check-cancelled'],
    'support-email': [shiftVendorToAchPageEvent, 'support-email'],
  },
  '/vendors/*/edit-address/*/invalid-link': {
    'page.view': [shiftVendorToAchPageEvent, 'invalid-link'],
  },
  '/vendors/unilateral/add-delivery-method/*': {
    'page.view': [unilateralPageEvent, 'add-delivery-method'],
    'select-category': [
      unilateralPageEvent,
      'select-delivery-method-${value}',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
      },
    ],
    'go.back': [
      unilateralPageEvent,
      'go-back',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
      },
    ],
    'vendors.addDeliveryMethodByLink.submitCTA': [unilateralPageEvent, 'add-new-delivery-method'],
    'log-in': [
      unilateralPageEvent,
      'go-log-in',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
      },
    ],
    'save-delivery-method': [
      unilateralPageEvent,
      'add-delivery-method',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        organizationId: 'organizationId',
      },
    ],
    'option-selected-manualAddress': [
      unilateralPageEvent,
      'select-manual-address',
      { address: 'option.label' },
    ],
    'manualAddress.edit': [unilateralPageEvent, 'edit-manual-address'],
    'submit-address': [unilateralPageEvent, 'confirm-manual-address', { option: 'option' }],
    'vendors.addDeliveryMethodByLink.linkExistingMethod.submit': [
      unilateralPageEvent,
      'link-meliome-delivery-method',
    ],
    'add-delivery-method-page-loaded': [
      unilateralPageEvent,
      'add-delivery-method-page-loaded',
      {
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
        deliveryMethodsType: 'deliveryMethodsType',
        bpVirtualCardOptInFeatureFlag: 'bpVirtualCardOptInFeatureFlag',
        isEligibleToVirtualCard: 'isEligibleToVirtualCard',
        triggerFlow: 'triggerFlow',
      },
    ],
    'vendor-add-ach-bank-details-page-viewed': [
      unilateralPageEvent,
      'VendorAddAchBankDetailsPage-Viewed',
      { paymentId: 'paymentId', triggerFlow: 'triggerFlow' },
    ],
    ...mapApiSlice(
      '[DELIVERYMETHODS] ADDRESS VALIDATION',
      unilateralPageEvent,
      'validate-manual-address'
    ),
    ...mapApiSlice(
      '[DELIVERYMETHODS] REPLACE VIRTUAL METHOD',
      unilateralPageEvent,
      'submit-new-delivery-method',
      {
        request: { deliveryType: 'payload.deliveryMethod.deliveryType' },
        success: { deliveryType: 'payload.deliveryMethod.deliveryType' },
      }
    ),
    ...mapApiSlice(
      '[DELIVERYMETHODS] COPY_WITH_UNILATERAL_TOKEN',
      unilateralPageEvent,
      'copy-delivery-method-from-owned-vendor'
    ),
    ...mapApiSlice(
      '[DELIVERYMETHODS] UPDATE_WITH_UNILATERAL_TOKEN',
      unilateralPageEvent,
      'update-delivery-method',
      {
        request: { vendorId: 'payload.vendorId', paymentId: 'payload.paymentId' },
      }
    ),
  },
  '/vendors/unilateral/add-delivery-method/*/switch-company': {
    'page.view': [unilateralPageEvent, 'switch-company'],
    'option-selected-organization': [
      unilateralPageEvent,
      'select-organization',
      { selectedOrgId: 'option.id' },
    ],
    'vendors.addDeliveryMethodByLink.switchCompanyCTA': [
      unilateralPageEvent,
      'switch-organization',
    ],
    'add-delivery-method-switch-company-page-loaded': [
      unilateralPageEvent,
      'add-delivery-method-switch-company-page-loaded',
      {
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
        isEligibleToVirtualCard: 'isEligibleToVirtualCard',
      },
    ],
  },
  '/vendors/unilateral/add-delivery-method/*/shifted-current-payment': {
    'page.view': [unilateralPageEvent, 'current-payment-shifted-to-ach'],
  },
  '/vendors/unilateral/add-delivery-method/*/success': {
    'page.view': [unilateralPageEvent, 'delivery-method-success'],
    'vendors.addDeliveryMethodByLink.signUpCTA': [unilateralPageEvent, 'sign-up'],
    'log-in': [
      unilateralPageEvent,
      'go-log-in',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
      },
    ],
    'vendors.addDeliveryMethodByLink.success.joinService.button': [
      unilateralPageEvent,
      'quickbooks-signup',
    ],
    'vendors.joinServiceUpsell.cta': [
      qbDirectoryEvent,
      'join-qbo-directory',
      {
        flow: 'flow',
      },
    ],
    'add-delivery-method-success-page-loaded': [
      unilateralPageEvent,
      'add-delivery-method-success-page-loaded',
      {
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
        isEligibleToVirtualCard: 'isEligibleToVirtualCard',
      },
    ],
  },
  '/vendors/unilateral/add-delivery-method/*/invalid-link': {
    'page.view': [unilateralPageEvent, 'invalid-token'],
    'invalid-link-page-loaded': [
      unilateralPageEvent,
      'invalid-link-page-loaded',
      { paymentId: 'paymentId' },
    ],
  },
  '/vendors/shift-virtual-card/*': {
    'page.view': ['shift-virtual-card', 'shift-virtual-card-details'],
  },
  '/vendors/shift-virtual-card/*/invalid-link': {
    'page.view': ['shift-virtual-card', 'invalid-token'],
  },
  '/vendors/add-debit-card/*/debit-method': {
    'page.view': [shiftToDebitPageEvent, 'add-debit-card-details'],
    'ptd-starts': [
      shiftToDebitPageEvent,
      'starts',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
      },
    ],
  },
  '/vendors/add-debit-card/*/address': {
    'page.view': [shiftToDebitPageEvent, 'add-address-details'],
    'go.back': [shiftToDebitPageEvent, 'go-back'],
    ...mapApiSlice(
      '[DELIVERYMETHODS] ADDRESS VALIDATION',
      shiftToDebitPageEvent,
      'validate-address'
    ),
    ...mapApiSlice('[DELIVERYMETHODS] ADD_CARD_ACCOUNT', shiftToDebitPageEvent, 'add-debit-card'),
    ...mapApiSlice('[PAYMENTS] UPDATE', shiftToDebitPageEvent, 'update-payment'),
    'option-selected-manualAddress': [
      shiftToDebitPageEvent,
      'select-manual-address',
      { address: 'option.label' },
    ],
    'manualAddress.edit': [shiftToDebitPageEvent, 'edit-manual-address'],
  },
  '/vendors/add-debit-card/*/invalid-link': {
    'page.view': [shiftToDebitPageEvent, 'invalid-token'],
  },
  '/vendors/add-debit-card/*/success': {
    'page.view': [shiftToDebitPageEvent, 'debit-card-added'],
    'vendors.deliveryMethods.shiftToDebit.success.upSaleCTA': [
      shiftToDebitPageEvent,
      'go-meliome-register',
    ],
    'join-quickbooks-online': [
      '',
      'join-quickbooks-online',
      {
        source: 'source',
      },
    ],
    'vendors.joinServiceUpsell.cta': [
      qbDirectoryEvent,
      'join-qbo-directory',
      {
        flow: 'flow',
      },
    ],
  },
  '/vendors/global-opt-in/*/*/personal-details': {
    'personal-details-error': [globalOptInEvent, 'personal-details-error', { error: 'error' }],
  },
  '/vendors/global-opt-in/*/*/business-details': {
    'business-details--error': [globalOptInEvent, 'business-details--error', { error: 'error' }],
  },
  '/vendors/virtual-card/*/*': {
    'page.view': [virtualCardEvent, 'virtual-card-details'],
    'state-changed': [
      virtualCardDetailsPageEvent,
      'state-changed',
      {
        state: 'state',
        virtualCardId: 'virtualCardId',
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
      },
    ],
    'vendors.virtualCardDetails.valid.cta': [
      virtualCardDetailsPageEvent,
      'valid_click-cta',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        cardFieldName: 'cardFieldName',
      },
    ],
    'vendors.virtualCardDetails.expired.cta': [
      virtualCardDetailsPageEvent,
      'expired_click-cta',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
      },
    ],
    'vendors.virtualCardDetails.recovered.cta': [
      virtualCardDetailsPageEvent,
      'recovered_click-cta',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
      },
    ],
  },
  '/vendors/virtual-card/*/*/invalid-link': {
    'page.view': [virtualCardEvent, 'invalid-token'],
    'invalid-link-page-loaded': [
      virtualCardEvent,
      'invalid-link-page-loaded',
      { paymentId: 'paymentId' },
    ],
  },
  '/vendors/virtual-card/opt-in/*': {
    'page.view': [virtualCardEvent, 'review-virtual-card-page'],
    'learn-about-virtual-card-click': [
      virtualCardEvent,
      'learn-about-virtual-card-click',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        billId: 'billId',
        deliveryType: 'deliveryType',
      },
    ],
    'payment-opt-in-page-loaded': [
      virtualCardEvent,
      'payment-opt-in-page-loaded',
      {
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
        isEligibleToVirtualCard: 'isEligibleToVirtualCard',
      },
    ],
    'confirm-virtual-card-payment': [
      virtualCardEvent,
      'confirm-virtual-card-payment',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        billId: 'billId',
        deliveryType: 'deliveryType',
        optInFlow: 'optInFlow',
      },
    ],
  },
  '/vendors/virtual-card/info/*': {
    'page.view': [virtualCardEvent, 'virtual-card-info'],
    'MoreInformationGetVirtualCard-Chose': [
      vendorEvent,
      'MoreInformationGetVirtualCard-Chose',
      {
        flow: 'flow',
        paymentId: 'paymentId',
        billId: 'billId',
        partialBillId: 'partialBillId',
        vendorId: 'vendorId',
      },
    ],
  },
  '/vendors/virtual-card/pos-terminal/*': {
    'page-viewed': [
      posTerminalPage,
      'Viewed',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'view-card-clicked': [
      `${posTerminalPage}ViewCardDetails`,
      'Clicked',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'receive-bank-transfer-clicked': [
      `${posTerminalPage}ReceiveBankTransfer`,
      'Clicked',
      {
        paymentId: 'paymentId',
        vendorId: 'vendorId',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
  },
  '/vendors/expedite/select-delivery-method/*': {
    'page.view': [expediteDeliveryMethod, 'vendor-select-delivery-method'],
    'vendor-select-delivery-method-loaded': [
      expediteDeliveryMethod,
      'vendor-select-delivery-method-loaded',
      { paymentId: 'paymentId' },
    ],
    'ChooseDeliveryMethod-Viewed': [
      vendorEvent,
      'ChooseDeliveryMethod-Viewed',
      {
        deliveryMethodTypes: 'deliveryMethodTypes',
        billId: 'billId',
        flow: 'flow',
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        partialBillId: 'partialBillId',
      },
    ],
    'DeliveryMethod-Chose': [
      vendorEvent,
      'DeliveryMethod-Chose',
      {
        flow: 'flow',
        paymentId: 'paymentId',
        billId: 'billId',
        partialBillId: 'partialBillId',
        deliveryMethodType: 'deliveryMethodType',
        vendorId: 'vendorId',
      },
    ],
    'select-category': [
      expediteDeliveryMethod,
      'select-delivery-method-${value}',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
      },
    ],
    'MoreInformation-Learned': [
      vendorEvent,
      'MoreInformation-Learned',
      {
        flow: 'flow',
        infoType: 'infoType',
        paymentId: 'paymentId',
        billId: 'billId',
        partialBillId: 'partialBillId',
        vendorId: 'vendorId',
      },
    ],
  },
  '/vendors/expedite/check/select-delivery-method/*': {
    'page.view': [expediteDeliveryMethod, 'vendor-select-delivery-method'],
    'vendor-select-delivery-method-loaded': [
      expediteDeliveryMethod,
      'vendor-select-delivery-method-loaded',
      { paymentId: 'paymentId' },
    ],
    'ChooseDeliveryMethod-Viewed': [
      vendorEvent,
      'ChooseDeliveryMethod-Viewed',
      {
        deliveryMethodTypes: 'deliveryMethodTypes',
        billId: 'billId',
        flow: 'flow',
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        partialBillId: 'partialBillId',
      },
    ],
    'DeliveryMethod-Chose': [
      vendorEvent,
      'DeliveryMethod-Chose',
      {
        flow: 'flow',
        paymentId: 'paymentId',
        billId: 'billId',
        partialBillId: 'partialBillId',
        deliveryMethodType: 'deliveryMethodType',
        vendorId: 'vendorId',
      },
    ],
    'MoreInformation-Learned': [
      vendorEvent,
      'MoreInformation-Learned',
      {
        flow: 'flow',
        paymentId: 'paymentId',
        billId: 'billId',
        partialBillId: 'partialBillId',
        infoType: 'infoType',
        vendorId: 'vendorId',
      },
    ],
  },
  '/vendors/expedite/select-delivery-method/*/virtual-card-success': {
    'page.view': [expediteDeliveryMethod, 'virtual-card-success'],
    'add-virtual-card-delivery-method-success-page-loaded': [
      expediteDeliveryMethod,
      'add-virtual-card-delivery-method-success-page-loaded',
      {
        vendorId: 'vendorId',
        paymentId: 'paymentId',
        vendorEmail: 'vendorEmail',
        payorOrgId: 'payorOrgId',
        isEligibleToVirtualCard: 'isEligibleToVirtualCard',
      },
    ],
  },
  '/vendors/track-payment/*/blocked-payment': {
    'page.view': [ANALYTICS_VENDOR_CLARITY_PAGE_NAME, 'blocked-payment'],
  },
  '/vendors/track-payment/*/invalid-link': {
    'page.view': [ANALYTICS_VENDOR_CLARITY_PAGE_NAME, 'invalid-link'],
  },
  '/vendors/track-payment/*': {
    'page-viewed': [
      ANALYTICS_VENDOR_CLARITY_PAGE_NAME,
      'Viewed',
      {
        isContainGlobalVCOptInOption: 'isContainGlobalVCOptInOption',
        organizationId: 'organizationId',
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentStatus: 'paymentStatus',
        possibleActions: 'possibleActions',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'edit-check-address-clicked': [
      `${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}CheckAddress`,
      'Edited',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'edit-bank-details-clicked': [
      `${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}DeliveryMethod`,
      'Edited',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'edit-email-clicked': [
      `${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}EmailAddress`,
      'Edited',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'contact-support-clicked': [
      `${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}Support`,
      'Contacted',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'change-delivery-method-clicked': [
      `${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}DeliveryMethod`,
      'Changed',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        fromDeliveryMethod: 'fromDeliveryMethod',
        toDeliveryMethod: 'toDeliveryMethod',
        newTrackingConvention: 'newTrackingConvention',
        deliveryPreference: 'deliveryPreference',
      },
    ],
    'check-address-modal-opened': [
      `CheckAddressModalIn${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}`,
      'Shown',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'bank-details-modal-opened': [
      `AchDetailsModalIn${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}`,
      'Shown',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'email-modal-opened': [
      `EmailAddressModalIn${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}`,
      'Shown',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'check-address-saved': [
      `${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}CheckAddress`,
      'Saved',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        successfullySaved: 'successfullySaved',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'bank-details-saved': [
      `${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}AchDetails`,
      'Saved',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        successfullySaved: 'successfullySaved',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
    'edit-email-saved': [
      `${ANALYTICS_VENDOR_CLARITY_PAGE_NAME}EmailAddress`,
      'Saved',
      {
        paymentId: 'paymentId',
        deliveryMethod: 'deliveryMethod',
        paymentAmount: 'paymentAmount',
        successfullySaved: 'successfullySaved',
        newTrackingConvention: 'newTrackingConvention',
      },
    ],
  },
};

export default MAPPING;
