import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@melio/billpay-design-system';
import { analytics } from 'src/app/version-2/externals';
import {
  BATCH_BULK_EVENT_PAGE,
  GLOBAL_DEDUCTION_DATE_TYPE_UPDATE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { DeductionDatePicker, DeductionDateType } from './components';
import {
  DATE_SELECTOR_TYPE,
  DeductionDateObjectType,
  DeductionDateSelectProps,
} from './DeductionDateSelect.consts';
import * as Styles from './DeductionDateSelect.styles';

export const DeductionDateSelect = ({
  onChange,
  date,
  disabled = false,
  deductionType,
  setDeductionType,
  minScheduleDate,
}: DeductionDateSelectProps) => {
  const { formatMessage } = useIntl();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  useEffect(() => {
    setShowDatePicker(deductionType?.id === DATE_SELECTOR_TYPE);
  }, [deductionType]);

  const onTypeSelect = (type) => {
    analytics.track(BATCH_BULK_EVENT_PAGE, GLOBAL_DEDUCTION_DATE_TYPE_UPDATE, {
      deductionDateType: type.id,
    });

    setDeductionType(type);
    onDeductionDateChange({ type });
  };

  const onDateSelect = (date: Date) => {
    onDeductionDateChange({ date });
  };

  const onDeductionDateChange = ({
    date,
    type,
  }: {
    date?: Date;
    type?: DeductionDateObjectType;
  }) => {
    onChange({ type: type?.id || deductionType.id, date: date || null });
  };

  return (
    <Styles.DeductionDateSelect>
      <Tooltip
        placement="top"
        label={
          disabled ? formatMessage({ id: 'batchBulkPage.headSection.multipleMethodTooltip' }) : ''
        }
      >
        <span>
          <DeductionDateType onSelect={onTypeSelect} disabled={disabled} />
        </span>
      </Tooltip>

      {showDatePicker && !disabled && (
        <DeductionDatePicker
          onChange={onDateSelect}
          date={date || new Date()}
          minScheduleDate={minScheduleDate}
        />
      )}
    </Styles.DeductionDateSelect>
  );
};
