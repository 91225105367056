import * as React from 'react';
import MIEnhancedDropDown from 'src/app/components/common/DropDown/MIEnhancedDropDown';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { MIFormTitleWithText } from 'src/app/components/common/MIFormTitleWithText';
import DatePicker from 'src/app/components/form/DatePicker';
import MISingleSelect from 'src/app/components/common/MISingleSelect';
import MIAddressAutocomplete from 'src/app/components/common/MIAddressAutocomplete';
import { WizardFormRow } from 'src/app/components/layout/WizardElements';
import Upload10KInvoiceFileLayout from 'src/app/components/layout/Upload10KInvoiceFileLayout';
import { MICardEditableField } from 'src/app/components/common/MICard';
import MIRadioGroup from 'src/app/components/form/MIRadioGroup';
import {
  AddressFormControlModeEnum,
  FormControlTypeEnum,
  FormTypeEnum,
  TextInputSizeEnum,
} from 'src/app/version-2/model/enums';

type Props = {
  formControl: any;
  formType: FormTypeEnum;
};

const inputWrappersMap = {
  [FormTypeEnum.WIZARD]: WizardFormRow,
  [FormTypeEnum.DETAILS]: MICardEditableField,
};

const MIEditFormControl = ({ formControl, formType = FormTypeEnum.WIZARD }: Props) => {
  const InputWrapper = inputWrappersMap[formType];
  const inputSize =
    formType === FormTypeEnum.WIZARD ? TextInputSizeEnum.WIZARD : TextInputSizeEnum.INLINE;
  const addressMode =
    formType === FormTypeEnum.WIZARD
      ? AddressFormControlModeEnum.INLINE
      : AddressFormControlModeEnum.FORM;

  let formControlComponent;

  switch (formControl.controlType) {
    case FormControlTypeEnum.ADDRESS:
      formControlComponent = <MIAddressAutocomplete {...formControl} mode={addressMode} />;
      break;
    case FormControlTypeEnum.SELECT:
      formControlComponent = <MISingleSelect {...formControl} />;
      break;
    case FormControlTypeEnum.DROP_DOWN:
      formControlComponent = <MIEnhancedDropDown {...formControl} />;
      break;
    case FormControlTypeEnum.DATE:
      formControlComponent = <DatePicker {...formControl} />;
      break;
    case FormControlTypeEnum.RADIO:
      formControlComponent = <MIRadioGroup {...formControl} size={inputSize} />;
      break;
    case FormControlTypeEnum.TEL:
      formControl.type = formControl.controlType;
      formControlComponent = <MITextInput {...formControl} size={inputSize} />;
      break;
    case FormControlTypeEnum.FILE:
      formControlComponent = <Upload10KInvoiceFileLayout {...formControl} />;
      break;
    case FormControlTypeEnum.TEXT:
    case FormControlTypeEnum.CURRENCY:
    default:
      formControlComponent = <MITextInput {...formControl} size={inputSize} />;
      break;
  }

  if (formControl.controlType === FormControlTypeEnum.FILE) {
    return (
      <>
        <MIFormTitleWithText {...formControl} />
        {formControlComponent}
      </>
    );
  }

  return <InputWrapper>{formControlComponent}</InputWrapper>;
};

export default MIEditFormControl;
