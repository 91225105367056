import React, { useEffect, useState } from 'react';
import MIForm from 'src/app/components/common/MIForm';
import {
  GoogleCombinedAddressType,
  CompanyFormPageType,
  CompanyInfoType,
} from 'src/app/utils/types';
import { COMPANY_FORM_PAGE } from 'src/app/utils/consts';
import { getTaxIdTypesOptions, isZipCodeValid } from 'src/app/utils/company';
import {
  AddressNameTypeEnum,
  FormControlTypeEnum,
  FormTypeEnum,
  ScreenModeEnum,
  TaxIdEnum,
} from 'src/app/version-2/model/enums';

type Props = {
  legalCompanyName: string;
  address: GoogleCombinedAddressType;
  companyAddress: GoogleCombinedAddressType;
  taxId?: string;
  taxIdType?: TaxIdEnum;
  mode?: ScreenModeEnum;
  onChange: (value: any) => void;
  formType?: FormTypeEnum;
  validationErrors: Record<string, any>;
  companyFormPage: CompanyFormPageType;
  contactFirstName: string;
  contactLastName: string;
  phone?: string;
  onAddressChange: (
    legalAddress: GoogleCombinedAddressType,
    type?: (typeof AddressNameTypeEnum)[keyof typeof AddressNameTypeEnum]
  ) => void;
  openLegalAddressInput?: boolean;
  companyInfo: CompanyInfoType;
  isLoading?: boolean;
};

export const JustPayCompleteLegalInfoForm = (props: Props) => {
  const {
    legalCompanyName,
    address,
    formType = FormTypeEnum.WIZARD,
    taxId,
    taxIdType,
    mode = ScreenModeEnum.EDIT,
    companyAddress,
    openLegalAddressInput,
    companyInfo,
    isLoading,
    onChange,
    onAddressChange,
    validationErrors,
    companyFormPage,
    contactFirstName,
    contactLastName,
    phone,
  } = props;
  const [addressInvalidZipCodeValidationError, setAddressInvalidZipCodeValidationError] = useState<
    string | null
  >();
  const [legalAddressInvalidZipCodeValidationError, setLegalAddressInvalidZipCodeValidationError] =
    useState<string | null>();
  const companyLegalValidationError =
    validationErrors.legalGooglePlaceId ||
    validationErrors.legalAddressLine1 ||
    validationErrors.legalZipCode;
  const companyAddressValidationError = validationErrors.addressLine1 || validationErrors.zipCode;

  useEffect(() => {
    if (companyInfo.zipCode && !isZipCodeValid(companyInfo.zipCode as string | null)) {
      setAddressInvalidZipCodeValidationError('inputErrors.companyInfo.zipCode.string.regex.base');
    }

    if (companyInfo.legalZipCode && !isZipCodeValid(companyInfo.legalZipCode as string | null)) {
      setLegalAddressInvalidZipCodeValidationError(
        'inputErrors.companyInfo.zipCode.string.regex.base'
      );
    }
  }, []);

  if (isLoading) {
    return null;
  }

  const legalInfoFormControls = () => {
    const legalInfoForm = [
      {
        id: 'contactFirstName',
        label: 'bills.pay.confirm.completeLegalInfo.contactFirstName',
        emptyLabel: 'bills.pay.confirm.completeLegalInfo.emptyLabelFirstName',
        value: contactFirstName,
        onChange,
        required: true,
        errorMessage: validationErrors.contactFirstName,
        controlType: FormControlTypeEnum.TEXT,
        initialState: companyInfo.contactFirstName,
        privateData: true,
      },
      {
        id: 'contactLastName',
        label: 'bills.pay.confirm.completeLegalInfo.contactLastName',
        emptyLabel: 'bills.pay.confirm.completeLegalInfo.emptyLabelLastName',
        value: contactLastName,
        onChange,
        required: true,
        errorMessage: validationErrors.contactLastName,
        controlType: FormControlTypeEnum.TEXT,
        initialState: companyInfo.contactLastName,
        privateData: true,
      },
      {
        id: 'legalCompanyName',
        label: 'bills.pay.confirm.completeLegalInfo.legalName.inputTitle',
        emptyLabel: 'bills.pay.confirm.completeLegalInfo.legalName.emptyLabel',
        notices: ['bills.pay.confirm.completeLegalInfo.legalName.inputHint'],
        value: legalCompanyName,
        onChange,
        required: true,
        errorMessage: validationErrors.legalCompanyName,
        controlType: FormControlTypeEnum.TEXT,
        initialState: companyInfo.legalCompanyName,
        privateData: true,
      },
      {
        id: 'companyAddress',
        addressLabel: 'bills.pay.confirm.completeLegalInfo.companyAddress.inputTitle',
        emptyAddressLabel: 'bills.pay.confirm.completeLegalInfo.legalAddress.emptyAddressLabel',
        address: companyAddress,
        onChange: (legalAddress, type) => {
          setAddressInvalidZipCodeValidationError(null);
          onAddressChange(legalAddress, type);
        },
        onSelect: (value: string) => onChange({ id: 'openLegalAddressInput', value }),
        required: true,
        errorMessage:
          (!companyAddress.formattedAddress && companyAddressValidationError) ||
          addressInvalidZipCodeValidationError,
        controlType: FormControlTypeEnum.ADDRESS,
        initialState:
          companyInfo.addressLine1 &&
          companyInfo.zipCode &&
          isZipCodeValid(companyInfo.zipCode) &&
          companyInfo.legalFormattedAddress,
        showCheckbooks: true,
        openLegalAddressInput,
        privateData: true,
      },
      {
        id: 'legalAddress',
        addressLabel: 'bills.pay.confirm.completeLegalInfo.legalAddress.inputTitle',
        emptyAddressLabel: 'bills.pay.confirm.completeLegalInfo.legalAddress.emptyAddressLabel',
        addressNotices: ['bills.pay.confirm.completeLegalInfo.legalAddress.inputHint'],
        address,
        onChange: (legalAddress, type) => {
          setLegalAddressInvalidZipCodeValidationError(null);
          onAddressChange(legalAddress, type);
        },
        required: openLegalAddressInput,
        errorMessage: companyLegalValidationError,
        controlType: FormControlTypeEnum.ADDRESS,
        initialState:
          (companyInfo.legalAddressLine1 && companyInfo.legalZipCode && !openLegalAddressInput) ||
          legalAddressInvalidZipCodeValidationError,
        hideInput: !openLegalAddressInput,
        showCheckbooks: false,
        privateData: true,
      },
      {
        id: 'taxIdType',
        label:
          companyFormPage === COMPANY_FORM_PAGE.ONBOARDING
            ? 'bills.pay.confirm.completeLegalInfo.taxIdType.inputTitle'
            : 'onboarding.companyInfo.taxIdType.inputTitle',
        emptyLabel: 'bills.pay.confirm.completeLegalInfo.taxIdType.emptyLabel',
        selected: taxIdType,
        onSelect: (value: string) => onChange({ id: 'taxIdType', value }),
        required: true,
        options: getTaxIdTypesOptions(),
        errorMessage: validationErrors.taxIdType,
        controlType: FormControlTypeEnum.RADIO,
        initialState: companyInfo.taxIdType,
      },
      {
        id: 'taxId',
        label: 'bills.pay.confirm.completeLegalInfo.taxId.inputTitle',
        emptyLabel: 'bills.pay.confirm.completeLegalInfo.taxId.emptyLabel',
        value: taxId,
        onChange,
        required: true,
        errorMessage: validationErrors.taxId,
        controlType: FormControlTypeEnum.TEL,
        initialState: companyInfo.taxId,
        privateData: true,
      },
      {
        id: 'phone',
        label: 'bills.pay.confirm.completeLegalInfo.phone.placeholder',
        emptyLabel: 'bills.pay.confirm.completeLegalInfo.phone.emptyLabelPhone',
        value: phone,
        onChange,
        required: true,
        errorMessage: validationErrors.phone,
        controlType: FormControlTypeEnum.TEL,
        initialState: companyInfo.phone,
        privateData: true,
      },
    ];

    return legalInfoForm.filter((form) => !form.initialState);
  };

  return <MIForm formControls={legalInfoFormControls()} mode={mode} formType={formType} />;
};
