import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { OrganizationBillingFee, OrganizationFeeCatalog } from 'src/app/version-2/model/dtos';

export const ORGANIZATION_SLICE = `organization-v2`;

interface OrganizationSlice {
  feeFundingSource: EntityState<OrganizationBillingFee>;
  isFeeFundingLoading: boolean;
  feeCatalog: OrganizationFeeCatalog | null;
  availableFreeChecks: number;
  totalFreeChecks: number;
  isCheckFeeModalOpen: boolean;
}

const orgFeeFundingSourceAdapter = createEntityAdapter<OrganizationBillingFee>({
  selectId: (feeFundingSource) => feeFundingSource.id,
});

export const getOrganizationInitialState = (): OrganizationSlice => ({
  feeFundingSource: orgFeeFundingSourceAdapter.getInitialState(),
  isFeeFundingLoading: false,
  feeCatalog: null,
  availableFreeChecks: 0,
  totalFreeChecks: 0,
  isCheckFeeModalOpen: false,
});

export const selectState = (state: any) => state[ORGANIZATION_SLICE];

const selectIsFeeLoading = createSelector(selectState, (state) => state.isFeeFundingLoading);
const selectOrgFeeFundingSource = createSelector(selectState, (state) => state.feeFundingSource);
const selectFeeCatalog = createSelector(selectState, (state) => state.feeCatalog);
const selectAvailableFreeChecks = createSelector(selectState, (state) => state.availableFreeChecks);
const selectTotalFreeChecks = createSelector(selectState, (state) => state.totalFreeChecks);
const selectIsCheckFeeModalOpen = createSelector(selectState, (state) => state.isCheckFeeModalOpen);

export const organizationSlice = createSlice({
  name: ORGANIZATION_SLICE,
  initialState: getOrganizationInitialState(),
  reducers: {
    fetchActiveFeeFundingSourceHandlerSuccess: (
      state,
      action: PayloadAction<OrganizationBillingFee>
    ) => {
      orgFeeFundingSourceAdapter.addOne(state.feeFundingSource, action.payload);
    },
    setFeeFundingLoading: (state, action: PayloadAction<boolean>) => {
      state.isFeeFundingLoading = action.payload;
    },
    setFeeCatalog: (state, action: PayloadAction<OrganizationFeeCatalog | null>) => {
      state.feeCatalog = action.payload;
    },
    setAvailableFreeChecks: (state, action: PayloadAction<number>) => {
      state.availableFreeChecks = action.payload;
    },
    setTotalFreeChecks: (state, action: PayloadAction<number>) => {
      state.totalFreeChecks = action.payload;
    },
    setIsCheckFeeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCheckFeeModalOpen = action.payload;
    },
  },
});

export const organizationSelectors = {
  selectIsFeeLoading,
  selectOrgFeeFundingSource,
  selectFeeCatalog,
  selectAvailableFreeChecks,
  selectTotalFreeChecks,
  selectIsCheckFeeModalOpen,
};

export const organizationActions = {
  ...organizationSlice.actions,
  fetchActiveFeeFundingSource: createAction(`${ORGANIZATION_SLICE}/fetch`),
  fetchFreeChecks: createAction<{ isBatch?: boolean }>(`${ORGANIZATION_SLICE}/free-checks`),
};
