import React from 'react';
import styled from 'styled-components';
import { CompanyContact } from 'src/app/components/common/CompanyContact';
import { devices } from 'src/app/theme/AppDevices';
import MIButton from 'src/app/components/common/MIButton';
import { ButtonVariantType, HeaderAction } from 'src/app/utils/types';

type Props = {
  isFullSingleView: boolean;
  status: string;
  companyName: string;
  description?: string | React.ReactNode;
  headerActions: HeaderAction[];
  isRPPSVendor?: boolean;
  showViewDetailsLink?: boolean;
  goViewDetails?: () => void;
};

const BillDetailsHeader = ({
  isFullSingleView,
  description,
  companyName,
  headerActions,
  isRPPSVendor,
  showViewDetailsLink,
  goViewDetails,
}: Props) => (
  <HeaderContainer>
    <HeaderContactContainer>
      {!isFullSingleView && (
        <CompanyContact
          companyName={companyName}
          description={description}
          goViewDetails={goViewDetails}
          showDirectoryVendorLogo={isRPPSVendor}
          showViewDetailsLink={showViewDetailsLink}
        />
      )}
      <ButtonsContainer>
        {headerActions.map((action) => (
          <StyledMIButton
            key={action.label}
            onClick={action.action}
            label={action.label as string}
            variant={action.variant as ButtonVariantType}
            disabled={action.disabled}
            fullWidth={isFullSingleView}
            analyticsProperties={action.analyticsProperties}
          />
        ))}
      </ButtonsContainer>
    </HeaderContactContainer>
  </HeaderContainer>
);

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 0;
  }
`;

const HeaderContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 0;
  }
`;

const StyledMIButton = styled(MIButton)`
  min-width: 10rem;
  justify-content: center;
  &:last-child {
    margin-left: 1.2rem;
  }
  @media ${devices.mobile} {
    margin-left: 0;
    &:last-child {
      margin-left: 0;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
  @media ${devices.mobile} {
    flex-direction: column;
    > button:nth-of-type(1) {
      margin-bottom: 1rem;
    }
  }
`;

export { BillDetailsHeader };
