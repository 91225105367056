import React, { FunctionComponent } from 'react';
import { RecordOf } from 'immutable';
import { BillType } from 'src/app/utils/types';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  onClick(): void;
  bill: RecordOf<BillType>;
};

export const QBMPaymentsMadeLink: FunctionComponent<Props> = ({ onClick, bill }) => {
  const handleClick = () => {
    if (bill.payments.length <= 1) {
      return;
    }

    onClick();
  };

  return (
    <Container bill={bill} onClick={handleClick}>
      <MIFormattedText
        label="bills.form.paymentActivity.paymentsMade"
        values={{
          count: bill.payments.length,
          amount: bill.totalAmount - (bill.balance as number),
        }}
      />
    </Container>
  );
};

const Container = styled.div<{ bill: BillType }>`
  font-size: 14px;
  color: #6b6c72;

  ${({ bill }) =>
    bill.payments.length > 1 &&
    css`
      color: #0077c5;
      cursor: pointer;
    `};
`;
