import { CardChangeEvent, ChangeEvent } from '@basis-theory/basis-theory-js/types/elements/events';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasisTheoryCardsBrandsEnum } from 'src/app/version-2/pages/add-card-account/model/enums/basisTheoryCardsBrands.enum';
import { BASIS_THEORY_CARD_FIELD_IDS } from 'src/app/version-2/pages/add-card-account/model/enums/cardFieldIds.enum';
import { BasisTheoryData } from 'src/app/version-2/pages/add-card-account/model/objects/basisTheoryData';
import { BasisTheoryValidationState } from 'src/app/version-2/pages/add-card-account/model/objects/basisTheoryValidationState';
import { ErrorValidationState } from 'src/app/version-2/pages/add-card-account/model/objects/errorValidationState';
import { ADD_CARD_ACCOUNT } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.reducer';

export const ADD_CARD_ACCOUNT_SLICE = 'addCardAccount';
export const selectState = (state) =>
  state[ADD_CARD_ACCOUNT][ADD_CARD_ACCOUNT_SLICE] as AddCardAccountSlice;

export interface AddCardAccountSlice {
  cardBrand: BasisTheoryCardsBrandsEnum;
  errorValidations: {
    [key in BASIS_THEORY_CARD_FIELD_IDS]: ErrorValidationState | BasisTheoryValidationState;
  };
  attemptedToLink: boolean;
  pageLoading: boolean;
  flowState: {
    isVendorFlow: boolean | null;
    location: any;
    vendorToken?: string;
  };
  isRequesting: boolean;
}

export const getAddCardAccountSlice = (): AddCardAccountSlice => ({
  cardBrand: BasisTheoryCardsBrandsEnum.UNKNOWN,
  errorValidations: {
    [BASIS_THEORY_CARD_FIELD_IDS.CARD_NUMBER]: {
      isFirstLoad: true,
      shouldShowError: false,
    },
    [BASIS_THEORY_CARD_FIELD_IDS.EXPIRATION_DATE]: {
      isFirstLoad: true,
      shouldShowError: false,
    },
    [BASIS_THEORY_CARD_FIELD_IDS.VERIFICATION_CODE]: {
      isFirstLoad: true,
      shouldShowError: false,
    },
  },
  attemptedToLink: false,
  pageLoading: true,
  flowState: {
    isVendorFlow: null,
    location: null,
  },
  isRequesting: false,
});

export const addCardAccountSlice = createSlice({
  name: ADD_CARD_ACCOUNT_SLICE,
  initialState: getAddCardAccountSlice(),
  reducers: {
    cardBrand: (state, action: PayloadAction<{ cardBrand: string }>) => {
      state.cardBrand =
        BasisTheoryCardsBrandsEnum[action.payload.cardBrand] || BasisTheoryCardsBrandsEnum.UNKNOWN;
    },
    flowState: (
      state,
      action: PayloadAction<{
        isVendorFlow: boolean | null;
        location: any;
        vendorToken?: string;
      }>
    ) => {
      state.flowState = action.payload;
    },
    isRequesting: (state, action: PayloadAction<boolean>) => {
      state.isRequesting = action.payload;
    },
    errorValidations: (
      state,
      action: PayloadAction<BasisTheoryValidationState & { fieldId: string; errorOnBlur: boolean }>
    ) => {
      const { fieldId } = action.payload;
      const { errorValidations } = state;

      if (!errorValidations[fieldId].isFirstLoad) {
        errorValidations[fieldId].shouldShowError =
          (errorValidations[fieldId].errorOnBlur || action.payload) &&
          errorValidations[fieldId]?.hasError;
      }

      state.errorValidations[fieldId] = {
        ...state.errorValidations[fieldId],
        ...action.payload,
        isFirstLoad: false,
      };
    },
    attemptedToLink: (state, action: PayloadAction<boolean>) => {
      state.attemptedToLink = action.payload;
      const { errorValidations } = state;

      for (const key of Object.keys(BASIS_THEORY_CARD_FIELD_IDS)) {
        const accessor = BASIS_THEORY_CARD_FIELD_IDS[key];

        errorValidations[accessor].shouldShowError =
          (errorValidations[accessor].errorOnBlur || action.payload) &&
          errorValidations[accessor]?.hasError;
      }
    },
    onFocus: (state, action: PayloadAction<{ fieldId: string }>) => {
      const { fieldId } = action.payload;

      state.errorValidations[fieldId].focus = true;
    },
    onBlur: (state, action: PayloadAction<{ fieldId: string }>) => {
      const { fieldId } = action.payload;

      state.errorValidations[fieldId].focus = false;
    },
    onReady: (state, action: PayloadAction<{ fieldId: string }>) => {
      const { fieldId } = action.payload;

      state.errorValidations[fieldId].isReady = true;
    },
    pageLoading: (state, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    },
    resetState: (state) => {
      const { errorValidations } = state;

      for (const key of Object.keys(BASIS_THEORY_CARD_FIELD_IDS)) {
        const accessor = BASIS_THEORY_CARD_FIELD_IDS[key];

        errorValidations[accessor].isReady = false;
        errorValidations[accessor].shouldShowError = false;
        errorValidations[accessor].hasError = true;
        errorValidations[accessor].isFirstLoad = true;
        state.pageLoading = true;
      }
    },
  },
});

export const addCardAccountActions = {
  ...addCardAccountSlice.actions,
  setCardBrand: createAction<{ event: CardChangeEvent }>(`${ADD_CARD_ACCOUNT_SLICE}/setCardBrand`),
  setErrorValidations: createAction<{
    fieldId: string;
    event?: ChangeEvent | CardChangeEvent;
    errorOnBlur?: any;
    attemptedToLink?: any;
  }>(`${ADD_CARD_ACCOUNT_SLICE}/setErrorValidations`),
  linkCardTokenization: createAction<{ bt: any; btData: BasisTheoryData; history: any }>(
    `${ADD_CARD_ACCOUNT_SLICE}/handleLinkCardContainer`
  ),
};

const selectCardBrand = createSelector(selectState, (state) => state.cardBrand);
const selectErrorValidation = createSelector(selectState, (state) => state.errorValidations);
const selectAttemptedToLink = createSelector(selectState, (state) => state.attemptedToLink);
const selectPageLoading = createSelector(selectState, (state) => state.pageLoading);
const selectFlowState = createSelector(selectState, (state) => state.flowState);
const selectIsRequesting = createSelector(selectState, (state) => state.isRequesting);
const selectHasError = createSelector(selectState, (state) => {
  const { errorValidations } = state;

  for (const property in errorValidations) {
    if (errorValidations[property].hasError) {
      return true;
    }
  }

  return false;
});

const selectIsReady = createSelector(selectState, (state) => {
  const { errorValidations } = state;
  let isReady = true;

  // eslint-disable-next-line guard-for-in
  for (const property in errorValidations) {
    isReady = isReady && errorValidations[property].isReady;
  }

  return isReady;
});

export const addCardAccountSelectors = {
  selectCardBrand,
  selectErrorValidation,
  selectAttemptedToLink,
  selectHasError,
  selectIsReady,
  selectPageLoading,
  selectFlowState,
  selectIsRequesting,
};
