import * as React from 'react';
import { Location } from 'history';
import { CONSTS, WIZARD_PROGRESS_BAR_TYPE } from 'src/app/utils/consts';
import { WizardProgressBarType, ButtonVariantType } from 'src/app/utils/types';
import { MINotificationCardProps } from 'src/app/components/common/MINotificationCard';
import { isEnterPressed } from 'src/app/utils/events';
import intercomService from 'src/app/services/intercom';
import * as WizardElements from './WizardElements';
import { MIFormattedText } from '../../utils/formatting';
import MIButton from '../common/MIButton';
import StepHeader from './StepHeader';

type Props = {
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  title?: string;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  exitLink?: string | Location;
  goExit?: () => void | null;
  children?: React.ReactNode;
  relativeStep?: number | null;
  onPrev?: () => void | null;
  isPrevDisabled?: boolean;
  onNext?: () => void | Promise<void> | null;
  nextLabel?: string;
  isNextDisabled?: boolean;
  onSubmit?: () => void | boolean | Promise<void> | null;
  isLoading?: boolean;
  innerSize?: number;
  isHideActions?: boolean;
  footer?: React.ReactNode | null;
  illustration?: string;
  progressBarSteps?: string[];
  minorSteps?: number[];
  progressBarType?: WizardProgressBarType;
  ctaVariant?: ButtonVariantType;
  fullWidthCTA?: boolean;
  testId?: string | null;
  docked?: boolean;
  hideHeader?: boolean;
  isSandboxIndicatorShown?: boolean;
  contentWrapperMode?: string;
  qboFooter?: React.ReactNode | null;
  notification?: MINotificationCardProps;
  displaySupportLink?: boolean;
};

const triggerIntercom = () => intercomService.show();

const getButtonConainerWidth = (docked?: boolean, fullWidthCTA?: boolean) => {
  if (docked) {
    return '25%';
  }

  if (fullWidthCTA) {
    return '45rem';
  }

  return 'auto';
};

const StepLayoutPage = ({
  title = undefined,
  titleValues,
  subtitle,
  exitLink,
  goExit,
  children,
  relativeStep,
  onPrev,
  isPrevDisabled,
  onNext,
  nextLabel,
  isNextDisabled,
  onSubmit,
  isLoading,
  innerSize = 50,
  isHideActions,
  footer,
  illustration,
  progressBarSteps = [],
  minorSteps = [],
  progressBarType = WIZARD_PROGRESS_BAR_TYPE.WITHOUT_SUBSTEPS,
  subTitleValues,
  ctaVariant = CONSTS.BUTTON_VARIANT.PRIMARY,
  fullWidthCTA,
  testId,
  docked,
  notification,
  displaySupportLink = false,
}: Props) => {
  const submitMethod = onSubmit || onNext;
  const nextActionLabel = nextLabel || 'progress.continue';
  const buttonContainerWidth = getButtonConainerWidth(docked, fullWidthCTA);

  // perform Next or Submit action on "Enter" key
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (submitMethod && !isNextDisabled && isEnterPressed(event)) {
      submitMethod();
    }
  };

  const handleNextClick = () => {
    if (submitMethod) {
      submitMethod();
    }
  };

  const isSubtitle = !!subtitle;
  const hasIllustration = !!illustration;
  const stepTestId = testId || 'step-title';
  const subtitleTestId = testId || 'step-subtitle';

  const actionButtons = (
    <WizardElements.WizardButtonContainer isProgressBar width={buttonContainerWidth}>
      <MIButton
        label={nextActionLabel}
        variant={ctaVariant}
        onClick={handleNextClick}
        isProcessing={isLoading}
        disabled={isNextDisabled}
        fullWidth={!docked}
      />
    </WizardElements.WizardButtonContainer>
  );

  return (
    <WizardElements.WizardContainer onKeyDown={onKeyPressed}>
      <StepHeader
        onPrev={onPrev}
        exitLink={exitLink}
        goExit={goExit}
        relativeStep={relativeStep}
        isPrevDisabled={isPrevDisabled}
        isPrev={!!onPrev}
        progressBarSteps={progressBarSteps}
        minorSteps={minorSteps}
        progressBarType={progressBarType}
        displaySupportLink={displaySupportLink}
        onSupportTrigger={triggerIntercom}
      />

      <WizardElements.WizardInner>
        {notification && <WizardElements.WizardNotificationCard {...notification} />}
        {hasIllustration && <WizardElements.WizardIllustration src={illustration} />}
        <WizardElements.WizardStepTitle isSubtitle={isSubtitle} data-testid={stepTestId}>
          {title && <MIFormattedText label={title} values={titleValues} />}
        </WizardElements.WizardStepTitle>

        {subtitle && (
          <WizardElements.WizardStepSubTitle data-testid={subtitleTestId}>
            <MIFormattedText label={subtitle} values={subTitleValues} />
          </WizardElements.WizardStepSubTitle>
        )}

        <WizardElements.WizardInputs innerSize={innerSize}>{children}</WizardElements.WizardInputs>

        {!docked && !isHideActions && submitMethod && (
          <WizardElements.WizardStepActionsContainer>
            {actionButtons}
          </WizardElements.WizardStepActionsContainer>
        )}

        <WizardElements.WizardFooterContainer>{footer}</WizardElements.WizardFooterContainer>
      </WizardElements.WizardInner>
      {docked && !isHideActions && submitMethod && (
        <>
          <WizardElements.DockerSpacer />
          <WizardElements.WizardStepActionsContainerDocked>
            {actionButtons}
          </WizardElements.WizardStepActionsContainerDocked>
        </>
      )}
    </WizardElements.WizardContainer>
  );
};

export default StepLayoutPage;
