import { isSameDay as isSameDayDF } from 'date-fns';
import { MIFormattedText } from 'src/app/utils/formatting';
import { DeliveryDateFormat, isSameDay, isTomorrow } from 'src/app/utils/dates';
import { DeliveryOptionType } from 'src/app/utils/types';
import { DeliveryTypeExpeditedEnum } from 'src/app/version-2/model/enums';

interface Props {
  deliveryOption: DeliveryOptionType;
  expeditedAchDeliveryDate?: Date;
}

const PaymentDeliveryOptionTitle = ({ deliveryOption, expeditedAchDeliveryDate }: Props) => {
  const getTitle = () => {
    let label;
    let values = {};

    // Edge Case. Condition: same delivery date for Fast ACH option and regular option. Delivery date is today/tomorrow.
    // Expected behavour: Fast option title should be 'Today'/'Tomorrow'. Regular option title should be the exact date.
    if (
      deliveryOption.type !== DeliveryTypeExpeditedEnum.EXPEDITED_ACH &&
      expeditedAchDeliveryDate &&
      isSameDayDF(new Date(expeditedAchDeliveryDate), new Date(deliveryOption.deliveryDate))
    ) {
      label = 'bills.pay.date.deliveryOptions.title.date'; // {date}
      values = {
        date: (
          <DeliveryDateFormat
            date={deliveryOption.deliveryDate}
            maxDate={deliveryOption.maxDeliveryDate}
          />
        ),
      };

      return { label, values };
    }

    if (isSameDay(deliveryOption.deliveryDate)) {
      label = 'bills.pay.date.deliveryOptions.title.today'; // 'Today'

      return { label, values };
    }

    if (isTomorrow(deliveryOption.deliveryDate)) {
      label = 'bills.pay.date.deliveryOptions.title.tomorrow'; // 'Tomorrow'

      return { label, values };
    }

    label = 'bills.pay.date.deliveryOptions.title.date'; // {date}
    values = {
      date: (
        <DeliveryDateFormat
          date={deliveryOption.deliveryDate}
          maxDate={deliveryOption.maxDeliveryDate}
        />
      ),
    };

    return { label, values };
  };

  const { label, values } = getTitle();

  return <MIFormattedText label={label} values={values} />;
};

export { PaymentDeliveryOptionTitle };
