import React, { useState, FC } from 'react';
import { useInterval } from 'usehooks-ts';
import { ProgressPageLoader } from '../entrypoint/ProgressPage';

const CHANGE_PROGRESS_INTERVAL = 2500;
const PROGRESS_STEP = 10;

type Props = {
  className?: string;
};

export const FTUProgressBarLoader: FC<Props> = ({ className }) => {
  const [progress, setProgress] = useState(PROGRESS_STEP);

  useInterval(() => {
    if (progress < 90) {
      setProgress(progress + PROGRESS_STEP);
    }
  }, CHANGE_PROGRESS_INTERVAL);

  return <ProgressPageLoader className={className} progress={progress} step={PROGRESS_STEP} />;
};
