import { OrderedSet, RecordOf } from 'immutable';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { CONSTS, BILL_STATUS, PAYMENT_STATUS, PAYMENT_APPROVAL_STATUS } from './consts';
import { getLatestPayment } from './payments';
import { BillType, BillStatusType, PaymentType, BillsGroup } from './types';
import { getBillTag, serializePaymentId } from './bills';

function getComparedDate(bill: RecordOf<BillType> | BillType, status: string | null) {
  if (status === BILL_STATUS.SCHEDULED || status === BILL_STATUS.PAID) {
    if (!isEmpty(bill.payments)) {
      return bill.payments[bill.payments.length - 1].scheduledDate;
    }

    return new Date();
  }

  return bill.dueDate;
}

const createBillsGroup = ({
  header,
  bills = [],
  sum = 0,
}: {
  header: string;
  bills?: (RecordOf<BillType> | BillType)[];
  sum?;
  count?: number;
}): BillsGroup => ({
  header,
  bills,
  sum,
});

const groupFailedPayments = (bills: OrderedSet<RecordOf<BillType> | BillType>): BillsGroup[] => {
  const failedPayments = createBillsGroup({
    header: 'list.group.bill.failedPayment',
  });
  const pendingApprovalPayments = createBillsGroup({
    header: 'list.group.bill.pendingApprovalPayment',
  });
  const declinedPayments = createBillsGroup({
    header: 'list.group.bill.declinedPayment',
  });

  bills.forEach((bill) => {
    const tag = getBillTag(bill);

    if (tag === PAYMENT_APPROVAL_STATUS.PENDING) {
      pendingApprovalPayments.bills.push(bill);
      pendingApprovalPayments.sum += bill.totalAmount;
    } else if (tag === PAYMENT_APPROVAL_STATUS.DECLINED) {
      declinedPayments.bills.push(bill);
      declinedPayments.sum += bill.totalAmount;
    } else if (tag === PAYMENT_STATUS.FAILED) {
      failedPayments.bills.push(bill);
      failedPayments.sum += bill.totalAmount;
    }
  });

  return [pendingApprovalPayments, failedPayments, declinedPayments];
};

const groupInboxBillForPartialPayments = (bills: OrderedSet<RecordOf<BillType>>): BillsGroup[] => {
  const status = BILL_STATUS.UNPAID;
  const overdueBills = createBillsGroup({
    header: 'list.group.bill.overdueBills',
  });
  const billsDueThisWeek = createBillsGroup({
    header: 'list.group.bill.billsDueThisWeek',
  });
  const billsForLater = createBillsGroup({
    header: 'list.group.bill.billsForLater',
  });

  bills.forEach((bill) => {
    const compareDate = moment(getComparedDate(bill, status));
    const now = moment();

    if (moment(compareDate).isBefore(now) && bill.payments.length === 0) {
      overdueBills.bills.push(bill);
      overdueBills.sum += bill.totalAmount;
    } else if (moment(compareDate).isSame(now, 'week')) {
      billsDueThisWeek.bills.push(bill);
      billsDueThisWeek.sum += bill.totalAmount;
    } else {
      billsForLater.bills.push(bill);
      billsForLater.sum += bill.totalAmount;
    }
  });

  return [overdueBills, billsDueThisWeek, billsForLater];
};

const groupInboxBills = (bills: OrderedSet<RecordOf<BillType>>): BillsGroup[] => {
  const status = BILL_STATUS.UNPAID;
  const overdueBills = createBillsGroup({
    header: 'list.group.bill.overdueBills',
  });
  const billsDueThisWeek = createBillsGroup({
    header: 'list.group.bill.billsDueThisWeek',
  });
  const billsForLater = createBillsGroup({
    header: 'list.group.bill.billsForLater',
  });
  const failedPayments = createBillsGroup({
    header: 'list.group.bill.failedPayment',
  });
  const pendingApprovalPayments = createBillsGroup({
    header: 'list.group.bill.pendingApprovalPayment',
  });
  const declinedPayments = createBillsGroup({
    header: 'list.group.bill.declinedPayment',
  });

  bills.forEach((bill) => {
    const compareDate = moment(getComparedDate(bill, status));
    const now = moment();
    const tag = getBillTag(bill);

    if (tag === PAYMENT_APPROVAL_STATUS.PENDING) {
      pendingApprovalPayments.bills.push(bill);
      pendingApprovalPayments.sum += bill.totalAmount;
    } else if (tag === PAYMENT_APPROVAL_STATUS.DECLINED) {
      declinedPayments.bills.push(bill);
      declinedPayments.sum += bill.totalAmount;
    } else if (tag === PAYMENT_STATUS.FAILED) {
      failedPayments.bills.push(bill);
      failedPayments.sum += bill.totalAmount;
    } else if (moment(compareDate).isBefore(now) && bill.payments.length === 0) {
      overdueBills.bills.push(bill);
      overdueBills.sum += bill.totalAmount;
    } else if (moment(compareDate).isSame(now, 'week')) {
      billsDueThisWeek.bills.push(bill);
      billsDueThisWeek.sum += bill.totalAmount;
    } else {
      billsForLater.bills.push(bill);
      billsForLater.sum += bill.totalAmount;
    }
  });

  return [
    pendingApprovalPayments,
    failedPayments,
    declinedPayments,
    overdueBills,
    billsDueThisWeek,
    billsForLater,
  ];
};

const groupPaidBills = (bills: OrderedSet<RecordOf<BillType> | BillType>): BillsGroup[] => {
  const status = BILL_STATUS.PAID;
  const billsBeforeThisWeek = createBillsGroup({
    header: 'list.group.bill.billsBeforeThisWeek',
  });
  const billsDueThisWeek = createBillsGroup({
    header: 'list.group.bill.billsDueThisWeek',
  });

  bills.forEach((bill) => {
    const compareDate = moment(getComparedDate(bill, status));
    const now = moment();

    if (moment(compareDate).isBefore(now, 'week')) {
      billsBeforeThisWeek.bills.push(bill);
      billsBeforeThisWeek.sum += bill.totalAmount;
    } else {
      billsDueThisWeek.bills.push(bill);
      billsDueThisWeek.sum += bill.totalAmount;
    }
  });

  return [billsDueThisWeek, billsBeforeThisWeek];
};

const groupByPaymentDate = (
  bills: OrderedSet<RecordOf<BillType>> | null,
  status: BillStatusType
): BillsGroup[] => {
  let groupedBills: BillsGroup[] = [];

  if (bills && bills.size > 0) {
    switch (status) {
      case BILL_STATUS.PAID:
        groupedBills = groupPaidBills(bills);
        break;
      case BILL_STATUS.SCHEDULED:
        groupedBills = groupScheduledBills(bills);
        break;

      default:
        groupedBills = groupInboxBills(bills);
    }
  }

  return groupedBills.filter((group) => group.bills && group.bills.length);
};

const getPaymentConvertedToBill = (
  bill: BillType,
  payment: PaymentType,
  status?: BillStatusType
): BillType => ({
  ...bill,
  id: serializePaymentId(bill.id, payment.id),
  vendor: payment.vendor,
  totalAmount: payment.amount,
  payments: [payment],
  status: status === BILL_STATUS.UNPAID ? (payment.status as any) : bill.status,
});

const groupByPaymentsDataPartialPayments = (
  payments: PaymentType[],
  bills: OrderedSet<RecordOf<BillType>>,
  status: BillStatusType
) => {
  let groupedBills: BillsGroup[] = [];
  const paymentsAsBills = OrderedSet(
    payments.map((payment) => getPaymentConvertedToBill(payment.bill as BillType, payment, status))
  );

  switch (status) {
    case BILL_STATUS.PAID:
      groupedBills = groupPaidBills(paymentsAsBills);
      break;
    case BILL_STATUS.SCHEDULED:
      groupedBills = groupScheduledBills(paymentsAsBills);
      break;

    default:
      groupedBills = [
        ...groupFailedPayments(paymentsAsBills),
        ...groupInboxBillForPartialPayments(bills),
      ];
  }

  return groupedBills.filter((group) => group.bills && group.bills.length);
};

const groupScheduledBills = (bills: OrderedSet<RecordOf<BillType> | BillType>): BillsGroup[] => {
  const status = BILL_STATUS.SCHEDULED;
  const billsDueThisWeek = createBillsGroup({
    header: 'list.group.bill.scheduled.billsDueThisWeek',
  });
  const billsDueThisMonth = createBillsGroup({
    header: 'list.group.bill.scheduled.billsDueThisMonth',
  });
  const billsForRest = createBillsGroup({
    header: 'list.group.bill.scheduled.billsForLater',
  });
  const pendingApprovalPayments = createBillsGroup({
    header: 'list.group.bill.pendingApprovalPayment',
  });
  const pendingVendorInfo = createBillsGroup({
    header: 'list.group.bill.scheduled.billsWithoutVendorInfo',
  });

  bills.forEach((bill) => {
    const compareDate = moment(getComparedDate(bill, status));
    const now = moment();
    const tag = getBillTag(bill);
    const latestPayment = getLatestPayment(bill.payments);
    const deliveryType = latestPayment?.deliveryMethod?.deliveryType;

    if (tag === PAYMENT_APPROVAL_STATUS.PENDING) {
      pendingApprovalPayments.bills.push(bill);
      pendingApprovalPayments.sum += bill.totalAmount;
    } else if (deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
      pendingVendorInfo.bills.push(bill);
      pendingVendorInfo.sum += bill.totalAmount;
    } else if (moment(compareDate).isSame(now, 'week')) {
      billsDueThisWeek.bills.push(bill);
      billsDueThisWeek.sum += bill.totalAmount;
    } else if (moment(compareDate).isSame(now, 'month')) {
      billsDueThisMonth.bills.push(bill);
      billsDueThisMonth.sum += bill.totalAmount;
    } else {
      billsForRest.bills.push(bill);
      billsForRest.sum += bill.totalAmount;
    }
  });

  return [
    pendingApprovalPayments,
    pendingVendorInfo,
    billsDueThisWeek,
    billsDueThisMonth,
    billsForRest,
  ];
};

export { groupByPaymentDate, groupByPaymentsDataPartialPayments };
