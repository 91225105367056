import { globalOptInFormFieldNamesEnum } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInFormFieldNamesEnum.enum';
import { PHONE_NUMBER_REGEXP } from '../consts/optInDetailsValidation.consts';

import { object, string } from 'yup';

export const personalDetailsSchema = object({
  [globalOptInFormFieldNamesEnum.firstName]: string()
    .trim()
    .required('vendors.globalOptInCandidates.validation.firstName'),
  [globalOptInFormFieldNamesEnum.lastName]: string()
    .trim()
    .required('vendors.globalOptInCandidates.validation.lastName'),
  [globalOptInFormFieldNamesEnum.email]: string()
    .email('vendors.globalOptInCandidates.validation.email')
    .required('vendors.globalOptInCandidates.validation.email'),
  [globalOptInFormFieldNamesEnum.phone]: string()
    .trim()
    .required('vendors.globalOptInCandidates.validation.phone')
    .matches(PHONE_NUMBER_REGEXP, 'vendors.globalOptInCandidates.validation.noValidPhone'),
}).required();
