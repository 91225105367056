import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import paymentStore from 'src/app/modules/payments/payment-store';
import profileStore from 'src/app/modules/profile/profile-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getFundingSources, getCompanyInfo } from 'src/app/redux/user/selectors';
import { CompanyInfoType, OrganizationPreferencesType, VendorType } from 'src/app/utils/types';
import { FundingSource } from 'src/app/version-2/model/dtos';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';

const useJustPayStore = () => {
  const paymentStoreActions = useStoreActions(paymentStore);
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );

  const site = useSiteContext();

  const payment: JustPayPaymentType = useSelector(
    paymentStore.selectors.justPay.justPayWizard.payment
  );

  /*
    TODO: @denis-melio
    'any' type should be remove when will be removed 'immutable' library
 */
  const userFundingSources: FundingSource[] | any = useSelector(getFundingSources);

  const companyInfo: CompanyInfoType = useSelector(getCompanyInfo);

  const selectedFundingSource: FundingSource = useSelector(
    fundingSourcesStore.selectors.byId(payment.fundingSourceId)
  );

  const selectedVendor: VendorType = useSelector(vendorsStore.selectors.byId(payment.vendor?.id));

  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(payment.fundingSourceId));

  return {
    site,
    payment,
    vendor: selectedVendor,
    fundingSource,
    paymentStoreActions,
    userFundingSources,
    permissions,
    companyInfo,
    selectedFundingSource,
    selectedVendor,
    organizationPreferences,
  };
};

export default useJustPayStore;
