import { v4 as uuidv4 } from 'uuid';
import analytics from 'src/app/services/analytics';

const PAYBILL_FLOW_TAGNAME = 'paybill-flow-tag-name';
const TRACK_PAY_BILL_FLOW_KEY = 'payBillFlowUuid';

export const startTrackBillPayFlow = () => {
  analytics.setExtraProperties(PAYBILL_FLOW_TAGNAME, { [TRACK_PAY_BILL_FLOW_KEY]: uuidv4() });
};
export const stopTrackingBillPayFlow = () => {
  analytics.removeExtraProperties(PAYBILL_FLOW_TAGNAME);
};
export const getPayBillFlowUUID = () => analytics.getExtraProperties()[TRACK_PAY_BILL_FLOW_KEY];
