import { useIntl } from 'react-intl';
import { SmartIcon, Text } from '@melio/billpay-design-system';
import {
  Bold,
  ExperimentLandingPageContainer,
  ExperimentLandingPageLink,
  SmartIconWrapper,
} from './ExperimentLandingPageFooter.styles';

export const ExperimentLandingPageFooter = () => {
  const { formatMessage } = useIntl();

  return (
    <ExperimentLandingPageContainer>
      <SmartIconWrapper>
        <SmartIcon type="qbo-footer-logos" />
      </SmartIconWrapper>
      <Text>
        {formatMessage(
          { id: 'landingPage.footer.text' },
          {
            melio: <Bold>{formatMessage({ id: 'landingPage.footer.melio' })}</Bold>,
            banks: <Bold>{formatMessage({ id: 'landingPage.footer.banks' })}</Bold>,
          }
        )}
        <ExperimentLandingPageLink href="/terms">
          {formatMessage({ id: 'landingPage.terms' })}
        </ExperimentLandingPageLink>
        <ExperimentLandingPageLink href="/policy">
          {formatMessage({ id: 'landingPage.policy' })}
        </ExperimentLandingPageLink>
      </Text>
    </ExperimentLandingPageContainer>
  );
};
