import styled from 'styled-components';
import { Link, Loader } from '@melio/billpay-design-system';

export const StyledLink = styled(Link)`
  cursor: pointer;
`;

export const StyledLoader = styled(Loader)`
  left: auto;
  right: 3rem;
`;
