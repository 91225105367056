import {
  CreateOrganizationBillingFeeParams,
  UpdateOrganizationBillingFeeParams,
  OrganizationBillingFee,
} from 'src/app/utils/types';
import { syncPaymentRetry } from 'src/app/utils/sync-payment-retry';
import { deleteRequest, fetchRequest, postRequest, putRequest } from './api';
import retry from '../../utils/retry';

export default {
  getCompanyInfo(orgId, local = false) {
    const url = `/orgs/${orgId}/company-info?local=${local}`;

    return fetchRequest(url);
  },
  getOrgByBillId(billId) {
    const url = `/orgs/orgByBillId/${billId}`;

    return fetchRequest(url);
  },

  getVendorsIntuitAcctNum(orgId, vendorOriginIds) {
    const url = `/orgs/${orgId}/quickbooks/get-vendor-intuit-acct-num?vendorOriginIds=${vendorOriginIds.join()}`;

    return fetchRequest(url);
  },

  updateCompanyInfo(orgId, companyInfo) {
    const url = `/orgs/${orgId}/company-info`;

    return putRequest(url, companyInfo);
  },

  getAccountsForBill(orgId) {
    const url = `/orgs/${orgId}/accounts-bill-options`;

    return fetchRequest(url);
  },

  getAccountsPaymentOptions(orgId) {
    const url = `/orgs/${orgId}/accounts-payment-options`;

    return fetchRequest(url);
  },

  createIntuitAccount(orgId, params) {
    const url = `/orgs/${orgId}/create-intuit-account`;

    return postRequest(url, params);
  },

  getActivitySummary(params?: { noPaid?: boolean }) {
    const url = '/orgs/activity-summary';

    return fetchRequest(url, params);
  },

  runPaymentSync(orgId, paymentId) {
    const url = `/orgs/${orgId}/quickbooks/sync/local-payment/${paymentId}`;

    return syncPaymentRetry(() => fetchRequest(url), 500, 3);
  },

  runBillSync(orgId, billId) {
    const url = `/orgs/${orgId}/quickbooks/sync/local-bill/${billId}`;

    return retry(() => fetchRequest(url), 500, 3);
  },

  runFullsync(orgId) {
    const url = `/orgs/${orgId}/full-sync`;

    return retry(() => fetchRequest(url), 500, 3);
  },

  runFullSyncSynchronously(orgId) {
    const url = `/orgs/${orgId}/full-sync`;

    return retry(() => postRequest(url), 500, 3);
  },

  syncCompanyInfo(orgId) {
    const url = `/orgs/${orgId}/quickbooks/sync/company-info`;

    return postRequest(url);
  },

  setTraitsToDB(orgId, trait) {
    const url = `/orgs/${orgId}/traits`;

    return postRequest(url, trait);
  },

  getOrganizationFeeCatalog(orgId, options = {}) {
    const url = `/orgs/${orgId}/fee-catalog`;

    return fetchRequest(url, null, options);
  },

  getOrganizationFee(orgId, params) {
    const url = `/orgs/${orgId}/fee`;

    if (params?.fundingSourceType === 'credit') params.fundingSourceType = 'creditCard';
    else if (params?.fundingSourceType === 'debit') params.fundingSourceType = 'debitCard';

    return fetchRequest(url, params);
  },

  getAllowedMcc(orgId, params) {
    const url = `/orgs/${orgId}/allowed-mcc`;

    return fetchRequest(url, params);
  },

  createFeeFundingSource(
    orgId: number,
    createOrganizationBillingFeeParams: CreateOrganizationBillingFeeParams
  ) {
    const url = `/orgs/${orgId}/billing-fee`;

    return postRequest(url, createOrganizationBillingFeeParams);
  },

  fetchActiveFeeFundingSource(
    orgId: number,
    options?: { isBypassThrowError: boolean }
  ): Promise<{ data: OrganizationBillingFee[] }> {
    const url = `/orgs/${orgId}/billing-fee`;

    return fetchRequest(url, null, options);
  },

  updateFeeFundingSource(
    orgId: number,
    updateOrganizationBillingFeeParams: UpdateOrganizationBillingFeeParams
  ) {
    const url = `/orgs/${orgId}/billing-fee/${updateOrganizationBillingFeeParams.id}`;

    return putRequest(url, updateOrganizationBillingFeeParams);
  },

  removeFeeFundingSource(orgId: number, orgBillingFeeId: Pick<OrganizationBillingFee, 'id'>) {
    const url = `/orgs/${orgId}/billing-fee/${orgBillingFeeId}`;

    return deleteRequest(url, { id: orgBillingFeeId });
  },
};

export const OrganizationRestApi = {
  fetch({ id }) {
    const url = `/orgs/${id}/company-info`;

    return fetchRequest(url);
  },
  update(companyInfo) {
    const url = `/orgs/${companyInfo.id}/company-info`;

    return putRequest(url, companyInfo).then((res) => ({
      id: companyInfo.id,
      ...res.companyInfo,
    }));
  },

  create(companyInfo) {
    const url = '/orgs/create-owned-organization';

    return postRequest(url, companyInfo);
  },
};
