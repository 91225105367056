import type { NotificationsState } from './types';
import notificationsData from './notificationsData.json';
import { getFormattedNotifications, sortNotificationsById } from '../utils';

export const initialState: NotificationsState = {
  list: sortNotificationsById(getFormattedNotifications(notificationsData.data)),
};

const notificationsReducer = (state: NotificationsState = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default notificationsReducer;
