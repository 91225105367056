import * as React from 'react';
import styled from 'styled-components';
import { Icon, Link } from '@melio/billpay-design-system';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS } from 'src/app/utils/consts';
import { ButtonVariantType, LayoutNotificationType } from 'src/app/utils/types';
import { isEnterPressed } from 'src/app/utils/events';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import * as WizardElements from './QBOWizardElements';
import * as QBOLayoutElements from './QBOElements';
import { MIFormattedText } from '../../utils/formatting';
import MIButton from '../common/MIButton';
import QBOProgressBar from '../common/QBOProgressBar';
import SandboxIndicator from '../common/SandboxIndicator';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';

type Props = {
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  image?: React.FC<React.SVGProps<SVGSVGElement>>;
  title?: any;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  children?: React.ReactNode;
  onNext?: () => void | Promise<void> | null;
  nextLabel?: string;
  goExit?: () => void | null;
  contentWrapperMode?: string;
  onPrev?: () => void | null;
  onRetry?: () => void | null;
  isNextDisabled?: boolean;
  onSubmit?: () => void | boolean | null | Promise<void>;
  isLoading?: boolean;
  isHideActions?: boolean;
  ctaVariant?: ButtonVariantType;
  fullWidthCTA?: boolean;
  innerSize?: number;
  footer?: React.ReactNode | null;
  relativeStep?: number | null;
  hideHeader?: boolean;
  isSandboxIndicatorShown?: boolean;
  qboFooter?: React.ReactNode;
  className?: string;
  secondarySubtitle?: string;
  secondarySubtitleValues?: Record<string, any>;
  settingsComponent?: React.ReactNode | null;
  notification?: LayoutNotificationType | null;
};

const QBOLayoutPage = ({
  headerLabel,
  headerLabelValues,
  title = null,
  titleValues,
  subtitle,
  goExit,
  children,
  onPrev,
  onNext,
  onRetry,
  nextLabel,
  isNextDisabled,
  onSubmit,
  isLoading,
  isHideActions,
  innerSize = 50,
  footer,
  subTitleValues,
  ctaVariant = CONSTS.BUTTON_VARIANT.PRIMARY,
  fullWidthCTA,
  relativeStep,
  contentWrapperMode,
  hideHeader,
  isSandboxIndicatorShown,
  qboFooter,
  className,
  secondarySubtitle,
  secondarySubtitleValues,
  settingsComponent,
  notification,
  image,
}: Props) => {
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const submitMethod = onSubmit || onNext;
  const nextActionLabel = nextLabel || 'progress.continue';
  const buttonContainerWidth = fullWidthCTA ? '45' : 'auto';
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (submitMethod && !isNextDisabled && isEnterPressed(event)) {
      submitMethod();
    }
  };

  const isRefundFlow = nextActionLabel.includes('getRefundButton');

  const handleNextClick = () => {
    if (submitMethod) {
      submitMethod();
    }
  };

  return (
    <QBOLayoutElements.QBOLayoutContainer className={className} onKeyDown={onKeyPressed}>
      {!hideHeader && (
        <>
          <QBOLayoutElements.QBOHeaderContainer
            goExit={goExit}
            onPrev={onPrev}
            headerLabel={headerLabel}
            headerLabelValues={headerLabelValues}
            settingsComponent={settingsComponent}
          />

          {relativeStep ? <QBOProgressBar relativeStep={relativeStep} /> : null}
        </>
      )}

      {hideHeader && goExit && <Close onClick={goExit} />}

      <WizardElements.WizardInner mode={contentWrapperMode as string}>
        {image && (
          <WizardElements.WizardImage isSubtitle={!!subtitle} isNotification={!!notification}>
            <Icon as={image} />
          </WizardElements.WizardImage>
        )}

        {title && (
          <WizardElements.WizardStepTitle
            className="wizard-step-title"
            isSubtitle={!!subtitle}
            isNotification={!!notification}
          >
            <MIFormattedText label={title} values={titleValues} />
          </WizardElements.WizardStepTitle>
        )}

        {subtitle && (
          <WizardElements.WizardStepSubTitle>
            <MIFormattedText label={subtitle} values={subTitleValues} />
            {secondarySubtitle && (
              <div>
                <MIFormattedText label={secondarySubtitle} values={secondarySubtitleValues} />
              </div>
            )}
          </WizardElements.WizardStepSubTitle>
        )}

        {notification && (
          <NotificationWrapper>
            <QBONotificationCard {...notification} />
          </NotificationWrapper>
        )}

        <WizardElements.WizardInputs mode={contentWrapperMode} innerSize={innerSize}>
          {children}
        </WizardElements.WizardInputs>

        {!isHideActions && submitMethod && (
          <WizardElements.WizardStepActionsContainer>
            <WizardElements.WizardButtonContainer isProgressBar width={buttonContainerWidth}>
              <MIButton
                label={nextActionLabel}
                variant={ctaVariant}
                onClick={handleNextClick}
                isProcessing={isLoading}
                disabled={isNextDisabled}
                fullWidth
              />
            </WizardElements.WizardButtonContainer>
          </WizardElements.WizardStepActionsContainer>
        )}

        {isRefundFlow && isRefundFlowFeature && (
          <RetryLink>
            <MIFormattedText
              label="bills.pay.requestRefund.review.retryLink"
              values={{
                retryLink: (...chunks) => (
                  <Link onClick={onRetry} cursor="pointer">
                    {React.Children.toArray(chunks)}
                  </Link>
                ),
              }}
            />
          </RetryLink>
        )}

        {footer && (
          <WizardElements.WizardFooterContainer className="wizard-footer-container">
            {footer}
          </WizardElements.WizardFooterContainer>
        )}
      </WizardElements.WizardInner>
      {isSandboxIndicatorShown && <SandboxIndicator />}
      <QboLayoutPageFooter>
        {qboFooter}
        {!qboFooter && (!hideHeader || contentWrapperMode) && (
          <QBOLayoutElements.QBOFooterContainer />
        )}
      </QboLayoutPageFooter>
    </QBOLayoutElements.QBOLayoutContainer>
  );
};

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: fixed;
  top: 1.2rem;
  right: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  z-index: 1;

  @media ${devices.mobile}, ${devices.phablet} {
    position: relative;
    display: flex;
    align-self: flex-end;
    margin: 12px 12px 0 0;
    font-size: ${(props) => props.theme.text.size.title};
    right: 1rem;
    top: 0.8rem;
    color: ${(props) => props.theme.text.color.green};
  }
`;

export const QboLayoutPageFooter = styled.div`
  width: 100%;
  ${(props) => props.theme?.pages?.QboLayoutPage?.QboLayoutPageFooter}
`;

export const RetryLink = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2};
  width: 50%;
  text-align: center;
  padding-top: 1.7rem;
`;

const NotificationWrapper = styled.div`
  margin: 1rem auto 4rem auto;
  width: 50%;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

export default QBOLayoutPage;
