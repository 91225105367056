import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

const defaultFormatters = {
  b: (...chunks) => <b>{React.Children.toArray(chunks)}</b>,
  capb: (...chunks) => <CapitilizedBold>{React.Children.toArray(chunks)}</CapitilizedBold>,
  sb: (...chunks) => <SemiBold>{React.Children.toArray(chunks)}</SemiBold>,
  em: (...chunks) => <em>{React.Children.toArray(chunks)}</em>,
  un: (...chunks) => <Underlined>{React.Children.toArray(chunks)}</Underlined>,
  br: <br />,
  colorSubtitle: (...chunks) => <ColoredSubtitle>{React.Children.toArray(chunks)}</ColoredSubtitle>,
  li: (...chunks) => <ListItem>{React.Children.toArray(chunks)}</ListItem>,
  ul: (...chunks) => <UnorderedList>{React.Children.toArray(chunks)}</UnorderedList>,
  ol: (...chunks) => <OrderedList>{React.Children.toArray(chunks)}</OrderedList>,
};

export const useTranslation = () => {
  const intl = useIntl();

  const formatMessage = useCallback(
    (id: any, values?: Record<string, any>) =>
      intl.formatMessage({ id }, { ...values, ...defaultFormatters }) as string,
    [intl]
  );

  return {
    formatMessage,
  };
};

const SemiBold = styled.b`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const Underlined = styled.b`
  text-decoration: underline;
`;

const CapitilizedBold = styled.b`
  text-transform: capitalize;
`;

const ColoredSubtitle = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const ListItem = styled.li`
  padding-left: 0.3rem;
`;

const ListStyle = `
  padding: 0;
  margin: 0;
  padding-inline-start: 2rem;
`;

const UnorderedList = styled.ul`
  ${ListStyle}
`;

const OrderedList = styled.ol`
  ${ListStyle}
`;
