import { RecordOf } from 'immutable';
import { hasRequiredLegalCompanyInfo } from 'src/app/utils/company';
import { CompanyInfoType } from 'src/app/utils/types';
import { useRefreshCompanyInfo } from './useRefreshCompanyInfo';

export const useCheckRequiredLegalCompanyInfo = () => {
  const { refreshCompanyInfo } = useRefreshCompanyInfo();
  const checkRequiredLegalCompanyInfo = async (companyInfo: RecordOf<CompanyInfoType>) => {
    if (hasRequiredLegalCompanyInfo(companyInfo)) {
      return true;
    }

    const refreshedCompanyInfo = await refreshCompanyInfo();

    return hasRequiredLegalCompanyInfo(refreshedCompanyInfo as any);
  };

  return { checkRequiredLegalCompanyInfo };
};
