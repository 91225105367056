import React, { useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import { getCompanyInfo, getProfile } from 'src/app/redux/user/selectors';
import internationalAPI from 'src/app/services/api/deliveryMethods';
import { CONSTS, DELIVERY_TYPE } from 'src/app/utils/consts';
import { useApi } from 'src/app/hoc/useApi';
import { geInternationalStaticFee } from 'src/app/utils/international';
import { useDidUpdateEffect } from 'src/app/hooks/useDidUpdateEffect';
import vendorsApi from 'src/app/services/api/vendors';
import locations from 'src/app/utils/locations';
import ultimateBeneficialOwnersAPI from 'src/app/services/api/organization-ultimate-beneficial-owners';
import useGetBillAndPayment from './useGetBillAndPayment';

import {
  createCountriesOptions,
  transformToCreateDeliveryRequest,
  transformToLocalState,
  isVendorDetailsOrigin,
  initRequiredFields,
} from '../utils';
import { ReducerActions, InitialState, ReturnType, StateTypes, BillingTypes } from '../types';

function useInternational(): ReturnType {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { orgId, id: vendorIdParam } = useParams<{
    orgId: string;
    id: string;
  }>();
  const companyInfo = useSelector(getCompanyInfo);
  const [bill, payment] = useGetBillAndPayment();
  const [getSupportedCountries] = useApi(internationalAPI.getSupportedCountries);
  const profile = useSelector(getProfile);

  useDidUpdateEffect(() => {
    if (history.location.pathname.includes(CONSTS.DELIVERY_TYPE.INTERNATIONAL)) {
      history.replace(history.location.pathname, state);
    }
  }, [state]);

  const init = useCallback(async () => {
    const historyState = history.location.state as InitialState;
    const { countries, orgId, exitUrl, redirectUrl, origin } = historyState || {};

    if (countries?.length && parseInt(orgId || '', 10) === companyInfo.id) {
      // has previous state
      return persistSavedState(historyState);
    }

    // init flow from scratch
    return coldInit({ exitUrl, redirectUrl, origin, historyState });
  }, []);

  const coldInit = async ({ exitUrl, redirectUrl, origin, historyState }) => {
    const { countries } = await getSupportedCountries(orgId, vendorIdParam);

    const isAdditionalCountriesEnabled = featureFlags.defaultClient.getVariant(
      'additional-countries-international-payments',
      false
    );

    const options = createCountriesOptions(countries, isAdditionalCountriesEnabled);
    const vendor = await vendorsApi.getVendorById({ orgId, id: vendorIdParam });
    const fee = geInternationalStaticFee();
    const ultimateBeneficialOwners =
      await ultimateBeneficialOwnersAPI.getOrganizationUltimateBeneficialOwners(orgId);

    const editDeliveryMethod = vendor?.object?.deliveryMethods?.find(
      (dm) => dm.deliveryType === DELIVERY_TYPE.INTERNATIONAL
    );

    const commonData = {
      ...historyState,
      [StateTypes.COUNTRIES]: countries,
      [StateTypes.OPTIONS]: options,
      [StateTypes.VENDOR]: vendor?.object,
      [StateTypes.ORG_ID]: orgId,
      [StateTypes.EXIT_URL]: exitUrl,
      [StateTypes.REDIRECT_URL]: redirectUrl,
      [StateTypes.ORIGIN]: origin,
      [StateTypes.EDIT]: false,
      [StateTypes.AMOUNT]: bill?.totalAmount,
      [StateTypes.CURRENCY]: bill.currency?.toLowerCase(),
      [StateTypes.FEE]: fee,
      [StateTypes.ULTIMATE_BENEFICIAL_OWNERS]: ultimateBeneficialOwners?.uboResults,
      [StateTypes.TAX_ID_TYPE]: companyInfo?.taxIdType,
    };

    if (editDeliveryMethod) {
      const editState = transformToLocalState(editDeliveryMethod, countries);

      // edit case for init
      dispatch(
        setData({
          ...commonData,
          ...editState,
          [StateTypes.EDIT]: true,
          [StateTypes.DELIVERY_METHOD]: editDeliveryMethod,
        })
      );

      return;
    }

    // default case for init
    dispatch(setData(commonData));
  };

  const setVendorLocation = ({ country }) => {
    if (isEditDeliveryMethod()) {
      // edit dm flow
      const isSameCountry = state.country.code === country.code;
      const resetData = !isSameCountry
        ? {
            [StateTypes.IBAN]: '',
            [StateTypes.SWIFT]: '',
            [StateTypes.ACCOUNT_NUMBER]: '',
            [StateTypes.BANK_NAME]: '',
          }
        : {};

      dispatch(
        setData({
          [StateTypes.COUNTRY]: country,
          [StateTypes.BANK_DETAILS]: {},
          ...resetData,
        })
      );

      return;
    }

    // create new dm flow
    dispatch(
      setData({
        [StateTypes.COUNTRY]: country,
        [StateTypes.REQUIRED_FIELDS]: initRequiredFields(),
        [StateTypes.BANK_DETAILS]: {},
        [StateTypes.IBAN]: '',
        [StateTypes.SWIFT]: '',
        [StateTypes.ACCOUNT_NUMBER]: '',
        [StateTypes.BANK_NAME]: '',
      })
    );
  };

  const setPaymentDetails = ({
    country,
    accountNumber,
    bankName,
    bankDetails,
    billingType,
    billingValue,
  }) => {
    // swift case - override bank details from user account number / bank name field
    if (accountNumber) bankDetails.account_number = accountNumber;

    if (bankName) bankDetails.bank_name = bankName;

    const commonData = {
      [StateTypes.COUNTRY]: country,
      [StateTypes.BANK_DETAILS]: bankDetails,
      [StateTypes.IBAN]: billingType === BillingTypes.IBAN ? billingValue : '',
      [StateTypes.SWIFT]: billingType === BillingTypes.SWIFT ? billingValue : '',
      [StateTypes.ACCOUNT_NUMBER]: accountNumber || '',
      [StateTypes.BANK_NAME]: bankName || '',
    };

    if (isEditDeliveryMethod()) {
      // edit dm flow
      dispatch(setData(commonData));

      return;
    }

    // create new dm flow
    dispatch(
      setData({
        ...commonData,
        [StateTypes.REQUIRED_FIELDS]: initRequiredFields(),
      })
    );
  };

  type GoToPathType = {
    path: string;
    state?: Record<string, any>;
    params?: Record<string, any>;
  };
  const goToPath = ({ path, state: persistState, params = {} }: GoToPathType) => {
    history.push(
      generatePath(path, { orgId: state.orgId, id: vendorIdParam, ...params }),
      persistState
    );
  };

  const setContextData = (key, values) => {
    dispatch(setData({ [key]: values }));
  };

  const persistSavedState = (state) => dispatch(setData(state));

  const getTitleValues = () => ({
    companyName: <strong>{state.vendor.companyName}</strong>,
  });

  const createInternationalDeliveryMethod = async (
    createDeliveryMethod,
    partialState: Record<string, any> = {}
  ) => {
    const bodyRequest = transformToCreateDeliveryRequest({
      state: { ...state, ...partialState },
    });

    const deliveryMethod = await createDeliveryMethod(orgId, vendorIdParam, bodyRequest);

    setContextData(StateTypes.DELIVERY_METHOD, deliveryMethod);

    return deliveryMethod;
  };

  const createDelivery = useCallback(createInternationalDeliveryMethod, [state, profile, orgId]);

  const editDelivery = useCallback(
    async (editDeliveryMethod, partialState: Record<string, any> = {}) => {
      const request = transformToCreateDeliveryRequest({
        state: { ...state, ...partialState },
      });

      const { id } = state[StateTypes.DELIVERY_METHOD];
      const deliveryMethod = await editDeliveryMethod(id, orgId, vendorIdParam, request);

      return deliveryMethod;
    },
    [state, profile, orgId]
  );

  const getOriginValues = () =>
    isVendorDetailsOrigin(state.origin)
      ? {
          goExit: () => actions.goToPath({ path: state.redirectUrl }),
          headerLabel: '',
          isHideActions: true,
        }
      : {};

  const isEditDeliveryMethod = () =>
    !!state[StateTypes.DELIVERY_METHOD] && isVendorDetailsOrigin(state.origin);

  const onPurposeBack = () => {
    const url = payment.id
      ? locations.Bills.pay.funding.url({
          id: bill.id,
          paymentId: payment.id,
        })
      : locations.Bills.pay.funding.url({ id: bill.id });

    return goToPath({ path: url });
  };

  const hasNoUltimateBeneficialOwners = () => {
    const uboArray = state[StateTypes.ULTIMATE_BENEFICIAL_OWNERS];

    return !uboArray || (Array.isArray(uboArray) && uboArray.length === 0);
  };

  const actions = {
    init,
    setVendorLocation,
    setPaymentDetails,
    setContextData,
    goToPath,
    getTitleValues,
    getOriginValues,
    createDelivery,
    createInternationalDeliveryMethod,
    editDelivery,
    onPurposeBack,
    hasNoUltimateBeneficialOwners,
  };

  return [state, actions];
}

function reducer(state: InitialState, { type, payload }) {
  switch (type) {
    case ReducerActions.SET_DATA:
      return { ...state, ...payload };

    default:
      throw new Error();
  }
}

const setData = (payload) => ({
  type: ReducerActions.SET_DATA,
  payload,
});

export const initialState: InitialState = {
  [StateTypes.COUNTRIES]: [],
  [StateTypes.COUNTRY]: { code: '', name: '', billingType: '', isSupported: false, risk: '' },
  [StateTypes.OPTIONS]: [],
  [StateTypes.REQUIRED_FIELDS_VALIDATION]: {
    business_name: '^.{1,255}',
    name: '^.{1,255}',
    address: '^.{1,255}',
    city: '^.{1,255}',
    company_name: '^.{1,255}',
    country: '^.{1,255}',
    state_or_province: '^.{1,255}',
    bic_swift: '^[0-9A-Z]{8}$|^[0-9A-Z]{8,11}$',
    iban: '([A-Z0-9]\\s*){15,34}',
    postcode: '^.{1,255}',
  },
  [StateTypes.REQUIRED_FIELDS]: initRequiredFields(),
  [StateTypes.BANK_DETAILS]: {},
  [StateTypes.PURPOSE_OF_PAYMENT]: {
    type: '',
    description: '',
  },
  [StateTypes.DELIVERY_METHOD]: null,
  [StateTypes.FEE]: '',
  [StateTypes.CURRENCY]: 'usd',
  [StateTypes.VENDOR]: {},
  [StateTypes.AMOUNT]: 0,
  [StateTypes.ORG_ID]: '',
  [StateTypes.IBAN]: '',
  [StateTypes.SWIFT]: '',
  [StateTypes.EXIT_URL]: '',
  [StateTypes.REDIRECT_URL]: '',
  [StateTypes.ORIGIN]: '',
  [StateTypes.EDIT]: false,
  [StateTypes.ACCOUNT_NUMBER]: '',
  [StateTypes.BANK_NAME]: '',
  [StateTypes.ULTIMATE_BENEFICIAL_OWNERS]: null,
};

export default useInternational;
