import {
  Tag as ChakraTag, TagCloseButton, TagLabel, TagProps,
} from '@chakra-ui/react';

const Tag = ({ children, ...rest }: TagProps) => <ChakraTag {...rest}>{children}</ChakraTag>;

Tag.TagCloseButton = TagCloseButton;
Tag.TagLabel = TagLabel;

export { Tag };
