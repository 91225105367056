import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
`;

export const Icon = styled.i`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.ds.gray[200]};
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.textStyles.ds.body1};
  line-height: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.ds.black};
`;
