import React from 'react';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { Expandable } from 'src/app/utils/types';

type Props = {
  label?: string;
  validationError?: string | null;
  labelValues?: { value?: string };
  required?: boolean;
  autoFocus?: boolean;
  value?: string;
  onChange?: (change: Expandable<{ value: string }>) => void;
};

export const VendorEmailField = ({
  label = 'bills.pay.collectVendorEmailModal.emailLabel',
  labelValues = {},
  validationError,
  required = false,
  autoFocus = false,
  value,
  onChange,
}: Props) => (
  <MITextInput
    id="contactEmail"
    label={label}
    labelValues={labelValues}
    placeholder="bills.pay.collectVendorEmailModal.emailPlaceholder"
    value={value}
    autoFocus={autoFocus}
    required={required}
    type="email"
    onChange={onChange}
    errorMessage={validationError}
    privateData
  />
);
