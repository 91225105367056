import styled from 'styled-components';
import { theme } from '@melio/billpay-design-system';

export const TooltipContainer = styled.div`
  background-color: ${(props) => props.theme.colors.ds.gray[100]};
  position: absolute;
  left: calc(100% + 2.2rem);
  padding: 0.8rem;
  bottom: 50%;
  width: 28.8rem;
  border-radius: 0.4rem;
  transform: translateY(50%);
`;

export const CloseIcon = styled.i`
  font-size: 2.4rem;
  color: ${theme.colors.ds.gray[300]};
  &:hover {
    cursor: pointer;
  }
`;

export const TextContainer = styled.div`
  color: ${({ theme }) => theme.colors.ds.white};
  ${(props) => props.theme.textStyles.ds.body3}
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
  padding: 0.8rem;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  ${(props) => props.theme.textStyles.ds.body2Semi}
  padding-bottom: 0.4rem;
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Arrow = styled.div`
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${(props) => props.theme.colors.ds.gray[100]};
  left: -1.4rem;
  top: 50%;
  transform: rotate(-135deg) translate(50%, 50%);
`;
