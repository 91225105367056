import * as React from 'react';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';

type Props = {
  goNextFlow: () => void;
  goCancel?: () => void;
  site: any;
};

const CantFindBankPage = ({ goNextFlow, goCancel, site }: Props) => (
  <QBOSuccessLayout
    illustration={site.theme?.pages?.CantFindBankPage?.cantFindImage}
    title="onboarding.fundingSources.bank.cantFind.title"
    text="onboarding.fundingSources.bank.cantFind.subtitle"
    buttonLabel="onboarding.fundingSources.bank.cantFind.next"
    buttonAction={goNextFlow}
    linkLabel={goCancel ? 'onboarding.fundingSources.bank.cantFind.cancel' : ''}
    linkAction={goCancel}
    hideHeader
    goExit={goCancel}
  />
);

export default withSiteContext()(CantFindBankPage);
