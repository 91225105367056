import React from 'react';
import { useIntl } from 'react-intl';
import { SmartIcon, Text } from '@melio/billpay-design-system';
import { FundingSourceImage } from 'src/app/version-2/pages/batch-bulk/renderers/PaymentMethod/PaymentMethod.renderer.styles';
import {
  getPaymentMethodLabel,
  getPaymentMethodsSmartIconsType,
} from 'src/app/version-2/utils/paymentMethods.utils';

export const PaymentMethodItem = ({ fundingSource }) => {
  const { formatMessage } = useIntl();

  const smartIconType = getPaymentMethodsSmartIconsType(fundingSource);

  const { label, values, nickname } = getPaymentMethodLabel(fundingSource);

  return (
    <>
      <FundingSourceImage>
        <SmartIcon type={smartIconType} size="1.6rem" />
      </FundingSourceImage>
      {nickname || <Text textStyle="ds.body3">{formatMessage({ id: label }, values)}</Text>}
    </>
  );
};
