import React from 'react';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

import { useBreak } from '../../hooks/useBreak';

import * as styles from './Tooltip.styles';

type BasicPopoverProps = Omit<PopoverProps, 'closeOnBlur' | 'closeOnEsc' | 'arrowShadowColor'>;

type QBMTooltipProps = BasicPopoverProps & {
  children?: React.ReactNode;
  label?: React.ReactNode;
  isDisabled?: boolean;
};

const TooltipMobile = ({
  children, label, isDisabled = false, ...rest
}: QBMTooltipProps) => {
  const { isMobile } = useBreak();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isMobile || isDisabled) return <>{children}</>;

  return (
    <Popover closeOnBlur={false} closeOnEsc={false} {...rest}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent onClick={(e) => e.stopPropagation()} {...styles.content}>
          <PopoverArrow />
          <PopoverCloseButton {...styles.close} />
          <PopoverBody {...styles.body}>{label}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export { TooltipMobile };
