import { useCallback } from 'react';
import noop from 'lodash/noop';
import analytics from 'src/app/services/analytics';

type Params = {
  selectableItemsIds?: string[];
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
};

type Response = {
  isAllSelected: boolean;
  isIntederminate: boolean;
  onAllCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
};
const eventName = 'data-table-event';
const useSelection = ({
  selectedIds = [],
  setSelectedIds = noop,
  selectableItemsIds = [],
}: Params): Response => {
  const isAllSelected =
    selectableItemsIds.length > 0 && selectedIds.length === selectableItemsIds.length;
  const isIntederminate = selectedIds.length > 0 && !isAllSelected;

  const onAllCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (checked) {
        analytics.trackAction(eventName, {
          action: 'select-all',
          id: selectableItemsIds,
        });
        setSelectedIds(selectableItemsIds);
      } else {
        analytics.trackAction(eventName, {
          action: 'deselect-all',
          id: null,
        });
        setSelectedIds([]);
      }
    },
    [selectableItemsIds, setSelectedIds]
  );

  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      const { checked } = event.target;

      if (checked) {
        analytics.trackAction(eventName, { action: 'select', id });
        setSelectedIds([...selectedIds, id]);
      } else {
        analytics.trackAction(eventName, { action: 'deselect', id });
        setSelectedIds(selectedIds.filter((i) => i !== id));
      }
    },
    [selectedIds, setSelectedIds]
  );

  return {
    isAllSelected,
    isIntederminate,
    onAllCheckboxChange,
    onCheckboxChange,
  };
};

export default useSelection;
