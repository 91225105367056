import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Flex } from '@melio/billpay-design-system';

import { useForm } from 'src/app/ui/form';
import { getOrgId } from 'src/app/redux/user/selectors';
import QBOVendorDeliveryMethodsList from 'src/app/pages/vendor/components/QBOVendorDeliveryMethodsList';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { getDeliveryMethodActionRedirectInfo } from 'src/app/pages/vendor/delivery-methods/utils';
import { AreaLoader } from '@melio/billpay-design-system';
import { DeliveryMethodType, VendorType } from 'src/app/utils/types';
import EditVendorForm from 'src/app/pages/qb-dashboard/components/EditVendorModal/EditVendorForm';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS, DELIVERY_METHOD_ORIGIN, NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import ValidationError from 'src/app/ui/ValidationError';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { useQueryState } from 'src/app/utils/hooks';
import { isRppsVendor } from 'src/app/pages/vendor-directory/utils';

type EditVendorProps = {
  closeModal: () => void;
};

function EditVendor({ closeModal }: EditVendorProps) {
  const [vendorId] = useQueryState<string>('vendorId');
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(vendorId));

  const location = useLocation();
  const orgId = useSelector(getOrgId);
  const vendorActions = useStoreActions(vendorsStore);
  const isUpdating = (
    useSelector(vendorsStore.selectors.update.status(vendorId)) as { loading: boolean }
  )?.loading;
  const isLoading = (
    useSelector(vendorsStore.selectors.fetch.status(vendorId)) as { loading: boolean }
  )?.loading;

  const [navigate] = useHistoryWithOrgId();
  const showVendorDeliveryMethods = vendor && !isRppsVendor(vendor);

  const submit = async (params) => {
    const validationErrors = getValidationErrors('vendor', params);

    if (!isValidationOk(validationErrors)) {
      throw new ValidationError({ validationErrors });
    }

    const hasChanged = checkIfVendorChanged(params);

    if (!hasChanged) {
      return closeModal();
    }

    try {
      await vendorActions.update({ orgId, ...params });
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: 'paymentDashboard.editVendor.success',
        textValues: {
          vendorName: params.companyName,
        },
      });
      closeModal();
    } catch (e) {
      // TODO: handle error
    }

    return params;
  };

  const checkIfVendorChanged = (newVendorValues) => {
    const nameChanged = newVendorValues.companyName !== vendor.companyName;
    const emailChanged = newVendorValues.contactEmail !== vendor.contactEmail;

    return nameChanged || emailChanged;
  };

  const [vendorMV, actions]: any[] = useForm(vendor, {
    submit,
  });

  const goEditDeliveryMethod = (deliveryMethod: DeliveryMethodType) => {
    const { actionUrl } = getDeliveryMethodActionRedirectInfo(vendor.id, deliveryMethod);
    const redirectUrl = `${location.pathname}${location.search}`;

    navigate({
      path: actionUrl,
      state: {
        redirectUrl,
        exitUrl: redirectUrl,
        origin: DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS,
      },
    });
  };

  if (isLoading || !vendor?.createdAt) {
    return (
      <Box p="20rem 10rem 10rem">
        <AreaLoader />
      </Box>
    );
  }

  return (
    <Box data-testid="edit-vendor-modal">
      <EditVendorForm vendorMV={vendorMV} />
      {showVendorDeliveryMethods && (
        <Box mt="4rem">
          <StyledQBOVendorDeliveryMethodsList
            vendor={vendor}
            goEditDeliveryMethod={goEditDeliveryMethod}
          />
        </Box>
      )}
      <Flex justifyContent="flex-end" mt="4rem">
        <MIButton
          label="paymentDashboard.editVendor.save"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          isProcessing={isUpdating}
          onClick={actions.submit}
        />
      </Flex>
    </Box>
  );
}

const StyledQBOVendorDeliveryMethodsList = styled(QBOVendorDeliveryMethodsList)`
  padding: 0;
`;

export default EditVendor;
