import styled from 'styled-components';

export const InfoSubmitButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  color: ${(props) => props.theme.text.color.darkGrey};
  text-align: center;
  margin-top: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;
