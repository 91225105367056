import React from 'react';
import { ROLE_TAGS, TAG_VARIANT } from 'src/app/utils/consts';
import MITag from '../common/MITag';

export type RoleTag = (typeof ROLE_TAGS)[keyof typeof ROLE_TAGS];

function mapStatusToTag(role: RoleTag | undefined) {
  switch (role) {
    case ROLE_TAGS.OWNER:
    case ROLE_TAGS.ADMIN:
      return { variant: TAG_VARIANT.BRAND, isSecondary: false };
    case ROLE_TAGS.CONTRIBUTOR:
      return { variant: ROLE_TAGS.CONTRIBUTOR, isSecondary: true };
    case ROLE_TAGS.ACCOUNTANT:
    default:
      return { variant: TAG_VARIANT.BRAND, isSecondary: true };
  }
}

interface Props {
  userRole?: RoleTag;
  testId?: string;
}

export const UserRoleTag = ({ userRole, testId }: Props) => (
  <MITag testId={testId} label={`user.role.${userRole}`} {...mapStatusToTag(userRole)} />
);
