import { MIFormattedText } from 'src/app/utils/formatting';
import { PaymentIcon } from 'src/app/components/layout/PaymentActivityElements';
import {
  Container,
  Content,
  IconComponentContainer,
  Subtitle,
  TextContainer,
  Title,
} from './RefundActivityItem.styles';

type Props = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  IconComponent?: JSX.Element;
};

export const RefundActivityItem = ({ children, title, subtitle, IconComponent }: Props) => (
  <Container>
    <PaymentIcon isBillPaid={false} data-testid="refund-activity-item_icon">
      <IconComponentContainer>{IconComponent}</IconComponentContainer>
    </PaymentIcon>
    <TextContainer>
      <Title data-testid="refund-activity-item_title">
        <MIFormattedText label={title} />
      </Title>
      <Content>{children}</Content>
      {subtitle && (
        <Subtitle data-testid="refund-activity-item_subtitle">
          <MIFormattedText label={subtitle} />
        </Subtitle>
      )}
    </TextContainer>
  </Container>
);
