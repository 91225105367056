import React from 'react';
import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { FundingSource, CardAccount } from 'src/app/version-2/model/dtos';
import { CARD_TYPES } from 'src/app/utils/consts';
import {
  getCardIcon,
  getCardImageUrl,
  isCardExpired,
  convertDateToMonthYearFormat,
  getExpirationDateHint,
  getCardDescription,
  getCardAdditionDescription,
} from 'src/app/utils/card';
import { getModifyFundingSourceOptions } from 'src/app/utils/funding-sources';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import profileStore from 'src/app/modules/profile/profile-store';
import { isEligibleToReceiveVirtualCard } from 'src/app/utils/payments';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import { getWizardFlow } from 'src/app/redux/payBillWizard/selectors';
import { allowInternational } from 'src/app/version-2/utils/international.utils';
import { getPaymentMethodNameParts } from 'src/app/version-2/utils/paymentMethods.utils';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  onClick?: () => void;
  method: FundingSource;
  showDropdownSelectedMethod: boolean;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
  modifyActions: Record<string, any>;
  isVendorAbsorbedFee?: boolean;
  description?: string;
  methodErrors?: any;
  onBenefitsClicked: (card?: FundingSource) => void;
  isBillingFeeFlow?: undefined | boolean;
  isInternationalVendor?: boolean;
  isInternationalEntryPoint?: boolean;
};

export const CreditPaymentMethod = ({
  onClick,
  method,
  isSelected,
  showDropdownSelectedMethod,
  optionComponent: OptionComponent,
  disabled,
  modifyActions,
  isVendorAbsorbedFee,
  description,
  onBenefitsClicked,
  isBillingFeeFlow,
  isInternationalVendor = false,
  isInternationalEntryPoint,
}: Props) => {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const payBillWizardFlow = useSelector(getWizardFlow);

  const [hasInternationalMastercard] = featureFlags.useFeature(
    FeatureFlagsEnum.INTERNATIONAL_MASTERCARD
  );

  const internationalCard = allowInternational({
    fundingSource: method,
    isInternationalVendor: isInternationalVendor || isInternationalEntryPoint,
  });

  if (isInternationalEntryPoint && !internationalCard.allow) return null;

  if (isInternationalEntryPoint && !hasInternationalMastercard) return null;

  if (showDropdownSelectedMethod) {
    if (method) {
      const icon = getCardIcon(method);
      const imageSrc = getCardImageUrl(method);

      return (
        <SelectedMethod
          icon={icon}
          imageSrc={imageSrc}
          title="bills.form.paymentActivity.scheduledBill.scheduleMethodCreditCard"
          label="bills.pay.fundingSource.creditLabel"
          labelValues={getPaymentMethodNameParts({ fundingSource: method })}
        />
      );
    }

    return null;
  }

  if (!method) {
    const descriptionLabel =
      internationalCard.description ||
      description ||
      getCardAdditionDescription({ flow: payBillWizardFlow, isVendorAbsorbedFee });

    const isDisabled = disabled || !internationalCard?.allow;

    return (
      <OptionComponent
        id="credit"
        disabled={isDisabled}
        label="onboarding.fundingSources.options.credit.label"
        description={descriptionLabel}
        descriptionValues={{
          cardIcons: null,
          link: (
            <BenefitsLink
              label="onboarding.fundingSources.options.credit.link"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onBenefitsClicked();
              }}
              disabled={isDisabled}
            />
          ),
        }}
        icon="icon-credit-card-icon"
        onClick={isDisabled ? null : onClick}
        isEmptyList
      />
    );
  }

  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);
  const isCreditCardExpired = isCardExpired(method.cardAccount as CardAccount);
  const isDisabled = disabled || !internationalCard?.allow;

  const actionOptions = getModifyFundingSourceOptions({
    permissions,
    modifyActions,
    isDisabled,
    origin: method.origin,
    isExpired: isCreditCardExpired,
    hasLabel: !!method.nickname,
  });

  const descriptionLabel =
    internationalCard.description ||
    description ||
    getCardDescription({
      method,
      isCreditCardExpired,
      isVendorAbsorbedFee,
      isBillingFeeFlow,
    });

  return (
    <OptionComponent
      id={method.id}
      isSelected={isSelected}
      disabled={isDisabled}
      isExpired={isCreditCardExpired}
      icon={icon}
      imageSrc={imageSrc}
      label="bills.pay.fundingSource.creditLabel"
      labelValues={getPaymentMethodNameParts({ fundingSource: method })}
      onTopDescription={method.nickname}
      hint={getExpirationDateHint(method.cardAccount as CardAccount)}
      hintValues={{
        expirationDate: convertDateToMonthYearFormat(method.cardAccount?.expiration || ''),
      }}
      description={descriptionLabel}
      descriptionValues={
        internationalCard.values || {
          cardIcons: null,
          link: (
            <BenefitsLink
              label="onboarding.fundingSources.options.credit.link"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onBenefitsClicked(method);
              }}
              disabled={isDisabled}
            />
          ),
        }
      }
      onClick={isDisabled ? null : onClick}
      actionOptions={actionOptions}
    />
  );
};

function CreditPaymentSelectedMethod({
  method,
  methodErrors,
}: {
  method: FundingSource;
  methodErrors?: any;
}) {
  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);

  return (
    <SelectedMethod
      icon={icon}
      imageSrc={imageSrc}
      title="bills.form.paymentActivity.scheduledBill.scheduleMethodCreditCard"
      label="bills.pay.fundingSource.creditLabel"
      labelValues={getPaymentMethodNameParts({ fundingSource: method })}
      errorLabel={methodErrors.label}
      errorLabelValues={methodErrors.values}
    />
  );
}

const BenefitsLink = styled(MIInlineLink)<{ disabled?: boolean }>`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

CreditPaymentMethod.methodType = CARD_TYPES.CREDIT;
CreditPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherCreditCard';
CreditPaymentMethod.SelectedMethod = CreditPaymentSelectedMethod;
CreditPaymentMethod.isDisabled = (method, additionalParams) => {
  if (additionalParams?.isSelectedDeliveryMethodVirtualCard) {
    return !isEligibleToReceiveVirtualCard(method) || isCardExpired(method.cardAccount);
  }

  return isCardExpired(method.cardAccount);
};
CreditPaymentMethod.showAddAnotherOption = true;
