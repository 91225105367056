import { removeIdsFromState } from 'src/app/helpers/redux/listsActions';
import { RestfulSliceParams } from './types';
import { ON_FAILURE, ON_REQUEST, ON_SUCCESS, createApiCallSlice } from './createApiCallSlice';

export function deleteSliceReducer(actionName = 'delete') {
  return {
    [ON_REQUEST](state, action) {
      state[actionName][action.payload.id] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const { id } = action.meta.identifier;

      delete state[actionName][id];
      removeIdsFromState(state, [id]);
    },
    [ON_FAILURE](state, action) {
      state[actionName][action.meta.identifier.id] = {
        loading: false,
        error: action.error,
      };
    },
  };
}

export function createDeleteSlice<T>({
  storeName,
  api,
  validate,
  sagas,
  actionName = 'delete',
}: RestfulSliceParams<T>) {
  return createApiCallSlice({
    initialState: {
      byId: {},
      [actionName]: {},
      lists: {},
    },
    name: `[${storeName}] ${actionName}`.toUpperCase(),
    api,
    reducers: deleteSliceReducer(actionName),
    validate,
    selectors: {
      status: (id) => (state) => state[storeName].delete[id],
    },
    sagas,
  });
}
