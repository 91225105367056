import React from 'react';
import styled from 'styled-components';
import { AreaLoader } from '@melio/billpay-design-system';
import { MICodeInput } from 'src/app/components/common/MICodeInput';
import MILink from 'src/app/components/common/MILink';
import MINotificationCard from 'src/app/components/common/MINotificationCard';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';

type Props = {
  email: string;
  isLoading: boolean;
  errorCode: string;
  onPrev?: () => void;
  sendEmailVerificationCode: () => void;
  onChange: (code: string) => void;
  codeInputKey: number;
  isCodeResend: boolean;
};
const CodeVerificationPage = ({
  codeInputKey,
  email,
  isLoading,
  errorCode,
  sendEmailVerificationCode,
  onChange,
  isCodeResend,
}: Props) => {
  const { WizardElements, StepLayout } = useSiteContext().components;

  return (
    <>
      {isLoading && <AreaLoader placement="melioMe" />}
      <StepLayout
        title="guests.emailVerify.title"
        subtitle="guests.emailVerify.subTitle"
        subTitleValues={{
          email: <strong>{email}</strong>,
          br: <br />,
        }}
        footer={
          <Footer>
            <WizardElements.WizardLinkContainer>
              <MILink to={sendEmailVerificationCode} label="guests.emailVerify.sendCodeAgain" />
            </WizardElements.WizardLinkContainer>
          </Footer>
        }
        hideHeader
      >
        {isCodeResend && (
          <NotificationCard
            type={NOTIFICATION_CARD_TYPES.SUCCESS}
            subtitle={{
              label: 'guests.emailVerify.resendNotification',
            }}
          />
        )}
        <MICodeInput
          key={codeInputKey}
          type="number"
          pattern="\d*"
          isValid={!errorCode}
          onChange={onChange}
        />
        {errorCode && (
          <WrongCodeMessage>
            <MIFormattedText label={`server.${errorCode}`} />
          </WrongCodeMessage>
        )}
      </StepLayout>
    </>
  );
};

const Footer = styled.div`
  margin: 4rem 0;
  ${(props) => props.theme?.components?.CodeVerificationPage?.Footer}
`;

const WrongCodeMessage = styled.div`
  text-align: center;
  margin-top: 1rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.text.color.red};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const NotificationCard = styled(MINotificationCard)`
  margin: 0 0 4rem;
  @media ${devices.mobile} {
    margin: 0 0 2rem;
  }
`;

export default CodeVerificationPage;
