import styled from 'styled-components';

export const CreditCardTileStyled = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
  gap: 2rem;

  width: 100%;
  height: ${(props) => (props.isSelected ? '3.8rem' : '4rem')};
  overflow: hidden;

  background: ${(props) =>
    props.isSelected
      ? props.theme.colors.primary.translucent1
      : props.theme.colors.white.veryLightGrey};

  border: ${(props) =>
    props.isSelected
      ? `0.2rem solid ${props.theme.colors.primary.opaque}`
      : `0.1rem solid ${props.theme.text.color.grey}`};
  border-radius: 0.8rem;
`;

export const CreditCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin: 0 2rem;
`;

export const CreditCardListButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
