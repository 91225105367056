import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Flex } from '@melio/billpay-design-system';
import {
  Arrow,
  CloseIcon,
  RelativeContainer,
  TextContainer,
  TooltipContainer,
} from './OnboardingTooltip.styles';

export interface OnboardingTooltipProps {
  isOpen: boolean;
  onClose: () => void;
}

export const OnboardingTooltip: FC<OnboardingTooltipProps> = ({ isOpen, onClose }) => {
  const { formatMessage } = useIntl();
  const [display, setDisplay] = useState('none');

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setDisplay('block'), 2000);
    } else {
      setDisplay('none');
    }
  }, [isOpen]);
  const closeTooltip = () => {
    onClose();
    setDisplay('none');
  };

  return isOpen ? (
    <TooltipContainer display={display}>
      <RelativeContainer>
        <Arrow />
        <Flex justifyContent="space-between" gap="1.6rem">
          <TextContainer>
            {formatMessage({ id: 'batchBulkPage.helpGuide.footer.tooltip' })}
          </TextContainer>
          <CloseIcon className="icon-close-icon" onClick={closeTooltip} />
        </Flex>
      </RelativeContainer>
    </TooltipContainer>
  ) : null;
};
