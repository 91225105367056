type useVirtualCardInfoModalProps = {
  onToggle?: () => void;
};

export const useVirtualCardInfoModal = (props: useVirtualCardInfoModalProps) => {
  const { onToggle } = props;

  const onToggleVirtualCardInfoModal = () => {
    if (onToggle) {
      onToggle();
    }
  };

  return {
    onToggleVirtualCardInfoModal,
  };
};
