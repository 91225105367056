import React from 'react';
import { RecordOf } from 'immutable';
import { Box, Flex, Link } from '@melio/billpay-design-system';

import { DeliveryMethodType, VendorType } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import MIDotsActionsMenu from 'src/app/components/common/MIDotsActionsMenu';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';
import has from 'lodash/has';
import { CONSTS } from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import { shouldAllowEditDeliveryMethod } from 'src/app/version-2/utils/deliveryMethod.utils';
import { DeliveryMethod } from 'src/app/version-2/model/dtos';

import { cardContainerStyles } from './styles';

type Props = {
  vendor: RecordOf<VendorType> | VendorType;
  deliveryMethod: any;
  goEditDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  onToggleVirtualCardInfoModal: () => void;
};

export const VirtualCardDeliveryMethodItem = ({
  deliveryMethod,
  goEditDeliveryMethod,
  onToggleVirtualCardInfoModal,
}: Props) => {
  const isDeliveryMethodExist: boolean = has(deliveryMethod, 'id');

  const renderIcon = () =>
    isDeliveryMethodExist ? (
      <Box ml="1">
        <Mastercard width="3.4rem" height="4rem" />
      </Box>
    ) : (
      <Box __css={iconStyles}>
        <i className="icon-credit-card-icon" />
      </Box>
    );

  const renderTitle = () =>
    isDeliveryMethodExist ? (
      <MIFormattedText
        label="vendors.deliveryMethods.virtualCard.titleTo"
        values={{ to: deliveryMethod.virtualCardAccount?.accountEmail || '' }}
      />
    ) : (
      <MIFormattedText label="vendors.deliveryMethods.virtualCard.title" />
    );

  const goLearnMore = () => {
    analytics.trackAction('VirtualCardLearnMore-Clicked');
    onToggleVirtualCardInfoModal();
  };

  return (
    <Flex __css={containerStyles}>
      <Flex flexDirection="column">
        <Flex>
          {renderIcon()}
          <Flex ml="4" direction="column">
            <Box color="black">{renderTitle()}</Box>
            <Box color="gray.300">
              <MIFormattedText
                label="vendors.deliveryMethods.virtualCard.subtitle"
                values={{
                  learnMoreLink: (...chunks) => (
                    <Link onClick={goLearnMore} cursor="pointer">
                      {React.Children.toArray(chunks)}
                    </Link>
                  ),
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Action deliveryMethod={deliveryMethod} goEditDeliveryMethod={goEditDeliveryMethod} />
    </Flex>
  );
};

const Action = ({
  deliveryMethod,
  goEditDeliveryMethod,
}: Pick<Props, 'deliveryMethod' | 'goEditDeliveryMethod'>) => {
  if (deliveryMethod?.id) {
    if (!shouldAllowEditDeliveryMethod(deliveryMethod as unknown as DeliveryMethod)) {
      return null;
    }

    const actionOptions = [
      {
        label: 'deliveryMethods.actions.edit',
        action: () => goEditDeliveryMethod(deliveryMethod),
      },
    ];

    return (
      <Flex align="center">
        <MIDotsActionsMenu actionOptions={actionOptions} />
      </Flex>
    );
  }

  return (
    <MIInlineLink
      onClick={() =>
        goEditDeliveryMethod({
          ...deliveryMethod,
          deliveryType: CONSTS.DELIVERY_TYPE.VIRTUAL_CARD,
        })
      }
      label="deliveryMethods.actions.addMethod"
    />
  );
};

const containerStyles = {
  ...cardContainerStyles,
  justifyContent: 'space-between',
  apply: 'textStyles.body3',
  fontWeight: 500,
};

const iconStyles = {
  fontSize: '4rem',
  boxSizing: 'border-box',
  color: 'gray.500',
};
