import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Box, Popover, PopoverArrow } from '@melio/billpay-design-system';
import { getUserPreferences } from 'src/app/redux/user/selectors';
import { getNotificationsList } from 'src/app/redux/notifications/selectors';
import NotificationIcon from 'src/app/images/general/notifications.svg';
import NoNotificationsIcon from 'src/app/images/general/announcement.svg';
import { MIFormattedText } from 'src/app/utils/formatting';
import useUpdateUserPreference from 'src/app/modules/users/hooks/useUpdateUserPreferences';
import NotificationsItem from './NotificationsItem/NotificationsItem';
import {
  BodyWrap,
  defaultArrowStyles,
  HeaderContentIcon,
  Image,
  NewTag,
  NotificationsLink,
  PopoverWrap,
  TransparentButton,
  NoNotifications,
  popoverContentStyles,
  popoverHeaderStyles,
  popoverBodyStyles,
  noNotificationContainerStyles,
} from './Notifications.styles';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const Notifications: FC<Props> = ({ isOpen, setIsOpen }) => {
  const notifications = useSelector(getNotificationsList);
  const userPreferences = useSelector(getUserPreferences);
  const { updateUserPreference } = useUpdateUserPreference();

  // save user preference on ref in order to base the functionality like red dot indicator on the original last notification id
  // and not on the current userPreference from the store that might be changed when the popper is open
  const lastNotificationId = useRef(userPreferences.lastNotificationIdSeen);

  useEffect(() => {
    const updateLastNotificationId = async () => {
      await updateUserPreference({ key: 'lastNotificationIdSeen', value: notifications[0]?.id });
    };

    if (isOpen && notifications.length) {
      updateLastNotificationId();
    }
  }, [isOpen]);

  const isNotificationNewHandler = useCallback(
    (notificationId) => {
      if (lastNotificationId.current) {
        return notificationId - +lastNotificationId.current > 0;
      } else {
        return true;
      }
    },
    [lastNotificationId.current]
  );

  // check if there is a new notification by checking if the latest notification's id is greater than the lastNotificationIdSeen
  // from the userPreferences
  const isNewNotificationExist = useMemo(
    () => isNotificationNewHandler(notifications[0]?.id),
    [notifications, isOpen]
  );

  const handleClosePopper = useCallback(() => {
    setIsOpen(false);
    lastNotificationId.current = userPreferences.lastNotificationIdSeen;
  }, [userPreferences.lastNotificationIdSeen]);

  return (
    <>
      <NotificationsLink>
        <PopoverWrap>
          <Popover
            isOpen={isOpen}
            onClose={handleClosePopper}
            placement="bottom-end"
            trigger="click"
            arrowStyles={defaultArrowStyles}
          >
            <Popover.PopoverTrigger>
              <TransparentButton onClick={() => setIsOpen(!isOpen)}>
                {isNewNotificationExist && <NewTag />}
                <Image src={NotificationIcon} />
              </TransparentButton>
            </Popover.PopoverTrigger>
            <Popover.PopoverContent {...popoverContentStyles}>
              <PopoverArrow />
              <Popover.PopoverHeader {...popoverHeaderStyles}>
                <Flex justifyContent="space-between">
                  <Box textStyle="ds.h6" fontWeight="600">
                    <MIFormattedText label="qbo.header.notifications.title" />
                  </Box>
                  <Flex cursor="pointer" onClick={handleClosePopper}>
                    <HeaderContentIcon className="icon-close-icon" />
                  </Flex>
                </Flex>
              </Popover.PopoverHeader>
              <Popover.PopoverBody {...popoverBodyStyles}>
                {notifications.length ? (
                  <BodyWrap>
                    {notifications.map((notification) => (
                      <NotificationsItem
                        key={notification.id}
                        isNew={isNotificationNewHandler(notification.id)}
                        notification={notification}
                      />
                    ))}
                  </BodyWrap>
                ) : (
                  <Flex {...noNotificationContainerStyles}>
                    <NoNotifications src={NoNotificationsIcon} />
                    <Box textStyle="ds.body1">
                      <MIFormattedText label="qbo.header.notifications.noNotifications" />
                    </Box>
                  </Flex>
                )}
              </Popover.PopoverBody>
            </Popover.PopoverContent>
          </Popover>
        </PopoverWrap>
      </NotificationsLink>
    </>
  );
};

export default Notifications;
