import { BillingTypes } from '../../types';

export const currenciesOptions = [
  {
    value: 'usd',
    flag: 'us',
    label: 'USD',
  },
];

export const dynamicFieldsInit = {
  [BillingTypes.IBAN]: '',
  [BillingTypes.SWIFT]: '',
};
