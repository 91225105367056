import React from 'react';
import styled from 'styled-components';
import { Badge, Flex, SmartIcon, Tooltip } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';

import { PAID_BILL_DASHBOARD_STATUSES } from 'src/app/pages/qb-dashboard/consts';
import { isUndepositedOverdueCheck } from 'src/app/utils/payments';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  item: DashboardListItemType;
};

const QBOPaidStatusCell = ({ item }: Props) => {
  const [voidChecksEnabled] = featureFlags.useFeature('bp-void-check', false);
  const isVoidCheckDelay = voidChecksEnabled && isUndepositedOverdueCheck(item as any);

  const { statusInDashboard } = item.metadata;

  if (statusInDashboard === PAID_BILL_DASHBOARD_STATUSES.MARKED_AS_PAID) {
    return (
      <Badge variant="primary">
        <MIFormattedText label="paymentDashboard.columns.paidStatus.markedAsPaid" />
      </Badge>
    );
  }

  if (statusInDashboard === PAID_BILL_DASHBOARD_STATUSES.DEPOSITED) {
    return (
      <Badge variant="primary">
        <MIFormattedText label="paymentDashboard.columns.paidStatus.deposited" />
      </Badge>
    );
  }

  if (statusInDashboard === PAID_BILL_DASHBOARD_STATUSES.COMPLETED) {
    return (
      <Badge variant="primary">
        <MIFormattedText label="paymentDashboard.columns.paidStatus.completed" />
      </Badge>
    );
  }

  return (
    <Flex align="center" gap="1rem">
      <Badge variant="primaryOutlined">
        <MIFormattedText label="paymentDashboard.columns.paidStatus.sent" />
      </Badge>

      {!!isVoidCheckDelay && (
        <Tooltip
          placement="top"
          label={<MIFormattedText label="bills.pay.voidCheckRecovery.dashboard.tooltip" />}
        >
          <InfoIconTooltip>
            <SmartIcon type="info" />
          </InfoIconTooltip>
        </Tooltip>
      )}
    </Flex>
  );
};

export default QBOPaidStatusCell;

const InfoIconTooltip = styled.div`
  path {
    fill: ${({ theme }) => theme.colors.ds.yellow[200]};
  }
`;
