import * as React from 'react';
import { History } from 'history';
import get from 'lodash/get';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  FULL_STORY_MASK_RULE_CLASS,
  SERVER_RESPONSE_CODES,
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  CARD_TYPES,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import { featureFlags } from '@melio/shared-web';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import paymentStore from 'src/app/modules/payments/payment-store';
import {
  AddFundingSourceWizardOriginEnum,
  DbAnalyticsTraitsEnum,
  SingleSelectFlavorEnum,
  FeatureFlagsEnum,
} from 'src/app/version-2/model/enums';
import { CardAccount } from 'src/app/version-2/model/dtos';
import { addCardAccountFundingSourceActions } from 'src/app/version-2/pages/add-card-account/modules/fundingSource/addCardAccountFundingSource.slice';
import { addCardFundingSourceApi } from 'src/app/version-2/pages/add-card-account/api/fundingSource/addCardFundingSource.api';
import { loggingApi } from 'src/app/version-2/api/loggers';
import locations from 'src/app/utils/locations';
import { withPreservedStateNavigator } from 'src/app/hoc';
import CardFormInput from './components/CardFormInput';
import analytics from 'src/app/services/analytics';
import api from 'src/app/services/api/financialAccounts';
import MISingleSelect from 'src/app/components/common/MISingleSelect';
import { WizardFormRow } from 'src/app/components/layout/WizardElements';
import { isSandboxIndicator } from 'src/app/utils/funding-sources';
import { GlobalState } from 'src/app/redux/types';
import US_STATES from 'src/app/utils/us-states';
import { selectFundingSourceAction } from 'src/app/redux/payBillWizard/actions';
import { loadFundingSourcesAction } from 'src/app/redux/user/actions';
import { getOrgId } from 'src/app/redux/user/selectors';
import { FundingSource } from 'src/app/version-2/model/dtos';

type LocationState = {
  token: string;
  digits: string;
  cardBin: string;
  expiration: string;
  goExit: () => void;
  fundingSource?: FundingSource;
  preservedState?: Record<string, any>;
  origin: string;
  newFundingSourceId?: boolean;
};

type MapStateToProps = {
  orgId: string;
};

type MapDispatchToProps = {
  selectFundingSourceForPayBillFlow: (id: number) => void;
  refreshFundingSources: () => Promise<FundingSource[]>;
  justPayUpdateFundingSourceId: (id: number) => void;
  setCreatedCardAccount: ({
    accounts,
    code,
    error,
  }: {
    accounts?: CardAccount[];
    code?: string;
    error?: { code: string; message: string };
  }) => void;
};

type Props = {
  locationState: LocationState;
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  site: any;
  showHints?: boolean;
  history?: History;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  errorCode: string | null | undefined;
  successDialog: boolean;
  isLoading: boolean;
  id?: number | null;
  cardAccount:
    | {
        firstName: string;
        lastName: string;
        card4digits: string;
        network: string;
        cardType: string;
      }
    | null
    | undefined;
  firstName?: string | null;
  lastName?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  submitValidation: Record<string, any>;
};

const fieldsValidationFlag = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
};

const eventPage = 'add-card';
const CLOSE_MODAL_TIMEOUT = 3000;

function withFeatureFlag() {
  return function withComp(Component: React.ComponentType) {
    return (props) => {
      const children = { props };
      const [showHints] = featureFlags.useFeature('cc-hints');

      return (
        <Component {...props} showHints={showHints}>
          {children}
        </Component>
      );
    };
  };
}

class SetCardAccountsHolderPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      errorCode: null,
      successDialog: false,
      cardAccount: null,
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      id: null,
      submitValidation: fieldsValidationFlag,
    };
  }

  onDone = () => {
    const isBasisTheoryFeatureFlag = featureFlags.defaultClient.getVariant(
      FeatureFlagsEnum.BP_BASIS_THEORY,
      true
    );

    const { firstName, lastName, address, city, state, zipCode } = this.state;
    const validationObj = ['firstName', 'lastName', 'address', 'city', 'state', 'zipCode'].reduce(
      (obj, s) => {
        if (Object.prototype.hasOwnProperty.call(this.state, s) && !this.state[s]) obj[s] = true;

        return obj;
      },
      {}
    );

    if (Object.keys(validationObj).length) {
      this.setState({
        submitValidation: { ...fieldsValidationFlag, ...validationObj },
      });
      analytics.track(
        eventPage,
        'add-card-account-submit-validation-error',
        this.state.submitValidation
      );

      return false;
    }

    analytics.track(eventPage, 'add-card-account-submit');
    this.setState({ isLoading: true });

    const { refreshFundingSources, locationState, orgId } = this.props;
    const { token, expiration, digits, cardBin } = locationState;

    let apiRequest = api.addCardAccount;

    let apiRequestParams: any = {
      orgId,
      token,
      expiration,
      card4digits: digits,
      firstName,
      lastName,
      line1: address,
      city,
      state,
      zipcode: zipCode,
      cardBin,
    };

    if (isBasisTheoryFeatureFlag) {
      apiRequest = addCardFundingSourceApi.createCardAccount;
      apiRequestParams = {
        orgId,
        token,
        expiration,
        card4digits: digits,
        firstName,
        lastName,
        address,
        city,
        state,
        zipCode,
        cardBin,
      };
    }

    return apiRequest(orgId, apiRequestParams, { catchCall: true })
      .then(({ accounts, code }) => {
        this.props.setCreatedCardAccount({
          accounts,
          code,
        });

        const success = code === SERVER_RESPONSE_CODES.OK;
        const cardAccount = get(accounts, '0.cardAccount', null);

        if (success) {
          const cardTypeEventLabel = cardAccount ? cardAccount.cardType.toLowerCase() : null;

          analytics.track(eventPage, 'done-adding-new-card');
          analytics.trackMqlEvent('added-funding', 'mql');

          if (cardTypeEventLabel) {
            analytics.trackMqlEvent(`added-funding-${cardTypeEventLabel}`, 'mql');
          }

          analytics.setTraits({
            [DbAnalyticsTraitsEnum.ADDED_FUNDING]: true,
          });
          analytics.setFundingSourceTraits();
        } else {
          analytics.track(eventPage, 'adding-new-card-error', { code });
        }

        this.setState(
          {
            id: get(accounts, '0.id'),
            cardAccount,
            firstName: null,
            lastName: null,
            address: null,
            city: null,
            state: null,
            zipCode: null,
            errorCode: code,
            isLoading: false,
            submitValidation: fieldsValidationFlag,
          },
          () => {
            if (success) {
              setTimeout(this.onCloseSuccessModal, CLOSE_MODAL_TIMEOUT);
            }
          }
        );

        return refreshFundingSources();
      })
      .catch((err) => {
        analytics.track(eventPage, 'failed-adding-new-card');

        this.props.setCreatedCardAccount({
          error: err,
        });
        loggingApi.error('addCardFundingSourceSaga.validateCardHandler(): failed', err);

        return this.setState({
          errorCode: err,
          isLoading: false,
        });
      });
  };

  onCloseErrorModal = () => {
    this.setState({
      errorCode: null,
    });
  };

  onCloseSuccessModal = () => this.goNext();

  getCardLabel = (type) =>
    type === CARD_TYPES.CREDIT
      ? 'onboarding.fundingSources.card.successNoteCredit'
      : 'onboarding.fundingSources.card.successNoteDebit';

  goPrev = () => {
    this.props.navigate(locations.Onboarding.fundingSources.card.index.url());
  };

  goExit = () => {
    if (this.props.navigateToExitWithPreservedState) {
      this.props.navigateToExitWithPreservedState(this.props.locationState);
    } else {
      this.props.history?.goBack();
    }
  };

  goNext = async () => {
    const {
      locationState,
      selectFundingSourceForPayBillFlow,
      navigate,
      justPayUpdateFundingSourceId,
    } = this.props;
    const { id } = this.state;
    const origin = get(locationState, 'preservedState.origin', '');

    if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL && id) {
      selectFundingSourceForPayBillFlow(id);
    }

    if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY && id) {
      justPayUpdateFundingSourceId(id);
    }

    if (origin === AddFundingSourceWizardOriginEnum.BATCH_BULK && id) {
      this.props.navigateWithPreservedState({ newFundingSourceId: id });
    } else {
      navigate(locations.Onboarding.fundingSources.bank.account.url(), false, {
        ...locationState,
        fundingSourceId: id,
        origin,
      });
    }
  };

  returnVal = ({ value, id }: { value: string; id: string }) => {
    if (!Object.prototype.hasOwnProperty.call(this.state, id)) return;

    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
      // eslint-disable-next-line react/no-access-state-in-setstate
      submitValidation: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.submitValidation,
        [id]: false,
      },
    }));
  };

  render() {
    const {
      firstName,
      lastName,
      address,
      city,
      state,
      zipCode,
      submitValidation,
      isLoading,
      cardAccount,
      errorCode,
    } = this.state;
    const { locationState, site, showHints } = this.props;

    const origin = get(locationState, 'preservedState.origin', '');
    const currentGoExit =
      origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.GUEST_ONBOARDING ? null : this.goExit;
    const successSubtitle = 'common.rawValue';
    const successSubtitleValues = {
      value: cardAccount
        ? `${cardAccount.network} (${cardAccount.card4digits})\n${cardAccount.firstName} ${cardAccount.lastName}`
        : '',
    };
    const successTitle = cardAccount ? this.getCardLabel(cardAccount.cardType.toLowerCase()) : '';
    const isSandboxIndicatorShown = isSandboxIndicator(site);

    return (
      <site.components.StepLayout
        title="onboarding.fundingSources.card.title"
        onPrev={this.goPrev}
        goExit={currentGoExit}
        onSubmit={this.onDone}
        nextLabel="onboarding.fundingSources.card.save"
        isLoading={isLoading}
        hideHeader
        isSandboxIndicatorShown={isSandboxIndicatorShown}
      >
        {errorCode === SERVER_RESPONSE_CODES.OK && (
          <QBODialog
            type={DIALOG_TYPE.ALERT}
            variant={DIALOG_VARIANTS.SUCCESS}
            title={successTitle}
            subtitleValues={successSubtitleValues}
            subtitle={successSubtitle}
            onCancelAction={this.onCloseSuccessModal}
          />
        )}
        {errorCode && errorCode !== SERVER_RESPONSE_CODES.OK && (
          <QBODialog
            type={DIALOG_TYPE.ALERT}
            variant={DIALOG_VARIANTS.ERROR}
            title="onboarding.fundingSources.card.notAddedTitle"
            subtitle="server.CHC04"
            onCancelAction={this.onCloseErrorModal}
            cancelButtonText="onboarding.fundingSources.card.errorButton"
          />
        )}
        <div className={FULL_STORY_MASK_RULE_CLASS}>
          <WizardFormRow>
            <CardFormInput
              value={firstName}
              label="onboarding.fundingSources.card.firstName.label"
              required
              id="firstName"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.firstName.errorMessage"
              validationTest="string"
              returnVal={this.returnVal}
              submitValidation={submitValidation.firstName}
              autoFocus
              notices={[showHints && 'onboarding.fundingSources.card.firstName.hint']}
            />
            <CardFormInput
              value={lastName}
              label="onboarding.fundingSources.card.lastName.label"
              required
              id="lastName"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.lastName.errorMessage"
              validationTest="string"
              returnVal={this.returnVal}
              submitValidation={submitValidation.lastName}
              notices={[showHints && 'onboarding.fundingSources.card.lastName.hint']}
            />
          </WizardFormRow>
          <CardFormInput
            value={address}
            label="onboarding.fundingSources.card.address.label"
            id="address"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.address.errorMessage"
            validationTest="string"
            wizardColumn={false}
            returnVal={this.returnVal}
            required
            submitValidation={submitValidation.address}
            notices={[showHints && 'onboarding.fundingSources.card.address.hint']}
          />
          <WizardFormRow>
            <CardFormInput
              value={city}
              label="onboarding.fundingSources.card.city.label"
              id="city"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.city.errorMessage"
              validationTest="string"
              required
              returnVal={this.returnVal}
              submitValidation={submitValidation.city}
            />
            <MISingleSelect
              id="state"
              value={state}
              label="onboarding.fundingSources.card.state.label"
              options={US_STATES}
              onChange={this.returnVal}
              placeholder=""
              errorMessage={
                submitValidation.state ? 'onboarding.fundingSources.card.state.errorMessage' : ''
              }
              required
              flavor={SingleSelectFlavorEnum.DEFAULT}
            />
          </WizardFormRow>
          <CardFormInput
            value={zipCode}
            label="onboarding.fundingSources.card.zipcode.label"
            required
            id="zipCode"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.zipcode.errorMessage"
            validationTest="string"
            wizardColumn={false}
            returnVal={this.returnVal}
            submitValidation={submitValidation.zipCode}
          />
        </div>
      </site.components.StepLayout>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectFundingSourceForPayBillFlow(id: number) {
    dispatch(selectFundingSourceAction(id));
  },
  refreshFundingSources() {
    return new Promise((resolve, reject) => {
      dispatch(loadFundingSourcesAction(resolve, reject));
    });
  },
  justPayUpdateFundingSourceId(id: number) {
    dispatch(paymentStore.actions.justPay.justPayWizard.update({ fundingSourceId: id }));
  },
  setCreatedCardAccount(payload: {
    accounts?: CardAccount[];
    code?: string;
    error?: { code: string; message: string };
  }) {
    dispatch(addCardAccountFundingSourceActions.setCreateCardAccountStatus(payload));
  },
});

export default compose(
  withPreservedStateNavigator(),
  withSiteContext(),
  connect(mapStateToProps, mapDispatchToProps),
  withFeatureFlag()
)(SetCardAccountsHolderPageContainer);
