import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  PAID_BILL_DASHBOARD_STATUSES,
  DASHBOARD_ITEM_ACTIONS,
} from 'src/app/pages/qb-dashboard/consts';
import useMarkAsUnpaidAction from 'src/app/pages/qb-dashboard/hooks/useMarkAsUnpaidAction';
import useViewPaymentAction from 'src/app/pages/qb-dashboard/hooks/useViewPaymentAction';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { useGetActionCellEventProperties } from 'src/app/pages/qb-dashboard/hooks/useGetActionCellEventProperties';
import QBOActionsCell from './QBOActionsCell';
import { PaymentType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const QBOPaidActionCell = ({ item }: Props) => {
  const { billId, paymentId, metadata, id } = item;
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(paymentId));
  const { eventProperties } = useGetActionCellEventProperties({
    billId,
    paymentId,
    status: metadata.statusInDashboard,
  });
  const [markAsUnpaid] = useMarkAsUnpaidAction({ billId, paymentId, id });
  const [goViewPayment] = useViewPaymentAction();

  const handleViewPaymentClick = useCallback(() => {
    goViewPayment({
      billId,
      paymentOriginId: payment?.originId,
      paymentId,
      paymentStatus: metadata?.statusInDashboard,
    });
  }, [billId, paymentId, payment, goViewPayment]);

  let actions = [
    {
      label: 'paymentDashboard.columns.actions.view',
      onClick: handleViewPaymentClick,
      id: DASHBOARD_ITEM_ACTIONS.VIEW,
    },
  ];

  if (metadata?.statusInDashboard === PAID_BILL_DASHBOARD_STATUSES.MARKED_AS_PAID) {
    actions = [
      {
        label: 'paymentDashboard.columns.actions.view',
        onClick: handleViewPaymentClick,
        id: DASHBOARD_ITEM_ACTIONS.VIEW,
      },
      {
        label: 'paymentDashboard.columns.actions.markAsUnpaid',
        onClick: markAsUnpaid,
        id: DASHBOARD_ITEM_ACTIONS.MARK_AS_UNPAID,
      },
    ];
  }

  return <QBOActionsCell actions={actions} eventProperties={eventProperties} />;
};

export default QBOPaidActionCell;
