import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CONSTS } from 'src/app/utils/consts';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { MIFormattedText } from 'src/app/utils/formatting';
import { OptionalDeliveryMethodsType } from 'src/app/utils/types';
import { FeeVariantsEnum } from 'src/app/version-2/model/enums/feeVariants.enum';
import { getCompleteFeeByDeliveryType } from 'src/app/version-2/utils/fee.utils';
import { getPaymentFeeValues } from 'src/app/version-2/utils/checkFees.utils';

import { PaymentRegularFeeCheck } from './PaymentRegularFeeCheck';

type PaymentRegularFeeTotalProps = {
  feeType?: string;
  textValues: Record<string, any>;
  feeText: React.ReactNode;
  showFeeHint: boolean;
  fundingSource?: FundingSource | FundingSource;
  deliveryMethodType?: OptionalDeliveryMethodsType | undefined;
  isEdit?: boolean | undefined;
};

const PaymentRegularFeeTotal = ({
  feeType,
  textValues,
  feeText,
  showFeeHint,
  fundingSource,
  deliveryMethodType,
  isEdit,
}: PaymentRegularFeeTotalProps) => {
  const catalog = useSelector((state) => state.organization.feeCatalog);
  const catalogFee = getCompleteFeeByDeliveryType({
    fees: catalog,
    deliveryType: FeeVariantsEnum.ACH_TO_CHECK,
  });
  const isCheckDM = deliveryMethodType === CONSTS.DELIVERY_TYPE.CHECK;

  if (isCheckDM) {
    const { hasAchCheckFee, hasCheckZeroFeeUI, hasPromotions } = getPaymentFeeValues({
      fundingSource: fundingSource as FundingSource,
      fee: textValues?.finalAmount,
      feeType,
      catalogFee,
    });

    if (hasAchCheckFee || hasCheckZeroFeeUI) {
      return (
        <PaymentRegularFeeCheck
          textValues={textValues}
          hasPromotions={hasPromotions}
          isEdit={isEdit}
        />
      );
    }
  }

  return (
    <TotalFee data-testid="total-fee">
      {feeText}
      {showFeeHint && (
        <FeeHint>
          <MIFormattedText label="bills.pay.confirm.feeHint" values={textValues} />
        </FeeHint>
      )}
    </TotalFee>
  );
};

export { PaymentRegularFeeTotal };

const TotalFee = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.text.size.subTitle};
  height: 3rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.TotalFee}
`;

const FeeHint = styled.span`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.label};
  align-self: center;
  margin-left: 1rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeHint}
`;
