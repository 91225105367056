import { DASHBOARD_ITEM_ACTIONS } from 'src/app/pages/qb-dashboard/consts';
import { featureFlags } from '@melio/shared-web';
import useViewPaymentAction from 'src/app/pages/qb-dashboard/hooks/useViewPaymentAction';
import { PaymentType } from 'src/app/utils/types';
import { isReturnedCheckPayment } from 'src/app/utils/payments';

type Params = {
  billId: string;
  paymentOriginId?: string;
  paymentId?: string;
  payment: PaymentType;
};

export const useReturnedCheckInDashboard = (params: Params) => {
  const { billId, paymentOriginId, paymentId, payment } = params;
  const [goViewPayment] = useViewPaymentAction();
  const [isReturnedCheckFeatureActive] = featureFlags.useFeature('bp-returned-check');
  const isReturnedCheck = isReturnedCheckPayment(payment);

  if (!isReturnedCheckFeatureActive || !isReturnedCheck) return null;

  return {
    label: 'paymentDashboard.columns.actions.retryPayment',
    onClick: () => {
      goViewPayment({
        billId,
        paymentOriginId,
        paymentId,
      });
    },
    id: DASHBOARD_ITEM_ACTIONS.RETRY,
  };
};
