import { useSelector } from 'react-redux';
import billsStore from 'src/app/modules/bills/bills-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { isPartialAmountInvalid } from 'src/app/pages/qb-dashboard/utils';
import { BillType } from 'src/app/utils/types';

type Params = {
  selectedBillsIds: string[];
  isBatch?: boolean;
};

type Response = {
  hasBillWithInvalidAmount: boolean;
  totalAmount: number;
  partialPaymentsCount: number;
};

export const useSelectedBillsMetadata = ({ selectedBillsIds, isBatch }: Params): Response => {
  const partialPaymentsBillAmountsDict: Record<string, number> = useSelector(
    qbDashboardListItemsStore.selectors.partialPaymentsAmounts.all
  );
  const bills: BillType[] = useSelector(billsStore.selectors.getByIds(selectedBillsIds));

  const hasBillWithInvalidAmount = bills.some((bill) => {
    const partialPaymentsAmount = partialPaymentsBillAmountsDict[bill.id];
    const amountToPay = (!isBatch && partialPaymentsAmount) || bill.balance;

    return isPartialAmountInvalid({
      balance: bill.balance as number,
      amount: amountToPay as number,
    });
  });
  const { totalAmount, partialPaymentsCount } = bills.reduce(
    (acc, bill) => {
      const partialPaymentsAmount = partialPaymentsBillAmountsDict[bill.id];
      const amountToPay = (!isBatch && partialPaymentsAmount) || bill.balance;

      const totalAmount = acc.totalAmount + (amountToPay as number);
      const partialPaymentsCount =
        (amountToPay || 0) < (bill.balance || 0)
          ? acc.partialPaymentsCount + 1
          : acc.partialPaymentsCount;

      return {
        totalAmount,
        partialPaymentsCount,
      };
    },
    { totalAmount: 0, partialPaymentsCount: 0 }
  );

  return {
    hasBillWithInvalidAmount,
    totalAmount,
    partialPaymentsCount,
  };
};
