import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import { GlobalState } from 'src/app/redux/types';
import { getOrgId } from 'src/app/redux/user/selectors';
import { COMPANY_FORM_PAGE, LINK_ACCOUNTING_SOFTWARE_ERROR } from 'src/app/utils/consts';
import { withNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import { BillType, NavigateType } from 'src/app/utils/types';
import { Option } from 'src/app/components/common/MISingleSelect';
import { parseQueryString } from 'src/app/utils/query-utils';
import NewBillCreateOptionsPage from './components/NewBillCreateOptionsPage';
import withNewBillData from './hoc/withNewBillData';

type MapStateToProps = {
  orgId: string;
};

type Props = {
  goWithUploadedBill: (file: Record<string, unknown>, orgId: string) => void;
  goExit: () => void;
  handleUploadStart: () => void;
  handleUploadCancel: () => void;
  isUploading: boolean;
  fileStorageUrl: string;
  filePreviewUrls: string[];
  bill: RecordOf<BillType>;
  files: number[];
  vendors: Option[];
  validationErrors: Record<string, string>;
  navigate: NavigateType;
  locationState: Record<string, any>;
  isUploadError: boolean;
  handleUploadError: () => void;
} & MapStateToProps;

const eventPage = 'bill-create-options';

class NewBillCreateOptionsPageContainer extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const {
      validationErrors,
      bill,
      fileStorageUrl,
      filePreviewUrls,
      files,
      vendors,
      orgId,
      navigate,
    } = this.props;
    const data = {
      bill,
      fileStorageUrl,
      filePreviewUrls,
      validationErrors,
      files,
      vendors,
    };
    const query = parseQueryString(window.location.search);
    const { error } = query;

    if (error === LINK_ACCOUNTING_SOFTWARE_ERROR.DIFF_REALMID) {
      navigate(locations.Bills.create.options.url({ orgId }), false);
    }

    if (fileStorageUrl && bill !== prevProps.bill) {
      navigate(locations.Bills.create.index.url(), false, data);
    }
  }

  goManual = () => {
    const { navigate } = this.props;

    analytics.track(eventPage, 'auto-reader-error-enter-manually');
    navigate(locations.Bills.create.index.url(), false, {
      manually: true,
    });
  };

  handleManualClick = () => {
    const { navigate, locationState } = this.props;

    analytics.track(eventPage, 'enter-manually');
    const { id } = locationState || {};

    if (id) {
      navigate(locations.Bills.create.fromVendor.url({ id }), false, {
        manually: true,
      });
    } else {
      navigate(locations.Bills.create.index.url(), false, {
        manually: true,
      });
    }
  };

  navigateToBills = (eventName: string) => {
    const { navigate, locationState } = this.props;

    analytics.track(eventPage, eventName);
    navigate(locations.Bills.index.url(), false, {
      ...locationState,
      errorNotification: undefined,
    });
  };

  handleSkipCreateBillClick = () => {
    this.navigateToBills('dont-have-a-bill');
  };

  handleCancelCreateBillClick = () => {
    this.navigateToBills('cancel-create-bill');
  };

  render() {
    const {
      goExit,
      isUploading,
      goWithUploadedBill,
      handleUploadStart,
      handleUploadCancel,
      locationState,
      isUploadError,
      handleUploadError,
    } = this.props;
    const isOnboarding = locationState && locationState.origin === COMPANY_FORM_PAGE.ONBOARDING;

    return (
      <NewBillCreateOptionsPage
        goExit={goExit}
        goManual={this.goManual}
        handleUploadStart={handleUploadStart}
        handleUploadCancel={handleUploadCancel}
        handleSkipCreateBillClick={this.handleSkipCreateBillClick}
        handleCancelCreateBillClick={this.handleCancelCreateBillClick}
        handleManualClick={this.handleManualClick}
        isUploading={isUploading}
        goWithUploadedBill={goWithUploadedBill}
        isOnboarding={isOnboarding}
        isUploadError={isUploadError}
        handleUploadError={handleUploadError}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

export default compose(
  connect(mapStateToProps),
  withNavigator(),
  withNewBillData()
)(NewBillCreateOptionsPageContainer);
