import * as React from 'react';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import { MiMoneyFlavorEnum } from 'src/app/version-2/model/enums';

type SizeType = 'huge' | 'extraLarge' | 'large' | 'normal' | 'small' | 'extraSmall';

type Props = {
  amount: number | string | null;
  currency?: string;
  size?: SizeType;
  flavor?: MiMoneyFlavorEnum;
  className?: string;
  search?: string;
  showAmountArrowIcon?: boolean;
};

const MIMoney = ({
  currency = '$',
  amount,
  size = 'normal',
  flavor = MiMoneyFlavorEnum.DEFAULT,
  className,
  search,
  showAmountArrowIcon,
}: Props) => {
  const formattedAmount = Number(amount || 0).toFixed(2);
  const fraction = formattedAmount.split('.')[1];
  let roundAmountSearch = search;
  let fractionAmountSearch = search;

  if (search) {
    const searchParts = search.split('.');

    if (
      searchParts.length === 2 &&
      searchParts.every((searchPart) => !Number.isNaN(searchPart as any))
    ) {
      [roundAmountSearch, fractionAmountSearch] = searchParts;
    }
  }

  return (
    <AmountContainer className={FULL_STORY_MASK_RULE_CLASS}>
      {showAmountArrowIcon && (
        <AmountArrowIcon flavor={flavor} className="icon-large-dropdown-arrow" />
      )}
      <Balance className={className} size={size}>
        <IntegerBalance size={size} flavor={flavor}>
          <MIFormattedCurrency
            value={(amount || 0).toString()}
            currency={currency}
            search={roundAmountSearch}
            round
          />
        </IntegerBalance>
        <FractionBalance size={size} flavor={flavor}>
          <Dot>.</Dot>
          <Highlighter searchWords={[fractionAmountSearch]} autoEscape textToHighlight={fraction} />
        </FractionBalance>
      </Balance>
    </AmountContainer>
  );
};

export default MIMoney;

const Balance = styled.div<{ size?: SizeType }>`
  font-weight: ${(props) =>
    props.size === 'huge' || props.size === 'extraLarge'
      ? props.theme.text.weight.regular
      : props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.MIMoney?.Balance}
`;

export const IntegerBalance = styled.span<{ size?: SizeType; flavor?: MiMoneyFlavorEnum }>`
  color: ${(props) =>
    props.flavor === 'positive' ? props.theme.text.color.green : props.theme.text.color.main};
  display: table-cell;
  vertical-align: top;
  line-height: 1;
  font-size: ${(props) => {
    if (props.size === 'huge') {
      return '9rem';
    }

    if (props.size === 'extraLarge') {
      return '6.4rem';
    }

    if (props.size === 'large') {
      return '3.4rem';
    }

    if (props.size === 'normal') {
      return '2.3rem';
    }

    if (props.size === 'extraSmall') {
      return '1.2rem';
    }

    return '1.6rem';
  }};

  .negative-marker {
    color: ${(props) => props.theme.text.color.error};
  }
  ${(props) => props.theme?.components?.MIMoney?.IntegerBalance}
`;

const Dot = styled.span`
  display: none;
  ${(props) => props.theme?.components?.MIMoney?.Dot}
`;

export const FractionBalance = styled.span<{
  size?: SizeType;
  flavor?: MiMoneyFlavorEnum;
}>`
  color: ${(props) =>
    props.flavor === 'positive' ? props.theme.text.color.green : props.theme.text.color.main};
  font-weight: ${(props) =>
    props.size === 'small' || props.size === 'extraSmall'
      ? props.theme.text.weight.regular
      : props.theme.text.weight.semiBold};
  display: table-cell;
  vertical-align: top;
  font-size: ${(props) => {
    if (props.size === 'huge') {
      return '5rem';
    }

    if (props.size === 'extraLarge') {
      return '3rem';
    }

    if (props.size === 'large') {
      return '1.6rem';
    }

    if (props.size === 'normal') {
      return '1.2rem';
    }

    if (props.size === 'extraSmall') {
      // For some reason `rem` size is ignored
      return '7px';
    }

    return '1rem';
  }};

  padding-left: ${(props) => {
    if (props.size === 'huge') {
      return '1rem';
    }

    if (props.size === 'extraLarge') {
      return '0.7rem';
    }

    if (props.size === 'large') {
      return '0.6rem';
    }

    if (props.size === 'normal') {
      return '0.2rem';
    }

    if (props.size === 'extraSmall') {
      return '0.1rem';
    }

    return '0.15rem';
  }};
  padding-top: ${(props) => {
    if (props.size === 'large') {
      return '0.2rem';
    }

    return '0';
  }};
  ${(props) => props.theme?.components?.MIMoney?.FractionBalance}
`;

const AmountArrowIcon = styled.i<{ flavor?: MiMoneyFlavorEnum }>`
  font-size: 2.5rem;
  color: ${(props) =>
    props.flavor === 'positive' ? props.theme.text.color.green : props.theme.text.color.main};
  transform: ${(props) => (props.flavor === 'positive' ? 'rotate(180deg)' : 'rotate(0deg)')};
  ${(props) => props.theme?.components?.MIMoney?.AmountArrowIcon}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme?.components?.MIMoney?.AmountContainer}
`;
