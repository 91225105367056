import { deliveryMethodsApi } from 'src/app/version-2/api/deliveryMethods.api';
import { vendorApi } from 'src/app/version-2/api/vendor.api';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { achDeliveryMethodDetailsActions } from '../achDeliveryMethodDetails.slice';

import { all, call, put, select } from 'redux-saga/effects';

export function* loadAchDeliveryMethodDetailsPageDataHandler(
  action: ReturnType<typeof achDeliveryMethodDetailsActions.loadAchDeliveryMethodDetailsPageData>
) {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const { vendorId, deliveryMethodId } = action.payload;

    yield put(achDeliveryMethodDetailsActions.setIsLoading(true));

    const [vendorResponse, deliveryMethodResponse] = yield all([
      call(vendorApi.fetchById, { orgId, id: vendorId }),
      deliveryMethodId
        ? call(deliveryMethodsApi.fetchById, {
            orgId,
            vendorId,
            id: deliveryMethodId,
          })
        : undefined,
    ]);

    if (deliveryMethodId) {
      const { deliveryMethod } = deliveryMethodResponse;

      yield put(achDeliveryMethodDetailsActions.setBankAccount(deliveryMethod.bankAccount));
      yield put(achDeliveryMethodDetailsActions.setShowConfirmAccountNumberField(false));
    }

    const vendor = vendorResponse.object;

    yield put(achDeliveryMethodDetailsActions.setVendorName(vendor.companyName));
    yield put(achDeliveryMethodDetailsActions.setIsLoading(false));
  } catch (error: any) {
    yield put(
      achDeliveryMethodDetailsActions.setError({
        error: {
          code: error?.code,
        },
      })
    );
    yield put(achDeliveryMethodDetailsActions.setIsLoading(false));
  }
}
