import React, { useEffect } from 'react';
import { isMobileInLoader } from 'src/app/utils/screen';
import { Switch, Route, Redirect, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getOrgId } from 'src/app/redux/user/selectors';
import { isValidQBCashBalance } from 'src/app/utils/payments';
import { QBCashStateType } from 'src/app/utils/types';
import onboardingRoutes from 'src/app/pages/onboarding/route';
import QuickpayRouter from 'src/app/pages/quickpay/QuickpayRouter';
import QBDashboardRouter from 'src/app/pages/qb-dashboard/QBDashboardRouter';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import MainNavigator from 'src/app/utils/locations';
import { JustPayRouter } from 'src/app/pages/just-pay/JustPayRouter';
import locations from '../locations';
import { DashboardRouter } from '../dashboard';
import { BillWizardRouter } from '../bill/BillWizardRouter';
import NotFoundPage from '../general/components/QBONotFoundPage';
import { PaymentsNotAvailableMessage } from '../bill/pay/PaymentsNotAvailableMessage';
import { InternationalEPRouter } from '../qb-dashboard/components/InternationalEntryPoint/router';
import { RequestRefundWrapper } from '../qb-dashboard/RefundFlow/RequestRefundWrapper';
import RequestRefundSuccess from '../qb-dashboard/RefundFlow/RequestRefundSuccess';

type Props = {
  orgsRoutes: any;
};

export const OrgsRouter = ({ orgsRoutes }: Props) => {
  const orgId = useSelector(getOrgId);
  const isMobile = isMobileInLoader();
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const fundingSourceActions = useStoreActions(fundingSourcesStore);

  useEffect(() => {
    if (!isValidQBCashBalance(qbCashState)) {
      fundingSourceActions
        .getQBCashBalance({ orgId }, { disableNotification: true })
        .catch((e) => {});
    }
  }, [fundingSourceActions, orgId]);

  return (
    <Switch>
      {onboardingRoutes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      <SmartRoute exact allowFor={['authenticated']} path={locations.orgs.index}>
        <Redirect to={orgId ? generatePath(locations.dashboard.base[0], { orgId }) : 'null'} />
      </SmartRoute>
      <SmartRoute exact allowFor="authenticated" path={locations.dashboard.base}>
        <DashboardRouter />
      </SmartRoute>
      <SmartRoute
        path={MainNavigator.MainApp.dashboardInternationalBill.url()}
        allowFor="authenticated"
      >
        <InternationalEPRouter />
      </SmartRoute>
      <SmartRoute allowFor="authenticated" path={locations.bill.base}>
        <BillWizardRouter />
      </SmartRoute>
      <SmartRoute path={locations.quickpay.base} allowFor="authenticated">
        <QuickpayRouter />
      </SmartRoute>
      <SmartRoute path={locations.bill.successScheduledRefund} exact>
        <RequestRefundSuccess />
      </SmartRoute>
      <SmartRoute path={locations.bill.refund}>
        <Switch>
          <RequestRefundWrapper />
        </Switch>
      </SmartRoute>
      {orgsRoutes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      <SmartRoute
        path={locations.qbDashboard.base}
        allowFor="authenticated"
        notAllowedComponent={<PaymentsNotAvailableMessage />}
        allowCallbackFn={() => !isMobile}
      >
        <QBDashboardRouter />
      </SmartRoute>
      <SmartRoute path={locations.justPay.create.entry} allowFor="authenticated">
        <JustPayRouter />
      </SmartRoute>

      <SmartRoute allowFor="authenticated" component={NotFoundPage} />
    </Switch>
  );
};
