import React from 'react';
import { Image } from '@melio/billpay-design-system';

import { getCardLogo, qbCashOriginIcon } from 'src/app/utils/card';
import { FUNDING_DEFAULT_LOGO } from 'src/app/version-2/model/constants';
import {
  FundingSourceTypesEnum,
  FundingSourceOrigins,
  CardNetworkEnum,
  CardTypeEnum,
} from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

type FundingSourceIconProps = {
  fundingSource: FundingSource;
};

const FundingSourceIcon = ({ fundingSource }: FundingSourceIconProps) => {
  const fallbackBankIcon = <div className="icon-bank-icon" />;
  const bankIcon =
    fundingSource.fundingType === FundingSourceTypesEnum.ACH &&
    fundingSource.logo &&
    fundingSource.logo !== FUNDING_DEFAULT_LOGO ? (
      <Image
        src={`data:image/jpeg;base64,${fundingSource.logo}`}
        alt="funding source icon"
        fallback={fallbackBankIcon}
      />
    ) : (
      fallbackBankIcon
    );
  const cardType = fundingSource.cardAccount?.cardType || '';
  const network = (fundingSource.cardAccount?.network || '').toUpperCase();
  let icon = bankIcon;

  if (
    fundingSource.fundingType === FundingSourceTypesEnum.ACH &&
    fundingSource.origin === FundingSourceOrigins.QBCASH
  ) {
    icon = qbCashOriginIcon;
  }

  if (fundingSource && cardType === CardTypeEnum.CREDIT) {
    icon = getCardLogo(cardType, network as CardNetworkEnum);
  }

  if (fundingSource && cardType === CardTypeEnum.DEBIT) {
    icon = getCardLogo(cardType, network as CardNetworkEnum);
  }

  return icon;
};

export default FundingSourceIcon;
