import React from 'react';
import { useSelector } from 'react-redux';
import { useHasFeatureFlag } from 'src/app/hooks/useHasFeatureFlag';
import styled from 'styled-components';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { SupportLine } from 'src/app/components/common/OutsideLayout/SupportLine';
import { MIFormattedText } from 'src/app/utils/formatting';
import successImage from 'src/app/images/general/qbo-check-circle.svg';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import { devices } from 'src/app/theme/AppDevices';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import {
  QBOBusinessDirectoryUpsell,
  BUSINESS_DIRECTORY_UPSELL_ORIGIN,
} from 'src/app/pages/vendor/components/QBOBusinessDirectoryUpsell';
import QBOJoinService from 'src/app/pages/vendor/components/QBOJoinService';
import { DeliveryMethodType } from 'src/app/utils/types';

const AddDeliveryMethodSuccessPage = () => {
  const { isFeatureFlagEnabled } = useHasFeatureFlag();
  const vendorFlowsViralityTestEnabled = isFeatureFlagEnabled('vendorFlowsViralityTest');

  const { deliveryMethodId } = useSelector(deliveryMethodsStore.selectors.validation) as {
    deliveryMethodId: any;
  };
  const deliveryMethod: DeliveryMethodType = useSelector(
    deliveryMethodsStore.selectors.byId(deliveryMethodId)
  );
  const digits = getAccountNumber4digits(deliveryMethod?.bankAccount);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <Content>
        <SuccessImage
          src={successImage}
          large={vendorFlowsViralityTestEnabled}
          centered={vendorFlowsViralityTestEnabled}
        />

        <Title centered={vendorFlowsViralityTestEnabled}>
          <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.title" />
        </Title>

        <SubTitle centered={vendorFlowsViralityTestEnabled}>
          <MIFormattedText
            label="vendors.deliveryMethods.ach.changeSuccess.subTitle"
            values={{ digits }}
          />
        </SubTitle>

        <Separator isViralityTestEnabled={vendorFlowsViralityTestEnabled} />

        {vendorFlowsViralityTestEnabled ? (
          <QBOBusinessDirectoryUpsell flow={BUSINESS_DIRECTORY_UPSELL_ORIGIN.ACH} />
        ) : (
          <QBOJoinService
            labelPrefix="vendors.deliveryMethods.ach.changeSuccess"
            utmCampaign="shift_vendor_to_ach"
          />
        )}
      </Content>
      <SupportLine __css={supportLineStyle} />
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 4rem;
  border-radius: 0.6rem;
  border: 1px solid ${(props) => props.theme.colors.shadow.lightGrey};
  height: auto;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const SuccessImage = styled.img<{ centered?: boolean; large?: boolean }>`
  margin-bottom: 2rem;
  width: ${({ large }) => (large ? '5rem' : '4.8rem')};
  height: ${({ large }) => (large ? '5rem' : '4.8rem')};
  align-self: ${({ centered }) => (centered ? 'center' : 'auto')};
`;

const Separator = styled.div<{ isViralityTestEnabled?: boolean }>`
  height: 0.1rem;
  background-color: ${({ theme, isViralityTestEnabled }) =>
    isViralityTestEnabled ? theme.text.color.grey : theme.colors.border.darkGrey};
  margin: ${({ isViralityTestEnabled }) => (isViralityTestEnabled ? '3.2rem 1rem' : '4rem 0')};
  @media ${devices.mobile}, ${devices.phablet} {
    margin: ${({ isViralityTestEnabled }) => (isViralityTestEnabled ? '3.2rem 0' : '4rem 0')};
  }
`;

const Title = styled.div<{ centered?: boolean }>`
  font-size: ${(props) => props.theme.text.size.subNav};
  font-weight: ${(props) => props.theme.text.weight.normal};
  line-height: 3.2rem;
  margin-bottom: 1rem;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

const SubTitle = styled.div<{ centered?: boolean }>`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  color: ${(props) => props.theme.text.color.main};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

const supportLineStyle = {
  m: ['2rem 0 5rem', '2rem 0'],
};

export default AddDeliveryMethodSuccessPage;
