import * as React from 'react';
import analytics from 'src/app/services/analytics';
import { BUTTON_SIZE } from 'src/app/utils/consts';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  label: string;
  icon?: string;
  svgIcon?: React.ReactNode;
  variant?: 'iconAndText' | 'inlineIconAndText' | 'svgIconAndText' | 'inlineIconAndTextPrimary';
  size?: 'normal' | 'padded';
  fullWidth?: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void | string | Promise<void>;
  testId?: string;
  id?: string;
};

const MIIconButton = ({
  label,
  variant,
  size,
  onClick,
  icon,
  svgIcon,
  fullWidth,
  testId,
  id,
}: Props) => {
  const onClickWithEvent = (event) => {
    analytics.trackAction(label);

    return onClick && onClick(event);
  };

  switch (variant) {
    case 'iconAndText':
      return (
        <ButtonWithIconAndText
          fullWidth={fullWidth}
          onClick={onClickWithEvent}
          data-testid={testId}
        >
          <ImageIcon src={icon} />
          <MICallToAction>
            <MIFormattedText label={label} />
          </MICallToAction>
        </ButtonWithIconAndText>
      );
    case 'inlineIconAndText':
      return (
        <InlineButtonWithIconAndText
          fullWidth={fullWidth}
          onClick={onClickWithEvent}
          padded={size === 'padded'}
          size={size}
          data-testid={testId}
          id={id}
        >
          <SmallIcon className={icon} />
          <MICallToActionUppercase>
            <MIFormattedText label={label} />
          </MICallToActionUppercase>
        </InlineButtonWithIconAndText>
      );
    case 'inlineIconAndTextPrimary':
      return (
        <InlinePrimaryButtonWithIconAndText
          fullWidth={fullWidth}
          onClick={onClickWithEvent}
          padded={size === 'padded'}
          size={size}
          data-testid={testId}
        >
          <SmallPrimaryIcon className={icon} />
          <MIPrimaryCallToAction>
            <MIFormattedText label={label} />
          </MIPrimaryCallToAction>
        </InlinePrimaryButtonWithIconAndText>
      );
    case 'svgIconAndText':
      return (
        <SvgIcanoButtonWithText fullWidth={fullWidth} onClick={onClickWithEvent}>
          {svgIcon}
          <LabelWrapper>
            <MIFormattedText label={label} />
          </LabelWrapper>
        </SvgIcanoButtonWithText>
      );
    default:
      return <></>;
  }
};

MIIconButton.defaultProps = {
  variant: 'iconAndText',
  svgIcon: null,
  size: 'normal',
  fullWidth: false,
  testId: null,
};

export default MIIconButton;

const Button = styled.div<{ fullWidth?: boolean }>`
  border-radius: 0.8rem;
  border: solid 0.2rem transparent;
  cursor: pointer;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.0007rem;
  color: ${(props) => props.theme.text.color.main};
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
  padding: 0 2.4rem;
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: 0 0 1rem 0 ${(props) => props.theme.colors.dark.translucent2};
  box-sizing: border-box;
  min-width: 16rem;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  &:hover {
    border-color: ${(props) => props.theme.colors.primary.opaque};
    box-shadow: none;
  }

  &:active {
    border-color: ${(props) => props.theme.colors.primary.opaque};
    box-shadow: none;
  }
`;

const MICallToAction = styled.div`
  display: block;
`;

const MICallToActionUppercase = styled(MICallToAction)`
  text-transform: none;
`;

const MIPrimaryCallToAction = styled(MICallToActionUppercase)`
  margin-top: 0.2rem;
`;

const ImageIcon = styled.img`
  height: 4.4rem;
  width: 4.4rem;
  border: none;
  margin-top: 0rem;
  margin-bottom: 0.9rem;
`;

const SmallIcon = styled.i`
  font-size: 1.6rem;
  user-select: none;
  margin: 0 1.1rem 0 0;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.colors.primary.opaque};
`;

const SmallPrimaryIcon = styled(SmallIcon)`
  color: ${(props) => props.theme.colors.white.opaque};
`;

const SvgIcanoButtonWithText = styled(Button)`
  height: 9rem;
  width: 100%;
  justify-content: flex-start;
`;

const LabelWrapper = styled.div`
  font-size: 1.7rem;
  margin-left: 2rem;
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const ButtonWithIconAndText = styled(Button)`
  flex-direction: column;
  height: 12.7rem;
`;

const InlineButtonWithIconAndText = styled(Button)<{
  padded?: boolean;
  size?: string;
}>`
  flex-direction: row;
  padding: ${(props) => (props.padded ? 2.2 : 0)}rem;
  height: ${(props) => (props.size === 'normal' ? '5rem' : '100%')};
  min-height: 5rem;
  font-size: ${(props) =>
    props.size === BUTTON_SIZE.TINY ? '1.2rem' : props.theme.text.size.button};
  text-transform: capitalize;
`;

const InlinePrimaryButtonWithIconAndText = styled(InlineButtonWithIconAndText)`
  background-color: ${(props) => props.theme.text.color.highlight};
  color: ${(props) => props.theme.colors.white.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;
