import { fetchRequest, postRequest, putRequest, deleteRequest } from './api';

export default {
  getDeliveryMethods(orgId, vendorId) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods`;

    return fetchRequest(url);
  },

  getDeliveryMethodById({ orgId, vendorId, id }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;

    return fetchRequest(url);
  },

  getDeliveryMethodStatus({ orgId, vendorId, id }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}/status`;

    return fetchRequest(url);
  },

  editDeliveryMethodById(orgId, vendorId, id, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;

    return putRequest(url, params);
  },

  addDeliveryMethod({ orgId, vendorId, params }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods`;

    return postRequest(url, params);
  },

  replaceDeliveryMethodWithToken(params) {
    const url = '/delivery-method/create-delivery-method';

    return postRequest(url, params);
  },

  getDeliveryMethodACHWithToken(params) {
    const url = '/delivery-method/delivery-method-ach';

    return fetchRequest(url, params);
  },

  updateDeliveryMethodWithToken(params) {
    const url = '/delivery-method/delivery-method-ach';

    return putRequest(url, params);
  },

  addPlaidAccount(orgId, vendorId, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/plaid/add-accounts`;

    return postRequest(url, params);
  },

  deleteDeliveryMethodById(orgId, vendorId, id, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;

    return deleteRequest(url, params);
  },

  copyFromOwnedVendor({ orgId, vendorId, token }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/copy-from-owned-vendor`;

    return postRequest(url, { token });
  },
  /**
   * @deprecated
   */
  checkCardWithToken(params) {
    const url = '/delivery-method/push-to-debit/check-card';

    return postRequest(url, params);
  },
  /**
   * @deprecated
   */
  addCardAccountWithToken(params) {
    const url = '/delivery-method/add-card-account';

    return postRequest(url, params);
  },
  getSupportedCountries(orgId, vendorId) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international/countriesList?origin=billpay`;

    return fetchRequest(url);
  },
  getRequiredFields(orgId, vendorId, countryCode, currency = 'usd') {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international/required-fields/${countryCode.toLowerCase()}/${currency}`;

    return fetchRequest(url);
  },
  getBankDetails(orgId, vendorId, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international/bank-details`;

    return postRequest(url, params);
  },
  createInternationalDelivery(orgId, vendorId, data) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international`;

    return postRequest(url, data);
  },
  editInternationalDelivery(id, orgId, vendorId, data) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international/${id}`;

    return putRequest(url, data);
  },
  shiftVirtualCardToAch(params) {
    const url = '/delivery-method/shift-virtual-card-to-ach-payment-status-collected';

    return postRequest(url, params);
  },
};
