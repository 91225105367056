import React, { useMemo, useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { isValidationOk } from '@melio/sizzers-js-common';

import {
  selectNewDeliveryMethodAction,
  setPurposeOfPaymentAction,
} from 'src/app/redux/payBillWizard/actions';
import billApi from 'apps/web/src/app/services/api/bills';
import { CONSTS, PURPOSE_OF_PAYMENT_STRUCTURE } from 'src/app/utils/consts';
import { useForm, InlineTextInputField } from 'src/app/ui/form';
import MIButton from 'src/app/components/common/MIButton';
import { devices } from 'src/app/theme/AppDevices';
import MISingleSelect from 'src/app/components/common/MISingleSelect';
import analytics from 'src/app/services/analytics';
import { transformPurposeOfPayment, getPurposeByType } from 'src/app/utils/payments';
import { getPayment, getBill, getInvoiceFileBillId } from 'src/app/redux/payBillWizard/selectors';
import { batchBulkSelectors } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { validateMultiFields } from 'src/app/utils/form-utils';
import {
  collectInvoiceActions,
  collectInvoiceSelectors,
} from 'src/app/version-2/modules/collectInvoice/collectInvoice.slice';

import { isPayBillFlow, ERROR_PREFIX } from '../../utils';
import InternationalContext from '../../Context';
import { ButtonContainer } from 'src/app/pages/vendor/delivery-methods/international/components/ButtonContainer';
import { StepProps } from '../../types';

import { options } from './utils';
import InvoiceUpload from './InvoiceUpload';
import { getOrgId } from 'src/app/redux/user/selectors';

const fileValidationErrorResource = 'international.purposeOfPayment.invoiceUpload.required';

const Form = ({ onNext }: StepProps) => {
  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const invoiceFileFromSingleFlow = useSelector(getInvoiceFileBillId(bill?.id));
  const invoiceFileFromBulk = useSelector(
    batchBulkSelectors.selectInvoiceByBillId(`bill-${bill?.id}`)
  );
  const invoiceFile = invoiceFileFromSingleFlow || invoiceFileFromBulk?.invoiceFile;
  const vendorId = String(bill?.vendor?.id);
  const [state] = useContext(InternationalContext);
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});
  const payment = useSelector(getPayment);
  const showInvoiceFileSelector = useSelector(
    collectInvoiceSelectors.selectShowInvoicetByVendorId(vendorId)
  );
  const [fileValidationError, setFileValidationError] = useState('');

  const model = useMemo(
    () => ({
      purpose: getPurposeByType(payment?.purpose, PURPOSE_OF_PAYMENT_STRUCTURE.TYPE),
      description: getPurposeByType(payment?.purpose, PURPOSE_OF_PAYMENT_STRUCTURE.DESCRIPTION),
    }),
    []
  );

  useEffect(() => {
    // saving new delivery method only when creating from flow
    // TODO: remove deliveryType
    if (isPayBillFlow(state.origin)) {
      dispatch(
        selectNewDeliveryMethodAction({
          ...state.deliveryMethod,
          deliveryType: 'international',
        } as any)
      );
    }

    dispatch(
      collectInvoiceActions.fetchCollectInvoice({
        vendorId,
        countryCode: state?.country?.code || '',
        countries: state?.countries,
        billId: bill?.id,
      })
    );
  }, []);

  useEffect(() => {
    if (invoiceFile) {
      setFileValidationError('');
    }
  }, [invoiceFile]);

  const onSubmit = async (values) => {
    setFileValidationError('');
    const errors = validateMultiFields(
      [
        { name: 'purpose', value: values.purpose, isRequired: true },
        {
          name: 'description',
          value: values.description,
          customCheck: () => {
            if (values.purpose === 'other' && !values.description)
              return `inputErrors.international.description.empty`;

            return false;
          },
        },
      ],
      ERROR_PREFIX
    );

    analytics.track('pay-bill', 'international-purpose-continue', {
      errors,
    });

    setValidationErrors(errors);

    const isInvoiceFileMissed = showInvoiceFileSelector && !invoiceFile;

    if (isInvoiceFileMissed) {
      setFileValidationError(fileValidationErrorResource);
    }

    if (!isValidationOk(errors) || isInvoiceFileMissed) {
      return;
    }

    const purpose = transformPurposeOfPayment({
      type: values.purpose,
      description: values.description,
    });

    dispatch(setPurposeOfPaymentAction(purpose));

    if (invoiceFile) {
      billApi
        .editBillById(
          orgId,
          bill.id,
          {
            files: [invoiceFile.id],
          },
          'all'
        )
        .then(() => onNext?.());
    } else {
      setTimeout(() => onNext?.());
    }
  };
  const onChange = (props) => {
    const { id, value } = props;

    values[id].onChange({ value });
  };

  const [values, { submit }] = useForm(model, { submit: onSubmit });

  return (
    <StyledForm onSubmit={submit}>
      <MISingleSelect
        id="purpose"
        value={values.purpose.value}
        label="international.purposeOfPayment.purpose.label"
        placeholder="international.purposeOfPayment.purpose.placeholder"
        errorMessage={validationErrors.purpose}
        onChange={onChange}
        options={options}
        required
        privateData
      />

      <InlineTextInputField
        id="description"
        value={values.description.value}
        label="international.purposeOfPayment.description.label"
        placeholder="international.purposeOfPayment.description.placeholder"
        errorMessage={validationErrors.description}
        onChange={onChange}
        required
        privateData
      />

      {showInvoiceFileSelector && !!bill && (
        <InvoiceUpload error={fileValidationError} billId={bill.id} />
      )}

      <ButtonContainer isFixed>
        <MIButton
          type="submit"
          className="button"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label="international.purposeOfPayment.button"
        />
      </ButtonContainer>
    </StyledForm>
  );
};

export default Form;

const StyledForm = styled.form`
  .button {
    display: flex;
    align-items: center;

    @media ${devices.tablet}, ${devices.desktop} {
      margin: 3rem auto 2.3rem;
    }
  }
`;
