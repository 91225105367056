import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import OutsideHeader from 'src/app/components/common/OutsideLayout/OutsideHeader';
import OutsideFooter from 'src/app/components/common/OutsideLayout/OutsideFooter';

type OutsideLayoutProps = {
  children: React.ReactNode;
  redirectUrl?: string;
  hideLogin?: boolean;
  analyticsProps?: Record<string, any>;
};

const OutsideLayout: React.FC<OutsideLayoutProps> = ({
  children,
  redirectUrl,
  hideLogin,
  analyticsProps,
}) => (
  <OutsideWrapper>
    <OutsideHeader
      redirectUrl={redirectUrl}
      hideLogin={hideLogin}
      analyticsProps={analyticsProps}
    />
    <OutsideCard>{children}</OutsideCard>
    <OutsideFooter />
  </OutsideWrapper>
);

export default OutsideLayout;

const OutsideWrapper = styled.div`
  height: 100%;

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: ${(props) => props.theme.background.wizard};
  }
`;

const OutsideCard = styled.div`
  background-color: ${(props) => props.theme.colors.white.opaque};
  border-radius: 0.6rem;
  width: 53rem;
  margin: -10rem auto auto auto;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

  @media ${devices.mobile}, ${devices.phablet} {
    width: auto;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
  }
`;
