import React from 'react';
import Box from 'src/app/core/ds/box';
import { ApprovalDetailsType } from 'src/app/pages/bill/pay/components/PayBillPaymentActivity/PaymentApprovalLogs/types';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import ApprovalLog from './ApprovalLog';

type Props = {
  approvalDecisionStatus: string;
  approvalDetails: ApprovalDetailsType;
};

const PaymentApprovalLogs = ({ approvalDecisionStatus, approvalDetails }: Props) => {
  const { scheduledBy, approvedBy, declinedBy } = approvalDetails;

  const showDeclinedActorsInfo =
    declinedBy.length > 0 && approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED;

  const showApprovedActorsInfo =
    approvedBy.length > 0 && approvalDecisionStatus !== PAYMENT_APPROVAL_STATUS.DECLINED;

  return (
    <Box>
      {showApprovedActorsInfo && (
        <ApprovalLog
          testId="PaymentApprovalLogs-Approved"
          actors={approvedBy}
          title="bills.form.paymentActivity.logs.approved.title"
          underline
        />
      )}
      {showDeclinedActorsInfo && (
        <ApprovalLog
          testId="PaymentApprovalLogs-Declined"
          actors={declinedBy}
          title="bills.form.paymentActivity.logs.declined.title"
          reasonLabel="bills.form.paymentActivity.logs.declined.reasonTitle"
          underline
        />
      )}
      {scheduledBy.user && (
        <ApprovalLog
          testId="PaymentApprovalLogs-Scheduled"
          actors={[scheduledBy]}
          title="bills.form.paymentActivity.logs.scheduled.title"
          underline
        />
      )}
    </Box>
  );
};

export default PaymentApprovalLogs;
