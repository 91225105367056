import { dateInCentral } from 'src/app/version-2/utils/dates.utils';
import { DataOriginEnum } from 'src/app/version-2/model/enums';
import { fetchRequest } from './api';

export default {
  getLatestProcessingDate(
    orgId: string,
    dueDate: Date,
    deliveryMethodId: string,
    fundingSourceId: number,
    amount: number,
    paymentId?: string,
    payBillFlowUUID?: string | null
  ) {
    const url = `/orgs/${orgId}/delivery/date/latest`;
    const params = {
      dueDate: dateInCentral(dueDate).toISOString(),
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentOrigin: DataOriginEnum.INTUIT,
      paymentId,
      payBillFlowUUID,
    };

    return fetchRequest(url, params).then((data) => {
      const { dates } = data;

      return {
        suggestedScheduledDate: dates[0].scheduledDate,
        actualDeliveryDays: dates[0].totalDays,
        deliveryDate: dates[0].deliveryDate,
        maxDeliveryDate: dates[0].maxDeliveryDate,
        deliveryOptions: dates,
      };
    });
  },

  getDeliveryTime(
    orgId: string,
    scheduledDate: Date,
    deliveryMethodId: number,
    fundingSourceId: number,
    amount: number,
    paymentId?: string,
    payBillFlowUUID?: string | null
  ) {
    const url = `/orgs/${orgId}/delivery/date`;
    const params = {
      scheduledDate: dateInCentral(scheduledDate).toISOString(),
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentOrigin: DataOriginEnum.INTUIT,
      paymentId,
      payBillFlowUUID,
    };

    return fetchRequest(url, params).then((data) => {
      const { dates } = data;

      return {
        suggestedScheduledDate: dates[0].scheduledDate,
        actualDeliveryDays: dates[0].totalDays,
        deliveryDate: dates[0].deliveryDate,
        maxDeliveryDate: dates[0].maxDeliveryDate,
        deliveryOptions: dates,
      };
    });
  },

  getApprovalDecisionStatus(orgId: string, amount: string) {
    const url = `/orgs/${orgId}/delivery/approval-decision-status`;
    const params = { amount };

    return fetchRequest(url, params);
  },

  getClosestDeliveryDates(
    orgId: string,
    deliveryMethodId: number,
    fundingSourceId: number,
    amount: number
  ) {
    const url = `/orgs/${orgId}/delivery/closest-delivery-dates`;
    const params = {
      deliveryMethodId,
      fundingSourceId,
      amount,
    };

    return fetchRequest(url, params);
  },

  getExpeditedAchDeliveryDates(
    orgId: string,
    deliveryMethodId: number,
    fundingSourceId: number,
    amount: number
  ) {
    const url = `/orgs/${orgId}/delivery/get-expedited-ach-delivery-dates`;
    const params = {
      deliveryMethodId,
      fundingSourceId,
      amount,
    };

    return fetchRequest(url, params);
  },

  getEffectiveScheduleDate({
    orgId,
    scheduledDate,
    fundingSourceId,
  }: {
    orgId: string;
    scheduledDate: number;
    fundingSourceId: number;
  }) {
    const url = `/orgs/${orgId}/delivery/effective-schedule-date`;
    const params = {
      scheduledDate,
      fundingSourceId,
    };

    return fetchRequest(url, params);
  },
};
