import React from 'react';
import { MIFormattedDate } from 'src/app/version-2/externals';

import { format, isBefore, isSameDay, isSameMonth, isSameYear } from 'date-fns';

export interface FormattedDeliveryDateProps {
  date?: Date;
  maxDate?: Date;
}

export const FormattedDeliveryDate = ({ date, maxDate }: FormattedDeliveryDateProps) => {
  if (!date) return null;

  if (!maxDate || !isBefore(date, maxDate) || isSameDay(date, maxDate)) {
    return <MIFormattedDate date={date} />;
  }

  let toDateValue;

  let fromDateValue = <MIFormattedDate date={date} />;

  if (isSameYear(date, maxDate)) {
    fromDateValue = <MIFormattedDate year={undefined} date={date} />;

    if (isSameMonth(date, maxDate)) {
      toDateValue = format(maxDate, 'd, yyyy');
    }
  }

  if (!toDateValue) {
    toDateValue = <MIFormattedDate date={maxDate} />;
  }

  return (
    <>
      {fromDateValue}
      {' - '}
      {toDateValue}
    </>
  );
};
