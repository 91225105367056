import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { getJWTPayload } from 'src/app/helpers/jwt';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import { useBreak } from 'src/app/hoc';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { PaymentType } from 'src/app/utils/types';

import * as PUSH_TO_DEBIT_LOCATIONS from 'src/app/pages/vendor/select-expedited/locations';
import * as PUSH_TO_ACH_LOCATIONS from 'src/app/pages/vendor/shift-vendor-to-ach/locations';

import { DELIVERY_TYPE, PAYMENT_STATUS } from 'src/app/utils/consts';
import { VendorPaymentDetails } from 'src/app/version-2/components/VendorPaymentDetails/VendorPaymentDetails';
import { featureFlags } from '@melio/shared-web';
import analytics from 'src/app/services/analytics';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import * as EDIT_CHECK_ADDRESS_LOCATIONS from '../edit-check-adress/locations';
import { VendorPaymentContent } from './components/VendorPaymentContent';
import { VendorPaymentEditModal } from './components/VendorPaymentEditModal';
import { FORM_TYPES, TIMELINE_STEP_ACTIONS } from './consts';
import locations from './locations';
import { PaymentDeliveryStatusEnum, RiskStatusEnum } from 'src/app/version-2/model/enums';
import { triggerAnalyticsEvent } from './utils';
import { upgradePaymentLocations } from '../upgrade-payment/locations';
import { upsellTypeToAnalyticsOptionName } from 'src/app/version-2/pages/vendor-upgrade-payment/utils/analytics.utils';
import { UpsellType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upsellType.enum';
import { useExpediteItemContext } from 'src/app/pages/vendor/vendor-payment-tracking/context/ExpediteItemContext';
import { AreaLoader } from '@melio/billpay-design-system';
import { loggingApi } from 'src/app/version-2/api/loggers';

const {
  CONTACT_SUPPORT,
  EDIT_BANK_DETAILS,
  EDIT_CHECK_ADDRESS,
  EDIT_EMAIL,
  TO_ACH,
  TO_PUSH_TO_DEBIT,
  TO_PUSH_TO_FAST_ACH,
  TO_PUSH_TO_RTP,
} = TIMELINE_STEP_ACTIONS;

export const VendorPaymentDetailsPage = ({ token }) => {
  const { isMobile } = useBreak();
  const { paymentId, vendorId } = getJWTPayload(token);
  const site = useSiteContext();
  const history = useHistory();

  const { loading: isDeliveryMethodLoading } = useSelector(
    deliveryMethodsStore.selectors.validation
  ) as { loading: boolean };

  const paymentStoreActions = useStoreActions(paymentStore);
  const payment = useSelector<PaymentType | null>(
    paymentStore.selectors.byId(paymentId)
  ) as PaymentType;
  const { isPaymentLoading, errorData } = useStructuredSelectors(
    paymentStore.selectors.validation(paymentId)
  );
  const [isBpGlobalOptInUpsellFeatureFlagOn] = featureFlags.useFeature(
    FeatureFlagsEnum.BP_GLOBAL_OPT_IN_UPSELL,
    false
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formType, setFormType] = useState<FORM_TYPES | null>(null);
  const { expediteType, isLoading: isExpediteItemLoading } = useExpediteItemContext();

  useEffect(() => {
    analytics.trackRoute('page-viewed', {
      isContainGlobalVCOptInOption: !!isBpGlobalOptInUpsellFeatureFlagOn,
    });
  }, []);

  useEffect(() => {
    if (token) {
      paymentStoreActions.fetchPaymentDetailsWithToken({ token });
    }
  }, [token]);

  useEffect(() => {
    if (!isPaymentLoading) {
      if (errorData || payment?.deliverStatus === PaymentDeliveryStatusEnum.CANCELED) {
        history.push(generatePath(locations.invalidLink, { token }));
      } else if (
        payment?.status === PAYMENT_STATUS.BLOCKED &&
        payment.riskStatus &&
        payment.riskStatus !== RiskStatusEnum.PENDING
      ) {
        history.push(generatePath(locations.blockedPayment, { token }));
      }
    }
  }, [isPaymentLoading, payment]);
  const handleModalClose = () => {
    if (!isDeliveryMethodLoading) {
      setIsModalOpen(false);
      setFormType(null);
    }
  };

  const openModal = (formType: FORM_TYPES) => {
    setFormType(formType);
    setIsModalOpen(true);
  };

  const vendorPaymentActions = {
    [CONTACT_SUPPORT]: () => {
      triggerAnalyticsEvent({ payment, action: 'contact-support-clicked' });
      window.open(`mailto:${site.config.support.email}`, '_blank');
    },
    [EDIT_BANK_DETAILS]: () => {
      triggerAnalyticsEvent({ payment, action: 'edit-bank-details-clicked' });
      openModal(FORM_TYPES.EDIT_BANK_DETAILS);
    },
    [EDIT_CHECK_ADDRESS]: () => {
      triggerAnalyticsEvent({ payment, action: 'edit-check-address-clicked' });

      if (isMobile) {
        history.push(
          generatePath(EDIT_CHECK_ADDRESS_LOCATIONS.default.checkNotSentChangeAddress, {
            id: vendorId,
            token,
          })
        );
      } else {
        openModal(FORM_TYPES.EDIT_CHECK_ADDRESS);
      }
    },
    [EDIT_EMAIL]: () => {
      triggerAnalyticsEvent({ payment, action: 'edit-email-clicked' });
      openModal(FORM_TYPES.EDIT_EMAIL);
    },
    [TO_ACH]: () => {
      triggerAnalyticsEvent({
        payment,
        action: 'change-delivery-method-clicked',
        additionalData: {
          fromDeliveryMethod: DELIVERY_TYPE.CHECK,
          toDeliveryMethod: DELIVERY_TYPE.ACH,
        },
      });
      history.push(generatePath(PUSH_TO_ACH_LOCATIONS.default.index, { id: vendorId, token }));
    },
    [TO_PUSH_TO_DEBIT]: () => {
      triggerAnalyticsEvent({
        payment,
        action: 'change-delivery-method-clicked',
        additionalData: {
          fromDeliveryMethod: payment?.deliveryMethod.deliveryType,
          toDeliveryMethod: DELIVERY_TYPE.CARD,
        },
      });

      history.push(generatePath(PUSH_TO_DEBIT_LOCATIONS.default.index, { token }));
    },
    [TO_PUSH_TO_FAST_ACH]: () => {
      try {
        sessionStorage.setItem('expediteFromTrackPayment', 'true');
      } catch (error) {
        loggingApi.error('VendorPaymentDetailsPage.vendorPaymentActions(): sessionStorage', {
          error,
        });
      }

      triggerAnalyticsEvent({
        payment,
        action: 'change-delivery-method-clicked',
        additionalData: {
          fromDeliveryMethod: payment?.deliveryMethod.deliveryType,
          deliveryPreference: payment?.eligibleForPushToDebit
            ? [
                upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_DEBIT],
                upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_FAST_ACH],
              ].join('_')
            : upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_FAST_ACH],
        },
      });

      history.push(generatePath(upgradePaymentLocations.index, { token }));
    },
    [TO_PUSH_TO_RTP]: () => {
      triggerAnalyticsEvent({
        payment,
        action: 'change-delivery-method-clicked',
        additionalData: {
          fromDeliveryMethod: payment?.deliveryMethod.deliveryType,
          deliveryPreference: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_RTP],
        },
      });

      history.push(generatePath(upgradePaymentLocations.index, { token }));
    },
  };

  const onExpediteCTA = expediteType && vendorPaymentActions[expediteType];

  const isPageLoading = isPaymentLoading || isExpediteItemLoading || !payment;

  if (isPageLoading) {
    return <AreaLoader />;
  }

  return (
    <>
      <VendorPaymentDetails
        payment={payment}
        token={token}
        onExpediteCTA={onExpediteCTA}
        expediteType={expediteType}
      >
        <VendorPaymentContent payment={payment} vendorPaymentActions={vendorPaymentActions} />
      </VendorPaymentDetails>
      {isModalOpen && formType && (
        <VendorPaymentEditModal
          payment={payment}
          formType={formType}
          token={token}
          isLoading={isDeliveryMethodLoading}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};
