import {
  MenuButton as ChakraMenuButton,
  MenuButtonProps,
  MenuProps as ChakraMenuProps,
  Menu as ChakraMenu,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

const MenuButton = forwardRef(
  ({ children, ...rest }: MenuButtonProps, ref?: LegacyRef<HTMLButtonElement>) => (
    <ChakraMenuButton ref={ref} _hover={{ cursor: 'pointer' }} {...rest}>
      {children}
    </ChakraMenuButton>
  ),
);

type MenuProps = ChakraMenuProps & {
  autoWidth?: boolean;
};

const Menu = ({ children, ...rest }: MenuProps) => <ChakraMenu {...rest}>{children}</ChakraMenu>;

Menu.MenuList = MenuList;
Menu.MenuButton = MenuButton;
Menu.MenuItem = MenuItem;

export { Menu };
