import { createReducer } from '@reduxjs/toolkit';
import { CLEAR_STATE } from 'src/app/redux/user/actionTypes';

export function clearStateReducer(initialState) {
  return createReducer(initialState, {
    [CLEAR_STATE]() {
      return initialState;
    },
  });
}
