import React from 'react';
import styled from 'styled-components';
import { AreaLoader } from '@melio/billpay-design-system';
import doneImage from 'src/app/images/qbo/check-circle.svg';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import ConvertedUnilateralPaymentActivity from 'src/app/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { useEditCheckAddressStatus } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import { BillInfoContainer, BillPaymentBlock, QBLogo, Subtitle } from './shared';

type Props = {
  token: string;
};

const SwitchedCheckAlreadyPage = ({ token }: Props) => {
  const { payment, isPaymentLoading, organization } = useEditCheckAddressStatus({ token });
  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <BillPaymentBlock>
        <BillInfoContainer>
          <div>
            <DoneIcon src={doneImage} />
            <Title data-testid="success-title">
              <MIFormattedText label="vendors.editCheckAddress.switchedCheckAlreadyPage.title" />
            </Title>
          </div>
          <Subtitle>
            <MIFormattedText
              label="vendors.editCheckAddress.switchedCheckAlreadyPage.subtitle"
              values={{
                companyName: organization?.companyName,
                totalAmount: <MIFormattedCurrency value={bill.totalAmount} />,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
      </BillPaymentBlock>
    </QBOLayoutPage>
  );
};

export default SwitchedCheckAlreadyPage;

const DoneIcon = styled.img<{ large?: boolean }>`
  width: ${({ large }) => (large ? '5rem' : '4rem')};
  height: ${({ large }) => (large ? '5rem' : '4rem')};
  margin-bottom: 2rem;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.text.color.main};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  font-size: ${({ theme }) => theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
  margin-bottom: 1rem;
`;
