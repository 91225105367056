import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import noPaymentCreated from 'src/app/images/qbo/no-payment-created-icon.png';
import intuit from 'src/app/utils/intuit';
import { redirectToContractors, melioClose } from 'src/app/utils/external-events';
import analytics from 'src/app/services/analytics';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';

const eventPage = 'contractors';

type LocationState = {
  billId?: string;
  exitUrl?: string;
};

export const PayContractorsErrorPage = () => {
  const location = useLocation<LocationState>();
  const [isLoading, setLoader] = useState(false);
  const history = useHistory();

  const goExit = () => {
    if (location.state?.exitUrl) {
      history.push(location.state.exitUrl);
    } else {
      melioClose();
    }
  };

  const redirectTo = () => {
    analytics.track(eventPage, 'redirect-to', location.state);
    setLoader(true);
    redirectToContractors();
  };

  useEffect(() => {
    analytics.track(eventPage, 'lp', location.state);
    intuit.endLoadingWrapper();
    intuit.hideFTULoadingWrapper();
  }, []);

  return (
    <QBOSuccessLayout
      illustration={noPaymentCreated}
      title="contractors.title"
      text="contractors.subTitle"
      buttonLabel="contractors.buttonLabel"
      buttonAction={redirectTo}
      isLoading={isLoading}
      goExit={goExit}
      hideHeader
      notesTitle="bills.pay.success.notes.title"
    />
  );
};
