import React from 'react';
import styled from 'styled-components';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { BillType } from 'src/app/utils/types';
import { getPaymentTag } from 'src/app/utils/payments';
import PartialPaymentStatus from './PartialPaymentStatus';
import PartialPaymentLabel from './PartialPaymentLabel';

type Props = {
  bill: BillType;
  currentPaymentId?: string;
  onLabelClick?: (paymentId, status) => void;
  className?: string;
};

const PartialPaymentsInfo = ({ bill, currentPaymentId, onLabelClick, className }: Props) => (
  <span className={className}>
    {bill.payments.map((payment) => {
      const { label, statusLabel, date } = getPaymentTag(payment as any);

      return (
        <PartialPayment key={payment.id}>
          <Container>
            <PartialPaymentStatus statusLabel={statusLabel} date={date} payment={payment} />
            <PartialPaymentLabel
              label={label}
              onLabelClick={() => onLabelClick && onLabelClick(payment.id, payment.status)}
              isCurrentPayment={payment.id.toString() === currentPaymentId}
            />
          </Container>
          <Amount value={payment.amount || ''} />
        </PartialPayment>
      );
    })}
    {!!bill.externallyPaid && (
      <PartialPayment>
        <MIFormattedText label="bills.form.partialPayments.status.externallyPaid" />
        <MIFormattedCurrency value={bill.externallyPaid} />
      </PartialPayment>
    )}
  </span>
);

const Amount = styled(MIFormattedCurrency)`
  font-weight: 600;
`;

const PartialPayment = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.regular};
  line-height: 1.6rem;

  &:not(:last-of-type) {
    margin-bottom: 0.8rem;
  }

  ${({ theme }) => theme?.components?.BillOpenBalance?.PartialPayment}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default PartialPaymentsInfo;
