import { Link } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const StyledLink = styled(Link)`
  cursor: pointer;
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${(props) => props.theme.colors.ds.gray['100']};
  font-size: 2.3rem;
  margin-bottom: 0.5rem;
`;
export const StyledSubtitle = styled.div`
  font-size: 1.4rem;
  line-height: 2.1rem;
`;

export const StyledItem = styled.div`
  margin-top: ${(props) => props.theme.sizes.ds.xl};
  display: flex;
  gap: 1.2rem;
  flex-grow: 0;
  line-height: 2.1rem;

  svg {
    flex-shrink: 0;
    padding-top: 0.5rem;
  }
  .title {
    color: ${(props) => props.theme.colors.ds.black};
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 0.4rem;
  }
  .text {
    font-size: 1.4rem;
  }
`;
