import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getBill } from 'src/app/redux/payBillWizard/selectors';
import PayBillCompleteLegalInfoPageContainer from 'src/app/pages/bill/pay/QBOPayBillCompleteLegalInfoPageContainer';
import Step from 'src/app/components/layout/QBOLayoutPage';
import internationalAPI from 'src/app/services/api/deliveryMethods';
import { useApi } from 'src/app/hoc/useApi';
import { ErrorAlert } from 'src/app/pages/vendor/delivery-methods/international/components/ErrorAlert';
import InternationalContext from '../../Context';
import { StepProps } from '../../types';
import { internationalLocations } from 'src/app/pages/vendor/delivery-methods/international/locations';
import { isPayBillFlow } from '../../utils';
import useShowUltimateBeneficialOwnersFlow from '../../hooks/useShowUltimateBeneficialOwnersFlow';

const MissingDetails = ({ onPrev, relativeStep, onExit }: StepProps) => {
  const [state, actions] = useContext(InternationalContext);
  const { id: billId } = useSelector(getBill);

  const [editDeliveryMethod, , editLoading, editError] = useApi(
    internationalAPI.editInternationalDelivery
  );
  const [createDeliveryMethod, , createLoading, createError] = useApi(
    internationalAPI.createInternationalDelivery
  );

  const isPayBill = isPayBillFlow(state.origin);
  const deliveryAction = () =>
    state.edit
      ? actions.editDelivery(editDeliveryMethod)
      : actions.createDelivery(createDeliveryMethod);
  const isLoading = createLoading || editLoading;
  const ErrorComponent = !isLoading ? (
    <ErrorAlert error={state.edit ? editError : createError} />
  ) : null;
  const shouldDisplayUltimateBeneficialOwnersFlow = useShowUltimateBeneficialOwnersFlow();

  const getNextStepURL = () => {
    if (shouldDisplayUltimateBeneficialOwnersFlow) {
      return internationalLocations.ultimateBeneficialOwners;
    }

    return isPayBill ? internationalLocations.purposeOfPayment : state.redirectUrl;
  };

  return (
    <Step relativeStep={relativeStep} onPrev={onPrev} goExit={onExit} hideHeader>
      <PayBillCompleteLegalInfoPageContainer
        id={billId}
        isInternational
        onLegalInfoCallback={deliveryAction}
        nextStepURL={getNextStepURL()}
        ErrorComponent={ErrorComponent}
        texts={{
          title: 'international.missingDetails.title',
          subtitle: 'international.missingDetails.subtitle',
          button: 'international.missingDetails.button',
          loading: 'international.missingDetails.loading',
          einHelperText: 'international.missingDetails.einHelperText',
        }}
      />
    </Step>
  );
};

export default MissingDetails;
