import { SyntheticEvent, useEffect } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { AreaLoader, Collapse, Input, PasswordInput, useBreak } from '@melio/billpay-design-system';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { analytics, getFundingSources, utilsLocations } from 'src/app/version-2/externals';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/version-2/model/constants';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import {
  AchDeliveryMethodDetailsLayout,
  AchDetailsFieldsWrapper,
  AchDetailsFooter,
  HiddenInput,
} from './AchDeliveryMethodDetailsPage.styles';
import { AchDetailsFormFieldNamesEnum } from '../../model/enums/achDetailsFormFieldNames.enum';
import { AchDeliveryMethodDetailsFlowState } from '../../model/objects/achDeliveryMethodDetailsFlowState';
import { AchDetailsFormData } from '../../model/objects/achDetailsFormData';
import { achDetailsSchema } from '../../model/schemas/achDetailsValidation.schema';
import {
  achDeliveryMethodDetailsActions,
  achDeliveryMethodDetailsSelectors,
} from '../../modules/achDeliveryMethodDetails.slice';
import { transformValidationErrorsToAnalyticsFormat } from '../../utils/analytics.utils';

interface AchDeliveryMethodDetailsPageProps {
  vendorId: string;
  deliveryMethodId?: string;
  flowState: AchDeliveryMethodDetailsFlowState;
  onPrev?: VoidFunction;
}

export const AchDeliveryMethodDetailsPage = ({
  vendorId,
  deliveryMethodId,
  flowState,
  onPrev,
}: AchDeliveryMethodDetailsPageProps) => {
  const { isMobile } = useBreak();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const orgId = useSelector(userSliceSelectors.selectOrgId);
  const initialFormValues = useSelector(achDeliveryMethodDetailsSelectors.selectInitialFormValues);
  const vendorName = useSelector(achDeliveryMethodDetailsSelectors.selectVendorName);
  const isLoading = useSelector(achDeliveryMethodDetailsSelectors.selectIsLoading);
  const isSubmitting = useSelector(achDeliveryMethodDetailsSelectors.selectIsSubmitting);
  const { exitUrl, origin, redirectUrl, selectedPaymentIntent, batchList } = useSelector(
    achDeliveryMethodDetailsSelectors.selectFlowState
  );

  const fundingSources = useSelector(getFundingSources);
  const showConfirmAccountNumberField = useSelector(
    achDeliveryMethodDetailsSelectors.selectShowConfirmAccountNumberField
  );

  const isEdit = !!deliveryMethodId;

  useEffect(() => {
    dispatch(achDeliveryMethodDetailsActions.setFlowState(flowState));
    dispatch(
      achDeliveryMethodDetailsActions.loadAchDeliveryMethodDetailsPageData({
        deliveryMethodId,
        vendorId,
      })
    );

    return () => {
      dispatch(achDeliveryMethodDetailsActions.resetState());
    };
  }, []);

  const onSubmit = (formData) => {
    dispatch(
      achDeliveryMethodDetailsActions.submitAchDeliveryMethod({
        bankAccount: {
          routingNumber: formData.routingNumber,
          accountNumber: formData.accountNumber,
        },
        deliveryMethodId,
        vendorId,
        history,
      })
    );
  };

  const onExit = () => {
    analytics.trackAction('go.exit');

    if (exitUrl) {
      const preservedState = {
        origin,
        redirectUrl,
        selectedPaymentIntent,
        batchList,
      };

      history.push(exitUrl, {
        ...preservedState,
        preservedState,
      });

      return;
    }

    loggingApi.error('AchDeliveryMethodDetailsPage.onExit(): exitUrl is not presented', { orgId });

    history.push(
      utilsLocations.Settings.vendorSingle.url({
        id: vendorId,
        orgId,
      })
    );
  };

  const {
    handleSubmit,
    formState: { dirtyFields, errors },
    register,
    trigger,
    getValues,
    setValue,
  } = useForm<AchDetailsFormData>({
    resolver: yupResolver(achDetailsSchema),
    mode: 'onTouched',
    defaultValues: initialFormValues,
    values: initialFormValues,
    context: { userFundingSources: fundingSources },
  });

  useEffect(() => {
    if (!showConfirmAccountNumberField && dirtyFields.accountNumber) {
      setValue(AchDetailsFormFieldNamesEnum.ConfirmAccountNumber, '');
      dispatch(achDeliveryMethodDetailsActions.setShowConfirmAccountNumberField(true));
    }
  }, [dirtyFields.accountNumber, showConfirmAccountNumberField]);

  if (isLoading) {
    return <AreaLoader placement="wizard" />;
  }

  const onValidationErrors = (validationErrors: FieldErrors<AchDetailsFormData>) => {
    const transformedValidationErrors = transformValidationErrorsToAnalyticsFormat({
      validationErrors,
    });

    analytics.trackAction('delivery-method-validation-error', {
      validationErrors: transformedValidationErrors,
    });
  };

  const getSubtitle = () => {
    if (isEdit) {
      return undefined;
    }

    return isMobile
      ? 'vendors.deliveryMethods.bank.doubleCheckDetailsMobile'
      : 'vendors.deliveryMethods.bank.doubleCheckDetails';
  };

  const routingNumberError = errors[AchDetailsFormFieldNamesEnum.RoutingNumber];
  const accountNumberError = errors[AchDetailsFormFieldNamesEnum.AccountNumber];
  const confirmAccountNumberError = errors[AchDetailsFormFieldNamesEnum.ConfirmAccountNumber];

  const accountNumberFieldRegisterProps = register(AchDetailsFormFieldNamesEnum.AccountNumber);

  const onAccountNumberBlur = (e: SyntheticEvent) => {
    if (getValues(AchDetailsFormFieldNamesEnum.ConfirmAccountNumber)) {
      // handle edge case when account number and its confirm field are filled, but then we back to account number field and change it
      // react-hook-form validates fields only on focused field level(due to performance reasons), so we had to trigger it manually
      trigger(AchDetailsFormFieldNamesEnum.ConfirmAccountNumber);
    }

    accountNumberFieldRegisterProps.onBlur(e);
  };

  return (
    <AchDeliveryMethodDetailsLayout
      hideHeader
      title="vendors.deliveryMethods.bank.title"
      titleValues={{ vendor: vendorName }}
      subtitle={getSubtitle()}
      onSubmit={handleSubmit(onSubmit, onValidationErrors)}
      nextLabel="vendors.deliveryMethods.bank.save"
      goExit={onExit}
      onPrev={onPrev}
      isLoading={isSubmitting}
      footer={
        <AchDetailsFooter>
          {formatMessage({ id: 'vendors.deliveryMethods.bank.saveFooter' })}
        </AchDetailsFooter>
      }
    >
      <AchDetailsFieldsWrapper>
        <Input
          {...register(AchDetailsFormFieldNamesEnum.RoutingNumber)}
          label={formatMessage({ id: 'vendors.deliveryMethods.bank.routingNumber' })}
          placeholder={formatMessage({
            id: 'vendors.deliveryMethods.bank.routingNumberPlaceholder',
          })}
          type="number"
          inputMode="numeric"
          data-testid="input-routingNumber"
          className={FULL_STORY_MASK_RULE_CLASS}
          errorMessage={routingNumberError && formatMessage({ id: routingNumberError.message })}
        />
        <PasswordInput
          {...accountNumberFieldRegisterProps}
          onBlur={onAccountNumberBlur}
          errorMessage={accountNumberError && formatMessage({ id: accountNumberError.message })}
          isValueVisible={!isEdit}
          label={formatMessage({ id: 'vendors.deliveryMethods.bank.accountNumber' })}
          placeholder={formatMessage({
            id: 'vendors.deliveryMethods.bank.accountNumberPlaceholder',
          })}
          inputMode="numeric"
          className={FULL_STORY_MASK_RULE_CLASS}
          data-testid="input-accountNumber"
        />

        <HiddenInput type="password" autoComplete="new-password" />
        <Collapse in={showConfirmAccountNumberField} animateOpacity>
          <PasswordInput
            {...register(AchDetailsFormFieldNamesEnum.ConfirmAccountNumber)}
            onBlur={onAccountNumberBlur}
            errorMessage={
              confirmAccountNumberError && formatMessage({ id: confirmAccountNumberError.message })
            }
            isValueVisible={!isEdit}
            label={formatMessage({ id: 'vendors.deliveryMethods.bank.confirmAccountNumber' })}
            placeholder={formatMessage({
              id: 'vendors.deliveryMethods.bank.confirmAccountNumberPlaceholder',
            })}
            inputMode="numeric"
            className={FULL_STORY_MASK_RULE_CLASS}
            data-testid="input-confirmAccountNumber"
          />
        </Collapse>
      </AchDetailsFieldsWrapper>
    </AchDeliveryMethodDetailsLayout>
  );
};
