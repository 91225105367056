import React from 'react';
import locations from '../../utils/locations';
import analytics from '../../services/analytics';
import LoginTokenRedirect from './LoginTokenRedirect';
import ChangeEmailPageContainer from './email-verification/ChangeEmailPageContainer';

const eventPage = 'auth';

export const authAgnosticRoutes = [
  {
    path: locations.Auth.loginWithToken.url({ token: ':token' }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'login-with-token');

      return <LoginTokenRedirect token={cProps.match.params.token} />;
    },
  },
  {
    path: locations.Auth.register.changeEmail.url(),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'change-email');

      return <ChangeEmailPageContainer {...cProps} />;
    },
  },
];
