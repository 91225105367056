import { envApi } from 'src/app/version-2/api/env';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import { AddressType } from 'src/app/utils/types';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import analytics from 'src/app/services/analytics';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const { Lookup } = SmartyStreetsSDK.usAutocompletePro;
const credentials = new SmartyStreetsCore.SharedCredentials(
  envApi.getConfig().services.smartystreets.key
);
const client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);

type Suggestion = {
  city: string;
  entries: number;
  secondary: string;
  state: string;
  streetLine: string;
  zipcode: string;
};

const suggestionToAddress = (suggestion: Suggestion): AddressType => ({
  addressLine1: suggestion.streetLine,
  addressLine2: suggestion.secondary,
  city: suggestion.city,
  state: suggestion.state,
  zipCode: suggestion.zipcode,
});

export const getAddressSuggestion = async (search: string) => {
  if (isEmpty(search)) {
    return [];
  }

  try {
    const result = await client.send(new Lookup(search));

    return map(result.result, suggestionToAddress);
  } catch (e: any) {
    analytics.track('check-address', 'smarty-streets-search-failed', {
      statusCode: e?.statusCode,
      errorMessage: e?.error,
      payload: e?.payload,
    });

    return [];
  }
};
