/* eslint-disable camelcase */
import { DELIVERY_METHOD_ORIGIN } from 'src/app/utils/consts';
import { generatePath } from 'react-router-dom';
import billPayLocations from 'src/app/utils/locations';
import { AddFundingSourceWizardOriginEnum } from 'src/app/version-2/model/enums';
import { CountryOption, Country, StateTypes } from './types';
import { internationalLocations } from 'src/app/pages/vendor/delivery-methods/international/locations';
import { getRiskByCountryCode } from 'src/app/version-2/utils/countries.utils';

export const VENDOR_ADDRESS_FIELDS: string[] = [
  'address',
  'city',
  'state_or_province',
  'postcode',
  'country',
];
export const VENDOR_PERSONAL_INFO: string[] = ['business_name', 'name'];
export const VENDOR_DETAILS_FIELDS = Array.prototype.concat(
  VENDOR_ADDRESS_FIELDS,
  VENDOR_PERSONAL_INFO
);

export const ERROR_PREFIX = 'inputErrors.international';

const GOOGLE_FIELDS_TO_INCLUDE = [
  'postal_town', // city location - UK & Sweden
  'locality',
  'administrative_area_level_1',
  'postal_code',
  'country',
];

const GOOGLE_FIELDS_MAPPING = {
  locality: 'city',
  postal_town: 'city',
  administrative_area_level_1: 'state_or_province',
  postal_code: 'postcode',
  country: 'country',
};

export const createCountriesOptions = (
  countries: Country[] = [],
  isAdditionalCountriesEnabled = false
): CountryOption[] => {
  const options: any = [];
  const regions: any = {};

  countries.forEach(({ label, code, region, billingType, isSupported, risk }) => {
    // generate countries options
    const optionValues = {
      label,
      value: code,
      billingType,
      isSupported,
      risk,
    };

    // handle region grouping
    if (!isAdditionalCountriesEnabled && region) {
      if (!regions[region])
        regions[region] = {
          label: `${region} countries`,
          options: [optionValues],
        };
      else regions[region].options.push(optionValues);
    } else options.push(optionValues);
  });

  return [...options, ...Object.values(regions)];
};

export const transformForValidation = (modelValues, validators) => {
  const fieldElements = Object.entries(validators).filter(([name]) =>
    VENDOR_DETAILS_FIELDS.includes(name)
  );

  return fieldElements.map(([name, regex]) => ({
    name,
    regex,
    value: modelValues?.[name]?.value || '',
    isRequired: true,
  }));
};

export const initRequiredFields = () => ({
  business_name: '',
  name: '',
  address: '',
  city: '',
  company_name: '',
  country: '',
  bic_swift: '',
  iban: '',
  postcode: '',
  state_or_province: '',
});

export const getVendorAddressValues = (modelValues) =>
  VENDOR_DETAILS_FIELDS.reduce(
    (values, field) => ({
      ...values,
      [field]: modelValues?.[field]?.value || '',
    }),
    {}
  );

export const extractAddress = (addressComponents = []) => {
  const relevantAddressFields: Record<string, string> = {
    city: '',
    state_or_province: '',
    postcode: '',
    country: '',
  };

  addressComponents.forEach(({ types, short_name, long_name }) => {
    const type = types[0];

    if (GOOGLE_FIELDS_TO_INCLUDE.includes(type)) {
      const useShortName = type === 'country';

      relevantAddressFields[GOOGLE_FIELDS_MAPPING[type]] = useShortName ? short_name : long_name;
    }
  });

  return relevantAddressFields;
};

export const removeSpace = (value: any): any =>
  typeof value === 'string' ? value?.replace(/\s/g, '') : value;

export const transformToCreateDeliveryRequest = ({ state }) => {
  const { requiredFields, bankDetails, country, iban } = state as any;

  const data: Record<string, any> = {
    internationalAccount: {
      payeeAccount: {
        name: requiredFields.name,
        bankAccountHolderName: requiredFields.name,
        bankCountry: country.code.toUpperCase(),
        currency: 'USD',
        address: requiredFields.address,
        country: requiredFields.country.toUpperCase(), // payee country
        bicSwift: bankDetails.bic_swift,
        bankName: bankDetails.bank_name,
        city: requiredFields.city,
        accountNumber: bankDetails.account_number,
        postcode: undefined,
        state: requiredFields?.state_or_province,
        companyName: requiredFields.business_name,
      },
    },
  };

  if (iban) data.internationalAccount.payeeAccount.iban = iban;

  if (requiredFields?.postcode) {
    data.internationalAccount.payeeAccount.postcode = requiredFields?.postcode;
  }

  if (bankDetails.bank_address) {
    data.internationalAccount.payeeAccount.bankAddress = bankDetails.bank_address;
  }

  return data;
};

export const transformToLocalState = (data, countries): Record<string, any> => {
  const { internationalAccount } = data;

  return {
    [StateTypes.COUNTRY]: {
      code: internationalAccount.payeeBankCountry,
      risk: getRiskByCountryCode(countries, internationalAccount.payeeBankCountry),
    },
    [StateTypes.IBAN]: internationalAccount.payeeBankIban,
    [StateTypes.SWIFT]: internationalAccount.payeeBankBicSwift,
    [StateTypes.REQUIRED_FIELDS]: {
      name: internationalAccount.payeeName,
      address: internationalAccount.payeeAddress,
      postcode: internationalAccount.payeePostcode,
      state_or_province: internationalAccount.payeeState,
      city: internationalAccount.payeeCity,
      business_name: internationalAccount.payeeCompanyName,
      country: internationalAccount.payeeCountry,
    },
    [StateTypes.ACCOUNT_NUMBER]: internationalAccount.payeeBankAccountNumber,
    [StateTypes.BANK_NAME]: internationalAccount.payeeBankName,
  };
};

export const isVendorDetailsOrigin = (origin) => origin === DELIVERY_METHOD_ORIGIN.VENDOR_DETAILS;

export const isPayBillFlow = (origin) => origin === DELIVERY_METHOD_ORIGIN.PAY_BILL;
export const isBatchFlow = (origin) =>
  origin === DELIVERY_METHOD_ORIGIN.BATCH || origin === AddFundingSourceWizardOriginEnum.BATCH_BULK;

export const getFundingSourcePagePath = (orgId, billId) =>
  generatePath(billPayLocations.Bills.pay.funding.url({ orgId, id: billId }));

export const getPurposeOfPaymentPagePath = (orgId, vendorId) =>
  generatePath(internationalLocations.purposeOfPayment, { orgId, id: vendorId });
