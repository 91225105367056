import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';

const FormTitle = () => (
  <StyledFormTitle>
    <div>
      <MIFormattedText label="international.ultimateBeneficialOwners.formTitle" />
    </div>
    <Tooltip
      placement="top"
      label={<MIFormattedText label="international.ultimateBeneficialOwners.ownerTooltipContent" />}
    >
      <ToolTipIcon className="icon-info-hollow-icon" />
    </Tooltip>
  </StyledFormTitle>
);

export default FormTitle;

const StyledFormTitle = styled.div`
  font-size: 1.4rem;
  color: #6b6c72;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0;
  margin-bottom: 2rem;
  display: flex;
  align-content: center;
`;

const ToolTipIcon = styled.i`
  margin-left: 0.5rem;
  font-size: 1.5rem;
`;
