import { RecordOf } from 'immutable';
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import authStore from 'src/app/modules/auth/auth-store';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { handleParentMessage } from 'src/app/utils/external-events';
import { UserContextType, CompanyInfoType } from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import {
  getProfile,
  getCompanyInfo,
  getIsUserChecked,
  getIsLoggedInAs,
  getOrgId,
} from 'src/app/redux/user/selectors';
import { isLoginFromForest } from 'src/app/utils/user-utils';
import analytics from 'src/app/services/analytics';
import { AreaLoader } from '@melio/billpay-design-system';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { loggingApi } from 'src/app/version-2/api/loggers';
import CodeVerificationPageContainer from './auth/email-verification/CodeVerificationPageContainer';
import { AuthCodeVerificationPage } from './quickbooks/auth-code-verification/AuthCodeVerificationPage/AuthCodeVerificationPage';
import { buildRoutes } from './route';
import { OrgsRouter } from './orgs/OrgsRouter';
import { VendorsRouter } from './vendor/VendorsRouter';
import locations from './locations';
import { EntrypointsRouter } from './quickbooks/EntrypointsRouter';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import {
  DIRECT_LINK,
  MELIO_FTU,
  ENTRYPOINT_INTUIT,
} from 'src/app/version-2/model/constants/risk-data-collecting';

type MapStateToProps = {
  profile: RecordOf<UserContextType>;
  isLoggedInAs: boolean;
  isUserChecked: boolean;
  companyInfo: RecordOf<CompanyInfoType>;
  orgId: string;
};

type MapDispatchToProps = {
  postSessionStartEvent: (params: { [key: string]: string }) => Promise<{ [key: string]: any }>;
  postSessionEndEvent: (contextName: string) => Promise<{ [key: string]: any }>;
};

type Props = MapStateToProps &
  MapDispatchToProps & {
    history: any;
  };

const Router = ({
  isLoggedInAs,
  companyInfo,
  isUserChecked,
  profile,
  history,
  postSessionStartEvent,
  postSessionEndEvent,
}: Props) => {
  const site = useSiteContext();
  const userAuthType = useSelector(authStore.selectors.userAuthType);

  useEffect(() => {
    try {
      if (!window.sessionStorage.getItem(MELIO_FTU)) {
        const applicationFlowEventName = history.location.pathname.match(/\/quickbooks\/entry/g)
          ? ENTRYPOINT_INTUIT
          : DIRECT_LINK;

        postSessionStartEvent({ applicationFlowEventName });
      } else {
        window.sessionStorage.removeItem(MELIO_FTU);
      }
    } catch (error) {
      loggingApi.error('Router.useEffect(): sessionStorage', {
        error,
      });
    }
  }, []);

  useEffect(() => {
    try {
      window.parent.addEventListener('message', handleParentMessage);
    } catch (e) {
      window.addEventListener('message', handleParentMessage);
    }
  }, []);

  useEffect(
    () =>
      history.listen(() => {
        if (history.action === 'POP') {
          analytics.trackAction('history-pop');
        }
      }),
    [history]
  );

  useEffect(() => {
    const onUnload = () => {
      analytics.trackAction('page-left');

      try {
        if (!window.sessionStorage.getItem(MELIO_FTU)) {
          postSessionEndEvent(site.name);
        }
      } catch (error) {
        loggingApi.error('Router.onUnload(): sessionStorage', {
          error,
        });
      }
    };

    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  useEffect(() => {
    if (!isLoginFromForest()) {
      analytics.identify(profile, undefined, {
        isLoggedInAs,
        companyInfo,
      });
    }
  }, [profile, isLoggedInAs, companyInfo]);

  if (!isUserChecked) {
    return <AreaLoader />;
  }

  const routes = buildRoutes(site, userAuthType);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <SmartRoute path={locations.codeVerification} exact>
          <CodeVerificationPageContainer />
        </SmartRoute>
        <SmartRoute path={locations.authCodeVerification} exact>
          <AuthCodeVerificationPage />
        </SmartRoute>
        <SmartRoute path={locations.orgs.index}>
          <OrgsRouter orgsRoutes={routes.orgsRoutes} />
        </SmartRoute>
        <SmartRoute
          path={locations.vendor.base}
          allowFor={['unauthenticated', 'authenticated', 'guest']}
        >
          <VendorsRouter />
        </SmartRoute>
        <SmartRoute
          path={locations.entrypoints.base}
          allowFor={['unauthenticated', 'authenticated']}
        >
          <EntrypointsRouter />
        </SmartRoute>
        {routes.publicRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Switch>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
  companyInfo: getCompanyInfo(state),
  isUserChecked: getIsUserChecked(state),
  isLoggedInAs: getIsLoggedInAs(state),
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch) => ({
  postSessionStartEvent(params: { [key: string]: string }) {
    dispatch(applicationActions.sessionStartAction(params));
  },
  postSessionEndEvent(contextName: string) {
    dispatch(applicationActions.sessionEndAction({ contextName }));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Router);
