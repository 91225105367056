import React from 'react';
import { ReactComponent as RightArrow } from 'src/app/images/core/right-arrow.svg';
import PaginationIconButton from './PaginationIconButton';

type Props = {
  nextPage: () => void;
  canNextPage?: boolean;
};

const Next = ({ canNextPage, nextPage }: Props) => (
  <PaginationIconButton
    ariaLabel="next-page"
    onClick={nextPage}
    icon={<RightArrow />}
    disabled={!canNextPage}
    testId="next-page"
  />
);

export default Next;
