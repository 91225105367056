import React, { useCallback, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { OrganizationPreferencesType, OrgPreferencesTypeKeys } from 'src/app/utils/types';

export type FeatureFlag = keyof typeof OrgPreferencesTypeKeys;
type SiteFlags = Record<FeatureFlag, boolean>;

export const featureFlagEnabledValues = ['1', 'true'];

export const useHasFeatureFlag = () => {
  const siteFeatureFlags: SiteFlags = useSiteContext().config.featureFlags || {};
  const orgFeatureFlags: OrganizationPreferencesType =
    useSelector(getOrganizationPreferences) || {};

  const orgHasFeature = useCallback(
    (featureFlag: FeatureFlag) => featureFlagEnabledValues.includes(orgFeatureFlags[featureFlag]),
    [orgFeatureFlags]
  );

  const siteHasFeature = useCallback(
    (featureFlag: FeatureFlag) => !!siteFeatureFlags[featureFlag],
    [siteFeatureFlags]
  );

  const isFeatureFlagEnabled = useCallback(
    (featureFlag: FeatureFlag) => siteHasFeature(featureFlag) || orgHasFeature(featureFlag),
    [siteHasFeature, orgHasFeature]
  );

  return { isFeatureFlagEnabled };
};

export function withHasFeatureFlag() {
  return function (Component: React.FC<any>): React.FC<any> {
    return function (props: any): ReactElement {
      const { isFeatureFlagEnabled } = useHasFeatureFlag();

      return (
        <Component {...props} isFeatureFlagEnabled={isFeatureFlagEnabled}>
          {props.children}
        </Component>
      );
    };
  };
}
