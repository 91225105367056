export const enum VirtualCardPaymentState {
  Unknown = 'UNKNOWN',
  Valid = 'VALID',
  Cancelled = 'CANCELLED',
  Processed = 'PROCESSED',
  NewDeliveryMethod = 'NEW_DELIVERY_METHOD',
  NewDeliveryMethodProcessed = 'NEW_DELIVERY_METHOD_PROCESSED',
  NewDeliveryMethodScheduled = 'NewDeliveryMethodScheduled',
  Expired = 'EXPIRED',
  Recovered = 'RECOVERED',
  RecoverySuccess = 'RECOVERY_SUCCESS',
  Notification = 'NOTIFICATION',
}
