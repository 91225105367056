import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { getOrgId } from 'src/app/redux/user/selectors';

const useGetVendorPaymentPreferences = (vendorId: string | number | null | undefined) => {
  const orgId = useSelector(getOrgId);
  const { checkVendorPaymentPreferences } = useStoreActions(vendorsStore);
  const vendorPaymentPreferences = useSelector((state) =>
    vendorsStore.selectors.checkVendorPaymentPreferences.item(state, vendorId)
  );
  const isCheckingVendorEnableCCPayments = useSelector((state) =>
    vendorsStore.selectors.checkVendorPaymentPreferences.loading(state, vendorId)
  );

  useEffect(() => {
    if (isNil(vendorPaymentPreferences) && orgId && vendorId) {
      checkVendorPaymentPreferences({
        orgId,
        id: vendorId,
      });
    }
  }, [vendorId, vendorPaymentPreferences, checkVendorPaymentPreferences, orgId]);

  return {
    vendorPaymentPreferences,
    isCheckingVendorEnableCCPayments,
  };
};

export default useGetVendorPaymentPreferences;
