import * as React from 'react';
import { compose } from 'recompose';
import get from 'lodash/get';
import { withPreservedStateNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import CantFindBankPage from './components/CantFindBankPage';
import { PlaidFailedPageContainer } from './PlaidFailedPageContainer';

type Props = {
  vendorId: string;
  locationState: Record<string, any>;
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const CantFindBankPageContainer = ({
  locationState,
  navigate,
  navigateToExitWithPreservedState,
}: Props) => {
  const origin = get(locationState, 'preservedState.origin', '');

  const goNextFlow = () => {
    analytics.trackAction('original-connect-manually');
    navigate(locations.Onboarding.fundingSources.bank.manually.url());
  };

  const goCancel = () => {
    analytics.trackAction('original-cancel');

    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  if (
    [
      ADD_FUNDING_SOURCE_WIZARD_ORIGIN.SETTINGS,
      ADD_FUNDING_SOURCE_WIZARD_ORIGIN.GUEST_ONBOARDING,
      ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY,
    ].includes(origin)
  ) {
    return <CantFindBankPage goNextFlow={goNextFlow} goCancel={goCancel} />;
  }

  return <PlaidFailedPageContainer />;
};

const CantFindBankPageContainerComposed = compose(withPreservedStateNavigator())(
  CantFindBankPageContainer
);

export { CantFindBankPageContainerComposed as CantFindBankPageContainer };
