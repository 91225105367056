import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';

import isEmpty from 'lodash/isEmpty';

export interface VendorEmailValidationErrors {
  contactEmail?: string;
}

interface ValidateReturn {
  validationErrors: VendorEmailValidationErrors;
  isValid: boolean;
}

interface Options {
  required?: boolean;
}

export const validateVendorEmail = (
  email?: string,
  options: Options = {
    required: true,
  }
): ValidateReturn => {
  if (isEmpty(email) && options.required) {
    return {
      validationErrors: {
        contactEmail: 'batchBulkPage.errors.contactEmail.empty',
      },
      isValid: false,
    };
  }

  const validationErrors = getValidationErrors('vendor', { contactEmail: email }, ['contactEmail']);
  const isValid = isValidationOk(validationErrors);

  return {
    validationErrors,
    isValid,
  };
};
