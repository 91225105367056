import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import noPaymentCreated from 'src/app/images/qbo/no-payment-created-icon.png';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import { useBreak } from 'src/app/hoc';
import { BILL_STATUS } from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import { melioClose } from 'src/app/utils/external-events';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useLocationState } from 'src/app/utils/hooks';
import analytics from 'src/app/services/analytics';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';

export const DirectPaymentErrorPage = () => {
  const { isMobile } = useBreak();
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const [exitUrl] = useLocationState('exitUrl');

  useEffect(() => {
    analytics.track('pay-bill', 'block-schedule-payment-to-biller-directory-vendor');
  }, []);

  const goExit = () => {
    if (exitUrl) {
      historyPush({ path: exitUrl });
    } else if (isMobile) {
      const defaultFilters = getBillsDefaultFilters(BILL_STATUS.UNPAID);
      const path = generatePath(locations.Bills.filteredViewWithoutId.url(defaultFilters), {
        orgId,
      });

      historyPush({ path });
    } else {
      melioClose();
    }
  };

  return (
    <ErrorPage
      illustration={noPaymentCreated}
      title="bills.view.error.notSupportedVendorTitle"
      subtitle="bills.view.error.notSupportedVendorSubTitle"
      buttonAction={goExit}
      buttonLabel="bills.view.error.notSupportedVendorSubButton"
    />
  );
};
