import * as React from 'react';
import { Loader } from '../..';
import { AreaLoaderContainer, AreaLoaderInner } from './AreaLoader.styles';
import type { AreaLoaderPlacement } from '../../types';

type Props = {
  placement?: AreaLoaderPlacement
}

export const AreaLoader: React.FC<Props> = ({ placement = 'single' }) => (
  <AreaLoaderContainer placement={placement}>
    <AreaLoaderInner>
      <Loader />
    </AreaLoaderInner>
  </AreaLoaderContainer>
);
