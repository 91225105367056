import React from 'react';
import { Menu, Tooltip } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ActionItem } from '../types';
import { NewFeatureBadge } from './AddNewBillMenuItem.styles';

export const AddNewBillMenuItem = ({ action }: { action: ActionItem }) => (
  <Tooltip
    isDisabled={!action.isDisabled}
    label={<MIFormattedText label={action.tooltip} />}
    shouldWrapChildren
    placement="top"
    css={{ width: '22rem' }}
  >
    <Menu.MenuItem key={action.label} onClick={action.onClick} isDisabled={action.isDisabled}>
      <MIFormattedText label={action.label} />
      {action.showNewFeatureBadge && !action.isDisabled && <NewFeatureBadge />}
    </Menu.MenuItem>
  </Tooltip>
);
