import React, { useCallback, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, Link, Text } from '@melio/billpay-design-system';
import { CARD_TYPES, CONSTS, DELIVERY_TYPE } from 'src/app/utils/consts';
import { DeliveryMethodType, PaymentType, ReferrerType } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ReactComponent as DebitCardIcon } from 'src/app/images/icons/debit-card.svg';
import analytics from 'src/app/services/analytics';

import vendorsLocations from '../../shift-vendor-to-debit/locations';
import * as VIRTUAL_CARD_LOCATIONS from '../../virtual-card-details/locations';
import virtualCardLocations from '../../locations';
import {
  CategoryInfoContainer,
  CategoryVirtualCardInfoContainer,
  CategoryVirtualCardSubInfoSpan,
  LinkContainer,
} from './useExpeditedCategories.styles';

const SHIFT_ACH_PATH = 'vendors.addDeliveryMethodByLink.virtualCardCategories.shiftAchToDebit';

type PropsType = {
  token: string;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  analyticsProps?: { [key: string]: any };
};

export const useExpeditedCategories = ({
  token,
  payment,
  deliveryMethod,
  analyticsProps,
}: PropsType) => {
  const history = useHistory();

  const selectDeliveryMethod = useCallback(
    (deliveryMethodType) => {
      analytics.trackAction('DeliveryMethod-Chose', {
        deliveryMethodType:
          deliveryMethodType === DELIVERY_TYPE.CARD ? CARD_TYPES.DEBIT : deliveryMethodType,
        ...analyticsProps,
      });
      const referrer =
        payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH
          ? ReferrerType.SHIFT_ACH_TO_VC
          : ReferrerType.SHIFT_CHECK_TO_VC;

      switch (deliveryMethodType) {
        case DELIVERY_TYPE.VIRTUAL_CARD:
          history.push(generatePath(virtualCardLocations.virtualCardDetails.optIn, { token }), {
            deliveryMethod,
            deliveryMethodId: deliveryMethod?.id,
            payment,
            referrer,
          });
          break;
        case DELIVERY_TYPE.CARD:
          history.push(generatePath(vendorsLocations.addDebitMethod, { token }));
          break;
        default:
          break;
      }
    },
    [token, payment, deliveryMethod]
  );

  const categories = useMemo(() => {
    const handleMoreInfoVirtualCard = (e) => {
      e.stopPropagation();
      analytics.trackAction('MoreInformation-Learned', {
        infoType: DELIVERY_TYPE.VIRTUAL_CARD,
        ...analyticsProps,
      });
      history.push(generatePath(VIRTUAL_CARD_LOCATIONS.default.infoPage, { token }), {
        payment,
      });
    };

    return [
      {
        id: DELIVERY_TYPE.CARD,
        label: `${SHIFT_ACH_PATH}.ach.label`,
        IconComponent: DebitCardIcon,
        hint: `${SHIFT_ACH_PATH}.ach.hint`,
        info: (
          <CategoryInfoContainer>
            <Text as="span" fontSize={12} fontWeight={400}>
              <MIFormattedText label={`${SHIFT_ACH_PATH}.ach.info`} />
            </Text>
          </CategoryInfoContainer>
        ),
        includeArrow: true,
      },
      {
        id: DELIVERY_TYPE.VIRTUAL_CARD,
        label: `${SHIFT_ACH_PATH}.virtualCard.label`,
        icon: 'icon-credit-card-icon',
        info: (
          <CategoryInfoContainer>
            <CategoryVirtualCardInfoContainer>
              <MIFormattedText label={`${SHIFT_ACH_PATH}.virtualCard.hint`} />
              <Box as="span" onClick={handleMoreInfoVirtualCard}>
                <LinkContainer>
                  <MIFormattedText
                    label={`${SHIFT_ACH_PATH}.virtualCard.learnMore`}
                    values={{
                      link: (...chunks) => (
                        <Link cursor="pointer">{React.Children.toArray(chunks)}</Link>
                      ),
                    }}
                  />
                </LinkContainer>
              </Box>
            </CategoryVirtualCardInfoContainer>
            <Text as="span" fontSize={12} fontWeight={400}>
              <MIFormattedText label={`${SHIFT_ACH_PATH}.virtualCard.info`} />
              <CategoryVirtualCardSubInfoSpan>
                <MIFormattedText label={`${SHIFT_ACH_PATH}.virtualCard.subInfo`} />
              </CategoryVirtualCardSubInfoSpan>
            </Text>
          </CategoryInfoContainer>
        ),
        includeArrow: true,
      },
    ];
  }, [history, token, payment]);

  return { categories, selectDeliveryMethod };
};
