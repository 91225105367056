import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import { PAYMENT_STATUS, DELIVERY_TYPE } from 'src/app/utils/consts';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import { getJWTPayload } from 'src/app/helpers/jwt';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/pages/vendor/edit-check-adress/locations';
import shiftToAchLocations from 'src/app/pages/vendor/shift-vendor-to-ach/locations';
import { PaymentType } from 'src/app/utils/types';
import { isEligibleToReceiveVirtualCard } from 'src/app/utils/payments';
import selectExpedited from '../../select-expedited/locations';
import { RiskStatusEnum } from 'src/app/version-2/model/enums';

export type EditCheckAddressProps = {
  token: string;
  id?: string;
};

export type EditCheckAddressState = {
  payment: PaymentType;
  organization: any;
  isPaymentLoading: boolean;
  isDeliveryMethodProcessing: boolean;
  updatingDeliveryMethodId?: string;
};

const tryShiftToAchFlag = 'shiftToAch';

export const paymentStatusPropsMap = {
  [PAYMENT_STATUS.COMPLETED]: {
    path: locations.checkDeposited,
    analyticProps: { flow: 'check-deposited' },
  },
  [PAYMENT_STATUS.IN_PROGRESS]: {
    path: locations.checkSent,
    analyticProps: { flow: 'in-progress' },
  },
  [PAYMENT_STATUS.SCHEDULED]: {
    path: locations.checkNotSentChangeAddress,
    analyticProps: { flow: 'payment-schedule' },
  },
  [PAYMENT_STATUS.BLOCKED]: {
    path: locations.checkBlocked,
    analyticProps: { flow: 'payment-blocked' },
  },
  fallback: {
    path: locations.invalidToken,
    analyticProps: {},
  },
};

const ANALYTICS_TAG_NAME = 'useEditCheckAddressRedirect';

export function useEditCheckAddressRedirect(props: EditCheckAddressProps) {
  const { token, id } = props;
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const isShiftToAch = searchParams.get(tryShiftToAchFlag) === 'true';
  const isEditCheckDetailsClicked = searchParams.get('button_clicked') === 'edit_check_address';
  const paymentActions = useStoreActions(paymentStore);
  const { paymentId } = getJWTPayload(token);
  const [checkToAchVCFeatureFlag] = featureFlags.useFeature('check-to-vc-ach');

  useEffect(() => {
    analytics.track('edit-address', 'vendor-notification-email-click', {
      isShiftToAch,
      paymentId,
      vendorId: id,
    });

    if (token) {
      paymentActions
        .fetchEmailToVendorDetails({ token })
        .then((res) => {
          const payment = res.payload?.payment;
          const deliveryMethod = payment?.deliveryMethod;
          const paymentStatus = payment?.status;
          const organizationId = payment?.organization?.id;
          const vendorEmail = payment?.vendor?.contactEmail;
          const riskStatus = payment?.riskStatus;

          const paymentStatusProps =
            paymentStatusPropsMap[paymentStatus] || paymentStatusPropsMap.fallback;

          const commonAnalyticProps = {
            ...analytics.getExtraProperties(),
            organizationId,
            paymentId,
            vendorId: id,
            vendorEmail,
            siteConfig: analytics.siteConfig,
            ...paymentStatusProps.analyticProps,
          };

          if (deliveryMethod?.deliveryType === DELIVERY_TYPE.ACH) {
            setExtraAnalyticsProps(commonAnalyticProps, { flow: 'voided-check' });
            history.push(generatePath(locations.switchedToACH, { token, id }));
          } else if (deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
            setExtraAnalyticsProps(commonAnalyticProps, { flow: 'voided-check' });
            history.push(generatePath(selectExpedited.virtualCardSuccess, { token }));
          } else if (riskStatus === RiskStatusEnum.DECLINED) {
            setExtraAnalyticsProps(commonAnalyticProps);
            history.push(
              generatePath(paymentStatusPropsMap[PAYMENT_STATUS.BLOCKED]?.path, { token, id })
            );
          } else if (
            isShiftToAch &&
            (paymentStatus === PAYMENT_STATUS.SCHEDULED || paymentStatus === PAYMENT_STATUS.BLOCKED)
          ) {
            setExtraAnalyticsProps(commonAnalyticProps, { flow: 'vendor' });
            const isVirtualCardSupported = isEligibleToReceiveVirtualCard(payment.fundingSource);

            if (isVirtualCardSupported && checkToAchVCFeatureFlag) {
              history.push(generatePath(selectExpedited.shiftCheckDeliveryMethod, { token }));
            } else {
              history.push(
                generatePath(shiftToAchLocations.index, { token, id: payment.deliveryMethod.id })
              );
            }
          } else if (
            isEditCheckDetailsClicked &&
            (riskStatus === RiskStatusEnum.PENDING || riskStatus === RiskStatusEnum.APPROVED) &&
            (paymentStatus === PAYMENT_STATUS.BLOCKED || paymentStatus === PAYMENT_STATUS.SCHEDULED)
          ) {
            setExtraAnalyticsProps(commonAnalyticProps);
            history.push(generatePath(locations.checkNotSentChangeAddress, { token, id }));
          } else {
            setExtraAnalyticsProps(commonAnalyticProps);
            history.push(generatePath(paymentStatusProps.path, { token, id }));
          }
        })
        .catch((err) => {
          if (err?.error?.code === 'PMT17') {
            setExtraAnalyticsProps({}, { origin: 'check-cancelled' });

            history.push(generatePath(locations.paymentCancelled, { token, id }));
          } else {
            history.push(generatePath(paymentStatusPropsMap.fallback.path, { token }));
          }
        });
    }

    return undefined;
  }, [token, history, id, paymentActions]);

  const setExtraAnalyticsProps = (commonProps, extraProps = {}) => {
    analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
      ...commonProps,
      ...extraProps,
    });
  };

  return undefined;
}
