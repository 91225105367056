import { put, all, call, select } from 'redux-saga/effects';
import paymentApi from 'src/app/services/api/payments';
import { loggingApi } from 'src/app/version-2/api/loggers';
import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import { PAY_BILL_EVENT_PAGE } from 'src/app/pages/bill/pay/consts';
import { FastFeeDeliveryEnum } from 'src/app/version-2/model/enums/fastFeeDelivery.enum';
import { DeliveryEnum } from 'src/app/version-2/model/enums/delivery.enum';

import type { CancelAndRetryPaymentType } from '../types';
import { cancelAndRetryPaymentSuccessAction, cancelAndRetryPaymentErrorAction } from '../actions';
import { getBill, getPayment } from '../selectors';
import { getOrgId } from '../../user/selectors';

export function* cancelAndRetryPaymentHandler({ resolve, reject }: CancelAndRetryPaymentType) {
  const [payment, orgId, bill] = yield all([select(getPayment), select(getOrgId), select(getBill)]);
  const shouldUpdateDeliveryPreferense =
    payment.deliveryMethod.deliveryType.includes(DeliveryEnum.CHECK) &&
    payment.deliveryPreference?.includes(FastFeeDeliveryEnum.EXPRESS_CHECK);

  const analyticsProps = {
    billId: bill.id,
    partialBillId: getPartialBillId(bill),
    paymentId: payment.id,
  };

  analytics.track(PAY_BILL_EVENT_PAGE, 'cancel-and-retry-confirm', analyticsProps);

  try {
    if (payment) {
      yield call(paymentApi.cancelAndRetry, orgId, {
        id: payment.id,
        newDeliveryMethodId: payment.deliveryMethodId,
        deliveryPreference: shouldUpdateDeliveryPreferense ? payment.deliveryPreference : null,
      });
    }

    analytics.track(PAY_BILL_EVENT_PAGE, 'cancel-and-retry-success', analyticsProps);

    yield put(cancelAndRetryPaymentSuccessAction(payment));
    resolve(payment);
  } catch (e: any) {
    loggingApi.error('withPayBillData.cancelAndRetry(): void check confirm', e);
    yield put(cancelAndRetryPaymentErrorAction(e.code));
    reject(e);
  }
}
