import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from '@melio/billpay-design-system';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import { QuickPayment } from 'src/app/modules/payments/quickpay-slice';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import PayBillDatePage from 'src/app/pages/bill/pay/components/PayBillDatePage/PayBillDatePage';
import { getOrgId } from 'src/app/redux/user/selectors';
import deliveryApi from 'src/app/services/api/delivery';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { getMinScheduledDate } from 'src/app/utils/dates';
import { getConfirmBill, getDateFields } from './utils';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const EditDatePage = ({ onNext, onPrev, onExit }: Props) => {
  const orgId = useSelector(getOrgId);
  const [isLoading, setIsLoading] = useState(false);
  const payment: QuickPayment = useSelector(
    paymentStore.selectors.quickpay.newPaymentWizard.payment
  );
  const vendorId = payment?.vendorId;
  const { vendor } = useFetchVendor(vendorId);
  const paymentActions = useStoreActions(paymentStore);
  const {
    deliveryMethodId,
    fundingSourceId,
    totalAmount,
    scheduledDate,
    deliveryEta,
    maxDeliveryEta,
  } = payment;

  const [selectedDate, setSelectedDate] = useState({
    scheduledDate,
    deliveryEta,
    maxDeliveryEta,
    minScheduledDate: new Date(),
  });

  useEffect(() => {
    initialMinScheduledDate();
  }, [orgId, deliveryMethodId, fundingSourceId, totalAmount]);

  const initialMinScheduledDate = async () => {
    const minScheduledDate = await getMinScheduledDate({
      orgId,
      deliveryMethodId,
      fundingSourceId,
      totalAmount,
    });

    setSelectedDate({ ...selectedDate, minScheduledDate });
  };

  const onDateChanges = async (selectedScheduledDate: Date) => {
    try {
      const { deliveryDate, suggestedScheduledDate, maxDeliveryDate } =
        await deliveryApi.getDeliveryTime(
          orgId,
          selectedScheduledDate,
          Number.parseInt(deliveryMethodId as string, 10),
          fundingSourceId as unknown as number,
          totalAmount as number,
          payment?.id,
          payment?.payBillFlowUUID
        );

      setSelectedDate({
        ...getDateFields(suggestedScheduledDate, deliveryDate, maxDeliveryDate),
        minScheduledDate: selectedDate.minScheduledDate,
      });
    } catch {
      setIsLoading(false);
    }
  };

  const bill = getConfirmBill(payment, vendor);

  const handleOnNext = async () => {
    setIsLoading(true);
    const { scheduledDate, deliveryEta, maxDeliveryEta } = selectedDate;

    try {
      await paymentActions.quickpay.newPaymentWizard.update({
        scheduledDate,
        deliveryEta,
        maxDeliveryEta,
      });
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }

    onNext();
  };

  const deliveryMethodType =
    deliveryMethodId !== undefined
      ? bill.getDeliveryMethodById(deliveryMethodId)?.deliveryType
      : DELIVERY_TYPE.ACH;
  const datesLoaded = scheduledDate && deliveryEta && maxDeliveryEta;

  if (!datesLoaded) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    datesLoaded && (
      <PayBillDatePage
        bill={bill}
        scheduledDate={selectedDate.scheduledDate as Date}
        deliveryDate={selectedDate.deliveryEta as Date}
        maxDeliveryDate={selectedDate.maxDeliveryEta as Date}
        minScheduledDate={selectedDate.minScheduledDate as Date}
        deliveryMethodType={deliveryMethodType}
        onNext={handleOnNext}
        onPrev={onPrev}
        onChange={onDateChanges}
        goExit={onExit}
        isLoading={isLoading}
        headerLabel="qbr.edit.date.title"
        title="bills.pay.date.title"
      />
    )
  );
};

export default EditDatePage;
