import React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ROLE, TAG_VARIANT } from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';

type Props = {
  label: string;
  labelValues?: Record<string, any>;
  className?: string;
  variant: string;
  isSecondary?: boolean;
  testId?: string;
};

const MITag = ({ label, labelValues = {}, className, variant, isSecondary, testId }: Props) => (
  <TagContainer
    data-testid={testId}
    variant={variant}
    isSecondary={isSecondary}
    className={className}
  >
    <MIFormattedText label={label} values={labelValues} />
  </TagContainer>
);

export default MITag;

const tagColors = css`
  ${({ variant, theme, isSecondary }: any) => {
    switch (variant) {
      case TAG_VARIANT.ERROR:
        return `
          background-color: ${theme.colors.white.opaque};
          color: ${theme.colors.failure.opaque};
          border: 1px solid ${theme.colors.failure.opaque};

          @media ${devices.mobile}, ${devices.phablet} {
            background-color: ${theme.colors.failure.opaque};
            color: ${theme.colors.white.opaque};
          }
        `;
      case TAG_VARIANT.WARNING:
        return `
          background-color: ${
            isSecondary ? theme.colors.warning.translucent1 : theme.colors.warning.opaque
          };
          color: ${isSecondary ? theme.colors.warning.opaque : theme.colors.white.opaque};
          border: 1px solid ${theme.colors.warning.opaque};
        `;
      case TAG_VARIANT.DISABLED:
        return `
          background-color: ${
            isSecondary ? theme.colors.border.halfDarkGrey : theme.text.color.light
          };
          color: ${isSecondary ? theme.text.color.light : theme.colors.white.opaque};
          border: 1px solid ${theme.text.color.light};
        `;
      case TAG_VARIANT.EXPIRED:
        return `
          background-color: ${theme.colors.expired.translucent2};
          color: ${theme.colors.expired.opaque};
          border: 1px solid ${theme.colors.expired.opaque};
        `;
      case TAG_VARIANT.SUCCESS:
        return `
          background-color: ${
            isSecondary ? theme.colors.teal.translucent1 : theme.colors.teal.opaque
          };
          color: ${!isSecondary ? theme.colors.white.opaque : theme.colors.teal.opaque};
          border: 1px solid ${theme.colors.teal.opaque};
        `;
      case TAG_VARIANT.DEPOSITED:
        return `
          background-color: ${theme.colors.teal.translucent2};
          color: ${theme.colors.white.opaque};
        `;
      case TAG_VARIANT.ACH_DEPOSITED:
        return `
            background-color: ${theme.colors.teal.translucent2};
            color: ${theme.colors.white.opaque};
          `;
      case TAG_VARIANT.LIGHT:
        return `
          background-color: ${theme.colors.primary.translucent1};
          color: ${theme.colors.brand};
        `;
      case TAG_VARIANT.DARK:
        return `
          background-color: ${theme.colors.text};
          color: ${theme.colors.white.opaque};
          border: 1px solid ${theme.colors.text};
        `;
      case ROLE.CONTRIBUTOR:
        return `
          background-color: ${isSecondary ? theme.colors.primary.translucent2 : theme.colors.brand};
          color: ${isSecondary ? theme.colors.primary.opaque : theme.colors.white.opaque};
          border: 1px solid ${isSecondary ? theme.colors.primary.translucent2 : theme.colors.brand};
        `;
      case TAG_VARIANT.PARTIALLY_PAID:
        return `
          background-color: ${theme.text.color.darkGrey};
          color: ${theme.colors.white.opaque};
          border: 1px solid ${theme.text.color.darkGrey};
        `;
      case TAG_VARIANT.BRAND:
      default:
        return `
          background-color: ${
            isSecondary ? theme.colors.primary.opaque : theme.colors.white.opaque
          };
          color: ${isSecondary ? theme.colors.white.opaque : theme.colors.primary.opaque};
          border: 1px solid ${theme.colors.brand};
        `;
    }
  }}
`;

const TagContainer = styled.span<{ variant: string; isSecondary?: boolean }>`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 0 0.45rem;
  border-radius: 3px;
  text-align: center;
  background-clip: padding-box;
  font-size: 1.2rem;
  line-height: 1.6rem;
  ${tagColors}
`;
