export const BATCH_FOOTER_HEIGHT = '5rem';
export const ITEMS_PER_PAGE = 24;

export const enum QB_DASHBOARD_TAB_STATUS {
  UNPAID = 'unpaid',
  SCHEDULED = 'scheduled',
  PAID = 'paid',
}

export const enum QB_DASHBOARD_EMPTY_STATE_STATUS {
  SYNCING = 'syncing',
}

export type QB_DASHBOARD_EMPTY_STATE_TYPE =
  | QB_DASHBOARD_TAB_STATUS
  | QB_DASHBOARD_EMPTY_STATE_STATUS;

export const TABS = [
  QB_DASHBOARD_TAB_STATUS.UNPAID,
  QB_DASHBOARD_TAB_STATUS.SCHEDULED,
  QB_DASHBOARD_TAB_STATUS.PAID,
];

export const enum DASHBOARD_FILTER_ITEMS_NAMES {
  STATUSES_IN_DASHBOARD = 'statusesInDashboard',
  VENDORS = 'vendors',
  DUE_DATES = 'dueDates',
}

export const DASHBOARD_FILTER_ITEMS = [
  DASHBOARD_FILTER_ITEMS_NAMES.STATUSES_IN_DASHBOARD,
  DASHBOARD_FILTER_ITEMS_NAMES.VENDORS,
  DASHBOARD_FILTER_ITEMS_NAMES.DUE_DATES,
];

export const enum DASHBOARD_COLUMNS {
  VENDOR_NAME = 'vendorName',
  INVOICE_NUMBER = 'invoiceNumber',
  SCHEDULED_DATE = 'scheduledDate',
  STATUS = 'status',
  DELIVERY_ETA = 'deliveryEta',
  DUE_DATE = 'dueDate',
  FUNDING_TYPE = 'fundingType',
  DELIVERY_TYPE = 'deliveryType',
  TOTAL_AMOUNT = 'totalAmount',
  PAYMENT = 'payment',
  BILL_AMOUNT = 'totalAmount',
  PAYMENT_AMOUNT = 'amount',
  BALANCE = 'balance',
  ACTIONS = 'actions',
}

export enum UNPAID_DUE_DATE_OPTIONS {
  NEXT_SEVEN_DAYS = 'nextSevenDays',
  THIS_MONTH = 'thisMonth',
  NEXT_MONTH = 'nextMonth',
  PAST_DUE = 'pastDue',
}

export enum UNPAID_BILL_DASHBOARD_STASUSES {
  FAILED = 'failed',
  OVERDUE = 'overdue',
  DUE_IN_ONE_WEEK = 'dueInOneWeek',
  OPEN = 'open',
  PARTIALLY_PAID = 'partiallyPaid',
  DECLINED = 'declined',
  REFUNDED = 'refunded',
}

export enum SCHEDULED_BILL_DASHBOARD_STASUSES {
  PENDING_VENDOR_DETAILS = 'pendingVendorDetails',
  BANK_UNVERIFIED = 'bankUnverified',
  SCHEDULED = 'scheduled',
  PENDING = 'pending',
}

export enum PAID_BILL_DASHBOARD_STATUSES {
  DEPOSITED = 'deposited',
  MARKED_AS_PAID = 'markedAsPaid',
  SENT = 'sent',
  COMPLETED = 'completed',
}

export const enum SYNC_PAYMENT_INTENT {
  EDIT_PAYMENT = 'edit-payment',
  VIEW_PAYMENT = 'view-payment',
}

export const enum PAYMENT_FLOW_TYPE {
  PAY = 'pay',
  EDIT = 'edit',
}

export const enum DASHBOARD_ITEM_ACTIONS {
  SCHEDULE = 'schedule',
  MARK_AS_PAID = 'markAsPaid',
  MARK_AS_UNPAID = 'markAsUnpaid',
  RETRY = 'retry',
  VIEW = 'view',
  DELETE = 'delete',
  CANCEL = 'cancel',
  EDIT = 'edit',
  VERIFY_MD = 'verify-md',
  DELETE_BILL = 'delete-bill',
  EDIT_BILL = 'edit-bill',
}

export const enum DASHBOARD_CLOSE_ORIGIN {
  SWITCHER = 'switcher',
  CROSS = 'cross',
}
