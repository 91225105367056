import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SmartIcon, Box } from '@melio/billpay-design-system';
import errorIllustration from 'src/app/images/icons/errorToken.svg';
import styled from 'styled-components';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import canceledImage from 'src/app/images/qbo/failed-to-load-002-icon.png';
import expiredImage from 'src/app/images/qbo/no-payment-created-icon.png';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { UnilateralPaymentProps } from 'src/app/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import paymentStore from 'src/app/modules/payments/payment-store';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { MIFormattedText } from 'src/app/utils/formatting';
import intercomService from 'src/app/services/intercom';
import MILink from 'src/app/components/common/MILink';
import { EmailLink } from 'src/app/components/common/EmailLink';
import analytics from 'src/app/services/analytics';

type Props = UnilateralPaymentProps;

const InvalidAddDeliveryMethodPage = ({ token }: Props) => {
  const site = useSiteContext();
  const { paymentId } = getJWTPayload(token);
  const { errorData } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const { invalidTokenData } = useSelector(deliveryMethodsStore.selectors.validation) as {
    invalidTokenData: any;
  };
  const error = errorData || invalidTokenData;
  const isCanceled = errorData?.message === 'PAYMENT_CANCELED';
  let companyName = '';
  let email = '';

  useEffect(() => {
    if (paymentId) {
      analytics.trackAction('invalid-link-page-loaded', {
        paymentId,
      });
    }
  }, [paymentId]);

  if (error && error.data) {
    ({ companyName, email } = error.data);
  }

  const renderIcon = (error) => {
    switch (error?.code) {
      case 'PMT17':
        return expiredImage;
      case 'PMT18':
        return canceledImage;
      default:
        return errorIllustration;
    }
  };

  const renderErrorMessage = () => {
    const supportEmail = email || site.config.support.email;

    if (isCanceled) {
      return (
        <NewContent>
          <SmartIcon type="error" size="3.3rem" />
          <Box textStyle="ds.body1Semi" mt="ds.xl" color="ds.gray.100">
            <MIFormattedText label="vendors.deliveryMethods.errors.canceled.title" />
          </Box>

          {companyName && supportEmail && (
            <Box textStyle="ds.body1" mt="ds.md" color="ds.gray.100">
              <MIFormattedText
                label="vendors.deliveryMethods.errors.canceled.subtitle"
                values={{
                  companyName,
                  supportEmail: (
                    <CustomEmailLink href={`mailto:${supportEmail}`}>
                      {supportEmail}
                    </CustomEmailLink>
                  ),
                }}
              />
            </Box>
          )}
        </NewContent>
      );
    }

    return (
      <Content>
        <Illustration src={renderIcon(error)} alt="error" />
        <SubTitle>
          {error && email && companyName ? (
            <MIFormattedText
              label={`server.${error.code}`}
              values={{
                companyName,
                email: <EmailLink email={email} />,
                supportEmail: <EmailLink email={site.config.support.email} />,
              }}
            />
          ) : (
            <>
              <MIFormattedText label="vendors.deliveryMethods.ach.errors.invalidTokenTitle" />
              <MIFormattedText label="vendors.deliveryMethods.ach.errors.supportText" />
              <ChatLink
                to={() => intercomService.show()}
                label="vendors.deliveryMethods.ach.errors.supportLink"
              />
            </>
          )}
        </SubTitle>
      </Content>
    );
  };

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      {renderErrorMessage()}
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 4rem;
`;
const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.text.color.link};
  font-weight: ${(props) => props.theme.text.weight.regular};

  &:active {
    color: ${(props) => props.theme.text.color.link};
  }

  &:hover {
    color: ${(props) => props.theme.text.color.link};
  }
`;

const NewContent = styled(Content)`
  border: 1px solid ${(props) => props.theme.colors.ds.gray[500]};
  border-radius: 5px;
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;
const Illustration = styled(QBLogo)`
  width: 12rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-align: center;
`;
const CustomEmailLink = styled.a`
  text-decoration: none;
  color: rgba(28, 130, 210);
`;

export default InvalidAddDeliveryMethodPage;
