import React from 'react';
import Box from 'src/app/core/ds/box';
import { HStack } from 'src/app/core/ds/stack';
import PaginationPrevious from './components/PaginationPrevious';
import PaginationNext from './components/PaginationNext';
import { getPaginationLabelRange } from './utils';

type Props = {
  pageIndex: number;
  totalItems?: number;
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage?: boolean;
  canNextPage?: boolean;
  pageItemCount?: number;
};

const Pagination = ({
  pageIndex,
  nextPage,
  previousPage,
  totalItems,
  canNextPage,
  canPreviousPage,
  pageItemCount,
}: Props) => {
  const labelDisplayRange = getPaginationLabelRange(pageIndex, totalItems, pageItemCount);

  return (
    <HStack spacing={3}>
      <Box textStyle="body10">{labelDisplayRange}</Box>
      <PaginationPrevious previousPage={previousPage} canPreviousPage={canPreviousPage} />
      <PaginationNext nextPage={nextPage} canNextPage={canNextPage} />
    </HStack>
  );
};

export default Pagination;
