import { FullOrgSyncStatusEnum } from 'src/app/version-2/model/enums';

export const name = 'fullOrgSync';

export type State = {
  status: FullOrgSyncStatusEnum;
};

export type CheckFullOrgSyncSuccessActionPayload = {
  status: FullOrgSyncStatusEnum;
  lastCompletionTime: string;
};
