import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import { WizardSelectField } from 'src/app/ui/form/WizardSelectField';
import { getCurrencyMask, getNumberMask } from 'src/app/utils/currency-utils';
import { MIFormattedText } from 'src/app/utils/formatting';
import { SelectVendor } from 'src/app/components/common/SelectVendor';
import { BillFrequencyEnum } from 'src/app/version-2/model/enums';
import { StyledRow, StyledForm, StyledLoader, StyledCol } from './BillModalForm.styles';
import { BillModalDatePicker } from '../BillModalDatePicker';
import { Frequency } from '../components/Frequency';

import { getRecurringDateNotice } from '../utils';
import { OrganizationPreferencesType } from 'src/app/utils/types';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';

export const BillModalForm = ({
  isEditBillMode,
  vendorOptions,
  billModalMV,
  categoryOptions,
  isLoading,
}) => {
  const [showRecurring] = featureFlags.useFeature('qb-recurring-payments');
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const isFirstWave = !!organizationPreferences?.billPayFirstWaveUser;
  const frequency = billModalMV?.frequency?.value;
  const hasRecurringFrequency = frequency !== BillFrequencyEnum.ONE_TIME;
  const isRecurringVisible = (showRecurring && !isEditBillMode) as boolean;

  const DueDateInput = ({ placement }) => {
    let label = 'paymentDashboard.addBillToDashboard.billModal.titles.dueDateInput';
    let placeholder = 'paymentDashboard.addBillToDashboard.billModal.placeholders.dueDateInput';
    let dateFormat;
    let notices;

    if (hasRecurringFrequency) {
      const dueDate = billModalMV?.dueDate?.value;
      const { label: noticeLabel, specificDay: noticeSpecificDay } = getRecurringDateNotice({
        frequency,
        dueDate,
      });

      label = 'bills.form.recurring.dueDate';
      placeholder = 'bills.form.recurring.dueDatePlaceholder';
      dateFormat = 'EEEE, MMM d, y';

      if (dueDate)
        notices = (
          <MIFormattedText label={noticeLabel} values={{ specificDay: noticeSpecificDay }} />
        );
    }

    return (
      <BillModalDatePicker
        label={label}
        placeholder={placeholder}
        model={billModalMV.dueDate}
        date={billModalMV.dueDate.value ? new Date(billModalMV.dueDate.value) : undefined}
        required
        disabled={isLoading}
        notices={[notices]}
        dateFormat={dateFormat}
        placement={placement}
        hasRecurringFrequency={hasRecurringFrequency}
        isFirstWave={isFirstWave}
      />
    );
  };

  return (
    <StyledForm $hasScroll={isRecurringVisible}>
      {isLoading && <StyledLoader />}
      <StyledRow mb="1rem">
        <SelectVendor model={billModalMV.vendorId} initialVendorOptions={vendorOptions} />
      </StyledRow>
      <StyledRow mb="1rem">
        <WizardSelectField
          label="paymentDashboard.addBillToDashboard.billModal.titles.categoryInputSelect"
          placeholder="paymentDashboard.addBillToDashboard.billModal.placeholders.categoryInputSelect"
          options={categoryOptions}
          model={billModalMV.intuitAccountId}
          disabled={isLoading}
        />
      </StyledRow>
      <StyledRow mb="2rem">
        <InlineTextInputField
          label="paymentDashboard.addBillToDashboard.billModal.titles.amountInput"
          placeholder="paymentDashboard.addBillToDashboard.billModal.placeholders.amountInput"
          model={billModalMV.totalAmount}
          mask={getCurrencyMask()}
          required
          privateData
          disabled={isLoading}
        />
      </StyledRow>

      {/* recurring radio buttons */}
      {isRecurringVisible && <Frequency model={billModalMV.frequency} />}

      <StyledRow>
        <StyledCol>
          <InlineTextInputField
            label="paymentDashboard.addBillToDashboard.billModal.titles.billNumberInput"
            placeholder="paymentDashboard.addBillToDashboard.billModal.placeholders.billNumberInput"
            model={billModalMV.invoiceNumber}
            type="text"
            disabled={isLoading}
          />
        </StyledCol>
        <StyledCol>
          {hasRecurringFrequency ? (
            <InlineTextInputField
              label="bills.form.recurring.occurrences"
              placeholder="bills.form.recurring.occurrencesPlaceHolder"
              model={billModalMV.occurrences}
              type="tel"
              disabled={isLoading}
              required
              mask={getNumberMask()}
              notices={
                frequency === BillFrequencyEnum.MONTHLY
                  ? ['bills.form.recurring.occurrencesHintForMonthly']
                  : ['bills.form.recurring.occurrencesHintForWeekly']
              }
            />
          ) : (
            <DueDateInput placement={isRecurringVisible ? 'top-end' : 'top'} />
          )}
        </StyledCol>
      </StyledRow>

      {hasRecurringFrequency && (
        <StyledRow $fullWidth>
          <DueDateInput placement="top-start" />
        </StyledRow>
      )}

      <StyledRow>
        <InlineTextInputField
          label="paymentDashboard.addBillToDashboard.billModal.titles.memoInput"
          placeholder="paymentDashboard.addBillToDashboard.billModal.placeholders.memoInput"
          model={billModalMV.note}
          disabled={isLoading}
        />
      </StyledRow>
    </StyledForm>
  );
};
