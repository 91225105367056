import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/app/hoc/useApi';
import billsApi from 'src/app/services/api/bills';
import { BillType } from 'src/app/utils/types';
import { getProfile } from 'src/app/redux/user/selectors';

type ReturnType = { billDetails?: BillType; error?: any; loading: boolean };

export const useBillDetails = (billId: string): ReturnType => {
  const { orgId } = useSelector(getProfile);
  const [loadBill, billResult, loading, error] = useApi<
    [{ orgId: string; id: string }],
    { object: BillType }
  >(billsApi.getBillById, false);

  useEffect(() => {
    loadBill({
      orgId,
      id: billId,
    });
  }, [billId, orgId, loadBill]);

  return {
    billDetails: billResult?.object,
    loading,
    error,
  };
};
