import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import { Badge, Box, Link } from '@melio/billpay-design-system';
import { RecordOf } from 'immutable';
import moment from 'moment';
import {
  DELIVERY_TYPE,
  PAYMENT_APPROVAL_STATUS,
  HOLIDAYS_WARNING_ICON_VARIANT,
} from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { isSameDay, DeliveryDate } from 'src/app/utils/dates';
import { isFirstPaymentLate } from 'src/app/utils/bills';
import {
  isFastDeliveryType,
  getIsNeedToShowACHLatePaymentNotification,
  isLateDeliveryType,
} from 'src/app/utils/delivery-methods';
import { BillType, DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentTextContainer,
  PaymentMethodText,
  EditElement,
} from 'src/app/components/layout/PaymentActivityElements';
import { getPaymentById, isUndepositedPayment } from 'src/app/utils/payments';
import { getDeliveryOptions } from 'src/app/redux/payBillWizard/selectors';
import { HolidaysWarning } from 'src/app/components/common/HolidaysWarning';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FirstPaymentLateTooltip } from 'src/app/pages/bill/pay/components/FirstPaymentLateTooltip';
import analytics from 'src/app/services/analytics';
import DeliveryDateInfoHint from '../DeliveryDateInfoHint';
import Tag from '../Tag';
import {
  PaymentActivityContainer,
  HolidaysWarningText,
  DeliveryDateContainer,
  LatePaymentNotificationText,
  LatePaymentNotificationContainer,
  WarningIcon,
} from './PayBillPaymentActivityDeliveryDateInfo.styles';

type Props = {
  payment: RecordOf<PaymentType>;
  bill: RecordOf<BillType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: FundingSource;
  onEdit?: VoidFunction | null;
  onEditDeductionDate: VoidFunction;
};

export const PayBillPaymentActivityDeliveryDateInfo = ({
  payment,
  bill,
  deliveryMethod,
  onEdit,
  fundingSource,
  onEditDeductionDate,
}: Props) => {
  const deliveryOptions = useSelector(getDeliveryOptions);
  const bills = payment.bills?.length ? payment.bills : [bill];
  const paymentDeliveryETA = payment.deliveryEta;
  const deliveryPreference = payment.deliveryPreference || '';
  const paymentStatus = payment.status;

  const isNeedToShowACHLatePaymentNotification = useMemo(
    () =>
      getIsNeedToShowACHLatePaymentNotification({
        deliveryPreference,
        deliveryOptions,
        bills,
        paymentDeliveryETA,
        paymentStatus,
      }),
    [paymentDeliveryETA, deliveryOptions, bills, deliveryPreference, paymentStatus]
  );

  const [showHolidaysWarningLD] = featureFlags.useFeature('us-holidays-checks');
  const isRecurringBill = !!bill.recurringBill;
  const isApprovalRequired = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL;
  const isInternational = deliveryMethod.deliveryType === DELIVERY_TYPE.INTERNATIONAL;
  const isRPPSDeliveryMethod = deliveryMethod.deliveryType === DELIVERY_TYPE.RPPS;
  const isDeliveryMethodCheck = deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK;
  const isSameDayDelivery = isSameDay(payment.deliveryEta);
  const isFast = isFastDeliveryType(payment.deliveryPreference);
  const originPayment = getPaymentById(bill.payments, payment.id);
  const isUpdated =
    originPayment &&
    (payment?.metadata?.failedType || isUndepositedPayment(payment)) &&
    moment(payment?.deliveryEta).diff(moment(originPayment?.deliveryEta), 'days') !== 0;
  const shouldShowHint = !isInternational;

  let label = 'payBillPaymentActivity.deliveryDate.deliveryEtaLabel';

  if (isMicrodeposit || isUnilateral || isApprovalRequired) {
    label = 'payBillPaymentActivity.deliveryDate.estimatedDeliveryEtaLabel';
  } else if (isRecurringBill) {
    label = 'payBillPaymentActivity.deliveryDate.recurringLabel';
  }

  const showHolidaysWarning = showHolidaysWarningLD && !isFast && isDeliveryMethodCheck;
  const firstPaymentLate = isRecurringBill && isFirstPaymentLate({ bill, payment });
  const showFirstPaymentLateTooltip = firstPaymentLate && (isMicrodeposit || isApprovalRequired);

  const handleReceivePaymentFaster = () => {
    analytics.track('pay-bill-confirm', 'experimental-edit-cta-click', {
      billId: bill.id,
      paymentId: payment.id,
      fundingSourceId: fundingSource.id,
      isFast: isFastDeliveryType(payment.deliveryPreference),
      isLate: isLateDeliveryType({
        paymentDeliveryETA,
        bills,
      }),
      deliveryEta: paymentDeliveryETA,
    });

    onEditDeductionDate();
  };

  return (
    <PaymentActivityContainer isRPPSDeliveryMethod={isRPPSDeliveryMethod}>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <i className="icon-eta-cal" />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={label} />
            </PaymentMethodText>
            <DeliveryDateContainer>
              {isSameDayDelivery && (
                <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />
              )}
              <DeliveryDate date={payment.deliveryEta} maxDate={payment.maxDeliveryEta} />
              {showFirstPaymentLateTooltip && <FirstPaymentLateTooltip />}
              {isFast && (
                <Badge variant="primaryOutlined">
                  <MIFormattedText label="bills.pay.date.deliveryOptions.price.fastTag" />
                </Badge>
              )}
              {showHolidaysWarning && (
                <HolidaysWarning
                  variant={HOLIDAYS_WARNING_ICON_VARIANT.WARNING}
                  customIconStyles={{ position: 'relative', top: '0.1rem', gridGap: '0.2rem' }}
                />
              )}
              {isUpdated && <Tag label="payBillPaymentActivity.updated" />}
            </DeliveryDateContainer>
            {showHolidaysWarning && (
              <HolidaysWarningText>
                <MIFormattedText label="holidaysWarning.tooltipLabels.scheduled" />
              </HolidaysWarningText>
            )}
          </PaymentTextContainer>
        </PaymentInfoContainer>
        <EditElement onEdit={onEdit} />
      </PaymentActivityMainContainer>
      {shouldShowHint && (
        <DeliveryDateInfoHint
          bill={bill}
          payment={payment}
          deliveryMethod={deliveryMethod}
          fundingSource={fundingSource}
        />
      )}
      {isNeedToShowACHLatePaymentNotification && onEditDeductionDate && (
        <LatePaymentNotificationContainer>
          <WarningIcon />
          <LatePaymentNotificationText>
            <MIFormattedText
              label="viewBillPaymentActivity.deliveryDate.fastACHPaymentLateNotification"
              values={{
                SendItFaster: (...chunks) => (
                  <Box as={Link} onClick={handleReceivePaymentFaster}>
                    {React.Children.toArray(chunks)}
                  </Box>
                ),
              }}
              testId="fast-ach-late-payment-notification"
            />
          </LatePaymentNotificationText>
        </LatePaymentNotificationContainer>
      )}
    </PaymentActivityContainer>
  );
};
