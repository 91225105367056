import NumberFormat from 'react-number-format';
import { InputProps as ChakraInputProps, Input as ChakraInput } from '@chakra-ui/react';

import { FieldWrapper } from '../FieldWrapper';
import { FieldWrapperType } from '../../types';
import { Text, Flex, SmartIcon } from '../..';

export type CurrencyFieldProps = FieldWrapperType &
  Pick<ChakraInputProps, 'placeholder'> & {
    prefix?: string;
    showIcon?: boolean;
    value?: string | number;
    onChange?: (values: any) => void;
  };

const CurrencyField = ({
  // field wrapper props
  label,
  helperText,
  errorMessage,
  tooltipLabel,
  placeholder = '$0.00',
  showIcon = true,
  value,
  onChange,

  // mask props
  prefix = '$',
  ...rest
}: CurrencyFieldProps) => {
  const { isDisabled } = rest;

  const hasIcon = showIcon && prefix === '$';
  const iconWidth = '6rem';

  const rightAddon = (() => {
    if (hasIcon) {
      return (
        <Flex gap="ds.sm" align="center">
          <SmartIcon type="usd" width="2.4rem" height="1.8rem" />
          <Text textStyle="ds.body1">USD</Text>
        </Flex>
      );
    }

    return undefined;
  })();

  const input = (id) => (
    <NumberFormat
      id={id}
      customInput={ChakraInput}
      prefix={prefix}
      thousandSeparator
      pr={hasIcon ? iconWidth : 0}
      isInvalid={!!errorMessage}
      onValueChange={onChange}
      placeholder={placeholder}
      value={value}
      {...rest}
    />
  );

  return (
    <FieldWrapper
      input={input}
      label={label}
      helperText={helperText}
      errorMessage={errorMessage}
      tooltipLabel={tooltipLabel}
      isDisabled={isDisabled}
      rightAddon={rightAddon}
      rightAddonWidth={iconWidth}
    />
  );
};

export { CurrencyField };
