import React from 'react';
import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import locations from './locations';
import { SinglePayBillLoader } from './entrypoint/loaders/SinglePayBillLoader';
import { DashboardLoader } from './entrypoint/loaders/DashboardLoader';

import { PayContractorsErrorPage } from './entrypoint/errors/PayContractorsErrorPage';
import { DirectPaymentErrorPage } from './entrypoint/errors/DirectPaymentErrorPage';
import { BillNotFoundErrorPage } from './entrypoint/errors/BillNotFoundErrorPage';
import { BillSyncErrorPage } from './entrypoint/errors/BillSyncErrorPage';
import { BillAlreadyScheduledPage } from './entrypoint/errors/BillAlreadyScheduledPage';
import { BillBalanceZeroPage } from './entrypoint/errors/BillBalanceZeroPage';
import { BillAmountLimit } from './entrypoint/errors/BillAmountLimit';

export const EntrypointsRouter = () => {
  const site = useSiteContext();

  return (
    <Switch>
      <SmartRoute path={locations.loaders.pay} exact allowFor="authenticated">
        <SinglePayBillLoader />
      </SmartRoute>

      <SmartRoute path={locations.loaders.dashboard} exact allowFor="authenticated">
        <DashboardLoader />
      </SmartRoute>

      <SmartRoute path={locations.errors.contractors} exact allowFor="authenticated">
        <PayContractorsErrorPage />
      </SmartRoute>
      <SmartRoute path={locations.errors.directPayments} exact allowFor="authenticated">
        <DirectPaymentErrorPage />
      </SmartRoute>
      <SmartRoute path={locations.errors.orgBlockedByRisk} exact>
        <site.components.BlockedUserPageContainer />
      </SmartRoute>
      <SmartRoute path={locations.errors.billNotFound} exact>
        <BillNotFoundErrorPage />
      </SmartRoute>
      <SmartRoute path={locations.errors.billSyncError} exact>
        <BillSyncErrorPage />
      </SmartRoute>
      <SmartRoute path={locations.errors.billAlreadyScheduled} exact>
        <BillAlreadyScheduledPage />
      </SmartRoute>
      <SmartRoute path={locations.errors.billZeroBalance} exact>
        <BillBalanceZeroPage />
      </SmartRoute>
      <SmartRoute path={locations.errors.billAmountLimit} exact>
        <BillAmountLimit />
      </SmartRoute>
      <SmartRoute path={locations.errors.batchBillAmountLimit} exact>
        <BillAmountLimit isBatch />
      </SmartRoute>
    </Switch>
  );
};
