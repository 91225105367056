import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NotificationCard, NotificationTypeEnum } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { getTableWidth } from 'src/app/version-2/pages/batch-bulk/utils/tableWidth.utils';
import { getUTCDate } from 'src/app/version-2/utils/dates.utils';

import { isAfter } from 'date-fns';
import styled, { css } from 'styled-components';

/**
 * Shows notification after applied fee in confirm page
 */
type Props = {
  fee: React.ReactNode | string | null;
  isBatch?: boolean;
};
const NotificationAppliedFee = ({ fee, isBatch }: Props) => {
  const { formatMessage } = useIntl();
  const [limitModalDate]: any = featureFlags.useFeature(FeatureFlagsEnum.CHECK_FEES_LIMIT_DATE);
  const dispatch = useDispatch();
  const orgId = useSelector(userSliceSelectors.selectOrgId);
  const organization = useSelector(userSliceSelectors.selectOrgById(orgId));

  const limitDate = !!limitModalDate && new Date(limitModalDate); // utc already from ff
  const createdDate = getUTCDate(organization?.createdAt); // org created date
  const isAfterLimitDate = createdDate && limitDate && isAfter(createdDate, limitDate);

  const goLearnMore = () => {
    dispatch(applicationActions.setFaqPanel({ isOpen: true, focusedQuestion: 19 }));
  };

  let values: Record<string, any> = {
    learnMoreLink: (...chunks) => (
      <Link onClick={goLearnMore} isExternal>
        {React.Children.toArray(chunks)}
      </Link>
    ),
    fee,
  };

  let text = {
    title: 'deliveryMethods.notDefinedInfo.check.appliedFee.notification.before.title',
    body: 'deliveryMethods.notDefinedInfo.check.appliedFee.notification.before.body',
  };

  if (isAfterLimitDate) {
    text = {
      title: 'deliveryMethods.notDefinedInfo.check.appliedFee.notification.after.title',
      body: 'deliveryMethods.notDefinedInfo.check.appliedFee.notification.after.body',
    };

    values = { fee };
  }

  return (
    <StyledCard
      $isBatch={isBatch}
      type={NotificationTypeEnum.INFO}
      title={formatMessage({ id: text.title })}
      subtitle={formatMessage({ id: text.body }, values)}
    />
  );
};

const StyledCard = styled(NotificationCard)<{ $isBatch?: boolean }>`
  margin: 0 0.2rem 2rem;

  ${(props) =>
    props.$isBatch &&
    css`
      margin: 1rem auto 0;
      width: 100%;
      max-width: ${getTableWidth()};

      i {
        font-size: 1.85rem;
        margin-left: 0.4rem;
      }
    `}

  .notification-title {
    padding-bottom: 0.4rem;
  }

  a {
    cursor: pointer;
  }
`;

export { NotificationAppliedFee };
