import React from 'react';
import { Flex, SmartIcon, Text } from '@melio/billpay-design-system';

export const PaymentActivityContainer: React.FC<{
  isRPPSDeliveryMethod?: boolean;
}> = ({ children, isRPPSDeliveryMethod }) => (
  <Flex
    direction="column"
    pt="ds.xl"
    pb={isRPPSDeliveryMethod ? '0' : 'ds.xl'}
    px="0"
    borderBottom={isRPPSDeliveryMethod ? 'none' : '0.1rem solid'}
    borderColor="ds.gray.700"
  >
    {children}
  </Flex>
);

export const HolidaysWarningText: React.FC = ({ children }) => (
  <Text textStyle="ds.body3" color="ds.color.300" as="span">
    {children}
  </Text>
);

export const DeliveryDateContainer: React.FC = ({ children }) => (
  <Flex align="center" gap="ds.sm" textStyle="ds.body1">
    {children}
  </Flex>
);

export const LatePaymentNotificationContainer: React.FC = ({ children }) => (
  <Flex gap="ds.md" ml="5.3rem" mt="ds.sm" align="center">
    {children}
  </Flex>
);

export const LatePaymentNotificationText: React.FC = ({ children }) => (
  <Text textStyle="ds.body3" m="0" color="ds.gray.200">
    {children}
  </Text>
);

export const WarningIcon = () => <SmartIcon type="yellow-info" w="ds.lg" h="ds.lg" />;
