import { Box } from '@melio/billpay-design-system';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import { FAST_DELIVERY_TYPES } from 'src/app/utils/consts';

interface Props {
  deliveryOptionType: string;
  feeAmount: number;
}

const PaymentDeliveryOptionPrice = ({ deliveryOptionType, feeAmount }: Props) => {
  const isCheckDeliveryOption = deliveryOptionType.includes('check');
  const isFastDeliveryOption = FAST_DELIVERY_TYPES.includes(deliveryOptionType);

  let label;
  let values = {};

  if (isCheckDeliveryOption && isFastDeliveryOption && feeAmount) {
    // fast Check option with amount
    label = 'bills.pay.date.deliveryOptions.price.dollarFedExCheck'; // 'via FedEx ({fee})',
    values = { fee: <MIFormattedCurrency value={feeAmount} /> };
  } else if (isCheckDeliveryOption && feeAmount) {
    // regular Check option with amount
    label = 'bills.pay.date.deliveryOptions.price.dollarUSPSCheck'; // 'via USPS ({fee})',
    values = { fee: <MIFormattedCurrency value={feeAmount} /> };
  } else if (isCheckDeliveryOption) {
    // fast Check option with no amount (won't happen)
    // or regular Check option with no amount
    label = 'bills.pay.date.deliveryOptions.price.defaultCheck'; // 'via USPS (Free)',
  } else if (isFastDeliveryOption && feeAmount) {
    // fast ACH option with amount
    label = 'bills.pay.date.deliveryOptions.price.dollar'; // '({fee})'
    values = { fee: <MIFormattedCurrency value={feeAmount} /> };
  } else {
    // fast ACH option with no amount
    // or non-fast non-check options
    label = 'bills.pay.date.deliveryOptions.price.default'; // '(Free)'
  }

  return (
    <Box as="span">
      <MIFormattedText label={label} values={values} />
    </Box>
  );
};

export { PaymentDeliveryOptionPrice };
