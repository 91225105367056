import { devices } from 'src/app/version-2/utils/devices.utils';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: ${({ theme }) => theme.space.ds.xl};
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  ${({ theme }) => theme.textStyles.ds.body1};

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: ${({ theme }) => theme.space.ds.lg};
  }
`;

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space.ds.xl};

  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;
    gap: 0.4rem;
  }
`;

export const TermsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1.4rem;
`;
