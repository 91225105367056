import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RecordOf } from 'immutable';
import locations from 'src/app/utils/locations';
import globalLocations from 'src/app/pages/locations';
import { UserContextType, NavigateType, BillType } from 'src/app/utils/types';
import { withNavigator } from 'src/app/hoc';
import accountingSoftwareSync from 'src/app/services/api/accountingSoftwareSync';
import { GlobalState } from 'src/app/redux/types';
import { getProfile, getFundingSources } from 'src/app/redux/user/selectors';
import analytics from 'src/app/services/analytics';
import batchBillsStore from 'src/app/modules/batch-bills/batch-bills-store';
import { BatchFetchBillResponse } from 'src/app/services/api/bills';
import { withPartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { hasValidFundingSources } from 'src/app/utils/funding-source-utils';
import { ProgressPage } from '../ProgressPage';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN, CARD_TYPES } from 'src/app/utils/consts';
import {
  FeatureFlagsEnum,
  EntrypointEventPageEnum,
  FundingSourceTypesEnum,
} from 'src/app/version-2/model/enums';
import { EventNameEnum } from 'src/app/version-2/pages/funding-source/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  landingPageActions,
  landingPageSelectors,
} from 'src/app/version-2/pages/landing-pages/modules/landingPage.slice';
import { getQBCashForAccount } from 'src/app/version-2/utils/paymentMethods.utils';
import { loggingApi } from 'src/app/version-2/api/loggers';
import {
  getFeatureFlagStatus,
  getLandingPageFeatureFlagStatus,
} from 'src/app/version-2/utils/featureFlags.utils';

type MapStateToProps = {
  profile: RecordOf<UserContextType>;
  fundingSources: FundingSource[];
  selectedPlan?: string;
  isNewLpEntry?: boolean;
};

type MapDispatchToProps = {
  clearSelectedPlan: () => Promise<any>;
  setNewLpForUser: () => Promise<any>;
  getBillListByIds: (params: {
    orgId: string;
    isByVendor?: boolean;
    billIds?: string[];
    vendorOriginId?: number;
  }) => Promise<any>;
};

type Props = {
  id: string;
  ids: string;
  redirectPath: string;
  navigate: NavigateType;
  query: {
    id?: string;
  };
  isPartialPaymentsEnabled: boolean;
} & MapStateToProps &
  MapDispatchToProps;

type SyncError = {
  message: string;
  code?: string;
  data?: Record<string, any>;
};

type State = {
  bill?: BillType;
  selectedPlan?: string;
  isNewLpEntry?: boolean;
  shouldCloseIframe: boolean;
  error: SyncError | null;
};
const SUCCESS_RESPONSE_CODE = 'OK';

enum EntryType {
  VENDOR_BATCH = 'VENDOR_BATCH',
  BATCH = 'BATCH',
  SINGLE = 'SINGLE',
}

const getEntryType = (billIds: string[], isByVendor: boolean) => {
  if (isByVendor) return EntryType.VENDOR_BATCH;

  return billIds.length > 1 ? EntryType.BATCH : EntryType.SINGLE;
};

class PayBillQuickbooksId extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      bill: undefined,
      shouldCloseIframe: false,
    };
  }

  componentDidMount() {
    const { profile, id, ids, vendorOriginId, isByVendor, navigate, redirectPath } = this
      .props as any;

    if (!profile.orgId) return;

    if (redirectPath) {
      navigate(redirectPath, true, { name: 'initialState' });

      return;
    }

    const billIds = ids?.split(',') || [id];
    const entryType = getEntryType(billIds, vendorOriginId && isByVendor);

    this.syncAndGetBills(entryType, profile.orgId, billIds, vendorOriginId)
      .then(this.onBillsSyncSuccess)
      .catch(this.onErrorOccured);
  }

  plansLandingPageEnabled = getLandingPageFeatureFlagStatus({
    origin: 'PayBillQuickbooksId',
    orgId: this.props?.profile?.orgId,
  });

  isFundingSourceExperiment = getFeatureFlagStatus(FeatureFlagsEnum.FUNDING_SOURCE_EXPERIMENT);

  syncAndGetBills = async (
    entryType: EntryType,
    orgId: string,
    billIds: string[],
    vendorOriginId?: number
  ): Promise<{ billIds?: string[]; bill?: BillType }> => {
    const { getBillListByIds } = this.props;

    if (entryType === EntryType.VENDOR_BATCH) {
      const res = await accountingSoftwareSync.syncVendorAndBillsByOriginId(orgId, vendorOriginId);

      if (res.code !== SUCCESS_RESPONSE_CODE) {
        throw new Error('failed to sync vendor and bills');
      }

      const batchFetchBillResponse: BatchFetchBillResponse = await getBillListByIds({
        orgId,
        isByVendor: true,
        billIds,
        vendorOriginId,
      });
      const billsKeys = Object.keys(batchFetchBillResponse.payload.billList);

      return billsKeys.length > 1
        ? { billIds: billsKeys }
        : {
            bill: Object.values(batchFetchBillResponse.payload.billList)[0].bill,
          };
    }

    if (entryType === EntryType.BATCH) {
      const res = await accountingSoftwareSync.syncMultipleBillsByOriginId(orgId, billIds);

      if (res.code !== SUCCESS_RESPONSE_CODE) {
        throw new Error('failed to sync bills for batch');
      }

      const billsKeys = res.bill;

      await getBillListByIds({ orgId, billIds: billsKeys });

      return { billIds: billsKeys };
    }

    const data = await accountingSoftwareSync.syncBillByOriginId(orgId, billIds[0]);

    return { bill: data?.bill };
  };

  onBillsSyncSuccess = async ({ billIds, bill }: { billIds?: string[]; bill?: BillType }) => {
    const { profile, selectedPlan, clearSelectedPlan, navigate, fundingSources, setNewLpForUser } =
      this.props;
    let redirectUrl;
    let isBatch = false;
    let billIdsForState: string[] | undefined;

    if (bill) {
      billIdsForState = [bill.id];

      redirectUrl = locations.Bills.pay[
        this.plansLandingPageEnabled && selectedPlan ? 'deliveryMethod' : 'funding'
      ].url({
        id: bill.id,
        orgId: profile.orgId,
      });
    } else {
      billIdsForState = billIds;
      isBatch = true;
      redirectUrl = locations.Bills.pay.batch.url({ ids: billIds, orgId: profile.orgId });
    }

    if (this.plansLandingPageEnabled && selectedPlan) {
      await clearSelectedPlan();
      let location = '';

      if (selectedPlan === FundingSourceTypesEnum.ACH) {
        const qbCash = getQBCashForAccount(fundingSources);

        location = locations.Onboarding.fundingSources.bank[
          this.isFundingSourceExperiment && qbCash ? 'type' : 'select'
        ].url({ orgId: profile.orgId });
      } else {
        if (selectedPlan === CARD_TYPES.DEBIT || selectedPlan === CARD_TYPES.CREDIT) {
          location = locations.Onboarding.fundingSources.card.index.url({ orgId: profile.orgId });
        }
      }

      analytics.track(
        EntrypointEventPageEnum.PayBill,
        EventNameEnum.experimentLandingPageFundingSource,
        {
          organizationId: profile.orgId,
          userId: profile.id,
          variant: 'combined-landing-page-and-funding-source',
        }
      );

      if (location) {
        let state = {
          billIds: billIdsForState,
          isBatch,
          exitUrl: redirectUrl,
          preservedState: {
            origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.NEW_LANDING_PAGE,
            exitUrl: redirectUrl,
          },
          redirectUrl,
        };

        if (!isBatch && bill) {
          await setNewLpForUser();
          state = {
            ...state,
            preservedState: {
              origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.NEW_LANDING_PAGE,
              exitUrl: locations.Bills.pay.funding.url({
                orgId: profile.orgId,
                id: bill.id,
              }),
            },
            exitUrl: locations.Bills.pay.funding.url({
              orgId: profile.orgId,
              id: bill.id,
            }),
          };
        }

        navigate(location, false, state);

        return;
      }
    }

    if (bill) {
      this.setState({ bill });

      return;
    }

    if (billIds?.length) {
      this.navigateToBatchFlow({ ids: billIds, orgId: profile.orgId });

      return;
    }

    this.setState({ error: { message: 'Bill not found' } });
  };

  onErrorOccured = (error) => {
    loggingApi.error('PayBillQuickbooksId.onErrorOccured(): error occured', {
      error,
      paybill: true,
    });
    this.setState({
      error: {
        code: error?.code,
        message: error?.message,
        data: error?.responseData,
      },
    });
  };

  navigateToBatchFlow = ({ ids, orgId }) => {
    const { fundingSources, navigate } = this.props;

    const batchPaymentsUrl = locations.Bills.pay.batch.url({ ids, orgId });
    const addFundingSourceUrl = locations.Bills.pay.batchFunding.url({ ids, orgId });

    if (!hasValidFundingSources(fundingSources)) {
      navigate(addFundingSourceUrl);

      return;
    }

    navigate(batchPaymentsUrl);
  };

  render() {
    const { bill, error } = this.state;
    const { profile } = this.props;

    if (bill?.id) {
      if (!bill?.balance) {
        return (
          <Redirect
            to={{
              pathname: globalLocations.entrypoints.errors.billAlreadyScheduled,
              state: { billId: bill.id },
            }}
          />
        );
      }

      if (bill?.contractor) {
        return (
          <Redirect
            to={{
              pathname: globalLocations.entrypoints.errors.contractors,
              state: { billId: bill?.id },
            }}
          />
        );
      }

      const payBillUrl = locations.Bills.pay.funding.url({
        id: bill?.id,
        orgId: profile.orgId,
      });

      return <Redirect to={payBillUrl} />;
    }

    if (error) {
      return (
        <Redirect
          to={{
            pathname: globalLocations.entrypoints.errors.billSyncError,
            state: {
              errorCode: error.code,
              vendorName: error.data?.vendorName,
              amount: error.data?.totalAmount,
            },
          }}
        />
      );
    }

    return <ProgressPage progress={75} />;
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
  fundingSources: getFundingSources(state),
  selectedPlan: landingPageSelectors.selectSelectedPlan(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  clearSelectedPlan() {
    return dispatch(landingPageActions.clearSelectedPlan());
  },
  getBillListByIds(params) {
    return dispatch(batchBillsStore.actions.getBillListByIdsSlice(params));
  },
  setNewLpForUser() {
    return dispatch(landingPageActions.setIsNewLpEntry(true));
  },
});

export default compose(
  withNavigator(),
  connect(mapStateToProps, mapDispatchToProps),
  withPartialPaymentsEnabled()
)(PayBillQuickbooksId);
