import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { fullOrgSyncStore } from 'src/app/modules/fullOrgSync/full-org-sync-store';
import { flagsStore } from 'src/app/modules/flags/flags-store';
import analytics from 'src/app/services/analytics';

type Props = {
  eventPage: string;
};

const useFullOrgSync = ({ eventPage }: Props) => {
  const dispatch = useDispatch();

  const isFullOrgSyncRequired = useSelector(
    (state: { flags: any }) => state['flags'].isFullOrgSyncRequired
  );
  const orgId = useSelector(getOrgId);

  useEffect(() => {
    if (isFullOrgSyncRequired) {
      dispatch(fullOrgSyncStore.actions.initiateFullOrgSync(orgId));
      dispatch(flagsStore.actions.setFullOrgSyncRequired(false));

      analytics.track(eventPage, 'OrgFullSync-Started');
    }
  }, []);
};

export { useFullOrgSync };
