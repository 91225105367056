import { theme } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const TooltipContainer = styled.div<{ display?: string }>`
  background-color: ${(props) => props.theme.colors.ds.gray[100]};
  position: absolute;
  left: 0;
  padding: 0.8rem;
  bottom: 6.2rem;
  width: 25.5rem;
  border-radius: 0.4rem;
  display: ${(props) => props.display};
`;

export const CloseIcon = styled.i`
  font-size: 2.4rem;
  color: ${theme.colors.ds.gray[300]};
  &:hover {
    cursor: pointer;
  }
`;

export const TextContainer = styled.div`
  color: ${({ theme }) => theme.colors.ds.white};
  ${(props) => props.theme.textStyles.ds.body3}
  font-weight: ${({ theme }) => theme.fontWeights.ds.regular};
  padding: 0.8rem;
`;

export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Arrow = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${(props) => props.theme.colors.ds.gray[100]};
  z-index: 5;
  position: absolute;
  bottom: -1.2rem;
  left: 2rem;
  transform: rotate(-135deg);
`;
