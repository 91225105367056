import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DeliveryEnum } from 'src/app/version-2/model/enums';
import { useHasFeatureFlag } from 'src/app/hooks/useHasFeatureFlag';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import doneImage from 'src/app/images/general/qbo-check-circle.svg';
import { CONSTS, NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { ContentWrapper } from 'src/app/pages/vendor/components/VendorLayoutElements';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import { SupportLine } from 'src/app/components/common/OutsideLayout/SupportLine';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { AreaLoader } from '@melio/billpay-design-system';
import vendorsApi from 'src/app/services/api/vendors';
import { analyticsApi } from 'src/app/services/analytics/guest-analytics-api';
import { getJWTPayload } from 'src/app/version-2/utils/jwtToken.utils';
import {
  QBOBusinessDirectoryUpsell,
  BUSINESS_DIRECTORY_UPSELL_ORIGIN,
} from 'src/app/pages/vendor/components/QBOBusinessDirectoryUpsell';
import QBOJoinService from 'src/app/pages/vendor/components/QBOJoinService';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { isAchDeliveryMethod } from './utils';
import { ShiftToDebitState } from './hoc/shift-to-debit-hooks';

type Props = {
  state: ShiftToDebitState;
  token: string;
};

const AddDeliveryMethodSuccessPage = ({ state, token }: Props) => {
  const { isFeatureFlagEnabled } = useHasFeatureFlag();
  const isAchDM = isAchDeliveryMethod(state.deliveryMethod);
  const vendorFlowsViralityTestEnabled = isFeatureFlagEnabled('vendorFlowsViralityTest');

  const { deliveryMethod, payment, organization } = state;
  const isPaymentProcessed = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CARD;
  const title = isPaymentProcessed
    ? 'vendors.deliveryMethods.shiftToDebit.processed.title'
    : 'vendors.deliveryMethods.shiftToDebit.success.title';
  const subtitle = isPaymentProcessed
    ? 'vendors.deliveryMethods.shiftToDebit.processed.subtitle'
    : 'vendors.deliveryMethods.shiftToDebit.success.subtitle';

  useEffect(() => {
    if (!payment) return;

    const { id, vendor } = payment;

    vendorsApi
      .getVendorById({
        orgId: organization?.id,
        id: vendor?.id,
        options: {
          isBypassThrowError: true,
        },
      })
      .then((res) => {
        const deliveryMethods = res?.object?.deliveryMethods || [];

        const achDM = deliveryMethods.find((dm) => dm.deliveryType === DeliveryEnum.ACH) || {};
        const cardDM = deliveryMethods.find((dm) => dm.deliveryType === DeliveryEnum.CARD) || {};

        const { Tag } = getJWTPayload(token);

        analyticsApi.track({
          token,
          eventName: 'P2D-VendorCompleted',
          properties: {
            vendorId: vendor.id,
            vendorEmail: vendor?.contactEmail,
            paymentId: id,
            payorOrgId: organization?.id,
            payorUserId: organization?.userOrganizations?.[0]?.userId,
            originalDMId: achDM.id,
            originalDMType: achDM.deliveryType,
            DMId: cardDM.id,
            DMType: cardDM.deliveryType,
            emailTag: Tag,
          },
        });
      })
      .catch(() => {
        loggingApi.error('AddDeliveryMethodSuccessPage.getVendorById(): failed');
      });
  }, [payment, organization]);

  const renderDescription = () => {
    if (isAchDM) {
      return (
        <MIFormattedText
          label="vendors.deliveryMethods.shiftToDebit.success.descriptionAch"
          values={{
            companyName: organization?.companyName,
          }}
        />
      );
    }

    return (
      <>
        <StyledNotification
          className="card-container"
          type={NOTIFICATION_CARD_TYPES.MAIN}
          renderText={
            <MIFormattedText label="vendors.deliveryMethods.shiftToDebit.success.descriptionCheck" />
          }
        />
        <TopSeparator />
      </>
    );
  };

  if (!deliveryMethod?.deliveryType) return <AreaLoader />;

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <Content>
        <ContentWrapper>
          <HeaderContentContainer centerText={vendorFlowsViralityTestEnabled}>
            <DoneIcon
              src={doneImage}
              large={vendorFlowsViralityTestEnabled}
              centered={vendorFlowsViralityTestEnabled}
            />
            <Title>
              <MIFormattedText label={title} />
            </Title>
            <Subtitle>
              <MIFormattedText
                label={subtitle}
                values={{
                  companyName: organization?.companyName,
                  amount: <MIFormattedCurrency value={payment?.amount || ''} />,
                  digits: deliveryMethod?.cardAccount?.card4digits,
                }}
              />
            </Subtitle>
            {!isPaymentProcessed && <Description>{renderDescription()}</Description>}
          </HeaderContentContainer>
          {vendorFlowsViralityTestEnabled ? (
            <>
              <Separator />
              <QBOBusinessDirectoryUpsell flow={BUSINESS_DIRECTORY_UPSELL_ORIGIN.PUSH_TO_DEBIT} />
            </>
          ) : (
            <QBOJoinService
              labelPrefix="vendors.deliveryMethods.shiftToDebit.success"
              utmCampaign="shift_vendor_to_debit"
            />
          )}
        </ContentWrapper>
      </Content>
      <SupportLine __css={supportLineStyle} />
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #d4d7dc;
  box-sizing: border-box;
`;

const DoneIcon = styled.img<{ large: boolean; centered: boolean }>`
  width: ${({ large }) => (large ? '5rem' : '4.8rem')};
  height: ${({ large }) => (large ? '5rem' : '4.8rem')};
  margin-bottom: 2rem;
  align-self: ${({ centered }) => (centered ? 'center' : 'auto')};
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.demi};
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme.text.size.wizardStepTitle};
`;

const Subtitle = styled.div`
  margin-bottom: 2rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Description = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.medium};
`;

const HeaderContentContainer = styled.div<{ centerText: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  //align-items: left;
  position: relative;
  text-align: ${({ centerText }) => (centerText ? 'center' : 'left')};
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.text.color.grey};
  height: 0.1rem;
  margin: -0.8rem 1rem 3.2rem;
  @media ${devices.mobile}, ${devices.phablet} {
    margin: -0.8rem 0 3.2rem;
  }
`;

const TopSeparator = styled.div`
  background-color: #d4d7dc;
  height: 0.1rem;
  margin-top: 4rem;
`;

const supportLineStyle = {
  m: ['2rem 0 5rem', '2rem 0'],
};

const StyledNotification = styled(QBONotificationCard)`
  && {
    color: #393a3d;
    border-color: #d4d7dc;
  }
`;

export default AddDeliveryMethodSuccessPage;
