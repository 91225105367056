import React from 'react';
import { ViewBillPaymentReviewTotalSection } from 'src/app/pages/bill/components/QBOViewBillPaymentReview/ViewBillPaymentReviewTotalSection/ViewBillPaymentReviewTotalSection';

import { BillInfoContainer, ReasonSeparator } from './ReviewRefundBillInfo.styles';
import { Option, RequestRefundStepPropsType } from '../../types';
import { ReviewRefundBillInfoReason } from './ReviewRefundBillInfoReason/ReviewRefundBillInfoReason';
import { RefundPaymentDetails } from './RefundPaymentDetails/RefundPaymentDetails';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';

type Props = {
  selectedReason: Option;
} & Pick<RequestRefundStepPropsType, 'bill' | 'payment'>;

export const ReviewRefundBillInfo = ({ payment, bill, selectedReason }: Props) => {
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );

  return (
    <BillInfoContainer>
      <ViewBillPaymentReviewTotalSection paymentId={payment?.id} currentAmount={bill.totalAmount} />
      <RefundPaymentDetails payment={payment} bill={bill} />
      <ReasonSeparator />

      {!isRefundFlowFeature && <ReviewRefundBillInfoReason selectedReason={selectedReason} />}
    </BillInfoContainer>
  );
};
