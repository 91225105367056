import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { ButtonsContainer } from './PayBillDatePageButtons.styles';

type PayBillDatePageButtonsProps = {
  isSecondaryButton?: boolean;
  primaryLabel: string;
  secondaryLabel?: string;
  handleNext: () => void;
  handleSecondary: () => void;
  isDisabled?: boolean;
};

export default function PayBillDatePageButtons({
  isSecondaryButton = false,
  primaryLabel,
  secondaryLabel,
  handleNext,
  handleSecondary,
  isDisabled,
}: PayBillDatePageButtonsProps) {
  return (
    <ButtonsContainer>
      {isSecondaryButton && (
        <MIButton
          className="secondary"
          label={secondaryLabel || ''}
          variant={CONSTS.BUTTON_VARIANT.SECONDARY}
          onClick={handleSecondary}
        />
      )}
      <MIButton
        label={primaryLabel}
        variant={CONSTS.BUTTON_VARIANT.PRIMARY}
        onClick={handleNext}
        testId="button-progress.continue"
        disabled={isDisabled}
      />
    </ButtonsContainer>
  );
}
