import { PaymentType } from 'src/app/utils/types';
import {
  TIMELINE_STEP_STAGES,
  TIMELINE_STEP_STATUSES,
  TIMELINE_STEP_TRANSLATION_ROOT_PATH,
} from '../consts';
import { TimelineStepType } from '../types';

const { SUCCEED } = TIMELINE_STEP_STATUSES;
const { SCHEDULE } = TIMELINE_STEP_STAGES;

const getScheduledStep = (payment: PaymentType): TimelineStepType => ({
  title: `${TIMELINE_STEP_TRANSLATION_ROOT_PATH}.${payment.deliveryMethod.deliveryType}.${SCHEDULE}.title`,
  subtitle: `${TIMELINE_STEP_TRANSLATION_ROOT_PATH}.${payment.deliveryMethod.deliveryType}.${SCHEDULE}.subtitle`,
  subtitleValues: {
    payor: payment.organization.companyName || '',
  },
  dates: [payment.createdAt],
  status: SUCCEED,
});

export { getScheduledStep };
