import { ReactNode } from 'react';
import { Box } from '@melio/billpay-design-system';
import { buttonContainerStyle } from './Modal.style';

type Props = {
  children?: ReactNode;
  isCentred?: boolean;
};

export const ModalButtonContent = ({ children, isCentred }: Props) => (
  <Box __css={buttonContainerStyle(isCentred)}>{children}</Box>
);
