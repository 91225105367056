import React from 'react';
import styled from 'styled-components';

import { BUTTON_VARIANT } from 'src/app/utils/consts';
import MIButton from 'src/app/components/common/MIButton';
import {
  WizardStepActionsContainer,
  WizardButtonContainer,
} from 'src/app/components/layout/QBOWizardElements';
import { SecurityDetails } from 'src/app/components/common/SecurityDetails';
import { devices } from 'src/app/theme/AppDevices';

type Props = {
  buttonLabel?: string;
  className?: string;
  shouldShowSecurityDetails?: boolean;
} & FooterCTAProps;

type FooterCTAProps =
  | { shouldShowCta?: false; isDisabled?: never; isProcessing?: never; onSubmit?: never }
  | { shouldShowCta: boolean; isDisabled: boolean; isProcessing: boolean; onSubmit: () => void };

export const Footer = ({
  className,
  shouldShowSecurityDetails = true,
  shouldShowCta,
  isDisabled,
  isProcessing,
  onSubmit,
  buttonLabel,
}: Props) => (
  <FooterContainer className={className} shouldShowSecurityDetails={shouldShowSecurityDetails}>
    {shouldShowCta && (
      <StyledWizardStepActionsContainer shouldShowSecurityDetails={shouldShowSecurityDetails}>
        <StyledWizardButtonContainer isProgressBar width="22.5rem">
          <MIButton
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={onSubmit}
            isProcessing={isProcessing}
            label={buttonLabel || 'progress.continue'}
            disabled={isDisabled}
          />
        </StyledWizardButtonContainer>
      </StyledWizardStepActionsContainer>
    )}
    {shouldShowSecurityDetails && <SecurityDetails showIconCC />}
  </FooterContainer>
);

const FooterContainer = styled.div<{ shouldShowSecurityDetails: boolean }>`
  ${({ shouldShowSecurityDetails }) => (shouldShowSecurityDetails ? '' : 'width: 100%;')}
`;

const StyledWizardButtonContainer = styled(WizardButtonContainer)<{
  shouldShowSecurityDetails?: boolean;
}>`
  @media ${devices.mobile}, ${devices.phablet} {
    height: ${({ shouldShowSecurityDetails }) => (shouldShowSecurityDetails ? '6.8rem' : 'auto')};
  }
`;

const StyledWizardStepActionsContainer = styled(WizardStepActionsContainer)<{
  shouldShowSecurityDetails: boolean;
}>`
  margin-top: 0;
  margin-bottom: ${({ shouldShowSecurityDetails }) => (shouldShowSecurityDetails ? '2rem' : '0')};

  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 3rem;
  }
`;
