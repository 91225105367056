import { useMemo, useState, useEffect, useContext } from 'react';
import { useModal } from 'src/app/helpers/react/useModal';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { isValidationOk } from '@melio/sizzers-js-common';
import { CONSTS } from 'src/app/utils/consts';
import { useForm } from 'src/app/ui/form';
import MIButton from 'src/app/components/common/MIButton';
import { devices } from 'src/app/theme/AppDevices';
import { useBreak } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import { useDidUpdateEffect } from 'src/app/hooks/useDidUpdateEffect';
import { validateMultiFields } from 'src/app/utils/form-utils';
import { UnsupportedCountriesModal } from 'src/app/pages/vendor/delivery-methods/international/routes/VendorLocation/UnsupportedCountriesModal';
import { ERROR_PREFIX } from '../../utils';
import InternationalContext from '../../Context';
import { ButtonContainer } from 'src/app/pages/vendor/delivery-methods/international/components/ButtonContainer';
import CountriesList from '../../components/CountriesList';
import { Footer } from 'src/app/pages/vendor/delivery-methods/international/components/Footer';
import { StepProps, BillingTypes, BankDetails } from '../../types';
import { dynamicFieldsInit } from './utils';
import PaymentField from './PaymentField';
import BillingField from './BillingField';

const { IBAN, SWIFT } = BillingTypes;

type Props = StepProps & {
  fetchBankDetails: (billingType: string, billingValue: string) => Promise<any>;
};

export const Form = ({ fetchBankDetails, onNext }: Props) => {
  const [state, actions] = useContext(InternationalContext);
  const [bankDetails, setBankDetails] = useState<Partial<BankDetails>>(state.bankDetails);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});
  const [billingType, setBillingType] = useState(state.country.billingType);
  const device = useBreak();
  const isMobileView = device.isMobile || device.isPhablet;
  const { country, fee, amount, currency, origin, edit, requiredFieldsValidation } = state;

  const [unsupportedCountriesModal, showUnsupportedCountriesModal] = useModal(
    UnsupportedCountriesModal,
    {}
  );

  const model = useMemo(
    () => ({
      country,
      amount,
      currency,
      accountNumber: state.accountNumber,
      bankName: state.bankName,
      [IBAN]: state[IBAN],
      [SWIFT]: state[SWIFT],
    }),
    [country]
  );

  useEffect(() => {
    if (edit && state[billingType] && isEmpty(bankDetails))
      getBankDetails(billingType, state[billingType]);
  }, []);

  const getBankDetails = async (type, value) => {
    try {
      const { details } = await fetchBankDetails(type, value);

      setBankDetails(details);

      if (details.account_number) values.accountNumber.onChange({ value: details.account_number });

      if (details.bank_name) values.bankName.onChange({ value: details.bank_name });
    } catch (e) {
      setValidationErrors((prev) => ({
        ...prev,
        [type]: `inputErrors.international.${type}.regex`,
      }));
    }
  };

  const onSubmit = async (values) => {
    const errors = validateMultiFields(
      [
        {
          name: billingType,
          value: values[billingType],
          regex: requiredFieldsValidation[billingType],
          isRequired: true,
        },
        {
          name: 'accountNumber',
          value: values.accountNumber,
          isRequired: true,
          regex: '^[a-zA-Z0-9]+$',
        },
        {
          name: 'bankName',
          value: values.bankName,
          isRequired: true,
        },
      ],
      ERROR_PREFIX
    );
    const analyticsError = errors[billingType] ? { billing: errors[billingType] } : {};

    analytics.track('pay-bill', 'international-country-details-continue', {
      errors: analyticsError,
    });

    if (isEmpty(bankDetails)) {
      setValidationErrors({
        [billingType]: `inputErrors.international.${billingType}.regex`,
      });

      return;
    }

    // bank details server or validation error
    if (!isValidationOk(errors)) {
      setValidationErrors(errors);

      return;
    }

    // success
    actions.setPaymentDetails({
      country: values.country,
      accountNumber: values.accountNumber,
      bankName: values.bankName,
      bankDetails,
      billingType,
      billingValue: values[billingType],
    });

    setTimeout(onNext as any);
  };

  const [values, { submit }] = useForm(model, { submit: onSubmit });

  useDidUpdateEffect(async () => {
    const { code: countryCode, billingType } = values.country;

    setBankDetails({});
    setBillingType((billingType as { value: string }).value || '');

    analytics.track('pay-bill', 'international-choose-country', {
      countryCode,
      source: 'country-page',
    });
  }, [
    (values.country.code as { value: string }).value,
    (values.country.billingType as { value: string }).value,
  ]);

  const outsideClickHandler = () => {
    // empty function for resetting input value
  };

  const onCountryChange = ({ value, label, billingType, isSupported, risk }) => {
    if (!isSupported) {
      showUnsupportedCountriesModal({ countryName: label });

      return;
    }

    // reset dynamic fields when changing country
    values.setModelState((prev) => ({
      ...prev,
      ...dynamicFieldsInit,
      country: { code: value, name: label, billingType, isSupported, risk },
      accountNumber: '',
      bankName: '',
    }));

    // reset errors for dynamic fields
    setValidationErrors((prev) => ({
      ...prev,
      country: '',
      accountNumber: '',
      bankName: '',
      ...dynamicFieldsInit,
    }));
  };

  const isLoading = useMemo(
    () => !validationErrors[billingType] && values[billingType]?.value && isEmpty(bankDetails),
    [validationErrors, billingType, bankDetails]
  );

  return (
    <StyledForm onSubmit={submit}>
      {unsupportedCountriesModal}
      <CountriesList
        value={(values.country.code as { value: string }).value}
        options={state.options}
        label="international.paymentDetails.country.label"
        onChange={onCountryChange}
        errorMessage={validationErrors.country}
        readOnlyValue={isLoading}
        outsideClickHandler={outsideClickHandler}
      />

      {/* amount / currency */}
      <PaymentField values={values} fee={fee} origin={origin} />

      {/* iban / swift */}
      <BillingField
        values={values}
        billingType={billingType}
        requiredFieldsValidation={requiredFieldsValidation}
        bankDetails={bankDetails}
        validationErrors={validationErrors}
        bankDetailsLoading={isLoading}
        setBankDetails={setBankDetails}
        getBankDetails={getBankDetails}
        setValidationErrors={setValidationErrors}
      />

      {isMobileView && <Footer />}

      <ButtonContainer>
        <MIButton
          type="submit"
          className="button"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label="international.paymentDetails.button"
          disabled={isLoading}
        />
      </ButtonContainer>

      {!isMobileView && <Footer />}
    </StyledForm>
  );
};

const StyledForm = styled.form`
  .button {
    display: flex;
    align-items: center;

    @media ${devices.tablet}, ${devices.desktop} {
      margin: 2.8rem auto 2.3rem;
    }
  }
`;
