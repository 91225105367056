import * as React from 'react';
import { SmartLink } from 'src/app/modules/navigation/components/SmartLink';
import isFunction from 'lodash/isFunction';
import analytics from 'src/app/services/analytics';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ToNavigationType } from 'src/app/utils/types';

type Props = {
  to: ToNavigationType | (() => void);
  label: string;
  disabled?: boolean;
  plain?: boolean;
  testId?: string;
  preserveState?: boolean;
  className?: string;
};

const MILink = ({ to, label, disabled, plain, testId, preserveState, className }: Props) => {
  if (!isFunction(to)) {
    return (
      <StyledLink
        className={className}
        to={to}
        disabled={disabled}
        plain={plain}
        testId={testId || `link-${label}`}
        preserveState={preserveState}
        onClick={() => analytics.trackAction(label)}
      >
        <MIFormattedText label={label} />
      </StyledLink>
    );
  }

  const goTo = () => {
    analytics.trackAction(label);
    to();
  };

  return (
    <StyledButton
      className={className}
      onClick={goTo}
      disabled={disabled}
      plain={plain}
      data-testid={testId || `button-${label}`}
    >
      <MIFormattedText label={label} />
    </StyledButton>
  );
};

export default MILink;

const shareStyle = (props) => `
  background-color: transparent;
  font-size: ${props.theme.text.size.button};
  font-weight: ${props.theme.text.weight.semiBold};
  line-height: 2rem;
  outline: none;
  text-decoration: none;
  border-radius: 0;
  border: none;
  border-bottom: solid 0.2rem ${props.theme.colors.dark.transparent};
  color: ${props.disabled ? 'rgba(33, 33, 36, 0.3)' : props.theme.colors.text};
  cursor: ${props.disabled ? 'default' : 'pointer'};
  padding: 0;

  &:hover {
    color: ${props.disabled ? 'rgba(33, 33, 36, 0.3)' : props.theme.colors.brand};
  }

  &:active {
    color: ${props.disabled ? 'rgba(33, 33, 36, 0.3)' : 'rgba(92, 53, 203, 1)'};
  }

  &:disabled {
    cursor: default;
  }
  ${
    props.theme?.components?.MILink?.shareStyle
      ? props.theme.components.MILink.shareStyle(props)
      : ''
  }
`;

const plainLink = (props) => `
  background-color: transparent;
  font-size: ${props.theme.text.size.sectionTitle};
  font-weight: ${props.theme.text.weight.regular};
  cursor: ${props.disabled ? 'auto' : 'pointer'};
  color: ${props.theme.colors.brand};
  outline: none;
  text-decoration: none;
  border-radius: 0;
  border: none;
  padding: 0;
`;

const StyledLink = styled(
  ({
    plain,
    to,
    ...rest
  }: Partial<Props> & { onClick?: (event: any) => void; children?: React.ReactNode }) => (
    <SmartLink to={to as ToNavigationType} {...rest} />
  )
)`
  ${(props) => (props.plain ? plainLink(props) : shareStyle(props))}
`;

const StyledButton = styled.button<{ plain?: boolean }>`
  ${(props) => (props.plain ? plainLink(props) : shareStyle(props))}
`;
