import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';

import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import profileStore from 'src/app/modules/profile/profile-store';
import analytics from 'src/app/services/analytics';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { ModalContentContainer } from 'src/app/components/common/ModalMessage/ModalMessage.styles';
import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS, NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { useApi } from 'src/app/hoc/useApi';
import userApi from 'src/app/services/api/user';
import organizationApi from 'src/app/services/api/organizations';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import { validateMultiFields } from 'src/app/utils/form-utils';
import vendorsApi from 'src/app/services/api/vendors';
import MIButton from 'src/app/components/common/MIButton';
import MISingleSelect from 'src/app/components/common/MISingleSelect';

type Props = {
  vendorName: string;
  orgId: string;
  analyticsData: Record<string, any>;
  vendorId: string;
  billId: string;
  onClose: () => void;
  onNext?: () => void;
  onUpdate: ({ vendorId, mccCode }: { vendorId: string; mccCode: string }) => void;
  ctaLabel?: string;
  isBatch?: boolean;
};

const Modal = ({
  vendorName,
  orgId,
  analyticsData,
  vendorId,
  billId,
  onUpdate,
  onClose,
  onNext,
  ctaLabel,
  isBatch,
}: Props) => {
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});
  const [getAllowedMcc, , isMccListLoading] = useApi(organizationApi.getAllowedMcc);
  const [setMccCode, , isUpdateMccLoading] = useApi(vendorsApi.updateVendorMccCode);
  const [industries, setIndustries] = useState([]);
  const [subIndustries, setSubIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedCode, setSelectedCode] = useState('');
  const currentUser = useSelector(profileStore.selectors.profile);

  useEffect(() => {
    getList();

    // send backend track event
    trackOpenModalBackendEvent();

    analytics.track('pay-bill', 'amex-industry-modal_open', analyticsData);
  }, []);

  const getList = async () => {
    try {
      const { list: allowedMCCs } = await getAllowedMcc(orgId, { cardNetwork: 'amex' });
      const { mccIndustries, mccSubIndustries } = breakMCCsToOptions(allowedMCCs);

      setIndustries(mccIndustries);
      setSubIndustries(mccSubIndustries);
    } catch (e) {
      // error
    }
  };

  const updateCode = async (mccCode: string) => {
    try {
      await setMccCode(orgId, vendorId, mccCode);

      if (onUpdate) {
        onUpdate({ vendorId, mccCode });
      }

      if (onNext) onNext();

      setTimeout(() => {
        pushNotification({
          type: NOTIFICATION_VARIANT.SUCCESS,
          msg: 'amexVerification.toast',
          textValues: { vendorName },
        });
      });
      onClose();
    } catch (er) {
      // cannot save mcc code
    }
  };

  const trackOpenModalBackendEvent = () => {
    try {
      userApi.trackEvent(currentUser.id, 'analytics-mcc-restriction-on-billpay-scheduleBill', {
        table: 'bill',
        id: billId,
        key: 'batch',
        value: !!isBatch,
      });
    } catch (er) {
      // faild to send backend analytics
    }
  };

  const breakMCCsToOptions = (allowedMCCs) => {
    const mccIndustries = [] as any;
    const mccSubIndustries = {} as any;

    for (const [, mcc] of Object.entries(allowedMCCs)) {
      const { code, industry, description } = mcc as any;

      if (!code || !industry || !description) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const isExist =
        mccIndustries.filter((mmcIndustry) => mmcIndustry.label === industry).length > 0;

      if (!isExist) {
        mccIndustries.push({ label: industry, value: industry });
      }

      if (!mccSubIndustries[industry]) {
        mccSubIndustries[industry] = [];
      }

      mccSubIndustries[industry].push({ label: description, value: code });
    }

    return {
      mccIndustries,
      mccSubIndustries,
    };
  };

  const validateForm = ({ industry, code }: { industry?: string; code?: string } = {}) =>
    validateMultiFields(
      [
        { name: 'industry', value: industry, isRequired: true },
        {
          name: 'code',
          value: code,
          customCheck: () => {
            if (industry && !code) {
              return `amexVerification.modal.form.code.empty`;
            }

            return false;
          },
        },
      ],
      'amexVerification.modal.form'
    );

  const handleSubmit = async () => {
    const errors = validateForm({ industry: selectedIndustry, code: selectedCode });

    setValidationErrors(errors);

    // invalid form
    if (!isEmpty(errors)) {
      analytics.track('pay-bill', 'amex-industry-modal_click-error', {
        errors,
        selectedCategory: selectedIndustry,
        selectedSubCategory: selectedCode,
        ...analyticsData,
      });

      return;
    }

    analytics.track('pay-bill', 'amex-industry-modal_click-save', {
      selectedCategory: selectedIndustry,
      selectedSubCategory: selectedCode,
      ...analyticsData,
    });

    // success
    updateCode(selectedCode);
  };

  const handleIndustryChange = ({ value: industry }) => {
    const code = '';
    const errors = validateForm({ industry, code });

    setSelectedCode(code);
    setSelectedIndustry(industry);
    setValidationErrors(errors);
  };

  const handleCodeChange = ({ value: code }) => {
    const errors = validateForm({ code, industry: selectedIndustry });

    setSelectedCode(code);
    setValidationErrors(errors);

    analytics.track('pay-bill', 'amex-industry-modal_select-category', {
      selectedCategory: selectedIndustry,
      selectedSubCategory: selectedCode,
      ...analyticsData,
    });
  };

  const renderForm = (
    <div>
      <MISingleSelect
        id="industry"
        value={selectedIndustry}
        label="amexVerification.modal.form.industry.label"
        placeholder="amexVerification.modal.form.industry.placeholder"
        errorMessage={validationErrors.industry}
        onChange={handleIndustryChange}
        options={industries}
        menuPosition="fixed"
        isProcessing={isMccListLoading}
        isSearchable={false}
        required
      />
      <MISingleSelect
        id="code"
        value={selectedCode}
        label="amexVerification.modal.form.code.label"
        placeholder="amexVerification.modal.form.code.placeholder"
        errorMessage={validationErrors.code}
        onChange={handleCodeChange}
        options={selectedIndustry ? subIndustries[selectedIndustry] : []}
        menuPosition="fixed"
        isDisabled={!selectedIndustry}
        isSearchable={false}
        required
      />
    </div>
  );

  const goLearnMore = () =>
    dispatch(applicationActions.setFaqPanel({ isOpen: true, focusedQuestion: 16 }));

  return (
    <StyledModal
      alignLeft
      titleComponent={
        <MIFormattedText label="amexVerification.modal.title" values={{ vendorName }} />
      }
      contentSubTitle={
        <>
          <MIFormattedText label="amexVerification.modal.description" />
          <LearnMoreLink onClick={goLearnMore}>
            <MIFormattedText label="amexVerification.modal.learnMore" />
          </LearnMoreLink>
        </>
      }
      buttonComponent={
        <ButtonsContainer>
          <MIButton
            label={ctaLabel || 'amexVerification.modal.submit'}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            isProcessing={isUpdateMccLoading}
            onClick={handleSubmit}
          />
        </ButtonsContainer>
      }
      contentComponent={renderForm}
      onCloseClick={onClose}
    />
  );
};

export default Modal;

const StyledModal = styled(ModalMessage)`
  ${ModalContentContainer} {
    padding: 4rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const LearnMoreLink = styled.span`
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
  ${(props) => props.theme.text.fontType.medium};
  margin-left: 0.5rem;
  font-size: 1.6rem;
`;
