import { ORDER_DIRECTION } from './consts';
import { OrderParams } from './types';

type Params = {
  accessor: string;
} & OrderParams;

export const getOrderParams = ({ orderBy, orderDirection, accessor }: Params) => {
  if (accessor !== orderBy) {
    return { orderBy: accessor, orderDirection: ORDER_DIRECTION.ASC };
  }

  if (orderDirection === ORDER_DIRECTION.ASC) {
    return { orderBy: accessor, orderDirection: ORDER_DIRECTION.DESC };
  }

  return { orderBy: accessor, orderDirection: ORDER_DIRECTION.ASC };
};
