import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Link } from '@melio/billpay-design-system';
import { useBreak } from 'src/app/hoc';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/version-2/externals';
import {
  Container,
  FooterNote,
  SectionTitle,
} from 'src/app/version-2/pages/virtual-card-details/components/HowToUseVirtualCard/HowToUseVirtualCard.style';
import { ProcessCardStep } from 'src/app/version-2/pages/virtual-card-details/components/ProcessCardStep';

const PROCESSS_CARD_STEPS = [
  {
    title: 'vendors.virtualCardDetails.valid.processCard.first.title',
    subtitle: 'vendors.virtualCardDetails.valid.processCard.first.subtitle',
  },
  {
    title: 'vendors.virtualCardDetails.valid.processCard.second.title',
    subtitle: 'vendors.virtualCardDetails.valid.processCard.second.subtitle',
  },
  {
    title: 'vendors.virtualCardDetails.valid.processCard.third.title',
    subtitle: 'vendors.virtualCardDetails.valid.processCard.third.subtitle',
  },
];

interface HowToUseVirtualCardProps {
  isLeftAlign?: boolean;
}

export const HowToUseVirtualCard = ({ isLeftAlign = false }: HowToUseVirtualCardProps) => {
  const { isMobile } = useBreak();
  const { formatMessage } = useIntl();
  const site = useSiteContext();

  return (
    <Container>
      <SectionTitle>
        {formatMessage({
          id: 'vendors.virtualCardDetails.valid.processCard.sectionTitle',
        })}
      </SectionTitle>
      <Grid gridRowGap={isMobile ? 5 : 6}>
        {PROCESSS_CARD_STEPS.map((step, index) => (
          <ProcessCardStep
            key={step.title}
            idx={index + 1}
            title={step.title}
            subtitle={step.subtitle}
          />
        ))}
      </Grid>
      <FooterNote isLeftAlign={isLeftAlign}>
        <MIFormattedText
          label="vendors.virtualCardDetails.valid.footerNote"
          values={{
            link: (...chunks) => (
              <Link
                onClick={() => window.open(`mailto:${site.config.support.email}`, '_blank')}
                textDecoration="none !important"
                cursor="pointer"
              >
                {React.Children.toArray(chunks)}
              </Link>
            ),
          }}
        />
      </FooterNote>
    </Container>
  );
};
