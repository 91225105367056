import React from 'react';
import { BillType, QBRBillListType } from 'src/app/utils/types';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { PaginationItemsTypeEnum } from 'src/app/version-2/model/enums';
import MIPaginationBar from 'src/app/components/common/MIPaginationBar';
import { QBR_BILL_LIST_TAB_TYPE } from 'src/app/utils/consts';
import { BillFilterType } from 'src/app/pages/quickpay/bills/types';
import { encodeQuery } from 'src/app/utils/query-utils';
import { sortFailedBills } from 'src/app/utils/bills';
import BillListItem from './QBRBillListItem';
import BillsGroupHeader from './QBRBillsGroupHeader';
import { groupUnpaidAndScheduledBills } from './utils';

type Props = {
  bills: BillType[];
  setSelectedBill: (billId: string) => void;
  filters: BillFilterType;
  totalCount: number;
  pathName: string;
};

const BillsList = ({ bills, setSelectedBill, filters, pathName, totalCount }: Props) => {
  const groupBillsByStatus = (
    bills: BillType[],
    type: QBRBillListType
  ): { group?: string; bills?: BillType[] }[] => {
    if (!bills || bills.length === 0) {
      return [];
    }

    const result: { group?: string; bills?: BillType[] }[] = [];

    if (type === QBR_BILL_LIST_TAB_TYPE.COMPLETED) {
      return [{ bills }];
    }

    if (type === QBR_BILL_LIST_TAB_TYPE.OPEN) {
      const { scheduledBills, failedBills } = groupUnpaidAndScheduledBills(bills);

      if (failedBills.length > 0) {
        result.push({
          group: 'bills.list.header.needsAttention',
          bills: failedBills.sort(sortFailedBills),
        });
      }

      if (scheduledBills.length > 0) {
        result.push({
          group: 'bills.list.header.scheduled',
          bills: scheduledBills,
        });
      }

      return result;
    }

    return [];
  };

  const groupedBills = groupBillsByStatus(bills, filters.type);

  if (groupedBills.length === 0) {
    return (
      <EmptyStateContainer>
        <Text>
          <MIFormattedText label="bills.list.empty" />
        </Text>
      </EmptyStateContainer>
    );
  }

  return (
    <BillsListContainer data-testid="qbr-bill-list-container">
      {groupedBills.map((groupBill, id) => (
        <BillGroupContainer key={id} data-testid="qbr-bill-group-container">
          {groupBill?.group && <BillsGroupHeader header={groupBill.group} />}
          {groupBill?.bills &&
            groupBill?.bills.map((bill) => (
              <BillListItem setSelectedBill={setSelectedBill} key={bill.id} bill={bill} />
            ))}
        </BillGroupContainer>
      ))}
      <BillListPaginationBarContainer>
        <MIPaginationBar
          total={totalCount}
          start={filters.start}
          limit={filters.limit}
          pathname={pathName}
          baseSearch={encodeQuery(filters, ['start', 'limit', 'id'], '')}
          itemType={PaginationItemsTypeEnum.LINK}
        />
      </BillListPaginationBarContainer>
    </BillsListContainer>
  );
};

export default BillsList;

const BillListPaginationBarContainer = styled.div`
  margin-top: -0.6rem;
  ${(props) => props.theme?.components?.MIPaginationBar?.BillListPaginationBarContainer}
`;

const BillsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const BillGroupContainer = styled.div`
  padding-top: 1.4rem;
`;

const EmptyStateContainer = styled.div`
  height: calc(100vh - 20rem);
  display: flex;
  flex-direction: column;
  align-items: flex-left;
  justify-content: center;
`;

const Text = styled.div`
  ${(props) => props.theme.text.fontType.regular}
  font-weight: ${(props) => props.theme.text.weight.normal};
  color: rgba(141, 144, 150, 1);
  text-align: center;
  text-align: center;
  padding-bottom: 1.5rem;
`;
