import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RecordOf } from 'immutable';
import { isValidationOk, getValidationErrors } from '@melio/sizzers-js-common';
import { HANDLED_SYNC_PAYMENT_ERROR_CODES } from 'src/app/version-2/model/constants/syncPaymentErrorCode.consts';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { useIsQbDashboardEnabled } from 'src/app/pages/bill/pay/hooks/useIsQBDashboardEnabled';
import { CompanyInfoRecord } from 'src/app/pages/settings/records';
import { setCompanyInfoAction } from 'src/app/redux/user/actions';
import { TaxIdEnum } from 'src/app/version-2/model/enums';
import {
  CompanyInfoType,
  ConfirmationOrigin,
  FieldType,
  GoogleCombinedAddressType,
} from 'src/app/utils/types';
import {
  convertLegalAddressToGoogleAddress,
  convertCompanyAddressToGoogleAddress,
  convertToServerAddress,
  convertToServerLegalAddress,
} from 'src/app/utils/address';
import { getCompanyInfo, getOrgId } from 'src/app/redux/user/selectors';
import companyInfoApi from 'src/app/services/api/organizations';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import { useJustPayCreatePayment } from 'src/app/pages/just-pay/hooks/useJustPayCreatePayment';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import analytics from 'src/app/services/analytics';
import { DEFAULT_DASHBOARD_REDIRECT_PARAMS } from 'src/app/pages/qb-dashboard/hooks/useGetDashboardListItemPaginationParams';
import { useEmailVendorOnEditCheckAddres } from 'src/app/pages/vendor/hooks/useEmailVendorOnEditCheckAddress';
import { AddressNameTypeEnum } from 'src/app/version-2/model/enums';
import { JustPayCompleteLegalInfoLayout } from './JustPayCompleteLegalInfoLayout';

type Props = {
  onNext(): void;
  onBack(): void;
  onExit(): void;
  onError(): void;
};

type FormFields = {
  legalAddress: GoogleCombinedAddressType;
  legalCompanyName: string;
  taxId?: string;
  taxIdType?: TaxIdEnum;
  contactFirstName: string;
  contactLastName: string;
  phone?: string;
  openLegalAddressInput?: boolean;
  companyAddress: GoogleCombinedAddressType;
};

export const JustPayCompleteLegalInfo = ({ onError, onExit, onBack, onNext }: Props) => {
  const isQbDashboardEnabled = useIsQbDashboardEnabled();
  const { redirectToDashboard } = useRedirectToDashboard();
  const { payment, paymentStoreActions, vendor } = useJustPayStore();
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);
  const [formValues, setFormValues] = useState<FormFields>({
    legalAddress: convertLegalAddressToGoogleAddress(companyInfo),
    legalCompanyName: companyInfo.legalCompanyName,
    taxId: companyInfo.taxId,
    taxIdType: companyInfo.taxIdType,
    contactFirstName: companyInfo.contactFirstName,
    contactLastName: companyInfo.contactLastName,
    phone: companyInfo.phone,
    companyAddress: convertCompanyAddressToGoogleAddress(companyInfo),
    openLegalAddressInput: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});
  const dispatch = useDispatch();
  const setCompanyInfo = (companyInfo: RecordOf<CompanyInfoType>) =>
    dispatch(setCompanyInfoAction(companyInfo));

  const { createPayment: createPaymentLogic } = useJustPayCreatePayment();

  const [emailVendorOnPaymentSchedule] = useEmailVendorOnEditCheckAddres();

  const onLegalInfoChange = ({ id, value }: FieldType) => {
    setFormValues((prevState) => ({ ...prevState, [id]: value }));
  };

  const onAddressChange = (
    address: GoogleCombinedAddressType,
    type = AddressNameTypeEnum.LEGAL_ADDRESS
  ) => {
    setFormValues((prevState) => ({ ...prevState, [type]: address }));
  };

  const onLegalInfoSubmit = async (
    address: GoogleCombinedAddressType,
    companyAddress: GoogleCombinedAddressType,
    legalCompanyName: string,
    taxId?: string,
    taxIdType?: TaxIdEnum,
    contactFirstName?: string,
    contactLastName?: string,
    phone?: string,
    isValidLegalAddress?: boolean
  ) => {
    const inputFields = [
      'legalCompanyName',
      'taxId',
      'addressLine1',
      'city',
      'zipCode',
      'taxIdType',
      'contactFirstName',
      'contactLastName',
      'phone',
    ];
    const dataToUpdate = companyInfo.merge({
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      phone,
      ...convertToServerAddress(companyAddress),
      ...convertToServerLegalAddress(address),
    });
    const inputFieldsWithLegalAddress =
      isValidLegalAddress && inputFields?.length
        ? [...inputFields, 'legalZipCode', 'legalAddressLine1', 'legalCity']
        : inputFields;

    const validationErrors = getValidationErrors(
      'companyInfo',
      dataToUpdate,
      inputFieldsWithLegalAddress
    );

    setValidationErrors(validationErrors);

    if (!isValidationOk(validationErrors)) {
      analytics.trackAction('complete-legal-info-validation-errors', {
        validationErrors,
        trackingBillId: payment.trackingBillId,
      });

      return;
    }

    try {
      setIsLoading(true);

      const { companyInfo: updatedCompanyInfo } = await companyInfoApi.updateCompanyInfo(orgId, {
        ...dataToUpdate.toObject(),
        didCompleteQBLegalInfo: true,
      });

      const companyInfoRecord = CompanyInfoRecord(updatedCompanyInfo);

      setCompanyInfo(companyInfoRecord);

      const createdPayment = await createPaymentLogic(payment);
      let syncPaymentErrorCode;

      try {
        await companyInfoApi.runPaymentSync(payment.orgId, createdPayment.id);
      } catch (syncPaymentError) {
        syncPaymentErrorCode = (syncPaymentError as any)?.code;
        loggingApi.error(
          'JustPayCompleteLegalInfo.onLegalInfoSubmit(): sync payment failed',
          syncPaymentErrorCode
        );

        if (!HANDLED_SYNC_PAYMENT_ERROR_CODES.includes(syncPaymentErrorCode)) {
          throw syncPaymentError;
        }
      }

      emailVendorOnPaymentSchedule({
        deliveryType: payment.deliveryMethodType,
        scheduledDate: payment.suggestedDates.scheduledDate,
        paymentId: createdPayment.id,
        contactEmail: vendor?.contactEmail,
      });

      analytics.trackAction('complete-legal-info-continue-success', {
        trackingBillId: payment.trackingBillId,
      });

      if (isQbDashboardEnabled) {
        analytics.trackAction('go-to-dashboard', { trackingBillId: payment.trackingBillId });

        const state = {
          payment: { ...payment, createdPayment },
          vendor,
          confirmationOrigin: ConfirmationOrigin.JUST_PAY,
          syncPaymentErrorCode,
        };

        paymentStoreActions.justPay.justPayWizard.clear();

        redirectToDashboard({ redirectQuery: DEFAULT_DASHBOARD_REDIRECT_PARAMS, state });
      } else {
        paymentStoreActions.justPay.justPayWizard.update({ createdPayment });

        onNext();
      }
    } catch (error) {
      analytics.trackAction('complete-legal-info-continue-failure', {
        trackingBillId: payment.trackingBillId,
      });
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnSubmit = () => {
    const address = formValues.openLegalAddressInput
      ? formValues.legalAddress
      : formValues.companyAddress;

    onLegalInfoSubmit(
      address,
      formValues.companyAddress,
      formValues.legalCompanyName,
      formValues.taxId,
      formValues.taxIdType,
      formValues.contactFirstName,
      formValues.contactLastName,
      formValues.phone,
      formValues.openLegalAddressInput
    );
  };

  return (
    <JustPayCompleteLegalInfoLayout
      legalCompanyName={formValues.legalCompanyName}
      companyName={companyInfo.companyName}
      taxId={formValues.taxId}
      contactFirstName={formValues.contactFirstName}
      contactLastName={formValues.contactLastName}
      companyAddress={formValues.companyAddress}
      phone={formValues.phone}
      onLegalInfoChange={onLegalInfoChange}
      openLegalAddressInput={formValues.openLegalAddressInput}
      companyInfo={companyInfo}
      taxIdType={formValues.taxIdType}
      address={formValues.legalAddress}
      onPrev={onBack}
      goExit={onExit}
      onAddressChange={onAddressChange}
      validationErrors={validationErrors}
      handleOnSubmit={handleOnSubmit}
      isLoading={isLoading}
      trackingBillId={payment.trackingBillId}
    />
  );
};
