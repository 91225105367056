import React from 'react';
import { useSelector } from 'react-redux';
import billsStore from 'src/app/modules/bills/bills-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { BillType } from 'src/app/utils/types';
import { shouldDisplayPopover } from 'src/app/version-2/components/BillsPopover';

type Props = {
  item: DashboardListItemType;
};

const QBOAmountCell = ({ item }: Props) => {
  const bill: BillType = useSelector(billsStore.selectors.byId(item.billId));

  const { billPayments } = item?.payment || {};
  let totalAmount = bill?.totalAmount;

  if (shouldDisplayPopover(billPayments)) {
    totalAmount = billPayments?.reduce(
      (amountSum, billPayment) => amountSum + billPayment?.bill?.totalAmount,
      0
    );
  }

  return <MIFormattedCurrency value={Number(totalAmount)} currency="$" />;
};

export default QBOAmountCell;
