import { useEffect } from 'react';

const title = 'Bill Pay services';

export default function SiteTitle() {
  useEffect(() => {
    document.title = title;
  }, []);

  return null;
}
