import { FC } from 'react';
import { Checkbox as ChakraCheckbox, CheckboxProps as ChakraCheckboxProps, Icon } from '@chakra-ui/react';
import { ReactComponent as Checked } from '../../assets/images/checked.svg';
import { ReactComponent as Indeterminate } from '../../assets/images/indeterminate.svg';

type CheckboxProps = Omit<ChakraCheckboxProps, 'size' | 'colorScheme' | 'variant'> & {
  'data-testid'?: string;
};

type CustomIconProps = {
  isChecked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
};

const CustomIcon: FC<CustomIconProps> = ({ isChecked, isDisabled, isIndeterminate }) => {
  if (isDisabled && isChecked) {
    return <Icon as={Checked} width="1.4rem" height="auto" />;
  }

  if (isChecked) {
    return <Icon as={Checked} fill="ds.green.200" width="1.4rem" height="auto" />;
  }

  if (isIndeterminate) {
    return <Icon as={Indeterminate} width="1.4rem" height="auto" />;
  }

  return null;
};
const Checkbox: FC<CheckboxProps> = ({ children, ...rest }) => (
  <ChakraCheckbox icon={<CustomIcon {...rest} />} {...rest}>
    {children}
  </ChakraCheckbox>
);

export { Checkbox };
