import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RecordOf } from 'immutable';
import { useHistory } from 'react-router-dom';
import { Link } from '@melio/billpay-design-system';
import { useDispatch, useSelector } from 'react-redux';

import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';
import { WizardOrLine } from 'src/app/components/layout/QBOWizardElements';
import { BillType, OptionalDeliveryMethodsType } from 'src/app/utils/types';
import { DELIVERY_TYPE, FAILED_PAYMENT_TYPE } from 'src/app/utils/consts';
import {
  DeliveryMethod,
  QBODeliveryMethodListItem,
} from 'src/app/pages/bill/pay/components/QBODeliveryMethodListItem';
import { getPartialBillId } from 'src/app/utils/bills';
import { VirtualCardInfoModal } from 'src/app/pages/bill/components/VirtualCardInfoModal';
import { useVirtualCardInfoModal } from 'src/app/pages/bill/pay/hooks/useVirtualCardInfoModal';
import { AddFundingSourceWizardOriginEnum } from 'src/app/version-2/model/enums';
import { fetchBillAction } from 'src/app/redux/payBillWizard/actions';
import { getPayment } from 'src/app/redux/payBillWizard/selectors';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { useDeliveryMethodListConfig } from '../hooks/useDeliveryMethodListConfig';
import { useDeliveryMethodVisibilityAnalytics } from '../hooks/useDeliveryMethodVisibilityAnalytics';

export type SelectDeliveryMethodListProps = {
  amount: string;
  companyName: string;
  bill: RecordOf<BillType>;
  selectedFundingSource: FundingSource | undefined;
  selectedDeliveryMethodType: string | undefined;
  onDeliveryMethodChange: (value: OptionalDeliveryMethodsType) => void;
  goAddVirtualDeliveryMethod: () => void;
  goAddVirtualCardDeliveryMethod: (props: { [key: string]: string }) => void;
};

const SelectDeliveryMethodList = ({
  amount,
  bill,
  selectedFundingSource,
  companyName,
  selectedDeliveryMethodType,
  goAddVirtualCardDeliveryMethod,
  onDeliveryMethodChange,
  goAddVirtualDeliveryMethod,
}: SelectDeliveryMethodListProps) => {
  const history = useHistory<{ [key: string]: any }>();
  const dispatch = useDispatch();

  const payment = useSelector(getPayment);
  const hasDeliveryMethods = bill?.vendor?.deliveryMethods?.length > 0;

  useEffect(() => {
    const { backFromSettings } = history?.location?.state || {};

    if (!hasDeliveryMethods && backFromSettings) {
      // getting updated dm list from settings
      dispatch(fetchBillAction(bill.id));

      // reset settings after fetching bill
      history.replace(history.location.pathname, {
        ...history.location?.state,
        backFromSettings: false,
      });
    }
  }, [hasDeliveryMethods]);

  const isBatchBulkFlow =
    history.location.state?.preservedState?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK;

  const {
    visibilityOptions,
    internationalConfig,
    virtualConfig,
    vitrualCardConfig,
    achConfig,
    checkConfig,
  } = useDeliveryMethodListConfig({
    amount,
    bill,
    selectedFundingSource,
    selectedDeliveryMethodType,
    selectedDeliveryMethod:
      history.location.state?.preservedState?.selectedPaymentIntent?.deliveryMethod,
  });
  const { showAllDMs, showDomesticDM, showInternationalDM } = visibilityOptions;

  const [shouldShowVirtualCardInfoModal, setShouldShowVirtualCardInfoModal] =
    useState<boolean>(false);

  const { onToggleVirtualCardInfoModal } = useVirtualCardInfoModal({
    onToggle: () => setShouldShowVirtualCardInfoModal(!shouldShowVirtualCardInfoModal),
  });

  useDeliveryMethodVisibilityAnalytics({
    visibilityOptions,
    virtualConfig,
    vitrualCardConfig,
    internationalConfig,
    achConfig,
  });

  const goLearnMore = () => {
    analytics.trackAction('VirtualCardLearnMore-Clicked', {
      vendorId: bill?.vendor.id,
      partialBillId: getPartialBillId(bill),
    });

    onToggleVirtualCardInfoModal();
  };

  const isFailedToDelivery =
    payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;
  const isVirtualEligibleForBatch = !isBatchBulkFlow || selectedFundingSource?.isVerified;
  const shouldShowVirtualDeliveryMethodOption =
    isVirtualEligibleForBatch && !isFailedToDelivery && virtualConfig.required;

  return (
    <DeliveryMethodsList>
      {showAllDMs && internationalConfig.required && (
        <SectionTitle>
          <MIFormattedText label="deliveryMethods.titles.domestic" />
        </SectionTitle>
      )}
      {(showAllDMs || showDomesticDM) && (
        <>
          {vitrualCardConfig.required && (
            <VirtualCardDeliveryMethodContainer>
              <QBODeliveryMethodListItem
                key={DELIVERY_TYPE.VIRTUAL_CARD}
                deliveryMethod={vitrualCardConfig.deliveryMethod}
                companyName={companyName}
                isSelected={vitrualCardConfig.selected}
                onItemClick={() =>
                  goAddVirtualCardDeliveryMethod({ triggerFlow: 'singleBillPayFlow' })
                }
                showDisplayName
                tagVariant={!vitrualCardConfig.selected ? 'primary' : null}
                tagLabel="deliveryMethods.tags.virtualCard"
                descriptionValues={{
                  learnMoreLink: (...chunks) => (
                    <Link onClick={goLearnMore} isExternal>
                      {React.Children.toArray(chunks)}
                    </Link>
                  ),
                }}
                disabled={vitrualCardConfig.disabled}
              />
            </VirtualCardDeliveryMethodContainer>
          )}
          {achConfig.required && (
            <QBODeliveryMethodListItem
              key={DELIVERY_TYPE.ACH}
              deliveryMethod={achConfig.deliveryMethod}
              companyName={companyName}
              isSelected={achConfig.selected}
              onItemClick={() => onDeliveryMethodChange(DELIVERY_TYPE.ACH)}
            />
          )}
          {checkConfig.required && (
            <QBODeliveryMethodListItem
              key={DELIVERY_TYPE.CHECK}
              deliveryMethod={checkConfig.deliveryMethod}
              companyName={companyName}
              isSelected={checkConfig.selected}
              onItemClick={() => onDeliveryMethodChange(DELIVERY_TYPE.CHECK)}
              customDescription={checkConfig.metadata?.description}
              descriptionValues={checkConfig.metadata?.descriptionValues}
            />
          )}
          {shouldShowVirtualDeliveryMethodOption && !internationalConfig.required && (
            <WizardOrLine />
          )}
          {shouldShowVirtualDeliveryMethodOption && (
            <VirtualDeliveryMethodContainer>
              <QBODeliveryMethodListItem
                key={DELIVERY_TYPE.VIRTUAL}
                deliveryMethod={virtualConfig.deliveryMethod}
                companyName={companyName}
                isSelected={virtualConfig.selected}
                onItemClick={goAddVirtualDeliveryMethod}
                showDisplayName={false}
              />
            </VirtualDeliveryMethodContainer>
          )}
        </>
      )}
      {(showInternationalDM || showAllDMs) && internationalConfig.required && (
        <>
          {showAllDMs && (
            <SectionTitle marginTop={virtualConfig.required ? '4rem' : '2rem'}>
              <MIFormattedText label="deliveryMethods.titles.international" />
            </SectionTitle>
          )}
          <QBODeliveryMethodListItem
            key={DELIVERY_TYPE.INTERNATIONAL}
            deliveryMethod={internationalConfig.deliveryMethod}
            companyName={companyName}
            isSelected={internationalConfig.selected}
            onItemClick={() => onDeliveryMethodChange(DELIVERY_TYPE.INTERNATIONAL)}
            disabled={internationalConfig.disabled}
            customDescription={internationalConfig.metadata?.description}
            descriptionValues={{
              cardType: selectedFundingSource?.cardAccount?.network,
              fee: internationalConfig.metadata?.fee ? (
                <MIFormattedCurrency value={internationalConfig.metadata.fee} />
              ) : (
                '..'
              ),
            }}
            tagVariant={internationalConfig.disabled ? null : 'new'}
            tagLabel="deliveryMethods.notDefinedInfo.international.tagLabel"
          />
        </>
      )}
      <VirtualCardInfoModal
        isVisible={shouldShowVirtualCardInfoModal}
        onToggle={onToggleVirtualCardInfoModal}
        onSubmit={() => goAddVirtualCardDeliveryMethod({ triggerFlow: 'VirtualCardInfoModal' })}
      />
    </DeliveryMethodsList>
  );
};

export { SelectDeliveryMethodList };

const DeliveryMethodsList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4rem;

  & > ${DeliveryMethod} {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .chakra-badge {
    align-self: flex-start;
  }
`;

const VirtualDeliveryMethodContainer = styled.div`
  width: 100%;
  margin-bottom: 0;
`;

const VirtualCardDeliveryMethodContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.div<{ padding?: string; marginTop?: string }>`
  font-weight: ${(props) => props.theme.text.weight.demi};
  margin-bottom: 1rem;
  color: ${(props) => props.theme.text.color.darkGrey};
  letter-spacing: 0.04rem;
  text-align: left;
  width: 100%;
  padding: ${(props) => props.padding || 0};
  margin-top: ${(props) => props.marginTop || 0};
  font-size: 1.2rem;
`;
