import {
  screenSizeToColumns,
  screenThresholds,
  tableSizePerThreshold,
} from 'src/app/version-2/pages/batch-bulk/model/constants/tableColumnsSizes.consts';

export const getColumnsWidths = () => {
  if (window.screen.width >= screenThresholds[2]) {
    return screenSizeToColumns[screenThresholds[2]];
  }

  if (window.screen.width >= screenThresholds[1]) {
    return screenSizeToColumns[screenThresholds[1]];
  }

  return screenSizeToColumns[screenThresholds[0]];
};

export const getTableWidth = () => {
  if (window.screen.width >= screenThresholds[2]) {
    return tableSizePerThreshold[screenThresholds[2]];
  }

  if (window.screen.width >= screenThresholds[1]) {
    return tableSizePerThreshold[screenThresholds[1]];
  }

  return tableSizePerThreshold[screenThresholds[0]];
};
