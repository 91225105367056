import React from 'react';
import MIPasswordInput from 'src/app/components/common/MIPasswordInput';
import { InputType } from 'src/app/components/common/MITextInput';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';
import { ModelViewField } from './use-form';
import { InlineFieldGroup } from './InlineFieldGroup';

export type InlinePasswordFieldProps = {
  model?: ModelViewField<string>;
  value?: string;
  label: string;
  type?: InputType;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => any;
  viewOnly?: boolean;
};
export function InlinePasswordField(props: InlinePasswordFieldProps) {
  const { model, onChange, ...rest } = props;
  const value = props.value === undefined ? model?.value : props.value;
  const innerOnChange = onChange || model?.onChange;
  const id = props.id || model?.id;

  return (
    <InlineFieldGroup>
      <MIPasswordInput
        {...rest}
        value={value}
        onChange={innerOnChange}
        id={id}
        size={TextInputSizeEnum.INLINE}
        errorMessage={model?.error}
      />
    </InlineFieldGroup>
  );
}
