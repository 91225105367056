import { useDispatch, useSelector } from 'react-redux';
import { PaymentType } from 'src/app/utils/types';
import { useEffect, useState } from 'react';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import {
  getPaymentExpediteType,
  getIsUpsellAvailableByPaymentState,
} from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import {
  upgradePaymentActions,
  upgradePaymentSelectors,
} from 'src/app/version-2/pages/vendor-upgrade-payment';
import paymentStore from '../../../../modules/payments/payment-store';
import { EXPEDITE_OPTIONS } from '../consts';

const useGetUpsellItems = (paymentId: number, token: string) => {
  const payment: PaymentType | null = useSelector(paymentStore.selectors.byId(paymentId));
  const upsellItems = useSelector(upgradePaymentSelectors.selectUpsellItems);
  const dispatch = useDispatch();
  const [isP2FACHUpgradeAvailable, isP2FACHUpgradeAvailableLoading] = featureFlags.useFeature(
    FeatureFlagsEnum.UPGRADE_PAYMENT_TO_FACH_FROM_TRACK_PAYMENT,
    false
  );
  const [isP2RTPUpgradeAvailable, isP2RTPUpgradeAvailableLoading] = featureFlags.useFeature(
    FeatureFlagsEnum.UPGRADE_PAYMENT_TO_RTP_FROM_TRACK_PAYMENT,
    false
  );

  const isFeatureFlagsLoaded = !isP2FACHUpgradeAvailableLoading && !isP2RTPUpgradeAvailableLoading;
  const isUpgradeFeatureFlagOpened = isP2RTPUpgradeAvailable || isP2FACHUpgradeAvailable;
  const isUpsellAvailableByPaymentState = payment && getIsUpsellAvailableByPaymentState(payment);
  const isPaymentUpgradeAvailable =
    isFeatureFlagsLoaded && isUpgradeFeatureFlagOpened && isUpsellAvailableByPaymentState;

  useEffect(() => {
    if (isPaymentUpgradeAvailable) {
      dispatch(
        upgradePaymentActions.getPaymentUpsellDetails({
          token,
        })
      );
    }

    return () => {
      dispatch(upgradePaymentActions.resetState());
    };
  }, [isPaymentUpgradeAvailable]);

  const isLoading = !isFeatureFlagsLoaded || !!(isPaymentUpgradeAvailable && !upsellItems);

  return {
    upsellItems,
    isLoading,
    isP2RTPUpgradeAvailable,
    isP2FACHUpgradeAvailable,
  };
};

export const useGetPaymentExpediteType = ({
  paymentId,
  token,
}: {
  paymentId: number;
  token: string;
}) => {
  const [expediteType, setExpediteType] = useState<EXPEDITE_OPTIONS | null>(null);
  const payment: PaymentType | null = useSelector(paymentStore.selectors.byId(paymentId));

  const { upsellItems, isP2RTPUpgradeAvailable, isP2FACHUpgradeAvailable, isLoading } =
    useGetUpsellItems(paymentId, token);

  useEffect(() => {
    if (payment && !isLoading) {
      const expediteType = getPaymentExpediteType({
        payment,
        upsellItems,
        isP2RTPUpgradeAvailable,
        isP2FACHUpgradeAvailable,
      });

      setExpediteType(expediteType);
    }
  }, [payment, isP2FACHUpgradeAvailable, isP2RTPUpgradeAvailable, upsellItems, isLoading]);

  return {
    expediteType,
    isLoading,
  };
};
