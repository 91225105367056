import { Menu } from '@melio/billpay-design-system';
import { DeductionDateSelection } from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';

import styled from 'styled-components';

export const DeductionDateSelect = styled.div`
  width: 70rem;
  display: inline-flex;
`;

export const DeductionDateType = styled(Menu)``;

export const DeductionDateMenuButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 25rem;
`;

export const DeductionDateButtonLabel = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const DeductionDateButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeductionDateMenuButton = styled(Menu.MenuButton)`
  border: none;
  background: transparent;
  text-align: left;
  width: 100%;
  height: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ds.gray[400]};
  padding: 0 0 5px;
  font-size: 1.6rem;
  font-weight: 400;

  &:hover {
    cursor: pointer;
  }

  span {
    display: flex;
    justify-content: space-between;
  }
`;

export const ExpandCollapseMenuIcon = styled.div<{ $isOpen: boolean }>`
  display: inline-flex;
  cursor: pointer;
  ${({ $isOpen }) => ($isOpen ? 'transform: rotate(180deg);' : '')}
  &[aria-disabled="true"] {
    svg path {
      fill: ${({ theme }) => theme.colors.ds.gray[400]};
    }
  }
`;

export const DeductionDateMenuList = styled(Menu.MenuList)``;

export const DeductionDateMenuItem = styled(Menu.MenuItem)``;

export const DeductionDateTrigger = styled(DeductionDateSelection)`
  width: 14.7rem;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 0 0 3px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ds.gray[400]};
  margin-left: 40px;
  position: relative;
  top: -3px;
`;
