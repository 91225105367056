import React from 'react';
import styled from 'styled-components';
import { BillType, PaymentType } from 'src/app/utils/types';
import { MIFormattedDate } from 'src/app/utils/formatting';
import MIMoney from 'src/app/components/common/MIMoney';
import { getLatestPayment } from 'src/app/utils/payments';
import BillListItemStatus from './QBRBillListItemStatus';

type Props = {
  bill: BillType;
  setSelectedBill: (billId: string) => void;
};

const BillListItem = ({ bill, setSelectedBill }: Props) => {
  const { vendor, payments, totalAmount, invoiceDate, id } = bill;
  const latestPayment: PaymentType = getLatestPayment(payments);
  const scheduledDate = latestPayment ? latestPayment.scheduledDate : invoiceDate;

  const onItemClick = () => {
    setSelectedBill(id);
  };

  const { companyName, printOnCheck } = vendor;

  return (
    <BillItemContainer onClick={onItemClick}>
      <BillItemRow>
        <BillItem bold>{printOnCheck || companyName}</BillItem>
        <BillItem bold>
          <StyledMIMoney amount={totalAmount} size="small" />
        </BillItem>
      </BillItemRow>
      <BillItemRow>
        <SubBillItem>
          <MIFormattedDate date={scheduledDate} />
        </SubBillItem>
        <BillListItemStatus payment={latestPayment} />
      </BillItemRow>
    </BillItemContainer>
  );
};

export default BillListItem;

const BillItemContainer = styled.div`
  padding: 1.6rem 0 1.9rem 0;
  > div {
    &:first-child {
      margin-bottom: 0.4rem;
    }
  }

  border-bottom: 1px solid rgba(244, 245, 248, 1);
  &:last-child {
    border-bottom: none;
  }
`;

const BillItemRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BillItem = styled.div<{ bold?: boolean }>`
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) =>
    props.bold ? props.theme.text.weight.normal : props.theme.text.weight.regular};
`;

const SubBillItem = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.darkGrey};
`;

const StyledMIMoney = styled(MIMoney)`
  > * {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
`;
