const ACTION_PREFIX = '[PAY_BILL_FLOW]';

export const BEGIN_REGULAR_PAY_BILL_FLOW = `${ACTION_PREFIX} BEGIN_REGULAR_PAY_BILL_FLOW`;
export const SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA = `${ACTION_PREFIX} SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA`;
export const SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA_FINISH = `${ACTION_PREFIX} SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA_FINISH`;
export const BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS = `${ACTION_PREFIX} BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS`;
export const BEGIN_REGULAR_PAY_BILL_FLOW_FAILED = `${ACTION_PREFIX} BEGIN_REGULAR_PAY_BILL_FLOW_FAILED`;

export const BEGIN_RECURRING_PAY_BILL_FLOW = `${ACTION_PREFIX} BEGIN_RECURRING_PAY_BILL_FLOW`;
export const BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS = `${ACTION_PREFIX} BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS`;
export const BEGIN_RECURRING_PAY_BILL_FLOW_FAILED = `${ACTION_PREFIX} BEGIN_RECURRING_PAY_BILL_FLOW_FAILED`;

export const END_PAY_BILL_FLOW = `${ACTION_PREFIX} END_PAY_BILL_FLOW`;
export const INIT_PAY_BILL_FLOW = `${ACTION_PREFIX} INIT_PAY_BILL_FLOW`;

export const SELECT_FUNDING_SOURCE = `${ACTION_PREFIX} SELECT_FUNDING_SOURCE`;
export const CLEAR_SELECTED_FUNDING_SOURCE = `${ACTION_PREFIX} CLEAR_SELECTED_FUNDING_SOURCE`;
export const SELECT_PAYMENT_DATES = `${ACTION_PREFIX} SELECT_PAYMENT_DATES`;
export const UPDATE_PAYMENT_MEMO = `${ACTION_PREFIX} UPDATE_PAYMENT_MEMO`;
export const SET_PAYMENT_AMOUNT = `${ACTION_PREFIX} SET_PAYMENT_AMOUNT`;
export const UPDATE_BILL = `${ACTION_PREFIX} UPDATE_BILL`;

export const FETCH_BILL = `${ACTION_PREFIX} FETCH_BILL`;
export const FETCH_BILL_SUCCESS = `${ACTION_PREFIX} FETCH_BILL_SUCCESS`;
export const FETCH_BILL_FAILED = `${ACTION_PREFIX} FETCH_BILL_FAILED`;

export const SELECT_NEW_DELIVERY_METHOD = `${ACTION_PREFIX} SELECT_NEW_DELIVERY_METHOD`;
export const SELECT_DELIVERY_METHOD = `${ACTION_PREFIX} SELECT_DELIVERY_METHOD`;
export const ADD_NEW_DELIVERY_METHOD = `${ACTION_PREFIX} ADD_NEW_DELIVERY_METHOD`;

export const SET_PURPOSE_OF_PAYMENT = `${ACTION_PREFIX} SET_PURPOSE_OF_PAYMENT`;

export const ADD_RECURRING_BILL = `${ACTION_PREFIX} ADD_RECURRING_BILL`;

export const CREATE_RECURRING_BILL = `${ACTION_PREFIX} CREATE_RECURRING_BILL`;
export const CREATE_RECURRING_BILL_SUCCESS = `${ACTION_PREFIX} CREATE_RECURRING_BILL_SUCCESS`;
export const CREATE_RECURRING_BILL_ERROR = `${ACTION_PREFIX} CREATE_RECURRING_BILL_ERROR`;

export const CREATE_PAYMENT = `${ACTION_PREFIX} CREATE_PAYMENT`;

export const CREATE_PAYMENT_SUCCESS = `${ACTION_PREFIX} CREATE_PAYMENT_SUCCESS`;
export const CREATE_PAYMENT_ERROR = `${ACTION_PREFIX} CREATE_PAYMENT_ERROR`;

export const UPDATE_PAYMENT = `${ACTION_PREFIX} UPDATE_PAYMENT`;
export const UPDATE_PAYMENT_SUCCESS = `${ACTION_PREFIX} UPDATE_PAYMENT_SUCCESS`;
export const UPDATE_PAYMENT_ERROR = `${ACTION_PREFIX} UPDATE_PAYMENT_ERROR`;

export const RETRY_FAILED_PAYMENT = `${ACTION_PREFIX} RESCHEDULE_PAYMENT`;
export const RETRY_FAILED_PAYMENT_SUCCESS = `${ACTION_PREFIX} RESCHEDULE_PAYMENT_SUCCESS`;
export const RETRY_FAILED_PAYMENT_ERROR = `${ACTION_PREFIX} RESCHEDULE_PAYMENT_ERROR`;

export const RESET_ERROR = `${ACTION_PREFIX} RESET_ERROR`;

export const SET_FEE = `${ACTION_PREFIX} SET_FEE`;

export const SET_BILL_ID = `${ACTION_PREFIX} SET_BILL_ID`;

export const SET_DELETED_FUNDING_SOURCE_ID = `${ACTION_PREFIX} SET_DELETED_FUNDING_SOURCE_ID`;

export const CLEAR_DELETED_FUNDING_SOURCE_ID = `${ACTION_PREFIX} CLEAR_DELETED_FUNDING_SOURCE_ID`;

export const SET_INVOICE_FILE = `${ACTION_PREFIX} SET_INVOICE_FILE`;

export const SET_PAYBILL_WIZARD_FLOW = `${ACTION_PREFIX} SET_PAYBILL_WIZARD_FLOW`;
export const CLEAR_PAYBILL_WIZARD_FLOW = `${ACTION_PREFIX} CLEAR_PAYBILL_WIZARD_FLOW`;
export const CLEAR_PAYBILL_WIZARD_BILL = `${ACTION_PREFIX} CLEAR_PAYBILL_WIZARD_BILL`;

export const SET_FEE_FUNDING_SOURCE_FLOW = `${ACTION_PREFIX} SET_FEE_FUNDING_SOURCE_FLOW`;
export const SET_FEE_FUNDING_BANK_TYPE = `${ACTION_PREFIX} SET_FEE_FUNDING_BANK_TYPE`;
export const SHOW_US_HOLIDAY_NOTIFICATION = `${ACTION_PREFIX} SHOW_US_HOLIDAY_NOTIFICATION`;
export const HIDE_US_HOLIDAY_NOTIFICATION = `${ACTION_PREFIX} HIDE_US_HOLIDAY_NOTIFICATION`;

export const SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW = `${ACTION_PREFIX} SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW`;
export const CANCEL_AND_RETRY_PAYMENT = `${ACTION_PREFIX} CANCEL_AND_RETRY_PAYMENT`;
export const CANCEL_AND_RETRY_PAYMENT_ERROR = `${ACTION_PREFIX} CANCEL_AND_RETRY_PAYMENT_ERROR`;
export const CANCEL_AND_RETRY_PAYMENT_SUCCESS = `${ACTION_PREFIX} CANCEL_AND_RETRY_PAYMENT_SUCCESS`;
export const SELECT_REPAYMENT_METHOD = `${ACTION_PREFIX} SELECT_REPAYMENT_METHOD`;

export const FETCH_DELIVERY_OPTIONS = `${ACTION_PREFIX} FETCH_DELIVERY_OPTIONS`;
export const FETCH_DELIVERY_OPTIONS_SUCCESS = `${ACTION_PREFIX} FETCH_DELIVERY_OPTIONS_SUCCESS`;
export const FETCH_DELIVERY_OPTIONS_FAILURE = `${ACTION_PREFIX} FETCH_DELIVERY_OPTIONS_FAILURE`;

export const FETCH_INITIAL_DELIVERY_OPTIONS = `${ACTION_PREFIX} FETCH_INITIAL_DELIVERY_OPTIONS`;
export const FETCH_INITIAL_DELIVERY_OPTIONS_SUCCESS = `${ACTION_PREFIX} FETCH_INITIAL_DELIVERY_OPTIONS_SUCCESS`;
export const FETCH_INITIAL_DELIVERY_OPTIONS_FAILURE = `${ACTION_PREFIX} FETCH_INITIAL_DELIVERY_OPTIONS_FAILURE`;

export const SET_DELIVERY_OPTIONS = `${ACTION_PREFIX} SET_DELIVERY_OPTIONS`;

export const SET_SYNC_PAYMENT_ERROR_CODE = `${ACTION_PREFIX} SET_SYNC_PAYMENT_ERROR_CODE`;
