export const enum TransactionTypesEnum {
  ACH = 'ach',
  CHECK = 'check',
  CARD = 'card',
  UNILATERAL = 'unilateral',
  ROUTING = 'routing',
  AUDIT = 'audit',
  RPPS = 'rpps',
  VIRTUAL_CARD = 'virtual-card',
  INTERNATIONAL = 'international',
  DOMESTIC_WIRE = 'domestic-wire',
}
