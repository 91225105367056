import React from 'react';
import { Box, Tooltip } from '@melio/billpay-design-system';

import { useBreak } from 'src/app/hoc';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  tooltipLabel: string;
};

export const DeliveryMethodInfoIcon = ({ tooltipLabel }: Props) => {
  const { isMobile } = useBreak();

  return (
    <Tooltip
      isDisabled={isMobile}
      placement="bottom"
      label={<MIFormattedText label={tooltipLabel} />}
    >
      <Box as="i" fontSize="1.6rem" ml="0.6rem">
        <i className="icon-info-hollow-icon" />
      </Box>
    </Tooltip>
  );
};
