import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import QBROutsideHeader from 'src/app/components/common/OutsideLayout/QBROutsideHeader';
import QBROutsideFooter from 'src/app/components/common/OutsideLayout/QBROutsideFooter';

type OutsideLayoutProps = {
  children: React.ReactNode;
  redirectUrl?: string;
  hideLogin?: boolean;
  hideFooter?: boolean;
  headerTitle: string;
};

const QBROutsideLayout: React.FC<OutsideLayoutProps> = ({
  children,
  redirectUrl,
  hideLogin,
  headerTitle,
  hideFooter,
}) => (
  <OutsideWrapper>
    <QBROutsideHeader redirectUrl={redirectUrl} hideLogin={hideLogin} headerTitle={headerTitle} />
    <QBROutsideCard>{children}</QBROutsideCard>
    {!hideFooter && <QBROutsideFooter />}
  </OutsideWrapper>
);

export default QBROutsideLayout;

const OutsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.theme.background.wizard};

  @media ${devices.desktop}, ${devices.tablet} {
    align-items: center;
    height: 66rem;
    position: relative;
    top: 50%;
    margin-top: -33rem;
  }
`;

const QBROutsideCard = styled.div`
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.white.opaque};
  border-radius: 0.6rem;
  width: 53rem;
  margin: -10rem auto auto auto;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);

  @media ${devices.mobile}, ${devices.phablet} {
    width: auto;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
  }

  @media ${devices.desktop}, ${devices.tablet} {
    margin: 0;
    box-shadow: none;
    align-items: center;
    max-width: 54rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
