import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import { AreaLoader } from '@melio/billpay-design-system';
import {
  CancelledLayout,
  ExpiredLayout,
  ProcessedLayout,
  NewDeliveryMethodLayout,
  NewDeliveryMethodProcessedLayout,
  RecoveredLayout,
  RecoverySuccessLayout,
  NewDeliveryMethodScheduledLayout,
} from './layouts';
import { ValidCardLayout } from 'src/app/version-2/pages/virtual-card-details/components/ValidCardLayout';
import { useVirtualCardData, VirtualCardPaymentState } from './hooks';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import locations from './locations';

import { Organization } from 'src/app/version-2/model/dtos/organization';
import { Payment } from 'src/app/version-2/model/dtos/payment';
import { VirtualCard } from 'src/app/version-2/model/dtos/virtualCard';

interface QBOVirtualCardDetailsPageProps {
  token: string;
  virtualCardId: string;
}

const QBOVirtualCardDetailsPage = ({ token, virtualCardId }: QBOVirtualCardDetailsPageProps) => {
  const history = useHistory();

  const [isBpGlobalOptInVCDetailsEntryFeatureFlagEnabled] = featureFlags.useFeature(
    FeatureFlagsEnum.BP_GLOBAL_OPT_IN_CARD_DETAILS_ENTRY,
    false
  );

  const handleInvalidToken = useCallback(() => {
    history.push(generatePath(locations.invalidToken, { token, id: virtualCardId }));
  }, [history, token, virtualCardId]);

  const {
    virtualCardPaymentState,
    payment,
    deliveryMethod,
    virtualCard,
    organization,
    isPaymentLoading,
    issueNewCard,
  } = useVirtualCardData({
    token,
    virtualCardId,
    onInvalidToken: handleInvalidToken,
  });

  // TO-DO: replace 'true' value with the data from API
  const isEligibleForGlobalOptinExperiment =
    true && isBpGlobalOptInVCDetailsEntryFeatureFlagEnabled;

  const handleViewNewCardDetailsClick = (id?: number) => {
    history.push(generatePath(locations.details, { token, id }));
  };

  if (virtualCardPaymentState === VirtualCardPaymentState.Unknown) {
    return <AreaLoader />;
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.RecoverySuccess) {
    return (
      <RecoverySuccessLayout
        deliveryMethod={deliveryMethod}
        payment={payment}
        organization={organization}
        virtualCard={virtualCard}
      />
    );
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Recovered) {
    return (
      <RecoveredLayout
        processing={isPaymentLoading}
        deliveryMethod={deliveryMethod}
        payment={payment}
        virtualCard={virtualCard}
        onViewNewCardDetailsClick={handleViewNewCardDetailsClick}
      />
    );
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Expired) {
    return (
      <ExpiredLayout
        processing={isPaymentLoading}
        organization={organization}
        payment={payment}
        virtualCard={virtualCard}
        onIssueNewCardClick={issueNewCard}
      />
    );
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Cancelled) {
    return <CancelledLayout payment={payment} organization={organization} />;
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.NewDeliveryMethod) {
    return (
      <NewDeliveryMethodLayout
        organization={organization}
        deliveryMethod={deliveryMethod}
        payment={payment}
      />
    );
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.NewDeliveryMethodProcessed) {
    return (
      <NewDeliveryMethodProcessedLayout
        deliveryMethod={deliveryMethod}
        payment={payment}
        organization={organization}
      />
    );
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.NewDeliveryMethodScheduled) {
    return (
      <NewDeliveryMethodScheduledLayout
        deliveryMethod={deliveryMethod}
        payment={payment}
        organization={organization}
      />
    );
  }

  if (virtualCardPaymentState === VirtualCardPaymentState.Processed) {
    return (
      <ProcessedLayout
        payment={payment}
        deliveryMethod={deliveryMethod}
        virtualCard={virtualCard}
      />
    );
  }

  return (
    <ValidCardLayout
      organization={organization as Organization}
      payment={payment as unknown as Payment}
      virtualCard={virtualCard as unknown as VirtualCard}
      token={token}
      isGlobalOptInUpsellEnabled={isEligibleForGlobalOptinExperiment}
    />
  );
};

export { QBOVirtualCardDetailsPage };
