import * as React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import styled from 'styled-components';
import ErrorIcon from 'src/app/images/general/error-icon.svg';
import { devices } from 'src/app/theme/AppDevices';

type SizeType = 'inline' | 'wizard' | 'none';
type Props = {
  size?: SizeType;
  errorMessage?: string | null;
  errorMessageValues?: Record<string, any>;
  notices?: any[];
  testId?: string | null;
  align?: string;
  noticeValues?: Record<string, string | number>;
};

const QBRNotices = ({
  size = 'wizard',
  errorMessage,
  notices = [],
  errorMessageValues = {},
  testId,
  align = 'left',
  noticeValues = {},
}: Props) => (
  <Notices size={size} data-testid={testId}>
    {errorMessage && (
      <ErrorNotice size={size} data-testid="input-email-notice">
        <Icon src={ErrorIcon} />
        <MIFormattedText label={errorMessage} values={errorMessageValues} />
      </ErrorNotice>
    )}
    {!errorMessage &&
      notices &&
      notices.map((notice, index) => (
        <Notice key={index} size={size} align={align}>
          {typeof notice === 'string' && (
            <MIFormattedText label={notice} values={{ ...noticeValues }} />
          )}
          {typeof notice !== 'string' && notice}
        </Notice>
      ))}
  </Notices>
);

export default QBRNotices;

const Notices = styled.div<{ size?: SizeType }>`
  margin-top: ${(props) => (props.size === 'inline' ? '0' : '0.4rem')};
`;

const Notice = styled.label<{ size?: SizeType; align?: string }>`
  display: block;
  color: ${(props) => props.theme.text.color.darkGrey};
  font-size: ${(props) => (props.size === 'inline' ? '1rem' : '1.4rem')};
  line-height: 2rem;
  text-align: ${(props) => props.align};

  @media ${devices.desktop}, ${devices.tablet} {
    ${(props) => props.theme.text.fontType.medium}
  }
`;

const ErrorNotice = styled(Notice)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text.color.error};
  ${(props) => props.theme?.components?.MINotices?.ErrorNotice}
`;

const Icon = styled.img`
  padding: 0 0.8rem 0 0;
  height: 1.6rem;
  width: 1.6rem;
`;
