import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';

import { getSelectedFundingSource } from 'src/app/redux/payBillWizard/selectors';
import { withNavigator } from 'src/app/hoc';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';
import { AreaLoader } from '@melio/billpay-design-system';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import QBRLayoutPage from 'src/app/components/layout/QBRLayoutPage';

import useQBRVirtualDeliveryMethodState from './hooks/useQBRVirtualDeliveryMethodState';
import { ModelViewField } from 'src/app/ui/form';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';

type Props = {
  locationState: Record<string, any>;
  navigate: (arg0: string) => void;
  vendorId: string;
};

const QBRVirtualDeliveryMethodPageContainer = ({ navigate, vendorId }: Props) => {
  const fundingSource = useSelector(getSelectedFundingSource);

  const site = useSiteContext();
  const history = useHistory();
  const { virtualDeliveryMethodVM, submit, vendor, isFormProcessing, isVendorLoading, goExit } =
    useQBRVirtualDeliveryMethodState({
      vendorId,
      navigate,
      shouldIgnorePhone: true,
    });

  if (isVendorLoading) {
    return <AreaLoader />;
  }

  const companyName = vendor?.companyName.replace('-vendor', '');

  let subtitle = 'vendors.deliveryMethods.virtual.subtitle';
  const title = 'vendors.deliveryMethods.virtual.title';
  const email = 'vendors.deliveryMethods.virtual.email';
  const done = 'vendors.deliveryMethods.virtual.done';

  if (fundingSource?.fundingType === FundingSourceTypesEnum.ACH) {
    subtitle = 'vendors.deliveryMethods.virtual.checkFee.subtitle';
  }

  return (
    <QBRLayoutPage
      headerLabel="qbr.new.selectDeliveryMethod.title"
      title={title}
      titleValues={{ vendor: companyName }}
      subtitle={subtitle}
      subTitleValues={{ vendor: companyName }}
      goExit={goExit}
      onNext={() => submit()}
      onPrev={() => history.goBack()}
      nextLabel={done}
      isLoading={isFormProcessing}
      innerSize={50}
      footer={
        <site.components.WizardElements.SimpleTextFooter>
          <MIFormattedText
            label="vendors.deliveryMethods.virtual.footer"
            values={{ vendor: companyName }}
          />
        </site.components.WizardElements.SimpleTextFooter>
      }
    >
      <FormContainer>
        <WizardTextInputField
          label={email}
          model={virtualDeliveryMethodVM.virtualAccount.email as ModelViewField<string>}
          required
          type="email"
          autoFocus
          autocomplete="username email"
          privateData
        />
      </FormContainer>
    </QBRLayoutPage>
  );
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 4rem;
  }
`;

export default compose(withNavigator())(QBRVirtualDeliveryMethodPageContainer);
