import styled from 'styled-components';
import { AreaLoaderPlacement } from '../../types';

const backgroundColors: Record<AreaLoaderPlacement, string> = {
  list: 'transparent',
  single: 'transparent',
  wizard: 'rgba(250, 250, 250, 1)',
  melioMe: 'rgba(250, 250, 250, 0.9)',
};

export const AreaLoaderContainer = styled.div<{placement: AreaLoaderPlacement}>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: ${(props) => (props.placement === 'list' ? '30vh' : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: ${(props) => props.placement && backgroundColors[props.placement]};
`;

export const AreaLoaderInner = styled.div``;
