import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import clockIcon from 'src/app/images/general/clock.svg';
import { WizardStepTitle, WizardStepSubTitle } from 'src/app/components/layout/QBOWizardElements';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS, BUTTON_SIZE } from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import { getOrgId } from 'src/app/redux/user/selectors';
import { StyledQBOLayoutPage } from '../styles';

const FORMAT_PREFIX = 'settings.billing.feeCard.checks.pages.pendingBankAccount';

export const PendingAccountVerification = () => {
  const history = useHistory();
  const location = useLocation<{ newUnverifiedBank: number; flow: string }>();
  const orgId = useSelector(getOrgId);

  const onClick = () => {
    history.push(
      locations.Onboarding.fundingSources.fee.checks.selectFundingSource.url({ orgId }),
      {
        newUnverifiedBank: location?.state?.newUnverifiedBank,
        flow: location?.state?.flow,
      }
    );
  };

  return (
    <StyledQBOLayoutPage isCentered qboFooter={<div />}>
      <Centered id="udi">
        <Icon src={clockIcon} />
        <WizardStepTitle>
          <MIFormattedText label={`${FORMAT_PREFIX}.title`} />
        </WizardStepTitle>
        <WizardStepSubTitle>
          <MIFormattedText label={`${FORMAT_PREFIX}.description`} />
        </WizardStepSubTitle>
        <MIButton
          {...{ onClick }}
          size={BUTTON_SIZE.NORMAL}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label={`${FORMAT_PREFIX}.cta`}
        />
      </Centered>
    </StyledQBOLayoutPage>
  );
};

const Icon = styled.img`
  width: 12rem;
  height: 12rem;
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
