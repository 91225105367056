import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';

import { unregister } from './app/registerServiceWorker';
import { dataDog } from './app/utils/dataDog';
import { initLoggers } from './app/version-2/api/loggers';
import App from './app/App';

dataDog.init();
initLoggers();

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

unregister();
