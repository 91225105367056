import React from 'react';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import lockIcon from 'src/app/images/qbo/no-access-icon.png';
import analytics from 'src/app/services/analytics';
import { melioClose } from 'src/app/utils/external-events';

const WrongAccount = () => {
  const goBackToQuickbooks = () => {
    analytics.track('pay-bill-wrong-realmId', 'close-integration');
    melioClose();
  };

  return (
    <ErrorPage
      illustration={lockIcon}
      title="bills.view.error.wrongAccountTitle"
      subtitle="bills.view.error.wrongAccountSubtitle"
      buttonAction={goBackToQuickbooks}
      buttonLabel="bills.view.error.buttonTextWrongAccount"
    />
  );
};

export default WrongAccount;
