import { extendTheme, defaultTheme } from '@melio/billpay-design-system';
import foundations from './foundations';
import typography from './typography';
import breakpoints from './breakpoints';

const theme = extendTheme(
  {
    ...typography,
    ...foundations,
  },
  {
    config: defaultTheme.config,
    direction: defaultTheme.direction,
    transition: defaultTheme.transition,
    zIndices: defaultTheme.zIndices,
    breakpoints,
    components: {},
    styles: {},
    borders: {},
    colors: {},
    radii: {},
    shadows: {},
    sizes: {},
    space: {},
    fonts: {},
    fontSizes: {},
    fontWeights: {},
    letterSpacings: {},
    lineHeights: {},
  }
);

export default theme;
