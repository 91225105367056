import { devices } from 'src/app/theme/AppDevices';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import intuit from 'src/app/utils/intuit';
import { envApi } from 'src/app/version-2/api/env';
import { QBRFooterContainer } from 'src/app/components/layout/QBRElements';
import { ReactComponent as MoneyOutLogo } from 'src/app/images/qbr/money-out-logo.svg';
import MIButton from 'src/app/components/common/MIButton';
import { BUTTON_SIZE, BUTTONS_DIRECTIONS, CONSTS } from 'src/app/utils/consts';
import { melioClose } from 'src/app/utils/external-events';

const QBRBlockedUserPageContainer = () => {
  useEffect(() => {
    intuit.endLoadingWrapper();
    intuit.hideFTULoadingWrapper();
  }, []);

  return (
    <PageContainer>
      <MoneyOutLogo />
      <Content>
        <TitleContainer textAlign="center">
          <MIFormattedText label="qbr.blockedUser.title" />
        </TitleContainer>

        <SubtitleContainer textAlign="center">
          <MIFormattedText
            label="qbr.blockedUser.subTitle"
            values={{
              supportEmail: (
                <Link href={`mailto:${envApi.getQBRSpecificConfig().support.email}`}>
                  {envApi.getQBRSpecificConfig().support.email}
                </Link>
              ),
              contactPhone: (
                <Link href={`tel:${envApi.getQBRSpecificConfig().support.phone}`}>
                  {envApi.getQBRSpecificConfig().support.phone}
                </Link>
              ),
            }}
          />
        </SubtitleContainer>

        <ButtonContainer buttonsDirection={BUTTONS_DIRECTIONS.HORIZONTAL}>
          <MIButton
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={melioClose}
            label="qbr.blockedUser.cta"
            fullWidth
            size={BUTTON_SIZE.VERY_SMALL}
            isProcessing={false}
            disabled={false}
          />
        </ButtonContainer>
      </Content>

      <Footer>
        <QBRFooterContainer />
      </Footer>
    </PageContainer>
  );
};

const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.link};

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  padding: 4rem 1.3rem;
`;

const directions = {
  [BUTTONS_DIRECTIONS.VERTICAL]: {
    dir: 'column-reverse',
    margin: 'margin-bottom: 1.2rem;',
  },
  [BUTTONS_DIRECTIONS.HORIZONTAL]: {
    dir: 'row',
    margin: 'margin-left: 1.2rem;',
  },
};

const TitleContainer = styled.div<{ textAlign: string }>`
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: ${(props) => props.theme.text.lineHeight.bigTitleM};
  text-align: ${(props) => props.textAlign};

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 2rem;
  }
`;

const SubtitleContainer = styled.div<{ textAlign: string }>`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.thin};
  text-align: ${(props) => props.textAlign};
  ${(props) => props.theme.text.fontType.regular};

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 1.4rem;
  }
  ${(props) => props.theme?.components?.QBOMIDialog?.SubtitleContainer}
`;

const ButtonContainer = styled.div<{ buttonsDirection: BUTTONS_DIRECTIONS }>`
  min-width: 11.8rem;
  text-align: center;
  > button {
    width: 100%;
  }

  &:not(:first-child) {
    ${(props) => directions[props.buttonsDirection].margin};
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    padding: 0 0.2rem;
    &:not(:first-child) {
      margin: 1.2rem 0;
    }
  }

  ${(props) => props.theme?.components?.QBOMIDialog?.ButtonContainer}
`;

export default QBRBlockedUserPageContainer;
