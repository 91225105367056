import pay from './pay/location';

const LOCATIONS = {
  base: ['/orgs/:orgId/bills/new'].concat(pay.base),
  create: {
    index: '/orgs/:orgId/bills/new',
    options: '/orgs/:orgId/bills/new/create-options',
  },
  pay,
  refund: '/orgs/:orgId/bill/:billId/payment/:paymentId/refund',
  successScheduledRefund: '/orgs/:orgId/bill/:billId/payment/:paymentId/refund-success',
};

export default LOCATIONS;
