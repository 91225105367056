import React from 'react';
import Flag from 'react-flagpack';

const mapper = (code: string) => {
  switch (code) {
    case 'UK':
    case 'GB':
      return 'GB-UKM';

    default:
      return code;
  }
};

export const Flags = ({ code, ...rest }) => {
  if (!code) return null;

  const countryCode = mapper(code.toUpperCase());

  return <Flag code={countryCode} size="m" {...rest} />;
};
