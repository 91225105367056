import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateCardAccountRequest } from 'src/app/version-2/pages/add-card-account/api/fundingSource/requests/CreateCardAccountRequest';
import { ValidateCardAccountRequest } from 'src/app/version-2/pages/add-card-account/api/fundingSource/requests/ValidateCardAccountRequest';
import { CreateCardAccountResponse } from 'src/app/version-2/pages/add-card-account/api/fundingSource/responses/CreateCardAccountResponse';
import { ADD_CARD_ACCOUNT } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.reducer';

export const ADD_CARD_ACCOUNT_FUNDING_SOURCE_SLICE = 'addCardAccountAsFundingSource';
export const selectState = (state) =>
  state[ADD_CARD_ACCOUNT][
    ADD_CARD_ACCOUNT_FUNDING_SOURCE_SLICE
  ] as AddCardAccountFundingSourceSlice;

export interface AddCardAccountFundingSourceSlice {
  validationStatus: {
    error?: any;
    code?: string;
    message?: string;
  };
  createCardAccountStatus: CreateCardAccountResponse;
}

export const getAddCardAccountFundingSourceSlice = (): AddCardAccountFundingSourceSlice => ({
  validationStatus: {},
  createCardAccountStatus: {},
});

export const addCardAccountFundingSourceSlice = createSlice({
  name: ADD_CARD_ACCOUNT_FUNDING_SOURCE_SLICE,
  initialState: getAddCardAccountFundingSourceSlice(),
  reducers: {
    setValidateCardStatus: (
      state,
      action: PayloadAction<{
        message?: string;
        code?: string;
        error?: {
          message?: string;
          code: string;
        };
      }>
    ) => {
      state.validationStatus = action.payload;
    },
    resetValidationCardStatus: (state) => {
      state.validationStatus = {};
    },
    setCreateCardAccountStatus: (state, action: PayloadAction<CreateCardAccountResponse>) => {
      state.createCardAccountStatus = action.payload;
    },
  },
});

export const addCardAccountFundingSourceActions = {
  ...addCardAccountFundingSourceSlice.actions,
  validateCardAccount: createAction<
    ValidateCardAccountRequest & {
      location: any;
      history: any;
    }
  >(`${ADD_CARD_ACCOUNT_FUNDING_SOURCE_SLICE}/validateCard`),
  createCardAccount: createAction<CreateCardAccountRequest>(
    `${ADD_CARD_ACCOUNT_FUNDING_SOURCE_SLICE}/createCardAccount`
  ),
};

const selectValidationCardStatus = createSelector(selectState, (state) => state.validationStatus);
const selectCardAccountStatus = createSelector(
  selectState,
  (state) => state.createCardAccountStatus
);

export const addCardFundingSourceSelectors = {
  selectValidationCardStatus,
  selectCardAccountStatus,
};
