import React, { useEffect } from 'react';
import analytics from 'src/app/services/analytics';
import {
  CreditCardBenefitList,
  CreditCardBenefitsButtonContainer,
  CreditCardBenefitsModalStyled,
} from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefits.styled';
import { CreditCardBenefitTile } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitTile';
import { ReactComponent as CalendarIcon } from 'src/app/images/credit-card-benefits/calendar.svg';
import { ReactComponent as TrophyIcon } from 'src/app/images/credit-card-benefits/trophy.svg';
import { ReactComponent as CardPaymentIcon } from 'src/app/images/credit-card-benefits/card-payment.svg';
import Box from 'src/app/core/ds/box/Box';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS, IGNORE_OUTSIDE_CLICK_CLASS } from 'src/app/utils/consts';
import { useBreak } from 'src/app/hoc';
import { getPartialBillId } from 'src/app/utils/bills';

export interface CreditCardBenefitsModalProps {
  onButtonClick?: () => any;
  onCloseClick?: () => any;
  analyticsProperties?: { bill?: any; vendorId?: string | number };
  isLoading?: boolean;
  shouldDisplayButtonComponent?: false;
}

export const CreditCardBenefitsModal = (props: CreditCardBenefitsModalProps) => {
  const {
    onButtonClick,
    onCloseClick,
    analyticsProperties,
    isLoading,
    shouldDisplayButtonComponent,
  } = props;
  const { isMobile } = useBreak();

  useEffect(() => {
    let analyticsProps;

    if (analyticsProperties) {
      analyticsProps = {
        billId: analyticsProperties.bill?.id,
        vendorId: analyticsProperties.vendorId,
        partialBillId: analyticsProperties.bill && getPartialBillId(analyticsProperties.bill),
      };
      analytics.track('pay-bill', 'credit-card-benefits-modal_open', analyticsProps);
    }

    return () => {
      if (analyticsProps) {
        analytics.track('pay-bill', 'credit-card-benefits-modal_close', analyticsProps);
      }
    };
  }, []);

  return (
    <ModalMessage
      className={IGNORE_OUTSIDE_CLICK_CLASS}
      titleComponent={
        <Box textStyle="h6" fontWeight="ds.semi">
          <MIFormattedText label="creditCardBenefits.title" />
        </Box>
      }
      onCloseClick={onCloseClick}
      shouldDisplayButtonComponent={shouldDisplayButtonComponent}
      buttonComponent={
        <CreditCardBenefitsButtonContainer>
          <MIButton
            onClick={onButtonClick}
            label="creditCardBenefits.button"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            analyticsProperties={analyticsProperties}
            fullWidth={isMobile}
            isProcessing={isLoading}
          />
        </CreditCardBenefitsButtonContainer>
      }
      contentComponent={
        <CreditCardBenefitsModalStyled>
          <CreditCardBenefitList>
            <CreditCardBenefitTile
              title="creditCardBenefits.deferPayments.title"
              icon={CalendarIcon}
              text="creditCardBenefits.deferPayments.label"
            />
            <CreditCardBenefitTile
              title="creditCardBenefits.earnRewards.title"
              icon={TrophyIcon}
              text="creditCardBenefits.earnRewards.label"
            />
            <CreditCardBenefitTile
              title="creditCardBenefits.fasterDelivery.title"
              icon={CardPaymentIcon}
              text="creditCardBenefits.fasterDelivery.label"
            />
          </CreditCardBenefitList>
          <Box textStyle="body3" textAlign={isMobile ? 'center' : 'right'}>
            <MIFormattedText label="creditCardBenefits.bottomLabel" />
          </Box>
        </CreditCardBenefitsModalStyled>
      }
    />
  );
};
