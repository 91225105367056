import React, { useState } from 'react';
import analytics from 'src/app/services/analytics';
import styled from 'styled-components';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { MIFormattedText } from 'src/app/utils/formatting';
import { VendorType } from 'src/app/utils/types';
import { isEnterPressed } from 'src/app/utils/events';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import { useVendorCollectEmailSubmit } from 'src/app/modules/vendors/vendor-email/hooks/useVendorCollectEmailSubmit';
import { VendorEmailField } from 'src/app/modules/vendors/vendor-email/components/VendorEmailField';

type Props = {
  dismiss: () => void;
  onSuccess: () => void;
  payment: JustPayPaymentType;
  vendor: VendorType;
  eventPage: string;
};

export const JustPayCollectVendorEmailModalMessage = ({
  dismiss,
  onSuccess,
  payment,
  vendor,
  eventPage,
}: Props) => {
  const [contactEmail, setContactEmail] = useState('');

  const vendorName = vendor?.companyName;
  const vendorId = vendor?.id;
  const pendingApproval = payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;

  const analyticsProps = {
    vendorId,
    screenName: 'success',
    trackingBillId: payment?.trackingBillId,
  };

  const handleCollectVendorEmailModalCancel = () => {
    analytics.track(eventPage, 'vendor-email-modal-closed');
    dismiss();
  };

  const { handleCollectVendorEmailModalSubmit, validationErrors, isProcessing } =
    useVendorCollectEmailSubmit({
      contactEmail,
      vendorId,
      deliveryType: payment.deliveryMethodType,
      scheduledDate: payment.suggestedDates.scheduledDate,
      paymentId: payment.createdPayment.id,
      onSubmitStart: () => {
        analytics.track(eventPage, 'vendor-email-modal-save', analyticsProps);
      },
      onValidationErrors: (validationErrors) => {
        analytics.track(eventPage, 'vendor-email-modal-save-validation-error', validationErrors);
      },
      onSubmitSuccess: () => {
        analytics.track(eventPage, 'vendor-email-modal-save-success', {
          vendorEmail: contactEmail,
          ...analyticsProps,
        });
        dismiss();
        onSuccess();
      },
    });

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      handleCollectVendorEmailModalSubmit();
    }
  };

  return (
    <ModalMessage
      titleComponent={
        <>
          <div className="title">
            <MIFormattedText
              label="bills.pay.collectVendorEmailModal.title"
              values={{
                vendorName: <StyledVendorName>{vendorName}</StyledVendorName>,
              }}
            />
          </div>
          <div className="text">
            <MIFormattedText
              label="bills.pay.collectVendorEmailModal.subTitle"
              values={{ vendorName }}
            />
          </div>
          <PendingNotification className="text">
            {pendingApproval && (
              <MIFormattedText label="bills.pay.collectVendorEmailModal.pending" />
            )}
          </PendingNotification>
        </>
      }
      contentComponent={
        <FormContainer onKeyDown={onKeyPressed}>
          <VendorEmailField
            value={contactEmail}
            onChange={({ value }) => setContactEmail(value)}
            validationError={validationErrors.contactEmail}
            autoFocus
            required
          />
        </FormContainer>
      }
      buttonLabel="bills.pay.collectVendorEmailModal.sendEmail"
      onButtonClick={handleCollectVendorEmailModalSubmit}
      onCloseClick={handleCollectVendorEmailModalCancel}
      isLoading={isProcessing}
    />
  );
};

const FormContainer = styled.div`
  width: 100%;

  #contactEmail {
    font-size: ${(props) => props.theme.text.size.regular};
  }
`;

const StyledVendorName = styled.span`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};

  ${(props) => props.theme?.pages?.PayBillSuccessPage?.StyledVendorName}
`;

const PendingNotification = styled.div`
  margin-top: 1rem;
`;
