import { saveAs } from 'file-saver';

const createPdfFile = (base64, file) => {
  const binary = atob(base64.replace(/\s/g, ''));
  const buffer = new ArrayBuffer(binary.length);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < binary.length; i += 1) {
    view[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([view], { type: 'application/pdf' });
  const fileName = `${file}.pdf`;

  saveAs(blob, fileName);
};

export { createPdfFile };
