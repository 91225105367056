import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import analytics from 'src/app/services/analytics';
import { CardNetworkEnum } from 'src/app/version-2/model/enums';
import vendorsApi from 'src/app/services/api/vendors';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useApi } from 'src/app/hoc/useApi';
import { getPartialBillId } from 'src/app/utils/bills';
import { FundingSource } from 'src/app/version-2/model/dtos';

import Modal from './Modal';

type AmexModalPropsType = {
  onNext?: () => void;
  ctaLabel?: string;
  isBatch?: boolean;
};

export const useAmexVerification = () => {
  const [vendorName, setVendorName] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [billId, setBillId] = useState('');
  const [analyticsData, setAnalyticsData] = useState({});
  const orgId = useSelector(getOrgId);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [getMccCode, , isLoading] = useApi(vendorsApi.getVendorMccCode);
  const [MCCCodes, setMCCCodes] = useState({});

  const setVendorMCCCodes = useCallback(
    ({ vendorId, mccCode }) => {
      if (!vendorId || !mccCode) {
        throw new Error();
      }

      setMCCCodes({ ...MCCCodes, [vendorId]: mccCode });
    },
    [MCCCodes]
  );

  const openAmexModal = ({ bill, vendorName, vendorId }) => {
    setVendorName(vendorName);
    setVendorId(vendorId);
    setBillId(bill?.id || '');
    setAnalyticsData({
      billId: bill?.id,
      vendorId,
      partialBillId: getPartialBillId(bill),
    });
    setIsOpen(true);
  };
  const closeAmexModal = () => {
    analytics.track('pay-bill', 'amex-industry-modal_close', analyticsData);

    setIsOpen(false);
    setAnalyticsData({});
    setVendorName('');
    setVendorId('');
    setBillId('');
  };

  const shouldDisplayAmexVerification = useCallback(
    async (fundingSource: FundingSource | undefined, vendorId: number): Promise<boolean> => {
      const isAmexCard = fundingSource?.cardAccount?.network === CardNetworkEnum.AMEX;

      if (!isAmexCard) return false;

      const localMCC = isVendorHasMCCCode({ vendorId });

      if (localMCC) {
        return false;
      }

      if (vendorId) {
        const { mccCode } = await getMccCode(orgId, vendorId);

        return !mccCode;
      }

      return false;
    },
    [MCCCodes]
  );

  const isVendorHasMCCCode = ({ vendorId }) => !!MCCCodes[vendorId];

  const AmexModal = ({ onNext, ctaLabel, isBatch }: AmexModalPropsType) => {
    if (!isOpen) return null;

    return (
      <Modal
        vendorName={vendorName}
        vendorId={vendorId}
        billId={billId}
        analyticsData={analyticsData}
        onClose={closeAmexModal}
        orgId={orgId}
        onNext={onNext}
        onUpdate={setVendorMCCCodes}
        ctaLabel={ctaLabel}
        isBatch={isBatch}
      />
    );
  };

  return {
    MCCCodes,
    shouldDisplayAmexVerification,
    AmexModal,
    openAmexModal,
    closeAmexModal,
    amexLoading: isLoading,
  };
};
