import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { Box } from '@melio/billpay-design-system';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { AreaLoader } from '@melio/billpay-design-system';
import Dialog from 'src/app/components/common/MIDialog';
import { useApi } from 'src/app/hoc/useApi';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { Header } from 'src/app/pages/vendor/components/VendorLayoutElements';
import { devices } from 'src/app/theme/AppDevices';
import analytics from 'src/app/services/analytics';
import deliveryMethodsApi from 'src/app/services/api/deliveryMethods';
import { ShiftToDebitState } from 'src/app/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import { SupportLine } from 'src/app/components/common/OutsideLayout/SupportLine';
import QBOPushToDebitFooter from 'src/app/pages/vendor/components/QBOPushToDebitFooter';
import { useBreak } from 'src/app/hoc';
import { getTransactionFee } from 'src/app/pages/bill/utils';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from 'src/app/pages/vendor/vendor-payment-tracking/locations';
import { upgradePaymentLocations } from 'src/app/pages/vendor/upgrade-payment/locations';
import { getIsVendorPaymentDetailsFlow } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import { envApi } from 'src/app/version-2/api/env';
import { AddCardAccountRenderer } from 'src/app/version-2/pages/add-card-account/renderers/AddCardAccount.renderer';
import { ADD_DELIVERY_METHOD_WIZARD_ORIGIN } from 'src/app/version-2/model/constants';
import locations from './locations';
import { analyticsApi } from 'src/app/services/analytics/guest-analytics-api';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
type Props = {
  token: string;
  state: ShiftToDebitState;
};

const AddDeliveryMethodPage = ({ token, state }: Props) => {
  const history = useHistory();
  const site = useSiteContext();
  const [errorCode, setErrorCode] = useState('');
  const [cardData, setCardData] = useState({});
  const { payment, organization, filesUrls, isPaymentLoading, fee } = state;

  const { Tag } = getJWTPayload(token);

  const [isBasisTheoryFlagEnabled, isBasisTheoryFlagLoading] = featureFlags.useFeature(
    FeatureFlagsEnum.BP_BASIS_THEORY,
    true,
    {
      shouldTrack: false,
    }
  );

  const [checkCard, result, isChecking, error] = useApi<
    [{ token: string; cardToken: string; cardBin: string }],
    any
  >(deliveryMethodsApi.checkCardWithToken);

  const transactionFee = getTransactionFee(payment?.amount as number);
  const isCap = transactionFee === fee?.cap;
  const iframeResponse = 'iframe-response';
  const iframeErrorResponse = 'iframe-error-response';
  const IframeLoadedSuccess = 'iframe-loaded-success';
  const iframeSuccessResponse = 'iframe-success-response';
  const tabaPay = 'taba-pay';

  const { isDesktop, isPhablet } = useBreak();
  const iFrameWidth = isDesktop || isPhablet ? '700px' : '200px';
  const iFrameHeight = site.theme?.pages?.AddCardAccountsPage?.iFrameHeight || '470px';

  const pfReceivedMessage = useCallback(
    async (event) => {
      // If event origin is not from TabaPay's iframe, skip this part
      if (!get(envApi.getConfig(), 'services.tabapay.url', '').startsWith(get(event, 'origin', '')))
        return;

      try {
        if (event.data === 'Close') setErrorCode(iframeErrorResponse);

        analytics.track(tabaPay, iframeResponse);

        if (event.data.slice(0, 7) === 'Error: ') {
          analytics.track(tabaPay, iframeErrorResponse);
          setErrorCode(iframeErrorResponse);
        }

        const [digitsAndBin, expiration, cardToken] = event.data.split('|');

        analytics.track(tabaPay, `${iframeResponse}-data`);

        if (!token) setErrorCode(iframeErrorResponse);

        const digits = (digitsAndBin || '0000').slice(-4);
        const cardBin = digitsAndBin.length === 10 ? digitsAndBin.substring(0, 6) : null;

        analytics.track('shift-to-debit', 'check-card-details');
        setCardData({
          card: {
            digits,
            cardToken,
            cardBin,
            expiration,
          },
        });
        await checkCard({
          token,
          cardToken,
          cardBin,
        });
      } catch (err: any) {
        analytics.track(tabaPay, iframeErrorResponse);
        loggingApi.error('QBOAddDeliveryMethodPage.pfReceivedMessage(): error', {
          error: err,
          message: `${tabaPay}${iframeErrorResponse}`,
        });
        setErrorCode(iframeErrorResponse);
        setCardData({});
      }
    },
    [checkCard, token]
  );

  const loadIframeError = () => {
    analytics.track(tabaPay, iframeErrorResponse);
    setErrorCode(iframeErrorResponse);
    const error = new Error(`${iframeErrorResponse}-test`);

    loggingApi.error('QBOAddDeliveryMethodPage.loadIframeError(): Iframe error', {
      error,
      message: `${tabaPay}${iframeErrorResponse}`,
    });
  };

  const loadIframeSuccess = () => {
    analytics.track(tabaPay, IframeLoadedSuccess);
  };

  const clearError = () => setErrorCode('');

  useEffect(() => {
    if (isBasisTheoryFlagEnabled) {
      return undefined;
    }

    window.addEventListener('message', pfReceivedMessage, false);

    return () => window.removeEventListener('message', pfReceivedMessage, false);
  }, [pfReceivedMessage, isBasisTheoryFlagEnabled]);

  useEffect(() => {
    if (result && !isBasisTheoryFlagEnabled) {
      analytics.track(tabaPay, iframeSuccessResponse);
      history.push(generatePath(locations.addAddress, { token }), cardData);
    }
  }, [result, cardData, token, history, isBasisTheoryFlagEnabled]);

  useEffect(() => {
    if (error) {
      setErrorCode(get(error, 'code') || get(error, 'response.status'));
    }
  }, [error]);

  useEffect(() => {
    if (!payment) return;

    const { vendor, id, organization } = payment;

    analyticsApi.track({
      token,
      eventName: 'P2D-VendorOpened',
      properties: {
        vendorId: vendor?.id,
        vendorEmail: vendor?.contactEmail,
        paymentId: id,
        payorOrgId: organization?.id,
        payorUserId: organization?.userOrganizations?.[0]?.userId,
        emailTag: Tag,
      },
    });
  }, [payment]);

  const onPrev: VoidFunction | null = useMemo(() => {
    const isVendorPaymentDetailsFlow = getIsVendorPaymentDetailsFlow(token);
    const isUserCameFromExpeditePaymentPage =
      sessionStorage.getItem('expediteFromTrackPayment') === 'true';

    if (isVendorPaymentDetailsFlow && isUserCameFromExpeditePaymentPage) {
      return () => history.push(generatePath(upgradePaymentLocations.index, { token }));
    }

    if (isVendorPaymentDetailsFlow) {
      return () =>
        history.push(generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token }));
    }

    return null;
  }, [token]);

  if (isPaymentLoading || isBasisTheoryFlagLoading) {
    return <AreaLoader />;
  }

  const feeDescriptionLabel = isCap ? 'descriptionNoFeeValue' : 'description';

  const tabaPayFlow = () => (
    <>
      {errorCode && (
        <Dialog
          type={DIALOG_TYPE.CONFIRM}
          variant={DIALOG_VARIANTS.WARNING}
          maxWidth="60rem"
          minHeight="0rem"
          hideIcon
          title={
            errorCode === iframeErrorResponse
              ? 'vendors.deliveryMethods.shiftToDebit.errorTitle'
              : 'onboarding.fundingSources.card.notSupportedTitle'
          }
          subtitle={
            errorCode === iframeErrorResponse
              ? 'vendors.deliveryMethods.shiftToDebit.errorCard'
              : 'vendors.deliveryMethods.shiftToDebit.notSupportedSubtitle'
          }
          okButtonText="dialogs.buttons.close"
          cancelButtonText="dialogs.buttons.close"
          onOkAction={clearError}
          showCancel={false}
          onCancelAction={clearError}
        />
      )}
      <IframeContainer>
        {isChecking && (
          <LoaderContainer>
            <AreaLoader />
          </LoaderContainer>
        )}
        <TabapayIframe
          isLoading={isChecking}
          onLoad={loadIframeSuccess}
          onError={loadIframeError}
          title="taba pay SSO"
          id="sso"
          src={envApi.getConfig().services.tabapay.url}
          frameBorder="0"
          scrolling="no"
          width={iFrameWidth}
          height={iFrameHeight}
        />
      </IframeContainer>
    </>
  );

  const cardProvider = isBasisTheoryFlagEnabled ? (
    <AddCardAccountRenderer
      location={{
        state: {
          redirectUrl: locations.addAddress,
          preservedState: {
            origin: ADD_DELIVERY_METHOD_WIZARD_ORIGIN,
          },
        },
      }}
      token={token}
      isTitle={false}
      isFooter={false}
    />
  ) : (
    tabaPayFlow()
  );

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <Box textAlign="center" marginBottom={5} marginTop={10}>
        <QBLogo />
      </Box>
      <Content>
        <Header
          onPrev={onPrev}
          payment={payment}
          organization={organization}
          description={`vendors.deliveryMethods.shiftToDebit.${feeDescriptionLabel}`}
          subTitle="vendors.deliveryMethods.shiftToDebit.subtitle"
          filesUrls={filesUrls}
          hideLogo
          note={payment?.note}
          showFullHeader
          descriptionValues={{
            fee: <MIFormattedCurrency value={transactionFee?.toString()} />,
          }}
        />
        <SeparatorContainer>
          <Separator />
        </SeparatorContainer>
        <ContentContainer isMobile={!isDesktop && !isPhablet}>
          <ContentTitle isMobile={!isDesktop && !isPhablet}>
            <MIFormattedText label="vendors.deliveryMethods.shiftToDebit.title" />
          </ContentTitle>
          <ContentSubTitle isMobile={!isDesktop && !isPhablet}>
            <MIFormattedText label="vendors.deliveryMethods.shiftToDebit.contentSubtitle" />
          </ContentSubTitle>
          {cardProvider}
          <QBOPushToDebitFooter companyName={organization?.companyName} />
        </ContentContainer>
      </Content>
      <SupportLine __css={supportLineStyle} />
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  position: relative;
  background: ${(props) => props.theme?.colors?.white?.opaque};
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  border: 1px solid ${(props) => props.theme?.colors?.shadow?.lightGrey};
  box-sizing: border-box;
`;

const SeparatorContainer = styled.div`
  padding-right: 4rem;
  padding-left: 4rem;
  margin-top: 0.5rem;
`;

const Separator = styled.div`
  width: 100%;
  height: 0.4rem;
  background-color: ${(props) => props.theme.background.wizard};
`;

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
`;

const TabapayIframe = styled.iframe<{ isLoading?: boolean }>`
  opacity: ${(props) => (props.isLoading ? '0.2' : '1')};
  width: 65rem;
  height: 34rem;
  margin-left: -10rem;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    margin-left: 0rem;
    height: 40rem;
  }
`;

const IframeContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-bottom: -1rem;
  height: 30rem;
`;

const ContentContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white.opaque};
  padding: ${(props) => (props.isMobile ? '1rem' : '4rem')};
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  ${(props) => props.theme?.components?.UnilateralElements?.ContentContainer}
`;

const ContentTitle = styled.div<{ isMobile?: boolean }>`
  font-weight: 600;
  text-align: left;
  font-size: 1.8rem;
  margin-bottom: 0.4rem;
  padding: ${(props) => (props.isMobile ? '1.6rem' : '')};
`;

const labelStyles = css`
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.hint};
`;

const ContentSubTitle = styled.div<{ isMobile?: boolean }>`
  ${labelStyles}
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: ${(props) => (props.isMobile ? '1.6rem' : '')};
`;

const supportLineStyle = {
  m: ['2rem 0 5rem', '2rem 0'],
};

export default AddDeliveryMethodPage;
