import React from 'react';
import { Box } from '@melio/billpay-design-system';
import { containerStyle, labelStyle, StyledLoader } from './SyncingBillsSpinner.styled';
import { MIFormattedText } from 'src/app/utils/formatting';

export const SyncingBillsSpinner = () => (
  <Box __css={containerStyle}>
    <StyledLoader context="button" />
    <Box __css={labelStyle}>
      <MIFormattedText label="paymentDashboard.search.filters.syncingBills" />
    </Box>
  </Box>
);
