import React, { memo } from 'react';
import { Box, Image } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import VirtualCardDesktopExampleImage from 'src/app/images/qbo/vc-details/virtual-card-example-desktop.jpg';
import TerminalDesktopExampleImage from 'src/app/images/qbo/vc-details/terminal-example-desktop.jpg';
import VirtualCardMobileExampleImage from 'src/app/images/qbo/vc-details/virtual-card-example-mobile.jpg';
import TerminalMobileExampleImage from 'src/app/images/qbo/vc-details/terminal-example-mobile.jpg';
import {
  UnilateralContentContainer,
  ItemContainer,
  ItemContentContainer,
} from './QBOVirtualCardUnilateralInfoContent.styles';
import dictionary from '../VirtualCardInfoPageDictionary';

export const QBOVirtualCardUnilateralInfoContent = memo(({ isMobile }: { isMobile: boolean }) => {
  const VirtualCardImage = isMobile
    ? VirtualCardMobileExampleImage
    : VirtualCardDesktopExampleImage;
  const TerminalImage = isMobile ? TerminalMobileExampleImage : TerminalDesktopExampleImage;

  const infoItems = [
    { id: 'cardExample', src: VirtualCardImage },
    { id: 'terminalExample', src: TerminalImage },
  ];

  return (
    <Box data-testid="qbo-virtual-card-unilateral-info-content">
      <UnilateralContentContainer>
        {infoItems.map((infoItem) => (
          <ItemContainer key={infoItem.id}>
            <ItemContentContainer isMobile={isMobile}>
              <Box fontWeight="ds.semi" textStyle={isMobile ? 'h5' : 'body1'} mb={1}>
                <MIFormattedText label={dictionary.unilateral.title(infoItem.id)} />
              </Box>
              <Box textAlign="center" textStyle="body3">
                <MIFormattedText label={dictionary.unilateral.subtitle(infoItem.id)} />
              </Box>
            </ItemContentContainer>
            <Image src={infoItem.src} width="full" borderRadius="1rem" />
          </ItemContainer>
        ))}
      </UnilateralContentContainer>
    </Box>
  );
});
