import { format, parseISO } from 'date-fns';

import { CheckType, PaymentStatusDates, PaymentType } from 'src/app/utils/types';
import billsApi from 'src/app/services/api/bills';
import { DELIVERY_TYPE, FAILED_PAYMENT_MESSAGE_TYPES } from 'src/app/utils/consts';
import { DeliveryEnum, PaymentCollectStatusEnum } from 'src/app/version-2/model/enums';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { DATE_FORMATS } from 'src/app/utils/date-fns';
import { getFormattedACH, getFormattedAddress } from 'src/app/utils/formatting';
import {
  TIMELINE_STEPS,
  TIMELINE_STEP_BY_DELIVERY_TYPE,
} from 'src/app/version-2/model/constants/timelineSteps.consts';
import { RecordOf } from 'immutable';

export const getQBOBillAttachments = async (originId, orgId) => {
  let result;

  if (originId && originId !== '-1') {
    try {
      result = await billsApi.getQBOBillAttachments({
        orgId,
        originId,
      });

      return result?.attachments.map((a) => ({
        name: a.FileName,
        url: a.TempDownloadUri,
      }));
    } catch {
      return null;
    }
  }

  return null;
};

export const getIsReturnedCheckFailure = (paymentMetaData) =>
  paymentMetaData?.failureMessage === FAILED_PAYMENT_MESSAGE_TYPES.RETURNED_CHECK;

interface GetSubtitleValuesForVoidedCheckNotificationParams {
  payment: PaymentType;
  paperCheckText: string;
  achText: string;
}

export const getSubtitleValuesForVoidedCheckNotification = ({
  payment,
  paperCheckText,
  achText,
}: GetSubtitleValuesForVoidedCheckNotificationParams) => {
  let deliveryTo;
  let deliveryBy;

  const { deliveryMethod } = payment;
  const deliveryType = deliveryMethod?.deliveryType;

  if (deliveryType.includes(DeliveryEnum.CHECK)) {
    deliveryBy = paperCheckText;
    deliveryTo = getFormattedAddress(deliveryMethod?.paperCheck as CheckType);
  }

  if (deliveryType.includes(DeliveryEnum.ACH)) {
    const companyName = payment?.vendor?.companyName;
    const managedBy = payment?.vendor?.managedBy;
    const digits = getAccountNumber4digits(deliveryMethod.bankAccount);

    deliveryBy = achText;
    deliveryTo = getFormattedACH(companyName, managedBy, digits);
  }

  const checkSerialNumber = payment.metadata?.voidedCheckData?.checkSerialNumber;
  const date = payment?.metadata?.voidedCheckData?.date;
  const formattedDate = date
    ? format(parseISO(date), DATE_FORMATS.monthShortWithDateAndYear)
    : null;

  return {
    checkSerialNumber,
    date: formattedDate,
    deliveryBy,
    deliveryTo,
  };
};

const getPaymentTimelineStatus = (payment: PaymentType) => {
  if (payment.manual) {
    return TIMELINE_STEPS.COMPLETED;
  }

  if (
    [
      PaymentCollectStatusEnum.SENT,
      PaymentCollectStatusEnum.CLEARED,
      PaymentCollectStatusEnum.SETTLED,
    ].includes(payment.collectStatus) &&
    !payment.deliverStatus
  ) {
    return TIMELINE_STEPS.COLLECTED;
  }

  const { deliveryMethod } = payment;
  const deliveryType = deliveryMethod?.deliveryType;

  return payment.status !== TIMELINE_STEPS.COMPLETED && deliveryType && payment.deliverStatus
    ? TIMELINE_STEP_BY_DELIVERY_TYPE[deliveryType][payment.deliverStatus]
    : payment.status;
};

const getStepDate = (step: string, updateDate: string, dates: PaymentStatusDates) => {
  const LOCALE_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  let stepDate: string | null | undefined = '';

  switch (step) {
    case TIMELINE_STEPS.COLLECTED:
      stepDate = dates?.collectDate;
      break;
    case TIMELINE_STEPS.PROGRESS:
      stepDate = dates?.inTransitDate;
      break;
    case TIMELINE_STEPS.COMPLETED:
      stepDate = dates?.depositedDate;
      break;
    default:
      stepDate = updateDate;
      break;
  }

  if (step === TIMELINE_STEPS.COMPLETED && !stepDate) {
    stepDate = updateDate;
  }

  return new Date(stepDate || updateDate).toLocaleDateString('en-US', LOCALE_DATE_OPTIONS);
};

const getPaymentType = (payment: RecordOf<PaymentType>) => {
  const isQbCash: boolean = payment?.fundingSource?.origin === 'qbcash';

  return isQbCash ? payment?.fundingSource?.origin : payment?.deliveryMethod?.deliveryType;
};

export const isCheckPaymentDelay = (payment: RecordOf<PaymentType>) => {
  const isCheck = getPaymentType(payment) === DELIVERY_TYPE.CHECK;
  const dates = payment?.metadata?.paymentStatusDates;
  const sentDate = new Date(dates?.inTransitDate || '').getTime();
  const status = getPaymentTimelineStatus(payment);
  const DAY_IN_MILLISECONDS = 86400000;

  return (
    isCheck &&
    Math.round(Math.abs((new Date().getTime() - sentDate) / DAY_IN_MILLISECONDS)) > 30 &&
    status === TIMELINE_STEPS.PROGRESS
  );
};

export const getTimelineProps = (payment: RecordOf<PaymentType>, formatMessage) => {
  const type = getPaymentType(payment);
  let steps: Array<{ label: string }> = [];
  let current = 0;

  if (type) {
    const status = getPaymentTimelineStatus(payment) || payment.status;
    const timelineSteps = Object.values(TIMELINE_STEPS);

    current = timelineSteps.findIndex((step) => step === status) + 1 || timelineSteps.length;

    steps = timelineSteps.map((step: string, index: number) => ({
      label: formatMessage({ id: `bills.timeline.${type}.${step}` }),
      text:
        index < current &&
        getStepDate(
          step,
          payment?.updatedAt,
          payment?.metadata?.paymentStatusDates as PaymentStatusDates
        ),
    }));
  }

  return { current, steps };
};
