import React, { useEffect } from 'react';
import styled from 'styled-components';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import { MIFormattedText } from 'src/app/utils/formatting';
import intuit from 'src/app/utils/intuit';
import { envApi } from 'src/app/version-2/api/env';
import { DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import { blockedUser, toggleDashboardSwitcher } from 'src/app/utils/external-events';
import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'src/app/redux/user/selectors';
import { KybTypeEnum } from 'src/app/version-2/model/enums';

const BlockedUserPageContainer = () => {
  const companyInfo = useSelector(getCompanyInfo).toJS();
  const showSupportEmail = companyInfo.kyb === KybTypeEnum.POLICY;

  useEffect(() => {
    toggleDashboardSwitcher(false);
    intuit.endLoadingWrapper();
    intuit.hideFTULoadingWrapper();
  }, []);

  return (
    <QBODialog
      type={DIALOG_TYPE.CONFIRM}
      variant={DIALOG_VARIANTS.SUCCESS}
      title="qbo.blockedUser.title"
      subtitle="qbo.blockedUser.subTitle"
      subtitleValues={{
        tos: (
          <Link href={envApi.getQBOSpecificConfig().agreementLinks.termsOfService}>
            <MIFormattedText label="qbo.blockedUser.termsOfService" />
          </Link>
        ),
        support: showSupportEmail && (
          <MIFormattedText
            label="qbo.blockedUser.contactDetails"
            values={{
              supportEmail: (
                <Link href={`mailto:${envApi.getQBOSpecificConfig().compliance.email}`}>
                  {envApi.getQBOSpecificConfig().compliance.email}
                </Link>
              ),
            }}
          />
        ),
      }}
      okButtonText="qbo.blockedUser.dismiss"
      onOkAction={blockedUser}
      onCancelAction={blockedUser}
      showCancel={false}
    />
  );
};

const Link = styled.a`
  text-decoration: none;
  color: rgb(0, 119, 197);

  &:hover {
    text-decoration: underline;
  }
`;

export default BlockedUserPageContainer;
