import React from 'react';

type Props = {
  onNext: (string) => void;
  onPrev: () => void;
  onExit: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const JustPayConnectBankAccount = ({ onNext }: Props) => (
  <div>JustPayConnectBankAccount</div>
);
