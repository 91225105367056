import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import { OrganizationType, PaymentType } from 'src/app/utils/types';
import { InfoLayout, InlineLink } from '../components';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  payment?: PaymentType;
  organization?: OrganizationType;
};

const CancelledLayout = ({ payment, organization }: Props) => {
  useStateChangedAnalytics({ payment, paymentState: VirtualCardPaymentState.Cancelled });

  const name = organization?.companyName;
  const email = organization?.userOrganizations?.[0]?.user?.email || '';
  const subtitleLabel = email
    ? 'vendors.virtualCardDetails.cancelled.subtitle'
    : 'vendors.virtualCardDetails.cancelled.subtitleWithoutEmail';

  return (
    <InfoLayout showLinks={false} showSupport={false}>
      <InfoLayout.Icon type={InfoLayout.IconType.Alert} />
      <InfoLayout.Title>
        <MIFormattedText label="vendors.virtualCardDetails.cancelled.title" />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label={subtitleLabel}
          values={{
            name,
            email,
            link: (...chunks) => <InlineLink href={`mailto:${email}`} chunks={chunks} />,
          }}
        />
      </InfoLayout.Subtitle>
    </InfoLayout>
  );
};

export { CancelledLayout };
