import React from 'react';
import { Flex, Image } from '@melio/billpay-design-system';

import qbCashBankOrigin from 'src/app/images/icons/qb-cash-bank-origin.svg';
import { MIFormattedText } from 'src/app/utils/formatting';
import { imageStyles } from './styles';

const QBCashMethod = () => (
  <Flex>
    <Image __css={imageStyles} src={qbCashBankOrigin} />
    <MIFormattedText label="paymentDashboard.columns.paymentMethod.qbcash" />
  </Flex>
);

export default QBCashMethod;
