export const enum FeatureFlagsEnum {
  US_HOLIDAYS_CHECK_FEATURE_FLAG = 'us-holidays-checks',
  BP_BASIS_THEORY = 'bp-basis-theory',
  PLANS_LANDING_PAGE_FEATURE_FLAG = 'qb-ftu-plans-lp',
  PLANS_LANDING_PAGE_FEATURE_FLAG_TOGGLE = 'qb-ftu-plans-lp-toggle',
  FUNDING_SOURCE_EXPERIMENT = 'qb-funding-source-experiment',
  FUNDING_SOURCE_VARIANT = 'qb-experiment-fs-variant',
  PAYMENT_DETAILS_TIMELINE = 'payment-details-timeline',
  CHECK_FEES = 'check-fees-billpay',
  CHECK_FEES_LIMIT_DATE = 'qb-check-fee-limit-date',
  CHECK_FEE_NOTIFICATION = 'qb-check-fee-notification',
  FUNDING_SOURCE_API_V2 = 'bp-use-funding-source-fetch-v2',
  FAST_CHECK_ENABLED = 'meliocom-fast-check-enabled',
  QBO_ROBINHOOD_REFUND_FLOW = 'qbo-robinhood-refund-flow',
  US_HOLIDAYS_CHECKS = 'us-holidays-checks',
  QBO_REFUND_PAYMENT = 'qbo-refund-payment',
  BATCH_UNCOMBINED = 'bp-batch-uncombined',
  FAST_ACH_ADOPTION_IN_BATCH_V2 = 'bp-fast-ach-adoption-in-batch-v2',
  SELF_SERVE_REFUND = 'bp-self-serve-refund',
  INTERNATIONAL_MASTERCARD = 'bp-international-mastercard',
  BP_GLOBAL_OPT_IN_UPSELL = 'bp-global-opt-in-upsell',
  BP_GLOBAL_OPT_IN_CARD_DETAILS_ENTRY = 'bp-global-opt-in-entry-virtual-card-details',
  BP_LOG_PER_REQUEST_RESPONSE = 'bp-log-per-request-response',
  RETRY_NEW_ERROR_TEXT = 'qb-retry-new-text',
  UPGRADE_PAYMENT_TO_FACH = 'bp-p2fach-web',
  UPGRADE_PAYMENT_TO_RTP = 'bp-p2rtp-web',
  UPGRADE_PAYMENT_TO_FACH_FROM_TRACK_PAYMENT = 'bp-p2fach-web-track-payment',
  UPGRADE_PAYMENT_TO_RTP_FROM_TRACK_PAYMENT = 'bp-p2rtp-web-track-payment',
  ZENDESK_MESSAGING = 'zendesk-messaging-in-intuit',
  ZENDESK_MESSAGING_AUTHENTICATION = 'zendesk-messaging-authentication-in-intuit',
  PLATFORM_LINE_ITEMS = 'platform-line-items',
}
