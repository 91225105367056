import {
  CITY_FALLBACK_COMPONENT_TYPE_NAME,
  COUNTRY_CODE_COMPONENT_TYPE_NAME,
  STATE_COMPONENT_TYPE_NAME,
  STREET_COMPONENT_TYPE_NAME,
  STREET_NUMBER_COMPONENT_TYPE_NAME,
  ZIP_CODE_COMPONENT_TYPE_NAME,
} from '../model/constants/getGoogleAddressFields.consts';
import { GoogleCombinedAddress } from '../model/dtos/googleCombinedAddress';

export const getGoogleAddress = (googleAddress: GoogleCombinedAddress) => {
  const streetNumberComponent = googleAddress.addressComponents.find((c) =>
    c.types.includes(STREET_NUMBER_COMPONENT_TYPE_NAME)
  );
  const streetComponent = googleAddress.addressComponents.find((c) =>
    c.types.includes(STREET_COMPONENT_TYPE_NAME)
  );
  const cityComponent =
    googleAddress.addressComponents.find((c) =>
      c.types.includes(CITY_FALLBACK_COMPONENT_TYPE_NAME)
    ) ||
    googleAddress.addressComponents.find((c) =>
      c.types.includes(CITY_FALLBACK_COMPONENT_TYPE_NAME)
    );
  const stateComponent = googleAddress.addressComponents.find((c) =>
    c.types.includes(STATE_COMPONENT_TYPE_NAME)
  );
  const zipComponent = googleAddress.addressComponents.find((c) =>
    c.types.includes(ZIP_CODE_COMPONENT_TYPE_NAME)
  );
  const countryCodeComponent = googleAddress.addressComponents.find((c) =>
    c.types.includes(COUNTRY_CODE_COMPONENT_TYPE_NAME)
  );
  const streetNumber = streetNumberComponent ? streetNumberComponent.long_name : '';
  const streetName = streetComponent ? streetComponent.long_name : '';
  const formattedAddress = googleAddress.formattedAddress;
  let addressLine1 = formattedAddress ? formattedAddress.split(',')[0] : '';
  const cityFromString = formattedAddress ? formattedAddress.split(', ')[1] : '';

  if (streetNumberComponent && streetComponent) {
    addressLine1 = `${streetNumber} ${streetName}`;
  }

  return {
    streetNumberComponent,
    streetComponent,
    cityComponent,
    stateComponent,
    zipComponent,
    countryCodeComponent,
    addressLine1,
    cityFromString,
  };
};
