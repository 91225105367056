import React from 'react';
import { Switch, Redirect, generatePath, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { getIsLoggedIn } from 'src/app/redux/user/selectors';
import profileStore from 'src/app/modules/profile/profile-store';
import { AreaLoader } from '@melio/billpay-design-system';
import VendorSwitchCompanyPage from 'src/app/pages/vendor/virtual-delivery-method/VendorSwitchCompanyPage';
import { useUnilateralPaymentState } from 'src/app/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import InvalidLinkPage from 'src/app/pages/vendor/shift-vendor-to-ach/InvalidAddDeliveryMethodPage';
import SwitchCompanyPage from 'src/app/pages/vendor/shift-vendor-to-ach/SwitchCompanyPage';
import LinkDeliveryMethodPage from 'src/app/pages/vendor/shift-vendor-to-ach/LinkDeliveryMethodPage';
import CheckSentInfoPage from 'src/app/pages/vendor/edit-check-adress/CheckSentInfoPage';
import CheckDepositedSuccessPage from 'src/app/pages/vendor/edit-check-adress/CheckDepositedSuccessPage';
import CheckChangeAddressPage from 'src/app/pages/vendor/edit-check-adress/CheckChangeAddressPage';
import CheckAddressChangedPage from 'src/app/pages/vendor/edit-check-adress/CheckAddressChangedPage';
import CheckBlockedPage from 'src/app/pages/vendor/edit-check-adress/CheckBlockedPage';
import PaymentCancelled from 'src/app/pages/vendor/edit-check-adress/PaymentCancelled';
import { ShiftToDebitRouter } from 'src/app/pages/vendor/shift-vendor-to-debit/router';
import SwitchedCheckAlreadyPage from 'src/app/pages/vendor/edit-check-adress/SwitchedCheckAlreadyPage';
import ShiftedCurrentPaymentPage from 'src/app/pages/vendor/shift-vendor-to-ach/ShiftedCurrentPaymentPage';
import { QBOVerifiedBusinessDirectoryPage } from 'src/app/pages/vendor/qb-directory/QBOVerifiedBusinessDirectoryPage';
import { QBOVirtualCardDetailsPage } from 'src/app/pages/vendor/virtual-card-details/QBOVirtualCardDetailsPage';
import { QBOVirtualCardOptInPage } from 'src/app/pages/vendor/virtual-card-details/components/QBOVirtualCardOptInPage';
import { QBOVirtualCardInfoPage } from 'src/app/pages/vendor/virtual-card-details/QBOVirtualCardInfoPage/QBOVirtualCardInfoPage';
import { useInitFetch } from 'src/app/pages/vendor/shift-vendor-to-ach/hoc/useInitFetchHook';
import { useEditCheckAddressRedirect } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressRedirect';
import { CONSTS } from 'src/app/utils/consts';
import AddDeliveryMethodPage from 'src/app/pages/vendor/shift-vendor-to-ach/QBOAddDeliveryMethodPage';
import SuccessAddDeliveryMethodPage from 'src/app/pages/vendor/shift-vendor-to-ach/QBOAddDeliveryMethodSuccessPage';
import { VendorSelectExpeditedDeliveryMethodPage } from 'src/app/pages/vendor/select-expedited/VendorSelectExpeditedDeliveryMethodPage/VendorSelectExpeditedDeliveryMethodPage';
import {
  SelectExpediteStateType,
  useSelectExpeditedState,
} from 'src/app/pages/vendor/select-expedited/hoc/select-expedited-hooks';
import { AddVirtualCardSuccessPage } from 'src/app/pages/vendor/select-expedited/AddVirtualCardSuccessPage';
import { NewDeliveryMethodScheduled } from 'src/app/pages/vendor/shift-virtual-card/NewDeliveryMethodScheduled';
import { NotificationLayout } from 'src/app/pages/vendor/virtual-card-details/layouts/NotificationLayout';
import { UpgradePaymentRouter } from 'src/app/pages/vendor/upgrade-payment/router';

import locations from './locations';
import { VendorPaymentTrackingRouter } from './vendor-payment-tracking/VendorPaymentTrackingRouter';
import { PosTerminalPage } from './virtual-card-details/PosTerminalPage';
import VendorCheckSelectDeliveryMethod from './select-expedited/check/VendorCheckSelectDeliveryMethod';
import { GlobalOptInRouter } from 'src/app/version-2/pages/global-opt-in-details/GlobalOptInRouter';

const UnilateralRouter = () => {
  const match = useRouteMatch<{ token?: string }>();
  const token = match.params?.token || '';
  const [state, actions, newDeliveryMethod, currentDeliveryMethod, isShiftVirtualCard] =
    useUnilateralPaymentState({
      token,
    });
  const isLoggedIn = useSelector(getIsLoggedIn);
  const site = useSiteContext();
  const organizations = useSelector(profileStore.selectors.getOrganizations);

  const getInitialUrl = () => {
    if (currentDeliveryMethod && !isShiftVirtualCard) {
      if (currentDeliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
        return isLoggedIn && organizations.length > 1
          ? locations.unilateral.switchCompany
          : locations.unilateral.addMethod;
      }

      return locations.unilateral.success;
    }

    return locations.unilateral.index;
  };

  return (
    <Switch>
      <SmartRoute path={locations.unilateral.index} exact>
        <Redirect to={generatePath(getInitialUrl(), { token })} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.addMethod} exact>
        <site.components.VendorSelectDeliveryMethodPage
          state={state}
          actions={actions}
          deliveryMethod={newDeliveryMethod}
          isShiftVirtualCard={isShiftVirtualCard}
          token={token}
        />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.switchCompany} exact>
        <VendorSwitchCompanyPage token={token} state={state} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.invalidToken} exact>
        <site.components.InvalidLinkPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.unilateral.success} exact>
        <site.components.AddDeliveryMethodSuccessPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

const ShiftVendorToAchRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  const id = match.params?.id || '';
  const site = useSiteContext();

  useInitFetch(id, token);

  return (
    <Switch>
      <SmartRoute path={locations.shiftVendorToAch.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.addMethod} exact>
        <AddDeliveryMethodPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.linkMethod} exact>
        <LinkDeliveryMethodPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.switchCompany} exact>
        <SwitchCompanyPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.shiftedCurrentPayment} exact>
        <ShiftedCurrentPaymentPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.success} exact>
        <SuccessAddDeliveryMethodPage />
      </SmartRoute>
      <SmartRoute path={locations.shiftVendorToAch.invalidToken} exact>
        <site.components.InvalidLinkPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

const EditCheckAddressRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  const id = match.params?.id || '';
  const site = useSiteContext();

  useEditCheckAddressRedirect({
    token,
    id,
  });

  return (
    <Switch>
      <SmartRoute path={locations.editCheckAddress.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkSent} exact>
        <CheckSentInfoPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkDeposited} exact>
        <CheckDepositedSuccessPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkNotSentChangeAddress} exact>
        <CheckChangeAddressPage token={token} id={id} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkNotSendAddressSaved} exact>
        <CheckAddressChangedPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.switchedToACH} exact>
        <SwitchedCheckAlreadyPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.checkBlocked} exact>
        <CheckBlockedPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.paymentCancelled} exact>
        <PaymentCancelled token={token} />
      </SmartRoute>
      <SmartRoute path={locations.editCheckAddress.invalidToken} exact>
        <site.components.InvalidLinkPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

const QBDirectoryRouter = () => (
  <Switch>
    <SmartRoute path={locations.qbDirectory.verified} exact>
      <QBOVerifiedBusinessDirectoryPage />
    </SmartRoute>
  </Switch>
);

const VirtualCardDetailsRouter = () => {
  const match = useRouteMatch<{ token?: string; id?: string }>();
  const token = match.params?.token || '';
  const virtualCardId = match.params?.id || '';
  const location: { state: any } = useLocation();

  return (
    <Switch>
      <SmartRoute path={locations.virtualCardDetails.optIn} exact>
        <QBOVirtualCardOptInPage
          payment={location.state?.payment}
          token={token}
          deliveryMethod={location.state?.unilateralDeliveryMethod}
          deliveryMethodId={location.state?.deliveryMethodId}
          referrer={location.state?.referrer}
        />
      </SmartRoute>
      <SmartRoute path={locations.virtualCardDetails.infoPage} exact>
        <QBOVirtualCardInfoPage token={token} payment={location.state?.payment} />
      </SmartRoute>
      <SmartRoute path={locations.virtualCardDetails.posTerminal} exact>
        <PosTerminalPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.virtualCardDetails.details} exact>
        <QBOVirtualCardDetailsPage token={token} virtualCardId={virtualCardId} />
      </SmartRoute>
      <SmartRoute path={locations.virtualCardDetails.invalidToken} exact>
        <InvalidLinkPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

const ShiftVirtualCardDetailsRouter = () => {
  const match = useRouteMatch<any>();
  const token = match.params?.token || '';

  return (
    <Switch>
      <SmartRoute path={locations.shiftVirtualCard.details} exact>
        <NewDeliveryMethodScheduled token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVirtualCard.notification} exact>
        <NotificationLayout token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftVirtualCard.invalidToken} exact>
        <InvalidLinkPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

const VendorSelectExpeditedRouter = () => {
  const match = useRouteMatch<{ token?: string; id?: string }>();
  const token = match.params?.token || '';
  const isShiftCheckDeliveryMethodPath =
    match.path === locations.selectExpedited.shiftCheckDeliveryMethod;

  const { state } = useSelectExpeditedState(token, isShiftCheckDeliveryMethodPath);

  return (
    <Switch>
      <SmartRoute path={locations.selectExpedited.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={locations.selectExpedited.selectDeliveryMethod} exact>
        <VendorSelectExpeditedDeliveryMethodPage
          token={token}
          state={state as SelectExpediteStateType}
        />
      </SmartRoute>
      <SmartRoute path={locations.selectExpedited.shiftCheckDeliveryMethod} exact>
        <VendorCheckSelectDeliveryMethod token={token} state={state} />
      </SmartRoute>
      <SmartRoute path={locations.selectExpedited.invalidToken} exact>
        <InvalidLinkPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.selectExpedited.paymentCanceled} exact>
        <PaymentCancelled token={token} />
      </SmartRoute>
      <SmartRoute path={locations.selectExpedited.virtualCardSuccess} exact>
        <AddVirtualCardSuccessPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

export const VendorsRouter = () => (
  <Switch>
    <SmartRoute path={locations.shiftVirtualCard.base}>
      <ShiftVirtualCardDetailsRouter />
    </SmartRoute>
    <SmartRoute path={locations.unilateral.base}>
      <UnilateralRouter />
    </SmartRoute>
    <SmartRoute path={locations.shiftVendorToAch.base}>
      <ShiftVendorToAchRouter />
    </SmartRoute>
    <SmartRoute path={locations.editCheckAddress.base}>
      <EditCheckAddressRouter />
    </SmartRoute>
    <SmartRoute path={locations.shiftToDebit.base}>
      <ShiftToDebitRouter />
    </SmartRoute>
    <SmartRoute path={locations.qbDirectory.base}>
      <QBDirectoryRouter />
    </SmartRoute>
    <SmartRoute path={locations.virtualCardDetails.base}>
      <VirtualCardDetailsRouter />
    </SmartRoute>
    <SmartRoute path={locations.selectExpedited.base}>
      <VendorSelectExpeditedRouter />
    </SmartRoute>
    <SmartRoute path={locations.vendorPaymentTracking.base}>
      <VendorPaymentTrackingRouter />
    </SmartRoute>
    <SmartRoute path={locations.virtualCardCandidatesLocations.base}>
      <GlobalOptInRouter />
    </SmartRoute>
    <SmartRoute path={locations.upgradePaymentLocations.base}>
      <UpgradePaymentRouter />
    </SmartRoute>
  </Switch>
);
