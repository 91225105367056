import { ExperimentLandingPageContainer } from 'src/app/version-2/components';

import styled from 'styled-components';

export const PageContainer = styled(ExperimentLandingPageContainer)`
  align-content: flex-start;
  padding-top: 11.5rem;
  grid-template-rows: 5.5rem 8rem 39.5rem 5.2rem;
  height: auto;

  p {
    margin: 0;
  }
`;

export const Subtitle = styled.div`
  max-width: 85rem;
`;

export const Footnote = styled.div`
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.2rem;
  max-width: 85rem;
  color: ${({ theme }) => theme.colors.ds.gray[200]};
`;

export const Close = styled.div`
  position: absolute;
  top: 2.8rem;
  right: 2.8rem;
  cursor: pointer;
`;
