import React, { useRef, useState } from 'react';
import {
  DropDownContainer,
  DropDownOption,
  SingleSelectContainer,
} from 'src/app/components/common/DropDown/MIDropDown';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import styled, { css } from 'styled-components';
import { getAddressSuggestion } from 'src/app/services/address-autocomplete';
import { ModelViewField } from 'src/app/ui/form';
import { SelectFieldOption } from 'src/app/ui/form/WizardSelectField';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import { AddressType } from 'src/app/utils/types';
import isEmpty from 'lodash/isEmpty';
import negate from 'lodash/negate';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import analytics from 'src/app/services/analytics';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';

const DEBOUNCE_DELAY = 300;

const addressToLabel = (address: AddressType) =>
  Object.values(address).filter(negate(isEmpty)).join(', ');

type AddressSuggestionProps = {
  id: string;
  model?: ModelViewField<string>;
  label: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onSelected?: (address: AddressType | null) => void;
  autoFocus?: boolean;
  autocomplete?: string;
  eventPage: string;
  hideError?: boolean;
  size?: string;
  showClearIcon?: boolean;
};

const searchSuggestions = debounce(async (value, callback) => {
  const results = await getAddressSuggestion(value);
  const suggestions = map(results, (address) => ({
    label: addressToLabel(address),
    value: address,
  }));

  callback(suggestions);
}, DEBOUNCE_DELAY);

const MIAddressAutocompleteSmartyStreets = ({
  id,
  model,
  onSelected,
  label,
  placeholder,
  required,
  autoFocus,
  autocomplete,
  eventPage,
  hideError,
  size = TextInputSizeEnum.INLINE,
  showClearIcon = true,
}: AddressSuggestionProps) => {
  const [addressSuggestions, setAddressSuggestions] = useState<SelectFieldOption<AddressType>[]>(
    []
  );
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  const onChange = (event: { value: string }) => {
    if (model) {
      model.onChange(event);
    }

    searchSuggestions(event.value, (suggestions) => {
      setAddressSuggestions(() => suggestions);

      if (document.activeElement === inputRef.current && suggestions.length > 0) {
        setOpen(true);
      }

      const isNotValidInput =
        !suggestions.length || suggestions.every(({ label }) => label !== event.value);

      if (!hideError && model?.setError && isNotValidInput) {
        model.setError('inputErrors.deliveryMethodCheck.addressLine1.any.invalidAddress');
      }
    });
  };

  const onSelectedOption = (option) => () => {
    onSelected && onSelected(option.value);
    setOpen(false);
    analytics.track(eventPage, 'suggested-option-address-selected');
  };

  const onClearClick = () => {
    onSelected && onSelected(null);
    model?.onChange && model.onChange({ value: '' });
    setOpen(false);
  };

  return (
    <SingleSelectContainer handleClickOutside={() => setOpen(false)}>
      <InputContainer>
        <WizardTextInputField
          id={id}
          label={label}
          placeholder={placeholder}
          model={model}
          required={required}
          autoFocus={autoFocus}
          autocomplete={autocomplete}
          onChange={onChange}
          type="search"
          inputRef={inputRef}
          privateData
        />
        {model?.value && showClearIcon && (
          <ClearIcon className="icon-close-icon" onClick={onClearClick} />
        )}
      </InputContainer>
      <DropDownContainer size={size} hidden={!open || !addressSuggestions.length}>
        <List>
          {addressSuggestions.map((option) => (
            <DropDownOption
              className={FULL_STORY_MASK_RULE_CLASS}
              key={option.label}
              onClick={onSelectedOption(option)}
            >
              {option.label}
            </DropDownOption>
          ))}
        </List>
      </DropDownContainer>
    </SingleSelectContainer>
  );
};

const List = styled.div`
  max-height: 25rem;
  overflow: scroll;
`;

const InputContainer = styled.div`
  input[type='search']::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  }
  input[type='search'] {
    -webkit-border-radius: 0;
    border-radius: 0;
    box-shadow: 0;
    -webkit-appearance: none;
  }
`;

const iconStyles = css`
  position: absolute;
  z-index: 2;
  display: block;
  line-height: 8.4rem;
  text-align: center;
  color: ${(props) => props.theme.colors.dark.translucent3};
  ${(props) => props.theme?.components?.MIAddressAutocompleteSmartyStreets?.IconStyles}
`;

const ClearIcon = styled.i`
  cursor: pointer;
  width: ${(props) => props.theme.sizes.searchBarHeight};
  font-size: 1.6rem;
  top: 0;
  right: -0.5rem;
  ${iconStyles}
  ${(props) => props.theme?.components?.MIAddressAutocompleteSmartyStreets?.ClearIcon}
`;

export default MIAddressAutocompleteSmartyStreets;
