import React from 'react';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ButtonSizeType } from 'src/app/utils/types';
import dictionary from '../VirtualCardInfoPageDictionary';
import { InfoSubmitButtonContainer, Subtitle } from './InfoSubmitButton.styles';

type InfoSubmitButtonProps = {
  onClick: () => void;
  size?: ButtonSizeType;
  isVirtualPayment: boolean;
  isPaymentCollected: boolean;
  isMobile?: boolean;
};

const InfoSubmitButton = ({
  isMobile,
  onClick,
  size,
  isVirtualPayment,
  isPaymentCollected,
}: InfoSubmitButtonProps) => (
  <InfoSubmitButtonContainer data-testid="info-submit-button">
    <MIButton
      variant={CONSTS.BUTTON_VARIANT.PRIMARY}
      label={dictionary.infoSubmitButton.buttonLabel(isVirtualPayment)}
      onClick={onClick}
      size={size}
      fullWidth={isVirtualPayment || isMobile}
    />
    {isPaymentCollected && !isMobile && (
      <Subtitle>
        <MIFormattedText label={dictionary.infoSubmitButton.subtitle} />
      </Subtitle>
    )}
  </InfoSubmitButtonContainer>
);

export default InfoSubmitButton;
