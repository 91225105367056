import React, { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import { useQueryString } from 'src/app/utils/hooks';
import { ProgressPage } from 'src/app/pages/quickbooks/entrypoint/ProgressPage';
import intuit from 'src/app/utils/intuit';
import { encodeQuery } from 'src/app/utils/query-utils';
import locations from 'src/app/pages/qb-dashboard/locations';
import { ITEMS_PER_PAGE } from 'src/app/pages/qb-dashboard/consts';

export function DashboardLoader() {
  const history = useHistory();
  const query = useQueryString();
  const orgId = useSelector(getOrgId);
  const { fetchRedirectQuery } = useRedirectToDashboard();

  useEffect(() => {
    intuit.endLoadingWrapper();
    redirectToDashboard();
  }, []);

  const redirectToDashboard = async () => {
    const { billId, paymentId, billIds, paymentIds, ...restQuery } = query;
    const billIdsList = (billIds as string)?.split(',') || [];
    const paymentIdsList = paymentIds === (paymentIds as string)?.split(',') || [];
    let redirectParams;

    if (billIdsList.length) {
      const itemIds = billIdsList.map((billId, index) => ({
        billId,
        paymentId: paymentIdsList?.[index],
      }));

      redirectParams = await fetchRedirectQuery(itemIds, true);
    } else {
      redirectParams = await fetchRedirectQuery(
        [{ billId: billId as string, paymentId: paymentId as string }],
        true
      );
    }

    history.replace({
      pathname: generatePath(locations.dashboard, { orgId }),
      search: encodeQuery(
        {
          ...restQuery,
          start: redirectParams.start,
          status: redirectParams.status,
          limit: ITEMS_PER_PAGE,
          highlightedItemIds: redirectParams.highlightedItemIds,
        },
        [],
        ''
      ),
    });
  };

  return <ProgressPage progress={75} />;
}
