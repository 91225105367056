import * as React from 'react';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import { compose } from 'recompose';
import { featureFlags } from '@melio/shared-web';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import locations from 'src/app/utils/locations';
import pagesLocations from 'src/app/pages/locations';
import companyInfoApi from 'src/app/services/api/organizations';
import { billListModifiedType } from 'src/app/pages/bill/pay/types';
import { CompanyInfoRecord } from 'src/app/pages/settings/records';
import internationalAPI from 'src/app/services/api/deliveryMethods';
import { setCompanyInfoAction, setProfileAction } from 'src/app/redux/user/actions';
import { DEFAULT_DASHBOARD_REDIRECT_PARAMS } from 'src/app/pages/qb-dashboard/hooks/useGetDashboardListItemPaginationParams';
import { melioClose } from 'src/app/utils/external-events';
import { withNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import {
  isValidDateOfBirth,
  hasDateOfBirthTaxType,
  formatDateOfBirth,
} from 'src/app/utils/user-utils';
import userApi from 'src/app/services/api/user';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  UserContextType,
  CompanyInfoType,
  FieldType,
  GoogleCombinedAddressType,
  NavigateType,
  ConfirmationOrigin,
} from 'src/app/utils/types';
import { transformToCreateDeliveryRequest } from 'src/app/pages/vendor/delivery-methods/international/utils';
import {
  RedirectParams,
  withRedirectToDashboard,
} from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import paymentStore, { getPaymentsActions } from 'src/app/modules/payments/payment-store';
import { GlobalState } from 'src/app/redux/types';
import {
  convertLegalAddressToGoogleAddress,
  convertToServerLegalAddress,
  convertToServerAddress,
} from 'src/app/utils/address';
import { parseQueryString } from 'src/app/utils/query-utils';
import { getCompanyInfo, getOrgId, getProfile } from 'src/app/redux/user/selectors';
import { Site } from 'src/app/sites/site';
import { CONSTS } from 'src/app/utils/consts';
import uboAPI from 'src/app/services/api/organization-ultimate-beneficial-owners';
import { hasUltimateBeneficialOwners } from 'src/app/utils/organizations-ubo-utils';
import { AddressNameTypeEnum, TaxIdEnum } from 'src/app/version-2/model/enums';
import { FeatureFlag, withHasFeatureFlag } from 'src/app/hooks/useHasFeatureFlag';
import QBOPayBillCompleteLegalInfoPage from './components/QBOPayBillCompleteLegalInfoPage';

type PaidPaymentItem = {
  billId: string;
  id: string;
};

type MapStateToProps = {
  companyInfo: RecordOf<CompanyInfoType>;
  orgId: string;
  paymentList: RecordOf<PaymentItem>[];
  redirectUrl: string;
  profile: RecordOf<UserContextType>;
  paidPayments: PaidPaymentItem[];
  billListModified: Record<string, RecordOf<billListModifiedType>>;
};
type MapDispatchToProps = {
  setCompanyInfo: (companyInfo: RecordOf<CompanyInfoType>) => void;
  setProfile: (profile: RecordOf<UserContextType>) => void;
  setQboBatchPaymentsNavigationUrls: (payload: any) => void;
  createBatchPayment: (payload: any) => any;
  createBatchBulkPayment: ({ history }: { history: any }) => void;
};
type Props = {
  redirectToDashboard: (props: RedirectParams) => void;
  navigate: NavigateType;
  site: Site;
  isFeatureFlagEnabled: (featureFlag: FeatureFlag) => boolean;
  history: any;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  legalAddress: GoogleCombinedAddressType;
  legalCompanyName: string;
  taxId?: string;
  taxIdType?: TaxIdEnum;
  validationErrors: Record<string, any>;
  isLoading: boolean;
  contactFirstName: string;
  contactLastName: string;
  phone?: string;
  companyAddress: GoogleCombinedAddressType;
  openLegalAddressInput?: boolean;
  isInternational?: boolean;
  dateOfBirth?: string;
};

class PayBatchCompleteLegalInfoPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const query = parseQueryString(window.location.search);

    this.state = {
      legalAddress: convertLegalAddressToGoogleAddress(this.props.companyInfo),
      legalCompanyName: this.props.companyInfo.legalCompanyName,
      taxId: this.props.companyInfo.taxId,
      taxIdType: this.props.companyInfo.taxIdType,
      validationErrors: {},
      isLoading: false,
      contactFirstName: this.props.companyInfo.contactFirstName,
      contactLastName: this.props.companyInfo.contactLastName,
      phone: this.props.companyInfo.phone,
      companyAddress: convertLegalAddressToGoogleAddress(this.props.companyInfo),
      openLegalAddressInput: false,
      isInternational: query.isInternational === 'true',
      dateOfBirth: this.props.profile.dateOfBirth || '',
    };
  }

  onLegalInfoChange = ({ id, value }: FieldType) => {
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  onAddressChange = (
    address: GoogleCombinedAddressType,
    type = AddressNameTypeEnum.LEGAL_ADDRESS
  ) => {
    this.setState((prevState) => ({ ...prevState, [type]: address }));
  };

  validateDateOfBirth = () => {
    const { taxIdType } = this.state;
    const { profile } = this.props;

    if (!profile.dateOfBirth && hasDateOfBirthTaxType(taxIdType)) {
      return isValidDateOfBirth(this.state.dateOfBirth)
        ? undefined
        : 'inputErrors.dateOfBirth.string.regex.base';
    }

    return false;
  };

  handleOnSubmit = async () => {
    const {
      legalAddress,
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      companyAddress,
      phone,
      openLegalAddressInput,
      isInternational,
    } = this.state;

    const { orgId, companyInfo, setCompanyInfo, history } = this.props;
    const inputFields = [
      'legalCompanyName',
      'taxId',
      'addressLine1',
      'city',
      'zipCode',
      'taxIdType',
      'contactFirstName',
      'contactLastName',
      'phone',
    ];
    const inputFieldsWithLegalAddress = openLegalAddressInput
      ? [...inputFields, 'legalZipCode', 'legalAddressLine1', 'legalCity']
      : inputFields;
    const address = openLegalAddressInput ? legalAddress : companyAddress;

    const dataToUpdate = companyInfo.merge({
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName: contactFirstName || '',
      contactLastName: contactLastName || '',
      phone: phone || '',
      ...convertToServerAddress(companyAddress),
      ...convertToServerLegalAddress(address),
    });
    const validationErrors = getValidationErrors(
      'companyInfo',
      dataToUpdate,
      inputFieldsWithLegalAddress
    );

    if (isInternational && this.validateDateOfBirth()) {
      const isValid = this.validateDateOfBirth();

      if (isValid) {
        validationErrors.dateOfBirth = this.validateDateOfBirth();
      }
    }

    this.setState({ validationErrors });

    if (isValidationOk(validationErrors)) {
      if (isInternational) {
        await this.updateUserProperties();
      }

      this.setState({ validationErrors, isLoading: true });
      companyInfoApi
        .updateCompanyInfo(orgId, {
          ...dataToUpdate.toObject(),
          didCompleteQBLegalInfo: true,
        })
        .then(({ companyInfo: updatedCompanyInfo }) => {
          const companyInfoRecord = CompanyInfoRecord(updatedCompanyInfo);

          setCompanyInfo(companyInfoRecord);

          if (isInternational) {
            return this.createInternationalDM();
          }

          return this.props.createBatchBulkPayment({ history });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  shouldShowUltimateBeneficialOwnersFlow = async (organizationId, taxIdType) => {
    const isUltimateBeneficialOwnersFlowOn = this.props.isFeatureFlagEnabled(
      'ultimateBeneficialOwnersFlow'
    );

    try {
      const uboResponse = await uboAPI.getOrganizationUltimateBeneficialOwners(organizationId);

      return (
        isUltimateBeneficialOwnersFlowOn &&
        hasUltimateBeneficialOwners(uboResponse) &&
        taxIdType === TaxIdEnum.EIN
      );
    } catch (e) {
      return false;
    }
  };

  async createInternationalDM() {
    const { state } = window.history.state?.state?.international as any;
    const { orgId, companyInfo, billListModified, redirectUrl, setQboBatchPaymentsNavigationUrls } =
      this.props;
    const { isInternational } = this.state;

    const deliveryRequest = transformToCreateDeliveryRequest({ state });
    const newDeliveryMethod = await internationalAPI.createInternationalDelivery(
      orgId,
      state?.vendor?.id,
      deliveryRequest
    );

    setQboBatchPaymentsNavigationUrls({
      // redirectUrl is success page beacuse we aready filled the missing details (won't trigger again on schedule all)
      redirectUrl: pagesLocations.qbDashboard.dashboard,
      // batch page close and confirm buttons won't work as expected without this reset (exitUrl is batch page)
      exitUrl: '',
    });

    const shouldShowUBO = await this.shouldShowUltimateBeneficialOwnersFlow(
      orgId,
      companyInfo?.taxIdType
    );

    if (shouldShowUBO) {
      const redirectUrl =
        locations.Vendors.deliveryMethods.international.ultimateBeneficialOwners.url({
          orgId,
          id: state?.vendor?.id,
        });
      const urlQueryParams = isInternational ? '?isBatchCreateDM=true' : '';

      // goes to ubo after missing details success
      this.props.navigate(redirectUrl + urlQueryParams, false, {
        ...state,
        batchPaymentIds: [],
        billListModified,
        confirmationOrigin: ConfirmationOrigin.BATCH_PAYMENT,
        newDeliveryMethod: {
          ...newDeliveryMethod,
          deliveryType: CONSTS.DELIVERY_TYPE.INTERNATIONAL,
        },
        isBatchCreateDM: !!isInternational,
      });
    } else {
      // go back to batch after missing details success
      this.props.navigate(redirectUrl, false, {
        ...state,
        newDeliveryMethod: {
          ...newDeliveryMethod,
          deliveryType: CONSTS.DELIVERY_TYPE.INTERNATIONAL,
        },
        ...(state?.preservedState || {}),
      });
    }
  }

  async updateUserProperties() {
    const { taxIdType, dateOfBirth } = this.state;
    const { profile, setProfile } = this.props;

    if (!profile.dateOfBirth && hasDateOfBirthTaxType(taxIdType)) {
      // has previous no date of birth
      const formattedDate = formatDateOfBirth(dateOfBirth);
      const dataToUpdate = profile.merge({
        dateOfBirth: formattedDate,
      });
      const res = await userApi.update(dataToUpdate);
      const newProfile = profile.merge({
        dateOfBirth: res.dateOfBirth,
      });

      setProfile(newProfile);
    }
  }

  goExit = () => {
    const hasNewBatchBulk = featureFlags.defaultClient.getVariant('qbo-bulk-payment', false);

    if (hasNewBatchBulk) {
      try {
        this.props.history.goBack();
      } catch (er) {
        melioClose();
      }
    } else {
      melioClose();
    }
  };

  goQBDashboard = async (batchPaymentIds) => {
    const { redirectToDashboard, billListModified } = this.props;

    analytics.trackAction('go-to-dashboard', {
      flow: 'batch',
      fromDashboard: true,
    });

    await redirectToDashboard({
      redirectQuery: DEFAULT_DASHBOARD_REDIRECT_PARAMS,
      state: {
        batchPaymentIds,
        billListModified,
        confirmationOrigin: ConfirmationOrigin.BATCH_PAYMENT,
      },
    });
  };

  render() {
    const {
      legalAddress,
      legalCompanyName,
      taxId,
      taxIdType,
      validationErrors,
      isLoading,
      contactFirstName,
      contactLastName,
      companyAddress,
      phone,
      openLegalAddressInput,
      isInternational,
      dateOfBirth,
    } = this.state;
    const { companyInfo } = this.props;

    return (
      <QBOPayBillCompleteLegalInfoPage
        legalCompanyName={legalCompanyName}
        companyName={companyInfo.companyName}
        taxId={taxId}
        taxIdType={taxIdType}
        address={legalAddress}
        contactFirstName={contactFirstName}
        contactLastName={contactLastName}
        phone={phone}
        companyInfo={companyInfo}
        companyAddress={companyAddress}
        openLegalAddressInput={openLegalAddressInput}
        goExit={this.goExit}
        onAddressChange={this.onAddressChange}
        validationErrors={validationErrors}
        onLegalInfoChange={this.onLegalInfoChange}
        handleOnSubmit={this.handleOnSubmit}
        isLoading={isLoading}
        isInternational={isInternational}
        dateOfBirth={dateOfBirth}
        texts={{
          title: isInternational
            ? 'bills.pay.confirm.completeLegalInfo.internationalTitle'
            : 'bills.pay.confirm.completeLegalInfo.title',
          subtitle: isInternational
            ? 'bills.pay.confirm.completeLegalInfo.internationalSubtitle'
            : 'bills.pay.confirm.completeLegalInfo.subtitle',
          loading: 'bills.pay.confirm.completeLegalInfo.titleLoading',
          button: 'bills.pay.confirm.completeLegalInfo.action',
        }}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  companyInfo: getCompanyInfo(state),
  orgId: getOrgId(state),
  paymentList: paymentStore.selectors.getPaymentList(state),
  redirectUrl: paymentStore.selectors.qboBatchPaymentsRedirectUrl(state),
  profile: getProfile(state),
  paidPayments: paymentStore.selectors.getPaidPayments(state),
  billListModified: paymentStore.selectors.billListModified(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  setProfile(profile: RecordOf<UserContextType>) {
    dispatch(setProfileAction(profile));
  },
  setCompanyInfo(companyInfo: RecordOf<CompanyInfoType>) {
    dispatch(setCompanyInfoAction(companyInfo));
  },
  setQboBatchPaymentsNavigationUrls(params: any) {
    return getPaymentsActions(dispatch).setQboBatchPaymentsNavigationUrls(params);
  },
  createBatchPayment(params: any) {
    return getPaymentsActions(dispatch).createBatchPayment(params);
  },
  createBatchBulkPayment({ history }: { history: any }) {
    return dispatch(batchBulkActions.createBatchBulkPayment({ history }));
  },
});

export default compose(
  withNavigator(),
  withSiteContext(),
  withRedirectToDashboard(),
  withHasFeatureFlag(),
  connect(mapStateToProps, mapDispatchToProps)
)(PayBatchCompleteLegalInfoPageContainer);
