export const cardStyle = {
  display: 'flex',
  p: ['ds.xl', 'ds.2xl'],
  bgColor: 'ds.white',
  flexDirection: 'column',
  border: '1px',
  borderRadius: '0.6rem',
  borderColor: 'gray.200',
  boxSizing: 'border-box',
  textStyle: 'ds.body2',
  color: 'black',
  fontWeight: '400',
};

export const logoStyle = {
  textAlign: 'center',
  my: 10,
};

export const successTitleStyle = {
  textStyle: 'ds.h5',
  mt: 'ds.2xl',
  mb: 'ds.md',
};

export const successSubtitleStyle = {
  textStyle: 'ds.body1',
  m: 0,
};
