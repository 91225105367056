import React from 'react';
import { IntlFormatters } from 'react-intl';
import { MIFormattedDate } from 'src/app/version-2/externals';
import { DeliveryOption } from 'src/app/version-2/model/dtos/deliveryOption';
import {
  DeliveryEnum,
  FastFeeDeliveryEnum,
  FundingSourceTypesEnum,
} from 'src/app/version-2/model/enums';
import { DeliveryDateFormat } from 'src/app/version-2/pages/batch-bulk/components/DeliveryDateFormat';
import { FundingSourceItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { Indication } from 'src/app/version-2/pages/batch-bulk/renderers/ArrivalDate/ArrivalDate.renderer.styles';
import { isBusinessDay, isTomorrowBusinessDay } from 'src/app/version-2/utils/dates.utils';

import { isSameDay, isTomorrow } from 'date-fns';

interface GetSubtitleType {
  selectedPaymentMethod?: FundingSourceItem;
  deliveryOption: DeliveryOption;
  deliveryDate: Date;
  formatMessage: IntlFormatters['formatMessage'];
}

export const getSubtitle = ({
  selectedPaymentMethod,
  deliveryOption,
  deliveryDate,
  formatMessage,
}: GetSubtitleType) => {
  const dateValue = { date: <MIFormattedDate date={deliveryOption.deliveryDate} /> };

  if (deliveryOption.type === DeliveryEnum.ACH && selectedPaymentMethod) {
    if (selectedPaymentMethod.fundingType === FundingSourceTypesEnum.ACH) {
      return formatMessage(
        { id: 'bills.pay.date.deliveryOptions.subTitle.ach-on-date' },
        dateValue
      );
    }

    if (selectedPaymentMethod.fundingType === FundingSourceTypesEnum.CARD) {
      if (isTomorrow(deliveryDate)) {
        return formatMessage({
          id: `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}-tomorrow`,
        });
      }

      return formatMessage(
        { id: `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}-on-date` },
        dateValue
      );
    }
  }

  if (deliveryOption.type === FastFeeDeliveryEnum.EXPEDITED_ACH) {
    if (isSameDay(new Date(), deliveryDate)) {
      return formatMessage({
        id: `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}-today`,
      });
    }

    if (isTomorrow(deliveryDate)) {
      return formatMessage({
        id: `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}-tomorrow`,
      });
    }

    return formatMessage(
      { id: `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}-on-date` },
      dateValue
    );
  }

  return formatMessage({ id: `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}` });
};

interface GetDescriptionType {
  selectedPaymentMethod?: FundingSourceItem;
  deliveryOption: DeliveryOption;
  deliveryDate: Date;
  isCheck: boolean;
  isFastCheck: boolean;
  formatMessage: IntlFormatters['formatMessage'];
}

export const getDescription = ({
  selectedPaymentMethod,
  deliveryOption,
  deliveryDate,
  isCheck,
  isFastCheck,
  formatMessage,
}: GetDescriptionType) => {
  if (deliveryOption.type === FastFeeDeliveryEnum.EXPEDITED_ACH) {
    if (isSameDay(new Date(), deliveryDate)) {
      return formatMessage({
        id: `bills.pay.date.deliveryOptions.descriptions.${deliveryOption.type}-today`,
      });
    }

    if (isTomorrow(deliveryDate) && isBusinessDay(deliveryOption.deliveryDate)) {
      return formatMessage({
        id: `bills.pay.date.deliveryOptions.descriptions.${deliveryOption.type}-tomorrow`,
      });
    }

    if (isSameDay(new Date(), deliveryDate) && !isTomorrowBusinessDay()) {
      return formatMessage({
        id: `bills.pay.date.deliveryOptions.descriptions.${deliveryOption.type}-tomorrow`,
      });
    }

    return formatMessage({
      id: `bills.pay.date.deliveryOptions.descriptions.${deliveryOption.type}-on-date`,
    });
  }

  if (deliveryOption.type === DeliveryEnum.ACH && selectedPaymentMethod) {
    if (selectedPaymentMethod.fundingType === FundingSourceTypesEnum.CARD) {
      if (isTomorrow(deliveryDate)) {
        return formatMessage({
          id: `bills.pay.date.deliveryOptions.descriptions.${deliveryOption.type}-tomorrow`,
        });
      }

      return formatMessage({
        id: `bills.pay.date.deliveryOptions.descriptions.${deliveryOption.type}-on-date`,
      });
    }
  }

  if (
    deliveryOption.type === FastFeeDeliveryEnum.OVERNIGHT_CHECK &&
    isSameDay(new Date(), deliveryDate)
  ) {
    return formatMessage({ id: 'batchBulkPage.renderers.arrivalDate.sameDayDeliveryExplanation' });
  }

  const deliveryExplanationLabel = isCheck
    ? 'bills.pay.date.deliveryOptions.deliveryExplanationCheck'
    : 'bills.pay.date.deliveryOptions.descriptions.ach';

  return formatMessage(
    { id: deliveryExplanationLabel },
    {
      date: (
        <DeliveryDateFormat
          date={deliveryOption.deliveryDate.toString()}
          maxDate={deliveryOption.maxDeliveryDate.toString()}
        />
      ),
      indication: isFastCheck && (
        <Indication>
          {formatMessage({
            id: 'batchBulkPage.renderers.arrivalDate.deliveryExplanationCheckIndication',
          })}
        </Indication>
      ),
    }
  );
};
