import React from 'react';
import { CompanyInfoType } from 'src/app/utils/types';
import styled, { css } from 'styled-components';
import analytics from 'src/app/services/analytics';
import PaymentItem from 'src/app/pages/bill/pay/PaymentItem';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BatchDataItem } from 'src/app/pages/bill/pay/types';
import { usePartialPayments } from './hooks/usePartialPayments';

type Props = {
  data: BatchDataItem[];
  onDeductionDateChange: (params: any, deliveryOptions: any) => void;
  onFastToggle: (params: any) => void;
  onItemSelected: (currentId: any) => void;
  selectedItemId?: string | null;
  orgId: number;
  companyInfo: CompanyInfoType;
  isErrorIcon?: boolean;
};

const PaymentItemList = ({
  orgId,
  data,
  selectedItemId,
  onDeductionDateChange,
  onItemSelected,
  onFastToggle,
  companyInfo,
  isErrorIcon,
}: Props) => {
  const { getPaymentAmount } = usePartialPayments(null);

  if (!data) {
    return null;
  }

  return (
    <Container>
      <PaymentListTitle />
      {data.map((item) => (
        <PaymentItem
          key={item.bill.id}
          item={item}
          orgId={orgId}
          amount={getPaymentAmount(item.bill)}
          isSelected={item.bill.id.toString() === selectedItemId}
          onDeductionDateChange={onDeductionDateChange}
          onFastToggle={onFastToggle}
          onItemSelected={(currentId) => {
            analytics.trackAction('bill-selected');
            onItemSelected(currentId);
          }}
          companyInfo={companyInfo}
          isErrorIcon={isErrorIcon}
        />
      ))}
    </Container>
  );
};

const PaymentListTitle = () => (
  <PaymentListTitleContainer>
    <VendorTitle>
      <MIFormattedText label="batchPayment.paymentListTitle.vendor" />
    </VendorTitle>
    <PaymentMethodTitle>
      <MIFormattedText label="batchPayment.paymentListTitle.paymentMethod" />
    </PaymentMethodTitle>
    <DeductionDateTitle>
      <MIFormattedText label="batchPayment.paymentListTitle.deductionDate" />
    </DeductionDateTitle>
    <DeliveryMethodTitle>
      <MIFormattedText label="batchPayment.paymentListTitle.deliveryMethod" />
    </DeliveryMethodTitle>
    <DeliverySpeedTitle>
      <MIFormattedText label="batchPayment.paymentListTitle.deliverySpeed" />
    </DeliverySpeedTitle>
    <DeliveryDateTitle>
      <MIFormattedText label="batchPayment.paymentListTitle.deliveryDate" />
    </DeliveryDateTitle>
    <AmountTitle>
      <MIFormattedText label="batchPayment.paymentListTitle.amount" />
    </AmountTitle>
  </PaymentListTitleContainer>
);

export default PaymentItemList;

const Cell = css`
  text-transform: uppercase;
  color: #6b6c72;
  font-size: 1rem;
  line-height: 1.8rem;
  width: 11.7rem;
  margin-right: 2rem;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const PaymentListTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-bottom: 0.4rem;
`;

const VendorTitle = styled.div`
  ${Cell};
`;

const DeductionDateTitle = styled.div`
  ${Cell};
  margin-right: 2rem;
`;

const DeliveryDateTitle = styled.div`
  ${Cell};
`;

const PaymentMethodTitle = styled.div`
  ${Cell};
  display: flex;
  align-items: center;
  min-width: 11.3rem;
`;

const DeliveryMethodTitle = styled.div`
  ${Cell};
`;

const DeliverySpeedTitle = styled.div`
  ${Cell};
`;

const AmountTitle = styled.div`
  ${Cell};
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
`;
