import styled from 'styled-components';

export const AddNewBillButton = styled.button`
  width: 117px;
  height: 36px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  border-radius: 2rem;
  border: 0;
  background-color: ${(props) => props.theme.colors.primary.opaque};
  box-sizing: border-box;
  padding: 0.6rem 2.4rem;
  color: ${(props) => props.theme.colors.white.opaque};
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  margin-inline-start: 0.8rem;
  user-select: none;
  &:hover {
    background-color: rgba(30, 144, 10, 1);
  }
  &:active {
    background-color: ${(props) => props.theme.colors.brand};
  }
  svg {
    margin-inline-start: 15px;
  }
`;
