import React from 'react';
import { Box, Popover, PopoverProps } from '@melio/billpay-design-system';

import * as styles from './styles';

type BasicPopoverProps = PopoverProps;

type FTUTooltipProps = BasicPopoverProps & {
  children?: React.ReactNode;
  label?: React.ReactNode;
  showCloseIcon?: boolean;
  isOpen?: boolean;
};

const FTUTooltip = ({ children, label, isOpen, showCloseIcon, ...rest }: FTUTooltipProps) => (
  <Popover isOpen={isOpen} arrowColor="ds.black" {...rest}>
    <Popover.PopoverTrigger>
      <Box>{children}</Box>
    </Popover.PopoverTrigger>
    <Popover.PopoverContent
      data-testid="switcher-tooltip"
      onClick={(e) => e.stopPropagation()}
      {...styles.content}
    >
      <Popover.PopoverBody {...styles.body}>
        {label}
        {showCloseIcon && (
          <Popover.PopoverCloseButton
            data-testid="switcher-tooltip-close-icon"
            position="relative"
            {...styles.close}
          />
        )}
      </Popover.PopoverBody>
    </Popover.PopoverContent>
  </Popover>
);

export { FTUTooltip };
