import { useEffect, useState } from 'react';
import { useApi } from 'src/app/hoc/useApi';
import authApi from 'src/app/services/api/auth';
import { shouldUse2FAHeaders } from 'src/app/version-2/utils/headers-2fa.utils';

export const enum VerificationState {
  Pending = 'pending',
  Verified = 'verified',
  Failed = 'falied',
  FailedMaxAttempts = 'failed-max-attempts',
  FailedBlocked = 'failed-blocked',
}

export const enum ResendState {
  Initial = 'initial', // no warning + no countdown
  Hidden = 'hidden', // no warning + no countdown
  Pending = 'pending', // warning depends on prev state + countdown
  Regular = 'regular', // warning + countdown
  Final = 'final', // warning + no resend
  FinalHidden = 'final-hidden', // no warning + no resend
}

type Props = {
  siteName: string;
  onSuccess: VoidFunction;
  onFailure: VoidFunction;
};

type Response = {
  processing: boolean;
  email: string | null;
  resendState: ResendState;
  verificationState: VerificationState;
  resetVerificationState: VoidFunction;
  requestCode: VoidFunction;
  verifyCode: (code: string) => void;
};

const useAuthCodeVerification = ({ siteName, onSuccess, onFailure }: Props): Response => {
  const [email, setEmail] = useState<string | null>(null);
  const [verificationState, setVerificationState] = useState<VerificationState>(
    VerificationState.Pending
  );
  const [resendState, setResendState] = useState<ResendState>(ResendState.Initial);

  const [requestAuthCode, , requestingAuthCode] = useApi(authApi.requestAuthCode);
  const [verifyAuthCode, , verifyingAuthCode] = useApi(authApi.verifyAuthCode);
  const [verifyAuthCodeForHeaderToken, , verifyingAuthCodeForHeaderToken] = useApi(
    authApi.verifyAuthCodeForHeaderToken
  );

  const requestCode = async () => {
    try {
      const backupResendState = resendState;

      setResendState(ResendState.Pending);

      const { maskedEmail, codeVerificationStatus, isAttemptsLimitExceeded } =
        await requestAuthCode();

      setEmail(maskedEmail);

      if (codeVerificationStatus !== 'pending' && isAttemptsLimitExceeded) {
        // An edge case where all codes are invalid and there are no more attempts to resend the code
        setResendState(ResendState.Final);
        setVerificationState(VerificationState.FailedBlocked);
      } else if (backupResendState === ResendState.Initial) {
        setResendState(isAttemptsLimitExceeded ? ResendState.FinalHidden : ResendState.Hidden);
      } else {
        setResendState(isAttemptsLimitExceeded ? ResendState.Final : ResendState.Regular);
      }
    } catch {
      // handling is not required, see:
      // https://docs.google.com/document/d/159ifT__jne5sH-ADb8aFWAQdeZk4pfTO2kOWVkKLaeA/edit#heading=h.gd3xgog3zh2u
    }
  };

  useEffect(() => {
    requestCode();
  }, []);

  const verifyCode = async (code: string) => {
    try {
      const { succeded, codeVerificationStatus, isAttemptsLimitExceeded } = shouldUse2FAHeaders(
        siteName
      )
        ? await verifyAuthCodeForHeaderToken(Number(code))
        : await verifyAuthCode(Number(code));

      if (succeded) {
        setVerificationState(VerificationState.Verified);
        onSuccess();

        return;
      }

      if (codeVerificationStatus !== 'pending' && isAttemptsLimitExceeded) {
        // An edge case where all codes are invalid and there are no more attempts to resend the code
        setVerificationState(VerificationState.FailedBlocked);
      } else {
        setVerificationState(
          codeVerificationStatus === 'max-attempts'
            ? VerificationState.FailedMaxAttempts
            : VerificationState.Failed
        );
      }

      onFailure();
    } catch {
      setVerificationState(VerificationState.Failed);
      onFailure();
    }
  };

  const resetVerificationState = () => setVerificationState(VerificationState.Pending);

  return {
    processing: requestingAuthCode || verifyingAuthCode || verifyingAuthCodeForHeaderToken,
    email,
    resendState,
    verificationState,
    resetVerificationState,
    requestCode,
    verifyCode,
  };
};

export { useAuthCodeVerification };
