import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { VendorsInput } from '../types';
import { EMPTY_EMAIL_ERROR } from './consts';

export const validateEmail = (
  vendorsEmailInput: VendorsInput
): Record<string, { contactEmail: string }> => {
  let validationErrors = {};

  Object.entries(vendorsEmailInput).forEach(([key, value]) => {
    if (isEmpty(value.contactEmail)) {
      validationErrors = { ...validationErrors, [key]: { contactEmail: EMPTY_EMAIL_ERROR } };
    } else {
      const error = getValidationErrors(
        'vendor',
        { contactEmail: vendorsEmailInput[key].contactEmail },
        ['contactEmail']
      );

      if (!isEmpty(error))
        validationErrors = {
          ...validationErrors,
          [key]: error,
        };
    }
  });

  return validationErrors;
};
