import { ReactNode } from 'react';
import { Modal } from './Modal';

type Props = {
  size?: string;
  isOpen: boolean;
  onClose: () => void;
  title?: string | ReactNode;
  text?: string | ReactNode;
  children?: ReactNode;
  label?: string | ReactNode;
  onClick?: () => void;
};

export const AlertModal = ({
  isOpen,
  onClose,
  title,
  text,
  onClick,
  label,
  children,
  size,
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} size={size}>
    <Modal.CloseButton />
    <Modal.Header>
      <Modal.HeaderTitle>{title}</Modal.HeaderTitle>
      <Modal.HeaderSubTitle>{text}</Modal.HeaderSubTitle>
    </Modal.Header>
    {children ? <Modal.Body>{children}</Modal.Body> : null}
    <Modal.ButtonContent>
      {label ? (
        <Modal.Button variant="primary" size="md" onClick={onClick}>
          {label}
        </Modal.Button>
      ) : null}
    </Modal.ButtonContent>
  </Modal>
);
