import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { ProgressBarFlavorEnum } from 'src/app/version-2/model/enums';

type FlavorType = ProgressBarFlavorEnum.WIZARD | ProgressBarFlavorEnum.MELIO_ME;

type Props = {
  relativeStep: number;
  flavor: FlavorType;
};

const ProgressBar = ({ relativeStep, flavor }: Props) => {
  const width = Math.round(relativeStep * 100) / 100;

  return (
    <ProgressContainer flavor={flavor}>
      <TotalBar flavor={flavor}>
        <Progress
          style={{ width: `${width * 100}%` }}
          isComplete={relativeStep >= 1}
          flavor={flavor}
        />
      </TotalBar>
    </ProgressContainer>
  );
};

ProgressBar.defaultProps = {};

export default ProgressBar;

const ProgressContainer = styled.div<{ flavor: FlavorType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 3;
`;

const TotalBar = styled.div<{ flavor: FlavorType }>`
  background-color: ${(props) => props.theme.colors.progress.undone};
  height: ${(props) => (props.flavor === ProgressBarFlavorEnum.MELIO_ME ? '0.6rem' : '0.8rem')};
  border-radius: 0.4rem;

  @media ${devices.mobile}, ${devices.phablet} {
    width: ${(props) => (props.flavor === ProgressBarFlavorEnum.WIZARD ? '12.5rem' : '100%')};
    margin-top: 0;
    height: ${(props) => (props.flavor === ProgressBarFlavorEnum.MELIO_ME ? '0.4rem' : '0.8rem')};
  }

  @media ${devices.desktop}, ${devices.tablet} {
    width: ${(props) => (props.flavor === ProgressBarFlavorEnum.WIZARD ? '25%' : '100%')};
    max-width: ${(props) => (props.flavor === ProgressBarFlavorEnum.WIZARD ? '22.5rem' : '100%')};
    margin: 0;
  }
  ${(props) => props.theme.components?.ProgressBar?.TotalBar}
`;

const Progress = styled.div<{ isComplete?: boolean; flavor: FlavorType }>`
  background-color: ${(props) => props.theme.colors.progress.done};
  height: 100%;
  max-width: 100%;

  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: ${(props) => (props.isComplete ? '0.4rem' : '0')};
  border-top-left-radius: 0.4rem;
  border-top-right-radius: ${(props) => (props.isComplete ? '0.4rem' : '0')};
`;
