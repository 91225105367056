import { CreateDeliveryMethodResponse } from 'src/app/version-2/api/reponses/CreateDeliveryMethodResponse';
import { FetchDeliveryMethodByIdResponse } from 'src/app/version-2/api/reponses/FetchDeliveryMethodByIdResponse';
import { fetchSupportedCountriesResponse } from 'src/app/version-2/api/reponses/fetchSupportedCountriesResponse';
import { UpdateDeliveryMethodResponse } from 'src/app/version-2/api/reponses/UpdateDeliveryMethodResponse';
import { CreateDeliveryMethodRequest } from 'src/app/version-2/api/requests/CreateDeliveryMethodRequest';
import { FetchDeliveryMethodByIdRequest } from 'src/app/version-2/api/requests/FetchDeliveryMethodByIdRequest';
import { UpdateDeliveryMethodRequest } from 'src/app/version-2/api/requests/UpdateDeliveryMethodRequest';
import { fetchRequest, postRequest, putRequest } from 'src/app/version-2/externals';

export const deliveryMethodsApi = {
  fetchSupportedCountries: (orgId, vendorId) =>
    fetchRequest<fetchSupportedCountriesResponse>(
      `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international/countriesList?origin=billpay`
    ),
  fetchById({ orgId, vendorId, id }: FetchDeliveryMethodByIdRequest) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;

    return fetchRequest<FetchDeliveryMethodByIdResponse>(url);
  },
  create({ orgId, vendorId, data }: CreateDeliveryMethodRequest) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods`;

    return postRequest<CreateDeliveryMethodResponse>(url, data);
  },
  update({ orgId, vendorId, deliveryMethodId, data }: UpdateDeliveryMethodRequest) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${deliveryMethodId}`;

    return putRequest<UpdateDeliveryMethodResponse>(url, data);
  },
};
