import { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Moment } from 'moment';
import { Link } from '@melio/billpay-design-system';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import {
  isBusinessDay,
  isSameDay,
  isTomorrow,
  getEndOfLifeMaxDate,
  isEndOfLifeMonth,
} from 'src/app/utils/dates';
import DatePicker from 'src/app/components/form/DatePicker';
import { convertDateToMonthYearFormat, isCardExpired } from 'src/app/utils/card';
import {
  DeliveryOptionType,
  FieldDateType,
  NavigateType,
  OptionalDeliveryMethodsType,
  OrganizationPreferencesType,
} from 'src/app/utils/types';
import { devices } from 'src/app/theme/AppDevices';
import {
  CONSTS,
  NOTIFICATION_VARIANT,
  CARD_TYPES,
  NOTIFICATION_CARD_TYPES,
} from 'src/app/utils/consts';
import {
  dismissNotification,
  pushNotification,
} from 'src/app/services/notifications/notificationService';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import PaymentDatePickerInfo from 'src/app/pages/bill/pay/components/PaymentDatePickerInfo';

import { CreditCardBenefitTile } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitTile';
import { ReactComponent as CalendarIcon } from 'src/app/images/credit-card-benefits/calendar-large.svg';
import { getPaymentMethodNameParts } from 'src/app/version-2/utils/paymentMethods.utils';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import { useBenefits } from 'src/app/pages/bill/pay/hooks/useBenefits';
import { CreditCardBenefitsModal } from 'src/app/components/common/CreditCardBenefitsModal/CreditCardBenefitsModal';
import locations from 'src/app/utils/locations';
import { withNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import { useCreditCardSourcesList } from 'src/app/hooks/useCreditCardSourcesList';
import { CreditCardSourcesListModal } from 'src/app/components/common/SelectMethods/containers/CreditCardSourcesListModal';
import { getFundingSources } from 'src/app/redux/user/selectors';
import {
  FundingSourceTypesEnum,
  TextInputSizeEnum,
  CardTypeEnum,
} from 'src/app/version-2/model/enums';
import MINotificationCard from './MINotificationCard';
import React from 'react';

type Props = {
  fundingSource: FundingSource | undefined;
  deliveryDate: Date;
  deliveryOptions?: DeliveryOptionType[];
  maxDeliveryDate: Date | null;
  deliveryMethodType: OptionalDeliveryMethodsType;
  scheduledDate: Date;
  totalAmount: string;
  deliveryPreference?: string;
  dueDate?: Date | null;
  minDate?: Date;
  onChange: (value: FieldDateType) => Promise<void> | void;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    type: string
  ) => void;
  navigate: NavigateType;
  eventPage: string;
  loadSuggestedProcessingDate?: (bypasses?: any) => Promise<void>;
  onCreditCardSelected?: (card: FundingSource) => void;
  navigationOrigin?: string;
  billId?: string;
};

const PaymentDatePicker = ({
  fundingSource,
  deliveryDate,
  deliveryOptions,
  maxDeliveryDate,
  deliveryMethodType,
  scheduledDate,
  totalAmount,
  minDate,
  onChange,
  dueDate,
  onSelectDeliveryOption,
  deliveryPreference,
  fundingSourceType,
  navigate,
  eventPage,
  loadSuggestedProcessingDate,
  onCreditCardSelected,
  navigationOrigin,
  billId,
}: Props) => {
  const { pathname } = useLocation();
  const fundingSources = useSelector(getFundingSources);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const isFirstWave = useMemo(() => !!organizationPreferences.billPayFirstWaveUser, []);

  const endOfLifeLabel = useMemo(
    () =>
      isFirstWave
        ? 'bills.pay.date.scheduledDateEndOfLifeNotificationFirstWave'
        : 'bills.pay.date.scheduledDateEndOfLifeNotificationSecondWave',
    [isFirstWave]
  );

  const creditSources = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.CARD &&
      fs.cardAccount?.cardType === CardTypeEnum.CREDIT &&
      fs.isVerified &&
      !isCardExpired(fs.cardAccount)
  );

  const hasCreditSources = creditSources?.length > 0;

  const expirationDate = fundingSource?.cardAccount?.expiration;
  const card4digits = fundingSource?.cardAccount?.card4digits;
  const cardNetwork = fundingSource?.cardAccount?.network;
  const [showExpirationNotification, setShowExpirationNotification] = useState(false);
  const [showEOLNotification, setShowEOLNotification] = useState(false);
  const toggleExpirationLabel = useCallback((dateToCompare: Moment) => {
    const isExpirationDateHappened = isCardExpired({ expiration: expirationDate }, dateToCompare);

    setShowExpirationNotification(isExpirationDateHappened);
  }, []);

  const toggleEndOfLifeNotification = useCallback((dateToCompare: Moment) => {
    const isEndOfLifeMonthResult = isEndOfLifeMonth(dateToCompare, isFirstWave);

    setShowEOLNotification(isEndOfLifeMonthResult);
  }, []);

  const onMonthChange = useCallback((date: Moment) => {
    toggleExpirationLabel(date);
    toggleEndOfLifeNotification(date);
  }, []);

  const getFilteredDate = (date) =>
    isCardExpired({ expiration: expirationDate }, date) ? false : isBusinessDay(date);
  const { onBenefitsClicked, shouldShowBenefitsModal, onCloseBenefitsModal } = useBenefits();
  const {
    onCloseCreditCardSourcesListModal,
    onCreditCardSourcesListClicked,
    shouldShowCreditCardSourcesListModal,
  } = useCreditCardSourcesList();
  const shouldShowCreditCardTile =
    fundingSourceType === FundingSourceTypesEnum.ACH &&
    deliveryMethodType === CONSTS.DELIVERY_TYPE.ACH;
  const goAddFundingSources = useCallback(
    (type: string) => {
      analytics.track(eventPage, `add-${type}-card`);
      navigate(locations.Onboarding.fundingSources.card.index.url(), false, {
        redirectUrl: pathname,
        exitUrl: pathname,
        preservedState: {
          origin: navigationOrigin,
        },
      });
    },
    [pathname]
  );
  const benefitsClickedHandler = useCallback(async () => {
    if (!hasCreditSources) {
      onBenefitsClicked();

      return;
    }

    if (creditSources?.length === 1) {
      onCreditCardSelected?.(creditSources[0]);
      analytics.trackAction('pay-by-card-clicked', { fundingSourceId: creditSources[0].id });
      await loadSuggestedProcessingDate?.({ fundingSourceId: creditSources[0].id });
      showChangeToCreditCardNotification();
    } else {
      onCreditCardSourcesListClicked();
    }
  }, [creditSources?.length, scheduledDate, loadSuggestedProcessingDate, onCreditCardSelected]);

  const timeKey = useMemo(() => {
    if (isSameDay(scheduledDate)) {
      return 'today';
    }

    if (isTomorrow(scheduledDate)) {
      return 'tomorrow';
    }

    return 'future';
  }, [scheduledDate]);

  const {
    creditCardBenefitsLinkKey,
    creditCardBenefitsLabelKey,
    labelAdditionalValues = {},
  } = useMemo(() => {
    if (!hasCreditSources) {
      return {
        creditCardBenefitsLinkKey: 'bills.pay.date.creditCardBenefits.bottomBanner.noCard.link',
        creditCardBenefitsLabelKey: `bills.pay.date.creditCardBenefits.bottomBanner.noCard.label.${timeKey}`,
      };
    }

    if (creditSources?.length === 1) {
      return {
        creditCardBenefitsLinkKey: 'bills.pay.date.creditCardBenefits.bottomBanner.oneCard.link',
        creditCardBenefitsLabelKey: `bills.pay.date.creditCardBenefits.bottomBanner.oneCard.label.${timeKey}`,
        labelAdditionalValues: getPaymentMethodNameParts({ fundingSource: creditSources[0] }),
      };
    }

    return {
      creditCardBenefitsLinkKey:
        'bills.pay.date.creditCardBenefits.bottomBanner.multipleCards.link',
      creditCardBenefitsLabelKey: `bills.pay.date.creditCardBenefits.bottomBanner.noCard.label.${timeKey}`,
    };
  }, [creditSources?.length, timeKey]);

  const showChangeToCreditCardNotification = () => {
    pushNotification({
      type: NOTIFICATION_VARIANT.SUCCESS,
      msg: 'bills.pay.date.creditCardBenefits.notification',
      autoClose: true,
    });
  };

  const creditCardSelectedHandler = async (card?: FundingSource) => {
    if (!card) return;

    onCreditCardSelected?.(card);
    onCloseCreditCardSourcesListModal();
    await loadSuggestedProcessingDate?.({ fundingSourceId: card.id });

    showChangeToCreditCardNotification();
  };

  const goLearnMore = () => {
    analytics.track('end-of-life-single', 'learn-more', { isFirstWave });
    window.open(
      'https://quickbooks.intuit.com/learn-support/en-us/help-article/money-movement/switch-bill-pay-powered-melio-quickbooks-bill-pay/L98z4nrOH_US_en_US?uid=lofvkaen',
      '_blank'
    );
  };

  useEffect(() => {
    if (shouldShowCreditCardTile) {
      const cta = creditSources?.length > 0 ? 'Pay by card' : 'View Benefits';

      analytics.track(eventPage, 'bill-cc-promotion-in-datepicker-shown', { cta });
    }
  }, []);

  useEffect(() => {
    toggleExpirationLabel(scheduledDate as unknown as Moment);
  }, [toggleExpirationLabel, scheduledDate]);

  useEffect(() => {
    toggleEndOfLifeNotification(scheduledDate as unknown as Moment);
  }, [toggleEndOfLifeNotification, scheduledDate]);

  useEffect(() => {
    let toastId;

    if (showExpirationNotification) {
      toastId = pushNotification({
        type: NOTIFICATION_VARIANT.ERROR,
        msg: 'bills.pay.date.cardExpiredLabel',
        textValues: {
          cardNetwork,
          card4digits,
          expirationDate: convertDateToMonthYearFormat(expirationDate as string),
        },
        autoClose: false,
      });
    }

    return () => {
      dismissNotification(toastId);
    };
  }, [showExpirationNotification]);

  return (
    <PaymentDatePickerContainer>
      <DatePicker
        id="scheduledDate"
        date={scheduledDate}
        dueDate={dueDate}
        required
        size={TextInputSizeEnum.INLINE}
        inline
        withBottomElement
        min={minDate}
        max={getEndOfLifeMaxDate(isFirstWave)}
        filterDate={getFilteredDate}
        onChange={({ id, date }) => onChange({ id, value: date })}
        onMonthChange={onMonthChange}
        overrideMobile
      />
      {showEOLNotification ? (
        <NotificationCardContainer>
          <NotificationCard
            type={NOTIFICATION_CARD_TYPES.INFO}
            subtitle={{
              label: endOfLifeLabel,
              values: {
                learnMoreLink: (...chunks) => (
                  <Link onClick={goLearnMore} cursor="pointer" isExternal>
                    {React.Children.toArray(chunks)}
                  </Link>
                ),
              },
            }}
          />
        </NotificationCardContainer>
      ) : null}
      <DateDescriptionContainer>
        {scheduledDate && (
          <DateDescription>
            <Circle isScheduledDate />
            <DateDescriptionTextOneLine>
              <MIFormattedText
                label="bills.pay.date.billScheduledDate"
                values={{
                  date: (
                    <DateContainer>
                      <MIFormattedDate date={scheduledDate} />
                    </DateContainer>
                  ),
                }}
              />
            </DateDescriptionTextOneLine>
          </DateDescription>
        )}
        {dueDate && (
          <DueDateDescription>
            <Circle />
            <DateDescriptionTextOneLine>
              <MIFormattedText
                label="bills.pay.date.billDueDate"
                values={{
                  date: (
                    <DateContainer>
                      <MIFormattedDate date={dueDate} />
                    </DateContainer>
                  ),
                }}
              />
            </DateDescriptionTextOneLine>
          </DueDateDescription>
        )}
      </DateDescriptionContainer>
      <PaymentDatePickerInfo
        fundingSource={fundingSource}
        scheduledDate={scheduledDate}
        totalAmount={totalAmount}
        deliveryDate={deliveryDate}
        maxDeliveryDate={maxDeliveryDate}
        deliveryMethodType={deliveryMethodType}
        dueDate={dueDate}
        deliveryOptions={deliveryOptions || []}
        deliveryPreference={deliveryPreference}
        fundingSourceType={fundingSourceType}
        onSelectDeliveryOption={onSelectDeliveryOption}
      />
      {shouldShowCreditCardTile && (
        <CreditCardBenefitTile
          isBottomBanner
          title={`bills.pay.date.creditCardBenefits.bottomBanner.title.${timeKey}`}
          icon={CalendarIcon}
          text={creditCardBenefitsLabelKey}
          textValues={{
            ...labelAdditionalValues,
            link: (
              <BenefitsLink
                label={creditCardBenefitsLinkKey}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  benefitsClickedHandler();
                }}
              />
            ),
          }}
        />
      )}
      {shouldShowBenefitsModal && (
        <CreditCardBenefitsModal
          onButtonClick={() => goAddFundingSources(CARD_TYPES.CREDIT)}
          onCloseClick={onCloseBenefitsModal}
        />
      )}
      {shouldShowCreditCardSourcesListModal && (
        <CreditCardSourcesListModal
          onCloseClick={onCloseCreditCardSourcesListModal}
          onButtonClick={creditCardSelectedHandler}
          creditSources={creditSources}
          analyticsProperties={{ billId }}
        />
      )}
    </PaymentDatePickerContainer>
  );
};

const textStyles = css`
  ${(props) => props.theme.text.fontType.hint};
`;

const PaymentDatePickerContainer = styled.div`
  width: 35.9rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin: 0 auto 1.6rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  border: 1px solid rgba(237, 237, 237, 1);

  .react-datepicker {
    border: none;
  }

  @media ${devices.mobile} {
    width: 100%;
    margin: 0;
  }
  ${(props) => props.theme?.components?.PaymentDatePicker?.PaymentDatePickerContainer}
`;

const DateContainer = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.PaymentDatePicker?.DateContainer}
`;

const DateDescriptionText = styled.div`
  ${textStyles}
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme?.components?.PaymentDatePicker?.DateDescriptionText}
`;
const DateDescriptionTextOneLine = styled(DateDescriptionText)`
  white-space: nowrap;
`;

const DateDescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white.opaque};
  padding: 0rem 2rem 1.8rem;
  ${(props) => props.theme?.components?.PaymentDatePicker?.DateDescriptionContainer}
`;

const DateDescription = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme?.components?.PaymentDatePicker?.DateDescription}
`;

const DueDateDescription = styled(DateDescription)`
  ${(props) => props.theme?.components?.PaymentDatePicker?.DueDateDescription}
`;

const Circle = styled.div<{ isScheduledDate?: boolean }>`
  box-sizing: border-box;
  height: 1.1rem;
  width: 1.1rem;
  border: 2px solid
    ${(props) =>
      props.isScheduledDate ? props.theme.colors.brand : props.theme.colors.border.dark};
  background-color: ${(props) => props.isScheduledDate && props.theme.colors.brand};
  border-radius: 50%;
  margin-right: 0.75rem;
  ${(props) => props.theme?.components?.PaymentDatePicker?.Circle}
`;

const BenefitsLink = styled(MIInlineLink)`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
`;

const NotificationCard = styled(MINotificationCard)`
  padding: 1.6rem;
`;

const NotificationCardContainer = styled.div`
  margin: 1.4rem 2rem;
`;

export default withNavigator()(PaymentDatePicker);
