import { AccountRecord } from 'src/app/pages/settings/records';
import { fetchRequest, postRequest } from './api';
import { loggingApi } from 'src/app/version-2/api/loggers';

const list = ({ orgId, ...params }) => {
  const url = `/orgs/${orgId}/funding-sources`;

  return fetchRequest(url, params);
};

const syncQBCash = ({ orgId }: { orgId: string }) => {
  const url = `/orgs/${orgId}/funding-sources/sync-from-qbcash`;

  return postRequest(url)
    .then((res) => AccountRecord(res.fundingSource).toJS())
    .catch((error) => {
      loggingApi.error('fundingSourcesApi.syncQBCash(): request error', error.stack);

      return error;
    });
};

const getQBCashBalanace = ({ orgId }: { orgId: string }) => {
  const url = `/orgs/${orgId}/funding-sources/qbcash-balance`;

  return fetchRequest(url);
};

const fundingSourcesApi = {
  list,
  syncQBCash,
  getQBCashBalanace,
};

export default fundingSourcesApi;
