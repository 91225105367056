import React from 'react';
import { VendorType, DeliveryMethodType } from 'src/app/utils/types';
import { CONSTS, VENDOR_TYPES } from 'src/app/utils/consts';
import { sortFundingSourceSelectOptions } from 'src/app/utils/funding-sources';
import { FundingSource } from 'src/app/version-2/model/dtos';
import isEmpty from 'lodash/isEmpty';
import { SelectMethods } from '../components/SelectMethods';
import { AchPaymentMethod } from '../types/AchPaymentMethod';
import { QboAchPaymentMethod } from '../types/QboAchPaymentMethod';
import { CreditPaymentMethod } from '../types/CreditPaymentMethod';
import { DebitPaymentMethod } from '../types/DebitPaymentMethod';
import { MethodListItemType } from '../components/MethodsList';
import { getVendorType } from 'src/app/utils/vendor-utils';
import {
  FundingSourceTypesEnum,
  FundingSourceOrigins,
  CardTypeEnum,
} from 'src/app/version-2/model/enums';

type Props<T> = {
  value?: T;
  fundingSources: T[];
  fundingSourcePlaceholder: string;
  placeholderIcon: string;
  vendor?: VendorType;
  errorLabelValues?: any;
  errorLabel?: string;
  onChange: (value: T) => void;
  onAddMethod: (type: string) => void;
  methodErrorsMap?: any;
  selectedDeliveryMethod?: DeliveryMethodType;
  debitFee?: React.ReactNode | string;
  onBenefitsClicked?: (card?: FundingSource) => void;
};

export const QboFundingSourcesSelect = ({
  value,
  fundingSources,
  vendor,
  onAddMethod,
  onChange,
  placeholderIcon,
  fundingSourcePlaceholder,
  errorLabelValues,
  errorLabel,
  methodErrorsMap,
  selectedDeliveryMethod,
  debitFee,
  onBenefitsClicked,
}: Props<FundingSource>) => {
  const isSelectedDeliveryMethodVirtualCard =
    selectedDeliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;

  const qboAchMethod = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.ACH && fs.origin === FundingSourceOrigins.QBCASH
  );
  const achMethods = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.ACH && fs.origin !== FundingSourceOrigins.QBCASH
  );
  const creditMethods = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.CARD &&
      fs.cardAccount?.cardType === CardTypeEnum.CREDIT
  );
  const debitMethods = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.CARD &&
      fs.cardAccount?.cardType === CardTypeEnum.DEBIT
  );
  const isCardsDisabled = getVendorType(vendor) === VENDOR_TYPES.INTERNATIONAL;
  const cardsDescription = isCardsDisabled
    ? 'bills.form.paymentActivity.scheduledBill.international.credit'
    : '';
  const debitDescription = isCardsDisabled
    ? 'bills.form.paymentActivity.scheduledBill.international.debit'
    : '';

  const list: MethodListItemType[] = sortFundingSourceSelectOptions([
    {
      componentType: CreditPaymentMethod,
      methods: creditMethods,
      disabled: isCardsDisabled,
      description: cardsDescription,
      methodErrors: { ...methodErrorsMap.creditPaymentMethod },
      isSelectedDeliveryMethodVirtualCard,
      onBenefitsClicked,
    },
    {
      componentType: AchPaymentMethod,
      methods: achMethods,
      methodErrors: { ...methodErrorsMap.achPaymentMethod },
    },
    {
      componentType: DebitPaymentMethod,
      methods: debitMethods,
      disabled: isCardsDisabled,
      description: debitDescription,
      debitFee,
      methodErrors: { ...methodErrorsMap.debitPaymentMethod },
    },
  ]);

  if (!isEmpty(qboAchMethod)) {
    list.unshift({
      componentType: QboAchPaymentMethod,
      methods: qboAchMethod,
      methodErrors: { ...methodErrorsMap.qboAchPaymentMethod },
    });
  }

  return (
    <SelectMethods
      value={value}
      errorLabelValues={errorLabelValues}
      errorLabel={errorLabel}
      id="funding-source"
      placeholderIcon={placeholderIcon || 'icon-bank-icon'}
      placeholderLabel={fundingSourcePlaceholder || 'selectMethods.fundingSourcePlaceholder'}
      onChange={onChange}
      onAddMethod={onAddMethod}
      list={list}
      showAddAnotherOption
    />
  );
};
