import captureStackTrace from 'capture-stack-trace';

const getName = ({ method, url, code }: Omit<ServerErrorParams, 'message'>) =>
  [method, url && url.replace(/\d+/g, '_'), code].join(':');

export interface ServerErrorParams {
  message: string;
  code: string;
  url: string;
  method: string;
}
export class ServerError extends Error {
  private code: string;
  private url: string;
  private method: string;

  constructor({ message, code, url, method }: ServerErrorParams) {
    super(message);
    captureStackTrace(this, this.constructor);
    this.name = this.constructor.name || getName({ method, url, code });
    this.message = message;
    this.code = code;
    this.url = url;
    this.method = method;
  }
}
