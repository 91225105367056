import { DELIVERY_TYPE, PAYMENT_STATUS } from 'src/app/utils/consts';
import { PaymentType } from 'src/app/utils/types';
import { PaymentCollectStatusEnum, PaymentDeliveryStatusEnum } from 'src/app/version-2/model/enums';

import { PAYMENT_ANALYTICS_STATUSES, TIMELINE_STEP_STATUSES } from '../consts';

const { FUTURE_STEP, SUCCEED, FAILED } = TIMELINE_STEP_STATUSES;

const getPaymentDeductionStatus = (payment: PaymentType): TIMELINE_STEP_STATUSES => {
  if (payment.status === PAYMENT_STATUS.FAILED) return FAILED;

  if (payment.status === PAYMENT_STATUS.SCHEDULED) return FUTURE_STEP;

  if ([PAYMENT_STATUS.IN_PROGRESS, PAYMENT_STATUS.COMPLETED].includes(payment.status))
    return SUCCEED;

  if (
    ![
      PaymentCollectStatusEnum.SENT,
      PaymentCollectStatusEnum.CLEARED,
      PaymentCollectStatusEnum.SETTLED,
    ].includes(payment.collectStatus)
  ) {
    return FUTURE_STEP;
  }

  return SUCCEED;
};

const getPaymentDeliveryStatus = (payment: PaymentType): TIMELINE_STEP_STATUSES => {
  if (payment.status === PAYMENT_STATUS.FAILED) return FAILED;

  if (payment.status === PAYMENT_STATUS.COMPLETED) return SUCCEED;

  return [
    PaymentDeliveryStatusEnum.SENT,
    PaymentDeliveryStatusEnum.CLEARED,
    PaymentDeliveryStatusEnum.SETTLED,
  ].includes(payment.deliverStatus)
    ? SUCCEED
    : FUTURE_STEP;
};

const getPaymentClearingStatus = (payment: PaymentType): TIMELINE_STEP_STATUSES => {
  if (payment.status === PAYMENT_STATUS.FAILED) return FAILED;

  return [PaymentDeliveryStatusEnum.CLEARED, PaymentDeliveryStatusEnum.SETTLED].includes(
    payment.deliverStatus
  )
    ? SUCCEED
    : FUTURE_STEP;
};

const getPaymentCurrentStatus = (payment: PaymentType): PAYMENT_ANALYTICS_STATUSES => {
  if (payment?.status === PAYMENT_STATUS.FAILED) return PAYMENT_ANALYTICS_STATUSES.FAILED;

  if ([PAYMENT_STATUS.SCHEDULED, PAYMENT_STATUS.BLOCKED].includes(payment?.status)) {
    return PAYMENT_ANALYTICS_STATUSES.SCHEDULED;
  }

  if (
    [
      PaymentCollectStatusEnum.SENT,
      PaymentCollectStatusEnum.CLEARED,
      PaymentCollectStatusEnum.SETTLED,
    ].includes(payment.collectStatus) &&
    !payment.deliverStatus
  ) {
    return PAYMENT_ANALYTICS_STATUSES.DELIVERED;
  }

  const statusByDeliveryType = {
    [DELIVERY_TYPE.ACH]: {
      [PaymentDeliveryStatusEnum.SENT]: PAYMENT_ANALYTICS_STATUSES.DELIVERED,
      [PaymentDeliveryStatusEnum.CLEARED]: PAYMENT_ANALYTICS_STATUSES.PAID,
      [PaymentDeliveryStatusEnum.ACKNOWLEDGED]: PAYMENT_ANALYTICS_STATUSES.PAID,
    },
    [DELIVERY_TYPE.CHECK]: {
      [PaymentDeliveryStatusEnum.SENT]: PAYMENT_ANALYTICS_STATUSES.DELIVERED,
      [PaymentDeliveryStatusEnum.CLEARED]: PAYMENT_ANALYTICS_STATUSES.DEPOSITED,
      [PaymentDeliveryStatusEnum.SETTLED]: PAYMENT_ANALYTICS_STATUSES.DEPOSITED,
    },
    [DELIVERY_TYPE.CARD]: {
      [PaymentDeliveryStatusEnum.SENT]: PAYMENT_ANALYTICS_STATUSES.PAID,
    },
    [DELIVERY_TYPE.VIRTUAL_CARD]: {
      [PaymentDeliveryStatusEnum.FAILED]: PAYMENT_ANALYTICS_STATUSES.EXPIRED,
      [PaymentDeliveryStatusEnum.SENT]: PAYMENT_ANALYTICS_STATUSES.CLEARED,
      [PaymentDeliveryStatusEnum.CLEARED]: PAYMENT_ANALYTICS_STATUSES.CLEARED,
    },
  };

  const deliveryTypeStatuses = statusByDeliveryType[payment.deliveryMethod.deliveryType];

  return deliveryTypeStatuses[payment.deliverStatus] || payment.deliverStatus;
};

export {
  getPaymentDeductionStatus,
  getPaymentDeliveryStatus,
  getPaymentClearingStatus,
  getPaymentCurrentStatus,
};
