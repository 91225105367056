import { postRequest, fetchRequest } from './api';

export default {
  createRequest({ orgId, ownedVendorId, data }) {
    const url = `/orgs/${orgId}/vendor-requests/${ownedVendorId}`;

    return postRequest(url, data);
  },

  async getQBvendorInfo({ orgId, vendorId }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-method-suggestions`;

    return fetchRequest(url);
  },
};
