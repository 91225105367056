import React from 'react';
import get from 'lodash/get';
import styled, { css } from 'styled-components';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { getCurrencyMask, convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { FieldType } from 'src/app/utils/types';
import { CURRENCY_SIGN, FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import MINotices from 'src/app/components/common/MINotices';
import { devices } from 'src/app/theme/AppDevices';

type Props = {
  totalAmount?: string;
  validationErrors?: Record<string, any>;
  onChange?: (FieldType) => void;
  viewOnly?: boolean;
};

class QBRAmountInput extends React.PureComponent<Props> {
  static defaultProps = {
    validationErrors: {},
  };

  // eslint-disable-next-line react/sort-comp
  hiddenAmountRef = React.createRef<HTMLSpanElement>();

  amountContainerRef = React.createRef<HTMLDivElement>();

  amountInputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    this.recalculateInputWidth();
  }

  onChange = (data: FieldType) => {
    const { onChange } = this.props;

    if (onChange) {
      this.recalculateInputWidth();
      onChange(data);
    }
  };

  // Considering the fact that amount input should be centered horizontally,
  // to show $ sign right before the input we need to change the width of the input dinamicaly,
  // based on inputed data or placeholder. For this purpose was introduced hidden span element,
  // which has the same font and text as input and is used to get the inputed text width
  recalculateInputWidth = () => {
    // Because of `text-mask` lib issue we need to get DOM element from current.inputElement,
    // not just from `current` https://github.com/text-mask/text-mask/pull/871#issue-229558396
    const inputElement = get(this.amountInputRef, 'current.inputElement');

    if (inputElement && this.amountContainerRef.current && this.hiddenAmountRef.current) {
      this.hiddenAmountRef.current.innerText =
        inputElement.value || inputElement.getAttribute('placeholder');
      const currencySignWidth = 30;
      const containerWidth = this.amountContainerRef.current.offsetWidth - currencySignWidth;
      const hiddenElementWidth = this.hiddenAmountRef.current.offsetWidth;
      const newInputWidth =
        hiddenElementWidth > containerWidth ? containerWidth : hiddenElementWidth;

      inputElement.style.width = `${newInputWidth + 20}px`;
    }
  };

  render() {
    const { totalAmount, validationErrors, viewOnly } = this.props;
    const totalAmountValue =
      viewOnly && totalAmount
        ? parseFloat(convertCurrencyToNumber(totalAmount)).toFixed(2)
        : totalAmount;

    return (
      <AmountContainer ref={this.amountContainerRef}>
        <AmountInputContainer className={FULL_STORY_MASK_RULE_CLASS}>
          <CurrencySign>{CURRENCY_SIGN.USD}</CurrencySign>
          <HiddenAmountElement ref={this.hiddenAmountRef} />
          {viewOnly ? (
            <AmountViewOnly data-testid="request-total-amount">{totalAmountValue}</AmountViewOnly>
          ) : (
            <MITextInput
              id="totalAmount"
              label=""
              value={totalAmountValue}
              placeholder="guests.register.amountPlaceholder"
              inputRef={this.amountInputRef}
              mask={getCurrencyMask('')}
              onChange={this.onChange}
              autocomplete="off"
              autoFocus
            />
          )}
        </AmountInputContainer>
        {validationErrors && (
          <AmountInputNotices data-testid="error-message">
            <MINotices errorMessage={validationErrors.totalAmount} />
          </AmountInputNotices>
        )}
      </AmountContainer>
    );
  }
}

const fontSizeStyles = css`
  font-size: 4.2rem;
`;

const placeholderStyles = css`
  ${fontSizeStyles}
  color: ${(props) => props.theme.text.color.main};
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AmountInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => props.theme.text.weight.demi};
  .input-container {
    width: inherit;
    margin-bottom: 0;
  }

  #totalAmount {
    ${fontSizeStyles}
    text-align: left;
    border: 0;
    height: 6.5rem;
    line-height: 6.5rem;
    max-width: 40rem;

    &::placeholder {
      ${placeholderStyles}
    }

    &::-moz-placeholder {
      ${placeholderStyles}
      opacity: 1;
    }
  }

  @media ${devices.desktop}, ${devices.tablet} {
    margin-top: 1.6rem;
  }
`;

const HiddenAmountElement = styled.span`
  ${fontSizeStyles}
  visibility: hidden;
  position: fixed;
  display: none;
`;

const AmountViewOnly = styled.span`
  line-height: 4.4rem;
  color: ${(props) => props.theme.text.color.white};
  font-size: 3.2rem;

  @media ${devices.desktop}, ${devices.tablet} {
    ${(props) => props.theme.text.fontType.hugest};
  }
`;

const CurrencySign = styled.span`
  margin-right: 0.2rem;
  font-size: 3.4rem;

  @media ${devices.desktop}, ${devices.tablet} {
    ${(props) => props.theme.text.fontType.hugest};
  }
`;
const AmountInputNotices = styled.span`
  margin: -1rem 0 1rem;
`;

export default QBRAmountInput;
