import { theme } from '@melio/billpay-design-system';
import { devices } from 'src/app/version-2/utils/devices.utils';

import styled from 'styled-components';

export const ContainerStyle = {
  width: theme.sizes.ds.full,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.colors.ds.white,
};

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${(props) => props.theme.space.ds.xl};
  max-width: ${(props) => props.theme.sizes.ds.full};
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.ds.xl};
`;

export const OrganizationSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${(props) => props.theme.space.ds.sm};
  text-align: center;
`;

export const CardFieldsContainer = styled.div`
  input {
    color: ${(props) => props.theme.colors.ds.gray[100]};
  }
`;

export const OrganizationName = styled.div`
  ${(props) => props.theme.textStyles.ds.h5};
  font-weight: ${(props) => props.theme.fontWeights.ds.semi};
`;

export const VirtualCardDescription = styled.div`
  ${(props) => props.theme.textStyles.ds.body1};
  color: ${(props) => props.theme.colors.ds.gray[300]};
`;

export const VirtualCardEstimation = styled.div`
  ${(props) => props.theme.textStyles.ds.body3};
  text-align: center;
  color: ${(props) => props.theme.colors.ds.black};
  background-color: ${(props) => props.theme.colors.ds.gray[800]};
  padding: ${(props) => props.theme.space.ds.md} ${(props) => props.theme.space.ds.lg};
  border-radius: ${({ theme }) => theme.radii.ds.lg};

  @media ${devices.mobile} {
    ${(props) => props.theme.textStyles.ds.body2};
  }
`;
