import { collectInvoiceActionsApi } from 'src/app/version-2/api/collectInvoiceActions.api';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { RiskTypeEnum } from 'src/app/version-2/model/enums';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { getRiskByCountryCode } from 'src/app/version-2/utils/countries.utils';
import { collectInvoiceActions, collectInvoiceSelectors } from './collectInvoice.slice';

import { call, put, select, takeEvery } from 'redux-saga/effects';

function* fetchCollectInvoiceHandler(
  action: ReturnType<typeof collectInvoiceActions.fetchCollectInvoice>
) {
  const { vendorId, countryCode, countries = [], billId } = action.payload;

  if (!billId) return;

  const countryRisk = getRiskByCountryCode(countries, countryCode);

  try {
    if (countryRisk === RiskTypeEnum.Medium && vendorId) {
      const existingFirstPayment = yield select(
        collectInvoiceSelectors.selectInvoicetByVendorId(vendorId)
      );

      // ignoring if previous value exists
      if (!existingFirstPayment) yield put(collectInvoiceActions.fetchFirstPayment({ vendorId }));
    } else if (countryRisk === RiskTypeEnum.High) {
      yield put(
        collectInvoiceActions.setShowInvoice({
          vendorId,
          showInvoice: true,
        })
      );
    } else if (countryRisk === RiskTypeEnum.Low) {
      yield put(
        collectInvoiceActions.setShowInvoice({
          vendorId,
          showInvoice: false,
        })
      );
    }
  } catch (e) {
    loggingApi.error('collectInvoiceSaga.fetchCollectInvoiceHandler(): failed', e);
  }
}

function* fetchFirstPaymentHandler(
  action: ReturnType<typeof collectInvoiceActions.fetchFirstPayment>
) {
  const { vendorId } = action.payload;

  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const { isFirstPayment } = yield call(collectInvoiceActionsApi.fetch, {
      orgId,
      vendorId,
    });

    yield put(
      collectInvoiceActions.setShowInvoice({
        vendorId,
        showInvoice: isFirstPayment,
      })
    );
  } catch (e) {
    loggingApi.error('collectInvoiceSaga.fetchFirstPaymentHandler(): failed', e);

    yield put(
      collectInvoiceActions.setShowInvoice({
        vendorId,
        showInvoice: false,
      })
    );
  }
}

export function* watchCollectInvoiceSaga() {
  yield takeEvery(collectInvoiceActions.fetchCollectInvoice, fetchCollectInvoiceHandler);
  yield takeEvery(collectInvoiceActions.fetchFirstPayment, fetchFirstPaymentHandler);
}
