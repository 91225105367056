import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { name } from './consts';

type SliceState = {
  byId: Record<string, any>;
};

type SetStatusPayload = {
  payload: {
    id: string;
    processing: boolean;
  };
};

type SliceReducers = {
  setStatus: (state: SliceState, payload: SetStatusPayload) => void;
};

const createMarkAsUnpaidStatusSlice = () => {
  const slice = createSlice<any, SliceReducers>({
    name: `[${name.toUpperCase()}] MARK_AS_UNPAID_STATUS`,
    initialState: { byId: {} },
    reducers: {
      setStatus(state, { payload }) {
        state.byId[payload.id] = merge(state.byId[payload.id] || {}, {
          markingAsUnpaid: payload.processing,
        });
      },
    },
  });

  return {
    ...slice,
    selectors: {
      processing: (state) =>
        Object.values(state[name]?.byId || {}).some((value: any) => value.markingAsUnpaid),
    },
    dispatchers: {
      setStatus: (dispatch) => (params) => dispatch(slice.actions.setStatus(params)),
    },
  };
};

const markAsUnpaidStatusSlice = createMarkAsUnpaidStatusSlice();

export { markAsUnpaidStatusSlice };
