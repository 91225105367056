import { CONSTS } from 'src/app/utils/consts';
import { DeliveryMethodType } from 'src/app/utils/types';

export const isAchDeliveryMethod = (deliveryMethod: DeliveryMethodType) =>
  deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH;
export const isCheckDeliveryMethod = (deliveryMethod: DeliveryMethodType) =>
  deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK;
export const extractFirstAndLastNameFromFullName = (fullName = '') => {
  const nameChunks = (fullName ?? '').trim().split(' '); // added ?? operator to handle fullName = null case
  const lastName = nameChunks.pop();
  const firstName = nameChunks.join(' ');

  return {
    lastName,
    firstName,
  };
};
