import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import GeneralFlowErrorContainer from 'src/app/pages/quickbooks/GeneralFlowErrorContainer';
import { getCompanyInfo } from 'src/app/redux/user/selectors';
import processesIcon from 'src/app/images/icons/gray-info.svg';
import analytics from 'src/app/services/analytics';
import { useSiteContext } from 'src/app/hoc/withSiteContext';

const PaymentFailed = ({ state }) => {
  const site = useSiteContext();
  const companyInfo = useSelector(getCompanyInfo);

  const { organization, deliveryMethod } = state;
  const companyEmail = organization?.userOrganizations?.[0]?.user?.email;
  const supportEmail = site.config.support.email;
  const OriginDeliveryType = deliveryMethod?.deliveryType || '';

  useEffect(() => {
    if (OriginDeliveryType) analytics.page('push-to-debit', 'blocked-payment-by-risk');
  }, [OriginDeliveryType]);

  return (
    <GeneralFlowErrorContainer
      illustration={processesIcon}
      title="vendors.deliveryMethods.shiftToDebit.errors.cannotProcessed.title"
      subtitle="vendors.deliveryMethods.shiftToDebit.errors.cannotProcessed.subtitle"
      subtitleValues={{
        companyName: companyInfo?.companyName,
        companyEmail: <EmailLink href={`mailto:${companyEmail}`}>{companyEmail}</EmailLink>,
        supportEmail: <EmailLink href={`mailto:${supportEmail}`}>{supportEmail}</EmailLink>,
      }}
    />
  );
};

export default PaymentFailed;

const EmailLink = styled.a`
  text-decoration: none;
  color: #2ca01c;
`;
