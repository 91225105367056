import { useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { generatePath, useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { getValidationErrors } from '@melio/sizzers-js-common';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import { FilesUrls } from 'src/app/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { useForm } from 'src/app/ui/form';
import { CONSTS, PAYMENT_STATUS } from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import { getIsVendorPaymentDetailsFlow } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from 'src/app/pages/vendor/vendor-payment-tracking/locations';
import {
  CheckType,
  DeliveryMethodType,
  OptionalDeliveryMethodsType,
  PaymentType,
  VendorType,
  PaymentFeeItem,
  OrganizationType,
} from 'src/app/utils/types';
import { PaymentDeliveryStatusEnum } from 'src/app/version-2/model/enums';
import { useShiftToDebitHooksState } from 'src/app/pages/vendor/shift-vendor-to-debit/hooks/useShiftToDebitHooksState';
import locations from '../locations';
import {
  extractFirstAndLastNameFromFullName,
  isAchDeliveryMethod,
  isCheckDeliveryMethod,
} from '../utils';

export type ShiftToDebitState = {
  payment: PaymentType;
  organization: any;
  filesUrls?: FilesUrls;
  isPaymentLoading: boolean;
  deliveryMethod: DeliveryMethodType;
  vendor: VendorType;
  fee?: PaymentFeeItem;
};

export type ShiftToDebitDeliveryMethodType = {
  cardToken?: string;
  expiration?: string;
  digits?: string;
  cardBin?: string;
  deliveryType?: OptionalDeliveryMethodsType;
  cardAccount: CheckType;
};

export type ShiftToDebitDeliveryMethodActionsType = {
  validateAddress: (address) => void;
};

const onValidateAddress = async (
  state: ShiftToDebitState,
  actions: ShiftToDebitDeliveryMethodActionsType,
  value: ShiftToDebitDeliveryMethodType
) => {
  const { payment } = state;
  const cardAccount = {
    ...value.cardAccount,
    deliveryType: CONSTS.DELIVERY_TYPE.CARD,
    deliveryMethodId: payment.deliveryMethodId,
    printName: value.cardAccount.printName.split(' '),
  };

  await actions.validateAddress(cardAccount);
};

export function useShiftToDebitState(token) {
  const { paymentId, vendorId } = getJWTPayload(token);

  const history = useHistory();
  const paymentActions = useStoreActions(paymentStore);
  const fetchPaymentDetailsWithToken = useCallback(async () => {
    try {
      await paymentActions.fetchPaymentDetailsWithToken({
        token,
        action: 'addDebitCardToVendor',
      });

      analytics.trackAction('ptd-starts', {
        paymentId,
        vendorId,
      });
    } catch (e) {
      history.push(generatePath(locations.invalidToken, { token }));
    }
  }, [paymentActions, token, history]);

  useEffect(() => {
    fetchPaymentDetailsWithToken();
  }, [fetchPaymentDetailsWithToken]);

  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));

  const { isLoading, fee, filesUrls } = useShiftToDebitHooksState(paymentId);

  const currentDeliveryMethod: DeliveryMethodType = useSelector(
    deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId)
  );
  const organization: OrganizationType = useSelector(
    organizationStore.selectors.byId(payment?.organization?.id)
  );
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const { vendor } = useFetchVendor(payment?.vendorId);

  useEffect(() => {
    const isCheckDM = isCheckDeliveryMethod(currentDeliveryMethod);
    const isAchDM = isAchDeliveryMethod(currentDeliveryMethod);
    const isCompleted = payment?.status === PAYMENT_STATUS.COMPLETED;
    const isBlocked = payment?.status === PAYMENT_STATUS.BLOCKED;
    const isFailed = payment?.status === PAYMENT_STATUS.FAILED;
    const deliverStatus = payment?.deliverStatus;

    if (isFailed || (isCheckDM && isBlocked)) {
      // general failed (cannot processed)
      history.replace(generatePath(locations.paymentFailed, { token }));
    } else if (currentDeliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CARD) {
      if (getIsVendorPaymentDetailsFlow(token)) {
        history.replace(generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token }));
      } else {
        // success
        history.replace(generatePath(locations.success, { token }));
      }
    } else if (isCheckDM && deliverStatus === PaymentDeliveryStatusEnum.CLEARED) {
      // check deposited
      history.replace(generatePath(locations.checkDeposited, { token }));
    } else if (isCheckDM && deliverStatus === PaymentDeliveryStatusEnum.CANCEL_SCHEDULED) {
      // check - payment already processed
      history.replace(generatePath(locations.paymentProcessed, { token }));
    } else if (isCheckDM && payment?.isCheckToP2DOfferExpired) {
      // expired
      history.replace(generatePath(locations.checkOfferExpired, { token }));
    } else if (isAchDM && isCompleted) {
      // failed - default invalid link
      history.replace(generatePath(locations.invalidToken, { token }));
    }
  }, [currentDeliveryMethod, payment, history, token]);

  const deliveryMethodModel: ShiftToDebitDeliveryMethodType = useMemo(
    () => ({
      deliveryType: CONSTS.DELIVERY_TYPE.CARD,
      cardAccount: {
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        zipCode: '',
        printName: '',
      },
    }),
    []
  );

  const enhancedState: ShiftToDebitState = {
    payment,
    organization,
    filesUrls,
    isPaymentLoading: isLoading,
    deliveryMethod: currentDeliveryMethod,
    vendor,
    fee,
  };

  const validateForm = async (enhancedState, deliveryMethodActions, value, deliveryMethodMV) => {
    onValidateAddress(enhancedState, deliveryMethodActions, value);
    const { firstName, lastName } = extractFirstAndLastNameFromFullName(
      value.cardAccount?.printName
    );
    const notEmptyNames = [firstName, lastName].filter((name) => !!name);

    const newValues = {
      ...value,
      cardAccount: {
        ...value.cardAccount,
        printName: notEmptyNames,
      },
    };

    const res = await getValidationErrors('deliveryMethod', newValues);

    deliveryMethodMV.setValidationErrors(res);
  };

  const [deliveryMethodMV, deliveryMethodMVActions] = useForm(deliveryMethodModel, {
    submit: (value) => validateForm(enhancedState, deliveryMethodActions, value, deliveryMethodMV),
  });

  return {
    state: enhancedState,
    deliveryMethodMV,
    onValidateAddress: deliveryMethodMVActions.submit,
  };
}
