import { postRequest } from 'src/app/version-2/externals';
import { ValidateCardAccountRequest } from 'src/app/version-2/pages/add-card-account/api/fundingSource/requests/ValidateCardAccountRequest';
import { CreateCardAccountResponse } from 'src/app/version-2/pages/add-card-account/api/fundingSource/responses/CreateCardAccountResponse';
import { ValidateCardResponse } from 'src/app/version-2/pages/add-card-account/api/fundingSource/responses/ValidateCardResponse';

export const addCardFundingSourceApi = {
  validateCard(params: Omit<ValidateCardAccountRequest, 'digits' | 'expiration'>) {
    const { orgId, cardToken, cardBin, cardNumberIdentifiers } = params;
    const url = `/orgs/${orgId}/funding-sources/card/validate`;

    return postRequest<ValidateCardResponse>(
      url,
      {
        token: cardToken,
        cardBin,
      },
      {
        headers: {
          'cardnumber-fingerprint': cardNumberIdentifiers.cardNumberFingerprint,
        },
      }
    );
  },

  createCardAccount(orgId, params, options) {
    const url = `/orgs/${orgId}/funding-sources/card/create-account`;

    return postRequest<CreateCardAccountResponse>(url, params, {}, options);
  },
};
