import styled from 'styled-components';

export const SectionTitle = styled.div`
  ${(props) => props.theme.textStyles.ds.body2}
  color: ${(props) => props.theme.colors.ds.gray[200]};
  margin-bottom: 0.4rem;
`;

export const Value = styled.div`
  ${(props) => props.theme.textStyles.ds.body1}
  color: ${(props) => props.theme.colors.ds.gray[300]};
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
`;
