import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Box, Tooltip } from '@melio/billpay-design-system';

import analytics from 'src/app/services/analytics';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import billsStore from 'src/app/modules/bills/bills-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';

import { TitleStyle, footerWrapperStyle } from './styles';
import QBOFooterButton from './components/QBOFooterButton';
import useScheduleBatchPaymentsAction from '../../hooks/useScheduleBatchPaymentsAction';
import useBatchMarkAsPaidAction from '../../hooks/useBatchMarkAsPaidAction';
import useSchedulePaymentAction from '../../hooks/useSchedulePaymentAction';
import { DashboardListItemType } from '../../types';
import { useSelectedBillsMetadata } from './hooks/useSelectedBillsMetadata';
import { BillType } from 'src/app/utils/types';

export type Action = {
  onClick: () => void;
  label: string;
  labelValues?: Record<string, any>;
};

type Props = {
  isOpenFooter: boolean;
};

function QBODashboardFooter({ isOpenFooter }: Props) {
  const selectedDashboardItemsIds: string[] = useSelector(
    qbDashboardListItemsStore.selectors.qbDashboardSelection.selectedIds
  );
  const selectedDashboardListItems: DashboardListItemType[] = useSelector(
    qbDashboardListItemsStore.selectors.getByIds(selectedDashboardItemsIds)
  );
  const selectedBillsIds = selectedDashboardListItems.map((i) => i.billId);
  const itemsCount = selectedDashboardItemsIds.length;
  const isBatch = itemsCount > 1;
  const bills = useSelector(billsStore.selectors.getByIds(selectedBillsIds)) as BillType[];

  const { hasBillWithInvalidAmount, totalAmount, partialPaymentsCount } = useSelectedBillsMetadata({
    selectedBillsIds,
    isBatch,
  });
  const partialPaymentsBillAmountsDict: Record<string, number> = useSelector(
    qbDashboardListItemsStore.selectors.partialPaymentsAmounts.all
  );

  const [schedulePayment] = useSchedulePaymentAction();

  const goSchedulePayment = () => {
    analytics.trackAction('batch-actions', {
      action: 'schedule-single',
      billIds: selectedBillsIds,
      partialPaymentsCount,
    });

    const initialPaymentAmount = partialPaymentsBillAmountsDict[selectedBillsIds[0]];

    schedulePayment({
      billId: bills[0]?.id,
      billOriginId: bills[0]?.originId,
      initialPaymentAmount,
    });
  };
  const [goScheduleBatchPayments] = useScheduleBatchPaymentsAction(selectedBillsIds);

  const [batchMarkBillsAsPaid, BatchMarkAsPaidModal] = useBatchMarkAsPaidAction(selectedBillsIds);

  const actions: Action[] = [
    {
      label: 'paymentDashboard.footer.payOnline',
      labelValues: { amount: itemsCount },
      onClick: isBatch ? goScheduleBatchPayments : goSchedulePayment,
    },
    {
      label: 'paymentDashboard.footer.markAsPaid',
      onClick: () => {
        analytics.trackAction('batch-actions', {
          action: 'mark-as-paid',
          billIds: selectedBillsIds,
          partialPaymentsCount,
        });
        batchMarkBillsAsPaid();
      },
    },
  ];

  return (
    <>
      {BatchMarkAsPaidModal}
      <Flex
        data-testid="qb-dashboard-footer"
        display={isOpenFooter ? 'flex' : 'none'}
        __css={footerWrapperStyle}
      >
        <Box __css={TitleStyle} data-testid="qb-dashboard-footer-title">
          <MIFormattedText
            label="paymentDashboard.footer.title"
            values={{
              totalAmount: <MIFormattedCurrency value={totalAmount} currency="$" />,
            }}
          />
        </Box>
        <Tooltip
          isDisabled={!hasBillWithInvalidAmount}
          label={<MIFormattedText label="paymentDashboard.footer.errorInvalidAmountsTooltip" />}
          shouldWrapChildren
        >
          <QBOFooterButton isDisabled={hasBillWithInvalidAmount} actions={actions} />
        </Tooltip>
      </Flex>
    </>
  );
}

export default QBODashboardFooter;
