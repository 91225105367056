import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Switch } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { CellContext } from '@tanstack/react-table';
import { analytics } from 'src/app/version-2/externals';
import {
  CardTypeEnum,
  DeliveryEnum,
  FastFeeDeliveryEnum,
  FeatureFlagsEnum,
} from 'src/app/version-2/model/enums';
import {
  BATCH_BULK_EVENT_PAGE,
  TOGGLE_FAST_OPTION,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { EXPEDITED_DELIVERY_TYPES } from 'src/app/version-2/pages/batch-bulk/model/consts/expeditedDeliveryTypes.consts';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  FastDeliveryWrap,
  NoDeliveryMethodText,
} from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';
import { deliveryDurationSpeedLabelMap } from 'src/app/version-2/pages/batch-bulk/utils/deliverySpeed.utils';

export const DeliverySpeedRenderer: FC<CellContext<BatchBulkItem, number>> = (props) => {
  const {
    row: {
      original: {
        amount,
        selectedDeliveryMethod,
        deliveryMethods,
        selectedPaymentMethod,
        id,
        minScheduledDate,
        deliveryEta,
        maxDeliveryEta,
        totalBillIds,
        vendorId,
        totalPartialBillIds,
        deliverySpeedEnabled,
        type,
        fastFee,
      },
    },
  } = props;
  const [isFastCheckEnabled] = featureFlags.useFeature(FeatureFlagsEnum.FAST_CHECK_ENABLED, false);

  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const { deliveryType, id: selectedDeliveryMethodId } = selectedDeliveryMethod || {};

  const {
    fundingType: fundingSourceType,
    cardAccount,
    id: selectedPaymentMethodId,
  } = selectedPaymentMethod || {};

  const { network: fundingSourceCardNetwork, cardType } = cardAccount || {};

  const deliveryDurationSpeedLabel = useMemo(
    () =>
      (
        deliveryDurationSpeedLabelMap[deliveryType || 'default'] ||
        deliveryDurationSpeedLabelMap.default
      )({
        fundingSourceType,
        cardType,
        deliveryType,
        fundingSourceCardNetwork,
        amount,
      }),
    [fundingSourceType, cardType, deliveryType, fundingSourceCardNetwork, amount]
  );

  const fastSpeedLabel = useMemo(
    () =>
      [DeliveryEnum.ACH, 'expedited-ach'].includes(deliveryType || '')
        ? 'batchBulkPage.renderers.deliverySpeed.cells.fastAch'
        : 'batchBulkPage.renderers.deliverySpeed.cells.fastCheck',
    [deliveryType]
  );

  const showFastDeliverySwitch = useMemo(
    () =>
      selectedPaymentMethodId &&
      selectedDeliveryMethodId &&
      (isFastCheckEnabled || deliveryType !== DeliveryEnum.CHECK) &&
      deliveryType !== DeliveryEnum.VIRTUAL &&
      !(deliveryType === DeliveryEnum.ACH && cardType === CardTypeEnum.CREDIT) &&
      deliverySpeedEnabled,
    [
      deliveryType,
      deliveryMethods,
      selectedPaymentMethodId,
      selectedDeliveryMethodId,
      isFastCheckEnabled,
      deliverySpeedEnabled,
    ]
  );

  const fastDeliverySwitchValue =
    EXPEDITED_DELIVERY_TYPES.includes(deliveryType || '') || !!fastFee;

  const onDeliverySpeedChange = useCallback(
    (event) => {
      let isFast = false;

      let deliveryPreference: string | null = null;

      const deliveryPreferenceObject = {
        [DeliveryEnum.ACH]: FastFeeDeliveryEnum.EXPEDITED_ACH,
        [DeliveryEnum.CHECK]: FastFeeDeliveryEnum.EXPRESS_CHECK,
      };

      if (event.target.checked) {
        isFast = true;
        deliveryPreference = deliveryPreferenceObject[deliveryType || ''] ?? null;
      }

      if (selectedDeliveryMethod) {
        dispatch(
          batchBulkActions.setPaymentDatesAction({
            billId: id,
            deliveryEta: deliveryEta?.toISOString() as string,
            deliveryPreference,
          })
        );
        analytics.track(BATCH_BULK_EVENT_PAGE, TOGGLE_FAST_OPTION, {
          fastType: deliveryType,
          toggleStatus: isFast,
          billIds: totalBillIds,
          partialBillIds: totalPartialBillIds,
          vendorId,
        });
      }
    },
    [minScheduledDate, maxDeliveryEta]
  );

  if (type === BatchBulkItemEnum.BILL) {
    return null;
  }

  if (!deliveryType) {
    return (
      <NoDeliveryMethodText>
        {formatMessage({ id: 'batchBulkPage.renderers.deliverySpeed.cells.notAvailable' })}
      </NoDeliveryMethodText>
    );
  }

  return (
    <div>
      {showFastDeliverySwitch ? (
        <FastDeliveryWrap>
          {formatMessage({
            id: fastSpeedLabel,
          })}
          <Switch
            defaultChecked={fastDeliverySwitchValue}
            isChecked={fastDeliverySwitchValue}
            onChange={onDeliverySpeedChange}
            data-testid="fast-check-switch"
          />
        </FastDeliveryWrap>
      ) : (
        formatMessage({ id: deliveryDurationSpeedLabel })
      )}
    </div>
  );
};
