import { EventMappingsTree } from 'src/app/services/analytics/types';

const pageEvent = 'quickpay-bill-list';

const MAPPING: EventMappingsTree = {
  '/orgs/*/quickpay/bills': {
    'page.view': [pageEvent, 'quickpay-bills-list'],
    'bills.list.tabs.open': [pageEvent, 'quickpay-bills-list-tab-open-click'],
    'bills.list.tabs.completed': [pageEvent, 'quickpay-bills-list-tab-completed-click'],
    'bills.form.paymentActivity.actions.cancel': [
      pageEvent,
      'quickpay-bills-list-payment-cancel-click',
    ],
    'payments.form.deleteDialog.cancelText': [
      pageEvent,
      'quickpay-bills-list-delete-payment-canceled',
    ],
  },
};

export default MAPPING;
