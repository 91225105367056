import { HeaderAccessorEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/headerAccessor.enum';

export const screenThresholds = [1440, 1664, 1812];

export const tableSizePerThreshold = {
  [screenThresholds[0]]: '137.8rem',
  [screenThresholds[1]]: '154.8rem',
  [screenThresholds[2]]: '164.6rem',
};

export const TABLE_1440_COLUMNS = {
  [HeaderAccessorEnum.EXPANDER]: 28,
  [HeaderAccessorEnum.VENDOR]: 140,
  [HeaderAccessorEnum.BILL]: 112,
  [HeaderAccessorEnum.PAYMENT_METHOD]: 208,
  [HeaderAccessorEnum.DEDUCTION_DATE]: 123,
  [HeaderAccessorEnum.DELIVERY_METHOD]: 160,
  [HeaderAccessorEnum.DELIVERY_SPEED]: 148,
  [HeaderAccessorEnum.DELIVERY_DATE]: 218,
  [HeaderAccessorEnum.ARRIVAL_DATE]: 155,
  [HeaderAccessorEnum.AMOUNT]: 144,
  [HeaderAccessorEnum.DETAILS]: 20,
  [HeaderAccessorEnum.REMOVE]: 20,
  [HeaderAccessorEnum.MEMO]: 20,
};

export const TABLE_1664_COLUMNS = {
  [HeaderAccessorEnum.EXPANDER]: 28,
  [HeaderAccessorEnum.VENDOR]: 200,
  [HeaderAccessorEnum.BILL]: 112,
  [HeaderAccessorEnum.PAYMENT_METHOD]: 208,
  [HeaderAccessorEnum.DEDUCTION_DATE]: 175,
  [HeaderAccessorEnum.DELIVERY_METHOD]: 160,
  [HeaderAccessorEnum.DELIVERY_SPEED]: 160,
  [HeaderAccessorEnum.DELIVERY_DATE]: 220,
  [HeaderAccessorEnum.ARRIVAL_DATE]: 155,
  [HeaderAccessorEnum.AMOUNT]: 88,
  [HeaderAccessorEnum.DETAILS]: 20,
  [HeaderAccessorEnum.REMOVE]: 20,
  [HeaderAccessorEnum.MEMO]: 20,
};

export const TABLE_1812_COLUMNS = {
  [HeaderAccessorEnum.EXPANDER]: 28,
  [HeaderAccessorEnum.VENDOR]: 210,
  [HeaderAccessorEnum.BILL]: 140,
  [HeaderAccessorEnum.PAYMENT_METHOD]: 208,
  [HeaderAccessorEnum.DEDUCTION_DATE]: 175,
  [HeaderAccessorEnum.DELIVERY_METHOD]: 160,
  [HeaderAccessorEnum.DELIVERY_SPEED]: 200,
  [HeaderAccessorEnum.DELIVERY_DATE]: 220,
  [HeaderAccessorEnum.ARRIVAL_DATE]: 155,
  [HeaderAccessorEnum.AMOUNT]: 108,
  [HeaderAccessorEnum.DETAILS]: 20,
  [HeaderAccessorEnum.REMOVE]: 20,
  [HeaderAccessorEnum.MEMO]: 20,
};

export const screenSizeToColumns = {
  [screenThresholds[0]]: TABLE_1440_COLUMNS,
  [screenThresholds[1]]: TABLE_1664_COLUMNS,
  [screenThresholds[2]]: TABLE_1812_COLUMNS,
};
