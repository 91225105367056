export const container = {
  w: '46rem',
  p: '1.6rem',
  borderWidth: 1,
  borderColor: 'ds.gray.500',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textStyle: 'ds.body2',
  bgColor: 'ds.white',
  borderRadius: 'ds.md',
  boxShadow: 'ds.md',
};

export const toastContent = {
  w: 'full',
  display: 'flex',
  alignItems: 'center',
  color: 'ds.gray.100',
};

export const typeIcon = {
  mr: 10,
};

export const closeIcon = {
  cursor: 'pointer',
};
