import * as React from 'react';
import styled from 'styled-components';
import analytics from 'src/app/services/analytics';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useBreak } from 'src/app/hoc';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS, BUTTON_SIZE } from 'src/app/utils/consts';
import { getIsLoggedIn, getProfile, getOrgId } from 'src/app/redux/user/selectors';
import { MIFormattedText } from 'src/app/utils/formatting';
import Logo from 'src/app/images/Logo';
import MIButton from 'src/app/components/common/MIButton';
import locations from 'src/app/utils/locations';

type OutsideHeaderProps = {
  redirectUrl?: string;
  hideLogin?: boolean;
  analyticsProps?: Record<string, any>;
};

const OutsideHeader: React.FC<OutsideHeaderProps> = ({
  hideLogin,
  analyticsProps,
}: OutsideHeaderProps) => {
  const device = useBreak();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const profile = useSelector(getProfile);
  const orgId = useSelector(getOrgId);
  const history = useHistory();

  const goDashboard = () => {
    history.push(locations.MainApp.dashboard.url({ orgId }));
  };

  const goToLogin = () => {
    analytics.trackAction('log-in', analyticsProps);
  };

  const goLearnMore = () => {
    analytics.track('unilateral', 'learn-more');
    window.open('https://www.meliopayments.com/request');
  };

  return (
    <OutsideHeaderContainer>
      <LogoContainer onClick={goLearnMore}>
        <Logo
          fill="white"
          size={device.isMobile || device.isPhablet ? 'mobile' : 'regular'}
          isFull
        />
      </LogoContainer>
      {!hideLogin && (
        <>
          {isLoggedIn && profile.orgName ? (
            <UserContainer onClick={goDashboard}>
              <UserName>{profile.orgName}</UserName>
              <i className="icon-user-icon" />
            </UserContainer>
          ) : (
            <LogInContainer>
              <MIFormattedText
                label={
                  device.isMobile || device.isPhablet
                    ? 'vendors.addDeliveryMethodByLink.loginHintMobile'
                    : 'vendors.addDeliveryMethodByLink.loginHint'
                }
              />
              <LogInButton
                label="vendors.addDeliveryMethodByLink.loginSubmit"
                size={BUTTON_SIZE.SMALL}
                variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                onClick={goToLogin}
              />
            </LogInContainer>
          )}
        </>
      )}
    </OutsideHeaderContainer>
  );
};

export default OutsideHeader;

const OutsideHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 5rem 0;
  background-color: ${(props) => props.theme.colors.primary.opaque};
  height: 15rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0 2rem 0;
    height: 7.7rem;
    align-items: center;
  }
`;

const LogInContainer = styled.div`
  display: flex;
  align-items: baseline;
  color: ${(props) => props.theme.colors.white.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};

  @media ${devices.mobile}, ${devices.phablet} {
    font-weight: ${(props) => props.theme.text.weight.regular};
  }
`;

const LogInButton = styled(MIButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white.opaque};
  border-color: ${(props) => props.theme.colors.white.opaque};
  margin-left: 2rem;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-left: 1.6rem;
  }
`;

const LogoContainer = styled.a`
  cursor: pointer;
  height: 4.2rem;
  @media ${devices.mobile}, ${devices.phablet} {
    height: 3.5rem;
  }
`;

const UserContainer = styled.a`
  color: ${(props) => props.theme.colors.white.opaque};
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  cursor: pointer;
  display: flex;
  height: 2rem;
`;

const UserName = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
`;
