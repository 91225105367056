import React from 'react';
import styled from 'styled-components';
import { DELIVERY_TYPE } from 'src/app/utils/consts';

type Props = {
  deliveryType: string;
};

export const DeliveryMethodIcon = ({ deliveryType }: Props) => {
  switch (deliveryType) {
    case DELIVERY_TYPE.CHECK:
      return <StyledDeliveryMethodIcon className="icon-check-icon" />;
    case DELIVERY_TYPE.ACH:
      return <StyledDeliveryMethodIcon className="icon-bank-transfer" />;
    default:
      return <StyledDeliveryMethodIcon className="icon-bank-transfer" />;
  }
};

const StyledDeliveryMethodIcon = styled.i`
  font-size: 4rem;
  color: ${(props) => props.theme.colors.icons.darkGrey};
`;
