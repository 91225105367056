import { ToastId, ToastParams, UseToastOptions } from '@melio/billpay-design-system';

export interface ToastsApi {
  show(toastParams: ToastParams): ToastId;
  success(toastParams: ToastParams): ToastId;
  error(toastParams: ToastParams): ToastId;
  closeToast(id: ToastId): void;
  updateToast(id: ToastId, options: Omit<UseToastOptions, 'id'>): void;
  isActiveToast(id: ToastId): boolean;
}

export const SHOWN_TOASTS_LIMIT = 5;

export const defaultOptions: UseToastOptions = {
  position: 'top',
};
