import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { RecordOf } from 'immutable';
import { useLocation } from 'react-router-dom';
import { Popover } from '@melio/billpay-design-system';

import { BillType } from 'src/app/utils/types';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { BILL_STATUS } from 'src/app/utils/consts';

import PaymentListItem from './PaymentListItem';
import PaymentListDesign from './PaymentListDesign';

type Props = {
  bill: RecordOf<BillType>;
  orgId: string;
  paymentId: string;
};

const PaymentList = ({ bill, orgId, paymentId }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { state: historyState } = useLocation<{ state: Record<string, any> }>();

  const { id: billId, payments, totalAmount, balance, status } = bill;
  const totalPayments = totalAmount - (balance || 0);
  const isBillPaid = status === BILL_STATUS.PAID;
  const { length } = payments;
  const paymentsLength = length + (bill.externallyPaid ? 1 : 0);

  const openPopover = () => {
    if (paymentsLength > 1) setOpen(true);
  };
  const closePopover = () => setOpen(false);

  useEffect(() => {
    closePopover();
  }, [paymentId]);

  return (
    <Popover isOpen={open} onClose={closePopover} onOpen={openPopover}>
      <Popover.PopoverTrigger>
        <PaymentsMade $paymentsLength={paymentsLength}>
          <MIFormattedText
            label="bills.form.partialPayments.paymentsMade"
            values={{ count: paymentsLength }}
          />{' '}
          (
          <MIFormattedCurrency value={totalPayments} />)
        </PaymentsMade>
      </Popover.PopoverTrigger>

      <Popover.PopoverContent>
        <Popover.PopoverHeader>
          <Header>
            <MIFormattedText label="bills.form.partialPayments.openBalance" />
            <div className="amount">
              {isBillPaid ? (
                <MIFormattedText label="bills.status.paymentPaid" />
              ) : (
                <MIFormattedCurrency value={balance || ''} />
              )}
            </div>
          </Header>
        </Popover.PopoverHeader>

        <Popover.PopoverBody>
          <PaymentDetails>
            <ul className="payments-list">
              {payments.map((payment) => (
                <PaymentListItem
                  key={payment.id}
                  currentPaymentId={paymentId}
                  payment={payment}
                  orgId={orgId}
                  billId={billId}
                  historyState={historyState}
                />
              ))}

              {!!bill.externallyPaid && (
                <PaymentListDesign>
                  <div className="status">
                    <MIFormattedText label="bills.status.paymentManuallyPaid" />
                  </div>
                  <div className="amount">
                    <MIFormattedCurrency value={bill.externallyPaid || ''} />
                  </div>
                </PaymentListDesign>
              )}

              <li className="bill-amount-list-item">
                <MIFormattedText label="bills.form.billAmount" />
                <span className="bill-amount">
                  <MIFormattedCurrency value={totalAmount || ''} />
                </span>
              </li>
            </ul>
          </PaymentDetails>
        </Popover.PopoverBody>
      </Popover.PopoverContent>
    </Popover>
  );
};

export default PaymentList;

const PaymentsMade = styled.span<{ $paymentsLength?: number }>`
  color: ${(props) => props.theme.text.color.link};
  font-size: ${(props) => props.theme.text.size.hint};
  margin-bottom: 1rem;
  cursor: pointer;
  display: inline-block;

  // single payment design
  ${(props) =>
    props.$paymentsLength === 1 &&
    css`
      cursor: default;
      color: ${(props) => props.theme.text.color.darkGrey};
    `}
`;
const PaymentDetails = styled.div`
  .payments-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-width: 30rem;
    color: ${(props) => props.theme.text.color.main};
  }
  .amount,
  .bill-amount {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
  .bill-amount-list-item {
    display: flex;
    margin-top: 0.2rem;
    justify-content: space-between;
    color: ${(props) => props.theme.text.color.darkGrey};
    ${(props) => props.theme.text.fontType.hint};
    font-weight: ${(props) => props.theme.text.weight.normal};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.text.fontType.medium};
  font-weight: ${(props) => props.theme.text.weight.normal};
  color: ${(props) => props.theme.text.color.main};

  .amount {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
`;
