import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/app/hoc/useApi';
import { getOrgId } from 'src/app/redux/user/selectors';
import fundingSourceApi from 'src/app/services/api/financialAccounts';
import useRefreshFundingSources from './useRefreshFundingSources';

type LinkIntuitAccountParams = {
  fundingSourceId: string;
  intuitAccountId: string;
  orgId?: string;
};

const useLinkIntuitAccount = () => {
  const currentOrgId = useSelector(getOrgId);
  const [setIntuitAccount, , isSettingAccount] = useApi<
    [string, string, { intuitAccountId: string }],
    any
  >(fundingSourceApi.setIntuitAccountId);
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  const linkIntuitAccount = useCallback(
    async ({ fundingSourceId, intuitAccountId, orgId }: LinkIntuitAccountParams) => {
      await setIntuitAccount(currentOrgId || (orgId as string), fundingSourceId, {
        intuitAccountId,
      });
      await refreshFundingSources();
    },
    [setIntuitAccount, refreshFundingSources, currentOrgId]
  );

  const isIntuitAccountLinking = isSettingAccount || isFundingSourcesRefreshing;

  return { linkIntuitAccount, isIntuitAccountLinking };
};

export default useLinkIntuitAccount;
