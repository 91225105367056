import { Box, IconType, SmartIcon } from '@melio/billpay-design-system';
import { modalImageStyle } from './Modal.style';

type Props = {
  type: IconType;
  size?: `${number}rem`;
};

export const ModalIcon = ({ type, size }: Props) => (
  <Box __css={modalImageStyle}>
    <SmartIcon type={type} size={size} />
  </Box>
);
