import { useMemo, useState, useEffect } from 'react';
import curry from 'lodash/curry';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import { History } from 'history';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { CONSTS, DELIVERY_TYPE, PAYMENT_STATUS, TOKEN_ACTIONS } from 'src/app/utils/consts';
import {
  BankType,
  CheckType,
  EditableDeliveryMethodType,
  PaymentType,
  FieldType,
  OptionalDeliveryMethodsType,
  AddNewVirtualCardAccountType,
  OrganizationType,
  ReferrerType,
} from 'src/app/utils/types';
import { getJWTPayload } from 'src/app/helpers/jwt';
import vendorsLocations from 'src/app/pages/vendor/locations';
import { deliveryTypePredicate } from 'src/app/utils/delivery-methods';
import { getIsLoggedIn } from 'src/app/redux/user/selectors';
import profileStore from 'src/app/modules/profile/profile-store';
import { BANK_ACCOUNT_CHECKING_TYPE } from 'src/app/version-2/model/constants';
import {
  PaymentDeliveryStatusEnum,
  PaymentStatusEnum,
  VirtualCardStatusEnum,
} from 'src/app/version-2/model/enums';

type State = {
  deliveryType?: OptionalDeliveryMethodsType;
};

export type FilesUrls = {
  logoUrl: string;
  filePreviewUrls: string[];
  fileStorageUrl: string;
};

export type UnilateralPaymentState = {
  payment: PaymentType;
  organization: OrganizationType;
  filesUrls: FilesUrls;
  isPaymentLoading: boolean;
  isDeliveryMethodProcessing: boolean;
  updatingDeliveryMethodId?: string;
  virtualCardId?: string;
} & State;

export type UnilateralPaymentProps = {
  token: string;
};

export type UnilateralPaymentActions = {
  onChange: (updateField: FieldType) => void;
  onSubmit: (
    value: EditableDeliveryMethodType,
    isAddressVerified: boolean,
    bpVirtualCardOptInFeatureFlag: boolean
  ) => Promise<void>;
};

export type DeliveryMethodActionsType = {
  updateWithUnilateralToken: (params) => void;
  fetchUnilateralRequestDetails: (params) => void;
  replaceVirtualDeliveryMethod: (params) => void;
  validateAddress: (address) => void;
  shiftVirtualCardToACHPaymentStatusCollected: (params) => void;
};

const onChange = curry(
  (
    state: UnilateralPaymentState,
    setState: (state: UnilateralPaymentState) => void,
    { id, value }: FieldType
  ) => {
    setState({
      ...state,
      [id]: value,
    });
  }
);

const onSubmit = curry(
  async (
    props: UnilateralPaymentProps,
    state: UnilateralPaymentState,
    actions: DeliveryMethodActionsType,
    history: History,
    value: UnilateralDeliveryMethodType,
    isAddressVerified: boolean,
    bpVirtualCardOptInFeatureFlag: boolean
  ) => {
    const { token } = props;
    const { deliveryMethodId, actions: payloadActions } = getJWTPayload(token);
    const { payment, updatingDeliveryMethodId } = state;

    if (
      [DELIVERY_TYPE.ACH, DELIVERY_TYPE.VIRTUAL_CARD].includes(
        value.deliveryType as DELIVERY_TYPE
      ) ||
      isAddressVerified
    ) {
      if (bpVirtualCardOptInFeatureFlag && value.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
        history.push(generatePath(vendorsLocations.virtualCardDetails.optIn, { token }), {
          unilateralDeliveryMethod: value,
          deliveryMethodId,
          payment,
          referrer: ReferrerType.UNILATERAL,
        });
      } else {
        if (includes(payloadActions, TOKEN_ACTIONS.shiftVirtualCardToACH)) {
          try {
            await actions.shiftVirtualCardToACHPaymentStatusCollected(
              pickBy({
                token,
                deliveryMethod: value,
                paymentId: payment?.id || null,
                id: updatingDeliveryMethodId || value.id,
                vendorId: payment?.vendorId,
              })
            );

            history.push(generatePath(vendorsLocations.shiftVirtualCard.details, { token }));

            return;
          } catch (error) {
            history.push(
              generatePath(vendorsLocations.virtualCardDetails.details, {
                token,
                id: payment?.lastCreatedVirtualCard?.id,
              })
            );

            return;
          }
        }

        if (!!updatingDeliveryMethodId || value.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
          await actions.updateWithUnilateralToken(
            pickBy({
              token,
              deliveryMethod: value,
              paymentId: payment?.id || null,
              id: updatingDeliveryMethodId || value.id,
              vendorId: payment?.vendorId,
            })
          );
        } else {
          await actions.replaceVirtualDeliveryMethod(
            pickBy({
              token,
              deliveryMethod: {
                ...value,
                id: payment?.deliveryMethodId || deliveryMethodId,
              },
              paymentId: payment?.id || null,
            })
          );
        }

        history.push(generatePath(vendorsLocations.unilateral.success, { token }));
      }
    } else {
      const paperCheck = {
        ...value.paperCheck,
        deliveryType: CONSTS.DELIVERY_TYPE.CHECK,
        deliveryMethodId: payment?.deliveryMethodId || deliveryMethodId,
      };

      await actions.validateAddress(paperCheck);
    }
  }
);

export type UnilateralDeliveryMethodType = {
  id?: string | null;
  deliveryType?: OptionalDeliveryMethodsType;
  paperCheck: CheckType;
  bankAccount: BankType;
  virtualCardAccount?: AddNewVirtualCardAccountType;
};

export function useUnilateralPaymentState(
  props: UnilateralPaymentProps
): [
  UnilateralPaymentState,
  UnilateralPaymentActions,
  UnilateralDeliveryMethodType,
  EditableDeliveryMethodType,
  boolean
] {
  const { token } = props;
  const {
    paymentId,
    vendorId,
    orgId,
    deliveryMethodId,
    actions: payloadActions,
  } = getJWTPayload(token);
  const history = useHistory();
  const location = useLocation();
  const paymentActions = useStoreActions(paymentStore);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const [unilateralState, setState]: [State, any] = useState({});
  const isUnilateralWithPayment = !vendorId && !orgId && !deliveryMethodId && paymentId;

  const isShiftVirtualCard =
    includes(payloadActions, TOKEN_ACTIONS.shiftVirtualCardToACH) ||
    includes(payloadActions, TOKEN_ACTIONS.shiftVirtualCardToACHorCheck);

  useEffect(() => {
    (isUnilateralWithPayment || isShiftVirtualCard) &&
      paymentActions
        .fetchPaymentDetailsWithToken({
          token,
          action: 'unilateralPayment',
        })
        .catch(() => {
          history.push(generatePath(vendorsLocations.unilateral.invalidToken, { token }));
        });
  }, [isUnilateralWithPayment, paymentActions, token, history]);

  useEffect(() => {
    !isUnilateralWithPayment &&
      !isShiftVirtualCard &&
      deliveryMethodActions
        .fetchUnilateralRequestDetails({
          token,
          action: 'unilateralRequest',
        })
        .catch(() => {
          history.push(generatePath(vendorsLocations.unilateral.invalidToken, { token }));
        });
  }, [isUnilateralWithPayment, deliveryMethodActions, token, history]);

  const payment: any = useSelector(paymentStore.selectors.byId(paymentId));

  useEffect(() => {
    if (
      payment?.status === PAYMENT_STATUS.COMPLETED &&
      payment?.deliveryMethod?.deliveryType !== DELIVERY_TYPE.VIRTUAL_CARD
    ) {
      history.push(generatePath(vendorsLocations.unilateral.invalidToken, { token }));
    }
  }, [payment, history, token]);

  const deliveryMethod: any = useSelector(
    deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId || deliveryMethodId)
  );

  useEffect(() => {
    if (payment && deliveryMethod && isShiftVirtualCard) {
      if (
        includes(payloadActions, TOKEN_ACTIONS.shiftVirtualCardToACHorCheck) &&
        payment?.lastCreatedVirtualCard?.id
      ) {
        history.push(generatePath(vendorsLocations.shiftVirtualCard.notification, { token }));

        return;
      }

      const isVirtualCardDM = deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

      if (!isVirtualCardDM) {
        history.push(generatePath(vendorsLocations.shiftVirtualCard.details, { token }));

        return;
      }

      const virtualCard = payment?.lastCreatedVirtualCard;
      const isPaymentStatusCompleted = payment?.status === PaymentStatusEnum.COMPLETED;
      const isPaymentDeliverStatusCleared =
        payment?.deliverStatus === PaymentDeliveryStatusEnum.CLEARED ||
        payment?.deliverStatus === PaymentDeliveryStatusEnum.SETTLED;
      const isVirtualCardCleared = isPaymentStatusCompleted && isPaymentDeliverStatusCleared;
      const isVirtualCardCanceled = virtualCard?.status === VirtualCardStatusEnum.Cancelled;

      if (!isVirtualCardCanceled && !isVirtualCardCleared) {
        const indexLocation =
          isLoggedIn && organizations.length > 1
            ? vendorsLocations.unilateral.switchCompany
            : vendorsLocations.unilateral.addMethod;

        history.push(generatePath(indexLocation, { token }));

        return;
      }

      history.push(
        generatePath(vendorsLocations.virtualCardDetails.details, {
          token,
          id: virtualCard?.id,
        })
      );
    }
  }, [payment, isShiftVirtualCard, deliveryMethod]);

  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const organization: OrganizationType = useSelector(
    organizationStore.selectors.byId(payment?.organization?.id || orgId)
  );
  const { filesUrls } = useStructuredSelectors(paymentStore.selectors.payment(payment?.id));
  const { invalidTokenData, loading: isLoading } = useSelector(
    deliveryMethodsStore.selectors.validation
  ) as any;
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor: any = useSelector(fetchSelector.byId(payment?.vendor?.id || vendorId));
  const vendorDeliveryMethods = vendor?.deliveryMethods;
  const updatingDeliveryMethodId = vendor?.deliveryMethods?.find(
    deliveryTypePredicate(unilateralState.deliveryType)
  )?.id;
  const isDeliveryMethodUpdating = (
    useSelector(deliveryMethodsStore.selectors.update.status(updatingDeliveryMethodId)) as any
  )?.loading;

  useEffect(() => {
    const successPath = generatePath(vendorsLocations.unilateral.success, {
      token,
    });

    if (
      deliveryMethod &&
      deliveryMethod.deliveryType !== CONSTS.DELIVERY_TYPE.VIRTUAL &&
      deliveryMethod.deliveryType !== CONSTS.DELIVERY_TYPE.VIRTUAL_CARD &&
      location.pathname !== successPath &&
      !isShiftVirtualCard
    ) {
      history.push(successPath);
    }
  }, [deliveryMethod, token, history, location]);

  const deliveryMethodModel = useMemo(() => {
    let bankAccountModel = {
      accountType: BANK_ACCOUNT_CHECKING_TYPE as typeof BANK_ACCOUNT_CHECKING_TYPE,
      routingNumber: '',
      accountNumber: '',
    };
    const achDeliveryMethod = vendorDeliveryMethods?.find(
      deliveryTypePredicate(CONSTS.DELIVERY_TYPE.ACH)
    );

    if (achDeliveryMethod?.bankAccount) {
      bankAccountModel = merge(
        bankAccountModel,
        pick(achDeliveryMethod?.bankAccount, keys(bankAccountModel))
      );
    }

    let paperCheckModel = {
      printName: '',
      addressLine1: '',
      addressLine2: '',
      state: '',
      city: '',
      zipCode: '',
    };
    const checkDeliveryMethod = vendorDeliveryMethods?.find(
      deliveryTypePredicate(CONSTS.DELIVERY_TYPE.CHECK)
    );

    if (checkDeliveryMethod?.paperCheck) {
      paperCheckModel = merge(
        paperCheckModel,
        pick(checkDeliveryMethod?.paperCheck, keys(paperCheckModel))
      );
    }

    return {
      deliveryType: unilateralState.deliveryType,
      bankAccount: bankAccountModel,
      paperCheck: paperCheckModel,
      virtualCardAccount: { accountEmail: payment?.vendor?.contactEmail },
    };
  }, [unilateralState.deliveryType, vendorDeliveryMethods]);

  const enhancedState: UnilateralPaymentState = {
    ...unilateralState,
    payment,
    organization,
    filesUrls,
    isPaymentLoading,
    updatingDeliveryMethodId,
    isDeliveryMethodProcessing: isLoading || isDeliveryMethodUpdating,
  };

  useEffect(() => {
    if (!isEmpty(invalidTokenData)) {
      history.push(generatePath(vendorsLocations.unilateral.invalidToken, { token }));
    }
  }, [invalidTokenData, token, history]);

  useEffect(() => {
    if (includes(payloadActions, TOKEN_ACTIONS.shiftVirtualCardToACH)) {
      setState({
        ...enhancedState,
        deliveryType: 'ach',
      });
    }
  }, []);

  const actions = {
    onChange: onChange(enhancedState, setState),
    onSubmit: onSubmit(props, enhancedState, deliveryMethodActions, history),
  };

  return [enhancedState, actions, deliveryMethodModel, deliveryMethod, isShiftVirtualCard];
}
