import React, { ReactNode, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import isString from 'lodash/isString';
import analytics from 'src/app/services/analytics';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import {
  ButtonContainer,
  ContentContainer,
  DockContainer,
  DescriptionContainer,
  FooterContainer,
  CloseButtonWrapper,
  ModalContentContainer,
  Icon,
  ModalContainer,
  ModalWrapper,
  SubTitleContainer,
  TitleContainer,
  Illustration,
  Backdrop,
} from './ModalMessage.styles';

type Props = {
  id?: string;
  image?: string | ReactNode;
  icon?: string;
  titleComponent?: ReactNode;
  contentComponent?: ReactNode;
  buttonComponent?: ReactNode;
  buttonLabel?: string;
  analyticsProperties?: Record<string, any>;
  onButtonClick?: () => any;
  secondaryButtonComponent?: ReactNode;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  onCloseClick?: (event: MouseEvent) => void;
  isLoading?: boolean;
  alignLeft?: boolean;
  descriptionComponent?: ReactNode;
  footerSubTitle?: ReactNode;
  contentSubTitle?: ReactNode;
  footerComponent?: ReactNode;
  fullPrimaryButtonWidth?: boolean;
  isMobileFullSize?: boolean;
  largeSize?: boolean;
  dockedFooter?: boolean;
  isButtonDisabled?: boolean;
  withoutBackdrop?: boolean;
  className?: string;
  withTitleContainer?: boolean;
  withContentContainer?: boolean;
  unsetModalOverflow?: boolean;
  footerContainerCustomStyles?: Record<string, any>;
  shouldDisplayButtonComponent?: boolean;
};

export const ModalMessage = ({
  id,
  image = '',
  titleComponent,
  contentComponent,
  buttonLabel,
  analyticsProperties,
  isMobileFullSize = true,
  onButtonClick,
  onCloseClick,
  isLoading,
  descriptionComponent,
  footerSubTitle,
  contentSubTitle = '',
  footerComponent,
  buttonComponent,
  alignLeft,
  secondaryButtonComponent,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  largeSize,
  icon,
  dockedFooter,
  className,
  isButtonDisabled,
  withoutBackdrop,
  withTitleContainer = true,
  withContentContainer = true,
  unsetModalOverflow = false,
  footerContainerCustomStyles,
  shouldDisplayButtonComponent = true,
  fullPrimaryButtonWidth = true,
  ...rest
}: Props) => {
  const doOnClose = (event) => {
    onCloseClick && onCloseClick(event);
    analytics.trackAction(`modal-close${id ? `-${id}` : ''}`);
  };

  const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

  const PrimaryButtonComponent = () =>
    buttonLabel &&
    onButtonClick && (
      <ButtonContainer>
        <MIButton
          onClick={onButtonClick}
          label={buttonLabel}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          analyticsProperties={analyticsProperties}
          isProcessing={isLoading}
          disabled={isButtonDisabled}
          fullWidth={fullPrimaryButtonWidth}
        />
      </ButtonContainer>
    );

  return ReactDOM.createPortal(
    <ModalWrapper data-testid={id} className={className} onClick={stopPropagation} {...rest}>
      {withoutBackdrop || <Backdrop onClick={doOnClose} />}
      <ModalContainer
        largeSize={largeSize}
        isMobileFullSize={isMobileFullSize}
        unsetModalOverflow={unsetModalOverflow}
        data-testid="modal-message-container"
        className="modal-message-container"
      >
        <ModalContentContainer className="modal-content-container">
          {onCloseClick && (
            <CloseButtonWrapper onClick={doOnClose} data-testid="modal-close">
              <i className="icon-close-icon" />
            </CloseButtonWrapper>
          )}

          {image && (isString(image) ? <Illustration src={image} /> : image)}

          {icon && <Icon src={icon} />}

          {titleComponent && withTitleContainer ? (
            <TitleContainer alignLeft={alignLeft}>{titleComponent}</TitleContainer>
          ) : (
            titleComponent
          )}

          {descriptionComponent && (
            <DescriptionContainer>{descriptionComponent}</DescriptionContainer>
          )}

          {contentSubTitle && <SubTitleContainer>{contentSubTitle}</SubTitleContainer>}

          {contentComponent && withContentContainer ? (
            <ContentContainer
              className="modal-inner-content"
              shouldDisplayButtonComponent={shouldDisplayButtonComponent}
            >
              {contentComponent}
            </ContentContainer>
          ) : (
            contentComponent
          )}

          {footerSubTitle && <SubTitleContainer>{footerSubTitle}</SubTitleContainer>}

          {!dockedFooter && footerComponent && (
            <FooterContainer footerContainerCustomStyles={footerContainerCustomStyles}>
              {footerComponent}
            </FooterContainer>
          )}

          {shouldDisplayButtonComponent && (buttonComponent || PrimaryButtonComponent())}

          {secondaryButtonComponent ||
            (secondaryButtonLabel && onSecondaryButtonClick && (
              <ButtonContainer>
                <MIButton
                  onClick={onSecondaryButtonClick}
                  label={secondaryButtonLabel}
                  variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                  analyticsProperties={analyticsProperties}
                  isProcessing={isLoading}
                  fullWidth
                />
              </ButtonContainer>
            ))}
        </ModalContentContainer>
        {dockedFooter && footerComponent && <DockContainer>{footerComponent}</DockContainer>}
      </ModalContainer>
    </ModalWrapper>,
    document.querySelector('body') as any
  );
};
