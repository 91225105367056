enum PAYMENT_ANALYTICS_STATUSES {
  SCHEDULED = 'scheduled',
  DELIVERED = 'delivered',
  FAILED = 'failed',
  PAID = 'paid',
  DEPOSITED = 'deposited',
  CLEARED = 'cleared',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
}

enum TIMELINE_STEP_STATUSES {
  SUCCEED = 'succeed',
  FAILED = 'failed',
  FUTURE_STEP = 'future-step',
}

enum EXPEDITE_OPTIONS {
  TO_ACH = 'shift-to-ach',
  TO_PUSH_TO_DEBIT = 'push-to-debit',
  TO_VIRTUAL_CARD = 'virtual-card',
  FROM_ACH = 'from-ach',
  FROM_CHECK = 'from-check',
  TO_PUSH_TO_FAST_ACH = 'push-to-fast-ach',
  TO_PUSH_TO_RTP = 'push-to-rtp',
}

enum TIMELINE_STEP_STAGES {
  SCHEDULE = 'schedule',
  DEDUCTION = 'deduction',
  DELIVERY = 'delivery',
  CLEAR = 'clear',
}

enum FORM_TYPES {
  EDIT_EMAIL = 'edit-email',
  EDIT_BANK_DETAILS = 'edit-ach',
  EDIT_CHECK_ADDRESS = 'edit-address',
}

enum SUPPORT_ACTIONS {
  CONTACT_SUPPORT = 'contact-support',
}

const TIMELINE_STEP_ACTIONS = {
  CONTACT_SUPPORT: SUPPORT_ACTIONS.CONTACT_SUPPORT,
  EDIT_BANK_DETAILS: FORM_TYPES.EDIT_BANK_DETAILS,
  EDIT_CHECK_ADDRESS: FORM_TYPES.EDIT_CHECK_ADDRESS,
  EDIT_EMAIL: FORM_TYPES.EDIT_EMAIL,
  TO_ACH: EXPEDITE_OPTIONS.TO_ACH,
  TO_PUSH_TO_DEBIT: EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT,
  TO_PUSH_TO_FAST_ACH: EXPEDITE_OPTIONS.TO_PUSH_TO_FAST_ACH,
  TO_PUSH_TO_RTP: EXPEDITE_OPTIONS.TO_PUSH_TO_RTP,
};

const VENDOR_PAYMENT_DETAILS_COLORS = {
  SUCCEED_TEXT_COLOR: 'gray.500',
  FUTURE_STATUS_COLOR: 'gray.700',
  SUCCEED_STATUS_COLOR: 'green.200',
  FAILED_STATUS_COLOR: 'red.200',
};

const DESKTOP_WIDTH = '53rem';
const PADDING_SIZE = '4rem';
const MOBILE_PADDING_SIZE = '1rem';
const MOBILE_SECTION_PADDING_SIZE = '2rem';

const TIMELINE_CONTENT_TRANSLATION_ROOT_PATH = 'vendors.vendorPaymentDetails.content';
const TIMELINE_STEP_TRANSLATION_ROOT_PATH = `${TIMELINE_CONTENT_TRANSLATION_ROOT_PATH}.steps`;
const TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH = `${TIMELINE_CONTENT_TRANSLATION_ROOT_PATH}.actions`;

const ANALYTICS_VENDOR_CLARITY_PAGE_NAME = 'PaymentStatusForVendorPage';

export {
  PAYMENT_ANALYTICS_STATUSES,
  TIMELINE_STEP_STATUSES,
  TIMELINE_STEP_STAGES,
  FORM_TYPES,
  EXPEDITE_OPTIONS,
  SUPPORT_ACTIONS,
  TIMELINE_STEP_ACTIONS,
  VENDOR_PAYMENT_DETAILS_COLORS,
  DESKTOP_WIDTH,
  PADDING_SIZE,
  MOBILE_PADDING_SIZE,
  MOBILE_SECTION_PADDING_SIZE,
  TIMELINE_CONTENT_TRANSLATION_ROOT_PATH,
  TIMELINE_STEP_TRANSLATION_ROOT_PATH,
  TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH,
  ANALYTICS_VENDOR_CLARITY_PAGE_NAME,
};
