import { loadAchDeliveryMethodDetailsPageDataHandler } from './sagas/loadAchDeliveryMethodDetailsPageData.saga';
import { submitAchDeliveryMethodHandler } from './sagas/submitAchDeliveryMethod.saga';
import { achDeliveryMethodDetailsActions } from './achDeliveryMethodDetails.slice';

import { takeLatest, takeLeading } from 'redux-saga/effects';

export function* watchAchDeliveryMethodDetails() {
  yield takeLeading(
    achDeliveryMethodDetailsActions.submitAchDeliveryMethod,
    submitAchDeliveryMethodHandler
  );
  yield takeLatest(
    achDeliveryMethodDetailsActions.loadAchDeliveryMethodDetailsPageData,
    loadAchDeliveryMethodDetailsPageDataHandler
  );
}
