import { generatePath } from 'react-router-dom';
import { featureFlags } from '@melio/shared-web';
import shiftToDebitLocations from 'src/app/pages/vendor/shift-vendor-to-debit/locations';
import { upgradePaymentLocations as locations } from 'src/app/pages/vendor/upgrade-payment/locations';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from 'src/app/pages/vendor/vendor-payment-tracking/locations';
import {
  DeliveryEnum,
  FeatureFlagsEnum,
  PaymentStatusEnum,
  VendorDeliveryPreferenceEnum,
} from 'src/app/version-2/model/enums';
import { GetPaymentUpsellDetailsResponse } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/responses/GetPaymentUpsellDetailsResponse';
import { UpsellType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upsellType.enum';

import { History } from 'history';

interface NavigateOnPaymentUpsellDetailsLoadedParams
  extends Pick<GetPaymentUpsellDetailsResponse, 'payment' | 'upsellItems'> {
  history: History;
  token: string;
}

export const navigateOnPaymentUpsellDetailsLoaded = ({
  payment,
  upsellItems,
  history,
  token,
}: NavigateOnPaymentUpsellDetailsLoadedParams) => {
  if (
    [PaymentStatusEnum.FAILED, PaymentStatusEnum.COMPLETED].includes(
      payment.status as PaymentStatusEnum
    )
  ) {
    redirectToOfferExpiredPage(history, token);

    return;
  }

  if (payment.deliveryMethod?.deliveryType === DeliveryEnum.CARD) {
    redirectToDebitMethodPage(history, token);

    return;
  }

  if (
    payment.deliveryMethod?.deliveryType === DeliveryEnum.ACH &&
    payment.deliveryPreference === VendorDeliveryPreferenceEnum.EXPEDITED_ACH
  ) {
    redirectToSuccessPage(history, token, UpsellType.PUSH_TO_FAST_ACH);

    return;
  }

  if (
    payment.deliveryMethod?.deliveryType === DeliveryEnum.ACH &&
    payment.deliveryPreference === VendorDeliveryPreferenceEnum.RTP
  ) {
    redirectToSuccessPage(history, token, UpsellType.PUSH_TO_RTP);

    return;
  }

  const isP2DEligible = upsellItems.find(
    ({ type }) => type === UpsellType.PUSH_TO_DEBIT
  )?.isEligible;
  const isP2FACHEligible = upsellItems.find(
    ({ type }) => type === UpsellType.PUSH_TO_FAST_ACH
  )?.isEligible;
  const isP2RTPEligible = upsellItems.find(
    ({ type }) => type === UpsellType.PUSH_TO_RTP
  )?.isEligible;

  const isUpgradePaymentToFastAchEnabled = featureFlags.defaultClient.getVariantNoTrack<boolean>(
    FeatureFlagsEnum.UPGRADE_PAYMENT_TO_FACH,
    false
  );
  const isUpgradePaymentToRtpEnabled = featureFlags.defaultClient.getVariantNoTrack<boolean>(
    FeatureFlagsEnum.UPGRADE_PAYMENT_TO_RTP,
    false
  );

  if (isUpgradePaymentToRtpEnabled && isP2RTPEligible) {
    redirectToRtpMethodPage(history, token);

    return;
  }

  if (isUpgradePaymentToFastAchEnabled && isP2DEligible && isP2FACHEligible) {
    redirectToSelectMethodPage(history, token);

    return;
  }

  if (isUpgradePaymentToFastAchEnabled && isP2FACHEligible) {
    redirectToFastAchMethodPage(history, token);

    return;
  }

  if (isUpgradePaymentToFastAchEnabled && isP2DEligible) {
    redirectToDebitMethodPage(history, token);

    return;
  }

  if (isP2DEligible) {
    redirectToDebitMethodPage(history, token);

    return;
  }

  redirectToOfferExpiredPage(history, token);
};

const redirectToSelectMethodPage = (history: History, token: string) => {
  history.replace(generatePath(locations.selectMethod, { token }));
};

const redirectToFastAchMethodPage = (history: History, token: string) => {
  history.replace(generatePath(locations.fastAchMethod, { token }));
};

const redirectToRtpMethodPage = (history: History, token: string) => {
  history.replace(generatePath(locations.rtpMethod, { token }));
};

const redirectToDebitMethodPage = (history: History, token: string) => {
  history.replace(generatePath(shiftToDebitLocations.index, { token }));
};

export const redirectToSuccessPage = (
  history: History,
  token: string,
  selectedUpsellType: UpsellType
) => {
  history.replace(generatePath(locations.success, { token }), {
    selectedUpsellType,
  });
};

export const redirectToOfferExpiredPage = (
  history: History,
  token: string,
  expirationReason?: string
) => {
  history.replace(generatePath(locations.offerExpired, { token }), { expirationReason });
};

export const navigateOnTrackPaymentPage = (history: History, token: string) => {
  history.replace(generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token }));
};
