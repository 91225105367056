import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.4rem;
`;

export const HeaderContentContainer = styled.div<{ isMobile: boolean }>`
  grid-gap: ${({ isMobile }) => (isMobile ? '0.8rem' : '0.4rem')};
  width: 100%;
  margin: 2rem 0;
`;

export const MobileFeeInfoText = styled(Box)`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const ImagePlaceholder = styled.div`
  height: 25rem;
  width: 100%;
  border-radius: 1rem;
`;

export const CheckListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
  & > div:last-child {
    margin-bottom: 0;
  }
`;
