import React from 'react';
import { RecordOf } from 'immutable';
import {
  BILL_STATUS,
  DELIVERY_TYPE,
  TAG_VARIANT,
  HOLIDAYS_WARNING_ICON_VARIANT,
} from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import styled from 'styled-components';
import { isSameDay, DeliveryDate } from 'src/app/utils/dates';
import MITag from 'src/app/components/common/MITag';
import { BillType, DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import { isFastDeliveryType } from 'src/app/utils/delivery-methods';
import { getCheckDepositedDate } from 'src/app/utils/payments';
import {
  PaymentActivityMainContainer,
  PaymentInfoContainer,
  PaymentIcon,
  PaymentActivityContainer,
  PaymentTextContainer,
  PaymentMethodText,
  PaymentInfoText,
} from 'src/app/components/layout/PaymentActivityElements';
import { HolidaysWarning } from 'src/app/components/common/HolidaysWarning';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { featureFlags } from '@melio/shared-web';
import DeliveryDateInfoHint from './DeliveryDateInfoHint';
import CheckDeposited from './CheckDeposited';

type Props = {
  payment: RecordOf<PaymentType>;
  bill: RecordOf<BillType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  fundingSource: FundingSource;
};

const ViewBillPaymentActivityDeliveryDateInfo = ({
  payment,
  bill,
  deliveryMethod,
  fundingSource,
}: Props) => {
  const [showHolidaysWarningLD] = featureFlags.useFeature('us-holidays-checks');
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const isSameDayDelivery = isSameDay(payment.deliveryEta);
  const isFast = isFastDeliveryType(payment.deliveryPreference);
  const label = isBillPaid
    ? 'viewBillPaymentActivity.deliveryDate.paidBillDeliveryEtaLabel'
    : 'viewBillPaymentActivity.deliveryDate.deliveryEtaLabel';
  let checkDepositedDate;

  if (isBillPaid && deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK) {
    checkDepositedDate = getCheckDepositedDate(payment.transactions);
  }

  const showHolidaysWarning =
    showHolidaysWarningLD && !isFast && deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK;

  return (
    <>
      <PaymentActivityContainer data-testid="view-payment-activity-delivery-date">
        <PaymentActivityMainContainer>
          <PaymentInfoContainer>
            <PaymentIcon isBillPaid={isBillPaid}>
              <i className="icon-eta-cal" />
            </PaymentIcon>
            <PaymentTextContainer>
              <PaymentMethodText>
                <MIFormattedText label={label} />
              </PaymentMethodText>
              <PaymentInfoText isBillPaid={isBillPaid}>
                {isSameDayDelivery && (
                  <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />
                )}
                <DeliveryDate date={payment.deliveryEta} maxDate={payment.maxDeliveryEta} />
                {showHolidaysWarning && (
                  <HolidaysWarning
                    customIconStyles={{ position: 'relative', top: '0.1rem' }}
                    variant={HOLIDAYS_WARNING_ICON_VARIANT.WARNING}
                  />
                )}
                {isFast && (
                  <FastTag
                    label="bills.pay.date.deliveryOptions.price.fastTag"
                    variant={TAG_VARIANT.BRAND}
                  />
                )}
              </PaymentInfoText>
              {showHolidaysWarning && (
                <HolidaysWarningText>
                  <MIFormattedText label="holidaysWarning.tooltipLabels.scheduled" />
                </HolidaysWarningText>
              )}
            </PaymentTextContainer>
          </PaymentInfoContainer>
        </PaymentActivityMainContainer>
        {!isBillPaid && (
          <DeliveryDateInfoHint
            payment={payment}
            deliveryMethod={deliveryMethod}
            fundingSource={fundingSource}
          />
        )}
      </PaymentActivityContainer>
      {checkDepositedDate && <CheckDeposited date={checkDepositedDate} />}
    </>
  );
};

export default ViewBillPaymentActivityDeliveryDateInfo;

const FastTag = styled(MITag)`
  margin-left: 0.6rem;
  vertical-align: middle;
  font-size: 0.8rem;
  padding: 0 0.4rem;
  position: relative;
  top: -2px;
`;

const HolidaysWarningText = styled.span`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: rgba(153, 153, 156, 1);
`;
