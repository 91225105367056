import React from 'react';
import { useSelector } from 'react-redux';
import { Badge, Tooltip } from '@melio/billpay-design-system';

import { UNPAID_BILL_DASHBOARD_STASUSES } from 'src/app/pages/qb-dashboard/consts';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { format } from 'date-fns';
import { DATE_FORMATS } from 'src/app/utils/date-fns';
import { PaymentType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const QBOUnpaidStatusCell = ({ item }: Props) => {
  const { statusInDashboard } = item.metadata;
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(item.paymentId));

  if (payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED) {
    return (
      <Tooltip
        label={
          <MIFormattedText
            label="paymentDashboard.columns.unpaidStatus.declinedDate"
            values={{
              date: format(
                new Date(payment.approvalDecisionDate),
                DATE_FORMATS.monthShortWithLongDateAndYearWithHours
              ),
            }}
          />
        }
      >
        <Badge variant="declined">
          <MIFormattedText label="paymentDashboard.columns.unpaidStatus.notApproved" />
        </Badge>
      </Tooltip>
    );
  }

  if (statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.FAILED) {
    return (
      <Badge variant="error">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.failed" />
      </Badge>
    );
  }

  if (statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.REFUNDED) {
    return (
      <Badge variant="primary">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.refund" />
      </Badge>
    );
  }

  if (statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.OVERDUE) {
    return (
      <Badge variant="errorOutlined">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.overdue" />
      </Badge>
    );
  }

  if (statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.PARTIALLY_PAID) {
    return (
      <Badge variant="gray">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.partiallyPaid" />
      </Badge>
    );
  }

  if (statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.DUE_IN_ONE_WEEK) {
    return (
      <Badge variant="warning">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.dueInOneWeek" />
      </Badge>
    );
  }

  if (statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.OPEN) {
    return (
      <Badge variant="lightGray">
        <MIFormattedText label="paymentDashboard.columns.unpaidStatus.dueLater" />
      </Badge>
    );
  }

  return null;
};

export default QBOUnpaidStatusCell;
