import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import toLower from 'lodash/toLower';
import { RecordOf } from 'immutable';
import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { getOrgId } from 'src/app/redux/user/selectors';
import locations from 'src/app/utils/locations';
import { BillType, NavigateType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import { getFundingSourceLabel } from 'src/app/utils/bills';
import PayBillPaymentReview from 'src/app/pages/bill/pay/components/PayBillPaymentReview';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { calculateAmountWithFees } from 'src/app/utils/fee';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import { AreaLoader } from '@melio/billpay-design-system';
import failedToLoad from 'src/app/images/qbo/failed-to-load-002-icon.png';
import { userActions } from 'src/app/version-2/modules/user/user.slice';
import { getConfirmDeliveryMethod } from 'src/app/version-2/utils/deliveryMethod.utils';
import analytics from 'src/app/services/analytics';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { NotificationCheckFee } from 'src/app/version-2/components/CheckFee/NotificationCheckFee';
import {
  isPaymentExceededQBCashBalance,
  isReturnedCheckPayment,
  isUndepositedPayment,
} from 'src/app/utils/payments';
import { useHistoryBack } from 'src/app/pages/bill/pay/hooks/useHistoryBack';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { isFastDeliveryType, isLateDeliveryType } from 'src/app/utils/delivery-methods';
import { DeliveryEnum, FundingSourceOrigins } from 'src/app/version-2/model/enums';
import { PaymentExceedsAvailableBalanceModal } from './PaymentExceedsAvailableBalanceModal';
import { useGoEditPage } from '../hooks/navigation/useGoEditPage';

type Props = {
  onSubmit: (payment: RecordOf<PaymentType>) => void;
  onPrev: () => void;
  goExit: () => void;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  selectedFundingSource: FundingSource;
  isLoading?: boolean;
  isRecurring: boolean;
  recurringBill: Record<string, any>;
  errorCode: string | null;
  fee: PaymentFeeInfo | null;
  headerLabel: string;
  title: string;
  titleValues?: Record<string, any>;
  relativeStep?: number;
  buttonLabel: string;
  qbCashState?: QBCashStateType;
  navigate: NavigateType;
};

const PayBillConfirmPage = ({
  onSubmit,
  payment,
  isLoading,
  bill,
  goExit,
  onPrev,
  errorCode,
  isRecurring,
  recurringBill,
  fee,
  selectedFundingSource,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  buttonLabel,
  qbCashState,
  navigate,
}: Props) => {
  const { goEditDeliveryMethod, goEditDate, goEditNote, goEditFundingSource, goEditPartialAmount } =
    useGoEditPage({
      navigate,
    });
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const paymentDeliveryETA = payment?.deliveryEta;

  const analyticsProps = {
    billId: bill?.id,
    paymentId: payment?.id,
    fundingSourceId: selectedFundingSource?.id,
    isFast: isFastDeliveryType(payment?.deliveryPreference),
    isLate: isLateDeliveryType({
      paymentDeliveryETA,
      bills: payment?.bills?.length ? payment.bills : [bill],
    }),
    deliveryEta: paymentDeliveryETA,
  };

  const [isQBCashWarningDialog, setIsQBCashWarningDialog] = useState(false);

  useEffect(() => {
    analytics.trackRoute('page.view-confirm', analyticsProps);
  }, []);

  const handleOnSubmit = () => {
    onSubmit(payment);

    dispatch(
      userActions.increaseCheckFeePaymentCount({
        deliveryMethod: payment.deliveryMethod as any,
        fundingSource: selectedFundingSource as any,
        deliveryPreference: payment.deliveryPreference,
      })
    );
  };

  useHistoryBack({
    path: locations.Bills.pay.confirm.url({ id: bill.id, orgId }),
  });

  const handleQBCashOnSubmit = () => {
    analytics.track('pay-bill-confirm', 'confirm-and-schedule-payment', analyticsProps);

    if (
      isPaymentExceededQBCashBalance({ payment, qbCashState, fundingSource: selectedFundingSource })
    ) {
      onSubmitQBCash();
    } else {
      handleOnSubmit();
    }
  };

  const onSubmitQBCash = () => {
    analytics.trackAction('qbcash-low-balance-confirm-open');
    setIsQBCashWarningDialog(true);
  };

  const onCloseQBCashModal = () => {
    analytics.trackAction('qbcash-low-balance-confirm-close');
    setIsQBCashWarningDialog(false);
  };

  const onSubmitAnyway = () => {
    onSubmit(payment);

    analytics.trackAction('qbcash-low-balance-schedule-anyway');
    setIsQBCashWarningDialog(false);
  };

  const handleOnQBCashEditFundingSource = () => {
    analytics.trackAction('qbcash-low-balance-edit-funding-source');
    goEditFundingSource();
  };

  const amount = payment?.amount ?? bill.balance;
  const amountWithFee = calculateAmountWithFees(amount, fee);

  const deliveryMethod = getConfirmDeliveryMethod({
    bill: bill as any,
    payment,
    type: DeliveryEnum.ACH,
  });
  const selectedDeliveryMethod = bill.getDeliveryMethodById(deliveryMethod?.id as string);
  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={amount?.toString() as string} />
      </strong>
    ),
    companyName: <strong>{bill.vendor.companyName}</strong>,
  };

  if (errorCode) {
    analytics.track('bill-error', 'payment-cannot-be-approved');

    return (
      <ErrorPage
        illustration={failedToLoad}
        title="bills.pay.confirm.error.approvingPaymentTitle"
        subtitle="bills.pay.confirm.error.approvingPaymentSubtitle"
        buttonAction={goExit}
        buttonLabel="bills.pay.confirm.error.approvingPaymentCTA"
      />
    );
  }

  if (!bill.id) {
    return <AreaLoader />;
  }

  const activityActions = {
    onEditDate: goEditDate,
    onEditDeliveryMethod: goEditDeliveryMethod,
    onEditFundingSource: goEditFundingSource,
    onEditNote: goEditNote,
    onEditPartialAmount: goEditPartialAmount,
  };

  const recurringBillData = isRecurring && !isEmpty(recurringBill) ? recurringBill : null;
  const billData = bill.merge({ recurringBill: recurringBillData as any });
  const showConfirmPageHeaderLabel =
    !isReturnedCheckPayment(payment) && !isUndepositedPayment(payment);

  return (
    <>
      {isQBCashWarningDialog && (
        <PaymentExceedsAvailableBalanceModal
          title="bills.pay.confirm.balanceConfirm.title"
          description="bills.pay.confirm.balanceConfirm.description"
          confirmText="bills.pay.confirm.balanceConfirm.confirm"
          cancelText="bills.pay.confirm.balanceConfirm.cancel"
          confirm={onSubmitAnyway}
          close={onCloseQBCashModal}
          edit={handleOnQBCashEditFundingSource}
        />
      )}
      <QBOLayoutPage
        headerLabel={showConfirmPageHeaderLabel ? headerLabel : ''}
        headerLabelValues={headerLabelValues}
        title={title}
        titleValues={titleValues}
        goExit={goExit}
        relativeStep={relativeStep}
        onPrev={onPrev}
        onNext={handleQBCashOnSubmit}
        isLoading={isLoading}
        innerSize={50}
        nextLabel={buttonLabel}
        ctaVariant={CONSTS.BUTTON_VARIANT.PAY}
        fullWidthCTA
      >
        <NotificationCheckFee
          deliveryMethod={selectedDeliveryMethod}
          fundingSource={selectedFundingSource}
          deliveryPreference={payment.deliveryPreference}
        />
        <PayBillPaymentReview
          bill={billData}
          fee={fee}
          fundingSource={selectedFundingSource}
          payment={payment}
          deliveryMethod={selectedDeliveryMethod}
          activityActions={activityActions}
          qbCashState={qbCashState}
        />
        <ConfirmAgreements>
          <MIFormattedText
            label="bills.pay.confirm.confirmTerms"
            values={{
              amount: <MIFormattedCurrency value={amountWithFee?.toString() as string} />,
              type: (
                <MIFormattedText label={getFundingSourceLabel(selectedFundingSource)}>
                  {!(selectedFundingSource.origin === FundingSourceOrigins.QBCASH) && toLower}
                </MIFormattedText>
              ),
              date: <MIFormattedDate date={payment.scheduledDate} />,
            }}
          />
        </ConfirmAgreements>
      </QBOLayoutPage>
    </>
  );
};

export default PayBillConfirmPage;
const ConfirmAgreements = styled.div`
  text-align: center;
  color: #393a3d;
  margin: 2rem 0;
  ${(props) => props.theme.text.fontType.hint};
`;
