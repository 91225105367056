import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import profileStore from 'src/app/modules/profile/profile-store';
import {
  canVerifyManualBankAccount,
  getModifyFundingSourceOptions,
  isBankAccountBlocked,
} from 'src/app/utils/funding-sources';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import intercomService from 'src/app/services/intercom';
import MILink from 'src/app/components/common/MILink';
import analytics from 'src/app/services/analytics';
import { getWizardFlow } from 'src/app/redux/payBillWizard/selectors';
import { getImages, getDescription, getAdditionDescription } from 'src/app/utils/bank-account';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { getPaymentMethodNameParts } from 'src/app/version-2/utils/paymentMethods.utils';
import { FundingSource } from 'src/app/version-2/model/dtos';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  onClick?: (event: any) => void;
  method?: FundingSource;
  isSelected: boolean;
  optionComponent: any;
  onVerifyClicked?: (id: number) => void;
  disabled: boolean;
  modifyActions?: Record<string, any>;
};

const openSupportChat = () => {
  analytics.track('qbo', 'support-open-start-chat');
  intercomService.show();
};

function getDescriptionValues(fundingSource, onVerifyClicked) {
  if (onVerifyClicked && canVerifyManualBankAccount(fundingSource)) {
    return {
      link: (
        <VerifyAccountLink
          testId={`verify-funding-source-${fundingSource.id}`}
          label="settings.paymentMethods.verifyAccountHint"
          onClick={(event) => {
            event.preventDefault();
            onVerifyClicked && onVerifyClicked(fundingSource.id);
            event.stopPropagation();
          }}
        />
      ),
    };
  }

  return {
    link: '',
    chatWithUs: (
      <ChatLink
        to={openSupportChat}
        label="settings.paymentMethods.microDepositsBlockedContactLabel"
      />
    ),
  };
}

export const AchPaymentMethod = ({
  onClick,
  method,
  isSelected,
  onVerifyClicked,
  optionComponent: OptionComponent,
  disabled,
  modifyActions,
}: Props) => {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const payBillWizardFlow = useSelector(getWizardFlow);

  if (!method) {
    const description = getAdditionDescription(payBillWizardFlow);

    return (
      <OptionComponent
        id="ach"
        key="ach-empty"
        label="onboarding.fundingSources.options.bank.label"
        description={description}
        icon="icon-bank-icon"
        onClick={onClick}
        isEmptyList
      />
    );
  }

  const { icon, imageSrc } = getImages(method, false);
  const description = getDescription(method, payBillWizardFlow);
  const descriptionValues = getDescriptionValues(method, onVerifyClicked);
  const actionOptions = getModifyFundingSourceOptions({
    permissions,
    modifyActions,
    isDisabled: disabled,
    origin: method.origin,
    isExpired: false,
    hasLabel: !!method.nickname,
  });

  return (
    <OptionComponent
      id={method.id}
      disabled={disabled}
      onTopDescription={method.nickname}
      label="bills.pay.fundingSource.achLabel"
      labelValues={getPaymentMethodNameParts({ fundingSource: method })}
      description={description}
      descriptionValues={descriptionValues}
      isSelected={isSelected}
      icon={icon}
      imageSrc={imageSrc}
      onClick={onClick}
      actionOptions={actionOptions}
    />
  );
};

const VerifyAccountLink = styled(MIInlineLink)`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
`;

const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.colors.primary.opaque};
  text-decoration: none;
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.normal};
  border: none;
  font-size: ${(props) => props.theme.text.size.linkHint};
`;

function AchPaymentSelectedMethod({ method }: { method: FundingSource }) {
  const { icon, imageSrc } = getImages(method);

  return (
    <SelectedMethod
      icon={icon}
      imageSrc={imageSrc}
      title="bills.form.paymentActivity.scheduledBill.scheduleMethodAch"
      label="bills.pay.fundingSource.achLabel"
      labelValues={getPaymentMethodNameParts({ fundingSource: method })}
    />
  );
}

AchPaymentMethod.methodType = FundingSourceTypesEnum.ACH;
AchPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherBank';
AchPaymentMethod.SelectedMethod = AchPaymentSelectedMethod;
AchPaymentMethod.isDisabled = isBankAccountBlocked;
AchPaymentMethod.showAddAnotherOption = true;
