import React, { useState, useRef, useEffect } from 'react';
import QBOSearchInput from 'src/app/components/qbo/QBOSearchInput';
import useDashboardListParams from 'src/app/pages/qb-dashboard/hooks/useDashboardListParams';

function SearchInput() {
  const [listParams, { setSearch }] = useDashboardListParams();
  const [searchValue, setSearchValue] = useState(listParams.filters.search || '');
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    clearTimeout(timer.current as any);
    timer.current = setTimeout(() => {
      setSearch({ ...listParams.filters, search: searchValue });
    }, 400);
  };

  useEffect(() => {
    setSearchValue(listParams.filters.search || '');
  }, [listParams.filters.search]);

  return (
    <div>
      <QBOSearchInput
        onSubmit={handleSearch}
        onChange={handleSearch}
        value={searchValue}
        placeholder="paymentDashboard.search.placeholder"
        testId="dashboard-search-field"
      />
    </div>
  );
}

export default SearchInput;
