import * as React from 'react';
import styled from 'styled-components';
import QBOMobileHeader from 'src/app/components/layout/QBOMobileHeader';

type Props = {
  children: React.ReactNode;
};

const MobileAppLayout = ({ children }: Props) => (
  <Container>
    <QBOMobileHeader />
    <Main>{children}</Main>
  </Container>
);

export default MobileAppLayout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Main = styled.div`
  position: relative;
  flex: 1;
`;
