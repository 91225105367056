import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Popover, SmartIcon } from '@melio/billpay-design-system';
import { CellContext } from '@tanstack/react-table';
import { analytics } from 'src/app/version-2/externals';
import {
  ADD_EMAIL_FOR_BULK_RECONCILIATION,
  BATCH_BULK_EVENT_PAGE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  AddVendorEmailPopoverText,
  AddVendorsEmailPopoverWrapper,
  ButtonWrapper,
  InfoIconTooltip,
  VendorCompanyName,
  VendorContainer,
} from './renderers.styles';

const VendorRenderer: FC<CellContext<BatchBulkItem, string>> = ({ row }) => {
  const { formatMessage } = useIntl();
  const { id, companyName, vendorId, totalBillIds, totalPartialBillIds, isVendorEmailMissing } =
    row.original;
  const dispatch = useDispatch();

  const openAddEmailModal = () => {
    dispatch(
      batchBulkActions.setAddEmailModalAsOpen({
        id,
      })
    );

    analytics.track(BATCH_BULK_EVENT_PAGE, ADD_EMAIL_FOR_BULK_RECONCILIATION, {
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
    });
  };

  const PopoverContent = () => (
    <Popover closeOnBlur placement="top" arrowColor="ds.white">
      <Popover.PopoverTrigger>
        <InfoIconTooltip $isOptional>
          <SmartIcon type="info" />
        </InfoIconTooltip>
      </Popover.PopoverTrigger>
      <Popover.PopoverContent>
        <AddVendorsEmailPopoverWrapper>
          <AddVendorEmailPopoverText>
            {formatMessage({ id: 'batchBulkPage.popovers.addVendorEmail.text' })}
          </AddVendorEmailPopoverText>
          <ButtonWrapper>
            <Button variant="primary" size="md" onClick={openAddEmailModal}>
              {formatMessage({ id: 'batchBulkPage.popovers.addVendorEmail.button' })}
            </Button>
          </ButtonWrapper>
        </AddVendorsEmailPopoverWrapper>
      </Popover.PopoverContent>
    </Popover>
  );

  return (
    <VendorContainer data-testid="vendor-cell">
      <VendorCompanyName>{companyName}</VendorCompanyName>
      {isVendorEmailMissing && <PopoverContent />}
    </VendorContainer>
  );
};

export { VendorRenderer };
