import styled, { css } from 'styled-components';
import { Loader } from '@melio/billpay-design-system';

export const FormContent = styled.div`
  width: 45rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  box-sizing: border-box;
  padding-right: 2rem;
  padding-bottom: 2rem;
`;

export const Row = styled.div<{ $fullWidth?: boolean; $flexStart?: boolean; mb?: string }>`
  margin-bottom: ${(p) => (p.mb ? p.mb : '4rem')};
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props) => props.$flexStart && `align-items: center;`};
  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.$fullWidth &&
    css`
      & > div {
        width: 100%;
      }
    `}

  #inlineDatePicker {
    padding-left: 0;
  }
`;

export const Column = styled.div`
  width: 48%;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: ${(props) => `0 0 5rem 5rem ${props.theme.colors.white.opaque}`};
  transition: 0.1s;
  z-index: 1;
`;

export const ErrorIcon = styled.i.attrs({
  className: 'icon-warning-icon',
})`
  font-size: 1.6rem;
  margin-right: 0.4rem;
`;
