import { put, takeEvery, all, call, select, getContext } from 'redux-saga/effects';
import { TakeableChannel } from 'redux-saga';
import { INIT_PAY_BILL_FLOW } from 'src/app/redux/payBillWizard/actionTypes';
import { userActions as version2UserAction } from 'src/app/version-2/modules/user/user.slice';
import { organizationActions } from 'src/app/version-2/modules/organization/organization.slice';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { OrganizationCreateOriginEnum } from 'src/app/version-2/model/enums';
import authApi from '../../services/api/auth';
import { fundingSourcesActions } from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import {
  batchBulkActions,
  batchBulkSelectors,
} from '../../version-2/pages/batch-bulk/modules/batchBulk.slice';
import financialAccountsApi from '../../services/api/financialAccounts';
import deliveryMethodsApi from '../../services/api/deliveryMethods';
import userPreferencesApi from '../../services/api/userPreferences';
import {
  LOAD_FUNDING_SOURCES,
  DELETE_FUNDING_SOURCE,
  UPDATE_FUNDING_SOURCE_LABEL,
  REMOVE_FUNDING_SOURCE_LABEL,
  LOAD_PROFILE,
  LOAD_DELIVERY_METHODS,
  LOAD_COMPANY_INFO,
  UPDATE_USER_PREFERENCE,
  CHECK_AND_INIT_USER,
  INIT_USER,
  VERIFY_FUNDING_SOURCE,
  CLEAR_USER_INFO,
  CLEAR_STATE,
  CLEAR_USER_INFO_FINISH,
} from './actionTypes';
import {
  loadFundingSourcesSuccessAction,
  loadFundingSourcesFailedAction,
  deleteFundingSourceSuccessAction,
  deleteFundingSourceFailedAction,
  updateFundingSourceLabelSuccessAction,
  updateFundingSourceLabelFailedAction,
  removeFundingSourceLabelSuccessAction,
  removeFundingSourceLabelFailedAction,
  loadProfileSuccessAction,
  loadProfileFailedAction,
  clearUserInfoAction,
  loadDeliveryMethodsSuccessAction,
  loadDeliveryMethodsFailedAction,
  loadCompanyInfoFailedAction,
  loadCompanyInfoSuccessAction,
  updateUserPreferenceSuccessAction,
  updateUserPreferenceFailedAction,
  setCompanyInfoAction,
  setProfileAction,
  setUserPreferencesAction,
  checkAndInitUserFinishAction,
  initUserSuccessAction,
  verifyFundingSourceSuccessAction,
  verifyFundingSourceFailedAction,
  loadFundingSourcesAction,
} from './actions';
import { flagsStore } from '../../modules/flags/flags-store';
import { AccountRecord, CompanyInfoRecord } from '../../pages/settings/records';
import { UserContextRecord, UserPreferencesRecord } from '../../context/records';
import { getOrgId, getOwnedVendorId, getCompanyInfo, getCanContactSupport } from './selectors';
import organizationApi from '../../services/api/organizations';
import * as featureFlagsService from '../../services/featureFlags';
import * as twoFactorAuthService from '../../services/twoFactorAuth';
import zendeskService from '../../services/intercom';
import {
  setOrganizationPreferencesAction,
  loadFeeCatalogSuccessAction,
  loadFeeCatalogFailedAction,
  initialSetFeeFundingSource,
} from '../organization/actions';
import { isManualBankAccountNotVerified } from '../../utils/funding-sources';
import { VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES } from '../../utils/consts';
import { DeliveryMethodRecord } from '../../pages/vendor/records-constants';
import { isCompanyEligibleForInternationalPayment } from '../utils';
import { setDeletedFundingSource } from '../payBillWizard/actions';

function* calculateBankAccountStatuses({ orgId, fundingSource }) {
  try {
    if (isManualBankAccountNotVerified(fundingSource)) {
      yield call(financialAccountsApi.getVerificationStatus, orgId, fundingSource.id);
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: true,
        isBlocked: false,
      };

      return fundingSource.merge({ bankAccount });
    }

    return fundingSource;
  } catch (e: any) {
    if (e.code === VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.NOT_FOUND) {
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: false,
        isBlocked: false,
      };

      return fundingSource.merge({ bankAccount });
    }

    if (
      e.code ===
      VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS
    ) {
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: false,
        isBlocked: true,
      };

      return fundingSource.merge({ bankAccount });
    }

    return fundingSource;
  }
}

function* loadFundingSources({ resolve = (...params) => {}, reject = (...params) => {} }) {
  const orgId = yield select(getOrgId);

  try {
    const { fundingSources } = yield call(financialAccountsApi.getFundingSources, orgId, {
      includeDeleted: true,
    });
    const fundingSourcesRecords = yield all(
      fundingSources
        .map((fundingSource) => AccountRecord(fundingSource))
        .map((fundingSource) => calculateBankAccountStatuses({ orgId, fundingSource }))
    );

    yield put(loadFundingSourcesSuccessAction(fundingSourcesRecords));
    resolve(fundingSourcesRecords);
  } catch (e) {
    yield put(loadFundingSourcesFailedAction());
    reject(e);
  }
}

function* loadProfile({ resolve = () => {}, reject = () => {} }) {
  try {
    const { user } = yield call(authApi.check);

    yield put(loadProfileSuccessAction(UserContextRecord(user)));
    resolve();
  } catch (e) {
    yield put(loadProfileFailedAction());
    yield put(clearUserInfoAction());
    reject();
  }
}

function* deleteFundingSource({ id, resolve, reject }) {
  const orgId = yield select(getOrgId);

  try {
    yield call(financialAccountsApi.deleteFundingSource, orgId, id);
    yield put(deleteFundingSourceSuccessAction(id));
    yield put(fundingSourcesActions.deleteFundingSourceSuccess(id));
    yield put(setDeletedFundingSource(id));
    const headerPaymentMethod = yield select(batchBulkSelectors.selectHeaderRepeatingPaymentMethod);

    if (headerPaymentMethod === id) {
      yield put(batchBulkActions.clearHeaderRepeatingPaymentMethod());
    }

    yield put(batchBulkActions.updateFundingSourceAfterDelete({ fundingSourceId: id }));

    resolve();
  } catch (e) {
    yield put(deleteFundingSourceFailedAction());
    reject(e);
  }
}

function* updateFundingSourceLabel({ id, nickname, resolve, reject }) {
  const orgId = yield select(getOrgId);

  try {
    const res = yield call(financialAccountsApi.updateFundingSourceLabel, orgId, id, { nickname });

    yield put(updateFundingSourceLabelSuccessAction(res.fundingSource));
    yield put(loadFundingSourcesAction());
    resolve();
  } catch (e) {
    yield put(updateFundingSourceLabelFailedAction());
    reject(e);
  }
}

function* removeFundingSourceLabel({ id, resolve, reject }) {
  const orgId = yield select(getOrgId);

  try {
    const res = yield call(financialAccountsApi.updateFundingSourceLabel, orgId, id, null);

    yield put(removeFundingSourceLabelSuccessAction(res.fundingSource));
    yield put(loadFundingSourcesAction());
    resolve();
  } catch (e) {
    yield put(removeFundingSourceLabelFailedAction());
    reject(e);
  }
}

function* verifyFundingSource({ id, token, amount1, amount2, resolve, reject }) {
  try {
    const orgId = yield select(getOrgId);
    const { verificationResult } = token
      ? yield call(financialAccountsApi.verifyMicroDepositsWithToken, {
          token,
          id,
          amount1,
          amount2,
        })
      : yield call(financialAccountsApi.verifyMicroDeposits, orgId, {
          id,
          amount1,
          amount2,
        });

    if (verificationResult) {
      yield put(verifyFundingSourceSuccessAction(id));
      resolve();
    } else {
      yield put(
        verifyFundingSourceFailedAction(
          id,
          VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.ERR_VERIFY_MICRO_DEPOSITS
        )
      );
      reject({
        code: VERIFY_FUNDING_SOURCE_MICRO_DEPOSITS_ERROR_CODES.ERR_VERIFY_MICRO_DEPOSITS,
      });
    }
  } catch (e: any) {
    yield put(verifyFundingSourceFailedAction(id, e ? e.code : ''));
    reject(e);
  }
}

function* loadDeliveryMethods({ resolve = () => {}, reject = () => {} }) {
  try {
    const orgId = yield select(getOrgId);
    const ownedVendorId = yield select(getOwnedVendorId);
    const { deliveryMethods } = yield call(
      deliveryMethodsApi.getDeliveryMethods,
      orgId,
      ownedVendorId
    );
    const deliveryMethodsRecords = deliveryMethods.map((deliveryMethod) =>
      DeliveryMethodRecord(deliveryMethod)
    );

    yield put(loadDeliveryMethodsSuccessAction(deliveryMethodsRecords));
    resolve();
  } catch (e) {
    yield put(loadDeliveryMethodsFailedAction());
    reject();
  }
}

function* loadFeeCatalog({ resolve = () => {}, reject = () => {} }) {
  try {
    const orgId = yield select(getOrgId);
    const { fees: feeCatalog } = yield organizationApi.getOrganizationFeeCatalog(orgId, {
      isBypassThrowError: true,
    });

    yield put(loadFeeCatalogSuccessAction(feeCatalog));
    yield put(organizationActions.setFeeCatalog(feeCatalog));
    resolve();
  } catch (e) {
    yield put(loadFeeCatalogFailedAction());
    reject();
  }
}

function* loadCompanyInfo({ resolve = (...params) => {}, reject = () => {} }) {
  try {
    const orgId = yield select(getOrgId);
    const { localCompanyInfo, organizationPreferences } = yield call(
      organizationApi.getCompanyInfo,
      orgId,
      true
    );
    const companyInfoRecord = CompanyInfoRecord(localCompanyInfo);

    try {
      const activeFeeFundingSource = yield call(
        organizationApi.fetchActiveFeeFundingSource,
        orgId,
        { isBypassThrowError: true }
      );

      yield put(initialSetFeeFundingSource(activeFeeFundingSource.organizationBillingFees[0]));
    } catch (e) {
      loggingApi.error(
        'loadCompanyInfo.fetchActiveFeeFundingSource(): Load active fee fundind sources failed',
        e
      );
    }

    const isEligibleForInternationalPayment =
      isCompanyEligibleForInternationalPayment(companyInfoRecord);

    yield put(
      setOrganizationPreferencesAction({
        ...organizationPreferences,
        isEligibleForInternationalPayment,
      })
    );
    yield put(loadCompanyInfoSuccessAction(companyInfoRecord));
    resolve(companyInfoRecord);
  } catch (e) {
    yield put(loadCompanyInfoFailedAction());
    reject();
  }
}

function* updateUserPreference({ id, value, resolve, reject }) {
  try {
    yield call(userPreferencesApi.updateUserPreference, id, value);
    yield put(updateUserPreferenceSuccessAction(id, value));
    resolve();
  } catch (e) {
    yield put(updateUserPreferenceFailedAction());
    reject(e);
  }
}

function* watchLoadCompanyInfo() {
  yield takeEvery(LOAD_COMPANY_INFO as unknown as TakeableChannel<unknown>, loadCompanyInfo);
}

function* watchDeleteFundingSource() {
  yield takeEvery(
    DELETE_FUNDING_SOURCE as unknown as TakeableChannel<unknown>,
    deleteFundingSource
  );
}

function* watchUpdateFundingSourceLabel() {
  yield takeEvery(
    UPDATE_FUNDING_SOURCE_LABEL as unknown as TakeableChannel<unknown>,
    updateFundingSourceLabel
  );
}

function* watchRemoveFundingSourceLabel() {
  yield takeEvery(
    REMOVE_FUNDING_SOURCE_LABEL as unknown as TakeableChannel<unknown>,
    removeFundingSourceLabel
  );
}

function* watchVerifyFundingSource() {
  yield takeEvery(
    VERIFY_FUNDING_SOURCE as unknown as TakeableChannel<unknown>,
    verifyFundingSource
  );
}

function* watchLoadFundingSources() {
  yield takeEvery(LOAD_FUNDING_SOURCES as unknown as TakeableChannel<unknown>, loadFundingSources);
}

function* watchLoadProfile() {
  yield takeEvery(LOAD_PROFILE as unknown as TakeableChannel<unknown>, loadProfile);
}

function* watchLoadDeliveryMethods() {
  yield takeEvery(
    LOAD_DELIVERY_METHODS as unknown as TakeableChannel<unknown>,
    loadDeliveryMethods
  );
}

function* watchUpdateUserPreferenceMethod() {
  yield takeEvery(
    UPDATE_USER_PREFERENCE as unknown as TakeableChannel<unknown>,
    updateUserPreference
  );
}

function* initializeSupport({ user, isLoggedInAs }) {
  const canContactSupport = yield select(getCanContactSupport);

  zendeskService.setChat({
    isLoggedInAs,
    user,
    canContactSupport,
  });
}

function* initUserContext({ user, isLoggedInAs = false, resolve, reject }) {
  try {
    yield put({ type: INIT_PAY_BILL_FLOW });
    yield put(initUserSuccessAction(isLoggedInAs));

    loggingApi.sessionContext = { userId: user?.id, orgId: user.orgId };

    yield all([
      put(setProfileAction(UserContextRecord(user), user.organizations)),
      put(setUserPreferencesAction(UserPreferencesRecord(user.userPreferences))),
    ]);

    yield all([
      put(version2UserAction.fetchUserProfileSuccess(user)),
      put(version2UserAction.fetchCompanyInfo()),
    ]);
    yield all([call(loadCompanyInfo, {}), call(loadFundingSources, {}), call(loadFeeCatalog, {})]);

    const [ownedVendorId, companyInfo] = yield all([
      select(getOwnedVendorId),
      select(getCompanyInfo),
    ]);

    yield call(initializeSupport, { user, isLoggedInAs });

    const intl = yield getContext('intl');

    intl.setDefaultValue('fees', companyInfo.billingSetting.fee);

    if (ownedVendorId) {
      yield call(loadDeliveryMethods, {});
    }

    yield put(initUserSuccessAction(isLoggedInAs));
    resolve({ companyInfo });
  } catch (e) {
    yield put(clearUserInfoAction());
    reject(e);
  }
}

function* clearUserContext() {
  yield put({ type: CLEAR_STATE });

  zendeskService.clearChat();
  yield put({ type: CLEAR_USER_INFO_FINISH });
}

function getCurrentOrgInfo(checkResult, orgId) {
  const orgToSelect = orgId || checkResult.orgId;

  let org = checkResult.organizations.find((org) => org.id === orgToSelect);

  if (!org) {
    [org] = checkResult.organizations;
  }

  return {
    orgId: org.id,
    orgName: org.companyName,
  };
}

function filterOrganizationsByOrigin(orgs) {
  const RESTRICTED_ORIGINS = [
    OrganizationCreateOriginEnum.QBDT_INTEGRATION,
    OrganizationCreateOriginEnum.QBDT_WINDOWS,
    OrganizationCreateOriginEnum.QBDT_MAC,
  ];

  return orgs.filter((o) => !RESTRICTED_ORIGINS.includes(o.createOrigin));
}

function parseUserData(orgId, sourceUser) {
  const organizations = filterOrganizationsByOrigin(sourceUser.organizations);
  const user = {
    ...sourceUser,
    organizations,
    ...getCurrentOrgInfo({ orgId: sourceUser.orgId, organizations }, orgId),
  };
  const organization = user.organizations.find((org) => org.id === user.orgId);
  const companyInfo = {
    id: organization.id,
    companyType: organization.companyType,
    kyb: organization.kyb,
    mql: organization.mql,
    organizationPreferences: organization.organizationPreferences,
  };

  return { user, companyInfo };
}

export function* checkAndInitUserInfo({
  orgId,
  realmId,
  skip2FACheck = true,
  resolve = () => {},
  reject = (...params) => {},
}) {
  try {
    const response = yield call(authApi.check);

    if (!response || !response.user) {
      yield put(clearUserInfoAction());
      reject();

      return;
    }

    const { user, companyInfo } = parseUserData(orgId, response.user);

    yield all([
      put(setProfileAction(UserContextRecord(user), user.organizations)),
      put(setUserPreferencesAction(UserPreferencesRecord(user.userPreferences))),
      put(setCompanyInfoAction(CompanyInfoRecord(companyInfo))),
    ]);

    yield call(
      featureFlagsService.identify,
      { id: user.id, email: user.email },
      {
        id: companyInfo.id,
        companyType: companyInfo.companyType,
        organizationPreferences: companyInfo.organizationPreferences,
      }
    );

    const site = yield getContext('site');

    const check2FACredentialsResult = yield call(twoFactorAuthService.check2FACredentials, {
      realmId,
      site,
      skip: skip2FACheck,
    });

    if (check2FACredentialsResult === twoFactorAuthService.Check2FACredentialsResult.Valid) {
      yield call(initUserContext, { user, isLoggedInAs: false, resolve, reject });
    } else if (
      check2FACredentialsResult === twoFactorAuthService.Check2FACredentialsResult.Invalid
    ) {
      yield call(initializeSupport, { user, isLoggedInAs: false });
      yield put(flagsStore.actions.set2FARequired(true));
    }

    yield put(checkAndInitUserFinishAction());
  } catch (e) {
    yield put(clearUserInfoAction());
    reject(e);
  }
}

function* watchInitUser() {
  yield takeEvery(INIT_USER as unknown as TakeableChannel<unknown>, initUserContext);
}

function* watchCheckAndInitUser() {
  yield takeEvery(CHECK_AND_INIT_USER as unknown as TakeableChannel<unknown>, checkAndInitUserInfo);
}

function* watchClearUserInfo() {
  yield takeEvery(CLEAR_USER_INFO as unknown as TakeableChannel<unknown>, clearUserContext);
}

export default function* payBillFlowSagas() {
  yield all([
    watchLoadFundingSources(),
    watchLoadProfile(),
    watchDeleteFundingSource(),
    watchUpdateFundingSourceLabel(),
    watchRemoveFundingSourceLabel(),
    watchLoadDeliveryMethods(),
    watchLoadCompanyInfo(),
    watchUpdateUserPreferenceMethod(),
    watchInitUser(),
    watchCheckAndInitUser(),
    watchVerifyFundingSource(),
    watchClearUserInfo(),
  ]);
}
