import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';

import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

export const LANDING_PAGE_SLICE = `${VERSION_2}landingPage`;

export const selectState = (state: any) => state[LANDING_PAGE_SLICE] as LandingPageSlice;

export interface LandingPageSlice {
  selectedPlan?: string;
  isNewLpEntry?: boolean;
}

export const getLandingPageInitialState = (): LandingPageSlice => ({
  selectedPlan: '',
  isNewLpEntry: false,
});

const slice = createSlice({
  name: LANDING_PAGE_SLICE,
  initialState: getLandingPageInitialState(),
  reducers: {
    setSelectedPlan: (state, action: PayloadAction<string>) => {
      state.selectedPlan = action.payload;
    },
    clearSelectedPlan: (state) => {
      state.selectedPlan = undefined;
    },
    setIsNewLpEntry: (state, action: PayloadAction<boolean>) => {
      state.isNewLpEntry = action.payload;
    },
  },
});

export const landingPageSelectors = {
  selectSelectedPlan: createSelector(selectState, (state) => state.selectedPlan),
  selectIsNewLpEntry: createSelector(selectState, (state) => state.isNewLpEntry),
};

export const landingPageActions = {
  ...slice.actions,
};

export const landingPageSlice = persistReducer(
  {
    key: 'landingPage',
    storage: storageSession,
  },
  slice.reducer
);
