import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { AchDeliveryMethodDetailsPage } from 'src/app/version-2/pages/ach-delivery-method-details';
import EditCheckNameDeliveryMethodPageContainer from './EditCheckNameDeliveryMethodPageContainer';
// eslint-disable-next-line max-len
import EditCheckAddressWithAutocompleteDeliveryMethodPageContainer from './EditCheckAddressWithAutocompleteDeliveryMethodPageContainer';

import InternationalDeliveryRouter from './international/router';
import { internationalLocations } from 'src/app/pages/vendor/delivery-methods/international/locations';
import VirtualCardDeliveryMethodPageContainer from './VirtualCardDeliveryMethodPageContainer';
import { getBillIdsAnalyticsParam } from 'src/app/version-2/pages/ach-delivery-method-details/utils/analytics.utils';

const eventPage = 'vendors';

export default [
  {
    path: internationalLocations.base,
    component: InternationalDeliveryRouter,
  },
  {
    path: locations.Vendors.deliveryMethods.virtual.create.url({
      id: ':id',
      orgId: ':orgId',
    }),
    exact: true,
    component: withSiteContext()((cProps) => {
      analytics.page(eventPage, 'add-virtual-delivery-method');

      return (
        <cProps.site.components.VirtualDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
        />
      );
    }),
  },

  {
    path: locations.Vendors.deliveryMethods.ach.create.url({
      id: ':id',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      const {
        redirectUrl,
        exitUrl,
        origin,
        preservedState = {},
        billId,
      } = cProps.location?.state || {};

      const { batchList, selectedPaymentIntent } = preservedState;

      const billIds = getBillIdsAnalyticsParam({
        billId,
        batchList,
        paymentIntentId: selectedPaymentIntent?.paymentIntentId,
      });

      const vendorId = cProps.match.params.id;

      analytics.page(eventPage, 'add-bank-delivery-method', {
        vendorId,
        billIds,
      });

      return (
        <AchDeliveryMethodDetailsPage
          vendorId={vendorId}
          flowState={{
            redirectUrl,
            exitUrl,
            origin,
            batchList,
            selectedPaymentIntent,
            analyticsParams: { billIds },
          }}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.ach.edit.url({
      id: ':id',
      deliveryMethodId: ':deliveryMethodId',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      const {
        redirectUrl,
        exitUrl,
        origin,
        preservedState = {},
        billId,
      } = cProps.location?.state || {};
      const { batchList, selectedPaymentIntent } = preservedState;

      const billIds = getBillIdsAnalyticsParam({
        billId,
        batchList,
        paymentIntentId: selectedPaymentIntent?.paymentIntentId,
      });

      const vendorId = cProps.match.params.id;

      analytics.page(eventPage, 'edit-bank-delivery-method', {
        vendorId,
        billIds,
      });

      return (
        <AchDeliveryMethodDetailsPage
          vendorId={vendorId}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
          flowState={{
            redirectUrl,
            exitUrl,
            origin,
            batchList,
            selectedPaymentIntent,
            analyticsParams: { billIds },
          }}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.check.create.url({
      id: ':id',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'add-check-delivery-method-name');

      return (
        <EditCheckNameDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['printName']}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.check.edit.url({
      id: ':id',
      deliveryMethodId: ':deliveryMethodId',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'edit-check-delivery-method-name');

      return (
        <EditCheckNameDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
          inputFields={['printName']}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.check.address.create.url({
      id: ':id',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'add-check-delivery-method-address');

      return (
        <EditCheckAddressWithAutocompleteDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['googlePlaceId']}
        />
      );
    },
  },

  {
    path: locations.Vendors.deliveryMethods.check.address.edit.url({
      id: ':id',
      deliveryMethodId: ':deliveryMethodId',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'edit-check-delivery-method-address');

      return (
        <EditCheckAddressWithAutocompleteDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['googlePlaceId']}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
        />
      );
    },
  },
  {
    path: locations.Vendors.deliveryMethods['virtual-card'].create.url({
      id: ':id',
      deliveryMethodId: ':deliveryMethodId',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps) => (
      <VirtualCardDeliveryMethodPageContainer {...cProps} vendorId={cProps.match.params.id} />
    ),
  },
];
