import React, { useState, useEffect, useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import MIButton from 'src/app/components/common/MIButton';
import styled from 'styled-components';
import { CONSTS } from 'src/app/utils/consts';
import { Box } from '@melio/billpay-design-system';
import { Header, ContentWrapper } from 'src/app/pages/vendor/components/VendorLayoutElements';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import NewDeliveryMethodForm from 'src/app/pages/vendor/components/NewDeliveryMethodForm';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import locations from 'src/app/pages/vendor/shift-vendor-to-debit/locations';
import { ModelView } from 'src/app/ui/form';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import QBOPushToDebitFooter from 'src/app/pages/vendor/components/QBOPushToDebitFooter';
import { SupportLine } from 'src/app/components/common/OutsideLayout/SupportLine';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import { getTransactionFee } from 'src/app/pages/bill/utils';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import { useSiteContext } from 'src/app/hoc/withSiteContext';

import { TabapayMigrationModal } from 'src/app/components/tabapay/TabapayMigrationModal';
import { ShiftToDebitState, ShiftToDebitDeliveryMethodType } from './hoc/shift-to-debit-hooks';
import useAddCardAccount from './hooks/useAddCardAccount';
import QBOWhitePageAddressContainer from '../virtual-delivery-method/components/QBOWhitePageAddressContainer';

type Props = {
  token: string;
  state: ShiftToDebitState;
  deliveryMethodMV: ModelView<ShiftToDebitDeliveryMethodType>;
  onValidateAddress: (event: Event) => void;
};

const AddAddressDetailsPage = ({ token, state, deliveryMethodMV, onValidateAddress }: Props) => {
  const history = useHistory();
  const { payment, organization } = state;
  const site = useSiteContext();

  const { onSubmit, onClearAddressState, isLoading, errorModal } = useAddCardAccount(
    token,
    payment,
    deliveryMethodMV
  );

  const [isWhitePageAddressViewEnabled, setIsWhitePageAddressViewEnabled] = useState(false);
  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId)
  );

  const goOnPrev = async () => {
    await onClearAddressState();
    history.push(generatePath(locations.addDebitMethod, { token }));
  };

  const handleWhitePageAddress = useCallback(() => {
    setIsWhitePageAddressViewEnabled(
      whitePageAddress &&
        !isEmpty(whitePageAddress) &&
        ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid)
    );

    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSubmit();
    }
  }, [whitePageAddress]);

  useEffect(handleWhitePageAddress, [handleWhitePageAddress]);

  if (isWhitePageAddressViewEnabled) {
    return (
      <QBOLayoutPage hideHeader contentWrapperMode="success">
        <Box textAlign="center" marginBottom={5} marginTop={10}>
          <QBLogo />
        </Box>
        <Content>
          {errorModal}
          <QBOWhitePageAddressContainer
            setWhitePageAddressView={setIsWhitePageAddressViewEnabled}
            isLoading={isAddressLoading || isAddressLoading || isLoading}
            whitePageAddress={whitePageAddress}
            submit={onSubmit}
            model={deliveryMethodMV.cardAccount}
          />
        </Content>
        <SupportLine __css={supportLineStyle} />
      </QBOLayoutPage>
    );
  }

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <Box textAlign="center" marginBottom={5} marginTop={10}>
        <QBLogo />
      </Box>
      <Content>
        {errorModal}
        <TabapayMigrationModal goExit={goOnPrev} />
        <Header
          payment={payment}
          organization={organization}
          onPrev={goOnPrev}
          showFullHeader
          subTitle="vendors.deliveryMethods.shiftToDebit.subtitle"
          hideInvoice
          hideLogo
        />
        <SeparatorContainer>
          <Separator />
        </SeparatorContainer>
        <ContentWrapper title="vendors.deliveryMethods.shiftToDebit.addressDetails.title">
          <NewDeliveryMethodForm
            submit={onValidateAddress}
            selectedDeliveryMethodType={CONSTS.DELIVERY_TYPE.CARD}
            cardModel={deliveryMethodMV.cardAccount}
          />
          <MIButton
            label="vendors.deliveryMethods.shiftToDebit.addressDetails.submit"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={onValidateAddress}
            isProcessing={isAddressLoading || isLoading}
            fullWidth
          />
          <InfoText>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftToDebit.infoWithFee"
              values={{
                termsOfService: (
                  <SupportLink href={site.config.agreementLinks.termsOfService} target="_blank">
                    <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.termsOfService" />
                  </SupportLink>
                ),
                privacyPolicy: (
                  <SupportLink href={site.config.agreementLinks.privacyPolicy} target="_blank">
                    <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.privacyPolicy" />
                  </SupportLink>
                ),
                fee: <MIFormattedCurrency value={getTransactionFee(payment?.amount as number)} />,
              }}
            />
          </InfoText>

          <QBOPushToDebitFooter companyName={organization?.companyName} />
        </ContentWrapper>
      </Content>
      <SupportLine __css={supportLineStyle} />
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #d4d7dc;
  box-sizing: border-box;
`;

const SeparatorContainer = styled.div`
  padding-right: 4rem;
  padding-left: 4rem;
  background: ${(props) => props.theme.colors.white.opaque};
`;

const Separator = styled.div`
  width: 100%;
  height: 0.4rem;
  background-color: ${(props) => props.theme.background.wizard};
`;

const SupportLink = styled.a`
  color: inherit;
  cursor: pointer;
`;
const InfoText = styled.div`
  ${({ theme }) => theme?.text?.fontType?.hint}
  text-align: left;
  color: ${(props) => props.theme.text.color.subtitle};
  margin: 0.8rem;
`;

const supportLineStyle = {
  m: ['2rem 0 5rem', '2rem 0'],
};

export default AddAddressDetailsPage;
