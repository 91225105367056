import React from 'react';
import analytics from 'src/app/services/analytics';
import SettingsMethodsListItem from 'src/app/components/common/SelectMethods/components/SettingsMethodsListItem';
import paymentMethodSVG from 'src/app/images/general/payment-method.svg';

const eventPage = 'settings-billing';

export const EmptyFundingSource = ({ actions }) => (
  <SettingsMethodsListItem
    imageSrc={paymentMethodSVG}
    id="empty-funding-source"
    label="settings.billing.feeCard.checks.cards.empty.label"
    description="settings.billing.feeCard.checks.cards.empty.description"
    actionOptions={[]}
    onClick={() => {
      analytics.track(eventPage, 'add-method-click');

      actions[0].action();
    }}
    hint=""
    origin="origin"
    isEmptyList
  />
);
