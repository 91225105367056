import React from 'react';
import styled from 'styled-components';
import { AreaLoader } from '@melio/billpay-design-system';
import successImage from 'src/app/images/general/qbo-check-circle.svg';
import { PAYMENT_STATUS } from 'src/app/utils/consts';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import { useEditCheckAddressStatus } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import ConvertedUnilateralPaymentActivity from 'src/app/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import { BillInfoContainer, BillPaymentBlock, QBLogo, Title, Subtitle } from './shared';

type Props = {
  token: string;
};

const CheckDepositedSuccessPage = ({ token }: Props) => {
  const { payment, isPaymentLoading, organization } = useEditCheckAddressStatus({ token });
  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  if (isPaymentLoading || payment?.status !== PAYMENT_STATUS.COMPLETED) {
    return <AreaLoader />;
  }

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <BillPaymentBlock>
        <BillInfoContainer>
          <DoneIcon src={successImage} />
          <Title data-testid="success-title">
            <MIFormattedText label="vendors.editCheckAddress.checkDeposited.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.editCheckAddress.checkDeposited.subtitle"
              values={{
                totalAmount: <MIFormattedCurrency value={bill.totalAmount} />,
                companyName: organization?.companyName,
                invoiceNumber: bill.invoiceNumber,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
      </BillPaymentBlock>
    </QBOLayoutPage>
  );
};

export default CheckDepositedSuccessPage;

const DoneIcon = styled.img`
  height: 4.8rem;
  width: 4.8rem;
  margin-bottom: 2rem;
`;
