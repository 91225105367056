import { useEffect } from 'react';
import analytics from 'src/app/services/analytics';
import { DeliveryMethodConfig, VisibilityOptions } from './useDeliveryMethodListConfig';

type Props = {
  visibilityOptions: VisibilityOptions;
  virtualConfig: DeliveryMethodConfig;
  vitrualCardConfig: DeliveryMethodConfig;
  internationalConfig: DeliveryMethodConfig;
  achConfig: DeliveryMethodConfig;
};

const useDeliveryMethodVisibilityAnalytics = ({
  visibilityOptions,
  virtualConfig,
  vitrualCardConfig,
  internationalConfig,
  achConfig,
}: Props) => {
  const { showAllDMs, showInternationalDM, showDomesticDM } = visibilityOptions;

  const isACHOnly = (showAllDMs || showDomesticDM) && achConfig.required && achConfig.only;
  const isVCOnly =
    (showAllDMs || showDomesticDM) && vitrualCardConfig.required && vitrualCardConfig.only;

  const isSingleMethodShown = isACHOnly || isVCOnly;
  const isDomestic = showAllDMs || showDomesticDM;
  const isInternational = showAllDMs || showInternationalDM;

  useEffect(() => {
    const required = isDomestic && virtualConfig.required;

    analytics.track('pay-bill', 'dm-unilateral-option-shown', {
      shown: required && !isSingleMethodShown,
    });
  }, [virtualConfig.required, isDomestic, isSingleMethodShown]);

  useEffect(() => {
    const required =
      isInternational && internationalConfig.required && !internationalConfig.disabled;

    analytics.track('pay-bill', 'dm-international-option-shown', {
      shown: required && !isSingleMethodShown,
    });
  }, [
    internationalConfig.required,
    internationalConfig.disabled,
    isInternational,
    isSingleMethodShown,
  ]);

  useEffect(() => {
    const required = isDomestic && vitrualCardConfig.required && !vitrualCardConfig.disabled;

    analytics.track('pay-bill', 'dm-virtual-card-option-shown', {
      shown: required && !isACHOnly,
    });
  }, [vitrualCardConfig.required, vitrualCardConfig.disabled, isDomestic, isACHOnly]);
};

export { useDeliveryMethodVisibilityAnalytics };
