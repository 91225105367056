import React from 'react';
import cookies from 'js-cookie';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import get from 'lodash/get';
import { generatePath } from 'react-router-dom';
import { GlobalState } from 'src/app/redux/types';
import pagesLocations from 'src/app/pages/locations';
import {
  getProfile,
  getCompanyInfo,
  getUserPreferences,
  getFundingSources,
} from 'src/app/redux/user/selectors';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import {
  UserContextType,
  NavigateType,
  OrgPreferencesTypeKeys,
  UserPreferencesType,
  CompanyInfoType,
} from 'src/app/utils/types';
import { getStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { updateOrganizationPreferenceAction } from 'src/app/redux/organization/actions';
import intuit from 'src/app/utils/intuit';
import { withNavigator } from 'src/app/hoc';
import { checkAndInitUserAction, updateUserPreferenceAction } from 'src/app/redux/user/actions';
import {
  ENTRYPOINT_SOURCE,
  FLOW_ORIGIN,
  FUNDING_SOURCE_ORIGIN,
  NEW_LANDING_PAGE_ACTIONS,
} from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import globalLocations from 'src/app/pages/locations';
import entrypointsLocations from 'src/app/pages/quickbooks/locations';
import authApi from 'src/app/services/api/auth';
import clientServiceApi, { MeasurementLabel } from 'src/app/services/api/clientService';
import analytics from 'src/app/services/analytics';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { melioClose } from 'src/app/utils/external-events';
import { UpdateUserPreferenceParams } from 'src/app/modules/users/hooks/useUpdateUserPreferences';
import { flagsStore } from 'src/app/modules/flags/flags-store';
import dashboardLocations from 'src/app/pages/qb-dashboard/locations';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { Loader } from '@melio/billpay-design-system';
import { encodeQuery } from 'src/app/utils/query-utils';
import { QBOLandingPage } from './landing-pages/QBOLandingPage';
import {
  FeatureFlagsEnum,
  EntrypointEventPageEnum,
  EntrypointActionEnum,
  RegistrationOriginEnum,
  DbAnalyticsTraitsEnum,
  FundingSourceOrigins,
} from 'src/app/version-2/model/enums';

import { PlansLandingPage } from 'src/app/version-2/pages/landing-pages/components';
import { landingPageActions } from 'src/app/version-2/pages/landing-pages/modules/landingPage.slice';
import { setAuthToken } from 'src/app/services/api/api';
import { MELIO_FTU } from 'src/app/version-2/model/constants/risk-data-collecting';
import {
  getFeatureFlagStatus,
  getLandingPageFeatureFlagStatus,
} from 'src/app/version-2/utils/featureFlags.utils';
import { FundingSource } from 'src/app/version-2/model/dtos';

type MapDispatchToProps = {
  checkAndInitUser: (params?: { orgId?: number }) => Promise<void>;
  syncQBCashFundingSource: (params?: { orgId?: string }) => Promise<void>;
  updateQBDashboardSwitcherValue: (params: { orgId: string; value: boolean }) => Promise<void>;
  updateUserPreference: (params: UpdateUserPreferenceParams) => Promise<void>;
  setDashboardWasUsedBefore: (wasUsedBefore: boolean) => void;
  setJustPayInitialParams: ({ flowOrigin }) => void;
  setFullOrgSyncRequired: () => Promise<void>;
  clearNewLandingPageForUser: () => void;
};

type MapStateToProps = {
  profile: RecordOf<UserContextType>;
  fundingSources: FundingSource[];
  companyInfo: CompanyInfoType;
  organizationPreferences: any;
  is2FAStarted: boolean;
  is2FARequired: boolean;
  userPreferences: UserPreferencesType;
};

type Props = {
  realmId: string;
  txnId: string;
  txnIds: string;
  vendorId: number;
  paymentId: number;
  currentSwitcherValue: boolean;
  source?: ENTRYPOINT_SOURCE;
  isByVendorId: boolean;
  action: EntrypointActionEnum;
  navigate: NavigateType;
  getUrlAfterFundingSourceStepForRecoveryFlow: () => Promise<string>;
  match: any;
  query: {
    isAfterRegister?: boolean;
    billcom?: string;
    authToken?: string;
    token?: string;
    siteConfig?: string;
    createdOrganization?: string;
    mdFundingSourceId?: string;
    billId?: string;
    billIds?: string;
    paymentId?: string;
    paymentIds?: string;
  };
  site: {
    name: string;
    registrationOrigin: string;
    config: Record<string, any>;
    skipQBOFTUScreen: boolean;
    defaultIntent: string;
    check2fa: boolean;
  };
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  newUser: boolean;
  isLoading: boolean;
};

async function measureLoadTime(route) {
  try {
    if (performance.timing) {
      const measurements = [
        {
          label: MeasurementLabel.PAGE_LOAD_INTERACTIVE,
          value: performance.timing.domInteractive - performance.timing.connectStart,
        },
        {
          label: MeasurementLabel.PAGE_LOAD_COMPLETE,
          value: performance.timing.domComplete - performance.timing.connectStart,
        },
        {
          label: MeasurementLabel.PAGE_LOAD_TTFB,
          value: performance.timing.responseStart - performance.timing.requestStart,
        },
      ];

      clientServiceApi.measure(measurements, route);
    }
  } catch (e) {
    loggingApi.error('EntrypointContainer.measureLoadTime(): could not measure load times', e);
  }
}

const ALLOWED_ACTIONS_AUTH_LOADING_OVERLAY = [
  EntrypointActionEnum.Pay,
  EntrypointActionEnum.JustPay,
];

class EntrypointContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newUser: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { match, query, clearNewLandingPageForUser } = this.props;
    const authToken = query.token || query.authToken;

    clearNewLandingPageForUser();

    let handled = this.handleOpenFromBackPress();

    if (handled) return;

    measureLoadTime(match.path);

    if (authToken) {
      setAuthToken(authToken);
    }

    handled = this.handleErrorCheck();

    if (handled) return;

    handled = this.handleDeviceCheck();

    if (handled) return;

    this.handleQBOCheck();
  }

  getEventPage = () =>
    this.props.action === EntrypointActionEnum.JustPay
      ? EntrypointEventPageEnum.JustPay
      : EntrypointEventPageEnum.PayBill;

  handleErrorCheck = () => {
    const { navigate, realmId } = this.props;
    const plansLandingPageEnabled = getLandingPageFeatureFlagStatus({ realmId });
    const isFundingSourceExperiment = getFeatureFlagStatus(
      FeatureFlagsEnum.FUNDING_SOURCE_EXPERIMENT
    );

    if (JSON.parse(get(this.props, 'query.billcom', false))) {
      intuit.endLoadingWrapper();
      navigate(locations.QuickBooks.errorPage.url({ source: 'billcom' }));

      return true;
    }

    if (this.isLocalStorageUnvailable()) {
      intuit.endLoadingWrapper();
      navigate(locations.QuickBooks.incognitoErrorPage.url());

      return true;
    }

    return false;
  };

  handleOpenFromBackPress = () => {
    // `true` for is2FAStarted means that the 2FA challenge flow has started but not finished.
    // Facing it here means that the user has pressed the back button and expects to return to Intuit.
    const { is2FAStarted } = this.props;

    if (is2FAStarted) {
      melioClose();

      return true;
    }

    return false;
  };

  handleDeviceCheck = () => {
    const { is2FARequired, navigate } = this.props;

    if (is2FARequired) {
      intuit.endLoadingWrapper();
      navigate(locations.Auth.authCodeVerification.url(), false);

      return true;
    }

    return false;
  };

  handleQBOCheck = () => {
    const {
      query,
      navigate,
      realmId,
      action,
      txnId,
      txnIds,
      checkAndInitUser,
      site,
      vendorId,
      isByVendorId,
      paymentId,
      source,
    } = this.props;

    const plansLandingPageEnabled = getLandingPageFeatureFlagStatus({ realmId });
    const isFundingSourceExperiment = getFeatureFlagStatus(
      FeatureFlagsEnum.FUNDING_SOURCE_EXPERIMENT
    );

    const { isAfterRegister } = query;
    const connectQuery = intuit.buildConnectQuery(this.getIntuitOptions());

    authApi.qboCheck(realmId, connectQuery).then(async ({ status, orgId, authToken }) => {
      if (authToken) {
        setAuthToken(authToken);
      }

      if (status === 'matched') {
        await checkAndInitUser({ orgId });

        if (!this.props.profile.isEmailVerified) {
          intuit.endLoadingWrapper();
          navigate(
            `${pagesLocations.codeVerification}?redirect=${
              window.location.pathname + window.location.search
            }`
          );

          return;
        }

        if (isAfterRegister) {
          this.onAfterRegister(orgId);
        }

        const {
          userPreferences,
          setDashboardWasUsedBefore,
          updateQBDashboardSwitcherValue,
          currentSwitcherValue,
          updateUserPreference,
          setJustPayInitialParams,
          setFullOrgSyncRequired,
        } = this.props;

        if (query.createdOrganization === 'true') {
          setFullOrgSyncRequired();
        }

        setDashboardWasUsedBefore(!!userPreferences.qbDashboardSeen);

        await Promise.all([
          this.syncQBCash(orgId),
          updateQBDashboardSwitcherValue({ orgId, value: currentSwitcherValue }),
        ]);

        if (action === EntrypointActionEnum.JustPay) {
          const isOrganizationCreated = query.createdOrganization === 'true';
          const isOnboardingScreenSeen = isAfterRegister || isOrganizationCreated;

          if (isOnboardingScreenSeen) {
            await updateUserPreference({ key: 'isJustPayIntuitEntrypointAware', value: true });
          }

          setJustPayInitialParams({
            flowOrigin: FLOW_ORIGIN.INTUIT_ENTRYPOINT,
          });
        }

        let redirectUrl;

        if (action === EntrypointActionEnum.AddCard) {
          navigate(locations.Onboarding.fundingSources.card.index.url({ orgId }), false, {
            redirectUrl: dashboardLocations.dashboard,
            exitUrl: dashboardLocations.dashboard,
          });

          return;
        }

        if (action === EntrypointActionEnum.Pay) {
          if (plansLandingPageEnabled || isFundingSourceExperiment) {
            redirectUrl = locations.QuickBooks.actions.payNewLandingPageExperiment.url({
              id: txnId,
              txnId,
              isByVendorId,
              paymentId,
              ...query,
            });
          } else {
            redirectUrl = generatePath(entrypointsLocations.loaders.pay, {
              originId: txnId,
              orgId,
            });
          }
        } else if (action === EntrypointActionEnum.Dashboard) {
          const dashboardSearchParams = {
            billId: query.billId,
            billIds: query.billIds,
            paymentId: query.paymentId,
            paymentIds: query.paymentIds,
            mdFundingSourceId: query.mdFundingSourceId,
          };
          const dashboardSearchPath = encodeQuery(dashboardSearchParams, [], '');

          redirectUrl = `${entrypointsLocations.loaders.dashboard}${
            dashboardSearchPath === '' ? dashboardSearchPath : `?${dashboardSearchPath}`
          }`;
        } else {
          redirectUrl = locations.QuickBooks.actions[action].url({
            id: txnId,
            txnIds,
            vendorId,
            isByVendorId,
            paymentId,
            ...query,
          });
        }

        navigate(redirectUrl, false, { source });
      } else if (status === 'known' || status === 'missingScope') {
        setTimeout(() => {
          this.connectToQuickBooks();
        }, 0);
      } else if (status === 'reject') {
        navigate(globalLocations.entrypoints.errors.orgBlockedByRisk);
      } else {
        const shouldShowLoadingOverlay =
          ALLOWED_ACTIONS_AUTH_LOADING_OVERLAY.includes(action) && !site.skipQBOFTUScreen;

        if (shouldShowLoadingOverlay) {
          intuit.initFTULoadingWrapper();
        }

        this.setState({ newUser: true });

        if (site.skipQBOFTUScreen) {
          this.onRegister();
        }
      }
    });
  };

  onAfterRegister = async (orgId: string) => {
    const { profile, site } = this.props;

    analytics.alias(profile.id);
    analytics.identify(profile);
    analytics.setTraits({
      [DbAnalyticsTraitsEnum.LINK_ACCOUNTING_SOFTWARE]: true,
    });
    analytics.triggerCampaignEvents();

    await authApi.updateUserMarketingDetails({
      marketingTam: cookies.get('trackingTam'),
      marketingUtms: cookies.get('utms'),
    });

    if (
      site.registrationOrigin === RegistrationOriginEnum.QUICKBOOKS_ROCKETMAN_IOS ||
      site.registrationOrigin === RegistrationOriginEnum.QUICKBOOKS_ROCKETMAN_ANDROID
    ) {
      await this.syncQBCash(orgId);
    }
  };

  onRegister = (plan?: string) => {
    const { realmId } = this.props;
    const plansLandingPageEnabled = getLandingPageFeatureFlagStatus({ realmId });

    this.setState({ isLoading: true });
    let eventProps = {};
    const eventPage = this.getEventPage();

    if (plan && plansLandingPageEnabled) {
      eventProps = {
        fundingSourceType: plan,
      };
    }

    try {
      window.sessionStorage.setItem(MELIO_FTU, 'true');
    } catch (error) {
      loggingApi.error('EntryPointContainer.onRegister(): sessionStorage', {
        error,
      });
    }

    analytics.track(eventPage, 'onboarding-continue', eventProps);
    this.connectToQuickBooks();
  };

  getIntuitOptions = () => {
    const { realmId, site } = this.props;

    return {
      site,
      quickbooksChooseRealmId: realmId,
      registrationOrigin: site.registrationOrigin,
      intuitReturnUrl: window.location.pathname + window.location.search,
      intuitErrorReturnUrl: locations.QuickBooks.errorConnect.url(),
      embeddedAppcenter: true,
      intent: site.defaultIntent,
    };
  };

  connectToQuickBooks = () => {
    intuit.goConnectToIntuit(this.getIntuitOptions());
  };

  isLocalStorageUnvailable = () => {
    try {
      localStorage.setItem('test', 'test');

      return false;
    } catch (e) {
      return true;
    }
  };

  syncQBCash = async (orgId) => {
    const { syncQBCashFundingSource, fundingSources, action } = this.props;
    const isQBcashFundingSourcePresent = fundingSources.some(
      (fs) => fs.origin === FundingSourceOrigins.QBCASH
    );
    const canSyncQBCashInBackground =
      isQBcashFundingSourcePresent || action === EntrypointActionEnum.Dashboard;

    if (canSyncQBCashInBackground) {
      syncQBCashFundingSource({ orgId });

      return;
    }

    await syncQBCashFundingSource({ orgId });
  };

  onLandingPageRegister = (plan?: string) => {
    const { action } = this.props;

    if (ALLOWED_ACTIONS_AUTH_LOADING_OVERLAY.includes(action)) {
      intuit.showFTULoadingWrapper(action);
    }

    this.onRegister(plan);
  };

  render() {
    const { site, realmId, txnId, txnIds, action } = this.props;
    const { newUser, isLoading } = this.state;
    const plansLandingPageEnabled = getLandingPageFeatureFlagStatus({ realmId });

    if (!newUser || site.skipQBOFTUScreen) {
      return <Loader />;
    }

    if (plansLandingPageEnabled && NEW_LANDING_PAGE_ACTIONS[action]) {
      analytics.page(EntrypointEventPageEnum.PayBill, 'onboarding-screen', {
        realmId,
        txnId,
        txnIds,
        experimentName: 'landing-page-and-funding-source',
        experimentVariant: 'cards-plan-page',
      });

      return (
        <PlansLandingPage
          onRegister={this.onLandingPageRegister}
          isLoading={isLoading}
          realmId={realmId}
          txnId={txnId}
          txnIds={txnIds}
          action={action}
        />
      );
    }

    if (plansLandingPageEnabled && NEW_LANDING_PAGE_ACTIONS[action]) {
      analytics.page(EntrypointEventPageEnum.PayBill, 'onboarding-screen', {
        realmId,
        txnId,
        txnIds,
        experimentName: 'landing-page-and-funding-source',
        experimentVariant: 'cards-plan-page',
      });

      return (
        <PlansLandingPage
          onRegister={this.onLandingPageRegister}
          isLoading={isLoading}
          realmId={realmId}
          txnId={txnId}
          txnIds={txnIds}
          action={action}
        />
      );
    }

    return (
      <QBOLandingPage
        onRegister={this.onLandingPageRegister}
        isLoading={isLoading}
        realmId={realmId}
        txnId={txnId}
        txnIds={txnIds}
        action={action}
      />
    );
  }
}

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  checkAndInitUser(params) {
    return new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction(params, resolve, reject));
    });
  },
  syncQBCashFundingSource(params) {
    return dispatch(fundingSourcesStore.actions.syncQBCash(params));
  },
  updateQBDashboardSwitcherValue({ orgId, value }) {
    return new Promise((resolve, reject) =>
      dispatch(
        updateOrganizationPreferenceAction(
          orgId,
          OrgPreferencesTypeKeys.qbDashboardSwitcherChecked,
          String(value),
          resolve,
          reject
        )
      )
    );
  },
  setDashboardWasUsedBefore(wasUsedBefore) {
    const actions = getStoreActions(qbDashboardListItemsStore)(dispatch);

    actions.usageStatus.setWasUsedBefore(wasUsedBefore);
  },
  updateUserPreference({ key, value }) {
    return new Promise((resolve, reject) =>
      dispatch(updateUserPreferenceAction(key, String(value), resolve, reject))
    );
  },
  setJustPayInitialParams({ flowOrigin }) {
    const paymentStoreActions = getStoreActions(paymentStore)(dispatch);

    paymentStoreActions.justPay.justPayWizard.update({
      flowOrigin,
    });
  },
  setFullOrgSyncRequired() {
    return dispatch(flagsStore.actions.setFullOrgSyncRequired(true));
  },
  clearNewLandingPageForUser() {
    dispatch(landingPageActions.setIsNewLpEntry(false));
  },
});

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
  fundingSources: getFundingSources(state),
  organizationPreferences: getOrganizationPreferences(state),
  userPreferences: getUserPreferences(state),
  companyInfo: getCompanyInfo(state),
  is2FAStarted: state['flags'].is2FAStarted,
  is2FARequired: state['flags'].is2FARequired,
});

export default compose(
  withNavigator(),
  withSiteContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(EntrypointContainer);
