import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { SmartIcon, Text } from '@melio/billpay-design-system';
import {
  PaymentMethodButtonContainer,
  PaymentMethodButtonLabel,
} from 'src/app/version-2/pages/batch-bulk/renderers/PaymentMethod/PaymentMethod.renderer.styles';
import { ExpandCollapseMenuIcon } from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';

export interface PaymentMethodChooseButtonProps {
  isMenuOpen: boolean;
}

export const PaymentMethodChooseButton: FC<PaymentMethodChooseButtonProps> = ({ isMenuOpen }) => {
  const { formatMessage } = useIntl();

  return (
    <PaymentMethodButtonContainer>
      <PaymentMethodButtonLabel>
        <Text textStyle="ds.body3">
          {formatMessage({ id: 'batchBulkPage.renderers.chooseMethod' })}
        </Text>
      </PaymentMethodButtonLabel>
      <ExpandCollapseMenuIcon $isOpen={isMenuOpen}>
        <SmartIcon type="arrow-dropdown" />
      </ExpandCollapseMenuIcon>
    </PaymentMethodButtonContainer>
  );
};
