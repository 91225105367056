import React from 'react';
import { RecordOf } from 'immutable';
import { DeliveryMethodType, PaymentType, VendorType } from 'src/app/utils/types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CONSTS } from 'src/app/utils/consts';
import batchBillsStore from 'src/app/modules/batch-bills/batch-bills-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import paymentsStore from 'src/app/modules/payments/payment-store';

import SelectedMethod from '../components/SelectedMethod';

type Props = {
  method: RecordOf<DeliveryMethodType>;
  onClick: () => void;
  showDropdownSelectedMethod: boolean;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
};
const MAX_EMAIL_LENGTH = 25;

function useVirtualDeliveryEmail() {
  const history = useHistory();

  const query = new URLSearchParams(history.location.search).get('currentId');
  const batchPayments: PaymentType[] = useSelector(
    batchBillsStore.selectors.paymentByBillId(query)
  );
  const batchPaymentDM: DeliveryMethodType = useSelector(
    paymentsStore.selectors.getBatchPaymentsByBillId(query)
  ); // it means that user didn't save changes on SidePanel and select different bill

  const vendorId = batchPayments?.[0]?.vendorId;
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(vendorId));

  const vendorNewVirtualDeliveryMethod: DeliveryMethodType =
    batchPaymentDM ||
    vendor?.deliveryMethods?.find((dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL);
  const deliveryMethods: DeliveryMethodType[] = useSelector(
    batchBillsStore.selectors.deliveryMethodsByBillId(query as unknown as number)
  );

  const vendorUpdatedVirtualPayment = batchPayments.find(
    (dm) => dm?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL
  );
  const vendorDeliveryMethod = deliveryMethods.find(
    (dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL
  );
  const emailVirtualAccount =
    vendorNewVirtualDeliveryMethod?.virtualAccount?.email ||
    vendorUpdatedVirtualPayment?.deliveryMethod?.virtualAccount?.email ||
    vendorDeliveryMethod?.virtualAccount?.email;

  return {
    email:
      (emailVirtualAccount?.length as number) > MAX_EMAIL_LENGTH
        ? `${emailVirtualAccount?.slice(0, MAX_EMAIL_LENGTH)}...`
        : emailVirtualAccount,
    fullEmail: emailVirtualAccount,
  };
}

function VirtualDeliveryMethod({
  onClick,
  method,
  isSelected,
  optionComponent: OptionComponent,
  disabled,
}: Props) {
  const { email } = useVirtualDeliveryEmail();

  if (!method) {
    return (
      <OptionComponent
        id="virtual"
        label="deliveryMethods.notDefinedInfo.virtual.label"
        icon="icon-email-icon"
        description="deliveryMethods.notDefinedInfo.virtual.desc"
        onClick={onClick}
      />
    );
  }

  return (
    <OptionComponent
      id={method.id}
      label="deliveryMethods.notDefinedInfo.virtual.selectedLabel"
      descriptionValues={{
        displayName: email,
      }}
      icon="icon-email-icon"
      description="deliveryMethods.virtual.desc"
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    />
  );
}

function VirtualDeliverySelectedMethod() {
  const { email, fullEmail } = useVirtualDeliveryEmail();

  return (
    <SelectedMethod
      icon="icon-email-icon"
      title="bills.form.paymentActivity.scheduledBill.virtualDeliveryMethod"
      label="selectMethods.selectedVirtualDeliveryMethodLabel"
      labelValues={{
        displayName: email,
      }}
      tooltipLabel="deliveryMethods.virtual.tooltipLabel"
      tooltipValue={fullEmail as any}
    />
  );
}

VirtualDeliveryMethod.methodType = CONSTS.DELIVERY_TYPE.VIRTUAL;
VirtualDeliveryMethod.SelectedMethod = VirtualDeliverySelectedMethod;
VirtualDeliveryMethod.isDisabled = () => false;

export default VirtualDeliveryMethod;
