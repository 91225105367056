import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import { AccountRecord } from 'src/app/pages/settings/records';
import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import { withNavigator } from 'src/app/hoc';
import { BillType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import deliveryApi from 'src/app/services/api/delivery';
import paymentsApi from 'src/app/services/api/payments';
import organizationApi from 'src/app/services/api/organizations';
import { getFundingSourceType } from 'src/app/utils/funding-sources';
import isNaN from 'lodash/isNaN';

import { AreaLoader } from '@melio/billpay-design-system';
import { selectPaymentDatesAction, setFeeAction } from 'src/app/redux/payBillWizard/actions';
import {
  getBill,
  getPayment,
  getIsRecurring,
  getRecurringBill,
  getIsLoading,
  getErrorCode,
  getFee,
  getSelectedFundingSource,
} from 'src/app/redux/payBillWizard/selectors';
import { getOrgId } from 'src/app/redux/user/selectors';
import { FAILED_PAYMENT_TYPE, PAYMENT_STATUS } from 'src/app/utils/consts';
import { getLayoutHeaderLabel } from 'src/app/pages/bill/utils';
import { convertFeeObject, isReturnedCheckPayment } from 'src/app/utils/payments';
import { defaultFeeObject } from 'src/app/utils/fee';
import { CreditCardFeePaymentEnum, DataOriginEnum } from 'src/app/version-2/model/enums';
import PayBillConfirmPage from './components/PayBillConfirmPage';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import { FundingSource } from 'src/app/version-2/model/dtos';

type MapStateToProps = {
  recurringBill: Record<string, any>;
  bill: RecordOf<BillType>;
  payment: RecordOf<PaymentType>;
  fee: PaymentFeeInfo | null;
  isRecurring: boolean;
  isLoading: boolean;
  selectedFundingSource: FundingSource | null;
  orgId: string;
  errorCode: string | null;
};

type MapDispatchToProps = {
  selectPaymentDates: (scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date) => void;
  setFee: (PaymentFeeInfo) => void;
};

type Props = {
  headerLabel: string;
  qbCashState: QBCashStateType;
} & PayBillProps &
  MapStateToProps &
  MapDispatchToProps;

type State = {
  isProcessingDatesLoading: boolean;
  isFeesLoading: boolean;
};

class PayBillConfirmPageContainer extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingDatesLoading: false,
      isFeesLoading: true,
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const { isRecurring, payment } = this.props;

    const isPaymentFailedToDeliver =
      payment.status === PAYMENT_STATUS.FAILED &&
      payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;

    setTimeout(() => {
      window.scrollBy(0, 1);
    }, 10);
    const newState = { isFeesLoading: true, isProcessingDatesLoading: false };
    const feeHandler = this.getFeeHandler(payment);

    if (isPaymentFailedToDeliver) {
      this.setClosestDeliveryDates(newState);
      feeHandler(payment.deliveryPreference as string);
    } else {
      const isRecurringPayment = isRecurring && payment.fundingSourceId && payment.deliveryMethodId;

      feeHandler(payment.deliveryPreference as string);

      if (isRecurringPayment) {
        this.setLatestProcessingDates(newState);
      }
    }

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState(newState);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getFeeHandler(payment: RecordOf<PaymentType>) {
    const { deliveryType } = payment?.deliveryMethod || {};

    return (deliveryPreference) => this.setFeeApi(deliveryType, deliveryPreference);
  }

  async setFeeApi(deliveryType, deliveryPreference) {
    const { orgId, payment, selectedFundingSource, setFee } = this.props;

    this.setState({ isFeesLoading: true });

    try {
      const { fees } = await organizationApi.getOrganizationFee(orgId, {
        paymentAmount: payment.amount,
        fundingSourceType: getFundingSourceType(selectedFundingSource),
        deliveryMethodType: deliveryType,
        deliveryPreference,
        paymentOrigin: DataOriginEnum.INTUIT,
        feesPaidBy: CreditCardFeePaymentEnum.PAYOR,
        organizationId: orgId,
        paymentId: payment?.id,
      });

      setFee(convertFeeObject(fees));
      this.setState({ isFeesLoading: false });
    } catch (e) {
      setFee(defaultFeeObject);
      this.setState({ isFeesLoading: false });
    }
  }

  setPaymentFees(deliveryPreference) {
    const { orgId, payment, setFee } = this.props;

    if (!isNaN(payment.fundingSourceId)) {
      this.setState({ isFeesLoading: true });
      paymentsApi
        .getPaymentFee({
          orgId,
          fundingSourceId: payment.fundingSourceId,
          amount: payment.amount,
          scheduledDate: payment.scheduledDate,
          deliveryPreference,
          deliveryMethodId: payment.deliveryMethodId,
          cardFeePaidBy: CreditCardFeePaymentEnum.PAYOR,
          allowDoubleFee: true,
        })
        .then((res) => {
          setFee(res.fee);

          if (this._isMounted) {
            this.setState({ isFeesLoading: false });
          }
        })
        .catch((err) => {
          loggingApi.error(
            'PayBillConfirmPageContainer.setPaymentFees(): unable to get payment fee',
            { error: err }
          );
        });
    }
  }

  setClosestDeliveryDates(newState) {
    const { orgId, payment } = this.props;

    newState.isProcessingDatesLoading = true;
    deliveryApi
      .getClosestDeliveryDates(
        orgId,
        payment.deliveryMethodId as unknown as number,
        payment.fundingSourceId,
        payment.amount as number
      )
      .then(({ deliveryDate, maxDeliveryDate }) => {
        this.setPaymentDates(payment.scheduledDate, deliveryDate, maxDeliveryDate);
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isProcessingDatesLoading: false });
        }
      });
  }

  setLatestProcessingDates(newState) {
    const { orgId, bill, payment } = this.props;

    newState.isProcessingDatesLoading = true;
    deliveryApi
      .getLatestProcessingDate(
        orgId,
        new Date(bill.dueDate),
        payment.deliveryMethodId,
        payment.fundingSourceId,
        payment.amount as number,
        payment?.id,
        payment?.payBillFlowUUID
      )
      .then(({ suggestedScheduledDate, deliveryDate, maxDeliveryDate }) => {
        this.setPaymentDates(suggestedScheduledDate, deliveryDate, maxDeliveryDate);
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isProcessingDatesLoading: false });
        }
      });
  }

  setPaymentDates(scheduledDate, deliveryDate, maxDeliveryDate) {
    const { selectPaymentDates } = this.props;

    selectPaymentDates(new Date(scheduledDate), new Date(deliveryDate), new Date(maxDeliveryDate));
    this.setState({ isProcessingDatesLoading: false });

    if (this._isMounted) {
      this.setState({ isProcessingDatesLoading: false });
    }
  }

  renderButtonLabel(payment) {
    const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);

    if (isRetryFailedToDeliver)
      return 'bills.form.paymentActivity.failedPaymentDescription.failedToDeliverACHRetryLink';

    return 'bills.pay.confirm.action';
  }

  render() {
    const { isProcessingDatesLoading, isFeesLoading } = this.state;
    const {
      onSubmit,
      bill,
      payment,
      isLoading,
      goExit,
      recurringBill,
      errorCode,
      selectedFundingSource,
      isRecurring,
      fee,
      qbCashState,
      onPrevConfirmPayment,
      navigate,
    } = this.props;

    const isReturnedCheck = isReturnedCheckPayment(payment);

    if (isProcessingDatesLoading || isFeesLoading) {
      return <AreaLoader placement="wizard" />;
    }

    return (
      <PayBillConfirmPage
        isRecurring={isRecurring}
        recurringBill={recurringBill}
        onSubmit={onSubmit}
        onPrev={onPrevConfirmPayment}
        bill={bill}
        headerLabel={getLayoutHeaderLabel({
          isRecurring,
          deliverFailureData: payment?.deliverFailureData,
        })}
        relativeStep={isReturnedCheck ? undefined : 5 / 5}
        payment={payment}
        selectedFundingSource={selectedFundingSource || AccountRecord()}
        isLoading={isLoading}
        goExit={goExit}
        errorCode={errorCode}
        fee={fee}
        title="bills.pay.confirm.title"
        buttonLabel={this.renderButtonLabel(payment)}
        qbCashState={qbCashState}
        navigate={navigate}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  bill: getBill(state),
  payment: getPayment(state),
  isRecurring: getIsRecurring(state),
  recurringBill: getRecurringBill(state),
  isLoading: getIsLoading(state),
  selectedFundingSource: getSelectedFundingSource(state),
  orgId: getOrgId(state),
  errorCode: getErrorCode(state),
  fee: getFee(state) as PaymentFeeInfo,
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectPaymentDates(scheduledDate, deliveryEta, maxDeliveryEta, selectedId?) {
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryEta, maxDeliveryEta, selectedId));
  },
  setFee(fee) {
    dispatch(setFeeAction(fee));
  },
});

export default compose(
  withNavigator(),
  withPayBillData(),
  connect(mapStateToProps, mapDispatchToProps)
)(PayBillConfirmPageContainer);
