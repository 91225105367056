import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';
import filter from 'lodash/filter';
import { getUserOrganization } from 'src/app/utils/user-utils';
import { createRestfulSlice, hashListKey } from '../../helpers/redux/createRestfulSlice';
import api from '../../services/api/user';
import { SET_PROFILE } from '../../redux/user/actionTypes';
import {
  userManagementDeleteSlice,
  userManagementListSlice,
  userManagementUpdateSlice,
  mergeUserOrganizationsCustomizer,
} from './user-management-slice';
import { addUsersSlice } from './add-users-slice';
import organizationsStore from '../organizations/organizations-store';
import { getBillByIdSlice } from '../batch-bills/batch-bills-store';

const name = 'users';

export function getUsersOfOrg(orgId) {
  return (state) =>
    filter(state[name].byId, (user) =>
      user.userOrganizations.some((userOrg) => userOrg.organizationId === orgId)
    );
}
const usersStore = createRestfulSlice({
  name,
  schemaName: 'user',
  api,
  extraReducers: {
    [SET_PROFILE](state, payload) {
      const { userPreferences, ...profile } = payload.profile.toJS();
      const { organizations } = payload;

      state.byId[profile.id] = merge(state.byId[profile.id] || {}, {
        ...profile,
        userOrganizations: (organizations || [])
          .filter((o) => !!o.userOrganization)
          .map((o) => o.userOrganization),
      });
    },
    [getBillByIdSlice.actions.success.type](state, { payload }) {
      (payload.object.payments || []).forEach((payment) => {
        (payment.metadata?.audit || []).forEach((auditLog) => {
          const user = {
            ...auditLog.actor,
            userOrganizations: [
              {
                userId: auditLog.actor.id,
                organizationId: payload.object.organizationId,
                role: auditLog.actor.role,
              },
            ],
          };

          state.byId[user.id] = mergeWith(
            state.byId[user.id] || {},
            user,
            mergeUserOrganizationsCustomizer
          );
        });
      });
    },
    [organizationsStore.actions.create.success](state, { payload }) {
      const { userId } = payload.userOrganization;
      const { userOrganizations } = state.byId[userId];

      userOrganizations.push(payload.userOrganization);
    },
  },
  selectors: {
    org(orgId) {
      const orgSelectors = {
        users(state) {
          return userManagementListSlice.select({ orgId })(state[name]);
        },
        isMultiUser(state) {
          return userManagementListSlice.select({ orgId })(state[name]).length > 1;
        },
        isLoading(state) {
          const hashKey = hashListKey({ orgId });

          return state[name].lists[hashKey]?.loading;
        },
      };

      return orgSelectors;
    },
    byId(userId) {
      return (state) => state[name].byId[userId];
    },
    userOrganization(userId, orgId) {
      const selectors = {
        userOrg(state) {
          const user = state[name].byId[userId];

          return getUserOrganization(user, orgId);
        },
        isLoading(state) {
          return state[name].meta[userId]?.loading;
        },
        isUpdating(state) {
          return state[name].update[userId]?.loading;
        },
      };

      return selectors;
    },
    changeOwner(orgId) {
      const selectors = {
        isChanging(state) {
          return state[name].changeOwner[orgId]?.isChanging;
        },
      };

      return selectors;
    },
  },
  slices: {
    userManagementDeleteSlice,
    userManagementUpdateSlice,
    userManagementListSlice,
    addUsers: addUsersSlice,
  },
});

export function getUserActions(dispatch) {
  return {
    async list(params) {
      return dispatch(userManagementListSlice.actions(params));
    },
    async updateUserOrganization(userOrganization, meta) {
      return dispatch(
        userManagementUpdateSlice.actions(
          {
            orgId: userOrganization.organizationId,
            id: userOrganization.userId,
            role: userOrganization.role,
            requireApproval: userOrganization.requireApproval,
            approvalAmountThreshold: userOrganization.approvalAmountThreshold,
          },
          meta
        )
      );
    },
    async deleteUserOrganization(userOrganization) {
      return dispatch(
        userManagementDeleteSlice.actions({
          orgId: userOrganization.organizationId,
          id: userOrganization.userId,
        })
      );
    },
  };
}
export default usersStore;
