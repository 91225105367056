import { useMemo } from 'react';
import MIRadioGroup, { Option } from 'src/app/components/form/MIRadioGroup';
import { PaymentDeliveryOption } from 'src/app/components/common/PaymentDeliveryOption';
import { DeliveryOptionType } from 'src/app/utils/types';
import { FAST_DELIVERY_TYPES } from 'src/app/utils/consts';
import { mapCalendarFees } from 'src/app/utils/fee';

import { useFastOptionTag } from './hooks';
import * as utils from './PaymentDeliveryOptions.utils';

interface Props {
  dueDate?: string | Date | null;
  paymentAmount?: number | null;
  deliveryOptions: DeliveryOptionType[];
  deliveryPreference?: string;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    type: string
  ) => void;
}

const PaymentDeliveryOptions = ({
  dueDate,
  deliveryOptions,
  onSelectDeliveryOption,
  deliveryPreference,
  fundingSourceType,
  paymentAmount,
}: Props) => {
  const mappedDeliveryOptions = useMemo(() => {
    const feeDeliveryOptions = mapCalendarFees(deliveryOptions);
    const sortedFeeDeliveryOptions = utils.sortDeliveryOptions(feeDeliveryOptions);

    return sortedFeeDeliveryOptions;
  }, [deliveryOptions]);

  const fastDeliveryOptionTag = useFastOptionTag({
    dueDate,
    deliveryOptions: mappedDeliveryOptions,
    selectedFundingSourceType: fundingSourceType,
  });

  const paymentDeliveryOptions: Option<string>[] = useMemo(() => {
    const expeditedAchDeliveryDate = utils.getExpeditedACHDeliveryDate(deliveryOptions);

    return mappedDeliveryOptions.map((option, index) => ({
      id: index.toString(),
      component: (
        <PaymentDeliveryOption
          deliveryOption={option}
          fundingSourceType={fundingSourceType}
          isFast={utils.isFastDeliveryOption(mappedDeliveryOptions, option.type)}
          tagLabel={fastDeliveryOptionTag}
          paymentAmount={Number(paymentAmount)}
          fee={utils.getDeliveryOptionFees(option, paymentAmount)}
          expeditedAchDeliveryDate={expeditedAchDeliveryDate}
        />
      ),
    }));
  }, [mappedDeliveryOptions, fundingSourceType, paymentAmount]);

  const handleSelectDeliveryOption = (selectedId) => {
    const { scheduledDate, deliveryDate, maxDeliveryDate, type } =
      mappedDeliveryOptions[selectedId];

    onSelectDeliveryOption &&
      onSelectDeliveryOption(scheduledDate, deliveryDate, maxDeliveryDate, type);
  };

  const optionType = mappedDeliveryOptions[0]?.type;
  const group = optionType ? `deliveryDate-${optionType}` : 'deliveryDate';

  const defaultRegularOptionIndex = mappedDeliveryOptions.findIndex(
    (option) => !FAST_DELIVERY_TYPES.includes(option.type)
  );
  const preferredOptionIndex = mappedDeliveryOptions.findIndex(
    (option) => option.type === deliveryPreference
  );
  const selected = (
    preferredOptionIndex > -1 ? preferredOptionIndex : defaultRegularOptionIndex
  ).toString();

  return (
    <MIRadioGroup
      id="PaymentDeliveryOption"
      options={paymentDeliveryOptions}
      selected={selected}
      onSelect={handleSelectDeliveryOption}
      colDirection
      group={group}
    />
  );
};

export { PaymentDeliveryOptions };
