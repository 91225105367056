import React, { FunctionComponent } from 'react';
import merge from 'lodash/merge';
import isArray from 'lodash/isArray';
import { Redirect, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import locations from 'src/app/utils/locations';
import { authAgnosticRoutes } from 'src/app/pages/auth/route';
import { qboBillRoutes, qboPrivateRoutes, qboPublicRoutes } from 'src/app/pages/quickbooks/route';
import billsRoutes from './bill/route';
import billsPayRoutes from './bill/pay/route';
import deliveryMethodRoutes from './vendor/delivery-methods/route';
import settingsRoutes from './settings/route';
import feeRoutes from './settings/components/fee/routes';
import { withSiteContext } from '../hoc/withSiteContext';
import fundingSourcesStandaloneRoutes from './funding-sources/route';
import orgLocations from './orgs/locations';

type Props = {
  location: {
    pathname: string;
    search: string;
  };
};

const indexRedirectAuth = {
  path: '/',
  exact: true,
  component: (() => {
    const orgId = useSelector(getOrgId);

    return <Redirect to={locations.Settings.index.url({ orgId })} />;
  }) as FunctionComponent,
};

const defaultPublic = {
  component: withSiteContext()((props: Props & { site: any }) => {
    const orgId = useSelector(getOrgId);

    return (
      <Redirect
        to={
          generatePath(orgLocations.index + props.location.pathname, {
            orgId,
          }) + props.location.search
        }
      />
    );
  }),
};

const routes = {
  public: {
    fundingSourcesStandaloneRoutes,
    authAgnosticRoutes,
    qboPublic: qboPublicRoutes,
  },
  authenticated: {
    orgs: {
      index: indexRedirectAuth,
      bills: merge(billsRoutes, qboBillRoutes),
      billsPay: billsPayRoutes,
      deliveryMethods: deliveryMethodRoutes,
      settings: settingsRoutes,
      fee: feeRoutes,
    },
    qboPrivate: qboPrivateRoutes,
  },
};

function isRoute(obj) {
  return obj && (obj.render || obj.component);
}
function flattenRoutes(def: any) {
  let defaultRoute = null;
  const flattened: Record<string, any> = [];

  Object.keys(def || {}).forEach((key) => {
    const route = def[key];

    if (key !== 'orgs') {
      if (key === 'default') {
        defaultRoute = route;
      } else if (isRoute(route)) {
        flattened.push(route);
      } else if (isArray(route)) {
        route.forEach((r) => flattened.push(r));
      } else {
        flattenRoutes(def[key]).forEach((r) => flattened.push(r));
      }
    }
  });

  return flattened.concat(defaultRoute).filter((route) => !!route);
}
export function buildRoutes(site: any, userAuthType: any): any {
  return {
    publicRoutes: flattenRoutes(routes.public)
      .concat(flattenRoutes(routes[userAuthType]))
      .concat(defaultPublic),
    orgsRoutes: flattenRoutes(routes[userAuthType]?.orgs),
  };
}
