import React from 'react';
import { RecordOf } from 'immutable';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import isNil from 'lodash/isNil';
import styled, { withTheme } from 'styled-components';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import { AreaLoader } from '@melio/billpay-design-system';
import { devices } from 'src/app/theme/AppDevices';
import { QBOBillDetailsForm } from 'src/app/pages/bill/components/QBOBillDetailsForm';
import QBRPageHeader from 'src/app/components/common/QBRPageHeader';
import { MIFormattedText, MIFieldOrEmpty, MIFormattedCurrency } from 'src/app/utils/formatting';
import { withBreak, withNavigator } from 'src/app/hoc';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import BillDetailsTotal from 'src/app/components/list/BillDetailsTotal';
import QBODialog from 'src/app/components/common/QBOMIDialog';
import {
  BillType,
  PaymentType,
  DeletePaymentActionType,
  UserSummaryType,
  FieldType,
  NavigateType,
  QBCashStateType,
  NotePlaceholderType,
  QboBillAttachment,
  OrganizationPreferencesType,
} from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { getStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { MICard, MICardForm, MICardTitle } from 'src/app/components/common/MICard';
import RightPanelPlaceholder from 'src/app/components/onboarding/RightPanelPlaceholder';
import { MIArrowActionsMenu } from 'src/app/components/common/MIArrowActionsMenu';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import { SingleViewLoadingContainer } from 'src/app/components/layout/Containers';
import { getPaymentMethodName } from 'src/app/version-2/utils/paymentMethods.utils';
import {
  CONSTS,
  BILL_STATUS,
  PAYMENT_STATUS,
  DELETE_PAYMENT_ACTION,
  BILL_PAGE_TYPE,
  NOTIFICATION_CARD_TYPES,
  PAYMENT_APPROVAL_STATUS,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import locations from 'src/app/utils/locations';
import { renderBlockedForPaymentDialog } from 'src/app/utils/vendors';
import analytics from 'src/app/services/analytics';
import {
  isManuallyPaid,
  getBillHeaderActionsOptions,
  getBillTag,
  showMarkAsPaidBills,
} from 'src/app/utils/bills';
import { MIFloatedEditDoneButtons } from 'src/app/components/common/MIFloatedEditDoneButtons';
import { Option } from 'src/app/components/common/MISingleSelect';
import billsApi from 'src/app/services/api/bills';
import recurringBillsApi from 'src/app/services/api/recurringBills';
import withListContainer from 'src/app/hoc/withListContainer';
import paymentsApi from 'src/app/services/api/payments';
import { getStatusInfo } from 'src/app/utils/bill-details';
import { getOrgId, getCompanyInfo } from 'src/app/redux/user/selectors';
import { Permissions } from 'src/app/utils/permissions';
import profileStore from 'src/app/modules/profile/profile-store';
import paymentsStore, {
  getPaymentsActions,
  ApprovalDecisionType,
} from 'src/app/modules/payments/payment-store';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import MIButton from 'src/app/components/common/MIButton';
import { FormContainer } from 'src/app/ui/form/FormElements';
import usersStore from 'src/app/modules/users/users-store';
import { getLatestPayment, getFailedPaymentDescription } from 'src/app/utils/payments';
import { encodeQuery } from 'src/app/utils/query-utils';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { getBillListActions } from 'src/app/modules/batch-bills/batch-bills-store';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { CheckVendorPaymentPreferencesType } from 'src/app/modules/vendors/check-vendor-payment-preferences';
import quickPayLocations from 'src/app/pages/quickpay/locations';
import { isEnterPressed } from 'src/app/utils/events';
import intercomService from 'src/app/services/intercom';
import MINotificationCard from 'src/app/components/common/MINotificationCard';
import { AccountRecord } from 'src/app/pages/settings/records';
import BillPaymentsActivity from 'src/app/pages/bill/components/QBRBillPaymentsActivity';
import {
  selectPaymentDatesAction,
  beginRegularPayBillFlowAction,
} from 'src/app/redux/payBillWizard/actions';
import { getQBOBillAttachments } from 'src/app/pages/bill/components/utils';
import { ScreenModeEnum } from 'src/app/version-2/model/enums';
import withNewBillData from '../hoc/withNewBillData';
import { BillDetailsHeader } from './BillDetailsHeader';
import BillAttachment from './BillAttachment';
import QBRBillStatus from './QBRBillStatus';
import { BillRecord } from '../records';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';

type PaymentParamsType = {
  orgId: string;
  id: string;
  reason?: string | null;
};

type MapStateToProps = {
  orgId: string;
  permissions: Permissions;
  currentUser: any;
  approvalDecisionStatus: ApprovalDecisionType;
  scheduledBy: UserSummaryType;
  vendorPaymentPreferences: Record<string, any>;
  companyName: string;
  organizationPreferences: OrganizationPreferencesType;
};

type MapDispatchToProps = {
  approvePayment: (params: PaymentParamsType) => void;
  declinePayment: (params: PaymentParamsType) => void;
  getBillById: (params: Omit<PaymentParamsType, 'reason'>) => Promise<any>;
  beginRegularPayBillFlow: (billId: string, paymentId: string) => void;
  selectPaymentDates: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    deliveryPreference?: string
  ) => void;
  checkVendorPaymentPreferences: ({ orgId, id }) => Promise<any>;
};

type Props = {
  bill: RecordOf<BillType>;
  backPath: string;
  vendors?: Option[];
  intuitAccountsOptions?: Option[];
  device: {
    isMobile: boolean;
    isPhablet: boolean;
  };
  onChangeAttachment?: (file: File) => void | null;
  onDeleteAttachment?: () => void | null;
  onFieldChange?: (field: FieldType) => void;
  validationErrors?: Record<string, any>;
  theme: Record<string, any>;
  fileStorageUrl?: string;
  filePreviewUrls?: string[];
  isUploading?: boolean;
  isAttachmentLoading: boolean;
  query: {
    start?: number;
    limit?: number;
    search?: string;
  };
  site: any;
  onLoadBill: (bill?: RecordOf<BillType>) => void;
  id: string;
  onSubmitBill: (cb: (isPassedValidation: boolean) => void) => void;
  navigate: NavigateType;
  billId: string;
  qbCashState?: QBCashStateType;
  notePlaceholder?: NotePlaceholderType;
  reloadBills: () => void;
  onCancelForm: (prevBill: RecordOf<BillType> | null) => void;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  isDeleting: boolean;
  isVendorBlockedForPayment: boolean;
  mode: ScreenModeEnum;
  isLoading: boolean;
  recurringBill: RecordOf<BillType> | null;
  showDeclineModal: boolean;
  declineReason: string;
  prevBill: RecordOf<BillType> | null;
  qboBillAttachments: QboBillAttachment[] | null;
};

const eventPage = 'bill';

class QBRViewBill extends React.PureComponent<Props, State> {
  static defaultProps = { isListLoading: false };

  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    mode: ScreenModeEnum.VIEW,
    isDeleting: false,
    isVendorBlockedForPayment: false,
    isLoading: false,
    recurringBill: null,
    showDeclineModal: false,
    declineReason: '',
    prevBill: null,
    qboBillAttachments: null,
  });

  componentDidMount() {
    this.loadBill();
  }

  componentDidUpdate(prevProps: Props) {
    const { billId, approvalDecisionStatus } = this.props;

    if (
      (prevProps.billId == null && billId) ||
      (prevProps.billId && billId && prevProps.billId !== billId) ||
      (prevProps.approvalDecisionStatus.loading &&
        prevProps.approvalDecisionStatus.loading !== approvalDecisionStatus.loading)
    ) {
      this.resetState();
      this.loadBill();
    }
  }

  onDeleteBill = () => {
    const { orgId, id, backPath, navigate } = this.props;

    analytics.track(eventPage, 'delete-bill-confirmed');
    this.setState({ isLoading: true });
    billsApi
      .deleteBillById(orgId, id)
      .then(() => {
        analytics.track(eventPage, 'delete-bill-success');
        navigate(backPath);
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onEditBill = (cb: (isPassedValidation: boolean) => void) => {
    const { onSubmitBill } = this.props;

    this.setState({ isLoading: true }, () => {
      onSubmitBill((isPassedValidation: boolean) => {
        this.setState({ isLoading: false });
        cb(isPassedValidation);
      });
    });
  };

  onDeletePayment = (payment: PaymentType, type?: DeletePaymentActionType) => {
    analytics.track(eventPage, 'delete-payment-confirmed');

    switch (type) {
      case DELETE_PAYMENT_ACTION.RECURRING_CURRENT:
        this.deleteCurrentRecurringPaymentById(payment.id);
        break;
      case DELETE_PAYMENT_ACTION.RECURRING_ALL:
        this.deleteAllRecurringPayments();
        break;
      default:
        this.deletePayment(payment.id);
    }
  };

  onMarkBillPaymentStatus = (isPaid: boolean) => {
    const { id, orgId, bill: markedBill, navigate, site } = this.props;
    const baseEventAction = isPaid ? 'paid' : 'unpaid';

    if (!markedBill) {
      return;
    }

    analytics.track(eventPage, `mark-as-${baseEventAction}`);
    this.setState({ isLoading: true });
    billsApi
      .markAsPaid({
        orgId,
        id: markedBill.id,
        isPaid,
        createOrigin: site.createOrigin.pay.payment,
      })
      .then(() => {
        analytics.track(eventPage, `mark-as-${baseEventAction}-success`);
        const status = isPaid ? BILL_STATUS.PAID : BILL_STATUS.UNPAID;
        const defaultFilters = getBillsDefaultFilters(status);

        navigate(
          locations.Bills.filteredView.url({
            id,
            ...defaultFilters,
          })
        );
      })
      .then(this.loadBill)
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onToggleMode = () => {
    const { bill, onCancelForm } = this.props;

    this.setState(
      ({ mode }) => ({
        mode: mode === ScreenModeEnum.VIEW ? ScreenModeEnum.EDIT : ScreenModeEnum.VIEW,
        // TODO: Use callback in setState when referencing the previous state
        // eslint-disable-next-line react/no-access-state-in-setstate
        prevBill: mode === ScreenModeEnum.VIEW ? bill : this.state.prevBill,
      }),
      () => {
        if (this.state.mode === ScreenModeEnum.EDIT) {
          analytics.track('bills', 'edit-bill-mode');
        } else {
          analytics.track('bills', 'cancel-edit-bill-mode');
          onCancelForm(this.state.prevBill);
        }
      }
    );
  };

  onEditBillFunc = () => {
    this.onEditBill((isPassedValidation) => {
      if (isPassedValidation) {
        this.setState({ mode: ScreenModeEnum.VIEW });
      }
    });
  };

  onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      this.onEditBillFunc();
    }
  };

  onDeleteClicked = () => {
    analytics.track('bills', 'delete-bill');
    this.setState({ isDeleting: true });
  };

  onDeleteBillCanceled = () => {
    analytics.track('bills', 'delete-bill-canceled');
    this.setState({ isDeleting: false });
  };

  onHideNonDeletableModal = () => {
    analytics.track('bills', 'hide-non-deletable-modal');
    this.setState({ isDeleting: false });
  };

  onMarkBillAsPaid = () => this.onMarkBillPaymentStatus(true);

  onMarkBillAsUnpaid = () => this.onMarkBillPaymentStatus(false);

  onRetryPayment = (paymentId: number) => {
    const { id, navigate } = this.props;

    analytics.track(eventPage, 'retry-payment');
    navigate(
      locations.Bills.pay.edit.funding.url({
        id,
        paymentId,
      })
    );
  };

  onRetryPaymentDelivery = (paymentId: number, deliveryMethodId?: number) => {
    const { id, navigate } = this.props;

    analytics.track(eventPage, 'retry-payment-delivery');
    navigate(
      locations.Bills.pay.edit.deliveryMethodAch.url({
        id,
        paymentId,
        deliveryMethodId,
      })
    );
  };

  onApproveDecision = (status: string, reason?: string | null) => {
    const { orgId, approvePayment, declinePayment, bill } = this.props;
    const payment = last(bill.payments) as PaymentType;

    if (status === 'approved') {
      approvePayment({ orgId, id: payment.id, reason });
    } else {
      declinePayment({ orgId, id: payment.id, reason });
    }
  };

  onApprove = () => {
    this.onApproveDecision(PAYMENT_APPROVAL_STATUS.APPROVED);
  };

  onDecline = () => {
    this.setState({
      showDeclineModal: true,
    });
  };

  onTrackDelivery = () => {
    const { bill } = this.props;

    analytics.track(eventPage, 'track-delivery');
    const lastPayment = getLatestPayment(bill.payments);
    const trackingUrl = lastPayment?.checkTracks?.[0]?.trackingUrl;

    if (trackingUrl) {
      window.open(trackingUrl);
    }
  };

  getActionOptions(bill, mode, addMarkAsPaid, permissions, currentUser) {
    const opts: {
      label: string;
      action: () => void;
      mobile?: boolean;
      disabled?: boolean;
      negative?: boolean;
    }[] = [];

    if (bill.status === BILL_STATUS.PAID && addMarkAsPaid) {
      opts.push({
        label: 'bills.actions.undoMarkAsPaidMobile',
        action: this.onMarkBillAsUnpaid,
      });
    } else if (bill.status === BILL_STATUS.UNPAID) {
      if (addMarkAsPaid && permissions.bills.markAsPaid()) {
        opts.push({
          label: 'bills.actions.markAsPaid',
          action: this.onMarkBillAsPaid,
        });
      }

      const editDisabled = bill.payments.length > 0;

      if (mode === ScreenModeEnum.VIEW && permissions.bills.update(currentUser, bill)) {
        opts.push({
          label: 'bills.actions.edit',
          action: this.onToggleMode,
          disabled: editDisabled,
        });
      }

      if (permissions.bills.delete(currentUser, bill)) {
        opts.push({
          label: 'bills.actions.delete',
          action: this.onDeleteClicked,
          negative: true,
        });
      }
    }

    return opts;
  }

  getHeaderSubtitle = () => {
    const { bill } = this.props;
    const { recurringBill } = this.state;

    if (bill.recurringBillId && recurringBill) {
      const { frequency, occurrences } = recurringBill;
      const index = bill.recurringBillIndex || null;

      return (
        <MIFormattedText
          label="bills.view.recurringBillInfo"
          values={{ frequency, index, occurrences }}
        />
      );
    }

    return bill.invoiceNumber ? (
      <MIFormattedText
        label="bills.view.invoiceNumber"
        values={{ invoiceNumber: bill.invoiceNumber }}
      />
    ) : (
      <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.form.invoiceNumberEmpty" />
    );
  };

  redirectDefaultScheduledBills = () => {
    const { site, navigate, orgId } = this.props;

    if (site.quickpayList) {
      navigate(generatePath(quickPayLocations.list.index, { orgId }));
    } else {
      const defaultFilters = getBillsDefaultFilters(BILL_STATUS.SCHEDULED);

      navigate(locations.Bills.filteredViewWithoutId.url(defaultFilters));
    }
  };

  deletePayment = async (paymentId) => {
    const { orgId, reloadBills, site } = this.props;

    this.setState({ isLoading: true });

    try {
      await paymentsApi.deletePaymentById(orgId, paymentId, {
        deleteBill: site.deleteBillWithPayment,
      });
      analytics.track(eventPage, 'delete-payment-success');
      this.setState({ isLoading: false });
      this.redirectDefaultScheduledBills();
      reloadBills();
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  deleteCurrentRecurringPaymentById = async (paymentId) => {
    const { orgId, bill, navigate, reloadBills } = this.props;
    const { recurringBill } = this.state;

    this.setState({ isLoading: true });

    try {
      await recurringBillsApi.deleteCurrentRecurringPaymentById(orgId, paymentId);
      analytics.track(eventPage, 'delete-current-recurring-bill-payment-success');

      const nextRecurringBillIndex = bill.recurringBillIndex + 1;

      if (recurringBill && nextRecurringBillIndex > recurringBill.occurrences) {
        this.setState({ isLoading: false });
        this.redirectDefaultScheduledBills();
        reloadBills();
      } else {
        const { objects: bills } = await billsApi.getBills({
          orgId,
          filters: {
            recurringBillId: bill.recurringBillId,
            recurringBillIndex: nextRecurringBillIndex,
          },
        });
        const defaultFilters = getBillsDefaultFilters(BILL_STATUS.SCHEDULED);
        const url =
          bills[0] && bills[0].id
            ? locations.Bills.filteredView.url({
                id: bills[0].id,
                ...defaultFilters,
              })
            : locations.Bills.filteredViewWithoutId.url(defaultFilters);

        this.setState({ isLoading: false });
        navigate(url);
        reloadBills();
      }
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  deleteAllRecurringPayments = async () => {
    const { orgId, bill, reloadBills } = this.props;

    this.setState({ isLoading: true });

    try {
      await recurringBillsApi.deleteAllRecurringPayments(orgId, bill.recurringBillId);
      analytics.track(eventPage, 'delete-all-recurring-bill-payments-success');
      this.setState({ isLoading: false });

      this.redirectDefaultScheduledBills();
      reloadBills();
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  goPayBill = async () => {
    const { navigate, id, checkVendorPaymentPreferences, orgId, bill, vendorPaymentPreferences } =
      this.props;

    analytics.track(eventPage, 'pay-bill');

    if (vendorPaymentPreferences?.blockPayments) {
      this.setState({ isVendorBlockedForPayment: true });
    } else {
      const { payload } = await checkVendorPaymentPreferences({
        orgId,
        id: bill.vendorId,
      });

      if (payload?.blockPayments) {
        this.setState({ isVendorBlockedForPayment: true });
      } else {
        navigate(locations.Bills.pay.funding.url({ id }));
      }
    }
  };

  goEditPayment = (payment: PaymentType) => {
    const { id, site, orgId, navigate } = this.props;

    analytics.track(eventPage, 'edit-bill');

    if (site.quickEditPayment) {
      const url = generatePath(quickPayLocations.edit.entry, {
        orgId,
        paymentId: payment.id,
      });

      navigate(url);
    } else {
      navigate(
        locations.Bills.pay.edit.funding.url({
          id,
          paymentId: payment.id,
        })
      );
    }
  };

  resetState = () => {
    const initialState = this.getInitialState();

    this.setState(initialState);
  };

  onClickSupport = () => {
    intercomService.show();
    analytics.track('bills', 'payment-failed-contact-support');
  };

  loadBill = async () => {
    const { orgId, id, onLoadBill, getBillById } = this.props;
    let bill;

    try {
      const response = await getBillById({
        orgId,
        id,
      });
      const { object: billData } = response.payload;

      if (billData.recurringBillId) {
        const result = await recurringBillsApi.getOrgRecurringBills(orgId, {
          params: [billData.recurringBillId],
        });
        const recurringBill = result.recurringBills.pop();

        this.setState({ recurringBill });
      }

      const qboBillAttachments = await getQBOBillAttachments(billData.originId, orgId);

      this.setState({ qboBillAttachments });
      bill = BillRecord(billData);

      onLoadBill && onLoadBill(bill);
    } catch {
      onLoadBill && onLoadBill(BillRecord());
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  renderDeleteModalDialog = () => {
    const { bill, site } = this.props;
    const isDeletable = get(bill, 'metadata.isDeletable', true);

    if (isDeletable) {
      return (
        <QBODialog
          type={DIALOG_TYPE.CONFIRM}
          variant={DIALOG_VARIANTS.ERROR}
          title="bills.form.deleteDialog.title"
          titleValues={{
            invoiceNumber: bill.invoiceNumber,
            companyName: bill.vendor.companyName,
          }}
          subtitle="bills.form.deleteDialog.subtitle"
          subtitleValues={{
            totalAmount: (
              <strong>
                <MIFormattedCurrency value={bill.totalAmount} />
              </strong>
            ),
            companyName: <strong>{bill.vendor.companyName}</strong>,
          }}
          okButtonText="bills.form.deleteDialog.confirm"
          onOkAction={this.onDeleteBill}
          onCancelAction={this.onDeleteBillCanceled}
        />
      );
    }

    return (
      <QBODialog
        type={DIALOG_TYPE.ALERT}
        variant={DIALOG_VARIANTS.ERROR}
        title="bills.form.nonDeletableDialog.title"
        subtitle="bills.form.nonDeletableDialog.subtitle"
        subtitleValues={{
          supportEmail: (
            <StyledMIInlineLink
              target="_self"
              text={site.config.support.email}
              to={`mailto:${site.config.support.email}`}
            />
          ),
        }}
        cancelButtonText="bills.form.nonDeletableDialog.ok"
        onCancelAction={this.onHideNonDeletableModal}
      />
    );
  };

  renderDeclineModalDialog = () => {
    const { showDeclineModal, declineReason } = this.state;
    const { approvalDecisionStatus, scheduledBy } = this.props;

    if (!showDeclineModal) {
      return null;
    }

    const onDecline = () => {
      this.onApproveDecision(PAYMENT_APPROVAL_STATUS.DECLINED, declineReason);
    };
    const onKeyPressed = (event: React.KeyboardEvent) => {
      if (isEnterPressed(event)) {
        onDecline();
        event.preventDefault();
      }
    };
    const onClose = () => this.setState({ showDeclineModal: false });

    return (
      <ModalMessage
        id="decline-payment-modal"
        titleComponent={
          <div className="title">
            <MIFormattedText
              label="bills.form.declineDialog.title"
              values={{ br: <br />, userName: scheduledBy?.firstName }}
            />
          </div>
        }
        contentComponent={
          <FormContainer onKeyDown={onKeyPressed}>
            <MITextInput
              id="declineNote"
              label="bills.form.declineDialog.noteTitle"
              placeholder="bills.form.declineDialog.placeholder"
              value={declineReason}
              autoFocus
              onChange={(e) => this.setState({ declineReason: e.value })}
            />
          </FormContainer>
        }
        buttonLabel="bills.form.declineDialog.buttonLabel"
        onButtonClick={onDecline}
        isLoading={approvalDecisionStatus.loading}
        onCloseClick={onClose}
      />
    );
  };

  renderErrorMessage = () => {
    const { bill } = this.props;
    const payment = getLatestPayment(bill.payments);

    if (payment) {
      const failedPaymentDescription = getFailedPaymentDescription(payment);
      const fundingSource = AccountRecord((payment as any)?.fundingSource);
      const showDisplayName = false;

      return (
        <StyledNotificationCard
          type={NOTIFICATION_CARD_TYPES.ERROR}
          title={{
            label: 'bills.status.paymentFailedActionRequired',
          }}
          subtitle={{
            label: failedPaymentDescription,
            values: {
              fundingSource: getPaymentMethodName({ fundingSource, showDisplayName }),
              vendorCompanyName: bill.vendor.companyName,
            },
          }}
        />
      );
    }

    return null;
  };

  render() {
    const {
      bill,
      vendors,
      intuitAccountsOptions,
      device,
      onChangeAttachment,
      onDeleteAttachment,
      theme,
      onFieldChange,
      validationErrors,
      isUploading,
      query,
      fileStorageUrl,
      filePreviewUrls,
      isAttachmentLoading,
      permissions,
      currentUser,
      approvalDecisionStatus,
      site,
      backPath,
      qbCashState,
      notePlaceholder,
      organizationPreferences,
    } = this.props;
    const {
      mode,
      isDeleting,
      isLoading,
      showDeclineModal,
      qboBillAttachments,
      isVendorBlockedForPayment,
    } = this.state;
    const isFullSingleView = device.isMobile || device.isPhablet;
    const unpaidBill = bill.status === BILL_STATUS.UNPAID;

    if (!bill || !bill.id) {
      return <RightPanelPlaceholder isLoading={isLoading} />;
    }

    const hasManualPayment = bill.payments.some((p) => p.manual);
    const manuallyPaid = isManuallyPaid(bill.status, hasManualPayment);
    const showMarkAsPaid = showMarkAsPaidBills(bill, permissions);
    const onCloseBlockPaymentDialog = () => this.setState({ isVendorBlockedForPayment: false });

    const actionOptions = this.getActionOptions(
      bill,
      mode,
      showMarkAsPaid,
      permissions,
      currentUser
    );

    const showTotalAmountSection = bill.payments.length === 0;
    const latestPayment = bill.payments[bill.payments.length - 1];

    const isPaymentFailed = latestPayment?.status === PAYMENT_STATUS.FAILED;
    const isInvalidBalance = latestPayment?.balance !== 0 && latestPayment?.balance !== null;
    const showBillInfoActions = !(unpaidBill && isInvalidBalance && isPaymentFailed);
    const status = getBillTag(bill);
    const isRequestAndHasNotDeliveryMethods =
      bill.isVendorRequest() && isEmpty(bill.vendor.deliveryMethods);
    const statusInfo = getStatusInfo(
      { status },
      theme,
      latestPayment,
      manuallyPaid,
      isRequestAndHasNotDeliveryMethods,
      bill.vendor.companyName
    );
    const billsSearchPath = encodeQuery(query, ['id'], '');
    const isEditMode = mode === ScreenModeEnum.EDIT;
    const showPageHeader = !(isEditMode && isFullSingleView);
    const canSchedulePayment = unpaidBill && !isRequestAndHasNotDeliveryMethods;
    const headerSubtitle = this.getHeaderSubtitle();
    const { recurringBill } = this.state;
    const isMarkAsPaid = find(actionOptions, (el) => el.label === 'bills.actions.markAsPaid');

    const isApproving = approvalDecisionStatus.loading && !showDeclineModal;
    const headerActions = getBillHeaderActionsOptions({
      bill,
      unpaidBill,
      permissions,
      onRetryPayment: this.onRetryPayment,
      onRetryPaymentDelivery: this.onRetryPaymentDelivery,
      onClickSupport: this.onClickSupport,
      onDecline: this.onDecline,
      onApprove: this.onApprove,
      goPayBill: this.goPayBill,
      onTrackDelivery: this.onTrackDelivery,
      isLoading,
      isEditMode,
      canSchedulePayment,
      isApproving,
    });

    const dueDate = bill.dueDate ? new Date(bill.dueDate) : null;
    const occurrences =
      recurringBill && !isNil(recurringBill.occurrences)
        ? recurringBill.occurrences.toString()
        : null;

    return (
      <ViewBillContainer>
        {isDeleting && this.renderDeleteModalDialog()}
        {isLoading && <AreaLoader />}
        {this.renderDeclineModalDialog()}
        {isVendorBlockedForPayment &&
          renderBlockedForPaymentDialog(site, onCloseBlockPaymentDialog)}
        {bill && !isLoading && (
          <SingleViewLoadingContainer
            isEditMode={isEditMode}
            className={isLoading ? 'loading' : ''}
          >
            {showPageHeader && (
              <QBRPageHeader
                backNav={{
                  pathname: backPath,
                  search: billsSearchPath,
                }}
                text={bill.vendor.companyName}
                subTitle={headerSubtitle}
                label={!canSchedulePayment ? 'bills.view.mobileTitle' : 'bills.view.title'}
                actionOptions={actionOptions}
              >
                {isFullSingleView && (
                  <BillDetailsHeader
                    companyName={bill.vendor.companyName}
                    isFullSingleView={isFullSingleView}
                    description={headerSubtitle}
                    status={status}
                    headerActions={headerActions}
                  />
                )}
              </QBRPageHeader>
            )}
            {!isFullSingleView && (
              <BillDetailsHeader
                companyName={bill.vendor.companyName}
                isFullSingleView={isFullSingleView}
                description={headerSubtitle}
                status={status}
                headerActions={headerActions}
              />
            )}
            <BillInfoContainer>
              {(statusInfo || status === PAYMENT_STATUS.FAILED || bill.payments.length > 0) && (
                <MICard mode="mainSingleScreen">
                  {statusInfo && (
                    <QBRBillStatus statusInfo={statusInfo} latestPayment={latestPayment} />
                  )}
                  {status === PAYMENT_STATUS.FAILED && this.renderErrorMessage()}
                  {bill.payments.length > 0 && (
                    <BillPaymentsActivity
                      bill={bill}
                      onDeletePayment={this.onDeletePayment}
                      goEditPayment={this.goEditPayment}
                      onRetryPayment={this.onRetryPayment}
                      onMarkBillAsPaid={this.onMarkBillAsPaid}
                      qbCashState={qbCashState}
                      notePlaceholder={notePlaceholder}
                    />
                  )}
                </MICard>
              )}
              <MICard>
                <PaymentDetails
                  onKeyDown={this.onKeyPressed}
                  canSchedulePayment={canSchedulePayment}
                >
                  <FormHeader>
                    <MiCardTitleWrapper>
                      <MICardTitle label="bills.view.title" />
                    </MiCardTitleWrapper>
                    {!isEditMode &&
                      !bill.internalBill &&
                      !isPaymentFailed &&
                      showBillInfoActions && (
                        <Actions showMarkAsPaid={showMarkAsPaid}>
                          {actionOptions.length > 0 && !manuallyPaid && (
                            <MIArrowActionsMenu actionOptions={actionOptions} />
                          )}
                        </Actions>
                      )}
                  </FormHeader>
                  <FormContent isEditMode={isEditMode}>
                    <BillFormContainer isEditMode={isEditMode}>
                      <QBOBillDetailsForm
                        isViewRecurring={!!recurringBill}
                        occurrences={occurrences}
                        frequency={recurringBill && recurringBill.frequency}
                        isDisabled={Boolean(isUploading)}
                        vendors={vendors}
                        intuitAccountsOptions={intuitAccountsOptions}
                        onFieldChange={onFieldChange}
                        totalAmount={bill.totalAmount}
                        vendorId={bill.vendorId ? bill.vendorId.toString() : null}
                        intuitAccountId={bill.intuitAccountId}
                        invoiceNumber={bill.invoiceNumber}
                        dueDate={dueDate}
                        note={bill.note}
                        validationErrors={validationErrors}
                        mode={mode}
                        billPageType={BILL_PAGE_TYPE.EDIT}
                        isRecurringBill={Boolean(recurringBill)}
                        isFirstWave={!!organizationPreferences.billPayFirstWaveUser}
                      />
                    </BillFormContainer>

                    {!isLoading && !isAttachmentLoading && (
                      <BillAttachment
                        qboBillAttachments={qboBillAttachments}
                        isDisabled={isUploading}
                        fileStorageUrl={fileStorageUrl}
                        filePreviewUrls={filePreviewUrls}
                        mode={mode}
                        onChangeAttachment={onChangeAttachment}
                        onDeleteAttachment={onDeleteAttachment}
                        eventPage="bills"
                        withCounter
                      />
                    )}
                  </FormContent>
                </PaymentDetails>
                {showTotalAmountSection && <BillDetailsTotal totalAmount={bill.totalAmount} />}
              </MICard>
              {isEditMode && (
                <MIFloatedEditDoneButtons
                  onDone={this.onEditBillFunc}
                  onCancel={this.onToggleMode}
                  doneLabel="bills.edit.save"
                  cancelLabel="bills.edit.cancel"
                  isDisabled={isUploading}
                  titleLabel="bills.new.edit"
                />
              )}
            </BillInfoContainer>
          </SingleViewLoadingContainer>
        )}
        {unpaidBill && !isEditMode && !isLoading && !isPaymentFailed && (
          <ButtonWrapper>
            <ButtonsContainer>
              <MIButton
                onClick={this.goPayBill}
                label="bills.new.schedulePayment"
                variant={CONSTS.BUTTON_VARIANT.PRIMARY}
                fullWidth
                disabled={isUploading}
                isProcessing={!'isProcessing && !isSaveAndCloseClicked'}
              />

              <MarkAsPaidButton
                onClick={isMarkAsPaid?.action}
                label={isMarkAsPaid?.label as string}
                variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                fullWidth
                isProcessing={!'isProcessing && isSaveAndCloseClicked'}
              />
            </ButtonsContainer>
          </ButtonWrapper>
        )}

        {!isLoading && (
          <ViewBillQBOFooterWrapper>
            <QBOFooterContainer />
          </ViewBillQBOFooterWrapper>
        )}
      </ViewBillContainer>
    );
  }
}

const mapStateToProps = (state: GlobalState, ownProps: Props): MapStateToProps => {
  const { companyName } = getCompanyInfo(state);

  return {
    orgId: getOrgId(state),
    permissions: profileStore.selectors.getPermissions(state),
    currentUser: profileStore.selectors.profile(state),
    approvalDecisionStatus: paymentsStore.selectors.approvalDecisionStatus(state),
    scheduledBy: usersStore.selectors.byId(getLatestPayment(ownProps.bill.payments)?.createdById)(
      state
    ),
    companyName,
    vendorPaymentPreferences: vendorsStore.selectors.checkVendorPaymentPreferences.item(
      state,
      ownProps.bill.vendorId
    ),
    organizationPreferences: getOrganizationPreferences(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...getPaymentsActions(dispatch),
  ...getBillListActions(dispatch),
  selectPaymentDates(
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    selectedId?: number
  ) {
    dispatch(
      selectPaymentDatesAction(
        scheduledDate,
        deliveryEta,
        maxDeliveryEta,
        selectedId as unknown as string
      )
    );
  },
  beginRegularPayBillFlow(id: string, paymentId: string) {
    dispatch(beginRegularPayBillFlowAction(id, paymentId));
  },
  checkVendorPaymentPreferences({ orgId, id }: CheckVendorPaymentPreferencesType) {
    const vendorActions = getStoreActions(vendorsStore)(dispatch);

    return vendorActions.checkVendorPaymentPreferences({ orgId, id });
  },
});

export default withTheme(
  compose(
    withBreak(),
    withNavigator(),
    withListContainer(),
    withNewBillData(),
    withSiteContext(),
    connect(mapStateToProps, mapDispatchToProps)
  )(QBRViewBill)
);

const ButtonsContainer = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    > button {
      margin: 1rem 0;
      width: 100%;
      height: 4.8rem;
      line-height: 4.8rem;
    }
  }
`;
const MarkAsPaidButton = styled(MIButton)`
  margin-top: 1rem;
`;
const ButtonWrapper = styled.div`
  display: none;
`;
const ViewBillContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PaymentDetails = styled(MICardForm)<{
  onKeyDown: (event: any) => void;
  canSchedulePayment: boolean;
}>`
  display: none;
`;

const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
    color: rgba(153, 153, 156, 1);
  }
`;

const FormContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${devices.mobile} {
    flex-direction: column-reverse;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${devices.mobile} {
    margin-left: 2.2rem;
  }

  @media ${devices.nonMobile} {
    width: 17.5rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    display: none;
  }
`;

const BillFormContainer = styled.div`
  width: 60%;
  margin-bottom: 2rem;
  margin-right: 1.6rem;

  @media ${devices.mobile} {
    width: 100%;
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
`;

const BillInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.mobile} {
    flex-direction: column-reverse;
  }
  overflow-y: scroll;
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: ${(props) => props.theme.text.size.regular};
`;

const MiCardTitleWrapper = styled.div`
  @media ${devices.mobile} {
    * {
      text-transform: unset;
    }
    * {
      font-size: 2.4rem;
    }
  }
`;

const ViewBillQBOFooterWrapper = styled.div`
  display: none;

  @media ${devices.mobile}, ${devices.phablet} {
    display: flex;
    justify-content: center;
  }
`;

const StyledNotificationCard = styled(MINotificationCard)`
  margin-bottom: 0.5rem;
  margin: 3rem 3.6rem 0;
`;
