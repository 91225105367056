import { generatePath } from 'react-router-dom';
import { pagesLocations } from 'src/app/version-2/externals';
import {
  DEFAULT_DASHBOARD_REDIRECT_PARAMS,
  ITEMS_PER_PAGE,
} from 'src/app/version-2/model/constants/dashboard';
import { ConfirmationOriginEnum, QBDashboardTabStatusEnum } from 'src/app/version-2/model/enums';
import { getArrayOfVendorIdToPaymentIdMapping } from 'src/app/version-2/utils/vendor.utils';

interface ItemIds {
  billId: string;
  paymentId?: string;
}
interface RedirectQuery {
  start: number;
  status: QBDashboardTabStatusEnum;
  limit: number;
  highlightedItemIds: string;
}
interface RedirectParams {
  itemIds?: ItemIds[];
  redirectQuery?: RedirectQuery;
  state?: Record<string, unknown>;
}

export const getScheduledPaymentsHandler = (
  paidPayments: { billId: string; id: string; amount: number }[]
) => {
  const totalAmount = paidPayments.reduce((total, payment) => total + Number(payment?.amount), 0);
  const countPayments = paidPayments?.length;

  return {
    totalAmount,
    countPayments,
  };
};

interface HistoryTypeParams {
  path: string;
  params?: Record<string, string | string>;
  state?: Record<string, unknown>;
  query?: Record<string, string | string> | RedirectParams;
}
export const convertToHistoryLocation = ({ path, params, state, query }: HistoryTypeParams) => ({
  pathname: generatePath(path, params),
  state,
  search: encodeQuery({ query: query || {}, excludeFields: [], baseSearch: '' }),
});

interface encodeQuery {
  query: Record<string, string | string> | RedirectParams;
  excludeFields: string[];
  baseSearch: string;
}
export const encodeQuery = ({ query, excludeFields, baseSearch }: encodeQuery) =>
  encodeURI(
    Object.keys(query)
      .filter((filterKey) => !excludeFields.some((field) => field === filterKey))
      .reduce((result, filterKey) => {
        if (query[filterKey] === undefined) {
          return result;
        }

        const filterSearchParam = `${filterKey}=${query[filterKey]}`;

        return result ? `${result}&${filterSearchParam}` : filterSearchParam;
      }, baseSearch)
  );

interface getDashboardItemIdParams {
  billId?: string;
  paymentId?: string;
}
export function getDashboardItemId({ billId, paymentId }: getDashboardItemIdParams): string {
  return `${billId}${parseInt(paymentId || '', 10) ? `_${paymentId}` : ''}`;
}

interface getDashboardListItemPaginationParams {
  billId: string;
  paymentId?: string;
  metadata: Record<string, string>;
}
export const getDashboardListItemPaginationParams = ({
  billId,
  paymentId,
  metadata,
}: getDashboardListItemPaginationParams) => {
  const isPaymentIdValid = paymentId && parseInt(paymentId || '', 10);
  const isBillIdValid = billId && parseInt(billId || '', 10);

  const redirectParams: RedirectQuery = DEFAULT_DASHBOARD_REDIRECT_PARAMS;

  try {
    if (isPaymentIdValid || isBillIdValid) {
      return {
        ...metadata,
        highlightedItemIds: getDashboardItemId({ billId, paymentId }),
        limit: ITEMS_PER_PAGE,
      };
    }
  } catch (e) {
    return redirectParams;
  }

  return redirectParams;
};

interface fetchRedirectQueryParams {
  itemIds: ItemIds[];
  metadata: Record<string, string>;
}
export const fetchRedirectQuery = ({ itemIds, metadata }: fetchRedirectQueryParams) => {
  const firstItem = itemIds?.[0];
  const highlightedItemIds = itemIds
    ?.map(({ billId, paymentId }) => getDashboardItemId({ billId, paymentId }))
    .join(',');
  const queryParams = getDashboardListItemPaginationParams({
    billId: firstItem?.billId,
    paymentId: firstItem?.paymentId,
    metadata,
  });

  return {
    ...queryParams,
    highlightedItemIds,
  };
};

export const getItemIds = (payments) =>
  payments?.map((payment) => ({
    billId: payment.billId,
    paymentId: payment.id,
    vendorId: payment.vendorId,
  }));

export const goQBDashboard = ({
  orgId,
  itemIds,
  paidPayments,
  billListModified,
  getScheduledPayments,
  query,
  history,
}) => {
  const batchPaymentIds = paidPayments?.map((item) => item.id);
  const url = generatePath(pagesLocations.qbDashboard.dashboard, { orgId });
  const state = {
    batchPaymentIds,
    billListModified,
    confirmationOrigin: ConfirmationOriginEnum.BATCH_PAYMENT,
    vendorsToPaymentsMapping: getArrayOfVendorIdToPaymentIdMapping(itemIds),
    scheduledPaymentsTextValues: getScheduledPayments(paidPayments),
  };

  history.push(
    convertToHistoryLocation({
      path: url,
      query,
      state,
    })
  );
};
