import React, { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Link, Menu, Popover, SmartIcon } from '@melio/billpay-design-system';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { CellContext } from '@tanstack/react-table';
import { analytics } from 'src/app/version-2/externals';
import { DeliveryEnum } from 'src/app/version-2/model/enums';
import { InvalidValueIndication } from 'src/app/version-2/pages/batch-bulk/components/InvalidValueIndication/InvalidValueIndication';
import {
  ADD_DELIVERY_METHOD_CLICK,
  BATCH_BULK_EVENT_PAGE,
  CHOOSE_DELIVERY_METHOD,
  INTERNATIONAL_DETAILS_MODAL_OPEN,
  OPEN_SELECT_DELIVERY_METHOD,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkPaymentIntentErrorsEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/BatchBulkPaymentIntentErrors.enum';
import {
  BatchBulkItem,
  DeliveryMethodItem,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  DeliveryMethodButton,
  DeliveryMethodMenuItem,
} from 'src/app/version-2/pages/batch-bulk/renderers/DeliveryMethod/components';
import {
  AddNew,
  AddNewContainer,
  DeliveryMethodMenuButton,
  DeliveryTitle,
  InternationalMenuButtonContainer,
} from 'src/app/version-2/pages/batch-bulk/renderers/DeliveryMethod/DeliveryMethod.renderer.styles';
import {
  ButtonWrapper,
  InfoIconTooltip,
  MenuButtonContainer,
  MissingDetailsPopoverText,
  MissingDetailsPopoverWrapper,
} from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';
import { shouldAllowEditDeliveryMethod } from 'src/app/version-2/utils/deliveryMethod.utils';

const DeliveryMethodRenderer: FC<CellContext<BatchBulkItem, DeliveryMethodItem>> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    row,
    row: {
      original: {
        id,
        deliveryMethods,
        selectedDeliveryMethod,
        selectedPaymentMethod,
        errors,
        vendorId,
        totalBillIds,
        totalPartialBillIds,
      },
    },
  } = props;

  const { formatMessage } = useIntl();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isSubRow = !!row.depth; // TODO: Denis - https://github.com/melio/billpay-web/pull/1081#discussion_r973694589

  const relevantErrors = useMemo(
    () => errors?.filter((error) => error.path === 'selectedPaymentMethod') || [],
    [errors]
  );

  const onChangeHandler = useCallback(
    (deliveryMethod: DeliveryMethodItem) => {
      dispatch(
        batchBulkActions.updateDeliveryMethod({
          selectedPaymentIntent: {
            paymentIntentId: row.original.id,
            deliveryMethod,
          },
        })
      );
      analytics.track(BATCH_BULK_EVENT_PAGE, CHOOSE_DELIVERY_METHOD, {
        fromTable: 'yes',
        billIds: totalBillIds,
        partialBillIds: totalPartialBillIds,
        vendorId,
        deliveryMethodId: deliveryMethod.id,
        deliveryMethodType: deliveryMethod.deliveryType,
      });
    },
    [row, totalBillIds, totalPartialBillIds, vendorId]
  );

  const createDeliveryMethod = useCallback(() => {
    dispatch(
      batchBulkActions.createDeliveryMethod({
        selectedPaymentIntent: {
          paymentIntentId: row.original.id,
          billId: row.original.billId || row.original?.subRows?.[0].id,
          selectedPaymentMethod,
          deliveryMethod: selectedDeliveryMethod,
        },
        history,
      })
    );
    analytics.track(BATCH_BULK_EVENT_PAGE, ADD_DELIVERY_METHOD_CLICK, {
      fromTable: 'yes',
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
    });
  }, [
    row,
    selectedPaymentMethod,
    selectedDeliveryMethod,
    history,
    totalBillIds,
    totalPartialBillIds,
    vendorId,
  ]);

  const menuTriggerHandler = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);

    if (!isMenuOpen) {
      analytics.track(BATCH_BULK_EVENT_PAGE, OPEN_SELECT_DELIVERY_METHOD, {
        fromTable: 'yes',
        billIds: totalBillIds,
        partialBillIds: totalPartialBillIds,
        vendorId,
      });
    }
  }, [setIsMenuOpen, isMenuOpen, totalBillIds, totalPartialBillIds, vendorId]);

  const openInternationalModal = useCallback(() => {
    dispatch(batchBulkActions.setInternationalModalAsOpen({ id }));
    analytics.track(BATCH_BULK_EVENT_PAGE, INTERNATIONAL_DETAILS_MODAL_OPEN, {
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
    });
  }, [totalBillIds, totalPartialBillIds, vendorId, id]);

  const isInternationalDetailsMissing = useMemo(
    () =>
      Boolean(
        errors?.find(
          (error) =>
            error.path === 'purpose' &&
            error.message === BatchBulkPaymentIntentErrorsEnum.INTERNATIONAL_DETAILS_MISSING
        )
      ),
    [errors]
  );

  const InternationalPopoverContent = () => (
    <Popover closeOnBlur placement="top" arrowColor="ds.white" closeDelay={400}>
      <Popover.PopoverTrigger>
        <InfoIconTooltip>
          <SmartIcon type="info" />
        </InfoIconTooltip>
      </Popover.PopoverTrigger>
      <Popover.PopoverContent>
        <MissingDetailsPopoverWrapper>
          <MissingDetailsPopoverText>
            {formatMessage({ id: 'batchBulkPage.popovers.internationalDetails.text' })}
          </MissingDetailsPopoverText>
          <ButtonWrapper>
            <Button variant="primary" size="md" onClick={openInternationalModal}>
              {formatMessage({ id: 'batchBulkPage.popovers.internationalDetails.button' })}
            </Button>
          </ButtonWrapper>
        </MissingDetailsPopoverWrapper>
      </Popover.PopoverContent>
    </Popover>
  );

  if (isSubRow) return null;

  if (!selectedDeliveryMethod) {
    return (
      <AddNewContainer>
        <AddNew onClick={createDeliveryMethod} data-testid="delivery-method">
          {formatMessage({
            id: 'batchBulkPage.renderers.addMethod',
          })}
        </AddNew>
        {relevantErrors && <InvalidValueIndication label="Missing delivery method" />}
      </AddNewContainer>
    );
  }

  if (selectedDeliveryMethod?.deliveryType === DeliveryEnum.INTERNATIONAL) {
    return (
      <InternationalMenuButtonContainer data-testid="delivery-method">
        <DeliveryTitle>{DeliveryEnum.INTERNATIONAL}</DeliveryTitle>
        {isInternationalDetailsMissing && <InternationalPopoverContent />}
      </InternationalMenuButtonContainer>
    );
  }

  if (!shouldAllowEditDeliveryMethod(selectedDeliveryMethod)) {
    return (
      <InternationalMenuButtonContainer data-testid="delivery-method">
        {formatMessage({
          id: 'batchBulkPage.renderers.deliveryMethod.deliveryMethodLabels.virtualCard',
        })}
      </InternationalMenuButtonContainer>
    );
  }

  return (
    <Menu
      placement="bottom-start"
      onOpen={menuTriggerHandler}
      onClose={menuTriggerHandler}
      autoWidth
    >
      <MenuButtonContainer data-testid="delivery-method">
        <Menu.MenuButton as={DeliveryMethodMenuButton}>
          <DeliveryMethodButton deliveryMethod={selectedDeliveryMethod} isMenuOpen={isMenuOpen} />
        </Menu.MenuButton>
      </MenuButtonContainer>
      <Menu.MenuList>
        {deliveryMethods?.map((deliveryMethod) => (
          <Menu.MenuItem
            onClick={() => onChangeHandler(deliveryMethod)}
            key={deliveryMethod.id}
            isDisabled={deliveryMethod.meta?.isDisabled}
            data-testid="delivery-method-button"
          >
            <DeliveryMethodMenuItem deliveryMethodItem={deliveryMethod} />
          </Menu.MenuItem>
        ))}
        <Menu.MenuItem>
          <Link onClick={createDeliveryMethod} data-testid="add-another-delivery-method-button">
            {formatMessage({
              id: 'batchBulkPage.renderers.deliveryMethod.addAnotherDeliveryMethod',
            })}
          </Link>
        </Menu.MenuItem>
      </Menu.MenuList>
    </Menu>
  );
};

export { DeliveryMethodRenderer };
