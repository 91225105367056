import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getOrgId } from 'src/app/redux/user/selectors';
import intuitBankAccountsStore from 'src/app/modules/intuit-bank-accounts/intuit-bank-accounts-store';

const useFetchIntuitBankAccounts = (forceReload = false): [] => {
  const intuitBankAccountsActions = useStoreActions(intuitBankAccountsStore);

  const orgId = useSelector(getOrgId);

  const status = useSelector(intuitBankAccountsStore.selectors.list.status({ orgId }));

  const [reloaded, setReloaded] = useState(forceReload);

  useEffect(() => {
    if (isEmpty(status) || reloaded) {
      setReloaded(false);
      intuitBankAccountsActions.list({ orgId });
    }
  }, [status, reloaded, intuitBankAccountsActions, orgId]);

  return [];
};

export default useFetchIntuitBankAccounts;
