import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import { Option } from '../../../types';
import {
  Container,
  ReasonContentContainer,
  ReasonTitleContainer,
} from './ReviewRefundBillInfoReason.styles';

type Props = {
  selectedReason: Option;
};

export const ReviewRefundBillInfoReason = ({ selectedReason }: Props) => (
  <Container>
    <ReasonTitleContainer>
      <MIFormattedText label="bills.pay.requestRefund.review.reason" />
    </ReasonTitleContainer>
    <ReasonContentContainer>
      <MIFormattedText label={`bills.pay.requestRefund.reason.reasons.${selectedReason?.id}`} />
    </ReasonContentContainer>
  </Container>
);
