import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import { featureFlags } from '@melio/shared-web';
import analytics from 'src/app/services/analytics';
import { getOrgId } from 'src/app/redux/user/selectors';
import {
  FeatureFlagsEnum,
  DeliveryEnum,
  RefundRequestSourceEnum,
} from 'src/app/version-2/model/enums';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import { getFailedPaymentDescription, getFailedPaymentTitle } from 'src/app/utils/payments';
import { AccountRecord } from 'src/app/pages/settings/records';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { BillType, PaymentType } from 'src/app/utils/types';
import locations from 'src/app/utils/locations';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import {
  isMissingPaymentDeliveryMethod,
  extractDeliveryMethodFromVendor,
} from 'src/app/version-2/utils/deliveryMethod.utils';
import { MIFormattedText } from 'src/app/utils/formatting';
import { getPaymentMethodName } from 'src/app/version-2/utils/paymentMethods.utils';
import { RefundLink } from './FailedPaymentMessage.styles';
import { loggingApi } from 'src/app/version-2/api/loggers';

type Props = {
  bill: RecordOf<BillType>;
  payment: PaymentType;
  className?: string;
  onRefundPayment?: () => void;
};

const eventPage = 'bills';

export const FailedPaymentMessage = ({ payment, bill, className, onRefundPayment }: Props) => {
  const history = useHistory();
  const [isQboRefundPaymentFF] = featureFlags.useFeature(FeatureFlagsEnum.QBO_REFUND_PAYMENT);
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const failedPaymentTitle = getFailedPaymentTitle(payment);
  const failedPaymentDescription = getFailedPaymentDescription(payment);
  const fundingSource = AccountRecord((payment as any)?.fundingSource);
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const shouldShowRefundLink =
    payment.metadata?.isPaymentRefundable && onRefundPayment && isQboRefundPaymentFF;

  useEffect(() => {
    if (shouldShowRefundLink) {
      const props = {
        paymentId: payment.id,
        organizationId: orgId,
        billId: bill.id,
      };

      analytics.page(eventPage, 'failed-payment-refund-suggestion', {
        ...props,
        isSelfServeEligible: payment.metadata?.isSelfServeRefundEligible,
      });

      if (isRefundFlowFeature) {
        analytics.track(eventPage, 'payment-details-get-refund-cta-show', props);
      }
    }
  }, [shouldShowRefundLink]);

  const handleRefundClick = useCallback(() => {
    const props = {
      paymentId: payment.id,
      billId: bill.id,
      organizationId: orgId,
    };

    loggingApi.info('FailedPaymentMessage.handleRefundClick(): refund request', {
      ...props,
      source: RefundRequestSourceEnum.link,
    });

    const actionKey = payment.metadata?.isSelfServeRefundEligible
      ? 'request-refund'
      : 'request-support-refund';

    analytics.trackAction(actionKey, props);

    if (isRefundFlowFeature) {
      analytics.track(eventPage, 'payment-details-get-refund-cta-link-click', props);
    }

    onRefundPayment?.();
  }, [onRefundPayment]);

  const onRetryPaymentDelivery = () => {
    analytics.track('RetryPayment', 'Clicked', {
      paymentId: payment?.id,
      deliveryMethodId: payment?.deliveryMethodId,
      buttonSource: 'disclaimer',
    });

    // missing delivery method in failed to deliver (changed/deleted after failed)
    const missingDeliveryMethod = isMissingPaymentDeliveryMethod(payment);
    const exitUrl = `${history.location.pathname}${history.location.search}`;

    if (missingDeliveryMethod) {
      const deliveryMethod = extractDeliveryMethodFromVendor({
        bill: bill as any,
        type: DeliveryEnum.ACH,
      });

      const redirectUrl = locations.Bills.pay.edit.confirm.url({
        id: bill.id,
        paymentId: payment.id,
        orgId,
      });

      historyPush({
        path: locations.Vendors.deliveryMethods.ach.edit.url({
          id: bill.vendor?.id,
          deliveryMethodId: deliveryMethod?.id,
          orgId,
        }),
        state: { redirectUrl, exitUrl },
      });
    } else {
      historyPush({
        path: locations.Bills.pay.edit.deliveryMethodAch.url({
          id: bill?.id,
          paymentId: payment?.id,
          deliveryMethodId: payment?.deliveryMethodId,
        }),
      });
    }
  };

  return (
    <QBONotificationCard
      className={className}
      type={NOTIFICATION_CARD_TYPES.ERROR}
      title={{ label: failedPaymentTitle }}
      showIcon={false}
      subtitle={{
        label: failedPaymentDescription,
        values: {
          fundingSource: getPaymentMethodName({ fundingSource, showDisplayName: false }),
          vendorCompanyName: bill.vendor.companyName,
          refund: shouldShowRefundLink ? (
            <MIFormattedText
              label="bills.form.paymentActivity.failedPaymentDescription.refund.requestRefund"
              values={{
                link: (
                  <RefundLink
                    label={`bills.form.paymentActivity.failedPaymentDescription.refund.link${
                      isRefundFlowFeature ? 'Get' : ''
                    }`}
                    onClick={handleRefundClick}
                  />
                ),
              }}
            />
          ) : null,
          retry: isRetryFailedToDeliver ? (
            <MIFormattedText
              label="bills.form.paymentActivity.failedPaymentDescription.retry.requestRetry"
              values={{
                link: (
                  <RefundLink
                    label="bills.form.paymentActivity.failedPaymentDescription.failedToDeliverACHRetryLink"
                    onClick={onRetryPaymentDelivery}
                  />
                ),
              }}
            />
          ) : null,
        },
      }}
    />
  );
};
