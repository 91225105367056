import React from 'react';
import styled from 'styled-components';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { ButtonVariantType } from 'src/app/utils/types';

type Props = {
  onButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  buttonLabel: string;
  secondaryButtonLabel: string;
  isLoading?: boolean;
  secondaryButtonVariant: ButtonVariantType;
};

const EditCheckAddressModalButtonRow: React.FC<Props> = ({
  onButtonClick,
  onSecondaryButtonClick,
  buttonLabel,
  secondaryButtonLabel,
  isLoading,
  secondaryButtonVariant,
}) => (
  <ButtonContainer secondaryButtonVariant={secondaryButtonVariant}>
    <MIButton
      onClick={onSecondaryButtonClick}
      label={secondaryButtonLabel}
      variant={secondaryButtonVariant}
      isProcessing={isLoading}
    />
    <MIButton
      onClick={onButtonClick}
      label={buttonLabel}
      variant={CONSTS.BUTTON_VARIANT.PRIMARY}
      disabled={isLoading}
    />
  </ButtonContainer>
);

export default EditCheckAddressModalButtonRow;

const ButtonContainer = styled.div<{ secondaryButtonVariant?: string }>`
  text-align: center;
  display: flex;
  justify-content: flex-end;

  > *:first-child {
    margin-right: ${(props) =>
      props.secondaryButtonVariant === CONSTS.BUTTON_VARIANT.CANCEL ? '0' : '2rem'};
  }
  ${(props) => props.theme?.components?.EditCheckAddressModalButtonRow?.ButtonContainer}}
`;
