import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-js/types/elements/elements';
import { useBasisTheory } from '@basis-theory/basis-theory-react';
import { AreaLoader, Button } from '@melio/billpay-design-system';
import { analytics } from 'src/app/version-2/externals';
import { BrandsLogos } from 'src/app/version-2/pages/add-card-account/components/CardDetails/brandsLogos/BrandsLogos';
import { CardDetails } from 'src/app/version-2/pages/add-card-account/components/CardDetails/CardDetails';
import {
  CardDetailsContainerWrapper,
  CardDetailsWrapper,
  LoaderWrapper,
  SubTitle,
  Title,
} from 'src/app/version-2/pages/add-card-account/components/CardDetails/CardDetails.styles';
import { SecurityComplianceMessage } from 'src/app/version-2/pages/add-card-account/components/SecurityComplianceMessage/SecurityComplianceMessage';
import {
  ADD_CARD_ACCOUNT_EVENT_PAGE,
  IFRAME_ERROR_RESPONSE,
  IFRAME_LOADED_SUCCESS,
} from 'src/app/version-2/pages/add-card-account/model/consts/AddCardAccountAnalytics.consts';
import { BASIS_THEORY_CARD_FIELD_IDS } from 'src/app/version-2/pages/add-card-account/model/enums/cardFieldIds.enum';
import {
  addCardAccountActions,
  addCardAccountSelectors,
} from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';
import { addCardAccountDeliveryMethodActions } from 'src/app/version-2/pages/add-card-account/modules/deliveryMethod/addCardAccountDeliveryMethod.slice';
import { addCardAccountFundingSourceActions } from 'src/app/version-2/pages/add-card-account/modules/fundingSource/addCardAccountFundingSource.slice';

export interface CardDetailsContainerProps {
  isTitle?: boolean;
  isFooter?: boolean;
}

export const CardDetailsContainer = ({ isTitle, isFooter }: CardDetailsContainerProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { bt } = useBasisTheory();
  const { formatMessage } = useIntl();

  const cardBrand = useSelector(addCardAccountSelectors.selectCardBrand);
  const hasError = useSelector(addCardAccountSelectors.selectHasError);
  const attemptedToLink = useSelector(addCardAccountSelectors.selectAttemptedToLink);
  const isReady = useSelector(addCardAccountSelectors.selectIsReady);
  const pageLoading = useSelector(addCardAccountSelectors.selectPageLoading);
  const isRequesting = useSelector(addCardAccountSelectors.selectIsRequesting);

  const cardNumberRef = useRef<CardNumberElement>();
  const cardExpirationRef = useRef<CardExpirationDateElement>();
  const cardVerificationRef = useRef<CardVerificationCodeElement>();

  const linkCardTokenization = useCallback(() => {
    if (!bt) {
      return;
    }

    try {
      if (cardNumberRef.current && cardExpirationRef.current && cardVerificationRef.current) {
        const btData = {
          number: cardNumberRef.current,
          expiration_month: cardExpirationRef.current.month(),
          expiration_year: cardExpirationRef.current.year(),
          cvc: cardVerificationRef.current,
        };

        dispatch(
          addCardAccountActions.linkCardTokenization({
            bt,
            btData,
            history,
          })
        );
      }
    } catch (e) {
      analytics.track(ADD_CARD_ACCOUNT_EVENT_PAGE, IFRAME_ERROR_RESPONSE);

      const error = {
        error: {
          code: 'VT4',
        },
      };

      dispatch(addCardAccountFundingSourceActions.setValidateCardStatus(error));
      dispatch(addCardAccountDeliveryMethodActions.setValidateCardStatus(error));
    }
  }, [bt]);

  useEffect(
    () => () => {
      dispatch(addCardAccountActions.resetState());
    },
    []
  );

  useEffect(() => {
    if (isReady) {
      analytics.track(ADD_CARD_ACCOUNT_EVENT_PAGE, IFRAME_LOADED_SUCCESS);

      dispatch(addCardAccountActions.pageLoading(false));
    }
  }, [isReady]);

  const attemptToLink = () => {
    dispatch(addCardAccountActions.attemptedToLink(true));
  };

  const handleReady = (fieldId) => {
    dispatch(
      addCardAccountActions.onReady({
        fieldId,
      })
    );
  };

  return (
    <>
      {pageLoading ? (
        <LoaderWrapper>
          <AreaLoader placement="melioMe" />
        </LoaderWrapper>
      ) : null}
      <CardDetailsContainerWrapper pageLoading={pageLoading} isTitle={isTitle}>
        {isTitle ?? (
          <>
            <Title>
              {formatMessage({
                id: 'linkCard.renderers.title',
              })}
            </Title>
            <SubTitle>
              {formatMessage({
                id: 'linkCard.renderers.subTitle',
              })}
            </SubTitle>
          </>
        )}
        <BrandsLogos selectedBrand={cardBrand} />
        <CardDetailsWrapper>
          <CardDetails
            cardNumberFieldId={BASIS_THEORY_CARD_FIELD_IDS.CARD_NUMBER}
            cardExpirationDateFieldId={BASIS_THEORY_CARD_FIELD_IDS.EXPIRATION_DATE}
            cardVerificationCodeFieldId={BASIS_THEORY_CARD_FIELD_IDS.VERIFICATION_CODE}
            onReady={handleReady}
            attemptedToLink={attemptedToLink}
            refs={{ cardNumberRef, cardExpirationRef, cardVerificationRef }}
          />
        </CardDetailsWrapper>
        <Button
          variant="primary"
          size="md"
          isLoading={isRequesting}
          mb="ds.xl"
          onClick={!hasError ? linkCardTokenization : attemptToLink}
          data-testid="tbt-button-link"
        >
          {formatMessage({ id: 'linkCard.renderers.buttons.linkCardDetailsCTA' })}
        </Button>
        {isFooter ?? <SecurityComplianceMessage />}
      </CardDetailsContainerWrapper>
    </>
  );
};
