import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  goViewDetails?: () => void;
};

const ViewDetailsLink = ({ goViewDetails }: Props) => (
  <ViewDetails onClick={goViewDetails}>
    <MIFormattedText label="bills.form.partialPayments.viewDetails" />
  </ViewDetails>
);

export default ViewDetailsLink;

const ViewDetails = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.primary.opaque};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
`;
