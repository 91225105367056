const bills = '/orgs/:orgId/bills';
const vendors = '/orgs/:orgId/vendors';
const internationalEntrypoint = '/orgs/:orgId/international';

const LOCATIONS = {
  base: [bills, vendors],
  bills,
  vendors,
  internationalEntrypoint,
};

export default LOCATIONS;
