import { useEffect, useCallback, useState } from 'react';
import head from 'lodash/head';
import { useApi } from 'src/app/hoc/useApi';
import { ENTRY_ACTION } from 'src/app/pages/quickpay/consts';
import { getInitialProcessingDates } from 'src/app/utils/dates';
import intuit from 'src/app/utils/intuit';
import { useSelector } from 'react-redux';
import { Loader } from '@melio/billpay-design-system';
import { getOrgId } from 'src/app/redux/user/selectors';
import useSyncQBCashFundingSource from 'src/app/modules/funding-sources/hooks/useSyncQBCashFundingSource';
import { generatePath, Redirect } from 'react-router-dom';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import organizationsApi from 'src/app/services/api/organizations';
import { useQueryString } from 'src/app/utils/hooks';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { melioLoaded } from 'src/app/utils/external-events';
import { OrganizationPreferencesType } from 'src/app/utils/types';

import locations from '../locations';
import { QuickPayment } from '../../../modules/payments/quickpay-slice';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';

const CreateEntryPage = () => {
  const query = useQueryString();
  const orgId = useSelector(getOrgId);
  const [canRedirect, setCanRedirect] = useState(false);
  const { vendorId, totalAmount, note, currency } = query;
  const currentNote = note === ':note' ? '' : note;
  const currentCurrency = currency === ':currency' ? 'USD' : currency;
  const payment: QuickPayment = useSelector(
    paymentsStore.selectors.quickpay.newPaymentWizard.payment
  );
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const vendorIdValue = (vendorId as string) || payment?.vendorId;
  const [syncCompanyInfo] = useApi(organizationsApi.syncCompanyInfo, false);
  const { vendor } = useFetchVendor(vendorIdValue);
  const { fundingSource } = useSyncQBCashFundingSource();
  const paymentActions = useStoreActions(paymentsStore);
  const setInitialPaymentDetails = useCallback(async () => {
    if (vendor && fundingSource) {
      const deliveryMethodId = head(vendor.deliveryMethods)?.id;

      if (deliveryMethodId) {
        const { suggestedScheduledDate, deliveryDate, maxDeliveryDate } =
          await getInitialProcessingDates({
            orgId,
            deliveryMethodId: Number.parseInt(deliveryMethodId, 10),
            fundingSourceId: fundingSource.id,
            scheduledDate: new Date(),
            amount: totalAmount,
            undefined,
            paymentId: payment.id,
            payBillFlowUUID: payment.payBillFlowUUID,
            isFirstWave: !!organizationPreferences?.billPayFirstWaveUser,
          });

        paymentActions.quickpay.newPaymentWizard.new({
          vendorId: vendor.id,
          totalAmount,
          note: currentNote,
          currency: currentCurrency,
          scheduledDate: suggestedScheduledDate,
          deliveryEta: deliveryDate,
          maxDeliveryEta: maxDeliveryDate,
          fundingSourceId: fundingSource.id,
          deliveryMethodId,
        });
      } else {
        // need to create delivery method for vendor
        paymentActions.quickpay.newPaymentWizard.new({
          vendorId: vendor.id,
          totalAmount,
          note: currentNote,
          currency: currentCurrency,
          fundingSourceId: fundingSource.id,
        });
      }

      setCanRedirect(true);
    }
  }, [
    paymentActions.quickpay.newPaymentWizard,
    orgId,
    totalAmount,
    currentNote,
    currentCurrency,
    vendor,
    fundingSource,
  ]);

  useEffect(() => {
    syncCompanyInfo(orgId);
  }, [orgId]);
  useEffect(() => {
    if (!canRedirect && vendor && fundingSource) {
      setInitialPaymentDetails();
    }
  }, [setInitialPaymentDetails, canRedirect]);

  useEffect(() => {
    if (canRedirect) {
      intuit.endLoadingWrapper();
      melioLoaded();
    }
  }, [canRedirect]);

  if (canRedirect) {
    if (!vendor.hasDeliveryMethods) {
      return (
        <Redirect
          to={{
            pathname: generatePath(locations.create.deliveryMethod, {
              orgId,
              vendorId: vendorId as string,
            }),
            state: { name: 'initialState' },
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: generatePath(locations.create.confirm, { orgId }),
          state: { entry: ENTRY_ACTION.CONFIRM },
        }}
      />
    );
  }

  return <Loader />;
};

export default CreateEntryPage;
