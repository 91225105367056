import qboSite from 'src/app/sites/qboSite';
import merge from 'lodash/merge';
import { PaymentCreateFlowOriginEnum, RegistrationOriginEnum } from 'src/app/version-2/model/enums';
import QBMVirtualDeliveryMethodPageContainer from 'src/app/pages/vendor/delivery-methods/QBMVirtualDeliveryMethodPageContainer';

const qbmIosSite = merge({}, qboSite, {
  name: 'qbmIos',
  check2fa: false,
  createOrigin: {
    pay: {
      payment: PaymentCreateFlowOriginEnum.QBM_IOS,
    },
  },
  registrationOrigin: RegistrationOriginEnum.QUICKBOOKS_QBM_IOS,
  redirectToDashboardOnCloseCreateBill: true,
  hasFastAch: true,
  components: {
    VirtualDeliveryMethodPageContainer: QBMVirtualDeliveryMethodPageContainer,
  },
  useNativeMobileDatePicker: false,
});

export default qbmIosSite;
