const LOCATIONS = {
  base: [
    '/vendors/shift-virtual-card/:token',
    '/vendors/shift-virtual-card/:token/invalid-link',
    '/vendors/shift-virtual-card/:token/details-was-shown',
    '/vendors/shift-virtual-card/:token/notification',
  ],
  details: '/vendors/shift-virtual-card/:token',
  invalidToken: '/vendors/shift-virtual-card/:token/invalid-link',
  notification: '/vendors/shift-virtual-card/:token/notification',
};

export default LOCATIONS;
