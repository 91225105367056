import React from 'react';
import { compose } from 'recompose';
import { RecordOf } from 'immutable';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import analytics from 'src/app/services/analytics';
import { withNavigator } from 'src/app/hoc';
import { GlobalState } from 'src/app/redux/types';
import locations from 'src/app/utils/locations';
import { BillType } from 'src/app/utils/types';
import billsApi from 'src/app/services/api/bills';
import { getOrgId } from 'src/app/redux/user/selectors';
import { getBill, getSelectedFundingSource } from 'src/app/redux/payBillWizard/selectors';
import { updateBillAction } from 'src/app/redux/payBillWizard/actions';
import { getPartialBillId, isBillAmountRequiresGoodsConfirmation } from 'src/app/utils/bills';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import ExpeditePaymentPage from './components/ExpeditePaymentPage';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

type MapStateToProps = {
  bill: RecordOf<BillType>;
  orgId: string;
  selectedFundingSource: FundingSource | null;
};

type MapDispatchToProps = {
  updateBill: (goodsReceived: boolean) => void;
};

type State = {
  selectedId: string | null;
  isUpdateBillProcessing: boolean;
};

type Props = PayBillProps &
  MapStateToProps &
  MapDispatchToProps & {
    site: any;
  };

const eventPage = 'expedite-payment-page';

class ExpeditePaymentPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedId: null,
      isUpdateBillProcessing: false,
    };
  }

  componentDidMount() {
    const { navigate, bill, selectedFundingSource } = this.props;

    if (
      !isNil(bill.goodsReceived) ||
      !isBillAmountRequiresGoodsConfirmation(bill.totalAmount) ||
      (selectedFundingSource && selectedFundingSource.fundingType !== FundingSourceTypesEnum.CARD)
    ) {
      navigate(locations.Bills.pay.confirm.url({ id: bill.id }));
    } else {
      analytics.page('pay-bill', 'expedite-payment');
    }
  }

  onChange = (id: string) => {
    const { bill } = this.props;
    const answer = id === '0' ? 'no' : 'yes';

    analytics.track(eventPage, `change-product-received-answer-${answer}`, {
      partialBillId: getPartialBillId(bill),
    });
    this.setState({ selectedId: id });
  };

  onNext = () => {
    const { orgId, bill, onNext, updateBill } = this.props;
    const { selectedId } = this.state;

    analytics.track(eventPage, 'continue', {
      partialBillId: getPartialBillId(bill),
    });
    const fileId = !isEmpty(bill.files) ? [bill.files[0].id] : [];

    this.setState({ isUpdateBillProcessing: true });
    const goodsReceived = selectedId ? !!+selectedId : false;

    updateBill(goodsReceived);

    const newBill = {
      totalAmount: bill.totalAmount,
      balance: bill.balance,
      currency: bill.currency,
      invoiceDate: bill.invoiceDate,
      dueDate: bill.dueDate,
      invoiceNumber: bill.invoiceNumber,
      note: bill.note,
      vendorId: bill.vendorId,
      terms: bill.terms,
      intuitAccountId: bill.intuitAccountId,
      goodsReceived,
      files: fileId,
    };

    billsApi
      .editBillById(orgId, bill.id, newBill, 'all')
      .then(() => {
        this.setState({ isUpdateBillProcessing: false });
        onNext();
      })
      .catch(() => {
        this.setState({
          isUpdateBillProcessing: false,
        });
      });
  };

  render() {
    const { selectedId, isUpdateBillProcessing } = this.state;
    const { goExit, onPrev } = this.props;

    return (
      <ExpeditePaymentPage
        onNext={this.onNext}
        onChange={this.onChange}
        goExit={goExit}
        onPrev={onPrev}
        selectedId={selectedId}
        isLoading={isUpdateBillProcessing}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  bill: getBill(state),
  orgId: getOrgId(state),
  selectedFundingSource: getSelectedFundingSource(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  updateBill(goodsReceived: boolean) {
    dispatch(updateBillAction(goodsReceived));
  },
});

export default compose(
  withNavigator(),
  withPayBillData(),
  withSiteContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(ExpeditePaymentPageContainer);
