import locations from 'src/app/utils/locations';
import { getPaymentTag, isPaymentFailedRefund, isRefundPaymentFlow } from 'src/app/utils/payments';
import { PaymentType } from 'src/app/utils/types';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { PAYMENT_STATUS } from 'src/app/utils/consts';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';

import PaymentListDesign from './PaymentListDesign';

const getStatusText = (status: string, isMarkedAsPaid: boolean, isRefundFlow: boolean): string => {
  if (isMarkedAsPaid) return 'bills.view.markAsPaidInfo';

  if (status === PAYMENT_STATUS.COMPLETED || status === PAYMENT_STATUS.IN_PROGRESS)
    return 'bills.view.processed';

  if (status === PAYMENT_STATUS.FAILED) {
    if (isRefundFlow) {
      return 'bills.form.paymentActivity.refund.sent';
    }

    return 'bills.form.paymentActivity.failedPayment';
  }

  return 'bills.view.scheduledOn';
};

const getButtonText = (status: string, isRefundFlow: boolean): string => {
  if (status === PAYMENT_STATUS.FAILED && !isRefundFlow)
    return 'bills.form.partialPayments.reviewAndResolve';

  return 'bills.form.partialPayments.review';
};

type Props = {
  payment: PaymentType;
  orgId: string;
  billId: string;
  currentPaymentId: string;
  historyState: Record<string, any>;
};

const PaymentListItem = ({ payment, orgId, billId, currentPaymentId, historyState }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const isRefundFlow = isRefundPaymentFlow(payment) || isPaymentFailedRefund(payment);
  const { amount, id, status } = payment;
  const { date } = getPaymentTag(payment as any);
  const isCurrent = String(id) === String(currentPaymentId);
  const buttonText = getButtonText(status, isRefundFlow); // review text variants

  const nextUrl = locations.Bills.payment.url({
    billId,
    paymentId: id,
    orgId,
  });

  // flag for current payment in tooltip
  const isMarkedAsPaid = payment?.manual;

  const renderReviewButton = (): React.ReactNode => {
    if (isCurrent) {
      // current text without link
      return (
        <span className="current">
          <MIFormattedText label="bills.form.partialPayments.current" />
        </span>
      );
    }

    return (
      <button
        type="button"
        className="button"
        onClick={() => historyPush({ path: nextUrl, state: historyState })}
      >
        <MIFormattedText label={buttonText} />
      </button>
    );
  };

  return (
    <PaymentListDesign $isRefundFlow={isRefundFlow} $status={status}>
      <div className="status">
        <MIFormattedText
          label={getStatusText(status, isMarkedAsPaid, isRefundFlow)}
          values={{
            date: (
              <span className="date">
                <MIFormattedDate date={date} />
              </span>
            ),
          }}
        />

        {/* renders current text or review link button */}
        {renderReviewButton()}
      </div>
      <div className="amount">
        <MIFormattedCurrency value={amount || ''} />
      </div>
    </PaymentListDesign>
  );
};

export default PaymentListItem;
