export const content = {
  m: '0 1.6rem',
  p: '1.6rem 5.6rem 1.6rem 1.6rem',
  bgColor: 'ds.black',
  border: 'solid 0.1rem',
  borderColor: 'ds.black',
  borderRadius: 'ds.lg',
  sx: {
    '--popper-arrow-size': '1.132rem',
    '--popper-arrow-bg': 'var(--chakra-colors-ds-black)',
  },
};

export const close = {
  top: '1.6rem',
  right: '1.6rem',
  w: '2.4rem',
  h: '2.4rem',
  border: 'none',
  bgColor: 'transparent',
  color: 'ds.gray.300',
  __css: {
    '.chakra-icon': {
      w: '1.2rem',
      h: '1.2rem',
    },
  },
};

export const body = {
  p: '0',
  textStyle: 'ds.body2',
  color: 'ds.white',
  fontWeight: 'ds.regular',
  wordBreak: 'break-word' as any,
};
