import { featureFlags } from '@melio/shared-web';
import { DeliveryOption } from 'src/app/version-2/model/dtos/deliveryOption';
import { PaymentFeeItem } from 'src/app/version-2/model/dtos/paymentFeeItem';
import {
  CheckFeeEnum,
  DeliveryEnum,
  FeatureFlagsEnum,
  FundingSourceTypesEnum,
} from 'src/app/version-2/model/enums';
import {
  INTERNATIONAL_FAST_FEE,
  INTERNATIONAL_FEE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/international.consts';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import { BatchBulkPaymentIntentItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { isCheckFeeOpenWithPromotions } from 'src/app/version-2/utils/checkFees.utils';
import { isFastDeliveryType } from 'src/app/version-2/utils/deliveryMethod.utils';
import { isAchCheckFeeType } from 'src/app/version-2/utils/fee.utils';

export const extractPaymentFees = (paymentIntent: BatchBulkPaymentIntent) => {
  const { deliveryPreference, deliveryMethod } = paymentIntent?.payment || {};

  const feeOptions = paymentIntent?.deliveryOptions?.find((option) =>
    deliveryPreference ? option.type === deliveryPreference : !isFastDeliveryType(option.type)
  );

  const feeOption = feeOptions?.fee?.find((option) => !isFastDeliveryType(option.feeType));
  const fastFeeOption = feeOptions?.fee?.find((option) => isFastDeliveryType(option.feeType));
  const isInternational = deliveryMethod?.deliveryType?.includes(DeliveryEnum.INTERNATIONAL);

  return {
    fee: feeOption?.amount,
    feeType: feeOption?.feeCatalog?.feeType || feeOption?.feeType,
    fastFee: isInternational ? INTERNATIONAL_FAST_FEE : fastFeeOption?.amount,
    internationalFee: isInternational ? INTERNATIONAL_FEE : 0,
  };
};

const isBatchAchCheck = (payment: BatchBulkPaymentIntentItem) => {
  const fundingSource = payment.paymentMethods?.find(
    (fs) => fs.id === payment?.selectedPaymentMethod?.id
  );
  const isAch = fundingSource?.fundingType?.includes(FundingSourceTypesEnum.ACH);
  const isFastChecks = payment.fastFee || isFastDeliveryType(payment.feeType);
  const isCheck = payment.selectedDeliveryMethod?.deliveryType?.includes(DeliveryEnum.CHECK);

  return isCheck && isAch && !isFastChecks;
};

interface ModifyItemsCheckPromotions {
  items: BatchBulkPaymentIntentItem[];
  freeChecks?: number;
  checkFee: number;
}

export const modifyItemsCheckPromotions = ({
  items,
  freeChecks = 0,
  checkFee,
}: ModifyItemsCheckPromotions) => {
  const checkFeeFlag = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.CHECK_FEES,
    CheckFeeEnum.CLOSED
  );
  const checkFeeEnabledWithPromotions = isCheckFeeOpenWithPromotions(checkFeeFlag);

  // apply to promotions only
  if (!checkFeeEnabledWithPromotions) return items;

  let availableFreeChecks = freeChecks;

  const modifiedList = items.map((item) => {
    if (!isBatchAchCheck(item)) return item;

    const newItem = { ...item };

    if (availableFreeChecks > 0) {
      // free check
      availableFreeChecks -= 1;

      newItem.fee = 0;
      newItem.freeCheck = true;

      return newItem;
    }

    // charge check
    newItem.fee = checkFee;
    newItem.freeCheck = false;
    newItem.feeType = 'ach-to-check';

    return newItem;
  });

  return modifiedList;
};

export const mapCalendarFees = (deliveryOptions: DeliveryOption[]) => {
  const result = deliveryOptions.map((dm: DeliveryOption) => {
    const fee = dm.fee?.find((fee: PaymentFeeItem) => {
      // check has a different feeType, ach-to-check
      if (dm.type === DeliveryEnum.CHECK && isAchCheckFeeType(fee?.feeCatalog?.feeType))
        return true;

      return fee.feeType === dm.type;
    });

    const newDm = { ...dm };

    if (fee) {
      // updating correct fast fee
      newDm.feeType = fee.feeType || fee.feeCatalog?.feeType || '';
      newDm.amount = fee.amount;
      newDm.isFixed = fee.isFixed || fee.feeCatalog?.valueType === 'fixed';
      newDm.percent = fee.percent;
    }

    return newDm;
  });

  return result;
};
