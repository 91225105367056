import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 2rem 0;
  font-weight: 500;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.ds.gray[200]};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2};
`;

export const Content = styled.div`
  ${({ theme }) => theme.textStyles.ds.body1};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  line-height: 2rem;
`;

export const Subtitle = styled.div`
  ${({ theme }) => theme.textStyles.ds.body3};
  color: ${({ theme }) => theme.colors.ds.gray[300]};
  font-weight: 400;
`;

export const IconComponentContainer = styled.div`
  & svg path {
    fill: ${({ theme }) => theme.colors.ds.gray[200]};
  }
`;
