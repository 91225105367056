import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';

export const LogoContainer = styled.div`
  text-align: center;
  margin: 1rem 0 2rem;
`;

export const InfoCard = styled.div<{ isMobile: boolean; isVirtualPayment: boolean }>`
  background-color: ${({ theme }) => theme.colors.ds.white};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile, isVirtualPayment }) =>
    isMobile ? `1.6rem 1.6rem ${isVirtualPayment ? '4rem' : '2rem'}` : '4rem'};
  grid-gap: 0.5rem;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0.8rem;
  border-color: ${({ theme }) => theme.colors.ds.gray[500]};
  margin-bottom: ${({ isMobile }) => isMobile && '3rem'};
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  left: 2rem;
  top: 1.6rem;
`;

export const MobileButtonsContainer = styled(Box)`
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.ds.white};
  padding: 2rem 1.6rem;
`;
