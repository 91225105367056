import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';

import styled from 'styled-components';
import MIButton from 'src/app/components/common/MIButton';
import { AreaLoader } from '@melio/billpay-design-system';
import OutsideLayout from 'src/app/components/layout/OutsideLayout';
import MIRadioGroup, { Option } from 'src/app/components/form/MIRadioGroup';
import profileStore from 'src/app/modules/profile/profile-store';
import { checkAndInitUserAction } from 'src/app/redux/user/actions';
import { getOrgId } from 'src/app/redux/user/selectors';
import { Header, ContentWrapper } from 'src/app/pages/vendor/components/VendorLayoutElements';
import { CONSTS } from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import vendorsLocations from '../locations';
import { UnilateralPaymentProps, UnilateralPaymentState } from './hoc/unilateral-payment-hooks';

type Props = {
  state: UnilateralPaymentState;
} & UnilateralPaymentProps;

const VendorSwitchCompanyPage = (props: Props) => {
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    state: { isPaymentLoading, payment, organization, filesUrls },
    token,
  } = props;
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(orgId);

  const options: Option<string>[] = useMemo(
    () =>
      organizations.map((o) => ({
        id: o.id,
        label: 'companies.acceptRequest.companyOption',
        labelValues: { companyName: o.companyName },
      })),
    [organizations]
  );

  useEffect(() => {
    if (payment) {
      analytics.trackAction('add-delivery-method-switch-company-page-loaded', {
        vendorId: payment.vendor.id,
        paymentId: payment.id,
        vendorEmail: payment.vendor?.contactEmail,
        payorOrgId: payment.organization.id,
        isEligibleToVirtualCard: payment.isEligibleToVirtualCard,
      });
    }
  }, [payment]);

  const initUserContext = (orgId) =>
    new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
    });

  const onCompanySwitch = async () => {
    if (orgId !== selectedOrganizationId) {
      await initUserContext(selectedOrganizationId);
    }

    history.push(generatePath(vendorsLocations.unilateral.addMethod, { token }));
  };

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return (
    <OutsideLayout>
      <Header
        payment={payment}
        organization={organization}
        filesUrls={filesUrls}
        showFullHeader
        note={payment?.note}
        subTitle="vendors.addDeliveryMethodByLink.subTitle"
      />
      <ContentWrapper title="vendors.addDeliveryMethodByLink.switchCompanyTitle">
        <SelectOrganizationsWrapper>
          <MIRadioGroup
            id="organization"
            colDirection
            group="organization"
            options={options}
            selected={selectedOrganizationId}
            onSelect={setSelectedOrganizationId}
          />
        </SelectOrganizationsWrapper>
        <MIButton
          onClick={onCompanySwitch}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label="progress.continue"
          fullWidth
        />
      </ContentWrapper>
    </OutsideLayout>
  );
};

const SelectOrganizationsWrapper = styled.div`
  padding: 2rem 0 4rem;
  overflow: auto;
  max-height: 40rem;
`;

export default VendorSwitchCompanyPage;
