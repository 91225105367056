import { createStandaloneToastFactory, ToastTypeEnum } from '@melio/billpay-design-system';
import { defaultOptions, ToastsApi } from './toasts.consts';
import { pushToast } from './toasts.utils';

const toast = createStandaloneToastFactory({ defaultOptions });

export const toastsApi: ToastsApi = {
  show(toastParams) {
    return pushToast(toast, toastParams);
  },
  success(toastParams) {
    return this.show({ ...toastParams, type: ToastTypeEnum.SUCCESS });
  },
  error(toastParams) {
    return this.show({ ...toastParams, type: ToastTypeEnum.ERROR });
  },
  closeToast(id) {
    toast.close(id);
  },
  updateToast(id, options) {
    toast.update(id, options);
  },
  isActiveToast(id) {
    return toast.isActive(id) ?? false;
  },
};
