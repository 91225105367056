import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import QBODueDateCell from '../components/QBODataTable/components/QBODueDateCell';
import QBOVendorNameCell from '../components/QBODataTable/components/QBOVendorNameCell/QBOVendorNameCell';
import { QBOInvoiceNumberCell } from '../components/QBODataTable/components/QBOInvoiceNumberCell';
import QBOUnpaidStatusCell from '../components/QBODataTable/components/QBOUnpaidStatusCell';
import QBOAmountCell from '../components/QBODataTable/components/QBOAmountCell';
import { QBOUnpaidActionCell } from '../components/QBODataTable/components/QBOUnpaidActionCell';
import { DashboardListItemType } from '../types';
import { ColumnItem } from '../components/QBODataTable/types';
import { DASHBOARD_COLUMNS } from '../consts';
import { QBOAmountInputCell } from '../components/QBODataTable/components/QBOAmountInputCell';
import { QBOOpenBalanceCell } from '../components/QBODataTable/components/QBOOpenBalanceCell';

type ResponseType = [ColumnItem<DashboardListItemType>[]];

function useUnpaidColumns(): ResponseType {
  const { isPartialPaymentsEnabled, isPartialPaymentsUIEnabled } = usePartialPaymentsEnabled();
  const unpaidColumns: ColumnItem<DashboardListItemType>[] = [
    {
      Header: <MIFormattedText label="paymentDashboard.columns.vendor.title" />,
      accessor: DASHBOARD_COLUMNS.VENDOR_NAME,
      Cell: QBOVendorNameCell,
    },
    {
      Header: <MIFormattedText label="paymentDashboard.columns.invoiceNumber.title" />,
      accessor: DASHBOARD_COLUMNS.INVOICE_NUMBER,
      Cell: QBOInvoiceNumberCell,
    },
    {
      Header: <MIFormattedText label="paymentDashboard.columns.dueDate.title" />,
      accessor: DASHBOARD_COLUMNS.DUE_DATE,
      Cell: QBODueDateCell,
    },
    {
      Header: <MIFormattedText label="paymentDashboard.columns.unpaidStatus.title" />,
      accessor: DASHBOARD_COLUMNS.STATUS,
      Cell: QBOUnpaidStatusCell,
    },
  ];

  if (isPartialPaymentsEnabled || isPartialPaymentsUIEnabled) {
    unpaidColumns.push(
      {
        Header: <MIFormattedText label="paymentDashboard.columns.billAmount.title" />,
        accessor: DASHBOARD_COLUMNS.BILL_AMOUNT,
        Cell: QBOAmountCell,
        isNumeric: true,
      },
      {
        Header: <MIFormattedText label="paymentDashboard.columns.openBalance.title" />,
        accessor: DASHBOARD_COLUMNS.BALANCE,
        Cell: QBOOpenBalanceCell,
        isNumeric: true,
      }
    );
  }

  if (isPartialPaymentsUIEnabled) {
    unpaidColumns.push({
      Header: <MIFormattedText label="paymentDashboard.columns.payment.title" />,
      accessor: DASHBOARD_COLUMNS.PAYMENT,
      Cell: QBOAmountInputCell,
      isOrderingDisabled: true,
      isNumeric: true,
      width: '16.8rem',
    });
  }

  if (!isPartialPaymentsEnabled && !isPartialPaymentsUIEnabled) {
    unpaidColumns.push({
      Header: <MIFormattedText label="paymentDashboard.columns.amount.title" />,
      accessor: DASHBOARD_COLUMNS.TOTAL_AMOUNT,
      Cell: QBOAmountCell,
      isNumeric: true,
    });
  }

  unpaidColumns.push({
    Header: <MIFormattedText label="paymentDashboard.columns.actions.title" />,
    accessor: DASHBOARD_COLUMNS.ACTIONS,
    width: '18rem',
    Cell: QBOUnpaidActionCell,
    isNumeric: true,
    isOrderingDisabled: true,
  });

  return [unpaidColumns];
}

export default useUnpaidColumns;
