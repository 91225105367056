import { Record } from 'immutable';
import { VerifiedStatusEnum } from 'src/app/version-2/model/enums';
import { DeliveryMethodType, OptionalDeliveryMethodsType, VendorType } from '../../utils/types';
import { CONSTS } from '../../utils/consts';
import { addressDefaults } from '../settings/records';
import {
  getDeliveryMethodByType,
  getDeliveryMethodDisplayName,
  getDeliveryMethodInfo,
} from './records';
import { getVendorType } from '../../utils/vendor-utils';

export const DeliveryMethodRecord = Record<DeliveryMethodType>(
  {
    id: '',
    logo: '',
    deliveryType: CONSTS.DELIVERY_TYPE.ACH,
    paperCheck: null,
    bankAccount: null,
    plaidAccount: null,
    hasScheduledPayments: false,
    isFilledByVendor: false,
    isVerified: false,
    verifiedStatus: VerifiedStatusEnum.NOT_VERIFIED,
    createdById: '',
    updatedAt: null,
    createdAt: null,
    cardAccountId: null,
    intuitAccountId: null,
    virtualCardAccount: null,
    internationalAccount: null,
    getDisplayName(vendorName = '') {
      return getDeliveryMethodDisplayName({ deliveryMethod: this, vendorName });
    },

    getDeliveryInfo(vendorName = '') {
      return getDeliveryMethodInfo({ deliveryMethod: this, vendorName });
    },

    getDeliveryTypeDescription() {
      return this.deliveryType === CONSTS.DELIVERY_TYPE.ACH
        ? 'deliveryMethods.bank'
        : 'deliveryMethods.check';
    },
    virtualAccount: null,
    managedBy: undefined,
  },
  'DeliveryMethodRecord'
);
export const VendorRecord = Record<VendorType>(
  {
    id: NaN,
    origin: '',
    originId: '',
    companyName: '',
    accountIdentifier: '',
    address: { ...addressDefaults },
    deliveryMethods: [],
    updatedById: '',
    createdAt: undefined,
    createdById: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    paymentRequestId: '',
    managedBy: null,
    ownedById: null,
    isOwned: false,
    isEditable: false,
    handle: '',
    hasDeliveryMethods: false,
    vendorTrusted: false,
    type: getVendorType(this),
    getDeliveryMethodByType(type: OptionalDeliveryMethodsType) {
      return getDeliveryMethodByType(type, this.deliveryMethods);
    },
    getDeliveryMethodById(id: string) {
      return DeliveryMethodRecord(this.deliveryMethods.find((dm) => dm.id === id));
    },
  },
  'VendorRecord'
);
