export function getNameInitials(name?: string | null, numOfInitials = 2) {
  const text = name || '';

  return text
    .split(/\W/g)
    .map((n) => n[0])
    .join('')
    .slice(0, numOfInitials)
    .toUpperCase();
}

export function getEventNameFromLocation(location) {
  return location.pathname.split(/[/]+/).pop();
}

export function removeProtocol(link?: string) {
  const text = link || '';
  const tmp = text.split('://');

  return tmp.length === 2 ? tmp[1] : '';
}

export function checkApostropheForPluralPossessive(value: string) {
  if (!value) return value;

  return value.slice(-1) === 's' ? `${value}’` : `${value}’s`;
}

export function middleTruncation({
  str,
  strLength,
  start,
  end,
}: {
  str?: string | null;
  strLength: number;
  start: number;
  end: number;
}) {
  if (str && str.length > strLength) {
    return `${str.substr(0, start)}...${str.substr(str.length - end, str.length)}`;
  }

  return str;
}
