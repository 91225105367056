import { FetchIntuitAcctNumResponse } from 'src/app/version-2/api/reponses/FetchIntuitAcctNumResponse';
import { FetchVendorByIdResponse } from 'src/app/version-2/api/reponses/FetchVendorByIdResponse';
import { FetchIntuitAcctNumRequest } from 'src/app/version-2/api/requests/FetchIntuitAcctNumRequest';
import { FetchVendorByIdRequest } from 'src/app/version-2/api/requests/FetchVendorByIdRequest';
import { fetchRequest, putRequest } from 'src/app/version-2/externals';

export const vendorApi = {
  update: ({ orgId, id, params }) => putRequest(`/orgs/${orgId}/vendors/${id}`, params),
  updateMccCode: ({ orgId, params }) => putRequest(`/orgs/${orgId}/vendor-traits/mcc-code`, params),
  fetchIntuitAcctNum: ({ orgId, id }: FetchIntuitAcctNumRequest) =>
    fetchRequest<FetchIntuitAcctNumResponse>(`/orgs/${orgId}/vendors/${id}/vendor-intuit-acct-num`),
  fetchById({ orgId, id }: FetchVendorByIdRequest) {
    const url = `/orgs/${orgId}/vendors/${id}`;

    return fetchRequest<FetchVendorByIdResponse>(url);
  },
};
