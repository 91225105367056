import * as React from 'react';
import { useSelector } from 'react-redux';
import errorIcon from 'src/app/images/qbo/error.svg';
import QBOMIDialog from 'src/app/components/common/QBOMIDialog';
import paymentsApi from 'src/app/services/api/payments';
import recurringBillsApi from 'src/app/services/api/recurringBills';
import { useModal } from 'src/app/helpers/react/useModal';
import { getOrgId } from 'src/app/redux/user/selectors';
import {
  IMAGE_POSITION,
  NOTIFICATION_VARIANT,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { getDashboardItemId } from 'src/app/pages/qb-dashboard/utils';
import useDashboardListParams from './useDashboardListParams';
import { useLoadDashboardList } from './useLoadDashboardList';
import { useDashboardHighlightedItems } from './useDashboardHighlightedItems';
import { useRedirectToDashboard } from './useRedirectToDashboard';

type Response = [React.ReactNode, () => void];

type Props = {
  companyName: string;
  amount: string;
  billId: string;
  paymentId?: string;
  modalName: string;
  recurringBillId?: number | null;
};

const useCancelPaymentAction = ({
  billId,
  paymentId,
  modalName,
  companyName,
  amount,
  recurringBillId,
}: Props): Response => {
  const orgId = useSelector(getOrgId);
  const [listParams] = useDashboardListParams();
  const { fetchRedirectQuery, redirectToDashboard } = useRedirectToDashboard();
  const { setHighlightedItemIds } = useDashboardHighlightedItems();
  const { loadDashboardList } = useLoadDashboardList();
  const isRecurring = !!recurringBillId;

  const cancelPayment = async (dismiss: () => void) => {
    try {
      const deleteAction = isRecurring
        ? recurringBillsApi.deleteCurrentRecurringPaymentById
        : paymentsApi.deletePaymentById;

      await deleteAction(orgId, paymentId);
      const redirectQuery = await fetchRedirectQuery([{ billId }]);

      dismiss();
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: 'paymentDashboard.cancelPayment.toastMessage',
      });

      if (isRecurring) loadDashboardList(listParams);
      else if (
        listParams.filters.status === redirectQuery.status &&
        listParams.filters.start === redirectQuery.start
      ) {
        setHighlightedItemIds([getDashboardItemId(billId)]);
        loadDashboardList(listParams);
      } else {
        await redirectToDashboard({ redirectQuery });
      }
    } catch (e) {
      dismiss();
    }
  };

  const cancelPaymentDialog = ({ dismiss }: { dismiss: () => void }) => (
    <QBOMIDialog
      image={errorIcon}
      imagePosition={IMAGE_POSITION.LEFT}
      type={DIALOG_TYPE.CONFIRM}
      variant={DIALOG_VARIANTS.SUCCESS}
      title="paymentDashboard.cancelPayment.dialogTitle"
      subtitle="paymentDashboard.cancelPayment.dialogSubTitle"
      subtitleValues={{
        companyName,
        amount: <MIFormattedCurrency value={amount} />,
      }}
      okButtonText="paymentDashboard.cancelPayment.dialogOkButtonText"
      onOkAction={() => cancelPayment(dismiss)}
      onCancelAction={dismiss}
      cancelButtonText="paymentDashboard.cancelPayment.dialogCancelButtonText"
    />
  );
  const [cancelPaymentDialogElement, updateModalStatus] = useModal(cancelPaymentDialog, {
    modalName,
  });

  return [cancelPaymentDialogElement, updateModalStatus];
};

export default useCancelPaymentAction;
