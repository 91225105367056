import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { FooterContainer } from 'src/app/components/common/ModalMessage/ModalMessage.styles';
import MIButton from 'src/app/components/common/MIButton';

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  background: gray;
  color: white;
  border: 100%;
`;

export const FeeInfo = styled.div`
  .method-type {
    font-weight: bold;
  }
  .text {
    font-weight: 100;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin-bottom: 3rem;

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: #f4f5f8;
    border-radius: 0;
    border: none;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.demi};
  margin-bottom: 1.7rem;
`;

export const Modal = styled(ModalMessage)`
  ${FooterContainer} {
    margin-bottom: 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row-reverse;
`;

export const Button = styled(MIButton)`
  margin-right: 0;
`;

export const Divider = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.ds.gray[400]};
  margin-bottom: 2.5rem;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.8125rem;
`;
export const Icon = styled.img`
  height: 3.125rem;
  width: 3.125rem;
`;
