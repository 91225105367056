import { initRestInterceptors } from 'src/app/version-2/api/rest/rest.interceptors';

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';

export interface Options {
  isBypassThrowError?: boolean;
  catchCall?: boolean;
  signal?: AbortSignal;
}

export class RestAPI {
  private instance: AxiosInstance = axios.create();
  private _isInitialized = false;
  private _baseUrl = '';
  private _siteContextName = '';
  private _authToken;
  get isInitialized(): boolean {
    return this._isInitialized;
  }
  set authToken(value: string) {
    this._authToken = value;

    if (this.isInitialized) {
      initRestInterceptors({
        instance: this.instance,
        siteContextName: this._siteContextName,
        authToken: this._authToken,
      });
    }
  }
  init(baseUrl: string, siteContextName: string, authToken?: string) {
    this._baseUrl = baseUrl;
    this._siteContextName = siteContextName;
    this._authToken = authToken;
    this.instance = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });
    axiosRetry(this.instance);
    initRestInterceptors({ instance: this.instance, siteContextName, authToken });
    this._isInitialized = true;
  }
  fetch<T>(
    url: string,
    params: Record<string, any> = {},
    options: Options = {}
  ): Promise<AxiosResponse<T>> {
    return this.instance.get<T>(url, {
      ...options,
      params,
    });
  }

  post<T>(
    url: string,
    params: Record<string, any> = {},
    options: Options = {}
  ): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(url, params, options as AxiosRequestConfig);
  }

  put<T>(
    url: string,
    params: Record<string, any> = {},
    options: Options = {}
  ): Promise<AxiosResponse<T>> {
    return this.instance.put<T>(url, params, options as AxiosRequestConfig);
  }

  delete<T>(
    url: string,
    params: Record<string, any> = {},
    options: Options = {}
  ): Promise<AxiosResponse<T>> {
    return this.instance.delete<T>(url, {
      ...options,
      data: params,
    });
  }
}
export const restApi = new RestAPI();
