import {
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
  PopoverContent as ChakraPopoverContent,
  PopoverHeader as ChakraPopoverHeader,
  PopoverTrigger as ChakraPopoverTrigger,
  PopoverBody as ChakraPopoverBody,
  PopoverFooter as ChakraPopoverFooter,
  PopoverCloseButton as ChakraPopoverCloseButton,
  PopoverContentProps,
  PopoverArrow,
  Portal,
  Image,
  Text,
} from '@chakra-ui/react';

import { useBreak } from '../../hooks/useBreak';

import { Box } from '../../components/ui/Box';
import { arrowStyles as defaultArrowStyles } from './Popover.styles';

export { PopoverArrow };
export type { PopoverContentProps };

export type PopoverProps = Omit<ChakraPopoverProps, 'size' | 'variant' | 'arrowPadding'> & {
  arrowStyles?: Record<string, any>;
  arrowColor?: string;
};

const Popover = ({ children, arrowStyles, arrowColor, ...rest }: PopoverProps) => {
  const { isMobile } = useBreak();

  return (
    <Box __css={arrowStyles || defaultArrowStyles(arrowColor)}>
      <ChakraPopover trigger={isMobile ? 'click' : 'hover'} {...rest}>
        {children}
      </ChakraPopover>
    </Box>
  );
};

export { Popover };

Popover.PopoverContent = ChakraPopoverContent;
Popover.PopoverHeader = ChakraPopoverHeader;
Popover.PopoverTrigger = ChakraPopoverTrigger;
Popover.PopoverBody = ChakraPopoverBody;
Popover.PopoverFooter = ChakraPopoverFooter;
Popover.PopoverCloseButton = ChakraPopoverCloseButton;
Popover.Portal = Portal;
Popover.Image = Image;
Popover.Text = Text;
