import { featureFlags } from '@melio/shared-web';
import { PaymentType } from 'src/app/version-2/externals';
import { DeliverFailureDataEnum, FeatureFlagsEnum } from 'src/app/version-2/model/enums';

export const isRetryFailedToDeliverACH = (payment: Partial<PaymentType>): boolean => {
  const retryFailedToDeliverACHEnabled = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.RETRY_NEW_ERROR_TEXT,
    false
  );

  // ff only
  if (!retryFailedToDeliverACHEnabled) return false;

  const errorCode = payment?.deliverFailureData?.failureCode;

  // R03, R04
  return (
    errorCode === DeliverFailureDataEnum.BankDetailsError ||
    errorCode === DeliverFailureDataEnum.AccountNumberError
  );
};

export const getRetryFailedToDeliverACH = (payment: Partial<PaymentType>): string => {
  const retryFailedToDeliverACHEnabled = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.RETRY_NEW_ERROR_TEXT,
    false
  );

  // ff only
  if (!retryFailedToDeliverACHEnabled) return '';

  const errorCode = payment?.deliverFailureData?.failureCode || '';

  return errorCode;
};

interface GetEditAchPageInputLabelsParams {
  isOriginPresented: boolean;
  isRetryFailedToDeliver: boolean;
}
export const getEditAchPageInputLabels = ({
  isOriginPresented,
  isRetryFailedToDeliver,
}: GetEditAchPageInputLabelsParams) => {
  if (isRetryFailedToDeliver) {
    return {
      routingNumber: 'vendors.deliveryMethods.bank.failedToDeliver.routingNumber',
      accountNumber: 'vendors.deliveryMethods.bank.failedToDeliver.accountNumber',
    };
  } else if (isOriginPresented) {
    return {
      routingNumber: 'vendors.deliveryMethods.bank.routingNumber',
      accountNumber: 'vendors.deliveryMethods.bank.accountNumber',
    };
  }

  return {
    routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
    accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
  };
};
