import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.space.ds.xl};
  gap: 1rem;
  ${({ theme }) => theme.textStyles.ds.body3};
`;

export const IconContainer = styled.div`
  width: 2.4rem;
  height: 2.4rem;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
`;
