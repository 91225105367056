import { featureFlags } from '@melio/shared-web';
import { envApi } from 'src/app/version-2/api/env';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { getPathParams, parseQueryString } from 'src/app/version-2/utils/query.utils';
import { restApi } from './rest.api';

import { InternalAxiosRequestConfig } from 'axios/index';

export const initUninitializedRestApi = (siteContextName: string, locationQueryString: string) => {
  if (restApi.isInitialized) return;

  const query = parseQueryString(locationQueryString) || {};
  const authToken = (query.token || query.authToken) as string;

  restApi.init(`${envApi.getConfig().server.baseUrl}/api`, siteContextName, authToken);
};

export const requestLogging = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const isLogPerRequestResponseFeatureFlag = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.BP_LOG_PER_REQUEST_RESPONSE,
    false
  );

  if (isLogPerRequestResponseFeatureFlag) {
    try {
      loggingApi.info('restInterceptors.interceptRequestLogging(): middleware-logger', {
        url: config?.url,
        urlParams: config?.url ? getPathParams(config.url) : null,
        queryParams: config?.params,
        clientRequestId: config?.headers ? config.headers['client-request-id'] : null,
        baseURL: config?.baseURL,
        method: config?.method,
      });
    } catch (error: any) {
      loggingApi.error('restInterceptors.interceptRequestLogging(): middleware-logger', {
        message: error.message,
      });
    }
  }

  return config;
};
