import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { OrganizationPreferencesType } from 'src/app/utils/types';
import { envApi } from 'src/app/version-2/api/env';

type UseVendorDirectoryEnabledType = {
  isVendorDirectoryEnabled: boolean;
};

export const useVendorDirectoryEnabled = (): UseVendorDirectoryEnabledType => {
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );

  const isVendorDirectoryEnabled =
    envApi.getConfig().featureFlags.showVendorDirectory ||
    organizationPreferences?.vendorDirectory === 'true';

  return {
    isVendorDirectoryEnabled,
  };
};

export type WithVendorDirectoryEnabledType = UseVendorDirectoryEnabledType;

export function withVendorDirectoryEnabled() {
  return function (Component: React.FC<any>): React.FC<WithVendorDirectoryEnabledType> {
    return function (props: any): ReactElement {
      const { isVendorDirectoryEnabled } = useVendorDirectoryEnabled();

      return (
        <Component {...props} isVendorDirectoryEnabled={isVendorDirectoryEnabled}>
          {props.children}
        </Component>
      );
    };
  };
}
