import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { SmartIcon } from '@melio/billpay-design-system';
import { analytics } from 'src/app/version-2/externals';
import {
  ADD_MEMO_TO_VENDOR_CLICK,
  BATCH_BULK_EVENT_PAGE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { HandCursorWrapper, MemoTooltip } from './renderers.styles';

export const MemoRenderer: FC<any> = (props) => {
  const { row } = props;
  const { id, type, vendorId, totalBillIds, totalPartialBillIds }: BatchBulkItem = row.original;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  if (type === BatchBulkItemEnum.BILL) return null;

  const onClick = () => {
    dispatch(batchBulkActions.setMemoForVendorModalAsOpen({ id }));
    analytics.track(BATCH_BULK_EVENT_PAGE, ADD_MEMO_TO_VENDOR_CLICK, {
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
    });
  };

  return (
    <MemoTooltip label={formatMessage({ id: 'batchBulkPage.renderers.memo.tooltip' })}>
      <HandCursorWrapper onClick={onClick} data-testid="memo-icon">
        <SmartIcon type="memo" />
      </HandCursorWrapper>
    </MemoTooltip>
  );
};
