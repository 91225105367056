import React, { useMemo, useEffect, useCallback } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useApi } from 'src/app/hoc/useApi';
import { checkApostropheForPluralPossessive } from 'src/app/utils/string-utils';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';
import ValidationError from 'src/app/ui/ValidationError';
import {
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  INPUT_PATTERNS,
  NOTIFICATION_VARIANT,
} from 'src/app/utils/consts';
import { BankType, DeliveryMethodType } from 'src/app/utils/types';
import { withPreservedStateNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import api from 'src/app/services/api/financialAccounts';
import { selectFundingSourceAction } from 'src/app/redux/payBillWizard/actions';
import { getOrgId } from 'src/app/redux/user/selectors';
import vendorsApi from 'src/app/services/api/vendors';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import useRefreshFundingSources from 'src/app/modules/funding-sources/hooks/useRefreshFundingSources';
import { useForm } from 'src/app/ui/form';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import { WizardPasswordInputField } from 'src/app/ui/form/WizardPasswordInputField';
import { WizardFormContainer } from 'src/app/ui/wizard/WizardFormContainer';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import {
  AddFundingSourceWizardOriginEnum,
  DbAnalyticsTraitsEnum,
} from 'src/app/version-2/model/enums';
import { BANK_ACCOUNT_CHECKING_TYPE } from 'src/app/version-2/model/constants';

type Props = {
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  locationState: Record<string, any>;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const SetManuallyBankAccountPageContainer = ({
  navigateToExitWithPreservedState,
  navigate,
  locationState,
  navigateWithPreservedState,
}: Props) => {
  const site = useSiteContext();
  const intl = useIntl();
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const [requestMicroDeposit, result, isLoading, error] = useApi<[string, { bank: any }], any>(
    api.requestMicroDeposit
  );
  const [getVendorsDeliveryMethods, , isValidating] = useApi<[string], any>(
    vendorsApi.getVendorsDeliveryMethods
  );
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();
  const { paymentStoreActions } = useJustPayStore();

  const model = useMemo(
    () => ({
      routingNumber: '',
      accountNumber: '',
    }),
    []
  );

  const goExit = () => {
    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  const submitManualBankAccount = async (value) => {
    const bank = {
      ...value,
      accountType: BANK_ACCOUNT_CHECKING_TYPE,
    };
    const validationErrors = await validateBankAccount(bank);

    if (isEmpty(validationErrors)) {
      analytics.trackAction('funding-source-added-manually');
      try {
        await requestMicroDeposit(orgId, { bank });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } else {
      throw new ValidationError({ validationErrors });
    }
  };

  const onMicroDepositCreated = useCallback(
    async ({ fundingSource }) => {
      const origin = locationState?.preservedState?.origin || '';

      await refreshFundingSources();
      analytics.setTraits({ [DbAnalyticsTraitsEnum.ADDED_FUNDING]: true });
      analytics.setFundingSourceTraits();
      analytics.trackAction('funding-source-added-manually-success');
      analytics.trackMqlEvent('added-funding', 'mql');
      analytics.trackMqlEvent('added-funding-manual', 'mql');

      if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL) {
        dispatch(selectFundingSourceAction(fundingSource.id));
      }

      if (origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY) {
        paymentStoreActions.justPay.justPayWizard.update({
          fundingSourceId: fundingSource.id,
          suggestedFundingSources: {
            selectedFundingSourceId: fundingSource.id,
            selectedFundingSource: fundingSource,
          },
        });
      }

      if (origin === AddFundingSourceWizardOriginEnum.BATCH_BULK) {
        navigateWithPreservedState({ newFundingSourceId: fundingSource.id });
      } else {
        navigate(locations.Onboarding.fundingSources.bank.account.url({ orgId }), false, {
          ...locationState,
          fundingSourceId: fundingSource.id,
          origin,
        });
      }
    },
    [locationState, refreshFundingSources, dispatch, navigate]
  );

  useEffect(() => {
    if (error) {
      pushNotification({
        type: NOTIFICATION_VARIANT.ERROR,
        msg: `server.${error.code}`,
      });
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      onMicroDepositCreated(result);
    }
  }, [result, onMicroDepositCreated]);

  const validateBankAccount = async (bank: BankType) => {
    const { routingNumber, accountNumber } = bank;
    const fieldsToValidation = [
      'accountType',
      'routingNumber',
      'accountNumber',
      'accountNumberConfirmation',
    ];
    const syncValidationErrors = getValidationErrors('fundingSourceBank', bank, fieldsToValidation);

    if (!isEmpty(syncValidationErrors)) {
      return syncValidationErrors;
    }

    const { vendorsDeliveryMethods } = await getVendorsDeliveryMethods(orgId);
    const deliveryMethod = vendorsDeliveryMethods.find((deliveryMethodType: DeliveryMethodType) => {
      const { bankAccount } = deliveryMethodType;

      return (
        bankAccount?.routingNumber === routingNumber && bankAccount?.accountNumber === accountNumber
      );
    });

    if (deliveryMethod?.vendor) {
      // TODO: add the possiblity to pass values in error messages for useForm
      return {
        accountNumber: intl.formatMessage(
          { id: 'inputErrors.fundingSourceBank.alreadyDefined' },
          {
            vendorName: checkApostropheForPluralPossessive(deliveryMethod.vendor.companyName),
          }
        ),
      };
    }

    return {};
  };

  const [bankAccountVM, { submit }] = useForm(model, {
    submit: submitManualBankAccount,
  });

  return (
    <site.components.StepLayout
      title="onboarding.fundingSources.bank.manually.connectBankTitle"
      subtitle="onboarding.fundingSources.bank.manually.connectBankSubTitle"
      goExit={goExit}
      onSubmit={submit}
      nextLabel="onboarding.fundingSources.bank.manually.completeAndSave"
      isLoading={isLoading || isFundingSourcesRefreshing || isValidating}
      footer={
        <site.components.WizardElements.SimpleTextFooterSlim>
          <MIFormattedText label="onboarding.fundingSources.bank.manually.saveFooter" />
        </site.components.WizardElements.SimpleTextFooterSlim>
      }
      hideHeader
      fullWidthCTA
    >
      <WizardFormContainer>
        <WizardTextInputField
          model={bankAccountVM.routingNumber}
          label="onboarding.fundingSources.bank.manually.routingNumber"
          type="tel"
          autoFocus
          required
          privateData
        />

        <WizardPasswordInputField
          model={bankAccountVM.accountNumber}
          label="onboarding.fundingSources.bank.manually.accountNumber"
          required
          shouldShowValue
          pattern={INPUT_PATTERNS.NUMBERS}
          inputMode="numeric"
        />
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormContainer>
    </site.components.StepLayout>
  );
};

export default compose(withPreservedStateNavigator())(SetManuallyBankAccountPageContainer);

const HiddenInput = styled.input`
  display: none;
`;
