import React, { FC, useState, useEffect } from 'react';
import { Flex, Stack, Box, Switch } from '@melio/billpay-design-system';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import Notifications from './Notifications/Notifications';
import { useModal } from 'src/app/helpers/react/useModal';
import { MIFormattedText } from 'src/app/utils/formatting';
import intercomService from 'src/app/services/intercom';
import locations from 'src/app/utils/locations';
import { closeDashboard, redirectToPayBills } from 'src/app/utils/external-events';
import QBODrawer from 'src/app/components/layout/QBODrawer';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { getOrgId } from 'src/app/redux/user/selectors';
import { ConfirmationOrigin } from 'src/app/utils/types';
import { FTUTooltip } from '../FTUTooltip';
import { DASHBOARD_CLOSE_ORIGIN } from '../../consts';
import { useUpdateQBDashboardSwitcher } from '../../hooks/useUpdateQbDashboardSwitcher';
import { PayBillsLoadingModal as PayBillsLoadingModalComponent } from '../PayBillsLoadingModal/PayBillsLoadingModal';
import analytics from 'src/app/services/analytics';
import { TooltipLabel, HeaderContentIcon, SettingsLink, BlackOverlay } from './QBOHeader.styles';
const eventPage = 'qb-dashboard';
const SHOW_PAY_BILLS_LOADING_MODAL_TIMEOUT = 400;

type Props = {
  isSwitcherTooltipVisible: boolean;
  onCloseTooltip: () => void;
};

const QBOHeader: FC<Props> = ({ isSwitcherTooltipVisible, onCloseTooltip }) => {
  const [showNotificationsCenter] = featureFlags.useFeature('notifications-center');
  const [isSwitcherHovered, setSwitcherHovered] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const location = useLocation<any>();
  const history = useHistory();
  const { toggleSwitcher } = useUpdateQBDashboardSwitcher();
  const [PayBillsLoadingModal, showPayBillsLoadingModal] = useModal(
    PayBillsLoadingModalComponent,
    {}
  );

  useEffect(() => {
    if (location?.state?.confirmationOrigin === ConfirmationOrigin.BATCH_PAYMENT) {
      return () => {
        if (history.action === 'POP') {
          history.go(1);
        }
      };
    }

    return undefined;
  }, [history]);

  const openSupportChat = () => {
    analytics.track(eventPage, 'support-open-start-chat');
    intercomService.show();
  };
  const openDrawer = () => {
    analytics.track(eventPage, 'need-help-open');
    dispatch(applicationActions.setFaqPanel({ isOpen: true }));
  };
  const closeIframe = () => {
    analytics.track(eventPage, 'close-integration');
    closeDashboard(DASHBOARD_CLOSE_ORIGIN.CROSS);
  };
  const onSettingsClick = () => {
    dispatch(applicationActions.setUrlToBack(`${location.pathname}${location.search}`));
    analytics.track(eventPage, 'settings');
  };

  const onSwitchClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleSwitcher(event.target.checked);
    redirectToPayBills();
    setTimeout(() => {
      showPayBillsLoadingModal();
    }, SHOW_PAY_BILLS_LOADING_MODAL_TIMEOUT);
  };

  const onMouseOver = () => {
    if (!isSwitcherTooltipVisible) {
      setSwitcherHovered(true);
    }
  };

  const onMouseOut = () => {
    if (!isSwitcherTooltipVisible) {
      setSwitcherHovered(false);
    }
  };

  const setIsNotificationHandler = (isOpen: boolean) => {
    setIsNotificationsOpen(isOpen);
  };

  return (
    <>
      <BlackOverlay display={isNotificationsOpen} />
      {PayBillsLoadingModal}
      <Stack direction="row" justifyContent="space-between" p="1.5rem 2rem" bg="white">
        <Flex textStyle="body3">
          <Flex align="center" color="black" fontWeight="600">
            <MIFormattedText label="paymentDashboard.title" />
          </Flex>
          <Flex align="center">
            <Flex
              align="center"
              ml="4"
              color="gray.500"
              fontWeight="500"
              __css={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <MIFormattedText label="paymentDashboard.modeLabel" />
              </Box>
              <FTUTooltip
                isOpen={isSwitcherHovered || isSwitcherTooltipVisible}
                showCloseIcon={isSwitcherTooltipVisible}
                label={
                  <MIFormattedText
                    values={{
                      on: <TooltipLabel>on&nbsp;</TooltipLabel>,
                      off: <TooltipLabel>off&nbsp;</TooltipLabel>,
                    }}
                    label="paymentDashboard.tooltips.setPayBillView"
                  />
                }
                onClose={onCloseTooltip}
              >
                <Switch
                  id="dashboard-toggle"
                  defaultChecked
                  onChange={onSwitchClick}
                  ml="2"
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                />
              </FTUTooltip>
            </Flex>
          </Flex>
        </Flex>
        <Flex textStyle="body3" alignItems="center" justifyContent="center" gap="2.4rem">
          <Flex align="center" cursor="pointer" onClick={openSupportChat}>
            <HeaderContentIcon className="icon-support-icon-streak" />
            <Box ml="1rem" color="black">
              <MIFormattedText label="qbo.header.support" />
            </Box>
          </Flex>

          <Flex align="center" cursor="pointer" onClick={openDrawer}>
            <HeaderContentIcon className="icon-help-icon" />
            <Box ml="1rem" color="black">
              <MIFormattedText label="qbo.header.help" />
            </Box>
          </Flex>
          {showNotificationsCenter && (
            <Notifications isOpen={isNotificationsOpen} setIsOpen={setIsNotificationHandler} />
          )}
          <SettingsLink to={locations.Settings.index.url({ orgId })} onClick={onSettingsClick}>
            <HeaderContentIcon className="icon-settings-icon" />
            <Box ml="1rem" color="black">
              <MIFormattedText label="qbo.header.settings" />
            </Box>
          </SettingsLink>
          <Flex cursor="pointer" onClick={closeIframe}>
            <HeaderContentIcon className="icon-close-icon" />
          </Flex>
        </Flex>
      </Stack>
      <Box position="relative">
        <QBODrawer />
      </Box>
    </>
  );
};

export default QBOHeader;
