import { RecordOf } from 'immutable';
import { useDispatch } from 'react-redux';
import { loadCompanyInfoAction } from 'src/app/redux/user/actions';
import { CompanyInfoType } from 'src/app/utils/types';

export const useRefreshCompanyInfo = () => {
  const dispatch = useDispatch();

  const refreshCompanyInfo = () =>
    new Promise<RecordOf<CompanyInfoType>>((resolve, reject) => {
      dispatch(loadCompanyInfoAction(resolve, reject));
    });

  return { refreshCompanyInfo };
};
