import { BatchBulkPayment } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPayment';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';

export const createBatchBulkPaymentAdapter = (
  payments: BatchBulkPaymentIntent[]
): BatchBulkPayment[] =>
  payments.map((paymentIntentItem) => {
    const bills = paymentIntentItem.payment.bills || [];

    return {
      ...paymentIntentItem.payment,
      billIds: bills.map((bill) => Number(bill.id)) || [Number(paymentIntentItem.payment.billId)],
      billId: bills[0]?.id,
    };
  });
