/* eslint-disable camelcase */
import React, { useContext, useMemo, useState } from 'react';
import { isValidationOk } from '@melio/sizzers-js-common';
import { featureFlags } from '@melio/shared-web';
import { useBreak } from 'src/app/hoc';
import { useForm } from 'src/app/ui/form';
import styled from 'styled-components';
import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import MIAddressAutocomplete from 'src/app/components/common/MIAddressAutocomplete';
import MIButton from 'src/app/components/common/MIButton';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS } from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import { validateMultiFields } from 'src/app/utils/form-utils';
import { AddressFormControlModeEnum } from 'src/app/version-2/model/enums';
import { ButtonContainer } from 'src/app/pages/vendor/delivery-methods/international/components/ButtonContainer';
import { Footer } from 'src/app/pages/vendor/delivery-methods/international/components/Footer';

import InternationalContext from '../../Context';
import {
  extractAddress,
  transformForValidation,
  getVendorAddressValues,
  VENDOR_ADDRESS_FIELDS,
  ERROR_PREFIX,
} from '../../utils';
import CountriesList from '../../components/CountriesList';

import { countries, forbiddenCountries } from './utils';

function Form({ isLoading, onSubmit }) {
  const device = useBreak();
  const isMobileView = device.isMobile || device.isPhablet;
  const [showHints] = featureFlags.useFeature('show-hints-to-user');
  const [state] = useContext(InternationalContext);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});
  const model = useMemo(() => state.requiredFields, []);

  const validateForm = () => {
    const validationFields = transformForValidation(modelValues, state.requiredFieldsValidation);
    const validationErrors = validateMultiFields(validationFields, ERROR_PREFIX);
    const isValid = isValidationOk(validationErrors);

    analytics.track('pay-bill', 'international-country-vendor-details-continue', {
      errors: validationErrors,
    });

    if (!isValid) setValidationErrors(validationErrors);

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateForm();

    if (!isValid) {
      return undefined;
    }

    return onSubmit({
      ...state.requiredFields,
      ...getVendorAddressValues(modelValues),
    });
  };

  const [modelValues, { submit }] = useForm(model, {
    submit: handleSubmit,
  });

  const handleAddressChange = ({ formattedAddress, addressComponents }) => {
    const fields = extractAddress(addressComponents);

    resetAddressFields();

    if (!addressComponents) return;

    modelValues?.address?.onChange({ value: formattedAddress });
    Object.keys(fields).forEach((fieldName) => {
      let fieldValue: string | undefined = fields[fieldName];

      if (fieldName === 'country') {
        const isAllowed = !forbiddenCountries.includes(fieldValue);

        fieldValue = isAllowed ? fieldValue : '';
      }

      modelValues?.[fieldName]?.onChange({ value: fieldValue });
    });
  };

  const resetAddressFields = () => {
    const errors = VENDOR_ADDRESS_FIELDS.reduce((errorsObj, name) => {
      modelValues[name]?.onChange && modelValues[name].onChange({ value: '' });
      errorsObj[name] = '';

      return errorsObj;
    }, {});

    setValidationErrors({ ...validationErrors, ...errors });
  };

  const handleChange: any = ({ id, value }) => {
    if (validationErrors[id]) {
      setValidationErrors((prev) => ({
        ...prev,
        [id]: '',
      }));
    }

    modelValues?.[id]?.onChange({ value });
  };

  return (
    <>
      <StyledForm>
        <Input
          id="business_name"
          label="international.vendorDetails.fields.business_name.label"
          placeholder="international.vendorDetails.fields.business_name.placeholder"
          value={modelValues?.business_name?.value}
          onChange={handleChange}
          errorMessage={validationErrors?.business_name}
          notices={
            [showHints && 'international.vendorDetails.fields.business_name.hint'] as string[]
          }
          required
          autocomplete="none"
        />
        <Input
          id="name"
          label="international.vendorDetails.fields.name.label"
          placeholder="international.vendorDetails.fields.name.placeholder"
          value={modelValues?.name?.value}
          onChange={handleChange}
          errorMessage={validationErrors?.name}
          notices={[showHints && 'international.vendorDetails.fields.name.hint'] as string[]}
          required
          autocomplete="none"
        />

        <AddressAutocompleteContainer>
          <MIAddressAutocomplete
            id="address"
            addressLabel="international.vendorDetails.fields.address.label"
            aptNumberLabel="international.vendorDetails.fields.address.placeholder"
            addressNotices={['international.vendorDetails.fields.address.hint']}
            onChange={handleAddressChange}
            onClear={resetAddressFields}
            address={{ formattedAddress: modelValues?.address?.value }}
            errorMessage={validationErrors?.address}
            allowAllCountries
            mode={AddressFormControlModeEnum.FORM}
            showAddManualAddress
            required
          />
        </AddressAutocompleteContainer>
        <AddressFields show={modelValues?.address?.value}>
          <StyledFormRow>
            <InputStyled
              id="city"
              label="international.vendorDetails.fields.city.label"
              placeholder="international.vendorDetails.fields.city.placeholder"
              value={modelValues?.city?.value}
              onChange={handleChange}
              errorMessage={validationErrors?.city}
              required
            />
            <InputStyled
              id="state_or_province"
              label="international.vendorDetails.fields.state_or_province.label"
              placeholder="international.vendorDetails.fields.state_or_province.placeholder"
              value={modelValues?.state_or_province?.value}
              onChange={handleChange}
              errorMessage={validationErrors?.state_or_province}
              required
            />
          </StyledFormRow>

          <StyledFormRow>
            <DropdownStyled>
              {/* payee country */}
              <CountriesList
                id="country"
                label="international.vendorDetails.fields.country.label"
                options={countries}
                value={modelValues?.country?.value}
                onChange={handleChange}
                errorMessage={validationErrors?.country}
              />
            </DropdownStyled>
            <InputStyled
              id="postcode"
              label="international.vendorDetails.fields.postcode.label"
              placeholder="international.vendorDetails.fields.postcode.placeholder"
              value={modelValues?.postcode?.value}
              onChange={handleChange}
              errorMessage={validationErrors?.postcode}
              required
            />
          </StyledFormRow>
        </AddressFields>
        {isMobileView && <Footer />}

        <ButtonContainer>
          <Button
            type="submit"
            disabled={false}
            onClick={submit}
            className="button"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            isProcessing={isLoading}
            label="international.vendorDetails.button"
          />
        </ButtonContainer>
      </StyledForm>
      {!isMobileView && <Footer />}
    </>
  );
}

export default Form;

const Input = styled(InlineTextInputField)`
  margin-top: 2rem;
`;

const InputStyled = styled(InlineTextInputField)`
  margin-top: 2rem;
  width: 48%;
  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const DropdownStyled = styled.div`
  margin-top: 2rem;
  width: 48%;
  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const AddressAutocompleteContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const AddressFields = styled.div<{ show: boolean }>`
  width: 100%;
  display: ${(props) => (props.show ? `block` : `none`)};

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: 0;
  }
`;

const StyledFormRow = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  @media ${devices.mobile}, ${devices.phablet} {
    display: inline-block;
  }
`;

const Button = styled(MIButton)`
  @media ${devices.desktop}, ${devices.tablet} {
    margin: 2.8rem auto 2.3rem;
  }
`;
