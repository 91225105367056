import { Box } from '@melio/billpay-design-system';
import {
  DeliveryTypeExpeditedEnum,
  FastFeeDeliveryEnum,
  FundingSourceTypesEnum,
} from 'src/app/version-2/model/enums';
import { MIFormattedText } from 'src/app/utils/formatting';
import { isSameDay, isTomorrow } from 'src/app/utils/dates';
import { DELIVERY_TYPE, CARD_TYPES } from 'src/app/utils/consts';
import { DeliveryOptionType } from 'src/app/utils/types';
import { RISK_BILL_AMOUNT } from 'src/app/version-2/pages/batch-bulk/model/consts/riskBillAmount.consts';

import * as styles from './PaymentDeliveryOptionDescription.styles';

interface Props {
  deliveryOption: DeliveryOptionType;
  paymentAmount: number;
  fundingSourceType?: string;
}

const PaymentDeliveryOptionDescription = ({
  deliveryOption,
  paymentAmount,
  fundingSourceType,
}: Props) => {
  const isFastCheck = deliveryOption.type === FastFeeDeliveryEnum.EXPRESS_CHECK;

  const getDescription = () => {
    let label;
    let values = {};

    if (isSameDay(deliveryOption.deliveryDate)) {
      label = 'bills.pay.date.deliveryOptions.description.sameDay'; // 'Same business day'

      return { label, values };
    }

    if (isTomorrow(deliveryOption.deliveryDate)) {
      label = 'bills.pay.date.deliveryOptions.description.nextDay'; // 'Next business day'

      return { label, values };
    }

    if (deliveryOption.type === DeliveryTypeExpeditedEnum.EXPEDITED_ACH) {
      label = 'bills.pay.date.deliveryOptions.description.sameDay'; // 'Same business day'

      return { label, values };
    }

    if (deliveryOption.type === DELIVERY_TYPE.ACH) {
      if (fundingSourceType === FundingSourceTypesEnum.ACH) {
        label =
          paymentAmount >= RISK_BILL_AMOUNT.BIG_TRANSFER_THRESHOLD
            ? 'bills.pay.date.deliveryOptions.subTitle.achOver100K' // '4 business days'
            : 'bills.pay.date.deliveryOptions.subTitle.achBelow100K'; // '3 business days'

        return { label, values };
      }

      if (fundingSourceType === CARD_TYPES.DEBIT || fundingSourceType === CARD_TYPES.CREDIT) {
        label = 'bills.pay.date.deliveryOptions.description.sameDay'; // 'Same business day'

        return { label, values };
      }
    }

    if (deliveryOption.type === DELIVERY_TYPE.RPPS) {
      if (fundingSourceType === FundingSourceTypesEnum.ACH) {
        label = 'bills.pay.date.deliveryOptions.subTitle.rppsAch'; // '4 business days'

        return { label, values };
      }

      if (fundingSourceType === CARD_TYPES.DEBIT || fundingSourceType === CARD_TYPES.CREDIT) {
        label = 'bills.pay.date.deliveryOptions.subTitle.rppsCreditOrDebit'; // '2 business days'

        return { label, values };
      }
    }

    if (
      deliveryOption.type === DELIVERY_TYPE.VIRTUAL_CARD &&
      (fundingSourceType === CARD_TYPES.DEBIT || fundingSourceType === CARD_TYPES.CREDIT)
    ) {
      label = 'bills.pay.date.deliveryOptions.subTitle.virtualCardCreditOrDebit'; // '1 business day'

      return { label, values };
    }

    if (isFastCheck) {
      label = 'bills.pay.date.deliveryOptions.subTitle.expressCheckWithIndication'; // '{indication} 3 business days'
      values = {
        indication: (
          <Box as="span" sx={styles.indicationStyle}>
            <MIFormattedText label="bills.pay.date.deliveryOptions.deliveryExplanationCheckIndication" />
          </Box>
        ),
      };

      return { label, values };
    }

    return { label: `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}`, values };
  };

  const { label, values } = getDescription();

  return (
    <Box as="span" sx={styles.containerStyle}>
      <MIFormattedText label={label} values={values} />
    </Box>
  );
};

export { PaymentDeliveryOptionDescription };
