import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { MICard, MICardForm } from 'src/app/components/common/MICard';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import MIButton from 'src/app/components/common/MIButton';
import { BUTTON_SIZE } from 'src/app/utils/consts';
import { buttonHeight } from 'src/app/theme/qboTheme';
import { FailedPaymentMessage } from '../../pay/QBOViewPaymentPage/NotificationCardStatusMessage/components/FailedPaymentMessage/FailedPaymentMessage';

export const ButtonsContainer = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    > button {
      margin: 1rem 0;
      width: 100%;

      ${buttonHeight[BUTTON_SIZE.NORMAL]};
    }
  }
`;
export const MarkAsPaidButton = styled(MIButton)`
  margin-top: 1rem;
`;
export const ButtonWrapper = styled.div`
  display: none;

  @media ${devices.mobile}, ${devices.phablet} {
    display: block;
    padding: 0 2rem;
  }
`;
export const ViewBillContainer = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    min-height: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const PaymentDetails = styled(MICardForm)<{
  onKeyDown: (event: any) => void;
  canSchedulePayment: boolean;
}>``;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
    color: rgba(153, 153, 156, 1);
  }
`;

export const FormContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${devices.mobile} {
    flex-direction: column-reverse;
  }
`;

export const BillFormContainer = styled.div`
  width: 60%;
  margin-bottom: 2rem;
  margin-right: 1.6rem;

  @media ${devices.mobile} {
    width: 100%;
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
`;

export const BillInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.mobile} {
    margin-bottom: 4rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 0;
  }
`;

export const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: ${(props) => props.theme.text.size.regular};
`;

export const MiCardTitleWrapper = styled.div`
  @media ${devices.mobile} {
    * {
      text-transform: unset;
      font-size: 2.4rem;
    }
  }
`;

export const ViewBillQBOFooterWrapper = styled.div`
  display: none;

  @media ${devices.mobile}, ${devices.phablet} {
    display: flex;
    justify-content: center;
  }
`;

export const StyledFailedPaymentMessage = styled(FailedPaymentMessage)`
  margin-bottom: 0.5rem;
  margin: 3rem 2rem 0;
`;

export const BillOpenBalanceInfoContainer = styled.div``;

export const PaymentDetailsCard = styled(MICard)`
  margin-bottom: 0;
`;

export const BillDetailsCard = styled(MICard)`
  margin: 1.6rem;
`;
