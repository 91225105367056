import { getValidationErrors } from '@melio/sizzers-js-common';
import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import api from 'src/app/services/api/deliveryMethods';
import { updateSliceReducer } from 'src/app/helpers/redux/restUpdateSlice';
import unilateralPaymentsApi from 'src/app/services/api/unilateralPayments';
import {
  createApiCallSlice,
  ON_FAILURE,
  ON_REQUEST,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import { CONSTS, ERR_SHIFT_VIRTUAL_CARD_TO_ACH_AUTHORIZATION } from 'src/app/utils/consts';
import clientServiceApi from 'src/app/services/api/clientService';
import { DeliveryMethodType } from 'src/app/utils/types';

type State = {
  byId: { [key: string]: any };
  validation: {
    invalidTokenData: any;
    deliveryMethodId?: string;
    loading: boolean;
    error: Error | null;
    shiftVCtoACH: { [key: string]: string };
  };
};

const DeliveryMethodInitialState = {
  byId: {},
  validation: {
    invalidTokenData: null,
    loading: false,
    error: null,
    deliveryMethodId: '',
    shiftVCtoACH: {},
  },
};

const replaceVirtualDeliveryMethodSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] REPLACE VIRTUAL METHOD',
  api: unilateralPaymentsApi.createDeliveryMethod,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.byId[payload.id] = payload;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error.code ? action.error : null;
    },
  },
  async validate({ deliveryMethod }) {
    if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
      return null;
    }

    const res = await getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const fetchUnilateralRequestDetailsWithTokenSlice = createApiCallSlice({
  name: '[DELIVERYMETHODS] FETCH_REQUEST_DETAILS',
  api: unilateralPaymentsApi.getRequestDataWithToken,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.byId[payload.deliveryMethod.id] = payload.deliveryMethod;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error.code ? action.error : null;
    },
  },
});

const whitePagesAddressSlice = createApiCallSlice<any, any>({
  name: '[DELIVERYMETHODS] ADDRESS VALIDATION',
  api: clientServiceApi.getAddressValidation,
  initialState: {
    meta: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.meta[action.payload.deliveryMethodId] = {
        loading: true,
        whitePageAddress: {},
      };
    },
    [ON_SUCCESS]: (state, action) => {
      const { deliveryMethodId } = action.meta.identifier;

      state.meta[deliveryMethodId] = {
        loading: false,
        whitePageAddress: action.payload,
      };
    },
    [ON_FAILURE]: (state, action) => {
      state.meta[action.payload.deliveryMethodId] = {
        loading: false,
        whitePageAddress: {},
      };
    },
  },
  async validate(data) {
    const deliveryMethod: Partial<DeliveryMethodType> = {
      deliveryType: data.deliveryType,
    };

    if (data.deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
      deliveryMethod.paperCheck = data;
    } else {
      deliveryMethod.cardAccount = data;
    }

    const res = await getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const clearWhitePageAddressSlice = createSlice({
  name: '[DELIVERYMETHODS] CLEAR_ADDRESS',
  initialState: {
    meta: {},
  },
  reducers: {
    clear(state, { payload }) {
      state.meta[payload] = {
        loading: false,
        whitePageAddress: {},
      };
    },
  },
});

type CopyFromOwnedVendorState = {
  byId: { [key: string]: any };
  copyWithUnilateralToken: { [key: string]: any };
};

const copyFromOwnedVendorWithUnilateralToken = createApiCallSlice<any, CopyFromOwnedVendorState>({
  name: '[DELIVERYMETHODS] COPY_WITH_UNILATERAL_TOKEN',
  api: unilateralPaymentsApi.copyFromOwnedVendor,
  initialState: {
    byId: {},
    copyWithUnilateralToken: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.copyWithUnilateralToken[action.payload.deliveryMethodId] = {
        loading: true,
      };
    },
    [ON_SUCCESS]: (state, action) => {
      delete state.copyWithUnilateralToken[action.meta.identifier.deliveryMethodId];
      state.byId[action.payload.id] = action.payload;
    },
    [ON_FAILURE]: (state, action) => {
      state.copyWithUnilateralToken[action.payload.deliveryMethodId] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    status: (id) => (state) => state.deliveryMethods.copyWithUnilateralToken?.[id],
  },
});

const deliveryMethodSliceReducer = {
  [ON_REQUEST]: (state) => {
    state.validation.loading = true;
    state.validation.error = null;
  },
  [ON_SUCCESS]: (state, { payload }) => {
    state.validation.loading = false;
    state.validation.error = null;
    state.validation.deliveryMethodId = payload.deliveryMethod.id;
    state.byId[payload.deliveryMethod.id] = payload.deliveryMethod;
  },
  [ON_FAILURE]: (state, action) => {
    state.validation.loading = false;
    state.validation.invalidTokenData = action.error;
  },
};

const updateDeliveryMethodACHSlice = createApiCallSlice({
  name: '[DELIVERYMETHODS] UPDATE_DELIVERY_METHOD',
  api: api.updateDeliveryMethodWithToken,
  reducers: deliveryMethodSliceReducer,
  async validate({ deliveryMethod }) {
    const res = await getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const replaceDeliveryMethodSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] REPLACE_METHOD',
  api: api.replaceDeliveryMethodWithToken,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.validation.deliveryMethodId = payload.deliveryMethod.id;
      state.byId[payload.deliveryMethod.id] = payload.deliveryMethod;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error;
    },
  },
  async validate({ deliveryMethod }) {
    if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
      return null;
    }

    const res = await getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const copyFromOwnedVendorSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] COPY_FORM_OWNED_VENDOR',
  api: api.copyFromOwnedVendor,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.validation.deliveryMethodId = payload.deliveryMethod.id;
      state.byId[payload.deliveryMethod.id] = payload.deliveryMethod;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error;
    },
  },
});

const updateWithUnilateralToken = createApiCallSlice({
  name: '[DELIVERYMETHODS] UPDATE_WITH_UNILATERAL_TOKEN',
  api: unilateralPaymentsApi.updateDeliveryMethod,
  reducers: updateSliceReducer(),
  async validate({ deliveryMethod }) {
    if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
      return null;
    }

    const res = await getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const addCardAccountWithToken = createApiCallSlice({
  name: '[DELIVERYMETHODS] ADD_CARD_ACCOUNT',
  api: api.addCardAccountWithToken,
  reducers: deliveryMethodSliceReducer,
});

const shiftVirtualCardToACHPaymentStatusCollectedSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] SHIFT_VIRTUAL_CARD_TO_ACH_PAYMENT_STATUS_COLLECTED',
  api: api.shiftVirtualCardToAch,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.byId[payload.id] = payload;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;

      if (action.error?.code === ERR_SHIFT_VIRTUAL_CARD_TO_ACH_AUTHORIZATION) {
        state.validation.shiftVCtoACH = action.error;
      } else {
        state.validation.invalidTokenData = action.error.code ? action.error : null;
      }
    },
  },
});

export {
  replaceVirtualDeliveryMethodSlice,
  fetchUnilateralRequestDetailsWithTokenSlice,
  whitePagesAddressSlice,
  clearWhitePageAddressSlice,
  copyFromOwnedVendorWithUnilateralToken,
  replaceDeliveryMethodSlice,
  updateDeliveryMethodACHSlice,
  copyFromOwnedVendorSlice,
  updateWithUnilateralToken,
  addCardAccountWithToken,
  shiftVirtualCardToACHPaymentStatusCollectedSlice,
};
