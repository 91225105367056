import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, NotificationCard, NotificationTypeEnum } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { analytics } from 'src/app/version-2/externals';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  CheckFeeEnum,
  DeliveryEnum,
  FeatureFlagsEnum,
  FundingSourceTypesEnum,
} from 'src/app/version-2/model/enums';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import { isCheckFeeOpen } from 'src/app/version-2/utils/checkFees.utils';

import styled from 'styled-components';

const STORAGE_NOTICE = 'qb-check-notification-notice';

/**
 * Temp code until fee in check
 * Shows notification of future fee in select delivery method
 */
type Props = {
  // gonna remove the any when starting ach-to-ach (batch - we need the code placeholder)
  selectedFundingSource?: FundingSource | any;
  batchData?: BatchBulkPaymentIntent[] | any;
  className?: string;
};

const NotificationDM = ({ selectedFundingSource, batchData, className = '' }: Props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [checkFeeFlag] = featureFlags.useFeature(FeatureFlagsEnum.CHECK_FEES, CheckFeeEnum.CLOSED, {
    shouldTrack: false,
  });
  const [notificationFlag] = featureFlags.useFeature(
    FeatureFlagsEnum.CHECK_FEE_NOTIFICATION,
    false
  );
  const notificationFirstTime = !localStorage.getItem(STORAGE_NOTICE);
  const [isOpen, setIsOpen] = useState(notificationFlag && notificationFirstTime);

  const isBatch = !!batchData;

  const hasBatchCheckDM = useMemo(
    () =>
      isBatch
        ? batchData?.some((item: any) =>
            item?.bill?.payments?.find(
              (payment) =>
                payment?.deliveryMethod?.deliveryType === DeliveryEnum.CHECK &&
                payment?.fundingSource?.fundingType === FundingSourceTypesEnum.ACH
            )
          )
        : false,
    [batchData]
  );

  const isACH = isBatch
    ? hasBatchCheckDM
    : selectedFundingSource?.fundingType === FundingSourceTypesEnum.ACH;
  const shouldShowNshowotification = isOpen && !isCheckFeeOpen(checkFeeFlag) && isACH;

  useEffect(() => {
    if (shouldShowNshowotification) {
      localStorage.setItem(STORAGE_NOTICE, 'visible');
    }
  }, [shouldShowNshowotification]);

  if (!shouldShowNshowotification) return null;

  const goLearnMore = () => {
    analytics.trackAction('CheckFeeLearnMore-Clicked');
    dispatch(applicationActions.setFaqPanel({ isOpen: true, focusedQuestion: 19 }));
  };

  const onClose = () => setIsOpen(false);

  const values = {
    learnMoreLink: (...chunks) => (
      <Link onClick={goLearnMore} isExternal>
        {React.Children.toArray(chunks)}
      </Link>
    ),
    br: <br />,
  };

  return (
    <StyledCard
      className={className}
      type={NotificationTypeEnum.INFO}
      subtitle={formatMessage(
        { id: 'deliveryMethods.notDefinedInfo.check.cardNotification' },
        values
      )}
      onClose={onClose}
    />
  );
};

const StyledCard = styled(NotificationCard)`
  margin: 0 0.2rem 2rem;
  a {
    cursor: pointer;
  }
`;

export { NotificationDM };
