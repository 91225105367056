import { Box, Heading, Text } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const Card = styled(Box)<{ $selected?: boolean; withButton?: boolean; hover?: boolean }>`
  width: 100%;
  height: ${({ $withButton }) => `${$withButton ? '37.6' : '32.5'}rem`};
  max-width: 27.2rem;
  background: ${({ theme, $selected }) =>
    $selected ? theme.colors.ds.green[700] : theme.colors.ds.white};
  border: ${({ theme, $selected }) =>
    `0.2rem solid ${$selected ? theme.colors.ds.green[200] : 'transparent'}`};
  box-shadow: ${({ theme }) => theme.shadows.ds.md};
  padding: 1.2rem 0;
  display: grid;
  grid-template-rows: ${({ $withButton }) =>
    `5rem 4.9rem 2.8rem 3.2rem ${$withButton ? '4.9rem' : ''} auto 1.6rem`};
  justify-items: center;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 200ms;

  &:hover {
    box-shadow: ${({ theme, hover }) => theme.shadows.ds[hover ? 'xl' : 'md']};
  }
`;

export const Advantages = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const AdvantageItem = styled.li`
  color: ${({ theme }) => theme.colors.ds.black};
  font-size: ${({ theme }) => theme.textStyles.ds.body3.fontSize};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 2.1rem 0.8rem;
`;

export const Title = styled(Heading)`
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  font-size: ${({ theme }) => theme.textStyles.ds.h5.fontSize};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  margin: 0;
`;

export const Subtitle = styled(Text)`
  color: ${({ theme }) => theme.colors.ds.black};
  font-size: ${({ theme }) => theme.textStyles.ds.body3.fontSize};
  margin: 0;
`;

export const Fee = styled(Text)`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  font-size: ${({ theme }) => theme.textStyles.ds.body3.fontSize};
  margin: 0;
`;

export const Comment = styled(Text)`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  font-size: ${({ theme }) => theme.textStyles.ds.body4.fontSize};
  margin: 0;
`;

export const IconContainer = styled.div<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, $selected }) => ($selected ? theme.colors.ds.green[200] : 'transparent')};
  border-radius: ${({ $selected }) => ($selected ? '50%' : 'auto')};
  width: ${({ $selected }) => `${$selected ? 3.3 : 4}rem`};
  height: ${({ $selected }) => `${$selected ? 3.3 : 4}rem`};
  position: relative;
  top: 0.1rem;

  svg {
    path {
      fill: ${({ theme, $selected }) => ($selected ? theme.colors.ds.white : 'auto')};
    }
  }
`;
