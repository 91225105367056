import { useSelector } from 'react-redux';
import { getBill, getPayment } from 'src/app/redux/payBillWizard/selectors';
import { getOrgId } from 'src/app/redux/user/selectors';
import locations from 'src/app/utils/locations';
import { PayEditLocationNamesType } from 'src/app/utils/types';

export const usePayBillNavigateCommon = () => {
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);

  const getUrl = (type: PayEditLocationNamesType) => {
    const url = payment.id
      ? locations.Bills.pay.edit[type].url({
          id: bill.id,
          paymentId: payment.id,
          orgId,
        })
      : locations.Bills.pay[type].url({ id: bill.id, orgId });

    return url;
  };

  return { getUrl };
};
