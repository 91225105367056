import { noop } from 'lodash';
import type {
  OrganizationPreferencesType,
  OrganizationFeeCatalogType,
  OrganizationBillingFee,
} from '../../utils/types';
import {
  SET_ORGANIZATION_PREFERENCES,
  UPDATE_ORGANIZATION_PREFERENCE,
  UPDATE_ORGANIZATION_PREFERENCE_SUCCESS,
  UPDATE_ORGANIZATION_PREFERENCE_FAILED,
  UPDATE_SERVICE_FEES_PREFERENCE,
  LOAD_FEE_CATALOG_SUCCESS,
  LOAD_FEE_CATALOG_FAILED,
  REMOVE_FEE_FUNDING_SOURCE,
  GET_ACTIVE_FEE_FUNDING_SOURCE,
  GET_ACTIVE_FEE_FUNDING_SOURCE_SUCCESS,
  GET_ACTIVE_FEE_FUNDING_SOURCE_FAILED,
  UPDATE_FEE_FUNDING_SOURCE,
  UPDATE_ORG_FEE_FUNDING_SOURCE,
  CREATE_FEE_FUNDING_SOURCE,
  SET_FEE_FUNDING_LOADING,
  INITIAL_SET_FEE_FUNDING_SOURCE,
  CLEAR_FEE_FUNDING_SOURCE,
} from './actionTypes';

export const updateOrganizationPreferenceAction = (
  orgId: string,
  key: string,
  value: any,
  resolve: (value?: any) => void = () => null,
  reject: (error: any) => void = () => null
) => ({
  type: UPDATE_ORGANIZATION_PREFERENCE,
  orgId,
  key,
  value,
  resolve,
  reject,
});

export const updateServiceFeesPreferenceAction = (
  orgId: string,
  value: boolean,
  resolve: (value?: unknown) => void = () => null,
  reject: (error: any) => void = () => null
) => ({
  type: UPDATE_SERVICE_FEES_PREFERENCE,
  orgId,
  value,
  resolve,
  reject,
});

export const setOrganizationPreferencesAction = (
  organizationPreferences: OrganizationPreferencesType
) => ({
  type: SET_ORGANIZATION_PREFERENCES,
  organizationPreferences,
});

export const updateOrganizationPreferenceSuccessAction = (key: string, value: any) => ({
  type: UPDATE_ORGANIZATION_PREFERENCE_SUCCESS,
  key,
  value,
});

export const updateOrganizationPreferenceFailedAction = () => ({
  type: UPDATE_ORGANIZATION_PREFERENCE_FAILED,
});

export const loadFeeCatalogSuccessAction = (feeCatalog: OrganizationFeeCatalogType) => ({
  type: LOAD_FEE_CATALOG_SUCCESS,
  feeCatalog,
});

export const loadFeeCatalogFailedAction = () => ({
  type: LOAD_FEE_CATALOG_FAILED,
});

export const initialSetFeeFundingSource = (feeFundingSource?: OrganizationBillingFee) => ({
  type: INITIAL_SET_FEE_FUNDING_SOURCE,
  payload: feeFundingSource,
});

export const updateFeeFundingSource = (feeFundingSource?: OrganizationBillingFee) => ({
  type: UPDATE_FEE_FUNDING_SOURCE,
  payload: feeFundingSource,
});

export const updateOrganizationFeeFundingSource = (
  feeFundingSource: Pick<
    OrganizationBillingFee,
    'fundingSourceId' | 'managedByOrganizationId' | 'isActive'
  >,
  redirectOnSuccess: () => void
) => ({
  type: UPDATE_ORG_FEE_FUNDING_SOURCE,
  payload: { feeFundingSource, redirectOnSuccess },
});

export const createOrganizationFeeFundingSource = (
  createOrganizationFeeFundingSource,
  redirectOnSuccess
) => ({
  type: CREATE_FEE_FUNDING_SOURCE,
  payload: { createOrganizationFeeFundingSource, redirectOnSuccess },
});

export const removeOrganizationFeeFundingSource = (
  id: number,
  dismissModal: () => void = noop
) => ({
  type: REMOVE_FEE_FUNDING_SOURCE,
  payload: { organizationBillingFeeId: id, dismissModal },
});

export const getActiveFeeFundingSource = () => ({
  type: GET_ACTIVE_FEE_FUNDING_SOURCE,
});

export const getActiveFeeFundingSourceSuccess = () => ({
  type: GET_ACTIVE_FEE_FUNDING_SOURCE_SUCCESS,
});
export const getActiveFeeFundingSourceFailed = () => ({
  type: GET_ACTIVE_FEE_FUNDING_SOURCE_FAILED,
});

export const setFeeFundingLoading = (isLoading: boolean) => ({
  type: SET_FEE_FUNDING_LOADING,
  payload: isLoading,
});

export const clearFeeFundingSource = () => ({
  type: CLEAR_FEE_FUNDING_SOURCE,
});
