import { BILL_STATUS, PAYMENT_STATUS, PAYMENT_APPROVAL_STATUS } from './consts';
import { isPaymentCompletedRefund, isPaymentScheduleRefund, isPaymentCompleted } from './payments';
import type { StatusInfoType, PaymentType } from './types';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';

const getStatusByApprovalDecision = ({ theme, approvalDecisionStatus }) => {
  let statusInfo;

  switch (approvalDecisionStatus) {
    case PAYMENT_APPROVAL_STATUS.PENDING:
      statusInfo = {
        status: PAYMENT_APPROVAL_STATUS.PENDING,
        color: theme.colors.warning.opaque,
        colorOpacity: theme.colors.warning.translucent1,
        label: 'bills.status.paymentPendingApproval',
        icon: 'icon-notification-icon',
      };
      break;

    case PAYMENT_APPROVAL_STATUS.DECLINED:
      statusInfo = {
        status: PAYMENT_APPROVAL_STATUS.DECLINED,
        color: theme.colors.failure.opaque,
        colorOpacity: theme.colors.failure.translucent1,
        label: 'bills.status.paymentDeclined',
        icon: 'icon-notification-icon',
      };
      break;

    default:
      break;
  }

  return statusInfo;
};

const getStatusInfo = (
  statusVerbose: Record<string, any>,
  theme: Record<string, any>,
  payment: PaymentType | null,
  isManuallyPaid = false,
  isRequestAndHasNotDeliveryMethods = false,
  vendorName = ''
): StatusInfoType | null => {
  let statusInfo;
  const { status } = statusVerbose;
  const isTimelineFeatureEnabled = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.PAYMENT_DETAILS_TIMELINE,
    false
  );
  const isRefundFlowFeature = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );

  const approvalDecisionStatus = payment?.approvalDecisionStatus;

  statusInfo = getStatusByApprovalDecision({ theme, approvalDecisionStatus });

  if (statusInfo) {
    return statusInfo;
  }

  const isRefundScheduled = payment ? isPaymentScheduleRefund(payment) : false;
  const isRefundCompleted = payment ? isPaymentCompletedRefund(payment) : false;
  const isCompleted = payment ? isPaymentCompleted(payment) : false;

  const getFailedPaymentColorOpacity = () => {
    if (isRefundCompleted) return theme.colors.ds.green[700];

    if (isRefundScheduled) return theme.colors.ds.teal[600];

    return theme.colors.ds.red[700];
  };

  const getFailedPaymentTextColor = () => {
    if (isRefundCompleted) return theme.colors.ds.green[200];

    if (isRefundScheduled) return theme.colors.ds.teal[100];

    return theme.colors.failure.opaque;
  };

  const getFailedPaymentIcon = () => {
    if (isRefundCompleted) return 'checked';

    if (isRefundScheduled) return 'sent';

    return null;
  };

  const getFailedPaymentLabel = () => {
    if (isRefundCompleted) return 'bills.status.paymentRefundCompleted.title';

    if (isRefundScheduled)
      return `bills.status.paymentRefundScheduled.refund${
        isRefundFlowFeature ? 'Progress' : 'Sent'
      }`;

    return 'bills.status.paymentFailed';
  };

  const getPaidLabel = () => {
    if (isManuallyPaid) return 'bills.status.paymentManuallyPaid';

    if (isRefundCompleted) return 'bills.status.paymentRefundCompleted.title';

    if (isRefundScheduled)
      return `bills.status.paymentRefundScheduled.refund${
        isRefundFlowFeature ? 'Progress' : 'Sent'
      }`;

    if (isTimelineFeatureEnabled) {
      return `bills.status.${isCompleted ? 'paymentPaid' : 'paymentProcessing'}`;
    }

    return 'bills.status.paymentPaid';
  };

  const defaultStatusInfo = {
    status: isTimelineFeatureEnabled ? PAYMENT_STATUS.IN_PROGRESS : status,
    color: theme.colors[isTimelineFeatureEnabled ? 'processing' : 'success'].opaque,
    colorOpacity: theme.colors[isTimelineFeatureEnabled ? 'processing' : 'success'].translucent1,
    label: getPaidLabel(),
    icon: `icon-full-${isTimelineFeatureEnabled ? 'process' : 'checkmark'}-icon`,
  };

  switch (status) {
    case BILL_STATUS.SCHEDULED:
    case PAYMENT_STATUS.BLOCKED:
    case PAYMENT_STATUS.SCHEDULED:
      statusInfo = {
        status,
        color: theme.text.color.blue,
        colorOpacity: theme.colors.progress.scheduled,
        label: 'bills.status.paymentScheduled',
        icon: 'icon-scheduled-bill',
        description: '',
      };
      break;
    case BILL_STATUS.UNPAID:
      if (isRequestAndHasNotDeliveryMethods) {
        statusInfo = {
          status,
          color: theme.colors.failure.opaque,
          colorOpacity: theme.colors.failure.translucent1,
          label: 'bills.status.cantSchedulePayment.title',
          icon: 'icon-notification-icon',
          description: 'bills.status.cantSchedulePayment.description',
          descriptionValues: { vendorName },
        };
      } else {
        statusInfo = null;
      }

      break;
    case PAYMENT_STATUS.FAILED:
      statusInfo = {
        status,
        color: getFailedPaymentTextColor(),
        colorOpacity: getFailedPaymentColorOpacity(),
        label: getFailedPaymentLabel(),
        icon: getFailedPaymentIcon(),
      };
      break;
    case PAYMENT_APPROVAL_STATUS.PENDING:
      statusInfo = {
        status,
        color: theme.colors.warning.opaque,
        colorOpacity: theme.colors.warning.translucent1,
        label: 'bills.status.paymentPendingApproval',
        icon: 'icon-payment-pending-approval',
      };
      break;
    case PAYMENT_APPROVAL_STATUS.DECLINED:
      statusInfo = {
        status,
        color: theme.colors.failure.opaque,
        colorOpacity: theme.colors.failure.translucent1,
        label: 'bills.status.paymentDeclined',
        icon: 'icon-decline-icon',
      };
      break;
    case BILL_STATUS.PAID:
      statusInfo =
        isCompleted && isTimelineFeatureEnabled
          ? {
              status,
              color: theme.colors.success.opaque,
              colorOpacity: theme.colors.success.translucent1,
              label: getPaidLabel(),
              icon: 'icon-full-checkmark-icon',
            }
          : defaultStatusInfo;

      break;
    default:
      statusInfo = defaultStatusInfo;
      break;
  }

  return statusInfo;
};

export { getStatusInfo };
