import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useBreak } from 'src/app/hoc';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { SupportLine } from 'src/app/components/common/OutsideLayout/SupportLine';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from 'src/app/pages/vendor/vendor-payment-tracking/locations';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import exclamationMark from 'src/app/images/qbo/exclamation-mark-icon.svg';
import { CONSTS } from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';
import { useShiftVendorToACHState } from 'src/app/pages/vendor/shift-vendor-to-ach/hoc/shift-vendor-to-ach-hooks';
import {
  BackButton,
  SubmitButtonMobile,
} from 'src/app/pages/vendor/components/VendorLayoutElements';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import { getIsVendorPaymentDetailsFlow } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import selectExpedited from '../select-expedited/locations';

type Props = {
  token: string;
  vendorId: string;
};

const QBOAddDeliveryMethodPage = ({ vendorId, token }: Props) => {
  const { isMobile } = useBreak();
  const history = useHistory();
  const site = useSiteContext();
  const { orgId } = getJWTPayload(token);
  const [submit, deliveryMethodMV, isLoading, editMode] = useShiftVendorToACHState(
    vendorId,
    orgId,
    token
  );
  const submitLabel = editMode
    ? 'vendors.deliveryMethods.ach.achDetails.buttonEdit'
    : 'vendors.deliveryMethods.ach.achDetails.buttonSave';

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <Box textAlign="center" marginBottom={5} marginTop={10}>
        <QBLogo />
      </Box>
      <Content>
        {!isMobile && (
          <Box
            position={isMobile ? 'relative' : 'absolute'}
            left={isMobile ? 0 : 5}
            top={isMobile ? 0 : 5}
          >
            <BackButton
              onPrev={() => {
                if (getIsVendorPaymentDetailsFlow(token)) {
                  history.push(
                    generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token })
                  );
                } else {
                  history.push(generatePath(selectExpedited.shiftCheckDeliveryMethod, { token }));
                }
              }}
              isAbsolutePosition={false}
            />
          </Box>
        )}
        <Title>
          <Box>
            <MIFormattedText label="vendors.deliveryMethods.ach.achDetails.title" />
          </Box>
        </Title>

        <SubTitle>
          <MIFormattedText label="vendors.deliveryMethods.ach.achDetails.subTitle" />
        </SubTitle>

        <Separator />

        <DialogTitle>
          <MIFormattedText label="vendors.deliveryMethods.ach.achDetails.formTitle" />
        </DialogTitle>

        <FormContainer onSubmit={submit}>
          <RowContainer>
            <InlineTextInputField
              label="vendors.addDeliveryMethodByLink.routingNumber"
              model={deliveryMethodMV?.bankAccount.routingNumber}
              required
              autoFocus
              privateData
            />
            <InlineTextInputField
              label="vendors.addDeliveryMethodByLink.accountNumber"
              model={deliveryMethodMV?.bankAccount.accountNumber}
              required
              privateData
            />
          </RowContainer>
        </FormContainer>

        {isMobile ? (
          <SubmitButtonMobile label={submitLabel} submit={submit} isLoading={isLoading} />
        ) : (
          <MIButton
            label={submitLabel}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={submit}
            isProcessing={isLoading}
            fullWidth
          />
        )}
        <Info>
          <ExclamationMark src={exclamationMark} />
          <InfoText>
            <MIFormattedText
              label="vendors.deliveryMethods.ach.achDetails.info"
              values={{
                termsOfService: (
                  <SupportLink href={site.config.agreementLinks.termsOfService} target="_blank">
                    <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.termsOfService" />
                  </SupportLink>
                ),
                privacyPolicy: (
                  <SupportLink href={site.config.agreementLinks.privacyPolicy} target="_blank">
                    <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.privacyPolicy" />
                  </SupportLink>
                ),
                intuitPrivacyPolicy: (
                  <SupportLink
                    href={site.config.agreementLinks.intuitPrivacyPolicy}
                    target="_blank"
                  >
                    <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.intuitPrivacyPolicy" />
                  </SupportLink>
                ),
              }}
            />
          </InfoText>
        </Info>
      </Content>
      <SupportLine __css={supportLineStyle} />
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 4rem;
  border-radius: 0.6rem;
  border: 1px solid ${(props) => props.theme.colors.shadow.lightGrey};
`;

const Separator = styled.div`
  height: 0.4rem;
  background-color: ${(props) => props.theme.background.wizard};
  margin: 2rem 0 4rem;
  width: 100%;
  margin: 3rem 0;
`;

const Title = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1.4rem;
  font-size: 2.4rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 3.2rem;
  margin-bottom: 1rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
`;

const DialogTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.normal};
  line-height: 2rem;
  margin-bottom: 2rem;
`;

const Info = styled.div`
  display: flex;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  color: ${(props) => props.theme.text.color.subtitle};
  padding-top: 2rem;
`;

const InfoText = styled.div`
  text-align: left;
  line-height: ${(props) => props.theme.text.lineHeight.hint};
`;

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
`;

const ExclamationMark = styled.img`
  width: 2rem;
  margin-right: 1.2rem;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 0.5rem;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  > * {
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    > * {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 2rem;
      flex: 1;
    }
  }
`;

const RowContainer = styled(FormRow)`
  align-items: flex-start;

  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;
  }
`;

const supportLineStyle = {
  m: ['2rem 0 5rem', '2rem 0'],
};

export default QBOAddDeliveryMethodPage;
