import { RecordOf } from 'immutable';
import React, { useMemo } from 'react';
import { generatePath, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { convertStringToUrlObject } from 'src/app/utils/query-utils';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import compact from 'lodash/compact';
import head from 'lodash/head';
import deliveryMethodsStore, {
  getDeliveryMethodActions,
} from 'src/app/modules/delivery-methods/delivery-methods-store';
import { getOrgId, getOwnedVendorId } from 'src/app/redux/user/selectors';
import { CONSTS, DELIVERY_METHOD_ORIGIN, DELIVERY_METHOD_CHECK_FLOW } from 'src/app/utils/consts';
import pickBy from 'lodash/pickBy';
import { CheckType, EditableDeliveryMethodType } from 'src/app/utils/types';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { selectNewDeliveryMethodAction } from 'src/app/redux/payBillWizard/actions';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import {
  AddFundingSourceWizardOriginEnum,
  DbAnalyticsTraitsEnum,
} from 'src/app/version-2/model/enums';
import EditCheckAddressWithAutocompleteDeliveryMethodPage from './components/EditCheckAddressWithAutocompleteDeliveryMethodPage';
import { getEventPageName } from './utils';

const EditCheckAddressWithAutocompleteDeliveryMethodPageContainer = () => {
  const location = useLocation<{
    check?: RecordOf<CheckType>;
    exitUrl?: string;
    redirectUrl?: string;
    origin: DELIVERY_METHOD_ORIGIN | AddFundingSourceWizardOriginEnum.BATCH_BULK;
    preservedState?: any;
    fundingSource: any;
    isFundingSourceVerified?: boolean;
    isVoidCheck?: boolean;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch<{
    orgId: string;
    id?: string;
    deliveryMethodId: string;
  }>();
  const site = useSiteContext();
  const vendorId = match.params?.id;
  const deliveryMethodId = match.params?.deliveryMethodId;
  const check = location.state?.check;
  const deliveryMethodActions = getDeliveryMethodActions(dispatch);
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const isUpdating =
    (
      useSelector(deliveryMethodsStore.selectors.update.status(deliveryMethodId)) as {
        loading: boolean;
      }
    )?.loading || false;
  const isCreating = (
    useSelector(deliveryMethodsStore.selectors.create.status()) as { loading: boolean }
  )?.loading;
  const { redirectUrl, exitUrl, fundingSource } = location?.state || {};
  const isDMOriginFromReturnedCheck =
    location.state?.origin === DELIVERY_METHOD_ORIGIN.RETURNED_CHECK;
  const paymentStoreActions = useStoreActions(paymentStore);
  const isVoidCheck = location.state?.isVoidCheck;

  useFetchVendor(vendorId);
  const eventPage = useMemo(
    () => getEventPageName(ownedVendorId, vendorId as string),
    [ownedVendorId, vendorId]
  );

  const goNext = (newDeliveryMethod) => {
    const nextStepPath = redirectUrl ?? '';
    const parsedNextUrl = convertStringToUrlObject(generatePath(nextStepPath, { orgId }));

    history.push({
      ...parsedNextUrl,
      state: {
        ...location.state?.preservedState,
        newDeliveryMethod,
      },
    });
  };
  const goUnilateralFlow = () => {
    const nextStepPath = locations.Vendors.deliveryMethods.virtual.create.url({
      orgId,
      id: vendorId,
    });

    history.push({
      pathname: nextStepPath,
      state: {
        ...location.state?.preservedState,
        redirectUrl,
        exitUrl,
        origin: location.state?.origin,
        changeDeliveryMethodType: true,
      },
    });
  };

  const goExit = () => {
    analytics.track(eventPage, 'exit');

    if (location.state?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK) {
      history.goBack();

      return;
    }

    const nextStepPath = head(compact([exitUrl, redirectUrl]));
    const parsedNextUrl = convertStringToUrlObject(generatePath(nextStepPath as string, { orgId }));

    history.push({
      ...parsedNextUrl,
      state: {
        ...location.state?.preservedState,
      },
    });
  };
  // TODO: replace analytics events with events-mapping (Yotam/Dima).
  const onSuccess = async (paperCheck: CheckType, isVerified: boolean) => {
    const deliveryMethod: EditableDeliveryMethodType = {
      paperCheck,
      isVerified,
      deliveryType: CONSTS.DELIVERY_TYPE.CHECK,
    };
    let response;

    if (deliveryMethodId && vendorId) {
      analytics.track(eventPage, 'edit-delivery-method', {
        type: CONSTS.DELIVERY_TYPE.CHECK,
        vendorId,
      });
      response = await deliveryMethodActions.edit({
        orgId,
        vendorId,
        id: deliveryMethodId,
        deliveryMethod,
      });
      analytics.track(eventPage, 'edit-delivery-method-success', {
        type: CONSTS.DELIVERY_TYPE.CHECK,
        vendorId,
      });
    } else {
      if (ownedVendorId && ownedVendorId?.toString() === vendorId?.toString()) {
        analytics.setTraits({
          [DbAnalyticsTraitsEnum.ADDED_DELIVERY_METHOD]: true,
        });
      }

      analytics.track(eventPage, 'add-delivery-method', {
        type: CONSTS.DELIVERY_TYPE.CHECK,
      });
      response = await deliveryMethodActions.create({
        orgId,
        vendorId,
        params: deliveryMethod,
      });
      analytics.track(eventPage, 'add-delivery-method-success', {
        type: CONSTS.DELIVERY_TYPE.CHECK,
      });
    }

    const newDeliveryMethod = response.payload;

    if (location.state?.origin === DELIVERY_METHOD_ORIGIN.PAY_BILL || isDMOriginFromReturnedCheck) {
      dispatch(selectNewDeliveryMethodAction(newDeliveryMethod));
    }

    if (location.state?.origin === DELIVERY_METHOD_ORIGIN.JUST_PAY) {
      await paymentStoreActions.justPay.justPayWizard.update({
        deliveryMethodId: newDeliveryMethod.id,
        deliveryMethodType: newDeliveryMethod.deliveryType,
      });
    }

    if (location.state?.preservedState?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK) {
      dispatch(selectNewDeliveryMethodAction(newDeliveryMethod));
      history.push(location.state.redirectUrl as string, {
        ...location.state.preservedState,
        newDeliveryMethod,
      });

      return;
    }

    goNext(newDeliveryMethod);
  };

  const checkObj = useMemo(() => (check?.toJS ? pickBy(check?.toJS()) : {}), [check]);

  const shouldGoUnilateral =
    location.state?.isFundingSourceVerified &&
    site.goUnilateralInvalidAddress &&
    !isManualBankAccountNotVerified(fundingSource);

  const flowType = shouldGoUnilateral
    ? DELIVERY_METHOD_CHECK_FLOW.UNILATERAL
    : DELIVERY_METHOD_CHECK_FLOW.CONFIRM;

  return (
    <EditCheckAddressWithAutocompleteDeliveryMethodPage
      check={checkObj}
      onSuccess={onSuccess}
      goExit={goExit}
      vendorId={vendorId as string}
      isLoading={isUpdating || isCreating}
      goUnilateralFlow={goUnilateralFlow}
      flowType={flowType}
      isReturnedCheck={isDMOriginFromReturnedCheck}
      isVoidCheck={isVoidCheck}
    />
  );
};

export default EditCheckAddressWithAutocompleteDeliveryMethodPageContainer;
