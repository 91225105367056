import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import analytics from 'src/app/services/analytics';
import { getOrgId } from 'src/app/redux/user/selectors';
import { getPartialBillId } from 'src/app/utils/bills';
import locations from 'src/app/utils/locations';
import { melioClose } from 'src/app/utils/external-events';
import { BillType, DeliveryMethodType, NavigateType } from 'src/app/utils/types';
import {
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
  BILL_STATUS,
  CARD_TYPES,
  DELIVERY_TYPE,
  DELIVERY_METHOD_ORIGIN,
} from 'src/app/utils/consts';
import { getAddDeliveryMethodUrlMap } from 'src/app/utils/delivery-methods';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { useBreak } from 'src/app/hoc';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { useFundingSourceStatelessNavigate } from '../hooks/navigation/useFundingSourceStatelessNavigate';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Params = {
  mainFlowExitUrl?: string;
  eventPage: string;
  navigate: NavigateType;
};

type GoNextParams = {
  bill: BillType;
  selectedFundingSource: FundingSource;
  defaultDeliveryMethod?: DeliveryMethodType;
};

type GoAddFundingSourceParams = {
  bill: BillType;
  fundingSourceTypeToAdd: string;
  defaultDeliveryMethod?: DeliveryMethodType;
};

type GetRedirectUrlParams = {
  billId: string;
  defaultDeliveryMethod?: DeliveryMethodType;
  fundingSourceTypeToAdd?: string;
  vendorId?: number;
};

export const useBackgroundSyncEntryNavigation = ({
  mainFlowExitUrl,
  eventPage,
  navigate,
}: Params) => {
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const { isMobile } = useBreak();
  const orgId = useSelector(getOrgId);
  const { goNextFundingSource } = useFundingSourceStatelessNavigate({ navigate });

  const getRedirectUrl = ({
    billId,
    defaultDeliveryMethod,
    fundingSourceTypeToAdd,
    vendorId,
  }: GetRedirectUrlParams) => {
    const isInternational = defaultDeliveryMethod?.deliveryType === DELIVERY_TYPE.INTERNATIONAL;

    if (
      isInternational &&
      (fundingSourceTypeToAdd === CARD_TYPES.CREDIT ||
        fundingSourceTypeToAdd === FundingSourceTypesEnum.ACH)
    ) {
      return locations.Bills.pay.funding.url({ id: billId });
    }

    if (defaultDeliveryMethod) {
      return locations.Bills.pay.date.url({ id: billId });
    }

    return locations.Bills.pay.deliveryMethod.url({ id: billId });
  };
  const getExitUrl = (billId: string) => locations.Bills.pay.funding.url({ id: billId });

  const goExit = () => {
    if (mainFlowExitUrl) {
      history.push(mainFlowExitUrl);
    } else if (isMobile) {
      const defaultFilters = getBillsDefaultFilters(BILL_STATUS.UNPAID);
      const path = locations.Bills.filteredViewWithoutId.url({ orgId, ...defaultFilters });

      history.push(path);
    } else {
      melioClose();
    }
  };

  const goNext = async ({ selectedFundingSource, bill, defaultDeliveryMethod }: GoNextParams) => {
    const isInternational = defaultDeliveryMethod?.deliveryType === DELIVERY_TYPE.INTERNATIONAL;

    const exitUrl = getExitUrl(bill.id);
    const redirectUrl = getRedirectUrl({ billId: bill.id, defaultDeliveryMethod });

    await goNextFundingSource({
      bill,
      selectedFundingSource,
      isInternational,
      redirectUrl,
      exitUrl,
    });
  };

  const goAddFundingSource = ({
    fundingSourceTypeToAdd,
    bill,
    defaultDeliveryMethod,
  }: GoAddFundingSourceParams) => {
    let path;
    let eventName;

    if (fundingSourceTypeToAdd === FundingSourceTypesEnum.ACH) {
      path = locations.Onboarding.fundingSources.bank.select.url();
      eventName = 'add-bank-account';
    } else if (
      [CARD_TYPES.CREDIT, CARD_TYPES.DEBIT].includes(fundingSourceTypeToAdd as CARD_TYPES)
    ) {
      path = locations.Onboarding.fundingSources.card.index.url();
      eventName =
        fundingSourceTypeToAdd === CARD_TYPES.CREDIT ? 'add-credit-card' : 'add-debit-card';
    }

    if (path) {
      const state = {
        preservedState: { origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL },
        redirectUrl: getRedirectUrl({
          billId: bill.id,
          defaultDeliveryMethod,
          fundingSourceTypeToAdd,
          vendorId: bill.vendorId,
        }),
        exitUrl: getExitUrl(bill.id),
      };

      analytics.track(eventPage, eventName, { partialBillId: getPartialBillId(bill) });
      historyPush({ path, state });
    }
  };

  const goAddDeliveryMethod = ({ type, billId, vendorId, fundingSourceId }) => {
    const redirectUrl = `${locations.Bills.pay.funding.url({
      id: billId,
    })}?initialStep=DM&collapsible=true`;
    const exitUrl = `${locations.Bills.pay.funding.url({ id: billId })}?initialStep=DM`;

    const deliveryMethodUrlMap = getAddDeliveryMethodUrlMap(orgId, vendorId);
    const url = deliveryMethodUrlMap[type || DELIVERY_TYPE.ACH];

    navigate(url, false, {
      origin: DELIVERY_METHOD_ORIGIN.PAY_BILL,
      redirectUrl,
      exitUrl,
      fundingSourceId,
      billId,
    });
  };

  return { goExit, goNext, goAddFundingSource, goAddDeliveryMethod };
};
