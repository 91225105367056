import analytics from 'src/app/services/analytics';
import { analyticsApi } from 'src/app/services/analytics/guest-analytics-api';
import { getJWTPayload } from 'src/app/version-2/utils/jwtToken.utils';
import { Payment } from '../../../model/dtos';
import { UpsellType } from '../model/enums/upsellType.enum';

type AnalyticsOptionName = 'fast-ach' | 'debit-card' | 'rtp';

type ExtraAnalyticsProps = Record<string, unknown>;

export const vendorUpgradePaymentClientEventNames = {
  OPTIONS_PAGE_VIEW: 'view',
  SELECT_OPTION: 'selected',
  SUCCESS_PAGE_VIEW: 'payment-success',
  OFFER_EXPIRED: 'offer_expired',
} as const;

export const vendorUpgradePaymentServerEventNames = {
  OPTIONS_PAGE_VIEW: 'P2FAST-VendorOpened',
  OFFER_EXPIRED: 'P2FAST-VendorOfferExpired',
  SELECT_OPTION: 'P2FAST-VendorOptionSelected',
  SUCCESS_PAGE_VIEW: 'P2FAST-VendorPaymentSuccess',
} as const;

export const upsellTypeToAnalyticsOptionName: Record<UpsellType, AnalyticsOptionName> = {
  [UpsellType.PUSH_TO_FAST_ACH]: 'fast-ach',
  [UpsellType.PUSH_TO_DEBIT]: 'debit-card',
  [UpsellType.PUSH_TO_RTP]: 'rtp',
};

export function getVendorUpgradeAnalytics(token: string, payment?: Partial<Payment>) {
  const { paymentId, vendorId } = getJWTPayload(token);

  const staticAnalyticsProps = {
    paymentId: payment?.id || paymentId,
    vendorId: payment?.vendorId || vendorId,
    vendorEmail: payment?.vendor?.contactEmail,
  };

  const staticServerAnalyticsProps = {
    ...staticAnalyticsProps,
    payorOrgId: payment?.organizationId,
  };

  function trackUIAction(
    action: (typeof vendorUpgradePaymentClientEventNames)[keyof typeof vendorUpgradePaymentClientEventNames],
    props?: ExtraAnalyticsProps
  ) {
    analytics.trackAction(action, { ...staticAnalyticsProps, ...props });
  }
  function trackServerAction(
    action: (typeof vendorUpgradePaymentServerEventNames)[keyof typeof vendorUpgradePaymentServerEventNames],
    props?: ExtraAnalyticsProps
  ) {
    analyticsApi.track({
      token,
      eventName: action,
      properties: { ...staticServerAnalyticsProps, ...props },
    });
  }

  return {
    trackUIAction,
    trackServerAction,
    staticAnalyticsProps,
  };
}
