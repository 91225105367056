import { useSelector } from 'react-redux';
import { AreaLoader } from '@melio/billpay-design-system';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import styled from 'styled-components';
import { melioClose } from 'src/app/utils/external-events';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { devices } from 'src/app/theme/AppDevices';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';
import {
  useFundingSourceMicroDepositState,
  FundingSourceMicroDepositProps,
} from '../settings/hooks/useFundingSourceMicroDeposits';
import { RESOLUTIONS as SUPPORT } from '../settings/hooks/microDepositsCommon';
import { MITextInput } from '../../components/common/MITextInput';
import MINotices from '../../components/common/MINotices';
import { MicroDepositPopover } from '../../components/micro-deposits/MicroDepositPopover';
import { useMicroDepositAmountValidation } from '../../components/micro-deposits/hooks/useMicroDepositAmountValidation';
import { AccountNotFoundPage } from '../general/components/QBOAccountNotFound';
import { getMicrodepositMask } from '../../utils/currency-utils';

const eventPage = 'micro-deposits-verification-page';

const getLabels = ({ fundingSource, areDepositsNotSentYet, isBankAccountAlreadyVerified }) => {
  const labels = {
    title: 'fundingSources.microDeposits.title',
    subtitle: 'settings.microDeposits.verifyDialog.subtitle',
    buttonLabel: 'settings.microDeposits.verifyDialog.confirm',
  };

  if (!fundingSource) {
    labels.title = 'settings.microDeposits.deletedBankAccountDialog.title';
    labels.subtitle = 'settings.microDeposits.deletedBankAccountDialog.subtitle';
    labels.buttonLabel = 'settings.microDeposits.deletedBankAccountDialog.ok';
  } else if (areDepositsNotSentYet) {
    labels.title = 'settings.microDeposits.depositsNotSentYetDialog.title';
    labels.subtitle = 'settings.microDeposits.depositsNotSentYetDialog.subtitle';
    labels.buttonLabel = 'settings.microDeposits.depositsNotSentYetDialog.ok';
  } else if (isBankAccountAlreadyVerified) {
    labels.title = 'settings.microDeposits.alreadyVerifiedDialog.title';
    labels.subtitle = 'settings.microDeposits.alreadyVerifiedDialog.subtitle';
    labels.buttonLabel = 'settings.microDeposits.alreadyVerifiedDialog.ok';
  }

  return labels;
};

export default function MicroDepositsVerifyPage(props: FundingSourceMicroDepositProps) {
  const { fundingSourceId } = props;
  const [state, actions]: any = useFundingSourceMicroDepositState(eventPage, props);

  const site = useSiteContext();
  const { WizardElements, StepLayout } = site.components;
  const { onSubmit, onChange } = actions;

  const fundingSource =
    useSelector(fundingSourcesStore.selectors.byId(fundingSourceId)) || state.fundingSource;

  const areDepositsNotSentYet = fundingSource?.bankAccount?.depositsNotSent;
  const isBankAccountAlreadyVerified = !isManualBankAccountNotVerified(fundingSource);
  const accountNumber = getAccountNumber4digits(fundingSource?.bankAccount);
  const [errors, handleChange, resetErrors] = useMicroDepositAmountValidation(onChange);
  const shouldShowInputs = fundingSource && !areDepositsNotSentYet && !isBankAccountAlreadyVerified;

  const handleSubmit = () => {
    resetErrors();

    onSubmit();
  };

  const { title, subtitle, buttonLabel } = getLabels({
    fundingSource,
    areDepositsNotSentYet,
    isBankAccountAlreadyVerified,
  });

  const goToQboHomepageOverride = () => {
    melioClose();
  };

  if (state.resolution === SUPPORT.NOT_FOUND) {
    return (
      <AccountNotFoundPage
        goToQboHomepageOverride={props.isEmbedded ? goToQboHomepageOverride : undefined}
      />
    );
  }

  if (state.isLoading) {
    return <AreaLoader />;
  }

  return (
    <StepLayout
      title={title}
      subtitle={subtitle}
      nextLabel={buttonLabel}
      isLoading={state.isLoading}
      hideHeader
      subTitleValues={{ companyName: state.companyName, accountNumber }}
      {...(shouldShowInputs && { onNext: handleSubmit })}
    >
      {shouldShowInputs && (
        <>
          <WizardElements.WizardFormRow>
            <TextInputContainer>
              <MITextInput
                id="amount1"
                label="settings.microDeposits.verifyDialog.sum1Label"
                placeholder="settings.microDeposits.verifyDialog.placeholder"
                value={state.amount1}
                autoFocus
                required
                onChange={handleChange}
                errorMessage={state.validationErrors.amount1 || errors.amount1}
                size={TextInputSizeEnum.INLINE}
                mask={getMicrodepositMask()}
              />
            </TextInputContainer>
            <TextInputContainer>
              <MITextInput
                id="amount2"
                label="settings.microDeposits.verifyDialog.sum2Label"
                placeholder="settings.microDeposits.verifyDialog.placeholder"
                value={state.amount2}
                required
                onChange={handleChange}
                errorMessage={state.validationErrors.amount2 || errors.amount2}
                size={TextInputSizeEnum.INLINE}
                mask={getMicrodepositMask()}
              />
            </TextInputContainer>
          </WizardElements.WizardFormRow>
          <MicroDepositNotice>
            {state.serverError && (
              <MINotices size="wizard" errorMessage={state.serverError} align="center" />
            )}
          </MicroDepositNotice>
          <ContentContainer>
            <MicroDepositPopover
              transitionStyles={{}}
              mobileStyles={{
                [`@media${devices.mobile}`]: {
                  left: 0,
                  top: '10px',
                },
              }}
            />
          </ContentContainer>
        </>
      )}
    </StepLayout>
  );
}

const ContentContainer = styled.div`
  position: relative;
  margin-top: 0;
  margin-bottom: 3rem;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.regular};
  ${(props) => props.theme?.components?.ModalMessage?.ContentContainer}

  .chakra-popover__popper {
    left: 100px;
  }
`;

const TextInputContainer = styled.div`
  width: 100%;

  &:first-child {
    margin-right: 2rem;
  }
`;

// eslint-disable-next-line max-len
// TODO: remove this component when fixing the WizardElements.WizardStepActionsContainer to not margin top, but to margin botton
const MicroDepositNotice = styled.div`
  ${(props) => props.theme?.components?.MicroDepositNotice}
  margin-bottom: 2.2rem;
`;
