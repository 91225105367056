import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Badge } from '@melio/billpay-design-system';
import { CellContext } from '@tanstack/react-table';
import { MIFormattedText } from 'src/app/version-2/externals';
import { FormattedDeliveryDate } from 'src/app/version-2/pages/batch-bulk/components/FormattedDeliveryDate';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import {
  BatchBulkItem,
  BatchBulkPaymentIntentItem,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import { isLateDeliveryDate } from 'src/app/version-2/pages/batch-bulk/utils/deliveryDate.utils';
import { isFastDeliveryType } from 'src/app/version-2/utils/deliveryMethod.utils';
import {
  DeliveryDateLateBadgeWrapper,
  DeliveryDateNA,
  DeliveryDateWrapper,
} from './renderers.styles';

export const DeliveryDateRenderer: FC<CellContext<BatchBulkItem, Date>> = (props) => {
  const { row } = props;
  const { type, fastFee, deliveryOptions, selectedDeliveryMethod }: BatchBulkItem = row.original;
  const { formatMessage } = useIntl();

  const { dueDate }: BatchBulkPaymentIntentItem = row.original;

  const isFastDeliverySelected = !!fastFee;

  const selectedDeliveryOption = useMemo(
    () =>
      deliveryOptions?.find((option) => {
        const isFastOption = isFastDeliveryType(option?.type);

        if (isFastDeliverySelected && isFastOption) {
          return option;
        }

        if (isFastDeliverySelected) {
          return false;
        }

        return option;
      }),
    [deliveryOptions, isFastDeliverySelected]
  );

  const deliveryDateETA = selectedDeliveryOption?.deliveryDate
    ? new Date(selectedDeliveryOption.deliveryDate)
    : undefined;
  const maxDeliveryDateETA = new Date(selectedDeliveryOption?.maxDeliveryDate);

  const isPaymentLate = useMemo(
    () => isLateDeliveryDate(deliveryDateETA, dueDate),
    [deliveryDateETA, dueDate]
  );

  if (type === BatchBulkItemEnum.BILL) {
    return null;
  }

  if (!selectedDeliveryMethod) {
    return (
      <DeliveryDateNA>
        {formatMessage({ id: 'batchBulkPage.deliveryDate.estimateNA' })}
      </DeliveryDateNA>
    );
  }

  return (
    <DeliveryDateWrapper
      $isEstimateAvailable={!!deliveryDateETA}
      data-testid="delivery-date-cell"
      data-testdata={deliveryDateETA}
    >
      <FormattedDeliveryDate date={deliveryDateETA} maxDate={maxDeliveryDateETA} />
      {isPaymentLate && (
        <DeliveryDateLateBadgeWrapper>
          <Badge variant="warning">
            <MIFormattedText label="batchBulkPage.deliveryDate.late" />
          </Badge>
        </DeliveryDateLateBadgeWrapper>
      )}
    </DeliveryDateWrapper>
  );
};
