import React from 'react';
import { RecordOf } from 'immutable';
import { Box, Flex } from '@melio/billpay-design-system';

import { getDeliveryMethodInfo } from 'src/app/pages/vendor/records';
import { DeliveryMethodType, VendorType } from 'src/app/utils/types';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import MIDotsActionsMenu from 'src/app/components/common/MIDotsActionsMenu';
import { ReactComponent as InternationalIcon } from 'src/app/images/qbo/international/delivery-icon.svg';
import { geInternationalStaticFee } from 'src/app/utils/international';

import { cardContainerStyles } from './styles';

type Props = {
  vendor: RecordOf<VendorType> | VendorType;
  deliveryMethod: any;
  isDisabled: boolean;
  goEditDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
};

export const InternationalDeliveryMethodItem = ({
  deliveryMethod,
  isDisabled,
  goEditDeliveryMethod,
}: Props) => {
  const internationalFee = geInternationalStaticFee();

  return (
    <Flex __css={containerStyles(isDisabled)}>
      <Flex>
        <Box __css={iconStyles}>
          <InternationalIcon />
        </Box>
        <Info deliveryMethod={deliveryMethod} fee={internationalFee} isDisabled={isDisabled} />
      </Flex>
      {!isDisabled && (
        <Action deliveryMethod={deliveryMethod} goEditDeliveryMethod={goEditDeliveryMethod} />
      )}
    </Flex>
  );
};

const Info = ({
  deliveryMethod,
  fee,
  isDisabled,
}: Pick<Props, 'deliveryMethod'> & { fee: string; isDisabled: boolean }) => {
  const desc = isDisabled
    ? 'deliveryMethods.notDefinedInfo.international.disabled.notEligible'
    : `deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.desc`;

  return (
    <Box>
      <Box color="black">
        <MIFormattedText
          label={`deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.label`}
          values={{
            to: deliveryMethod.id ? <MIFormattedText label="deliveryMethods.to" /> : '',
          }}
        />
      </Box>
      <Box marginTop="0.5rem" color="gray.300">
        {deliveryMethod.id ? (
          getDeliveryMethodInfo({
            deliveryMethod,
          })
        ) : (
          <MIFormattedText
            label={desc}
            values={{ fee: fee ? <MIFormattedCurrency value={fee} /> : '..' }}
          />
        )}
      </Box>
    </Box>
  );
};

const Action = ({
  deliveryMethod,
  goEditDeliveryMethod,
}: Pick<Props, 'deliveryMethod' | 'goEditDeliveryMethod'>) => {
  if (deliveryMethod.id) {
    const actionOptions = [
      {
        label: 'deliveryMethods.actions.edit',
        action: () => goEditDeliveryMethod(deliveryMethod),
      },
    ];

    return (
      <Flex align="center">
        <MIDotsActionsMenu actionOptions={actionOptions} />
      </Flex>
    );
  }

  return (
    <MIInlineLink
      onClick={() => goEditDeliveryMethod(deliveryMethod)}
      label="deliveryMethods.actions.addMethod"
    />
  );
};

const containerStyles = (isDisabled) => ({
  ...cardContainerStyles,
  justifyContent: 'space-between',
  apply: 'textStyles.body3',
  fontWeight: 500,
  pointerEvents: isDisabled ? 'none' : 'auto',
  opacity: isDisabled ? 0.6 : 1,
});

const iconStyles = {
  marginRight: '1.5rem',
  svg: {
    width: '4rem',
    height: '4rem',
  },
};
