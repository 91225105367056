import { featureFlags } from '@melio/shared-web';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  CardTypeEnum,
  CheckFeeEnum,
  DeliveryEnum,
  FastFeeDeliveryEnum,
  FeatureFlagsEnum,
  FeeVariantsEnum,
} from 'src/app/version-2/model/enums';
import { billDTOToBillItemAdapter } from 'src/app/version-2/pages/batch-bulk/adapters/billDTOToBillItemAdapter';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import { BatchBulkPaymentIntentItem } from 'src/app/version-2/pages/batch-bulk/model/objects/batchBulkPaymentIntentItem';
import { batchBulkPaymentIntentItemSchema } from 'src/app/version-2/pages/batch-bulk/model/schemas/batchBulkPaymentIntentItem.schema';
import {
  preSelectedDeliveryMethod,
  preSelectedDeliveryMethods,
} from 'src/app/version-2/pages/batch-bulk/utils/deliveryMethod.utils';
import { extractPaymentFees } from 'src/app/version-2/pages/batch-bulk/utils/fee.utils';
import { isCheckFeeOpenWithPromotions } from 'src/app/version-2/utils/checkFees.utils';
import { getFirstBusinessDayFrom } from 'src/app/version-2/utils/dates.utils';
import {
  orderPaymentMethods,
  preSelectedPaymentMethod,
  preSelectedPaymentMethods,
} from 'src/app/version-2/utils/paymentMethods.utils';

export const paymentIntentDTOToPaymentIntentItemAdapter = (
  paymentIntent: BatchBulkPaymentIntent,
  maxAmounts: Record<string, number>,
  fundingSources?: FundingSource[]
): BatchBulkPaymentIntentItem => {
  const checkFeeFlag = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.CHECK_FEES,
    CheckFeeEnum.CLOSED
  );
  const checkFeeEnabledWithPromotions = isCheckFeeOpenWithPromotions(checkFeeFlag);

  let subRows;
  let billId;
  let invoiceNumber;
  const bills = paymentIntent.payment.bills || [];
  const deliveryEta = paymentIntent.payment.deliveryEta
    ? new Date(paymentIntent.payment.deliveryEta)
    : undefined;

  const maxDeliveryEta = paymentIntent.payment.maxDeliveryEta
    ? new Date(paymentIntent.payment.maxDeliveryEta)
    : undefined;

  if (bills.length === 1) {
    billId = bills[0].id;
    invoiceNumber = bills[0].invoiceNumber;
  } else {
    subRows = bills?.map((bill) =>
      billDTOToBillItemAdapter({
        bill,
        paymentIntentId: paymentIntent.id,
        maxAmount: maxAmounts[bill.id],
        deliveryEta,
      })
    );
  }

  const dueDate = paymentIntent.payment.bills?.length
    ? new Date(Math.min(...bills.map((bill) => new Date(bill.dueDate).getTime())))
    : new Date();

  const minScheduledDate = paymentIntent.minScheduledDate
    ? new Date(paymentIntent.minScheduledDate)
    : getFirstBusinessDayFrom(new Date());
  const deductionDate = paymentIntent.payment.scheduledDate || minScheduledDate;
  const qboSyncVersion = subRows ?? bills[0]?.qboSyncVersion;

  const fees = extractPaymentFees(paymentIntent);
  let { fee, feeType } = fees;

  if (checkFeeEnabledWithPromotions && feeType === FeeVariantsEnum.ACH_TO_CHECK) {
    // promotions fee are calculated manually (temp code)
    fee = 0;
    feeType = '';
  }

  const feeIncludeAdditional = (fee || 0) + (fees.fastFee || 0) + (fees.internationalFee || 0);

  const orderedPaymentMethods = orderPaymentMethods(fundingSources);

  const paymentMethod = orderedPaymentMethods?.find(
    ({ id }) => id === paymentIntent.payment.fundingSourceId
  );

  const selectedPaymentMethod = preSelectedPaymentMethod({
    defaultPaymentMethod: paymentMethod,
  });

  const paymentMethods = preSelectedPaymentMethods({
    fundingSources: orderedPaymentMethods,
    selectedDeliveryMethod: paymentIntent.payment.deliveryMethod,
  });

  const selectedDeliveryMethod = preSelectedDeliveryMethod({
    deliveryMethod: paymentIntent.payment.deliveryMethod,
  });

  const deliveryMethods = preSelectedDeliveryMethods({
    deliveryMethods: paymentIntent.payment.vendor.deliveryMethods.filter(
      (dm) => dm.deliveryType !== DeliveryEnum.CARD
    ),
    selectedPaymentMethod: paymentMethod,
  });

  const totalBillIds = bills.map((bill) => bill.id);
  const totalPartialBillIds = bills
    .filter((bill) => (bill.balance || 0) < maxAmounts[bill.id])
    .map((bill) => bill.id);

  const getIsVendorEmailMissing = () => {
    if (paymentIntent.payment.deliveryMethod?.virtualAccount?.email) return false;

    if (paymentIntent.payment.deliveryMethod?.virtualCardAccount?.accountEmail) return false;

    return !paymentIntent.payment.vendor.contactEmail;
  };

  const isDeliveryOptionSupported = (deliveryOption) => {
    if (deliveryOption.type === FastFeeDeliveryEnum.OVERNIGHT_CHECK) {
      return false;
    }

    if (
      selectedPaymentMethod?.cardAccount?.cardType === CardTypeEnum.DEBIT &&
      deliveryOption.type === FastFeeDeliveryEnum.EXPEDITED_ACH
    ) {
      return false;
    }

    return true;
  };

  const enrichDeliveryOption = (deliveryOption) => ({
    ...deliveryOption,
    deliveryDate: new Date(deliveryOption.deliveryDate),
    maxDeliveryDate: new Date(deliveryOption.maxDeliveryDate),
    minScheduledDate: new Date(deliveryOption.minScheduledDate),
    scheduledDate: new Date(deliveryOption.scheduledDate),
  });

  const deliveryOptions = paymentIntent?.deliveryOptions
    .filter(isDeliveryOptionSupported)
    .map(enrichDeliveryOption);
  const result = {
    id: paymentIntent.id,
    type: BatchBulkItemEnum.BATCH_BULK_PAYMENT_INTENT,
    subRows,
    billId,
    selectedPaymentMethod,
    paymentMethods,
    deductionDate,
    selectedDeliveryMethod,
    deliveryMethods,
    deliverySpeedEnabled: deliveryOptions?.length > 1,
    deliveryOptions,
    deliveryEta,
    maxDeliveryEta,
    dueDate,
    amount:
      paymentIntent.payment.bills
        ?.map((bill) => bill.balance || 0)
        .reduce((sum, cur) => sum + cur) || 0,
    companyName: paymentIntent.payment.vendor.companyName as string,
    invoiceNumber,
    minScheduledDate,
    fee,
    feeType,
    internationalFee: fees.internationalFee,
    fastFee: fees.fastFee,
    feeIncludeAdditional,
    purpose: paymentIntent.payment?.purpose,
    qboSyncVersion,
    isVendorEmailMissing: getIsVendorEmailMissing(),
    mccCode: paymentIntent?.mccCode || undefined,
    vendorId: paymentIntent.payment.vendor.id,
    totalBillIds,
    totalPartialBillIds,
    contactEmail: paymentIntent.payment.vendor?.contactEmail,
  };

  try {
    batchBulkPaymentIntentItemSchema.validateSync(result, { abortEarly: false });
  } catch (e: any) {
    return {
      ...result,
      errors: e.inner,
    };
  }

  return result;
};
