import React from 'react';
import styled from 'styled-components';
import MIIconButton from '../common/MIIconButton';
import { MIFormattedText } from '../../utils/formatting';
import { EmptyListButtonContainer } from '../layout/Containers';
import fullMoonImage from '../../images/general/full-moon.svg';
import { devices } from '../../theme/AppDevices';
import ZeroState from './ZeroState';

type Props = {
  zeroStateTitle?: string;
  ctaLabel?: string;
  ctaAction?: (event: React.SyntheticEvent<HTMLInputElement>) => void | string | Promise<void>;
  text: string;
  textValues?: Record<string, any>;
  isHideActionButton?: boolean;
  showZeroState?: boolean;
  // TODO - fix empty list height calculation
  zeroStateOptions?: Record<string, any>[];
  ctaZeroState?: string;
  zeroStateAction?: () => void;
  showVerifyMdIndicator?: boolean;
};

const EmptyList = ({
  zeroStateTitle = '',
  ctaLabel = '',
  ctaAction,
  text,
  textValues,
  isHideActionButton = true,
  showZeroState = false,
  zeroStateOptions = [],
  ctaZeroState = '',
  zeroStateAction,
  showVerifyMdIndicator = false,
}: Props) => (
  <EmptyListContainer>
    {!isHideActionButton && (
      <EmptyListButtonContainer>
        {ctaAction && ctaLabel && (
          <MIIconButton
            icon="icon-plus-icon"
            label={ctaLabel}
            onClick={ctaAction}
            variant="inlineIconAndTextPrimary"
            size="normal"
            testId={`button-${ctaLabel}`}
          />
        )}
      </EmptyListButtonContainer>
    )}

    {showZeroState && (
      <ZeroState
        title={zeroStateTitle}
        options={zeroStateOptions}
        ctaZeroState={ctaZeroState}
        zeroStateAction={zeroStateAction}
      />
    )}

    <MiddleContainer showZeroState={showZeroState} showVerifyMdIndicator={showVerifyMdIndicator}>
      <EmptyListImage src={fullMoonImage} />
      <SubTitle>
        <MIFormattedText label={text} values={textValues} />
      </SubTitle>
    </MiddleContainer>
  </EmptyListContainer>
);

export default EmptyList;

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${devices.mobile}, ${devices.phablet} {
    height: calc(100vh - 46rem);
  }
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(props) => (props.showZeroState ? 'auto' : '100%')};
  justify-content: ${(props) => (props.showZeroState ? '' : 'center')};
  padding-bottom: ${(props) => (props.showZeroState ? '10rem' : 0)};
  margin-top: ${(props) => (props.showVerifyMdIndicator ? '2.3rem' : '20rem')};

  @media ${devices.tablet}, ${devices.desktop} {
    height: auto;
  }

  @media ${devices.desktop} {
    top: 48.5rem;
  }

  @media ${devices.tablet} {
    top: 51.5rem;
  }
`;

const EmptyListImage = styled.img`
  width: 10rem;
  height: 10rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.label};
  margin-top: 2rem;
  text-align: center;
`;
