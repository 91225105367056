import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import { useHasFeatureFlag } from 'src/app/hooks/useHasFeatureFlag';
import styled from 'styled-components';
import { AreaLoader } from '@melio/billpay-design-system';
import doneImage from 'src/app/images/qbo/check-circle.svg';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { SupportLine } from 'src/app/components/common/OutsideLayout/SupportLine';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import paymentStore from 'src/app/modules/payments/payment-store';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import { MIFormattedText, MIFormattedCurrency, MIFormattedDate } from 'src/app/utils/formatting';
import { UnilateralPaymentProps } from 'src/app/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { devices } from 'src/app/theme/AppDevices';
import { getJWTPayload } from 'src/app/helpers/jwt';
import ConvertedUnilateralPaymentActivity from 'src/app/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import vendorsLocations from 'src/app/pages/vendor/locations';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { CONSTS } from 'src/app/utils/consts';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import {
  QBOBusinessDirectoryUpsell,
  BUSINESS_DIRECTORY_UPSELL_ORIGIN,
} from 'src/app/pages/vendor/components/QBOBusinessDirectoryUpsell';
import QBOJoinService from 'src/app/pages/vendor/components/QBOJoinService';
import analytics from 'src/app/services/analytics';

type Props = UnilateralPaymentProps;

const QBOAddDeliveryMethodSuccessPage = (props: Props) => {
  const history = useHistory();
  const { token } = props;
  const { paymentId } = getJWTPayload(token);
  const payment: any = useSelector(paymentStore.selectors.byId(paymentId));
  const paymentActions = useStoreActions(paymentStore);
  const { isFeatureFlagEnabled } = useHasFeatureFlag();
  const vendorFlowsViralityTestEnabled = isFeatureFlagEnabled('vendorFlowsViralityTest');

  useEffect(() => {
    if (!payment) {
      paymentActions
        .fetchPaymentDetailsWithToken({ token, action: 'unilateralPayment' })
        .catch(() => {
          history.push(generatePath(vendorsLocations.unilateral.invalidToken, { token }));
        });
    }
  }, [paymentActions, token, history, payment]);

  useEffect(() => {
    if (payment) {
      analytics.trackAction('add-delivery-method-success-page-loaded', {
        vendorId: payment.vendor.id,
        paymentId: payment.id,
        vendorEmail: payment.vendor?.contactEmail,
        payorOrgId: payment.organization.id,
        isEligibleToVirtualCard: payment.isEligibleToVirtualCard,
      });
    }
  }, [payment]);

  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const organization: any = useSelector(
    organizationStore.selectors.byId(payment?.organization?.id)
  );
  const deliveryMethod: any = useSelector(
    deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId)
  );

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  const utmCampaign = `unilateral_${deliveryMethod?.deliveryType}`;
  const isVirtualCardDeliveryMethod =
    deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;

  const successTitle = isVirtualCardDeliveryMethod
    ? 'vendors.addDeliveryMethodByLink.success.virtualCard.title'
    : 'vendors.addDeliveryMethodByLink.success.title';

  let successSubtitle = payment?.bill?.invoiceNumber
    ? 'vendors.addDeliveryMethodByLink.success.subtitle.regular'
    : 'vendors.addDeliveryMethodByLink.success.subtitle.withoutInvoice';
  let subtitleValues: Record<string, any> = {
    totalAmount: <MIFormattedCurrency value={payment?.amount} />,
    companyName: organization?.companyName,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  if (isVirtualCardDeliveryMethod) {
    successSubtitle = 'vendors.addDeliveryMethodByLink.success.virtualCard.subtitle';
    subtitleValues = {
      ...subtitleValues,
      vendorEmail: deliveryMethod?.virtualCardAccount?.accountEmail || '',
      paymentETA: <MIFormattedDate date={payment.deliveryEta} />,
    };
  }

  const isVirtualCardDeliveryMethodRender = () => (
    <>
      <Separator thin={vendorFlowsViralityTestEnabled} />

      {vendorFlowsViralityTestEnabled ? (
        <QBOBusinessDirectoryUpsell flow={BUSINESS_DIRECTORY_UPSELL_ORIGIN.UNILATERAL} />
      ) : (
        <QBOJoinService
          labelPrefix="vendors.addDeliveryMethodByLink.success.joinService"
          utmCampaign={utmCampaign}
        />
      )}
    </>
  );

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success" innerSize={60}>
      <QBLogo src={qbLogo} />
      <Content>
        <Header useFlex={vendorFlowsViralityTestEnabled}>
          <DoneIcon
            src={doneImage}
            large={vendorFlowsViralityTestEnabled}
            centered={vendorFlowsViralityTestEnabled}
          />
          <Title testId="success-title" centered={vendorFlowsViralityTestEnabled}>
            <MIFormattedText label={successTitle} />
          </Title>
          <Subtitle centered={vendorFlowsViralityTestEnabled}>
            <MIFormattedText label={successSubtitle} values={subtitleValues} />
          </Subtitle>
          <Separator />
          <StyledConvertedUnilateralPaymentActivity payment={payment} />
        </Header>
        {!isVirtualCardDeliveryMethod && isVirtualCardDeliveryMethodRender()}
      </Content>
      <SupportLine __css={supportLineStyle} />
    </QBOLayoutPage>
  );
};

export default QBOAddDeliveryMethodSuccessPage;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 2rem;
`;

const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.darkGrey};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white.opaque};
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const DoneIcon = styled.img<{ large: boolean; centered: boolean }>`
  width: ${({ large }) => (large ? '5rem' : '4rem')};
  height: ${({ large }) => (large ? '5rem' : '4rem')};
  margin-bottom: 2rem;
  align-self: ${({ centered }) => (centered ? 'center' : 'auto')};
`;

const Title = styled.div<{ centered: boolean; testId?: string }>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  color: ${({ theme }) => theme.text.color.main};
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  font-size: ${({ theme }) => theme.text.size.wizardStepTitle};
  line-height: 3.2rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.div<{ centered: boolean }>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  color: ${({ theme }) => theme.text.color.main};
  ${({ theme }) => theme.text.fontType.medium};
`;

const Header = styled.div<{ useFlex: boolean }>`
  ${({ useFlex }) => (useFlex ? 'display: flex; flex-direction: column;' : '')}
  padding-bottom: ${({ useFlex }) => (useFlex ? '0.4rem' : '0')};
`;

const Separator = styled.div<{ thin?: boolean }>`
  background-color: ${({ theme, thin }) =>
    thin ? theme.text.color.grey : theme.colors.white.lightBlue};
  height: ${({ thin }) => (thin ? '0.1rem' : '0.4rem')};
  margin: ${({ thin }) => (thin ? '3.2rem 1rem' : '4rem -0.5rem 4rem -0.5rem')};
  @media ${devices.mobile}, ${devices.phablet} {
    margin: ${({ thin }) => (thin ? '3.2rem 0' : '4rem 0 4rem 0')};
  }
`;

const StyledConvertedUnilateralPaymentActivity = styled(ConvertedUnilateralPaymentActivity)`
  margin-top: 4rem;
`;

const supportLineStyle = {
  m: ['2rem 0 5rem', '2rem 0'],
};
