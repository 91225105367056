import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, NotificationCard, NotificationTypeEnum } from '@melio/billpay-design-system';
import analytics from 'src/app/services/analytics';
import { getTableWidth } from 'src/app/version-2/pages/batch-bulk/utils/tableWidth.utils';

import styled from 'styled-components';

/**
 * Shows notification if batch is blocked
 */
type Props = {
  isDisabled?: boolean;
  isFirstWave?: boolean;
};
const EndOfLifeNotification = ({ isDisabled, isFirstWave }: Props) => {
  const { formatMessage } = useIntl();

  const goLearnMore = () => {
    analytics.track('end-of-life-batch-notification', 'learn-more', { isFirstWave });
    window.open(
      'https://quickbooks.intuit.com/learn-support/en-us/help-article/money-movement/switch-bill-pay-powered-melio-quickbooks-bill-pay/L98z4nrOH_US_en_US?uid=lofvkaen',
      '_blank'
    );
  };

  const endOfLifeLabel = useMemo(
    () =>
      isFirstWave
        ? 'bills.pay.date.scheduledDateEndOfLifeNotificationFirstWave'
        : 'bills.pay.date.scheduledDateEndOfLifeNotificationSecondWave',
    [isFirstWave]
  );

  const values: Record<string, any> = {
    learnMoreLink: (...chunks) => (
      <Link onClick={goLearnMore} cursor="pointer" isExternal>
        {React.Children.toArray(chunks)}
      </Link>
    ),
  };

  return isDisabled ? (
    <StyledCard
      type={NotificationTypeEnum.INFO}
      subtitle={formatMessage({ id: endOfLifeLabel }, values)}
    />
  ) : null;
};

const StyledCard = styled(NotificationCard)`
  margin: 0 0.2rem 2rem;
  margin: 1rem auto 0;
  width: 100%;
  max-width: ${getTableWidth()};

  i {
    font-size: 1.85rem;
    margin-left: 0.4rem;
  }
`;

export { EndOfLifeNotification };
