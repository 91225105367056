import styled from 'styled-components';

export const BrandsLogosContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 4rem;
`;

export const BrandLogo = styled.div<{ translucent?: boolean }>`
  opacity: ${({ translucent }) => (translucent ? 0.2 : 1)};
  border-color: ${(props) => props.theme.colors.ds.white};
`;
