import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDeliveryMethodByType } from 'src/app/pages/vendor/records';
import { VendorType, AddressType, CheckType } from 'src/app/utils/types';
import { useForm } from 'src/app/ui/form';
import { ManualAddressType } from 'src/app/components/common/ManualAddress/ManualAddressOptionsContainer';
import { useApi } from 'src/app/hoc/useApi';
import clientServiceApi from 'src/app/services/api/clientService';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import ValidationError from 'src/app/ui/ValidationError';
import { CONSTS } from 'src/app/utils/consts';
import { whitePagesAddressKeys } from 'src/app/utils/address';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import analytics from 'src/app/services/analytics';
import { ManualAddressEnum } from 'src/app/version-2/model/enums';
import { CheckDeliveryMethodState } from '../CheckDeliveryMethod';
import {
  getCheckDeliveryMethodModel,
  getManualAddressFromModel,
  getStateByManualAddress,
  getEventPageName,
} from '../utils';
import { findErrorFields } from '../validations-utils';

const getTrackProperty = (address?: ManualAddressType | null) => {
  if (!address) {
    return {};
  }

  if (address.isPoBox) {
    return { addressType: 'po-box' };
  }

  return { addressType: 'regular' };
};

type Props = {
  vendorId: string;
  ownedVendorId: string;
  onSuccess: (
    paperCheck: CheckType,
    isVerified: boolean,
    checkDeliveryMethodId?: string
  ) => Promise<void>;
  checkModelOverwrite?: Partial<CheckType>;
};

const useManualAddress = ({ vendorId, ownedVendorId, onSuccess, checkModelOverwrite }: Props) => {
  const eventPage = useMemo(
    () => getEventPageName(ownedVendorId, vendorId),
    [ownedVendorId, vendorId]
  );
  const [address, setAddress] = useState<AddressType>();
  const [currentState, setCurrentState] = useState(CheckDeliveryMethodState.NEW);
  const [selectedAddressId, setSelectedAddressId] = useState(ManualAddressEnum.SUGGESTED);
  const [whitePageValidationErrors, setWhitePageValidationErrors] = useState<
    Record<string, any> | undefined
  >(undefined);
  const vendor: VendorType = useSelector(vendorsStore.selectors.fetch.byId(vendorId));
  const qboInfoLoading = useSelector((state) =>
    vendorsStore.selectors.qboVendorInfo.loading(state, vendorId)
  );
  const qboVendorInfo = useSelector((state) =>
    vendorsStore.selectors.qboVendorInfo.value(state, vendorId)
  ) as AddressType;
  const vendorDeliveryMethods = vendor?.deliveryMethods;

  const checkDeliveryMethod = useMemo(
    () => getDeliveryMethodByType(CONSTS.DELIVERY_TYPE.CHECK, vendorDeliveryMethods || []),
    [vendorDeliveryMethods]
  );
  const model = useMemo(() => {
    const check = getCheckDeliveryMethodModel(checkDeliveryMethod);

    if (!check.addressLine1 && !qboInfoLoading) {
      return {
        ...check,
        ...qboVendorInfo,
        ...checkModelOverwrite,
      };
    }

    return {
      ...check,
      ...checkModelOverwrite,
    };
  }, [checkDeliveryMethod, checkModelOverwrite, qboInfoLoading, qboVendorInfo]);

  const [validateAddressApiCall, validatedAddressObj, isAddressLoading] = useApi<
    [AddressType],
    ManualAddressType
  >(clientServiceApi.getAddressValidationSmartyStreets);

  const [checkDeliveryMethodMV, { submit }] = useForm(model, {
    submit: async (value) => {
      const validationFields = ['printName', 'addressLine1', 'city', 'state', 'zipCode'];
      const errors = getValidationErrors('deliveryMethodCheck', value, validationFields);

      const fieldsErrors = findErrorFields(validationFields, checkDeliveryMethodMV);
      const validationErrors = { ...errors, ...fieldsErrors };

      if (!isValidationOk(validationErrors)) {
        throw new ValidationError({ validationErrors });
      }

      const manualAddress = getManualAddressFromModel(checkDeliveryMethodMV);

      setAddress(manualAddress);
      const validatedAddress = await validateAddressApiCall(manualAddress);

      await onAddressChange(validatedAddress, manualAddress);
    },
  });

  const getValidationErrorsObj = (diff: Record<string, any>[]) =>
    diff.reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const onAddressChange = async (
    validatedAddress: ManualAddressType,
    manualAddress: AddressType
  ) => {
    if (validatedAddress && manualAddress) {
      const { printName } = checkDeliveryMethodMV;
      const isValidAddress = !!validatedAddress?.is_valid;
      const whitePageValidationErrors = isValidAddress
        ? getValidationErrorsObj(validatedAddress?.diff)
        : undefined;

      setWhitePageValidationErrors(whitePageValidationErrors);
      const paperCheck: CheckType = {
        printName: printName.value as string,
        ...manualAddress,
      };
      const addressState = getStateByManualAddress(validatedAddress);

      switch (addressState) {
        case CheckDeliveryMethodState.NEW:
          await onSuccess(paperCheck, true, checkDeliveryMethod?.id);
          break;
        case CheckDeliveryMethodState.CONFIRM:
          setCurrentState(addressState);
          analytics.track(eventPage, 'alternative-suggested');
          break;
        case CheckDeliveryMethodState.INVALID_PO_BOX:
        case CheckDeliveryMethodState.INVALID_ADDRESS:
          setCurrentState(addressState);
          analytics.track(eventPage, 'check-no-suggestion-found');
          break;
        case CheckDeliveryMethodState.NEW_UNVERIFIED:
          await onSuccess(paperCheck, false, checkDeliveryMethod?.id);
          analytics.track(eventPage, 'check-address-api-validation-error');
          break;
        default:
          break;
      }
    }
  };

  const onConfirmInvalidAddress = async () => {
    if (selectedAddressId === ManualAddressEnum.ORIGINAL) {
      if (validatedAddressObj?.isPoBox) {
        setCurrentState(CheckDeliveryMethodState.INVALID_PO_BOX);
      } else {
        setCurrentState(CheckDeliveryMethodState.INVALID_ADDRESS);
      }
    } else {
      await onConfirmAddress();
    }
  };

  const convertValidatedAddressToAddress = (
    validatedAddress: ManualAddressType | null
  ): AddressType =>
    Object.keys(whitePagesAddressKeys).reduce((obj, key) => {
      const newKey = whitePagesAddressKeys[key];

      obj[newKey] = validatedAddress ? validatedAddress[key] : null;

      return obj;
    }, {} as AddressType);

  const onConfirmAddress = async () => {
    let addressToSubmit = address as AddressType;
    let isVerified = false;
    const { printName } = checkDeliveryMethodMV;
    const { INVALID_ADDRESS, INVALID_PO_BOX, NEW_UNVERIFIED } = CheckDeliveryMethodState;

    if (!!validatedAddressObj?.is_valid && selectedAddressId === ManualAddressEnum.SUGGESTED) {
      isVerified = true;
      addressToSubmit = convertValidatedAddressToAddress(validatedAddressObj);
      analytics.track(
        eventPage,
        'check-suggested-address-confirmed',
        getTrackProperty(validatedAddressObj)
      );
    } else if (currentState === INVALID_ADDRESS || currentState === INVALID_PO_BOX) {
      analytics.track(
        eventPage,
        'check-no-suggestion-address-confirmed',
        getTrackProperty(validatedAddressObj)
      );
    } else if (currentState === NEW_UNVERIFIED) {
      analytics.track(
        eventPage,
        'check-suggested-address-declined',
        getTrackProperty(validatedAddressObj)
      );
    }

    const paperCheck = {
      printName: printName.value as string,
      ...addressToSubmit,
    };

    await onSuccess(paperCheck, isVerified, checkDeliveryMethod?.id);
  };

  const updateCheckDeliveryMethodModel = () => {
    const validatedAddress = convertValidatedAddressToAddress(validatedAddressObj);

    checkDeliveryMethodMV.setModelState((prevState) => ({
      ...prevState,
      ...validatedAddress,
    }));
    checkDeliveryMethodMV.setValidationErrors({});
  };

  const onEditAddress = () => {
    setSelectedAddressId(ManualAddressEnum.SUGGESTED);
    updateCheckDeliveryMethodModel();
    setCurrentState(CheckDeliveryMethodState.NEW);
    analytics.track(
      eventPage,
      'check-suggested-address-edited',
      getTrackProperty(validatedAddressObj)
    );
  };

  const onEditInvalidAddress = () => {
    setCurrentState(CheckDeliveryMethodState.NEW);
    analytics.track(eventPage, 'invalid-address-edited');
  };

  const onCloseModal = () => {
    setCurrentState(CheckDeliveryMethodState.NEW);
    analytics.track(
      eventPage,
      'check-invalid-address-edited',
      getTrackProperty(validatedAddressObj)
    );
  };

  const onSelectedAddressId = (id: (typeof ManualAddressEnum)[keyof typeof ManualAddressEnum]) => {
    if (id === ManualAddressEnum.SUGGESTED) {
      analytics.track(
        eventPage,
        'address-suggestion-selected',
        getTrackProperty(validatedAddressObj)
      );
    } else {
      analytics.track(
        eventPage,
        'address-original-selected',
        getTrackProperty(validatedAddressObj)
      );
    }

    setSelectedAddressId(id);
  };

  const actions = {
    onEditAddress,
    onEditInvalidAddress,
    onCloseModal,
    onSelectedAddressId,
    onConfirmAddress,
    onConfirmInvalidAddress,
    submit,
  };

  const state = {
    address,
    selectedAddressId,
    whitePageValidationErrors,
    isAddressLoading,
    validatedAddressObj,
    checkDeliveryMethodMV,
    checkDeliveryMethod,
    currentState,
    eventPage,
  };

  return {
    actions,
    state,
  };
};

export default useManualAddress;
