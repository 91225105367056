import { Bill } from 'src/app/version-2/model/dtos';
import { QboSyncVersionEnum } from 'src/app/version-2/model/enums';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';

export const canPartiallyPayBill = (
  isPartialPaymentsUIEnabled: boolean,
  qboSyncVersion?: number,
  paymentId?: string
): boolean =>
  isPartialPaymentsUIEnabled &&
  !paymentId &&
  qboSyncVersion === QboSyncVersionEnum.BILL_CREATED_AFTER_SYNC;

export const canCombinedBills = (batchBulks: BatchBulkItem[]) => {
  const vendors: number[] = [];

  return batchBulks.some(({ vendorId, totalBillIds }) => {
    if (vendors.includes(vendorId) || totalBillIds.length > 1) return true;

    vendors.push(vendorId);

    return false;
  });
};

interface GetDefaultMemoParams {
  bills: Bill[];
  intuitAcctNum?: string;
  translations: Record<string, string>;
}
export const getDefaultMemo = ({ bills, intuitAcctNum, translations }: GetDefaultMemoParams) => {
  const intuitAcctNumString =
    intuitAcctNum && typeof intuitAcctNum !== 'string' ? String(intuitAcctNum) : intuitAcctNum;
  const isEmptyAcctNumber = !(intuitAcctNumString || '').trim();
  const { invoiceNumber } = bills?.[0] || {};
  let invoiceText = '';

  if (invoiceNumber && bills?.length === 1)
    invoiceText = `${translations.invoiceNum} ${invoiceNumber}`;
  else if (bills?.length > 1) invoiceText = translations.combinedPayments;

  if (invoiceText && !isEmptyAcctNumber) {
    return `${translations.accountNum} ${intuitAcctNumString} | ${invoiceText}`;
  }

  if (!invoiceText && !isEmptyAcctNumber) {
    return `${translations.accountNum} ${intuitAcctNumString}`;
  }

  if (invoiceText && isEmptyAcctNumber) {
    return `${invoiceText}`;
  }

  return '';
};

interface isBatchHasPartialPaymentParams {
  preservedState?: Record<string, any>;
  billId?: string;
  amount?: string;
}
export const isBatchHasPartialPayment = ({
  preservedState,
  billId,
  amount,
}: isBatchHasPartialPaymentParams): boolean => {
  if (preservedState) {
    const batchBill = preservedState?.batchList?.find((bill) => bill.id === `bill-${billId}`);
    const batchBillAmount = batchBill?.payment?.amount;

    if (batchBillAmount && amount && Number(amount) !== Number(batchBillAmount)) return true;
  }

  return false;
};
