import { Box, Flex, StackDivider, VStack } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { QBOFooterContainer, useBreak } from 'src/app/version-2/externals';
import { ReactComponent as QBLogo } from 'src/app/version-2/images/qb-logo.svg';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { Footer } from './Footer';
import { Header } from './Header';
import { Container, InfoContainer, InfoSection, StyledUpsell } from './StyledComponents';

export const VendorPaymentDetails = ({ payment, token, onExpediteCTA, expediteType, children }) => {
  const { isMobile } = useBreak();
  const [isBpGlobalOptInUpsellFeatureFlagOn] = featureFlags.useFeature(
    FeatureFlagsEnum.BP_GLOBAL_OPT_IN_UPSELL,
    false
  );

  return (
    <Flex direction="column" justify="space-around" align="center" minHeight="full">
      <Container>
        <Box marginBottom={5}>
          <QBLogo />
        </Box>
        <InfoContainer>
          <VStack divider={<StackDivider borderColor="gray.50" />} spacing={0.5} width="full">
            <InfoSection isMobile={isMobile}>
              <Header payment={payment} />
            </InfoSection>

            <InfoSection isMobile={isMobile}>{children}</InfoSection>
            {expediteType && (
              <InfoSection isMobile={isMobile}>
                <Footer
                  payment={payment}
                  onFooterActionClick={onExpediteCTA}
                  expediteType={expediteType}
                />
              </InfoSection>
            )}
          </VStack>
          {isBpGlobalOptInUpsellFeatureFlagOn && (
            <>
              <Box w="ds.sm" h="ds.sm" bg="ds.gray.800" />
              <StyledUpsell paymentId={payment.id} token={token} />
            </>
          )}
        </InfoContainer>
      </Container>
      <QBOFooterContainer />
    </Flex>
  );
};
