import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

export const FAQFeesTable = () => (
  <Table>
    <thead>
      <th>Payment method</th>
      <th>Delivery method</th>
      <th>Cost</th>
    </thead>
    <tbody>
      {[1, 2, 3, 4, 5, 6, 7].map((feeNum) => (
        <tr key={`feeNum_${feeNum}`}>
          <td>
            <MIFormattedText label={`drawer.faq.fee${feeNum}PaymentMethod`} />
          </td>
          <td>
            <MIFormattedText label={`drawer.faq.fee${feeNum}DeliveryMethod`} />
          </td>
          <td>
            <MIFormattedText label={`drawer.faq.fee${feeNum}Cost`} />
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  th,
  td {
    width: 33%;
    padding: 5px;
    border: 1px solid black;
  }
`;
