import isEmpty from 'lodash/isEmpty';

// validate single field
type validateProps = {
  name: string;
  value: string;
  regex?: string;
  isRequired?: boolean;
  prefix?: string;
  customCheck?: (value) => string;
};
export const validateField = ({
  name = '',
  value = '',
  regex = '',
  isRequired = false,
  customCheck,
  prefix = '',
}: Partial<validateProps>): string => {
  const hasValue = !isEmpty(value);

  if (typeof customCheck === 'function' && customCheck(value)) return customCheck(value);

  if (!hasValue && isRequired) {
    return `${prefix}.${name}.empty`;
  }

  if (hasValue && regex) {
    const regexCondition = new RegExp(regex);
    const isValid = regexCondition.test(value);

    if (!isValid) return `${prefix}.${name}.regex`;
  }

  // no errors
  return '';
};

// validate multi fields
type Field = Record<string, any>;
export const validateMultiFields = (fields: Field[] = [], prefix: string): Field => {
  const errors: Field = {};

  fields.forEach((field) => {
    const fieldError = validateField({ ...field, prefix });
    const { name } = field;

    if (fieldError) errors[name] = fieldError;
  });

  return errors;
};
