import { Link } from '@melio/billpay-design-system';
import { MIFormattedText, useSiteContext } from 'src/app/version-2/externals';
import { Container, SupportContainer, TermsContainer } from './Support.styles';

export const Support = () => {
  const site = useSiteContext();

  return (
    <Container>
      <SupportContainer>
        <MIFormattedText label="vendors.upgradePayment.support.question" />
        <Link
          href={`mailto:${site.config.support.email}`}
          isExternal
          color="gray.500"
          textDecoration="underline"
          sx={{ ':hover': { textDecoration: 'none' } }}
        >
          {site.config.support.email}
        </Link>
      </SupportContainer>
      <TermsContainer>
        <Link
          href={site.config.agreementLinks.privacyPolicy}
          isExternal
          textDecoration="none !important"
          color="gray.500"
        >
          <MIFormattedText label="vendors.upgradePayment.support.privacyPolicy" />
        </Link>
        |
        <Link
          href={site.config.agreementLinks.termsOfService}
          isExternal
          textDecoration="none !important"
          color="gray.500"
        >
          <MIFormattedText label="vendors.upgradePayment.support.termsOfService" />
        </Link>
      </TermsContainer>
    </Container>
  );
};
