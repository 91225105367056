import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';

import { ReactComponent as FirstIcon } from 'src/app/images/qbo/vc-details/first.svg';
import { ReactComponent as SecondIcon } from 'src/app/images/qbo/vc-details/second.svg';
import { ReactComponent as ThirdIcon } from 'src/app/images/qbo/vc-details/third.svg';

type Props = {
  idx: number;
  title: string;
  subtitle: string;
};

const stepIcons = {
  1: <FirstIcon />,
  2: <SecondIcon />,
  3: <ThirdIcon />,
};

const ProcessCardStep = ({ idx, title, subtitle }: Props) => (
  <Flex justifyContent="flex-start" alignItems="center" gridGap="1rem">
    <Box>{stepIcons[idx]}</Box>
    <Flex direction="column" color="black" gridGap={2}>
      <Box __css={titleStyle}>
        <MIFormattedText label={title} />
      </Box>
      <Box __css={subtitleStyle}>
        <MIFormattedText label={subtitle} />
      </Box>
    </Flex>
  </Flex>
);

const titleStyle = {
  fontWeight: '600',
  textStyle: ['body2', 'body2'],
  color: 'black',
  mb: ['0.4rem', '0.2rem'],
};

const subtitleStyle = {
  fontWeight: '400',
  textStyle: ['body3', 'body3'],
};

export { ProcessCardStep };
