import { useCallback } from 'react';
import {
  Tab as ChakraTab,
  TabProps as ChakraTabProps,
  useTab,
  useStyles,
  forwardRef,
} from '@chakra-ui/react';
import { Box } from '../../components/ui/Box';

import { selectedBorderStyle } from './Tabs.styles';

type TabProps = ChakraTabProps & {
  eventName: string;
};

const Tab = forwardRef<TabProps, 'button'>(({ eventName, ...props }, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];
  const styles = useStyles();

  const onClickWithEvent = useCallback(
    (event) => {
      tabProps.onClick(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps, comma-dangle
    [tabProps, eventName]
  );

  return (
    // eslint-disable-next-line dot-notation
    <ChakraTab __css={styles['tab']} {...tabProps} onClick={onClickWithEvent}>
      {tabProps.children}
      {isSelected && <Box __css={selectedBorderStyle} />}
    </ChakraTab>
  );
});

export { Tab };
