import { DeliveryTypeEnum, PaymentDeliveryStatusEnum } from 'src/app/version-2/model/enums';

export const TIMELINE_STEPS = {
  COLLECTED: 'collected',
  PROGRESS: 'in-progress',
  COMPLETED: 'completed',
};

export const TIMELINE_STEP_BY_DELIVERY_TYPE = {
  [DeliveryTypeEnum.ACH]: {
    [PaymentDeliveryStatusEnum.SENT]: TIMELINE_STEPS.PROGRESS,
    [PaymentDeliveryStatusEnum.CLEARED]: TIMELINE_STEPS.COMPLETED,
    [PaymentDeliveryStatusEnum.ACKNOWLEDGED]: TIMELINE_STEPS.COMPLETED,
  },
  [DeliveryTypeEnum.CHECK]: {
    [PaymentDeliveryStatusEnum.SENT]: TIMELINE_STEPS.PROGRESS,
    [PaymentDeliveryStatusEnum.CLEARED]: TIMELINE_STEPS.COMPLETED,
    [PaymentDeliveryStatusEnum.SETTLED]: TIMELINE_STEPS.COMPLETED,
  },
  [DeliveryTypeEnum.CARD]: {
    [PaymentDeliveryStatusEnum.SENT]: TIMELINE_STEPS.PROGRESS,
    [PaymentDeliveryStatusEnum.CLEARED]: TIMELINE_STEPS.COMPLETED,
  },
  [DeliveryTypeEnum.VIRTUAL_CARD]: {
    [PaymentDeliveryStatusEnum.SCHEDULED]: TIMELINE_STEPS.COLLECTED,
    [PaymentDeliveryStatusEnum.SENT]: TIMELINE_STEPS.PROGRESS,
    [PaymentDeliveryStatusEnum.CLEARED]: TIMELINE_STEPS.COMPLETED,
  },
  [DeliveryTypeEnum.VIRTUAL]: {
    [PaymentDeliveryStatusEnum.SCHEDULED]: TIMELINE_STEPS.COLLECTED,
    [PaymentDeliveryStatusEnum.SENT]: TIMELINE_STEPS.PROGRESS,
    [PaymentDeliveryStatusEnum.CLEARED]: TIMELINE_STEPS.COMPLETED,
  },
};
