import { Flex, Heading, Text } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const PageContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.ds.gray[700]};
  height: 100%;
`;

export const Title = styled(Heading)`
  font-size: ${({ theme }) => theme.textStyles.ds.h5.fontSize};
  line-height: ${({ theme }) => theme.textStyles.ds.h5.lineHeight};
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
  padding-bottom: ${({ theme }) => theme.space.ds.lg};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  margin: 0;
`;

export const Subtitle = styled(Text)`
  ${({ theme }) => theme.textStyles.ds.body2};
  padding-bottom: ${({ theme }) => theme.space.ds['2xl']};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  margin: 0;
`;

export const CardTextContainer = styled(Flex)`
  flex-direction: column;
  padding-left: ${({ theme }) => theme.space.ds.xl};
`;

export const CardTitle = styled(Text)`
  font-size: ${({ theme }) => theme.textStyles.ds.body2Medium.fontSize};
  line-height: ${({ theme }) => theme.textStyles.ds.body2Medium.lineHeight};
  font-weight: ${({ theme }) => theme.fontWeights.ds.medium};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  margin: 0;
`;

export const CardSubtitle = styled(CardTitle)`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  margin: 0;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 3rem;
  right: 3rem;
  cursor: pointer;
`;
