import { DELIVERY_TYPE, VENDOR_TYPES } from './consts';

const getVendorType = (vendor) => {
  const deliveryMethods = vendor?.deliveryMethods;

  if (!deliveryMethods || !deliveryMethods?.length) {
    return VENDOR_TYPES.NOT_DETERMINED;
  }

  const isInternational =
    deliveryMethods.filter(
      (dm) => dm.deliveryType === DELIVERY_TYPE.INTERNATIONAL || dm.internationalAccount
    ).length > 0;

  if (isInternational) {
    return VENDOR_TYPES.INTERNATIONAL;
  }

  return VENDOR_TYPES.DOMESTIC;
};

export { getVendorType };
