export const cardContainerStyles = {
  display: 'flex',
  border: '1px',
  borderColor: 'gray.400',
  borderRadius: 'lg',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  width: '100%',
  p: '5',
};
