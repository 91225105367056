import { Skeleton } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const SkeletonLoading = styled(Skeleton)`
  width: 100%;
  height: 1.6rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`;
