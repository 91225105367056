import React, { memo } from 'react';

import MISingleSelect from 'src/app/components/common/MISingleSelect';

import { CountryOption } from '../types';

const formatGroupLabel = (data) => (
  <div style={{ padding: '1rem 0 0.8rem 1.8rem' }}>
    <span>{data.label}</span>
  </div>
);

const overrideStyles = {
  option: {
    minHeight: '3rem',
    padding: '0.6rem 2rem',
  },
};

type Props = {
  id?: string;
  value?: string;
  label: string;
  options: CountryOption[];
  errorMessage?: string;
  isProcessing?: boolean;
  readOnlyValue?: boolean;
  onChange: (any) => any;
  outsideClickHandler?: (event) => void;
};

const CountriesList = ({
  id,
  options,
  value,
  label,
  errorMessage,
  isProcessing,
  readOnlyValue,
  onChange,
  outsideClickHandler,
}: Props) => (
  <MISingleSelect
    id={id}
    value={value}
    label={label}
    placeholder="international.paymentDetails.country.placeholder"
    options={options}
    onChange={onChange}
    formatGroupLabel={formatGroupLabel}
    errorMessage={errorMessage}
    overrideStyles={overrideStyles}
    isProcessing={isProcessing}
    readOnlyValue={readOnlyValue}
    required
    privateData
    outsideClickHandler={outsideClickHandler}
  />
);

function areEqual(prevProps, nextProps) {
  return (
    prevProps.value === nextProps.value &&
    prevProps.errorMessage === nextProps.errorMessage &&
    prevProps.isProcessing === nextProps.isProcessing &&
    prevProps.readOnlyValue === nextProps.readOnlyValue
  );
}

export default memo(CountriesList, areEqual);
