import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import analytics from '../../services/analytics';

type Props = {
  id: string;
  name: string;
};
class VendorListItem extends React.PureComponent<Props> {
  handleClick() {
    analytics.track('vendors', 'nav-to-single');
  }

  render() {
    const { id, name } = this.props;

    return (
      <VendorListItemContainer
        to={{ pathname: `/settings/vendors/${id}` }}
        onClick={this.handleClick}
      >
        <VendorName>{name}</VendorName>
        <EditIcon />
      </VendorListItemContainer>
    );
  }
}

export default VendorListItem;

const VendorListItemContainer = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  padding: 1.3rem 1rem;
  text-decoration: none;
  border-bottom: 0.1rem solid rgba(212, 215, 220, 1);

  &:last-child {
    margin-bottom: 2rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.white.lightBlue};
  }
`;

const VendorName = styled.span`
  color: rgba(51, 51, 51, 1);
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.text.weight.regular};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.8rem;
`;

const EditIcon = styled.i.attrs({ className: 'icon-edit-icon' })`
  display: none;
  font-size: 1.8rem;
  color: rgba(141, 144, 150, 1);

  ${VendorListItemContainer}:hover & {
    display: block;
  }
`;
