import styled from 'styled-components';
import { devices } from '../../theme/AppDevices';

const MITable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 0.7rem;
  border: 0.1rem solid ${(props) => props.theme.colors.ds.gray[500]};

  th,
  td {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  th,
  td {
    &:first-child {
      border-right: 1px solid ${(props) => props.theme.colors.ds.gray[600]};
    }
    &:last-child {
      border-left: 1px solid ${(props) => props.theme.colors.ds.gray[600]};

      i {
        display: block;
      }
    }
  }
`;

const MITableHeaderCell = styled.th`
  height: 3.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #212124;
  background-color: ${(props) => props.theme.colors.white.neutralGray};
  text-align: left;

  &:first-child {
    @media ${devices.nonMobile} {
      border-top-left-radius: 0.7rem;
    }
  }

  &:last-child {
    @media ${devices.nonMobile} {
      border-top-right-radius: 0.7rem;
      width: 5rem;
    }
  }

  ${(props) => props.theme.pages?.Settings?.MITableHeaderCell}
`;

const MITableBodyCell = styled.td`
  height: 4rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.dark.opaque};
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.grey};
`;

const MITableRow = styled.tr`
  @media ${devices.mobile}, ${devices.phablet} {
    background: white;
  }
`;

const MITableHead = styled.thead`
  background-color: ${(props) => props.theme.colors.white.lightGray};
`;

const MITableBody = styled.tbody``;

export { MITable, MITableHeaderCell, MITableBodyCell, MITableRow, MITableHead, MITableBody };
