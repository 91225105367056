import React from 'react';
import styled from 'styled-components';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import MILink from 'src/app/components/common/MILink';
import { melioClose } from 'src/app/utils/external-events';
import intercomService from '../../services/intercom';
import analytics from '../../services/analytics';

type Props = {
  illustration: string;
  illustrationStyle?: Record<string, any>;
  title?: string;
  subtitle?: string;
  settingsHelp?: string;
  settingsHelpTitle?: string;
  supportLink?: string;
  supportText?: string;
  token?: string;
  vendorId?: string;
  buttonLabel?: string;
  buttonAction?: () => void;
  hideExitButton?: boolean;
  subtitleValues?: Record<string, any>;
};

const GeneralFlowErrorContainer = (props: Props) => {
  const site = useSiteContext();
  const eventPage = 'qbo';
  const openSupportChat = () => {
    analytics.track(eventPage, 'support-open-start-chat');
    intercomService.show();
  };

  const goExit = () => {
    analytics.track('qbo-incognito-error-page', 'close-incognito-error');
    melioClose();
  };

  const layoutProps: Record<string, any> = {};

  if (!props.hideExitButton) layoutProps.goExit = goExit;

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success" {...layoutProps}>
      <QBLogo src={qbLogo} />
      <Content>
        <Illustration src={props.illustration} style={props.illustrationStyle} />
        {props.title && (
          <Title>
            <MIFormattedText label={props.title} />
          </Title>
        )}
        {props.subtitle && (
          <SubTitle>
            <MIFormattedText
              label={props.subtitle}
              values={
                props.subtitleValues || {
                  email: (
                    <Link href={`mailto:${site.config.support.email}`}>
                      <MIFormattedText label={site.config.support.email} />
                    </Link>
                  ),
                }
              }
            />
          </SubTitle>
        )}
        <SettingsHelp>
          <SettingsHelpTitle>
            <MIFormattedText label={props.settingsHelpTitle} />
          </SettingsHelpTitle>
          <MIFormattedText label={props.settingsHelp} />
        </SettingsHelp>

        {props.supportLink && (
          <Support>
            <MIFormattedText label={props.supportText} />
            <ChatLink to={openSupportChat} label={props.supportLink} />
          </Support>
        )}

        {props.buttonLabel && (
          <ButtonContainer>
            <MIButton
              label={props.buttonLabel}
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={props.buttonAction}
            />
          </ButtonContainer>
        )}
      </Content>
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.6rem;
  border: 1px solid ${(props) => props.theme.colors.shadow.lightGrey};
  box-sizing: border-box;

  @media ${devices.desktop}, ${devices.tablet} {
    padding: 4rem;
  }
`;

const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.text.color.link};
  font-weight: ${(props) => props.theme.text.weight.regular};
  &:active {
    color: ${(props) => props.theme.text.color.link};
  }
  &:hover {
    color: ${(props) => props.theme.text.color.link};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Link = styled.a`
  text-decoration: underline;
  text-transform: lowercase;
  cursor: pointer;
  color: ${(props) => props.theme.text.color.subtitle};
`;

const Support = styled.div`
  margin-top: 2rem;
  align-self: center;
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const Illustration = styled(QBLogo)`
  width: auto;
`;

const SettingsHelp = styled.span`
  margin-top: 2rem;
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-align: center;
`;

const SettingsHelpTitle = styled.span`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-align: center;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.text.size.errorPageTitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 2rem;
  align-self: center;
  margin-bottom: 1rem;
`;

export default GeneralFlowErrorContainer;
