import React, { useEffect } from 'react';
import ListLayoutPage, { ListPage, ViewSinglePage } from 'src/app/components/layout/ListLayoutPage';
import { useApi } from 'src/app/hoc/useApi';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { getOrgId } from 'src/app/redux/user/selectors';
import styled from 'styled-components';
import intuit from 'src/app/utils/intuit';
import { devices } from 'src/app/theme/AppDevices';
import { Loader } from '@melio/billpay-design-system';
import { useSelector } from 'react-redux';
import analytics from 'src/app/services/analytics';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { newPayment, melioLoaded } from 'src/app/utils/external-events';
import quickpayBillsStore from 'src/app/modules/quickpay-bills/quickpay-bills-store';
import RightPanelPlaceholder from 'src/app/components/onboarding/RightPanelPlaceholder';
import {
  CONSTS,
  QBR_BILL_LIST_TAB_TYPE,
  TOGGLE_TYPE,
  PAGINATION,
  BUTTON_SIZE,
} from 'src/app/utils/consts';
import { useQueryState, useQueryString } from 'src/app/utils/hooks';
import QBRViewBill from 'src/app/pages/bill/components/QBRViewBill';
import { QBRBillListType, QBCashStateType, NotePlaceholderType } from 'src/app/utils/types';
import locations from 'src/app/pages/quickpay/locations';
import MIButton from 'src/app/components/common/MIButton';
import { encodeQuery } from 'src/app/utils/query-utils';
import organizationsApi from 'src/app/services/api/organizations';
import QBRBillsList from './components/QBRBillsList';
import BillsListTabs from './components/QBRBillsListTabs';
import { getSelectedTab, getFilters } from './utils';

const eventPage = 'QBRBillsListPage';
const INITIAL_STATE = 'initialState';

const BillListPage = () => {
  const orgId = useSelector(getOrgId);
  const query = useQueryString();
  const location = useLocation();
  const history = useHistory();
  const selectedType = getSelectedTab(query);
  const [type] = useQueryState<QBRBillListType>('type', selectedType, false, false);
  const [selectedBillId, setSelectedBill] = useQueryState<string>('id', undefined, false, false);
  const billsActions = useStoreActions(quickpayBillsStore);
  const [syncCompanyInfo] = useApi(organizationsApi.syncCompanyInfo, false);
  const filters = getFilters(query, type);
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const totalCount = (
    useSelector(quickpayBillsStore.selectors.list.status({ orgId, filters })) as {
      totalCount: number;
    }
  )?.totalCount;
  const bills = useSelector((state) =>
    quickpayBillsStore.selectors.list.value(state, { orgId, filters })
  );
  const isLoading = (
    useSelector(quickpayBillsStore.selectors.list.status({ orgId, filters })) as {
      loading: boolean;
    }
  )?.loading;

  const isSingleLoading = (
    useSelector(quickpayBillsStore.selectors.fetch.status({ orgId, id: selectedBillId })) as {
      loading: boolean;
    }
  )?.loading;
  const pathName = generatePath(locations.list.index, { orgId });

  const setInitialFilters = async () => {
    if (!(query.start && query.limit && query.type) || (query.start === '0' && !query.id)) {
      const search = encodeQuery(filters, [], '');

      await history.push({
        ...location,
        search,
        state: { name: INITIAL_STATE },
      });
    }
  };

  const fetchBills = async () => {
    await billsActions.list({ orgId, filters });
  };

  const toggleType = () => {
    const search = encodeQuery(
      {
        start: PAGINATION.DEFAULT_START,
        limit: PAGINATION.DEFAULT_LIMIT,
        type: type === QBR_BILL_LIST_TAB_TYPE.OPEN ? TOGGLE_TYPE.COMPLETED : TOGGLE_TYPE.OPEN,
      },
      [],
      ''
    );

    history.push({
      ...location,
      search,
      state: { name: INITIAL_STATE },
    });
  };

  const onNewPaymentClick = () => {
    analytics.track(eventPage, 'new-payment');
    newPayment();
  };

  useEffect(() => {
    fetchBills();
  }, [orgId, billsActions, type, query]);
  useEffect(() => {
    syncCompanyInfo(orgId);
  }, [orgId]);
  useEffect(() => {
    setInitialFilters();
  }, []);

  useEffect(() => {
    if (isLoading === false) {
      melioLoaded();
      intuit.endLoadingWrapper();
    }
  }, [isLoading]);

  const notePlaceholder: NotePlaceholderType = {
    view: 'bills.pay.memo.uneditablePlaceholder',
    edit: 'bills.pay.memo.placeholder',
  };

  return (
    <>
      <ListLayoutPage isLoading={isLoading} title="bills.title">
        <ListPage>
          <BillsListTabs currentType={type} toggleType={toggleType} />
          {isLoading ? (
            <Loader />
          ) : (
            <QBRBillsList
              pathName={pathName}
              filters={filters}
              totalCount={totalCount}
              setSelectedBill={setSelectedBill}
              bills={bills}
            />
          )}
        </ListPage>
        <ViewSinglePage>
          {selectedBillId ? (
            <QBRViewBill
              billId={selectedBillId}
              id={selectedBillId}
              isLoading={isSingleLoading}
              reloadBills={fetchBills}
              backPath={locations.list.index}
              qbCashState={qbCashState}
              notePlaceholder={notePlaceholder}
            />
          ) : (
            <RightPanelPlaceholder isLoading={isLoading} />
          )}
        </ViewSinglePage>
      </ListLayoutPage>
      <ButtonContainer>
        <MIButton
          size={BUTTON_SIZE.SMALL}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={onNewPaymentClick}
          label="bills.list.button"
          fullWidth
        />
      </ButtonContainer>
    </>
  );
};

export default BillListPage;

const ButtonContainer = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: -0.5rem;
    padding: 1.6rem;
    width: 90%;
    position: fixed;
    bottom: 0;
  }
`;
