import React from 'react';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { QuickPayment } from 'src/app/modules/payments/quickpay-slice';
import ConfirmPage from 'src/app/pages/quickpay/operations/ConfirmPage';
import paymentsStore from 'src/app/modules/payments/payment-store';
import organizationsStore from 'src/app/modules/organizations/organizations-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import locations from '../locations';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};
const CreateConfirmPage = ({ onNext, onPrev, onExit }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const site = useSiteContext();
  const payment: QuickPayment = useSelector(
    paymentsStore.selectors.quickpay.newPaymentWizard.payment
  );
  const paymentActions = useStoreActions(paymentsStore);
  const orgId = useSelector(getOrgId);
  const status: { loading: boolean } = useSelector(
    paymentsStore.selectors.quickpay.create.status()
  );
  const companyInfo: any = useSelector(organizationsStore.selectors.byId(orgId));

  const shouldCompleteCompanyInfo = !(
    companyInfo.contactFirstName &&
    companyInfo.contactLastName &&
    companyInfo.taxId &&
    companyInfo.taxIdType
  );

  const onSubmit = async (payment: QuickPayment) => {
    const {
      vendorId,
      currency,
      totalAmount,
      scheduledDate,
      fundingSourceId,
      deliveryMethodId,
      note,
    } = payment;

    if (shouldCompleteCompanyInfo) {
      historyPush({ path: locations.create.completeCompanyInfo });
    } else {
      const createOrigin = site.createOrigin.pay.payment;

      await paymentActions.quickpay.create({
        amount: totalAmount,
        orgId,
        vendorId,
        currency,
        scheduledDate,
        fundingSourceId,
        deliveryMethodId,
        note,
        createOrigin,
      });
      onNext();
    }
  };
  const goEditNote = () => {
    historyPush({ path: locations.create.note });
  };
  const goEditDate = () => {
    historyPush({ path: locations.create.date });
  };
  const goEditDeliveryMethod = () => {
    const { vendorId } = payment;

    historyPush({
      path: locations.create.deliveryMethod,
      params: { vendorId: vendorId as string },
    });
  };

  return (
    <ConfirmPage
      onExit={onExit}
      onPrev={onPrev}
      onSubmit={onSubmit}
      goEditDate={goEditDate}
      goEditNote={goEditNote}
      goEditDeliveryMethod={goEditDeliveryMethod}
      headerLabel="qbr.confirm.create.header"
      isLoading={status?.loading}
      buttonLabel="bills.pay.confirm.action"
    />
  );
};

export default CreateConfirmPage;
