import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Input, Option, Select } from '@melio/billpay-design-system';
import { FileUploader } from 'src/app/version-2/components/FileUploader/FileUploader';
import { PurposeOfPaymentTypes } from 'src/app/version-2/components/PurposeOfPayment/PurposeOfPayment.types';
import { options } from 'src/app/version-2/utils/purposeOfPayment.utils';
import { InputWrapper, Purpose } from './PurposeOfPayment.styles';

export const PurposeOfPayment = ({
  onChange,
  errors,
  purpose,
  billId,
  showInvoiceFileSelector,
}: PurposeOfPaymentTypes) => {
  const { formatMessage } = useIntl();
  const { description } = purpose;

  const optionsTranslation: Option[] = useMemo(
    () =>
      options.map((option) => {
        option.label = formatMessage({ id: option.label });

        return option;
      }),
    []
  );

  return (
    <Purpose>
      <Select
        placeholder={formatMessage({ id: 'international.purposeOfPayment.purpose.placeholder' })}
        errorMessage={errors.type ? formatMessage({ id: errors.type }) : ''}
        label={formatMessage({ id: 'international.purposeOfPayment.purpose.label' })}
        options={optionsTranslation}
        onChange={(val) => onChange('type', { value: val.target.value })}
        required
      />
      <InputWrapper>
        <Input
          value={description}
          variant="flushed"
          label={formatMessage({ id: 'international.purposeOfPayment.description.label' })}
          placeholder={formatMessage({
            id: 'international.purposeOfPayment.description.placeholder',
          })}
          onChange={(val) => onChange('description', { value: val.target.value })}
        />
      </InputWrapper>

      {showInvoiceFileSelector && <FileUploader billId={billId} />}
    </Purpose>
  );
};
