import { useContext, useMemo, useCallback, useRef } from 'react';
import { History, LocationState } from 'history';
import { parseQueryString, encodeQuery } from 'src/app/utils/query-utils';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
// import { setAuthToken } from 'src/app/services/api/api';
import { useLocation, useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { LOCAL_STORAGE_KEYS } from 'src/app/utils/consts';

export function useLocationState<T = any>(name: string, defaultValue?: any) {
  const location = useLocation<T>();
  const history = useHistory();
  const value = location.state?.[name] === undefined ? defaultValue : location.state?.[name];
  const setValue = useCallback(
    (value) => {
      const state = { ...location.state, [name]: value };

      history.push({ ...location, state });
    },
    [location, history, name]
  );

  return [value, setValue];
}

export function useStyledTheme() {
  const theme = useContext(ThemeContext);

  return theme || {};
}

export function useQueryString() {
  const location = useLocation();

  return useMemo(() => parseQueryString(location.search) || {}, [location.search]);
}

export function useQueryState<T = any>(
  name: string,
  defaultValue: T | undefined = undefined,
  replace = true,
  copyState = true
): [T, (value: T) => void] {
  const query = useQueryString();
  const location = useLocation();
  const history = useHistory();
  const value: T = (query?.[name] === undefined ? defaultValue : (query?.[name] as T)) as T;
  const setValue = useCallback<(value: T) => void>(
    async (value) => {
      const currQuery = parseQueryString(history.location.search);
      const newQuery = pickBy({ ...currQuery, [name]: value });
      const search = encodeQuery(newQuery, [], '');
      const { state, ...restLocation } = location;

      if (replace) {
        await history.replace({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      } else {
        await history.push({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      }
    },
    [replace, location, history, name, copyState]
  );

  return [value, setValue];
}

export function useQueryStateMultiValues<T extends Record<string, any>>(
  defaultValue: T,
  replace = true,
  copyState = true
): [T, (T) => void] {
  const query = useQueryString();
  const location = useLocation();
  const history = useHistory();
  const currentKeys = keys(defaultValue);
  const currentValue = { ...defaultValue, ...pick(query, currentKeys) };
  const setValue = useCallback(
    async (values: Partial<T>) => {
      const currQuery = parseQueryString(history.location.search);
      const newQuery = pickBy({ ...currQuery, ...values });
      const search = encodeQuery(newQuery, [], '');
      const { state, ...restLocation } = location;

      if (replace) {
        await history.replace({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      } else {
        await history.push({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      }
    },
    [replace, history, copyState]
  );

  return [currentValue, setValue];
}

export function useQueryIds(
  name = 'ids',
  replace = true
): [string[], (string, boolean) => void, (list: string[]) => void, () => void] {
  const [queryIds, setQueryIds] = useQueryState<string | undefined>(name, undefined, replace);
  const ids = queryIds?.split(',') || [];
  const setIdValue = useCallback(
    (id: string, value: boolean) => {
      const found = ids.indexOf(id);

      if (found !== -1 && !value) {
        ids.splice(found, 1);
      } else if (found === -1 && value) {
        ids.push(id);
      }

      setQueryIds(ids.join(','));
    },
    [setQueryIds, ids]
  );
  const clear = useCallback((): void => {
    setQueryIds(undefined);
  }, [setQueryIds]);
  const setListValue = useCallback(
    (list: string[]): void => {
      setQueryIds(list.join(','));
    },
    [setQueryIds]
  );

  return [ids, setIdValue, setListValue, clear];
}

export function useSetAuthTokenOnce(history: History<LocationState>, enabled: boolean) {
  const isAuthTokenSet = useRef<boolean>(false);
  const { location } = history;

  const authToken = useMemo(() => {
    if (isAuthTokenSet.current) return null;

    const query = parseQueryString(location.search) || {};

    return query.token || query.authToken;
  }, [location.search]);

  if (enabled && !isAuthTokenSet.current && authToken) {
    isAuthTokenSet.current = true;
    // setAuthToken(authToken);
  }
}

export function useSet2FATokenOnce(history: History<LocationState>, enabled: boolean) {
  const is2FATokenSet = useRef<boolean>(false);
  const { location } = history;

  const twoFAToken = useMemo(() => {
    if (is2FATokenSet.current) return null;

    const query = parseQueryString(location.search) || {};

    return query.twoFAToken;
  }, [location.search]);

  if (enabled && !is2FATokenSet.current && twoFAToken) {
    is2FATokenSet.current = true;
    let tokens = localStorage.getItem(LOCAL_STORAGE_KEYS.TWO_FA_TOKENS);

    tokens = tokens?.length ? `${tokens};${twoFAToken}` : (twoFAToken as string);
    localStorage.setItem(LOCAL_STORAGE_KEYS.TWO_FA_TOKENS, tokens as string);
  }
}
