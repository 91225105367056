import React from 'react';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import { compose } from 'recompose';
import { withNavigator } from 'src/app/hoc/index';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import {
  convertLegalAddressToGoogleAddress,
  convertCompanyAddressToGoogleAddress,
  validateAddressValue,
} from 'src/app/utils/address';
import {
  CompanyInfoType,
  UserContextType,
  FieldType,
  GoogleCombinedAddressType,
} from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import { getCompanyInfo, getProfile } from 'src/app/redux/user/selectors';
import PayBillCompleteLegalInfoPage from 'src/app/pages/bill/pay/components/QBOPayBillCompleteLegalInfoPage';
import { AddressNameTypeEnum, TaxIdEnum } from 'src/app/version-2/model/enums';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

type MapStateToProps = {
  companyInfo: RecordOf<CompanyInfoType>;
  profile: RecordOf<UserContextType>;
};

type Props = PayBillProps &
  MapStateToProps & {
    texts?: {
      title: string;
      subtitle: string;
      button: string;
      loading: string;
      einHelperText: string;
    };
    isInternational?: boolean;
    ErrorComponent?: React.ReactNode;
  };

type State = {
  legalAddress: GoogleCombinedAddressType & { inputValue?: string | null };
  legalCompanyName: string;
  taxId?: string;
  taxIdType?: TaxIdEnum;
  contactFirstName: string;
  contactLastName: string;
  dateOfBirth?: string;
  phone?: string;
  openLegalAddressInput?: boolean;
  companyAddress: GoogleCombinedAddressType & { inputValue?: string | null };
};

class QBOPayBillCompleteLegalInfoPageContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      legalAddress: convertLegalAddressToGoogleAddress(this.props.companyInfo),
      legalCompanyName: this.props.companyInfo.legalCompanyName,
      taxId: this.props.companyInfo.taxId,
      taxIdType: this.props.companyInfo.taxIdType,
      contactFirstName: this.props.companyInfo.contactFirstName,
      contactLastName: this.props.companyInfo.contactLastName,
      dateOfBirth: this.props.profile.dateOfBirth,
      phone: this.props.companyInfo.phone,
      companyAddress: convertCompanyAddressToGoogleAddress(this.props.companyInfo),
      openLegalAddressInput: false,
    };
  }

  onLegalInfoChange = ({ id, value }: FieldType) => {
    this.setState((prevState) => ({ ...prevState, [id]: value }));
  };

  onAddressChange = (
    address: GoogleCombinedAddressType,
    type = AddressNameTypeEnum.LEGAL_ADDRESS
  ) => {
    this.setState((prevState) => ({ ...prevState, [type]: address }));
  };

  handleOnSubmit = () => {
    const {
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      dateOfBirth,
      phone,
      companyAddress,
      legalAddress,
      openLegalAddressInput,
    } = this.state;

    const address = openLegalAddressInput ? legalAddress : companyAddress;

    const isLegalAddressValid =
      !openLegalAddressInput ||
      validateAddressValue(legalAddress?.formattedAddress, legalAddress?.inputValue);
    const isCompanyAddressValid = validateAddressValue(
      companyAddress?.formattedAddress,
      companyAddress?.inputValue
    );

    if (!isCompanyAddressValid) {
      this.setState((prevState) => ({
        ...prevState,
        companyAddress: {
          ...prevState.companyAddress,
          formattedAddress: '',
        },
      }));
    }

    this.props.onLegalInfoSubmit(
      address,
      companyAddress,
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      phone,
      isLegalAddressValid,
      isCompanyAddressValid,
      dateOfBirth,
      openLegalAddressInput
    );
  };

  render() {
    const {
      legalAddress,
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      dateOfBirth,
      companyAddress,
      openLegalAddressInput,
      phone,
    } = this.state;
    const {
      onPrev,
      goExit,
      validationErrors,
      isLoading,
      companyInfo,
      profile,
      texts,
      isInternational,
      ErrorComponent,
    } = this.props;

    return (
      <PayBillCompleteLegalInfoPage
        legalCompanyName={legalCompanyName}
        companyName={companyInfo.companyName}
        taxId={taxId}
        contactFirstName={contactFirstName}
        contactLastName={contactLastName}
        dateOfBirth={dateOfBirth}
        companyAddress={companyAddress}
        phone={phone}
        onLegalInfoChange={this.onLegalInfoChange}
        openLegalAddressInput={openLegalAddressInput}
        companyInfo={companyInfo}
        profile={profile}
        taxIdType={taxIdType}
        address={legalAddress}
        onPrev={onPrev}
        goExit={goExit}
        onAddressChange={this.onAddressChange}
        validationErrors={validationErrors}
        handleOnSubmit={this.handleOnSubmit}
        isLoading={isLoading}
        texts={texts}
        isInternational={isInternational}
        ErrorComponent={ErrorComponent}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  companyInfo: getCompanyInfo(state),
  profile: getProfile(state),
});

export default compose(
  (Comp) => (props) =>
    (
      <Comp
        {...props}
        inputFields={[
          'legalCompanyName',
          'taxId',
          'addressLine1',
          'city',
          'zipCode',
          'taxIdType',
          'contactFirstName',
          'contactLastName',
          'phone',
        ]}
      />
    ),
  withNavigator(),
  withPayBillData(),
  withSiteContext(),
  connect(mapStateToProps)
)(QBOPayBillCompleteLegalInfoPageContainer);
