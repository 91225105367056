import React from 'react';
import styled from 'styled-components';

import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import { MIFormattedDate } from 'src/app/utils/formatting';

type Props = {
  dueDate: Date;
  className?: string;
};

export const InvalidFundingSourceNotificationCard = ({ dueDate, className = '' }: Props) => (
  <InfoNotificationCard
    className={className}
    type={NOTIFICATION_CARD_TYPES.INFO}
    title={{
      label: 'bills.status.paymentFailedActionRequired',
    }}
    subtitle={{
      label: 'bills.pay.fundingSource.warningMessage',
      values: {
        date: <MIFormattedDate date={dueDate} />,
      },
    }}
  />
);

const InfoNotificationCard = styled(QBONotificationCard)`
  margin-bottom: 2rem;
`;
