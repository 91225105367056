import { Box, Badge, VStack } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { HOLIDAYS_WARNING_ICON_VARIANT, DELIVERY_TYPE } from 'src/app/utils/consts';
import { DeliveryOptionType } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import { HolidaysWarning } from '../HolidaysWarning';

import {
  PaymentDeliveryOptionTitle,
  PaymentDeliveryOptionDescription,
  PaymentDeliveryOptionPrice,
} from './components';
import * as styles from './PaymentDeliveryOption.styles';

interface Props {
  deliveryOption: DeliveryOptionType;
  isFast: boolean;
  fundingSourceType?: string;
  paymentAmount: number;
  fee: number;
  tagLabel: string;
  expeditedAchDeliveryDate?: Date;
}

const PaymentDeliveryOption = ({
  deliveryOption,
  isFast,
  fundingSourceType,
  paymentAmount,
  fee,
  tagLabel,
  expeditedAchDeliveryDate,
}: Props) => {
  const [isUSHolidaysChecksEnabled] = featureFlags.useFeature(
    FeatureFlagsEnum.US_HOLIDAYS_CHECKS,
    false
  );

  const showHolidaysWarning =
    isUSHolidaysChecksEnabled && !isFast && deliveryOption.type === DELIVERY_TYPE.CHECK;

  return (
    <VStack sx={styles.containerStyle}>
      <Box sx={styles.titleStyle}>
        <PaymentDeliveryOptionTitle
          deliveryOption={deliveryOption}
          expeditedAchDeliveryDate={expeditedAchDeliveryDate}
        />
        {showHolidaysWarning && (
          <HolidaysWarning
            tooltipLabel="holidaysWarning.tooltipLabels.scheduled"
            customIconStyles={styles.warningStyle}
            variant={HOLIDAYS_WARNING_ICON_VARIANT.WARNING}
          />
        )}
        {isFast && (
          <Badge data-testid="fast-ach-tag" variant="primaryOutlined" sx={styles.tagStyle}>
            <MIFormattedText label={tagLabel} />
          </Badge>
        )}
      </Box>

      <Box sx={styles.descriptionStyle}>
        <PaymentDeliveryOptionDescription
          deliveryOption={deliveryOption}
          paymentAmount={paymentAmount}
          fundingSourceType={fundingSourceType}
        />
        <PaymentDeliveryOptionPrice deliveryOptionType={deliveryOption.type} feeAmount={fee} />
      </Box>
    </VStack>
  );
};

export { PaymentDeliveryOption };
