import React, { useEffect } from 'react';
import { Switch, Redirect, generatePath, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { useShiftToDebitState } from 'src/app/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import analytics from 'src/app/services/analytics';
import AddDeliveryMethodPagePushToDebit from 'src/app/pages/vendor/shift-vendor-to-debit/QBOAddDeliveryMethodPage';
import AddAddressDetailsPagePushToDebit from 'src/app/pages/vendor/shift-vendor-to-debit/QBOAddAddressDetailsPage';
import AddDeliveryMethodSuccessPagePushToDebit from 'src/app/pages/vendor/shift-vendor-to-debit/QBOAddDeliveryMethodSuccessPage';
import CheckDeposited from 'src/app/pages/vendor/shift-vendor-to-debit/notifications/CheckDeposited';
import PaymentProcessed from 'src/app/pages/vendor/shift-vendor-to-debit/notifications/PaymentProcessed';
import CheckOfferExpired from 'src/app/pages/vendor/shift-vendor-to-debit/notifications/CheckOfferExpired';
import PaymentFailed from 'src/app/pages/vendor/shift-vendor-to-debit/notifications/PaymentFailed';
import InvalidLinkPage from 'src/app/pages/vendor/components/InvalidLinkPage';

import locations from '../locations';

const ANALYTICS_TAG_NAME = 'ShiftToDebitRouter';

export const ShiftToDebitRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  const { state, deliveryMethodMV, onValidateAddress } = useShiftToDebitState(token);
  const { payment, vendor } = state;
  const originDeliveryType = state.payment?.deliveryMethod?.deliveryType;

  const updateExtraProperties = () => {
    if (payment && vendor) {
      analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
        paymentId: payment?.id,
        vendorId: vendor?.id,
        vendorEmail: vendor?.contactEmail,
        originDeliveryType,
      });
    }

    const cleanExtraProperties = () => {
      analytics.removeExtraProperties(ANALYTICS_TAG_NAME);
    };

    return cleanExtraProperties;
  };

  useEffect(updateExtraProperties, [payment, vendor]);

  return (
    <Switch>
      <SmartRoute path={locations.shiftToDebit.index} exact>
        <Redirect to={generatePath(locations.shiftToDebit.addDebitMethod, { token })} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addDebitMethod} exact>
        <AddDeliveryMethodPagePushToDebit token={token} state={state} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.addAddress} exact>
        <AddAddressDetailsPagePushToDebit
          token={token}
          state={state}
          deliveryMethodMV={deliveryMethodMV}
          onValidateAddress={onValidateAddress}
        />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.invalidToken} exact>
        <InvalidLinkPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.success} exact>
        <AddDeliveryMethodSuccessPagePushToDebit state={state} token={token} />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.checkDeposited} exact>
        <CheckDeposited />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.checkOfferExpired} exact>
        <CheckOfferExpired />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.paymentProcessed} exact>
        <PaymentProcessed />
      </SmartRoute>
      <SmartRoute path={locations.shiftToDebit.paymentFailed} exact>
        <PaymentFailed state={state} />
      </SmartRoute>
    </Switch>
  );
};
