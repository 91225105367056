import * as React from 'react';
import styled from 'styled-components';
import { CONSTS, DELIVERY_DATE_FONT_TYPE } from 'src/app/utils/consts';
import { DeliveryDate } from 'src/app/utils/dates';
import QBROutsideLayout from 'src/app/components/layout/QBROutsideLayout';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import MIButton from '../common/MIButton';

type Props = {
  textValues?: Record<string, any>;
  buttonLabel?: string;
  buttonAction?: () => void;
  buttonValues?: Record<string, any>;
  linkLabel?: string | null;
  linkAction?: () => void;
  isLoading?: boolean;
};

const QBRSuccessLayout = ({
  textValues,
  buttonLabel,
  buttonAction,
  buttonValues,
  linkLabel,
  linkAction,
  isLoading,
}: Props) => (
  <QBROutsideLayout hideLogin headerTitle={textValues?.title} hideFooter>
    <BillPaymentBlock>
      <BillInfoContainer>
        <SuccessRow>
          <SuccessRowTitle>
            <MIFormattedText label="qbr.success.amount" />
          </SuccessRowTitle>
          <SuccessRowContent>
            <MIFormattedCurrency value={textValues?.totalAmount} />
          </SuccessRowContent>
        </SuccessRow>
        <SuccessRow>
          <SuccessRowTitle>
            <MIFormattedText label="qbr.success.to" />
          </SuccessRowTitle>
          <SuccessRowContent>
            <SuccessRowContent>
              <MIFormattedText
                label="qbr.success.toTitle"
                values={{ companyName: textValues?.vendorName }}
              />
            </SuccessRowContent>
            <SuccessRowContentSubtitle>
              <MIFormattedText
                label={textValues?.toSubtitle}
                values={{ to: textValues?.deliveryMethodInfo }}
              />
            </SuccessRowContentSubtitle>
          </SuccessRowContent>
        </SuccessRow>
        <SuccessRow>
          <SuccessRowTitle>
            <MIFormattedText label="qbr.success.scheduledDate" />
          </SuccessRowTitle>
          <SuccessRowContent>
            <DeliveryDate
              date={textValues?.scheduledDate}
              fontType={DELIVERY_DATE_FONT_TYPE.REGULAR}
            />
          </SuccessRowContent>
        </SuccessRow>
        <SuccessRow>
          <SuccessRowTitle>
            <MIFormattedText label="qbr.success.deliveryETA" />
          </SuccessRowTitle>
          <SuccessRowContent>
            <DeliveryDate
              date={textValues?.deliveryEta}
              maxDate={textValues?.maxDeliveryEta}
              fontType={DELIVERY_DATE_FONT_TYPE.REGULAR}
            />
          </SuccessRowContent>
        </SuccessRow>
      </BillInfoContainer>
    </BillPaymentBlock>

    {textValues?.note && (
      <Note>
        <MIFormattedText
          label={textValues?.note}
          values={{
            scheduledDate: <DeliveryDate date={textValues?.scheduledDate} />,
          }}
        />
      </Note>
    )}

    <ButtonContainer>
      {buttonLabel && buttonAction && (
        <MIButton
          label={buttonLabel}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onClick={buttonAction}
          isProcessing={isLoading}
          values={buttonValues}
        />
      )}

      {linkLabel && linkAction && (
        <MIButton
          label={textValues?.linkLabel}
          variant={textValues?.linkVariant}
          onClick={linkAction}
        />
      )}
    </ButtonContainer>
  </QBROutsideLayout>
);

const SuccessRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${(props) => props.theme.text.fontType.regular};
  padding: 2rem 0;
  border-bottom: 0.05rem solid ${(props) => props.theme.colors.border.light};
`;

const SuccessRowTitle = styled.div`
  color: ${(props) => props.theme.text.color.darkGrey};
`;

const SuccessRowContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: ${(props) => props.theme.text.weight.medium};
  color: ${(props) => props.theme.text.color.main};
`;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.6rem;
`;

const BillInfoContainer = styled.div`
  padding: 0 1.6rem;
`;

const SuccessRowContentSubtitle = styled.div`
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.darkGrey};
  margin-top: 0.4rem;
`;

const Note = styled.div`
  display: block;
  text-align: center;
  ${(props) => props.theme.text.fontType.small};
  color: ${(props) => props.theme.text.color.darkGrey};
  margin-bottom: 1.6rem;
  > span {
    ${(props) => props.theme.text.fontType.small};
    color: ${(props) => props.theme.text.color.darkGrey};
  }
  padding: 0 1.6rem;
`;

const ButtonContainer = styled.div`
  disaply: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.6rem;
  > button {
    width: 100%;
    :first-child {
      margin-bottom: 1.6rem;
    }
  }
  position: sticky;
  bottom: 0;
  background: ${(props) => props.theme.colors.white.opaque};
`;

export default QBRSuccessLayout;
