import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';
import { LearnMoreModal as Modal } from 'src/app/pages/qb-dashboard/components/InternationalEntryPoint/components/LearnMoreModal';
import { useModal } from 'src/app/helpers/react/useModal';
import { Box, Link } from '@melio/billpay-design-system';
import { flagsStore } from 'src/app/modules/flags/flags-store';
import JustPayLayoutPage from 'src/app/pages/just-pay/JustPayLayoutPage';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { useLocation } from 'react-router-dom';
import locations from 'src/app/utils/locations';
import { useBillModalState } from '../../BillModal/hooks/useBillModalState';
import { Form } from './Form/Form';
import { ANALYTICS_PAGE_NAME } from '../../BillModal/utils';

enum BUTTON_ACTION_TYPES {
  SAVE = 'save',
  SCHEDULE = 'schedule',
}

type Props = {
  onPrev: () => void;
  onExit: () => void;
};

type OnBillSavedProps = { billId: string; vendorId: string; isNewVendor: boolean };

const TEXTS_PREFIX = 'paymentDashboard.addBillToDashboard.billModal';

const InternationalBillPage = ({ onPrev, onExit }: Props) => {
  const [buttonActionType, setButtonActionType] = useState('');
  const [isMainDataLoading, setIsMainDataLoading] = useState(false);
  const [LearnMoreModal, showLearnMoreModal] = useModal(Modal, {
    confirm: () => {
      analytics.track(ANALYTICS_PAGE_NAME, 'LearnMoreModal-Checked', {
        flow: 'international-payment',
      });
    },
    close: () => {
      analytics.track(ANALYTICS_PAGE_NAME, 'LearnMoreModal-Closed', {
        flow: 'international-payment',
      });
    },
  });
  const [historyPush] = useHistoryWithOrgId();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isInternationalEntryPoint } = useSelector((state) => state['flags']);

  useEffect(() => {
    analytics.track(ANALYTICS_PAGE_NAME, 'add-bill-modal-show', { flow: 'international-payment' });

    if (!isInternationalEntryPoint) dispatch(flagsStore.actions.setIsInternationalEntryPoint(true));
  }, []);

  const onBillSavedAndSynced = useCallback(
    ({ billId, vendorId, isNewVendor }: OnBillSavedProps) => {
      analytics.track(ANALYTICS_PAGE_NAME, 'schedule-payment-click', {
        billId,
        isNewVendor,
        vendorId,
        flow: 'international-payment',
      });

      if (buttonActionType === BUTTON_ACTION_TYPES.SCHEDULE) {
        schedulePayment(billId);
        setButtonActionType('');
      }
    },
    [buttonActionType]
  );

  const onFailure = (errorType: string) => {
    analytics.track(ANALYTICS_PAGE_NAME, 'schedule-payment-failure', { errorType });
    setButtonActionType('');
  };

  const billModalState = useBillModalState({
    onBillSavedAndSynced,
    onEditBillSaved: null,
    onFailure,
    isInternationalEntrypoint: true,
    billId: null,
    setIsMainDataLoading,
  });

  const schedulePayment = useCallback((billId) => {
    const redirectUrl = `${location.pathname}${location.search}`;

    setTimeout(() => {
      historyPush({
        path: locations.Bills.pay.funding.url({ id: billId, international: 'international' }),
        state: {
          exitUrl: redirectUrl,
          billId,
        },
      });
    });
  }, []);

  const handleSchedulePaymentClick = useCallback(async () => {
    setButtonActionType(BUTTON_ACTION_TYPES.SCHEDULE);
    analytics.track(ANALYTICS_PAGE_NAME, 'schedule-payment-try', { flow: 'international-payment' });
  }, [billModalState]);

  const saveBill = useCallback(async () => {
    await billModalState.billModalMVActions.submit();
  }, [billModalState]);

  useEffect(() => {
    if (buttonActionType) {
      saveBill();
    }
  }, [buttonActionType]);

  const primaryButtonProps = {
    label: `${TEXTS_PREFIX}.buttons.schedulePayment`,
    onClick: handleSchedulePaymentClick,
    isProcessing: billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SCHEDULE,
    disabled:
      isMainDataLoading ||
      (billModalState.isLoading && buttonActionType === BUTTON_ACTION_TYPES.SAVE),
    variant: CONSTS.BUTTON_VARIANT.PRIMARY,
  };

  const onShowLearnMoreModal = useCallback(() => {
    analytics.track(ANALYTICS_PAGE_NAME, 'LearnMoreModal-Viewed', {
      flow: 'international-payment',
    });
    showLearnMoreModal();
  }, []);

  return (
    <>
      <JustPayLayoutPage goExit={onExit} onPrev={onPrev} isQboFooter relativeStep={1 / 10}>
        <Body>
          <Title>
            <MIFormattedText label="internationalPayment.internationalBill.title" />
          </Title>
          <Subtitle>
            <MIFormattedText label="internationalPayment.internationalBill.subtitle" />
            <Link
              textStyle="body3"
              color="blue.500"
              cursor="pointer"
              onClick={onShowLearnMoreModal}
            >
              <MIFormattedText label="internationalPayment.internationalBill.learnMore" />
            </Link>
          </Subtitle>
          <Form
            isEditBillMode={false}
            vendorOptions={billModalState.vendorOptions}
            billModalMV={billModalState.billModalMV}
            categoryOptions={billModalState.categoryOptions}
            isLoading={isMainDataLoading}
          />
          <Container>
            <MIButton {...primaryButtonProps} />
            <Box textStyle="body4">
              <MIFormattedText label="internationalPayment.internationalBill.flatFee" />
            </Box>
          </Container>
        </Body>
      </JustPayLayoutPage>
      {LearnMoreModal}
    </>
  );
};

const Body = styled.div`
  padding: 4rem 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 4rem;
  gap: 2rem;
`;

const Title = styled.h1`
  h1: {
    margin: 0 0 1rem 0;
  }
  text-align: center;
  color: ${({ theme }) => theme.text.color.main};
  font-weight: ${({ theme }) => theme.text.weight.normal};
  font-size: ${({ theme }) => theme.text.size.wizardStepTitle};
`;

const Subtitle = styled.div`
  margin-bottom: 4rem;
  text-align: center;
  color: ${({ theme }) => theme.text.color.main};
  font-size: ${({ theme }) => theme.text.size.wizardText};
  a {
    font-size: inherit;
  }
`;

export { InternationalBillPage };
