import { BillStatusType } from 'src/app/utils/types';
import { BILL_STATUS, PAGINATION } from 'src/app/utils/consts';
import { encodeQuery } from 'src/app/utils/query-utils';

export const getBillsDefaultFilters = (status: BillStatusType) => {
  const statusFilterParamsMap = {
    [BILL_STATUS.UNPAID]: {
      status: BILL_STATUS.UNPAID,
      sorting: 'payment.status,dueDate',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
    [BILL_STATUS.SCHEDULED]: {
      status: BILL_STATUS.SCHEDULED,
      sorting: 'payment.scheduledDate',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
    [BILL_STATUS.PAID]: {
      status: BILL_STATUS.PAID,
      sorting: 'payment.scheduledDate:desc',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
  };

  return statusFilterParamsMap[status];
};
export const getBillsSearchPath = (
  status: BillStatusType,
  baseSearch = '',
  excludeFields: string[] = [],
  start: number = PAGINATION.DEFAULT_START,
  limit: number = PAGINATION.DEFAULT_LIMIT
) => {
  const defaultFilters = getBillsDefaultFilters(status);
  const billFilters = {
    ...defaultFilters,
    start,
    limit,
  };

  return encodeQuery(billFilters, excludeFields, baseSearch);
};
