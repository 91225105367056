import React from 'react';
import styled from 'styled-components';
import { Badge, BadgeVariants } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS } from 'src/app/utils/consts';
import { ReactComponent as InternationalIcon } from 'src/app/images/qbo/international/delivery-icon.svg';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';
import { MethodOptionLabel, MethodOptionDescription, MethodOptionLogo } from './MethodElements';

type SelectMethodOptionProps = {
  id: string;
  icon?: string;
  imageSrc?: string;
  label: string;
  labelValues?: Record<string, any>;
  description: string;
  descriptionValues?: Record<string, any>;
  disabled?: boolean;
  isSelected?: boolean;
  onClick: (event: any) => void;
  method: any;
  tag?: {
    label: string;
    variant: BadgeVariants;
  };
};

function SelectMethodOption({
  id,
  tag,
  method,
  icon,
  imageSrc,
  label,
  labelValues = {},
  description,
  descriptionValues = {},
  disabled,
  isSelected,
  onClick,
}: SelectMethodOptionProps) {
  const handleClick = (e) => {
    if (disabled) return;

    onClick(e);
  };

  const getDeliveryIcon = () => {
    switch (method?.deliveryType || id) {
      case CONSTS.DELIVERY_TYPE.INTERNATIONAL:
        return <StyledInternationalIcon />;

      case CONSTS.DELIVERY_TYPE.VIRTUAL_CARD:
        return (
          <StyledVirtualCardIcon>
            <Mastercard width="3.5rem" height="2.8rem" />
          </StyledVirtualCardIcon>
        );

      default:
        return <MethodOptionLogo icon={icon} imageSrc={imageSrc} alt="option-icon" />;
    }
  };

  return (
    <SelectOptionContainer
      data-testid={`method-select-${id}`}
      disabled={disabled}
      onClick={handleClick}
    >
      {getDeliveryIcon()}
      <MethodInfoContainer>
        <MethodOptionLabel data-testid="select-method-option-label" disabled={disabled}>
          {label && <MIFormattedText label={label} values={labelValues} />}
          {tag && (
            <Tag variant={tag.variant}>
              <MIFormattedText label={tag.label} />
            </Tag>
          )}
        </MethodOptionLabel>
        {description && (
          <MethodOptionDescription disabled={disabled}>
            <MIFormattedText label={description} values={descriptionValues} />
          </MethodOptionDescription>
        )}
      </MethodInfoContainer>
      {isSelected && <SelectedIcon />}
    </SelectOptionContainer>
  );
}

export default SelectMethodOption;

const SelectOptionContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 8.4rem;
  box-sizing: border-box;
  padding: 2rem;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.white.veryLightGrey : props.theme.colors.white.opaque};
`;

const StyledInternationalIcon = styled(InternationalIcon)`
  min-width: 4rem;
  height: 4rem;
  margin-right: 2rem;
`;

const StyledVirtualCardIcon = styled.div`
  display: flex;
  align-self: flex-start;
  margin-left: 0.3rem;
  margin-right: 2rem;
`;

const SelectedIcon = styled.i.attrs({
  className: 'icon-notification-checkmark-icon',
})`
  font-size: 4rem;
  color: ${(props) => props.theme.colors.brand};
`;

const MethodInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Tag = styled(Badge)`
  margin-left: 0.8rem;
`;
