import { featureFlags } from '@melio/shared-web';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { userApi } from 'src/app/version-2/api/user.api';
import { CheckFeeEnum, FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { userActions, userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import {
  isAchCheckPayment,
  isCheckFeeOpenNoPromotions,
  isCheckFeeOpenWithPromotions,
} from 'src/app/version-2/utils/checkFees.utils';

import { call, put, select, takeEvery } from 'redux-saga/effects';

const MAX_NOTIFICATION_COUNT = 1;

function* fetchCompanyInfoHandler() {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const { localCompanyInfo } = yield call(userApi.getCompanyInfo, { orgId, local: true });

    yield put(userActions.setCompanyInfoSuccess(localCompanyInfo));
  } catch (e) {
    yield put(userActions.setCompanyInfoFailed());
    loggingApi.error('userSaga.fetchCompanyInfoHandler(): failed', e);
  }
}

function* increaseCheckFeePaymentCountHandler(
  action: ReturnType<typeof userActions.increaseCheckFeePaymentCount>
) {
  try {
    const checkFeeFlag = featureFlags.defaultClient.getVariantNoTrack(
      FeatureFlagsEnum.CHECK_FEES,
      CheckFeeEnum.CLOSED
    );
    const userPreferences = yield select(userSliceSelectors.selectUserPreferences);
    const isValidCheckFeePayment = isAchCheckPayment(action.payload);

    // init notification count
    const checkFeeEnabledWithPromotions = isCheckFeeOpenWithPromotions(checkFeeFlag);
    const feeNotificationCount = Number(userPreferences.checkFeesNotificationCount) || 0;

    // promotions ended count
    const checkFeeOpenNoPromotions = isCheckFeeOpenNoPromotions(checkFeeFlag);
    const promotionsEndedCount = Number(userPreferences.checkFeesPromotionsEndedCount) || 0;

    if (
      checkFeeEnabledWithPromotions &&
      isValidCheckFeePayment &&
      feeNotificationCount < MAX_NOTIFICATION_COUNT + 1
    ) {
      yield call(userApi.updateUserPreference, {
        key: 'checkFeesNotificationCount',
        value: String(feeNotificationCount + 1),
      });
    }

    if (
      checkFeeOpenNoPromotions &&
      isValidCheckFeePayment &&
      promotionsEndedCount < MAX_NOTIFICATION_COUNT + 1
    ) {
      yield call(userApi.updateUserPreference, {
        key: 'checkFeesPromotionsEndedCount',
        value: String(promotionsEndedCount + 1),
      });
    }
  } catch (e) {
    loggingApi.error('userSaga.increaseCheckFeePaymentCountHandler(): failed', e);
  }
}

export function* watchUser() {
  yield takeEvery(userActions.fetchCompanyInfo, fetchCompanyInfoHandler);
  yield takeEvery(userActions.increaseCheckFeePaymentCount, increaseCheckFeePaymentCountHandler);
}
