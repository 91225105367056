import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

function ReasonLog({ label, children }) {
  return (
    <ReasonLogContainer>
      <Label>
        <MIFormattedText label={label} />:
      </Label>
      <Reason>{children}</Reason>
    </ReasonLogContainer>
  );
}

const ReasonLogContainer = styled.div`
  min-width: 100%;
  margin-top: 2rem;
`;

const Reason = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

const Label = styled.div`
  color: ${(props) => props.theme.text.color.grey};
  font-size: ${(props) => props.theme.text.size.wizardText};
  line-height: 2rem;
`;

export default ReasonLog;
