import React from 'react';
import styled from 'styled-components';
import locations from 'src/app/utils/locations';
import analytics from '../../services/analytics';

interface Props {
  navigate: (path: string, params?: boolean, state?: any) => void;
  testId?: string;
}

const QBOFab = ({ navigate, testId = 'qbo-fab-container' }: Props) => {
  const onClick = () => {
    analytics.trackAction('fab.action');
    // TODO temporary changes
    navigate(locations.Bills.create.index.url(), false, { manually: true });
  };

  return (
    <QBOFabContainer data-testid={testId} onClick={onClick}>
      <PlusIcon className="icon-plus-icon" />
    </QBOFabContainer>
  );
};

export default QBOFab;

const QBOFabContainer = styled.div`
  display: none;
  z-index: 100;
  ${(props) => props.theme?.components?.ListLayoutPage?.QBOFab}
`;

const PlusIcon = styled.i`
  font-size: 1.6em;
  color: ${(props) => props.theme.colors.white.opaque};
`;
