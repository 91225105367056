import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { QBCashStateType, OptionalDeliveryMethodsType } from 'src/app/utils/types';
import styled, { css } from 'styled-components';
import { useApi } from 'src/app/hoc/useApi';
import { MIFormattedText } from 'src/app/utils/formatting';
import { getOrgId } from 'src/app/redux/user/selectors';
import deliveryApi from 'src/app/services/api/delivery';
import { devices } from 'src/app/theme/AppDevices';
import MIMoney from 'src/app/components/common/MIMoney';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  JustPayPaymentActivity as PaymentActivity,
  ActivityActionsType,
} from './PaymentActivity/PaymentActivity';
import JustPayPaymentFee from './JustPayPaymentFee';

type Props = {
  fee?: PaymentFeeInfo;
  fundingSource: FundingSource;
  payment: RecordOf<JustPayPaymentType>;
  activityActions: ActivityActionsType;
  qbCashState?: QBCashStateType;
  isVendorAbsorbedFee?: boolean;
  deliveryMethodType: OptionalDeliveryMethodsType | undefined;
};

type ApprovalDecisionStatusResponse = {
  approvalDecisionStatus: PAYMENT_APPROVAL_STATUS;
};
export const JustPayPaymentReview = ({
  fee,
  fundingSource,
  payment,
  activityActions,
  qbCashState,
  isVendorAbsorbedFee,
  deliveryMethodType,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const [getApprovalDecisionStatus, approvalDecisionStatusResult] = useApi<
    [string, string],
    ApprovalDecisionStatusResponse
  >(deliveryApi.getApprovalDecisionStatus);
  const totalAmount = payment.amount ? payment.amount.toString() : '';
  const paymentData = {
    ...payment,
    approvalDecisionStatus: approvalDecisionStatusResult?.approvalDecisionStatus,
  };
  const isVendorAbsorbedCardFee =
    isVendorAbsorbedFee && fundingSource?.fundingType === FundingSourceTypesEnum.CARD;

  useEffect(() => {
    getApprovalDecisionStatus(orgId, totalAmount);
  }, [orgId, totalAmount, getApprovalDecisionStatus]);

  return (
    <MainBox className="review">
      <BaseContainer>
        <HighlightedHeader>
          <SubTitle>
            <MIFormattedText label="bills.pay.confirm.total" />
          </SubTitle>
          <AmountContainer>
            <StyledMIMoney size="large" amount={totalAmount} />
          </AmountContainer>
        </HighlightedHeader>
      </BaseContainer>

      <PayBillDetails companyName={payment.vendor?.companyName} />
      <PaymentActivityContainer>
        <PaymentActivity
          fundingSource={fundingSource}
          payment={paymentData}
          activityActions={activityActions}
          qbCashState={qbCashState}
        />
      </PaymentActivityContainer>
      {fee && (
        <JustPayPaymentFee
          fee={fee}
          isVendorAbsorbedCardFee={isVendorAbsorbedCardFee}
          deliveryMethodType={deliveryMethodType}
          fundingSource={fundingSource}
        />
      )}
    </MainBox>
  );
};

type PayBillDetailsProps = {
  companyName: string;
};

const PayBillDetails = ({ companyName }: PayBillDetailsProps) => (
  <PaymentInfoContainer>
    <SubTitle>
      <MIFormattedText label="bills.pay.confirm.payTo" />
    </SubTitle>
    <BoxRow>
      <BoxColumn>
        <FieldName>
          <MIFormattedText label="bills.pay.confirm.businessName" />
        </FieldName>
        <FieldValue data-testid="bill-company-name">{companyName}</FieldValue>
      </BoxColumn>
    </BoxRow>
    <SectionSeparator />
  </PaymentInfoContainer>
);

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.14);

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.MainBox}
`;

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

const HighlightedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.HighlightedHeader}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const PaymentActivityContainer = styled(BaseContainer)`
  padding-bottom: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentActivityContainer}
`;

const PaymentInfoContainer = styled(BaseContainer)`
  padding-bottom: 1.7rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentInfoContainer}
`;

const BoxRow = styled.div`
  display: flex;
  margin-top: 1.8rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  padding-right: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}

  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldName}
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldValue}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;
const SectionSeparator = styled.div`
  display: none;
  ${(props) => props.theme?.components?.BillPaymentReview?.SectionSeparator}
`;
