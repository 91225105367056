import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import batchBillsStore from 'src/app/modules/batch-bills/batch-bills-store';
import { getOrgId, getFundingSources } from 'src/app/redux/user/selectors';
import { Loader } from '@melio/billpay-design-system';
import accountingSoftwareSync from 'src/app/services/api/accountingSoftwareSync';
import { useLocationState } from 'src/app/utils/hooks';
import locations from 'src/app/utils/locations';
import globalLocations from 'src/app/pages/locations';
import { getPaymentsActions } from 'src/app/modules/payments/payment-store';
import { hasValidFundingSources } from 'src/app/utils/funding-source-utils';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import useResetQboBatchPaymentsState from '../bill/pay/hooks/useResetQboBatchPaymentsState';
import { usePartialPaymentsEnabled } from '../bill/hoc/withPartialPaymentsEnabled';
import { loggingApi } from 'src/app/version-2/api/loggers';

const QBOSyncBillsPage = () => {
  const dispatch = useDispatch();
  const [resetQboBatchPaymentsState] = useResetQboBatchPaymentsState();
  const { setQboBatchPaymentsNavigationUrls } = getPaymentsActions(dispatch);
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const fundingSources = useSelector(getFundingSources);
  const [redirectUrl] = useLocationState('redirectUrl');
  const [exitUrl] = useLocationState('exitUrl');
  const [billsToSync] = useLocationState('billsToSync');
  const errorUrl = globalLocations.entrypoints.errors.billSyncError;

  const synchronizeBills = async () => {
    const originBillsIds = billsToSync.map((b) => b.originId);
    const result = await accountingSoftwareSync.syncMultipleBillsByOriginId(orgId, originBillsIds);
    const billsToPay = billsToSync.filter((b) => result.bill.includes(b.id));
    const ids = billsToPay.map((b) => b.id);
    const amounts = isPartialPaymentsEnabled ? billsToPay.map((b) => b.amount) : undefined;

    return { ids, amounts };
  };

  const fetchBills = ({ orgId, billIds }) =>
    dispatch(batchBillsStore.actions.getBillListByIdsSlice({ orgId, billIds }));

  const redirectToBatchFlow = async () => {
    try {
      const { ids, amounts } = await synchronizeBills();

      resetQboBatchPaymentsState();

      let batchUrl;
      const shouldAddFundingSource = !hasValidFundingSources(fundingSources);

      if (shouldAddFundingSource) {
        await fetchBills({ orgId, billIds: ids });

        batchUrl = isPartialPaymentsEnabled
          ? `${locations.Bills.pay.batchFunding.url({ ids, orgId })}&amounts=${amounts}`
          : locations.Bills.pay.batchFunding.url({ ids, orgId });
      } else {
        batchUrl = isPartialPaymentsEnabled
          ? `${locations.Bills.pay.batch.url({ ids, orgId })}&amounts=${amounts}`
          : locations.Bills.pay.batch.url({ ids, orgId });
      }

      setQboBatchPaymentsNavigationUrls({
        redirectUrl,
        exitUrl,
      });

      dispatch(batchBulkActions.setExitUrl({ exitUrl }));

      history.replace(batchUrl);
    } catch (e: any) {
      loggingApi.error('QBOSyncBillsPage.redirectToBatchFlow(): error when redirecting', {
        error: e,
        paybill: true,
      });
      history.replace(errorUrl, { exitUrl });
    }
  };

  useEffect(() => {
    redirectToBatchFlow();
  }, []);

  return <Loader />;
};

export default QBOSyncBillsPage;
