import { DeliveryMethod } from 'src/app/version-2/model/dtos';
import { DeliveryMethodItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  getDeliveryMethodDescription,
  getDeliveryMethodLabel,
  getImageSrc,
} from 'src/app/version-2/utils/deliveryMethod.utils';

interface DeliveryMethodAdapterParams {
  deliveryMethod: DeliveryMethod;
  isDisabled?: boolean;
}

export const deliveryMethodAdapter = ({
  deliveryMethod,
  isDisabled,
}: DeliveryMethodAdapterParams): DeliveryMethodItem => {
  const imageSrc = getImageSrc(deliveryMethod);
  const label = getDeliveryMethodLabel(deliveryMethod);
  const description = getDeliveryMethodDescription(deliveryMethod);
  // const isRPPSDeliveryMethod = deliveryMethod?.deliveryType === DeliveryType.RPPS; // TODO: Denis - need to add RPPS and VC tooltip
  // const isVirtualCardDeliveryMethod = deliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD;
  // const showTooltip = isRPPSDeliveryMethod || isVirtualCardDeliveryMethod;

  return {
    ...deliveryMethod,
    meta: {
      label,
      imageSrc,
      description,
      isDisabled: !!isDisabled,
    },
  };
};
