import React from 'react';
import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';

import { ManualAddressType } from 'src/app/components/common/ManualAddress/ManualAddressOptionsContainer';
import { ModelView } from 'src/app/ui/form';
import {
  AddressType,
  EditableDeliveryMethodType,
  OptionalDeliveryMethodsType,
} from 'src/app/utils/types';
import MIButton from 'src/app/components/common/MIButton';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { MIFormattedText } from 'src/app/utils/formatting';
import { FormRow } from 'src/app/ui/form/FormElements';
import { CONSTS } from 'src/app/utils/consts';
import { ContentWrapper, BackButton } from 'src/app/pages/vendor/components/VendorLayoutElements';
import useWhitePageAction from 'src/app/pages/vendor/hoc/white-page-hooks';
import { BillPaymentBlock, BlockSeparator, Title } from './shared';

type Props = {
  setWhitePageAddressView: (value: boolean) => void;
  whitePageAddress: ManualAddressType;
  model: ModelView<AddressType>;
  isLoading: boolean;
  submit: (deliveryMethod: EditableDeliveryMethodType, isAddressVerified: boolean) => Promise<void>;
  deliveryMethodType?: OptionalDeliveryMethodsType;
  header: React.ReactNode;
};

function WhitePageAddressContainer(props: Props) {
  const { isLoading, setWhitePageAddressView } = props;
  const [confirmAddress, renderWhitePageAddress] = useWhitePageAction(props);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <Box textAlign="center" marginBottom={5} marginTop={10}>
        <QBLogo />
      </Box>
      <StyledBillPaymentBlock title="vendors.addDeliveryMethodByLink.manualAddressTitle">
        <StyledBackButton onPrev={() => setWhitePageAddressView(false)} />
        {props.header}
        <BlockSeparator $width="80%" />
        <ContentWrapper>
          <Title>
            <MIFormattedText label="vendors.addDeliveryMethodByLink.manuaulTitle" />
          </Title>
          <ManualAddressSubTitle>
            <MIFormattedText label="vendors.addDeliveryMethodByLink.manualAddressSubTitle" />
          </ManualAddressSubTitle>
          {renderWhitePageAddress()}
          <ButtonContainer>
            <MIButton
              onClick={confirmAddress}
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              label="vendors.editCheckAddress.suggestedAddress.manualAddressCTA"
              fullWidth
              isProcessing={isLoading}
            />
          </ButtonContainer>
        </ContentWrapper>
      </StyledBillPaymentBlock>
    </QBOLayoutPage>
  );
}

const StyledBillPaymentBlock = styled(BillPaymentBlock)`
  position: relative;
`;

const StyledBackButton = styled(BackButton)`
  z-index: 999;
  top: 4rem;
`;

const ButtonContainer = styled(FormRow)`
  margin-top: 4rem;
`;

const ManualAddressSubTitle = styled.div`
  margin-bottom: 4rem;
  ${(props) => props.theme.text.fontType.regular};
  font-size: 1.4rem;
`;

export default WhitePageAddressContainer;
