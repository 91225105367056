import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import { ReactComponent as Icon } from 'src/app/images/icons/debit-card.svg';
import shiftToDebitLocations from 'src/app/pages/vendor/shift-vendor-to-debit/locations';
import { MIFormattedText } from 'src/app/version-2/externals';
import { Payment } from 'src/app/version-2/model/dtos';
import { UpsellType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upsellType.enum';
import {
  getVendorUpgradeAnalytics,
  upsellTypeToAnalyticsOptionName,
  vendorUpgradePaymentClientEventNames,
  vendorUpgradePaymentServerEventNames,
} from 'src/app/version-2/pages/vendor-upgrade-payment/utils/analytics.utils';
import { Container, IconContainer, Link, Text } from './DebitCardPromotion.styles';

interface Props {
  token: string;
  isEnabled: boolean;
  payment?: Partial<Payment>;
}

export const DebitCardPromotion = ({ token, isEnabled, payment }: Props) => {
  const history = useHistory();

  const { trackServerAction, trackUIAction } = useMemo(
    () => getVendorUpgradeAnalytics(token, payment),
    [payment, token]
  );

  const handleDebitCardPromotionClick = () => {
    if (isEnabled) {
      trackUIAction(vendorUpgradePaymentClientEventNames.SELECT_OPTION, {
        selectedOption: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_DEBIT],
      });

      trackServerAction(vendorUpgradePaymentServerEventNames.SELECT_OPTION, {
        selectedOption: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_DEBIT],
      });

      history.push(generatePath(shiftToDebitLocations.index, { token }));
    }
  };

  return (
    <Container>
      <IconContainer>
        <Icon width="2.4rem" height="2.4rem" />
      </IconContainer>
      <Text>
        <MIFormattedText
          label="vendors.upgradePayment.p2dPromotion.message"
          values={{
            link: (
              <Link as="span" isEnabled={isEnabled} onClick={handleDebitCardPromotionClick}>
                <MIFormattedText label="vendors.upgradePayment.p2dPromotion.link" />
              </Link>
            ),
          }}
        />
      </Text>
    </Container>
  );
};
