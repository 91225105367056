import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import { Loader } from '@melio/billpay-design-system';
import companyInfoApi from 'src/app/services/api/organizations';
import organizationsStore from 'src/app/modules/organizations/organizations-store';
import { getOrgId } from 'src/app/redux/user/selectors';
import failedToLoad from 'src/app/images/qbo/failed-to-load-002-icon.png';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { QuickPayment } from 'src/app/modules/payments/quickpay-slice';
import { useForm } from 'src/app/ui/form';
import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import QBRLayoutPage from 'src/app/components/layout/QBRLayoutPage';
import ValidationError from 'src/app/ui/ValidationError';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import MIRadioGroup, {
  OptionsContainer,
  RadioGroupContainer,
} from 'src/app/components/form/MIRadioGroup';
import { getTaxIdTypesOptions } from 'src/app/utils/company';
import analytics from '../../../services/analytics';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const CompleteCompanyInfoPage = ({ onNext, onPrev, onExit }: Props) => {
  const orgId = useSelector(getOrgId);
  const site = useSiteContext();
  const companyInfo: any = useSelector(organizationsStore.selectors.byId(orgId));
  const paymentActions = useStoreActions(paymentsStore);
  const payment: QuickPayment = useSelector(
    paymentsStore.selectors.quickpay.newPaymentWizard.payment
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState('');

  const onSubmit = async (value) => {
    const {
      vendorId,
      currency,
      totalAmount,
      scheduledDate,
      fundingSourceId,
      deliveryMethodId,
      note,
    } = payment;
    const validationErrors = getValidationErrors('companyInfo', value, [
      'contactFirstName',
      'contactLastName',
      'taxId',
      'taxIdType',
    ]);

    if (!isValidationOk(validationErrors)) {
      analytics.track('complete-company-info-page', 'validation-error', validationErrors);
      throw new ValidationError({ validationErrors });
    } else {
      setIsLoading(true);
      try {
        await companyInfoApi.updateCompanyInfo(orgId, {
          contactFirstName: modelValues.contactFirstName.value,
          contactLastName: modelValues.contactLastName.value,
          taxId: modelValues.taxId.value,
          taxIdType: modelValues.taxIdType.value,
        });
        const createOrigin = site.createOrigin.pay.payment;

        await paymentActions.quickpay.create({
          amount: totalAmount,
          orgId,
          vendorId,
          currency,
          scheduledDate,
          fundingSourceId,
          deliveryMethodId,
          note,
          createOrigin,
        });
        setIsLoading(false);
        onNext();
      } catch (e: any) {
        setIsLoading(false);
        setErrorCode(e.code);
      }
    }
  };

  const model = useMemo(
    () => ({
      contactFirstName: companyInfo.contactFirstName,
      contactLastName: companyInfo.contactLastName,
      taxId: companyInfo.taxId,
      taxIdType: companyInfo.taxIdType,
    }),
    [companyInfo]
  );

  const [modelValues, { submit }] = useForm(model, {
    submit: onSubmit,
  });

  if (isLoading) {
    return <Loader />;
  }

  if (errorCode) {
    return (
      <ErrorPage
        illustration={failedToLoad}
        title="bills.pay.errorVendorNotFound.title"
        subtitle={`server.${errorCode}`}
        buttonAction={onExit}
        buttonLabel="bills.pay.errorVendorNotFound.action"
      />
    );
  }

  return (
    <QBRLayoutPage
      title="bills.pay.confirm.completeLegalInfo.title"
      subtitle="bills.pay.confirm.completeLegalInfo.subtitle"
      goExit={onExit}
      onPrev={onPrev}
      onNext={submit}
      nextLabel="bills.pay.confirm.completeLegalInfo.action"
    >
      <CompleteCompanyInfoPageContainer>
        <InlineTextInputField
          label="user.firstName"
          model={modelValues.contactFirstName}
          required
          privateData
        />
        <InlineTextInputField
          label="user.lastName"
          model={modelValues.contactLastName}
          required
          privateData
        />
        <RadioGroup
          id="TaxIdType"
          options={getTaxIdTypesOptions()}
          label="bills.pay.confirm.completeLegalInfo.taxIdType.inputTitle"
          selected={modelValues.taxIdType.value}
          onSelect={(value) => modelValues.taxIdType.onChange({ value })}
          group="TaxIdType"
          errorMessage={modelValues.taxIdType.error}
          required
        />
        <InlineTextInputField
          label="bills.pay.confirm.completeLegalInfo.taxId.inputTitle"
          model={modelValues.taxId}
          required
          privateData
        />
      </CompleteCompanyInfoPageContainer>
    </QBRLayoutPage>
  );
};

const CompleteCompanyInfoPageContainer = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2.4rem 0 2rem;
  }
`;

const RadioGroup = styled(MIRadioGroup)`
  @media ${devices.mobile}, ${devices.phablet} {
    border: 0;
    box-shadow: initial;
    border-radius: 0;

    ${OptionsContainer} > div {
      border: 0;
      margin: 0;
      padding: 0;
      margin-left: 21px;
      &:first-of-type {
        margin-left: 0;
      }
    }

    ${RadioGroupContainer} {
      margin: 0;
    }
  }
  margin: 3.2rem 0 0 -1.6rem;
`;

export default CompleteCompanyInfoPage;
