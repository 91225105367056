import {
  createStandaloneToastFactory,
  ToastId,
  ToastParams,
  UseToastOptions,
} from '@melio/billpay-design-system';
import { SHOWN_TOASTS_LIMIT } from './toasts.consts';

import { v4 as uuidv4 } from 'uuid';

interface QueryToastConfigOptions extends UseToastOptions {
  id: ToastId;
}
export interface QueryToastConfig extends ToastParams {
  options: QueryToastConfigOptions;
  postponed?: boolean;
}

let toastsQuery: QueryToastConfig[] = [];

export const pushToast = (
  toastCreator: ReturnType<typeof createStandaloneToastFactory>,
  toastParams: ToastParams
) => {
  const onCloseComplete = (toastId: ToastId) => {
    toastsQuery = toastsQuery.filter((toastParams) => toastParams.options.id !== toastId);
    const postponedToastConfig = toastsQuery.find((toastParams) => toastParams.postponed);

    if (postponedToastConfig) {
      postponedToastConfig.postponed = undefined;
      toastCreator.show({
        ...postponedToastConfig,
        options: {
          ...postponedToastConfig.options,
          onCloseComplete: () => onCloseComplete(postponedToastConfig.options.id),
        },
      });
    }

    toastParams.options?.onCloseComplete?.();
  };

  const newToastId = toastParams.options?.id || uuidv4();
  const options = { ...toastParams.options, id: newToastId };

  if (toastsQuery.length < SHOWN_TOASTS_LIMIT) {
    toastCreator.show({
      ...toastParams,
      options: { ...options, onCloseComplete: () => onCloseComplete(newToastId) },
    });

    toastsQuery.push({
      ...toastParams,
      options,
    });
  } else {
    toastsQuery.push({
      ...toastParams,
      options,
      postponed: true,
    });
  }

  return newToastId;
};
