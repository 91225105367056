import React, { MutableRefObject } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CardNumberElement as CardNumberElementType } from '@basis-theory/basis-theory-js/types/elements/elements';
import { CardNumberElement } from '@basis-theory/basis-theory-react';
import { FieldInputWrapperContainer } from 'src/app/version-2/pages/add-card-account/components/CardDetails/CardDetails.styles';
import { CardNumberFieldContainer } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/CardNumberField/CardNumberField.styles';
import { FieldErrors } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/common/FieldErrors/FieldErrors';
import { FieldTitle } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/common/FieldTitle/FieldTitle';
import { CardFieldProps } from 'src/app/version-2/pages/add-card-account/model/objects/cardFieldProps';
import { addCardAccountSelectors } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';

export const CardNumberField = ({
  id,
  elementStyle,
  onChange,
  onFocus,
  onBlur,
  onReady,
  cardNumberRef,
  ...rest
}: CardFieldProps & { cardNumberRef: MutableRefObject<CardNumberElementType | undefined> }) => {
  const errorValidations = useSelector(addCardAccountSelectors.selectErrorValidation);
  const shouldShowError = errorValidations[id]?.shouldShowError;
  const hasError = errorValidations[id]?.hasError;

  const { formatMessage } = useIntl();

  return (
    <CardNumberFieldContainer>
      <FieldTitle
        id="cn"
        label={formatMessage({ id: 'linkCard.cardDetails.cardNumber.title' })}
        error={shouldShowError}
      />
      <FieldInputWrapperContainer
        error={shouldShowError && hasError}
        focus={errorValidations[id]?.focus}
      >
        <CardNumberElement
          id={id}
          style={elementStyle}
          {...rest}
          iconPosition="none"
          placeholder={formatMessage({ id: 'linkCard.cardDetails.cardNumber.placeholder' })}
          onFocus={() => onFocus(id)}
          onBlur={() => onBlur(id)}
          onChange={(event) => onChange(id, event)}
          onReady={() => onReady(id)}
          ref={cardNumberRef as MutableRefObject<CardNumberElementType>}
        />
      </FieldInputWrapperContainer>
      {shouldShowError ? (
        <FieldErrors
          testId="card-number-errors"
          fieldErrors={errorValidations[id]?.errors}
          emptyLabel={formatMessage({ id: 'linkCard.cardDetails.cardNumber.emptyMessage' })}
          invalidLabel={formatMessage({ id: 'linkCard.cardDetails.cardNumber.invalidMessage' })}
          empty={errorValidations[id]?.empty}
        />
      ) : null}
    </CardNumberFieldContainer>
  );
};
