import { Button } from '@melio/billpay-design-system';
import { ReactNode } from 'react';

type Props = {
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'destructive'
    | 'secondaryDestructive'
    | 'passivePrimary';
  size?: 'sm' | 'md' | 'lg' | 'xs';
  children?: string | ReactNode;
};

export const ModalButton = ({
  onClick,
  isLoading,
  isDisabled,
  size,
  variant,
  children,
  ...rest
}: Props) => (
  <Button
    size={size}
    variant={variant}
    onClick={onClick}
    isLoading={isLoading}
    disabled={isDisabled}
    {...rest}
  >
    {children}
  </Button>
);
