import React, { useMemo } from 'react';
import successImage from 'src/app/images/general/qbo-check-circle.svg';
import { WizardStepTitle, WizardStepSubTitle } from 'src/app/components/layout/QBOWizardElements';
import analytics from 'src/app/services/analytics';
import { CONSTS, BUTTON_SIZE } from 'src/app/utils/consts';
import MIButton from 'src/app/components/common/MIButton';
import { MIFormattedText } from 'src/app/utils/formatting';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { useHistory, useLocation } from 'react-router-dom';
import locations from 'src/app/utils/locations';
import { useSelector, useDispatch } from 'react-redux';
import { getFundingSources, getOrgId, getUserId } from 'src/app/redux/user/selectors';
import { getPaymentMethodNameParts } from 'src/app/version-2/utils/paymentMethods.utils';
import {
  getIsFeeFundingSourceLoading,
  getOrgFeeFundingSource,
} from 'src/app/redux/organization/selectors';
import { BillingFeeTypeEnum, UpdateOrganizationBillingFeeParams } from 'src/app/utils/types';
import {
  updateOrganizationFeeFundingSource,
  createOrganizationFeeFundingSource,
} from 'src/app/redux/organization/actions';
import { FEE_FLOWS } from 'src/app/redux/payBillWizard/types';
import { getFeeFundingSourceFlow } from 'src/app/redux/payBillWizard/selectors';
import {
  FundingSourceTypesEnum,
  CardTypeEnum,
  FundingSourceOrigins,
} from 'src/app/version-2/model/enums';
import { Centered, DoneIcon, StyledQBOLayoutPage } from './styles';

const eventCategory = 'settings-billing';

const createOrganizationBillingFeeParams = ({ orgId, userId, fundingSourceId }) => ({
  updatedById: userId,
  organizationId: orgId,
  fundingSourceId,
  billingFeeType: BillingFeeTypeEnum.AchToCheck,
});

const updateOrganizationBillingFeeParams = ({
  isActive,
  managedByOrganizationId,
  fundingSourceId,
  id,
}): UpdateOrganizationBillingFeeParams => ({
  isActive,
  managedByOrganizationId,
  fundingSourceId,
  id,
});

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const feeFundingSource = useSelector(getOrgFeeFundingSource);
  const fundingSources = useSelector(getFundingSources);
  const userId = useSelector(getUserId);
  const isFeeFundingLoading = useSelector(getIsFeeFundingSourceLoading);
  const { state } = useLocation<{ selectedFundingSourceId: number }>();
  const { selectedFundingSourceId } = state;
  const flow = useSelector(getFeeFundingSourceFlow);
  const selectedFundingSource = useMemo(
    () => fundingSources.find((fs) => fs.id === selectedFundingSourceId),
    [fundingSources]
  );

  const redirectOnSuccess = () => {
    const url = locations.Settings.billingItems.url({ orgId });

    history.push(url);
  };

  const onClick = async () => {
    if (feeFundingSource) {
      const updateOrgBillingFeeParams = updateOrganizationBillingFeeParams({
        ...(feeFundingSource as any),
        fundingSourceId: selectedFundingSourceId,
      });

      dispatch(updateOrganizationFeeFundingSource(updateOrgBillingFeeParams, redirectOnSuccess));
    } else {
      const createOrgBillingFeeParams = createOrganizationBillingFeeParams({
        orgId,
        userId,
        fundingSourceId: selectedFundingSourceId,
      });

      dispatch(createOrganizationFeeFundingSource(createOrgBillingFeeParams, redirectOnSuccess));
    }
  };

  const { title, description, values, cta } = useMemo(
    () => getCopy(selectedFundingSource as FundingSource, flow),
    [selectedFundingSource]
  );

  return (
    <StyledQBOLayoutPage isCentered>
      <Centered>
        <DoneIcon src={successImage} />
        <WizardStepTitle>
          <MIFormattedText label={title} />
        </WizardStepTitle>
        <WizardStepSubTitle>
          <MIFormattedText label={description} values={values} />
        </WizardStepSubTitle>
        <MIButton
          {...{ onClick, isProcessing: isFeeFundingLoading }}
          size={BUTTON_SIZE.NORMAL}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label={cta}
        />
      </Centered>
    </StyledQBOLayoutPage>
  );
};

enum PAYMENT_TYPE {
  CREDIT_CARD = 'credit card',
  DEBIT_CARD = 'debit card',
  BANK = 'bank account',
}

function getPaymentType(fundingSource: FundingSource): PAYMENT_TYPE {
  if (fundingSource.fundingType === FundingSourceTypesEnum.ACH) return PAYMENT_TYPE.BANK;

  if (fundingSource.cardAccount?.cardType === CardTypeEnum.CREDIT) {
    return PAYMENT_TYPE.CREDIT_CARD;
  }

  return PAYMENT_TYPE.DEBIT_CARD;
}

function getCopy(fundingSource: FundingSource, flow?: string) {
  const BASE_PREFIX = 'settings.billing.feeCard.checks.pages.complete';

  const { identifier } = getPaymentMethodNameParts({ fundingSource });
  const values = { ccNumber: identifier, fsMethod: getPaymentType(fundingSource) };
  const isQbChecking = fundingSource.origin === FundingSourceOrigins.QBCASH;

  if (fundingSource.fundingType === FundingSourceTypesEnum.ACH && !fundingSource.isVerified) {
    if (flow === FEE_FLOWS.ADD) {
      return {
        title: `${BASE_PREFIX}.unverifiedBankFlow.add.title`,
        description: `${BASE_PREFIX}.unverifiedBankFlow.add.info`,
        cta: `${BASE_PREFIX}.unverifiedBankFlow.add.cta`,
        values,
      };
    }

    return {
      title: `${BASE_PREFIX}.unverifiedBankFlow.change.title`,
      description: `${BASE_PREFIX}.unverifiedBankFlow.change.info`,
      cta: `${BASE_PREFIX}.unverifiedBankFlow.change.cta`,
      values,
    };
  }

  if (flow === FEE_FLOWS.CHANGE) {
    return {
      title: `${BASE_PREFIX}.changeFlow.title`,
      description: isQbChecking
        ? `${BASE_PREFIX}.changeFlow.infoQbChecking`
        : `${BASE_PREFIX}.changeFlow.info`,
      cta: `${BASE_PREFIX}.changeFlow.cta`,
      values,
    };
  }

  analytics.page(eventCategory, 'payment-method-added');

  return {
    title: `${BASE_PREFIX}.createFlow.title`,
    description: isQbChecking
      ? `${BASE_PREFIX}.createFlow.infoQbChecking`
      : `${BASE_PREFIX}.createFlow.info`,
    cta: `${BASE_PREFIX}.createFlow.cta`,
    values,
  };
}
