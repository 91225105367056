const qbx = [
  'entry.messages.quicklyPay',
  'entry.messages.payWithACH',
  'entry.messages.extendFloat',
  'entry.messages.scheduleBill',
];

export const CONST = {
  TITLES: {
    qbmIos: 'entry.header',
    qbmAndroid: 'entry.header',
    qbo: 'entry.header',
    qbr: 'qbr.entry.header',
  },
  MESSAGES: {
    qbo: qbx,
    qbmIos: qbx,
    qbmAndroid: qbx,
    qbr: [
      'qbr.entry.messages.reminder',
      'qbr.entry.messages.printAndMail',
      'qbr.entry.messages.changePayments',
    ],
  },
};
