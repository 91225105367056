import React from 'react';
import { useIntl } from 'react-intl';
import { Badge } from '@melio/billpay-design-system';
import { MIFormattedCurrency } from 'src/app/version-2/externals';
import { DeliveryOption } from 'src/app/version-2/model/dtos/deliveryOption';
import { FastFeeDeliveryEnum } from 'src/app/version-2/model/enums';
import { FundingSourceItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  DeliveryOptionPrice,
  Description,
  MenuItemContainer,
  SubTitle,
} from 'src/app/version-2/pages/batch-bulk/renderers/ArrivalDate/ArrivalDate.renderer.styles';
import {
  getDescription,
  getSubtitle,
} from 'src/app/version-2/pages/batch-bulk/renderers/ArrivalDate/components/arrivalDate.utils';

import { parseISO } from 'date-fns';

interface ArrivalDateMenuItemProps {
  deliveryOption: DeliveryOption;
  selectedPaymentMethod?: FundingSourceItem;
  isFast: boolean;
  isOnTimeBadge?: boolean;
}

export const ArrivalDateMenuItem = ({
  deliveryOption,
  selectedPaymentMethod,
  isFast,
  isOnTimeBadge,
}: ArrivalDateMenuItemProps) => {
  const { formatMessage } = useIntl();

  const deliveryDate = parseISO(deliveryOption.deliveryDate.toString());

  const isCheck = deliveryOption.type.includes('check');
  const isFastCheck = deliveryOption.type === FastFeeDeliveryEnum.EXPRESS_CHECK;

  const renderItemBadge = () => {
    if (!isFast) return null;

    let label = 'batchBulkPage.renderers.arrivalDate.fast';

    if (isOnTimeBadge) {
      label = 'batchBulkPage.renderers.arrivalDate.onTime';
    }

    return <Badge variant="primaryOutlined">{formatMessage({ id: label })}</Badge>;
  };

  const renderDeliveryOptionPrice = () => {
    const dollarLabel = isCheck
      ? 'batchBulkPage.renderers.arrivalDate.price.dollarCheck'
      : 'batchBulkPage.renderers.arrivalDate.price.dollar';

    return (
      <DeliveryOptionPrice>
        {deliveryOption.amount
          ? formatMessage(
              { id: dollarLabel },
              {
                fee: <MIFormattedCurrency value={deliveryOption.amount} />,
              }
            )
          : formatMessage({
              id: isCheck
                ? 'batchBulkPage.renderers.arrivalDate.price.defaultCheck'
                : 'batchBulkPage.renderers.arrivalDate.price.default',
            })}
      </DeliveryOptionPrice>
    );
  };

  return (
    <MenuItemContainer>
      <SubTitle>
        {getSubtitle({ selectedPaymentMethod, deliveryOption, deliveryDate, formatMessage })}
        {renderItemBadge()}
      </SubTitle>
      <Description>
        {getDescription({
          selectedPaymentMethod,
          deliveryOption,
          deliveryDate,
          isCheck,
          isFastCheck,
          formatMessage,
        })}
        {renderDeliveryOptionPrice()}
      </Description>
    </MenuItemContainer>
  );
};
