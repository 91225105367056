export const ROUTE_NUMBER_PREFIXES = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '70',
  '71',
  '72',
  '80',
];

export const ROUTING_NUMBER_REGEXP = /^[0-9]{9}$/;
export const ACCOUNT_NUMBER_REGEXP = /^[0-9]{6,17}$/;
