import { VStack, ModalBody as ChakraModalBody, ModalBodyProps } from '@chakra-ui/react';
import { spacingBody, modalBodyStyle, modalBodyContainer } from './Modal.style';

type Props = ModalBodyProps & {
  unsetModalOverflow?: boolean;
};

export const ModalBody = ({ children, ...rest }: Props) => (
  <ChakraModalBody {...modalBodyStyle(rest)} {...rest}>
    <VStack __css={modalBodyContainer} spacing={spacingBody} data-testid="modal-body">
      {children}
    </VStack>
  </ChakraModalBody>
);
