import { createContext, PropsWithChildren, useContext } from 'react';
import { EXPEDITE_OPTIONS } from '../consts';
import { getJWTPayload } from '../../../../helpers/jwt';
import { useGetPaymentExpediteType } from '../hooks/useGetPaymentExpediteType';
export const ExpediteItemContext = createContext<{
  expediteType: null | EXPEDITE_OPTIONS;
  isLoading: boolean;
}>({
  expediteType: null,
  isLoading: true,
});

export const ExpediteItemContextProvider = ({
  token,
  children,
}: PropsWithChildren<{ token: string }>) => {
  const { paymentId } = getJWTPayload(token);

  const { expediteType, isLoading } = useGetPaymentExpediteType({ paymentId, token });

  return (
    <ExpediteItemContext.Provider value={{ expediteType, isLoading }}>
      {children}
    </ExpediteItemContext.Provider>
  );
};

export const useExpediteItemContext = () => useContext(ExpediteItemContext);
