import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Menu, Flex, Box } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';

import { ReactComponent as ArrowDownAcuteIcon } from 'src/app/images/qbo/arrow_down_acute.svg';
import { mainButtonStyle, menuButtonWrapper, menuButton } from '../styles';
import { Action } from '..';

type Props = {
  actions: Action[];
  isDisabled?: boolean;
};

const QBOFooterButton = ({ actions, isDisabled }: Props) => {
  const [mainAction, ...restActions] = actions;

  const hasMenuActions = !isEmpty(restActions);
  const onMainActionClick = () => {
    if (!isDisabled) {
      mainAction.onClick();
    }
  };

  const onMenuButtonClick = (event) => {
    if (isDisabled) {
      event.preventDefault();
    }
  };

  return (
    <Flex h="3.6rem">
      <Box
        __css={mainButtonStyle({ hasMenuActions, isDisabled })}
        onClick={onMainActionClick}
        data-testid="footer-main-action"
      >
        <MIFormattedText label={mainAction.label} values={mainAction.labelValues || {}} />
      </Box>
      {hasMenuActions && (
        <Box __css={menuButtonWrapper({ isDisabled })}>
          <Menu>
            <Menu.MenuButton
              as={Box}
              __css={menuButton}
              data-testid="footer-menu-button"
              onClick={onMenuButtonClick}
            >
              <ArrowDownAcuteIcon />
            </Menu.MenuButton>
            <Menu.MenuList>
              {restActions.map((item) => (
                <Menu.MenuItem
                  key={item.label}
                  onClick={item.onClick}
                  data-testid={`footer-menu-action-${item.label}`}
                >
                  <MIFormattedText label={item.label} values={item.labelValues || {}} />
                </Menu.MenuItem>
              ))}
            </Menu.MenuList>
          </Menu>
        </Box>
      )}
    </Flex>
  );
};

export default QBOFooterButton;
