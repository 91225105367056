import styled from 'styled-components';
import PaymentList from '../../../../QBOViewBillPaymentReview/PaymentList';

export const Container = styled.div`
  padding: 2rem 0 1rem;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[400]};
  ${({ theme }) => theme.textStyles.ds.body2};
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  ${({ theme }) => theme.textStyles.ds.body2};
  font-size: 1.6rem;
`;

export const PaymentListContainer = styled(PaymentList)`
  line-height: 1.6rem;
`;
