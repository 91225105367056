import { consoleLogger } from 'src/app/version-2/api/loggers/console.logger';
import { dataDogLogger } from './dataDog.logger';
import { loggingApi } from './logging.api';

export const initLoggers = () => {
  if (process.env.NX_REACT_APP_ENV === 'production' || process.env.NX_REACT_APP_ENV === 'alpha') {
    loggingApi.addLogger(dataDogLogger);
  } else {
    loggingApi.addLogger(consoleLogger);
  }

  loggingApi.init();
};

export { loggingApi };
