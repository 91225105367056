import React from 'react';
import { RecordOf } from 'immutable';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { DeliveryMethodType } from 'src/app/utils/types';
import { VerifiedStatusEnum } from 'src/app/version-2/model/enums';
import { ActionOption } from 'src/app/components/common/MIActionsMenu';
import { getDeliveryMethodName } from 'src/app/utils/delivery-methods';
import { CONSTS } from 'src/app/utils/consts';
import SettingItemDetails from './SettingItemDetails';

type Props = {
  deliveryMethods: RecordOf<DeliveryMethodType>[];
};

const DeliveryMethodsList = ({ deliveryMethods }: Props) => {
  const isManualBankAccountNotVerified = (deliveryMethod?: RecordOf<DeliveryMethodType>) =>
    deliveryMethod &&
    deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.ACH &&
    deliveryMethod.plaidAccount === null &&
    deliveryMethod.verifiedStatus === VerifiedStatusEnum.NOT_VERIFIED;

  const isBankAccountBlocked = (deliveryMethod?: RecordOf<DeliveryMethodType>) =>
    !!(deliveryMethod && deliveryMethod.bankAccount && deliveryMethod.bankAccount.isBlocked);

  const getDescription = (deliveryMethod: RecordOf<DeliveryMethodType>) => {
    if (isManualBankAccountNotVerified(deliveryMethod)) {
      if (isBankAccountBlocked(deliveryMethod)) {
        return 'settings.receivingMethods.microDepositsBlocked';
      }

      return 'settings.receivingMethods.notVerifiedAccountHintWithAction';
    }

    return 'settings.receivingMethods.achDescription';
  };

  return (
    <DeliveryMethodContainer>
      {!isEmpty(deliveryMethods) &&
        deliveryMethods.map((deliveryMethod) => {
          const deliveryMethodName = getDeliveryMethodName(deliveryMethod);

          const imageObj = {
            logo: deliveryMethod.logo,
          };

          const actionOptions: ActionOption[] = [];

          return (
            <SettingItemDetails
              key={deliveryMethod.id}
              iconClassName="bank"
              imageObj={imageObj}
              descriptionLabel={getDescription(deliveryMethod)}
              descriptionValues={{
                accountName: deliveryMethodName,
                link: '',
              }}
              nameLabel="settings.receivingMethods.ach"
              actionOptions={actionOptions}
              hideAddItemLink
            />
          );
        })}
      {isEmpty(deliveryMethods) && (
        <SettingItemDetails
          iconClassName="bank"
          imageObj={{ logo: '' }}
          nameLabel="settings.receivingMethods.ach"
          descriptionLabel="settings.receivingMethods.hint"
        />
      )}
    </DeliveryMethodContainer>
  );
};

export default DeliveryMethodsList;

const DeliveryMethodContainer = styled.div`
  margin-top: 1.7rem;
`;
