import React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import { withPreservedStateNavigator } from 'src/app/hoc';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { AreaLoader } from '@melio/billpay-design-system';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import useVirtualCardDeliveryMethodState from './hooks/useVirtualCardDeliveryMethodState';
import { ModelViewField } from 'src/app/ui/form';

type Props = {
  locationState: Record<string, any>;
  navigate: (arg0: string) => void;
  vendorId: string;
  navigateWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
};

const VirtualCardDeliveryMethodPageContainer = ({
  navigate,
  vendorId,
  navigateWithPreservedState,
}: Props) => {
  const site = useSiteContext();
  const { virtualCardDeliveryMethodVM, submit, vendor, isFormProcessing, isVendorLoading, goExit } =
    useVirtualCardDeliveryMethodState({
      vendorId,
      navigate,
      navigateWithPreservedState,
    });

  if (isVendorLoading) {
    return <AreaLoader />;
  }

  const vendorCompanyName = vendor?.companyName || vendor?.printName;

  return (
    <site.components.StepLayout
      title="vendors.deliveryMethods.virtualCard.addEmail.title"
      titleValues={{ vendor: vendorCompanyName }}
      subtitle="vendors.deliveryMethods.virtualCard.addEmail.subtitle"
      subTitleValues={{ vendor: vendorCompanyName }}
      goExit={goExit}
      onNext={submit}
      nextLabel="vendors.deliveryMethods.virtualCard.addEmail.done"
      isLoading={isFormProcessing}
      innerSize={50}
    >
      <FormContainer>
        <WizardTextInputField
          label="vendors.deliveryMethods.virtualCard.addEmail.inputTitle"
          model={
            virtualCardDeliveryMethodVM.virtualCardAccount.accountEmail as ModelViewField<string>
          }
          required
          type="email"
          autoFocus
          autocomplete="username email"
          privateData
          hint="vendors.deliveryMethods.virtualCard.addEmail.inputHint"
        />
      </FormContainer>
    </site.components.StepLayout>
  );
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 4rem;
  }
`;

export default compose(withPreservedStateNavigator())(VirtualCardDeliveryMethodPageContainer);
