import React from 'react';
import { Box, Grid as DSGrid, Image } from '@melio/billpay-design-system';

import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import OutsideFooter from 'src/app/components/common/OutsideLayout/OutsideFooter';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import { ReactComponent as AlertIcon } from 'src/app/images/qbo/vc-details/circle-alert.svg';
import { ReactComponent as InfoIcon } from 'src/app/images/qbo/vc-details/circle-info.svg';
import { ReactComponent as CheckIcon } from 'src/app/images/qbo/vc-details/circle-check.svg';

type DefaultProps = { children: React.ReactNode };
type InfoLayoutProps = DefaultProps & React.ComponentProps<typeof OutsideFooter>;

const InfoLayout = ({ children, ...rest }: InfoLayoutProps) => (
  <QBOLayoutPage hideHeader contentWrapperMode="success" innerSize={53}>
    <Image src={qbLogo} __css={logoStyle} />
    <Box __css={cardStyle}>{children}</Box>
    <OutsideFooter {...rest} />
  </QBOLayoutPage>
);

enum IconType {
  Check = 'CHECK',
  Alert = 'ALERT',
  Info = 'INFO',
}

const Icon = ({ type }: { type: IconType }) => {
  let icon;

  if (type === IconType.Check) icon = <CheckIcon width="4rem" height="4rem" />;

  if (type === IconType.Info) icon = <InfoIcon width="4rem" height="4rem" />;

  if (type === IconType.Alert) icon = <AlertIcon width="4rem" height="4rem" />;

  return (
    <Box h="4rem" mb="2rem">
      {icon}
    </Box>
  );
};

const Title = ({ children }: DefaultProps) => <Box __css={titleStyle}>{children}</Box>;

const Subtitle = ({ children }: DefaultProps) => <Box __css={subtitleStyle}>{children}</Box>;

const Separator = () => <Box __css={separatorStyle} />;

const SectionTitle = ({ children }: DefaultProps) => (
  <Box __css={reviewSectionTitleStyle}>{children}</Box>
);

const Grid = ({ children }: DefaultProps) => <DSGrid gridRowGap="2rem">{children}</DSGrid>;

InfoLayout.Icon = Icon;
InfoLayout.IconType = IconType;
InfoLayout.Title = Title;
InfoLayout.Subtitle = Subtitle;
InfoLayout.Separator = Separator;
InfoLayout.SectionTitle = SectionTitle;
InfoLayout.Grid = Grid;

const logoStyle = {
  w: '15rem',
  alignSelf: 'center',
  mt: '4rem',
  mb: '2rem',
};

const cardStyle = {
  display: 'flex',
  p: ['2rem', '4rem'],
  bgColor: 'white',
  flexDirection: 'column',
  border: '1px',
  borderRadius: '0.6rem',
  borderColor: 'gray.200',
  boxSizing: 'border-box',
  textStyle: 'body2',
  color: 'black',
  fontWeight: '400',
};

const titleStyle = {
  fontWeight: '600',
};

const subtitleStyle = {
  pr: ['0', '1.6rem'],
  mt: '0.8rem',
};

const separatorStyle = {
  h: '0.4rem',
  bgColor: 'gray.50',
  m: '4rem 0',
};

const reviewSectionTitleStyle = {
  fontWeight: '400',
  color: 'gray.500',
  textStyle: 'body3',
  textTransform: 'uppercase',
  mb: '2rem',
};

export { InfoLayout };
