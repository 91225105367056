import { loggingApi } from 'src/app/version-2/api/loggers';

import { SYNC_BILL_ERROR_CODE } from './consts';

const handledSyncBillErrorCodes = [
  SYNC_BILL_ERROR_CODE.BILL_ALREADY_PAID,
  SYNC_BILL_ERROR_CODE.BILL_AMOUNT_ZERO,
  SYNC_BILL_ERROR_CODE.BILL_HAS_BEEN_DELETED,
];

export function syncBillRetry(fn, interval = 1000, retriesLeft = 5) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (handledSyncBillErrorCodes.includes(error?.code) || retriesLeft === 1) {
            reject(error);

            loggingApi.error('ApiUtils.syncBillRetry(): sync bill retry error', {
              retriesLeft,
              interval,
            });

            return;
          }

          syncBillRetry(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}
