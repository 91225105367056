import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  AreaLoader,
  FileUploader as FileUploaderDS,
  SmartIcon,
  Text,
} from '@melio/billpay-design-system';
import { FileUploaderTypes } from 'src/app/version-2/components/FileUploader/FileUploader.types';
import { analytics } from 'src/app/version-2/externals';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  ErrorAction,
  FileName,
  FileUploadContainer,
  IconWrapper,
  Label,
  ModifyAttachmentAction,
  ModifyAttachmentActionContainer,
  Title,
  Tooltip,
  UploadArea,
  UploadError,
} from './FileUploader.styles';

export const FileUploader = ({ billId }: FileUploaderTypes) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const invoice = useSelector(batchBulkSelectors.selectInvoiceByBillId(billId));
  const isError = !!invoice?.loadingStatus?.error;
  const isLoading = invoice?.loadingStatus?.isLoading;

  const handleSelectFile = (file: File) => {
    dispatch(batchBulkActions.uploadInvoiceFile({ billId, file }));
  };

  const handleRemoveFile = (billId) => {
    dispatch(batchBulkActions.removeUploadInvoiceFile({ billId }));
  };

  const trackEvent = (event: string) => {
    analytics.track('international-invoice-file-upload', event, {});
  };

  useEffect(() => {
    if (billId) {
      analytics.track('international-invoice-collection', 'InvoiceFieldForPayment-Shown', {
        billId,
        reason: 'international-payment',
        isFromBatchFlow: true,
      });
    }
  }, [billId]);

  const renderActions = () => {
    if (isError || isLoading || !invoice?.invoiceFile) {
      return null;
    }

    return (
      <FileUploadContainer>
        <FileName>{invoice.invoiceFile.fileName}</FileName>
        <ModifyAttachmentActionContainer>
          <ModifyAttachmentAction
            destructive
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveFile(billId);
            }}
          >
            {formatMessage({ id: 'bills.form.attachment.delete' })}
          </ModifyAttachmentAction>
          <ModifyAttachmentAction>
            {formatMessage({ id: 'bills.form.attachment.replace' })}
          </ModifyAttachmentAction>
        </ModifyAttachmentActionContainer>
      </FileUploadContainer>
    );
  };

  const renderTitle = () => {
    if (isError || isLoading || invoice?.invoiceFile) {
      return null;
    }

    return (
      <FileUploadContainer>
        <IconWrapper>
          <SmartIcon type="file-upload" />
        </IconWrapper>
        <Title>{formatMessage({ id: 'international.purposeOfPayment.invoiceUpload.title' })}</Title>
      </FileUploadContainer>
    );
  };

  const renderError = () => {
    if (!isError) {
      return null;
    }

    return (
      <UploadError>
        <Text>{formatMessage({ id: 'international.purposeOfPayment.invoiceUpload.error' })}</Text>
        <ErrorAction>
          {formatMessage({ id: 'international.purposeOfPayment.invoiceUpload.retry' })}
        </ErrorAction>
      </UploadError>
    );
  };

  return (
    <>
      <Label>{formatMessage({ id: 'international.purposeOfPayment.invoiceUpload.label' })}</Label>

      <UploadArea error={isError}>
        <FileUploaderDS
          onSelectFile={handleSelectFile}
          allowedTypes={['image/png', 'image/jpeg', 'application/pdf']}
          changeInputFile={() => trackEvent('file-selected')}
          dragAndDropClick={() => trackEvent('import-from-file-button')}
        >
          {!isError && isLoading && <AreaLoader />}
          {renderActions()}
          {renderTitle()}
          {renderError()}
        </FileUploaderDS>
      </UploadArea>

      <Tooltip>
        {formatMessage({ id: 'international.purposeOfPayment.invoiceUpload.tooltip' })}
      </Tooltip>
    </>
  );
};
