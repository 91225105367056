import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { generatePath, Switch, useHistory } from 'react-router-dom';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { melioClose } from 'src/app/utils/external-events';
import { getOrgId } from 'src/app/redux/user/selectors';
import CheckDeliveryMethodPage from 'src/app/pages/just-pay/common/CheckDeliveryMethodPage';
import justPayLocations from 'src/app/pages/just-pay/justPayLocations';
import { JustPayReviewAndConfirm } from 'src/app/pages/just-pay/create/ReviewAndConfirm/JustPayReviewAndConfirm';
import paymentStore from 'src/app/modules/payments/payment-store';
import { JustPaySuccess } from 'src/app/pages/just-pay/create/Success/JustPaySuccess';
import {
  JustPayAddPaymentInfo,
  JustPaySelectDeliveryMethod,
  JustPayConnectBankAccount,
  JustPayVerifyBankAccount,
  JustPayNoteToVendor,
  JustPaySelectFundingSource,
  JustPayErrorPage,
} from 'src/app/pages/just-pay/create';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import analytics from 'src/app/services/analytics';
import { AchDeliveryMethodDetailsPage } from 'src/app/version-2/pages/ach-delivery-method-details';
import { AddDeliveryMethodOriginEnum } from 'src/app/version-2/model/enums';
import { JustPaySelectDeductionDate } from './operations';
import { JustPayCompleteLegalInfo } from './create/CompleteLegalInfo/JustPayCompleteLegalInfo';
import { JUST_PAY_PAGE_EVENT } from './just-pay-event-mapping';

export const JustPayRouter = () => {
  const [historyPush] = useHistoryWithOrgId();
  const paymentStoreActions = useStoreActions(paymentStore);
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const onNext = (path: string) => () => historyPush({ path });
  const goBack = () => {
    analytics.trackAction('go.back');
    history.goBack();
  };
  const {
    payment: { exitUrl, trackingBillId, vendor: { id: vendorId } = {}, deliveryMethodId },
  } = useJustPayStore();

  const onExit = () => {
    analytics.trackAction('go.exit');
    paymentStoreActions.justPay.justPayWizard.clear();

    if (exitUrl) {
      historyPush({ path: exitUrl });
    } else {
      melioClose();
    }
  };
  const onError = () => historyPush({ path: justPayLocations.errorPage });

  useEffect(() => {
    if (!trackingBillId) {
      const newTrackingBillId = uuidv4();

      paymentStoreActions.justPay.justPayWizard.update({
        trackingBillId: newTrackingBillId,
      });
    }
  }, [paymentStoreActions, trackingBillId]);

  return (
    <Switch>
      <SmartRoute exact path={justPayLocations.create.entry}>
        <JustPayAddPaymentInfo onNext={onNext} onPrev={onExit} onExit={onExit} />
      </SmartRoute>
      <SmartRoute exact path={justPayLocations.create.selectFundingSource}>
        <JustPaySelectFundingSource onNext={onNext} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute exact path={justPayLocations.create.selectDeliveryMethod}>
        <JustPaySelectDeliveryMethod
          onNext={onNext(justPayLocations.operations.selectDeductionDate)}
          onPrev={goBack}
          onExit={onExit}
        />
      </SmartRoute>
      <SmartRoute path={justPayLocations.create.connectBankAccount}>
        <JustPayConnectBankAccount onNext={onNext} onPrev={goBack} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={justPayLocations.create.verifyBankAccount}>
        <JustPayVerifyBankAccount onNext={onNext} />
      </SmartRoute>
      <SmartRoute path={justPayLocations.create.noteToVendor}>
        <JustPayNoteToVendor
          onNext={onNext(justPayLocations.create.reviewAndConfirm)}
          onPrev={goBack}
          onExit={onExit}
        />
      </SmartRoute>
      <SmartRoute path={justPayLocations.operations.selectDeductionDate}>
        <JustPaySelectDeductionDate
          onNext={onNext(justPayLocations.create.noteToVendor)}
          onPrev={goBack}
          onExit={onExit}
          onError={onError}
        />
      </SmartRoute>
      <SmartRoute
        path={justPayLocations.edit.achDeliveryMethodPage}
        component={() => {
          analytics.page(JUST_PAY_PAGE_EVENT, 'add-bank-delivery-method', {
            vendorId,
            billIds: null,
            trackingBillId,
          });

          return (
            <AchDeliveryMethodDetailsPage
              vendorId={(vendorId || '').toString()}
              deliveryMethodId={(deliveryMethodId || '').toString()}
              flowState={{
                redirectUrl: generatePath(justPayLocations.operations.selectDeductionDate, {
                  orgId,
                }),
                exitUrl: generatePath(justPayLocations.create.selectDeliveryMethod, { orgId }),
                origin: AddDeliveryMethodOriginEnum.JUST_PAY,
                analyticsParams: { trackingBillId, billIds: null },
              }}
              onPrev={goBack}
            />
          );
        }}
      ></SmartRoute>
      <SmartRoute path={justPayLocations.edit.checkDeliveryMethodPage}>
        <CheckDeliveryMethodPage
          onNext={onNext(justPayLocations.operations.selectDeductionDate)}
          onPrev={goBack}
          onExit={onExit}
        />
      </SmartRoute>
      <SmartRoute path={justPayLocations.create.reviewAndConfirm}>
        <JustPayReviewAndConfirm
          onNext={onNext}
          onExit={onExit}
          onBack={goBack}
          onError={onError}
        />
      </SmartRoute>
      <SmartRoute path={justPayLocations.create.completeLegalInfo}>
        <JustPayCompleteLegalInfo
          onNext={onNext(justPayLocations.create.success)}
          onExit={onExit}
          onBack={goBack}
          onError={onError}
        />
      </SmartRoute>
      <SmartRoute path={justPayLocations.create.success}>
        <JustPaySuccess />
      </SmartRoute>
      <SmartRoute path={justPayLocations.errorPage}>
        <JustPayErrorPage />
      </SmartRoute>
    </Switch>
  );
};
