import * as React from 'react';
import analytics from '../services/analytics';
import locations from './locations';
import QBOAccountSettingsPage from '../pages/settings/components/QBOAccountSettingsPage';
import AccountSettingsPageContainer from '../pages/settings/AccountSettingsPageContainer';
import PaymentMethodsSettingsPageContainer from '../pages/settings/PaymentMethodsSettingsPageContainer';
import BillingSettingsPageContainer from '../pages/settings/BillingSettingsPageContainer';
import VendorSettingsPageContainer from '../pages/settings/VendorSettingsPageContainer';

import { PAGINATION } from './consts';

// eslint-disable-next-line max-len
const search = `sorting=invoiceDate:desc&start=${PAGINATION.DEFAULT_START}&limit=${PAGINATION.DEFAULT_LIMIT}`;

export const getSettingPagesRoutes = (site, orgId) => [
  {
    path: locations.Settings.paymentMethods.url({ orgId }),
    main: (props) => {
      analytics.page('settings', 'payment-methods');

      return <PaymentMethodsSettingsPageContainer {...props} />;
    },
    title: 'settings.paymentMethods.title',
    description: 'settings.paymentMethods.description',
    exact: true,
  },
  {
    path: locations.Settings.vendors.url({ orgId }),
    main: (props) => {
      analytics.page('settings', 'vendors');

      return <VendorSettingsPageContainer {...props} />;
    },
    title: 'settings.vendors.title',
    exact: false,
  },
  {
    path: locations.Settings.billingItems.url({ orgId }),
    main: (props) => {
      analytics.page('settings', 'billing');

      return <BillingSettingsPageContainer {...props} />;
    },
    search,
    title: 'settings.billing.title',
    description: 'settings.billing.description',
    exact: true,
  },
  {
    path: locations.Settings.account.url({ orgId }),
    main: (props) => {
      analytics.page('settings', 'account');

      return <AccountSettingsPageContainer SettingsPage={QBOAccountSettingsPage} {...props} />;
    },
    title: 'settings.account.title',
    description: 'settings.account.description',
    exact: true,
  },
];
