import React from 'react';
import styled from 'styled-components';
import { AreaLoader } from '@melio/billpay-design-system';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import ConvertedUnilateralPaymentActivity from 'src/app/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import { useEditCheckAddressStatus } from 'src/app/pages/vendor/edit-check-adress/hooks/useEditCheckAddressStatus';
import { BillInfoContainer, BillPaymentBlock, QBLogo, Title, Subtitle } from './shared';
import { OrganizationType, PaymentType } from '../../../utils/types';

type Props = {
  token: string;
};

const CheckSentInfoPage = ({ token }: Props) => {
  const { payment, isPaymentLoading, organization } = useEditCheckAddressStatus({ token }) as {
    payment: PaymentType;
    isPaymentLoading: boolean;
    organization: OrganizationType;
  };

  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={qbLogo} />
      <BillPaymentBlock>
        <BillInfoContainer>
          <InfoIcon />
          <Title data-testid="success-title">
            <MIFormattedText label="vendors.editCheckAddress.checkSent.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.editCheckAddress.checkSent.subtitle"
              values={{
                totalAmount: <MIFormattedCurrency value={bill.totalAmount} />,
                companyName: organization?.companyName,
                companyEmail: (
                  <StyledEmailLink
                    href={`mailto:${organization?.userOrganizations?.[0]?.user?.email}`}
                  >
                    {organization?.userOrganizations?.[0]?.user?.email}
                  </StyledEmailLink>
                ),
                companyPhone: organization?.phone,
                invoiceNumber: bill.invoiceNumber,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
      </BillPaymentBlock>
    </QBOLayoutPage>
  );
};

export default CheckSentInfoPage;

const InfoIcon = styled.i.attrs({ className: 'icon-info-hollow-icon' })`
  display: block;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  color: ${(props) => props.theme.text.color.grey};
`;

const StyledEmailLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.highlight};
  text-decoration: none;
`;
