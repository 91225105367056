import React from 'react';
import styled from 'styled-components';
import Box from 'src/app/core/ds/box';
import { UserSummary } from 'src/app/components/user/UserSummary';
import { ApprovalDetailType } from 'src/app/pages/bill/pay/components/PayBillPaymentActivity/PaymentApprovalLogs/types';
import { MIFormattedDateTime, MIFormattedText } from 'src/app/utils/formatting';
import ReasonLog from './ReasonLog';

type Props = {
  testId?: string;
  actors: ApprovalDetailType;
  title: string;
  reasonLabel?: string;
  underline: boolean;
};

const ApprovalLog = ({ actors, title, underline = false, reasonLabel, testId }: Props) => (
  <ItemWrapper data-testid={testId} underline={underline}>
    <SubTitle>
      <MIFormattedText label={title} />
    </SubTitle>
    {actors.map((actor) => (
      <Box key={actor.user.id}>
        <UserSummary
          user={actor.user}
          userRole={actor.role}
          details={
            <MIFormattedText
              label="bills.form.paymentActivity.logs.actionDate"
              values={{
                date: <MIFormattedDateTime date={actor.actionDate} />,
              }}
            />
          }
        />
        {reasonLabel && <ReasonLog label={reasonLabel}>{actor.reason}</ReasonLog>}
      </Box>
    ))}
  </ItemWrapper>
);

const ItemWrapper = styled.div<{ underline?: boolean }>`
  padding: 0 0 2rem 0;
  margin: 0 0 2rem 0;
  border-bottom: ${(props) => (props.underline ? `0.1rem` : `0`)} solid
    ${(props) => props.theme.colors.white.lightBlue};
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;

export default ApprovalLog;
