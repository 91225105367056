import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCompanyInfo } from 'src/app/redux/user/selectors';
import { CONSTS, DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import {
  MicroDepositActions,
  MicroDepositState,
  RESOLUTIONS,
} from 'src/app/pages/settings/hooks/microDepositsCommon';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import SuccessIllustration from 'src/app/images/qbo/success-check.svg';
import intercomService from 'src/app/services/intercom';
import { FundingSource } from 'src/app/version-2/model/dtos';
import MILink from 'src/app/components/common/MILink';
import QBODialogComponent from 'src/app/components/common/QBOMIDialog';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { useQueryState } from 'src/app/utils/hooks';
import { getOrgFeeFundingSource } from 'src/app/redux/organization/selectors';
import VerifyMicroDepositsDialog from './VerifyMicroDepositsDialog';

type Props = {
  onVerifyFinished: (isSuccess?: boolean) => void;
  onVerifyFailed?: () => void;
  eventPage: string;
  dialogSuccessTitle: string;
  dialogSuccessSubtitle: string;
  fundingSourceId: number;
} & MicroDepositState &
  MicroDepositActions;

const openSupportChat = () => {
  analytics.track('micro-deposits-unable-to-verify', 'support-open-start-chat');
  intercomService.show();
};

function bindEvents(
  onVerifyFinished: (isSuccess?: boolean) => void,
  eventPage: string,
  dismiss: () => void
) {
  return {
    cancel: () => {
      analytics.track(eventPage, 'cancel-dialog');
      dismiss();
      onVerifyFinished();
    },

    dismissFailed: () => {
      analytics.track(eventPage, 'dismiss-failed-dialog');
      dismiss();
      onVerifyFinished();
    },

    dismissSuccess: () => {
      analytics.track(eventPage, 'dismiss-success-dialog');
      dismiss();
      onVerifyFinished(true);
    },
  };
}

type ModalViewEventParamsType = {
  fundingSourceID: number;
  paymentID?: string;
};

const trackModalViewEvent = (
  eventPage: string,
  eventType: string,
  additionalParams: ModalViewEventParamsType
) => {
  const eventActions = {
    'dashboard-verify-manual-account': 'bills-dashboard',
    'settings-verify-manual-account': 'settings-payment-methods',
    'pay-bill-verify-manual-account': 'pay-bill',
  };

  const event = eventActions[eventPage];

  analytics.track(event, eventType, { ...additionalParams });
};

export default function VerifyMicroDeposits(props: Props) {
  const {
    onVerifyFinished,
    onVerifyFailed,
    dismiss,
    eventPage,
    resolution,
    dialogSuccessTitle,
    dialogSuccessSubtitle,
    fundingSourceId,
  } = props;
  const dismissEvents = useMemo(
    () => bindEvents(onVerifyFinished, eventPage, dismiss),
    [onVerifyFinished, dismiss, eventPage]
  );
  const feeFundingSource = useSelector(getOrgFeeFundingSource);
  const fundingSource: FundingSource =
    useSelector(fundingSourcesStore.selectors.byId(fundingSourceId)) || (feeFundingSource as any);
  const [paymentID] = useQueryState('paymentId');
  const companyInfo = useSelector(getCompanyInfo);
  const companyName = companyInfo?.companyName;
  const accountNumberLast4Digits = getAccountNumber4digits(fundingSource?.bankAccount);
  const areDepositsNotSentYet = (fundingSource?.bankAccount as any)?.depositsNotSent;
  const isBankAccountAlreadyVerified = !isManualBankAccountNotVerified(fundingSource);
  const site = useSiteContext();

  const closeModal = () => onVerifyFinished(false);

  if (!fundingSource) {
    trackModalViewEvent(eventPage, 'verify-click-bank-account-deleted', {
      fundingSourceID: fundingSourceId,
      ...(paymentID && { paymentID }),
    });

    return (
      <QBODialog
        type={DIALOG_TYPE.ALERT}
        variant={DIALOG_VARIANTS.SUCCESS}
        title="settings.microDeposits.deletedBankAccountDialog.title"
        subtitle="settings.microDeposits.deletedBankAccountDialog.subtitle"
        cancelButtonText="settings.microDeposits.deletedBankAccountDialog.ok"
        cancelButtonVariant={CONSTS.BUTTON_VARIANT.PRIMARY}
        onCancelAction={closeModal}
        minHeight="36.8rem"
      />
    );
  }

  if (areDepositsNotSentYet) {
    trackModalViewEvent(eventPage, 'verify-click-cant-verify-yet', {
      fundingSourceID: fundingSourceId,
      ...(paymentID && { paymentID }),
    });

    return (
      <QBODialog
        className="bank-verify-modal"
        type={DIALOG_TYPE.ALERT}
        variant={DIALOG_VARIANTS.SUCCESS}
        title="settings.microDeposits.depositsNotSentYetDialog.title"
        subtitle="settings.microDeposits.depositsNotSentYetDialog.subtitle"
        subtitleValues={{ accountNumber: accountNumberLast4Digits, companyName }}
        cancelButtonText="settings.microDeposits.depositsNotSentYetDialog.ok"
        cancelButtonVariant={CONSTS.BUTTON_VARIANT.PRIMARY}
        onCancelAction={closeModal}
        minHeight="36.8rem"
      />
    );
  }

  if (isBankAccountAlreadyVerified) {
    trackModalViewEvent(eventPage, 'verify-click-account-already-verified', {
      fundingSourceID: fundingSourceId,
      ...(paymentID && { paymentID }),
    });

    return (
      <QBODialog
        className="bank-verify-modal"
        type={DIALOG_TYPE.ALERT}
        variant={DIALOG_VARIANTS.SUCCESS}
        title="settings.microDeposits.alreadyVerifiedDialog.title"
        subtitle="settings.microDeposits.alreadyVerifiedDialog.subtitle"
        cancelButtonText="settings.microDeposits.alreadyVerifiedDialog.ok"
        cancelButtonVariant={CONSTS.BUTTON_VARIANT.PRIMARY}
        onCancelAction={closeModal}
        minHeight="36.8rem"
        image={SuccessIllustration}
      />
    );
  }

  switch (resolution) {
    case RESOLUTIONS.SUCCESS:
      return (
        <QBODialog
          type={DIALOG_TYPE.ALERT}
          variant={DIALOG_VARIANTS.SUCCESS}
          title={dialogSuccessTitle}
          subtitle={dialogSuccessSubtitle}
          cancelButtonText="settings.microDeposits.verifyDialogSuccess.ok"
          cancelButtonVariant={CONSTS.BUTTON_VARIANT.PRIMARY}
          onCancelAction={dismissEvents.dismissSuccess}
          minHeight="36.8rem"
          image={SuccessIllustration}
        />
      );
    case RESOLUTIONS.SUPPORT:
      return (
        <QBODialog
          type={DIALOG_TYPE.ALERT}
          variant={DIALOG_VARIANTS.ERROR}
          title="settings.microDeposits.verifyDialogLimitReached.title"
          subtitle="settings.microDeposits.verifyDialogLimitReached.subtitle"
          subtitleValues={{
            supportPhone: (
              <SupportLink href={`tel:${site.config.support.phone}`}>
                {site.config.support.phone}
              </SupportLink>
            ),
            supportEmail: (
              <SupportLink href={`mailto:${site.config.support.email}`}>
                {site.config.support.email}
              </SupportLink>
            ),
            chatWithUs: (
              <ChatLink
                to={openSupportChat}
                label="settings.microDeposits.verifyDialogLimitReached.chatWithUs"
              />
            ),
            br: <br />,
          }}
          cancelButtonText="settings.microDeposits.verifyDialogLimitReached.dismiss"
          onCancelAction={() => {
            dismissEvents.dismissFailed();
            typeof onVerifyFailed === 'function' && onVerifyFailed();
          }}
        />
      );
    default:
      return (
        <VerifyMicroDepositsDialog
          {...props}
          accountNumberLast4Digits={accountNumberLast4Digits}
          companyName={companyName}
          dismiss={dismissEvents.cancel}
        />
      );
  }
}

const QBODialog = styled(QBODialogComponent)`
  .modal-subtitle {
    font-weight: 400;
  }

  &.bank-verify-modal {
    .actions-container {
      > div {
        width: 100%;
      }
    }
  }

  .actions-container {
    justify-content: center;
  }
`;

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;

const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.colors.primary.opaque};
  text-decoration: none;
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.normal};
`;
