import { useState } from 'react';
import { useSelector } from 'react-redux';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { useDidUpdateEffect } from 'src/app/hooks/useDidUpdateEffect';
import useDashboardListParams from './useDashboardListParams';
import { DASHBOARD_FILTER_ITEMS } from '../consts';

enum DashboardTabState {
  UNKNOWN = 'unknown',
  LOADING = 'loading',
  EMPTY = 'empty',
  NO_RESULTS = 'no_results',
  DATA = 'data',
}

const useDashboardTabState = (): [DashboardTabState] => {
  const [tabState, setTabState] = useState(DashboardTabState.UNKNOWN);

  const [listParams] = useDashboardListParams();
  const searchTerm = listParams?.filters?.search ?? '';

  const loadStatus: { loading: boolean; totalCount: number } = useSelector(
    qbDashboardListItemsStore.selectors.list?.status(listParams)
  );
  const markingUnpaid = useSelector(
    qbDashboardListItemsStore.selectors.markAsUnpaidStatus.processing
  );

  useDidUpdateEffect(() => {
    if (markingUnpaid) {
      setTabState(DashboardTabState.LOADING);

      return;
    }

    if (loadStatus) {
      if (loadStatus.loading) {
        setTabState(DashboardTabState.LOADING);

        return;
      }

      if (loadStatus.totalCount > 0) {
        setTabState(DashboardTabState.DATA);

        return;
      }

      const filters = listParams?.filters;
      const hasAppliedFilters = DASHBOARD_FILTER_ITEMS.some((filterName) => filters[filterName]);

      if (searchTerm || hasAppliedFilters) {
        setTabState(DashboardTabState.NO_RESULTS);

        return;
      }

      setTabState(DashboardTabState.EMPTY);
    }
  }, [loadStatus, markingUnpaid]);

  useDidUpdateEffect(() => {
    setTabState(DashboardTabState.UNKNOWN);
  }, [searchTerm]);

  return [tabState];
};

export { useDashboardTabState, DashboardTabState };
