import { useSelector } from 'react-redux';
import errorIllustration from 'src/app/images/icons/errorToken.svg';
import styled from 'styled-components';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import moneyByQuickbooksLogo from 'src/app/images/qbr/money-by-quickbooks-logo.svg';
import canceledImage from 'src/app/images/qbo/failed-to-load-002-icon.png';
import expiredImage from 'src/app/images/qbo/no-payment-created-icon.png';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { UnilateralPaymentProps } from 'src/app/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import paymentStore from 'src/app/modules/payments/payment-store';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { MIFormattedText } from 'src/app/utils/formatting';
import intercomService from 'src/app/services/intercom';
import MILink from 'src/app/components/common/MILink';
import { EmailLink } from 'src/app/components/common/EmailLink';

type Props = UnilateralPaymentProps;

const QBRInvalidAddDeliveryMethodPage = ({ token }: Props) => {
  const site = useSiteContext();
  const { paymentId } = getJWTPayload(token);
  const { errorData } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const { invalidTokenData } = useSelector(deliveryMethodsStore.selectors.validation) as {
    invalidTokenData: any;
  };
  const error = errorData || invalidTokenData;
  let companyName = '';
  let email = '';

  if (error && error.data) {
    ({ companyName, email } = error.data);
  }

  const renderIcon = (error) => {
    switch (error?.code) {
      case 'PMT17':
        return expiredImage;
      case 'PMT18':
        return canceledImage;
      default:
        return errorIllustration;
    }
  };

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <QBLogo src={moneyByQuickbooksLogo} />
      <Content>
        <Illustration src={renderIcon(error)} alt="error" />
        <SubTitle>
          {error && email && companyName ? (
            <MIFormattedText
              label={`server.${error.code}`}
              values={{
                companyName,
                email: <EmailLink email={email} />,
                supportEmail: <EmailLink email={site.config.support.email} />,
              }}
            />
          ) : (
            <>
              <MIFormattedText label="vendors.deliveryMethods.ach.errors.invalidTokenTitle" />
              <MIFormattedText label="vendors.deliveryMethods.ach.errors.supportText" />
              <ChatLink
                to={() => intercomService.show()}
                label="vendors.deliveryMethods.ach.errors.supportLink"
              />
            </>
          )}
        </SubTitle>
      </Content>
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  background: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  flex-direction: column;
  padding: 4rem;
`;
const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.text.color.link};
  font-weight: ${(props) => props.theme.text.weight.regular};
  &:active {
    color: ${(props) => props.theme.text.color.link};
  }
  &:hover {
    color: ${(props) => props.theme.text.color.link};
  }
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 1.5rem;
`;
const Illustration = styled(QBLogo)`
  width: 12rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 2rem;
  text-align: center;
`;

export default QBRInvalidAddDeliveryMethodPage;
