import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';

import MIButton from 'src/app/components/common/MIButton';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'src/app/utils/consts';

type Props = {
  primaryLabel: string;
  secondaryLabel: string;
  onSubmit: (value: any) => void;
  onClose: () => void;
  isLoading: boolean;
  isMobile: boolean;
};

const EditPaymentFormFooterButton = ({
  isMobile,
  children,
}: {
  isMobile: boolean;
  children: React.ReactNode;
}) => <Box width={isMobile ? '14.8rem' : '10rem'}>{children}</Box>;

const EditPaymentFormFooter = ({
  primaryLabel,
  secondaryLabel,
  onSubmit,
  onClose,
  isLoading,
  isMobile,
}: Props) => (
  <Flex justifyContent={isMobile ? 'center' : 'flex-end'} gridGap="1rem">
    <EditPaymentFormFooterButton isMobile={isMobile}>
      <MIButton
        size={isMobile ? BUTTON_SIZE.SMALL : BUTTON_SIZE.NORMAL}
        onClick={onClose}
        label={secondaryLabel}
        disabled={isLoading}
        fullWidth
        variant={BUTTON_VARIANT.SECONDARY}
      />
    </EditPaymentFormFooterButton>
    <EditPaymentFormFooterButton isMobile={isMobile}>
      <MIButton
        size={isMobile ? BUTTON_SIZE.SMALL : BUTTON_SIZE.NORMAL}
        label={primaryLabel}
        onClick={onSubmit}
        isProcessing={isLoading}
        variant={BUTTON_VARIANT.PRIMARY}
        fullWidth
        type="submit"
      />
    </EditPaymentFormFooterButton>
  </Flex>
);

export { EditPaymentFormFooter };
