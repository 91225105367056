import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { Loader } from '@melio/billpay-design-system';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentsStore from 'src/app/modules/payments/payment-store';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from '../locations';

const EditEntryPage = () => {
  const match = useRouteMatch<{ paymentId?: string }>();
  const orgId = useSelector(getOrgId);
  const paymentId = match.params?.paymentId;

  const paymentActions = useStoreActions(paymentsStore);
  const [, historyReplace] = useHistoryWithOrgId();
  const initiateEditPayment = useCallback(
    async (orgId, paymentId) => {
      const { payload: payment } = await paymentActions.quickpay.fetch({
        orgId,
        id: paymentId,
      });

      await paymentActions.quickpay.newPaymentWizard.new({
        id: payment.id,
        vendorId: payment.vendorId,
        totalAmount: payment.amount,
        note: payment.note,
        deliveryMethodId: payment.deliveryMethodId,
        fundingSourceId: payment.fundingSourceId,
        currency: payment.currency,
        scheduledDate: payment.scheduledDate,
        deliveryEta: payment.deliveryEta,
        maxDeliveryEta: payment.maxDeliveryEta,
        status: payment.status,
        billId: payment.billId,
      });
      historyReplace({
        path: generatePath(locations.edit.confirm, { orgId, paymentId }),
      });
    },
    [paymentActions, historyReplace]
  );

  useEffect(() => {
    if (orgId && paymentId) {
      initiateEditPayment(orgId, paymentId);
    }
  }, [orgId, paymentId, initiateEditPayment]);

  return <Loader />;
};

export default EditEntryPage;
