import React, { MutableRefObject } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CardVerificationCodeElement as CardVerificationCodeElementType } from '@basis-theory/basis-theory-js/types/elements/elements';
import { CardVerificationCodeElement } from '@basis-theory/basis-theory-react';
import { FieldInputWrapperContainer } from 'src/app/version-2/pages/add-card-account/components/CardDetails/CardDetails.styles';
import { CardVerificationCodeFieldContainer } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/CardVerificationCodeField/CardVerificationCodeField.styles';
import { FieldErrors } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/common/FieldErrors/FieldErrors';
import { FieldTitle } from 'src/app/version-2/pages/add-card-account/components/CardDetails/fields/common/FieldTitle/FieldTitle';
import { CardFieldProps } from 'src/app/version-2/pages/add-card-account/model/objects/cardFieldProps';
import { addCardAccountSelectors } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';

export const CardVerificationCodeField = ({
  id,
  elementStyle,
  onChange,
  onFocus,
  onBlur,
  onReady,
  cardVerificationRef,
  ...rest
}: CardFieldProps & {
  cardVerificationRef?: MutableRefObject<CardVerificationCodeElementType | undefined>;
}) => {
  const errorValidations = useSelector(addCardAccountSelectors.selectErrorValidation);
  const shouldShowError = errorValidations[id]?.shouldShowError;
  const hasError = errorValidations[id]?.hasError;

  const { formatMessage } = useIntl();

  return (
    <CardVerificationCodeFieldContainer>
      <FieldTitle
        id="cvc"
        label={formatMessage({ id: 'linkCard.cardDetails.CVV.title' })}
        error={shouldShowError}
      />
      <FieldInputWrapperContainer
        error={shouldShowError && hasError}
        focus={errorValidations[id]?.focus}
      >
        <CardVerificationCodeElement
          id={id}
          style={elementStyle}
          {...rest}
          placeholder={formatMessage({ id: 'linkCard.cardDetails.CVV.placeholder' })}
          onFocus={() => onFocus(id)}
          onBlur={() => onBlur(id)}
          onChange={(event) => onChange(id, event)}
          onReady={() => onReady(id)}
          ref={cardVerificationRef as MutableRefObject<CardVerificationCodeElementType>}
        />
      </FieldInputWrapperContainer>
      {shouldShowError ? (
        <FieldErrors
          testId="verification-code-errors"
          fieldErrors={errorValidations[id]?.errors}
          emptyLabel={formatMessage({ id: 'linkCard.cardDetails.CVV.emptyMessage' })}
          invalidLabel={formatMessage({ id: 'linkCard.cardDetails.CVV.invalidMessage' })}
          empty={errorValidations[id]?.empty}
        />
      ) : null}
    </CardVerificationCodeFieldContainer>
  );
};
