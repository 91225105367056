import React from 'react';
import { envApi } from 'src/app/version-2/api/env';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import type { RecordOf } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import once from 'lodash/once';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import indexOf from 'lodash/indexOf';
import { isValidationOk } from '@melio/sizzers-js-common';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { withBreak } from 'src/app/hoc';
import { melioClose, isFirstQBOPage } from 'src/app/utils/external-events';
import { Option, getSelectOptionObject } from 'src/app/components/common/MISingleSelect';
import { withPartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { getBillValidationErrors } from 'src/app/utils/bills';
import { BILL_STATUS } from 'src/app/utils/consts';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import type { BillType, BillLineItemType, VendorType, FieldType } from 'src/app/utils/types';
import type { GlobalState } from 'src/app/redux/types';
import { beginRecurringPayBillFlowAction } from 'src/app/redux/payBillWizard/actions';
import { getOrgId } from 'src/app/redux/user/selectors';
import billsApi from 'src/app/services/api/bills';
import vendorsApi from 'src/app/services/api/vendors';
import analytics from 'src/app/services/analytics';
import organizationsApi from 'src/app/services/api/organizations';
import fileAPI from 'src/app/services/api/files';
import locations from 'src/app/utils/locations';
import { VendorRecord } from 'src/app/pages/vendor/records-constants';
import { BillRecord } from 'src/app/pages/bill/records';
import { recalculateDueDate, strLowerNoSpaces } from 'src/app/pages/bill/utils';
import {
  addNewVendorToGroupedVendors,
  createLocalVendorOption,
  createNetworkVendorOption,
  createNewLocalVendorOption,
  createNewLocalVendorOptionFromName,
  getAccountIdentifierValidationError,
  groupLocalAndDirectoryVendors,
  isVendorRequiresAccountIdentifier,
} from 'src/app/pages/vendor-directory/select-vendor/utils';
import debounce from 'lodash/debounce';
import { getStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import directoryApi from 'src/app/services/api/directory';
import { VendorOptionType } from 'src/app/pages/vendor-directory/select-vendor/types';
import { withVendorDirectoryEnabled } from 'src/app/pages/bill/hoc/withVendorDirectoryEnabled';
import { CURRENCY_USD } from 'src/app/version-2/model/constants';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { BillFrequencyEnum, ContactsTabEnum } from 'src/app/version-2/model/enums';
import { getBillsDefaultFilters, getBillsSearchPath } from 'src/app/utils/billsPath';

type MapStateToProps = {
  orgId: string;
};

type MapDispatchToProps = {
  beginRecurringPayBillFlow: (
    bill: Record<string, any>,
    recurringBill: Record<string, any>
  ) => void;
  checkVendorPaymentPreferences: ({ orgId, id }: { orgId: string; id: string }) => Promise<any>;
};

type Props = {
  locationState?: Record<string, any>;
  site: any;
  device?: {
    isMobile: boolean;
  };
  navigate: (parma1: string, param2?: boolean, param3?: Record<string, any>) => void;
  query: {
    vendorId: string;
    start: number;
    limit: number;
  };
  isPartialPaymentsEnabled: boolean;
  isVendorDirectoryEnabled: boolean;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  bill: RecordOf<BillType>;
  isUploading: boolean;
  vendors: VendorOptionType[];
  filteredVendors: Option[];
  initialVendors: VendorType[];
  intuitAccountsOptions: Option[];
  vendorAccountIdentifier?: string;
  files: number[] | null;
  fileStorageUrl: string | null;
  filePreviewUrls: string[] | null;
  validationErrors: Record<string, any>;
  originalLineItems: BillLineItemType[];
  isUploadCanceled: boolean;
  isUploadError: boolean;
  isAttachmentLoading: boolean;
  isRecurring: boolean;
  frequency: BillFrequencyEnum;
  occurrences: string;
  fileName: string;
  prevBill: RecordOf<BillType> | null;
  isVendorBlockedForPayment: boolean;
};

const eventPage = 'bill-create';

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  beginRecurringPayBillFlow(bill: Record<string, any>, recurringBill: Record<string, any>) {
    dispatch(beginRecurringPayBillFlowAction(bill, recurringBill));
  },
  checkVendorPaymentPreferences({ orgId, id }) {
    const vendorActions = getStoreActions(vendorsStore)(dispatch);

    return vendorActions.checkVendorPaymentPreferences({ orgId, id });
  },
});

export default function withNewBillData() {
  return function (Component: any) {
    return compose(
      withSiteContext(),
      withBreak(),
      withPartialPaymentsEnabled(),
      withVendorDirectoryEnabled(),
      connect(mapStateToProps, mapDispatchToProps)
    )(
      class ComponentWithNewBillData extends React.Component<Props, State> {
        _isMounted = false;

        formTextFieldsAnalytics = {
          totalAmount: null,
          invoiceNumber: null,
          note: null,
        };

        constructor(props: Props) {
          super(props);

          const { locationState } = this.props;

          const defaultVendorId = this.props.query.vendorId
            ? Number(this.props.query.vendorId)
            : undefined;
          const bill =
            locationState && locationState.bill
              ? BillRecord(locationState.bill)
              : BillRecord({
                  vendorId: defaultVendorId,
                });
          const vendors = locationState && locationState.vendors ? locationState.vendors : [];
          const initialVendors =
            locationState && locationState.initialVendors ? locationState.initialVendors : [];
          const files = locationState && locationState.files ? locationState.files : [];
          const fileStorageUrl =
            locationState && locationState.fileStorageUrl ? locationState.fileStorageUrl : '';
          const filePreviewUrls =
            locationState && locationState.filePreviewUrls ? locationState.filePreviewUrls : [];
          const validationErrors =
            locationState && locationState.validationErrors ? locationState.validationErrors : {};
          const occurrences =
            locationState && locationState.recurringBill
              ? locationState.recurringBill.occurrences
              : '';
          const frequency =
            locationState && locationState.recurringBill
              ? locationState.recurringBill.frequency
              : BillFrequencyEnum.ONE_TIME;

          this.state = {
            // all the existing organization vendors
            initialVendors,
            // all vendors in form context (the existing vendors + vendors have been added in the form context)
            vendors,
            // filtered local and directory vendors.
            filteredVendors: groupLocalAndDirectoryVendors(vendors) as any,
            intuitAccountsOptions: [],
            vendorAccountIdentifier: '',
            bill,
            isUploading: false,
            files,
            fileStorageUrl,
            filePreviewUrls,
            validationErrors,
            originalLineItems: [],
            isUploadCanceled: false,
            isUploadError: false,
            isAttachmentLoading: false,
            isRecurring: frequency !== BillFrequencyEnum.ONE_TIME,
            occurrences,
            frequency,
            fileName: '',
            prevBill: null,
            isVendorBlockedForPayment: false,
          };

          // we want the analytics for onChange to fire only once
          forOwn(this.formTextFieldsAnalytics, (val, key) => {
            this.formTextFieldsAnalytics[key] = once(() => {
              analytics.track(eventPage, `${key}-create`);
            });
          });
        }

        componentDidMount() {
          this._isMounted = true;
          const { vendors } = this.state;

          if (isEmpty(vendors)) {
            this.loadVendors();
          }

          this.loadIntuitAccounts();
        }

        componentWillUnmount() {
          this._isMounted = false;
        }

        handleFieldChangeAnalytics = (obj: Record<string, any>, properties) => {
          const { id } = obj;
          const filedMeta = get(obj, 'meta', { action: '' });
          const { action } = filedMeta;
          const DROP_DOWNS = ['vendorId', 'terms'];
          let eventAction = 'select';

          if (action === 'create-option' && indexOf(DROP_DOWNS, id) > -1) {
            eventAction = 'create';
          }

          // we want to track event if 1 char was entered in the text fields (but only one time, not for every char)
          if (this.formTextFieldsAnalytics[id]) {
            this.formTextFieldsAnalytics[id]();
          } else {
            analytics.track(eventPage, `${id}-${eventAction}`, properties);
          }
        };

        onChange = ({ id, value }: FieldType) => {
          this.setState({ [id]: value } as any);

          if (
            value === BillFrequencyEnum.MONTHLY ||
            value === BillFrequencyEnum.WEEKLY ||
            value === BillFrequencyEnum.ONE_TIME
          ) {
            analytics.track('bill-create', 'frequency-changed', {
              frequency: value,
            });
            this.setState({
              isRecurring:
                value === BillFrequencyEnum.MONTHLY || value === BillFrequencyEnum.WEEKLY,
            });
          }
        };

        onVendorsInputChange = async ({ value: inputValue }) => {
          const { orgId } = this.props;
          const result = await directoryApi.search({
            orgId,
            name: inputValue,
          });
          const localVendors = result.vendors.local.map(createLocalVendorOption);
          const directoryVendors = result.vendors.biller.map(createNetworkVendorOption);

          this.setState({
            filteredVendors: groupLocalAndDirectoryVendors(localVendors, directoryVendors),
          } as any);
        };

        debouncedOnVendorsInputChange = debounce(
          (params: { value: string }) => this.onVendorsInputChange(params),
          envApi.getConfig().debounceDelay
        );

        onFieldChange = (obj: Record<string, any>) => {
          // eslint-disable-line react/sort-comp
          const { bill, vendors, filteredVendors } = this.state;
          const valueKey = Object.prototype.hasOwnProperty.call(obj, 'value') ? 'value' : 'date';
          let changedField = obj[valueKey];
          let newVendor: VendorOptionType | null = null;
          const idType = obj.id.includes('.') ? obj.id.split('.') : obj.id;
          let analyticsProperties = {};

          if (idType === 'vendorId') {
            // eslint-disable-next-line no-underscore-dangle
            if (obj.__isNew__) {
              newVendor = createNewLocalVendorOption(obj);
              changedField = newVendor.value;
            }

            analyticsProperties = {
              vendorType: newVendor ? 'new-local' : obj.type,
              billerSuggestedCount: filteredVendors?.[1]?.options.length,
              localVendorSuggestedCount: filteredVendors?.[0]?.options.length,
            };
          }

          const newDueDate = recalculateDueDate(idType, changedField, bill);

          this.handleFieldChangeAnalytics(obj, analyticsProperties);

          const newBill = BillRecord({
            ...bill.toJS(),
            ...this.getNewChangedField(bill, idType, changedField),
            ...(newDueDate ? { dueDate: newDueDate } : {}),
            invoiceDate: new Date(),
          } as any);

          let newState = { bill: newBill };

          if (newVendor) {
            newState = {
              ...newState,
              vendors: [newVendor, ...vendors],
            } as any;

            if (!isEmpty(filteredVendors)) {
              newState = {
                ...newState,
                filteredVendors: addNewVendorToGroupedVendors(filteredVendors as any, newVendor),
              } as any;
            }
          }

          if (idType === 'vendorAccountIdentifier')
            newState = {
              ...newState,
              vendorAccountIdentifier: changedField,
            } as any;

          this.setState(newState);
        };

        onCancelForm = (prevBill, prevFilteredVendors?: Option[] | null) => {
          this.setState(
            prevFilteredVendors
              ? ({
                  bill: prevBill,
                  filteredVendors: prevFilteredVendors,
                } as any)
              : ({ bill: prevBill } as any)
          );
        };

        onLoadBill = (bill: RecordOf<BillType>) => {
          const { initialVendors } = this.state;
          let originalLineItems: BillLineItemType[] = [];
          let files: number[] | null = null;

          if (bill) {
            originalLineItems = [...bill.lineItems];
            const fileId = bill.getFirstFileId();

            if (fileId) {
              files = [+fileId];
            }
          }

          const vendorsOptions = this.generateVendorsOptions(initialVendors, bill);

          this.setState(
            {
              bill,
              vendors: vendorsOptions,
              originalLineItems,
              files,
              fileStorageUrl: '',
              filePreviewUrls: null,
              validationErrors: {},
            },
            () => {
              const { orgId } = this.props;

              if (!isEmpty(files)) {
                this.loadFileImg(orgId, bill.getFirstFileId() as unknown as number);
              }
            }
          );
        };

        onUpdateBill = (bill: RecordOf<BillType>, cb: (isPassedValidation: boolean) => void) => {
          const { orgId } = this.props;

          analytics.track(eventPage, 'edit');
          billsApi
            .editBillById(orgId, bill.id, bill.toJS(), 'all')
            .then(({ object: savedBill }) => {
              const billRecord = BillRecord(savedBill);
              const fileId = billRecord.getFirstFileId();

              analytics.track(eventPage, 'edit-bill-success');

              if (fileId) {
                this.loadFileImg(orgId, +fileId);
              } else {
                this.setState({ bill: billRecord });
                const billsSearchPath = getBillsSearchPath(
                  bill.status,
                  `/bills?id=${billRecord.id}`,
                  [],
                  this.props.query.start,
                  this.props.query.limit
                );

                this.props.navigate(billsSearchPath);
              }

              cb(true);
            })
            .catch(() => {
              analytics.track(eventPage, 'edit-bill-fail');
              cb(false);
            });
        };

        onCreateBill = async (
          bill: Record<string, any>,
          cb: (isPassedValidation: boolean) => void = () => null,
          shouldReturnToBillsDashboard: boolean
        ) => {
          const { orgId, site, checkVendorPaymentPreferences } = this.props;

          try {
            const data = await billsApi.createBill(orgId, {
              ...bill.toJS(),
              balance: bill.totalAmount,
              status: BILL_STATUS.UNPAID,
              currency: CURRENCY_USD,
            });

            analytics.track(data, 'save-success');

            if (eventPage === 'bill-create') {
              analytics.trackMqlEvent('added-bill', 'mql');
            }

            const newBill = BillRecord(data.object);
            const {
              payload: { blockPayments },
            } = await checkVendorPaymentPreferences({
              orgId,
              id: bill?.vendorId,
            });

            this.setState({
              isVendorBlockedForPayment: blockPayments,
              bill: newBill,
            });

            analytics.setTraits({
              last_bill_added_date: new Date().toISOString(),
            });
            cb(true);

            const isRequestAndHasNotDeliveryMethods =
              newBill.isVendorRequest() && isEmpty(newBill.vendor.deliveryMethods);

            if (blockPayments) {
              return;
            }

            if (
              site.redirectToDashboardOnCloseCreateBill &&
              (isRequestAndHasNotDeliveryMethods || shouldReturnToBillsDashboard)
            ) {
              const defaultFilters = getBillsDefaultFilters(BILL_STATUS.UNPAID);
              const redirectPath = generatePath(
                locations.Bills.filteredViewWithoutId.url(defaultFilters),
                { orgId }
              );

              this.props.navigate(redirectPath, true, {
                name: 'initialState',
              });
            } else if (isRequestAndHasNotDeliveryMethods || shouldReturnToBillsDashboard) {
              this.props.navigate(locations.Bills.view.url({ id: newBill.id }));
            } else {
              this.props.navigate(locations.Bills.pay.funding.url({ id: newBill.id }));
            }
          } catch (e) {
            analytics.track(eventPage, 'save-fail');
            cb(false);
          }
        };

        getNewChangedField = (bill: Record<string, any>, id: any, value: any) =>
          Array.isArray(id)
            ? {
                [id[0]]: bill[id[0]].reduce((arr, item, i) => {
                  arr.push({ ...item, ...(i === +id[2] ? { [id[1]]: value } : {}) });

                  return arr;
                }, []),
              }
            : { [id]: id === 'vendorId' ? +value : value };

        onChangeAttachment = (file: File) => {
          const { orgId } = this.props;
          const { bill } = this.state;

          if (bill) {
            this.handleUploadStart();
            fileAPI
              .uploadFile(orgId, file)
              .then((result: Record<string, any>) => this.goWithUploadedBill(result.file, orgId))
              .catch(() => null);
          }
        };

        onDeleteAttachment = () => {
          this.setState({
            files: [],
            fileStorageUrl: '',
            filePreviewUrls: [],
            fileName: '',
          });
        };

        addNewItem = (description: string, amount: number) => {
          const { bill } = this.state;

          analytics.track(eventPage, 'save-line-item');
          this.setState({
            bill: BillRecord({
              ...bill.toJS(),
              lineItems: bill.lineItems.concat({
                description,
                amount,
                currency: CURRENCY_USD,
                id: '',
              }),
            } as any),
          });
        };

        onItemRemove = (index: number) => {
          // eslint-disable-line react/sort-comp
          const { bill } = this.state;

          analytics.track(eventPage, 'remove-line-item');
          this.setState({
            bill: BillRecord({
              ...bill.toJS(),
              lineItems: bill.lineItems.filter((item, i) => i !== index).map((item) => item),
            } as any),
          });
        };

        onSubmitBill = async (
          cb: (isPassedValidation: boolean) => void = () => null,
          shouldReturnToBillsDashboard: boolean
        ) => {
          const {
            bill,
            files,
            isRecurring,
            occurrences,
            frequency,
            vendorAccountIdentifier,
            filteredVendors,
          } = this.state;
          const { orgId, checkVendorPaymentPreferences, isPartialPaymentsEnabled } = this.props;
          const totalAmount = convertCurrencyToNumber(bill.totalAmount);

          let totalBill = BillRecord({
            ...bill.toJS(),
            ...(bill.totalAmount ? { totalAmount, balance: totalAmount } : {}),
            lineItems: bill.lineItems.filter((item) => item.description.length && item.amount),
            files,
            occurrences,
            frequency,
          } as any);
          let validationErrors = getBillValidationErrors(
            totalBill,
            isRecurring,
            isPartialPaymentsEnabled
          );

          const currentOption = getSelectOptionObject(filteredVendors, bill.vendorId);

          if (
            currentOption?.directoryId &&
            isVendorRequiresAccountIdentifier(bill.vendorId, filteredVendors)
          ) {
            const accountIdentifierValidationError = await getAccountIdentifierValidationError(
              orgId,
              currentOption?.directoryId,
              vendorAccountIdentifier
            );

            validationErrors = merge(validationErrors, accountIdentifierValidationError);
          }

          this.setState({ validationErrors }, async () => {
            if (isValidationOk(this.state.validationErrors)) {
              let { vendorId } = bill;

              if (!this.isVendorExists(vendorId)) {
                vendorId = await this.createNewVendor();

                if (vendorId) {
                  totalBill = totalBill.merge({ vendorId });
                }
              }

              const vendor = await vendorsApi.getVendorById({
                orgId,
                id: vendorId,
              });

              if (!isRecurring) {
                if (totalBill.id && (totalBill.id as unknown as number) !== -1) {
                  this.onUpdateBill(totalBill, cb);
                } else {
                  this.onCreateBill(totalBill, cb, shouldReturnToBillsDashboard);
                }
              } else {
                const {
                  payload: { blockPayments },
                } = await checkVendorPaymentPreferences({
                  orgId,
                  id: vendor.object.id,
                });

                this.setState({ isVendorBlockedForPayment: blockPayments });

                if (!blockPayments) {
                  this.props.beginRecurringPayBillFlow(
                    {
                      totalAmount,
                      vendor: vendor ? vendor.object : bill.vendor,
                      vendorId: totalBill.vendorId,
                      id: -1,
                      dueDate: bill.dueDate,
                      note: bill.note ? bill.note : '',
                      invoiceNumber: bill.invoiceNumber,
                      intuitAccountId: bill.intuitAccountId ? bill.intuitAccountId : '',
                    },
                    {
                      frequency,
                      occurrences,
                      dueDate: bill.dueDate,
                    }
                  );
                  analytics.track(`${eventPage}-recurring`, 'save-success');

                  if (eventPage === 'bill-create') {
                    analytics.trackMqlEvent('added-bill', 'mql');
                  }

                  this.props.navigate(locations.Bills.pay.recurring.funding.url());
                }
              }
            } else {
              cb(false);
              analytics.track(eventPage, 'save-validation-error', validationErrors);
            }
          });
        };

        async loadVendors() {
          const { orgId } = this.props;

          return vendorsApi
            .getVendors({ orgId, shouldMelioMeVendorHasDeliveryMethod: true })
            .then(({ objects: initialVendors }) => {
              if (this._isMounted) {
                // eslint-disable-next-line react/no-access-state-in-setstate
                const vendorsOptions = this.generateVendorsOptions(initialVendors, this.state.bill);

                this.setState({
                  initialVendors,
                  vendors: vendorsOptions,
                  filteredVendors: groupLocalAndDirectoryVendors(vendorsOptions),
                } as any);
              }
            })
            .catch(() => {
              if (this._isMounted) {
                this.setState({ vendors: [] });
              }
            });
        }

        async loadIntuitAccounts() {
          const { orgId } = this.props;

          try {
            const result = await organizationsApi.getAccountsForBill(orgId);

            if (result.accounts) {
              const intuitAccountsOptions = result.accounts.map((account) => ({
                value: account.Id,
                label: account,
              }));

              if (this._isMounted) {
                this.setState({ intuitAccountsOptions });
              }
            }
          } catch (e: any) {
            loggingApi.error(
              'withNewBillData.loadIntuitAccounts(): unable to get accounts for bill',
              { error: e, extra: 'getAccountsForBill' }
            );
          }
        }

        handleUploadStart = () => {
          analytics.track(eventPage, 'upload-image');
          this.setState({
            isUploading: true,
            isUploadCanceled: false,
            isUploadError: false,
          });
        };

        handleUploadCancel = () => {
          // FIXME need to cancel requests using axios cancelToken
          analytics.track(eventPage, 'cancel-upload-image');
          this.setState({
            isUploading: false,
            isUploadCanceled: true,
            files: [],
            isUploadError: false,
          });
        };

        handleUploadError = () => {
          analytics.track(eventPage, 'error-upload-image');
          this.setState({
            isUploading: false,
            files: [],
            isUploadError: true,
            fileName: '',
          });
        };

        handleRetry = () => {
          analytics.track(eventPage, 'retry-upload-image');
          this.setState({
            fileName: '',
            files: [],
            isUploading: false,
            isUploadError: false,
          });
        };

        onUploadRemove = () => {
          analytics.track(eventPage, 'remove-image');

          return this.setState({
            files: [],
            fileStorageUrl: '',
            filePreviewUrls: [],
            bill: BillRecord(),
            validationErrors: {},
          });
        };

        isVendorExists = (vendorId: number) => vendorId > 0;

        generateVendorsOptions = (initialVendors: VendorType[], bill: RecordOf<BillType>) => {
          const { vendor } = bill;
          const localVendors = [...initialVendors];

          if (vendor.id && !initialVendors.find((v) => +v.id === +vendor.id)) {
            localVendors.push(vendor as any);
          }

          const vendorsOptions = localVendors.map(createLocalVendorOption);

          return vendorsOptions;
        };

        createNewVendor = async () => {
          const { orgId } = this.props;
          const { bill, filteredVendors, vendors, vendorAccountIdentifier } = this.state;
          const vendorsOptions = isEmpty(filteredVendors) ? vendors : filteredVendors;

          const vendorOption = getSelectOptionObject(vendorsOptions as any, bill.vendorId);

          const newVendor = {
            ...VendorRecord().toJS(),
            ...pickBy({
              companyName: vendorOption?.label || '',
              billerId: vendorOption?.directoryId,
              accountIdentifier: vendorAccountIdentifier,
              ...pick(vendorOption, ['contactName', 'contactEmail', 'contactPhone', 'address']),
            }),
          };

          return vendorsApi
            .createVendor(orgId, newVendor)
            .then(({ object }) => {
              analytics.track(eventPage, 'create-vendor', {
                vendorId: object.id,
                billerId: vendorOption?.directoryId,
              });
              this.setState(({ initialVendors }) => ({
                initialVendors: [...initialVendors, object],
              }));

              return object.id;
            })
            .catch(() => false);
        };

        loadBillDetailsFromFile = (orgId: string, fileId: number) =>
          fileAPI.billDetails(orgId, fileId).then((result) => {
            const { isUploadCanceled } = this.state;

            if (this._isMounted && !isUploadCanceled) {
              if (!result.extract) return false; // we need to think of a message to user about download failure

              const { extract } = result;
              const { bill, isRecurring, vendors } = this.state;
              const dueDate = extract.dueDate ? moment(extract.dueDate).toDate() : bill.dueDate;
              const totalAmount = extract.totalAmount || bill.totalAmount;
              const invoiceNumber = extract.invoiceNumber || bill.invoiceNumber;
              let vendorId: number | null = null;

              // pick the matched name, if not exist take the raw name.
              const vendorName = extract.vendorMatchedName || extract.vendorName;
              let vendor: VendorOptionType | null = null;
              let newVendor: VendorOptionType | null = null;

              if (vendorName) {
                vendor = vendors.find(
                  (option) => strLowerNoSpaces(option.label) === strLowerNoSpaces(vendorName)
                ) as VendorOptionType;

                if (!vendor) {
                  newVendor = createNewLocalVendorOptionFromName(vendorName);
                  vendorId = +newVendor.value;
                } else {
                  vendorId = +vendor.value;
                }
              }

              const newBill = BillRecord({
                ...bill.toJS(),
                vendorId,
                totalAmount,
                invoiceNumber,
                dueDate,
              } as any);

              const newBillForValidation = bill.totalAmount
                ? newBill.merge({
                    totalAmount: convertCurrencyToNumber(bill.totalAmount),
                  })
                : newBill;

              const validationErrors = getBillValidationErrors(newBillForValidation, isRecurring);

              const newVendors = newVendor ? [newVendor, ...vendors] : null;

              return this.setState({
                ...({
                  bill: newBill,
                  validationErrors,
                  files: [fileId],
                  isUploading: false,
                } as any),
                ...(newVendors
                  ? {
                      vendors: newVendors,
                      filteredVendors: groupLocalAndDirectoryVendors(newVendors),
                    }
                  : {}),
              });
            }

            if (isUploadCanceled) {
              this.setState({
                isUploadCanceled: false,
                files: [],
                fileStorageUrl: '',
                filePreviewUrls: [],
              });
            }

            return false;
          });

        async loadFileImg(orgId: string, fileId: number) {
          try {
            this.setState({
              isAttachmentLoading: true,
            });
            const { fileStorageUrl, filePreviewUrls } = await fileAPI.fetchFileUrls(orgId, fileId);

            if (this._isMounted) {
              this.setState({
                filePreviewUrls,
                fileStorageUrl,
                isAttachmentLoading: false,
              });
            }
          } catch (e) {
            if (this._isMounted) {
              this.setState({
                isAttachmentLoading: false,
              });
            }
          }
        }

        goWithUploadedBill = (file: Record<string, any>, orgId: string) => {
          this.loadBillDetailsFromFile(orgId, +file.id).catch(this.handleUploadError);
          this.loadFileImg(orgId, +file.id);
        };

        handleUploadSuccess = (file: Record<string, any>) => {
          analytics.track(eventPage, 'upload-10K-invoice-file-success');
          this.setState({
            isUploading: false,
            isUploadError: false,
            fileName: file.fileName,
            files: [file.id],
          });
        };

        goExit = () => {
          const { locationState } = this.props;
          const { vendorId } = this.props.query;

          analytics.track(eventPage, 'exit');

          if (vendorId) {
            analytics.track(eventPage, 'exit-to-vendor');
            this.props.navigate(
              locations.Vendors.view.url({
                id: vendorId,
                type: ContactsTabEnum.VENDORS,
              })
            );
          } else {
            analytics.track(eventPage, 'exit-to-dashboard');
            this.props.navigate(locations.MainApp.dashboard.url(), false, locationState);
          }
        };

        goExitManually = () => {
          analytics.track(eventPage, 'exit-to-bill-create-options');

          const defaultFilters = getBillsDefaultFilters(BILL_STATUS.SCHEDULED);

          if (isFirstQBOPage()) {
            melioClose();
          }

          this.props.navigate(locations.Bills.filteredViewWithoutId.url(defaultFilters));
        };

        render() {
          const {
            filteredVendors,
            vendors,
            bill,
            isUploading,
            isAttachmentLoading,
            validationErrors,
            fileStorageUrl,
            filePreviewUrls,
            files,
            intuitAccountsOptions,
            vendorAccountIdentifier,
            isUploadError,
            occurrences,
            frequency,
            isRecurring,
            fileName,
            isVendorBlockedForPayment,
          } = this.state;
          const { isVendorDirectoryEnabled } = this.props;

          const vendorProps = isVendorDirectoryEnabled
            ? {
                filteredVendors,
                onVendorsInputChange: this.debouncedOnVendorsInputChange,
                vendorAccountIdentifier,
              }
            : {};

          return (
            <Component
              {...this.props}
              {...vendorProps}
              vendors={vendors}
              onChange={this.onChange}
              occurrences={occurrences}
              frequency={frequency}
              goWithUploadedBill={this.goWithUploadedBill}
              handleUploadStart={this.handleUploadStart}
              handleUploadCancel={this.handleUploadCancel}
              intuitAccountsOptions={intuitAccountsOptions}
              onChangeAttachment={this.onChangeAttachment}
              onDeleteAttachment={this.onDeleteAttachment}
              bill={bill}
              fileStorageUrl={fileStorageUrl}
              filePreviewUrls={filePreviewUrls}
              files={files}
              fileName={fileName}
              onFieldChange={this.onFieldChange}
              onCancelForm={this.onCancelForm}
              isRecurring={isRecurring}
              onLoadBill={this.onLoadBill}
              onUploadRemove={this.onUploadRemove}
              onSubmitBill={this.onSubmitBill}
              onItemRemove={this.onItemRemove}
              addNewItem={this.addNewItem}
              validationErrors={validationErrors}
              isUploading={isUploading}
              isAttachmentLoading={isAttachmentLoading}
              goExit={this.goExit}
              goExitManually={this.goExitManually}
              isUploadError={isUploadError}
              handleUploadError={this.handleUploadError}
              handleUploadSuccess={this.handleUploadSuccess}
              handleRetry={this.handleRetry}
              isVendorBlockedForPayment={isVendorBlockedForPayment}
            />
          );
        }
      }
    );
  };
}
