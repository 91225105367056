import * as React from 'react';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { featureFlags } from '@melio/shared-web';
import { getSiteFromUrl, SiteProvider } from 'src/app/context/SiteContext';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { useSetAuthTokenOnce, useSet2FATokenOnce } from 'src/app/utils/hooks';
import {
  isContextSupportedBy2FAHeaders,
  shouldSetAuthToken,
} from 'src/app/version-2/utils/headers-2fa.utils';
import { setupStore } from '../../redux/store';
import { TranslationProvider } from '../../services/translation-service';
import { initUninitializedRestApi } from 'src/app/version-2/api/rest/rest.utils';

const history = createBrowserHistory();

type Props = {
  url: string;
  children: React.ReactNode;
};

const SiteAwareProviders = withSiteContext()(({ site, children }) => {
  useSetAuthTokenOnce(history, shouldSetAuthToken(site.name));
  useSet2FATokenOnce(history, isContextSupportedBy2FAHeaders(site.name));

  initUninitializedRestApi(site.name, history.location.search);

  const { store, persistor, intl } = setupStore(site, history);

  return (
    <Provider store={store}>
      <featureFlags.FeatureProvider>
        <PersistGate loading={null} persistor={persistor}>
          <TranslationProvider value={intl}>{children}</TranslationProvider>
        </PersistGate>
      </featureFlags.FeatureProvider>
    </Provider>
  );
});

const AppProviders = ({ url, children }: Props) => (
  <SiteProvider site={getSiteFromUrl(url)}>
    <SiteAwareProviders>{children}</SiteAwareProviders>
  </SiteProvider>
);

AppProviders.defaultProps = {};

export default AppProviders;

export { history };
