import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import loaderAnimation from 'src/app/images/melio-logo-loader.gif';
import MIButton from 'src/app/components/common/MIButton';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ButtonVariantType } from 'src/app/utils/types';

type Props = {
  onCancelAction: () => void;
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonVariant: ButtonVariantType;
};

export const FileLoader = ({
  onCancelAction,
  title,
  subtitle,
  buttonLabel,
  buttonVariant,
}: Props) => (
  <LoaderContainer>
    <CoverLoadingImg src={loaderAnimation} />
    <LoadingTitle>
      <MIFormattedText label={title} />
    </LoadingTitle>
    <LoadingSubTitle>
      <MIFormattedText label={subtitle} />
    </LoadingSubTitle>
    <LoadingCancel>
      <MIButton variant={buttonVariant} onClick={onCancelAction} label={buttonLabel} />
    </LoadingCancel>
  </LoaderContainer>
);

const LoaderContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CoverLoadingImg = styled.img`
  margin-bottom: 3.5rem;
  width: 7rem;
  height: 7rem;
  margin-top: 2rem;
`;

const LoadingTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-align: center;
  ${(props) => props.theme.text.fontType.regular};
`;

const LoadingSubTitle = styled(LoadingTitle)`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const LoadingCancel = styled.div`
  margin-top: 3.5rem;
  @media ${devices.mobile}, ${devices.phablet} {
    width: 90%;
    text-align: center;
    justify-content: center;
    display: flex;
  }
`;
