import * as React from 'react';
import { FieldType } from 'src/app/utils/types';
import { UserExistErrorNotificationCard } from 'src/app/components/common/UserExistNotificationCard';
import { MITextInput } from 'src/app/components/common/MITextInput';
import StepLayoutPage from 'src/app/components/layout/StepLayoutPage';

type Props = {
  onPrev: () => void;
  onNext: () => void;
  onChange: (email: string) => void;
  email: string;
  validationErrors: Record<string, any>;
  isLoading: boolean;
  errorCode?: string;
};

class ChangeEmailPage extends React.PureComponent<Props> {
  static defaultProps = {
    errorCode: null,
  };

  onChange = ({ value }: FieldType) => {
    this.props.onChange(value);
  };

  render() {
    const { onPrev, onNext, isLoading, errorCode } = this.props;

    return (
      <StepLayoutPage
        title="onboarding.registration.changeEmail.title"
        subtitle="onboarding.registration.changeEmail.subtitle"
        onPrev={onPrev}
        onNext={onNext}
        nextLabel="onboarding.registration.changeEmail.nextLabel"
        isLoading={isLoading}
      >
        {errorCode && <UserExistErrorNotificationCard errorCode={errorCode} />}
        <MITextInput
          id="emailInput"
          label="onboarding.registration.changeEmail.inputHint"
          value={this.props.email}
          autoFocus
          type="email"
          onChange={this.onChange}
          errorMessage={this.props.validationErrors.email}
          autocomplete="username email"
          required
        />
      </StepLayoutPage>
    );
  }
}

export default ChangeEmailPage;
