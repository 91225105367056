import React, { ReactElement } from 'react';
import { useHasFeatureFlag } from 'src/app/hooks/useHasFeatureFlag';

type UsePartialPaymentsEnabledType = {
  isPartialPaymentsEnabled: boolean;
  isPartialPaymentsUIEnabled: boolean;
};

export const usePartialPaymentsEnabled = (): UsePartialPaymentsEnabledType => {
  const { isFeatureFlagEnabled } = useHasFeatureFlag();

  return {
    isPartialPaymentsEnabled:
      isFeatureFlagEnabled('partialPayments') || isFeatureFlagEnabled('partialPaymentsUI'),
    isPartialPaymentsUIEnabled: isFeatureFlagEnabled('partialPaymentsUI'),
  };
};

export type WithPartialPaymentsEnabledType = UsePartialPaymentsEnabledType;
export function withPartialPaymentsEnabled() {
  return function (Component: React.FC<any>): React.FC<WithPartialPaymentsEnabledType> {
    return function (props: any): ReactElement {
      const { isPartialPaymentsEnabled, isPartialPaymentsUIEnabled } = usePartialPaymentsEnabled();

      return (
        <Component
          {...props}
          isPartialPaymentsEnabled={isPartialPaymentsEnabled}
          isPartialPaymentsUIEnabled={isPartialPaymentsUIEnabled}
        >
          {props.children}
        </Component>
      );
    };
  };
}
