import React, { useEffect } from 'react';
import styled from 'styled-components';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { MIFormattedText } from 'src/app/utils/formatting';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import bannerLogo from 'src/app/images/qbo/switch-to-desktop.svg';
import intuit from 'src/app/utils/intuit';
import { useSelector } from 'react-redux';
import authStore from 'src/app/modules/auth/auth-store';

const LogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 4rem;
`;

const QBLogo = styled.img`
  width: 77%;
`;

const BannerImg = styled.img`
  width: 9.3rem;
  transform: scale(1.1rem);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 91.5%;
  max-height: 354px;
  margin: 5rem auto;

  .qbo-footer {
    position: absolute;
    bottom: 16px;
    width: 100%;
    left: 0;
    padding: 1.6rem 1.6rem 0;
  }
`;

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #d4d7dc;
  border-radius: 0.8rem;
  padding: 5.7rem 3.8rem 4rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #393a3c;
  font-weight: 600;
  margin-top: 3.7rem;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  color: #393a3c;
  font-weight: 400;
  margin: 0 0 2.5rem;
`;

export const PaymentsNotAvailableMessage = () => {
  const authType = useSelector(authStore.selectors.userAuthType);
  const isUnautorized = authType === 'unauthenticated';
  const title = isUnautorized ? 'user.sessionExpired.title' : 'batchPayment.notAvailableTitle';
  const label = isUnautorized ? 'user.sessionExpired.label' : 'batchPayment.notAvailableMessage';

  useEffect(() => {
    intuit.endLoadingWrapper();
  }, []);

  return (
    <Wrapper>
      <LogoWrapper>
        <QBLogo src={qbLogo} />
      </LogoWrapper>
      <Container>
        <BannerImg src={bannerLogo} />
        <Title>
          <MIFormattedText label={title} />
        </Title>
        <Text>
          <MIFormattedText label={label} />
        </Text>
      </Container>
      <QBOFooterContainer />
    </Wrapper>
  );
};
