import React from 'react';
import { MIFormattedCurrency } from 'src/app/utils/formatting';

export const getJustPayHeaderLabelValues = (
  amount: number,
  vendorCompanyName: string
): Record<string, any> => ({
  amount: (
    <strong>
      <MIFormattedCurrency value={amount} />
    </strong>
  ),
  vendorCompanyName: <strong>{vendorCompanyName}</strong>,
});
