import React, { useEffect } from 'react';
import styled from 'styled-components';

import { melioClose } from 'src/app/utils/external-events';
import { devices } from 'src/app/theme/AppDevices';
import InternationalIcon from 'src/app/images/icons/money.svg';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import analytics from 'src/app/services/analytics';
import { BILL_MAX_AMOUNT } from 'src/app/utils/consts';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { useLocationState } from 'src/app/utils/hooks';

type Props = {
  isBatch?: boolean;
};

export const BillAmountLimit = ({ isBatch = false }: Props) => {
  const [exitUrl] = useLocationState('exitUrl');

  useEffect(() => {
    const category = isBatch ? 'pay-bills-batch-payments' : 'pay-bill';

    analytics.page(category, 'amount-exceeded');
  }, []);

  const prefix = isBatch ? 'batchMaxAmount' : 'maxAmount';

  return (
    <StyledBillAmountLimt
      illustration={InternationalIcon}
      title={`bills.errors.${prefix}.title`}
      subtitle={`bills.errors.${prefix}.subTitle`}
      subtitleValues={{
        amount: <MIFormattedCurrency value={BILL_MAX_AMOUNT} />,
      }}
      buttonAction={melioClose}
      buttonLabel={`bills.errors.${prefix}.buttonLabel`}
      exitUrl={exitUrl}
    />
  );
};

const StyledBillAmountLimt = styled(ErrorPage)`
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2rem;
  }
`;
