import { FileUploadResponse } from 'src/app/version-2/api/reponses/FileUploadResponse';
import { postRequest } from 'src/app/version-2/externals';
import { FileUploadRequest } from './requests/FileUploadRequest';

export const filesApi = {
  uploadFile: ({ orgId, file, conf = {} }: FileUploadRequest) => {
    const url = `/orgs/${orgId}/files`;

    const data = new FormData();

    data.append('file', file);

    return postRequest<FileUploadResponse>(url, data, conf);
  },
};
