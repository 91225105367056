import React, { useEffect } from 'react';
import { featureFlags } from '@melio/shared-web';
import { Box, Flex, StackDivider, VStack } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';

import { VendorPaymentTimeline } from './VendorPaymentTimeline';
import { VendorPaymentMailInfo } from './VendorPaymentMailInfo';
import { VendorPaymentSectionProps } from '../types';
import { FORM_TYPES, TIMELINE_CONTENT_TRANSLATION_ROOT_PATH } from '../consts';
import { useGetTimelineStepsFromPayment, triggerAnalyticsEvent } from '../utils';
import { getPaymentCurrentStatus } from '../lib/steps-statuses';

const VendorPaymentContent = ({ payment, vendorPaymentActions }: VendorPaymentSectionProps) => {
  const [isMailEditEnabled] = featureFlags.useFeature('guest-vendor-mail-edit', false);
  const steps = useGetTimelineStepsFromPayment({ payment, vendorPaymentActions });

  useEffect(() => {
    if (payment) {
      const possibleActions = [
        ...(steps || []).map(({ actionAnalyticsName }) => actionAnalyticsName),
        isMailEditEnabled ? FORM_TYPES.EDIT_EMAIL : null,
      ].filter((x) => x);

      triggerAnalyticsEvent({
        payment,
        action: 'page-viewed',
        additionalData: {
          organizationId: payment.organization.id,
          paymentId: payment.id,
          deliveryMethod: payment.deliveryMethod.deliveryType,
          paymentStatus: getPaymentCurrentStatus(payment),
          possibleActions,
        },
      });
    }
  }, [payment]);

  return (
    <Flex direction="column" justifyContent="flex-start" gridGap={5} wordBreak="break-word">
      <Box textStyle="body4" textColor="gray.500" fontWeight="ds.semi">
        <MIFormattedText label={`${TIMELINE_CONTENT_TRANSLATION_ROOT_PATH}.title`} />
      </Box>
      <VStack
        direction="column"
        justify="center"
        align="center"
        bgColor="white"
        divider={<StackDivider borderColor="gray.100" borderStyle="solid" marginBottom={5} />}
        spacing={5}
        width="full"
      >
        {steps && <VendorPaymentTimeline steps={steps} />}
        <VendorPaymentMailInfo
          payment={payment}
          vendorPaymentActions={vendorPaymentActions}
          isMailEditEnabled={isMailEditEnabled}
        />
      </VStack>
    </Flex>
  );
};

export { VendorPaymentContent };
