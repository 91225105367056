// <reference types="googlemaps" />
/* eslint-disable camelcase */
import React, { RefObject, ReactNode } from 'react';
import { List, OrderedSet, RecordOf } from 'immutable';
import * as History from 'history';
import {
  PaymentCollectStatusEnum,
  PaymentDeliveryStatusEnum,
  VendorManagedByEnum,
  RefundReasonEnum,
  KybStatusEnum,
  KybTypeEnum,
  MqlTypeEnum,
  TaxIdEnum,
  BillFrequencyEnum,
  VirtualCardStatusEnum,
  RegistrationFlowEnum,
  RegistrationOriginEnum,
  OrganizationCreateOriginEnum,
  VerifiedStatusEnum,
  TransactionTypesEnum,
  TransactionDestinationEnum,
  TransactionDirectionEnum,
} from 'src/app/version-2/model/enums';
import { DeliverFailureData } from 'src/app/version-2/model/dtos';
import { BANK_ACCOUNT_CHECKING_TYPE } from 'src/app/version-2/model/constants';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { Filters } from '../hoc/withListContainer';
import {
  BUTTONS_DIRECTIONS,
  QBR_BILL_LIST_TAB_TYPE,
  BUTTONS_ROW_POSITION,
  IMAGE_POSITION,
  PAY_EDIT_LOCATION_NAME,
  DELIVERY_TYPE,
  BILL_STATUS,
  PAYMENT_STATUS,
  CARD_NETWORK_TYPES,
  FEE_VARIANTS,
  DELETE_PAYMENT_ACTION,
  WIZARD_PROGRESS_BAR_TYPE,
  COMPANY_FORM_PAGE,
  BILL_PAGE_TYPE,
  COUNTRY,
  VENDOR_TYPES,
  BADGE_SIZE,
  NOTIFICATION_CARD_TYPES,
  PAYMENT_APPROVAL_STATUS,
  CARD_TYPES,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
  BUTTON_SIZE,
} from './consts';
import * as CONSTS from './consts';

export type FieldType = { id: string; value: string };
export type FieldDateType = { id: string; value: Date | null };
export type DateFieldType = { id: string; date: Date | null };
export type UserPreferencesType = {
  isClosedBillsZeroState: boolean | null | undefined;
  isClosedRequestsZeroState: boolean | null | undefined;
  allowCompaniesSwitcher: boolean | null | undefined;
  isQBRUnilateral: boolean | null | undefined;
  qbDashboardSeen: boolean | null | undefined;
  showQbDashboardInternalEntrypoints: boolean | null | undefined;
  hideBatchPaymentsHolidaysNotification: boolean | null | undefined;
  qbDashboardSwitcherManageModalSeen: boolean | null | undefined;
  qbDashboardSwitcherTooltipSeen: boolean | null | undefined;
  isJustPayIntuitEntrypointAware: boolean | null | undefined;
  qbExistingDebitCardFeeModal: boolean | null | undefined;
  qbExistingCheckFeeModal: boolean | null | undefined;
  lastNotificationIdSeen: string | null | undefined;
  checkFeesNotificationCount: string | null | undefined;
  checkFeesPromotionsEndedCount: string | null | undefined;
  batchBulkOnboardingSeen: boolean | null | undefined;
  qbBatchBulkUncombinedSeen: boolean | null | undefined;
  fundingSourceCCTooltipSeen: boolean | null | undefined;
};

export type PaymentFeeItem = {
  feeType: string;
  isFixed: boolean;
  amount: number;
  percent: number | null;
  cap: number | null;
  feeCatalog?: organizationFee;
};

export type DeliveryOptionType = PaymentFeeItem & {
  showPercent: boolean;
  id?: number;
  deliveryDate: Date;
  scheduledDate: Date;
  minScheduledDate: Date;
  maxDeliveryDate: Date;
  type: string;
  fee: PaymentFeeItem[];
};

export type UserContextType = {
  email: string;
  isEmailVerified: boolean;
  id: number | null | undefined;
  isGuest: boolean;
  name: string | null | undefined;
  orgId: string;
  orgName: string | null | undefined;
  userPreferences?: UserPreferencesType | null | undefined;
  organizations: any[];
  registrationOrigin: RegistrationOriginEnum;
  registrationFlow: RegistrationFlowEnum;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  dateOfBirth?: string;
};
export type UserSummaryType = {
  email?: string;
  id?: number | null;
  firstName: string | null;
  lastName: string | null;
};

export const enum RoleType {
  OWNER = 'owner',
  ADMIN = 'admin',
  CONTRIBUTOR = 'contributor',
  ACCOUNTANT = 'accountant',
}

export type RoleLiteral = 'owner' | 'admin' | 'contributor' | 'accountant';

export type AccessLevelLiteral = 'full' | 'none' | 'blocked';

export type UserOrganization = Partial<{
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  userId: number;
  organizationId: number;
  role: RoleLiteral;
  approvalAmountThreshold: number | null;
  requireApproval: boolean;
  accessLevel: AccessLevelLiteral;
  user: {
    email?: string;
  };
}>;

export type CountryCodeType = COUNTRY.US | COUNTRY.PR | COUNTRY.VI | COUNTRY.GU | COUNTRY.MP;
export type AddressType = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  countryCode?: CountryCodeType | null | string;
  googlePlaceId?: string | null;
  aptNumber?: string | null;
  addressLat?: number;
  addressLng?: number;
  formattedAddress?: string | null;
};

export type FormAddressType = AddressType & LegalAddressType;

export type LegalAddressType = {
  legalAddressLine1?: string | null;
  legalAddressLine2?: string | null;
  legalCity?: string | null;
  legalState?: string | null;
  legalZipCode?: string | null;
  legalGooglePlaceId?: string | null;
  legalAptNumber?: string | null;
  legalAddressLat?: number;
  legalAddressLng?: number;
  legalFormattedAddress?: string | null;
  legalCountryCode?: CountryCodeType | null | string;
};
export type CheckType = {
  printName: string;
  isAddressSuggestionIgnored?: boolean | null;
  deliveryMethodId?: string;
} & AddressType;
export type CardAccountType = {
  printName: string;
  cardType: string;
  card4digits: string;
  network: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
} & AddressType;
export type LatLng = {
  lat?: number;
  lng?: number;
  toJSON?: () => LatLng;
};
export type LocationType = {
  location: LatLng;
};
export type GooglePlaceAddressComponentType = {
  long_name?: string | null | undefined;
  short_name?: string | null | undefined;
  types: string[];
};
export type GoogleCombinedAddressType = {
  addressComponents: GooglePlaceAddressComponentType[];
  formattedAddress: string | null | undefined;
  geometry: LatLng;
  placeId: string | null | undefined;
  aptNumber: string | null | undefined;
  name?: string | null;
};
export type GooglePlaceType = google.maps.places.PlaceResult;

export type BankType = {
  accountType: typeof BANK_ACCOUNT_CHECKING_TYPE;
  routingNumber?: string;
  accountNumber?: string;
  accountNumber4digits?: string;
  intuitAccountId?: string;
  isBlocked?: boolean;
  canVerify?: boolean;
};
export type PlaidType = {
  plaidItemId: number;
  accountId: string;
  plaidItem?: {
    institutionName: string;
  };
};

export type OptionalDeliveryMethodsType = CONSTS.DELIVERY_TYPE;

export type EditableDeliveryMethodType = {
  deliveryType: OptionalDeliveryMethodsType;
  isVerified?: boolean;
  paperCheck?: CheckType | null | undefined;
  bankAccount?: BankType | null | undefined;
  plaidAccount?: PlaidType | null | undefined;
  cardAccount?: CardAccountType | null | undefined;
  virtualAccount?: VirtualAccountType | null | undefined;
  virtualCardAccount?: VirtualCardAccountType | AddNewVirtualCardAccountType | null | undefined;
  internationalAccount?: InternationalAccountType | null | undefined;
  verifiedStatus?: VerifiedStatusEnum;
  isFilledByVendor?: boolean;
  cardAccountId?: number | null;
  managedBy?: VendorManagedByEnum;
};

export type CardTypes = CARD_TYPES.CREDIT | CARD_TYPES.DEBIT;
export type CardNetworkTypes =
  | CARD_NETWORK_TYPES.VISA
  | CARD_NETWORK_TYPES.MASTERCARD
  | CARD_NETWORK_TYPES.AMEX
  | CARD_NETWORK_TYPES.DINERS;

export type VirtualAccountType = {
  id?: string;
  email: string;
  phone?: string;
};

export type VirtualCardAccountType = {
  id: string;
  accountEmail: string;
};

export type AddNewVirtualCardAccountType = {
  accountEmail: string;
};

export type RppsAccountType = {
  accountNumber: string;
  billerId: string;
};

export type InternationalAccountType = {
  payeeBankBicSwift: string;
  payeeBankIban: string;
  payeeCountry: string;
  payeeAccount: {
    bicSwift: string;
    payeeBankCountry?: string;
  };
  payeeBankCountry: string;
};

export type DeliveryMethodType = {
  id: string;
  logo: string;
  hasScheduledPayments: boolean;
  isFilledByVendor: boolean;
  createdById?: string;
  updatedAt?: Date | null;
  deletedAt?: Date | null;
  createdAt?: Date | null;
  getDisplayName: (vendorName?: string) => React.ReactNode;
  getDeliveryInfo: (vendorName?: string) => React.ReactNode;
  getDeliveryTypeDescription: () => string;
  intuitAccountId: string | null;
  rppsAccount?: RppsAccountType;
  vendorId?: string;
  virtualCardAccount?: { accountEmail: string } | null;
} & EditableDeliveryMethodType;

export type BillingSettingFeeType = {
  value: number;
  type: 'fixed' | 'percent';
};
export type BillingSettingsType = {
  fee: {
    ach: BillingSettingFeeType;
    credit: BillingSettingFeeType;
    card: BillingSettingFeeType;
  };
};

export const enum CompanyType {
  SMB = 'smb',
  ACCOUNTING_FIRM = 'accounting-firm',
}

export type CompanyTypeLiteral = 'accounting-firm' | 'smb';

export type CompanyInfoType = {
  id: number;
  // TODO: add companyName can be null (or find the root cause).
  companyName: string;
  legalCompanyName: string;
  taxId?: string;
  taxIdType?: TaxIdEnum;
  phone: string;
  contactFirstName: string;
  contactLastName: string;
  yearOfIncorporation: number | null | undefined;
  accountingSoftware: string;
  businessType: string;
  intuitLastFullUpdate: Date | null;
  logoId: number | null | undefined;
  logoUrl: string | undefined;
  canDoPayments: boolean;
  canPayWithFastCheck: boolean;
  kybStatus: KybStatusEnum;
  kyb: KybTypeEnum;
  mql: MqlTypeEnum;
  ownedVendorId: number | null | undefined;
  ownedVendorHandle: string | null | undefined;
  billingSetting: BillingSettingsType;
  onboardingIndustryId: number | null | undefined;
  onboardingSubIndustryId: number | null | undefined;
  companyType?: CompanyType;
  createOrigin?: OrganizationCreateOriginEnum;
} & AddressType &
  LegalAddressType;

export type UserType = {
  name: string;
  email: string;
  phone: string;
};

export type ContactType = {
  id: string | undefined | null;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
};

export type EditableVendorType = {
  contactName: string;
  contactEmail: string;
  contactPhone: string;
};

export type VendorType = {
  id: number;
  type: VENDOR_TYPES;
  companyName: string;
  accountIdentifier?: string;
  address?: AddressType;
  paymentRequestId?: string;
  deliveryMethods: DeliveryMethodType[];
  createdAt: Date | null | undefined;
  createdById: string;
  updatedById: string;
  managedBy: VendorManagedByEnum | null;
  origin: string;
  originId: string;
  ownedById: number | null | undefined;
  handle: string | null | undefined;
  hasDeliveryMethods: boolean;
  isEditable: boolean;
  isOwned: boolean;
  vendorTrusted: boolean;
  getDeliveryMethodByType: (type: OptionalDeliveryMethodsType) => RecordOf<DeliveryMethodType>;
  getDeliveryMethodById: (id: string) => RecordOf<DeliveryMethodType>;
  printName?: string;
  printOnCheck?: string;
} & EditableVendorType;

export type BillLineItemType = {
  id: string;
  description: string;
  amount: number;
  currency: string;
};
export type EditableBillType = {
  totalAmount: number;
  invoiceNumber: string;
  // invoiceDate: Date,
  // terms: number,
  dueDate?: Date | null;
  note: string;
  vendorId: number | string | null;
  intuitAccountId: string | null | undefined;
  lineItems?: List<RecordOf<BillLineItemType>>;
};
export type EditablePaymentType = {
  id: string;
};
export type NewBillType = {
  totalAmount?: number | null | undefined;
  invoiceNumber?: string;
  // invoiceDate?: Date,
  // terms?: number,
  dueDate?: Date;
  note?: string | null | undefined;
  vendorId?: number | null | undefined;
  lineItems?: List<RecordOf<BillLineItemType>>;
};

export type FeeVariants = FEE_VARIANTS.ACH_TO_CHECK;

export type TransactionType = {
  id: number;
  createdAt: any;
  updatedAt: any;
  transactionType: TransactionTypesEnum | null | undefined;
  amount: number | null | undefined;
  currency: number | null | undefined;
  destination: TransactionDestinationEnum | null | undefined;
  transactionDirection: TransactionDirectionEnum | null | undefined;
  sourceLink: string | null | undefined;
  status: string | null | undefined;
  rawData:
    | {
        [key: string]: any;
      }
    | null
    | undefined;
  source: string | null | undefined;
  checkSerialNumber: string | null;
  organizationId: number | null | undefined;
  vendorId: number | null | undefined;
};
export type PaymentApprovalActionType = {
  id: string;
  source: 'manual' | 'system';
  result: 'approved' | 'declined' | 'pending';
  reasons: string | null | undefined;
  paymentId: number;
  riskDecisionCodeId: number;
  createdAt: Date;
  updatedAt: Date;
};
export type CheckTrackDeliveryType = {
  trackingUrl: string;
};
export type VirtualCardType = {
  id: number;
  paymentId: number;
  accountNumber4digits: number;
  createdAt: Date;
  cardNumber: string;
  cvv: string;
  expirationDate: Date;
  endDate: Date;
  clearedDate?: Date;
  canceledDate?: Date;
  status: VirtualCardStatusEnum;
  shiftVirtualCardToACHPaymentCredentials: {
    createdAt: string | null;
    deletedAt: string | null;
    id: number | null;
    isVCDetailsWasShown: boolean | null;
    paymentId: number | null;
    updatedAt: string | null;
  };
  postedDate?: Date;
  authorizationDate?: Date;
};
export type PaymentApprovalDecision = {
  status: string;
  createdAt: string | Date;
  userId: number | string;
  reason?: string;
};
export type VoidCheckType = {
  id: number;
  paymentId: number;
  reason: string;
};
export type PaymentAuditLog = {
  event: string;
  timestamp: string;
  actor: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    role: CONSTS.ROLE;
  };
};
export const enum RefundRiskDecision {
  RiskyAmount = 'PaymentAmountIsRisky',
  NotEnoughCompletedPayments = 'NotEnoughCompletedPayments',
  TooManyActiveRefunds = 'TooManyActiveRefunds',
}
export type PaymentMetadataType = {
  createdAt?: string;
  isPaymentRefundable?: boolean;
  isVoidAndRetryEnabled?: boolean;
  isSelfServeRefundEligible?: boolean;
  refundReason?: RefundReasonEnum;
  notEligibleByRiskReason?: RefundRiskDecision;
  paymentType: string;
  achDeposited?: boolean;
  achTraceNumber?: string;
  checkDeposited?: boolean;
  canUserContactSupport?: boolean;
  collected?: string;
  canUserRetry?: boolean;
  failureMessage?: string;
  failedType?: string;
  isRetryByVirtual?: boolean;
  isDeletable?: boolean;
  voidedCheckData?: {
    date: string;
    checkSerialNumber: string | null;
  };
  paymentStatusDates?: PaymentStatusDates;
  audit: PaymentAuditLog[];
};

export type PaymentStatusDates = {
  collectDate?: string;
  inTransitDate?: string;
  depositedDate?: string;
  refundInitiated?: string;
  refundSent?: string;
  refundCompleted?: string;
  refundFailureDate?: string;
};

export type PaymentType = {
  deliveryPreference?: string | null;
  originDeliveryPreference?: string;
  id: string;
  paymentApprovalDecisions: PaymentApprovalDecision[];
  organizationId: string;
  organization: OrganizationType;
  status: PAYMENT_STATUS;
  amount?: number | null;
  balance?: number | null;
  billId?: string | null;
  bill?: {
    id: string;
    invoiceNumber: string;
  };
  billPayments?: PaymentType[];
  bills?: BillType[];
  deliverFailureData?: DeliverFailureData;
  vendorId: string;
  vendor: VendorType;
  fundingSourceId: number;
  fundingSource: FundingSource;
  deliveryMethodId: string;
  deliveryCompleteDate: string;
  deliveryMethod: DeliveryMethodType;
  collectStatus: PaymentCollectStatusEnum;
  currency: string;
  scheduledDate: any;
  deliveryEta: any;
  originId: string;
  maxDeliveryEta: any;
  paidDate: string | null | undefined;
  actualStartDate?: string | null | undefined;
  payBillFlowUUID?: string | null;
  deliverStatus: PaymentDeliveryStatusEnum;
  note?: string;
  manual: boolean;
  createdAt: any;
  updatedAt: string;
  createdById: number;
  createdBy?: UserSummaryType;
  canUserRetry?: boolean;
  transactions: TransactionType[];
  createOrigin: string;
  approvalDecisionById: number;
  approvalDecisionDate: any;
  approvalDecisionReason: string;
  approvalDecisionStatus: string;
  riskStatus: string;
  intuitAccountId?: string;
  paymentApprovalActions: PaymentApprovalActionType[];
  metadata?: PaymentMetadataType;
  checkTracks: CheckTrackDeliveryType[];
  virtualCards?: VirtualCardType[];
  lastCreatedVirtualCard?: VirtualCardType;
  isEligibleToVirtualCard?: boolean;
  purpose: string;
  isCheckToP2DOfferExpired?: boolean;
  checkToP2DExpirationDate?: Date;
  originalDeliveryMethodId?: string;
  originalDeliveryMethod?: DeliveryMethodType;
  eligibleForPushToDebit: boolean;
  voidChecks?: VoidCheckType[];
  isVCDetailsWasShown?: boolean;
  financingAccount?: Partial<FinancingAccount>;
};
export type OrganizationType = Partial<{
  id: number;
  companyName: string | null;
  logoId: number | null;
  phone: string | null;
  userOrganizations: UserOrganization[];
}>;
export type BillStatusType =
  | BILL_STATUS.UNPAID
  | BILL_STATUS.PAID
  | BILL_STATUS.PARTIALLY_PAID
  | BILL_STATUS.SCHEDULED;
export type PaymentStatusType =
  | PAYMENT_APPROVAL_STATUS.PENDING
  | PAYMENT_APPROVAL_STATUS.DECLINED
  | PAYMENT_STATUS.SCHEDULED
  | PAYMENT_STATUS.COMPLETED
  | PAYMENT_STATUS.FAILED
  | PAYMENT_STATUS.IN_PROGRESS;
export type FileType = {
  id: string;
  mimeType: string;
};
export type ModalDialogTypes = DIALOG_TYPE.CONFIRM | DIALOG_TYPE.ALERT;
export type ModalDialogVariants =
  | DIALOG_VARIANTS.SUCCESS
  | DIALOG_VARIANTS.ERROR
  | DIALOG_VARIANTS.WARNING;

export type RecurringBillType = {
  id?: string;
  occurrences?: number;
  frequency?: BillFrequencyEnum;
  dueDate?: string;
};

export type BillType = {
  id: string;
  createdAt: string;
  balance: number | null | undefined;
  totalAmount: any;
  currency: string;
  organization: {
    id: string;
    companyName: string;
  };
  createdById: number;
  metadata?: {
    isDeletable: boolean;
  };
  origin: string;
  originId: string;
  organizationId: string;
  status: BillStatusType;
  vendorId: number;
  lineItems: BillLineItemType[];
  payments: PaymentType[];
  dueDate: string;
  originMetaUrl: string;
  lastUpdater: string;
  invoiceDate: Date;
  terms: number;
  creator: string;
  invoiceNumber: string;
  note: string;
  intuitAccountId: string;
  internalBill: boolean;
  goodsReceived: boolean;
  vendor: VendorType;
  files: FileType[];
  getDeliveryMethodByType: (type: OptionalDeliveryMethodsType) => RecordOf<DeliveryMethodType>;
  getDeliveryMethodById: (id: string) => RecordOf<DeliveryMethodType>;
  getFirstFileId(): string | null | undefined;
  isVendorRequest(): boolean;
  occurrences: number;
  frequency: BillFrequencyEnum;
  recurringBill?: RecurringBillType | null;
  recurringBillId: string;
  recurringBillIndex: number;
  classificationId?: string;
  partner?: string;
  contractor?: string;
  externallyPaid?: number;
  qboSyncVersion: number;
};
export type ReactRouterNavTo = {
  pathname?: string;
  search?: string | null | undefined;
  state?: Record<string, any> | null | undefined;
};

export type NavigationCallbacks = {
  goExit?: (any?) => void;
  onPrev: (any?) => void;
  onNext?: (any?) => void;
};

export type TrackEventType = {
  page: string;
  name: string;
  properties?: Record<string, any>;
};
export type TabSettingsType = {
  to: ToNavigationType;
  label: string;
  active?: boolean;
  trackEvent: TrackEventType;
  params?: Record<string, any>;
  unreadBadge?: boolean;
};

export type ModalDialogSettings = {
  image?: string;
  imagePosition?: IMAGE_POSITION;
  type: ModalDialogTypes;
  variant: ModalDialogVariants;
  title: string;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subtitleValues?: Record<string, any>;
  okButtonText?: string;
  cancelButtonText?: string;
  onOkAction?: () => any;
  onOkDisabled?: boolean;
  onCancelAction?: () => any;
  onCloseAction?: () => any;
  hideIcon?: boolean;
  showCancel?: boolean;
  maxWidth?: string;
  minHeight?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  buttonsRowPosition?: BUTTONS_ROW_POSITION;
  buttonsDirection?: BUTTONS_DIRECTIONS;
  buttonsSize?: BUTTON_SIZE;
  cancelButtonVariant?: CONSTS.BUTTON_VARIANT;
  className?: string;
  showConfirmationText?: boolean;
  disclaimerText?: string;
};

export type BillCreateOptionsType = {
  id: string;
  icon?: string;
  imgSrc?: string;
  label: string;
  description: string;
  hideOption?: boolean;
  click?: () => void;
};

export type BillPageType = BILL_PAGE_TYPE.EDIT | BILL_PAGE_TYPE.CREATE;
export type CompanyFormPageType = COMPANY_FORM_PAGE.SETTINGS | COMPANY_FORM_PAGE.ONBOARDING;
export type WizardProgressBarType =
  | WIZARD_PROGRESS_BAR_TYPE.WITHOUT_SUBSTEPS
  | WIZARD_PROGRESS_BAR_TYPE.WITH_SUBSTEPS;
export type ButtonVariantType =
  | CONSTS.BUTTON_VARIANT.PRIMARY
  | CONSTS.BUTTON_VARIANT.PAY
  | CONSTS.BUTTON_VARIANT.SECONDARY
  | CONSTS.BUTTON_VARIANT.TERTIARY
  | CONSTS.BUTTON_VARIANT.DESTRUCTIVE
  | CONSTS.BUTTON_VARIANT.CANCEL
  | CONSTS.BUTTON_VARIANT.REMOVE
  | CONSTS.BUTTON_VARIANT.EMPTY
  | CONSTS.BUTTON_VARIANT.EMPTY_FILLED_IN
  | CONSTS.BUTTON_VARIANT.NAKED;
export type ButtonSizeType =
  | BUTTON_SIZE.NORMAL
  | BUTTON_SIZE.SMALL
  | BUTTON_SIZE.TINY
  | BUTTON_SIZE.VERY_SMALL;

export type ListSearchBarContextType = {
  contextSearchInputValue: string;
  contextSearchFilterValue: string;
  setContextSearchFilterValue: (contextSearchFilterValue?: string) => void;
  setContextSearchInputValue: (contextSearchInputValue?: string) => void;
  clearSearchContext: () => void;
  focusAndSelectSearch: () => void;
  searchInputRef?: RefObject<HTMLInputElement>;
};

export type StatusInfoType = {
  status?: BillStatusType | PaymentStatusType;
  color: string;
  label: string;
  colorOpacity: string;
  icon: string | React.ReactNode;
  description?: string;
  descriptionValues?: Record<string, any>;
};
export type BillsGroup = {
  header?: string;
  sum?: number;
  bills: (RecordOf<BillType> | BillType)[];
};

export type BillsListProps = {
  bills: OrderedSet<RecordOf<BillType>>;
  groupedBills: BillsGroup[];
  totalCount: number;
  filters: Filters;
};

export type BadgeSizeType =
  | BADGE_SIZE.LARGE
  | BADGE_SIZE.MEDIUM
  | BADGE_SIZE.SMALL
  | BADGE_SIZE.X_SMALL
  | BADGE_SIZE.X_LARGE;
export type DeletePaymentActionType =
  | DELETE_PAYMENT_ACTION.RECURRING_CURRENT
  | DELETE_PAYMENT_ACTION.RECURRING_ALL
  | DELETE_PAYMENT_ACTION.SINGLE;

export const enum OrgPreferencesTypeKeys {
  showPaymentAuditLog = 'showPaymentAuditLog',
  featureInternalTesting = 'featureInternalTesting',
  newAccountingSoftwareView = 'newAccountingSoftwareView',
  vendorDirectory = 'vendorDirectory',
  partialPayments = 'partialPayments',
  partialPaymentsUI = 'partialPaymentsUI',
  isVendorAbsorbedFee = 'isVendorAbsorbedFee',
  ffNativeQboPilot = 'ffNativeQboPilot',
  internationalPayments = 'internationalPayments',
  qbDashboardSwitcherChecked = 'qbDashboardSwitcherChecked',
  isMigratedToPlatform = 'isMigratedToPlatform',
  vendorFlowsViralityTest = 'vendorFlowsViralityTest',
  holidaysWarning = 'holidaysWarning',
  test2FARolloutPeriod = 'test2FARolloutPeriod',
  amexMCC = 'amexMCC',
  ultimateBeneficialOwnersFlow = 'ultimateBeneficialOwnersFlow',
  datePickerDeliverySpeedChangesFF = 'datePickerDeliverySpeedChangesFF',
  isEligibleForInternationalPayment = 'isEligibleForInternationalPayment',
  billPayFirstWaveUser = 'billPayFirstWaveUser',
}

export type OrganizationPreferencesType = {
  [OrgPreferencesTypeKeys.showPaymentAuditLog]?: 'true' | null;
  [OrgPreferencesTypeKeys.featureInternalTesting]?: 'true' | null;
  [OrgPreferencesTypeKeys.newAccountingSoftwareView]?: string | null;
  [OrgPreferencesTypeKeys.vendorDirectory]?: 'true' | null;
  [OrgPreferencesTypeKeys.partialPayments]?: 'true' | null;
  [OrgPreferencesTypeKeys.partialPaymentsUI]?: 'true' | null;
  [OrgPreferencesTypeKeys.isVendorAbsorbedFee]?: string | null;
  [OrgPreferencesTypeKeys.ffNativeQboPilot]?: 'true' | '1' | boolean | null;
  [OrgPreferencesTypeKeys.qbDashboardSwitcherChecked]?: 'true' | boolean | null;
  [OrgPreferencesTypeKeys.isMigratedToPlatform]?: 'true' | boolean | null;
  [OrgPreferencesTypeKeys.isEligibleForInternationalPayment]?: boolean | null;
  [OrgPreferencesTypeKeys.billPayFirstWaveUser]?: 'true' | boolean | null;
};

export const enum BillingFeeTypeEnum {
  AchToCheck = 'ach_to_check',
}

export interface OrganizationBillingFee {
  id: number;
  organizationId: number;
  fundingSourceId: number;
  billingFeeType?: BillingFeeTypeEnum;
  managedByOrganizationId?: number | null;
  isActive?: boolean;
  createdById: number;
  updatedById: number;
}

export type CreateOrganizationBillingFeeParams = Pick<OrganizationBillingFee, 'fundingSourceId'>;
export type UpdateOrganizationBillingFeeParams = Pick<
  OrganizationBillingFee,
  'fundingSourceId' | 'managedByOrganizationId' | 'isActive' | 'id'
>;

export type paymentFeeApiType = {
  amount: number;
  feeType: string;
  valueType: string;
  feeCatalog?: organizationFee;
};
export type feeApiItem = {
  feeCatalog?: organizationFee;
  amount?: number;
  isCap?: boolean;
  type?: string;
  value?: string;
  cap?: any;
  feeType?: string;
  valueType?: string;
  percent?: string | number;
};
export type feeApiFastItem = {
  fee?: string;
  totalAmount?: number;
  feeType?: string;
  cap?: number;
};
export type organizationFee = {
  cap: any;
  feeType: DELIVERY_TYPE;
  value: string;
  valueType: string;
};
export type OrganizationFeeCatalogType = {
  credit: organizationFee;
  check?: organizationFee;
  'expedited-ach': organizationFee;
  'express-check': organizationFee;
  international: organizationFee;
  'overnight-check': organizationFee;
  'push-to-debit': organizationFee;
} | null;

export type AddressTypeFromApi = {
  line1: string | null | undefined;
  line2: string | null | undefined;
  city: string | null | undefined;
  countryCode: CountryCodeType;
  postalCode: string | null | undefined;
};

export type UserOrgType = {
  id: number | null;
  firstName: string;
  lastName: string;
};

export type UserOrganizationType = {
  user?: UserOrgType;
};

export type NavigateType = (
  url: string,
  shouldReplaceCurrent?: boolean,
  state?: Record<string, any>,
  exitIframe?: boolean,
  isEmbedded?: boolean
) => void;

export type QBRBillListType = QBR_BILL_LIST_TAB_TYPE.OPEN | QBR_BILL_LIST_TAB_TYPE.COMPLETED;

export type Expandable<T> = T & Record<string, any>;

export type ErrorState = {
  errorOccurred: boolean;
  title?: string;
  subtitle?: string;
};

export type ScheduledDataType = {
  title: string;
  date?: Date | string;
  method: string;
  info: string | React.ReactNode;
  hint: string;
  icon: React.ReactNode;
  dateIcon: string;
  description: string;
  isEditable: boolean;
  onEdit?: () => void;
  onEditDate?: () => void;
  secondDate?: React.ReactNode;
  secondDateIcon?: string;
  secondDateDescription?: string;
};

export type DeliveryDataType = {
  title?: string;
  date?: Date | string;
  deliveryDate?: Date | string;
  maxDate?: Date | string;
  method?: string;
  info?: string | React.ReactNode;
  icon?: React.ReactNode;
  dateIcon?: string;
  description?: string;
  deliveryPreference?: string;
  additionalDescription?: string;
  isEditable?: boolean;
  isExpedited?: boolean;
  onEdit?: () => void;
  onEditDate?: () => void;
  formattedCheckSerial?: string;
  origin?: string | null;
  type?: OptionalDeliveryMethodsType | null;
  paperCheck?: CheckType | null;
  additionalHint?: {
    label?: string;
    values?: React.ReactNode | string;
  };
} | null;

export type ToNavigationType =
  | History.LocationDescriptor
  | ((location?: History.Location) => History.LocationDescriptor);

export type ValidationErrors<T> = Partial<{ [F in keyof T]: string }>;

export type ValidationErrorsAccType = { addressLine1?: string };

export type QBCashStateType = {
  balance: number;
  loading: boolean;
  error: any;
};

export type NotePlaceholderType = {
  view: string;
  edit?: string;
};

export type HeaderAction = {
  label?: string;
  action?: () => void;
  variant?: string;
  disabled?: boolean;
  iconClass?: string;
  isProcessing?: boolean;
  analyticsProperties?: Record<string, any>;
};

export type QboBillAttachment = {
  name: string;
  url: string;
};

export type PayEditLocationNamesType =
  | PAY_EDIT_LOCATION_NAME.DATE
  | PAY_EDIT_LOCATION_NAME.FUNDING
  | PAY_EDIT_LOCATION_NAME.DELIVERY_METHOD
  | PAY_EDIT_LOCATION_NAME.DELIVERY_METHOD_ACH
  | PAY_EDIT_LOCATION_NAME.CONFIRM
  | PAY_EDIT_LOCATION_NAME.MEMO
  | PAY_EDIT_LOCATION_NAME.FAST_PAYMENT;

export type IntuitBankAccountResponseType = {
  AccountSubType: string;
  AccountType: string;
  Classification: string;
  FullyQualifiedName: string;
  Id: string;
  Name: string;
};

export type IntuitBankAccountType = {
  accountSubType: string;
  accountType: string;
  classification: string;
  fullyQualifiedName: string;
  name: string;
  id: string;
};

export type FundingSourceType = {
  id: number;
  fundingType: FundingSourceTypes;
  origin: FundingSourceOrigins;
  displayName: string;
  nickname: string;
  isVerified: boolean;
  intuitAccountId: string;
  feeOnly: boolean;
  bankAccount: BankAccount;
  bankAccountId: number;
  cardAccount: CardAccount | null;
  cardAccountId: number | null;
  createdAt: string;
  createdById: number;
  deferredDelivery: {
    natural: number;
  };
  deletedAt: string | null;
  organizationId: number;
  plaidAccount: null;
  plaidAccountId: null;
  updatedAt: string;
};

export type BankAccount = {
  accountNumber: string;
  accountType: string;
  createdAt: string;
  deletedAt: string | null;
  id: number;
  routingNumber: string;
  updatedAt: string;
};

export type CardAccount = {
  cardType: string;
  card4digits: string;
  expiration: string;
  firstName: string;
  lastName: string;
  token: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
  referenceId: string;
  cardBin: string;
  network: string;
};

export const enum FundingSourceTypes {
  'ach',
  'card',
}

export const enum FundingSourceOrigins {
  'plaid',
  'micro',
  'manual',
  'taba-pay',
  'qbcash',
}

export type PaymentSyncResultType = {
  payment: {
    billId: number;
    id: number;
    originId: string;
  };
};

export const enum ReferrerType {
  SHIFT_ACH_TO_VC = 'push-to-debit',
  SHIFT_CHECK_TO_VC = 'shift-check-to-vc',
  UNILATERAL = 'unilateral',
}

export const enum ConfirmationOrigin {
  JUST_PAY = 'just-pay',
  SINGLE_PAYMENT = 'single-payment',
  BATCH_PAYMENT = 'batch-payment',
}

export type ExtendedSelectOption = SelectOption & { id: number; values: any };

export type SelectOption = {
  label: string;
  value: any;
  selected: boolean;
  disabled: boolean;
};

export type FiltersChangeInfo = {
  type: string | null;
  options?: ExtendedSelectOption[];
};

export type MenuFilterItem = {
  type: string;
  label: string;
  options: ExtendedSelectOption[];
};

export type LabelValues = {
  label: string;
  values?: Record<string, any>;
};

export type LayoutNotificationType = {
  type: NOTIFICATION_CARD_TYPES;
  title?: LabelValues;
  showIcon?: boolean;
  subtitle?: LabelValues;
  onClose?: () => void;
  className?: string;
  renderText?: ReactNode;
};

export type FinancingAccount = {
  id: number;
  provider: string;
};

export type PaymentTagProps = {
  label: string;
  statusLabel: string;
  date: string | Date | null | undefined;
};
