import { getQBRBillsDefaultFilters } from 'src/app/utils/bills';
import { QBR_BILL_LIST_TAB_TYPE } from 'src/app/utils/consts';
import isEmpty from 'lodash/isEmpty';
import { BillFilterType } from 'src/app/pages/quickpay/bills/types';

const TAB_TYPES = [QBR_BILL_LIST_TAB_TYPE.COMPLETED, QBR_BILL_LIST_TAB_TYPE.OPEN];

export const getFilters = (query, currentType: QBR_BILL_LIST_TAB_TYPE): BillFilterType => {
  const isDefault = isEmpty(query.start) && isEmpty(query.limit) && isEmpty(query.type);

  return isDefault
    ? getQBRBillsDefaultFilters(currentType)
    : {
        start: query.start,
        limit: query.limit,
        type: currentType,
      };
};

export const getSelectedTab = (query) =>
  TAB_TYPES.includes(query.type) ? query.type : QBR_BILL_LIST_TAB_TYPE.OPEN;
