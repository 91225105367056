import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import withOutsideClickHandler from 'src/app/hoc/withOutsideClickHandler';
import analytics from 'src/app/services/analytics';
import { CONSTS } from 'src/app/utils/consts';
import SelectedMethod from './SelectedMethod';
import SelectMethodOption from './SelectMethodOption';
import { AddAnotherMethodOption } from './MethodElements';

type SelectProps = {
  value: any;
  placeholderIcon: string;
  placeholderLabel: string;
  id: string;
  list: {
    name?: string;
    componentType: any;
    methods: any[];
    disabled?: boolean;
    methodErrors?: any;
    isSelectedDeliveryMethodVirtualCard?: boolean;
  }[];
  errorLabelValues?: any;
  errorLabel?: string;
  onChange: (value: any) => void;
  onAddMethod: (type: string) => void;
  showAddAnotherOption?: boolean;
  testId?: string;
};

export const SelectMethods = ({
  placeholderIcon,
  placeholderLabel,
  list,
  value,
  onAddMethod,
  onChange,
  showAddAnotherOption,
  errorLabelValues,
  errorLabel,
  id,
  testId = 'select-method',
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedListItem = list.find((l) => (l.methods || []).some((m) => m === value));

  const haveInternationalDM =
    list.filter((dm) => dm.name === CONSTS.DELIVERY_TYPE.INTERNATIONAL).length === 1;

  function onMethodChange(method) {
    analytics.trackAction(`select-${id}`);
    onChange(method);
  }

  return (
    <Select
      data-testid={testId}
      handleClickOutside={(e) => {
        // allow clickable elements outside of dropdown list
        const ignoreOutsideClick = e.target?.closest('[data-ignore-outside-click]');

        if (ignoreOutsideClick === null) setIsOpen(false);
      }}
      onClick={() => {
        setIsOpen(!isOpen);
        !isOpen && analytics.track('pay-bills-batch', `open-select-${id}`);
      }}
    >
      <SelectedMethodContainer>
        {selectedListItem ? (
          <selectedListItem.componentType.SelectedMethod method={value} {...selectedListItem} />
        ) : (
          <SelectedMethod
            icon={placeholderIcon}
            label={placeholderLabel}
            errorLabelValues={errorLabelValues}
            errorLabel={errorLabel}
          />
        )}
        <DropdownIcon data-testid="select-method-dropdown-icon" isDropdownOpen={isOpen} />
      </SelectedMethodContainer>
      {isOpen && (
        <OptionsContainer larger={!haveInternationalDM}>
          {list.map(({ componentType: ComponentType, methods, ...rest }, index) => (
            <React.Fragment key={index}>
              {isEmpty(methods) ? (
                <ComponentType
                  onClick={(e) => {
                    if (rest.disabled) {
                      e.stopPropagation();
                    } else {
                      onAddMethod(ComponentType.methodType);
                    }
                  }}
                  optionComponent={SelectMethodOption}
                  {...rest}
                />
              ) : (
                <>
                  {methods.map((method) => {
                    const isDisabled =
                      rest.disabled ||
                      ComponentType.isDisabled(method, {
                        isSelectedDeliveryMethodVirtualCard:
                          rest?.isSelectedDeliveryMethodVirtualCard,
                      });

                    return (
                      <ComponentType
                        key={method.id}
                        method={method}
                        isSelected={value === method}
                        onClick={(event) => {
                          if (isDisabled) {
                            event.stopPropagation();
                          } else {
                            onMethodChange(method);
                          }
                        }}
                        optionComponent={SelectMethodOption}
                        {...rest}
                        disabled={isDisabled}
                      />
                    );
                  })}
                  {showAddAnotherOption && ComponentType.showAddAnotherOption && !rest.disabled && (
                    <AddAnotherMethodOption
                      key={`add-another-${ComponentType.methodType}`}
                      label={ComponentType.addAnotherMethodLabel}
                      onClick={() => {
                        onAddMethod(ComponentType.methodType);
                      }}
                    />
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </OptionsContainer>
      )}
    </Select>
  );
};

const Select = withOutsideClickHandler(styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.white.opaque};
  cursor: pointer;
`);

const OptionsContainer = styled.div<{ larger?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  max-height: ${(props) => (props.larger ? `72rem` : `23rem`)};
  overflow: auto;
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.colors.border.lightGrey};

  & > * {
    border-bottom: 1px solid ${(props) => props.theme.colors.border.lightGrey};

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const SelectedMethodContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem 2rem 0rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.white.lightBlue};
`;

const DropdownIcon = styled.i.attrs({ className: 'icon-next-icon-2' })<{
  isDropdownOpen?: boolean;
}>`
  font-size: 1.6rem;
  color: rgba(107, 108, 114, 1);
  font-weight: ${(props) => props.theme.text.weight.bold};
  transform: rotate(90deg);

  ${(props) =>
    props.isDropdownOpen &&
    css`
      transform: rotate(-90deg);
    `}
`;
