import { generatePath } from 'react-router-dom';
import { analytics } from 'src/app/version-2/externals';
import { GlobalOptInFormSubmitType } from 'src/app/version-2/pages/global-opt-in-details/api/candidates/request/GlobalOptInFormSubmitType';
import { virtualCardCandidatesLocations } from 'src/app/version-2/pages/global-opt-in-details/location';
import {
  GLOBAL_OPT_IN_ALREADY_FAILED,
  GLOBAL_OPT_IN_FORM_SUBMIT_ERROR,
  GLOBAL_OPT_IN_INVALID_TOKEN,
  GLOBAL_OPT_IN_PAGE,
  GLOBAL_OPT_IN_SUCCESS_SUBMIT,
  PERSONAL_DETAILS_FORM_SUCCESS,
} from 'src/app/version-2/pages/global-opt-in-details/model/consts/GlobalOptInAnalytics.consts';
import {
  globalOptInSelectors,
  globalOptInSliceActions,
} from 'src/app/version-2/pages/global-opt-in-details/modules/globalOptIn.slice';
import { candidatesApi } from './../api/candidates/candidatesApi.api';

import { call, put, select, takeLatest } from 'redux-saga/effects';

function* checkCandidatesToken(
  action: ReturnType<typeof globalOptInSliceActions.checkGlobalOptInCandidates>
) {
  const { history, token } = action.payload;

  try {
    const { isVendorFilledForm } = yield call(candidatesApi.getCandidateByToken, {
      token,
    });

    if (isVendorFilledForm) {
      analytics.track(GLOBAL_OPT_IN_PAGE, GLOBAL_OPT_IN_ALREADY_FAILED);
      history.push(generatePath(virtualCardCandidatesLocations.alreadyFilled, { token }));
    } else {
      history.push(generatePath(virtualCardCandidatesLocations.personalDetails, { token }));
    }
  } catch {
    analytics.track(GLOBAL_OPT_IN_PAGE, GLOBAL_OPT_IN_INVALID_TOKEN);
    history.push(generatePath(virtualCardCandidatesLocations.invalid, { token }));
  }
}

function* submitPersonalDetails(
  action: ReturnType<typeof globalOptInSliceActions.continuePersonalDetailsForm>
) {
  const { history, personalDetailsFormData, optInActiveSteps, token } = action.payload;

  yield put(
    globalOptInSliceActions.setPersonalData({
      personalFormData: personalDetailsFormData,
      activeStep: optInActiveSteps,
      token,
    })
  );
  analytics.track(GLOBAL_OPT_IN_PAGE, PERSONAL_DETAILS_FORM_SUCCESS);
  history.push(generatePath(virtualCardCandidatesLocations.businessDetails, { token }));
}

function* submitGlobalOptInCandidates(
  action: ReturnType<typeof globalOptInSliceActions.submitBusinessDetails>
) {
  const { history, businessDetailsFormData, token } = action.payload;

  yield put(globalOptInSliceActions.setBusinessData({ businessDetails: businessDetailsFormData }));
  const personalData = yield select(globalOptInSelectors.selectPersonalDetailsData);

  const { firstName, lastName, phone, email } = personalData;
  const {
    businessName,
    fcbStatus,
    googlePlaceId,
    addressLine1,
    addressLine2,
    city,
    countryCode,
    aptNumber,
    state,
    zipCode,
    routingNumber,
    accountNumber,
  } = businessDetailsFormData;

  const params: GlobalOptInFormSubmitType = {
    contactName: `${firstName} ${lastName}`,
    publicCompanyName: businessName,
    email: email,
    phone: phone,
    fcbStatus: fcbStatus,
    routingNumber,
    accountNumber,
    address: {
      googlePlaceId: googlePlaceId,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      aptNumber: aptNumber,
      state: state,
      zipCode: zipCode,
      countryCode: countryCode,
      city: city,
    },
  };

  try {
    yield call(candidatesApi.submitRawBusiness, token, params);
    history.push(generatePath(virtualCardCandidatesLocations.success, { token }));
    analytics.track(GLOBAL_OPT_IN_PAGE, GLOBAL_OPT_IN_SUCCESS_SUBMIT);
  } catch (err: any) {
    analytics.track(GLOBAL_OPT_IN_PAGE, GLOBAL_OPT_IN_FORM_SUBMIT_ERROR, {
      error: err,
    });
    history.push(generatePath(virtualCardCandidatesLocations.invalid, { token }));
  }
}

export function* watchGlobalOptInCandidates() {
  yield takeLatest(globalOptInSliceActions.checkGlobalOptInCandidates, checkCandidatesToken);
  yield takeLatest(globalOptInSliceActions.continuePersonalDetailsForm, submitPersonalDetails);
  yield takeLatest(globalOptInSliceActions.submitBusinessDetails, submitGlobalOptInCandidates);
}
