import { VendorType } from 'src/app/utils/types';
import { RecordOf } from 'immutable';
import { billListModifiedType } from 'src/app/pages/bill/pay/types';

export type VendorsInput = Record<string, VendorType>;

export const enum ModalContent {
  MAIN = 'main',
  NOTIFY_VENDOR = 'notify-vendor',
}

export type SelectedBill = Record<string, RecordOf<billListModifiedType>>;
