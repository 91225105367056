import React, { useState, useRef } from 'react';
import analytics from 'src/app/services/analytics';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import withOutsideClickHandler from 'src/app/hoc/withOutsideClickHandler';
import { CurrencyInput, CurrencyInputType } from 'src/app/components/common/CurrencyInput';
import { devices } from 'src/app/theme/AppDevices';
import { isEnterPressed } from 'src/app/utils/events';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';

type Props = {
  editAmount: boolean;
  canPartiallyPay: boolean;
  partialBillAmount: string;
  billBalance?: string;
  validationErrors?: { totalAmount?: string };
  validationErrorsValues?: { billBalance: string };
  onChange?: (FieldType) => void;
};

const PartialPaymentAmount = ({
  billBalance,
  partialBillAmount,
  validationErrors,
  validationErrorsValues,
  editAmount,
  onChange,
  canPartiallyPay,
}: Props) => {
  const [focus, setFocus] = useState(editAmount);
  const isInternationalEntryPoint = useSelector(
    (state) => state['flags'].isInternationalEntryPoint
  );
  const ref = useRef<HTMLInputElement>();

  const handleClickOutside = () => {
    setFocus(false);
  };

  const onFocusAmount = (e: React.MouseEvent<HTMLInputElement>) => {
    const inputAmount = e.currentTarget?.querySelector('input') as HTMLElement;

    inputAmount.focus();
    setFocus(true);
    analytics.trackAction('payment-on-focus-amount', {
      amount: partialBillAmount,
    });
  };

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      const inputAmount = event.currentTarget?.querySelector('input') as HTMLElement;

      inputAmount.blur();
      setFocus(false);
    }
  };

  return (
    <PartialAmountWrapper onKeyDown={onKeyPressed}>
      <PartialAmountContainer
        className={FULL_STORY_MASK_RULE_CLASS}
        onClick={onFocusAmount}
        handleClickOutside={handleClickOutside}
        ref={ref}
      >
        <CurrencyInput
          name="totalAmount"
          type={CurrencyInputType.CENTER_ALIGNED}
          value={partialBillAmount}
          placeholder={billBalance}
          onChange={onChange}
          autoFocus={focus}
          showPencil={!focus && canPartiallyPay && !isInternationalEntryPoint}
          pencilTooltip="bills.form.partialPayments.tooltip"
          errors={validationErrors}
          errorsValues={validationErrorsValues}
          disableEdit={!canPartiallyPay || isInternationalEntryPoint}
        />
      </PartialAmountContainer>
    </PartialAmountWrapper>
  );
};

const PartialAmountContainer = withOutsideClickHandler(styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 45rem;

  @media ${devices.mobile} {
    font-weight: 600;
  }
`);

const PartialAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default PartialPaymentAmount;
