import styled from 'styled-components';

export const CategoryListContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  & > div {
    text-align: initial;
    width: 100%;
  }
`;
