import * as React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@melio/billpay-design-system';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS } from 'src/app/utils/consts';
import { ButtonVariantType } from 'src/app/utils/types';
import { isEnterPressed } from 'src/app/utils/events';
import * as WizardElements from 'src/app/components/layout/QBOWizardElements';
import * as QBOLayoutElements from 'src/app/components/layout/QBOElements';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import QBOProgressBar from 'src/app/components/common/QBOProgressBar';
import SandboxIndicator from 'src/app/components/common/SandboxIndicator';

type Props = {
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  title?: any;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  children?: React.ReactNode;
  onNext?: () => void | Promise<void> | null;
  nextLabel?: string;
  goExit?: () => void | null;
  contentWrapperMode?: string;
  onPrev?: () => void | null;
  isNextDisabled?: boolean;
  onSubmit?: () => void | boolean | null;
  isLoading?: boolean;
  isHideActions?: boolean;
  ctaVariant?: ButtonVariantType;
  fullWidthCTA?: boolean;
  innerSize?: number;
  footerLabel?: string;
  footerValues?: Record<string, any>;
  relativeStep?: number | null;
  isQboHeader?: boolean;
  isSandboxIndicatorShown?: boolean;
  isQboFooter?: boolean;
  className?: string;
  nextButtonTooltip?: string;
  footer?: React.ReactNode;
};

const JustPayLayoutPage = ({
  headerLabel,
  headerLabelValues,
  title = null,
  titleValues,
  subtitle,
  goExit,
  children,
  onPrev,
  onNext,
  nextLabel,
  isNextDisabled,
  onSubmit,
  isLoading,
  isHideActions,
  innerSize = 50,
  subTitleValues,
  ctaVariant = CONSTS.BUTTON_VARIANT.PRIMARY,
  fullWidthCTA,
  relativeStep,
  contentWrapperMode,
  isQboHeader,
  isSandboxIndicatorShown,
  className,
  footerLabel,
  footerValues,
  isQboFooter,
  nextButtonTooltip,
  footer,
}: Props) => {
  const submitMethod = onSubmit || onNext;
  const nextActionLabel = nextLabel || 'progress.continue';
  const buttonContainerWidth = fullWidthCTA ? '45' : 'auto';
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (submitMethod && !isNextDisabled && isEnterPressed(event)) {
      submitMethod();
    }
  };

  const handleNextClick = () => {
    if (submitMethod) {
      submitMethod();
    }
  };

  return (
    <QBOLayoutElements.QBOLayoutContainer className={className} onKeyDown={onKeyPressed}>
      {!isQboHeader && (
        <>
          <QBOLayoutElements.QBOHeaderContainer
            goExit={goExit}
            onPrev={onPrev}
            headerLabel={headerLabel}
            headerLabelValues={headerLabelValues}
          />

          {relativeStep ? <QBOProgressBar relativeStep={relativeStep} /> : null}
        </>
      )}

      {isQboHeader && goExit && <Close onClick={goExit} />}

      <WizardInner mode={contentWrapperMode as string}>
        {title && (
          <WizardElements.WizardStepTitle isSubtitle={!!subtitle}>
            {title && <MIFormattedText label={title} values={titleValues} />}
          </WizardElements.WizardStepTitle>
        )}

        {subtitle && (
          <WizardElements.WizardStepSubTitle>
            <MIFormattedText label={subtitle} values={subTitleValues} />
          </WizardElements.WizardStepSubTitle>
        )}

        <WizardElements.WizardInputs mode={contentWrapperMode} innerSize={innerSize}>
          {children}
        </WizardElements.WizardInputs>

        {!isHideActions && submitMethod && (
          <Tooltip
            isDisabled={!isNextDisabled}
            isOpen={isNextDisabled && !!nextButtonTooltip}
            label={<MIFormattedText label={nextButtonTooltip} />}
          >
            <WizardElements.WizardStepActionsContainer>
              <WizardElements.WizardButtonContainer isProgressBar width={buttonContainerWidth}>
                <MIButton
                  label={nextActionLabel}
                  variant={ctaVariant}
                  onClick={handleNextClick}
                  isProcessing={isLoading}
                  disabled={isNextDisabled}
                  fullWidth
                />
              </WizardElements.WizardButtonContainer>
            </WizardElements.WizardStepActionsContainer>
          </Tooltip>
        )}
        {footer && (
          <WizardElements.WizardFooterContainer>{footer}</WizardElements.WizardFooterContainer>
        )}
        {footerLabel && !footer && (
          <WizardElements.WizardFooterContainer>
            <MIFormattedText label={footerLabel} values={footerValues} />
          </WizardElements.WizardFooterContainer>
        )}
      </WizardInner>
      <LayoutPageFooter>{isQboFooter && <QBOLayoutElements.QBOFooterContainer />}</LayoutPageFooter>
      {isSandboxIndicatorShown && <SandboxIndicator />}
    </QBOLayoutElements.QBOLayoutContainer>
  );
};

const WizardInner = styled.div<{ mode: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  z-index: 999;

  @media ${devices.desktop}, ${devices.tablet} {
    justify-content: center;
    width: 90rem;
    margin-bottom: 3rem;
    ${(props) =>
      props.mode === 'alignment-top' &&
      `
        margin-top: 14rem;
        justify-content: start;
      `}
  }

  @media ${devices.mobile}, ${devices.phablet} {
    justify-content: start;
    padding: 0 1.6rem;
  }
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: rgba(113, 113, 115, 1);
`;

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: fixed;
  top: 1.2rem;
  right: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  z-index: 1;

  @media ${devices.mobile}, ${devices.phablet} {
    position: relative;
    display: flex;
    align-self: flex-end;
    margin: 12px 12px 0 0;
    font-size: ${(props) => props.theme.text.size.title};
    right: 1rem;
    top: 0.8rem;
    color: ${(props) => props.theme.text.color.green};
  }
`;

const LayoutPageFooter = styled.div`
  width: 100%;
  @media ${devices.mobile}, ${devices.phablet} {
    display: none;
  }
`;

export default JustPayLayoutPage;
