import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import withOutsideClickHandler from 'src/app/hoc/withOutsideClickHandler';
import { devices } from 'src/app/theme/AppDevices';
import analytics from 'src/app/services/analytics';
import { MIActionsMenu, ActionOption } from 'src/app/components/common/MIActionsMenu';

type Props = {
  actionOptions: ActionOption[];
  moduleName?: string;
  testId?: string;
};

export const MIArrowActionsMenu = ({ actionOptions, moduleName, testId }: Props) => {
  const [isOpen, toggleIsOpen] = useState(false);

  const toggleMenu = (value: boolean) => {
    const actionName = moduleName || 'toggle-actions-menu';

    analytics.trackAction(actionName, { isOpen: value });
    toggleIsOpen(value);
  };

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    toggleMenu(!isOpen);
  };

  const handleClickOutside = () => {
    if (isOpen) toggleMenu(false);
  };

  return (
    <MIArrowActionsContainer handleClickOutside={handleClickOutside}>
      <Container onClick={onClick} isOpen={isOpen} data-testid={testId}>
        <MIFormattedText label="actionsMenu.label" />
        <ArrowIcon isOpen={isOpen} />
      </Container>
      {isOpen && (
        <StyledMIActionsMenu onClose={() => toggleIsOpen(false)} actionOptions={actionOptions} />
      )}
    </MIArrowActionsContainer>
  );
};

const MIArrowActionsContainer = withOutsideClickHandler(styled.div`
  @media ${devices.nonMobile} {
    position: relative;
  }
`);

const StyledMIActionsMenu = styled(MIActionsMenu)`
  border-radius: 0.8rem;

  @media ${devices.nonMobile} {
    right: 0rem;
    top: 3.5rem;
    z-index: 1;
  }
`;

const Container = styled.div<{ isOpen?: boolean }>`
  padding: 0.5rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.border.lightGrey};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};

  &:hover {
    background-color: rgba(222, 222, 222, 1);
  }
`;

const ArrowIcon = styled.i.attrs({ className: 'icon-large-dropdown-arrow' })<{
  isOpen?: boolean;
}>`
  color: ${(props) => props.theme.text.color.main};
  font-size: 2.2rem;
  margin-left: 0.8rem;

  ${(props) =>
    props.isOpen &&
    css`
      transform: rotate(-180deg);
    `}
`;
