import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Flex } from '@melio/billpay-design-system';
import useUpdateUserPreference from 'src/app/modules/users/hooks/useUpdateUserPreferences';
import {
  TooltipContainer,
  CloseIcon,
  TextContainer,
  RelativeContainer,
  Arrow,
  Title,
} from './CreditPaymentMethodTooltip.styles';

const TRANSLATE_CREDIT_TOOLTIP_PATH = 'onboarding.fundingSources.options.credit.tooltip';

export const CreditPaymentMethodTooltip = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage } = useIntl();
  const { updateUserPreference } = useUpdateUserPreference();

  useEffect(() => {
    updateUserPreference({ key: 'fundingSourceCCTooltipSeen', value: true });
  }, []);

  return isOpen ? (
    <TooltipContainer>
      <RelativeContainer>
        <Arrow />
        <Flex justifyContent="space-between" gap="1rem">
          <TextContainer>
            <Title>{formatMessage({ id: `${TRANSLATE_CREDIT_TOOLTIP_PATH}.title` })}</Title>
            {formatMessage({ id: `${TRANSLATE_CREDIT_TOOLTIP_PATH}.description` })}
          </TextContainer>
          <CloseIcon className="icon-close-icon" onClick={() => setIsOpen(false)} />
        </Flex>
      </RelativeContainer>
    </TooltipContainer>
  ) : null;
};

export default CreditPaymentMethodTooltip;
