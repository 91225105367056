import React, { useEffect, useMemo } from 'react';
import { Box, Flex } from '@melio/billpay-design-system';

import US_STATES from 'src/app/utils/us-states';
import { MITextInput } from 'src/app/components/common/MITextInput';
import MISingleSelect from 'src/app/components/common/MISingleSelect';

import { useForm } from 'src/app/ui/form';
import { AddressType } from 'src/app/utils/types';
import MIAddressAutocompleteSmartyStreets from 'src/app/components/common/MIAddressAutocompleteSmartyStreets';
import { SingleSelectFlavorEnum, TextInputSizeEnum } from 'src/app/version-2/model/enums';
import { TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH } from '../../consts';
import { FormProps } from '../../types';
import { EditPaymentFormFooter } from './EditPaymentFormFooter';

const EditPaymentCheckAddressForm = ({
  payment,
  labels,
  isMobile,
  isLoading,
  validationErrors,
  onSubmit,
  onClose,
}: FormProps) => {
  const model = useMemo(
    () => ({
      printName: payment.deliveryMethod.paperCheck?.printName || '',
      addressLine1: payment.deliveryMethod.paperCheck?.addressLine1 || '',
      addressLine2: payment.deliveryMethod.paperCheck?.addressLine2 || '',
      city: payment.deliveryMethod.paperCheck?.city || '',
      state: payment.deliveryMethod.paperCheck?.state || '',
      zipCode: payment.deliveryMethod.paperCheck?.zipCode || '',
    }),
    [payment.deliveryMethod.paperCheck]
  );

  const [values, { submit }] = useForm(model, { submit: onSubmit });

  useEffect(() => {
    if (validationErrors.addressLine1 && values.addressLine1.setError) {
      values.addressLine1.setError(validationErrors.addressLine1);
    }
  }, [validationErrors.addressLine1]);

  const handleSearchAddressResult = (address: AddressType | null) => {
    const { addressLine1, addressLine2, city, state, zipCode } = address || {};

    values.addressLine1.onChange({ value: addressLine1 || '' });
    values.addressLine2.onChange({ value: addressLine2 || '' });
    values.city.onChange({ value: city || '' });
    values.state.onChange({ value: state || '' });
    values.zipCode.onChange({ value: zipCode || '' });
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Flex direction="column" gridGap={8}>
        <Flex gridGap={5} direction="column" justifyContent="center" alignItems="flex-start">
          <MITextInput
            id="printName"
            value={values.printName.value}
            label={labels.printName}
            type="text"
            autoFocus
            required
            onChange={values.printName.onChange}
            errorMessage={validationErrors.printName}
            autocomplete="off"
            size={TextInputSizeEnum.INLINE}
            autoHeightForNotice
          />
          <MIAddressAutocompleteSmartyStreets
            id="addressLine1"
            label={labels.addressLine1}
            model={values.addressLine1}
            autocomplete="off"
            onSelected={(address) => handleSearchAddressResult(address)}
            eventPage="vendor-payment-details"
            showClearIcon={false}
            required
          />
          <MITextInput
            id="addressLine2"
            value={values.addressLine2.value}
            label={labels.addressLine2}
            type="text"
            onChange={values.addressLine2.onChange}
            errorMessage={validationErrors.addressLine2}
            autocomplete="off"
            size={TextInputSizeEnum.INLINE}
            autoHeightForNotice
          />
          <Flex
            direction="row"
            minWidth="full"
            justifyContent="center"
            alignItems="baseline"
            gridGap={6}
          >
            <Box width="50%">
              <MITextInput
                id="city"
                value={values.city.value}
                label={labels.city}
                type="text"
                required
                onChange={values.city.onChange}
                errorMessage={validationErrors.city}
                autocomplete="off"
                size={TextInputSizeEnum.INLINE}
                autoHeightForNotice
              />
            </Box>
            <Box width="50%" justifyContent="center" alignItems="center">
              <MISingleSelect
                id="state"
                value={values.state.value}
                label={labels.state}
                options={US_STATES}
                required
                onChange={values.state.onChange}
                errorMessage={validationErrors.state}
                flavor={SingleSelectFlavorEnum.INLINE}
                autoPosition
              />
            </Box>
          </Flex>
          <MITextInput
            id="zipCode"
            value={values.zipCode.value}
            label={labels.zipCode}
            type="text"
            required
            onChange={values.zipCode.onChange}
            errorMessage={validationErrors.zipCode}
            autocomplete="off"
            size={TextInputSizeEnum.INLINE}
            autoHeightForNotice
          />
        </Flex>
        <EditPaymentFormFooter
          primaryLabel={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.button.save`}
          secondaryLabel={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.button.cancel`}
          onSubmit={submit}
          onClose={onClose}
          isLoading={isLoading}
          isMobile={isMobile}
        />
      </Flex>
    </form>
  );
};

export { EditPaymentCheckAddressForm };
