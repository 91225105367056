import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getOrgId } from 'src/app/redux/user/selectors';
import { fullOrgSyncStore } from 'src/app/modules/fullOrgSync/full-org-sync-store';
import { FullOrgSyncStatusEnum } from 'src/app/version-2/model/enums';
import analytics from 'src/app/services/analytics';

const CHECK_SYNC_STATUS_INTERVAL = 5000;

type FullOrgSyncStatusPullingResult = {
  status: FullOrgSyncStatusEnum;
};

const useFullOrgSyncStatusPolling = (): FullOrgSyncStatusPullingResult => {
  const orgId = useSelector(getOrgId);
  const syncStatus: FullOrgSyncStatusEnum = useSelector(fullOrgSyncStore.selectors.status);

  const fullOrgSyncActions = useStoreActions(fullOrgSyncStore);

  const syncStatusInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const startSyncStatusInterval = () => {
    syncStatusInterval.current = setInterval(updateSyncStatus, CHECK_SYNC_STATUS_INTERVAL);
  };

  const stopSyncStatusInterval = () => {
    if (syncStatusInterval.current) {
      clearInterval(syncStatusInterval.current);
      syncStatusInterval.current = null;
    }
  };

  const updateSyncStatus = () => {
    fullOrgSyncActions.checkFullOrgSyncStatus(orgId);
  };

  useEffect(() => {
    if (syncStatus !== FullOrgSyncStatusEnum.RUNNING) {
      stopSyncStatusInterval();

      if ([FullOrgSyncStatusEnum.DONE, FullOrgSyncStatusEnum.ERROR].includes(syncStatus)) {
        analytics.track('bills-dashboard', 'OrgFullSync-Finished');
      }
    }
  }, [syncStatus]);

  useEffect(() => {
    if (syncStatus === FullOrgSyncStatusEnum.RUNNING) {
      updateSyncStatus();
      startSyncStatusInterval();
    }

    return stopSyncStatusInterval;
  }, []);

  return {
    status: syncStatus,
  };
};

export { useFullOrgSyncStatusPolling };
