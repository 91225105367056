import styled from 'styled-components';

export const TopPanel = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.div<{ isVendorFlow: boolean }>`
  display: ${({ isVendorFlow }) => (isVendorFlow ? 'none' : 'flex')};
  margin-top: 1.6rem;
  margin-right: 1.6rem;
  cursor: pointer;
`;
