import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Avoid browser back navigation
 * in case of back button - go back to the given path url
 */

type Props = {
  path: string;
};
export const useHistoryBack = ({ path }: Props) => {
  const history = useHistory();

  useEffect(
    () => () => {
      if (history.action === 'POP') {
        // navigate only when going back
        history.push(path);
      }
    },
    [history]
  );

  return [];
};
