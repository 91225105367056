import { useBreakpointValue } from '@melio/billpay-design-system';

export const enum DASHBOARD_TABLE_VARIANT {
  LIMITED = 'limited',
  FULL = 'full',
}

const useDashboardTableVariant = () =>
  useBreakpointValue({
    sm: DASHBOARD_TABLE_VARIANT.LIMITED,
    md: DASHBOARD_TABLE_VARIANT.LIMITED,
    lg: DASHBOARD_TABLE_VARIANT.LIMITED,
    xl: DASHBOARD_TABLE_VARIANT.FULL,
    '2xl': DASHBOARD_TABLE_VARIANT.FULL,
  });

export default useDashboardTableVariant;
