const shadows = {
  sm: '0px 1px 10px #D4D7DC',
  card: '0px 2px 10px #D4D7DC',
  cardHover: '0px 2px 10px #C0C4CB',
  md: '0px 2px 2px #babec54d',
  none: 'none',
};

export type QBOShadows = typeof shadows;

export default shadows;
