import React, { Suspense } from 'react';
import MobileAppLayout from '../../components/layout/MobileAppLayout';
import locations from '../../utils/locations';
import analytics from '../../services/analytics';
import NewBillCreateOptionsPageContainer from './NewBillCreateOptionsPageContainer';
import { AreaLoader } from '@melio/billpay-design-system';

const BillsListPageContainerAsync = React.lazy(() => import('./BillsListPageContainer'));
const NewBillPageContainerAsync = React.lazy(() => import('./NewBillPageContainer'));

const eventPage = 'bills';

export default [
  {
    path: locations.Bills.index.url(),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'bills-list');

      return (
        <MobileAppLayout>
          <Suspense fallback={<AreaLoader />}>
            <BillsListPageContainerAsync {...props} />
          </Suspense>
        </MobileAppLayout>
      );
    },
  },
  {
    path: locations.Bills.create.index.url(),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'new-bill');

      return (
        <Suspense fallback={<AreaLoader />}>
          <NewBillPageContainerAsync {...cProps} />
        </Suspense>
      );
    },
  },
  {
    path: locations.Bills.create.options.url(),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'new-bill-create-options');

      return <NewBillCreateOptionsPageContainer {...cProps} />;
    },
  },
];
