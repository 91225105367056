import React from 'react';
import { IntlShape } from 'react-intl';
import { createColumnHelper } from '@tanstack/react-table';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  AmountRenderer,
  ArrivalDateRenderer,
  BillDetailsRenderer,
  BillRenderer,
  CellDeductionDateRenderer,
  DeliveryDateRenderer,
  DeliveryMethodRenderer,
  DeliverySpeedRenderer,
  ExpanderRenderer,
  HeaderRenderer,
  HeaderRendererWrapper,
  MemoRenderer,
  PaymentMethodRenderer,
  RemoveRenderer,
  VendorRenderer,
} from 'src/app/version-2/pages/batch-bulk/renderers';
import { getColumnsWidths } from 'src/app/version-2/pages/batch-bulk/utils/tableWidth.utils';
import { HeaderAccessorEnum } from '../model/enums/headerAccessor.enum';

const BORDER_WIDTH = 32;
const columnHelper = createColumnHelper<BatchBulkItem>();

export const getTableColumns = ({
  columnWidths,
  isLoading,
  formatMessage,
  isBpFastACHExperimentInBatchFlow,
}: {
  columnWidths: ReturnType<typeof getColumnsWidths>;
  isLoading: boolean;
  formatMessage: IntlShape['formatMessage'];
  isBpFastACHExperimentInBatchFlow: boolean;
}) => {
  const columns = [
    columnHelper.display({
      id: HeaderAccessorEnum.EXPANDER,
      cell: ExpanderRenderer as any,
      size: columnWidths[HeaderAccessorEnum.EXPANDER],
      enableSorting: false,
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    columnHelper.accessor(HeaderAccessorEnum.VENDOR, {
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-vendor">
            <HeaderRenderer title={formatMessage({ id: 'batchBulkPage.headers.vendor' })} />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: VendorRenderer as any,
      size: columnWidths[HeaderAccessorEnum.VENDOR] - BORDER_WIDTH,
      sortDescFirst: false,
    }),
    columnHelper.accessor(HeaderAccessorEnum.BILL, {
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-bill">
            <HeaderRenderer title={formatMessage({ id: 'batchBulkPage.headers.bill' })} />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: BillRenderer as any,
      size: columnWidths[HeaderAccessorEnum.BILL] - BORDER_WIDTH,
      enableSorting: false,
    }),
    columnHelper.accessor(HeaderAccessorEnum.PAYMENT_METHOD, {
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-payment-method">
            <HeaderRenderer title={formatMessage({ id: 'batchBulkPage.headers.paymentMethod' })} />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: PaymentMethodRenderer as any,
      size: columnWidths[HeaderAccessorEnum.PAYMENT_METHOD] - BORDER_WIDTH,
      enableSorting: false,
    }),
    columnHelper.accessor(HeaderAccessorEnum.DEDUCTION_DATE, {
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-deduction-date">
            <HeaderRenderer title={formatMessage({ id: 'batchBulkPage.headers.deductionDate' })} />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: CellDeductionDateRenderer as any,
      size: columnWidths[HeaderAccessorEnum.DEDUCTION_DATE] - BORDER_WIDTH,
      sortDescFirst: false,
    }),
    columnHelper.accessor(HeaderAccessorEnum.DELIVERY_METHOD, {
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-delivery-method">
            <HeaderRenderer
              id="testing-this"
              title={formatMessage({ id: 'batchBulkPage.headers.deliveryMethod' })}
            />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: DeliveryMethodRenderer as any,
      size: columnWidths[HeaderAccessorEnum.DELIVERY_METHOD] - BORDER_WIDTH,
      enableSorting: false,
    }),
    columnHelper.accessor(HeaderAccessorEnum.DELIVERY_SPEED, {
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-delivery-speed">
            <HeaderRenderer title={formatMessage({ id: 'batchBulkPage.headers.deliverySpeed' })} />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: DeliverySpeedRenderer as any,
      size: columnWidths[HeaderAccessorEnum.DELIVERY_SPEED] - BORDER_WIDTH,
      enableSorting: false,
    }),
    columnHelper.accessor(HeaderAccessorEnum.DELIVERY_DATE, {
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-delivery-date">
            <HeaderRenderer title={formatMessage({ id: 'batchBulkPage.headers.deliveryDate' })} />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: DeliveryDateRenderer as any,
      size: columnWidths[HeaderAccessorEnum.DELIVERY_DATE] - BORDER_WIDTH,
      sortDescFirst: false,
    }),
    columnHelper.accessor(HeaderAccessorEnum.AMOUNT, {
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-amount">
            <HeaderRenderer title={formatMessage({ id: 'batchBulkPage.headers.amount' })} />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: AmountRenderer as any,
      size: columnWidths[HeaderAccessorEnum.AMOUNT] - BORDER_WIDTH,
      sortDescFirst: false,
    }),
    columnHelper.display({
      id: HeaderAccessorEnum.DETAILS,
      size: columnWidths[HeaderAccessorEnum.DETAILS],
      cell: BillDetailsRenderer,
      enableSorting: false,
    }),
    columnHelper.display({
      id: HeaderAccessorEnum.REMOVE,
      size: columnWidths[HeaderAccessorEnum.REMOVE],
      cell: RemoveRenderer,
      enableSorting: false,
    }),
    columnHelper.display({
      id: HeaderAccessorEnum.MEMO,
      size: columnWidths[HeaderAccessorEnum.MEMO],
      cell: MemoRenderer,
      enableSorting: false,
    }),
  ];

  if (isBpFastACHExperimentInBatchFlow) {
    const arrivalDate = columnHelper.display({
      id: HeaderAccessorEnum.ARRIVAL_DATE,
      header: () => (
        <HeaderRendererWrapper isLoading={isLoading}>
          <div data-testid="batch-bulk-table_header-arrival-date">
            <HeaderRenderer title={formatMessage({ id: 'batchBulkPage.headers.arrivalDate' })} />
          </div>
        </HeaderRendererWrapper>
      ),
      cell: ArrivalDateRenderer as any,
      size: columnWidths[HeaderAccessorEnum.ARRIVAL_DATE] - BORDER_WIDTH,
      sortDescFirst: false,
    });

    columns.splice(6, 2, arrivalDate);
  }

  return columns;
};

const DEFAULT_SORTING = [
  {
    id: HeaderAccessorEnum.VENDOR,
    desc: false,
  },
];

export const getColumnsDefaultSorting = (isLoading: boolean) =>
  isLoading ? undefined : DEFAULT_SORTING;
