import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { PAGINATION } from 'src/app/utils/consts';
import { useQueryStateMultiValues } from 'src/app/utils/hooks';
import { ITEMS_PER_PAGE, QB_DASHBOARD_TAB_STATUS } from '../consts';
import { DashboardListFiltersParams } from '../types';
import { useDashboardHighlightedItems } from './useDashboardHighlightedItems';

type OrderParams = Pick<DashboardListFiltersParams, 'orderBy' | 'orderDirection'>;
type DashboardListParamsActions = {
  setPage: (pageIndex: number) => void;
  setStatus: (status: QB_DASHBOARD_TAB_STATUS) => void;
  setOrder: (params: OrderParams) => void;
  setSearch: (filters: DashboardListFiltersParams) => void;
};

type ExtendedDashboardListParams = {
  orgId: string;
  filters: DashboardListFiltersParams;
};

const useDashboardListParams = (): [ExtendedDashboardListParams, DashboardListParamsActions] => {
  const orgId = useSelector(getOrgId);
  const [filters, setFiltersInitial] = useQueryStateMultiValues<DashboardListFiltersParams>({
    status: QB_DASHBOARD_TAB_STATUS.UNPAID,
    start: PAGINATION.DEFAULT_START,
    limit: ITEMS_PER_PAGE,
    orderBy: undefined,
    orderDirection: undefined,
    search: undefined,
    statusesInDashboard: undefined,
    vendors: undefined,
    dueDates: undefined,
  });
  const { removeHighlightedItemIds } = useDashboardHighlightedItems();
  const {
    status,
    start,
    limit,
    orderBy,
    orderDirection,
    search,
    statusesInDashboard,
    vendors,
    dueDates,
  } = filters;

  const setFilters = useCallback(
    (params: Partial<DashboardListFiltersParams>) => {
      removeHighlightedItemIds();
      setFiltersInitial(params);
    },
    [setFiltersInitial, removeHighlightedItemIds]
  );

  const listParams = useMemo<{
    orgId: string;
    filters: DashboardListFiltersParams;
  }>(
    () => ({
      orgId,
      filters: {
        start,
        limit,
        status,
        orderBy: orderBy || undefined,
        orderDirection: orderDirection || undefined,
        search,
        statusesInDashboard,
        vendors,
        dueDates,
      },
    }),
    [
      orgId,
      start,
      limit,
      status,
      orderBy,
      orderDirection,
      search,
      statusesInDashboard,
      vendors,
      dueDates,
    ]
  );
  const setPage = useCallback(
    (pageIndex: number) => {
      setFilters({
        start: pageIndex * ITEMS_PER_PAGE,
        limit: ITEMS_PER_PAGE,
      });
    },
    [setFilters]
  );

  const setOrder = useCallback(
    (params: OrderParams) => {
      setFilters({
        start: PAGINATION.DEFAULT_START,
        ...params,
      });
    },
    [setFilters]
  );

  const setStatus = useCallback(
    (status: QB_DASHBOARD_TAB_STATUS) => {
      setFilters({
        status,
        start: PAGINATION.DEFAULT_START,
        limit: ITEMS_PER_PAGE,
        orderBy: undefined,
        search: undefined,
        orderDirection: undefined,
        statusesInDashboard: undefined,
        vendors: undefined,
        dueDates: undefined,
      });
    },
    [setFilters]
  );

  const setSearch = useCallback(
    ({ search, statusesInDashboard, vendors, dueDates }: DashboardListFiltersParams) => {
      setFilters({
        status,
        start: PAGINATION.DEFAULT_START,
        limit: ITEMS_PER_PAGE,
        orderBy: undefined,
        orderDirection: undefined,
        search,
        statusesInDashboard,
        vendors,
        dueDates,
      });
    },
    [setFilters]
  );

  return [
    listParams,
    {
      setStatus,
      setPage,
      setOrder,
      setSearch,
    },
  ];
};

export default useDashboardListParams;
