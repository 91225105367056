export const enum PaymentDeliveryStatusEnum {
  ACKNOWLEDGED = 'acknowledged',
  BLOCKED = 'blocked',
  READY = 'ready',
  PROCESSING = 'processing',
  SENT = 'sent',
  COMPLETED = 'completed',
  FAILED = 'failed',
  RETRY = 'retry',
  TECHNICAL_FAILURE = 'technicalFailure',
  CANCEL = 'cancel',
  CANCELED = 'canceled',
  SCHEDULED = 'scheduled',
  INVALID = 'invalid',
  IN_PROGRESS = 'in-progress',
  CLEARED = 'cleared',
  SETTLED = 'settled',
  CANCEL_SCHEDULED = 'cancel-scheduled',
  CANCEL_IN_PROGRESS = 'cancel-in-progress',
  CANCEL_SENT = 'cancel-sent',
  CANCEL_CLEARED = 'cancel-cleared',
  CANCEL_SETTLED = 'cancel-settled',
  CANCEL_FAILED = 'cancel-failed',
}
