import React from 'react';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import ReturnedCheckFlowLocation from 'src/app/pages/bill/pay/ReturnedCheckRecoveryFlowPage/locations';
import ReturnedCheckFlowRouter from 'src/app/pages/bill/pay/ReturnedCheckRecoveryFlowPage/router';
import { BatchBulkPage } from 'src/app/version-2/pages/batch-bulk/components/BatchBulkPage';
import { featureFlags } from '@melio/shared-web';
import PayBatchBillPage from 'src/app/pages/bill/pay/PayBatchBillPage';
import { PayBatchFundingSourcePage } from './PayBatchFundingSourcePage';
import PayBatchCompleteLegalInfoPageContainer from './PayBatchCompleteLegalInfoPageContainer';

const eventPage = 'pay-bill';
const batchEventPage = 'pay-bills-batch-payments';

// eslint-disable-next-line import/no-default-export
export default [
  {
    path: ReturnedCheckFlowLocation.index,
    component: ReturnedCheckFlowRouter,
  },
  {
    path: '/orgs/:orgId/bills/pay/batch',
    exact: true,
    component: (cProps) => {
      const query = new URLSearchParams(cProps.location.search);
      const ids = (query.get('ids') || '').split(',');
      const amounts = (query.get('amounts') || '').split(',');
      const bills = ids.reduce(
        (res, id, idx) => ({
          ...res,
          [id]: Number(amounts[idx] || ''),
        }),
        {}
      );

      const queryCurrentId = query.get('currentId');
      const currentId = queryCurrentId && ids.includes(queryCurrentId) ? queryCurrentId : null;

      analytics.page(batchEventPage, 'batch-payments-table-page-load');
      const [isBatchBulkFlagEnabled] = featureFlags.useFeature('qbo-bulk-payment', false);

      return isBatchBulkFlagEnabled ? (
        <BatchBulkPage
          partialPayments={bills}
          billIds={ids}
          preservedState={cProps.location.state}
        />
      ) : (
        <PayBatchBillPage
          partialPayments={bills}
          currentId={currentId}
          preservedState={cProps.location.state}
        />
      );
    },
  },
  {
    path: '/orgs/:orgId/bills/pay/batch-funding', // locations.Bills.pay.batchFunding
    exact: true,
    component: (cProps) => {
      const query = new URLSearchParams(cProps.location.search);
      const ids = (query.get('ids') || '').split(',');
      const amounts = (query.get('amounts') || '').split(',');

      const {
        location: { state: locationState },
      } = cProps;

      const partialPayments = ids.reduce(
        (res, id, idx) => ({
          ...res,
          [id]: Number(amounts[idx] || ''),
        }),
        {}
      );

      analytics.page(batchEventPage, 'add-first-funding-source');

      return (
        <PayBatchFundingSourcePage
          billIds={ids}
          partialPayments={partialPayments}
          locationState={locationState}
        />
      );
    },
  },
  {
    path: locations.Bills.pay.batchCompleteLegalInfo.url({ orgId: ':orgId' }),
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'batch-complete-legal-info');

      return <PayBatchCompleteLegalInfoPageContainer {...cProps} />;
    },
  },
];
