import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';

export const QBONotificationCardWrapper = styled.div`
  max-width: 45rem;
  margin: 0 auto 4rem;
  border-radius: 0.8rem;
`;

export const DateContainer = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.PaymentDatePicker?.DateContainer}
`;

export const FastACHContainer = styled.div`
  width: 35.9rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin: 0 auto 0 auto;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  border: 1px solid rgba(237, 237, 237, 1);
  @media ${devices.mobile} {
    width: 100%;
  }
`;
