import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { Payment } from 'src/app/version-2/model/dtos';
import { GetPaymentUpsellDetailsRequest } from 'src/app/version-2/pages/vendor-upgrade-payment/api/upgradePayment/requests/GetPaymentUpsellDetailsRequest';
import { FilesUrls } from 'src/app/version-2/pages/vendor-upgrade-payment/model/dtos/filesUrls';
import { UpsellItem } from 'src/app/version-2/pages/vendor-upgrade-payment/model/dtos/upsellItem';
import { GetPaymentUpsellDetailsAndNavigateActionParams } from 'src/app/version-2/pages/vendor-upgrade-payment/model/objects/GetPaymentUpsellDetailsAndNavigateActionParams';
import { UpgradePaymentByPayeeActionParams } from 'src/app/version-2/pages/vendor-upgrade-payment/model/objects/upgradePaymentByPayeeActionParams';
import { GetPaymentUpsellDetailsResponse } from '../api/upgradePayment/responses/GetPaymentUpsellDetailsResponse';

interface UpgradePaymentState {
  payment?: Partial<Payment>;
  filesUrls?: FilesUrls;
  upsellItems?: UpsellItem[];
  isLoading: boolean;
  isSubmitting: boolean;
  isLoadFinished: boolean;
  error: {
    code?: string;
  };
}

export const UPGRADE_PAYMENT_SLICE = `${VERSION_2}upgradePayment`;

const getUpgradePaymentSliceInitialState = (): UpgradePaymentState => ({
  payment: undefined,
  filesUrls: undefined,
  upsellItems: undefined,
  isLoading: false,
  isLoadFinished: false,
  isSubmitting: false,
  error: {
    code: undefined,
  },
});

export const upgradePaymentSlice = createSlice({
  name: UPGRADE_PAYMENT_SLICE,
  initialState: getUpgradePaymentSliceInitialState(),
  reducers: {
    setUpsellData: (
      state: UpgradePaymentState,
      action: PayloadAction<GetPaymentUpsellDetailsResponse>
    ) => {
      state.payment = action.payload.payment;
      state.filesUrls = action.payload.filesUrls;
      state.upsellItems = action.payload.upsellItems;
    },
    setError: (state: UpgradePaymentState, action: PayloadAction<{ error: { code?: string } }>) => {
      state.error = action.payload.error;
    },
    setIsLoading: (state: UpgradePaymentState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLoadFinished: (state: UpgradePaymentState, action: PayloadAction<boolean>) => {
      state.isLoadFinished = action.payload;
    },
    setIsSubmitting: (state: UpgradePaymentState, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    resetState: () => getUpgradePaymentSliceInitialState(),
  },
});

export const upgradePaymentActions = {
  ...upgradePaymentSlice.actions,
  getPaymentUpsellDetails: createAction<GetPaymentUpsellDetailsRequest>(
    `${UPGRADE_PAYMENT_SLICE}/getPaymentUpsellDetails`
  ),
  getPaymentUpsellDetailsAndNavigate: createAction<GetPaymentUpsellDetailsAndNavigateActionParams>(
    `${UPGRADE_PAYMENT_SLICE}/getPaymentUpsellDetailsAndNavigate`
  ),
  upgradePaymentByPayee: createAction<UpgradePaymentByPayeeActionParams>(
    `${UPGRADE_PAYMENT_SLICE}/upgradePaymentByPayee`
  ),
};

const selectState = (state) => state[UPGRADE_PAYMENT_SLICE] as UpgradePaymentState;
const selectPayment = createSelector(selectState, (state) => state.payment);
const selectOrganization = createSelector(selectState, (state) => state.payment?.organization);
const selectDeliveryMethod = createSelector(
  selectState,
  (state) => state.payment?.vendor?.['deliveryMethod']
);
const selectFilesUrls = createSelector(selectState, (state) => state.filesUrls);
const selectUpsellItems = createSelector(selectState, (state) => state.upsellItems);
const selectIsLoading = createSelector(selectState, (state) => state.isLoading);
const selectIsLoadFinished = createSelector(selectState, (state) => state.isLoadFinished);
const selectIsSubmitting = createSelector(selectState, (state) => state.isSubmitting);
const selectErrorCode = createSelector(selectState, (state) => state.error?.code);

export const upgradePaymentSelectors = {
  selectPayment,
  selectOrganization,
  selectDeliveryMethod,
  selectFilesUrls,
  selectUpsellItems,
  selectIsLoading,
  selectIsLoadFinished,
  selectIsSubmitting,
  selectErrorCode,
};
