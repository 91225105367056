import React from 'react';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import { FormRow, FormContainer } from 'src/app/ui/form/FormElements';
import { ModelView } from 'src/app/ui/form';

type TitleProps = {
  vendorName: string;
};

type VendorEmailModelType = {
  contactEmail: string;
};

type ContentProps = {
  model: ModelView<VendorEmailModelType>;
};

const VendorEmailContent = ({ model }: ContentProps) => (
  <FormContainer>
    <FormRow>
      <WizardTextInputField
        model={model.contactEmail}
        type="email"
        required
        label="qbr.success.email.emailLabel"
        placeholder="qbr.success.email.emailPlaceholder"
        privateData
      />
    </FormRow>
  </FormContainer>
);

const VendorEmailTitle = ({ vendorName }: TitleProps) => {
  const site = useSiteContext();
  const { WizardElements } = site.components;

  const titleValues = {
    vendor: vendorName,
  };

  return (
    <>
      <WizardElements.WizardFormRow>
        <WizardElements.WizardStepTitle>
          <MIFormattedText label="qbr.success.email.modal.title" values={titleValues} />
        </WizardElements.WizardStepTitle>
      </WizardElements.WizardFormRow>
      <WizardElements.WizardFormRow>
        <WizardElements.WizardStepSubTitle>
          <MIFormattedText label="qbr.success.email.modal.subtitle" />
        </WizardElements.WizardStepSubTitle>
      </WizardElements.WizardFormRow>
    </>
  );
};

export { VendorEmailTitle, VendorEmailContent };
