import styled from 'styled-components';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { WizardFooterContainer, WizardInner } from 'src/app/components/layout/QBOWizardElements';

export const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  ${WizardInner} {
    max-width: 42rem;
  }
  ${WizardFooterContainer} {
    margin-top: 0;
  }
`;
