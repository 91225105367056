import { useState } from 'react';
import { useEmailVendorOnEditCheckAddres } from 'src/app/pages/vendor/hooks/useEmailVendorOnEditCheckAddress';
import { ValidationErrors } from 'src/app/ui/form';
import { DELIVERY_TYPE, CONSTS } from 'src/app/utils/consts';
import { featureFlags } from '@melio/shared-web';
import paymentsApi from 'src/app/services/api/payments';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useUpdateVendorEmail } from './useUpdateVendorEmail';
import { validateVendorEmail, VendorEmailValidationErrors } from '../utils/validate-user-email';

type Props = {
  contactEmail: string;
  vendorId: number;
  deliveryType?: DELIVERY_TYPE;
  scheduledDate: Date;
  paymentId: string;
  onSubmitSuccess: () => void;
  onSubmitStart: () => void;
  onValidationErrors: (errors: ValidationErrors<VendorEmailValidationErrors>) => void;
};

export const useVendorCollectEmailSubmit = ({
  contactEmail,
  vendorId,
  deliveryType,
  scheduledDate,
  paymentId,
  onSubmitSuccess,
  onSubmitStart,
  onValidationErrors,
}: Props) => {
  const orgId = useSelector(getOrgId) as string;
  const [isProcessing, setIsProcessing] = useState(false);
  const [validationErrors, setValidationErrors] = useState<VendorEmailValidationErrors>({
    contactEmail: undefined,
  });
  const [emailVendorOnPaymentSchedule] = useEmailVendorOnEditCheckAddres();
  const { updateVendorEmail } = useUpdateVendorEmail(vendorId);
  const [shouldSendEmailWhenDMIsACH] = featureFlags.useFeature('bp-vendor-notification-dm-ach');

  const saveVendorEmailAndSendEmail = async ({ onSuccess }: { onSuccess: () => void }) => {
    await updateVendorEmail(contactEmail);

    emailVendorOnPaymentSchedule({
      deliveryType,
      scheduledDate,
      paymentId,
      contactEmail,
    });

    emailVendorIfDeliveryMethodACH();

    onSuccess();
  };

  const emailVendorIfDeliveryMethodACH = () => {
    if (shouldSendEmailWhenDMIsACH && deliveryType === CONSTS.DELIVERY_TYPE.ACH) {
      paymentsApi.notifyVendor(orgId, paymentId);
    }
  };

  const handleCollectVendorEmailModalSubmit = () => {
    onSubmitStart();
    const { validationErrors, isValid } = validateVendorEmail(contactEmail);

    // set or reset(clear) validation errors
    setValidationErrors(validationErrors);

    if (!isValid) {
      onValidationErrors(validationErrors);

      return;
    }

    setIsProcessing(true);

    saveVendorEmailAndSendEmail({
      onSuccess: () => {
        setIsProcessing(false);
        onSubmitSuccess();
      },
    });
  };

  return {
    isProcessing,
    validationErrors,
    handleCollectVendorEmailModalSubmit,
  };
};
