import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { AchPaymentMethod } from 'src/app/components/common/SelectMethods/types/AchPaymentMethod';
import { QboAchPaymentMethod } from 'src/app/components/common/SelectMethods/types/QboAchPaymentMethod';
import { DebitPaymentMethod } from 'src/app/components/common/SelectMethods/types/DebitPaymentMethod';
import { CreditPaymentMethod } from 'src/app/components/common/SelectMethods/types/CreditPaymentMethod';
import SettingsMethodsListItem from 'src/app/components/common/SelectMethods/components/SettingsMethodsListItem';
import RadioSelectMethodOption from 'src/app/components/common/SelectMethods/components/RadioSelectMethodOption';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { VENDOR_TYPES, NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import {
  FeatureFlagsEnum,
  FundingSourceTypesEnum,
  CardTypeEnum,
  FundingSourceOrigins,
} from 'src/app/version-2/model/enums';
import MethodsList, { MethodListItemType } from '../components/MethodsList';

type Props<T> = {
  value?: T;
  fundingSources: T[];
  onAddMethod: (type: string) => void;
  onChange?: (value: T) => void;
  onVerifyClicked?: (id: number) => void;
  onBenefitsClicked?: (card?: FundingSource) => void;
  modifyFundingSourceActions?: (method: FundingSource) => Record<string, any>;
  blockCCMoneyIn?: boolean;
  isVendorEnableCCPayments?: boolean;
  vendorType?: number;
  debitFee?: React.ReactNode | string;
  isBillingFeeFlow?: undefined | boolean;
  showCCTooltip?: boolean;
  eventSource?: string;
  billIds?: string[];
};

export const QboFundingSourcesListLayout: React.FC<Props<FundingSource>> = ({
  value,
  fundingSources,
  onAddMethod,
  onChange,
  onVerifyClicked,
  modifyFundingSourceActions,
  blockCCMoneyIn,
  isVendorEnableCCPayments = true,
  vendorType,
  onBenefitsClicked,
  debitFee,
  isBillingFeeFlow,
  showCCTooltip,
  eventSource,
  billIds = [],
}) => {
  const [isInfoCardVisible, setIsInfoCardVisible] = useState(true);
  const { pathname } = useLocation();
  const isInternationalEntryPoint = useSelector(
    (state) => state['flags'].isInternationalEntryPoint
  );
  const isSettingsPage = pathname.includes('settings');
  const [isPaymentMethodOrderReversed] = featureFlags.useFeature('payment-methods-order');
  const [hasInternationalMastercard] = featureFlags.useFeature(
    FeatureFlagsEnum.INTERNATIONAL_MASTERCARD
  );

  const qboAchMethod = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.ACH && fs.origin === FundingSourceOrigins.QBCASH
  );
  const achMethods = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.ACH && fs.origin !== FundingSourceOrigins.QBCASH
  );
  const creditMethods = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.CARD &&
      fs.cardAccount?.cardType === CardTypeEnum.CREDIT
  );
  const debitMethods = fundingSources.filter(
    (fs) =>
      fs.fundingType === FundingSourceTypesEnum.CARD &&
      fs.cardAccount?.cardType === CardTypeEnum.DEBIT
  );

  const isInternationalVendor = vendorType === VENDOR_TYPES.INTERNATIONAL;
  const disableDebitCards = isInternationalVendor;

  const list: MethodListItemType[] = [];

  if (!isEmpty(qboAchMethod)) {
    list.push({
      name: 'qbCash',
      componentType: QboAchPaymentMethod,
      methods: qboAchMethod,
      onVerifyClicked: undefined,
      isSettings: !onChange,
      isBillingFeeFlow,
    });
  }

  if (!blockCCMoneyIn && isVendorEnableCCPayments) {
    list.push({
      name: 'CC',
      componentType: CreditPaymentMethod,
      methods: creditMethods,
      onVerifyClicked: undefined,
      isBillingFeeFlow,
      onBenefitsClicked,
      showCCTooltip,
      isInternationalVendor,
      isInternationalEntryPoint,
      hideShowMore:
        isInternationalEntryPoint || (isInternationalVendor && !!hasInternationalMastercard),
    });
  }

  list.push({
    name: 'ACH',
    componentType: AchPaymentMethod,
    methods: achMethods,
    onVerifyClicked,
    isBillingFeeFlow,
  });

  if (!isInternationalEntryPoint) {
    list.push({
      name: 'DC',
      componentType: DebitPaymentMethod as any,
      methods: debitMethods,
      onVerifyClicked: undefined,
      isSettings: !onChange,
      isBillingFeeFlow,
      disabled: disableDebitCards,
      description: isInternationalVendor
        ? 'bills.form.paymentActivity.scheduledBill.international.debit'
        : '',
      debitFee,
      hideShowMore: isInternationalVendor && !!hasInternationalMastercard,
    });
  }

  const indexOfCC = list.findIndex((item) => item.name === 'CC');
  const indexOfAch = list.findIndex((item) => item.name === 'ACH');

  if (isPaymentMethodOrderReversed && indexOfAch !== -1 && indexOfCC !== -1) {
    [list[indexOfCC], list[indexOfAch]] = [list[indexOfAch], list[indexOfCC]];
  }

  const shouldShowInfoCard = !isSettingsPage && isInternationalEntryPoint && isInfoCardVisible;
  const notificationLabel = `internationalPayment.fundingSource.${
    hasInternationalMastercard ? 'infoCardMastercard' : 'infoCard'
  }`;

  return (
    <>
      {shouldShowInfoCard && (
        <InfoCard
          type={NOTIFICATION_CARD_TYPES.DISCOVER}
          onClose={() => setIsInfoCardVisible(false)}
          subtitle={{
            label: notificationLabel,
          }}
        />
      )}
      <MethodsList
        value={value}
        list={list}
        onChange={onChange}
        onAddMethod={onAddMethod}
        showAddAnotherOption
        modifyFundingSourceActions={modifyFundingSourceActions}
        isBillingFeeFlow={isBillingFeeFlow}
        optionComponent={onChange ? RadioSelectMethodOption : SettingsMethodsListItem}
      />
    </>
  );
};

export default QboFundingSourcesListLayout;

const InfoCard = styled(QBONotificationCard)`
  margin-bottom: 4rem;
`;
