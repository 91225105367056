import styled from 'styled-components';

export const TooltipContainer = styled.div<{ display?: string }>`
  background-color: ${(props) => props.theme.colors.ds.gray[100]};
  position: absolute;
  transform: translateX(-50%);
  left: 45%;
  padding: 0.8rem;
  bottom: 5rem;
  width: 25.5rem;
  border-radius: 0.4rem;
  display: ${(props) => props.display};
`;

export const TextContainer = styled.div`
  color: ${({ theme }) => theme.colors.ds.white};
  ${(props) => props.theme.textStyles.ds.body3}
  font-weight: ${({ theme }) => theme.fontWeights.ds.regular};
  padding: 0.8rem;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const Arrow = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${(props) => props.theme.colors.ds.gray[100]};
  z-index: 5;
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: rotate(-135deg) translateX(-50%);
`;
