import {
  replaceVirtualDeliveryMethodSlice,
  copyFromOwnedVendorWithUnilateralToken,
  updateWithUnilateralToken,
  shiftVirtualCardToACHPaymentStatusCollectedSlice,
} from 'src/app/modules/delivery-methods/delivery-methods-slice';
import { CONSTS } from 'src/app/utils/consts';
import { createSlice } from '@reduxjs/toolkit';
import head from 'lodash/head';
import get from 'lodash/get';
import set from 'lodash/set';
import size from 'lodash/size';
import unionWith from 'lodash/unionWith';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import assign from 'lodash/assign';
import last from 'lodash/last';
import { PaymentRecord } from 'src/app/pages/payment/records';
import { PaymentType } from 'src/app/utils/types';
import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import api from 'src/app/services/api/payments';
import {
  createApiCallSlice,
  ON_FAILURE,
  ON_REQUEST,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import {
  fetchPaymentDetailsWithToken,
  updatePaymentWithToken,
  fetchEmailToVendorDetails,
  retryFailedToDeliverWithToken,
} from './payments-slice';
import { quickpaySlice } from './quickpay-slice';
import { justPaySlice } from './justPay-slice';

import { qbDashboardListItemsStore } from '../qb-dashboard-list-items/qb-dashboard-list-items-store';
import { DeliveryMethodRecord, VendorRecord } from 'src/app/pages/vendor/records-constants';

const name = 'payments';

export const PAY_STATUS_CONSTS = {
  ALL_PASSED: 'allPassed',
  ALL_FAILED: 'allFailed',
  SOME_FAILED: 'someFailed',
};

export type ApprovalDecisionType = {
  loading: boolean;
  error: any;
  approvalStatus: string;
};

type AddPaymentsByBatchSlicePayloadType = {
  paid: PaymentType[];
  payments: PaymentType[];
  orgId: string;
};

type GetDeliveryTimeSlicePayloadType = {
  deliveryDate: Date;
  suggestedScheduledDate: Date;
  orgId: string;
  deductionDate: Date;
  deliveryMethodId: string;
  fundingSourceId: string;
  amount: string;
};

const approvalDecisionReducers = {
  [ON_REQUEST]: (state, action) => {
    state.approvalDecision.loading = true;
    state.approvalDecision.error = null;
    state.approvalDecision.approvalStatus = action.payload.status;
  },
  [ON_FAILURE]: (state, action) => {
    state.approvalDecision.loading = false;
    state.approvalDecision.error = action.error;
  },
  [ON_SUCCESS]: (state) => {
    state.approvalDecision.loading = false;
    state.approvalDecision.error = null;
  },
};

const approvePaymentSlice = createApiCallSlice({
  name: `[${name.toUpperCase()}] PAYMENT_APPROVED`,
  api: api.approvePayment,
  initialState: {
    approvalDecision: {
      loading: false,
      error: undefined,
      approvalStatus: undefined,
    },
    batchPayments: {},
    deliveryMethods: {},
    meta: {},
    byId: {},
  },
  reducers: approvalDecisionReducers,
});

const declinePaymentSlice = createApiCallSlice({
  name: `[${name.toUpperCase()}] PAYMENT_DECLINED`,
  api: api.declinePayment,
  reducers: approvalDecisionReducers,
});

const batchPaymentsSlice = createSlice({
  name: `[${name.toUpperCase()}] CREATE_BATCH_PAYMENTS`,
  initialState: {
    batchPayments: {},
    deliveryMethods: {},
  },
  reducers: {
    changePayment(state, action) {
      const { billId } = action.payload;

      if (!state.batchPayments[billId]) {
        state.batchPayments[billId] = {};
      }

      state.batchPayments[billId].fundingSourceId = action.payload.fundingSourceId;
      state.batchPayments[billId].deliveryMethodId = action.payload.deliveryMethodId;
      state.batchPayments[billId].note = action.payload.note;

      state.batchPayments[billId].scheduledDate = action.payload.scheduledDate;
      state.batchPayments[billId].deliveryEta = action.payload.deliveryEta;
      state.batchPayments[billId].deliveryPreference = action.payload.deliveryPreference;
      state.batchPayments[billId].purpose = action.payload.purpose;
    },
    setPaymentDatesAction(state, action) {
      const { billId, scheduledDate, deliveryDate, deliveryPreference, maxDeliveryDate } =
        action.payload;

      if (!state.batchPayments[billId]) {
        state.batchPayments[billId] = {};
      }

      const { batchPayments } = state;

      batchPayments[billId].scheduledDate = scheduledDate;
      batchPayments[billId].deliveryEta = deliveryDate;
      batchPayments[billId].deliveryPreference = deliveryPreference;
      batchPayments[billId].maxDeliveryEta = maxDeliveryDate;
    },
    setPaymentMethod(state, action) {
      const { batchPayments } = state;

      const {
        billId,
        newFundingSource,
        selectedFundingSourceId,
        newDeliveryMethod,
        scheduledDate,
        newNote,
      } = action.payload;

      if (billId && !batchPayments[billId]) {
        batchPayments[billId] = {};
      }

      if (newDeliveryMethod?.id) {
        if (newDeliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
          batchPayments[billId].virtualAccount = newDeliveryMethod?.virtualAccount;
          batchPayments[billId].deliveryType = newDeliveryMethod?.deliveryType;
          batchPayments[billId].virtualAccountId = newDeliveryMethod?.virtualAccountId;
        }

        batchPayments[billId].deliveryMethodId = newDeliveryMethod.id;
        batchPayments[billId].deliveryMethod = newDeliveryMethod;
      }

      if (newFundingSource) {
        batchPayments[billId].selectedFundingSource = newFundingSource;
      }

      if (scheduledDate) {
        batchPayments[billId].scheduledDate = scheduledDate;
      }

      // add new debit/credit card
      if (selectedFundingSourceId) {
        batchPayments[billId].fundingSourceId = selectedFundingSourceId;
      }

      if (newNote) {
        batchPayments[billId].newNote = newNote;
      }
    },
    setScheduledDate(state, action) {
      const { batchPayments } = state;
      const { billId, deductionDate } = action.payload;

      if (billId && !batchPayments[billId]?.scheduledDate) {
        batchPayments[billId] = {};
        batchPayments[billId].scheduledDate = {};
      }

      batchPayments[billId].scheduledDate = deductionDate;
    },
    resetDates(state, action) {
      const { batchPayments } = state;

      if (!batchPayments[action.payload]) {
        batchPayments[action.payload] = {};
      }

      batchPayments[action.payload].scheduledDate = null;
      batchPayments[action.payload].deliveryEta = null;
    },
    resetState() {
      return {
        approvalDecision: {
          loading: false,
          error: undefined,
          approvalStatus: undefined,
        },
        batchPayments: {},
        deliveryMethods: {},
        qboBatchPaymentsNavigationUrls: {},
        meta: {},
        byId: {},
        justPay: {},
      };
    },
  },
});

const qboBatchPaymentsNavigationUrlsSlice = createSlice({
  name: `[${name.toUpperCase()}]_NAVIGATION_URLS`,
  initialState: {
    qboBatchPaymentsNavigationUrls: {
      redirectUrl: '',
      exitUrl: '',
    },
  },
  reducers: {
    setNavigationUrls: (state, action) => {
      if (!state.qboBatchPaymentsNavigationUrls) {
        state.qboBatchPaymentsNavigationUrls = { redirectUrl: '', exitUrl: '' };
      }

      state.qboBatchPaymentsNavigationUrls.redirectUrl = action.payload.redirectUrl;
      state.qboBatchPaymentsNavigationUrls.exitUrl = action.payload.exitUrl;
    },
  },
});

const addPaymentsByBatchSlice = createApiCallSlice<AddPaymentsByBatchSlicePayloadType, any>({
  name: `[${name.toUpperCase()}] SEND_BATCH_PAYMENTS`,
  api: api.createPaymentsByBatch,
  initialState: { scheduled: [], totalAmount: 0, paid: [], meta: {} },
  reducers: {
    [ON_REQUEST](state, action) {
      set(state, 'meta.batchPayments.save.status', { loading: true });
      set(state, 'scheduled', action.payload.payments);
    },
    [ON_SUCCESS](state, action) {
      set(state, 'meta.batchPayments.save.status', { loading: false });
      state.paid = action.payload.paid;
    },
    [ON_FAILURE](state, action) {
      state.paid = action.payload.paid;
      set(state, 'meta.batchPayments.save.status', {
        loading: false,
        error: action.error,
      });
    },
  },
});

const getDeliveryTimeSlice = createApiCallSlice<GetDeliveryTimeSlicePayloadType, any>({
  name: `[${name.toUpperCase()}] BATCH_PAYMENTS_GET_DELIVERY_DATE`,
  api: api.getDeliveryTime as any,
  initialState: {
    batchPayments: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      const currentBillId = get(action, 'payload.billId');

      if (state.batchPayments[currentBillId]) {
        state.batchPayments[currentBillId].status = { loading: false };
      } else {
        set(state, `batchPayments[${currentBillId}]`, {
          status: { loading: true },
        });
      }
    },
    [ON_SUCCESS](state, action) {
      const currentBillId = get(action, 'meta.identifier.billId');

      if (state.batchPayments[currentBillId]) {
        assign(state.batchPayments[currentBillId], {
          deliveryEta: action.payload.deliveryDate,
          scheduledDate: action.payload.suggestedScheduledDate,
        });
      } else {
        set(state, `batchPayments[${currentBillId}]`, {
          deliveryEta: action.payload.deliveryDate,
          scheduledDate: action.payload.suggestedScheduledDate,
        });
      }

      state.batchPayments[currentBillId].status = { loading: false };
    },
    [ON_FAILURE](state, action) {
      const currentBillId = get(action, 'meta.identifier.billId');

      state.batchPayments[currentBillId].status = {
        loading: false,
        error: action.error,
      };
    },
  },
});

const persistConfig = {
  whitelist: ['quickpay'],
};

const paymentStore = createRestfulSlice({
  name,
  api: {
    list: (params) =>
      api.list(params).then(({ objects, totalCount }) => ({ items: objects, totalCount })),
  },
  initialState: {},
  persistConfig,
  selectors: {
    approvalDecisionStatus: (state) => state[name].approvalDecision,
    // BPSP - Bill Pay Service Provider: (fs.fundingType === 'card' && !fs.isVerified)
    getUserFundingSources: (state) =>
      filter(state.user.fundingSources, (fs) => !(fs.fundingType === 'card' && !fs.isVerified)),
    billListModified: (state) => {
      const billList = cloneDeep(state.billsStore.byId);
      const deliveryOptions = cloneDeep(state.billsStore.deliveryOptions);

      const bills = {};

      for (const [billId, bill] of Object.entries<any>(billList)) {
        if (head(bill.payments) instanceof PaymentRecord) {
          bill.payments = [(head(bill.payments) as any).toJS()];
        }

        const {
          payments: { batchPayments, deliveryMethods },
        } = state;
        const currentChanges = batchPayments[bill.id];
        const payment = head(bill.payments) as any;

        if (currentChanges?.scheduledDate || currentChanges?.scheduledDate === null) {
          payment.scheduledDate = currentChanges.scheduledDate;
        }

        if (currentChanges?.purpose) {
          payment.purpose = currentChanges.purpose;
        }

        if (currentChanges?.deliveryEta) {
          payment.deliveryEta = currentChanges.deliveryEta;
        }

        if (currentChanges?.note) {
          payment.note = currentChanges.note;
        }

        if (currentChanges?.fundingSourceId) {
          payment.fundingSourceId = currentChanges.fundingSourceId;
        }

        if (currentChanges?.deliveryPreference) {
          payment.deliveryPreference = currentChanges.deliveryPreference;
        }

        payment.fundingSource = find(
          state.user.fundingSources,
          (fs) => fs.id === payment.fundingSourceId
        );

        // concat all deliveryMethods
        assign(bill.vendor.deliveryMethods, deliveryMethods[bill.vendorId]);

        if (currentChanges?.deliveryMethodId) {
          payment.deliveryMethodId = currentChanges.deliveryMethodId;
          payment.deliveryMethod = find(
            bill.vendor.deliveryMethods,
            (dm) => dm.id === payment.deliveryMethodId
          );
        }

        bills[billId] = {
          bill,
          deliveryOptions: deliveryOptions[billId],
        };
      }

      return bills;
    },
    getSelectedBill: (params) => (state) => {
      const { currentId, isExternalFlow, preservedState } = params;

      if (!currentId) return null;

      const {
        payments: { batchPayments, deliveryMethods },
      } = state;

      const bill = cloneDeep(get(state, `billsStore.byId[${currentId}]`));

      if (!bill) return null;

      const deliveryOptions = cloneDeep(state.billsStore.deliveryOptions);

      if (head(bill.payments) instanceof PaymentRecord) {
        bill.payments = [(head(bill.payments) as any).toJS()];
      }

      const payment = head(bill.payments) as any;

      // concat all deliveryMethods
      bill.vendor.deliveryMethods = assign(
        bill.vendor.deliveryMethods,
        deliveryMethods[bill.vendorId]
      );

      if (preservedState?.newDeliveryMethod || batchPayments[currentId]?.deliveryMethod) {
        bill.vendor.deliveryMethods = unionWith(
          bill.vendor.deliveryMethods,
          [
            DeliveryMethodRecord(
              preservedState?.newDeliveryMethod || batchPayments[currentId]?.deliveryMethod
            ),
          ],
          isEmpty
        );
      }
      // if batchPayments[currentId].scheduleDate not exists,
      // it means that user didn't save changes on SidePanel (didn't click "Set and close" button)

      if (batchPayments[currentId]?.deliveryMethodId) {
        payment.deliveryMethodId =
          preservedState?.newDeliveryMethod?.id || batchPayments[currentId].deliveryMethodId;
        payment.deliveryMethod = find(
          bill.vendor.deliveryMethods,
          (dm) => dm.id === payment.deliveryMethodId
        );
      } else if (!payment.deliveryMethodId) {
        payment.deliveryMethod = last(bill.vendor.deliveryMethods);
        payment.deliveryMethodId = payment.deliveryMethod?.id;
      }

      if (batchPayments[currentId]?.note) {
        payment.note = batchPayments[currentId].note;
      }

      if (batchPayments[currentId]?.purpose) {
        payment.purpose = batchPayments[currentId].purpose;
      }

      if (batchPayments[currentId]?.newNote && isExternalFlow) {
        payment.note = batchPayments[currentId].newNote;
      }

      if (batchPayments[currentId]?.fundingSourceId) {
        // selected payment fundingSource
        // eslint-disable-next-line max-len
        payment.fundingSource = find(
          state.user.fundingSources,
          (fs) => fs.id === batchPayments[currentId].fundingSourceId
        );
      } else if (batchPayments[currentId]?.selectedFundingSource) {
        // new fundingSource
        const fundingSource = batchPayments[currentId].selectedFundingSource;

        if (fundingSource.fsId) {
          // ACH fundingSource

          // eslint-disable-next-line max-len
          payment.fundingSource = find(
            state.user.fundingSources,
            (fs) => fs.id === fundingSource.fsId
          );
          payment.fundingSourceId = fundingSource.fsId;
        } else {
          // plaid fundingSource
          payment.fundingSource = find(
            state.user.fundingSources,
            (fs) => fs.origin === fundingSource.origin
          );
          payment.fundingSourceId = payment.fundingSource?.id;
        }
      } else {
        // default payment fundingSource
        payment.fundingSource = find(
          state.user.fundingSources,
          (fs) => fs.id === (head(bill.payments) as any).fundingSourceId
        );
      }

      if (
        batchPayments[currentId]?.scheduledDate ||
        batchPayments[currentId]?.scheduledDate === null
      ) {
        payment.scheduledDate = batchPayments[currentId].scheduledDate;
      }

      if (batchPayments[currentId]?.deliveryEta || batchPayments[currentId]?.deliveryEta === null) {
        payment.deliveryEta = batchPayments[currentId].deliveryEta;
      }

      if (batchPayments[currentId]?.deliveryPreference) {
        payment.deliveryPreference = preservedState?.newDeliveryMethod
          ? null
          : batchPayments[currentId].deliveryPreference;
      }

      bill.vendor = VendorRecord(bill.vendor);

      return { bill, deliveryOptions: deliveryOptions[bill.id] };
    },
    getPaymentList: (state) => {
      const paymentList: PaymentType[] = [];
      const { partialPayments = {} } = state.billsStore;

      mapValues(state.billsStore.byId, (bill) => {
        const payment = cloneDeep(head<PaymentType>(bill.payments));
        const changes = state.payments.batchPayments[bill.id];

        if (payment) {
          if (changes?.scheduledDate) {
            payment.scheduledDate = changes.scheduledDate;
          }

          if (changes?.purpose) {
            payment.purpose = changes.purpose;
          }

          if (changes?.deliveryEta) {
            payment.deliveryEta = changes.deliveryEta;
          }

          if (changes?.deliveryMethod) {
            payment.deliveryMethod = changes.deliveryMethod;
          }

          if (changes?.deliveryMethodId) {
            payment.deliveryMethodId = changes.deliveryMethodId;
          }

          if (changes?.fundingSourceId) {
            payment.fundingSourceId = changes.fundingSourceId;
          }

          if (changes?.note) {
            payment.note = changes.note;
          }

          if (changes?.deliveryPreference) {
            payment.deliveryPreference = changes.deliveryPreference;
          }

          if (partialPayments[bill.id]) {
            payment.amount = partialPayments[bill.id];
          }

          paymentList.push(payment);
        }
      });

      return paymentList;
    },
    getScheduledPayments: (state) => {
      const totalAmount = state.payments.scheduled?.reduce(
        (total, payment) => total + Number(payment.amount),
        0
      );
      const countPayments = size(state.payments.scheduled);

      return {
        totalAmount,
        countPayments,
      };
    },
    getPaidPayments: (state) => state.payments.paid ?? [],
    getPaymentResult: (state) => {
      const totalAmount = state.payments.paid?.reduce(
        (total, payment) => total + payment.amount,
        0
      );
      const countPayments = size(state.payments.paid);
      const billsNotPaid = filter<PaymentType>(
        state.billsStore.byId,
        (v) =>
          !includes(
            map(state.payments.paid, (p) => p.billId),
            v.id
          )
      );
      let payStatus;

      if (size(state.payments.paid) === 0) {
        payStatus = PAY_STATUS_CONSTS.ALL_FAILED;
      } else if (size(state.billsStore.byId) === size(state.payments.paid)) {
        payStatus = PAY_STATUS_CONSTS.ALL_PASSED;
      } else {
        payStatus = PAY_STATUS_CONSTS.SOME_FAILED;
      }

      const companyNameSet = reduce(
        billsNotPaid,
        (result, value) => result.add(value.vendor.companyName),
        new Set<string>()
      );

      return {
        totalAmount,
        countPayments,
        payStatus,
        vendorsName: [...companyNameSet],
      };
    },
    getPaymentLoading: (state) =>
      get(state, 'payments.meta.batchPayments.save.status.loading', false),
    dictionary: (state) => state[name].byId || {},
    byId: (paymentId) => (state) => state[name].byId[paymentId] as PaymentType,
    payment(paymentId) {
      const paymentSelectors = {
        filesUrls(state) {
          return state[name].meta[paymentId]?.filesUrls;
        },
        deliveryDates(state) {
          return state[name].meta[paymentId]?.deliveryDates;
        },
        deliveryMethodExist(state) {
          return state[name].meta[paymentId]?.deliveryMethodExist;
        },
      };

      return paymentSelectors;
    },
    validation(paymentId) {
      const validationData = {
        isPaymentLoading(state) {
          return state[name].meta[paymentId]?.loading;
        },
        errorData(state) {
          return state[name].meta[paymentId]?.error;
        },
      };

      return validationData;
    },
    getBatchPaymentsByBillId: (billId) => (state) => state[name].batchPayments[billId],
    qboBatchPaymentsRedirectUrl: (state) =>
      state[name].qboBatchPaymentsNavigationUrls?.redirectUrl || '',
    qboBatchPaymentsExitUrl: (state) => state[name].qboBatchPaymentsNavigationUrls?.exitUrl || '',
  },
  slices: {
    approvePaymentSlice,
    declinePaymentSlice,
    getDeliveryTimeSlice,
    addPaymentsByBatchSlice,
    batchPaymentsSlice,
    fetchPaymentDetailsWithToken,
    fetchEmailToVendorDetails,
    retryFailedToDeliverWithToken,
    quickpay: quickpaySlice,
    updatePaymentWithToken,
    qboBatchPaymentsNavigationUrlsSlice,
    justPay: justPaySlice,
  },
  extraReducers: {
    [replaceVirtualDeliveryMethodSlice.actions.success](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [copyFromOwnedVendorWithUnilateralToken.actions.success](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [updateWithUnilateralToken.actions.success](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [qbDashboardListItemsStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        if (item.payment) {
          state.byId[item.payment.id] = item.payment;
        }
      });
    },
    [shiftVirtualCardToACHPaymentStatusCollectedSlice.actions.success](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
  },
});

function virtualDeliveryMethodReplaceSuccessHandler(state, action) {
  const paymentId = action?.meta?.identifier?.paymentId;

  if (paymentId) {
    const deliveryType = action?.payload?.deliveryType;
    const { achDates, checkDates } = state.meta[paymentId].deliveryDates;
    const dates = deliveryType === CONSTS.DELIVERY_TYPE.CHECK ? checkDates : achDates;

    state.byId[paymentId].deliveryMethodId = action.payload.id;
    state.byId[paymentId].deliveryEta = dates.deliveryDate;
    state.byId[paymentId].maxDeliveryEta = dates.maxDeliveryDate;
  }
}

export function getPaymentsActions(dispatch: any) {
  return {
    approvePayment: (params: any) => dispatch(paymentStore.actions.approvePaymentSlice(params)),
    declinePayment: (params: any) => dispatch(paymentStore.actions.declinePaymentSlice(params)),
    // send batch payments
    createBatchPayment: (params: any) =>
      dispatch(paymentStore.actions.addPaymentsByBatchSlice(params)),
    setScheduledDate: (params: any) => {
      const { billId, orgId, deductionDate, deliveryMethodId, fundingSourceId, amount } = params;

      dispatch(
        batchPaymentsSlice.actions.setScheduledDate({
          billId,
          deductionDate,
        })
      );

      if (deductionDate.date && deliveryMethodId && fundingSourceId) {
        dispatch(
          paymentStore.actions.getDeliveryTimeSlice({
            billId,
            orgId,
            deductionDate,
            deliveryMethodId,
            fundingSourceId,
            amount,
          })
        );
      }

      if (!deductionDate) {
        dispatch(batchPaymentsSlice.actions.resetDates(billId));
      }
    },
    changePayment: (params: any) => dispatch(batchPaymentsSlice.actions.changePayment(params)),
    setPaymentMethod: (params: any) => {
      dispatch(batchPaymentsSlice.actions.setPaymentMethod(params));
    },
    setPaymentDatesAction: (params: any) => {
      dispatch(batchPaymentsSlice.actions.setPaymentDatesAction(params));
    },
    resetQboBatchPaymentsState: () => dispatch(batchPaymentsSlice.actions.resetState()),
    setQboBatchPaymentsNavigationUrls: (params: { redirectUrl: string; exitUrl: string }) =>
      dispatch(qboBatchPaymentsNavigationUrlsSlice.actions.setNavigationUrls(params)),
  };
}

export default paymentStore;
