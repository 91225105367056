import { CSSObject } from '@melio/billpay-design-system';

export const containerStyle: CSSObject = {
  alignItems: 'flex-start',
};

export const titleStyle: CSSObject = {
  display: 'flex',
  fontWeight: 'ds.semi',
  alignItems: 'center',
  gap: 'ds.sm',
};

export const descriptionStyle: CSSObject = {
  textStyle: 'ds.body3',
  fontWeight: 'ds.regular',
  display: 'inline-block',
  color: 'ds.gray.200',
  maxWidth: '27.8rem',
};

export const warningStyle: CSSObject = {
  position: 'relative',
  bottom: '0.1rem',
  left: '-0.2rem',
};

export const tagStyle: CSSObject = {
  padding: '0.1rem 0.5rem',
};
