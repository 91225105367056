export const mainActionLabelStyle = ({ isDisabled }) => ({
  apply: 'textStyles.body3',
  fontWeight: 500,
  color: isDisabled ? 'gray.400' : 'blue.500',
  _hover: isDisabled
    ? undefined
    : {
        cursor: 'pointer',
        color: 'blue.200',
      },
});

export const menuButtonStyle = ({ isDisabled }) => ({
  color: isDisabled ? 'gray.400' : 'black',
  _hover: {
    cursor: isDisabled ? 'none' : 'pointer',
  },
});
