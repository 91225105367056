import styled, { css } from 'styled-components';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import {
  WizardStepTitle,
  WizardStepSubTitle,
  WizardInner,
} from 'src/app/components/layout/QBOWizardElements';
import { QBOHeaderContainer } from 'src/app/components/layout/QBOHeader';
import { devices } from 'src/app/theme/AppDevices';

const commonSpacing = `
  margin-bottom: 40px;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const StyledQBOLayoutPage = styled(QBOLayoutPage)<{ isCentered: boolean }>`
  ${QBOHeaderContainer} {
    @media ${devices.mobile} {
      background: none;
    }
  }

  ${WizardStepTitle} {
    margin-bottom: 0.8rem;
  }

  ${WizardInner} {
    @media ${devices.desktop}, ${devices.tablet} {
      justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
    }
  }

  ${css`
    ${WizardStepSubTitle} {
      ${commonSpacing}

      @media ${devices.mobile}, ${devices.phablet} {
        margin-bottom: 2rem;
      }
    }
  `}
`;

export const DoneIcon = styled.img`
  height: 60px;
  width: 60px;
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
