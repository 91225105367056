import React, { MouseEventHandler, useState } from 'react';
import { Box } from '@melio/billpay-design-system';

import { CONSTS } from 'src/app/utils/consts';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { MIFormattedText } from 'src/app/utils/formatting';
import { getCardLogo } from 'src/app/utils/card';
import { FundingSource } from 'src/app/version-2/model/dtos';
import MIButton from 'src/app/components/common/MIButton';
import { useBreak } from 'src/app/hoc';
import { CardTypeEnum, CardNetworkEnum } from 'src/app/version-2/model/enums';
import { getPaymentMethodNameParts } from 'src/app/version-2/utils/paymentMethods.utils';
import {
  CreditCardList,
  CreditCardListButtonContainer,
  CreditCardTileStyled,
} from 'src/app/components/common/SelectMethods/containers/CreditCardSourcesListModal.styles';

interface CreditCardTileProps {
  icon: any;
  label: string;
  labelValues: Record<string, any>;
  isSelected: boolean;
  onClick?: MouseEventHandler;
}

export interface CreditCardSourcesListModalProps {
  onButtonClick: (card?: FundingSource) => void;
  onCloseClick: MouseEventHandler;
  analyticsProperties?: Record<string, any>;
  creditSources: FundingSource[];
}

const CreditCardTile: React.FC<CreditCardTileProps> = (props) => {
  const { icon, label, labelValues, isSelected, onClick } = props;

  return (
    <CreditCardTileStyled isSelected={isSelected} onClick={onClick}>
      {icon}
      <Box textStyle="body2">
        <MIFormattedText label={label} values={labelValues} />
      </Box>
    </CreditCardTileStyled>
  );
};

export const CreditCardSourcesListModal: React.FC<CreditCardSourcesListModalProps> = (props) => {
  const { onButtonClick, onCloseClick, creditSources, analyticsProperties } = props;
  const { isMobile } = useBreak();
  const [selectedCard, setSelectedCard] = useState<FundingSource | undefined>(undefined);
  const analyticsWithFundingSource = { fundingSourceId: selectedCard?.id, ...analyticsProperties };

  return (
    <ModalMessage
      titleComponent={
        <Box textStyle="h6" fontWeight="ds.semi">
          <MIFormattedText label="bills.pay.date.creditCardBenefits.modal.title" />
        </Box>
      }
      contentSubTitle={
        <Box textStyle="body2">
          <MIFormattedText label="bills.pay.date.creditCardBenefits.modal.subTitle" />
        </Box>
      }
      buttonComponent={
        <CreditCardListButtonContainer>
          <MIButton
            onClick={() => onButtonClick(selectedCard)}
            label="bills.pay.date.creditCardBenefits.modal.button"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            analyticsProperties={analyticsWithFundingSource}
            fullWidth={isMobile}
            disabled={!selectedCard}
          />
        </CreditCardListButtonContainer>
      }
      contentComponent={
        <CreditCardList>
          {creditSources.map((card) => {
            const cardType = card.cardAccount?.cardType as CardTypeEnum;
            const network = (card.cardAccount?.network || '').toUpperCase() as CardNetworkEnum;

            return (
              <CreditCardTile
                key={card.id}
                icon={cardType && getCardLogo(cardType, network)}
                label="bills.pay.date.creditCardBenefits.modal.tile"
                labelValues={getPaymentMethodNameParts({ fundingSource: card })}
                isSelected={selectedCard?.id === card.id}
                onClick={() => setSelectedCard(card)}
              />
            );
          })}
        </CreditCardList>
      }
      onCloseClick={onCloseClick}
      buttonLabel="bills.pay.date.creditCardBenefits.modal.button"
    />
  );
};
