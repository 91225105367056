import { Option } from '@melio/billpay-design-system';
import {
  MccIndustryInterface,
  MccInterface,
} from 'src/app/version-2/pages/batch-bulk/model/objects';

export const isValidMcc = (mcc: MccInterface) => mcc?.code && mcc?.industry;

export const isExistMccIndustry = (mccIndustries: Option[], industry: string) =>
  mccIndustries.filter((mmcIndustry) => mmcIndustry.label === industry).length > 0;

export const breakMCCsToOptions = (allowedMCCs: MccInterface[]) => {
  const mccIndustries: Option[] = [];
  const mccSubIndustries: MccIndustryInterface = {};

  const validAllowedMCCs: MccInterface[] = allowedMCCs.map(
    (mcc) => (isValidMcc(mcc) && mcc) as MccInterface
  );

  for (const mcc of validAllowedMCCs) {
    const { code, industry, description }: MccInterface = mcc;

    if (!isExistMccIndustry(mccIndustries, industry)) {
      mccIndustries.push({ label: industry, value: industry });
    }

    if (!mccSubIndustries[industry]) {
      mccSubIndustries[industry] = [];
    }

    mccSubIndustries[industry].push({ label: description, value: code });
  }

  return {
    mccIndustries,
    mccSubIndustries,
  };
};
