import React from 'react';
import styled from 'styled-components';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';
import groupBy from 'lodash/groupBy';
import { RecordOf } from 'immutable';
import moment from 'moment';
import { getValidationErrors } from '@melio/sizzers-js-common';
import get from 'lodash/get';
import trim from 'lodash/trim';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { qbcashError } from 'src/app/modules/funding-sources/constants';
import { BillRecord } from 'src/app/pages/bill/records';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { isRppsVendor } from 'src/app/pages/vendor-directory/utils';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  getPaymentMethodName,
  getPaymentMethodDisplayName,
  getPaymentMethodInstitutionName,
} from 'src/app/version-2/utils/paymentMethods.utils';
import {
  PaymentDeliveryStatusEnum,
  FundingSourceTypesEnum,
  TransactionTypesEnum,
  TransactionDirectionEnum,
  TransactionStatusEnum,
  RiskStatusEnum,
  ScreenModeEnum,
  FundingSourceOrigins,
} from 'src/app/version-2/model/enums';
import { FUNDING_DEFAULT_LOGO } from '../version-2/model/constants';
import { getAccountNumber4digits } from './bank-account';
import { getCardLogo, qbCashIcon } from './card';
import {
  BILL_STATUS,
  CONSTS,
  QBR_BILL_LIST_TAB_TYPE,
  PAYMENT_STATUS,
  BILL_MIN_AMOUNT,
  BILL_MAX_AMOUNT,
  RECEIVER_REFUSED_CREDIT_ENTRY,
  TAG_VARIANT,
  FAILED_PAYMENT_TYPE,
  PAYMENT_APPROVAL_STATUS,
  PAGINATION,
  CARD_TYPES,
} from './consts';
import { getCheckDepositedDate, getLatestPayment, getPaymentById } from './payments';
import { getDefaultTabSetting } from './tabs-utils';
import { MIFormattedCurrency, MIFormattedText } from './formatting';
import {
  BillStatusType,
  BillType,
  DeliveryDataType,
  DeliveryMethodType,
  EditableBillType,
  HeaderAction,
  PaymentType,
  QBCashStateType,
  QBRBillListType,
  ScheduledDataType,
  TabSettingsType,
  UserType,
  VendorType,
} from './types';
import { isManualBankAccountNotVerified } from './funding-sources';
import { encodeQuery } from './query-utils';
import { Permissions } from './permissions';
import { getBillsSearchPath } from 'src/app/utils/billsPath';
import { RISK_BILL_AMOUNT } from 'src/app/version-2/pages/batch-bulk/model/consts/riskBillAmount.consts';

const PAYMENT_PREFIX = 'payment';

const serializePaymentId = (billId: string, paymentId: string) =>
  `${PAYMENT_PREFIX}-${billId}-${paymentId}`;

const deserializePaymentId = (id: string): { billId: string; paymentId: string } => {
  if (!id?.toString().includes(PAYMENT_PREFIX)) {
    return {
      billId: id,
      paymentId: '',
    };
  }

  const ids = id.toString().split('-');

  if (ids.length === 3) {
    return {
      billId: ids[1],
      paymentId: ids[2],
    };
  }

  return {
    billId: '',
    paymentId: '',
  };
};

function getBillTag(bill: RecordOf<BillType> | BillType) {
  const latestPayment = getLatestPayment(bill.payments);

  return getBillPaymentTag(bill, latestPayment);
}

function getBillPaymentTag(bill: RecordOf<BillType> | BillType, payment?: PaymentType) {
  const isBillPaid = bill.status === BILL_STATUS.PAID;
  const paymentStatus = payment?.status;
  const approvalStatus = payment?.approvalDecisionStatus;
  const deliveryMethod = payment?.deliveryMethod;
  const deliverStatus = payment?.deliverStatus;

  if (deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD && isBillPaid) {
    if (
      paymentStatus === PAYMENT_STATUS.COMPLETED &&
      deliverStatus === PaymentDeliveryStatusEnum.CLEARED
    ) {
      return 'vcCompleted';
    }

    return 'vcSent';
  }

  if (payment?.manual) {
    return 'markedAsPaid';
  }

  if (paymentStatus === PAYMENT_STATUS.FAILED) {
    if (approvalStatus === PAYMENT_APPROVAL_STATUS.DECLINED) {
      return PAYMENT_APPROVAL_STATUS.DECLINED;
    }

    return PAYMENT_STATUS.FAILED;
  }

  if (approvalStatus === PAYMENT_APPROVAL_STATUS.PENDING) {
    return PAYMENT_APPROVAL_STATUS.PENDING;
  }

  if (isBillPaid) {
    if (payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
      const checkDepositedDate = getCheckDepositedDate(payment.transactions);

      return checkDepositedDate && TAG_VARIANT.DEPOSITED;
    }

    if (payment?.metadata?.achDeposited) {
      return TAG_VARIANT.ACH_DEPOSITED;
    }
  }

  return paymentStatus as string;
}

function getPartialPaymentBillTag(id: string, bill: RecordOf<BillType> | BillType) {
  const { paymentId } = deserializePaymentId(id);

  if (paymentId) {
    return getBillPaymentTag(bill, getPaymentById(bill.payments, paymentId));
  }

  return bill.status;
}

type Params = {
  bill: RecordOf<BillType>;
  paymentId?: string;
  isPartialPaymentsEnabled?: boolean;
  unpaidBill: boolean;
  permissions: Permissions;
  onRetryPayment: (paymentId: number) => void;
  onRetryPaymentDelivery: (paymentId: number, deliveryMethodId: number) => void;
  onChangeDeliveryMethod?: (paymentId: number) => void;
  onClickSupport(): void;
  onDecline(): void;
  onApprove(): void;
  goPayBill(): void;
  onTrackDelivery: () => void;
  isLoading: boolean;
  isEditMode: boolean;
  canSchedulePayment: boolean;
  isApproving: boolean;
  payment?: RecordOf<PaymentType>;
};

function getBillHeaderActionsOptions(params: Params): HeaderAction[] {
  const {
    bill,
    paymentId,
    isPartialPaymentsEnabled,
    unpaidBill,
    onClickSupport,
    isLoading,
    isEditMode,
    canSchedulePayment,
    onRetryPayment,
    onDecline,
    isApproving,
    onApprove,
    goPayBill,
    onTrackDelivery,
    permissions,
    onRetryPaymentDelivery,
    onChangeDeliveryMethod,
  } = params;
  const payment = isPartialPaymentsEnabled
    ? getPaymentById(bill.payments, paymentId as string)
    : getLatestPayment(bill.payments || []);

  if (payment) {
    const {
      id,
      approvalDecisionStatus,
      riskStatus,
      metadata,
      status,
      deliveryMethodId,
      deliveryMethod,
    } = payment;

    const collectMessagesToRetry = [
      'accountFrozen',
      'uncollectedFunds',
      'invalidTransaction',
      'bankAccountUnauthorized',
      'userBlockedMelioAccount',
      'exceedsWithdrawalAmountLimit',
    ];

    const supportAction = {
      action: onClickSupport,
      label: 'bills.actions.contactSupport',
      variant: CONSTS.BUTTON_VARIANT.TERTIARY,
      disabled: isLoading || isEditMode || !canSchedulePayment,
    };

    if (status === PAYMENT_STATUS.FAILED && metadata?.canUserRetry && unpaidBill) {
      const disabled = isLoading || isEditMode;

      if (
        metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER &&
        isRppsVendor(bill.vendor as VendorType)
      ) {
        return [supportAction];
      }

      if (metadata?.failureMessage === RECEIVER_REFUSED_CREDIT_ENTRY) {
        return [
          {
            action: () =>
              onRetryPaymentDelivery(
                id as unknown as number,
                deliveryMethodId as unknown as number
              ),
            label: 'bills.actions.resendPayment',
            variant: CONSTS.BUTTON_VARIANT.PRIMARY,
            disabled,
          },
        ];
      }

      if (metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER) {
        const missingDeliveryMethod =
          !deliveryMethod || (deliveryMethod && Boolean((deliveryMethod as any).deletedAt));
        const retryAction =
          missingDeliveryMethod && onChangeDeliveryMethod
            ? () => onChangeDeliveryMethod(id as unknown as number)
            : () =>
                onRetryPaymentDelivery(
                  id as unknown as number,
                  deliveryMethodId as unknown as number
                );

        return [
          {
            action: retryAction,
            label: 'bills.actions.retryPaymentDelivery',
            variant: CONSTS.BUTTON_VARIANT.PRIMARY,
            disabled,
          },
        ];
      }

      if (metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_PAYMENT) {
        return [
          {
            action: () => onRetryPayment(id as unknown as number),
            label: 'bills.actions.retryPayment',
            variant: CONSTS.BUTTON_VARIANT.PRIMARY,
            disabled,
          },
        ];
      }

      if (
        metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_COLLECT &&
        collectMessagesToRetry.includes(metadata?.failureMessage as string)
      ) {
        return [
          {
            action: () => onRetryPayment(id as unknown as number),
            label: 'bills.actions.retryPayment',
            variant: CONSTS.BUTTON_VARIANT.PRIMARY,
            disabled,
          },
        ];
      }

      return [supportAction];
    }

    if (riskStatus === RiskStatusEnum.DECLINED) {
      return [supportAction];
    }

    if (approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING && permissions.bills.approve()) {
      return [
        {
          action: onDecline,
          label: 'bills.actions.decline',
          variant: CONSTS.BUTTON_VARIANT.SECONDARY,
          iconClass: 'icon-close-icon',
          disabled: isApproving,
        },
        {
          action: onApprove,
          label: 'bills.actions.approve',
          variant: CONSTS.BUTTON_VARIANT.PRIMARY,
          iconClass: 'icon-notification-checkmark-icon',
          isProcessing: isApproving,
        },
      ];
    }

    if (
      approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED &&
      permissions.bills.reschedule()
    ) {
      return [
        {
          action: () => onRetryPayment(id as unknown as number),
          label: 'bills.actions.reschedule',
          variant: CONSTS.BUTTON_VARIANT.PAY,
          disabled: isLoading,
        },
      ];
    }

    if (unpaidBill) {
      if (isPartialPaymentsEnabled && status !== PAYMENT_STATUS.FAILED) {
        return [
          {
            action: goPayBill,
            label: 'bills.actions.pay',
            variant: CONSTS.BUTTON_VARIANT.PAY,
            disabled: isLoading || isEditMode || !canSchedulePayment,
          },
        ];
      }

      return [supportAction];
    }

    if (payment.checkTracks && payment.checkTracks[0]?.trackingUrl) {
      return [
        {
          action: onTrackDelivery,
          label: 'bills.actions.trackDelivery',
          variant: CONSTS.BUTTON_VARIANT.PRIMARY,
        },
      ];
    }

    return [];
  }

  return [
    {
      action: goPayBill,
      label: 'bills.actions.pay',
      variant: CONSTS.BUTTON_VARIANT.PAY,
      disabled: isLoading || isEditMode || !canSchedulePayment,
    },
  ];
}

const getCardLabel = (type) =>
  type === CARD_TYPES.CREDIT
    ? 'bills.form.paymentActivity.scheduledBill.scheduleMethodCreditCard'
    : 'bills.form.paymentActivity.scheduledBill.scheduleMethodDebitCard';

function getFundingSourceLabel(fundingSource: FundingSource | null) {
  if (get(fundingSource, 'origin') === FundingSourceOrigins.QBCASH) {
    return 'bills.form.paymentActivity.scheduledBill.scheduleMethodAchQBCash';
  }

  return get(fundingSource, 'fundingType') === FundingSourceTypesEnum.ACH
    ? 'bills.form.paymentActivity.scheduledBill.scheduleMethodAch'
    : getCardLabel(get(fundingSource, 'cardAccount.cardType', CARD_TYPES.CREDIT));
}

const paymentMethodLabels = {
  [BILL_STATUS.SCHEDULED]: {
    scheduleMethod: getFundingSourceLabel,
    deliveryMethod: {
      [CONSTS.DELIVERY_TYPE.ACH]:
        'bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod',
      [CONSTS.DELIVERY_TYPE.CHECK]:
        'bills.form.paymentActivity.scheduledBill.deliveryPaperCheckMethod',
    },
  },
  [BILL_STATUS.UNPAID]: {
    scheduleMethod: getFundingSourceLabel,
    deliveryMethod: {
      [CONSTS.DELIVERY_TYPE.ACH]:
        'bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod',
      [CONSTS.DELIVERY_TYPE.CHECK]:
        'bills.form.paymentActivity.scheduledBill.deliveryPaperCheckMethod',
    },
  },
  [BILL_STATUS.PAID]: {
    scheduleMethod: getFundingSourceLabel,
    deliveryMethod: {
      [CONSTS.DELIVERY_TYPE.ACH]: 'bills.form.paymentActivity.paidBill.deliveryBankTransferMethod',
      [CONSTS.DELIVERY_TYPE.CHECK]: 'bills.form.paymentActivity.paidBill.deliveryPaperCheckMethod',
    },
  },
};

function getMethod(billPaymentMethodLabels, fundingSource) {
  if (!billPaymentMethodLabels || !fundingSource) {
    return '';
  }

  return billPaymentMethodLabels.scheduleMethod(fundingSource);
}

function getQBBalanceProps(
  billStatus: BillStatusType,
  qbCashState?: QBCashStateType
): { label?: string; values?: QBCashStateType } {
  const isUnauthorized = qbCashState?.error?.response?.status === qbcashError.unauthorized;

  if (isUnauthorized) {
    return getQBBalanceUnauthorizedProps(billStatus);
  }

  if (!qbCashState?.balance) {
    return {
      label: '',
    };
  }

  return {
    label: 'bills.form.paymentActivity.scheduledBill.balance',
    values: qbCashState,
  };
}

function getQBBalanceUnauthorizedProps(billStatus) {
  switch (billStatus) {
    case BILL_STATUS.PAID:
      return {};

    default:
      return {
        label: 'bills.form.paymentActivity.scheduledBill.unauthorizedBalance',
      };
  }
}

function getPaymentActivityScheduleData(
  billStatus: (typeof BILL_STATUS)[keyof typeof BILL_STATUS],
  scheduledDate: string | Date,
  fundingSource: FundingSource | null,
  isEditable = false,
  onEdit?: () => void,
  onEditDate?: () => void,
  isRecurring?: boolean | null,
  site?: any,
  qbCashState?: QBCashStateType
): ScheduledDataType {
  const billPaymentMethodLabels = paymentMethodLabels[billStatus];
  const displayName = fundingSource ? getPaymentMethodDisplayName({ fundingSource }) : '';
  const institutionName = fundingSource ? getPaymentMethodInstitutionName({ fundingSource }) : '';
  const cardType = get(fundingSource, 'cardAccount.cardType', '');
  const network = get(fundingSource, 'cardAccount.network', '').toUpperCase();
  const bankIcon =
    fundingSource &&
    fundingSource.fundingType === FundingSourceTypesEnum.ACH &&
    fundingSource.logo &&
    fundingSource.logo !== FUNDING_DEFAULT_LOGO ? (
      <img
        src={`data:image/jpeg;base64,${fundingSource.logo}`}
        alt={getPaymentMethodName({ fundingSource })}
      />
    ) : (
      <div className="icon-bank-icon" />
    );
  let icon = bankIcon;

  if (fundingSource && [CARD_TYPES.CREDIT, CARD_TYPES.DEBIT].includes(cardType)) {
    icon = getCardLogo(cardType, network);
  }

  let info: string | React.ReactNode = '';
  const title =
    billStatus === BILL_STATUS.PAID
      ? 'bills.form.paymentActivity.paidFromMy'
      : 'bills.form.paymentActivity.payFromMy';
  let description;

  if (isRecurring) {
    description = 'bills.form.paymentActivity.recurringFutureProcessedDate';
  } else if (billStatus === BILL_STATUS.PAID) {
    description = 'bills.form.paymentActivity.processedDate';
  } else {
    description = 'bills.form.paymentActivity.futureProcessedDate';
  }

  if (fundingSource && fundingSource.bankAccount) {
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);

    if (institutionName) {
      info = `${displayName} (${institutionName}, ...${cutAccountNumber})`;
    } else {
      info = `${displayName} (...${cutAccountNumber})`;
    }
  } else if (fundingSource && fundingSource.cardAccount) {
    info = `${fundingSource.cardAccount.network} (...${fundingSource.cardAccount.card4digits})`;
  }

  if (fundingSource?.origin === FundingSourceOrigins.QBCASH) {
    const qbBalanceProps = getQBBalanceProps(billStatus, qbCashState);

    info = <MIFormattedText {...qbBalanceProps} />;
    icon = qbCashIcon;
  }

  let hint = '';

  if (isManualBankAccountNotVerified(fundingSource)) {
    hint = 'settings.paymentMethods.notVerifiedAccountHint';
  }

  return {
    title,
    date: scheduledDate,
    method: getMethod(billPaymentMethodLabels, fundingSource),
    info,
    hint,
    icon,
    dateIcon: 'icon-scheduled-icon',
    description,
    isEditable,
    onEdit,
    onEditDate,
  };
}

export function getFormattedCheckSerial(payment: PaymentType) {
  const checkTransactions = (payment?.transactions || []).filter(
    (transaction) =>
      transaction.transactionType === TransactionTypesEnum.CHECK &&
      transaction.transactionDirection === TransactionDirectionEnum.CREDIT &&
      transaction.status === TransactionStatusEnum.COMPLETED
  );

  if (checkTransactions.length === 0) {
    return '';
  }

  // Assuming there is 1 or more check credit transaction per payment, showing the last
  const rawTransactionData = checkTransactions[checkTransactions.length - 1].rawData || {};

  if (!rawTransactionData.checkSerialNumber) {
    return '';
  }

  return ` #${rawTransactionData.checkSerialNumber}`;
}

function getAdditionalDescription(isRecurring, isRequest, deliveryType) {
  if (isRecurring || deliveryType !== CONSTS.DELIVERY_TYPE.ACH) {
    return '';
  }

  if (isRequest) {
    return 'bills.form.paymentActivity.deliveryMethodAdditionalDescriptionPayee';
  }

  return 'bills.form.paymentActivity.deliveryMethodAdditionalDescription';
}

function getPaymentActivityDeliveryData(
  payment: PaymentType,
  billStatus: (typeof BILL_STATUS)[keyof typeof BILL_STATUS] | undefined,
  isRecurring: boolean,
  isFirstPaymentLate: boolean,
  vendorName: string,
  deliveryEta: string,
  deliveryMethod: RecordOf<DeliveryMethodType> | null,
  isEditable = false,
  onEdit?: () => void,
  onEditDate?: () => void,
  isRequest?: boolean | null
): DeliveryDataType {
  const billPaymentMethodLabels = paymentMethodLabels[billStatus || BILL_STATUS.SCHEDULED];
  const deliveryType = get(deliveryMethod, 'deliveryType');
  const method =
    billPaymentMethodLabels && deliveryMethod && deliveryType
      ? billPaymentMethodLabels.deliveryMethod[deliveryType]
      : '';
  const info = deliveryMethod ? deliveryMethod.getDeliveryInfo(vendorName) : '';
  const icon =
    deliveryType === CONSTS.DELIVERY_TYPE.ACH ? (
      <div className="icon-bank-icon" />
    ) : (
      <div className="icon-check-icon" />
    );
  let title = '';
  let description =
    billStatus === BILL_STATUS.PAID
      ? 'bills.form.paymentActivity.deliveryTitleAt'
      : 'bills.form.paymentActivity.deliveryTitleETA';
  const date = deliveryEta;
  const maxDate = payment.maxDeliveryEta;
  let additionalHint = {};

  if (deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL) {
    description = 'bills.form.paymentActivity.deliveryVirtualDescription';
    additionalHint = {
      label: isRecurring
        ? 'bills.form.paymentActivity.deliveryVirtualRecurringAdditionalHint'
        : 'bills.form.paymentActivity.deliveryVirtualAdditionalHint',
    };
  } else if (isRecurring) {
    description = 'requests.form.paymentActivity.paymentETA';

    if (isFirstPaymentLate) {
      additionalHint = { label: 'settings.paymentMethods.recurringHint' };
    }
  }

  title =
    billStatus === BILL_STATUS.PAID
      ? 'bills.form.paymentActivity.vendorReceived'
      : 'bills.form.paymentActivity.vendorReceive';

  return {
    title,
    date,
    maxDate,
    method,
    info,
    icon,
    dateIcon: 'icon-eta-cal',
    description,
    additionalDescription: getAdditionalDescription(isRecurring, isRequest, deliveryType),
    deliveryPreference: payment.deliveryPreference as string,
    isEditable,
    onEdit,
    onEditDate,
    formattedCheckSerial: getFormattedCheckSerial(payment),
    origin: null,
    type: deliveryMethod && deliveryMethod.deliveryType,
    paperCheck: deliveryMethod && deliveryMethod.paperCheck,
    additionalHint,
  };
}

type BillValidationErrorType = {
  vendorId: string;
  totalAmount: string | React.ReactNode;
  invoiceNumber: string;
  dueDate: string;
};

function getBillValidationErrors(
  bill: EditableBillType | RecordOf<BillType>,
  isRecurringBill?: boolean,
  isPartialPaymentsEnabled?: boolean
) {
  const validationFields = ['vendorId', 'totalAmount', 'invoiceNumber', 'dueDate', 'note'];

  if (isRecurringBill) {
    validationFields.push('occurrences');
    validationFields.push('frequency');
  }

  let validationErrors = {} as BillValidationErrorType;

  validationErrors = getValidationErrors('bill', bill, validationFields, {
    context: { isRecurringBill },
  });

  if (isPartialPaymentsEnabled && !!BillRecord(bill as any).payments.length) {
    const paymentsAmount = sumBy(BillRecord(bill as any).payments, 'amount');

    if (bill.totalAmount < Number(paymentsAmount) && !validationErrors.totalAmount) {
      validationErrors.totalAmount = (
        <Block>
          <MIFormattedText
            label="bills.form.partialPayments.errors.editAmount"
            values={{
              paymentsAmount: <MIFormattedCurrency value={paymentsAmount} />,
            }}
          />
        </Block>
      );
    }
  }

  return validationErrors;
}

const Block = styled.div`
  display: block;
`;

function isManuallyPaid(billStatus: BillStatusType, hasManualPayment: boolean) {
  return billStatus === BILL_STATUS.PAID && hasManualPayment;
}

const TYPE_FILTER_MAP = {
  [QBR_BILL_LIST_TAB_TYPE.COMPLETED]: {
    start: PAGINATION.DEFAULT_START,
    limit: PAGINATION.DEFAULT_LIMIT,
    type: QBR_BILL_LIST_TAB_TYPE.COMPLETED,
  },
  [QBR_BILL_LIST_TAB_TYPE.OPEN]: {
    start: PAGINATION.DEFAULT_START,
    limit: PAGINATION.DEFAULT_LIMIT,
    type: QBR_BILL_LIST_TAB_TYPE.OPEN,
  },
};

const getQBRBillsDefaultFilters = (type: QBRBillListType) => TYPE_FILTER_MAP[type];

const getVendorBillSearchTab = (tab: BillStatusType, vendorId: string) =>
  encodeQuery({ vendorId }, [], getBillsSearchPath(tab));

const getBillsTabs = (
  statusesList: BillStatusType[],
  selectedTabBillStatus: BillStatusType,
  type: string,
  baseSearch = ''
): TabSettingsType[] =>
  statusesList.map((status: BillStatusType) => {
    const defaultTabSetting = getDefaultTabSetting(
      status,
      type,
      status === selectedTabBillStatus,
      baseSearch
    );
    const billsListSearchPath = getBillsSearchPath(status, baseSearch);

    return {
      ...defaultTabSetting,
      to: {
        search: billsListSearchPath,
      },
    };
  });

const productReceivedOptions = [
  {
    id: '1',
    label: 'categoryList.yes',
  },
  {
    id: '0',
    label: 'bills.form.receivedProduct.no',
  },
];

const isBillAmountRequiresInvoiceFile = (amount: string) =>
  Number(amount) >= RISK_BILL_AMOUNT.REQUIRES_INVOICE_FILE;
const isBillAmountRequiresGoodsConfirmation = (amount: string) =>
  Number(amount) >= RISK_BILL_AMOUNT.REQUIRES_GOODS_CONFIRMATION_10K;

const getDefaultMemo = (invoiceNumber: string, intuitAcctNum: string) => {
  const isEmptyAcctNumber = isEmpty(trim(intuitAcctNum));

  if (invoiceNumber && !isEmptyAcctNumber) {
    return `Account no. ${intuitAcctNum} | Invoice no. ${invoiceNumber}`;
  }

  if (!invoiceNumber && !isEmptyAcctNumber) {
    return `Account no. ${intuitAcctNum}`;
  }

  if (invoiceNumber && isEmptyAcctNumber) {
    return `Invoice no. ${invoiceNumber}`;
  }

  return '';
};

const sortFailedBills = (a: BillType, b: BillType) =>
  moment(getLatestPayment(a.payments)?.scheduledDate || a.invoiceDate).diff(
    moment(getLatestPayment(b.payments)?.scheduledDate || b.invoiceDate)
  );

const showMarkAsPaidBills = (bill: BillType, permissions: Permissions) => {
  const unpaidBill = bill.status === BILL_STATUS.UNPAID;
  const hasManualPayment = bill.payments.some((p) => p.manual);
  const manuallyPaid = isManuallyPaid(bill.status, hasManualPayment);

  return (
    ((unpaidBill && !bill.isVendorRequest()) || manuallyPaid) && permissions.bills.markAsPaid()
  );
};

const showMarkAsPaidPartialPayment = (
  bill: BillType,
  permissions: Permissions,
  isInboxTab: boolean
) => isInboxTab && !bill.isVendorRequest() && permissions.bills.markAsPaid();

const isBillHasPartialPayments = (bill: BillType): boolean => {
  if (isNil(bill)) {
    return false;
  }

  const billAmount = convertCurrencyToNumber(bill.totalAmount);

  return (
    !isEmpty(bill.payments) && bill.payments?.every((p) => p && p?.amount && p.amount < billAmount)
  );
};

type ActionOption = {
  label: string;
  action: () => void;
  mobile?: boolean;
  disabled?: boolean;
  negative?: boolean;
};

const getActionOptions = ({
  bill,
  mode,
  addMarkAsPaid,
  permissions,
  currentUser,
  actions,
}: {
  bill: RecordOf<BillType>;
  mode: string;
  addMarkAsPaid: boolean;
  permissions: Permissions;
  currentUser: UserType;
  actions: Record<string, (params?) => void>;
}) => {
  const opts: ActionOption[] = [];

  if (bill.status === BILL_STATUS.PAID && addMarkAsPaid) {
    opts.push({
      label: 'bills.actions.undoMarkAsPaidMobile',
      action: actions.onMarkBillAsUnpaid,
    });
  } else if (bill.status === BILL_STATUS.UNPAID) {
    if (addMarkAsPaid && permissions.bills.markAsPaid()) {
      opts.push({
        label: 'bills.actions.markAsPaid',
        action: actions.onMarkBillAsPaid,
      });
    }

    const editDisabled = bill.payments.length > 0;

    if (mode === ScreenModeEnum.VIEW && permissions.bills.update(currentUser, bill)) {
      opts.push({
        label: 'bills.actions.edit',
        action: actions.onToggleMode,
        disabled: editDisabled,
      });
    }

    if (permissions.bills.delete(currentUser, bill)) {
      opts.push({
        label: 'bills.actions.delete',
        action: actions.onDeleteClicked,
        negative: true,
      });
    }
  }

  return opts;
};

const convertPartialPaymentStatusToBillStatus = (paymentStatus: string) => {
  switch (paymentStatus) {
    case PAYMENT_STATUS.COMPLETED:
    case PAYMENT_STATUS.IN_PROGRESS:
      return BILL_STATUS.PAID;
    case PAYMENT_STATUS.SCHEDULED:
    case PAYMENT_STATUS.BLOCKED:
      return BILL_STATUS.SCHEDULED;
    default:
      return BILL_STATUS.UNPAID;
  }
};

const getActionOptionsPartialPayments = ({
  bill,
  mode,
  addMarkAsPaid,
  permissions,
  currentUser,
  isUnpaidInboxBill,
  actions,
}: {
  bill: RecordOf<BillType>;
  mode: string;
  addMarkAsPaid: boolean;
  permissions: Permissions;
  currentUser: any;
  isUnpaidInboxBill: boolean;
  actions: Record<string, (params?) => void>;
}) => {
  const opts: {
    label: string;
    action: (patams?) => void;
    mobile?: boolean;
    disabled?: boolean;
    negative?: boolean;
  }[] = [];
  const paymentsBlockedForDeletion = bill.payments.some(
    (p) => p.status === PAYMENT_STATUS.COMPLETED || p.status === PAYMENT_STATUS.IN_PROGRESS
  );
  const validBalance = bill.payments.every((p) => !p.balance);
  const isBillDeletionAvailable =
    !bill.internalBill && isEmpty(paymentsBlockedForDeletion) && validBalance;

  if (!isBillHasPartialPayments(bill) && bill.status === BILL_STATUS.PAID && addMarkAsPaid) {
    opts.push({
      label: 'bills.actions.undoMarkAsPaidMobile',
      action: actions.onMarkBillAsUnpaid,
    });
  }

  if (isUnpaidInboxBill) {
    if (!bill.isVendorRequest() && permissions.bills.markAsPaid()) {
      opts.push({
        label: 'bills.actions.markAsPaid',
        action: actions.onMarkBillAsPaid,
      });
    }

    if (mode === ScreenModeEnum.VIEW && permissions.bills.update(currentUser, bill)) {
      opts.push({
        label: 'bills.actions.edit',
        action: actions.onToggleMode,
      });
    }

    if (isBillDeletionAvailable && permissions.bills.delete(currentUser, bill)) {
      opts.push({
        label: 'bills.actions.delete',
        action: actions.onDeleteClicked,
        negative: true,
      });
    }
  }

  return opts;
};

const getStatusApiParam = (status: BILL_STATUS, isPartialPaymentsEnabled: boolean): string => {
  if (isPartialPaymentsEnabled && status === 'unpaid') {
    return 'inbox';
  }

  return status;
};

const groupPartialPaymentsByBill = (bills: BillType[]) =>
  values(groupBy(bills, (bill) => deserializePaymentId(bill.id)?.billId));

const getBillBalance = (bill: BillType, excludePaymentIds: string[]) => {
  const filteredPayments = bill.payments.filter(
    (payment) => !excludePaymentIds.includes(payment.id)
  );

  return bill.totalAmount - (bill.externallyPaid || 0) - sumBy(filteredPayments, 'amount');
};

const getPartialBillId = (bill: Partial<BillType>) =>
  `${bill?.id || 0}-${bill?.payments?.length || 0}`;

const hasMaximumAmountLimit = (amount: number) => amount >= BILL_MAX_AMOUNT;
const hasOneBillWithMaximumAmountLimit = (bills: Record<string, any>[]): boolean => {
  if (!bills?.length) return false;

  return !!bills.find((item) => hasMaximumAmountLimit(item?.bill?.totalAmount));
};

const hasMinimumAmountLimit = (amount: number) => amount <= BILL_MIN_AMOUNT;

const isRecurringBill = (bill: BillType): boolean =>
  !!(bill?.recurringBillId && bill?.recurringBill);
const isRecurringRecord = (bill: BillType): boolean =>
  !!(bill?.recurringBill?.frequency && bill?.recurringBill?.dueDate);

const isBillSynced = (bill) => bill.originId !== '-1';

const isFirstPaymentLate = ({ bill, payment }: { bill: BillType; payment: PaymentType }) => {
  const dueDate = moment(bill.dueDate).format('YYYY-MM-DD');
  const deliveryEta = moment(payment.deliveryEta).format('YYYY-MM-DD');

  return deliveryEta > dueDate;
};

export {
  getPaymentActivityScheduleData,
  getPaymentActivityDeliveryData,
  isManuallyPaid,
  getBillValidationErrors,
  getBillsTabs,
  getFundingSourceLabel,
  productReceivedOptions,
  isBillAmountRequiresInvoiceFile,
  isBillAmountRequiresGoodsConfirmation,
  getBillTag,
  getBillHeaderActionsOptions,
  getVendorBillSearchTab,
  getQBRBillsDefaultFilters,
  getDefaultMemo,
  sortFailedBills,
  getQBBalanceProps,
  showMarkAsPaidBills,
  showMarkAsPaidPartialPayment,
  isBillHasPartialPayments,
  getPartialPaymentBillTag,
  serializePaymentId,
  deserializePaymentId,
  getBillPaymentTag,
  getActionOptions,
  getActionOptionsPartialPayments,
  getStatusApiParam,
  convertPartialPaymentStatusToBillStatus,
  groupPartialPaymentsByBill,
  getBillBalance,
  getPartialBillId,
  hasMaximumAmountLimit,
  hasMinimumAmountLimit,
  hasOneBillWithMaximumAmountLimit,
  isRecurringBill,
  isRecurringRecord,
  isBillSynced,
  isFirstPaymentLate,
};
