import React from 'react';
import { Box, Tooltip } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import { HOLIDAYS_WARNING_ICON_VARIANT } from 'src/app/utils/consts';
import { useBreak } from 'src/app/hoc';

type Props = {
  tooltipLabel?: string;
  customIconStyles?: { [key: string]: any };
  variant?: string;
};

const HolidaysWarning = ({ tooltipLabel, customIconStyles, variant, ...rest }: Props) => {
  let iconStyles;
  const { isMobile } = useBreak();

  switch (variant) {
    case HOLIDAYS_WARNING_ICON_VARIANT.WARNING:
      iconStyles = {
        color: '#EAB719',
        className: 'icon-warning-icon',
      };
      break;
    case HOLIDAYS_WARNING_ICON_VARIANT.INFO:
      iconStyles = {
        color: '#6B6C72',
        className: 'icon-warning-hollow-icon',
      };
      break;
    default:
      iconStyles = {
        color: '#6B6C72',
        className: 'icon-warning-hollow-icon',
      };
  }

  return (
    <Tooltip
      placement="top"
      label={tooltipLabel && <MIFormattedText label={tooltipLabel} />}
      {...rest}
    >
      <Box
        as="i"
        fontSize="1.7rem"
        ml="0.5rem"
        flexShrink={0}
        onClick={(e) => {
          if (isMobile) {
            e.stopPropagation();
          }
        }}
        {...iconStyles}
        {...customIconStyles}
      />
    </Tooltip>
  );
};

export { HolidaysWarning };
