import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import isNaN from 'lodash/isNaN';
import { useModal } from 'src/app/helpers/react/useModal';
import successImage from 'src/app/images/icons/icons_check.svg';
import styled from 'styled-components';
import dashboardLocations from 'src/app/pages/dashboard/locations';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';
import QBOMIDialog from 'src/app/components/common/QBOMIDialog';
import {
  CONSTS,
  BILL_STATUS,
  EXPEDITED_DELIVERY_TYPES,
  NOTIFICATION_CARD_TYPES,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import analytics from 'src/app/services/analytics';
import { AreaLoader } from '@melio/billpay-design-system';
import { getIsLoading } from 'src/app/redux/payBillWizard/selectors';
import { WizardIllustration } from 'src/app/components/layout/WizardElements';
import paymentStore from 'src/app/modules/payments/payment-store';
import {
  WizardActionsRowContainer,
  WizardLinkContainer,
  WizardButtonContainer,
  WizardOrLine,
  WizardOptionLine,
} from 'src/app/components/layout/QBOWizardElements';
import MIButton from 'src/app/components/common/MIButton';
import MILink from 'src/app/components/common/MILink';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { MIFormattedCurrency, MIFormattedDate } from 'src/app/utils/formatting';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import { QBCashStateType, VendorType } from 'src/app/utils/types';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { shouldAskForVendorEmail } from 'src/app/modules/vendors/vendor-email/utils/should-ask-for-vendor-email';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourceOrigins } from 'src/app/version-2/model/enums';
import { JustPayCollectVendorEmailModalMessage } from './JustPayCollectVendorEmailModalMessage';
import { useCheckOwnedVendorExists } from './hooks/useCheckOwnedVendorExists';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';

type ActionType = {
  label: string;
  onClick: () => void;
};

const eventPage = 'just-pay';

type GetHeadTextsParams = {
  payment: JustPayPaymentType;
  hasVendorEmail: boolean;
  vendorName: string;
  fundingSource: FundingSource;
};

export const getHeadTexts = ({
  payment,
  fundingSource,
  hasVendorEmail,
  vendorName,
}: GetHeadTextsParams) => {
  const isExpedited =
    payment.suggestedDates.deliveryPreferenceType !== null
      ? EXPEDITED_DELIVERY_TYPES.includes(payment.suggestedDates.deliveryPreferenceType ?? '')
      : false;

  const title = 'bills.pay.payBillSuccess.titles.regular';
  let subtitle = hasVendorEmail
    ? 'bills.pay.payBillSuccess.subtitles.regularWithNotify'
    : 'bills.pay.payBillSuccess.subtitles.regular';

  if (fundingSource.origin === FundingSourceOrigins.QBCASH) {
    subtitle = hasVendorEmail
      ? 'bills.pay.payBillSuccess.subtitles.qbCashWithNotify'
      : 'bills.pay.payBillSuccess.subtitles.qbCash';
  }

  if (isExpedited) {
    subtitle = 'bills.pay.payBillSuccess.subtitles.expedited';
  }

  const textValues = {
    amount: <MIFormattedCurrency key="amount" value={payment?.amount?.toString() || null} />,
    vendorName,
    processDate: (
      <MIFormattedDate key="scheduledDate" date={payment.suggestedDates.scheduledDate} />
    ),
  };

  return { title, subtitle, textValues };
};

export const JustPaySuccess = () => {
  const { fundingSource, payment, vendor } = useJustPayStore() as {
    fundingSource: FundingSource;
    payment: JustPayPaymentType;
    vendor: VendorType;
  };
  const [historyPush] = useHistoryWithOrgId();

  const isLoading = useSelector(getIsLoading);
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );

  const paymentStoreActions = useStoreActions(paymentStore);
  const vendorId = vendor?.id;
  const [isOwnedVendorExists, isGettingOwnedVendorData] = useCheckOwnedVendorExists({
    vendorId,
    vendorEmail: vendor?.contactEmail,
    eventPage,
  });

  const vendorName = vendor.companyName;
  const hasVendorEmail = isOwnedVendorExists ? true : !!vendor.contactEmail;
  const shouldShowNotifyVendorButton = shouldAskForVendorEmail({
    isOwnedVendorExists,
    vendorContactEmail: vendor?.contactEmail,
    deliveryType: payment?.deliveryMethodType,
  });

  const analyticsProps = {
    vendorId,
    trackingBillId: payment?.trackingBillId,
  };

  const CollectSuccessModal = () => {
    const handleCollectVendorEmailSuccessModalOk = async () => {
      analytics.trackAction('success-vendor-email-modal-closed', analyticsProps);

      goBillsList();
    };

    return (
      <QBOMIDialog
        type={DIALOG_TYPE.CONFIRM}
        variant={DIALOG_VARIANTS.SUCCESS}
        title="bills.pay.collectVendorEmailModal.successTitle"
        titleValues={{ vendorName }}
        image={successImage}
        subtitle="bills.pay.collectVendorEmailModal.successSubTitle"
        okButtonText="bills.pay.collectVendorEmailModal.confirm"
        onOkAction={handleCollectVendorEmailSuccessModalOk}
        onCancelAction={handleCollectVendorEmailSuccessModalOk}
        showCancel={false}
      />
    );
  };

  const [CollectVendorEmailSuccessModal, showCollectVendorEmailSuccessModal] = useModal(
    CollectSuccessModal,
    {
      modalName: 'successCollectEmail',
    }
  );

  const [CollectVendorEmailModal, showCollectVendorEmailModal] = useModal(
    JustPayCollectVendorEmailModalMessage,
    {
      onSuccess: showCollectVendorEmailSuccessModal,
      payment,
      vendor,
      eventPage,
      modalName: 'collectEmail',
    }
  );

  const goBillsList = () => {
    const defaultFilters = getBillsDefaultFilters(BILL_STATUS.SCHEDULED);

    analytics.trackAction('done-btn', analyticsProps);

    paymentStoreActions.justPay.justPayWizard.clear();

    historyPush({
      path: dashboardLocations.bills,
      query: defaultFilters,
    });
  };

  const collectVendorEmail = () => {
    showCollectVendorEmailModal();
    analytics.trackAction('notify-my-vendor', {
      screenName: 'success',
      ...analyticsProps,
    });
  };

  const { title, subtitle, textValues } = getHeadTexts({
    payment,
    fundingSource: fundingSource as any,
    hasVendorEmail,
    vendorName,
  });

  useEffect(() => {
    shouldShowNotifyVendorButton &&
      analytics.track(eventPage, 'exposed-to-notify-my-vendor-button', {
        screenName: 'success',
        ...analyticsProps,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowNotifyVendorButton]);

  if (isGettingOwnedVendorData) {
    return <AreaLoader />;
  }

  const actions: ActionType[] = [];

  if (shouldShowNotifyVendorButton) {
    actions.push({
      label: 'bills.pay.payBillSuccess.buttonLabel.notifyMyVendor',
      onClick: collectVendorEmail,
    });
  }

  actions.push({
    label: 'bills.pay.payBillSuccess.buttonLabel.done',
    onClick: goBillsList,
  });

  const [firstAction, secondAction] = actions;
  const showQbcashNotification =
    fundingSource.origin === FundingSourceOrigins.QBCASH && payment?.amount <= qbCashState.balance;

  return (
    <Wrapper>
      {CollectVendorEmailModal}
      {CollectVendorEmailSuccessModal}
      {isNaN(payment?.amount) ? (
        <></>
      ) : (
        <QBOSuccessLayout
          illustration={successImage}
          title={title}
          text={subtitle}
          textValues={textValues}
          isLoading={isLoading}
          hideHeader
        >
          {showQbcashNotification && (
            <QBONotificationCardContainer>
              <QBONotificationCard
                type={NOTIFICATION_CARD_TYPES.WARNING}
                subtitle={{
                  label: 'bills.pay.payBillSuccess.subtitles.notification',
                  values: { processDate: textValues.processDate },
                }}
              />
            </QBONotificationCardContainer>
          )}

          <WizardActionsRowContainer>
            {firstAction && (
              <WizardButtonContainer>
                <MIButton
                  label={firstAction.label}
                  variant={CONSTS.BUTTON_VARIANT.PRIMARY}
                  onClick={firstAction.onClick}
                  isProcessing={isLoading}
                  fullWidth={actions.length > 1}
                />
              </WizardButtonContainer>
            )}

            {secondAction && (
              <>
                <QBOWizardOrLine />

                <WizardLinkContainer>
                  <QBOMILink to={secondAction.onClick} label={secondAction.label} />
                </WizardLinkContainer>
              </>
            )}
          </WizardActionsRowContainer>
        </QBOSuccessLayout>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  ${WizardIllustration} {
    height: 10rem;
    margin-bottom: 2rem;
  }

  ${WizardActionsRowContainer} {
    flex-direction: column;
    align-self: auto;
    width: 100%;
  }

  ${WizardButtonContainer} {
    &:first-child {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`;

const QBONotificationCardContainer = styled.div`
  display: block;
  text-align: left;
  margin-bottom: 4rem;
`;

const QBOMILink = styled(MILink)`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

const QBOWizardOrLine = styled(WizardOrLine)`
  margin-bottom: 2rem;
  margin-top: 1rem;

  ${WizardOptionLine} {
    &:first-child {
      margin-right: 0;
    }
  }
`;
