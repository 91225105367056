import React from 'react';
import { useIntl } from 'react-intl';
import { Text } from '@melio/billpay-design-system';
import { DeliveryMethodImage } from 'src/app/version-2/pages/batch-bulk/renderers/DeliveryMethod/DeliveryMethod.renderer.styles';

export const DeliveryMethodMenuItem = ({ deliveryMethodItem }) => {
  const { formatMessage } = useIntl();

  const {
    meta: {
      imageSrc,
      description: { label, values },
    },
  } = deliveryMethodItem;

  return (
    <>
      <DeliveryMethodImage src={imageSrc} />
      <Text textStyle="ds.body3">{formatMessage({ id: label }, values)}</Text>
    </>
  );
};
