import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Box, SmartIcon } from '@melio/billpay-design-system';
import { analytics, QBOLayoutPage } from 'src/app/version-2/externals';
import { ReactComponent as QBLogo } from 'src/app/version-2/images/qb-logo.svg';
import {
  ALREADY_FILLED_PAGE_EVENT_NAME,
  APPLICATION_RECEIVED_EVENT_NAME,
  GLOBAL_OPT_IN_PAGE,
} from 'src/app/version-2/pages/global-opt-in-details/model/consts/GlobalOptInAnalytics.consts';
import {
  cardStyle,
  logoStyle,
  successSubtitleStyle,
  successTitleStyle,
} from './AlreadyFilledPage.styles';

export const AlreadyFilled = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    analytics.page(GLOBAL_OPT_IN_PAGE, ALREADY_FILLED_PAGE_EVENT_NAME);
    analytics.track(GLOBAL_OPT_IN_PAGE, APPLICATION_RECEIVED_EVENT_NAME, {
      retry_to_submit: false,
    });
  }, []);

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success-top" innerSize={53}>
      <Box __css={logoStyle}>
        <QBLogo />
      </Box>
      <Box __css={cardStyle}>
        <SmartIcon type="success-outline" size="4.8rem" />
        <Box as="h1" __css={successTitleStyle}>
          {formatMessage({ id: 'vendors.globalOptInCandidates.alreadyFilled.title' })}
        </Box>
        <Box as="p" __css={successSubtitleStyle}>
          {formatMessage({ id: 'vendors.globalOptInCandidates.alreadyFilled.subTitle' })}
        </Box>
      </Box>
    </QBOLayoutPage>
  );
};
