import {
  ContentContainer,
  TitleContainer as Title,
} from 'src/app/components/common/ModalMessage/ModalMessage.styles';
import { ModalMessage } from 'src/app/components/common/ModalMessage/ModalMessage';
import styled from 'styled-components';

export const ButtonContainer = styled('div')`
  display: flex;
  gap: 3rem;
  justify-content: flex-end;
  margin-top: 4rem;
`;

export const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 0;
`;

export const ModalMessageStyled = styled(ModalMessage)`
  padding: 4rem;
  ${Title} {
    margin-bottom: 0;
  }
  ${ContentContainer} {
    margin-bottom: 0;
    margin-top: 3rem;
  }
`;
