import React from 'react';
import { MultiLineSelectBox } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  onClick: VoidFunction;
  email?: string;
};

export const VirtualDeliveryMethodOption = ({ onClick, email }: Props) => (
  <MultiLineSelectBox onClick={onClick}>
    <MultiLineSelectBox.TopContentWrapper>
      <MultiLineSelectBox.Icon name="icon-email-icon" />
      <MultiLineSelectBox.HeadingWrapper>
        <MultiLineSelectBox.Title>
          <MIFormattedText label="bills.pay.virtualCardRecovery.unilateral.title" />
        </MultiLineSelectBox.Title>
        <MultiLineSelectBox.Description>
          <MIFormattedText label="bills.pay.virtualCardRecovery.unilateral.description" />
        </MultiLineSelectBox.Description>
      </MultiLineSelectBox.HeadingWrapper>
    </MultiLineSelectBox.TopContentWrapper>

    {email ? (
      <>
        <MultiLineSelectBox.Separator />

        <MultiLineSelectBox.Info>{email}</MultiLineSelectBox.Info>
      </>
    ) : null}
  </MultiLineSelectBox>
);
