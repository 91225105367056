import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useApi } from 'src/app/hoc/useApi';
import { getOrgId } from 'src/app/redux/user/selectors';
import deliveryApi from 'src/app/services/api/delivery';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { OptionalDeliveryMethodsType, DeliveryOptionType } from 'src/app/utils/types';
import { isManualBankAccountNotVerified } from 'src/app/utils/funding-sources';
import { CONSTS, PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { DeliveryDate } from 'src/app/utils/dates';
import { PaymentDeliveryOptions } from 'src/app/components/common/PaymentDeliveryOptions';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  fundingSource?: FundingSource;
  scheduledDate: Date;
  totalAmount: string;
  deliveryDate: Date;
  maxDeliveryDate: Date | null;
  deliveryMethodType: OptionalDeliveryMethodsType;
  deliveryOptions: DeliveryOptionType[];
  deliveryPreference?: string;
  dueDate?: Date | null;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    type: string
  ) => void;
};

type ApprovalDecisionStatusResponse = {
  approvalDecisionStatus: PAYMENT_APPROVAL_STATUS;
};

const PaymentDatePickerInfo = ({
  fundingSource,
  scheduledDate,
  totalAmount,
  deliveryDate,
  maxDeliveryDate,
  deliveryMethodType,
  deliveryOptions,
  deliveryPreference,
  dueDate,
  fundingSourceType,
  onSelectDeliveryOption,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const [getApprovalDecisionStatus, approvalDecisionStatusResult] = useApi<
    [string, string],
    ApprovalDecisionStatusResponse
  >(deliveryApi.getApprovalDecisionStatus);
  const isApprovalRequired =
    approvalDecisionStatusResult?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethodType === CONSTS.DELIVERY_TYPE.VIRTUAL;

  useEffect(() => {
    getApprovalDecisionStatus(orgId, totalAmount);
  }, [orgId, totalAmount, getApprovalDecisionStatus]);

  const getTextValues = () => {
    const defaultLabels = {
      [CONSTS.DELIVERY_TYPE.ACH]: 'bills.pay.date.ach.deliveryMethod',
      [CONSTS.DELIVERY_TYPE.CHECK]: 'bills.pay.date.check.deliveryMethod',
    };
    let label = defaultLabels[deliveryMethodType] || defaultLabels[CONSTS.DELIVERY_TYPE.ACH];

    if (isMicrodeposit && isApprovalRequired) {
      label = 'bills.pay.date.paymentDatePickerDescription.unverifiedBankAccountApprovalRequired';
    } else if (isUnilateral && isApprovalRequired) {
      label = 'bills.pay.date.paymentDatePickerDescription.unilateralApprovalRequired';
    } else if (isMicrodeposit) {
      label = 'bills.pay.date.paymentDatePickerDescription.unverifiedBankAccount';
    } else if (isApprovalRequired) {
      label = 'bills.pay.date.paymentDatePickerDescription.approvalRequired';
    } else if (isUnilateral) {
      label = 'bills.pay.date.paymentDatePickerDescription.unilateral';
    }

    const identifier = getAccountNumber4digits(fundingSource?.bankAccount);
    const values = {
      date: <MIFormattedDate date={scheduledDate} year={undefined} />,
      bankAccount: `(...${identifier})`,
    };

    return { label, values };
  };

  const { label, values } = getTextValues();

  if (deliveryOptions && deliveryOptions.length > 1) {
    return (
      <FastOptionsPaymentInfo
        isApprovalRequired={isApprovalRequired}
        fundingSourceType={fundingSourceType}
        deliveryOptions={deliveryOptions}
        deliveryPreference={deliveryPreference}
        dueDate={dueDate}
        totalAmount={Number(totalAmount)}
        onSelectDeliveryOption={onSelectDeliveryOption}
        label={label}
        values={values}
        isMicrodeposit={isMicrodeposit}
      />
    );
  }

  return (
    <RegularPaymentInfo
      deliveryOptions={deliveryOptions}
      deliveryDate={deliveryDate}
      deliveryMethodType={deliveryMethodType}
      maxDeliveryDate={maxDeliveryDate}
      label={label}
      values={values}
    />
  );
};

export default PaymentDatePickerInfo;

type FastOptionsPaymentInfoProps = {
  isApprovalRequired: boolean;
  isMicrodeposit: boolean;
  label: string;
  values: Record<string, any>;
  deliveryOptions: DeliveryOptionType[];
  deliveryPreference?: string;
  dueDate?: Date | null;
  totalAmount: number;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    type: string
  ) => void;
};

const FastOptionsPaymentInfo = ({
  isApprovalRequired,
  isMicrodeposit,
  fundingSourceType,
  deliveryOptions,
  deliveryPreference,
  totalAmount,
  onSelectDeliveryOption,
  label,
  dueDate,
  values,
}: FastOptionsPaymentInfoProps) => (
  <PaymentDateInfoWrapper deliveryOptions={deliveryOptions}>
    {isApprovalRequired || isMicrodeposit ? (
      <>
        <SelectDeliveryDateContainer>
          <PaymentDeliveryOptions
            dueDate={dueDate}
            paymentAmount={totalAmount}
            fundingSourceType={fundingSourceType}
            deliveryOptions={deliveryOptions}
            deliveryPreference={deliveryPreference}
            onSelectDeliveryOption={onSelectDeliveryOption}
          />
        </SelectDeliveryDateContainer>
        <Separator />
        <DateDescriptionText>
          <MIFormattedText label={label} values={values} />
        </DateDescriptionText>
      </>
    ) : (
      <SelectDeliveryDateContainer>
        <PaymentDeliveryOptions
          dueDate={dueDate}
          paymentAmount={totalAmount}
          fundingSourceType={fundingSourceType}
          deliveryOptions={deliveryOptions}
          deliveryPreference={deliveryPreference}
          onSelectDeliveryOption={onSelectDeliveryOption}
        />
      </SelectDeliveryDateContainer>
    )}
  </PaymentDateInfoWrapper>
);

type RegularPaymentInfoProps = {
  deliveryDate: Date;
  maxDeliveryDate: Date | null;
  deliveryOptions: DeliveryOptionType[];
  deliveryMethodType: OptionalDeliveryMethodsType;
  label: string;
  values: Record<string, any>;
};

const RegularPaymentInfo = ({
  deliveryOptions,
  deliveryDate,
  deliveryMethodType,
  maxDeliveryDate,
  label,
  values,
}: RegularPaymentInfoProps) => {
  const isInternational = deliveryMethodType === CONSTS.DELIVERY_TYPE.INTERNATIONAL;

  const shouldShowDescription = !isInternational;
  const shouldShowETA = isInternational;

  return (
    <PaymentDateInfoWrapper deliveryOptions={deliveryOptions}>
      <InfoItemText>
        <InfoItemDate>
          <DeliveryDate date={deliveryDate} maxDate={maxDeliveryDate} />
        </InfoItemDate>
      </InfoItemText>
      {shouldShowDescription && (
        <DateDescriptionText>
          <MIFormattedText label={label} values={values} />
        </DateDescriptionText>
      )}
      {shouldShowETA && (
        <DateETA>
          <MIFormattedText label="bills.pay.date.paymentDatePickerDescription.dateETA" />
        </DateETA>
      )}
    </PaymentDateInfoWrapper>
  );
};

type PaymentDateInfoWrapperProps = {
  deliveryOptions: DeliveryOptionType[];
  children: React.ReactNode;
};

const PaymentDateInfoWrapper = ({ deliveryOptions, children }: PaymentDateInfoWrapperProps) => (
  <InfoContainer>
    <InfoItemContainer>
      <InfoItemText>
        <MIFormattedText
          label={`bills.pay.date.${
            deliveryOptions && deliveryOptions.length > 1 ? 'deliverySpeed' : 'estimatedDelivery'
          }`}
        />
      </InfoItemText>
      {children}
    </InfoItemContainer>
  </InfoContainer>
);

const InfoContainer = styled.div`
  display: flex;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  border-top: 1px solid ${(props) => props.theme.colors.border.grey};
  padding: 2rem;

  ${(props) => props.theme?.components?.PaymentDatePicker?.InfoContainer}
`;

const InfoItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${(props) => props.theme?.components?.PaymentDatePicker?.InfoItemContainer}
`;

const SelectDeliveryDateContainer = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 1.6rem;
  ${(props) => props.theme.text.fontType.medium};
`;

const InfoItemText = styled.div`
  color: ${(props) => props.theme.colors.light.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentDatePicker?.InfoItemText}
`;

const InfoItemDate = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin: 0.8rem 0 0;
  > span {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentDatePicker?.InfoItemDate}
`;

const DateDescriptionText = styled.div`
  color: ${(props) => props.theme.colors.ds.gray[200]};
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentDatePicker?.DateDescriptionText}
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.border.darkGrey};
  height: 0.1rem;
  margin: 2rem -2rem 2rem -2rem;
`;

const DateETA = styled.div`
  padding-top: 1.1rem;
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.label};
`;
