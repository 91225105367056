import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import analytics from 'src/app/services/analytics/index';

type Props = {
  label?: string;
  text?: string;
  to?: string;
  className?: string;
  onClick?: (event: any) => void;
  testId?: string;
  target?: string;
};

export const MIInlineLink = ({
  label,
  text,
  className,
  onClick,
  to,
  testId,
  target = '_blank',
}: Props) =>
  to ? (
    <StyledMIInlineLink
      target={target}
      href={to}
      className={className}
      data-testid={testId}
      onClick={onClick}
    >
      {text && <span>{text}</span>}
      {label && <MIFormattedText label={label} />}
    </StyledMIInlineLink>
  ) : (
    <StyledMIInlineButton
      onClick={(event) => {
        text && analytics.trackAction(text, {});
        onClick && onClick(event);
      }}
      className={className}
      data-testid={testId}
    >
      {text && <span>{text}</span>}
      {label && <MIFormattedText label={label} />}
    </StyledMIInlineButton>
  );

const commonStyles = css`
  color: ${(props) => props.theme.colors.brand};
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  height: 1.6rem;
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  letter-spacing: 0;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: rgba(92, 53, 203, 1);
  }
`;

export const StyledMIInlineButton = styled.button`
  ${commonStyles};
  font-size: ${(props) => props.theme.text.size.button};
  min-width: 8.5rem;

  @media ${devices.mobile}, ${devices.phablet} {
    white-space: nowrap;
  }
  ${(props) => props.theme?.components?.MIInlineLink?.StyledMIInlineButton}
`;

export const StyledMIInlineLink = styled.a`
  ${commonStyles};
  font-size: ${(props) => props.theme.text.size.button};
  text-decoration: none;
`;
