import React from 'react';
import styled from 'styled-components';

type Props = {
  context: 'value' | 'menu';
  account: {
    Name: string;
    AccountType: string;
    FullyQualifiedName: string;
  };
};

export default ({ context, account }: Props) => (
  <AccountOption
    selected={context === 'value'}
    indent={(account.FullyQualifiedName.match(/:/g) || []).length}
  >
    <div className="account-name">{account.Name}</div>
    <div className="account-type" title={account.AccountType}>
      {account.AccountType}
    </div>
  </AccountOption>
);

type AccountOptionProps = {
  selected: boolean;
  indent: number;
};

const AccountOption = styled.div<AccountOptionProps>`
  display: flex;

  .account-name {
    padding-left: ${(props) => (props.selected ? 0 : props.indent)}em;
    margin-right: 0.5em;
    flex-grow: 1;
  }

  .account-type {
    display: ${(props) => (props.selected ? 'none' : 'block')};
    color: ${(props) => props.theme.text.color.light};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
