import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { CONSTS, NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { getJWTPayload } from 'src/app/helpers/jwt';
import locations from 'src/app/pages/vendor/edit-check-adress/locations';
import { deliveryTypePredicate } from 'src/app/utils/delivery-methods';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import deliveryMethodsStore, {
  getDeliveryMethodActions,
} from 'src/app/modules/delivery-methods/delivery-methods-store';
import { FieldType, OrganizationType, PaymentType, VendorType } from 'src/app/utils/types';
import { getIsVendorPaymentDetailsFlow } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import { UnilateralDeliveryMethodType } from 'src/app/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import curry from 'lodash/curry';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from 'src/app/pages/vendor/vendor-payment-tracking/locations';
import { History } from 'history';
import { pushNotification } from 'src/app/services/notifications';
import {
  FORM_TYPES,
  TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH,
} from '../../vendor-payment-tracking/consts';

export type EditCheckAddressProps = {
  token: string;
  id: string;
};

export type EditCheckAddressState = {
  payment: PaymentType;
  organization: any;
  isPaymentLoading: boolean;
  isDeliveryMethodProcessing: boolean;
  updatingDeliveryMethodId?: string;
};

const onChange = curry(
  (
    state: EditCheckAddressState,
    setState: (state: EditCheckAddressState) => void,
    { id, value }: FieldType
  ) => {
    setState({
      ...state,
      [id]: value,
    });
  }
);

const onSubmit = curry(
  async (
    props: EditCheckAddressProps,
    state: EditCheckAddressState,
    actions: any,
    history: History,
    value: UnilateralDeliveryMethodType,
    isAddressVerified: boolean
  ) => {
    const { token, id } = props;
    const { payment, updatingDeliveryMethodId } = state;

    if (isAddressVerified) {
      await actions.updateDeliveryMethodWithToken({
        token,
        deliveryMethod: { id: updatingDeliveryMethodId, ...value },
        orgId: payment?.organizationId,
        vendorId: updatingDeliveryMethodId,
      });

      if (getIsVendorPaymentDetailsFlow(token)) {
        history.push(generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token }));

        pushNotification({
          type: NOTIFICATION_VARIANT.SUCCESS,
          msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_CHECK_ADDRESS}.notification.success`,
        });
      } else {
        history.push(generatePath(locations.checkNotSendAddressSaved, { token, id }));
        actions.fetchEmailToVendorDetails({ token });
      }
    } else {
      const paperCheck = {
        ...value.paperCheck,
        deliveryType: CONSTS.DELIVERY_TYPE.CHECK,
        deliveryMethodId: payment.deliveryMethodId,
      };

      await actions.validateAddress(paperCheck);
    }
  }
);

export function useEditCheckAddressFormState(props: EditCheckAddressProps) {
  const { token } = props;
  const history = useHistory();
  const paymentActions = useStoreActions(paymentStore);

  const { paymentId } = getJWTPayload(token);
  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const organization: OrganizationType = useSelector(
    organizationStore.selectors.byId(payment?.organization?.id)
  );
  const [emailOnScheduleState, setState] = useState({});

  const { loading: isLoading } = useSelector(deliveryMethodsStore.selectors.validation) as {
    loading: boolean;
  };
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor: VendorType = useSelector(fetchSelector.byId(payment?.vendor?.id));
  const vendorDeliveryMethods = vendor?.deliveryMethods;
  const bundledActions = {
    ...useStoreActions(deliveryMethodsStore),
    ...getDeliveryMethodActions(useDispatch()),
    ...paymentActions,
  };
  const updatingDeliveryMethodId = vendor?.deliveryMethods?.find(
    deliveryTypePredicate(CONSTS.DELIVERY_TYPE.CHECK)
  )?.id;
  const isDeliveryMethodUpdating = (
    useSelector(deliveryMethodsStore.selectors.update.status(updatingDeliveryMethodId)) as {
      loading: boolean;
    }
  )?.loading;

  const deliveryMethodModel = useMemo(() => {
    let paperCheckModel = {
      printName: '',
      addressLine1: '',
      addressLine2: '',
      state: '',
      city: '',
      zipCode: '',
    };
    const checkDeliveryMethod = vendorDeliveryMethods?.find(
      deliveryTypePredicate(CONSTS.DELIVERY_TYPE.CHECK)
    );

    if (checkDeliveryMethod?.paperCheck) {
      paperCheckModel = merge(
        paperCheckModel,
        pick(checkDeliveryMethod?.paperCheck, keys(paperCheckModel))
      );
    }

    return { paperCheck: paperCheckModel };
  }, [vendorDeliveryMethods]);

  const enhancedState: EditCheckAddressState = {
    ...emailOnScheduleState,
    payment,
    organization,
    isPaymentLoading,
    updatingDeliveryMethodId,
    isDeliveryMethodProcessing: isLoading || isDeliveryMethodUpdating,
  };

  const actions = {
    onChange: onChange(enhancedState, setState),
    onSubmit: onSubmit(props, enhancedState, bundledActions, history),
  };

  return {
    actions,
    deliveryMethodModel,
    isDeliveryMethodProcessing: isLoading || isDeliveryMethodUpdating,
  };
}
