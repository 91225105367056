import { fetchRequest, postRequest } from './api';

export default {
  search({ orgId, name }: { orgId: string; name: string }) {
    const url = `/orgs/${orgId}/directory`;

    return fetchRequest(url, { name });
  },
  validate({ orgId, billerId, accountNumber }) {
    const url = `/orgs/${orgId}/directory/biller/validate`;

    return postRequest(url, { billerId, accountNumber });
  },
};
