import React, { useEffect, useRef } from 'react';
import { Box, Flex, Text, Tooltip } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import { FieldType } from 'src/app/utils/types';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import { CURRENCY_USD } from 'src/app/version-2/model/constants';
import InputField, * as styles from './styles';
import { CurrencyInputType, CurrencySign, DEFAULT_CURRENCY, DEFAULT_CURRENCY_SIGN } from './consts';
import * as Utils from './utils';
import { convertCurrencyToNumber } from '../../../utils/currency-utils';

export type ErrorProps = {
  errors?: Record<string, string | undefined>;
  errorsValues?: Record<string, string>;
};

type CommonProps = ErrorProps & {
  type: CurrencyInputType;
  currency?: typeof CURRENCY_USD;
  name?: string;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (fieldType: FieldType) => void;
  onFocus?: VoidFunction;
};

type TypeProps =
  | {
      type: CurrencyInputType.INPUT_FIELD;
      label?: string;
      showPencil?: never;
      pencilTooltip?: never;
      disableEdit?: boolean;
    }
  | {
      type: CurrencyInputType.CENTER_ALIGNED;
      label?: never;
      showPencil?: boolean;
      pencilTooltip?: string;
      disableEdit?: boolean;
    };

export type CurrencyInputProps = CommonProps & TypeProps;

const CurrencyInput = ({
  type,
  currency = DEFAULT_CURRENCY,
  name = '',
  value,
  label,
  placeholder,
  autoFocus,
  errors,
  errorsValues,
  onFocus,
  onChange,
  showPencil,
  pencilTooltip,
  disableEdit,
}: CurrencyInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenAmountRef = useRef<HTMLParagraphElement>(null);

  const inputFieldFormattingProps = Utils.getInputFieldFormattingProps(type, currency);

  useEffect(() => {
    if (type === CurrencyInputType.CENTER_ALIGNED) {
      const formatted = Utils.getFormattedValue(value, {
        groupSeparator: inputFieldFormattingProps.groupSeparator as string,
        decimalSeparator: inputFieldFormattingProps.decimalSeparator as string,
      });

      recalculateInputWidth(formatted);
    }
  }, []);

  const handleValueChange = (value: string | undefined, name: string) => {
    if (type === CurrencyInputType.CENTER_ALIGNED) {
      const formatted = Utils.getVerifiedValue(value, {
        groupSeparator: inputFieldFormattingProps.groupSeparator as string,
        decimalSeparator: inputFieldFormattingProps.decimalSeparator as string,
      });

      recalculateInputWidth(formatted);
    }

    if (onChange) {
      onChange({ id: name, value: value ?? '' });
    }
  };

  const handleBlur = () => {
    if (type === CurrencyInputType.CENTER_ALIGNED) {
      setTimeout(() => {
        confirmInputWidth();
      }, 0);
    }
  };

  // Considering the fact that amount input should be centered horizontally,
  // to show $ sign right before the input we need to change the width of the input dinamicaly,
  // based on inputed data or placeholder. For this purpose was introduced hidden span element,
  // which has the same font and text as input and is used to get the inputed text width
  const recalculateInputWidth = (value: string | undefined) => {
    const inputElement = inputRef.current;
    const hiddenAmountElement = hiddenAmountRef.current;

    if (inputElement && hiddenAmountElement) {
      const text =
        value ||
        (inputElement.value === '.' && '.') ||
        (inputElement.value === '-' && '-') ||
        inputElement.getAttribute('placeholder') ||
        '';

      hiddenAmountElement.innerText = text;
      const hiddenElementWidth = hiddenAmountElement.offsetWidth;

      inputElement.style.width = text ? `${hiddenElementWidth + 16}px` : '90px';
    }
  };

  const confirmInputWidth = () => {
    const inputElement = inputRef.current;
    const hiddenAmountElement = hiddenAmountRef.current;

    if (inputElement && hiddenAmountElement) {
      const text = inputElement.value || inputElement.getAttribute('placeholder') || '';

      hiddenAmountElement.innerText = text;
      const hiddenElementWidth = hiddenAmountElement.offsetWidth;

      inputElement.style.width = text ? `${hiddenElementWidth + 16}px` : '90px';
    }
  };

  return (
    <Flex {...styles.container(type)}>
      {!!label && (
        <Box {...styles.label}>
          <MIFormattedText label={label} />
        </Box>
      )}
      <Flex {...styles.inputContainer(type)} className={FULL_STORY_MASK_RULE_CLASS}>
        {type === CurrencyInputType.CENTER_ALIGNED && (
          <>
            <Text as="span" {...styles.currencySign}>
              {CurrencySign[currency] ?? DEFAULT_CURRENCY_SIGN}
            </Text>
            <Text ref={hiddenAmountRef} as="span" {...styles.hiddenAmount} />
          </>
        )}
        <InputField
          data-testid="currency-input-input-field"
          {...inputFieldFormattingProps}
          ref={inputRef}
          type={type}
          name={name}
          value={convertCurrencyToNumber(value)}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={handleBlur}
          onValueChange={handleValueChange as any}
          readOnly={disableEdit}
        />
        {showPencil && (
          <Tooltip
            placement="right"
            isDisabled={!pencilTooltip}
            label={<MIFormattedText label={pencilTooltip} />}
          >
            <Text as="i" {...styles.pencil} />
          </Tooltip>
        )}
      </Flex>
      {!!errors?.[name] && (
        <Box {...styles.error(type)} data-testid="currency-input-error-message">
          <MIFormattedText label={errors[name]} values={errorsValues ?? {}} />
        </Box>
      )}
    </Flex>
  );
};

export { CurrencyInput };
