import React from 'react';
import { ReactComponent as LeftArrow } from 'src/app/images/core/left-arrow.svg';
import PaginationIconButton from './PaginationIconButton';

type Props = {
  previousPage: () => void;
  canPreviousPage?: boolean;
};

const Previous = ({ previousPage, canPreviousPage }: Props) => (
  <PaginationIconButton
    ariaLabel="previous-page"
    onClick={previousPage}
    icon={<LeftArrow />}
    disabled={!canPreviousPage}
    testId="previous-page"
  />
);

export default Previous;
