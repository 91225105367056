import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { compose } from 'recompose';

import analytics from 'src/app/services/analytics';
import type { VendorType } from 'src/app/utils/types';
import { isRppsVendor } from 'src/app/pages/vendor-directory/utils';
import { getPartialBillId, hasMaximumAmountLimit } from 'src/app/utils/bills';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { UserCompanyInfo } from 'src/app/version-2/model/dtos/userCompanyInfo';
import locations from 'src/app/pages/locations';
import { isOrgBlockedByRisk } from 'src/app/utils/company';
import intuit from 'src/app/utils/intuit';
import { withNavigator } from 'src/app/hoc';
import { getBill, getIsLoading } from 'src/app/redux/payBillWizard/selectors';
import ScrollToTop from 'src/app/components/layout/ScrollToTop';
import noPaymentCreated from 'src/app/images/qbo/no-payment-created-icon.png';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import { PayBillFundingSourcePage } from './PayBillFundingSourcePage';
import { PayBillProps, withPayBillData } from '../hoc/withPayBillData';
import { PayBillSkeleton } from '../components/PayBillSkeleton';

const eventPage = 'pay-bill';

const PayBillFundingSourcePageContainer = ({
  goExit,
  setPaymentAmount,
  getUrlAfterFundingSourceStepForRecoveryFlow,
  navigate,
  showCCTooltip,
}: PayBillProps) => {
  const bill = useSelector(getBill);
  const [historyPush] = useHistoryWithOrgId();
  const isBillDataLoading = useSelector(getIsLoading);
  const companyInfo: Partial<UserCompanyInfo> = useSelector(userSliceSelectors.getCompanyInfo);
  const history = useHistory();
  const { isInternationalEntryPoint } = useSelector((state) => state['flags']);

  useEffect(() => {
    analytics.trackRoute('page-viewed');
  }, []);

  useEffect(() => {
    intuit.endLoadingWrapper();

    // control go back on international payment dashboard flow (go to new international bill page)
    if (isInternationalEntryPoint) {
      history.listen((location, action) => {
        action === 'POP' &&
          location.pathname.includes('funding') &&
          historyPush({ path: locations.dashboard.internationalEntrypoint });
      });
    }
  }, []);

  useEffect(() => {
    if (bill.id && !isBillDataLoading) {
      intuit.hideFTULoadingWrapper();
    }
  }, [bill.id, isBillDataLoading]);

  if (isBillDataLoading) {
    return <PayBillSkeleton />;
  }

  if (isOrgBlockedByRisk(companyInfo)) {
    return <Redirect to={{ pathname: locations.entrypoints.errors.orgBlockedByRisk }} />;
  }

  if (isRppsVendor(bill.vendor as VendorType)) {
    analytics.track(eventPage, 'block-schedule-payment-to-biller-directory-vendor', {
      partialBillId: getPartialBillId(bill),
    });

    return (
      <ErrorPage
        illustration={noPaymentCreated}
        title="bills.view.error.notSupportedVendorTitle"
        subtitle="bills.view.error.notSupportedVendorSubTitle"
        buttonAction={goExit}
        buttonLabel="bills.view.error.notSupportedVendorSubButton"
      />
    );
  }

  if (hasMaximumAmountLimit(Number(bill.totalAmount))) {
    return <Redirect to={{ pathname: locations.entrypoints.errors.billAmountLimit }} />;
  }

  return (
    <ScrollToTop>
      <PayBillFundingSourcePage
        onPrev={null}
        goExit={goExit}
        getUrlAfterFundingSourceStepForRecoveryFlow={getUrlAfterFundingSourceStepForRecoveryFlow}
        setPaymentAmount={setPaymentAmount}
        navigate={navigate}
        showCCTooltip={showCCTooltip}
      />
    </ScrollToTop>
  );
};

const PayBillFundingSourcePageContainerComposed = compose(
  withNavigator(),
  withPayBillData()
)(PayBillFundingSourcePageContainer);

export { PayBillFundingSourcePageContainerComposed as PayBillFundingSourcePageContainer };
