import { all, put, fork } from 'redux-saga/effects';
import { watchBatchBulk } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.saga';
import { watchAddCardAccount } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.saga';
import { watchAddCardAccountFundingSource } from 'src/app/version-2/pages/add-card-account/modules/fundingSource/addCardAccountFundingSource.saga';
import { watchAddCardAccountDeliveryMethod } from 'src/app/version-2/pages/add-card-account/modules/deliveryMethod/addCardAccountDeliveryMethod.saga';
import { watchAchDeliveryMethodDetails } from 'src/app/version-2/pages/ach-delivery-method-details/modules/achDeliveryMethodDetails.saga';
import { watchUpgradePaymentByPayee } from 'src/app/version-2/pages/vendor-upgrade-payment';
import { watchIntuitSaga } from 'src/app/version-2/modules/intuit/intuit.saga';
import { watchUser } from 'src/app/version-2/modules/user/user.saga';
import { watchFundingSources } from 'src/app/version-2/modules/fundingSources/fundingSources.saga';
import { watchUltimateBeneficialOwnersSaga } from 'src/app/version-2/modules/ultimateBeneficialOwners/ultimateBeneficialOwners.saga';
import { watchCountriesSaga } from 'src/app/version-2/modules/countries/countries.saga';
import { watchOrganizationSaga } from 'src/app/version-2/modules/organization/organization.saga';
import { watchPaymentsSaga } from 'src/app/version-2/modules/payments/payments.saga';
import { watchGlobalOptInCandidates } from 'src/app/version-2/pages/global-opt-in-details/modules/globalOptIn.saga';
import { watchFundingSource } from 'src/app/version-2/pages/funding-source/modules/fundingSource.saga';
import { watchApplicationSaga } from 'src/app/version-2/modules/application/application.saga';
import { watchCollectInvoiceSaga } from 'src/app/version-2/modules/collectInvoice/collectInvoice.saga';
import { watchRefund } from 'src/app/version-2/pages/refund/modules/refund.saga';
import payBillWizardSagas from './payBillWizard/sagas';
import userSagas from './user/sagas';
import { checkAndInitUserAction } from './user/actions';
import { organizationSagas } from './organization/sagas';
import auth from '../modules/auth/auth-store';
import users from '../modules/users/users-store';
import profile from '../modules/profile/profile-store';
import organizations from '../modules/organizations/organizations-store';
import vendors from '../modules/vendors/vendors-store';
import payments from '../modules/payments/payment-store';
import batchBills from '../modules/batch-bills/batch-bills-store';
import bills from '../modules/bills/bills-store';
import deliveryMethods from '../modules/delivery-methods/delivery-methods-store';
import navigation from '../modules/navigation/navigation-store';
import quickpayBills from '../modules/quickpay-bills/quickpay-bills-store';
import fundingSources from '../modules/funding-sources/funding-sources-store';
import intuitBankAccountsStore from '../modules/intuit-bank-accounts/intuit-bank-accounts-store';
import { qbDashboardListItemsStore } from '../modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { fullOrgSyncStore } from '../modules/fullOrgSync/full-org-sync-store';
import { getOrgIdByUrl, getRealmIdByUrl } from '../helpers/utilities';

function* initialSaga() {
  const params = {
    skip2FACheck: false,
    orgId: getOrgIdByUrl(),
    realmId: getRealmIdByUrl(),
  };

  yield put(checkAndInitUserAction(params));
}

export default function* rootSaga() {
  yield fork(initialSaga);
  yield all([
    payBillWizardSagas(),
    userSagas(),
    organizationSagas(),
    watchBatchBulk(),
    watchAddCardAccount(),
    watchAddCardAccountFundingSource(),
    watchAddCardAccountDeliveryMethod(),
    watchAchDeliveryMethodDetails(),
    watchIntuitSaga(),
    watchOrganizationSaga(),
    watchUser(),
    watchFundingSources(),
    watchUltimateBeneficialOwnersSaga(),
    watchCountriesSaga(),
    watchPaymentsSaga(),
    watchGlobalOptInCandidates(),
    watchFundingSource(),
    watchApplicationSaga(),
    watchCollectInvoiceSaga(),
    watchRefund(),
    watchUpgradePaymentByPayee(),
    auth.saga(),
    users.saga(),
    profile.saga(),
    organizations.saga(),
    vendors.saga(),
    payments.saga(),
    deliveryMethods.saga(),
    batchBills.saga(),
    bills.saga(),
    navigation.saga(),
    quickpayBills.saga(),
    fundingSources.saga(),
    intuitBankAccountsStore.saga(),
    qbDashboardListItemsStore.saga(),
    fullOrgSyncStore.saga(),
  ]);
}
