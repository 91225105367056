import { RecordOf } from 'immutable';
import React from 'react';
import { useParams } from 'react-router-dom';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BillType } from 'src/app/utils/types';
import { Title, Content, PaymentListContainer, Container } from './RefundPaymentsItem.styles';

type Props = {
  bill: RecordOf<BillType>;
  paymentId: string;
};

export const RefundPaymentsItem = ({ bill, paymentId }: Props) => {
  const { orgId } = useParams<{
    orgId: string;
  }>();

  return (
    <Container>
      <Title data-testid="refund-payments-item_title">
        <MIFormattedText label="bills.pay.requestRefund.review.partialPayments.title" />
      </Title>
      <Content data-testid="refund-payments-item_bill-id">{bill.id}</Content>
      <PaymentListContainer bill={bill} orgId={orgId} paymentId={paymentId} />
    </Container>
  );
};
