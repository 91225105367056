import { BILLING_FEATURE_WIZARD_FLOW } from 'src/app/pages/settings/components/fee/consts';
import { FUNDING_DEFAULT_LOGO } from 'src/app/version-2/model/constants';
import { FundingSourceTypesEnum, FundingSourceOrigins } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

// duplicated this code until problem with publicPath won't be solved
const isBankAccountBlocked = (fundingSource?: FundingSource | null) =>
  !!(fundingSource && fundingSource.bankAccount && fundingSource.bankAccount.isBlocked);

const isManualBankAccountNotVerified = (fundingSource?: FundingSource | null) =>
  !!(
    fundingSource &&
    fundingSource.fundingType === FundingSourceTypesEnum.ACH &&
    fundingSource.origin === FundingSourceOrigins.MANUAL &&
    !fundingSource.isVerified
  );

export const getAccountNumber4digits = (bankAccount?): string =>
  bankAccount?.accountNumber4digits || bankAccount?.accountNumber?.slice(-4) || '';

export function getImages(fundingSource: FundingSource, isIconOptional = true) {
  const hasLogo = fundingSource.logo !== '' && fundingSource.logo !== FUNDING_DEFAULT_LOGO;

  const imageSrc = hasLogo ? `data:image/jpeg;base64,${fundingSource.logo}` : '';
  const icon = imageSrc && isIconOptional ? '' : 'icon-bank-icon';

  return { icon, imageSrc };
}

export function getAdditionDescription(flow?: string) {
  if (flow === BILLING_FEATURE_WIZARD_FLOW) {
    return 'onboarding.fundingSources.options.bank.feeFundingSourceDescriptionAddition';
  }

  return 'bills.pay.fundingSource.achDescription';
}
export function getDescription(fundingSource: FundingSource, flow?: string) {
  if (flow === BILLING_FEATURE_WIZARD_FLOW && fundingSource.isVerified) {
    return 'onboarding.fundingSources.options.bank.feeFundingSourceDescription';
  }

  if (isManualBankAccountNotVerified(fundingSource)) {
    if (isBankAccountBlocked(fundingSource)) {
      return 'settings.paymentMethods.microDepositsBlocked';
    }

    return 'settings.paymentMethods.notVerifiedAccountHintWithAction';
  }

  return 'bills.pay.fundingSource.achDescription';
}
