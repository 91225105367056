import DatePicker from 'src/app/components/form/DatePicker';
import styled from 'styled-components';
import { isBusinessDay, getEndOfLifeMaxDate } from 'src/app/utils/dates';
import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import { ModelViewField } from 'src/app/ui/form/use-form';
import { format } from 'date-fns';
import { DATE_FORMATS } from 'src/app/utils/date-fns';

export type InlineDatePickerProps = {
  date?: Date;
  model: ModelViewField<string>;
  label: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  notices?: string[];
  dateFormat?: string;
  placement?: string;
  hasRecurringFrequency?: boolean;
  isFirstWave?: boolean;
};

export const BillModalDatePicker = (props: InlineDatePickerProps) => {
  const {
    model,
    date,
    label,
    placeholder,
    required,
    disabled,
    notices,
    dateFormat,
    placement = 'top',
    hasRecurringFrequency,
    isFirstWave,
  } = props;
  const customInput = (
    <DatePickerWrapper>
      <Section isDateSelected={!!date}>
        <InlineTextInputField
          label={label}
          placeholder={date ? '' : placeholder}
          value={
            date
              ? format(new Date(date), dateFormat || DATE_FORMATS.monthShortWithLongDateAndYear)
              : ''
          }
          required={required}
          model={model}
          disabled={disabled}
          viewOnly
          notices={notices}
        />
        <EtaIconContainer>
          <EtaIcon isSelected={date} className="icon-eta-cal" />
        </EtaIconContainer>
      </Section>
    </DatePickerWrapper>
  );

  const onChange = ({ date }) => {
    model.onChange({ value: date?.toString() });
  };

  const minDate = hasRecurringFrequency ? new Date() : undefined;
  const maxDate = getEndOfLifeMaxDate(isFirstWave);

  return (
    <StopPropagationSection
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DatePicker
        id="inlineDatePicker"
        required
        date={date}
        onChange={onChange}
        customInput={customInput}
        filterDate={(day) => isBusinessDay(day)}
        disabled={disabled}
        popperPlacement={placement}
        min={minDate}
        max={maxDate}
      />
    </StopPropagationSection>
  );
};

const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 1.4rem;
  padding-left: 0.8rem;
  padding-right: 0.4rem;
  height: 3rem;
  color: ${(props) => props.theme.text.color.label};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.text.weight.normal};
  letter-spacing: -0.04rem;
`;

const StopPropagationSection = styled.div``;

const Section = styled.div<{ isDateSelected: boolean }>`
  color: ${(props) => props.isDateSelected && props.theme.text.color.main};
  width: 100%;
  line-height: 2rem;
  position: relative;
`;

const EtaIconContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 3rem;
  right: 0;
  width: 2rem;
  height: 2rem;
`;

const EtaIcon = styled.i`
  color: ${(props) => props.theme.text.color.label};
  font-size: 1.5rem;
`;
