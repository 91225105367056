import React from 'react';
import styled from 'styled-components';
import { Image } from 'src/app/core/ds/image';
import { BADGE_SIZE } from 'src/app/utils/consts';
import MIInitialsBadge from 'src/app/components/common/MIInitialsBadge';
import ViewDetailsLink from 'src/app/components/common/ViewDetailsLink';
import melioDirectoryIndication from 'src/app/images/biller-directory/melio-directory-indication.svg';

type Props = {
  companyName: string;
  description?: React.ReactNode;
  showViewDetailsLink?: boolean;
  showDirectoryVendorLogo?: boolean;
  goViewDetails?: () => void;
};

export const CompanyContact = ({
  companyName,
  description,
  showViewDetailsLink,
  showDirectoryVendorLogo,
  goViewDetails,
}: Props) => (
  <ViewBillContactContainer>
    <MIInitialsBadge name={companyName} size={BADGE_SIZE.LARGE} />
    {showDirectoryVendorLogo && (
      <Image
        width={4}
        height={4}
        border="2px"
        borderColor="white"
        borderRadius="full"
        top="3.1rem"
        left="3.1rem"
        position="absolute"
        src={melioDirectoryIndication}
      />
    )}
    <ViewBillContactContainerText>
      <ViewBillSubHeader>{companyName}</ViewBillSubHeader>
      <ViewBillDescription>
        {description && <ViewHeaderDescription>{description}</ViewHeaderDescription>}
        {showViewDetailsLink && <ViewDetailsLink goViewDetails={goViewDetails} />}
      </ViewBillDescription>
    </ViewBillContactContainerText>
  </ViewBillContactContainer>
);

const ViewBillContactContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ViewBillContactContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
`;

const ViewBillSubHeader = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.01rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const ViewHeaderDescription = styled.div`
  color: ${(props) => props.theme.text.color.label};
`;

const ViewBillDescription = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  line-height: 1.8rem;
`;
