import React, { useCallback, ReactElement } from 'react';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/app/pages/locations';
import first from 'lodash/first';
import { getDashboardItemId } from 'src/app/pages/qb-dashboard/utils';
import {
  useGetDashboardListItemPaginationParams,
  RedirectQuery,
} from './useGetDashboardListItemPaginationParams';

export type ItemIds = { billId: string; paymentId?: string }[];
export type RedirectParams = {
  itemIds?: ItemIds;
  redirectQuery?: RedirectQuery;
  state?: Record<string, unknown>;
};

export const useRedirectToDashboard = () => {
  const [historyPush] = useHistoryWithOrgId();
  const { getDashboardListItemPaginationParams, isFetchingRedirectParams } =
    useGetDashboardListItemPaginationParams();

  const fetchRedirectQuery = useCallback(
    async (itemIds: ItemIds, withoutTimeout?: boolean): Promise<RedirectQuery> => {
      const firstItem = first(itemIds);
      const highlightedItemIds = itemIds
        .map(({ billId, paymentId }) => getDashboardItemId(billId, paymentId))
        .join(',');
      const queryParams = await getDashboardListItemPaginationParams({
        billId: firstItem?.billId,
        paymentId: firstItem?.paymentId,
        withoutTimeout,
      });

      return {
        ...queryParams,
        highlightedItemIds,
      };
    },
    [getDashboardListItemPaginationParams]
  );

  const redirectToDashboard = useCallback(
    async ({ itemIds, redirectQuery, state }: RedirectParams) => {
      let query = redirectQuery;

      if (!query && itemIds) {
        query = await fetchRedirectQuery(itemIds);
      }

      historyPush({
        path: locations.qbDashboard.dashboard,
        query,
        state,
      });
    },
    [fetchRedirectQuery, historyPush]
  );

  return {
    fetchRedirectQuery,
    redirectToDashboard,
    isFetchingRedirectParams,
  };
};

export function withRedirectToDashboard() {
  return function (Component: React.FC): React.FC {
    return function (props: any): ReactElement {
      const { fetchRedirectQuery, redirectToDashboard, isFetchingRedirectParams } =
        useRedirectToDashboard();

      return (
        <Component
          {...props}
          fetchRedirectQuery={fetchRedirectQuery}
          redirectToDashboard={redirectToDashboard}
          isFetchingRedirectParams={isFetchingRedirectParams}
        />
      );
    };
  };
}
