import React from 'react';
import styled from 'styled-components';

import { BillFrequencyEnum } from 'src/app/version-2/model/enums';
import MIRadioGroup from 'src/app/components/form/MIRadioGroup';
import analytics from 'src/app/services/analytics';

import { MODEL_KEYS } from '../hooks/useBillModalState';

const { FREQUENCY } = MODEL_KEYS;

type Props = {
  model: Record<string, any>;
};

const Frequency = ({ model }: Props) => {
  const options = [
    {
      label: 'bills.form.paymentRepeat.oneTime',
      id: BillFrequencyEnum.ONE_TIME,
    },
    {
      label: 'bills.form.paymentRepeat.weekly',
      id: BillFrequencyEnum.WEEKLY,
    },
    {
      label: 'bills.form.paymentRepeat.monthly',
      id: BillFrequencyEnum.MONTHLY,
    },
  ];

  const onSelect = (newValue: string) => {
    model.onChange({ value: newValue });
    analytics.track('bill-create', 'frequency-changed', {
      frequency: newValue,
    });
  };

  return (
    <StyledRecurring>
      <MIRadioGroup
        label="bills.form.paymentRepeat.title"
        id={FREQUENCY}
        group={FREQUENCY}
        options={options}
        selected={model?.value}
        onSelect={onSelect}
        required
      />
    </StyledRecurring>
  );
};

export { Frequency };

const StyledRecurring = styled.div`
  margin-bottom: 3rem;
`;
