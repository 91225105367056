import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { Badge, BadgeVariants } from '@melio/billpay-design-system';
import { generatePath, useHistory } from 'react-router-dom';
import { AddFundingSourceWizardOriginEnum } from 'src/app/version-2/model/enums';
import { DeliveryMethodType } from 'src/app/utils/types';
import {
  CONSTS,
  DELIVERY_METHOD_ORIGIN,
  FULL_STORY_MASK_RULE_CLASS,
  HOLIDAYS_WARNING_ICON_VARIANT,
} from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ReactComponent as InternationalIcon } from 'src/app/images/qbo/international/delivery-icon.svg';
import { ReactComponent as VirtualIcon } from 'src/app/images/qbo/virtual-dm.svg';
import { ReactComponent as MastercardIcon } from 'src/app/images/icons/mastercard.svg';
import { featureFlags } from '@melio/shared-web';
import { HolidaysWarning } from 'src/app/components/common/HolidaysWarning';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { getBill } from 'src/app/redux/payBillWizard/selectors';
import locations from 'src/app/utils/locations';
import justPayLocations from 'src/app/pages/just-pay/justPayLocations';
import analytics from 'src/app/services/analytics';
import { useEditDeliveryMethod } from '../hooks/useEditDeliveryMethod';

type Props = {
  deliveryMethod: DeliveryMethodType;
  companyName: string;
  onItemClick: () => void;
  isSelected: boolean;
  tagVariant?: BadgeVariants | null;
  tagLabel?: string;
  disabled?: boolean;
  customDescription?: string | React.ReactNode;
  descriptionValues?: Record<string, any>;
  showDisplayName?: boolean;
  deliveryMethodOrigin?: DELIVERY_METHOD_ORIGIN;
  vendorId?: string;
};

export const QBODeliveryMethodListItem = ({
  deliveryMethod,
  onItemClick,
  companyName,
  isSelected,
  tagVariant,
  tagLabel,
  disabled,
  customDescription,
  descriptionValues,
  showDisplayName = true,
  deliveryMethodOrigin,
  vendorId,
}: Props) => {
  const [showHolidaysWarningLD] = featureFlags.useFeature('us-holidays-checks');
  const editDeliveryMethodPath = useEditDeliveryMethod(deliveryMethod, deliveryMethodOrigin);
  const history = useHistory<{ [key: string]: any }>();
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const bill = useSelector(getBill);
  const isJustPayFlow = deliveryMethodOrigin === DELIVERY_METHOD_ORIGIN.JUST_PAY;
  const getIconByClassname = (className: string) => (
    <DeliveryMethodIcon
      data-testid={`delivery-method-${deliveryMethod.deliveryType}`}
      isSelected={isSelected}
      className={className}
    />
  );

  const getIconComponent = () => {
    switch (deliveryMethod.deliveryType) {
      case CONSTS.DELIVERY_TYPE.VIRTUAL_CARD:
        if (deliveryMethod.id) {
          return <VirtualCardIconContainer />;
        }

        return getIconByClassname('icon-credit-card-icon');

      case CONSTS.DELIVERY_TYPE.ACH:
        return getIconByClassname('icon-bank-icon');

      case CONSTS.DELIVERY_TYPE.CHECK:
        return getIconByClassname('icon-check-icon');

      case CONSTS.DELIVERY_TYPE.VIRTUAL:
        return <StyledVirtualIcon />;

      case CONSTS.DELIVERY_TYPE.INTERNATIONAL:
        return <StyledInternationalIcon />;

      default:
        return getIconByClassname('icon-bank-icon');
    }
  };

  const description =
    customDescription ||
    `deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.${
      disabled ? 'disabled' : 'desc'
    }`;

  const getDeliveryMethodIcon = () => {
    if (isSelected) {
      return getIconByClassname('icon-checkmark-icon');
    }

    return getIconComponent();
  };

  const handleClick = () => {
    if (disabled) {
      return;
    }

    onItemClick();
  };

  const getDisplayName = () => {
    switch (deliveryMethod.deliveryType) {
      case CONSTS.DELIVERY_TYPE.VIRTUAL_CARD:
      case CONSTS.DELIVERY_TYPE.INTERNATIONAL:
        return ` ${deliveryMethod.getDeliveryInfo()}`;
      default:
        return ` (${deliveryMethod.id && deliveryMethod.getDisplayName(companyName)})`;
    }
  };

  const getLabelValues = () => {
    if (
      deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD &&
      deliveryMethod.getDeliveryInfo()
    ) {
      return <MIFormattedText label="deliveryMethods.to" />;
    }

    if (
      deliveryMethod.id &&
      deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD &&
      !isSelected
    ) {
      return (
        <MIFormattedText label="deliveryMethods.toValue" values={{ value: getDisplayName() }} />
      );
    }

    if (deliveryMethod.id && deliveryMethod.deliveryType !== CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
      return <MIFormattedText label="deliveryMethods.to" />;
    }

    return '';
  };

  const handleEditDeliveryMethod = () => {
    analytics.track('', 'DeliveryMethod-Edited', {
      paymentFlow: 'single',
      deliveryMethodType: deliveryMethod.deliveryType,
      deliveryMethodId: deliveryMethod.id,
      vendorId: isJustPayFlow ? vendorId : bill.vendor.id,
    });

    let stateObj = isJustPayFlow
      ? {
          origin: DELIVERY_METHOD_ORIGIN.JUST_PAY,
          redirectUrl: generatePath(justPayLocations.operations.selectDeductionDate, { orgId }),
          exitUrl: generatePath(justPayLocations.create.selectDeliveryMethod, { orgId }),
        }
      : {
          origin: DELIVERY_METHOD_ORIGIN.PAY_BILL,
          redirectUrl: locations.Bills.pay.date.url({ id: bill?.id, orgId }),
          exitUrl: locations.Bills.pay.deliveryMethod.url({ id: bill?.id, orgId }),
          billId: bill?.id,
        };

    if (
      history.location.state?.preservedState?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK
    ) {
      stateObj = history.location.state as any;
    }

    historyPush({
      path: editDeliveryMethodPath,
      params: {
        id: (isJustPayFlow ? vendorId : bill.vendor.id) as string | number,
        deliveryMethodId: deliveryMethod?.id,
        orgId,
      },
      state: stateObj,
    });
  };

  return (
    <DeliveryMethod
      disabled={disabled}
      isSelected={isSelected}
      onClick={handleClick}
      className={classNames({
        'delivery-method-item-selected': isSelected,
        'delivery-method-item-disabled': disabled,
      })}
    >
      <DeliveryMethodDetailsContainer>
        <DeliveryMethodDescriptionContainer>
          {getDeliveryMethodIcon()}
          <DeliveryMethodInfo>
            <DeliveryMethodName data-testid={`method-select-${deliveryMethod.deliveryType}`}>
              <MIFormattedText
                label={`deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.label`}
                values={{
                  to: getLabelValues(),
                }}
              />
              {showDisplayName && deliveryMethod.id && (
                <span className={FULL_STORY_MASK_RULE_CLASS}>{getDisplayName()}</span>
              )}
              {showHolidaysWarningLD &&
                deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.CHECK && (
                  <HolidaysWarning
                    tooltipLabel="holidaysWarning.tooltipLabels.default"
                    variant={
                      deliveryMethod?.id
                        ? HOLIDAYS_WARNING_ICON_VARIANT.WARNING
                        : HOLIDAYS_WARNING_ICON_VARIANT.INFO
                    }
                    customIconStyles={{ position: 'relative', top: '0.2rem', marginLeft: '0.1rem' }}
                  />
                )}
            </DeliveryMethodName>
            <DeliveryMethodDescription>
              <MIFormattedText label={description as string} values={descriptionValues} />
            </DeliveryMethodDescription>
          </DeliveryMethodInfo>
          {tagVariant && (
            <Badge variant={tagVariant}>
              <MIFormattedText label={tagLabel} />
            </Badge>
          )}
        </DeliveryMethodDescriptionContainer>
        {editDeliveryMethodPath && (
          <StyledEditDeliveryMethodText
            label="deliveryMethods.edit"
            onClick={handleEditDeliveryMethod}
          />
        )}
      </DeliveryMethodDetailsContainer>
    </DeliveryMethod>
  );
};

const DeliveryMethodDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeliveryMethodDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const DeliveryMethod = styled.div<{ disabled?: boolean; isSelected?: boolean }>`
  cursor: pointer;
  max-width: 45rem;
  width: 100%;
  min-height: 8.4rem;
  border-radius: 0.8rem;
  border: ${(props) =>
    `0.1rem solid ${props.isSelected ? props.theme.colors.primary.opaque : 'transparent'}`};
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primary.translucent1 : props.theme.colors.white.opaque};
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: ${(props) => `0 0.2rem 1rem 0 ${props.theme.colors.shadow.lightGrey}`};
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  transition: ${(props) => props.theme.animation.transition.default};
  transition-property: box-shadow;
  &:hover:not(:active) {
    ${(props) =>
      !props.isSelected &&
      css`
        box-shadow: 0 0.2rem 1rem 0 rgba(192, 196, 203, 1);
      `}
  }
  /* disabled style */
  ${(props) => `
    opacity: ${props.disabled ? 0.6 : 1};
    cursor: ${props.disabled ? 'default' : 'pointer'};
  `};

  &:first-child {
    margin-top: 0;
  }
`;

const DeliveryMethodInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${(props) => props.theme.text.weight.normal};
  width: 90%;
  ${(props) => props.theme.text.fontType.medium};
`;

const DeliveryMethodDescription = styled.div`
  color: ${(props) => props.theme.text.color.label};
`;

const DeliveryMethodIcon = styled.i<{ isSelected?: boolean }>`
  font-size: 4rem;
  color: ${(props) => (props.isSelected ? props.theme.colors.brand : 'rgba(107, 108, 114, 1)')};
  margin-right: 2rem;
`;

const DeliveryMethodName = styled.span`
  color: ${(props) => props.theme.text.color.main};
`;

const StyledInternationalIcon = styled(InternationalIcon)`
  width: 4rem;
  height: 4rem;
  margin-right: 2.5rem;
`;

const StyledVirtualIcon = styled(VirtualIcon)`
  width: 4rem;
  height: 4rem;
  margin-right: 2.2rem;
`;

const VirtualCardIconContainer = styled(MastercardIcon)`
  margin-right: 2.5rem;
`;

const StyledEditDeliveryMethodText = styled(MIInlineLink)`
  align-self: center;
  padding: 0 0.8rem;
`;
