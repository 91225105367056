export const enum EntrypointActionEnum {
  Pay = 'pay',
  Edit = 'edit',
  BatchPay = 'batchpay',
  BillsList = 'bills-list',
  CreateBill = 'create-bill',
  QuickPay = 'quickpay',
  QuickList = 'quicklist',
  QuickPayView = 'quickpay-view',
  Dashboard = 'dashboard',
  JustPay = 'just-pay',
  AddCard = 'add-card',
}
