import React from 'react';
import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { QBDashboardPage } from './QBDashboardPage';
import locations from './locations';

import QBOSyncBillsPage from './QBOSyncBillsPage';
import QBOSyncBillPage from './QBOSyncBillPage';
import QBOSyncPaymentPage from './QBOSyncPaymentPage';

const QBDashboardRouter = () => (
  <Switch>
    <SmartRoute path={locations.dashboard} exact>
      <QBDashboardPage />
    </SmartRoute>
    <SmartRoute path={locations.syncBillsByOrigin} exact>
      <QBOSyncBillsPage />
    </SmartRoute>

    <SmartRoute path={locations.syncBillByOrigin} exact>
      <QBOSyncBillPage />
    </SmartRoute>

    <SmartRoute path={locations.syncPaymentByOrigin} exact>
      <QBOSyncPaymentPage />
    </SmartRoute>
  </Switch>
);

export default QBDashboardRouter;
