import { put, takeEvery, all, call, select, getContext, fork } from 'redux-saga/effects';
import { TakeableChannel } from 'redux-saga';
import type { RecordOf } from 'immutable';
import get from 'lodash/get';
import { getDefaultMemo } from 'src/app/utils/bills';
import vendorsApi from 'src/app/services/api/vendors';
import { getDeliveryMethodForPayment, getDefaultFundingSource } from 'src/app/redux/utils';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { featureFlagEnabledValues } from 'src/app/hooks/useHasFeatureFlag';
import {
  getPayBillFlowUUID,
  startTrackBillPayFlow,
  stopTrackingBillPayFlow,
} from 'src/app/services/analytics/trackPayBillFlow';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { loggingApi } from 'src/app/version-2/api/loggers';
import billsApi from '../../services/api/bills';
import paymentApi from '../../services/api/payments';
import { BillRecord } from '../../pages/bill/records';
import {
  BEGIN_REGULAR_PAY_BILL_FLOW,
  SELECT_NEW_DELIVERY_METHOD,
  CREATE_RECURRING_BILL,
  CREATE_PAYMENT,
  UPDATE_PAYMENT,
  BEGIN_RECURRING_PAY_BILL_FLOW,
  END_PAY_BILL_FLOW,
  FETCH_BILL,
  RETRY_FAILED_PAYMENT,
  SELECT_FUNDING_SOURCE,
  SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA,
  CANCEL_AND_RETRY_PAYMENT,
  FETCH_DELIVERY_OPTIONS,
  FETCH_INITIAL_DELIVERY_OPTIONS,
} from './actionTypes';
import type {
  BeginRegularPayBillFlowType,
  BeginRecurringPayBillFlowType,
  SelectNewDeliveryMethodType,
  SelectFundingSourceType,
  LoadDefaultMemoParams,
} from './types';
import type { BillType, DeliveryMethodType, PaymentType } from '../../utils/types';
import {
  beginRegularPayBillFlowSuccessAction,
  beginRegularPayBillFlowFailedAction,
  addNewDeliveryMethodAction,
  selectDeliveryMethodAction,
  beginRecurringPayBillFlowSuccessAction,
  beginRecurringPayBillFlowFailedAction,
  fetchBillSuccessAction,
  fetchBillFailedAction,
  setInitialRegularFlowDataActionFinish,
  updatePaymentMemoAction,
  setInvoiceFileAction,
} from './actions';
import { getPayment, getBill, getIsRecurring } from './selectors';
import { getOrgId, getFundingSources } from '../user/selectors';
import { fetchDeliveryOptionsHandler } from './sagas/fetchDeliveryOprions.saga';
import { fetchInitialDeliveryOptionsHandler } from './sagas/fetchInitialDeliveryOptions.saga';
import { PaymentRecord } from '../../pages/payment/records';
import { melioClose, paymentSuccess } from '../../utils/external-events';
import { createRecurringBillHandler } from './sagas/createRecurringBill.saga';
import { createPaymentHandler } from './sagas/createPayment.saga';
import { updatePaymentHandler } from './sagas/updatePayment.saga';
import { cancelAndRetryPaymentHandler } from './sagas/cancelAndRetryPayment.saga';
import { retryFailedPaymentHandler } from './sagas/retryFailedPayment.saga';

function* isPartialPaymentsFeatureEnabled() {
  const site = yield getContext('site');
  const organizationPreferences = yield select(getOrganizationPreferences);
  const siteFeatureFlags = site.config.featureFlags || {};
  const orgFeatureFlags = organizationPreferences || {};

  return (
    !!siteFeatureFlags.partialPayments ||
    !!siteFeatureFlags.partialPaymentsUI ||
    featureFlagEnabledValues.includes(orgFeatureFlags.partialPayments) ||
    featureFlagEnabledValues.includes(orgFeatureFlags.partialPaymentsUI)
  );
}

function buildPaymentRecordFromExistingPayment(payment, payBillFlowUUID) {
  return PaymentRecord({
    ...payment,
    originDeliveryPreference: payment.deliveryPreference,
    payBillFlowUUID,
  });
}

function* buildPaymentRecord(
  billRecord: RecordOf<BillType>,
  orgId: string,
  paymentId?: string,
  initialPaymentAmount?: number,
  fundingSourceIdToKeep?: number
) {
  const payBillFlowUUID = getPayBillFlowUUID();

  if (paymentId) {
    const paymentFromBill = billRecord.payments.find(
      (p) => (p.id as unknown as number) === +paymentId
    );

    if (paymentFromBill) {
      return PaymentRecord({
        ...paymentFromBill,
        originDeliveryPreference: paymentFromBill.deliveryPreference as string,
        payBillFlowUUID,
      });
    }
  }

  const site = yield getContext('site');
  const fundingSources = yield select(getFundingSources);
  const filters = {
    start: 0,
    limit: 1,
  };
  const sorting = 'createdAt';
  const { objects: payments } = yield call(paymentApi.getPayments as any, {
    orgId,
    filters,
    sorting,
  });
  const lastCreatedPayment = payments[0];
  const deliveryMethods = (billRecord?.vendor?.deliveryMethods || []) as DeliveryMethodType[];

  let fundingSource;

  if (fundingSourceIdToKeep) {
    fundingSource = fundingSources.find((fs) => fs.id === fundingSourceIdToKeep);
  }

  if (!fundingSource) {
    fundingSource = getDefaultFundingSource(fundingSources, deliveryMethods, lastCreatedPayment);
  }

  const fundingSourceId = get(fundingSource, 'id', null);

  const deliveryMethod = getDeliveryMethodForPayment({
    deliveryMethods,
    fundingSource,
  });
  const deliveryMethodId = deliveryMethod?.id || null;
  const createOrigin = site.createOrigin.pay.payment;

  const isRecurring = yield select(getIsRecurring);
  const isPartialPaymentsEnabled = yield call(isPartialPaymentsFeatureEnabled);
  const amount =
    initialPaymentAmount ||
    (isPartialPaymentsEnabled && !isRecurring ? billRecord.balance : billRecord.totalAmount);

  return PaymentRecord({
    billId: billRecord.id,
    vendorId: billRecord.vendorId.toString(),
    amount,
    currency: billRecord.currency,
    createOrigin,
    ...(fundingSourceId ? { fundingSourceId } : {}),
    ...(deliveryMethod ? { deliveryMethod } : {}),
    ...(deliveryMethodId ? { deliveryMethodId } : {}),
    voidChecks: lastCreatedPayment?.voidChecks,
    payBillFlowUUID,
  } as any);
}

function* beginRegularPayBillFlow({
  id,
  paymentId,
  initialPaymentAmount,
  keepPaymentFundingSource,
}: BeginRegularPayBillFlowType) {
  const orgId = yield select(getOrgId);
  let bill;
  let billRecord;
  let paymentRecord;

  startTrackBillPayFlow();
  const payBillFlowUUID = getPayBillFlowUUID();

  try {
    if (paymentId) {
      const { object: payment } = yield call(paymentApi.getPaymentById, orgId, paymentId);

      bill = payment?.bills?.find((bill) => bill.id === +id);
      billRecord = BillRecord({ ...bill, vendor: payment.vendor });

      paymentRecord = buildPaymentRecordFromExistingPayment(payment, payBillFlowUUID);
    } else {
      ({ object: bill } = yield call(billsApi.getBillById, {
        orgId,
        id,
      }));
      let fundingSourceIdToKeep: number | undefined;

      if (bill.files?.length) {
        const [file] = bill.file;

        put(setInvoiceFileAction(bill.id, file));
      }

      billRecord = BillRecord(bill);

      if (keepPaymentFundingSource) {
        const existingPayment = yield select(getPayment);

        fundingSourceIdToKeep = existingPayment.fundingSourceId;
      }

      paymentRecord = yield call(
        buildPaymentRecord as any,
        billRecord,
        orgId,
        paymentId,
        initialPaymentAmount,
        fundingSourceIdToKeep
      );
    }

    yield put(beginRegularPayBillFlowSuccessAction(billRecord, paymentRecord));
    yield fork(loadDefaultMemo, { billRecord, paymentRecord });
  } catch (e: any) {
    loggingApi.error('payBillWizard/sagas.beginRegularPayBillFlow(): failed', e);
    yield put(beginRegularPayBillFlowFailedAction());
  }
}
function* beginRecurringPayBillFlow({ bill, recurringBill }: BeginRecurringPayBillFlowType) {
  try {
    startTrackBillPayFlow();
    const orgId = yield select(getOrgId);
    const billRecord = BillRecord(bill);
    const paymentRecord = yield call(buildPaymentRecord, billRecord, orgId);

    yield put(beginRecurringPayBillFlowSuccessAction(billRecord, paymentRecord, recurringBill));
    yield fork(loadDefaultMemo, { billRecord, paymentRecord });
  } catch (e: any) {
    yield put(beginRecurringPayBillFlowFailedAction());
  }
}

function* setInitialRegularFlowData({
  bill,
  initialPaymentAmount,
  fundingSource,
  deliveryMethod,
  exitUrl,
  redirectUrl,
}) {
  const billRecord = BillRecord(bill);
  const site = yield getContext('site');
  const deliveryMethodId = deliveryMethod?.id || null;
  const fundingSourceId = fundingSource?.id || null;
  const createOrigin = site.createOrigin.pay.payment;
  const amount = initialPaymentAmount || billRecord.balance;

  const paymentRecord = PaymentRecord({
    billId: billRecord.id,
    vendorId: billRecord.vendorId.toString(),
    amount,
    currency: billRecord.currency,
    createOrigin,
    ...(fundingSourceId ? { fundingSourceId } : {}),
    ...(deliveryMethod ? { deliveryMethod } : {}),
    ...(deliveryMethodId ? { deliveryMethodId } : {}),
  } as any);

  yield fork(loadDefaultMemo, {
    billRecord,
    paymentRecord,
  });

  yield put(
    setInitialRegularFlowDataActionFinish({
      bill: billRecord,
      payment: paymentRecord,
      redirectUrl,
      exitUrl,
    })
  );
}

function* loadDefaultMemo({ billRecord, paymentRecord }: LoadDefaultMemoParams) {
  const isEditPaymentFlow = !!paymentRecord.id;

  if (isEditPaymentFlow) {
    return;
  }

  const vendorId = billRecord.vendor.id;
  const { invoiceNumber } = billRecord;
  const orgId = yield select(getOrgId);
  const intuitVendor = yield call(vendorsApi.getIntuitAcctNum, orgId, vendorId);
  const intuitAcctNum = intuitVendor?.intuitAcctNum;
  const note = getDefaultMemo(invoiceNumber, intuitAcctNum);

  yield put(updatePaymentMemoAction(note));
}

function* selectFundingSource({ id }: SelectFundingSourceType) {
  const [fundingSources, payment, bill]: [FundingSource[], PaymentType, BillType] = yield all([
    select(getFundingSources),
    select(getPayment),
    select(getBill),
  ]);

  const fundingSource = fundingSources.find((fs) => fs.id === id);
  const deliveryMethods = bill?.vendor?.deliveryMethods as DeliveryMethodType[];
  const deliveryMethod = getDeliveryMethodForPayment({
    deliveryMethods,
    fundingSource,
    currentDeliveryMethodId: payment.deliveryMethodId,
  });

  if (deliveryMethod?.id !== payment.deliveryMethodId) {
    yield put(selectDeliveryMethodAction(deliveryMethod));
  }
}

function* selectNewDeliveryMethod({ deliveryMethod }: SelectNewDeliveryMethodType) {
  yield put(addNewDeliveryMethodAction(deliveryMethod));
  yield put(selectDeliveryMethodAction(deliveryMethod));
}

function* fetchBill({ id }) {
  const orgId = yield select(getOrgId);

  try {
    const { object: bill } = yield call(billsApi.getBillById, {
      orgId,
      id,
    });
    const billRecord = BillRecord(bill);

    yield put(fetchBillSuccessAction(billRecord));
  } catch (e: any) {
    yield put(fetchBillFailedAction());
  }
}

function endPayBillFlow({ isCloseEvent, preventCloseIframe }) {
  stopTrackingBillPayFlow();

  if (!preventCloseIframe) {
    if (isCloseEvent) {
      melioClose();
    } else {
      paymentSuccess();
    }
  }
}

function* watchBeginRegularPayBillFlow() {
  yield takeEvery(BEGIN_REGULAR_PAY_BILL_FLOW, beginRegularPayBillFlow);
}

function* watchBeginRecurringPayBillFlow() {
  yield takeEvery(BEGIN_RECURRING_PAY_BILL_FLOW, beginRecurringPayBillFlow);
}

function* watchSelectNewDeliveryMethod() {
  yield takeEvery(SELECT_NEW_DELIVERY_METHOD, selectNewDeliveryMethod);
}

function* watchCreateRecurringBill() {
  yield takeEvery(CREATE_RECURRING_BILL, createRecurringBillHandler);
}

function* watchCreatePayment() {
  yield takeEvery(CREATE_PAYMENT, createPaymentHandler);
}

function* watchUpdatePayment() {
  yield takeEvery(UPDATE_PAYMENT, updatePaymentHandler);
}

function* watchSelectFundingSource() {
  yield takeEvery(SELECT_FUNDING_SOURCE, selectFundingSource);
}

function* watchRetryFailedPayment() {
  yield takeEvery(RETRY_FAILED_PAYMENT, retryFailedPaymentHandler);
}

function* watchEndPayBillFlow() {
  yield takeEvery(END_PAY_BILL_FLOW as unknown as TakeableChannel<unknown>, endPayBillFlow);
}

function* watchCancelAndRetryPayment() {
  yield takeEvery(CANCEL_AND_RETRY_PAYMENT, cancelAndRetryPaymentHandler);
}

function* watchFetchBill() {
  yield takeEvery(FETCH_BILL as unknown as TakeableChannel<unknown>, fetchBill);
}

function* watchFetchDeliveryOptions() {
  yield takeEvery(FETCH_DELIVERY_OPTIONS, fetchDeliveryOptionsHandler);
}

function* watchFetchInitialDeliveryOptions() {
  yield takeEvery(FETCH_INITIAL_DELIVERY_OPTIONS, fetchInitialDeliveryOptionsHandler);
}

export default function* payBillFlowSagas() {
  yield all([
    watchBeginRegularPayBillFlow(),
    watchBeginRecurringPayBillFlow(),
    watchSelectNewDeliveryMethod(),
    watchSelectFundingSource(),
    watchCreateRecurringBill(),
    watchCreatePayment(),
    watchUpdatePayment(),
    watchEndPayBillFlow(),
    watchFetchBill(),
    watchRetryFailedPayment(),
    watchCancelAndRetryPayment(),
    watchFetchDeliveryOptions(),
    watchFetchInitialDeliveryOptions(),
    yield takeEvery(
      SET_REGULAR_PAY_BILL_FLOW_INITIAL_DATA as unknown as TakeableChannel<unknown>,
      setInitialRegularFlowData
    ),
  ]);
}
