import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import analytics from 'src/app/services/analytics';
import {
  QB_DASHBOARD_TAB_STATUS,
  UNPAID_BILL_DASHBOARD_STASUSES,
  DASHBOARD_ITEM_ACTIONS,
} from 'src/app/pages/qb-dashboard/consts';
import billsStore from 'src/app/modules/bills/bills-store';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import useViewPaymentAction from 'src/app/pages/qb-dashboard/hooks/useViewPaymentAction';
import useSchedulePaymentAction from 'src/app/pages/qb-dashboard/hooks/useSchedulePaymentAction';
import { isPartialAmountInvalid } from 'src/app/pages/qb-dashboard/utils';
import { BillType, PaymentType } from 'src/app/utils/types';

type Props = {
  tabStatus: QB_DASHBOARD_TAB_STATUS;
};

type DashboardRowClickabilityChecker = (item: DashboardListItemType) => boolean;
type DashboardRowClickHandler = (item: DashboardListItemType) => void;

const useDashboardRowClickHandler = ({
  tabStatus,
}: Props): [DashboardRowClickabilityChecker, DashboardRowClickHandler] => {
  const paymentsDictionary: Record<string, PaymentType> = useSelector(
    paymentsStore.selectors.dictionary
  );
  const billsDictionary: Record<string, BillType> = useSelector(billsStore.selectors.dictionary);
  const partialAmountsDictionary: Record<string, number> = useSelector(
    qbDashboardListItemsStore.selectors.partialPaymentsAmounts.all
  );

  const [goViewPayment] = useViewPaymentAction();
  const [goSchedulePayment] = useSchedulePaymentAction();

  const isClickable = useCallback(
    (item: DashboardListItemType) => {
      const { billId } = item;
      const { balance, totalAmount } = billsDictionary[billId];
      const partialAmount = partialAmountsDictionary[billId];

      return !isPartialAmountInvalid({
        balance: totalAmount,
        amount: (partialAmount || balance) as number,
      });
    },
    [partialAmountsDictionary, billsDictionary]
  );

  const handleRowClick = useCallback(
    (item: DashboardListItemType) => {
      if (!isClickable(item) || window.getSelection()?.toString()) return;

      const { billId, paymentId, metadata } = item;
      const bill = billsDictionary[billId];
      const payment = paymentsDictionary[paymentId as string];
      const partialAmount = partialAmountsDictionary[billId];

      const action =
        tabStatus === QB_DASHBOARD_TAB_STATUS.UNPAID &&
        item.metadata.statusInDashboard !== UNPAID_BILL_DASHBOARD_STASUSES.FAILED
          ? DASHBOARD_ITEM_ACTIONS.SCHEDULE
          : DASHBOARD_ITEM_ACTIONS.VIEW;

      let isPartial;

      if (payment) {
        // for failed payment on unpaid tab and payments on scheduled/paid tabs
        isPartial = (payment?.amount as number) < bill?.totalAmount;
      } else {
        // for unpaid/partially paid bills on unpaid tab
        isPartial = ((partialAmount || bill?.balance) as number) < bill?.totalAmount;
      }

      analytics.trackAction('action-click', {
        billId,
        paymentId,
        status: metadata.statusInDashboard,
        action,
        isPartial,
        fromRow: true,
      });

      if (action === DASHBOARD_ITEM_ACTIONS.SCHEDULE) {
        goSchedulePayment({
          billId,
          billOriginId: bill?.originId,
          initialPaymentAmount: partialAmount,
        });
      } else {
        goViewPayment({
          billId,
          paymentOriginId: payment?.originId,
          paymentId,
        });
      }
    },
    [
      isClickable,
      tabStatus,
      paymentsDictionary,
      billsDictionary,
      partialAmountsDictionary,
      goViewPayment,
      goSchedulePayment,
    ]
  );

  return [isClickable, handleRowClick];
};

export default useDashboardRowClickHandler;
