import styled from 'styled-components';
import MIMoney from 'src/app/components/common/MIMoney';

export const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

export const HighlightedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.HighlightedHeader}
`;

export const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

export const PaymentIdBox = styled.div`
  color: var(--chakra-colors-gray-500);
  font-size: 1.2rem;
  margin-top: 0.6rem;
`;

export const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;
