import React, { useCallback, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Flex } from '@melio/billpay-design-system';
import { useBreak } from 'src/app/hoc';
import { getJWTPayload } from 'src/app/helpers/jwt';
import includes from 'lodash/includes';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import MIButton from 'src/app/components/common/MIButton';
import { PaymentType, ReferrerType } from 'src/app/utils/types';
import { CONSTS, DELIVERY_TYPE, TOKEN_ACTIONS, BUTTON_SIZE } from 'src/app/utils/consts';
import vendorsLocations from 'src/app/pages/vendor/locations';

import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import {
  PaymentCollectStatusEnum,
  TransactionDestinationEnum,
} from 'src/app/version-2/model/enums';
import { BackButton } from '../../components/VendorLayoutElements';
import locations from '../locations';
import { QBOVirtualCardUnilateralInfoContent } from './unilateral-info-content/QBOVirtualCardUnilateralInfoContent';
import QBOVirtualCardMultilateralInfoContent from './multilateral-info-content/QBOVirtualCardMultilateralInfoContent';
import {
  BackButtonContainer,
  InfoCard,
  LogoContainer,
  MobileButtonsContainer,
} from './QBOVirtualCardInfoPage.styles';
import InfoSubmitButton from './info-submit-button/InfoSubmitButton';
import dictionary from './VirtualCardInfoPageDictionary';

type PropsType = {
  token: string;
  payment: PaymentType;
};

export const QBOVirtualCardInfoPage = ({ token, payment }: PropsType) => {
  const history = useHistory();
  const { isMobile } = useBreak();
  const { actions: payloadActions } = getJWTPayload(token);

  const isVirtualPayment = useMemo(
    () => payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL,
    [payment?.deliveryMethod?.deliveryType]
  );
  const isPaymentCollected = useMemo(
    () => payment?.collectStatus === PaymentCollectStatusEnum.SENT,
    [payment?.collectStatus]
  );

  const isShiftVirtualCard = useMemo(
    () =>
      includes(payloadActions, TOKEN_ACTIONS.shiftVirtualCardToACH) ||
      includes(payloadActions, TOKEN_ACTIONS.shiftVirtualCardToACHorCheck),
    [payloadActions]
  );

  const goBack = () => {
    history.goBack();
  };

  const HandleClickPrimaryButton = useCallback(() => {
    analytics.trackAction('MoreInformationGetVirtualCard-Chose', {
      flow: TransactionDestinationEnum.VENDOR,
      paymentId: payment.id,
      billId: payment.billId,
      partialBillId: getPartialBillId({
        id: payment.billId as string,
        payments: payment.billPayments,
      }),
      vendorId: payment?.vendor?.id,
    });

    const vcReferrer =
      payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH
        ? ReferrerType.SHIFT_ACH_TO_VC
        : ReferrerType.SHIFT_CHECK_TO_VC;

    history.push(generatePath(locations.optIn, { token }), {
      ...(isVirtualPayment
        ? {
            unilateralDeliveryMethod: {
              id: payment?.deliveryMethod.id,
              deliveryType: DELIVERY_TYPE.VIRTUAL_CARD,
              virtualCardAccount: { accountEmail: payment?.vendor.contactEmail },
            },
          }
        : { deliveryMethod: payment?.deliveryMethod }),
      deliveryMethodId: payment?.deliveryMethod?.id,
      payment,
      referrer: isVirtualPayment ? ReferrerType.UNILATERAL : vcReferrer,
    });
  }, [token, isVirtualPayment, payment]);

  const changeDeliveryMethod = useCallback(() => {
    history.push(generatePath(vendorsLocations.selectExpedited.selectDeliveryMethod, { token }));
  }, [history, token]);

  return (
    <Flex direction="column" color="ds.black">
      <QBOLayoutPage hideHeader contentWrapperMode="success">
        <LogoContainer>
          <QBLogo />
        </LogoContainer>
        <InfoCard isMobile={isMobile} isVirtualPayment={isVirtualPayment}>
          {!isMobile && !isShiftVirtualCard && (
            <BackButtonContainer>
              <BackButton isAbsolutePosition={false} onPrev={goBack} />
            </BackButtonContainer>
          )}

          {isVirtualPayment ? (
            <QBOVirtualCardUnilateralInfoContent isMobile={isMobile} />
          ) : (
            <QBOVirtualCardMultilateralInfoContent
              isMobile={isMobile}
              deliveryType={payment?.deliveryMethod?.deliveryType}
            />
          )}

          {!isShiftVirtualCard && !isMobile && (
            <InfoSubmitButton
              onClick={HandleClickPrimaryButton}
              isVirtualPayment={isVirtualPayment}
              isPaymentCollected={isPaymentCollected}
            />
          )}
        </InfoCard>
      </QBOLayoutPage>
      {!isShiftVirtualCard && isMobile && (
        <MobileButtonsContainer boxShadow="sm">
          <InfoSubmitButton
            isMobile
            onClick={HandleClickPrimaryButton}
            isVirtualPayment={isVirtualPayment}
            isPaymentCollected={isPaymentCollected}
            size={BUTTON_SIZE.SMALL}
          />
          {!isVirtualPayment && (
            <Flex mt={2}>
              <MIButton
                variant={CONSTS.BUTTON_VARIANT.SECONDARY}
                label={dictionary.multilateral.changeDeliveryMethodCta}
                onClick={changeDeliveryMethod}
                size={BUTTON_SIZE.SMALL}
                fullWidth
              />
            </Flex>
          )}
        </MobileButtonsContainer>
      )}
    </Flex>
  );
};
