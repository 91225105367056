import { UploadedFileInfo } from 'src/app/version-2/model/dtos/UploadedFileInfo';
import { PURPOSE_OF_PAYMENT_SEPARATOR } from 'src/app/version-2/pages/batch-bulk/model/consts/international.consts';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import { PurposeOfPaymentStructure } from 'src/app/version-2/pages/batch-bulk/model/enums/international.enum';

interface transformPurposeOfPaymentParams {
  type: string;
  description: string;
}
export const transformPurposeOfPayment = ({
  type,
  description,
}: transformPurposeOfPaymentParams) => {
  if (!type && !description) {
    return '';
  }

  return `${type || ''}${PURPOSE_OF_PAYMENT_SEPARATOR}${description || ''}`;
};

// Purpose of payment handlers
// since we have only one field for purpose of the payment,
// we're exposing these 2 functions to handle get and set for this field
export const getPurposeByType = (purpose: string, property = PurposeOfPaymentStructure.TYPE) => {
  switch (property) {
    case PurposeOfPaymentStructure.DESCRIPTION:
      return purpose?.split(PURPOSE_OF_PAYMENT_SEPARATOR)[1] || '';

    case PurposeOfPaymentStructure.TYPE:
    default:
      return purpose?.split(PURPOSE_OF_PAYMENT_SEPARATOR)[0] || '';
  }
};

interface validatePurposeOfPaymentFormParams {
  purpose: string;
  showInvoiceFileSelector: boolean;
  invoiceFile: UploadedFileInfo | null;
}
export const validatePurposeOfPaymentForm = ({
  purpose,
  showInvoiceFileSelector,
  invoiceFile,
}: validatePurposeOfPaymentFormParams) => {
  const typeError = !getPurposeByType(purpose, PurposeOfPaymentStructure.TYPE);
  const descriptionError =
    getPurposeByType(purpose, PurposeOfPaymentStructure.TYPE) === 'other' &&
    !getPurposeByType(purpose, PurposeOfPaymentStructure.DESCRIPTION);
  const fileError = showInvoiceFileSelector && !invoiceFile;
  const hasError = typeError || descriptionError || fileError;

  const validationErrors = {
    type: '',
    description: '',
    file: '',
  };

  if (typeError) validationErrors.type = 'inputErrors.international.purpose.empty';

  if (descriptionError)
    validationErrors.description = 'inputErrors.international.description.empty';

  if (fileError) validationErrors.file = 'international.purposeOfPayment.invoiceUpload.required';

  return {
    validationErrors,
    isValid: !hasError,
  };
};

export const checkIsFileChanged = (
  paymentIntent: BatchBulkPaymentIntent,
  invoiceFile: UploadedFileInfo,
  billId: string
) => {
  const currentBill = paymentIntent.payment.bills?.find((bill) => bill.id === billId);

  if (!currentBill?.files?.length) {
    return true;
  }

  const isFileAlreadyExists = currentBill.files.some((file) => file.id === invoiceFile.id);

  return !isFileAlreadyExists;
};
