import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { SmartIcon, Text } from '@melio/billpay-design-system';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { PaymentMethodChooseButton } from 'src/app/version-2/pages/batch-bulk/renderers/PaymentMethod/components';
import {
  FundingSourceImage,
  PaymentMethodButtonContainer,
  PaymentMethodButtonContent,
  PaymentMethodButtonLabel,
} from 'src/app/version-2/pages/batch-bulk/renderers/PaymentMethod/PaymentMethod.renderer.styles';
import { ExpandCollapseMenuIcon } from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';
import {
  getPaymentMethodLabel,
  getPaymentMethodsSmartIconsType,
} from 'src/app/version-2/utils/paymentMethods.utils';

export interface PaymentMethodButtonProps {
  isMenuOpen: boolean;
  fundingSource: FundingSource | undefined;
  renderers?: Record<string, any>;
}

export const PaymentMethodButton: FC<PaymentMethodButtonProps> = ({
  fundingSource,
  isMenuOpen,
  renderers,
}) => {
  const { formatMessage } = useIntl();

  if (!fundingSource) return <PaymentMethodChooseButton isMenuOpen={isMenuOpen} />;

  const smartIconType = getPaymentMethodsSmartIconsType(fundingSource);
  const { values, nickname, label } = getPaymentMethodLabel(fundingSource);

  return (
    <PaymentMethodButtonContainer>
      <PaymentMethodButtonLabel>
        <FundingSourceImage as={renderers?.PaymentFundingSourceImage}>
          <SmartIcon type={smartIconType} size="1.6rem" />
        </FundingSourceImage>
        {nickname || (
          <Text as={renderers?.PaymentMethodButton || PaymentMethodButtonContent}>
            {formatMessage({ id: label }, values)}
          </Text>
        )}
      </PaymentMethodButtonLabel>
      <ExpandCollapseMenuIcon $isOpen={isMenuOpen}>
        <SmartIcon type="arrow-dropdown" />
      </ExpandCollapseMenuIcon>
    </PaymentMethodButtonContainer>
  );
};
