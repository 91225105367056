import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  label: string;
  type?: 'message' | 'error';
  testId?: string | null;
  children?: React.ReactNode;
  icon?: React.ReactNode;
};

const MINotification = ({ label, type = 'message', testId, children, icon = null }: Props) => (
  <MINotificationContainer
    className={type}
    data-testid={testId || `notification-${type || 'message'}`}
  >
    <Notification>
      {icon}
      <MIFormattedText label={label} />
    </Notification>
    {children}
  </MINotificationContainer>
);

export default MINotification;

const MINotificationContainer = styled.div`
  border-radius: 0.8rem;
  border-style: solid;
  border-width: 0.1rem;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  padding: 2rem 2rem 2rem 2rem;
  line-height: 2.1rem;
  box-sizing: border-box;

  a {
    color: ${(props) => props.theme.colors.dark.opaque};
  }

  &.message {
    color: ${(props) => props.theme.text.color.message};
    border-color: ${(props) => props.theme.text.color.message};
  }

  &.error {
    color: ${(props) => props.theme.text.color.error};
    border-color: ${(props) => props.theme.text.color.error};
  }

  button {
    padding: 0;
  }

  @media ${devices.desktop}, ${devices.tablet} {
    margin: 0 0 4rem 0;
    width: 45rem;
  }

  @media ${devices.phablet}, ${devices.mobile} {
    margin: 0 0 2rem 0;
    width: 100%;
  }

  ${(props) => props.theme?.components?.MINotification?.MINotificationContainer}
`;

const Notification = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
