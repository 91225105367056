import React, { useCallback } from 'react';
import { Menu } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ReactComponent as ArrowDownAcuteIcon } from 'src/app/images/qbo/arrow_down_acute.svg';
import { useBillModal } from 'src/app/pages/qb-dashboard/components/BillModal/hooks/useBillModal';
import { useAddBillMenuItems } from './hooks/useAddBillMenuItems';
import { AddNewBillButton } from './DashboardAddNewBillButton.styles';
import { AddNewBillMenuItem } from './AddNewBillMenuItem/AddNewBillMenuItem';
import analytics from 'src/app/services/analytics';

export const DashboardAddNewBillButton = () => {
  const [BillModalDialog, showBillModalDialog] = useBillModal({
    modalName: 'bill-modal__new',
  });

  const menuActions = useAddBillMenuItems({ showBillModalDialog });

  const handleNewButtonClick = useCallback(() => {
    analytics.track('bills-dashboard', 'new-bill-click');
  }, []);

  return (
    <>
      <Menu>
        <Menu.MenuButton as={AddNewBillButton} onClick={handleNewButtonClick}>
          <MIFormattedText label="paymentDashboard.addBillToDashboard.addNewBillButton.buttonTitle" />
          <ArrowDownAcuteIcon />
        </Menu.MenuButton>
        <Menu.MenuList>
          {menuActions.map((action, i) => (
            <AddNewBillMenuItem action={action} key={i} />
          ))}
        </Menu.MenuList>
      </Menu>
      {BillModalDialog}
    </>
  );
};
