import NumberFormat from 'react-number-format';
import { InputProps as ChakraInputProps, Input as ChakraInput } from '@chakra-ui/react';

import { FieldWrapperType } from '../../types';

import { FieldWrapper } from '../FieldWrapper';

export type MaskFieldProps = FieldWrapperType &
  Pick<ChakraInputProps, 'placeholder'> & {
    format?: string;
    mask?: string | string[];
    value?: string | number;
    onChange?: (values: any) => void;
  };

const MaskField = ({
  // field wrapper props
  label,
  helperText,
  errorMessage,
  tooltipLabel,
  leftAddon,
  rightAddon,
  value,
  onChange,

  // mask props
  format,
  mask,
  ...rest
}: MaskFieldProps) => {
  const { isDisabled } = rest;

  const input = (id) => (
    <NumberFormat
      id={id}
      customInput={ChakraInput}
      format={format}
      mask={mask}
      isInvalid={!!errorMessage}
      value={value}
      onValueChange={onChange}
      background="inherit"
      {...rest}
    />
  );

  return (
    <FieldWrapper
      input={input}
      label={label}
      helperText={helperText}
      errorMessage={errorMessage}
      tooltipLabel={tooltipLabel}
      isDisabled={isDisabled}
    />
  );
};

export { MaskField };
