import React from 'react';
import first from 'lodash/first';
import { Skeleton } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { BillType } from 'src/app/utils/types';

import { PaymentsList } from './PaymentsList';
import { FieldValue } from './shared';

type Props = {
  bill: BillType;
  bills: BillType[];
};

const PaymentTooltip = ({ bill, bills }: Props) => {
  const paymentId = first(bill.payments)?.id;

  if (!bills.length || !paymentId) return <Skeleton w="11.6rem" h="1.2rem" mt="2" mb="5" />;

  const currentPaymentIndex = bills.findIndex((item) => item.id === bill.id) + 1;
  const occurrences = bills.length;

  return (
    <FieldValue>
      <PaymentsList bills={bills} paymentId={paymentId}>
        <MIFormattedText
          label="bills.pay.confirm.occurrencesCurrent"
          values={{
            current: currentPaymentIndex,
            payments: occurrences,
          }}
        />
      </PaymentsList>
    </FieldValue>
  );
};

export { PaymentTooltip };
