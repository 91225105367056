import { useEffect } from 'react';

type Params = {
  elementId?: string;
};

const useAutoScrollToItem = ({ elementId }: Params): void => {
  useEffect(() => {
    if (elementId) {
      const element = document.getElementById(elementId);

      if (!element) return;

      const offset = Math.floor(window.innerHeight / 3) + 30;
      const position = element.getBoundingClientRect().bottom + window.scrollY;

      window.scrollTo({
        top: position - offset,
        behavior: 'smooth',
      });
    }
  }, []);
};

export { useAutoScrollToItem };
