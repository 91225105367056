import React from 'react';
import { SmartIcon } from '@melio/billpay-design-system';
import { RecordOf } from 'immutable';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { BillType, PaymentType } from 'src/app/utils/types';
import { PaymentInfoContainer } from 'src/app/components/layout/PaymentActivityElements';
import { RefundActivityItem } from './RefundActivityItem/RefundActivityItem';
import {
  Container,
  Separator,
  PaymentFailedContainer,
  ReasonContainer,
  ReasonTitleContainer,
  ReasonContentContainer,
  ReasonSeparator,
} from './RefundPaymentActivity.styles';
import { RefundPaymentsItem } from './RefundPaymentsItem/RefundPaymentsItem';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';

type Props = {
  bill: RecordOf<BillType>;
  payment: PaymentType;
  refundRequestedDate?: string;
  refundDueDate?: string | null;
  isPartialPayment: boolean;
};

export const RefundPaymentActivity = ({
  bill,
  payment,
  refundRequestedDate,
  refundDueDate,
  isPartialPayment,
}: Props) => {
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const failedRefundPaymentDate = payment.metadata?.paymentStatusDates?.refundFailureDate;
  const refundReason = payment.metadata?.refundReason;
  const etaSubtitle = !refundRequestedDate ? 'etaSubtitle' : 'by8PM';

  const CalendarIconComponent = () => <SmartIcon type="calendar" size="3.2rem" />;

  return (
    <Container $shouldAddMB={(failedRefundPaymentDate || refundReason) && !isRefundFlowFeature}>
      {isPartialPayment && (
        <>
          <RefundPaymentsItem bill={bill} paymentId={payment.id} />
          <Separator />
        </>
      )}
      {refundRequestedDate && (
        <RefundActivityItem
          title="bills.pay.requestRefund.review.requestedDate"
          IconComponent={<CalendarIconComponent />}
        >
          <MIFormattedDate date={refundRequestedDate} />
        </RefundActivityItem>
      )}
      {!failedRefundPaymentDate && (
        <>
          {refundRequestedDate && <Separator />}
          <RefundActivityItem
            title="bills.pay.requestRefund.review.eta"
            subtitle={`bills.pay.requestRefund.review.${etaSubtitle}`}
            IconComponent={<CalendarIconComponent />}
          >
            <MIFormattedDate date={refundDueDate} />
          </RefundActivityItem>
        </>
      )}
      {(refundReason || failedRefundPaymentDate) && <ReasonSeparator />}
      {failedRefundPaymentDate && (
        <PaymentInfoContainer>
          <PaymentFailedContainer>
            <MIFormattedText
              label="bills.status.paymentRefundFailed.note"
              values={{
                date: <MIFormattedDate date={failedRefundPaymentDate} />,
              }}
            />
          </PaymentFailedContainer>
        </PaymentInfoContainer>
      )}
      {refundReason && !isRefundFlowFeature && (
        <ReasonContainer>
          <ReasonTitleContainer>
            <MIFormattedText label="bills.pay.requestRefund.review.reason" />
          </ReasonTitleContainer>
          <ReasonContentContainer>
            <MIFormattedText label={`bills.pay.requestRefund.reason.reasons.${refundReason}`} />
          </ReasonContentContainer>
        </ReasonContainer>
      )}
    </Container>
  );
};
