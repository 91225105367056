import React, { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Popover, SmartIcon } from '@melio/billpay-design-system';
import { CellContext } from '@tanstack/react-table';
import { analytics } from 'src/app/version-2/externals';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { InvalidValueIndication } from 'src/app/version-2/pages/batch-bulk/components/InvalidValueIndication/InvalidValueIndication';
import { PaymentMethodMenu } from 'src/app/version-2/pages/batch-bulk/components/PaymentMethodMenu';
import {
  ADD_SELECT_FUNDING_SOURCE,
  AMEX_INDUSTRY_MODAL_OPEN,
  BATCH_BULK_EVENT_PAGE,
  CHOOSE_PAYMENT_METHOD,
  OPEN_SELECT_FUNDING_SOURCE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkPaymentIntentErrorsEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/BatchBulkPaymentIntentErrors.enum';
import { BatchBulkItem, FundingSourceItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  AddNew,
  AddNewContainer,
  PaymentMethodMenuButton,
} from 'src/app/version-2/pages/batch-bulk/renderers/PaymentMethod/PaymentMethod.renderer.styles';
import {
  ButtonWrapper,
  InfoIconTooltip,
  MissingDetailsPopoverText,
  MissingDetailsPopoverWrapper,
} from 'src/app/version-2/pages/batch-bulk/renderers/renderers.styles';

const PaymentMethodRenderer: FC<CellContext<BatchBulkItem, FundingSource>> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    row,
    row: {
      original: {
        selectedPaymentMethod,
        paymentMethods,
        id,
        vendorId,
        totalBillIds,
        totalPartialBillIds,
      },
    },
  } = props;

  const { formatMessage } = useIntl();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isSubRow = !!row.depth;

  const { errors } = row.original;
  const isNoPaymentMethodSelected = useMemo(
    () =>
      Boolean(
        errors?.find(
          (error) =>
            error.path === 'selectedPaymentMethod' &&
            error.message === BatchBulkPaymentIntentErrorsEnum.NO_PAYMENT_METHOD
        )
      ),
    [errors]
  );
  const isAmexVerificationMissing = useMemo(
    () =>
      Boolean(
        errors?.find(
          (error) =>
            error.path === 'mccCode' &&
            error.message === BatchBulkPaymentIntentErrorsEnum.AMEX_VERIFICATION_MISSING
        )
      ),
    [errors]
  );

  const onChangeHandler = useCallback(
    (fundingSourceId: any, fundingSourceType: FundingSourceTypesEnum) => {
      dispatch(
        batchBulkActions.updateFundingSource({
          selectedPaymentIntent: {
            paymentIntentId: row.original.id,
            fundingSourceId,
          },
        })
      );
      analytics.track(BATCH_BULK_EVENT_PAGE, CHOOSE_PAYMENT_METHOD, {
        fromTable: 'yes',
        billIds: totalBillIds,
        partialBillIds: totalPartialBillIds,
        vendorId,
        fundingSourceId,
        fundingSourceType,
      });
    },
    [row, totalBillIds, totalPartialBillIds, vendorId]
  );

  const openAmexVerificationModal = useCallback(() => {
    dispatch(
      batchBulkActions.setAmexVerificationModalAsOpen({
        id,
      })
    );
    analytics.track(BATCH_BULK_EVENT_PAGE, AMEX_INDUSTRY_MODAL_OPEN, {
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
    });
  }, [totalBillIds, totalPartialBillIds, vendorId]);

  const showAddMethodButton = !selectedPaymentMethod?.id && !paymentMethods?.length;

  if (isSubRow) return null;

  const createFundingSource = () => {
    dispatch(
      batchBulkActions.createFundingSource({
        selectedPaymentIntent: {
          paymentIntentId: row.original.id,
        },
        history,
      })
    );
    analytics.track(BATCH_BULK_EVENT_PAGE, ADD_SELECT_FUNDING_SOURCE, {
      fromTable: 'yes',
      billIds: totalBillIds,
      partialBillIds: totalPartialBillIds,
      vendorId,
    });
  };

  if (showAddMethodButton) {
    return (
      <AddNewContainer>
        <AddNew onClick={createFundingSource}>
          {formatMessage({
            id: 'batchBulkPage.renderers.addMethod',
          })}
        </AddNew>
        {isNoPaymentMethodSelected && <InvalidValueIndication label="Missing payment method" />}
      </AddNewContainer>
    );
  }

  const menuTriggerHandler = () => {
    setIsMenuOpen(!isMenuOpen);

    if (!isMenuOpen) {
      analytics.track(BATCH_BULK_EVENT_PAGE, OPEN_SELECT_FUNDING_SOURCE, {
        vendorId,
        billIds: totalBillIds,
        fromTable: 'yes',
        partialBillIds: totalPartialBillIds,
      });
    }
  };

  const AmexPopoverContent = () => (
    <Popover closeOnBlur placement="top" arrowColor="ds.white">
      <Popover.PopoverTrigger>
        <InfoIconTooltip $isAmex>
          <SmartIcon type="info" />
        </InfoIconTooltip>
      </Popover.PopoverTrigger>
      <Popover.PopoverContent>
        <MissingDetailsPopoverWrapper>
          <MissingDetailsPopoverText>
            {formatMessage({ id: 'batchBulkPage.popovers.amexVerification.text' })}
          </MissingDetailsPopoverText>
          <ButtonWrapper>
            <Button variant="primary" size="md" onClick={openAmexVerificationModal}>
              {formatMessage({ id: 'batchBulkPage.popovers.amexVerification.button' })}
            </Button>
          </ButtonWrapper>
        </MissingDetailsPopoverWrapper>
      </Popover.PopoverContent>
    </Popover>
  );

  return (
    <PaymentMethodMenu
      renderers={{ PaymentMethodMenuButton }}
      onMenuOpen={menuTriggerHandler}
      onMenuClose={menuTriggerHandler}
      selectedPaymentMethod={selectedPaymentMethod}
      amex
      isAmexVerificationMissing={isAmexVerificationMissing}
      AmexPopover={<AmexPopoverContent />}
      openAmexVerificationModal={openAmexVerificationModal}
      paymentMethods={paymentMethods}
      onCreateFundingSource={createFundingSource}
      onMethodSelect={(fundingSource) =>
        onChangeHandler(fundingSource.id, fundingSource.fundingType)
      }
      checkIfDisabled={(fundingSource: FundingSourceItem): boolean =>
        !!fundingSource?.meta?.isDisabled
      }
    />
  );
};

export { PaymentMethodRenderer };
