import styled from 'styled-components';

export const ListItem = styled.li`
  display: flex;
  font-size: 1.2rem;
  padding: 0.6rem 0;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray.lightGray};

  &:last-child {
    border-bottom: 0;
  }

  div:nth-child(1),
  div:nth-child(2) {
    width: 40%;
  }
  div:nth-child(3) {
    width: 20%;
    text-align: end;
    padding-right: 0.5rem;
  }
`;

export const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldValue}
`;
