import { useStyleConfig } from '@chakra-ui/react';
import { Box, BoxProps } from '@melio/billpay-design-system';
import { modalCoverStyle } from './Modal.style';

type Props = BoxProps & {
  src?: string;
  size?: 'sm' | 'md' | 'lg';
};

export const ModalCover = ({ src, size, ...rest }: Props) => {
  const style = useStyleConfig('ModalCover', { size });

  return (
    <Box __css={style} {...rest}>
      <Box as="img" __css={modalCoverStyle} src={src} />
    </Box>
  );
};
