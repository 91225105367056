import * as React from 'react';
import styled from 'styled-components';
import MINotificationCard from 'src/app/components/common/MINotificationCard';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';

type UserExistNotificationCardProps = {
  errorCode: string;
};

const UserExistErrorNotificationCard = ({ errorCode }: UserExistNotificationCardProps) => (
  <NotificationCard
    type={NOTIFICATION_CARD_TYPES.ERROR}
    subtitle={{ label: `server.${errorCode}` }}
  />
);

export { UserExistErrorNotificationCard };

const NotificationCard = styled(MINotificationCard)`
  margin: 0 0 4rem;
`;
