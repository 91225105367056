import React from 'react';
import { Box, Flex } from '@melio/billpay-design-system';
import { StyledLoader } from './PayBillsLoadingModal.styles';
import { Backdrop, ModalContainer, ModalWrapper } from 'src/app/components/common/ModalMessage';
import { MIFormattedText } from 'src/app/utils/formatting';

export const PayBillsLoadingModal = () => (
  <ModalWrapper>
    <Backdrop />
    <ModalContainer className="batch-payment-modal">
      <Flex height="32rem" justify="center" align="center" direction="column">
        <Box textStyle="h5" mb={10}>
          <MIFormattedText label="paymentDashboard.toggleOffModal.title" />
        </Box>
        <Box>
          <StyledLoader />
        </Box>
      </Flex>
    </ModalContainer>
  </ModalWrapper>
);
