import { FetchCompanyInfoResponse } from 'src/app/version-2/api/reponses/FetchCompanyInfoResponse';
import { FetchCompanyInfoRequest } from 'src/app/version-2/api/requests/FetchCompanyInfoRequest';
import { fetchRequest, putRequest } from 'src/app/version-2/externals';

export const userApi = {
  fetchProfile: () => fetchRequest('/auth/check', null, { isBypassThrowError: true }),
  updateUserPreference: ({ key, value }) => putRequest('/users/user-preferences', { key, value }),
  getCompanyInfo: ({ orgId, local = false }: FetchCompanyInfoRequest) =>
    fetchRequest<FetchCompanyInfoResponse>(`/orgs/${orgId}/company-info?local=${local}`),
};
