import React, { useMemo } from 'react';
import cx from 'classnames';
import analytics from 'src/app/services/analytics';
import { Table, Box, Flex, Checkbox } from '@melio/billpay-design-system';

import { BaseTableFilters, ColumnItem, OrderParams } from './types';
import QBOPagination from '../QBOPagination';
import { getOrderParams } from './utils';
import QBOOrderArrow from './components/QBOOrderArrow';
import { headerStyle } from './styles';
import useSelection from './hooks/useSelection';
import { useAutoScrollToItem } from './hooks/useAutoScrollToItem';

type Props<T> = {
  columns: ColumnItem<T>[];
  data: T[];
  filters: BaseTableFilters;
  totalItems: number;
  onChangeOrder: (orderParams: OrderParams) => void;
  onChangePage: (pageIndex: number) => void;
  highlightedItemIds: string[];
  selectedIds?: string[];
  setSelectedIds?: (ids: string[]) => void;
  selectionEnabled?: boolean;
  selectableItemsIds?: string[];
  rowClickSettings: {
    isRowClickable: (item: T) => boolean;
    handleRowClick: (item: T) => void;
  };
};

function QBODataTable<T extends { id: string }>(props: Props<T>) {
  const {
    columns,
    data,
    onChangeOrder,
    totalItems,
    filters,
    onChangePage,
    selectedIds,
    setSelectedIds,
    selectionEnabled,
    selectableItemsIds = [],
    rowClickSettings,
    highlightedItemIds,
  } = props;

  const showPagination = totalItems > filters.limit;
  const currentPage = filters.start / filters.limit;
  const pageCount = Math.ceil(totalItems / filters.limit);
  const { orderBy, orderDirection } = filters;

  const { isAllSelected, isIntederminate, onAllCheckboxChange, onCheckboxChange } = useSelection({
    selectedIds,
    setSelectedIds,
    selectableItemsIds,
  });

  const topMostHighlightedItemId = useMemo(
    () =>
      highlightedItemIds.length > 0
        ? data.find((item) => highlightedItemIds.includes(item.id))?.id
        : undefined,
    [highlightedItemIds, data]
  );

  useAutoScrollToItem({
    elementId: `data-table-row-${topMostHighlightedItemId}`,
  });

  const onHeaderClick = ({ accessor, isOrderingDisabled }) => {
    if (isOrderingDisabled) {
      return;
    }

    const newOrderParams = getOrderParams({
      orderBy,
      orderDirection,
      accessor,
    });

    analytics.trackAction('sort-by', { sorting: newOrderParams });
    onChangeOrder(newOrderParams);
  };

  const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

  const getHighlightedClass = (itemId: string) =>
    highlightedItemIds.includes(itemId) ? 'highlighted' : undefined;

  return (
    <Flex direction="column">
      <Table>
        <Table.Header>
          <Table.Row>
            {selectionEnabled && (
              <Table.HeaderCell w="2rem">
                <Checkbox
                  data-testid="data-table-header-checkbox"
                  isChecked={isAllSelected}
                  isDisabled={!selectableItemsIds.length}
                  isIndeterminate={isIntederminate}
                  onChange={onAllCheckboxChange}
                />
              </Table.HeaderCell>
            )}
            {columns.map(({ Header, width, isNumeric, isOrderingDisabled, accessor }) => (
              <Table.HeaderCell
                className={cx({ sortable: !isOrderingDisabled })}
                data-testid={`data-table-header-${accessor}`}
                key={accessor}
                onClick={() => onHeaderClick({ accessor, isOrderingDisabled })}
                isNumeric={isNumeric}
                w={width}
              >
                <Box
                  __css={headerStyle({
                    accessor,
                    isOrderingDisabled,
                    orderBy,
                  })}
                >
                  {Header}
                  {isOrderingDisabled ? null : (
                    <QBOOrderArrow
                      accessor={accessor}
                      orderBy={orderBy}
                      orderDirection={orderDirection}
                    />
                  )}
                </Box>
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((item) => (
            <Table.Row
              id={`data-table-row-${item.id}`}
              data-testid={`data-table-row-${item.id}`}
              key={item.id}
              className={getHighlightedClass(item.id)}
              cursor={rowClickSettings?.isRowClickable(item) ? 'pointer' : 'inherit'}
              onClick={() => rowClickSettings?.handleRowClick(item)}
            >
              {selectionEnabled && (
                <Table.Cell key={`checkbox_${item.id}`} w="2rem" overflow="initial">
                  {selectableItemsIds.includes(item.id) && (
                    <Box onClick={stopPropagation} overflow="initial">
                      <Checkbox
                        data-testid={`data-table-cell-checkbox-${item.id}`}
                        isChecked={(selectedIds || []).includes(item.id)}
                        onChange={(event) => onCheckboxChange(event, item.id)}
                      />
                    </Box>
                  )}
                </Table.Cell>
              )}
              {columns.map(({ Cell, width, isNumeric, accessor }) => (
                <Table.Cell
                  data-testid={`data-table-cell-${accessor}-${item.id}`}
                  key={`${accessor}_${item.id}`}
                  w={width}
                  isNumeric={isNumeric}
                >
                  {Cell ? <Cell item={item} /> : item[accessor]}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {showPagination && (
        <Box display="flex" justifyContent="flex-end" mt="6" mb="8">
          <QBOPagination pageIndex={currentPage} pageCount={pageCount} goToPage={onChangePage} />
        </Box>
      )}
    </Flex>
  );
}

export default QBODataTable;
