import { useCallback } from 'react';
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';

type LinkProps = ChakraLinkProps & {
  to?: any;
};

const Link = ({ children, ...rest }: LinkProps) => {
  const onClickWithEvent = useCallback(
    (event) => {
      event.stopPropagation();
      const { onClick } = rest;
      onClick?.(event);
    },
    // eslint-disable-next-line comma-dangle
    [rest]
  );

  return (
    <ChakraLink {...rest} onClick={onClickWithEvent}>
      {children}
    </ChakraLink>
  );
};

export { Link };
