import styled from 'styled-components';
import { type LoaderContext } from '../../types';

export const Loading = styled.svg<{ context?: LoaderContext }>`
  position: absolute;
  height: ${(props) => (props.context === 'button' ? 2.4 : 12)}rem;
  width: ${(props) => (props.context === 'button' ? 2.4 : 12)}rem;
  top: calc(50% - ${(props) => (props.context === 'button' ? 1.2 : 6)}rem);
  left: calc(50% - ${(props) => (props.context === 'button' ? 1.2 : 6)}rem);
  animation: spinnerrotate 4s linear infinite;
  transform-origin: center center;

  .track {
    stroke: #f4f5f8;
    stroke-width: 0.2rem;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-width: 0.2rem;
    animation: spinnerdash 4s cubic-bezier(0.15, 0, 0, 1) infinite, spinnercolor 4s linear infinite;
    stroke-linecap: round;
  }

  @keyframes spinnerrotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinnerdash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 126, 200;
      stroke-dashoffset: 0;
    }

    100% {
      stroke-dasharray: 126, 200;
      stroke-dashoffset: -126;
    }
  }

  @keyframes spinnercolor {
    0% {
      stroke: rgba(16, 128, 0, 1);
    }

    50% {
      stroke: rgba(44, 160, 28, 1);
    }

    100% {
      stroke: rgba(44, 160, 28, 1);
    }
  }
`;
