import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { PaymentType } from 'src/app/utils/types';

import {
  EXPEDITE_OPTIONS,
  TIMELINE_STEP_ACTIONS,
  TIMELINE_STEP_STAGES,
  TIMELINE_STEP_STATUSES,
  TIMELINE_STEP_TRANSLATION_ROOT_PATH,
} from '../consts';
import { TimelineStepType, VendorPaymentActionsType, VendorPaymentSectionProps } from '../types';
import { getScheduledStep } from './get-common-steps';
import { getPaymentDeductionStatus, getPaymentDeliveryStatus } from './steps-statuses';
import { useExpediteItemContext } from 'src/app/pages/vendor/vendor-payment-tracking/context/ExpediteItemContext';
import { useSelector } from 'react-redux';
import { upgradePaymentSelectors } from 'src/app/version-2/pages/vendor-upgrade-payment';
import { UpsellItem } from 'src/app/version-2/pages/vendor-upgrade-payment/model/dtos/upsellItem';
import { getFastACHTitle } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import { UpsellType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upsellType.enum';

const { FUTURE_STEP, FAILED } = TIMELINE_STEP_STATUSES;
const { DEDUCTION, DELIVERY } = TIMELINE_STEP_STAGES;

const getAchDeductionAction = ({
  deductionStatus,
  baseTranslationPath,
  vendorPaymentActions,
}: {
  deductionStatus: TIMELINE_STEP_STATUSES;
  baseTranslationPath: string;
  vendorPaymentActions: VendorPaymentActionsType;
}): Pick<TimelineStepType, 'actionLabel' | 'actionAnalyticsName' | 'onAction'> => {
  const actionByStatus = {
    [FUTURE_STEP]: {
      actionLabel: `${baseTranslationPath}.${DEDUCTION}.action.${FUTURE_STEP}`,
      actionAnalyticsName: TIMELINE_STEP_ACTIONS.EDIT_BANK_DETAILS,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.EDIT_BANK_DETAILS],
    },
    [FAILED]: {
      actionLabel: `${baseTranslationPath}.${DEDUCTION}.action.${FAILED}`,
      actionAnalyticsName: TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.CONTACT_SUPPORT],
    },
  };

  return actionByStatus[deductionStatus] || {};
};

const getAchDeliveryAction = ({
  baseTranslationPath,
  vendorPaymentActions,
  expediteType,
  upsellItems,
}: {
  baseTranslationPath: string;
  vendorPaymentActions: VendorPaymentActionsType;
  expediteType: EXPEDITE_OPTIONS | null;
  upsellItems?: UpsellItem[];
}): Pick<
  TimelineStepType,
  'actionLabel' | 'actionAnalyticsName' | 'tooltipLabel' | 'onAction' | 'textValues'
> => {
  if (expediteType === EXPEDITE_OPTIONS.TO_PUSH_TO_RTP) {
    return {
      actionLabel: `${baseTranslationPath}.${DELIVERY}.action.${FUTURE_STEP}.${EXPEDITE_OPTIONS.TO_PUSH_TO_RTP}.text`,
      actionAnalyticsName: EXPEDITE_OPTIONS.TO_PUSH_TO_RTP,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.TO_PUSH_TO_RTP],
    };
  }

  if (expediteType === EXPEDITE_OPTIONS.TO_PUSH_TO_FAST_ACH) {
    const fastACHUpsellItem = upsellItems?.find(({ type }) => type === UpsellType.PUSH_TO_FAST_ACH);
    const { label, values } = getFastACHTitle(
      `${baseTranslationPath}.${DELIVERY}.action.${FUTURE_STEP}.${EXPEDITE_OPTIONS.TO_PUSH_TO_FAST_ACH}`,
      fastACHUpsellItem?.deliveryDate
    );

    return {
      actionLabel: label,
      actionAnalyticsName: EXPEDITE_OPTIONS.TO_PUSH_TO_FAST_ACH,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.TO_PUSH_TO_FAST_ACH],
      textValues: {
        actionLabel: values,
      },
    };
  }

  if (expediteType === EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT) {
    return {
      actionLabel: `${baseTranslationPath}.${DELIVERY}.action.${FUTURE_STEP}.${EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT}.text`,
      actionAnalyticsName: EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT,
      tooltipLabel: `${baseTranslationPath}.${DELIVERY}.action.${FUTURE_STEP}.${EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT}.tooltipLabel`,
      onAction: vendorPaymentActions[TIMELINE_STEP_ACTIONS.TO_PUSH_TO_DEBIT],
    };
  }

  return {};
};

const getAchDeductionStep = ({
  baseTranslationPath,
  deductionStatus,
  payment,
}: {
  baseTranslationPath: string;
  deductionStatus: TIMELINE_STEP_STATUSES;
  payment: PaymentType;
}): TimelineStepType => ({
  title: `${baseTranslationPath}.${DEDUCTION}.title.${deductionStatus}`,
  subtitle: `${baseTranslationPath}.${DEDUCTION}.subtitle`,
  subtitleValues: {
    lastBankAccountDigits: getAccountNumber4digits(payment.deliveryMethod.bankAccount),
  },
  dates: [payment.scheduledDate],
  status: deductionStatus,
});

const getAchDeliveryStep = ({
  baseTranslationPath,
  deliveryStatus,
  payment,
}: {
  baseTranslationPath: string;
  deliveryStatus: TIMELINE_STEP_STATUSES;
  payment: PaymentType;
}): TimelineStepType => ({
  title: `${baseTranslationPath}.${DELIVERY}.title.${deliveryStatus}`,
  dates: [payment.deliveryEta],
  status: deliveryStatus,
});

const useGetScheduledStep = ({ payment }: { payment: PaymentType }) => {
  const deductionStatus = getPaymentDeductionStatus(payment);
  const scheduledStep = { ...getScheduledStep(payment), nextStepStatus: deductionStatus };

  return { scheduledStep };
};

const useGetAchDeductionStep = ({
  payment,
  vendorPaymentActions,
  baseTranslationPath,
  deliveryStatus,
}: {
  payment: PaymentType;
  vendorPaymentActions: VendorPaymentActionsType;
  baseTranslationPath: string;
  deliveryStatus: TIMELINE_STEP_STATUSES;
}) => {
  const deductionStatus = getPaymentDeductionStatus(payment);

  const deductionStep = getAchDeductionStep({ baseTranslationPath, deductionStatus, payment });
  const deductionStepAction = getAchDeductionAction({
    deductionStatus,
    baseTranslationPath,
    vendorPaymentActions,
  });

  return {
    deductionStep: {
      ...deductionStep,
      ...deductionStepAction,
      ...(deductionStatus !== FAILED && {
        nextStepStatus: deliveryStatus,
      }),
    },
  };
};

const useGetAchDeliveryStep = ({
  payment,
  vendorPaymentActions,
  baseTranslationPath,
  deliveryStatus,
}: {
  payment: PaymentType;
  vendorPaymentActions: VendorPaymentActionsType;
  baseTranslationPath: string;
  deliveryStatus: TIMELINE_STEP_STATUSES;
}) => {
  const { expediteType } = useExpediteItemContext();
  const upsellItems = useSelector(upgradePaymentSelectors.selectUpsellItems);

  const deliveryStep = getAchDeliveryStep({ baseTranslationPath, payment, deliveryStatus });
  const deliveryStepAction = getAchDeliveryAction({
    baseTranslationPath,
    vendorPaymentActions,
    expediteType,
    upsellItems,
  });

  return {
    deliveryStep: {
      ...deliveryStep,
      ...deliveryStepAction,
    },
  };
};

const useGetAchSteps = ({
  payment,
  vendorPaymentActions = {},
}: VendorPaymentSectionProps): TimelineStepType[] => {
  const baseTranslationPath = `${TIMELINE_STEP_TRANSLATION_ROOT_PATH}.ach`;
  const deductionStatus = getPaymentDeductionStatus(payment);
  const deliveryStatus = getPaymentDeliveryStatus(payment);

  const { scheduledStep } = useGetScheduledStep({ payment });
  const { deductionStep } = useGetAchDeductionStep({
    payment,
    vendorPaymentActions,
    baseTranslationPath,
    deliveryStatus,
  });
  const { deliveryStep } = useGetAchDeliveryStep({
    payment,
    vendorPaymentActions,
    baseTranslationPath,
    deliveryStatus,
  });

  if (deductionStatus === FAILED) {
    return [scheduledStep, deductionStep];
  }

  return [scheduledStep, deductionStep, deliveryStep];
};

export { useGetAchSteps };
