import React from 'react';
import {
  MIFormattedText,
  MIFormattedDate,
  MIFormattedCurrency,
  getFormattedAddress,
} from 'src/app/utils/formatting';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { InfoLayout, ReviewGridItem } from 'src/app/pages/vendor/virtual-card-details/components';
import {
  useStateChangedAnalytics,
  VirtualCardPaymentState,
} from 'src/app/pages/vendor/virtual-card-details/hooks';
import { useShiftVirtualCardData } from './hooks/useShiftVirtualCardData';

type Props = {
  token: string;
};

const NewDeliveryMethodScheduled = ({ token }: Props) => {
  const { payment, organization, deliveryMethod } = useShiftVirtualCardData({
    token,
  });

  useStateChangedAnalytics({
    payment,
    paymentState: VirtualCardPaymentState.NewDeliveryMethodScheduled,
  });

  if (!deliveryMethod) {
    return null;
  }

  const renderDeliveryMethod = () => {
    if (deliveryMethod?.deliveryType === DELIVERY_TYPE.ACH) {
      return (
        <ReviewGridItem
          icon="icon-bank-icon"
          label={
            <MIFormattedText label="vendors.virtualCardDetails.newDeliveryMethodScheduled.ach.bankTransferTo" />
          }
          value={
            <MIFormattedText
              label="vendors.virtualCardDetails.newDeliveryMethodScheduled.ach.bankAccount"
              values={{
                digits: getAccountNumber4digits(deliveryMethod?.bankAccount),
              }}
            />
          }
        />
      );
    }

    if (deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK) {
      const { paperCheck } = deliveryMethod as any;

      return (
        <ReviewGridItem
          icon="icon-check-icon"
          label={
            <MIFormattedText label="vendors.virtualCardDetails.newDeliveryMethodScheduled.check.paperCheckTransferTo" />
          }
          value={
            <MIFormattedText
              label="vendors.virtualCardDetails.newDeliveryMethodScheduled.check.paperCheck"
              values={{
                displayName: getFormattedAddress(paperCheck),
              }}
            />
          }
        />
      );
    }

    return null;
  };

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <InfoLayout.Icon type={InfoLayout.IconType.Check} />
      <InfoLayout.Title>
        <MIFormattedText
          label={`vendors.virtualCardDetails.newDeliveryMethodScheduled.${deliveryMethod?.deliveryType}.title`}
        />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label={`vendors.virtualCardDetails.newDeliveryMethodScheduled.${deliveryMethod?.deliveryType}.subtitle`}
          values={{
            name: organization?.companyName,
            amount: <MIFormattedCurrency value={payment?.amount?.toString() || ''} />,
          }}
        />
      </InfoLayout.Subtitle>
      <InfoLayout.Separator />
      <InfoLayout.SectionTitle>
        <MIFormattedText label="vendors.virtualCardDetails.newDeliveryMethodScheduled.reviewSectionTitle" />
      </InfoLayout.SectionTitle>
      <InfoLayout.Grid>
        {renderDeliveryMethod()}
        <ReviewGridItem
          icon="icon-eta-cal"
          label={<MIFormattedText label="vendors.virtualCardDetails.common.deliveryOn" />}
          value={<MIFormattedDate date={payment?.deliveryEta} />}
        />
      </InfoLayout.Grid>
    </InfoLayout>
  );
};

export { NewDeliveryMethodScheduled };
