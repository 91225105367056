import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { getNameParts } from 'src/app/utils/funding-sources';
import { FundingSourceTypesEnum, CardTypeEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Params = {
  fundingSource: FundingSource;
};

const useGetFundingSourceDisplayName = () => {
  const intl = useIntl();
  const getFundingSourceDisplayName = useCallback(
    ({ fundingSource }: Params): string => {
      if (!fundingSource) {
        return '';
      }

      let label = 'bills.pay.fundingSource.achLabel';

      if (fundingSource.fundingType === FundingSourceTypesEnum.CARD) {
        label =
          fundingSource.cardAccount?.cardType === CardTypeEnum.CREDIT
            ? 'bills.pay.fundingSource.creditLabel'
            : 'bills.pay.fundingSource.debitLabel';
      }

      const nameParts = getNameParts(fundingSource);

      return intl.formatMessage({ id: label }, nameParts);
    },
    [intl]
  );

  return { getFundingSourceDisplayName };
};

export default useGetFundingSourceDisplayName;
