import React from 'react';
import styled from 'styled-components';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { AreaLoader } from '@melio/billpay-design-system';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import QBRLayoutPage from 'src/app/components/layout/QBRLayoutPage';
import { NOTE_FIELD_MAX_LENGTH } from 'src/app/version-2/model/constants';

type Props = {
  onPrev: () => void;
  onNext: () => void;
  goExit: () => void;
  onChange: (memo: string) => void;
  memo?: string | null;
  amount: string;
  companyName: string;
  billId: string | null;
  headerLabel: string;
  relativeStep?: number;
  title: string;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  titleValues?: Record<string, any>;
  label: string;
  labelValues?: Record<string, any>;
  hint: string;
  maxLength?: number;
  hintValues?: Record<string, any>;
};
const QBRPayBillMemoPage = ({
  memo,
  onNext,
  onPrev,
  goExit,
  onChange,
  amount,
  companyName,
  billId,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  subtitle,
  subTitleValues,
  labelValues,
  label,
  hint,
  maxLength = NOTE_FIELD_MAX_LENGTH,
  hintValues,
}: Props) => {
  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={amount} />
      </strong>
    ),
    companyName: <strong>{companyName}</strong>,
  };

  const onChangeEdit = ({ value }) => onChange(value);

  if (!billId) {
    return <AreaLoader />;
  }

  return (
    <QBRLayoutPage
      headerLabel={headerLabel}
      headerLabelValues={headerLabelValues}
      title={title}
      titleValues={titleValues}
      subtitle={subtitle}
      subTitleValues={subTitleValues}
      goExit={goExit}
      onPrev={onPrev}
      onNext={onNext}
      relativeStep={relativeStep}
    >
      <MemoPageContainer>
        <MITextInput
          id="memo"
          label={label}
          labelValues={labelValues}
          placeholder="bills.pay.memo.placeholder"
          notices={[hint]}
          noticeValues={{ ...hintValues }}
          value={memo}
          autoFocus
          type="text"
          maxlength={maxLength}
          onSubmit={onNext}
          onChange={onChangeEdit}
          privateData
        />
      </MemoPageContainer>
    </QBRLayoutPage>
  );
};

const MemoPageContainer = styled.div`
  margin-top: 3.2rem;
`;

export default QBRPayBillMemoPage;
