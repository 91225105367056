import React, { useMemo, useState, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/fp/set';
import map from 'lodash/map';
import join from 'lodash/join';
import findIndex from 'lodash/findIndex';
import styled from 'styled-components';
import { featureFlags } from '@melio/shared-web';
import { Box, Flex, Link } from '@melio/billpay-design-system';

import { CheckType, EditableDeliveryMethodType, ReferrerType } from 'src/app/utils/types';
import { AreaLoader } from '@melio/billpay-design-system';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { SupportLine } from 'src/app/components/common/OutsideLayout/SupportLine';
import { MIFormattedText } from 'src/app/utils/formatting';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { ModelView, useForm } from 'src/app/ui/form';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import exclamationMark from 'src/app/images/qbo/exclamation-mark-icon.svg';
import {
  CONSTS,
  DELIVERY_TYPE,
  CATEGORY_LIST_VIEW_VARIANTS,
  TOKEN_ACTIONS,
  REGEX,
  TAG_VARIANT,
} from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';
import { MICategoryList } from 'src/app/components/common/MICategoryList';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import { convertPaperCheck } from 'src/app/utils/address';
import { formatCheckPrintName } from 'src/app/utils/delivery-methods';
import MIButton from 'src/app/components/common/MIButton';
import { Header, ContentWrapper } from 'src/app/pages/vendor/components/VendorLayoutElements';
import NewDeliveryMethodForm from 'src/app/pages/vendor/components/NewDeliveryMethodForm';
import { HolidaysWarning } from 'src/app/components/common/HolidaysWarning';
import MITag from 'src/app/components/common/MITag';
import analytics from 'src/app/services/analytics';
import { analyticsApi } from 'src/app/services/analytics/guest-analytics-api';
import orderBy from 'lodash/orderBy';
import { isJPMTransaction } from 'src/app/utils/payments';
import { getJWTPayload } from 'src/app/helpers/jwt';
import includes from 'lodash/includes';
import QBOWhitePageAddressContainer from './components/QBOWhitePageAddressContainer';
import {
  UnilateralDeliveryMethodType,
  UnilateralPaymentActions,
  UnilateralPaymentProps,
  UnilateralPaymentState,
} from './hoc/unilateral-payment-hooks';

import * as VIRTUAL_CARD_LOCATIONS from '../virtual-card-details/locations';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';

type CategoryType = {
  id: string;
  label: string;
  icon: string;
  hint: string;
  info?: React.ReactNode;
  ribbon?: React.ReactNode;
  includeArrow?: boolean;
  iconTooltip?: {
    label: string;
    title: string;
    subtitle: string;
  };
};

const getRegularCategories = (isCardFundingSource: boolean): CategoryType[] => [
  {
    id: 'ach',
    label: 'vendors.addDeliveryMethodByLink.achCategoryLabel',
    icon: 'icon-bank-icon',
    hint: isCardFundingSource
      ? 'vendors.addDeliveryMethodByLink.cardToAchHint'
      : 'vendors.addDeliveryMethodByLink.achHint',
    ribbon: (
      <MITag
        label="vendors.unilateralWithoutPayment.ribbon.recommended"
        variant={TAG_VARIANT.BRAND}
        isSecondary
      />
    ),
  },
  {
    id: 'check',
    label: 'vendors.addDeliveryMethodByLink.checkCategoryLabel',
    icon: 'icon-check-icon',
    hint: 'vendors.addDeliveryMethodByLink.checkHint',
    info: (
      <Flex justifyContent="center" alignItems="center" color="gray.500">
        <Box>
          <MIFormattedText label="holidaysWarning.labels.unilateral.check" />
        </Box>
        <Box>
          <HolidaysWarning tooltipLabel="holidaysWarning.tooltipLabels.default" />
        </Box>
      </Flex>
    ),
  },
];

type Props = {
  state: UnilateralPaymentState;
  actions: UnilateralPaymentActions;
  deliveryMethod: UnilateralDeliveryMethodType;
  isShiftVirtualCard: boolean;
} & UnilateralPaymentProps;

type AnalyticsPropsType = {
  vendorId: number;
  paymentId: string;
  vendorEmail: string;
  payorOrgId?: number;
  deliveryMethodsType: string;
  bpVirtualCardOptInFeatureFlag: boolean;
  isEligibleToVirtualCard?: boolean;
  triggerFlow: string;
};

const QBOVendorSelectDeliveryMethodPage = (props: Props) => {
  const site = useSiteContext();
  const history = useHistory();
  const [bpVirtualCardOptInFeatureFlag, isBpVirtualCardOptInFeatureFlagLoading] =
    featureFlags.useFeature<boolean>('bp-virtual-card-opt-in');
  const [showHolidaysWarningLD] = featureFlags.useFeature('us-holidays-checks');
  const [analyticsProps, setAnalyticsProps] = useState<AnalyticsPropsType>();
  const [isVirtualCardMode, setIsVirtualCardMode] = useState<boolean>(false);
  const { token, state } = props;
  const {
    actions: payloadActions,
    unilateralRequestId,
    isVirtualCardOptInFFEnabled,
  } = getJWTPayload(token);
  const isShiftVirtualCardToACHAction = includes(
    payloadActions,
    TOKEN_ACTIONS.shiftVirtualCardToACH
  );

  const isCardFundingSource =
    state?.payment?.fundingSource?.fundingType === FundingSourceTypesEnum.CARD;

  const virtualCardCategories: CategoryType[] = [
    {
      id: 'virtual-card',
      label: 'vendors.addDeliveryMethodByLink.virtualCardCategories.virtualCard.label',
      icon: 'icon-credit-card-icon',
      hint: 'vendors.addDeliveryMethodByLink.virtualCardCategories.virtualCard.hint',
      includeArrow: true,
      info: (
        <Flex
          maxWidth="full"
          justifyContent="center"
          alignItems="center"
          textStyle="body4"
          color="blue.500"
          fontWeight="normal"
          onClick={() =>
            history.push(
              generatePath(VIRTUAL_CARD_LOCATIONS.default.infoPage, { token: props.token }),
              {
                payment,
                referrer: ReferrerType.UNILATERAL,
              }
            )
          }
        >
          <MIFormattedText
            label="vendors.addDeliveryMethodByLink.virtualCardCategories.virtualCard.learnMore"
            values={{
              link: (...chunks) => <Link cursor="pointer">{React.Children.toArray(chunks)}</Link>,
            }}
          />
        </Flex>
      ),
    },
    {
      id: 'ach',
      label: 'vendors.addDeliveryMethodByLink.virtualCardCategories.ach.label',
      icon: 'icon-bank-icon',
      hint: isCardFundingSource
        ? 'vendors.addDeliveryMethodByLink.virtualCardCategories.ach.cardToAchHint'
        : 'vendors.addDeliveryMethodByLink.virtualCardCategories.ach.hint',
      includeArrow: true,
    },
    {
      id: 'check',
      label: 'vendors.addDeliveryMethodByLink.checkCategoryLabel',
      icon: 'icon-check-icon',
      hint: 'vendors.addDeliveryMethodByLink.checkHint',
      includeArrow: true,
      info: (
        <Flex justifyContent="center" alignItems="center" color="gray.500">
          <Box>
            <MIFormattedText label="holidaysWarning.labels.unilateral.check" />
          </Box>
          <Box>
            <HolidaysWarning tooltipLabel="holidaysWarning.tooltipLabels.default" />
          </Box>
        </Flex>
      ),
    },
  ];

  const {
    actions,
    deliveryMethod,
    state: { isPaymentLoading, isDeliveryMethodProcessing, deliveryType, payment, organization },
    isShiftVirtualCard,
  } = props;

  const { onChange, onSubmit } = actions;
  const [deliveryMethodMV, deliveryMethodMVActions] = useForm(deliveryMethod, {
    submit: (value) => {
      const { deliveryType, ...rest } = value;

      analyticsApi.track({
        token,
        eventName: 'RequestDM-VendorCompleted',
        properties: {
          flow: payment ? 'Unilateral with payment' : 'Unilateral without payment',
          vendorId: payment?.vendor?.id,
          paymentId: payment?.id,
          vendorEmail: payment?.vendor?.contactEmail,
          payorOrgId: organization?.id,
          unilateralRequestId,
        },
      });

      if (deliveryType) {
        if (deliveryType === 'check') {
          value.paperCheck.printName = formatCheckPrintName(value.paperCheck.printName);
        }

        return onSubmit({ deliveryType, ...rest }, false, bpVirtualCardOptInFeatureFlag);
      }

      return Promise.reject(new Error('Delivery type not selected'));
    },
    onChange: ({ key, value, modelState }) => {
      let newState = modelState;

      if (key === 'paperCheck.printName' && value) {
        const isInvalid = REGEX.CHECK_PRINT_NAME.test(value);
        const formattedValue = value.replace(REGEX.CHECK_PRINT_NAME, '');

        if (isInvalid) {
          const { setError } = deliveryMethodMV.paperCheck.printName;

          setError?.('inputErrors.deliveryMethodCheck.printName.any.invalidChar');
        }

        newState = set('paperCheck.printName', formattedValue, modelState);
      }

      return newState;
    },
  });

  const deliveryMethods = payment?.vendor?.deliveryMethods || [];
  const virtualCardDeliveryMethod = deliveryMethods.find(
    (dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD
  );

  const headerHandler = () => {
    if (deliveryType === CONSTS.DELIVERY_TYPE.CHECK && whitePageAddressView) {
      return setWhitePageAddressView(false);
    }

    if (deliveryMethod.deliveryType) {
      return selectDeliveryMethod('');
    }

    return null;
  };

  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId)
  );
  const [whitePageAddressView, setWhitePageAddressView] = useState(false);
  const paperCheck = useMemo<CheckType>(
    () => convertPaperCheck(deliveryMethodMV.paperCheck as ModelView<CheckType>),
    [deliveryMethodMV.paperCheck]
  );

  useEffect(() => {
    setWhitePageAddressView(
      whitePageAddress &&
        !isEmpty(whitePageAddress) &&
        ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid)
    );

    if (whitePageAddress?.is_valid && !whitePageAddress.diff) {
      onSubmit(
        { deliveryType: CONSTS.DELIVERY_TYPE.CHECK, paperCheck },
        true,
        bpVirtualCardOptInFeatureFlag
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitePageAddress]);

  useEffect(() => {
    if (payment && !isBpVirtualCardOptInFeatureFlagLoading) {
      let isVirtualCard;

      if (bpVirtualCardOptInFeatureFlag) {
        isVirtualCard = payment?.isEligibleToVirtualCard;

        if (!isEmpty(payment.transactions)) {
          isVirtualCard = isJPMTransaction(payment.transactions);
        }
      } else {
        isVirtualCard = !!virtualCardDeliveryMethod && payment?.isEligibleToVirtualCard;
      }

      setIsVirtualCardMode(isVirtualCard);

      const category =
        isVirtualCard &&
        !isShiftVirtualCard &&
        !virtualCardDeliveryMethod &&
        isVirtualCardOptInFFEnabled
          ? virtualCardCategories
          : getRegularCategories(isCardFundingSource);

      const deliveryMethodsType = isShiftVirtualCardToACHAction
        ? 'ach'
        : join(map(orderBy(category, 'id'), 'id'), '_');

      setAnalyticsProps({
        vendorId: payment.vendor.id,
        paymentId: payment.id,
        vendorEmail: payment.vendor?.contactEmail,
        payorOrgId: payment.organization.id,
        deliveryMethodsType,
        bpVirtualCardOptInFeatureFlag,
        isEligibleToVirtualCard: payment.isEligibleToVirtualCard,
        triggerFlow: join(payloadActions, '_'),
      });

      analyticsApi.track({
        token,
        eventName: 'RequestDM-VendorOpened',
        properties: {
          organizationId: organization?.id,
          vendorId: payment.vendor.id,
          deliveryMethodOptions: category.map((c) => c.id),
          paymentId: payment.id,
          unilateralRequestId,
        },
      });
    }
  }, [payment, isBpVirtualCardOptInFeatureFlagLoading]);

  useEffect(() => {
    if (analyticsProps) {
      analytics.trackAction('add-delivery-method-page-loaded', { ...analyticsProps });
    }
  }, [analyticsProps]);

  useEffect(() => {
    if (isShiftVirtualCardToACHAction) {
      analytics.trackAction('vendor-add-ach-bank-details-page-viewed', {
        paymentId: payment.id,
        triggerFlow: join(payloadActions, '_'),
      });
    }
  }, [payment]);

  const selectDeliveryMethod = (type) => {
    onChange({ id: 'deliveryType', value: type });

    if (type === DELIVERY_TYPE.VIRTUAL_CARD) {
      onSubmit(
        {
          deliveryType: DELIVERY_TYPE.VIRTUAL_CARD,
          id: virtualCardDeliveryMethod?.id,
          virtualCardAccount: deliveryMethod?.virtualCardAccount,
        } as EditableDeliveryMethodType,
        true,
        bpVirtualCardOptInFeatureFlag
      );
    }
  };

  const isVirtualCardProcessing =
    isDeliveryMethodProcessing && deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;

  const billPaymentsLength = payment?.billPayments?.length || 0;
  const isBulkPayments = billPaymentsLength > 1;

  if (isPaymentLoading || isVirtualCardProcessing) {
    return <AreaLoader />;
  }

  const renderContent = () => {
    if (whitePageAddressView && deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
      return (
        <QBOWhitePageAddressContainer
          setWhitePageAddressView={setWhitePageAddressView}
          isLoading={isDeliveryMethodProcessing || isAddressLoading}
          whitePageAddress={whitePageAddress}
          submit={(deliveryMethod, isAddressVerified) =>
            onSubmit(deliveryMethod, isAddressVerified, bpVirtualCardOptInFeatureFlag)
          }
          model={deliveryMethodMV.paperCheck}
        />
      );
    }

    if (deliveryMethod.deliveryType) {
      return (
        <ContentWrapper
          title={
            deliveryType === CONSTS.DELIVERY_TYPE.ACH
              ? 'vendors.addDeliveryMethodByLink.achTitle'
              : 'vendors.addDeliveryMethodByLink.checkTitle'
          }
        >
          <NewDeliveryMethodForm
            submit={deliveryMethodMVActions.submit}
            selectedDeliveryMethodType={deliveryType}
            achModel={deliveryMethodMV.bankAccount}
            checkModel={deliveryMethodMV.paperCheck}
          />
          <MIButton
            label="vendors.addDeliveryMethodByLink.submitCTA"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={deliveryMethodMVActions.submit}
            isProcessing={isDeliveryMethodProcessing || isAddressLoading}
            fullWidth
          />
          <Info>
            <ExclamationMark src={exclamationMark} />
            <InfoText>
              <MIFormattedText
                label="vendors.deliveryMethods.ach.achDetails.info"
                values={{
                  termsOfService: (
                    <SupportLink href={site.config.agreementLinks.termsOfService} target="_blank">
                      <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.termsOfService" />
                    </SupportLink>
                  ),
                  privacyPolicy: (
                    <SupportLink href={site.config.agreementLinks.privacyPolicy} target="_blank">
                      <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.privacyPolicy" />
                    </SupportLink>
                  ),
                  // eslint-disable-next-line max-len
                  intuitPrivacyPolicy: (
                    <SupportLink
                      href={site.config.agreementLinks.intuitPrivacyPolicy}
                      target="_blank"
                    >
                      <MIFormattedText label="vendors.deliveryMethods.ach.changeSuccess.intuitPrivacyPolicy" />
                    </SupportLink>
                  ),
                }}
              />
            </InfoText>
          </Info>
        </ContentWrapper>
      );
    }

    const categories =
      isVirtualCardMode &&
      !isShiftVirtualCard &&
      !payment.deliveryMethod.virtualCardAccount &&
      !virtualCardDeliveryMethod &&
      isVirtualCardOptInFFEnabled
        ? virtualCardCategories
        : getRegularCategories(isCardFundingSource);

    if (!showHolidaysWarningLD) {
      const checkCategoryIndex = findIndex(categories, { id: 'check' });

      categories[checkCategoryIndex].info = undefined;
    }

    const contentTitle = isVirtualCardMode
      ? 'vendors.addDeliveryMethodByLink.selectTitleVirtualCard'
      : 'vendors.addDeliveryMethodByLink.selectTitle';

    return (
      <ContentWrapper title={contentTitle}>
        <CategoryListContainer>
          <MICategoryList
            categories={categories}
            selectedId={deliveryType}
            onSelect={(type) => selectDeliveryMethod(type)}
            analyticsProps={analyticsProps}
            withShadow={false}
            viewVariant={CATEGORY_LIST_VIEW_VARIANTS.COLUMN}
          />
        </CategoryListContainer>
      </ContentWrapper>
    );
  };

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success" innerSize={60}>
      <QBLogo src={qbLogo} data-testid="qb-logo" />
      <Content>
        <Header
          payment={payment}
          organization={organization}
          onPrev={
            deliveryMethod.deliveryType && !isShiftVirtualCardToACHAction ? headerHandler : null
          }
          showFullHeader
          hideLogo
          filesUrls={{ filePreviewUrls: [payment?.bill?.invoiceNumber] }}
          note={payment?.note}
          subTitle="vendors.addDeliveryMethodByLink.subTitle"
          multipleInvoices={isBulkPayments ? billPaymentsLength : undefined}
        />
        <Divider />
        {renderContent()}
      </Content>
      <SupportLine __css={supportLineStyle} />
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  box-sizing: border-box;
  border-radius: 0.6rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
`;

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 2rem;
`;
const Divider = styled.div`
  background-color: ${(props) => props.theme.background.wizard};
  height: 3px;
  margin: 0 4rem;
`;

const CategoryListContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: -1.5rem;
  justify-content: center;
  @media ${devices.mobile}, ${devices.phablet} {
    & > label {
      width: 100%;
    }
  }
`;

const Info = styled.div`
  margin-top: 2rem;
  display: flex;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  color: ${(props) => props.theme.text.color.subtitle};
`;

const InfoText = styled.div`
  text-align: left;
  line-height: ${(props) => props.theme.text.lineHeight.hint};
`;

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.text.color.link};
  cursor: pointer;
`;

const ExclamationMark = styled.img`
  width: 2rem;
  margin-right: 1.2rem;
  align-self: end;
`;

const supportLineStyle = {
  m: ['2rem 0 5rem', '2rem 0'],
};

export default QBOVendorSelectDeliveryMethodPage;
