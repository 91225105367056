import React, { useMemo } from 'react';
import styled from 'styled-components';

import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import MIAddressAutocompleteSmartyStreets from 'src/app/components/common/MIAddressAutocompleteSmartyStreets';
import { MIFormattedText, getFormattedAddress } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';
import masking from 'src/app/utils/masking';
import { AddressType } from 'src/app/utils/types';
import MINotices from 'src/app/components/common/MINotices';
import FormTitle from './FormTitle';
import { shouldDisplayDelete, shouldDisplayAdd, getNextForm, uboTaxIdMask } from './utils';
import { ActiveForms } from './types';

type Props = {
  namespace: string;
  values: Record<string, any>; // local values
  activeForms: ActiveForms;
  validationErrors: Record<string, string>; // local errors
  validateForms: () => Record<string, any>;
  setActiveForms: (arr) => void;
  setValidationErrors: (errors: any) => void;
};
function Form({
  namespace,
  values,
  activeForms,
  validationErrors,
  validateForms,
  setActiveForms,
  setValidationErrors,
}: Props) {
  const hasDeleteButton = shouldDisplayDelete(activeForms);
  const hasAddButton = useMemo(() => shouldDisplayAdd(activeForms, namespace), [activeForms]);
  const nextFormName = useMemo(() => getNextForm(activeForms), [activeForms]);

  const onAddOwnerClick = () => {
    const { isValid, errors } = validateForms();

    analytics.track('pay-bill', 'ubo-add-owner', {
      errors,
      count: isValid ? activeForms.length + 1 : activeForms.length,
    });

    // local form has error
    if (!isValid) return;

    // success - add to active forms
    setActiveForms((prev) => [...prev, nextFormName]);
  };

  const onRemoveOwnerClick = () => {
    setActiveForms((prev) => prev.filter((key) => key !== namespace));

    // reset form values (if user will re-add it)
    Object.values(values).forEach((field) => field.onChange({ value: '' }));
    setValidationErrors((errors) => ({
      ...errors,
      [namespace]: {},
    }));

    analytics.track('pay-bill', 'ubo-remove-owner', {
      count: activeForms.length - 1,
    });
  };

  const onChange: any = ({ id, value }) => values[id].onChange({ value });

  const onChangeSuggestedAddress = async (addressValues: AddressType | null) => {
    if (!addressValues) {
      values.address.onChange({ value: '' });

      return;
    }

    const formattedAddress = getFormattedAddress(addressValues);

    const { zipCode, city, state, addressLine1 } = addressValues;

    // saving display input text
    values.addressText.onChange({ value: formattedAddress });

    // saving payload data
    values.zipCode.onChange({ value: zipCode });
    values.city.onChange({ value: city });
    values.state.onChange({ value: state });
    values.address.onChange({ value: addressLine1 });
  };

  return (
    <StyledForm>
      <FormTitle />
      <StyledFormRow>
        <InputStyled
          id="firstName"
          label="international.ultimateBeneficialOwners.inputLabels.firstName"
          onChange={onChange}
          value={values.firstName.value}
          errorMessage={validationErrors.firstName}
          required
          privateData
        />
        <InputStyled
          id="lastName"
          label="international.ultimateBeneficialOwners.inputLabels.lastName"
          onChange={onChange}
          value={values.lastName.value}
          errorMessage={validationErrors.lastName}
          required
          privateData
        />
      </StyledFormRow>
      <StyledFormRow>
        <InputStyled
          id="dateOfBirth"
          label="international.ultimateBeneficialOwners.inputLabels.dateOfBirth"
          placeholder="international.ultimateBeneficialOwners.inputPlaceholders.dateOfBirth"
          onChange={onChange}
          value={values.dateOfBirth.value}
          errorMessage={validationErrors.dateOfBirth}
          mask={masking.DATE}
          required
          privateData
        />
        <InputStyled
          id="taxId"
          label="international.ultimateBeneficialOwners.inputLabels.taxId"
          placeholder="international.ultimateBeneficialOwners.inputPlaceholders.taxId"
          onChange={onChange}
          value={values.taxId.value}
          errorMessage={validationErrors.taxId}
          tooltipLabel="international.ultimateBeneficialOwners.taxTypeTooltipContent"
          required
          privateData
          mask={uboTaxIdMask}
        />
      </StyledFormRow>
      <AddressAutocompleteContainer>
        <MIAddressAutocompleteSmartyStreets
          id="addressText"
          label="international.ultimateBeneficialOwners.inputLabels.address"
          placeholder="international.ultimateBeneficialOwners.inputPlaceholders.address"
          model={values.addressText}
          onSelected={onChangeSuggestedAddress}
          hideError
          required
          autocomplete="off"
          eventPage="vendor-delivery-method"
        />
        <MINotices
          notices={['international.ultimateBeneficialOwners.inputNotices.address']}
          errorMessage={validationErrors.address}
        />
      </AddressAutocompleteContainer>
      <StyledButtons>
        {hasAddButton && (
          <StyledAddButton onClick={onAddOwnerClick}>
            <StyledPlusIcon className="icon-plus-icon" />
            <MIFormattedText label="international.ultimateBeneficialOwners.buttons.add" />
          </StyledAddButton>
        )}

        {hasDeleteButton && (
          <StyledDeleteButton onClick={onRemoveOwnerClick}>
            <MIFormattedText label="international.ultimateBeneficialOwners.buttons.remove" />
          </StyledDeleteButton>
        )}
      </StyledButtons>
    </StyledForm>
  );
}

export default Form;
const StyledButtons = styled.div`
  display: flex;
  margin-top: 2.4rem;
  align-items: center;
`;

const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &:not(:last-of-type) {
    ${StyledButtons} {
      margin-bottom: 4rem;
    }
  }
`;

const StyledFormRow = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 4rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const InputStyled = styled(InlineTextInputField)`
  width: 48%;
`;

const AddressAutocompleteContainer = styled.div`
  width: 100%;
`;

const StyledAddButton = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #0077c5;
  cursor: pointer;
  line-height: 16px;
  margin-inline-end: 20px;
  display: flex;
  align-content: center;
  gap: 0.2rem;
`;

const StyledDeleteButton = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: #b80000;
  cursor: pointer;
  line-height: 16px;
  border: 2px solid #d52b1e;
  border-radius: 1.5rem;
  padding: 0.2rem 2rem;
`;

const StyledPlusIcon = styled.i`
  font-size: 1.6rem;
`;
