import React from 'react';
import styled from 'styled-components';
import { Skeleton, Flex, Box } from '@melio/billpay-design-system';

import { devices } from 'src/app/theme/AppDevices';
import { useBreak } from 'src/app/hoc';
import { QboLayoutPageFooter } from 'src/app/components/layout/QBOLayoutPage';
import * as QBOWizardElements from 'src/app/components/layout/QBOWizardElements';
import { SecurityDetails } from 'src/app/components/common/SecurityDetails';
import * as QBOLayoutElements from 'src/app/components/layout/QBOElements';
import { MIFormattedText } from 'src/app/utils/formatting';

export const PayBillSkeleton = () => {
  const { isMobile } = useBreak();

  return (
    <QBOLayoutElements.QBOLayoutContainer>
      <HeaderSkeleton />
      <StyledQBOWizzardInner>
        <QBOWizardElements.WizardInputs innerSize={50}>
          <TitlesSkeleton isMobile={isMobile} />
          <FundingSourceSkeleton />
          <FundingSourceSkeleton />
          <FundingSourceSkeleton />
          <Flex justifyContent="center">
            <Skeleton w="11.6rem" h="3.6rem" mt="0" mb="5" />
          </Flex>
          <SecurityDetails showIconCC={false} />
        </QBOWizardElements.WizardInputs>
      </StyledQBOWizzardInner>
      <QboLayoutPageFooter>
        <QBOLayoutElements.QBOFooterContainer />
      </QboLayoutPageFooter>
    </QBOLayoutElements.QBOLayoutContainer>
  );
};

const HeaderSkeleton = styled.div`
  height: 4.8rem;
  width: 100%;
  border-bottom: 2px solid rgba(216, 216, 216);

  @media ${devices.mobile} {
    height: 9.4rem;
    background: ${(props) => props.theme.colors.white.opaque};
    border-bottom-width: 4px;
  }
`;

const StyledQBOWizzardInner = styled(QBOWizardElements.WizardInner)`
  margin-top: 2rem;

  @media ${devices.mobile} {
    margin-top: 3.2rem;
  }
`;

const FundingSourceSkeleton = () => <Skeleton h="8.4rem" mb="5" borderRadius="lg" />;

const TitlesSkeleton = ({ isMobile }) => (
  <TitleWrapper>
    <Flex direction="column" align="center" mb="2rem">
      <Flex direction="column" align="center" mb={isMobile ? '3rem' : '4rem'}>
        <Flex mb="4" align="center">
          <Skeleton ml="0.5rem" w="21.2rem" h="3.6rem" borderRadius="lg" />
        </Flex>
        <Flex align="center">
          <Skeleton ml="0.5rem" w="13.3rem" h="3.6rem" borderRadius="lg" />
        </Flex>
      </Flex>
      <Box>
        <WizardSubtitle>
          <MIFormattedText label="bills.pay.fundingSource.partialPayment.subtitle" />
        </WizardSubtitle>
      </Box>
    </Flex>
  </TitleWrapper>
);

const TitleWrapper = styled.div`
  position: relative;
`;

const WizardSubtitle = styled(QBOWizardElements.WizardStepSubTitle)`
  margin-bottom: 0;
`;
