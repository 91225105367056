import { Button as DefaultButton } from '@melio/billpay-design-system';
import { QBOFooter } from 'src/app/components/layout/QBOElements';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { WizardInner } from 'src/app/components/layout/QBOWizardElements';
import {
  Header as DefaultHeader,
  InvoiceAttachmentContainer,
  InvoiceLabel,
  StyledFormattedCurrency,
  VendorSubTitle,
} from 'src/app/pages/vendor/components/VendorLayoutElements';
import { devices } from 'src/app/version-2/utils/devices.utils';

import styled from 'styled-components';

export const StyledQBOLayoutPage = styled(QBOLayoutPage)`
  @media ${devices.phablet} {
    align-items: initial;
  }

  ${WizardInner} {
    margin-bottom: ${({ theme }) => theme.space.ds['2xl']};

    @media ${devices.mobile}, ${devices.phablet} {
      padding: 0;
    }
  }

  ${QBOFooter} {
    @media ${devices.tablet} {
      border: 0 solid #dce0e5;
      border-top-width: 1px;
    }
  }
`;

export const LogoContainer = styled.div`
  margin-top: ${({ theme }) => theme.space.ds['2xl']};
  margin-bottom: ${({ theme }) => theme.space.ds['3xl']};
  text-align: center;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: ${({ theme }) => theme.space.ds.lg};
    margin-bottom: ${({ theme }) => theme.space.ds.xl};
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.ds.white};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.ds.lg};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.ds.md};
  padding: ${({ theme }) => theme.space.ds['2xl']};

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: ${({ theme }) => theme.space.ds['2xl']} ${({ theme }) => theme.space.ds.xl};
  }
`;

export const Header = styled(DefaultHeader)`
  padding: 0;

  ${VendorSubTitle} {
    color: ${({ theme }) => theme.colors.ds.black};
  }

  ${StyledFormattedCurrency} {
    font-size: 3.4rem;
    line-height: 4.4rem;
  }

  ${InvoiceAttachmentContainer} {
    flex-direction: row;
  }

  ${InvoiceLabel} {
    color: ${({ theme }) => theme.colors.ds.black};
    ${({ theme }) => theme.textStyles.ds.body2};
  }
`;

export const Separator = styled.div`
  height: 0.2rem;
  margin: ${({ theme }) => theme.space.ds['2xl']} 0;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.ds.gray[700]};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.ds.black};
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  ${({ theme }) => theme.textStyles.ds.h5};
`;

export const Subtitle = styled.div`
  margin-top: ${({ theme }) => theme.space.ds.lg};
  color: ${({ theme }) => theme.colors.ds.black};
  ${({ theme }) => theme.textStyles.ds.body1};
`;

export const Fees = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[200]};
  ${({ theme }) => theme.textStyles.ds.body1};
`;

export const Button = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.space.ds['2xl']};
`;
