import { CurrencyField, Tooltip } from '@melio/billpay-design-system';

import MINotificationCard from 'apps/web/src/app/components/common/MINotificationCard';
import styled from 'styled-components';

const editIconSize = '2.5rem';

export const NotificationCardStyled = styled(MINotificationCard)`
  padding: 1.6rem;
  text-wrap: initial;
`;

export const NotificationCardContainer = styled.div`
  margin: 1.4rem 2rem;
`;

export const MemoTooltip = styled(Tooltip)`
  position: relative;
  right: ${(props) => props.theme.sizes.ds.xs};
`;
export const InfoIconTooltip = styled.div<{ $isOptional?: boolean; $isAmex?: boolean }>`
  display: flex;
  cursor: pointer;
  margin-left: ${({ $isAmex }) => ($isAmex ? '-11ch' : '0.5rem')};
  min-width: 1.8rem;
  width: ${({ $isAmex }) => ($isAmex ? '1.8rem' : 'unset')};
  path {
    fill: ${({ theme, $isOptional }) =>
      $isOptional ? theme.colors.ds.gray[100] : theme.colors.ds.yellow[200]};
  }
`;

export const HandCursorWrapper = styled.span`
  cursor: pointer;
  height: 1.6rem;
`;

export const ExpanderRendererContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ExpanderImage = styled.div<{ isExpanded?: boolean }>`
  margin-top: -0.2rem;
  ${({ isExpanded }) => (isExpanded ? 'transform: rotate(90deg)' : '')};
`;

export const AmmountContainer = styled.div<{ $withIconMargin: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-right: ${({ $withIconMargin }) => ($withIconMargin ? editIconSize : '0')};
  max-height: 1.6rem;
`;

export const DeductionDatePickerWrapper = styled.div`
  width: 35rem;
  min-height: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DeliveryDateCircle = styled.div<{ isDeductionDate?: boolean }>`
  height: 0.8rem;
  width: 0.8rem;
  border: 0.2rem solid
    ${(props) =>
      props.isDeductionDate ? props.theme.colors.brand : props.theme.colors.border.dark};
  background-color: ${(props) => props.isDeductionDate && props.theme.colors.brand};
  border-radius: 50%;
  margin-right: 0.75rem;
`;

export const DeductionDateDueDate = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  background: white;
  border-radius: 0.4rem;
`;

export const DeductionDateDueDateItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const DeductionDateDueDateLabel = styled.div`
  ${({ theme }) => theme.textStyles.ds.body3}
`;
export const DeductionDateDueDateValue = styled.div`
  ${({ theme }) => theme.textStyles.ds.body3}
  font-weight: 600;
`;

export const DeductionDateSelection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const DeductionDateFormattedDateContainer = styled.div`
  display: flex;
  align-items: center;

  div:first-of-type {
    padding-right: 6px;
  }
`;

export const ExpandCollapseMenuIcon = styled.div<{ $isOpen: boolean }>`
  cursor: pointer;
  ${({ $isOpen }) => ($isOpen ? 'transform: rotate(180deg)' : '')}
`;

export const Amount = styled.div<{ $hasNoSelectedDM: boolean; $isBill: boolean }>`
  color: ${({ theme, $hasNoSelectedDM }) =>
    $hasNoSelectedDM ? theme.colors.ds.gray[300] : theme.colors.ds.gray[100]};
  font-weight: ${({ theme, $isBill }) =>
    $isBill ? theme.fontWeights.ds.regular : theme.fontWeights.ds.semi};
`;

export const Fee = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[300]};
`;

export const CurrencyFieldForCell = styled(CurrencyField)`
  text-align: right;
  margin-right: ${editIconSize};
  &&&& {
    background-color: transparent;
    width: calc(100% - ${editIconSize});
    transition: none;
  }
`;

export const EditIconWrapper = styled.div`
  cursor: pointer;
  margin-left: 0.8rem;
`;
export const NoDeliveryMethodText = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[300]};
`;
export const FastDeliveryWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeliveryDateWrapper = styled.div<{ $isEstimateAvailable: boolean }>`
  color: ${(props) => {
    if (props.$isEstimateAvailable) {
      return props.theme.text.color.main;
    }

    return props.theme.text.color.label;
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const DeliveryDateLateBadgeWrapper = styled.div`
  margin-left: 2rem;
`;

export const AddVendorsEmailPopoverWrapper = styled.div`
  display: flex;
  padding: 2rem;
  width: 32rem;
  height: 15.2rem;
  box-sizing: border-box;
  flex-direction: row;
  white-space: normal;
  flex-wrap: wrap;
  align-content: start;
`;

export const AddVendorEmailPopoverText = styled.div`
  ${(props) => props.theme.textStyles.ds.body2}
  margin: 0 0 1.6rem 0;
`;

export const MissingDetailsPopoverWrapper = styled.div`
  display: flex;
  padding: 2rem;
  width: 32rem;
  box-sizing: border-box;
  flex-direction: row;
  white-space: normal;
  flex-wrap: wrap;
  align-content: start;
`;

export const MissingDetailsPopoverText = styled.div`
  ${(props) => props.theme.textStyles.ds.body2}
  margin: 0 0 1.6rem 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  flex-basis: 100%;
`;

export const VendorContainer = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const VendorCompanyName = styled.span`
  flex: 0 1 min-content;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeliveryDateNA = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[300]};
`;

export const MenuButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MenuEmptyListButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
