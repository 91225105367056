import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withNavigator } from 'src/app/hoc';
import { useModal } from 'src/app/helpers/react/useModal';
import successImage from 'src/app/images/icons/icons_check.svg';
import { compose } from 'recompose';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import locations from 'src/app/utils/locations';
import QBOSuccessLayout from 'src/app/components/layout/QBOSuccessLayout';
import QBOMIDialog from 'src/app/components/common/QBOMIDialog';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import {
  CONSTS,
  IMAGE_POSITION,
  BUTTONS_DIRECTIONS,
  BILL_STATUS,
  NEXT_BILL,
  NOTIFICATION_CARD_TYPES,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import { useLocationState } from 'src/app/utils/hooks';
import organizationStore from 'src/app/modules/organizations/organizations-store';
import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import { CollectVendorEmailModalMessage } from 'src/app/pages/bill/pay/components/CollectVendorEmailModalMessage';
import { AreaLoader } from '@melio/billpay-design-system';
import {
  PaymentType,
  BillType,
  QBCashStateType,
  ButtonVariantType,
  CompanyInfoType,
} from 'src/app/utils/types';
import { endPayBillFlowAction } from 'src/app/redux/payBillWizard/actions';
import { getBill, getPayment, getIsLoading } from 'src/app/redux/payBillWizard/selectors';
import { getOrgId } from 'src/app/redux/user/selectors';
import {
  WizardActionsRowContainer,
  WizardButtonContainer,
} from 'src/app/components/layout/QBOWizardElements';
import { NextQboBillToPayInfo } from 'src/app/pages/qb-dashboard/components/NextQboBillToPayInfo';
import MIButton from 'src/app/components/common/MIButton';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { FundingSourceOrigins } from 'src/app/version-2/model/enums';
import { shouldAskForVendorEmail } from 'src/app/modules/vendors/vendor-email/utils/should-ask-for-vendor-email';
import { withPayBillData } from './hoc/withPayBillData';
import { useCheckOwnedVendorExists } from './hooks/useCheckOwnedVendorExists';
import { getPayBillSuccessLabels, getWarningNotificationLabels } from '../../qb-dashboard/utils';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any>) => void;
};

type ActionType = {
  label: string;
  variant: ButtonVariantType;
  onClick: () => void;
};

type NextQboBillToPayData = {
  nextQboBillToPay: BillType;
};

type AnalyticNextBillAdditionalData = {
  vendorEmail: string;
  nextBill?: string;
  billId?: string | number | null;
};

const eventPage = 'pay-bill';

const QBOPayBillSuccessPageContainer = ({ navigate }: Props) => {
  const dispatch = useDispatch();
  const bill = useSelector<BillType>(getBill as any) as BillType;
  const payment = useSelector<PaymentType>(getPayment as any) as PaymentType;
  const isLoading = useSelector(getIsLoading);
  const orgId = useSelector(getOrgId);
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const [visible, setVisibility] = useState(true);
  const [historyPush] = useHistoryWithOrgId();
  const vendorName = bill.vendor.companyName;
  const deliveryMethod = bill.getDeliveryMethodById(payment.deliveryMethodId);
  const organization: CompanyInfoType = useSelector(organizationStore.selectors.byId(orgId));
  const [isOwnedVendorExists, isGettingOwnedVendorData] = useCheckOwnedVendorExists({
    bill,
    eventPage,
  });
  const hasVendorEmail = isOwnedVendorExists ? true : !!bill.vendor.contactEmail;

  const shouldShowNotifyVendorButton = shouldAskForVendorEmail({
    isOwnedVendorExists,
    vendorContactEmail: bill?.vendor?.contactEmail,
    deliveryType: deliveryMethod?.deliveryType,
  });

  const analyticsProps = {
    vendorId: bill.vendorId,
    partialBillId: getPartialBillId(bill),
  };

  const vendorEmailExists = !shouldShowNotifyVendorButton;

  const [nextQboBillToPayData] = useLocationState<NextQboBillToPayData>('nextQboBillToPayData');
  const [syncPaymentErrorCode] = useLocationState<string>('syncPaymentErrorCode');
  const nextQboBillToPayInfo = nextQboBillToPayData?.nextQboBillToPay;

  const CollectSuccessModal = () => {
    const handleCollectVendorEmailSuccessModalOk = async () => {
      analytics.track(eventPage, 'success-vendor-email-modal-closed', analyticsProps);

      goBillsList();
    };

    const handleCloseAndTrackAnalyticsForCollectSuccessModal = () => {
      handleCollectVendorEmailSuccessModalOk();
      analytics.track(eventPage, 'SuccessPaymentModal-Closed');
    };

    useEffect(() => {
      analytics.track(eventPage, 'EmailSentSuccessPaymentModal-Shown', {
        paymentId: payment?.id,
        vendorId: bill?.vendor?.id,
        ...addAdditionalAnalytics(true),
      });
    }, []);

    return (
      <QBOMIDialog
        type={DIALOG_TYPE.CONFIRM}
        variant={DIALOG_VARIANTS.SUCCESS}
        title="bills.pay.collectVendorEmailModal.successTitle"
        titleValues={{ vendorName }}
        image={successImage}
        subtitle="bills.pay.collectVendorEmailModal.qboSuccessSubTitle"
        onCancelAction={nextQboBillToPayInfo && handleCollectVendorEmailSuccessModalOk}
        okButtonText={
          nextQboBillToPayInfo
            ? 'dialogs.buttons.schedule'
            : 'bills.pay.collectVendorEmailModal.confirm'
        }
        {...(nextQboBillToPayInfo && { cancelButtonText: 'dialogs.buttons.done' })}
        onOkAction={
          nextQboBillToPayInfo ? scheduleNextPayment : handleCollectVendorEmailSuccessModalOk
        }
        onCloseAction={handleCloseAndTrackAnalyticsForCollectSuccessModal}
        showCancel={nextQboBillToPayInfo}
        showConfirmationText={false}
        imagePosition={nextQboBillToPayInfo ? IMAGE_POSITION.LEFT : IMAGE_POSITION.CENTER}
        buttonsDirection={BUTTONS_DIRECTIONS.VERTICAL}
      >
        {nextQboBillToPayInfo && (
          <NextQboBillToPayInfo nextQboBillToPayInfo={nextQboBillToPayInfo} />
        )}
      </QBOMIDialog>
    );
  };

  const [CollectVendorEmailSuccessModal, showCollectVendorEmailSuccessModal] = useModal(
    CollectSuccessModal,
    {
      modalName: 'successCollectEmail',
    }
  );

  const [vendorContactEmail, setVendorContactEmail] = useState(bill.vendor.contactEmail);

  const handleShowCollectVendorEmailModal = (vendorContactEmail) => {
    setVendorContactEmail(vendorContactEmail);
    showCollectVendorEmailSuccessModal();
  };

  const [CollectVendorEmailModal, showCollectVendorEmailModal] = useModal(
    CollectVendorEmailModalMessage,
    {
      onSuccess: handleShowCollectVendorEmailModal,
      onDismiss: () => setVisibility(true),
      payment,
      bill,
      eventPage,
      modalName: 'collectEmail',
    }
  );

  const goBillsList = () => {
    const defaultFilters = getBillsDefaultFilters(BILL_STATUS.UNPAID);

    analytics.track(eventPage, 'SuccessPaymentModal-Closed');

    dispatch(endPayBillFlowAction());
    navigate(generatePath(locations.Bills.filteredViewWithoutId.url(defaultFilters), { orgId }));
  };

  const collectVendorEmail = () => {
    showCollectVendorEmailModal();
    setVisibility(false);
    analytics.track(eventPage, 'notify-my-vendor', {
      screenName: 'success',
      ...analyticsProps,
    });
  };

  const { title, subtitle, textValues } = getPayBillSuccessLabels({
    payment,
    organization,
    hasVendorEmail,
    vendorName,
    shouldShowNotifyVendorButton,
  });

  useEffect(() => {
    shouldShowNotifyVendorButton &&
      analytics.track(eventPage, 'exposed-to-notify-my-vendor-button', {
        screenName: 'success',
        ...analyticsProps,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowNotifyVendorButton]);

  const addAdditionalAnalytics = (
    shouldSendAdditionalData: boolean
  ): AnalyticNextBillAdditionalData => {
    const data: AnalyticNextBillAdditionalData = {
      vendorEmail: vendorContactEmail,
      ...(shouldSendAdditionalData && {
        nextBill: NEXT_BILL.NO_BILL,
        billId: null,
      }),
    };

    if (vendorContactEmail && nextQboBillToPayInfo) {
      data.nextBill = NEXT_BILL.WITH_DETAILS;
      data.billId = nextQboBillToPayInfo.id as any;
    }

    return data;
  };

  useEffect(() => {
    if (nextQboBillToPayData) {
      analytics.track(eventPage, 'success-payment-modal', {
        paymentId: payment?.id,
        vendorId: bill?.vendor?.id,
        ...addAdditionalAnalytics(vendorEmailExists),
      });
    }
  }, [nextQboBillToPayData]);

  if (isGettingOwnedVendorData) {
    return <AreaLoader />;
  }

  const scheduleNextPayment = () => {
    const billId = nextQboBillToPayData?.nextQboBillToPay?.id;

    analytics.track(eventPage, 'NextPaymentInSuccessPaymentModal-Scheduled', {
      billId,
    });

    historyPush({ path: locations.Bills.pay.funding.url({ id: billId }) });
  };

  const getActionsButtons = (): ActionType[] => {
    if (shouldShowNotifyVendorButton) {
      return [
        {
          label: 'bills.pay.payBillSuccess.buttonLabel.notifyMyVendor',
          variant: CONSTS.BUTTON_VARIANT.PRIMARY,
          onClick: collectVendorEmail,
        },
        {
          label: 'bills.pay.payBillSuccess.buttonLabel.done',
          variant: CONSTS.BUTTON_VARIANT.SECONDARY,
          onClick: goBillsList,
        },
      ];
    }

    return [
      ...(nextQboBillToPayInfo
        ? [
            {
              label: 'dialogs.buttons.schedule',
              variant: CONSTS.BUTTON_VARIANT.PRIMARY,
              onClick: scheduleNextPayment,
            },
          ]
        : []),
      {
        label: 'bills.pay.payBillSuccess.buttonLabel.done',
        variant: CONSTS.BUTTON_VARIANT[nextQboBillToPayInfo ? 'SECONDARY' : 'PRIMARY'],
        onClick: goBillsList,
      },
    ];
  };

  const actionsButtons = getActionsButtons();

  const [firstAction, secondAction] = actionsButtons;
  const showQbcashNotification =
    payment.fundingSource.origin === FundingSourceOrigins.QBCASH &&
    parseFloat(payment.amount?.toString() as string) <= qbCashState.balance;

  const nextQboBillToPayAndVendorEmailExist = nextQboBillToPayInfo && vendorEmailExists;
  const {
    title: warningTitle = '',
    subtitle: warningSubtitle = '',
    subtitleValues: warningSubtitleValues = {},
  } = getWarningNotificationLabels({
    showQbcashNotification,
    syncPaymentErrorCode,
    payment,
  });
  const shouldShowWarningNotification = warningTitle || warningSubtitle;

  return (
    <>
      {CollectVendorEmailModal}
      {CollectVendorEmailSuccessModal}
      {visible && (
        <Wrapper>
          {payment?.amount && Number.isNaN(payment.amount) ? (
            <></>
          ) : (
            <QBOSuccessLayout
              illustration={successImage}
              title={title}
              text={subtitle}
              textValues={textValues}
              isLoading={isLoading}
              className="qbo-pay-bill-success-page"
              shouldAskNotification={shouldShowNotifyVendorButton}
              hideHeader
            >
              {shouldShowWarningNotification && (
                <QBONotificationCardContainer>
                  <QBONotificationCard
                    type={NOTIFICATION_CARD_TYPES.WARNING}
                    title={{ label: warningTitle }}
                    subtitle={{
                      label: warningSubtitle,
                      values: warningSubtitleValues,
                    }}
                  />
                </QBONotificationCardContainer>
              )}
              {nextQboBillToPayAndVendorEmailExist && (
                <NextQboBillToPayInfo nextQboBillToPayInfo={nextQboBillToPayInfo} />
              )}
              <WizardActionsRowContainer>
                {firstAction && (
                  <WizardButtonContainer>
                    <MIButton
                      label={firstAction.label}
                      variant={firstAction.variant}
                      onClick={firstAction.onClick}
                      isProcessing={isLoading}
                      fullWidth={actionsButtons.length > 1}
                    />
                  </WizardButtonContainer>
                )}

                {secondAction && (
                  <WizardButtonContainer>
                    <MIButton
                      label={secondAction.label}
                      variant={secondAction.variant}
                      onClick={secondAction.onClick}
                      fullWidth
                    />
                  </WizardButtonContainer>
                )}
              </WizardActionsRowContainer>
            </QBOSuccessLayout>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default compose(withNavigator(), withPayBillData())(QBOPayBillSuccessPageContainer);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  > div {
    font-size: 1.6rem;
  }

  .qbo-pay-bill-success-page {
    margin-left: 1.8rem;
    margin-right: 1.8rem;

    > div {
      justify-content: center;
      padding-top: 4rem;
    }
  }

  .qbo-wizzard-illustration {
    height: 6rem;
    margin-bottom: 4rem;
  }

  ${WizardActionsRowContainer} {
    flex-direction: column;
    align-self: auto;
    width: 100%;
  }

  ${WizardButtonContainer} {
    &:first-child {
      margin-right: 0;
    }
  }
`;

const QBONotificationCardContainer = styled.div`
  display: block;
  text-align: left;
  margin-bottom: 4rem;
`;
