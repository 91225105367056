import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/app/hoc/useApi';
import vendorApi from 'src/app/services/api/vendors';
import { getOrgId } from 'src/app/redux/user/selectors';

type Params = {
  eventPage: string;
  vendorId: number;
  vendorEmail: string;
};

export const useCheckOwnedVendorExists = ({
  vendorId,
  vendorEmail,
}: // eventPage,
Params) => {
  const orgId = useSelector(getOrgId);

  const [getOwnedVendorExists, isOwnedVendorExists, isGettingOwnedVendorData] = useApi<
    [{ orgId: string; id: string }],
    boolean
  >(vendorApi.getOwnedVendorExists, false, true);

  const getOwnedVendor = useCallback(async () => {
    if (vendorId) {
      await getOwnedVendorExists({
        orgId,
        id: vendorId.toString(),
      });
    }
  }, [vendorId, getOwnedVendorExists, orgId]);

  useEffect(() => {
    if (vendorId) {
      getOwnedVendor();
    }

    if (!vendorEmail) {
      // analytics.track(eventPage, 'confirm-notify-vendor');
    }
  }, [vendorEmail, getOwnedVendor]);

  return [isOwnedVendorExists, isGettingOwnedVendorData];
};
