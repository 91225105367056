import * as React from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex, Tooltip, theme, CSSObject } from '@melio/billpay-design-system';

import analytics from 'src/app/services/analytics';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';
import { useBreak } from 'src/app/hoc';
import { CATEGORY_LIST_VIEW_VARIANTS } from 'src/app/utils/consts';
import { ReactComponent as RightArrow } from 'src/app/images/core/right-arrow.svg';

export type Option = {
  id: string;
  label: string;
  disabled?: boolean;
  IconComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
  icon?: string;
  iconTooltip?: { label: string; title?: string; subtitle?: string };
  hint?: string;
  info?: React.ReactNode;
  ribbon?: React.ReactNode;
  includeArrow?: boolean;
};

type Props = {
  onSelect: (id: string) => void;
  categories: Option[];
  selectedId?: string | null;
  withShadow?: boolean;
  analyticsProps?: { [key: string]: any };
  viewVariant?: CATEGORY_LIST_VIEW_VARIANTS;
};

const CategoryListLayoutsMap = {
  [CATEGORY_LIST_VIEW_VARIANTS.COLUMN]: {
    label: `
      width: 100%;
      max-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;
      margin-left: 0rem;
      &:nth-child(even) {
        margin-left: 0rem;
      };
    `,
    ribbon: `
      display: flex;
      flex-grow: 1;
      align-self: flex-start;
      justify-content: end;
      margin-top: 0rem;
    `,
    icon: `
      font-size: 4rem;
      color: ${theme.colors.ds.gray[200]};
    `,
    category: `
      display: flex;
      flex-direction: column;
      align-items: start;
    `,
  },
};

export const MICategoryList = ({
  categories,
  onSelect,
  selectedId,
  withShadow = true,
  analyticsProps,
  viewVariant,
}: Props) => {
  const { isMobile } = useBreak();
  const onSelectCategory = (id) => {
    analytics.trackAction('select-category', {
      value: id,
      deliveryMethodType: id === 'card' ? 'debit' : id,
      ...analyticsProps,
    });
    onSelect(id);
  };

  const desktopRender = () =>
    categories.map(
      ({
        id,
        label,
        icon,
        IconComponent,
        iconTooltip,
        disabled,
        hint,
        info,
        ribbon,
        includeArrow = false,
      }) => (
        <Tooltip
          key={id}
          placement="top"
          isDisabled={!iconTooltip}
          label={
            <MIFormattedText
              label={iconTooltip?.label}
              values={{
                title: (
                  <Box fontSize="1.4rem" margin="1.6rem 1.6rem 0.5rem 1.6rem">
                    <MIFormattedText label={iconTooltip?.title} />
                  </Box>
                ),
                subtitle: (
                  <Box fontSize="1.2rem" margin="0rem 1.6rem 1.6rem 1.6rem">
                    <MIFormattedText label={iconTooltip?.subtitle} />
                  </Box>
                ),
              }}
            />
          }
        >
          <Label
            htmlFor={id}
            key={id}
            selected={id === selectedId}
            disabled={disabled}
            data-testid={`category-list-${id}`}
            itemIcon={icon}
            withShadow={withShadow}
            viewVariant={viewVariant}
            isFullWidth
          >
            <Flex gridGap={5}>
              {icon && (
                <IconContainer>
                  <MethodOptionIcon className={icon} disabled={disabled} />
                </IconContainer>
              )}
              {!!IconComponent && (
                <IconComponentContainer>
                  <IconComponent />
                </IconComponentContainer>
              )}
              <Category selected={id === selectedId} disabled={disabled} viewVariant={viewVariant}>
                <Input
                  type="radio"
                  id={id}
                  onClick={() => onSelectCategory(id)}
                  disabled={disabled}
                />
                <Flex direction="column" alignItems="start" justifyContent="start" gridGap={1}>
                  <MIFormattedText label={label} />
                  {hint && (
                    <HintContainer>
                      <MIFormattedText label={hint} />
                    </HintContainer>
                  )}
                  {info && <Box>{info}</Box>}
                </Flex>
              </Category>
            </Flex>
            {ribbon && <RibbonContainer viewVariant={viewVariant}>{ribbon}</RibbonContainer>}
            {includeArrow && (
              <Flex
                height="full"
                direction="column"
                alignContent="center"
                justifyContent="center"
                color="gray.500"
              >
                <RightArrow />
              </Flex>
            )}
          </Label>
        </Tooltip>
      )
    );

  const mobileRender = () =>
    categories.map(
      ({ id, label, icon, IconComponent, iconTooltip, disabled, hint, info, ribbon }) => (
        <RelativeWrapper>
          <Tooltip
            key={id}
            placement="top"
            isDisabled={!iconTooltip}
            label={
              <MIFormattedText
                label={iconTooltip?.label}
                values={{
                  title: (
                    <Box fontSize="1.4rem" margin="1.6rem 1.6rem 0.5rem 1.6rem">
                      <MIFormattedText label={iconTooltip?.title} />
                    </Box>
                  ),
                  subtitle: (
                    <Box fontSize="1.2rem" margin="0rem 1.6rem 1.6rem 1.6rem">
                      <MIFormattedText label={iconTooltip?.subtitle} />
                    </Box>
                  ),
                }}
              />
            }
          >
            {iconTooltip ? (
              <Box as="i" className="icon-help-question-icon" __css={virtualCardInfoTooltip} />
            ) : (
              <></>
            )}
          </Tooltip>
          <Label
            htmlFor={id}
            key={id}
            selected={id === selectedId}
            disabled={disabled}
            data-testid={`category-list-${id}`}
            itemIcon={icon}
            withShadow={withShadow}
          >
            <Flex gridGap={5}>
              {icon && (
                <IconContainer>
                  <MethodOptionIcon className={icon} disabled={disabled} />
                </IconContainer>
              )}
              {!!IconComponent && (
                <IconComponentContainer>
                  <IconComponent />
                </IconComponentContainer>
              )}
              <Category selected={id === selectedId} disabled={disabled}>
                <Input
                  type="radio"
                  id={id}
                  onClick={() => onSelectCategory(id)}
                  disabled={disabled}
                />
                <Flex direction="column" alignItems="start" justifyContent="start" gridGap={1}>
                  <MIFormattedText label={label} />
                  {hint && (
                    <HintContainer>
                      <MIFormattedText label={hint} />
                    </HintContainer>
                  )}
                  {info && <Box>{info}</Box>}
                </Flex>
              </Category>
            </Flex>
            {ribbon && <RibbonContainer>{ribbon}</RibbonContainer>}
          </Label>
        </RelativeWrapper>
      )
    );

  return <ListContainer>{isMobile ? mobileRender() : desktopRender()}</ListContainer>;
};

const Category = styled.div<{ disabled?: boolean; selected?: boolean; viewVariant?: string }>`
  letter-spacing: 0;
  color: ${(props) =>
    props.disabled ? props.theme.text.color.label : props.theme.text.color.main};
  font-weight: 500;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  ${(props) => props.theme.text.fontType.medium};

  @media ${devices.mobile}, ${devices.phablet} {
    ${(props) => props.theme.text.fontType.regular};
  }

  ${({ viewVariant }) => CategoryListLayoutsMap[viewVariant as string]?.category};
`;

const Input = styled.input`
  display: none;
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
  max-width: 100%;
`;

const Label = styled.label<{
  selected?: boolean;
  itemIcon?: string;
  disabled?: boolean;
  withShadow?: boolean;
  isFullWidth?: boolean;
  viewVariant?: string;
}>`
  display: flex;
  justify-content: start;
  padding: 2rem;
  cursor: pointer;
  border: ${(props) =>
    props.selected
      ? `0.2rem solid ${props.theme.colors.brand}`
      : `0.1rem solid ${props.withShadow ? 'transparent' : props.theme.colors.border.darkGrey}`};
  border-radius: ${({ withShadow }) => (withShadow ? '0.8rem' : '0.4rem')};
  box-sizing: border-box;
  box-shadow: ${({ withShadow }) => (withShadow ? '0 5px 10px 0 rgba(33, 33, 36, 0.15)' : 'none')};
  width: 21.5rem;
  max-width: 21.5rem;
  outline-color: ${(props) => props.theme.colors.border.darkGrey};
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin-bottom: 1rem;
  position: relative;
  transition: ${(props) => props.theme.animation.transition.default};
  &:nth-child(even) {
    margin-left: 1.5rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    &:nth-child(even) {
      margin-left: 0rem;
    }
    max-width: 100%;
    width: 100%;
  }

  ${(props) =>
    props.itemIcon &&
    css`
      flex-direction: column;
      align-items: start;
    `};

  ${(props) =>
    !props.selected &&
    !props.disabled &&
    css<{ withShadow?: boolean }>`
      &:hover {
        box-shadow: ${({ withShadow, theme }) =>
          withShadow ? `0 0.8rem 1.5rem 0 ${theme.colors.dark.translucent2}` : 'none'};
        outline: ${({ withShadow, theme }) =>
          withShadow ? 'none' : `0.1rem solid ${theme.colors.border.darkGrey}`};
      }
    `};

  ${({ viewVariant }) => CategoryListLayoutsMap[viewVariant as string]?.label};
`;

const IconContainer = styled.div<{ viewVariant?: string }>`
  display: flex;
`;

const IconComponentContainer = styled.div`
  display: flex;
  & svg {
    height: 4rem;
    width: 3.8rem;
    & path {
      fill: ${theme.colors.ds.gray[200]};
    }
  }
`;

const HintContainer = styled.div`
  ${(props) => props.theme.text.fontType.medium};
  color: ${theme.colors.ds.gray[200]};

  @media ${devices.mobile} {
    white-space: pre-wrap;
    text-align: left;
  }
`;

const MethodOptionIcon = styled.i<{ disabled?: boolean; viewVariant?: string }>`
  color: ${(props) => (props.disabled ? props.theme.text.color.label : theme.colors.ds.gray[200])};
  font-size: 4rem;

  ${({ viewVariant }) => CategoryListLayoutsMap[viewVariant as string]?.icon};
`;

const RibbonContainer = styled.div<{ viewVariant?: string }>`
  margin-top: 0.8rem;

  ${({ viewVariant }) => CategoryListLayoutsMap[viewVariant as string]?.ribbon};
`;

const virtualCardInfoTooltip: CSSObject = {
  position: 'absolute',
  top: '1.1rem',
  right: '1.1rem',
  fontSize: '1.6rem',
  zIndex: '2',
};
