import { VendorType } from 'src/app/utils/types';
import { SUCCESS_PAGE_STATES } from './SuccessPage';

export const getCurrentStateByVendor = (
  vendor: VendorType,
  isOwnedVendorExists: boolean | undefined | null
) => {
  if (vendor && isOwnedVendorExists !== null) {
    const vendorEmailExist = isOwnedVendorExists || !!vendor.contactEmail;

    if (vendorEmailExist) {
      return SUCCESS_PAGE_STATES.HAS_VENDOR_EMAIL;
    }
  }

  return SUCCESS_PAGE_STATES.NO_VENDOR_EMAIL;
};
