import React from 'react';
import { Box, Flex, Image } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  title: string;
  subtitle: string;
  image: string;
};

const QBOUpsellBenefit = ({ title, subtitle, image }: Props): JSX.Element => (
  <Flex direction="column" align="center" textAlign="center" mb="3.2rem">
    <Image src={image} boxSize="8.8rem" />
    <Box mb="0.8rem" textStyle="body2" fontWeight="600">
      <MIFormattedText label={title} />
    </Box>
    <Box textStyle="body3" fontWeight="400">
      <MIFormattedText label={subtitle} />
    </Box>
  </Flex>
);

export { QBOUpsellBenefit };
