import React from 'react';
import { useSelector } from 'react-redux';
import profileStore from 'src/app/modules/profile/profile-store';
import { getModifyFundingSourceOptions } from 'src/app/utils/funding-sources';
import { CARD_TYPES } from 'src/app/utils/consts';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  getCardIcon,
  getCardImageUrl,
  isCardExpired,
  convertDateToMonthYearFormat,
  getExpirationDateHint,
} from 'src/app/utils/card';
import { getWizardFlow } from 'src/app/redux/payBillWizard/selectors';
import { getAdditionDescriptionLabel, getDescriptionLabel } from 'src/app/utils/debit-card.utils';
import { getPaymentMethodNameParts } from 'src/app/version-2/utils/paymentMethods.utils';
import SelectedMethod from '../components/SelectedMethod';

type Props = {
  onClick?: () => void;
  method: FundingSource;
  optionComponent: any;
  isSelected: boolean;
  disabled: boolean;
  modifyActions: Record<string, any>;
  description?: string;
  debitFee?: React.ReactNode | string;
};

export const DebitPaymentMethod = ({
  onClick,
  method,
  isSelected,
  optionComponent: OptionComponent,
  disabled,
  modifyActions,
  description,
  debitFee,
}: Props) => {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const payBillWizardFlow = useSelector(getWizardFlow);

  const descriptionValues = debitFee ? { fee: debitFee } : {};

  if (!method) {
    const descriptionLabel = getAdditionDescriptionLabel(payBillWizardFlow, debitFee);

    return (
      <OptionComponent
        id="debit"
        label="onboarding.fundingSources.options.debit.label"
        description={description || descriptionLabel}
        descriptionValues={descriptionValues}
        icon="icon-debit-card-icon"
        onClick={disabled ? null : onClick}
        disabled={disabled}
        isEmptyList
      />
    );
  }

  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);
  const isDebitCardExpired = isCardExpired(method.cardAccount || {});
  const actionOptions = getModifyFundingSourceOptions({
    permissions,
    modifyActions,
    isDisabled: disabled,
    origin: method.origin,
    isExpired: isDebitCardExpired,
    hasLabel: !!method.nickname,
  });

  const descriptionLabel =
    description ||
    getDescriptionLabel({
      payBillWizardFlow,
      isDebitCardExpired,
      debitFee,
    });

  return (
    <OptionComponent
      id={method.id}
      isSelected={isSelected}
      isExpired={isDebitCardExpired}
      disabled={disabled}
      onTopDescription={method.nickname}
      icon={icon}
      imageSrc={imageSrc}
      label="bills.pay.fundingSource.debitLabel"
      labelValues={getPaymentMethodNameParts({ fundingSource: method })}
      hint={getExpirationDateHint(method.cardAccount || {})}
      hintValues={{
        expirationDate: convertDateToMonthYearFormat(method.cardAccount?.expiration || ''),
      }}
      description={descriptionLabel}
      descriptionValues={descriptionValues}
      onClick={disabled ? null : onClick}
      actionOptions={actionOptions}
    />
  );
};

function DebitPaymentSelectedMethod({ method }: { method: FundingSource }) {
  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);

  return (
    <SelectedMethod
      icon={icon}
      imageSrc={imageSrc}
      title="bills.form.paymentActivity.scheduledBill.scheduleMethodDebitCard"
      label="bills.pay.fundingSource.debitLabel"
      labelValues={getPaymentMethodNameParts({ fundingSource: method })}
    />
  );
}

DebitPaymentMethod.methodType = CARD_TYPES.DEBIT;
DebitPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherDebitCard';
DebitPaymentMethod.SelectedMethod = DebitPaymentSelectedMethod;
DebitPaymentMethod.isDisabled = (method) => isCardExpired(method.cardAccount);
DebitPaymentMethod.showAddAnotherOption = true;
