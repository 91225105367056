import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Popover, Box } from '@melio/billpay-design-system';

import { BillType } from 'src/app/utils/types';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';

import { PaymentListItem } from './PaymentListItem/PaymentListItem';
import { ListItem } from './shared';

type Props = {
  children: React.ReactNode;
  bills: BillType[];
  paymentId: string | undefined;
};

const PaymentsList = ({ children, bills, paymentId }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const openPopover = () => setOpen(true);
  const closePopover = () => setOpen(false);

  useEffect(() => {
    closePopover();
  }, [paymentId]);

  return (
    <Popover isOpen={open} onClose={closePopover} onOpen={openPopover} trigger="click">
      <Popover.PopoverTrigger>
        <StyledLink>{children}</StyledLink>
      </Popover.PopoverTrigger>

      <Popover.PopoverContent>
        <Popover.PopoverBody>
          <ListWrapper>
            <List>
              <ListItem>
                <HeaderItem>
                  <MIFormattedText label="bills.form.paymentActivity.recurring.dueDate" />
                </HeaderItem>
                <HeaderItem>
                  <MIFormattedText label="bills.form.paymentActivity.recurring.etaDate" />
                </HeaderItem>
              </ListItem>
            </List>

            <List>
              {bills.map((bill) => (
                <PaymentListItem key={bill.id} bill={bill} paymentId={paymentId} />
              ))}
            </List>
          </ListWrapper>
        </Popover.PopoverBody>
      </Popover.PopoverContent>
    </Popover>
  );
};

export { PaymentsList };

const ListWrapper = styled.div`
  min-width: 30rem;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const HeaderItem = styled(Box).attrs({ color: 'gray.300' })``;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.text.color.main};
  max-height: 22rem;
  overscroll-behavior-y: contain;
  overflow: auto;
`;

const StyledLink = styled.span`
  ${({ theme }) => theme?.components?.BillOpenBalance?.ReviewLink}
  cursor: pointer;
`;
