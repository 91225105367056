import React, { useContext } from 'react';
import { SiteContext } from '../context/SiteContext';

export function withSiteContext() {
  return function (Component: any) {
    return class ComponentWithSiteContext extends React.PureComponent<any> {
      static defaultProps: any;

      render() {
        return (
          <SiteContext.Consumer>
            {(site) => (
              <Component {...this.props} site={site}>
                {this.props.children}
              </Component>
            )}
          </SiteContext.Consumer>
        );
      }
    };
  };
}

export function useSiteContext() {
  return useContext(SiteContext);
}
