import styled from 'styled-components';

export const SecurityComplianceMessageContainer = styled.div`
  display: flex;
  max-width: 45rem;
  color: ${({ theme }) => theme.colors.ds.black};
  justify-content: flex-start;
`;

export const SmartIconWrapper = styled.div`
  margin-right: 0.5rem;
`;

export const Content = styled.div`
  margin-top: 0;

  ${({ theme }) => theme.textStyles.ds.body3};
`;
