import React from 'react';
import analytics from 'src/app/services/analytics';
import QBOMIDialog from 'src/app/components/common/QBOMIDialog';
import { DIALOG_TYPE, DIALOG_VARIANTS } from 'src/app/utils/consts';

const eventPage = 'not-supported-countries-modal';

export const UnsupportedCountriesModal = ({ countryName, dismiss }) => {
  analytics.track(eventPage, 'dialog-opened', { countryName });
  const onCancelAction = () => {
    analytics.track(eventPage, 'dialog-closed', { countryName });
    dismiss();
  };

  return (
    <QBOMIDialog
      type={DIALOG_TYPE.CONFIRM}
      variant={DIALOG_VARIANTS.ERROR}
      title="international.vendorLocation.notSupportedCountriesModal.title"
      titleValues={{
        countryName,
      }}
      subtitle="international.vendorLocation.notSupportedCountriesModal.subtitle"
      subtitleValues={{
        countryName,
      }}
      onCancelAction={onCancelAction}
      cancelButtonText="international.vendorLocation.notSupportedCountriesModal.cta"
    />
  );
};
