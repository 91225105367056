import { DELIVERY_TYPE, FUNDING_SOURCE_ORIGIN } from 'src/app/utils/consts';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import { formatDistanceToNowStrict } from 'date-fns';
import { NotificationType } from './notifications/types';
import { CompanyInfoType, DeliveryMethodType } from 'src/app/utils/types';
import { getDeliveryMethodByType } from 'src/app/pages/vendor/records';
import { isEligibleToReceiveVirtualCard } from 'src/app/utils/payments';
import { isBankAccountBlocked } from 'src/app/utils/funding-sources';
import { isEligibleToReceiveInternational } from 'src/app/version-2/utils/international.utils';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { getMobileOperatingSystem } from 'src/app/utils/user-utils';
import { OperatingSystemEnum, PaymentCreateFlowOriginEnum } from 'src/app/version-2/model/enums';

export function getDefaultDeliveryMethod(deliveryMethods) {
  const deliveryTypesPriority = [
    DELIVERY_TYPE.INTERNATIONAL,
    DELIVERY_TYPE.RPPS,
    DELIVERY_TYPE.VIRTUAL,
    DELIVERY_TYPE.ACH,
    DELIVERY_TYPE.CHECK,
  ];
  const supportedDeliveryMethods = deliveryMethods.filter(
    (dm) => deliveryTypesPriority.indexOf(dm.deliveryType) !== -1
  );

  return head(
    sortBy(supportedDeliveryMethods, (dm) => deliveryTypesPriority.indexOf(dm.deliveryType))
  );
}

type Params = {
  deliveryMethods: DeliveryMethodType[];
  fundingSource?: FundingSource;
  currentDeliveryMethodId?: string;
};

export const getDeliveryMethodForPayment = ({
  deliveryMethods,
  fundingSource,
  currentDeliveryMethodId,
}: Params): DeliveryMethodType | undefined => {
  if (!deliveryMethods) return undefined;

  const currentDeliveryMethod = deliveryMethods.find((dm) => dm.id === currentDeliveryMethodId);
  const isVirtualCardSupported = isEligibleToReceiveVirtualCard(fundingSource);

  const hasVirtualCardDeliveryMethod = deliveryMethods.some(
    (dm) => dm.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD
  );

  if (hasVirtualCardDeliveryMethod && isVirtualCardSupported) {
    return getDeliveryMethodByType(DELIVERY_TYPE.VIRTUAL_CARD, deliveryMethods);
  }

  const isCurrentDeliveryMethodInvalid =
    !isVirtualCardSupported && currentDeliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

  if (isCurrentDeliveryMethodInvalid || !currentDeliveryMethod) {
    return getDefaultDeliveryMethod(deliveryMethods);
  }

  return currentDeliveryMethod;
};

function isRestrictedByDeliveryMethod(
  fundingSource?: FundingSource,
  deliveryMethods?: DeliveryMethodType[]
) {
  const isInternationalSupported = isEligibleToReceiveInternational(fundingSource as any);
  const isInternationalAdded = deliveryMethods?.some(
    (dm) => dm.deliveryType === DELIVERY_TYPE.INTERNATIONAL
  );

  if (isInternationalAdded && !isInternationalSupported) {
    return true;
  }

  return false;
}

export function getDefaultFundingSource(fundingSources, deliveryMethods, lastCreatedPayment) {
  const fundingSourceId = lastCreatedPayment?.fundingSourceId;
  let lastCreatedPaymentFundingSource = fundingSourceId
    ? fundingSources.find((fs) => fs.id === fundingSourceId)
    : null;

  if (isBankAccountBlocked(lastCreatedPaymentFundingSource)) {
    lastCreatedPaymentFundingSource = null;
  }

  if (isRestrictedByDeliveryMethod(lastCreatedPaymentFundingSource, deliveryMethods)) {
    lastCreatedPaymentFundingSource = null;
  }

  const qbCashFundingSource = fundingSources.find(
    (fs) => fs.origin === FUNDING_SOURCE_ORIGIN.QBCASH
  );

  return lastCreatedPaymentFundingSource || qbCashFundingSource;
}

export function getCreateOrigin(siteName) {
  if (/qbm/i.test(siteName)) {
    const mobileOs = getMobileOperatingSystem();

    if (mobileOs !== OperatingSystemEnum.UNKNOWN) {
      return mobileOs === OperatingSystemEnum.ANDROID
        ? PaymentCreateFlowOriginEnum.QBM_ANDROID
        : PaymentCreateFlowOriginEnum.QBM_IOS;
    }
  }

  return PaymentCreateFlowOriginEnum.QBO_INTEGRATION;
}

export const getFormattedNotifications = (notifications: NotificationType[]) =>
  notifications.map((notification) => {
    const formattedDistanceToNow = formatDistanceToNowStrict(new Date(notification.date));

    // Because both months and minutes starts with the letter m, therefore minutes will be displayed as m and months as mo
    const isMonthlyFormatting = formattedDistanceToNow.includes('month');
    const splittedDate = formattedDistanceToNow.split(' ');

    // example of output - 4 days will become 4d and 4 months will become 4mo
    const formattedDate =
      splittedDate[0] + splittedDate[1].substring(0, isMonthlyFormatting ? 2 : 1);

    return {
      ...notification,
      date: formattedDate,
    };
  });

export const sortNotificationsById = (notifications: NotificationType[]) =>
  notifications.sort((a, b) => (Number(a.id) < Number(b.id) ? 1 : -1));

export const isCompanyEligibleForInternationalPayment = (companyInfo: CompanyInfoType) => {
  const NOT_ALLOWED_STATES = ['TX', 'VT'];

  return (
    companyInfo?.legalState &&
    companyInfo.state &&
    !(
      NOT_ALLOWED_STATES.includes(companyInfo.legalState) ||
      NOT_ALLOWED_STATES.includes(companyInfo.state)
    )
  );
};
