// <reference types="googlemaps" />
/* eslint-disable no-return-assign */
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { compose } from 'recompose';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import styled, { css } from 'styled-components';
import { MICheckbox } from 'src/app/components/common/MICheckbox';
import { ADDRESS_NO_GOOGLE_PLACE_ID } from 'src/app/version-2/model/constants';
import { AddressFormControlModeEnum, TextInputSizeEnum } from 'src/app/version-2/model/enums';
import { GoogleCombinedAddressType, GooglePlaceType } from '../../utils/types';
import { googleMapStyles, mapContainerStyle } from '../../theme/GoogleMapStyle';
import { MITextInput, INPUT_TYPE } from './MITextInput';
import { envApi } from '../../version-2/api/env';
import pin from '../../images/general/map-pin.png';
import { DEFAULT_LOCATION, COUNTRY } from '../../utils/consts';
import { withBreak } from '../../hoc';
import { MIFormattedText } from '../../utils/formatting';

/**
 This component uses '@react-google-maps/api' to display a Google Maps widget and a places autocomplete input.
 Documentation: https://react-google-maps-api-docs.netlify.com/
 */
type Props = {
  id: string;
  addressLabel: string;
  addressPlaceholder?: string;
  errorMessage?: string;
  errorMessageIcon?: React.ReactNode;
  aptNumberLabel: string;
  aptNumberPlaceholder?: string;
  addressNotices?: string[];
  aptNumberNotices?: string[];
  address?: GoogleCombinedAddressType;
  disabled?: boolean;
  required?: boolean;
  mode?: AddressFormControlModeEnum;
  autoFocus?: boolean;
  showAddManualAddress?: boolean;
  limitToCountry: string;
  allowAllCountries?: boolean;
  onChange: (arg0: GoogleCombinedAddressType, type?: string) => void;
  goToManualAddress?: () => void;
  device: { isMobile: boolean };
  onSelect: (value: boolean) => void;
  showCheckbooks?: boolean;
  hideSuite: boolean;
  hideInput: boolean;
  openLegalAddressInput?: boolean;
  showClearIcon?: boolean;
};

type State = {
  place: GooglePlaceType;
  marker: google.maps.LatLngLiteral;
  aptNumber?: string;
  formattedAddress?: string;
  isMarkerSet: boolean;
  zoom: number;
  isAddressNotFound: boolean;
};

const DEFAULT_ADDRESS_STATE: any = {
  place: {
    address_components: [],
    name: undefined,
    formatted_address: undefined,
    place_id: undefined,
    geometry: {
      location: DEFAULT_LOCATION,
    },
  },
  marker: DEFAULT_LOCATION,
  isMarkerSet: false,
  zoom: 10,
  aptNumber: undefined,
  formattedAddress: undefined,
  isAddressNotFound: false,
};
const PLACE_ZOOM = 16;
const COUNTRY_TERRITORIES = Object.values(COUNTRY);
const places = [`${envApi.getConfig().services.googleMaps.url}&libraries=places&callback=initMap`];

class MIAddressAutocomplete extends React.PureComponent<Props, State> {
  static defaultProps = {
    addressNotices: [],
    aptNumberNotices: [],
    errorMessage: null,
    addressPlaceholder: '',
    aptNumberPlaceholder: '',
    address: {
      addressComponents: [],
      formattedAddress: undefined,
      geometry: DEFAULT_LOCATION,
      placeId: '',
      aptNumber: undefined,
    },
    disabled: false,
    required: false,
    mode: AddressFormControlModeEnum.WITH_MAP,
    autoFocus: false,
    showAddManualAddress: false,
    limitToCountry: COUNTRY_TERRITORIES,
    allowAllCountries: false,
    goToManualAddress: null,
    hideInput: false,
    showCheckbooks: false,
    openLegalAddressInput: false,
    showClearIcon: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = cloneDeep(DEFAULT_ADDRESS_STATE);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.address && !isEmpty(props.address.formattedAddress)) {
      if (isEmpty(state.formattedAddress) && !state.isMarkerSet) {
        return {
          place: {
            address_components: props.address.addressComponents,
            name: props.address.name,
            formatted_address: props.address.formattedAddress,
            place_id: props.address.placeId,
            geometry: {
              location: props.address.geometry,
            },
          },
          marker: props.address.geometry,
          isMarkerSet: true,
          zoom: PLACE_ZOOM,
          aptNumber: props.address.aptNumber,
          formattedAddress: props.address.formattedAddress,
        };
      }

      return {
        formattedAddress: state.formattedAddress,
      };
    }

    return null;
  }

  onLoad = () => {
    const input = document.getElementById(this.props.id) as HTMLInputElement;

    input.setAttribute('role', 'presentation');

    this.autocomplete = new window.google.maps.places.Autocomplete(input);

    if (!this.props.allowAllCountries) {
      this.autocomplete.setComponentRestrictions({
        country: this.props.limitToCountry,
      });
    }

    this.autocomplete.addListener('place_changed', this.onPlacesChanged);
  };

  onPlacesChanged = () => {
    const place = this.autocomplete.getPlace();

    if (place.geometry) {
      this.setState(
        {
          place,
          formattedAddress: place.formatted_address,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          marker: place.geometry.location.toJSON(),
          isMarkerSet: true,
          zoom: PLACE_ZOOM,
        },
        () => {
          this.props.onChange(this.buildAddress(), this.props.id);
        }
      );
    }
  };

  onAddressInputChanged = ({ value }) => {
    this.setState(
      {
        isAddressNotFound: !!value,
        formattedAddress: value,
      },
      () => {
        this.props.onChange(this.buildAddress(), this.props.id);
      }
    );
  };

  onAddressInputClear = () => {
    this.setState(
      {
        isAddressNotFound: true,
        formattedAddress: undefined,
      },
      () => {
        this.props.onChange(this.buildAddress(), this.props.id);
      }
    );
  };

  onAptNumberChanged = ({ value }) => {
    if (this.state.isMarkerSet) {
      this.setState(
        {
          aptNumber: value !== 0 && !value ? null : value,
        },
        () => {
          this.props.onChange(this.buildAddress(), this.props.id);
        }
      );
    }
  };

  autocomplete!: google.maps.places.Autocomplete;

  buildAddress = () => {
    const geometry = this.state.place.geometry?.location?.toJSON
      ? this.state.place.geometry.location.toJSON()
      : this.state.place.geometry?.location;

    this.setState({ isAddressNotFound: false });

    if (isEmpty(this.state.formattedAddress)) {
      return MIAddressAutocomplete.defaultProps.address;
    }

    return {
      addressComponents: this.state.place.address_components,
      formattedAddress: this.state.place.formatted_address,
      placeId: this.state.place.place_id,
      geometry,
      name: this.state.place.name,
      aptNumber: this.state.aptNumber,
      inputValue: this.state.formattedAddress,
    } as any;
  };

  render() {
    const {
      address,
      addressLabel,
      addressPlaceholder,
      addressNotices,
      errorMessage,
      errorMessageIcon,
      disabled,
      required,
      aptNumberLabel,
      aptNumberPlaceholder,
      aptNumberNotices,
      mode,
      id,
      device,
      goToManualAddress,
      showAddManualAddress,
      onSelect,
      showCheckbooks,
      hideSuite,
      hideInput,
      openLegalAddressInput,
      showClearIcon,
    } = this.props;
    const { marker, zoom, isMarkerSet, formattedAddress, aptNumber, isAddressNotFound } =
      this.state;
    const autoFocus = device.isMobile ? false : this.props.autoFocus;
    const isAddressSelected = !!address?.placeId && address?.placeId !== ADDRESS_NO_GOOGLE_PLACE_ID;
    const textInputSize =
      mode === AddressFormControlModeEnum.FORM || showCheckbooks
        ? TextInputSizeEnum.INLINE
        : TextInputSizeEnum.WIZARD;

    // This is here to make sure no one listens to enter events on the address component, but the component itself
    const onKeyPressed = (event) => {
      event.stopPropagation();
    };

    return (
      <MIInnerAddressAutocompleteContainer hideInput={hideInput}>
        <MapContainer mode={mode}>
          <LoadScript
            googleMapsApiKey={envApi.getConfig().services.googleMaps.key}
            libraries={places as any}
          >
            <GoogleMap
              onLoad={this.onLoad}
              mapContainerStyle={mapContainerStyle}
              zoom={zoom}
              center={marker}
              options={{
                styles: googleMapStyles,
                fullscreenControl: false,
              }}
            >
              {isMarkerSet && <Marker position={marker} icon={{ url: pin }} />}
            </GoogleMap>
          </LoadScript>
        </MapContainer>

        <AddressContainer data-testid={isAddressSelected ? 'address-selected' : ''}>
          <StandaloneSearchBoxContainer onKeyDown={onKeyPressed}>
            <MITextInput
              id={id}
              value={formattedAddress || ''}
              label={addressLabel}
              placeholder={addressPlaceholder}
              notices={addressNotices}
              errorMessage={errorMessage}
              errorMessageIcon={errorMessageIcon}
              disabled={disabled}
              required={required}
              autoFocus={autoFocus}
              onChange={this.onAddressInputChanged}
              size={textInputSize}
              type={INPUT_TYPE.SEARCH}
              showClearIcon={showClearIcon}
              privateData
            />
            {formattedAddress && showClearIcon && (
              <ClearIcon className="icon-close-icon" onClick={this.onAddressInputClear} />
            )}

            {showAddManualAddress && isAddressNotFound && (
              <CantFindAddress
                mode={mode}
                onClick={goToManualAddress}
                data-testid="cant-find-address"
              >
                <MIFormattedText label="form.addressAutocomplete.cantFindAddress" />
              </CantFindAddress>
            )}
          </StandaloneSearchBoxContainer>
          {showCheckbooks && (
            <AddLegalAddress>
              <MICheckbox
                onChange={onSelect}
                value={!!openLegalAddressInput}
                positiveLabel="form.addressAutocomplete.legalAddressLabel"
                negativeLabel="form.addressAutocomplete.legalAddressLabel"
              />
            </AddLegalAddress>
          )}
          {!hideSuite && (
            <AptNumberContainer mode={mode}>
              <MITextInput
                id="businessAddressAptNumber"
                value={aptNumber || ''}
                label={aptNumberLabel}
                placeholder={aptNumberPlaceholder}
                notices={aptNumberNotices}
                disabled={!isMarkerSet || this.props.disabled}
                onChange={this.onAptNumberChanged}
                size={
                  mode === AddressFormControlModeEnum.FORM
                    ? TextInputSizeEnum.INLINE
                    : TextInputSizeEnum.WIZARD
                }
                privateData
              />
            </AptNumberContainer>
          )}
        </AddressContainer>
      </MIInnerAddressAutocompleteContainer>
    );
  }
}

const MIInnerAddressAutocompleteContainer = styled.div<{ hideInput?: boolean }>`
  width: 100%;
  display: ${({ hideInput }) => (hideInput ? 'none' : 'block')};
`;

const MapContainer = styled.div<{ mode?: AddressFormControlModeEnum }>`
  margin-bottom: ${(props) =>
    props.mode !== AddressFormControlModeEnum.WITH_MAP ? '0' : '4.8rem'};
  display: ${(props) => (props.mode === AddressFormControlModeEnum.WITH_MAP ? 'block' : 'none')};
`;

const AptNumberContainer = styled.div<{ mode?: AddressFormControlModeEnum }>`
  display: flex;
  width: 100%;
  ${(props) =>
    props.mode === AddressFormControlModeEnum.FORM &&
    css`
      margin: 1.6rem 0 0;
    `}
  ${(props) => props.theme?.components?.MIAddressAutocomplete?.AptNumberContainer}
`;

const AddressContainer = styled.div`
  width: 100%;
  ${(props) => props.theme?.components?.MIAddressAutocomplete?.AddressContainer}
`;

const StandaloneSearchBoxContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  input[type='search']::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  }

  ${(props) => props.theme?.components?.MIAddressAutocomplete?.StandaloneSearchBoxContainer}
`;

const CantFindAddress = styled.div<{ mode?: AddressFormControlModeEnum }>`
  height: 3rem;
  top: ${(props) => (props.mode === AddressFormControlModeEnum.FORM ? '4.8rem' : '7.2rem')};
  width: 100%;
  position: absolute;
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 3rem;
  background: ${(props) => props.theme.colors.white.opaque};
  box-shadow: 0 5px 6px 0px rgba(0, 0, 0, 0.3);
  padding: 0 1rem;
  color: ${(props) => props.theme.text.color.highlight};
  box-sizing: border-box;
  cursor: pointer;
`;
const AddLegalAddress = styled.div`
  ${(props) => props.theme?.components?.MIAddressAutocomplete?.AddLegalAddress}
`;

const ClearIcon = styled.i`
  cursor: pointer;
  top: 2rem;
  right: -0.5rem;
  font-size: 2.6rem;
  position: absolute;
`;

MIAddressAutocomplete.defaultProps = {
  addressNotices: [],
  errorMessage: null,
  aptNumberNotices: [],
  addressPlaceholder: 'form.addressAutocomplete.addressPlaceholder',
  aptNumberPlaceholder: '',
  address: {
    addressComponents: [],
    formattedAddress: undefined,
    geometry: DEFAULT_LOCATION,
    placeId: '',
    name: '',
    aptNumber: undefined,
  } as any,
  disabled: false,
  required: false,
  mode: AddressFormControlModeEnum.WITH_MAP,
  autoFocus: false,
  limitToCountry: COUNTRY_TERRITORIES,
  allowAllCountries: false,
  goToManualAddress: null,
  showAddManualAddress: false,
  showCheckbooks: false,
  hideInput: false,
  openLegalAddressInput: false,
  showClearIcon: false,
};

export default compose(withBreak())(MIAddressAutocomplete);
