import styled, { css } from 'styled-components';

export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const UploadError = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  font-weight: ${(props) => props.theme.fontWeights.ds.semi};
  color: ${({ theme }) => theme.colors.ds.red[200]}};
`;

export const FileName = styled.div<{ isFileUploadError?: boolean }>`
  display: flex;
  flex: 1;
  color: ${({ isFileUploadError, theme }) =>
    isFileUploadError ? theme.colors.ds.red[200] : theme.colors.ds.green[200]};
  letter-spacing: -0.02rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 2rem;
  ${({ theme }) => theme.textStyles.ds.body1};
`;

export const IconWrapper = styled.div`
  margin: 2rem;

  img {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 2rem;
  }
`;

export const ModifyAttachmentActionContainer = styled.div<{
  isShowDeleteButton?: boolean;
}>`
  display: flex;
  justify-content: ${({ isShowDeleteButton }) => (isShowDeleteButton ? 'space-between' : 'center')};
  max-width: ${({ isShowDeleteButton }) => (isShowDeleteButton ? '100%' : '19rem')};
  width: 100%;
  overflow: hidden;
  margin-left: 1rem;
  align-self: center;
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.ds.red[200]}};

  &:last-of-type {
    margin-top: 0;
  }
`;

export const ModifyAttachmentAction = styled.div<{
  destructive?: boolean;
  isDisabled?: boolean;
}>`
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ destructive, theme }) =>
    destructive ? theme.colors.ds.red[200] : theme.text.color.black};
  text-transform: none;
  position: relative;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.3' : '1')};
  text-transform: unset;
  &:hover {
    cursor: pointer;
    color: ${({ theme, destructive }) =>
      destructive ? theme.colors.ds.red[200] : theme.colors.ds.green[200]};
  }
  margin-right: 1rem;

  &:last-of-type {
    margin-top: 0;
  }
  font-weight: ${(props) => props.theme.fontWeights.ds.semi};
  ${({ theme }) => theme.textStyles.ds.body1};
`;

export const UploadArea = styled.div<{ error?: boolean }>`
  height: 5rem;
  border: 0.1rem dashed
    ${({ theme, error }) => (error ? theme.colors.ds.red[200] : theme.text.color.black)};
  border-radius: 0.8rem;
  max-width: 45rem;
  position: relative;
  ${(p) =>
    !p.error &&
    css`
      &:hover {
        border: 0.1rem dashed ${({ theme }) => theme.colors.ds.green[200]};
      }
    `};
`;

export const Label = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.ds.gray[200]}};
  ${({ theme }) => theme.textStyles.ds.body1};
`;

export const Tooltip = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.ds.gray[200]}};
  ${({ theme }) => theme.textStyles.ds.body3};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.ds.gray[200]}};
  ${({ theme }) => theme.textStyles.ds.body1};
`;

export const ErrorAction = styled.div`
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text.color.black};

  text-transform: uppercase;
  position: relative;

  ${({ theme }) => theme.textStyles.ds.body1};
`;
