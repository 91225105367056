export const selectedBorderStyle = {
  bg: 'ds.green.200',
  bottom: '-0.2rem',
  position: 'absolute',
  height: 'ds.sm',
  left: 0,
  width: '100%',
  borderTopRightRadius: 'ds.sm',
  borderTopLeftRadius: 'ds.sm',
};
