import React from 'react';
import { Switch } from 'react-router-dom';
import MobileAppLayout from '../../components/layout/MobileAppLayout';
import BillsListPageContainer from '../bill/BillsListPageContainer';
import { SmartRoute } from '../../modules/navigation/components/SmartRoute';
import locations from './locations';

export function DashboardRouter() {
  return (
    <MobileAppLayout>
      <Switch>
        <SmartRoute path={locations.bills}>
          <BillsListPageContainer />
        </SmartRoute>
      </Switch>
    </MobileAppLayout>
  );
}
