import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useHistory, useLocation } from 'react-router-dom';

import { getCompanyInfo } from 'src/app/redux/user/selectors';
import Step from 'src/app/components/layout/QBOLayoutPage';
import { useForm } from 'src/app/ui/form';
import { validateMultiFields } from 'src/app/utils/form-utils';
import analytics from 'src/app/services/analytics';
import ultimateBeneficialOwnersAPI from 'src/app/services/api/organization-ultimate-beneficial-owners';
import { useApi } from 'src/app/hoc/useApi';
import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS, NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import MIButton from 'src/app/components/common/MIButton';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { useLocationState } from 'src/app/utils/hooks';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { StepProps } from '../../types';
import Form from './Form';
import { ButtonContainer } from 'src/app/pages/vendor/delivery-methods/international/components/ButtonContainer';
import InternationalContext from '../../Context';
import {
  initialErrorValues,
  getInitialFormState,
  getFieldsValidations,
  convertDataToPayload,
  FORMS,
  ERROR_PREFIX,
} from './utils';
import { ActiveForms, ErrorForms } from './types';

const UltimateBenficialOwners = ({ onNext, onExit }: StepProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, actions] = useContext(InternationalContext);
  const companyInfo = useSelector(getCompanyInfo, shallowEqual);
  const [activeForms, setActiveForms] = useState<ActiveForms>([FORMS[0]]);
  const [validationErrors, setValidationErrors] = useState<ErrorForms>(initialErrorValues());
  const [showError, setShowError] = useState<boolean>(false);
  const isBatchLoading: boolean = useSelector(batchBulkSelectors.isScheduleDisabled);
  const organizationId = state?.orgId;
  const [createOrganizationUltimateBeneficialOwners, , isLoading] = useApi(
    ultimateBeneficialOwnersAPI.createOrganizationUltimateBeneficialOwners
  );
  const [newDeliveryMethod] = useLocationState('newDeliveryMethod', '');
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  // some flows override one of the params
  const isBatchCreateDM = query.get('isBatchCreateDM') || state.isBatchCreateDM;
  const isBatchDirect = query.get('isBatchDirect') || state.isBatchDirect;

  useEffect(() => {
    analytics.page('pay-bill', 'international-ubo');
  }, []);

  const model = useMemo(getInitialFormState, []);
  const onSubmit = async (values) => {
    const { isValid, errors } = validateForms();

    setShowError(false);

    analytics.track('pay-bill', 'ubo-save-details', {
      errors,
      count: activeForms.length,
    });

    if (!isValid) {
      if (activeForms.length > 1) setShowError(true);

      return;
    }

    const filtered = pick(values, activeForms);
    const payload = convertDataToPayload(filtered);

    try {
      await createOrganizationUltimateBeneficialOwners(organizationId, payload);

      if (isBatchDirect) {
        // go to dashboard after ubo (batch submit)
        dispatch(batchBulkActions.createBatchBulkPayment({ history }));
      } else if (isBatchCreateDM) {
        // go back to batch (batch create new dm)
        actions.goToPath({
          path: state.redirectUrl,
          state: {
            newDeliveryMethod: {
              ...newDeliveryMethod,
              deliveryType: CONSTS.DELIVERY_TYPE.INTERNATIONAL,
            },
            ...(state?.preservedState || {}),
          },
          params: { currentId: state.billId },
        });
      } else {
        // single flow
        setTimeout(() => onNext?.());
      }
    } catch (e) {
      // Failed
    }
  };
  const [values, { submit }] = useForm(model, { submit: onSubmit });

  const validateForms = (): { isValid: boolean; errors: Record<string, any> } => {
    const errors: any = {};

    FORMS.forEach((form) => {
      const isActiveForm = activeForms.includes(form);
      const validations = getFieldsValidations(values[form]);
      const checkErrors = validateMultiFields(validations, ERROR_PREFIX);

      errors[form] = isActiveForm ? checkErrors : {};
    });

    setValidationErrors(errors);

    return {
      isValid: Object.values(errors).every((form) => isEmpty(form)),
      errors,
    };
  };

  const onCustomExit = () => {
    if (isBatchDirect) {
      // leave ubo directly from batch submit (back to batch)
      return history.goBack();
    }

    if (isBatchCreateDM) {
      // leave ubo from international new dm flow
      return actions.goToPath({
        path: state.redirectUrl,
        state: {
          newDeliveryMethod: {
            ...newDeliveryMethod,
            deliveryType: CONSTS.DELIVERY_TYPE.INTERNATIONAL,
          },
          ...(state?.preservedState || {}),
        },
        params: { currentId: state.billId },
      });
    }

    // single default exit
    return onExit?.();
  };

  return (
    <Step
      goExit={onCustomExit}
      hideHeader
      title="international.ultimateBeneficialOwners.title"
      titleValues={{ companyName: companyInfo?.companyName }}
      subtitle="international.ultimateBeneficialOwners.subtitle"
    >
      {showError && (
        <ErrorNotification
          type={NOTIFICATION_CARD_TYPES.ERROR}
          renderText={
            <MIFormattedText label="international.ultimateBeneficialOwners.missingFieldsError" />
          }
        />
      )}

      <FormWrapper onSubmit={submit}>
        {activeForms.map((form) => (
          <Form
            key={form}
            namespace={form}
            validateForms={validateForms}
            validationErrors={validationErrors[form]}
            setValidationErrors={setValidationErrors}
            values={values[form]}
            activeForms={activeForms}
            setActiveForms={setActiveForms}
          />
        ))}

        <ButtonContainer>
          <MIButton
            type="submit"
            disabled={false}
            isProcessing={isLoading || isBatchLoading}
            className="button"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            label="international.vendorDetails.button"
          />
        </ButtonContainer>
      </FormWrapper>
    </Step>
  );
};

export default UltimateBenficialOwners;

const FormWrapper = styled.form`
  .button {
    display: flex;
    align-items: center;
    margin: 4.5rem auto 0;
  }
`;

const ErrorNotification = styled(QBONotificationCard)`
  margin-bottom: 4rem;
  font-size: 1.4rem;
  line-height: 2rem;
`;
