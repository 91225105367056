import styled from 'styled-components';

export const StepContainer = styled.div`
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.ds.white};
  border: 0.1rem solid ${(props) => props.theme.colors.ds.gray[500]};
  border-radius: 0.4rem;
  box-shadow: 0rem 0rem 0.4rem rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.textStyles.ds.h6};
  font-weight: ${(props) => props.theme.fontWeights.ds.semi};
`;

export const BodyContainer = styled.div`
  ${(props) => props.theme.textStyles.ds.body2}
`;

export const CloseIcon = styled.i`
  font-size: 2.4rem;
  color: ${(props) => props.theme.colors.ds.gray[100]};
  &:hover {
    cursor: pointer;
  }
`;

export const StepsText = styled.div`
  ${(props) => props.theme.textStyles.ds.body2}
  color: ${(props) => props.theme.colors.ds.gray[300]};
`;

export const Arrow = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-top: 0.1rem solid ${(props) => props.theme.colors.ds.gray[500]};
  border-left: 0.1rem solid ${(props) => props.theme.colors.ds.gray[500]};
  background-color: ${(props) => props.theme.colors.ds.white};
  box-shadow: 0rem 0rem 0.4rem rgba(0, 0, 0, 0.2);
  z-index: 5;
  position: absolute;
  &.top-start {
    bottom: -0.7rem;
    left: 1.6rem;
    transform: rotate(-135deg);
  }
  &.top {
    bottom: -0.7rem;
    left: 50%;
    transform: translateX(-50%) rotate(-135deg);
  }
  &.top-end {
    bottom: -0.7rem;
    right: 1.6rem;
    transform: rotate(-135deg);
  }
  &.bottom-start {
    top: -0.7rem;
    left: 1.6rem;
    transform: rotate(45deg);
  }
  &.bottom {
    top: -0.7rem;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  &.bottom-end {
    top: 0;
    right: 1.6rem;
    transform: rotate(45deg);
  }
  &.left-start {
    top: 1.6rem;
    right: -0.7rem;
    transform: rotate(135deg);
  }
  &.left {
    top: 50%;
    right: -0.7rem;
    transform: translateY(-50%) rotate(135deg);
  }
  &.left-end {
    bottom: 1.6rem;
    right: 0;
    transform: rotate(135deg);
  }
  &.right-start {
    top: 1.6rem;
    left: -0.7rem;
    transform: rotate(-45deg);
  }
  &.right {
    top: 1.6rem;
    left: -0.7rem;
    transform: translateY(-50%) rotate(-45deg);
  }
  &.right-end {
    bottom: 1.6rem;
    left: -0.7rem;
    transform: rotate(-45deg);
  }
`;

export const ArrowShadow = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 1.7rem;
    height: 1.7rem;
    background-color: ${(props) => props.theme.colors.ds.white};
  }
`;

export const flexFooterButtonsStyles = {
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '0.4rem',
};
export const flexFooterStyles = { justifyContent: 'space-between', alignItems: 'center' };
export const mainContainerStyles = {
  width: '32.8rem',
  position: 'fixed' as const,
  id: 'current-guide-step',
};
