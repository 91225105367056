import * as React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BillPageType } from 'src/app/utils/types';
import { BILL_PAGE_TYPE } from 'src/app/utils/consts';

type MIFormTitleWithTextProps = {
  title: string;
  text: string;
  errorMessage: string | null;
  pageType: BillPageType;
};

const MIFormTitleWithText = ({ title, text, errorMessage, pageType }: MIFormTitleWithTextProps) => (
  <TitleWithTextContainer pageType={pageType}>
    {title && (
      <Title>
        <MIFormattedText label={title} />
      </Title>
    )}
    <Text>
      <MIFormattedText label={text} />
    </Text>
    {errorMessage && (
      <Error>
        <MIFormattedText label={errorMessage} />
      </Error>
    )}
  </TitleWithTextContainer>
);

export { MIFormTitleWithText };

const textStyles = css`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: 2rem;
`;

const Title = styled.div`
  ${textStyles};
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 2rem;
`;

const Text = styled.div`
  ${textStyles};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const Error = styled.div`
  ${textStyles};
  color: ${(props) => props.theme.text.color.error};
`;

const TitleWithTextContainer = styled.div<{ pageType: BillPageType }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.pageType === BILL_PAGE_TYPE.CREATE ? '-0.8rem' : '3rem')};
  margin-bottom: 2rem;
`;
