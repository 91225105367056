import * as React from 'react';
import styled from 'styled-components';
import ViewDetailsLink from 'src/app/components/common/ViewDetailsLink';
import { SmartLink } from 'src/app/modules/navigation/components/SmartLink';
import { ReactRouterNavTo } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';

import removeImage from 'src/app/images/qbo/ic_delete.svg';
import editImage from 'src/app/images/qbo/ic_edit.svg';
import find from 'lodash/find';

type Props = {
  backNav?: ReactRouterNavTo | null;
  label?: string | null;
  text?: string | null;
  subTitle?: string | React.ReactNode | null;
  closeNav?: ReactRouterNavTo | null;
  children?: React.ReactNode;
  actionOptions?: any[];
  isPartialPayments?: boolean;
  goViewDetails?: () => void;
};

const QBRPageHeader = ({
  backNav,
  label,
  text,
  subTitle,
  closeNav,
  children,
  actionOptions,
  isPartialPayments,
  goViewDetails,
}: Props) => {
  if (!text && !label) {
    return <></>;
  }

  const isRemoveAction = find(actionOptions, (el) => el.label === 'bills.actions.delete');
  const isEditAction = find(actionOptions, (el) => el.label === 'bills.actions.edit');

  return (
    <PageHeaderContainer>
      <PageHeaderTop>
        {backNav ? (
          <StyledHeaderLink to={backNav as any}>
            <i className="icon-prev-icon" />
          </StyledHeaderLink>
        ) : (
          <StyledHeaderLinkEmpty />
        )}

        <PageHeaderTitle>
          {label && (
            <div>
              <MIFormattedText label={label} />
            </div>
          )}
          {text && <span>{text}</span>}
        </PageHeaderTitle>

        {closeNav ? (
          <StyledHeaderLink to={closeNav as any}>
            <i className="icon-close-icon" />
          </StyledHeaderLink>
        ) : (
          <StyledHeaderLinkEmpty />
        )}

        <ImageArea>
          {isRemoveAction && isEditAction && (
            <>
              <RemoveImage src={removeImage} onClick={() => isRemoveAction.action()} />
              <EditImage src={editImage} onClick={() => isEditAction.action()} />
            </>
          )}
        </ImageArea>
      </PageHeaderTop>

      <PageHeaderCenter>
        {subTitle && <PageHeaderSubTitle>{subTitle}</PageHeaderSubTitle>}

        {isPartialPayments && <ViewDetailsLink goViewDetails={goViewDetails} />}

        {children && <PageHeaderCenterChildren>{children}</PageHeaderCenterChildren>}
      </PageHeaderCenter>
    </PageHeaderContainer>
  );
};

export default QBRPageHeader;

const PageHeaderContainer = styled.div`
  min-height: 6rem;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.white.opaque};
  flex-direction: column;
  box-shadow: none;
  height: 6rem;

  @media ${devices.mobile}, ${devices.phablet} {
    position: sticky;
    top: 0;
    width: 100%;
    max-width: 63rem;
    z-index: 1011;
    display: flex;
    padding: 2rem 2rem 2rem 2rem;
  }

  @media ${devices.phablet} {
    padding: 2rem 4rem 2rem 4rem;
  }

  @media ${devices.tablet} {
    display: none;
  }

  @media ${devices.desktop} {
    display: none;
  }
`;

const PageHeaderTop = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  min-height: 2.3rem;
  align-items: center;
`;

const PageHeaderCenter = styled.div`
  display: none;
`;

const PageHeaderTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.main};
  line-height: 2.3rem;
  min-height: 2.3rem;
  flex-direction: row;
  flex-grow: 1;
  text-align: center;
  display: none;

  @media ${devices.mobile}, ${devices.phablet} {
    display: block;
  }

  div {
    display: block;
  }
  span {
    display: none;
  }
`;

const PageHeaderSubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.label};
  line-height: 2rem;
  height: 2rem;
  overflow-y: hidden;
`;

const PageHeaderCenterChildren = styled.div`
  margin-top: 1.3rem;
`;

const StyledHeaderLink = styled(SmartLink)`
  width: 2.3rem;
  height: 2.3rem;
  font-size: 2.3rem;
  text-decoration: none;
  min-width: 6.8rem;
  color: ${({ theme }) => theme.text.color.green};
`;

const StyledHeaderLinkEmpty = styled.div`
  width: 6.8rem;
`;

const ImageArea = styled.div`
  display: none;
`;

const RemoveImage = styled.img`
  margin-right: 2rem;
`;

const EditImage = styled.img``;
