import { featureFlags } from '@melio/shared-web';
import { envApi } from 'src/app/version-2/api/env';
import { loggingApi } from 'src/app/version-2/api/loggers';

type TrackVariant = (variantName: string, value: unknown) => unknown;

const initialize = (trackVariant: TrackVariant) => {
  if (envApi.getConfig().services.featureFlagProvider.enabled) {
    featureFlags.defaultClient
      .initialize({
        clientId: envApi.getConfig().services.featureFlagProvider.clientId,
        trackVariant,
      })
      .catch((error) => {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';

        loggingApi.error('featureFlags.initialize(): feature flags initialization failed:', {
          message: errorMessage,
          stack: error.stack,
        });
      });
  }
};

const identify = async (user: featureFlags.User, organization: featureFlags.Organization) => {
  if (envApi.getConfig().services.featureFlagProvider.enabled) {
    await featureFlags.defaultClient.identify(
      { id: user.id, email: user.email },
      {
        id: organization.id,
        companyType: organization.companyType,
        organizationPreferences: organization.organizationPreferences,
      }
    );
  }
};

export { initialize, identify };
