import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from 'src/app/helpers/react/useModal';
import fundingSourcesStore from 'src/app/modules/funding-sources/funding-sources-store';
import { QuickPayment } from 'src/app/modules/payments/quickpay-slice';
import { getFee } from 'src/app/redux/payBillWizard/selectors';
import { BillRecord } from 'src/app/pages/bill/records';
import paymentStore from 'src/app/modules/payments/payment-store';
import { AccountRecord } from 'src/app/pages/settings/records';
import { Loader } from '@melio/billpay-design-system';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import QBRPayBillConfirmPage from 'src/app/pages/bill/pay/components/QBRPayBillConfirmPage';
import { getOrgId, getDMVirtualAccountEmail } from 'src/app/redux/user/selectors';
import { CommonDialog } from 'src/app/ui/dialog/CommonDialog';
import {
  QBCashStateType,
  NotePlaceholderType,
  OrganizationPreferencesType,
} from 'src/app/utils/types';
import { BUTTONS_DIRECTIONS, DIALOG_VARIANTS } from 'src/app/utils/consts';
import { getInitialProcessingDates } from 'src/app/utils/dates';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import head from 'lodash/head';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';

type Props = {
  onPrev: () => void;
  onExit: () => void;
  goEditNote: () => void;
  goEditDate: () => void;
  goEditDeliveryMethod: () => void;
  onSubmit: (payment: QuickPayment) => void;
  headerLabel: string;
  isLoading: boolean;
  buttonLabel: string;
};
const ConfirmPage = ({
  onPrev,
  onExit,
  goEditNote,
  goEditDate,
  goEditDeliveryMethod,
  onSubmit,
  headerLabel,
  isLoading,
  buttonLabel,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const dmVirtualAccountEmail = useSelector(getDMVirtualAccountEmail);
  const paymentActions = useStoreActions(paymentStore);
  const selectedFundingSource = AccountRecord(head(useSelector(fundingSourcesStore.selectors.all)));
  const qbCashState: QBCashStateType = useSelector(
    fundingSourcesStore.selectors.getQBCashBalance.state
  );
  const payment: QuickPayment = useSelector(
    paymentStore.selectors.quickpay.newPaymentWizard.payment
  );
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const vendorId = payment?.vendorId;
  const { vendor } = useFetchVendor(vendorId);

  const [BalanceConfirmModal, showBalanceConfirmModal] = useModal(CommonDialog, {
    confirm: () => onSubmit(payment),
    variant: DIALOG_VARIANTS.SUCCESS,
    buttonsDirection: BUTTONS_DIRECTIONS.VERTICAL,
    title: 'bills.pay.confirm.balanceConfirm.title',
    description: 'bills.pay.confirm.balanceConfirm.description',
    confirmText: 'bills.pay.confirm.balanceConfirm.confirm',
    cancelText: 'bills.pay.confirm.balanceConfirm.cancel',
    modalName: 'balanceConfirm',
  });
  const handleSubmit = useCallback(
    (payment) => {
      if (payment.totalAmount > qbCashState.balance) {
        showBalanceConfirmModal();
      } else {
        onSubmit(payment);
      }
    },
    [onSubmit, qbCashState.balance, showBalanceConfirmModal]
  );
  const onHideErrorMessageAlert = () => {
    loggingApi.log('ConfirmPage.onHideErrorMessageAlert(): onHideErrorMessageAlert');
  };

  const fetchProcessingDatesAndUpdatePayment = useCallback(
    async ({ fundingSourceId, totalAmount, deliveryMethodId, scheduledDate }: QuickPayment) => {
      if (deliveryMethodId) {
        const { suggestedScheduledDate, deliveryDate, maxDeliveryDate } =
          await getInitialProcessingDates({
            orgId,
            deliveryMethodId: Number.parseInt(deliveryMethodId, 10),
            fundingSourceId,
            scheduledDate: scheduledDate || new Date(),
            amount: totalAmount,
            undefined,
            paymentId: payment?.id,
            payBillFlowUUID: payment?.payBillFlowUUID,
            isFirstWave: !!organizationPreferences?.billPayFirstWaveUser,
          });

        paymentActions.quickpay.newPaymentWizard.update({
          scheduledDate: suggestedScheduledDate,
          deliveryEta: deliveryDate,
          maxDeliveryEta: maxDeliveryDate,
          fundingSourceId,
        });
      }
    },
    [orgId, paymentActions.quickpay.newPaymentWizard]
  );

  useEffect(() => {
    if (payment) {
      fetchProcessingDatesAndUpdatePayment(payment);
    }
    // do not remove the empty array because this is a loop
    // payment is updated in this use effect and then re-created again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fee = useSelector(getFee);
  const errorCode = null;

  if (vendor) {
    const bill = BillRecord({
      id: -1 as unknown as string,
      vendor: {
        ...vendor,
        companyName: vendor.printOnCheck || vendor.companyName,
      } as any,
      scheduledDate: payment.scheduledDate,
      totalAmount: payment.totalAmount,
      payment,
    } as any);
    const notePlaceholder: NotePlaceholderType = {
      view: 'bills.pay.memo.uneditablePlaceholder',
      edit: 'bills.pay.memo.placeholder',
    };

    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        {BalanceConfirmModal}
        <QBRPayBillConfirmPage
          isRecurring={false}
          headerLabel={headerLabel}
          recurringBill={{}}
          onSubmit={handleSubmit}
          onPrev={onPrev}
          bill={bill}
          payment={payment}
          selectedFundingSource={selectedFundingSource}
          isLoading={isLoading}
          goExit={onExit}
          goEditDeliveryMethod={goEditDeliveryMethod}
          goEditNote={goEditNote}
          goEditDate={goEditDate}
          errorCode={errorCode}
          fee={fee}
          onHideErrorMessageAlert={onHideErrorMessageAlert}
          title="bills.pay.confirm.title"
          isFundingSourceEditable={false}
          qbCashState={qbCashState}
          buttonLabel={buttonLabel}
          notePlaceholder={notePlaceholder}
          dmVirtualAccountEmail={dmVirtualAccountEmail}
        />
      </>
    );
  }

  return <Loader />;
};

export default ConfirmPage;
