import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { QuickPayment } from 'src/app/modules/payments/quickpay-slice';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import QBRPayBillMemoPage from 'src/app/pages/bill/pay/components/QBRPayBillMemoPage';
import { NOTE_FIELD_MAX_LENGTH } from 'src/app/version-2/model/constants';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const EditNotePage = ({ onNext, onPrev, onExit }: Props) => {
  const payment: QuickPayment = useSelector(
    paymentStore.selectors.quickpay.newPaymentWizard.payment
  );
  const vendorId = payment?.vendorId;
  const [note, setNote] = useState(payment?.note || '');
  const { vendor } = useFetchVendor(vendorId);
  const paymentActions = useStoreActions(paymentStore);
  const onSubmit = () => {
    paymentActions.quickpay.newPaymentWizard.update({
      note,
    });
    onNext();
  };

  const updatePaymentNote = (memo: string) => {
    setNote(memo);
  };
  const companyName = vendor?.printOnCheck || vendor?.companyName;
  const labelValues = {
    label: (
      <EditLabel>
        <MIFormattedText values={{ companyName }} label="bills.pay.memo.inputTitle" />
      </EditLabel>
    ),
  };

  return (
    <QBRPayBillMemoPage
      onNext={onSubmit}
      onPrev={onPrev}
      goExit={onExit}
      onChange={updatePaymentNote}
      memo={note}
      companyName={companyName}
      amount={String(payment.totalAmount)}
      headerLabel="qbr.edit.note.title"
      title="bills.pay.memo.title"
      subtitle="bills.pay.memo.subtitle"
      billId="-1"
      labelValues={labelValues}
      label="bills.pay.memo.inputTitleValue"
      maxLength={NOTE_FIELD_MAX_LENGTH}
      hint="bills.pay.memo.counter"
      hintValues={{
        length: note.length,
        maxLength: NOTE_FIELD_MAX_LENGTH,
      }}
    />
  );
};

export default EditNotePage;

const EditLabel = styled.div`
  display: inline;
`;
