import React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';

const SandboxIndicator = () => (
  <SandboxIndicationContainer isQbo>
    <MIFormattedText label="onboarding.fundingSources.card.sandboxIndication" />
  </SandboxIndicationContainer>
);

export default SandboxIndicator;

const SandboxIndicationContainer = styled.div<{ isQbo?: boolean }>`
  background-color: ${(props) => props.theme.text.color.label};
  color: ${(props) => props.theme.colors.white.opaque};
  font-size: 1.5rem;
  line-height: 3.2rem;
  height: 3.2rem;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;

  ${(props) =>
    props.isQbo &&
    css`
      position: relative;
    `};

  ${(props) =>
    props.isQbo &&
    css`
      @media ${devices.mobile} {
        margin-top: 4rem;
      }
    `};
`;
