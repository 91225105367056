import { VendorType } from 'src/app/utils/types';
import {
  DirectoryType,
  GroupedVendorsType,
  VendorOptionType,
} from 'src/app/pages/vendor-directory/select-vendor/types';
import {
  SELECT_VENDOR_TYPE,
  VENDOR_SELECT_OPTION_HEIGHT,
  VENDOR_SELECT_OPTION_PADDING,
} from 'src/app/pages/vendor-directory/select-vendor/consts';
import { isRppsVendor } from 'src/app/pages/vendor-directory/utils';
import isEmpty from 'lodash/isEmpty';
import {
  getSelectOptionObject,
  Option,
  overrideStyles,
} from 'src/app/components/common/MISingleSelect';
import directoryApi from 'src/app/services/api/directory';
import { getValidationErrors } from '@melio/sizzers-js-common';

export const groupLocalAndDirectoryVendors = (
  localVendors: VendorOptionType[],
  directoryVendors: VendorOptionType[] = []
): GroupedVendorsType => [
  {
    type: SELECT_VENDOR_TYPE.LOCAL,
    options: localVendors,
  },
  {
    type: SELECT_VENDOR_TYPE.DIRECTORY,
    options: directoryVendors,
  },
];

export const createLocalVendorOption = (localVendor: VendorType): VendorOptionType => ({
  type: SELECT_VENDOR_TYPE.LOCAL,
  value: String(localVendor.id),
  label: localVendor.companyName,
  isRppsVendor: isRppsVendor(localVendor),
});

export const generateNewVendorId = () => String(Math.floor(Math.random() * Math.floor(10000)) * -1);

export const createNewLocalVendorOption = (option): VendorOptionType => {
  const { value, newFromDirectory, directoryId, address, contactName, contactEmail, contactPhone } =
    option;

  return {
    type: SELECT_VENDOR_TYPE.LOCAL,
    value: generateNewVendorId(),
    label: value,
    newFromDirectory,
    directoryId,
    address,
    contactName,
    contactEmail,
    contactPhone,
  };
};

export const createNewLocalVendorOptionFromName = (vendorName: string): VendorOptionType => ({
  type: SELECT_VENDOR_TYPE.LOCAL,
  value: generateNewVendorId(),
  label: vendorName,
});

export const createNetworkVendorOption = (networkVendor: DirectoryType): VendorOptionType => ({
  type: SELECT_VENDOR_TYPE.DIRECTORY,
  value: generateNewVendorId(),
  label: networkVendor.companyName,
  address: networkVendor.address,
  directoryId: networkVendor.id,
  contactName: networkVendor.contactName,
  contactEmail: networkVendor.contactEmail,
  contactPhone: networkVendor.contactPhone,
});

export const filterLocalVendors = (
  vendors: VendorOptionType[],
  value: string
): VendorOptionType[] =>
  vendors.filter((vendor) => vendor.label.toLowerCase().includes(value.toLowerCase()));

export const getSelectOverrideStyle = (
  options: Option[],
  theme: any,
  isEditPageType: boolean
): overrideStyles => ({
  option: {
    padding: '0',
  },
  groupHeading: {
    // The group headers should stick to the top.
    position: 'sticky',
    paddingTop: '0.8rem',
    top: 0,
    background: 'white',
  },
  menuList: {
    padding: 0,
    backgroundColor: 'white',
    zIndex: '1000',
    maxHeight: isEditPageType
      ? `${VENDOR_SELECT_OPTION_HEIGHT * 6 + VENDOR_SELECT_OPTION_PADDING * 2}rem`
      : `${VENDOR_SELECT_OPTION_HEIGHT * 8 + VENDOR_SELECT_OPTION_PADDING * 2}rem`,
    // The creatable option should stick to the bottom.
    '& > .select__option': {
      position: 'sticky',
      borderTop: `0.1rem solid ${theme.colors.border.grey}`,
      paddingBottom: '0.8rem',
      bottom: 0,
      '&:only-child': {
        borderTop: 'none',
      },
    },
  },
  group: {
    borderBottom: `0.1rem solid ${theme.colors.border.grey}`,
    '&:only-of-type': {
      borderBottom: `none`,
    },
    '&:last-of-type': {
      borderBottom: `none`,
    },
  },
});

export const isValidNewOption = (
  inputValue: string,
  selectValue: Option[],
  selectOptions: Option[]
): boolean => {
  const localVendorOptions = selectOptions?.[0]?.options || [];

  return !(
    !inputValue ||
    selectValue.some((option) => option.label === inputValue) ||
    localVendorOptions.some((option) => option.label === inputValue)
  );
};

export const isVendorRequiresAccountIdentifier = (
  currentVendorId,
  groupedVendors?: Option[]
): boolean => {
  if (!groupedVendors) return false;

  const selectedVendor = getSelectOptionObject(groupedVendors, currentVendorId);

  return selectedVendor?.newFromDirectory || selectedVendor?.type === SELECT_VENDOR_TYPE.DIRECTORY;
};

export const addNewVendorToGroupedVendors = (
  groupedVendors: GroupedVendorsType,
  newVendor: VendorOptionType
): GroupedVendorsType => {
  const localVendors = [newVendor, ...groupedVendors[0].options];
  const directoryVendors = groupedVendors[1].options;

  return groupLocalAndDirectoryVendors(localVendors, directoryVendors);
};

export const getAccountIdentifierValidationError = async (
  orgId: string,
  billerId: string,
  accountIdentifier?: string
): Promise<{ accountIdentifier?: string }> => {
  const accountIdentifierValidationError = getValidationErrors(
    'directoryVendor',
    { accountIdentifier },
    'accountIdentifier'
  );

  if (isEmpty(accountIdentifierValidationError)) {
    const { valid } = await directoryApi.validate({
      orgId,
      billerId,
      accountNumber: accountIdentifier,
    });

    if (!valid) {
      accountIdentifierValidationError.accountIdentifier =
        'inputErrors.directoryVendor.accountIdentifier.custom.mask';
    }
  }

  return accountIdentifierValidationError;
};
