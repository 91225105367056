import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { FundingSource, CardAccount } from 'src/app/version-2/model/dtos';

import { isCardExpired } from './card';

const hasValidFundingSources = (fundingSources: FundingSource[]): boolean =>
  fundingSources.some((fs) => {
    const invalid =
      (fs.fundingType === FundingSourceTypesEnum.CARD &&
        isCardExpired(fs.cardAccount as CardAccount)) ||
      fs.bankAccount?.isBlocked;

    return !invalid;
  });

export { hasValidFundingSources };
