import moment from 'moment';
import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';

import { getOrgId } from 'src/app/redux/user/selectors';
import { CONSTS } from 'src/app/utils/consts';
import paymentsApi from 'src/app/services/api/payments';

export const useEmailVendorOnEditCheckAddres = () => {
  const orgId = useSelector(getOrgId) as string;
  const [shouldSendEmail] = featureFlags.useFeature('qb-edit-check-address');

  const emailVendorOnPaymentSchedule = ({
    deliveryType,
    scheduledDate,
    paymentId,
    contactEmail,
  }) => {
    if (moment(scheduledDate).isSameOrAfter(moment()) && !!contactEmail && paymentId) {
      if (shouldSendEmail && deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
        paymentsApi.sendEditCheckAddress(orgId, paymentId);
      }
    }
  };

  return [emailVendorOnPaymentSchedule];
};
