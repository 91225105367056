import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS } from 'src/app/utils/consts';
import * as WizardElements from './QBOWizardElements';
import * as QBOLayoutElements from './QBOElements';
import { MIFormattedText } from '../../utils/formatting';
import MIButton from '../common/MIButton';
import Logo from '../../images/Logo';

type Props = {
  illustration?: string | React.ReactNode;
  title: string;
  titleValues?: Record<string, any>;
  text?: string | null;
  textAlign?: string;
  className?: string;
  hasLogo?: boolean;
  textValues?: Record<string, any>;
  buttonLabel?: string;
  buttonAction?: () => void;
  buttonValues?: Record<string, any>;
  linkLabel?: string | null;
  linkAction?: () => void;
  isLoading?: boolean;
  shouldAskNotification?: boolean;
  hideHeader?: boolean | null;
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  children?: React.ReactNode;
  goExit?: (() => void) | null;
  notes?: string[];
  notesTitle?: string;
};

const QBOSuccessPage = ({
  illustration,
  title,
  titleValues,
  text,
  textValues,
  buttonLabel,
  buttonAction,
  buttonValues,
  linkLabel,
  linkAction,
  isLoading,
  children,
  hasLogo,
  hideHeader,
  headerLabel,
  headerLabelValues,
  goExit,
  textAlign = 'center',
  notes,
  notesTitle,
  className,
  shouldAskNotification,
}: Props) => (
  <QBOLayoutElements.QBOLayoutContainer className={className}>
    {!hideHeader && (
      <QBOLayoutElements.QBOHeaderContainer
        headerLabel={headerLabel}
        headerLabelValues={headerLabelValues}
      />
    )}

    {hideHeader && goExit && <Close onClick={goExit} />}
    <WizardElements.WizardInnerSuccess isCenterVert>
      {illustration &&
        (React.isValidElement(illustration) ? (
          illustration
        ) : (
          <WizardElements.WizardIllustration
            src={illustration as string}
            className="qbo-wizzard-illustration"
          />
        ))}
      {hasLogo && (
        <WizardElements.LogoContainer textAlign={textAlign}>
          <Logo fill="light" size="regular" isFull />
        </WizardElements.LogoContainer>
      )}

      <WizardElements.WizardTitle data-testid="wizard-title" textAlign={textAlign}>
        <MIFormattedText label={title} values={titleValues} />
      </WizardElements.WizardTitle>

      {text && (
        <WizardElements.WizardText textAlign={textAlign}>
          <MIFormattedText label={text} values={textValues} />
        </WizardElements.WizardText>
      )}

      {shouldAskNotification && (
        <WizardElements.WizardText className="success-layout-ask-text" textAlign={textAlign}>
          <MIFormattedText label="bills.pay.payBillSuccess.subtitles.askToNotify" />
        </WizardElements.WizardText>
      )}

      {children && (
        <WizardElements.WizardActionsContainer>{children}</WizardElements.WizardActionsContainer>
      )}

      {notes && notes.length > 0 && (
        <WizardElements.WizardNotesContainer label={notesTitle || ''}>
          {notes.map((note) => (
            <MIFormattedText key={note} label={note} values={textValues} />
          ))}
        </WizardElements.WizardNotesContainer>
      )}

      <WizardElements.WizardActionsRowContainer>
        {buttonLabel && buttonAction && (
          <WizardElements.WizardButtonContainer>
            <MIButton
              label={buttonLabel}
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={buttonAction}
              isProcessing={isLoading}
              values={buttonValues}
            />
          </WizardElements.WizardButtonContainer>
        )}

        {linkLabel && linkAction && (
          <WizardElements.WizardButtonContainer>
            <MIButton
              label={linkLabel}
              variant={CONSTS.BUTTON_VARIANT.SECONDARY}
              onClick={linkAction}
            />
          </WizardElements.WizardButtonContainer>
        )}
      </WizardElements.WizardActionsRowContainer>
    </WizardElements.WizardInnerSuccess>
    <QBOLayoutElements.QBOFooterContainer />
  </QBOLayoutElements.QBOLayoutContainer>
);

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  position: fixed;
  top: 1.2rem;
  right: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  z-index: 1;
  @media ${devices.mobile} {
    color: ${(props) => props.theme.text.color.green};
  }
`;

export default QBOSuccessPage;
