import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';

export const billListModifiedAdapter = (payments: BatchBulkPaymentIntent[]) => {
  const billsById = {};
  const deliveryOptions = {};

  for (const paymentIntentItem of payments) {
    const bills = paymentIntentItem?.payment?.bills || [];

    for (const bill of bills) {
      billsById[bill?.id] = bill;
      deliveryOptions[bill?.id] = paymentIntentItem?.deliveryOptions;
    }
  }

  const bills = {};

  for (const [billId, bill] of Object.entries<any>(billsById)) {
    bills[billId] = {
      bill,
      deliveryOptions: deliveryOptions[billId],
    };
  }

  return bills;
};
