import { useSelector, useDispatch } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useHistory } from 'react-router-dom';
import {
  getBill,
  getIsRecurring,
  getPayment,
  getWizardFlow,
} from 'src/app/redux/payBillWizard/selectors';
import { getFundingSources } from 'src/app/redux/user/selectors';
import { getPartialBillId } from 'src/app/utils/bills';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import { getAddDeliveryMethodUrlMap } from 'src/app/utils/delivery-methods';
import {
  PAY_EDIT_LOCATION_NAME,
  DELIVERY_METHOD_ORIGIN,
  DELIVERY_TYPE,
  CARD_TYPES,
  ADD_FUNDING_SOURCE_WIZARD_ORIGIN,
} from 'src/app/utils/consts';
import { NavigateType, OptionalDeliveryMethodsType } from 'src/app/utils/types';
import { BILLING_FEATURE_WIZARD_FLOW } from 'src/app/pages/settings/components/fee/consts';
import { updateOriginPlaidItemIdAction } from 'src/app/redux/user/actions';
import {
  AddFundingSourceWizardOriginEnum,
  FundingSourceTypesEnum,
} from 'src/app/version-2/model/enums';
import { usePayBillNavigateCommon } from './usePayBillNavigateCommon';
import { PAY_BILL_EVENT_PAGE, EVENT_NAMES } from 'src/app/pages/bill/pay/consts';
import { useFundingSourceNavigate } from './useFundingSourceNavigate';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { EXPERIMENT_NAME } from 'src/app/version-2/model/constants';
import {
  getLpExperimentType,
  getLpExperimentVariantComponentName,
} from 'src/app/version-2/utils/experimental.util';
import {
  getFeatureFlagStatus,
  getFundingSourceVariant,
} from 'src/app/version-2/utils/featureFlags.utils';
import { paymentsSelectors } from 'src/app/version-2/modules/payments/payments.slice';

type Params = {
  navigate: NavigateType;
  getUrlAfterFundingSourceStepForRecoveryFlow: () => Promise<string>;
};

export const useGoAddPage = ({ navigate, getUrlAfterFundingSourceStepForRecoveryFlow }: Params) => {
  const history: any = useHistory();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);
  const fundingSources = useSelector(getFundingSources);
  const isRecurring = useSelector(getIsRecurring);
  const isFeeFundingSourceFlow = useSelector(getWizardFlow) === BILLING_FEATURE_WIZARD_FLOW;
  const isFundingSourceExperiment = getFeatureFlagStatus(
    FeatureFlagsEnum.FUNDING_SOURCE_EXPERIMENT
  );
  const payments = useSelector(paymentsSelectors.selectPayments);
  const fundingSourceVariant = getFundingSourceVariant(
    isFundingSourceExperiment,
    fundingSources,
    !!payments.length
  );
  const dispatch = useDispatch();
  const { getUrl } = usePayBillNavigateCommon();
  const { getUrlAfterFundingSource } = useFundingSourceNavigate({
    navigate,
    getUrlAfterFundingSourceStepForRecoveryFlow,
  });

  const isInternational = payment?.deliveryMethod?.deliveryType === DELIVERY_TYPE.INTERNATIONAL;
  const isBatchBulkFlow =
    history.location.state?.preservedState?.origin === AddFundingSourceWizardOriginEnum.BATCH_BULK;

  const goAddDeliveryMethod = (type: OptionalDeliveryMethodsType) => {
    let redirectUrl;
    let exitUrl;
    let origin: DELIVERY_METHOD_ORIGIN.PAY_BILL | AddFundingSourceWizardOriginEnum.BATCH_BULK =
      DELIVERY_METHOD_ORIGIN.PAY_BILL;

    if (isRecurring) {
      redirectUrl = locations.Bills.pay.recurring.memo.url({ orgId });
      exitUrl = locations.Bills.pay.deliveryMethod.url({ orgId });
    } else {
      redirectUrl = getUrl(PAY_EDIT_LOCATION_NAME.DATE);
      exitUrl = getUrl(PAY_EDIT_LOCATION_NAME.DELIVERY_METHOD);
    }

    const deliveryMethodUrlMap = getAddDeliveryMethodUrlMap(orgId, bill.vendor.id);
    const url = deliveryMethodUrlMap[type || DELIVERY_TYPE.ACH];

    if (isBatchBulkFlow) {
      redirectUrl = history.location.state.redirectUrl;
      origin = AddFundingSourceWizardOriginEnum.BATCH_BULK;
      exitUrl = locations.Bills.pay.deliveryMethod.url({ orgId, id: bill.id });
    }

    navigate(url, false, {
      origin,
      redirectUrl,
      exitUrl,
      fundingSourceId: payment.fundingSourceId,
      billId: bill.id,
      preservedState: {
        ...history?.location?.state?.preservedState,
        newFundingSource: payment.fundingSourceId,
        redirectUrl,
      },
    });
  };

  const getExitUrl = () => {
    if (isFeeFundingSourceFlow) {
      return locations.Onboarding.fundingSources.fee.checks.selectFundingSource.url();
    }

    return isRecurring
      ? locations.Bills.pay.recurring.funding.url({ orgId })
      : getUrl(PAY_EDIT_LOCATION_NAME.FUNDING);
  };

  const goAddSelectedFundingSource = async (selectedFundingSource, ignoreExperiment = false) => {
    const analyticProperty =
      selectedFundingSource === CARD_TYPES.CREDIT ? EVENT_NAMES.ADD_CREDIT : EVENT_NAMES.ADD_DEBIT;
    const experimentProps = {
      billIds: [bill?.id],
      experimentName: EXPERIMENT_NAME,
      experimentVariant: getLpExperimentVariantComponentName(
        isFundingSourceExperiment,
        fundingSourceVariant,
        fundingSources,
        !!payments.length
      ),
    };

    let redirectUrl = await getUrlAfterFundingSource();

    const exitUrl = getExitUrl();

    if (payment) {
      const plaidAccount = payment.fundingSource?.plaidAccount;

      if (plaidAccount) {
        dispatch(updateOriginPlaidItemIdAction(plaidAccount.plaidItemId));
      }
    }

    switch (selectedFundingSource) {
      case FundingSourceTypesEnum.ACH:
        // go back to funding source in case of international with empty purpose
        if (isInternational && !payment?.purpose) {
          redirectUrl = locations.Bills.pay.funding.url({ id: bill.id });
        }

        analytics.track(PAY_BILL_EVENT_PAGE, EVENT_NAMES.ADD_BANK, {
          partialBillId: getPartialBillId(bill),
          ...experimentProps,
        });

        navigate(
          locations.Onboarding.fundingSources.bank[
            getLpExperimentType(
              isFundingSourceExperiment && !ignoreExperiment,
              fundingSourceVariant,
              fundingSources,
              !!payments.length
            )
          ].url(),
          false,
          {
            preservedState: {
              origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL,
              exitUrl,
            },
            redirectUrl,
            exitUrl,
            billIds: [bill.id],
            isBatch: false,
          }
        );
        break;
      case CARD_TYPES.CREDIT:
      case CARD_TYPES.DEBIT:
        // go back to funding source in case of international with empty purpose
        if (isInternational && !payment?.purpose) {
          redirectUrl = locations.Bills.pay.funding.url({ id: bill.id });
        }

        analytics.track(PAY_BILL_EVENT_PAGE, analyticProperty, {
          partialBillId: getPartialBillId(bill),
          ...experimentProps,
        });
        navigate(locations.Onboarding.fundingSources.card.index.url(), false, {
          preservedState: {
            origin: ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL,
          },
          redirectUrl,
          exitUrl,
        });
        break;
      default:
        break;
    }
  };

  return {
    goAddDeliveryMethod,
    goAddSelectedFundingSource,
  };
};
