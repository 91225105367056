import React, { useMemo } from 'react';
import { Flex } from '@melio/billpay-design-system';
import { useForm } from 'src/app/ui/form';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';
import { TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH } from '../../consts';
import { EditPaymentFormFooter } from './EditPaymentFormFooter';

import { FormProps } from '../../types';

const EditPaymentEmailForm = ({
  payment,
  labels,
  isMobile,
  validationErrors,
  isLoading,
  onSubmit,
  onClose,
}: FormProps) => {
  const model = useMemo(
    () => ({
      contactEmail: payment.vendor.contactEmail || '',
    }),
    [payment.vendor]
  );

  const [values, { submit }] = useForm(model, { submit: onSubmit });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Flex direction="column" gridGap={8}>
        <Flex gridGap={6} justifyContent="center" alignItems="flex-start">
          <MITextInput
            id="contactEmail"
            value={values.contactEmail.value}
            label={labels.contactEmail}
            type="text"
            autoFocus
            onChange={values.contactEmail.onChange}
            errorMessage={validationErrors.contactEmail}
            autocomplete="off"
            size={TextInputSizeEnum.INLINE}
            required
            autoNoticeHeight
          />
        </Flex>
        <EditPaymentFormFooter
          primaryLabel={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.button.save`}
          secondaryLabel={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.button.cancel`}
          onSubmit={submit}
          onClose={onClose}
          isLoading={isLoading}
          isMobile={isMobile}
        />
      </Flex>
    </form>
  );
};

export { EditPaymentEmailForm };
