import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadFundingSourcesAction } from 'src/app/redux/user/actions';

const useRefreshFundingSources = () => {
  const dispatch = useDispatch();
  const [isFundingSourcesRefreshing, setIsFundingSourcesRefreshing] = useState(false);
  const refreshFundingSources = useCallback(async () => {
    setIsFundingSourcesRefreshing(true);

    return new Promise((resolve, reject) => {
      dispatch(loadFundingSourcesAction(resolve, reject));
    }).finally(() => {
      setIsFundingSourcesRefreshing(false);
    });
  }, [dispatch]);

  return { refreshFundingSources, isFundingSourcesRefreshing };
};

export default useRefreshFundingSources;
