import { Logger } from 'src/app/version-2/api/loggers/Logger';
import { LoggerMessage } from 'src/app/version-2/api/loggers/LoggerMessage';
import { LoggerSessionContext } from 'src/app/version-2/api/loggers/LoggerSessionContext';
import { LoggerWrapper } from 'src/app/version-2/api/loggers/LoggerWrapper';
import { DebugLevelEnum } from 'src/app/version-2/model/enums';

const loggersArray: Logger[] = [];
/**
 * This is the general debug level.
 * It is used to hard-cut logs across all loggers.
 * For example, if the debug level here is WARNING  - and we have 2 loggers,
 * one with debug level of DEBUG and one with debug level of ERROR,
 * then the first will log only WARNING and ERROR, while the second one will log only ERROR
 */
let debugLevel: DebugLevelEnum = DebugLevelEnum.DEBUG;

const shouldLog = (logger: Logger, requestedDebugLevel: DebugLevelEnum) =>
  debugLevel <= requestedDebugLevel && logger.debugLevel <= requestedDebugLevel;

export const loggingApi: LoggerWrapper = {
  init() {
    loggersArray.forEach((logger) => logger.init());
  },
  set debugLevel(value: DebugLevelEnum) {
    debugLevel = value;
  },
  set sessionContext(value: LoggerSessionContext) {
    // eslint-disable-next-line no-return-assign
    loggersArray.forEach((logger) => (logger.sessionContext = value));
  },
  addLogger(logger: Logger) {
    loggersArray.unshift(logger);
  },
  debug(message?: any, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.DEBUG)) {
        return;
      }

      logger.debug(message, ...optionalParams);
    });
  },
  log(message?: LoggerMessage, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.LOG)) {
        return;
      }

      logger.log(message, ...optionalParams);
    });
  },
  info(message?: LoggerMessage, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.INFO)) {
        return;
      }

      logger.info(message, ...optionalParams);
    });
  },
  warn(message?: LoggerMessage, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.WARN)) {
        return;
      }

      logger.warn(message, ...optionalParams);
    });
  },
  error(message?: LoggerMessage, ...optionalParams) {
    loggersArray.forEach((logger) => {
      if (!shouldLog(logger, DebugLevelEnum.ERROR)) {
        return;
      }

      logger.error(message, ...optionalParams);
    });
  },
};
