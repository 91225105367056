import styled from 'styled-components';
import { Menu } from '@melio/billpay-design-system';
import { Portal } from 'src/app/core/ds/portal';

import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  items: { label: string; onClick: (event: Event) => void | Promise<void> }[];
  menuWidth?: string;
};

export const MIButtonDropDown = ({ items, menuWidth }: Props) => {
  const arrowClick = (event) => event.stopPropagation();

  const itemClick = (onClick) => (event) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <Menu placement="top-end">
      <Menu.MenuButton as={ArrowButton} onClick={arrowClick}>
        <div className="arrow" />
      </Menu.MenuButton>

      <Portal>
        <Menu.MenuList minWidth={menuWidth}>
          {items.map((item) => (
            <Menu.MenuItem onClick={itemClick(item.onClick)} key={item.label}>
              <MIFormattedText label={item.label} />
            </Menu.MenuItem>
          ))}
        </Menu.MenuList>
      </Portal>
    </Menu>
  );
};

const ArrowButton = styled.div`
  width: 4rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid var(--dropdown-border-color);

  &:hover {
    cursor: pointer;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    background: var(--dropdown-hover-color);
  }

  .arrow {
    padding: 0.65rem;
    box-shadow: 4px 4px 0 -2px var(--dropdown-arrow-color);
    position: absolute;
    top: 50%;
    right: 14px;
    transform: rotate(45deg);
    margin-top: -14px;
  }
`;
