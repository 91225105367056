import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';

export const Footer = () => (
  <StyledFooter>
    <MIFormattedText label="international.paymentDetails.footer" />
  </StyledFooter>
);

const StyledFooter = styled.div`
  text-align: center;
  line-height: 1.6rem;
  font-size: ${(props) => props.theme.text.size.small};
  color: ${(props) => props.theme.text.color.main};

  @media ${devices.mobile}, ${devices.phablet} {
    margin: 0.8rem 0;
  }
`;
