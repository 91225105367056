import React from 'react';
import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import { useSiteContext } from 'src/app/hoc/withSiteContext';

type EditElementProps = {
  onEdit?: (() => void) | null;
};

export const EditElement = ({ onEdit }: EditElementProps) => {
  const site = useSiteContext();

  if (!onEdit) {
    return null;
  }

  return site.theme?.components?.PaymentActivity?.showEditIcon ? (
    <EditLink onClick={onEdit} />
  ) : (
    <StyledMIInlineLink label="bills.form.paymentActivity.editLink" onClick={onEdit} />
  );
};

const EditLink = styled.i.attrs({ className: 'icon-edit-icon' })`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.ds.gray[400]};
  margin-right: 0.4rem;
  cursor: pointer;
  ${(props) => props.theme?.components?.PaymentActivity?.EditLink}
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  margin: 0 2rem 0 1rem;
  ${(props) => props.theme?.components?.PaymentActivity?.StyledMIInlineLink}
`;

export const PaymentIcon = styled.i<{ isBillPaid?: boolean }>`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  > img {
    height: 2.4rem;
  }

  > i {
    font-size: 2.4rem;
    color: ${(props) =>
      props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentIcon}
`;

export const PaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};

  ${(props) => props.theme?.components?.PaymentActivity?.PaymentActivityContainer}
`;

export const PaymentActivityMainContainer = styled.div`
  display: flex;
  padding-right: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentActivityMainContainer}
`;

export const PaymentInfoContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoContainer}
`;

export const PaymentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentTextContainer}
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobile} {
    flex-direction: column;
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentMethodContainer}
`;

export const PaymentMethodText = styled.div<{ isRefundMethodHeader?: boolean }>`
  display: flex;
  color: ${(props) =>
    props.isRefundMethodHeader ? props.theme.colors.ds.gray[200] : props.theme.text.color.label};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  line-height: 2rem;
  letter-spacing: inherit;
`;

export const PaymentInfoText = styled.div<{
  isBillPaid?: boolean;
  isQBCash?: boolean;
}>`
  color: ${(props) =>
    props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main};
  ${(props) =>
    props.isQBCash &&
    `
    color: ${props.theme.text.color.green};
  `}
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoText}
`;

export const PaymentInfoHint = styled.div<{
  hasError?: boolean;
}>`
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) =>
    props.hasError &&
    `
      color: ${props.theme.colors.failure.opaque};
      font-weight: ${props.theme.text.weight.semiBold};
    `}
  margin-left: 4.5rem;
  margin-right: 2rem;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoHint}
`;

export const MainPaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const BlockTitleContainer = styled.h5`
  color: ${(props) => props.theme.text.color.darkGrey};
  margin-bottom: -1rem;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.normal};
  ${(props) => props.theme.text.fontType.medium};
`;
