import React from 'react';
import { AreaLoader } from '@melio/billpay-design-system';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import styled from 'styled-components';
import { melioClose } from 'src/app/utils/external-events';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';
import {
  useFundingSourceMicroDepositState,
  FundingSourceMicroDepositProps,
} from '../settings/hooks/useFundingSourceMicroDeposits';
import { RESOLUTIONS as SUPPORT } from '../settings/hooks/microDepositsCommon';
import { MITextInput } from '../../components/common/MITextInput';
import MINotices from '../../components/common/MINotices';
import { AccountNotFoundPage } from '../general/components/QBOAccountNotFound';
import { getMicrodepositMask } from '../../utils/currency-utils';

const eventPage = 'micro-deposits-verification-page';

export default function MicroDepositsVerifyPage(props: FundingSourceMicroDepositProps) {
  const [state, actions]: any = useFundingSourceMicroDepositState(eventPage, props);
  const site = useSiteContext();
  const { WizardElements, StepLayout } = site.components;
  const { onSubmit, onChange } = actions;

  const goToQboHomepageOverride = () => {
    melioClose();
  };

  if (state.resolution === SUPPORT.NOT_FOUND) {
    return (
      <AccountNotFoundPage
        goToQboHomepageOverride={props.isEmbedded ? goToQboHomepageOverride : undefined}
      />
    );
  }

  if (state.isLoading) {
    return <AreaLoader />;
  }

  return (
    <StepLayout
      title="fundingSources.microDeposits.title"
      subtitle="settings.microDeposits.verifyDialog.subtitle"
      onNext={onSubmit}
      nextLabel="settings.microDeposits.verifyDialog.confirm"
      isLoading={state.isLoading}
      fullWidthCTA
      hideHeader
      subTitleValues={{ companyName: state.companyName }}
    >
      <WizardElements.WizardFormRow>
        <TextInputContainer>
          <MITextInput
            id="amount1"
            label="settings.microDeposits.verifyDialog.sum1Label"
            placeholder="settings.microDeposits.verifyDialog.placeholder"
            value={state.amount1}
            autoFocus
            required
            onChange={onChange}
            errorMessage={state.validationErrors.amount1}
            size={TextInputSizeEnum.INLINE}
            mask={getMicrodepositMask(true)}
          />
        </TextInputContainer>
        <TextInputContainer>
          <MITextInput
            id="amount2"
            label="settings.microDeposits.verifyDialog.sum2Label"
            placeholder="settings.microDeposits.verifyDialog.placeholder"
            value={state.amount2}
            required
            onChange={onChange}
            errorMessage={state.validationErrors.amount2}
            size={TextInputSizeEnum.INLINE}
            mask={getMicrodepositMask(true)}
          />
        </TextInputContainer>
      </WizardElements.WizardFormRow>
      <MicroDepositNotice>
        {state.serverError && (
          <MINotices size="wizard" errorMessage={state.serverError} align="center" />
        )}
      </MicroDepositNotice>
    </StepLayout>
  );
}

const TextInputContainer = styled.div`
  width: 100%;

  &:first-child {
    margin-right: 2rem;
  }
`;

// eslint-disable-next-line max-len
// TODO: remove this component when fixing the WizardElements.WizardStepActionsContainer to not margin top, but to margin botton
const MicroDepositNotice = styled.div`
  ${(props) => props.theme?.components?.MicroDepositNotice}
`;
