import React, { useState } from 'react';
import { Flex } from '@melio/billpay-design-system';

import { ISSUER_ADDRESS, ISSUER_ZIP_CODE } from 'src/app/utils/consts';
import { getCreditCardNumberMask } from 'src/app/utils/formatting';
import { CardFieldsType } from './types';
import { CardField } from './CardField';

interface CardFieldsProps {
  cardDetails: { number?: string; cvc?: string; exp?: string };
  onFieldCopy: (fieldName: string, value?: string) => void;
}

const CardFieldsDesktop = ({ cardDetails, onFieldCopy }: CardFieldsProps) => {
  const [suffixStateByField, setSuffixStateByField] = useState<CardFieldsType>({
    cardNumber: false,
    cvc: false,
    expiryDate: false,
    issuerAddress: false,
    zipCode: false,
  });

  const maskedCardNumber = getCreditCardNumberMask(cardDetails?.number);

  return (
    <Flex gridGap={2}>
      <Flex direction="column" gridGap="0.3rem" width="full">
        <Flex gridGap={2}>
          <CardField
            fieldName="cardNumber"
            label="vendors.virtualCardDetails.valid.labels.cardNumber"
            value={maskedCardNumber}
            suffixStateByField={suffixStateByField}
            onFieldCopy={onFieldCopy}
            setSuffixStateByField={setSuffixStateByField}
            withSuffix
            fullWidth
          />
          <CardField
            fieldName="cvc"
            label="vendors.virtualCardDetails.valid.labels.cvc"
            value={cardDetails?.cvc}
            suffixStateByField={suffixStateByField}
            onFieldCopy={onFieldCopy}
            setSuffixStateByField={setSuffixStateByField}
            withSuffix
          />
        </Flex>
        <CardField
          fieldName="issuerAddress"
          label="vendors.virtualCardDetails.valid.labels.issuerAddress"
          value={ISSUER_ADDRESS}
          suffixStateByField={suffixStateByField}
          onFieldCopy={onFieldCopy}
          setSuffixStateByField={setSuffixStateByField}
          notices={['vendors.virtualCardDetails.valid.issuerAddressHint']}
          withSuffix
          fullWidth
        />
      </Flex>
      <Flex direction="column" gridGap="0.3rem">
        <CardField
          fieldName="expiryDate"
          label="vendors.virtualCardDetails.valid.labels.expiryDate"
          value={cardDetails?.exp}
          suffixStateByField={suffixStateByField}
          onFieldCopy={onFieldCopy}
          setSuffixStateByField={setSuffixStateByField}
          withSuffix
        />
        <CardField
          fieldName="zipCode"
          label="vendors.virtualCardDetails.valid.labels.zipCode"
          value={ISSUER_ZIP_CODE}
          suffixStateByField={suffixStateByField}
          onFieldCopy={onFieldCopy}
          setSuffixStateByField={setSuffixStateByField}
          withSuffix
        />
      </Flex>
    </Flex>
  );
};

const CardFieldsMobile = ({ cardDetails, onFieldCopy }: CardFieldsProps) => {
  const maskedCardNumber = getCreditCardNumberMask(cardDetails?.number);

  return (
    <Flex direction="column" gridGap={2}>
      <CardField
        fieldName="cardNumber"
        label="vendors.virtualCardDetails.valid.labels.cardNumber"
        value={maskedCardNumber}
        onFieldCopy={onFieldCopy}
        withSuffix={false}
        withMargin={false}
        fullWidth
      />
      <Flex gridGap={2}>
        <CardField
          fieldName="expiryDate"
          label="vendors.virtualCardDetails.valid.labels.expiryDate"
          value={cardDetails?.exp}
          onFieldCopy={onFieldCopy}
          withSuffix={false}
          withMargin={false}
        />
        <CardField
          fieldName="cvc"
          label="vendors.virtualCardDetails.valid.labels.cvc"
          value={cardDetails?.cvc}
          onFieldCopy={onFieldCopy}
          withSuffix={false}
          withMargin={false}
        />
        <CardField
          fieldName="zipCode"
          label="vendors.virtualCardDetails.valid.labels.zipCode"
          value={ISSUER_ZIP_CODE}
          onFieldCopy={onFieldCopy}
          withSuffix={false}
          withMargin={false}
        />
      </Flex>
      <CardField
        fieldName="issuerAddress"
        label="vendors.virtualCardDetails.valid.labels.issuerAddress"
        value={ISSUER_ADDRESS}
        onFieldCopy={onFieldCopy}
        notices={['vendors.virtualCardDetails.valid.issuerAddressHint']}
        withSuffix={false}
        withMargin={false}
        fullWidth
      />
    </Flex>
  );
};

export { CardFieldsDesktop, CardFieldsMobile };
