import { ReactNode } from 'react';
import { CSSObject } from '@melio/billpay-design-system';
import { Box, Flex, theme } from '@melio/billpay-design-system';

import { TIMELINE_STEP_STATUSES, VENDOR_PAYMENT_DETAILS_COLORS } from '../../consts';

const { SUCCEED, FAILED, FUTURE_STEP } = TIMELINE_STEP_STATUSES;
const { SUCCEED_TEXT_COLOR, SUCCEED_STATUS_COLOR, FAILED_STATUS_COLOR, FUTURE_STATUS_COLOR } =
  VENDOR_PAYMENT_DETAILS_COLORS;

const TIMELINE_STEP_PARTS_GAP = '2rem';
const TIMELINE_STEP_STATUS_SIZE = '2rem';
const TIMELINE_STEP_LINE_HEIGHT = '100%';

const VendorPaymentTimelineStepContainer = ({
  status,
  children,
  ...rest
}: {
  status?: TIMELINE_STEP_STATUSES;
  children: ReactNode[];
}) => {
  const containerStyle: CSSObject = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    gridGap: TIMELINE_STEP_PARTS_GAP,
    alignItems: 'start',
    textStyle: 'body3',
    color: status === SUCCEED ? SUCCEED_TEXT_COLOR : 'black',
    width: 'full',
    paddingBottom: 10,

    ':last-child': {
      paddingBottom: 0,
    },
  };

  return (
    <Flex __css={containerStyle} {...rest}>
      {children}
    </Flex>
  );
};

const VendorPaymentTimelineStepContent = ({
  children,
  ...rest
}: {
  children: ReactNode[];
  [attr: string]: any;
}) => (
  <Flex
    direction="column"
    width={`calc(100% - ${TIMELINE_STEP_STATUS_SIZE} - ${TIMELINE_STEP_PARTS_GAP})`}
    gridGap={1}
    wordBreak="break-word"
    whiteSpace="pre-wrap"
    {...rest}
  >
    {children}
  </Flex>
);

const StepLine = ({
  absolutePosition,
  minHeight,
  borderColor,
}: {
  absolutePosition?: boolean;
  minHeight: string;
  borderColor: string;
}) => (
  <Box
    position={absolutePosition ? 'absolute' : 'relative'}
    minHeight={minHeight}
    borderStyle="solid"
    borderColor={borderColor}
    borderTopWidth={0}
    borderBottomWidth={0}
    borderRightWidth={1}
    borderLeftWidth={1}
  />
);

const PastStatusMark = ({ status }: { status?: typeof FAILED | typeof SUCCEED }) => {
  const styleByStatus = {
    [SUCCEED]: { backgroundColor: SUCCEED_STATUS_COLOR, iconClass: 'icon-checkmark-icon' },
    [FAILED]: { backgroundColor: FAILED_STATUS_COLOR, iconClass: 'icon-close-icon' },
  };

  return (
    <Flex
      width={TIMELINE_STEP_STATUS_SIZE}
      minHeight={TIMELINE_STEP_STATUS_SIZE}
      borderRadius="full"
      backgroundColor={status && styleByStatus[status].backgroundColor}
      color="white"
      justifyContent="center"
      alignItems="center"
      zIndex={1}
    >
      <Box as="i" textStyle="ds.body1Semi" className={status && styleByStatus[status].iconClass} />
    </Flex>
  );
};

const FutureStatusMark = () => (
  <Flex
    width={TIMELINE_STEP_STATUS_SIZE}
    borderRadius="full"
    justifyContent="center"
    alignItems="center"
    zIndex={1}
  >
    <Flex direction="column" alignItems="center">
      <StepLine minHeight="0.4rem" borderColor={FUTURE_STATUS_COLOR} />
      <Box width={2} height={2} borderRadius="full" backgroundColor={FUTURE_STATUS_COLOR} />
    </Flex>
  </Flex>
);

const Status = ({ status }: { status?: TIMELINE_STEP_STATUSES }) =>
  status === FUTURE_STEP ? <FutureStatusMark /> : <PastStatusMark status={status} />;

const FailedNextStepStatusLine = () => {
  const failedDashedLineStyle = {
    position: 'absolute',
    minHeight: TIMELINE_STEP_LINE_HEIGHT,
    width: 0.5,
    backgroundImage: `linear-gradient(to bottom, ${theme.colors.ds.gray[500]} 48%, rgba(255, 255, 255, 0) 20%)`,
    backgroundPosition: 'left',
    backgroundSize: '0.2rem 0.8rem',
    backgroundRepeat: 'repeat-y',
  };

  return <Box __css={failedDashedLineStyle} />;
};

const NextStepStatusLine = ({ nextStepStatus }: { nextStepStatus?: TIMELINE_STEP_STATUSES }) => {
  if (!nextStepStatus) return <></>;

  if (nextStepStatus === FAILED) return <FailedNextStepStatusLine />;

  const borderByStatus = {
    [SUCCEED]: { color: SUCCEED_STATUS_COLOR },
    [FUTURE_STEP]: { color: FUTURE_STATUS_COLOR },
  };

  return (
    <StepLine
      absolutePosition
      minHeight={TIMELINE_STEP_LINE_HEIGHT}
      borderColor={borderByStatus[nextStepStatus].color}
    />
  );
};

const VendorPaymentTimelineStepStatus = ({
  status,
  nextStepStatus,
  ...rest
}: {
  status?: TIMELINE_STEP_STATUSES;
  nextStepStatus?: TIMELINE_STEP_STATUSES;
}) => (
  <Box display="flex" flexDirection="column" alignItems="center" minHeight="full" {...rest}>
    <Status status={status} />
    <NextStepStatusLine nextStepStatus={nextStepStatus} />
  </Box>
);

export {
  VendorPaymentTimelineStepContainer,
  VendorPaymentTimelineStepStatus,
  VendorPaymentTimelineStepContent,
};
