import React from 'react';
import { RecordOf } from 'immutable';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BillType, PaymentType } from 'src/app/utils/types';
import { FundingSource } from 'src/app/version-2/model/dtos';
import ViewBillPaymentActivityFundingSourceInfo from '../../ViewBillPaymentActivity/components/ViewBillPaymentActivityFundingSourceInfo';
import { RefundPaymentActivity } from './RefundPaymentActivity/RefundPaymentActivity';
import { MainPaymentActivityContainer, Title } from './FailedRefundPaymentActivity.styles';

type FailedRefundPaymentActivityProps = {
  bill: RecordOf<BillType>;
  payment: PaymentType;
  fundingSource: FundingSource;
  isPartialPayment: boolean;
  refundRequestedDate?: string;
  refundDueDate?: string | null;
};

export const FailedRefundPaymentActivity = ({
  bill,
  payment,
  fundingSource,
  isPartialPayment,
  refundRequestedDate,
  refundDueDate,
}: FailedRefundPaymentActivityProps) => (
  <>
    <Title data-testid="review-refund_activity-title">
      <MIFormattedText
        label={`bills.pay.requestRefund.review.to${
          payment?.metadata?.paymentStatusDates?.refundCompleted ? 'Completed' : ''
        }`}
      />
    </Title>
    <MainPaymentActivityContainer data-testid="review-refund_activities-container">
      <ViewBillPaymentActivityFundingSourceInfo
        isRefundMethodHeader
        fundingSource={fundingSource}
        bill={bill}
      />
      <RefundPaymentActivity
        bill={bill}
        payment={payment}
        refundRequestedDate={refundRequestedDate}
        refundDueDate={refundDueDate}
        isPartialPayment={isPartialPayment}
      />
    </MainPaymentActivityContainer>
  </>
);
