export const GLOBAL_OPT_IN_PAGE = 'global-opt-in-suvc';
export const PERSONAL_DETAILS_PAGE_EVENT_NAME = 'personal-details';
export const SUCCESS_PAGE_EVENT_NAME = 'success';
export const ALREADY_FILLED_PAGE_EVENT_NAME = 'already-filled';
export const APPLICATION_RECEIVED_EVENT_NAME = 'appliation_received';
export const PERSONAL_DETAILS_FORM_ERROR = 'personal-details-error';
export const BUSINESS_DETAILS_PAGE_EVENT_NAME = 'business-details';
export const BUSINESS_DETAILS_FORM_ERROR = 'business-details--error';
export const PERSONAL_DETAILS_CTA_CLICK = 'personal-details-continue-click';
export const PERSONAL_DETAILS_FORM_SUCCESS = 'personal_details_continue_success';
export const BUSINESS_DETAILS_CTA_CLICK = 'submit-form-click';
export const GLOBAL_OPT_IN_SUCCESS_SUBMIT = 'submit_form_success';
export const GLOBAL_OPT_IN_ALREADY_FAILED = 'already-signed-up-page-loaded';
export const GLOBAL_OPT_IN_INVALID_TOKEN = 'invalid-token-page-loaded';
export const GLOBAL_OPT_IN_FORM_SUBMIT_ERROR = 'submit-error';
