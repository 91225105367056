import React, { useEffect } from 'react';

import GeneralFlowErrorContainer from 'src/app/pages/quickbooks/GeneralFlowErrorContainer';
import Icon from 'src/app/images/icons/gray-timer.svg';
import analytics from 'src/app/services/analytics';

const CheckOfferExpired = () => {
  useEffect(() => {
    analytics.page('push-to-debit', 'expired-offer', { OriginDeliveryType: 'check' });
  }, []);

  return (
    <GeneralFlowErrorContainer
      illustration={Icon}
      title="vendors.deliveryMethods.shiftToDebit.errors.expired.title"
      subtitle="vendors.deliveryMethods.shiftToDebit.errors.expired.subtitle"
    />
  );
};

export default CheckOfferExpired;
