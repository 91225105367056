import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { TabSettingsType, ToNavigationType } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import analytics from 'src/app/services/analytics';

/**
 * Tab - in use only with Tabs component
 */
class Tab extends React.PureComponent<TabSettingsType> {
  handleClick = () => {
    const { name, page, properties } = this.props.trackEvent;

    analytics.track(`${name}-list`, page, properties);
  };

  isActive = () => this.props.active;

  render() {
    const { label, to, unreadBadge } = this.props;

    return (
      <StyledTab
        activeClassName="active"
        isActive={this.isActive}
        role="tab"
        to={to}
        onClick={() => this.handleClick()}
        unreadBadge={unreadBadge || false}
        data-testid={label}
      >
        <MIFormattedText label={label} />
        <Underline isActive={this.props.active} />
      </StyledTab>
    );
  }
}

export { Tab };

/**
 * Tabs
 */
type Props = {
  tabs: TabSettingsType[];
};

// eslint-disable-next-line react/no-multi-comp
const Tabs = ({ tabs }: Props) => (
  <TabsWrapper>
    <div role="tablist">
      {tabs.map((tab, idx) => (
        <Tab key={idx} {...tab} />
      ))}
    </div>
  </TabsWrapper>
);

export default Tabs;

/**
 * Styles
 */
const TabsWrapper = styled.div`
  width: 100%;
  margin-bottom: 3.2rem;
  font-size: 1.6rem;

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: ${(props) => props.theme.colors.white.opaque};
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    font-size: 1.6rem;
    margin-top: 4rem;
    position: fixed;
    z-index: ${(props) => props.theme.zIndex.mobileTabs};
    box-sizing: border-box;
    height: ${(props) => props.theme.sizes.mobileHeaderTabsHeight};
    left: 0;
    padding-left: 2rem;
    top: 2.3rem;
    display: flex;
    align-items: flex-end;
  }

  @media ${devices.tablet} {
    font-size: 1.8rem;
    margin-top: 1.4rem;
  }

  @media ${devices.desktop} {
    font-size: 1.8rem;
    margin-top: 1.4rem;
  }
  ${(props) => props.theme?.components?.Tabs?.TabsWrapper}
`;

const StyledTab = styled(
  ({
    unreadBadge,
    ...rest
  }: {
    activeClassName?: string;
    isActive?: () => any;
    to: ToNavigationType;
    onClick?: () => void;
    unreadBadge?: boolean;
  }) => <NavLink {...rest} />
)`
  position: relative;
  line-height: 2.7rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.label};
  margin-right: 2rem;
  user-select: none;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  text-decoration: none;

  &.active {
    color: ${(props) => props.theme.text.color.main};
  }

  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }

  &:focus {
    outline: none;
  }
  ${(props) => props.theme?.components?.Tabs?.StyledTab}

  ${(props) =>
    props.unreadBadge
      ? `
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      right: -1rem;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      background-color: ${props.theme.colors.failure.opaque};
    }
  `
      : ''}
`;

const Underline = styled.div<{ isActive?: boolean }>`
  display: none;
  width: 100%;
  height: 2px;
  background: ${(props) => props.theme.text.color.main};
  ${(props) =>
    props.isActive &&
    css`
      display: block;
    `}

  ${(props) => props.theme?.components?.Tabs?.Underline}
`;
