export const enum globalOptInFormFieldNamesEnum {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  businessName = 'businessName',
  address = 'address',
  googlePlaceId = 'googlePlaceId',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  aptNumber = 'aptNumber',
  state = 'state',
  city = 'city',
  zipCode = 'zipCode',
  countryCode = 'countryCode',
  fcbStatus = 'fcbStatus',
  taxId = 'taxId',
  taxIdType = 'taxIdType',
  routingNumber = 'routingNumber',
  accountNumber = 'accountNumber',
  inputValue = 'inputValue',
  formattedAddress = 'formattedAddress',
}
