import React, { useCallback } from 'react';
import { Box } from '@melio/billpay-design-system';
import analytics from 'src/app/services/analytics';
import { MIFormattedText } from 'src/app/utils/formatting';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { UseModalOptions } from 'src/app/helpers/react/useModal';
import intercomService from 'src/app/services/intercom';
import { RefundRiskDecision } from 'src/app/utils/types';

type ViewBillBlockRefundModalProps = {
  paymentId: string;
  billId: string;
  riskBlockReason: RefundRiskDecision;
} & UseModalOptions;

export const ViewBillBlockRefundModal: React.FC<ViewBillBlockRefundModalProps> = ({
  paymentId,
  billId,
  riskBlockReason,
  dismiss,
}) => {
  const openChat = useCallback(() => {
    analytics.trackAction('chat-with-support', { paymentId, billId, riskBlockReason });
    dismiss();
    intercomService.show();
  }, [paymentId, billId]);

  return (
    <ModalMessage
      id="refund-block"
      titleComponent={
        <Box textAlign="left">
          <MIFormattedText label="bills.pay.requestRefund.modalMessage.title" />
        </Box>
      }
      contentComponent={<MIFormattedText label="bills.pay.requestRefund.modalMessage.subtitle" />}
      buttonLabel="bills.pay.requestRefund.modalMessage.buttonLabel"
      onButtonClick={openChat}
      onCloseClick={dismiss}
      fullPrimaryButtonWidth={false}
    />
  );
};
