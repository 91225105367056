export const QBO_FONT_WEIGHT = {
  BOLD: 'bold',
  DEMI: 600,
  MEDIUM: 500,
  REGULAR: 'normal',
};

const textStyles = {
  h2: {
    fontSize: '4rem',
    fontWeight: QBO_FONT_WEIGHT.BOLD,
    lineHeight: '5.2rem',
  },
  h3: {
    fontSize: '3.4rem',
    fontWeight: QBO_FONT_WEIGHT.BOLD,
    lineHeight: '4.4rem',
  },
  h4: {
    fontSize: '2.8rem',
    lineHeight: '3.6rem',
  },
  h5: {
    fontSize: '2.4rem',
    lineHeight: '3.2rem',
  },
  h6: {
    fontSize: '2rem',
    lineHeight: '2.8rem',
  },
  h7: {
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
  },
  h8: {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  },
  'h8-demi': {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: QBO_FONT_WEIGHT.DEMI,
  },
  body1: {
    fontSize: '2rem',
    lineHeight: '2.8rem',
  },
  body2: {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
  },
  body3: {
    fontSize: '1.4rem',
    lineHeight: '2rem',
  },
  body4: {
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
  },
  body5: {
    fontSize: '1rem',
    lineHeight: '1.2rem',
  },
  badge: {
    fontSize: '1.2rem',
    fontWeight: QBO_FONT_WEIGHT.DEMI,
    lineHeight: '1.4rem',
    textTransform: 'uppercase',
  },
};

export type QBOTextStyles = typeof textStyles;

export default textStyles;
