import api from 'src/app/services/api/userManagement';
import {
  createApiCallSlice,
  ON_FAILURE,
  ON_REQUEST,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import { UserOrganization } from 'src/app/utils/types';

type PayloadType = {
  orgId: number;
  targetOrgId: number;
  items: UserOrganization[];
};
type ResultType = UserOrganization[];

export const addUsersSlice = createApiCallSlice<PayloadType, any, ResultType>({
  name: '[USERS] ADD_USERS',
  api: api.addUsers,
  initialState: {
    addUsers: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.addUsers.loading = true;
      state.addUsers.error = undefined;
    },
    [ON_SUCCESS]: (state, { payload: userOrgs }) => {
      state.addUsers.loading = false;
      state.addUsers.error = undefined;

      if (userOrgs.length) {
        userOrgs.forEach((userOrg) => {
          const stateUser = state.byId[userOrg?.userId || 0];

          if (stateUser) {
            stateUser.userOrganizations = [
              ...(stateUser.userOrganizations || []).filter(
                (uo) => uo.organizationId !== userOrg.organizationId
              ),
              userOrg,
            ];
          }
        });
      }
    },
    [ON_FAILURE]: (state, action) => {
      state.addUsers.loading = false;
      state.addUsers.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state.users.addUsers.loading,
  },
});
