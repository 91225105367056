import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteComponentProps, RouteChildrenProps } from 'react-router-dom';
import { Location } from 'history';
import authStore from 'src/app/modules/auth/auth-store';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import pagesLocations from 'src/app/pages/locations';
import NotFoundPage from 'src/app/pages/general/components/QBONotFoundPage';

function getNotAllowedRoutingAction(site, authType, notAllowedComponent) {
  if (notAllowedComponent) {
    return notAllowedComponent;
  }

  if (authType === 'emailNotVerified') {
    return <Redirect to={pagesLocations.codeVerification} />;
  }

  return <NotFoundPage />;
}

export type GuardedRouteType = {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  render?: (router: RouteComponentProps<any>) => React.ReactNode;
  children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  strict?: boolean;
  location?: Location;
  sensitive?: boolean;
  allowFor?: string | string[];
  notAllowedComponent?: React.ReactElement<any>;
  allowCallbackFn?: () => boolean;
};

export const SmartRoute = memo((props: GuardedRouteType) => {
  const site = useSiteContext();
  const authType: string = useSelector(authStore.selectors.userAuthType);
  const { allowFor, notAllowedComponent, allowCallbackFn, ...rest } = props;
  let allowed = true;

  if (allowFor) {
    let allowedResult: string[] = [];

    allowedResult = allowedResult.concat(allowFor);
    allowed = allowedResult.includes(authType);
  }

  if (allowCallbackFn) {
    allowed = allowed && allowCallbackFn();
  }

  return allowed ? (
    <Route {...rest} />
  ) : (
    getNotAllowedRoutingAction(site, authType, notAllowedComponent)
  );
});
