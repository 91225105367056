import React from 'react';
import { RecordOf } from 'immutable';
import { TooltipMobile } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { CONSTS, PAYMENT_STATUS } from 'src/app/utils/consts';
import { BillType, DeliveryMethodType, PaymentType, QBCashStateType } from 'src/app/utils/types';
import {
  MainPaymentActivityContainer,
  BlockTitleContainer,
} from 'src/app/components/layout/PaymentActivityElements';
import { isReturnedCheckPayment, isUndepositedPayment } from 'src/app/utils/payments';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import { FundingSource } from 'src/app/version-2/model/dtos';
import PayBillPaymentActivityFundingSourceInfo from './components/PayBillPaymentActivityFundingSourceInfo';
import PayBillPaymentActivityDeductionDateInfo from './components/PayBillPaymentActivityDeductionDateInfo';
import PayBillPaymentActivityDeliveryMethodInfo from './components/PayBillPaymentActivityDeliveryMethodInfo';
import { PayBillPaymentActivityDeliveryDateInfo } from './components/PayBillPaymentActivityDeliveryDateInfo';
import PayBillPaymentActivityMemoInfo from './components/PayBillPaymentActivityMemoInfo';

export type ActivityActionsType = {
  onEditFundingSource: () => void;
  onEditPartialAmount: () => void;
  onEditDate: () => void;
  onEditNote: () => void;
  onEditDeliveryMethod?: () => void;
};

type Props = {
  bill: RecordOf<BillType>;
  fundingSource: FundingSource;
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  activityActions: ActivityActionsType;
  qbCashState?: QBCashStateType;
};

const PayBillPaymentActivity = ({
  bill,
  activityActions,
  fundingSource,
  payment,
  deliveryMethod,
  qbCashState,
}: Props) => {
  const { onEditFundingSource, onEditDate, onEditDeliveryMethod, onEditNote } = activityActions;
  const isSharedVendor = bill.isVendorRequest();
  const isVirtualDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;
  const isRPPSDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;
  const isVirtualCardDeliveryMethod =
    deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;
  const showMemo = !isRPPSDeliveryMethod;
  const isReturnedCheck = isReturnedCheckPayment(payment);
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);

  const payFromLabel = 'payBillPaymentActivity.payFromTitle';
  const receiveLabel = 'payBillPaymentActivity.receiveTitle';

  return (
    <>
      {!isRetryFailedToDeliver && (
        <>
          <BlockTitleContainer>
            <MIFormattedText label={payFromLabel} />
          </BlockTitleContainer>
          <MainPaymentActivityContainer>
            <PayBillPaymentActivityFundingSourceInfo
              bill={bill}
              payment={payment}
              fundingSource={fundingSource}
              onEdit={onEditFundingSource}
              qbCashState={qbCashState}
            />
            <PayBillPaymentActivityDeductionDateInfo
              bill={bill}
              payment={payment}
              onEdit={onEditDate}
            />
            {isSharedVendor && (
              <PayBillPaymentActivityDeliveryDateInfo
                bill={bill}
                payment={payment}
                deliveryMethod={deliveryMethod}
                fundingSource={fundingSource}
                onEditDeductionDate={onEditDate}
              />
            )}
          </MainPaymentActivityContainer>
        </>
      )}

      {!isSharedVendor && (
        <>
          <BlockTitleContainer>
            <MIFormattedText label={receiveLabel} />
          </BlockTitleContainer>
          <MainPaymentActivityContainer>
            <TooltipMobile
              label={
                <MIFormattedText label="payBillPaymentActivity.deliveryMethod.virtualCardTooltip" />
              }
              isDisabled={!isVirtualCardDeliveryMethod}
            >
              {!isVirtualDeliveryMethod && (
                <PayBillPaymentActivityDeliveryMethodInfo
                  deliveryMethod={deliveryMethod}
                  onEdit={onEditDeliveryMethod}
                  bill={bill}
                  payment={payment}
                />
              )}
            </TooltipMobile>
            <PayBillPaymentActivityDeliveryDateInfo
              bill={bill}
              payment={payment}
              deliveryMethod={deliveryMethod}
              fundingSource={fundingSource}
              onEditDeductionDate={onEditDate}
              onEdit={
                isVirtualDeliveryMethod &&
                (payment?.status !== PAYMENT_STATUS.FAILED || isReturnedCheck)
                  ? onEditDeliveryMethod
                  : null
              }
            />
          </MainPaymentActivityContainer>
        </>
      )}
      {showMemo && (
        <PayBillPaymentActivityMemoInfo
          payment={payment}
          onEdit={onEditNote}
          canEdit={!isReturnedCheckPayment(payment) && !isUndepositedPayment(payment)}
        />
      )}
    </>
  );
};

export default PayBillPaymentActivity;
