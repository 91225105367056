import { featureFlags } from '@melio/shared-web';
import { SUPPORTED_SITE_CONTEXTS_BY_2FA_HEADERS } from 'src/app/version-2/model/constants/twoFaHeaders';
import { SiteNameEnum } from 'src/app/version-2/model/enums/siteName.enum';

import { UAParser } from 'ua-parser-js';

const isMobileSafari = () => {
  const parser = new UAParser(window.navigator.userAgent);
  const { name } = parser.getBrowser();

  return name === 'Mobile Safari';
};

export const isContextSupportedBy2FAHeaders = (siteName: string) =>
  isMobileSafari() || SUPPORTED_SITE_CONTEXTS_BY_2FA_HEADERS.includes(siteName as SiteNameEnum);

export const shouldSetAuthToken = (siteName: string) =>
  siteName === SiteNameEnum.QBO || isContextSupportedBy2FAHeaders(siteName);

export const get2FAContext = (siteName: string) => (isMobileSafari() ? 'mobileSafari' : siteName);

export const shouldUse2FAHeaders = (siteName: string, shouldTrackEvent = true) => {
  const is2FAHeadersEnabled = shouldTrackEvent
    ? featureFlags.defaultClient.getVariant('2FA-headers', false)
    : featureFlags.defaultClient.getVariantNoTrack('2FA-headers', false);
  const isContextSupported = isContextSupportedBy2FAHeaders(siteName);

  return is2FAHeadersEnabled && isContextSupported;
};
