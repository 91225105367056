import { useEffect } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { withNavigator } from 'src/app/hoc';
import { CONSTS } from 'src/app/utils/consts';
import {
  getBill,
  getSelectedFundingSource,
  getPayment,
} from 'src/app/redux/payBillWizard/selectors';
import { selectPaymentDatesAction } from 'src/app/redux/payBillWizard/actions';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';
import { PaymentDeliveryOptions } from 'src/app/components/common/PaymentDeliveryOptions';
import { AreaLoader } from '@melio/billpay-design-system';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import { useDeliveryDateRecoveryHeadings } from 'src/app/pages/bill/pay/hooks/useDeliveryDateRecoveryHeadings';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { withPayBillData, PayBillProps } from './hoc/withPayBillData';

const getFundingSourceType = (fundingSource?: FundingSource): string | undefined =>
  fundingSource?.fundingType === FundingSourceTypesEnum.CARD
    ? fundingSource?.cardAccount?.cardType
    : fundingSource?.fundingType;
const eventPage = 'bill';

const FastPaymentOfferPage = ({
  onNext,
  onPrev,
  goExit,
  deliveryOptionsDates,
  isLoading,
}: PayBillProps) => {
  const site = useSiteContext();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const fundingSourceType = getFundingSourceType(selectedFundingSource);
  const deliveryOptions = deliveryOptionsDates?.deliveryOptions;
  const deliveryPreference = payment?.deliveryPreference;
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();

  const { getTitle, getSubtitle } = useDeliveryDateRecoveryHeadings();

  const isBulkPayment = !!(payment?.bills?.length && payment?.bills?.length > 1);
  const dueDate = isBulkPayment
    ? (payment.bills
        ?.map((bill) => new Date(bill.dueDate).getTime())
        .reduce((acc, curr) => Math.min(acc, curr)) as number)
    : bill.dueDate;

  useEffect(() => {
    if (deliveryOptionsDates) {
      const { deliveryOptions } = deliveryOptionsDates;

      if (deliveryOptions.length <= 1) {
        historyPush({
          path: locations.Bills.pay.edit.confirm.url(),
          params: { id: bill.id, paymentId: payment.id },
        });
      } else if (payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK) {
        analytics.track(
          eventPage,
          deliveryOptions?.length === 2 ? 'express-check-shown' : 'express-fast-shown'
        );
      } else if (payment?.deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.ACH) {
        analytics.track(eventPage, 'fast-ach-shown');
      }
    }
  }, [deliveryOptionsDates, bill.id, payment.id, deliveryPreference, historyPush]);

  const onSelectDeliveryOption = (scheduledDate, deliveryDate, maxDeliveryDate, type) => {
    const properties = {
      fundingSourceType,
      fundingSourceId: selectedFundingSource?.id,
    };

    analytics.track(eventPage, `delivery-selected-${type || 'regular'}`, properties);

    dispatch(selectPaymentDatesAction(scheduledDate, deliveryDate, maxDeliveryDate, type));
  };

  if (isLoading) {
    return <AreaLoader placement="wizard" />;
  }

  const { label: titleLabel } = getTitle({ dueDate, deliveryOptions });
  const { label: subtitleLabel, values: subtitleValues } = getSubtitle({
    isBulkPayment,
    dueDate,
    deliveryOptions,
  });

  return (
    <site.components.StepLayout
      title={titleLabel}
      subtitle={subtitleLabel}
      subTitleValues={subtitleValues}
      goExit={goExit}
      onPrev={onPrev}
      onNext={onNext}
      relativeStep={4 / 5}
    >
      <InfoContainer>
        <InfoItemContainer>
          <InfoItemText>
            <MIFormattedText label="bills.pay.date.deliverySpeed" />
          </InfoItemText>
          <SelectDeliveryDateContainer>
            {deliveryOptions && (
              <PaymentDeliveryOptions
                dueDate={new Date(dueDate)}
                paymentAmount={payment?.amount}
                fundingSourceType={fundingSourceType}
                deliveryOptions={deliveryOptions}
                deliveryPreference={deliveryPreference as string | undefined}
                onSelectDeliveryOption={onSelectDeliveryOption}
              />
            )}
          </SelectDeliveryDateContainer>
        </InfoItemContainer>
      </InfoContainer>
    </site.components.StepLayout>
  );
};

export default compose(withNavigator(), withPayBillData())(FastPaymentOfferPage);

const InfoContainer = styled.div`
  display: flex;
  padding: 2rem;
  background: ${(props) => props.theme.colors.white.opaque};
  border: 1px solid #ededed;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const SelectDeliveryDateContainer = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 1.6rem;
  ${(props) => props.theme.text.fontType.medium};
`;

const InfoItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const InfoItemText = styled.div`
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.colors.light.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;
