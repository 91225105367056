import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AreaLoader } from '@melio/billpay-design-system';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { ReactComponent as CheckIcon } from 'src/app/images/general/qbo-check-circle.svg';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/app/version-2/externals';
import { ReactComponent as QBLogo } from 'src/app/version-2/images/qb-logo.svg';
import { UpsellType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upsellType.enum';
import { upgradePaymentSelectors } from 'src/app/version-2/pages/vendor-upgrade-payment/modules/upgradePayment.slice';
import {
  getVendorUpgradeAnalytics,
  upsellTypeToAnalyticsOptionName,
  vendorUpgradePaymentClientEventNames,
  vendorUpgradePaymentServerEventNames,
} from 'src/app/version-2/pages/vendor-upgrade-payment/utils/analytics.utils';
import { isToday, isTomorrow } from 'src/app/version-2/utils/dates.utils';
import { getAccountNumber4digits } from 'src/app/version-2/utils/deliveryMethod.utils';
import {
  ContentContainer,
  LogoContainer,
  ReviewItemsContainer,
  ReviewTitle,
  Separator,
  StyledQBOLayoutPage,
  Subtitle,
  Title,
} from './SuccessPage.styles';
import { ReviewItem } from '../ReviewItem';

type Props = {
  token: string;
};

const getTexts = ({
  accountNumber4digits,
  deliveryDate,
  paymentAmount,
  companyName,
  upsellType,
}: {
  accountNumber4digits: string;
  deliveryDate: Date;
  paymentAmount: number;
  companyName: string;
  upsellType: UpsellType;
}) => {
  let reviewDeliveryLabel;
  let subtitleLabel;

  const reviewDeliveryValues = {
    deliveryDate: <MIFormattedDate date={deliveryDate} />,
  };
  const subtitleValues = {
    ...reviewDeliveryValues,
    amount: <MIFormattedCurrency value={paymentAmount} />,
    companyName,
    accountNumber4digits,
  };

  if (upsellType === UpsellType.PUSH_TO_RTP) {
    subtitleLabel = 'vendors.upgradePayment.success.subtitle.now';
    reviewDeliveryLabel = 'vendors.upgradePayment.success.review.delivery.subtitle.now';
  } else if (isToday(deliveryDate)) {
    subtitleLabel = 'vendors.upgradePayment.success.subtitle.today';
    reviewDeliveryLabel = 'vendors.upgradePayment.success.review.delivery.subtitle.today';
  } else if (isTomorrow(deliveryDate)) {
    subtitleLabel = 'vendors.upgradePayment.success.subtitle.tomorrow';
    reviewDeliveryLabel = 'vendors.upgradePayment.success.review.delivery.subtitle.tomorrow';
  } else {
    subtitleLabel = 'vendors.upgradePayment.success.subtitle.date';
    reviewDeliveryLabel = 'vendors.upgradePayment.success.review.delivery.subtitle.date';
  }

  return { subtitleLabel, subtitleValues, reviewDeliveryLabel, reviewDeliveryValues };
};

export const SuccessPage = ({ token }: Props) => {
  const location = useLocation<UpsellType>();
  const selectedUpsellType: UpsellType = location.state?.['selectedUpsellType'];

  const payment = useSelector(upgradePaymentSelectors.selectPayment);
  const organization = useSelector(upgradePaymentSelectors.selectOrganization);
  const deliveryMethod = useSelector(upgradePaymentSelectors.selectDeliveryMethod);
  const upsellItems = useSelector(upgradePaymentSelectors.selectUpsellItems);

  const { trackServerAction, trackUIAction } = useMemo(
    () => getVendorUpgradeAnalytics(token, payment),
    [payment, token]
  );

  useEffect(() => {
    if (upsellItems) {
      const selectedOption = upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_FAST_ACH];

      trackUIAction(vendorUpgradePaymentClientEventNames.SUCCESS_PAGE_VIEW, {
        selectedOption,
      });

      trackServerAction(vendorUpgradePaymentServerEventNames.SUCCESS_PAGE_VIEW, {
        selectedOption,
      });
    }
  }, [upsellItems, trackUIAction, trackServerAction]);

  if (!upsellItems || !selectedUpsellType) {
    return <AreaLoader />;
  }

  const selectedUpsellItem = upsellItems.find(({ type }) => type === selectedUpsellType);
  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);
  const deliveryDate =
    selectedUpsellType === UpsellType.PUSH_TO_RTP
      ? new Date()
      : new Date(selectedUpsellItem?.deliveryDate || payment?.deliveryEta);
  const { subtitleLabel, subtitleValues, reviewDeliveryLabel, reviewDeliveryValues } = getTexts({
    accountNumber4digits,
    deliveryDate,
    paymentAmount: payment?.amount || 0,
    companyName: organization?.companyName || '',
    upsellType: selectedUpsellType,
  });

  return (
    <StyledQBOLayoutPage
      hideHeader
      contentWrapperMode="success"
      qboFooter={<QBOFooterContainer hasBorder />}
    >
      <LogoContainer>
        <QBLogo height="3.2rem" width="16rem" />
      </LogoContainer>
      <ContentContainer>
        <CheckIcon />
        <Title>
          <MIFormattedText label="vendors.upgradePayment.success.title" />
        </Title>
        <Subtitle>
          <MIFormattedText label={subtitleLabel} values={subtitleValues} />
        </Subtitle>
        <Separator />
        <ReviewTitle>
          <MIFormattedText label="vendors.upgradePayment.success.review.title" />
        </ReviewTitle>
        <ReviewItemsContainer>
          <ReviewItem
            iconClassName="icon-eta-cal"
            titleLabel="vendors.upgradePayment.success.review.method.title"
            subtitleLabel="vendors.upgradePayment.success.review.method.subtitle"
            subtitleValues={{
              accountNumber4digits,
            }}
          />
          <ReviewItem
            iconClassName="icon-bank-icon"
            titleLabel="vendors.upgradePayment.success.review.delivery.title"
            subtitleLabel={reviewDeliveryLabel}
            subtitleValues={reviewDeliveryValues}
          />
        </ReviewItemsContainer>
      </ContentContainer>
    </StyledQBOLayoutPage>
  );
};
