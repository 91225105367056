import React from 'react';
import { Image } from '@melio/billpay-design-system';

import { DeliveryMethodType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';
import international from 'src/app/images/qbo/international/delivery-icon.svg';

type Props = {
  deliveryMethod: DeliveryMethodType;
};

export const DeliveryMethodIcon = ({ deliveryMethod }: Props) => {
  const isRPPSDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;
  const isCheckDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.CHECK;
  const isVirtualCardDeliveryMethod =
    deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD;
  const isVirtualDeliveryMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL;
  const isInternationalMethod = deliveryMethod.deliveryType === CONSTS.DELIVERY_TYPE.INTERNATIONAL;

  if (isRPPSDeliveryMethod) {
    return <i className="icon-dirctory-confirmed" />;
  }

  if (isCheckDeliveryMethod) {
    return <i className="icon-check-icon" />;
  }

  if (isVirtualCardDeliveryMethod) {
    return <Mastercard width="3.2rem" />;
  }

  if (isInternationalMethod) {
    return <Image w={8} src={international} />;
  }

  if (isVirtualDeliveryMethod) {
    return null;
  }

  return <i className="icon-bank-icon" />;
};
