import React, { useMemo, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';
import { QboBillAttachment } from 'src/app/utils/types';
import { middleTruncation } from 'src/app/utils/string-utils';
import invoicePlaceholder from 'src/app/images/bills/invoice.jpg';
import UploadAttachment from 'src/app/components/common/UploadAttachment';
import BillAttachmentCounter from 'src/app/pages/bill/components/BillAttachmentCounter';
import analytics from 'src/app/services/analytics';
import { AcceptFilesEnum, ScreenModeEnum } from 'src/app/version-2/model/enums';

type Props = {
  fileStorageUrl: string | undefined;
  filePreviewUrls: string[] | undefined;
  mode: ScreenModeEnum;
  qboBillAttachments: QboBillAttachment[] | null;
  onChangeAttachment?: (file: File) => void | null;
  onDeleteAttachment?: () => void | null;
  isDisabled?: boolean;
  withCounter?: boolean;
  eventPage: string;
  className?: string;
};

const BillAttachment = ({
  fileStorageUrl,
  filePreviewUrls,
  mode,
  qboBillAttachments,
  onChangeAttachment,
  onDeleteAttachment,
  isDisabled,
  withCounter,
  eventPage,
  className,
}: Props) => {
  const isEditMode = mode === ScreenModeEnum.EDIT;
  const attachments = useMemo(() => {
    const melioAttachment = filePreviewUrls?.length
      ? [
          {
            url: filePreviewUrls[0],
            name: null,
            type: 'melio',
          },
        ]
      : [];
    const qboAttachments = (qboBillAttachments || []).map((a) => ({
      url: a.url,
      name: a.name,
      type: 'qbo',
    }));

    if (isEditMode) {
      return [...melioAttachment];
    }

    return [...melioAttachment, ...qboAttachments];
  }, [filePreviewUrls, qboBillAttachments, isEditMode]);
  const isMultipleAttachments = attachments.length > 1;
  const uploadLabel = fileStorageUrl
    ? 'bills.form.attachment.replace'
    : 'bills.form.attachment.upload';
  const [currentPage, changeCurrentPage] = useState(1);
  const [isAttachmentLoaded, setIsAttachmentLoaded] = useState(false);
  const currentAttachment = attachments[currentPage - 1];
  const isMelioAttachment = currentAttachment?.type === 'melio' && currentAttachment?.url;
  const isOnlyMelioAttachment = !isMultipleAttachments && isMelioAttachment;
  const showAttachmentPlaceholder =
    isEmpty(attachments) || (isMelioAttachment && !isAttachmentLoaded);

  const onAttachmentClicked = () => {
    analytics.track(eventPage, 'open-attachment');
  };

  const onChangeAttachmentClicked = ({ currentTarget: target }) => {
    if (target.files && target.files.length > 0 && onChangeAttachment) {
      onChangeAttachment(target.files[0]);
      target.value = '';
    }
  };

  const onNextPageClick = () => {
    analytics.track(eventPage, 'view-next-attachment');
    const newPage = currentPage === attachments.length ? 1 : currentPage + 1;

    changeCurrentPage(newPage);
  };

  const onPrevPageClick = () => {
    analytics.track(eventPage, 'view-prev-attachment');
    const newPage = currentPage === 1 ? attachments.length : currentPage - 1;

    changeCurrentPage(newPage);
  };

  const renderPaginationArrows = () => (
    <>
      <PaginationItemNavLinkLeft onClick={onPrevPageClick} isDisabled={isDisabled}>
        <i className="icon-prev-icon-2" />
      </PaginationItemNavLinkLeft>
      <PaginationItemNavLinkRight onClick={onNextPageClick} isDisabled={isDisabled}>
        <i className="icon-next-icon-2" />
      </PaginationItemNavLinkRight>
    </>
  );

  const renderAttachmentsPreview = () => {
    if (isMelioAttachment) {
      return (
        <PreviewLink
          href={fileStorageUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onAttachmentClicked}
        >
          <MelioAttachmentWrapper
            isOnlyMelioAttachment={isOnlyMelioAttachment}
            isVisible={isAttachmentLoaded}
          >
            {withCounter && !isEditMode && <BillAttachmentCounter count={attachments.length} />}
            <Invoice
              onLoad={() => setIsAttachmentLoaded(true)}
              src={currentAttachment?.url}
              alt="invoice"
            />
          </MelioAttachmentWrapper>
        </PreviewLink>
      );
    }

    return (
      <PreviewLink
        href={currentAttachment?.url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onAttachmentClicked}
      >
        <QBOAttachmentWrapper>
          <BillAttachmentCounter count={attachments.length} isQBOAttachment />
          <AttachmentFileName>
            {middleTruncation({
              str: currentAttachment?.name,
              strLength: 23,
              start: 14,
              end: 9,
            })}
          </AttachmentFileName>
        </QBOAttachmentWrapper>
      </PreviewLink>
    );
  };

  useEffect(() => {
    if (isEditMode) {
      changeCurrentPage(1);
    }
  }, [isEditMode]);

  return (
    <Container isEditMode={isEditMode} isNoAttachment={isEmpty(attachments)} className={className}>
      {showAttachmentPlaceholder && (
        <AttachmentPlaceholder isEditMode={isEditMode} src={invoicePlaceholder} />
      )}
      {currentAttachment && (
        <AttachmentWrapper
          isOnlyMelioAttachment={isOnlyMelioAttachment}
          isVisible={!showAttachmentPlaceholder}
          isEditMode={isEditMode}
          isDisabled={isDisabled}
        >
          {renderAttachmentsPreview()}
          {isMultipleAttachments && renderPaginationArrows()}
        </AttachmentWrapper>
      )}
      {isEditMode && (
        <UploadAttachment
          isDisabled={isDisabled}
          uploadLabel={uploadLabel}
          deleteLabel="bills.form.attachment.delete"
          onChangeAttachment={onChangeAttachmentClicked}
          onDeleteAttachment={onDeleteAttachment}
          isShowDeleteButton={!!fileStorageUrl}
          accept={AcceptFilesEnum.BILL_ATTACHMENT}
        />
      )}
    </Container>
  );
};

export default BillAttachment;

const Container = styled.div<{ isEditMode?: boolean; isNoAttachment?: boolean }>`
  width: ${(props) => props.theme.sizes.attachmentLarge};
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.mobile} {
    align-items: flex-start;
    justify-content: ${(props) => (props.isEditMode ? 'space-between' : 'flex-start')};
    margin: ${(props) => (props.isNoAttachment ? 0 : '2rem 0 5rem')};
    width: ${(props) => (props.isEditMode ? '100%' : '40%')};
  }
`;

const attachmentStyles = css`
  max-width: 100%;
  max-height: 22.8rem;
`;

const attachmentWrappersStyles = css<{ isOnlyMelioAttachment?: boolean }>`
  height: ${(props) => (props.isOnlyMelioAttachment ? 'auto' : '22.8rem')};
  border-radius: 0.6rem;
  width: ${(props) => props.theme.sizes.attachmentLarge};
`;

const paginationItemNavLinkStyles = css<{ isDisabled?: boolean }>`
  position: absolute;
  top: 45%;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.white.opaque};
  opacity: ${(props) => (props.isDisabled ? '0.3' : '0.7')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  background-color: ${(props) => props.theme.text.color.light};
  ${(props) => props.theme.text.fontType.medium};

  &:hover {
    background-color: ${(props) => props.theme.text.color.label};
  }

  @media ${devices.mobile} {
    top: initial;
    bottom: -6rem;
    width: 4rem;
    height: 4rem;
    font-size: 2.8rem;
    line-height: 4rem;
  }
`;

const AttachmentPlaceholder = styled.img<{ fileStorageUrl?: string; isEditMode?: boolean }>`
  ${attachmentStyles};
  ${attachmentWrappersStyles};
  background-color: white;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6rem;

  @media ${devices.mobile} {
    display: ${(props) => (props.fileStorageUrl && !props.isEditMode ? 'flex' : 'none')};
    margin: ${(props) => (props.isEditMode ? '2rem 0 1.6rem' : '2rem 0 0')};
  }
`;

const AttachmentFileName = styled.span`
  text-align: center;
  max-width: 11rem;
  word-break: break-all;
  text-transform: none;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
`;

const QBOAttachmentWrapper = styled.div`
  ${attachmentWrappersStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const MelioAttachmentWrapper = styled.div<{ isVisible?: boolean }>`
  ${attachmentWrappersStyles};
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const PreviewLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};
`;

const PaginationItemNavLinkLeft = styled.div`
  ${paginationItemNavLinkStyles};
  left: 1rem;

  @media ${devices.mobile} {
    left: 2rem;
  }
`;

const PaginationItemNavLinkRight = styled.div`
  ${paginationItemNavLinkStyles};
  right: 1rem;

  @media ${devices.mobile} {
    right: 2rem;
  }
`;

const AttachmentWrapper = styled.div<{
  isEditMode?: boolean;
  isDisabled?: boolean;
  isVisible?: boolean;
}>`
  ${attachmentWrappersStyles};
  position: relative;
  opacity: ${(props) => (props.isDisabled ? '0.3' : '1')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  border: 0.1rem solid ${(props) => props.theme.text.color.readonly};
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6rem;

  &:hover {
    box-shadow: ${(props) => `0 0.5rem 1.5rem ${props.theme.colors.dark.translucent2}`};
  }

  &:hover ${AttachmentFileName} {
    color: ${(props) => props.theme.colors.brand};
  }

  &:hover .counter-icon-container {
    background-color: ${(props) => props.theme.text.color.label};
  }

  @media ${devices.mobile} {
    margin-bottom: ${(props) => (props.isEditMode ? '0' : '1.6rem')};
    margin: ${(props) => (props.isEditMode ? '0 0 2rem' : '0')};
    box-shadow: none;
  }
`;

const Invoice = styled.img`
  ${attachmentStyles};
  border-radius: 0.6rem;
`;
