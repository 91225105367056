import { useEffect, useMemo } from 'react';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourceType, FundingSourceOrigins } from 'src/app/utils/types';
import head from 'lodash/head';
import fundingSourcesStore from '../funding-sources-store';

const useSyncQBCashFundingSource = (): { fundingSource?: FundingSource } => {
  const fundingSourcesActions = useStoreActions(fundingSourcesStore);
  const orgId = useSelector(getOrgId);
  const fundingSources: FundingSourceType[] = useSelector(fundingSourcesStore.selectors.all);
  const qbCashFundingSource = useMemo(
    () => fundingSources.filter((fs) => fs.origin === FundingSourceOrigins.qbcash),
    [fundingSources]
  );
  const syncQBCashFundingSource = async () => {
    await fundingSourcesActions.syncQBCash({ orgId });
  };

  useEffect(() => {
    syncQBCashFundingSource();
  }, [orgId]);

  return { fundingSource: head(qbCashFundingSource) as any };
};

export default useSyncQBCashFundingSource;
