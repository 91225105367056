import React, { ReactElement, ReactNode, RefObject } from 'react';
import { NavigateType } from 'src/app/utils/types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import QBOFab from 'src/app/components/layout/QBOFab';
import { withNavigator, useBreak } from '../../hoc';
import LoadingOverlay from './LoadingOverlay';
import { MIFormattedText } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import { shouldShowSinglePageView } from '../../utils/query-utils';
import { showSearchBar } from '../../utils/search-bar-utils';
import ListSearchBar from '../common/ListSearchBar';

/**
 * ListPage - in use only with ListLayoutPage
 */
type ListPageProps = {
  title?: string;
  listViewRef?: RefObject<any>;
  children: ReactNode;
  eventPage?: string;
  device: {
    isDesktop: boolean;
  };
  query: {
    id?: string;
  };
  location: {
    pathname: string;
  };
  navigate: NavigateType;
};

const ListPageComp = ({
  title,
  children,
  query,
  listViewRef,
  location,
  eventPage = 'list-layout-page',
  navigate,
}: ListPageProps) => {
  const { isDesktop } = useBreak();
  const orgId = useSelector(getOrgId) as string;

  return (
    <ListContainer
      path={location.pathname}
      orgId={orgId}
      ref={listViewRef}
      className={shouldShowSinglePageView(query) ? 'single' : undefined}
    >
      <ListContent>
        {showSearchBar(location.pathname, orgId) && isDesktop && (
          <SearchBarContainer>
            <ListSearchBar eventPage={eventPage} />
          </SearchBarContainer>
        )}
        {title && isDesktop && (
          <Title>
            <MIFormattedText label={title} />
          </Title>
        )}
        {children}
      </ListContent>
      <QBOFab testId="create-new-bill" navigate={navigate} />
    </ListContainer>
  );
};

/**
 * ViewSinglePage - in use only with ListLayoutPage
 */
type ViewSinglePageProps = {
  children: React.ReactNode;
  query: {
    id?: string;
    singlePageView?: string;
  };
};

const ViewSinglePageComp = ({ children, query }: ViewSinglePageProps) => {
  const shouldShowSinglePage = shouldShowSinglePageView(query) ? 'single' : undefined;

  return (
    <ProjectionContainer className={shouldShowSinglePage}>
      <ProjectionScrollable data-testid="projection-scrollable">
        <ProjectionContent>{children}</ProjectionContent>
      </ProjectionScrollable>
    </ProjectionContainer>
  );
};

ViewSinglePageComp.defaultProps = {};

const ListPage = compose(withNavigator())(ListPageComp);
const ViewSinglePage = compose(withNavigator())(ViewSinglePageComp);

export { ListPage, ViewSinglePage };

/**
 * ListLayoutPage - main
 */
type Props = {
  title: string;
  isLoading?: boolean;
  children?: React.ReactNode;
  eventPage?: string;
  listViewRef?: RefObject<any>;
};

const ListLayoutPage = ({ title, isLoading, children, listViewRef, eventPage }: Props) => {
  let listPage = <></>;
  let viewPage = <></>;

  React.Children.forEach(children, (child) => {
    if (child) {
      const c: ReactElement<typeof ListPage | typeof ViewSinglePage> = child as any;

      if (c.type === ListPage) {
        listPage = (
          <ListPage listViewRef={listViewRef} {...c.props} eventPage={eventPage} title={title} />
        );
      } else if (c.type === ViewSinglePage) {
        viewPage = <ViewSinglePage {...c.props} />;
      }
    }
  });

  return (
    <LoadingOverlay isLoading={!!isLoading}>
      <Container>
        {listPage}
        {viewPage}
      </Container>
    </LoadingOverlay>
  );
};

ListLayoutPage.defaultProps = {
  isLoading: false,
  children: null,
  listViewRef: null,
};

export default ListLayoutPage;

const Container = styled.div`
  background-color: rgba(250, 250, 250, 1);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
`;

const ListContainer = styled.div<{ path: string; orgId: string }>`
  background-color: rgba(250, 250, 250, 1);
  overflow-y: auto;
  border-right: 0.1rem solid rgba(231, 231, 231, 1);
  -webkit-overflow-scrolling: touch; //make ios scroll smooth

  @media ${devices.mobile} {
    flex: 1;
    padding: ${(props) =>
      showSearchBar(props.path, props.orgId) ? '15rem 2rem 4rem 2rem' : '8.2rem 2rem 4rem 2rem'};
    display: block;
    transition: ${(props) => props.theme.animation.transition.menu};
  }

  @media ${devices.phablet} {
    flex: 1;
    padding: 15rem 4rem 4rem 4rem;
    display: block;
    transition: ${(props) => props.theme.animation.transition.menu};
  }

  @media ${devices.tablet} {
    width: 40%;
    padding: 8rem 0 4rem 0;
  }

  @media ${devices.desktop} {
    width: 40%;
    padding: 4rem 0;
    margin-left: ${(p) => p.theme.sizes.sideMenuOld};
  }
  ${(props) => props.theme?.components?.ListLayoutPage?.ListContainer}
`;

const ListContent = styled.div`
  @media ${devices.tablet} {
    padding: 0 7%;
  }

  @media ${devices.desktop} {
    padding: 0 7%;
    max-width: 40rem;
    margin: 0 auto;
  }
`;

const Title = styled.div`
  font-size: 3.4rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  color: ${(props) => props.theme.text.color.main};
  line-height: 5.1rem;
  text-transform: capitalize;
`;

const SearchBarContainer = styled.div`
  margin-bottom: 3.8rem;
`;

const ProjectionContainer = styled.div`
  transition: ${(props) => props.theme.animation.transition.menu};
  box-sizing: border-box;
  position: relative;

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: rgba(243, 243, 243, 1);
    position: absolute;
    left: calc(100% + 1rem);
    height: 100%;
    width: 100%;
    z-index: ${(props) => props.theme.zIndex.singleMobileView};
    &.single {
      left: 0;
    }
  }

  @media ${devices.tablet} {
    background-color: rgba(255, 255, 255, 1);
    width: 60%;
    padding: 6.5rem 0 4rem 0;
  }

  @media ${devices.desktop} {
    background-color: rgba(255, 255, 255, 1);
    width: 60%;
    padding-bottom: 4rem;
  }
`;

const ProjectionScrollable = styled.div`
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;

  @media ${devices.desktop}, ${devices.tablet} {
    padding: 4rem 7% 0 7%;
  }
`;

const ProjectionContent = styled.div`
  max-width: 63rem;
  margin: 0 auto;

  ${(props) => props.theme?.components?.ListLayoutPage?.ProjectionContent}
`;
