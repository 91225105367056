import * as React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { mapProps } from 'recompose';
import {
  getVisiblePages,
  calculateCurrentPage,
  calculateTotalPagesCount,
  calculateNewStart,
  convertStart,
  convertLimit,
  calculateMorePrevPage,
  calculateMoreNextPage,
} from 'src/app/utils/pagination-utils';
import { PAGINATION } from 'src/app/utils/consts';
import { PaginationItemsTypeEnum } from 'src/app/version-2/model/enums';

type Props = {
  start: number;
  limit: number;
  total: number;
  itemType: PaginationItemsTypeEnum;
  pathname?: string;
  baseSearch?: string;
  onChangePage?: (start: number, limit: number) => void;
};

type State = {
  currentPage: number;
};

class MIPaginationBar extends React.PureComponent<Props, State> {
  static defaultProps = {
    start: PAGINATION.DEFAULT_START,
    limit: PAGINATION.DEFAULT_LIMIT,
    total: 0,
    pathname: '',
    baseSearch: '',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      currentPage: this.getCurrentPage(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.limit !== this.props.limit ||
      prevProps.start !== this.props.start ||
      prevProps.total !== this.props.total
    ) {
      const newCurrentPage = this.getCurrentPage();

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: newCurrentPage });
    }
  }

  getTotalPagesCount = () => {
    const { total, limit } = this.props;

    return calculateTotalPagesCount(total, limit);
  };

  getCurrentPage = () => {
    const { start, limit, total } = this.props;

    return calculateCurrentPage(start, limit, total);
  };

  getRedirectPath = (redirectPage: number): { pathname?: string; search: string } => {
    const { limit, pathname, baseSearch } = this.props;
    const newStart = calculateNewStart(redirectPage, limit);

    return {
      pathname,
      search: `${baseSearch ? `${baseSearch}&` : ''}start=${newStart}&limit=${limit}`,
    };
  };

  getMorePrevRedirectPath = () => {
    const { currentPage } = this.state;
    const redirectPage = calculateMorePrevPage(currentPage);

    return this.getRedirectPath(redirectPage);
  };

  getMoreNextRedirectPath = () => {
    const { currentPage } = this.state;
    const totalPagesCount = this.getTotalPagesCount();
    const redirectPage = calculateMoreNextPage(currentPage, totalPagesCount);

    return this.getRedirectPath(redirectPage);
  };

  goToPage = (page: number) => {
    this.setState({ currentPage: page }, this.currentPageChanged);
  };

  goPrevPage = () => {
    if (this.hasPrev()) {
      this.setState(
        ({ currentPage }) => ({ currentPage: currentPage - 1 }),
        this.currentPageChanged
      );
    }
  };

  goNextPage = () => {
    if (this.hasNext()) {
      this.setState(
        ({ currentPage }) => ({ currentPage: currentPage + 1 }),
        this.currentPageChanged
      );
    }
  };

  goToFirstPage = () => {
    const { currentPage } = this.state;

    if (currentPage !== 1) {
      this.goToPage(1);
    }
  };

  goToLastPage = () => {
    const { currentPage } = this.state;
    const totalPagesCount = this.getTotalPagesCount();

    if (currentPage !== totalPagesCount) {
      this.goToPage(totalPagesCount);
    }
  };

  goToMorePrev = () => {
    this.setState(({ currentPage }) => {
      const newCurrentPage = calculateMorePrevPage(currentPage);

      return {
        currentPage: newCurrentPage,
      };
    }, this.currentPageChanged);
  };

  goToMoreNext = () => {
    this.setState(({ currentPage }) => {
      const totalPagesCount = this.getTotalPagesCount();
      const newCurrentPage = calculateMoreNextPage(currentPage, totalPagesCount);

      return {
        currentPage: newCurrentPage,
      };
    }, this.currentPageChanged);
  };

  currentPageChanged = () => {
    const { limit, onChangePage } = this.props;
    const { currentPage } = this.state;
    const newStart = calculateNewStart(currentPage, limit);

    if (onChangePage) {
      onChangePage(newStart, limit);
    }
  };

  hasPrev = () => {
    const { currentPage } = this.state;

    return currentPage > 1;
  };

  hasNext = () => {
    const { currentPage } = this.state;
    const totalPagesCount = this.getTotalPagesCount();

    return currentPage < totalPagesCount;
  };

  renderBeforePagesBlock = (currentPage: number, firstVisiblePage: number) => (
    <>
      <PaginationItemWrapper
        isHidden={currentPage === 1}
        redirectPath={this.getRedirectPath(1)}
        onItemClick={() => this.goToFirstPage()}
        itemType={this.props.itemType}
      >
        <PaginationIconItem className="icon-first-page-icon" />
      </PaginationItemWrapper>
      <PaginationItemWrapper
        isHidden={!this.hasPrev()}
        redirectPath={this.getRedirectPath(currentPage - 1)}
        onItemClick={() => this.goPrevPage()}
        itemType={this.props.itemType}
      >
        <PaginationIconItem className="icon-prev-page-icon" />
      </PaginationItemWrapper>

      {firstVisiblePage !== 1 && (
        <PaginationItemWrapper
          redirectPath={this.getMorePrevRedirectPath()}
          onItemClick={() => this.goToMorePrev()}
          itemType={this.props.itemType}
        >
          <PaginationBarMoreItem>...</PaginationBarMoreItem>
        </PaginationItemWrapper>
      )}
    </>
  );

  renderPagesBlock = (currentPage: number, pages: number[]) => (
    <>
      {pages.map((page) => (
        <PaginationItemWrapper
          key={page}
          redirectPath={this.getRedirectPath(page)}
          onItemClick={() => this.goToPage(page)}
          itemType={this.props.itemType}
        >
          <PaginationPageItem isActive={page === currentPage}>{page}</PaginationPageItem>
        </PaginationItemWrapper>
      ))}
    </>
  );

  renderAfterPagesBlock = (
    currentPage: number,
    lastVisiblePage: number,
    totalPagesCount: number
  ) => (
    <>
      {lastVisiblePage !== totalPagesCount && (
        <PaginationItemWrapper
          redirectPath={this.getMoreNextRedirectPath()}
          onItemClick={() => this.goToMoreNext()}
          itemType={this.props.itemType}
        >
          <PaginationBarMoreItem>...</PaginationBarMoreItem>
        </PaginationItemWrapper>
      )}
      <PaginationItemWrapper
        isHidden={!this.hasNext()}
        redirectPath={this.getRedirectPath(currentPage + 1)}
        onItemClick={() => this.goNextPage()}
        itemType={this.props.itemType}
      >
        <PaginationIconItem className="icon-next-page-icon" />
      </PaginationItemWrapper>
      <PaginationItemWrapper
        isHidden={currentPage === totalPagesCount}
        redirectPath={this.getRedirectPath(totalPagesCount)}
        onItemClick={() => this.goToLastPage()}
        itemType={this.props.itemType}
      >
        <PaginationIconItem className="icon-last-page-icon" />
      </PaginationItemWrapper>
    </>
  );

  render() {
    const { currentPage } = this.state;
    const totalPagesCount = this.getTotalPagesCount();

    if (totalPagesCount <= 1) {
      return null;
    }

    const visiblePages = getVisiblePages(
      currentPage,
      totalPagesCount,
      PAGINATION.DEFAULT_VISIBLE_PAGES_COUNT
    );
    const firstVisiblePage = visiblePages[0];
    const lastVisiblePage = visiblePages[visiblePages.length - 1];

    return (
      <PaginationBarContainer>
        {this.renderBeforePagesBlock(currentPage, firstVisiblePage)}
        {this.renderPagesBlock(currentPage, visiblePages)}
        {this.renderAfterPagesBlock(currentPage, lastVisiblePage, totalPagesCount)}
      </PaginationBarContainer>
    );
  }
}

type PaginationItemWrapperProps = {
  isHidden?: boolean;
  itemType: PaginationItemsTypeEnum;
  redirectPath?: string | any;
  onItemClick?: () => void;
  children: React.ReactNode;
};

const PaginationItemWrapper = ({
  isHidden,
  itemType,
  redirectPath,
  children,
  onItemClick,
}: PaginationItemWrapperProps) => {
  if (isHidden) {
    return <PaginationItemPlaceholder />;
  }

  return itemType === PaginationItemsTypeEnum.LINK ? (
    <PaginationItemNavLink to={redirectPath}>{children}</PaginationItemNavLink>
  ) : (
    <PaginationItemButton onClick={onItemClick}>{children}</PaginationItemButton>
  );
};

const PaginationBarContainer = styled.div`
  display: flex;
  justify-content: center;

  ${(props) => props.theme?.components?.MIPaginationBar?.PaginationBarContainer}
`;

const paginationBarItemBaseStyles = css`
  height: 2.4rem;
  width: 2.4rem;
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  text-align: center;
`;

const PaginationItemPlaceholder = styled.div`
  ${paginationBarItemBaseStyles}
`;

const PaginationItemNavLink = styled(NavLink)`
  text-decoration: none;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`;

const PaginationItemButton = styled.div`
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  ${paginationBarItemBaseStyles}
`;

const PaginationIconItem = styled.i`
  ${paginationBarItemBaseStyles}
  font-size: 2.4rem;
  color: ${(props) => props.theme.text.color.light};

  :hover {
    color: ${(props) => props.theme.colors.primary.opaque};
  }

  ${(props) => props.theme?.components?.MIPaginationBar?.PaginationIconItem}
`;

const PaginationPageItem = styled.div<{ isActive?: boolean }>`
  ${paginationBarItemBaseStyles}
  border-radius: 1.2rem;
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.primary.opaque : 'transparent'};
  color: ${(props) =>
    props.isActive ? props.theme.colors.white.opaque : props.theme.text.color.light};

  :hover {
    background-color: ${(props) =>
      props.isActive ? props.theme.colors.primary.opaque : props.theme.colors.border.grey};
    color: ${(props) =>
      props.isActive ? props.theme.colors.white.opaque : props.theme.text.color.subtitle};
  }

  ${(props) => props.theme?.components?.MIPaginationBar?.PaginationPageItem}
`;

const PaginationBarMoreItem = styled.div`
  ${paginationBarItemBaseStyles}
  vertical-align: bottom;
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.light};

  ${(props) => props.theme?.components?.MIPaginationBar?.PaginationBarMoreItem}
`;

export default mapProps(({ start, limit, ...restProps }: Props) => ({
  start: convertStart(start),
  limit: convertLimit(limit),
  ...restProps,
}))(MIPaginationBar);
