const VIRTUAL_CARD_INFO_TRANSLATION_PATH = 'vendors.addDeliveryMethodByLink.virtualCardInfoPage';

const dictionary = {
  infoSubmitButton: {
    buttonLabel: (isVirtualPayment: boolean) =>
      `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.${isVirtualPayment ? 'unilateral.' : ''}cta.label`,
    subtitle: `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.cta.subtitle`,
  },
  multilateral: {
    title: `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.multilateral.cardExample.title`,
    body: `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.multilateral.cardExample.subtitle`,
    checkListItem: {
      title: (item: string) =>
        `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.multilateral.checkMark.${item}.title`,
      subtitle: (item: string, isCheckDeliveryType: boolean) =>
        `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.multilateral.checkMark.${item}.${
          item === 'flexible' && isCheckDeliveryType ? 'check_' : ''
        }subtitle`,
    },
    info: `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.multilateral.info`,
    changeDeliveryMethodCta: `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.multilateral.changeDeliveryMethodCta`,
  },
  unilateral: {
    title: (item: string) => `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.unilateral.${item}.title`,
    subtitle: (item: string) => `${VIRTUAL_CARD_INFO_TRANSLATION_PATH}.unilateral.${item}.subtitle`,
  },
};

export default dictionary;
