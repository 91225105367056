import React from 'react';
import { featureFlags } from '@melio/shared-web';
import { Box, Link } from '@melio/billpay-design-system';

import { PaymentType } from 'src/app/utils/types';
import { isUndepositedCheck, isUndepositedOverdueCheck } from 'src/app/utils/payments';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';

type Props = {
  payment: PaymentType;
  onVoidCheckAndResendPayment: () => void;
};

const CheckOverdueNotification = ({ payment, onVoidCheckAndResendPayment }: Props) => {
  const [voidChecksEnabled] = featureFlags.useFeature('bp-void-check', false);
  const isUndepositedCheckPayment = isUndepositedCheck(payment);
  const isPaymentVoidAndResendCompitable = payment.metadata?.isVoidAndRetryEnabled;

  if (!voidChecksEnabled || !isUndepositedCheckPayment || !isPaymentVoidAndResendCompitable) {
    return null;
  }

  const isOverdueUndepositedCheckPayment = isUndepositedOverdueCheck(payment);
  const undepositedCheckNotificationLabel = isOverdueUndepositedCheckPayment ? 'overdue' : 'early';
  const undepositedCheckNotificationValues = isOverdueUndepositedCheckPayment
    ? {
        ctaLink: (...chunks) => (
          <Link
            cursor="pointer"
            textDecoration="none !important"
            onClick={onVoidCheckAndResendPayment}
          >
            {React.Children.toArray(chunks)}
          </Link>
        ),
      }
    : {};
  const undepostiedCheckNotificationType = isOverdueUndepositedCheckPayment
    ? NOTIFICATION_CARD_TYPES.WARNING
    : NOTIFICATION_CARD_TYPES.INFO;

  return (
    <Box m={4} marginBottom={0} data-testid="check-paid-notification">
      <QBONotificationCard
        type={undepostiedCheckNotificationType}
        subtitle={{
          label: `bills.pay.confirm.undepositedCheck.${undepositedCheckNotificationLabel}`,
          values: undepositedCheckNotificationValues,
        }}
      />
    </Box>
  );
};

export { CheckOverdueNotification };
