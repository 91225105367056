import * as React from 'react';
import analytics from 'src/app/services/analytics';
import styled from 'styled-components';
import { AcceptFilesEnum } from 'src/app/version-2/model/enums';
import { devices } from '../../theme/AppDevices';
import { MIFormattedText } from '../../utils/formatting';

type Props = {
  uploadLabel: string;
  deleteLabel: string;
  isShowDeleteButton: boolean;
  onChangeAttachment?: (fileInput: React.ChangeEvent<HTMLInputElement>) => void | null;
  onDeleteAttachment?: () => void | null;
  accept?: string;
  isDisabled: boolean;
};

class UploadAttachment extends React.PureComponent<Props> {
  static defaultProps = {
    accept: AcceptFilesEnum.ALL,
    isDisabled: false,
  };

  fileInputRef: { current: HTMLInputElement | null } = React.createRef();

  triggerFileInput = () => {
    if (this.fileInputRef.current && !this.props.isDisabled) {
      this.fileInputRef.current.click();
      analytics.trackAction(this.props.uploadLabel);
    }
  };

  triggerDeleteFile = () => {
    if (!this.props.isDisabled && this.props.onDeleteAttachment) {
      this.props.onDeleteAttachment();
      analytics.trackAction(this.props.deleteLabel);
    }
  };

  render() {
    const { uploadLabel, deleteLabel, isShowDeleteButton, onChangeAttachment, accept, isDisabled } =
      this.props;

    return (
      <ModifyAttachmentActionContainer
        id="actionContainer"
        isDisabled={isDisabled}
        isShowDeleteButton={isShowDeleteButton}
      >
        {isShowDeleteButton && (
          <ModifyAttachmentAction
            isDisabled={isDisabled}
            destructive
            onClick={this.triggerDeleteFile}
          >
            <MIFormattedText label={deleteLabel} />
          </ModifyAttachmentAction>
        )}
        <ModifyAttachmentAction isDisabled={isDisabled} onClick={this.triggerFileInput}>
          <MIFormattedText label={uploadLabel} />
          <FileInput
            ref={this.fileInputRef}
            onChange={onChangeAttachment}
            type="file"
            accept={accept}
          />
        </ModifyAttachmentAction>
      </ModifyAttachmentActionContainer>
    );
  }
}

const ModifyAttachmentActionContainer = styled.div<{
  isDisabled?: boolean;
  isShowDeleteButton?: boolean;
}>`
  display: flex;
  justify-content: ${(props) => (props.isShowDeleteButton ? 'space-between' : 'center')};
  max-width: ${(props) => (props.isShowDeleteButton ? '100%' : props.theme.sizes.attachmentLarge)};
  width: 100%;
  overflow: hidden;

  &:last-of-type {
    margin-left: 1.4rem;
    margin-top: 0;
  }

  @media ${devices.mobile} {
    justify-content: ${(props) => (props.isDisabled ? 'flex-start' : 'space-between')};
    width: 50%;
    padding-top: 0rem;
  }
  ${(props) => props.theme?.components?.UploadAttachment?.ModifyAttachmentActionContainer}
`;

const ModifyAttachmentAction = styled.div<{
  destructive?: boolean;
  isDisabled?: boolean;
}>`
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) =>
    props.destructive ? props.theme.text.color.red : props.theme.text.color.main};
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: 2.5rem;
  text-transform: none;
  position: relative;
  opacity: ${(props) => (props.isDisabled ? '0.3' : '1')};

  &:hover {
    cursor: pointer;
    color: ${(props) =>
      props.destructive ? 'rgba(192, 2, 15, 1)' : props.theme.colors.primary.opaque};
  }

  ${(props) => props.theme?.components?.UploadAttachment?.ModifyAttachmentAction}
`;

const FileInput = styled.input`
  visibility: hidden;
  position: absolute;
  height: 0;
  width: 0;
`;

export default UploadAttachment;
