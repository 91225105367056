import { theme } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 24.6rem;
  min-height: 15rem;
  align-self: center;
  padding: ${(props) => props.theme.space.ds.md} ${(props) => props.theme.space.ds.lg}
    ${(props) => props.theme.space.ds.lg};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radii.ds.lg};
  box-shadow: ${({ theme }) => theme.shadows.ds.lg};
  background: linear-gradient(
    230.87deg,
    ${({ theme }) => theme.colors.ds.teal[300]} 0%,
    ${({ theme }) => theme.colors.ds.green[300]} 100%
  );
`;

export const CardPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${(props) => props.theme.space.ds.md};
  button {
    ${({ theme }) => theme.textStyles.ds.body2};
    color: ${({ theme }) => theme.colors.ds.blue[200]};
    outline: none !important;
    &:hover {
      background-color: transparent !important;
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const AmountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DecimalAmountPart = styled.span`
  ${({ theme }) => theme.textStyles.ds.body4};
  vertical-align: top;
`;

export const amountStyle = {
  flexWrap: 'wrap',
  wordBreak: 'break-word',
  color: theme.colors.ds.gray[800],
  textStyle: 'h5',
  fontWeight: theme.fontWeights.ds.semi,
  mr: theme.space.ds.xs,
};

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fontWeights.ds.semi};
  color: ${({ theme }) => theme.colors.ds.white};
  ${({ theme }) => theme.textStyles.ds.body3};
`;

export const CardNumber = styled.div`
  margin-bottom: ${(props) => props.theme.space.ds.sm};
`;

export const CardDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftLabel = styled.div`
  font-size: 0.6rem;
  line-height: 0.6rem;
  margin-right: ${(props) => props.theme.space.ds.sm};
  font-weight: ${({ theme }) => theme.fontWeights.ds.regular};
`;

export const RightLabel = styled.div`
  font-size: 0.6rem;
  line-height: 0.6rem;
  margin-left: ${(props) => props.theme.space.ds.lg};
  margin-right: ${(props) => props.theme.space.ds.sm};
  font-weight: ${({ theme }) => theme.fontWeights.ds.regular};
`;
