import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { globalOptInSteps } from 'src/app/version-2/pages/global-opt-in-details/model/enums/globalOptInSteps.enum';
import { BusinessDetails } from './../model/dtos/businessDetails';
import { PersonalDetails } from './../model/dtos/personalDetails';

import { History } from 'history';

export const GLOBAL_OPT_IN_SLICE = `${VERSION_2}globalOptInSlice`;

export interface GlobalOptInSliceInitialState {
  isLoading: boolean;
  personalDetailsData: PersonalDetails;
  optInActiveSteps: globalOptInSteps;
  token: string;
  businessDetails: BusinessDetails;
}

const selectState = (state) => state[GLOBAL_OPT_IN_SLICE] as GlobalOptInSliceInitialState;

const getGlobalOptInInitialState = (): GlobalOptInSliceInitialState => ({
  isLoading: true,
  optInActiveSteps: globalOptInSteps.personalDetails,
  personalDetailsData: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  businessDetails: {
    businessName: '',
    fcbStatus: '',
    googlePlaceId: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    countryCode: 'US',
    aptNumber: '',
    state: '',
    zipCode: '',
    inputValue: '',
    formattedAddress: '',
    routingNumber: '',
    accountNumber: '',
  },
  token: '',
});

export const globalOptInSlice = createSlice({
  name: GLOBAL_OPT_IN_SLICE,
  initialState: getGlobalOptInInitialState(),
  reducers: {
    setPersonalData: (
      state,
      action: PayloadAction<{
        personalFormData: PersonalDetails;
        activeStep: globalOptInSteps;
        token: string;
      }>
    ) => {
      const { personalFormData, activeStep, token } = action.payload;

      state.personalDetailsData = personalFormData;
      state.optInActiveSteps = activeStep;
      state.token = token;
    },
    setBusinessData: (state, action: PayloadAction<{ businessDetails: BusinessDetails }>) => {
      state.businessDetails = action.payload.businessDetails;
    },
  },
});

export const globalOptInSliceActions = {
  ...globalOptInSlice.actions,
  checkGlobalOptInCandidates: createAction<{ history: History; token: string }>(
    `${GLOBAL_OPT_IN_SLICE}/checkCandidatesToken`
  ),
  continuePersonalDetailsForm: createAction<{
    history: History;
    personalDetailsFormData: PersonalDetails;
    optInActiveSteps: globalOptInSteps;
    token: string;
  }>(`${GLOBAL_OPT_IN_SLICE}/continuePersonalDetails`),
  submitBusinessDetails: createAction<{
    history: History;
    businessDetailsFormData: BusinessDetails;
    token: string;
  }>(`${GLOBAL_OPT_IN_SLICE}/submitBusinessDetails`),
};

const selectPersonalDetailsData = createSelector(selectState, (state) => state.personalDetailsData);
const selectEmail = createSelector(selectState, (state) => state.personalDetailsData.email);
const selectVCCandidatesTokenState = createSelector(selectState, (state) => ({
  token: state.token,
}));
const selectBusinessDetailsData = createSelector(selectState, (state) => state.businessDetails);

export const globalOptInSelectors = {
  selectPersonalDetailsData,
  selectVCCandidatesTokenState,
  selectBusinessDetailsData,
  selectEmail,
};
