import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Box } from '@melio/billpay-design-system';
import { TransactionDestinationEnum } from 'src/app/version-2/model/enums';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { devices } from 'src/app/theme/AppDevices';
import { SelectExpediteStateType } from 'src/app/pages/vendor/select-expedited/hoc/select-expedited-hooks';
import { ReactComponent as QBLogo } from 'src/app/images/qb-logo.svg';
import OutsideFooter from 'src/app/components/common/OutsideLayout/OutsideFooter';
import analytics from 'src/app/services/analytics';
import { MIFormattedCurrency, MIFormattedText } from 'src/app/utils/formatting';
import { useBreak } from 'src/app/hoc';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { getPartialBillId } from 'src/app/utils/bills';
import VendorCheckSelectCategories from './VendorCheckSelectCategories';
import VendorCheckETA from './VendorCheckETA';
import { stringifyDeliveryMethods } from '../utils';

type PropsType = {
  token: string;
  state: SelectExpediteStateType;
};

const SHIFT_ACH_PATH = 'vendors.addDeliveryMethodByLink.checkChooseDeliveryMethod';

const achOptions = [DELIVERY_TYPE.ACH, DELIVERY_TYPE.VIRTUAL_CARD];

const VendorCheckSelectDeliveryMethod = ({ state, token }: PropsType) => {
  const { payment, organization, deliveryMethod } = state;
  const { isMobile } = useBreak();

  const analyticsProps = useMemo(
    () => ({
      flow: TransactionDestinationEnum.VENDOR,
      paymentId: payment.id,
      billId: payment.billId,
      partialBillId: getPartialBillId({
        id: payment.billId as string,
        payments: payment.billPayments,
      }),
      vendorId: payment?.vendor?.id,
    }),
    [payment]
  );

  useEffect(() => {
    analytics.trackAction('ChooseDeliveryMethod-Viewed', {
      deliveryMethodTypes: stringifyDeliveryMethods(achOptions),
      ...analyticsProps,
    });
  }, []);

  return (
    <QBOLayoutPage
      hideHeader
      contentWrapperMode="success"
      innerSize={60}
      qboFooter={
        <Box sx={{ position: 'relative', bottom: '3rem' }}>
          <OutsideFooter />
        </Box>
      }
    >
      <Box textAlign="center" marginBottom={5} marginTop={10}>
        <QBLogo />
      </Box>
      <Content>
        <Box gridGap="1.1rem" p={isMobile ? 5 : 8} pb={0} lineHeight="20px">
          <Box textStyle="h5" fontWeight="ds.semi" pb={2}>
            <MIFormattedText label={`${SHIFT_ACH_PATH}.title`} />
          </Box>
          <Box fontSize={14} mb={20}>
            <MIFormattedText
              label={`${SHIFT_ACH_PATH}.subtitle`}
              values={{
                totalAmount: <MIFormattedCurrency value={payment?.amount || ''} />,
                companyName: organization?.companyName,
              }}
            />
          </Box>
          <VendorCheckETA
            deliveryEta={payment.deliveryEta}
            maxDeliveryEta={payment.maxDeliveryEta}
            scheduledDate={payment.scheduledDate}
            translationPath={SHIFT_ACH_PATH}
          />
        </Box>

        {isMobile && <Separator />}
        <VendorCheckSelectCategories
          payment={payment}
          deliveryMethod={deliveryMethod}
          token={token}
          analyticsProps={analyticsProps}
          translationPath={SHIFT_ACH_PATH}
        />
      </Content>
    </QBOLayoutPage>
  );
};

const Content = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  box-sizing: border-box;
  border-radius: 0.6rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  color: ${(props) => props.theme.colors.ds.black};
  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 6rem;
  }
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.border.lightGrey};
  height: 0.4rem;
  width: 89%;
  margin: 4rem auto 0.8rem;
`;

export default VendorCheckSelectDeliveryMethod;
