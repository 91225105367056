import * as React from 'react';
import { RecordOf } from 'immutable';
import { useSelector } from 'react-redux';
import moment from 'moment-business-days';
import styled, { css } from 'styled-components';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { getFormattedCheckSerial } from 'src/app/utils/bills';
import { DeliveryMethodRecord } from 'src/app/pages/vendor/records-constants';
import { DELIVERY_TYPE, FULL_STORY_MASK_RULE_CLASS, PAYMENT_STATUS } from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';
import { DeliveryDate } from 'src/app/utils/dates';
import { MIFormattedDate, MIFormattedText } from 'src/app/utils/formatting';
import { DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';
import { FundingSourceTypesEnum, PaymentCollectStatusEnum } from 'src/app/version-2/model/enums';

type ConvertedUnilateralPaymentActivityProps = {
  payment: PaymentType;
  className?: string;
};

const ConvertedUnilateralPaymentActivity: React.FC<ConvertedUnilateralPaymentActivityProps> = ({
  payment,
  className,
}) => {
  const deliveryMethod: DeliveryMethodType = useSelector(
    deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId)
  );
  const deliveryMethodRecord = DeliveryMethodRecord(deliveryMethod);
  const isPaymentCheckCompleted =
    payment?.status === PAYMENT_STATUS.COMPLETED &&
    deliveryMethodRecord.deliveryType === DELIVERY_TYPE.CHECK;

  const titleLabel = isPaymentCheckCompleted
    ? 'requests.form.paymentActivity.paymentDetails'
    : 'requests.form.paymentActivity.vendorReceive';

  return (
    <OutsideBillReviewWrapper
      className={className}
      data-testid="converted-unilateral-payment-activity"
    >
      <PaymentActivityWrapper>
        <BlockTitleContainer>
          <MIFormattedText label={titleLabel} />
        </BlockTitleContainer>
        <MainPaymentActivityContainer>
          <PaymentActivitySource payment={payment} deliveryMethod={deliveryMethodRecord} />
          <PaymentActivityDate payment={payment} deliveryMethod={deliveryMethodRecord} />
        </MainPaymentActivityContainer>
      </PaymentActivityWrapper>
    </OutsideBillReviewWrapper>
  );
};

type PaymentActivitySourceProps = {
  payment: PaymentType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

const PaymentActivitySource = ({ payment, deliveryMethod }: PaymentActivitySourceProps) => (
  <PaymentActivityContainer>
    <PaymentActivityMainContainer>
      <PaymentInfoContainer>
        <PaymentIcon>
          <DeliveryMethodIcon deliveryMethod={deliveryMethod} />
        </PaymentIcon>
        <PaymentTextContainer>
          <PaymentMethodContainer>
            <PaymentMethodText>
              <DeliveryMethodMethodTitle deliveryMethod={deliveryMethod} payment={payment} />
            </PaymentMethodText>
          </PaymentMethodContainer>
          <PaymentInfoText>
            <DeliveryMethodMethodInfo deliveryMethod={deliveryMethod} />
          </PaymentInfoText>
        </PaymentTextContainer>
      </PaymentInfoContainer>
    </PaymentActivityMainContainer>
  </PaymentActivityContainer>
);

type DeliveryMethodIconProps = {
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

const DeliveryMethodIcon = ({ deliveryMethod }: DeliveryMethodIconProps) => {
  if (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH) {
    return <div className="icon-bank-icon" />;
  }

  if (deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
    return <Mastercard width="2.4rem" height="1.9rem" />;
  }

  return <div className="icon-check-icon" />;
};

type DeliveryMethodMethodTitleProps = {
  payment: PaymentType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};
const DeliveryMethodMethodTitle = ({ deliveryMethod, payment }: DeliveryMethodMethodTitleProps) => {
  let label = 'bills.form.paymentActivity.scheduledBill.deliveryPaperCheckMethod';

  if (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH) {
    label = 'bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod';
  } else if (deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
    label = 'bills.form.paymentActivity.scheduledBill.virtualCardDeliveryMethod';
  } else if (
    payment?.status === PAYMENT_STATUS.COMPLETED &&
    deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK
  ) {
    label = 'bills.form.paymentActivity.scheduledBill.checkDeliveryMethod';
  }

  const formattedCheckSerial = getFormattedCheckSerial(payment);

  return <MIFormattedText label={label} values={{ formattedCheckSerial }} />;
};

type DeliveryMethodMethodInfoProps = {
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

const DeliveryMethodMethodInfo = ({ deliveryMethod }: DeliveryMethodMethodInfoProps) => {
  if (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH) {
    return (
      <>
        <MIFormattedText label="vendors.addDeliveryMethodByLink.bankAccount" />
        {deliveryMethod.getDeliveryInfo('')}
      </>
    );
  }

  return <span className={FULL_STORY_MASK_RULE_CLASS}>{deliveryMethod.getDeliveryInfo('')}</span>;
};

type PaymentActivityDateProps = {
  payment: PaymentType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
};

const PaymentActivityDate = ({ payment, deliveryMethod }: PaymentActivityDateProps) => {
  const isPaymentCheck = deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK;
  const isPaymentCheckCompleted = payment?.status === PAYMENT_STATUS.COMPLETED && isPaymentCheck;
  const isPaymentCollected = payment?.collectStatus === PaymentCollectStatusEnum.SENT;
  const isCardFundingSource = payment?.fundingSource?.fundingType === FundingSourceTypesEnum.CARD;
  const isThreeDaysPayment =
    deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD ||
    (deliveryMethod.deliveryType === DELIVERY_TYPE.ACH && !isCardFundingSource) ||
    deliveryMethod.deliveryType === DELIVERY_TYPE.CARD;

  const additionalDescription = isPaymentCheck
    ? 'bills.form.paymentActivity.deliveryMethodAdditionalDescriptionPayee'
    : '';

  const titleLabel = isPaymentCheckCompleted
    ? 'bills.form.paymentActivity.checkDeliveryTitleETA'
    : 'bills.form.paymentActivity.deliveryETASuccessTitle';

  const DeliveryDateValue = () => {
    if (isPaymentCollected) {
      return <MIFormattedText label="bills.form.paymentActivity.deliveryETASuccessValue" />;
    }

    return (
      <DeliveryDate
        {...(isThreeDaysPayment
          ? { date: moment(payment?.scheduledDate).businessAdd(3) }
          : { date: payment?.deliveryEta, maxDate: payment?.maxDeliveryEta })}
      />
    );
  };

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <i className="icon-eta-cal" />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={titleLabel} />
            </PaymentMethodText>
            <PaymentDateContainer>
              <PaymentInfoText>
                {isPaymentCheckCompleted ? (
                  <MIFormattedDate date={payment?.deliveryEta} />
                ) : (
                  <DeliveryDateValue />
                )}
              </PaymentInfoText>
              {additionalDescription && (
                <AdditionalDescription>
                  <MIFormattedText label={additionalDescription} />
                </AdditionalDescription>
              )}
            </PaymentDateContainer>
          </PaymentTextContainer>
        </PaymentInfoContainer>
      </PaymentActivityMainContainer>
    </PaymentActivityContainer>
  );
};

const baseContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

const OutsideBillReviewWrapper = styled.div`
  ${baseContainerStyles}
  align-items: center;
  background: ${(props) => props.theme.background.default};
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: none;

  @media ${devices.mobile} {
    align-items: flex-start;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    border: none;
  }

  background-color: unset;
`;

const PaymentActivityWrapper = styled.div`
  ${baseContainerStyles}
  width: 100%;
  margin-bottom: 0;
`;

const MainPaymentActivityContainer = styled.div`
  ${baseContainerStyles}
  margin-bottom: 0;
  padding-top: 2rem;
  > div:first-child {
    margin-bottom: 2rem;
  }
`;

const PaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentActivityMainContainer = styled.div`
  display: flex;
  padding-right: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const BlockTitleContainer = styled.h5`
  color: ${(props) => props.theme.text.color.darkGrey};
  margin-bottom: 0.2rem;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.medium};
`;

const PaymentInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PaymentTextContainer = styled.div`
  ${baseContainerStyles}
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const PaymentMethodText = styled.div`
  font-size: 1.4rem;
  color: ${(props) => props.theme.text.color.label};
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.2px;
`;

const PaymentDateContainer = styled.div`
  ${baseContainerStyles}
  flex-direction: row;
  align-items: center;
`;

const PaymentInfoText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: 500;
  ${(props) => props.theme.text.fontType.regular};
  margin-right: 0.4rem;
`;

const AdditionalDescription = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
`;

const PaymentIcon = styled.i`
  font-size: 2.4rem;
  box-sizing: border-box;
  margin-right: 1.2rem;
  color: ${(props) => props.theme.text.color.main};
  > img {
    height: 2.4rem;
  }

  > i {
    font-size: 2.4rem;
    color: ${(props) => props.theme.text.color.main};
  }
`;

export default ConvertedUnilateralPaymentActivity;
