import { vendorUpgradePaymentClientEventNames } from './utils/analytics.utils';
import { type EventMappingsTree } from '../../externals';

const FAST_DELIVERY_EVENT_PAGE = 'push-to-fast-delivery-options';

const defaultAnalyticsProps = {
  paymentId: 'paymentId',
  vendorId: 'vendorId',
  vendorEmail: 'vendorEmail',
};

export const vendorUpgradePaymentEventMapping: EventMappingsTree = {
  '/vendors/upgrade-payment/*/select': {
    [vendorUpgradePaymentClientEventNames.OPTIONS_PAGE_VIEW]: [
      FAST_DELIVERY_EVENT_PAGE,
      vendorUpgradePaymentClientEventNames.OPTIONS_PAGE_VIEW,
      {
        ...defaultAnalyticsProps,
        shownOptions: 'shownOptions',
      },
    ],
    [vendorUpgradePaymentClientEventNames.SELECT_OPTION]: [
      FAST_DELIVERY_EVENT_PAGE,
      vendorUpgradePaymentClientEventNames.SELECT_OPTION,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
    'go.back': [FAST_DELIVERY_EVENT_PAGE, 'exit', defaultAnalyticsProps],
  },
  '/vendors/upgrade-payment/*/push-to-rtp': {
    [vendorUpgradePaymentClientEventNames.OPTIONS_PAGE_VIEW]: [
      FAST_DELIVERY_EVENT_PAGE,
      vendorUpgradePaymentClientEventNames.OPTIONS_PAGE_VIEW,
      {
        ...defaultAnalyticsProps,
        shownOptions: 'shownOptions',
      },
    ],
    [vendorUpgradePaymentClientEventNames.SELECT_OPTION]: [
      FAST_DELIVERY_EVENT_PAGE,
      vendorUpgradePaymentClientEventNames.SELECT_OPTION,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
    'go.back': [FAST_DELIVERY_EVENT_PAGE, 'exit', defaultAnalyticsProps],
  },
  '/vendors/upgrade-payment/*/push-to-fast-ach': {
    [vendorUpgradePaymentClientEventNames.OPTIONS_PAGE_VIEW]: [
      FAST_DELIVERY_EVENT_PAGE,
      vendorUpgradePaymentClientEventNames.OPTIONS_PAGE_VIEW,
      {
        ...defaultAnalyticsProps,
        shownOptions: 'shownOptions',
      },
    ],
    [vendorUpgradePaymentClientEventNames.SELECT_OPTION]: [
      FAST_DELIVERY_EVENT_PAGE,
      vendorUpgradePaymentClientEventNames.SELECT_OPTION,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
    'go.back': [FAST_DELIVERY_EVENT_PAGE, 'exit', defaultAnalyticsProps],
  },
  '/vendors/upgrade-payment/*/success': {
    [vendorUpgradePaymentClientEventNames.SUCCESS_PAGE_VIEW]: [
      FAST_DELIVERY_EVENT_PAGE,
      vendorUpgradePaymentClientEventNames.SUCCESS_PAGE_VIEW,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
  },
  '/vendors/upgrade-payment/*/offer-expired': {
    [vendorUpgradePaymentClientEventNames.OFFER_EXPIRED]: [
      FAST_DELIVERY_EVENT_PAGE,
      vendorUpgradePaymentClientEventNames.OFFER_EXPIRED,
      {
        ...defaultAnalyticsProps,
        reason: 'reason',
      },
    ],
  },
};
