import { EventMappingsTree } from 'src/app/services/analytics/types';
import { mapApiSlice } from 'src/app/services/analytics/event-mapping-utils';

const pageEvent = 'bills-dashboard';
const pagePaymentEvent = 'pay-bill';
const eventPageForBatches = 'pay-bills-batch';

const MAPPING: EventMappingsTree = {
  '/orgs/*/bills/qb-dashboard-list': {
    'tab-event-unpaid': [pageEvent, 'tab-unpaid'],
    'tab-event-scheduled': [pageEvent, 'tab-scheduled'],
    'tab-event-paid': [pageEvent, 'tab-paid'],
    'pagination-first-page': [pageEvent, 'first-page', { page: 'page' }],
    'pagination-last-page': [pageEvent, 'last-page', { page: 'page' }],
    'pagination-previous-page': [pageEvent, 'previous-page', { page: 'page' }],
    'pagination-next-page': [pageEvent, 'next-page', { page: 'page' }],
    'pagination-go-page': [pageEvent, 'go-to-page', { page: 'page' }],
    'batch-actions': [
      pageEvent,
      'batch-action-click',
      {
        action: 'action',
        billIds: 'billIds',
        isPartial: 'partialPaymentsCount',
      },
    ],
    'paymentDashboard.ftuModal.ctaButton': [pageEvent, 'ftu-modal-click'],
    'paymentDashboard.cancelPayment.dialogOkButtonText': [pageEvent, 'cancel-payment-submit'],
    'cancel-payment-dialog': [pageEvent, 'cancel-payment-dialog', { toggle: 'toggle' }],
    'delete-payment-dialog': [pageEvent, 'delete-payment-dialog', { toggle: 'toggle' }],
    'delete-bill-modal-dialog': [pageEvent, 'delete-bill-dialog', { toggle: 'toggle' }],
    'sort-by': [pageEvent, 'sort-bills', { sorting: 'sorting' }],
    'data-table-event': [pageEvent, 'select-bills', { billId: 'id', action: 'action' }],
    'vendor-modal-close': [pageEvent, 'edit-vendor-close'],
    ...mapApiSlice('[VENDORS] FETCH', pageEvent, 'edit-vendor-open', {
      success: { vendorId: 'payload.id', userId: 'payload.updatedById' },
    }),
    ...mapApiSlice('[VENDORS] UPDATE', pageEvent, 'edit-vendor-save', {
      request: {
        vendorCompanyName: 'payload.companyName',
        vendorEmail: 'payload.contactEmail',
      },
    }),
    navigate: [pageEvent, 'change-tab-from-empty-state', { to_tab: 'navigateTo.search' }],
    'dashboard-list-action': [
      pageEvent,
      'list',
      {
        total_bills_count: 'totalCount',
        filters: 'filters',
        tab_name: 'tabName',
        list_of_bills: 'billIds',
        list_of_payments: 'paymentIds',
        current_page_total_amount: 'totalAmount',
        billStatus: 'billStatus',
        withSyncLoader: 'withSyncLoader',
      },
    ],
    'dashboard-list-filters-action': [
      pageEvent,
      'filters-button-click',
      {
        click_target: 'clickTarget',
        status: 'status',
      },
    ],
    'dashboard-toggle-switch-changed': [pageEvent, 'turn-switch-off'],
    'turn-on-switcher-manually': [pageEvent, 'turn-on-switcher-manually'],
    'set-pay-bills-online-mode-as-default': [pageEvent, 'set-pay-bills-online-mode-as-default'],
    'close-default-switcher-state-modal': [pageEvent, 'close-default-switcher-state-modal'],
    'set-pay-bills-online-mode-maybe-later': [pageEvent, 'set-pay-bills-online-mode-maybe-later'],
    'show-default-switcher-state-modal': [pageEvent, 'show-default-switcher-state-modal'],
    'show-ftu-dashboard-state-modal': [pageEvent, 'show-ftu-dashboard-state-modal'],
    'mark-as-paid-modal': [pageEvent, 'mark-as-paid-modal', { toggle: 'toggle' }],
    'option-changed-intuitAccountId': [
      pageEvent,
      'mark-as-paid-modal-option-changed',
      { qboAccountId: 'option.value' },
    ],
    'mark-as-paid-modal-submit': [pageEvent, 'mark-as-paid-modal-submit', { billIds: 'billIds' }],
    'mark-as-paid-modal-on-focus-amount': [
      pageEvent,
      'focus-payment-amount',
      { amount: 'amount', source: 'source' },
    ],
    'action-click': [
      pageEvent,
      'action-item-click',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        isPartial: 'isPartial',
        status: 'status',
        action: 'action',
        fromRow: 'fromRow',
      },
    ],
    'success-payment-modal': [
      pagePaymentEvent,
      'success-payment-modal',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        vendorId: 'vendorId',
      },
    ],
    'success-payment-modal-done': [
      pagePaymentEvent,
      'done',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        vendorId: 'vendorId',
      },
    ],
    'pay-bills-batch': [
      eventPageForBatches,
      'success-batch-payment-modal',
      {
        partialBillIds: 'partialBillIds',
        paymentIds: 'paymentIds',
      },
    ],
  },
};

export default MAPPING;
