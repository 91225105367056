import { createSlice } from '@reduxjs/toolkit';
import { name } from './consts';

const initialState = {
  qbDashboardSelection: {
    selectedIds: [],
  },
};

type SliceState = {
  qbDashboardSelection: {
    selectedIds: string[];
  };
};

type SelectionActionPayload = {
  payload: string[];
};

type SelectByIdActionPayload = {
  payload: string;
};

type SliceReducers = {
  setSelection: (state: SliceState, action: SelectionActionPayload) => void;
  selectById: (state: SliceState, action: SelectByIdActionPayload) => void;
  unselectById: (state: SliceState, action: SelectByIdActionPayload) => void;
};

const createQbDashboardSelectionSlice = () => {
  const slice = createSlice<SliceState, SliceReducers>({
    name: `[${name.toUpperCase()}] QB_DASHBOARD_SELECTION`,
    initialState,
    reducers: {
      setSelection(state, { payload }) {
        state.qbDashboardSelection = {
          selectedIds: payload,
        };
      },
      selectById(state, { payload }) {
        const selectedIds = state.qbDashboardSelection?.selectedIds || [];

        if (!selectedIds.includes(payload)) {
          state.qbDashboardSelection = {
            selectedIds: [...selectedIds, payload],
          };
        }
      },
      unselectById(state, { payload }) {
        const selectedIds = state.qbDashboardSelection?.selectedIds || [];

        if (selectedIds.includes(payload)) {
          state.qbDashboardSelection = {
            selectedIds: selectedIds.filter((i) => i !== payload),
          };
        }
      },
    },
  });

  return {
    ...slice,
    selectors: {
      selectedIds: (state) => state[name].qbDashboardSelection?.selectedIds || [],
    },
    dispatchers: {
      setSelection: (dispatch) => (params) => dispatch(slice.actions.setSelection(params)),
      selectById: (dispatch) => (params) => dispatch(slice.actions.selectById(params)),
      unselectById: (dispatch) => (params) => dispatch(slice.actions.unselectById(params)),
    },
  };
};

const qbDashboardSelectionSlice = createQbDashboardSelectionSlice();

export { qbDashboardSelectionSlice };
