import React from 'react';
import { RecordOf } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { DeliveryMethodType } from 'src/app/utils/types';
import { FundingSource } from 'src/app/version-2/model/dtos';
import {
  organizationActions,
  organizationSelectors,
} from 'src/app/version-2/modules/organization/organization.slice';
import { CONSTS } from 'src/app/utils/consts';
import { getNotice } from 'src/app/version-2/utils/checkFees.utils';

import SelectedMethod from '../components/SelectedMethod';

type Props = {
  onClick: () => void;
  method: RecordOf<DeliveryMethodType>;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
  fundingSource?: FundingSource;
};

function CheckDeliveryMethod({
  onClick,
  method,
  isSelected,
  optionComponent: OptionComponent,
  disabled,
  fundingSource,
}: Props) {
  const dispatch = useDispatch();
  const catalog = useSelector(organizationSelectors.selectFeeCatalog);

  const onModalOpen = () => dispatch(organizationActions.setIsCheckFeeModalOpen(true));
  const notices = getNotice({
    fundingSource,
    catalog,
    onModalOpen,
  });
  const description = notices?.description || 'deliveryMethods.notDefinedInfo.check.desc';
  const descriptionValues = notices?.descriptionValues;

  if (!method) {
    return (
      <OptionComponent
        id="check"
        label="deliveryMethods.notDefinedInfo.check.label"
        labelValues={{ to: '' }}
        icon="icon-check-icon"
        description={description}
        descriptionValues={descriptionValues}
        onClick={onClick}
      />
    );
  }

  return (
    <OptionComponent
      id={method.id}
      isSelected={isSelected}
      label="deliveryMethods.notDefinedInfo.check.selectedLabel"
      labelValues={{
        displayName: method.getDisplayName(),
      }}
      icon="icon-check-icon"
      description={description}
      descriptionValues={descriptionValues}
      disabled={disabled}
      onClick={onClick}
    />
  );
}

function CheckDeliverySelectedMethod({ method }: { method: RecordOf<DeliveryMethodType> }) {
  return (
    <SelectedMethod
      icon="icon-check-icon"
      title="bills.form.paymentActivity.scheduledBill.deliveryPaperCheckMethod"
      label="selectMethods.selectedDeliveryMethodLabel"
      labelValues={{
        displayName: method.getDeliveryInfo(''),
      }}
    />
  );
}

CheckDeliveryMethod.methodType = CONSTS.DELIVERY_TYPE.CHECK;
CheckDeliveryMethod.SelectedMethod = CheckDeliverySelectedMethod;
CheckDeliveryMethod.isDisabled = () => false;

export default CheckDeliveryMethod;
