import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
// eslint-disable-next-line import/no-unresolved
import type { StatusInfoType } from 'src/app/utils/types';
import { devices } from 'src/app/theme/AppDevices';
import { PAYMENT_STATUS, PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { SmartIcon } from '@melio/billpay-design-system';

/* Icons */
import exclamationMarkIcon from 'src/app/images/qbo/error-outline.svg';
import paymentScheduledIcon from 'src/app/images/qbo/payment-scheduled.svg';
import paymentPendingIcon from 'src/app/images/qbo/payment-pending.svg';
import paymentDeclinedIcon from 'src/app/images/qbo/payment-declined.svg';

type Props = {
  statusInfo: StatusInfoType;
  className?: string;
};

const QBOBillStatus = ({ statusInfo, className }: Props) => {
  const { color, icon, label, description, descriptionValues, status } = statusInfo;

  const getBillStatusImage = () => {
    const { status } = statusInfo;

    let image;

    switch (status) {
      case PAYMENT_STATUS.FAILED:
        if (icon === 'checked') {
          image = <SmartIcon width="1.5rem" type="checked" color="ds.green.300" />;
        } else if (icon === 'sent') {
          image = <SmartIcon size="2rem" type="sent" />;
        } else {
          image = <Image src={exclamationMarkIcon} />;
        }

        break;
      case PAYMENT_APPROVAL_STATUS.PENDING:
        image = <Image src={paymentPendingIcon} />;
        break;
      case PAYMENT_APPROVAL_STATUS.DECLINED:
        image = <Image src={paymentDeclinedIcon} />;
        break;
      case PAYMENT_STATUS.SCHEDULED:
        image = <Image src={paymentScheduledIcon} />;
        break;
      case PAYMENT_STATUS.IN_PROGRESS:
        image = <SmartIcon width="2rem" type="process" color="ds.cyan.400" />;
        break;
      default:
        break;
    }

    return image;
  };

  return (
    <StatusContainer statusInfo={statusInfo} className={className}>
      {getBillStatusImage() || (
        <Icon color={color} label={label} status={status}>
          {typeof icon === 'string' ? <i className={icon} /> : icon}
        </Icon>
      )}
      <Status color={color} status={status}>
        <MIFormattedText label={label} />
        {description && (
          <StatusDescriptionText>
            <MIFormattedText label={description} values={descriptionValues} />
          </StatusDescriptionText>
        )}
      </Status>
    </StatusContainer>
  );
};

export default QBOBillStatus;

const StatusContainer = styled.div<{ statusInfo?: { colorOpacity?: string } }>`
  display: flex;
  align-items: center;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 3rem;

  @media ${devices.mobile} {
    padding: 0 1.5rem;
  }

  ${(props) => props.theme?.components?.BillStatus?.StatusContainer}

  background-color: ${(props) => props.statusInfo?.colorOpacity};
`;

const Status = styled.div`
  color: ${(props) => props.color};
  padding: 1.2rem 0 1.2rem 1.3rem;
  text-align: left;
  align-self: center;

  @media ${devices.mobile} {
    padding: 1.2rem 0 1.2rem 0.4rem;
  }
  ${(props) => props.theme.text.fontType.medium};
  ${(props) => props.theme?.components?.BillStatus?.Status}
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const Icon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.color};
  margin: 1.9rem 1.1rem 1.5rem 0;
  ${(props) => props.theme?.components?.BillStatus?.Icon}
`;

const StatusDescriptionText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.regular};
  opacity: 0.7;
  ${(props) => props.theme?.components?.BillStatus?.StatusDescriptionText}
`;

const Image = styled.img`
  height: 2rem;
`;
