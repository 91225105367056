import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Modal, Option, Select } from '@melio/billpay-design-system';
import { analytics } from 'src/app/version-2/externals';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { Container } from 'src/app/version-2/pages/batch-bulk/components/AmexVerificationModal/AmexVerificationModal.styles';
import {
  AMEX_INDUSTRY_MODAL_SELECT_CATEGORY,
  BATCH_BULK_EVENT_PAGE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import {
  AmexVerificationValidationErrors,
  validateAmexMccCode,
} from 'src/app/version-2/utils/amex-verification-utils';

interface AmexVerificationModalProps {
  isOpen: boolean;
}

export const AmexVerificationModal = ({ isOpen }: AmexVerificationModalProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const paymentIntent = useSelector(
    batchBulkSelectors.selectAmexVerificationModalBillDTO
  ) as BatchBulkPaymentIntent;
  const allowedMccCodes = useSelector(batchBulkSelectors.selectAllowedMccCodes);
  const { mccIndustries } = allowedMccCodes as { mccIndustries: Option[] };
  const [mccSubIndustries, setMccSubIndustries] = useState<Option[]>([]);
  const [selectedMccIndustry, setSelectedMccIndustry] = useState<Option | undefined>(undefined);
  const [selectedMccSubIndustry, setSelectedMccSubIndustry] = useState<Option | undefined>(
    undefined
  );
  const modalRef = useRef();
  const [isProcessing, setIsProcessing] = useState(false);
  const isMounted = useRef(false);
  const [validationErrors, setValidationErrors] = useState<AmexVerificationValidationErrors>({
    industry: undefined,
    subIndustry: undefined,
  });

  const { companyName } = paymentIntent.payment.vendor;
  const { bills, vendorId } = paymentIntent.payment;
  const partialBillIds = useSelector(
    batchBulkSelectors.selectPartialBillIdsOfPayment(paymentIntent.id)
  );
  const billIds = bills?.map((bill) => bill.id);
  const closeModal = () => {
    dispatch(batchBulkActions.setAmexVerificationModalAsClosed());
  };

  const goLearnMore = () =>
    dispatch(applicationActions.setFaqPanel({ isOpen: true, focusedQuestion: 16 }));

  const handleIndustryChange = ({ target: { value: industry } }) => {
    setMccSubIndustries(allowedMccCodes?.mccSubIndustries[industry] || []);
    const selectedIndustry = mccIndustries.find(
      (industryElement: Option) => industryElement.value === industry
    );

    if (selectedIndustry) {
      setSelectedMccIndustry(selectedIndustry);
    }

    setSelectedMccSubIndustry(undefined);
    analytics.track(BATCH_BULK_EVENT_PAGE, AMEX_INDUSTRY_MODAL_SELECT_CATEGORY, {
      vendorId,
      billIds,
      partialBillIds,
      selectedCategory: selectedIndustry,
    });
  };

  const handleSubIndustryChange = ({ target: { value: subIndustry } }) => {
    const selectedSubIndustry = mccSubIndustries.find(
      (subIndustryElement: Option) => subIndustryElement.value === subIndustry
    );

    if (selectedSubIndustry) setSelectedMccSubIndustry(selectedSubIndustry);
  };

  const validateForm = () => {
    const { validationErrors, isValid } = validateAmexMccCode(
      selectedMccIndustry,
      selectedMccSubIndustry
    );

    setValidationErrors(validationErrors);

    return isValid;
  };

  useEffect(() => {
    if (isMounted.current) {
      validateForm();
    } else {
      isMounted.current = true;
    }
  }, [selectedMccIndustry, selectedMccSubIndustry]);

  const handleSave = () => {
    const isValid = validateForm();

    if (!isValid || !selectedMccSubIndustry) {
      return;
    }

    setIsProcessing(true);
    dispatch(
      batchBulkActions.updateVendorMccCode({
        mccCode: selectedMccSubIndustry.value,
        vendorId,
        paymentIntentId: paymentIntent.id,
      })
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Header>
        <Modal.HeaderTitle>
          {formatMessage({ id: 'batchBulkPage.modals.amexVerification.title' }, { companyName })}
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.CloseButton />
      <Modal.Body unsetModalOverflow>
        <Container>
          <div>
            {formatMessage(
              { id: 'batchBulkPage.modals.amexVerification.info' },
              {
                link: (
                  <Link onClick={goLearnMore}>
                    {formatMessage({ id: 'batchBulkPage.modals.amexVerification.link' })}
                  </Link>
                ),
              }
            )}
          </div>
          <Select
            menuPortalTarget={modalRef.current}
            options={mccIndustries}
            value={selectedMccIndustry}
            onChange={handleIndustryChange}
            errorMessage={
              validationErrors?.industry && formatMessage({ id: validationErrors.industry })
            }
            label={formatMessage({
              id: 'batchBulkPage.modals.amexVerification.approvedCategories.label',
            })}
            placeholder={formatMessage({
              id: 'batchBulkPage.modals.amexVerification.approvedCategories.placeholder',
            })}
          />
          <Select
            menuPortalTarget={modalRef.current}
            options={mccSubIndustries}
            value={selectedMccSubIndustry}
            onChange={handleSubIndustryChange}
            errorMessage={
              validationErrors?.subIndustry && formatMessage({ id: validationErrors.subIndustry })
            }
            label={formatMessage({
              id: 'batchBulkPage.modals.amexVerification.approvedSubCategories.label',
            })}
            placeholder={formatMessage({
              id: 'batchBulkPage.modals.amexVerification.approvedSubCategories.placeholder',
            })}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonContent isCentred>
          <Modal.Button
            isLoading={isProcessing}
            disabled={isProcessing}
            data-testid="modal-button"
            onClick={handleSave}
            size="md"
            variant="primary"
          >
            {formatMessage({ id: 'batchBulkPage.modals.amexVerification.button' })}
          </Modal.Button>
        </Modal.ButtonContent>
      </Modal.Footer>
    </Modal>
  );
};
