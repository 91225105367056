import React from 'react';
import styled from 'styled-components';

// **** created upon intuit's request in order to help them testing rocketman on mobile
const HelloPage = () => {
  const Hello = styled.h1`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return <Hello>Hello from Melio</Hello>;
};

export default HelloPage;
