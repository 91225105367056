import React, { useEffect, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getExpandedRowModel,
  ExpandedState,
} from '@tanstack/react-table';

import classNames from 'classnames';

import {
  TableContainer,
  HeaderRow,
  HeaderCell,
  DataRow,
  DataCell,
  ArrowSortingImage,
} from './AdvancedTable.styles';
import { AdvancedTableProps } from './types';
import { SmartIcon } from '../SmartIcon';
import { SortingEnum } from './enums';

const ArrowSortingIcon = ({ rotate }: { rotate?: boolean }) => (
  <ArrowSortingImage {...(rotate ? { rotate: 180 } : {})}>
    <SmartIcon type="arrow-sorting" size="1.1rem" />
  </ArrowSortingImage>
);

const AdvancedTable = ({
  data,
  columns,
  onMouseOverRow,
  onMouseLeaveRow,
  defaultSorting = [],
  onSorting,
  className,
  getDataRowClassName,
}: AdvancedTableProps) => {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  useEffect(() => {
    setSorting(defaultSorting);
    // defaultSorting can not be used as deps - leads to infinite re-renders.
  }, [defaultSorting?.length]);

  const onSortingHandler = (sortingFn: () => SortingState) => {
    if (!onSorting) {
      setSorting(sortingFn);
      return;
    }

    const prevSorting = sorting?.[0];
    const currSorting = sortingFn()[0];

    if (prevSorting?.desc && !currSorting?.desc) {
      onSorting(currSorting?.id, SortingEnum.DEFAULT);
    } else {
      onSorting(currSorting?.id, currSorting?.desc ? SortingEnum.DESC : SortingEnum.ASC);
    }

    setSorting(sortingFn);
  };

  const table = useReactTable<any>({
    data,
    columns,
    state: {
      sorting,
      expanded,
    },
    onSortingChange: onSorting ? (onSortingHandler as typeof setSorting) : setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <TableContainer className={classNames('table-container', className)}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <HeaderRow key={`header-row-${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <th
                key={`header-${header.id}`}
                colSpan={header.colSpan}
                style={{ width: header.getSize() }}
              >
                <HeaderCell
                  isSortable={header.column.getCanSort()}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {{
                    asc: <ArrowSortingIcon />,
                    desc: <ArrowSortingIcon rotate />,
                  }[header.column.getIsSorted() as string] ?? null}
                </HeaderCell>
              </th>
            ))}
          </HeaderRow>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          const onMouseOver = onMouseOverRow
            ? (event) => onMouseOverRow?.(event, row.original.id)
            : undefined;
          const onMouseLeave = onMouseLeaveRow
            ? (event) => onMouseLeaveRow?.(event, row.original.id)
            : undefined;

          return (
            <DataRow
              key={`row-${row.id}`}
              isSubRow={!!row.depth}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
              className={classNames('row', getDataRowClassName?.(row))}
            >
              {row.getVisibleCells().map((cell) => (
                <DataCell key={`row-${row.id}-cell-${cell.id}`} size={cell.column.getSize()}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </DataCell>
              ))}
            </DataRow>
          );
        })}
      </tbody>
    </TableContainer>
  );
};

export { AdvancedTable };
