import React from 'react';
import { RecordOf } from 'immutable';
import { Box, Flex } from '@melio/billpay-design-system';
import { getDeliveryMethodDisplayName } from 'src/app/pages/vendor/records';
import { DeliveryMethodType, VendorType } from 'src/app/utils/types';
import {
  DELIVERY_TYPE,
  FULL_STORY_MASK_RULE_CLASS,
  HOLIDAYS_WARNING_ICON_VARIANT,
} from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIDotsActionsMenu from 'src/app/components/common/MIDotsActionsMenu';
import { MIInlineLink } from 'src/app/components/common/MIInlineLink';
import { HolidaysWarning } from 'src/app/components/common/HolidaysWarning';
import { featureFlags } from '@melio/shared-web';
import styled from 'styled-components';
import { isSharedVendor } from '../../utils';
import { cardContainerStyles } from './styles';

type Props = {
  vendor: RecordOf<VendorType> | VendorType;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  goEditDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  customDescription?: string | React.ReactNode;
  descriptionValues?: Record<string, any> | undefined;
};

export const AchCheckDeliveryMethodItem = ({
  deliveryMethod,
  vendor,
  goEditDeliveryMethod,
  customDescription,
  descriptionValues,
}: Props) => (
  <Flex __css={containerStyles}>
    <Flex>
      <Icon deliveryMethod={deliveryMethod} />
      <Info
        deliveryMethod={deliveryMethod}
        vendor={vendor}
        customDescription={customDescription}
        descriptionValues={descriptionValues}
      />
    </Flex>
    <Action
      deliveryMethod={deliveryMethod}
      vendor={vendor}
      goEditDeliveryMethod={goEditDeliveryMethod}
    />
  </Flex>
);

const Icon = ({ deliveryMethod }: Pick<Props, 'deliveryMethod'>) => (
  <Box
    as="i"
    fontSize="4rem"
    mr="1.4rem"
    color="gray.500"
    className={
      deliveryMethod.deliveryType === DELIVERY_TYPE.ACH ? 'icon-bank-icon' : 'icon-check-icon'
    }
  />
);

const DeliveryMethodText = styled.span`
  line-break: anywhere;
`;

const Info = ({
  deliveryMethod,
  vendor,
  customDescription,
  descriptionValues,
}: Pick<Props, 'deliveryMethod' | 'vendor' | 'customDescription' | 'descriptionValues'>) => {
  const [showHolidaysWarningLD] = featureFlags.useFeature('us-holidays-checks');

  return (
    <Box>
      <Box color="black">
        <MIFormattedText
          label={`deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.label`}
          values={{
            to: deliveryMethod.id ? <MIFormattedText label="deliveryMethods.to" /> : '',
          }}
        />
        {deliveryMethod.id && (
          <DeliveryMethodText className={FULL_STORY_MASK_RULE_CLASS}>
            {`(${getDeliveryMethodDisplayName({
              deliveryMethod,
              vendorName: vendor.companyName,
              isSharedVendor: isSharedVendor(vendor),
            })})`}
          </DeliveryMethodText>
        )}
        {showHolidaysWarningLD && deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK && (
          <HolidaysWarning
            tooltipLabel="holidaysWarning.tooltipLabels.default"
            variant={
              deliveryMethod?.id
                ? HOLIDAYS_WARNING_ICON_VARIANT.WARNING
                : HOLIDAYS_WARNING_ICON_VARIANT.INFO
            }
            customIconStyles={{ position: 'relative', top: '0.2rem', marginLeft: '0.1rem' }}
          />
        )}
      </Box>
      <Box color="gray.300">
        <MIFormattedText
          label={
            (customDescription as string) ||
            `deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.desc`
          }
          values={descriptionValues}
        />
      </Box>
    </Box>
  );
};

const Action = ({
  deliveryMethod,
  goEditDeliveryMethod,
  vendor,
}: Pick<Props, 'deliveryMethod' | 'goEditDeliveryMethod' | 'vendor'>) => {
  if (deliveryMethod.id) {
    const isEditDisabled = isSharedVendor(vendor);

    if (isEditDisabled) {
      return null;
    }

    const actionOptions = [
      {
        label: 'deliveryMethods.actions.edit',
        action: () => goEditDeliveryMethod(deliveryMethod),
      },
    ];

    return (
      <Flex align="center">
        <MIDotsActionsMenu actionOptions={actionOptions} />
      </Flex>
    );
  }

  return (
    <MIInlineLink
      onClick={() => goEditDeliveryMethod(deliveryMethod)}
      label="deliveryMethods.actions.addMethod"
    />
  );
};

const containerStyles = {
  ...cardContainerStyles,
  justifyContent: 'space-between',
  apply: 'textStyles.body3',
  fontWeight: 500,
};
