import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import PaginationControl from './PaginationControl';

type Props = {
  canPreviousPage?: boolean;
  goToFirstPage: () => void;
  goPreviousPage: () => void;
};

const PaginationPrevious = ({ canPreviousPage, goToFirstPage, goPreviousPage }: Props) => (
  <>
    <PaginationControl onClick={goToFirstPage} disabled={!canPreviousPage}>
      <MIFormattedText label="paymentDashboard.pagination.first" />
    </PaginationControl>
    <PaginationControl onClick={goPreviousPage} disabled={!canPreviousPage}>
      &lt;&nbsp;
      <MIFormattedText label="paymentDashboard.pagination.previous" />
    </PaginationControl>
  </>
);

export default PaginationPrevious;
