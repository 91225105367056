import React from 'react';
import { useSelector } from 'react-redux';
import billsStore from 'src/app/modules/bills/bills-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import { BillType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

export const QBOOpenBalanceCell = ({ item }: Props) => {
  const bill: BillType = useSelector(billsStore.selectors.byId(item.billId));

  return <MIFormattedCurrency value={Number(bill?.balance)} currency="$" />;
};
