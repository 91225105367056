import React, { useEffect } from 'react';
import QBRLayoutPage from 'src/app/components/layout/QBRLayoutPage';
import styled from 'styled-components';
import { CONSTS } from 'src/app/utils/consts';
import ErrorIcon from 'src/app/images/qbr/money-out-logo.svg';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import analytics from 'src/app/services/analytics';
import { useLocation } from 'react-router-dom';

type Props = {
  onClose: () => void;
};

type LocationState = {
  error?: string;
};

const ErrorPage = ({ onClose }: Props) => {
  const location = useLocation<LocationState>();

  useEffect(() => {
    analytics.trackAction('page-viewed', {
      newTrackingConvention: true,
      errorType: location?.state?.error,
    });
  }, []);

  return (
    <QBRLayoutPage
      hideHeader
      isLoading={false}
      nextLabel="Close"
      ctaVariant={CONSTS.BUTTON_VARIANT.PAY}
      fullWidthCTA
      showFooter
      onPrev={onClose}
    >
      <Container data-testid="qbr-error-page">
        <Icon src={ErrorIcon} />
        <Title>
          <MIFormattedText label="qbr.errorPage.title" />
        </Title>
        <Subtitle>
          <MIFormattedText label="qbr.errorPage.subtitle" />
          <MIFormattedText label="qbr.errorPage.subtitle2" />
        </Subtitle>
        <Button>
          <MIButton
            onClick={onClose}
            label="qbr.errorPage.button"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            fullWidth
            data-testid="qbr-error-page-button"
          />
        </Button>
      </Container>
    </QBRLayoutPage>
  );
};

export default ErrorPage;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20rem;
`;

const Title = styled.div`
  margin: 5rem 0 1.5rem 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-align: center;
  color: ${(props) => props.theme.text.color.main};
`;

const Subtitle = styled.div`
  margin-bottom: 4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: ${(props) => props.theme.text.weight.regular};
  text-align: center;
  color: ${(props) => props.theme.text.color.main};
`;

const Icon = styled.img`
  padding: 0;
  height: 11.3rem;
  width: 11.3rem;
`;

const Button = styled.div`
  width: 100%;
  button {
    height: 4.4rem;
  }
`;
