import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getBill, getIsRecurring, getPayment } from 'src/app/redux/payBillWizard/selectors';
import { getPartialBillId } from 'src/app/utils/bills';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import {
  getPaymentById,
  isReturnedCheckPayment,
  isUndepositedPayment,
  isVirtualCardPaymentExpired,
} from 'src/app/utils/payments';
import { PAY_EDIT_LOCATION_NAME } from 'src/app/utils/consts';
import { getOrgId } from 'src/app/redux/user/selectors';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import { extractDeliveryMethodFromVendor } from 'src/app/version-2/utils/deliveryMethod.utils';
import { DeliveryEnum } from 'src/app/version-2/model/enums';
import { NavigateType } from 'src/app/utils/types';
import { usePayBillNavigateCommon } from './usePayBillNavigateCommon';
import { PAY_BILL_EVENT_PAGE } from '../../consts';

type Params = {
  navigate: NavigateType;
};

export const useGoEditPage = ({ navigate }: Params) => {
  const history = useHistory();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const isRecurring = useSelector(getIsRecurring);
  const orgId = useSelector(getOrgId);
  const { getUrl } = usePayBillNavigateCommon();

  const trackEvent = (eventName: string) => {
    analytics.track(PAY_BILL_EVENT_PAGE, eventName, {
      partialBillId: getPartialBillId(bill),
      vendorId: bill?.vendor?.id,
      deliveryMethodId: payment?.deliveryMethod?.id,
    });
  };

  const goEditDeliveryMethod = () => {
    trackEvent('edit-delivery-method');

    const originPayment = getPaymentById(bill.payments, payment.id);
    const isVirtualCardExpired = isVirtualCardPaymentExpired(
      payment,
      originPayment?.deliveryMethod
    );
    const isReturnedCheck = isReturnedCheckPayment(payment);
    const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);

    if (isRecurring) {
      navigate(locations.Bills.pay.recurring.deliveryMethod.url());

      return;
    }

    if (payment?.metadata?.canUserRetry && isVirtualCardExpired) {
      navigate(
        locations.Bills.pay.edit.virtualCardRecovery.url({
          id: bill.id,
          paymentId: payment.id,
        })
      );

      return;
    }

    const isUndepositedCheckPayment = isUndepositedPayment(payment);

    if (isUndepositedCheckPayment) {
      navigate(
        locations.Bills.pay.edit.voidCheck.entry.url({
          id: bill.id,
          paymentId: payment.id,
        })
      );

      return;
    }

    if (isReturnedCheck) {
      navigate(
        locations.Bills.pay.edit.returnedCheckRecovery.entry.url({
          id: bill.id,
          paymentId: payment.id,
        })
      );

      return;
    }

    if (isRetryFailedToDeliver) {
      const deliveryMethod = extractDeliveryMethodFromVendor({
        bill: bill as any,
        type: DeliveryEnum.ACH,
      });

      const redirectUrl = locations.Bills.pay.edit.confirm.url({
        id: bill.id,
        paymentId: payment.id,
        orgId,
      });
      const exitUrl = `${history.location.pathname}${history.location.search}`;

      navigate(
        locations.Vendors.deliveryMethods.ach.edit.url({
          id: bill.vendor?.id,
          deliveryMethodId: deliveryMethod?.id,
          orgId,
        }),
        false,
        { redirectUrl, exitUrl }
      );

      return;
    }

    navigate(getUrl(PAY_EDIT_LOCATION_NAME.DELIVERY_METHOD));
  };

  const goEditFundingSource = () => {
    trackEvent('edit-funding-source');

    if (isRecurring) {
      navigate(locations.Bills.pay.recurring.funding.url());
    } else {
      navigate(getUrl(PAY_EDIT_LOCATION_NAME.FUNDING));
    }
  };

  const goEditPartialAmount = () => {
    trackEvent('edit-partial-amount');

    navigate(getUrl(PAY_EDIT_LOCATION_NAME.FUNDING), false, { editAmount: true });
  };

  const goEditNote = () => {
    trackEvent('edit-note');

    if (isRecurring) {
      navigate(locations.Bills.pay.recurring.memo.url());
    } else {
      navigate(getUrl(PAY_EDIT_LOCATION_NAME.MEMO));
    }
  };

  const goEditDate = () => {
    trackEvent('edit-date');

    navigate(getUrl(PAY_EDIT_LOCATION_NAME.DATE));
  };

  return {
    goEditDeliveryMethod,
    goEditFundingSource,
    goEditPartialAmount,
    goEditDate,
    goEditNote,
  };
};
