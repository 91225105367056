import { CardTypeEnum, FundingSourceOrigins } from 'src/app/version-2/model/enums';

export const QB_CASH_LABEL = 'QB Checking';

export const PAYMENT_METHODS_ORDER = {
  [FundingSourceOrigins.QBCASH]: 0,
  [CardTypeEnum.CREDIT]: 1,
  [FundingSourceOrigins.PLAID]: 2,
  [FundingSourceOrigins.MANUAL]: 3,
  [CardTypeEnum.DEBIT]: 4,
};
