import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { RefundReasonEnum } from 'src/app/version-2/pages/refund/model/reason.enum';
import { RefundStatusEnum } from 'src/app/version-2/pages/refund/model/status.enum';

export const REFUND_SLICE = `${VERSION_2}refund`;

export const selectState = (state: any) => state[REFUND_SLICE] as RefundSlice;

export interface RefundSlice {
  status: RefundStatusEnum;
  loading: boolean;
}

export const getRefundInitialState = (): RefundSlice => ({
  status: RefundStatusEnum.UNKNOWN,
  loading: false,
});

export const refundSlice = createSlice({
  name: REFUND_SLICE,
  initialState: getRefundInitialState(),
  reducers: {
    clearRefundStatus: (state) => {
      state.status = RefundStatusEnum.UNKNOWN;
    },
    setRefundStatus: (state, action: PayloadAction<RefundStatusEnum>) => {
      state.status = action.payload;
    },
    refundPaymentSuccess: (state) => {
      state.status = RefundStatusEnum.OK;
    },
    refundPaymentFailed: (state) => {
      state.status = RefundStatusEnum.ERR_FAILED_REFUND;
    },
    setRefundLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const refundActions = {
  ...refundSlice.actions,
  createRefundPayment: createAction<{ reason: RefundReasonEnum; paymentId: string }>(
    `${REFUND_SLICE}/refund`
  ),
};

const selectRefundStatus = createSelector(selectState, (state) => state.status);
const selectRefundLoading = createSelector(selectState, (state) => state.loading);

export const refundSelectors = {
  selectRefundStatus,
  selectRefundLoading,
};
