import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useForm } from 'src/app/ui/form';
import { beginRecurringPayBillFlowAction } from 'src/app/redux/payBillWizard/actions';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';

// SELECTORS
import { getOrgId } from 'src/app/redux/user/selectors';

// APIS
import { useApi } from 'src/app/hoc/useApi';
import vendorsApi from 'src/app/services/api/vendors';
import organizationsApi from 'src/app/services/api/organizations';
import billsApi from 'src/app/services/api/bills';
import { BillRecord } from 'src/app/pages/bill/records';
import { DELIVERY_TYPE, BILL_STATUS } from 'src/app/utils/consts';
import { prepareRecurringObject, hasRecurringFrequency } from 'src/app/utils/payments';
import ValidationError from 'src/app/ui/ValidationError';
import analytics from 'src/app/services/analytics';

// 3rd party libs
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

// Types
import { SelectFieldOption } from 'src/app/ui/form/WizardSelectField';
import { BillFrequencyEnum } from 'src/app/version-2/model/enums';

// Utils
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { validateMultiFields } from 'src/app/utils/form-utils';
import { isNewVendor } from 'src/app/utils/vendors';
import { MIFormattedText, MIFormattedCurrency } from 'src/app/utils/formatting';
import locations from 'src/app/utils/locations';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { OrganizationPreferencesType, VendorType } from 'src/app/utils/types';
import { loggingApi } from 'src/app/version-2/api/loggers';
import {
  extractAndMapVendorOptions,
  mapCategoriesToOptions,
  mapBillToFormModel,
  calcTotalPaymentsMade,
  BILL_MODAL_FAILURE_TYPES,
  ANALYTICS_PAGE_NAME,
  extractAndMapInternationalVendors,
  scrollToDateIfNeeded,
} from '../utils';

type ModelType = {
  vendorId?: string;
  intuitAccountId?: string;
  totalAmount?: any;
  invoiceNumber?: string;
  dueDate?: string;
  note?: string;
  billId?: number | null;
  balance?: number | null;
  frequency?: string;
  occurrences?: number;
};

export enum MODEL_KEYS {
  VENDOR_ID = 'vendorId',
  INTUIT_ACCOUNT_ID = 'intuitAccountId',
  TOTAL_AMOUNT = 'totalAmount',
  INVOICE_NUMBER = 'invoiceNumber',
  DUE_DATE = 'dueDate',
  NOTE = 'note',
  BILL_ID = 'billId',
  BALANCE = 'balance',
  FREQUENCY = 'frequency',
  OCCURRENCES = 'occurrences',
}

type OptionType = {
  id?: string;
  value?: string;
  label?: string;
  meta?: any;
};

type BillSyncResultType = {
  bill: {
    id: number;
    originId: string;
  };
};

const AMOUNT_LIMITS = {
  MIN: 0,
  MAX: 1000000,
};

const wasBillSynced = (syncResponse) => {
  const { bill } = syncResponse;

  if (bill?.id > 0 && bill?.originId > 0) return true;

  return false;
};

export const BILL_MODAL_PREFIX = 'paymentDashboard.addBillToDashboard.billModal';
export const ERROR_PREFIX = `${BILL_MODAL_PREFIX}.inputErrors`;

export const useBillModalState = ({
  onBillSavedAndSynced,
  onEditBillSaved,
  onFailure,
  billId,
  isInternationalEntrypoint = false,
  setIsMainDataLoading,
}) => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const [vendorOptions, setVendorOptions] = useState<SelectFieldOption<string>[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<OptionType[]>([]);
  const [lastChosenCategory, setLastChosenCategory] = useState<OptionType>({});
  const [newBill, setNewBill] = useState<ModelType>({});
  const [totalPaymentsMade, setTotalPaymentsMade] = useState(0);
  const [getVendors] = useApi(vendorsApi.getVendors, false);
  const [getCategoriesForBill] = useApi(organizationsApi.getAccountsForBill, false);
  const [getBills] = useApi(billsApi.getBills);
  const [createBill] = useApi(billsApi.createBill);
  const [editBillById] = useApi(billsApi.editBillById);
  const [getBillById] = useApi(billsApi.getBillById);
  const [createVendor] = useApi(vendorsApi.createVendor);
  const [runBillSync] = useApi<[number, string], BillSyncResultType>(organizationsApi.runBillSync);
  const isEditBillMode = !!billId;
  const [historyPush] = useHistoryWithOrgId();
  const { checkVendorPaymentPreferences } = useStoreActions(vendorsStore);
  const location = useLocation();
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );

  const model = useMemo(
    () =>
      ({
        [MODEL_KEYS.VENDOR_ID]: newBill[MODEL_KEYS.VENDOR_ID] || null,
        [MODEL_KEYS.INTUIT_ACCOUNT_ID]: newBill[MODEL_KEYS.INTUIT_ACCOUNT_ID] || '',
        [MODEL_KEYS.TOTAL_AMOUNT]: newBill[MODEL_KEYS.TOTAL_AMOUNT] || '',
        [MODEL_KEYS.INVOICE_NUMBER]: newBill[MODEL_KEYS.INVOICE_NUMBER] || '',
        [MODEL_KEYS.DUE_DATE]: newBill[MODEL_KEYS.DUE_DATE] || '',
        [MODEL_KEYS.NOTE]: newBill[MODEL_KEYS.NOTE] || '',
        [MODEL_KEYS.BILL_ID]: newBill[MODEL_KEYS.BILL_ID] || null,
        [MODEL_KEYS.BALANCE]: newBill[MODEL_KEYS.BALANCE] || newBill[MODEL_KEYS.TOTAL_AMOUNT] || '',
        [MODEL_KEYS.FREQUENCY]: newBill[MODEL_KEYS.FREQUENCY] || BillFrequencyEnum.ONE_TIME,
        [MODEL_KEYS.OCCURRENCES]: newBill[MODEL_KEYS.OCCURRENCES],
      } as ModelType),
    [newBill]
  );

  useEffect(() => {
    const getBill = (billId) => {
      if (billId) return getBillById({ orgId, id: billId });

      return null;
    };

    const fetchAllData = async () => {
      setIsMainDataLoading(true);
      const [vendorResponse, categoryResponse, billResponse] = await Promise.all([
        getVendors({ orgId }),
        getCategoriesForBill(orgId),
        getBill(billId),
      ]);
      // to differentiate between regular bill flow and international dashboard entry point bill flow
      const extractAndMap = isInternationalEntrypoint
        ? extractAndMapInternationalVendors
        : extractAndMapVendorOptions;

      if (vendorResponse) setVendorOptions(extractAndMap(vendorResponse));

      if (categoryResponse) setCategoryOptions(mapCategoriesToOptions(categoryResponse));

      if (billResponse) {
        const newBill = mapBillToFormModel(billResponse?.object);

        setTotalPaymentsMade(
          calcTotalPaymentsMade(isEditBillMode, newBill?.totalAmount, newBill?.balance)
        );
        setNewBill(newBill);
      }

      setIsMainDataLoading(false);
    };

    fetchAllData();
  }, [orgId]);

  useEffect(() => {
    let lastCategoryValue = '';

    if (lastChosenCategory?.value) {
      lastCategoryValue = lastChosenCategory.value;
    }

    setNewBill({ ...newBill, [MODEL_KEYS.INTUIT_ACCOUNT_ID]: lastCategoryValue });
  }, [lastChosenCategory]);

  const getFieldsValidations = (values) => [
    {
      name: 'vendorId',
      value: values.vendorId.value,
      isRequired: true,
    },
    {
      name: 'totalAmount',
      value: values.totalAmount.value?.toString(),
      isRequired: true,
      customCheck: (value) => {
        const billAmount = toNumber(convertCurrencyToNumber(value));

        if (!billAmount && billAmount !== 0) return false;

        if (isEditBillMode && billAmount < totalPaymentsMade)
          return (
            <>
              <MIFormattedText label={`${ERROR_PREFIX}.totalAmount.partial`} />
              (<MIFormattedCurrency value={totalPaymentsMade} />)
            </>
          );

        if (billAmount === AMOUNT_LIMITS.MIN) return `${ERROR_PREFIX}.totalAmount.min`;

        if (billAmount >= AMOUNT_LIMITS.MAX) return `${ERROR_PREFIX}.totalAmount.max`;

        return false;
      },
    },
    {
      name: 'invoiceNumber',
      value: values.invoiceNumber.value,
    },
    {
      name: 'dueDate',
      value: values.dueDate.value,
      isRequired: true,
      customCheck: (value) => {
        const isRecurring = hasRecurringFrequency(values[MODEL_KEYS.FREQUENCY]?.value);

        if (value) return false;

        return `${ERROR_PREFIX}.dueDate.${isRecurring ? 'recurringEmpty' : 'empty'}`;
      },
    },
    {
      name: 'note',
      value: values.note.value,
    },
  ];

  const getRecurringFieldsValidations = (values) => [
    {
      name: 'occurrences',
      value: values.occurrences.value,
      isRequired: true,
      customCheck: (value) => {
        const frequency = values[MODEL_KEYS.FREQUENCY].value;

        if (
          !value ||
          value <= 0 ||
          (frequency === BillFrequencyEnum.MONTHLY && value > 24) ||
          (frequency === BillFrequencyEnum.WEEKLY && value > 60)
        ) {
          return `paymentDashboard.addBillToDashboard.billModal.inputErrors.occurrences.${frequency}`;
        }

        return false;
      },
    },
  ];

  const validateForm = (): { isValid: boolean; validationErrors: Record<string, any> } => {
    const isRecurring = hasRecurringFrequency(billModalMV[MODEL_KEYS.FREQUENCY]?.value);
    const validations = getFieldsValidations(billModalMV);
    const validateFields = isRecurring
      ? [...validations, ...getRecurringFieldsValidations(billModalMV)]
      : validations;
    const validationErrors = validateMultiFields(validateFields, ERROR_PREFIX);

    return {
      isValid: Object.values(validationErrors).every((field) => isEmpty(field)),
      validationErrors,
    };
  };

  const createNewBillRecord = (values: ModelType) =>
    BillRecord({
      vendorId: toNumber(values.vendorId),
      ...(values.intuitAccountId && { intuitAccountId: values.intuitAccountId }),
      totalAmount: toNumber(convertCurrencyToNumber(values.totalAmount)),
      ...(values.invoiceNumber && { invoiceNumber: values.invoiceNumber }),
      dueDate: values.dueDate,
      ...(values.note && { note: values.note }),
      status: BILL_STATUS.UNPAID,
      balance: toNumber(convertCurrencyToNumber(values.totalAmount)),
    });

  const createEditBillObject = (values: ModelType) => ({
    vendorId: toNumber(values.vendorId),
    ...(values.intuitAccountId && { intuitAccountId: values.intuitAccountId }),
    totalAmount: toNumber(convertCurrencyToNumber(values.totalAmount)),
    ...(values.invoiceNumber && { invoiceNumber: values.invoiceNumber }),
    dueDate: values.dueDate,
    ...(values.note && { note: values.note }),
  });

  const buildParamsObj = (orgId, vendorId) => ({
    orgId,
    filters: { start: 0, limit: 1, sorting: 'createdAt:DESC', vendorId },
  });

  const getLastCreatedBillOfVendor = (orgId, vendorId) => getBills(buildParamsObj(orgId, vendorId));

  const getLastCategoryIdByBill = (bill) => get(bill, 'objects[0].intuitAccountId');

  const getLastCategoryObj = (categoryId) =>
    find(categoryOptions, (category) => category.id === categoryId) || {};

  const [billModalMV, billModalMVActions, loading] = useForm<ModelType>(model, {
    submit: async (billModel: ModelType) => {
      const isRecurring = hasRecurringFrequency(billModel[MODEL_KEYS.FREQUENCY]);

      const { isValid, validationErrors } = validateForm();

      if (!isValid) {
        onFailure(BILL_MODAL_FAILURE_TYPES.VALIDATION);
        scrollToDateIfNeeded({ isRecurring, validationErrors });

        throw new ValidationError({ validationErrors });
      }

      let vendorId = billModel?.vendorId;

      try {
        loggingApi.info('internationalEntryPoint.submit(): payment init');

        const isNewVendorId = isNewVendor(vendorOptions, vendorId);

        if (isNewVendorId) {
          const {
            object: { id },
          } = await createVendor(orgId, { companyName: vendorId });

          vendorId = id;
        }

        if (isRecurring) {
          // getting full vendor object to know if he has previous delivery methods for next step
          const { object: vendor } = await vendorsApi.getVendorById({ orgId, id: vendorId });
          const { payload } = await checkVendorPaymentPreferences({ orgId, id: vendorId });

          if (payload?.blockPayments) return true;

          const recurringFlow = prepareRecurringObject({ bill: billModel, vendor });

          dispatch(
            beginRecurringPayBillFlowAction(
              recurringFlow.bill,
              recurringFlow.recurringBill,
              location.pathname
            )
          );
          analytics.track(`bill-create-recurring`, 'save-success');

          // go to recurring flow
          historyPush({
            path: locations.Bills.pay.recurring.funding.url(
              isInternationalEntrypoint && {
                international: 'international',
              }
            ),
          });

          return true;
        }

        const response = isEditBillMode
          ? await editBillById(
              orgId,
              billModel.billId,
              createEditBillObject({ ...billModel, vendorId }),
              null
            )
          : await createBill(orgId, createNewBillRecord({ ...billModel, vendorId }));

        const billIdRes = response?.object?.id;

        if (!isEditBillMode) {
          if (billIdRes) {
            const syncResponse = await runBillSync(orgId as unknown as number, billIdRes);

            if (wasBillSynced(syncResponse)) {
              onBillSavedAndSynced({
                billId: billIdRes,
                vendorId: vendorId?.toString(),
                isNewVendor: isNewVendorId,
              });
              setNewBill({});
            }
          }
        } else if (billIdRes) {
          onEditBillSaved(billIdRes);
          setNewBill({});
        }
      } catch (e) {
        const loggerMessage = isInternationalEntrypoint
          ? 'internationalEntryPoint.submit(): payment failed'
          : 'billModal.submit(): payment failed';

        loggingApi.error(loggerMessage, { errorLog: e });
        onFailure(BILL_MODAL_FAILURE_TYPES.API);

        return false;
      }

      return true;
    },
    onChange: ({ key, id, value, modelState }) => {
      setNewBill({ ...newBill, [key]: value });

      const changeCases = {
        [MODEL_KEYS.VENDOR_ID]: () => {
          const isNewVendorId = isNewVendor(vendorOptions, id);

          if (!isNewVendorId) {
            getLastCreatedBillOfVendor(orgId, id)
              .then(getLastCategoryIdByBill)
              .then(getLastCategoryObj)
              .then(setLastChosenCategory);
          }

          const analyticsOptions = isNewVendorId ? {} : { vendorId: id };
          const analyticsMessage = isNewVendorId ? 'add-new-vendor' : 'select-existing-vendor';

          analytics.track(ANALYTICS_PAGE_NAME, analyticsMessage, analyticsOptions);
        },
      };

      if (changeCases[key]) {
        changeCases[key]();
      }

      return modelState;
    },
  });

  const selectedVendor: VendorType = useSelector(
    vendorsStore.selectors.byId(billModalMV.vendorId.value)
  );
  const isVendorWithInternationalDM = selectedVendor?.deliveryMethods?.find(
    (dm) => dm.deliveryType === DELIVERY_TYPE.INTERNATIONAL
  );
  const isInternationalVendorDisable =
    isVendorWithInternationalDM && !organizationPreferences.isEligibleForInternationalPayment;

  return {
    orgId,
    vendorOptions,
    billModalMV,
    billModalMVActions,
    categoryOptions,
    isLoading: loading,
    disableTooltip:
      isInternationalVendorDisable && `${BILL_MODAL_PREFIX}.tooltips.inEligibleForInternational`,
  };
};
