import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SmartLink } from 'src/app/modules/navigation/components/SmartLink';
import intercomService from 'src/app/services/intercom';
import { getOrgId } from 'src/app/redux/user/selectors';
import { applicationActions } from 'src/app/version-2/modules/application/application.slice';
import { MIFormattedText } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import locations from '../../utils/locations';
import QBODrawer from './QBODrawer';
import analytics from '../../services/analytics';
import { useBreak } from '../../hoc';
import { melioClose } from '../../utils/external-events';

interface Props {
  goExit?: () => void;
  onPrev?: () => void | null;
  headerLabel?: string;
  headerLabelValues?: Record<string, React.ReactNode>;
  settingsComponent?: React.ReactNode;
  goToSettings?: () => void;
}

const eventPage = 'qbo';

export const QBOHeader = ({
  onPrev,
  goExit,
  headerLabel,
  headerLabelValues,
  settingsComponent,
  goToSettings,
}: Props) => {
  const dispatch = useDispatch();
  const [isHeaderClosed, setIsHeaderClosed] = useState(false);
  const orgId = useSelector(getOrgId);
  const { isMobile } = useBreak();
  const history = useHistory();
  const onPrevHandler = () => {
    analytics.track(`${eventPage}-pay-bill-flow`, 'view-prev-page');

    if (onPrev) {
      onPrev();
    }
  };

  const onSettingsClick = () => {
    const { pathname, search } = history.location;
    const url = `${pathname}${search}`;

    dispatch(applicationActions.setUrlToBack(url));

    if (goToSettings) goToSettings();

    analytics.track(eventPage, 'settings');
  };

  const onDrawerClose = () => {
    analytics.track(eventPage, 'need-help-close');
  };

  const openDrawer = () => {
    dispatch(applicationActions.setFaqPanel({ isOpen: true }));
    analytics.track(eventPage, 'need-help-open');
  };

  const openSupportChat = () => {
    analytics.track(eventPage, 'support-open-start-chat');
    intercomService.show();
  };

  const closeIframe = () => {
    analytics.track(`${eventPage}-pay-bill-flow`, 'close-integration');
    setIsHeaderClosed(true);

    if (goExit) {
      goExit();
    } else {
      melioClose();
    }
  };

  const renderSettings = () => {
    if (settingsComponent) return settingsComponent;

    const Content = () => (
      <>
        <HeaderContentIcon className="icon-settings-icon" />
        <ButtonText>
          <MIFormattedText label="qbo.header.settings" />
        </ButtonText>
      </>
    );

    if (goToSettings) {
      return (
        <Link onClick={onSettingsClick}>
          <Content />
        </Link>
      );
    }

    return (
      <SettingsLink
        to={locations.Settings.index.url({ orgId })}
        onClick={onSettingsClick}
        preserveState
      >
        <Content />
      </SettingsLink>
    );
  };

  return (
    <>
      <QBOHeaderContainer isBottomBorder={headerLabel}>
        <HeaderContent>
          {onPrev && (
            <Back onClick={onPrevHandler}>
              <i className="icon-back-icon" />
            </Back>
          )}
          {headerLabel && !isHeaderClosed && (
            <HeaderText data-testid="qbo-header-text">
              <MIFormattedText
                label={headerLabel}
                values={{
                  breakLine: isMobile ? <br /> : <></>,
                  ...headerLabelValues,
                }}
              />
            </HeaderText>
          )}
        </HeaderContent>
        <HeaderButtonContent flexEnd={!onPrev}>
          {onPrev && (
            <BackMobile onClick={onPrevHandler}>
              <i className="icon-back-arrow" />
            </BackMobile>
          )}
          <ButtonContainer>
            <ButtonText>
              <HelpText onClick={openSupportChat}>
                <HeaderContentIcon className="icon-support-icon-streak" />
                <ButtonText>
                  <MIFormattedText label="qbo.header.support" />
                </ButtonText>
              </HelpText>
            </ButtonText>

            <HelpText onClick={openDrawer}>
              <HeaderContentIcon className="icon-help-icon" />
              <ButtonText>
                <MIFormattedText label="qbo.header.help" />
              </ButtonText>
            </HelpText>

            {renderSettings()}

            {goExit && (
              <Close data-testid="qbo-close-page" onClick={closeIframe}>
                <i className="icon-close-icon" />
              </Close>
            )}
          </ButtonContainer>
        </HeaderButtonContent>
      </QBOHeaderContainer>
      <QBODrawer onCloseCallback={onDrawerClose} />
    </>
  );
};

const commonStyles = css`
  display: flex;
  align-items: center;
`;

export const QBOHeaderContainer = styled.div<{ isBottomBorder?: string }>`
  ${commonStyles};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  height: 5rem;
  background-color: rgba(244, 245, 248);
  color: ${(props) => props.theme.text.color.main};
  border-bottom: ${(props) => (props.isBottomBorder ? '0.2rem solid rgba(216, 216, 216)' : 'none')};
  font-size: 1.4rem;
  padding: 0 1.4rem;

  @media ${devices.mobile} {
    font-size: 1.2rem;
    height: auto;
    background: ${(props) => props.theme.colors.white.opaque};
    flex-direction: column-reverse;
    padding: 1.6rem;
    ${(props) => props.theme?.components?.QBOHeaderContainerClass?.QBOHeader}
  }
`;

const HeaderContent = styled.div`
  ${commonStyles};
  ${(props) => props.theme?.components?.QBOHeaderContainerClass?.HeaderContent}
`;

const HeaderButtonContent = styled.div<{ flexEnd?: boolean }>`
  ${commonStyles};
  display: flex;
  @media ${devices.mobile} {
    align-self: flex-end;
    justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'space-between')};
    width: inherit;
    ${(props) => props.theme?.components?.QBOHeaderContainerClass?.HeaderButtonContent}
  }
`;

const HeaderText = styled.div`
  strong {
    font-weight: 600;
  }
  @media ${devices.mobile} {
    text-align: center;
    font-size: ${({ theme }) => theme.text.size.regular};
  }
`;

export const HeaderContentIcon = styled.i`
  color: rgba(107, 108, 114, 1);
  font-size: 2.4rem;
  margin-right: 1rem;
  @media ${devices.mobile} {
    color: ${(props) => props.theme.colors.brand};
    font-size: 2.8rem;
  }
`;

const HelpText = styled.div`
  ${commonStyles};
  cursor: pointer;
  margin-right: 2rem;
  @media ${devices.mobile} {
    margin: 0;
  }
`;

export const ButtonText = styled.div`
  @media ${devices.mobile} {
    display: none;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
`;

export const settingsStyles = css`
  ${commonStyles};
  margin-right: 3.6rem;
  text-decoration: none;
  border: none;
  color: ${(props) => props.theme.colors.text};
  &:active {
    color: ${(props) => props.theme.colors.text};
  }
  @media ${devices.mobile} {
    display: none;
  }
`;

export const SettingsLink = styled(SmartLink)`
  ${settingsStyles}
`;

export const Link = styled.div`
  ${settingsStyles}
  cursor: pointer;
`;

const navIconStyles = css`
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  color: ${(props) => props.theme.text.color.label};
  cursor: pointer;
  @media ${devices.mobile}, ${devices.phablet} {
    font-size: 2.8rem;
    width: 2.8rem;
    height: 2.8rem;
  }
`;

const Close = styled.div`
  ${navIconStyles};
  @media ${devices.mobile} {
    color: ${(props) => props.theme.colors.brand};
    ${(props) => props.theme?.components?.QBOHeaderContainerClass?.Close}
  }
`;

const Back = styled.div`
  ${navIconStyles};
  padding-right: 1.4rem;
  @media ${devices.mobile} {
    display: none;
  }
`;

export const BackMobile = styled.div`
  display: none;
  @media ${devices.mobile} {
    display: block;
    ${navIconStyles};
    color: ${(props) => props.theme.colors.brand};
    min-width: 7.6rem;
  }
  ${(props) => props.theme?.components?.QBOElements?.BackMobile}
`;
