const LOCATIONS = {
  base: [
    '/orgs/:orgId/bills/pay/recurring',
    '/orgs/:orgId/bills/pay/background-sync-entry',
    '/orgs/:orgId/bills/:billId/payment/:paymentId/edit',
    '/orgs/:orgId/bills/:billId/pay',
  ],
  recurring: {
    funding: '/orgs/:orgId/bills/pay/recurring/funding',
    deliveryMethod: '/orgs/:orgId/bills/pay/recurring/delivery-method',
    memo: '/orgs/:orgId/bills/pay/recurring/memo',
    confirm: '/orgs/:orgId/bills/pay/recurring/confirm',
    success: '/orgs/:orgId/bills/pay/recurring/success',
    completeLegalInfo: '/orgs/:orgId/bills/pay/recurring/complete-legal-info',
  },
  schedule: {
    backgroundSyncEntry: '/orgs/:orgId/bills/pay/background-sync-entry',
    entry: '/orgs/:orgId/bills/:billId/pay',
    amount: '/orgs/:orgId/bills/:billId/pay/amount',
    deliveryMethod: '/orgs/:orgId/bills/:billId/pay/delivery-method',
    date: '/orgs/:orgId/bills/:billId/pay/date',
    funding: '/orgs/:orgId/bills/:billId/pay/funding',
    confirm: '/orgs/:orgId/bills/:billId/pay/confirm',
    memo: '/orgs/:orgId/bills/:billId/pay/memo',
    expedite: '/orgs/:orgId/bills/:billId/pay/expedite',
    success: '/orgs/:orgId/bills/:billId/pay/success',
    completeLegalInfo: '/orgs/:orgId/bills/:billId/pay/complete-legal-info',
  },
  edit: {
    deliveryMethod: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/delivery-method',
    date: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/date',
    funding: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/funding',
    confirm: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/confirm',
    memo: '/orgs/:orgId/bills/:billId/pay/payment/:paymentId/edit/memo',
  },
};

export default LOCATIONS;
