import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { formatCheckPrintName } from 'src/app/utils/delivery-methods';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { getMaxlength } from '@melio/sizzers-js-common';
import { FieldType } from 'src/app/utils/types';
import { REGEX } from 'src/app/utils/consts';
import { MITextInput } from 'src/app/components/common/MITextInput';

type Props = {
  onNext: (arg0: string) => void;
  goExit: () => void;
  printName: string;
  isLoading: boolean;
  validationErrors: Record<'printName', string>;
  getQBvendorInfo: () => void;
};

const EditCheckNameDeliveryMethodPage = ({
  onNext,
  goExit,
  printName,
  isLoading,
  validationErrors,
  getQBvendorInfo,
}: Props) => {
  const site = useSiteContext();
  const { StepLayout } = site.components;
  const [currentPrintName, changeCurrentPrintName] = useState(formatCheckPrintName(printName));
  const [isInvalidChar, setIsInvalidChar] = useState(false);
  const errorMessage = isInvalidChar
    ? 'inputErrors.deliveryMethodCheck.printName.any.invalidChar'
    : validationErrors.printName;

  const onChange = ({ value }: FieldType) => {
    const isInvalid = REGEX.CHECK_PRINT_NAME.test(value);

    setIsInvalidChar(isInvalid);

    if (isInvalid) {
      const formattedValue = value.replace(REGEX.CHECK_PRINT_NAME, '');

      changeCurrentPrintName(formattedValue);
    } else {
      changeCurrentPrintName(value);
    }
  };

  const onSubmit = () => {
    onNext(formatCheckPrintName(currentPrintName));
  };

  useEffect(() => {
    getQBvendorInfo();
  }, [getQBvendorInfo]);

  return (
    <StepLayout
      title="vendors.deliveryMethods.check.name.title"
      goExit={goExit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      hideHeader
    >
      <TextInputContainer>
        <MITextInput
          id="printName"
          value={currentPrintName}
          label="vendors.deliveryMethods.check.name.label"
          autoFocus
          onChange={onChange}
          required
          errorMessage={errorMessage}
          autocomplete="off"
          notices={['vendors.deliveryMethods.check.name.notice']}
          maxlength={getMaxlength('deliveryMethodCheck', 'printName')}
        />
      </TextInputContainer>
    </StepLayout>
  );
};

export default EditCheckNameDeliveryMethodPage;

const TextInputContainer = styled.div`
  & input {
    text-transform: uppercase;
  }
`;
