import { Bank, DeliveryMethod, FundingSource } from 'src/app/version-2/model/dtos';
import {
  CardNetworkEnum,
  CardTypeEnum,
  DeliveryEnum,
  DeliveryMethodEligibilityStatusEnum,
  FundingSourceOrigins,
  FundingSourceTypesEnum,
  InstitutionNamesEnum,
  PaymentMethodsIconsEnum,
  PaymentMethodsSmartIconsTypesEnum,
} from 'src/app/version-2/model/enums';
import { paymentMethodAdapter } from 'src/app/version-2/pages/batch-bulk/adapters';
import {
  PAYMENT_METHODS_ORDER,
  QB_CASH_LABEL,
} from 'src/app/version-2/pages/batch-bulk/model/consts/paymentMethods.consts';
import { FundingSourceItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { getDeliveryMethodEligibilityStatus } from '../pages/batch-bulk/utils/deliveryMethod.utils';

interface PreSelectedPaymentMethodParams {
  defaultPaymentMethod?: FundingSource;
}

interface GetDefaultPaymentMethodParams {
  fundingSources?: FundingSource[];
  fundingSourceId?: number;
  deliveryMethod?: DeliveryMethod;
}

interface PreSelectedPaymentMethodsParams {
  fundingSources?: FundingSource[];
  selectedDeliveryMethod?: DeliveryMethod;
  fundingSource?: FundingSource;
}

const getSmartIconTypeByNetworkName = (networkName: string) => {
  switch (networkName) {
    case CardNetworkEnum.AMEX:
      return PaymentMethodsSmartIconsTypesEnum.AMEX;
    case CardNetworkEnum.DINERS:
      return PaymentMethodsSmartIconsTypesEnum.DINERS;
    case CardNetworkEnum.MASTERCARD:
    case CardNetworkEnum.MASTERCARD_PASCAL:
      return PaymentMethodsSmartIconsTypesEnum.MASTERCARD;
    case CardNetworkEnum.VISA:
      return PaymentMethodsSmartIconsTypesEnum.VISA;
    default:
      return '';
  }
};

const getSmartIconTypeByInstitutionName = (institutionName: string) => {
  if (institutionName.indexOf(InstitutionNamesEnum.BANK_OF_AMERICA) !== -1)
    return PaymentMethodsSmartIconsTypesEnum.BANK_OF_AMERICA;

  if (institutionName.indexOf(InstitutionNamesEnum.CHASE) !== -1)
    return PaymentMethodsSmartIconsTypesEnum.CHASE;

  if (institutionName.indexOf(InstitutionNamesEnum.CITI_BANK) !== -1)
    return PaymentMethodsSmartIconsTypesEnum.CITI_BANK;

  if (institutionName.indexOf(InstitutionNamesEnum.FIFTH_THIRD_BANK) !== -1)
    return PaymentMethodsSmartIconsTypesEnum.FIFTH_THIRD_BANK;

  if (institutionName.indexOf(InstitutionNamesEnum.SILICON_VALLEY_BANK) !== -1)
    return PaymentMethodsSmartIconsTypesEnum.SILICON_VALLEY_BANK;

  if (institutionName.indexOf(InstitutionNamesEnum.US_BANK) !== -1)
    return PaymentMethodsSmartIconsTypesEnum.US_BANK;

  if (institutionName.indexOf(InstitutionNamesEnum.WELLS_FARGO) !== -1)
    return PaymentMethodsSmartIconsTypesEnum.WELLS_FARGO;

  return PaymentMethodsSmartIconsTypesEnum.BANK;
};

const extractInstitutionNameFromFundingSource = (fundingSource: FundingSourceItem) =>
  fundingSource?.plaidAccount?.plaidItem?.institutionName || '';

const getSmartIconTypeForACH = (fundingSourceItem: FundingSourceItem) => {
  if (
    fundingSourceItem?.fundingType === FundingSourceTypesEnum.CARD &&
    fundingSourceItem?.cardAccount?.cardType === CardTypeEnum.DEBIT
  )
    return PaymentMethodsSmartIconsTypesEnum.BANK;

  if (fundingSourceItem?.origin === FundingSourceOrigins.QBCASH)
    return PaymentMethodsSmartIconsTypesEnum.QB_CASH;

  const institutionName = extractInstitutionNameFromFundingSource(fundingSourceItem);
  const institutionNameSmartIconType = getSmartIconTypeByInstitutionName(institutionName);

  return institutionNameSmartIconType;
};

const isCreditCardMethod = (method: FundingSource) =>
  method?.fundingType === FundingSourceTypesEnum.CARD &&
  method.cardAccount?.cardType === CardTypeEnum.CREDIT;

const isDebitCardMethod = (fundingSource?: FundingSource) =>
  fundingSource?.cardAccount?.cardType === CardTypeEnum.DEBIT;

export const getPaymentMethodsSmartIconsType = (method: FundingSourceItem) => {
  const isCreditCard = isCreditCardMethod(method);
  const isDebitCard = isDebitCardMethod(method);

  if (isCreditCard || isDebitCard) {
    const networkName = method?.cardAccount?.network || '';
    const cardNetworkSmartIconType = getSmartIconTypeByNetworkName(networkName);

    if (!cardNetworkSmartIconType && isCreditCard)
      return PaymentMethodsSmartIconsTypesEnum.CREDIT_CARD;

    if (!cardNetworkSmartIconType && isDebitCard)
      return PaymentMethodsSmartIconsTypesEnum.DEBIT_CARD;

    return cardNetworkSmartIconType || PaymentMethodsSmartIconsTypesEnum.CREDIT_CARD;
  }

  return getSmartIconTypeForACH(method);
};

export const getPaymentMethodLabel = (method: FundingSource) => {
  const label =
    isCreditCardMethod(method) || isDebitCardMethod(method)
      ? 'batchBulkPage.renderers.fundingSource.cardLabel'
      : 'batchBulkPage.renderers.fundingSource.achLabel';
  const values = getPaymentMethodNameParts({ fundingSource: method });

  return {
    label,
    values,
    nickname: method?.nickname,
  };
};

export const getPaymentMethodNameParts = ({ fundingSource }: { fundingSource: FundingSource }) => {
  if (fundingSource?.bankAccount) {
    return {
      displayName:
        fundingSource.origin === FundingSourceOrigins.QBCASH
          ? QB_CASH_LABEL
          : fundingSource.displayName.trim() || '',
      institutionName: fundingSource?.plaidAccount?.plaidItem?.institutionName || undefined,
      identifier: getAccountNumber4digits(fundingSource.bankAccount),
    };
  }

  if (fundingSource?.cardAccount) {
    return {
      displayName: '',
      institutionName: fundingSource.cardAccount.network,
      identifier: fundingSource.cardAccount.card4digits,
    };
  }

  return {
    displayName: '',
    institutionName: '',
    identifier: '',
  };
};

export const getPaymentMethodName = ({ fundingSource, showDisplayName = true }) => {
  const { displayName, plaidAccount, bankAccount, cardAccount } = fundingSource;
  const institutionName = plaidAccount?.plaidItem?.institutionName;
  const cutAccountNumber = getAccountNumber4digits(bankAccount);
  let info = '';

  if (bankAccount) {
    info = institutionName
      ? `(${institutionName}, ...${cutAccountNumber})`
      : `(...${cutAccountNumber})`;

    if (showDisplayName) {
      info = `${displayName} ${info}`;
    }
  } else if (cardAccount) {
    info = `${cardAccount.network} (...${cardAccount.card4digits})`;
  }

  return info;
};

export const getPaymentMethodDisplayName = ({ fundingSource }) => fundingSource?.displayName;
export const getPaymentMethodInstitutionName = ({ fundingSource }) =>
  fundingSource?.plaidAccount?.plaidItem?.institutionName;

export const getAccountNumber4digits = (bankAccount: Partial<Bank> | null | undefined): string =>
  bankAccount?.accountNumber4digits || bankAccount?.accountNumber?.slice(-4) || '';

export const isEligibleToReceiveVirtualCard = (
  fundingSource?: Pick<FundingSource, 'cardAccount'>
): boolean => {
  const UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD = [
    CardNetworkEnum.VISA,
    CardNetworkEnum.AMEX,
  ];

  const blockedToVirtualCard =
    fundingSource?.cardAccount?.cardType === CardTypeEnum.CREDIT &&
    fundingSource?.cardAccount?.network &&
    UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD.includes(fundingSource?.cardAccount?.network);

  return !blockedToVirtualCard;
};

export const getOrderPosition = (paymentMethod: FundingSource) => {
  if (paymentMethod.origin === FundingSourceOrigins.QBCASH) {
    return PAYMENT_METHODS_ORDER[FundingSourceOrigins.QBCASH];
  }

  if (paymentMethod.cardAccount?.cardType) {
    return PAYMENT_METHODS_ORDER[paymentMethod.cardAccount?.cardType];
  }

  if (paymentMethod.origin === FundingSourceOrigins.PLAID) {
    return PAYMENT_METHODS_ORDER[FundingSourceOrigins.PLAID];
  }

  return PAYMENT_METHODS_ORDER[FundingSourceOrigins.MANUAL];
};

export const isBankAccountBlocked = (fundingSource): FundingSource =>
  fundingSource?.fundingType === FundingSourceTypesEnum.ACH &&
  fundingSource?.bankAccount?.isBlocked;

export const preSelectedPaymentMethod = ({
  defaultPaymentMethod,
}: PreSelectedPaymentMethodParams): FundingSource | undefined =>
  defaultPaymentMethod ? paymentMethodAdapter({ paymentMethod: defaultPaymentMethod }) : undefined;

export const preSelectedPaymentMethods = ({
  fundingSources,
  selectedDeliveryMethod,
}: PreSelectedPaymentMethodsParams) =>
  fundingSources?.map((fundingSource) =>
    paymentMethodAdapter({
      paymentMethod: fundingSource,
      selectedDeliveryMethod,
    })
  ) || [];

export const getDefaultPaymentMethod = ({
  fundingSources,
  fundingSourceId,
  deliveryMethod,
}: GetDefaultPaymentMethodParams): FundingSource | undefined => {
  const paymentMethod = fundingSources?.find(
    (fundingSource) => fundingSource.id === fundingSourceId
  );

  if (paymentMethod && !isBankAccountBlocked(paymentMethod)) {
    const currentDeliveryMethodEligibilityStatus = getDeliveryMethodEligibilityStatus({
      deliveryMethod,
      fundingSource: paymentMethod,
    });

    if (currentDeliveryMethodEligibilityStatus === DeliveryMethodEligibilityStatusEnum.Eligible) {
      return paymentMethod;
    }
  }

  const eligiblePaymentMethod = fundingSources?.find(
    (fundingSource) =>
      getDeliveryMethodEligibilityStatus({
        deliveryMethod,
        fundingSource,
      }) === DeliveryMethodEligibilityStatusEnum.Eligible && !isBankAccountBlocked(fundingSource)
  );

  if (eligiblePaymentMethod) {
    return eligiblePaymentMethod;
  }

  // In case when we have International as DM and not compatible FS
  // we should set DM and leave FS empty, cause in this case we can't have
  // any other DM than International
  if (deliveryMethod?.deliveryType === DeliveryEnum.INTERNATIONAL) {
    return undefined;
  }

  // handle the case when we have incompatible FS+DM pair initially set by API.
  // So in this case we will have first available FS and DM that compatible with this FS or empty DM
  return fundingSources?.[0];
};

export const orderPaymentMethods = (paymentMethods?: FundingSource[]) =>
  paymentMethods?.length
    ? [...paymentMethods]?.sort((a, b) => getOrderPosition(a) - getOrderPosition(b))
    : [];

export const getQBCash = (fundingSources: FundingSource[]) =>
  fundingSources.find((fs) => fs.origin === 'qbcash');

export const getQBCashForAccount = (fundingSources: FundingSource[]) =>
  fundingSources.find((fs) => fs.origin === 'qbcash');

export const checkEmptyFundingSources = (fundingSources: FundingSource[]) =>
  !fundingSources?.length || (fundingSources.length === 1 && fundingSources[0].origin === 'qbcash');

export const getPaymentMethodIcon = (method: string) => PaymentMethodsIconsEnum[method];
export const getCardName = (fundingSource: FundingSource) => fundingSource?.cardAccount?.network;
export const isMastercard = (fundingSource: FundingSource) =>
  fundingSource?.cardAccount?.network === CardNetworkEnum.MASTERCARD_PASCAL ||
  fundingSource?.cardAccount?.network === CardNetworkEnum.MASTERCARD;
