import { useIntl } from 'react-intl';
import { Modal } from '@melio/billpay-design-system';
import { ModalBodyText } from './ErrorMessageModal.styles';

export interface ErrorMessageModalProps {
  errorMessage?: string;
  onClose: () => void;
}

export const ErrorMessageModal = ({ errorMessage, onClose }: ErrorMessageModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <Modal isOpen={!!errorMessage} onClose={onClose}>
      <Modal.Header>
        <Modal.HeaderTitle>
          {formatMessage({ id: 'linkCard.modal.notSupportedTitle' })}
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.CloseButton data-testid="modal-close" />
      <Modal.Body>
        <ModalBodyText>
          {errorMessage && formatMessage({ id: `server.${errorMessage}` })}
        </ModalBodyText>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonContent isCentred>
          <Modal.Button data-testid="modal-button" onClick={onClose} size="md" variant="primary">
            {formatMessage({ id: 'dialogs.buttons.close' })}
          </Modal.Button>
        </Modal.ButtonContent>
      </Modal.Footer>
    </Modal>
  );
};
