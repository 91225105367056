import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import billsStore from 'src/app/modules/bills/bills-store';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import {
  UNPAID_BILL_DASHBOARD_STASUSES,
  DASHBOARD_ITEM_ACTIONS,
} from 'src/app/pages/qb-dashboard/consts';
import useViewPaymentAction from 'src/app/pages/qb-dashboard/hooks/useViewPaymentAction';
import useSchedulePaymentAction from 'src/app/pages/qb-dashboard/hooks/useSchedulePaymentAction';
import useMarkAsPaidAction from 'src/app/pages/qb-dashboard/hooks/useMarkAsPaidAction';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import useCancelPaymentAction from 'src/app/pages/qb-dashboard/hooks/useCancelPaymentAction';
import vendorsStore from 'src/app/modules/vendors/vendors-store';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { useGetActionCellEventProperties } from 'src/app/pages/qb-dashboard/hooks/useGetActionCellEventProperties';
import { isPartialAmountInvalid } from 'src/app/pages/qb-dashboard/utils';
import { useBillModal } from 'src/app/pages/qb-dashboard/components/BillModal/hooks/useBillModal';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import { useReturnedCheckInDashboard } from 'src/app/pages/qb-dashboard/components/QBODataTable/hooks/useReturnedCheckInDashboard';
import QBOActionsCell from './QBOActionsCell';
import useDeleteBillModal from '../../../hooks/useDeleteBillModal';
import { BillType, PaymentType, VendorType } from 'src/app/utils/types';
import useDeletePaymentModal from 'src/app/pages/qb-dashboard/hooks/useDeletePaymentModal/useDeletePaymentModal';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { featureFlags } from '@melio/shared-web';

type Props = {
  item: DashboardListItemType;
};

export const QBOUnpaidActionCell = ({ item }: Props) => {
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const { billId, paymentId, vendorId, metadata } = item;
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(paymentId));
  const bill: BillType = useSelector(billsStore.selectors.byId(billId));
  const vendor: VendorType = useSelector(vendorsStore.selectors.byId(vendorId));
  const [isPlatformLineItemsEnabled] = featureFlags.useFeature(
    FeatureFlagsEnum.PLATFORM_LINE_ITEMS,
    false
  );
  const [isQboRefundPaymentFF] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_REFUND_PAYMENT,
    false
  );
  const [isRefundFlowFeature] = featureFlags.useFeature(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );
  const partialAmount: number = useSelector(
    qbDashboardListItemsStore.selectors.partialPaymentsAmounts.byId(billId)
  );
  const returnedCheckActionItem = useReturnedCheckInDashboard({
    billId,
    paymentId,
    paymentOriginId: payment?.originId,
    payment,
  });
  const { eventProperties } = useGetActionCellEventProperties({
    billId,
    paymentId,
    status: metadata.statusInDashboard,
  });

  const isInvalidPartialAmount = isPartialAmountInvalid({
    balance: bill?.balance as number,
    amount: partialAmount,
  });
  const dashboardListActions = useStoreActions(qbDashboardListItemsStore);
  const [goViewPayment] = useViewPaymentAction();
  const [goSchedulePayment] = useSchedulePaymentAction();
  const [CancelPaymentDialog, showCancelPaymentDialog] = useCancelPaymentAction({
    billId,
    paymentId,
    companyName: vendor?.companyName,
    amount: payment?.amount?.toString() as string,
    modalName: 'delete-payment-dialog',
  });

  const [DeletePaymentDialog, showDeletePaymentDialog] = useDeletePaymentModal({
    billId,
    companyName: vendor?.companyName,
    amount: payment?.amount as number,
  });

  const [markAsPaid, MarkAsPaidModal] = useMarkAsPaidAction(billId);

  const handleSchedulePaymentClick = useCallback(() => {
    goSchedulePayment({
      billId,
      billOriginId: bill?.originId,
      initialPaymentAmount: partialAmount || (payment?.amount as number),
    });
  }, [billId, bill, partialAmount, goSchedulePayment]);

  const handleViewPaymentClick = useCallback(() => {
    goViewPayment({
      billId,
      paymentOriginId: payment?.originId,
      paymentId,
    });
  }, [billId, paymentId, payment, goViewPayment]);

  const onRetryFailedPayment = () => {
    if (isPartialPaymentsEnabled) {
      handleViewPaymentClick();
    } else {
      handleSchedulePaymentClick();
    }
  };

  const [DeleteBillDialogElement, showDeleteBillModal] = useDeleteBillModal({
    billId,
    companyName: vendor?.companyName,
    amount: bill?.totalAmount,
  });

  const [BillModalDialog, showBillModalDialog] = useBillModal({
    modalName: 'bill-modal__edit',
    billId,
  });

  const schedulePaymentAction = {
    label: 'paymentDashboard.columns.actions.schedule',
    id: DASHBOARD_ITEM_ACTIONS.SCHEDULE,
    onClick: handleSchedulePaymentClick,
  };

  let actions = [
    schedulePaymentAction,
    {
      label: 'paymentDashboard.columns.actions.markAsPaid',
      id: DASHBOARD_ITEM_ACTIONS.MARK_AS_PAID,
      onClick: markAsPaid,
    },
  ];

  if (metadata?.statusInDashboard !== UNPAID_BILL_DASHBOARD_STASUSES.PARTIALLY_PAID) {
    const deleteBillAction = {
      label: 'paymentDashboard.columns.actions.deleteBill',
      id: DASHBOARD_ITEM_ACTIONS.DELETE_BILL,
      onClick: showDeleteBillModal,
    };

    actions.push(deleteBillAction);
  }

  const editBillAction = {
    label: 'paymentDashboard.columns.actions.editBill',
    id: DASHBOARD_ITEM_ACTIONS.EDIT_BILL,
    onClick: showBillModalDialog,
  };

  if (!isPlatformLineItemsEnabled) {
    actions.splice(1, 0, editBillAction);
  }

  if (metadata.statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.REFUNDED) {
    const viewPaymentItem = {
      label: 'paymentDashboard.columns.actions.view',
      onClick: handleViewPaymentClick,
      id: DASHBOARD_ITEM_ACTIONS.DELETE,
    };
    const deletePaymentAction = {
      label: 'paymentDashboard.columns.actions.delete',
      onClick: showDeletePaymentDialog,
      id: DASHBOARD_ITEM_ACTIONS.VIEW,
    };

    actions = [viewPaymentItem, deletePaymentAction];
  }

  if (metadata.statusInDashboard === UNPAID_BILL_DASHBOARD_STASUSES.FAILED) {
    const failureActionItem = {
      label: 'paymentDashboard.columns.actions.retryPayment',
      onClick: onRetryFailedPayment,
      id: DASHBOARD_ITEM_ACTIONS.RETRY,
    };

    if (returnedCheckActionItem) {
      actions = [returnedCheckActionItem];
    } else if (metadata?.canUserRetry) {
      actions = [failureActionItem];
    } else {
      actions = [];
    }

    const actionLabel = isQboRefundPaymentFF ? 'resolve' : 'view';

    actions.push({
      label: `paymentDashboard.columns.actions.${actionLabel}`,
      onClick: handleViewPaymentClick,
      id: DASHBOARD_ITEM_ACTIONS.VIEW,
    });

    if (!isQboRefundPaymentFF) {
      if (isPartialPaymentsEnabled && (payment?.balance === 0 || payment?.balance === null)) {
        actions.push({
          label: 'paymentDashboard.columns.actions.cancel',
          onClick: showCancelPaymentDialog,
          id: DASHBOARD_ITEM_ACTIONS.DELETE,
        });
      }
    }

    if (isRefundFlowFeature) {
      actions = [
        {
          label: `paymentDashboard.columns.actions.resolve`,
          onClick: handleViewPaymentClick,
          id: DASHBOARD_ITEM_ACTIONS.VIEW,
        },
      ];
    }
  }

  if (payment?.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED) {
    actions.splice(1, 0, schedulePaymentAction);
  }

  const onMouseEnter = () => {
    if (isInvalidPartialAmount) {
      dashboardListActions.partialPaymentsAmounts.setInvalidTooltipVisible({
        billId: item.billId,
        invalidTooltipVisible: true,
      });
    }
  };

  const onMouseLeave = () => {
    if (isInvalidPartialAmount) {
      dashboardListActions.partialPaymentsAmounts.setInvalidTooltipVisible({
        billId: item.billId,
        invalidTooltipVisible: false,
      });
    }
  };

  return (
    <>
      {CancelPaymentDialog}
      {MarkAsPaidModal}
      {DeleteBillDialogElement}
      {DeletePaymentDialog}
      {BillModalDialog}
      <QBOActionsCell
        isDisabled={isInvalidPartialAmount}
        eventProperties={eventProperties}
        actions={actions}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </>
  );
};
