import { getTableWidth } from 'src/app/version-2/pages/batch-bulk/utils/tableWidth.utils';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
  width: ${getTableWidth()};

  > div {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
`;
