import moment from 'moment';
import type { RecordOf } from 'immutable';
import compact from 'lodash/compact';
import {
  CONSTS,
  BILL_STATUS,
  PAYMENT_STATUS,
  DELETE_PAYMENT_ACTION,
  FAILED_PAYMENT_TYPE,
  BUTTON_SIZE,
} from 'src/app/utils/consts';
import { isUndepositedCheck, isVirtualCardPaymentExpired } from 'src/app/utils/payments';
import { isRetryFailedToDeliverACH } from 'src/app/version-2/utils/payment.utils';
import { featureFlags } from '@melio/shared-web';
import type {
  DeliveryMethodType,
  BillType,
  PaymentType,
  DeletePaymentActionType,
} from '../../utils/types';

export const getTermsFromDates = (
  dueDate: any,
  invoiceDate: any // eslint-disable-line react/sort-comp
) => moment(dueDate).diff(invoiceDate, 'days');

export const recalculateTerm = (idType: string, changedField: any, bill: RecordOf<BillType>) => {
  let newTerm: number | null = null;

  if (idType === 'dueDate' && bill.invoiceDate) {
    newTerm = getTermsFromDates(changedField, bill.invoiceDate);
  }

  if (idType === 'invoiceDate' && bill.dueDate) {
    newTerm = getTermsFromDates(bill.dueDate, changedField);
  }

  if (idType === 'terms') {
    newTerm = +changedField;
  }

  return newTerm;
};

export const recalculateDueDate = (idType: string, changedField: any, bill: RecordOf<BillType>) => {
  let dueDate: Date | null = null;

  if (idType === 'terms' && bill.invoiceDate && changedField) {
    dueDate = moment(bill.invoiceDate).add(changedField, 'days').toDate();
  }

  return dueDate;
};

export const recalculateInvoiceDate = (
  idType: string,
  changedField: any,
  bill: RecordOf<BillType>
) => {
  let invoiceDate: Date | null = null;

  if (!bill.invoiceDate) {
    if (idType === 'terms' && bill.dueDate && changedField) {
      invoiceDate = moment(bill.dueDate).subtract(changedField, 'days').toDate();
    }

    if (idType === 'dueDate' && bill.terms && changedField) {
      invoiceDate = moment(changedField).subtract(bill.terms, 'days').toDate();
    }
  }

  return invoiceDate;
};

export const getTermsByUploadBill = (
  dueDate: Record<string, any> | string,
  invoiceDate: Record<string, any> | string
) =>
  Object.prototype.toString.call(dueDate) === '[object Date]' &&
  Object.prototype.toString.call(invoiceDate) === '[object Date]'
    ? moment(dueDate).diff(invoiceDate, 'days')
    : null;

export const strLowerNoSpaces = (str: string) => str.toLowerCase().replace(/\s/g, '');

interface GetBillActionOptionsParams {
  bill: BillType;
  payment: PaymentType;
  goEditPayment: (payment: PaymentType) => void;
  onCancelPaymentClicked: (
    payment: PaymentType,
    deletePaymentAction: DeletePaymentActionType
  ) => void;
  onChangeDeliveryMethod?: (paymentId: number) => void;
  onRetryPaymentDelivery?: (paymentId: number, deliveryMethodId?: number) => void;
  onRetryPayment: (payment: PaymentType) => void;
  onMarkBillAsPaid?: (() => void) | null;
  onMarkBillAsUnpaid?: ((payment: PaymentType) => void) | null;
  onVoidCheckAndResendPayment?: () => void;
  onClose?: () => void;
}

export const getBillActionOptions = ({
  bill,
  payment,
  goEditPayment,
  onCancelPaymentClicked,
  onChangeDeliveryMethod,
  onRetryPaymentDelivery,
  onRetryPayment,
  onMarkBillAsPaid,
  onMarkBillAsUnpaid,
  onVoidCheckAndResendPayment,
  onClose,
}: GetBillActionOptionsParams) => {
  const collectMessagesToRetry = [
    'accountFrozen',
    'uncollectedFunds',
    'invalidTransaction',
    'bankAccountUnauthorized',
    'userBlockedMelioAccount',
    'exceedsWithdrawalAmountLimit',
  ];

  if (bill.internalBill) return [];

  if (payment.manual) {
    return [
      {
        label: 'bills.form.paymentActivity.actions.close',
        action: () => {
          onClose && onClose();
        },
        variant: CONSTS.BUTTON_VARIANT.SECONDARY,
      },
      {
        label: 'bills.form.paymentActivity.actions.markAsUnpaid',
        action: () => {
          onMarkBillAsUnpaid && onMarkBillAsUnpaid(payment);
        },
        variant: CONSTS.BUTTON_VARIANT.PRIMARY,
      },
    ];
  }

  const [voidChecksEnabled] = featureFlags.useFeature('bp-void-check', false);
  const isUndepositedCheckPayment = isUndepositedCheck(payment);
  const isPaymentVoidAndResendCompitable = payment.metadata?.isVoidAndRetryEnabled;

  if (voidChecksEnabled && isPaymentVoidAndResendCompitable && isUndepositedCheckPayment) {
    return [
      {
        action: () => onVoidCheckAndResendPayment && onVoidCheckAndResendPayment(),
        label: 'bills.pay.confirm.undepositedCheck.cta',
        variant: CONSTS.BUTTON_VARIANT.SECONDARY,
      },
    ];
  }

  if (
    [PAYMENT_STATUS.COMPLETED, PAYMENT_STATUS.IN_PROGRESS].includes(payment.status as any) &&
    !payment.manual
  ) {
    return [];
  }

  if (payment?.metadata?.canUserRetry) {
    if (isVirtualCardPaymentExpired(payment)) {
      return [
        {
          action: () => onRetryPayment(payment),
          label: 'bills.form.paymentActivity.actions.retryPaymentAction',
          variant: CONSTS.BUTTON_VARIANT.PRIMARY,
        },
      ];
    }

    if (payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER) {
      const { deliveryMethod, id, deliveryMethodId } = payment;
      const missingDeliveryMethod = !deliveryMethod || Boolean((deliveryMethod as any)?.deletedAt);
      const retryAction =
        missingDeliveryMethod && onChangeDeliveryMethod
          ? () => onChangeDeliveryMethod(Number(id))
          : () =>
              onRetryPaymentDelivery &&
              onRetryPaymentDelivery(Number(id), Number(deliveryMethodId));

      return [
        {
          action: retryAction,
          label: 'bills.actions.retryPaymentDelivery',
          variant: CONSTS.BUTTON_VARIANT.PRIMARY,
        },
      ];
    }

    if (
      payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_COLLECT &&
      collectMessagesToRetry.includes(payment?.metadata?.failureMessage as any)
    ) {
      return [
        {
          action: () => onRetryPayment && onRetryPayment(payment),
          label: 'bills.actions.retryPayment',
          variant: CONSTS.BUTTON_VARIANT.PRIMARY,
        },
      ];
    }

    const markAsPaidAction = onMarkBillAsPaid && {
      label: 'bills.actions.markAsPaid',
      action: onMarkBillAsPaid,
      variant: CONSTS.BUTTON_VARIANT.SECONDARY,
    };
    const retryAction = onRetryPayment && {
      label: 'bills.form.paymentActivity.actions.retryPayment',
      action: () => onRetryPayment && onRetryPayment(payment),
      variant: CONSTS.BUTTON_VARIANT.PRIMARY,
    };
    const cancelAction = onCancelPaymentClicked && {
      label: 'bills.form.paymentActivity.actions.cancel',
      action: onCancelPaymentClicked,
      variant: CONSTS.BUTTON_VARIANT.SECONDARY,
    };

    return compact([markAsPaidAction, cancelAction, retryAction]);
  }

  if (bill.status === BILL_STATUS.SCHEDULED) {
    const actionsList = [
      {
        label: 'bills.form.paymentActivity.actions.edit',
        action: () => {
          goEditPayment && goEditPayment(payment);
        },
        variant: CONSTS.BUTTON_VARIANT.SECONDARY,
      },
    ];
    const cancelAction = {
      label: 'bills.form.paymentActivity.actions.cancel',
      action: () => {
        onCancelPaymentClicked && onCancelPaymentClicked(payment, DELETE_PAYMENT_ACTION.SINGLE);
      },
      variant: CONSTS.BUTTON_VARIANT.DESTRUCTIVE,
    };
    const cancelCurrentRecurringPaymentAction = {
      label: 'bills.form.paymentActivity.actions.cancelCurrentRecurringPayment',
      action: () => {
        onCancelPaymentClicked &&
          onCancelPaymentClicked(payment, DELETE_PAYMENT_ACTION.RECURRING_CURRENT);
      },
      variant: CONSTS.BUTTON_VARIANT.DESTRUCTIVE,
    };
    const cancelAllRecurringPayments = {
      label: 'bills.form.paymentActivity.actions.cancelAllRecurringPayments',
      action: () => {
        onCancelPaymentClicked &&
          onCancelPaymentClicked(payment, DELETE_PAYMENT_ACTION.RECURRING_ALL);
      },
      variant: CONSTS.BUTTON_VARIANT.DESTRUCTIVE,
    };

    if (bill.recurringBillId) {
      actionsList.push(cancelCurrentRecurringPaymentAction, cancelAllRecurringPayments);
    } else {
      actionsList.push(cancelAction);
    }

    return actionsList;
  }

  return [];
};

export const getQBRBillActionOptions = (
  bill: BillType,
  payment: PaymentType,
  goEditPayment: (PaymentType) => void,
  onCancelPaymentClicked: (PaymentType, DeletePaymentActionType) => void
) => {
  const failureKey = payment?.metadata?.failureMessage ?? 'default';

  if (failureKey === 'incorrectVendorACH') {
    return [];
  }

  if (bill.status === BILL_STATUS.SCHEDULED) {
    return [
      {
        label: 'bills.form.paymentActivity.actions.edit',
        action: () => {
          goEditPayment && goEditPayment(payment);
        },
        variant: CONSTS.BUTTON_VARIANT.SECONDARY,
        size: BUTTON_SIZE.SMALL,
        fullWidth: true,
      },
      {
        label: 'bills.form.paymentActivity.actions.cancel',
        action: () => {
          onCancelPaymentClicked && onCancelPaymentClicked(payment, DELETE_PAYMENT_ACTION.SINGLE);
        },
        variant: CONSTS.BUTTON_VARIANT.DESTRUCTIVE,
        size: BUTTON_SIZE.SMALL,
        fullWidth: true,
      },
    ];
  }

  if (bill.status === BILL_STATUS.UNPAID) {
    return compact([
      {
        label: 'bills.form.paymentActivity.actions.delete',
        action: () => {
          onCancelPaymentClicked && onCancelPaymentClicked(payment, DELETE_PAYMENT_ACTION.SINGLE);
        },
        variant: CONSTS.BUTTON_VARIANT.SECONDARY,
        size: BUTTON_SIZE.SMALL,
        fullWidth: true,
      },
      payment?.metadata?.canUserRetry && {
        label: 'bills.form.paymentActivity.actions.retryPayment',
        action: () => {
          goEditPayment && goEditPayment(payment);
        },
        variant: CONSTS.BUTTON_VARIANT.PRIMARY,
        size: BUTTON_SIZE.SMALL,
        fullWidth: true,
      },
    ]);
  }

  return [];
};

export const isNumber = (n: any) => !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0);

export const getTransactionFee = (amount?: number) => (amount ? amount / 100 : '');

export const getDeliveryMethodIcon = (deliveryMethod: DeliveryMethodType) => {
  const isRPPSDeliveryMethod = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.RPPS;
  const isCheckDeliveryMethod = deliveryMethod?.deliveryType === CONSTS.DELIVERY_TYPE.CHECK;

  if (isRPPSDeliveryMethod) {
    return 'icon-dirctory-confirmed';
  }

  if (isCheckDeliveryMethod) {
    return 'icon-check-icon';
  }

  return 'icon-bank-icon';
};

export const getLayoutHeaderLabel = ({ isRecurring, deliverFailureData }) => {
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH({ deliverFailureData });

  if (isRecurring) return 'qbo.header.recurringTitle';

  if (isRetryFailedToDeliver) return 'qbo.header.resendTitle';

  return 'qbo.header.title';
};

export const getArrayOfVendorIdToPaymentIdMapping = (objsArray) =>
  objsArray.reduce((acc, obj) => {
    const { vendorId, paymentId } = obj;

    return { ...acc, [vendorId]: paymentId };
  }, {});
