import { RecordOf } from 'immutable';
import * as React from 'react';
import MINotification from 'src/app/components/common/MINotification';
import MIPasswordInput from 'src/app/components/common/MIPasswordInput';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { INPUT_PATTERNS } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import { BankType, FieldType } from 'src/app/utils/types';
import styled from 'styled-components';

type Props = {
  onDone: () => void;
  onPrev: () => void;
  onExit: () => void;
  bank: RecordOf<BankType>;
  onChange: (arg0: FieldType) => void;
  isLoading?: boolean;
  validationErrors: Record<string, string>;
  errorCode: string;
  vendorName: string;
  companyName: string;
};

const EditBankDeliveryMethodPage = ({
  isLoading,
  onDone,
  onChange,
  onPrev,
  validationErrors,
  bank,
  errorCode,
  vendorName,
  onExit,
}: Props) => {
  const { routingNumber, accountNumber } = bank;
  const site = useSiteContext();
  const { WizardFormRow } = site.components.WizardElements;
  const shouldShowValue = routingNumber === '';

  return (
    <site.components.StepLayout
      hideHeader={false}
      headerLabel="vendors.deliveryMethods.bank.header.title"
      title="vendors.deliveryMethods.bank.title"
      titleValues={{ vendor: vendorName }}
      subtitle="vendors.deliveryMethods.bank.sectionTitle"
      onSubmit={onDone}
      onPrev={onPrev}
      goExit={onExit}
      nextLabel="vendors.deliveryMethods.bank.save"
      isLoading={isLoading}
      fullWidthCTA
    >
      {errorCode && <MINotification label={`server.${errorCode}`} type="error" />}
      <WizardFormRow>
        <MITextInput
          id="routingNumber"
          value={routingNumber}
          label="vendors.deliveryMethods.bank.routingNumber"
          onChange={onChange}
          type="tel"
          autoFocus
          required
          errorMessage={validationErrors.routingNumber}
          autocomplete="off"
          privateData
        />
      </WizardFormRow>

      <WizardFormRow>
        <MIPasswordInput
          id="accountNumber"
          value={accountNumber}
          label="vendors.deliveryMethods.bank.accountNumber"
          onChange={onChange}
          required
          errorMessage={validationErrors.accountNumber}
          shouldShowValue={shouldShowValue}
          autocomplete="off"
          pattern={INPUT_PATTERNS.NUMBERS}
          inputMode="numeric"
        />
      </WizardFormRow>
      <SecurityContainer>
        <MIFormattedText label="vendors.deliveryMethods.bank.security" />
      </SecurityContainer>
    </site.components.StepLayout>
  );
};

export default EditBankDeliveryMethodPage;

const SecurityContainer = styled.div`
  ${(props) => props.theme.text.fontType.hint}
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.darkGrey};
  padding: 0 1.6rem;
  ${(props) => props.theme.pages?.EditBankDeliveryMethodPage?.SecurityContainer}
`;
