import analytics from 'src/app/services/analytics';
import { EntrypointActionEnum, RegistrationOriginEnum } from 'src/app/version-2/model/enums';
import { envApi } from 'src/app/version-2/api/env';
import { LOCAL_STORAGE_KEYS } from './consts';
import { CompanyInfoType } from './types';

type ConnectToIntuitOptions = {
  intuitReturnUrl?: string;
  intuitErrorReturnUrl?: string;
  quickbooksChooseRealmId?: string;
  linkOrgId?: string;
  registrationOrigin?: string;
  createCompany?: boolean;
  companyInfo?: Partial<CompanyInfoType>;
  embeddedAppcenter?: boolean;
  intent?: string;
  isAfterRegister?: boolean;
  site?: any;
};

// Initiates the Intuit OpenID and QuickBooks Connect dance
// We save two URLs to local storage
// One for when Intuit finishes to sync, and one for when quickbook is done.
//
// When intuit returns, if it's a valid user redirect to the intuit return url
// or to the dashboard. If it's not a user, it'll continue to the quickbooks
// connect, and upon successful QuickBooks connect the quickbooks url will be used.

function setUnsetLocalStorage(key, value) {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
}

function setupReturnData({ intuitReturnUrl, quickbooksChooseRealmId }: ConnectToIntuitOptions) {
  setUnsetLocalStorage(LOCAL_STORAGE_KEYS.INTUIT_RETURN_URL, intuitReturnUrl);
  setUnsetLocalStorage(LOCAL_STORAGE_KEYS.QUICKBOOKS_CHOOSE_REALM_ID, quickbooksChooseRealmId);
}

export default {
  buildConnectQuery(options: ConnectToIntuitOptions) {
    const {
      intuitReturnUrl,
      intuitErrorReturnUrl = '',
      quickbooksChooseRealmId,
      site,
      linkOrgId,
      registrationOrigin,
      createCompany,
      companyInfo,
      embeddedAppcenter,
      intent,
      isAfterRegister,
    } = options;
    const intuitReturn = [
      `${site?.config?.web?.baseUrl || envApi.getConfig().web.baseUrl}`,
      `${intuitReturnUrl}`,
    ].join('');

    const intuitErrorReturn = [
      `${site?.config?.web?.baseUrl || envApi.getConfig().web.baseUrl}`,
      `${String(intuitErrorReturnUrl) || String(intuitReturnUrl)}`,
    ].join('');

    const query = [
      `intuitReturn=${encodeURIComponent(intuitReturn)}`,
      `intuitErrorReturn=${encodeURIComponent(intuitErrorReturn)}`,
      `qbRealmId=${encodeURIComponent(quickbooksChooseRealmId || '')}`,
      `registrationOrigin=${encodeURIComponent(registrationOrigin || RegistrationOriginEnum.APP)}`,
    ];

    if (linkOrgId) {
      query.push(`linkOrgId=${linkOrgId}`);
    }

    query.push('isBillpay=true');

    if (embeddedAppcenter) {
      query.push('embeddedAppcenter=true');
    }

    if (createCompany) {
      query.push('createCompany=true');
    }

    if (companyInfo) {
      query.push(`companyInfo=${encodeURIComponent(JSON.stringify(companyInfo))}`);
    }

    if (intent) {
      query.push(`intent=${intent}`);
    }

    if (isAfterRegister) {
      query.push(`isAfterRegister=${isAfterRegister}`);
    }

    return query;
  },
  goConnectToIntuit(options: ConnectToIntuitOptions) {
    const { intuitReturnUrl, quickbooksChooseRealmId } = options;

    setupReturnData({ intuitReturnUrl, quickbooksChooseRealmId });
    this.updateLoadingStatus(75);
    const query = this.buildConnectQuery(options);
    const url = `${envApi.getConfig().server.baseUrl}/api/auth/intuit/oauth${
      options.intent ? '/intent' : ''
    }?${query.join('&')}`;

    window.location.href = url;
  },
  goConnectToIntuitWithAction(options: ConnectToIntuitOptions) {
    const { intuitReturnUrl, quickbooksChooseRealmId } = options;

    setupReturnData({ intuitReturnUrl, quickbooksChooseRealmId });
    this.updateLoadingStatus(75);
    const query = this.buildConnectQuery(options);

    window.location.href = `${envApi.getConfig().server.baseUrl}/api/auth/intuit/oauth?${query.join(
      '&'
    )}`;
  },
  closeIntegration() {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage('melio-quickbooks-done', '*');
  },
  getRealmId() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.QUICKBOOKS_CHOOSE_REALM_ID);
  },

  endLoadingWrapper() {
    analytics.trackAction('melio-wrapper-loaded');
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ done: true, precentage: 100, source: 'melio-loading-iframe' }, '*');
  },
  initFTULoadingWrapper() {
    analytics.trackAction('melio-ftu-wrapper-init');
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ source: 'melio-loading-iframe', initFTULoader: true }, '*');
  },
  showFTULoadingWrapper(action: EntrypointActionEnum) {
    analytics.trackAction('melio-ftu-wrapper-show');
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ source: 'melio-loading-iframe', showFTULoader: true, action }, '*');
  },
  hideFTULoadingWrapper() {
    analytics.trackAction('melio-ftu-wrapper-hide');
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ source: 'melio-loading-iframe', hideFTULoader: true }, '*');
  },
  updateLoadingStatus(precentage: number) {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ precentage, source: 'melio-loading-iframe' }, '*');
  },
};
