import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import errorImage from 'src/app/images/qbo/error-icon.png';
import canceledImage from 'src/app/images/qbo/help.svg';
import expiredImage from 'src/app/images/qbo/payment-send.svg';
import invalidImage from 'src/app/images/icons/errorToken.svg';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { PAYMENT_STATUS } from 'src/app/utils/consts';
import GeneralFlowErrorContainer from 'src/app/pages/quickbooks/GeneralFlowErrorContainer';
import { PaymentType } from 'src/app/utils/types';

type Props = {
  token: string;
};

function DefaultErrorPage({ token }: Props) {
  const site = useSiteContext();
  const { paymentId } = getJWTPayload(token);
  const { errorData } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const { invalidTokenData } = useSelector(deliveryMethodsStore.selectors.validation) as {
    invalidTokenData: any;
  };
  const payment: PaymentType = useSelector(paymentStore.selectors.byId(paymentId));
  const error = errorData || invalidTokenData;
  const isPaymentProcessedError =
    payment?.status === PAYMENT_STATUS.COMPLETED || payment?.status === PAYMENT_STATUS.FAILED;
  let companyName = '';
  let email = '';

  if (error && error.data) {
    ({ companyName, email } = error.data);
  }

  const renderIcon = (error) => {
    switch (error?.code) {
      case 'UNP5':
      case 'PMT18':
      case 'PMT19':
        return expiredImage;
      case 'UNP1':
      case 'VT2':
      case 'PMT1':
        return invalidImage;
      case 'UNP4':
        return canceledImage;
      default:
        return errorImage;
    }
  };

  const errorSubtitle =
    !error && isPaymentProcessedError
      ? 'vendors.deliveryMethods.shiftToDebit.errors.paymentCompletedError.subtitle'
      : '';

  return (
    <GeneralFlowErrorContainer
      illustration={isPaymentProcessedError ? expiredImage : renderIcon(error)}
      title={
        isPaymentProcessedError
          ? 'vendors.deliveryMethods.shiftToDebit.errors.paymentCompletedError.title'
          : ''
      }
      subtitle={error ? `server.${error.code}` : errorSubtitle}
      subtitleValues={{
        companyName,
        email,
        supportEmail: (
          <EmailLink href={`mailto:${site.config.support.email}`}>
            {site.config.support.email}
          </EmailLink>
        ),
      }}
    />
  );
}

const EmailLink = styled.a`
  text-decoration: none;
  color: rgba(28, 130, 210);
`;

export default DefaultErrorPage;
