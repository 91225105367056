import React from 'react';
import useFetchVendor from 'src/app/modules/vendors/hooks/useFetchVendor';
import { CheckType, EditableDeliveryMethodType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import deliveryMethodsStore, {
  getDeliveryMethodActions,
} from 'src/app/modules/delivery-methods/delivery-methods-store';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getOrgId } from 'src/app/redux/user/selectors';
import CheckDeliveryMethod from 'src/app/pages/vendor/delivery-methods/CheckDeliveryMethod';
import locations from '../locations';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const CheckDeliveryMethodPage = ({ onNext, onPrev, onExit }: Props) => {
  const dispatch = useDispatch();
  const match = useRouteMatch<{
    vendorId?: string;
    paymentId?: string;
    deliveryMethodId?: string;
  }>();
  const vendorId = match.params?.vendorId;
  const selectedDeliveryMethodId = match.params?.deliveryMethodId;
  const deliveryMethodActions = getDeliveryMethodActions(dispatch);
  const paymentActions = useStoreActions(paymentsStore);
  const orgId = useSelector<string>(getOrgId as any) as string;
  const isLoadingCreate = (
    useSelector(deliveryMethodsStore.selectors.create.status()) as { loading: boolean }
  )?.loading;
  const isLoadingUpdate = (
    useSelector(deliveryMethodsStore.selectors.update.status(selectedDeliveryMethodId)) as {
      loading: boolean;
    }
  )?.loading;
  const { fetchVendor } = useFetchVendor(vendorId);
  const onSuccess = async (
    paperCheck: CheckType,
    isVerified: boolean,
    checkDeliveryMethodId?: string
  ) => {
    const deliveryMethod: EditableDeliveryMethodType = {
      paperCheck,
      isVerified,
      deliveryType: CONSTS.DELIVERY_TYPE.CHECK,
    };
    let response;

    if (checkDeliveryMethodId && vendorId) {
      response = await deliveryMethodActions.edit({
        orgId,
        vendorId,
        id: checkDeliveryMethodId,
        deliveryMethod,
      });
    } else {
      response = await deliveryMethodActions.create({
        orgId,
        vendorId,
        params: deliveryMethod,
      });
    }

    await paymentActions.quickpay.newPaymentWizard.update({
      deliveryMethodId: response.payload.id,
    });
    await fetchVendor();
    onNext();
  };

  return (
    <CheckDeliveryMethod
      onSuccess={onSuccess}
      goExit={onExit}
      onPrev={onPrev}
      isLoading={isLoadingCreate || isLoadingUpdate}
      vendorId={vendorId as string}
      headerLabel="vendors.deliveryMethods.check.header.title"
      nextLabel="vendors.deliveryMethods.check.submit"
      title="vendors.deliveryMethods.check.title"
      selectDeliveryMethodPath={locations.create.deliveryMethod}
    />
  );
};

export default CheckDeliveryMethodPage;
