const baseStyle = {
  content: {
    outline: 0,
    bg: 'ds.white',
    maxWidth: '60rem',
    border: '1px solid',
    borderColor: 'ds.gray.500',
    color: 'ds.gray.100',
    boxShadow: 'ds.md',
    borderRadius: 4,
    fontFamily: 'body',
  },
  header: {
    padding: '1.6rem 2.4rem 1.2rem',
    borderBottom: '1px solid #eceef1',
  },
  body: {
    padding: '1.2rem 2.4rem 1.6rem',
    overflow: 'auto',
  },
  popper: {
    minWidth: '0 !important',
    zIndex: 1,
  },
};

const defaultProps = {
  placement: 'bottom',
  closeOnBlur: false,
};

export const PopoverTheme = {
  baseStyle,
  defaultProps,
};
