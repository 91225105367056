import React, { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Flex, Image } from '@melio/billpay-design-system';

import analytics from 'src/app/services/analytics';
import paymentsStore from 'src/app/modules/payments/payment-store';
import { useBreak } from 'src/app/hoc';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { MIFormattedText } from 'src/app/utils/formatting';
import TerminalDesktopExampleImage from 'src/app/images/qbo/vc-details/terminal-example-desktop.jpg';
import TerminalMobileExampleImage from 'src/app/images/qbo/vc-details/terminal-example-mobile.jpg';

import locations from './locations';
import VirtualDeliveryMethodLocations from '../virtual-delivery-method/locations';

import { InfoLayout } from './components';
import { ButtonsGroupComponent } from './components/ButtonsGroup';
import { PaymentType } from 'src/app/utils/types';

interface Props {
  token: string;
}

const PosTerminalPage = ({ token }: Props) => {
  const history = useHistory();
  const { isMobile } = useBreak();
  const { paymentId } = getJWTPayload(token);

  const paymentActions = useStoreActions(paymentsStore);
  const payment: PaymentType = useSelector(paymentsStore.selectors.byId(paymentId));

  useEffect(() => {
    paymentActions.fetchPaymentDetailsWithToken({ token });
  }, []);

  useEffect(() => {
    if (payment) {
      analytics.trackAction('page-viewed', {
        paymentId: payment?.id,
        vendorId: payment?.vendor?.id,
        newTrackingConvention: true,
      });
    }
  }, [payment]);

  const TerminalImage = isMobile ? TerminalMobileExampleImage : TerminalDesktopExampleImage;

  const viewCardDetails = () => {
    const virtualCardId = payment?.lastCreatedVirtualCard?.id;

    if (virtualCardId) {
      analytics.trackAction('view-card-clicked', {
        paymentId: payment?.id,
        vendorId: payment?.vendor?.id,
        newTrackingConvention: true,
      });

      history.push(generatePath(locations.details, { token, id: virtualCardId }));
    }
  };

  const chooseAnotherDeliveryMethod = () => {
    analytics.trackAction('receive-bank-transfer-clicked', {
      paymentId: payment?.id,
      vendorId: payment?.vendor?.id,
      newTrackingConvention: true,
    });
    history.push(generatePath(VirtualDeliveryMethodLocations.index, { token }));
  };

  return (
    <Flex direction="column">
      <InfoLayout>
        <Flex direction="column" gridGap={10}>
          <Flex direction="column" gridGap={5}>
            <Flex direction="column" gridGap={2} textAlign="center">
              <Box textStyle="h6" fontWeight="ds.semi">
                <MIFormattedText label="vendors.addDeliveryMethodByLink.virtualCardPosTerminal.title" />
              </Box>
              <Box textStyle="body3">
                <MIFormattedText label="vendors.addDeliveryMethodByLink.virtualCardPosTerminal.subtitle" />
              </Box>
            </Flex>
            <Image src={TerminalImage} />
          </Flex>
          {!isMobile && (
            <Flex direction="column" gridGap="1rem">
              <ButtonsGroupComponent
                primaryLabel="vendors.addDeliveryMethodByLink.virtualCardPosTerminal.buttons.view"
                secondaryLabel="vendors.addDeliveryMethodByLink.virtualCardPosTerminal.buttons.optOut"
                onPrimaryClick={viewCardDetails}
                onSecondaryClick={chooseAnotherDeliveryMethod}
              />
            </Flex>
          )}
        </Flex>
      </InfoLayout>
      {isMobile && (
        <Flex
          direction="column"
          gridGap="1rem"
          position="sticky"
          left="0"
          bottom="0"
          backgroundColor="white"
          padding={4}
          boxShadow="sm"
        >
          <ButtonsGroupComponent
            primaryLabel="vendors.addDeliveryMethodByLink.virtualCardPosTerminal.buttons.view"
            secondaryLabel="vendors.addDeliveryMethodByLink.virtualCardPosTerminal.buttons.optOut"
            onPrimaryClick={viewCardDetails}
            onSecondaryClick={chooseAnotherDeliveryMethod}
          />
          <Box textStyle="body4" textAlign="center">
            <MIFormattedText
              label="vendors.addDeliveryMethodByLink.virtualCardPosTerminal.notice"
              values={{ payor: payment?.organization?.companyName }}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export { PosTerminalPage };
