import { generatePath } from 'react-router-dom';
import locations from './locations';

const showSearchBar = (currentPathname: string, orgId: string) => {
  const searchablePathnames = [locations.Bills.index.url()];

  return (
    orgId &&
    searchablePathnames.some((pathname) => generatePath(pathname, { orgId }) === currentPathname)
  );
};

export { showSearchBar };
