import React from 'react';
import billAlreadyPaidIcon from 'src/app/images/qbo/bill-already-paid.svg';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import {
  isPaymentScheduled,
  getLatestPayment,
  isPaymentMarkedAsPaid,
  isPaymentProcessed,
  isPaymentFailed,
} from 'src/app/utils/payments';
import analytics from 'src/app/services/analytics';
import { useBillDetails } from 'src/app/pages/bill/hooks/useBillDetails';
import { useLocationState } from 'src/app/utils/hooks';
import { useRedirectToDashboard } from 'src/app/pages/qb-dashboard/hooks/useRedirectToDashboard';
import errorIcon from 'src/app/images/qbo/error-icon.png';

const eventPage = 'pay-bill';

enum TEXT_VARIANT {
  PROCESSED = 'processed',
  SCHEDULED = 'scheduled',
  PAID = 'paid',
  FAILED = 'failed',
}

type ItemIds = { billId: string; paymentId?: string }[];

export const BillAlreadyScheduledPage = () => {
  const [billId] = useLocationState('billId');
  const [exitUrl] = useLocationState('exitUrl');
  const { fetchRedirectQuery, isFetchingRedirectParams, redirectToDashboard } =
    useRedirectToDashboard();
  const { billDetails: bill, error } = useBillDetails(billId);

  const getHighlightedItems = (): ItemIds => {
    if (bill) {
      const highlightedPayment = getLatestPayment(bill.payments);

      return [
        {
          billId: bill.id,
          paymentId: highlightedPayment.id,
        },
      ];
    }

    return [
      {
        billId: '',
        paymentId: '',
      },
    ];
  };

  const handleRedirect = async () => {
    const itemIds = getHighlightedItems();

    if (itemIds) {
      const redirectQuery = await fetchRedirectQuery(itemIds);

      analytics.track(eventPage, 'bill-already-scheduled-go-to-dashboard-button-clicked');
      redirectToDashboard({ redirectQuery });
    }
  };

  const textValues = {
    amount: <MIFormattedCurrency value={bill?.totalAmount ? bill.totalAmount.toString() : ''} />,
    vendorName: bill?.vendor?.companyName,
  };

  const getComponentMessages = () => {
    let textVariant = TEXT_VARIANT.SCHEDULED;

    if (bill) {
      const lastPayment = getLatestPayment(bill?.payments);

      if (lastPayment) {
        const isMarkedAsPaid = isPaymentMarkedAsPaid(lastPayment);
        const isProcessed = isPaymentProcessed(lastPayment);
        const isFailed = isPaymentFailed(lastPayment);
        const isScheduled = isPaymentScheduled(lastPayment);

        if (isMarkedAsPaid) {
          textVariant = TEXT_VARIANT.PAID;
        } else if (isFailed) {
          textVariant = TEXT_VARIANT.FAILED;
        } else if (isProcessed) {
          textVariant = TEXT_VARIANT.PROCESSED;
        } else if (isScheduled) {
          textVariant = TEXT_VARIANT.SCHEDULED;
        }
      }
    }

    return {
      title: `bills.pay.billAlreadyScheduled.${textVariant}.title`,
      subtitle: `bills.pay.billAlreadyScheduled.${textVariant}.subTitle`,
    };
  };

  if (error?.code === 'ERR') {
    return (
      <ErrorPage
        illustration={errorIcon}
        title="inputErrors.QBO01.title"
        subtitle="inputErrors.QBO01.subTitle"
        buttonAction={exitUrl}
        buttonLabel="inputErrors.QBO01.buttonCTA"
        exitUrl={exitUrl}
      />
    );
  }

  return (
    <>
      {!!bill && (
        <ErrorPage
          illustration={billAlreadyPaidIcon}
          subtitleValues={textValues}
          buttonLabel="bills.pay.billAlreadyScheduled.buttonLabel"
          buttonAction={handleRedirect}
          isProcessing={isFetchingRedirectParams}
          exitUrl={exitUrl}
          {...getComponentMessages()}
        />
      )}
    </>
  );
};
