import { featureFlags } from '@melio/shared-web';
import { MIFormattedText } from 'src/app/utils/formatting';
import {
  BaseContainer,
  HighlightedHeader,
  PaymentIdBox,
  StyledMIMoney,
  SubTitle,
} from './ViewBillPaymentReviewTotalSection.styles';

type Props = {
  currentAmount: number;
  paymentId: string;
};

export const ViewBillPaymentReviewTotalSection = ({ currentAmount, paymentId }: Props) => {
  const [showPaymentId] = featureFlags.useFeature('expose-paymentid');

  return (
    <BaseContainer>
      <HighlightedHeader data-testid="billpay-view-bill-total">
        <SubTitle>
          <MIFormattedText label="bills.pay.confirm.total" />
        </SubTitle>
        <StyledMIMoney size="large" amount={currentAmount} />
        {showPaymentId && paymentId && (
          <PaymentIdBox>
            <MIFormattedText label="bills.pay.confirm.paymentId" values={{ paymentId }} />
          </PaymentIdBox>
        )}
      </HighlightedHeader>
    </BaseContainer>
  );
};
