import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import unionBy from 'lodash/unionBy';

import { debounce } from 'src/app/utils/search';
import { ExtendedSelectOption } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';

import { CheckboxSelect } from './CheckboxSelect';
import { MultiSelectSearchInput } from './MultiSelectSearchInput';

enum SelectType {
  Radio,
  Checkbox,
}

type Props = {
  options: ExtendedSelectOption[];
  type?: SelectType;
  searchable: boolean;
  searchPlaceholder?: string;
  clearLabelMessage?: string;
  noResultMessage?: string;
  onChange: (value: ExtendedSelectOption[]) => void;
};

const MultiSelect = ({
  options,
  type = SelectType.Checkbox,
  searchable,
  searchPlaceholder = 'paymentDashboard.search.placeholder',
  clearLabelMessage = 'paymentDashboard.search.filters.clearLabelMessage',
  noResultMessage = 'paymentDashboard.search.filters.noResultsMessage',
  onChange,
}: Props) => {
  const intl = useIntl();
  const [filteredOptions, setFilteredOptions] = useState<ExtendedSelectOption[]>(options);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hasSelectedOptions, setHasSelectedOptions] = useState<boolean>(false);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const normalizedQuery = query?.toLowerCase().trim();

    setFilteredOptions(
      !normalizedQuery
        ? options
        : options.filter(({ label, values }) =>
            intl
              .formatMessage({ id: label }, values)
              ?.toLowerCase()
              .trim()
              ?.includes(normalizedQuery)
          )
    );
  };

  useEffect(() => {
    handleSearch(searchQuery);
    setHasSelectedOptions(options.some(({ value }) => value));
  }, [options]);

  const handleSelection = (updatedOptions) => {
    onChange(
      unionBy(updatedOptions, options, ({ label, values }) => (values ? values?.vendorName : label))
    );
  };

  const handleClearAll = () =>
    hasSelectedOptions &&
    handleSelection(options.map((option) => ({ ...option, selected: false, value: false })));

  const debouncedHandleSearch = debounce(handleSearch, 250);

  return (
    <MultiSelectWrapper>
      <MultiSelectContentWrapper isSearchable={searchable}>
        {searchable && (
          <MultiSelectSearchInput
            placeholder={intl.formatMessage({ id: searchPlaceholder })}
            onChange={debouncedHandleSearch}
          />
        )}
        {type === SelectType.Checkbox && (
          <CheckboxSelect options={filteredOptions} onChange={handleSelection} />
        )}
        {!filteredOptions.length && (
          <NoResultMessage>
            <MIFormattedText label={noResultMessage} />
          </NoResultMessage>
        )}
      </MultiSelectContentWrapper>
      <Divider />
      <ClearLabel hasSelectedOptions={hasSelectedOptions} onClick={handleClearAll}>
        <MIFormattedText label={clearLabelMessage} />
      </ClearLabel>
    </MultiSelectWrapper>
  );
};

export { MultiSelect };

const MultiSelectWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.ds.white};
  border: 1px solid ${(props) => props.theme.colors.ds.gray[400]};
  box-shadow: 0 2px 10px 0 ${(props) => props.theme.colors.ds.gray[500]};
`;

const MultiSelectContentWrapper = styled.div<{ isSearchable?: boolean }>`
  width: 22.3rem;
  padding-top: ${(props) => (props.isSearchable ? '0.7rem' : 0)};
`;

const NoResultMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  color: ${(props) => props.theme.text.color.grey};
  line-height: 2rem;
  font-size: 1.4rem;
  font-weight: 400;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
`;

const ClearLabel = styled.div<{ hasSelectedOptions: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(22.3rem - (1.4rem * 2));
  padding: 1.2rem 1.4rem;
  color: ${(props) =>
    props.hasSelectedOptions ? props.theme.colors.ds.blue[100] : props.theme.colors.ds.gray[300]};
  cursor: ${(props) => (props.hasSelectedOptions ? 'pointer' : 'initial')};
  line-height: 2rem;
  user-select: none;
  font-size: 1.4rem;
  font-weight: 400;
`;
