import mergeWith from 'lodash/mergeWith';
import unionWith from 'lodash/unionWith';
import api from '../../services/api/userManagement';
import {
  createApiCallSlice,
  ON_REQUEST,
  ON_SUCCESS,
  ON_FAILURE,
} from '../../helpers/redux/createApiCallSlice';
import { selectList } from '../../helpers/redux/restListSlice';
import { createDeleteSlice } from '../../helpers/redux/restDeleteSlice';

const managementHashFunc = (payload) => `user-management:${payload.orgId}`;

export function mergeUserOrganizationsCustomizer(objValue, srcValue, key) {
  if (key === 'userOrganizations') {
    return unionWith(
      srcValue,
      objValue,
      (
        a: { organizationId: string | number; userId: string | number },
        b: { organizationId: string | number; userId: string | number }
      ) => a.organizationId === b.organizationId && a.userId === b.userId
    );
  }

  return undefined;
}

function userManagementListReducer(hashFunc) {
  return {
    [ON_REQUEST](state, action) {
      const key = hashFunc(action.payload);

      state.lists[key] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const key = hashFunc(action.meta.identifier);

      state.lists[key] = {
        loading: false,
        order: action.payload.map((e) => e.id),
      };
      (action.payload || []).forEach((listItem) => {
        state.byId[listItem.id] = mergeWith(
          state.byId[listItem.id] || {},
          listItem,
          mergeUserOrganizationsCustomizer
        );
      });
    },
    [ON_FAILURE](state, action) {
      const key = hashFunc(action.meta.identifier);

      state.lists[key] = { loading: false, error: action.error };
    },
  };
}

// TODO: convert to use createListSlice
const userManagementListSlice = createApiCallSlice({
  api: api.list,
  initialState: {
    lists: {},
  },
  name: '[USERS] USER_MANAGEMENT_LIST',
  reducers: userManagementListReducer(managementHashFunc),
  select: selectList(managementHashFunc),
});

const userManagementUpdateSlice = createApiCallSlice({
  api: api.update,
  name: '[USERS] USER_MANAGEMENT_UPDATE',
  reducers: {
    [ON_REQUEST](state: any, action) {
      state.update[action.payload.id] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      delete state.update[action.payload.id];
      state.byId[action.payload.id] = mergeWith(
        state.byId[action.payload.id] || {},
        action.payload,
        mergeUserOrganizationsCustomizer
      );
    },
    [ON_FAILURE](state, action) {
      state.update[action.payload.id] = { loading: false, error: action.error };
    },
  },
});
const userManagementDeleteSlice = createDeleteSlice({
  api: api.delete,
  storeName: 'users',
});

export { userManagementListSlice, userManagementUpdateSlice, userManagementDeleteSlice };
