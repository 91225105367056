import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { OptionalDeliveryMethodsType } from 'src/app/utils/types';

import {
  EXPEDITE_OPTIONS,
  TIMELINE_STEP_STAGES,
  TIMELINE_STEP_STATUSES,
  TIMELINE_STEP_TRANSLATION_ROOT_PATH,
} from '../consts';
import { TimelineStepType, VendorPaymentSectionProps } from '../types';
import { getScheduledStep } from './get-common-steps';
import { getPaymentDeliveryStatus } from './steps-statuses';

const { SUCCEED } = TIMELINE_STEP_STATUSES;
const { DEDUCTION, DELIVERY } = TIMELINE_STEP_STAGES;

const getExpediteOrigin = (
  originalDeliveryMethodType: OptionalDeliveryMethodsType
): EXPEDITE_OPTIONS =>
  originalDeliveryMethodType === DELIVERY_TYPE.CHECK
    ? EXPEDITE_OPTIONS.FROM_CHECK
    : EXPEDITE_OPTIONS.FROM_ACH;

const getDebitCardDeductionHint = ({
  expediteSource,
  debitCardClearingStatus,
  baseTranslationPath,
}: {
  debitCardClearingStatus: TIMELINE_STEP_STATUSES;
  expediteSource: EXPEDITE_OPTIONS;
  baseTranslationPath: string;
}): Pick<TimelineStepType, 'hint'> =>
  debitCardClearingStatus === TIMELINE_STEP_STATUSES.FUTURE_STEP &&
  expediteSource === EXPEDITE_OPTIONS.FROM_CHECK
    ? { hint: `${baseTranslationPath}.${DEDUCTION}.${expediteSource}.hint` }
    : {};

const getDebitCardDeliveryHint = ({
  expediteSource,
  debitCardClearingStatus,
  baseTranslationPath,
}: {
  debitCardClearingStatus: TIMELINE_STEP_STATUSES;
  expediteSource: EXPEDITE_OPTIONS;
  baseTranslationPath: string;
}): Pick<TimelineStepType, 'hint'> =>
  debitCardClearingStatus === TIMELINE_STEP_STATUSES.FUTURE_STEP &&
  expediteSource === EXPEDITE_OPTIONS.FROM_ACH
    ? { hint: `${baseTranslationPath}.${DELIVERY}.${expediteSource}.hint` }
    : {};

const getPushToDebitSteps = ({ payment }: VendorPaymentSectionProps): TimelineStepType[] => {
  const baseTranslationPath = `${TIMELINE_STEP_TRANSLATION_ROOT_PATH}.${payment.deliveryMethod.deliveryType}`;
  const debitCardClearingStatus = getPaymentDeliveryStatus(payment);
  const originalDeliveryMethodType = payment?.originalDeliveryMethod?.deliveryType;

  const expediteSource = originalDeliveryMethodType
    ? getExpediteOrigin(originalDeliveryMethodType)
    : EXPEDITE_OPTIONS.FROM_ACH;

  const getDebitCardDeductionStep = (): TimelineStepType => ({
    title: `${baseTranslationPath}.${DEDUCTION}.${expediteSource}.title`,
    subtitle: `${baseTranslationPath}.${DEDUCTION}.subtitle`,
    subtitleValues: {
      card4digits: payment.deliveryMethod.cardAccount?.card4digits || '',
      network: payment.deliveryMethod.cardAccount?.network || '',
    },
    dates: [payment.scheduledDate],
    status: SUCCEED,
    nextStepStatus: debitCardClearingStatus,
    ...getDebitCardDeductionHint({ debitCardClearingStatus, expediteSource, baseTranslationPath }),
  });

  const getDebitCardClearingStep = (): TimelineStepType => ({
    title: `${baseTranslationPath}.${DELIVERY}.title.${debitCardClearingStatus}`,
    dates: [payment.scheduledDate],
    status: debitCardClearingStatus,
    ...getDebitCardDeliveryHint({ debitCardClearingStatus, expediteSource, baseTranslationPath }),
  });

  const scheduledStep = { ...getScheduledStep(payment), nextStepStatus: SUCCEED };
  const deductionStep = getDebitCardDeductionStep();
  const clearingStep = getDebitCardClearingStep();

  return [scheduledStep, deductionStep, clearingStep];
};

export { getPushToDebitSteps };
