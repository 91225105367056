import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeliveryMethod } from 'src/app/version-2/model/dtos';
import { ValidateCardAccountWithTokenRequest } from 'src/app/version-2/pages/add-card-account/api/deliveryMethod/requests/ValidateCardAccountWithTokenRequest';
import { ADD_CARD_ACCOUNT } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.reducer';

export const ADD_CARD_ACCOUNT_DELIVERY_METHOD_SLICE = 'addCardAccountAsDeliveryMethod';
export const selectState = (state) =>
  state[ADD_CARD_ACCOUNT][
    ADD_CARD_ACCOUNT_DELIVERY_METHOD_SLICE
  ] as AddCardAccountDeliveryMethodSlice;

export interface AddCardAccountDeliveryMethodSlice {
  validationStatus: {
    error?: {
      code?: string;
      message?: string;
    };
    code?: string;
    message?: string;
  };
  createCardAccountStatus: {
    error?: {
      code?: string;
      message?: string;
    };
    deliveryMethod?: DeliveryMethod;
    code?: string;
    message?: string;
  };
}

export const getAddCardDeliveryMethodSlice = (): AddCardAccountDeliveryMethodSlice => ({
  validationStatus: {
    code: '',
    message: '',
  },
  createCardAccountStatus: {},
});

export const addCardAccountDeliveryMethodSlice = createSlice({
  name: ADD_CARD_ACCOUNT_DELIVERY_METHOD_SLICE,
  initialState: getAddCardDeliveryMethodSlice(),
  reducers: {
    setValidateCardStatus: (
      state,
      action: PayloadAction<AddCardAccountDeliveryMethodSlice['validationStatus']>
    ) => {
      state.validationStatus = action.payload;
    },
    resetValidationCardStatus: (state) => {
      state.validationStatus = {};
    },
    setCreateCardAccountStatus: (
      state,
      action: PayloadAction<AddCardAccountDeliveryMethodSlice['createCardAccountStatus']>
    ) => {
      state.createCardAccountStatus = action.payload;
    },
  },
});

export const addCardAccountDeliveryMethodActions = {
  ...addCardAccountDeliveryMethodSlice.actions,
  validateCard: createAction<
    ValidateCardAccountWithTokenRequest & {
      location: any;
      history: any;
    }
  >(`${ADD_CARD_ACCOUNT_DELIVERY_METHOD_SLICE}/validateCardAccount`),
  createCardAccount: createAction<{
    token: string;
    card4digits: string;
    cardToken: string;
    cardBin: string;
    expiration: string;
    line1: string;
    zipcode: string;
    state: string;
    city: string;
    firstName: string;
    lastName: string;
  }>(`${ADD_CARD_ACCOUNT_DELIVERY_METHOD_SLICE}/createCardAccount`),
};

const selectValidationCardAccount = createSelector(selectState, (state) => state.validationStatus);
const selectCreateCardAccount = createSelector(
  selectState,
  (state) => state.createCardAccountStatus
);

export const addCardAccountDeliveryMethodSelectors = {
  selectValidationCardAccount,
  selectCreateCardAccount,
};
