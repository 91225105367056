import first from 'lodash/first';
import omit from 'lodash/omit';
import {
  DashboardListItemTypeResponse,
  OldDashboardListItemType,
} from 'src/app/pages/qb-dashboard/types';
import { QB_DASHBOARD_TAB_STATUS } from 'src/app/pages/qb-dashboard/consts';

import analytics from 'src/app/services/analytics';
import { convertStart, convertLimit } from 'src/app/utils/pagination-utils';
import { BillType, DeliveryMethodType, DeliveryOptionType } from 'src/app/utils/types';
import { getDashboardItemId } from 'src/app/pages/qb-dashboard/utils';
import { fetchRequest, postRequest, putRequest, deleteRequest } from './api';
import { Options } from 'src/app/version-2/api/rest/rest.api';
import { DbAnalyticsTraitsEnum, PaymentCreateFlowOriginEnum } from 'src/app/version-2/model/enums';

export type FilterType = {
  start?: number;
  limit?: number;
  [key: string]: any;
};

type DashboardListResponse = {
  totalCount: number;
  items: DashboardListItemTypeResponse[];
};

type OldDashboardListResponse = {
  totalCount: number;
  items: OldDashboardListItemType[];
};

export type DashboardItemMetadataParams = {
  orgId: string;
  params: {
    billId?: string;
    paymentId?: string;
    pageSize: number;
  };
  options?: Options;
};

export type DashboardItemMetadataResponse = {
  code: string;
  message?: string;
  start: number;
  status: QB_DASHBOARD_TAB_STATUS;
  billId: string;
};

type MarkAsPaidParams = {
  orgId: string;
  id: string;
  isPaid: boolean;
  intuitAccountId?: string | null;
  paymentId?: number | null;
  createOrigin?: PaymentCreateFlowOriginEnum;
  amount?: number | null;
};

export default {
  getBills({
    orgId,
    filters = {},
    scope = undefined,
  }: {
    orgId: string;
    filters: FilterType;
    scope?: string;
  }) {
    const url = `/orgs/${orgId}/bills`;
    const params = {
      ...filters,
      scope,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  getBillList({
    orgId,
    filters = {},
    scope = undefined,
  }: {
    orgId: string;
    filters: FilterType;
    scope?: string;
  }) {
    const url = `/orgs/${orgId}/bills/billList`;
    const params = {
      ...filters,
      scope,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },
  getQBDashboardList({ orgId, filters }): Promise<DashboardListResponse> {
    const url = `/orgs/${orgId}/bills/qb-dashboard-list`;

    return fetchRequest(url, filters).then(({ items, totalCount }: OldDashboardListResponse) => ({
      items: items.map((item) => {
        const { vendor, payments, metadata, ...restBill } = item;
        const bill = { ...restBill, balance: restBill.totalAmount };
        let payment = first(payments);
        const fundingSource = payment?.fundingSource;
        const deliveryMethod = payment?.deliveryMethod;

        if (payment) {
          payment = omit(payment, ['fundingSource', 'deliveryMethod']);
        }

        return {
          billId: bill.id,
          vendorId: vendor.id,
          paymentId: payment?.id,
          fundingSourceId: fundingSource?.id,
          deliveryMethodId: deliveryMethod?.id,
          bill,
          vendor,
          payment,
          deliveryMethod,
          fundingSource,
          metadata,
          id: getDashboardItemId(bill.id, payment?.id),
        };
      }),
      totalCount,
    }));
  },
  getNewQBDashboardList({ orgId, filters }): Promise<DashboardListResponse> {
    const url = `/orgs/${orgId}/bills/new-qb-dashboard-list`;

    return fetchRequest(url, filters).then(({ items, totalCount }) => ({
      items: items.map((item) => {
        const { billId, paymentId } = item;

        return {
          ...item,
          id: getDashboardItemId(billId, paymentId),
        };
      }),
      totalCount,
    }));
  },
  getQBDashboardItemMetadata({
    orgId,
    params,
    options,
  }: DashboardItemMetadataParams): Promise<DashboardItemMetadataResponse> {
    const url = `/orgs/${orgId}/bills/qb-dashboard-item-metadata`;

    return fetchRequest(url, params, options);
  },
  getNewQBDashboardItemMetadata({
    orgId,
    params,
    options,
  }: DashboardItemMetadataParams): Promise<DashboardItemMetadataResponse> {
    const url = `/orgs/${orgId}/bills/new-qb-dashboard-item-metadata`;

    return fetchRequest(url, params, options);
  },
  getInternalBills({ orgId, filters = {} }: { orgId: string; filters: FilterType }) {
    const url = `/orgs/${orgId}/bills/internal`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  getBillById({ orgId, id }) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return fetchRequest(url);
  },

  getVendorBillById({ orgId, id }) {
    const url = `/orgs/${orgId}/bills/vendor/${id}`;

    return fetchRequest(url);
  },

  getQBOBillAttachments({ orgId, originId }) {
    const url = `/orgs/${orgId}/quickbooks/bill/${originId}/attachments`;

    return fetchRequest(url);
  },

  generatePdfInvoice(orgId, billId) {
    const url = `/orgs/${orgId}/bills/${billId}/pdf-invoice`;

    return fetchRequest(url);
  },

  createBill(orgId, params) {
    const url = `/orgs/${orgId}/bills`;

    analytics.setTraits({ [DbAnalyticsTraitsEnum.CREATE_A_BILL]: true });

    return postRequest(url, params);
  },

  editBillById(orgId, id, params, scope) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return putRequest(url, { ...params, scope });
  },

  deleteBillById(orgId, id) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return deleteRequest(url);
  },

  markAsPaid({
    orgId,
    id,
    isPaid,
    intuitAccountId,
    paymentId,
    createOrigin,
    amount,
  }: MarkAsPaidParams) {
    const url = `/orgs/${orgId}/bills/${id}/mark-as-paid`;

    return postRequest(url, {
      isPaid,
      intuitAccountId: intuitAccountId || null,
      amount: amount || null,
      paymentId: paymentId || null,
      createOrigin,
    });
  },

  batchMarkAsPaid({ orgId, items, intuitAccountId, createOrigin }) {
    const url = `/orgs/${orgId}/bills/batch-mark-as-paid`;

    return postRequest(url, { items, intuitAccountId, createOrigin });
  },

  getBillsStatusCount(orgId) {
    const url = `/orgs/${orgId}/bills/bills-status-count`;

    return fetchRequest(url);
  },
  getVendorBills({ orgId, filters = {} }: { orgId: string; filters: FilterType }) {
    const url = `/orgs/${orgId}/bills/vendor-bills`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  getBillsByIds({
    orgId,
    billIds,
    isByVendor,
    vendorOriginId,
  }: {
    orgId: string;
    isByVendor?: boolean;
    billIds?: string[];
    vendorOriginId: number | null;
  }) {
    let url;

    if (isByVendor && vendorOriginId) {
      url = `/orgs/${orgId}/bills/batch/?vendorOriginId=${vendorOriginId}&isByVendor=${isByVendor}`;
    } else {
      url = `/orgs/${orgId}/bills/batch/?billIds=${billIds?.join()}`;
    }

    return fetchRequest(url) as Promise<BatchFetchBillResult>;
  },
  getPayBillInitialData({ orgId, originId }) {
    const url = `/orgs/${orgId}/quickbooks/bill/${originId}/single-pay-data`;

    return fetchRequest(url);
  },
  getNextQboBillToPay({ orgId, params }) {
    const url = `/orgs/${orgId}/bills/next-bill-to-pay`;

    return fetchRequest(url, params);
  },
};
export type DefaultDateType = {
  billId: number;
  defaultFundingSourceId?: number;
  defaultDates?: {
    actualDeliveryDays: number;
    deliveryDate: string;
    maxDeliveryDate: string;
    suggestedScheduledDate: string;
  };
  defaultDeliveryMethod?: DeliveryMethodType;
};

export type BatchFetchBillResult = {
  billList: Record<
    string,
    {
      defaultDate: DefaultDateType;
      deliveryOptions: DeliveryOptionType[];
      bill: BillType;
    }
  >;
  totalCount: number;
};

export type BatchFetchBillResponse = {
  payload: BatchFetchBillResult;
};

export const billListRestApi = {
  list({
    orgId,
    filters = {},
    scope = undefined,
  }: {
    orgId: string;
    filters: FilterType;
    scope?: string;
  }) {
    const url = `/orgs/${orgId}/bills/qbrBillList`;
    const params = {
      ...filters,
      scope,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params).then((res) => ({
      items: [...res.objects],
      totalCount: res.totalCount,
    }));
  },
  update({ orgId, id, params, scope }) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return putRequest(url, { ...params, scope });
  },
  create({ orgId, params }) {
    const url = `/orgs/${orgId}/bills`;

    analytics.setTraits({ [DbAnalyticsTraitsEnum.CREATE_A_BILL]: true });

    return postRequest(url, params).then((res) => res.object);
  },
  delete({ orgId, id }) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return deleteRequest(url);
  },
  fetch({ orgId, id }) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return fetchRequest(url);
  },
};
