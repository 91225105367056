import { useMemo } from 'react';
import { Flex } from '@melio/billpay-design-system';

import { useForm } from 'src/app/ui/form';
import { MITextInput } from 'src/app/components/common/MITextInput';
import { TextInputSizeEnum } from 'src/app/version-2/model/enums';

import { TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH } from '../../consts';
import { FormProps } from '../../types';
import { EditPaymentFormFooter } from './EditPaymentFormFooter';

const EditPaymentBankDetailsForm = ({
  payment,
  labels,
  isMobile,
  validationErrors,
  isLoading,
  onSubmit,
  onClose,
}: FormProps) => {
  const model = useMemo(
    () => ({
      routingNumber: payment.deliveryMethod.bankAccount?.routingNumber || '',
      accountNumber: payment.deliveryMethod.bankAccount?.accountNumber || '',
    }),
    [payment.deliveryMethod.bankAccount]
  );

  const [values, { submit }] = useForm(model, { submit: onSubmit });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Flex direction="column" gridGap={8}>
        <Flex gridGap={6} justifyContent="center" alignItems="flex-start">
          <MITextInput
            id="routingNumber"
            value={values.routingNumber.value}
            label={labels.routingNumber}
            type="text"
            autoFocus
            required
            onChange={values.routingNumber.onChange}
            errorMessage={validationErrors.routingNumber}
            autocomplete="off"
            size={TextInputSizeEnum.INLINE}
            autoHeightForNotice
          />
          <MITextInput
            id="accountNumber"
            value={values.accountNumber.value}
            label={labels.accountNumber}
            type="text"
            required
            onChange={values.accountNumber.onChange}
            errorMessage={validationErrors.accountNumber}
            autocomplete="off"
            size={TextInputSizeEnum.INLINE}
            autoHeightForNotice
          />
        </Flex>
        <EditPaymentFormFooter
          primaryLabel={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.button.save`}
          secondaryLabel={`${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.button.cancel`}
          onSubmit={submit}
          onClose={onClose}
          isLoading={isLoading}
          isMobile={isMobile}
        />
      </Flex>
    </form>
  );
};

export { EditPaymentBankDetailsForm };
