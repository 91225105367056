import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';

export const Content = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  box-sizing: border-box;
  border-radius: 0.6rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 6rem;
  }
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.border.lightGrey};
  height: 0.4rem;
  width: 83%;
  margin: 2rem auto;
`;
