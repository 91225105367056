import { devices } from 'src/app/version-2/utils/devices.utils';

import styled from 'styled-components';

export const CardDetailsContainer = styled.div`
  width: 100%;
  max-width: 45rem;

  @media ${devices.mobile} {
    margin: 0 1.6rem;
  }
`;

export const CardNumber = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 4rem;
`;

export const CardValidations = styled.div`
  display: flex;
  gap: 4rem;
`;

export const CardDetailsContainerWrapper = styled.div<{ pageLoading?: boolean; isTitle?: boolean }>`
  display: ${({ pageLoading }) => (pageLoading ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90%;
  margin-top: ${({ isTitle }) => (isTitle ? 0 : '2rem')};
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;
`;

export const LoaderWrapper = styled.div`
  height: 36.8rem;
`;

export const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.sizes.ds.md};

  ${({ theme }) => theme.textStyles.ds.h5};
`;

export const SubTitle = styled.div`
  text-align: center;
  max-width: 45rem;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.sizes.ds['2xl']};

  ${({ theme }) => theme.textStyles.ds.body2};
`;

export const FieldInputWrapperContainer = styled.div<{ error: boolean; focus: boolean }>`
  border-bottom: 1px;
  width: 100%;
  border-bottom-style: solid;
  color: ${({ theme }) => theme.colors.ds.gray[400]};
  color: ${({ theme, error }) => error && theme.colors.ds.red[100]};
  color: ${({ theme, focus }) => focus && theme.colors.ds.black};
  ${({ theme }) => theme.textStyles.ds.body3};
`;
