import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SmartIcon, Switch, Tooltip } from '@melio/billpay-design-system';
import { featureFlags } from '@melio/shared-web';
import { analytics, MIFormattedCurrency } from 'src/app/version-2/externals';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { DeliveryEnum, FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import { fundingSourcesSelectors } from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import { organizationSelectors } from 'src/app/version-2/modules/organization/organization.slice';
import * as Styles from 'src/app/version-2/pages/batch-bulk/components/HeadSection/HeadSection.styles';
import { PaymentMethodMenu } from 'src/app/version-2/pages/batch-bulk/components/PaymentMethodMenu';
import {
  BATCH_BULK_EVENT_PAGE,
  BATCH_BULK_PAYMENT_METHOD_SELECT,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkPaymentIntentItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import {
  batchBulkActions,
  batchBulkSelectors,
} from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { DeductionDateSelect } from 'src/app/version-2/pages/batch-bulk/renderers';
import {
  BY_DUE_DATE_SELECTOR,
  DEDUCTION_DATE_TYPES,
  DeductionDateObjectType,
} from 'src/app/version-2/pages/batch-bulk/renderers/DeductionDateSelect/DeductionDateSelect.consts';
import { isValidPaymentMethod } from './utils';

export interface IHeadSection {
  onCombineChanged: (e) => void;
  isCombined: boolean;
  selectedFundingSourceId?: number;
  partialPayments: Record<string, number>;
}

export const HeadSection = ({ onCombineChanged, isCombined, partialPayments }: IHeadSection) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [paymentMethod, setPaymentMethod] = useState<FundingSource>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [minScheduleDate, setMinScheduleDate] = useState<Date>(new Date());
  const [deductionType, setDeductionType] = useState<DeductionDateObjectType>(
    DEDUCTION_DATE_TYPES[1]
  );

  const [shouldUncombineBulkFF] = featureFlags.useFeature(FeatureFlagsEnum.BATCH_UNCOMBINED, false);
  const [sectionVisible, setSectionVisible] = useState<boolean>(!shouldUncombineBulkFF);

  const isCombineSwitchDisabled: boolean = useSelector(
    batchBulkSelectors.selectIsCombineSwitchDisabled
  );

  const isBulkPaymentMethod = useSelector(batchBulkSelectors.selectIsBulkPaymentMethod);
  const repeatingFundingSource = useSelector(batchBulkSelectors.selectRepeatingFundingSource);
  const feeFundingSource = useSelector(organizationSelectors.selectOrgFeeFundingSource);
  const fundingSources = useSelector(fundingSourcesSelectors.selectValidFundingSources);
  const deliveryMethods = useSelector(batchBulkSelectors.selectDeliveryMethods);
  const isLoading = useSelector(batchBulkSelectors.selectIsLoading);

  const batchBulks: BatchBulkPaymentIntentItem[] = useSelector(
    batchBulkSelectors.selectPaymentIntentsItems
  );

  const totalAmount: number = useSelector(batchBulkSelectors.selectTotalAmount) || 0;
  const checkFee: number = useSelector(batchBulkSelectors.selectTotalCheckFee) || 0;
  const cardFee: number = useSelector(batchBulkSelectors.selectTotalCardFees) || 0;
  const additionalFee: number = useSelector(batchBulkSelectors.selectAdditionalFee) || 0;
  const totalFee = (useSelector(batchBulkSelectors.selectTotalFee) || 0) as number;
  const isCombinedOn = useSelector(batchBulkSelectors.selectIsCombined);
  const isCombinedDisabled = useSelector(batchBulkSelectors.selectIsCombineSwitchDisabled);
  const hasBeenCombinedByFeature = useSelector(batchBulkSelectors.selectHasBeenCombinedByFeature);
  const splitAmount = totalAmount.toFixed(2).split('.');
  const integerAmount = +splitAmount[0];
  const floatingPointAmount = splitAmount[1];
  const shouldDisplayPaymentMethod = isBulkPaymentMethod || !!repeatingFundingSource;

  const isInternational = deliveryMethods.includes(DeliveryEnum.INTERNATIONAL);
  const isVirtualCard = deliveryMethods.includes(DeliveryEnum.VIRTUAL_CARD);

  useEffect(() => {
    if (!!shouldUncombineBulkFF) {
      if (!isCombinedDisabled && isCombinedOn && !hasBeenCombinedByFeature) {
        dispatch(
          batchBulkActions.setIsCombined({
            isCombined: false,
            partialPayments,
          })
        );
      }

      dispatch(batchBulkActions.setHasBeenUncombinedByFeature(true));

      // avoiding combine switch toggle off animation
      setTimeout(() => {
        setSectionVisible(true);
      }, 200);
    }
  }, []);

  useEffect(() => {
    setPaymentMethod(repeatingFundingSource || fundingSources[0]);

    if (repeatingFundingSource === false) {
      dispatch(batchBulkActions.setHeaderRepeatingPaymentMethod(undefined));
    }
  }, [repeatingFundingSource, isCombined]);

  useEffect(() => {
    setDeductionType(DEDUCTION_DATE_TYPES[1]);
  }, [isCombined]);

  useEffect(() => {
    let batchBulkMinScheduleDate: Date | undefined = undefined;

    batchBulks.forEach((bill) => {
      if (
        bill?.minScheduledDate &&
        (!batchBulkMinScheduleDate || bill?.minScheduledDate > batchBulkMinScheduleDate)
      )
        batchBulkMinScheduleDate = bill.minScheduledDate;
    });

    if (batchBulkMinScheduleDate) setMinScheduleDate(batchBulkMinScheduleDate);
  }, [paymentMethod]);

  const updatePaymentMethods = (fundingSource: FundingSource) => {
    setPaymentMethod(fundingSource);
    dispatch(batchBulkActions.setHeaderRepeatingPaymentMethod(fundingSource.id));

    if (deductionType.id === BY_DUE_DATE_SELECTOR) {
      // fetch new batch bulk by fundingsource
      return dispatch(
        batchBulkActions.refetchBatchBulksByPaymentMethod({
          fundingSourceId: fundingSource.id,
          partialPayments,
        })
      );
    }

    dispatch(
      batchBulkActions.updateBulkPaymentMethods({
        batchBulks,
        fundingSourceId: fundingSource.id,
      })
    );
  };

  const updateBulkDates = (deductionDate: { type: string; date?: Date | null }) => {
    dispatch(
      batchBulkActions.updateBulkDeductionDate({
        ...deductionDate,
      })
    );
  };

  const createFundingSource = () => {
    dispatch(
      batchBulkActions.createFundingSource({
        selectedPaymentIntent: {
          paymentIntentId: '-1',
        },
        history,
      })
    );
  };

  const menuTriggerHandler = () => setIsMenuOpen(!isMenuOpen);

  return (
    <Styles.HeadSectionWrap $sectionVisible={sectionVisible}>
      <div>
        <Styles.Title>
          <span id="head-section-title">
            {formatMessage({
              id: 'batchBulkPage.headSection.title',
            })}
          </span>
        </Styles.Title>
        <Styles.SmallSubTitle>
          {formatMessage({
            id: 'batchBulkPage.headSection.subTitle',
          })}
        </Styles.SmallSubTitle>
        <Styles.Row>
          <Styles.Column>
            <Styles.LabelText>
              <Styles.MultipleBillsText $isDisabled={isCombineSwitchDisabled}>
                {formatMessage({
                  id: 'batchBulkPage.headSection.multipleBills.text',
                })}
              </Styles.MultipleBillsText>
              <Tooltip
                label={formatMessage({
                  id: 'batchBulkPage.headSection.tooltip.text',
                })}
                placement="top"
              >
                <Styles.InfoIconContainer $isDisabled={isCombineSwitchDisabled}>
                  <SmartIcon type="info" />
                </Styles.InfoIconContainer>
              </Tooltip>
            </Styles.LabelText>
            <Styles.SwitchWrap>
              <Switch
                onChange={onCombineChanged}
                defaultChecked={isCombined}
                isChecked={isCombined}
                isDisabled={isCombineSwitchDisabled || isLoading}
                data-testid="combine-bills-switch"
              />
              <Tooltip
                label={formatMessage({
                  id: 'batchBulkPage.headSection.combine.tooltip.text',
                })}
                placement="right"
                isOpen={isCombineSwitchDisabled ? undefined : false}
              >
                <Styles.CombineBillsText $isDisabled={isCombineSwitchDisabled}>
                  {formatMessage({
                    id: 'batchBulkPage.headSection.combine.switch',
                  })}
                </Styles.CombineBillsText>
              </Tooltip>
            </Styles.SwitchWrap>
          </Styles.Column>
          <Styles.Column>
            <Styles.LabelText>
              {formatMessage({ id: 'batchBulkPage.headSection.paymentMethod' })}
            </Styles.LabelText>
            <PaymentMethodMenu
              data-testid="batch-bulk-payment-method"
              renderers={{
                PaymentMethodMenuButton: Styles.MethodsMenuButton,
                PaymentMethodButton: Styles.PaymentMethodButton,
                PaymentFundingSourceImage: Styles.PaymentFundingSourceImage,
              }}
              selectedPaymentMethod={shouldDisplayPaymentMethod ? paymentMethod : undefined}
              onMenuOpen={menuTriggerHandler}
              onMenuClose={menuTriggerHandler}
              paymentMethods={fundingSources}
              onCreateFundingSource={createFundingSource}
              onMethodSelect={(fundingSource) => {
                analytics.track(BATCH_BULK_EVENT_PAGE, BATCH_BULK_PAYMENT_METHOD_SELECT, {
                  fundingSourceId: fundingSource.id,
                  fundingSourceType: fundingSource.fundingType,
                });

                updatePaymentMethods(fundingSource);
              }}
              emptyText={formatMessage({ id: 'batchBulkPage.headSection.noneSelected' })}
              multipleText={formatMessage({ id: 'batchBulkPage.headSection.multipleMethod' })}
              bulkSelect={isBulkPaymentMethod}
              checkIfDisabled={(pm) =>
                isValidPaymentMethod({ paymentMethod: pm, isInternational, isVirtualCard })
              }
              showTooltip
            />
          </Styles.Column>
          <Styles.ExpendableColumn>
            <Styles.LabelText>
              {formatMessage({ id: 'batchBulkPage.headSection.deductionDate.title' })}
            </Styles.LabelText>
            <DeductionDateSelect
              onChange={updateBulkDates}
              disabled={!isBulkPaymentMethod && !repeatingFundingSource}
              deductionType={deductionType}
              date={minScheduleDate}
              minScheduleDate={minScheduleDate}
              setDeductionType={setDeductionType}
            />
          </Styles.ExpendableColumn>
        </Styles.Row>
      </div>

      <Styles.RightHeadSection>
        <Styles.SmallSubTitle>
          {formatMessage({ id: 'batchBulkPage.headSection.totalAmount' })}
        </Styles.SmallSubTitle>
        <Styles.AmountTitle>
          <MIFormattedCurrency value={integerAmount} round data-testid="batch-bulk-total-amount" />
          <Styles.AmountSup data-testid="batch-bulk-total-amount-float">
            {floatingPointAmount}
          </Styles.AmountSup>
        </Styles.AmountTitle>
        <Styles.FeeWrap>
          {!!checkFee && (
            <>
              <Styles.FeeTitle>
                {!!feeFundingSource && (
                  <Tooltip
                    label={formatMessage({ id: 'batchBulkPage.headSection.checkFee.tooltip' })}
                    placement="top"
                  >
                    <Styles.InfoIconContainer>
                      <SmartIcon type="info" />
                    </Styles.InfoIconContainer>
                  </Tooltip>
                )}

                {formatMessage({ id: 'batchBulkPage.headSection.checkFee.text' })}
              </Styles.FeeTitle>
              <Styles.FeeTitle>
                +<MIFormattedCurrency value={checkFee} data-testid="batch-bulk-check-fee" />
              </Styles.FeeTitle>
            </>
          )}

          {!!cardFee && (
            <>
              <Styles.FeeTitle>
                {formatMessage({ id: 'batchBulkPage.headSection.cardFee' })}
              </Styles.FeeTitle>
              <Styles.FeeAmount>
                +<MIFormattedCurrency value={cardFee} data-testid="batch-bulk-card-fee" />
              </Styles.FeeAmount>
            </>
          )}

          {!!additionalFee && (
            <>
              <Styles.FeeTitle>
                <Tooltip
                  label={formatMessage({ id: 'batchBulkPage.headSection.additionalFee.tooltip' })}
                  placement="top"
                >
                  <Styles.InfoIconContainer>
                    <SmartIcon type="info" />
                  </Styles.InfoIconContainer>
                </Tooltip>
                {formatMessage({ id: 'batchBulkPage.headSection.additionalFee.text' })}
              </Styles.FeeTitle>
              <Styles.FeeAmount>
                +
                <MIFormattedCurrency
                  value={additionalFee}
                  data-testid="batch-bulk-additional-fee"
                />
              </Styles.FeeAmount>
            </>
          )}
          <>
            <Styles.FeeTitle $isBold>
              {formatMessage({ id: 'batchBulkPage.headSection.totalFee' })}
            </Styles.FeeTitle>
            <Styles.FeeAmount $isBold>
              <MIFormattedCurrency value={totalFee} data-testid="batch-bulk-total-fee" />
            </Styles.FeeAmount>
          </>
        </Styles.FeeWrap>
      </Styles.RightHeadSection>
    </Styles.HeadSectionWrap>
  );
};

export default HeadSection;
