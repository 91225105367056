import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BillType, PaymentType } from 'src/app/utils/types';
import { getBill, getPayment } from 'src/app/redux/payBillWizard/selectors';
import { AddFundingSourceWizardOriginEnum } from 'src/app/version-2/model/enums';

function useGetBillAndPayment(): [BillType, PaymentType] {
  const { state = {} } = useLocation<any>();
  const { origin } = state;

  const payBillWizardBill = useSelector(getBill);
  const payBillWizardPayment = useSelector(getPayment);

  if (origin === AddFundingSourceWizardOriginEnum.BATCH_BULK) {
    // new batch
    const currentItem = state?.preservedState?.batchList?.find((item) =>
      item.id.includes(`bill-${state.billId}`)
    );
    const payment = currentItem?.payment;
    const bill = payment?.bills?.find((item) => item.id === state.billId);
    const modifiedBill = {
      ...bill,
      // support combined payment
      totalAmount: payment?.amount,
    };

    return [modifiedBill, payment];
  }

  // single flow
  return [payBillWizardBill, payBillWizardPayment];
}

export default useGetBillAndPayment;
