import { fetchRequest } from 'src/app/version-2/externals';
import { FetchFundingSourcesResponse } from './reponses/FetchFundingSourcesResponse';
import { FetchFundingSourcesRequest } from './requests/FetchFundingSourcesRequest';

export const fundingSourcesApi = {
  fetch: ({ orgId, params }: FetchFundingSourcesRequest) =>
    fetchRequest<FetchFundingSourcesResponse>(`/orgs/${orgId}/funding-sources`, params),
  fetchV2: ({ orgId, params }: FetchFundingSourcesRequest) =>
    fetchRequest<FetchFundingSourcesResponse>(`/orgs/${orgId}/qbo/funding-sources`, params),
};
