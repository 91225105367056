import React from 'react';
import { useSelector } from 'react-redux';
import errorIcon from 'src/app/images/qbo/error.svg';
import QBOMIDialog from 'src/app/components/common/QBOMIDialog';
import billsApi from 'src/app/services/api/bills';
import { useModal } from 'src/app/helpers/react/useModal';
import { getOrgId } from 'src/app/redux/user/selectors';
import {
  IMAGE_POSITION,
  NOTIFICATION_VARIANT,
  DIALOG_TYPE,
  DIALOG_VARIANTS,
} from 'src/app/utils/consts';
import { pushNotification } from 'src/app/services/notifications/notificationService';
import { MIFormattedCurrency } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';
import useDashboardListParams from './useDashboardListParams';
import { useLoadDashboardList } from './useLoadDashboardList';

type Response = [React.ReactNode, () => void];

type Props = {
  billId: string;
  companyName: string;
  amount: string;
};

const TEXT_PREFIX = 'paymentDashboard.deleteBillModal';

const useDeleteBillModal = ({ billId, companyName, amount }: Props): Response => {
  const orgId = useSelector(getOrgId);
  const [listParams] = useDashboardListParams();
  const { loadDashboardList } = useLoadDashboardList();

  const deleteBill = async (dismiss: () => void) => {
    try {
      await billsApi.deleteBillById(orgId, billId);
      analytics.track('bills-dashboard', 'delete-bill-submit');

      setTimeout(() => {
        pushNotification({
          type: NOTIFICATION_VARIANT.SUCCESS,
          msg: `${TEXT_PREFIX}.deleteSuccessNotification`,
          textValues: {
            billId,
          },
        });

        loadDashboardList(listParams);
      });
    } catch (e) {
      dismiss();
    }
  };

  const deleteBillDialog = ({ dismiss }: { dismiss: () => void }) => (
    <QBOMIDialog
      image={errorIcon}
      imagePosition={IMAGE_POSITION.LEFT}
      type={DIALOG_TYPE.CONFIRM}
      variant={DIALOG_VARIANTS.WARNING}
      title={`${TEXT_PREFIX}.title`}
      subtitle={`${TEXT_PREFIX}.subtitle`}
      subtitleValues={{
        billId,
        companyName,
        amount: <MIFormattedCurrency value={amount} />,
      }}
      okButtonText={`${TEXT_PREFIX}.dialogDeleteButtonText`}
      onOkAction={() => deleteBill(dismiss)}
      onCancelAction={dismiss}
      cancelButtonText={`${TEXT_PREFIX}.dialogCancelButtonText`}
      showConfirmationText={false}
    />
  );
  const [deleteBillDialogElement, updateModalStatus] = useModal(deleteBillDialog, {
    modalName: 'delete-bill-modal-dialog',
  });

  return [deleteBillDialogElement, updateModalStatus];
};

export default useDeleteBillModal;
