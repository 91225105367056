import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TextInput, Container } from 'src/app/components/common/MITextInput';
import { MIInputLabel } from 'src/app/components/common/MIInputLabel';
import MINotices from 'src/app/components/common/MINotices';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import styled from 'styled-components';
import IMask from 'imask';
import { IMaskMixin } from 'react-imask';

import { ModelViewField } from './use-form';

const MaskedTextInput = IMaskMixin(({ inputRef, ...props }) => (
  <TextInput
    {...(props as any)}
    ref={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
  />
));

export type WizardCurrencyFieldProps = {
  model: ModelViewField<number>;
  label: string;
  type?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hint?: string;
  viewOnly?: boolean;
  autoFocus?: boolean;
  onFocus?: () => void;
};

const mask = IMask.createMask({
  mask: '$num',
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      thousandsSeparator: ',',
      radix: '.',
      padFractionalZeros: true,
    },
  },
});

export function WizardCurrencyField(props: WizardCurrencyFieldProps) {
  const { model, label, placeholder, hint, ...rest } = props;
  const intl = useIntl();
  const id = props.id || model.id;
  const originalOnChange = model.onChange;
  const onChange = useCallback(
    (_, maskValue) => {
      // eslint-disable-next-line no-underscore-dangle
      const value = maskValue.masked._blocks[1].typedValue;

      if (value !== model.value) {
        originalOnChange({ value });
      }
    },
    [originalOnChange, model.value]
  );

  return (
    <Container className="input-container">
      <MIInputLabel
        label={label}
        inputId={id}
        errorMessage={model.error}
        required={props.required}
      />
      <InputWrapper className={FULL_STORY_MASK_RULE_CLASS}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*
          // @ts-ignore */}
        <MaskedTextInput
          {...rest}
          mask={mask}
          unmask="typed"
          value={model?.value?.toString()}
          defaultValue={model.value}
          onAccept={onChange}
          placeholder={placeholder && intl.formatMessage({ id: placeholder })}
          id={id}
          size="none"
        />
        <MINotices
          size="none"
          notices={hint ? [hint] : []}
          errorMessage={model.error}
          testId={`${id}-notices`}
        />
      </InputWrapper>
    </Container>
  );
}

const InputWrapper = styled.div`
  position: relative;
  ${(props) => props.theme?.components?.MITextInput?.InputWrapper}
`;
