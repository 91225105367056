/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import vendorsEventsMapping from 'src/app/pages/vendor/event-mapping';
import quickpayEventMapping from 'src/app/pages/quickpay/event-mapping';
import fundingSourceEventMapping from 'src/app/pages/onboarding/funding-sources/event-mapping';
import { selfServeRefundEventMapping } from 'src/app/pages/qb-dashboard/RefundFlow/analytics/self-serve-refund-event-mapping';
import accountingSoftwareSyncEventsMapping from 'src/app/pages/settings/event-mapping';
import qbDashboardEventsMapping from 'src/app/pages/qb-dashboard/event-mapping';
import justPayEventsMapping from 'src/app/pages/just-pay/just-pay-event-mapping';
import entrypointsEventsMapping from 'src/app/pages/quickbooks/event-mappings';
import { EVENT_MAPPING_NAME } from 'src/app/utils/consts';
import { mapApiSlice } from './event-mapping-utils';
import { EventMappingsTree } from './types';
import { getMemoPageEvents } from './common-events/memo-page-events';
import { vendorUpgradePaymentEventMapping } from 'src/app/version-2/pages/vendor-upgrade-payment/event-mapping';

const DEFAULT_MAPPING_NAME = EVENT_MAPPING_NAME.DEFAULT;

const MAPPINGS: EventMappingsTree = {
  '/orgs/*/settings/payment-methods': {
    'toggle-actions-menu': [
      'settings-payment-methods',
      'toggle-actions-menu',
      { isOpen: 'isOpen' },
    ],
    'add-payment-method-label': [
      'settings-payment-methods',
      'add-payment-method-label',
      { fundingSourceId: 'id' },
    ],
    'add-payment-method-label-success': [
      'settings-payment-methods',
      'add-payment-method-label-successful',
      { fundingSourceId: 'id', fundingSourceLabel: 'label' },
    ],
  },
  '/orgs/*/settings/vendors/*': {
    'toggle-actions-menu': [
      'settings-vendors-delivery-methods',
      'toggle-actions-menu',
      { isOpen: 'isOpen' },
    ],
    'qbo-view-vendors-actions': ['settings-vendors', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/orgs/*/settings/account': {
    '[PROFILE] PASSWORD_CHANGE_REQUEST': ['reset', 'password-continue'],
    '[PROFILE] PASSWORD_CHANGE_FAILURE': ['reset', 'password-validation-error', { error: 'error' }],
    '[PROFILE] PASSWORD_CHANGE_SUCCESS': ['reset', 'password-validation-success'],
    '[USER] UPDATE_USER_PREFERENCE': [
      'settings-account-information',
      'update-${id}',
      { isOn: 'value' },
    ],
    '[USER] UPDATE_USER_PREFERENCE_SUCCESS': [
      'settings-account-information',
      'update-${id}-success',
    ],
    '[USER] UPDATE_USER_PREFERENCE_FAILURE': [
      'settings-account-information',
      'update-${id}-failed',
    ],
    '[USERS] UPDATE_REQUEST': [
      'settings-account-information',
      'update-details',
      { payload: 'payload' },
    ],
    '[USERS] UPDATE_SUCCESS': ['settings-account-information', 'update-details-success'],
    '[USERS] UPDATE_FAILURE': [
      'settings-account-information',
      'update-details-failed',
      { error: 'error' },
    ],
    'profile.changePassword.edit': ['settings-account-information', 'change-password-menu'],
    'profile.details.edit': ['settings-account-information', 'change-password-menu'],
    'general.done': ['settings-account-information', 'save-button'],
    'general.cancel': ['settings-account-information', 'cancel-button'],
  },
  '/orgs/*/settings/company': {
    'get-pro-enabled': ['settings-company', 'turn-on-pro'],
    'get-pro-disabled': ['settings-company', 'turn-off-pro'],
    'copy-link': ['settings-company', 'link-copied'],
    'copy-link-action': ['settings-company', 'meliome-link-clicked'],
    'payments-csv-report.clicked': ['settings-company', 'payments-csv-report-clicked'],
    'payments-csv-report.downloaded': [
      'settings-company',
      'payments-csv-report-downloaded',
      {
        startDate: 'startDate',
        endDate: 'endDate',
        countPayments: 'countPayments',
      },
    ],
  },
  '/orgs/*/bills': {
    'schedule.payment': ['bills', 'dashboard-schedule-payment-clicked'],
    'fab.action': ['bills', 'dashboard-fab-clicked'],
    'settings.view': ['bills', 'dashboard-settings-clicked'],
    'faq.view': ['bills', 'dashboard-faq-clicked'],
    'page.view': ['bills', 'dashboard-bills-list'],
    'bills.actions.decline': ['bills', 'decline-payment-button-clicked'],
    ...mapApiSlice('[PAYMENTS] PAYMENT_APPROVED', 'bills', 'payment_approved'),
    ...mapApiSlice('[PAYMENTS] PAYMENT_DECLINED', 'bills', 'payment_declined', {
      request: { reason: 'payload.reason' },
    }),
    'modal-close-decline-payment-modal': ['bills', 'decline-payment-modal-closed'],
    'toggle-actions-menu': ['bills', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'bills-payment': ['bills-payment', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'bills.actions.trackDelivery': ['check', 'tracking-delivery'],
    'bills.newEmptyState.buttonTextFirstBill': ['bills', 'add-your-first-bill-button'],
    'mark-as-paid-modal': ['bills', 'mark-as-paid-modal', { toggle: 'toggle' }],
    'option-changed-intuitAccountId': [
      'bills',
      'mark-as-paid-modal-option-changed',
      { qboAccountId: 'option.value' },
    ],
    'bills.form.markAsPaidDialog.dialogOkButtonText': ['bills', 'mark-as-paid-modal-submit'],
    'mark-as-paid-modal-on-focus-amount': [
      'pay-bill',
      'focus-payment-amount',
      { amount: 'amount', source: 'source' },
    ],
  },
  '/orgs/*/bills/new': {
    'page.view': ['bills', 'new-bill'],
    'bills.new.saveAndAdd': ['bill-create', 'save'],
    'bills.new.saveAndClose': ['bill-create', 'save-and-close'],
  },
  '/orgs/*/bills/pay/batch-success': {
    'batchPayment.success.buttonLabel': ['pay-bills-batch-success', 'done'],
    'go-to-dashboard': [
      'pay-bills-batch-success',
      'go-to-online-bill-payment',
      { flow: 'flow', fromDashboard: 'fromDashboard' },
    ],
    'support@qbousers.meliopayments.com': ['pay-bills-batch-success', 'support-email'],
  },
  '/orgs/*/bills/pay/batch-complete-legal-info': {
    ...mapApiSlice('[USER] SET_COMPANY_INFO', 'complete-legal-info', 'continue'),
    ...mapApiSlice('[PAYMENTS] SEND_BATCH_PAYMENTS', 'pay-bills-batch', 'schedule-batch-payments', {
      failure: {
        paid: 'payload.paid.length',
        payments: 'meta.identifier.payments.length',
      },
    }),
  },
  '/orgs/*/bills/pay/batch': {
    'select-delivery-method': ['pay-bills-batch', 'select-delivery-method'],
    'select-funding-source': ['pay-bills-batch', 'choose-payment-method'],
    'bill-selected': ['pay-bills-batch', 'bill-selected'],
    'select-scheduled-date-error': [
      'pay-bills-batch',
      'select-scheduled-date-error',
      { error: 'error' },
    ],
    'batch-add-new-bank': ['pay-bills-batch', 'add-bank-account'],
    'batch-add-new-credit': ['pay-bills-batch', 'add-credit-card'],
    'batch-add-new-debit': ['pay-bills-batch', 'add-debit-card'],
    'batch-add-new-delivery-method': [
      'pay-bills-batch',
      'add-delivery-method',
      { deliveryMethod: 'deliveryMethod' },
    ],
    'part-of-batch-failed-single': ['pay-bills-batch', 'part-of-batch-failed-single-case'],
    'part-of-batch-failed-multiple': ['pay-bills-batch', 'part-of-batch-failed-multiple-case'],
    'batchPayment.sidePanel.buttons.cancel': ['pay-bills-batch', 'cancel-side-panel'],
    'set-side-panel': [
      'pay-bills-batch',
      'set-side-panel',
      {
        amount: 'amount',
        partialBillId: 'partialBillId',
        isPartial: 'isPartial',
      },
    ],
    'batch-focus-payment-amount': [
      'pay-bills-batch',
      'focus-payment-amount',
      { source: 'source', amount: 'amount', partialBillId: 'partialBillId' },
    ],
    'batchPayment.buttons.cancel': ['pay-bills-batch', 'cancel-batch-payment'],
    ...mapApiSlice('[PAYMENTS] SEND_BATCH_PAYMENTS', 'pay-bills-batch', 'schedule-batch-payments', {
      failure: {
        paid: 'payload.paid.length',
        payments: 'meta.identifier.payments.length',
      },
    }),
    '[PAYMENTS] CREATE_BATCH_PAYMENTS/setPaymentMethod': [
      'pay-bills-batch',
      'add-${payload.paymentType}',
      { type: 'payload.type' },
    ],
    '[PAYMENTS] CREATE_BATCH_PAYMENTS/setScheduledDate': [
      'pay-bills-batch',
      'select-scheduled-date-from-table',
    ],
    'open-date-picker': ['pay-bills-batch', 'open-scheduled-date-calendar'],
    'open-date-picker-from-table': ['pay-bills-batch', 'open-scheduled-date-calendar-from-table'],
    'qbcash-low-balance-confirm-open': ['pay-bills-batch', 'qbcash-low-balance-confirm-open'],
    'qbcash-low-balance-confirm-close': ['pay-bills-batch', 'qbcash-low-balance-confirm-close'],
    'qbcash-low-balance-schedule-anyway': ['pay-bills-batch', 'qbcash-low-balance-schedule-anyway'],
    'qbcash-low-balance-edit-funding-source': [
      'pay-bills-batch',
      'qbcash-low-balance-edit-funding-source',
    ],
    'switch-fast-ach-on': ['pay-bills-batch', 'toggle-fast-ach-on'],
    'switch-fast-ach-off': ['pay-bills-batch', 'toggle-fast-ach-off'],
    'switch-fast-check-on': ['pay-bills-batch', 'toggle-fast-check-on'],
    'switch-fast-check-off': ['pay-bills-batch', 'toggle-fast-check-off'],
    'option-selected-deliveryDate-check': [
      'pay-bills-batch',
      'option-selected-deliveryDate-check-${option.id}',
    ],
    'option-selected-deliveryDate-ach': [
      'pay-bills-batch',
      'option-selected-deliveryDate-ach-${option.id}',
    ],
    'schedule-payments-success': [
      'pay-bills-batch',
      'schedule-payments-success',
      {
        billIds: 'billIds',
        totalPayments: 'totalPayments',
        totalFastEligibleAchPayments: 'totalFastEligibleAchPayments',
        totalFastEligibleCheckPayments: 'totalFastEligibleCheckPayments',
        totalFastCheckPayments: 'totalFastCheckPayments',
        totalFastAchPayments: 'totalFastAchPayments',
        partialBillIds: 'partialBillIds',
        batchId: 'batchId',
      },
    ],
    'filtered-biller-directory-bills': [
      'pay-bills-batch',
      'filtered-biller-directory-bills',
      {
        totalBills: 'totalBills',
        totalDisplayedBills: 'totalDisplayedBills',
        totalFilteredBills: 'totalFilteredBills',
        partialBillIds: 'partialBillIds',
      },
    ],
    'VirtualCardLearnMore-Clicked': ['pay-bill', 'VirtualCardLearnMore-Clicked'],
  },

  '/orgs/*/bills/new/create-options': {
    'page.view': ['bills', 'new-bill-create-options'],
  },
  '/orgs/*/bills/*/pay/funding': {
    'page-viewed': ['pay-bill', 'add-funding'],
    'payment-on-focus-amount': ['pay-bill', 'focus-payment-amount', { amount: 'amount' }],
    'benefits-click': [
      'pay-bill',
      'benefits-click',
      { shouldShowBenefitsModal: 'shouldShowBenefitsModal' },
    ],
  },
  '/orgs/*/bills/pay/background-sync-entry': {
    'page-viewed': ['pay-bill', 'add-funding'],
    'payment-on-focus-amount': ['pay-bill', 'focus-payment-amount', { amount: 'amount' }],
    'sync-bill-error-dialog': ['pay-bill', 'sync-bill-error-dialog', { toggle: 'toggle' }],
    'bills.pay.fundingSource.syncErrorDialog.okButton': [
      'pay-bill',
      'sync-bill-error-dialog-contact-support-click',
    ],
    'bills.pay.fundingSource.syncErrorDialog.cancelButton': [
      'pay-bill',
      'sync-bill-error-dialog-close-click',
    ],
  },
  '/orgs/*/bills/*/payment/*/edit/funding': {
    'page.view': ['pay-bill', 'edit-funding'],
  },
  '/orgs/*/bills/recurring/pay/funding': {
    'page.view': ['pay-bill', 'recurring-add-funding'],
  },
  '/orgs/*/bills/*/pay/delivery-method': {
    'page.view': ['pay-bill', 'add-delivery'],
    'VirtualCardLearnMore-Clicked': [
      'pay-bill',
      'VirtualCardLearnMore-Clicked',
      { vendorId: 'vendorId', partialBillId: 'partialBillId' },
    ],
  },
  '/orgs/*/bills/*/payment/*/edit/delivery-method': {
    'page.view': ['pay-bill', 'edit-delivery-method'],
  },
  '/orgs/*/bills/recurring/pay/delivery-method': {
    'page.view': ['pay-bill', 'recurring-add-delivery'],
  },
  '/orgs/*/bills/*/pay/date': {
    'page-viewed': [
      'pay-bill',
      'date',
      {
        billId: 'billId',
        isPaymentWillBeLate: 'isPaymentWillBeLate',
      },
    ],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
    'benefits-click': [
      'pay-bill',
      'benefits-click',
      { shouldShowBenefitsModal: 'shouldShowBenefitsModal' },
    ],
    'pay-by-card-clicked': [
      'pay-bill',
      'pay-by-card-clicked',
      { fundingSourceId: 'fundingSourceId' },
    ],
  },
  '/orgs/*/bills/*/payment/*/edit/date': {
    'page.view': ['pay-bill', 'edit-date'],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
    'benefits-click': [
      'pay-bill',
      'benefits-click',
      { shouldShowBenefitsModal: 'shouldShowBenefitsModal' },
    ],
    'pay-by-card-clicked': [
      'pay-bill',
      'pay-by-card-clicked',
      { fundingSourceId: 'fundingSourceId' },
    ],
  },
  '/orgs/*/bills/recurring/pay/memo': {
    'page.view': ['pay-bill', 'recurring-add-memo'],
    ...getMemoPageEvents(),
  },
  '/orgs/*/bills/*/pay/memo': {
    'page.view': ['pay-bill', 'memo'],
    ...getMemoPageEvents(),
  },
  '/orgs/*/bills/*/payment/*/edit/memo': {
    'page.view': ['pay-bill', 'edit-memo'],
  },
  '/orgs/*/bills/recurring/pay/confirm': {
    'page.view-confirm': [
      'pay-bill',
      'recurring-confirm',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        fundingSourceId: 'fundingSourceId',
        isFast: 'isFast',
        isLate: 'isLate',
        deliveryEta: 'deliveryEta',
        variant: 'variant',
      },
    ],
  },
  '/orgs/*/bills/*/pay/confirm': {
    'page.view-confirm': [
      'pay-bill',
      'confirm',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        fundingSourceId: 'fundingSourceId',
        isFast: 'isFast',
        isLate: 'isLate',
        deliveryEta: 'deliveryEta',
        variant: 'variant',
      },
    ],
    'qbcash-low-balance-confirm-open': ['pay-bill', 'qbcash-low-balance-confirm-open'],
    'qbcash-low-balance-confirm-close': ['pay-bill', 'qbcash-low-balance-confirm-close'],
    'qbcash-low-balance-schedule-anyway': ['pay-bill', 'qbcash-low-balance-schedule-anyway'],
    'qbcash-low-balance-edit-funding-source': [
      'pay-bill',
      'qbcash-low-balance-edit-funding-source',
    ],
  },
  '/orgs/*/bills/*/payment/*/edit/confirm': {
    'page.view-confirm': [
      'pay-bill',
      'edit-confirm',
      {
        billId: 'billId',
        paymentId: 'paymentId',
        fundingSourceId: 'fundingSourceId',
        isFast: 'isFast',
        isLate: 'isLate',
        deliveryEta: 'deliveryEta',
        variant: 'variant',
      },
    ],
  },
  '/orgs/*/bills/recurring/pay/success': {
    'page.view': ['pay-bill', 'recurring-success'],
  },
  '/orgs/*/bills/*/pay/success': {
    'page.view': ['pay-bill', 'success'],
    'go-to-dashboard': [
      'pay-bill-success',
      'go-to-online-bill-payment',
      { flow: 'flow', fromDashboard: 'fromDashboard' },
    ],
  },
  '/orgs/*/bills/recurring/pay/complete-legal-info': {
    'page.view': ['pay-bill', 'recurring-complete-legal-info'],
  },
  '/orgs/*/bills/*/pay/complete-legal-info': {
    'page.view': ['pay-bill', 'complete-legal-info'],
  },
  '/quickbooks/block/*': {
    'page.view': ['quickbooks', 'blocked-user'],
  },
  '/register/code-verification': {
    'page.view': ['auth', 'code-verification'],
    'guests.emailVerify.sendCodeAgain': ['registration', 'email-code-verification-resend-code'],
  },
  '/orgs/*/vendors': {
    'page.view': ['vendors', 'vendors-list'],
    'toggle-actions-menu': [
      'vendors-delivery-methods',
      'toggle-actions-menu',
      { isOpen: 'isOpen' },
    ],
    'view-vendors-actions': ['vendors', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/orgs/*/vendors/*/delivery-method/virtual/new': {
    ...mapApiSlice('[DELIVERYMETHODS] CREATE', 'virtual-delivery-method', 'create-delivery-method'),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE', 'virtual-delivery-method', 'update-delivery-method'),
    'go.exit': ['virtual-delivery-method', 'exit'],
  },
  '/orgs/*/requests': {
    'page.view': ['requests', 'requests-list'],
    'toggle-actions-menu': ['requests', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/orgs/*/vendors/*/delivery-method/virtual-card/new': {
    'page.view': ['vendors', 'edit-virtual-card-delivery-method'],
    'VirtualCardAddDeliveryMethod-Clicked': [
      'vendors',
      'VirtualCardAddDeliveryMethod-Clicked',
      { vendorId: 'vendorId', virtualCardAccountId: 'virtualCardAccountId', origin: 'origin' },
    ],
    'go.exit': ['virtual-card-delivery-method', 'exit'],
  },
  '/meliome/pay/*': {
    'page.view': ['payor', 'payment-amount'],
  },
  '/meliome/pay/*/r/*': {
    'page.view': ['payor', 'start'],
  },
  '/meliome/pay/*/expedite': {
    'page.view': ['payor', 'expedite-payment'],
  },
  '/meliome/pay/*/payment-date': {
    'page.view': ['payor', 'payment-date'],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
  },
  '/meliome/pay/*/t': {
    'page.view': ['payor', 'button-with-params'],
  },
  '/quickbooks/entry/bills-list/*': {
    'page.view': ['qbo-entrypoint', 'dashboard'],
  },
  '/quickbooks/entry/create-bill/*': {
    'page.view': ['qbo-entrypoint', 'create-bill'],
  },
  '/quickbooks/entry/just-pay/*': {
    'pay-bills-click': ['just-pay', 'pay-bills-click'],
  },
  '/auth/code-verification': {
    'enter-code': [
      'two-factor-auth',
      'verification-code-enter',
      {
        userId: 'userId',
        userEmail: 'userEmail',
        orgId: 'orgId',
        count: 'count',
        success: 'success',
      },
    ],
    'resend-code': [
      'two-factor-auth',
      'resend-code',
      { userId: 'userId', userEmail: 'userEmail', orgId: 'orgId', count: 'count' },
    ],
    'contact-support': [
      'two-factor-auth',
      'contact-support',
      { userId: 'userId', userEmail: 'userEmail', orgId: 'orgId', countOfAttempts: 'count' },
    ],
  },
  [DEFAULT_MAPPING_NAME]: {
    'companies-switcher': [
      'companies-switcher',
      'toggle',
      { isOpen: 'isOpen', location: 'location' },
    ],
    'companies-switcher-top': ['companies-switcher-top', 'company-changed'],
    'companies-switcher-bottom': ['companies-switcher-bottom', 'company-changed'],
    'nav.accountSettings': ['side-menu', 'account-settings'],
    'companies-switcher-mobile': ['companies-switcher-mobile', 'toggle', { isOpen: 'isOpen' }],
    'melio-wrapper-loaded': ['melio-wrapper-loaded', 'success'],
    'history-pop': ['page', 'browser-go-back'],
    'page-left': ['page', 'browser-left-or-reloaded'],
  },
  '/orgs/*/bill/*': {
    'bills.form.paymentActivity.actions.cancel': ['bill', 'delete-single-payment'],
    'bills.form.paymentActivity.actions.close': ['bill', 'close-marked-as-paid'],
    'bills.form.paymentActivity.actions.markAsUnpaid': ['bill', 'mark-as-unpaid'],
    'payments.form.deleteDialog.cancelText': ['bill', 'delete-payment-canceled'],
  },
  '/orgs/*/bill/*/payment/*': {
    'request-refund': [
      'view-payment',
      'refund-payment',
      { paymentId: 'paymentId', billId: 'billId' },
    ],
    'request-support-refund': [
      'refund-payment',
      'talk-to-support',
      { paymentId: 'paymentId', billId: 'billId' },
    ],
    'chat-with-support': [
      'refund-payment',
      'chat-with-support',
      { paymentId: 'paymentId', billId: 'billId', riskBlockReason: 'riskBlockReason' },
    ],
  },
  '/quickbooks/pay-new-landing-page/*': {
    'page.view': ['qbo-actions', 'pay'],
  },
  ...vendorsEventsMapping,
  ...quickpayEventMapping,
  ...fundingSourceEventMapping,
  ...accountingSoftwareSyncEventsMapping,
  ...qbDashboardEventsMapping,
  ...justPayEventsMapping,
  ...entrypointsEventsMapping,
  ...selfServeRefundEventMapping,
  ...vendorUpgradePaymentEventMapping,
};

export default MAPPINGS;
