import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MIFormattedText } from 'src/app/utils/formatting';

type NavigationItem = {
  path: string;
  title: string;
};

type Props = {
  items: NavigationItem[];
  preservedState?: Record<string, any>;
};

const MINavigation = ({ items, preservedState = {} }: Props) => (
  <Wrapper>
    {items.map((item) => (
      <StyledNavLink
        key={item.title}
        activeClassName="active"
        to={{ pathname: item.path, state: preservedState }}
      >
        <MIFormattedText label={item.title} />
      </StyledNavLink>
    ))}
  </Wrapper>
);

const StyledNavLink = styled(NavLink)`
  color: rgba(51, 51, 51, 1);
  flex-direction: row;
  height: 2.8rem;
  margin: 2.2rem 1.6rem -0.2rem 0;
  text-decoration: none;
  &.active {
    border-bottom: 0.4rem solid ${(props) => props.theme.colors.primary.active};
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }
  ${(props) => props.theme.text.fontType.medium};
`;

const Wrapper = styled.div`
  border-bottom: 0.2rem solid rgba(212, 215, 220, 1);
  display: flex;
  justify-content: space-between;
`;

export default MINavigation;
