import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';

export const CategoryInfoContainer = styled.div`
  ${({ theme }) => theme.text.fontType.medium};
  color: ${({ theme }) => theme.text.color.darkGrey};
  text-align: initial;
  @media ${devices.mobile}, ${devices.phablet} {
    white-space: initial;
  }
`;

export const CategoryVirtualCardInfoContainer = styled.div`
  ${({ theme }) => theme.text.fontType.medium};
  color: ${({ theme }) => theme.text.color.darkGrey};
  font-weight: 500;
  white-space: normal;
`;

export const CategoryVirtualCardSubInfoSpan = styled.span`
  z-index: 1000;
  @media ${devices.mobile}, ${devices.phablet} {
    display: none;
  }
`;

export const LinkContainer = styled.span`
  color: ${({ theme }) => theme.text.color.blue};
  &:hover {
    text-decoration: underline;
  }
`;
