import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { SimpleTextFooter, WizardFormRow } from 'src/app/components/layout/QBOWizardElements';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import analytics from 'src/app/services/analytics';
import { DELIVERY_METHOD_ORIGIN, INPUT_PATTERNS } from 'src/app/utils/consts';
import { FieldType, BankType, DeliveryMethodType } from 'src/app/utils/types';
import { MITextInput } from 'src/app/components/common/MITextInput';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import MIPasswordInput from 'src/app/components/common/MIPasswordInput';
import { MIFormattedText } from 'src/app/utils/formatting';
import { DeliverFailureData } from 'src/app/version-2/model/dtos';
import { FAILED_TO_DELIVER_SAME_BANK_DETAILS } from 'src/app/version-2/model/constants';
import {
  isRetryFailedToDeliverACH,
  getEditAchPageInputLabels,
} from 'src/app/version-2/utils/payment.utils';

import { EditBankDeliveryMethodNotification } from './EditBankDeliveryMethodNotification';

type Props = {
  onDone: () => void;
  goExit: () => void;
  onPrev?: () => void;
  bank: RecordOf<BankType>;
  onChange: (arg0: FieldType) => void;
  isLoading?: boolean;
  validationErrors: Record<string, string>;
  origin?: DELIVERY_METHOD_ORIGIN;
  errorCode: string;
  errorTitle?: string;
  vendorName: string;
  titleValues?: Record<string, any>;
  deliveryMethodId: string;
  deliverFailureData?: DeliverFailureData;
};

const EditBankDeliveryMethodPage = ({
  isLoading,
  goExit,
  onDone,
  onChange,
  onPrev,
  validationErrors,
  bank,
  origin,
  errorCode,
  errorTitle,
  vendorName,
  titleValues,
  deliveryMethodId,
  deliverFailureData,
}: Props) => {
  const bankAccount = (
    useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId)) as DeliveryMethodType
  )?.bankAccount;
  const { routingNumber, accountNumber } = bank;
  const isOriginPresented = !!origin;

  const isRetryFailedToDeliver = isRetryFailedToDeliverACH({ deliverFailureData });
  const AccountNumberComponent = isRetryFailedToDeliver ? MITextInput : MIPasswordInput;
  const isNextDisabled = errorCode === FAILED_TO_DELIVER_SAME_BANK_DETAILS;
  const originTitle = isEmpty(vendorName)
    ? 'vendors.deliveryMethods.bank.titleNoVendorAlternative'
    : 'vendors.deliveryMethods.bank.title';

  useEffect(() => {
    if (isRetryFailedToDeliver) {
      analytics.page('pay-bill', 'edit-delivery-method', {
        deliveryMethodId,
        paymentStatus: 'failed',
      });
    }
  }, [isRetryFailedToDeliver]);

  const stepLayoutPageProps = isOriginPresented
    ? {
        title: isRetryFailedToDeliver
          ? 'vendors.deliveryMethods.bank.titleRetryFailedToDeliver'
          : originTitle,
        titleValues: { vendor: vendorName },
        goExit,
        onSubmit: onDone,
        nextLabel: `vendors.deliveryMethods.bank.${isRetryFailedToDeliver ? 'continue' : 'save'}`,
        isLoading,
        footer: (
          <SimpleTextFooter>
            <MIFormattedText label="vendors.deliveryMethods.bank.saveFooter" />
          </SimpleTextFooter>
        ),
        fullWidthCTA: true,
        isNextDisabled,
      }
    : {
        title: isRetryFailedToDeliver
          ? 'vendors.deliveryMethods.bank.titleRetryFailedToDeliver'
          : 'bills.pay.editAchDeliveryMethod.title',
        titleValues,
        relativeStep: 1 / 2,
        onSubmit: onDone,
        nextLabel: `bills.pay.editAchDeliveryMethod.${
          isRetryFailedToDeliver ? 'continue' : 'save'
        }`,
        isLoading,
        goExit,
        onPrev,
        fullWidthCTA: true,
        isNextDisabled,
        footer: (
          <FooterContainer>
            <MIFormattedText label="bills.pay.editAchDeliveryMethod.footerText" />
          </FooterContainer>
        ),
      };

  const shouldShowValue = routingNumber === '';
  const inputLabels = getEditAchPageInputLabels({ isOriginPresented, isRetryFailedToDeliver });

  return (
    <QBOLayoutPage hideHeader {...stepLayoutPageProps}>
      <EditBankDeliveryMethodNotification
        routingNumber={bankAccount?.routingNumber}
        accountNumber={bankAccount?.accountNumber}
        errorCode={errorCode}
        errorTitle={errorTitle}
        deliverFailureData={deliverFailureData}
      />
      <WizardFormRow>
        <MITextInput
          id="routingNumber"
          placeholder="bills.pay.editAchDeliveryMethod.routingNumberPlaceholder"
          value={routingNumber}
          label={inputLabels.routingNumber}
          onChange={onChange}
          type="tel"
          autoFocus
          required
          errorMessage={validationErrors.routingNumber}
          autocomplete="off"
          privateData
        />
      </WizardFormRow>
      <WizardFormRow>
        <AccountNumberComponent
          id="accountNumber"
          placeholder="bills.pay.editAchDeliveryMethod.accountNumberPlaceholder"
          value={accountNumber}
          label={inputLabels.accountNumber}
          onChange={onChange}
          required
          errorMessage={validationErrors.accountNumber}
          shouldShowValue={shouldShowValue}
          autocomplete="off"
          pattern={INPUT_PATTERNS.NUMBERS}
          inputMode="numeric"
        />
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormRow>
    </QBOLayoutPage>
  );
};

export default EditBankDeliveryMethodPage;

const HiddenInput = styled.input`
  display: none;
`;

const FooterContainer = styled.div`
  display: flex;
  margin-top: -2rem;
  ${(props) => props.theme.text.fontType.hint};
`;
