import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AreaLoader } from '@melio/billpay-design-system';
import { QBOFooterContainer } from 'src/app/components/layout/QBOElements';
import { useBreak } from 'src/app/hoc';
import { MIFormattedDate, MIFormattedText } from 'src/app/version-2/externals';
import { ReactComponent as QBLogo } from 'src/app/version-2/images/qb-logo.svg';
import { DeliveryMethod } from 'src/app/version-2/model/dtos';
import { upgradePaymentActions } from 'src/app/version-2/pages/vendor-upgrade-payment';
import { BackToTrackPaymentPageButton } from 'src/app/version-2/pages/vendor-upgrade-payment/components/BackToTrackPaymentPageButton';
import { UpgradePaymentByPayeeType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upgradePaymentByPayeeType.enum';
import { UpsellType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upsellType.enum';
import { upgradePaymentSelectors } from 'src/app/version-2/pages/vendor-upgrade-payment/modules/upgradePayment.slice';
import {
  getVendorUpgradeAnalytics,
  upsellTypeToAnalyticsOptionName,
  vendorUpgradePaymentClientEventNames,
  vendorUpgradePaymentServerEventNames,
} from 'src/app/version-2/pages/vendor-upgrade-payment/utils/analytics.utils';
import { isToday, isTomorrow } from 'src/app/version-2/utils/dates.utils';
import { getAccountNumber4digits } from 'src/app/version-2/utils/deliveryMethod.utils';
import {
  Button,
  ContentContainer,
  Fees,
  Header,
  LogoContainer,
  Separator,
  StyledQBOLayoutPage,
  Subtitle,
  Title,
} from './SelectMethodPage.styles';
import { Agreement } from '../Agreement';
import { DebitCardPromotion } from '../DebitCardPromotion';
import { Support } from '../Support';

type Props = {
  token: string;
};

const getSubtitle = (deliveryMethod: Partial<DeliveryMethod>, deliveryDate: string) => {
  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);
  const expectedDeliveryDate = new Date(deliveryDate);

  let label;
  const values = {
    accountNumber4digits,
    deliveryDate: <MIFormattedDate date={expectedDeliveryDate} />,
  };

  if (isToday(expectedDeliveryDate)) {
    label = 'vendors.upgradePayment.landing.subtitle.fast.today';
  } else if (isTomorrow(expectedDeliveryDate)) {
    label = 'vendors.upgradePayment.landing.subtitle.fast.tomorrow';
  } else {
    label = 'vendors.upgradePayment.landing.subtitle.fast.date';
  }

  return { label, values };
};

export const SelectMethodPage = ({ token }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isDesktop, isTablet } = useBreak();

  const payment = useSelector(upgradePaymentSelectors.selectPayment);
  const organization = useSelector(upgradePaymentSelectors.selectOrganization);
  const deliveryMethod = useSelector(upgradePaymentSelectors.selectDeliveryMethod);
  const upsellItems = useSelector(upgradePaymentSelectors.selectUpsellItems);
  const filesUrls = useSelector(upgradePaymentSelectors.selectFilesUrls);
  const isSubmitting = useSelector(upgradePaymentSelectors.selectIsSubmitting);

  const { trackServerAction, trackUIAction, staticAnalyticsProps } = useMemo(
    () => getVendorUpgradeAnalytics(token, payment),
    [payment, token]
  );

  useEffect(() => {
    if (upsellItems) {
      const shownOptions = [UpsellType.PUSH_TO_FAST_ACH, UpsellType.PUSH_TO_DEBIT]
        .map((type) => upsellTypeToAnalyticsOptionName[type])
        .sort((a, b) => a.localeCompare(b))
        .join('_');

      trackUIAction(vendorUpgradePaymentClientEventNames.OPTIONS_PAGE_VIEW, {
        shownOptions,
      });

      trackServerAction(vendorUpgradePaymentServerEventNames.OPTIONS_PAGE_VIEW, { shownOptions });
    }
  }, [upsellItems, trackUIAction, trackServerAction]);

  if (!upsellItems) {
    return <AreaLoader />;
  }

  const handleConfirmClick = () => {
    trackUIAction(vendorUpgradePaymentClientEventNames.SELECT_OPTION, {
      selectedOption: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_FAST_ACH],
    });

    trackServerAction(vendorUpgradePaymentServerEventNames.SELECT_OPTION, {
      selectedOption: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_FAST_ACH],
    });

    dispatch(
      upgradePaymentActions.upgradePaymentByPayee({
        token,
        type: UpgradePaymentByPayeeType.FAST_ACH,
        history,
      })
    );
  };

  const fastAchUpsellItem = upsellItems.find(({ type }) => type === UpsellType.PUSH_TO_FAST_ACH);
  const { label: subtitleLabel, values: subtitleValues } = getSubtitle(
    deliveryMethod,
    fastAchUpsellItem?.deliveryDate || new Date().toString()
  );

  return (
    <StyledQBOLayoutPage
      hideHeader
      contentWrapperMode="success"
      qboFooter={<QBOFooterContainer hasBorder />}
    >
      <LogoContainer>
        <QBLogo height="3.2rem" width="16rem" />
      </LogoContainer>
      <ContentContainer>
        <BackToTrackPaymentPageButton analyticsProps={staticAnalyticsProps} token={token} />
        <Header
          payment={payment}
          organization={organization}
          filesUrls={filesUrls}
          subTitle="vendors.upgradePayment.header.subtitle"
          hideLogo
          showFullHeader
        />
        <Separator />
        <Title>
          <MIFormattedText label="vendors.upgradePayment.landing.title.fast" />
        </Title>
        <Subtitle>
          <MIFormattedText label={subtitleLabel} values={subtitleValues} />
        </Subtitle>
        <Fees>
          <MIFormattedText label="vendors.upgradePayment.landing.fees" />
        </Fees>
        <Button
          variant="primary"
          size={isDesktop || isTablet ? 'md' : 'lg'}
          onClick={handleConfirmClick}
          isLoading={isSubmitting}
        >
          <MIFormattedText label="vendors.upgradePayment.landing.cta" />
        </Button>
        <Agreement />
        <DebitCardPromotion token={token} payment={payment} isEnabled={!isSubmitting} />
      </ContentContainer>
      <Support />
    </StyledQBOLayoutPage>
  );
};
