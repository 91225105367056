import React, { useEffect } from 'react';
import { useRouteMatch, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrgId } from 'src/app/redux/user/selectors';
import { useApi } from 'src/app/hoc/useApi';
import accountingSoftwareSync from 'src/app/services/api/accountingSoftwareSync';
import locations from 'src/app/utils/locations';
import { Loader } from '@melio/billpay-design-system';
import globalLocations from 'src/app/pages/locations';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { SYNC_PAYMENT_INTENT } from './consts';

type Response = {
  payment: {
    billId: string;
    id: string;
  };
};

type LocationState = {
  redirectUrl: string;
  exitUrl: string;
  intent?: SYNC_PAYMENT_INTENT;
  unpaidTabRedirectUrl: string;
  billId: string;
  paymentId: string;
};

const QBOSyncPaymentPage = () => {
  const [syncPaymentByOriginId, result, , error] = useApi<[string, string], Response>(
    accountingSoftwareSync.syncPaymentByOriginId
  );
  const orgId = useSelector(getOrgId);
  const {
    redirectUrl,
    paymentId,
    billId,
    exitUrl,
    intent = SYNC_PAYMENT_INTENT.VIEW_PAYMENT,
    unpaidTabRedirectUrl,
  } = useLocation<LocationState>().state;
  const match = useRouteMatch<{ originId: string }>();
  const { isPartialPaymentsEnabled } = usePartialPaymentsEnabled();
  const { viewPaymentUrls } = useStoreActions(qbDashboardListItemsStore);
  const { originId } = match.params;

  useEffect(() => {
    const syncPayment = async () => {
      try {
        await syncPaymentByOriginId(orgId, originId);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    if (originId !== '-1') {
      syncPayment();
    }
  }, [syncPaymentByOriginId, orgId, originId]);

  if (error) {
    return (
      <Redirect
        to={{
          pathname: globalLocations.entrypoints.errors.billNotFound,
          state: {
            redirectUrl,
            exitUrl,
          },
        }}
      />
    );
  }

  const billIdToRedirect = originId === '-1' ? billId : result?.payment?.billId;
  const paymentIdToRedirect = originId === '-1' ? paymentId : result?.payment?.id;

  if (billIdToRedirect) {
    if (intent === SYNC_PAYMENT_INTENT.EDIT_PAYMENT) {
      const payBillUrl = locations.Bills.pay.edit.funding.url({
        id: billIdToRedirect,
        paymentId: paymentIdToRedirect,
        orgId,
      });

      return (
        <Redirect
          to={{
            pathname: payBillUrl,
            state: {
              redirectUrl,
              exitUrl,
            },
          }}
        />
      );
    }

    const sharedStateParams = {
      payBillRedirectUrl: redirectUrl,
      exitUrl,
      unpaidTabRedirectUrl,
    };

    viewPaymentUrls.setUrls(sharedStateParams);

    if (paymentId && isPartialPaymentsEnabled) {
      const viewPaymentUrl = locations.Bills.payment.url({
        billId,
        paymentId,
        orgId,
      });

      return <Redirect to={viewPaymentUrl} />;
    }

    const viewBillUrl = locations.Bills.single.url({
      billId,
      orgId,
    });

    return <Redirect to={viewBillUrl} />;
  }

  return <Loader />;
};

export default QBOSyncPaymentPage;
