import React from 'react';
import { useSelector } from 'react-redux';
import { encodeQuery } from 'src/app/utils/query-utils';
import { NavLink, useHistory, generatePath } from 'react-router-dom';
import { Link } from '@melio/billpay-design-system';

import billsEmptyUnpaid from 'src/app/images/qbo/dashboard/bills-empty-unpaid.svg';
import billsEmptyScheduled from 'src/app/images/qbo/dashboard/bills-empty-scheduled.svg';
import billsEmptyPaid from 'src/app/images/qbo/dashboard/bills-empty-paid.svg';
import billsEmptySyncing from 'src/app/images/qbo/dashboard/bills-empty-syncing.svg';
import { useBillModal } from 'src/app/pages/qb-dashboard/components/BillModal/hooks/useBillModal';
import { getOrgId } from 'src/app/redux/user/selectors';
import justPayLocations from 'src/app/pages/just-pay/justPayLocations';
import dashboardLocations from 'src/app/pages/qb-dashboard/locations';
import analytics from 'src/app/services/analytics';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import { FLOW_ORIGIN } from 'src/app/utils/consts';
import { DashboardEmptyStateContent } from './components/DashboardEmptyStateContent';
import {
  QB_DASHBOARD_TAB_STATUS,
  QB_DASHBOARD_EMPTY_STATE_STATUS,
  QB_DASHBOARD_EMPTY_STATE_TYPE,
} from '../../consts';

type Props = {
  type: QB_DASHBOARD_EMPTY_STATE_TYPE;
};

export const QBODashboardEmptyState = ({ type }: Props) => {
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const paymentStoreActions = useStoreActions(paymentStore);
  const [BillModalDialog, showBillModalDialog] = useBillModal({
    modalName: 'bill-modal__empty-state',
  });

  const goToPath = (path: string) => {
    history.push(generatePath(path, { orgId }));
  };

  const listEmptyStatePropsByType = {
    [QB_DASHBOARD_TAB_STATUS.UNPAID]: {
      image: billsEmptyUnpaid,
      title: 'paymentDashboard.listEmptyState.unpaid.title',
      description: 'paymentDashboard.listEmptyState.unpaid.description',
      primaryBtnText: 'paymentDashboard.listEmptyState.unpaid.primaryBtnText',
      primaryBtnOnClick: () => {
        showBillModalDialog();
        analytics.track('bills-dashboard', 'unpaid-tab-add-bill-click');
      },
      secondaryBtnText: 'paymentDashboard.listEmptyState.unpaid.secondaryBtnText',
      secondaryBtnOnClick: () => {
        analytics.track('bills-dashboard', 'unpaid-tab-pay-without-bill-click');
        paymentStoreActions.justPay.justPayWizard.update({
          exitUrl: dashboardLocations.dashboard,
          flowOrigin: FLOW_ORIGIN.DASHBOARD,
        });
        goToPath(justPayLocations.create.entry);
      },
      goTo: null,
    },
    [QB_DASHBOARD_TAB_STATUS.SCHEDULED]: {
      image: billsEmptyScheduled,
      title: 'paymentDashboard.listEmptyState.scheduled.title',
      description: 'paymentDashboard.listEmptyState.scheduled.description',
      primaryBtnText: 'paymentDashboard.listEmptyState.unpaid.primaryBtnText',
      primaryBtnOnClick: () => {
        showBillModalDialog();
        analytics.track('bills-dashboard', 'scheduled-tab-add-bill-click');
      },
      secondaryBtnText: 'paymentDashboard.listEmptyState.unpaid.secondaryBtnText',
      secondaryBtnOnClick: () => {
        analytics.track('bills-dashboard', 'scheduled-tab-pay-without-bill-click');
        paymentStoreActions.justPay.justPayWizard.update({
          exitUrl: dashboardLocations.dashboard,
          flowOrigin: FLOW_ORIGIN.DASHBOARD,
        });
        goToPath(justPayLocations.create.entry);
      },
      goTo: null,
    },
    [QB_DASHBOARD_TAB_STATUS.PAID]: {
      image: billsEmptyPaid,
      title: 'paymentDashboard.listEmptyState.paid.title',
      description: 'paymentDashboard.listEmptyState.paid.description',
      primaryBtnText: 'paymentDashboard.listEmptyState.paid.primaryBtnText',
      primaryBtnOnClick: () => {
        analytics.track('bills-dashboard', 'change-tab-from-empty-state', { toTab: 'unpaid' });
        const query = encodeQuery({ status: QB_DASHBOARD_TAB_STATUS.UNPAID }, [], '');

        goToPath(`${dashboardLocations.dashboard}?${query}`);
      },
      secondaryBtnText: 'paymentDashboard.listEmptyState.paid.secondaryBtnText',
      secondaryBtnOnClick: () => {
        analytics.track('bills-dashboard', 'change-tab-from-empty-state', { toTab: 'scheduled' });
        const query = encodeQuery({ status: QB_DASHBOARD_TAB_STATUS.SCHEDULED }, [], '');

        goToPath(`${dashboardLocations.dashboard}?${query}`);
      },
      goTo: null,
    },
    [QB_DASHBOARD_EMPTY_STATE_STATUS.SYNCING]: {
      image: billsEmptySyncing,
      title: 'paymentDashboard.listEmptyState.syncing.title',
      description: 'paymentDashboard.listEmptyState.syncing.description',
      primaryBtnText: 'paymentDashboard.listEmptyState.syncing.primaryBtnText',
      primaryBtnOnClick: () => {
        analytics.track('bills-dashboard', 'unpaid-tab-pay-without-bill-click');
        paymentStoreActions.justPay.justPayWizard.update({
          exitUrl: dashboardLocations.dashboard,
          flowOrigin: FLOW_ORIGIN.DASHBOARD,
        });
        goToPath(justPayLocations.create.entry);
      },
      goTo: null,
    },
  };

  const mapItem = listEmptyStatePropsByType[type];

  const listEmptyStateProps = mapItem && {
    ...mapItem,
    descriptionValues: {
      link: (value) => (
        <Link
          as={NavLink}
          data-testid="empty-state-navigate-link"
          to={{
            search: encodeQuery({ status: mapItem.goTo }, [], ''),
          }}
        >
          {value}
        </Link>
      ),
    },
  };

  return (
    <>
      {BillModalDialog}
      <DashboardEmptyStateContent {...listEmptyStateProps} />;
    </>
  );
};
