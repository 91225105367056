import { fundingSourcesApi } from 'src/app/version-2/api/fundingSources.api';
import { fundingSourcesActions } from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';

import { call, put, select, takeEvery } from 'redux-saga/effects';

function* fetchFundingSourcesHandler(/* action: ReturnType<typeof fundingSourcesActions.fetchFundingSources> */) {
  try {
    yield put(fundingSourcesActions.setIsFundingSourcesFetched(false));
    const orgId = yield select(userSliceSelectors.selectOrgId);

    const { fundingSources } = yield call(fundingSourcesApi.fetch, {
      orgId,
      params: { includeDeleted: true },
    });

    yield put(fundingSourcesActions.fetchFundingSourcesSuccess(fundingSources));
  } catch (e) {
    yield put(fundingSourcesActions.fetchFundingSourcesFail());
  } finally {
    yield put(fundingSourcesActions.setIsFundingSourcesFetched(true));
  }
}

function* fetchFundingSourcesV2Handler() {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);

    const { fundingSources } = yield call(fundingSourcesApi.fetchV2, {
      orgId,
      params: { includeDeleted: true },
    });

    yield put(fundingSourcesActions.fetchFundingSourcesSuccess(fundingSources));
  } catch (e) {
    yield put(fundingSourcesActions.fetchFundingSourcesFail());
  }
}

export function* watchFundingSources() {
  yield takeEvery(fundingSourcesActions.fetchFundingSources, fetchFundingSourcesHandler);
  yield takeEvery(fundingSourcesActions.fetchFundingSourcesV2, fetchFundingSourcesV2Handler);
}
