import { createSlice } from '@reduxjs/toolkit';
import { composeSlices } from 'src/app/helpers/redux/composeSlice';
import { clearStateReducer } from 'src/app/helpers/redux/clearStateReducer';
import isObject from 'lodash/isObject';

import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';

const name = 'payments';

type EmptyObject = {
  [k: string]: never;
};

type JustPayState = {
  justPay: JustPayPaymentType | EmptyObject;
};

const initialState: JustPayState = { justPay: {} };

const justPayWizard = () => {
  const slice = createSlice({
    name: `[${name}] create_justPay`.toUpperCase(),
    initialState,
    reducers: {
      update: (state, action) => {
        const { payload } = action;

        Object.entries(payload).forEach(([key, value]) => {
          if (isObject(value)) {
            state.justPay[key] = { ...state.justPay[key], ...value };
          } else {
            state.justPay[key] = value;
          }
        });
      },
      clear: (state) => {
        state.justPay = {};
      },
    },
  });

  return {
    ...slice,
    initialState,
    selectors: {
      payment: (state) => state[name]?.justPay || {},
    },
    dispatchers: {
      update: (dispatch) => (params) => dispatch(slice.actions.update(params)),
      clear: (dispatch) => () => dispatch(slice.actions.clear()),
    },
  };
};

const justPaySlice = composeSlices(
  {
    justPayWizard: justPayWizard(),
  },
  {
    extraReducers: { ...clearStateReducer(initialState) },
  }
);

export { justPaySlice };
