import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import moment, { Moment } from 'moment';
import { BILLING_FEATURE_WIZARD_FLOW } from 'src/app/pages/settings/components/fee/consts';
import { CardNetworkEnum, CardTypeEnum } from 'src/app/version-2/model/enums';
import { CARD_TYPES, CARD_NETWORK_TYPES } from './consts';
import visa from '../images/icons/visa.svg';
import mastercard from '../images/icons/mastercard.svg';
import americanexpress from '../images/icons/amex.svg';
import qbCashBank from '../images/icons/qb-cash-bank.svg';
import qbCashBankOrigin from '../images/icons/qb-cash-bank-origin.svg';
import diners from '../images/icons/diners.svg';
import { FundingSource } from 'src/app/version-2/model/dtos';

export const cardIcons = {
  visa,
  mastercard,
  americanexpress,
  diners,
};

const getCardIconName = (fs) =>
  get(fs, 'cardAccount.cardType', CARD_TYPES.CREDIT) === CARD_TYPES.CREDIT
    ? 'icon-credit-card-icon'
    : 'icon-debit-card-icon';

export const getCardLogo = (cardType: CardTypeEnum, network: CardNetworkEnum) => {
  const icon = CARD_NETWORK_TYPES[network];

  if (icon) {
    return <Image src={cardIcons[icon]} alt={cardType} />;
  }

  return <FundingSourceIcon className={`icon-${cardType}-card-icon`} />;
};

export const getCardImageUrl = (fs: FundingSource) => {
  if (!fs) return '';

  const cardName = get(fs, 'cardAccount.network', '').toUpperCase();
  const icon = CARD_NETWORK_TYPES[cardName];

  if (icon) return cardIcons[icon];

  return '';
};

export const isAMEXCard = (fs: FundingSource) => fs.cardAccount?.network === CardNetworkEnum.AMEX;

export const getCardAdditionDescription = ({
  flow,
  isVendorAbsorbedFee,
}: {
  flow?: string;
  isVendorAbsorbedFee?: boolean;
}) => {
  if (isVendorAbsorbedFee) {
    return 'onboarding.fundingSources.options.credit.freeFeeDescription';
  }

  if (flow === BILLING_FEATURE_WIZARD_FLOW) {
    return 'onboarding.fundingSources.options.credit.feeFundingSourceDescriptionAddition';
  }

  return 'onboarding.fundingSources.options.credit.description';
};

export const getCardDescription = ({
  method,
  isCreditCardExpired,
  isBillingFeeFlow,
  isVendorAbsorbedFee,
}: {
  method: FundingSource;
  isVendorAbsorbedFee?: boolean;
  isBillingFeeFlow?: boolean;
  isCreditCardExpired?: boolean;
}) => {
  if (isCreditCardExpired) {
    return 'bills.pay.fundingSource.expiredDescription';
  }

  if (isBillingFeeFlow) {
    return 'onboarding.fundingSources.options.credit.feeFundingSourceDescription';
  }

  if (isVendorAbsorbedFee) {
    return 'onboarding.fundingSources.options.credit.freeFeeDescription';
  }

  if (isAMEXCard(method)) {
    return 'amexVerification.paymentMethod.description';
  }

  return 'onboarding.fundingSources.options.credit.description';
};

export const getCardIcon = (fs: FundingSource) =>
  getCardImageUrl(fs) !== '' ? '' : getCardIconName(fs);

export const getIconCardLogo = (icn: string) => (
  <FundingSourceIcon className={`icon-${icn}-icon`} />
);

const FundingSourceIcon = styled.i`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.icons.card};
`;

const Image = styled.img`
  height: 4rem;
`;

export const isCardExpired = (card: Record<string, any>, dateToCompare: Moment = moment()) => {
  const convertedExpirationDate = moment(card?.expiration, 'MM-YY').add(1, 'M');

  return moment(convertedExpirationDate).isBefore(moment(dateToCompare));
};

const getConvertedDate = (date: string) => moment(date, 'MM/YYYY').toDate();

export const convertDateToMonthYearFormat = (date: string) =>
  moment(getConvertedDate(date)).format('MMM YYYY');

export const getExpirationDateHint = (card: Record<string, any>) => {
  let hint = '';

  if (isCardExpired(card)) {
    hint = 'bills.pay.fundingSource.expired';
  } else if (moment(getConvertedDate(card?.expiration)).isSame(moment(), 'month')) {
    hint = 'bills.pay.fundingSource.expirationDateLabel';
  }

  return hint;
};

export const qbCashIcon = <Image src={qbCashBank} />;
export const qbCashOriginIcon = <Image src={qbCashBankOrigin} />;
