import analytics from 'src/app/services/analytics';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { DELIVERY_TYPE, PAYMENT_STATUS } from 'src/app/utils/consts';
import { PaymentType } from 'src/app/utils/types';
import { EXPEDITE_OPTIONS, TIMELINE_STEP_STATUSES } from './consts';
import { getCheckSteps, getPushToDebitSteps, getVirtualCardSteps, useGetAchSteps } from './lib';
import { getPaymentDeductionStatus, getPaymentDeliveryStatus } from './lib/steps-statuses';
import {
  DesktopStylesType,
  MobileStylesType,
  StyleMeasureType,
  TimelineStepType,
  VendorPaymentSectionProps,
} from './types';
import { UpsellItem } from 'src/app/version-2/pages/vendor-upgrade-payment/model/dtos/upsellItem';
import { UpsellType } from 'src/app/version-2/pages/vendor-upgrade-payment/model/enums/upsellType.enum';
import { getIsPaymentCollected } from 'src/app/utils/payments';
import { isToday, isTomorrow } from 'date-fns';
import { MIFormattedDate } from 'src/app/utils/formatting';

const { FUTURE_STEP } = TIMELINE_STEP_STATUSES;

const getMobileStyles = (measure: StyleMeasureType): MobileStylesType => ({
  base: measure,
  sm: measure,
  md: measure,
});

const getDesktopStyles = (measure: StyleMeasureType): DesktopStylesType => ({
  lg: measure,
  xl: measure,
  '2xl': measure,
});

const useGetTimelineStepsFromPayment = ({
  payment,
  vendorPaymentActions,
}: VendorPaymentSectionProps): TimelineStepType[] | null => {
  const achSteps = useGetAchSteps({ payment, vendorPaymentActions });

  const paymentDeliveryType = payment.deliveryMethod.deliveryType;

  if (paymentDeliveryType === DELIVERY_TYPE.ACH) {
    return achSteps;
  }

  if (paymentDeliveryType === DELIVERY_TYPE.CHECK) {
    return getCheckSteps({ payment, vendorPaymentActions });
  }

  if (paymentDeliveryType === DELIVERY_TYPE.VIRTUAL_CARD) {
    return getVirtualCardSteps({ payment, vendorPaymentActions });
  }

  if (paymentDeliveryType === DELIVERY_TYPE.CARD) {
    return getPushToDebitSteps({ payment, vendorPaymentActions });
  }

  return null;
};

const getIsVendorPaymentDetailsFlow = (token: string): boolean => {
  const { actions } = getJWTPayload(token);
  const VENDOR_PAYMENT_DETAILS_ACTION = 'vendorPaymentDetailsActions';

  return actions?.every((actionName) => actionName === VENDOR_PAYMENT_DETAILS_ACTION);
};

const getPaymentExpediteType = ({
  payment,
  upsellItems,
  isP2RTPUpgradeAvailable,
  isP2FACHUpgradeAvailable,
}: {
  payment: PaymentType;
  upsellItems?: UpsellItem[];
  isP2RTPUpgradeAvailable: boolean;
  isP2FACHUpgradeAvailable: boolean;
}): EXPEDITE_OPTIONS | null => {
  if (payment.deliveryPreference) {
    return null;
  }

  const deliveryStatus = getPaymentDeliveryStatus(payment);

  if (deliveryStatus !== FUTURE_STEP) {
    return null;
  }

  const upsellItemsMap = (upsellItems || []).reduce((map, currentValue) => {
    map[currentValue.type] = currentValue;

    return map;
  }, {} as Record<UpsellType, UpsellItem>);

  const isFastAchEligible =
    isP2FACHUpgradeAvailable && upsellItemsMap[UpsellType.PUSH_TO_FAST_ACH]?.isEligible;

  const isRTPEligible =
    isP2RTPUpgradeAvailable && upsellItemsMap[UpsellType.PUSH_TO_RTP]?.isEligible;

  if (isRTPEligible) {
    return EXPEDITE_OPTIONS.TO_PUSH_TO_RTP;
  }

  if (isFastAchEligible) {
    return EXPEDITE_OPTIONS.TO_PUSH_TO_FAST_ACH;
  }

  if (payment.eligibleForPushToDebit && getIsUpsellAvailableByPaymentState(payment)) {
    if (
      payment.deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK &&
      !payment.isCheckToP2DOfferExpired
    ) {
      return EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT;
    }

    if (payment.deliveryMethod.deliveryType === DELIVERY_TYPE.ACH) {
      return EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT;
    }
  }

  const deductionStatus = getPaymentDeductionStatus(payment);

  if (
    payment.deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK &&
    deductionStatus === FUTURE_STEP
  ) {
    return EXPEDITE_OPTIONS.TO_ACH;
  }

  return null;
};

export const getIsUpsellAvailableByPaymentState = (payment: PaymentType) =>
  payment.status === PAYMENT_STATUS.IN_PROGRESS && getIsPaymentCollected(payment);

type AnalyticsTrigger = {
  payment: PaymentType;
  action: string;
  additionalData?: { [key: string]: any };
};

const triggerAnalyticsEvent = ({ payment, action, additionalData = {} }: AnalyticsTrigger) => {
  const basicAnalyticsData = {
    paymentId: payment.id,
    deliveryMethod: payment.deliveryMethod.deliveryType,
    paymentAmount: payment.amount,
    newTrackingConvention: true,
  };

  analytics.trackAction(action, { ...basicAnalyticsData, ...additionalData });
};

const getFastACHTitle = (translationRootPath: string, deliveryDate?: string) => {
  const expectedDeliveryDate = deliveryDate ? new Date(deliveryDate) : new Date();

  const values = {
    deliveryDate: <MIFormattedDate date={expectedDeliveryDate} />,
  };

  let label;

  if (isToday(expectedDeliveryDate)) {
    label = `${translationRootPath}.title.today`;
  } else if (isTomorrow(expectedDeliveryDate)) {
    label = `${translationRootPath}.title.tomorrow`;
  } else {
    label = `${translationRootPath}.title.date`;
  }

  return {
    label,
    values,
  };
};

const getExpediteTranslations = (
  translationRootPath: string,
  expediteType: EXPEDITE_OPTIONS,
  upsellItems?: UpsellItem[]
) => {
  const defaultResponse = {
    labels: {
      title: `${translationRootPath}.title`,
      description: `${translationRootPath}.description`,
      cta: `${translationRootPath}.expediteCTA`,
    },
    values: {
      title: {},
      description: {},
      cta: {},
    },
  };

  if (expediteType === EXPEDITE_OPTIONS.TO_PUSH_TO_FAST_ACH) {
    const fastACHUpsellItem = upsellItems?.find(({ type }) => type === UpsellType.PUSH_TO_FAST_ACH);

    const fastACHTitle = getFastACHTitle(translationRootPath, fastACHUpsellItem?.deliveryDate);

    return {
      labels: {
        ...defaultResponse.labels,
        title: fastACHTitle.label,
      },
      values: {
        ...defaultResponse.values,
        title: fastACHTitle.values,
      },
    };
  }

  return defaultResponse;
};

export {
  getMobileStyles,
  getDesktopStyles,
  useGetTimelineStepsFromPayment,
  getIsVendorPaymentDetailsFlow,
  getPaymentExpediteType,
  triggerAnalyticsEvent,
  getFastACHTitle,
  getExpediteTranslations,
};
