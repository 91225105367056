import { RecordOf } from 'immutable';

import { UserCompanyInfo } from 'src/app/version-2/model/dtos/userCompanyInfo';
import { TaxIdEnum } from 'src/app/version-2/model/enums';
import { loggingApi } from 'src/app/version-2/api/loggers';

import { CompanyInfoType } from './types';

const getTaxIdTypesOptions = () => [
  { id: TaxIdEnum.EIN, label: 'companyInfo.taxIdTypes.EIN' },
  { id: TaxIdEnum.SSN, label: 'companyInfo.taxIdTypes.SSN' },
  { id: TaxIdEnum.ITIN, label: 'companyInfo.taxIdTypes.ITIN' },
];

const isOrgBlockedByRisk = (companyInfo: Partial<UserCompanyInfo>): boolean => {
  const isBlocked = companyInfo?.allowedActions?.canBeginFlow === false;

  if (isBlocked) {
    loggingApi.info('company.isOrgBlockedByRisk(): user is blocked', { orgId: companyInfo?.id });
  }

  return isBlocked;
};

const isZipCodeValid = (zipCode: string | null | undefined) =>
  // Validate zipcode against the payment processor zipcode schema.
  zipCode && new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/).test(zipCode);

const hasRequiredLegalCompanyInfo = (companyInfo: RecordOf<CompanyInfoType>) =>
  !!(
    isZipCodeValid(companyInfo.zipCode) &&
    companyInfo.legalCompanyName &&
    companyInfo.legalFormattedAddress &&
    companyInfo.taxId &&
    companyInfo.taxIdType &&
    companyInfo.contactFirstName &&
    companyInfo.contactLastName &&
    companyInfo.phone &&
    companyInfo.formattedAddress
  );

export { getTaxIdTypesOptions, isOrgBlockedByRisk, isZipCodeValid, hasRequiredLegalCompanyInfo };
