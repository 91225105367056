import QBRBlockedUserPageContainer from 'src/app/pages/quickpay/QBRBlockedUserPageContainer';
import qboSite from 'src/app/sites/qboSite';
import { PaymentCreateFlowOriginEnum, RegistrationOriginEnum } from 'src/app/version-2/model/enums';
import merge from 'lodash/merge';
import QBRNotices from 'src/app/components/common/QBRNotices';
import QBRVirtualDeliveryMethodPageContainer from 'src/app/pages/vendor/delivery-methods/QBRVirtualDeliveryMethodPageContainer';
import QBRVendorSelectDeliveryMethodPage from 'src/app/pages/vendor/virtual-delivery-method/QBRVendorSelectDeliveryMethodPage';
import QBRAddDeliveryMethodSuccessPage from 'src/app/pages/vendor/virtual-delivery-method/QBRAddDeliveryMethodSuccessPage';
import QBRInvalidAddDeliveryMethodPage from 'src/app/pages/vendor/shift-vendor-to-ach/QBRInvalidAddDeliveryMethodPage';
import { QBRDrawerSubtitle } from 'src/app/components/layout/DrawerSubtitle/QBRDrawerSubtitle';
import enTranslations from '../locale/en-US.qbr';
import { envApi } from '../version-2/api/env';
import { theme } from '../theme/qbrTheme';
import * as WizardElements from '../components/layout/QBRWizardElements';
import QBRLayoutPage from '../components/layout/QBRLayoutPage';

const qbrIosSite = merge({}, qboSite, {
  name: 'qbr',
  config: envApi.getQBRSpecificConfig(),
  theme,
  check2fa: false,
  createOrigin: {
    pay: {
      payment: PaymentCreateFlowOriginEnum.QBR_IOS,
    },
  },
  registrationOrigin: RegistrationOriginEnum.QUICKBOOKS_ROCKETMAN_IOS,
  messages: {
    'en-US': enTranslations['en-US'],
  },
  components: {
    MINotices: QBRNotices,
    BlockedUserPageContainer: QBRBlockedUserPageContainer,
    VirtualDeliveryMethodPageContainer: QBRVirtualDeliveryMethodPageContainer,
    WizardElements,
    StepLayout: QBRLayoutPage,
    VendorSelectDeliveryMethodPage: QBRVendorSelectDeliveryMethodPage,
    AddDeliveryMethodSuccessPage: QBRAddDeliveryMethodSuccessPage,
    InvalidLinkPage: QBRInvalidAddDeliveryMethodPage,
    DrawerSubtitle: QBRDrawerSubtitle,
  },
  quickEditPayment: true,
  skipQBOFTUScreen: true,
  quickpayList: true,
  hasFastAch: false,
  goUnilateralInvalidAddress: false,
  deleteBillWithPayment: true,
  defaultIntent: 'rocketmanBillPayConnect',
});

export default qbrIosSite;
