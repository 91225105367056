import { createSlice } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import { name } from './consts';

const initialState = {
  partialPaymentsAmounts: {},
};

type PartiaPaymentItem = {
  amount?: number | null;
  invalidTooltipVisible?: boolean;
};

type SliceState = {
  partialPaymentsAmounts: Record<string, PartiaPaymentItem>;
};

type SetAmountPayload = {
  payload: {
    billId: string;
    amount: number;
  };
};

type SetInvalidTooltipVisablePayload = {
  payload: {
    billId: string;
    invalidTooltipVisible: boolean;
  };
};

type SliceReducers = {
  setAmount: (state: SliceState, payload: SetAmountPayload) => void;
  setInvalidTooltipVisible: (state: SliceState, payload: SetInvalidTooltipVisablePayload) => void;
  resetAmounts: (state: SliceState) => void;
};

const createQbDashboardPartialPaymentsAmountsSlice = () => {
  const slice = createSlice<SliceState, SliceReducers>({
    name: `[${name.toUpperCase()}] PARTIAL_PAYMENTS_AMOUNTS`,
    initialState,
    reducers: {
      setAmount(state, { payload }) {
        state.partialPaymentsAmounts[payload.billId] = merge(
          state.partialPaymentsAmounts[payload.billId] || {},
          {
            amount: payload.amount,
          }
        );
      },
      resetAmounts(state) {
        state.partialPaymentsAmounts = {};
      },
      setInvalidTooltipVisible(state, { payload }) {
        state.partialPaymentsAmounts[payload.billId] = merge(
          state.partialPaymentsAmounts[payload.billId] || {},
          {
            invalidTooltipVisible: payload.invalidTooltipVisible,
          }
        );
      },
    },
  });

  return {
    ...slice,
    selectors: {
      byId: (billId) => (state) => state[name]?.partialPaymentsAmounts?.[billId]?.amount,
      invalidTooltipVisibleById: (billId) => (state) =>
        Boolean(state[name]?.partialPaymentsAmounts?.[billId]?.invalidTooltipVisible),
      all: (state) => mapValues(state[name]?.partialPaymentsAmounts || {}, 'amount'),
    },
    dispatchers: {
      setAmount: (dispatch) => (params) => dispatch(slice.actions.setAmount(params)),
      setInvalidTooltipVisible: (dispatch) => (params) =>
        dispatch(slice.actions.setInvalidTooltipVisible(params)),
      resetAmounts: (dispatch) => () => dispatch(slice.actions.resetAmounts()),
    },
  };
};

const qbDashboardPartialPaymentsAmountSlice = createQbDashboardPartialPaymentsAmountsSlice();

export { qbDashboardPartialPaymentsAmountSlice };
