import React from 'react';
import { RecordOf } from 'immutable';
import { BillType, PaymentType } from 'src/app/utils/types';
import { isPaymentCompletedRefund, isPaymentScheduleRefund } from 'src/app/utils/payments';
import { ScheduleRefundMessage } from './ScheduleRefundMessage/ScheduleRefundMessage';
import { CompletedRefundMessage } from './CompletedRefundMessage/CompletedRefundMessage';
import { FailedPaymentMessage } from './FailedPaymentMessage/FailedPaymentMessage';

export type StatusMessageProps = {
  bill: RecordOf<BillType>;
  payment: PaymentType;
  onRefundPayment: () => void;
};

export const StatusMessage = ({ bill, payment, onRefundPayment }: StatusMessageProps) => {
  const isCompletedRefund = isPaymentCompletedRefund(payment);
  const isScheduledRefund = isPaymentScheduleRefund(payment);

  if (isCompletedRefund) {
    return <CompletedRefundMessage payment={payment} />;
  }

  if (isScheduledRefund) {
    return <ScheduleRefundMessage payment={payment} />;
  }

  return <FailedPaymentMessage payment={payment} bill={bill} onRefundPayment={onRefundPayment} />;
};
