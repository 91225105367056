import React, { useEffect } from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS, NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import successImage from 'src/app/images/icons/icons_check.svg';
import { getPartialBillId } from 'src/app/utils/bills';
import { useBatchesTitles } from 'src/app/pages/bill/pay/hooks/useBatchesTitles';
import * as QBOLayoutElements from 'src/app/components/layout/QBOElements';
import * as WizardElements from 'src/app/components/layout/QBOWizardElements';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import maxBy from 'lodash/maxBy';
import useApprovalDesicionStatus from 'src/app/pages/bill/hooks/useApprovalDesicionStatus';
import analytics from 'src/app/services/analytics';
import {
  WizardActionsRowContainer,
  WizardButtonContainer,
  ModalContainer,
  BatchInfoContainer,
} from 'src/app/pages/qb-dashboard/styles';
import { BillType } from 'src/app/utils/types';
import { ModalContent } from '../types';
import { MainContentProps } from './types';
import { ANALYTIC_PROP_FLOW, ANALYTIC_EVENT_PAGE } from '../../const';

export const MainContent: React.FC<MainContentProps> = (props) => {
  const {
    closeModal,
    hasMissingEmails,
    navigateModalContentTo,
    isModalVisible,
    analyticsData,
    selectedBills,
    billedVendorIds,
    vendorsWithoutEmail,
    scheduledPaymentsTextValues,
  } = props;
  const navigateToVendorVerify = () => {
    analytics.track(ANALYTIC_EVENT_PAGE, 'SuccessPaymentModalNotifyVendorButton-Clicked', {
      flow: ANALYTIC_PROP_FLOW,
      paymentIds: analyticsData.paymentIds,
    });
    navigateModalContentTo(ModalContent.NOTIFY_VENDOR);
  };

  const MainActions = () => {
    if (hasMissingEmails) {
      return (
        <WizardActionsRowContainer hasMissingEmails>
          <WizardButtonContainer>
            <MIButton
              testId="bill-success-modal-done"
              label="bills.pay.success.buttonLabel.toDashboard"
              variant={CONSTS.BUTTON_VARIANT.SECONDARY}
              onClick={closeModal}
            />
          </WizardButtonContainer>
          <WizardButtonContainer>
            <MIButton
              testId="notify-vendors"
              label="batchPayment.buttons.notifyVendors"
              variant={CONSTS.BUTTON_VARIANT.PRIMARY}
              onClick={navigateToVendorVerify}
            />
          </WizardButtonContainer>
        </WizardActionsRowContainer>
      );
    }

    return (
      <WizardActionsRowContainer>
        <WizardButtonContainer>
          <MIButton
            testId="bill-success-modal-done"
            label="batchPayment.success.buttonLabel"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            onClick={closeModal}
          />
        </WizardButtonContainer>
      </WizardActionsRowContainer>
    );
  };

  const getPartialBillIds = () =>
    Object.values(selectedBills || {})
      .map((item) => item.bill as Partial<BillType>)
      .map(getPartialBillId);

  useEffect(() => {
    if (isModalVisible) {
      analytics.track(ANALYTIC_EVENT_PAGE, 'success-batch-payment-modal', {
        partialBillIds: getPartialBillIds(),
        paymentIds: analyticsData.paymentIds,
      });
    }
  }, []);

  useEffect(() => {
    if (hasMissingEmails) {
      analytics.track(ANALYTIC_EVENT_PAGE, 'NotifyVendorOption-Exposed', {
        flow: ANALYTIC_PROP_FLOW,
        vendorsIds: billedVendorIds,
        vendorsCount: billedVendorIds.length,
        paymentIds: analyticsData.paymentIds,
        vendorsWithoutEmailCount: vendorsWithoutEmail.length,
      });
    }
  }, []);

  const textValues = useBatchesTitles({ scheduledPaymentsTextValues });

  const arrayOfBillListModified: any[] = Object.entries(selectedBills || {}).map((e) => e[1]);

  const billModifiedItem = maxBy(arrayOfBillListModified, (item) => item.bill?.payments[0]?.amount);

  const approvalDesicionStatus = useApprovalDesicionStatus({
    amount: billModifiedItem?.bill.payments[0]?.amount,
  });

  const subtitle = approvalDesicionStatus.pending
    ? 'batchPayment.success.subTitleWithApproval'
    : 'batchPayment.success.subTitle';

  return (
    <ModalContainer className="batch-payment-modal" data-testid="modal-message-container">
      <QBOLayoutElements.QBOLayoutContainer>
        <WizardElements.WizardInnerSuccess isCenterVert>
          <WizardElements.WizardIllustration src={successImage} />
          <WizardElements.WizardTitle data-testid="wizard-title">
            <MIFormattedText label="batchPayment.success.title" />
          </WizardElements.WizardTitle>
          <WizardElements.WizardText textAlign="center" className="batches-modal-subtitle">
            <MIFormattedText label={subtitle} values={textValues} />
          </WizardElements.WizardText>
          <WizardElements.WizardActionsContainer>
            <BatchInfoContainer>
              <QBONotificationCard
                className="batch-info-container-notification-card"
                type={NOTIFICATION_CARD_TYPES.INFO}
                title={{
                  label: 'fundingSources.microDeposits.whatsNext',
                }}
                subtitle={{
                  label: 'batchPayment.success.scheduledNotes',
                }}
              />
              <MainActions />
            </BatchInfoContainer>
          </WizardElements.WizardActionsContainer>
        </WizardElements.WizardInnerSuccess>
      </QBOLayoutElements.QBOLayoutContainer>
    </ModalContainer>
  );
};
