import { useIntl } from 'react-intl';
import { SmartIcon, Text, Tooltip } from '@melio/billpay-design-system';
import {
  Column,
  ColumnItem,
  Container,
  IconContainer,
  MethodColumn,
  MethodsTable,
  Row,
  SectionColumn,
  SectionHeader,
  SectionTitle,
} from './FundingSourceTable.styles';
import { FundingSource } from '../';

type FundingSourceTableProps = {
  methods: string[];
  onSelect?: (method) => void;
};

export const FundingSourceTable = ({ onSelect, methods = [] }: FundingSourceTableProps) => {
  const { formatMessage, messages } = useIntl();

  const onMethodSelect = (method: string) => {
    if (onSelect) onSelect(method);
  };

  const getColumnItem = (method: string, column: string): JSX.Element => {
    const textLabelKey = `fundingSource.sources.${method}.advantages.${column}`;
    const text = messages[textLabelKey] ? formatMessage({ id: textLabelKey }) : null;

    const infoLabelKey = `fundingSource.sources.${method}.info.${column}`;
    const info = messages[infoLabelKey] ? formatMessage({ id: infoLabelKey }) : null;

    const showText = !!text;
    const showInfo = !!info;

    return (
      <ColumnItem key={`${method}_${column}`}>
        <SmartIcon type={showText ? 'checked' : 'close'} size="1.5rem" color="ds.green.200" />
        {showText && <Text>{text}</Text>}
        {showInfo && (
          <Tooltip placement="top-end" label={info}>
            <IconContainer>
              <SmartIcon type="info" size="1.3rem" />
            </IconContainer>
          </Tooltip>
        )}
      </ColumnItem>
    );
  };

  return (
    <Container>
      <MethodsTable>
        <tbody>
          <Row>
            <SectionColumn>
              <SectionTitle>
                <SectionHeader>
                  {formatMessage({ id: 'fundingSource.tableSections.headers.method' })}
                </SectionHeader>
              </SectionTitle>
            </SectionColumn>
            {methods?.map((method) => (
              <MethodColumn key={`${method}`} $isDebit={method === 'debit'}>
                <FundingSource
                  key={method}
                  fundingSource={method}
                  onClick={() => onMethodSelect(method)}
                />
              </MethodColumn>
            ))}
          </Row>
        </tbody>
      </MethodsTable>
      <MethodsTable>
        <tbody>
          <Row>
            <SectionColumn>
              <SectionTitle>
                <SectionHeader>
                  {formatMessage({ id: 'fundingSource.tableSections.headers.send' })}
                </SectionHeader>
              </SectionTitle>
              <SectionTitle>
                {formatMessage({ id: 'fundingSource.tableSections.transfer' })}
              </SectionTitle>
              <SectionTitle>
                {formatMessage({ id: 'fundingSource.tableSections.check' })}
              </SectionTitle>
              <SectionTitle>
                {formatMessage({ id: 'fundingSource.tableSections.defer' })}
              </SectionTitle>
              <SectionTitle>
                {formatMessage({ id: 'fundingSource.tableSections.cashback' })}
              </SectionTitle>
            </SectionColumn>
            {methods?.map((method) => (
              <Column key={`${method}_advantages`}>
                {getColumnItem(method, 'transfer')}
                {getColumnItem(method, 'check')}
                {getColumnItem(method, 'defer')}
                {getColumnItem(method, 'cashback')}
              </Column>
            ))}
          </Row>
        </tbody>
      </MethodsTable>
    </Container>
  );
};
