//import { GoogleAddress } from './../model/dtos/googleAddress';
import { Address } from 'src/app/version-2/model/dtos';
import { getGoogleAddress } from './getGoogleAddress.utils';
import { ADDRESS_DEFAULTS_NO_GOOGLE_PLACE_ID } from '../model/constants/getGoogleAddressFields.consts';
import { GoogleCombinedAddress } from '../model/dtos/googleCombinedAddress';

export const convertToServerAddress = (googleAddress: GoogleCombinedAddress): Address => {
  const address = getGoogleAddress(googleAddress);

  return {
    addressLine1: address.addressLine1,
    addressLine2: '',
    city: address.cityComponent ? address.cityComponent.long_name : address.cityFromString,
    state: address.stateComponent ? address.stateComponent.short_name : '',
    zipCode: address.zipComponent ? address.zipComponent.long_name : '',
    countryCode: address.countryCodeComponent ? address.countryCodeComponent.short_name : '',
    googlePlaceId: googleAddress.placeId || ADDRESS_DEFAULTS_NO_GOOGLE_PLACE_ID,
    addressLat: googleAddress.geometry.lat,
    addressLng: googleAddress.geometry.lng,
    formattedAddress: googleAddress.formattedAddress,
    aptNumber: googleAddress.aptNumber,
  };
};
