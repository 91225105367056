import React from 'react';
import { CONSTS } from 'src/app/utils/consts';
import { OptionalDeliveryMethodsType } from 'src/app/utils/types';
import QBRNewAchDeliveryMethodForm from './QBRNewAchDeliveryMethodForm';
import QBRNewCheckOrCardDeliveryMethodForm from './QBRNewCheckOrCardDeliveryMethodForm';

type Props = {
  submit: any;
  selectedDeliveryMethod?: OptionalDeliveryMethodsType;
  achModel?: any;
  checkModel?: any;
  cardModel?: any;
};

const QBRNewDeliveryMethodForm = ({
  submit,
  selectedDeliveryMethod,
  achModel,
  checkModel,
  cardModel,
}: Props) => {
  const printNameLabel = cardModel
    ? 'vendors.deliveryMethods.shiftToDebit.printName'
    : 'vendors.addDeliveryMethodByLink.printName';

  return (
    <>
      {selectedDeliveryMethod === CONSTS.DELIVERY_TYPE.ACH ? (
        <QBRNewAchDeliveryMethodForm submit={submit} achModel={achModel} />
      ) : (
        <QBRNewCheckOrCardDeliveryMethodForm
          submit={submit}
          model={cardModel || checkModel}
          printNameLabel={printNameLabel}
        />
      )}
    </>
  );
};

export default QBRNewDeliveryMethodForm;
