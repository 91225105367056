import React from 'react';
import styled from 'styled-components';

import { NOTIFICATION_CARD_TYPES } from 'src/app/utils/consts';
import QBONotificationCard from 'src/app/components/qbo/QBONotificationCard';
import { NotificationDM as CheckFeeNotification } from 'src/app/version-2/components/CheckFee/NotificationDM';
import { BatchDataItem } from 'src/app/pages/bill/pay/types';

type Props = {
  numOfErrors?: number;
  isCompleted: boolean;
  isCompletedNotificationClosed: boolean;
  onCompletedNotificationClose: () => void;
  numOfBills: number;
  originalNumOfBills: number;
  isLimitReached?: boolean;
  filteredDirectPayments?: boolean;
  isLimitNotificationClosed: boolean;
  onLimitNotificationClose: () => void;
  isHolidaysWarning?: boolean;
  onHolidaysNotificationClose?: () => void;
  batchData: BatchDataItem[];
};
const QBOBatchNotifications = ({
  numOfErrors,
  isCompleted,
  isCompletedNotificationClosed,
  onCompletedNotificationClose,
  numOfBills,
  originalNumOfBills,
  isLimitReached,
  filteredDirectPayments,
  isLimitNotificationClosed,
  onLimitNotificationClose,
  isHolidaysWarning,
  onHolidaysNotificationClose,
  batchData,
}: Props) => (
  <Container>
    {!!numOfErrors && (
      <QBONotificationCard
        type={NOTIFICATION_CARD_TYPES.ERROR}
        title={{
          label: 'batchPayment.notifications.error.missingDetails',
          values: {
            numOfErrors,
            red: (...chunks) => <Red>{React.Children.toArray(chunks)}</Red>,
          },
        }}
      />
    )}
    {isCompleted && (
      <NotificationAnimationWrapper className={isCompletedNotificationClosed ? 'close' : ''}>
        <QBONotificationCard
          type={NOTIFICATION_CARD_TYPES.SUCCESS}
          title={{
            label: 'batchPayment.notifications.success.detailsAdded',
            values: { numOfErrors },
          }}
          onClose={onCompletedNotificationClose}
        />
      </NotificationAnimationWrapper>
    )}
    {isLimitReached && (
      <NotificationAnimationWrapper
        className={isLimitNotificationClosed ? 'close' : ''}
        data-testid="batchPayment.notifications.warning.limit"
      >
        <QBONotificationCard
          type={NOTIFICATION_CARD_TYPES.WARNING}
          title={{
            label: 'batchPayment.notifications.warning.limit.title',
            values: { paymentLimit: numOfBills },
          }}
          subtitle={{
            label: 'batchPayment.notifications.warning.limit.subtitle',
            values: { paymentLimit: numOfBills, originalNumOfBills },
          }}
          onClose={onLimitNotificationClose}
        />
      </NotificationAnimationWrapper>
    )}
    {!isLimitReached && filteredDirectPayments && (
      <NotificationAnimationWrapper
        className={isLimitNotificationClosed ? 'close' : ''}
        data-testid="batchPayment.notifications.warning.limit"
      >
        <QBONotificationCard
          type={NOTIFICATION_CARD_TYPES.WARNING}
          title={{
            label: 'batchPayment.notifications.warning.filtered.title',
          }}
          onClose={onLimitNotificationClose}
        />
      </NotificationAnimationWrapper>
    )}
    {isHolidaysWarning && (
      <NotificationAnimationWrapper
        className={isLimitNotificationClosed ? 'close' : ''}
        data-testid="batchPayment.notifications.warning.holidays"
      >
        <QBONotificationCard
          type={NOTIFICATION_CARD_TYPES.WARNING}
          title={{
            label: 'batchPayment.notifications.warning.holidays.title',
          }}
          subtitle={{
            label: 'batchPayment.notifications.warning.holidays.subtitle',
          }}
          onClose={onHolidaysNotificationClose}
        />
      </NotificationAnimationWrapper>
    )}
    <CheckFeeNotification batchData={batchData} />
  </Container>
);

export default QBOBatchNotifications;

const NotificationAnimationWrapper = styled.div`
  transition: all 0.5s ease-in-out 0.1s, opacity 0.3s ease-out 0s;
  height: 8rem;

  &.close {
    height: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
  }
`;

const Red = styled.span`
  color: ${({ theme }) => theme.text.color.error};
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

const Container = styled.div`
  margin-bottom: 4rem;
  > :not(:last-child) {
    margin-bottom: 2rem;
  }
`;
