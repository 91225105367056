const baseUrl = '/orgs/:orgId/quickbooks/just-pay';

const LOCATIONS = {
  create: {
    entry: `${baseUrl}`,
    selectFundingSource: `${baseUrl}/select-funding-source`,
    selectDeliveryMethod: `${baseUrl}/select-delivery-method`,
    connectBankAccount: `${baseUrl}/connect-bank-account`,
    verifyBankAccount: `${baseUrl}/verify-bank-account`,
    noteToVendor: `${baseUrl}/note-to-vendor`,
    reviewAndConfirm: `${baseUrl}/review-and-confirm`,
    success: `${baseUrl}/success`,
    completeLegalInfo: `${baseUrl}/complete-legal-info`,
  },
  operations: {
    selectDeductionDate: `${baseUrl}/select-deduction-date`,
  },
  edit: {
    achDeliveryMethodPage: `${baseUrl}/ach-delivery-method-page`,
    checkDeliveryMethodPage: `${baseUrl}/check-delivery-method-page`,
  },
  errorPage: `${baseUrl}/error-page`,
};

export default LOCATIONS;
