import { getMemoPageEvents } from 'src/app/services/analytics/common-events/memo-page-events';
import { EventMappingsTree } from 'src/app/services/analytics/types';

export const JUST_PAY_PAGE_EVENT = 'just-pay';

const MAPPING: EventMappingsTree = {
  '/orgs/*/quickbooks/just-pay': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'add-payment-info'],
    'payment-info-continue': [
      JUST_PAY_PAGE_EVENT,
      'payment-info-continue',
      {
        vendorId: 'vendorId',
        isNewVendor: 'isNewVendor',
        category: 'category',
        isCategoryPreselected: 'isCategoryPreselected',
        trackingBillId: 'trackingBillId',
      },
    ],
    'form-validation-error': [
      JUST_PAY_PAGE_EVENT,
      'payment-info-continue-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'select-vendor-category': [
      JUST_PAY_PAGE_EVENT,
      'select-vendor-category',
      {
        categoryId: 'categoryId',
        vendorId: 'vendorId',
        categoryName: 'categoryName',
        trackingBillId: 'trackingBillId',
      },
    ],
    'upload-image': [JUST_PAY_PAGE_EVENT, 'upload-image', { trackingBillId: 'trackingBillId' }],
    'upload-10K-invoice-file-success': [
      JUST_PAY_PAGE_EVENT,
      'upload-10K-invoice-file-success',
      { trackingBillId: 'trackingBillId' },
    ],
    'error-upload-image': [
      JUST_PAY_PAGE_EVENT,
      'error-upload-image',
      { trackingBillId: 'trackingBillId' },
    ],
    'retry-upload-image': [
      JUST_PAY_PAGE_EVENT,
      'error-upload-image',
      { trackingBillId: 'trackingBillId' },
    ],
    'go.exit': [JUST_PAY_PAGE_EVENT, 'browser-go-back'],
    'go.back': [JUST_PAY_PAGE_EVENT, 'exit'],
    'justPay.introModal.cta': [JUST_PAY_PAGE_EVENT, 'intro-modal-continue'],
    'pay-bills-redirect': [JUST_PAY_PAGE_EVENT, 'intro-modal-pay-bills-redirect'],
    'intro-modal': [JUST_PAY_PAGE_EVENT, 'intro-modal', { toggle: 'toggle' }],
  },
  '/orgs/*/quickbooks/just-pay/select-funding-source': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'select-funding-source'],
    'add-bank-account': [
      JUST_PAY_PAGE_EVENT,
      'add-bank-account',
      { trackingBillId: 'trackingBillId' },
    ],
    'add-credit-card': [
      JUST_PAY_PAGE_EVENT,
      'add-credit-card',
      { trackingBillId: 'trackingBillId' },
    ],
    'add-debit-card': [JUST_PAY_PAGE_EVENT, 'add-debit-card', { trackingBillId: 'trackingBillId' }],
    'verify-click': [JUST_PAY_PAGE_EVENT, 'verify-click', { trackingBillId: 'trackingBillId' }],
    'verify-finish': [JUST_PAY_PAGE_EVENT, 'verify-finish', { trackingBillId: 'trackingBillId' }],
    'change-funding-source': [
      JUST_PAY_PAGE_EVENT,
      'change-funding-source',
      { fundingSourceId: 'fundingSourceId', trackingBillId: 'trackingBillId' },
    ],
    'go.exit': [JUST_PAY_PAGE_EVENT, 'browser-go-back'],
    'go.back': [JUST_PAY_PAGE_EVENT, 'exit'],
    'funding-source-continue': [
      JUST_PAY_PAGE_EVENT,
      'funding-source-continue',
      { fundingSourceId: 'fundingSourceId', trackingBillId: 'trackingBillId' },
    ],
  },
  '/orgs/*/quickbooks/just-pay/select-delivery-method': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'add-delivery'],
    'delivery-method-add': [
      JUST_PAY_PAGE_EVENT,
      'delivery-method-add',
      { deliveryMethodType: 'deliveryMethodType', trackingBillId: 'trackingBillId' },
    ],
    'go.exit': [JUST_PAY_PAGE_EVENT, 'browser-go-back'],
    'go.back': [JUST_PAY_PAGE_EVENT, 'exit'],
    'set-delivery-method-continue': [
      JUST_PAY_PAGE_EVENT,
      'set-delivery-method-continue',
      { trackingBillId: 'trackingBillId' },
    ],
  },
  '/orgs/*/quickbooks/just-pay/ach-delivery-method-page': {
    'add-delivery-method': [
      JUST_PAY_PAGE_EVENT,
      'add-delivery-method',
      { deliveryMethodType: 'type', trackingBillId: 'trackingBillId' },
    ],
    'add-delivery-method-success': [
      JUST_PAY_PAGE_EVENT,
      'create-delivery-method-success',
      { deliveryMethodType: 'type', trackingBillId: 'trackingBillId' },
    ],
    'add-delivery-method-failure': [
      JUST_PAY_PAGE_EVENT,
      'create-delivery-method-failure',
      { deliveryMethodType: 'type', trackingBillId: 'trackingBillId' },
    ],
    'delivery-method-validation-error': [
      JUST_PAY_PAGE_EVENT,
      'add-delivery-method-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'go.exit': [JUST_PAY_PAGE_EVENT, 'browser-go-back'],
    'go.back': [JUST_PAY_PAGE_EVENT, 'exit'],
  },
  '/orgs/*/quickbooks/just-pay/check-delivery-method-page': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'add-check-delivery-method-address'],
    'create-delivery-method-success': [
      JUST_PAY_PAGE_EVENT,
      'create-delivery-method-success',
      { deliveryMethodType: 'deliveryMethodType', trackingBillId: 'trackingBillId' },
    ],
    'create-delivery-method-failure': [
      JUST_PAY_PAGE_EVENT,
      'create-delivery-method-failure',
      { deliveryMethodType: 'deliveryMethodType', trackingBillId: 'trackingBillId' },
    ],
    'add-delivery-method': [
      JUST_PAY_PAGE_EVENT,
      'add-delivery-method',
      { deliveryMethodType: 'deliveryMethodType', trackingBillId: 'trackingBillId' },
    ],
  },
  '/orgs/*/quickbooks/just-pay/select-deduction-date': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'date'],
    'fast-ach-shown': [
      JUST_PAY_PAGE_EVENT,
      'fast-ach-shown',
      { fundingSourceType: 'fundingSourceType', trackingBillId: 'trackingBillId' },
    ],
    'delivery-selected-expedited-ach': [
      JUST_PAY_PAGE_EVENT,
      'delivery-selected-expedited-ach',
      { trackingBillId: 'trackingBillId' },
    ],
    'delivery-selected-ach': [
      JUST_PAY_PAGE_EVENT,
      'delivery-selected-ach',
      { trackingBillId: 'trackingBillId' },
    ],
    'express-check-shown': [
      JUST_PAY_PAGE_EVENT,
      'express-check-shown',
      { fundingSourceType: 'fundingSourceType', trackingBillId: 'trackingBillId' },
    ],
    'delivery-selected-express-check': [
      JUST_PAY_PAGE_EVENT,
      'delivery-selected-express-check',
      { trackingBillId: 'trackingBillId' },
    ],
    'delivery-selected-check': [
      JUST_PAY_PAGE_EVENT,
      'delivery-selected-check',
      { trackingBillId: 'trackingBillId' },
    ],
    'select-date': [
      JUST_PAY_PAGE_EVENT,
      'select-date',
      {
        suggestedScheduledDate: 'suggestedScheduledDate',
        deliveryDate: 'deliveryDate',
        maxDeliveryDate: 'maxDeliveryDate',
        trackingBillId: 'trackingBillId',
      },
    ],
    'set-date-continue': [
      JUST_PAY_PAGE_EVENT,
      'set-date-continue',
      { trackingBillId: 'trackingBillId' },
    ],
  },
  '/orgs/*/quickbooks/just-pay/note-to-vendor': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'memo'],
    'set-memo-continue': [
      JUST_PAY_PAGE_EVENT,
      'set-memo-continue',
      { memoToVendor: 'memoToVendor', trackingBillId: 'trackingBillId' },
    ],
    ...getMemoPageEvents(JUST_PAY_PAGE_EVENT),
  },
  '/orgs/*/quickbooks/just-pay/review-and-confirm': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'confirm'],
    'confirm-and-schedule': [JUST_PAY_PAGE_EVENT, 'confirm', { trackingBillId: 'trackingBillId' }],
    'qbcash-low-balance-confirm-open': [
      JUST_PAY_PAGE_EVENT,
      'qbcash-low-balance-confirm-open',
      { trackingBillId: 'trackingBillId' },
    ],
    'qbcash-low-balance-confirm-close': [
      JUST_PAY_PAGE_EVENT,
      'qbcash-low-balance-confirm-close',
      { trackingBillId: 'trackingBillId' },
    ],
    'qbcash-low-balance-edit-funding-source': [
      JUST_PAY_PAGE_EVENT,
      'qbcash-low-balance-edit-funding-source',
      { trackingBillId: 'trackingBillId' },
    ],
    'qbcash-low-balance-schedule-anyway': [
      JUST_PAY_PAGE_EVENT,
      'qbcash-low-balance-schedule-anyway',
      { trackingBillId: 'trackingBillId' },
    ],
    'confirm-success': [
      JUST_PAY_PAGE_EVENT,
      'confirm-success',
      { paymentId: 'paymentId', trackingBillId: 'trackingBillId' },
    ],
    'confirm-failure': [
      JUST_PAY_PAGE_EVENT,
      'confirm-failure',
      { createPaymentError: 'createPaymentError', trackingBillId: 'trackingBillId' },
    ],
    'edit-note': [JUST_PAY_PAGE_EVENT, 'edit-note', { trackingBillId: 'trackingBillId' }],
    'edit-date': [JUST_PAY_PAGE_EVENT, 'edit-date', { trackingBillId: 'trackingBillId' }],
    'edit-funding-source': [
      JUST_PAY_PAGE_EVENT,
      'edit-funding-source',
      { trackingBillId: 'trackingBillId' },
    ],
    'edit-delivery-method': [
      JUST_PAY_PAGE_EVENT,
      'edit-delivery-method',
      { trackingBillId: 'trackingBillId' },
    ],
    'go-to-complete-legal-info': [
      JUST_PAY_PAGE_EVENT,
      'go-to-complete-legal-info',
      { trackingBillId: 'trackingBillId' },
    ],
    'go-to-dashboard': [
      JUST_PAY_PAGE_EVENT,
      'go-to-dashboard',
      { trackingBillId: 'trackingBillId' },
    ],
  },
  '/orgs/*/quickbooks/just-pay/success': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'success'],
    'notify-my-vendor': [
      JUST_PAY_PAGE_EVENT,
      'notify-my-vendor',
      { trackingBillId: 'trackingBillId' },
    ],
    'success-vendor-email-modal-closed': [
      JUST_PAY_PAGE_EVENT,
      'success-vendor-email-modal-closed',
      { trackingBillId: 'trackingBillId' },
    ],
    'done-btn': [JUST_PAY_PAGE_EVENT, 'done', { trackingBillId: 'trackingBillId' }],
  },
  '/orgs/*/quickbooks/just-pay/complete-legal-info': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'complete-legal-info'],
    'complete-legal-info-validation-errors': [
      JUST_PAY_PAGE_EVENT,
      'complete-legal-info-validation-errors',
      { validationErrors: 'validationErrors', trackingBillId: 'trackingBillId' },
    ],
    'complete-legal-info-continue-success': [
      JUST_PAY_PAGE_EVENT,
      'complete-legal-info-continue-success',
      { trackingBillId: 'trackingBillId' },
    ],
    'complete-legal-info-continue-failure': [
      JUST_PAY_PAGE_EVENT,
      'complete-legal-info-continue-failure',
      { trackingBillId: 'trackingBillId' },
    ],
    'hide-error-alert': [
      JUST_PAY_PAGE_EVENT,
      'hide-error-alert',
      { trackingBillId: 'trackingBillId' },
    ],
    'bill-error': [JUST_PAY_PAGE_EVENT, 'bill-error', { trackingBillId: 'trackingBillId' }],
    'go-to-dashboard': [
      JUST_PAY_PAGE_EVENT,
      'go-to-dashboard',
      { trackingBillId: 'trackingBillId' },
    ],
  },
  '/orgs/*/quickbooks/just-pay/error-page': {
    'page.view': [JUST_PAY_PAGE_EVENT, 'error-page'],
    'support-open-start-chat': [
      JUST_PAY_PAGE_EVENT,
      'support-open-start-chat',
      { trackingBillId: 'trackingBillId' },
    ],
  },
};

export default MAPPING;
