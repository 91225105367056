import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@melio/billpay-design-system';

import billsStore from 'src/app/modules/bills/bills-store';
import { DashboardListItemType } from 'src/app/pages/qb-dashboard/types';
import QBOTableDate from './QBOTableDate';
import { BillType } from 'src/app/utils/types';

type Props = {
  item: DashboardListItemType;
};

const DueDateCell = ({ item }: Props) => {
  const bill: BillType = useSelector(billsStore.selectors.byId(item.billId));
  const isRecurring = bill?.recurringBillId;

  if (isRecurring) return <Box>-</Box>;

  return <QBOTableDate date={bill?.dueDate} />;
};

export default DueDateCell;
