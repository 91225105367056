import { CLEAR_STATE } from 'src/app/redux/user/actionTypes';
import type { OrganizationState } from './types';
import {
  SET_ORGANIZATION_PREFERENCES,
  UPDATE_ORGANIZATION_PREFERENCE,
  UPDATE_ORGANIZATION_PREFERENCE_SUCCESS,
  UPDATE_ORGANIZATION_PREFERENCE_FAILED,
  UPDATE_SERVICE_FEES_PREFERENCE,
  LOAD_FEE_CATALOG_SUCCESS,
  LOAD_FEE_CATALOG_FAILED,
  INITIAL_FETCH_FEE_FUNDING_SOURCE,
  INITIAL_FETCH_FEE_FUNDING_SOURCE_SUCCESS,
  INITIAL_FETCH_FEE_FUNDING_SOURCE_FAILED,
  CLEAR_FEE_FUNDING_SOURCE,
  GET_ACTIVE_FEE_FUNDING_SOURCE_SUCCESS,
  GET_ACTIVE_FEE_FUNDING_SOURCE_FAILED,
  GET_ACTIVE_FEE_FUNDING_SOURCE,
  UPDATE_FEE_FUNDING_SOURCE,
  SET_FEE_FUNDING_LOADING,
  INITIAL_SET_FEE_FUNDING_SOURCE,
} from './actionTypes';

export const initialState: OrganizationState = {
  organizationPreferences: {},
  isOrganizationPreferencesUpdating: false,
  feeCatalog: null,
  feeFundingSource: undefined,
  isFeeFundingSourceLoading: false,
};

export const organizationReducer = (
  state: OrganizationState = initialState,
  action: any
): OrganizationState => {
  switch (action.type) {
    case SET_ORGANIZATION_PREFERENCES:
      return {
        ...state,
        organizationPreferences: action.organizationPreferences,
      };
    case UPDATE_ORGANIZATION_PREFERENCE:
      return {
        ...state,
        isOrganizationPreferencesUpdating: true,
      };
    case UPDATE_SERVICE_FEES_PREFERENCE:
      return {
        ...state,
        isOrganizationPreferencesUpdating: true,
      };
    case UPDATE_ORGANIZATION_PREFERENCE_SUCCESS:
      return {
        ...state,
        isOrganizationPreferencesUpdating: false,
        organizationPreferences: {
          ...state.organizationPreferences,
          [action.key]: action.value,
        },
      };
    case UPDATE_ORGANIZATION_PREFERENCE_FAILED:
      return {
        ...state,
        isOrganizationPreferencesUpdating: false,
      };
    case LOAD_FEE_CATALOG_SUCCESS:
      return {
        ...state,
        feeCatalog: action.feeCatalog,
      };
    case LOAD_FEE_CATALOG_FAILED:
      return {
        ...state,
        feeCatalog: null,
      };
    case INITIAL_FETCH_FEE_FUNDING_SOURCE:
      return {
        ...state,
        isFeeFundingSourceLoading: true,
        feeFundingSource: action.payload,
      };

    case INITIAL_FETCH_FEE_FUNDING_SOURCE_SUCCESS:
      return {
        ...state,
        isFeeFundingSourceLoading: false,
      };

    case INITIAL_FETCH_FEE_FUNDING_SOURCE_FAILED:
      return {
        ...state,
        feeFundingSource: undefined,
        isFeeFundingSourceLoading: false,
      };

    case UPDATE_FEE_FUNDING_SOURCE:
      return {
        ...state,
        feeFundingSource: action.payload,
      };

    case INITIAL_SET_FEE_FUNDING_SOURCE:
      return {
        ...state,
        feeFundingSource: action.payload,
      };

    case GET_ACTIVE_FEE_FUNDING_SOURCE:
      return {
        ...state,
        isFeeFundingSourceLoading: true,
      };

    case GET_ACTIVE_FEE_FUNDING_SOURCE_SUCCESS:
      return {
        ...state,
        isFeeFundingSourceLoading: false,
      };

    case GET_ACTIVE_FEE_FUNDING_SOURCE_FAILED:
      return {
        ...state,
        isFeeFundingSourceLoading: false,
      };

    case SET_FEE_FUNDING_LOADING:
      return {
        ...state,
        isFeeFundingSourceLoading: action.payload,
      };

    case CLEAR_FEE_FUNDING_SOURCE:
      return {
        ...state,
        feeFundingSource: undefined,
      };

    case CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
