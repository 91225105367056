import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { withNavigator } from 'src/app/hoc';
import { getBill, getPayment } from 'src/app/redux/payBillWizard/selectors';
import { useVendorMemoStepForm } from 'src/app/modules/vendors/vendor-memo-step/hooks/useVendorMemoStepForm';
import { useShouldAskForVendorEmailOnMemoStep } from 'src/app/modules/vendors/vendor-memo-step/hooks/useShouldAskForVendorEmailOnMemoStep';
import { updatePaymentMemoAction } from 'src/app/redux/payBillWizard/actions';
import { BillType, PaymentType } from 'src/app/utils/types';
import { MemoStepFormFields } from 'src/app/modules/vendors/vendor-memo-step/components/MemoStepFormFields';
import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import { withPayBillData, PayBillProps } from '../hoc/withPayBillData';
import { PayBillMemoPageLayout } from './components/PayBillMemoPageLayout';
import { useCheckOwnedVendorExists } from '../hooks/useCheckOwnedVendorExists';

const SCREEN_NAME = 'memo';
const EVENT_PAGE = 'pay-bill';

type Props = PayBillProps;

const Page = ({ onNextMemo, onPrevMemo, goExit }: Props) => {
  const payment: PaymentType = useSelector(getPayment);
  const bill: BillType = useSelector(getBill);

  const {
    deliveryMethod: { deliveryType, virtualCardAccount },
    note,
  } = payment;
  const { vendor } = bill;

  const vendorContactEmail = vendor.contactEmail || virtualCardAccount?.accountEmail;

  const analyticsProps = {
    vendorId: vendor.id,
    partialBillId: getPartialBillId(bill),
  };

  const dispatch = useDispatch();

  const onSubmitSuccess = () => {
    onNextMemo();
  };

  const [isOwnedVendorExists] = useCheckOwnedVendorExists({ bill, eventPage: EVENT_PAGE });

  const shouldAskForEmail = useShouldAskForVendorEmailOnMemoStep({
    isOwnedVendorExists,
    vendorContactEmail,
    deliveryType,
  });

  const { formMV, submit, loading } = useVendorMemoStepForm({
    onSubmitSuccess,
    shouldAskForEmail,
    vendorId: vendor.id,
    initialValues: {
      contactEmail: vendorContactEmail,
      memo: note,
    },
    onChange: (onlyChangedValues) => {
      onlyChangedValues.memo !== undefined &&
        dispatch(updatePaymentMemoAction(onlyChangedValues.memo));
    },
    onEmailUpdateSuccess: (newVendorEmail) => {
      analytics.trackAction('vendor-email-save-success', {
        screenName: SCREEN_NAME,
        vendorEmail: newVendorEmail,
        ...analyticsProps,
      });
    },
    onValidationErrors: (validationErrors) => {
      analytics.trackAction('form-validation-error', {
        validationErrors,
      });
    },
  });

  useEffect(() => {
    shouldAskForEmail &&
      analytics.trackAction('exposed-to-add-vendor-email-line', {
        screenName: SCREEN_NAME,
        ...analyticsProps,
      });
  }, [shouldAskForEmail]);

  return (
    <PayBillMemoPageLayout
      onExit={goExit}
      onPrev={onPrevMemo}
      onNext={submit}
      isProcessing={loading}
    >
      <MemoStepFormFields formMV={formMV} showEmailField={shouldAskForEmail} />
    </PayBillMemoPageLayout>
  );
};

export const PayBillMemoPage = compose(withNavigator(), withPayBillData())(Page);
