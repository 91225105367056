import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import { getExitUrl } from 'src/app/redux/payBillWizard/selectors';
import { RECOVERY_FLOW_TYPE } from '../consts';
import { ChooseDeliveryDate } from '../RecoveryFlowSteps/StepChooseDeliveryDate/ChooseDeliveryDate';
import { StepChooseDeliveryMethod } from '../RecoveryFlowSteps/StepChooseDeliveryMethod';
import { StepMissingACHDetails } from '../RecoveryFlowSteps/StepMissingACHDetails';

import locations from './locations';

type Props = {
  id: string | undefined;
  paymentId: string | undefined;
};

const ReturnedCheckRecoveryFlowRouter = ({ id, paymentId }: Props) => {
  const exitUrl = useSelector(getExitUrl);

  return (
    <Switch>
      <SmartRoute path={locations.index} exact>
        <StepChooseDeliveryMethod
          id={id}
          paymentId={paymentId}
          prevStepURL={exitUrl}
          flowType={RECOVERY_FLOW_TYPE.RETURNED_CHECK}
        />
      </SmartRoute>
      <SmartRoute path={locations.missingACHDetails} exact>
        <StepMissingACHDetails
          id={id}
          paymentId={paymentId}
          prevStepURL={locations.index}
          flowType={RECOVERY_FLOW_TYPE.RETURNED_CHECK}
        />
      </SmartRoute>
      <SmartRoute path={locations.chooseCheckDelivery} exact>
        <ChooseDeliveryDate
          id={id}
          paymentId={paymentId}
          prevStepURL={locations.index}
          flowType={RECOVERY_FLOW_TYPE.RETURNED_CHECK}
        />
      </SmartRoute>
    </Switch>
  );
};

export default ReturnedCheckRecoveryFlowRouter;
