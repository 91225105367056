import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { withBreak, withPreservedStateNavigator } from 'src/app/hoc';
import { getSettingPagesRoutes } from 'src/app/utils/settings-pages-route';
import locations from 'src/app/utils/locations';
import { CompanyInfoType, BillType, NavigateType } from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import MINavigation from 'src/app/components/common/MINavigation';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import { getBill } from 'src/app/redux/payBillWizard/selectors';
import { getDeletedFundingSource } from 'src/app/redux/payBillWizard/selectors';
import analytics from 'src/app/services/analytics';
import {
  applicationActions,
  applicationSelectors,
} from 'src/app/version-2/modules/application/application.slice';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { getCompanyInfo, getFundingSources, getOrgId } from 'src/app/redux/user/selectors';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { Site } from 'src/app/sites/site';
import qbDashboardLocations from 'src/app/pages/qb-dashboard/locations';
import { clearDeletedFundingSources } from 'src/app/redux/payBillWizard/actions';

type MapStateToProps = {
  bill: RecordOf<BillType>;
  companyInfo: RecordOf<CompanyInfoType>;
  urlToBack: string;
  deletedFundingSources: number[];
  fundingSources: FundingSource[];
  orgId: string;
};

type MapDispatchToProps = {
  setUrlToBack: (url: string) => void;
  clearCurrentDeletedFundingSources: () => void;
};

type Props = {
  device: {
    isDesktop: boolean;
    isMobile: boolean;
  };
  location: {
    pathname: string;
  };
  navigate: NavigateType;
  site: Site;
  locationState: Record<string, any>;
} & MapStateToProps &
  MapDispatchToProps;

class QBOSettingsPageContainer extends React.PureComponent<Props> {
  updateDeletedFundingSourcesInPreservedState = () => {
    const {
      locationState,
      deletedFundingSources,
      fundingSources,
      clearCurrentDeletedFundingSources,
    } = this.props;
    const locationStateToUpdate = { ...locationState };

    if (deletedFundingSources?.length && locationStateToUpdate?.batchList?.length) {
      locationStateToUpdate.batchList = locationStateToUpdate.batchList.map((batchElement) => {
        const deletedFundingSource = deletedFundingSources.find(
          (deletedFundingSource: number) =>
            deletedFundingSource === batchElement.payment.fundingSourceId
        );

        if (deletedFundingSource) {
          return {
            ...batchElement,
            payment: {
              ...batchElement.payment,
              fundingSourceId: fundingSources[0]?.id,
              fundingSource: fundingSources[0],
            },
          };
        }

        return batchElement;
      });
    }

    clearCurrentDeletedFundingSources();

    return locationStateToUpdate;
  };
  goExit = () => {
    const { navigate, bill, urlToBack, setUrlToBack } = this.props;
    let defaultUrl;

    if (!bill.id) {
      defaultUrl = `${qbDashboardLocations.defaultDashboard}`;
    } else {
      defaultUrl = locations.Bills.pay.funding.url({ id: bill.id });
    }

    const locationStateUpdated = this.updateDeletedFundingSourcesInPreservedState();

    analytics.track('qbo-settings', 'exit');
    navigate(urlToBack || defaultUrl, false, {
      ...locationStateUpdated,
      backFromSettings: true,
    });
    setUrlToBack('');
  };

  handlerQBORoutes = () => {
    const { site, device, orgId } = this.props;

    if (device.isMobile) {
      return getSettingPagesRoutes(site, orgId).filter(
        (route) => route?.title !== 'settings.account.title'
      );
    }

    return getSettingPagesRoutes(site, orgId);
  };

  render() {
    const { locationState } = this.props;
    const routes = this.handlerQBORoutes();

    return (
      <QBOLayoutPage
        headerLabel="qbo.settings.title"
        innerSize={75}
        contentWrapperMode="settings"
        hideHeader
      >
        <SettingsContainer>
          <SettingsTitle>
            <IconBackArrow className="icon-intuit-back-arrow" onClick={this.goExit} />
            <PageTitle>
              <MIFormattedText label="qbo.settings.title" />
            </PageTitle>
            <IconBackArrow />
            <Close onClick={this.goExit} />
          </SettingsTitle>
          <SettingsCard>
            <SettingsNavigationContainer>
              <MINavigation items={routes} preservedState={locationState} />
            </SettingsNavigationContainer>
            <Switch>
              {routes.map((route) => (
                <Route key={route.path} path={route.path} exact={route.exact} render={route.main} />
              ))}
              <Redirect to={{ pathname: routes[0].path, state: locationState }} />
            </Switch>
          </SettingsCard>
        </SettingsContainer>
      </QBOLayoutPage>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  bill: getBill(state),
  urlToBack: applicationSelectors.selectUrlToBack(state),
  deletedFundingSources: getDeletedFundingSource(state),
  fundingSources: getFundingSources(state),
  companyInfo: getCompanyInfo(state),
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  setUrlToBack(url: string) {
    dispatch(applicationActions.setUrlToBack(url));
  },
  clearCurrentDeletedFundingSources() {
    dispatch(clearDeletedFundingSources());
  },
});

export default compose(
  withBreak(),
  withPreservedStateNavigator(),
  withSiteContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(QBOSettingsPageContainer);

const Close = styled.i.attrs({ className: 'icon-close-icon' })`
  font-size: 2.4rem;
  cursor: pointer;
  color: ${(props) => props.theme.text.color.label};

  @media ${devices.desktop} {
    position: fixed;
    top: 1.2rem;
    right: 1.4rem;
    z-index: 1;
  }

  @media ${devices.mobile} {
    color: ${(props) => props.theme.text.color.green};
    top: 3rem;
    padding-right: 1.8rem;
  }
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media ${devices.mobile} {
    width: auto;
    margin: 1rem 0 2rem 0;
  }

  @media ${devices.phablet} {
    width: auto;
    margin: 4rem 2rem 2rem 2rem;
  }

  @media ${devices.tablet}, ${devices.desktop} {
    width: 100%;
    margin: 4rem 0 4rem 0;
  }
`;

const SettingsNavigationContainer = styled.div`
  margin: 0 3.6rem;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
`;

const SettingsCard = styled.div`
  border: 1px solid rgba(231, 231, 231, 1);
  border-radius: 0.9rem;
  box-shadow: none;
  box-sizing: border-box;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(props) => props.theme.colors.white.opaque};
  margin-top: 1rem;

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: #f4f5f8;
    border-radius: 0;
    border: none;
  }
`;

const SettingsTitle = styled.div`
  margin: 0;
  font-size: 2.3rem;
  font-weight: ${(props) => props.theme.text.weight.normal};
  color: ${(props) => props.theme.text.color.main};
  line-height: 3.1rem;

  @media ${devices.mobile}, ${devices.phablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 2rem;
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.text.weight.bold};
    line-height: 2rem;
  }
`;

const IconBackArrow = styled.i`
  @media ${devices.nonMobile} {
    display: none;
  }
  font-size: 2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding-left: 1.8rem;
  @media ${devices.mobile} {
    color: ${(props) => props.theme.text.color.green};
  }
`;

const PageTitle = styled.div`
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: center;
`;
