import React from 'react';
import styled from 'styled-components';
import { BUTTON_SIZE } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';

type BottomUploadComponentProps = {
  label: string;
  action: () => void;
  shadow: boolean;
};

export const BottomButtonComponent = ({ label, action, shadow }: BottomUploadComponentProps) => (
  <BottomButtonContainer shadow={shadow}>
    <BottomButtonOrSeparator>
      <OrText>
        <MIFormattedText label="general.or" />
      </OrText>
    </BottomButtonOrSeparator>
    <BottomButtonWrapper>
      <MIButton onClick={action} label={label} fullWidth size={BUTTON_SIZE.TINY} />
    </BottomButtonWrapper>
  </BottomButtonContainer>
);

const BottomButtonContainer = styled.div<{ shadow: boolean }>`
  ${(props) => props.shadow && `box-shadow: 0 2px 10px 0 ${props.theme.colors.dark.translucent1};`}
`;

const OrText = styled.span`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  background: white;
  padding: 0 15px;
`;
const BottomButtonOrSeparator = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.grey};
  line-height: 0.1rem;
  padding-top: 17px;
`;
const BottomButtonWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
`;
