export const getArrayOfPaymentsForModal = (objsArray) =>
  objsArray.map((obj) => {
    const { vendorId, paymentId, deliveryType, organizationId, scheduledDate } = obj;

    return {
      vendorId,
      paymentId,
      organizationId,
      deliveryType,
      scheduledDate,
    };
  });

export const getVendorIdToContactEmailMapping = (objsArray) =>
  objsArray.reduce((acc, obj) => {
    const { id, contactEmail } = obj;

    return {
      ...acc,
      [id]: contactEmail,
    };
  }, {});
