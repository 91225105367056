export const HeadingTextStyle = {
  textStyle: 'h6',
  fontWeight: '600',
  color: 'black',
  my: '1',
};

export const TextStyle = {
  textStyle: 'body2',
  color: 'gray.500',
  my: '1',
};
