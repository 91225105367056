export const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

const isObject = (value) => {
  const type = typeof value;

  return value !== null && (type === 'object' || type === 'function');
};

export const deepMerge = (object, ...sources) => {
  if (!isObject(object)) {
    return object;
  }

  sources.forEach((source) => {
    if (isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!object[key] || !isObject(object[key])) {
            object[key] = {};
          }

          deepMerge(object[key], source[key]);
        } else {
          object[key] = source[key];
        }
      }
    }
  });

  return object;
};
