import React, { useEffect } from 'react';
import styled from 'styled-components';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import { MIFormattedText } from 'src/app/utils/formatting';
import intercomService from 'src/app/services/intercom';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import MIButton from 'src/app/components/common/MIButton';
import MILink from 'src/app/components/common/MILink';
import { CONSTS, BUTTON_SIZE } from 'src/app/utils/consts';
import qbLogo from 'src/app/images/accounting-software/quickbooks-logo-horz.png';
import QuestionMark from 'src/app/images/general/question-mark-circle.svg';
import { envApi } from 'src/app/version-2/api/env';
import analytics from '../../../services/analytics';

interface IAccountNotFoundPageProps {
  goToQboHomepageOverride?: () => void;
}

export function AccountNotFoundPage({ goToQboHomepageOverride }: IAccountNotFoundPageProps) {
  useEffect(() => {
    const eventPage = 'account-not-found-page';

    analytics.page(eventPage, eventPage);
  }, []);

  const site = useSiteContext();

  const goToQboHomepage = () => {
    goToQboHomepageOverride
      ? goToQboHomepageOverride()
      : window.location.replace(envApi.getConfig().quickbooks.baseUrl);
  };

  const openSupportChat = () => {
    analytics.track('micro-deposits-account-not-found', 'support-open-start-chat');
    intercomService.show();
  };

  return (
    <QBOLayoutPage hideHeader contentWrapperMode="success">
      <Container>
        <QBLogo src={qbLogo} />
        <ContentContainer>
          <QuestionMarkIconContainer src={QuestionMark} />
          <TextsContainer>
            <TitleContainer>
              <MIFormattedText label="accountNotFound.title" />
            </TitleContainer>
            <BodyContainer>
              <MIFormattedText
                label="accountNotFound.text"
                values={{
                  supportMail: (
                    <SupportLink href={`mailto:${site.config.support.email}`}>
                      <MIFormattedText label="accountNotFound.email" />
                    </SupportLink>
                  ),
                  chatWithUs: <ChatLink to={openSupportChat} label="accountNotFound.chatWithUs" />,
                }}
              />
            </BodyContainer>
          </TextsContainer>
          <MIButton
            label="accountNotFound.button"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            size={BUTTON_SIZE.VERY_SMALL}
            onClick={goToQboHomepage}
          />
        </ContentContainer>
      </Container>
    </QBOLayoutPage>
  );
}

const QBLogo = styled.img`
  width: 15rem;
  align-self: center;
  margin-bottom: 4rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white.opaque};
  color: ${(props) => props.theme.text.color.main};
  padding: 4rem 3.8rem;
  border: solid 1px ${(props) => props.theme.colors.border.lightGrey};
  border-radius: 0.6rem;
`;

const QuestionMarkIconContainer = styled.img`
  padding: 2rem;
`;

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
`;

const TitleContainer = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  font-size: ${(props) => props.theme.text.size.subNav};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  text-align: center;
`;
const BodyContainer = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  text-align: center;
`;

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
  font-weight: ${(props) => props.theme.text.weight.normal};
`;

const ChatLink = styled(MILink)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
  font-weight: ${(props) => props.theme.text.weight.normal};
`;
