import React from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { ReactComponent as Mastercard } from 'src/app/images/icons/mastercard.svg';
import { VirtualCardType, DeliveryMethodType, PaymentType } from 'src/app/utils/types';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { InfoLayout, ReviewGridItem } from '../components';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  payment?: PaymentType;
  deliveryMethod?: DeliveryMethodType;
  virtualCard?: VirtualCardType;
};

const ProcessedLayout = ({ payment, deliveryMethod, virtualCard }: Props) => {
  useStateChangedAnalytics({ payment, paymentState: VirtualCardPaymentState.Processed });

  const virtualCardDeliveryMethod: DeliveryMethodType = useSelector(
    deliveryMethodsStore.selectors.byId(payment?.originalDeliveryMethodId)
  );

  const card4digits =
    virtualCard?.accountNumber4digits ||
    (virtualCard?.cardNumber && virtualCard.cardNumber.slice(virtualCard.cardNumber.length - 4));

  const processedDate =
    virtualCard?.clearedDate || virtualCard?.postedDate || virtualCard?.authorizationDate;

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <InfoLayout.Icon type={InfoLayout.IconType.Check} />
      <InfoLayout.Title>
        <MIFormattedText label="vendors.virtualCardDetails.processed.title" />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label="vendors.virtualCardDetails.processed.subtitle"
          values={{ date: <MIFormattedDate date={processedDate} /> }}
        />
      </InfoLayout.Subtitle>
      <InfoLayout.Separator />
      <InfoLayout.SectionTitle>
        <MIFormattedText label="vendors.virtualCardDetails.processed.reviewSectionTitle" />
      </InfoLayout.SectionTitle>
      <InfoLayout.Grid>
        <ReviewGridItem
          image={<Mastercard width="3.2rem" height="3.2rem" />}
          label={<MIFormattedText label="vendors.virtualCardDetails.common.cardTo" />}
          value={
            <MIFormattedText
              label="vendors.virtualCardDetails.common.cardData"
              values={{
                email:
                  deliveryMethod?.virtualCardAccount?.accountEmail ||
                  virtualCardDeliveryMethod.virtualCardAccount?.accountEmail,
                card4digits,
              }}
            />
          }
        />
        <ReviewGridItem
          icon="icon-eta-cal"
          label={<MIFormattedText label="vendors.virtualCardDetails.common.processedOn" />}
          value={<MIFormattedDate date={processedDate} />}
        />
      </InfoLayout.Grid>
    </InfoLayout>
  );
};

export { ProcessedLayout };
