import { Option } from '@melio/billpay-design-system';
import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import { billListModifiedType } from 'src/app/version-2/externals';
import { VERSION_2 } from 'src/app/version-2/model/constants';
import { Bill, FundingSource, IntuitBillCategory, Payment } from 'src/app/version-2/model/dtos';
import { DeliveryOption } from 'src/app/version-2/model/dtos/deliveryOption';
import { UploadedFileInfo } from 'src/app/version-2/model/dtos/UploadedFileInfo';
import { CardTypeEnum, DeliveryEnum, FeeVariantsEnum } from 'src/app/version-2/model/enums';
import {
  batchBulkItemsAdapter,
  paymentIntentDTOToPaymentIntentItemAdapter,
} from 'src/app/version-2/pages/batch-bulk/adapters';
import { CreateBatchBulkPaymentResponse } from 'src/app/version-2/pages/batch-bulk/api/responses';
import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';
import {
  BatchBulkPaymentIntentItem,
  MccIndustryInterface,
  MccInterface,
  SelectedPaymentIntent,
  UploadInvoiceFile,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects/batchBulkItem';
import { DefaultMemo } from 'src/app/version-2/pages/batch-bulk/model/objects/defaultMemo';
import { breakMCCsToOptions } from 'src/app/version-2/pages/batch-bulk/utils/amexModalUtils';
import {
  buildPaymentIntentDTO,
  sortBatchList,
} from 'src/app/version-2/pages/batch-bulk/utils/batchList.utils';
import { modifyItemsCheckPromotions } from 'src/app/version-2/pages/batch-bulk/utils/fee.utils';
import { billListModifiedAdapter } from '../adapters/billListModified.adapter';

import { isBefore, isSameDay } from 'date-fns';
import { RecordOf } from 'immutable';

export const BATCH_BULK_SLICE = `${VERSION_2}batchBulk`;

export const selectState = (state: any) => state[BATCH_BULK_SLICE] as BatchBulkSlice;

export interface BatchBulkSlice {
  billIds: string[];
  isCombined: boolean;
  hasBeenCombinedByFeature: boolean;
  hasTriedToSubmit: boolean;
  isCombineSwitchDisabled: boolean;
  memoForVendorModalId?: string;
  billDetailsModalId?: string;
  addEmailModalId?: string;
  amexVerificationModalId?: string;
  internationalModalId?: string;
  paymentIntentsDTOs: EntityState<BatchBulkPaymentIntent>;
  paymentIntentsItems: EntityState<BatchBulkPaymentIntentItem>;
  billDTOs: EntityState<Bill>;
  maxAmounts: Record<string, number>;
  freeChecks?: number;
  checkFee?: number;
  allowedMccCodes?: { mccIndustries: Option[]; mccSubIndustries: MccIndustryInterface };
  showHelpGuide: boolean;
  showHelpGuideTooltip: boolean;
  showUncombinedGuide: boolean;
  paidPayments: Record<string, any>;
  isScheduleDisabled: boolean;
  billListModified: Record<string, RecordOf<billListModifiedType>>;
  exitUrl?: string;
  intuitBillCategories: IntuitBillCategory[];
  isLoading?: boolean;
  vendorIdToContactEmailMapping: Record<number, string>;
  billsDefaultMemo: EntityState<DefaultMemo>;
  invoiceFilesByBillId: EntityState<UploadInvoiceFile>;
  isUSHolidayNotificationEnabled?: boolean;
  shownBatchBulkFastACHExperiment?: string;
  isBulkPaymentMethod?: boolean;
  headerRepeatingPaymentMethod?: number;
  removedInternationalCount: number;
  isScheduledBlocked: boolean;
}

const paymentIntentsDTOsAdapter = createEntityAdapter<BatchBulkPaymentIntent>({
  selectId: (paymentIntentDTO) => paymentIntentDTO.id,
});

const paymentIntentsItemsAdapter = createEntityAdapter<BatchBulkPaymentIntentItem>({
  selectId: (paymentIntentItem) => paymentIntentItem.id,
});

const billDTOsAdapter = createEntityAdapter<Bill>({
  selectId: (billDTO) => billDTO.id,
});

const billDefaultMemoAdapter = createEntityAdapter<DefaultMemo>({
  selectId: (billMemo) => billMemo.id,
});

const invoiceFilesAdapter = createEntityAdapter<UploadInvoiceFile>({
  selectId: (invoice) => invoice.paymentIntentId,
});

export const getBatchBulkInitialState = (): BatchBulkSlice => ({
  paymentIntentsDTOs: paymentIntentsDTOsAdapter.getInitialState(),
  paymentIntentsItems: paymentIntentsItemsAdapter.getInitialState(),
  billDTOs: billDTOsAdapter.getInitialState(),
  billIds: [],
  isCombined: true,
  hasBeenCombinedByFeature: false,
  isCombineSwitchDisabled: false,
  isUSHolidayNotificationEnabled: undefined,
  hasTriedToSubmit: false,
  maxAmounts: {},
  freeChecks: 0,
  showHelpGuide: false,
  showHelpGuideTooltip: false,
  showUncombinedGuide: false,
  isScheduleDisabled: false,
  paidPayments: [],
  billListModified: {},
  intuitBillCategories: [],
  vendorIdToContactEmailMapping: {},
  billsDefaultMemo: billDefaultMemoAdapter.getInitialState(),
  invoiceFilesByBillId: invoiceFilesAdapter.getInitialState(),
  shownBatchBulkFastACHExperiment: undefined,
  isBulkPaymentMethod: false,
  headerRepeatingPaymentMethod: undefined,
  removedInternationalCount: 0,
  isScheduledBlocked: false,
});

// const selectState = (state: any): BatchBulkSlice => state[BATCH_BULK_SLICE];

export const batchBulkSlice = createSlice({
  name: BATCH_BULK_SLICE,
  initialState: getBatchBulkInitialState(),
  reducers: {
    setPaidPayments: (state, action: PayloadAction<Record<string, any>>) => {
      state.paidPayments = action.payload;
    },
    setIsCombined: (
      state,
      action: PayloadAction<{
        isCombined: boolean;
        partialPayments?: Record<string, number>;
      }>
    ) => {
      state.isCombined = action.payload.isCombined;
    },
    setHasBeenUncombinedByFeature: (state, action: PayloadAction<boolean>) => {
      state.hasBeenCombinedByFeature = action.payload;
    },
    setIsUSHolidayNotificationEnabled: (state, action: PayloadAction<boolean | undefined>) => {
      state.isUSHolidayNotificationEnabled = action.payload;
    },
    setHasTriedToSubmit: (state, action: PayloadAction<boolean>) => {
      state.hasTriedToSubmit = action.payload;
    },
    setIsCombineSwitchDisabled: (state, action: PayloadAction<boolean>) => {
      state.isCombineSwitchDisabled = action.payload;
    },
    setIsScheduleDisabled: (state, action: PayloadAction<boolean>) => {
      state.isScheduleDisabled = action.payload;
    },
    setMemoForVendorModalAsOpen: (state, action: PayloadAction<{ id: string }>) => {
      state.memoForVendorModalId = action.payload.id;
    },
    setMemoForVendorModalAsClosed: (state) => {
      state.memoForVendorModalId = undefined;
    },
    setBillDetailsModalAsOpen: (state, action: PayloadAction<{ id: string }>) => {
      state.billDetailsModalId = action.payload.id;
    },
    setBillDetailsModalAsClosed: (state) => {
      state.billDetailsModalId = undefined;
    },
    setAddEmailModalAsOpen: (state, action: PayloadAction<{ id: string }>) => {
      state.addEmailModalId = action.payload.id;
    },
    setAddEmailModalAsClosed: (state) => {
      state.addEmailModalId = undefined;
    },
    setAmexVerificationModalAsOpen: (state, action: PayloadAction<{ id: string }>) => {
      state.amexVerificationModalId = action.payload.id;
    },
    setAmexVerificationModalAsClosed: (state) => {
      state.amexVerificationModalId = undefined;
    },
    setInternationalModalAsOpen: (state, action: PayloadAction<{ id: string }>) => {
      state.internationalModalId = action.payload.id;
    },
    setInternationalModalAsClosed: (state) => {
      state.internationalModalId = undefined;
    },
    setBillIds: (state, action: PayloadAction<string[]>) => {
      state.billIds = action.payload;
    },
    setFreeChecks: (state, action: PayloadAction<number | undefined>) => {
      state.freeChecks = action.payload;
    },
    setCheckFee: (state, action: PayloadAction<number | undefined>) => {
      state.checkFee = action.payload;
    },
    setAllowedMccCodes: (state, action: PayloadAction<MccInterface[] | undefined>) => {
      state.allowedMccCodes = action.payload && breakMCCsToOptions(action.payload);
    },
    setVendorIdToContactEmailMappingItem: (
      state,
      action: PayloadAction<{ vendorId: number; contactEmail: string }>
    ) => {
      state.vendorIdToContactEmailMapping[action.payload.vendorId] = action.payload.contactEmail;
    },
    // addPaymentIntentDTOSuccess: paymentIntentsDTOsAdapter.addOne
    deletePaymentIntentDTOSuccess: (state, action: PayloadAction<{ id: string }>) => {
      const deleted = paymentIntentsDTOsAdapter
        .getSelectors()
        .selectById(state.paymentIntentsDTOs, action.payload.id);
      const removedBillIds: unknown[] = [];

      // removing inner bills
      deleted?.payment.bills?.forEach((bill) => {
        removedBillIds.push(bill.id);
        billDTOsAdapter.removeOne(state.billDTOs, bill.id);
      });

      // removing intent / dto items
      paymentIntentsDTOsAdapter.removeOne(state.paymentIntentsDTOs, action.payload.id);
      paymentIntentsItemsAdapter.removeOne(
        state.paymentIntentsItems as unknown as EntityState<BatchBulkPaymentIntentItem>,
        action.payload.id
      );

      // removing specific bill ids from billIds (for re-entering page)
      state.billIds = state?.billIds?.filter((item) => !removedBillIds.includes(Number(item)));

      // removing specific bills from billListModified (for dashboard redirect usage)
      const newBillListModified = {};

      Object.keys(state.billListModified).forEach((id) => {
        if (removedBillIds.includes(Number(id))) return;

        newBillListModified[id] = state.billListModified[id];
      });

      state.billListModified = newBillListModified;
    },
    updatedPaymentIntentDTOSuccess: (
      state,
      action: PayloadAction<Update<BatchBulkPaymentIntent>>
    ) => {
      // const current = paymentIntentsDTOsAdapter.getSelectors().selectById(state.paymentIntentsDTOs, action.payload.id);

      paymentIntentsDTOsAdapter.updateOne(state.paymentIntentsDTOs, action.payload);
      const changedPaymentIntentDTO = paymentIntentsDTOsAdapter
        .getSelectors()
        .selectById(state.paymentIntentsDTOs, action.payload.id);
      const fundingSources = paymentIntentsItemsAdapter
        .getSelectors()
        .selectById(
          state.paymentIntentsItems as unknown as EntityState<BatchBulkPaymentIntentItem>,
          action.payload.id
        )?.paymentMethods;

      const paymentIntentDTO =
        changedPaymentIntentDTO &&
        buildPaymentIntentDTO({
          fundingSources,
          paymentIntentDTO: changedPaymentIntentDTO,
        });

      if (paymentIntentDTO) {
        paymentIntentsDTOsAdapter.setOne(state.paymentIntentsDTOs, paymentIntentDTO);
        paymentIntentsItemsAdapter.setOne(
          state.paymentIntentsItems as unknown as EntityState<BatchBulkPaymentIntentItem>,
          paymentIntentDTOToPaymentIntentItemAdapter(
            paymentIntentDTO,
            state.maxAmounts,
            fundingSources
          )
        );
      }

      paymentIntentDTO?.payment.bills?.forEach((bill) => {
        billDTOsAdapter.setOne(state.billDTOs, bill);
      });
      // TODO update all relevant entityAdapters - bills delta
    },
    fetchPaymentIntentDTOsSuccess: (
      state,
      action: PayloadAction<{
        batchList: BatchBulkPaymentIntent[];
        fundingSources: FundingSource[];
      }>
    ) => {
      paymentIntentsDTOsAdapter.setAll(state.paymentIntentsDTOs, action.payload.batchList);

      const { bills, batchBulkItems, maxAmounts, vendorIdToContactEmailMapping } =
        batchBulkItemsAdapter(action.payload.batchList, action.payload.fundingSources);

      state.billListModified = billListModifiedAdapter(action.payload.batchList);
      state.vendorIdToContactEmailMapping = vendorIdToContactEmailMapping;

      const sortedBatchList = sortBatchList(batchBulkItems);

      paymentIntentsItemsAdapter.setAll(
        state.paymentIntentsItems as unknown as EntityState<BatchBulkPaymentIntentItem>,
        sortedBatchList
      );
      billDTOsAdapter.setAll(state.billDTOs, bills);
      state.maxAmounts = maxAmounts;
    },
    paymentIntentInit: (state) => {
      paymentIntentsDTOsAdapter.setAll(state.paymentIntentsDTOs, []);

      const { bills, batchBulkItems } = batchBulkItemsAdapter([], []);

      state.billListModified = billListModifiedAdapter([]);
      paymentIntentsItemsAdapter.setAll(
        state.paymentIntentsItems as unknown as EntityState<BatchBulkPaymentIntentItem>,
        batchBulkItems
      );
      billDTOsAdapter.setAll(state.billDTOs, bills);
    },
    billsDefaultMemoInit: (state) => {
      billDefaultMemoAdapter.setAll(state.billsDefaultMemo, []);
    },
    fetchPaymentIntentDTOsFail: (state) => {
      paymentIntentsDTOsAdapter.removeAll(state.paymentIntentsDTOs);
      paymentIntentsItemsAdapter.removeAll(
        state.paymentIntentsItems as unknown as EntityState<BatchBulkPaymentIntentItem>
      );
      billDTOsAdapter.removeAll(state.billDTOs);
    },
    setShowHelpGuide: (state, action: PayloadAction<boolean>) => {
      state.showHelpGuide = action.payload;
    },
    setShowHelpGuideTooltip: (state, action: PayloadAction<boolean>) => {
      state.showHelpGuideTooltip = action.payload;
    },
    setShowUncombinedGuide: (state, action: PayloadAction<boolean>) => {
      state.showUncombinedGuide = action.payload;
    },
    setExitUrl: (state, action: PayloadAction<{ exitUrl: string }>) => {
      state.exitUrl = action.payload.exitUrl;
    },
    setIntuitCategories: (
      state,
      action: PayloadAction<{ intuitBillCategories: IntuitBillCategory[] }>
    ) => {
      state.intuitBillCategories = action.payload?.intuitBillCategories;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setBillsDefaultMemo: (state, action: PayloadAction<DefaultMemo>) => {
      billDefaultMemoAdapter.setOne(state.billsDefaultMemo, action.payload);
    },
    setInvoiceFile: (state, action: PayloadAction<UploadInvoiceFile>) => {
      invoiceFilesAdapter.setOne(state.invoiceFilesByBillId, action.payload);
    },
    setInvoiceFilesBatch: (state, action: PayloadAction<UploadInvoiceFile[]>) => {
      invoiceFilesAdapter.setAll(state.invoiceFilesByBillId, action.payload);
    },
    setShownBatchBulkFastACHExperiment: (state, action) => {
      state.shownBatchBulkFastACHExperiment = action.payload;
    },
    setHeaderRepeatingPaymentMethod: (state, action) => {
      state.headerRepeatingPaymentMethod = action.payload;
    },
    clearHeaderRepeatingPaymentMethod: (state) => {
      state.headerRepeatingPaymentMethod = undefined;
    },
    setIsBulkPaymentMethod: (state, action) => {
      state.isBulkPaymentMethod = action.payload;
    },
    setRemovedInternationalCount: (state, action) => {
      state.removedInternationalCount = action.payload;
    },
    setIsScheduledBlocked: (state, action) => {
      state.isScheduledBlocked = action.payload;
    },
  },
});

export const batchBulkActions = {
  ...batchBulkSlice.actions,
  fetchBatchBulks: createAction<{
    preservedState: {
      batchList?: BatchBulkPaymentIntent[];
      selectedPaymentIntent: SelectedPaymentIntent;
    };
    partialPayments?: Record<string, number>;
    analyticsEventProps: { [key: string]: string | string[] };
  }>(`${BATCH_BULK_SLICE}/fetch`), // TODO: add correct type
  createBatchBulkPayment: createAction<{ history: any }>(`${BATCH_BULK_SLICE}/create`),
  updateVendorEmail: createAction<{ contactEmail: string; paymentIntentId: string }>(
    `${BATCH_BULK_SLICE}/vendor/update`
  ),
  updateVendorMccCode: createAction<{ vendorId: string; mccCode: string; paymentIntentId: string }>(
    `${BATCH_BULK_SLICE}/vendor/mccCode`
  ),
  updateVendorEmailSuccess: createAction<{ contactEmail: string; vendorId: number }>(
    `${BATCH_BULK_SLICE}/vendor/update/success`
  ),
  updateDeductionDate: createAction<{
    date?: Date;
    paymentIntentId: string;
    amount?: number;
    isByDueDate?: boolean;
  }>(`${BATCH_BULK_SLICE}/deductionDate/update`),
  updateAmount: createAction<{ amount: number; billId: string; paymentIntentId: string }>(
    `${BATCH_BULK_SLICE}/amount/update`
  ),
  createFundingSource: createAction<{ selectedPaymentIntent: SelectedPaymentIntent; history: any }>(
    `${BATCH_BULK_SLICE}/paymentMethods/create`
  ),
  updateFundingSource: createAction<{
    selectedPaymentIntent: SelectedPaymentIntent;
    bulk?: boolean;
  }>(`${BATCH_BULK_SLICE}/paymentMethods/update`),
  updateFundingSourceAfterDelete: createAction<{
    fundingSourceId: number;
  }>(`${BATCH_BULK_SLICE}/paymentMethods/updateAfterDelete`),
  updateBulkPaymentMethods: createAction<{
    batchBulks: BatchBulkPaymentIntentItem[];
    fundingSourceId: number;
  }>(`${BATCH_BULK_SLICE}/paymentMethods/bulk-update`),
  updateBulkDeductionDate: createAction<{ type: string; date?: Date | null }>(
    `${BATCH_BULK_SLICE}/deductionDate/bulk-update`
  ),
  createDeliveryMethod: createAction<{
    selectedPaymentIntent: SelectedPaymentIntent;
    history: any;
  }>(`${BATCH_BULK_SLICE}/deliveryMethods/create`),
  updateDeliveryMethod: createAction<{
    selectedPaymentIntent: SelectedPaymentIntent;
    isNewDeliveryMethod?: boolean;
  }>(`${BATCH_BULK_SLICE}/deliveryMethod/update`),
  fetchShowHelpGuide: createAction(`${BATCH_BULK_SLICE}/show-help-guide/fetch`),
  updateShowHelpGuideUI: createAction<{ key: string; value: boolean }>(
    `${BATCH_BULK_SLICE}/show-help-guide/ui/update`
  ),
  updateShowHelpGuideApi: createAction<{ key: string; value: boolean }>(
    `${BATCH_BULK_SLICE}/show-help-guide/api/update`
  ),
  setPaymentDatesAction: createAction<{
    billId: string;
    deliveryEta: string;
    deliveryPreference: string | null;
  }>(`${BATCH_BULK_SLICE}/paymentDates/update`),
  trackCreateBatchBulkPaymentSuccess: createAction<CreateBatchBulkPaymentResponse>(
    `${BATCH_BULK_SLICE}/paymentMethods/track-create-success`
  ),
  goToSettingsPage: createAction<{ history: any }>(`${BATCH_BULK_SLICE}/go-to-settings-page`),
  updateCheckPaymentScheduledNotificationToVendor: createAction(
    `${BATCH_BULK_SLICE}/payments/edit-check-address`
  ),
  fetchDefaultMemo: createAction<{ paymentIntentId: string; translations: Record<string, string> }>(
    `${BATCH_BULK_SLICE}/payments/fetch-default-memo`
  ),
  updateInvoiceFile: createAction<{
    paymentIntentId: string;
    invoiceFile: UploadedFileInfo;
  }>(`${BATCH_BULK_SLICE}/update-invoice-file`),
  refetchBatchBulksByPaymentMethod: createAction<{ fundingSourceId: number }>(
    `${BATCH_BULK_SLICE}/refetch-batch-bulks-by-payment-method`
  ),
  redirectToDashboard: createAction<{ history: any; payments: Payment[] }>(
    `${BATCH_BULK_SLICE}/redirect-to-dashboard`
  ),
  updateDeliveryOption: createAction<{
    paymentIntentId: string;
    deliveryOption: DeliveryOption;
    isFast: boolean;
    deliverySpeedLabel: boolean;
  }>(`${BATCH_BULK_SLICE}/deliveryOption/update`),
  updateShownBatchBulkFastACHExperiment: createAction<{ key: string; value: boolean }>(
    `${BATCH_BULK_SLICE}/shown-batch-bulk-fast-ACH-experiment/update`
  ),
  uploadInvoiceFile: createAction<{ billId: string; file: File }>(
    `${BATCH_BULK_SLICE}/uploadInvoiceFile`
  ),
  updateBillInvoice: createAction<{ billId: string }>(`${BATCH_BULK_SLICE}/updateBillInvoice`),
  removeUploadInvoiceFile: createAction<{ billId: string }>(
    `${BATCH_BULK_SLICE}/removeUploadInvoiceFile`
  ),
};

const selectPaymentIntentsDTOs = createSelector(selectState, (state) =>
  paymentIntentsDTOsAdapter.getSelectors().selectAll(state.paymentIntentsDTOs)
);
const selectPaymentIntentsPaymentMethodsItems = (paymentId) =>
  createSelector(selectState, (state) =>
    paymentIntentsItemsAdapter.getSelectors().selectById(state.paymentIntentsItems, paymentId)
  );
const selectPaymentIntentDTO = (id: string) =>
  createSelector(selectState, (state) =>
    paymentIntentsDTOsAdapter.getSelectors().selectById(state.paymentIntentsDTOs, id)
  );
const selectPaymentIntentDTOsByVendorId = (vendorId) =>
  createSelector(selectState, (state) =>
    paymentIntentsDTOsAdapter
      .getSelectors()
      .selectAll(state.paymentIntentsDTOs)
      .filter((paymentDTO) => paymentDTO.payment.vendorId === vendorId)
  );

const selectPaymentIntentItemsByVendorId = (vendorId) =>
  createSelector(selectState, (state) =>
    paymentIntentsItemsAdapter
      .getSelectors()
      .selectAll(state.paymentIntentsItems)
      .filter((paymentDTO) => paymentDTO.vendorId === vendorId)
  );
const selectIsCombined = createSelector(selectState, (state) => state.isCombined);
const selectHasBeenCombinedByFeature = createSelector(
  selectState,
  (state) => state.hasBeenCombinedByFeature
);
const selectIsUSHolidayNotificationEnabled = createSelector(
  selectState,
  (state) => state.isUSHolidayNotificationEnabled
);
const selectHasTriedToSubmit = createSelector(selectState, (state) => state.hasTriedToSubmit);
const selectIsCombineSwitchDisabled = createSelector(
  selectState,
  (state) => state.isCombineSwitchDisabled
);
const selectMemoForVendorModalBatchBulk = createSelector(
  selectState,
  (state) =>
    state.memoForVendorModalId &&
    paymentIntentsDTOsAdapter
      .getSelectors()
      .selectById(state.paymentIntentsDTOs, state.memoForVendorModalId)
);
const selectAddEmailModalBillDTO = createSelector(
  selectState,
  (state) =>
    state.addEmailModalId &&
    paymentIntentsDTOsAdapter
      .getSelectors()
      .selectById(state.paymentIntentsDTOs, state.addEmailModalId)
);
const selectAmexVerificationModalBillDTO = createSelector(
  selectState,
  (state) =>
    state.amexVerificationModalId &&
    paymentIntentsDTOsAdapter
      .getSelectors()
      .selectById(state.paymentIntentsDTOs, state.amexVerificationModalId)
);
const selectInternationalModalBillDTO = createSelector(
  selectState,
  (state) =>
    state.internationalModalId &&
    paymentIntentsDTOsAdapter
      .getSelectors()
      .selectById(state.paymentIntentsDTOs, state.internationalModalId)
);
const selectInternationalModalBillId = createSelector(
  selectState,
  (state) => state.internationalModalId
);
const selectAllowedMccCodes = createSelector(selectState, (state) => state.allowedMccCodes);
const selectBillDetailsModalBillDTO = createSelector(
  selectState,
  (state) =>
    !!state.billDetailsModalId &&
    billDTOsAdapter.getSelectors().selectById(state.billDTOs, state.billDetailsModalId)
);
const selectBillDTO = (id: string) =>
  createSelector(selectState, (state) =>
    billDTOsAdapter.getSelectors().selectById(state.billDTOs, id)
  );
const selectBillMaxAmount = (id: string) =>
  createSelector(selectState, (state) => state.maxAmounts[id]);
const selectIsMemoForVendorModalOpen = createSelector(
  selectState,
  (state) => !!state.memoForVendorModalId
);
const selectIsBillDetailsModalOpen = createSelector(
  selectState,
  (state) => !!state.billDetailsModalId
);
const selectIsAddEmailModalOpen = createSelector(selectState, (state) => !!state.addEmailModalId);
const selectIsAmexVerificationModalOpen = createSelector(
  selectState,
  (state) => !!state.amexVerificationModalId
);
const selectIsInternationalModalOpen = createSelector(
  selectState,
  (state) => !!state.internationalModalId
);
const selectIsValid = createSelector(selectState, (state) => {
  const paymentIntentItems = paymentIntentsItemsAdapter
    .getSelectors()
    .selectAll(state.paymentIntentsItems);

  const itemWithErrors = paymentIntentItems.find((paymentIntentItem) =>
    Boolean(paymentIntentItem?.errors?.length)
  );

  return !itemWithErrors;
});
const selectBillIds = createSelector(selectState, (state) => state.billIds);
const selectTotalAmount = createSelector(selectState, (state) =>
  Object.values(state.paymentIntentsItems.entities).reduce((acc, val: any) => acc + val.amount, 0)
);

const selectShowHelpGuide = createSelector(selectState, (state) => state.showHelpGuide);
const selectShowUncombinedGuide = createSelector(selectState, (state) => state.showUncombinedGuide);

const selectPaymentIntentsItems = createSelector(selectState, (state) => {
  const items = paymentIntentsItemsAdapter.getSelectors().selectAll(state.paymentIntentsItems);
  const { freeChecks, checkFee } = state;

  let paymentItems = items;

  // free checks modify (temp code)
  if (checkFee)
    paymentItems = modifyItemsCheckPromotions({
      items,
      freeChecks,
      checkFee,
    });

  return paymentItems;
});

const selectTotalCheckFee = createSelector(selectState, selectPaymentIntentsItems, (_a, entities) =>
  entities.reduce((acc, paymentIntent: BatchBulkItem) => {
    if (paymentIntent.feeType !== FeeVariantsEnum.ACH_TO_CHECK || paymentIntent.freeCheck)
      return acc;

    return acc + (paymentIntent.fee || 0);
  }, 0)
);

const selectTotalCardFees = createSelector(selectState, selectPaymentIntentsItems, (_a, entities) =>
  entities.reduce((acc, paymentIntent: BatchBulkItem) => {
    if (
      paymentIntent.feeType !== CardTypeEnum.CREDIT &&
      paymentIntent.feeType !== CardTypeEnum.DEBIT
    )
      return acc;

    return acc + (paymentIntent.fee || 0);
  }, 0)
);
const selectAdditionalFee = createSelector(selectState, selectPaymentIntentsItems, (_a, entities) =>
  entities.reduce((acc, paymentIntent: BatchBulkItem) => {
    const additionalFee =
      acc + (paymentIntent.fastFee || 0) + (paymentIntent.internationalFee || 0);

    return additionalFee;
  }, 0)
);

const selectTotalFee = createSelector(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  selectState,
  selectTotalCheckFee,
  selectTotalCardFees,
  selectAdditionalFee,
  // eslint-disable-next-line max-params
  (_state, checkFees, cardFees, additionalFees) => checkFees + cardFees + additionalFees
);
const selectNumOfPaymentIntentsWithErrors = createSelector(
  selectState,
  selectPaymentIntentsItems,
  (_state, intentItems) =>
    intentItems.filter((intentItem) => Number(intentItem?.errors?.length)).length
);
const selectEligiblePaymentIntentsForFastAch = createSelector(
  selectState,
  selectPaymentIntentsItems,
  (_state, paymentIntentItems) =>
    paymentIntentItems
      .filter((paymentIntentItem) => {
        const { selectedPaymentMethod, selectedDeliveryMethod, deliverySpeedEnabled } =
          paymentIntentItem;
        const deliveryType: string = selectedDeliveryMethod?.deliveryType ?? '';

        return (
          selectedPaymentMethod?.id &&
          selectedDeliveryMethod?.id &&
          deliveryType === DeliveryEnum.ACH &&
          deliverySpeedEnabled
        );
      })
      .map((paymentIntentItem) => paymentIntentItem?.id)
);

const selectEligiblePaymentIntentsForExpressCheck = createSelector(
  selectState,
  selectPaymentIntentsItems,
  (_state, paymentIntentItems) =>
    paymentIntentItems
      .filter((paymentIntentItem) => {
        const { selectedPaymentMethod, selectedDeliveryMethod, deliverySpeedEnabled } =
          paymentIntentItem;
        const deliveryType: string = selectedDeliveryMethod?.deliveryType ?? '';

        return (
          selectedPaymentMethod?.id &&
          selectedDeliveryMethod?.id &&
          deliveryType === DeliveryEnum.CHECK &&
          deliverySpeedEnabled
        );
      })
      .map((paymentIntentItem) => paymentIntentItem?.id)
);

const selectVendorIds = createSelector(
  selectState,
  selectPaymentIntentsDTOs,
  (_state, paymentIntents) => [
    ...new Set(paymentIntents.map((paymentIntent) => paymentIntent.payment.vendorId)),
  ]
);

const selectPaymentIntentItemIdsWithoutDeliveryMethod = createSelector(
  selectState,
  selectPaymentIntentsItems,
  (_state, paymentIntentItems) =>
    paymentIntentItems
      .filter((paymentIntentItem) => !paymentIntentItem.selectedDeliveryMethod)
      .map((intentItem) => intentItem.id)
);

const selectPartialBillIdsOfPayment = (paymentId: string) =>
  createSelector(selectState, selectPaymentIntentDTO(paymentId), (state, paymentIntent) => {
    const bills = paymentIntent?.payment?.bills || [];

    return bills
      .filter((bill) => (bill.balance || 0) < state.maxAmounts[bill.id])
      .map((bill) => bill.id);
  });
const selectAllPartialBillIds = createSelector(selectState, (state) => {
  const bills = billDTOsAdapter.getSelectors().selectAll(state.billDTOs);

  bills.filter((bill) => (bill.balance || 0) < state.maxAmounts[bill.id]).map((bill) => bill.id);
});

const selectShowHelpGuideTooltip = createSelector(
  selectState,
  (state) => state.showHelpGuideTooltip
);

const selectPaidPayments = createSelector(selectState, (state) => state.paidPayments);
const isScheduleDisabled = createSelector(selectState, (state) => !!state.isScheduleDisabled);
const selectIsScheduledBlocked = createSelector(selectState, (state) => state.isScheduledBlocked);

const selectBillListModified = createSelector(selectState, (state) => state.billListModified);

const selectExitUrl = createSelector(selectState, (state) => state.exitUrl);

const selectVendorIdToContactEmailMapping = createSelector(
  selectState,
  (state) => state.vendorIdToContactEmailMapping
);

const selectIntuitBillCategory = (categoryId) =>
  createSelector(
    selectState,
    (state) => state.intuitBillCategories.find((cat) => cat.Id === categoryId)?.Name
  );
const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectPaidPaymentsToScheduleVendorNotification = createSelector(
  selectState,
  selectPaidPayments,
  selectVendorIdToContactEmailMapping,
  (_state, paidPayments, vendorIdToContactEmailMapping) => {
    const now = new Date();
    const validateRequiredParams = (payment) => {
      const isScheduledDateTodayOrAfterToday =
        isSameDay(now, new Date(payment?.scheduledDate)) ||
        isBefore(now, new Date(payment?.scheduledDate));
      const hasContactEmail = vendorIdToContactEmailMapping[payment.vendorId];

      return (
        hasContactEmail &&
        payment?.deliveryMethod?.deliveryType === DeliveryEnum.CHECK &&
        isScheduledDateTodayOrAfterToday &&
        payment?.id &&
        payment?.organizationId
      );
    };
    const payments = paidPayments?.filter((payment) => validateRequiredParams(payment));

    return payments.map((payment) => ({
      orgId: payment?.organizationId?.toString(),
      paymentId: payment?.id?.toString(),
    }));
  }
);

const selectBillIdsArrFromBillDTOs = createSelector(selectState, (state) =>
  billDTOsAdapter.getSelectors().selectIds(state.billDTOs)
);

const selectHasCheckDeliveryMethod = createSelector(
  selectState,
  selectPaymentIntentsItems,
  (_a, entities: BatchBulkItem[]) =>
    entities.some((entity) => entity?.selectedDeliveryMethod?.deliveryType === DeliveryEnum.CHECK)
);

const selectBillDefaultMemo = (id: string) =>
  createSelector(selectState, (state) =>
    billDefaultMemoAdapter.getSelectors().selectById(state.billsDefaultMemo, id)
  );

const selectInternationalBills = createSelector(
  selectState,
  selectPaymentIntentsItems,
  (_state, entities) =>
    entities.filter((bill) =>
      bill.selectedDeliveryMethod?.deliveryType?.includes(DeliveryEnum.INTERNATIONAL)
    )
);

const selectInvoiceByBillId = (id: string) =>
  createSelector(selectState, (state) => {
    const invoice = invoiceFilesAdapter.getSelectors().selectById(state.invoiceFilesByBillId, id);

    return invoice;
  });

const selectShownBatchBulkFastACHExperiment = createSelector(
  selectState,
  (state) => state.shownBatchBulkFastACHExperiment
);
const selectHeaderRepeatingPaymentMethod = createSelector(
  selectState,
  (state) => state.headerRepeatingPaymentMethod
);
const selectRemovedInternationalCount = createSelector(
  selectState,
  (state) => state.removedInternationalCount
);

const selectIsBulkPaymentMethod = createSelector(selectState, (state) => state.isBulkPaymentMethod);

const selectRepeatingFundingSource = createSelector(selectState, (state) => {
  const items = paymentIntentsItemsAdapter.getSelectors().selectAll(state.paymentIntentsItems);
  const fundingSource = items[0]?.selectedPaymentMethod;
  const hasUniqueFundingSource = items?.every(
    (item) => item?.selectedPaymentMethod?.id === fundingSource?.id
  );

  return hasUniqueFundingSource ? fundingSource : false;
});

const selectInitDeductionDates = createSelector(
  selectState,
  selectBillListModified,
  selectRepeatingFundingSource,
  (state, billListModified, repeatingFundingSource) => {
    if (!repeatingFundingSource) return false;

    const items = paymentIntentsItemsAdapter.getSelectors().selectAll(state.paymentIntentsItems);

    const hasInitDeductionDates = items?.every((item) => {
      const billId = item.totalBillIds?.[0];
      const billModified = billListModified?.[billId];
      const initScheduledDate = billModified?.deliveryOptions[0]?.scheduledDate;

      return isSameDay(new Date(initScheduledDate as Date), new Date(item?.deductionDate as Date));
    });

    return hasInitDeductionDates;
  }
);

const selectHasSameDates = createSelector(
  selectState,
  selectRepeatingFundingSource,
  (state, repeatingFundingSource) => {
    if (!repeatingFundingSource) return false;

    const items = paymentIntentsItemsAdapter.getSelectors().selectAll(state.paymentIntentsItems);
    const { deductionDate } = items[0] || {};

    const hasInitDeductionDates = items?.every((item) =>
      isSameDay(new Date(deductionDate as Date), new Date(item?.deductionDate as Date))
    );

    return hasInitDeductionDates;
  }
);

const selectDeliveryMethods = createSelector(selectState, (state) => {
  const deliveryMethods: DeliveryEnum[] = [];

  const paymentIntentItems = paymentIntentsItemsAdapter
    .getSelectors()
    .selectAll(state.paymentIntentsItems);

  paymentIntentItems.forEach((intentItem) => {
    const type: DeliveryEnum | undefined = intentItem?.selectedDeliveryMethod?.deliveryType;

    if (type && !deliveryMethods.includes(type)) deliveryMethods.push(type);
  });

  return deliveryMethods;
});

export const batchBulkSelectors = {
  selectPaymentIntentsDTOs,
  selectPaymentIntentsPaymentMethodsItems,
  selectPaymentIntentDTO,
  selectPaymentIntentDTOsByVendorId,
  selectPaymentIntentItemsByVendorId,
  selectIsCombined,
  selectHasBeenCombinedByFeature,
  selectHasTriedToSubmit,
  selectIsCombineSwitchDisabled,
  selectMemoForVendorModalBatchBulk,
  selectAddEmailModalBillDTO,
  selectAmexVerificationModalBillDTO,
  selectInternationalModalBillDTO,
  selectInternationalModalBillId,
  selectAllowedMccCodes,
  selectBillDetailsModalBillDTO,
  selectBillDTO,
  selectBillMaxAmount,
  selectIsMemoForVendorModalOpen,
  selectIsBillDetailsModalOpen,
  selectIsAddEmailModalOpen,
  selectIsAmexVerificationModalOpen,
  selectIsInternationalModalOpen,
  selectIsValid,
  selectBillIds,
  selectTotalAmount,
  selectPaymentIntentsItems,
  selectTotalCheckFee,
  selectTotalCardFees,
  selectAdditionalFee,
  selectTotalFee,
  selectShowHelpGuide,
  selectShowUncombinedGuide,
  selectNumOfPaymentIntentsWithErrors,
  selectVendorIds,
  selectPaymentIntentItemIdsWithoutDeliveryMethod,
  selectPartialBillIdsOfPayment,
  selectAllPartialBillIds,
  selectEligiblePaymentIntentsForFastAch,
  selectEligiblePaymentIntentsForExpressCheck,
  selectShowHelpGuideTooltip,
  selectPaidPayments,
  isScheduleDisabled,
  selectBillListModified,
  selectExitUrl,
  selectIntuitBillCategory,
  selectIsLoading,
  selectPaidPaymentsToScheduleVendorNotification,
  selectVendorIdToContactEmailMapping,
  selectBillIdsArrFromBillDTOs,
  selectIsUSHolidayNotificationEnabled,
  selectHasCheckDeliveryMethod,
  selectBillDefaultMemo,
  selectInternationalBills,
  selectShownBatchBulkFastACHExperiment,
  selectIsBulkPaymentMethod,
  selectRepeatingFundingSource,
  selectInitDeductionDates,
  selectHasSameDates,
  selectHeaderRepeatingPaymentMethod,
  selectDeliveryMethods,
  selectRemovedInternationalCount,
  selectIsScheduledBlocked,
  selectInvoiceByBillId,
};
