import { useSelector } from 'react-redux';
import { getProfile } from 'src/app/redux/user/selectors';
export const isEmailEligibleForInternational = (userEmail: string) => {
  const excludedDomains = ['melio.com', 'meliopayments.com'];
  const forbiddenSigns = ["'", '-', '_', '=', ',', '+'];
  const [emailAddress, domain] = userEmail.split('@');

  if (excludedDomains.includes(domain)) {
    return true;
  }

  return !forbiddenSigns.some((sign) => emailAddress.includes(sign));
};

export const useIsInternationalOn = () => {
  const profile = useSelector(getProfile);

  return isEmailEligibleForInternational(profile.email);
};
