import {
  createIntl,
  createIntlCache,
  injectIntl,
  IntlConfig,
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  RawIntlProvider,
  UnsupportedFormatterError,
} from 'react-intl';
import merge from 'lodash/merge';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { loggingApi } from 'src/app/version-2/api/loggers';

export function addFlattenedValues(values: any, prefix: string, obj: any) {
  if (!isObject(obj)) {
    values[prefix] = obj;
  } else {
    if (isArray(obj)) {
      values[`${prefix}_length`] = obj.length;
    }

    Object.keys(obj).forEach((key) => {
      addFlattenedValues(values, `${prefix}_${key}`, obj[key]);
    });
  }

  return values;
}

export function createTranslationService(options: IntlConfig, defaults: any = {}) {
  const cache = createIntlCache();
  const intl = createIntl(
    merge(
      {
        formats: {
          number: {
            currency: {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            },
            percent: {
              style: 'percent',
              maximumFractionDigits: 2,
            },
          },
        },
        wrapRichTextChunksInFragment: true,
        onError: (
          error:
            | MissingTranslationError
            | MessageFormatError
            | MissingDataError
            | InvalidConfigError
            | UnsupportedFormatterError
        ) => {
          loggingApi.error(`translation-service.createTranslationService(): ${error?.code} error`, {
            error,
          });
        },
      },
      options
    ),
    cache
  );
  const originalFormatMessage = intl.formatMessage;
  const defaultValues = defaults;

  (intl as any).setDefaultValue = function (key, value) {
    addFlattenedValues(defaultValues, key, value);
  };
  intl.formatMessage = function (descriptor, values) {
    const newValues =
      values && Object.keys(values).length > 0 ? { ...defaultValues, ...values } : defaultValues;

    return originalFormatMessage(descriptor, newValues);
  };

  return intl;
}

export { injectIntl as withIntl };
export { RawIntlProvider as TranslationProvider };
