import React, { useCallback } from 'react';
import Box from 'src/app/core/ds/box/Box';
import { QBO_FONT_WEIGHT } from 'src/app/design-system/theme/foundations/qboTextStyles';
import { MIFormattedText } from 'src/app/utils/formatting';
import styled from 'styled-components';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import MIButton from 'src/app/components/common/MIButton';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS, IGNORE_OUTSIDE_CLICK_CLASS } from 'src/app/utils/consts';
import { useBreak } from 'src/app/hoc';
import { Icon } from '@melio/billpay-design-system';
import { theme } from 'src/app/design-system';
import { ReactComponent as CheckedIcon } from 'src/app/images/qbo/international/check-mark-icon.svg';
import { ReactComponent as LearnMoreIllustration } from 'src/app/images/qbo/international/learn-more.svg';

type ModalProps = {
  dismiss: () => any;
  confirm: () => any;
  close: () => any;
  analyticsProperties?: Record<string, any>;
};

export const LearnMoreModal = ({ dismiss, analyticsProperties, close, confirm }: ModalProps) => {
  const { isMobile } = useBreak();

  const infos = [
    'internationalPayment.learnMoreModal.directlySent',
    'internationalPayment.learnMoreModal.noSignUp',
    'internationalPayment.learnMoreModal.flatFee',
  ];

  const onClose = useCallback(() => {
    close();
    dismiss();
  }, []);

  const onConfirm = useCallback(() => {
    confirm();
    dismiss();
  }, []);

  return (
    <ModalMessage
      data-testid="learn-more-modal"
      className={IGNORE_OUTSIDE_CLICK_CLASS}
      onCloseClick={onClose}
      buttonComponent={
        <ButtonContainer>
          <MIButton
            onClick={onConfirm}
            label="internationalPayment.learnMoreModal.button"
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
            analyticsProperties={analyticsProperties}
            fullWidth={isMobile}
          />
        </ButtonContainer>
      }
      contentComponent={
        <>
          <LearnMoreIllustration width="100%" />
          <Content>
            <Box textStyle="h5" fontWeight={QBO_FONT_WEIGHT.DEMI}>
              <MIFormattedText label="internationalPayment.learnMoreModal.title" />
            </Box>
            <Box textStyle="body2">
              <MIFormattedText label="internationalPayment.learnMoreModal.easilyPay" />
            </Box>
            {infos.map((info) => (
              <Item key={info}>
                <Icon as={CheckedIcon} w="2rem" />
                <MIFormattedText label={info} />
              </Item>
            ))}
          </Content>
        </>
      }
    />
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: space-between;
  gap: 1.6rem;
  padding: 4rem 0px;
  border-bottom: 0.1rem solid ${theme.colors.gray[400]};
  @media ${devices.mobile} {
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 1.1rem;
`;
