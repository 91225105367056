import { useIntl } from 'react-intl';
import { Box, Flex, Heading } from '@melio/billpay-design-system';
import { CURRENCY_SIGN, PaymentType } from 'src/app/version-2/externals';

type Props = {
  payment: PaymentType;
};

const Header = ({ payment }: Props) => {
  const billPaymentsLength = payment?.billPayments?.length || 0;
  const isBulkPayments = billPaymentsLength > 1;

  const hasInfoiceOrBulk = !!(payment.bill?.invoiceNumber || isBulkPayments);
  const hasNoteOrInvoiceOrBulkNumber = !!(hasInfoiceOrBulk || payment.note);

  const { formatMessage } = useIntl();

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      textStyle="body3"
      color="black"
      textAlign="center"
      wordBreak="break-word"
      data-testid="vendor-payment-details-header-container"
    >
      <Heading
        as="h2"
        margin={0}
        textStyle="body2"
        fontWeight="ds.semi"
        data-testid="vendor-payment-details-header-title"
      >
        {payment.organization.companyName}
      </Heading>
      <Box
        margin={0}
        textStyle="body3"
        color="gray.500"
        data-testid="vendor-payment-details-header-subtitle"
      >
        {formatMessage({ id: 'vendors.vendorPaymentDetails.header.subtitle' })}
      </Box>

      <Box
        textStyle="h3"
        fontWeight="ds.semi"
        margin={`1rem 0 ${hasNoteOrInvoiceOrBulkNumber ? '' : '0'}`}
        data-testid="vendor-payment-details-header-amount"
      >
        <Heading textStyle="h3" fontWeight="ds.semi" color="black">
          {CURRENCY_SIGN.USD}
          {payment.amount ? payment.amount.toFixed(2) : '0.00'}
        </Heading>
      </Box>
      <Flex direction="column" gridGap="1rem" justify="center" align="center">
        {hasInfoiceOrBulk && (
          <Flex
            direction="column"
            justify="center"
            align="center"
            data-testid="vendor-payment-details-header-invoice"
          >
            <Box margin={0} textStyle="body3" color="gray.500">
              {formatMessage({ id: 'vendors.vendorPaymentDetails.header.forInvoice' })}
            </Box>

            {isBulkPayments
              ? formatMessage(
                  { id: 'vendors.vendorPaymentDetails.header.invoices' },
                  { invoices: billPaymentsLength }
                )
              : payment.bill?.invoiceNumber}
          </Flex>
        )}

        {payment.note && (
          <Flex
            direction="column"
            justify="center"
            align="center"
            data-testid="vendor-payment-details-header-included-note"
          >
            <Box margin={0} textStyle="body3" color="gray.500">
              {formatMessage({ id: 'vendors.vendorPaymentDetails.header.includedNote' })}
            </Box>
            {payment.note}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export { Header };
