import { put, all, call, select, getContext } from 'redux-saga/effects';
import { getPayBillFlowUUID } from 'src/app/services/analytics/trackPayBillFlow';
import paymentApi from 'src/app/services/api/payments';
import { PaymentRecord } from 'src/app/pages/payment/records';
import { FAILED_PAYMENT_TYPE } from 'src/app/utils/consts';
import { getCreateOrigin } from 'src/app/redux/utils';
import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import { PAY_BILL_EVENT_PAGE } from 'src/app/pages/bill/pay/consts';
import { getConfirmDeliveryMethod } from 'src/app/version-2/utils/deliveryMethod.utils';
import { DeliveryEnum } from 'src/app/version-2/model/enums';

import type { RetryFailedPaymentType } from '../types';
import { retryFailedPaymentSuccessAction, retryFailedPaymentErrorAction } from '../actions';
import { getBill, getPayment } from '../selectors';
import { getOrgId } from '../../user/selectors';

export function* retryFailedPaymentHandler({ resolve, reject }: RetryFailedPaymentType) {
  const [payment, orgId, bill] = yield all([select(getPayment), select(getOrgId), select(getBill)]);
  const payBillFlowUUID = getPayBillFlowUUID();
  const site = yield getContext('site');
  const siteName = site.name;
  const createOrigin = getCreateOrigin(siteName);
  const partialBillId = getPartialBillId(bill);
  const deliveryMethod = getConfirmDeliveryMethod({
    bill: bill as any,
    payment,
    type: DeliveryEnum.ACH,
  });
  const paymentData = {
    ...payment.toObject(),
    createOrigin,
    payBillFlowUUID,
    deliveryMethodId: deliveryMethod?.id,
    deliveryMethod,
  };

  const props = {
    organizationId: orgId,
    billId: parseInt(partialBillId),
    paymentId: payment.id,
    fundingSourceId: deliveryMethod?.id,
  };

  analytics.track(PAY_BILL_EVENT_PAGE, 'reschedule-confirm', {
    ...props,
    partialBillId: parseInt(partialBillId),
  });

  try {
    let paymentObj;

    if (payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER) {
      paymentObj = yield call(paymentApi.retryFailedToDeliver, orgId, paymentData);
    } else {
      paymentObj = yield call(paymentApi.retryFailedToCollect, orgId, paymentData);
    }

    analytics.track(PAY_BILL_EVENT_PAGE, 'reschedule-confirm-success', {
      ...props,
      partialBillId: parseInt(partialBillId),
    });
    analytics.trackMqlEvent('reschedule-payment', 'mql', props);

    yield put(retryFailedPaymentSuccessAction(PaymentRecord(paymentObj.payment)));
    resolve();
  } catch (e: any) {
    analytics.track(PAY_BILL_EVENT_PAGE, 'confirm-failed', { partialBillId });
    yield put(retryFailedPaymentErrorAction(e.code));
    reject(e);
  }
}
