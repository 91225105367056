import { put, all, call, select } from 'redux-saga/effects';
import { HANDLED_SYNC_PAYMENT_ERROR_CODES } from 'src/app/version-2/model/constants/syncPaymentErrorCode.consts';
import { KybStatusEnum } from 'src/app/version-2/model/enums';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { getPayBillFlowUUID } from 'src/app/services/analytics/trackPayBillFlow';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import { buildCreatePaymentApiRequest } from 'src/app/utils/payments';
import paymentApi from 'src/app/services/api/payments';
import organizationApi from 'src/app/services/api/organizations';
import { PaymentRecord } from 'src/app/pages/payment/records';
import analytics from 'src/app/services/analytics';
import { getPartialBillId } from 'src/app/utils/bills';
import { PAY_BILL_EVENT_PAGE } from 'src/app/pages/bill/pay/consts';

import type { CreatePaymentType } from '../types';
import {
  createPaymentSuccessAction,
  createPaymentErrorAction,
  setSyncPaymentErrorCode,
} from '../actions';
import { getBill, getPayment, getSelectedFundingSource } from '../selectors';
import { getCompanyInfo, getOrgId, getProfile } from '../../user/selectors';
import { sendEmailToVendorOnPaymentScheduleHandler } from './sendEmailToVendorOnPaymentSchedule.saga';

export function* createPaymentHandler({ resolve, reject }: CreatePaymentType) {
  const [payment, bill, orgId, profile, selectedFundingSource, companyInfo] = yield all([
    select(getPayment),
    select(getBill),
    select(getOrgId),
    select(getProfile),
    select(getSelectedFundingSource),
    select(getCompanyInfo),
  ]);
  const payBillFlowUUID = getPayBillFlowUUID();
  const partialBillId = getPartialBillId(bill);
  const deliveryType = payment?.deliveryMethod?.deliveryType;

  analytics.track(PAY_BILL_EVENT_PAGE, 'confirm', {
    fundingSourceId: selectedFundingSource?.id,
    partialBillId,
  });

  try {
    let paymentObject;
    const isInternationalPayment = deliveryType === DELIVERY_TYPE.INTERNATIONAL;

    if (isInternationalPayment) {
      const request = buildCreatePaymentApiRequest({ payment, orgId, profile });

      paymentObject = yield call(paymentApi.paymentApiCreate, orgId, request);
    } else {
      const createPaymentResult = yield call(paymentApi.createPayment, orgId, {
        ...payment.toObject(),
      });

      paymentObject = createPaymentResult.object;
    }

    if (!paymentObject.payBillFlowUUID) {
      paymentObject.payBillFlowUUID = payBillFlowUUID;
    }

    const paymentId = paymentObject.id;

    try {
      const resSyncData = yield call(organizationApi.runPaymentSync, orgId, paymentId);

      paymentObject.originId = resSyncData?.payment?.originId;
    } catch (syncPaymentError) {
      const syncPaymentErrorCode = (syncPaymentError as any)?.code;

      loggingApi.error('createPaymentSaga.handler(): sync payment failed', syncPaymentErrorCode);

      if (HANDLED_SYNC_PAYMENT_ERROR_CODES.includes(syncPaymentErrorCode)) {
        yield put(setSyncPaymentErrorCode(syncPaymentErrorCode));
      } else {
        throw syncPaymentError;
      }
    }

    yield put(createPaymentSuccessAction(PaymentRecord(paymentObject)));

    const isPartial = (payment?.amount || 0) < bill.totalAmount;

    analytics.track(PAY_BILL_EVENT_PAGE, 'confirm-success', {
      partialBillId,
      billId: bill.id,
      isPartial,
    });

    analytics.trackMqlEvent('created-payment', 'mql');

    if (companyInfo.kybStatus === KybStatusEnum.ACCEPTED) {
      analytics.track(PAY_BILL_EVENT_PAGE, 'kybaccepted-confirm-success', { partialBillId });
    }

    yield call(sendEmailToVendorOnPaymentScheduleHandler, {
      deliveryType,
      paymentId,
      contactEmail: bill.vendor.contactEmail,
    });

    resolve();
  } catch (e: any) {
    analytics.track(PAY_BILL_EVENT_PAGE, 'confirm-failed', { partialBillId });
    yield put(createPaymentErrorAction(e.code));
    reject(e);
  }
}
