import React from 'react';
import { RecordOf } from 'immutable';
import { DELIVERY_TYPE, PAYMENT_STATUS } from 'src/app/utils/consts';
import { DeliveryMethodType, PaymentType, VirtualCardType } from 'src/app/utils/types';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { PaymentInfoHint } from 'src/app/components/layout/PaymentActivityElements';
import { isVirtualCardPaymentExpired, isPushToDebitPayment } from 'src/app/utils/payments';
import { PaymentDeliveryStatusEnum } from 'src/app/version-2/model/enums';

type Props = {
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  virtualCard?: VirtualCardType;
};

export const DeliveryMethodInfoHint = ({ payment, deliveryMethod, virtualCard }: Props) => {
  const isVirtualCardDeliveryMethod = deliveryMethod.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;
  const isPaymentProcessedButNotCleared =
    [PAYMENT_STATUS.IN_PROGRESS, PAYMENT_STATUS.COMPLETED].includes(
      payment.status as PAYMENT_STATUS
    ) && payment.deliverStatus !== PaymentDeliveryStatusEnum.CLEARED;
  const isVirtualCardExpired = isVirtualCardPaymentExpired(payment);
  const isPushToDebitDeliveryMethod = isPushToDebitPayment(payment);

  let hint = '';
  let hintValues = {};
  let hasError = false;

  if (isVirtualCardDeliveryMethod && isPaymentProcessedButNotCleared) {
    hint = 'viewBillPaymentActivity.deliveryMethod.virtualCardNotClearedHint';
  } else if (isVirtualCardExpired) {
    hint = 'viewBillPaymentActivity.deliveryMethod.virtualCardExpiredHint';
    hasError = true;
    hintValues = {
      expirationDate: <MIFormattedDate date={virtualCard?.endDate} />,
    };
  } else if (isPushToDebitDeliveryMethod) {
    hint = 'viewBillPaymentActivity.deliveryMethod.modifiedByVendor';
  }

  if (hint) {
    return (
      <PaymentInfoHint hasError={hasError}>
        <MIFormattedText label={hint} values={hintValues} />
      </PaymentInfoHint>
    );
  }

  return null;
};
