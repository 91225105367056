import {
  EXPEDITE_OPTIONS,
  FORM_TYPES,
  TIMELINE_STEP_STAGES,
  TIMELINE_STEP_STATUSES,
} from 'src/app/pages/vendor/vendor-payment-tracking/consts';

import { DELIVERY_TYPE, ISSUER_ADDRESS, ISSUER_ZIP_CODE } from '../utils/consts';

/* eslint-disable import/no-default-export */
/* eslint-disable max-len,no-template-curly-in-string */
export default {
  'en-US': {
    profile: {
      details: {
        title: 'Profile information',
        email: 'Email',
        firstName: 'First name',
        lastName: 'Last name',
        edit: 'Edit',
        role: 'Role',
        picture: {
          title: 'Profile picture',
          help: 'Upload your profile picture',
        },
      },
      changePassword: {
        title: 'Change password',
        currentPassword: 'Current password',
        edit: 'Edit',
        password: 'New password',
        confirmPassword: 'Confirm password',
        error: {
          wrongCurrentPassword: 'Current password is incorrect',
        },
      },
    },
    qbr: {
      blockedUser: {
        title: 'We can’t authorize your Bill Pay account',
        subTitle:
          'If this looks like a mistake or you have questions, call {contactPhone} or email {supportEmail}',
        cta: 'Got it',
      },
      footer: {
        poweredBy:
          'Pay services powered by <sb>Melio</sb> with funds held by <sb>Evolve Bank & Trust</sb> or <sb>J.P Morgan</sb> (members of the FDIC and the Federal Reserve)<br><br> {termsOfService}',
      },
      errorPage: {
        title: 'Couldn’t load the page',
        subtitle: 'We ran into a technical issue and are looking into it.',
        subtitle2: 'Please try again at a later time',
        button: 'Close',
      },
    },
    qbo: {
      header: {
        help: 'FAQ',
        settings: 'Settings',
        support: 'Need help?',
        title:
          'Schedule a <private><sb>{amount}</sb></private> payment {breakLine} to <sb>{companyName}</sb>',
        recurringTitle:
          'Schedule a <private><sb>{amount}</sb></private> recurring payment to <sb>{companyName}</sb>',
        resendTitle:
          'Resend a <private><sb>{amount}</sb></private> payment to <sb>{companyName}</sb>',
        billDetails: 'Bill details',
        beta: 'Beta',
        notifications: {
          title: 'Notifications',
          noNotifications: 'No new notifications just yet',
        },
      },
      footer: {
        poweredBy:
          'Bill Pay services powered by <sb>Melio</sb> with funds held by <sb>Evolve Bank & Trust</sb> or <sb>J.P Morgan</sb> (members of the FDIC and the Federal Reserve)',
        termsOfService: 'Terms of service',
        privacyPolicy: 'Privacy policy',
      },
      settings: {
        title: 'Bill pay settings',
        account: {
          delete: 'Delete my account',
          logout: 'Log out of Bill Pay',
          logoutHint:
            'Click below to log out of Bill Pay services. Even once logged out, we’ll process all your scheduled payments and send you status updates for them. You can always log back in later.',
        },
      },
      blockedUser: {
        title: 'We’re unable to authorize your account',
        subTitle:
          'Thank you for your interest in Bill Pay powered by <b>Melio</b>. Unfortunately, our team couldn’t verify your account and/or your activity does not meet our Eligibility Criteria outlined in our {tos} agreement.{br}{br}As a result, we’re unable to authorize your activity and have blocked you from using Bill Pay services.{support}',
        dismiss: 'Dismiss',
        contactDetails: '{br}{br}If you have any questions you can email {supportEmail}',
        termsOfService: 'Terms of Service',
        buttonTextClose: 'Close',
        titleBlocked: 'Sorry, we could not locate the bill',
        subtitleBlocked:
          'You’re probably looking for something else. You can find this payment’s details in your QuickBooks account.',
      },
      incognitoErrorPage: {
        title: 'Incognito mode is blocking us',
        subtitle:
          'To continue with Bill Pay, please make sure you’re not in incognito mode and that your browser’s settings allow third-party cookies.',
        supportLink: 'Chat with us',
        supportText: 'For further assistance, ',
      },
      safariErrorPage: {
        title: 'Safari is blocking us',
        subtitle:
          'The settings in your Safari browser is blocking us from loading Bill Pay. Try using another browser (we recommend Chrome).',
        settingsHelpTitle: 'To continue with Safari: ',
        settingsHelp: 'Go to Settings > Privacy > uncheck Prevent cross-site tracking',
        supportLink: 'Chat with us.',
        supportText: 'For further assistance, ',
      },
      testVendorForex: {
        title: 'Welcome aboard!',
        subtitle: 'We’ll notify you as soon as your local currency will be available.',
      },
    },
    nav: {
      mobileHeader: {
        bills: 'Bill pay',
        payments: 'Payments',
        vendors: 'Contacts',
        settings: 'Settings',
      },
    },
    fab: {
      bill: 'Payment',
    },
    actionsMenu: {
      label: 'Actions',
      cancel: 'Cancel',
    },
    form: {
      addressAutocomplete: {
        addressLabel: 'Business address',
        emptyAddressLabel: 'No business address',
        addressPlaceholder: 'Ex.: 177a Bleecker Street, New York, NY, USA',
        addressInputHint: 'The address of your business’s primary location',
        hint: 'Start typing for autocomplete',
        aptNumberLabel: 'Suite #',
        emptyNumberLabel: 'No suite',
        aptNumberPlaceholder: 'Suite or appartment number',
        cantFindAddress: 'I can’t find my address',
        legalAddressLabel: 'Legal address is different from the company address',
      },
      input: {
        currency: {
          placeholder: '$0.00',
        },
      },
    },
    categoryList: {
      yes: 'Yes',
    },
    payBillPaymentActivity: {
      payFromTitle: 'Pay from',
      payWithTitle: 'Pay with',
      receiveTitle: 'Vendor receives',
      sendAsTitle: 'Send as',
      updated: 'Updated',
      fundingSource: {
        achLabel: 'Bank account',
        creditCardLabel: 'Credit card',
        debitCardLabel: 'Debit card',
        debitCardDescription: 'Using debit card ...{card4digits}',
        unverifiedBankAccountHint: 'Pending verification',
      },
      deductionDate: {
        futureLabel: 'Payment will be deducted on',
        recurringLabel: 'First payment will be deducted on',
        pastLabel: 'Payment was deducted on',
      },
      deliveryMethod: {
        paperCheckLabel: 'Paper check will be delivered to',
        achLabel: 'Bank transfer ACH to',
        rppsLabel: 'Direct payment',
        internationalLabel: 'International payment transfer',
        virtualCardLabel: 'Single-use virtual card to {icon}',
        virtualCardTooltip: 'This vendor has opted to receive payments by single-use card.',
      },
      deliveryDate: {
        unverifiedBankAccountHint:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
        unverifiedBankAccountApprovalRequiredHint:
          'To pay on schedule, verify your bank account {bankAccount} and make sure this payment is admin approved by <sb>{date} at 12pm EST</sb>',
        approvalRequiredHint:
          'To pay on schedule, make sure this payment is admin approved by <sb>{date} at 12pm EST</sb>',
        unilateralApprovalRequiredHint:
          'To pay on schedule, make sure this payment is admin approved and that your vendor selects how to receive payments by <sb>{date} at 12pm EST</sb>',
        unilateralHint:
          'To pay on schedule, your vendor needs to select how to receive payments by <sb>{date} at 12pm EST</sb>',
        unilateralFailedToDeliver:
          'For this payment to arrive on schedule your vendor needs to enter their delivery details by {date}',
        recurringHint:
          'Please note that the first payment may not arrive on time due to a minimum required processing time.',
        achHint: 'By 8 PM vendor’s local time',
        recurringLabel: 'First payment ETA',
        deliveryEtaLabel: 'Estimated payment delivery',
        estimatedDeliveryEtaLabel: 'Estimated payment delivery',
      },
      memo: {
        label: 'Memo to vendor',
        emptyPlaceholder: 'No memo to vendor',
      },
      sendingTo: 'Sending to',
    },
    viewBillPaymentActivity: {
      payFromTitle: 'Pay from',
      paidFromTitle: 'Paid from',
      payWithTitle: 'Pay with',
      paidWithTitle: 'Paid with',
      sendAsTitle: 'Send as',
      sentAsTitle: 'Sent as',
      receiveTitle: 'Vendor will receive',
      receivedTitle: 'Vendor received',
      fundingSource: {
        achLabel: 'Bank account',
        creditCardLabel: 'Credit card',
        debitCardLabel: 'Debit card',
        qbCash: '<private>{balance, number, currency}</private> available',
        debitCardDescription: 'Using debit card ...{card4digits}',
        unverifiedBankAccountHint: 'Pending verification',
        unverifiedBankAccountHintWithAction: 'Pending verification',
      },
      deductionDate: {
        unpaidLabel: 'Payment will be deducted on',
        paidLabel: 'Payment was processed on',
        approvalRequiredHint: 'Pending admin approval',
      },
      deliveryMethod: {
        paperCheckLabel: 'Paper check will be delivered to',
        achLabel: 'Bank transfer ACH to',
        rppsLabel: 'Direct payment to',
        virtualCardLabel: 'Single-use virtual card to',
        virtualCardLabelWithDetails: 'Single-use virtual card (...{card4digits}) emailed to',
        paidBillPaperCheckLabel: 'Delivery by paper check {formattedCheckSerial}',
        paidBillAchLabel: 'Delivery by bank transfer',
        virtualCardNotClearedHint: 'Pending clearance by the vendor',
        virtualCardExpiredHint: 'Expired on {expirationDate}',
        bankDebitCard: 'Delivery by bank using debit card',
        modifiedByVendor: 'Delivery method was modified by vendor',
        internationalLabel: 'International payment transfer',
      },
      deliveryDate: {
        unilateralApprovalRequiredHint: 'Pending admin approval and delivery details ',
        unilateralHint: 'Pending delivery details',
        achHint: 'By 8 PM vendor’s local time',
        deliveryEtaLabel: 'Estimated payment delivery',
        paidBillDeliveryEtaLabel: 'Delivery by',
        fastACHPaymentLateNotification:
          'Payment will arrive late. <SendItFaster>Send it faster</SendItFaster>',
      },
      memo: {
        label: 'Memo to vendor',
        emptyPlaceholder: 'No memo to vendor',
      },
    },
    viewBillWarningNotificationCard: {
      actionRequiredTitle: 'Action required:',
      contributor: {
        unverifiedBankAccountMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
        unverifiedBankAccountWithActionMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>{br}<verifyLink>Verify bank account</verifyLink>',
        unverifiedBankAccountApprovalRequiredMessage: `To pay on schedule, these need to be resolved.{br}
        <ul>
        <li>Verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb></li>
        <li>Make sure this payment is admin approved by <sb>{date} at 12pm EST</sb></li>
        </ul>`,
        approvalRequiredMessage:
          'To pay on schedule, make sure this payment is admin approved by <sb>{date} at 12pm EST</sb>',
        unilateralApprovalRequiredMessage: `To pay on schedule, these need to be resolved.{br}
        <ul>
        <li>Make sure this payment is admin approved by <sb>{date} at 12pm EST</sb></li>
        <li><sb>{vendor}</sb> needs to select how to receive payments by <sb>{date} at 12pm EST</sb></li>
        </ul>`,
        unilateralMessage:
          'To pay on schedule, <sb>{vendor}</sb> needs to select how they’d like to receive payments by <sb>{date} at 12pm EST</sb>',
      },
      admin: {
        unverifiedBankAccountMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
        unverifiedBankAccountWithActionMessage:
          'To pay on schedule, verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>{br}<verifyLink>Verify bank account</verifyLink>',
        unverifiedBankAccountApprovalRequiredMessage: `To pay on schedule, these need to be resolved.{br}
        <ul>
        <li>Verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb></li>
        <li>Make sure this payment is approved by yourself or another admin by <sb>{date} at 12pm EST</sb></li>
        </ul>`,
        approvalRequiredMessage:
          'To be paid on schedule, this payment must be admin approved by <sb>{date}</sb>',
        unilateralApprovalRequiredMessage: `To pay on schedule, these need to be resolved.{br}
        <ul>
        <li>Make sure this payment is approved by yourself or another admin before <sb>{date} at 12pm EST</sb></li>
        <li><sb>{vendor}</sb> needs to select how to receive payments by <sb>{date} at 12pm EST</sb></li>
        </ul>`,
        unilateralMessage:
          'To pay on schedule, <sb>{vendor}</sb> needs to select how they’d like to receive payments by <sb>{date} at 12pm EST</sb>',
      },
    },
    deliveryMethod: {
      achLabel:
        '{institutionName, select, undefined{} other{{institutionName}, }}{displayName} ...{identifier}',
      achLabelNoDots:
        '{institutionName, select, undefined{} other{{institutionName}, }}{displayName} {identifier}',
    },
    progress: {
      next: 'Next',
      prev: 'Back',
      finish: 'Finish',
      continue: 'Continue',
      update: 'Update',
      skip: 'Skip',
    },
    general: {
      free: 'Free',
      or: 'Or',
      new: 'New',
      emptyLabel: ' ',
      areYouSure: 'Are you sure?',
      error: {
        title: 'Sorry, something went wrong',
        subtitle: 'If this persists, please contact support at {email} to let us know.',
        buttonlabel: 'Reload',
      },
      on: 'On',
      off: 'Off',
      cancel: 'Cancel',
      save: 'Save',
      active: 'Active',
      back: 'Back',
      notNow: 'Not now',
      copy: 'Copy',
      continue: 'Continue',
    },
    manualAddress: {
      title: 'Let’s fix this',
      subtitle:
        'We spotted a small issue with the address you provided, and suggested an alternative below in green. Please select and/or edit the final version you’d like to use.',
      line1Label: 'Address line 1',
      line1Placeholder: 'Street and number, PO box, check locker',
      line2Label: 'Address line 2',
      line2Placeholder: 'Apartment, suite, building, floor, etc',
      cityLabel: 'City',
      stateLabel: 'State',
      zipCodeLabel: 'ZIP code',
      edit: 'Edit address',
      original: 'Original address',
      suggested: 'Suggested address',
      editAddress: 'Edit address',
      confirm: {
        title: 'Let’s fix the address you entered',
        subtitle:
          'We spotted a small issue with the address you provided, and suggested an alternative below. Please select and/or edit the final version you’d like to use.',
      },
      correctInvalid: 'Continue anyway',
      goUnilateral: 'Ask vendor for details',
      confirmInvalid: 'Confirm payment address',
      invalid: 'Address entered',
      invalidTitle: 'Address couldn’t be verified',
      invalidSubtitle:
        'The address entered couldn’t be verified. Please review it below to ensure the check arrives at the right place.',
      invalidPOBoxSubtitle:
        'We couldn’t confirm the accuracy of this address, but that doesn’t necessarily mean it’s wrong. Please review to ensure this check arrives at the right place.',
      invalidUnilateralSubtitle:
        'We couldn’t verify the address you entered. Edit the address or ask your vendor for their delivery details so they can choose how to receive your payments.',
      form: {
        title: 'No worries, let’s try it this way instead:',
      },
      confirmPage: {
        title: 'Let’s fix the address you entered',
        subtitle:
          'We spotted a small issue with the address you provided, and suggested an alternative below. Please select and/or edit the final version you’d like to use.',
      },
      poBoxPage: {
        subtitle:
          'We can’t verify the address you entered, which doesn’t necessarily mean it’s wrong. Please take a minute and review the address to ensure the check arrives at the right place.',
        correctInvalid: 'It’s correct, continue',
      },
    },
    list: {
      search: 'Search...',
      tab: {
        bills: {
          paid: 'paid',
          unpaid: 'unpaid',
          scheduled: 'scheduled',
        },
        requests: {
          paid: 'paid',
          scheduled: 'in-progress',
          sent: 'sent',
          unsent: 'unsent',
        },
        contacts: {
          vendors: 'vendors',
          customers: 'customers',
        },
        payments: {
          scheduled: 'scheduled',
          completed: 'completed',
          failed: 'failed',
        },
        invoices: {
          all: 'all',
          failed: 'failed',
          sent: 'sent',
          unsent: 'unsent',
        },
      },
      group: {
        bill: {
          failedPayment: 'Payment failed',
          pendingApprovalPayment: 'Pending approval',
          declinedPayment: 'Declined',
          overdueBills: 'Overdue',
          billsBeforeThisWeek: 'Older',
          billsDueThisWeek: 'Due this week',
          billsDueThisMonth: 'Later this month',
          billsForRest: 'Later',
          billsForLater: 'Due later',
          frequency: 'Recurring-{frequency}',
          occurrences: ' {index} of {occurrences}',
          listItemStatus: {
            failed: 'payment failed',
            pendingApproval: 'Pending',
            declined: 'Declined',
            scheduled: 'Scheduled',
            paid: 'Paid',
            markedAsPaid: 'Marked as paid',
            sent: 'Sent',
            completed: 'Completed',
          },
          scheduled: {
            billsDueThisWeek: 'Scheduled for this week',
            billsForLater: 'Scheduled for later',
            billsDueThisMonth: 'Scheduled for later this month',
            billsWithoutVendorInfo: 'Pending vendor’s information',
          },
          paymentRequested: 'Requested',
          createdByCustomer: 'Created by customer',
        },
        invoice: {
          listTagStatus: {
            unsent: 'unsent',
            sent: 'sent',
            failed: 'failed',
          },
          failed: 'failed',
          overDue: 'overdue',
          dueThisWeek: 'due this week',
          dueThisMonth: 'due this month',
          dueLater: 'due later',
          todayScheduled: 'scheduled today',
          pastWeekScheduled: 'scheduled in the past week',
          todayPaid: 'Paid today',
          pastWeekPaid: 'Paid last 7 days',
          paidEarlier: 'Paid earlier',
          rest: 'scheduled earlier',
        },
      },
    },
    wizard: {
      support: 'Need help?',
    },
    input: {
      optional: ' (optional)',
    },
    select: {
      defaultPlaceholder: 'Select',
      noOptions: 'No options',
    },
    companies: {
      acceptRequest: {
        title: 'Select company {user} should join',
        subtitle:
          'Which of your Melio companies would you like <b>{user}</b> to join as <ind>{role}</ind> <capb>{role}</capb>?',
        companyOption: '{companyName}',
      },
    },
    server: {
      'Network Error':
        'Looks like some network error. Please reload and try again. If the problem continues, please contact our customer support',
      ATH02: 'Email or password are incorrect, please enter again',
      ATH03: 'There’s an error with this account, please contact support',
      ATH04: 'We’re sorry, looks like there’s an error with this account, please contact support.',
      ATH01: 'This email already registered in Melio.',
      ATH09:
        'We’re sorry, looks like this QuickBooks account can not be connected. Please contact our support.',
      ATH13: 'The code does not match the latest code or the latest code is invalid',
      ATH14: 'Can’t sign in as a guest',
      ATH15: 'The user already belong to organization',
      RSC03: 'You will have to manually cancel all scheduled payments for this payment method.',
      USED_AS_CF:
        'This payment method is being used in Billing to cover check fees. Remove the method there and then try again.',
      ALREADY_EXISTS_CONTINUE: 'continue to login',
      ALREADY_EXISTS_CHANGE_EMAIL_CONTINUE: 'confirm verification code',
      DLM07: 'You already added this bank account',
      FAILED_TO_DELIVER:
        'This account recently failed to receive your payment, try another account instead.',
      RSC01: 'This payment method is currently being used in a different tab on your computer.',
      RSC05: 'You already added this card',
      RSC11: 'Microdeposit verification failed',
      CHC01: 'Sorry, we don’t support consumer Visa cards. Please try again with a business card.',
      CHC02:
        'Sorry, but we don’t currently support pre-paid cards or any cards issued outside the US.',
      CHC03:
        'Unfortunately, we weren’t able to link your card. Please take another look at the card details and contact support if you’re still having trouble.',
      CHC04:
        'The billing details you’ve entered don’t match the bank’s records, please check and re-enter.',
      CHC05:
        'Unfortunately, we weren’t able to link your card. Please take another look at the card details and contact support if you’re still having trouble.',
      RSC09: 'You already added this bank account',
      DLM15: 'You already added this card',
      VDR03: 'We’re sorry, this contact has existing bills and can not be deleted.',
      VDR04: 'We’re sorry, this contact has scheduled payments and can not be deleted.',
      VDR06: 'We’re sorry, looks like your link is not valid.',
      PMT05: 'Payment approval has declined the payment',
      PMT10:
        'You can not use the same account as both a payment and a delivery method. Please change one of them and try again.',
      RB06: 'You can not use the same account as both a payment and a delivery method. Please change one of them and try again.',
      ERR: 'We’re sorry, some issue occurred and our team was notified. If this still happens, please contact support.',
      VT1: 'We’re sorry, looks like your link is not valid.',
      VT2: 'We’re sorry, looks like your link is not valid.',
      VT3: 'This payment link has expired.{br}If you need any help we’re available at {br}{supportEmail}',
      VT4: 'We are sorry but there seems to have been a problem with adding your card. Please try again.',
      UNP1: 'We’re sorry, looks like your link is not valid.',
      UNP3: 'You already added payment delivery details',
      UNP4: 'We’re sorry but this payment was cancelled. {br}Need help? We are always available at {supportEmail}',
      UNP5: 'This payment link has expired. Please contact <sb>{companyName}</sb> at {email} to resolve this and get an updated link.',
      PMT01: 'We’re sorry, looks like your link is not valid.',
      PMT14:
        'We’re sorry but you cannot ask your vendor to add their payment details when using a payment method that hasn’t been verified.',
      PMT17:
        'We’re sorry but this payment was cancelled by <sb>{companyName}</sb>. Please reach out to <sb>{companyName}</sb> at {email} and ask them to resend their request.{br}{br}Need help? We are always available at {supportEmail}',
      PMT18:
        'This payment link has expired. Please contact <sb>{companyName}</sb> at {email} to resolve this and get an updated link.',
      PMT19:
        'Sorry, but this payment can’t be processed instantly anymore. It’ll be processed as a free ACH bank transfer. {br}{br} If you need any help we`re available at {supportEmail}',
      RB07: 'We’re sorry but you cannot ask your vendor to add their payment details when using a payment method that hasn’t been verified.',
      RSC20: 'Manual unverified bank accounts limit exceeded',
      RETRY_SAME_BANK_DETAILS:
        'Previously entered bank details are incorrect. Contact your vendor for a valid routing and account number and try again.',
      RETRY_SAME_BANK_DETAILS_TITLE: 'No account found',
      PTD1: 'Payment already in progress. This payment is already being processed as a fast transfer and cannot be sent via debit card.',
    },
    settings: {
      title: 'Settings',
      account: {
        title: 'Account',
        description: 'Set a new password, change email notifications settings.',
        cardTitle: 'Account information',
        form: {
          name: 'Name',
          nameEmpty: 'No name',
          email: 'Email',
          emailEmpty: 'No email',
          phone: 'phone',
          phoneEmpty: 'No phone',
          password: {
            title: 'Change password',
            currentPassword: 'Current password',
            newPassword: 'New password',
            confirmPassword: 'Confirm password',
          },
          sendEmailNotifications: 'Email notifications',
          billQbSyncedNotification: 'Remind me about new bills added on QuickBooks',
          on: 'On',
          off: 'Off',
        },
      },
      paymentMethods: {
        title: 'Payment methods',
        description:
          'Add/remove or edit all your payment methods - bank accounts, credit or debit cards.',
        addLabel: 'Add label',
        editLabel: 'Edit label',
        delete: 'Delete',
        verify: 'Verify',
        edit: 'Edit',
        editQBLink: 'Edit QB Link',
        view: 'View',
        ach: 'Bank account ',
        achNameLabel: '{name}',
        cardItemName: '{name}',
        hint: 'Add method',
        bankHint: 'ACH. Fast and reliable (free)',
        notVerifiedAccountHint: '* Account is pending verification.',
        notVerifiedAccountHintWithAction: 'Pending verification*{br}{link}',
        verifiedAccountHint: 'Free',
        verifyAccountHint: 'Verify account',
        microDepositsBlocked: 'Unable to verify your account. {chatWithUs}',
        recurringHint:
          'Please note that the first payment may not arrive on time due to a minimum required processing time.',
        creditCardHint: '{fees_credit_value}% Fee',
        addAnotherBank: '+ Add another bank account',
        addAnotherCreditCard: '+ Add another credit card',
        addAnotherDebitCard: '+ Add another debit card',
        credit: 'Credit card ',
        deleteDialog: {
          title: 'Delete payment method?',
          subtitle:
            'You asked to delete payment method {paymentMethodName} ending in {paymentMethodNumber}',
          confirm: 'Delete',
        },
        editLabelDialog: {
          title: 'Label this payment method',
          description: 'Use a few words to describe this payment method.',
          inputLabel: 'Label',
          emptyError: 'Please enter a label',
          placeholder: 'e.g., Utilities',
          saveCta: 'Save',
          removeCta: 'Remove label',
          savedToast: 'Label saved',
          removedToast: 'Label removed',
        },
        notVerifiedManualBankHint: 'Pending verification',
        debitCardHint: 'Free',
        debit: 'Debit card ',
        microDepositsBlockedContactLabel: 'Contact support',
      },
      receivingMethods: {
        title: 'Receiving methods',
        description: 'Add/remove or edit your receiving method - your bank account to get paid.',
        addAnotherBank: '+ Add another bank account',
        addAnotherCreditCard: '+ Add another credit card',
        addAnotherDebitCard: '+ Add another debit card',
        ach: 'Bank account',
        achDescription: '{accountName}',
        notVerifiedAccountHintWithAction: '* Account is pending verification. {link}',
        microDepositsBlocked: 'Unable to verify your account. Contact support',
        verifyAccountHint: 'Verify account',
        hint: 'Connect your bank account to get paid',
        edit: 'Edit',
        view: 'View',
        editBankAccountLink: 'Edit sync details',
        delete: 'Delete',
        dialog: {
          delete: {
            titleDeletable: 'Delete this bank account?',
            titleUndeletable: 'Can’t delete this bank account',
            subtitleDeletable:
              'Your Melio.me payment portal will stop working without a bank account that will receive the funds.',
            subtitleUndeletable:
              'This receiving method has scheduled payments incoming and cannot be deleted right now. You can delete it when the payment will be deposited.',
            confirm: 'Yes, delete',
          },
          edit: {
            title: 'Change this bank account?',
            subtitle: 'You have ongoing requests. They will be deposited to the new bank account',
            confirm: 'Yes, continue to edit',
          },
        },
      },
      microDeposits: {
        verifyDialog: {
          title: 'Verify your bank account',
          subtitle:
            'We sent 2 small deposits (less than $1.00) from Melio Payments to your bank account ending in <sb>{accountNumber}</sb>.{br}{br} Enter the exact amounts below to verify your account.{br}{br}',
          sum1Label: 'Deposit #1',
          sum2Label: 'Deposit #2',
          placeholder: '$0.00',
          confirm: 'Verify',
          incorrectAmounts: 'One or both of the amounts above is incorrect. Please try again.',
          emptyAmounts: 'Please enter both amounts and try again.',
          viewAnExample: 'View an example',
          exampleTitleText: 'This is only an example',
        },
        verifyDialogLimitReached: {
          title: 'Too many failed attempts',
          subtitle:
            'To verify your account, please contact our support team at {supportEmail} or {chatWithUs}.',
          dismiss: 'Close',
          chatWithUs: 'chat with us',
          subtitleWithoutChat:
            'To verify your account, please contact our support team at {supportEmail}.',
        },
        verifyDialogSuccess: {
          paymentMethods: {
            title: 'Verification complete!',
            subtitle:
              'You successfully verified this bank account and can now use it to send and schedule payments.',
          },
          receivingMethods: {
            title: 'Your bank account is verified!',
            subtitle:
              'You can view all incoming payments, including those initiated by your customers.',
          },
          ok: 'Done',
        },
        alreadyVerifiedDialog: {
          title: 'Account already verified',
          subtitle:
            'This bank account is already verified. You can use it to send and schedule payments online.',
          ok: 'Got it',
        },
        depositsNotSentYetDialog: {
          title: 'Can’t verify just yet',
          subtitle:
            'We’re sending 2 deposits to your bank account ending in <sb>{accountNumber}</sb> (can take up to 3 business days).{br}{br} We’ll email you once the deposits are made with a link to complete the verification.',
          ok: 'Got it',
        },
        deletedBankAccountDialog: {
          title: 'Account deleted',
          subtitle: 'The account you are trying to verify has been deleted from Bill Pay',
          ok: 'Got it',
        },
      },
      billing: {
        title: 'Billing',
        description: 'View or download your billing receipts.',
        cardTitle: 'Transaction fee history',
        emptyTablePlaceholder: 'Receipts for fees due to Intuit will appear here.',
        emptyFeesPlaceholder: 'No fees just yet.',
        paymentsTable: {
          date: 'Date',
          paymentMethod: 'Payment method',
          amount: 'Amount',
          receipt: 'Receipt',
        },
        feeCard: {
          modal: {
            removePayment: {
              title: 'Remove payment method',
              description:
                'Are you sure you want to remove this payment method for check fees? Check fees will then be billed to the method used for each payment.',
              actions: {
                cancel: 'Cancel',
                remove: 'Remove',
              },
            },
            changePayment: {
              title: 'Change payment method',
              description:
                'Are you sure you want to change the payment method for check fees? Only your accountant can change it back to their method for check fees.',
              actions: {
                cancel: 'Cancel',
                change: 'Change',
              },
            },
          },
          checks: {
            title: 'Payment method for check fees',
            cards: {
              empty: {
                label: 'Pay paper check fees separately',
                description: 'Use a specific payment method for all check fees.',
                action: {
                  add: 'Add billing method',
                },
              },
              firmFS: {
                label: "Using accountant's payment method",
                description: 'Paper check fees are being covered by your accountant.',
                action: {
                  add: 'Add billing method',
                },
              },
              ownFS: {
                creditcard: {
                  label: '{network} ...{card4digits}',
                  description: 'Check fees will be billed separately to this payment method.',
                },
                bank: {
                  label: '{displayName} ...{identifier}',
                  labelWithInstitution: '{institutionName}, {displayName} ...{identifier}',
                  descriptionUnverified:
                    'Until the bank account is verified, check fees will be  billed to the method used for each payment. {br}{link}',
                  description: 'Check fees will be billed separately to this payment method.',
                },
                qbcash: {
                  label: 'QuickBooks Checking',
                  labelWithInstitution: '{displayName}',
                  description: 'Check fees will be billed separately to this payment method.',
                },
              },
            },
            pages: {
              complete: {
                createFlow: {
                  title: 'Payment method added for check fees',
                  info: 'Your paper check fees will now be billed separately using the <b>{fsMethod}</b> ending in <b>{ccNumber}</b>.',
                  infoQbChecking:
                    'Your paper check fees will now be billed separately using <b>QuickBook Checking</b>.',
                  cta: 'Done',
                },
                changeFlow: {
                  title: 'Payment method changed',
                  info: 'Your paper check fees will now be billed separately using the <b>{fsMethod}</b> ending in <b>{ccNumber}</b>.',
                  infoQbChecking:
                    'Your paper check fees will now be paid using <b>QuickBooks Checking</b>.',
                  cta: 'Done',
                },
                unverifiedBankFlow: {
                  change: {
                    title: 'Payment method changed',
                    info: 'Check fees will be billed to this payment method (<b>bank account</b> ending in <b>{ccNumber}</b>) once verified. Until then, fees will continue being billed to the method used for the payment. ',
                    cta: 'Done',
                  },
                  add: {
                    title: 'Payment method added for billing',
                    info: 'Check fees will be billed to this payment method (<b>bank account</b> ending in <b>{ccNumber}</b>) once verified. Until then, fees will continue being billed to the method used for the payment. ',
                    cta: 'Done',
                  },
                },
              },
              pendingBankAccount: {
                title: 'Pending bank account verification',
                description:
                  'When the micro-deposits are transferred to your account, you’ll receive an email with a link to complete the verification process. The micro-deposits will show up as credits and then debits from Melio Payments inc.',
                cta: 'Got it',
              },
            },
          },
          toaster: {
            create: {
              fail: 'Failed creating a fee funding source',
            },
            remove: {
              success: 'Payment method for paper check fees was removed.',
              failed: 'Payment method for paper check fees was failed to be removed.',
            },
          },
          actions: {
            change: 'Change',
            remove: 'Remove',
            addMethod: 'Add payment method',
          },
        },
      },
      vendors: {
        title: 'Vendors',
      },
      actions: {
        edit: 'Edit',
        done: 'Done',
        update: 'Update',
      },
    },
    companyInfo: {
      title: 'Company info',
      companyName: 'Company name',
      taxId: 'Tax ID',
      phone: 'Phone',
      yearOfIncorporation: 'Year of incorporation',
      accountingSoftware: 'Accounting software',
      businessType: 'Business type',
      name: {
        inputTitle: 'Business name',
      },
      taxIdTypes: {
        SSN: 'SSN',
        ITIN: 'ITIN',
        EIN: 'EIN',
      },
    },
    bills: {
      title: 'Pay',
      errors: {
        sync: {
          title: 'Sorry, something went wrong',
          subTitle:
            'We couldn’t load Bill Pay services. Please try again or reach out to us via chat for assistance',
          buttonLabel: 'Start chat',
        },
        billAlreadyPaid: {
          title: 'This bill is already paid',
          subTitle:
            'The bill for <sb>{amount}</sb> to <sb>{vendorName}</sb> is already marked as paid. We moved it to your expenses, so you can always view it there.',
          buttonLabel: 'Go back to Online payments',
        },
        zeroAmount: {
          title: 'Bill amount cannot be 0',
          subTitle:
            'The bill to <sb>{vendorName}</sb> requires a valid amount to schedule payment. Please go back, edit the bill and try again.',
          buttonLabel: 'Go back and edit Bill',
        },
        maxAmount: {
          title: 'Bill amount exceeds the sending limit',
          subTitle:
            'Currently, we accept bill payments for up to <sb>{amount}</sb>. Please go back, edit the bill amount and try again. ',
          buttonLabel: 'Go back and edit Bill',
        },
        batchMaxAmount: {
          title: 'One of the bills exceeds the sending limit',
          subTitle:
            'Currently, we accept bill payments for up to <sb>{amount}</sb>. Please go back, edit the exceeding bill and try again.',
          buttonLabel: 'Go back and edit Bill',
        },
      },
      actions: {
        createFirst: 'Add your first bill',
        edit: 'Edit',
        pay: 'Schedule payment',
        refresh: 'Refresh',
        delete: 'Delete',
        markAsPaid: 'Mark as paid',
        undoMarkAsPaid: 'Paid',
        undoMarkAsPaidMobile: 'Make unpaid',
        save: 'Save',
        contactSupport: 'Contact support',
        retryPayment: 'Resolve and reschedule payment',
        retryPaymentDelivery: 'Edit delivery details',
        resendPayment: 'Resend payment',
        askVendorForDetails: 'Ask vendor for details',
        decline: 'Decline',
        approve: 'Approve',
        reschedule: 'Reschedule this bill payment',
        trackDelivery: 'Track Delivery',
        rejectPaymentRequest: 'Reject',
        schedulePaymentRequest: 'Schedule payment',
        payOnline: 'Schedule payments online ({amount})',
        retry: 'Retry payment',
        refund: 'Get a refund',
      },
      emptyState: {
        title: 'How paying your vendors works',
        btnText: 'Tap (+) to add a bill.',
        btnHint: 'No bills to pay.',
        text: 'No bills here',
        search: 'Oops! No results found',
        textPaid: 'No paid bills yet.',
        textScheduled: 'No bills scheduled for payment.',
      },
      newEmptyState: {
        title: 'Bill pay',
        text: 'Enter, upload or just connect QuickBooks to add a bill. Then connect your preferred payment method’s details and decide how and when it should be received. That’s it.',
        buttonText: 'Add a new bill',
        buttonTextFirstBill: 'Add your first bill',
        goProductEducationButton: 'See what you can pay using Melio',
        subtitle: 'Pay right on time, every time',
        item1: 'Manage all your bills in one place.',
        item2: 'Schedule payments for now or later.',
        item3: 'Pay by card or bank transfer. Your vendors get checks or deposits.',
      },
      new: {
        section: 'Add a new bill',
        saveAndAdd: 'Save and schedule payment',
        schedulePayment: 'Schedule a payment',
        saveAndClose: 'Save and close',
        submit: 'Add bill',
        cancel: 'Cancel',
        removeFile: 'Delete image/file',
        edit: 'Edit bill',
        manual: 'Enter manually',
        camera: 'Import from camera',
        file: 'Import from file',
        retry: 'Retry',
        enterManually: 'Enter manually',
        withQuickbooks: 'Sync with QuickBooks',
        dragAndDrop: 'Drag and drop here to upload your invoice image/file or {browse}',
        dragAndDropMobile: 'Click here to upload a file',
        errorProcessingFile: 'Unfortunately we couldn’t read the invoice info automatically.',
        errorProcessingFileAction: 'Please try again or enter details manually.',
        browse: 'Browse',
        vendorAddInSelect: 'Add <sb>{value}</sb> as a new vendor',
        lineItems: 'Bill items',
        addFirstLineItem: 'Add a bill item',
        addLineItem: 'Add another bill item',
        pdfPagination: '{page} {current} / {total}',
        success: {
          success: 'New bill added!',
          wasCreated: 'A new bill was successfully added and is ready to be paid',
          payThisBill: 'Schedule your payment',
          addAnother: 'Add another bill',
          done: 'I’m done here',
        },
        uploading: {
          title: 'Processing your invoice file',
          subtitle: 'Thanks for your patience, this shouldn’t take long.',
        },
        createOptions: {
          title: 'Add a bill to pay',
          subTitle: 'Add a bill however you like and schedule an online payment.',
          skipCreation: 'I don’t have a bill, I’ll do it later',
          cancel: 'Cancel',
          importBillFile: 'From a file',
          importBillFileDescription: 'Upload a file from your device',
          addBillManually: 'Manually',
          addBillManuallyDescription: 'Type in bill’s details',
          syncWithQuickbooks: 'Sync with QuickBooks',
          syncWithQuickbooksDescription: 'Automatically sync all your bills',
          scanBill: 'Scan a bill',
          scanBillDescription: 'Use your device’s camera',
          header: {
            title: 'New bill',
          },
        },
      },
      form: {
        total: 'Total to pay',
        billAmount: 'Bill amount',
        totalAmount: 'Amount',
        totalAmountPlaceholder: '$0.00',
        vendor: 'Vendor name',
        accountIdentifier: 'Account number',
        vendorNoOptions: 'Enter a vendor name to add it to your list',
        vendorPlaceholder: 'Enter vendor',
        vendorAccNum: 'Account no.',
        vendorAccNumPlaceholder: 'e.g 012078200',
        vendorAccNumEmpty: 'No Account Number',
        vendorAccNumHint: 'Unique to your company. Typically found on invoices.',
        receivedProduct: {
          question: 'A new text will be provided 1',
          instruction: 'To avoid delays please include invoices for payments that exceed $10,000',
          no: 'Not yet',
        },
        partialPayments: {
          linkedPayments: 'Linked payments',
          total: 'Bill amount',
          openBalance: 'Open balance',
          partialPaymentStatus: '{status} {preposition} {date}',
          preposition: {
            for: 'for',
            on: 'on',
          },
          current: '(Current)',
          reviewAndResolve: 'Review & Resolve',
          review: 'Review',
          markUnpaid: 'Mark unpaid',
          viewDetails: 'View details',
          status: {
            failed: 'Failed',
            declined: 'Declined',
            scheduled: 'Scheduled',
            manuallyPaid: 'Marked as paid',
            paid: 'Paid',
            externallyPaid: 'Marked as paid',
            refundCompleted: 'Refunded',
            refundScheduled: 'Refund sent',
            refundFailed: 'Refund failed',
          },
          markAsPaid: {
            title: 'Enter an amount to mark as paid',
            intuitLinkedTitle: 'Which bank account did this payment come from?',
            amountLabel: 'Payment amount',
            description: 'If you paid part of the bill, update the amount paid.',
            saveButton: 'Mark as paid',
          },
          tooltip: 'Edit payment amount',
          paymentsMade: '{count} {count, plural, one {payment} other {payments}} made',
          errors: {
            amountToBalance:
              'Enter an amount equal or lower than the open balance ({paymentsAmount})',
            editAmountError:
              'Payment amount can be equal or lower than bill open balance (<private>{billBalance}</private>)',
            enterAmount: 'Enter a payment amount',
            editAmount:
              'Enter an amount larger than total payments already made ({paymentsAmount})',
            cannotBeZero: 'Payment amount cannot be $0.00',
          },
          billListItem: {
            totalAmount: 'Total bill amount',
            tag: 'Partially paid',
          },
        },
        paymentRepeat: {
          oneTime: 'One time',
          monthly: 'Monthly',
          weekly: 'Weekly',
          title: 'Is this a one-time or recurring payment?',
        },
        frequency: 'Payment frequency',
        recurring: {
          occurrences: 'Number of payments',
          occurrencesPlaceHolder: 'e.g. 12',
          occurrencesHintForMonthly: 'Up to 24 monthly payments',
          occurrencesHintForWeekly: 'Up to 60 weekly payments',
          dueDate: 'Delivery date for the first payment',
          recurringWeeklyHint: 'Future payments will be set to {specificDay} every week',
          recurringMonthlyHint: 'Future payments will be set to day {specificDay} of every month',
          dueDatePlaceholder: 'Select first payment arrival date',
        },
        invoiceNumber: 'Bill no.',
        invoiceNumberEmpty: 'No invoice number',
        invoiceNumberPlaceholder: 'e.g. 200000',
        deliveredBy: 'Delivered by',
        deliveredByEmpty: '',
        terms: 'Payment term',
        termsPlaceholder: 'Select terms',
        termsEmpty: 'No terms',
        description: 'Memo to self',
        noteFromVendor: 'Note from vendor',
        paymentRequestInvoiceNumber: 'Invoice no.',
        descriptionEmpty: 'No note',
        descriptionPlaceholder: 'e.g. office expenses',
        dueDate: 'Due date',
        dueDatePlaceholder: 'Select due date',
        dueDateEmpty: 'No due date',
        dateIssued: 'Bill date',
        dateIssuedPlaceholder: 'Select issue date',
        dateIssuedEmpty: 'No invoice date',
        intuitAccount: 'Enter QuickBooks account',
        intuitAccountPlaceholder: 'Category',
        intuitAccountEmpty: 'No QuickBooks account',
        attachment: {
          label: 'Image attachment',
          addAnother: 'Add another',
          remove: 'Remove',
          uploading: 'Uploading your file...',
          add: 'Add',
          addAttachmentAlt: 'Add image or file',
          upload: 'Snap or upload bill',
          replace: 'Replace file',
          retry: 'Try again',
          delete: 'Delete file',
          formatError: 'Please try to upload an image',
          error: 'Oops. Something went wrong',
        },
        lineItems: {
          title: 'Items',
          description: 'Line Item Name',
          amount: 'Amount',
          delete: 'Delete line item',
          add: 'Add new item',
          item: 'Item',
          amountPlaceholder: '$00.00',
          addItemImageAlt: 'Add item',
        },
        paymentActivity: {
          title: 'Payment activity',
          fullPaymentTitle: 'Full payment',
          failedPaymentTitle: 'Failed payment',
          checkDeposited: 'deposited',
          achDeposited: 'delivered',
          partiallyPaid: 'partially paid',
          checkDepositedDate: 'On {date}',
          debitInfoLabel: 'Using debit card ...{card4digits}',
          scheduledBill: {
            scheduleMethodAch: 'Bank account',
            scheduleMethodCreditCard: 'Credit card',
            scheduleMethodDebitCard: 'Debit card',
            deliveryPaperCheckMethod: 'Paper check will be delivered to',
            deliveryBankTransferMethod: 'Bank transfer ACH to',
            scheduleMethodAchQBCash: 'QuickBooks Checking',
            internationalTransferMethod: 'International payment',
            virtualDeliveryMethod: 'Asking vendor for delivery details',
            virtualCardDeliveryMethod: 'Single-use virtual card to',
            checkDeliveryMethod: 'Paper Check',
            balance: 'Current balance: <private>{balance, number, currency}</private>',
            freeBalance: 'Free. Current balance: <private>{balance, number, currency}</private>',
            noBalance: ' ',
            hint: {
              regular: 'Free. ',
              feeFundingSource: 'Use this bank account to cover paper check fees.',
            },
            international: {
              credit: 'Credit cards can’t be used as a funding source for international payments.',
              debit: 'International payments cannot be made with a debit card.',
              card: {
                disable: 'International payment is not supported in Melio using {cardType}',
              },
            },
          },
          paidBill: {
            scheduleMethodAch: 'Bank account',
            deliveryPaperCheckMethod: 'Delivery by paper check {formattedCheckSerial}',
            deliveryBankTransferMethod: 'Delivery by bank transfer',
          },
          completedPaymentDescription: `Still don't see your refund? Contact your bank with this trace number #{traceNumber}.`,
          schedulePaymentDescription:
            'A refund for this payment was sent on {date}. It can take up to 5 business days until you see it.',
          schedulePaymentDescriptionPops:
            'Bill pay issued a manual refund for this payment on {date}. It can take up to 5 business days until you see the funds.',
          scheduleRefundProcessingDescription:
            'A refund for this payment was sent on {date}. It can take up to 5 business days until you see it.',
          scheduleRefundProcessingDescriptionPops:
            'We’re processing your refund. It may take up to 5 business days before you see it.',
          failedPayment: 'Failed on {date}',
          refund: {
            sent: 'Refund sent on {date}',
          },
          failedPaymentDescription: {
            defaultFailedToCollect:
              'This payment couldn’t be processed. Please contact billops@meliopayments.com to learn more and resolve this issue.',
            exceedsWithdrawalAmountLimit:
              'This card {fundingSource} has a limit preventing this payment from being processed. Change your payment method or ask the card’s issuer to remove or modify your card’s withdrawal limit to reschedule the payment.',
            defaultFailedToDeliver:
              'This payment couldn’t be delivered to your vendor. Please contact billops@meliopayments.com for assistance.',
            cardNetworkRestrictions:
              'Payment failed due to card network restrictions. Please connect another payment method and retry payment to complete this payment.',
            paymentNotAllowedConsumer: 'Sorry but Melio doesn’t support consumer payments.',
            paymentNotAllowedVendorDetails:
              'Payment failed because we were unable to verify your vendor’s details.',
            paymentNotAllowedPayroll:
              'Sorry but Melio doesn’t support payroll transactions at the moment.',
            retrySupportedFundingSource:
              'Please retry the payment with a payment method we support.',
            paymentNotAllowedOther: 'We were unable to process this payment.',
            paymentNotAllowedTechnical:
              'Payment failed because of an issue with the information you added. Please contact our team at billops@meliopayments.com or on the chat below.',
            paymentNotAllowedDuplicate:
              'Payment failed because it appears to be a duplicate of another payment you’ve already scheduled. Please contact support if this isn’t a copy or mistake.',
            unilateralDeliveryMethodNotProvided: 'Vendor’s payment details were not provided',
            doNotHonor:
              'This card {fundingSource} seems to have been blocked, possibly due to a card limit or fraud concern. Change your payment method or ask your card’s issuer to remove the "card issuer block" to reschedule the payment.',
            insufficientFunds:
              'This bank account {fundingSource} has insufficient funds for this payment. You can either add funds to your bank account or change your payment method to reschedule the payment.',
            balancesNotAvailable: 'Payment failed due to insufficient funds for this payment.',
            incorrectVendorACH:
              'Please correct the payment receiver’s routing number (ACH/direct deposit, not wire) and notify us at support@qbousers.meliopayments.com so we can quickly process this payment.',
            accountFrozen:
              'This bank account {fundingSource} seems to be frozen. Change your payment method to reschedule the payment.',
            userBlockedMelioAccount:
              'This payment has been blocked by your bank. Change your payment method or ask them to remove their block to resend the payment.',
            receiverAccountClosed:
              'The bank account used for this payment’s delivery appears unable to receive payments. Change to another delivery method to resend this payment.',
            noBankAccount:
              'Your vendor’s delivery details seem to be outdated. Change to another delivery method to resend this payment {refund}',
            bankDetailsIncorrect:
              'Vendor’s delivery details are incorrect. Change to another delivery method to resend this payment {refund}',
            accountNumberIncorrect:
              'Vendor’s account number is incorrect. Change to another delivery method to resend this payment {refund}',
            invalidBankAccountNumber:
              'Your vendor’s bank account number seems to be incorrect. {retry} with valid bank details {refund}.',
            routingNumberIncorrect:
              'Your vendor’s routing number seems to be incorrect. Change to another delivery method to resend this payment.',
            receiverRefusedCreditEntry:
              'This payment couldn’t be delivered because its recipient has declined it. Resolve this with {vendorCompanyName} before resending the payment.',
            nonTransactionAccount:
              'This payment’s delivery method seems unable to receive payments. Change to another delivery method to resend this payment.',
            uncollectedFunds:
              'This payment could not be processed as there aren’t enough accessible funds in your account. Change your payment method or try again once there are sufficient accessible funds.',
            invalidTransaction:
              'This payment failed to transfer, which may have been caused by a number of reasons. Change your payment method or contact your card issuer to resolve this and reschedule the payment.',
            paymentStoppedByVendor:
              'You stopped this payment with your bank. Please contact billops@melio.com to resolve this issue.',
            bankAccountUnauthorized:
              'This payment has been put on hold due to the bank account owner reporting it as unauthorized. Change your payment method or contact the bank to authorize it and reschedule the payment.',
            unauthorizedDebitToConsumerAccount:
              'This payment wasn’t delivered as it was being transferred to a consumer bank account. Please contact billops@melio.com to resolve this issue.',
            default:
              'Payment failed due to card network restriction. Please connect another payment method and retry to complete this payment.',
            virtualCardExpired:
              'The vendor didn’t clear the card payment within 30 days. Resend payment with a new single-use card or as a bank transfer.',
            internationalFailedToDeliver:
              "This payment couldn't be delivered to your vendor. Please contact billops@qbousers.meliopayments.com for assistance.",
            returnedCheck:
              'USPS return reason: "No mailbox, check returned as undeliverable". Resend the payment by ACH or reissue the check.',
            refund: {
              title: 'Resend or refund payment',
              requestRefund: 'or, {link}',
              link: 'request a refund',
              linkGet: 'get a refund',
              R03: 'Vendor’s bank details are incorrect. {retry} with valid bank details {refund}',
              R04: 'Vendor’s account number is incorrect. {retry} with valid bank details {refund}',
            },
            retry: {
              requestRetry: '{link}',
            },
            failedRefundPayment: 'For help with your refund, please contact billops@melio.com.',
            failedSendingRefund:
              'There was a problem resending your refund, but we will take care of it. It may take up to 5 business days to start processing. We’ll reach out to you if needed.',
            failedToDeliverACHRetryTitle: {
              R03: 'Vendor’s bank details are incorrect. {retry} with valid bank details.',
              R04: 'Vendor’s account number is incorrect. {retry} with valid bank details.',
            },
            failedToDeliverACHRetryLink: 'Retry payment',
            failedToDeliverACHRetryNotification: 'Payment resent',
          },
          deliveryTitleETA: 'Estimated payment delivery',
          deliveryETASuccessTitle: 'Delivery ETA',
          deliveryETASuccessValue: 'Up to 30 minutes',
          checkDeliveryTitleETA: 'Check was deposited on',
          deliveryVirtualDescription: 'Estimated payment delivery',
          deliveryVirtualAdditionalHint:
            'You’ll be updated with a final delivery date once payment details are received.',
          deliveryVirtualRecurringAdditionalHint:
            'You’ll be updated with a final delivery date once payment details are received.',
          deliveryTitleAt: 'Delivery by',
          deliveryMethodAdditionalDescription: 'By 8 PM vendor’s local time',
          deliveryMethodAdditionalDescriptionPayee: '(By 8 PM local time)',
          processed: 'Processed on {date}',
          actions: {
            edit: 'Edit payment',
            cancel: 'Cancel payment',
            cancelPayment: 'Cancel payment',
            deleteBill: 'Delete bill',
            deletePayment: 'Delete payment',
            cancelCurrentRecurringPayment: 'Cancel this payment',
            cancelAllRecurringPayments: 'Cancel all payments',
            retryPayment: 'Change payment method',
            markAsPaid: 'Mark bill as paid',
            cancelRecurringPayment: 'Cancel this payment',
            retryPaymentAction: 'Reschedule payment',
            close: 'Close',
            markAsUnpaid: 'Mark as unpaid',
            resolvePayment: 'Resolve payment',
            askVendorForDetails: 'Ask vendor for details',
          },
          editLink: 'Edit',
          futureProcessedDate: 'Payment will be deducted on',
          recurringFutureProcessedDate: 'First payment will be deducted on',
          processedDate: 'Payment was processed on',
          paidFromMy: 'Paid from',
          payFromMy: 'Pay from',
          vendorReceive: 'Vendor receives',
          vendorReceived: 'Vendor received',
          logs: {
            actionDate: 'On {date}',
            approved: {
              title: 'Approved by',
            },
            declined: {
              title: 'Declined by',
              reasonTitle: 'Decline reason or note',
            },
            scheduled: {
              title: 'Scheduled by',
            },
          },
          paymentsMade:
            '{count} {count, plural, one {payment} other {payments}} made (<private>{amount, number, currency}</private>)',
          recurring: {
            dueDate: 'Deduction date',
            etaDate: 'Arrival date',
            review: 'Review',
            current: 'Current',
          },
          dialog: {
            cancel: {
              title: 'Cancel payment?',
              subtitle: 'You asked to cancel a {amount} payment to {companyName}.',
              confirm: 'Yes',
              cancelText: 'Close',
              removalFailedTitle: 'This payment method can’t be deleted.',
            },
            cancelRecurringPayment: {
              title: 'Cancel this payment',
              subtitle:
                'Are you sure you want to cancel this payment of {amount} to {companyName}? The remaining recurring payments will continue as scheduled.',
              disclaimer: '<b>Note</b>: This can’t be undone.',
              confirm: 'Cancel payment',
            },
            cancelAllPayments: {
              title: 'Cancel all payments?',
              subtitle:
                'Are you sure you want to cancel all recurring payments of {amount} to {companyName}?',
              disclaimer: '<sb>Note:</sb> This cannot be undone.',
            },
            delete: {
              title: 'Delete payment?',
              subtitle:
                'You asked to delete a {amount} payment associated with your vendor {companyName}.',
              confirm: 'Delete',
              cancelText: 'Close',
              removalFailedTitle: 'This payment method can’t be deleted.',
            },
          },
        },
        deleteDialog: {
          title: 'Delete bill?',
          subtitle:
            'You asked to delete a {totalAmount} bill associated with your vendor {companyName}.',
          confirm: 'Delete',
        },
        markAsPaidDialog: {
          dialogTitle: 'Which account was used for this payment?',
          intuitAccountLabel: 'Bank account in QuickBooks',
          intuitAccountPlaceholder: 'Select account',
          dialogOkButtonText: 'Save',
        },
        blockedForPaymentDialog: {
          title: 'Blocked at vendor’s request',
          subtitle:
            'This vendor has requested they not be paid with Melio while the NYSLA evaluates Melio’s credit card payment feature.{br}{br} Feel free to contact them or our support team at {supportEmail} for further clarification.',
        },
        declineDialog: {
          title: 'Payment declined.{br}Let {userName} know why you’ve declined this payment.',
          noteTitle: 'Decline reason or note',
          placeholder: ' ',
          buttonLabel: 'Submit and decline',
        },
        nonDeletableDialog: {
          title: 'We’re sorry, this bill can not be deleted',
          subtitle:
            'This bill can’t be deleted since payment for it is already in progress.{br}{br}Please contact support by chatting with us or emailing {supportEmail}',
          ok: 'Ok. Got it',
        },
      },
      timeline: {
        qbcash: {
          collected: 'Collected',
          'in-progress': 'Sent',
          processed: 'Sent',
          completed: 'Deposited',
        },
        ach: {
          collected: 'Collected',
          'in-progress': 'In transit',
          processed: 'In transit',
          completed: 'Deposited',
        },
        plaid: {
          collected: 'Collected',
          'in-progress': 'In transit',
          processed: 'In transit',
          completed: 'Deposited',
        },
        'virtual-card': {
          collected: 'Collected',
          'in-progress': 'Sent',
          processed: 'Sent',
          completed: 'Cleared',
        },
        card: {
          collected: 'Collected',
          'in-progress': 'In transit',
          processed: 'In transit',
          completed: 'Deposited',
        },
        manual: {
          collected: 'Collected',
          'in-progress': 'Sent',
          processed: 'Sent',
          completed: 'Deposited',
        },
        check: {
          collected: 'Collected',
          'in-progress': 'Sent',
          processed: 'Sent',
          completed: 'Deposited',
        },
      },
      status: {
        paymentScheduled: 'Payment scheduled',
        paymentPaid: 'Fully paid',
        paymentManuallyPaid: 'Marked as paid',
        paymentFailed: 'Payment failed',
        paymentPendingApproval: 'Pending approval',
        paymentDeclined: 'Not approved',
        paymentFailedActionRequired: 'Action required',
        paymentFailedResend: 'Resend payment',
        cantSchedulePayment: {
          title: 'Can’t schedule a payment',
          description:
            '{vendorName} can not receive your payment at the moment. Please contact support.',
        },
        paymentReturnedCheck: 'Check was not delivered',
        failedToDeliverACHRetry: "Funds were deducted but payment wasn't delivered",
        paymentRefundScheduled: {
          refundSent: 'Refund sent',
          refundProgress: 'Refund in progress',
          messageTitle: 'Refund status',
        },
        paymentRefundFailed: {
          title: 'Refund Failed',
          messageTitle: `We weren't able to deliver your refund`,
          messageTitleChanges: 'Refund status changes',
          note: 'Failed on {date}',
        },
        paymentRefundCompleted: {
          title: 'Refund completed',
        },
        paymentProcessing: 'Payment’s on the way',
      },
      view: {
        title: 'Bill details',
        mobileTitle: 'Payment details',
        headerTitle: 'New bill',
        noInvoiceNumber: 'No invoice number',
        due: 'Due on {date}',
        scheduledOn: 'Scheduled for {date}',
        deliveryEta: 'Delivery ETA {date}',
        pendingInformation: 'Pending vendor’s information',
        partiallyPaid: 'Total bill amount',
        processed: 'Paid on {date}',
        invoiceNumber: 'Invoice #{invoiceNumber}',
        recurringBillInfo: 'Recurring-{frequency} {index} of {occurrences}',
        markAsPaidInfo: 'Marked as paid on {date}',
        markAsPaidAcountInfo: 'Paid with {account}',
        paymentDetails: 'Payment details',
        refundDetails: 'Refund details',
        recurringPaymentDetails: 'Recurring payment details',
        error: {
          titleNotFound: ' Sorry, we could not locate the bill',
          subtitleNotFound:
            'You’re probably looking for something else. You can find this payment’s details in your QuickBooks account.',
          buttonTextNotFound: 'Ok. Take me back',
          buttonTextBillNoMelioFound: 'Try online Bill Pay',
          notMelioBillTitle: 'This bill wasn’t paid online',
          notMelioBillSubtitle:
            'Once you pay a bill online, you will be able see the online payment details on this page. To make an online payment, go to a bill page and look for the “Pay bill online” button.',
          notSupportedVendorTitle: 'This vendor cannot be paid with Bill Pay',
          notSupportedVendorSubTitle:
            'At the moment this vendor is not supported for payments with Bill Pay',
          notSupportedVendorSubButton: 'Ok, Take me back',
          wrongAccountTitle: 'You’re logged in to another account',
          wrongAccountSubtitle:
            'Return to QuickBooks and switch to this bill’s account to continue.',
          buttonTextWrongAccount: 'Return to QuickBooks',
        },
      },
      request: {
        description:
          '<b>{vendorName}</b> has sent you a payment request of <private><b>{amount, number, currency}</b></private> for invoice <b>{invoiceNumber}</b>.',
        trustVendor: 'Add their future payment requests too',
        addButton: 'Add bill to inbox',
        removeButton: 'Remove',
        removeNotification: 'Payment request removed {undo}',
        note: 'This bill has been added to your inbox because <b>{vendor}</b> requested a payment from you. You can reject the bill if you don’t recognize it.',
      },
      edit: {
        title: 'Bill - edit mode',
        cancel: 'Cancel',
        save: 'Save',
      },
      pay: {
        sectionTitle: 'Paying {companyName} for invoice {invoiceNumber}',
        amount: {
          title: 'Confirm amount to pay',
          notice: 'Bill total ',
        },
        deliveryMethods: {
          title: 'How do you want the payment sent to <b>{vendor}</b>?',
          unilateralLink: 'Ask vendor for payment details',
          unilateralHint: 'Send an email to the vendor',
        },
        editAchDeliveryMethod: {
          title: 'Please edit <em>{vendor}</em>’s bank account details',
          save: 'Save new bank details',
          continue: 'Continue',
          routingNumberPlaceholder: 'Enter 9 digit code',
          accountNumberPlaceholder: 'Enter 6 to 17 digit account number',
          footerText:
            'These details will be used for future payments to this vendor. You can always edit them in your vendors section.',
          failedToDeliverACH: {
            R03: 'The previously entered bank details are incorrect: {br} <ul><li>Routing number: <sb>{routingNumber}</sb></li><li>Account number: <sb>{accountNumber}</sb></li></ul>',
            R04: 'The previously entered account number is incorrect: <sb>{accountNumber}</sb>',
          },
        },
        date: {
          title: 'When would you like this payment to be deducted?',
          subtitle: 'Choose a deduction date for this payment',
          fastACHExperiment: {
            onTimeTitle: 'Get your payment delivered on time',
            onTimeSubtitle:
              'Your payment is scheduled to arrive after it’s due date ({date}). To make sure it’s on time, switch to have it arrive today.',
            deliverSoonerTitle: 'Deliver your payment sooner',
            deliverSoonerSubtitle:
              'Your payment is scheduled to arrive after it’s due date ({date}). To avoid additional delays and deliver it sooner, switch to fast delivery.',
          },
          deliveryMethodBank: 'Bank transfer',
          deliveryMethodCheck: 'Paper check',
          dueDate: 'Bill due date',
          federalBankHoliday: 'Federal Bank Holiday',
          processDate: 'When you pay',
          shortProcessDate: 'Process date',
          deliveryDate: 'When vendor receives',
          buttons: {
            primary: 'Continue',
            secondary: 'Edit deduction date',
          },
          ach: {
            deliveryMethod: '(by 8 PM vendor’s local time)',
          },
          creditCardBenefits: {
            modal: {
              title: 'Choose a card',
              subTitle: 'Select which credit card you want to use for this payment (2.9% fee).',
              button: 'Continue',
              tile: '{institutionName, select, undefined{} other{{institutionName} }} ...{identifier}',
            },
            bottomBanner: {
              title: {
                today: 'Deliver it today, pay later',
                tomorrow: 'Deliver it tomorrow, pay later',
                future: 'Deliver it faster, pay later',
              },
              noCard: {
                label: {
                  today: 'Pay by card to defer your payment and have it delivered today.{br}{link}',
                  tomorrow:
                    'Pay by card to defer your payment and have it delivered tomorrow.{br}{link}',
                  future: 'Pay by card to defer this payment and get faster delivery.{br}{link}',
                },
                link: 'View benefits',
              },
              oneCard: {
                label: {
                  today:
                    'Pay with your {institutionName, select, undefined{} other{{institutionName} }} ...{identifier} to defer payment and have it delivered today.{br}{link}',
                  tomorrow:
                    'Pay with your {institutionName, select, undefined{} other{{institutionName} }} ...{identifier} to defer payment and have it delivered tomorrow.{br}{link}',
                  future:
                    'Pay with your {institutionName, select, undefined{} other{{institutionName} }} ...{identifier} to defer payment and get faster delivery.{br}{link}',
                },
                link: 'Pay by card',
              },
              multipleCards: {
                label: 'Pay by card instead to defer payment and get faster delivery.{br}{link}',
                link: 'Pay by card',
              },
            },
            notification: 'Successfully changed payment method to credit card.',
          },
          deliveryOptions: {
            price: {
              default: '(Free)',
              dollar: '({fee})',
              defaultCheck: 'via <b>USPS</b> (Free)',
              dollarFedExCheck: 'via <b>FedEx</b> ({fee})',
              dollarUSPSCheck: 'via <b>USPS</b> ({fee})',
              fastTag: 'Fast',
              onTimeTag: 'On Time',
            },
            fastAchDates: {
              today: 'Today, ',
              date: '{date}',
              free: '{date}',
            },
            subTitle: {
              'expedited-ach': 'Same day delivery',
              'expedited-ach-today': 'Today',
              'expedited-ach-tomorrow': 'Tomorrow',
              'expedited-ach-on-date': '{date}',
              'overnight-check': 'Next business day',
              'express-check': '3 business days',
              expressCheckWithIndication: '{indication} 3 business days',
              ach: '3 business days',
              'ach-tomorrow': 'Tomorrow',
              'ach-on-date': '{date}',
              achBelow100K: '3 business days',
              achOver100K: '4 business days',
              achDebit: '1 business day',
              achCredit: '1 business day',
              rppsCreditOrDebit: '2 business days',
              rppsAch: '4 business days',
              check: '5-7 business days',
              virtual: '3-7 business days',
              'virtual-card': '3 business days',
              virtualCardCreditOrDebit: '1 business day',
              international: '4 business days',
            },
            descriptions: {
              'expedited-ach-today': 'Same business day ',
              'expedited-ach-tomorrow': 'Next business day ',
              'expedited-ach-on-date': 'Same business day ',
              'ach-tomorrow': 'Next business day ',
              'ach-on-date': 'Same business day ',
              ach: '3 business days',
            },
            title: {
              today: 'Today',
              tomorrow: 'Tomorrow',
              date: '{date}',
            },
            description: {
              sameDay: 'Same business day',
              nextDay: 'Next business day',
            },
            deliveryExplanationCheck: '{indication} Arriving on {date}',
            deliveryExplanationCheckIndication: 'Trackable.',
            sameDayDeliveryExplanationCheck: 'Arriving on today ',
            default: 'Free',
          },
          check: {
            deliveryMethod: 'Paper checks take 5-7 business days',
          },
          scheduledDateEndOfLifeNotificationFirstWave:
            'We’re introducing the new QuickBooks Bill Pay soon. You cannot schedule payments beyond Jan 4, 2024 with Bill Pay powered by Melio. <learnMoreLink>Learn more</learnMoreLink>',
          scheduledDateEndOfLifeNotificationSecondWave:
            'We’re introducing the new QuickBooks Bill Pay soon. You cannot schedule payments beyond May 22, 2024 with Bill Pay powered by Melio. <learnMoreLink>Learn more</learnMoreLink>',
          billDueDate: 'Bill due {date}',
          billScheduledDate: 'Deduction {date}',
          cardExpiredLabel: '{cardNetwork} ...{card4digits} expires in {expirationDate}',
          estimatedDelivery: 'Estimated payment delivery',
          deliverySpeed: 'Choose when this payment arrives',
          unilateralPaymentDescription:
            'You’ll be updated with a final delivery date once payment details are received.',
          paymentDatePickerDescription: {
            unverifiedBankAccount:
              'To pay on schedule: verify your bank account {bankAccount} by <sb>{date} at 12pm EST</sb>',
            unverifiedBankAccountApprovalRequired:
              'To pay on schedule: verify your bank account {bankAccount} and make sure this payment is admin approved by <sb>{date} at 12pm EST</sb>',
            approvalRequired:
              'To pay on schedule: make sure this payment is admin approved by <sb>{date} at 12pm EST</sb>',
            unilateralApprovalRequired:
              'To pay on schedule: make sure this payment is admin approved and that your vendor selects how to receive payments by <sb>{date} at 12pm EST</sb>',
            unilateral:
              'To pay on schedule: your vendor needs to select how to receive payments by <sb>{date} at 12pm EST</sb>',
            dateETA: 'Final ETA may be subject to banking holidays',
          },
        },
        fundingSource: {
          title: 'Choose how you want to pay',
          subtitle: 'Vendor receives a bank deposit or check for free',
          recurringSubtitle: 'You’re paying a {amount} recurring payment to {companyName}',
          partialPayment: {
            title: '<sb>Pay {companyName}</sb>',
            subtitle: 'Choose how you want to pay:',
          },
          combined: {
            subtitle:
              'What you pay with and how it’s sent to the vendor doesn’t need to be the same. For example, pay with a card and send a bank transfer.',
            totalAmount: 'Total amount',
            warnings: {
              noFundingSource: 'First, choose what you want to pay with.',
              preselectedDeliveryMethod:
                'The vendor has already chosen how they want to receive payment.',
            },
          },
          notification: {
            recurring: {
              label:
                'Changes will only apply to this payment. You can edit the other recurring payments once they have been scheduled',
            },
          },
          creditLabel:
            '{institutionName, select, undefined{} other{{institutionName} }} ...{identifier}',
          debitLabel:
            '{institutionName, select, undefined{} other{{institutionName} }} ...{identifier}',
          selectedDebitLabel: 'Using debit card ...{identifier}',
          achLabel:
            '{institutionName, select, undefined{} other{{institutionName}, }}{displayName} ...{identifier}',
          creditDescription: 'Credit. {fees_credit_value}% Fee',
          freeFeeDescription: 'Free for this payment!{br} Defer this payment and earn card rewards',
          debitDescription: 'Free',
          achDescription: 'Use your bank account to schedule payments (free).',
          expired: ' Expired',
          expiredDescription: 'Please add a new card',
          expirationDateLabel: ' Expires {expirationDate}',
          warningMessage:
            'FYI - the selected payment method couldn’t transfer a payment on {date}. Make sure that issue has been resolved before you continue.',
          syncErrorDialog: {
            title: 'There was a technical issue',
            subtitle:
              'Sorry! We ran into a technical issue and are looking into it. If you need additional help, please contact our support team.',
            okButton: 'Contact support',
            cancelButton: 'Close',
          },
        },
        feeFundingSourcePage: {
          ctaButton: 'Save',
          title: 'Choose a payment method to use for check fees',
          smbSubtitle:
            'The method you choose will be used for any remaining and future check fees.',
          firmSubtitle:
            'Currently, paper check fees are covered by your accountant’s payment method',
        },
        blockedInternationalPage: {
          title: 'International payments not supported',
          subtitle:
            'Temporarily, international payments are unavailable for businesses registered in your state.',
          cancelButton: 'Close',
        },
        memo: {
          title: 'Leave a memo for your vendor',
          inputTitle: 'Memo',
          placeholder: 'Account ID#, payment description',
          hint: 'This will appear on checks and payment notifications',
        },
        expedite: {
          title: 'Have you received the goods or services you’re paying for?',
          subtitle:
            'Please specify whether you’ve already received the goods or services related to this payment.',
          meliomeSubtitle:
            'Please specify whether you’ve already received the goods or services related to this payment.',
          dragAndDrop: 'Include a file attachment',
        },
        confirm: {
          title: 'Review & confirm',
          addMemo: 'Memo to vendor',
          emptyMemoLabel: 'No memo to vendor',
          placeholder: 'Add memo to vendor here',
          amountField: 'Bill total',
          vendorField: 'Vendor',
          businessName: 'Business name',
          accountIdentifier: 'Account number',
          frequencyField: 'Payments arrive on',
          recurringWeeklyHint: 'Every {specificDay} for {occurrences} weeks',
          recurringMonthlyHint: 'Day {specificDay} of every month',
          occurrencesField: 'Number of Payments',
          occurrencesViewField: 'Number of Payment',
          occurrencesCurrent: '{current} of {payments}',
          frequency: '{frequency}',
          total: 'Amount',
          payTo: 'Pay to',
          paymentId: 'Payment ID: {paymentId}',
          paidBy: 'Paid by',
          invoiceNumberField: 'Invoice #',
          billNumberField: 'Bill #',
          deliveryMethodField: 'Delivery method',
          dateField: 'Process date',
          deliveryDate: 'Delivery ETA',
          fundingSourceField: 'Payment method',
          memoField: 'Memo',
          ach: 'Bank transfer',
          check: 'Paper check',
          fee: 'Transaction fee',
          feeLabel: '({amount} fee)',
          feeHint: `({finalAmount, select,
              0 {}
              other {Originally <private>{baseAmount, number, currency}</private>. }
            }Saving <private>{savingAmount, number, currency}</private> in Credit Card fees.{expiration, select,
              null {)}
              other {{br} Applies for payments processed by {expiration, date, medium})}
            }`,
          feeTerm: 'Credit card fee is {fees_credit_value}% and is billed separately.',
          feePromoBase: '{basePercent, number, percent} credit card fee applies',
          feePromoReduction: `{promotionName, select,
            COVID_APRIL{April COVID-19 promotion}
            AMEX_STAND_FOR_SMALL{Stand For Small promotion}
            other{Promotion}
          }`,
          feePromoReductionHint: 'Applies for payments processed by {expiration, date, medium}',
          absorbedFee: 'Free for this payment!',
          totalFee: 'Total fee',
          'expedited-ach': {
            feeDetails: 'Same day delivery ({fee}% fee)',
            feeDetailsNoPercentage: 'Same day delivery',
            today: 'Today, ',
            totalFeeDescription: 'Charged separately to your account',
          },
          creditCardFee: 'Credit card fee <private>{fee}</private>',
          debitCardFee: 'Debit card fee <private>{fee}</private>',
          internationalFee: 'International payments',
          'overnight-check': {
            feeDetails: 'Next business day delivery',
          },
          'express-check': {
            feeDetails: '3 business days delivery',
          },
          edit: 'Edit',
          action: 'Confirm and schedule payment',
          confirmTerms:
            'By clicking confirm and schedule I give permission to charge my {type} on {date} for {amount}',
          error: {
            title: 'This payment can’t be scheduled',
            CTA: 'OK',
            approvingPaymentTitle: 'Sorry, something went wrong',
            approvingPaymentSubtitle:
              'We had trouble receiving the data to approve your payment in real-time. We’ve saved your payment details and we’ll send you an email as soon as the payment is approved.',
            approvingPaymentCTA: 'Ok. I’ll check back later',
          },
          completeLegalInfo: {
            title: 'One last thing...',
            titleLoading: 'Scheduling payment...',
            subtitle: 'We just need this once to verify your business before making payments.',
            subtitleLoading: 'it’ll just be a minute',
            action: 'Save and schedule payment',
            legalName: {
              inputTitle: 'Your business’s legal name',
              emptyLabel: 'No legal business name',
              inputHint: 'Your full legal business name as it appears on your tax return',
            },
            legalAddress: {
              inputTitle: 'Legal address',
              emptyAddressLabel: 'No legal business address',
              inputHint: 'Search and select an address from the list',
            },
            taxId: {
              inputTitle: 'Tax ID number',
              emptyLabel: 'No Tax ID',
            },
            taxIdType: {
              inputTitle: 'Select your tax ID type',
              emptyLabel: 'No Tax ID type',
            },
            internationalTitle: 'Add your business details',
            internationalSubtitle:
              'We just need this once to verify your business before making international payments.',
            contactFirstName: 'First name',
            contactLastName: 'Last name',
            dateOfBirth: {
              inputTitle: 'Date of birth',
              placeholder: 'MM/DD/YYYY',
            },
            emptyLabelFirstName: 'No first name',
            emptyLabelLastName: 'No last name',
            emptyDateOfBirth: 'No date of birth',
            companyAddress: { inputTitle: 'Company address' },
            phone: {
              placeholder: 'Phone number',
              emptyLabelPhone: 'No phone number',
            },
          },
          balanceConfirm: {
            title: 'Payment exceeds current balance',
            description:
              'This payment exceeds the current balance of your QuickBooks Checking account. Please make sure your account has enough funds for this payment so that it doesn’t fail.',
            confirm: 'Schedule anyway',
            cancel: 'Edit payment',
          },
          undepositedCheck: {
            early: 'This check was sent but wasn’t deposited yet.',
            overdue:
              'This check was sent but wasn’t deposited for 30 days. To try again, you can <ctaLink>void check and resend payment</ctaLink>.',
            cta: 'Void check and resend payment',
          },
          checkFee: {
            title: 'Paper check fee',
            promotionsLeft:
              'You currently have <sb>{available}</sb> of <sb>{total}</sb> monthly free checks left',
            recurring: {
              twoLeft: 'The first 2 checks sent are free, checks after incur a {fee} fee',
              oneLeft: 'The first check sent is free, checks after incur a {fee} fee',
              noneLeft: 'Each check sent for this recurring payment will incur a {fee} fee',
            },
            feeCharge: 'Charged separately to your account',
            noCharge: 'No additional fees',
            firmFee: 'Billed separately to the payment method for paper checks',
          },
        },
        success: {
          titles: {
            regular: 'Payment scheduled!',
            recurring: '{frequency} payment {br} scheduled!',
            pendingApproval: 'Payment scheduled & pending approval!',
            fastAch: '<b>Expedited payment scheduled!</b>',
          },
          subtitles: {
            'expedited-ach': {
              regular:
                'Your <b>{amount}</b> expedited payment was successfully scheduled to be processed on <b>{processDate}</b>.',
              pendingApproval:
                'Your <b>{amount}</b> expedited payment to <b>{vendorName}</b>{br}was successfully scheduled and is pending your admin approval.',
            },
            regular:
              'Your <b>{amount}</b> payment to <b>{vendorName}</b> was successfully scheduled.',
            pendingApproval:
              'Your <b>{amount}</b> payment to <b>{vendorName}</b>{br}was successfully scheduled and is pending your admin approval.',
            recurringPendingApproval:
              'Your <b>{amount}</b> recurring payment to <b>{vendorName}</b>{br}was successfully scheduled and is pending your admin approval.',
            recurring:
              'A <b>{amount}</b> recurring payment to <b>{vendorName}</b> has been successfully scheduled.{br}A summary has been sent to your email.',
            fastAch: {
              regular: 'Your <b>{amount}</b> to <b>{vendorName}</b> was successfully scheduled.',
            },
          },
          notes: {
            title: '<b>What’s next?</b>',
            unilateral:
              'This payment’s estimated delivery date is between {deliveryDate} but is dependent on <b>{vendorName}</b> selecting their preferred payment delivery method.',
            unilateralPendingApproval:
              'This payment’s estimated delivery date is between <b>{deliveryDate}</b> but is dependent on the payment’s approval by an Admin. Once approved, <b>{vendorName}</b> will be asked to enter their payment delivery details.{br}{br} After the payment has been approved and then finalized by <b>{vendorName}</b> with delivery details, you’ll be updated with an accurate payment delivery ETA.',
            microDeposit:
              'While scheduled, this payment will not be processed until you’ve verified your bank account (micro-deposits are usually sent within two business days).',
            microDepositPendingApproval:
              'While scheduled, this payment will not be processed until it’s approved by an Admin and you’ve verified your bank account (micro-deposits are usually sent within two business days).',
          },
          buttonLabel: {
            done: 'Done',
            doneHere: 'I’m done here',
            toDashboard: 'Go to dashboard',
            notifyMyVendor: 'Notify my vendor',
            notifyVendor: 'Notify vendor',
            later: 'Maybe later',
          },
          doneHere: 'I’m done here',
          regular: {
            title: 'Payment scheduled!',
            subtitle:
              'Your {amount} payment to {vendorName} was successfully scheduled and the bill was marked as paid.{br}To edit/cancel the payment go to the corresponding Bill Payment record and click “View online payment”',
          },
          notVerified: {
            title: 'Payment scheduled!',
            subtitle:
              'Your {amount} payment has been set to be processed on {processDate}.{br}To edit/cancel the payment go to the corresponding Bill Payment record and click “View online payment”{br}{br}Please note that while scheduled, our payment process is waiting for your bank’s verification. This typically takes 2 business days. Once verified, payment can begin right away. This delay applies to this first payment only.',
          },
          recurring: {
            subtitle:
              'A {amount} recurring payment to {vendor} was successfully scheduled. {br} A summary has been sent to your email.{additionalSubtitle}',
          },
        },
        payBillSuccess: {
          titles: {
            regular: 'Payment scheduled!',
            regularSuccess: 'Payment successfully scheduled!',
            recurring: '{frequency} payment {br} scheduled!',
            nextQboBillToPay: 'Your next payment is ready to go!',
          },
          subtitles: {
            regular:
              'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb> is scheduled for <b>{processDate}</b>.',
            regularWithNotify:
              'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb> is scheduled for <b>{processDate}</b>. We’ll notify them once it’s on the way.',
            recurring:
              'Your <sb>{amount}</sb> recurring payment to <sb>{vendorName}</sb> is scheduled for <sb>{processDate}</sb>.',
            recurringWithNotify:
              'Your <sb>{amount}</sb> recurring payment to <sb>{vendorName}</sb> is scheduled for <sb>{processDate}</sb>. We’ll notify them once it’s on the way.',
            expedited:
              'Your <sb>{amount}</sb> expedited payment was successfully scheduled to be processed on <sb>{processDate}</sb>.',
            pendingApproval:
              'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb> is scheduled for <sb>{processDate}</sb>. We’ve sent an email to admins of <b>{companyName}</b> asking them to approve this payment.',
            qbCash:
              'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb> was successfully scheduled and will process on <sb>{processDate}</sb>.{br}{br}That’s also when your balance will be updated.',
            qbCashWithNotify:
              'Your <sb>{amount}</sb> payment to <sb>{vendorName}</sb>{br} was successfully scheduled and will process on <sb>{processDate}</sb>.{br}{br}That’s also when your balance will be updated.',
            notification:
              'For this payment to transfer smoothly, please make sure there are available funds in your QuickBooks Checking account by <sb>{processDate}, at 12pm EST.</sb>',
            nextQboBillToPay:
              'Now that you successfully scheduled your first payment, you can jump ahead and take care of the next one.',
            askToNotify: 'Want to notify and let them know?',
          },
          notifications: {
            qbCash: {
              subtitle:
                'For this payment to transfer smoothly, please make sure there are available funds in your QuickBooks Checking account by <sb>{processDate}, at 12pm EST.</sb>',
            },
            qbCashAndPaymentNotApproved: {
              title: '<sb>Important notes!</sb>',
              subtitle: `<list>
                <listItem>For this payment to transfer smoothly, please make sure there are available funds in your QuickBooks Checking account by <sb>{processDate}, at 12pm EST.</sb></listItem>
                <listItem>Your payment is pending approval from your organization admin. We’ll email you when it’s approved and processed.</listItem>
              </list>`,
            },
            paymentNotApproved: {
              title: '<sb>Important note!</sb>',
              subtitle:
                'Your payment is pending approval from your organization admin. We’ll email you when it’s approved and processed.',
            },
          },
          notes: {
            qbo: 'This payment will be synced to your QuickBooks',
          },
          buttonLabel: {
            done: 'I’m done here',
            notifyVendor: 'Notify vendor',
            notifyVendors: 'Notify vendors',
            notifyMyVendor: 'Notify my vendor',
            later: 'Maybe later',
            qbmFlowDone: 'Done',
            viewQBDashboard: 'View my Online Bill Payments',
            backQBDashboard: 'Back to my Online Bill Payments',
          },
          doneHere: 'Thanks, I’m done here',
        },
        requestRefund: {
          reason: {
            title: 'Request a refund for ${amount}',
            errorMessage: 'Choose a reason from the list to continue',
            dropdownTitle: 'Tell us why',
            placeholder: 'Select reason',
            continueButton: 'Continue',
            reasons: {
              incorrectVendorDetails: 'Incorrect vendor details',
              incorrectPaymentAmount: 'Incorrect payment amount',
              paidViaAnotherProvider: 'Paid via another provider',
              switchingPaymentMethod: 'Switching payment method',
              goodsServicesIssues: 'Issues with vendor goods/services',
              badCustomerExperience: 'Bad customer experience',
              other: 'Other',
            },
          },
          review: {
            title: 'Review & confirm your refund',
            amount: 'REFUND AMOUNT',
            to: 'REFUND TO',
            toCompleted: 'REFUNDED TO',
            traceNumber: 'Trace #{achTraceNumber}',
            bankAccount: 'Bank account',
            eta: 'Refund ETA by',
            etaSubtitle: 'It can take up to 5 business days before you see the funds',
            by8PM: 'By 8 PM local time',
            reason: 'Refund request reason',
            refundButton: 'Send refund request',
            getRefundButton: 'Get a refund',
            requestedDate: 'Refund request date',
            partialPayments: {
              title: 'Bill #',
            },
            retryLink: 'Don’t want a refund? <retryLink>Retry the payment</retryLink>',
          },
          modalMessage: {
            title: 'Requesting a refund?',
            subtitle: 'Reach out to Melio support for assistance.',
            buttonLabel: 'Chat with Support',
          },
        },
        collectVendorEmailModal: {
          title: 'Let {vendorName} know the payment is on its way!',
          successTitle: 'Email sent!',
          successSubTitle:
            'We also added this email to your vendor’s details so you won’t have to worry about next time.',
          confirm: 'Done',
          subTitle:
            'Once your payment goes out we’ll email {vendorName} with your payment’s details to let them know when and how to expect it.',
          pending:
            '<b>Note:</b> This email will be sent only after the payment is approved by your admin.',
          sendEmail: 'Send email notification',
          emailLabel: 'Vendor’s business email',
          emailQboLabel: 'Vendor’s email address',
          emailPlaceholder: 'Email address',
          qboSubTitle:
            "Once the payment is processed, we’ll send them an email with the payment’s details, so they'll know when to expect its arrival.",
          qboSuccessSubTitle:
            'We’ve also added this email to your vendor’s details. You can always change or edit it in your settings.',
        },
        virtualCardRecovery: {
          title: 'How would you like to resend the money to <b>{vendor}</b>?',
          ach: {
            title: 'Switch to bank transfer (ACH)',
            description: 'Free. No additional fee. Same day delivery.',
            info: 'Deposit funds into your vendor’s bank account',
          },
          virtualCard: {
            title: 'Send a new single-use card',
            description: 'Free. Instant delivery.',
          },
          unilateral: {
            title: 'Ask vendor for delivery details',
            description: 'Send an email to the vendor',
          },
        },
        billAlreadyScheduled: {
          paid: {
            title: 'This bill is already paid',
            subTitle:
              'The bill for <sb>{amount}</sb> to <sb>{vendorName}</sb> is already marked as paid. You can view the the bill in the <sb>Paid</sb> tab of your Online Payments.',
          },
          scheduled: {
            title: 'Payment for this bill already scheduled',
            subTitle:
              'The bill for <sb>{amount}</sb> to <sb>{vendorName}</sb> already has a scheduled payment. You can view the the status of the bill in the <sb>Scheduled</sb> tab of your Online Payments.',
          },
          processed: {
            title: 'This bill is already paid',
            subTitle:
              'The bill for <sb>{amount}</sb> to <sb>{vendorName}</sb> was already paid. You can view the the bill in the <sb>Paid</sb> tab of your Online Payments.',
          },
          failed: {
            title: 'There was an issue processing bill payment',
            subTitle:
              'The payment for the bill of <sb>{amount}</sb> to <sb>{vendorName}</sb> was already scheduled, but the payment could not be processed. You can view and recover it from the <sb>Unpaid</sb> tab of your Online Payments.',
          },
          deleted: {
            title: 'This bill has been deleted',
            subTitle:
              'The bill for <sb>{amount}</sb> to <sb>{vendorName}</sb> was deleted. You can view all your open bills the <sb>Unpaid</sb> tab of your Online Payments.',
          },
          buttonLabel: 'Go to Online payments',
        },
        billZeroBalance: {
          title: 'There’s nothing left to pay',
          scheduled: {
            subTitle:
              'The bill for <sb>{amount}</sb> to <sb>{vendorName}</sb> already has scheduled payments for its total amount. Go to Online payments to view the status of the payments in the <sb>Scheduled</sb> tab.',
          },
          processed: {
            subTitle:
              'The total amount for this bill of <sb>{amount}</sb> to <sb>{vendorName}</sb> is already paid. Go to Online payments to view the status of the payments in the <sb>Paid</sb> tab.',
          },
          paid: {
            subTitle:
              'This bill for <sb>{amount}</sb> to <sb>{vendorName}</sb> is already marked as paid. Go to Online payments to view the status of the payments in the <sb>Paid</sb> tab.',
          },
          failed: {
            subTitle:
              'The total amount for this bill of <sb>{amount}</sb> to <sb>{vendorName}</sb> is already marked as paid, but one of the payments is still unresolved. Go to Online Payments to resolve the issue.',
          },
          buttonLabel: 'Go to Online payments',
        },
        returnedCheckRecovery: {
          stepChooseDM: {
            title: 'How would you like to resend the payment to <b>{vendorName}</b>?',
            recommended: 'recommended',
            ach: {
              title: 'Send ACH bank transfer',
              description: '3 business days. No additional cost.',
            },
            check: {
              title: 'Review address & resend check',
              description: '5-7 business days. No additional cost.',
            },
          },
          stepMissingACHDetails: {
            title: 'What are <b>{vendorName}</b> bank details?',
            inputRoutingNumberLabel: `Vendor's routing number (ACH)`,
            inputAccountNumberLabel: `Vendor's account number`,
            buttonText: 'Save bank details',
          },
          stepRecoverByCheck: {
            reviewAddressDetails: {
              notificationText:
                'A check was already sent to the address below and returned. Please make sure that the details are correct.',
            },
            chooseDeliveryDates: {
              formTitle: 'CHOOSE DELIVERY SPEED',
              buttonSave: 'Continue',
            },
          },
        },
        voidCheckRecovery: {
          stepChooseDM: {
            title: 'How would you like to resend the payment to <b>{vendorName}</b>?',
            recommended: 'recommended',
            ach: {
              title: 'Change to bank transfer (ACH)',
              description: '3 business days. No additional cost.',
            },
            check: {
              title: 'Paper check',
              description: '5-7 business days. No additional cost.',
              submit: 'Save address and continue',
            },
          },
          stepMissingACHDetails: {
            title: 'What are <b>{vendorName}</b> bank details?',
            inputRoutingNumberLabel: `Vendor's routing number (ACH)`,
            inputAccountNumberLabel: `Vendor's account number`,
            buttonText: 'Save bank details',
          },
          stepRecoverByCheck: {
            reviewAddressDetails: {
              notificationText:
                "A check was already sent to this address but wasn't deposited. Edit or review that the details are correct.",
            },
            chooseDeliveryDates: {
              formTitle: 'CHOOSE DELIVERY SPEED',
              buttonSave: 'Continue',
            },
          },
          dashboard: {
            tooltip: 'Check was sent but not deposited yet.{br}Click on view payment to resolve.',
          },
          voidedNotification: {
            title: 'Payment status',
            subtitle:
              'You voided check #{checkSerialNumber} on {date} and resent the payment as a {deliveryBy} to {deliveryTo}. View the new details below.',
            paperCheck: 'Paper check',
            ach: 'Bank transfer (ACH)',
          },
        },
        common: {
          recovery: {
            chooseDeliveryDate: {
              title: {
                onTime: 'Deliver this payment faster',
                late: 'Send a fast payment',
              },
              predicate: {
                regular: 'This bill’s due date is <sb>{dueDate}</sb>.',
                bulk: 'This payment’s earliest bill due date is <sb>{dueDate}</sb>.',
              },
              subtitle: {
                onTime: '{predicate}{br}Pay your vendor sooner with fast delivery.',
                late: '{predicate}{br}Avoid further delays. Pay your vendor sooner.',
              },
            },
          },
        },
      },
      approval: {
        approveSuccess: 'Payment approved and scheduled',
        declineSuccess: 'Payment declined',
      },
      labelPaidTab: {
        title: 'Don’t see all your paid bills?',
        subTitle: 'If you marked bills as paid on the web, head to the web to see them.',
      },
      refund: {
        chooseReason: {
          title: 'Request a refund for <b>{amount}</b>',
          dropdownTitle: 'Tell us why',
          dropdownPlaceholder: 'Select reason',
          dropdownError: 'Choose a reason from the list to continue',
          reasons: {
            incorrectVendorDetails: 'Incorrect vendor details',
            incorrectPaymentAmount: 'Incorrect payment amount',
            paidViaAnotherProvider: 'Paid via another provider',
            switchingPaymentMethod: 'Switching payment method',
            goodsServicesIssues: 'Issues with vendor goods/services',
            badCustomerExperience: 'Bad customer experience',
            other: 'Other',
          },
        },
        review: {
          title: 'Review & confirm your refund',
        },
        success: {
          title: 'Your refund’s on the way',
          titlePOPS: 'Refund request sent',
          subtitle:
            'We’ve sent a refund for <sb>${amount}</sb> to your bank account ending in <sb>{bankNumber}</sb>. It may take up to 5 business days before you see the funds.',
          subtitleNew:
            'We’ve sent a refund for <sb>${amount}</sb> to your bank account ending in <sb>{bankNumber}</sb>. It may take up to 5 business days before you see the funds. ',
          subtitlePOPS:
            'We’re processing your refund. It may take up to 5 business days before you see the funds in your account.',
          button: 'Track refund',
          buttonDetails: 'View refund details',
        },
      },
    },
    requests: {
      form: {
        paymentActivity: {
          youReceived: 'You received',
          title: 'Payment details',
          paymentETA: 'Payment ETA',
          recurringPaymentETA: 'First payment ETA',
          deliveryPaperCheckMethod: 'Paper check will be delivered to',
          deliveryBankTransferMethod: 'Bank transfer to',
          vendorReceive: 'You’ll receive',
          vendorReceived: 'You received',
          paymentDetails: 'Payment details',
          noteToCustomer: 'Note to customer',
          emptyNoteToCustomerLabel: 'No note to customer',
          noteFromCustomer: 'Note from customer',
          emptyNoteFromCustomerLabel: 'no note from customer',
          deliveredOn: 'Delivered on',
          failedPaymentLabel: 'Can’t receive this payment',
        },
      },
    },
    vendors: {
      title: 'Edit vendor',
      actions: {
        create: 'Create a vendor',
        newBill: 'New bill',
        newRequest: 'New request',
        unilateralRequest: 'Request payment details',
        edit: 'Edit contact',
        delete: 'Delete contact',
        save: 'Save',
      },
      emptyState: {
        vendorText: 'You have no vendors yet',
      },
      unilateralWithoutPayment: {
        successNotification: 'Request sent to vendor',
        ribbon: { recommended: 'Recommended' },
      },
      view: {
        title: 'Contact details',
        emptyIndicator: 'No contact info',
      },
      edit: {
        title: 'Edit vendor',
        submit: 'Edit vendor',
        done: 'Save',
        cancel: 'Cancel',
      },
      create: {
        sectionTitle: 'Add new contact',
        name: {
          title: 'A few quick details about your vendor',
          inputTitle: 'Business name',
          customerInputTitle: 'Customer’s company name',
          inputNotice: 'The name their business is registered under',
        },
        accountIdentifier: { inputTitle: 'Account no.' },
        address: { inputTitle: 'Vendor’s company address' },
        contact: {
          title: 'Who is your main contact there?',
          inputTitle: 'Contact full name',
        },
        email: {
          title: 'What’s their email address?',
          inputTitle: 'Business email',
          inputNotice: 'The email you usually use to contact this vendor',
        },
        phone: {
          title: 'What’s their phone number?',
          inputTitle: 'Phone number',
          inputNotice: 'This helps Melio enhance payment security',
        },
        verify: {
          title: 'Everything looking good?',
        },
        success: {
          title: 'Vendor added',
          subtitle:
            'New vendor has been successfully added to your network. You can now add bills and make payments to this vendor.',
          actions: {
            schedulePayment: 'Schedule a payment',
            done: 'Done',
            confirm: 'Save',
          },
        },
      },
      deliveryMethods: {
        title: 'Delivery methods',
        new: {
          section: 'Add delivery method',
          title: 'Choose delivery method',
        },
        virtualCard: {
          title: 'Single-use virtual card',
          titleTo: 'Single-use virtual card to {to}',
          subtitle: 'Free. Sent via email. <learnMoreLink>Find out more</learnMoreLink>',
          description:
            'Delivery by email to {email}{br}Added by the vendor on {date}. <learnMoreLink>Learn more about single-use cards</learnMoreLink>',
          addEmail: {
            title: 'What’s {vendor}’s email?',
            subtitle:
              'We’ll send them a single-use card to the email you enter below. They’ll still be able to change to a check or ACH if they want.',
            done: 'Save and continue',
            inputTitle: 'Vendor’s email',
            inputHint: 'We’ll send a single-use card to this email. Please make sure it’s correct.',
          },
        },
        bank: {
          sectionTitle: 'Add delivery method - Bank transfer',
          title: 'What are <em><ap>{vendor}</ap></em> bank details?',
          titleNoVendorAlternative: 'What are your vendor’s bank details?',
          titleRetryFailedToDeliver: "Update <sb>{vendor}</sb>'s bank details",
          routingNumber: 'Routing number (ACH)',
          routingNumberPlaceholder: 'Enter vendor’s routing number',
          accountNumber: 'Account number',
          accountNumberPlaceholder: 'Enter vendor’s account number',
          confirmAccountNumber: 'Confirm account number',
          confirmAccountNumberPlaceholder: 'Re-enter the vendor’s account number',
          doubleCheckDetails: 'Make sure to double check that your vendor’s details are correct.',
          doubleCheckDetailsMobile: 'Double check that your vendor’s details are correct.',
          save: 'Save bank details',
          completeAndSave: 'Complete and save',
          saveFooter:
            'These details will be used for future payments to this vendor. You can always edit them in your vendors section.',
          manuallyConnectBankTitle: 'Connect bank manually',
          manuallyConnectBankSubTitle:
            'Enter your routing and account details for bank transfers (ACH) and get your account verified in one business day.',
          selectAccountType: 'Select your account type',
          accountTypeSaving: 'Saving',
          accountTypeChecking: 'Checking',
          security: 'Your info is protected by encryption',
          header: { title: 'Bank transfer' },
          failedToDeliver: {
            routingNumber: 'Vendor’s routing number (ACH)',
            accountNumber: 'Vendor’s account number',
          },
        },
        check: {
          sectionTitle: 'Add delivery method - Paper check',
          save: 'Save check details',
          submit: 'Submit',
          saveFooter:
            'Vendor bank details will be used for future payments. You can always change them.',
          name: {
            title: 'Who’s receiving the checks we’ll send?',
            label: 'Name to appear on the check',
            notice: 'You can usually find this on the invoice',
          },
          address: {
            title: 'Where should we mail the check to?',
            confirm: 'Confirm address and save',
            confirmSubTitleLine:
              'We spotted a small issue with the address you provided, and suggested an alternative below in green. Please select and/or edit the final version you’d like to use.',
            label: 'Mailing address',
            suiteLabel: 'Suite #',
            notice: 'Look on your bill to find the address of your vendor',
            confirmSubTitleLine2: 'Please select and/or edit the final version you’d like to use.',
          },
          header: { title: 'Check in the mail' },
          title: 'Where should we mail it?',
        },
        virtual: {
          title: 'Send this payment with an email',
          subtitle:
            'Enter <ap>{vendor}</ap> email and we’ll ask how they’d like to receive this payment. Their details will also be available for your future payments.',
          email: "Your vendor's business email",
          phone: 'Your vendor’s mobile number',
          footer:
            'If <b>{vendor}</b> doesn’t respond to our email within 7 business days this payment will be cancelled.',
          supportLink: 'What will my vendor see?',
          done: 'Submit and continue',
          securityText:
            'All your account information will remain secure and hidden from <b>{companyName}</b> and other businesses.',
          header: 'Ask vendor for payment details',
          checkFee: {
            subtitle:
              'Ask how they want to get paid for this and future payments. ACH transfers are free, paper checks will incur you a $1.50 fee.',
          },
        },
        ach: {
          errors: {
            invalidTokenTitle: 'Sorry but it seems like this link isn’t valid anymore.',
            supportText: 'If you need a new link or have questions ',
            supportLink: 'chat with us',
          },
          achDetails: {
            title: 'Get your future payment faster',
            subTitle:
              "Enter your bank account's details to receive future payments in just 3 business days, without paying any fees.",
            formTitle: 'Add your company’s ACH bank details',
            buttonSave: 'Submit and accept payment',
            buttonEdit: 'Edit bank details',
            info: 'By continuing, you agree to the Bill Pay powered by Melio {termsOfService} and {privacyPolicy}, you consent to Intuit sharing your QuickBooks data with Melio for purposes of the Bill Pay service and consent to Intuit and its subsidiaries’ use of your Bill Pay data subject to our {intuitPrivacyPolicy}.',
            footerText: 'By continuing you agree to Melio’s terms of service and privacy policy',
          },
          changeSuccess: {
            title: 'Your bank details have been received',
            subTitle:
              'All future payments sent to you via QuickBooks Bill Pay will be directly deposited to your bank account ending in {digits}',
            dialog: {
              title: 'Wasn’t that easy? Try it yourself:{br}QuickBooks Bill Pay.',
              subTitle:
                'This payment is being processed with QuickBooks Bill Pay powered by Melio, a simple online payment solution for businesses like yours to send bank transfers and paper checks for free. Not a QuickBooks Online user yet? Join now and get started.',
            },
            button: 'Join QuickBooks Online',
            termsOfService: 'terms of service',
            privacyPolicy: 'privacy policy',
            intuitPrivacyPolicy: 'privacy statement',
          },
        },
        shiftVendorToAch: {
          addAchTitle: 'Get paid from {companyName} faster, for free.',
          addAchSubtitle:
            'Enter your bank details to get your future payments deposited directly into your bank account for free, in just 1-3 business days.',
          achTitle: 'Enter your bank account’s ACH details',
          submitCTA: 'Save bank details',
          linkDeliveryMethodTitle:
            '{companyName}There’s a bank account already connected to this account, would you like to recieve this payment there?',
          success: {
            title: 'All set!',
            subtitle:
              'Your future payments from <sb>{companyName}</sb> will be directly deposited to your bank account ending in {digits}',
            contentTitle:
              'Get bank deposits quickly & easily from <un>all</un> your customers, for free.',
            descriptionTitle: 'It’s simple:',
            cta: 'Join melio for free',
            goDashboard: 'Go to your dashboard',
            learnMore: 'Learn more',
            shareLabel: 'Share a payment request',
            shareDescription:
              'Melio users just upload or enter an invoice to share it with any customer as a trackable payment request.',
            getPaidLabel: 'Get paid free',
            getPaidDescription:
              'Customers click on your unique link and schedule payments from their bank for free, or even their credit card for a 2.9% fee. You receive all payments for free.',
            upsale: {
              title: 'Got it! Your bank’s details are saved',
              cta: 'Sign up for free',
              subtitle:
                'All <sb>future</sb> payments from <sb>{companyName}</sb> will now be directly deposited to your bank account ending in {digits}.',
              acceptPaymentLabel: 'Accept business payments online',
              acceptPaymentDescription:
                'Enable customers to pay you in a few clicks for free or even use their credit card (2.9% fee). Even without them signing up.',
              getPaidLabel: 'Get paid fast for free',
              getPaidDescription:
                'Stop waiting for checks! Get paid online in 1-3 business days instead, without any fees.',
            },
          },
          shiftedCurrentPayment: {
            title: 'Your bank’s details are saved',
            ownedVendorTitle: 'Your payment will be deposited',
            subtitle:
              'Your payments from <sb>{companyName}</sb> is on its way.{br} Final approval will be emailed shortly.',
            upsaleTitle: 'Get free bank deposits from your other customers too',
            upsaleSubTitle:
              'Join Melio to receive all your business payments quickly and easily right to your bank account.',
            learnMore: 'Learn more.',
            upsaleCTA: 'Join melio for free',
          },
        },
        shiftToDebit: {
          title: 'Instantly receive this payment to your bank account',
          subtitle: 'Sent you an online payment of',
          description: 'Debit card transaction fee 1% ({fee})',
          descriptionNoFeeValue: 'Debit card transaction fee ({fee})',
          contentSubtitle:
            'Enter your debit card details to receive this payment to its associated bank account within minutes.',
          errorCard:
            'We are sorry but there seems to have been a problem with adding your card. Please try again.',
          errorTitle: 'Your card was not added',
          notSupportedSubtitle:
            'Credit cards are not supported for instant payments, you can only use a debit card.',
          printName: 'Cardholder name',
          linkExistingMethod: {
            title:
              'There’s already a debit card linked to this account- want to use it to receive this payment? ',
            submit: 'Receive payment to this card',
          },
          addressDetails: {
            title: 'Please add your cardholder details',
            submit: 'Submit and accept payment',
          },
          success: {
            title: 'Funds are on their way!',
            subtitle:
              'Your payment from <sb>{companyName}</sb> is on its way and should arrive within <sb>30 minutes</sb>.',
            description:
              '*In a case where there is an issue with your card we will notify you and suggest next steps.',
            contentTitle: 'Receive these direct deposits from your other customers too',
            contentSubtitle:
              'Join Melio to have all your payments deposited directly to your bank account quickly and easily. {learnMore}',
            upSaleCTA: 'Join melio for free',
            learnMore: 'Learn more.',
            dialog: {
              title: 'Wasn’t that easy? Try it yourself: QuickBooks Bill Pay.',
              subTitle:
                'This payment is being processed with QuickBooks Bill Pay powered by Melio, a simple online payment solution for businesses like yours to send bank transfers and paper checks for free. Not a QuickBooks Online user yet? Join now and get started.',
            },
            button: 'Join QuickBooks Online',
            descriptionAch:
              '*In a case where there is an issue with your card we will notify you and suggest next steps.',
            descriptionCheck:
              'The original check is voided but will still arrive at your address. Please do not deposit as it may lead to penalty fees.',
          },
          processed: {
            title: 'Success!',
            subtitle:
              'A <sb>{amount}</sb> payment from <sb>{companyName}</sb> was sent to your account ending ...<sb>{digits}</sb>',
          },
          chatWithUs: 'chat with us',
          paymentCompletedError:
            'Sorry but this payment was already processed and therefore you can not receive this payment instantly. Need assitance? {chatWithUs}',
          blockTabaPayError:
            'Sorry, this payment can’t be processed instantly due to ongoing maintenance. It’ll be processed as a free ACH bank transfer. You can try again in couple of hours.',
          infoWithFee:
            'By continuing you agree to charge your debit card a <b> {fee}</b> fee and to Melio’s {termsOfService} and {privacyPolicy}',
          info: 'All your account information will remain secure and hidden from <b>{companyName}</b> and other businesses.',
          errors: {
            paymentCancelledTitle: 'Payment was cancelled',
            paymentCancelled:
              '<sb>{companyName}</sb> has canceled their payment. You can reach out to {supportEmail} for additional info or to request they resend.',
            paymentCompletedError: {
              title: 'ACH was already processed',
              subtitle:
                'Sorry but this payment was already processed and therefore you can not receive this payment instantly.{br}{br} If you need any help we`re available at {supportEmail}',
            },
            cancelled: {
              title: 'Payment was cancelled',
              subtitle:
                '{companyName} has canceled their payment. You can reach out to {supportEmail} for additional info or to request they resend.',
            },
            cannotProcessed: {
              title: 'Payment couldn’t be processed',
              subtitle:
                'There was an issue processing the payment sent by {companyName}. You can reach out to {companyEmail} for further details.{br}{br} If you need any help we’re available at {supportEmail}',
            },
            expired: {
              title: 'Instant payment is no longer available',
              subtitle:
                'The offer to receive this payment via debit card has expired. You will still receive this payment according to the original payment method.',
            },
            alreadyProcessed: {
              title: 'Payment already processed',
              subtitle: 'This payment has already been processed and cannot be received again.',
            },
            tech: {
              title: 'There was a technical issue',
              subtitle:
                "We've run into an unexpected technical problem and are working to quickly fix the issue. Please wait and try again at a later time. ",
            },
            deposited: {
              title: 'The check was already deposited',
              subtitle: 'This payment has already been completed and cannot be received again.',
            },
            continue: 'Continue to QuickBooks',
          },
        },
        addressGoogle: 'To a physical address',
        addressPobox: 'To a PO box',
        errors: {
          canceled: {
            title: 'This payment has been cancelled',
            subtitle:
              '<sb>{companyName}</sb> has cancelled this payment. You can email them at {supportEmail} and ask them to resend payment.',
          },
        },
      },
      editCheckAddress: {
        shiftAchTitle: 'Get this {totalAmount} payment from {companyName} faster, for free.',
        shiftAchSubtitle:
          'Enter your bank details to receive this and future payments from <sb>{companyName}</sb> as bank deposits in just 1-3 business days, for free. ',
        checkSent: {
          title: 'Your check is already on the way',
          subtitle:
            'This <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>invoice #{invoiceNumber}</sb> is already on its way to you.{br}{br}If the delivery address is incorrect, contact <sb>{companyName}</sb> at <sb>{companyEmail}</sb> or {companyPhone}',
          upsaleTitle: 'Want to get paid faster?',
          upsaleSubTitle:
            "Instead of waiting for checks, enter your bank account's details to receive future payments in just 3 business days, without paying any fees.",
          upsaleCTA: 'Add bank details',
        },
        checkDeposited: {
          title: 'Your check has already been deposited ',
          subtitle:
            'This <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>invoice #{invoiceNumber}</sb> has already been deposited.',
          upsaleTitle: 'Want to get paid faster?',
          upsaleSubTitle:
            "Instead of waiting for checks, enter your bank account's details to receive future payments in just 3 business days, without paying any fees.",
          upsaleCTA: 'Add bank details',
        },
        checkNotSent: {
          form: {
            title: 'Enter a delivery address for your paper checks',
            saveCTA: 'Save and update address',
          },
          success: {
            title: 'Address updated!',
            subtitle:
              'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>invoice #{invoiceNumber}</sb> will arrive as scheduled.',
            upsaleTitle: 'Want this payment sooner?',
            upsaleSubTitle:
              'Get this and future payments directly deposited into your bank account in just 3 business days, for free.',
            upsaleCTA: 'Get paid by bank deposit',
          },
          header: 'Sent you an online payment of',
        },
        switchedCheckAlreadyPage: {
          title: 'Success!',
          subtitle:
            'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> is being sent to your bank account.',
          upsaleTitle: 'Get free bank deposits from your other customers too',
          upsaleSubTitle:
            'Join Melio to receive all your business payments quickly and easily right to your bank account.',
          upsaleCTA: 'Join Melio for free',
          learnMore: 'Learn more.',
        },
        checkCancelled: {
          description:
            'Sorry but this payment has been cancelled by <sb>{companyName}</sb>. Please reach out to {companyEmail} and ask them to resend it.{br}{br}Need our help? We’re always available at {supportMail}',
        },
        checkBlocked: {
          description:
            "Sorry but this payment by <sb>{companyName}</sb> couldn’t be processed and won’t be delivered. Please reach out to {companyEmail} for further clarification.{br}{br}Need help? We're always available{br}{supportEmail}",
          chatWithUs: 'Chat with us',
        },
        paymentCancelled: {
          description:
            "We're sorry but this payment was cancelled by <sb>{companyName}</sb>. Please reach out to <sb>{companyName}</sb> at {companyEmail} and ask them to resend their request.{br}{br}Need help? We're always available at {supportEmail}",
        },
        suggestedAddress: {
          manualAddressCTA: 'Confirm address and finish',
        },
      },
      form: {
        vendorName: 'Vendor name',
        contactEmail: 'Email address',
        contactEmailEmpty: 'No email address',
        contactName: 'Contact person',
        contactNameEmpty: 'No contact person',
        contactPhone: 'Phone',
        contactPhoneEmpty: 'No phone',
        handle: 'Melio.me link',
        trustLabel: 'Trust this vendor?',
        trustCheckbox: 'Add this vendor’s payment requests to my inbox',
        paymentTerms: 'Payment terms',
        goAddDeliveryMethod: 'Add delivery method',
        useSameAddressForCheck: 'Fill from address',
        deleteDialog: {
          title: 'Delete {vendorName}?',
          subtitle: 'Warning: this cannot be undone.',
          subtitleWarning: 'NOTICE: you have {number} {number, plural, one {bill} other {bills}}',
          subtitleWarningHasBills: 'If you still wish to delete it, please delete the bills first.',
          subtitleWarningHasPayments:
            'If you still wish to delete it, please cancel the payments first.',
          confirm: 'Yes, delete this vendor',
        },
        cantDeleteDialog: {
          title: '{vendorName} {br} has scheduled payments and cannot be deleted',
          subtitle: 'You will have to manually cancel all scheduled payments for this vendor',
        },
      },
      bills: {
        title: 'Bills',
        tabs: {
          paid: 'Paid bills',
          unpaid: 'Unpaid bills',
          scheduled: 'Scheduled bills',
        },
      },
      addDeliveryMethodByLink: {
        title: 'Choose how to get your instant payment',
        subtitle:
          'Get your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> instantly instead of waiting 3 business days.',
        paymentETA: 'Estimated payment delivery',
        partnersIconsAlt: 'Partners icon',
        achLabel: 'Bank transfer (ACH)',
        routingNumber: 'Routing number',
        accountNumber: 'Account number',
        submitCTA: 'Submit and accept payment',
        printName: 'Name on check',
        addressLine1: 'Address line 1',
        addressLine2: 'Address line 2',
        city: 'City',
        state: 'State',
        zipCode: 'Zip code',
        support: 'Have any questions?',
        bankAccount: 'Bank account',
        success: {
          title: 'Success!',
          subtitle: {
            withoutInvoice:
              'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> is on its way.',
            regular:
              'Your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> for <sb>Invoice {invoiceNumber}</sb> is on its way.',
          },
          virtualCard: {
            title: 'Success!',
            subtitle:
              'On <sb>{paymentETA}</sb>, Your <sb>{totalAmount}</sb> payment will be sent to <sb>{vendorEmail}</sb> as a single-use virtual card, as will all future payments from <sb>{companyName}.</sb>',
            subtitle_old:
              'We’ve sent a single-use card for <sb>{totalAmount}</sb> to <sb>{vendorEmail}</sb>. You can process it right away.',
          },
          joinService: {
            title: 'Your bank details have been received',
            subTitle:
              'All future payments sent to you via QuickBooks Bill Pay will be directly deposited to your bank account ending in {digits}',
            dialog: {
              title: 'Wasn’t that easy? Try it yourself:{br}QuickBooks Bill Pay.',
              subTitle:
                'This payment is being processed with QuickBooks Bill Pay powered by Melio, a simple online payment solution for businesses like yours to send bank transfers and paper checks for free. Not a QuickBooks Online user yet? Join now and get started.',
            },
            button: 'Join QuickBooks Online',
            termsOfService: 'terms of service',
            privacyPolicy: 'privacy policy',
          },
        },
        linkExistingMethod: {
          title:
            'There’s a bank account already connected to this account, would you like to receive this payment there?',
          submit: 'Receive payment to this account',
        },
        unilateralWithoutPayment: {
          subTitle: 'wants to send you payments with Melio',
          selectTitle: 'How would you like to receive their payments?',
          achCategoryLabel: 'Bank transfers ACH',
          checkCategoryLabel: 'Paper checks',
          achHint: 'Arrive in 2-3 days (free)',
          checkHint: 'Arrive in 5 -7 days (free)',
          submitCTA: 'Save details',
          success: {
            title: 'Success!',
            subtitleAch:
              'Future payments from <b>{companyName}</b> will now arrive directly to your bank account',
            subtitleCheck:
              'Future payments from <b>{companyName}</b> will now arrive as paper check to your address',
          },
          signUpTitle: 'Get deposits to this account from your other customers',
          signUpSubtitle:
            'Join Melio to get all your payments faster and directly to your bank account quickly and easily. ',
          signUpCTA: 'Join melio for free',
          linkExistingMethod: {
            title:
              'There’s a bank account already connected to this account, would you like to recieve your payments there?',
            submit: 'Recieve payments to this account',
          },
        },
        loginHint: 'Already a Melio user?',
        loginHintMobile: 'Melio user?',
        loginSubmit: 'Log In',
        vendorSignUpTitle: 'Receive payments to this account from any of your customers',
        vendorSignUpSubtitle:
          'Join Melio to get all your payments faster and directly to your bank account quickly and easily.',
        vendorSignUpCTA: 'Join Melio free',
        signUpTitle: 'Join Melio to get payments faster!{br} Just like this one.',
        signUpSubtitle:
          'With Melio, you can get paid from all your customers quickly and easily. Upload invoices and send over payment requests with a few clicks. And it’s all completely free.',
        signUpCTA: 'Sign up for free',
        trustedBy: 'Trusted by our leading financial partners',
        achTitle: 'Add your company’s ACH bank details',
        checkTitle: 'Enter your information so QuickBooks can mail you a paper check',
        for: 'For invoice no.',
        subTitle: 'sent you an online payment of',
        includedNote: 'Included note: {note}',
        selectTitle: 'When would you like to receive this payment?',
        achCategoryLabel: 'Bank transfer (ACH)',
        checkCategoryLabel: 'Paper check',
        achHint: '3 business days via bank deposit. Free.',
        cardToAchHint: '1 business day.',
        checkHint: '5-7 business days.',
        confirmMelio: 'By continuing you agree to Melio’s {termsOfService} and {privacyPolicy}',
        privacyPolicy: 'Privacy policy',
        termsOfService: 'Terms of service',
        manuaulTitle: 'Let’s fix this',
        manualAddressTitle: 'Let’s fix the address you entered',
        manualAddressSubTitle:
          'We spotted a small issue with the address you provided, and suggested an alternative below. Please select and/or edit the final version you’d like to use.',
        manualAddressCTA: 'Confirm and accept payment',
        switchCompanyTitle:
          'To which of your organizations would you like to receive this payments?',
        selectTitleVirtualCard: 'How would you like to receive this payment?',
        virtualCardCategories: {
          virtualCard: {
            label: 'Single-use virtual card',
            hint: '3 business days. Card processor required.',
            tooltip: {
              label: '<sb>{title}</sb>{subtitle}',
              title: 'What’s a single-use card?',
              subtitle:
                'It’s an online card sent to your email that you can safely process at any time in your own POS.',
            },
            learnMore: 'What’s a single-use virtual card?',
          },
          ach: {
            label: 'Bank transfer (ACH)',
            hint: '3 business days.',
            cardToAchHint: '1 business day.',
          },
          shiftAchToDebit: {
            selectTitleVirtualCard: 'How would you like to receive this payment{br}instantly?',
            virtualCard: {
              label: 'Single-use virtual card',
              hint: 'Sent to your email. POS Terminal or card processor required. ',
              learnMore: 'Tell me more',
              info: 'Processing fees may apply ',
              subInfo: 'depending on your provider',
              tooltip: {
                label: '<sb>{title}</sb>{subtitle}',
                title: 'What are single-use cards',
                subtitle:
                  'It’s a one-time virtual payment sent to your email that requires no bank details. You can process it any time on your POS.',
              },
            },
            ach: {
              label: 'Debit card',
              hint: 'Sent to your bank account.',
              info: '1% fee',
            },
          },
        },
        checkChooseDeliveryMethod: {
          title: 'Choose how to get paid faster',
          subtitle:
            'Get your <sb>{totalAmount}</sb> payment from <sb>{companyName}</sb> in 3 business days instead of 5-7 with one of the faster options below.',
          eta: {
            original: '<sb>Original ETA:</sb> {paymentETA}',
            new: '<sb>New ETA:</sb> {paymentETA}',
          },
          virtualCard: {
            label: 'Single-use virtual card',
            hint: 'Sent to your email. POS Terminal or card processor required. ',
            learnMore: 'Tell me more',
          },
          ach: {
            label: 'Bank transfer (ACH)',
            hint: 'Sent to your bank account.',
          },
        },
        virtualCardOptInPage: {
          unilateral: {
            title: 'Receive payment by single-use virtual card',
            ctaLabel: 'Confirm and receive card',
            changeDeliveryMethodCta: 'Change delivery method',
          },
          title: 'Get this and future payments by single-use virtual card',
          subTitle:
            'We’ll send the card to your email. Enter the card details into your POS terminal to process the payment. (Processing fees may apply)',
          moblieSubTitle:
            'We’ll send the card to your email. Enter the card details into your POS terminal to process the payment.{br} (Processing fees may apply)',
          learnAboutSingleUseCard: 'Learn about single-use cards',
          stepsDetailsTitle: 'How it works',
          steps: {
            open: {
              title: '1. Open',
              subTitle: 'Recieve an email and unlock your card details.',
            },
            process: {
              title: '2. Process',
              subTitle: 'Copy card details and process 24/7 on your POS.',
            },
            reconcile: {
              title: '3. Reconcile',
              subTitle: 'Get your payment with full remittance details.',
            },
          },
          paymentDetails: {
            title: 'PAYMENT DETAILS',
            amount: '<sb>Amount:</sb> {amount}',
            toBeDeliveredTo: '<sb>Deliver to:</sb> {vendorEmail}',
            changeEmail: 'Would you like to change this email? {contactLink}',
            contact: 'Contact {organizationName}',
            paymentETA: '<sb>Payment ETA:</sb> {paymentETA}',
            paymentETAAchValue: 'Up to 30 minutes',
          },
          posExplanation: {
            title: 'Why do I need a POS terminal?',
            subtitle:
              'A POS terminal, a card processing device, or software is needed to process the card and only for the total payment amount.',
            tag: 'IMPORTANT',
          },
          ctaLabel: 'Confirm and get the card',
          ctaLabelMobile: 'Confirm and receive payment',
          info: 'If confirmed, future payments from <sb>{organizationName}</sb> will also be sent to you as single-use virtual cards.',
          secondCta: 'Back',
        },
        virtualCardInfoPage: {
          unilateral: {
            cardExample: {
              title: 'What’s a single-use virtual card?',
              subtitle:
                'It’s a virtual card sent to your email as a payment. The card details can be used in a POS terminal to process it like any other card payment.',
            },
            terminalExample: {
              title: 'How does it work?',
              subtitle:
                'Enter the card details and total amount into your POS terminal, card processing device or software to get the payment instantly.',
            },
            cta: {
              label: 'Receive a single-use card',
            },
          },
          multilateral: {
            cardExample: {
              title: 'What’s a single-use virtual card?',
              subtitle: 'It’s a virtual credit card sent via email to process in a POS terminal.',
            },
            checkMark: {
              easy: {
                title: 'Easy to use, more control',
                subtitle:
                  'Use your own POS terminal or processing device for instant payment and immediate reconciliation.',
              },
              safe: {
                title: 'Keep your info secure',
                subtitle:
                  'The card details are sent to your email, so you don’t have to share any bank or address info to get paid.',
              },
              flexible: {
                title: 'Stay flexible',
                check_subtitle:
                  'If you decide not to get the card details, you can always change to receiving an ACH transfer instead.',
                subtitle: 'You can always change to receive payment as an an ACH transfer instead.',
              },
            },
            changeDeliveryMethodCta: 'Back',
            info: 'Processing fees may apply ',
          },
          cta: {
            label: 'Get a single-use card',
            subtitle: 'Processing fees may apply depending on your provider',
          },
        },
        virtualCardPosTerminal: {
          title: 'What’s a POS terminal?',
          subtitle:
            'A point of sale (POS) terminal is a device or software used to process card payments for credit, debit and single-use cards.',
          buttons: {
            view: 'View card details',
            optOut: 'Receive bank transfer instead',
          },
          notice:
            'If confirmed, future payments from <sb>{payor}</sb> will also be sent to you as single-use cards.',
        },
      },
      joinServiceUpsell: {
        title: 'You’re one click away from getting paid faster by your customers',
        subtitle:
          'Join the QuickBooks Verified Business Directory so your customers can find you easily and pay you faster',
        cta: 'Join the QuickBooks directory',
        benifits: {
          delivery: {
            title: 'Faster Delivery',
            subtitle: 'Payments arrive in{br}1-2 business{br}days, not a week{br}in the mail',
          },
          tracking: {
            title: 'Full Tracking',
            subtitle:
              'Know where all{br}your payments are{br}and when they’re{br}expected to arrive',
          },
          security: {
            title: 'Safe & Secure',
            subtitle:
              'Intuit safely stores{br}your bank details so{br}your customers{br}don’t need it',
          },
        },
      },
      qbDirectory: {
        verifiedBusinessDirectory: {
          title: 'You have been added to the QuickBooks Directory',
          subtitle:
            'We’re finalizing the Quickbooks Verified Business Directory. Once published, it will allow you to safely and easily manage all payments received via QuickBooks.{br}{br}We’ll notify you once the directory is live.',
        },
      },
      virtualCardDetails: {
        common: {
          cardTo: 'Single-use virtual card to',
          cardData: '{email} (...{card4digits})',
          processedOn: 'Processed on',
          bankTransferTo: 'Bank transfer to',
          bankAccount: 'Bank account ...{digits}',
          bankAccountLbl: 'Bank account',
          checkingAccount: 'Checking account (...{digits})',
          eta: 'Delivery ETA',
          etaNotes: 'By 8PM local time',
          deliveryOn: 'Delivery on',
          contactSupport: 'Can’t accept cards right now? <link>Contact support</link>',
        },
        valid: {
          openModalButton: 'How to use this card',
          sent: 'has sent you an online payment using QuickBooks Online',
          estimate:
            'The card is valid until <sb>{date},</sb> and can only be used in your POS terminal to receive the total payment amount.',
          issuerAddress: ISSUER_ADDRESS,
          issuerAddressHint: 'Note: Some processing systems require an address.',
          zipCode: ISSUER_ZIP_CODE,
          ctabutton: 'Copy card number',
          previewCard: {
            validThru: 'VALID{br}THRU',
            cvc: 'CVC',
          },
          processCard: {
            modalCTA: 'Got it',
            sectionTitle: 'How to use the card',
            first: {
              title: 'Enter the card details into your POS terminal',
              subtitle: 'Make sure you enter the exact details and amount.',
            },
            second: {
              title: 'Process the card',
              subtitle: 'Use your POS terminal or processing system.',
            },
            third: {
              title: 'Receive payment instantly',
              subtitle: 'Get paid instantly, as soon as you process the card.',
            },
          },
          labels: {
            cardNumber: 'Card number',
            expiryDate: 'Expiry date',
            cvc: 'CVC',
            zipCode: 'Zip code',
            issuerAddress: 'Card issuer address',
          },
          notifications: {
            cardNumber: 'Card number copied',
            expiryDate: 'Expiry date copied',
            cvc: 'CVC copied',
            zipCode: 'Zip code copied',
            issuerAddress: 'Card issuer address copied',
          },
          footerNote:
            'Have trouble processing the card? You can <link>contact support</link> for assistance or to change how you receive this payment. ',
        },
        cancelled: {
          title: 'This payment has been cancelled',
          subtitle:
            '<sb>{name}</sb> has cancelled this payment. You can email them at <link>{email}</link> and ask them to resend payment.',
          subtitleWithoutEmail:
            '<sb>{name}</sb> has cancelled this payment. You can email them and ask them to resend payment.',
        },
        processed: {
          title: 'Payment was cleared',
          subtitle: 'Your business already processed this single-use card on <sb>{date}</sb>.',
          reviewSectionTitle: 'You received',
        },
        newDeliveryMethod: {
          title: 'New delivery method for this payment',
          subtitle:
            '<sb>{name}</sb> has updated the delivery method for this payment. The card can no longer be processed.',
          reviewSectionTitle: 'You’ll receive a',
        },
        newDeliveryMethodProcessed: {
          ach: {
            title: 'Payment was deposited to your bank account',
            subtitle:
              'This <sb>{amount}</sb> payment from <sb>{name}</sb> will be sent to the bank account below.',
          },
          check: {
            title: 'Payment was deposited as a paper check',
            subtitle:
              'This <sb>{amount}</sb> payment from <sb>{name}</sb> will be sent to the address below.',
          },
          reviewSectionTitle: 'You received a',
        },
        newDeliveryMethodScheduled: {
          ach: {
            title: 'Payment will be sent as a bank transfer',
            subtitle:
              'This <sb>{amount}</sb> payment from <sb>{name}</sb> will be sent to the bank account below.',
            bankAccount: 'Bank account ...{digits}',
            bankTransferTo: 'Bank transfer to',
          },
          check: {
            title: 'Payment will be sent as a paper check',
            subtitle:
              'This <sb>{amount}</sb> payment from <sb>{name}</sb> will be sent to the address below.',
            paperCheck: '{displayName}',
            paperCheckTransferTo: 'Paper check to',
          },
          'virtual-card': {
            title: 'Payment will be sent as a single-use virtual card',
            subtitle:
              'This <sb>{amount}</sb> payment from <sb>{name}</sb> will be sent as a single-use virtual card',
          },
          reviewSectionTitle: 'You received a',
        },
        notification: {
          title: 'A single-use card is already on the way',
          subtitle:
            'Want to receive this payment another way instead of a single-use card? <link>Contact us to change</link>',
          reviewSectionTitle: 'You’ll receive a',
          cardTo: 'Single-use virtual card to',
          cardData: '{email} (...{card4digits})',
          deliveryETA: 'Delivery ETA',
        },
        expired: {
          title: 'This card expired on {date}',
          subtitle:
            'Recover {name}’s <private><sb>{amount}</sb></private> payment by issuing a new single-use card.',
          cta: 'Issue a new card',
        },
        recovered: {
          title: 'This card expired on <sb>{date}</sb>',
          subtitle:
            'A new Single-use virtual card for the <private><sb>{amount}</sb></private> payment was sent to <sb>{email}</sb> on <sb>{date}</sb>.',
          cta: 'View new card details',
        },
        recoverySuccess: {
          title: 'A new card was sent to your inbox',
          subtitle:
            'The card for the <private><sb>{amount}</sb></private> payment from <sb>{name}</sb> was sent to your inbox.',
          reviewSectionTitle: 'You’ll receive',
        },
        upsell: {
          title: 'Get a virtual card every time',
          description:
            'Want all your payments to be quick and secure? Apply now to get paid with a single-use virtual card every time a client pays you with QuickBooks Online.',
          actionButton: 'Apply Now',
        },
      },
      globalOptInCandidates: {
        content: {
          personalDetailsTitle:
            'Enter your details to get all QuickBooks payments as single-use virtual cards',
          personalDetailsSubTitle: 'Your details',
          continue: 'Continue',
          emailHelperText:
            'Eligible payments will be sent to this email address as Single-use virtual cards',
          businessDetailsTitle: 'Enter your business details below',
          businessDetailsSubTitle: 'Business details',
          addressHelperText: 'The address of your business’s primary location',
          tooltipLabel:
            'We’ll check for any payments to this bank account and switch them to arrive faster by single-use card.',
          applyButton: 'Apply',
          businessHelperText: 'The name you do business as (DBA)',
          businessSelectPlaceholder: 'Select an option',
          privacyPolicy: `By continuing, I confirm that the above information is correct and that I have the authority to provide it, and that this
            information will be used to facilitate single-use virtual card payments to my business. I further agree that business whose information
             is provided will receive all payments as a single-use virtual card payments until providing Bill Pay powered by Melio with a written notice,
              and agrees to Melio’s {termsOfService} and {privacyPolicyLink}`,
          termOfServiceLink: 'Terms of Service',
          privacyLink: 'Privacy Policy.',
          recommended: '(recommended)',
        },
        form: {
          firstName: 'First name',
          lastName: 'Last name',
          phone: 'Phone',
          email: 'Email',
          businessName: 'Business name',
          address: 'Address',
          businessType: 'Business type',
          routingNumber: 'Bank routing number',
          accountNumber: 'Bank account number',
        },
        validation: {
          firstName: 'Please enter your first name',
          lastName: 'Please enter your last name',
          email: 'Please enter your email, make sure it’s valid',
          phone: 'Please enter your phone number',
          noValidPhone: 'Invalid phone number',
          businessName: 'Enter the business name',
          address: 'No business address',
          addressSelect: 'Search and select an address from the list',
          googlePlaceId: 'googlePlaceId',
          fcbStatus: 'Add your business type',
          routingNumber: 'Add your routing number',
          routingNumberMin: 'Sorry, this doesn’t look like a routing number, should be 9 digit',
          accountNumber: 'Add your account number',
          accountNumberMin:
            'Sorry, this doesn’t look like an account number, should be from 6 to 17 digits',
          accountNumberMax:
            'Sorry, this doesn’t look like an account number, should be from 6 to 17 digits',
          inputValue: 'inputValue',
          formattedAddress: 'formattedAddress',
          invalidChecksum: 'The routing number you have entered is invalid',
        },
        success: {
          title: 'We’ll be in touch!',
          subTitle:
            'Your application is being reviewed and an update will be sent to <b>{email}</b> within 2-3 business days.',
        },
        alreadyFilled: {
          title: 'We’ll be in touch!',
          subTitle: 'Your application is being reviewed.{br}Thank you!',
        },
      },
      select: {
        title: 'Vendor',
        placeholder: 'Enter vendor',
        addInSelect: 'Add <sb>{value}</sb> as a new vendor',
      },
      vendorPaymentDetails: {
        header: {
          subtitle: 'sent an online payment of',
          forInvoice: 'For invoice no.',
          includedNote: 'Included note',
          invoices: '{invoices} invoices',
        },
        content: {
          title: 'PAYMENT STATUS',
          steps: {
            [DELIVERY_TYPE.CHECK]: {
              [TIMELINE_STEP_STAGES.SCHEDULE]: {
                title: 'Scheduled',
                subtitle: 'By {payor}',
              },
              [TIMELINE_STEP_STAGES.DEDUCTION]: {
                title: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Sending as paper check',
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Sent as paper check',
                  [TIMELINE_STEP_STATUSES.FAILED]: 'Failed to deliver paper check',
                },
                subtitle: 'to {checkAddress}',
                action: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Change address',
                  [TIMELINE_STEP_STATUSES.FAILED]: 'Contact support',
                },
                hint: {
                  [TIMELINE_STEP_STATUSES.FAILED]: 'The payor was informed.',
                },
              },
              [TIMELINE_STEP_STAGES.DELIVERY]: {
                title: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Estimated delivery',
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Delivered',
                },
                action: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: {
                    [EXPEDITE_OPTIONS.TO_ACH]: 'Get this payment faster',
                    [EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT]: 'Get paid instantly',
                    tooltipLabel: 'Use your debit card to get this payment instantly.',
                  },
                },
              },
              [TIMELINE_STEP_STAGES.CLEAR]: {
                title: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Deposited',
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Deposited',
                },
              },
            },
            [DELIVERY_TYPE.ACH]: {
              [TIMELINE_STEP_STAGES.SCHEDULE]: {
                title: 'Scheduled',
                subtitle: 'By {payor}',
              },
              [TIMELINE_STEP_STAGES.DEDUCTION]: {
                title: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Sending as Bank transfer (ACH)',
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Sent as Bank transfer (ACH)',
                  [TIMELINE_STEP_STATUSES.FAILED]: 'Sent as Bank transfer (ACH)',
                },
                subtitle: 'to Account ending in....{lastBankAccountDigits}',
                action: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Change bank details',
                  [TIMELINE_STEP_STATUSES.FAILED]: 'Contact support',
                },
              },
              [TIMELINE_STEP_STAGES.DELIVERY]: {
                title: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Estimated delivery',
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Delivered',
                },
                action: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: {
                    [EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT]: {
                      text: 'Get paid instantly',
                      tooltipLabel: 'Use your debit card to get this payment instantly.',
                    },
                    [EXPEDITE_OPTIONS.TO_PUSH_TO_RTP]: {
                      text: 'Get paid instantly',
                    },
                    [EXPEDITE_OPTIONS.TO_PUSH_TO_FAST_ACH]: {
                      title: {
                        today: 'Get paid today',
                        tomorrow: 'Get paid tomorrow',
                        date: 'Get paid <sb>{deliveryDate}</sb> by 8pm',
                      },
                    },
                  },
                },
              },
            },
            [DELIVERY_TYPE.VIRTUAL_CARD]: {
              [TIMELINE_STEP_STAGES.SCHEDULE]: {
                title: 'Scheduled',
                subtitle: 'By {payor}',
              },
              [TIMELINE_STEP_STAGES.DEDUCTION]: {
                title: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Delivery by single-use card',
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Single-use virtual card sent',
                },
                subtitle: 'to {vendorEmail}',
                action: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Contact support',
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Contact support',
                },
                hint: {
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Not your email address?',
                },
              },
              [TIMELINE_STEP_STAGES.CLEAR]: {
                title: {
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Cleared',
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Cleared',
                  [TIMELINE_STEP_STATUSES.FAILED]: 'Single-use virtual card expired',
                },
                action: {
                  [TIMELINE_STEP_STATUSES.FAILED]: 'Contact support',
                },
                hint: {
                  [TIMELINE_STEP_STATUSES.FAILED]: 'The payor was informed.',
                },
              },
            },
            [DELIVERY_TYPE.CARD]: {
              [TIMELINE_STEP_STAGES.SCHEDULE]: {
                title: 'Scheduled',
                subtitle: 'By {payor}',
              },
              [TIMELINE_STEP_STAGES.DEDUCTION]: {
                [EXPEDITE_OPTIONS.FROM_ACH]: {
                  title: 'Delivered by bank using debit card',
                },
                [EXPEDITE_OPTIONS.FROM_CHECK]: {
                  title: 'Sent to bank account using debit card',
                  hint: 'Can take up to 30 minutes',
                },
                subtitle: '{network}....{card4digits}',
              },
              [TIMELINE_STEP_STAGES.DELIVERY]: {
                title: {
                  [TIMELINE_STEP_STATUSES.SUCCEED]: 'Delivered',
                  [TIMELINE_STEP_STATUSES.FUTURE_STEP]: 'Estimated delivery',
                },
                [EXPEDITE_OPTIONS.FROM_ACH]: {
                  hint: 'Should arrive within 30 minutes',
                },
              },
            },
          },
          actions: {
            edit: {
              button: {
                save: 'Save',
                cancel: 'Cancel',
              },
              [FORM_TYPES.EDIT_BANK_DETAILS]: {
                title: 'Change bank details',
                description: `We'll send an ACH bank transfer to the bank account below for this and future payments from <sb>{companyName}</sb>.`,
                form: {
                  accountNumber: 'Account number',
                  routingNumber: 'Routing number',
                },
                notification: {
                  success: 'Bank details updated',
                  failed: 'Failed to update bank details',
                },
              },
              [FORM_TYPES.EDIT_CHECK_ADDRESS]: {
                title: 'Change or edit address',
                description: `We'll mail a paper check to the address below for this and future payments from <sb>{companyName}</sb>.`,
                form: {
                  printName: 'Name on check',
                  addressLine1: 'Address line 1',
                  addressLine2: 'Address line 2',
                  city: 'City',
                  state: 'State',
                  zipCode: 'Zip code',
                },
                notification: {
                  success: 'Address details updated',
                  failed: 'Failed to update address',
                },
              },
              [FORM_TYPES.EDIT_EMAIL]: {
                title: 'Where should we send the updates?',
                description: `We'll email updates for all payments from <sb>{payor}</sb> to the address below.`,
                form: {
                  contactEmail: 'Email address',
                  validation: {
                    contactEmail: {
                      required: 'Please enter a valid email address',
                    },
                  },
                },
                notification: {
                  success: 'Updates will be sent to {contactEmail}',
                  failed: 'Failed to update email',
                },
                vendor: 'Payment updates sent to',
                payor: 'Payor contact info',
              },
            },
            expedite: {
              [EXPEDITE_OPTIONS.TO_ACH]: 'Successfully changed to payment by bank transfer',
              [EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT]: 'Funds are on their way!',
              [EXPEDITE_OPTIONS.TO_VIRTUAL_CARD]: 'Single-use virtual card was sent to your email', // [TODO]: Define a better copy for this case
            },
          },
        },
        footer: {
          [EXPEDITE_OPTIONS.TO_ACH]: {
            title: 'Get this payment faster',
            description:
              'Get this and future payments directly deposited into your bank account in just 3 business days, for free.',
            expediteCTA: 'Get paid faster',
          },
          [EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT]: {
            title: 'Want to get paid instantly',
            description:
              'Receive this payment to your bank instantly with your debit card. Works on weekends & holidays as well.',
            expediteCTA: 'Get paid instantly',
            offerExpires: 'Offer expires on {expirationDate}',
          },
          [EXPEDITE_OPTIONS.TO_PUSH_TO_FAST_ACH]: {
            title: {
              today: 'Get this payment today',
              tomorrow: 'Get this payment tomorrow',
              date: 'Get this payment <sb>{deliveryDate}</sb> by 8pm',
            },
            description: 'With just a click you can get your {amount} faster.',
            expediteCTA: 'Get paid',
          },
          [EXPEDITE_OPTIONS.TO_PUSH_TO_RTP]: {
            title: 'Get this payment instantly',
            description: 'With just a click you can get your {amount} instantly.',
            expediteCTA: 'Get paid instantly',
          },
        },
      },
      upgradePayment: {
        header: {
          subtitle: 'Sent you an online payment of',
        },
        p2dPromotion: {
          message: 'Want to get paid to your debit card instead?{br}{link}',
          link: 'Enter your card details',
        },
        support: {
          question: '<sb>Have any questions?</sb>',
          privacyPolicy: 'Privacy Policy',
          termsOfService: 'Terms of Service',
        },
        landing: {
          title: {
            fast: 'Accept your fast payment',
            rtp: 'Accept your instant payment',
          },
          subtitle: {
            fast: {
              today:
                'Get it <sb>today</sb> by 8pm to your <sb>bank account ...{accountNumber4digits}</sb>.',
              tomorrow:
                'Get it <sb>tomorrow</sb> by 8pm to your <sb>bank account ...{accountNumber4digits}</sb>.',
              date: 'Get it <sb>{deliveryDate}</sb> by 8pm to your <sb>bank account ...{accountNumber4digits}</sb>.',
            },
            rtp: {
              inSeconds:
                'Get it <sb>within seconds</sb> to your <sb>bank account ...{accountNumber4digits}</sb>.',
              inMinutes:
                'Get it <sb>in minutes</sb> to your <sb>bank account ...{accountNumber4digits}</sb>.',
              inHour:
                'Get it <sb>within an hour</sb> to your <sb>bank account ...{accountNumber4digits}</sb>.',
              today:
                'Get it <sb>today</sb> to your <sb>bank account ...{accountNumber4digits}</sb>.',
              date: 'Get it <sb>{deliveryDate}</sb> to your <sb>bank account ...{accountNumber4digits}</sb>.',
            },
          },
          fees: '1% fee (deducted from the total amount)',
          cta: 'Confirm and accept',
          agreement: {
            base: 'By continuing, you agree to the Bill Pay powered by Melio {termsOfService} and {privacyPolicy}, you consent to Intuit sharing your QuickBooks data with Melio for purposes of the Bill Pay service and consent to Intuit and its subsidiaries’ use of your Bill Pay data subject to our {privacyStatement}.',
            termsOfService: 'terms of service',
            privacyPolicy: 'privacy policy',
            privacyStatement: 'privacy statement',
          },
        },
        success: {
          title: 'Payment accepted',
          subtitle: {
            now: 'Your <sb>{amount}</sb> payment from <sb>{companyName}</sb> will be sent <sb>now</sb> to <sb>bank account...{accountNumber4digits}</sb> along with an email of the receipt.',
            today:
              'Your <sb>{amount}</sb> payment from <sb>{companyName}</sb> will be sent <sb>today</sb> to <sb>bank account...{accountNumber4digits}</sb> along with an email of the receipt.',
            tomorrow:
              'Your <sb>{amount}</sb> payment from <sb>{companyName}</sb> will be sent <sb>tomorrow</sb> to <sb>bank account...{accountNumber4digits}</sb> along with an email of the receipt.',
            date: 'Your <sb>{amount}</sb> payment from <sb>{companyName}</sb> will be sent on <sb>{deliveryDate}</sb> to <sb>bank account...{accountNumber4digits}</sb> along with an email of the receipt. ',
          },
          review: {
            title: 'YOU’LL RECEIVE',
            method: {
              title: 'Bank transfer ACH to',
              subtitle: 'Bank account ...{accountNumber4digits}',
            },
            delivery: {
              title: 'Delivery on',
              subtitle: {
                now: 'Now, {deliveryDate}',
                today: 'Today, {deliveryDate} by 8:00 PM',
                tomorrow: 'Tomorrow, {deliveryDate} by 8:00 PM',
                date: '{deliveryDate} by 8:00 PM',
              },
            },
          },
        },
        offerExpired: {
          title: 'Payment can’t be accepted',
          subtitle:
            'The link to accept a fast payment has expired or is no longer valid.{br}Need help? We’re available at {supportEmail}',
        },
        toast: {
          errorFast: 'Could not change to fast transfer',
          errorRtp: 'Could not change to instant transfer',
          errorAlreadyExpeditedRtp:
            'Payment already in progress. This payment is already being processed as an instant transfer.',
          errorAlreadyExpeditedFast:
            'Payment already in progress. This payment is already being processed as a fast transfer.',
        },
      },
    },
    contacts: {
      create: {
        successNotification: '{payload_companyName} added to your contacts',
      },
    },
    payments: {
      form: {
        deleteDialog: {
          title: 'Cancel payment?',
          deleteRecurringCurrentTitle: 'Cancel this payment to {companyName}?',
          deleteRecurringAllTitle: 'Cancel recurring payment to {companyName}?',
          subtitle: 'You asked to cancel a {amount} payment to {companyName}.',
          deleteRecurringCurrentSubtitle:
            'Warning: this cannot be undone. Following months payment will continue to be automatically scheduled',
          removalFailedTitle: 'This payment method can’t be deleted.',
          confirm: 'Yes',
          confirmRecurringCurrent: 'Yes, cancel just this payment',
          confirmRecurringAll: 'Yes, cancel all payments',
          cancelText: 'Close',
          failed: {
            title: 'Can’t delete this payment method yet',
            scheduledPaymentsSubtitle:
              'Cancel all scheduled payments where this method is used and try again.',
            failedPaymentsSubtitle:
              'Cancel or resolve all failed and scheduled payments where this method is used and try again.',
          },
        },
      },
    },
    deliveryMethods: {
      bank: 'Bank transfer',
      check: 'Mail check',
      rpps: "Vendor's verified delivery method",
      to: 'to ',
      toValue: 'to {value}',
      deliveryMethodTitle: 'Send as',
      selectDeliveryMethod: 'Select what to send the vendor',
      notDefinedInfo: {
        'virtual-card': {
          label: 'Single-use virtual card {to}',
          selectedLabel: 'Single-use virtual card to {displayName}',
          desc: 'Free. Sent via email. <learnMoreLink>Find out more</learnMoreLink>',
          disabled: 'Available when paying via bank account or Mastercard',
          tagLabel: 'NEW',
          title: 'Single-use virtual card to',
          infoModal: {
            title: 'What’s a single-use virtual card?',
            subtitle:
              'It’s a simple and safe way to pay. Vendors get the details via email and can process the payment with a POS terminal to get paid.',
            checkMark: {
              safe: {
                title: 'Safe',
                subtitle: 'You only need a vendor’s email. Their details stay private and secure.',
              },
              flexible: {
                title: 'Flexible',
                subtitle: 'They can always change to get an ACH or paper check instead.',
              },
            },
            primaryCTALabel: 'Send a single-use virtual card',
            secondaryCTALabel: 'Close',
            settingsPanel: {
              primaryCTALabel: 'Add this method',
            },
          },
        },
        ach: {
          label: 'Bank transfer (ACH) {to}',
          selectedLabel: 'Bank transfer (ACH) to {displayName}',
          desc: 'Free. No additional fee',
          placeholder: 'Deposit funds into your vendor’s bank account',
        },
        check: {
          label: 'Paper check {to}',
          selectedLabel: 'Paper check to <private>{displayName}</private>',
          desc: 'Free. No additional fee',
          cardNotification:
            'Starting on Oct 19, 2022 paying with an ACH to check payment will incur a $1.50 fee. This won’t affect payments already scheduled.{br}<learnMoreLink>Learn more</learnMoreLink>',
          placeholder: 'Melio mails a check to your vendor’s address on your behalf',
          appliedFee: {
            description:
              '{fee} fee, first 2 every month are free.{br} Covered by Check Protection. <learnMoreLink>Tell me more</learnMoreLink>',
            settingsDescription:
              '{fee} fee when paying via ACH, first 2 every month are free. Covered by Check Protection.{br}<learnMoreLink>Tell me more</learnMoreLink>',
            free: 'Free. Covered by Check Protection.{br}<learnMoreLink>Tell me more</learnMoreLink>',
            notification: {
              before: {
                title: 'Paper checks now incur a fee',
                body: 'As of October 19, 2022, checks incur a {fee}. The first 2 checks every month are free. Payments created before won’t be affected. <learnMoreLink>Learn more</learnMoreLink>',
              },
              after: {
                title: 'You get 2 free checks every month',
                body: 'Checks incur a {fee} fee but the first 2 checks you send every month are free.',
              },
              promotionsEnded:
                "The offer for 2 free checks every month has ended and checks now incur a $1.50 fee from the first monthly check. Payments created before June 5, 2023 won't be affected.",
            },
          },
          appliedFeeNoPromotions: {
            description:
              '{fee} fee.{br} Covered by Check Protection. <learnMoreLink>Tell me more</learnMoreLink>',
            settingsDescription:
              '{fee} fee when paying via ACH.{br} Covered by Check Protection. <learnMoreLink>Tell me more</learnMoreLink>',
          },
        },
        virtual: {
          label: 'Ask vendor for delivery details',
          placeholder: 'Send an email to the vendor',
          desc: 'Send email to the vendor',
          selectedLabel: 'Ask vendor for delivery details',
          checkFee: {
            notification:
              'A {fee} fee is applied if your vendor chooses to get a check. If you have a monthly free check available, no fee will apply.',
            notificationNoPromotions:
              'A {fee} fee is applied if your vendor chooses to get a check.',
          },
        },

        rpps: {
          label: 'Direct payment',
          desc: 'Free. No additional fee.',
          placeholder: 'Payment is delivered according to method entered by this vendor',
        },
        infoText: ' ',
        international: {
          labelTo: 'International payment {to}',
          label: 'International payment',
          selectedLabel: 'International payment',
          desc: '4 business days. {fee} fee',
          tagLabel: 'NEW',
          disabled: {
            credit: 'Credit Card can’t be used as a funding source for international payments',
            debit: 'Debit Card can’t be used as a funding source for international payments',
            partial: 'International payments can’t be used when sending partial payments',
            notEligible: 'Temporarily unavailable for your state.',
          },
        },
      },
      actions: {
        edit: 'Edit',
        delete: 'Delete',
        addMethod: 'Add method',
        sendRequest: 'Send request',
        resendRequest: 'Resend request',
        replace: 'Replace details',
      },
      updatedBy: 'Updated on {date} by {displayName}',
      titles: {
        domestic: 'DOMESTIC',
        international: 'INTERNATIONAL',
      },
      virtual: {
        desc: '{displayName}',
        tooltipLabel: 'Sending to {value}',
      },
      tags: {
        virtualCard: 'NEW',
      },
      edit: 'Edit',
    },
    fundingSources: {
      fundingSourceTitle: 'Pay with',
      selectFundingSource: 'Choose what you want to pay with.',
      microDeposits: {
        title: 'Verify your bank account',
        subtitle:
          'We sent 2 small deposits (less than $1.00) from Melio Payments to your bank account ending in <sb>{accountNumber}</sb>.{br}{br} Enter the exact amounts below to verify your account.{br}{br}',
        chatWithSupport: '{icon} Chat with our support',
        successCTA: 'Sign up now',
        whatsNext: 'What’s next',
        signUp:
          'Sign up to Melio and pay all your vendors however you like, even if they only accept checks.',
        signUpNow: 'Sign up now',
        learnMore: 'Learn more about Melio',
        complete: 'Done',
      },
    },
    onboarding: {
      registration: {
        changeEmail: {
          title: 'Change your email',
          subtitle: 'No worries, you can change your email here',
          nextLabel: 'Change your email',
          inputHint: 'Email',
        },
      },
      companyInfo: {
        taxIdType: {
          inputTitle: 'Tax ID type',
        },
      },
      fundingSources: {
        section: 'Add a payment method',
        title: 'Link payment method',
        subtitle: 'Pay vendors with bank or card, they receive a check or ACH.',
        options: {
          bank: {
            feeFundingSourceDescription: 'Use this bank account to cover paper check fees.',
            feeFundingSourceDescriptionAddition: 'Use a bank account to cover paper check fees.',
            label: 'Bank account',
            description: 'Free',
            settingsDescription: 'ACH. Fast and reliable (free)',
          },
          debit: {
            feeFundingSourceDescription: 'Use this debit card to cover paper check fees.',
            feeFundingSourceDescriptionAddition: 'Use a debit card to cover paper check fees.',
            label: 'Debit card',
            description: 'Free',
            descriptionFee:
              'Pay directly with your debit card and unlock same-day delivery ({fee} fee).',
          },
          credit: {
            feeFundingSourceDescription: 'Use this credit card to cover paper check fees.',
            feeFundingSourceDescriptionAddition: 'Use a credit card to cover paper check fees.',
            label: 'Credit card',
            description:
              'Defer payments, earn card rewards, and unlock same-day delivery ({fees_credit_value}% fee). {link}',
            freeFeeDescription:
              'Free for this payment!{br} Defer this payment and earn card rewards. {link}',
            settingsDescription:
              'Defer this payment, earn card rewards, and unlock same-day delivery ({fees_credit_value}% fee).{br}{link}',
            link: 'View benefits',
            international: 'International payments can only be made with a Mastercard. {link}',
            tooltip: {
              title: 'Claim same-day delivery',
              description:
                'Your payment is eligible for a same-day delivery bank transfer at no extra cost when paying by credit card (2.9% fee).',
            },
          },
        },
        bank: {
          section: 'Add a payment method - bank account',
          plaid: {
            title: 'Choose your bank',
          },
          verification: {
            title: 'Connect your bank account',
            paymentSubtitle: 'Choose how you’d like to transfer payments for free.',
            deliverySubtitle:
              'Connect your bank account and get paid with a direct bank transfer (ACH).',
            plaid: {
              label: 'Connect instantly',
              description:
                'Securely log in to your bank and start scheduling  transfers right away.',
            },
            manual: {
              label: 'Verify with deposits',
              description:
                'You’ll receive two micro-deposits in your bank account in 1-2 business days. Verify the amounts to start making payments.',
              limit:
                'This account has the maximum number of unverified bank accounts allowed at a time. Please remove or verify a bank account with micro-deposits before adding another one.',
            },
            debit: {
              label: 'Connect using a debit card',
              description:
                'Enter your card’s details to schedule transfers. Great for next-day payments under $3,000.',
            },
          },
          intuitAccounts: {
            title: 'Link activity to matching {type}',
            subtitle:
              'Select the matching payment account to link it and sync its payment activity',
            ach: 'bank account',
            creditAccount: 'credit card account',
            debitAccount: 'debit card account',
            linkAch: 'Link my bank account',
            linkCredit: 'Link my credit card',
            linkDebit: 'Link my debit card',
            accountLabel: 'Payment account in QuickBooks',
            placeholder: 'Select account',
            customLabel: 'Add this as a new payment account in QuickBooks',
            notification:
              'A new payment account will be added to your QuickBooks account. It’s labelled "<sb>{displayName}</sb>" and you can edit its details in your QuickBooks.',
            paymentNotification:
              'This payment will be synced to a new payment account in QuickBooks labelled "<sb>{displayName}</sb>". You can edit its details in QuickBooks. ',
            errors: {
              exist: 'This account already exists',
              required: 'Please select the matching account in QuickBooks',
            },
            success: {
              title: 'We’ve added this {type} to your QuickBooks',
              subTitle:
                'Seems like this payment account wasn’t in QuickBooks yet, that’s OK, we’ve just added it. It’s labelled <sb>{displayName}</sb> and you can edit its details in QuickBooks.',
              cta: 'Link {type}',
            },
          },
          complete: {
            section: 'Add a payment method - bank account',
            imageAlt: 'Success! Bank linked.',
            title: 'Success! Bank linked.',
            subtitle: 'You can now make bank transfers (ACH) to any of your vendors.',
            next: 'Done',
            settingsNext: 'All set',
            exploreText: 'Already using QuickBooks?',
            exploreLink: 'Connect your QuickBooks account',
          },
          manually: {
            view: {
              title: 'Bank account details',
              subtitle: 'Review the routing and account numbers of this bank account in Melio',
            },
            connectBankTitle: 'Verify with deposits',
            connectBankSubTitle: 'Enter your bank account details.',
            connectSuccessTitle: 'Pending bank account verification',
            connectSuccessSubTitle:
              'When the micro-deposits are transferred to your account, you’ll receive an email with a link to complete the verification process. The micro-deposits will show up as credits and then debits from Melio Payments inc.',
            connectSuccessNext: 'Done',
            accountTypeSaving: 'Saving',
            accountTypeChecking: 'Checking',
            saveFooter:
              'Your bank details will be stored in your profile as a convenience for future use.',
            selectAccountType: 'Select your account type',
            routingNumber: 'Routing number (ACH)',
            accountNumber: 'Account number',
            completeAndSave: 'Save and continue',
            buttonCTA: 'Ok. Got it',
            connectSuccessSubTitleBoldText: 'What happens next?',
          },
          cantFind: {
            title: 'Don’t see your bank listed?',
            subtitle:
              'That’s OK, you can enter your account and routing numbers to connect your bank account manually.',
            next: 'Connect account manually',
            cancel: 'Cancel',
          },
          plaidFailed: {
            title: 'Connect with account details instead',
            subtitle:
              'Add your account details below to receive 2 small deposits (within 1-3 business days). Confirm the amounts to connect your account.',
            cta: 'Save and continue',
            note: 'We’ll keep your information safely stored in your profile{br}using secure encryption for future use.',
            link: 'Choose a different payment method',
          },
        },
        card: {
          iframeTitle: 'Add your card details',
          iframeSubTitle:
            'The vendor doesn’t need to accept cards and you can earn rewards and defer payments.',
          title: 'Add cardholder details',
          editTitle: 'Cardholder details',
          save: 'Complete and save',
          successNoteCredit: 'Your credit card was successfully added.',
          successNoteDebit: 'Your debit card was successfully added.',
          failNote: 'Your card was not added',
          failCancelButton: 'Ok',
          notAddedTitle: 'Card not added',
          notSupportedTitle: 'This card is not supported',
          tabaPayMigrationErrorTitle: 'Adding new cards is currently disabled',
          tabaPayMigrationErrorSubtitle:
            'Sorry for the inconvenience but adding a new debit/credit card to your account is currently disabled due to some required maintenance. You can connect a new bank account or return later to add a new card.',
          errorButton: 'OK. Let me fix it',
          sandboxIndication: 'You are currently in Tabapay Sandbox mode.',
          firstName: {
            label: 'First name',
            id: 'firstName',
            errorMessage: 'Please enter your first name',
            validationTest: 'string',
            hint: 'The first name listed on the card.',
          },
          lastName: {
            label: 'Last name',
            id: 'lastName',
            errorMessage: 'Please enter your last name',
            validationTest: 'string',
            hint: 'The last name listed on the card.',
          },
          address: {
            label: 'Address line 1',
            id: 'address',
            errorMessage: 'Please enter your address listed for this card',
            validationTest: 'string',
            hint: 'The address listed for this card.',
          },
          city: {
            label: 'City',
            id: 'city',
            errorMessage: 'Please enter your city',
            validationTest: 'string',
          },
          state: {
            label: 'State',
            id: 'state',
            errorMessage: 'Please choose a state',
            validationTest: 'string',
          },
          zipcode: {
            label: 'ZIP code',
            id: 'zipCode',
            errorMessage: 'Please enter your ZIP code',
            validationTest: 'string',
          },
        },
      },
      deliveryMethods: {
        bank: {
          title: 'Verify with deposits',
          verifyTitle: 'Verify your bank account',
          verifySubtitle:
            'Please verify that you’d like to use these bank details for your future incoming payments.',
          melioMeSubtitle: 'Enter your bank account details.',
          routingNumber: 'Routing number (ACH)',
          accountNumber: 'Account number',
          view: {
            title: 'Bank account details',
            subtitle: 'Review the routing and account numbers of this bank account in Melio',
          },
          save: 'Link my bank account and finish',
          verifyCta: 'Verify details and continue',
          verification: {
            plaid: {
              label: 'Connect instantly',
              description:
                'Log in and connect your bank to request, track, and receive incoming payments.',
            },
            manual: {
              label: 'Verified with deposits',
              description:
                'You’ll receive two micro-deposits in your bank account in 1-2 business days. Verify the amounts to start making payments.',
            },
          },
        },
      },
    },
    guests: {
      register: {
        amountPlaceholder: '0.00',
        learnMore: 'Learn more.',
        securityText:
          'We keep all your financial data protected so your sensitive information stays hidden. Your card/account details are stored in your profile to save you time on future payments.',
      },
      emailVerify: {
        title: 'Check your email',
        subTitle:
          'We sent a 4-digit code to {email}.{br}Please enter it below.{br}Can’t find it? Check your spam folder',
        sendCodeAgain: 'Resend the code',
        changeEmail: 'Noticed a typo? Fix your email address',
        codeNotValid: 'Oops! You got it wrong',
        resendNotification:
          'We’ve just sent you another verification code. Please check your inbox (it might be in your spam folder)',
      },
      codeVerification: {
        title: 'Verify it’s you',
        subtitle: {
          soft: 'For your security, we just want to make sure this is you. We sent you a 4 digit code to <b>{email}</b>{br}{br}Enter code below',
          regular:
            'We’ve noticed you haven’t logged into your account from this device before. For your security, we just want to make sure it was you.{br}{br}We sent you a 4 digit code to <b>{email}</b>{br}{br}Enter code below',
        },
        footer: {
          code: 'Haven’t received a code? ',
          resend: 'Resend',
          support: 'Need more help? <link>Contact support</link>',
        },
        messages: {
          codeSent: {
            title: 'Verification code resent',
            subtitle:
              'We sent a new verification code to your email (can take a few mins). If you still don`t see it, try checking your spam folder.',
          },
          finalCodeSent: {
            title: 'Final verification code resent',
            subtitle:
              'We sent a final verification code to your email, so please make sure it`s correct. Don`t see it? Check your spam folder.',
          },
        },
        errors: {
          invalidCode: {
            title: 'Invalid verification code',
            subtitle:
              'This code is invalid or expired. Please double-check the verification code you received and try again.',
          },
          attemptsAreOver: {
            title: 'Too many attempts',
            subtitle:
              'Please resend and try again with a different code or contact support for further assistance.',
          },
          verificationBlocked: {
            title: 'Too many attempts',
            subtitle:
              'Verification is temporarily disabled because of too many failed attempts. Please contact support for assistance.',
          },
        },
      },
      confirm: {
        scheduledDate: 'Payment will be deducted on',
        deliveryDate: 'Delivery ETA',
      },
    },
    notFound: {
      title: '404',
      subtitle:
        'We searched everywhere but couldn’t find what you’re looking for. Let’s get you home.',
      button: 'Melio home',
      text: 'Page not found!',
    },
    error: {
      title: 'Error',
      report: 'Report',
    },
    dialogs: {
      buttons: {
        close: 'Close',
        cancel: 'Cancel',
        done: 'Done',
        no: 'No',
        schedule: 'Schedule next payment',
      },
    },
    storybook: {
      button: 'Button',
      label: 'Label',
      guidingText1: 'This is guiding text #1',
      guidingText2: 'This is guiding text #2',
      error: 'This is an error message',
      placeholder: 'This is a placeholder',
      description: 'This is a description',
      inputValue: 'A Value example',
      textType: 'text',
      yes: 'yes',
      no: 'no',
      notification: 'This is an notification message',
      notificationWithValues: 'This is an notification message {undo}',
    },
    serverErrors: {
      ERR: 'An error has occurred',
      '[CUSTOMERS] CREATE_REQUEST': {
        'CONTACT-EXISTS': 'Company already exists, please choose a different name',
      },
      '[CUSTOMERS] UPDATE_REQUEST': {
        'CONTACT-EXISTS': 'Company already exists, please choose a different name',
      },
      '[VENDORS] CREATE_REQUEST': {
        'CONTACT-EXISTS': 'Company already exists, please choose a different name',
      },
      '[VENDORS] UPDATE_REQUEST': {
        'CONTACT-EXISTS': 'Company already exists, please choose a different name',
      },
    },
    inputErrors: {
      userRegistration: {
        email: {
          string: {
            email: 'Please enter your email, make sure it’s valid',
          },
        },
        password: {
          string: {
            base: 'This password is too easy to guess, please try a more complex one',
            zxcvbn: {
              generic: 'This password is too easy to guess, please try a more complex one',
              addMoreWords: 'This password is too easy to guess, please try a more complex one',
              avoidAssociatedDatesAndYears:
                'Please avoid dates and years that are associated with you',
              avoidRepeats: 'Please avoid repeated words and characters',
              avoidSequences: 'Please avoid sequences',
              avoidRecentYears: 'Please avoid recent years',
              tooCommon:
                'This password is very common and too easy to guess, please try a more complex one',
              noRepeats:
                'Repeats like "abcabcabc" are only slightly harder to guess than "abc", please try a more complex password',
            },
            min: 'Please enter a password with at least 8 characters',
          },
          server: {
            ALREADY_EXISTS: 'This email already registered',
            ATH02: 'Current password is incorrect',
          },
        },
        confirmedPassword: {
          any: {
            allowOnly: 'Passwords don’t match',
          },
        },
        currentPassword: {
          any: {
            empty: 'Please enter your current password',
          },
          string: {
            base: 'Please enter your current password',
          },
        },
        companyOwnerInfo: {
          email: {
            any: {
              required: 'Enter the company owner’s email, make sure it’s valid',
            },
            string: {
              base: 'Enter the company owner’s email, make sure it’s valid',
              email: 'Enter the company owner’s email, make sure it’s valid',
            },
          },
        },
      },
      bill: {
        totalAmount: {
          any: {
            empty: 'Please enter the bill total',
          },
          number: {
            greater: 'Please enter an amount that is greater than zero',
            base: 'Enter a bill total',
            max: 'That’s too much!',
          },
        },

        note: {
          string: {
            max: 'We’re sorry, but bill note should not exceed 2000 characters',
          },
        },

        invoiceNumber: {
          any: {
            empty: 'Enter a bill number',
          },
          string: {
            base: 'Please enter an invoice number',
            max: 'We’re sorry, but invoice number should not exceed 255 characters',
          },
        },
        vendorId: {
          number: {
            base: 'Select a vendor',
          },
        },
        dueDate: {
          date: {
            base: 'Enter a due date',
            min: 'Please select today’s date or greater',
          },
          any: {
            required: 'Please select a due date for this bill',
          },
        },
        invoiceDate: {
          date: {
            base: 'Please select an issue date for this bill',
          },
          any: {
            required: 'Please select an issue date for this bill',
          },
        },
        terms: {
          number: {
            base: 'Please select payment terms',
            min: 'Please enter 0 or more days, not a negative number',
          },
        },
        occurrences: {
          string: {
            regex: {
              base: 'Please enter up to 24 for monthly or up to 60 for weekly payments',
            },
          },
          number: {
            max: 'Please enter up to 24 for monthly or up to 60 for weekly payments',
            base: 'Please enter the number of payments',
            greater: 'Please enter 2 or more payments',
          },
        },
      },
      microDepositsVerification: {
        amount1: {
          any: {
            empty: 'Please enter the microdeposit sum',
          },
          number: {
            greater: 'Please enter an amount that is greater than zero',
            base: 'Please enter the microdeposit sum',
          },
        },
        amount2: {
          any: {
            empty: 'Please enter the microdeposit sum',
          },
          number: {
            greater: 'Please enter an amount that is greater than zero',
            base: 'Please enter the microdeposit sum',
          },
        },
        invalidAmount: 'One or both of the amounts above is incorrect. Try again',
        invalidDeposit: 'Deposit must be less than $1.00',
      },
      directoryVendor: {
        accountIdentifier: {
          custom: {
            mask: 'Invalid account number',
          },
          any: {
            empty: 'Please enter the account number of your vendor',
            required: 'Please enter the account number of your vendor',
          },
        },
      },
      vendor: {
        companyName: {
          any: {
            empty: 'Please enter the business name of your vendor',
            required: 'Please enter the business name of your vendor',
          },
          string: {
            max: 'Noooo, that’s just too long',
          },
        },
        contactEmail: {
          any: {
            empty: 'Please enter the email address of your vendor',
            required: 'Please enter the email address of your vendor',
          },
          string: {
            email: 'Please enter the correct email address of your vendor',
            base: 'Please enter a valid email address',
            max: 'We’re sorry, but email address should not exceed 255 characters',
          },
        },
        contactPhone: {
          any: {
            empty: 'Please enter the phone number of your vendor',
            required: 'Please enter the phone number of your vendor',
          },
        },
      },
      deliveryMethodCheck: {
        printName: {
          any: {
            empty: 'Please enter the vendor name (to print on the check)',
            required: 'Please enter the vendor name (to print on the check)',
            invalidChar: 'Hmm, that character isn’t supported here',
          },
          string: {
            max: 'We’re sorry, but name on check should not exceed 255 characters',
            'regex.base': 'Please enter the vendor name (to print on the check)',
          },
        },
        googlePlaceId: {
          any: {
            empty: 'Please enter the vendor address',
            required: 'Please enter the vendor address',
          },
        },
        addressLine1: {
          any: {
            empty: 'Search and select an address from the list',
            required: 'Search and select an address from the list',
            invalidAddress: 'Search and select an address from the list',
          },
          string: {
            min: 'Search and select an address from the list',
          },
        },
        city: {
          any: {
            empty: 'Please enter the city',
            required: 'Please enter the city',
          },
        },
        state: {
          any: {
            empty: 'Please select the state',
            required: 'Please enter the state',
            allowOnly: 'Please select the state',
          },
        },
        zipCode: {
          any: {
            empty: 'Please retry, zip codes should be 5 or 9 digits',
            required: 'Please retry, zip codes should be 5 or 9 digits',
          },
          string: {
            regex: {
              base: 'Please retry, zip codes should be 5 or 9 digits',
            },
          },
        },
      },
      deliveryMethodAch: {
        routingNumber: {
          string: {
            regex: {
              base: 'Sorry, this doesn’t look like a routing number, should be 9 digits',
            },
            invalidChecksum: 'The routing number you have entered is invalid',
          },
        },
        accountNumber: {
          string: {
            regex: {
              base: 'Sorry, this doesn’t look like an account number, should be from 6 to 17 digits',
            },
          },
        },
        confirmAccountNumber: {
          doesntMatchAccountNumber: 'Please make sure the account numbers match',
        },
        tryToPayHimself:
          'This account is already set to be your payment method and can’t be set as a vendor’s delivery method',
      },
      deliveryMethod: {
        virtualCardAccount: {
          accountEmail: {
            any: {
              allowOnly: 'Please enter the email address of your vendor',
              required: 'Please enter the email address of your vendor',
            },
            string: {
              email: 'Please enter the correct email address of your vendor',
              base: 'Please enter your vendor’s email',
              max: 'We’re sorry, but email address should not exceed 255 characters',
            },
          },
        },
        virtualAccount: {
          email: {
            any: {
              empty: 'Please enter the email address of your vendor',
              required: 'Please enter the email address of your vendor',
            },
            string: {
              email: 'Please enter the correct email address of your vendor',
              base: 'Please enter your vendor’s email',
              max: 'We’re sorry, but email address should not exceed 255 characters',
            },
          },
        },
        bankAccount: {
          routingNumber: {
            string: {
              regex: {
                base: 'This should be 9 digits',
              },
              invalidChecksum: 'The routing number you have entered is invalid',
            },
          },
          accountNumber: {
            string: {
              regex: {
                base: 'This should be 6-17 digits',
              },
            },
          },
        },
        paperCheck: {
          printName: {
            any: {
              empty: 'Please enter the recipient’s name',
              required: 'Please enter the recipient’s name',
            },
            string: {
              max: 'We’re sorry, but name on check should not exceed 255 characters',
              'regex.base': 'Please enter the recipient’s name',
            },
          },
          googlePlaceId: {
            any: {
              empty: 'Please enter the address',
              required: 'Please enter the address',
            },
          },
          addressLine1: {
            any: {
              empty: 'Please enter a delivery address',
              required: 'Please enter a delivery address',
            },
            string: {
              min: 'Please enter a delivery address',
            },
          },
          city: {
            any: {
              empty: 'Please enter a city',
              required: 'Please enter a city',
            },
          },
          state: {
            any: {
              empty: 'Please select a state',
              required: 'Please select a state',
              allowOnly: 'Please select a state',
            },
          },
          zipCode: {
            any: {
              empty: 'Please enter a 5 or 9 digit zip code',
              required: 'Please enter a 5 or 9 digit zip code',
            },
            string: {
              regex: {
                base: 'Please enter a 5 or 9 digit zip code',
              },
            },
          },
        },
        cardAccount: {
          printName: {
            array: {
              min: 'Please enter the recipient’s name',
            },
          },
          googlePlaceId: {
            any: {
              empty: 'Please enter the address',
              required: 'Please enter the address',
            },
          },
          addressLine1: {
            string: {
              min: 'Please enter a delivery address',
            },
          },
          city: {
            any: {
              empty: 'Please enter a city',
              required: 'Please select a city',
            },
          },
          state: {
            any: {
              empty: 'Please select a state',
              required: 'Please select a state',
              allowOnly: 'Please select a state',
            },
          },
          zipCode: {
            string: {
              regex: {
                base: 'Please enter a 5 or 9 digit zip code',
              },
            },
          },
        },
      },
      companyInfo: {
        companyName: {
          any: {
            base: 'Please enter the company name',
            empty: 'Please enter the company name',
            required: 'Please enter the company name',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter the company name',
          },
        },
        phone: {
          string: {
            base: 'Please enter phone number',
          },
          any: {
            empty: 'Please enter your phone number',
            required: 'Please enter your phone number',
          },
        },
        contactFirstName: {
          any: {
            empty: 'Please enter your first name',
            required: 'Please enter your first name',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter your first name',
          },
        },
        contactLastName: {
          any: {
            empty: 'Please enter your last name',
            required: 'Please enter your last name',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter your last name',
          },
        },
        googlePlaceId: {
          any: {
            empty: 'Please enter the address of your business',
            required: 'Please enter the address of your business',
          },
          string: {
            base: 'Please enter the address of your business',
          },
        },
        zipCode: {
          any: {
            empty: 'Please enter a ZIP code',
            required: 'Please enter a ZIP code',
          },
          string: {
            regex: {
              base: 'Please enter a valid ZIP code',
            },
            base: 'Please enter the company’s address',
          },
        },
        legalCompanyName: {
          any: {
            empty: 'Please enter the legal name of your business',
            required: 'Please enter the legal name of your business',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter the legal name of your business',
          },
        },
        legalGooglePlaceId: {
          any: {
            empty: 'Please enter the address of your business',
            required: 'Please enter the address of your business',
          },
          string: {
            base: 'Please enter the address of your business',
          },
        },
        legalZipCode: {
          any: {
            empty: 'Please enter a ZIP code',
            required: 'Please enter a ZIP code',
          },
          string: {
            base: 'Please enter a valid ZIP code',
            regex: {
              base: 'Please enter a valid ZIP code',
            },
          },
        },
        taxIdType: {
          any: {
            empty: 'Please select the tax ID type',
            required: 'Please select the tax ID type',
          },
          string: {
            base: 'Please select the tax ID type',
          },
        },
        taxId: {
          any: {
            empty: 'Please enter the tax ID number (EIN, SSN or ITIN)',
            required: 'Please enter the tax ID number (EIN, SSN or ITIN)',
          },
          string: {
            max: 'Noooo, that’s just too long',
            base: 'Please enter the tax ID number (EIN, SSN or ITIN)',
            regex: {
              base: 'The tax ID you entered is invalid',
            },
          },
        },
        addressLine1: {
          any: {
            empty: 'Please enter the address',
            required: 'Please enter the address',
          },
        },
        legalAddressLine1: {
          any: {
            empty: 'Please enter the address',
            required: 'Please enter the address',
          },
        },
        city: {
          any: {
            empty: 'Please enter the city',
            required: 'Please enter the city',
          },
        },
        state: {
          any: {
            empty: 'Please select the state',
            required: 'Please enter the state',
          },
        },
        companyOwnerInfo: {
          phone: {
            string: {
              base: 'Enter the owner’s phone number',
            },
            any: {
              empty: 'Enter the owner’s phone number',
              required: 'Enter the owner’s phone number',
            },
          },
          contactFirstName: {
            any: {
              empty: 'Enter the owner’s first name',
              required: 'Enter the owner’s first name',
            },
            string: {
              max: 'Noooo, that’s just too long',
              base: 'Enter the owner’s first name',
            },
          },
          contactLastName: {
            any: {
              empty: 'Enter the owner’s last name',
              required: 'Enter the owner’s last name',
            },
            string: {
              max: 'Noooo, that’s just too long',
              base: 'Enter the owner’s last name',
            },
          },
        },
        POBox: "International payments can't be sent if your company address is a PO box",
      },
      fundingSourceBank: {
        accountType: {
          string: {
            any: {
              empty: 'Please enter account type',
              required: 'Please enter account type',
            },
          },
        },
        routingNumber: {
          string: {
            regex: {
              base: 'Sorry, this doesn’t look like a routing number, should be 9 digits',
            },
            invalidChecksum: 'The routing number you have entered is invalid',
          },
        },
        accountNumber: {
          string: {
            regex: {
              base: 'Sorry, this doesn’t look like an account number, should be from 6 to 17 digits',
            },
          },
        },
        alreadyDefined:
          'You’ve already saved these bank account details. To continue, remove it from {vendorName} delivery methods or use other bank account details',
      },
      guest: {
        totalAmount: {
          any: {
            empty: 'Please enter an amount to pay',
          },
          number: {
            greater: 'Please enter an amount that is greater than zero',
            base: 'Please enter an amount to pay',
            max: 'That’s too much!',
          },
          custom: {
            nopay: 'We’re sorry, You can not transfer funds to yourself',
          },
        },
      },
      international: {
        country: {
          empty: 'Please select a country',
          error: 'Sorry, something went wrong',
        },
        iban: {
          empty: 'Enter IBAN nunber',
          regex: 'Invalid IBAN number',
        },
        accountNumber: {
          empty: 'Enter account number',
          regex: 'Invalid account number',
        },
        bankName: {
          empty: 'Enter bank name',
          regex: 'Invalid bank name',
        },
        bic_swift: {
          empty: 'Enter SWIFT code',
          regex: 'Invalid SWIFT code',
        },
        purpose: { empty: 'Choose a purpose' },
        description: { empty: 'Description is required to complete payment' },
        business_name: {
          empty: 'Enter the business name',
          regex: 'Enter the business name',
        },
        name: {
          empty: 'Enter the account holder’s full name',
          regex: 'Enter the account holder’s full name',
        },
        address: {
          empty: 'Search and select an address from the list',
          regex: 'Search and select an address from the list',
        },
        city: {
          empty: 'Enter the city',
          regex: 'Enter the city',
        },
        postcode: {
          empty: 'Enter the postal code',
          regex: 'Enter the postal code',
        },
        state_or_province: {
          empty: 'Enter the state or district',
          regex: 'Enter the state or district',
        },
      },
      dateOfBirth: {
        any: {
          empty: 'Please enter your date of birth',
          required: 'Please enter your date of birth',
        },
        string: { regex: { base: 'Please enter a valid date of birth' } },
      },
      QBO01: {
        title: 'Sorry, something went wrong',
        subTitle:
          'We couldn’t load Bill Pay services but we’re working hard to fix it.{br}You can refresh this page or try again later.',
        buttonCTA: 'Take me back',
      },
      justPayPaymentInfo: {
        amount: {
          string: {
            base: 'Select amount',
            max: 'Bill amount exceeds the sending limit of $999,999.99',
            min: 'Please enter an amount that is greater than zero',
          },
          any: {
            required: 'Enter amount',
            empty: 'Enter amount',
          },
        },
        vendor: {
          string: { base: 'Select vendor' },
          any: {
            required: 'Select vendor',
            empty: 'Select vendor',
          },
        },
      },
    },
    user: {
      role: {
        owner: 'Owner',
        admin: 'Admin',
        contributor: 'Contributor',
        accountant: 'Accountant',
        deleted: 'Deleted',
        accountingFirmAdmin: 'Firm admin',
        pending: 'Pending',
      },
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      sessionExpired: {
        title: 'Your session has expired',
        label:
          "Please close this page and start the flow again. Don't worry we saved all your data.",
      },
    },
    batchBulkPage: {
      button: 'Schedule payments',
      cancel: 'Cancel',
      modals: {
        addVendorEmail: {
          title: 'Add vendor email for reconciliation',
          text: 'We’ll send the vendor a payment confirmation email with invoice numbers for reconciliation.',
          button: 'Save',
          input: {
            label: 'Vendor’s business email',
            placeholder: 'Email address',
          },
        },
        memoForVendor: {
          title: 'Leave a memo for your vendor',
          button: 'Save',
          input: {
            label: 'Memo',
            placeholder: 'Account ID#, payment description',
            info: 'This will appear on checks and payment notifications',
          },
          combinedPayments: 'Combined payment',
          invoiceNum: 'Invoice no.',
          accountNum: 'Account no.',
        },
        billDetails: {
          title: 'Bill details',
          vendorName: 'Vendor name',
          category: 'Category',
          billOpenBalance: 'Bill open balance',
          dueDate: 'Due date',
          billNo: 'Bill no.',
          noteToSelf: 'Note to self',
          noNote: 'No note',
          noCategory: 'No category',
          noInvoiceNumber: 'No invoice number',
        },
        missingDetails: {
          title: 'Add missing details',
          button: 'Save',
          purposeOfPayment: {
            label: 'Purpose of payment',
            placeholder: 'Select option',
          },
          description: {
            label: 'Description',
            placeholder: 'Write the purpose of this payment',
          },
          invoiceAttachment: {
            title: 'Invoice attachment',
            label: 'Include a file attachment',
          },
        },
        amexVerification: {
          title: 'Verify {companyName} to pay with AMEX',
          button: 'Save',
          info: 'AMEX payments are allowed for approved industries only. Find and select your vendor’s industry below or, please choose another payment method. {link}',
          link: 'Learn more.',
          approvedCategories: {
            label: 'Approved categories',
            placeholder: 'Select vendor’s industry category',
          },
          approvedSubCategories: {
            label: 'Approved sub-categories',
            placeholder: 'Select vendor’s industry subcategory',
          },
        },
        internationalDetails: {
          title: 'Add missing details',
          button: 'Save',
        },
      },
      headers: {
        expander: 'Expander',
        vendor: 'Vendor',
        bill: 'Bill #',
        paymentMethod: 'Payment Method',
        deductionDate: 'Deduction date',
        deliveryMethod: 'Delivery method',
        deliverySpeed: 'Delivery speed',
        enableFastDelivery: 'Enable fast delivery',
        deliveryDate: 'Delivery date',
        arrivalDate: 'Arrival date',
        amount: 'Amount',
        details: 'Details',
        memo: 'Memo',
      },
      fundingSource: {
        creditLabel:
          '{institutionName, select, undefined{} other{{institutionName}}} ...{identifier}',
        achLabel: '{displayName, select, undefined {} other{{displayName}}} ...{identifier}',
        addAnotherPaymentMethod: '+ Add another payment method',
      },
      deliveryDate: {
        estimateNA: 'N/A',
        late: 'LATE',
      },
      renderers: {
        addMethod: '+ Add method',
        chooseMethod: 'Choose method',
        deductionDate: {
          deductedDate: 'Deducted',
          dueDate: 'Bill due',
        },
        bill: {
          hide: 'Hide {billsNum} bills',
          show: 'Show {billsNum} bills',
        },
        memo: {
          tooltip: 'Add memo to vendor',
        },
        remove: {
          tooltip: 'Remove from list',
        },
        amount: {
          tooltip: 'Edit amount',
        },
        billDetails: {
          tooltip: 'View bill details',
        },
        deliverySpeed: {
          cells: {
            fastAch: 'Fast ACH',
            fastCheck: 'Fast Check',
            fastCCToAch: 'Same day delivery',
            notAvailable: 'N/A',
            times: {
              'expedited-ach': 'Same day delivery',
              'overnight-check': 'Next business day',
              'express-check': '3 business days',
              ach: '3 business days',
              achBelow100K: '3 business days',
              achOver100K: '4 business days',
              achDebit: '1 business day',
              achCredit: '1 business day',
              achCreditAmex: '2 business day',
              rppsCreditOrDebit: '2 business days',
              rppsAch: '4 business days',
              check: '5-7 business days',
              virtual: '3-7 business days',
              'virtual-card': '3 business days',
              international: '4 business days',
              virtualCardAch: '3 business days',
              internationalBelowBigTransferThreshold: '4 business days',
              internationalOverBigTransferThreshold: '5 business days',
              beforeCutoffFastACH: 'Same-day',
              afterCutoffFastACH: 'Next-day',
              fastCheck: '3 days delivery',
            },
          },
        },
        fundingSource: {
          cardLabel:
            '{institutionName, select, undefined {} other{{institutionName}}} ...{identifier}',
          achLabel: '{displayName, select, undefined {} other{{displayName}}} ...{identifier}',
          addAnotherPaymentMethod: '+ Add another payment method',
          disabledTooltip:
            'This payment method is not supported for some of the payments in the list.',
        },
        deliveryMethod: {
          addAnotherDeliveryMethod: '+ Add another delivery method',
          deliveryMethodLabels: {
            bank: 'Bank',
            check: 'Check',
            checkDescription: '{displayName}',
            bankDescription: 'Bank account ...{digits}',
            virtualCardDescription: '{displayName}',
            virtual: 'Pending vendor',
            virtualDescription: '{displayName}',
            direct: 'Direct',
            international: 'International',
            virtualCard: 'Single-use card',
            default: ' ',
          },
        },
        arrivalDate: {
          estimateNA: 'N/A',
          today: 'Today',
          tomorrow: 'Tomorrow',
          date: '{date}',
          onTime: 'ON TIME',
          fast: 'FAST',
          popover: {
            title: 'Avoid being late',
            text: 'Change the arrival date to pay on time and avoid delays. It’s a 1% fee when paying with a bank account, free when paying by card.',
            cta: 'Got it',
          },
          price: {
            default: ' (Free)',
            dollar: '({fee})',
            defaultCheck: ' via USPS (Free)',
            dollarCheck: ' via FedEx ({fee})',
            fastTag: 'Fast',
            onTimeTag: 'On Time',
          },
          descriptions: {
            'expedited-ach-today': 'Same business day ',
            'expedited-ach-tomorrow': 'Next business day ',
            'expedited-ach-on-date': 'Same business day ',
            'ach-tomorrow': 'Next business day ',
            'ach-on-date': 'Same business day ',
            ach: '3 business days',
          },
          deliveryExplanationCheck: '{indication} Arriving on {date}',
          deliveryExplanationCheckIndication: 'Trackable.',
          sameDayDeliveryExplanationCheck: 'Arriving on today ',
          deliveryExplanation: 'Vendor receives on {date}',
          sameDayDeliveryExplanation: 'Vendor receives today ',
          default: 'Free',
        },
      },
      internationalBlock: {
        title: 'Can’t schedule multiple international payments',
        content:
          'Scheduling multiple international payments at the same time is unavailable for now, but you can still schedule them individually. ',
        contactSupport:
          'Need further assistance? You can reach us at <link>support@qbousers.meliopayments.com</link>',
        button: 'Go back',
      },
      banners: {
        scheduleLimit: {
          title: 'You can only schedule 20 payments at a time',
          subtitle:
            '20 of the {numOfSelectedBills} bills you previously selected were added below as bill payments that you can now edit end schedule.',
        },
        missingDetails: {
          title: `{numOfErrors} {numOfErrors, plural, one {payment is} other {payments are}} missing details`,
          subtitle: `To schedule, make sure to add the missing details for the {numOfErrors, plural, one {payment} other {payments}} below.`,
        },
        usHolidayUSPCLatency: {
          title: `Potential USPS holiday delays on checks`,
          subtitle: `Switch to fast delivery so your checks gets to your vendors on time this holiday season. {link}`,
          more: 'Learn more',
        },
        internationalStateBlock: {
          title: 'International payments not supported',
          subtitle:
            '{numOfRemovedBills} payment removed as international payments are not supported in your state.',
          subtitleMulti:
            '{numOfRemovedBills} payments removed as international payments are not supported in your state.',
        },
      },
      helpGuide: {
        next: 'Next',
        prev: 'Back',
        done: 'Done',
        gotit: 'Got it',
        of: 'of',
        steps: {
          1: {
            title: 'New & improved',
            body: 'Check out the new version of scheduling multiple payments and the new combine feature for payments to the same vendor.',
          },
          2: {
            title: 'View your payments ',
            body: 'All your bill payments and their details are listed here.',
          },

          3: {
            title: 'Edit payment details',
            body: 'You can edit your bill payments and each of their details directly from the list.',
          },

          4: {
            title: 'Combine into one',
            body: 'Bill payments to the same vendor are combined and sent as one. Toggle off to send them separately.',
            testBody: 'Toggle on to combine multiple payments to the same vendor into one.',
          },
        },
        footer: {
          title: 'See how it works',
          tooltip: 'Want to retake the tour and see how it all works? Just click here.',
        },
      },
      errors: {
        contactEmail: {
          empty: 'Please enter vendor’s email',
        },
      },
      popovers: {
        addVendorEmail: {
          text: `Add the vendor's email and we'll send them a payment confirmation with invoice numbers for reconciliation`,
          button: 'Add email',
        },
        amexVerification: {
          text: `Verify your vendor's industry is approved by AMEX.`,
          button: `Verify`,
        },
        internationalDetails: {
          text: `Some of the required details are missing for this international payment.`,
          button: `Add details`,
        },
      },
      onboarding: {
        footer: {
          title: 'See how it works',
          tooltip: 'Want to see how scheduling multiple payments works? Just click here',
        },
      },
      headSection: {
        totalAmount: 'TOTAL AMOUNT',
        totalFee: 'Total fees',
        cardFee: 'Card processing fees',
        paymentMethod: 'Select payment method',
        multipleMethod: 'Multiple payment method',
        multipleBills: {
          text: 'Multiple payments to same vendor',
        },
        multipleMethodTooltip:
          'Since multiple payment methods are selected, each deduction date must be updated individually.',
        noneSelected: 'None selected',
        deductionDate: {
          title: 'Set deduction date',
          types: {
            due: 'By due date',
            same: 'The same date for all payments',
            multi: 'Multiple deduction date',
          },
        },
        additionalFee: {
          text: 'Additional fees',
          tooltip: 'Fees incurred for fast or international payments',
        },
        checkFee: {
          text: 'Paper check fees',
          tooltip:
            'Paper check fees are billed to the specific payment method used for their billing.',
        },
        combine: {
          tooltip: {
            text: 'Combining into one is only available for multiple payments going to the same vendor. ',
          },
          switch: 'Combine into one',
        },
        subTitle: 'PAYMENTS SETTINGS',
        title: 'Schedule payments',
        tooltip: {
          text: 'Bills from the same vendor are combined and will be sent as 1 payment',
        },
      },
      notifications: {
        flattened: 'Bills from the same vendor will be sent as separate payments',
        combined: 'Bills from the same vendor are combined and will be sent as 1 payment',
      },
    },
    linkCard: {
      modal: {
        notSupportedTitle: 'This card is not supported',
      },
      renderers: {
        title: 'Add your card details',
        subTitle:
          'The vendor doesn’t need to accept cards and you can earn rewards and defer payments.',
        buttons: {
          linkCardDetailsCTA: 'Save my card and continue',
        },
      },
      cardDetails: {
        cardNumber: {
          placeholder: '13-19 digits',
          title: 'Card number',
          emptyMessage: 'Please enter a card number',
          invalidMessage: 'This card number is not valid',
        },
        validThrough: {
          placeholder: 'MM/YY',
          title: 'Valid through',
          emptyMessage: 'Please enter expiration date',
          invalidMessage: 'This expiration date is not valid',
        },
        CVV: {
          placeholder: '3-4 digits',
          title: 'CVV',
          emptyMessage: 'Please enter the security code',
          invalidMessage: 'Minimum of 3 digits required',
        },
      },
      securityComplianceMessage:
        'We keep all your financial data protected so your sensitive information stays hidden. Your card/account details are stored in your profile to save you time on future payments.',
    },
    batchPayment: {
      noPaymentMethod: 'Select payment method',
      deliveryDayPlaceholder: 'Not finalized',
      title: 'Schedule payments ({billsCount})',
      amountTitle: 'TOTAL PAYMENT AMOUNT',
      datePlaceholder: 'Select date',
      methodPlaceholder: 'Set method',
      deliveryPlaceholder: 'Set delivery',
      feesHeader: ' in fees',
      buttons: {
        notifyVendors: 'Notify vendors',
        cancel: 'Cancel',
        scheduleAllPayments: 'Schedule all payments',
      },
      success: {
        title: 'Payments scheduled!',
        subTitle: 'You have successfully scheduled {totalPayments} with a total of {total}',
        subTitleWithApproval:
          'You have successfully scheduled {totalPayments} with a total of {total}.{br}Some of the payments require approval, so we sent a request to the admin of <b>{companyName}</b>.',
        total: '{totalPayedBills} {totalPayedBills, plural, one {payment} other {payments}}',
        buttonLabel: 'I’m done here',
        notes:
          'These bills have been marked as paid and now have Bill Payment records. We’ve also sent a summary of these transactions to your email. Remember, each payment will be processed according to its own deduction date.',
        scheduledNotes:
          'These bills are now marked as <b>paid</b> and have Bill Payments in QuickBooks. Note that each payment will be processed according to its own deduction date.',
        viewQBDashboard: 'View my Online Bill Payments',
        backQBDashboard: 'Back to my Online Bill Payments',
      },
      failed: {
        title: 'Sorry, something went wrong',
        subTitle:
          'It seems something went wrong and no payments have been scheduled. Please retry or email us at {email}',
        buttonLabel: 'Ok. I’ll check back later',
        titleSome: 'Some of your payments were scheduled!',
        subTitleSeveral:
          'You have successfully scheduled {totalPayments} {br} amounting to {total}. However, {vendorsCount} {br} of the {vendorsCount, plural, one {payment} other {payments}} to {vendors} {vendorsCount, plural, one {has} other {have}} not been scheduled. Please chat with us below or email {email} so that we can help you schedule these payments.',
        totalPayments: '{totalPayedBills}',
        vendors: '{vendorsName}',
      },
      paymentListTitle: {
        vendor: 'Vendor',
        deductionDate: 'Deduction date',
        deliveryDate: 'Delivery date',
        paymentMethod: 'Payment method',
        deliveryMethod: 'Delivery method',
        deliverySpeed: 'Delivery Speed',
        amount: 'Amount',
        achLabel: '...{digits}',
        bank: 'Bank',
        check: 'Check',
        paymentDetails: 'Payment Details',
        qbCashLabel: 'QB Checking',
        virtualCard: 'Single-use virtual card',
        international: 'International payment',
        virtualCardTooltip: 'This method was entered by the vendor',
      },
      sidePanel: {
        total: 'total',
        paymentAmount: 'Payment amount',
        payTo: 'pay to',
        payFrom: 'pay from',
        vendor: 'Vendor',
        vendorReceives: 'vendor receives',
        amountSourceError:
          'Payment amount can be equal or lower than bill open balance (<private>{max}</private>)',
        fundingSourceError: 'Please add y our payment method.',
        deductionDateError: 'Please select a deduction date.',
        deliveryMethodError: 'Please add your vendor’s delivery method.',
        dueDate: 'Due date',
        invoice: 'Invoice #',
        memoBank: 'Note to vendor',
        memoCheck: 'Memo to vendor',
        memoPlaceholder: 'Account ID#, payment description',
        noInvoiceNumber: 'No invoice number',
        setDeductionDate: 'Funds deduction date',
        setDeductionDateEmpty: 'Set deduction date',
        dateValue: '{date}',
        estimateDateBank: '{date} by 8 PM local time',
        estimatePayments: 'Estimated payment delivery',
        deliveryDate: 'Delivery Date',
        virtualCardTitle: 'Single-use credit card sent to',
        virtualCardTooltip: 'This vendor has opted to receive{br}payments by single-use card.',
        buttons: {
          cancel: 'Close',
          saveAndContinue: 'Save and continue',
          saveAndComplete: 'Save and complete',
        },
        estimateNA: 'N/A',
      },
      completeLegalInfo: {
        action: 'Save and schedule payments',
      },
      notifications: {
        error: {
          missingDetails:
            '{numOfErrors, plural, one {One} other {<red>#</red>}} of your bills {numOfErrors, plural, one {is} other {are}} missing payment details. Please complete {numOfErrors, plural, one {} other {them}} to continue.',
        },
        warning: {
          filtered: {
            title: 'Some bills has been filtered out because they cannot be scheduled',
          },
          limit: {
            title: 'Batch payments can only schedule {paymentLimit} payments at a time',
            subtitle:
              'Listed below are the first {paymentLimit} bills of the {originalNumOfBills} you previously selected',
          },
          holidays: {
            title: 'Checks might be delayed in holiday season',
            subtitle:
              'To avoid missed due dates, we recommend choosing our fast check delivery option or make sure to schedule your payment ahead of time.',
          },
        },
        success: {
          detailsAdded: 'All missing details have been added',
        },
      },
      fast: {
        ach: 'Fast ACH',
        check: 'Fast Check',
        initialLabel: 'Not finalized',
      },
      date: {
        today: 'Today',
        tomorrow: 'Tomorrow',
      },
      notAvailableTitle: 'Screen size is too small',
      notAvailableMessage:
        'Your resolution needs to be at least 960px wide. Please switch to full screen, refresh and try again or use a device with a larger display.',
      deliveryDayVirtual: 'N/A',
      deliveryVirtualPlaceholder: 'Pending details',
      balanceConfirm: {
        title: 'Payment exceeds current balance',
        description:
          'This payment exceeds the current balance of your QuickBooks Checking account. Please make sure your account has enough funds for this payment so that it doesn’t fail.',
        titlePlural: 'These payments exceed your balance',
        descriptionPlural:
          'The sum total of these payments exceeds the balance in your QuickBooks Checking account. Please make sure your account has enough funds for these scheduled payments so that they don’t fail.',
        confirm: 'Schedule anyway',
        cancel: 'Edit payment',
      },
      deliveryOptions: {
        empty: 'First, set a delivery method for this bill to select a deduction date.',
      },
      vendorsEmailForm: {
        title: 'Add your vendors’ email',
        subtitle: 'We’ll let them know once your payment is on the way.',
        primaryCTA: 'Save and notify',
        fields: {
          email: {
            placeholder: 'Email Address',
          },
        },
      },
      billsPopover: {
        id: 'Bill #',
        dueDate: 'Due date',
        amount: 'Amount',
        count: '{billsNum} bills',
      },
    },
    contractors: {
      title: 'It seems you’re trying to pay a contractor',
      subTitle: 'Paying contractors can be done through the contractors page.',
      buttonLabel: 'Take me there',
    },
    selectMethods: {
      fundingSourcePlaceholder: 'Select payment method',
      deliveryMethodPlaceholder: 'Select how vendor receives',
      selectedDeliveryMethodLabel: '{displayName}',
      selectedVirtualDeliveryMethodLabel: '{displayName}',
    },
    common: {
      rawValue: '{value}',
    },
    entry: {
      header: 'Bill Pay is loading, get ready to...',
      messages: {
        quicklyPay: 'quickly pay any vendor online',
        payWithACH: 'pay using bank transfers for free',
        extendFloat: 'extend float by paying with your credit card',
        scheduleBill: 'schedule bill payments to arrive right on time',
      },
    },
    batchPaymentFunding: {
      header: 'Schedule <private><sb>{count}</sb></private> payments (total <sb>{total}</sb>)',
      title: 'Link a payment method',
      subtitle:
        'Choose what you want to pay with. You can use your bank or card and still send a check, bank transfer, or virtual card to your vendor.',
    },
    connect: {
      error: {
        orgNotFound: {
          title: 'Sorry, something went wrong',
          subtitle: 'We had trouble receiving data about your organization.',
          buttonLabel: 'Ok. I‘ll check back later',
        },
        userNotActive: {
          title: 'Your QuickBooks user is not active anymore. Please reach out to QuickBooks care.',
          buttonLabel: 'Ok',
        },
        userNotExist: {
          title: 'Your QuickBooks user is not active anymore. Please reach out to QuickBooks care.',
          buttonLabel: 'Ok',
        },
        userPermission: {
          title: 'Sorry, Bill Pay is only available for admins',
          subtitle: `Bill Pay was unable to launch because it’s currently only
          available for admin users. Any questions? {chatWithUs}`,
          chatWithUs: 'Chat with us',
          buttonLabel: 'Close',
        },
        general: {
          title: 'We’re sorry but something went wrong. Please retry.',
          buttonLabel: 'Retry',
        },
      },
    },
    accountNotFound: {
      title: 'Bank account not found in Bill Pay',
      text: 'This bank account has been deleted from your Bill Pay account, and therefore can’t be verified. You can re-add your bank account and verify it once your new micro-deposits arrive.{br} If you need any help, {chatWithUs} or {supportMail}.',
      email: 'email',
      chatWithUs: 'chat with us',
      button: 'Ok. Got it',
    },
    drawer: {
      title: 'Frequently asked questions',
      contactLabel:
        'You can find most answers in our <helpCenterLink>Help Center</helpCenterLink>, but if you don’t, prease contact us <supportLink>here</supportLink>, or <chatLink>start a chat.</chatLink>',
      contactLabelMobile:
        'You can find most answers in our <helpCenterLink>Help Center</helpCenterLink>, but if you don’t, prease contact us <supportLink>here</supportLink>',
      emailLabel: 'Email ',
      supportEmailAddress: ' {supportEmail} ',
      orLabel: ' or ',
      chatLabel: 'start a chat',
      faq: {
        question1: "How do I view or edit my vendor's delivery information?",
        answer1:
          "Here's how to view/edit a vendor's delivery method:{br}{br}" +
          '1. Within Bill Pay powered by Melio, select “Settings”, located on the page header in the upper-right corner.{br}{br}' +
          '2. Select "Vendors" and locate the appropriate vendor by name by scrolling through the vendor list.{br}{br}' +
          '3. Select the Vendor, and navigate to "Delivery Methods".{br}{br}' +
          '4. Press the Three Gray Dots located to the right of the delivery method you want to edit (ACH Bank Transfer or Check Delivery).{br}{br}' +
          '5. Select "Edit".  Then, input the new delivery information if necessary and press "Save".',
        question2: 'How do I void a check?',
        answer2: `If you need to void an undeposited check, you can submit your request to our team via the above chat, or via email.  When reaching out, please provide the following information:{br}{br}
          <ulcf>
            <li>Vendor Name</li>{br}
            <li>Payment Amount</li>{br}
            <li>Check Number</li>{br}
            <li>How you would like us to proceed once the check is voided</li>{br}
          </ulcf>
          Once voided, we are able to re-send the payment directly to the vendor, either by issuing a new check, or via ACH bank transfer if the vendor accepts electronic payment.  If ACH is possible, we would be able to get the payment to the vendor within one business day.{br}{br}
          When re-sending payment, Melio <b>will not</b> debit the funds from your payment method again - we will simply re-send the funds we already hold.`,
        question3: 'Where can I see the check number?',
        answer3:
          'For any Bill Pay payment sent out via Paper Check, you will be able to see the check number in two places:{br}{br}' +
          '1. Within the bill payment, the check number will be listed in the "Ref No." space{br}{br}' +
          '2. Select the "View Online Payment" button within the relevant bill payment to see the Payment Details in Bill Pay.  Scroll down to the "Vendor Received" section, and you will see the check number listed.{br}{br}' +
          "Please note that a check number is only generated once the check has been printed and sent out from our (Melio's) bank.",
        question4: 'Can I see a list of all payments made via Bill Pay powered by Melio?',
        answer4: `To see a full list of your unpaid bills and payments made via Bill Pay powered by Melio, you can navigate to the Bill Pay Dashboard. In the current version, here's how to access the Dashboard:{br}{br}
          1. After successfully scheduling a payment in Bill Pay, you will see a button labelled "View my Online Bill Payments" on the payment confirmation screen. Selecting this option will open the Dashboard.{br}{br}
          The Dashboard will organize your bills and payments into three tabs: Unpaid, Scheduled, & Paid.{br}{br}
          <ulcf>
            <li>The "Unpaid" tab will display your open bills and any failed payments.</li>
              <ulce>
                <li>To schedule a payment on an open bill, you can select the "Schedule Payment" option in the "Action" column on the far-right.</li>
                <li>To retry a failed payment, you can select the "Retry Payment" option in the "Action" column.</li>
                <uls>
                  <li>If you do not see the "Retry Payment" option for a failed payment, you can reach out directly to our Support team via the "Need Help" option in the upper-right area of the page, and we can help you resolve the issue.</li>
                </uls>
              </ulce>
              {br}
            <li>The "Scheduled" tab will display payments that have been scheduled, but have not yet begun processing.</li>
              <ulce>
                <li>To edit a scheduled payment, click the drop-down arrow next to "View Payment", and select "Edit Payment". You will then be taken through the payment set-up, where you can edit or change your payment details.</li>
                <li>To cancel a scheduled payment before it begins processing, click the drop-down arrow next to "View Payment", and select "Cancel Payment". The payment will be cancelled, and the associated bill payment will be removed.</li>
              </ulce>
              {br}
            <li>The "Paid" tab will display all sent and completed payments.</li>
              <ulce>
                <li>For payments delivered to your vendor via check, you will see a "Sent" status when the check is sent, and a "Deposited" status once deposited by your vendor.</li>
              </ulce>
          </ulcf>`,
        question5: 'When will my payment arrive?',
        answer5: `Our standard processing times per payment are as follows:{br}{br}
          <ulcf>
            <li>ACH to ACH (< $100k): 3 full business days</li>{br}
            <li>ACH to ACH (≥ $100k): 4 full business days</li>{br}
            <li>Debit Card or Credit Card to ACH: 1 full business day</li>{br}
            <li>ACH/Card to Paper Check: 5-7 business days via USPS</li>
          </ulcf>`,
        question6: 'How do I verify my bank account?',
        answer6:
          'If you connected your bank account to Bill Pay via the "Verify with deposits" option, you should have received two micro-deposits (less than $1 amounts) from "Melio", 1-2 business days after first linking the account.{br}{br}' +
          'Once you have received those two amounts, you can verify your bank account within your Bill Pay Settings.  To do so, navigate to "Settings", and for the bank account you would like to verify, select the blue "Verify account" link.  Then, enter the two deposit amounts you received.',
        question7: 'Can I make a partial payment?',
        answer7: `Partial payments are available on Bill Pay. This option gives you more flexibility in how you choose to pay vendors. It allows you to divide a single invoice into several payments to accommodate installments, milestone payments, and use different methods at various stages of the payment.
          {br}{br}
          Partial payments are available for single payments and batch payments. Below are the instructions for single payments only.
          {br}{br}
          When you schedule a partial payment, Bill Pay will track and display the remaining balance and change the status of the bill to <b>Partially Paid</b>.
          {br}{br}
          <un>How to make partial payments with Bill Pay:</un>
          {br}{br}
          <ol>
              <li>Create the full bill under the relevant vendor, then select <b>Schedule Payment</b>.</li>{br}
              <li>Click the pencil icon next to the open balance and type the amount you’d like to pay.</li>{br}
              <li>Choose your payment method and click <b>Continue</b>.</li>{br}
              <li>Choose when you want the funds to be deducted from your account. The estimated delivery date will appear below. Click <b>Continue</b>.</li>{br}
              <li>Leave a note for your vendor and click <b>Continue</b>.</li>{br}
              <li>A <b>review and confirm</b> page will appear, where you can select how your vendor receives the payment.</li>{br}
              <li>Press <b>Confirm and schedule payment</b> when you're ready.</li>{br}
              <li>To view your open balances, go back to the Unpaid tab of Online Bill Payments. Partially paid invoices will be marked under status.</li>{br}
          </ol>
          When the open balance of a bill reaches $0 it will automatically be removed from the Unpaid tab. The various payments associated with that bill will appear in the Scheduled or Paid tabs and will be labeled according to their stage.{br}{br}
          <b>IMPORTANT NOTE</b>: You can’t partially pay bills that were created before Aug. 31, 2021.
          `,
        question8: 'How can I combine multiple bills from the same vendor into one payment?',
        answer8: `
        Once you’ve selected bills you want to pay to one or multiple vendors, you’ll see a payments table. By default, bill payments going to the same vendor are combined into one.
        {br}
        {br}
        <ol>
          <ulcf>
            <li>Click Show X bills to view which bill payments will be combined.</li>
            <li>Click Hide X bills to hide them again.</li>
            <li>Continue to schedule payments as usual.</li>
            <li>An i icon will show up if the email is missing from a vendor receiving payment for multiple bills. Click the i, then click Add email. Add your vendor's email address and click Save.</li>
          </ulcf>
        </ol>
        {br}
        You can change the setting so that payments aren’t combined. When you see the payments table, simply toggle off Combine into one to undo.
        `,
        question9: 'How can I have my vendor fill out their own ACH or Check information?',
        answer9:
          'As you set up your payment in Bill Pay, you will be prompted to select how your vendor would like to receive their payment.  Here, you can select either ACH bank transfer or Paper Check, and enter the information if you have it already.{br}{br}' +
          'If you would like your vendor to choose their own preferred method, you can select the "Ask vendor for payment details" option.  If you select this option, we will send an email to the vendor with a secure link through which they can choose their preferred method, and enter the ACH or Paper Check information themselves.{br}{br}' +
          'Once your vendor has entered their information, you will be notified via email.',
        question10: 'Can I make an international payment?',
        answer10: `
        Yes. Bill Pay supports international payments in USD only to countries in Europe, Asia-Pacific, Latin America and more. We regularly add new countries, so check to see if your preferred country is available.
        {br}
        {br}
        Important notes:
        {br}
        <ol>
          <ulcf>
            <li>There's a $20 fee for each international payment.</li>
            <li>International payment is only available when paying by bank transfer.</li>
            <li>Payments are delivered via wire transfer.</li>
            <li>If you've sent a domestic payment to a vendor in the past, the option for international payment won’t be available for that vendor.
            </li>
          </ulcf>
        </ol>
        {br}
        {br}
        How to send an international payment:
        {br}
        {br}
        <ol>
          <li>Create a new bill and vendor for the payment. If your vendor has both domestic and international accounts, you’ll still need to create a separate vendor. Just add in a word which identifies this vendor as the one used for international payment.</li>{br}
          <li>When choosing your payment delivery method, select ‘International payment.’</li>{br}
          <li>Select the vendor's bank location from the dropdown list of countries. Not all countries on the list are available for international payments. If you select one of these countries, a message will advise you that you can’t continue with your payment.</li>{br}
          <li>Add your vendor's international payment details. You’ll require your vendor’s SWIFT or IBAN number, bank name and account number. You should be able to find the right code on your vendor's invoice.</li>{br}
          <li>Fill in details about the vendor including the purpose of the payment. The payment won’t be processed without this information.</li>{br}
          <li>Complete and schedule your payment as normal.</li>{br}
        </ol>
        `,
        question11: 'Can you make a payment using foreign currency on Bill Pay?',
        answer11: `QuickBooks gives you the option to add a bill in foreign currency (FX). When you want to schedule a payment for this bill, you will be directed to Bill Pay.
          {br}{br}
          Currently, Bill Pay supports international payments outside the US in USD only.
          {br}{br}
          We plan to add foreign currency to international payments in the near future.
        `,
        question12: 'Are there any fees?',
        answer12: `
        Bill Pay powered by Melio is included in your current QuickBooks Online subscription, at no additional cost.
        {br}{br}
        Standard payments made via bank transfer (ACH) are free. Credit card and debit card payments incur a 2.9% processing fee - which is billed separately by our Bill Pay partner Melio.
        {br}{br}
        International payments in USD incurs a flat $20 fee per transaction.
        {br}{br}

        A breakdown of our fees
        {br}{br}
        {feesTable}
        {br}{br}
        *Fast ACH is not available when paying by credit card.
       `,
        question13: 'Can I make payments to individuals via Bill Pay powered by Melio?',
        answer13:
          'Bill Pay powered by Melio is a business to business (B2B) payment solution.  Therefore, we are not be able to process payments to non-business individuals, or payments made for personal reasons.{br}{br}' +
          'However, if your vendor is a registered sole proprietor, we will be able to facilitate payment to that individual.',
        question14: 'Can my business use Bill Pay powered by Melio?',
        answer14: `Bill Pay is a B2B solution designed to serve small businesses in the United States, including sole proprietorships. Therefore, virtually any business looking to make bill payments to another business can use Bill Pay.{br}{br}
          We are, however, prohibited from working with businesses involved with any of the following:{br}{br}
          <ulcf>
            <li>Internet gambling</li>{br}
            <li>Multi-level marketing firms or any agents that represent them</li>{br}
            <li>Sales of tobacco, marijuana, pharmaceuticals, or nutraceuticals</li>{br}
            <li>Pornography, obscene materials, or any sexual/adult services</li>{br}
            <li>Weapons, ammunitions, gunpowder, fireworks, and any other explosives</li>{br}
            <li>Toxic, flammable or any radioactive material</li>
          </ulcf>`,
        question15: 'How does Melio process my payment?',
        answer15:
          "Melio processes the payments made through Bill Pay on QuickBooks' behalf.  There are two steps in the payment process:{br}{br}" +
          '1. First, Melio collects the funds from your bank account or card.{br}{br}' +
          "2. Then, Melio's bank sends the payment amount out to your vendor for delivery. For payments sent via Paper Check, Melio's bank prints and sends the check out in the mail.",
        question16: 'All the ways to contact Bill Pay’s support team',
        answer16:
          "We're here for you whenever you want to get in touch. Here are all the ways that you can contact Bill Pay’s support team.{br}{br}" +
          'Live Chat{br}{br}' +
          'Use live chat to talk to Bill Pay’s support team (during operating hours).{br}{br}' +
          'NOTE: Support team operating hours{br}' +
          'Our support team is available Monday through Friday, 9AM to 8PM EST. Closed on Federal Holidays.{br}{br}' +
          "Here's how to find it:{br}{br}" +
          '1. Enter Online Bill Payments.{br}' +
          '2. Click the support bubble or Need help?{br}' +
          '3. Our support team will be there to help you.{br}{br}' +
          'Email{br}{br}' +
          'Get in touch at { supportEmail }',
        question17: "Why is my vendor's industry required for American Express card payments.",
        answer17: `From November 22nd 2021, there is an important change in how Bill Pay powered by Melio processes payments made with an American Express credit card. From this date, you’ll only be able to pay vendors from the American Express list of pre-approved industries:{br}{br}
                <ulcf>
                <li>Education</li>{br}
                <li>Government</li>{br}
                <li>Utilities</li>{br}
                <li>Membership Club</li>{br}
                <li>Professional Services</li>{br}
                <li>Business Services</li>{br}
                <li>Inventory/ Wholesale</li>{br}
                <li>Construction/ Logistics</li>{br}
                </ulcf>
                {br}<b>How this change affects your payments</b>{br}{br}
                From November 22nd, you’ll be asked to choose your vendor’s industry when you pay with your American Express card. Note that you’ll only need to do this once per vendor, and you won’t need to choose your own industry
                If your vendor's industry is not on the permitted industries list, you won’t be able to complete the payment with American Express. You can choose an alternative payment method - either a non-American Express card or bank transfer (ACH).`,
        question18: 'What’s a single-use card?',
        answer18: `A single-use card is an online credit card sent via email to your vendor (no physical card is present), which is a popular payout method for Account Payable solutions like Bill Pay. It’s authorized and settled like any other credit card payment that’s processed in a POS terminal, and carries the standard credit card processing fees.
                  For you, it’s free to send and won’t affect how you choose to pay the bill.
                  Delivery time will depend on the type of payment method, for example ACH will take 3 full days to process, then delivered instantly via the single-use card.
                  Once you schedule the payment, your vendor receives an email with the delivery date of the single-use card and can choose to accept the payment by ACH or paper check instead. When they receive the email with the card, they’ll have another chance to change the delivery method to ACH only.
                  {br}{br}<b>Why send a single-use card?</b>{br}{br}
                  It’s a secure way for your vendor to receive payment because it:{br}{br}
                  <ulcf>
                    <li>Doesn’t require your vendor to share their personal bank account details.</li>{br}
                    <li>Features a unique 16-digit digital card number.</li>{br}
                    <li>Is issued for a specific amount to pay a single supplier invoice.</li>{br}
                    <li>Can only be used once.</li>{br}
                    <li>Has a 30-day expiration date.</li>{br}
                  </ulcf>
                  <b>What happens if my vendor doesn’t process the single-use card before the 30-day expiration date?</b>{br}{br}
                  A single-use card expires 30 days from the issue date. We’ll email your vendor reminding them to process the card 14 days before it expires. We’ll also email you that the card has yet to clear, 14 days before it expires.{br}{br}
                  If your vendor hasn't processed the card within 30 days, the payment will be unsuccessful. That's ok, you can easily reschedule by sending another single-use card, or change delivery method to ACH or paper check.
                  `,
        question19: 'An important update in check price',
        answer19: `
        From October 2022, there'll be a $1.50 fee for sending a check. We will notify you of the exact day by email 30 days before the price change goes into effect.{br}
        {br}<b>Why do checks cost $1.50?</b>{br}{br}
        The fee covers printing, postage and handling. There is also an additional layer of security to sending checks with Bill Pay - your bank details are never on the check, helping protect you from fraud and check forgery.{br}
        {br}<b>Upcoming features to make this change smoother</b>{br}{br}
        <b>Combine payments</b> to the same vendor. This means one check can cover several bills from the same vendor. Plus, you save on check fees.{br}{br}
        <b>Want to pay the check fee from a different payment method or entity?</b> You'll be able to define a separate payment method to be used to pay for check fees.{br}
        {br}<b>A breakdown of our check fees</b>{br}{br}
        <ulcf>
        <li>ACH -> ACH payments are free.</li>{br}
        <li>Credit/Debit card -> check payments only incur a 2.9% transaction fee (no $1.50 check fee!)</li>{br}
        <li>ACH -> Fast Check payments incur a $20 fixed fee (no additional $1.50).</li>{br}
        <li>Credit/Debit card -> Fast Check payments cost $20 fixed fee + 2.9% transaction fee.</li>{br}
        </ulcf>
        <b>Payments created before October 2022 won’t be affected</b>{br}{br}
        There’s still no charge for check delivery on the payments you create before October and you can continue making payments as you normally would.{br}
        {br}<b>More about how Bill Pay secures your checks</b>{br}{br}
        Bill Pay takes every precaution to help keep your checks secure from issue date until they’re delivered to your vendor.{br}
        <ulcf>
        <li><b>Your info is secure</b> - Your bank details are never on the check, helping protect you from fraud and check forgery.</li>{br}
        <li><b>Continuous monitoring</b> - You get updated when the checks are sent and deposited.</li>{br}
        <li><b>End-to-end handling</b> - Bill Pay prints and sends bounce-proof checks to your vendor, and can handle potential issues. Voiding checks is free.</li>{br}
        </ulcf>
        `,
        question20: `Potential USPS check delivery delays during the holidays`,
        answer20: `Paying your vendors with paper checks during the holidays? Keep in mind that the {br}
        holiday season typically affects USPS shipping and delivery times, and can delay {br}
        check deliveries to your vendors. {br}{br}

        <b>What causes USPS delays?</b> {br}{br}


        Some contributing factors to these end-of-year shipping delays are unpredictable {br}
        weather conditions, high volume created by the online shopping rush, and {br}
        COVID-19-surges. {br}{br}


        <b>How to avoid USPS delivery delays</b> {br}{br}

        To make sure the check gets to your vendor on time, we suggest you plan ahead and {br}
        schedule your payment in advance. Alternatively, you have two faster delivery {br}
        options: {br}{br}

        <ulcf>
        <li>Switch to sending your payment via ACH delivery (3 business days if you pay with a bank account, next business day if you pay by credit or debit card).</li>  {br}
        <li>Switch to sending a fast check that arrives within 3 business days (delivered via FedEx, $20 fee). You’ll be able to select fast delivery when choosing your payment deduction date.</li>
        </ulcf>
        `,
        question21: `How can I pay multiple bills at once?`,
        answer21: `
        <ol>
            <li>Select multiple bills and click Schedule payments online (X).</li>
            <li>You’ll see a table with all your bill payments. {br}
            Note: Bill payments to the same vendor will be combined into one {br}
            payment. Under Multiple payments to same vendor, toggle off {br}
            Combine into one to undo.{br}
            </li>
            {br}
            Tips to get the most out of scheduling payments:
            <ulcf>
              <li>Pay all your bills at once on a set date, or pay each bill on its due date.</li>
              <li>Sort the table by vendor name, deduction date, delivery date or amount by clicking on the column title.
              </li>
              <li>Select a payment method, deduction date and delivery method for each bill separately. </li>
              <li>Ask a vendor to fill out their payment details if you don’t know their delivery method by clicking +Add method. You can’t schedule the payment if there is missing information for any vendor.
              </li>
              <li>Edit the amount for any scheduled bill using the pencil symbol. This creates a partial payment.
              </li>
              <li>Add a memo for your vendor by clicking the message icon.</li>
            </ulcf>
            {br}
            <li>Once all of your payments are ready to go, you’ll see the total amount to be paid and the total fees on the top right of your screen. Click Schedule payments to continue.
            You can schedule up to 20 bills at once using the Batch Payment feature.
            </li>{br}
        </ol>`,
        fee1PaymentMethod: `ACH bank transfer`,
        fee1DeliveryMethod: `ACH bank transfer`,
        fee1Cost: `Totally free`,
        fee2PaymentMethod: `ACH bank transfer`,
        fee2DeliveryMethod: `Check transfer`,
        fee2Cost: `$1.50 per check`,
        fee3PaymentMethod: `Credit or debit card`,
        fee3DeliveryMethod: `Check or ACH bank transfer`,
        fee3Cost: `2.9% transaction fee (but checks are free)`,
        fee4PaymentMethod: `ACH bank transfer`,
        fee4DeliveryMethod: `Fast check`,
        fee4Cost: `$20 fixed fee`,
        fee5PaymentMethod: `Credit or debit card`,
        fee5DeliveryMethod: `Fast check`,
        fee5Cost: `$20 fixed fee + 2.9% transaction fee`,
        fee6PaymentMethod: `ACH bank transfer`,
        fee6DeliveryMethod: `Fast ACH`,
        fee6Cost: `1%`,
        fee7PaymentMethod: `Debit card*`,
        fee7DeliveryMethod: `Fast ACH`,
        fee7Cost: `1% + 2.9% transaction fee`,
      },
    },
    launcher: { startChat: 'Start a chat' },
    firstTimeNew: {
      title: 'Get started and pay your first bill',
      step: {
        title1: 'Pay with a bank or card',
        subTitle1:
          'Schedule a payment using a bank transfer for free. Or use your credit card or debit card for a {fees_credit_value}% fee.',
        title2: 'Choose how vendor gets paid',
        subTitle2:
          'We’ll mail a check on your behalf or deposit funds to your vendor’s bank at no additional cost.',
        subTitle2Hint: '* Vendors don’t need to sign up or pay any fees',
      },
      description:
        'Bill Pay is a service powered by {melio} with funds held by Evolve Bank & Trust or J.P Morgan. By clicking “Continue”, you agree to Melio’s {termsOfService} and that you have read and acknowledged Melio’s {privacyPolicy}. When you Get Started with Bill Pay, you understand and agree to share your QuickBooks Online data with Melio for purposes of the Bill Pay service. You can find the list of data that may be shared {QBOnlineData}.',
      melio: 'Melio',
      privacyPolicy: 'Privacy policy',
      termsOfService: 'Terms of Service',
      QBOnlineData: 'QuickBooks Online Data',
      button: 'Continue',
      justPay: {
        title: 'Pay vendors online. No bill needed',
        fundingSourceStep: {
          title: 'Pay with QuickBooks Checking',
          subTitle: 'Schedule a payment using QuickBooks Checking account as your payment method.',
        },
        ctaNote:
          'Want to pay an open bill instead?{br}Go to <payBillsLink>Pay Bills</payBillsLink> to schedule payment.',
      },
    },
    landingPage: {
      title: 'Fast, effortless & safe payments with Online Bill Pay',
      subtitle:
        'Over 100,000 businesses use Bill Pay for instant reconciliation and payment flexibility. You can pay the way you want then we’ll send a check or ACH to your vendor. Start by choosing a payment method below.',
      button: 'Continue',
      footnote:
        'Bill Pay is a service provided by Evolve Bank and powered by {melio}. By clicking “Continue”, you agree to Melio’s {terms} and that you have read and acknowledge Melio’s {policy}. When you Get Started with Bill Pay, you understand and agree to share your QuickBooks Online data with Melio for purposes of the Bill Pay service. You can find the list of data that may be shared {data}.',
      footer: {
        text: 'Bill Pay services powered by {melio} with funds held by {banks} (members of the FDIC and the Federal Reserve)',
        melio: 'Melio',
        banks: 'Evolve Bank & Trust or J.P Morgan',
      },
      terms: 'Terms of Service',
      policy: 'Privacy Policy',
      melio: 'Melio',
    },
    paymentMethods: {
      options: 'bank,debit,credit',
      bank: {
        title: 'Bank account',
        subtitle: 'Link an account to pay with',
        fee: 'Free',
        button: 'Link an account',
        advantages: '2 free checks per month,3 day delivery (bank transfer),International payments',
        comment: 'Quickbooks checking included',
      },
      credit: {
        title: 'Credit card',
        subtitle: 'Add a credit card to pay with',
        fee: '2.9% fee',
        button: 'Add a card',
        advantages:
          'Unlimited free checks,Same-day delivery (bank transfer),Defer payments,Extend cash flow,Earn card cashback and rewards',
      },
      debit: {
        title: 'Debit card',
        subtitle: 'Add debit card to pay with',
        fee: '2.9% fee',
        button: 'Add a card',
        advantages: 'Unlimited free checks,Same-day delivery (Bank transfer)',
      },
    },
    fundingSource: {
      title: 'Pay {amount} to {payee}',
      subtitle:
        'Choose the payment method you want to use and we’ll mail a paper check or send a bank transfer to your vendor. You can always change your method later.',
      button: 'Continue',
      footnote:
        'We keep all your financial data protected so your sensitive information stays hidden. Your card/account details are stored in your profile to save you time on future payments.',
      header: {
        payment: 'Schedule a {amount} payment to {payee}',
        help: 'FAQ',
        settings: 'Settings',
        support: 'Need help?',
      },
      tableSections: {
        headers: {
          method: 'Payment method',
          send: 'Send payment as:',
        },
        transfer: 'Bank transfer',
        check: 'Paper check',
        defer: 'Defer payment',
        cashback: 'Cashback and rewards',
      },
      sources: {
        options: 'bank,debit,credit',
        bank: {
          title: 'Bank account',
          fee: 'Free',
          link: 'Link an account',
          comment: '*Including QuickBooks Checking',
          advantages: {
            transfer: '3 day delivery',
            check: '$1.50 fee',
          },
          info: {
            transfer:
              '3 business day delivery for bank transfers. Same-day delivery available for eligible payments (1% fee).',
            check: 'Paper checks incur a $1.50 fee. The first 2 checks every month are free.',
          },
        },
        debit: {
          title: 'Debit card',
          subtitle: 'Add debit card to pay your bills',
          fee: '2.9% fee',
          link: 'Add a card',
          advantages: {
            transfer: 'Same-day delivery',
            check: 'Free',
          },
        },
        credit: {
          title: 'Credit card',
          subtitle: 'Add a credit card to pay your bills',
          fee: '2.9% fee',
          link: 'Add a card',
          advantages: {
            transfer: 'Same-day delivery',
            check: 'Free',
            defer: 'Extend cash flow',
            cashback: 'Earn card points',
          },
          info: {
            defer:
              'Extend cashflow for up to 30 to 105 days depending on your card’s billing schedule',
            cashback: 'Earn points, miles, rewards and more with each payment.',
          },
        },
      },
    },
    bankAccounts: {
      title: 'Choose the account type',
      subtitle: 'Which type of account do you want to link and pay with?',
      options: {
        quickbooks: {
          title: 'QuickBooks Checking',
          subtitle: 'Connect to an account used in QuickBooks.',
        },
        bank: {
          title: 'Bank account',
          subtitle: 'Link and pay with a specific bank account.',
        },
      },
    },
    paymentDashboard: {
      title: 'Online Bill Payments',
      ftuTitle: 'Schedule online payment',
      modeLabel: 'Online payment mode',
      tabs: {
        unpaid: 'Unpaid',
        scheduled: 'Scheduled',
        paid: 'Paid',
      },
      pagination: {
        first: 'First',
        last: 'Last',
        previous: 'Previous',
        next: 'Next',
      },
      search: {
        placeholder: 'Search',
        noResults: {
          queryOnly: {
            title: 'No results for "{term}"',
            description: 'Try searching for another term',
          },
          filtersOnly: {
            title: 'No results found',
            description: 'Try adjusting or clearing the filter to view more results.',
            actionLable: 'Clear filters',
          },
          queryAndFilters: {
            title: 'No results found',
            description: 'Try to use different keywords, check for typos or clear the filter.',
            actionLable: 'Clear filters',
          },
        },
        filters: {
          mainButtonTitle: 'Filter',
          clearLabelMessage: 'Clear all',
          noResultsMessage: 'No results',
          syncingBills: 'Syncing bills',
          menuItems: {
            statusesInDashboard: {
              label: 'Status',
              options: {
                dueInOneWeek: 'Due in 1 week',
                overdue: 'Overdue',
                partiallyPaid: 'Partially paid',
                pending: 'Pending',
                declined: 'Declined',
                failed: 'Failed',
                refunded: 'Refunded',
                scheduled: 'Scheduled',
                bankUnverified: 'Verify account',
                pendingVendorDetails: 'Vendor details',
                sent: 'Sent',
                deposited: 'Deposited',
                markedAsPaid: 'Marked as paid',
              },
            },
            vendors: {
              label: 'Vendors',
              options: { vendorName: '{vendorName}' },
            },
            dueDates: {
              label: 'Due date',
              options: {
                nextSevenDays: 'Next 7 days',
                thisMonth: 'This month',
                nextMonth: 'Next month',
                pastDue: 'Past due',
              },
            },
          },
        },
      },
      footer: {
        title: 'Total to pay: <sb>{totalAmount}</sb>',
        markAsPaid: 'Mark as paid',
        payOnline:
          '{amount, plural, one {Schedule payment} other {Schedule payments}} online ({amount})',
        errorInvalidAmountsTooltip: 'Check that the amounts entered for each payment are valid',
      },
      columns: {
        vendor: { title: 'Vendor' },
        invoiceNumber: { title: 'Bill #' },
        deductionDate: { title: 'Deducted on' },
        dueDate: { title: 'Due date' },
        deliveryEta: { title: 'Delivery eta' },
        amount: { title: 'Amount' },
        billAmount: { title: 'Bill amount' },
        paymentAmount: { title: 'Amount' },
        actions: {
          title: 'Actions',
          view: 'View payment',
          schedule: 'Schedule payment',
          cancel: 'Cancel payment',
          resolve: 'Resolve payment',
          delete: 'Delete',
          edit: 'Edit payment',
          verifyAccount: 'Verify account',
          retryPayment: 'Retry payment',
          markAsPaid: 'Mark as paid',
          markAsUnpaid: 'Mark unpaid',
          deleteBill: 'Delete bill',
          editBill: 'Edit bill',
        },
        unpaidStatus: {
          title: 'Status',
          failed: 'Payment failed',
          overdue: 'Overdue',
          refund: 'Refunded',
          dueLater: 'Due later',
          dueInOneWeek: 'Due in 1 week',
          partiallyPaid: 'Partially paid',
          notApproved: 'Not approved',
          declinedDate: 'On {date}',
        },
        scheduledStatus: {
          title: 'Status',
          unilateral: 'Vendor details',
          unilateralTooltip: 'Pending vendor’s delivery details',
          unverifiedBankAccount: 'Verify account',
          unverifiedBankAccountTooltip:
            'To pay on schedule, verify your bank account by {date} at 12pm EST',
          scheduled: 'Scheduled',
          PendingApproval: 'Pending approval',
          recurring: 'Recurring',
        },
        paymentMethod: {
          title: 'Payment method',
          qbcash: 'QB Checking',
        },
        deliveryMethod: {
          title: 'Delivery method',
          ach: 'Bank transfer',
          virtual: 'Pending',
          virtualCard: 'Single-use virtual card',
          check: 'Paper check',
          tooltip: 'Asking vendor for delivery details',
          international: 'International payment',
          debit: 'Debit card',
        },
        paidStatus: {
          title: 'Status',
          sent: 'Sent',
          deposited: 'Deposited',
          completed: 'Completed',
          markedAsPaid: 'Marked as paid',
        },
        payment: {
          title: 'Payment',
          amountTooBigErrorTooltip: 'Enter amount equal or lower than the bill open balance',
        },
        paidOn: { title: 'Paid on' },
        checkbox: { failedPaymentTooltip: 'Failed payments can’t be selected' },
        openBalance: { title: 'Open balance' },
      },
      listEmptyState: {
        scheduled: {
          title: 'Your payment schedule is clear',
          description: 'Here’s what you can do:',
          primaryBtnText: 'Add bill',
          secondaryBtnText: 'Pay without bill',
        },
        paid: {
          title: 'You have no completed payments',
          description: 'Your payments haven’t been delivered or scheduled yet',
          primaryBtnText: 'View unpaid bills',
          secondaryBtnText: 'See scheduled payments',
        },
        unpaid: {
          title: 'No bills here!',
          description: 'In the future this is where you can add, schedule and pay',
          primaryBtnText: 'Add bill',
          secondaryBtnText: 'Pay without bill',
        },
        syncing: {
          title: 'Syncing bills',
          description:
            'We’re importing your bills, and this can take a while.{br}Meantime, you can add a new bill or send a payment instead.',
          primaryBtnText: 'Send a payment',
        },
      },
      microDeposits: {
        verifyDialogSuccessTitle: 'Bank account verified!',
        verifyDialogSuccessSubTitle:
          'Payments made from this bank account will now be processed without interruption.',
        toastMessage: 'Bank account verified',
      },
      deleteRefundedPayment: {
        title: 'Delete refund from inbox?',
        content:
          'Your’re about to delete bill <sb>#{billId}</sb> for <sb>${amount}</sb> to <sb>{companyName}</sb>',
        cancel: 'Cancel',
        delete: 'Delete',
      },
      cancelPayment: {
        dialogTitle: 'Cancel this bill payment?',
        dialogSubTitle: 'You asked to cancel a <b>{amount}</b> payment to <b>{companyName}</b>.',
        dialogOkButtonText: 'Yes',
        dialogCancelButtonText: 'No',
        toastMessage: 'Payment cancelled',
      },
      schedulePayment: {
        toastMessage: {
          edit: 'Payment updated',
          pay: 'Payment scheduled',
          batch: '{count} payments scheduled',
        },
      },
      notifyVendors: {
        toastMessage: {
          success: 'Your vendors will be notified',
          error: 'Notifying vendors has failed',
        },
      },
      ftuModal: {
        title: 'Your bills and payments, now in one place!',
        ctaButton: 'Got it',
        items: {
          view: 'View your unpaid bills and payments',
          manage: 'Manage bill payments from start to finish',
          schedule: 'Schedule batch payments',
          switch: 'Switch between Bills and Payments modes in a click',
        },
      },
      toggleOffModal: { title: 'Loading Pay bills' },
      markAsPaid: {
        toastMessageSingle: '1 payment marked as {meta_identifier_actionType}',
        toastMessageUnpaidSingle: '1 payment marked as unpaid',
        toastMessageBatch: '{count} bills marked as paid',
        dialogTitle: 'Which account was used for this payment?',
        dialogTitleBatch: 'Which account was used for these payments?',
        dialogTitlePartial: 'Mark as paid',
        dialogSubTitle:
          'Review and confirm the amount paid and which account you used for this payment.',
        dialogSubTitleBatch: 'Which account you used for these payments.',
        intuitAccountLabel: 'Bank account in QuickBooks',
        intuitAccountLabelPartial: 'Payment account in QuickBooks',
        intuitAccountPlaceholder: 'Select account',
        paymentAmountLabel: 'Payment amount',
        paymentAmountLabelPlaceholder: '$0.00',
        ctaSave: 'Save',
        ctaMarkAsPaid: 'Mark bills as paid',
        ctaSaveAndClose: 'Save and close',
        errors: {
          invalidAmount: 'Enter an amount equal or lower than bill open balance ({balance})',
          zeroAmount: 'Enter a payment amount',
        },
      },
      editVendor: {
        title: 'Contact Details',
        success: '{vendorName} details updated',
        name: 'Business name',
        email: 'Business email',
        save: 'Save',
      },
      setPayBillView: {
        title: 'A new home for all your bills and payments',
        options: {
          first: 'View your unpaid bills and payments',
          second: 'Manage bill payments from start to finish',
          third: 'Keep track of your scheduled payments',
        },
        buttons: {
          default: 'Set Pay Bills Online mode as default',
          later: 'Maybe later',
        },
      },
      tooltips: {
        notEligibleForPartialPayment:
          'You can only pay partial amount for bills added after August 31, 2021',
        setPayBillView:
          'Tip: Toggle {on} to track payments in Online payments mode or {off} to return to Pay Bills.',
      },
      addBillToDashboard: {
        addNewBillButton: {
          buttonTitle: 'New',
          addBillOption: 'Add a bill',
          payWithoutBillOption: 'Pay without a bill',
          internationalPayment: {
            label: 'International payment',
            tooltip:
              'Temporarily, international payments are unavailable for organizations or businesses registered in your state.',
          },
        },
        billModal: {
          titles: {
            main: 'Bill details',
            vendorInputSelect: "Vendor's name",
            categoryInputSelect: 'Category',
            amountInput: 'Amount',
            billNumberInput: 'Bill #',
            dueDateInput: 'Due Date',
            memoInput: 'Memo to self',
          },
          placeholders: {
            vendorInputSelect: 'Search vendor',
            categoryInputSelect: 'Uncategorized expense',
            amountInput: '$0.00',
            amountNotice: 'Currently, international payments can only be sent and received in USD.',
            billNumberInput: 'e.g 2000000',
            dueDateInput: 'Select due date',
            memoInput: 'e.g office expenses',
          },
          buttons: {
            schedulePayment: 'Schedule payment',
            saveAndClose: 'Save and close',
            cancel: 'Cancel',
          },
          inputErrors: {
            vendorId: { empty: 'Select a vendor' },
            totalAmount: {
              empty: 'Enter bill amount',
              min: 'Enter an amount greater than $0.00',
              max: 'Enter an amount less than $1,000,000',
              partial: 'Enter an amount larger than total payments already made ',
            },
            dueDate: {
              empty: 'Select a due date',
              recurringEmpty: 'Please enter a delivery date for the first payment',
            },
            note: { regex: 'Enter a valid memo' },
            occurrences: {
              weekly: 'Invalid number (please use 1-60)',
              monthly: 'Invalid number (please use 1-24)',
            },
          },
          notifications: {
            billSaved: 'Bill {billId} saved',
            billUpdated: 'Bill {billId} has been updated',
          },
          tooltips: {
            inEligibleForInternational:
              'Temporarily, international payments are unavailable for organizations or businesses registered in your state.',
          },
        },
      },
      deleteBillModal: {
        deleteSuccessNotification: 'Bill {billId} has been deleted',
        dialogDeleteButtonText: 'Delete',
        dialogCancelButtonText: 'Cancel',
        title: 'Are you sure?',
        subtitle:
          'Your’re about to delete bill <b>#{billId}</b> for <b>{amount}</b> to <b>{companyName}</b>',
      },
    },
    international: {
      vendorLocation: {
        title: 'Where is {companyName}‘s bank located?',
        country: {
          label: 'Vendor’s bank country',
          placeholder: 'Select country',
        },
        button: 'Continue',
        notSupportedCountriesModal: {
          title: 'Payments to {countryName} are not available yet',
          subtitle:
            'International payments to {countryName} aren’t available just yet, but we’re working to change that and will notify you as soon as it is.',
          cta: 'Got it',
        },
        errorModal: {
          title: 'Invalid details',
          text: 'The details entered appear to be incorrect. Make sure everything looks right and try again.',
          cta: 'Try again',
        },
      },
      paymentDetails: {
        title: 'What are {companyName} payment details?',
        country: {
          label: 'Vendor’s bank country',
          placeholder: 'Select country',
        },
        amount: {
          label: 'Send amount',
          notice: 'International payment fee: {fee}',
        },
        currency: { label: 'Currency' },
        iban: {
          label: 'IBAN',
          placeholder: 'EX: GB33BUKB20201555555555',
        },
        accountNumber: {
          label: 'Account Number',
          placeholder: 'EX: GB33BUKB20201',
        },
        bankName: {
          label: 'Bank Name',
          placeholder: 'EX: BARCLAYS BANK UK PLC',
        },
        bic_swift: {
          label: 'SWIFT Code',
          placeholder: 'EX: CITIAUSXXXX',
        },
        button: 'Continue',
        moreCountries: 'Other countries coming soon!',
        editPayment: 'To change the amount, go back and edit the bill',
        footer:
          'We’ll save these details for future payments to this vendor. You can always edit these details by going to your vendors settings.',
      },
      vendorDetails: {
        title: 'Tell us about {companyName}',
        button: 'Save payment details',
        message:
          'These details will be used for future payments to this vendor. You can always edit them in your vendors section.',
        fields: {
          business_name: {
            label: 'Legal business name',
            placeholder: 'Ex.: THE CAR COMPANY LTD',
            hint: 'The legal and official name of the business.',
          },
          name: {
            label: 'Company name',
            placeholder: 'Ex.: Car company',
            hint: 'The business name as listed on the bank account.',
          },
          address: {
            label: 'Company address',
            placeholder: 'House number, Street, State',
            hint: 'Search and select an address from the list',
          },
          city: {
            label: 'City',
            placeholder: 'City',
          },
          postcode: {
            label: 'Postal code',
            placeholder: 'Postal code',
          },
          state_or_province: {
            label: 'State / District ',
            placeholder: 'State / District ',
          },
          country: {
            label: 'Country',
            placeholder: 'Country',
          },
        },
      },
      missingDetails: {
        title: 'Add your business details',
        subtitle:
          'We just need this once to verify your business before making international payments.',
        button: 'Save and continue',
        loading: 'Updating details...',
        einHelperText: "This will require a few details about the company's ownership",
      },
      purposeOfPayment: {
        title: 'What is the purpose of your payment?',
        purpose: {
          label: 'Purpose of payment',
          placeholder: 'Select option',
        },
        description: {
          label: 'Description',
          placeholder: 'Write the purpose of this payment',
        },
        button: 'Continue',
        invoiceUpload: {
          label: 'Invoice attachment',
          required: 'Please attach an invoice',
          tooltip:
            'An invoice is required when sending international payments to the country you selected.',
          title: 'Include a file attachment',
          error: 'Oops. Something went wrong',
          retry: 'Try again',
          options: {
            goods: 'Goods',
            services: 'Services',
            charitableDonations: 'Charitable donations',
            other: 'Other',
          },
        },
      },
      ultimateBeneficialOwners: {
        title: "{companyName}'s ownership",
        subtitle:
          'For all international payments, please add the following details for all individuals with 25% or more ownership. In addition, please add information for a key decision maker.',
        formTitle: 'OWNER / KEY DECISION-MAKER',
        ownerTooltipContent:
          'Individuals with the authority to make key decisions on behalf of the company',
        taxTypeTooltipContent:
          'SSN (US residents only) or Individual Taxpayer Identification Number (non-US residents)',
        inputLabels: {
          firstName: 'First name',
          lastName: 'Last name',
          dateOfBirth: 'Date of birth',
          taxId: 'SSN/ITIN',
          address: "Owner's personal address",
        },
        buttons: {
          add: 'Add another owner',
          remove: 'Remove owner',
          save: 'Save details and continue',
        },
        inputErrors: {
          firstName: { empty: 'Enter first name' },
          lastName: { empty: 'Enter last name' },
          dateOfBirth: {
            empty: 'Enter date of birth',
            regex: 'Invalid date of birth',
          },
          taxId: {
            empty: 'Enter SSN/ITIN',
            regex: 'Invalid SSN/ITIN',
          },
          address: { empty: 'Search and select an address from the list' },
          addressNote: { empty: 'No PO boxes, except for military' },
        },
        inputPlaceholders: {
          dateOfBirth: 'MM/DD/YYY',
          taxId: 'XXX-XX-XXXX',
          address: 'Ex: 177a Bleecker Street, New York, NY, USA',
        },
        missingFieldsError: 'Add missing details or remove individuals without ownership.',
        inputNotices: { address: 'No PO boxes, except for military' },
      },
    },
    justPay: {
      defaultHeader: 'Schedule a {amount} {breakLine}payment to {vendorCompanyName}',
      wizard: { header: 'Schedule a payment' },
      paymentInfo: {
        title: 'Add payment info',
        categorySelectTitle: 'Category',
        categorySelectPlaceHolder: 'Uncategorized expense',
        amount: {
          title: 'Amount',
          placeholder: '$0.00',
        },
        instruction: 'To avoid delays please include invoices for payments that exceed $10,000',
        international: {
          disableTooltip:
            'Temporarily, international payments are unavailable for organizations or businesses registered in your state.',
        },
      },
      deductionDate: {
        title: 'When would you like this payment deducted?',
        subtitle: 'Choose the date for your payment to go out.',
      },
      noteToVendor: {
        title: 'Leave a note for your vendor',
        placeholder: 'Account ID#, payment description',
        label: 'Note to vendor',
        hint: 'This will appear on checks and payment notifictions',
      },
      fundingSource: {
        title: 'Choose how you want to pay',
        subtitle: 'We’ll send a bank transfer or mail a paper check',
      },
      checkDeliveryMethod: { header: 'Schedule a {amount} payment to {vendorCompanyName}' },
      errorPage: {
        title: 'Sorry, something went wrong',
        subtitle:
          'We couldn’t load Bill Pay services. Please try again or reach out to us via chat for assistance',
        buttonLabel: 'Start chat',
      },
      deliveryMethods: {
        title: 'How would <b>{vendor}</b> like to receive this payment?',
        cta: 'Save bank details',
        footer:
          'These details will be used for future payments to this vendor. You can always edit them in your vendors section.',
        bank: {
          routingNumber: 'Vendor’s routing number (ACH)',
          accountNumber: 'Vendor’s account number',
        },
        check: {
          title: 'Where should we mail the check to?',
          cta: 'Submit',
          ctaAddressConfirm: 'Confirm address and save',
        },
      },
      introModal: {
        title: 'Pay vendors online.{br}No bill needed',
        newTag: 'NEW',
        options: {
          addPaymentDetails: 'Simply add payment details',
          chooseDeliveryMethod: 'Choose how the vendor gets paid',
          weHandleSync: 'We’ll handle the bill and payment records for you',
        },
        cta: 'Continue',
        ctaNote:
          'Want to pay an open bill instead? Go to <payBillsLink>Pay Bills</payBillsLink> to schedule payment.',
      },
    },
    holidaysWarning: {
      tooltipLabels: {
        default: 'Checks may be delayed with USPS this holiday season',
        scheduled: 'Your check may be delayed with USPS this holiday season',
        detailed:
          'To make sure payment arrives by due date, switch the delivery method to ACH or select fast check.',
      },
      labels: { unilateral: { check: 'USPS expects delays' } },
      deductionDate: {
        warning: {
          title: 'Potential USPS holiday delays on checks',
          subtitle:
            'Switch to fast delivery so your check gets to your vendor on time this holiday season. {link}',
        },
        info: {
          title: 'Check deliveries may be delayed this holiday season',
          subtitle:
            'To avoid delays or miss due dates, make sure to schedule your payment ahead of time.',
        },
      },
    },
    amexVerification: {
      modal: {
        title: 'Verify {vendorName}’s industry to pay with AMEX',
        description:
          'AMEX payments are allowed for approved industries only. Find and select your vendor’s industry below or, please choose another payment method.',
        submit: 'Save and continue',
        batchSubmit: 'Save',
        form: {
          industry: {
            label: 'Approved categories',
            placeholder: 'Choose vendor’s industry category',
            empty: 'Select vendor’s industry category',
          },
          code: {
            label: 'Approved sub-categories',
            placeholder: 'Choose vendor’s industry sub-category',
            empty: 'Select vendor’s industry sub-category',
          },
        },
        learnMore: 'Learn more.',
      },
      paymentMethod: {
        description: 'Limited for vendors in industries approved by AMEX. {link}',
      },
      toast: '{vendorName}’s industry has been verified.',
      tooltip: { title: 'Limited for vendors in industries approved by AMEX' },
      methodErrors: {
        creditPaymentMethod: {
          label:
            'Limited for vendors in industries approved by AMEX.{br}<value>Verify industry</value>',
        },
      },
    },
    creditCardBenefits: {
      title: 'Why pay with a credit card?',
      deferPayments: {
        title: 'Defer payments',
        label: 'Hold onto money longer and improve cash flow when you use a credit card.',
      },
      earnRewards: {
        title: 'Earn rewards',
        label: 'Collect cash back, perks, points, miles, and more rewards with each payment.',
      },
      fasterDelivery: {
        title: 'Faster delivery',
        label:
          'Unlock same-day delivery when paying before 12:00 PM EST or 1 day delivery for after, so your vendors get paid sooner.',
      },
      bottomLabel: '2.9% processing fee can be tax-deductible.',
      button: 'Pay by card',
    },
    internationalPayment: {
      learnMoreModal: {
        title: 'International payments',
        easilyPay:
          'Easily pay global vendors in over 70 countries around the world with seamless and instant reconciliation.',
        directlySent: 'Payments are sent directly via bank transfer in USD.',
        noSignUp: "Vendors don't need to sign up to get paid.",
        flatFee: 'It’s a $20 flat fee, no matter the amount.',
        button: 'Got it',
      },
      internationalBill: {
        title: 'Enter international bill details',
        subtitle: 'Select a vendor and add the bill details to send an international payment. ',
        learnMore: 'Learn more',
        flatFee: 'Sending an international payment incurs a $20 flat fee no matter the amount.',
        chooseVendor: {
          title: 'Choose an international vendor',
          subtitle: 'Can’t find an existing vendor? Add them again as an international vendor. ',
        },
      },
      vendor: {
        select: {
          title: 'International vendor',
          placeholder: 'Search or add an international vendor',
          addInSelect: 'Add <sb>{value}</sb> as a new international vendor',
          notice: 'Can’t find an existing vendor? Add them again as an international vendor',
        },
      },
      fundingSource: {
        infoCard:
          'International payments can be made only when paying with a bank account or QuickBooks Checking.',
        infoCardMastercard:
          'International payments can be made when paying via bank account, QuickBooks Checking or Mastercard.',
      },
    },
    debitFee: {
      modal: {
        title: 'Debit card now has a fee',
        description:
          'As of June 15, paying with a debit card will incur a 2.9% processing fee.{br}{br}This won’t affect payments already scheduled.',
        confirm: 'Got it',
        decline: 'Change payment method',
      },
    },
    checkFee: {
      modal: {
        title: 'Check Protection',
        description:
          'Bill Pay helps keep your checks secure from issue date until they’re delivered to your vendor.',
        confirm: 'Got it',
        title1: 'Securing your info',
        text1:
          'Your sensitive details are never on the check, helping protect you from fraud and check forgery.',
        title2: 'Continuous monitoring',
        text2: 'Checks are actively tracked, and you get updated when they’re sent and deposited.',
        title3: 'End-to-end handling',
        text3:
          'Bill Pay prints and sends bounce-proof checks to your vendor, and can handle any potential issues. Voiding checks is free.',
      },
    },
    refundSent: {
      success: {
        title: 'Your refund’s on the way',
        subtitle:
          'We’ve sent a refund for ${amount} to your bank account ending in {4digits}. It may take up to 5 business days before you see the funds. ',
      },
      pops: {
        title: 'Refund request sent',
        subtitle:
          'We’re processing your refund. It may take up to 5 business days before you see the funds in your account.',
      },
      button: 'View refund details',
    },
  },
};
