import React from 'react';
import { Box, Flex, Badge, Link, VStack } from '@melio/billpay-design-system';

import {
  Backdrop,
  ModalContainer,
  ModalWrapper,
  ModalContentContainer,
} from 'src/app/components/common/ModalMessage';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/app/utils/consts';

export const JustPayIntroModal = ({ dismiss, onContinueClick, onPayBillsRedirectClick }) => {
  const options = [
    'justPay.introModal.options.addPaymentDetails',
    'justPay.introModal.options.chooseDeliveryMethod',
    'justPay.introModal.options.weHandleSync',
  ];

  return (
    <ModalWrapper>
      <Backdrop />
      <ModalContainer>
        <ModalContentContainer>
          <Box>
            <Badge variant="primary">
              <MIFormattedText label="justPay.introModal.newTag" />
            </Badge>
          </Box>
          <Box textStyle="h3" fontWeight="600" mt="1rem">
            <MIFormattedText label="justPay.introModal.title" />
          </Box>

          <VStack
            textStyle="body2"
            fontWeight="600"
            spacing="1rem"
            align="flex-start"
            mb="4rem"
            mt="4rem"
          >
            {options.map((option, index) => (
              <Box key={option}>
                <Box as="span" color="primary.500">
                  {index + 1}.&nbsp;&nbsp;
                </Box>
                <MIFormattedText label={option} />
              </Box>
            ))}
          </VStack>

          <Flex direction="column" align="center">
            <MIButton
              onClick={() => {
                dismiss();
                onContinueClick();
              }}
              variant={BUTTON_VARIANT.PRIMARY}
              label="justPay.introModal.cta"
            />

            <Box mt="2rem" textStyle="body2">
              <MIFormattedText
                label="justPay.introModal.ctaNote"
                values={{
                  payBillsLink: (...content) => (
                    <Link cursor="pointer" onClick={onPayBillsRedirectClick}>
                      {content}
                    </Link>
                  ),
                }}
              />
            </Box>
          </Flex>
        </ModalContentContainer>
      </ModalContainer>
    </ModalWrapper>
  );
};
