import * as React from 'react';
import styled from 'styled-components';
import pciIcon from 'src/app/images/icons/pci-dss-logo.png';
import lockIcon from 'src/app/images/icons/privacy.svg';
import { devices } from 'src/app/theme/AppDevices';
import { MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';

type Props = {
  eventPage: string;
  label?: string;
  values?: Record<string, any>;
  showIconCC?: boolean;
};

const MISecurityDetails = ({ eventPage, label, values }: Props) => {
  const goSecurityPage = () => {
    analytics.track(eventPage, 'go-security-page');
    window.open('https://www.meliopayments.com/security');
  };

  return (
    <SecurityDetails>
      <LockContainer>
        <LockImage src={lockIcon} alt="lock" />
        <LockText>
          <MIFormattedText
            label={label || 'guests.register.securityText'}
            values={{
              ...values,
              link: (
                <GoSecurityPage onClick={goSecurityPage}>
                  <MIFormattedText label="guests.register.learnMore" />
                </GoSecurityPage>
              ),
            }}
          />
        </LockText>
      </LockContainer>
      <Image src={pciIcon} alt="pci" />
    </SecurityDetails>
  );
};

export default MISecurityDetails;

const Image = styled.img`
  height: 3.4rem;
  margin-left: 1.3rem;
`;

const SecurityDetails = styled.div`
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 45rem;
`;

const LockContainer = styled.div`
  display: flex;
  ${(props) => props.theme.text.fontType.small};
`;

const LockImage = styled.img`
  height: 4rem;
  margin-right: 1.3rem;
  width: 3rem;
  margin-top: -0.6rem;
  min-width: 3rem;

  @media ${devices.mobile} {
    height: 3.5rem;
    margin-top: 0.5rem;
  }
`;

const LockText = styled.div`
  text-align: left;
  color: ${(props) => props.theme.text.color.subtitle};
  line-height: 1.4rem;
`;

const GoSecurityPage = styled.span`
  color: ${(props) => props.theme.colors.brand};
  cursor: pointer;
`;
