import { BatchBulkPaymentIntent } from 'src/app/version-2/pages/batch-bulk/model/dtos/batchBulkPaymentIntent';

export const removeCharsAndParseIds = (batchBulkIdsArr: (string | string[])[]): number[] =>
  batchBulkIdsArr
    .flat()
    .map((billId) => billId.replace(/bill-/g, '').split(','))
    .flat()
    .map((item) => parseInt(item, 10));

export const isEligibleToBulkPayment = (
  batchList: BatchBulkPaymentIntent[],
  numOfBills: number
) => {
  if (batchList.length < numOfBills) return true;

  const vendorIdsList = batchList.map((item) => item?.payment?.vendorId);
  const vendorIdsListWithoutDuplicates = new Set(vendorIdsList);

  if (vendorIdsList.length > [...vendorIdsListWithoutDuplicates].length) return true;

  return false;
};
