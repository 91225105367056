import React from 'react';
import { Box, Divider, Image, Flex, theme } from '@melio/billpay-design-system';
import { ModalMessage } from 'src/app/components/common/ModalMessage';
import { CheckListItem } from 'src/app/components/common/CheckListItem';
import { MIFormattedText } from 'src/app/utils/formatting';
import virtualCardInfoImage from 'src/app/images/virtual-card/virtual-card-info-image.svg';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { useBreak } from 'src/app/hoc';

enum STYLE_KEYS {
  COMMON = 'common',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

type VirtualCardInfoModalProps = {
  isVisible: boolean;
  onToggle?: () => void;
  onSubmit: () => void;
  customLabels?: { [key: string]: string };
};

const INFO_MODAL_TRANSLATION_PATH = 'deliveryMethods.notDefinedInfo.virtual-card.infoModal';

const getLabels = (customLabels) => ({
  primaryCTA: customLabels?.primaryCTA || `${INFO_MODAL_TRANSLATION_PATH}.primaryCTALabel`,
  cancelCTA: customLabels?.cancelCTA || `${INFO_MODAL_TRANSLATION_PATH}.secondaryCTALabel`,
});

const VirtualCardInfoModal = (props: VirtualCardInfoModalProps): React.ReactElement | null => {
  const { isVisible = false, onToggle, onSubmit, customLabels } = props;

  const { isMobile } = useBreak();
  const stylesKey = isMobile ? STYLE_KEYS.MOBILE : STYLE_KEYS.DESKTOP;

  if (!isVisible) return null;

  return (
    <ModalMessage
      titleComponent={
        <>
          <Image
            w="full"
            h="full"
            src={virtualCardInfoImage}
            mb={8}
            __css={styles[stylesKey].titleImage}
          />
          <Box textStyle="h5" fontWeight="ds.semi" __css={styles[stylesKey].title}>
            <MIFormattedText label={`${INFO_MODAL_TRANSLATION_PATH}.title`} />
          </Box>
        </>
      }
      withTitleContainer={!isMobile}
      descriptionComponent={
        <>
          <Box textStyle="body2" mb={5} __css={styles[STYLE_KEYS.COMMON].descriptionTitle}>
            <MIFormattedText label={`${INFO_MODAL_TRANSLATION_PATH}.subtitle`} />
          </Box>
          <CheckListItem
            title={`${INFO_MODAL_TRANSLATION_PATH}.checkMark.safe.title`}
            subtitle={`${INFO_MODAL_TRANSLATION_PATH}.checkMark.safe.subtitle`}
          />

          <CheckListItem
            title={`${INFO_MODAL_TRANSLATION_PATH}.checkMark.flexible.title`}
            subtitle={`${INFO_MODAL_TRANSLATION_PATH}.checkMark.flexible.subtitle`}
          />
          <Divider bg="gray.400" height="0.1rem" mt={8} />
        </>
      }
      footerComponent={
        <Flex __css={styles[stylesKey].footerComponent}>
          <MIButton
            onClick={onToggle}
            label={getLabels(customLabels).cancelCTA}
            variant={CONSTS.BUTTON_VARIANT.CANCEL}
            fullWidth={isMobile}
            customStyles={styles[stylesKey].cancelButton}
          />
          <MIButton
            onClick={onSubmit}
            label={getLabels(customLabels).primaryCTA}
            variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          />
        </Flex>
      }
      footerContainerCustomStyles={styles[STYLE_KEYS.COMMON].footerContainer}
    />
  );
};

const styles = {
  common: {
    descriptionTitle: {
      textAlign: 'left',
    },
    footerContainer: {
      marginBottom: 0,
    },
  },
  mobile: {
    titleImage: {
      display: 'none',
    },
    title: {
      marginBottom: '1rem',
    },
    footerComponent: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      height: '9rem',
    },
    cancelButton: {
      backgroundColor: theme.colors.ds.white,
      borderColor: theme.colors.ds.white,
      color: theme.colors.ds.blue[200],
    },
  },
  desktop: {
    titleImage: {
      display: 'block',
    },
    title: {},
    footerComponent: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '0.9rem',
    },
    cancelButton: {
      backgroundColor: theme.colors.white,
    },
  },
};

export { VirtualCardInfoModal };
