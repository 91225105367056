import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { StatusInfoType } from 'src/app/utils/types';
import { CONSTS, PAYMENT_STATUS } from 'src/app/utils/consts';
import { isPaymentDelivered } from 'src/app/pages/quickpay/bills/components/utils';

type Props = {
  statusInfo: StatusInfoType;
  className?: string;
  latestPayment?: any;
};

const QBRBillStatus = ({ statusInfo, className, latestPayment }: Props) => {
  const { color, status } = statusInfo;

  const getFormattedDate = (date) => <MIFormattedDate date={date} />;

  const CONTENT_BY_STATUS = {
    [PAYMENT_STATUS.FAILED]: {
      [CONSTS.DELIVERY_TYPE.ACH]: {
        label: 'bills.list.paymentStatus.failed',
        description: 'bills.list.paymentStatus.failedBillStatus.ach.description',
      },
      [CONSTS.DELIVERY_TYPE.CHECK]: {
        label: 'bills.list.paymentStatus.failed',
        description: 'bills.list.paymentStatus.failedBillStatus.check.description',
      },
      [CONSTS.DELIVERY_TYPE.VIRTUAL]: {
        label: 'bills.list.paymentStatus.failed',
        description: 'bills.list.paymentStatus.failedBillStatus.virtual.description',
      },
    },
    [PAYMENT_STATUS.BLOCKED]: {
      [CONSTS.DELIVERY_TYPE.ACH]: {
        label: 'bills.list.paymentStatus.scheduled',
        description: 'bills.list.paymentStatus.scheduledBillStatus.ach.description',
      },
      [CONSTS.DELIVERY_TYPE.CHECK]: {
        label: 'bills.list.paymentStatus.scheduled',
        description: 'bills.list.paymentStatus.scheduledBillStatus.check.description',
      },
      [CONSTS.DELIVERY_TYPE.VIRTUAL]: {
        label: 'bills.list.paymentStatus.scheduledVirtual',
        description: 'bills.list.paymentStatus.scheduledBillStatus.virtual.description',
      },
    },
    [PAYMENT_STATUS.SCHEDULED]: {
      [CONSTS.DELIVERY_TYPE.ACH]: {
        label: 'bills.list.paymentStatus.scheduled',
        description: 'bills.list.paymentStatus.scheduledBillStatus.ach.description',
      },
      [CONSTS.DELIVERY_TYPE.CHECK]: {
        label: 'bills.list.paymentStatus.scheduled',
        description: 'bills.list.paymentStatus.scheduledBillStatus.check.description',
      },
      [CONSTS.DELIVERY_TYPE.VIRTUAL]: {
        label: 'bills.list.paymentStatus.scheduledVirtual',
        description: 'bills.list.paymentStatus.scheduledBillStatus.virtual.description',
      },
    },
    [PAYMENT_STATUS.IN_PROGRESS]: {
      [CONSTS.DELIVERY_TYPE.ACH]: {
        label: 'bills.list.paymentStatus.processing',
        description: 'bills.list.paymentStatus.scheduledBillStatus.ach.description',
      },
      [CONSTS.DELIVERY_TYPE.CHECK]: {
        label: 'bills.list.paymentStatus.processing',
        description: 'bills.list.paymentStatus.scheduledBillStatus.check.description',
      },
      [CONSTS.DELIVERY_TYPE.VIRTUAL]: {
        label: 'bills.list.paymentStatus.processing',
        description: 'bills.list.paymentStatus.scheduledBillStatus.virtual.description',
      },
    },
    [PAYMENT_STATUS.COMPLETED]: {
      [CONSTS.DELIVERY_TYPE.ACH]: {
        label: 'bills.list.paymentStatus.completed.ach',
        description: 'bills.list.paymentStatus.completedBillStatus.ach.description',
      },
      [CONSTS.DELIVERY_TYPE.CHECK]: {
        label: isPaymentDelivered(latestPayment)
          ? 'bills.list.paymentStatus.completed.checkDeposited'
          : 'bills.list.paymentStatus.completed.checkSent',
        description: isPaymentDelivered(latestPayment)
          ? 'bills.list.paymentStatus.completedBillStatus.check.checkDeliveredDescription'
          : 'bills.list.paymentStatus.completedBillStatus.check.checkSentDescription',
      },
      [CONSTS.DELIVERY_TYPE.VIRTUAL]: {
        label: 'bills.list.paymentStatus.scheduled',
        description: 'bills.list.paymentStatus.completedBillStatus.virtual.description',
      },
    },
  };

  const descriptionValues = {
    createdAt: getFormattedDate(latestPayment.createdAt),
    scheduledDate: getFormattedDate(latestPayment.scheduledDate),
    deliveryEta: getFormattedDate(latestPayment.deliveryEta),
    expiredDate: getFormattedDate(latestPayment.deliveryMethod?.virtualAccount?.expired),
  };

  const { label, description } =
    CONTENT_BY_STATUS[latestPayment.status][latestPayment.deliveryMethod?.deliveryType];

  return (
    <StatusContainer statusInfo={statusInfo} className={className} status={latestPayment.status}>
      <Status color={color} status={status}>
        <MIFormattedText label={label} />
        <StatusDescriptionText>
          <MIFormattedText label={description} values={descriptionValues} />
        </StatusDescriptionText>
      </Status>
    </StatusContainer>
  );
};

export default QBRBillStatus;

const StatusContainer = styled.div<{ status?: string }>`
  display: flex;
  background: ${(props) => props.theme.colors.brand};
  padding: 2rem 1.6rem;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 0.4rem solid ${(props) => props.theme.colors.white.lightBlue};
  height: 8.8rem;
  ${(props) =>
    props.status === PAYMENT_STATUS.FAILED &&
    `
    background: ${props.theme.colors.error};
  `}
`;

const Status = styled.div`
  color: ${(props) => props.theme.text.color.white};
  font-weight: ${(props) => props.theme.text.weight.medium};
  text-align: left;
  align-self: center;
  ${(props) => props.theme.text.fontType.regular};
`;

const StatusDescriptionText = styled.div`
  color: ${(props) => props.theme.text.color.white};
  ${(props) => props.theme.text.fontType.medium};
  margin-top: 0.4rem;
`;
