import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RecordOf } from 'immutable';
import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import { PAYMENT_APPROVAL_STATUS } from 'src/app/utils/consts';
import {
  BillType,
  DeliveryMethodType,
  PaymentType,
  QBCashStateType,
  VendorType,
} from 'src/app/utils/types';
import styled, { css } from 'styled-components';
import { useApi } from 'src/app/hoc/useApi';
import { MIFormattedText, MIFieldOrEmpty } from 'src/app/utils/formatting';
import { getOrgId } from 'src/app/redux/user/selectors';
import deliveryApi from 'src/app/services/api/delivery';
import { devices } from 'src/app/theme/AppDevices';
import MIMoney from 'src/app/components/common/MIMoney';
import { EditElement } from 'src/app/components/layout/PaymentActivityElements';
import { isRppsVendor } from 'src/app/pages/vendor-directory/utils';
import { shouldAllowEditByDeliveryType, isReturnedCheckPayment } from 'src/app/utils/payments';
import { usePartialPaymentsQualification } from 'src/app/pages/bill/hooks/usePartialPaymentsBillQualification';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import PayBillPaymentActivity, {
  ActivityActionsType,
} from './PayBillPaymentActivity/PayBillPaymentActivity';
import PaymentFee from './PaymentFee';
import RecurringPaymentView from './RecurringPaymentView';

type Props = {
  bill: RecordOf<BillType>;
  fee?: PaymentFeeInfo | null;
  fundingSource: FundingSource;
  payment: RecordOf<PaymentType>;
  deliveryMethod: RecordOf<DeliveryMethodType>;
  activityActions: ActivityActionsType;
  qbCashState?: QBCashStateType;
  isVendorAbsorbedFee?: boolean;
};

type ApprovalDecisionStatusResponse = {
  approvalDecisionStatus: PAYMENT_APPROVAL_STATUS;
};

const PayBillPaymentReview = ({
  fee,
  bill,
  fundingSource,
  payment,
  deliveryMethod,
  activityActions,
  qbCashState,
  isVendorAbsorbedFee,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const [getApprovalDecisionStatus, approvalDecisionStatusResult] = useApi<
    [string, string],
    ApprovalDecisionStatusResponse
  >(deliveryApi.getApprovalDecisionStatus);
  const totalAmount = payment.amount ? payment.amount.toString() : '';
  const paymentData = payment.merge({
    approvalDecisionStatus: approvalDecisionStatusResult?.approvalDecisionStatus,
  });
  const isVendorAbsorbedCardFee =
    isVendorAbsorbedFee && fundingSource?.fundingType === FundingSourceTypesEnum.CARD;
  const { onEditPartialAmount } = activityActions;
  const canEditPaymentAmount = usePartialPaymentsQualification(bill?.qboSyncVersion, payment);
  const isReturnedCheck = isReturnedCheckPayment(payment);
  const allowEdit =
    !isReturnedCheck && canEditPaymentAmount && shouldAllowEditByDeliveryType(deliveryMethod);

  useEffect(() => {
    getApprovalDecisionStatus(orgId, totalAmount);
  }, [orgId, totalAmount, getApprovalDecisionStatus]);

  return (
    <MainBox className="review">
      <BaseContainer>
        <HighlightedHeader>
          <SubTitle>
            <MIFormattedText label="bills.pay.confirm.total" />
          </SubTitle>
          <AmountContainer>
            <StyledMIMoney size="large" amount={totalAmount} />
            {allowEdit && <EditElement onEdit={onEditPartialAmount} />}
          </AmountContainer>
        </HighlightedHeader>
      </BaseContainer>

      <PayBillDetails bill={bill} />
      <PaymentActivityContainer>
        <PayBillPaymentActivity
          bill={bill}
          fundingSource={fundingSource}
          payment={paymentData}
          activityActions={activityActions}
          deliveryMethod={deliveryMethod}
          qbCashState={qbCashState}
        />
      </PaymentActivityContainer>
      {fee && (
        <PaymentFee
          fee={fee}
          isVendorAbsorbedCardFee={isVendorAbsorbedCardFee}
          deliveryMethod={deliveryMethod}
          fundingSource={fundingSource}
          isEdit={!!payment.id}
        />
      )}
    </MainBox>
  );
};

export default PayBillPaymentReview;

type PayBillDetailsProps = {
  bill: RecordOf<BillType>;
};

const PayBillDetails = ({ bill }: PayBillDetailsProps) => {
  const companyName = bill.vendor?.companyName || '';
  const isRPPSVendor = isRppsVendor(bill.vendor as VendorType);
  const isRecurring = !!bill.recurringBill;
  const invoiceNumberLabel = 'bills.pay.confirm.invoiceNumberField';

  return (
    <PaymentInfoContainer>
      <SubTitle>
        <MIFormattedText label="bills.pay.confirm.payTo" />
      </SubTitle>
      <BoxRow>
        <BoxColumn>
          <FieldName>
            <MIFormattedText label="bills.pay.confirm.businessName" />
          </FieldName>
          <FieldValue data-testid="bill-company-name">{companyName}</FieldValue>
        </BoxColumn>
        <InvoiceBoxColumn>
          <FieldName>
            <MIFormattedText label={invoiceNumberLabel} />
          </FieldName>
          <FieldValue data-testid="bill-invoice-number">
            <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.view.noInvoiceNumber" />
          </FieldValue>
        </InvoiceBoxColumn>
      </BoxRow>
      {isRPPSVendor && (
        <BoxRow>
          <BoxColumn>
            <FieldName>
              <MIFormattedText label="bills.pay.confirm.accountIdentifier" />
            </FieldName>
            <FieldValue data-testid="bill-account-identifier">
              {bill.vendor?.accountIdentifier}
            </FieldValue>
          </BoxColumn>
        </BoxRow>
      )}
      {isRecurring && <RecurringPaymentView bill={bill} />}
      <SectionSeparator />
    </PaymentInfoContainer>
  );
};

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.14);

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.MainBox}
`;

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

const HighlightedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.HighlightedHeader}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const PaymentActivityContainer = styled(BaseContainer)`
  padding-bottom: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentActivityContainer}
`;

const PaymentInfoContainer = styled(BaseContainer)`
  padding-bottom: 1.7rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentInfoContainer}
`;

const BoxRow = styled.div`
  display: flex;
  margin-top: 1.8rem;
  column-gap: 1.6rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxColumn}
`;

const InvoiceBoxColumn = styled(BoxColumn)`
  ${(props) => props.theme?.components?.BillPaymentReview?.InvoiceBoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}

  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldName}
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldValue}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;
const SectionSeparator = styled.div`
  display: none;
  ${(props) => props.theme?.components?.BillPaymentReview?.SectionSeparator}
`;
