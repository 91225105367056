import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';

import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getDeliveryMethodActions } from 'src/app/modules/delivery-methods/delivery-methods-store';
import paymentStore from 'src/app/modules/payments/payment-store';
import { pushNotification } from 'src/app/services/notifications';
import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';

import { PaymentType } from 'src/app/utils/types';
import { FORM_TYPES, TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH } from '../consts';
import { EditPaymentCheckAddressForm } from './forms';
import { triggerAnalyticsEvent } from '../utils';

const formFieldNames = ['printName', 'addressLine1', 'addressLine2', 'city', 'state', 'zipCode'];
const labels = formFieldNames.reduce(
  (currentLabels, currentFieldName) => ({
    ...currentLabels,
    [currentFieldName]: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_CHECK_ADDRESS}.form.${currentFieldName}`,
  }),
  {}
);

type Props = {
  payment: PaymentType;
  isMobile: boolean;
  token: string;
  isLoading: boolean;
  onClose: () => void;
};

const EditPaymentCheckAddress = ({ payment, isMobile, token, isLoading, onClose }: Props) => {
  const deliveryMethodActions = getDeliveryMethodActions(useDispatch());
  const paymentStoreActions = useStoreActions(paymentStore);

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    triggerAnalyticsEvent({ payment, action: 'check-address-modal-opened' });
  }, []);

  const handleSubmit = async (value: { [key: string]: string }) => {
    const { printName, addressLine1, addressLine2, city, state, zipCode } = value;
    const validationErrors = getValidationErrors(
      'deliveryMethodCheck',
      { printName, addressLine1, addressLine2, city, state, zipCode },
      formFieldNames
    );

    setValidationErrors(validationErrors);

    const isValid = isValidationOk(validationErrors);

    if (!isValid) return;

    try {
      await deliveryMethodActions.updateDeliveryMethodWithToken({
        token,
        deliveryMethod: {
          ...payment.deliveryMethod,
          paperCheck: {
            printName,
            addressLine1,
            addressLine2,
            city,
            state,
            zipCode,
          },
        },
        orgId: payment.organization.id,
        vendorId: payment.vendor.id,
      });

      paymentStoreActions.fetchPaymentDetailsWithToken({ token });

      triggerAnalyticsEvent({
        payment,
        action: 'check-address-saved',
        additionalData: {
          successfullySaved: true,
        },
      });
      onClose();
      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_CHECK_ADDRESS}.notification.success`,
      });
    } catch (error) {
      triggerAnalyticsEvent({
        payment,
        action: 'check-address-saved',
        additionalData: {
          successfullySaved: false,
        },
      });
      pushNotification({
        type: NOTIFICATION_VARIANT.ERROR,
        msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.edit.${FORM_TYPES.EDIT_CHECK_ADDRESS}.notification.failed`,
      });
    }
  };

  return (
    <EditPaymentCheckAddressForm
      payment={payment}
      labels={labels}
      isMobile={isMobile}
      isLoading={isLoading}
      validationErrors={validationErrors}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};

export { EditPaymentCheckAddress };
