import React from 'react';
import { format } from 'date-fns';
import { Box, Flex, Grid, Link, StackDivider, VStack } from '@melio/billpay-design-system';

import analytics from 'src/app/services/analytics';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { useBreak } from 'src/app/hoc';
import { MIFormattedText, MIFormattedDate } from 'src/app/utils/formatting';
import { PaymentType, VirtualCardType, OrganizationType } from 'src/app/utils/types';
import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import { pushNotification } from 'src/app/services/notifications';
import { DATE_FORMATS } from 'src/app/utils/date-fns';

import { CardPreview, InfoLayout } from '../../components';
import { ProcessCardStep } from 'src/app/version-2/pages/virtual-card-details/components/ProcessCardStep';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../../hooks';
import { CardFieldsDesktop, CardFieldsMobile } from './StyledComponents';

type Props = {
  payment?: PaymentType;
  virtualCard?: VirtualCardType;
  organization?: OrganizationType;
};

const PROCESSS_CARD_STEPS = [
  {
    title: 'vendors.virtualCardDetails.valid.processCard.first.title',
    subtitle: 'vendors.virtualCardDetails.valid.processCard.first.subtitle',
  },
  {
    title: 'vendors.virtualCardDetails.valid.processCard.second.title',
    subtitle: 'vendors.virtualCardDetails.valid.processCard.second.subtitle',
  },
  {
    title: 'vendors.virtualCardDetails.valid.processCard.third.title',
    subtitle: 'vendors.virtualCardDetails.valid.processCard.third.subtitle',
  },
];

const notificationByField = {
  cardNumber: 'vendors.virtualCardDetails.valid.notifications.cardNumber',
  expiryDate: 'vendors.virtualCardDetails.valid.notifications.expiryDate',
  cvc: 'vendors.virtualCardDetails.valid.notifications.cvc',
  zipCode: 'vendors.virtualCardDetails.valid.notifications.zipCode',
  issuerAddress: 'vendors.virtualCardDetails.valid.notifications.issuerAddress',
};

const ValidLayout = ({ payment, virtualCard, organization }: Props) => {
  const site = useSiteContext();
  const { analyticsProperties } = useStateChangedAnalytics({
    payment,
    paymentState: VirtualCardPaymentState.Valid,
  });
  const { isMobile } = useBreak();

  const handleFieldCopy = (fieldName: string, value?: string) => {
    const notification = notificationByField[fieldName];

    if (value) {
      navigator.clipboard.writeText(String(value));
      analytics.trackAction('vendors.virtualCardDetails.valid.cta', {
        ...analyticsProperties,
        cardFieldName: fieldName,
      });

      if (notification) {
        pushNotification({
          type: NOTIFICATION_VARIANT.SUCCESS,
          msg: notification,
        });
      }
    }
  };

  const cardDetails = {
    number: virtualCard?.cardNumber,
    cvc: virtualCard?.cvv,
    exp:
      virtualCard?.expirationDate &&
      format(new Date(virtualCard.expirationDate), DATE_FORMATS.monthNumberSlashShortYear),
  };

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <VStack
        direction="column"
        justify="center"
        align="center"
        bgColor="white"
        divider={<StackDivider borderColor="gray.400" borderStyle="solid" />}
        spacing={isMobile ? 5 : 10}
        width="full"
      >
        <Flex direction="column" gridGap={5} maxWidth="full">
          <Flex direction="column" textAlign="center" gridGap={1}>
            <Box textStyle="h5" fontWeight="ds.semi">
              {organization?.companyName}
            </Box>
            <Box textStyle="body2" color="gray.300">
              <MIFormattedText label="vendors.virtualCardDetails.valid.sent" />
            </Box>
          </Flex>
          <CardPreview amount={payment?.amount ?? 0} cardDetails={cardDetails} />
          {isMobile ? (
            <CardFieldsMobile cardDetails={cardDetails} onFieldCopy={handleFieldCopy} />
          ) : (
            <CardFieldsDesktop cardDetails={cardDetails} onFieldCopy={handleFieldCopy} />
          )}
          <Box
            textStyle={isMobile ? 'body3' : 'body4'}
            textAlign="center"
            color="black"
            padding="0.8rem 1.6rem"
            bgColor="gray.50"
            borderRadius="0.8rem"
          >
            <MIFormattedText
              label="vendors.virtualCardDetails.valid.estimate"
              values={{
                date: <MIFormattedDate date={virtualCard?.endDate} />,
              }}
            />
          </Box>
        </Flex>
        <Flex direction="column" gridGap={5}>
          <Box textStyle="h5" fontWeight="ds.semi">
            <MIFormattedText label="vendors.virtualCardDetails.valid.processCard.sectionTitle" />
          </Box>
          <Grid gridRowGap={isMobile ? 5 : 6}>
            {PROCESSS_CARD_STEPS.map((step, index) => (
              <ProcessCardStep
                key={step.title}
                idx={index + 1}
                title={step.title}
                subtitle={step.subtitle}
              />
            ))}
          </Grid>
          <Box textStyle="body3" textAlign="center" color="gray.500">
            <MIFormattedText
              label="vendors.virtualCardDetails.valid.footerNote"
              values={{
                link: (...chunks) => (
                  <Link
                    onClick={() => window.open(`mailto:${site.config.support.email}`, '_blank')}
                    textDecoration="none !important"
                    cursor="pointer"
                  >
                    {React.Children.toArray(chunks)}
                  </Link>
                ),
              }}
            />
          </Box>
        </Flex>
      </VStack>
    </InfoLayout>
  );
};

export { ValidLayout };
