import { Button } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const WideButton = styled(Button)`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.body};
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.ds.gray[800]};
  padding: ${({ theme }) => theme.space.ds['2xl']};
  border-radius: ${({ theme }) => theme.radii.ds.lg};
`;

export const DescriptionContainer = styled.div`
  margin: ${({ theme }) => theme.space.ds.lg} 0;
`;
