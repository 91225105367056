function optionColorsByState({ state, theme }) {
  const { colors } = theme;

  if (state.isDisabled) {
    return {
      background: 'rgba(250, 250, 250, 1)',
      color: 'rgba(194, 194, 195, 1)',
      '&:hover': {},
      '&:active': { background: 'rgba(246,246,246, 1)' },
    };
  }

  return {
    background: state.isSelected ? 'rgba(246,246,246, 1)' : colors.ds.white,
    color: colors.ds.gray['100'],
    '&:hover': {
      background: 'rgba(246,246,246, 1)',
    },
    '&:active': state.isSelected ? { background: 'rgba(246,246,246, 1)' } : {},
  };
}

// Configure the Select styles here since we pass them to
// the react-select component
export const selectStyleTemplate = (isSelectInvalid: boolean, theme: any) => {
  const { colors, fontWeights, space, fonts } = theme;
  const fontSize = space.ds.lg;
  const lineHeight = '2.4rem';
  const fontWeight = fontWeights.ds.medium;

  return {
    // The main control component
    control: (base, state) => {
      const errorBottomBorder = `1px solid ${colors.ds.red['100']}`;
      const grayBottomBorder = isSelectInvalid
        ? errorBottomBorder
        : `1px solid ${state.isDisabled ? colors.ds.gray['500'] : colors.ds.gray['500']}`;
      const blackBottomBorder = `1px solid ${colors.ds.gray['100']}`;

      const readOnlyBorder = state.selectProps.readOnlyValue && colors.ds.gray['500'];
      const pointerEvents = state.selectProps.readOnlyValue ? 'none' : 'auto';
      const borderBottom = state.selectProps.menuIsOpen ? blackBottomBorder : grayBottomBorder;

      return {
        ...base,
        '&:hover': {
          grayBottomBorder,
        },
        border: 'none',
        borderRadius: '0',
        borderBottom,
        maxWidth: 'unset',
        background: 'transparent',
        boxShadow: 'none',
        pointerEvents,
        borderBottomColor: readOnlyBorder,
        height: '3rem',
        minHeight: '3rem',
        fontFamily: fonts.body,
      };
    },

    // Text placeholder when nothing is selected
    placeholder: (base) => ({
      ...base,
      color: colors.ds.gray['400'],
      fontSize,
      fontWeight,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),

    // A separator for the indicators when something is selected
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),

    // The X button to clear the selection
    clearIndicator: (base) => ({
      ...base,
      color: colors.ds.black,
    }),

    // The input box (for the user to type)
    input: (base) => ({
      ...base,
      fontSize,
      fontWeight,
      padding: 0,
      input: {
        fontWeight,
        lineHeight,
      },
    }),

    // The group header label.
    groupHeading: (base) => ({
      ...base,
      padding: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      cursor: 'default',
    }),

    // The group container.
    group: (base) => ({
      ...base,
      padding: 0,
    }),

    // List item, the options when the menu is open
    option: (base, state) => ({
      ...base,
      ...optionColorsByState({ state, theme }),
      minHeight: '5rem',
      padding: '1.6rem 2rem',
      fontSize: '1.4rem',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      cursor: 'default',
    }),

    // The list of items
    menuList: (base) => ({
      ...base,
      padding: '0.8rem 0',
      border: 'none',
      borderRadius: '0.8rem',
      boxShadow: '0 0 1rem 0 rgba(33, 33, 36, 0.2)',
    }),

    // When a single value is selected (how it appears in the control)
    singleValue: (base, state) => ({
      ...base,
      fontSize,
      fontWeight,
      lineHeight,
      margin: 0,
      color: state.isDisabled ? colors.ds.gray['500'] : colors.ds.gray['100'],
    }),

    valueContainer: (base) => ({
      ...base,
      padding: 0,
    }),

    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };
};
