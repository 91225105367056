import React from 'react';
import { Card as StyledCard } from './BankAccountCard.styles';

interface BankAccountCardProps {
  children: string | React.ReactNode;
}

export const BankAccountCard = ({ children }: BankAccountCardProps) => (
  <StyledCard>{children}</StyledCard>
);
