import { put, all, call, select, getContext } from 'redux-saga/effects';
import { getCreateOrigin } from 'src/app/redux/utils';
import analytics from 'src/app/services/analytics';
import { getPayBillFlowUUID } from 'src/app/services/analytics/trackPayBillFlow';
import recurringBillsApi from 'src/app/services/api/recurringBills';
import { dateInCentral } from 'src/app/version-2/utils/dates.utils';
import organizationApi from 'src/app/services/api/organizations';
import { PaymentRecord } from 'src/app/pages/payment/records';
import { getPartialBillId } from 'src/app/utils/bills';
import { PAY_BILL_EVENT_PAGE } from 'src/app/pages/bill/pay/consts';
import { loggingApi } from 'src/app/version-2/api/loggers';
import { KybStatusEnum } from 'src/app/version-2/model/enums';
import { HANDLED_SYNC_PAYMENT_ERROR_CODES } from 'src/app/version-2/model/constants/syncPaymentErrorCode.consts';

import type { CreateRecurringBillType } from '../types';
import {
  createRecurringBillSuccessAction,
  createRecurringBillErrorAction,
  setSyncPaymentErrorCode,
} from '../actions';
import { getPayment, getBill, getRecurringBill } from '../selectors';
import { getOrgId, getCompanyInfo } from '../../user/selectors';

export function* createRecurringBillHandler({ resolve, reject }: CreateRecurringBillType) {
  const [payment, bill, recurringBill, orgId, companyInfo] = yield all([
    select(getPayment),
    select(getBill),
    select(getRecurringBill),
    select(getOrgId),
    select(getCompanyInfo),
  ]);
  const payBillFlowUUID = getPayBillFlowUUID();
  const partialBillId = getPartialBillId(bill);
  const site = yield getContext('site');
  const siteName = site.name;
  const createOrigin = getCreateOrigin(siteName);

  analytics.track(PAY_BILL_EVENT_PAGE, 'recurring-confirm', { partialBillId });

  try {
    const data = {
      recurringFields: recurringBill,
      vendorId: bill.vendor.id,
      amount: bill.totalAmount,
      dueDate: dateInCentral(new Date(bill.dueDate)),
      invoiceNumber: bill.invoiceNumber,
      billNote: bill.note,
      paymentNote: payment.note,
      intuitAccountId: bill.intuitAccountId,
      fundingSourceId: payment.fundingSourceId,
      deliveryMethodId: payment.deliveryMethodId,
      purpose: payment.purpose,
      payBillFlowUUID,
      createOrigin,
    };

    const {
      bills,
      recurringBill: { id: recurringBillId },
    } = yield call(recurringBillsApi.createRecurringBill, orgId, data);
    const firstBill = bills.find((b) => b.recurringBillIndex === 1);
    const firstBillId = firstBill.id;
    const firstPayment = firstBill.payments[0];
    const paymentRecord = PaymentRecord({ ...firstPayment, payBillFlowUUID });
    const firstPaymentId = firstPayment?.id;

    yield call(organizationApi.runBillSync, orgId, firstBillId);

    try {
      if (firstPaymentId) {
        yield call(organizationApi.runPaymentSync, orgId, firstPayment.id);
      }
    } catch (syncPaymentError) {
      const syncPaymentErrorCode = (syncPaymentError as any)?.code;

      loggingApi.error(
        'createRecurringBillSaga.handler(): sync payment failed',
        syncPaymentErrorCode
      );

      if (HANDLED_SYNC_PAYMENT_ERROR_CODES.includes(syncPaymentErrorCode)) {
        yield put(setSyncPaymentErrorCode(syncPaymentErrorCode));
      } else {
        throw syncPaymentError;
      }
    }

    analytics.track(PAY_BILL_EVENT_PAGE, 'recurring-confirm-success', {
      partialBillId: getPartialBillId(bill),
      firstBillID: firstBillId,
      firstPaymentId,
      recurringBillId,
    });
    analytics.trackMqlEvent('created-payment', 'mql');

    if (companyInfo.kybStatus === KybStatusEnum.ACCEPTED) {
      analytics.track(PAY_BILL_EVENT_PAGE, 'kybaccepted-confirm-success', { partialBillId });
    }

    // Also set traits from bill flow
    analytics.setTraits({
      last_bill_added_date: new Date().toISOString(),
    });
    yield put(createRecurringBillSuccessAction(firstBillId, paymentRecord, recurringBillId));
    resolve();
  } catch (e: any) {
    analytics.track(PAY_BILL_EVENT_PAGE, 'recurring-confirm-failed', { partialBillId });
    yield put(createRecurringBillErrorAction(e.code));
    reject(e);
  }
}
