import { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getJWTPayload } from 'src/app/helpers/jwt';
import { useModal } from 'src/app/helpers/react/useModal';
import { useStructuredSelectors } from 'src/app/helpers/redux/useStructuredSelectors';
import paymentStore from 'src/app/modules/payments/payment-store';
import deliveryMethodsStore, {
  getDeliveryMethodActions,
} from 'src/app/modules/delivery-methods/delivery-methods-store';
import locations from 'src/app/pages/vendor/shift-vendor-to-debit/locations';
import { useLocationState } from 'src/app/utils/hooks';
import { convertPaperCheck } from 'src/app/utils/address';
import { ModelView } from 'src/app/ui/form';
import { CommonDialog } from 'src/app/ui/dialog/CommonDialog';
import { PaymentType, AddressType, CardAccountType } from 'src/app/utils/types';
import { ShiftToDebitDeliveryMethodType } from 'src/app/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import * as VENDOR_PAYMENT_DETAILS_LOCATIONS from 'src/app/pages/vendor/vendor-payment-tracking/locations';
import { getIsVendorPaymentDetailsFlow } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import { pushNotification } from 'src/app/services/notifications';
import { NOTIFICATION_VARIANT } from 'src/app/utils/consts';
import {
  EXPEDITE_OPTIONS,
  TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH,
} from 'src/app/pages/vendor/vendor-payment-tracking/consts';
import {
  addCardAccountDeliveryMethodSelectors,
  addCardAccountDeliveryMethodActions,
} from 'src/app/version-2/pages/add-card-account/modules/deliveryMethod/addCardAccountDeliveryMethod.slice';
import { addCardAccountSelectors } from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';
import { extractFirstAndLastNameFromFullName } from '../utils';
import { featureFlags } from '@melio/shared-web';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';

type AddCardAccountParamsType = {
  token: string;
  card4digits: string;
  cardToken: string;
  cardBin: string;
  expiration: string;
  line1: string;
  zipcode: string;
  state: string;
  city: string;
  firstName: string;
  lastName: string;
};

const useAddCardAccount = (
  token: string,
  payment: PaymentType,
  deliveryMethodMV: ModelView<ShiftToDebitDeliveryMethodType>
) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { paymentId } = getJWTPayload(token);

  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const [isBasisTheoryFlagEnabled, isBasisTheoryFlagLoading] = featureFlags.useFeature(
    FeatureFlagsEnum.BP_BASIS_THEORY,
    true,
    {
      shouldTrack: false,
    }
  );

  const [{ digits, cardToken, cardBin, expiration }] = useLocationState('card', {});

  const paymentActions = useStoreActions(paymentStore);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const { clearWhitePageAddress } = getDeliveryMethodActions(useDispatch());

  const { isPaymentLoading } = useStructuredSelectors(paymentStore.selectors.validation(paymentId));
  const { loading: isLoading } = useSelector(deliveryMethodsStore.selectors.validation) as {
    loading: boolean;
  };
  const createCardAccount = useSelector(
    addCardAccountDeliveryMethodSelectors.selectCreateCardAccount
  );
  const isRequesting = useSelector(addCardAccountSelectors.selectIsRequesting);

  const cardAccount = useMemo(
    () => convertPaperCheck(deliveryMethodMV.cardAccount as ModelView<AddressType>),
    [deliveryMethodMV.cardAccount]
  );

  const addCardAccount = useCallback(async () => {
    const { addressLine1, state, city, zipCode, printName } = cardAccount as CardAccountType;
    const { firstName, lastName } = extractFirstAndLastNameFromFullName(printName);

    const addCardAccountParams = {
      token,
      card4digits: digits,
      cardToken,
      cardBin,
      expiration,
      line1: addressLine1,
      zipcode: zipCode,
      state,
      city,
      firstName,
      lastName,
    };

    if (isBasisTheoryFlagEnabled) {
      dispatch(
        addCardAccountDeliveryMethodActions.createCardAccount(
          addCardAccountParams as AddCardAccountParamsType
        )
      );

      return null;
    }

    return deliveryMethodActions.addCardAccountWithToken(addCardAccountParams);
  }, [cardAccount, cardBin, cardToken, deliveryMethodActions, digits, expiration, token]);

  const dismissModal = useCallback(() => {
    setErrorCode(undefined);
    dispatch(
      addCardAccountDeliveryMethodActions.setCreateCardAccountStatus({
        error: undefined,
      })
    );
  }, []);

  const [errorModal, showErrorModal] = useModal(CommonDialog, {
    title: 'vendors.deliveryMethods.shiftToDebit.errorTitle',
    description: `server.${errorCode}`,
    showCancel: true,
    onDismiss: dismissModal,
  });

  const onSubmitResponse = () => {
    if (getIsVendorPaymentDetailsFlow(token)) {
      history.push(generatePath(VENDOR_PAYMENT_DETAILS_LOCATIONS.default.base, { token }));

      pushNotification({
        type: NOTIFICATION_VARIANT.SUCCESS,
        msg: `${TIMELINE_ACTIONS_TRANSLATION_ROOT_PATH}.expedite.${EXPEDITE_OPTIONS.TO_PUSH_TO_DEBIT}`,
      });
    } else {
      history.push(generatePath(locations.success, { token }));
    }
  };

  const handleError = (error?: { code?: string }) => {
    if (error?.code) {
      setErrorCode(error.code);
      showErrorModal();
    }
  };

  useEffect(() => {
    handleError(createCardAccount?.error);
  }, [createCardAccount?.error]);

  useEffect(() => {
    if (isBasisTheoryFlagEnabled && createCardAccount?.deliveryMethod?.id) {
      const updatePaymentWithToken = async () => {
        try {
          await paymentActions.updatePaymentWithToken({
            token,
            id: paymentId,
            deliveryMethodId: createCardAccount.deliveryMethod?.id,
          });

          onSubmitResponse();
        } catch (e: any) {
          handleError(e?.error);
        }
      };

      updatePaymentWithToken();
    }
  }, [createCardAccount?.deliveryMethod?.id, isBasisTheoryFlagEnabled]);

  const onSubmit = useCallback(async () => {
    try {
      const addCardAccountResponse = await addCardAccount();

      if (!isBasisTheoryFlagEnabled) {
        await paymentActions.updatePaymentWithToken({
          token,
          id: paymentId,
          deliveryMethodId: addCardAccountResponse?.payload.deliveryMethod.id,
        });

        onSubmitResponse();
      }
    } catch (e: any) {
      handleError(e?.error);
    }
  }, [
    addCardAccount,
    history,
    paymentActions,
    paymentId,
    showErrorModal,
    token,
    createCardAccount,
    isBasisTheoryFlagEnabled,
  ]);

  const onClearAddressState = async () => {
    await clearWhitePageAddress({
      deliveryMethodId: payment?.deliveryMethodId,
    });
  };

  return {
    onSubmit,
    onClearAddressState,
    isLoading: isLoading || isPaymentLoading || isRequesting || isBasisTheoryFlagLoading,
    errorModal,
  };
};

export default useAddCardAccount;
