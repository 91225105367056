import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Flex, SmartIcon } from '@melio/billpay-design-system';
import { selectFundingSourceAction } from 'src/app/redux/payBillWizard/actions';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/app/utils/consts';
import { analytics, utilsLocations } from 'src/app/version-2/externals';
import {
  fundingSourcesActions,
  fundingSourcesSelectors,
} from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { BankingTypesEnum } from 'src/app/version-2/pages/bank-account/model/enums/bankingTypes.enum';
import { getQBCash } from 'src/app/version-2/utils/paymentMethods.utils';
import {
  CardSubtitle,
  CardTextContainer,
  CardTitle,
  CloseButton,
  PageContainer,
  Subtitle,
  Title,
} from './BankAccountPage.styles';
import { BankAccountCard } from '../';

const EVENT_PAGES = {
  PAYBILL: 'pay-bill',
  BATCH: 'pay-bill-batch-payments',
};
const EVENT_NAMES = {
  ADD_BANK_ACCOUNT: 'add-bank-account',
  ADD_FIRST: 'add-first-funding-source',
  ADD_FUNDING: 'add-funding',
};

interface BankAccountPageProps {
  billIds: string[];
  isBatch: boolean;
  redirectUrl: string;
  preservedState: Record<string, any>;
}

export const BankAccountPage = ({
  isBatch,
  billIds,
  redirectUrl,
  preservedState,
}: BankAccountPageProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const orgId = useSelector(userSliceSelectors.selectOrgId);
  const fundingSources = useSelector(fundingSourcesSelectors.selectValidFundingSources);

  const getEventPage = (isBatch = false) => (isBatch ? EVENT_PAGES.BATCH : EVENT_PAGES.PAYBILL);
  const getEventName = (isBatch) => (isBatch ? EVENT_NAMES.ADD_FIRST : EVENT_NAMES.ADD_FUNDING);

  useEffect(() => {
    dispatch(fundingSourcesActions.fetchFundingSources());
    analytics.page(getEventPage(isBatch), getEventName(isBatch), {
      fundingSourceOptions: [BankingTypesEnum.BANK, BankingTypesEnum.QUICKBOOKS],
      billIds,
    });
  }, []);

  const handleExit = () => {
    const redirectUrl = isBatch
      ? utilsLocations.Bills.pay.batch.url({ ids: billIds, orgId })
      : utilsLocations.Bills.pay.funding.url({ orgId, id: billIds[0] });

    history.push(redirectUrl);
  };

  const onClickHandler = (type: string) => {
    analytics.track(`${getEventPage()}`, EVENT_NAMES.ADD_BANK_ACCOUNT, {
      bankAccountType: type,
      billIds,
    });

    if (type === BankingTypesEnum.BANK) {
      history.push(utilsLocations.Onboarding.fundingSources.bank.select.url({ orgId }), {
        preservedState: {
          origin: 'funding-source-pay-bill',
          originFromNewLp:
            preservedState?.origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.NEW_LANDING_PAGE,
        },
        exitUrl:
          preservedState?.exitUrl || utilsLocations.Onboarding.fundingSources.bank.type.url(),
        redirectUrl,
      });
    } else if (type === BankingTypesEnum.QUICKBOOKS) {
      const qbCash = getQBCash(fundingSources);

      if (
        qbCash &&
        qbCash?.id &&
        preservedState?.origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.NEW_LANDING_PAGE
      ) {
        dispatch(selectFundingSourceAction(qbCash.id));
      }

      history.push(utilsLocations.Onboarding.fundingSources.bank.account.url({ orgId }), {
        fundingSourceId: qbCash?.id,
        preservedState: {
          ...preservedState,
          originFromNewLp:
            preservedState?.origin === ADD_FUNDING_SOURCE_WIZARD_ORIGIN.NEW_LANDING_PAGE,
        },
        exitUrl:
          preservedState?.exitUrl || utilsLocations.Onboarding.fundingSources.bank.type.url(),
        redirectUrl,
      });
    }
  };

  return (
    <PageContainer direction="column" align="center" justify="center">
      <CloseButton onClick={handleExit}>
        <SmartIcon type="close" size="1.2rem" />
      </CloseButton>
      <Title>{formatMessage({ id: 'bankAccounts.title' })}</Title>
      <Subtitle textStyle="ds.body2">{formatMessage({ id: 'bankAccounts.subtitle' })}</Subtitle>
      <BankAccountCard>
        <Flex onClick={() => onClickHandler(BankingTypesEnum.QUICKBOOKS)}>
          <SmartIcon type="qb-cash" size="4rem" />
          <CardTextContainer direction="column">
            <CardTitle>{formatMessage({ id: 'bankAccounts.options.quickbooks.title' })}</CardTitle>
            <CardSubtitle>
              {formatMessage({ id: 'bankAccounts.options.quickbooks.subtitle' })}
            </CardSubtitle>
          </CardTextContainer>
        </Flex>
      </BankAccountCard>
      <BankAccountCard>
        <Flex onClick={() => onClickHandler(BankingTypesEnum.BANK)}>
          <SmartIcon type="bank" size="4rem" />
          <CardTextContainer direction="column">
            <CardTitle>{formatMessage({ id: 'bankAccounts.options.bank.title' })}</CardTitle>
            <CardSubtitle>
              {formatMessage({ id: 'bankAccounts.options.bank.subtitle' })}
            </CardSubtitle>
          </CardTextContainer>
        </Flex>
      </BankAccountCard>
    </PageContainer>
  );
};
