import unionWith from 'lodash/unionWith';
import { qbDashboardListItemsStore } from 'src/app/modules/qb-dashboard-list-items/qb-dashboard-list-items-store';
import api from 'src/app/services/api/vendors';
import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import paymentStore from 'src/app/modules/payments/payment-store';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { ADD_NEW_DELIVERY_METHOD } from 'src/app/redux/payBillWizard/actionTypes';
import { VendorType } from 'src/app/utils/types';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { achDeliveryMethodDetailsActions } from 'src/app/version-2/pages/ach-delivery-method-details/modules/achDeliveryMethodDetails.slice';
import { qboVendorInfo } from './vendor-management-slice';
import checkVendorPaymentPreferences from './check-vendor-payment-preferences';

export const name = 'vendors';

const vendorsStore = createRestfulSlice<any>({
  name,
  schemaName: 'vendor',
  api: {
    fetch: (params) => api.getVendorById(params).then(({ object }) => object),
    update: ({ orgId, id, ...params }) =>
      api.editVendorById({ orgId, id, params }).then((res) => res.object),
    create: ({ orgId, ...params }) => api.createVendor(orgId, params).then((res) => res.object),
    list: (params) =>
      api.getVendors(params).then((res) => ({ items: res.objects, totalCount: res.totalCount })),
    delete: ({ orgId, id }: any) => api.deleteVendorById(orgId, id),
  },
  extraReducers: {
    [batchBulkActions.updateVendorEmailSuccess.type](state, { payload }) {
      const { vendorId, contactEmail } = payload;

      state.byId[vendorId] = { ...state.byId[vendorId], contactEmail };
    },
    [paymentStore.actions.fetchPaymentDetailsWithToken.success](state, { payload }) {
      const { vendor }: { vendor: VendorType } = payload.payment;

      state.byId[vendor.id] = vendor;
    },
    [paymentStore?.actions.fetchEmailToVendorDetails.success](state: any, { payload }) {
      const { vendor } = payload.payment;

      state.byId[vendor.id] = vendor;
    },
    [ADD_NEW_DELIVERY_METHOD](state, action) {
      const vendorId = action?.deliveryMethod?.vendorId;

      if (state.byId[vendorId]) {
        state.byId[vendorId].deliveryMethods = unionWith(
          [action.deliveryMethod],
          state.byId[vendorId].deliveryMethods || [],
          (a, b) => a.id === b.id
        );
      }
    },
    [deliveryMethodsStore.actions.fetchUnilateralRequestDetails.success](state: any, { payload }) {
      const { vendor } = payload;

      state.byId[vendor.id] = vendor;
    },
    '[DELIVERYMETHODS] CREATE_SUCCESS': function (state, { payload }) {
      if (state.byId[payload.vendorId]) {
        state.byId[payload.vendorId].deliveryMethods = unionWith(
          [payload],
          state.byId[payload.vendorId].deliveryMethods || [],
          (a: any, b: any) => a.id === b.id
        );
      }
    },
    [achDeliveryMethodDetailsActions.deliveryMethodCreated.type]: function (state, { payload }) {
      const { deliveryMethod } = payload;

      if (state.byId[deliveryMethod.vendorId]) {
        state.byId[deliveryMethod.vendorId].deliveryMethods = unionWith(
          [deliveryMethod],
          state.byId[deliveryMethod.vendorId].deliveryMethods || [],
          (a: any, b: any) => a.id === b.id
        );
      }
    },
    [qbDashboardListItemsStore.actions.list.success]: (state, action) => {
      action.payload.items.forEach((item) => {
        state.byId[item.vendor.id] = item.vendor;
      });
    },
  },
  slices: {
    qboVendorInfo,
    checkVendorPaymentPreferences,
  },
  selectors: {
    byId: (vendorId) => (state) => state.vendors.byId[vendorId],
    all: (state) => Object.values(state[name]?.byId || {}),
    loadingByOrgId: (orgId) => (state) => state[name]?.[`orgId:${orgId}`]?.loading,
  },
});

export default vendorsStore;
