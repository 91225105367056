import React from 'react';
import { envApi } from 'src/app/version-2/api/env';
import SiteIcon from 'src/app/components/layout/SiteIcon';
import SiteTitle from 'src/app/components/layout/SiteTitle';
import AppProviders, { history } from './components/layout/AppProviders';
import ErrorBoundary from './pages/general/ErrorBoundary';
import Router from './pages/Router';
import ListSearchBarContextProvider from './context/ListSearchBarContext';
import Segment from './components/common-app/Segment';
import { EnvRibbon } from './components/common/EnvRibbon';

const App = () => (
  <AppProviders url={window.location.href}>
    <EnvRibbon envName={envApi.getConfig().env_ribbon_label || process.env.NX_REACT_APP_ENV} />
    <SiteIcon />
    <SiteTitle />
    <ErrorBoundary>
      <Segment />
      <ListSearchBarContextProvider>
        <Router history={history} />
      </ListSearchBarContextProvider>
    </ErrorBoundary>
  </AppProviders>
);

App.defaultProps = {};

export default App;
