import { useState, useEffect } from 'react';
import { usePartialPaymentsEnabled } from 'src/app/pages/bill/hoc/withPartialPaymentsEnabled';
import { PaymentType } from 'src/app/utils/types';
import { QboSyncVersionEnum } from 'src/app/version-2/model/enums';

export const usePartialPaymentsQualification = (
  qboSyncVersion: number | null | undefined,
  payment?: PaymentType
): boolean => {
  // Getting the feature flag which was set in melio db
  const { isPartialPaymentsUIEnabled } = usePartialPaymentsEnabled();
  // Setting a flag which this hook will return
  const [isBillQualified, setIsBillQualified] = useState(false);

  const canPartiallyPayBill = (
    isPartialPaymentsUIEnabled: boolean,
    paymentId: string | null | undefined,
    qboSyncVersion: number | null | undefined
  ): boolean =>
    isPartialPaymentsUIEnabled &&
    !paymentId &&
    qboSyncVersion === QboSyncVersionEnum.BILL_CREATED_AFTER_SYNC;

  useEffect(() => {
    if (canPartiallyPayBill(isPartialPaymentsUIEnabled, payment?.id, qboSyncVersion))
      setIsBillQualified(true);
    else setIsBillQualified(false);
  }, [qboSyncVersion]);

  return isBillQualified;
};
