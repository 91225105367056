import React, { useEffect } from 'react';

import GeneralFlowErrorContainer from 'src/app/pages/quickbooks/GeneralFlowErrorContainer';
import Icon from 'src/app/images/icons/check-deposited.svg';
import analytics from 'src/app/services/analytics';

const CheckDeposited = () => {
  useEffect(() => {
    analytics.page('push-to-debit', 'check-already-deposited', { OriginDeliveryType: 'check' });
  }, []);

  return (
    <GeneralFlowErrorContainer
      illustration={Icon}
      title="vendors.deliveryMethods.shiftToDebit.errors.deposited.title"
      subtitle="vendors.deliveryMethods.shiftToDebit.errors.deposited.subtitle"
    />
  );
};

export default CheckDeposited;
