import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Loader } from '@melio/billpay-design-system';
import locations from 'src/app/utils/locations';
import { getPayment, getRedirectUrl } from 'src/app/redux/payBillWizard/selectors';
import { getUserPreferences } from 'src/app/redux/user/selectors';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import PayBillSuccessPageContainer from 'src/app/pages/bill/pay/QBOPayBillSuccessPageContainer';
import PayBillCompleteLegalInfoPageContainer from 'src/app/pages/bill/pay/QBOPayBillCompleteLegalInfoPageContainer';
import { DELIVERY_TYPE } from 'src/app/utils/consts';
import ReturnedCheckRecoveryFlowRouter from 'src/app/pages/bill/pay/ReturnedCheckRecoveryFlowPage/router';
import VoidCheckRecoveryFlowPage from './VoidCheckRecoveryFlowPage/router';
import { PayBillSelectDeliveryMethodPageContainer } from './PayBillSelectDeliveryMethodPage';
import { VirtualCardRecoveryFlowPage } from './VirtualCardRecoveryFlowPage';
import PayBillDatePageContainer from './PayBillDatePageContainer';
import { PayBillMemoPage } from './PayBillMemoPage/PayBillMemoPage';
import ExpeditePaymentPageContainer from './ExpeditePaymentPageContainer';
import PayBillConfirmPageContainer from './PayBillConfirmPageContainer';
import FastPaymentOfferPage from './FastPaymentOfferPage';
import EditAchDeliveryMethodPage from './EditAchDeliveryMethodPage';
import { PayBillBackgroundSyncEntryPage } from './PayBillBackgroundSyncEntryPage';
import { PayBillInternationalBlockPage } from './PayBillInternationalBlockPage/PayBillInternationalBlockPage';
import payBillLocations from './location';
import { FeatureFlagsEnum } from 'src/app/version-2/model/enums';
import {
  fundingSourcesActions,
  fundingSourcesSelectors,
} from 'src/app/version-2/modules/fundingSources/fundingSources.slice';
import { getFsExperimentPage } from 'src/app/version-2/utils/experimental.util';
import {
  getFeatureFlagStatus,
  getFundingSourceVariant,
} from 'src/app/version-2/utils/featureFlags.utils';
import {
  paymentsActions,
  paymentsSelectors,
} from 'src/app/version-2/modules/payments/payments.slice';

function getExitLink(location, billId) {
  if (location.state && location.state.exitLink) {
    return location.state.exitLink;
  }

  return locations.Bills.view.url({ id: billId });
}

function getNextStepURLForDeliveryMethod(match, isRecurring, deliveryType, location) {
  const isInternational = deliveryType === DELIVERY_TYPE.INTERNATIONAL;
  const locationState = location.state;

  if (isInternational && locationState?.returnToURL) {
    return `${locationState?.returnToURL}`;
  }

  if (isRecurring) {
    return `${match.url}/memo`;
  }

  return `${match.url}/date`;
}

export function BillPayWizardRouter() {
  const match = useRouteMatch<{ billId?: string; paymentId?: string }>();
  const { billId, paymentId } = match.params;
  const location = useLocation();
  const dispatch = useDispatch();
  const redirectUrl = useSelector(getRedirectUrl);
  const isPaymentFetching = useSelector(paymentsSelectors.selectIsFetching);
  const isFundingSourcesFetched = useSelector(fundingSourcesSelectors.selectFundingSourcesFetched);
  const isFetching = !isFundingSourcesFetched || isPaymentFetching;
  const payment = useSelector(getPayment);
  const payments = useSelector(paymentsSelectors.selectPayments);
  const userPreferences = useSelector(getUserPreferences);
  const showCCTooltip = useMemo(() => !userPreferences.fundingSourceCCTooltipSeen, []);
  const isFundingSourceExperiment = getFeatureFlagStatus(
    FeatureFlagsEnum.FUNDING_SOURCE_EXPERIMENT
  );

  const successUrl = redirectUrl || `${match.path}/success`;
  const fundingSources = useSelector(fundingSourcesSelectors.selectValidFundingSources);
  const isRecurring = !billId;

  const getFundingSourceElement = useCallback(() => {
    if (isFetching) return;

    const fundingSourceVariant = getFundingSourceVariant(
      isFundingSourceExperiment,
      fundingSources,
      !!payments.length
    );
    const PayBillFundingSource = getFsExperimentPage(
      isFundingSourceExperiment,
      fundingSourceVariant,
      fundingSources,
      !!payments.length
    );

    const PayBillFundingSourceComponent: JSX.Element = (
      <PayBillFundingSource
        id={billId}
        paymentId={paymentId}
        prevStepURL={getExitLink(location, billId)}
        nextStepURL={`${match.url}/delivery-method`}
        showCCTooltip={showCCTooltip}
        isFundingSourceExperiment={isFundingSourceExperiment}
      />
    );

    return PayBillFundingSourceComponent;
  }, [isFetching]);

  useEffect(() => {
    dispatch(fundingSourcesActions.fetchFundingSources());
    dispatch(paymentsActions.fetchPayments({ limit: 1 }));
  }, []);

  return (
    <Switch>
      <SmartRoute path={payBillLocations.schedule.backgroundSyncEntry} exact>
        <PayBillBackgroundSyncEntryPage showCCTooltip={showCCTooltip} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/funding`} exact>
        {isFetching ? <Loader /> : getFundingSourceElement()}
      </SmartRoute>
      <SmartRoute path={`${match.path}/international/block`} exact>
        <PayBillInternationalBlockPage />
      </SmartRoute>
      <SmartRoute path={`${match.path}/delivery-method`}>
        <PayBillSelectDeliveryMethodPageContainer
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/funding`}
          nextStepURL={getNextStepURLForDeliveryMethod(
            match,
            isRecurring,
            (payment?.deliveryMethod as any)?.type,
            location
          )}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/virtual-card-recovery`}>
        <VirtualCardRecoveryFlowPage id={billId} paymentId={paymentId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/returned-check-recovery`}>
        <ReturnedCheckRecoveryFlowRouter id={billId} paymentId={paymentId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/void-check`}>
        <VoidCheckRecoveryFlowPage id={billId} paymentId={paymentId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/date`}>
        <PayBillDatePageContainer
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/delivery-method`}
          nextStepURL={`${match.url}/memo`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/memo`}>
        <PayBillMemoPage
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/${isRecurring ? 'funding' : 'date'}`}
          nextStepURL={`${match.url}/${isRecurring ? 'confirm' : 'expedite'}`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/expedite`}>
        <ExpeditePaymentPageContainer
          id={billId}
          prevStepURL={`${match.url}/memo`}
          nextStepURL={`${match.url}/confirm`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/confirm`}>
        <PayBillConfirmPageContainer
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/memo`}
          nextStepURL={successUrl}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/success`}>
        <PayBillSuccessPageContainer id={billId} paymentId={paymentId} />
      </SmartRoute>
      <SmartRoute path={`${match.path}/complete-legal-info`}>
        <PayBillCompleteLegalInfoPageContainer
          id={billId}
          prevStepURL={`${match.url}/confirm`}
          nextStepURL={successUrl}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/fast-payment`}>
        <FastPaymentOfferPage
          id={billId}
          paymentId={paymentId}
          prevStepURL={`${match.url}/funding`}
          nextStepURL={`${match.url}/confirm`}
        />
      </SmartRoute>
      <SmartRoute path={`${match.path}/delivery-method-ach`}>
        <EditAchDeliveryMethodPage id={billId} paymentId={paymentId} />
      </SmartRoute>
    </Switch>
  );
}
