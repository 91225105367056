import React from 'react';
import { RecordOf } from 'immutable';
import { BadgeVariants } from '@melio/billpay-design-system';

import { DeliveryMethodType } from 'src/app/utils/types';
import { CONSTS } from 'src/app/utils/consts';
import PayBillPaymentActivitySendingToCountry from 'src/app/pages/bill/pay/components/PayBillPaymentActivity/components/PayBillPaymentActivitySendingToCountry';
import { geInternationalStaticFee } from 'src/app/utils/international';
import { MIFormattedCurrency } from 'src/app/utils/formatting';

import SelectedMethod from '../components/SelectedMethod';

type Props = {
  method: RecordOf<DeliveryMethodType>;
  onClick: () => void;
  companyName: string;
  showDropdownSelectedMethod: boolean;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
  disabledText?: string;
  tag?: {
    label: string;
    variant: BadgeVariants;
  };
  customDescription?: string;
};

function InternationalDeliveryMethod({
  tag,
  onClick,
  method,
  isSelected,
  companyName,
  showDropdownSelectedMethod,
  optionComponent: OptionComponent,
  disabled,
  disabledText,
}: Props) {
  const internationalFee = geInternationalStaticFee();

  if (showDropdownSelectedMethod) {
    if (method) {
      return (
        <SelectedMethod
          method={method}
          title="bills.form.paymentActivity.scheduledBill.internationalTransferMethod"
          label="selectMethods.selectedDeliveryMethodLabel"
          labelValues={{
            displayName: method.getDeliveryInfo(companyName),
          }}
        />
      );
    }

    return null;
  }

  if (!method) {
    return (
      <OptionComponent
        disabled={disabled}
        id="international"
        label="deliveryMethods.notDefinedInfo.international.label"
        labelValues={{ to: '' }}
        icon="icon-international-icon"
        description={disabledText || 'deliveryMethods.notDefinedInfo.international.desc'}
        descriptionValues={{
          fee: internationalFee ? <MIFormattedCurrency value={internationalFee} /> : '..',
        }}
        onClick={onClick}
        tag={tag}
      />
    );
  }

  return (
    <OptionComponent
      id={method.id}
      label="deliveryMethods.notDefinedInfo.international.selectedLabel"
      labelValues={{
        displayName: method.getDisplayName(companyName),
      }}
      method={method}
      description="deliveryMethods.notDefinedInfo.international.desc"
      descriptionValues={{
        fee: internationalFee ? <MIFormattedCurrency value={internationalFee} /> : '..',
      }}
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
      tag={tag}
    />
  );
}

function InternationalDeliverySelectedMethod({
  method,
  companyName,
}: {
  method: RecordOf<DeliveryMethodType>;
  companyName: string;
}) {
  return (
    <div>
      <SelectedMethod
        method={method}
        title="bills.form.paymentActivity.scheduledBill.internationalTransferMethod"
        label="selectMethods.selectedDeliveryMethodLabel"
        labelValues={{
          displayName: method.getDeliveryInfo(companyName),
        }}
      />
      <PayBillPaymentActivitySendingToCountry deliveryMethod={method} />
    </div>
  );
}

InternationalDeliveryMethod.methodType = CONSTS.DELIVERY_TYPE.INTERNATIONAL;
InternationalDeliveryMethod.SelectedMethod = InternationalDeliverySelectedMethod;
InternationalDeliveryMethod.isDisabled = () => false;

export default InternationalDeliveryMethod;
