import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import analytics from 'src/app/services/analytics';

type Props = {
  id: string;
  label?: string;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean, id?: string) => any;
};

const QBOSwitch = ({ id, label, value, onChange, disabled }: Props) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (disabled) return;

    analytics.trackAction(`switch-${id}-${e.target.checked ? 'on' : 'off'}`);
    onChange(e.target.checked, id);
  };

  return (
    <Container onClick={stopPropagationHandler}>
      {label && (
        <Label htmlFor={id}>
          <MIFormattedText label={label} />
        </Label>
      )}
      <BooleanSliderContainer>
        <Checkbox id={`${id}-checkbox`} checked={value} onChange={handleOnChange} />
        <RoundSlider />
      </BooleanSliderContainer>
    </Container>
  );
};

const stopPropagationHandler = (e: SyntheticEvent) => {
  e.stopPropagation();
};

const SIZE = '1.3rem';
const ANIMATION_SPEED = '0.2s';

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.text.color.main};
  ${({ theme }) => theme.text.fontType.medium};
  margin-right: 0.8rem;
  cursor: pointer;
  min-width: 7rem;
`;

const RoundSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.text.color.arrow};
  -webkit-transition: ${ANIMATION_SPEED};
  transition: ${ANIMATION_SPEED};
  border-radius: ${SIZE};

  :before {
    position: absolute;
    content: '';
    height: ${SIZE};
    width: ${SIZE};
    left: 0.1rem;
    bottom: 0.1rem;
    background-color: white;
    -webkit-transition: ${ANIMATION_SPEED};
    transition: ${ANIMATION_SPEED};
    border-radius: 50%;
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  :enabled:checked + ${RoundSlider} {
    background-color: ${({ theme }) => theme.text.color.green};
  }

  :enabled:focus + ${RoundSlider} {
    box-shadow: 0 0 1px ${({ theme }) => theme.text.color.green};
  }

  :checked + ${RoundSlider}:before {
    transform: translateX(1.2rem);
    box-shadow: unset;
  }
`;

const BooleanSliderContainer = styled.label`
  position: relative;
  display: inline-block;

  width: 2.7rem;
  height: 1.5rem;

  ${Checkbox} {
    display: none;
  }
`;

export default QBOSwitch;
