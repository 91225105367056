import React from 'react';
import { MIFormattedText, MIFormattedDate, MIFormattedCurrency } from 'src/app/utils/formatting';
import { DeliveryMethodType, PaymentType, OrganizationType } from 'src/app/utils/types';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { InfoLayout, ReviewGridItem } from '../components';
import { useStateChangedAnalytics, VirtualCardPaymentState } from '../hooks';

type Props = {
  payment?: PaymentType;
  deliveryMethod?: DeliveryMethodType;
  organization?: OrganizationType;
};

const NewDeliveryMethodProcessedLayout = ({ payment, deliveryMethod, organization }: Props) => {
  useStateChangedAnalytics({
    payment,
    paymentState: VirtualCardPaymentState.NewDeliveryMethodProcessed,
  });

  return (
    <InfoLayout showLinks={false} withEmailHighlighting>
      <InfoLayout.Icon type={InfoLayout.IconType.Check} />
      <InfoLayout.Title>
        <MIFormattedText
          label={`vendors.virtualCardDetails.newDeliveryMethodProcessed.${deliveryMethod?.deliveryType}.title`}
        />
      </InfoLayout.Title>
      <InfoLayout.Subtitle>
        <MIFormattedText
          label={`vendors.virtualCardDetails.newDeliveryMethodProcessed.${deliveryMethod?.deliveryType}.subtitle`}
          values={{
            name: organization?.companyName,
            amount: <MIFormattedCurrency value={payment?.amount?.toString() || ''} />,
          }}
        />
      </InfoLayout.Subtitle>
      <InfoLayout.Separator />
      <InfoLayout.SectionTitle>
        <MIFormattedText label="vendors.virtualCardDetails.newDeliveryMethodProcessed.reviewSectionTitle" />
      </InfoLayout.SectionTitle>
      <InfoLayout.Grid>
        <ReviewGridItem
          icon="icon-bank-icon"
          label={<MIFormattedText label="vendors.virtualCardDetails.common.bankTransferTo" />}
          value={
            <MIFormattedText
              label="vendors.virtualCardDetails.common.bankAccount"
              values={{
                digits: getAccountNumber4digits(deliveryMethod?.bankAccount),
              }}
            />
          }
        />
        <ReviewGridItem
          icon="icon-eta-cal"
          label={<MIFormattedText label="vendors.virtualCardDetails.common.deliveryOn" />}
          value={<MIFormattedDate date={payment?.deliveryEta} />}
        />
      </InfoLayout.Grid>
    </InfoLayout>
  );
};

export { NewDeliveryMethodProcessedLayout };
