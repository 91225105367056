import React from 'react';
import { featureFlags } from '@melio/shared-web';
import { ENTRYPOINT_SOURCE } from 'src/app/utils/consts';
import { parseQueryString } from 'src/app/utils/query-utils';
import locations from '../../utils/locations';
import analytics from '../../services/analytics';
import MicroDepositsVerifyPage from './MicroDepositsVerifyPage';
import MicroDepositsVerifyPageOld from './MicroDepositsVerifyPageOld';
import MicroDepositsBlockedPage from './MicroDepositsBlcokedPage';
import { envApi } from '../../version-2/api/env';
import WrongAccount from '../quickbooks/WrongAccount';
import MicroDepositsQBOSuccessPage from './MicroDepositsQBOSuccessPage';

const navTo = (props, url) => () => props.history.push(url);

export default [
  {
    path: locations.FundingSources.microDepositsVerify.url({
      id: ':id',
      token: ':token',
    }),
    exact: true,
    component: (props: any) => {
      const [shouldShowVerifyMDPage] = featureFlags.useFeature('bp-verify-md-page');

      const { id, token } = props.match.params;

      const query = parseQueryString(props.location.search);
      const { source, connectedUserRealmId, realmId, emailTag } = query;

      analytics.page(
        'funding-sources',
        source === ENTRYPOINT_SOURCE.EMAIL ? 'micro-payments-email' : 'micro-payments',
        source === ENTRYPOINT_SOURCE.EMAIL ? { emailTag, fundingSourceId: id } : undefined
      );

      if (source === ENTRYPOINT_SOURCE.EMAIL && connectedUserRealmId !== realmId) {
        return <WrongAccount />;
      }

      const Component = shouldShowVerifyMDPage
        ? MicroDepositsVerifyPage
        : MicroDepositsVerifyPageOld;

      return (
        <Component
          isEmbedded={source === ENTRYPOINT_SOURCE.EMAIL}
          fundingSourceId={parseFloat(id)}
          token={token}
          onSuccess={navTo(props, locations.FundingSources.microDepositsSuccess.url({ id }))}
          onBlocked={navTo(props, locations.FundingSources.microDepositsBlocked.url({ id }))}
        />
      );
    },
  },
  {
    path: locations.FundingSources.microDepositsSuccess.url({ id: ':id' }),
    exact: true,
    component: () => {
      analytics.page('funding-sources', 'micro-payments-success');

      return (
        <MicroDepositsQBOSuccessPage
          complete={() => {
            window.location = envApi.getConfig().quickbooks.baseUrl;
          }}
        />
      );
    },
  },
  {
    path: locations.FundingSources.microDepositsBlocked.url({ id: ':id' }),
    exact: true,
    component: () => {
      analytics.page('funding-sources', 'micro-payments-blocked');

      return <MicroDepositsBlockedPage />;
    },
  },
];
