import { Box, FormLabel } from '@chakra-ui/react';
import { Tooltip, SmartIcon } from '../..';
import { Flex } from '../ui/Flex';

type LabelProps = {
  id: string;
  label?: string;
  tooltipLabel?: string;
  isDisabled?: boolean;
  recommendedLabelText?: string;
  isRequired?: boolean;
};

const Label = ({
  id,
  label,
  tooltipLabel,
  isDisabled,
  recommendedLabelText,
  isRequired,
}: LabelProps) => {
  if (!label) return null;
  return (
    <FormLabel
      textStyle="ds.body2"
      fontWeight="ds.medium"
      mb="ds.xs"
      color={isDisabled ? 'ds.gray.300' : 'ds.gray.200'}
      htmlFor={id}
      display="flex"
      gap="ds.sm"
    >
      {label}
      {tooltipLabel && (
        <Tooltip label={tooltipLabel} closeDelay={500}>
          <Flex alignItems="center">
            <SmartIcon type="info" />
          </Flex>
        </Tooltip>
      )}
      {!isRequired && recommendedLabelText && (
        <Box textStyle="ds.body2" color="ds.grey.200" ms={1}>
          {recommendedLabelText}
        </Box>
      )}
    </FormLabel>
  );
};

export { Label };
