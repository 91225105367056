import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import analytics from 'src/app/services/analytics';

export type ActionOption = {
  label: string;
  action: () => void;
  negative?: boolean;
  disabled?: boolean;
  mobile?: boolean;
  iconName?: string;
};

type Props = {
  actionOptions: ActionOption[];
  onClose: () => void;
  className?: string;
};

export const MIActionsMenu = ({ actionOptions, onClose, className }: Props) => {
  const handleClick = (actionOption: ActionOption) => {
    onClose();
    analytics.trackAction(actionOption.label);
    actionOption.action();
  };

  return (
    <>
      <ActionOverlay onClick={onClose} />
      <Menu className={className}>
        {actionOptions.map((actionOption) => (
          <ActionRow
            key={actionOption.label}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleClick(actionOption);
            }}
            negative={actionOption.negative}
            disabled={actionOption.disabled}
            mobile={actionOption.mobile}
            data-testid={`menu-action-${actionOption.label}`}
          >
            <MIFormattedText label={actionOption.label} />
            {actionOption.iconName && <Icon className={actionOption.iconName} />}
          </ActionRow>
        ))}
        <ActionRow onClick={onClose} cancel mobile>
          <MIFormattedText label="actionsMenu.cancel" />
        </ActionRow>
      </Menu>
    </>
  );
};

const Menu = styled.div`
  background-color: white;

  @media ${devices.mobile} {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 24rem;
    width: 100%;
    padding: 1rem 0;
    box-shadow: 0 -0.4rem 1rem 0 rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    z-index: 1013;
  }

  @media ${devices.nonMobile} {
    position: absolute;
    min-width: 17rem;
    white-space: nowrap;
    padding: 1rem 0;

    border: 0.1rem solid ${(props) => props.theme.colors.border.grey};
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
  }
  ${(props) => props.theme?.components?.MIActionsMenu?.Menu}
`;

const ActionOverlay = styled.div`
  position: fixed;
  z-index: 1012;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(33, 33, 36, 0.53);

  @media ${devices.nonMobile} {
    display: none;
  }
  ${(props) => props.theme?.components?.MIActionsMenu?.ActionOverlay}
`;

const ActionRow = styled.div<{
  negative?: boolean;
  disabled?: boolean;
  mobile?: boolean;
  cancel?: boolean;
}>`
  height: 4.2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: ${(props) =>
    props.negative ? props.theme.text.color.error : props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.bold};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  &:hover {
    background-color: ${(props) => props.theme.colors.dark.translucent05};
  }
  font-size: 1.2rem;

  @media ${devices.nonMobile} {
    padding: 0 2.4rem;
    justify-content: space-between;
    ${(props) =>
      props.mobile &&
      css`
        display: none;
      `}
  }

  @media ${devices.mobile} {
    margin: 1rem 1.4rem;
    border-radius: 0.6rem;
    justify-content: center;
    letter-spacing: 0.111rem;
    line-height: 1.8rem;
    color: ${(props) => props.theme.text.color.main};

    ${(props) =>
      !props.cancel &&
      css`
        background-color: rgba(237, 237, 237, 1);
      `}

    ${(props) =>
      props.cancel &&
      css`
        &:hover {
          background-color: white;
          border: 0.1rem solid ${props.theme.colors.border.grey};
        }
      `}
  }
  ${(props) => props.theme?.components?.MIActionsMenu?.ActionRow}
`;

const Icon = styled.i`
  font-size: 2rem;
  margin-right: -1.6rem;
  color: ${(props) => props.theme.text.color.main};
`;
