import { createSlice } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import api from 'src/app/services/api/bills';
import {
  createApiCallSlice,
  ON_FAILURE,
  ON_REQUEST,
  ON_SUCCESS,
} from 'src/app/helpers/redux/createApiCallSlice';
import { EntityState } from 'src/app/helpers/redux/types';
import { BillType } from 'src/app/utils/types';
import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import { DELIVERY_TYPE } from 'src/app/utils/consts';

const name = 'bills';

export const getBillByIdSlice = createApiCallSlice({
  name: `[${name.toUpperCase()}] FETCH`,
  api: api.getBillById,
  initialState: {},
  reducers: {},
});

type BillListByIdState = {
  deliveryOptions: any;
  partialPayments: Record<number, number>;
  totalCount: number;
  status: {
    loading?: boolean;
    error?: string;
    filteredDirectPayments?: boolean;
    isLimitReached?: boolean;
  };
} & EntityState<BillType>;

const BILLS_LIST_SLICE_NAME = `[${name.toUpperCase()}] BATCH_LIST`;

const initialState: BillListByIdState = {
  totalCount: 0,
  byId: {},
  deliveryOptions: {},
  partialPayments: {},
  status: {
    loading: false,
    error: undefined,
    filteredDirectPayments: false,
    isLimitReached: false,
  },
};

const getBillListByIdsSlice = createApiCallSlice({
  name: BILLS_LIST_SLICE_NAME,
  api: api.getBillsByIds,
  initialState: initialState as BillListByIdState,
  reducers: {
    [ON_REQUEST](state) {
      state.status = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const { billList, totalCount } = action.payload;
      const filteredBillList = pickBy(
        billList,
        (item) =>
          !item.bill.vendor.deliveryMethods?.find((dm) => dm.deliveryType === DELIVERY_TYPE.RPPS)
      ) as any;

      state.status.isLimitReached = Object.keys(billList).length < totalCount;
      state.status.filteredDirectPayments = Object.keys(filteredBillList).length < totalCount;

      state.totalCount = totalCount;
      state.byId = mapValues(filteredBillList, (item) => {
        const deliveryMethod = find(
          item.bill.vendor.deliveryMethods,
          (deliveryMethod) => deliveryMethod.id === item.defaultDate?.defaultDeliveryMethod?.id
        );

        item.bill.payments.push({
          organizationId: item.bill.organizationId,
          amount: item.bill.totalAmount,
          billId: item.bill.id,
          bill: { id: item.bill.id, invoiceNumber: item.bill.invoiceNumber },
          vendorId: item.bill.vendorId,
          vendor: {
            id: item.bill.vendorId,
            companyName: item.bill.vendor.companyName,
          },
          fundingSourceId: item.defaultDate?.defaultFundingSourceId,
          deliveryMethodId: item.defaultDate?.defaultDeliveryMethod?.id,
          deliveryMethod,
          deliveryPreference: null,
          scheduledDate: item.defaultDate?.defaultDates?.suggestedScheduledDate,
          deliveryEta: item.defaultDate?.defaultDates?.deliveryDate,
          currency: item.bill.currency,
          createOrigin: 'qbo-integration',
          purpose: null,
        } as any);

        return item.bill;
      });
      state.deliveryOptions = mapValues(billList, (item) => item.deliveryOptions);
    },
    [ON_FAILURE](state, action) {
      state.status = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    filteredDirectPayments: (state) => state.billsStore.status.filteredDirectPayments,
    isLimitReached: (state) => state.billsStore.status.isLimitReached,
    totalCount: (state) => state.billsStore.totalCount,
    deliveryOptionsByBillId: (billId) => (state) => state.deliveryOptions[billId],
    partialPayments: (state) => state.billsStore.partialPayments,
  },
});

const updateDeliveryMethodsSlice = createSlice({
  name: `${BILLS_LIST_SLICE_NAME} UPDATE DELIVERY METHODS BY BILLID`,
  initialState: initialState as BillListByIdState,
  reducers: {
    update: (state, action) => {
      const { billId, deliveryMethods } = action.payload;

      state.byId[billId].vendor.deliveryMethods = deliveryMethods;
    },
  },
});

const updateDeliveryOptionsSlice = createSlice({
  name: `${BILLS_LIST_SLICE_NAME} UPDATE DELIVERY OPTIONS BY BILLID`,
  initialState: {
    deliveryOptions: {},
  },
  reducers: {
    update: (state, action) => {
      const { billId, deliveryOptions } = action.payload;

      state.deliveryOptions[billId] = deliveryOptions;
    },
  },
});

const updatePartialPayments = createSlice({
  name: `${BILLS_LIST_SLICE_NAME} UPDATE PARTIAL PAYMENTS BY BILLID`,
  initialState: {
    partialPayments: {},
  },
  reducers: {
    updateOne: (state, action) => {
      const { billId, paymentAmount } = action.payload;

      state.partialPayments[billId] = paymentAmount;
    },
    updateMany: (state, action) => {
      const { bills } = action.payload;

      state.partialPayments = {
        ...state.partialPayments,
        ...bills,
      };
    },
  },
});

const resetBatchBillsStoreSlice = createSlice({
  name: `[${name.toUpperCase()}]_RESET_STATE`,
  initialState: {},
  reducers: {
    resetState: () => ({ ...initialState }),
  },
});

const updateBatchBillsSlice = createSlice({
  name: `[${name.toUpperCase()}]_UPDATE_NOTE`,
  initialState: {
    byId: {},
  },
  reducers: {
    updateBatchBillsNoteById: (state, action) => {
      const { billId, note } = action.payload;

      state.byId[billId].note = note;
    },
  },
});

const billsStore = createRestfulSlice({
  name: `[${name.toUpperCase()}]`,
  api: {},
  initialState: {
    byId: {},
    deliveryOptions: {},
    partialPayments: {},
  },
  slices: {
    getBillByIdSlice,
    getBillListByIdsSlice,
    updateDeliveryOptionsSlice,
    resetBatchBillsStoreSlice,
    updateBatchBillsSlice,
    updateDeliveryMethodsSlice,
    updatePartialPayments,
  },
  selectors: {
    deliveryMethodsByBillId: (billId: number) => (state: any) =>
      state.billsStore?.byId[billId]?.vendor?.deliveryMethods,
    paymentByBillId: (billId: number) => (state: any) => state.billsStore?.byId[billId]?.payments,
  },
});

export const getBillListActions = (dispatch: any) => ({
  getBillById: (params: any) => dispatch(billsStore.actions.getBillByIdSlice(params)),
  getBillListByIds: (params: {
    orgId?: string;
    isByVendorId?: boolean;
    billIds?: string | string[];
    vendorId?: number;
  }) => dispatch(billsStore.actions.getBillListByIdsSlice(params)),
  updateDeliveryOptions: (params: any) => {
    dispatch(billsStore.actions.updateDeliveryOptionsSlice.update(params));
  },
  updatePartialPayment: (billId: number, paymentAmount: number) => {
    dispatch(
      billsStore.actions.updatePartialPayments.updateOne({
        billId,
        paymentAmount,
      })
    );
  },
  updatePartialPayments: (bills: Record<number, number>) => {
    dispatch(billsStore.actions.updatePartialPayments.updateMany({ bills }));
  },
  updateBatchBillsNoteById: (bill: Record<string, string>) => {
    dispatch(billsStore.actions.updateBatchBillsSlice.updateBatchBillsNoteById(bill));
  },
  resetQboBatchPaymentsState: () => dispatch(resetBatchBillsStoreSlice.actions.resetState()),
  updateDeliveryMethods: (params: any) =>
    dispatch(billsStore.actions.updateDeliveryMethodsSlice.update(params)),
});

export default billsStore;
