import { VERSION_2 } from 'src/app/version-2/model/constants';
import {
  ADD_CARD_ACCOUNT_SLICE,
  addCardAccountSlice,
} from 'src/app/version-2/pages/add-card-account/modules/addCardAccount.slice';
import {
  ADD_CARD_ACCOUNT_DELIVERY_METHOD_SLICE,
  addCardAccountDeliveryMethodSlice,
} from 'src/app/version-2/pages/add-card-account/modules/deliveryMethod/addCardAccountDeliveryMethod.slice';
import {
  ADD_CARD_ACCOUNT_FUNDING_SOURCE_SLICE,
  addCardAccountFundingSourceSlice,
} from 'src/app/version-2/pages/add-card-account/modules/fundingSource/addCardAccountFundingSource.slice';

import { combineReducers } from 'redux';

export const ADD_CARD_ACCOUNT = `${VERSION_2}addCardAccount`;

export const addCardAccountReducer = () => {
  const reducers = combineReducers({
    [ADD_CARD_ACCOUNT_SLICE]: addCardAccountSlice.reducer,
    [ADD_CARD_ACCOUNT_FUNDING_SOURCE_SLICE]: addCardAccountFundingSourceSlice.reducer,
    [ADD_CARD_ACCOUNT_DELIVERY_METHOD_SLICE]: addCardAccountDeliveryMethodSlice.reducer,
  });

  return (state, action) => reducers(state, action);
};
