import { useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SmartRoute } from 'src/app/modules/navigation/components/SmartRoute';
import analytics from 'src/app/services/analytics';
import { getBill } from 'src/app/redux/payBillWizard/selectors';
import { internationalLocations } from 'src/app/pages/vendor/delivery-methods/international/locations';
import InternationalContext from './Context';
import { Loader } from '@melio/billpay-design-system';
import useInternational from './hooks/useInternational';
import { getFundingSourcePagePath, getPurposeOfPaymentPagePath, isBatchFlow } from './utils';

/* Routes */
import VendorLocation from './routes/VendorLocation';
import PaymentDetails from './routes/PaymentDetails';
import MissingDetails from './routes/MissingDetails';
import PurposeOfPayment from './routes/PurposeOfPayment';
import UltimateBeneficialOwners from './routes/UltimateBeneficialOwners';
import VendorAddress from './routes/VendorAddress';

const Context = InternationalContext.Provider;

const Router = () => {
  const contextValue = useInternational();
  const [state, actions] = contextValue;
  const { countries, exitUrl, redirectUrl } = state;
  const bill = useSelector(getBill);
  const history = useHistory();
  const isInternationalEntryPoint = useSelector(
    (state) => state['flags'].isInternationalEntryPoint
  );

  useEffect(() => {
    analytics.page('vendors', 'international-delivery-method');

    actions.init();
  }, []);

  if (!countries?.length) return <Loader />;

  const onNavigate = (path) => () => actions.goToPath({ path, state });
  const onRedirectBack = (path, state) => actions.goToPath({ path, state });

  const onExit = () => {
    const isBatch = isBatchFlow(state.origin);

    if (state.deliveryMethod || isInternationalEntryPoint) redirectBackToFundingSourcePage();
    else if (isBatch) {
      // exit from new batch flow
      const billId = state?.preservedState?.selectedPaymentIntent?.billId;

      actions.goToPath({ path: state.exitUrl, state, params: { id: billId } });
    } else {
      // exit from single flow
      actions.goToPath({ path: state.exitUrl });
    }
  };

  const redirectBackToFundingSourcePage = () => {
    history.push(getFundingSourcePagePath(state.orgId, bill.id), {
      ...state,
      returnToURL: getPurposeOfPaymentPagePath(state.orgId, state.vendor.id),
    });
  };

  return (
    <Context value={contextValue}>
      <Switch>
        <SmartRoute path={internationalLocations.index} exact>
          <VendorLocation
            onPrev={onNavigate(exitUrl)}
            onNext={onNavigate(internationalLocations.paymentDetails)}
            onExit={onExit}
          />
        </SmartRoute>
        <SmartRoute path={internationalLocations.paymentDetails} exact>
          <PaymentDetails
            onPrev={onNavigate(internationalLocations.index)}
            onNext={onNavigate(internationalLocations.vendorAddress)}
            onExit={onExit}
          />
        </SmartRoute>
        <SmartRoute path={internationalLocations.vendorAddress} exact>
          <VendorAddress
            onPrev={onNavigate(internationalLocations.paymentDetails)}
            onNext={onNavigate(internationalLocations.purposeOfPayment)}
            onExit={onExit}
          />
        </SmartRoute>
        <SmartRoute path={internationalLocations.missingDetails} exact>
          <MissingDetails
            onPrev={onNavigate(internationalLocations.vendorAddress)}
            onNext={onNavigate(internationalLocations.purposeOfPayment)}
            onExit={onExit}
          />
        </SmartRoute>
        <SmartRoute path={internationalLocations.purposeOfPayment} exact>
          <PurposeOfPayment
            onPrev={actions.onPurposeBack}
            onNext={(historyState) => onRedirectBack(redirectUrl, historyState)}
            onExit={onExit}
          />
        </SmartRoute>
        <SmartRoute path={internationalLocations.ultimateBeneficialOwners} exact>
          <UltimateBeneficialOwners
            onNext={onNavigate(internationalLocations.purposeOfPayment)}
            onExit={onExit}
          />
        </SmartRoute>
      </Switch>
    </Context>
  );
};

export default Router;
