import { FormattedDate, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Box, Button, Flex } from '@melio/billpay-design-system';
import { getExpediteTranslations } from 'src/app/pages/vendor/vendor-payment-tracking/utils';
import {
  BUTTON_VARIANT,
  DELIVERY_TYPE,
  EXPEDITE_OPTIONS,
  MIFormattedText,
  PaymentType,
} from 'src/app/version-2/externals';
import { upgradePaymentSelectors } from 'src/app/version-2/pages/vendor-upgrade-payment';

type Props = {
  payment: PaymentType;
  onFooterActionClick: () => void;
  expediteType: EXPEDITE_OPTIONS;
};

const Footer = ({ payment, onFooterActionClick, expediteType }: Props) => {
  const translationRootPath = `vendors.vendorPaymentDetails.footer.${expediteType}`;
  const upsellItems = useSelector(upgradePaymentSelectors.selectUpsellItems);
  const { labels, values } = getExpediteTranslations(
    translationRootPath,
    expediteType,
    upsellItems
  );

  const isCheckedDelivery = payment.deliveryMethod.deliveryType === DELIVERY_TYPE.CHECK;
  const isPushToDebit = `vendors.vendorPaymentDetails.footer.${expediteType}`;
  const { formatMessage, formatNumber } = useIntl();

  return (
    <Flex
      direction="column"
      justify="center"
      bgColor="white"
      gridGap={10}
      data-testid={expediteType}
    >
      <Flex direction="column" justify="center" gridGap={1}>
        <Box
          textStyle="h6"
          fontWeight="ds.semi"
          color="black"
          data-testid="vendor-payment-details-expedite-title"
        >
          <MIFormattedText label={labels.title} values={values.title} />
        </Box>
        <Box
          textStyle="body3"
          color="gray.500"
          data-testid="vendor-payment-details-expedite-description"
        >
          {formatMessage(
            { id: labels.description },
            {
              ...values.description,
              amount: formatNumber(payment?.amount || 0, { style: 'currency', currency: 'USD' }),
            }
          )}
        </Box>
      </Flex>
      <Flex direction="column" justify="center" align="center" gridGap="1rem">
        <Box width="full">
          <Button
            style={{ width: '100%' }}
            variant={BUTTON_VARIANT.PRIMARY}
            size="md"
            onClick={onFooterActionClick}
            data-testid="vendor-payment-details-expedite-cta-button"
          >
            {formatMessage({ id: labels.cta }, values.cta)}
          </Button>
        </Box>
        {isCheckedDelivery &&
          isPushToDebit &&
          !payment.isCheckToP2DOfferExpired &&
          payment.checkToP2DExpirationDate && (
            <Box textStyle="body4" textColor="gray.500">
              {formatMessage(
                { id: `${translationRootPath}.offerExpires` },
                {
                  expirationDate: (
                    <FormattedDate
                      value={+new Date()}
                      day="numeric"
                      month="short"
                      year="numeric"
                      format="MMM DD YYYY"
                    />
                  ),
                }
              )}
            </Box>
          )}
      </Flex>
    </Flex>
  );
};

export { Footer };
