import { Bill } from 'src/app/version-2/model/dtos';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import { BatchBulkBillItem } from 'src/app/version-2/pages/batch-bulk/model/objects';

type BillDTOToBillItemAdapter = (params: {
  bill: Bill;
  paymentIntentId: string;
  maxAmount: number;
  deliveryEta?: Date;
}) => BatchBulkBillItem;

export const billDTOToBillItemAdapter: BillDTOToBillItemAdapter = ({
  bill,
  paymentIntentId,
  deliveryEta,
  maxAmount,
}) => ({
  id: bill.id,
  type: BatchBulkItemEnum.BILL,
  invoiceNumber: bill.invoiceNumber,
  dueDate: new Date(bill.dueDate),
  amount: bill.balance as number,
  companyName: bill.vendor.companyName,
  paymentIntentId,
  deliveryEta,
  qboSyncVersion: bill.qboSyncVersion,
  vendorId: bill.vendorId,
  totalBillIds: [bill.id],
  totalPartialBillIds: (bill.balance || 0) < maxAmount ? [bill.id] : [],
});
