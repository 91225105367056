const commonArrowStyles = {
  display: 'block',
  content: "' '",
  position: 'absolute',
  borderStyle: 'solid',
  borderWidth: '7px',
  boxSizing: 'border-box',
};

export const arrowStyles = (color = 'ds.gray.400') => ({
  '[data-popper-placement*="bottom"] .chakra-popover__content:before': {
    ...commonArrowStyles,
    borderColor: 'transparent',
    borderBottomColor: color,
    transform: 'translateX(-50%)',
    height: '7px',
    left: '50%',
    top: '-15px',
  },
  '[data-popper-placement*="top"] .chakra-popover__content:before': {
    ...commonArrowStyles,
    height: '7px',
    borderColor: 'transparent',
    borderTopColor: color,
    transform: 'translateX(-50%)',
    left: '50%',
    bottom: '-15px',
  },
  '[data-popper-placement*="left"] .chakra-popover__content:before': {
    ...commonArrowStyles,
    borderColor: 'transparent',
    borderLeftColor: color,
    transform: 'translateY(-50%)',
    width: '7px',
    top: '50%',
    right: '-15px',
  },
  '[data-popper-placement*="right"] .chakra-popover__content:before': {
    ...commonArrowStyles,
    borderColor: 'transparent',
    borderRightColor: color,
    transform: 'translateY(-50%)',
    width: '7px',
    top: '50%',
    left: '-15px',
  },
});
