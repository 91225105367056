import { useContext, useEffect } from 'react';
import { generatePath, Redirect } from 'react-router-dom';
import { useApi } from 'src/app/hoc/useApi';
import internationalApi from 'src/app/services/api/deliveryMethods';
import Step from 'src/app/components/layout/QBOLayoutPage';
import analytics from 'src/app/services/analytics';
import { StepProps } from '../../types';
import InternationalContext from '../../Context';
import { Form } from 'src/app/pages/vendor/delivery-methods/international/routes/PaymentDetails/Form';
import { internationalLocations } from 'src/app/pages/vendor/delivery-methods/international/locations';

const PaymentDetails = ({ relativeStep, onNext, onPrev, onExit }: StepProps) => {
  const [state, actions] = useContext(InternationalContext);

  useEffect(() => {
    analytics.page('pay-bill', 'international-country-details', {
      countryCode: state.country.code,
    });
  }, []);

  const [getBankDetails] = useApi(internationalApi.getBankDetails);
  const fetchBankDetails = (billingType, billingValue) =>
    getBankDetails(state.orgId, state.vendor.id, { billingType, billingValue });

  const titleValues = actions.getTitleValues();
  const originValues = actions.getOriginValues();

  if (!state.country?.code)
    return (
      <Redirect
        to={{
          pathname: generatePath(internationalLocations.index, {
            orgId: state.orgId,
            id: state.vendor.id,
          }),
        }}
      />
    );

  return (
    <Step
      titleValues={titleValues}
      relativeStep={relativeStep}
      onPrev={onPrev}
      title="international.paymentDetails.title"
      goExit={onExit}
      hideHeader
      {...originValues}
    >
      <Form fetchBankDetails={fetchBankDetails} onNext={onNext} />
    </Step>
  );
};

export default PaymentDetails;
