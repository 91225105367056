import { createContext } from 'react';

import { initialState } from './hooks/useInternational';

import { ReturnType } from './types';

// TODO: set default values
const context: ReturnType = [
  initialState,
  {
    init: () => ({}),
    setVendorLocation: () => ({}),
    setPaymentDetails: () => ({}),
    setContextData: () => ({}),
    createInternationalDeliveryMethod: () => new Promise(() => ({})),
    goToPath: () => ({}),
    createDelivery: () => new Promise(() => ({})),
    editDelivery: () => new Promise(() => ({})),
    getTitleValues: () => ({}),
    getOriginValues: () => ({}),
    onPurposeBack: () => ({}),
    hasNoUltimateBeneficialOwners: () => true,
  },
];

const InternationalContext = createContext<ReturnType>(context);

export default InternationalContext;
