import React from 'react';
import analytics from 'src/app/services/analytics';
import intercomService from 'src/app/services/intercom';
import errorIcon from 'src/app/images/qbo/error-icon.png';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import useJustPayStore from '../hooks/useJustPayStore';

export const JustPayErrorPage = () => {
  const {
    payment: { trackingBillId },
  } = useJustPayStore();

  const startChat = () => {
    analytics.trackAction('support-open-start-chat', { trackingBillId });
    intercomService.show();
  };

  return (
    <ErrorPage
      illustration={errorIcon}
      title="justPay.errorPage.title"
      subtitle="justPay.errorPage.subtitle"
      buttonAction={startChat}
      buttonLabel="justPay.errorPage.buttonLabel"
    />
  );
};
