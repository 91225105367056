import { InternationalAccountType } from 'src/app/utils/types';
import { DELIVERY_TYPE } from 'src/app/utils/consts';

export const getInternationalDMDisplayLabel = (
  internationalAccount?: InternationalAccountType | null
) => {
  let type = 'SWIFT';
  let number =
    internationalAccount?.payeeBankBicSwift || internationalAccount?.payeeAccount?.bicSwift;

  if (internationalAccount?.payeeBankIban) {
    type = 'IBAN';
    number = internationalAccount?.payeeBankIban;
  }

  const last4digits = number?.substr(-4) || '';

  return `${type} (...${last4digits})`;
};

export const getInternationalDeliveryMethod = (deliveryMethods) => {
  const res = deliveryMethods?.find((dm) => dm?.deliveryType === DELIVERY_TYPE.INTERNATIONAL);

  return res;
};

export const hasInternationalDeliveryMethod = (deliveryMethods) => {
  const res = getInternationalDeliveryMethod(deliveryMethods);

  return !!res;
};

export const geInternationalStaticFee = (): string => '20';

export default { getInternationalDMDisplayLabel };
