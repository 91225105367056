import { MD_SIZE, SIMPLE_VARIANT } from './Table.consts';
import { simpleVariant, mdSize } from './Table.styles';

const parts = ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'];

const variants = {
  [SIMPLE_VARIANT]: simpleVariant,
};

const sizes = {
  [MD_SIZE]: mdSize,
};

const defaultProps = {
  variant: SIMPLE_VARIANT,
  size: MD_SIZE,
};

export const TableTheme = {
  parts,
  variants,
  sizes,
  defaultProps,
};
