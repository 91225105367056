import React, { useMemo } from 'react';
import { addBusinessDays } from 'date-fns';
import { RecordOf } from 'immutable';
import { AccountRecord } from 'src/app/pages/settings/records';
import { BillType, PaymentType } from 'src/app/utils/types';
import { FailedRefundPaymentActivity } from 'src/app/pages/bill/components/ViewPaymentActivity/FailedRefundPaymentActivity/FailedRefundPaymentActivity';
import { Container } from './RefundPaymentDetails.styles';

type Props = {
  payment: PaymentType;
  bill: BillType;
};

export const RefundPaymentDetails = ({ payment, bill }: Props) => {
  const fundingSource = useMemo(
    () => AccountRecord(payment.fundingSource),
    [payment.fundingSource]
  );
  const refundDueDate = useMemo(() => addBusinessDays(new Date(), 5).toISOString(), []);

  return (
    <Container>
      <FailedRefundPaymentActivity
        bill={bill as RecordOf<BillType>}
        payment={payment}
        fundingSource={fundingSource}
        isPartialPayment={false}
        refundDueDate={refundDueDate}
      />
    </Container>
  );
};
