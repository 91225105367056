import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getOrgId } from 'src/app/redux/user/selectors';
import vendorsStore from '../../vendors-store';

export const useUpdateVendorEmail = (vendorId: number) => {
  const orgId = useSelector(getOrgId);
  const vendorActions = useStoreActions(vendorsStore);
  const status = useSelector(vendorsStore.selectors.update.status(vendorId)) as {
    loading: boolean;
  };

  return {
    updateVendorEmail: async (email: string) => {
      await vendorActions.update({
        orgId,
        id: vendorId,
        contactEmail: email,
      });
    },
    loading: !!status?.loading,
  };
};
