import React from 'react';
import styled from 'styled-components';
import { QBRInlineSelectField } from 'src/app/ui/form/QBRInlineSelectField';
import { InlineTextInputField } from 'src/app/ui/form/InlineTextInputField';
import { FormRow } from 'src/app/ui/form/FormElements';
import US_STATES from 'src/app/utils/us-states';

type Props = {
  submit: any;
  printNameLabel: string;
  model: any;
};

const NewCheckOrCardDeliveryMethodForm = ({ submit, printNameLabel, model }: Props) => (
  <FormContainer onSubmit={submit}>
    <RowContainer>
      <InlineTextInputField
        label={printNameLabel}
        model={model?.printName}
        required
        autoFocus
        privateData
      />
    </RowContainer>
    <RowContainer>
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.addressLine1"
        model={model?.addressLine1}
        required
        privateData
      />
    </RowContainer>
    <RowContainer>
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.addressLine2"
        model={model?.addressLine2}
        privateData
      />
    </RowContainer>
    <RowContainer>
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.city"
        model={model?.city}
        required
        privateData
      />
    </RowContainer>
    <RowContainer>
      <QBRInlineSelectField
        id="state"
        label="vendors.addDeliveryMethodByLink.state"
        options={US_STATES}
        model={model?.state}
        required
        privateData
      />
      <Separator />
      <InlineTextInputField
        label="vendors.addDeliveryMethodByLink.zipCode"
        model={model?.zipCode}
        required
        type="tel"
        privateData
      />
    </RowContainer>
    <button type="submit" aria-label="Submit" hidden />
  </FormContainer>
);

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0rem 0 4rem;
`;

const RowContainer = styled(FormRow)`
  align-items: flex-start;
`;

const Separator = styled.div`
  max-width: 3.2rem;
`;

export default NewCheckOrCardDeliveryMethodForm;
