import { devices } from 'src/app/version-2/utils/devices.utils';

import styled from 'styled-components';

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.ds.body1}
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  margin-bottom: ${({ theme }) => theme.sizes.ds.xs};
  color: ${({ theme }) => theme.colors.black};

  @media ${devices.mobile} {
    margin-bottom: ${({ theme }) => theme.sizes.ds.sm};
  }
`;

export const Subtitle = styled.div`
  ${({ theme }) => theme.textStyles.ds.body2}
  font-weight: ${({ theme }) => theme.text.weight.regular};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black};
  gap: ${({ theme }) => theme.sizes.ds.md};
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.sizes.ds.md};
`;
