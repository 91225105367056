const LOCATIONS = {
  base: ['/vendors/virtual-card/:id/:token'],
  details: '/vendors/virtual-card/:id/:token',
  optIn: '/vendors/virtual-card/opt-in/:token',
  infoPage: '/vendors/virtual-card/info/:token',
  posTerminal: '/vendors/virtual-card/pos-terminal/:token',
  invalidToken: '/vendors/virtual-card/:id/:token/invalid-link',
};

export default LOCATIONS;
