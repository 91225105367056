import { EventMappingType } from './types';

type MappingsType = {
  request?: Record<string, number | string | boolean>;
  failure?: Record<string, number | string | boolean>;
  validationError?: Record<string, number | string | boolean>;
  success?: Record<string, number | string | boolean>;
};

export function mapApiSlice(
  sliceName: string,
  page: string,
  eventType: string,
  mappings?: MappingsType
): Record<string, EventMappingType> {
  return {
    [`${sliceName}_REQUEST`]: [
      page,
      `${eventType}-request`,
      { id: 'payload.id', ...(mappings?.request || {}) },
    ],
    [`${sliceName}_FAILURE`]: [
      page,
      `${eventType}-error`,
      { error: 'error', ...(mappings?.failure || {}) },
    ],
    [`${sliceName}_VALIDATION_FAILURE`]: [
      page,
      `${eventType}-validation-error`,
      {
        validation: 'error.validationErrors',
        ...(mappings?.validationError || {}),
      },
    ],
    [`${sliceName}_SUCCESS`]: [page, `${eventType}-success`, { ...(mappings?.success || {}) }],
  };
}
