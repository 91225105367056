export const modalHeaderTitleStyle = (isCentred?: boolean) => ({
  textStyle: 'ds.h6',
  alignSelf: isCentred ? 'center' : 'flex-start',
});

export const modalHeaderSubTitleStyle = {
  textStyle: 'ds.body2',
  alignSelf: 'flex-start',
  pb: 'ds.lg',
  pt: 'ds.md',
};

export const modalImageStyle = {
  display: 'flex',
  alignItems: 'center',
  mb: 'ds.xl',
  mt: 'ds.2xl',
};

export const buttonContainerStyle = (isCentred?: boolean) => ({
  width: 'full',
  display: 'flex',
  flexWrap: 'wrap-reverse',
  gap: 'ds.xl',
  justifyContent: isCentred ? 'center' : 'flex-end',
});

export const modalBodyStyle = ({ unsetModalOverflow = false }) => ({
  overflow: unsetModalOverflow ? 'unset' : 'auto',
});

export const spacingBody = { base: 'ds.2xl', sm: '3.2rem' };

export const modalBodyContainer = {
  textStyle: 'ds.body2',
};

export const modalCoverStyle = {
  w: 'inherit',
  h: 'inherit',
  borderTopRadius: 'ds.lg',
};
