import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Checkbox } from '@melio/billpay-design-system';

import { ExtendedSelectOption } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  options: ExtendedSelectOption[];
  onChange: (value: ExtendedSelectOption[]) => void;
};

const CheckboxSelect = ({ options, onChange }: Props) => {
  const intl = useIntl();

  const handleSelection = (checked: boolean, selectedIndex: number) => {
    onChange(
      options.map((option, index) =>
        index === selectedIndex ? { ...option, value: checked, selected: checked } : option
      )
    );
  };

  return (
    <CheckboxSelectWrapper>
      {options.map((option, index) => (
        <CheckboxSelectItem
          key={`${index + option.label}`}
          title={intl.formatMessage({ id: option.label }, option.values)}
        >
          <Checkbox
            onChange={() => handleSelection(!option.value, index)}
            isChecked={option.value}
          />
          <CheckboxLabel onClick={() => handleSelection(!option.value, index)}>
            <MIFormattedText label={option.label} values={option.values} />
          </CheckboxLabel>
        </CheckboxSelectItem>
      ))}
    </CheckboxSelectWrapper>
  );
};

export { CheckboxSelect };

const CheckboxSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-height: 25rem;
  overflow: auto;
  padding: 0.4rem 0;
`;

const CheckboxSelectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  line-height: 4rem;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 1.4rem;

  &:hover {
    background-color: ${(props) => props.theme.colors.ds.gray[800]};
  }
`;

const CheckboxLabel = styled.span`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
`;
