/* eslint-disable global-require,@typescript-eslint/no-var-requires */
import { parseBoolean, parseNumber } from 'src/app/version-2/api/env/env.utils';
import { deepMerge } from 'src/app/version-2/utils/common';

const env = {
  server: {
    baseUrl: process.env.NX_SERVER_BASE_URL,
  },
  web: {
    baseUrl: process.env.NX_WEB_BASE_URL,
  },
  analytics: {
    shouldTrackEvents: parseBoolean(process.env.NX_ANALYTICS_SHOULD_TRACK_EVENTS),
    shouldPrintEvents: parseBoolean(process.env.NX_ANALYTICS_SHOULD_PRINT_EVENTS),
  },
  services: {
    intuit: {
      clientId: process.env.NX_SERVICES_INTUIT_CLIENT_ID,
      openid_url: process.env.NX_SERVICES_INTUIT_OPEN_ID_URL,
    },
    dataDog: {
      applicationId: process.env.NX_SERVICES_DATADOG_APPLICATION_ID,
      clientToken: process.env.NX_SERVICES_DATADOG_CLIENT_TOKEN,
      service: process.env.NX_SERVICES_DATADOG_SERVICE,
      site: process.env.NX_SERVICES_DATADOG_SITE,
    },
    plaid: {
      clientName: process.env.NX_SERVICES_PLAID_CLIENT_NAME,
      linkCustomizationName: process.env.NX_SERVICES_PLAID_LINK_CUSTOMIZATION_NAME,
      env: process.env.NX_SERVICES_PLAID_ENV,
    },
    segment: {
      key: process.env.NX_SERVICES_SEGMENT_KEY,
      shouldLoad: parseBoolean(process.env.NX_SERVICES_SEGMENT_SHOULD_LOAD),
    },
    tabapay: {
      url: process.env.NX_SERVICES_TABAPAY_URL,
    },
    intercom: {
      app_id: process.env.NX_SERVICES_INTERCOM_APP_ID,
      isShow: parseBoolean(process.env.NX_SERVICES_INTERCOM_IS_SHOW),
    },
    zendesk: {
      widgetApiKey: process.env.NX_SERVICES_ZENDESK_WIDGET_API_KEY,
    },
    googleMaps: {
      url: process.env.NX_SERVICES_GOOGLE_MAPS_URL,
      key: process.env.NX_SERVICES_GOOGLE_MAPS_KEY,
    },
    smartystreets: {
      key: process.env.NX_SERVICES_SMARTY_STREETS,
    },
    featureFlagProvider: {
      enabled: parseBoolean(process.env.NX_SERVICES_FEATURE_FLAG_PROVIDER_ENABLED),
      clientId: process.env.NX_SERVICES_FEATURE_FLAG_PROVIDER_CLIENT_ID,
    },
    basisTheory: {
      key: process.env.NX_SERVICES_BASIS_THEORY_KEY,
    },
  },
  jwt: {
    // not used?
    session: process.env.NX_JWT_SESSION,
    expirationDays: parseNumber(process.env.NX_JWT_EXPIRATION_DAYS),
  },
  support: {
    phone: process.env.NX_SUPPORT_PHONE,
    email: process.env.NX_SUPPORT_EMAIL,
    accountantsEmail: process.env.NX_SUPPORT_ACCOUNTANTS_EMAIL,
    site: process.env.NX_SUPPORT_SITE,
  },
  agreementLinks: {
    userAgreement: process.env.NX_AGREEMENT_LINKS_USER_AGREEMENT,
    privacyPolicy: process.env.NX_AGREEMENT_LINKS_PRIVACY_POLICY,
  },
  quickbooks: {
    baseUrl: process.env.NX_QUICKBOOKS_BASE_URL,
  },
  qbo: {
    web: {
      baseUrl: process.env.NX_QBO_WEB_BASE_URL,
    },
    support: {
      phone: process.env.NX_QBO_SUPPORT_PHONE,
      email: process.env.NX_QBO_SUPPORT_EMAIL,
    },
    compliance: {
      email: process.env.NX_QBO_COMPLIANCE_EMAIL,
    },
    agreementLinks: {
      privacyPolicy: process.env.NX_QBO_AGREEMENT_LINKS_PRIVACY_POLICY,
      userAgreement: process.env.NX_QBO_AGREEMENT_LINKS_USER_AGREEMENT,
      termsOfService: process.env.NX_QBO_AGREEMENT_LINKS_TERMS_OF_SERVICE,
      QBOnlineData: process.env.NX_QBO_AGREEMENT_LINKS_QB_ONLINE_DATA,
      intuitPrivacyPolicy: process.env.NX_QBO_AGREEMENT_LINKS_INTUIT_PRIVACY_POLICY,
    },
    helpCenter: {
      link: process.env.NX_QBO_HELP_CENTER_LINK,
    },
  },
  qbr: {
    web: {
      baseUrl: process.env.NX_QBR_WEB_BASE_URL,
    },
    support: {
      email: process.env.NX_QBR_SUPPORT_EMAIL,
      phone: process.env.NX_QBR_SUPPORT_PHONE,
    },
  },
  debounceDelay: parseNumber(process.env.NX_DEBOUNCE_DELAY),
  featureFlags: {
    partialPayments: parseBoolean(process.env.NX_FEATURE_FLAGS_PARTIAL_PAYMENTS),
    vendorFlowsViralityTest: parseBoolean(process.env.NX_FEATURE_FLAGS_VENDOR_FLOWS_VIRALITY_TEST),
    test2FARolloutPeriod: parseBoolean(process.env.NX_FEATURE_FLAGS_TEST_2FA_ROLLOUT_PERIOD),
    ultimateBeneficialOwnersFlow: parseBoolean(
      process.env.NX_FEATURE_FLAGS_ULTIMATE_BENEFICIAL_OWNERS_FLOW
    ),
    showVendorDirectory: parseBoolean(process.env.NX_FEATURE_FLAGS_SHOW_VENDOR_DIRECTORY),
  },
  globalOptInFlowLinks: {
    privacyPolicy: process.env.NX_GLOBAL_OPT_IN_FLOW_LINKS_PRIVACY_POLICY,
    termsOfServices: process.env.NX_GLOBAL_OPT_IN_FLOW_LINKS_TERMS_OF_SERVICE,
  },
};
const { qbo, qbr, ...config } = deepMerge({}, env, (window as any).dynEnvConfig);

export const envApi = {
  getConfig: () => config,
  getQBOSpecificConfig: () => qbo,
  getQBRSpecificConfig: () => qbr,
};
