const index = '/orgs/:orgId/bills/:id/payment/:paymentId/edit/returned-check-recovery';

const LOCATIONS = {
  base: [index],
  index,
  missingACHDetails: `${index}/ach/new`,
  chooseCheckDelivery: `${index}/check/update-delivery`,
};

export default LOCATIONS;
