import React from 'react';
import { RecordOf } from 'immutable';
import { MIFormattedText } from 'src/app/utils/formatting';
import { getAccountNumber4digits } from 'src/app/utils/bank-account';
import { QBCashStateType, BillType } from 'src/app/utils/types';
import { getQBBalanceProps } from 'src/app/utils/bills';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { FundingSourceOrigins } from 'src/app/version-2/model/enums';
import {
  getPaymentMethodDisplayName,
  getPaymentMethodInstitutionName,
} from 'src/app/version-2/utils/paymentMethods.utils';

type FundingSourceDescriptionProps = {
  fundingSource: FundingSource;
  qbCashState?: QBCashStateType;
  bill: RecordOf<BillType>;
  testId?: string;
};

const FundingSourceDescription = ({
  bill,
  fundingSource,
  qbCashState,
  testId = 'funding-source-description',
}: FundingSourceDescriptionProps) => {
  let description;

  if (fundingSource?.bankAccount) {
    const displayName = fundingSource ? getPaymentMethodDisplayName({ fundingSource }) : '';
    const institutionName = fundingSource ? getPaymentMethodInstitutionName({ fundingSource }) : '';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);

    if (institutionName) {
      description = `${displayName} (${institutionName}, ...${cutAccountNumber})`;
    } else {
      description = `${displayName} (...${cutAccountNumber})`;
    }
  } else if (fundingSource?.cardAccount) {
    description = `${fundingSource.cardAccount.network} (...${fundingSource.cardAccount.card4digits})`;
  }

  if (fundingSource.origin === FundingSourceOrigins.QBCASH) {
    const qbBalanceProps = getQBBalanceProps(bill.status, qbCashState);

    if (qbBalanceProps?.values?.error?.code) {
      description = (
        <MIFormattedText
          testId={testId}
          label="bills.form.paymentActivity.scheduledBill.noBalance"
        />
      );
    } else {
      description = (
        <MIFormattedText
          testId={testId}
          label="bills.form.paymentActivity.scheduledBill.balance"
          {...qbBalanceProps}
        />
      );
    }
  }

  return description;
};

export default FundingSourceDescription;
