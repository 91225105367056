import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Button, SmartIcon } from '@melio/billpay-design-system';
import { MIFormattedText } from 'src/app/version-2/externals';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/app/version-2/model/constants/fullStoryMaskClass.consts';
import { virtualCardDetailsActions } from 'src/app/version-2/pages/virtual-card-details/modules/virtualCardDetails.slice';
import { getFormattedCardNumbers } from 'src/app/version-2/utils/formatting';
import {
  AmountContainer,
  amountStyle,
  ButtonContainer,
  CardDetailsContainer,
  CardDetailsWrapper,
  CardNumber,
  CardPreviewWrapper,
  Container,
  DecimalAmountPart,
  Footer,
  LeftLabel,
  LogoContainer,
  RightLabel,
} from './CardPreview.styles';

interface CardPreviewProps {
  amount: number;
  isGlobalOptInUpsellEnabled?: boolean;
  cardDetails: {
    number?: string;
    cvc?: string;
    exp?: string;
  };
}

export const CardPreview = ({
  amount,
  cardDetails,
  isGlobalOptInUpsellEnabled = false,
}: CardPreviewProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const formattedAmount = intl.formatNumber(amount, {
    style: 'currency',
    currency: 'USD',
  });
  const [wholeAmountPart, decimalAmountPart] = formattedAmount.split('.');

  const formattedCardNumber = getFormattedCardNumbers(cardDetails.number);

  const onModalOpen = () => {
    dispatch(virtualCardDetailsActions.setHowToUseCardModalAsOpen());
  };

  return (
    <CardPreviewWrapper>
      <Container>
        <LogoContainer>
          <SmartIcon type="qb-semitransparent-logo" width="10.8rem" height="10rem" />
        </LogoContainer>

        <AmountContainer>
          <Box as="span" __css={amountStyle} className={FULL_STORY_MASK_RULE_CLASS}>
            {wholeAmountPart}
            <DecimalAmountPart>{decimalAmountPart}</DecimalAmountPart>
          </Box>
          <SmartIcon type="mastercard" width="3.6rem" height="3.4rem" />
        </AmountContainer>

        <Footer>
          <CardNumber>
            <MIFormattedText
              privateData
              label="common.rawValue"
              values={{ value: formattedCardNumber }}
            />
          </CardNumber>
          <CardDetailsContainer>
            <CardDetailsWrapper>
              <LeftLabel>
                <MIFormattedText label="vendors.virtualCardDetails.valid.previewCard.validThru" />
              </LeftLabel>
              <MIFormattedText
                privateData
                label="common.rawValue"
                values={{ value: cardDetails.exp }}
              />
              <RightLabel>
                <MIFormattedText label="vendors.virtualCardDetails.valid.previewCard.cvc" />
              </RightLabel>
              <MIFormattedText
                privateData
                label="common.rawValue"
                values={{ value: cardDetails.cvc }}
              />
            </CardDetailsWrapper>
            <SmartIcon type="jpmorgan-bank" width="7.6rem" height="1.6rem" />
          </CardDetailsContainer>
        </Footer>
      </Container>
      {isGlobalOptInUpsellEnabled && (
        <ButtonContainer>
          <Button onClick={onModalOpen} variant="tertiary" size="sm">
            <MIFormattedText label="vendors.virtualCardDetails.valid.openModalButton" />
          </Button>
        </ButtonContainer>
      )}
    </CardPreviewWrapper>
  );
};
