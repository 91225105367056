import React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  label: string;
  onLabelClick?: (paymentId, status, isMarkedAsPaid) => void;
  isCurrentPayment: boolean;
};

const PartialPaymentLabel = ({ label, onLabelClick, isCurrentPayment }: Props) => (
  <Label>
    {isCurrentPayment ? (
      <MIFormattedText label="bills.form.partialPayments.current" />
    ) : (
      <ReviewLink onClick={onLabelClick as any}>
        <MIFormattedText label={label} />
      </ReviewLink>
    )}
  </Label>
);

const Label = styled.span`
  margin-left: 0.8rem;
  ${(props) => props.theme.text.fontType.hint};

  ${({ theme }) => theme?.components?.BillOpenBalance?.CurrentLabel}
`;

const ReviewLink = styled.div`
  color: ${(props) => props.theme.colors.primary.opaque};
  font-weight: ${(props) => props.theme.text.weight.regular};
  cursor: pointer;
  ${(props) => props.theme.text.fontType.hint};

  ${({ theme }) => theme?.components?.BillOpenBalance?.ReviewLink}
`;

export default PartialPaymentLabel;
