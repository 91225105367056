import { FileLoader } from 'src/app/components/common/FileLoader';
import { CONSTS } from 'src/app/utils/consts';

type Props = {
  onCancelAction: () => void;
};

export const BillFileLoader = ({ onCancelAction }: Props) => (
  <FileLoader
    title="bills.new.uploading.title"
    subtitle="bills.new.uploading.subtitle"
    buttonLabel="bills.new.cancel"
    onCancelAction={onCancelAction}
    buttonVariant={CONSTS.BUTTON_VARIANT.CANCEL}
  />
);
