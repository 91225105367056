import React from 'react';
import MIAddressAutocompleteSmartyStreets from 'src/app/components/common/MIAddressAutocompleteSmartyStreets';
import { FormContainer, FormRow } from 'src/app/ui/form/FormElements';
import styled from 'styled-components';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import { WizardSelectField } from 'src/app/ui/form/WizardSelectField';
import { ModelView } from 'src/app/ui/form';
import { AddressType, CheckType } from 'src/app/utils/types';
import US_STATES from 'src/app/utils/us-states';

export type CheckDeliveryMethodModelType = ModelView<CheckType>;

type Props = {
  submit: () => Promise<void>;
  model: CheckDeliveryMethodModelType;
  eventPage: string;
  setShowNotification: (boolean) => void;
};

const CheckAddressDeliveryMethodForm = ({
  submit,
  model,
  eventPage,
  setShowNotification,
}: Props) => {
  const onChangeSuggestedAddress = async (value: AddressType | null) => {
    model.setModelState((prevState) => ({
      ...prevState,
      ...value,
    }));
    model.setValidationErrors({});
    setShowNotification(false);
  };

  return (
    <FormStyle>
      <FormContainer onSubmit={submit}>
        <FormRow>
          <MIAddressAutocompleteSmartyStreets
            id="addressLine1"
            label="manualAddress.line1Label"
            placeholder="manualAddress.line1Placeholder"
            model={model.addressLine1}
            required
            autoFocus
            autocomplete="off"
            onSelected={onChangeSuggestedAddress}
            eventPage={eventPage}
          />
        </FormRow>
        <FormRow>
          <WizardTextInputField
            label="manualAddress.line2Label"
            placeholder="manualAddress.line2Placeholder"
            model={model.addressLine2}
            autocomplete="off"
            required
            privateData
          />
        </FormRow>
        <FormRow>
          <WizardTextInputField
            label="manualAddress.cityLabel"
            model={model.city}
            autocomplete="off"
            required
            privateData
          />
        </FormRow>
        <FormRowSideBySide>
          <WizardSelectField
            id="state"
            label="manualAddress.stateLabel"
            model={model.state}
            options={US_STATES}
            required
            privateData
          />
          <WizardTextInputField
            label="manualAddress.zipCodeLabel"
            model={model.zipCode}
            autocomplete="off"
            required
            privateData
          />
        </FormRowSideBySide>
      </FormContainer>
    </FormStyle>
  );
};

export default CheckAddressDeliveryMethodForm;

const FormStyle = styled.div`
  width: 100%;
  .input-container {
    margin-bottom: 0;
  }
`;

const FormRowSideBySide = styled(FormRow)`
  align-items: baseline;
  margin-top: 2rem;
`;
