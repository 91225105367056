import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  applicationActions,
  applicationSelectors,
} from 'src/app/version-2/modules/application/application.slice';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import QBOAccordion from './QBOAccordion';

type Props = {
  onCloseCallback?: () => void;
};

const QBODrawer = ({ onCloseCallback }: Props) => {
  const dispatch = useDispatch();
  const { isOpen, focusedQuestion } = useSelector(applicationSelectors.selectFaqPanel);
  const site = useSiteContext();
  const { DrawerSubtitle } = site.components;

  useEffect(() => {
    if (isOpen && focusedQuestion) {
      const toElement = document.getElementById(`faq-${focusedQuestion}`);

      if (toElement) {
        toElement.click();
        setTimeout(() => {
          toElement.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    }
  }, [isOpen]);

  const onClose = () => {
    onCloseCallback?.(); // cb from component
    dispatch(applicationActions.setFaqPanel({ isOpen: false })); // closing from redux
  };

  return (
    <DrawerContainer id="drawer" isDrawerOpen={isOpen}>
      <FAQDrawer className={isOpen ? 'open' : ''}>
        <Close>
          <CloseAction onClick={onClose}>
            <i className="icon-close-icon" />
          </CloseAction>
        </Close>
        <TitleContainer>
          <MIFormattedText label="drawer.title" />
        </TitleContainer>
        <DrawerContentContainer>
          <DrawerSubtitle />
        </DrawerContentContainer>
        {isOpen && <QBOAccordion />}
      </FAQDrawer>
    </DrawerContainer>
  );
};

export default QBODrawer;

const DrawerContainer = styled.div<{ isDrawerOpen?: boolean }>`
  z-index: ${(props) => props.theme.zIndex.overlay};
  position: ${(props) => (props.isDrawerOpen ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.dark.translucent5};
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${devices.nonDesktop} {
    ${({ isDrawerOpen }) =>
      !isDrawerOpen &&
      css`
        height: 0;
      `}
  }
`;

const FAQDrawer = styled.div`
  width: 56.5rem;
  padding: 2rem 3rem 3rem;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white.opaque};
  position: fixed;
  z-index: 10000;
  top: 0;
  right: -56.5rem;
  overflow-x: hidden;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;

  &.open {
    right: 0;

    @media ${devices.mobile} {
      width: 100%;
    }
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4.2rem 1.6rem 0 1.6rem;
  }
`;

const Close = styled.div`
  font-size: 1.8rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: ${(props) => props.theme.text.size.title};
  }
`;

const CloseAction = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.text.color.label};

  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }
`;

const TitleContainer = styled.div`
  color: rgba(37, 37, 37, 1);
  font-weight: ${(props) => props.theme.text.weight.normal};
  font-size: 2.4rem;
  line-height: 3.2rem;
`;

const DrawerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`;
