import React, { useEffect, useRef, useState } from 'react';
import { Box, Link } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';
import { ResendState, VerificationState } from '../hooks/useAuthCodeVerification';

type Props = {
  resendState: ResendState;
  verificationState: VerificationState;
  onResendCodeClick: VoidFunction;
  onContactSupportClick: VoidFunction;
};

const RESEND_INTERVAL = 30;

const Footer = ({
  resendState,
  verificationState,
  onResendCodeClick,
  onContactSupportClick,
}: Props) => {
  const coundownRef = useRef<ReturnType<typeof setInterval>>();
  const [counter, setCounter] = useState<number>(0);

  useEffect(
    () => () => {
      if (coundownRef.current) {
        clearInterval(coundownRef.current);
      }
    },
    []
  );

  useEffect(() => {
    if (counter === 0) {
      clearInterval(coundownRef.current as any);
    }
  }, [counter]);

  useEffect(() => {
    if (verificationState === VerificationState.FailedMaxAttempts) {
      setCounter(0);
    }
  }, [verificationState]);

  const handleResendCodeClick = () => {
    initializeCountdown();
    onResendCodeClick();
  };

  const initializeCountdown = () => {
    setCounter(RESEND_INTERVAL);

    coundownRef.current = setInterval(() => {
      setCounter((currentCounter) => currentCounter - 1);
    }, 1000);
  };

  const resendDisabled = counter > 0;
  const resendVisible =
    resendState !== ResendState.Final && resendState !== ResendState.FinalHidden;
  const countdownVisible =
    (resendState === ResendState.Regular || resendState === ResendState.Pending) &&
    verificationState !== VerificationState.FailedMaxAttempts;

  return (
    <Box __css={footerStyle}>
      {resendVisible && (
        <Box mb="0.8rem">
          <MIFormattedText label="guests.codeVerification.footer.code" />

          <Link
            onClick={resendDisabled ? () => null : handleResendCodeClick}
            color={resendDisabled ? '#8c9097' : 'blue.500'}
            cursor={resendDisabled ? 'auto' : 'pointer'}
            textDecoration="none !important"
          >
            <MIFormattedText label="guests.codeVerification.footer.resend" />
          </Link>

          {counter > 0 && countdownVisible && (
            <>
              {` in `}
              <Box as="span" __css={boldTextStyle}>{`${counter} second${
                counter > 1 ? 's' : ''
              }`}</Box>
            </>
          )}
        </Box>
      )}
      <MIFormattedText
        label="guests.codeVerification.footer.support"
        values={{
          link: (...chunks) => (
            <Link onClick={onContactSupportClick} textDecoration="none !important" cursor="pointer">
              {React.Children.toArray(chunks)}
            </Link>
          ),
        }}
      />
    </Box>
  );
};

const footerStyle = {
  color: '#000',
  textAlign: 'center',
};

const boldTextStyle = {
  fontWeight: 600,
};

export { Footer };
