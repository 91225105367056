import { organizationApi } from 'src/app/version-2/api/organization.api';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { intuitActions } from './intuit.slice';

import { call, put, select, takeEvery } from 'redux-saga/effects';

function* fetchIntuitBillCategories() {
  try {
    const orgId = yield select(userSliceSelectors.selectOrgId);
    const billCategories = yield call(organizationApi.fetchCategoriesForBill, orgId);

    yield put(intuitActions.setBillCategories(billCategories?.accounts ?? []));
  } catch (error) {
    yield put(intuitActions.setBillCategories([]));
  }
}

export function* watchIntuitSaga() {
  yield takeEvery(intuitActions.fetchBillCategories, fetchIntuitBillCategories);
}
