import { useState } from 'react';
import styled from 'styled-components';
import withOutsideClickHandler from 'src/app/hoc/withOutsideClickHandler';
import { devices } from 'src/app/theme/AppDevices';
import analytics from 'src/app/services/analytics';
import { MIActionsMenu, ActionOption } from 'src/app/components/common/MIActionsMenu';

type Props = {
  actionOptions: ActionOption[];
  moduleName?: string;
  testId?: string;
  icon?: string;
};

const MIDotsActionsMenu = ({ actionOptions, moduleName, testId, icon }: Props) => {
  const [isOpen, toggleIsOpen] = useState(false);

  const toggleMenu = (value) => {
    const actionName = moduleName || 'toggle-actions-menu';

    analytics.trackAction(actionName, { isOpen: value });
    toggleIsOpen(value);
  };

  return (
    <MIDotsActionsContainer
      handleClickOutside={() => {
        if (isOpen) toggleMenu(false);
      }}
    >
      <Container
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          toggleMenu(!isOpen);
        }}
        isOpen={isOpen}
        data-testid={testId}
      >
        {icon ? (
          <Icon className={icon} />
        ) : (
          <Dots open={isOpen}>
            <Dot />
            <Dot />
            <Dot />
          </Dots>
        )}
      </Container>
      {isOpen && (
        <StyledMIActionsMenu onClose={() => toggleIsOpen(false)} actionOptions={actionOptions} />
      )}
    </MIDotsActionsContainer>
  );
};

export default MIDotsActionsMenu;

const MIDotsActionsContainer = withOutsideClickHandler(styled.div`
  @media ${devices.nonMobile} {
    position: relative;
  }
  ${(props) => props.theme?.components?.MIDotsActionsMenu?.MIDotsActionsContainer}
`);

const Dot = styled.div`
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme?.components?.MIDotsActionsMenu?.Dot}
`;

const Dots = styled.div<{ open?: boolean }>`
  display: flex;
  ${(props) => props.theme?.components?.MIDotsActionsMenu?.Dots}
`;

const Icon = styled.i`
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.regular};
`;

const StyledMIActionsMenu = styled(MIActionsMenu)`
  @media ${devices.nonMobile} {
    right: 0rem;
    top: 3.8rem;
    z-index: 1;
  }
  ${(props) => props.theme?.components?.MIDotsActionsMenu?.StyledMIActionsMenu}
`;

const Container = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  width: 4.2rem;
  border-radius: 0.6rem;
  background-color: rgba(238, 238, 238, 1);
  cursor: pointer;

  &:hover {
    background-color: rgba(222, 222, 222, 1);
  }
  ${(props) => props.theme?.components?.MIDotsActionsMenu?.Container}
`;
