import { useSelector } from 'react-redux';
import { featureFlags } from '@melio/shared-web';
import { getOrgId } from 'src/app/redux/user/selectors';
import accountingSoftwareSync from 'src/app/services/api/accountingSoftwareSync';
import locations from 'src/app/utils/locations';
import globalLocations from 'src/app/pages/locations';
import { isOrgBlockedByRisk } from 'src/app/utils/company';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { userSliceSelectors } from 'src/app/version-2/modules/user/user.slice';
import { UserCompanyInfo } from 'src/app/version-2/model/dtos/userCompanyInfo';
import { loggingApi } from 'src/app/version-2/api/loggers';

type Params = {
  initialPaymentAmount?: number;
  redirectUrl?: string;
  exitUrl?: string;
  originId: string;
  errorBillDetails?: {
    amount?: number;
    vendorName?: string;
  };
  billZeroBalanceRedirectUrl: string;
};

type HookParams = {
  shouldUseHistoryReplace: boolean;
};

export const useRedirectToSinglePayBillFlow = ({ shouldUseHistoryReplace }: HookParams) => {
  const [historyPush, historyReplace] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const companyInfo: Partial<UserCompanyInfo> = useSelector(userSliceSelectors.getCompanyInfo);

  const [isBackgroundSyncPayBillEntry] = featureFlags.useFeature(
    'bp-background-sync-pay-bill-entry',
    false
  );
  const historyNavigate = shouldUseHistoryReplace ? historyReplace : historyPush;

  const syncBill = async ({
    originId,
    errorBillDetails,
    redirectUrl,
    exitUrl,
    initialPaymentAmount,
    billZeroBalanceRedirectUrl,
  }: Params) => {
    try {
      const { bill } = await accountingSoftwareSync.syncBillByOriginId(orgId, originId);

      if (bill.balance === 0) {
        historyNavigate({ path: billZeroBalanceRedirectUrl, state: { billId: bill.id, exitUrl } });
      } else if (bill.contractor) {
        historyNavigate({
          path: globalLocations.entrypoints.errors.contractors,
          state: { billId: bill.id, exitUrl },
        });
      } else {
        const payBillUrl = locations.Bills.pay.funding.url({
          id: bill.id,
          orgId,
        });

        historyNavigate({
          path: payBillUrl,
          state: { redirectUrl, exitUrl, initialPaymentAmount },
        });
      }
    } catch (error: any) {
      loggingApi.error('useRedirectToSinglePayBillFlow.syncBill(): error syncing', {
        error,
        paybill: true,
      });

      const vendorName = errorBillDetails?.vendorName || error?.responseData?.vendorName;
      const amount = errorBillDetails?.amount || error?.responseData?.totalAmount;

      historyNavigate({
        path: globalLocations.entrypoints.errors.billSyncError,
        state: {
          errorCode: error?.code,
          vendorName,
          amount,
          exitUrl,
        },
      });
    }
  };

  const redirectToSinglePayBillFlow = ({
    originId,
    redirectUrl,
    exitUrl,
    initialPaymentAmount,
    errorBillDetails,
    billZeroBalanceRedirectUrl,
  }: Params) => {
    if (isOrgBlockedByRisk(companyInfo)) {
      historyNavigate({ path: globalLocations.entrypoints.errors.orgBlockedByRisk });
    } else if (isBackgroundSyncPayBillEntry) {
      historyNavigate({
        path: globalLocations.bill.pay.schedule.backgroundSyncEntry,
        state: {
          originId,
          redirectUrl,
          exitUrl,
          initialPaymentAmount,
          billZeroBalanceRedirectUrl,
        },
      });
    } else {
      syncBill({
        originId,
        errorBillDetails,
        redirectUrl,
        exitUrl,
        initialPaymentAmount,
        billZeroBalanceRedirectUrl,
      });
    }
  };

  return { redirectToSinglePayBillFlow };
};
