import React from 'react';
import { RecordOf } from 'immutable';
import moment from 'moment';
import get from 'lodash/get';
import { PaymentFeeInfo } from 'src/app/redux/payBillWizard/types';
import { isManuallyPaid } from 'src/app/utils/bills';
import { getLatestPayment } from 'src/app/utils/payments';
import { CONSTS, FULL_STORY_MASK_RULE_CLASS } from 'src/app/utils/consts';
import { BillFrequencyEnum } from 'src/app/version-2/model/enums';
import {
  BillType,
  OptionalDeliveryMethodsType,
  NotePlaceholderType,
  CheckType,
} from 'src/app/utils/types';
import { isSharedVendor } from 'src/app/pages/vendor/utils';
import { MiMoneyFlavorEnum } from 'src/app/version-2/model/enums';
import styled, { css } from 'styled-components';
import QBRPaymentActivity, {
  QBRPaymentActivitySource,
} from '../../pages/bill/components/QBRPaymentActivity';
import { MIFormattedText, MIFieldOrEmpty, MIFormattedCurrency } from '../../utils/formatting';
import { devices } from '../../theme/AppDevices';
import MIMoney from '../common/MIMoney';

// TODO: remove after page/bills refactored to ts
type PaymentActivityInfoBlockType = {
  title: string;
  date: any;
  maxDate?: any;
  method: string;
  info: string;
  icon: React.ReactNode;
  dateIcon: string;
  secondDate?: any;
  secondDateIcon?: string;
  secondDateDescription?: string;
  description: string;
  additionalDescription?: string;
  additionalHint?: { label?: string };
  isPaymentFailed?: boolean;
  paymentFailedDate?: Date;
  isEditable?: boolean;
  isBillPaid?: boolean;
  onEdit?: () => void;
  onEditDate?: () => void;
  formattedCheckSerial?: string;
  type?: OptionalDeliveryMethodsType;
  paperCheck?: CheckType;
  hint?: string;
  isRecurringBill?: boolean;
  checkDepositedDate?: Date;
  deliveryDate?: Date;
};

type Props = {
  bill: RecordOf<BillType>;
  memo?: string;
  companyName: string;
  scheduledData?: PaymentActivityInfoBlockType;
  deliveryData?: PaymentActivityInfoBlockType;
  onEditNote: () => void;
  occurrences?: number;
  frequency?: BillFrequencyEnum;
  isRecurringBill?: boolean;
  dueDate?: Date;
  isSingleBill?: boolean;
  isPaymentFailed?: boolean;
  isPayeeMode?: boolean;
  fee?: PaymentFeeInfo | null;
  notePlaceholder?: NotePlaceholderType;
  dmVirtualAccountEmail?: string;
};
export class QBRBillPaymentReview extends React.PureComponent<Props> {
  static defaultProps = {
    scheduledData: null,
    dueDate: null,
    deliveryData: null,
    occurrences: null,
    frequency: null,
    isRecurringBill: false,
    isPaymentFailed: false,
    onEditNote: undefined,
    isSingleBill: false,
  };

  getFee = (fee: PaymentFeeInfo) => {
    const feeReduced = fee.finalAmount < fee.baseAmount;
    const showFeeDescription = fee.baseAmount > 0;

    return {
      feeText: <StyledMIMoney amount={fee.finalAmount} />,
      showFeeDescription,
      textValues: {
        showAmount: true,
        baseAmount: fee.baseAmount,
        basePercent: fee.feeStructure.value,
        finalAmount: fee.finalAmount,
        savingAmount: fee.promotion.reduction,
        expiration: fee.promotion.expiration && new Date(fee.promotion.expiration),
        promotionName: fee.promotion.promotionName,
      },
      baseAmount: fee.baseAmount,
      savingAmount: fee.promotion.reduction,
      finalAmount: fee.finalAmount,
      showFeeHint: feeReduced,
    };
  };

  renderFastFee(fastFee, cap) {
    const feeDetailsLabel =
      cap && Number(fastFee.totalAmount) === Number(cap) ? 'feeDetailsNoPercentage' : 'feeDetails';

    return (
      <FeeContainer>
        <SubTitle>
          <MIFormattedText label="bills.pay.confirm.fee" />
        </SubTitle>
        <FeeItem>
          <MIFormattedText
            label={`bills.pay.confirm.${fastFee.type}.${feeDetailsLabel}`}
            values={{ fee: fastFee.fee }}
          />
          <MIFormattedCurrency value={fastFee.totalAmount.toString() || ''} />
        </FeeItem>
        <TotalFeeContainer>
          <FeeItem>
            <MIFormattedText label="bills.pay.confirm.totalFee" />
            <MIFormattedCurrency value={fastFee.totalAmount.toString() || ''} />
          </FeeItem>
          <TotalFeeDescription>
            <MIFormattedText label="bills.pay.confirm.expedited-ach.totalFeeDescription" />
          </TotalFeeDescription>
        </TotalFeeContainer>
      </FeeContainer>
    );
  }

  renderFee() {
    const { fee } = this.props;
    const {
      feeText,
      showFeeDescription,
      showFeeHint,
      textValues,
      savingAmount,
      baseAmount,
      finalAmount,
    } = (fee && this.getFee(fee)) || {};

    if (fee && fee.fastFee) {
      return this.renderFastFee(fee.fastFee, get(fee, 'feeStructure.cap', 0));
    }

    if (savingAmount && savingAmount > 0) {
      return (
        <FeeContainer>
          <SubTitle>
            <MIFormattedText label="bills.pay.confirm.fee" />
          </SubTitle>
          <FeeItem>
            <MIFormattedText label="bills.pay.confirm.feePromoBase" values={textValues} />
            <StyledMIMoney size="small" amount={baseAmount as number} />
          </FeeItem>
          <FeeItem>
            <div>
              <MIFormattedText label="bills.pay.confirm.feePromoReduction" values={textValues} />
              <FeeDescription>
                <MIFormattedText
                  label="bills.pay.confirm.feePromoReductionHint"
                  values={textValues}
                />
              </FeeDescription>
            </div>
            <StyledMIMoney
              size="small"
              flavor={MiMoneyFlavorEnum.POSITIVE}
              amount={-savingAmount}
            />
          </FeeItem>
          <TotalFeeContainer>
            <FeeItem>
              <MIFormattedText label="bills.pay.confirm.totalFee" values={textValues} />
              <StyledMIMoney size="normal" amount={finalAmount as number} />
            </FeeItem>
          </TotalFeeContainer>
        </FeeContainer>
      );
    }

    return (
      <FeeContainer>
        <SubTitle>
          <MIFormattedText label="bills.pay.confirm.fee" />
        </SubTitle>
        <TotalFee data-testid="total-fee">
          {feeText}
          {showFeeHint && (
            <FeeHint>
              <MIFormattedText label="bills.pay.confirm.feeHint" values={textValues} />
            </FeeHint>
          )}
        </TotalFee>
        {showFeeDescription && (
          <FeeDescription>
            <MIFormattedText label="bills.pay.confirm.feeTerm" />
          </FeeDescription>
        )}
      </FeeContainer>
    );
  }

  getVirtualAccountEmail() {
    const { dmVirtualAccountEmail, bill } = this.props;

    return (
      dmVirtualAccountEmail ||
      bill.vendor?.deliveryMethods?.find((dm) => dm.deliveryType === CONSTS.DELIVERY_TYPE.VIRTUAL)
        ?.virtualAccount?.email
    );
  }

  render() {
    const {
      companyName,
      scheduledData,
      deliveryData,
      memo,
      onEditNote,
      isRecurringBill,
      frequency,
      occurrences,
      dueDate,
      isSingleBill,
      isPaymentFailed,
      bill,
      isPayeeMode,
      notePlaceholder,
    } = this.props;
    const isMarkedAsPayed = isManuallyPaid(bill.status, getLatestPayment(bill.payments)?.manual);
    const totalAmount = bill.totalAmount ? bill.totalAmount.toString() : '';
    let specificDay;
    let label = '';
    let invoiceNumberLabel = 'bills.pay.confirm.invoiceNumberField';

    if (isRecurringBill) {
      invoiceNumberLabel = 'bills.pay.confirm.billNumberField';

      if (frequency === BillFrequencyEnum.WEEKLY) {
        specificDay = dueDate ? moment(dueDate).format('dddd').toString() : '';
        label = 'bills.pay.confirm.recurringWeeklyHint';
      } else {
        specificDay = dueDate ? moment(dueDate).format('Do').toString() : '';
        label = 'bills.pay.confirm.recurringMonthlyHint';
      }
    }

    const isVendorShared = isSharedVendor(bill?.vendor);

    return (
      <MainBox className={isSingleBill ? '' : 'review'}>
        <BaseContainer>
          <HighlightedHeader>
            <SubTitle>
              <MIFormattedText label="bills.pay.confirm.total" />
            </SubTitle>
            <StyledMIMoney size="small" amount={totalAmount} />
          </HighlightedHeader>
        </BaseContainer>

        <SectionSeparator />
        <FromContainer>
          {scheduledData?.info && scheduledData?.method && (
            <QBRPaymentActivitySource
              method={scheduledData.method}
              info={scheduledData.info}
              icon={scheduledData.icon}
              isEditable={scheduledData.isEditable}
              hint={scheduledData.hint}
              onEdit={scheduledData.onEdit}
              formattedCheckSerial={scheduledData.formattedCheckSerial}
              title={scheduledData.title}
            />
          )}
        </FromContainer>
        <PaymentInfoContainer>
          <SubTitle>
            <MIFormattedText
              label={isPayeeMode ? 'bills.pay.confirm.paidBy' : 'bills.pay.confirm.payTo'}
            />
          </SubTitle>
          <BoxRow>
            <BoxColumn>
              <VendorFieldName>
                <MIFormattedText
                  label={
                    isPayeeMode ? 'bills.pay.confirm.businessName' : 'bills.pay.confirm.vendorField'
                  }
                />
              </VendorFieldName>
              <FieldValue data-testid="bill-company-name">
                <MIFormattedText
                  label="bills.pay.confirm.companyName"
                  values={{
                    companyName: deliveryData?.paperCheck?.printName || companyName,
                  }}
                />
              </FieldValue>
              {!isVendorShared && (
                <VendorDeliveryMethod>
                  {deliveryData?.type === CONSTS.DELIVERY_TYPE.VIRTUAL && (
                    <MIFormattedText
                      label="bills.pay.confirm.vendorEmail"
                      values={{
                        vendorEmail: this.getVirtualAccountEmail(),
                      }}
                    />
                  )}
                  {deliveryData?.type === CONSTS.DELIVERY_TYPE.ACH && (
                    <MIFormattedText
                      label="bills.pay.confirm.accountNumber"
                      values={{
                        accountNumber: deliveryData?.info.split('...')[1],
                      }}
                    />
                  )}
                  {deliveryData?.type === CONSTS.DELIVERY_TYPE.CHECK && (
                    <MIFormattedText
                      label="bills.pay.confirm.address"
                      values={{
                        address: (
                          <span className={FULL_STORY_MASK_RULE_CLASS}>{deliveryData?.info}</span>
                        ),
                      }}
                    />
                  )}
                </VendorDeliveryMethod>
              )}
            </BoxColumn>
            <InvoiceBoxColumn>
              <FieldName>
                <MIFormattedText label={invoiceNumberLabel} />
              </FieldName>
              <FieldValue data-testid="bill-invoice-number">
                <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.view.noInvoiceNumber" />
              </FieldValue>
            </InvoiceBoxColumn>
          </BoxRow>
          {isRecurringBill && (
            <BoxRow>
              <BoxColumn>
                <FieldName>
                  <MIFormattedText label="bills.pay.confirm.frequencyField" />
                </FieldName>
                <FieldValue data-testid="bill-frequency-field">
                  <MIFormattedText label={label} values={{ specificDay, occurrences }} />
                </FieldValue>
              </BoxColumn>
              <BoxColumn>
                <FieldName>
                  <MIFormattedText label="bills.pay.confirm.occurrencesField" />
                </FieldName>
                <FieldValue data-testid="occurrences">{occurrences}</FieldValue>
              </BoxColumn>
            </BoxRow>
          )}
        </PaymentInfoContainer>
        <SectionSeparator />
        {!isMarkedAsPayed && (
          <PaymentActivityContainer isPayeeMode={isPayeeMode}>
            <QBRPaymentActivity
              bill={bill}
              scheduledData={scheduledData}
              deliveryData={deliveryData}
              memo={memo || ''}
              onEditNote={onEditNote}
              isRecurringBill={isRecurringBill}
              isPayeeMode={isPayeeMode}
              notePlaceholder={notePlaceholder}
              isSharedVendor={isVendorShared}
            />
          </PaymentActivityContainer>
        )}
        {!isPaymentFailed && !isPayeeMode && this.props.fee && this.renderFee()}
        <BlockSeparator />
      </MainBox>
    );
  }
}

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.14);

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  margin: 0 -1.6rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.MainBox}
`;

const StyledMIMoney = styled(MIMoney)`
  ${(props) => props.theme.text.fontType.regular};
  font-weight: ${(props) => props.theme.text.weight.medium};
`;

const HighlightedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.HighlightedHeader}
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 1.6rem;
  align-self: center;
  overflow-x: hidden;
`;

const PaymentActivityContainer = styled(BaseContainer)<{
  isPayeeMode?: boolean;
}>`
  padding: 0 1.6rem 2rem;
  ${(props) => props.isPayeeMode && 'border-bottom: 0'};
`;

const PaymentInfoContainer = styled(BaseContainer)``;

const FeeContainer = styled(BaseContainer)`
  border-bottom: 0;
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeContainer}
`;

const FromContainer = styled(BaseContainer)`
  padding: 0 1.6rem;
`;

const BoxRow = styled.div`
  display: flex;
  margin-top: 1.8rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  padding-right: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxColumn}
`;

const InvoiceBoxColumn = styled(BoxColumn)`
  ${(props) => props.theme?.components?.BillPaymentReview?.InvoiceBoxColumn}
`;

const TotalFee = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.text.size.subTitle};
  height: 3rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.TotalFee}
`;
const FeeHint = styled.span`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.label};
  align-self: center;
  margin-left: 1rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeHint}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}

  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldName}
`;

const VendorFieldName = styled(FieldName)`
  ${(props) => props.theme?.components?.BillPaymentReview?.VendorFieldName}
`;

const FeeDescription = styled.div`
  ${baseTextStyles}
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeDescription}
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.medium};
  ${(props) => props.theme.text.fontType.regular};
`;

const SubTitle = styled.div`
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.darkGrey};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-bottom: 0.4rem;
`;

const SectionSeparator = styled.div`
  display: block;
  height: 0.05rem;
  width: calc(100% - 3.2rem);
  align-self: center;
  background-color: ${(props) => props.theme.colors.border.light};
`;

const BlockSeparator = styled.div`
  height: 0.4rem;
  background: ${(props) => props.theme.colors.white.lightBlue};
  width: 100%;
`;

const FeeItem = styled.div<{ total?: number }>`
  padding-right: 2rem;
  margin: 2rem 0;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) =>
    props.total ? props.theme.text.size.subTitle : props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.regular};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TotalFeeContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${FeeItem} {
    margin-bottom: 0.5rem;
  }
`;

const TotalFeeDescription = styled.div`
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.hint};
  ${(props) => props.theme?.components?.BillPaymentReview?.TotalFeeDescription}
`;

const VendorDeliveryMethod = styled.div`
  ${(props) => props.theme.text.fontType.medium};
  margin-top: 0.4rem;
  color: ${(props) => props.theme.text.color.darkGrey};
`;
