import styled from 'styled-components';
import { devices } from 'src/app/theme/AppDevices';

export const CreditCardBenefitsModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  @media ${devices.mobile} {
    justify-content: center;
  }
`;

export const CreditCardBenefitIconWrapper = styled.div<{ isBottomBanner?: boolean }>`
  width: ${(props) => (props.isBottomBanner ? '7.6rem' : '6rem')};
  height: ${(props) => (props.isBottomBanner ? '7.6rem' : '6rem')};
`;

export const CreditCardBenefitTextWrapper = styled.div<{ isBottomBanner?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isBottomBanner ? '0.2rem' : '0.8rem')};
  ${(props) =>
    props.isBottomBanner
      ? ''
      : `
      @media ${devices.mobile} {
        text-align: center;
      }`}
`;

export const CreditCardBenefitTileStyled = styled.div<{ isBottomBanner?: boolean }>`
  width: ${(props) => (props.isBottomBanner ? 'calc(100% - 2.2rem)' : '45rem')};
  height: 10.4rem;
  padding: ${(props) => (props.isBottomBanner ? '0 1rem' : '0 1.6rem')};
  gap: ${(props) => (props.isBottomBanner ? '1rem' : '1.6rem')};
  background: ${(props) => props.theme.colors.background};
  display: flex;
  align-items: center;
  border-radius: ${(props) => (props.isBottomBanner ? '0 0 0.8rem 0.8rem' : '0.8rem')};
  border: 0.1rem solid ${(props) => props.theme.colors.background};
  ${(props) =>
    props.isBottomBanner
      ? ''
      : `
      @media ${devices.mobile} {
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 9.6rem);
        height: 16.6rem;
        padding: 1.6rem 4.8rem 0;
        gap: 0rem;
      }
  `}
`;

export const CreditCardBenefitList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const CreditCardBenefitsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
