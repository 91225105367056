import styled from 'styled-components';

export const FieldErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.ds.red[100]};
  margin-top: 1rem;
  gap: 0.8rem;
  ${({ theme }) => theme.textStyles.ds.body3}
`;
