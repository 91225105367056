import { defaultDecoder, parse } from 'qs';

const LEADING_QUESTION_MARK_REGEX = /[^?](\S)*/g;

export const parseQueryString = (locationSearch: string) => {
  const [queryString] = locationSearch.match(LEADING_QUESTION_MARK_REGEX) || [];

  return parse(queryString, {
    decoder: (str: string, decoder: defaultDecoder, charset: string) => {
      if (charset === 'iso-8859-1') {
        return str.replace(/%[0-9a-f]{2}/gi, decodeURI);
      }

      // utf-8
      try {
        return decodeURIComponent(str);
      } catch (e) {
        return str;
      }
    },
  });
};

export const getPathParams = (path: string): Record<string, string> => {
  const params: Record<string, string> = {};

  path.match(/[a-zA-Z-]+\/\d+/g)?.forEach((matching) => {
    const values = matching.split('/');

    params[values[0]] = values[1];
  });

  return params;
};
