import React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/app/utils/formatting';
import { devices } from 'src/app/theme/AppDevices';
import { CONSTS, BUTTON_SIZE } from 'src/app/utils/consts';
import MIButton from '../common/MIButton';

type Props = {
  title?: string;
  options?: Record<string, any>[];
  ctaZeroState?: string;
  zeroStateAction?: () => void;
  border?: string;
  padding?: string;
  black?: boolean;
};

const ZeroState = ({
  title,
  options,
  ctaZeroState,
  zeroStateAction,
  border,
  padding,
  black,
}: Props) => (
  <ZeroStateContainer border={border} padding={padding}>
    {title && (
      <ZeroStateTitle>
        <MIFormattedText label={title} />
      </ZeroStateTitle>
    )}

    {options &&
      options.map((step, index) => (
        <React.Fragment key={index}>
          <ZeroStateStepWrapper>
            <ZeroStateStepNumber black={black}>{index + 1}</ZeroStateStepNumber>
            <ZeroStateStepContent>
              <ZeroStateStepTitle black={black}>
                <MIFormattedText label={step.title} />
              </ZeroStateStepTitle>
              <ZeroStateStepDescription black={black}>
                <MIFormattedText label={step.description} />
              </ZeroStateStepDescription>
            </ZeroStateStepContent>
          </ZeroStateStepWrapper>
        </React.Fragment>
      ))}

    {ctaZeroState && zeroStateAction && (
      <MIButton
        variant={CONSTS.BUTTON_VARIANT.EMPTY}
        onClick={zeroStateAction}
        label={ctaZeroState}
        size={BUTTON_SIZE.SMALL}
        testId={`button-${ctaZeroState}`}
      />
    )}
  </ZeroStateContainer>
);

export default ZeroState;

const ZeroStateContainer = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '2.4rem 2rem')};
  border-radius: 0.8rem;
  box-sizing: border-box;
  border: ${(props) =>
    props.border ? props.border : `0.1rem solid ${props.theme.colors.border.darkGrey}`};
  display: block;
  margin-top: 1.5rem;
  width: 100%;

  @media ${devices.mobile} {
    margin-bottom: 10rem;
  }

  @media ${devices.nonMobile} {
    margin-bottom: 4.5rem;
  }
`;

const ZeroStateTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.subtitle};
`;

const zeroStateStepText = css`
  font-size: ${(props) => props.theme.text.size.sectionTitleM};
  line-height: 1.8rem;
`;

const ZeroStateStepNumber = styled.div`
  box-sizing: border-box;
  height: 2.6rem;
  min-width: 2.6rem;
  color: ${(props) =>
    props.black ? props.theme.text.color.main : props.theme.text.color.subtitle};
  border: 0.2rem solid ${(props) => props.theme.text.color.subtitle};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const ZeroStateStepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const ZeroStateStepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.8rem 0;
`;

const ZeroStateStepTitle = styled.div`
  ${zeroStateStepText};
  color: ${(props) =>
    props.black ? props.theme.text.color.main : props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const ZeroStateStepDescription = styled.div`
  ${zeroStateStepText};
  color: ${(props) =>
    props.black ? props.theme.text.color.main : props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.regular};
  width: 100%;
`;
