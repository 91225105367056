import { createRestfulSlice } from 'src/app/helpers/redux/createRestfulSlice';
import intuitBankAccountsApi from 'src/app/services/api/intuit-bank-accounts';
import { IntuitBankAccountType } from 'src/app/utils/types';
import { name } from './consts';

const intuitBankAccountsStore = createRestfulSlice<IntuitBankAccountType>({
  name,
  api: intuitBankAccountsApi,
  selectors: {
    byId: (id) => (state) => state[name]?.byId[id],
  },
});

export default intuitBankAccountsStore;
