import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import QBOLayoutPage from 'src/app/components/layout/QBOLayoutPage';
import analytics from 'src/app/services/analytics';
import { getBill, getPayment } from 'src/app/redux/payBillWizard/selectors';
import { selectNewDeliveryMethodAction } from 'src/app/redux/payBillWizard/actions';
import { getOrgId, getOwnedVendorId, getFundingSources } from 'src/app/redux/user/selectors';
import { withNavigator } from 'src/app/hoc';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS, DELIVERY_TYPE } from 'src/app/utils/consts';
import deliveryMethodsStore from 'src/app/modules/delivery-methods/delivery-methods-store';
import { useStoreActions } from 'src/app/helpers/redux/createRestfulSlice';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useForm } from 'src/app/ui/form';
import { RecordOf } from 'immutable';
import { BankType } from 'src/app/utils/types';
import { BankRecord, getDeliveryMethodByType } from 'src/app/pages/vendor/records';
import { getPartialBillId } from 'src/app/utils/bills';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { checkBankAccount } from 'src/app/pages/vendor/delivery-methods/utils';
import { getConfirmURL } from 'src/app/pages/bill/pay/RecoveryFlowSteps/utils';
import { BANK_ACCOUNT_CHECKING_TYPE } from 'src/app/version-2/model/constants';
import { RESOURCE_BASE } from '../config';
import { BankMissingDetails } from './bankMissingDetails';
import { withPayBillData, PayBillProps } from '../../hoc/withPayBillData';
import { RecoveryFlowType } from '../../consts';

export const getInitialFormState = (bankAccount) => ({
  routingNumber: bankAccount?.routingNumber || '',
  accountNumber: bankAccount?.accountNumber || '',
});

const StepMissingACHDetails = ({
  onPrev,
  goExit,
  flowType,
}: PayBillProps & { flowType: RecoveryFlowType }) => {
  const orgId = useSelector(getOrgId);
  const bill = useSelector(getBill);
  const { companyName = '', id: vendorId = '' } = bill.vendor ?? {};
  const ownedVendorId = useSelector(getOwnedVendorId);
  const payment = useSelector(getPayment);
  const userFundingSources = useSelector(getFundingSources);
  const achDM = getDeliveryMethodByType(DELIVERY_TYPE.ACH, bill.vendor?.deliveryMethods || []);
  const [bank, setBank] = useState<RecordOf<BankType>>(BankRecord());
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const model = useMemo(() => getInitialFormState(achDM?.bankAccount), [achDM?.bankAccount]);
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();
  const isVoidCheck = flowType === RecoveryFlowType.VOID_CHECK;

  useEffect(() => {
    if (isVoidCheck) {
      analytics.page('vendors', 'add-bank-delivery-method', {
        billId: bill?.id,
        paymentId: payment?.id,
        partialBillId: getPartialBillId(bill),
      });
    }
  }, []);

  const createDeliveryMethod = async (deliveryMethod) => {
    const { payload } = await deliveryMethodActions.create({
      orgId,
      vendorId,
      ...deliveryMethod,
    });

    await dispatch(selectNewDeliveryMethodAction(payload));
  };

  const updateDeliveryMethod = async (deliveryMethod) => {
    const { payload } = await deliveryMethodActions.update({
      orgId,
      vendorId,
      id: achDM?.id,
      deliveryMethod,
    });

    await dispatch(selectNewDeliveryMethodAction(payload));
  };

  const getValidationsError = () => {
    let validationErrors = getValidationErrors('deliveryMethodAch', bank, [
      'routingNumber',
      'accountNumber',
    ]);

    if (isValidationOk(validationErrors) && Number(vendorId) !== Number(ownedVendorId)) {
      validationErrors = checkBankAccount(bank, userFundingSources);
    }

    return validationErrors;
  };

  const submitAnalytics = ({ success }) => {
    if (isVoidCheck) {
      const eventName = success ? 'add-delivery-method-success' : 'add-delivery-method';

      analytics.track('vendor-delivery-method', eventName, {
        billId: bill?.id,
        paymentId: payment?.id,
        partialBillId: getPartialBillId(bill),
      });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const validationErrors = getValidationsError();

    submitAnalytics({ success: false });
    setValidationErrors(validationErrors);

    const newDeliveryMethod = {
      deliveryType: CONSTS.DELIVERY_TYPE.ACH,
      bankAccount: bank,
      isFilledByVendor: false,
    };

    if (isValidationOk(validationErrors)) {
      try {
        if (achDM?.id) {
          await updateDeliveryMethod(newDeliveryMethod);
        } else {
          await createDeliveryMethod(newDeliveryMethod);
        }

        submitAnalytics({ success: true });

        const confirmUrl = getConfirmURL(flowType);

        historyPush({
          path: confirmUrl.url({
            id: bill?.id,
            paymentId: payment?.id,
          }),
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const [values, { submit }] = useForm(model, { submit: onSubmit });

  const onChange = ({ id, value }) => {
    values[id].onChange({ value });
    setBank((prevBank) => ({
      ...prevBank,
      accountType: BANK_ACCOUNT_CHECKING_TYPE,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (achDM?.bankAccount) setBank(BankRecord(achDM.bankAccount));
  }, [achDM?.bankAccount]);

  return (
    <QBOLayoutPage
      title={`${RESOURCE_BASE[flowType]}.stepMissingACHDetails.title`}
      titleValues={{ vendorName: companyName }}
      goExit={goExit}
      onPrev={onPrev}
      isHideActions
    >
      <FormWrapper onSubmit={submit}>
        <BankMissingDetails
          values={values}
          onChange={onChange}
          validationErrors={validationErrors}
          flowType={flowType}
        />
        <MIButton
          type="submit"
          disabled={false}
          isProcessing={isLoading}
          className="button"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label={`${RESOURCE_BASE[flowType]}.stepMissingACHDetails.buttonText`}
        />
      </FormWrapper>
    </QBOLayoutPage>
  );
};

const StepMissingACHDetailsHocs = compose(
  withNavigator(),
  withPayBillData()
)(StepMissingACHDetails);

export { StepMissingACHDetailsHocs as StepMissingACHDetails };

const FormWrapper = styled.form`
  .button {
    display: flex;
    align-items: center;
    margin: 4.5rem auto 0;
  }
`;
