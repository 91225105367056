import React from 'react';
import styled, { css } from 'styled-components';
import { getNameInitials } from 'src/app/utils/string-utils';
import { BadgeSizeType } from 'src/app/utils/types';
import { BADGE_SIZE } from 'src/app/utils/consts';

type Props = {
  size: BadgeSizeType;
  name?: string | null;
  link?: string | null;
  selected?: boolean | null;
  disabled?: boolean | null;
  color?: string;
  textColor?: string;
  alt?: string;
};

const MIInitialsBadge = ({ name, link, alt = 'Company logo', ...props }: Props) =>
  link ? (
    <Logo src={link} alt={alt} {...props} />
  ) : (
    <InitialsWrapper {...props}>
      {getNameInitials(name, props.size === BADGE_SIZE.X_SMALL ? 1 : 2)}
    </InitialsWrapper>
  );

export default MIInitialsBadge;

type PlaceholderProps = Pick<Props, 'size'>;
export const MIInitialsBadgePlaceholder = (props: PlaceholderProps) => (
  <StyledPlaceholder {...props} />
);

const sizesMap = {
  [BADGE_SIZE.X_LARGE]: {
    size: '12rem',
    fontSize: '3.4rem',
  },
  [BADGE_SIZE.LARGE]: {
    size: '4.8rem',
    fontSize: '1.4rem',
  },
  [BADGE_SIZE.MEDIUM]: {
    size: '4.2rem',
    fontSize: '1.4rem',
  },
  [BADGE_SIZE.SMALL]: {
    size: '2.4rem',
    fontSize: '1rem',
  },
  [BADGE_SIZE.X_SMALL]: {
    size: '1.6rem',
    fontSize: '0.8rem',
  },
};

const getBackgroundColor = (color, disabled, colors) => {
  if (color) return color;

  if (disabled) return colors.readonly;

  return colors.label;
};

const getTextColor = (color, main, theme) => {
  if (color) return color;

  if (main) return theme.text.color.main;

  return theme.colors.white.opaque;
};

const circleStyles = css<{
  size: BadgeSizeType;
  selected?: boolean | null;
  color?: string;
  disabled?: boolean | null;
  textColor?: string;
}>`
  height: ${(props) => sizesMap[props.size].size};
  width: ${(props) =>
    props.selected ? `calc(${sizesMap[props.size].size} - 0.2rem)` : sizesMap[props.size].size};
  min-width: ${(props) =>
    props.selected ? `calc(${sizesMap[props.size].size} - 0.2rem)` : sizesMap[props.size].size};
  line-height: ${(props) => sizesMap[props.size].size};
  border-radius: 50%;
  border: ${(props) => (props.selected ? `2px solid ${props.theme.colors.success.opaque}` : '')};
  background-color: ${(props) =>
    getBackgroundColor(props.color, props.disabled, props.theme.text.color)};
  color: ${(props) => getTextColor(props.textColor, props.color, props.theme)};
`;

const textStyles = css<{ size: BadgeSizeType }>`
  font-size: ${(props) => sizesMap[props.size].fontSize};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const InitialsWrapper = styled.div<{
  size: BadgeSizeType;
  selected?: boolean | null;
  color?: string;
  disabled?: boolean | null;
}>`
  ${circleStyles}
  ${textStyles}
`;

const Logo = styled.img<{
  size: BadgeSizeType;
  selected?: boolean | null;
  disabled?: boolean | null;
}>`
  ${circleStyles};
  ${(props) =>
    props.disabled &&
    css`
      filter: grayscale(100%);
    `};
`;

const StyledPlaceholder = styled.div<{ size: BadgeSizeType }>`
  ${circleStyles}
  ${textStyles}
  :before {
    content: ${(props) => (props.size === BADGE_SIZE.X_SMALL ? "'C'" : "'CF'")};
  }
`;
