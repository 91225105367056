import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from '@melio/billpay-design-system';
import { CONSTS, BUTTON_SIZE } from 'src/app/utils/consts';
import { MIFormattedText } from 'src/app/utils/formatting';
import MIButton from 'src/app/components/common/MIButton';
import locations from 'src/app/pages/vendor/locations';
import iconGlobe from 'src/app/images/qbo/join-service/globe.svg';
import iconSecurity from 'src/app/images/qbo/join-service/security.svg';
import iconClock from 'src/app/images/qbo/join-service/time-fly.svg';
import { QBOUpsellBenefit } from './QBOUpsellBenefit';

enum BUSINESS_DIRECTORY_UPSELL_ORIGIN {
  UNILATERAL = 'unilateral',
  PUSH_TO_DEBIT = 'push-to-debit',
  ACH = 'check-to-ach',
}

type UpsellBenefit = {
  title: string;
  subtitle: string;
  image: string;
};

type Props = {
  flow: BUSINESS_DIRECTORY_UPSELL_ORIGIN;
};

const BENEFITS: UpsellBenefit[] = [
  {
    title: 'vendors.joinServiceUpsell.benifits.delivery.title',
    subtitle: 'vendors.joinServiceUpsell.benifits.delivery.subtitle',
    image: iconClock,
  },
  {
    title: 'vendors.joinServiceUpsell.benifits.tracking.title',
    subtitle: 'vendors.joinServiceUpsell.benifits.tracking.subtitle',
    image: iconGlobe,
  },
  {
    title: 'vendors.joinServiceUpsell.benifits.security.title',
    subtitle: 'vendors.joinServiceUpsell.benifits.security.subtitle',
    image: iconSecurity,
  },
];

const QBOBusinessDirectoryUpsell = ({ flow }: Props) => {
  const history = useHistory();

  const handleOnClick = () =>
    history.push(locations.qbDirectory.verified, {
      flow,
    });

  return (
    <Flex direction="column" textAlign="center" color="black">
      <Box mb={['1.6rem', '1.6rem', '2.6rem']} textStyle="h5" fontWeight="600">
        <MIFormattedText label="vendors.joinServiceUpsell.title" />
      </Box>
      <Box mb="2.6rem" textStyle="body3" fontWeight="400">
        <MIFormattedText label="vendors.joinServiceUpsell.subtitle" />
      </Box>
      <Flex
        direction={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        {BENEFITS.map(({ title, subtitle, image }) => (
          <QBOUpsellBenefit key={title} title={title} subtitle={subtitle} image={image} />
        ))}
      </Flex>
      <Box w={['100%', '100%', '30rem']} alignSelf="center">
        <MIButton
          label="vendors.joinServiceUpsell.cta"
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          fullWidth
          size={BUTTON_SIZE.VERY_SMALL}
          onClick={handleOnClick}
          analyticsProperties={{ flow }}
        />
      </Box>
    </Flex>
  );
};

export { QBOBusinessDirectoryUpsell, BUSINESS_DIRECTORY_UPSELL_ORIGIN };
