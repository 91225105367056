import { ExtendedSelectOption, SelectOption, VendorType } from 'src/app/utils/types';
import {
  DASHBOARD_FILTER_ITEMS_NAMES,
  PAID_BILL_DASHBOARD_STATUSES,
  QB_DASHBOARD_TAB_STATUS,
  SCHEDULED_BILL_DASHBOARD_STASUSES,
  UNPAID_BILL_DASHBOARD_STASUSES,
  UNPAID_DUE_DATE_OPTIONS,
} from '../../consts';
import { DashboardFilterMenuItems, DashboardListFiltersParams } from '../../types';

const { NEXT_SEVEN_DAYS, THIS_MONTH, NEXT_MONTH, PAST_DUE } = UNPAID_DUE_DATE_OPTIONS;
const { DUE_IN_ONE_WEEK, OVERDUE, PARTIALLY_PAID, DECLINED, FAILED, REFUNDED } =
  UNPAID_BILL_DASHBOARD_STASUSES;
const { SCHEDULED, BANK_UNVERIFIED, PENDING_VENDOR_DETAILS, PENDING } =
  SCHEDULED_BILL_DASHBOARD_STASUSES;
const { SENT, DEPOSITED, MARKED_AS_PAID } = PAID_BILL_DASHBOARD_STATUSES;

type DefaultOptionValues = Omit<SelectOption, 'label'>;

const staticOptionsByTab = {
  [QB_DASHBOARD_TAB_STATUS.UNPAID]: {
    [DASHBOARD_FILTER_ITEMS_NAMES.STATUSES_IN_DASHBOARD]: [
      DUE_IN_ONE_WEEK,
      OVERDUE,
      PARTIALLY_PAID,
      DECLINED,
      FAILED,
      REFUNDED,
    ],
    [DASHBOARD_FILTER_ITEMS_NAMES.DUE_DATES]: [NEXT_SEVEN_DAYS, THIS_MONTH, NEXT_MONTH, PAST_DUE],
  },
  [QB_DASHBOARD_TAB_STATUS.SCHEDULED]: {
    [DASHBOARD_FILTER_ITEMS_NAMES.STATUSES_IN_DASHBOARD]: [
      SCHEDULED,
      BANK_UNVERIFIED,
      PENDING_VENDOR_DETAILS,
      PENDING,
    ],
  },
  [QB_DASHBOARD_TAB_STATUS.PAID]: {
    [DASHBOARD_FILTER_ITEMS_NAMES.STATUSES_IN_DASHBOARD]: [SENT, DEPOSITED, MARKED_AS_PAID],
  },
};

const getOptionValues = ({
  value = false,
  selected = false,
  disabled = false,
}): DefaultOptionValues => ({
  value,
  selected,
  disabled,
});

const getDashboardFilters = (
  tabName: string,
  selectedFilters: DashboardListFiltersParams,
  vendors: VendorType[],
  shouldDisplayRefund: boolean
): DashboardFilterMenuItems => {
  const labelRootPath = 'paymentDashboard.search.filters.menuItems';
  const tabStaticOptions = staticOptionsByTab[tabName];

  // TODO: remove when isQboRefundPaymentFF will be open to 100%
  if (!shouldDisplayRefund) {
    const withoutRefund = tabStaticOptions.statusesInDashboard.filter((item) => item !== REFUNDED);

    tabStaticOptions.statusesInDashboard = withoutRefund;
  }

  const staticTabFilters = Object.keys(tabStaticOptions).reduce((filters, currentFilterName) => {
    const currentFilter = {
      label: `${labelRootPath}.${currentFilterName}.label`,
      options: tabStaticOptions[currentFilterName].map((optionName: string) => {
        const existingValue = selectedFilters[currentFilterName]
          ?.split(',')
          .some((filterName) => optionName === filterName);

        return {
          label: `${labelRootPath}.${currentFilterName}.options.${optionName}`,
          ...getOptionValues({ value: existingValue, selected: existingValue }),
        };
      }),
    };

    return {
      ...filters,
      [currentFilterName]: currentFilter,
    };
  }, {});

  const vendorsRootLabel = `${labelRootPath}.${DASHBOARD_FILTER_ITEMS_NAMES.VENDORS}`;
  const selectedVendorsById = selectedFilters.vendors
    ?.split(',')
    .reduce((prev, curr) => ({ ...prev, [curr]: curr }), {});
  const vendorOptions: ExtendedSelectOption[] = vendors
    .map(({ id, companyName }) => ({
      ...getOptionValues({ value: selectedVendorsById && !!selectedVendorsById[id] }),
      label: `${vendorsRootLabel}.options.vendorName`,
      values: { vendorName: companyName },
      id,
    }))
    .sort((a, b) => a.values.vendorName.localeCompare(b.values.vendorName));

  return {
    ...staticTabFilters,
    vendors: { label: `${vendorsRootLabel}.label`, options: vendorOptions },
  };
};

export { getDashboardFilters };
