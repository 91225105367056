import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import { getSelectedFundingSource } from 'src/app/redux/payBillWizard/selectors';
import { withNavigator } from 'src/app/hoc';
import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';
import { AreaLoader } from '@melio/billpay-design-system';
import useJustPayStore from 'src/app/pages/just-pay/hooks/useJustPayStore';
import { WizardTextInputField } from 'src/app/ui/form/WizardTextInputField';
import { FundingSourceTypesEnum } from 'src/app/version-2/model/enums';

import useVirtualDeliveryMethodState from './hooks/useVirtualDeliveryMethodState';
import { ModelViewField } from 'src/app/ui/form';
import { FundingSource } from 'src/app/version-2/model/dtos';

type Props = {
  locationState: Record<string, any>;
  navigate: (arg0: string) => void;
  vendorId: string;
};

const QBMVirtualDeliveryMethodPageContainer = ({ navigate, vendorId }: Props) => {
  const singleFundingSource = useSelector(getSelectedFundingSource);
  const { fundingSource: justPayFundingSource } = useJustPayStore();

  const fundingSource = singleFundingSource || (justPayFundingSource as FundingSource);

  const site = useSiteContext();
  const { virtualDeliveryMethodVM, submit, vendor, isFormProcessing, isVendorLoading, goExit } =
    useVirtualDeliveryMethodState({
      vendorId,
      navigate,
      shouldIgnorePhone: true,
      navigateWithPreservedState: () => null, // TODO - Denis - please fill it in if needed, or remove this todo
    });

  if (isVendorLoading) {
    return <AreaLoader />;
  }

  let subtitle = 'vendors.deliveryMethods.virtual.subtitle';
  const title = 'vendors.deliveryMethods.virtual.title';
  const email = 'vendors.deliveryMethods.virtual.email';
  const done = 'vendors.deliveryMethods.virtual.done';

  if (fundingSource?.fundingType === FundingSourceTypesEnum.ACH) {
    subtitle = 'vendors.deliveryMethods.virtual.checkFee.subtitle';
  }

  return (
    <site.components.StepLayout
      headerLabel="vendors.deliveryMethods.virtual.header"
      title={title}
      titleValues={{ vendor: vendor?.companyName }}
      subtitle={subtitle}
      subTitleValues={{ vendor: vendor?.companyName }}
      goExit={goExit}
      onNext={submit}
      nextLabel={done}
      isLoading={isFormProcessing}
      innerSize={50}
      footer={
        <site.components.WizardElements.SimpleTextFooter>
          <MIFormattedText
            label="vendors.deliveryMethods.virtual.footer"
            values={{ vendor: vendor?.companyName }}
          />
        </site.components.WizardElements.SimpleTextFooter>
      }
    >
      <FormContainer>
        <WizardTextInputField
          label={email}
          model={virtualDeliveryMethodVM.virtualAccount.email as ModelViewField<string>}
          required
          type="email"
          autoFocus
          autocomplete="username email"
          privateData
        />
      </FormContainer>
    </site.components.StepLayout>
  );
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 4rem;
  }
`;

export default compose(withNavigator())(QBMVirtualDeliveryMethodPageContainer);
