import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { RecordOf } from 'immutable';
import { isValidationOk } from '@melio/sizzers-js-common';
import {
  UserType,
  ScreenMode,
  UserPreferencesType,
  FieldType,
  UserContextType,
} from 'src/app/utils/types';
import { GlobalState } from 'src/app/redux/types';
import analytics from 'src/app/services/analytics';
import authApi from 'src/app/services/api/auth';
import clientServiceApi from 'src/app/services/api/clientService';
import { getProfile, getUserPreferences } from 'src/app/redux/user/selectors';
import { updateUserPreferenceAction } from 'src/app/redux/user/actions';
import { logOut } from 'src/app/utils/external-events';
import { ScreenModeEnum } from 'src/app/version-2/model/enums';
import { UserRecord } from './records';

type MapStateToProps = {
  userPreferences: RecordOf<UserPreferencesType>;
  profile: RecordOf<UserContextType>;
};

type MapDispatchToProps = {
  updateUserPreference: (id: string, value: any) => Promise<void>;
};

type Props = { SettingsPage: React.ComponentType<any> } & MapStateToProps & MapDispatchToProps;

type State = {
  isLoading: boolean;
  userDetails: RecordOf<UserType>;
  validationErrors: Record<string, any>;
  isPasswordFieldFocused: boolean;
  currentPassword: string;
  newPassword: string;
  confirmedPassword: string;
  mode: ScreenMode;
};

const eventPage = 'settings-account-information';

class AccountSettingsPageContainer extends React.PureComponent<Props, State> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      isPasswordFieldFocused: false,
      userDetails: UserRecord(),
      validationErrors: {},
      currentPassword: '',
      newPassword: '',
      confirmedPassword: '',
      mode: ScreenModeEnum.VIEW,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.onLoadUserDetails();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onUpdatePassword = () => {
    const { currentPassword, newPassword, confirmedPassword } = this.state;

    this.setState({ isLoading: true });
    const event = 'reset';

    analytics.track(event, 'password-continue');
    clientServiceApi
      .getValidationErrors('userRegistration', {
        password: newPassword,
        confirmedPassword,
        currentPassword,
      })
      .then(({ validationErrors }) => {
        if (isValidationOk(validationErrors)) {
          this.setState({ validationErrors, isLoading: true }, () => {
            authApi
              .changePassword(currentPassword, newPassword)
              .then(() => {
                this.setState({
                  isLoading: false,
                  currentPassword: '',
                  newPassword: '',
                  confirmedPassword: '',
                });
                this.setState({ mode: ScreenModeEnum.VIEW });
              })
              .catch((e) => {
                const serverValidationErrors = {
                  currentPassword: `inputErrors.userRegistration.password.server.${e.code}`,
                };

                this.setState({
                  isLoading: false,
                  validationErrors: serverValidationErrors,
                });
                analytics.track(event, 'password-validation-error', serverValidationErrors);
              });
          });
        } else {
          analytics.track(event, 'password-validation-error', validationErrors);
          this.setState({ validationErrors, isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onSendEmailNotificationsChanged = (id: string, sendEmailNotifications: boolean) => {
    this.setState((prevState) => ({
      ...prevState,
      [id]: sendEmailNotifications,
    }));
    const { updateUserPreference } = this.props;

    analytics.track(eventPage, `update-${id}`, {
      isOn: sendEmailNotifications,
    });

    updateUserPreference(id, sendEmailNotifications)
      .then(() => {
        analytics.track(eventPage, `update-${id}-success`);
      })
      .catch(() => {
        analytics.track(eventPage, `update-${id}-fail`);
      });
  };

  onLoadUserDetails = () => {
    this.setState({ isLoading: true, validationErrors: {} });
    authApi
      .getUserDetails()
      .then(({ user }) => {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
            userDetails: UserRecord(user),
          });
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isLoading: false });
        }
      });
  };

  onChange = ({ id, value }: FieldType) => {
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  onIsPasswordFieldFocusedChanged = (isPasswordFieldFocused: boolean) => {
    this.setState({ isPasswordFieldFocused });
  };

  setScreenModeState = (mode: ScreenMode) => {
    analytics.track(eventPage, `go-to-${mode}-mode`);
    this.setState({ mode });
  };

  onLogoutAndUnlink = () => {
    const { profile } = this.props;

    analytics.track(eventPage, 'logout');
    authApi
      .unlinkQuickbooksAccount(profile.orgId)
      .then(authApi.logout)
      .then(() => {
        logOut();
      });
  };

  render() {
    const { SettingsPage } = this.props;

    return (
      <SettingsPage
        {...this.state}
        setScreenModeState={this.setScreenModeState}
        onUpdatePassword={this.onUpdatePassword}
        onSendEmailNotificationsChanged={this.onSendEmailNotificationsChanged}
        onChange={this.onChange}
        onIsPasswordFieldFocusedChanged={this.onIsPasswordFieldFocusedChanged}
        onLogoutAndUnlink={this.onLogoutAndUnlink}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  userPreferences: getUserPreferences(state),
  profile: getProfile(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  updateUserPreference(id: string, value: any) {
    return new Promise((resolve, reject) => {
      dispatch(updateUserPreferenceAction(id, value, resolve, reject));
    });
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(AccountSettingsPageContainer);
