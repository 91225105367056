import { Flex, Text } from '@melio/billpay-design-system';

import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  align-content: space-between;
  background: ${({ theme }) => theme.colors.ds.gray[800]};
  overflow-x: hidden;
  padding-bottom: 6rem;
`;

export const Title = styled(Flex)`
  color: ${({ theme }) => theme.colors.ds.gray[100]};
  ${({ theme }) => theme.textStyles.ds.h6.fontSize};
  gap: 0.5rem;
`;

export const Subtitle = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;

export const Footnote = styled(Text)`
  max-width: 85.6rem;
  font-size: ${({ theme }) => theme.textStyles.ds.body4.fontSize};
  color: ${({ theme }) => theme.colors.ds.gray[100]};
`;
