import {
  Tabs as ChakraTabs,
  TabList,
  TabPanels,
  TabPanel,
  TabsProps as ChakraTabsProps,
} from '@chakra-ui/react';

import { Tab } from './Tab';

type TabsProps = Omit<ChakraTabsProps, 'variant'> & {
  variant?: 'line';
  size?: 'md';
};

const Tabs = ({ children, ...rest }: TabsProps) => <ChakraTabs {...rest}>{children}</ChakraTabs>;

Tabs.TabList = TabList;
Tabs.Tab = Tab;
Tabs.TabPanels = TabPanels;
Tabs.TabPanel = TabPanel;

export { Tabs };
