import moment from 'moment';
import { RecordOf } from 'immutable';
import get from 'lodash/get';
import maxBy from 'lodash/fp/maxBy';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import findIndex from 'lodash/findIndex';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import { differenceInCalendarDays } from 'date-fns';

import { calculateTotalFees, defaultFeeObject } from 'src/app/utils/fee';
import { isFastDeliveryType } from 'src/app/utils/delivery-methods';
import { JustPayPaymentType } from 'src/app/pages/just-pay/justPayTypes';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { convertCurrencyToNumber } from 'src/app/utils/currency-utils';
import { dateInCentral } from 'src/app/version-2/utils/dates.utils';
import {
  isRetryFailedToDeliverACH,
  getRetryFailedToDeliverACH,
} from 'src/app/version-2/utils/payment.utils';
import {
  RiskStatusEnum,
  MetadataPaymentTypeEnum,
  BillFrequencyEnum,
  FailedPaymentMessageEnum,
  TransactionTypesEnum,
  TransactionStatusEnum,
  TransactionSourceEnum,
  CardTypeEnum,
  FundingSourceOrigins,
  CardNetworkEnum,
  FeatureFlagsEnum,
  DeliverFailureDataEnum,
  PaymentCollectStatusEnum,
} from 'src/app/version-2/model/enums';
import paymentApi from 'src/app/services/api/payments';
import { feeApiItem, feeApiFastItem, paymentFeeApiType } from 'src/app/utils/types';
import { getIsReturnedCheckFailure } from 'src/app/pages/bill/components/utils';

import {
  PURPOSE_OF_PAYMENT_SEPARATOR,
  CONSTS,
  DELIVERY_TYPE,
  FAILED_PAYMENT_TYPE,
  PURPOSE_OF_PAYMENT_STRUCTURE,
  DEFAULT_QB_CASH_BALANCE,
  PAYMENT_STATUS,
  FAST_DELIVERY_TYPES,
  PAYMENT_APPROVAL_STATUS,
} from './consts';
import type {
  PaymentType,
  TransactionType,
  NotePlaceholderType,
  DeliveryMethodType,
  QBCashStateType,
  PaymentTagProps,
} from './types';
import { featureFlags } from '@melio/shared-web';

const { ACH, CHECK } = CONSTS.DELIVERY_TYPE;

export const getNotePlaceholder = (
  notePlaceholder: NotePlaceholderType | null,
  isEditable: boolean | null,
  isRequest: boolean | null
) => {
  if (notePlaceholder) {
    return isEditable && notePlaceholder?.edit ? notePlaceholder.edit : notePlaceholder.view;
  }

  return isRequest
    ? 'requests.form.paymentActivity.emptyNoteToCustomerLabel'
    : 'bills.pay.confirm.emptyMemoLabel';
};

export const getFailedPaymentTitle = (payment: PaymentType) => {
  const isRefundFlowFeature = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );

  const isVirtualPaymentFailedToDeliver = isVirtualCardPaymentExpired(payment);

  if (isVirtualPaymentFailedToDeliver) return 'bills.status.paymentFailedResend';

  if (isReturnedCheckPayment(payment)) return `bills.status.paymentReturnedCheck`;

  if (isRetryFailedToDeliverACH(payment)) {
    // new retry
    return 'bills.status.failedToDeliverACHRetry';
  }

  if (isPaymentFailedRefund(payment)) {
    return `bills.status.paymentRefundFailed.messageTitle${isRefundFlowFeature ? 'Changes' : ''}`;
  }

  if (payment.metadata?.isPaymentRefundable) {
    return 'bills.form.paymentActivity.failedPaymentDescription.refund.title';
  }

  return 'bills.status.paymentFailedActionRequired';
};

export const getFailedPaymentDescription = (payment: PaymentType) => {
  const isRefundFlowFeature = featureFlags.defaultClient.getVariantNoTrack(
    FeatureFlagsEnum.QBO_ROBINHOOD_REFUND_FLOW,
    false
  );

  let key = payment?.metadata?.failureMessage || 'default';
  const isPaymentRefundable = payment.metadata?.isPaymentRefundable;
  const isFailedRefund = isPaymentFailedRefund(payment);
  const isFailedToDeliver = isFailedToDeliverPayment(payment);
  const isRetryFailedToDeliver = isRetryFailedToDeliverACH(payment);
  const isInternational = isInternationalPayment(payment);

  if (isInternational && isFailedToDeliver) {
    key = 'internationalFailedToDeliver';
  }

  if (isFailedRefund) {
    key = isRefundFlowFeature ? 'failedSendingRefund' : 'failedRefundPayment';
  }

  if (isPaymentRefundable) {
    key = 'noBankAccount';
  }

  if (isRetryFailedToDeliver) {
    const errorCode = getRetryFailedToDeliverACH(payment);

    key = `failedToDeliverACHRetryTitle.${errorCode}`;
  }

  if (isRefundFlowFeature && isPaymentRefundable) {
    const errorCode = getRetryFailedToDeliverACH(payment);

    if (
      errorCode === DeliverFailureDataEnum.BankDetailsError ||
      errorCode === DeliverFailureDataEnum.AccountNumberError
    ) {
      key = `refund.${errorCode}`;
    }
  }

  return `bills.form.paymentActivity.failedPaymentDescription.${key}`;
};

export const isPaymentFailedRefund = (payment: PaymentType) =>
  !isEmpty(payment?.metadata?.paymentStatusDates?.refundFailureDate) &&
  payment?.metadata?.paymentType === MetadataPaymentTypeEnum.REFUND;

export const isPaymentScheduleRefund = (payment: PaymentType): boolean =>
  payment?.metadata?.paymentType === MetadataPaymentTypeEnum.REFUND &&
  !isEmpty(payment?.metadata?.paymentStatusDates?.refundInitiated) &&
  isEmpty(payment?.metadata?.paymentStatusDates?.refundCompleted) &&
  isEmpty(payment?.metadata?.paymentStatusDates?.refundFailureDate);

export const isPaymentCompletedRefund = (payment: PaymentType): boolean =>
  payment?.metadata?.paymentType === MetadataPaymentTypeEnum.REFUND &&
  !isEmpty(payment?.metadata?.paymentStatusDates?.refundCompleted) &&
  isEmpty(payment?.metadata?.paymentStatusDates?.refundFailureDate);

export const isRefundPaymentFlow = (payment: PaymentType): boolean =>
  isPaymentScheduleRefund(payment) || isPaymentCompletedRefund(payment);

export const getLatestPayment = (payments: PaymentType[]): PaymentType =>
  maxBy((payment: any) => moment(payment.createdAt).unix())(payments);

export const getCheckDepositedDate = (transactions: TransactionType[]) => {
  const transaction = (transactions || []).find(
    (t) => t.status === 'SYSTEM' && t.rawData && t.rawData.description === 'Posted Check'
  );

  return get(transaction, 'rawData.line.DateTxn') || get(transaction, 'rawData.transfer.DateTxn');
};

export const getFailedRefundPaymentDate = (payment: PaymentType) => {
  if (payment?.metadata?.paymentType === MetadataPaymentTypeEnum.REFUND) {
    return payment?.metadata?.paymentStatusDates?.refundFailureDate;
  }

  if (payment.riskStatus === RiskStatusEnum.DECLINED) {
    const lastDeclinedApprovalAction: any = flow(
      filter((action: any) => action.result === 'declined'),
      maxBy((action: any) => moment(action.createdAt).unix())
    )(payment.paymentApprovalActions);

    return lastDeclinedApprovalAction?.createdAt;
  }

  if (getIsReturnedCheckFailure(payment?.metadata)) {
    return payment?.metadata?.createdAt;
  }

  const lastFailedTransaction: any = flow(
    filter((t: any) => t.status === TransactionStatusEnum.FAILED),
    maxBy((t: any) => moment(t.createdAt).unix())
  )(payment.transactions);

  return lastFailedTransaction?.createdAt;
};

export const getDeliveryPreference = (deliveryOptions: any, deliveryPreference: string) => {
  const id = findIndex(deliveryOptions, ['type', deliveryPreference]);
  const selectedId = id < 0 ? 0 : id;

  return selectedId.toString();
};

export const shouldShowFastPaymentOnRetryPaymentFlow = (
  deliveryType: string,
  deliveryPreference: string,
  deliveryOptions: any
) => {
  const isAlreadyFastPayment = FAST_DELIVERY_TYPES.includes(deliveryPreference);
  const isDeliveryTypeLegitForFastPayment = deliveryType === CHECK || deliveryType === ACH;
  const shouldShowFastPaymentPage =
    deliveryOptions && deliveryOptions.length > 1 && isDeliveryTypeLegitForFastPayment;

  return !isAlreadyFastPayment && shouldShowFastPaymentPage;
};

export const isPaymentScheduled = (payment: PaymentType) =>
  [PAYMENT_STATUS.SCHEDULED, PAYMENT_STATUS.BLOCKED].includes(payment.status);
export const isPaymentProcessed = (payment: PaymentType) =>
  [PAYMENT_STATUS.COMPLETED, PAYMENT_STATUS.IN_PROGRESS].includes(payment.status) &&
  !payment.manual;
export const isPaymentFailed = (payment: PaymentType) => payment.status === PAYMENT_STATUS.FAILED;
export const isPaymentMarkedAsPaid = (payment: PaymentType) => !!payment.manual;
export const isPaymentCompleted = (payment: PaymentType) =>
  payment.status === PAYMENT_STATUS.COMPLETED;

const getRefundPaymentTag = (payment: PaymentType): PaymentTagProps => {
  if (isPaymentFailedRefund(payment)) {
    return {
      statusLabel: 'bills.form.partialPayments.status.refundFailed',
      label: 'bills.form.partialPayments.review',
      date: payment.metadata?.paymentStatusDates?.refundFailureDate,
    };
  }

  if (isPaymentCompletedRefund(payment)) {
    return {
      statusLabel: 'bills.form.partialPayments.status.refundCompleted',
      label: 'bills.form.partialPayments.trackRefund',
      date: payment.metadata?.paymentStatusDates?.refundCompleted,
    };
  }

  return {
    statusLabel: 'bills.form.partialPayments.status.refundScheduled',
    label: 'bills.form.partialPayments.trackRefund',
    date: payment.metadata?.paymentStatusDates?.refundInitiated,
  };
};

export const getPaymentTag = (payment: RecordOf<PaymentType>) => {
  let label = 'bills.form.partialPayments.review';
  let statusLabel = 'bills.form.partialPayments.status.scheduled';
  let date = payment.scheduledDate;
  const isPaymentFailed = payment.status === PAYMENT_STATUS.FAILED;

  if (payment.status === PAYMENT_STATUS.COMPLETED) {
    statusLabel = 'bills.form.partialPayments.status.paid';
    date = payment.paidDate;

    if (payment.manual) {
      statusLabel = 'bills.form.partialPayments.status.manuallyPaid';
      date = payment.createdAt;
    }
  } else if (isPaymentFailed) {
    if (payment.metadata?.paymentType === MetadataPaymentTypeEnum.REFUND) {
      const refundTag = getRefundPaymentTag(payment);

      statusLabel = refundTag.statusLabel;
      label = refundTag.label;
      date = refundTag.date;
    } else {
      statusLabel = 'bills.form.partialPayments.status.failed';
      label = 'bills.form.partialPayments.reviewAndResolve';

      if (payment.approvalDecisionStatus === PAYMENT_APPROVAL_STATUS.DECLINED) {
        statusLabel = 'bills.form.partialPayments.status.declined';
      }

      date = getFailedRefundPaymentDate(payment);
    }
  }

  return { label, statusLabel, date };
};

export const getPaymentById = (payments?: PaymentType[], paymentId?: string) =>
  payments?.find((payment) => payment.id.toString() === paymentId?.toString());

export const isDirectPayment = (payment: PaymentType): boolean =>
  payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.RPPS;

export const isVirtualCardPayment = (payment: PaymentType): boolean =>
  payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

export const isVirtualCardPaymentExpired = (
  payment?: PaymentType,
  deliveryMethod?: DeliveryMethodType
): boolean =>
  payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER &&
  (deliveryMethod || payment?.deliveryMethod)?.deliveryType === DELIVERY_TYPE.VIRTUAL_CARD;

export const isVirtualCardExpired = (payment: PaymentType) =>
  payment.status === PAYMENT_STATUS.FAILED &&
  payment.id &&
  payment.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER &&
  payment.metadata?.failureMessage === FailedPaymentMessageEnum.VIRTUAL_CARD_EXPIRED;

export const isInternationalPayment = (payment: PaymentType): boolean =>
  payment.deliveryMethod?.deliveryType === DELIVERY_TYPE.INTERNATIONAL;

export const isEligibleToReceiveVirtualCard = (fundingSource?: Partial<FundingSource>): boolean => {
  const UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD = [
    CardNetworkEnum.VISA,
    CardNetworkEnum.AMEX,
  ];
  const blockedToVirtualCard =
    fundingSource?.cardAccount?.cardType === CardTypeEnum.CREDIT &&
    fundingSource?.cardAccount?.network &&
    UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD.includes(
      fundingSource?.cardAccount?.network as CardNetworkEnum
    );

  return !blockedToVirtualCard;
};

export const buildUpdatePaymentApiRequest = ({ payment }) => ({
  id: payment.id,
  scheduledDate: new Date(payment.scheduledDate).valueOf(),
  note: payment.note || null,
  fundingSourceId: payment.fundingSourceId,
  deliveryMethodId: payment.deliveryMethodId,
  purpose: payment.purpose,
});

export const buildCreatePaymentApiRequest = ({ payment, profile, orgId }) => ({
  amount: payment.amount,
  currency: payment.currency,
  userId: profile.id,
  scheduledDate: new Date(payment.scheduledDate).valueOf(),
  note: payment.note || null,
  isExpedited: payment.isExpedited || false,
  billId: payment.billId,
  originId: payment.originId || '-1',
  organizationId: orgId,
  origin: 'local',
  vendorId: parseInt(payment.vendorId, 10),
  fundingSourceId: payment.fundingSourceId,
  deliveryMethodId: payment.deliveryMethodId,
  deliveryPreference: payment.deliveryPreference,
  idempotentKey: (Math.random() * 100000).toString(),
  purpose: payment.purpose,
  createOrigin: payment.createOrigin,
});

// for get fee api by org (confirm)
export const convertFeeObject = (fees = []) => {
  if (!fees?.length) return defaultFeeObject;

  let standartFee: feeApiItem | undefined;
  let fastFee: feeApiFastItem | undefined;

  fees.forEach((fee: any) => {
    const { feeCatalog = {}, amount } = fee || {};

    if (isFastDeliveryType(feeCatalog.feeType)) {
      fastFee = {
        fee: feeCatalog.value,
        totalAmount: amount,
        feeType: feeCatalog.feeType,
      };
    } else if (!standartFee) {
      standartFee = {
        feeType: feeCatalog.feeType,
        valueType: feeCatalog.valueType,
        value: amount,
        cap: feeCatalog.cap,
        percent: feeCatalog.valueType === 'percent' ? feeCatalog.value / 100 : '',
      };
    }
  });

  return {
    finalAmount: calculateTotalFees(fees),
    feeStructure: standartFee,
    fastFeeApi: fastFee,
    promotion: {},
  };
};

// for get fee api by payment id (view paymnet)
export const convertPaymentFeeApiObj = (fees: paymentFeeApiType[] = []) => {
  if (!fees?.length) return defaultFeeObject;

  let standartFee: any | undefined;
  let fastFee: feeApiFastItem | undefined;

  fees.forEach((fee: any = {}) => {
    const { feeType, amount, valueType } = fee;

    if (isFastDeliveryType(fee?.feeType)) {
      fastFee = {
        feeType,
        totalAmount: amount,
      };
    } else {
      standartFee = {
        feeType,
        valueType,
        value: amount,
      };
    }
  });

  return {
    finalAmount: calculateTotalFees(fees),
    feeStructure: standartFee,
    fastFeeApi: fastFee,
    promotion: {},
  };
};

export const shouldAllowEditByDeliveryType = (deliveryMethod: DeliveryMethodType) => {
  const isInternational = deliveryMethod?.deliveryType === DELIVERY_TYPE.INTERNATIONAL;

  return !isInternational;
};

// Purpose of payment handlers
// since we have only one field for purpose of the payment,
// we're exposing these 2 functions to handle get and set for this field
export const getPurposeByType = (purpose, property = PURPOSE_OF_PAYMENT_STRUCTURE.TYPE) => {
  switch (property) {
    case PURPOSE_OF_PAYMENT_STRUCTURE.DESCRIPTION:
      return purpose?.split(PURPOSE_OF_PAYMENT_SEPARATOR)[1] || '';

    case PURPOSE_OF_PAYMENT_STRUCTURE.TYPE:
    default:
      return purpose?.split(PURPOSE_OF_PAYMENT_SEPARATOR)[0] || '';
  }
};

export const transformPurposeOfPayment = ({ type, description }: Record<string, any>) => {
  if (!type && !description) {
    return '';
  }

  return `${type || ''}${PURPOSE_OF_PAYMENT_SEPARATOR}${description || ''}`;
};
// ---------------------------

export const isFailedToDeliverPayment = (payment) =>
  payment.status === PAYMENT_STATUS.FAILED &&
  payment?.metadata?.failedType === FAILED_PAYMENT_TYPE.FAILED_TO_DELIVER;

export const isPushToDebitPayment = (payment: PaymentType) =>
  payment.status === PAYMENT_STATUS.COMPLETED &&
  payment?.eligibleForPushToDebit &&
  payment?.deliveryMethod?.deliveryType === DELIVERY_TYPE.CARD &&
  payment?.deliveryMethod?.id !== payment?.originalDeliveryMethodId &&
  payment?.transactions.length > 0;

export const isPaymentExceededQBCashBalance = ({
  payment,
  qbCashState,
  fundingSource,
}: {
  payment: PaymentType | JustPayPaymentType;
  qbCashState: QBCashStateType | undefined;
  fundingSource: FundingSource;
}): boolean =>
  !isNil(payment.amount) &&
  !isUndefined(qbCashState) &&
  qbCashState?.balance !== null &&
  qbCashState?.balance < payment.amount &&
  fundingSource?.origin === FundingSourceOrigins.QBCASH;

export const isValidQBCashBalance = (qbCashState: QBCashStateType | undefined) =>
  !qbCashState?.loading &&
  qbCashState?.balance !== DEFAULT_QB_CASH_BALANCE &&
  isEmpty(qbCashState?.error);

export const isJPMTransaction = (transactions: TransactionType[]) => {
  const transaction = head(transactions);

  return (
    transaction?.source === TransactionSourceEnum.JPM &&
    transaction?.transactionType === TransactionTypesEnum.ROUTING
  );
};

export const prepareRecurringObject = ({ bill, vendor }) => {
  const recurringBill = {
    frequency: bill.frequency,
    occurrences: bill.occurrences,
    dueDate: bill.dueDate && dateInCentral(new Date(bill.dueDate)),
  };

  return {
    bill: {
      totalAmount: convertCurrencyToNumber(bill.totalAmount),
      vendor,
      vendorId: vendor.id,
      id: '0',
      dueDate: bill.dueDate,
      note: bill.note || '',
      invoiceNumber: bill.invoiceNumber,
      intuitAccountId: bill.intuitAccountId || '',
      recurringBill,
    },
    recurringBill,
  };
};

export const hasRecurringFrequency = (value) => value !== BillFrequencyEnum.ONE_TIME;

export const getLatestCreatedPayment = async (orgId: string): Promise<PaymentType | undefined> => {
  const filters = {
    start: 0,
    limit: 1,
  };
  const { objects } = await paymentApi.getPayments({
    orgId,
    filters,
    sorting: 'createdAt',
  });

  return head(objects);
};

export const isReturnedCheckPayment = (payment) => {
  if (!payment) return false;

  const { voidChecks, balance } = payment;

  if (!voidChecks || !balance) return false;

  return !isEmpty(voidChecks) && !isNil(balance) && balance > 0;
};

export const isUndepositedPayment = (payment?: PaymentType): boolean => {
  const paymentStatusDates = payment?.metadata?.paymentStatusDates;

  return !paymentStatusDates?.depositedDate && Boolean(paymentStatusDates?.inTransitDate);
};

export const isUndepositedCheck = (payment?: PaymentType): boolean => {
  const isVoidAndRetryEnabled = !!payment?.metadata?.isVoidAndRetryEnabled;
  const isUndeposited = isUndepositedPayment(payment);
  const isCheckDeliveryMethod = payment?.deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK;

  return isVoidAndRetryEnabled && isUndeposited && isCheckDeliveryMethod;
};

export const isVoidAndRetryInProgress = (payment: PaymentType): boolean => {
  const isVoidAndRetryEnabled = !!payment?.metadata?.isVoidAndRetryEnabled;
  const isVoidAndRetryInProgress = !!payment?.metadata?.voidedCheckData;

  return isVoidAndRetryEnabled && isVoidAndRetryInProgress;
};

const isUndepositedMoreThan30DaysPayment = (payment?: PaymentType): boolean => {
  const paymentStatusDates = payment?.metadata?.paymentStatusDates;
  const isUndeposited = isUndepositedPayment(payment);
  const isPaymentInTransitMoreThan30Days = paymentStatusDates?.inTransitDate
    ? differenceInCalendarDays(new Date(), new Date(paymentStatusDates?.inTransitDate)) > 30
    : false;

  return isUndeposited && isPaymentInTransitMoreThan30Days;
};

export const isUndepositedOverdueCheck = (payment?: PaymentType): boolean => {
  const isVoidAndRetryEnabled = !!payment?.metadata?.isVoidAndRetryEnabled;
  const isCheckDeliveryMethod = payment?.deliveryMethod?.deliveryType === DELIVERY_TYPE.CHECK;

  return (
    isVoidAndRetryEnabled && isCheckDeliveryMethod && isUndepositedMoreThan30DaysPayment(payment)
  );
};

export const getIsPaymentCollected = (payment: Partial<PaymentType>) => {
  const collectedStatuses = [
    PaymentCollectStatusEnum.SENT,
    PaymentCollectStatusEnum.CLEARED,
    PaymentCollectStatusEnum.SETTLED,
    PaymentCollectStatusEnum.ACKNOWLEDGED,
  ];

  return !!payment?.collectStatus && collectedStatuses.includes(payment.collectStatus);
};
