import React from 'react';
import { SmartIcon } from '@melio/billpay-design-system';
import { NotificationTypeEnum } from './NotificationCard.enum';
import { IconWrapper } from './NotificationCard.styles';

export interface NotificationCardIconProps {
  type: NotificationTypeEnum;
}

export const NotificationCardIcon = (props: NotificationCardIconProps) => {
  const { type } = props;
  return (
    <IconWrapper>
      {
        {
          [NotificationTypeEnum.INFO]: <SmartIcon size="2rem" type="blue-info" />,
          [NotificationTypeEnum.WARNING]: <SmartIcon size="2rem" type="yellow-info" />,
          [NotificationTypeEnum.ERROR]: <SmartIcon size="2rem" type="error" />,
          [NotificationTypeEnum.DISCOVER]: <SmartIcon size="2rem" type="idea" />,
          [NotificationTypeEnum.SUCCESS]: <SmartIcon size="2rem" type="success" />,
        }[type]
      }
    </IconWrapper>
  );
};
