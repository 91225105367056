import { DeliveryMethod, FundingSource } from 'src/app/version-2/model/dtos';
import { DeliveryEnum, DeliveryMethodEligibilityStatusEnum } from 'src/app/version-2/model/enums';
import { deliveryMethodAdapter } from 'src/app/version-2/pages/batch-bulk/adapters/deliveryMethod.adapter';
import {
  DeliveryMethodItem,
  FundingSourceItem,
} from 'src/app/version-2/pages/batch-bulk/model/objects';
import { isEligibleToReceiveInternational } from 'src/app/version-2/utils/international.utils';
import { isEligibleToReceiveVirtualCard } from 'src/app/version-2/utils/paymentMethods.utils';

const DELIVERY_METHODS_ORDER = [
  DeliveryEnum.VIRTUAL_CARD,
  DeliveryEnum.ACH,
  DeliveryEnum.CHECK,
  DeliveryEnum.RPPS,
  DeliveryEnum.CARD,
  DeliveryEnum.VIRTUAL,
];

export const orderDeliveryMethods = (deliveryMethods: DeliveryMethod[]): DeliveryMethod[] => {
  const deliveryMethodsFiltered = deliveryMethods.filter((deliveryMethod) => !!deliveryMethod);

  return [...deliveryMethodsFiltered].sort(
    (a, b) =>
      DELIVERY_METHODS_ORDER.indexOf(a.deliveryType) -
      DELIVERY_METHODS_ORDER.indexOf(b.deliveryType)
  );
};

interface PreSelectedDeliveryMethodsParams {
  deliveryMethods: DeliveryMethod[];
  selectedPaymentMethod?: FundingSourceItem;
}

export const preSelectedDeliveryMethods = ({
  deliveryMethods,
  selectedPaymentMethod,
}: PreSelectedDeliveryMethodsParams): DeliveryMethodItem[] => {
  const deliveryMethodsAdapted = deliveryMethods.map((deliveryMethod) => {
    const deliveryMethodEligibilityStatus = getDeliveryMethodEligibilityStatus({
      deliveryMethod,
      fundingSource: selectedPaymentMethod,
    });

    return deliveryMethodAdapter({
      deliveryMethod,
      isDisabled: deliveryMethodEligibilityStatus !== DeliveryMethodEligibilityStatusEnum.Eligible,
    });
  });

  return deliveryMethodsAdapted.sort(
    (a, b) =>
      DELIVERY_METHODS_ORDER.indexOf(a.deliveryType) -
      DELIVERY_METHODS_ORDER.indexOf(b.deliveryType)
  );
};

interface PreSelectedDeliveryMethodParams {
  deliveryMethod?: DeliveryMethod;
}

export const preSelectedDeliveryMethod = ({
  deliveryMethod,
}: PreSelectedDeliveryMethodParams): DeliveryMethodItem | undefined =>
  deliveryMethod
    ? deliveryMethodAdapter({
        deliveryMethod,
      })
    : undefined;

interface GetDefaultDeliveryMethodParams {
  deliveryMethod?: DeliveryMethod;
  selectedPaymentMethod?: FundingSourceItem;
  deliveryMethods: DeliveryMethod[];
}

export const getDefaultDeliveryMethod = ({
  deliveryMethod,
  selectedPaymentMethod,
  deliveryMethods,
}: GetDefaultDeliveryMethodParams): DeliveryMethod | undefined => {
  if (!deliveryMethods.length) return undefined;

  if (deliveryMethod) {
    const deliveryMethodEligibilityStatus = getDeliveryMethodEligibilityStatus({
      deliveryMethod,
      fundingSource: selectedPaymentMethod,
    });

    if (deliveryMethodEligibilityStatus === DeliveryMethodEligibilityStatusEnum.Eligible) {
      return deliveryMethod;
    }
  }

  const fallbackDeliveryMethod = deliveryMethods.find(
    (deliveryMethodDTO) =>
      getDeliveryMethodEligibilityStatus({
        deliveryMethod: deliveryMethodDTO,
        fundingSource: selectedPaymentMethod,
      }) === DeliveryMethodEligibilityStatusEnum.Eligible
  );

  return fallbackDeliveryMethod;
};

interface GetDeliveryMethodEligibilityStatusParams {
  deliveryMethod?: DeliveryMethod;
  fundingSource?: FundingSource;
}

export const getDeliveryMethodEligibilityStatus = ({
  deliveryMethod,
  fundingSource,
}: GetDeliveryMethodEligibilityStatusParams): DeliveryMethodEligibilityStatusEnum => {
  if (!fundingSource) {
    return DeliveryMethodEligibilityStatusEnum.Eligible;
  }

  if (deliveryMethod?.deliveryType === DeliveryEnum.VIRTUAL && !fundingSource?.isVerified) {
    return DeliveryMethodEligibilityStatusEnum.VirtualNotEligible;
  }

  if (
    deliveryMethod?.deliveryType === DeliveryEnum.VIRTUAL_CARD &&
    !isEligibleToReceiveVirtualCard(fundingSource)
  ) {
    if (deliveryMethod.isFilledByVendor) {
      return DeliveryMethodEligibilityStatusEnum.Eligible;
    }

    return DeliveryMethodEligibilityStatusEnum.VirtualCardNotEligible;
  }

  if (
    deliveryMethod?.deliveryType === DeliveryEnum.INTERNATIONAL &&
    !isEligibleToReceiveInternational(fundingSource)
  ) {
    return DeliveryMethodEligibilityStatusEnum.InternationalNotEligible;
  }

  return DeliveryMethodEligibilityStatusEnum.Eligible;
};
