import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import MIButton from 'src/app/components/common/MIButton';
import { CONSTS } from 'src/app/utils/consts';
import { devices } from 'src/app/theme/AppDevices';
import analytics from 'src/app/services/analytics';
import { useModal } from 'src/app/helpers/react/useModal';
import CountriesList from '../../components/CountriesList';
import { ButtonContainer } from 'src/app/pages/vendor/delivery-methods/international/components/ButtonContainer';
import InternationalContext from '../../Context';
import { StepProps } from '../../types';
import { UnsupportedCountriesModal } from './UnsupportedCountriesModal';

const Form = ({ onNext }: StepProps) => {
  const [state, actions] = useContext(InternationalContext);

  const [error, setError] = useState('');
  const [fieldValue, setFieldValue] = useState<{
    code: string;
    name: string;
    billingType: string;
    isSupported: boolean;
    risk: string;
  }>(state.country);

  const [unsupportedCountriesModal, showUnsupportedCountriesModal] = useModal(
    UnsupportedCountriesModal,
    {}
  );

  useEffect(() => {
    if (state.edit) {
      const country = state.countries.find((country) => country.code === fieldValue.code);

      if (country) {
        const { code, label, billingType, isSupported, risk } = country;

        onChange({ value: code, label, billingType, isSupported, risk });
      }
    }
  }, []);

  const onChange = ({ value, label, billingType, isSupported, risk }) => {
    setFieldValue({ code: value, name: label, billingType, isSupported, risk });
    setError('');
  };

  const handleSubmit = () => {
    // update new context values for next step
    actions.setVendorLocation({ country: fieldValue });

    if (!fieldValue.isSupported) {
      showUnsupportedCountriesModal({ countryName: fieldValue.name });

      return;
    }

    // moving to next step
    onNext?.();
  };

  const onSubmit = () => {
    if (!fieldValue.code) {
      setError('inputErrors.international.country.empty');

      return;
    }

    if (error) setError('');

    analytics.track('pay-bill', 'international-choose-country', {
      countryCode: fieldValue.code,
      source: 'dropdown',
      isSupported: fieldValue.isSupported,
    });

    // submit success
    handleSubmit();
  };

  const outsideClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (fieldValue.name) {
      event.preventDefault();
    } else {
      setFieldValue({ code: '', name: '', billingType: '', isSupported: false, risk: '' });
    }
  };

  return (
    <StyledForm>
      {unsupportedCountriesModal}
      <CountriesList
        label="international.vendorLocation.country.label"
        options={state.options}
        value={fieldValue.code}
        onChange={onChange}
        errorMessage={error}
        outsideClickHandler={outsideClickHandler}
      />

      <ButtonContainer isFixed>
        <MIButton
          type="submit"
          className="button"
          onClick={onSubmit}
          variant={CONSTS.BUTTON_VARIANT.PRIMARY}
          label="international.vendorLocation.button"
        />
      </ButtonContainer>
    </StyledForm>
  );
};

export default Form;

const StyledForm = styled.div`
  .button {
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media ${devices.tablet}, ${devices.desktop} {
      margin: 3rem auto 2.3rem;
    }
  }
`;
