import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CellContext } from '@tanstack/react-table';
import { getOrganizationPreferences } from 'src/app/redux/organization/selectors';
import { getEndOfLifeMaxDate, isEndOfLifeMonth } from 'src/app/utils/dates';
import { OrganizationPreferencesType } from 'src/app/utils/types';
import { analytics } from 'src/app/version-2/externals';
import {
  BATCH_BULK_EVENT_PAGE,
  OPEN_SCHEDULED_DATE_CALENDAR_FROM_TABLE,
  SELECT_SCHEDULED_DATE,
} from 'src/app/version-2/pages/batch-bulk/model/consts/batchBulkAnalytics.consts';
import { BatchBulkItemEnum } from 'src/app/version-2/pages/batch-bulk/model/enums/batchBulkItem.enum';
import { BatchBulkItem } from 'src/app/version-2/pages/batch-bulk/model/objects';
import { batchBulkActions } from 'src/app/version-2/pages/batch-bulk/modules/batchBulk.slice';
import { DeductionDateRenderer } from 'src/app/version-2/pages/batch-bulk/renderers/DeductionDate.renderer';

import { Moment } from 'moment';

export const CellDeductionDateRenderer: FC<CellContext<BatchBulkItem, Date>> = (props) => {
  const { row, cell } = props;
  const {
    id,
    type,
    dueDate,
    minScheduledDate,
    totalBillIds,
    totalPartialBillIds,
    vendorId,
  }: BatchBulkItem = row.original;
  const [isOpen, setIsOpen] = useState(false);
  const organizationPreferences: OrganizationPreferencesType = useSelector(
    getOrganizationPreferences
  );
  const dispatch = useDispatch();
  const isSubRow = type === BatchBulkItemEnum.BILL;
  const isFirstWave = !!organizationPreferences?.billPayFirstWaveUser;
  const maxDeliveryDate: Date = getEndOfLifeMaxDate(isFirstWave);
  const [showEOLNotification, setShowEOLNotification] = useState(false);
  const endOfLifeLabel = useMemo(
    () =>
      isFirstWave
        ? 'bills.pay.date.scheduledDateEndOfLifeNotificationFirstWave'
        : 'bills.pay.date.scheduledDateEndOfLifeNotificationSecondWave',
    [isFirstWave]
  );

  const deductionDate = cell.getValue();

  const onChange = useCallback(
    (value: { id: string; date: Date | null }) => {
      setIsOpen(false);

      if (!value.date) return;

      dispatch(
        batchBulkActions.updateDeductionDate({
          date: value.date,
          paymentIntentId: id,
        })
      );
      analytics.track(BATCH_BULK_EVENT_PAGE, SELECT_SCHEDULED_DATE, {
        billIds: totalBillIds,
        partialBillIds: totalPartialBillIds,
        vendorId,
      });
    },
    [id, totalBillIds, totalPartialBillIds, vendorId]
  );

  const goLearnMore = () => {
    analytics.track('end-of-life-batch-cell', 'learn-more', { isFirstWave });
    window.open(
      'https://quickbooks.intuit.com/learn-support/en-us/help-article/money-movement/switch-bill-pay-powered-melio-quickbooks-bill-pay/L98z4nrOH_US_en_US?uid=lofvkaen',
      '_blank'
    );
  };

  const toggleEndOfLifeNotification = useCallback((dateToCompare?: Moment | null) => {
    const isEndOfLifeMonthResult = isEndOfLifeMonth(dateToCompare, isFirstWave);

    setShowEOLNotification(isEndOfLifeMonthResult);
  }, []);

  useEffect(() => {
    toggleEndOfLifeNotification(cell.getValue() as unknown as Moment);
  }, [toggleEndOfLifeNotification, cell]);

  const onMonthChange = useCallback((date: Moment) => {
    toggleEndOfLifeNotification(date);
  }, []);

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      analytics.track(BATCH_BULK_EVENT_PAGE, OPEN_SCHEDULED_DATE_CALENDAR_FROM_TABLE, {
        billIds: totalBillIds,
        partialBillIds: totalPartialBillIds,
        vendorId,
      });
    }
  }, [setIsOpen, isOpen, totalBillIds, totalPartialBillIds, vendorId]);

  if (isSubRow) return null;

  return (
    <DeductionDateRenderer
      dueDate={dueDate}
      minScheduledDate={minScheduledDate}
      maxScheduledDate={maxDeliveryDate}
      id={row.id}
      isOpen={isOpen}
      onClick={onClick}
      onChange={onChange}
      onClose={() => setIsOpen(false)}
      deductionDate={deductionDate}
      goLearnMore={goLearnMore}
      showEOLNotification={showEOLNotification}
      eolNotificationLabel={endOfLifeLabel}
      onMonthChange={onMonthChange}
    />
  );
};
