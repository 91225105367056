import React from 'react';
import { LoaderContext } from '../../types';
import { Loading } from './Loader.styles';

type Props = {
  className?: string;
  context?: LoaderContext;
};

export const Loader: React.FC<Props> = ({ className = '', context = 'page' }) => (
  <Loading
    context={context}
    className={`circular ${className}`}
    viewBox="25 25 50 50"
    data-testid="quickbooks-loader"
  >
    <circle
      className="track"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
    <circle
      className="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="1.2"
      strokeMiterlimit="10"
    />
  </Loading>
);
