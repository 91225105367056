import isSameDay from 'date-fns/isSameDay';
import subBusinessDays from 'date-fns/subBusinessDays';
import moment from 'moment-timezone';

const ONE_DAY = 24 * 60 * 60 * 1000;

export const isToday = (date: Date | number | string) => {
  const today = new Date();

  return isSameDay(new Date(date), today);
};

export const isTomorrow = (date: Date | number | string) => {
  const tomorrow = new Date().setDate(new Date().getDate() + 1);

  return isSameDay(new Date(date), tomorrow);
};

export const isBusinessDay = (date: Date): boolean => {
  const oneDayAhead = new Date(date.getTime() + ONE_DAY);

  return subBusinessDays(oneDayAhead, 1).getTime() === date.getTime();
};

export const getFirstBusinessDayFrom = (date: Date): Date => {
  let selectedDate = date;

  while (!isBusinessDay(selectedDate)) {
    selectedDate = new Date(selectedDate.getTime() + ONE_DAY);
  }

  return selectedDate;
};

// export const dateInCentral = (date: Date) => date.toLocaleString('en-US', { timeZone: 'CST' });
export const dateInCentral = (date: Date) => {
  // TODO - this has to be converted to non moment implementation
  const m = moment(date);

  return moment
    .tz('US/Central')
    .year(m.year())
    .month(m.month())
    .date(m.date())
    .hour(9)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate();
};

export const isTomorrowBusinessDay = () => {
  const tomorrow = new Date();

  tomorrow.setDate(new Date().getDate() + 1);

  return isBusinessDay(tomorrow);
};

export const removeTime = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const getUTCDate = (date?: Date | string | null) => {
  if (!date) return undefined;

  const d = new Date(date);

  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
};
