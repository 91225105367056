import React, { useState, useEffect } from 'react';
import first from 'lodash/first';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StyledLink, StyledLoader } from './PaymentListItem.styles';
import useHistoryWithOrgId from 'src/app/modules/navigation/hooks/useHistoryWithOrgId';
import { BillType } from 'src/app/utils/types';
import { MIFormattedText } from 'src/app/utils/formatting';
import { isBillSynced } from 'src/app/utils/bills';
import { getOrgId } from 'src/app/redux/user/selectors';
import locations from 'src/app/utils/locations';
import { formatDate } from '../utils';
import { ListItem } from '../shared';

type Props = {
  bill: BillType;
  paymentId: string | undefined;
};

const PaymentListItem = ({ bill, paymentId }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const orgId = useSelector(getOrgId) as string;
  const { state: historyState } = useLocation<{ state: Record<string, any> }>();
  const [historyPush] = useHistoryWithOrgId();
  const { deliveryEta, scheduledDate, id: currentPaymentId } = first(bill.payments) || {};

  useEffect(() => {
    setLoading(false);
  }, [paymentId]);

  const nextUrl = locations.Bills.payment.url({
    billId: bill.id,
    paymentId: currentPaymentId,
    orgId,
  });

  const onReview = () => {
    setLoading(true);

    if (currentPaymentId) historyPush({ path: nextUrl, state: historyState });
  };

  const renderButton = () => {
    const hasBill = isBillSynced(bill);
    const isCurrent = String(paymentId) === String(currentPaymentId);

    if (isCurrent) return <MIFormattedText label="bills.form.paymentActivity.recurring.current" />;

    if (hasBill) {
      if (loading) return <StyledLoader context="button" />;

      return (
        <StyledLink onClick={onReview}>
          <MIFormattedText label="bills.form.paymentActivity.recurring.review" />
        </StyledLink>
      );
    }

    return null;
  };

  return (
    <ListItem key={bill.id}>
      <div>{formatDate(scheduledDate)}</div>
      <div>{formatDate(deliveryEta)}</div>
      {renderButton()}
    </ListItem>
  );
};

export { PaymentListItem };
