import { ReactNode } from 'react';
import { BILLING_FEATURE_WIZARD_FLOW } from '../pages/settings/components/fee/consts';

export const getAdditionDescriptionLabel = (
  payBillWizardFlow?: string,
  debitFee?: ReactNode | string
) => {
  if (payBillWizardFlow === BILLING_FEATURE_WIZARD_FLOW) {
    return 'onboarding.fundingSources.options.debit.feeFundingSourceDescriptionAddition';
  }

  if (debitFee) {
    return 'onboarding.fundingSources.options.debit.descriptionFee';
  }

  return '';
};

export const getDescriptionLabel = ({
  payBillWizardFlow,
  debitFee,
  isDebitCardExpired,
}: {
  payBillWizardFlow?: string;
  isDebitCardExpired: boolean;
  debitFee?: ReactNode | string;
}) => {
  if (isDebitCardExpired) {
    return 'bills.pay.fundingSource.expiredDescription';
  }

  if (payBillWizardFlow) {
    return 'onboarding.fundingSources.options.debit.feeFundingSourceDescription';
  }

  if (debitFee) {
    return 'onboarding.fundingSources.options.debit.descriptionFee';
  }

  return '';
};
