import { useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';

export const FTUInitPage: FC = () => {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event?.data?.type === 'redirect-to-loader') {
        history.push('/quickbooks/ftu-loader', { action: event?.data?.action });
      }
    });
  }, [history]);

  return null;
};
