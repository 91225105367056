import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, generatePath } from 'react-router-dom';
import profileStore from 'src/app/modules/profile/profile-store';
import { withSiteContext } from 'src/app/hoc/withSiteContext';
import { ENTRYPOINT_SOURCE, BILL_STATUS } from 'src/app/utils/consts';
import errorIcon from 'src/app/images/qbo/error-icon.png';
import ErrorPage from 'src/app/components/layout/ErrorLayoutPage';
import errorIllustration from 'src/app/images/icons/errorToken.svg';
import { melioClose } from 'src/app/utils/external-events';
import locations from 'src/app/utils/locations';
import analytics from 'src/app/services/analytics';
import { parseQueryString, removeQueryString } from 'src/app/utils/query-utils';
import { QBOViewPaymentPage } from 'src/app/pages/bill/pay/QBOViewPaymentPage';
import { isMobileInLoader } from 'src/app/utils/screen';
import EntrypointContainer from './EntrypointContainer';
import WrongAccount from './WrongAccount';
import QuickpayRedirect from './QuickpayRedirect';
import GeneralFlowErrorContainer from './GeneralFlowErrorContainer';
import ConnectErrorPageContainer from './ConnectErrorPageContainer';
import QuickpayViewBillRedirect from './QuickpayViewBillRedirect';
import PayLoader from './entrypoint/loaders/pay';
import { EditPaymentLoader } from './entrypoint/loaders/EditPaymentLoader';
import { FTULoaderPage } from './first-time-user-loader/FTULoaderPage';
import { FTUInitPage } from './first-time-user-loader/FTUInitPage';
import { PaymentsNotAvailableMessage } from '../bill/pay/PaymentsNotAvailableMessage';
import TestVendorForex from './TestVendorForex';
import { getBillsDefaultFilters } from 'src/app/utils/billsPath';

export const qboPublicRoutes = [
  {
    path: '/quickbooks/entry/:action/:realmId/:txnId?',
    exact: true,
    component: (cProps: any) => {
      const query = parseQueryString(cProps.location.search);
      const { realmId } = cProps.match.params;
      const {
        txnIds,
        vendorId,
        isByVendorId,
        paymentId,
        source,
        emailTag,
        connectedUserRealmId,
        switcher,
      } = query;
      const queryIsByVendorId = isByVendorId === 'true';

      analytics.page(
        'qbo-entrypoint',
        source === ENTRYPOINT_SOURCE.EMAIL
          ? `email-action-${cProps.match.params.action}`
          : `action-${cProps.match.params.action}`,
        source === ENTRYPOINT_SOURCE.EMAIL ? { emailTag } : undefined
      );

      if (source === ENTRYPOINT_SOURCE.EMAIL && connectedUserRealmId !== realmId) {
        return <WrongAccount />;
      }

      return (
        <EntrypointContainer
          {...cProps.match.params}
          txnIds={txnIds}
          vendorId={vendorId}
          isByVendorId={queryIsByVendorId}
          paymentId={paymentId}
          source={source}
          currentSwitcherValue={switcher === 'true'}
        />
      );
    },
  },
  {
    path: '/quickbooks/ftu-loader/init',
    exact: true,
    component: FTUInitPage,
  },
  {
    path: '/quickbooks/ftu-loader',
    exact: true,
    component: FTULoaderPage,
  },
  {
    path: locations.QuickBooks.incognitoErrorPage.url(),
    component: (cProps: any) => {
      analytics.page('qbo-incognito-error', 'incognito-error');

      return (
        <GeneralFlowErrorContainer
          {...cProps}
          subtitle="qbo.incognitoErrorPage.subtitle"
          title="qbo.incognitoErrorPage.title"
          illustration={errorIllustration}
        />
      );
    },
  },
  {
    path: locations.QuickBooks.testVendorForex.url(),
    component: () => <TestVendorForex />,
  },
  {
    path: locations.QuickBooks.safariErrorPage.url(),
    component: (cProps: any) => {
      analytics.page('qbo-safari-error', 'safari-error');

      return (
        <GeneralFlowErrorContainer
          {...cProps}
          subtitle="qbo.safariErrorPage.subtitle"
          title="qbo.safariErrorPage.title"
          supportLink="qbo.safariErrorPage.supportLink"
          supportText="qbo.safariErrorPage.supportText"
          settingsHelp="qbo.safariErrorPage.settingsHelp"
          settingsHelpTitle="qbo.safariErrorPage.settingsHelpTitle"
          illustration={errorIllustration}
        />
      );
    },
  },
  {
    path: locations.QuickBooks.errorConnect.url(),
    exact: true,
    component: (cProps: any) => {
      const query = parseQueryString(cProps.location.search);
      const eventPage = 'qbo-error-connect';

      analytics.page(eventPage, query.error as string);

      return <ConnectErrorPageContainer query={query as { error: string }} />;
    },
  },
  {
    path: locations.QuickBooks.mobile.pay.url({
      action: ':action',
      realmId: ':realmId',
      txnId: 'txnId',
    }),
    exact: true,
    component: (cProps: any) => {
      analytics.page('qbo-entrypoint', `action-${cProps.match.params.action}`);
      const query = parseQueryString(cProps.location.search);

      return <EntrypointContainer {...cProps.match.params} txnIds={query.txnIds} />;
    },
  },
  {
    path: locations.QuickBooks.errorPage.url({ source: ':source' }),
    exact: true,
    component: () => (
      <ErrorPage
        illustration={errorIcon}
        title="qbo.blockedUser.titleBlocked"
        subtitle="qbo.blockedUser.subtitleBlocked"
        buttonAction={melioClose}
        buttonLabel="qbo.blockedUser.buttonTextClose"
      />
    ),
  },
];

export const qboPrivateRoutes = [
  {
    path: locations.QuickBooks.createBill.url({ realmId: ':realmId' }),
    exact: true,
    component: withSiteContext()((cProps: any) => {
      const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
      const redirectPath = generatePath(locations.Bills.create.index.url(), {
        orgId,
      });
      // TODO temporary changes

      return <PayLoader {...cProps.match.params} redirectPath={redirectPath} />;
    }),
  },
  {
    path: locations.QuickBooks.billsList.url({ realmId: ':realmId' }),
    exact: true,
    component: withSiteContext()((cProps: any) => {
      const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
      const defaultFilters = getBillsDefaultFilters(BILL_STATUS.UNPAID);
      const redirectPath = generatePath(locations.Bills.filteredViewWithoutId.url(defaultFilters), {
        orgId,
      });

      if (cProps.site.quickpayList) {
        // TODO add full sync for QBO
        return (
          <Redirect
            to={{
              pathname: locations.QuickBooks.actions.quicklist.url(cProps.match.params),
              state: { state: { name: 'initialState' } },
            }}
          />
        );
      }

      return <PayLoader {...cProps.match.params} redirectPath={redirectPath} />;
    }),
  },
  {
    path: '/quickbooks/pay-new-landing-page/pay',
    exact: true,
    component: (cProps: any) => {
      const query = parseQueryString(cProps.location.search);
      const { txnId, vendorId, isByVendorId } = query;
      const queryTxnId = txnId;
      const queryIsByVendorId = isByVendorId === 'true';

      return isMobileInLoader() ? (
        <PaymentsNotAvailableMessage />
      ) : (
        <PayLoader
          {...cProps.match.params}
          ids={queryTxnId}
          vendorOriginId={vendorId}
          isByVendor={queryIsByVendorId}
        />
      );
    },
  },
  {
    path: '/quickbooks/batchpay',
    exact: true,
    component: (cProps: any) => {
      const query = parseQueryString(cProps.location.search);
      const { txnIds, vendorId, isByVendorId } = query;
      const queryTxnIds = txnIds;
      const queryIsByVendorId = isByVendorId === 'true';

      return isMobileInLoader() ? (
        <PaymentsNotAvailableMessage />
      ) : (
        <PayLoader
          {...cProps.match.params}
          ids={queryTxnIds}
          vendorOriginId={vendorId}
          isByVendor={queryIsByVendorId}
        />
      );
    },
  },
  {
    path: removeQueryString(
      locations.QuickBooks.actions.quickpay.url({
        vendorOriginId: ':vendorOriginId',
      })
    ),
    exact: true,
    component: (cProps: any) => {
      const query = parseQueryString(cProps.location.search);

      return <QuickpayRedirect {...cProps.match.params} query={query} />;
    },
  },
  {
    path: removeQueryString(
      locations.QuickBooks.actions['quickpay-view'].url({
        paymentId: ':paymentId',
      })
    ),
    exact: true,
    component: (cProps: any) => {
      const query = parseQueryString(cProps.location.search);

      return <QuickpayViewBillRedirect {...cProps.match.params} query={query} />;
    },
  },
  {
    path: locations.QuickBooks.actions.edit.url({ id: ':id' }),
    exact: true,
    component: (cProps: any) => {
      analytics.page('qbo-actions', 'edit');

      return <EditPaymentLoader {...cProps.match.params} />;
    },
  },
];

export const qboBillRoutes = [
  {
    path: locations.Bills.single.url({ billId: ':billId', orgId: ':orgId' }),
    exact: true,
    component: (cProps: any) => {
      analytics.page('bills', 'single-qbo-bill');

      return <QBOViewPaymentPage {...cProps} />;
    },
  },
  {
    path: locations.Bills.payment.url({
      billId: ':billId',
      paymentId: ':paymentId',
      orgId: ':orgId',
    }),
    exact: true,
    component: (cProps: any) => {
      analytics.page('bills', 'single-qbo-payment');

      return <QBOViewPaymentPage {...cProps} />;
    },
  },
];
