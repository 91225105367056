import React from 'react';
import { MIFormattedText } from 'src/app/utils/formatting';
import styled from 'styled-components';

type Props = {
  companyName?: string;
};

const QBOPushToDebitFooter = ({ companyName }: Props) => (
  <Info>
    <LockImage>
      <i className="icon-lock-icon" />
    </LockImage>
    <InfoText>
      <MIFormattedText label="vendors.deliveryMethods.shiftToDebit.info" values={{ companyName }} />
    </InfoText>
  </Info>
);

const Info = styled.div`
  ${({ theme }) => theme?.text?.fontType?.hint}
  display: flex;
  text-align: center;
  color: ${(props) => props.theme.text.color.subtitle};
  padding-top: 2rem;
`;

const LockImage = styled.div`
  font-size: 2.4rem;
  color: ${(props) => props.theme.text.color.darkGrey};
  margin-right: 0.8rem;
`;

const InfoText = styled.div`
  text-align: left;
  line-height: ${(props) => props.theme.text.lineHeight.hint};
`;

export default QBOPushToDebitFooter;
