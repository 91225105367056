import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@melio/billpay-design-system';

import { MIFormattedText } from 'src/app/utils/formatting';

const FirstPaymentLateTooltip = () => (
  <Tooltip
    label={<MIFormattedText label="payBillPaymentActivity.deliveryDate.recurringHint" />}
    placement="top"
  >
    <StyledIcon className="icon-info-hollow-icon" />
  </Tooltip>
);

export { FirstPaymentLateTooltip };

const StyledIcon = styled.i`
  margin-left: 0.5rem;
  font-weight: 600;
`;
