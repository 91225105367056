import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ADD_FUNDING_SOURCE_WIZARD_ORIGIN } from 'src/app/utils/consts';
import { withPreservedStateNavigator } from 'src/app/hoc';
import analytics from 'src/app/services/analytics';
import locations from 'src/app/utils/locations';
import { AreaLoader } from '@melio/billpay-design-system';
import { getDeliveryMethods, getFundingSources } from 'src/app/redux/user/selectors';
import { isUnverifiedAchLimitExceeded } from 'src/app/utils/funding-sources';
import useRefreshFundingSources from 'src/app/modules/funding-sources/hooks/useRefreshFundingSources';
import { setFeeFundingSourceBankType } from 'src/app/redux/payBillWizard/actions';
import { BANK_TYPE } from 'src/app/redux/payBillWizard/types';
import SelectBankAccountVerificationTypePage from './components/SelectBankAccountVerificationTypePage';

export type Option = {
  id: string;
  label: string;
  description: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: string;
};

type Props = {
  navigate: (
    url: string,
    shouldReplaceCurrent?: boolean,
    state?: Record<string, any> | null
  ) => void;
  navigateToExitWithPreservedState: () => void | null | undefined;
  vendorId: string;
  locationState: Record<string, any>;
};

const eventPage = 'bank-add-variants';

const SelectBankAccountVerificationTypePageContainer = ({
  navigate,
  vendorId,
  locationState,
  navigateToExitWithPreservedState,
}: Props) => {
  const dispatch = useDispatch();
  const origin = get(locationState, 'preservedState.origin', '');
  const userDeliveryMethods = useSelector(getDeliveryMethods);
  const userFundingSources = useSelector(getFundingSources);
  const [showUnverifiedAchLimitNotification, setShowUnverifiedAchLimitNotification] =
    useState<boolean>(false);
  const isComingFromFundingSourceWizard = [
    ADD_FUNDING_SOURCE_WIZARD_ORIGIN.SETTINGS,
    ADD_FUNDING_SOURCE_WIZARD_ORIGIN.GUEST_ONBOARDING,
    ADD_FUNDING_SOURCE_WIZARD_ORIGIN.PAY_BILL,
    ADD_FUNDING_SOURCE_WIZARD_ORIGIN.JUST_PAY,
  ].includes(origin);
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  useEffect(() => {
    refreshFundingSources();
  }, [refreshFundingSources]);

  const onPlaidClick = () => {
    analytics.track(eventPage, 'add-plaid');
    dispatch(setFeeFundingSourceBankType(BANK_TYPE.PLAID));

    navigate(locations.Onboarding.fundingSources.bank.plaid.url());
  };

  const onManualClick = () => {
    if (isUnverifiedAchLimitExceeded(userFundingSources) && isComingFromFundingSourceWizard) {
      analytics.track(eventPage, 'add-manual-limit');
      setShowUnverifiedAchLimitNotification(true);
    } else {
      analytics.track(eventPage, 'add-manual');
      dispatch(setFeeFundingSourceBankType(BANK_TYPE.UNVERIFIED));
      navigate(locations.Onboarding.fundingSources.bank.manually.url());
    }
  };

  const goDashboard = () => null;

  const goExit = () => {
    analytics.track(eventPage, 'exit');

    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState();
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  const bankType = isEmpty(vendorId) ? 'fundingSources' : 'deliveryMethods';
  const verificationOptions: Option[] = [
    {
      id: 'plaid',
      label: `onboarding.${bankType}.bank.verification.plaid.label`,
      description: `onboarding.${bankType}.bank.verification.plaid.description`,
      onClick: onPlaidClick,
    },
    {
      id: 'manual',
      label: `onboarding.${bankType}.bank.verification.manual.label`,
      description: `onboarding.${bankType}.bank.verification.manual.description`,
      onClick: onManualClick,
    },
  ];

  if (isFundingSourcesRefreshing) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <SelectBankAccountVerificationTypePage
      options={verificationOptions}
      goExit={goExit}
      vendorId={vendorId}
      deliveryMethods={userDeliveryMethods}
      goDashboard={goDashboard}
      showUnverifiedAchLimitNotification={showUnverifiedAchLimitNotification}
    />
  );
};

export default compose(withPreservedStateNavigator())(
  SelectBankAccountVerificationTypePageContainer
);
