import { Box, Flex, Link, CSSObject } from '@melio/billpay-design-system';

import { useSiteContext } from 'src/app/hoc/withSiteContext';
import { MIFormattedText } from 'src/app/utils/formatting';

type Props = {
  __css?: CSSObject;
  withHighlighting?: boolean;
};

const SupportLine = ({ __css, withHighlighting = false }: Props) => {
  const site = useSiteContext();

  return (
    <Box __css={__css}>
      <Flex justify="center" textStyle="body4">
        <Box mr="0.8rem" fontWeight="600" color="gray.500">
          <MIFormattedText label="vendors.addDeliveryMethodByLink.support" />
        </Box>
        <Link
          href={`mailto:${site.config.support.email}`}
          isExternal
          textDecoration="none !important"
          fontWeight="400"
          color={withHighlighting ? 'blue.500' : 'gray.500'}
        >
          {site.config.support.email}
        </Link>
      </Flex>
    </Box>
  );
};

export { SupportLine };
