import sortBy from 'lodash/sortBy';
import { ActionOption } from 'src/app/components/common/MIActionsMenu';
import { isEligibleToReceiveVirtualCard } from 'src/app/utils/payments';
import {
  FundingSourceTypesEnum,
  CardTypeEnum,
  FundingSourceOrigins,
} from 'src/app/version-2/model/enums';
import { FundingSource } from 'src/app/version-2/model/dtos';
import { CardTypes } from './types';
import { CONSTS, FUNDING_SOURCE_LIMIT, FUNDING_SOURCE_ORIGIN, CARD_TYPES } from './consts';
import { Permissions } from './permissions';
import { getAccountNumber4digits } from './bank-account';

const isManualBankAccountNotVerified = (fundingSource?: FundingSource | null) =>
  !!(
    fundingSource &&
    fundingSource.fundingType === FundingSourceTypesEnum.ACH &&
    fundingSource.origin === FundingSourceOrigins.MANUAL &&
    !fundingSource.isVerified
  );

const canVerifyManualBankAccount = (fundingSource?: FundingSource | null) =>
  !!(
    fundingSource &&
    isManualBankAccountNotVerified(fundingSource) &&
    fundingSource.bankAccount &&
    fundingSource.bankAccount.canVerify &&
    !fundingSource.bankAccount.isBlocked
  );

const isBankAccountBlocked = (fundingSource?: FundingSource | null) =>
  !!(fundingSource && fundingSource.bankAccount && fundingSource.bankAccount.isBlocked);

const isSandboxIndicator = (site: any) =>
  site.config.services.tabapay.url.indexOf('sandbox') !== -1;

const sortFundingSourceSelectOptions = (
  list: {
    methods: any[];
    componentType: any;
    description?: string;
    disabled?: boolean;
    methodErrors?: any;
    isSelectedDeliveryMethodVirtualCard?: boolean;
    onBenefitsClicked?: (card?: FundingSource) => void;
    debitFee?: React.ReactNode | string;
  }[]
) => sortBy(list, (listItem) => (listItem.methods.length === 0 ? 1 : 0));

type FundingSourceModificationParams = {
  permissions: Permissions;
  isDisabled: boolean;
  isExpired?: boolean;
  modifyActions?: Record<string, any>;
  origin?: string;
  hasLabel?: boolean;
};

const getModifyFundingSourceOptions = ({
  permissions,
  isDisabled,
  modifyActions,
  origin,
  isExpired,
  hasLabel,
}: FundingSourceModificationParams) => {
  const actionOptions: ActionOption[] = [];

  if (modifyActions) {
    if (origin === FUNDING_SOURCE_ORIGIN.MANUAL && !isExpired) {
      actionOptions.push({
        label: 'settings.paymentMethods.view',
        action: () => modifyActions.view(),
      });
    }

    if (!isDisabled && permissions.fundingSources.update()) {
      actionOptions.push({
        label: 'settings.paymentMethods.editQBLink',
        action: () => modifyActions.edit(),
      });
    }

    if (!isDisabled && permissions.fundingSources.update()) {
      actionOptions.push({
        label: hasLabel ? 'settings.paymentMethods.editLabel' : 'settings.paymentMethods.addLabel',
        action: () => modifyActions.editLabel(),
      });
    }

    if (permissions.fundingSources.delete()) {
      actionOptions.push({
        label: 'settings.paymentMethods.delete',
        action: () => modifyActions.delete(),
        negative: true,
      });
    }
  }

  return actionOptions;
};

const getNameParts = (fundingSource: FundingSource) => {
  if (fundingSource?.bankAccount) {
    return {
      displayName: fundingSource.displayName || '',
      institutionName: fundingSource?.plaidAccount?.plaidItem?.institutionName || undefined,
      identifier: getAccountNumber4digits(fundingSource.bankAccount),
    };
  }

  if (fundingSource?.cardAccount) {
    return {
      displayName: '',
      institutionName: fundingSource.cardAccount.network,
      identifier: fundingSource.cardAccount.card4digits,
    };
  }

  return {
    displayName: '',
    institutionName: '',
    identifier: '',
  };
};

const getFundingSourceType = (fundingSource): string | undefined =>
  fundingSource?.fundingType === FundingSourceTypesEnum.CARD
    ? fundingSource?.cardAccount?.cardType
    : fundingSource?.fundingType;

const isOneDayDeliverySpeed = (deliveryOptionType?: string, fundingSourceType?: string) =>
  Boolean(
    deliveryOptionType &&
      fundingSourceType &&
      deliveryOptionType === CONSTS.DELIVERY_TYPE.ACH &&
      [CARD_TYPES.DEBIT, CARD_TYPES.CREDIT].includes(fundingSourceType as CardTypes)
  );

const getSelectedOrDefaultFundingSource = (
  fundingSourceId,
  fundingSources,
  selectedDeliveryMethodType
): FundingSource | undefined => {
  if (!fundingSourceId) {
    for (const fs of fundingSources) {
      if (fs.origin === FUNDING_SOURCE_ORIGIN.QBCASH) {
        return fs;
      }
    }
  }

  const selectedFundingSource = fundingSources.find((fs) => fs.id === fundingSourceId);

  if (selectedDeliveryMethodType === CONSTS.DELIVERY_TYPE.VIRTUAL_CARD) {
    if (isEligibleToReceiveVirtualCard(selectedFundingSource)) {
      return selectedFundingSource;
    }

    return fundingSources.find((fs) => fs.origin === FUNDING_SOURCE_ORIGIN.QBCASH);
  }

  return selectedFundingSource;
};

const isUnverifiedAchLimitExceeded = (fundingSources: FundingSource[]) =>
  fundingSources.filter((fs) => fs.fundingType === FundingSourceTypesEnum.ACH && !fs.isVerified)
    .length >= FUNDING_SOURCE_LIMIT.ACH_UNVERIFIED;

const isDebitCard = (fundingSource: FundingSource) => {
  const isCard = fundingSource?.fundingType === FundingSourceTypesEnum.CARD;
  const isDebitType = fundingSource?.cardAccount?.cardType === CardTypeEnum.DEBIT;

  return isCard && isDebitType;
};

const hasDebitFundingSource = (fundingSources: FundingSource[]): boolean =>
  !!fundingSources.find((fs) => isDebitCard(fs));

export {
  isManualBankAccountNotVerified,
  isBankAccountBlocked,
  canVerifyManualBankAccount,
  isSandboxIndicator,
  sortFundingSourceSelectOptions,
  getModifyFundingSourceOptions,
  getNameParts,
  getFundingSourceType,
  getSelectedOrDefaultFundingSource,
  isUnverifiedAchLimitExceeded,
  isOneDayDeliverySpeed,
  isDebitCard,
  hasDebitFundingSource,
};
