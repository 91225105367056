import { Theme } from '../../theme';

const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'right',
  },
};

const textStyles = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const simpleVariant = ({ theme }: { theme: Theme }) => ({
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    tableLayout: 'fixed',
    width: 'full',
    fontFamily: 'body',
    borderCollapse: 'separate',
    borderSpacing: `0 ${theme.space.ds.md}`,
  },
  tbody: {
    tr: {
      borderRadius: 'ds.lg',
      boxShadow: 'ds.md',
      '&.highlighted': {
        td: {
          borderTop: '2px solid',
          borderBottom: '2px solid',
          borderColor: 'ds.gray.500',
          _first: {
            borderLeft: '2px solid',
            borderColor: 'ds.gray.500',
          },
          _last: {
            borderRight: '2px solid',
            borderColor: 'ds.gray.500',
          },
        },
      },
    },
  },
  th: {
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '1rem',
    lineHeight: '1.2rem',
    ...textStyles,
    ...numericStyles,
    color: 'ds.gray.200',
    textStyle: 'ds.body3',
    fontWeight: 'ds.medium',

    _hover: {
      '&.sortable': {
        backgroundColor: 'ds.gray.700',
        borderRadius: 'ds.lg',
        cursor: 'pointer',
        color: 'ds.gray.200',

        div: {
          color: 'ds.gray.200',
        },

        svg: {
          fill: 'ds.gray.200',
        },
      },
    },
    _active: {
      '&.sortable': {
        backgroundColor: 'ds.gray.700',
        borderRadius: 'ds.lg',
        cursor: 'pointer',
        color: 'ds.black',

        div: {
          color: 'ds.black',
        },

        svg: {
          fill: 'ds.black',
        },
      },
    },
  },
  td: {
    textAlign: 'left',
    textStyle: 'ds.body2',
    fontWeight: 'ds.medium',
    bg: 'ds.white',
    '*': {
      ...textStyles,
    },
    ...textStyles,
    ...numericStyles,

    '&:first-of-type': {
      borderTopLeftRadius: 'ds.lg',
      borderBottomLeftRadius: 'ds.lg',
    },

    '&:last-of-type': {
      borderTopRightRadius: 'ds.lg',
      borderBottomRightRadius: 'ds.lg',
    },
  },
});

export const mdSize = {
  th: {
    px: 'ds.md',
    py: 'ds.md',

    '&:first-of-type': {
      pl: 'ds.lg',
    },

    '&:last-of-type': {
      pr: 'ds.lg',
    },
  },
  td: {
    px: 'ds.md',
    py: 'ds.xl',

    '&:first-of-type': {
      pl: 'ds.lg',
    },

    '&:last-of-type': {
      pr: 'ds.lg',
    },
  },
};
