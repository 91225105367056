export const styles = {
  container: {
    paddingTop: 1,
    borderTopWidth: 1,
    borderTopColor: 'gray.400',
  },
  table: {
    display: 'flex',
    mt: 4,
    mb: 0,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'gray.400',
  },
  tableTitle: {
    textStyle: 'body4',
    textColor: 'gray.500',
    fontWeight: 600,
  },
  tableText: {
    mt: 0,
    mb: 0,
    fontWeight: 500,
    textStyle: 'body3',
    textColor: 'black',
  },
  textBlock: {
    display: 'flex',
    flex: '1 1 50%',
  },
  textSection: {
    flex: '1 1 50%',
    padding: 3,
  },
};
